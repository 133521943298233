var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'AppSearchDropdown',
    { 'AppSearchDropdown--no-results': !!_vm.noResults }
  ]},[_c('div',{staticClass:"AppSearchDropdown__header"},[_c('input',{directives:[{name:"focus",rawName:"v-focus"}],ref:"queryInput",staticClass:"AppSearchDropdown__header-input",attrs:{"type":_vm.number ? 'number' : 'text',"min":_vm.number ? _vm.number.min : null,"max":_vm.number ? _vm.number.max : null,"placeholder":_vm.placeholder},domProps:{"value":_vm.query},on:{"input":function($event){_vm.query = $event.target.value},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.down.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.up.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.$emit('close')}],"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.enterClicked.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}}}),(!_vm.number)?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.query),expression:"!!query"}],staticClass:"AppSearchDropdown__header-reset-button",attrs:{"type":"button"},on:{"click":_vm.resetQuery}},[_c('AppSvgIcon',{attrs:{"name":"icon-close-small"}})],1):_vm._e()]),(_vm.sortedOptions.length)?_c('ul',{ref:"options",staticClass:"AppSearchDropdown__list"},_vm._l((_vm.sortedOptions),function(item,i){
  var _obj, _obj$1;
return _c('li',{key:i,class:[
        'AppSearchDropdown__item',
        ( _obj = {
          'AppSearchDropdown__item--not-selectable': !item.isSelectable,
          'AppSearchDropdown__item--disabled': item.disabled,
          'AppSearchDropdown__item--active': _vm.activeIndex === i
        }, _obj[("AppSearchDropdown__item--" + (item.style))] = !!item.style, _obj )
      ],on:{"mousemove":function($event){_vm.activeIndex = i},"click":function($event){return _vm.selectItem(i)}}},[(item.isSelected)?_c('span',[_vm._v("✓ ")]):_vm._e(),_c('span',{class:[
          'AppSearchDropdown__item-label',
          ( _obj$1 = {}, _obj$1[("AppSearchDropdown__item-label--" + (item.style))] = !!item.style, _obj$1["AppSearchDropdown__item-label--disabled"] = item.disabled, _obj$1 )
        ]},[_vm._v(_vm._s(item.label))])])}),0):(_vm.noResults)?_c('div',{staticClass:"AppSearchDropdown__body-no-results"},[_vm._v(" "+_vm._s(_vm.noResults)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }