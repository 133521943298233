<template>
  <div class="AppEventReportsFilter">
    <label class="AppEventReportsFilter__title">
      {{ $t('analysis_filter_option') }}
    </label>
    <div class="AppEventReportsFilter__event">
      <AppSelectFilterable
        v-model="eventType"
        :placeholder="$t('select_event')"
        :options="EVENT_OPTIONS"
        class="AppEventReportsFilter__select-filterable"
      />
      <AppSelectFilterable
        v-model="aggregateMethod"
        :placeholder="$t('select_aggregate_method')"
        :options="AGGREGATE_METHOD_OPTIONS"
        class="AppEventReportsFilter__select-filterable"
      />
      <AppSelectFilterable
        v-if="requiresAggregateColumn"
        v-model="aggregateColumn"
        :placeholder="$t('select_aggregate_column')"
        :options="COLUMN_OPTIONS"
        class="AppEventReportsFilter__select-filterable"
      />
    </div>
    <div>
      <AppButton :label="$t('add_column_filter')" @click="addColumnFilter" />
    </div>
    <AppEventReportsFilterColumn
      v-for="(columnFilter, index) in columnFilters"
      :key="index"
      :column-filter="columnFilter"
      :column-options="COLUMN_OPTIONS"
      @update:column="columnFilter.column = $event"
      @update:operator="columnFilter.operator = $event"
      @update:value="columnFilter.value = $event"
      @remove="removeColumnFilter(index)"
    />
    <AppButton
      class="AppEventReportsFilter__search-button"
      button-style="blue"
      :label="$t('app.search')"
      @click="fetchResults"
    />
  </div>
</template>

<script>
export default {
  name: 'AppEventReportsFilter',
  props: {
    eventFilters: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      EVENT_OPTIONS: [{ label: 'view', value: 'view' }],
      AGGREGATE_METHOD_OPTIONS: [
        {
          label: 'COUNT',
          value: 'count'
        },
        {
          label: 'UNIQUE',
          value: 'unique_count'
        }
      ],
      COLUMN_OPTIONS: [
        'device_token',
        'username',
        'mobile',
        'event',
        'location',
        'referrer',
        'product_code',
        'category_code'
      ].map(option => ({ label: option, value: option })),
      eventType: this.eventFilters.event_type,
      aggregateMethod: this.eventFilters.aggregate_method,
      aggregateColumn: this.eventFilters.aggregate_column,
      columnFilters: this.eventFilters.column_filters
    };
  },
  computed: {
    requiresAggregateColumn() {
      return this.eventType && ['unique_count'].includes(this.aggregateMethod);
    }
  },
  watch: {
    requiresAggregateColumn(newValue) {
      if (!newValue) {
        this.aggregateColumn = null;
      }
    }
  },
  methods: {
    addColumnFilter() {
      this.columnFilters.push({
        column: '',
        operator: 'include',
        value: ''
      });
    },
    removeColumnFilter(index) {
      this.columnFilters.splice(index, 1);
    },
    fetchResults() {
      const params = {
        event_type: this.eventType,
        aggregate_method: this.aggregateMethod,
        aggregate_column: this.aggregateColumn,
        column_filters: JSON.stringify(this.columnFilters)
      };
      this.$emit('fetch-result', params);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppEventReportsFilter {
  margin-top: 16px;
  margin-bottom: 12px;
  padding: 23px 27px;
  border: 1px solid $color-form-border;
  border-radius: 3px;
}

.AppEventReportsFilter__title {
  @include text-label;
  display: block;
  margin-bottom: 16px;
}

.AppEventReportsFilter__event {
  margin-bottom: 16px;
}

.AppEventReportsFilter__select-filterable {
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }
}

.AppEventReportsFilter__search-button {
  margin-top: 16px;
}
</style>

<i18n locale="ko">
{
  "analysis_filter_option": "분석 항목",
  "select_event": "이벤트 선택",
  "select_aggregate_method": "계산 방식 선택",
  "select_aggregate_column": "속성 선택",
  "add_column_filter": "+ 속성 필터 추가"
}
</i18n>
