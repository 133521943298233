var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"form-props":Object.assign({}, {formStyle: 'wide'}, _vm.formProps),"is-loading":_vm.isLoading,"title":_vm.$t(_vm.editing ? 'title_edit' : 'title_new')},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
var errors = ref.errors;
return [(id === 'parameters')?[_c('div',{staticClass:"AppForm__group-field"},[_c('div',{staticClass:"table-head-control"},[_c('AppButton',{attrs:{"label":_vm.$t('button.add_url_param')},on:{"click":_vm.addUrlPattern}})],1),_c('AppSmallDeviceScrollable',[_c('AppTable',{attrs:{"valign-baseline":"","columns":_vm.COLUMNS,"rows":_vm.formObject.parameters},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
var rowIndex = ref.rowIndex;
return [(column.id === 'key')?[_c('input',{attrs:{"type":"hidden","name":"page_type_regex[parameters][][id]"},domProps:{"value":row.id}}),_c('AppTextInput',{attrs:{"invalid":!!errors[("parameters[" + (row.index) + "][param_key]")],"name":"page_type_regex[parameters][][param_key]"},on:{"blur":function($event){return _vm.validateField(("parameters[" + (row.index) + "][param_key]"))},"change":function($event){return _vm.validateField(("parameters[" + (row.index) + "][param_key]"))}},model:{value:(row.param_key),callback:function ($$v) {_vm.$set(row, "param_key", $$v)},expression:"row.param_key"}}),_c('AppFormError',{attrs:{"error":errors[("parameters[" + (row.index) + "][param_key]")]}})]:(column.id === 'value')?[_c('AppTextInput',{attrs:{"invalid":!!errors[("parameters[" + (row.index) + "][param_value]")],"name":"page_type_regex[parameters][][param_value]"},on:{"blur":function($event){return _vm.validateField(("parameters[" + (row.index) + "][param_value]"))},"change":function($event){return _vm.validateField(("parameters[" + (row.index) + "][param_value]"))}},model:{value:(row.param_value),callback:function ($$v) {_vm.$set(row, "param_value", $$v)},expression:"row.param_value"}}),_c('AppFormError',{attrs:{"error":errors[("parameters[" + (row.index) + "][param_value]")]}})]:(column.id === 'feature')?[_c('AppButton',{attrs:{"button-style":"red-outline","label":_vm.$t('app.delete')},on:{"click":function($event){return _vm.formObject.parameters.splice(rowIndex, 1)}}})]:_vm._e()]}}],null,true)})],1)],1)]:_vm._e()]}}])},_vm.formEvents),[_c('input',{attrs:{"type":"hidden","name":"page_type_regex[page_type]"},domProps:{"value":_vm.pageType}})])}
var staticRenderFns = []

export { render, staticRenderFns }