<template>
  <AppResourceTable
    :columns="columns"
    :rows="rows"
    :enable-total-count="false"
    :custom-buttons="[filterButton, sortButton]"
    table-id="product_displays"
  >
    <template #cell="{ row, column }">
      <template v-if="column === 'name'">
        <span
          :class="
            `DisplayProductDisplaysTable__name-content--${productDisplayRange}`
          "
          >{{ row.name }}</span
        >
      </template>
      <template v-else-if="column === 'corners'">
        <AppSummaryBadge
          v-if="row.corners.length"
          :items="row.corners"
          unit-path="count"
        />
        <span v-else>-</span>
      </template>
      <template v-else-if="column === 'status'">
        <DisplayProductDisplayStatusBadge
          :product-display-status="row.product_display_status"
          :edited-at="row.edited_at"
          :cohort="row.applying_cohort"
        />
        <AppButton
          v-if="isErrored(row.product_display_status)"
          :label="$t('show_detail')"
          button-style="underline"
          @click="openErrorDetailDialog(row)"
        />
      </template>
      <template v-else-if="column === 'actions'">
        <AppButton
          :label="$t('app.edit')"
          :disabled="!row.corners.length"
          @click="openDisplayCornerProductsDialog(row)"
        />
        <AppDropdownMenuButton
          :label="$t('app.manage')"
          :menu-items="[
            {
              label: $t('cohorts'),
              disabled: !row.cohorts_exist,
              clickHandler: ({ close }) => {
                close();
                eventBus.$emit('show-cohorts', row);
              }
            },
            {
              label: $t('app.delete'),
              style: 'danger',
              clickHandler: ({ close }) => {
                close();
                deleteProductDisplay(row);
              }
            }
          ]"
        />
      </template>
    </template>
  </AppResourceTable>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';
import DisplayCornerStatus from '@/enums/DisplayCornerStatus';
import DisplayProductDisplayStatus from '@/enums/DisplayProductDisplayStatus';
import DisplayProductDisplayExecuteStatus from '@/enums/DisplayProductDisplayExecuteStatus';
import ShopBuilder from '@/enums/ShopBuilder';

export default {
  name: 'DisplayProductDisplaysTable',
  props: {
    productDisplayRange: {
      type: String,
      default: 'corner',
      validator: v => ['general', 'corner'].includes(v)
    },
    corner: { type: Object, default: null },
    eventBus: { type: Object, required: true }
  },
  data() {
    return {
      filterButton: {
        icon: 'icon-filter-alt-outlined',
        controlType: 'select',
        options: [{ label: this.$t('button.filter.all'), value: null }],
        value: null
      },
      sortButton: {
        icon: 'icon-sort',
        controlType: 'select',
        options: ['created_desc', 'updated_desc'].map(value => ({
          label: this.$t(`button.sort.${value}`),
          value
        })),
        value: 'created_desc'
      }
    };
  },
  computed: {
    ...mapState('displayProductDisplays', [
      'cornerPageType',
      'productDisplays'
    ]),
    ...mapState('session', ['currentBrand']),
    ...mapGetters('displayProductDisplays', [
      'isCategoryPage',
      'cornerPageTypeKey'
    ]),
    columnWidth() {
      if (this.isCategoryPage) {
        if (this.productDisplayRange === 'general')
          return {
            name: '184px',
            corners: '256px',
            last_displayed_at: '200px',
            display_at: '200px',
            status: '100px',
            actions: '168px'
          };
        else
          return {
            name: '140px',
            corners: '180px',
            last_displayed_at: '150px',
            display_at: '150px',
            status: '100px',
            actions: '150px'
          };
      } else
        return {
          name: '280px',
          last_displayed_at: '150px',
          display_at: '150px',
          status: '100px',
          actions: '150px'
        };
    },
    columns() {
      return [
        {
          id: 'name',
          label: this.$t('column.name.label'),
          tooltip: {
            message: this.$t(
              `column.name.tooltip.${this.cornerPageTypeKey}.${this.productDisplayRange}`
            ),
            textAlign: 'left'
          },
          align: 'left'
        },
        ...(this.isCategoryPage
          ? [
              {
                id: 'corners',
                label: this.$t('column.corners.label'),
                align: 'left'
              }
            ]
          : []),
        {
          id: 'last_displayed_at',
          label: this.$t('column.last_displayed_at.label'),
          type: 'datetime_hm',
          default: '-',
          align: 'left'
        },
        {
          id: 'display_at',
          label: this.$t('column.display_at.label'),
          type: 'datetime_hm',
          default: '-',
          align: 'left'
        },
        { id: 'status', label: this.$t('app.status') },
        { id: 'actions', label: this.$t('app.actions') }
      ].map(column => ({ ...column, width: this.columnWidth[column.id] }));
    },
    rows() {
      return _.chain(this.productDisplays)
        .filter(productDisplay =>
          this.corner
            ? productDisplay.corners.some(({ id }) => id === this.corner.id)
            : true
        )
        .filter(productDisplay =>
          this.filterButton.value === null
            ? true
            : productDisplay.product_display_status === this.filterButton.value
        )
        .sortBy(productDisplay => {
          switch (this.sortButton.value) {
            case 'created_desc':
              return -moment(productDisplay.created_at);
            case 'updated_desc':
              return -moment(productDisplay.edited_at);
            case 'corners_count_desc':
              return -productDisplay.corners.length;
            default:
              return null;
          }
        })
        .map(productDisplay => ({
          ...productDisplay,
          corners: productDisplay.corners.map(corner => {
            const label = DisplayCornerStatus.isDeleted(corner.corner_status)
              ? `${corner.name} ${this.$t('column.corners.deleted')}`
              : corner.name;
            return { label, description: label };
          })
        }))
        .value();
    }
  },
  mounted() {
    const existingProductDisplayStatuses = _.chain(this.rows)
      .map('product_display_status')
      .uniq()
      .sort()
      .value();

    this.filterButton.options = [
      { label: this.$t('button.filter.all'), value: null },
      ...DisplayProductDisplayStatus.options(existingProductDisplayStatuses)
    ];

    if (this.isCategoryPage)
      this.sortButton.options = [
        ...this.sortButton.options,
        {
          label: this.$t('button.sort.corners_count_desc'),
          value: 'corners_count_desc'
        }
      ];
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('displayProductDisplays', [
      'SET_PRODUCT_DISPLAYS',
      'SET_POLLING'
    ]),
    ...mapMutations('displayProductDisplay', ['UPDATE_IS_STATUS_UPDATED']),
    openDisplayCornerProductsDialog(productDisplay) {
      this.openDialog([
        'DisplayProductDisplayPreviewDialog',
        {
          productDisplayId: productDisplay.id,
          cornerPageType: this.cornerPageType
        }
      ]);
      this.SET_POLLING(false);
    },
    deleteProductDisplay(productDisplay) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t('delete_confirm.title'),
          markdownText: this.$t('delete_confirm.content', [
            productDisplay.name
          ]),
          width: DialogSize.AUTO
        }
      ]).then(eventBus => {
        eventBus.$on('close', () =>
          api
            .delete(`/display/product_displays/${productDisplay.id}`, {
              successMessage: this.$t('app.deleted')
            })
            .then(() => {
              this.SET_PRODUCT_DISPLAYS(
                this.productDisplays.filter(
                  ({ id }) => id !== productDisplay.id
                )
              );
              this.UPDATE_IS_STATUS_UPDATED(true);
            })
        );
      });
    },
    isErrored(product_display_status) {
      return DisplayProductDisplayStatus.isErrored(product_display_status);
    },
    openErrorDetailDialog(row) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t(`error_detail.${this.cornerPageTypeKey}.title`),
          markdownText: this.errorMessage(row),
          width: DialogSize.SMALL
        }
      ]);
    },
    errorMessage(row) {
      const erroredCohort = row.errored_cohort;
      let argument;
      switch (erroredCohort.execute_status) {
        case DisplayProductDisplayExecuteStatus.ERROR_DISPATCHING:
        case DisplayProductDisplayExecuteStatus.ERROR_LOGIN_BLOCKED:
        case DisplayProductDisplayExecuteStatus.ERROR_SESSION_EXPIRED:
          argument = ShopBuilder.tk(this.currentBrand.shop_builder);
          break;
        case DisplayProductDisplayExecuteStatus.ERROR_CORNER_NOT_EXISTS:
          argument = erroredCohort.corner_name;
          break;
      }

      return this.$t(
        `error_detail.${
          this.cornerPageTypeKey
        }.${DisplayProductDisplayExecuteStatus.key(
          erroredCohort.execute_status
        )}`,
        [argument]
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayProductDisplaysTable__name-content {
  &--general {
    @include text-sub-title;
  }
}
</style>

<i18n locale="ko">
{
  "button": {
    "filter": {
      "all": "모든 상태"
    },
    "sort": {
      "created_desc": "최근 생성일 순",
      "updated_desc": "최근 수정일 순",
      "corners_count_desc": "적용 카테고리 많은 순"
    }
  },
  "column": {
    "name": {
      "label": "진열 설정",
      "tooltip": {
        "MAIN": {
          "corner": "진열 위치 별로 어떤 상품을 어떤 식으로\n진열할지 결정할 수 있습니다.\n위치별로 다수의 진열 설정이 가능합니다."
        },
        "CATEGORY": {
          "general": "카테고리를 선택하여 어떤 상품을 어떤식으로 진열할지 결정할 수 있습니다.\n다수의 진열 설정이 가능합니다.",
          "corner": "이 카테고리에 적용할 진열 설정을 추가할 수 있습니다.\n다수의 진열 설정을 추가할 수 있으며\n설정을 추가한 이후 적용 카테고리를 변경하거나 추가할 수 있습니다."
        }
      }
    },
    "corners": {
      "label": "대상 카테고리",
      "deleted": "(삭제됨)"
    },
    "last_displayed_at": {
      "label": "최근 진열 적용일"
    },
    "display_at": {
      "label": "진열 예약"
    }
  },
  "cohorts": "진열 이력",
  "delete_confirm": {
    "title": "진열 설정 삭제",
    "content": "{0}((을)) 삭제하시겠습니까?"
  },
  "show_detail": "자세히 보기",
  "error_detail": {
    "MAIN": {
      "title": "메인 페이지 진열 중 문제가 발생했습니다.",
      "ERROR_PREPARING": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_CALCULATING": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_DISPATCHING": "{0} 메인페이지 진열 중 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_CORNER_NOT_EXISTS": "{0} 진열 위치를 찾을 수 없습니다.<br>[진열 위치 동기화] 후 다시 시도하시기 바랍니다.",
      "ERROR_LOGIN_BLOCKED": "{0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_SESSION_EXPIRED": "세션이 만료되어 {0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다."
    },
    "CATEGORY": {
      "title": "카테고리 페이지 진열 중 문제가 발생했습니다.",
      "ERROR_PREPARING": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_CALCULATING": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_DISPATCHING": "{0} 카테고리 페이지 진열 중 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_CORNER_NOT_EXISTS": "{0} 카테고리를 찾을 수 없습니다.<br>카테고리 정보 확인 후 다시 시도하시기 바랍니다.",
      "ERROR_LOGIN_BLOCKED": "{0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_SESSION_EXPIRED": "세션이 만료되어 {0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다."
    }
  }
}
</i18n>
