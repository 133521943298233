<template>
  <div
    :class="[
      'AppSelect',
      { 'AppSelect--disabled': disabled, 'AppSelect--inline': inline }
    ]"
  >
    <select
      :id="id"
      :class="[
        'AppSelect__select',
        {
          'AppSelect__select--focus': isFocused,
          'AppSelect__select--invalid': invalid,
          [`AppSelect__select--${size}`]: !!size
        }
      ]"
      :name="name"
      :disabled="disabled"
      :value="currentValue"
      @change="change($event.target.value)"
      @blur="$emit('blur')"
    >
      <option v-if="placeholder !== null" :value="null">
        {{ placeholder }}
      </option>
      <option
        v-for="(option, i) in items"
        :key="i"
        :value="option.value"
        :disabled="option.disabled"
        :selected="option.value === currentValue"
        >{{ option.label }}</option
      >
    </select>
    <button
      v-if="placeholder && (currentValue || currentValue === 0)"
      type="button"
      class="AppSelect__clear-button"
      :disabled="disabled"
      @click.stop="clear"
    >
      <AppSvgIcon name="icon-close-small" />
    </button>
    <AppSvgIcon
      v-else
      name="nav-chevron-down"
      :class="['AppSelect__icon', { [`AppSelect__icon--${size}`]: !!size }]"
    />
  </div>
</template>

<script>
import SingleSelectable from '@/mixins/SingleSelectable';

export default {
  name: 'AppSelect',
  mixins: [SingleSelectable],
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    onDemandOptions: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    eventBus: {
      type: Object,
      default: null
    }
  },
  computed: {
    items() {
      return !this.onDemandOptions || this.isFocused || this.value
        ? this.options
        : [];
    }
  },
  mounted() {
    if (this.eventBus) this.eventBus.$on('select', this.change);
  },
  methods: {
    change(newValue) {
      this.setCurrentValue(newValue);
    },
    clear() {
      this.setCurrentValue(null);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppSelect {
  position: relative;
  display: inline-block;

  &--disabled {
    color: $color-disable-text;
  }

  &--inline {
    margin-right: 4px;
  }
}

.AppSelect__select {
  @include input-base;
  background-color: transparent;
  padding: 5px 31px 5px 11px;
  outline: none;

  &--focus {
    border-color: $color-form-border-selected;
    @include focus-highlight($color-form-focus-highlight);
  }

  &:disabled {
    border-color: $color-grey-25;
  }

  &--invalid {
    @include input-invalid;
  }

  &--small {
    font-size: 12px;
    padding: 4px 17px 4px 11px;
    height: 28px;
    line-height: 18px;
  }
}

$icon-size: 10px;

.AppSelect__icon {
  position: absolute;
  top: 50%;
  right: 10px;
  pointer-events: none;
  margin-top: $icon-size * -0.5;
  color: $color-form-border-hover;

  &--small {
    right: 8px;
  }
}

.AppSelect__clear-button {
  position: absolute;
  top: 1px;
  right: 1px;
  line-height: 0;
  padding: 9px;
}
</style>
