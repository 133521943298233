<template>
  <AppSelect
    v-bind="{ id, name, value, disabled, invalid }"
    :options="options"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'AppSelectHour',
  model: { event: 'change' },
  props: {
    id: { type: String, default: null },
    value: { type: Number, default: 0 },
    name: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    interval: { type: String, default: 'whole' },
    selectableHours: { type: Array, default: () => [...Array(24).keys()] }
  },
  computed: {
    options() {
      return this.selectableHours.map(value => ({
        label: this.selectLabel(value),
        value
      }));
    }
  },
  methods: {
    selectLabel(value) {
      switch (this.interval) {
        case 'whole':
          return this.$t('hour', [value]);
        case 'half':
          if (value % 1 === 0) {
            return this.$t("o'clock", [this.padLeadingZero(value)]);
          } else {
            return this.$t('half_past_hour', [this.hourString(value)]);
          }
        case 'quarter':
          if (value % 1 === 0) {
            return this.$t("o'clock", [this.padLeadingZero(value)]);
          } else if (value % 1 === 0.25) {
            return this.$t('quarter_past_hour', [this.hourString(value)]);
          } else if (value % 1 === 0.5) {
            return this.$t('half_past_hour', [this.hourString(value)]);
          } else {
            return this.$t('three_quarters_past_hour', [
              this.hourString(value)
            ]);
          }
      }
    },
    hourString(value) {
      return this.padLeadingZero(Math.floor(value));
    },
    padLeadingZero(value) {
      return String(value).padStart(2, '0');
    }
  }
};
</script>

<i18n locale="ko">
{
  "hour": "{0}시",
  "o'clock": "{0}:00",
  "half_past_hour": "{0}:30",
  "quarter_past_hour": "{0}:15",
  "three_quarters_past_hour": "{0}:45"
}
</i18n>
