<template>
  <AppCheckbox
    v-model="widget.show_size_filter"
    name="widget[show_size_filter]"
    :disabled="isDisabled"
    :label="$t('label')"
    :tooltip="!currentBrand.use_size_review_option_types ? $t('tooltip') : null"
    @change="$emit('change')"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ReviewSettingsShowSizeFilter',
  props: {
    widget: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      defautl: false
    }
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    isDisabled() {
      return this.disabled || !this.currentBrand.use_size_review_option_types;
    }
  }
};
</script>

<i18n locale="ko">
{
  "label": "맞춤 체형 필터 사용",
  "tooltip": "맞춤 체형 필터를 사용하려면 [리뷰 설정 > 기본 설정 > 추가 수집정보 > 키, 몸무게]를 사용해야 합니다."
}
</i18n>
