<template>
  <AppModalDefault
    :title="$t('fit_size_feedback_product_dialog.title')"
    :sub-title="product.name"
    @close="close"
  >
    <AppAjaxContent slot="body" :is-loading="isLoading">
      <AppSelectButton
        v-if="sizeOptions.length > 0"
        v-model="selectedSizeOptionId"
        class="FitSizeFeedbackProducts__size-option-buttons"
        :options="sizeOptions"
        :tooltip="$t('fit_size_feedback_product_dialog.no_size_feedbacks')"
        @change="filterBySizeOption($event)"
      />
      <AppContainer v-if="fitScorePercentages.length > 0">
        <AppDataItem
          :label="
            titleWithSizeOptionName(
              $t('fit_size_feedback_product_dialog.fit_score_stats')
            )
          "
          gap="narrow"
        >
          <ul>
            <li
              v-for="(item, i) in fitScorePercentages"
              :key="i"
              class="app-list__item"
            >
              {{ item.fit_score_name }} {{ item.fit_score_value }}%
              <span class="FitSizeFeedbackProductDialog__fit-score-count">{{
                `${item.fit_score_count}${$t('unit.count')}`
              }}</span>
            </li>
          </ul>
        </AppDataItem>
      </AppContainer>

      <AppResourceTable
        :table-name="
          titleWithSizeOptionName(
            $t('fit_size_feedback_product_dialog.size_feedbacks')
          )
        "
        :toggle-button="{
          label: $t(
            'fit_size_feedback_product_dialog.show_size_feedbacks_with_fit_message'
          )
        }"
        :resources="sizeFeedbacks"
        :columns="columns"
        :rows="rows"
        @paginate="paginate"
        @toggle="filterOnlyFitMessages($event)"
      >
        <template #cell="{ column, value }">
          <template v-if="column == 'last_updated_at'">
            <div
              v-tooltip="
                `${$t('size_feedback.last_updated_at')}: ${formatDateTime(
                  value
                )}`
              "
            >
              <div class="FitSizeFeedbackProducts__date table-line">
                {{ value | formatDate }}
              </div>
              <div
                class="FitSizeFeedbackProducts__detail table-line table-line--mt4"
              >
                <AppBadge :label="$t('app.detail_info')" />
              </div>
            </div>
          </template>
          <template v-else-if="column == 'user_property'">
            <div
              v-for="(v, i) in value"
              :key="i"
              class="table-line table-line--mt4"
            >
              {{ v }}
            </div>
          </template>
        </template>
      </AppResourceTable>
    </AppAjaxContent>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import { mapGetters } from 'vuex';
import { nullResources } from '@/lib/resources';
import DialogView from '@/mixins/DialogView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

const REQUEST_ID = 'FIT_SIZE_FEEDBACK_PRODUCT_DIALOG_FETCH_PRODUCT';

export default {
  name: 'FitSizeFeedbackProductDialog',
  mixins: [DialogView, ResourceViewNoRoute],
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedSizeOptionId: null,
      fitScorePercentages: [],
      sizeFeedbacks: nullResources,
      sizeOptions: [],
      columns: [
        {
          id: 'last_updated_at',
          label: this.$t('size_feedback.last_updated_at')
        },
        {
          id: 'user_property',
          label: this.$t('size_feedback.user_property')
        },
        {
          id: 'purchased_size',
          label: this.$t('size_feedback.purchased_size'),
          type: 'table_line',
          default: '-'
        },
        {
          id: 'fit_score',
          label: this.$t('size_feedback.fit_score'),
          type: 'table_line',
          default: '-'
        },
        {
          id: 'fit_message',
          label: this.$t('size_feedback.fit_message'),
          type: 'table_line',
          default: '-'
        }
      ]
    };
  },
  computed: {
    ...mapGetters('request', ['isRequestPending']),
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      only_fit_messages: '0'
    }),
    rows() {
      return this.sizeFeedbacks.items.map(sizeFeedback => {
        const userPropertyText = this.userPropertyText(sizeFeedback);

        return {
          last_updated_at: sizeFeedback.last_updated_at,
          user_property: userPropertyText,
          purchased_size: sizeFeedback.purchased_size,
          fit_score: sizeFeedback.fit_score,
          fit_message: sizeFeedback.fit_message
        };
      });
    },
    selectedSizeOption() {
      return this.sizeOptions.find(o => o.value === this.selectedSizeOptionId);
    },
    isLoading() {
      return this.sizeFeedbacks.isNull || this.isRequestPending(REQUEST_ID);
    }
  },
  methods: {
    fetchResource(params) {
      const isInitialRequest = this.sizeFeedbacks.isNull;
      api
        .get(`/fit/size_feedback/products/${this.product.id}`, {
          requestId: REQUEST_ID,
          params: {
            ...params,
            init: isInitialRequest ? '1' : undefined
          }
        })
        .then(({ data }) => {
          this.sizeFeedbacks = data.size_feedbacks;
          this.fitScorePercentages = data.fit_score_percentages;

          if (isInitialRequest && data.size_options.length) {
            const defaultOption = {
              label: this.$t('app.all'),
              value: null,
              disabled: false
            };
            const purchasedSizeCountMap = _.countBy(
              this.sizeFeedbacks.items,
              'purchased_size'
            );
            const options = data.size_options.map(sizeOption => ({
              label: sizeOption.value,
              value: sizeOption.id,
              disabled: !purchasedSizeCountMap[sizeOption.value]
            }));
            this.sizeOptions = [defaultOption, ...options];
          }
        });
    },
    userPropertyText(sizeFeedback) {
      let result = [];
      sizeFeedback.user_properties.forEach(userProperty => {
        const userPropertyValue = userProperty.value ? userProperty.value : '-';
        result.push(`${userProperty.name}: ${userPropertyValue}`);
      });

      return result;
    },
    filterOnlyFitMessages($event) {
      this.resourceParams = {
        ...this.resourceParams,
        only_fit_messages: $event ? '1' : '0'
      };
      this.searchResource();
    },
    filterBySizeOption($event) {
      this.resourceParams = {
        ...this.resourceParams,
        size_option_id: $event
      };
      this.searchResource();
    },
    titleWithSizeOptionName(title) {
      return this.selectedSizeOption
        ? `'${this.selectedSizeOption.label}' ${title}`
        : title;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.FitSizeFeedbackProducts__size-option-buttons {
  margin-bottom: 20px;
}

.FitSizeFeedbackProductDialog__fit-score-count {
  color: $color-title-text-light;
}
</style>

<i18n locale="ko">
{
  "fit_size_feedback_product_dialog": {
    "title": "피드백 현황",
    "fit_score_stats": "사이즈 평가 통계",
    "size_feedbacks": "사이즈 피드백 목록",
    "show_size_feedbacks_with_fit_message": "한줄평있는 피드백만 보기",
    "no_size_feedbacks": "수집한 피드백이 없습니다."
  },
  "size_feedback": {
    "last_updated_at": "수집일",
    "user_property": "고객정보",
    "purchased_size": "구매 사이즈",
    "fit_score": "사이즈 평가",
    "fit_message": "한줄평 내용"
  }
}
</i18n>
