var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppSearchbar',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('li',[_c('AppSelect',{attrs:{"options":_vm.widgetAlteredOptions,"placeholder":_vm.$t('searchbar.dispatch_result_placeholder')},on:{"change":_vm.searchResource},model:{value:(_vm.resourceParams.widget_alternated),callback:function ($$v) {_vm.$set(_vm.resourceParams, "widget_alternated", $$v)},expression:"resourceParams.widget_alternated"}})],1)]},proxy:true}])}),_c('AppResourceTable',{attrs:{"rows":_vm.rows,"resources":_vm.widgets,"columns":_vm.columns},on:{"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
var value = ref.value;
return [(column === 'parent_title')?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            message: row.parent_url,
            info: _vm.$t('parent_title_info')
          }),expression:"{\n            message: row.parent_url,\n            info: $t('parent_title_info')\n          }"}]},[_vm._v(" "+_vm._s(value)+" "),_c('AppResourceTableLinkIcon',{on:{"click":function($event){return _vm.openParentUrl(row.parent_url)}}})],1):(column === 'widget_status')?_c('AppBadge',{attrs:{"label":row.badgeLabel,"badge-style":row.badgeStyle,"tooltip":row.badgeTooltip}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }