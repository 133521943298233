var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppContainer',{staticClass:"AnalyticsInsightKeywordsComment"},[_c('AppGrid',{staticClass:"AnalyticsInsightKeywordsComment__grid"},[(_vm.reviewTagsCount)?[_c('div',{staticClass:"AppGrid__col AppGrid__col--6"},[_c('div',{staticClass:"AnalyticsInsightKeywordsComment__grid-title",domProps:{"innerHTML":_vm._s(_vm.$t('comment.title'))}}),_c('div',{staticClass:"AnalyticsInsightKeywordsComment__grid-text"},[_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.$t('comment.text', {
                reviews_count: _vm.reviewsCount,
                review_tags_count: _vm.reviewTagsCount
              })
            )}}),(_vm.noBrandsKeywords)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('comment.no_comparison'))}}):_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.$t('comment.comparison.text', {
                review_tags_sentiments_ratio: _vm.sentimentsRatioString(
                  _vm.myBrandKeywordsSummary
                ),
                suffix: _vm.sentimentsRatioDiffString
              })
            )}})])]),_c('div',{staticClass:"AppGrid__col AppGrid__col--6"},[_c('div',{staticClass:"AnalyticsInsightKeywordsComment__grid-title",domProps:{"innerHTML":_vm._s(_vm.$t('keywords.title'))}}),_c('div',{staticClass:"AnalyticsInsightKeywordsComment__grid-text"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('keywords.text', [_vm.myBrandTop3TotalNames]))}}),(
              _vm.myBrandTop3PositiveNames.length &&
                _vm.myBrandTop3NegativeNames.length
            )?_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.$t('keywords.description', [
                _vm.myBrandTop3PositiveNames,
                _vm.myBrandTop3NegativeNames
              ])
            )}}):_vm._e(),(!_vm.noBrandsKeywords)?[(_vm.myBrandTop3InferiorNames.length)?_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$t('keywords.comparison_alert', [_vm.myBrandTop3InferiorNames])
              )}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('keywords.comparison_okay'))}})]:_vm._e()],2)])]:[_c('div',{staticClass:"AppGrid__col"},[_c('div',{staticClass:"AnalyticsInsightKeywordsComment__grid-title",domProps:{"innerHTML":_vm._s(_vm.$t('comment.title'))}}),_c('div',{staticClass:"AnalyticsInsightKeywordsComment__grid-text",domProps:{"innerHTML":_vm._s(_vm.$t('comment.no_data_text'))}})])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }