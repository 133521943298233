<template>
  <AppDropdownMenuButton
    :tooltip="noTooltip ? null : $t('manage_tooltip.default')"
    v-bind="manageButtonProps"
  />
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
import api from '@/lib/api';
import HttpStatus from '@/enums/HttpStatus';

export default {
  name: 'ReviewReviewManageButton',
  props: {
    review: {
      type: Object,
      required: true
    },
    isManagerReview: {
      type: Boolean,
      default: false
    },
    noTooltip: {
      type: Boolean,
      default: false
    },
    resourceParams: { type: Object, default: () => ({}) }
  },
  computed: {
    ...mapGetters('review', ['isBookmarkedReview']),
    ...mapState('session', ['reviewSettings']),
    manageButtonProps() {
      const review = this.review;
      const menuItems = [];
      if (this.isManagerReview) {
        menuItems.push(
          [this.menuItemPin, this.menuItemNote],
          [
            { label: this.$t('manage.edit_review'), clickHandler: this.edit },
            this.menuItemDelete
          ]
        );
      } else {
        const isBookmarked = this.isBookmarkedReview(review);
        menuItems.push([
          this.menuItemPin,
          {
            label: this.$t(
              isBookmarked ? 'manage.remove_bookmark' : 'manage.set_bookmark'
            ),
            selected: isBookmarked,
            clickHandler: this.bookmark
          },
          this.menuItemNote
        ]);

        if (review.nthReview || review.nthPhotoReview) {
          let label;
          if (review.nthReview && review.nthPhotoReview) {
            label = this.$t('manage.disqualify_nth_review_and_photo_review', {
              nth_review: review.nthReview,
              nth_photo_review: review.nthPhotoReview
            });
          } else if (review.nthReview) {
            label = this.$t('manage.disqualify_first_review');
          } else {
            label = this.$t('manage.disqualify_nth_photo_review', {
              n: review.nthPhotoReview
            });
          }
          menuItems.push([{ label, clickHandler: this.disqualify }]);
        }

        menuItems.push([
          {
            label: this.$t('manage.review_mileage_logs'),
            clickHandler: ({ close }) => {
              this.openDialog([
                'TheLogsDialog',
                {
                  reviewId: this.review.id
                }
              ]);
              close();
            },
            superAdmin: true
          }
        ]);

        const reviewDisplaying =
          review.force_display || (review.display && review.product_visible);
        menuItems.push([
          {
            label: this.$t(
              reviewDisplaying ? 'manage.hide_review' : 'manage.show_review'
            ),
            selected: !reviewDisplaying,
            clickHandler: args =>
              this.updateDisplay({ ...args, display: !reviewDisplaying })
          },
          this.menuItemDelete
        ]);
      }

      return { label: this.$t('app.manage'), menuItems };
    },
    menuItemPin() {
      const review = this.review;
      const isReviewHidden =
        !review.force_display && (!review.display || !review.product_visible);
      return {
        label: this.$t(
          review.pinned ? 'manage.remove_pin' : 'manage.pin_to_top'
        ),
        selected: review.pinned,
        disabled: isReviewHidden,
        tooltip: this.$t('manage_tooltip.pin'),
        clickHandler: review.pinned ? this.unpin : this.pin
      };
    },
    menuItemNote() {
      return {
        label: this.$t('manage.note'),
        selected: this.review.note,
        clickHandler: this.editNote
      };
    },
    menuItemDelete() {
      return {
        label: this.$t('manage.delete_review'),
        style: 'danger',
        clickHandler: this.delete
      };
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('review', ['UPDATE_REVIEW']),
    ...mapMutations('session', ['SET_REVIEW_SETTINGS']),
    ...mapActions('review', [
      'fetchCurrentPageReviews',
      'unpinReview',
      'deleteReview',
      'updateDisplayReview'
    ]),
    pin({ close }) {
      api
        .patch(
          `/review/reviews/${this.review.id}/pin`,
          { sort: this.resourceParams.sort },
          { successMessage: this.$t('manage.pin_success') }
        )
        .then(({ data }) => {
          this.UPDATE_REVIEW(data.review);
          if (data.pinned_reviews.length > 1) {
            this.openDialog([
              'ReviewReviewsPinDialog',
              {
                productName: data.review.product.name,
                orgReviews: data.pinned_reviews
              }
            ]);
          }
        });
      close();
    },
    unpin({ close }) {
      this.unpinReview(this.review.id);
      close();
    },
    editNote({ close }) {
      this.openDialog([
        'ReviewReviewsNoteEditDialog',
        { review: this.review }
      ]).then(eventBus => {
        eventBus.$on('change', review => (this.review = review));
      });
      close();
    },
    disqualify({ close }) {
      const { review_index, photo_review_index } = this.review;
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          width: '440px',
          title: this.$t('manage.disqualify_review_confirm.title'),
          markdownText: this.$t('manage.disqualify_review_confirm.content'),
          closeButtonLabel: this.$t(
            'manage.disqualify_review_confirm.disqualify'
          ),
          cancelButtonLabel: this.$t('manage.disqualify_review_confirm.cancel')
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => {
          api
            .patch(`/review/reviews/${this.review.id}/disqualify`, {
              review_index,
              photo_review_index
            })
            .then(response => {
              if (response.status === HttpStatus.OK)
                this.fetchCurrentPageReviews();
            });
        });
      });
      close();
    },
    updateDisplay({ close, display }) {
      this.updateDisplayReview({
        reviewId: this.review.id,
        display,
        successMessage: this.$t(
          display ? 'manage.show_review_success' : 'manage.hide_review_success'
        )
      }).then(response => {
        if (response.status === HttpStatus.OK) this.fetchCurrentPageReviews();
      });
      close();
    },
    edit({ close }) {
      close();
      this.openDialog([
        'ReviewReviewFormDialog',
        {
          reviewId: this.review.id,
          subTitle: this.$t('sub_title', [this.formatUser(this.review)]),
          focusGroupId: 'user_name'
        }
      ]);
    },
    delete({ close }) {
      if (!confirm(this.$t('manage.delete_review_confirmation'))) return;

      this.deleteReview({
        reviewId: this.review.id,
        resourceParams: this.resourceParams,
        successMessage: this.$t('manage.delete_review_success')
      }).then(() => this.$emit('delete'));
      close();
    },
    bookmark({ close }) {
      const review_id = this.isBookmarkedReview(this.review)
        ? null
        : this.review.id;
      api
        .patch(
          '/review/bookmark',
          { review_id },
          {
            successMessage: this.$t(
              review_id
                ? 'manage.set_bookmark_success'
                : 'manage.remove_bookmark_success'
            )
          }
        )
        .then(() => {
          this.SET_REVIEW_SETTINGS({
            ...this.reviewSettings,
            bookmarked_review_id: review_id
          });
        });
      if (close) close();
    }
  }
};
</script>

<i18n locale="ko">
{
  "sub_title": "{0}님의 리뷰",
  "manage": {
    "pin_to_top": "추천 리뷰 지정",
    "pin_success": "추천 리뷰 지정을 완료했습니다.",
    "remove_pin": "추천 리뷰 해제",
    "set_bookmark": "책갈피 설정",
    "remove_bookmark": "책갈피 제거",
    "set_bookmark_success": "책갈피를 설정했습니다.",
    "remove_bookmark_success": "책갈피를 제거했습니다.",
    "note": "메모 (리뷰 관리를 위한 메모 작성)",
    "review_mileage_logs": "적립금 관련 이력 보기",
    "disqualify_nth_review_and_photo_review": "선착순 리뷰에서 제외",
    "disqualify_first_review": "최초 작성 리뷰에서 제외",
    "disqualify_nth_photo_review": "선착순 리뷰에서 제외",
    "disqualify_review_confirm": {
      "title": "선착순 리뷰 적립금 대상에서 제외하시겠습니까?",
      "content": "선택된 리뷰에 선착순 리뷰 적립금을 지급하지 않습니다. <br>(이미 지급된 적립금은 회수되지 않으니 직접 차감해주세요)",
      "disqualify": "제외",
      "cancel": "취소"
    },
    "edit_review": "리뷰 수정",
    "hide_review": "리뷰 숨김",
    "hide_review_success": "리뷰를 숨김 처리했습니다.",
    "show_review": "리뷰 숨김 해제",
    "show_review_success": "리뷰 숨김을 해제했습니다.",
    "delete_review": "리뷰 삭제",
    "delete_review_confirmation": "삭제하면 복구가 불가능합니다. 정말로 삭제하시겠습니까?",
    "delete_review_success": "리뷰를 삭제했습니다."
  },
  "manage_tooltip": {
    "default": "버튼을 클릭해 숨김, 삭제, 메모, 추천 리뷰 지정 등\n리뷰 관리를 할 수 있습니다.",
    "pin": "위젯에서 추천순 정렬 시, [쇼핑몰 추천 리뷰] 표시와 함께 해당 리뷰를 최상단에 노출합니다."
  }
}
</i18n>
<i18n locale="en">
{
  "sub_title": "Review by {0}",
  "manage": {
    "pin_to_top": "Pin to top",
    "pin_success": "Success to pin",
    "remove_pin": "Remove pin",
    "set_bookmark": "Set bookmark",
    "remove_bookmark": "Remove bookmark",
    "note": "Note",
    "review_mileage_logs": "Mileage logs",
    "disqualify_nth_review_and_photo_review": "Exclude from 'Reviews by order'",
    "disqualify_first_review": "Exclude from the 1st review",
    "disqualify_nth_photo_review": "Exclude from 'Reviews by order'",
    "disqualify_review_confirm": {
      "title": "Do you want to exclude this review from 'Rewards for reviews by order'?",
      "content": "This review will not be rewarded for its submitted order. <br>(Given rewards will not be cancelled. Please cancel the rewards manually if you want.)",
      "disqualify": "Exclude",
      "cancel": "Cancel"
    },
    "edit_review": "Edit review",
    "hide_review": "Hide review",
    "hide_review_success": "Success to hide review.",
    "show_review": "Show review",
    "show_review_success": "Success to show review.",
    "delete_review": "Delete review",
    "delete_review_confirmation": "Once deleted, recovery is not possible. Are you sure?",
    "delete_review_success": "Success to delete review."
  },
  "manage_tooltip": {
    "default": "You can click the button to manage reviews,\nsuch as hide, delete, note, or pin reviews.",
    "pin": "When sorting in order of recommendation on the widget, [Shopping mall recommended review] is displayed and the review is displayed at the top."
  }
}
</i18n>
