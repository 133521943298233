import FitOptionKeyType from '@/enums/FitOptionKeyType';

export default [
  {
    id: 'fit_size',
    type: 'sub_menu',
    path: '/fit/size/products',
    parentId: 'fit'
  },
  {
    id: 'fit_size_products',
    type: 'sub_menu',
    path: '/fit/size/products',
    parentId: 'fit_size'
  },
  {
    id: 'fit_size_products_all',
    type: 'tab',
    path: '/fit/size/products',
    parentId: 'fit_size_products'
  },
  {
    id: 'fit_size_products_none',
    type: 'tab',
    path: '/fit/size/products?tab=none',
    parentId: 'fit_size_products'
  },
  {
    id: 'fit_size_products_partial',
    type: 'tab',
    path: '/fit/size/products?tab=partial',
    parentId: 'fit_size_products'
  },
  {
    id: 'fit_size_products_full',
    type: 'tab',
    path: '/fit/size/products?tab=full',
    parentId: 'fit_size_products'
  },
  {
    id: 'fit_size_products_disable',
    type: 'tab',
    path: '/fit/size/products?tab=disable',
    parentId: 'fit_size_products'
  },
  {
    id: 'fit_size_presets',
    type: 'sub_menu',
    path: '/fit/size/presets',
    parentId: 'fit_size'
  },
  {
    id: 'fit_size_presets_all',
    type: 'tab',
    path: '/fit/size/presets',
    parentId: 'fit_size_presets'
  },
  {
    id: 'fit_size_presets_matched',
    type: 'tab',
    path: '/fit/size/presets?tab=matched',
    parentId: 'fit_size_presets'
  },
  {
    id: 'fit_size_presets_unmatched',
    type: 'tab',
    path: '/fit/size/presets?tab=unmatched',
    parentId: 'fit_size_presets'
  },
  {
    id: 'fit_size_csvs',
    type: 'sub_menu',
    path: '/fit/size/csvs',
    parentId: 'fit_size'
  },
  {
    id: 'fit_size_feedback_products',
    type: 'sub_menu',
    path: '/fit/size_feedback/products',
    parentId: 'fit'
  },
  {
    id: 'fit_reports',
    type: 'sub_menu',
    path: '/fit/reports',
    parentId: 'fit'
  },
  {
    id: 'fit_reports_main_kpis',
    type: 'tab',
    path: '/fit/reports',
    parentId: 'fit_reports'
  },
  {
    id: 'fit_reports_size_feedbacks',
    type: 'tab',
    path: '/fit/reports/size_feedbacks',
    parentId: 'fit_reports'
  },
  {
    id: 'fit_reports_return_rates',
    type: 'tab',
    path: '/fit/reports/return_rates',
    parentId: 'fit_reports',
    superAdmin: true
  },
  {
    id: 'fit_templates',
    type: 'sub_menu',
    path: '/fit/templates',
    parentId: 'fit'
  },
  {
    id: 'fit_display_names',
    type: 'sub_menu',
    path: '/fit/display_names',
    parentId: 'fit'
  },
  {
    id: 'fit_display_names_size',
    type: 'tab',
    path: '/fit/display_names',
    parentId: 'fit_display_names'
  },
  {
    id: 'fit_display_names_info',
    type: 'tab',
    path: `/fit/display_names?tab=${FitOptionKeyType.INFO}`,
    parentId: 'fit_display_names'
  },
  {
    id: 'fit_display_names_property',
    type: 'tab',
    path: `/fit/display_names?tab=${FitOptionKeyType.PROPERTY}`,
    parentId: 'fit_display_names'
  },
  {
    id: 'fit_settings',
    type: 'sub_menu',
    path: '/fit/settings',
    parentId: 'fit'
  },
  {
    id: 'fit_settings_basic',
    type: 'tab',
    path: '/fit/settings',
    parentId: 'fit_settings'
  },
  {
    id: 'fit_settings_review_options_info',
    type: 'tab',
    path: '/fit/settings/review_options_info',
    parentId: 'fit_settings'
  },
  {
    id: 'fit_settings_widgets',
    type: 'tab',
    path: '/fit/settings/widgets',
    parentId: 'fit_settings'
  },
  {
    id: 'fit_settings_fit_product_api',
    type: 'tab',
    path: '/fit/settings/fit_product_api',
    parentId: 'fit_settings'
  },
  {
    id: 'fit_settings_admin',
    type: 'tab',
    path: '/fit/settings/admin',
    parentId: 'fit_settings',
    superAdmin: true
  },
  {
    id: 'fit_intro',
    type: 'sub_menu',
    path: '/fit/intro',
    parentId: 'fit',
    group: 1
  }
].map(item => {
  if (item.id === 'fit_intro') return item;

  return { ...item, isAccessible: getters => getters.isFitAccessible };
});
