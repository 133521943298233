<template>
  <AppModalDefault :title="$t('title')" width="middle" @close="close">
    <!-- eslint-disable vue/no-v-html -->
    <template #body>
      <AppInfoBox>
        <span>{{ $t('info_box_text_1') }}</span
        ><br />
        <span>{{ $t('info_box_text_2') }}</span
        ><br /><br />
        <span>
          {{ $t('info_box_text_3-1') }}
          <u
            ><a @click="click">{{ $t('info_box_text_3-2') }}</a></u
          >{{ $t('info_box_text_3-3') }}
        </span>
      </AppInfoBox>
      <div class="TheAiCommentHelpDialog__svg-icon-container">
        <AppSvgIcon
          class="TheAiCommentHelpDialog__svg-icon"
          name="sticker-analytics"
        />
      </div>
      <div class="TheAiCommentHelpDialog__summary">
        <div v-html="$t('summary_text', [applicationFormUrl])" />
      </div>
      <div
        v-for="(desc, i) in descriptions"
        :key="i"
        class="TheAiCommentHelpDialog__description-container"
      >
        <span class="TheAiCommentHelpDialog__description-title">
          {{ $t(`${desc}.title`) }}
        </span>
        <div class="TheAiCommentHelpDialog__description">
          <video
            v-tooltip="$t('description_video_tooltip')"
            class="TheAiCommentHelpDialog__description-video"
            playsinline
            autoplay
            loop
            muted
            @click="openEnlargedVideoDialog(descriptionVideoUrl(i + 1))"
          >
            <source :src="descriptionVideoUrl(i + 1)" type="video/mp4" />
          </video>
          <div
            class="TheAiCommentHelpDialog__description-text"
            v-html="$t(`${desc}.description`)"
          />
        </div>
      </div>
    </template>
    <!-- eslint-enable vue/no-v-html -->
    <template #foot>
      <AppExternalLink
        class="TheAiCommentHelpDialog__form-link"
        :to="applicationFormUrl"
      >
        <AppButton
          button-style="blue"
          :label="$t('apply_for_official_release')"
        />
      </AppExternalLink>
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapActions } from 'vuex';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'TheAiCommentHelpDialog',
  mixins: [DialogView],
  data() {
    return {
      applicationFormUrl: 'https://forms.gle/qgaFqav1uZr3GBuH7',
      descriptions: [
        'how_to_use_ai_comment',
        'comment_auto_generate',
        'comment_enrichment',
        'comment_auto_correct'
      ]
    };
  },
  watch: {
    $route() {
      this.openDialogFromHash();
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'openDialogFromHash']),
    descriptionVideoUrl(index) {
      return `https://assets.cre.ma/m/admin/ai_comment_0${index}.mp4`;
    },
    openEnlargedVideoDialog(url) {
      this.openDialog(['TheEnlargedVideoDialog', { title: '', url }]);
    },
    click() {
      this.openDialog('ThelaboratoryDialog');
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheAiCommentHelpDialog__svg-icon-container {
  margin-top: 20px;
  text-align: center;
}

.TheAiCommentHelpDialog__svg-icon {
  stroke: transparent;
}

.TheAiCommentHelpDialog__summary {
  margin-bottom: 30px;
}

.TheAiCommentHelpDialog__description-container {
  margin-top: 20px;
}

.TheAiCommentHelpDialog__description-title {
  @include text-sub-title;
}

.TheAiCommentHelpDialog__description {
  margin-top: 8px;
  display: flex;
}

.TheAiCommentHelpDialog__description-video {
  width: 394px;
  cursor: pointer;
}

.TheAiCommentHelpDialog__description-text {
  margin-left: 30px;
  flex-grow: 1;
}

.TheAiCommentHelpDialog__form-link {
  margin-right: 8px;
}
</style>

<i18n locale="ko">
{
  "title": "AI 댓글 이란?",
  "info_box_text_1": "정식 출시 전 실험실 기능으로, 직접 체험할 수 있도록 500회 무료 사용 횟수를 충전해 드렸습니다.",
  "info_box_text_2": "개발 중인 기능이므로 언제나 기능의 추가 및 변경이 있을 수 있으며 상황에 따라 동작이 불안정할 수 있습니다.",
  "info_box_text_3-1": "AI 댓글 기능을 사용하지 않으려면, ",
  "info_box_text_3-2": "여기를 클릭",
  "info_box_text_3-3": "하여 AI 댓글 사용 설정을 해제해 주세요.",
  "summary_text": "<b>고객이 작성한 리뷰 본문을 기반</b>으로 적절한 <b>댓글을 자동으로 작성</b>하거나, 관리자가 <b>작성한 댓글을 개선</b>합니다.<br>AI 댓글 기능을 사용해 높은 퀄리티의 댓글을 쉽고 빠르게 작성해 고객 만족도를 높여 보세요.<br><br>기능이 마음에 든다면 <a href='{0}' target='_blank'><u>정식 출시 대기 신청</u></a>을 해주세요. 정식 출시 후 우선 적용 및 무료 사용 기간 등 혜택을 드립니다.",
  "description_video_tooltip": "이미지를 클릭하면 크게 볼 수 있습니다.",
  "how_to_use_ai_comment": {
    "title": "위치 및 사용 방법",
    "description": "1. <b>리뷰 및 적립금 주기</b> 메뉴로 이동하세요.<br>2. <b>리뷰 상세 내용</b>을 클릭해 상세 팝업을 열어주세요.<br>3. 팝업 하단 <b>댓글 작성</b> 버튼을 클릭해 주세요.<br>4. <b>AI 댓글</b> 버튼을 클릭 해 주세요.<br>5. AI 댓글 기능 중 <b>원하는 기능을 클릭</b>해 주세요."
  },
  "comment_auto_generate": {
    "title": "댓글 자동 작성",
    "description": "<b>고객별 맞춤 댓글, 쉽게 시작하세요!</b><br><br>댓글 자동 작성 버튼을 클릭하면, AI가 <b>리뷰 본문을 분석해 댓글 초안을 자동으로 작성</b>합니다.<br><br>관리자 의도에 부합하면 그대로 작성을 완료하세요. 개선할 부분이 있다면 직접 수정한 후 작성 완료하세요."
  },
  "comment_enrichment": {
    "title": "입력한 문장 개선",
    "description": "<b>문장 개선 기능으로 원하는 내용을 빠르고 완벽하게!</b><br><br>핵심 키워드만 입력하면 완성은 AI가 책임집니다. 원하는 내용으로 고객별 맞춤 댓글을 작성할 수 있습니다.<br><br>문장에 자신 없어도 클릭 한 번에 완성도 높은 댓글로 만들어 드려요."
  },
  "comment_auto_correct": {
    "title": "맞춤법 및 문법 개선",
    "description": "<b>맞춤법 및 문법 개선 기능으로 전문성을 더하세요!</b><br><br>정성스럽게 작성한 댓글에 잘못된 맞춤법이 없도록 <b>AI 댓글이 수정</b>해 드립니다."
  },
  "apply_for_official_release": "정식 출시 대기 신청"
}
</i18n>
