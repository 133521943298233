<template>
  <div
    v-tooltip="tooltip"
    :class="[
      'AppAccordion',
      isExpanded ? 'AppAccordion--expanded' : null,
      isCompleted ? 'AppAccordion--completed' : null,
      disabled ? 'AppAccordion--disabled' : null
    ]"
  >
    <div class="AppAccordion__contents-container">
      <div
        class="AppAccordion__toggle-button-container"
        @click="toggleAccordion"
      >
        <div class="AppAccordion__icon-container">
          <AppSvgIcon
            :class="[
              'AppAccordion__arrow-icon',
              isExpanded ? 'AppAccordion__arrow-icon--up' : null
            ]"
            name="nav-chevron-down"
            :height="14"
          />
        </div>
        <div>
          <div class="AppAccordion__title">
            <div>{{ title }}</div>
            <AppSvgIcon
              v-show="isCompleted"
              class="AppAccordion__check-icon"
              :height="20"
              name="icon-check-tiny-checked"
            />
          </div>
          <div class="AppAccordion__sub-title">
            {{ subTitleText }}
          </div>
        </div>
      </div>
      <TransitionExpand>
        <div v-show="isExpanded" class="AppAccordion__items">
          <div class="AppAccordion__items-content">
            <Component
              :is="componentName"
              v-if="componentName"
              :is-loading="isLoading"
              :is-completed="isCompleted"
              @submitted="toggleCurrentStepCompletion"
              @loaded="clearLoading"
              @errored="changeToggleButtonDisabled($event)"
            />
            <slot v-else />
            <div v-if="buttonVisible" class="AppAccordion__button-box">
              <AppButton
                :disabled="toggleButtonDisabled"
                :label="toggleButtonLabel"
                :button-style="toggleButtonStyle"
                @click.stop="toggleButtonClicked"
              />
            </div>
          </div>
        </div>
      </TransitionExpand>
    </div>
  </div>
</template>

<script>
import TransitionExpand from '@/transitions/TransitionExpand';

export default {
  name: 'AppAccordion',
  components: { TransitionExpand },
  props: {
    title: { type: String, required: true },
    subTitle: { type: String, default: null },
    completeSubTitle: { type: String, default: null },
    componentName: { type: String, default: null },
    defaultExpanded: { type: Boolean, default: false },
    isCompleted: { type: Boolean, default: false },
    type: {
      type: String,
      default: 'default',
      validator: v => ['default', 'dataEntry', 'check'].includes(v)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledTooltip: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isExpanded: this.defaultExpanded,
      isLoading: false,
      toggleButtonDisabled: false
    };
  },
  computed: {
    subTitleText() {
      return this.completeSubTitle && this.isCompleted
        ? this.completeSubTitle
        : this.subTitle;
    },
    toggleButtonLabel() {
      if (this.isCompleted) return this.$t('button.change');

      return this.$t('button.confirmed');
    },
    toggleButtonStyle() {
      return this.isCompleted ? 'grey' : 'blue';
    },
    nextButtonStyle() {
      return this.isCompleted ? 'default' : 'blue';
    },
    buttonVisible() {
      switch (this.type) {
        case 'dataEntry':
          return true;
        case 'check':
          return !this.isCompleted;
        default:
          return false;
      }
    },
    tooltip() {
      if (this.disabled)
        return {
          message: this.disabledTooltip,
          alignLeft: true
        };

      return null;
    }
  },
  watch: {
    isCompleted() {
      if (this.isCompleted) {
        this.isExpanded = false;
      }
    }
  },
  methods: {
    toggleAccordion() {
      if (this.disabled) return;

      this.isExpanded = !this.isExpanded;
    },
    setLoading() {
      this.isLoading = true;
    },
    clearLoading() {
      this.isLoading = false;
    },
    changeToggleButtonDisabled(buttonDisabled) {
      this.toggleButtonDisabled = buttonDisabled;
    },
    toggleCurrentStepCompletion() {
      if (this.isCompleted) this.$emit('toggleCompletion', false);
      else this.$emit('toggleCompletion', true);
    },
    toggleButtonClicked() {
      this.componentName
        ? this.setLoading()
        : this.toggleCurrentStepCompletion();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.AppAccordion {
  display: flex;
  border: solid 1px $color-grey-25;
  border-radius: 4px;
  transition: border-color 500ms ease-out;

  &:hover,
  &--expanded {
    border-color: $color-grey-35;

    .AppAccordion__title {
      color: $color-grey-80;
    }
  }

  &--expanded {
    box-shadow: 0 2px 4px 0 rgba(15, 15, 15, 0.06);
  }

  &--completed,
  &--completed:hover {
    color: $color-grey-40;

    .AppAccordion__title,
    .AppAccordion__sub-title {
      color: $color-grey-40;
    }
  }

  &--disabled,
  &--disabled:hover {
    border: solid 1px $color-grey-15;

    .AppAccordion__title,
    .AppAccordion__sub-title {
      color: $color-grey-40;
    }

    .AppAccordion__arrow-icon {
      color: $color-grey-25;
    }
  }
}

.AppAccordion__contents-container {
  text-align: left;
  width: 100%;
}

.AppAccordion__icon-container {
  width: 20px;
  display: flex;
  justify-content: center;
  margin-right: 16px;
}

.AppAccordion__toggle-button-container {
  display: flex;
  padding: 16px 20px;

  &:hover {
    cursor: pointer;
  }
}

.AppAccordion__title {
  @include text-sub-title;
  display: flex;
  align-items: center;
  color: $color-grey-60;
  white-space: nowrap;
  transition: color 500ms ease-out;
}

.AppAccordion__sub-title {
  @include text-caption;
  color: $color-grey-50;
  white-space: nowrap;
  margin-top: 4px;
  transition: color 500ms ease-out;
}

$accordion-title-height: 24px;
$accordion-arrow-icon-height: 14px;

.AppAccordion__arrow-icon {
  position: relative;
  top: ($accordion-title-height - $accordion-arrow-icon-height) * 0.5;
  color: $color-grey-40;

  &--up {
    transform: rotate(180deg);
  }
}

.AppAccordion__items-content {
  margin-left: 36px;
  padding: 0 20px 16px 20px;
}

.AppAccordion__button-box {
  margin-top: 24px;
}

.AppAccordion__check-icon {
  margin-left: 8px;
  color: $color-mint-green;
  stroke-width: 0;
  background: $color-mint-green-light;
  border-radius: 50%;
}

::v-deep .AppForm__group {
  & + .AppForm__group {
    margin-top: 16px !important;
  }
}
</style>

<i18n locale="ko">
  {
    "button" : {
      "confirmed": "확인",
      "change" : "변경",
      "next" : "다음",
      "prev" : "이전"
    }
  }
</i18n>
