<template>
  <div v-if="isMainMenuReview && isUsingAiComment" class="SubMenuAiComment">
    <div class="SubMenuAiComment__title">
      <span>{{ $t('title') }}</span>
      <AppBadge
        class="SubMenuAiComment__title-badge"
        :label="$t('app.beta')"
        badge-style="red"
        badge-size="small"
      />
    </div>
    <div class="SubMenuAiComment__body">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="SubMenuAiComment__message"
        v-html="
          remainingAmount
            ? $t('body_message', [remainingAmount])
            : $t('body_message_when_disabled')
        "
      />
      <!-- eslint-enable vue/no-v-html -->
      <div class="SubMenuAiComment__graph">
        <div
          class="SubMenuAiComment__graph-remaining"
          :style="{ transform: `scaleX(${remainingRate})` }"
        />
      </div>
      <template v-if="remainingAmount">
        <span class="SubMenuAiComment__link" @click="openAiCommentHelpDialog">
          {{ $t('ai_review_comment_help') }}
        </span>
      </template>
      <template v-else>
        <AppExternalLink
          class="SubMenuAiComment__link"
          to="https://forms.gle/qgaFqav1uZr3GBuH7"
          >{{ $t('apply_for_official_release') }}</AppExternalLink
        >
        <span class="SubMenuAiComment__link" @click="turnOffAiCommentSetting">{{
          $t('app.close')
        }}</span>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';

export default {
  name: 'SubMenuAiComment',
  computed: {
    ...mapState('session', ['laboratory']),
    ...mapGetters('menu', ['selectedMainMenuItemId']),
    isMainMenuReview() {
      return this.selectedMainMenuItemId === 'review';
    },
    isUsingAiComment() {
      return this.laboratory.ai_review_comment;
    },
    givenAmount() {
      return this.laboratory.ai_review_comment_given_amount;
    },
    usedAmount() {
      return this.laboratory.ai_review_comment_used_amount;
    },
    remainingAmount() {
      // 무제한 사용 가능 시 표시 방법은 디자인팀 의견 대기 중으로 우선 9999 로 표시
      return this.givenAmount ? this.givenAmount - this.usedAmount : 999999;
    },
    remainingRate() {
      return this.givenAmount ? this.remainingAmount / this.givenAmount : 100;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('session', ['updateLaboratory']),
    openAiCommentHelpDialog() {
      this.openDialog('TheAiCommentHelpDialog');
    },
    turnOffAiCommentSetting() {
      api
        .patch('/laboratory', {
          laboratory: { ai_review_comment: false }
        })
        .then(() => this.updateLaboratory({ ai_review_comment: false }));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.SubMenuAiComment {
  width: 100%;
  padding: 20px 17px;
  background-color: white;
}

.SubMenuAiComment__title {
  @include text-label;
}

.SubMenuAiComment__title-badge {
  margin-left: 4px;
}

.SubMenuAiComment__body {
  margin-top: 4px;
}

.SubMenuAiComment__message {
  @include text-caption-dark;
}

.SubMenuAiComment__graph {
  height: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: $color-grey-15;
}

.SubMenuAiComment__graph-remaining {
  transform-origin: center left;
  height: 100%;
  background-color: $color-mint-green;
}

.SubMenuAiComment__link {
  @include text-caption;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  & + & {
    margin-left: 8px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "CREMA AI 댓글",
  "body_message": "AI댓글을 앞으로<br>{0}회 사용할 수 있습니다.",
  "body_message_when_disabled": "AI댓글을 모두 사용하였습니다.",
  "ai_review_comment_help": "AI 댓글 이란?",
  "apply_for_official_release": "정식 출시 대기 신청"
}
</i18n>
