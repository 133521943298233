<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppButton label="Start Progress" @click="progress(100, 15)" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Sparse</label>
      </div>
      <AppButton label="Start Sparse Progress" @click="progress(6, 250)" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UiProgress',
  methods: {
    ...mapActions('dialog', ['openDialog']),
    progress(totalCount, delay) {
      this.openDialog([
        'AppProgressDialog',
        { title: 'Progress', totalCount }
      ]).then(async eventBus => {
        for (let i = 0; i < totalCount; ++i) {
          await new Promise(resolve =>
            setTimeout(() => {
              eventBus.$emit('progress');
              resolve();
            }, delay)
          );
        }
      });
    }
  }
};
</script>
