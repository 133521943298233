<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #right>
        <li>
          <AppSelect
            v-model="resourceParams.payment_status"
            :options="[
              { label: $t('payment_status.success'), value: 'success' },
              { label: $t('payment_status.pending'), value: 'pending' },
              { label: $t('payment_status.failure'), value: 'failure' }
            ]"
            :placeholder="$t('payment_status.all')"
            @change="searchResource"
          />
        </li>
        <li v-if="isSuperAdminAccessible" class="super-admin__item">
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="SEARCH_TYPES"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppResourceTable
      table-id="payments-table"
      :resources="payments"
      :rows="rows"
      :columns="COLUMNS"
      :enable-total-count="false"
      :no-data="$t('no_data')"
      @paginate="paginate"
    >
      <template #cell="{ row, column }">
        <template v-if="column === 'payment_method'">
          <div>{{ PaymentMethod.t(row.payment_method) }}</div>
          <AppButton
            v-if="row.payment_method === PaymentMethod.BANK"
            class="mt4"
            :label="$t('bank_info_button')"
            :disabled="
              row.payment_status !== PaymentStatus.PENDING_BANK_REQUESTED
            "
            @click="
              openDialog(['ThePaymentBankInfoDialog', { paymentId: row.id }])
            "
          />
          <template v-else-if="row.payment_method === PaymentMethod.VACCOUNT">
            <div
              v-if="
                row.payment_status === PaymentStatus.PENDING_VACCOUNT_REQUESTED
              "
              class="ThePaymentHistoryDialogPayments__vaccount"
            >
              {{ row.danal_vaccount }}
              {{
                $t('vaccount_expires_at', [
                  formatDateTime(row.danal_vaccount_expires_at)
                ])
              }}
            </div>
            <AppButton
              class="mt4"
              :label="$t('vaccount_info_button')"
              :disabled="isVaccountInfoDisabled(row.payment_status)"
              @click="openPaymentVaccountInfoDialog(row.id)"
            />
          </template>
        </template>
        <template v-else-if="column === 'payment_status'">
          <AppBadge
            :tooltip="
              row.payment_status_short === 'success'
                ? formatDateTime(row.paid_at)
                : row.payment_method === PaymentMethod.VACCOUNT &&
                  row.payment_status_short === 'pending'
                ? $t('vaccount_pending_tooltip')
                : `${PaymentStatus.t(row.payment_status)}${
                    row.payment_failure_msg
                  }`
            "
            :label="$t(`payment_status.${row.payment_status_short}`)"
            :badge-style="
              row.payment_status_short === 'pending'
                ? 'mint-green'
                : row.payment_status_short === 'success'
                ? 'default'
                : 'red'
            "
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import PaymentMethod from '@/enums/PaymentMethod';
import PaymentStatus from '@/enums/PaymentStatus';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

function paymentStatusShort(paymentStatus) {
  switch (paymentStatus) {
    case PaymentStatus.PENDING_BANK_REQUESTED:
    case PaymentStatus.PENDING_VACCOUNT_REQUESTED:
    case PaymentStatus.NOT_PAID_PROCESSED_PAYMENT:
      return 'pending';
    case PaymentStatus.SUCCESS_PAID:
    case PaymentStatus.SUCCESS_PAID_NOT_SHIPPED:
      return 'success';
    default:
      return 'failure';
  }
}

export default {
  name: 'ThePaymentHistoryDialogPayments',
  mixins: [ResourceViewNoRoute],
  data() {
    return {
      COLUMNS: [
        {
          id: 'date',
          label: this.$t('table.payment_request_date'),
          type: 'datetime',
          width: '156px'
        },
        {
          id: 'id',
          label: this.$t('table.payment_id'),
          type: 'string',
          width: '92px',
          superAdmin: true
        },
        {
          id: 'product_name',
          label: this.$t('table.product_name'),
          align: 'left',
          width: '170px',
          type: 'html'
        },
        {
          id: 'payment_amount',
          label: this.$t('table.payment_amount'),
          type: 'number',
          unit: 'currency.krw',
          align: 'right',
          width: '108px'
        },
        {
          id: 'payment_method',
          label: this.$t('table.payment_method'),
          width: '242px'
        },
        { id: 'payment_status', label: this.$t('app.status'), width: '92px' }
      ],
      SEARCH_TYPES: ['payment_id'].map(value => ({
        value,
        label: this.$t(`payment_search_type.${value}`)
      })),
      isLoading: true,
      payments: nullResources
    };
  },
  computed: {
    ...mapGetters('payment', ['packageNameSpecialOfferPostfix']),
    ...mapGetters(['isSuperAdminAccessible']),
    defaultResourceParams: () => ({
      search_type: 'payment_id'
    }),
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    PaymentMethod() {
      return PaymentMethod;
    },
    PaymentStatus() {
      return PaymentStatus;
    },
    rows() {
      return this.payments.items.map(item => ({
        ...item,
        date: item.created_at,
        product_name: item.packages
          .map(p => ({
            ...p,
            parent_package_name: p.parent_package
              ? this.packageNameSpecialOfferPostfix(p.parent_package)
              : null
          }))
          .map(p => this.packageNameSpecialOfferPostfix(p))
          .join('<br />'),
        payment_amount: item.price,
        payment_status_short: paymentStatusShort(item.payment_status),
        payment_failure_msg: this.paymentFailureMessage(item)
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/payment_history/payments', { params })
        .then(({ data }) => (this.payments = data.payments))
        .finally(() => (this.isLoading = false));
    },
    isVaccountInfoDisabled(paymentStatus) {
      return ![
        PaymentStatus.PENDING_VACCOUNT_REQUESTED,
        PaymentStatus.SUCCESS_PAID
      ].includes(paymentStatus);
    },
    openPaymentVaccountInfoDialog(paymentId) {
      this.openDialog([
        'ThePaymentVaccountInfoDialog',
        { paymentId }
      ]).then(eventBus => eventBus.$on('refresh', this.refreshResource));
    },
    paymentFailureMessage(item) {
      if (
        paymentStatusShort(item.payment_status) === 'failure' &&
        item.danal_returnmsg
      )
        return `<br />${item.danal_returnmsg}`;
      else return '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ThePaymentHistoryDialogPayments__vaccount {
  @include text-caption;
}
</style>

<i18n locale="ko">
{
  "payment_status": {
    "all": "전체 내역",
    "success": "결제 완료",
    "pending": "결제 대기중",
    "failure": "결제 실패"
  },
  "table": {
    "payment_id": "결제 ID",
    "payment_request_date": "결제 요청일",
    "product_name": "결제 상품",
    "payment_amount": "결제 금액",
    "payment_method": "결제 방법"
  },
  "payment_search_type": {
    "payment_id": "결제 ID"
  },
  "no_data": "결제내역이 없습니다.",
  "bank_info_button": "입금정보",
  "vaccount_info_button": "결제 정보",
  "vaccount_expires_at": "~{0} 까지",
  "vaccount_pending_tooltip": "입금이 확인되면\n자동으로 서비스가 활성화됩니다."
}
</i18n>
