<template>
  <AppModalDefault :title="$t('title')" @close="close">
    <template #body>
      <AppDataList :data="BOX_DATA">
        <template #value="datum">
          <template v-if="datum.id === 'issue_info'">
            <div>
              <pre>{{ $t('box.issue_info.description') }}</pre>
            </div>
            <div class="mt4">
              <AppImage
                src="https://assets.cre.ma/m/sample_telecom_cert_v1.png"
              />
            </div>
          </template>
        </template>
      </AppDataList>
    </template>
    <template #foot>
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'ThePhoneChangeInfoDialog',
  mixins: [DialogView],
  data() {
    return {
      BOX_DATA: [
        {
          label: this.$t('box.telecom_cert.label'),
          value: this.$t('box.telecom_cert.description'),
          type: 'pre'
        },
        { id: 'issue_info', label: this.$t('box.issue_info.label') }
      ]
    };
  }
};
</script>

<i18n locale="ko">
{
  "title": "발급 방법 안내",
  "box": {
    "telecom_cert": {
      "label": "통신서비스 이용증명원(가입증명서)이란?",
      "description": "이용자 본인이 사용하는 전화번호를 증명하는 서류입니다.\n상점 또는 대표자 명의로 가입한 휴대폰/유선번호 통신사에서 발급가능합니다."
    },
    "issue_info": {
      "label": "발급 방법",
      "description": "휴대폰/유선번호 가입 통신사 사이트 방문 > 가입정보 조회/관리 > 통신 서비스 이용증명서 발급\n(발급시 '가려진 정보보기'를 설정해주세요)\n\n예시"
    }
  }
}
</i18n>
