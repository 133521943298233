<template>
  <div class="ReviewImportAdminKakaoStyle">
    <AppAjaxContent :is-loading="isLoading">
      <template #default>
        <AppForm
          object-id="kakao_style_settings"
          v-bind="formProps"
          submit-button
          v-on="formEvents"
        >
          <template #group="{ id }">
            <template v-if="id === 'trigger_review_import'">
              <div class="AppForm__group-field">
                <AppButton
                  v-tooltip="triggerReviewImportTooltip"
                  :label="$t('trigger_review_import.label')"
                  :disabled="isReviewImportDisabled || isTriggeringReviewImport"
                  @click="onClickTriggerReviewImport"
                />
              </div>
            </template>
          </template>
        </AppForm>
      </template>
    </AppAjaxContent>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import KakaoStyleReviewImportStatus from '@/enums/KakaoStyleReviewImportStatus';

export default {
  name: 'ReviewImportAdminKakaoStyle',
  mixins: [FormView],
  data() {
    return { isLoading: true, isTriggeringReviewImport: false };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('kakaoStyle', ['isReviewImportDisabled']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'review_import_status',
              label: this.$t('review_import_status.label'),
              type: 'select',
              options: KakaoStyleReviewImportStatus.options()
            },
            {
              id: 'trigger_review_import',
              label: this.$t('trigger_review_import.label'),
              hint: this.$t('trigger_review_import.hint', {
                brand: this.currentBrand.name
              }),
              type: 'custom'
            }
          ]
        }
      ];
    },
    triggerReviewImportTooltip() {
      return this.isReviewImportDisabled
        ? this.$t('trigger_review_import.tooltip')
        : null;
    }
  },
  mounted() {
    api
      .get('kakao_style/admin_settings')
      .then(({ data }) => {
        this.orgFormObject = data.kakao_style_settings;
        this.SET_REVIEW_IMPORT_STATUS(this.orgFormObject.review_import_status);
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('kakaoStyle', ['SET_REVIEW_IMPORT_STATUS']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('kakao_style/admin_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = { ...this.formObject };
          this.SET_REVIEW_IMPORT_STATUS(
            this.orgFormObject.review_import_status
          );
        })
        .finally(() => (this.isSubmitting = false));
    },
    onClickTriggerReviewImport() {
      this.isTriggeringReviewImport = true;
      api
        .post(
          'kakao_style/admin_settings/trigger_review_import',
          {},
          { successMessage: this.$t('trigger_review_import.success') }
        )
        .finally(() => (this.isTriggeringReviewImport = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "review_import_status": {
    "label": "카카오스타일 리뷰 연동 상태"
  },
  "trigger_review_import": {
    "label": "리뷰 연동 즉시 시작",
    "hint": "{brand} 쇼핑몰에 대한 카카오스타일 리뷰 연동을 즉시 시작합니다.",
    "tooltip": "카카오스타일 리뷰 연동이 사용중인 경우에만 동작합니다.",
    "success": "{brand} 쇼핑몰에 대한 카카오스타일 리뷰 연동을 시작했습니다."
  }
}
</i18n>
