import { constructEnum } from '@/lib/enum';

const BizMessageCampaignType = constructEnum('BizMessageCampaignType', {
  REVIEW_MEMBER: 1000,
  REVIEW_NONMEMBER: 1010,
  REVIEW_OFFLINE: 1020
});

const DEFAULT_REVIEW_MEMBER_TEMPLATE_ID = 27;
const DEFAULT_REVIEW_NONMEMBER_TEMPLATE_ID = 29;

BizMessageCampaignType.defaultTemplateId = function(campaignType) {
  switch (campaignType) {
    case BizMessageCampaignType.REVIEW_MEMBER:
      return DEFAULT_REVIEW_MEMBER_TEMPLATE_ID;
    case BizMessageCampaignType.REVIEW_NONMEMBER:
      return DEFAULT_REVIEW_NONMEMBER_TEMPLATE_ID;
    default:
      return null;
  }
};

export default BizMessageCampaignType;
