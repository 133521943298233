<template>
  <div class="SubMenuNotice">
    <div class="SubMenuNotice__divider" />
    <div v-if="isCollapsed" class="SubMenuNotice__collapsed">
      <Component
        :is="notice.button_url ? 'AppExternalLink' : 'div'"
        :to="notice.button_url"
        class="SubMenuNotice__link"
        @click="markClickedNotice"
      >
        <div class="SubMenuNotice__collapsed-title">{{ notice.title }}</div>
      </Component>
    </div>
    <div v-else class="SubMenuNotice__expanded">
      <SubMenuTextAndImageNotice
        v-if="isTextNotice"
        :notice="notice"
        @click="markClickedNotice"
      />
      <SubMenuImageNotice v-else :notice="notice" @click="markClickedNotice" />
    </div>
  </div>
</template>

<script>
import SubMenuImageNotice from './SubMenuImageNotice';
import SubMenuTextAndImageNotice from './SubMenuTextAndImageNotice';
import MiniNoticeType from '@/enums/MiniNoticeType';
import api from '@/lib/api';

export default {
  name: 'SubMenuNotice',
  components: {
    SubMenuImageNotice,
    SubMenuTextAndImageNotice
  },
  props: {
    notice: { type: Object, required: true },
    isCollapsed: { type: Boolean, required: true }
  },
  computed: {
    isTextNotice() {
      return this.notice.notice_type === MiniNoticeType.TEXT_WITH_IMAGE;
    }
  },
  methods: {
    markClickedNotice() {
      api.post(`/help/mini_notices/${this.notice.id}/click`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.SubMenuNotice {
  width: 100%;
  background-color: $color-background;
  position: relative;
}

.SubMenuNotice__divider {
  height: 1px;
  border: none;
  background-color: $color-layout-section;
}

.SubMenuNotice__collapsed {
  height: 50px;
}

.SubMenuNotice__collapsed-title {
  @include text-label;
  text-align: center;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 24px;
}

.SubMenuNotice__expanded {
  height: 230px;
}
</style>
