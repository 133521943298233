<template>
  <div @click.stop="click">
    <AppSvgIcon
      class="DisplayCornerProductOverlayDropdownMenu__icon"
      name="nav-more"
    />
    <AppModeless v-if="isDropdownVisible" @close="close">
      <AppDropdownMenu :menu-items="menuItems" @close="close" />
    </AppModeless>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DisplayCornerProductOverlayDropdownMenu',
  props: { product: { type: Object, required: true } },
  data: () => ({ isDropdownVisible: false }),
  computed: {
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    ...mapGetters('displayProductDisplay', ['cornerProductCategory']),
    menuItems() {
      if (this.isCategoryPage) {
        const disabled = this.cornerProductCategory?.hasChildCategory || false;

        return [
          {
            label: this.$t('menu_item.remove.label'),
            style: 'danger',
            disabled,
            tooltip: disabled ? this.$t('menu_item.remove.tooltip') : null,
            clickHandler: this.openRemoveProductDialog
          }
        ];
      } else
        return [
          {
            label: this.$t('menu_item.exclude'),
            clickHandler: this.openExcludingProductDialog
          },
          {
            label: this.$t('menu_item.replace'),
            clickHandler: this.openReplaceProductDialog
          }
        ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    click() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    close() {
      this.isDropdownVisible = false;
    },
    openExcludingProductDialog() {
      this.openDialog([
        'DisplayCornerExcludingProductDialog',
        { selectedProducts: [this.product] }
      ]);
    },
    openReplaceProductDialog() {
      this.openDialog([
        'DisplayCornerReplaceProductDialog',
        { product: this.product }
      ]);
    },
    openRemoveProductDialog() {
      this.openDialog([
        'DisplayCornerRemoveProductDialog',
        { selectedProducts: [this.product] }
      ]);
    }
  }
};
</script>

<style scoped>
.DisplayCornerProductOverlayDropdownMenu__icon {
  margin-right: 4px;
}
</style>

<i18n locale="ko">
{
  "menu_item": {
    "exclude": "진열 제외",
    "replace": "상품 교체",
    "remove": {
      "label": "카테고리 제외",
      "tooltip": "상품이 포함된 최하위 카테고리에서 제거할 수 있습니다."
    }
  }
}
</i18n>
