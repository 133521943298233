import BizMessageTemplateMessageType from '@/enums/BizMessageTemplateMessageType';
import BizMessageTemplateEmphasizeType from '@/enums/BizMessageTemplateEmphasizeType';
import CremaServiceType from '@/enums/CremaServiceType';

export default {
  data: () => ({ template: { buttons: [] } }),
  computed: {
    templateOptions() {
      return {
        category_code: { type: 'static' },
        header: { type: 'static' },
        title: { type: 'static' },
        subtitle: { type: 'static' },
        image: { type: 'static_image' },
        highlight_image: { type: 'static_image' },
        item_list: { type: 'custom' },
        content: { type: 'custom' },
        extra: { type: 'static' },
        buttons: { type: 'custom' }
      };
    },
    templateOptionIds() {
      return [
        'category_code',
        ...this.emphasizeTypeGroupIds,
        'content',
        ...this.messageTypeGroupIds
      ];
    },
    emphasizeTypeGroupIds() {
      switch (this.template.emphasize_type) {
        case BizMessageTemplateEmphasizeType.TEXT:
          return ['title', 'subtitle'];
        case BizMessageTemplateEmphasizeType.IMAGE:
          return ['image'];
        case BizMessageTemplateEmphasizeType.ITEM_LIST:
          return [
            'header',
            'title',
            'subtitle',
            'highlight_image',
            'item_list'
          ];
        default:
          return [];
      }
    },
    messageTypeGroupIds() {
      switch (this.template.message_type) {
        case BizMessageTemplateMessageType.EXTRA_INFO:
          return ['extra'];
        case BizMessageTemplateMessageType.CHANNEL_ADD:
          return ['buttons'];
        case BizMessageTemplateMessageType.MIXED:
          return ['extra', 'buttons'];
        default:
          return ['buttons'];
      }
    }
  },
  methods: {
    formatTemplate(template) {
      return {
        ...template,
        service_type: CremaServiceType.t(template.service_type),
        category_code: template.category?.name || this.$t('app.none'),
        message_type: BizMessageTemplateMessageType.t(template.message_type),
        emphasize_type: BizMessageTemplateEmphasizeType.t(
          template.emphasize_type
        )
      };
    }
  }
};
