<template>
  <div
    ref="modal"
    :class="['AppModal', { 'AppModal--maximized': maximized }]"
    tabindex="0"
    @keyup.esc="close"
    @click="hideBorderBubble"
  >
    <Transition name="modal-fade">
      <div v-if="show">
        <div v-if="!skipBackdrop" class="AppModal__backdrop" @click="close" />
        <div class="AppModal__wrapper" :style="wrapperStyle">
          <AppScrollBox
            v-if="hasLeft()"
            :class="['AppModal__left', resizing && 'AppModal__left--inactive']"
            :style="leftStyle"
          >
            <slot name="left" />
          </AppScrollBox>
          <div
            v-if="hasLeft() && maximized"
            :class="[
              'AppModal__handle',
              { 'AppModal__handle--visible': resizing }
            ]"
            :style="{ transform: `translateX(${handleTranslateX}px)` }"
            @mousedown="startResize"
          />
          <div
            ref="main"
            :class="['AppModal__main', { 'AppModal__main--wide': !hasLeft() }]"
            :style="mainStyle"
          >
            <!-- eslint-disable vue/no-v-html -->
            <AppBubble
              v-if="!isBubbleHidden"
              class="AppModal__main-bubble"
              side="left"
              @click="hideBubble"
              v-html="bubbleMessage"
            />
            <!-- eslint-enable vue/no-v-html -->
            <div class="AppModal__head"><slot name="head" /></div>
            <div class="AppModal__body"><slot name="body" /></div>
            <div class="AppModal__foot"><slot name="foot" /></div>
          </div>
          <Transition name="basic-fade">
            <div v-if="resizing" class="AppModal__resizing-tooltip">
              {{ resizingTooltip }}
            </div>
          </Transition>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'AppModal',
  props: {
    width: {
      type: String,
      default: null
    },
    leftWidth: {
      type: String,
      default: null
    },
    marginVertical: {
      type: Number,
      default: 20
    },
    maximized: {
      type: Boolean,
      defalut: false
    },
    skipBackdrop: {
      type: Boolean,
      default: false
    },
    defaultLeftMinWidth: {
      type: Number,
      default: 600
    },
    defaultMainMinWidth: {
      type: Number,
      default: 418
    },
    showBubble: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      startX: 0,
      handleTranslateX: 0,
      oldHandleTranslateX: 0,
      resizing: false,
      mainMinWidth: '0',
      borderBubbleHidden: false,
      resizeBubbleHidden: false,
      currentScreenWidth: window.innerWidth
    };
  },
  computed: {
    wrapperStyle() {
      return {
        maxHeight: `calc(100vh - ${this.marginVertical * 2}px)`,
        margin: `${this.marginVertical}px 0`
      };
    },
    leftStyle() {
      return { width: this.leftWidth };
    },
    mainStyle() {
      return {
        width: this.width,
        minWidth: this.maximized ? this.mainMinWidth : '0'
      };
    },
    resizingTooltip() {
      return `${window.innerWidth + this.handleTranslateX}px`;
    },
    bubbleMessage() {
      return this.maximized
        ? this.$t('bubble_message.resize')
        : this.$t('bubble_message.border');
    },
    isBubbleHidden() {
      if (!this.showBubble) return true;

      return this.maximized ? this.resizeBubbleHidden : this.borderBubbleHidden;
    },
    currentWidth() {
      return this.currentScreenWidth;
    },
    isScreenWidthOutOfRange() {
      return this.currentWidth > 1401 || this.currentWidth < 1024;
    }
  },
  watch: {
    resizing: {
      handler(val) {
        if (val) this.resizeBubbleHidden = true;
      }
    },
    maximized: {
      handler(val) {
        if (val) this.borderBubbleHidden = true;
        else this.resizeBubbleHidden = true;
      }
    },
    currentWidth() {
      if (this.isScreenWidthOutOfRange) this.borderBubbleHidden = true;
    }
  },
  mounted() {
    // Start transition after mount
    this.show = true;
    if (this.isScreenWidthOutOfRange) this.borderBubbleHidden = true;
    window.addEventListener('resize', this.handleScreenResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleScreenResize);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    hasLeft() {
      return this.$slots.left || this.$scopedSlots.left;
    },
    startResize(e) {
      this.resizing = true;
      this.startX = e.clientX;
      this.oldHandleTranslateX = this.handleTranslateX;

      document.addEventListener('mouseup', this.stopResize, { once: true });
      document.addEventListener('mousemove', this.moveHandle);
      e.preventDefault();
    },
    moveHandle(e) {
      this.handleTranslateX = Math.min(
        Math.max(
          this.oldHandleTranslateX + e.clientX - this.startX,
          this.defaultLeftMinWidth +
            this.defaultMainMinWidth -
            window.innerWidth
        ),
        0
      );
    },
    stopResize() {
      document.removeEventListener('mousemove', this.moveHandle);
      this.mainMinWidth = `${this.defaultMainMinWidth -
        this.handleTranslateX}px`;
      this.resizing = false;
    },
    hideBorderBubble(event) {
      if (event.srcElement.classList.contains('AppModalHeadMaximizeButton')) {
        event.stopPropagation();
        return;
      }

      this.borderBubbleHidden = true;
    },
    hideBubble() {
      if (this.maximized) {
        this.resizeBubbleHidden = true;
      } else {
        this.borderBubbleHidden = true;
      }
    },
    handleScreenResize() {
      this.currentScreenWidth = window.innerWidth;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_z-indexes.scss';
@import '@/scss/mixins/_transitions.scss';
@import '@/scss/mixins/_dialog.scss';
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_breakpoints.scss';
@import '@/scss/blocks/tooltip.scss';

.AppModal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-modal;
  text-align: center;
}

.AppModal__backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-dropdown;
}

@mixin modal-wrapper-maximized {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;

  // override inline style
  max-height: 100vh !important;
  margin: 0 !important;
}
.AppModal__wrapper {
  @include dialog;

  position: relative;
  display: inline-flex;
  text-align: left;

  .AppModal--maximized & {
    @include modal-wrapper-maximized;
  }
  @include media-breakpoint-each(mobile) {
    @include modal-wrapper-maximized;
  }
}

.AppModal__left {
  border-right: 1px solid #e7e7e7;

  @include media-breakpoint-each(tablet, mobile) {
    display: none;
  }

  &--inactive {
    pointer-events: none;
  }
}

.AppModal__main {
  max-width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-each(mobile) {
    width: 100vw !important;
  }

  @include media-breakpoint-each(tablet) {
    .AppModal--maximized & {
      width: 100vw !important;
    }
  }
}

.AppModal__main-bubble {
  text-align: center;
}

.AppModal__body {
  overflow-y: auto;
}

@include media-breakpoint-each(desktop) {
  .AppModal--maximized .AppModal__left,
  .AppModal--maximized .AppModal__main--wide {
    flex: 1;
  }
}

@keyframes modal-fade-in {
  0% {
    opacity: 0;
  }

  10%,
  100% {
    opacity: 1;
  }
}

@keyframes modal-content-fade-in {
  0%,
  10% {
    opacity: 0;
    transform: translateY(5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-fade-enter-active {
  animation: modal-fade-in $transition-duration-slow;

  .AppModal__wrapper {
    animation: modal-content-fade-in $transition-duration-slow;
  }
}

.modal-fade-leave-active {
  animation: fade-in $transition-duration-basic reverse;
}

.AppModal__resizing-tooltip {
  @include tooltip;
  position: absolute;
  top: 12px;
  left: 12px;
  white-space: nowrap;
  z-index: 1;
}
</style>

<i18n locale="ko">
{
  "bubble_message": {
    "resize": "구분선을 드래그하여 미리보기 화면 너비를 조절할 수 있습니다.",
    "border": "브라우저 창의 너비를 늘리면<br>미리보기 영역이 잘리지 않습니다."
  }
}
</i18n>

<i18n locale="en">
{
  "bubble_message": {
    "resize": "You can change the preview pane width by dragging the line.",
    "border": "Resize the browser window<br />to prevent the preview from being cropped."
  }
}
</i18n>
