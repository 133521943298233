import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import MENU_ITEMS from '@/constants/MENU_ITEMS';
import i18n from '@/lib/i18n';
import { getParamsFromPath } from '@/lib/params';
import BrandReviewStatus from '@/enums/BrandReviewStatus';
import {
  propagateSuperAdmin,
  isMenuItemAccessible,
  isMenuItemAccessibleForInstallationAgency
} from './menuAccess';

const state = {
  routerMenu: '',
  showCustomTitle: false,
  menuItems: _.cloneDeep(MENU_ITEMS)
};

const mutations = {
  SET_ROUTER_MENU(state, routerMenu) {
    state.routerMenu = routerMenu;
  },
  DISABLE_MENU({ menuItems }, id) {
    const menuItem = menuItems.find(i => i.id === id);
    Vue.set(menuItem, 'disabled', true);
  },
  SET_SHOW_CUSTOM_TITLE(state, value) {
    state.showCustomTitle = value;
  }
};

function isReviewChatbotNotAvailable(session) {
  const currentBrandReviewStatus = session.currentBrand.review_status;
  const isReviewLaunchedWithinSixDays = moment(
    session.reviewSettings.review_launched_at
  ).isSameOrAfter(moment().subtract(6, 'days'));

  const isReviewLaunchedRecently =
    currentBrandReviewStatus === BrandReviewStatus.OPERATING &&
    isReviewLaunchedWithinSixDays;
  const isReviewNotLaunchedNorOnboarding = [
    BrandReviewStatus.NONE,
    BrandReviewStatus.REQUESTED,
    BrandReviewStatus.PAID,
    BrandReviewStatus.TESTING
  ].includes(currentBrandReviewStatus);

  return isReviewLaunchedRecently || isReviewNotLaunchedNorOnboarding;
}

const getters = {
  selectedMenuItem: ({ routerMenu }, { menuItem }) => menuItem(routerMenu),
  selectedMainMenuItem(_state, { selectedMenuItem, menuItem }) {
    let item = selectedMenuItem;
    if (item) {
      while (item.parentId) {
        item = menuItem(item.parentId);
      }
    }
    return item;
  },
  selectedMainMenuItemId: (_state, { selectedMainMenuItem }) =>
    selectedMainMenuItem ? selectedMainMenuItem.id : null,
  selectedSubMenuItemId(_state, { selectedMenuItem, menuItem }) {
    let subMenuItem = null;
    let item = selectedMenuItem;
    while (item) {
      if (item.type === 'sub_menu') {
        subMenuItem = item;
        break;
      }
      item = item.parentId ? menuItem(item.parentId) : null;
    }
    return subMenuItem ? subMenuItem.id : null;
  },
  selectedTabItemId(_state, { selectedMenuItem, childTabItems }, rootState) {
    if (!selectedMenuItem) return null;
    if (selectedMenuItem.type === 'tab') return selectedMenuItem.id;

    const tabItems = childTabItems(selectedMenuItem.id);
    const item = tabItems.find(
      i => rootState.currentQuery.tab == getParamsFromPath(i.path).tab
    );
    return item ? item.id : null;
  },
  mainMenuItems(_state, { accessibleMenuItems }) {
    return accessibleMenuItems.filter(item => item.type === 'main_menu');
  },
  subMenuItems(_state, { accessibleMenuItems }) {
    return accessibleMenuItems.filter(item => item.type === 'sub_menu');
  },
  dialogItems(_state, { accessibleMenuItems }) {
    return accessibleMenuItems.filter(item => item.type === 'dialog');
  },
  tabItems(_state, { accessibleMenuItems }) {
    return accessibleMenuItems.filter(item =>
      ['tab', 'dialog_tab'].includes(item.type)
    );
  },
  externalLinkItems(_state, { accessibleMenuItems }) {
    return accessibleMenuItems.filter(item => item.type === 'external_link');
  },
  childTabItems: (_state, { tabItems }) => parentId => {
    return tabItems.filter(item => item.parentId === parentId);
  },
  moreMenuItems(_state, { accessibleMenuItems }) {
    return accessibleMenuItems.filter(item => item.dialogMenu === 'more_menu');
  },
  menuItem: (_state, { accessibleMenuItems }) => id => {
    return accessibleMenuItems.find(i => i.id === id);
  },
  menuItemWithCurrentPathParams: (
    _state,
    { menuItem },
    _rootState,
    { pathWithCurrentPathParams }
  ) => id => {
    const item = menuItem(id);

    return { ...item, path: pathWithCurrentPathParams(item.path) };
  },
  rootItem: (_state, { menuItem }) => item => {
    let currentItem = item;
    while (currentItem.parentId) {
      currentItem = menuItem(currentItem.parentId);
    }
    return currentItem;
  },
  accessibleMenuItems({ menuItems }, _getters, { session }, rootGetters) {
    if (!session.currentUser) return [];

    const { isSuperAdminAccessible } = rootGetters;

    const sessionGetters = new Proxy(rootGetters, {
      get: (obj, prop) => {
        return obj[
          prop === 'isSuperAdminAccessible' ? prop : `session/${prop}`
        ];
      }
    });

    let items = menuItems.map(item =>
      typeof item.superAdmin === 'function'
        ? { ...item, superAdmin: item.superAdmin(sessionGetters) }
        : item
    );

    items = items.map(item =>
      !item.isLaunched || item.isLaunched(sessionGetters)
        ? item
        : { ...item, superAdmin: true }
    );

    items = propagateSuperAdmin(items);

    if (sessionGetters.isCurrentUserInstallationAgency) {
      items = items.filter(item =>
        isMenuItemAccessibleForInstallationAgency(item, sessionGetters)
      );
    } else {
      items = items.filter(item =>
        isMenuItemAccessible(item, {
          isSuperAdminAccessible,
          getters: sessionGetters
        })
      );
    }
    return items.map(item => {
      return {
        label: item.label || i18n.t(`menu.${item.id}`),
        ...item,
        path:
          typeof item.path === 'function'
            ? item.path(sessionGetters)
            : item.path
      };
    });
  },
  helpChatLabel(_state, { selectedMainMenuItemId }, { session }) {
    const isValidMainMenuItemId = [
      'review',
      'fit',
      'target',
      'display',
      'analytics'
    ].includes(selectedMainMenuItemId);
    if (!isValidMainMenuItemId) return null;

    return selectedMainMenuItemId === 'review' &&
      isReviewChatbotNotAvailable(session)
      ? i18n.t('help.inquiry')
      : i18n.t('help.chat');
  }
};

export default { namespaced: true, state, mutations, getters };
