import { constructEnum } from '@/lib/enum';

const BizMessageTemplateType = constructEnum('BizMessageTemplateType', {
  CUSTOM: 0,
  GROUP: 10
});

BizMessageTemplateType.isGroupTemplateType = function(templateType) {
  return templateType === BizMessageTemplateType.GROUP;
};

export default BizMessageTemplateType;
