import { constructEnum } from '@/lib/enum';
import TargetCampaignMediaType from './TargetCampaignMediaType';

const TargetCampaignDispatchType = constructEnum('TargetCampaignDispatchType', {
  DIRECT_MESSAGE: 0,
  REALTIME: 10
});

TargetCampaignDispatchType.campaignMediaTypes = function(campaignDispatchType) {
  switch (campaignDispatchType) {
    case this.DIRECT_MESSAGE:
      return [TargetCampaignMediaType.DIRECT_MESSAGE];
    case this.REALTIME:
      return [TargetCampaignMediaType.POPUP, TargetCampaignMediaType.WIDGET];
  }
};

export default TargetCampaignDispatchType;
