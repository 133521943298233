import { constructEnum } from '@/lib/enum';

export default constructEnum('TargetTotalReportType', {
  TOTAL: 0,
  PURCHASE_PROMOTION: 10,
  CHURNING_PREVENTION: 20,
  USER_MANAGEMENT: 30,
  REVIEW: 40,
  MASS: 50,
  RECOMMENDATION: 60
});
