<template>
  <div class="AnalyticsEventsMetrics">
    <AppEventReportsSearchbar
      :reports-filter-options="METRIC_TYPES"
      :multiple-select="false"
      v-bind="searchBarProps"
      v-on="searchBarEvents"
    />
    <AppChart
      :options="chartOptions"
      :tooltip="chartTooltip"
      @tooltip="onChartTooltip"
    />
    <AppButtonDownload
      class="AnalyticsEventsMetrics__download-excel"
      button-style="grey"
      :label="$t('app.download_excel')"
      :url="downloadExcelUrl"
      :disabled="!reports.length"
    />
    <AppResourceTable
      :resources="resources"
      :rows="[totalRow, ...rows]"
      :columns="columns"
      :enable-total-count="false"
      table-style="stats"
      @paginate="paginate"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import qs from 'qs';
import ReportsView from '@/mixins/ReportsView';

export default {
  name: 'AnalyticsEventsMetrics',
  mixins: [ReportsView],
  data() {
    return {
      chartTooltip: null,
      filterName: 'report_type',
      METRIC_TYPES: [
        { label: this.$t('custom'), value: 'custom' },
        { label: 'DAU', value: 'dau' },
        { label: 'PV', value: 'pv' }
      ]
    };
  },
  computed: {
    columns() {
      return [
        { id: 'date', label: this.$t('app.date') },
        {
          id: 'value',
          label: this.selectedMetricType,
          type: 'number',
          default: '-',
          align: 'right'
        }
      ];
    },
    rows() {
      return this.reportsTable.map(item => ({
        ...item,
        date: this.getFormattedDate(item.date),
        rowStyle: 'stats-summary'
      }));
    },
    totalRow() {
      const metricTotalCount = _.sumBy(this.reportsChart, 'value');
      return {
        value: metricTotalCount,
        date: this.$t('total'),
        rowStyle: 'stats-total'
      };
    },
    searchBarEvents() {
      return {
        'change-date-range': this.changeDateRange,
        'change-interval': this.changeInterval,
        'change-filter': this.changeFilter,
        'fetch-result': this.fetchResult
      };
    },
    searchBarProps() {
      return {
        startDate: this.resourceParams.start_date,
        endDate: this.resourceParams.end_date,
        interval: this.resourceParams.interval,
        filterValue: this.resourceParams[this.filterName],
        eventType: this.resourceParams.event_type,
        aggregateMethod: this.resourceParams.aggregate_method,
        aggregateColumn: this.resourceParams.aggregate_column,
        columnFilters: this.resourceParams.column_filters
          ? JSON.parse(this.resourceParams.column_filters)
          : []
      };
    },
    chartOptions() {
      return {
        title: {
          text: ''
        },
        series: [
          {
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData('value'),
            name: this.selectedMetricType,
            yAxis: 0
          }
        ],
        xAxis: { type: 'datetime' },
        yAxis: [
          {
            labels: {
              format: '{value:,.0f}',
              style: {
                color: '#4572A7'
              }
            },
            title: {
              text: this.selectedMetricType,
              style: {
                color: '#4572A7'
              }
            }
          }
        ],
        tooltip: {
          enabled: false
        }
      };
    },
    selectedMetricType() {
      return this.METRIC_TYPES.find(
        metricType => metricType.value === this.resourceParams[this.filterName]
      ).label;
    },
    downloadExcelUrl() {
      return `/api/analytics/events/download_excel?${qs.stringify(
        this.resourceParams
      )}`;
    }
  },
  methods: {
    onChartTooltip({ x, index, series }) {
      this.chartTooltip = {
        title: this.getFormattedDate(new Date(x)),
        items: series.chart.series.map(({ data, name }) => ({
          symbol: 'circle',
          name,
          value: data[index].y
        }))
      };
    },
    changeFilter(filter) {
      if (filter === this.resourceParams[this.filterName]) return;

      const params = {};
      params[this.filterName] = filter;
      this.$emit('change-resource-params', params);
    },
    fetchResult(params) {
      this.$emit('change-resource-params', params);
    }
  }
};
</script>

<style scoped>
.AnalyticsEventsMetrics__download-excel {
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>

<i18n locale="ko">
{
  "total": "총 합계",
  "custom": "커스텀"
}
</i18n>
