<template>
  <div class="WidgetInstallRequestDialogReviewBoard">
    <div class="WidgetInstallRequestDialogReviewBoard__action">
      <AppButton
        class="WidgetInstallRequestDialogReviewBoard__action-placement-button"
        :label="$t('placement_button_label')"
        @click="openChangePlacementDialog"
      />
    </div>
    <AppTable :columns="COLUMNS" :rows="rows" :no-data="$t('no_data')">
      <template #cell="{ row, column, rowIndex }">
        <div v-if="column.id === 'feature'">
          <AppButtonDraggable :drag-text="row.title" @sort="sort" />
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            @click="deleteRow(rowIndex)"
          />
        </div>
      </template>
    </AppTable>
    <div class="WidgetInstallRequestDialogReviewBoard__move-buttons">
      <AppButton
        button-style="default"
        :label="$t('prev_label')"
        @click="moveToPrevPage"
      />
      <AppButton
        button-style="blue"
        :label="$t('next_label')"
        @click="clickNextPageButton"
      />
    </div>
    <div class="WidgetInstallRequestDialogReviewBoard__widget_list_title">
      {{ $t('widget_list_title.installable_widget') }}
    </div>
    <div class="WidgetInstallRequestDialogReviewBoard_widget-card-container">
      <div
        v-for="combinationType in installableWidgetCombinationTypes"
        :key="combinationType"
      >
        <WidgetCard
          :combination-type="combinationType"
          is-single-widget
          @apply="apply(combinationType)"
        />
      </div>
    </div>
    <div class="WidgetInstallRequestDialogReviewBoard__widget_list_title">
      {{ $t('widget_list_title.recommendation') }}
    </div>
    <div class="WidgetInstallRequestDialogReviewBoard_widget-card-container">
      <div
        v-for="combinationType in recommendationWidgetCombinationTypes"
        :key="combinationType"
      >
        <WidgetCard
          :combination-type="combinationType"
          :page-type="WidgetPageType.REVIEW_BOARD"
          @apply="apply(combinationType)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { arrayMoveIndex } from '@/lib/array';
import WidgetInstallRequestProgressStep from '@/enums/WidgetInstallRequestProgressStep';
import WidgetCombinationType from '@/enums/WidgetCombinationType';
import WidgetPageType from '@/enums/WidgetPageType';
import WidgetCard from '@/views/review/widget_installation_request/components/WidgetCard';

export default {
  name: 'WidgetInstallRequestDialogReviewBoard',
  components: { WidgetCard },
  data() {
    return {
      isLoading: false,
      COLUMNS: [
        {
          id: 'selected_widget',
          label: this.$t('column.selected_widget')
        },
        {
          id: 'feature',
          label: this.$t('column.feature'),
          width: '160px'
        }
      ]
    };
  },
  computed: {
    ...mapState('widgetInstallRequest', ['reviewBoardWidgetCombinationTypes']),
    WidgetPageType: () => WidgetPageType,
    installableWidgetCombinationTypes() {
      return [
        WidgetCombinationType.LIST,
        WidgetCombinationType.GALLERY,
        WidgetCombinationType.HORIZONTAL_SCROLL,
        WidgetCombinationType.BEST_GALLERY,
        WidgetCombinationType.BOARD,
        WidgetCombinationType.PRODUCTS
      ];
    },
    recommendationWidgetCombinationTypes() {
      return WidgetCombinationType.reviewBoardTypes;
    },
    rows() {
      return this.reviewBoardWidgetCombinationTypes.map(v => ({
        selected_widget: WidgetCombinationType.t(v)
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'confirm']),
    ...mapMutations('widgetInstallRequest', [
      'SET_WIDGET_INSTALL_REQUEST_STEP',
      'ADD_REVIEW_BOARD_WIDGET_COMBINATION_TYPES',
      'SET_REVIEW_BOARD_WIDGET_COMBINATION_TYPES',
      'DELETE_REVIEW_BOARD_WIDGET_COMBINATION_TYPES'
    ]),
    openChangePlacementDialog() {
      this.openDialog(['WidgetInstallRequestChangePlacementFormDialog']);
    },
    moveToPrevPage() {
      this.SET_WIDGET_INSTALL_REQUEST_STEP(
        WidgetInstallRequestProgressStep.PRODUCT_DETAIL
      );
    },
    clickNextPageButton() {
      if (this.rows.length) this.moveToNextPage();
      else
        this.confirm({
          title: this.$t('no_data_next_modal.title'),
          message: this.$t('no_data_next_modal.content')
        }).then(result => {
          if (result) this.moveToNextPage();
        });
    },
    moveToNextPage() {
      this.SET_WIDGET_INSTALL_REQUEST_STEP(
        WidgetInstallRequestProgressStep.MAIN
      );
    },
    apply(combinationType) {
      this.ADD_REVIEW_BOARD_WIDGET_COMBINATION_TYPES(combinationType);
    },
    sort({ srcIndex, dstIndex }) {
      const sortedRows = arrayMoveIndex(
        this.reviewBoardWidgetCombinationTypes,
        srcIndex,
        dstIndex
      );
      this.SET_REVIEW_BOARD_WIDGET_COMBINATION_TYPES(sortedRows);
    },
    deleteRow(rowIdx) {
      this.DELETE_REVIEW_BOARD_WIDGET_COMBINATION_TYPES(rowIdx);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.WidgetInstallRequestDialogReviewBoard__action {
  display: flex;
  justify-content: flex-end;
}

.WidgetInstallRequestDialogReviewBoard__action-placement-button {
  margin-bottom: 16px;
}

.WidgetInstallRequestDialogReviewBoard__move-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.WidgetInstallRequestDialogReviewBoard__widget_list_title {
  @include text-title;
  text-align: left;
  margin: 32px 0 12px 0;
}

.WidgetInstallRequestDialogReviewBoard_widget-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}
</style>

<i18n locale="ko">
{
  "placement_button_label": "설치 위치 변경",
  "prev_label": "\u003C 이전목록으로 돌아가기",
  "next_label": "선택 완료 / 다음 페이지로 이동 >",
  "widget_list_title": {
    "installable_widget": "전체 리뷰 페이지에 설치가능한 위젯 목록",
    "recommendation": "크리마 추천 구성"
  },
  "column": {
    "selected_widget": "선택한 위젯",
    "feature": "기능"
  },
  "no_data": "위젯을 선택해주세요.",
  "no_data_next_modal": {
    "title": "이 페이지에 위젯을 설치하지 않습니다.",
    "content": "전체 리뷰 페이지에 별도의 위젯 설치 없이 진행합니다.<br />이 후, 추가 설치가 필요한 경우에는 다시 신청해주셔야합니다. "
  }
}
</i18n>
