import DialogView from './DialogView';
import FormView from './FormView';

export default {
  mixins: [DialogView, FormView],
  methods: {
    close(force) {
      if (force === true || !this.isFormDataChanged) this.closeDialog();
      else if (confirm(this.$t('app.confirm_discard_change_navigate'))) {
        this.beforeCloseDialog();
        this.closeDialog();
      }
    },
    beforeCloseDialog() {}
  },
  computed: {
    formEvents() {
      return {
        change: this.setFormObject,
        'error-summary': this.setErrorSummary,
        submit: this.submit,
        close: this.close
      };
    }
  }
};
