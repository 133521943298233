<template>
  <div
    :class="[
      'AppMultipleSelect',
      'dropdown',
      { 'AppMultipleSelect--disabled': disabled }
    ]"
  >
    <AppMultipleSelection
      :id="id"
      :options="selectedOptions"
      :disabled="disabled"
      :invalid="invalid"
      :placeholder="placeholder"
      @click="click"
      @check="$emit('check', $event)"
      @remove="removeHandler"
    >
      <template #modeless>
        <AppModeless
          v-if="isDropdownVisible"
          class="AppMultipleSelect__dialog dropdown__dialog"
          @close="closeDropdown"
        >
          <AppSelectSearchDropdown
            :data="optionsWithSelect"
            @select="selectValue"
            @close="closeDropdown"
          />
        </AppModeless>
      </template>
    </AppMultipleSelection>
    <AppHiddenInputArray v-if="name" :name="name" :values="currentValue" />
  </div>
</template>

<script>
import _ from 'lodash';
import MultipleSelectable from '@/mixins/MultipleSelectable';
import AppSelectSearchDropdown from './AppSelect/AppSelectSearchDropdown';

export default {
  name: 'AppMultipleSelect',
  components: { AppSelectSearchDropdown },
  mixins: [MultipleSelectable],
  props: {
    id: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isDropdownVisible: false
    };
  },
  computed: {
    selectedOptions() {
      return this.currentValue
        .map(v => this.optionsMap[v])
        .filter(v => v !== undefined);
    },
    optionsMap() {
      return _.keyBy(this.options, 'value');
    },
    optionsWithSelect() {
      return this.options.map(option => ({
        ...option,
        isSelected: this.isSelected(option)
      }));
    }
  },
  methods: {
    castValues(values) {
      return values ? values.map(this.castValue) : [];
    },
    select(option) {
      if (this.isSelected(option)) return;

      this.currentValue.push(option.value);
      this.$emit('select', option.value);
      this.changed();
    },
    selectValue(value) {
      const option = this.options.find(o => o.value === value);
      this.isSelected(option) ? this.unselect(option) : this.select(option);
      this.closeDropdown();
    },
    click() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    closeDropdown() {
      this.isDropdownVisible = false;
    },
    removeHandler(option) {
      this.unselect(option);
      this.$emit('remove', option);
    }
  }
};
</script>

<style scoped>
.AppMultipleSelect {
  display: block;
}

.AppMultipleSelect__dialog {
  width: 100%;
}
</style>
