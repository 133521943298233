<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <AppSearchbar @submit="search">
      <template #default>
        <AppButtonSyncCategory />
      </template>
      <template #right>
        <AppSearchWithType
          v-model="searchWithType"
          class="DisplaySettingsIncludingCategories__search-bar-item"
          :search-types="searchTypes"
        />
        <AppButtonToggle
          v-model="advancedSearchVisible"
          class="DisplaySettingsIncludingCategories__search-bar-item"
          :label="$t('app.advanced_search')"
        />
      </template>
      <template #advanced-search>
        <AppAdvancedSearch
          v-model="searchParams"
          :visible="advancedSearchVisible"
          :sections="advancedSearchSections"
          @submit="search"
        >
          <template #group="{ id }">
            <template v-if="id === 'products_count_range'">
              <div class="AppForm__group-field">
                <i18n path="unit.count">
                  <span>
                    <AppNumberInput
                      v-model="searchParams.min_products_count"
                      inline
                      :digits="6"
                    />
                    ~&nbsp;
                    <AppNumberInput
                      v-model="searchParams.max_products_count"
                      inline
                      :digits="6"
                    />
                  </span>
                </i18n>
              </div>
            </template>
          </template>
        </AppAdvancedSearch>
      </template>
    </AppSearchbar>
    <AppForm v-bind="formProps" submit-button @submit="submit">
      <AppSelectResourceTable
        v-model="formObject.enabled_category_ids"
        class="DisplaySettingsIncludingCategories__category-table"
        table-id="category-table"
        table-style="depth"
        :columns="columns"
        :rows="rows"
        :filter-options="categoryFilterOptions"
        :filter-values="categoryFilterValues"
        :selected-item-column-label="$t('enable_display_category')"
        :enable-batch-button-label="
          $t('batch_buttons.enable_selected_categories')
        "
        :disable-batch-button-label="
          $t('batch_buttons.disable_selected_categories')
        "
        @change-filter-values="categoryFilterValues = $event"
      >
        <template #cell="{ column, value, row }">
          <template v-if="column === 'property'">
            <AppBadge
              v-if="row.deleted_at"
              badge-style="red-outline"
              :label="$t('product_category.deleted')"
              :tooltip="$t('product_category.deleted_tooltip')"
            />
            <AppBadge
              v-if="row.virtual"
              badge-style="grey-outline"
              :label="$t('product_category.virtual')"
            />
            <AppBadge
              v-if="row.sub_category_display"
              badge-style="grey-outline"
              :label="$t('product_category.sub_category_display')"
              :tooltip="{
                message: $t('product_category.sub_category_display_tooltip'),
                textAlign: 'left'
              }"
            />
          </template>
        </template>
      </AppSelectResourceTable>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import { filterItems } from '@/lib/text-search';
import TargetContentView from '@/mixins/TargetContentView';
import FormView from '@/mixins/FormView';
import DisplayCategoriesTableView from '@/mixins/DisplayCategoriesTableView';
import ShopBuilder from '@/enums/ShopBuilder';
import ProductCategoryFilterType from '@/enums/ProductCategoryFilterType';

export default {
  name: 'DisplaySettingsIncludingCategories',
  mixins: [TargetContentView, FormView, DisplayCategoriesTableView],
  data: () => ({
    isLoading: true,
    advancedSearchVisible: false,
    searchParams: {
      min_products_count: null,
      max_products_count: null,
      virtual: null,
      sub_category_display: null,
      include_deleted_categories: false
    },
    orgFormObject: { enabled_category_ids: [] }
  }),
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('productCategory', ['productCategoriesWithTreeName']),
    totalCategories() {
      return this.productCategoriesWithTreeName.map(category => ({
        ...category,
        full_name: category.treeNames.join(' ')
      }));
    },
    items() {
      return this.filteredCategories;
    },
    categoryFilterOptions() {
      return ProductCategoryFilterType.options([
        ProductCategoryFilterType.MAIN,
        ProductCategoryFilterType.MIDDLE,
        ProductCategoryFilterType.SUB
      ]);
    },
    advancedSearchSections() {
      const { shop_builder } = this.currentBrand;

      return [
        {
          id: 'search',
          groups: [
            {
              id: 'products_count_range',
              label: this.$t('advanced_search.products_count_range')
            },
            {
              id: 'property',
              type: 'hash_select_button',
              label: this.$t('advanced_search.property.label', [
                ShopBuilder.tk(shop_builder)
              ]),
              selectButtons:
                shop_builder === 'makeshop'
                  ? [
                      {
                        id: 'virtual',
                        options: [false, true].map(value => ({
                          label: this.$t(
                            `advanced_search.property.virtual.${value}`
                          ),
                          value
                        }))
                      }
                    ]
                  : shop_builder === 'cafe24'
                  ? [
                      {
                        id: 'sub_category_display',
                        options: [true, false].map(value => ({
                          label: this.$t(
                            `advanced_search.property.sub_category_display.${value}`
                          ),
                          value
                        }))
                      }
                    ]
                  : []
            },
            {
              id: 'include_deleted_categories',
              type: 'checkbox',
              label: this.$t(
                'advanced_search.include_deleted_categories.label'
              ),
              description: this.$t(
                'advanced_search.include_deleted_categories.description'
              )
            }
          ]
        }
      ];
    }
  },
  created() {
    api
      .get('/display/settings/categories')
      .then(({ data }) => {
        this.orgFormObject = {
          enabled_category_ids: data.enabled_category_ids
        };
        this.UPDATE_DISPLAY_SETTINGS(this.orgFormObject);
      })
      .finally(() => (this.isLoading = false));
  },
  mounted() {
    this.search();
  },
  methods: {
    ...mapMutations('session', ['UPDATE_DISPLAY_SETTINGS']),
    search() {
      const { search_type, search_query } = this.searchWithType;
      const {
        min_products_count,
        max_products_count,
        virtual,
        sub_category_display,
        include_deleted_categories
      } = this.searchParams;

      let result = filterItems(this.totalCategories, search_type, search_query);

      if (min_products_count !== null) {
        result = result.filter(
          ({ products_count }) => products_count >= min_products_count
        );
      }
      if (max_products_count !== null) {
        result = result.filter(
          ({ products_count }) => products_count <= max_products_count
        );
      }

      if (virtual !== null)
        result = result.filter(category => category.virtual === virtual);

      if (sub_category_display !== null)
        result = result.filter(
          category => category.sub_category_display === sub_category_display
        );

      if (!include_deleted_categories)
        result = result.filter(({ deleted_at }) => !deleted_at);

      this.categories = result;
    },
    submit() {
      this.isSubmitting = true;
      api
        .patch('/display/settings/categories', this.formObject, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
          this.UPDATE_DISPLAY_SETTINGS(this.orgFormObject);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style scoped>
.DisplaySettingsIncludingCategories__category-table {
  margin-top: 16px;
}

.DisplaySettingsIncludingCategories__search-bar-item {
  display: inline-block;
  vertical-align: middle;

  & + & {
    margin-left: 8px;
  }
}
</style>

<i18n locale="ko">
{
  "enable_display_category": "진열 사용",
  "search_type": {
    "full_name": "카테고리 명",
    "code": "카테고리 코드"
  },
  "advanced_search": {
    "products_count_range": "카테고리에 포함된 상품 수",
    "property": {
      "label": "{0} 카테고리 분류",
      "virtual": {
        "false": "기본 카테고리",
        "true": "가상 카테고리"
      },
      "sub_category_display": {
        "true": "하위 상품 진열 설정 사용",
        "false": "하위 상품 진열 설정 미사용"
      }
    },
    "include_deleted_categories": {
      "label": "삭제된 카테고리 표시",
      "description": "쇼핑몰 솔루션에서 삭제된 카테고리를 표시합니다."
    }
  },
  "batch_buttons": {
    "enable_selected_categories": "일괄 진열 사용 설정",
    "disable_selected_categories": "일괄 진열 미사용 설정"
  }
}
</i18n>
