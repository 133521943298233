<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #right>
        <li>
          <AppSelect
            v-model="resourceParams.widget_type"
            :options="widgetTypeOptions"
            :placeholder="$t('app.all')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppSelect
            v-model="resourceParams.log_type"
            :options="logTypeOptions"
            :placeholder="$t('app.all')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="SEARCH_TYPES"
          />
        </li>
        <li>
          <AppDateRangePicker
            v-model="resourceParams"
            :min-date="minDate"
            @update="searchResource"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppInfoBox>
      {{ $t('info_box') }}
    </AppInfoBox>
    <AppResourceTable
      table-id="logs-table"
      :columns="columns"
      :resources="logs"
      :rows="rows"
      @paginate="paginate"
    >
      <template #cell="{row, column}">
        <template v-if="column === 'log_type'">
          <AppBadge v-bind="logBadge(row.log_type)" />
        </template>
        <template v-if="column === 'description'">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="row.description" />
          <!-- eslint-enable vue/no-v-html -->
          <div v-for="(type, index) in ['css', 'javascript']" :key="index">
            <div v-if="row[type].name">
              {{ row[type].name }}:
              {{ row[type].isNone ? 'none' : '' }}
              <AppInternalLink
                v-if="!row[type].isNone"
                to=""
                link-style="blue"
                @click="openWidgetSettingsDiffDialog(row.id, row[type].name)"
              >
                {{ $t('show_detail') }}
              </AppInternalLink>
            </div>
          </div>
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import api from '@/lib/api';
import escapeHtml from '@/lib/vendor/escapeHtml';
import { nullResources } from '@/lib/resources';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

import LogType from '@/enums/LogType';
import WidgetType from '@/enums/WidgetType';

export default {
  name: 'TheLogsDialogWidgetSettings',
  mixins: [ResourceViewNoRoute],
  data() {
    return {
      isLoading: true,
      logs: nullResources,
      minDate: moment()
        .subtract(12, 'month')
        .add(1, 'day')
        .toDate()
        .toISOString(),
      SEARCH_TYPES: [
        'actor_email',
        'actor_ip',
        'widget_id',
        'setting_name'
      ].map(value => ({
        value,
        label: this.$t(`search_type.${value}`)
      }))
    };
  },
  computed: {
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      search_type: 'actor_email'
    }),
    widgetTypeOptions() {
      return WidgetType.options();
    },
    logTypeOptions() {
      return LogType.options([
        LogType.CREATE_WIDGET,
        LogType.UPDATE_WIDGET,
        LogType.DESTROY_WIDGET
      ]);
    },
    columns() {
      return [
        { id: 'date', label: this.$t('date') },
        { id: 'actor', type: 'html', label: this.$t('actor') },
        { id: 'widget_type', label: this.$t('widget_type') },
        { id: 'widget_id', label: this.$t('widget_id') },
        { id: 'log_type', label: this.$t('log_type') },
        {
          id: 'description',
          type: 'html',
          label: this.$t('description'),
          align: 'left'
        }
      ];
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    rows() {
      return this.logs.items.map(log => {
        return {
          id: log.id,
          date: this.formatDateTime(log.created_at),
          actor: this.$t('actor_content', {
            actor_email: log.email,
            actor_ip: log.ip
          }),
          widget_type: WidgetType.t(log.widget_type),
          widget_id: log.widget_id,
          log_type: log.log_type,
          description: this.description(log),
          css: this.loadSettingObject(log, 'css'),
          javascript: this.loadSettingObject(log, 'javascript')
        };
      });
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    logBadge(logType) {
      return {
        label: LogType.t(logType),
        badgeStyle:
          logType === LogType.CREATE_WIDGET
            ? 'blue'
            : logType === LogType.UPDATE_WIDGET
            ? 'blue-outline'
            : 'red'
      };
    },
    fetchResource(params) {
      this.isLoading = true;
      const type = 'widget_settings';
      api
        .get('/logs', { params: { ...params, type } })
        .then(({ data }) => (this.logs = data.logs))
        .finally(() => (this.isLoading = false));
    },
    loadSettingObject(row, type) {
      const oldSettings = row.old_setting_name_value_map;
      const newSettings = row.new_setting_name_value_map;
      const settingName = this.$t(`settings.${type}`);
      const settingValue = oldSettings[settingName] || newSettings[settingName];

      return {
        name: settingValue !== undefined ? settingName : null,
        isNone:
          row.log_type === LogType.CREATE_WIDGET &&
          newSettings[settingName] === 'none'
      };
    },
    description(row) {
      if (row.log_type === LogType.DESTROY_WIDGET) return;

      const oldSettings = row.old_setting_name_value_map;
      const newSettings = row.new_setting_name_value_map;
      let description = [];

      for (const name in newSettings) {
        const oldValue = escapeHtml(oldSettings[name]);
        const newValue = escapeHtml(newSettings[name]);

        const settingNames = ['css', 'javascript'].map(type =>
          this.$t(`settings.${type}`)
        );
        if (settingNames.includes(name)) continue;

        if (row.log_type === LogType.CREATE_WIDGET) {
          description.push(`${name}: ${newValue}`);
        } else if (row.log_type === LogType.UPDATE_WIDGET) {
          const changed_value_text = [oldValue, newValue]
            .filter(v => v)
            .join(' -> ');
          description.push(`${name}: ${changed_value_text}`);
        }
      }
      return description.join('<br>');
    },
    openWidgetSettingsDiffDialog(logId, settingName) {
      this.openDialog([
        'TheLogsDialogSettingDiffDialog',
        { logId, settingName, logType: LogType.WIDGET_SETTINGS }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .logs-table__date {
    flex: 0 0 155px;
  }

  .logs-table__actor {
    flex: 0 0 170px;
  }

  .logs-table__widget-id {
    flex: 0 0 70px;
  }

  .logs-table__widget-type,
  .logs-table__log-type {
    flex: 0 0 100px;
  }
}
</style>

<i18n locale="ko">
{
  "search_type": {
    "actor_email": "관리자 ID",
    "actor_ip": "관리자 IP",
    "widget_id": "위젯 ID",
    "setting_name": "상세이력 내 설정명"
  },
  "info_box": "리뷰 위젯 설정 이력은 최근 1년 간의 내역만 제공합니다.",
  "date": "날짜",
  "actor": "관리자",
  "actor_content": "%{actor_email}<br />%{actor_ip}",
  "widget_type": "위젯 종류",
  "widget_id": "위젯 ID",
  "log_type": "동작",
  "description": "상세 이력",
  "settings": {
    "css": "이 위젯에만 적용할 CSS",
    "javascript": "이 위젯에만 적용할 JavaScript"
  },
  "show_detail": "상세 내역 확인"
}
</i18n>
