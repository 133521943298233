<template>
  <AppModalDefault
    :title="$t('dialog_title')"
    is-maximized-by-default
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <div class="ReviewDashboardWidgetTryoutDialog__containter">
          <div class="ReviewDashboardWidgetTryoutDialog__title">
            {{ $t('title') }}
          </div>
          <!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->
          <AppInfoBox
            class="ReviewDashboardWidgetTryoutDialog__info-box"
            v-html="$t('info_content')"
          />
          <!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->
          <template v-for="(rows, pageType) in requests">
            <div
              v-if="rows.length > 0"
              :key="pageType"
              class="ReviewDashboardWidgetTryoutDialog__image-table"
            >
              <div class="ReviewDashboardWidgetTryoutDialog__image">
                <AppImage
                  :src="imageUrl[pageType]"
                  alt="resolutions"
                  :resolutions="[2, 3]"
                />
              </div>
              <div class="ReviewDashboardWidgetTryoutDialog__table">
                <div class="ReviewDashboardWidgetTryoutDialog__table-topbar">
                  <div>
                    <span
                      class="ReviewDashboardWidgetTryoutDialog__table-topbar__table-name"
                    >
                      {{ WidgetPageType.t(pageType) }}
                    </span>
                  </div>
                </div>
                <AppTable
                  table-id="widget-tryout-table"
                  :columns="REQUEST_COLUMNS"
                  :rows="rows"
                >
                  <template #cell="{ column, row }">
                    <template v-if="column.id === 'tryout_link'">
                      <template v-if="row.deviceType === WidgetDeviceType.PC">
                        <AppButton :to="row.tryoutLink" type="external_link">
                          {{ $t('app.pc') }}
                          <AppExternalLinkIcon
                            class="AppDropdownMenu__menu-icon"
                          />
                        </AppButton>
                      </template>
                      <template v-else>
                        <AppButton
                          type="external_link"
                          @click="openMobileWindow(row.tryoutLink)"
                        >
                          {{ $t('app.mobile') }}
                          <AppExternalLinkIcon
                            class="AppDropdownMenu__menu-icon"
                          />
                        </AppButton>
                      </template>
                    </template>
                  </template>
                </AppTable>
              </div>
            </div>
          </template>
          <div
            v-if="countAndScoreWidgets.length !== 0"
            class="ReviewDashboardWidgetTryoutDialog__image-table"
          >
            <div class="ReviewDashboardWidgetTryoutDialog__image">
              <AppImage :src="imageUrl['count_and_score']" />
            </div>
            <div class="ReviewDashboardWidgetTryoutDialog__table">
              <div class="ReviewDashboardWidgetTryoutDialog__table-topbar">
                <div>
                  <div
                    class="ReviewDashboardWidgetTryoutDialog__table-topbar__table-name"
                  >
                    {{ $t('table.name.count_and_score') }}
                  </div>
                </div>
              </div>
              <AppTable
                table-id="widget-tryout-table"
                :columns="COUNT_AND_SCORE_COLUMNS"
                :rows="countAndScoreWidgets"
              >
                <template #cell="{ column, row }">
                  <template v-if="column.id === 'tryout_link'">
                    <AppButton :to="row.pcTryoutLink" type="external_link">
                      {{ $t('app.pc') }}
                      <AppExternalLinkIcon class="AppDropdownMenu__menu-icon" />
                    </AppButton>
                    <AppButton
                      type="external_link"
                      @click="openMobileWindow(row.mobileTryoutLink)"
                    >
                      {{ $t('app.mobile') }}
                      <AppExternalLinkIcon class="AppDropdownMenu__menu-icon" />
                    </AppButton>
                  </template>
                </template>
              </AppTable>
            </div>
          </div>
          <div class="ReviewDashboardWidgetTryoutDialog__image-table">
            <div class="ReviewDashboardWidgetTryoutDialog__image">
              <AppImage
                :src="imageUrl['required']"
                alt="resolutions"
                :resolutions="[2, 3]"
              />
            </div>
            <div class="ReviewDashboardWidgetTryoutDialog__table">
              <div class="ReviewDashboardWidgetTryoutDialog__table-topbar">
                <div>
                  <div
                    class="ReviewDashboardWidgetTryoutDialog__table-topbar__table-name"
                  >
                    {{ $t('table.name.required_widgets') }}
                  </div>
                </div>
              </div>
              <AppTipBox :text="$t('required_widget_tip_box.text_html')" />
              <AppTable
                table-id="widget-tryout-table"
                :columns="REQUIRED_WIDGETS_COLUMNS"
                :rows="requiredWidgets"
              >
                <template #cell="{ column, row }">
                  <template v-if="column.id === 'tryout_link'">
                    <AppButton :to="row.pcTryoutLink" type="external_link">
                      {{ $t('app.pc') }}
                      <AppExternalLinkIcon class="AppDropdownMenu__menu-icon" />
                    </AppButton>
                    <AppButton
                      type="external_link"
                      @click="openMobileWindow(row.mobileTryoutLink)"
                    >
                      {{ $t('app.mobile') }}
                      <AppExternalLinkIcon class="AppDropdownMenu__menu-icon" />
                    </AppButton>
                  </template>
                </template>
              </AppTable>
            </div>
          </div>
          <AppSummaryBox>
            <template #title>{{ $t('contact_summary_box.title') }}</template>
            <pre>{{ $t('contact_summary_box.content') }}</pre>
            <template #buttons>
              <AppButton
                type="external_link"
                :label="$t('contact_summary_box.button_label')"
                @click="openInquiry"
              />
            </template>
          </AppSummaryBox>
        </div>
      </AppAjaxContent>
    </template>
    <template #foot>
      <div class="ReviewDashboardWidgetTryoutDialog__button">
        <AppButton
          button-style="blue"
          :label="$t('close')"
          @click="clickCompleteButton"
        />
      </div>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import api from '@/lib/api';
import InquiryHelper from '@/mixins/InquiryHelper';
import WidgetStyle from '@/enums/WidgetStyle';
import WidgetPageType from '@/enums/WidgetPageType';
import WidgetType from '@/enums/WidgetType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import WidgetInstallStatus from '@/enums/WidgetInstallStatus';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'ReviewDashboardWidgetTryoutDialog',
  mixins: [DialogView, InquiryHelper],
  data() {
    return {
      isLoading: false,
      requests: {
        [WidgetPageType.MAIN]: [],
        [WidgetPageType.REVIEW_BOARD]: [],
        [WidgetPageType.PRODUCT_DETAIL]: []
      },
      pageUrls: null,
      countAndScoreWidgets: []
    };
  },
  computed: {
    ...mapState(['env']),
    WidgetPageType() {
      return WidgetPageType;
    },
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    imageUrl() {
      return {
        [WidgetPageType.PRODUCT_DETAIL]:
          'https://assets.cre.ma/m/admin/v2/request_installation_product_detail.png',
        [WidgetPageType.REVIEW_BOARD]:
          'https://assets.cre.ma/m/admin/v2/request_installation_reviews.png',
        [WidgetPageType.MAIN]:
          'https://assets.cre.ma/m/admin/v2/request_installation_main.png',
        count_and_score:
          'https://assets.cre.ma/m/admin/v2/request_installation_count_and_score_widget@3x.png',
        required:
          'https://assets.cre.ma/m/admin/v2/request_installation_required.png'
      };
    },
    REQUEST_COLUMNS() {
      return [
        { id: 'widget', label: this.$t('table.columns.widget') },
        { id: 'tryout_link', label: this.$t('table.columns.tryout_link') }
      ];
    },
    COUNT_AND_SCORE_COLUMNS() {
      return [
        { id: 'position', label: this.$t('table.columns.position') },
        { id: 'tryout_link', label: this.$t('table.columns.tryout_link') }
      ];
    },
    REQUIRED_WIDGETS_COLUMNS() {
      return [
        { id: 'widget', label: this.$t('table.columns.widget') },
        { id: 'position', label: this.$t('table.columns.position') },
        { id: 'tryout_link', label: this.$t('table.columns.tryout_link') }
      ];
    },
    requiredWidgets() {
      if (!this.pageUrls) return [];

      return [
        {
          widget: this.$t('widget.review_popup.label'),
          position: this.$t('widget.review_popup.position'),
          pageType: WidgetPageType.MAIN,
          pcTryoutLink: this.tryoutLink({
            widget_device_type: WidgetDeviceType.PC,
            page_type: WidgetPageType.MAIN
          }),
          mobileTryoutLink: this.tryoutLink({
            widget_device_type: WidgetDeviceType.MOBILE,
            page_type: WidgetPageType.MAIN
          })
        },
        {
          widget: this.$t('widget.managing_reviews.label'),
          position: this.$t('widget.managing_reviews.position'),
          pageType: WidgetPageType.MY_REVIEWS,
          pcTryoutLink: this.tryoutLink({
            widget_device_type: WidgetDeviceType.PC,
            page_type: WidgetPageType.MY_REVIEWS
          }),
          mobileTryoutLink: this.tryoutLink({
            widget_device_type: WidgetDeviceType.MOBILE,
            page_type: WidgetPageType.MY_REVIEWS
          })
        }
      ];
    }
  },
  mounted() {
    this.isLoading = true;
    api
      .get('/easy_join/widget_tryouts')
      .then(({ data }) => {
        this.pageUrls = data.page_urls;

        this.setPageTypeWidgetRequest(data.installation_data);
        this.setCountAndScoreRequest(data.installation_data);
      })
      .catch(() => {
        this.close();
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions('dialog', ['confirm']),
    ...mapMutations('easyJoin', ['SET_WIDGET_INSTALL_STATUS']),
    installationToRequestRow(installation) {
      return {
        widget: this.widgetStyleString(installation),
        deviceType: installation.widget_device_type,
        tryoutLink: this.tryoutLink(installation)
      };
    },
    hasCountAndScoreOnPageType(installationData, pageType) {
      return installationData.some(
        i =>
          i.widget_style === WidgetStyle.COUNT_AND_SCORE &&
          i.page_type === pageType
      );
    },
    setCountAndScoreRequest(installationData) {
      [
        WidgetPageType.MAIN,
        WidgetPageType.PRODUCT_LIST,
        WidgetPageType.PRODUCT_SEARCH,
        WidgetPageType.PRODUCT_DETAIL
      ].forEach(pageType => {
        if (this.hasCountAndScoreOnPageType(installationData, pageType)) {
          this.countAndScoreWidgets.push({
            position: WidgetPageType.t(pageType),
            pcTryoutLink: this.tryoutLink({
              widget_device_type: WidgetDeviceType.PC,
              page_type: pageType
            }),
            mobileTryoutLink: this.tryoutLink({
              widget_device_type: WidgetDeviceType.MOBILE,
              page_type: pageType
            })
          });
        }
      });
    },
    setPageTypeWidgetRequest(installationData) {
      for (const pageType in this.requests) {
        const result = installationData
          .filter(
            i =>
              i.page_type === parseInt(pageType) &&
              i.widget_style !== WidgetStyle.COUNT_AND_SCORE
          )
          .map(i => this.installationToRequestRow(i));

        this.requests[pageType] = [...result];
      }
    },
    widgetStyleString(installation) {
      const widgetStyle =
        installation.widget_sub_style || installation.widget_style;
      if (
        widgetStyle == null ||
        widgetStyle == undefined ||
        installation.widget_type === WidgetType.PRODUCTS
      )
        return WidgetType.t(installation.widget_type).replace(/ 위젯$/, '');

      return WidgetStyle.t(widgetStyle);
    },
    clickCompleteButton() {
      this.confirm({
        title: this.$t('confirm.title'),
        message: this.$t('confirm.message'),
        closeButtonLabel: this.$t('app.complete')
      }).then(result => {
        if (!result) return;

        this.requestInstallConfirm();
        this.close(true);
      });
    },
    requestInstallConfirm() {
      this.isLoading = true;
      api
        .post('/easy_join/widget_install_confirm')
        .then(() =>
          this.SET_WIDGET_INSTALL_STATUS(WidgetInstallStatus.CONFIRMED)
        )
        .finally(() => (this.isLoading = false));
    },
    tryoutLink(installation) {
      const {
        widget_id,
        widget_device_type,
        page_type,
        inserted_url
      } = installation;

      const url = new URL(
        inserted_url || this.pageUrls[page_type][widget_device_type]
      );

      const env = this.env === 'production' ? '' : location.host[0];
      if (env) {
        url.searchParams.set('crema-test', env);
      }

      url.searchParams.set('crema-sim', 1);
      if (widget_id)
        url.searchParams.set('crema-scroll-to-widget-id', widget_id);
      if (widget_device_type === WidgetDeviceType.MOBILE)
        url.searchParams.set('crema-widgets', 'mobile');

      return url.href;
    },
    openMobileWindow(url) {
      if (!url) return;

      const newWindow = window.open(url, 'popup', 'width=390px,height=600px');
      if (newWindow) {
        newWindow.focus();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

::v-deep {
  .AppModal__main {
    background-color: $color-grey-05;
  }
  .AppModal__head {
    background-color: #fff;
  }

  .widget-tryout-table__widget,
  .widget-tryout-table__position,
  .widget-tryout-table__tryout_link {
    flex: 1 0 0;
    min-width: 201px;
  }
}

.ReviewDashboardWidgetTryoutDialog__containter {
  width: 900px;
  margin: 0 auto;
  text-align: center;
}
.ReviewDashboardWidgetTryoutDialog__title {
  @include text-sub-title;
  margin: 28px 0 16px;
}
.ReviewDashboardWidgetTryoutDialog__info-box {
  margin-bottom: 16px;
}
.ReviewDashboardWidgetTryoutDialog__image-table {
  display: flex;
  flex-direction: row;
  padding: 24px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 12px;
  min-height: 208px;
}
.ReviewDashboardWidgetTryoutDialog__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  background-color: #e7eaed;
  border-radius: 4px;
  margin-right: 20px;
  padding: 0 20px;
  box-sizing: content-box;
}
.ReviewDashboardWidgetTryoutDialog__table {
  width: 100%;
}
.ReviewDashboardWidgetTryoutDialog__table-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 10px;
  line-height: 34px;
}
.ReviewDashboardWidgetTryoutDialog__table-topbar__table-name {
  @include text-sub-title;
}
.ReviewDashboardWidgetTryoutDialog__button {
  text-align: center;
}
</style>

<i18n locale="ko">
{
  "dialog_title": "위젯 테스트 체험",
  "title": "쇼핑몰에 위젯이 설치된 모습을 테스트해 보세요.",
  "close": "테스트 체험 완료",
  "info_content": "테스트 링크를 통해 쇼핑몰에 크리마 위젯이 설치된 모습을 미리 확인할 수 있습니다.<br />설치 과정 중 기존 리뷰 영역 대체 등 요청하지 않은 위젯 설치 작업이 진행되었을 수 있습니다.",
  "table": {
    "columns": {
      "widget": "위젯",
      "position": "위치",
      "tryout_link": "테스트 링크"
    },
    "name": {
      "required_widgets": "필수 설치 위젯",
      "count_and_score": "리뷰 수 + 평점 위젯"
    }
  },
  "widget": {
    "review_popup": {
      "label": "리뷰 작성 팝업",
      "position": "메인 페이지"
    },
    "managing_reviews": {
      "label": "내 리뷰 위젯",
      "position": "내 게시글 관리 페이지 하단"
    }
  },
  "confirm": {
    "title": "위젯 테스트 체험을 완료하시겠습니까?",
    "message": "테스트 체험을 완료할 경우 다시 진행할 수 없습니다. 완료하시겠습니까?"
  },
  "required_widget_tip_box":{
    "text_html": "작성 팝업은 메인, 상품 상세, 주문 조회, 마이 페이지에 설치되지만, 테스트 링크는 메인 페이지만 제공합니다.<br />필수 설치 위젯의 테스트 방법을 참고하여 진행해 주세요.<br/>· 리뷰 작성 팝업 : 로그아웃 상태에서 확인<br/>· 내 리뷰 위젯 : 로그인 상태에서 확인 (어떤 계정이든 가능)"
  },
  "contact_summary_box": {
    "title": "💡 궁금한 점이 있으신가요?",
    "content": "테스트 중 문의 사항이 있으실 경우 아래 [채팅 상담하기] 버튼을 클릭해 주세요. 문의 내용에 관한 스크린샷을 함께 첨부하시면 더욱 빠른 답변을 받아보실 수 있습니다.",
    "button_label": "채팅 상담하기"
  }
}
</i18n>
