var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'AppMultipleSelectProductCategory',
    {
      'AppMultipleSelectProductCategory--disabled': _vm.disabled,
      'AppMultipleSelectProductCategory--invalid': _vm.invalid
    }
  ],on:{"click":_vm.openMultipleSelectProductCategoryDialog}},[(_vm.productCategories.length)?_c('div',{staticClass:"AppMultipleSelectProductCategory__options"},[_c('ul',_vm._l((_vm.selectedProductCategoryOptions),function(option,i){return _c('li',{key:option.value,staticClass:"AppMultipleSelectProductCategory__option"},[_c('div',{staticClass:"AppMultipleSelectProductCategory__selection-wrapper",on:{"click":function($event){$event.stopPropagation();}}},[_c('AppSelection',{attrs:{"label":option.label},on:{"remove":function($event){return _vm.removeProductCategory(option.value)}}})],1),(
            _vm.productCategories.length > _vm.MAX_PRODUCT_CATEGORIES_COUNT &&
              i === _vm.MAX_PRODUCT_CATEGORIES_COUNT - 1
          )?_c('span',{staticClass:"AppMultipleSelectProductCategory__omitted-count"},[_vm._v(_vm._s(_vm.$t('omitted_count', [ _vm.productCategories.length - _vm.MAX_PRODUCT_CATEGORIES_COUNT ])))]):_vm._e()])}),0)]):_c('div',{staticClass:"AppMultipleSelectProductCategory__placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),(_vm.name)?_c('AppHiddenInputArray',{attrs:{"name":_vm.name,"values":_vm.productCategories}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }