<template>
  <div class="InstallRequestRenewalDirectionDialogProductDetail">
    <SlotRequestCard
      v-for="(slot, i) in slotTypes"
      :key="i"
      v-bind="slotRequestCardProps(slot)"
      class="InstallRequestRenewalDirectionDialogProductDetail__slot"
      @click="openInstallRequestRenewalSlotDialog(slot)"
    />
    <Banner
      class="InstallRequestRenewalDirectionDialogProductDetail__banner"
      url="https://sample.cre.ma/v2/review/recommendation/product_detail/110"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ShopBuilder from '@/enums/ShopBuilder';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetPageType from '@/enums/WidgetPageType';
import SlotRequestCard from './components/SlotRequestCard';
import Banner from './components/Banner';

export default {
  name: 'InstallRequestRenewalDirectionDialogProductDetail',
  components: { SlotRequestCard, Banner },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('installRequest', ['bubbleVisibility']),
    slotTypes() {
      return [
        InstallSlotType.PRODUCT_DETAIL_DEFAULT,
        InstallSlotType.PRODUCT_DETAIL_PRODUCT_THUMBNAIL_AFTER,
        InstallSlotType.PRODUCT_DETAIL_DESC_SECTION_TOP
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('installRequest', ['SET_BUBBLE_INVISIBLE']),
    isProductDetailDefaultSlot(slot) {
      return slot === InstallSlotType.PRODUCT_DETAIL_DEFAULT;
    },
    slotRequestCardProps(slot) {
      const key = InstallSlotType.key(slot).toLowerCase();
      return {
        imageUrl: `https://assets.cre.ma/m/admin/v2/install_request/${key}_slot.png`,
        title: this.$t(`${key}.title`, [
          ShopBuilder.tk(this.currentBrand.shop_builder)
        ]),
        description: this.$t(`${key}.description`, [
          ShopBuilder.tk(this.currentBrand.shop_builder)
        ]),
        bubbleMessage:
          this.bubbleVisibility.cardButton &&
          this.isProductDetailDefaultSlot(slot)
            ? this.$t(`${key}.bubble`)
            : '',
        isRequired: this.isProductDetailDefaultSlot(slot),
        slotType: slot,
        pageType: WidgetPageType.PRODUCT_DETAIL
      };
    },
    openInstallRequestRenewalSlotDialog(slot) {
      if (this.bubbleVisibility.cardButton)
        this.SET_BUBBLE_INVISIBLE('cardButton');
      if (this.isProductDetailDefaultSlot(slot)) {
        this.openDialog('InstallRequestRenewalSlotProductDetailDefaultDialog');
      } else if (
        slot === InstallSlotType.PRODUCT_DETAIL_PRODUCT_THUMBNAIL_AFTER
      ) {
        this.openDialog(
          'InstallRequestRenewalSlotProductDetailProductThumbnailAfterDialog'
        );
      } else {
        this.openDialog(
          'InstallRequestRenewalSlotProductDetailDescSectionTopDialog'
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.InstallRequestRenewalDirectionDialogProductDetail {
  margin-top: 24px;
}

.InstallRequestRenewalDirectionDialogProductDetail__slot {
  & + & {
    margin-top: 12px;
  }
}

.InstallRequestRenewalDirectionDialogProductDetail__banner {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "product_detail_default": {
    "title": "{0} 기본 리뷰 영역",
    "description": "{0}에서 기본으로 제공하던 리뷰 영역을 대체할 크리마 위젯을 선택해주세요.",
    "bubble": "[위젯 선택] 버튼을 눌러, 이 위치에 설치할 위젯을 골라보세요."
  },
  "product_detail_product_thumbnail_after": {
    "title": "상품 섬네일 하단",
    "description": "상품 섬네일과 함께 고객의 포토 리뷰를 함께 노출해보세요."
  },
  "product_detail_desc_section_top": {
    "title": "상세 정보 영역 최상단",
    "description": "상품의 상세 정보를 보기 전 고객의 리뷰를 효과적으로 노출해보세요."
  }
}
</i18n>
