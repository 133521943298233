<template>
  <AppModalDefault :title="$t('title')" @close="close">
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppForm :sections="sections" />
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'TargetCampaignCopyWidgetScriptDialog',
  mixins: [DialogView],
  props: { campaignId: { type: Number, required: true } },
  data() {
    return {
      isLoading: true,
      htmlTag: '',
      scriptTag: ''
    };
  },
  computed: {
    sections() {
      return [
        {
          id: 'script',
          label: this.$t('section_script'),
          groups: [
            {
              id: 'html_tag',
              label: 'HTML Tag',
              type: 'copyable_textarea',
              value: this.htmlTag
            },
            {
              id: 'script_tag',
              label: 'JavaScript',
              type: 'copyable_textarea',
              value: this.scriptTag
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaignId}/tag`)
      .then(({ data }) => {
        this.htmlTag = data.html_tag;
        this.scriptTag = data.script_tag;
      })
      .finally(() => (this.isLoading = false));
  }
};
</script>

<i18n locale="ko">
{
  "title": "위젯 스크립트 복사하기",
  "section_script": "사이트에 삽입할 스크립트"
}
</i18n>
