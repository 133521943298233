<template>
  <AppDrawer :title="$t('title')" :sub-title="corner.name" @close="close">
    <template #body>
      <AppTabs v-model="activeTab" :tabs="tabs" sticky />
      <Component
        :is="currentTabComponent"
        :key="activeTab"
        v-bind="currentTabProps"
        @reset-product-display-id="productDisplayId = null"
      />
    </template>
  </AppDrawer>
</template>

<script>
import DrawerView from '@/mixins/DrawerView';
import TargetContentView from '@/mixins/TargetContentView';

export default {
  name: 'DisplayCornerShowDrawer',
  mixins: [DrawerView, TargetContentView],
  props: {
    corner: { type: Object, required: true },
    tab: {
      type: String,
      default: 'product_displays',
      validator: v => ['product_displays', 'cohorts'].includes(v)
    }
  },
  data() {
    return { activeTab: this.tab, productDisplayId: null };
  },
  computed: {
    tabs() {
      return ['product_displays', 'cohorts'].map(id => ({
        id,
        label: this.$t(`tab.${id}`)
      }));
    },
    currentTabComponent() {
      switch (this.activeTab) {
        case 'product_displays':
          return 'DisplayCornerProductDisplays';
        case 'cohorts':
          return 'DisplayCornerCohorts';
        default:
          return 'div';
      }
    },
    currentTabProps() {
      const { corner, eventBus, productDisplayId } = this;
      switch (this.activeTab) {
        case 'product_displays':
          return { corner, eventBus };
        case 'cohorts':
          return { corner, productDisplayId, eventBus };
        default:
          return {};
      }
    }
  },
  mounted() {
    this.eventBus.$on('show-cohorts', productDisplay => {
      this.activeTab = 'cohorts';
      this.productDisplayId = productDisplay.id;
    });
  }
};
</script>

<i18n locale="ko">
{
  "title": "진열 설정 관리",
  "tab": {
    "product_displays": "진열 설정",
    "cohorts": "진열 이력"
  }
}
</i18n>
