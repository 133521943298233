<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppCheckbox v-model="basic" />
      <div class="ui-section__description">Selected Value: {{ basic }}</div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">With Label</div>
      <AppCheckbox v-model="label" label="Label" />
      <div class="ui-section__description">Selected Value: {{ label }}</div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Right Position Checkbox</div>
      <AppCheckbox v-model="right" label="Label" :input-position="'right'" />
      <div class="ui-section__description">Selected Value: {{ right }}</div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Disabled</div>
      <AppCheckbox v-model="disabled" label="Disabled" disabled />
      <div class="ui-section__description">Selected Value: {{ disabled }}</div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Invalid</div>
      <AppCheckbox v-model="invalid" label="Invalid" invalid />
      <div class="ui-section__description">Selected Value: {{ invalid }}</div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">SlotLabel</div>
      <AppCheckbox v-model="valueSlotLabel">
        <template #label>
          Option with input
          <AppNumberInput :disabled="!valueSlotLabel" :digits="2" />
        </template>
      </AppCheckbox>
      <div class="ui-section__description">
        Selected Value: {{ valueSlotLabel }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Partial</div>
      <div><AppCheckboxPartial v-model="partialCheckStatus" /></div>
      <div>
        <AppButton
          label="Make Partial"
          @click="partialCheckStatus = 'partial'"
        />
      </div>
      <div class="ui-section__description">
        Selected Value: {{ partialCheckStatus }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">AgreementCheckbox</div>
      <div>
        <AppAgreementCheckbox
          v-model="agreementChecked"
          label="Check if you agree"
          terms-link="https://assets.cre.ma/m/pdf/crema_tos.pdf"
        />
      </div>
      <div class="ui-section__description">
        Selected Value: {{ agreementChecked }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiCheckbox',
  data() {
    return {
      basic: true,
      label: false,
      right: true,
      disabled: false,
      invalid: false,
      valueSlotLabel: false,
      partialCheckStatus: 'partial',
      agreementChecked: false
    };
  }
};
</script>
