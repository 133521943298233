<template>
  <div class="AppEventReportsFilterColumn">
    <AppSelectFilterable
      :value="columnFilter.column"
      :placeholder="$t('select_column')"
      :options="columnOptions"
      class="AppEventReportsFilterColumn__select-filterable"
      @change="$emit('update:column', $event)"
    />
    <AppSelect
      :value="columnFilter.operator"
      :options="OPERATOR_OPTIONS"
      class="AppEventReportsFilterColumn__select"
      @change="$emit('update:operator', $event)"
    />
    <AppTextInput
      v-if="requiresValue"
      :value="columnFilter.value"
      :placeholder="$t('enter_column_field')"
      @change="$emit('update:value', $event)"
    />
    <AppButton
      class="AppEventReportsFilterColumn__remove-button"
      button-style="red-outline"
      :label="$t('remove_column_filter')"
      @click="$emit('remove')"
    />
  </div>
</template>

<script>
export default {
  name: 'AppEventReportsFilterColumn',
  props: {
    columnFilter: { type: Object, default: () => {} },
    columnOptions: { type: Array, required: true }
  },
  data() {
    return {
      OPERATOR_OPTIONS: [
        {
          label: this.$t('operator_include'),
          value: 'include'
        }
      ]
    };
  },
  computed: {
    requiresValue() {
      return this.columnFilter.column && this.columnFilter.operator;
    }
  },
  watch: {
    requiresValue(newValue) {
      if (!newValue) {
        this.columnFilter.value = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppEventReportsFilterColumn {
  margin-top: 16px;
  padding-top: 0;
  padding-left: 16px;
  border-left: 1px solid $color-form-border;

  & + & {
    margin-top: 0;
    padding-top: 8px;
  }

  .AppTextInput {
    width: auto;
    margin-left: 8px;
    vertical-align: middle;
  }
}

.AppEventReportsFilterColumn__select {
  vertical-align: middle;
  padding-left: 8px;
}

.AppEventReportsFilterColumn__remove-button {
  margin-left: 8px;
}
</style>

<i18n locale="ko">
{
  "select_column": "속성 선택",
  "operator_include": "다음을 포함",
  "enter_column_field": "속성값 입력",
  "remove_column_filter": "필터 삭제"
}
</i18n>
