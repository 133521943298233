import _ from 'lodash';
import Vue from 'vue';

const state = { jobs: [] };

const mutations = {
  ADD_JOB({ jobs }, job) {
    jobs.push({
      id: _.uniqueId('job'),
      status: 'pending',
      progressCount: 0,
      startedAt: null,
      result: null,
      ...job
    });
  },
  RUN_JOB({ jobs }, id) {
    const job = jobs.find(j => j.id === id);
    if (!job) return;

    Vue.set(job, 'status', 'running');
    Vue.set(job, 'startedAt', new Date());
  },
  PROGRESS_JOB({ jobs }, id) {
    const job = jobs.find(j => j.id === id);
    if (job) Vue.set(job, 'progressCount', job.progressCount + 1);
  },
  COMPLETE_JOB({ jobs }, { id, result }) {
    const job = jobs.find(j => j.id === id);
    if (job) {
      Vue.set(job, 'status', 'complete');
      Vue.set(job, 'result', result);
    }
  },
  REMOVE_JOB(state, id) {
    state.jobs = state.jobs.filter(j => j.id !== id);
  }
};

const actions = {
  enqueueJob({ state, commit, dispatch }, job) {
    commit('ADD_JOB', job);
    if (state.jobs.every(j => j.status !== 'running')) dispatch('runNextJob');
  },
  async runNextJob({ state, commit, dispatch }) {
    const job = state.jobs.find(j => j.status === 'pending');
    if (!job) return;

    commit('RUN_JOB', job.id);

    const result = await job.jobHandler({
      progress: () => commit('PROGRESS_JOB', job.id)
    });

    const waitForTransition = () => new Promise(r => setTimeout(r, 50));
    await waitForTransition();

    commit('COMPLETE_JOB', { id: job.id, result });
    if (job.showResultImmediatly) dispatch('completeJob', job.id);

    dispatch('runNextJob');
  },
  cancelJob({ state, commit }, id) {
    const job = state.jobs.find(j => j.id === id);
    if (job?.status === 'pending') {
      commit('REMOVE_JOB', id);
      return true;
    } else return false;
  },
  completeJob({ state, commit }, id) {
    const job = state.jobs.find(j => j.id === id);
    if (job?.status === 'complete') {
      if (job.resultHandler) job.resultHandler(job.result);
      commit('REMOVE_JOB', id);
      return true;
    } else return false;
  }
};

export default { namespaced: true, state, mutations, actions };
