<template>
  <div
    v-focus
    tabindex="0"
    class="AppDropdownMenu dropdown__dialog"
    @keydown.down.prevent="down"
    @keydown.up.prevent="up"
    @keydown.tab="tabClicked"
    @blur="blur"
  >
    <template v-for="(items, i) in groups">
      <hr
        v-if="i !== 0"
        :key="`${i}-divider`"
        class="AppDropdownMenu__divider"
      />
      <ul :key="`${i}-group`" class="AppDropdownMenu__menu-group">
        <li
          v-for="(item, j) in items"
          :key="j"
          ref="items"
          :class="{ 'super-admin__item': item.superAdmin }"
          @mousemove="activateItem(item)"
        >
          <slot :id="item.id" name="item">
            <div v-tooltip="item.tooltip">
              <a
                v-if="item.type === 'file_link'"
                tabindex="-1"
                :class="buttonClass(item)"
                download
                @click.prevent="clickFileLinkItem(item)"
                >{{ item.label }}</a
              >
              <AppInternalLink
                v-else-if="item.type === 'link'"
                tabindex="-1"
                :class="buttonClass(item)"
                :to="item.to"
                @click="clickItem(item)"
                >{{ item.label }}</AppInternalLink
              >
              <AppExternalLink
                v-else-if="item.type === 'external_link'"
                tabindex="-1"
                :class="buttonClass(item)"
                :to="item.to"
                @click="clickItem(item)"
              >
                {{ item.label }}
                <AppExternalLinkIcon class="AppDropdownMenu__menu-icon" />
              </AppExternalLink>
              <AppOldAdminLink
                v-else-if="item.type === 'old_admin_link'"
                tabindex="-1"
                :class="buttonClass(item)"
                :to="item.to"
                @click="clickItem(item)"
              >
                {{ item.label }}
                <AppExternalLinkIcon class="AppDropdownMenu__menu-icon" />
              </AppOldAdminLink>
              <button
                v-else-if="item.type === 'switch'"
                tabindex="-1"
                :class="buttonClass(item)"
                :disabled="item.disabled"
                type="button"
                @click="clickItem(item)"
              >
                {{ item.label }}
                <div class="AppDropdownMenu__menu-link-switch-wrapper">
                  <AppSwitch
                    :checked="item.checked"
                    :disabled="item.disabled"
                  />
                </div>
              </button>
              <AppExpandableItem
                v-else-if="item.type === 'expandable'"
                tabindex="-1"
                :menu-items="item.subItems"
                :class="buttonClass(item)"
                @click="clickItem(item)"
              >
                {{ item.label }}
                <AppSvgIcon
                  name="nav-chevron-right"
                  class="AppDropdownMenu__menu-icon"
                />
              </AppExpandableItem>
              <button
                v-else
                tabindex="-1"
                type="button"
                :disabled="item.disabled"
                :class="buttonClass(item)"
                @click="clickItem(item)"
              >
                <span
                  v-if="item.title"
                  :class="{
                    AppDropdownMenu__highlight: true,
                    'AppDropdownMenu__highlight--disabled': item.disabled
                  }"
                >
                  {{ item.title }}
                </span>
                {{ item.label }}
                <AppBadge
                  v-if="item.badge"
                  v-bind="item.badge"
                  class="AppDropdownMenu__badge"
                />
              </button>
            </div>
          </slot>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import focusChild from '@/lib/focusChild';

export default {
  name: 'AppDropdownMenu',
  props: { menuItems: { type: Array, required: true } },
  data() {
    return { activeIndex: -1 };
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    isGroup() {
      return this.menuItems.length && Array.isArray(this.menuItems[0]);
    },
    visibleMenuItems() {
      const isVisible = i => !i.superAdmin || this.isSuperAdminAccessible;

      if (this.isGroup)
        return this.menuItems
          .map(items => items.filter(isVisible))
          .filter(items => items.length);
      else return this.menuItems.filter(isVisible);
    },
    groups() {
      return this.isGroup ? this.visibleMenuItems : [this.visibleMenuItems];
    },
    flatMenuItems() {
      return _.flatten(this.visibleMenuItems);
    },
    activeItem: {
      get() {
        return this.flatMenuItems[this.activeIndex];
      },
      set(item) {
        this.activeIndex = this.flatMenuItems.indexOf(item);
      }
    }
  },
  watch: {
    menuItems() {
      if (this.activeIndex >= this.flatMenuItems.length) this.activeIndex = 0;
    },
    activeIndex() {
      const itemEl = this.$refs.items[this.activeIndex];
      focusChild(itemEl);
    }
  },
  mounted() {
    this.activeIndex = this.flatMenuItems.findIndex(i => !i.disabled);
  },
  methods: {
    buttonClass(item) {
      return [
        'AppDropdownMenu__menu-link',
        { 'AppDropdownMenu__menu-link--active': item === this.activeItem },
        { 'AppDropdownMenu__menu-link--selected': item.selected },
        { 'AppDropdownMenu__menu-link--disabled': item.disabled },
        item.style ? `AppDropdownMenu__menu-link--${item.style}` : null,
        item.class
      ];
    },
    clickFileLinkItem(item) {
      if (item.disabled) return;

      if (!item.clickHandler) return this.close();

      item.clickHandler({
        close: this.close,
        click: () => (window.location.href = item.url)
      });
    },
    clickItem(item) {
      if (item.disabled) return;

      if (item.clickHandler) item.clickHandler({ close: this.close });
      else this.close();
    },
    close() {
      this.$emit('close');
    },
    down() {
      for (let i = this.activeIndex + 1; i < this.flatMenuItems.length; i++) {
        if (this.flatMenuItems[i].disabled !== true) {
          this.activeIndex = i;
          break;
        }
      }
    },
    up() {
      for (let i = this.activeIndex - 1; i >= 0; i--) {
        if (this.flatMenuItems[i].disabled !== true) {
          this.activeIndex = i;
          break;
        }
      }
    },
    tabClicked() {
      this.close();
    },
    blur(e) {
      // Force focus to root element as long as the focus is within its decendants
      if (this.$el.contains(e.relatedTarget))
        setTimeout(() => this.$el.focus());
    },
    activateItem(item) {
      if (!item.disabled) this.activeItem = item;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_clearfix.scss';

.AppDropdownMenu {
  min-width: 182px;

  &:focus {
    outline: none;
  }
}

.AppDropdownMenu__menu-group {
  padding: 9px 7px;
}

$menu-item-padding: 6px 8px;

.AppDropdownMenu__menu-link {
  display: block;
  width: 100%;
  cursor: pointer;
  padding: $menu-item-padding;
  position: relative;
  text-align: left;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &--active {
    background-color: $color-nav-selected;
    border-radius: 3px;
  }

  &--danger {
    color: $color-red;
  }

  &--selected {
    &:before {
      content: '✓';
      font-family: 'LucidaGrande';
      margin-right: 4px;
    }
  }

  &--disabled {
    pointer-events: none;
    color: $color-disable-text;
  }
}

.AppDropdownMenu__highlight {
  color: $color-blue;

  &--disabled {
    color: $color-disable-text;
  }
}

.AppDropdownMenu__menu-link-switch-wrapper {
  pointer-events: none;
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 0;
}

.AppDropdownMenu__badge {
  pointer-events: none;
  position: absolute;
  top: 6px;
  right: 8px;
}

.AppDropdownMenu__menu-icon {
  position: absolute;
  top: 9px;
  right: 8px;
}
</style>

<style lang="scss">
@import '@/scss/vars/_colors.scss';

.AppDropdownMenu__divider {
  height: 1px;
  background-color: $color-layout-section;
  border: none;
  margin: 0;
}
</style>
