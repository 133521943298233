<template>
  <AppAjaxContent :is-loading="isLoading">
    <template #default>
      <AppSearchbar>
        <template #right>
          <li>
            <AppSelect
              v-model="optionTypeId"
              :options="optionTypes.map(t => ({ label: t.name, value: t.id }))"
              @change="searchResource"
            />
          </li>
          <li>
            <AppButtonToggle
              v-model="isAdvancedSearchVisible"
              :label="$t('app.advanced_search')"
            />
          </li>
        </template>

        <template #advanced-search>
          <AppAdvancedSearch
            v-model="resourceParams"
            :visible="isAdvancedSearchVisible"
            :sections="advancedSearchSections"
            @submit="searchResource"
          >
            <template #group="{ id, inputId }">
              <template v-if="id === 'product_filter'">
                <div class="AppForm__group-field">
                  <AppSelect
                    :id="inputId"
                    v-model="resourceParams.product_filter"
                    :options="
                      (isShopifyBrand
                        ? ['product']
                        : ['category', 'product']
                      ).map(value => ({
                        label: $t(`product_filter.${value}`),
                        value
                      }))
                    "
                    :placeholder="$t('search.all')"
                    @change="changeProductFilter"
                  />
                </div>
                <div
                  v-if="resourceParams.product_filter === 'category'"
                  class="AppForm__group-field"
                >
                  <AppSelectProductCategory
                    v-model="resourceParams.category_ids"
                    packing-method="string"
                    multiple
                  />
                </div>
                <div
                  v-else-if="resourceParams.product_filter === 'product'"
                  class="AppForm__group-field"
                >
                  <AppSelectProduct
                    :products="products"
                    packing-method="string"
                    multiple
                    @change="resourceParams.product_ids = $event"
                  />
                </div>
              </template>
              <div v-if="id === 'user_filter'" class="AppForm__group-field">
                <AppSelect
                  :id="inputId"
                  v-model="resourceParams.user_filter"
                  :options="
                    ['click', 'purchase'].map(value => ({
                      label: $t(`user_filter.${value}`),
                      value
                    }))
                  "
                  :placeholder="$t('search.all')"
                  @change="changeUserFilter"
                />
                <i18n
                  v-if="resourceParams.user_filter"
                  class="Analytics__sub-field"
                  :path="
                    `user_filter_recent_days.${resourceParams.user_filter}`
                  "
                >
                  <AppNumberInput
                    v-model="resourceParams.recent_days"
                    :default="1"
                    :digits="2"
                    inline
                  />
                </i18n>
              </div>
            </template>
          </AppAdvancedSearch>
        </template>
      </AppSearchbar>
      <div class="Analytics__summary">
        <template v-if="totalUsersCount">
          {{
            $t('summary', [activeUsersCount, optionType.name, usersPercentage])
          }}
        </template>
        <template v-else>{{ $t('summary_no_data') }}</template>
      </div>
      <AppChart :options="chartOptions" />
      <AppResourceTable
        :table-name="$t('table.title', [optionType.name])"
        :rows="[totalRow, ...rows]"
        :columns="COLUMNS"
        :enable-total-count="false"
        table-style="stats"
      />
    </template>
  </AppAjaxContent>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import { mapActions, mapGetters } from 'vuex';
import HttpStatus from '@/enums/HttpStatus';
import ResourceView from '@/mixins/ResourceView';

export default {
  name: 'Analytics',
  mixins: [ResourceView],
  props: {
    type: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'option_type', label: this.$t('table.option_type') },
        {
          id: 'users_count',
          label: this.$t('table.users_count'),
          type: 'number',
          default: '-',
          align: 'right'
        },
        {
          id: 'active_users_count',
          label: this.$t('table.active_users_count'),
          tooltip: this.$t('table.active_users_count_tooltip'),
          type: 'number',
          default: '-',
          align: 'right'
        },
        {
          id: 'users_percentage',
          label: this.$t('table.users_percentage'),
          type: 'percentage',
          default: '-',
          align: 'right'
        }
      ],
      isLoading: true,
      optionTypes: [],
      activeUsersCount: 0,
      reports: [],
      products: [],
      isAdvancedSearchVisible: ['product_filter', 'user_filter'].some(
        k => k in this.$route.query
      )
    };
  },
  computed: {
    ...mapGetters('session', ['isShopifyBrand']),
    defaultResourceParams: () => ({}),
    advancedSearchSections() {
      return [
        {
          groups: [
            {
              id: 'product_filter',
              label: this.$t('search.product_filter')
            },
            {
              id: 'user_filter',
              label: this.$t('search.user_filter')
            }
          ]
        }
      ];
    },
    optionTypeId: {
      get() {
        return parseInt(
          this.resourceParams.option_type_id || this.optionTypes[0].id
        );
      },
      set(val) {
        if (this.optionTypes[0].id === val)
          this.$delete(this.resourceParams, 'option_type_id');
        else this.$set(this.resourceParams, 'option_type_id', val);
      }
    },
    optionType() {
      return this.optionTypes.find(t => t.id === this.optionTypeId);
    },
    usersPercentage() {
      return this.activeUsersCount === 0
        ? 0
        : _.round((this.totalUsersCount / this.activeUsersCount) * 100, 1);
    },
    totalUsersCount() {
      return this.reports.reduce((a, r) => a + r.count, 0);
    },
    rows() {
      return this.reports.map(report => {
        const rate = this.totalUsersCount
          ? report.count / this.totalUsersCount
          : 0;
        const users_percentage = this.totalUsersCount === 0 ? 0 : rate * 100;
        return {
          option_type: report.key,
          users_count: report.count,
          active_users_count: Math.round(this.activeUsersCount * rate),
          users_percentage,
          rowStyle: 'stats-summary'
        };
      });
    },
    totalRow() {
      return {
        rowStyle: 'stats-total',
        option_type: this.$t('table.total_option_types'),
        users_count: this.totalUsersCount,
        active_users_count: this.totalUsersCount ? this.activeUsersCount : 0,
        users_percentage: this.totalUsersCount ? 100 : 0
      };
    },
    chartData() {
      return this.rows.map((row, x) => ({
        x,
        y: row.users_count,
        percentage: row.users_percentage
      }));
    },
    chartOptions() {
      return {
        chart: { type: 'column', animation: false },
        title: { text: this.optionType.name },
        tooltip: {
          pointFormat: `${this.$t('unit.people', [
            '{point.y}'
          ])} ({point.percentage:.1f}%)`
        },
        xAxis: { categories: this.reports.map(r => r.key) },
        yAxis: [
          {
            title: { text: this.$t('chart.users_count') },
            labels: { format: this.$t('unit.people', ['{value:,.0f}']) }
          }
        ],
        series: [
          { name: this.optionType.name, data: this.chartData, color: '#6db6ee' }
        ]
      };
    }
  },
  methods: {
    ...mapActions('session', ['redirectToHome']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/analytics/review_option_reports', {
          params: { ...params, type: this.type }
        })
        .then(({ data }) => {
          this.optionTypes = data.option_types;
          this.activeUsersCount = data.brand_users_count;
          this.reports = data.reports;
          this.products = data.products;
        })
        .catch(error => {
          if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.redirectToHome();
          } else {
            error.errorHandler();
          }
        })
        .finally(() => (this.isLoading = false));
    },
    changeProductFilter() {
      if (!this.resourceParams.product_filter !== 'category')
        this.$delete(this.resourceParams, 'category_ids');

      if (!this.resourceParams.product_filter !== 'product')
        this.$delete(this.resourceParams, 'product_ids');
    },
    changeUserFilter() {
      if (!this.resourceParams.user_filter)
        this.$delete(this.resourceParams, 'recent_days');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.Analytics__summary {
  @include text-content;
  background-color: $color-grey-15;
  border-radius: 3px;
  padding: 12px;
  text-align: center;
  margin-bottom: 8px;
}

.Analytics__sub-field {
  margin-left: 8px;
}
</style>

<i18n locale="ko">
{
  "search": {
    "product_filter": "상품 필터링",
    "user_filter": "유저 필터링",
    "all": "전체"
  },
  "product_filter": {
    "category": "선택 카테고리 보기",
    "product": "선택 상품 보기"
  },
  "user_filter": {
    "click": "필터링 상품 클릭",
    "purchase": "필터링 상품 구매"
  },
  "user_filter_recent_days": {
    "click": "최근 {0}일 간 필터링 대상 상품을 클릭한 이력이 있는 고객",
    "purchase": "최근 {0}일 간 필터링 대상 상품을 구매한 이력이 있는 고객"
  },
  "table": {
    "title": "고객 {0} 표",
    "option_type": "구간",
    "users_count": "옵션 입력 고객수",
    "active_users_count": "활동 고객수",
    "active_users_count_tooltip": "활동 고객은 리뷰 작성, 상품 구매, 리뷰 도움 됐음/안됐음 클릭, 댓글 작성 등을 한 고객으로\n실제 쇼핑몰에서 적극적인 활동을 하는 고객을 나타냅니다.",
    "users_percentage": "구간별 고객 비율",
    "total_option_types": "전체 구간"
  },
  "chart": {
    "users_count": "고객수"
  },
  "summary": "필터링 대상고객 {0}명 중 {1} 옵션을 입력한 {2}% 고객의 데이터를 분석한 통계치입니다.",
  "summary_no_data": "필터링 대상고객이 없습니다."
}
</i18n>
