<template>
  <AppAjaxContent
    :is-loading="isLoading"
    class="ReviewSmartInstallationHideWidgetManagement"
  >
    <AppButton
      :label="$t('title_new')"
      button-style="red"
      @click="newHideWidgetClicked"
    />
    <AppInfoBox class="ReviewSmartInstallationHideWidgetManagement__info-box">
      {{ $t('info_box.hide_widget_management') }}
    </AppInfoBox>
    <AppResourceTable
      table-id="hideWidgetTable"
      :columns="COLUMNS"
      :rows="rows"
      :event-bus="tableEventBus"
    >
      <template #cell="{ column, rowIndex }">
        <template v-if="column === 'actions'">
          <AppButton
            :label="$t('app.settings')"
            @click="hideWidgetSettingsClicked(rowIndex)"
          />
          <AppDropdownMenuButton
            :label="$t('app.manage')"
            :menu-items="[
              [
                {
                  label: $t('move_to_shop.label'),
                  type: 'external_link',
                  to: rows[rowIndex].inserted_url,
                  disabled: !rows[rowIndex].inserted_url,
                  tooltip: rows[rowIndex].inserted_url
                    ? null
                    : $t('move_to_shop.not_found_inserted_url')
                },
                {
                  label: $t('app.delete'),
                  style: 'danger',
                  clickHandler: ({ close }) => deleteHideWidget(rowIndex, close)
                }
              ]
            ]"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import api from '@/lib/api';
import { mapActions } from 'vuex';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import SmartInstallationHelper from '@/mixins/SmartInstallationHelper';
import SmartInstallationType from '@/enums/SmartInstallationType';

export default {
  name: 'ReviewSmartInstallationHideWidgetManagement',
  mixins: [SmartInstallationHelper],
  data() {
    return {
      isLoading: true,
      hideWidgets: [],
      tableEventBus: null,
      COLUMNS: [
        { id: 'installation_id', label: this.$t('table_head.installation_id') },
        { id: 'device_type', label: this.$t('table_head.device_type') },
        { id: 'page_type', label: this.$t('table_head.page_type') },
        {
          id: 'element_selector',
          label: this.$t('table_head.element_selector')
        },
        { id: 'skin_id', label: this.$t('table_head.skin_id') },
        { id: 'actions', label: this.$t('app.actions') }
      ]
    };
  },
  computed: {
    rows() {
      return this.hideWidgets.map(hideWidget => {
        const installation = {
          ...hideWidget,
          isMobile: hideWidget.device_type === WidgetDeviceType.MOBILE
        };
        return {
          installation_id: hideWidget.id,
          device_type: WidgetDeviceType.t(hideWidget.device_type),
          page_type: this.getPageTypeOrPath(hideWidget),
          element_selector: hideWidget.element_selector,
          skin_id: this.formatSkinId(hideWidget.skin_id),
          inserted_url: this.insertedUrl(installation)
        };
      });
    }
  },
  created() {
    this.tableEventBus = new Vue();
  },
  mounted() {
    api
      .get('/smart_installations', {
        params: { installation_type: SmartInstallationType.HIDE_ELEMENT }
      })
      .then(({ data }) => {
        this.hideWidgets = data.smart_installations;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    newHideWidgetClicked() {
      this.openDialog(['ReviewSmartInstallationHideWidgetFormDialog']).then(
        eventBus => {
          eventBus.$on('save', hideWidget => {
            this.hideWidgets.push(hideWidget);
            this.$nextTick(() => {
              this.tableEventBus.$emit(
                'focus-row',
                this.hideWidgets.length - 1
              );
            });
          });
        }
      );
    },
    hideWidgetSettingsClicked(index) {
      this.openDialog([
        'ReviewSmartInstallationHideWidgetFormDialog',
        {
          hideWidget: this.hideWidgets[index]
        }
      ]).then(eventBus => {
        eventBus.$on('update', hideWidget => {
          Vue.set(this.hideWidgets, index, hideWidget);
        });
      });
    },
    deleteHideWidget(index, close) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      api
        .delete(`/smart_installations/${this.hideWidgets[index].id}`)
        .then(() => {
          Vue.delete(this.hideWidgets, index);
          close();
        });
    },
    formatSkinId(skin_id) {
      return skin_id || this.$t('smart_installations.all_skin');
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewSmartInstallationHideWidgetManagement__info-box {
  margin: 28px 0;
}

::v-deep {
  .hideWidgetTable__installation-id {
    flex: 1 0 10%;
    min-width: 90px;
  }
  .hideWidgetTable__device-type {
    flex: 1 0 15%;
    min-width: 125px;
  }
  .hideWidgetTable__page-type {
    flex: 1 0 20%;
    min-width: 180px;
  }
  .hideWidgetTable__element-selector {
    flex: 1 0 25%;
    min-width: 200px;
  }
  .hideWidgetTable__skin-id {
    flex: 1 0 10%;
    min-width: 100px;
  }
  .hideWidgetTable__actions {
    flex: 1 0 20%;
    min-width: 200px;
  }
}
</style>

<i18n locale="ko">
{
  "title_new": "기존 위젯 숨김 추가",
  "info_box": {
    "hide_widget_management": "설정 후 [관리 > 쇼핑몰 이동] 기능을 통해 쇼핑몰 페이지에 기존 위젯이 잘 숨김되었는지 확인해 주세요."
  },
  "table_head": {
    "installation_id": "설치 ID",
    "device_type": "디바이스 타입",
    "page_type": "관련 페이지",
    "element_selector": "숨김 위젯",
    "skin_id": "적용 스킨"
  },
  "move_to_shop": {
    "label": "쇼핑몰로 이동",
    "not_found_inserted_url": "위젯이 삽입된 페이지의 접속 정보를 찾을 수 없습니다."
  }
}
</i18n>
