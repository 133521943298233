<template>
  <TheAccountMenuPopupService
    v-if="isDisplayMenuAccessible"
    service="display"
    :is-launched="displaySettings.launch_display_menu"
    :items="items"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TheAccountMenuPopupService from './TheAccountMenuPopupService';

export default {
  name: 'TheAccountMenuPopupServiceDisplay',
  components: { TheAccountMenuPopupService },
  computed: {
    ...mapState('session', ['displayStatus', 'displaySettings']),
    ...mapGetters('alert', ['displayRemainingDates']),
    ...mapGetters('session', [
      'isDisplayTesting',
      'isDisplayFreeTrial',
      'isDisplayMenuAccessible'
    ]),
    items() {
      const { display_expires_at } = this.displayStatus;

      if (this.isDisplayTesting)
        return [{ title: this.$t('expires_on'), content: this.$t('testing') }];
      else if (this.isDisplayFreeTrial)
        return [
          {
            title: this.$t('free_trial_expires_on'),
            content: this.formatDate(display_expires_at),
            contentTooltip: this.expirationTooltip
          }
        ];
      else
        return [
          {
            title: this.$t('expires_on'),
            content: this.formatDate(display_expires_at),
            contentTooltip: this.expirationTooltip
          }
        ];
    },
    expirationTooltip() {
      return this.displayRemainingDates >= 0
        ? this.$t('days_before_expiration', [this.displayRemainingDates])
        : this.$t('days_after_expiration', [-this.displayRemainingDates]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "expires_on": "만료일",
  "free_trial_expires_on": "무료체험 만료일",
  "testing": "테스트 중",
  "days_before_expiration": "잔여 {0}일",
  "days_after_expiration": "{0}일 전 만료"
}
</i18n>
