import { render, staticRenderFns } from "./TheMoreMenuPopup.vue?vue&type=template&id=4cac8089&scoped=true&"
import script from "./TheMoreMenuPopup.vue?vue&type=script&lang=js&"
export * from "./TheMoreMenuPopup.vue?vue&type=script&lang=js&"
import style0 from "./TheMoreMenuPopup.vue?vue&type=style&index=0&id=4cac8089&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cac8089",
  null
  
)

/* custom blocks */
import block0 from "./TheMoreMenuPopup.vue?vue&type=custom&index=0&blockType=i18n&locale=ko"
if (typeof block0 === 'function') block0(component)

export default component.exports