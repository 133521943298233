import Hangul from 'hangul-js';
import hangulRangeSearch from './vendor/hangulRangeSearch';

function findRanges(text, keywords) {
  const ranges = [];
  keywords.forEach(function(keyword) {
    const localRanges = hangulRangeSearch(text, keyword, true);
    localRanges.forEach(range => ranges.push(range));
  });
  return ranges;
}

function mergeRanges(ranges) {
  ranges.sort((a, b) => a[0] - b[0] || a[1] - b[1]);

  const mergedRanges = [];
  let currentRange = ranges[0];
  for (let i = 1; i < ranges.length; ++i) {
    const range = ranges[i];
    const isMergeable = currentRange[1] + 1 >= range[0];
    if (isMergeable) {
      currentRange[1] = range[1];
    } else {
      mergedRanges.push(currentRange);
      currentRange = range;
    }
  }
  mergedRanges.push(currentRange);
  return mergedRanges;
}

function highlightedTextWithRanges(text, ranges, tag) {
  let highlightedText = '';
  let lastIndex = 0;
  ranges.forEach(function(range) {
    highlightedText += text.slice(lastIndex, range[0]);
    highlightedText += `<${tag}>${text.slice(range[0], range[1] + 1)}</${tag}>`;
    lastIndex = range[1] + 1;
  });
  highlightedText += text.slice(lastIndex, text.length);

  return highlightedText;
}

export const highlightedText = function(text, keywords, tag = 'em') {
  if (!keywords) return text;

  if (!Array.isArray(keywords)) keywords = Array.of(keywords);

  const ranges = findRanges(text, keywords);
  if (!ranges.length) return text;

  const mergedRanges = mergeRanges(ranges);

  return highlightedTextWithRanges(text, mergedRanges, tag);
};

export const filterItems = function(items, textKey, keyword, keywords = []) {
  if (!keyword) return items;

  keywords.push(...keyword.toLowerCase().split(/[\s/]+/));

  const searchers = keywords.map(k => new Hangul.Searcher(k));
  return items
    .filter(item => item[textKey])
    .filter(item => {
      const text = item[textKey].replace(/[\s/]/g, '').toLowerCase();
      return searchers.every(s => s.search(text) !== -1);
    });
};

export const filterItemsWithHighlightedText = function(
  items,
  textKey,
  keyword,
  tag = 'em'
) {
  const keywords = [];
  const filteredItems = filterItems(items, textKey, keyword, keywords);
  const highlightedTextKey = `${textKey}Highlighted`;
  return filteredItems.map(item => {
    const highlighted = highlightedText(item[textKey], keywords, tag);
    return { ...item, [highlightedTextKey]: highlighted };
  });
};
