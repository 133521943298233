<template>
  <div :class="['AppScrollBox', { 'AppScrollBox--enabled': isEnabled }]">
    <div
      ref="content"
      class="AppScrollBox__content"
      @scroll="e => isEnabled && scroll(e)"
    >
      <slot />
    </div>
    <Transition name="fade">
      <div
        v-if="isEnabled && (isScrolling || isDragging)"
        ref="scrollbar"
        :class="[
          'AppScrollBox__scrollbar',
          { 'AppScrollBox__scrollbar--dragging': isDragging }
        ]"
        :style="{ top: scrollbarTop, height: scrollbarHeight }"
        @mouseenter="mouseenter"
        @mousedown="mousedown"
      />
    </Transition>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

const RUNNING_TIME = 1000;

export default {
  name: 'AppScrollBox',
  data: () => ({
    isScrolling: false,
    scrollRadio: 0,
    scrollbarTop: null,
    scrollbarHeight: null,
    isDragging: false,
    lastPageY: 0
  }),
  computed: {
    ...mapGetters(['isWindows']),
    isEnabled() {
      return this.isWindows;
    }
  },
  mounted() {
    this.stopScrollDebounced = _.debounce(this.stopScroll, RUNNING_TIME);
  },
  methods: {
    scroll() {
      if (!this.isScrolling) this.startScroll();

      const { scrollTop, scrollHeight } = this.$refs.content;
      this.scrollbarTop = `${(scrollTop / scrollHeight) * 100}%`;
      this.stopScrollDebounced();
    },
    startScroll() {
      const { scrollHeight, clientHeight } = this.$refs.content;
      this.scrollRadio = clientHeight / scrollHeight;
      const scrollbarHeight = Math.max(this.scrollRadio, 0.1);
      this.scrollbarHeight = `${scrollbarHeight * 100}%`;
      this.isScrolling = true;
    },
    stopScroll() {
      this.isScrolling = false;
    },
    mouseenter({ target }) {
      this.stopScrollDebounced();
      const key = setInterval(this.stopScrollDebounced, RUNNING_TIME * 0.5);
      target.addEventListener('mouseleave', () => clearInterval(key), {
        once: true
      });
    },
    mousedown({ pageY }) {
      this.startDrag(pageY);
    },
    startDrag(pageY) {
      this.lastPageY = pageY;
      this.isDragging = true;
      document.body.style.setProperty('user-select', 'none');
      document.addEventListener('mousemove', this.drag);
      document.addEventListener('mouseup', this.stopDrag, { once: true });
    },
    stopDrag() {
      this.isDragging = false;
      document.body.style.setProperty('user-select', '');
      document.removeEventListener('mousemove', this.drag);
    },
    drag({ pageY }) {
      const delta = pageY - this.lastPageY;
      this.lastPageY = pageY;
      this.$refs.content.scrollTop += delta / this.scrollRadio;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_transitions.scss';

.AppScrollBox {
  position: relative;

  &--enabled .AppScrollBox__content::-webkit-scrollbar {
    display: none;
  }
}

.AppScrollBox__content {
  height: 100%;
  overflow: auto;
}

.AppScrollBox__scrollbar {
  position: absolute;
  right: 0;
  width: 11px;
  @include transition-basic(width);

  &:hover,
  &--dragging {
    width: 13px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 18px;
    left: 2px;
    border-radius: 999px;
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>
