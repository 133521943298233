<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #right>
        <li>
          <AppSelect
            v-model="resourceParams.log_type"
            :options="logTypeOptions"
            :placeholder="$t('app.all')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="SEARCH_TYPES"
          />
        </li>
        <li>
          <AppDateRangePicker
            v-model="resourceParams"
            :min-date="minDate"
            @update="searchResource"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppInfoBox>
      {{ $t('info_box') }}
    </AppInfoBox>
    <AppResourceTable
      table-id="logs-table"
      :columns="columns"
      :resources="logs"
      :rows="rows"
      @paginate="paginate"
    >
      <template #cell="{row, column}">
        <template v-if="column === 'log_type'">
          <AppBadge v-bind="logBadge(row.log_type)" />
        </template>
        <template v-if="column === 'description'">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="row.description" />
          <span v-if="row.is_custom_setting">
            <AppInternalLink
              to=""
              link-style="blue"
              @click="openCustomSettingsDiffDialog(row.id, row.name)"
            >
              {{ $t('custom_setting_link') }}
            </AppInternalLink>
          </span>
          <!-- eslint-disable vue/no-v-html -->
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

import LogType from '@/enums/LogType';

export default {
  name: 'TheLogsDialogCommonSettings',
  mixins: [ResourceViewNoRoute],
  data() {
    return {
      isLoading: true,
      logs: nullResources,
      minDate: moment()
        .subtract(12, 'month')
        .add(1, 'day')
        .toDate()
        .toISOString(),
      SEARCH_TYPES: ['actor_email', 'actor_ip'].map(value => ({
        value,
        label: this.$t(`search_type.${value}`)
      }))
    };
  },
  computed: {
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      search_type: 'actor_email'
    }),
    logTypeOptions() {
      return LogType.options([
        LogType.BASIC_SETTINGS,
        LogType.ADVANCED_SETTINGS
      ]);
    },
    columns() {
      return [
        { id: 'date', label: this.$t('date') },
        { id: 'actor', type: 'html', label: this.$t('actor') },
        { id: 'log_type', label: this.$t('log_type') },
        {
          id: 'description',
          type: 'pre',
          label: this.$t('description'),
          align: 'left'
        }
      ];
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    rows() {
      return this.logs.items.map(log => {
        const isCustomSetting = this.isCustomSetting(log);
        return {
          id: log.id,
          date: this.formatDateTime(log.created_at),
          actor: this.$t('actor_content', {
            actor_email: log.email,
            actor_ip: log.ip
          }),
          log_type: log.log_type,
          description: this.description(log, isCustomSetting),
          name: isCustomSetting ? log.description : null,
          is_custom_setting: isCustomSetting
        };
      });
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    logBadge(logType) {
      return {
        label: LogType.t(logType),
        badgeStyle: 'grey-outline'
      };
    },
    fetchResource(params) {
      this.isLoading = true;
      const type = 'common_settings';
      api
        .get('/logs', { params: { ...params, type } })
        .then(({ data }) => (this.logs = data.logs))
        .finally(() => (this.isLoading = false));
    },
    isCustomSetting(row) {
      return row.old_setting_value || row.new_setting_value;
    },
    description(row, isCustomSetting) {
      if (isCustomSetting) {
        return `[위젯 공통]<br>${row.description}:`;
      } else {
        return row.description;
      }
    },
    openCustomSettingsDiffDialog(logId, settingName) {
      this.openDialog([
        'TheLogsDialogSettingDiffDialog',
        { logId, settingName, logType: LogType.COMMON_SETTINGS }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .logs-table__date {
    flex: 0 0 155px;
  }

  .logs-table__actor {
    flex: 0 0 170px;
  }

  .logs-table__log-type {
    flex: 0 0 100px;
  }
}
</style>

<i18n locale="ko">
{
  "search_type": {
    "actor_email": "관리자 ID",
    "actor_ip": "관리자 IP"
  },
  "info_box": "공통 설정 이력은 최근 1년 간의 내역만 제공합니다.",
  "date": "날짜",
  "actor": "관리자",
  "actor_content": "%{actor_email}<br />%{actor_ip}",
  "log_type": "설정 소속",
  "description": "상세 이력",
  "custom_setting_link": "상세 내역 확인"
}
</i18n>
