<template>
  <AppContainer>
    <AppInfoBox>{{ $t('section_info') }}</AppInfoBox>
    <template v-if="isCategoryPageWidgetCampaign">
      <div class="TargetCampaignSettingsProductRecommendationMisc__item">
        <AppDataItem
          :label="$t('undisplayable_category_ids.title')"
          class="TargetCampaignSettingsProductRecommendationMisc__contents"
        >
          {{ undisplayableCategoryNames }}
        </AppDataItem>
        <AppButton
          class="TargetCampaignSettingsProductRecommendationMisc__button"
          :label="$t('app.settings')"
          @click="openUndisplayableCategoryIdsSettings"
        />
      </div>
    </template>
    <div class="TargetCampaignSettingsProductRecommendationMisc__item">
      <AppDataItem
        :label="$t('exclude_category_ids.title')"
        class="TargetCampaignSettingsProductRecommendationMisc__contents"
      >
        {{ excludeCategoryDescription }}
      </AppDataItem>
      <AppButton
        class="TargetCampaignSettingsProductRecommendationMisc__button"
        :label="$t('app.settings')"
        @click="openExcludeCategoryIdsSettings"
      />
    </div>
    <div class="TargetCampaignSettingsProductRecommendationMisc__item">
      <AppDataItem
        :label="$t('exclude_product_ids.title')"
        class="TargetCampaignSettingsProductRecommendationMisc__contents"
      >
        {{ excludeProductDescription }}
      </AppDataItem>
      <AppButton
        class="TargetCampaignSettingsProductRecommendationMisc__button"
        :label="$t('app.settings')"
        @click="openExcludeProductIdsSettings"
      />
    </div>
    <div
      v-if="isSuperAdminAccessible"
      class="TargetCampaignSettingsProductRecommendationMisc__item super-admin__item"
    >
      <AppDataItem
        :label="$t('display_rate.title')"
        class="TargetCampaignSettingsProductRecommendationMisc__contents"
      >
        {{
          $t('display_rate.description', [
            campaign.widget_recommendation_settings.display_rate
          ])
        }}
      </AppDataItem>
      <AppButton
        class="TargetCampaignSettingsProductRecommendationMisc__button"
        :label="$t('app.settings')"
        @click="openDisplayRateSettings"
      />
    </div>
    <div
      v-if="isReviewHighlightWidgetCampaign"
      class="TargetCampaignSettingsProductRecommendationMisc__item"
    >
      <AppDataItem
        :label="$t('undisplayable_category_ids.title')"
        class="TargetCampaignSettingsProductRecommendationMisc__contents"
      >
        {{ undisplayableCategoryNames }}
      </AppDataItem>
      <AppButton
        class="TargetCampaignSettingsProductRecommendationMisc__button"
        :label="$t('app.settings')"
        @click="openUndisplayableCategoryIdsSettings"
      />
    </div>
    <div
      v-if="isSuperAdminAccessible"
      class="TargetCampaignSettingsProductRecommendationMisc__item super-admin__item"
    >
      <AppDataItem
        :label="$t('show_loader_image.title')"
        class="TargetCampaignSettingsProductRecommendationMisc__content"
      >
        {{
          $t(
            `show_loader_image.description.${campaign.widget_recommendation_settings.show_loader_image}`
          )
        }}
      </AppDataItem>
      <AppButton
        class="TargetCampaignSettingsProductRecommendationMisc__button"
        :label="$t('app.settings')"
        @click="openShowLoaderImageSettings"
      />
    </div>
  </AppContainer>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'TargetCampaignSettingsProductRecommendationMisc',
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    ...mapState('session', ['productSettings']),
    ...mapGetters('productCategory', ['productCategoryOptions']),
    ...mapGetters('targetCampaign', [
      'isCategoryPageWidgetCampaign',
      'isReviewHighlightWidgetCampaign'
    ]),
    ...mapGetters(['isSuperAdminAccessible']),
    undisplayableCategoryNames() {
      if (
        !this.campaign.widget_recommendation_settings
          .use_undisplayable_category_ids ||
        this.campaign.widget_recommendation_settings.undisplayable_category_ids
          .length === 0
      )
        return this.$t('undisplayable_category_ids.description.unselected');

      return this.$t('undisplayable_category_ids.description.selected', [
        this.categoryNames(
          this.campaign.widget_recommendation_settings
            .undisplayable_category_ids
        )
      ]);
    },
    recommendationExcludingCategoryInfo() {
      const recommendationExcludingCategoryInfos = this.productCategoryOptions.filter(
        categoryInfo =>
          this.productSettings.recommendation_excluding_category_ids.includes(
            categoryInfo.value
          )
      );

      if (recommendationExcludingCategoryInfos.length === 0) return;

      return {
        name: recommendationExcludingCategoryInfos[0].label,
        count: this.productSettings.recommendation_excluding_category_ids.length
      };
    },
    recommendationExcludingCategoryNames() {
      return this.categoryNames(
        this.productSettings.recommendation_excluding_category_ids
      );
    },
    excludeCategoryNames() {
      return this.categoryNames(
        this.campaign.widget_recommendation_settings.exclude_category_ids
      );
    },
    excludeCategoryDescription() {
      if (this.campaign.widget_recommendation_settings.use_exclude_category_ids)
        return this.excludeCategoryNames;

      var description = this.$t('use_product_setting');
      if (this.productSettings.recommendation_excluding_category_ids.length > 0)
        description += ` - ${this.recommendationExcludingCategoryNames}`;

      return description;
    },
    excludeProductDescription() {
      if (this.campaign.widget_recommendation_settings.use_exclude_product_ids)
        return this.productNames(
          this.campaign.widget_recommendation_settings.exclude_products
        );

      var description = this.$t('use_product_setting');
      if (this.productSettings.recommendation_excluding_product_ids.length > 0)
        description += ` - ${this.productNames(
          this.productSettings.recommendation_excluding_products
        )}`;

      return description;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    categoryNames(categoryIds) {
      return this.productCategoryOptions
        .filter(c => categoryIds.includes(c.value))
        .map(c => c.label)
        .join(', ');
    },
    productNames(products) {
      return _.map(products, 'name').join(', ');
    },
    openDisplayRateSettings() {
      this.openDialog([
        'TargetCampaignSettingsWidgetDisplayRateDialog',
        { campaign: this.campaign }
      ]);
    },
    openUndisplayableCategoryIdsSettings() {
      this.openDialog([
        'TargetCampaignSettingsWidgetUndisplayableCategoryIdsDialog',
        { campaign: this.campaign }
      ]);
    },
    openExcludeCategoryIdsSettings() {
      this.openDialog([
        'TargetCampaignSettingsWidgetExcludeCategoryIdsDialog',
        {
          campaign: this.campaign,
          recommendationExcludingCategoryInfo: this
            .recommendationExcludingCategoryInfo
        }
      ]);
    },
    openExcludeProductIdsSettings() {
      this.openDialog([
        'TargetCampaignSettingsWidgetExcludeProductIdsDialog',
        {
          campaign: this.campaign,
          recommendationExcludingProducts: this.productSettings
            .recommendation_excluding_products,
          excludeProducts: this.campaign.widget_recommendation_settings
            .exclude_products
        }
      ]);
    },
    openShowLoaderImageSettings() {
      this.openDialog([
        'TargetCampaignSettingsWidgetShowLoaderImageDialog',
        { campaign: this.campaign }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetCampaignSettingsProductRecommendationMisc__item {
  position: relative;
  padding: 16px 0;

  & + & {
    border-top: solid 1px $color-grey-35;
  }
}
.TargetCampaignSettingsProductRecommendationMisc__contents {
  width: calc(100% - 70px);
}
.TargetCampaignSettingsProductRecommendationMisc__button {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}
</style>

<i18n locale="ko">
{
  "section_info": "추천 기준 제외 설정 등 필수 설정 이외의 설정을 할 수 있습니다.",
  "display_rate":{
    "title": "위젯 노출 빈도",
    "description": "위젯 요청 중 {0}% 노출"
  },
  "undisplayable_category_ids":{
    "title": "위젯 사용 카테고리",
    "description": {
      "selected": "{0} 카테고리를 제외한 나머지 카테고리에 위젯 사용",
      "unselected": "전체 카테고리에 위젯 사용"
    }
  },
  "exclude_category_ids":{
    "title": "추천 기준 제외 카테고리"
  },
  "exclude_product_ids":{
    "title": "추천 기준 제외 상품"
  },
  "use_product_setting": "상품 설정 메뉴에 따름",
  "show_loader_image": {
    "title": "로딩 중 표시 설정",
    "description": {
      "true": "위젯 로딩 시 로딩 중 표시",
      "false": "위젯 로딩 시 로딩 중 표시 안 함"
    }
  }
}
</i18n>
