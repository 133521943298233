<template>
  <div class="ChannelProductsInfoCell">
    <div class="ChannelProductsInfoCell__product-description-wrapper">
      <AppExternalLink :to="product.url">
        <div class="ChannelProductsInfoCell__product-title">
          {{ product.name }}
        </div>
      </AppExternalLink>
      <div class="ChannelProductsInfoCell__product-info">
        {{ finalPriceText }}
      </div>
      <div class="ChannelProductsInfoCell__product-info">
        {{ discountPriceText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChannelProductsInfoCell',
  props: {
    product: { type: Object, required: true }
  },
  computed: {
    finalPriceText() {
      const { final_price } = this.product;

      return this.$t('final_price', [final_price]);
    },
    discountPriceText() {
      const { final_price, discount_price } = this.product;

      if (final_price === discount_price) {
        return this.$t('discount_price', ['-']);
      }
      return this.$t('discount_price', [discount_price]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ChannelProductsInfoCell {
  display: flex;
}

.ChannelProductsInfoCell__product-description-wrapper {
  text-align: left;
  margin-left: 11px;
}

.ChannelProductsInfoCell__product-title {
  @include text-content;
}

.ChannelProductsInfoCell__product-info {
  @include text-caption-small;
  margin-top: 4px;
}
</style>

<i18n locale="ko">
{
  "code": "상품번호: {0}",
  "final_price": "가격: {0}",
  "discount_price": "할인가: {0}"
}
</i18n>
