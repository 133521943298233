<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppResourceTable
      table-id="smartstore-review-csvs-table"
      :table-name="$t('table_name')"
      :resources="review_csvs"
      :columns="columns"
      :rows="rows"
      :enable-total-count="false"
      :event-bus="eventBus"
      @paginate="paginate"
    >
      <template #cell="{ column, value }">
        <template v-if="column === 'brand_csv_status'">
          <AppBadge v-bind="value" />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import api from '@/lib/api';
import ResourceView from '@/mixins/ResourceView';
import { nullResources } from '@/lib/resources';
import BrandCsvStatus from '@/enums/BrandCsvStatus';

export default {
  name: 'ReviewImportSmartstoreReviewCsv',
  mixins: [ResourceView],
  data() {
    return {
      isLoading: true,
      review_csvs: nullResources,
      columns: [
        { id: 'id', label: this.$t('table_header.id') },
        {
          id: 'created_at',
          label: this.$t('table_header.created_at'),
          type: 'datetime'
        },
        { id: 'type', label: this.$t('table_header.type') },
        { id: 'brand_csv_status', label: this.$t('table_header.status') }
      ],
      eventBus: new Vue()
    };
  },
  computed: {
    defaultResourceParams: () => ({
      per: '20',
      page: '1'
    }),
    rows() {
      return this.review_csvs.items.map(review_csv => ({
        ...review_csv,
        brand_csv_status: {
          label: this.$t(
            `brand_csv_status.${BrandCsvStatus.key(
              review_csv.brand_csv_status
            )}`
          ),
          badgeStyle: this.badgeStyle(review_csv.brand_csv_status)
        },
        type: this.$t(`smartstore_type.${review_csv.sort}`)
      }));
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/smartstore/review_csvs', { params })
        .then(({ data }) => {
          this.review_csvs = data.review_csvs;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    badgeStyle(status) {
      switch (status) {
        case BrandCsvStatus.IMPORTED:
          return 'mint-green';
        case BrandCsvStatus.IMPORTING:
        case BrandCsvStatus.EXPORTING:
        case BrandCsvStatus.EXPORTED:
          return 'mint-green-outline';
        case BrandCsvStatus.EXPORT_FAILED:
        case BrandCsvStatus.EXPORTED_PARTIAL:
        case BrandCsvStatus.IMPORT_FAILED:
          return 'red';
        default:
          return 'default';
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "table_name": "전체 연동 내역",
  "table_header": {
    "id": "ID",
    "created_at": "생성일",
    "type": "동기화 리뷰 종류",
    "status": "상태"
  },
  "smartstore_type": {
    "4000": "전체 과거 리뷰",
    "2000": "전체 매일 리뷰"
  },
  "brand_csv_status": {
    "IMPORT_PENDING": "연동 대기 중",
    "IMPORTING": "연동 진행 중",
    "IMPORTED": "연동 완료",
    "IMPORT_FAILED": "연동 실패",
    "EXPORTING": "리뷰 수 계산 중",
    "EXPORTED": "리뷰 수 계산 완료",
    "EXPORTED_PARTIAL": "리뷰 수 계산 일부 실패함",
    "EXPORT_FAILED": "리뷰 수 계산 실패",
    "EXPORT_PENDING": "리뷰 수 계산 대기 중",
    "EXPORT_SCHEDULED": "리뷰 수 계산 예약됨",
    "EXPORT_CANCELED": "리뷰 수 계산 취소됨"
  }
}
</i18n>
