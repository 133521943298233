var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'AppVirtualListResourceTable',
    _vm.tableId,
    _vm.tableStyle ? ("AppVirtualListResourceTable--" + _vm.tableStyle) : null,
    { 'AppVirtualListResourceTable--in-dialog': _vm.isInLayer }
  ]},[_c('div',{staticClass:"AppVirtualListResourceTable__table"},[_c('div',{ref:"stickyHead",staticClass:"AppVirtualListResourceTable__head-sticky"},[_c('AppResourceTableSearchbar',_vm._g(_vm._b({on:{"cancel-selection":function($event){_vm.selectedRowIdsSet = new Set()}}},'AppResourceTableSearchbar',{
          tableName: _vm.tableName,
          tableDescription: _vm.tableDescription,
          enableRefresh: _vm.enableRefresh,
          enableColumnSettings: _vm.enableColumnSettings,
          enableQuery: _vm.enableQuery,
          customButtons: _vm.customButtons,
          batchButtons: _vm.batchButtons,
          toggleButton: _vm.toggleButton,
          tableColumns: _vm.tableColumns,
          selectedRowIdsSet: _vm.selectedRowIdsSet,
          totalCount: _vm.enableTotalCount
            ? _vm.resources
              ? _vm.resources.total_count
              : _vm.rows.length
            : null,
          isInLayer: _vm.isInLayer,
          filterOptions: [],
          filterValues: []
        },false),_vm.$listeners)),_c('div',{ref:"headScroller",staticClass:"AppVirtualListResourceTable__head-scroller",attrs:{"data-vertically-scrollable":"no"}},[_c('div',{ref:"head",staticClass:"AppVirtualListResourceTable__head"},[(_vm.mainColumns.length)?_c('div',{staticClass:"AppVirtualListResourceTable__head-row"},_vm._l((_vm.mainColumns),function(column){return _c('AppResourceTableCell',{key:column.id,staticClass:"AppVirtualListResourceTable__cell",style:({ flexBasis: _vm.flexBasis(column.colspan || 1) }),attrs:{"cell-type":"head","colspan":column.colspan,"column":column,"cell-style":column.columnStyle,"value":column.label}})}),1):_vm._e(),_c('div',{ref:"finalHeadRow",class:[
              'AppVirtualListResourceTable__head-row',
              {
                'AppVirtualListResourceTable__head-row--sub':
                  _vm.mainColumns.length
              }
            ]},_vm._l((_vm.visibleColumns),function(column){return _c('AppResourceTableCell',{key:column.columnId,class:[
                'AppVirtualListResourceTable__cell',
                _vm.tableId ? column.columnId : null,
                _vm.tableId ? ((column.columnId) + "--head") : null,
                column.superAdmin ? 'super-admin__item' : null
              ],style:(column.style),attrs:{"cell-type":"head","column":column,"cell-style":column.columnStyle}},[_vm._t("head-cell",function(){return [(column.type === 'row_select')?_c('AppCheckboxPartial',{attrs:{"check-status":_vm.selectedRows.length
                      ? _vm.rows.length === _vm.selectedRows.length
                        ? 'checked'
                        : 'partial'
                      : 'unchecked'},on:{"change":function($event){return _vm.selectRows($event === 'checked')}}}):(column.tooltip)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(column.tooltip),expression:"column.tooltip"}]},[_c('span',{staticClass:"AppVirtualListResourceTable__head-col-label"},[_vm._v(_vm._s(column.label))]),_c('AppSvgIcon',{staticClass:"AppVirtualListResourceTable__head-col-icon",attrs:{"name":"icon-info-tooltip"}})],1):_c('div',[_vm._v(_vm._s(column.label))])]},{"column":column.id})],2)}),1)])])],1),_c('div',{ref:"bodyScroller",staticClass:"AppVirtualListResourceTable__body-scroller",attrs:{"data-vertically-scrollable":"no"}},[_c('div',{ref:"body",staticClass:"AppVirtualListResourceTable__body js-draggable"},[_c('VirtualList',{ref:"virtualList",attrs:{"data-key":"id","data-sources":_vm.tableRows,"data-component":_vm.rowComponent,"estimate-size":_vm.minRowHeight,"keeps":20,"extra-props":{
            tableId: _vm.tableId,
            visibleColumns: _vm.visibleColumns,
            selectedRowIdsSet: _vm.selectedRowIdsSet
          },"item-scoped-slots":_vm.$scopedSlots,"parent-id":"main-body"}}),(_vm.rows.length === 0)?_c('AppNoData',{staticClass:"AppVirtualListResourceTable__no-data",attrs:{"message":_vm.noData}}):_vm._e()],1)])]),(_vm.resources)?_c('AppResourceTablePagination',{attrs:{"total-count":_vm.resources.total_count,"per":_vm.resources.per,"current-page":_vm.resources.page,"enable-per":_vm.enablePer,"enable-summary":!_vm.isInLayer,"enable-route":!_vm.isInLayer,"pers":_vm.pers},on:{"paginate":_vm.paginate}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }