import { constructEnum } from '@/lib/enum';

const TargetWidgetProductInformationDisplayStyle = constructEnum(
  'TargetWidgetProductInformationDisplayStyle',
  {
    DISPLAY: 10,
    HOVER: 20,
    IMAGE_ONLY: 30
  }
);

const PC_OPTIONS = [
  TargetWidgetProductInformationDisplayStyle.DISPLAY,
  TargetWidgetProductInformationDisplayStyle.HOVER,
  TargetWidgetProductInformationDisplayStyle.IMAGE_ONLY
];

const MOBILE_OPTIONS = [
  TargetWidgetProductInformationDisplayStyle.DISPLAY,
  TargetWidgetProductInformationDisplayStyle.IMAGE_ONLY
];

TargetWidgetProductInformationDisplayStyle.optionsByDeviceType = function(
  isPc
) {
  return this.options(isPc ? PC_OPTIONS : MOBILE_OPTIONS);
};

export default TargetWidgetProductInformationDisplayStyle;
