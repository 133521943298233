<template>
  <CustomContentHeader
    :resource="productManagement"
    resource-type="product_management"
    @update-name="UPDATE_PRODUCT_MANAGEMENT({ name: $event })"
  >
    <template #actions>
      <AppDropdownMenuButton
        :menu-items="dispatchMenuItems"
        :label="label"
        :button-style="buttonStyle"
      />
    </template>
  </CustomContentHeader>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DisplayProductManagementDispatchType from '@/enums/DisplayProductManagementDispatchType';

export default {
  name: 'DisplayProductManagementShowHead',
  props: { isFormValid: { type: Boolean, default: true } },
  computed: {
    ...mapState('displayProductManagement', ['productManagement']),
    dispatchMenuItems() {
      return [
        [
          {
            label: this.$t('dispatch.enabled'),
            disabled: !this.productManagement.enabled && !this.isFormValid,
            tooltip: {
              textAlign: 'left',
              message:
                !this.productManagement.enabled && !this.isFormValid
                  ? this.$t('dispatch.tooltip.invalid')
                  : null
            },
            selected: this.productManagement.enabled,
            clickHandler: () => {
              this.updateEnabled(true);
            }
          },
          {
            label: this.$t('dispatch.disabled'),
            selected: !this.productManagement.enabled,
            clickHandler: () => {
              this.updateEnabled(false);
            }
          }
        ],
        [
          ...DisplayProductManagementDispatchType.options([
            DisplayProductManagementDispatchType.IMMEDIATE,
            DisplayProductManagementDispatchType.CONFIRM
          ]).map(option => {
            return {
              ...option,
              label: this.$t('dispatch.dispatch_now', [option.label]),
              disabled: !!this.disabledTooltip,
              tooltip: { textAlign: 'left', message: this.disabledTooltip },
              clickHandler: ({ close }) => {
                close();
                this.$emit('dispatch-now', option.value);
              }
            };
          })
        ]
      ];
    },
    disabledTooltip() {
      if (!this.isFormValid) return this.$t('dispatch.tooltip.invalid');

      const { is_scheduled, apply_at } = this.productManagement;
      if (!is_scheduled) return;

      return this.$t('dispatch.tooltip.dispatching', [
        this.formatDateTime(apply_at)
      ]);
    },
    label() {
      return this.productManagement.enabled
        ? this.$t('dispatch.enabled')
        : this.$t('dispatch.disabled');
    },
    buttonStyle() {
      return this.productManagement.enabled ? 'mint-green' : 'red-outline';
    }
  },
  methods: {
    ...mapMutations('displayProductManagement', ['UPDATE_PRODUCT_MANAGEMENT']),
    updateEnabled(value) {
      this.$emit('save', value);
    }
  }
};
</script>

<i18n locale="ko">
{
  "dispatch": {
    "tooltip": {
      "dispatching": "데이터 동기화 및 대상 상품을 선정하여 자동 관리 진행 중입니다.\n시작시간: {0}",
      "invalid": "사용할 수 없습니다.\n모든 필수 설정값이 입력되고 자동 관리 항목이 1개 이상 사용 설정되어야 합니다."
    },
    "enabled": "사용중",
    "disabled": "미사용",
    "dispatch_now": "즉시실행 - {0}"
  }
}
</i18n>
