<template>
  <AppAjaxContent :is-loading="isLoading">
    <template>
      <div class="SmartstorePayment">
        <AppSummaryBox>
          <template #title>{{ $t('summary.title') }}</template>
          <AppText :text="$t('summary.description')" />
        </AppSummaryBox>
        <AppGrid class="SmartstorePayment__grid" gutter-width="wide">
          <div class="AppGrid__col AppGrid__col--6">
            <AppDataItem
              :label="$t('starter_pricing.title')"
              :label-link="{
                label: $t('starter_pricing.open_pricing_dialog'),
                clickHandler: clickOpenPricingDialog
              }"
            >
              <AppTable
                :columns="STARTER_PRICING_COLUMNS"
                :rows="starterRows"
              />
              <AppText
                class="SmartstorePayment__table-description"
                :text="$t('starter_pricing.description')"
              />
            </AppDataItem>
          </div>
          <div class="AppGrid__col AppGrid__col--6">
            <AppDataItem :label="$t('daily_pricing.title')">
              <AppTable :columns="DAILY_PRICING_COLUMNS" :rows="dailyRows" />
              <AppText
                class="SmartstorePayment__table-description"
                :text="$t('daily_pricing.description')"
              />
            </AppDataItem>
          </div>
        </AppGrid>

        <div class="SmartstorePayment__foot">
          <AppButton
            button-style="blue"
            :label="$t('footer.open_payment_dialog')"
            @click="clickOpenPaymentDialog"
          />
          <AppButton
            :label="$t('footer.retry_product_match')"
            @click="clickRetryProductMatch"
          />
        </div>
      </div>
    </template>
  </AppAjaxContent>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';

export default {
  name: 'SmartstorePayment',
  data() {
    return { isLoading: true, starterPackage: {}, dailyPackages: [] };
  },
  computed: {
    starterRows() {
      const { sync_months, reviews_count, final_price } = this.starterPackage;
      return [
        {
          period: this.$t('starter_pricing.sync_months', [sync_months]),
          reviews_count,
          price: final_price
        }
      ];
    },
    dailyRows() {
      return this.dailyPackages.map(p => {
        return {
          period:
            p.final_price === p.price
              ? this.$t('daily_pricing.period', [p.months])
              : this.$t('daily_pricing.special_price_period', [p.months]),
          price: p.final_price
        };
      });
    }
  },
  created() {
    this.STARTER_PRICING_COLUMNS = [
      {
        id: 'period',
        label: this.$t('app.period'),
        align: 'left'
      },
      {
        id: 'reviews_count',
        label: this.$t('starter_pricing.count'),
        type: 'number',
        align: 'right'
      },
      {
        id: 'price',
        label: this.$t('app.price'),
        type: 'currency',
        align: 'right'
      }
    ];

    this.DAILY_PRICING_COLUMNS = [
      {
        id: 'period',
        label: this.$t('app.period'),
        align: 'left'
      },
      {
        id: 'price',
        label: this.$t('app.price'),
        type: 'currency',
        align: 'right'
      }
    ];
  },
  mounted() {
    api
      .get('/smartstore/payment')
      .then(({ data }) => {
        this.starterPackage = data.starter_package;
        this.dailyPackages = data.daily_packages;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'confirm']),
    ...mapActions('smartstore', [
      'requestRetryProductMatchJob',
      'fetchSmartstoreStatus'
    ]),
    clickOpenPricingDialog() {
      this.openDialog('SmartstorePricingDialog');
    },
    clickOpenPaymentDialog() {
      this.openDialog([
        'ThePaymentDialog',
        { activeTabId: 'smartstore_packages' }
      ]).then(eventBus => eventBus.$on('close', this.fetchSmartstoreStatus));
    },
    clickRetryProductMatch() {
      this.confirm({
        title: this.$t('footer.retry_product_match'),
        message: this.$t('confirm_retry_product_match')
      }).then(result => {
        if (!result) return;

        this.isLoading = true;
        this.requestRetryProductMatchJob().finally(
          () => (this.isLoading = false)
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.SmartstorePayment {
  margin-top: 32px;
}

.SmartstorePayment__grid {
  margin-top: 36px;
}

.SmartstorePayment__table-description {
  margin-top: 20px;
}

.SmartstorePayment__foot {
  margin-top: 60px;
}
</style>

<i18n locale="ko">
{
  "summary": {
    "title": "🙆‍♀️️️ 리뷰 연동 준비가 완료되었습니다. 7일 이내에 결제해주세요.",
    "description": "결제가 진행되면 리뷰를 가지고 오며 리뷰수에 따라 최대 2일까지 소요될 수 있습니다.\n계산된 리뷰수를 기준으로 7일 안에 결제해야 하며, 7일 경과 시 매칭표 등록부터 다시 진행해야합니다."
  },
  "starter_pricing": {
    "title": "과거 리뷰 연동 비용 (부가세 별도)",
    "open_pricing_dialog": "연동 비용 기준 보기",
    "count": "연동 리뷰수",
    "sync_months": "최근 {0}개월 리뷰",
    "description": "선택한 기간의 **과거 리뷰를 연동**합니다.",
    "change_period_button_label": "연동 기간 변경"
  },
  "daily_pricing": {
    "title": "매일 리뷰 연동 비용 (부가세 별도)",
    "period": "{0}개월",
    "special_price_period": "[특별가] {0}개월",
    "description": "결제 시점부터 선택한 기간동안 **매일 1회 새로운 리뷰를 연동**합니다."
  },
  "footer": {
    "open_payment_dialog": "결제창으로 이동",
    "retry_product_match": "상품 매칭표 다시 등록"
  },
  "confirm_retry_product_match": "기존 상품 매칭표 기준으로 계산한 결제 정보가 삭제됩니다. 정말 새로운 상품 매칭표를 등록하시겠습니까?"
}
</i18n>
