import { constructEnum } from '@/lib/enum';

export default constructEnum('TargetCampaignGroupType', {
  CUSTOM_DM: -10,
  NEW_USER_MANAGEMENT: 10,
  REMINDING: 20,
  PURCHASE_PROMOTION: 30,
  MASS: 40,
  CHURNING_PREVENTION: 50,
  FUNNEL_OPTIMIZATION: 60,
  SUBSCRIPTION_PROMOTION: 70,
  PERSONALIZED_PRODUCT_INFO: 80,
  PRODUCT_DETAIL_PAGE: 90,
  CATEGORY_PAGE: 100,
  MAIN_PAGE: 110,
  CART_PAGE: 120,
  REVIEW_HIGHLIGHT: 130,
  CART_POPUP: 140,
  CAMPAIGN_SUGGESTION: 1000
});
