<template>
  <div>
    <AppInputGroupItem v-for="button in buttons" :key="button.id">
      <AppButtonToggle v-bind="button" @change="$emit('select', button.id)" />
    </AppInputGroupItem>
  </div>
</template>

<script>
export default {
  name: 'AppButtonToggleGroup',
  props: { buttons: { type: Array, required: true } }
};
</script>
