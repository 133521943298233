import Vue from 'vue';
import moment from 'moment';
import i18n from '@/lib/i18n';
import api from '@/lib/api';
import BrandSyncType from '@/enums/BrandSyncType';
import DialogSize from '@/enums/DialogSize';
import ManualSyncStatus from '@/enums/ManualSyncStatus';

const JOB_TYPE = 'manual_sync';
const jobId = brandSyncType =>
  `${JOB_TYPE}_${BrandSyncType.key(brandSyncType)}`;

const state = { manualSync: {} };

const mutations = {
  UPDATE_MANUAL_SYNC({ manualSync }, { brandSyncType, manualSyncInfo }) {
    Vue.set(manualSync, brandSyncType, manualSyncInfo);
  },
  REMOVE_MANUAL_SYNC({ manualSync }, brandSyncType) {
    Vue.delete(manualSync, brandSyncType);
  }
};

const progressStatusName = function(syncStatus, brandSyncType) {
  switch (syncStatus) {
    case ManualSyncStatus.READY:
      return i18n.t('manual_sync_jobs.progress_status.ready');
    case ManualSyncStatus.IN_PROGRESS:
      if (BrandSyncType.isCsvType(brandSyncType))
        return i18n.t('manual_sync_jobs.progress_status.in_progress.csv');
      else
        return i18n.t('manual_sync_jobs.progress_status.in_progress.default');
    case ManualSyncStatus.CALCULATING_TARGET_DATA:
      return i18n.t('manual_sync_jobs.progress_status.calculating_target_data');
    case ManualSyncStatus.COMPLETED:
    case ManualSyncStatus.FAILED:
    case ManualSyncStatus.EXPORT_FAILED:
    case ManualSyncStatus.IMPORT_FAILED:
    case ManualSyncStatus.CALCULATION_FAILED:
      return i18n.t('manual_sync_jobs.progress_status.finished');
    default:
      return '';
  }
};

const DATE_FORMAT = 'YYYY. MM. DD.';
const formattedDateRange = (startDate, endDate) => {
  const formattedStartDate = moment(startDate).format(DATE_FORMAT);
  const formattedEndDate = moment(endDate).format(DATE_FORMAT);
  return `<p>${formattedStartDate} - ${formattedEndDate}</p>`;
};

const confirmDialogFailMessage = function(
  { sync_status, start_date, end_date, success_dates, fail_dates },
  brandSyncType
) {
  const manualSyncName = BrandSyncType.t(brandSyncType);
  const syncStatus = ManualSyncStatus.key(sync_status);
  if (fail_dates) {
    const syncDateRange = formattedDateRange(start_date, end_date);
    const successDateRanges = success_dates.length
      ? success_dates
          .map(dates => formattedDateRange(dates[0], dates[1]))
          .join('')
      : '<p>-</p>';
    const failDateRanges = fail_dates.length
      ? fail_dates.map(dates => formattedDateRange(dates[0], dates[1])).join('')
      : '<p>-</p>';

    return i18n.t('manual_sync_jobs.failed_alert.content_with_dates', [
      manualSyncName,
      syncDateRange,
      successDateRanges,
      failDateRanges
    ]);
  } else if (brandSyncType === BrandSyncType.ORDER) {
    return i18n.t(
      `manual_sync_jobs.failed_alert.retry_content_html.${syncStatus}`,
      [manualSyncName]
    );
  } else
    return i18n.t(`manual_sync_jobs.failed_alert.content_html.${syncStatus}`, [
      manualSyncName
    ]);
};

const actions = {
  setManualSyncJobs({ state, dispatch }, manualSyncInfos) {
    BrandSyncType.values.forEach(brandSyncType => {
      const manualSyncInfo = manualSyncInfos.find(
        ({ brand_sync_type }) => brand_sync_type === brandSyncType
      );
      if (!manualSyncInfo) {
        dispatch('removeManualSyncJob', brandSyncType);
        return;
      }

      if (brandSyncType in state.manualSync)
        dispatch('updateManualSyncJob', { brandSyncType, manualSyncInfo });
      else dispatch('enqueueManualSyncJob', { brandSyncType, manualSyncInfo });
    });
  },
  enqueueManualSyncJob(
    { commit, dispatch },
    { brandSyncType, manualSyncInfo, displayRunningTime }
  ) {
    commit('UPDATE_MANUAL_SYNC', { brandSyncType, manualSyncInfo });

    const progressInfo = ManualSyncStatus.progressInfo(
      brandSyncType,
      manualSyncInfo.sync_status
    );
    dispatch(
      'multiAsyncJob/enqueueJob',
      {
        title: BrandSyncType.t(brandSyncType),
        id: jobId(brandSyncType),
        type: JOB_TYPE,
        startedAt: manualSyncInfo.sync_started_at,
        ...progressInfo,
        progressStatus: progressStatusName(
          manualSyncInfo.sync_status,
          brandSyncType
        ),
        displayRunningTime: displayRunningTime,
        resultHandler: () => dispatch('confirmManualSyncJob', brandSyncType)
      },
      { root: true }
    );
  },
  updateManualSyncJob({ commit, dispatch }, { brandSyncType, manualSyncInfo }) {
    commit('UPDATE_MANUAL_SYNC', { brandSyncType, manualSyncInfo });

    const { sync_status } = manualSyncInfo;
    const { progressCount } = ManualSyncStatus.progressInfo(
      brandSyncType,
      sync_status
    );

    dispatch(
      'multiAsyncJob/upsertJob',
      {
        id: jobId(brandSyncType),
        progressCount,
        progressStatus: progressStatusName(sync_status, brandSyncType),
        title: ManualSyncStatus.isFailed(sync_status)
          ? i18n.t('manual_sync_jobs.failed', [BrandSyncType.t(brandSyncType)])
          : null
      },
      { root: true }
    );

    if (sync_status === ManualSyncStatus.COMPLETED)
      dispatch('multiAsyncJob/completeJob', jobId(brandSyncType), {
        root: true
      });
  },
  confirmManualSyncJob({ state, dispatch }, brandSyncType) {
    api.post('/sync/confirm', { brand_sync_type: brandSyncType }).then(() => {
      const { sync_status } = state.manualSync[brandSyncType];
      const manualSyncName = BrandSyncType.t(brandSyncType);

      if (sync_status === ManualSyncStatus.COMPLETED)
        dispatch(
          'toast/createToast',
          i18n.t('manual_sync_jobs.completed', [manualSyncName]),
          { root: true }
        );
      else {
        const failMessage = confirmDialogFailMessage(
          state.manualSync[brandSyncType],
          brandSyncType
        );
        dispatch(
          'dialog/openDialog',
          [
            'AppMessageDialog',
            {
              type: 'alert',
              title: i18n.t('manual_sync_jobs.failed_alert.title', [
                manualSyncName
              ]),
              markdownText: failMessage,
              width: DialogSize.SMALL
            }
          ],
          { root: true }
        );
      }
      dispatch('removeManualSyncJob', brandSyncType);
    });
  },
  removeManualSyncJob({ commit }, brandSyncType) {
    commit('multiAsyncJob/REMOVE_JOB', jobId(brandSyncType), { root: true });
    commit('REMOVE_MANUAL_SYNC', brandSyncType);
  }
};

export default { namespaced: true, state, mutations, actions };
