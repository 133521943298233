import Vue from 'vue';
import api from '@/lib/api';

const FETCH_REVIEW_USER_GRADES_REQUEST_ID = 'FETCH_REVIEW_USER_GRADES';

const state = {
  userGrades: []
};

const mutations = {
  SET_USER_GRADES(state, userGrades) {
    state.userGrades = userGrades;
  },
  UPDATE_ICON_URL({ userGrades }, { id, icon_url }) {
    const userGrade = userGrades.find(g => g.id === id);
    Vue.set(userGrade, 'icon_url', icon_url);
  }
};

const actions = {
  fetchUserGrades({ commit }) {
    api
      .get('/review/user_grades', {
        requestId: FETCH_REVIEW_USER_GRADES_REQUEST_ID
      })
      .then(function({ data }) {
        commit('SET_USER_GRADES', data.user_grades);
      });
  }
};

const getters = {
  isFetchingReviewUserGrades(_state, _getters, _rootState, rootGetters) {
    return rootGetters['request/isRequestPending'](
      FETCH_REVIEW_USER_GRADES_REQUEST_ID
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
