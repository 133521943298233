<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #default>
        <li>
          <AppButtonDownload
            button-style="grey"
            :label="$t('app.download_csv')"
            url="/api/smartstore/products/download_csv"
          />
        </li>
      </template>
      <template #right>
        <li>
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="SEARCH_TYPES"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppInfoBox>
      <span>{{ $t('info_box.text1') }}</span
      ><br />
      <span>{{ $t('info_box.text2') }}</span>
    </AppInfoBox>
    <AppResourceTable
      table-id="smartstore-products-table"
      :resources="products"
      :columns="columns"
      :rows="rows"
      :batch-buttons="[
        {
          label: $t('batch.unlink'),
          tooltip: $t('batch.unlink_tooltip'),
          clickHandler: batchUnlink
        }
      ]"
      :event-bus="eventBus"
      @paginate="paginate"
      @select-rows="selectedItems = $event"
    />
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceView from '@/mixins/ResourceView';
import { mapActions } from 'vuex';

export default {
  name: 'ReviewImportSmartstoreProducts',
  mixins: [ResourceView],
  data() {
    return {
      SEARCH_TYPES: ['code', 'name', 'smartstore_product_no'].map(value => ({
        value,
        label: this.$t(`search_type.${value}`)
      })),
      isLoading: true,
      products: nullResources,
      columns: [
        { id: 'row_select', type: 'row_select' },
        { id: 'code', label: this.$t('table_header.product_code') },
        {
          id: 'product',
          label: this.$t('table_header.product'),
          type: 'product'
        },
        {
          id: 'smartstore_product_no',
          label: this.$t('table_header.smartstore_product_no'),
          type: 'pre'
        },
        {
          id: 'smartstore_reviews_count',
          label: this.$t('table_header.smartstore_reviews_count'),
          type: 'number'
        },
        {
          id: 'created_at',
          label: this.$t('table_header.created_at'),
          type: 'pre'
        }
      ],
      selectedItems: [],
      eventBus: new Vue()
    };
  },
  computed: {
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      search_type: 'code'
    }),
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    rows() {
      return this.products.items.map(item => ({
        ...item,
        product: item,
        smartstore_product_no: item.smartstore_products
          .map(e => e.smartstore_product_no || 'null')
          .join(',\n'),
        created_at: item.smartstore_products
          .map(e => this.formatDate(e.created_at))
          .join('\n')
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['confirm']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/smartstore/products', { params })
        .then(({ data }) => (this.products = data.products))
        .finally(() => (this.isLoading = false));
    },
    batchUnlink() {
      const count = this.selectedItems.length;
      this.confirm({
        title: this.$t('batch.unlink_confirm_title'),
        message: this.$t('batch.unlink_confirm_message', { count })
      }).then(result => {
        if (result) {
          this.isLoading = true;
          const ids = this.selectedItems.map(e => e.id);
          api
            .patch(
              '/smartstore/products/batch_unlink',
              { ids },
              { successMessage: this.$t('batch.unlinked', { count }) }
            )
            .then(() => {
              this.eventBus.$emit('select-rows', false);
              this.refreshResource();
            })
            .catch(() => (this.isLoading = false));
        }
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "info_box": {
    "text1": "현재 연동중인 전체 상품 목록이며, 상품을 선택하면 연동 해제가 가능합니다.",
    "text2": "상품연동 해제 시, 앞으로의 리뷰를 가지고 오지 않게 되며 이미 연동한 리뷰는 삭제되지 않습니다."
  },
  "table_header": {
    "product_code": "쇼핑몰 상품번호",
    "product": "상품",
    "smartstore_product_no": "스마트스토어 상품번호",
    "smartstore_reviews_count": "동기화한 리뷰수",
    "created_at": "최초 상품 매칭일"
  },
  "search_type": {
    "code": "쇼핑몰 상품번호",
    "name": "쇼핑몰 상품명",
    "smartstore_product_no": "스마트스토어 상품번호"
  },
  "batch": {
    "unlink": "연동 해제",
    "unlink_tooltip": "쇼핑몰 상품과 스마트스토어 상품간의 연동을 해제합니다. 이미 동기화된 리뷰는 삭제되지 않습니다.",
    "unlink_confirm_title": "연동 해제",
    "unlink_confirm_message": "%{count}개 상품에 대한 연동을 해제합니다. 진행하시겠습니까?",
    "unlinked": "%{count}개 상품에 대한 연동이 해제되었습니다."
  }
}
</i18n>
