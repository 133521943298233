const state = {
  filteredBrandsCount: 0,
  turnoverTypeValue: '',
  turnoverTypeCountMap: {}
};

const mutations = {
  SET_FILTERED_BRANDS_COUNT(state, filteredBrandsCount) {
    state.filteredBrandsCount = filteredBrandsCount;
  },
  SET_TURNOVER_TYPE_VALUE(state, turnoverTypeValue) {
    state.turnoverTypeValue = turnoverTypeValue;
  },
  SET_TURNOVER_TYPE_COUNT_MAP(state, turnoverTypeCountMap) {
    state.turnoverTypeCountMap = turnoverTypeCountMap;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
