<template>
  <AppAjaxContent :is-loading="isLoading">
    <div>
      <AppInternalLink class="CustomContentHeader__back-button" :to="backUrl"
        >&lt; {{ $t('back_button', [resourceTypeName]) }}</AppInternalLink
      >
    </div>
    <div class="CustomContentHeader__head">
      <div v-if="isNameEditing" class="CustomContentHeader__name-wrapper">
        <AppForm
          class="CustomContentHeader__name-form"
          v-bind="formProps"
          focus-group-id="name"
          v-on="formEvents"
        />
        <AppButtonSubmit
          class="CustomContentHeader__name-button"
          v-bind="{ isSubmitting, errorSummary }"
          :disabled="formObject.name.trim() === resource.name"
          @click="formEventBus.$emit('submit')"
        />
        <AppButton
          class="CustomContentHeader__name-button"
          :label="$t('app.cancel')"
          @click="isNameEditing = false"
        />
      </div>
      <div v-else class="CustomContentHeader__name-wrapper">
        <div class="CustomContentHeader__name-placeholder">
          {{ resource.name }}
        </div>
        <AppEditLink
          v-if="isNameEditable"
          class="CustomContentHeader__name-edit-button"
          :label="$t('name.edit_name')"
          @click="startEditing"
        />
      </div>
      <div class="CustomContentHeader__actions">
        <slot name="actions" />
      </div>
    </div>
  </AppAjaxContent>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import NameFormView from '@/mixins/NameFormView';

export default {
  name: 'CustomContentHeader',
  mixins: [FormView, NameFormView],
  props: {
    resource: { type: Object, required: true },
    resourceType: {
      type: String,
      required: true,
      validator: v =>
        [
          'target_campaign',
          'review_campaign',
          'product_management',
          'feed'
        ].includes(v)
    },
    isNameEditable: { type: Boolean, default: true }
  },
  data() {
    return { isLoading: true, isNameEditing: false };
  },
  computed: {
    ...mapGetters('targetCampaigns', ['campaignPath', 'campaignsPath']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              label: '',
              type: 'text',
              placeholder: this.$t('name.placeholder', [this.resourceTypeName]),
              validate: [
                'required',
                { rule: 'max_length', limit: 30 },
                {
                  rule: v => this.isNameNotDuplicated(v, this.resource.name),
                  errorMessage: this.$t(
                    `target_errors.duplicated_${this.resourceType}_name`
                  )
                }
              ]
            }
          ]
        }
      ];
    },
    backUrl() {
      switch (this.resourceType) {
        case 'target_campaign':
          return this.campaignsPath(this.resource);
        case 'review_campaign':
          return '/review/messages/campaigns';
        case 'product_management':
          return '/display/product-managements';
        case 'feed':
          return '/channel/feeds';
        default:
          return '';
      }
    },
    resourceTypeName() {
      return this.$t(`resource.${this.resourceType}`);
    },
    resourceBaseUrl() {
      switch (this.resourceType) {
        case 'target_campaign':
          return '/target/campaigns';
        case 'product_management':
          return '/display/product_managements';
        case 'feed':
          return '/channel/feeds';
        default:
          return '';
      }
    },
    path() {
      switch (this.resourceType) {
        case 'target_campaign':
          return this.campaignPath(this.resource);
        case 'review_campaign':
          return `/review/messages/campaigns/${this.resource.id}`;
        case 'product_management':
          return `/display/product-managements/${this.resource.id}`;
        case 'feed':
          return `/channel/feeds/${this.resource.id}`;
        default:
          return '';
      }
    }
  },
  watch: {
    'resource.name'() {
      this.updateBreadcrumbs();
    }
  },
  mounted() {
    this.SET_SHOW_CUSTOM_TITLE(true);
    this.updateBreadcrumbs();

    if (this.isNameEditable) this.fetchExistingNames();
    else this.isLoading = false;
  },
  beforeDestroy() {
    this.SET_SHOW_CUSTOM_TITLE(false);
    this.SET_PAGE_BREADCRUMBS([]);
  },
  methods: {
    ...mapMutations('pageBreadcrumb', ['SET_PAGE_BREADCRUMBS']),
    ...mapMutations('menu', ['SET_SHOW_CUSTOM_TITLE']),
    updateBreadcrumbs() {
      this.SET_PAGE_BREADCRUMBS([
        { id: this.resource.id, label: this.resource.name, path: this.path }
      ]);
    },
    startEditing() {
      this.isNameEditing = true;
      this.orgFormObject = { name: this.resource.name };
    },
    fetchExistingNames() {
      this.isLoading = true;
      api
        .get(`${this.resourceBaseUrl}/created_names`)
        .then(({ data }) => (this.existingNames = data.created_names))
        .finally(() => (this.isLoading = false));
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `${this.resourceBaseUrl}/${this.resource.id}/update_name`,
          formData,
          { successMessage: this.$t('app.saved') }
        )
        .then(({ data }) => {
          this.$emit('update-name', this.formObject.name);
          this.isNameEditing = false;
          this.existingNames = data.created_names;
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.CustomContentHeader__back-button {
  @include text-caption-dark;
}

.CustomContentHeader__head {
  margin: 4px 0 8px 0;
  display: flex;
}

.CustomContentHeader__name-wrapper {
  flex: 1 1 auto;
  padding-right: 12px;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}

.CustomContentHeader__name-form {
  display: inline-block;
  width: 300px;

  ::v-deep .AppForm__group-field {
    margin-top: 0;
  }
}

.CustomContentHeader__name-placeholder {
  flex: 0 1 auto;
  @include text-title;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CustomContentHeader__name-edit-button {
  flex: 0 0 auto;
  margin-left: 8px;
}

.CustomContentHeader__name-form {
  display: inline-block;
  width: 300px;

  ::v-deep .AppForm__group-field {
    margin-top: 0;
  }
}

.CustomContentHeader__name-button {
  vertical-align: top;
  &,
  & + & {
    margin-left: 4px;
  }
}

.CustomContentHeader__actions {
  flex: 0 0 auto;
  float: right;
}
</style>

<i18n locale="ko">
{
  "resource": {
    "target_campaign": "캠페인",
    "review_campaign": "캠페인",
    "product_management": "상품 관리 규칙",
    "feed": "피드"
  },
  "back_button": "{0} 리스트로 이동",
  "name": {
    "edit_name": "이름 변경",
    "placeholder": "{0} 이름을 입력해주세요."
  }
}
</i18n>
