<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Normal</label>
      </div>
      <AppSwitch id="normal" v-model="valueNormal" />
      <div class="ui-section__description">
        Selected Value: {{ valueNormal }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppSwitch id="disabled" v-model="valueDisabled" disabled />
      <div class="ui-section__description">
        <AppButtonToggle v-model="valueDisabled" label="Toggle" />
        Selected Value: {{ valueDisabled }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="insideLabel">Inside Label</label>
      </div>
      <label>
        <AppSwitch id="insideLabel" v-model="valueInsideLabel" />
        Label
      </label>
      <div class="ui-section__description">
        Selected Value: {{ valueInsideLabel }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiSwitch',
  data() {
    return {
      valueNormal: true,
      valueDisabled: true,
      valueInsideLabel: false
    };
  }
};
</script>
