<template>
  <div class="AppNewPasswordRequirements">
    <ul class="AppNewPasswordRequirements__main-items">
      <li v-for="(item, i) in passwordRequirements" :key="i">
        <div
          :class="[
            'AppNewPasswordRequirements__main-item',
            {
              'AppNewPasswordRequirements__main-item--satisfied': item.value
            }
          ]"
        >
          <AppSvgIcon
            :name="
              item.value
                ? 'icon-check-tiny-checked'
                : 'icon-check-tiny-unchecked'
            "
            class="AppNewPasswordRequirements__main-item-icon"
          />
          <span class="AppNewPasswordRequirements__main-item-text">{{
            item.label
          }}</span>
        </div>
        <ul v-if="item.subItems" class="AppNewPasswordRequirements__sub-items">
          <li
            v-for="(subItem, j) in item.subItems"
            :key="j"
            :class="[
              'AppNewPasswordRequirements__sub-item',
              {
                'AppNewPasswordRequirements__sub-item--satisfied': subItem.value
              }
            ]"
          >
            <span class="AppNewPasswordRequirements__sub-item-text">{{
              subItem.label
            }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  MIN_PASSWORD_COMPLEXITY,
  MIN_PASSWORD_LENGTH,
  validatePassword
} from '@/lib/passwordValidator';

export default {
  name: 'AppNewPasswordRequirements',
  props: { password: { type: String, required: true } },
  computed: {
    passwordRequirements() {
      const { requirements, complexities } = validatePassword(this.password);

      const complexityItems = _.map(complexities, (value, key) => ({
        label: this.$t(key),
        value
      }));

      return _.map(requirements, (value, key) => ({
        label: this.$t(
          key,
          key === 'enough_length'
            ? [MIN_PASSWORD_LENGTH]
            : key === 'enough_complexity'
            ? [MIN_PASSWORD_COMPLEXITY]
            : null
        ),
        value,
        subItems: key === 'enough_complexity' ? complexityItems : null
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppNewPasswordRequirements__main-items {
  @include text-caption;
  margin-top: 4px;
  margin-left: 8px;
}

.AppNewPasswordRequirements__main-item--satisfied {
  color: $color-mint-green-dark;
}

.AppNewPasswordRequirements__main-item-icon {
  position: relative;
  top: 1px;
}

.AppNewPasswordRequirements__main-item-text {
  margin-left: 8px;
}

.AppNewPasswordRequirements__sub-items {
  margin-left: 28px;
  list-style: disc inside;
}

.AppNewPasswordRequirements__sub-item--satisfied {
  color: $color-mint-green-dark;
}

.AppNewPasswordRequirements__sub-item-text {
  margin-left: -4px;
}
</style>

<i18n locale="ko">
{
  "no_space_front_and_back": "공백으로 시작하거나 끝나면 안됨",
  "ascii_only": "영문(대소문자), 숫자, 특수문자만 입력",
  "enough_length": "{0}자 이상 입력",
  "enough_complexity": "다음 중 {0}종류 이상 입력",
  "uppercase": "영문 대문자",
  "lowercase": "영문 소문자",
  "number": "숫자",
  "special_character": "특수문자"
}
</i18n>
