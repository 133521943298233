<template>
  <AppAjaxContent :is-loading="isLoading">
    <template #default>
      <Component :is="currentViewComponent" />
    </template>
  </AppAjaxContent>
</template>

<script>
import KakaoStyleReviewImportStatus from '@/enums/KakaoStyleReviewImportStatus';
import KakaoStyleAgreement from './components/KakaoStyleAgreement';
import KakaoStyleSettings from './components/KakaoStyleSettings';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ReviewImportKakaoStyle',
  components: { KakaoStyleAgreement, KakaoStyleSettings },
  data() {
    return { isLoading: true };
  },
  computed: {
    ...mapState('kakaoStyle', ['reviewImportStatus']),
    currentViewComponent() {
      return this.reviewImportStatus ===
        KakaoStyleReviewImportStatus.NOT_REQUESTED
        ? KakaoStyleAgreement
        : KakaoStyleSettings;
    }
  },
  mounted() {
    this.fetchHome().finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('kakaoStyle', ['fetchHome'])
  }
};
</script>
