export default [
  {
    id: 'product_settings',
    menuItemId: 'product_settings',
    sections: [
      {
        groups: [
          { id: 'recommendation_excluding_category_ids' },
          { id: 'recommendation_excluding_product_ids' }
        ]
      }
    ]
  }
];
