import { constructEnum } from '@/lib/enum';

export default constructEnum('ReviewSource', {
  UNKNOWN: 0,
  BRAND_MOBILE: 10,
  BRAND_MOBILE_POPUP: 11,
  BRAND_MOBILE_PRODUCT_DETAIL: 12,
  BRAND_MOBILE_PRODUCT_REVIEWS: 13,
  BRAND_MOBILE_MY_ORDERS: 15,
  BRAND_MOBILE_PENDING_REVIEW: 16,
  BRAND_MOBILE_REVIEW_DETAIL: 17,
  BRAND_MOBILE_MY_REVIEW: 18,
  BRAND_PC: 20,
  BRAND_PC_POPUP: 22,
  BRAND_PC_PRODUCT: 24,
  BRAND_PC_MY_ORDERS: 26,
  BRAND_PC_MY_REVIEW: 27,
  SMS: 30,
  IMPORTED: 40,
  SYNCHRONIZED: 41,
  BULK_IMPORTED: 42,
  TRANSLATED: 45,
  MANAGER: 50,
  UNPURCHASED: 55,
  COPIED: 60,
  EMAIL: 70,
  FIT_REVIEW_POPUP: 80,
  ETC: 90
});
