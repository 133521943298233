<template>
  <AppSummaryBox>
    <template #title>{{ $t('title') }}</template>
    <div class="AppStoreSummaryBox-content">
      {{
        isReviewOnlyShopBuilder
          ? $t('review_only_content', {
              shop_builder: ShopBuilder.t(shopBuilder)
            })
          : $t('default_content')
      }}
    </div>
    <template #buttons>
      <AppExternalLink :to="appStoreLink" target="_blank">
        <AppButton button-style="blue-outline">
          {{
            $t('button', {
              shop_builder: ShopBuilder.t(shopBuilder)
            })
          }}
          <AppExternalLinkIcon class="AppStoreSummaryBox__link-icon" />
        </AppButton>
      </AppExternalLink>
    </template>
  </AppSummaryBox>
</template>

<script>
import ShopBuilder from '@/enums/ShopBuilder';

export default {
  name: 'AppStoreSummaryBox',
  props: {
    shopBuilder: {
      type: Number,
      required: true
    },
    appStoreLink: {
      type: String,
      required: true
    }
  },
  computed: {
    ShopBuilder: () => ShopBuilder,
    isReviewOnlyShopBuilder() {
      return (
        this.shopBuilder === ShopBuilder.SHOPIFY ||
        this.shopBuilder === ShopBuilder.IMWEB
      );
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppStoreSummaryBox-content {
    white-space: pre-line;
  }
}
</style>

<i18n locale="ko">
  {
    "title": "앱 스토어에서 크리마 리뷰 앱을 설치해 주세요.",
    "review_only_content": "{shop_builder}에서는 크리마 리뷰 서비스 신청만 가능합니다. \n 앱 스토어에서 크리마 리뷰 앱 설치 후 가입 진행해 주세요.",
    "default_content": "크리마 리뷰 앱 설치 후 간편 회원가입이 진행됩니다.",
    "button": "{shop_builder} 앱 스토어 바로가기"
  }
  </i18n>
