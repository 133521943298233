<template>
  <div>
    <AppMultipleSelection
      :id="id"
      :options="currentOptions"
      :disabled="disabled"
      :invalid="invalid"
      :placeholder="placeholder"
      class="AppSelectSearchable__selection"
      @click="click"
      @remove="unselect($event.value)"
    />
    <template v-if="name">
      <AppHiddenInputArray
        v-if="multiple"
        :name="name"
        :values="currentItems.map(i => i.id)"
      />
      <input
        v-else
        type="hidden"
        :name="name"
        :value="currentItems.length ? currentItems[0].id : null"
      />
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import ArrayPackable from '@/mixins/ArrayPackable';

export default {
  name: 'AppSelectSearchable',
  mixins: [ArrayPackable],
  props: {
    id: { type: String, default: null },
    name: { type: String, default: null },
    items: { type: Array, required: true },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    multiple: { type: Boolean, default: false },
    title: { type: String, required: true },
    columns: { type: Array, required: true },
    searchTypes: { type: Array, default: () => [] },
    selectButtonLabel: { type: String, default: null },
    sort: { type: Boolean, default: true }
  },
  data() {
    return { currentItems: [] };
  },
  computed: {
    currentOptions() {
      return this.currentItems.map(i => ({ label: i.name, value: i.id }));
    }
  },
  watch: {
    items() {
      this.resetCurrentItems();
    }
  },
  created() {
    this.resetCurrentItems();
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    select(items) {
      this.change(items);
    },
    unselect(val) {
      this.change(this.currentItems.filter(o => o.id !== val));
    },
    click() {
      this.openDialog([
        'AppSelectSearchableDialog',
        {
          title: this.title,
          searchTypes: this.searchTypes,
          columns: this.columns,
          multiple: this.multiple,
          items: this.currentItems,
          selectButtonLabel: this.selectButtonLabel,
          sort: this.sort
        }
      ]).then(eventBus => {
        eventBus.$on('search', args => this.$emit('search', args));
        eventBus.$on('select', this.select);
      });
    },
    change(items) {
      this.currentItems = items;
      const values = this.currentItems.map(i => i.id);
      this.$emit(
        'change',
        this.multiple
          ? this.packFromArray(values)
          : values.length
          ? values[0]
          : null
      );
      this.$emit('change-items', items);
    },
    resetCurrentItems() {
      this.currentItems = _.cloneDeep(this.items);
    }
  }
};
</script>
