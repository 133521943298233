<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppTextarea v-model="valueBasic" />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Disabled</div>
      <AppTextarea v-model="valueDisabled" disabled />
      <div class="ui-section__description">
        Selected Value: {{ valueDisabled }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Invalid</div>
      <AppTextarea v-model="valueInvalid" invalid />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">No Autosize</div>
      <AppTextarea v-model="valueNoAutosize" :autosize="false" />
      <div class="ui-section__description">
        Selected Value: {{ valueNoAutosize }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">No Minimum Rows</div>
      <AppTextarea v-model="valueNoMinRows" :rows="1" />
      <div class="ui-section__description">
        Selected Value: {{ valueNoMinRows }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="max_bytes">Max Bytes</label>
      </div>
      <AppTextarea
        id="max_bytes"
        v-model="valueMaxBytes"
        placeholder="10 bytes 이내로 입력해주세요."
        :max-bytes="10"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueMaxBytes }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="max_length">Max Characters</label>
      </div>
      <AppTextarea
        id="max_length"
        v-model="valueMaxLength"
        placeholder="70자 이내로 입력해주세요."
        :maxlength="70"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueMaxLength }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTextarea',
  data() {
    return {
      valueBasic: '',
      valueDisabled: '',
      valueInvalid: '',
      valueNoAutosize: '',
      valueNoMinRows: '',
      valueWithMaxByteSize: '',
      valueMaxBytes: '',
      valueMaxLength: ''
    };
  }
};
</script>
