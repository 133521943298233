<template>
  <div>
    <i18n path="title" class="DisplayProductDisplayCategoryCorners__title">
      <AppDropdownMenuButton
        class="DisplayProductDisplayCategoryCorners__button"
        :label="label"
        :menu-items="menuItems"
      />
    </i18n>
    <div class="DisplayProductDisplayCategoryCorners__description">
      <span>{{ $t('description', [display.corners.length]) }}</span>
      <AppButton
        button-style="underline"
        class="DisplayProductDisplayCategoryCorners__button"
        @click="confirmSelectCategoryCorners"
        >{{ $t('update_corners') }}</AppButton
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import DisplayCornerStatus from '@/enums/DisplayCornerStatus';
import DisplayPageType from '@/enums/DisplayPageType';

export default {
  name: 'DisplayProductDisplayCategoryCorners',
  computed: {
    ...mapState('displayProductDisplay', ['display', 'cornerId']),
    ...mapGetters('displayProductDisplay', ['isDisplayDataChanged']),
    ...mapGetters('productCategory', ['productCategoriesWithTreeName']),
    categoryCornerNamesMap() {
      const result = {};
      this.productCategoriesWithTreeName.forEach(category => {
        const { name, treeName } = category;
        result[category.code] = { name, treeName };
      });

      return result;
    },
    corners() {
      return this.display.corners.map(corner => {
        const isDeleted = DisplayCornerStatus.isDeleted(corner.corner_status);
        const { name, treeName } = this.categoryCornerNamesMap[corner.code];

        return {
          ...corner,
          name: isDeleted ? this.$t('deleted', [name]) : name,
          treeName: isDeleted ? this.$t('deleted', [treeName]) : treeName
        };
      });
    },
    label() {
      return this.corners.find(corner => corner.id === this.cornerId).name;
    },
    menuItems() {
      return this.corners.map(corner => ({
        label: corner.treeName,
        clickHandler: ({ close }) => this.updateCurrentCorner(corner, close)
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('displayProductDisplay', [
      'updateDisplayCorners',
      'switchDisplayCorner'
    ]),
    confirmSelectCategoryCorners() {
      if (this.isDisplayDataChanged) {
        this.openDialog([
          'AppMessageDialog',
          {
            title: this.$t('confirm_update_corners.title'),
            markdownText: this.$t('confirm_update_corners.content_html'),
            type: 'delete_confirm'
          }
        ]).then(eventBus =>
          eventBus.$on('close', this.openSelectCategoryCornersDialog)
        );
      } else this.openSelectCategoryCornersDialog();
    },
    openSelectCategoryCornersDialog() {
      this.openDialog([
        'DisplayProductDisplaySelectCategoryCornersDialog',
        { selectedCategoryCodes: this.display.corners.map(({ code }) => code) }
      ]).then(eventBus => {
        eventBus.$on('submit', cornerCodes => {
          const {
            scheduled_cohort_id,
            next_execute_time,
            auto_scheduling
          } = this.display;

          const isScheduled = !!next_execute_time;
          const isAutoScheduledCohortProcessing =
            auto_scheduling && !scheduled_cohort_id;

          if (isScheduled && !isAutoScheduledCohortProcessing)
            this.confirmScheduledCohortExists(
              next_execute_time,
              eventBus,
              cornerCodes,
              auto_scheduling ? 'auto_schedule' : 'schedule'
            );
          else this.submit(eventBus, cornerCodes);
        });
      });
    },
    confirmScheduledCohortExists(
      scheduledAt,
      dialogEventBus,
      cornerCodes,
      schedule_type
    ) {
      this.openDialog([
        'AppMessageDialog',
        {
          title: this.$t(
            `confirm_scheduled_cohort_exists.${schedule_type}.title`
          ),
          markdownText: this.$t(
            `confirm_scheduled_cohort_exists.${schedule_type}.content_html`,
            [this.formatDateTimeWithoutSecond(scheduledAt)]
          ),
          closeButtonLabel: this.$t('confirm_scheduled_cohort_exists.confirm'),
          type: 'confirm'
        }
      ]).then(eventBus =>
        eventBus.$on('close', () => this.submit(dialogEventBus, cornerCodes))
      );
    },
    submit(dialogEventBus, cornerCodes) {
      const { id } = this.display;

      api
        .patch(
          `display/product_displays/${id}/update_corner_previews`,
          {
            product_display: {
              page_type: DisplayPageType.CATEGORY,
              corner_codes: cornerCodes
            }
          },
          { successMessage: this.$t('updated_corner_previews') }
        )
        .then(({ data }) => {
          dialogEventBus.$emit('close-dialog', true);
          this.switchDisplayCorner({});

          this.updateDisplayCorners({
            corners: data.corners,
            cornerDetails: data.corner_previews
          });
          this.switchDisplayCorner(data.corners[0]);
        });
    },
    updateCurrentCorner(corner, close) {
      this.switchDisplayCorner(corner);
      close();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayProductDisplayCategoryCorners__title {
  @include text-sub-title;
}

.DisplayProductDisplayCategoryCorners__button {
  margin: 0 8px;
  vertical-align: baseline;
}

.DisplayProductDisplayCategoryCorners__description {
  margin-top: 8px;
}

.DisplayProductDisplayCategoryCorners__button {
  margin-left: 8px;
}
</style>

<i18n locale="ko">
{
  "title": "현재 보고 있는 카테고리는{0}입니다.",
  "description": "{0}개 카테고리에 적용할 규칙을 설정 중입니다.",
  "deleted": "{0} (삭제됨)",
  "update_corners": "적용 대상 카테고리 변경",
  "confirm_update_corners": {
    "title": "저장되지 않은 변경사항이 있습니다.",
    "content_html": "적용 대상 카테고리 변경 진행 시 저장하지 않은 사항은 사라집니다.<br />정말로 진행하시겠습니까?"
  },
  "confirm_scheduled_cohort_exists": {
    "schedule": {
      "title": "1회 예약된 진열 항목이 있습니다.",
      "content_html": "<b>{0} 진열 예약항목</b>은 이미 계산이 완료되어 대상 카테고리 변경에 영향을 받지 않습니다.<br />계산 완료된 진열예약에 대하여 대상 카테고리 변경을 원하시면 예약 취소 후 다시 예약해 주시기 바랍니다."
    },
    "auto_schedule": {
      "title": "반복 예약된 진열 항목이 있습니다.",
      "content_html": "앞으로 진열 예약 계산 시 변경된 대상 카테고리를 사용합니다."
    },
    "confirm": "대상 카테고리 변경"
  },
  "updated_corner_previews": "대상 카테고리가 변경되었습니다."
}
</i18n>
