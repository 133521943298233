<template>
  <AppContainer>
    <div class="AnalyticsInsightProductDialogProduct">
      <AppImage
        :src="product.image_url"
        background
        class="AnalyticsInsightProductDialogProduct__image"
      />
      <div class="AnalyticsInsightProductDialogProduct__right">
        <div class="AnalyticsInsightProductDialogProduct__name">
          {{ product.name }}
        </div>
        <div class="AnalyticsInsightProductDialogProduct__code">
          {{ $t('products.code') + ' ' + product.code }}
        </div>
        <div class="AnalyticsInsightProductDialogProduct__infos">
          <div
            v-for="productInfo in productInfos"
            :key="productInfo.title"
            class="AnalyticsInsightProductDialogProduct__info"
          >
            <div class="AnalyticsInsightProductDialogProduct__info-title">
              {{ productInfo.title }}
              <AppSvgIcon
                v-if="productInfo.tooltip"
                v-tooltip="productInfo.tooltip"
                name="icon-info-tooltip"
                :width="10"
                :height="10"
              />
            </div>
            <div class="AnalyticsInsightProductDialogProduct__info-value">
              {{ productInfo.value }}
            </div>
          </div>
        </div>
        <AppButton
          v-if="isReviewOperating"
          class="AnalyticsInsightProductDialogProduct__show-reviews-button"
          type="external_link"
          :to="reviewsPath"
          :label="$t('show_reviews')"
        />
      </div>
    </div>
  </AppContainer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import qs from 'qs';
import BrandReviewStatus from '@/enums/BrandReviewStatus';

export default {
  name: 'AnalyticsInsightProductDialogProduct',
  props: {
    product: { type: Object, required: true },
    dateRange: { type: Object, required: true },
    analyticsType: { type: String, required: true }
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters(['pathWithBrandParams']),
    productInfos() {
      const precision = this.analyticsType === 'potential' ? 2 : 1;
      const valueString = (
        this.product.analysis_aggregated_value || '-'
      ).toLocaleString(undefined, {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
      });
      const mediaReviewsRatio = this.product.reviews_count
        ? (this.product.media_reviews_count * 100) / this.product.reviews_count
        : 0;

      return [
        {
          title: this.$t(
            `insight.products.analysis_aggregated_value.${this.analyticsType}`
          ),
          value:
            this.analyticsType === 'risky'
              ? this.$t('unit.percentage', [valueString])
              : valueString,
          tooltip: this.$t(
            `insight.products.analysis_aggregated_value_tooltip.${this.analyticsType}`
          )
        },
        {
          title: this.$t('insight.products.score'),
          value: this.product.score
            ? `${this.product.score.toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
              })}/5.0`
            : '-',
          tooltip: this.$t('insight.products.score_tooltip')
        },
        {
          title: this.$t('insight.reviews_count'),
          value: this.product.reviews_count.toLocaleString()
        },
        {
          title: this.$t('insight.media_reviews_ratio'),
          value: this.$t('unit.percentage', [
            mediaReviewsRatio.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
          ])
        },
        ...['sub_orders_count', 'sales'].map(v => ({
          title: this.$t(`insight.products.${v}`),
          value: this.product[v].toLocaleString()
        }))
      ];
    },
    isReviewOperating() {
      return BrandReviewStatus.isOperating(this.currentBrand.review_status);
    },
    reviewsPath() {
      const params = qs.stringify({
        search_type: 'product_code',
        search_query: this.product.code,
        ...this.dateRange
      });
      return this.pathWithBrandParams(`/v2/review/reviews?${params}`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightProductDialogProduct {
  position: relative;
}

$image-size: 100px;
.AnalyticsInsightProductDialogProduct__image {
  width: $image-size;
  height: $image-size;
  position: absolute;
  top: 0;
  left: 0;
}

.AnalyticsInsightProductDialogProduct__right {
  position: relative;
  margin-left: $image-size + 16px;
  height: 100%;
}

.AnalyticsInsightProductDialogProduct__name {
  @include text-label;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AnalyticsInsightProductDialogProduct__code {
  @include text-caption;
  padding-top: 4px;
}

.AnalyticsInsightProductDialogProduct__infos {
  display: inline-block;
  width: 100%;
  margin-top: 4px;
}

.AnalyticsInsightProductDialogProduct__info {
  display: inline-block;
  min-width: 52px;
  height: 42px;
  margin-top: 12px;
  margin-right: 44px;
}

.AnalyticsInsightProductDialogProduct__info-title {
  @include text-caption;
}

.AnalyticsInsightProductDialogProduct__info-value {
  @include text-content-emphasis;
  padding-top: 4px;
}

.AnalyticsInsightProductDialogProduct__show-reviews-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>

<i18n locale="ko">
{
  "show_reviews": "리뷰 목록"
}
</i18n>
