<template>
  <div class="TargetCampaignsTableEffectsCell">
    <AppMiniChart
      v-for="(chartProps, i) in charts"
      :key="i"
      class="TargetCampaignsTableEffectsCell__chart"
      type="spline"
      :focused-index="focusedIndex"
      v-bind="{ ...chartProps, data: toChartData(chartProps.data) }"
      @change="focusedIndex = $event"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';

export default {
  name: 'TargetCampaignsTableEffectsCell',
  props: { campaign: { type: Object, required: true } },
  data: () => ({ focusedIndex: 6 }),
  computed: {
    ...mapGetters('targetCampaigns', ['campaignText']),
    charts() {
      const { stats, campaign_media_type } = this.campaign;
      return campaign_media_type === TargetCampaignMediaType.DIRECT_MESSAGE
        ? [
            {
              data: stats.map(s => s.sent_count),
              valueLabel: this.$t('label.sent'),
              valueUnit: this.$t('count')
            },
            {
              data: stats.map(s => Math.round(s.check_rate * 100)),
              valueLabel: this.$t('label.checked'),
              valueUnit: '%'
            },
            {
              data: stats.map(s => Math.round(s.effects * 100)),
              valueLabel: this.effectsTypeName,
              valueUnit: '%'
            }
          ]
        : campaign_media_type === TargetCampaignMediaType.WIDGET
        ? [
            {
              data: stats.map(s => s.unique_check_count),
              valueLabel: this.$t('label.display'),
              valueUnit: this.$t('count')
            },
            {
              data: stats.map(s => Math.round(s.click_per_check * 100)),
              valueLabel: this.$t('label.clicked'),
              valueUnit: '%'
            },
            {
              data: stats.map(s => Math.round(s.clicked_effects * 100)),
              valueLabel: this.effectsTypeName,
              valueUnit: '%'
            }
          ]
        : [
            {
              data: stats.map(s => s.unique_check_count),
              valueLabel: this.$t('label.display'),
              valueUnit: this.$t('count')
            },
            {
              data: stats.map(s => Math.round(s.click_per_check * 100)),
              valueLabel: this.$t('label.clicked'),
              valueUnit: '%'
            },
            {
              data: stats.map(s => Math.round(s.effects * 100)),
              valueLabel: this.effectsTypeName,
              valueUnit: '%'
            }
          ];
    },
    effectsTypeName() {
      return this.campaignText(this.campaign, 'kpi.name');
    }
  },
  methods: {
    toChartData(ys) {
      const WEEKS = 8;
      return ys.map((y, index) => {
        const weeksAgo = WEEKS - index - 1;
        return {
          y,
          tooltipMessage: weeksAgo
            ? this.$t('unit.weeks_ago', [weeksAgo])
            : this.$t('unit.this_week'),
          tooltipInfo:
            weeksAgo * 7 >
            TargetCampaignMediaType.effectPeriodByDays(
              this.campaign.campaign_media_type
            )
              ? null
              : this.$t('collecting')
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignsTableEffectsCell {
  white-space: nowrap;
  line-height: 0;
  padding-top: 14px;
}

.TargetCampaignsTableEffectsCell__chart {
  & + & {
    margin-left: 24px;
  }
}
</style>

<i18n locale="ko">
{
  "label": {
    "sent": "발송",
    "checked": "확인",
    "display": "노출",
    "clicked": "클릭"
  },
  "count": "건",
  "collecting": "효과 수집 중"
}
</i18n>
