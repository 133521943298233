<template>
  <div>
    <AppToastTransition :gap="16" :items="jobs">
      <template #item="{ item }">
        <AppMultiAsyncJob class="TheMultiAsyncJobs__item" :job="item" />
      </template>
    </AppToastTransition>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TheMultiAsyncJobs',
  computed: {
    ...mapState('multiAsyncJob', ['jobs'])
  }
};
</script>

<style lang="scss" scoped>
.TheMultiAsyncJobs__item {
  pointer-events: all;
}
</style>
