<template>
  <SlotModalForm
    class="InstallRequestRenewalSlotReviewBoardDialog"
    :title="$t('title')"
    :sub-title="$t('review_board')"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #head>
      <SlotInfoCard :image-url="cardImageUrl" :name="$t('review_board')" />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'pc_review_board'">
        <SlotTable
          :device-type="WidgetDeviceType.PC"
          :slot-type="InstallSlotType.REVIEW_BOARD"
        />
      </template>
      <template v-else-if="id === 'mobile_review_board'">
        <SlotTable
          :device-type="WidgetDeviceType.MOBILE"
          :slot-type="InstallSlotType.REVIEW_BOARD"
        />
      </template>
    </template>
  </SlotModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import SlotModalForm from './components/SlotModalForm';
import SlotInfoCard from './components/SlotInfoCard';
import SlotTable from './components/SlotTable';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import ReviewBoardInstallOptionType from '@/enums/ReviewBoardInstallOptionType';
import DialogSize from '@/enums/DialogSize';
import WidgetPageType from '@/enums/WidgetPageType';

export default {
  name: 'InstallRequestRenewalSlotReviewBoardDialog',
  components: {
    SlotModalForm,
    SlotInfoCard,
    SlotTable
  },
  mixins: [DialogFormView],
  data() {
    return {
      cardImageUrl:
        'https://assets.cre.ma/m/admin/v2/install_request/review_board_slot@3x.png',
      oldInstallations: []
    };
  },
  computed: {
    ...mapState('session', ['reviewSettings']),
    ...mapState('installRequest', ['installDeviceTypes']),
    ...mapGetters('installRequest', [
      'currentInstallations',
      'currentDirections'
    ]),
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    InstallSlotType() {
      return InstallSlotType;
    },
    formSections() {
      return [
        {
          id: 'custom_url',
          groups: [
            {
              id: 'custom_url',
              label: this.$t('custom_url.label'),
              type: 'text',
              placeholder: this.$t('custom_url.placeholder'),
              hint: this.$t('custom_url.hint', [
                this.reviewSettings.reviews_index_url
              ]),
              groupDescription: this.$t('custom_url.group_description')
            }
          ]
        },
        {
          id: 'install_option',
          groups: [
            {
              id: 'install_option',
              label: this.$t('install_option.label'),
              type: 'select',
              validate: ['required'],
              value:
                this.formObject.install_option ||
                ReviewBoardInstallOptionType.REPLACE_PAGE,
              hint: this.$t('install_option.hint'),
              options: ReviewBoardInstallOptionType.options()
            }
          ]
        },
        {
          id: 'review_board_slot',
          label: this.$t('select_widget'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('select_widget_tip')
            },
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
              ? [
                  {
                    id: 'pc_review_board',
                    label: this.$t('app.pc')
                  }
                ]
              : []),
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
              ? [
                  {
                    id: 'mobile_review_board',
                    label: this.$t('app.mobile')
                  }
                ]
              : [])
          ]
        }
      ];
    },
    isDirectionChanged() {
      const direction = this.currentDirections[0];
      return (
        this.formObject['custom_url'] !== direction?.custom_url ||
        this.formObject['install_option'] !== direction?.install_option
      );
    },
    isInstallationChanged() {
      const orgSortedInstallations = _.sortBy(this.oldInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const sortedInstallations = _.sortBy(this.currentInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const result = !_.isEqual(orgSortedInstallations, sortedInstallations);
      return result;
    },
    isFormDataChanged() {
      return this.isInstallationChanged || this.isDirectionChanged;
    }
  },
  created() {
    this.SET_CURRENT_PAGE_TYPE(WidgetPageType.REVIEW_BOARD);
    this.SET_CURRENT_SLOT_TYPES([InstallSlotType.REVIEW_BOARD]);
    this.oldInstallations = this.currentInstallations || [];
    this.setDirection();
  },
  methods: {
    ...mapActions('dialog', ['alert']),
    ...mapMutations('installRequest', [
      'SET_CURRENT_PAGE_TYPE',
      'SET_CURRENT_SLOT_TYPES',
      'ADD_INSTALLATIONS',
      'REMOVE_INSTALLATION',
      'APPEND_DIRECTION',
      'REMOVE_CURRENT_SLOT_DIRECTIONS'
    ]),
    openInstallationGuideDialog() {
      this.alert({
        title: this.$t('guide.title'),
        message: this.$t('guide.message'),
        width: DialogSize.MIDDLE,
        closeButtonStyle: 'default',
        closeButtonLabel: this.$t('app.close')
      });
    },
    beforeCloseDialog() {
      this.currentInstallations.forEach(installation =>
        this.REMOVE_INSTALLATION(installation)
      );
      this.ADD_INSTALLATIONS(this.oldInstallations);
    },
    setDirection() {
      const direction = this.currentDirections[0];

      if (direction) {
        this.$set(this.formObject, 'custom_url', direction.custom_url);
        this.$set(this.formObject, 'install_option', direction.install_option);
      }
    },
    appendDirection(customUrl, installOption) {
      const data = {
        page_type: WidgetPageType.REVIEW_BOARD,
        slot: InstallSlotType.REVIEW_BOARD
      };

      if (customUrl) {
        data.custom_url = customUrl;
      }

      if (installOption) {
        data.install_option = installOption;
      }

      if (customUrl || installOption) {
        this.APPEND_DIRECTION(data);
      }
    },
    submit() {
      this.REMOVE_CURRENT_SLOT_DIRECTIONS();
      this.appendDirection(
        this.formObject['custom_url'],
        this.formObject['install_option']
      );
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppForm__group {
    margin-top: 0;
  }

  .AppForm__section:last-child {
    padding-top: 0px;
  }

  #review_board_slot--mobile_review_board {
    margin-top: 32px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "review_board": "전체 리뷰 페이지",
  "select_widget": "위젯 선택",
  "select_widget_tip": "설치할 위젯을 선택해 주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해주세요.",
  "custom_url" : {
    "label": "전체 리뷰 페이지 URL",
    "group_description": "사이트 리뉴얼로 전체 리뷰 페이지 URL이 변경될 경우만 입력해주세요.",
    "placeholder": "전체 리뷰 페이지 URL을 입력해주세요.",
    "hint": "현재 설정된 전체 리뷰 페이지 URL: {0}"
  },
  "install_option" : {
    "label": "설치 방법",
    "hint": "설치 방법에 관한 참고 이미지를 확인해보세요."
  },
  "guide": {
    "title": "전체 리뷰 페이지 설치 방법 안내",
    "message": "![](https://assets.cre.ma/m/admin/v2/position_preview_pc_review_board.png)"
  }
}
</i18n>
