<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Normal</label>
      </div>
      <AppMultipleSelectProductCategory v-model="productCategoriesNormal" />
      <div class="ui-section__description">
        Selected Value: {{ productCategoriesNormal }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Invalid</label>
      </div>
      <AppMultipleSelectProductCategory
        v-model="productCategoriesInvalid"
        invalid
      />
      <div class="ui-section__description">
        Selected Value: {{ productCategoriesInvalid }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Disabled</label>
      </div>
      <AppMultipleSelectProductCategory
        v-model="productCategoriesDisabled"
        disabled
      />
      <div class="ui-section__description">
        Selected Value: {{ productCategoriesDisabled }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiMultipleSelectProductCategory',
  data: () => ({
    productCategoriesNormal: [],
    productCategoriesInvalid: [],
    productCategoriesDisabled: []
  })
};
</script>
