const MENU_ITEMS_DIALOG_MORE_MENU = [
  {
    id: 'notices_dialog',
    type: 'dialog',
    dialog: 'TheNoticesDialog',
    group: 0
  },
  {
    id: 'manuals_dialog',
    type: 'dialog',
    dialog: 'TheManualsDialog',
    isAccessible: getters => !getters.isShopifyBrand,
    group: 0
  },
  {
    id: 'manuals_dialog_all',
    type: 'dialog_tab',
    dialog: 'TheManualsDialog',
    parentId: 'manuals_dialog'
  },
  {
    id: 'manuals_dialog_review',
    type: 'dialog_tab',
    dialog: 'TheManualsDialog',
    parentId: 'manuals_dialog'
  },
  {
    id: 'manuals_dialog_target',
    type: 'dialog_tab',
    dialog: 'TheManualsDialog',
    parentId: 'manuals_dialog'
  },
  {
    id: 'manuals_dialog_fit',
    type: 'dialog_tab',
    dialog: 'TheManualsDialog',
    parentId: 'manuals_dialog'
  },
  {
    id: 'manuals_dialog_display',
    type: 'dialog_tab',
    dialog: 'TheManualsDialog',
    parentId: 'manuals_dialog'
  },
  {
    id: 'global_help_center',
    type: 'external_link',
    path: 'https://help-global.cre.ma',
    isAccessible: getters => getters.isShopifyBrand,
    group: 0
  },
  {
    id: 'laboratory_dialog',
    type: 'dialog',
    dialog: 'ThelaboratoryDialog',
    isAccessible: getters => !getters.isShopifyBrand && getters.isKoreanBrand,
    group: 1
  },
  {
    id: 'logs_dialog',
    type: 'dialog',
    dialog: 'TheLogsDialog',
    superAdmin: getters => !getters.isCurrentUserInstallationAgency,
    group: 2
  },
  {
    id: 'logs_dialog_review_mileage',
    type: 'dialog_tab',
    dialog: 'TheLogsDialog',
    parentId: 'logs_dialog',
    superAdmin: true
  },
  {
    id: 'logs_dialog_widget_settings',
    type: 'dialog_tab',
    dialog: 'TheLogsDialog',
    parentId: 'logs_dialog',
    superAdmin: true
  },
  {
    id: 'logs_dialog_common_settings',
    type: 'dialog_tab',
    dialog: 'TheLogsDialog',
    parentId: 'logs_dialog',
    superAdmin: true
  },
  {
    id: 'logs_dialog_smart_installation',
    type: 'dialog_tab',
    dialog: 'TheLogsDialog',
    parentId: 'logs_dialog',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'logs_dialog_smart_installation_settings',
    type: 'dialog_tab',
    dialog: 'TheLogsDialog',
    parentId: 'logs_dialog',
    superAdmin: true,
    installationAgency: true,
    isAccessible: getters => getters.isSmartInstallationSupported
  },
  {
    id: 'logs_dialog_biz_message',
    type: 'dialog_tab',
    dialog: 'TheLogsDialog',
    parentId: 'logs_dialog',
    superAdmin: true
  },
  {
    id: 'admin_audit_logs_dialog',
    type: 'dialog',
    dialog: 'TheAdminAuditLogsDialog',
    superAdmin: true,
    isAccessible: getters => !getters.isShopifyBrand || getters.isKoreanBrand,
    group: 2
  },
  {
    id: 'global_crema_intro',
    type: 'external_link',
    path:
      'https://cremafactory.notion.site/SERVICE-INTRODUCTION-132504922fd8456bb4507d9fb2d8ce2e',
    isAccessible: getters => getters.isShopifyBrand,
    group: 3
  },
  {
    id: 'crema_homepage',
    type: 'external_link',
    path: getters =>
      `https://www.cre.ma${getters.adminLocale === 'en' ? '/en' : ''}`,
    group: 3
  },
  {
    id: 'crema_blog',
    type: 'external_link',
    path: 'https://blog.naver.com/cremablog',
    isAccessible: getters => !getters.isShopifyBrand,
    group: 3
  },
  {
    id: 'crema_help_center',
    type: 'expandable',
    isAccessible: getters => !getters.isShopifyBrand,
    group: 3
  },
  {
    id: 'crema_help_center_review',
    type: 'external_link',
    path: 'https://help-review.cre.ma/hc/ko',
    parentId: 'crema_help_center',
    isAccessible: getters => !getters.isShopifyBrand
  },
  {
    id: 'crema_help_center_fit',
    type: 'external_link',
    path: 'https://help-fit.cre.ma/hc/ko',
    parentId: 'crema_help_center',
    isAccessible: getters => !getters.isShopifyBrand
  },
  {
    id: 'crema_help_center_target',
    type: 'external_link',
    path: 'https://help-target.cre.ma/hc/ko',
    parentId: 'crema_help_center',
    isAccessible: getters => !getters.isShopifyBrand
  },
  {
    id: 'crema_help_center_display',
    type: 'external_link',
    path: 'https://help-display.cre.ma/hc/ko',
    parentId: 'crema_help_center',
    isAccessible: getters => !getters.isShopifyBrand
  },
  {
    id: 'crema_sample_site',
    type: 'expandable',
    isAccessible: getters => !getters.isShopifyBrand,
    group: 3
  },
  {
    id: 'crema_sample_site_review',
    type: 'external_link',
    path: 'https://sample.cre.ma/v2/review',
    parentId: 'crema_sample_site',
    isAccessible: getters => !getters.isShopifyBrand
  },
  {
    id: 'crema_sample_site_fit',
    type: 'external_link',
    path: 'https://sample.cre.ma/fit/index',
    parentId: 'crema_sample_site',
    isAccessible: getters => !getters.isShopifyBrand
  }
].map(item => ({ ...item, dialogMenu: 'more_menu' }));

export default [
  ...MENU_ITEMS_DIALOG_MORE_MENU,
  {
    id: 'payment_dialog',
    type: 'dialog',
    dialog: 'ThePaymentDialog'
  },
  {
    id: 'payment_history_dialog',
    type: 'dialog',
    dialog: 'ThePaymentHistoryDialog'
  },
  {
    id: 'payment_history_dialog_payments',
    type: 'dialog_tab',
    dialog: 'ThePaymentHistoryDialog',
    parentId: 'payment_history_dialog'
  },
  {
    id: 'payment_history_dialog_review',
    type: 'dialog_tab',
    dialog: 'ThePaymentHistoryDialog',
    parentId: 'payment_history_dialog'
  },
  {
    id: 'payment_history_dialog_fit',
    type: 'dialog_tab',
    dialog: 'ThePaymentHistoryDialog',
    parentId: 'payment_history_dialog'
  },
  {
    id: 'payment_history_dialog_target',
    type: 'dialog_tab',
    dialog: 'ThePaymentHistoryDialog',
    parentId: 'payment_history_dialog'
  },
  {
    id: 'payment_history_dialog_display',
    type: 'dialog_tab',
    dialog: 'ThePaymentHistoryDialog',
    parentId: 'payment_history_dialog',
    isAccessible: getters => getters.isDisplayMenuAccessibleByShopBuilder,
    isLaunched: getters => getters.isDisplayMenuLaunched
  },
  {
    id: 'payment_history_dialog_ad',
    type: 'dialog_tab',
    dialog: 'ThePaymentHistoryDialog',
    parentId: 'payment_history_dialog',
    isLaunched: getters => getters.isChannelMenuLaunched
  },
  {
    id: 'payment_history_dialog_insight',
    type: 'dialog_tab',
    dialog: 'ThePaymentHistoryDialog',
    parentId: 'payment_history_dialog',
    isLaunched: false,
    superAdmin: true
  },
  {
    id: 'payment_history_dialog_review_additional_services',
    type: 'dialog_tab',
    dialog: 'ThePaymentHistoryDialog',
    parentId: 'payment_history_dialog',
    isAccessible: getters =>
      getters.isSuperAdminAccessible ||
      getters.paymentHistoryReviewAdditionalServicesAccessible
  },
  {
    id: 'settings_dialog',
    type: 'dialog',
    dialog: 'TheSettingsDialog'
  },
  {
    id: 'settings_dialog_brand',
    type: 'dialog_tab',
    dialog: 'TheSettingsDialog',
    parentId: 'settings_dialog',
    isAccessible: getters => getters.isBrandSettingsAccessible
  },
  {
    id: 'settings_dialog_user',
    type: 'dialog_tab',
    dialog: 'TheSettingsDialog',
    parentId: 'settings_dialog'
  },
  {
    id: 'settings_dialog_material',
    type: 'dialog_tab',
    dialog: 'TheSettingsDialog',
    parentId: 'settings_dialog',
    isAccessible: getters => !getters.isShopifyBrand || getters.isKoreanBrand
  },
  {
    id: 'settings_dialog_api_key',
    type: 'dialog_tab',
    dialog: 'TheSettingsDialog',
    parentId: 'settings_dialog'
  },
  {
    id: 'settings_dialog_third_party',
    type: 'dialog_tab',
    dialog: 'TheSettingsDialog',
    parentId: 'settings_dialog',
    superAdmin: true
  },
  {
    id: 'admin_locale_dialog',
    type: 'dialog',
    dialog: 'TheAdminLocaleDialog'
  },
  {
    id: 'review_settings_comment_preset_dialog',
    type: 'dialog',
    dialog: 'ReviewSettingsCommentPresetDialog'
  },
  {
    id: 'review_settings_comment_preset_dialog_smart',
    type: 'dialog_tab',
    dialog: 'ReviewSettingsCommentPresetDialog',
    parentId: 'review_settings_comment_preset_dialog'
  },
  {
    id: 'review_settings_comment_preset_dialog_default',
    type: 'dialog_tab',
    dialog: 'ReviewSettingsCommentPresetDialog',
    parentId: 'review_settings_comment_preset_dialog'
  },
  {
    id: 'fit_descriptions_edit_dialog',
    type: 'dialog',
    dialog: 'FitDescriptionsEditDialog',
    isAccessible: getters => getters.isFitAccessible
  },
  {
    id: 'fit_descriptions_edit_dialog_template',
    type: 'dialog_tab',
    dialog: 'FitDescriptionsEditDialog',
    parentId: 'fit_descriptions_edit_dialog',
    isAccessible: getters => getters.isFitAccessible
  },
  {
    id: 'fit_descriptions_edit_dialog_product',
    type: 'dialog_tab',
    dialog: 'FitDescriptionsEditDialog',
    parentId: 'fit_descriptions_edit_dialog',
    isAccessible: getters => getters.isFitAccessible
  },
  {
    id: 'install_request_renewal_dialog',
    type: 'dialog',
    dialog: 'TheInstallRequestRenewalDialog',
    isAccessible: getters => getters.isReviewAccessible,
    superAdmin: true // TODO : 론칭 후 일반 관리자에 오픈; 2024/09/09; 강주현; https://app.asana.com/0/1202077512166145/1208200982543862/f
  },
  {
    id: 'advanced_settings_dialog',
    type: 'dialog',
    dialog: 'TheAdvancedSettingsDialog',
    superAdmin: true
  },
  {
    id: 'advanced_settings_basic',
    type: 'dialog_tab',
    dialog: 'TheAdvancedSettingsDialog',
    parentId: 'advanced_settings_dialog',
    isAccessible: getters => getters.isReviewOperating
  },
  {
    id: 'advanced_settings_widget_installation',
    type: 'dialog_tab',
    dialog: 'TheAdvancedSettingsDialog',
    parentId: 'advanced_settings_dialog',
    isAccessible: getters => getters.isDisplayMenuAdvancedSettingsWidgetSettings
  },
  {
    id: 'advanced_settings_danger',
    type: 'dialog_tab',
    dialog: 'TheAdvancedSettingsDialog',
    parentId: 'advanced_settings_dialog'
  }
];
