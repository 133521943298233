<template>
  <AppInfoBox class="DisplayCornerInfoBox">
    {{ description }}
    <template v-if="infoType === 'deleted'">
      <DisplayCornerDeleteButton
        class="DisplayCornerInfoBox__delete-button"
        :corner="corner"
        @delete="redirectToCorners"
      />
    </template>
  </AppInfoBox>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DisplayCornerInfoBox',
  props: {
    infoType: {
      type: String,
      required: true,
      validator: v => ['product_displays', 'cohorts', 'deleted'].includes(v)
    },
    eventBus: { type: Object, required: true },
    corner: { type: Object, default: () => {} }
  },
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    ...mapGetters('displayProductDisplays', ['cornerPageTypeKey']),
    description() {
      return this.$t(`description.${this.infoType}.${this.cornerPageTypeKey}`);
    }
  },
  methods: {
    redirectToCorners() {
      this.eventBus.$emit('delete');
      this.eventBus.$emit('close-drawer');
    }
  }
};
</script>

<style scoped>
.DisplayCornerInfoBox {
  display: flex;
  justify-content: center;
}

.DisplayCornerInfoBox__delete-button {
  margin-left: 8px;
}
</style>

<i18n locale="ko">
{
  "description": {
    "product_displays": {
      "MAIN": "상황에 따라 다른 기준의 진열을 적용하고 싶으면 2개 이상의 설정을 만들어 사용할 수 있습니다.",
      "CATEGORY": "해당 카테고리가 대상으로 포함된 진열 설정을 관리 할 수 있습니다."
    },
    "cohorts": {
      "MAIN": "해당 진열 위치에 적용된 진열 설정을 보여줍니다.",
      "CATEGORY": "해당 카테고리에 적용된 진열 설정을 보여줍니다."
    },
    "deleted": {
      "MAIN": "삭제됨: 해당 진열 위치를 더 이상 사용하지 않으신다면 리스트에서 제거 버튼을 눌러 진열 리스트에서 제거하실 수 있습니다.",
      "CATEGORY": "삭제됨: 해당 카테고리를 더이상 사용하지 않으신다면 리스트에서 제거 버튼을 눌러 제거하실 수 있습니다."
    }
  }
}
</i18n>
