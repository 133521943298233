import { constructEnum } from '@/lib/enum';

const TargetWidgetRecommendationFilterServiceType = constructEnum(
  'TargetWidgetRecommendationFilterServiceType',
  {
    FIT: 10,
    REVIEW: 20,
    REVIEW_ANALYSIS: 30
  }
);

export default TargetWidgetRecommendationFilterServiceType;
