<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="default">Default</label>
      </div>
      <AppSelectResourceTable
        v-model="selectedItemIds"
        :columns="COLUMNS"
        :rows="rows"
        :resources="resources"
        :filter-options="FILTER_OPTIONS"
        :filter-values="filterValues"
        selected-item-column-label="선택"
        @paginate="pagination = $event"
        @change-filter-values="filterValues = $event"
      >
        <template #cell="{ column, row }">
          <template v-if="column === 'name'">{{ `row${row.id}` }}</template>
        </template>
      </AppSelectResourceTable>
      <div class="ui-section__description">
        {{ selectedItemIds }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiSelectResourceTable',
  data: () => ({
    selectedItemIds: [1],
    pagination: { page: 1, per: 20 },
    filterValues: []
  }),
  computed: {
    FILTER_OPTIONS() {
      return [
        { value: 'remove_odd', label: '홀수 빼고 보기' },
        { value: 'remove_even', label: '짝수 빼고 보기' }
      ];
    },
    COLUMNS() {
      return [
        { id: 'id', label: 'ID' },
        { id: 'name', label: '이름' }
      ];
    },
    TOTAL_COUNT() {
      return 21;
    },
    ITEMS() {
      return [...Array(this.TOTAL_COUNT).keys()].map(x => ({
        id: ++x,
        disabled: x % 3 === 0,
        disabledTooltip: 'tooltip'
      }));
    },
    items() {
      let items = this.ITEMS;
      this.filterValues.forEach(filterValue => {
        if (filterValue === 'remove_odd')
          items = items.filter(({ id }) => id % 2 === 0);
        else if (filterValue === 'remove_even')
          items = items.filter(({ id }) => id % 2 === 1);
      });
      return items;
    },
    rows() {
      const { per, page } = this.pagination;

      const indexStart = per * (page - 1);
      const indexEnd = per * page;

      return this.items.slice(indexStart, indexEnd);
    },
    resources() {
      return { ...this.pagination, total_count: this.items.length };
    }
  },
  watch: {
    filterValues() {
      this.pagination = { ...this.pagination, page: 1 };
    }
  }
};
</script>
