<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="widget.name"
    :is-loading="isBrandsLoading || isWidgetsLoading"
    :form-props="{
      ...formProps,
      submitLabel: $t('override_button_label')
    }"
    v-on="formEvents"
  >
    <input v-model="widget.id" name="dst_widget_id" type="hidden" />
    <!-- eslint-disable vue/no-v-html -->
    <AppInfoBox
      class="ReviewSettingsSettingOverrideDialog__info-box"
      v-html="infoBoxMessage"
    />
    <!-- eslint-enable vue/no-v-html -->
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import { mapState } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import WidgetStyle from '@/enums/WidgetStyle';
import WidgetType from '@/enums/WidgetType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';

export default {
  name: 'ReviewSettingsSettingOverrideDialog',
  mixins: [DialogFormView],
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isBrandsLoading: true,
      isWidgetsLoading: false,
      brands: [],
      widgets: []
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    infoBoxMessage() {
      return this.widget.widget_style != null
        ? this.$t('info_with_style_html', {
            type: WidgetType.t(this.widget.widget_type),
            device: WidgetDeviceType.t(this.widget.widget_device_type),
            style: WidgetStyle.t(this.widget.widget_style)
          })
        : this.$t('info_without_style_html', {
            type: WidgetType.t(this.widget.widget_type),
            device: WidgetDeviceType.t(this.widget.widget_device_type)
          });
    },
    overrideSuccessMessage() {
      return this.$t('complete_message', {
        source_brand_name: this.sourceBrandName,
        source_widget_id: this.sourceWidgetId,
        dst_widget_name: this.widget.name,
        dst_brand_name: this.currentBrand.name,
        dst_widget_id: this.widget.id
      });
    },
    sourceBrandId() {
      return this.formObject.source_brand_id;
    },
    sourceBrandName() {
      return this.brandOptions.find(
        option => option.value === this.sourceBrandId
      )?.label;
    },
    sourceWidgetId() {
      return this.formObject.source_widget_id;
    },
    brandOptions() {
      return this.brands.map(b => ({
        label: b.name,
        value: b.id
      }));
    },
    widgetOptions() {
      return this.widgets.map(({ id, name }) => ({
        label: this.$t('source_widget_id_with_prefix', { id, name }),
        value: id
      }));
    },
    groupOptions() {
      return {
        source_brand_id: {
          label: this.$t('source_brand_id_label'),
          placeholder: this.$t('source_brand_id_placeholder'),
          options: this.brandOptions,
          required: true,
          type: 'select_filterable'
        },
        source_widget_id: {
          label: this.$t('source_widget_id_label'),
          placeholder: this.$t('source_widget_id_placeholder'),
          options: this.widgetOptions,
          type: 'select_filterable',
          validate: [
            {
              rule: () => this.widgetOptions.length > 0,
              errorMessage: this.$t('no_available_widget_error_message')
            },
            'required'
          ]
        }
      };
    },
    groupIds() {
      const ids = ['source_brand_id'];
      if (this.sourceBrandId) ids.push('source_widget_id');
      return ids;
    },
    formSections() {
      return [
        {
          groups: this.groupIds.map(id => {
            return {
              id,
              ...this.groupOptions[id]
            };
          })
        }
      ];
    }
  },
  watch: {
    sourceBrandId() {
      if (this.sourceBrandId) this.createWidgetList();
    },
    widgetOptions() {
      if (this.widgetOptions.length < 1) {
        this.validateField('source_widget_id');
      }
    }
  },
  mounted() {
    this.orgFormObject = {
      dst_widget_id: this.widget.id,
      source_brand_id: this.sourceBrandId,
      source_widget_id: this.sourceWidgetId
    };
    api
      .get('/widget_setting_override/brands')
      .then(({ data }) => {
        this.brands = data.brands;
      })
      .finally(() => (this.isBrandsLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .request({
          data: formData,
          url: '/widget_setting_override/override',
          method: 'patch',
          successMessage: this.overrideSuccessMessage
        })
        .then(({ request }) => {
          if (request.status === 200) this.close(true);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    createWidgetList() {
      this.isWidgetsLoading = true;
      api
        .get('/widget_setting_override/widgets', {
          params: {
            source_brand_id: this.sourceBrandId,
            dst_widget_id: this.widget.id,
            widget_type: this.widget.widget_type,
            widget_device_type: this.widget.widget_device_type,
            widget_style: this.widget.widget_style
          }
        })
        .then(({ data }) => {
          this.widgets = data.widgets;
        })
        .finally(() => {
          this.isWidgetsLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.ReviewSettingsSettingOverrideDialog__info-box {
  margin-bottom: 32px;
}
</style>

<i18n locale="ko">
{
  "title": "위젯 설정 불러오기",
  "info_with_style_html": "디바이스 선택, 위젯 스타일, 위젯 이름 설정을 제외한 모든 설정값을 불러옵니다.<br /><b>{type}, {device}, {style} 스타일</b> 위젯 설정을 불러올 쇼핑몰과 위젯을 선택해주세요.",
  "info_without_style_html": "디바이스 선택, 위젯 스타일, 위젯 이름 설정을 제외한 모든 설정값을 불러옵니다.<br /><b>{type}, {device}</b> 위젯 설정을 불러올 쇼핑몰과 위젯을 선택해주세요.",
  "source_brand_id_label": "설정을 불러올 쇼핑몰 선택",
  "source_brand_id_placeholder": "쇼핑몰 선택",
  "source_widget_id_label": "설정을 불러올 위젯 선택",
  "source_widget_id_placeholder": "위젯 선택",
  "source_widget_id_with_prefix": "{name} (위젯 ID: {id})",
  "no_available_widget_error_message": "선택할 수 있는 위젯이 없습니다. 다른 쇼핑몰을 선택해주세요.",
  "override_button_label": "불러오기",
  "complete_message": "'{source_brand_name}, 위젯 ID {source_widget_id}'의 설정을 '{dst_widget_name}({dst_brand_name}, 위젯 ID {dst_widget_id}'에 복사해서 저장했습니다."
}
</i18n>
