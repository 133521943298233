<template>
  <AppDataSection :title="title" class="AnalyticsInsightSection">
    <AppButton
      v-if="buttonProps"
      class="AnalyticsInsightSection__button"
      type="link"
      :to="buttonProps.link"
      :label="buttonProps.label"
    />
    <div class="AnalyticsInsightSection__slot">
      <slot />
    </div>
  </AppDataSection>
</template>

<script>
export default {
  name: 'AnalyticsInsightSection',
  props: {
    title: { type: String, required: true },
    buttonProps: { type: Object, default: null }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightSection__button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.AnalyticsInsightSection__slot {
  margin-top: 24px;
}
</style>
