export const isUrlFormatValid = url => {
  return /^((http(s?)):\/\/)([0-9a-zA-Z가-힣-]+\.)+[a-zA-Z]{2,63}(\/\S*)?$/.test(
    url
  );
};

export const isDomainOnlyUrlFormatValid = url => {
  return /^((http(s?)):\/\/)([0-9a-zA-Z가-힣-]+\.)+[a-zA-Z]{2,63}\/?$/.test(
    url
  );
};
