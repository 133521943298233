<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{
      ...formProps,
      objectId: 'fit_transaction',
      detectFormDataChange: false,
      submitLabel: $t('app.edit'),
      submittingLabel: $t('app.editing')
    }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="fit_transaction[description]"
      :value="description"
    />
  </AppModalForm>
</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import FitTransactionType from '@/enums/FitTransactionType';
import DialogFormView from '@/mixins/DialogFormView';
export default {
  name: 'ThePaymentHistoryEditFitTransactionDialog',
  mixins: [DialogFormView],
  props: { transaction: { type: Object, required: true } },
  data() {
    return {
      orgFormObject: {
        fit_event_type: this.transaction.fit_event_type,
        description: this.transaction.description
      }
    };
  },
  computed: {
    formSections() {
      const transactionType = this.formObject.fit_event_type;
      return [
        {
          id: 'default',
          groups: [
            {
              id: 'fit_event_type',
              label: this.$t('fit_transaction.fit_event_type'),
              type: 'select',
              options: [FitTransactionType.RETURN, FitTransactionType.ETC].map(
                value => ({
                  label: this.transactionTypeLabel(value),
                  value
                })
              )
            },
            {
              id: 'description',
              name: null,
              label: this.$t('fit_transaction.description'),
              type: 'text',
              placeholder: this.transactionTypeLabel(transactionType),
              validate:
                transactionType === FitTransactionType.ETC ? ['required'] : []
            }
          ]
        }
      ];
    },
    description() {
      return this.formObject.description === ''
        ? this.transactionTypeLabel(this.formObject.fit_event_type)
        : this.formObject.description;
    }
  },
  methods: {
    ...mapMutations('session', ['SET_FIT_STATUS']),
    transactionTypeLabel(transactionType) {
      switch (transactionType) {
        case FitTransactionType.RETURN:
          return this.$t('fit_transaction_type_return');
        case FitTransactionType.ETC:
          return this.$t('fit_transaction_type_etc');
        default:
          return '';
      }
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/payment_history/fit_transactions/${this.transaction.id}`,
          formData,
          {
            successMessage: this.$t('app.saved')
          }
        )
        .then(({ data }) => {
          if (data.fit_status) this.SET_FIT_STATUS(data.fit_status);
          this.eventBus.$emit('update', data);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "이용내역 추가하기",
  "sub_title": "핏 이용내역",
  "fit_transaction_type_return": "서비스 결제 취소 내역 추가",
  "fit_transaction_type_etc": "기타 이용내역 추가",
  "fit_transaction": {
    "fit_event_type": "항목 선택",
    "description": "메시지 입력"
  }
}
</i18n>
