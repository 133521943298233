<template>
  <div class="DisplayProductDisplayControlTab">
    <DisplayProductDisplayName
      class="DisplayProductDisplayControlTab__title"
      @update-form-status="updateNameFormStatus"
    />
    <DisplayProductDisplayCategoryCorners
      v-if="isCategoryPage"
      class="DisplayProductDisplayControlTab__category-corners"
    />
    <AppTabs v-model="activeTab" :tabs="tabs" sticky />
    <Component
      :is="currentTabComponent"
      :key="activeTab"
      v-bind="currentTabProps"
      class="DisplayProductDisplayControlTab__content"
    />
    <div class="DisplayProductDisplayControlTab__foot">
      <DisplayProductDisplayPreviewSettingsButton />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DisplayProductDisplayControlTab',
  data() {
    return { activeTab: 'current_corner_snapshot' };
  },
  computed: {
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    ...mapGetters('displayProductDisplay', [
      'addedProductIds',
      'removedProductIds'
    ]),
    tabIds() {
      if (this.isCategoryPage)
        return [
          'current_corner_snapshot',
          'search_product',
          'pinned_products',
          'added_or_removed_products'
        ];
      else
        return [
          'current_corner_snapshot',
          'search_product',
          'pinned_products',
          'excluded_products'
        ];
    },
    tabs() {
      return this.tabIds.map(id => {
        const tab = { id, label: this.$t(`tab.${id}`) };
        if (id === 'added_or_removed_products') {
          const addedOrRemovedProductIdsCount =
            this.addedProductIds.length + this.removedProductIds.length;
          if (addedOrRemovedProductIdsCount > 0)
            tab.badge = {
              label: String(addedOrRemovedProductIdsCount),
              style: 'red'
            };
        }

        return tab;
      });
    },
    currentTabComponent() {
      switch (this.activeTab) {
        case 'current_corner_snapshot':
          return 'DisplayCurrentCornerSnapshot';
        case 'search_product':
        case 'pinned_products':
        case 'excluded_products':
        case 'added_or_removed_products':
          return 'DisplayProducts';
        default:
          return 'div';
      }
    },
    currentTabProps() {
      switch (this.activeTab) {
        case 'search_product':
          return { columnType: 'multi_select' };
        case 'pinned_products':
          return { columnType: 'multi_select', filterType: 'pinned' };
        case 'excluded_products':
          return { columnType: 'multi_select', filterType: 'excluded' };
        case 'added_or_removed_products':
          return {
            columnType: 'multi_select',
            filterType: 'added_or_removed'
          };
        default:
          return {};
      }
    }
  },
  methods: {
    updateNameFormStatus(value) {
      this.$emit('update-name-form-status', value);
    }
  }
};
</script>

<style scoped>
.DisplayProductDisplayControlTab {
  padding: 32px 30px 24px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.DisplayProductDisplayControlTab__title {
  margin-bottom: 24px;
}

.DisplayProductDisplayControlTab__category-corners {
  margin-bottom: 32px;
}

.DisplayProductDisplayControlTab__content {
  overflow-y: scroll;
  height: 100%;
}

.DisplayProductDisplayControlTab__foot {
  margin-top: auto;
  padding-top: 24px;
}
</style>

<i18n locale="ko">
{
  "tab": {
    "current_corner_snapshot": "현재 쇼핑몰 진열",
    "search_product": "상품 검색",
    "pinned_products": "진열 고정",
    "excluded_products": "진열 제외",
    "added_or_removed_products": "카테고리 추가 / 제외"
  }
}
</i18n>
