import { constructEnum } from '@/lib/enum';

export default constructEnum('TargetKpiRank', {
  NONE: -1,
  CHECK_NEEDED: 0,
  NOT_BAD: 10,
  NORMAL: 20,
  GOOD: 30,
  EXCELLENT: 40
});
