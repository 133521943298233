<template>
  <AppResourceTable
    table-id="campaign-stats-detail-table"
    v-bind="{ tableName, rows, columns, resources }"
    :enable-per="false"
    @paginate="paginate"
  />
</template>

<script>
export default {
  name: 'TargetCampaignStatsDetailTable',
  props: {
    campaign: { type: Object, required: true },
    isMassCampaign: { type: Boolean, required: true },
    reportItemType: {
      type: String,
      required: true,
      validator: v =>
        [
          'checked_users',
          'clicked_purchased_orders',
          'clicked_users',
          'purchased_orders',
          'visited_users',
          'clicked_visited_users',
          'reviewed_sub_orders',
          'clicked_reviewed_sub_orders',
          'joined_users'
        ].includes(v)
    },
    pagination: { type: Object, required: true },
    resources: { type: Object, required: true },
    tableName: { type: String, required: true }
  },
  computed: {
    columnIds() {
      switch (this.reportItemType) {
        case 'checked_users':
          return [
            'username',
            'name',
            'email_address',
            'phone',
            'total_checks_count',
            'checked_at'
          ];
        case 'visited_users':
          return [
            'username',
            'name',
            this.isMassCampaign ? 'sent_at' : 'checked_at',
            'first_visited_at',
            'first_visited_by'
          ];
        case 'clicked_visited_users':
          return [
            'username',
            'name',
            'clicked_at',
            'first_visited_at',
            'first_visited_by'
          ];
        case 'purchased_orders':
          return [
            'username',
            'name',
            'product_name',
            'purchase_price',
            this.isMassCampaign ? 'sent_at' : 'checked_at',
            'purchased_at'
          ];
        case 'clicked_purchased_orders':
          return [
            'username',
            'name',
            'product_name',
            'purchase_price',
            'clicked_at',
            'purchased_at'
          ];
        case 'reviewed_sub_orders':
          return [
            'username',
            'name',
            'product_name',
            'checked_at',
            'reviewed_at'
          ];
        case 'clicked_reviewed_sub_orders':
          return [
            'username',
            'name',
            'product_name',
            'clicked_at',
            'reviewed_at'
          ];
        case 'clicked_users':
          return ['username', 'name', 'total_clicks_count', 'clicked_at'];
        case 'joined_users':
          return ['username', 'name', 'checked_at', 'joined_at'];
        case 'clicked_joined_users':
          return ['username', 'name', 'clicked_at', 'joined_at'];
        default:
          return [];
      }
    },
    columnProps() {
      return {
        checked_at: { type: 'datetime', align: 'right' },
        clicked_at: { type: 'datetime', align: 'right' },
        first_visited_at: { type: 'date', align: 'right' },
        joined_at: { type: 'datetime', align: 'right' },
        product_name: { type: 'pre', align: 'left' },
        purchase_price: {
          type: 'number',
          unit: 'currency.krw',
          align: 'right'
        },
        purchased_at: { type: 'datetime', align: 'right' },
        reviewed_at: { type: 'datetime', align: 'right' },
        sent_at: { type: 'datetime', align: 'right' },
        total_checks_count: { type: 'number' },
        total_clicks_count: { type: 'number' }
      };
    },
    columns() {
      return this.columnIds.map(id => ({
        id,
        ...this.columnProps[id],
        label: this.campaignText(id)
      }));
    },
    rows() {
      return this.resources.items.map(item => {
        switch (this.reportItemType) {
          case 'visited_users':
          case 'clicked_visited_users':
            return {
              ...item,
              first_visited_by: this.$t(item.first_visited_by)
            };
          case 'purchased_orders':
          case 'clicked_purchased_orders':
            return {
              ...item,
              product_name: item.product_names.join('\n'),
              purchase_price: item.purchase_price.cents
            };
          default:
            return item;
        }
      });
    }
  },
  methods: {
    campaignText(key) {
      return this.$store.getters['targetCampaigns/campaignText'](
        this.campaign,
        `detail_table.${key}`
      );
    },
    paginate(pagination) {
      this.$emit('change-pagination', pagination);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .campaign-stats-detail-table__name {
    flex: 0 0 90px;
  }
  .campaign-stats-detail-table__username {
    flex: 0.3 0 128px;
  }

  .campaign-stats-detail-table__product-name {
    flex: 2 0 270px;
  }
  .campaign-stats-detail-table__purchase-price,
  .campaign-stats-detail-table__total-clicks-count {
    flex-basis: 100px;
  }

  .campaign-stats-detail-table__email-address {
    flex-basis: 180px;
  }

  .campaign-stats-detail-table__first-visited-by {
    flex-basis: 120px;
  }

  .campaign-stats-detail-table__phone {
    flex: 0 0 120px;
  }

  .campaign-stats-detail-table__total-checks-count {
    flex: 0.3 0 90px;
  }

  .campaign-stats-detail-table__checked-at,
  .campaign-stats-detail-table__purchased-at,
  .campaign-stats-detail-table__reviewed-at,
  .campaign-stats-detail-table__first-visited-at,
  .campaign-stats-detail-table__clicked-at,
  .campaign-stats-detail-table__joined-at,
  .campaign-stats-detail-table__sent-at {
    flex: 0.1 0 160px;
  }
}
</style>

<i18n locale="ko">
{
  "login": "로그인 방문",
  "logout": "로그아웃 방문"
}
</i18n>
