<template>
  <AppForm
    id="category_exclusion"
    v-bind="formProps"
    :submit-button="{ submitLabel: $t('app.save') }"
    v-on="formEvents"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';

export default {
  name: 'ReviewSettingsReviewOptionCategoryExclusion',
  mixins: [FormView],
  data() {
    return { productCategoriesWithDisabled: [] };
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoryOptions']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'info',
              label: '',
              type: 'infobox',
              value: this.$t('info')
            },
            {
              id: 'excluded_category_ids',
              label: this.$t('label'),
              placeholder: this.$t('placeholder'),
              type: 'multiple_select',
              options: this.productCategoriesWithDisabled,
              groupDescription: this.$t('desc')
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get('/product_category_product_types/excluded_categories')
      .then(({ data }) => {
        const inexcludableCategoryIdSet = new Set(
          data.inexcludable_category_ids
        );
        this.productCategoriesWithDisabled = this.productCategoryOptions.map(
          o => ({
            ...o,
            disabled: inexcludableCategoryIdSet.has(o.value)
          })
        );

        this.orgFormObject = {
          excluded_category_ids: data.excluded_category_ids
        };
      });
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          '/product_category_product_types/update_excluded_categories',
          formData,
          {
            successMessage: this.$t('app.saved')
          }
        )
        .then(() => (this.orgFormObject = this.formObject))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "info": "상품에 카테고리가 설정되어 있지 않은 경우, 추가 수집정보 설정이 적용되지 않습니다.",
  "desc": "추가 정보를 설정하지 않을 카테고리를 등록해주세요. 적용 카테고리 메뉴에서 설정한 카테고리는 제외할 수 없습니다.",
  "label": "추가 정보 제외 카테고리",
  "placeholder": "카테고리를 선택해주세요."
}
</i18n>
