<template>
  <AppImage class="AppProductIcon" :src="url || imageUrl" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppProductIcon',
  props: {
    url: { type: String, default: null },
    iconId: { type: Number, default: null }
  },
  computed: {
    ...mapGetters('productIcons', ['productIconImageUrl']),
    imageUrl() {
      return this.productIconImageUrl(this.iconId);
    }
  }
};
</script>

<style scoped>
.AppProductIcon {
  height: 16px;
  vertical-align: middle;
}
</style>
