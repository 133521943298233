<template>
  <div class="SummaryBoxLive">
    <AppAjaxContent :is-loading="isLoading">
      <AppSummaryBox>
        <template #title>
          {{ $t('title') }}
        </template>
        <div>{{ $t('content') }}</div>
        <template #buttons>
          <AppButton
            :label="$t('button_label')"
            button-style="blue"
            @click="openLiveDialog"
          />
        </template>
      </AppSummaryBox>
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import { mapActions } from 'vuex';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'SummaryBoxLive',
  data: () => ({ isLoading: false }),
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('session', ['fetchSession', 'redirectToHome']),
    openLiveDialog() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'check_confirm',
          closeButtonLabel: this.$t('modal.button_label'),
          title: this.$t('modal.title'),
          markdownText: this.$t('modal.content'),
          checkboxLabel: this.$t('modal.checkbox_label'),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', this.updateReviewStatusToLive);
      });
    },
    updateReviewStatusToLive() {
      this.isLoading = true;
      api
        .post('/easy_join/launches')
        .then(() =>
          this.fetchSession()
            .then(() => this.redirectToHome())
            .finally(() => (this.isLoading = false))
        )
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "모든 준비가 완료되었습니다. 🥳️",
  "content": "아래 [사용 시작] 버튼을 눌러 크리마 리뷰를 쇼핑몰에 적용해보세요!",
  "button_label": "사용 시작",
  "checkbox_label": "주의 사항 확인 후 사용 시작합니다.",
  "modal": {
    "title": "사용 시작 하시겠습니까?",
    "content": "사용 시작시, 결제 비용이 차감되어 이전 단계로 돌아갈 수 없습니다. <br />사용 시작 하시겠습니까?",
    "button_label": "사용 시작",
    "checkbox_label": "주의 사항 확인 후 사용 시작합니다."
  }
}
</i18n>
