<template>
  <div class="AppSelectButton">
    <input v-if="name" type="hidden" :name="name" :value="currentValue" />
    <AppButtonToggleGroup :buttons="buttons" @select="change($event)" />
  </div>
</template>

<script>
import SingleSelectable from '@/mixins/SingleSelectable';

export default {
  name: 'AppSelectButton',
  mixins: [SingleSelectable],
  props: {
    name: {
      type: String,
      default: null
    },
    unselectable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: [String, Object],
      default: null
    }
  },
  computed: {
    buttons() {
      return this.options.map(option => ({
        id: option.value,
        value: option.value === this.currentValue,
        disabled: this.disabled || option.disabled,
        label: option.label,
        tooltip:
          'tooltip' in option
            ? option.tooltip
            : option.disabled
            ? this.tooltip
            : null
      }));
    }
  },
  methods: {
    change(value) {
      const newValue =
        this.unselectable && this.currentValue === value ? null : value;
      this.setCurrentValue(newValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_breakpoints.scss';

.AppSelectButton {
  white-space: nowrap;

  @include media-breakpoint-each(mobile) {
    white-space: normal;
  }
}
</style>
