<template>
  <TargetReportsDispatchResultDialogTable
    table-id="dispatch-result-items-sms-table"
    v-bind="{
      isLoading,
      cohortItems,
      columns,
      itemStatusOptions,
      dispatchMethod
    }"
    :item-status="resourceParams.item_status"
    @change-item-status="changeItemStatus"
    @paginate="paginate"
  />
</template>

<script>
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import TargetDispatchMethod from '@/enums/TargetDispatchMethod';
import TargetCohortItemStatus from '@/enums/TargetCohortItemStatus';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import TargetReportsDispatchResultDialogTable from './TargetReportsDispatchResultDialogTable';

export default {
  name: 'TargetReportsDispatchResultDialogTableSms',
  components: { TargetReportsDispatchResultDialogTable },
  mixins: [ResourceViewNoRoute],
  props: { cohort: { type: Object, required: true } },
  data: () => ({ isLoading: true, cohortItems: nullResources }),
  computed: {
    dispatchMethod: () => TargetDispatchMethod.SMS,
    columns() {
      return [
        { id: 'id' },
        { id: 'date' },
        { id: 'from', label: this.$t('table.from') },
        { id: 'user' },
        { id: 'message' },
        { id: 'status' }
      ];
    },
    itemStatusOptions() {
      return TargetCohortItemStatus.values
        .filter(value => value !== TargetCohortItemStatus.DUPLICATE_MSG_ID)
        .map(value => ({
          value,
          label: TargetCohortItemStatus.translateForDispatchMethod(
            value,
            TargetDispatchMethod.SMS
          )
        }));
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/target/cohorts/${this.cohort.id}/items/sms`, { params })
        .then(({ data }) => (this.cohortItems = data.cohort_items))
        .finally(() => (this.isLoading = false));
    },
    changeItemStatus(status) {
      this.resourceParams.item_status = status;
      this.searchResource();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dispatch-result-items-sms-table__id {
    flex-basis: 90px;
  }

  .dispatch-result-items-sms-table__date {
    flex-basis: 200px;
  }

  .dispatch-result-items-sms-table__from {
    flex-basis: 100px;
  }

  .dispatch-result-items-sms-table__user {
    flex-basis: 150px;
  }

  .dispatch-result-items-sms-table__message {
    flex-basis: 224px;
  }

  .dispatch-result-items-sms-table__status {
    flex-basis: 150px;
  }
}
</style>

<i18n locale="ko">
{
  "table": {
    "from": "발신 번호"
  },
  "date": {
    "created_at": "요청일",
    "sent_at": "발송일",
    "first_checked_at": "최초확인일",
    "first_checked_ip": "최초확인IP",
    "last_checked_at": "최종확인일"
  }
}
</i18n>
