import { constructEnum } from '@/lib/enum';

const MileageActionType = constructEnum('MileageActionType', {
  CALCULATED: 10,
  ADMIN_CHANGED: 20,
  MANUAL: 30,
  CALCULATED_BATCH: 40,
  ADMIN_CHANGED_BATCH: 50,
  MANUAL_BATCH: 60
});

MileageActionType.ADMIN_ACTION = [
  MileageActionType.CALCULATED,
  MileageActionType.ADMIN_CHANGED,
  MileageActionType.MANUAL
];

MileageActionType.SYSTEM_ACTION = [
  MileageActionType.CALCULATED_BATCH,
  MileageActionType.ADMIN_CHANGED_BATCH,
  MileageActionType.MANUAL_BATCH
];

MileageActionType.isAdminAction = function(mileageActionType) {
  return MileageActionType.ADMIN_ACTION.includes(mileageActionType);
};

export default MileageActionType;
