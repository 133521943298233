var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalDefault',{attrs:{"title":_vm.$t('title'),"width":"wide","sub-title":_vm.$t('sub_title', [_vm.campaignName, _vm.cohortId.toString()])},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"TargetCohortBrandCsvsDialog__brand"},[_c('span',{staticClass:"TargetCohortBrandCsvsDialog__brand-name"},[_vm._v(_vm._s(_vm.currentBrand.name)),(_vm.currentBrand.shop_builder)?[_vm._v("("+_vm._s(_vm.ShopBuilder.tk(_vm.currentBrand.shop_builder))+")")]:_vm._e()],2),_c('AppEditLink',{attrs:{"type":"external_link","label":_vm.$t('brand_settings'),"to":_vm.advancedSettingsUrl}})],1),_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppSmallDeviceScrollable',[_c('AppTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [(column.id === 'created_at')?[_c('div',[_vm._v(" "+_vm._s(_vm.$t('created_at'))+": "+_vm._s(_vm.formatDateTime(row.created_at))+" ")]),(
                  row.brand_csv_status === _vm.BrandCsvStatus.EXPORT_SCHEDULED
                )?_c('div',[_vm._v(" "+_vm._s(_vm.$t('export_scheduled_at'))+": "+_vm._s(row.export_scheduled_at ? _vm.formatDateTime(row.export_scheduled_at) : 'X')+" ")]):_vm._e(),(row.exported_at)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('exported_at'))+": "+_vm._s(_vm.formatDateTime(row.exported_at))+" ")]):_vm._e(),(row.imported_at)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('imported_at'))+": "+_vm._s(_vm.formatDateTime(row.imported_at))+" ")]):_vm._e()]:(column.id === 'brand_csv_type')?[_c('div',[_c('span',[_vm._v(_vm._s(_vm.BrandCsvType.t(row.brand_csv_type)))]),_c('span',[_vm._v("("+_vm._s(_vm.$t('unit.count', [row.rows_count]))+")")])])]:(column.id === 'period')?[(!row.start_date || !row.end_date)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('app.entire_period'))+" ")]):(row.start_date === row.end_date)?_c('span',[_vm._v(" "+_vm._s(row.start_date)+" ")]):_c('div',[_vm._v(_vm._s(row.start_date)+" ~ "+_vm._s(row.end_date))]),_c('span',[_vm._v(" ("+_vm._s(_vm.filterOrSortText(row))+") "),(row.infoText)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    message: row.infoText,
                    maxWidth: '500px',
                    wordBreak: 'break-all'
                  }),expression:"{\n                    message: row.infoText,\n                    maxWidth: '500px',\n                    wordBreak: 'break-all'\n                  }"}]},[_c('AppSvgIcon',{staticClass:"TargetCohortBrandCsvsDialog__info-icon",attrs:{"name":"icon-info-tooltip"}})],1):_vm._e()])]:(column.id === 'file')?[(row.download_url)?_c('AppButtonDownload',{attrs:{"url":row.download_url}}):_vm._e()]:(column.id === 'is_automatic')?[_c('div',[_vm._v(" "+_vm._s(_vm.$t( ("is_automatic." + (row.is_automatic ? 'automatic' : 'manual')) ))+" ")])]:(column.id === 'actions')?[_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"menu-items":_vm.manageButtonMenuItems(row)}})]:_vm._e()]}}])})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }