<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Normal</label>
      </div>
      <AppMultipleSelect
        id="normal"
        v-model="valueNormal"
        :options="OPTIONS_NORMAL"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueNormal }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppMultipleSelect
        id="disabled"
        v-model="valueDisabled"
        disabled
        :options="OPTIONS_NORMAL"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueDisabled }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppMultipleSelect
        id="invalid"
        v-model="valueInvalid"
        invalid
        :options="OPTIONS_NORMAL"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Checkable</label>
      </div>
      <AppMultipleSelect
        id="checkable"
        v-model="valueCheckable"
        :options="OPTIONS_CHECKABLE"
        @check="onCheck"
        @remove="onRemove"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueCheckable }}
      </div>
      <div class="ui-section__description">
        Checked Selected Value: {{ valueCheckableChecked }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="placeholder">Placeholder</label>
      </div>
      <AppMultipleSelect
        id="placeholder"
        v-model="valuePlaceholder"
        :options="OPTIONS_NORMAL"
        placeholder="Select heroes"
      />
      <div class="ui-section__description">
        Selected Value: {{ valuePlaceholder }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="string">String Value</label>
      </div>
      <AppMultipleSelect
        id="string"
        v-model="valueString"
        :options="OPTIONS_STRING"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueString }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="stringify">Stringify</label>
      </div>
      <AppMultipleSelect
        id="stringify"
        v-model="valueStringify"
        :options="OPTIONS_NORMAL"
        packing-method="string"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueStringify }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="bit-flag">Bit Flag</label>
      </div>
      <AppMultipleSelect
        id="bit-flag"
        v-model="valueBitFlag"
        :options="OPTIONS_BIT_FLAG"
        packing-method="bit_flag"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueBitFlag }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="duplicateNames">Duplicate Names</label>
      </div>
      <AppMultipleSelect
        id="duplicateNames"
        v-model="valueDuplicateNames"
        :options="OPTIONS_DUPLICATE_NAMES"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueDuplicateNames }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="tooltip">Tooltip</label>
      </div>
      <AppMultipleSelect
        id="tooltip"
        v-model="valueTooltip"
        :options="OPTIONS_TOOLTIP"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueTooltip }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabledSelection">Disabled Selection</label>
      </div>
      <AppMultipleSelect
        id="disabled-selection"
        v-model="valueDisabledSelection"
        :options="OPTIONS_DISABLED_SELECTION"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueDisabledSelection }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabledSelection">With Priority</label>
      </div>
      <AppMultipleSelect
        id="disabled-selection"
        v-model="valueWithPriority"
        :options="optionsWithPriority"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueWithPriority }}
      </div>
    </div>
    <div class="ui-section__title">
      <label for="disabledSelection">With Priority No Border</label>
    </div>
    <AppMultipleSelect
      id="disabled-selection"
      v-model="valueWithPriorityNoBorder"
      :options="optionsWithPriorityNoBorder"
    />
    <div class="ui-section__description">
      Selected Value: {{ valueWithPriority }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiMultipleSelect',
  data() {
    return {
      OPTIONS_NORMAL: [
        { value: 1, label: 'Ant-Man' },
        { value: 2, label: 'Black Panther' },
        { value: 3, label: '블랙 위도우' },
        { value: 4, label: '캡틴 아메리카' },
        { value: 5, label: 'Captain Marvel' },
        { value: 6, label: 'Doctor Strange' },
        { value: 8, label: 'Falcon', disabled: true },
        {
          value: 9,
          label:
            'Guardians of the Galaxy - Drax the Destroyer, Gamora, Groot, Star-Lord, Rocket'
        },
        { value: 11, label: '호크아이' },
        { value: 12, label: '헐크' },
        { value: 13, label: '아이언맨' },
        { value: 16, label: 'Scarlet Witch' },
        { value: 17, label: 'Spider-Man' },
        { value: 19, label: '토르' },
        { value: 20, label: 'Vision' },
        { value: 21, label: 'War Machine' },
        { value: 22, label: 'Wasp' },
        { value: 23, label: 'Winter Soldier' }
      ],
      OPTIONS_STRING: [
        { value: 'Ant-Man', label: 'Ant-Man' },
        { value: 'Black Panther', label: 'Black Panther' },
        { value: '블랙 위도우', label: '블랙 위도우' },
        { value: '캡틴 아메리카', label: '캡틴 아메리카' },
        { value: 'Captain Marvel', label: 'Captain Marvel' },
        { value: 'Doctor Strange', label: 'Doctor Strange' },
        { value: 'Falcon', label: 'Falcon', disabled: true },
        {
          value:
            'Guardians of the Galaxy - Drax the Destroyer, Gamora, Groot, Star-Lord, Rocket',
          label:
            'Guardians of the Galaxy - Drax the Destroyer, Gamora, Groot, Star-Lord, Rocket'
        },
        { value: '호크아이', label: '호크아이' },
        { value: '헐크', label: '헐크' },
        { value: '아이언맨', label: '아이언맨' },
        { value: 'Scarlet Witch', label: 'Scarlet Witch' },
        { value: 'Spider-Man', label: 'Spider-Man' },
        { value: '토르', label: '토르' },
        { value: 'Vision', label: 'Vision' },
        { value: 'War Machine', label: 'War Machine' },
        { value: 'Wasp', label: 'Wasp' },
        { value: 'Winter Soldier', label: 'Winter Soldier' }
      ],
      OPTIONS_DUPLICATE_NAMES: [
        { value: 1, label: 'Ant-Man' },
        { value: 2, label: 'Black Panther' },
        { value: 3, label: 'Ant-Man' },
        { value: 4, label: 'Black Panther' },
        { value: 5, label: 'Ant-Man' },
        { value: 6, label: 'Black Panther' },
        { value: 7, label: 'Ant-Man' },
        { value: 8, label: 'Black Panther' },
        { value: 9, label: 'Ant-Man' },
        { value: 10, label: 'Black Panther' }
      ],
      OPTIONS_BIT_FLAG: [
        { value: 1, label: 'Ant-Man' },
        { value: 2, label: 'Black Panther' },
        { value: 4, label: '캡틴 아메리카' },
        { value: 8, label: 'Falcon', disabled: true },
        { value: 16, label: 'Scarlet Witch' }
      ],
      OPTIONS_TOOLTIP: [
        { value: 1, label: 'Ant-Man', tooltip: 'Director: Peyton Reed' },
        {
          value: 2,
          label: 'Black Panther',
          tooltip: { message: 'Director: Ryan Coogler' }
        },
        { value: 3, label: '블랙 위도우', tooltip: '감독: Cate Shortland' },
        {
          value: 4,
          label: '캡틴 아메리카',
          tooltip: { message: '감독: Joe Johnston' }
        }
      ],
      OPTIONS_DISABLED_SELECTION: [
        { value: 1, label: 'Ant-Man', tooltip: 'Director: Peyton Reed' },
        {
          value: 2,
          label: 'Black Panther',
          tooltip: { message: 'Director: Ryan Coogler' }
        },
        { value: 3, label: '블랙 위도우', tooltip: '감독: Cate Shortland' },
        {
          value: 4,
          label: '캡틴 아메리카',
          disabled: true,
          tooltip: { message: '감독: Joe Johnston' }
        }
      ],
      valueNormal: [4, 13],
      valueDisabled: [4, 13],
      valueInvalid: [4, 13],
      valueCheckable: [4, 13],
      valueCheckableChecked: [4],
      valuePlaceholder: [],
      valueString: ['캡틴 아메리카', '아이언맨'],
      valueStringify: '4,13',
      valueBitFlag: 10,
      valueDuplicateNames: [1, 2, 3, 4],
      valueTooltip: [2, 3],
      valueDisabledSelection: [1, 4],
      optionsWithPriority: [
        { value: 1, label: 'Ant-Man', priority: 'primary' },
        {
          value: 4,
          label: '캡틴 아메리카',
          priority: 'primary'
        },
        {
          value: 2,
          label: 'Black Panther'
        },
        {
          value: 8,
          label: '블랙 위도우',
          priority: 'primary'
        },
        {
          value: 32,
          label: 'info',
          priority: 'info'
        },
        {
          value: 16,
          label: '민간인',
          priority: 'primary',
          disabled: true
        }
      ],
      valueWithPriority: [],
      optionsWithPriorityNoBorder: [
        { value: 1, label: 'no border if only info', priority: 'info' },
        {
          value: 2,
          label: 'Black Panther'
        }
      ],
      valueWithPriorityNoBorder: []
    };
  },
  computed: {
    OPTIONS_CHECKABLE() {
      return this.OPTIONS_NORMAL.map(option => ({
        ...option,
        checked: this.valueCheckableChecked.includes(option.value)
      }));
    }
  },
  methods: {
    onCheck(option) {
      const id = option.value;
      const checkedIds = this.valueCheckableChecked;

      // toggle presence
      if (checkedIds.includes(id)) {
        checkedIds.splice(checkedIds.indexOf(id), 1);
      } else {
        checkedIds.push(id);
      }
    },
    onRemove(option) {
      const id = option.value;
      const checkedIds = this.valueCheckableChecked;

      if (checkedIds.includes(id)) {
        checkedIds.splice(checkedIds.indexOf(id), 1);
      }
    }
  }
};
</script>
