var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('menu.fit_display_names'),"sub-title":_vm.subTitle,"form-props":Object.assign({}, _vm.formProps,
    {id: 'fit_display_name',
    objectId: 'fit_option_key',
    focusGroupId: 'display_name'})},scopedSlots:_vm._u([{key:"group",fn:function(ref){
  var id = ref.id;
  var errors = ref.errors;
return [(id === 'option_values')?_c('div',{staticClass:"AppForm__group-field AppForm__group-field--mt12"},[_c('AppTable',{attrs:{"columns":[
          { id: 'name', label: _vm.$t('option_name') },
          {
            id: 'display_name',
            label: _vm.$t('fit_display_name.display_name')
          }
        ],"rows":_vm.fitOptionKey.fit_option_values,"no-data":_vm.$t('no_option_value')},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
        var row = ref.row;
        var column = ref.column;
        var rowIndex = ref.rowIndex;
return [(column.id === 'display_name')?[_c('input',{attrs:{"type":"hidden","name":"fit_option_key[fit_option_values][][id]"},domProps:{"value":row.id}}),_c('AppTextarea',{attrs:{"rows":1,"maxlength":255,"name":"fit_option_key[fit_option_values][][display_name]","invalid":!!errors[("option_values[" + (row.id) + "][display_name]")]},on:{"blur":function($event){return _vm.validateField(("option_values[" + (row.id) + "][display_name]"))},"change":function($event){return _vm.validateField(("option_values[" + (row.id) + "][display_name]"))}},model:{value:(_vm.fitOptionKey.fit_option_values[rowIndex].display_name),callback:function ($$v) {_vm.$set(_vm.fitOptionKey.fit_option_values[rowIndex], "display_name", $$v)},expression:"fitOptionKey.fit_option_values[rowIndex].display_name"}}),_c('AppFormError',{attrs:{"error":errors[("option_values[" + (row.id) + "][display_name]")]}})]:_vm._e()]}}],null,true)})],1):_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }