<template>
  <div
    :class="[
      'AppCodeEditor',
      { 'AppCodeEditor--disabled': disabled, 'AppCodeEditor--invalid': invalid }
    ]"
  >
    <AppCodeMirror
      v-bind="{ id, name, value, disabled, options }"
      @change="change"
    />
    <div v-if="placeholder && !value" class="AppCodeEditor__placeholder">
      {{ placeholder }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TextInput from '@/mixins/TextInput';

import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';

import 'codemirror/addon/comment/comment.js';
import 'codemirror/addon/selection/active-line.js';

export default {
  name: 'AppCodeEditor',
  mixins: [TextInput],
  model: { event: 'change' },
  props: {
    lang: {
      type: String,
      required: true,
      validator: v => ['javascript', 'css', 'htmlmixed'].includes(v)
    }
  },
  computed: {
    ...mapGetters(['isWindows']),
    options() {
      const extraKeys = {
        Tab: cm => {
          const spaces = Array(cm.getOption('indentUnit') + 1).join(' ');
          cm.replaceSelection(spaces);
        }
      };

      const toggleCommentKey = this.isWindows ? 'Ctrl-/' : 'Cmd-/';
      extraKeys[toggleCommentKey] = cm => cm.execCommand('toggleComment');

      return {
        mode: this.lang,
        lineWrapping: true,
        lineNumbers: true,
        extraKeys,
        styleActiveLine: true
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppCodeEditor {
  position: relative;
  @include input-base;

  &--disabled {
    pointer-events: all;

    ::v-deep .CodeMirror {
      opacity: 0.7;
    }
  }
}

.AppCodeEditor__placeholder {
  position: absolute;
  top: 4px;
  left: 34px;
  @include text-placeholder;
}

::v-deep {
  .CodeMirror-activeline-gutter .CodeMirror-linenumber {
    background: $color-grey-25;
  }

  .CodeMirror-activeline-background {
    display: none;
  }
}
</style>
