<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar>
      <template #default>
        <li v-if="isSuperAdminAccessible" class="super-admin__item">
          <AppButton
            :label="$t('add_bonus_transaction')"
            button-style="red"
            @click="clickAddBonusTransaction"
          />
        </li>
        <li v-if="isSuperAdminAccessible" class="super-admin__item">
          <AppButton
            :label="$t('add_fit_transaction')"
            @click="clickAddTransaction"
          />
        </li>
      </template>
      <template #right>
        <li>
          <AppSelect
            v-model="resourceParams.fit_event_type"
            :options="FitTransactionType.options()"
            :placeholder="$t('transaction_type_placeholder')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppDateRangePicker
            v-model="resourceParams"
            @update="searchResource"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppResourceTable
      table-id="fit-transactions-table"
      :resources="transactions"
      :rows="rows"
      :columns="columns"
      :enable-total-count="false"
      :no-data="$t('no_data')"
      @paginate="paginate"
    >
      <template #cell="{ row, column }">
        <template v-if="column === 'transaction_type'">
          <AppBadge
            :label="FitTransactionType.t(row.fit_event_type)"
            :badge-style="getBadgeStyle(row.fit_event_type)"
          />
        </template>
        <template v-else-if="column === 'actions'">
          <div class="super-admin__item">
            <AppButton
              v-if="isEditAndDeleteButtonsVisible(row)"
              :label="$t('app.edit')"
              @click="clickEditTransaction(row)"
            />
            <AppButton
              v-if="isEditAndDeleteButtonsVisible(row)"
              :label="$t('app.delete')"
              button-style="red-outline"
              @click="clickDeleteTransaction(row)"
            />
          </div>
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import FitTransactionType from '@/enums/FitTransactionType';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'ThePaymentHistoryDialogFit',
  mixins: [ResourceViewNoRoute],
  data() {
    return { isLoading: true, transactions: nullResources };
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    FitTransactionType() {
      return FitTransactionType;
    },
    columns() {
      return [
        { id: 'date', label: this.$t('app.date'), type: 'datetime' },
        {
          id: 'transaction_type',
          label: this.$t('table.transaction_type')
        },
        {
          id: 'description',
          label: this.$t('app.description'),
          align: 'left',
          type: 'html'
        },
        { id: 'actions', label: this.$t('app.actions'), superAdmin: true }
      ];
    },
    rows() {
      return this.transactions.items.map(item => ({
        ...item,
        date: item.created_at
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/payment_history/fit_transactions', { params })
        .then(({ data }) => (this.transactions = data.fit_transactions))
        .finally(() => (this.isLoading = false));
    },
    getBadgeStyle(transactionType) {
      switch (transactionType) {
        case FitTransactionType.PAYMENT:
        case FitTransactionType.FEE_PAYMENT:
          return 'blue';
        case FitTransactionType.PERIOD_BONUS:
          return 'blue-outline';
        case FitTransactionType.RETURN:
        case FitTransactionType.FEE_RETURN:
          return 'red';
        case FitTransactionType.EXPIRED:
          return 'red-outline';
        default:
          return 'default';
      }
    },
    isEditAndDeleteButtonsVisible({ fit_event_type, manually_created }) {
      return (
        [
          FitTransactionType.PAYMENT,
          FitTransactionType.RETURN,
          FitTransactionType.ETC
        ].includes(fit_event_type) && manually_created
      );
    },
    clickAddBonusTransaction() {
      this.openDialog('ThePaymentHistoryBonusFitDialog').then(eventBus => {
        this.orgResourceParams = this.resourceParams = this.defaultResourceParams;
        eventBus.$on('update', this.refreshResource);
      });
    },
    clickAddTransaction() {
      this.openDialog('ThePaymentHistoryAddFitTransactionDialog').then(
        eventBus => {
          this.orgResourceParams = this.resourceParams = this.defaultResourceParams;
          eventBus.$on('update', this.refreshResource);
        }
      );
    },
    clickEditTransaction(row) {
      this.openDialog([
        'ThePaymentHistoryEditFitTransactionDialog',
        { transaction: row }
      ]).then(eventBus => {
        this.orgResourceParams = this.resourceParams = this.defaultResourceParams;
        eventBus.$on('update', this.refreshResource);
      });
    },
    clickDeleteTransaction({ id, description }) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      api
        .delete(`/payment_history/fit_transactions/${id}`, {
          params: { description },
          successMessage: this.$t('app.deleted')
        })
        .then(this.refreshResource);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .fit-transactions-table__date {
    flex: 0 0 156px;
  }

  .fit-transactions-table__transaction-type {
    flex: 0 0 180px;
  }

  .fit-transactions-table__actions {
    flex: 0 0 132px;
  }
}
</style>

<i18n locale="ko">
{
  "table": {
    "transaction_type": "유형",
    "balance_count": "잔여건수",
    "paid_amount": "차감금액",
    "balance_amount": "잔여금액"
  },
  "add_bonus_transaction": "충전하기",
  "add_fit_transaction": "이용내역 추가하기",
  "transaction_type_placeholder": "전체 내역",
  "no_data": "핏 이용내역이 없습니다."
}
</i18n>
