<template>
  <div class="AppNewPasswordInput">
    <AppPasswordInput
      v-model="value.password"
      :name="name"
      :placeholder="$t('new_password_placeholder')"
      :invalid="!!passwordError"
      :input-size="inputSize"
      autocomplete="new-password"
      @blur="$emit('validate', 'password')"
      @change="$emit('validate', 'password')"
    />
    <AppFormError :error="passwordError" />
    <AppPasswordInput
      v-model="value.password_confirmation"
      :name="name.replace('password', 'password_confirmation')"
      class="AppNewPasswordInput__password-confirmation"
      :placeholder="$t('new_password_confirmation_placeholder')"
      :invalid="!!passwordConfirmationError"
      :input-size="inputSize"
      autocomplete="new-password"
      @blur="$emit('validate', 'password_confirmation')"
      @change="$emit('validate', 'password_confirmation')"
    />
    <AppFormError :error="passwordConfirmationError" />
  </div>
</template>

<script>
export default {
  name: 'AppNewPasswordInput',
  model: { event: 'change' },
  props: {
    name: { type: String, required: true },
    passwordError: { type: Object, default: null },
    passwordConfirmationError: { type: Object, default: null },
    inputSize: { type: String, default: null },
    value: {
      type: Object,
      default: () => ({ password: '', password_confirmation: '' })
    }
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.$emit('change', this.value);
      }
    }
  }
};
</script>

<style scoped>
.AppNewPasswordInput__password-confirmation {
  margin-top: 8px;
}
</style>

<i18n locale="ko">
{
  "new_password_placeholder": "비밀번호를 입력해주세요.",
  "new_password_confirmation_placeholder": "비밀번호를 한 번 더 입력해주세요."
}
</i18n>
