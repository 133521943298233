<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="javascript">JavaScript</label>
      </div>
      <AppCodeEditor
        id="javascript"
        v-model="valueJavaScript"
        lang="javascript"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueJavaScript }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="css">CSS</label>
      </div>
      <AppCodeEditor id="css" v-model="valueCss" lang="css" />
      <div class="ui-section__description">Selected Value: {{ valueCss }}</div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="htmlmixed">HTML Mixed</label>
      </div>
      <AppCodeEditor id="htmlmixed" v-model="valueHtmlMixed" lang="htmlmixed" />
      <div class="ui-section__description">
        Selected Value: {{ valueHtmlMixed }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="long">Long Code</label>
      </div>
      <AppCodeEditor id="long" v-model="valueLong" lang="javascript" />
      <div class="ui-section__description">Selected Value: {{ valueLong }}</div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="placeholder">Placeholder</label>
      </div>
      <AppCodeEditor
        id="placeholder"
        v-model="valuePlaceholder"
        lang="javascript"
        placeholder="Code goes here.."
      />
      <div class="ui-section__description">
        Selected Value: {{ valuePlaceholder }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppCodeEditor
        id="disabled"
        v-model="valueDisabled"
        :disabled="isDisabled"
        lang="javascript"
      />
      <AppCheckbox v-model="isDisabled" label="Disabled" class="mt4" />
      <div class="ui-section__description">
        Selected Value: {{ valueDisabled }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppCodeEditor
        id="invalid"
        v-model="valueInvalid"
        invalid
        lang="javascript"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiCodeEditor',
  data() {
    return {
      valueJavaScript: `
function foo() {
  alert('김덕일이 이거 쓰면 한글 잘된다고 했다. 🙈');
  alert('Deokil coerced me into using Codemirror. 🙈');
  alert('金德日说，如果您编写此文字，韩文会很好用。 🙈');
  alert('ギムドクイルがこれ使えば、ハングルよくなるした。 🙈');
  alert('Deokil กล่าวว่า อังกูล ทำงานได้ดีถ้าคุณเขียนสิ่งนี้ 🙈')
}`.trim(),
      valueCss: `
body {
  display: block;
}

/* foo는 폰트 사이즈가 10px이어야 한다. */
.foo {
  font-size: 10px;
}

#bar {
  background-color: #aa00ff;
}`.trim(),
      /* eslint-disable */
      valueHtmlMixed: `
<!doctype html>
<html lang="en-US">
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width" />
    <title>My test page</title>
    <style>
      h1 {color: blue;}
    </style>
  </head>
  <body>
    <!-- some comment -->
    <h1>Main title</h1>
    <script>console.log('Hello!')<\/script>
  </body>
</html>
`.trim(),
      /* eslint-enable */
      valueLong: `
// foo라는 메소드로 호출 시 console에 긴 메시지를 출력한다.
function foo() {
  console.log('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor eros in elit luctus aliquet. Fusce scelerisque pulvinar odio, mattis iaculis risus ultrices quis. Duis semper erat eu nisl eleifend posuere. Nunc felis velit, suscipit non quam commodo, facilisis commodo odio. Integer eu gravida ex, id lobortis lacus. Nam tempor vestibulum tristique. Vivamus faucibus eu justo in placerat. Donec et bibendum felis, nec porta erat.');
}`.trim(),
      valuePlaceholder: '',
      valueDisabled: `
function foo() {
  alert('disabled');
}`.trim(),
      valueInvalid: `
function foo() {
  alert('disabled');
}`.trim(),
      isDisabled: true
    };
  }
};
</script>
