<template>
  <AppResourceTable
    table-id="batch-jobs-table"
    :table-name="title || $t('local.upload_result')"
    :resources="batchJobs"
    :columns="columns"
    :rows="rows"
    @paginate="paginate"
  >
    <template #cell="{ column, row }">
      <template v-if="column === 'created_at'">
        <div class="table-line">
          {{ $t('batch_job.created_at') }}:
          {{ row.created_at | formatDateTime }}
        </div>
        <div v-if="row.finished_at" class="table-line">
          {{ $t('batch_job.finished_at') }}:
          {{ row.finished_at | formatDateTime }}
        </div>
      </template>
      <template v-else-if="column === 'status'">
        <AppBadge
          :tooltip="row.error_message"
          :badge-style="statusBadgeStyle(row)"
          :label="statusText(row)"
          :spinner="!row.finished_at"
        />
      </template>
      <template v-else-if="column === 'result'">
        <template v-if="row.finished_at">
          <div class="table-line">
            {{ $t('batch_job.success_count', [row.total_success_count]) }}
          </div>
          <div class="table-line">
            {{ $t('batch_job.failure_count', [row.total_failure_count]) }}
          </div>
        </template>
      </template>
      <template v-else-if="column === 'actions'">
        <AppButton v-if="row.downloadUrl" :label="$t('local.download_csv')">
          <a :href="row.downloadUrl">
            {{ $t('local.download_csv') }}
          </a>
        </AppButton>
        <AppButton
          v-if="row.finished_at"
          :label="$t('app.detail_breakdown')"
          @click="$emit('show', row.id)"
        />
      </template>
    </template>
  </AppResourceTable>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import BatchJobStatus from '@/enums/BatchJobStatus';
import ResourceView from '@/mixins/ResourceView';

export default {
  name: 'BatchJobTable',
  mixins: [ResourceView],
  props: {
    batchJobType: { type: Number, required: true },
    title: { type: String, default: null }
  },
  computed: {
    ...mapState('poll', ['latestData']),
    ...mapState('batchJob', ['batchJobs']),
    ...mapGetters(['pathWithBrandParams']),
    columns() {
      return [
        { id: 'id', label: 'ID' },
        { id: 'created_at', label: this.$t('batch_job.created_at') },
        { id: 'status', label: this.$t('app.status') },
        { id: 'result', label: this.$t('app.result') },
        { id: 'actions', label: this.$t('app.actions') }
      ];
    },
    rows() {
      return this.batchJobs.items.map(batchJob => ({
        ...batchJob,
        downloadUrl: this.pathWithBrandParams(
          `/api/batch_jobs/${batchJob.id}/download`
        )
      }));
    }
  },
  watch: {
    batchJobs: {
      deep: true,
      handler() {
        const value = this.batchJobs.items
          .filter(c => !c.finished_at)
          .map(c => c.id);
        this.SET_POLL_PARAMS({ key: 'batch_job_ids', value });
      }
    },
    latestData() {
      const { batch_jobs } = this.latestData;
      if (batch_jobs) this.UPDATE_BATCH_JOBS(batch_jobs);
    }
  },
  beforeDestroy() {
    this.UNSET_POLL_PARAMS('batch_job_ids');
  },
  methods: {
    ...mapMutations('poll', ['SET_POLL_PARAMS', 'UNSET_POLL_PARAMS']),
    ...mapMutations('batchJob', ['UPDATE_BATCH_JOBS']),
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('batchJob', ['fetchBatchJobs']),
    fetchResource(params) {
      this.fetchBatchJobs({ ...params, batch_job_type: this.batchJobType });
    },
    statusText(batchJob) {
      return BatchJobStatus.t(batchJob.batch_job_status);
    },
    statusBadgeStyle(batchJob) {
      switch (batchJob.batch_job_status) {
        case BatchJobStatus.COMPLETE:
          return 'mint-green';
        case BatchJobStatus.PARTIALLY_FAILED:
          return 'mango';
        case BatchJobStatus.FAILED:
        case BatchJobStatus.ERROR_INVALID_FILE:
        case BatchJobStatus.ERROR_SERVER:
          return 'red';
        default:
          return 'default';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .batch-jobs-table__id {
    flex: 12 0 60px;
  }

  .batch-jobs-table__created-at {
    flex: 22 0 220px;
  }

  .batch-jobs-table__status {
    flex: 22 0 100px;
  }

  .batch-jobs-table__result {
    flex: 18 0 80px;
  }

  .batch-jobs-table__actions {
    flex: 30 0 220px;
  }
}
</style>

<i18n locale="ko">
{
  "local": {
    "upload_result": "일괄 등록 처리 결과",
    "download_csv": "CSV 다운로드"
  }
}
</i18n>
