var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalFormLivePreview',_vm._g(_vm._b({attrs:{"title":_vm.$t('title'),"sub-title":_vm.isGroupTemplate ? _vm.$t('subtitle_group') : _vm.$t('subtitle_normal'),"form-props":Object.assign({}, _vm.formProps,
    {objectId: 'template',
    submitLabel: _vm.inspectDirectly ? _vm.$t('submit_and_inspect') : _vm.$t('app.save')}),"width":"560px"},on:{"change-group":_vm.changeGroup},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('BizMessageTemplatePreview',{attrs:{"template":_vm.formObject}})]},proxy:true},{key:"group",fn:function(ref){
  var id = ref.id;
  var maxlength = ref.maxlength;
  var placeholder = ref.placeholder;
  var error = ref.error;
  var disabled = ref.disabled;
return [(['header', 'subtitle'].includes(id))?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppTextInputWithLength',{attrs:{"name":("template[" + id + "]"),"maxlength":maxlength,"placeholder":placeholder,"invalid":!!error},on:{"change":function($event){return _vm.validateField(id)},"blur":function($event){return _vm.validateField(id)}},model:{value:(_vm.formObject[id]),callback:function ($$v) {_vm.$set(_vm.formObject, id, $$v)},expression:"formObject[id]"}})],1)]:(id === 'item_list')?[_c('div',{staticClass:"AppForm__group-field"},[_c('BizMessageTemplateItemList',{attrs:{"invalid":!!error},on:{"change":function($event){return _vm.validateField(id)}},model:{value:(_vm.formObject.item_list),callback:function ($$v) {_vm.$set(_vm.formObject, "item_list", $$v)},expression:"formObject.item_list"}})],1)]:(id === 'buttons')?[_c('div',{staticClass:"AppForm__group-field"},[_c('BizMessageTemplateButtons',{attrs:{"buttons":_vm.formObject.buttons,"disabled":disabled},on:{"add":_vm.clickAddButton,"sort":_vm.sortButtons,"edit":_vm.clickEditButton,"delete":_vm.clickDeleteButton}}),_vm._l((_vm.buttonFields),function(button,i){return _c('div',{key:i},_vm._l(([
              'id',
              'name',
              'button_type',
              'mobile_link',
              'pc_link',
              'android_link',
              'ios_link',
              'position',
              '_destroy'
            ]),function(key){return _c('input',{key:key,attrs:{"type":"hidden","name":'template[buttons_attributes][][' + key + ']'},domProps:{"value":button[key]}})}),0)})],2)]:_vm._e()]}},{key:"description",fn:function(ref){
            var id = ref.id;
return [(id === 'image')?[_c('div',{staticClass:"AppForm__description"},[_c('AppExternalLink',{staticClass:"AppForm__description-link",attrs:{"to":"https://kakaobusiness.gitbook.io/main/ad/bizmessage/notice-friend/content-guide/image"}},[_vm._v(" "+_vm._s(_vm.$t('template.image_description'))+" "),_c('AppExternalLinkIcon',{attrs:{"size":"small"}})],1)],1)]:_vm._e()]}},{key:"foot",fn:function(){return [_c('div',{staticClass:"BizMessageTemplateFormDialog__foot"},[_c('AppSvgIcon',{staticClass:"BizMessageTemplateFormDialog__foot-icon",attrs:{"name":"icon-info-tooltip"}}),_vm._v(" "+_vm._s(_vm.$t('foot'))+" ")],1)]},proxy:true}])},'AppModalFormLivePreview',{ isLoading: _vm.isLoading },false),_vm.formEvents),[(!_vm.shouldDisplayServiceType)?_c('input',{attrs:{"type":"hidden","name":"template[service_type]"},domProps:{"value":_vm.serviceTypes[0]}}):_vm._e(),(_vm.isServiceTypeReview)?_c('input',{attrs:{"type":"hidden","name":"template[category_code]"},domProps:{"value":_vm.formObject.category_code}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }