<template>
  <div class="DisplayProductsInfoCell">
    <div class="DisplayProductsInfoCell__image-wrapper">
      <img
        v-if="hasPreviewImage"
        class="DisplayProductsInfoCell__image"
        :src="productImageUrl"
        :alt="product.name"
      />
      <div
        v-else-if="product.image_source_url"
        v-tooltip="$t('no_image_tooltip')"
        class="DisplayProductsInfoCell__no-image"
      >
        <AppSpinner
          :class="[
            'DisplayProductsInfoCell__spinner',
            product.display ? 'DisplayProductsInfoCell__spinner--display' : null
          ]"
        />
      </div>
      <div
        v-if="!product.display"
        class="DisplayProductsInfoCell__image-overlay"
      >
        {{ $t('undisplayed') }}
      </div>
    </div>
    <div class="DisplayProductsInfoCell__product-description-wrapper">
      <div class="DisplayProductsInfoCell__product-title">
        {{ product.name }}
      </div>
      <div class="DisplayProductsInfoCell__product-info">
        {{ $t('code', [product.code]) }}
      </div>
      <div class="DisplayProductsInfoCell__product-info">
        {{
          $t('displayed_at', [
            product.displayed_at ? formatDate(product.displayed_at) : '-'
          ])
        }}
      </div>
      <div class="DisplayProductsInfoCell__product-info">
        {{ priceText }}
      </div>
      <div class="DisplayProductsInfoCell__product-info">
        {{
          $t('sub_orders_count', [
            formatCurrency(product.revenue),
            product.sub_orders_count
          ])
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DisplayProductsInfoCell',
  props: {
    product: { type: Object, required: true }
  },
  computed: {
    ...mapState('displayPreviewSettings', ['previewSettings']),
    priceText() {
      const { org_price, final_price, discount_percentage } = this.product;
      const { formatCurrency } = this;

      if (org_price === final_price)
        return this.$t('price', [formatCurrency(org_price)]);
      else if (discount_percentage)
        return this.$t('prices_with_discount_percentage', [
          formatCurrency(org_price),
          formatCurrency(final_price),
          discount_percentage
        ]);
      else
        return this.$t('prices', [
          formatCurrency(org_price),
          formatCurrency(final_price)
        ]);
    },
    hasPreviewImage() {
      return (
        (this.previewSettings.use_original_product_image &&
          this.product.image_source_url) ||
        this.product.image_width
      );
    },
    productImageUrl() {
      if (this.previewSettings.use_original_product_image)
        return this.product.image_source_url;
      else return this.product.image_url;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayProductsInfoCell {
  display: flex;
}

.DisplayProductsInfoCell__image-wrapper {
  width: 64px;
  height: 84px;
  font-size: 0;
  line-height: 0;
  position: relative;
}

.DisplayProductsInfoCell__image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid $color-grey-25;
}

.DisplayProductsInfoCell__no-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: $color-grey-25;
}

.DisplayProductsInfoCell__spinner {
  margin-top: -24px;
  font-size: 24px;

  &--display {
    margin-top: 0;
  }
}

.DisplayProductsInfoCell__image-overlay {
  @include text-caption-darker;
  background-color: white;
  opacity: 0.7;
  width: 100%;
  position: absolute;
  top: 60px;
  text-align: center;
  padding: 3px 0px;
}

.DisplayProductsInfoCell__product-description-wrapper {
  text-align: left;
  margin-left: 11px;
}

.DisplayProductsInfoCell__product-title {
  color: $color-grey-70;
  font-size: 13px;
  line-height: 1.54;
  margin-left: 0;
}

.DisplayProductsInfoCell__product-info {
  @include text-caption-small;
}
</style>

<i18n locale="ko">
{
  "code": "상품번호: {0}",
  "displayed_at": "노출일: {0}",
  "price": "상품 가격: {0}",
  "prices": "상품 가격: {0}, 할인가: {1}",
  "prices_with_discount_percentage": "상품 가격: {0}, 할인가: {1} ({2}% 할인)",
  "sub_orders_count": "누적판매: {0}({1}건)",
  "undisplayed": "미노출",
  "no_image_tooltip": "상품 사진을 불러오는 중입니다."
}
</i18n>
