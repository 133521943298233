<template>
  <AppModalForm
    :title="$t('title_move')"
    :sub-title="$t('subtitle', [selectedCount])"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId: 'option_value'
    }"
    v-on="formEvents"
  >
    <template #group="group">
      <template v-if="group.id === 'move'">
        <AppSelectRadio v-model="formObject.move_position" :options="options">
          <template #label="{ value }">
            <div v-if="value === 3">
              <i18n path="target_to_move">
                <AppSelectFilterable
                  id="select_target"
                  slot="target_option_value"
                  v-model="formObject.target_option_value"
                  :placeholder="$t('placeholder_select_target')"
                  :options="availableOptionValues"
                />
                <AppSelect
                  id="move_direction"
                  slot="move_type"
                  v-model="formObject.move_direction"
                  :options="directions"
                />
              </i18n>
            </div>
          </template>
        </AppSelectRadio>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';

const MOVE_TO_TOP = 1;
const MOVE_TO_BOTTOM = 2;
const MOVE_TO_POSTION = 3;
const MOVE_UP = 0;
const MOVE_DOWN = 1;

export default {
  name: 'ProductOptionValueMoveDialog',
  mixins: [DialogFormView],
  props: {
    optionValues: {
      type: Array,
      required: true
    },
    selectedCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      options: [
        { label: this.$t('move_to_top'), value: MOVE_TO_TOP },
        { label: this.$t('move_to_bottom'), value: MOVE_TO_BOTTOM },
        { label: '', value: MOVE_TO_POSTION }
      ],
      directions: [
        { label: this.$t('above'), value: MOVE_UP },
        { label: this.$t('below'), value: MOVE_DOWN }
      ],
      isLoading: true,
      formObject: {
        move_position: null,
        target_option_value: null,
        move_direction: MOVE_UP
      }
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'move',
              type: 'select_radio',
              label: this.$t('group_title'),
              options: this.options,
              required: true
            }
          ]
        }
      ];
    },
    availableOptionValues() {
      return this.optionValues.map(o => ({ label: o.value, value: o.id }));
    }
  },
  mounted() {
    this.isLoading = false;
  },
  methods: {
    submit() {
      if (
        this.formObject.move_position === MOVE_TO_POSTION &&
        !this.formObject.target_option_value
      ) {
        return false;
      }

      if (this.optionValues.length > 0) {
        this.eventBus.$emit('move', this.moveEvent());
      }

      this.close(true);
    },
    moveEvent() {
      let dstId = null;
      let move = MOVE_UP;

      switch (this.formObject.move_position) {
        case MOVE_TO_TOP:
          dstId = this.optionValues[0].id;
          break;
        case MOVE_TO_BOTTOM:
          dstId = this.optionValues[this.optionValues.length - 1].id;
          move = MOVE_DOWN;
          break;
        case MOVE_TO_POSTION:
          dstId = this.formObject.target_option_value;
          move = this.formObject.move_direction;
          break;
      }
      return { dstId, move };
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_move": "이동",
  "subtitle": "선택한 옵션값 {0}개",
  "group_title": "이동 위치",
  "move_to_top": "전체 목록의 최상단으로 이동합니다.",
  "move_to_bottom": "전체 목록의 최하단으로 이동합니다.",
  "option_value": "옵션값",
  "target_to_move": "{target_option_value} 옵션값 {move_type} 로 이동합니다.",
  "placeholder_select_target": "옵션값 선택",
  "above": "위",
  "below": "아래"
}
</i18n>
<i18n locale="en">
{
  "target_to_move": "Move option value {target_option_value} to the {move_type}"
}
</i18n>
