<template>
  <div
    v-if="isLaunched || isSuperAdminAccessible"
    v-tooltip="{ message: contentTooltip, textAlign: 'left' }"
    :class="[isLaunched ? '' : 'super-admin__item']"
  >
    <div class="TheAccountMenuPopupService__title">
      {{ $t(`menu.${service}`)
      }}<AppSvgIcon
        v-if="contentTooltip"
        name="icon-info-tooltip"
        class="TheAccountMenuPopupService__title-icon"
      />
    </div>
    <table class="TheAccountMenuPopupService__detail-table">
      <tr v-for="(item, i) in items" :key="`${service}-row-${i}`">
        <th>{{ item.title }}{{ item.content ? ':' : '' }}</th>
        <td
          v-tooltip="{ message: item.contentTooltip, textAlign: 'right' }"
          class="TheAccountMenuPopupService__detail-value"
        >
          {{ item.content }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheAccountMenuPopupService',
  props: {
    service: {
      type: String,
      required: true,
      validator: v =>
        [
          'review',
          'target',
          'fit',
          'display',
          'channel',
          'insight',
          'review_addon'
        ].includes(v)
    },
    contentTooltip: { type: String, default: '' },
    items: { type: Array, required: true },
    isLaunched: { type: Boolean, default: true }
  },
  computed: mapGetters(['isSuperAdminAccessible'])
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheAccountMenuPopupService__title {
  @include text-label;
  position: relative;
}

.TheAccountMenuPopupService__title-icon {
  fill: $color-grey-50;
  margin-left: 4px;
  top: 3px;
  position: absolute;
}

.TheAccountMenuPopupService__detail-table {
  @include text-caption-dark;
  width: 100%;
}

.TheAccountMenuPopupService__detail-value {
  text-align: right;
}
</style>
