<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm
      v-bind="{
        ...formProps,
        id: SETTINGS_ID,
        objectId: 'engagement_settings',
        formStyle: 'hor',
        submitButton: true
      }"
      v-on="formEvents"
    >
      <template #group="{ id }">
        <template v-if="id === 'message_sending_enabled'">
          <div class="AppForm__group-field">
            {{ $t('groups.message_sending_enabled.description') }}
            <AppExternalLinkWithIcon
              class="ReviewSettingsCampaignMessage__external-link"
              :to="pathWithBrandParams('/v2/review/messages/settings')"
            >
              <AppInlineButton
                button-style="caption"
                :label="$t('groups.message_sending_enabled.link')"
              />
            </AppExternalLinkWithIcon>
          </div>
        </template>
        <template v-else-if="id === 'sending_limit_per_day'">
          <div class="AppForm__group-field">
            <AppSelectRadio
              v-model="formObject.sending_limit_per_day_enabled"
              name="engagement_settings[sending_limit_per_day_enabled]"
              :options="sendingLimitPerDayOptions"
            >
              <template #label="{ value, checked }">
                <i18n
                  v-if="value"
                  path="groups.sending_limit_per_day.options.limited"
                >
                  <AppNumberInput
                    v-model="formObject.sending_limit_per_day"
                    name="engagement_settings[sending_limit_per_day]"
                    :digits="3"
                    :disabled="!checked"
                    @blur="validateField('sending_limit_per_day')"
                    @change="validateField('sending_limit_per_day')"
                  />
                  <AppInlineButton
                    v-tooltip="{
                      message: $t(
                        'groups.sending_limit_per_day.tooltip.message'
                      ),
                      textAlign: 'left',
                      horizontal: true
                    }"
                    :label="$t('groups.sending_limit_per_day.tooltip.label')"
                    button-style="caption"
                  />
                </i18n>
              </template>
            </AppSelectRadio>
          </div>
        </template>
        <template v-else-if="id === 'show_write_popup'">
          <AppButton
            class="AppForm__group-field"
            :label="$t('groups.show_write_popup.button')"
            :disabled="!formObject.show_write_popup"
            @click="openWritePopupSettings"
          />
        </template>
        <template v-else-if="id === 'popup_period'">
          <div class="AppForm__group-field">
            <i18n path="groups.popup_period.description">
              <AppNumberInput
                v-model="formObject.pending_starts_after_days"
                name="engagement_settings[pending_starts_after_days]"
                :disabled="!formObject.show_write_popup"
                :digits="3"
                @blur="validateField('pending_starts_after_days')"
                @change="validateField('pending_starts_after_days')"
              />
              <AppNumberInput
                v-model="formObject.pending_expires_after_days"
                name="engagement_settings[pending_expires_after_days]"
                :disabled="!formObject.show_write_popup"
                :digits="3"
                @blur="validateField('pending_expires_after_days')"
                @change="validateField('pending_expires_after_days')"
              />
            </i18n>
          </div>
        </template>
        <template v-else-if="id === 'email_preview'">
          <div class="AppForm__group-field">
            <AppButton
              :label="
                $t('groups.email_preview.open_email_preview_button_label')
              "
              @click="openEmailPreviewDialog"
            />
            <AppButton
              :label="$t('groups.email_preview.send_sample_email_button_label')"
              @click="sendSampleEmail"
            />
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import { isEmailFormatValid } from '@/lib/emailFormatValidator';
import NineWayDirection from '@/enums/NineWayDirection';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';

export default {
  name: 'ReviewSettingsEngagement',
  mixins: [FormView, SettingsView],
  data: () => ({ isLoading: true, SETTINGS_ID: 'review_settings_engagement' }),
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    sendingLimitPerDayOptions() {
      return [
        {
          value: false,
          label: this.$t('groups.sending_limit_per_day.options.unlimited')
        },
        { value: true }
      ];
    },
    groupOptions() {
      return {
        popup_excluding_category_ids: {
          type: 'select_product_category',
          multiple: true,
          hint: this.$t('groups.popup_excluding_category_ids.hint')
        },
        popup_blacklist_usernames: {
          type: 'tags',
          placeholder: this.$t('groups.popup_blacklist_usernames.placeholder'),
          hint: this.$t('groups.popup_blacklist_usernames.hint')
        },
        sending_limit_per_day: {
          groupDescription: this.$t('groups.sending_limit_per_day.description'),
          validate: this.formObject.sending_limit_per_day_enabled
            ? ['required']
            : []
        },
        advanced_tip_box: {
          type: 'tip_box',
          label: '',
          value: this.$t('review_settings_advanced_tip_box.text')
        },
        show_write_popup: {
          type: 'checkbox'
        },
        max_popup_count_per_day: {
          type: 'number',
          digits: 2,
          i18n: 'review_settings_popup.max_popup_count_per_day'
        },
        popup_direction: {
          type: 'select_radio',
          options: NineWayDirection.options(),
          cols: 3
        },
        popup_offset_x: {
          type: 'number',
          digits: 3,
          default: 0,
          allowNegative: true
        },
        popup_offset_y: {
          type: 'number',
          digits: 3,
          default: 0,
          allowNegative: true
        },
        email_sending_enabled: { type: 'checkbox' },
        email_subject: {
          type: 'text',
          placeholder: this.$t('groups.email_subject.placeholder'),
          hint: this.$t('groups.email_subject.hint', {
            brand: '%{brand}',
            user: '%{user}'
          })
        },
        reward_notice_short: { type: 'textarea', superAdmin: true }
      };
    }
  },
  mounted() {
    api
      .get('/review/engagement_settings')
      .then(({ data }) => (this.orgFormObject = data.engagement_settings))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openEmailPreviewDialog() {
      this.openDialog(['ReviewSettingsEmailPreviewDialog']);
    },
    sendSampleEmail() {
      const to = prompt(
        this.$t('groups.email_preview.send_sample_email_prompt')
      );
      if (!to) return;

      if (!isEmailFormatValid(to)) {
        alert(this.$t('groups.email_preview.email_format_invalid', { to }));
        return;
      }

      api.post(
        '/review/sample_email',
        { to },
        {
          successMessage: this.$t('groups.email_preview.sample_email_sent', {
            to
          })
        }
      );
    },
    openWritePopupSettings() {
      this.openDialog(['WritePopupSettingsDialog']);
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/review/engagement_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => (this.orgFormObject = { ...this.formObject }))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style scoped>
.ReviewSettingsCampaignMessage__external-link {
  margin-left: 4px;
}
</style>

<i18n locale="ko">
{
  "groups": {
    "popup_excluding_category_ids": {
      "hint": "한 주문에 작성 유도 제한 카테고리에 해당하는 상품이 포함되어 있다면 해당 상품을 제외한 나머지 상품에 대해서만 팝업과 작성 유도 메시지 및 이메일을 발송합니다."
    },
    "popup_blacklist_usernames": {
      "hint": "작성 유도를 제한한 사용자 ID에 대해 팝업, 작성 유도 메시지 및 이메일을 발송하지 않습니다.",
      "placeholder": "ID를 선택해주세요."
    },
    "message_sending_enabled": {
      "description": "작성 유도 메시지 종류별 사용 여부는 [메시지 관리]에서 관리할 수 있습니다.",
      "link": "메시지 관리 메뉴를 새 탭에서 열기"
    },
    "sending_limit_per_day": {
      "description": "하루에 발송할 메시지 수를 제한하여 한 달 동안 균등하게 메시지를 보낼 수 있습니다.",
      "options": {
        "unlimited": "모든 배송 완료 고객에게 메시지 발송",
        "limited": "하루에 {0} 건까지 메시지 발송 {1}"
      },
      "tooltip": {
        "label": "메시지 발송 기준",
        "message": "발송 건수 제한으로 미발송된 메시지가 우선적으로 발송됩니다.\n이후, 배송 완료 순서대로 메시지가 발송됩니다."
      }
    },
    "show_write_popup": {
      "button": "작성 팝업 문구 설정"
    },
    "popup_period": {
      "description": "배송 완료일을 기준으로 {0} 일 후부터, {1} 일 후까지 노출"
    },
    "email_subject": {
      "placeholder": "제목을 입력해주세요.",
      "hint": "{brand}를 입력하면 쇼핑몰 이름을, {user}를 입력하면 고객 이름을 이메일 제목에 표시합니다."
    },
    "email_preview": {
      "open_email_preview_button_label": "이메일 미리보기",
      "send_sample_email_button_label": "테스트 이메일 발송",
      "send_sample_email_prompt": "테스트 이메일을 받을 주소를 입력해주세요.",
      "email_format_invalid": "`{to}`는 정상적인 이메일 주소가 아닙니다.",
      "sample_email_sent": "테스트 이메일을 `{to}`로 발송했습니다."
    }
  }
}
</i18n>
