<template>
  <div class="StepWidgetInstall">
    <template v-if="isImwebBrand">
      <AccordionStepContent
        class="StepWidgetInstall__content--imweb"
        :current-step="2"
        :steps-count="2"
        :is-completed="requestInstallationCompleted"
        :title="$t('imweb.title')"
        :tip="contentTipText"
        v-on="$listeners"
      >
        <div class="StepWidgetInstall__image">
          <AppImage
            src="https://assets.cre.ma/m/admin/v2/onboarding-imweb-install-v2.png"
          />
        </div>
      </AccordionStepContent>
    </template>
    <template v-else-if="isMakeshopBrand || isGodoBrand">
      <AccordionStepContent
        :current-step="2"
        :steps-count="2"
        :is-completed="requestInstallationCompleted"
        :title="$t('title')"
        :tip="contentTipText"
        :disable-next-button="disableNextButton"
        v-on="$listeners"
        @next="submit"
      >
        <AppForm
          class="StepWidgetInstall__form"
          v-bind="formProps"
          :disabled="requestInstallationCompleted"
          v-on="formEvents"
        />
        <AppDataList :data="items">
          <template #value>
            <template v-if="isWidgetInstallRequested">
              <div>{{ $t('install_requested') }}</div>
            </template>
            <template v-else>
              <AppButton
                :label="$t('button')"
                @click="openRequestInstallationFormDialog"
              />
            </template>
          </template>
        </AppDataList>
      </AccordionStepContent>
    </template>
    <template v-else>
      <AccordionStepContent
        :current-step="2"
        :steps-count="2"
        :is-completed="requestInstallationCompleted"
        :title="$t('title')"
        :tip="contentTipText"
        :disable-next-button="disableNextButton"
        v-on="$listeners"
      >
        <AppDataList :data="items">
          <template #value>
            <template v-if="isWidgetInstallRequested">
              <div>{{ $t('install_requested') }}</div>
            </template>
            <template v-else>
              <AppButton
                :label="$t('button')"
                @click="openRequestInstallationFormDialog"
              />
            </template>
          </template>
        </AppDataList>
      </AccordionStepContent>
    </template>
  </div>
</template>

<script>
import WidgetInstallStatus from '@/enums/WidgetInstallStatus';
import AccordionStepContent from './AccordionStepContent';
import FormView from '@/mixins/FormView';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'StepWidgetInstall',
  components: { AccordionStepContent },
  mixins: [FormView],
  computed: {
    ...mapState('session', ['currentBrand', 'adminSettings']),
    ...mapGetters('session', [
      'isImwebBrand',
      'isMakeshopBrand',
      'isGodoBrand'
    ]),
    ...mapGetters('easyJoin', [
      'requestInstallationCompleted',
      'widgetInstallStatus',
      'mallAdminUsername',
      'mallAdminPassword'
    ]),
    items() {
      return [
        {
          label: this.$t('label'),
          labelDisabled: this.requestInstallationCompleted,
          badge: {
            label: WidgetInstallStatus.t(this.widgetInstallStatus),
            badgeStyle: this.isWidgetInstallRequested
              ? 'default'
              : 'red-outline'
          }
        }
      ];
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'mall_admin_username',
              label: this.$t('mall_admin_username.label'),
              type: 'text',
              placeholder: this.$t('mall_admin_username.placeholder'),
              validate: ['required'],
              labelDisabled: this.requestInstallationCompleted
            },
            {
              id: 'mall_admin_password',
              label: this.$t('mall_admin_password.label'),
              type: 'text',
              placeholder: this.$t('mall_admin_password.placeholder'),
              validate: ['required'],
              labelDisabled: this.requestInstallationCompleted
            }
          ]
        }
      ];
    },
    disableNextButton() {
      if (this.isMakeshopBrand) {
        return (
          !this.isWidgetInstallRequested ||
          !this.formObject.mall_admin_username ||
          !this.formObject.mall_admin_password
        );
      } else return !this.isWidgetInstallRequested;
    },
    isWidgetInstallRequested() {
      return this.widgetInstallStatus !== WidgetInstallStatus.NONE;
    },
    imwebAdminUrl() {
      return `${this.currentBrand.url}/admin/design`;
    },
    contentTipText() {
      switch (this.currentBrand.shop_builder) {
        case 'imweb':
          return this.$t('imweb.tip');
        case 'makeshop':
          return this.$t('makeshop.tip', {
            link:
              'https://help-review.cre.ma/hc/ko/articles/9993159992345--리뷰-메이크샵-부운영자-생성-및-권한-위임-설정'
          });
        case 'godo':
          return this.$t('godo.tip', {
            link:
              'https://help-review.cre.ma/hc/ko/articles/9968400527001--%EB%A6%AC%EB%B7%B0-NHN%EA%B3%A0%EB%8F%84-%EB%B6%80%EC%9A%B4%EC%98%81%EC%9E%90-%EC%83%9D%EC%84%B1-%EB%B0%8F-%EA%B6%8C%ED%95%9C-%EC%9C%84%EC%9E%84-%EC%84%A4%EC%A0%95'
          });
        default:
          return this.$t('tip');
      }
    }
  },
  created() {
    this.orgFormObject = {
      mall_admin_username: this.mallAdminUsername,
      mall_admin_password: this.mallAdminPassword
    };
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('easyJoin', ['updateOnboardingProgress']),
    openRequestInstallationFormDialog() {
      this.adminSettings.enable_widget_install_request_v2
        ? this.openDialog('WidgetInstallRequestDialog')
        : this.openDialog('ReviewDashboardRequestInstallationDialog');
    },
    submit() {
      this.isSubmitting = true;
      this.updateOnboardingProgress({
        mall_admin_password: this.formObject.mall_admin_password,
        mall_admin_username: this.formObject.mall_admin_username
      })
        .then(() => {
          this.orgFormObject = { ...this.formObject };
          this.$emit('next');
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.StepWidgetInstall {
  ::v-deep a {
    text-decoration: underline;
  }
}

.StepWidgetInstall__image {
  padding: 20px 32px;
  text-align: center;
  background-color: $color-grey-05;
  line-height: 0;
}

.StepWidgetInstall__form {
  margin-bottom: 24px;
}
</style>

<i18n locale="ko">
{
  "title": "위젯 설치",
  "tip": "쇼핑몰에 설치할 크리마 위젯을 신청하는 단계입니다. 요청 완료 후 [확인] 버튼을 눌러주세요.",
  "label": "위젯 설치 요청",
  "button": "설치 요청 시작",
  "install_requested": "설치 요청 내역은 가입시 입력한 이메일 주소로 발송되었습니다.",
  "makeshop": {
    "tip": "쇼핑몰에 크리마 위젯 설치를 위해 부운영자 계정 정보가 필요합니다.<br /><a href='{link}' target='_blank'>메이크샵 부운영자 생성 및 권한 위임 설정 방법</a>을 참고하여 계정을 생성한 뒤 위젯 설치 요청을 진행해 주세요. 요청 완료 후 [확인] 버튼을 눌러주세요."
  },
  "godo": {
    "tip": "<a href='{link}' target='_blank'>고도몰 부운영자 생성 및 권한 위임 설정 방법</a>을 참고하여 계정을 생성한 뒤 위젯 설치 요청을 진행해 주세요. <br />요청 완료 후 [확인] 버튼을 눌러주세요."
  },
  "mall_admin_username": {
    "label": "부운영자 아이디",
    "placeholder": "부운영자 아이디(ID)를 입력해주세요."
  },
  "mall_admin_password": {
    "label": "부운영자 비밀번호",
    "placeholder": "부운영자 비밀번호를 입력해주세요."
  },
  "imweb": {
    "title": "위젯 설치 방법 안내",
    "tip": "아임웹 디자인 페이지에서 쇼핑몰 페이지 별로 원하는 크리마 리뷰 위젯을 추가 할 수 있습니다.",
    "label": "아임웹 디자인 페이지에서 크리마 리뷰 위젯 설치",
    "button": "아임웹 디자인 페이지 바로가기"
  }
}
</i18n>
