<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppTags id="basic" v-model="tags" />
      <div class="ui-section__description">Selected Tags: {{ tags }}</div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppTags id="disabled" v-model="tagsDisabled" disabled />
      <div class="ui-section__description">
        Selected Tags: {{ tagsDisabled }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppTags id="invalid" v-model="tagsInvalid" invalid />
      <div class="ui-section__description">
        Selected Tags: {{ tagsInvalid }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="banned">With Banned Tags</label>
      </div>
      <AppTags id="banned" v-model="tagsBanned" :banned-tags="BANNED_TAGS" />
      <div class="ui-section__description">
        Selected Tags: {{ tagsBanned }}, Banned Tags: {{ BANNED_TAGS }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="placeholder">With Placeholder</label>
      </div>
      <AppTags
        id="placeholder"
        v-model="tagsPlaceholder"
        placeholder="Add your tag by clicking here."
      />
      <div class="ui-section__description">
        Selected Tags: {{ tagsPlaceholder }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="remove-only">Remove Only</label>
      </div>
      <AppTags
        id="remove-only"
        v-model="tagsRemoveOnly"
        remove-only
        placeholder="Every tag is removed."
      />
      <div class="ui-section__description">
        Selected Tags: {{ tagsRemoveOnly }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTags',
  data() {
    return {
      BANNED_TAGS: ['Blue', 'Cardigan'],
      tags: ['L', 'Blue', 'Jean'],
      tagsDisabled: ['L', 'Blue', 'Jean'],
      tagsInvalid: ['L', 'Blue', 'Jean'],
      tagsBanned: ['L', 'Blue', 'Jean'],
      tagsPlaceholder: [],
      tagsRemoveOnly: ['L', 'Blue', 'Jean']
    };
  }
};
</script>
