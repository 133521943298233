<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="subTitle"
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #group="{ id, errors }">
      <template v-if="id === 'review_tags'">
        <div class="AppForm__group-field">
          <AppButton
            :label="$t('review_tags.add_button')"
            @click="addReviewTag"
          />
          <AppTable
            v-if="reviewTagRows.length"
            table-id="review-edit-analysis-review-tags-table"
            :rows="reviewTagRows"
            :columns="reviewTagColumns"
            no-head
          >
            <template #cell="{ row, column, rowIndex }">
              <template v-if="column.id == 'nlp_category_type_id'">
                <AppSelect
                  v-model="row.nlp_category_type_id"
                  :options="
                    nlpCategoryTypes.map(t => ({ label: t.name, value: t.id }))
                  "
                  @change="resetReviewTagTypeId(rowIndex)"
                />
              </template>
              <template v-else-if="column.id == 'review_tag_type_id'">
                <AppSelectFilterable
                  v-model="row.review_tag_type_id"
                  :options="
                    reviewTagTypeOptions[row.nlp_category_type_id] || []
                  "
                  :invalid="
                    !!errors[`review_tags[${rowIndex}][review_tag_type_id]`]
                  "
                  :placeholder="
                    $t('review_tags.placeholder.review_tag_type_id')
                  "
                  @change="
                    validateField(
                      `review_tags[${rowIndex}][review_tag_type_id]`
                    )
                  "
                />
                <AppFormError
                  :error="
                    errors[`review_tags[${rowIndex}][review_tag_type_id]`]
                  "
                />
              </template>
              <template v-else-if="column.id == 'sentiment'">
                <AppSelectFilterable
                  v-model="row.sentiment"
                  :options="reviewTagSentimentOpitons"
                  :invalid="!!errors[`review_tags[${rowIndex}][sentiment]`]"
                  :placeholder="$t('review_tags.placeholder.sentiment')"
                  @change="validateField(`review_tags[${rowIndex}][sentiment]`)"
                />
                <AppFormError
                  :error="errors[`review_tags[${rowIndex}][sentiment]`]"
                />
              </template>
              <template v-else-if="column.id == 'actions'">
                <AppButton
                  :label="$t('app.delete')"
                  button-style="red-outline"
                  @click="deleteReviewTag(rowIndex)"
                />
              </template>
            </template>
          </AppTable>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import ReviewAnalysisStatus from '@/enums/ReviewAnalysisStatus';
import ReviewSentimentType from '@/enums/ReviewSentimentType';

import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewReviewEditAnalysisDialog',
  mixins: [DialogFormView],
  props: {
    reviewIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState('review', ['reviews', 'nlpCategoryTypes']),
    ...mapState('session', ['reviewSettings', 'reviewTagTypes']),
    ...mapGetters('session', ['isUsingNlp']),
    formSections() {
      return [
        {
          groups: [
            this.reviewSettings.use_nlp_sentiment
              ? {
                  id: 'sentiment_type',
                  label: this.$t('sentiment_type.title'),
                  type: 'select_radio',
                  options: ReviewSentimentType.options([
                    ReviewSentimentType.POSITIVE,
                    ReviewSentimentType.NEUTRAL,
                    ReviewSentimentType.NEGATIVE
                  ])
                }
              : null,
            this.isReviewAnalyzable
              ? {
                  id: 'review_tags',
                  label: this.$t('review_tags.title'),
                  fields: _.flatten(
                    this.reviewTagRows.map((row, i) => [
                      {
                        id: `review_tags[${i}][review_tag_type_id]`,
                        value: () => row.review_tag_type_id,
                        validate: ['required']
                      },
                      {
                        id: `review_tags[${i}][sentiment]`,
                        value: () => row.sentiment,
                        validate: ['required']
                      }
                    ])
                  )
                }
              : null
          ].filter(g => g)
        }
      ];
    },
    review() {
      return this.reviews.items[this.reviewIndex];
    },
    isReviewAnalyzable() {
      return (
        this.isUsingNlp &&
        this.review.analysis_status !== ReviewAnalysisStatus.NOT_ANALYZE
      );
    },
    subTitle() {
      return this.$t('sub_title', [this.formatUser(this.review)]);
    },
    reviewTagColumns() {
      return [
        'nlp_category_type_id',
        'review_tag_type_id',
        'sentiment',
        'actions'
      ].map(id => ({
        id
      }));
    },
    reviewTagRows() {
      return this.formObject.review_tags || [];
    },
    reviewTagTypeOptions() {
      const selectedReviewTagTypeIdSet = new Set(
        _.map(this.reviewTagRows, 'review_tag_type_id')
      );
      return _.chain(this.reviewTagTypes)
        .map(type => ({
          label: type.name,
          value: type.id,
          nlp_category_type_id: type.nlp_category_type_id,
          disabled: selectedReviewTagTypeIdSet.has(type.id)
        }))
        .groupBy('nlp_category_type_id')
        .value();
    },
    reviewTagSentimentOpitons() {
      return _.range(-2, 3, 1).map(value => ({
        label: ReviewSentimentType.t(value),
        value
      }));
    }
  },
  mounted() {
    const reviewTagTypesMap = _.keyBy(this.reviewTagTypes, 'id');
    const reviewTags = this.isReviewAnalyzable
      ? this.review.review_tags.map(review_tag => ({
          ...review_tag,
          nlp_category_type_id:
            reviewTagTypesMap[review_tag.review_tag_type_id]
              .nlp_category_type_id
        }))
      : [];
    this.orgFormObject = {
      sentiment_type: this.review.sentiment_type,
      review_tags: reviewTags
    };
  },
  methods: {
    ...mapActions('review', ['updateAnalysisReview']),
    addReviewTag() {
      this.reviewTagRows.push({
        id: _.uniqueId('review_tag_type_id'),
        nlp_category_type_id: this.nlpCategoryTypes[0].id
      });
    },
    resetReviewTagTypeId(rowIndex) {
      this.$set(this.reviewTagRows[rowIndex], 'review_tag_type_id', null);
      this.validateField(`review_tags[${rowIndex}][review_tag_type_id]`);
      this.validateField(`review_tags[${rowIndex}][sentiment]`);
    },
    deleteReviewTag(rowIndex) {
      this.reviewTagRows.splice(rowIndex, 1);
    },
    submit() {
      const review_tags_attributes = this.reviewTagRows
        .filter(
          r => r.review_tag_type_id && ReviewSentimentType.hasValue(r.sentiment)
        )
        .map(r => ({
          review_tag_type_id: r.review_tag_type_id,
          sentiment: r.sentiment
        }));

      this.isSubmitting = true;
      this.updateAnalysisReview({
        reviewId: this.review.id,
        analysis_attributes: {
          sentiment_attribute: this.formObject.sentiment_type,
          review_tags_attributes
        },
        successMessage: this.$t('app.saved')
      })
        .then(({ data }) => {
          this.eventBus.$emit('save', {
            sentimentAnalysisReport:
              data.review_messages_sentiment_analysis_reports,
            reviewAnalysisReports: data.review_analysis_reports,
            reviewSentimentType: data.review.review_sentiment_type
          });
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.review-edit-analysis-review-tags-table {
  width: fit-content;

  &.AppTable--no-head {
    border-top: none;
    margin-top: 4px;
  }
}

::v-deep {
  .review-edit-analysis-review-tags-table {
    tbody tr {
      border-bottom: none;

      &:hover {
        background-color: white;
      }
    }

    .AppTable__body-col {
      vertical-align: top;
    }
  }

  .review-edit-analysis-review-tags-table__nlp-category-type-id {
    padding: 4px;
  }

  .review-edit-analysis-review-tags-table__review-tag-type-id {
    padding: 4px 4px 4px 0;
  }

  .review-edit-analysis-review-tags-table__sentiment {
    width: 190px;
    padding: 4px 4px 4px 0;
  }

  .review-edit-analysis-review-tags-table__actions {
    width: 58px;
    padding: 4px 0;
  }
}
</style>

<i18n locale="ko">
{
  "title": "리뷰 분석 결과 수정",
  "sub_title": "{0}님의 리뷰",
  "sentiment_type": {
    "title": "리뷰 본문 긍정/부정 분석"
  },
  "review_tags": {
    "title": "주요 키워드별 분석",
    "add_button": "항목 추가",
    "placeholder": {
      "review_tag_type_id": "키워드를 선택해주세요.",
      "sentiment": "분석 결과를 선택해주세요."
    }
  }
}
</i18n>

<i18n locale="en">
{
  "title": "Edit review analysis results",
  "sub_title": "{0}'s review",
  "sentiment_type": {
    "title": "Review positive/negative analysis"
  },
  "review_tags": {
    "title": "Analysis by keywords",
    "add_button": "Add item",
    "placeholder": {
      "review_tag_type_id": "select keywords.",
      "sentiment": "Select analysis results."
    }
  }
}
</i18n>
