<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="isCategoryPage ? cornerPreview.name : subTitle"
    :form-props="{
      ...formProps,
      disabled: !isValid,
      formStyle: 'narrow',
      submitLabel: $t('app.confirmed')
    }"
    v-on="formEvents"
  >
    <AppInfoBox class="DisplayCornerAddProductDialog__info-box">
      {{ $t('info') }}
      <div v-if="isNewCategoryProduct">{{ $t('additional_info') }}</div>
    </AppInfoBox>
    <AppDataSection :title="$t('product_info.title')">{{
      productsName
    }}</AppDataSection>
    <template #group="{ id, value, error, options }">
      <template v-if="id === 'position'">
        <i18n path="form.position.description">
          <AppNumberInput
            v-model="formObject.position"
            class="AppForm__group-field DisplayCornerAddProductDialog__position"
            :digits="3"
            :invalid="!!error"
            @change="validateField('position')"
            @blur="validateField('position')"
          />
        </i18n>
      </template>
      <template v-else-if="id === 'method'">
        <div class="AppForm__group-field">
          <AppSelectRadio
            v-model="formObject.method"
            name="method"
            :options="options"
          >
            <template #label="{ value }">
              <div>{{ $t(`form.method.options.${value}.label`) }}</div>
              <div class="DisplayCornerAddProductDialog__method-description">
                {{ $t(`form.method.options.${value}.description`) }}
              </div>
            </template>
          </AppSelectRadio>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DisplayCornerHandleProductView from '@/mixins/DisplayCornerHandleProductView';

export default {
  name: 'DisplayCornerAddProductDialog',
  mixins: [DisplayCornerHandleProductView],
  data: () => ({ formObject: { method: 'insert', position: 1 } }),
  computed: {
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    ...mapGetters('displayProductDisplay', [
      'cornerPreview',
      'removedProductIds'
    ]),
    isNewCategoryProduct() {
      return (
        this.isCategoryPage &&
        !this.selectedProducts.some(({ id }) =>
          this.removedProductIds.includes(id)
        )
      );
    },
    formSections() {
      return this.isValid
        ? [
            {
              groups: [
                {
                  id: 'position',
                  label: this.$t('form.position.label'),
                  validate: [
                    'required',
                    { rule: 'min_max_integer', min: 0, max: 999 }
                  ]
                },
                {
                  id: 'method',
                  label: this.$t('form.method.label'),
                  type: 'select_radio',
                  options: ['insert', 'replace'].map(value => ({ value }))
                }
              ]
            }
          ]
        : [];
    }
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapActions('displayProductDisplay', ['addProducts', 'removeProducts']),
    submit() {
      const { position, method } = this.formObject;
      const index = position === 0 ? 0 : position - 1;

      if (method === 'insert') {
        this.addProducts({ index, products: this.selectedProducts });
      } else {
        this.removeProducts([index]);
        this.addProducts({ index, products: this.selectedProducts });
      }

      this.createToast(
        this.$t(`toast.${method}`, [this.selectedProducts.length, position])
      );
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayCornerAddProductDialog__info-box {
  margin-bottom: 16px;
}

.DisplayCornerAddProductDialog__position {
  margin-right: 4px;
}

.DisplayCornerAddProductDialog__method-description {
  @include text-caption;
}
</style>

<i18n locale="ko">
{
  "title": "상품 진열",
  "info": "해당 상품을 진열할 위치를 설정해 주세요.",
  "additional_info": "카테고리 진열 시 해당 카테고리에 추가됩니다.",
  "product_info": {
    "title": "진열 대상 상품"
  },
  "form": {
    "position": {
      "label": "진열 위치",
      "description": "{0}에 진열"
    },
    "method": {
      "label": "진열 방법",
      "options": {
        "insert": {
          "label": "끼워넣기",
          "description": "지정한 진열 위치에 상품을 진열합니다. 기존 상품들은 하나씩 뒤로 밀립니다."
        },
        "replace": {
          "label": "교체하기",
          "description": "지정한 진열 위치에 있는 상품 대신 진열 합니다. 기존 상품은 진열 취소됩니다."
        }
      }
    }
  },
  "toast": {
    "insert": "{0}개의 상품을 {1}번 위치에 끼워넣어 진열했습니다.",
    "replace": "{0}개의 상품을 {1}번 위치에 교체하여 진열했습니다."
  }
}
</i18n>
