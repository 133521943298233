<template>
  <div class="SummaryBoxInstallSimulationConfirm">
    <AppSummaryBox>
      <template #title>{{ $t('title') }}</template>
      <AppText :text="content" />
      <template #buttons>
        <AppButton
          button-style="blue-outline"
          :label="$t('button_label')"
          @click="openSimulationChangesDialog"
        />
      </template>
    </AppSummaryBox>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'SummaryBoxInstallSimulationConfirm',
  computed: {
    ...mapState('session', ['currentBrand']),
    link() {
      return `/v2/review/settings/widgets?brand_id=${this.currentBrand.id}`;
    },
    content() {
      return this.$t('content_html', { link: this.link });
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openSimulationChangesDialog() {
      this.openDialog('ReviewInstallSimulationConfirmDialog');
    }
  }
};
</script>

<style lang="scss" scoped>
.SummaryBoxInstallSimulationConfirm {
  margin-bottom: 30px;
}

::v-deep {
  a {
    text-decoration: underline;
  }
}
</style>

<i18n locale="ko">
{
  "title": "리뷰 간편 설치 테스트",
  "content_html": "라이브 전 쇼핑몰에 위젯 간편 설치를 테스트할 수 있는 페이지입니다. 위젯 추가 및 위젯 설정은 <a href='{link}'>리뷰 설정 메뉴</a>에서 진행해주세요.",
  "button_label": "설치 테스트 내역 보기"
}
</i18n>
