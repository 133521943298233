<template>
  <AppModeless class="TheMoreMenuPopup" align="center" @close="$emit('close')">
    <AppDropdownMenu :menu-items="displayMenuItems" @close="$emit('close')" />
  </AppModeless>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import api from '@/lib/api';
import WidgetInstallStatus from '@/enums/WidgetInstallStatus';

export default {
  name: 'TheMoreMenuPopup',
  computed: {
    ...mapState('editor', [
      'enableSuperAdminMode',
      'enableSuperAdminHighlight',
      'enableI18nKey'
    ]),
    ...mapState('session', ['currentBrand']),
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('session', [
      'isCurrentUserAdmin',
      'isCurrentUserInstallationAgency',
      'isSmartInstallationSupported',
      'isWidgetInstallCompleteAccessible'
    ]),
    ...mapGetters('menu', ['moreMenuItems']),
    menuItems() {
      return this.moreMenuItems
        .filter(i => !i.parentId)
        .map(i => ({ ...i, ...this.menuItemConfig(i) }));
    },
    installationAgencyMenuItems() {
      if (!this.isSuperAdminAccessible && !this.isCurrentUserInstallationAgency)
        return [];

      const items = [];

      if (this.isSmartInstallationSupported) {
        items.push({
          label: this.$t('more_menu.smart_installation_inspect'),
          clickHandler: ({ close }) => {
            this.openDialog('TheSmartInstallationInspectDialog');
            close();
          }
        });
      }

      if (this.isWidgetInstallCompleteAccessible) {
        items.push({
          label: this.$t('more_menu.widget_install_complete'),
          clickHandler: ({ close }) => {
            this.clickWidgetInstallComplete();
            close();
          }
        });
      }

      return items.map(i => ({ ...i, group: 4 }));
    },
    superAdminMenuItems() {
      const items = [];

      if (this.isCurrentUserAdmin) {
        items.push({
          label: this.$t('more_menu.super_admin_mode'),
          type: 'switch',
          checked: this.enableSuperAdminMode,
          clickHandler: this.toggleSuperAdminMode,
          class: 'super-admin__item'
        });
      }

      if (this.isSuperAdminAccessible) {
        items.push(
          {
            label: this.$t('more_menu.super_admin_highlight'),
            type: 'switch',
            checked: this.enableSuperAdminHighlight,
            clickHandler: this.toggleSuperAdminHighlight,
            superAdmin: true
          },
          {
            label: 'I18n Key',
            type: 'switch',
            checked: this.enableI18nKey,
            clickHandler: this.toggleI18nKey,
            superAdmin: true
          },
          {
            label: this.$t('more_menu.super_admin_home'),
            type: 'old_admin_link',
            to: '/admin',
            superAdmin: true
          }
        );
      }

      return items.map(i => ({ ...i, group: 5 }));
    },
    displayMenuItems() {
      return _.chain([
        ...this.menuItems,
        ...this.installationAgencyMenuItems,
        ...this.superAdminMenuItems
      ])
        .groupBy('group')
        .map(ary => ary)
        .value();
    }
  },
  methods: {
    ...mapMutations('session', ['SET_WIDGET_INSTALL_STATUS']),
    ...mapActions('dialog', ['confirm', 'openDialog']),
    ...mapActions('editor', [
      'setEnableSuperAdminMode',
      'setEnableSuperAdminHighlight',
      'setEnableI18nKey'
    ]),
    clickWidgetInstallComplete() {
      this.confirm({
        title: this.$t('widget_install_complete_confirm_title', {
          brand: this.currentBrand.name
        }),
        message: this.$t('widget_install_complete_confirm_message'),
        closeButtonLabel: this.$t('app.dispatch')
      }).then(result => {
        if (!result) return;

        api
          .post(
            '/easy_join/widget_install_complete',
            {},
            { successMessage: this.$t('app.saved') }
          )
          .then(() =>
            this.SET_WIDGET_INSTALL_STATUS(WidgetInstallStatus.COMPLETE)
          );
      });
    },
    toggleSuperAdminMode() {
      this.setEnableSuperAdminMode(!this.enableSuperAdminMode);
    },
    toggleSuperAdminHighlight() {
      this.setEnableSuperAdminHighlight(!this.enableSuperAdminHighlight);
    },
    toggleI18nKey() {
      this.setEnableI18nKey(!this.enableI18nKey);
    },
    menuItemConfig(item) {
      const config = {};

      switch (item.type) {
        case 'dialog':
          config.clickHandler = ({ close }) => {
            this.openDialog([item.dialog, this.dialogProps(item.id)]);
            close();
          };
          break;
        case 'expandable':
          config.subItems = this.moreMenuItems
            .filter(i => i.parentId === item.id)
            .map(i => ({ ...i, to: i.path }));
          break;
        case 'external_link':
          config.to = item.path;
      }

      return config;
    },
    dialogProps(id) {
      return id === 'logs_dialog'
        ? {
            initialTab: this.isCurrentUserAdmin
              ? 'logs_dialog_review_mileage'
              : 'logs_dialog_smart_installation'
          }
        : {};
    }
  }
};
</script>

<style scoped>
.TheMoreMenuPopup {
  width: 182px;
  margin-top: 12px;
}
</style>

<i18n locale="ko">
{
  "more_menu": {
    "smart_installation_inspect" : "위젯 설치 검수",
    "widget_install_complete": "테스트 체험 안내 발송",
    "super_admin_mode": "최고관리자 모드",
    "super_admin_highlight": "강조해서 보기",
    "super_admin_home": "최고 관리 페이지 이동"
  },
  "widget_install_complete_confirm_title": "[%{brand}]에 테스트 체험 안내를 발송하시겠습니까?",
  "widget_install_complete_confirm_message": "테스트 체험 안내를 발송한 경우 되돌릴 수 없습니다. 발송하시겠습니까?"
}
</i18n>
