var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-head-control"},[_c('AppButton',_vm._b({attrs:{"label":_vm.$t('add_image'),"tooltip":_vm.disabled && !_vm.isEtcReview ? _vm.$t('add_image_tooltip') : null},on:{"click":_vm.addImage}},'AppButton',{ disabled: _vm.disabled || _vm.isEtcReview },false))],1),_c('AppTable',{attrs:{"table-id":"images-table","columns":_vm.COLUMNS,"rows":_vm.images,"no-data":_vm.$t('no_data')},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var rowIndex = ref.rowIndex;
return [(column.id === 'position')?[_vm._v(_vm._s(rowIndex + 1))]:(column.id === 'image_preview')?[_c('input',{attrs:{"type":"hidden","name":"review[images_attributes][][rotation]"},domProps:{"value":_vm.images[rowIndex].rotation}}),_c('input',{attrs:{"type":"hidden","name":"review[images_attributes][][url]"},domProps:{"value":_vm.images[rowIndex].url}}),_c('div',{staticClass:"ReviewReviewEditImagesTable__image-preview"},[(_vm.hasImage(rowIndex))?_c('AppImage',{staticClass:"ReviewReviewEditImagesTable__image-preview-image",style:({
              transform: ("rotate(" + (_vm.images[rowIndex].rotation) + "deg)")
            }),attrs:{"src":_vm.images[rowIndex].url || _vm.images[rowIndex].file},on:{"error":function($event){return _vm.deleteErroredImage(rowIndex)}}}):_c('div',{staticClass:"ReviewReviewEditImagesTable__image-preview-no-image"},[_vm._v(" - ")])],1)]:(column.id === 'image')?[_c('AppImageInput',{key:("review_image_file" + (_vm.images[rowIndex].id)),ref:("review_image_file" + (_vm.images[rowIndex].id)),attrs:{"not-removable":"","no-preview":"","name":"review[images_attributes][][file]","select-on-mount":_vm.images[rowIndex].selectOnMount,"invalid":!_vm.hasImage(rowIndex),"disabled":_vm.isEtcReview},on:{"change":function($event){return _vm.imageFileSelected(rowIndex, $event.imageUrl)}}})]:(column.id === 'actions')?[_c('AppButtonDraggable',{attrs:{"disabled":_vm.isEtcReview},on:{"sort":_vm.sort},scopedSlots:_vm._u([{key:"drag-image",fn:function(){return [_c('img',{staticStyle:{"padding":"4px"},attrs:{"src":_vm.images[rowIndex].url || _vm.images[rowIndex].file}})]},proxy:true}],null,true)}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('edit_delete_button'),"menu-items":[
            [
              {
                label: _vm.$t('rotate_image_ccw'),
                disabled: !_vm.hasImage(rowIndex) || _vm.isEtcReview,
                clickHandler: function (ref) {
                            var close = ref.close;

                            return _vm.rotateImageCcwClicked(rowIndex, close);
}
              },
              {
                label: _vm.$t('rotate_image_cw'),
                disabled: !_vm.hasImage(rowIndex) || _vm.isEtcReview,
                clickHandler: function (ref) {
                            var close = ref.close;

                            return _vm.rotateImageCwClicked(rowIndex, close);
}
              }
            ],
            [
              {
                label: _vm.$t('app.delete'),
                style: 'danger',
                clickHandler: function (ref) {
                            var close = ref.close;

                            return _vm.deleteImageClicked(rowIndex, close);
}
              }
            ]
          ]}})]:_vm._e()]}}])}),(!_vm.images.length)?_c('input',{attrs:{"type":"hidden","name":"review[images_attributes][]"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }