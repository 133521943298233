<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Normal</label>
      </div>
      <AppSelectProduct
        id="normal"
        :products="productsNormal"
        @change-items="productsNormal = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ productsNormal.map(p => p.id)[0] }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppSelectProduct
        id="disabled"
        disabled
        :products="productsDisabled"
        @change-items="productsDisabled = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ productsDisabled.map(p => p.id)[0] }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppSelectProduct
        id="invalid"
        invalid
        :products="productsInvalid"
        @change-items="productsInvalid = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ productsInvalid.map(p => p.id)[0] }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="multiple">Multiple</label>
      </div>
      <AppSelectProduct
        id="multiple"
        multiple
        :products="productsMultiple"
        @change-items="productsMultiple = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ productsMultiple.map(p => p.id) }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="duplicateNames">Duplicate Names</label>
      </div>
      <AppSelectProduct
        id="duplicateNames"
        multiple
        :products="productsDuplicateNames"
        @change-items="productsDuplicateNames = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ productsDuplicateNames.map(p => p.id) }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="multiple_stringify">Multiple + Stringify</label>
      </div>
      <AppSelectProduct
        id="multiple_stringify"
        multiple
        packing-method="string"
        :products="productsMultipleStringify"
        @change-items="productsMultipleStringify = $event"
        @change="valueMultipleStringify = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueMultipleStringify }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiSelectProduct',
  data() {
    const PRODUCTS_BASIC = [
      { id: 1, name: 'Ant-Man' },
      { id: 2, name: 'Black Panther' }
    ];
    return {
      productsNormal: PRODUCTS_BASIC.filter(p => p.id === 1),
      productsDisabled: PRODUCTS_BASIC.filter(p => p.id === 2),
      productsInvalid: PRODUCTS_BASIC.filter(p => p.id === 1),
      productsMultiple: [...PRODUCTS_BASIC],
      productsDuplicateNames: [
        { id: 1, name: '블랙 위도우' },
        { id: 2, name: '캡틴 아메리카' },
        { id: 3, name: '블랙 위도우' },
        { id: 4, name: '캡틴 아메리카' },
        { id: 5, name: '블랙 위도우' },
        { id: 6, name: '캡틴 아메리카' },
        { id: 7, name: '블랙 위도우' },
        { id: 8, name: '캡틴 아메리카' }
      ],
      productsMultipleStringify: [...PRODUCTS_BASIC],
      valueMultipleStringify: '1,2'
    };
  }
};
</script>
