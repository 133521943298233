<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="productDisplayName"
    :form-props="{
      ...formProps,
      formStyle: 'narrow',
      submitStyle: 'danger',
      submitLabel: $t('submit_button.submit'),
      detectFormDataChange: false
    }"
    v-on="formEvents"
  />
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'DisplayProductDisplayCancelAutoScheduleDialog',
  mixins: [DialogFormView],
  props: {
    productDisplayId: { type: Number, required: true },
    productDisplayName: { type: String, required: true },
    scheduledAt: { type: String, required: true }
  },
  data: () => ({ orgFormObject: { cancel_option: 'skip' } }),
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: '',
              label: '',
              description: this.$t('description')
            },
            {
              id: 'cancel_option',
              label: this.$t('cancel_option.label'),
              type: 'select_radio',
              options: [
                {
                  label: this.$t('cancel_option.skip', [
                    this.formatDateTimeWithoutSecond(this.scheduledAt)
                  ]),
                  value: 'skip'
                },
                {
                  label: this.$t('cancel_option.cancel'),
                  value: 'cancel'
                }
              ]
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    submit() {
      const { cancel_option } = this.formObject;

      const endpoint =
        cancel_option === 'skip'
          ? 'skip_auto_schedule'
          : 'cancel_auto_schedule';
      api
        .post(`/display/product_displays/${this.productDisplayId}/${endpoint}`)
        .then(({ data }) => {
          this.createToast(this.$t(`toast.${cancel_option}`));
          this.eventBus.$emit('schedule-canceled', data);
          this.close(true);
        });
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "진열 예약 취소",
  "description": "설정된 반복 진열 예약을 취소합니다.",
  "cancel_option": {
    "label": "반복 진열 예약 취소",
    "skip": "바로 다음 예약({0}) 한 건만 취소",
    "cancel": "이후 모든 진열 예약 취소"
  },
  "submit_button": {
    "submit": "예약 취소"
  },
  "toast": {
    "skip": "진열 예약 1건이 취소 되었습니다.",
    "cancel": "모든 진열 예약이 취소 되었습니다."
  }
}
</i18n>
