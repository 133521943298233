<template>
  <AppSearchbar @submit="search">
    <template #default>
      <AppButtonSyncCategory />
    </template>
    <template #right>
      <li>
        <AppSearchWithType
          v-model="searchWithType"
          :search-types="searchTypes"
        />
      </li>
    </template>
  </AppSearchbar>
</template>

<script>
export default {
  name: 'DisplayCategoryCornersSearchbar',
  data() {
    return {
      searchWithType: { search_type: 'full_name', search_query: '' },
      searchTypes: [
        { value: 'full_name', label: this.$t('search_type.full_name') },
        { value: 'code', label: this.$t('search_type.code') }
      ]
    };
  },
  methods: {
    search() {
      this.$emit('search', this.searchWithType);
    }
  }
};
</script>

<i18n locale="ko">
{
  "search_type": {
    "full_name": "카테고리 명",
    "code": "카테고리 코드"
  }
}
</i18n>
