<template>
  <AppIntroContent
    :images="[
      { url: 'https://assets.cre.ma/m/admin/crema-display-intro-v1.png' }
    ]"
    :image-width="600"
  />
</template>

<script>
export default {
  name: 'DisplayIntro'
};
</script>
