<template>
  <div :class="['AppGrid', `AppGrid--gutter-width-${gutterWidth}`]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppGrid',
  props: {
    gutterWidth: {
      type: String,
      default: 'default',
      validator: v => ['default', 'wide'].includes(v)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_grid.scss';

.AppGrid {
  &--gutter-width-default {
    @include make-row();
  }
  &--gutter-width-wide {
    @include make-row(32px);
  }

  & + & {
    margin-top: 24px;
  }
}

::v-deep {
  .AppGrid__col {
    .AppGrid--gutter-width-default & {
      @include make-col-ready();
    }
    .AppGrid--gutter-width-wide & {
      @include make-col-ready(32px);
    }
    @include make-col(12);

    @for $i from 1 through 12 {
      &--#{$i} {
        @include make-col($i);
      }
    }

    @include media-breakpoint-each(tablet) {
      &--tablet-12 {
        @include make-col(12);

        margin-top: 24px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    @include media-breakpoint-each(mobile) {
      @include make-col(12);

      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>
