<template>
  <div />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BrandReviewStatus from '@/enums/BrandReviewStatus';
import JoinType from '@/enums/JoinType';
import moment from 'moment';

export default {
  name: 'AppScriptLoader',
  props: {
    type: {
      type: String,
      default: 'review'
    }
  },
  data() {
    return {
      script: {}
    };
  },
  computed: {
    ...mapState('session', [
      'currentBrand',
      'reviewSettings',
      'currentUser',
      'easyJoin'
    ]),
    ...mapGetters('session', ['isShopifyBrand']),
    src() {
      switch (this.type) {
        case 'review':
          if (this.isShopifyBrand)
            return 'https://static.zdassets.com/ekr/snippet.js?key=8f6492f7-6dc5-4c8b-9109-2fcc6ac30b13';
          else if (this.isReviewNewPart && !this.joinFromEasyJoin) return '';
          else
            return 'https://static.zdassets.com/ekr/snippet.js?key=e5dfc9bf-5a30-48d0-a5a2-928be6f0b81d';
        case 'target':
          return 'https://static.zdassets.com/ekr/snippet.js?key=9634cb7a-6a92-497d-8b8d-aca71fb5e974';
        case 'fit':
          return 'https://static.zdassets.com/ekr/snippet.js?key=9b458d1a-828c-447f-a1ee-9f09cb8cd243';
        case 'display':
          return 'https://static.zdassets.com/ekr/snippet.js?key=b66f8e6d-4582-4f34-86ac-11c9216dee73';
      }
      return '';
    },
    isReviewOperatingPart() {
      return [
        BrandReviewStatus.OPERATING,
        BrandReviewStatus.EXPIRED_BUT_OPERATING,
        BrandReviewStatus.EXPIRED_AND_ADMIN_BLOCKED,
        BrandReviewStatus.TERMINATED
      ].includes(this.currentBrand.review_status);
    },
    isReviewNewPart() {
      if (
        this.currentBrand.review_status === BrandReviewStatus.OPERATING &&
        moment(this.reviewSettings.review_launched_at).isSameOrAfter(
          moment().subtract(6, 'days')
        )
      )
        return true;

      return [
        BrandReviewStatus.NONE,
        BrandReviewStatus.REQUESTED,
        BrandReviewStatus.PAID,
        BrandReviewStatus.TESTING
      ].includes(this.currentBrand.review_status);
    },
    joinFromEasyJoin() {
      return this.currentBrand.join_type === JoinType.EASY_JOIN;
    }
  },
  watch: {
    type() {
      this.change();
    }
  },
  mounted() {
    this.change();
  },
  methods: {
    change() {
      this.resetZendesk();
      this.openZendesk();
    },
    resetZendesk() {
      // REF: https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/
      // NOTE: 첫 페이지 로딩 때, 젠데스크 위젯이 열리는 것을 방지하자
      if (window.zE) window.zE('messenger', 'close');

      // HACK: 젠데스크는 SPA에서 여러 위젯을 노출하는 것을 기본 동작으로 제공하지 않는다.
      // 리뷰, 핏, 타겟과 같이 접속된 메뉴가 달라질 때, 젠데스크 관련 요소를 직접 제거하고 스크립트를 새로 불러오자
      Object.keys(window).forEach(k => {
        if (k.startsWith('zE')) delete window[k];
      });
      document.getElementById('ze-snippet')?.remove();
      document.querySelector('[data-product="web_widget"]')?.remove();
      document
        .querySelector('[title="Messaging window"], [title="메시징 창"]')
        ?.parentElement?.remove();
    },
    openZendesk() {
      if (this.src) {
        const zendeskElement = document.createElement('script');
        zendeskElement.setAttribute('src', this.src);
        zendeskElement.setAttribute('id', 'ze-snippet');
        zendeskElement.onload = () => {
          if (this.currentUser.zendesk_messanger_jwt) {
            window.zE('messenger', 'logoutUser');
            window.zE('messenger:on', 'open', () => {
              window.zE('messenger', 'loginUser', callback => {
                callback(this.currentUser.zendesk_messanger_jwt);
              });
            });
          }
        };
        document.body.appendChild(zendeskElement);
      }
    }
  }
};
</script>
