<template>
  <AppAjaxContent :is-loading="isLoading" class="NewReviewLink">
    <AppRadioLabel
      class="NewReviewLink__radio-label"
      :label="$t('new_review_link.title')"
      :tooltip="$t('new_review_link.tooltip')"
      :checked="false"
    />
    <AppSearchbar>
      <AppButton
        button-style="grey-outline"
        :label="$t('smart_installation.create')"
        @click="openNewReviewLinkFormDialog"
      />
    </AppSearchbar>
    <AppTable
      table-id="new-review-link-popup-table"
      :columns="COLUMNS"
      :rows="rows"
      :event-bus="tableEventBus"
    >
      <template #cell="{ column, rowIndex }">
        <div v-if="column.id === 'feature'">
          <AppButton
            :label="$t('app.settings')"
            @click="openNewReviewLinkSettings(rowIndex)"
          />
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            @click="clickDeleteNewReviewLink(rowIndex)"
          />
        </div>
      </template>
    </AppTable>
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import api from '@/lib/api';
import { mapActions } from 'vuex';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import SmartInstallationType from '@/enums/SmartInstallationType';
import SmartInstallationHelper from '@/mixins/SmartInstallationHelper';

export default {
  name: 'NewReviewLink',
  mixins: [SmartInstallationHelper],
  data() {
    return {
      newReviewLinks: [],
      isLoading: true,
      tableEventBus: new Vue(),
      COLUMNS: [
        {
          id: 'installation_id',
          label: this.$t('table_head.installation_id')
        },
        {
          id: 'device_type',
          label: this.$t('table_head.device_type')
        },
        {
          id: 'page_type',
          label: this.$t('table_head.page_type')
        },
        {
          id: 'element_selector',
          label: this.$t('table_head.element_selector')
        },
        {
          id: 'feature',
          label: this.$t('table_head.feature')
        }
      ]
    };
  },
  computed: {
    rows() {
      return this.newReviewLinks.map(popup => ({
        installation_id: popup.id,
        device_type: WidgetDeviceType.t(popup.device_type),
        page_type: this.getPageTypeOrPath(popup),
        element_selector: `${popup.element_selector}`
      }));
    }
  },
  mounted() {
    api
      .get('/smart_installations', {
        params: {
          installation_type: SmartInstallationType.NEW_REVIEW_LINK
        }
      })
      .then(({ data }) => (this.newReviewLinks = data.smart_installations))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openNewReviewLinkFormDialog() {
      this.openDialog(['NewReviewLinkFormDialog']).then(eventBus =>
        eventBus.$on('save', newReviewLink => {
          this.newReviewLinks.push(newReviewLink);
          this.$nextTick(() => {
            this.tableEventBus.$emit(
              'focus-row',
              this.newReviewLinks.length - 1
            );
          });
        })
      );
    },
    openNewReviewLinkSettings(index) {
      const newReviewLink = this.newReviewLinks[index];
      this.openDialog([
        'NewReviewLinkFormDialog',
        { newReviewLink: newReviewLink }
      ]).then(eventBus =>
        eventBus.$on('update', newReviewLink => {
          Vue.set(this.newReviewLinks, index, newReviewLink);
        })
      );
    },
    clickDeleteNewReviewLink(index) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      api
        .delete(`/smart_installations/${this.newReviewLinks[index].id}`, {
          successMessage: this.$t('app.deleted')
        })
        .then(() => {
          Vue.delete(this.newReviewLinks, index);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppRadioLabel__icon {
  color: $color-grey-50;
}

.NewReviewLink__radio-label {
  font-weight: 600;
  margin-top: 30px;
  margin-left: -8px;
  margin-bottom: 4px;
}

::v-deep {
  .new-review-link-popup-table__installation-id,
  .new-review-link-popup-table__device-type,
  .new-review-link-popup-table__page-type,
  .new-review-link-popup-table__element-selector,
  .new-review-link-popup-table__feature {
    min-width: 132px;
  }
  .new-review-link-popup-table {
    margin-top: 12px;
  }
}
</style>

<i18n locale="ko">
{
  "table_head": {
    "installation_id": "설치 ID",
    "device_type": "디바이스",
    "page_type": "설치 페이지",
    "element_selector": "설치 위치",
    "feature": "기능"
  },
  "smart_installation": {
    "create": "+ 간편 설치 추가"
  },
  "new_review_link": {
    "tooltip": "쇼핑몰 구매 후기 버튼에 크리마 리뷰 작성 팝업이 노출되도록 설치할 수 있습니다.",
    "title": "구매 후기 버튼에 작성 팝업 설치"
  }
}
</i18n>
