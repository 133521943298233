<template>
  <AppModalForm
    :title="$t('title')"
    :is-loading="isLoading"
    :form-props="{ ...formProps, object: null }"
    v-on="formEvents"
  >
    <template #searchbar>
      <li>
        <AppButton
          button-style="red"
          :label="$t('add_author_type')"
          @click="addAuthorType"
        />
      </li>
    </template>
    <template #group>
      <AppTable
        table-id="author-types-table"
        :columns="COLUMNS"
        :rows="authorTypes"
        :no-data="$t('no_data')"
      >
        <template #cell="{ column, rowIndex }">
          <template v-if="column.id === 'title'">
            <input
              type="hidden"
              :value="authorTypes[rowIndex].id"
              name="author_types[][id]"
            />
            <AppTextInput
              :ref="rowIndex === authorTypes.length - 1 ? 'titleLast' : null"
              v-model="authorTypes[rowIndex].title"
              name="author_types[][title]"
              :invalid="!authorTypes[rowIndex].title"
              :placeholder="$t('author_type.title')"
            />
          </template>
          <template v-else-if="column.id === 'image_preview'">
            <AppImage
              v-if="authorTypes[rowIndex].image_url"
              class="ReviewAuthorTypesDialog__image-preview"
              :src="authorTypes[rowIndex].image_url"
            />
            <template v-else>
              -
            </template>
          </template>
          <template v-else-if="column.id === 'image'">
            <AppImageInput
              :key="`author_types_image${authorTypes[rowIndex].id}`"
              not-removable
              no-preview
              name="author_types[][image]"
              @change="
                $set(authorTypes[rowIndex], 'image_url', $event.imageUrl)
              "
            />
          </template>
          <template v-else-if="column.id === 'actions'">
            <AppButton
              button-style="red-outline"
              :label="$t('app.delete')"
              @click="authorTypes.splice(rowIndex, 1)"
            />
          </template>
        </template>
      </AppTable>
      <input v-if="!authorTypes.length" type="hidden" name="author_types[]" />
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewAuthorTypesDialog',
  mixins: [DialogFormView],
  data() {
    return {
      COLUMNS: [
        { id: 'title', label: this.$t('author_type.title') },
        { id: 'image_preview', label: this.$t('author_type.image_preview') },
        { id: 'image', label: this.$t('author_type.image') },
        { id: 'actions', label: this.$t('app.actions') }
      ],
      isLoading: true
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'author_types',
              label: null,
              validate: [
                {
                  rule: () => this.authorTypes.every(t => t.title),
                  errorMessage: this.$t('validations.required')
                }
              ]
            }
          ]
        }
      ];
    },
    authorTypes() {
      return this.formObject;
    }
  },
  watch: {
    authorTypes: {
      handler() {
        this.validateField('author_types');
      },
      deep: true
    }
  },
  mounted() {
    api
      .get('/review/author_types')
      .then(({ data }) => (this.orgFormObject = data.author_types))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    addAuthorType() {
      this.authorTypes.push({ id: _.uniqueId('author_type'), title: '' });
      this.$nextTick(() => this.$refs.titleLast.$el.focus());
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/review/author_types', formData, {
          successMessage: this.$t('saved_author_types')
        })
        .then(() => this.close(true))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewAuthorTypesDialog__image-preview {
  max-height: 40px;
}

::v-deep {
  .author-types-table {
    table-layout: fixed;
  }
  .author-types-table__title {
    width: 156px;
  }
  .author-types-table__image-preview {
    width: 190px;
    line-height: 0;
  }
  .author-types-table__image {
    line-height: 0;
  }
  .author-types-table__actions {
    width: 72px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "작성자 타입 설정",
  "add_author_type": "작성자 타입 추가",
  "no_data": "작성자 타입이 없습니다.",
  "author_type": {
    "title": "타입 이름",
    "image_preview": "이미지 미리보기",
    "image": "이미지 등록"
  },
  "saved_author_types": "작성자 타입 설정을 저장했습니다."
}
</i18n>
