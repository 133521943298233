import _ from 'lodash';
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import api from '@/lib/api';
import ResourceView from '@/mixins/ResourceView';
import TargetContentView from '@/mixins/TargetContentView';
import TargetCampaignsSearchbar from './components/TargetCampaignsSearchbar';
import TargetCampaignsTable from './components/TargetCampaignsTable';
import TargetCampaignStatus from '@/enums/TargetCampaignStatus';
import TargetCalculateStatus from '@/enums/TargetCalculateStatus';

const POLL_KEY = 'calculate_statuses_sync_ids';

export default {
  components: { TargetCampaignsSearchbar, TargetCampaignsTable },
  mixins: [ResourceView, TargetContentView],
  data: () => ({ isLoading: true, tableEventBus: null }),
  computed: {
    ...mapState('targetCampaigns', ['campaigns']),
    defaultResourceParams() {
      return {
        search_type: 'name',
        search_query: '',
        sort_type: this.sortTypes[0]
      };
    },
    searchbarProps() {
      return {
        resourceParams: this.resourceParams,
        sortTypes: this.sortTypes,
        searchTypes: this.searchTypes,
        creatableCampaignTypes: this.creatableCampaignTypes
      };
    },
    searchbarEvents() {
      return {
        change: val => (this.resourceParams = val),
        search: this.searchResource
      };
    },
    tableProps() {
      return {
        rows: this.rows,
        columns: this.columns,
        eventBus: this.tableEventBus
      };
    },
    rows() {
      return this.campaigns;
    },
    syncableCampaignIds() {
      const SYNCABLE_CALCULATE_STATUSES = [
        TargetCalculateStatus.PENDING,
        TargetCalculateStatus.CALCULATING
      ];
      return this.campaigns
        .filter(
          c =>
            TargetCampaignStatus.isCalculable(c.status) &&
            SYNCABLE_CALCULATE_STATUSES.includes(c.calculate_status)
        )
        .map(c => c.id);
    }
  },
  created() {
    this.tableEventBus = new Vue();
  },
  beforeDestroy() {
    this.UNSET_POLL_PARAMS(POLL_KEY);
    this.SET_CAMPAIGNS([]);
  },
  watch: {
    campaigns() {
      if (this.syncableCampaignIds.length)
        this.SET_POLL_PARAMS({
          key: POLL_KEY,
          value: this.syncableCampaignIds
        });
      else this.UNSET_POLL_PARAMS(POLL_KEY);
    }
  },
  methods: {
    ...mapMutations('poll', ['SET_POLL_PARAMS', 'UNSET_POLL_PARAMS']),
    ...mapMutations('targetCampaigns', ['SET_CAMPAIGNS']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/target/campaigns', {
          params: {
            ..._.omit(params, 'tab'),
            campaign_status: params.tab,
            campaign_category: this.campaignCategory,
            campaign_media_type: this.campaignMediaType
          }
        })
        .then(({ data }) => {
          this.SET_CAMPAIGNS(data.campaigns);
          this.$nextTick(() => {
            this.tableEventBus.$emit(
              'focus-row',
              this.campaigns.findIndex(c => c.id === this.resourceParams.focus)
            );
          });
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
