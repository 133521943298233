<template>
  <AppAjaxContent :is-loading="isLoading">
    <div>
      <AppButton
        button-style="red"
        :label="$t('button.create_ab_test')"
        @click="clickCreateAbTestDialog"
      />
    </div>
    <AppResourceTable
      table-id="ab-tests-table"
      :columns="COLUMNS"
      :rows="abTests"
    >
      <template #cell="{ row, column }">
        <div v-if="column === 'ab_test_type'">
          {{ abTestTypeString(row.ab_test_type) }}
        </div>
        <div v-else-if="column === 'duration'">
          {{ formatDateTime(row.start_time) }} -
          {{ row.end_time ? formatDateTime(row.end_time) : $t('ongoing') }}
        </div>
        <div v-if="column === 'status'">
          {{ abTestStatusString(row.status) }}
        </div>
        <div v-else-if="column === 'actions'">
          <AppButton
            button-style="mango-outline"
            :label="$t('app.edit')"
            :disabled="abTestComplete(row.status)"
            @click="clickEditAbTestDialog(row.id)"
          />
          <AppDropdownMenuButton
            button-style="red-outline"
            :label="$t('button.manage')"
            :menu-items="[
              [
                {
                  label: $t('app.delete'),
                  clickHandler: ({ close }) => deleteAbTest(row.id, close)
                },
                {
                  label: $t('button.terminate'),
                  disabled: !abTestTesting(row.status),
                  clickHandler: ({ close }) => terminateAbTest(row.id, close)
                }
              ]
            ]"
          />
          <AppButton
            :label="$t('button.stats')"
            :disabled="abTestPending(row.status)"
            @click="openStatsDialog(row)"
          />
        </div>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import api from '@/lib/api';
import AbTestType from '@/enums/AbTestType';
import AbTestStatus from '@/enums/AbTestStatus';

export default {
  name: 'AnalyticsAbTestsAbTests',
  data() {
    return {
      isLoading: false,
      COLUMNS: [
        { id: 'id', label: this.$t('column.id') },
        { id: 'name', label: this.$t('column.name') },
        { id: 'ab_test_type', label: this.$t('column.ab_test_type') },
        { id: 'duration', label: this.$t('column.duration') },
        { id: 'status', label: this.$t('column.status') },
        { id: 'actions', label: this.$t('column.actions') }
      ]
    };
  },
  computed: {
    ...mapState('abTest', ['abTests'])
  },
  mounted() {
    this.isLoading = true;
    api
      .get('/analytics/ab_tests')
      .then(({ data }) => {
        this.SET_AB_TESTS(data.ab_tests);
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('abTest', ['SET_AB_TESTS']),
    clickCreateAbTestDialog() {
      this.openDialog('AnalyticsAbTestsFormDialog');
    },
    clickEditAbTestDialog(abTestId) {
      this.openDialog(['AnalyticsAbTestsFormDialog', { abTestId }]);
    },
    abTestTypeString(abTestType) {
      return AbTestType.t(abTestType);
    },
    abTestStatusString(abTestStatus) {
      return AbTestStatus.t(abTestStatus);
    },
    abTestComplete(abTestStatus) {
      return abTestStatus == AbTestStatus.COMPLETE;
    },
    abTestTesting(abTestStatus) {
      return abTestStatus == AbTestStatus.TESTING;
    },
    abTestPending(abTestStatus) {
      return abTestStatus == AbTestStatus.PENDING;
    },
    deleteAbTest(abTestId, close) {
      if (confirm(this.$t('terminate_ab_test_confirm_message'))) {
        this.isLoading = true;
        api
          .delete(`/analytics/ab_tests/${abTestId}`, {
            successMessage: this.$t('app.deleted')
          })
          .then(({ data }) => {
            this.SET_AB_TESTS(data.ab_tests);
          })
          .finally(() => (this.isLoading = false));
      }
      close();
    },
    terminateAbTest(abTestId, close) {
      if (confirm(this.$t('terminate_ab_test_confirm_message'))) {
        this.isLoading = true;
        api
          .post(`/analytics/ab_tests/${abTestId}/terminate_ab_test`, {
            successMessage: this.$t('terminated')
          })
          .then(({ data }) => {
            this.SET_AB_TESTS(data.ab_tests);
          })
          .finally(() => (this.isLoading = false));
      }
      close();
    },
    openStatsDialog(abTest) {
      this.openDialog([
        abTest.ab_test_type === AbTestType.TARGET_DISABLE_DISPATCH
          ? 'AnalyticsAbTestsTargetStatsDialog'
          : 'AnalyticsAbTestsReviewStatsDialog',
        { abTest }
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "column": {
    "id": "ID",
    "name": "이름",
    "ab_test_type": "종류",
    "duration": "기간",
    "status": "상태",
    "actions": "기능"
  },
  "button": {
    "create_ab_test": "A/B 테스트 생성",
    "stats": "통계",
    "manage": "관리",
    "terminate": "종료"
  },
  "ongoing": "미정",
  "delete_ab_test_confirm_message": "정말로 테스트를 삭제하시겠습니까?",
  "terminate_ab_test_confirm_message": "정말로 테스트를 중단하시겠습니까?",
  "terminated": "종료 되었습니다."
}
</i18n>
