import { constructEnum } from '@/lib/enum';

export default constructEnum('BrandCsvSort', {
  CREATED_AT: 0,
  UPDATED_AT: 100,
  LOGGED_IN_AT: 200,
  JOINED_AT: 300,
  INACTIVATED_AT: 400,
  DELIVERY_STARTED_AT: 1000,
  DELIVERY_FINISHED_AT: 1500,
  SYNCHRONIZED: 2000,
  RETURNED_ORDER_SYNC: 2100,
  SELECTED_ITEMS_SYNCHRONIZED: 2500,
  TRANSLATED: 3000,
  ALL: 4000
});
