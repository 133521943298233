<template>
  <div
    v-if="enableSearchbar"
    :class="[
      'AppResourceTableSearchbar',
      { 'AppResourceTableSearchbar--in-layer': isInLayer }
    ]"
  >
    <template v-if="selectedRowIdsSet.size && batchButtons.length">
      <div class="AppResourceTableSearchbar__left">
        <AppResourceTableSearchbarBatchButton
          v-for="(button, i) in batchButtons"
          :key="i"
          :control-type="button.controlType"
          :button="button"
        />
        <AppButton
          class="AppResourceTableSearchbar__text-button"
          button-style="underline"
          @click="$emit('cancel-selection')"
        >
          {{ $t('cancel_selection', { count: selectedRowIdsSet.size }) }}
        </AppButton>
      </div>
      <div class="AppResourceTableSearchbar__right">
        <AppMultipleSelectCheckbox
          v-if="filterOptions.length"
          class="AppResourceTableSearchbar__multiple-select-checkbox"
          :value="filterValues"
          :options="filterOptions"
          :inline="true"
          @change="$emit('change-filter-values', $event)"
        />
      </div>
    </template>
    <template v-else>
      <div class="AppResourceTableSearchbar__left">
        <span v-if="tableName" class="AppResourceTableSearchbar__table-name">{{
          tableName
        }}</span>
        <span
          v-if="totalCount !== null"
          class="AppResourceTableSearchbar__total-count"
          >{{ $t('total_count', { count: totalCount }) }}</span
        >
        <span
          v-if="tableDescription"
          class="AppResourceTableSearchbar__table-description"
          >{{ tableDescription }}</span
        >
      </div>
      <div class="AppResourceTableSearchbar__right">
        <slot name="bubble" />
        <AppMultipleSelectCheckbox
          v-if="filterOptions.length"
          class="AppResourceTableSearchbar__multiple-select-checkbox"
          :value="filterValues"
          :options="filterOptions"
          :inline="true"
          @change="$emit('change-filter-values', $event)"
        />
        <AppResourceTableSearchbarCustomButton
          v-for="(button, i) in customButtons"
          :key="i"
          :control-type="button.controlType"
          :button="button"
        />
        <slot name="additional-searchbar" />
        <AppResourceTableSearchbarIconButton
          v-if="enableRefresh"
          :label="$t('app.refresh')"
          icon="icon-refresh-small"
          @click="$emit('refresh')"
        />
        <AppResourceTableColumnSettingsButton
          v-if="enableColumnSettings"
          :columns="tableColumns"
        />
        <AppButtonToggle
          v-if="toggleButton"
          v-model="isToggleButtonAcitve"
          :label="toggleButton.label"
          @change="$emit('toggle', $event)"
        />
        <AppSearchInput
          v-if="enableQuery"
          class="AppResourceTableSearchbar__search"
          :value="query"
          :placeholder="$t('query_placeholder')"
          @change="setQuery"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AppResourceTableSearchbar',
  props: {
    tableName: { type: String, default: null },
    tableDescription: { type: String, default: null },
    enableQuery: { type: Boolean, required: true },
    enableRefresh: { type: Boolean, required: true },
    enableColumnSettings: { type: Boolean, required: true },
    customButtons: { type: Array, required: true },
    batchButtons: { type: Array, required: true },
    toggleButton: { type: Object, default: null },
    tableColumns: { type: Array, required: true },
    selectedRowIdsSet: { type: Set, required: true },
    totalCount: { type: Number, default: null },
    isInLayer: { type: Boolean, required: true },
    filterOptions: { type: Array, required: true },
    filterValues: { type: Array, required: true }
  },
  data() {
    return { query: '', isToggleButtonAcitve: false };
  },
  computed: {
    enableSearchbar() {
      return (
        this.tableName ||
        this.tableDescription ||
        this.totalCount !== null ||
        this.enableRefresh ||
        this.enableColumnSettings ||
        this.customButtons.length ||
        this.enableQuery ||
        this.toggleButton ||
        this.filterOptions.length
      );
    }
  },
  methods: {
    setQuery(query) {
      this.query = query;
      this.$emit('change-query', query);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_clearfix.scss';
@import '@/scss/mixins/_breakpoints.scss';

.AppResourceTableSearchbar {
  @include clearfix;
  padding: 8px 0;
  line-height: 34px;
  background: white;

  @include main-padding-horizontal;
  @include main-negative-margin-horizontal;

  &--in-layer {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.AppResourceTableSearchbar__left {
  float: left;
}

.AppResourceTableSearchbar__right {
  float: right;
  position: relative;
}

.AppResourceTableSearchbar__text-button {
  color: $color-grey-60;
}

.AppResourceTableSearchbar__button-icon {
  position: relative;
  top: 2px;
  margin-right: 8px;
}

.AppResourceTableSearchbar__search {
  margin-left: 4px;

  &:first-child {
    margin-left: 0;
  }
}

.AppResourceTableSearchbar__multiple-select-checkbox {
  display: inline-block;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
}

.AppResourceTableSearchbar__table-name {
  @include text-sub-title;

  padding-right: 8px;
}

.AppResourceTableSearchbar__table-description,
.AppResourceTableSearchbar__total-count {
  @include text-sub-title-light;
}
</style>

<i18n locale="ko">
{
  "total_count": "{count}개의 결과",
  "query_placeholder": "검색어를 입력해주세요",
  "cancel_selection": "{count}개 선택 취소"
}
</i18n>
<i18n locale="en">
{
  "total_count": "{count} of result",
  "query_placeholder": "Please enter a search term"
}
</i18n>
