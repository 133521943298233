<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #right>
        <li>
          <AppSelect
            v-model="resourceParams.log_type"
            :options="logTypeOptions"
            :placeholder="$t('app.all')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="SEARCH_TYPES"
          />
        </li>
        <li>
          <AppDateRangePicker
            v-model="resourceParams"
            :min-date="minDate"
            @update="searchResource"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppInfoBox>
      {{ $t('info_box') }}
    </AppInfoBox>
    <AppResourceTable
      table-id="logs-table"
      :columns="columns"
      :resources="logs"
      :rows="rows"
      @paginate="paginate"
    >
      <template #cell="{row, column}">
        <template v-if="column === 'action'">
          <AppBadge v-bind="actionBadge(row.log_type)" />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import moment from 'moment';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import ReviewPointTypeHelper from '@/mixins/ReviewPointTypeHelper';

import LogType from '@/enums/LogType';
import MileageActionType from '@/enums/MileageActionType';
import ReviewPointType from '@/enums/ReviewPointType';

export default {
  name: 'TheLogsDialogReviewMileage',
  mixins: [ResourceViewNoRoute, ReviewPointTypeHelper],
  props: {
    reviewId: { type: Number, default: null, required: false }
  },
  data() {
    return {
      isLoading: true,
      logs: nullResources,
      minDate: moment()
        .subtract(3, 'month')
        .add(1, 'day')
        .toDate()
        .toISOString(),
      SEARCH_TYPES: ['review_id', 'actor_email', 'actor_ip'].map(value => ({
        value,
        label: this.$t(`search_type.${value}`)
      }))
    };
  },
  computed: {
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      search_type: 'review_id'
    }),
    logTypeOptions() {
      return LogType.options([
        LogType.GIVE_MILEAGE,
        LogType.CALCULATE_MILEAGE,
        LogType.CANCEL_MILEAGE
      ]);
    },
    columns() {
      return [
        { id: 'date', label: this.$t('date') },
        { id: 'actor', type: 'html', label: this.$t('actor') },
        { id: 'action', label: this.$t('action') },
        { id: 'review_id', label: this.$t('review_id') },
        { id: 'point_type', label: this.$t('point_type') },
        { id: 'amount_cents', label: this.$t('amount_cents') },
        { id: 'position', label: this.$t('position') },
        {
          id: 'description',
          type: 'html',
          label: this.$t('description'),
          align: 'left'
        }
      ];
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    rows() {
      return this.logs.items.map(log => {
        const actor_email = log.actor_email
          ? log.actor_email
          : this.$t(`auto.${log.log_type}`);
        return {
          date: this.formatDateTime(log.created_at),
          actor: this.$t('actor_content', {
            actor_email: actor_email,
            actor_ip: log.actor_ip
          }),
          log_type: log.log_type,
          review_id: log.review_id,
          point_type: this.pointType(log),
          amount_cents: this.formatCurrency(log.amount_cents),
          position: this.position(log),
          description: this.description(log)
        };
      });
    }
  },
  mounted() {
    if (this.reviewId) {
      this.resourceParams = {
        ...this.resourceParams,
        search_query: this.reviewId
      };
    }
  },
  methods: {
    isManual({ actor_email }) {
      return !!actor_email;
    },
    actionBadge(logType) {
      return {
        label: LogType.t(logType),
        badgeStyle:
          logType === LogType.GIVE_MILEAGE
            ? 'blue'
            : logType === LogType.CALCULATE_MILEAGE
            ? 'blue-outline'
            : 'red'
      };
    },
    pointType({ point_type, log_type }) {
      return log_type === LogType.GIVE_MILEAGE && point_type !== null
        ? ReviewPointType.t(point_type)
        : '';
    },
    position({ position, log_type }) {
      const new_position = position + 1;
      return log_type === LogType.GIVE_MILEAGE
        ? this.$t('position_content', [new_position])
        : '';
    },
    description(row) {
      return row.log_type === LogType.CALCULATE_MILEAGE
        ? this.calculateMileageDescription(row)
        : row.log_type === LogType.GIVE_MILEAGE
        ? this.giveMileageDescription(row)
        : this.$t('description_content.cancel');
    },
    giveMileageDescription({
      action_type,
      main_reasons,
      review_default_reward_message,
      additional_reasons
    }) {
      const messages = [];
      if (action_type) {
        const action_type_message = MileageActionType.isAdminAction(action_type)
          ? this.$t('description_content.give.admin')
          : this.$t('description_content.give.system');
        messages.push(action_type_message);
      }
      if (main_reasons) {
        const gave_main_reason = this.$t('description_content.give.main', [
          main_reasons
        ]);
        if (main_reasons === review_default_reward_message) {
          messages.push(`${gave_main_reason}의 리뷰 작성`);
        } else {
          messages.push(gave_main_reason);
        }
      }
      if (additional_reasons) messages.push(additional_reasons);

      return messages.join('<br />');
    },
    calculateMileageDescription({
      main_reasons,
      amount_cents,
      additional_reasons
    }) {
      const messages = [];
      if (main_reasons) {
        messages.push(this.$t('description_content.calculate', [main_reasons]));
      }
      if (additional_reasons)
        messages.push(
          this.$t('description_content.changed_mileage_amount', [
            additional_reasons,
            amount_cents
          ])
        );

      return messages.join('<br />');
    },
    fetchResource(params) {
      this.isLoading = true;
      const type = 'review_mileage';
      api
        .get('/logs', { params: { ...params, type } })
        .then(({ data }) => (this.logs = data.logs))
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .logs-table__date {
    flex: 0 0 155px;
  }

  .logs-table__actor {
    flex: 0 0 170px;
  }

  .logs-table__action,
  .logs-table__amount-cents {
    flex: 0 0 108px;
  }

  .logs-table__review-id,
  .logs-table__point-type,
  .logs-table__position {
    flex: 0 0 70px;
  }
}
</style>

<i18n locale="ko">
{
  "search_type": {
    "review_id": "리뷰 ID",
    "actor_email": "관리자 ID",
    "actor_ip": "관리자 IP"
  },
  "info_box": "적립금 관련 이력은 최근 3개월 간의 내역만 제공합니다.",
  "date": "날짜",
  "actor": "관리자",
  "actor_content": "%{actor_email}<br />%{actor_ip}",
  "action": "동작",
  "review_id": "리뷰 ID",
  "point_type": "지급수단",
  "amount_cents": "지급(예상) 금액",
  "position": "지급 회차",
  "position_content":  "{0}회차",
  "description": "상세 이력",
  "description_content": {
    "cancel": "적립금 차감",
    "calculate": "예상 {point_type} 산정 근거: {0}",
    "changed_mileage_amount": "예상 {point_type} 수정: {0}원 -> {1}원",
    "give": {
      "admin": "[관리자 수동 지급]",
      "system": "[시스템 자동 지급]",
      "main": "지급 사유: {0}"
    }
  },
  "auto": {
    "11": "자동 지급",
    "13": "자동 계산"
  }
}
</i18n>
