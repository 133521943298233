<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppButton label="Open Toast" @click="openToast" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UiToast',
  data() {
    return { toastNo: 1 };
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    openToast() {
      this.createToast(`Toast #${this.toastNo}`);
      this.toastNo += 1;
    }
  }
};
</script>
