import _ from 'lodash';
import { mapActions, mapMutations } from 'vuex';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';

const FETCH_FIT_SETTINGS_REQUEST_ID = 'FETCH_FIT_SETTINGS';

export default {
  mixins: [FormView, SettingsView],
  data() {
    return {
      data: null,
      fit_settings: null
    };
  },
  methods: {
    ...mapActions('session', ['updateFitSettings']),
    ...mapMutations('request', ['CLEAR_REQUEST']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/fit/settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.data.fit_settings = _.cloneDeep(this.fit_settings);
          this.updateFitSettings(this.fit_settings);
        })
        .finally(() => (this.isSubmitting = false));
    },
    setData(data) {
      this.data = data;
    }
  },
  mounted() {
    const TAB_ANIMATION_DURATION = 100;
    const GRACE_PERIOD = 50;
    const requestStartTime = Date.now();
    api
      .get(this.FIT_SETTINGS_URL, {
        requestId: FETCH_FIT_SETTINGS_REQUEST_ID
      })
      .then(({ data }) => {
        const delay = Math.max(
          TAB_ANIMATION_DURATION +
            GRACE_PERIOD -
            (Date.now() - requestStartTime),
          0
        );
        setTimeout(() => {
          this.setData(data);
          this.fit_settings = _.cloneDeep(this.data.fit_settings);
        }, delay);
      });
  },
  beforeDestroy() {
    this.CLEAR_REQUEST(FETCH_FIT_SETTINGS_REQUEST_ID);
  },
  computed: {
    isFormDataChanged() {
      return (
        !!this.data && !_.isEqual(this.fit_settings, this.data.fit_settings)
      );
    },
    fitFormProps() {
      return {
        ...this.formProps,
        id: this.SETTINGS_ID,
        submitButton: true,
        formStyle: 'hor',
        objectId: 'fit_settings'
      };
    }
  }
};
