<template>
  <div class="DisplayProducts">
    <DisplayProductsSearchbar
      :resource-params="resourceParams"
      :width="isInDialog ? 'narrow' : 'default'"
      :selectable-category-ids="selectableCategoryIds"
      @change="resourceParams = $event"
      @search="searchResource"
    />
    <AppAjaxContent :is-loading="isLoading">
      <AppResourceTable
        table-id="displays-products-table"
        :resources="products"
        :columns="columns"
        :rows="filteredRows"
        :batch-buttons="batchButtons"
        :filter-values="filterNotDisplayed ? ['undisplayed'] : []"
        :filter-options="
          columnType === 'select'
            ? [{ label: $t('filter_undisplayed'), value: 'undisplayed' }]
            : []
        "
        :custom-buttons="customButtons"
        @change-filter-values="filterNotDisplayed = $event.length > 0"
        @paginate="paginate"
        @select-rows="selectedProducts = $event"
      >
        <template #additional-table-searchbar>
          <AppSelect
            v-if="sortTypeDateRequired"
            v-model="resourceParams.recent_days"
            class="DisplayProducts__custom-search-bar-item"
            size="small"
            :options="recentDaysOptions"
            @change="searchResource"
          />
          <AppSelect
            v-model="resourceParams.sort_type"
            class="DisplayProducts__custom-search-bar-item"
            size="small"
            :options="sortTypeOptions"
            @change="searchResource"
          />
        </template>
        <template #cell="{ row, column }">
          <div v-if="column === 'product'">
            <DisplayProductsInfoCell :product="row" />
          </div>
          <div v-else-if="column === 'status'">
            <DisplayProductsStatusCell :product="row" />
          </div>
          <div v-else-if="column === 'actions'">
            <DisplayProductsActionsCell :product="row" />
          </div>
          <div v-else-if="column === 'select'">
            <AppRadio
              name="product"
              :disabled="row.displayStatus !== 'undisplayed'"
              :value="row.id"
              @change="$emit('select-product', row)"
            />
          </div>
        </template>
      </AppResourceTable>
    </AppAjaxContent>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import DisplayPollProductsView from '@/mixins/DisplayPollProductsView';
import DisplayProductSortType from '@/enums/DisplayProductSortType';

export default {
  name: 'DisplayProducts',
  mixins: [ResourceViewNoRoute, DisplayPollProductsView],
  props: {
    columnType: {
      type: String,
      required: true,
      validator: v => ['default', 'select', 'multi_select'].includes(v)
    },
    filterType: { type: String, default: 'default' }
  },
  data() {
    return {
      isLoading: true,
      products: nullResources,
      filterNotDisplayed: this.columnType === 'select',
      filterButton: {
        icon: 'icon-filter-alt-outlined',
        controlType: 'select',
        value: null
      },
      selectedProducts: []
    };
  },
  computed: {
    ...mapState('session', ['currentBrand', 'displaySettings']),
    ...mapState('displayProductDisplay', ['display']),
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    ...mapGetters('displayProductDisplay', [
      'cornerProductCategory',
      'isPinnedProduct',
      'isExcludingProduct',
      'pinnedProductIds',
      'excludingProductIds',
      'addedProductIds',
      'removedProductIds'
    ]),
    ...mapGetters('displayProducts', ['displayedCorner', 'scheduledCorner']),
    isInDialog() {
      return this.columnType === 'select';
    },
    showDisplayStatusFilter() {
      return (
        this.filterType === 'default' && this.columnType === 'multi_select'
      );
    },
    sortTypeDateRequired() {
      const { sort_type } = this.resourceParams;
      return ![
        DisplayProductSortType.CREATED_DESC,
        DisplayProductSortType.UPDATED_DESC,
        DisplayProductSortType.DISPLAYED_DESC
      ].includes(sort_type);
    },
    sortTypeOptions() {
      return DisplayProductSortType.options();
    },
    recentDaysOptions() {
      return [1, 3, 7, 14, 30, 60, 90].map(value => ({
        label: this.$t('recent_days', [value]),
        value
      }));
    },
    customButtons() {
      return this.showDisplayStatusFilter ? [this.filterButton] : [];
    },
    excludeOtherCategories() {
      if (this.currentBrand.shop_builder !== 'makeshop') return false;

      const { cornerProductCategory } = this;
      return cornerProductCategory && !cornerProductCategory.virtual;
    },
    selectableCategoryIds() {
      if (this.excludeOtherCategories) return [this.cornerProductCategory.id];
      else return this.displaySettings.enabled_category_ids;
    },
    defaultResourceParams() {
      return {
        per: '20',
        page: '1',
        product_category_id:
          this.filterType === 'default' ? this.cornerProductCategory?.id : null,
        searchable_category_id: this.excludeOtherCategories
          ? this.cornerProductCategory.id
          : null,
        search_type: 'name',
        date_type: 'displayed_at',
        sort_type: DisplayProductSortType.CREATED_DESC,
        recent_days: 3,
        min_org_price: null,
        max_org_price: null,
        min_final_price: null,
        max_final_price: null,
        corner_product_category_id: this.cornerProductCategory?.id
      };
    },
    columnIds() {
      switch (this.columnType) {
        case 'select':
          return ['rank', 'product', 'status', 'select'];
        case 'multi_select':
          return ['row_select', 'rank', 'product', 'status', 'actions'];
        default:
          return ['rank', 'product', 'status', 'actions'];
      }
    },
    columnConfigs() {
      return {
        row_select: { type: 'row_select' },
        rank: { label: this.$t('table_header.rank'), width: '54px' },
        product: { label: this.$t('table_header.product'), align: 'left' },
        status: { label: this.$t('table_header.status'), width: '120px' },
        actions: { label: this.$t('table_header.actions'), width: '96px' },
        select: { label: this.$t('table_header.select'), width: '80px' }
      };
    },
    columns() {
      return this.columnIds.map(id => ({ id, ...this.columnConfigs[id] }));
    },
    rows() {
      const { page, per, items } = this.products;
      return items.map((item, itemIndex) => {
        const [displayStatus, index] = this.displayInfo(item);

        return {
          ...item,
          displayStatus,
          index,
          rank: per * (page - 1) + itemIndex + 1,
          displayedCorner: this.displayedCorner(item.id),
          scheduledCorner: this.scheduledCorner(item.id)
        };
      });
    },
    batchControlIds() {
      switch (this.filterType) {
        case 'pinned':
          return ['unpin_products'];
        case 'excluded':
          return ['cancel_exclude_products'];
        case 'added_or_removed':
          return ['cancel_add_to_category', 'cancel_remove_from_category'];
        default:
          if (this.isCategoryPage)
            return [
              'add_products',
              'sort_products',
              'pin_products',
              'remove_from_category',
              'cancel_remove_from_category'
            ];
          else
            return [
              'add_products',
              'remove_products',
              'sort_products',
              'pin_products',
              'unpin_products',
              'exclude_products',
              'cancel_exclude_products'
            ];
      }
    },
    batchControlConfigs() {
      const hasChildCategory =
        this.cornerProductCategory?.hasChildCategory || false;
      const { selectedProducts } = this;

      return {
        add_products: {
          disabled: hasChildCategory,
          clickHandler: () =>
            this.openDialog([
              'DisplayCornerAddProductDialog',
              {
                selectedProducts: this.filterProductsByDisplayStatus(
                  selectedProducts,
                  ['undisplayed']
                )
              }
            ])
        },
        remove_products: {
          clickHandler: () =>
            this.removeProducts(
              this.filterProductsByDisplayStatus(selectedProducts, [
                'displayed'
              ]).map(({ index }) => index)
            )
        },
        exclude_products: {
          style: 'danger',
          clickHandler: () =>
            this.openDialog([
              'DisplayCornerExcludingProductDialog',
              {
                selectedProducts: this.filterProductsByDisplayStatus(
                  selectedProducts,
                  ['undisplayed', 'displayed']
                )
              }
            ])
        },
        cancel_exclude_products: {
          style: 'danger',
          clickHandler: () =>
            this.CANCEL_EXCLUDE_PRODUCTS(
              this.filterProductsByDisplayStatus(selectedProducts, [
                'excluded'
              ]).map(({ id }) => id)
            )
        },
        pin_products: {
          clickHandler: () =>
            this.openDialog([
              'DisplayCornerPinProductDialog',
              {
                selectedProducts: this.filterProductsByDisplayStatus(
                  selectedProducts,
                  ['displayed']
                )
              }
            ])
        },
        unpin_products: {
          clickHandler: () =>
            this.UNPIN_PRODUCTS(
              this.filterProductsByDisplayStatus(selectedProducts, ['pinned'])
            )
        },
        sort_products: {
          clickHandler: () =>
            this.openDialog([
              'DisplayCornerSortProductDialog',
              {
                selectedProducts: this.filterProductsByDisplayStatus(
                  selectedProducts,
                  ['displayed']
                )
              }
            ])
        },
        cancel_add_to_category: {
          clickHandler: () =>
            this.removeProducts(
              selectedProducts
                .filter(row => this.addedProductIds.includes(row.id))
                .map(({ index }) => index)
            )
        },
        remove_from_category: {
          style: 'danger',
          disabled: hasChildCategory,
          clickHandler: () =>
            this.openDialog([
              'DisplayCornerRemoveProductDialog',
              {
                selectedProducts: this.filterProductsByDisplayStatus(
                  selectedProducts,
                  ['displayed']
                )
              }
            ])
        },
        cancel_remove_from_category: {
          style: 'danger',
          clickHandler: () =>
            this.openDialog([
              'DisplayCornerAddProductDialog',
              {
                selectedProducts: selectedProducts.filter(row =>
                  this.removedProductIds.includes(row.id)
                )
              }
            ])
        }
      };
    },
    batchButtons() {
      const batchControls = this.batchControlIds.map(id => {
        const config = this.batchControlConfigs[id] || {};
        const tooltip = config.disabled
          ? this.$t(`batch_buttons.${id}.tooltip.disabled`)
          : this.$t(`batch_buttons.${id}.tooltip.default`);

        return {
          label: this.$t(`batch_buttons.${id}.label`),
          tooltip,
          ...config
        };
      });

      const buttons = batchControls.slice(0, 3);
      const menuItems = batchControls.slice(3);

      return menuItems.length
        ? [
            ...buttons,
            { label: this.$t('app.else'), controlType: 'menu', menuItems }
          ]
        : buttons;
    },
    filteredRows() {
      let rows = this.rows;

      if (this.showDisplayStatusFilter) {
        switch (this.filterButton.value) {
          case 'displayed':
            rows = this.filterProductsByDisplayStatus(rows, [
              'displayed',
              'pinned'
            ]);
            break;
          case 'undisplayed':
          case 'pinned':
          case 'excluded':
            rows = this.filterProductsByDisplayStatus(rows, [
              this.filterButton.value
            ]);
            break;
          case 'added_to_category':
            rows = rows.filter(row => this.addedProductIds.includes(row.id));
            break;
          case 'removed_from_category':
            rows = rows.filter(row => this.removedProductIds.includes(row.id));
            break;
          case 'sold_out':
            rows = rows.filter(({ sold_out }) => sold_out);
            break;
        }
      }

      if (this.filterNotDisplayed)
        return this.filterProductsByDisplayStatus(rows, ['undisplayed']);
      else return rows;
    },
    filterProductIds() {
      switch (this.filterType) {
        case 'pinned':
          return this.pinnedProductIds;
        case 'excluded':
          return this.excludingProductIds;
        case 'added_or_removed':
          return [...this.addedProductIds, ...this.removedProductIds];
        default:
          return null;
      }
    }
  },
  watch: {
    filterProductIds() {
      this.fetchResource(this.resourceParams);
    },
    'cornerProductCategory.id'() {
      if (!this.cornerProductCategory) return;

      this.$nextTick(() => {
        const { id } = this.cornerProductCategory;
        this.resourceParams = {
          ...this.resourceParams,
          product_category_id: id,
          corner_product_category_id: id,
          searchable_category_id: this.excludeOtherCategories ? id : null
        };

        this.searchResource();
      });
    },
    rows() {
      this.initializeDisplayStatusOptions();
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('displayProductDisplay', ['removeProducts']),
    ...mapMutations('displayProductDisplay', [
      'CANCEL_EXCLUDE_PRODUCTS',
      'PIN_PRODUCTS',
      'UNPIN_PRODUCTS'
    ]),
    filterProductsByDisplayStatus(products, displayStatuses) {
      return products.filter(product =>
        displayStatuses.includes(product.displayStatus)
      );
    },
    initializeDisplayStatusOptions() {
      const { rows } = this;
      const optionIds = (this.isCategoryPage
        ? ['undisplayed', 'displayed', 'pinned']
        : ['undisplayed', 'displayed', 'excluded', 'pinned']
      ).filter(displayStatus =>
        rows.some(row => row.displayStatus === displayStatus)
      );
      if (this.isCategoryPage) {
        if (rows.some(row => this.addedProductIds.includes(row.id)))
          optionIds.push('added_to_category');
        if (rows.some(row => this.removedProductIds.includes(row.id)))
          optionIds.push('removed_from_category');
      }
      if (this.rows.some(({ sold_out }) => sold_out))
        optionIds.push('sold_out');

      this.filterButton = {
        ...this.filterButton,
        options: [
          { label: this.$t('filter.all'), value: null },
          ...optionIds.map(value => ({
            label: this.$t(`filter.${value}`),
            value
          }))
        ]
      };
    },
    displayInfo(product) {
      const index = this.display.products.findIndex(p => p.id == product.id);

      if (index > -1) {
        if (this.isPinnedProduct(product)) return ['pinned', index];
        else return ['displayed', index];
      } else if (this.isExcludingProduct(product)) {
        return ['excluded', -1];
      } else {
        return ['undisplayed', -1];
      }
    },
    doUpdateImageDownloadedProducts(downloadedProductsMap) {
      this.products.items = this.products.items.map(product => ({
        ...product,
        ...(downloadedProductsMap[product.id] || {})
      }));
    },
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/display/products', {
          params: {
            ...params,
            product_ids: this.filterProductIds
              ? [...this.filterProductIds, '']
              : null
          }
        })
        .then(({ data }) => {
          this.products = data.products;
          this.setImageDownloadingProductIds(this.products.items);
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayProducts__custom-search-bar-item {
  vertical-align: middle;
  margin-left: 8px;
  line-height: 0;

  & + & {
    margin-left: 4px;
  }
}

::v-deep {
  .displays-products-table__rank--body {
    color: $color-grey-80;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
  }
}

::v-deep {
  .AppDateRangePicker .dropdown__dialog {
    position: fixed;
    left: auto;
  }
}
</style>

<i18n locale="ko">
{
  "recent_days": "최근 {0}일",
  "table_header": {
    "rank": "순위",
    "product": "상품",
    "status": "상태",
    "actions": "기능",
    "select": "선택"
  },
  "filter_undisplayed": "미진열 상품만 보기",
  "filter": {
    "all": "모든 상태",
    "undisplayed": "미진열",
    "displayed": "진열 중",
    "pinned": "진열 고정",
    "excluded": "진열 제외",
    "added_to_category": "카테고리 추가",
    "removed_from_category": "카테고리 제외",
    "sold_out": "품절"
  },
  "batch_buttons": {
    "add_products": {
      "label": "진열",
      "tooltip": {
        "default": "선택한 상품들 중 미진열인 상품들이 있으면 진열합니다.",
        "disabled": "메이크샵은 상품을 최하위 카테고리에만 추가할 수 있습니다."
      }
    },
    "remove_products": {
      "label": "진열 취소",
      "tooltip": {
        "default": "선택한 상품들 중 진열 중인 상품들이 있으면 진열 취소합니다."
      }
    },
    "sort_products": {
      "label": "진열 위치 이동",
      "tooltip": {
        "default": "선택한 상품들의 진열 위치를 변경합니다."
      }
    },
    "pin_products": {
      "label": "진열 고정",
      "tooltip": {
        "default": "선택한 상품들의 진열 위치를 현재 위치에 고정합니다."
      }
    },
    "unpin_products": {
      "label": "진열 고정 해제",
      "tooltip": {
        "default": "선택한 상품들 중 위치 고정 중인 상품들이 있으면 고정 해제합니다."
      }
    },
    "exclude_products": {
      "label": "진열 제외",
      "tooltip": {
        "default": "선택한 상품들을 진열 제외합니다. 진열 제외된 상품들은 진열 규칙에 의해 표시되지 않습니다."
      }
    },
    "cancel_exclude_products": {
      "label": "진열 제외 취소",
      "tooltip": {
        "default": "선택한 상품들 중 진열 제외 중인 상품들이 있으면 진열 제외 취소합니다."
      }
    },
    "cancel_add_to_category": {
      "label": "카테고리 추가 취소",
      "tooltip": {
        "default": "선택한 상품들 중 현재 카테고리에서 추가 예정인 상품이 있으면 이를 취소합니다."
      }
    },
    "remove_from_category": {
      "label": "카테고리 제외",
      "tooltip": {
        "default": "선택한 상품들 중 현재 진열 중인 상품들이 있으면 카테고리에서 제외하여 진열되지 않게 합니다.\n카테고리 제외는 진열 진행시 처리됩니다.",
        "disabled": "상품이 포함된 최하위 카테고리에서 제거할 수 있습니다."
      }
    },
    "cancel_remove_from_category": {
      "label": "카테고리 제외 취소",
      "tooltip": {
        "default": "선택한 상품들 중 현재 카테고리에서 제외 예정인 상품이 있으면 이를 취소합니다."
      }
    }
  }
}
</i18n>
