<template>
  <div v-if="isCalculable" v-tooltip="tooltip">
    <div
      class="table-line TargetCampaignsTableCalculateStatusCell__calculate-status"
    >
      {{ calculateStatus }}{{ calculatingDescrtion }}
    </div>
    <div v-if="tooltip" class="table-line table-line--mt4">
      <AppBadge :label="$t('app.detail')" />
    </div>
  </div>
  <span v-else>-</span>
</template>

<script>
import TargetCampaignStatus from '@/enums/TargetCampaignStatus';
import TargetCalculateStatus from '@/enums/TargetCalculateStatus';

export default {
  name: 'TargetCampaignsTableCalculateStatusCell',
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  computed: {
    calculateStatus() {
      const { status, calculate_status } = this.campaign;
      return calculate_status == undefined ||
        (status === TargetCampaignStatus.DISABLED &&
          calculate_status === TargetCalculateStatus.NONE)
        ? '-'
        : TargetCalculateStatus.t(calculate_status);
    },
    calculatingDescrtion() {
      const { calculate_status, calculating_description } = this.campaign;
      return calculating_description &&
        calculate_status === TargetCalculateStatus.CALCULATING
        ? `- ${calculating_description}`
        : '';
    },
    tooltip() {
      if (!this.campaign.calculated_at) return;

      const { formatDateTime } = this.$options.filters;
      return `${this.$t('calculated_at')}: ${formatDateTime(
        this.campaign.calculated_at
      )}`;
    },
    isCalculable() {
      return TargetCampaignStatus.isCalculable(this.campaign.status);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TargetCampaignsTableCalculateStatusCell__calculate-status {
  color: $color-grey-70;
}
</style>

<i18n locale="ko">
{
  "calculated_at": "계산일"
}
</i18n>
