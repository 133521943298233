<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Normal</label>
      </div>
      <AppVideoInput v-model="video" />
      <div class="ui-section__description">
        ID: {{ video.id }}<br />
        Video Thumbnail Url: {{ video.thumbnail_url.substr(0, 100) + '...' }}
        <br /><br />
        <div class="UiVideoInput__image-container">
          <AppVideoImage :src="video.thumbnail_url" />
          <AppSpinner v-if="video.isUploading" class="UiVideoInput__spinner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiVideoInput',
  data() {
    return {
      video: {
        id: null,
        invalid: true,
        thumbnail_url: ''
      }
    };
  }
};
</script>

<style scoped>
.UiVideoInput__image-container {
  display: inline-block;
  position: relative;
}

.UiVideoInput__spinner {
  position: absolute;
  font-size: 56px;
  left: 32px;
  top: 32px;
}
</style>
