import api from '@/lib/api';

const cache = {};

function checkSocialUrlCache(url) {
  return cache[url];
}

function checkSocialUrl(url) {
  return new Promise(function(resolve, reject) {
    if (cache[url]) return resolve(cache[url]);

    api
      .get('/validations/check_social_url', { params: { url } })
      .then(({ data }) => {
        if (data.valid) {
          cache[url] = true;
          resolve();
        } else {
          cache[url] = false;
          reject();
        }
      })
      .catch(reject);
  });
}

export { checkSocialUrlCache, checkSocialUrl };
