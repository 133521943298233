<template>
  <div>
    <AppHeading :label="$t('code_conversion.title')" />
    <AppContainer>
      <AppForm
        :sections="formSections"
        :is-submitting="isSubmitting"
        @submit="submit"
      >
        <template #group="{ id }">
          <template v-if="id === 'upload_csv'">
            <AppFileField
              class="AppForm__group-field"
              name="product_code_conversion[file]"
              placeholder="product.csv"
              :event-bus="fileEventBus"
              accept=".csv"
              :is-submitting="isSubmitting"
            />
          </template>
        </template>
      </AppForm>
    </AppContainer>
    <AppHeading :label="$t('code_conversion_detail.title')" />
    <AppAjaxContent :is-loading="isLoading">
      <AppResourceTable
        :columns="columns"
        :rows="rows"
        :enable-total-count="false"
      >
        <template #cell="{ column, value }">
          <template v-if="column === 'status'">
            <AppBadge
              :label="ProductCodeConversionStatus.t(value)"
              :badge-style="ProductCodeConversionStatus.badgeStyle(value)"
            />
          </template>
        </template>
      </AppResourceTable>
    </AppAjaxContent>
  </div>
</template>

<script>
import Vue from 'vue';
import api from '@/lib/api';
import ProductCodeConversionStatus from '@/enums/ProductCodeConversionStatus';

export default {
  name: 'ReviewMoveShopBuilderCodeConversion',
  data: () => ({
    rows: [],
    isLoading: true,
    fileEventBus: new Vue(),
    isSubmitting: false
  }),
  computed: {
    ProductCodeConversionStatus: () => ProductCodeConversionStatus,
    columns() {
      return [
        { id: 'id', label: this.$t('app.id') },
        { id: 'file', label: this.$t('code_conversion_detail.columns.file') },
        { id: 'status', label: this.$t('app.status') }
      ];
    },
    formSections() {
      return [
        {
          id: 'description',
          groups: [
            {
              id: 'description',
              label: null,
              description: this.$t('code_conversion.description')
            },
            {
              id: 'sample_button',
              label: null,
              type: 'button_download',
              value: this.$t('code_conversion.sample'),
              url: '/api/product/code_conversions/sample.csv'
            }
          ]
        },
        {
          id: 'upload_csv',
          groups: [
            {
              id: 'upload_csv',
              label: this.$t('code_conversion.upload_csv'),
              type: 'file',
              placeholder: 'product.csv',
              accept: '.csv'
            }
          ]
        }
      ];
    }
  },
  mounted() {
    this.fetchCodeConversions();
  },
  methods: {
    fetchCodeConversions() {
      api
        .get('product/code_conversions')
        .then(({ data }) => (this.rows = data.code_conversions))
        .finally(() => (this.isLoading = false));
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('product/code_conversions', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.fileEventBus.$emit('reset');
          this.fetchCodeConversions();
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "code_conversion": {
    "title": "상품번호 변환",
    "description": "이전하기 전 호스팅사의 상품번호를 이전할 호스팅사의 상품 번호로 변환합니다.",
    "sample": "상품번호 변환 예시 CSV 파일 다운로드",
    "upload_csv": "상품번호 변환 CSV 파일 업로드"
  },
  "code_conversion_detail": {
    "title": "상품번호 변환 내역",
    "columns": {
      "file": "변환 파일(CSV)"
    }
  }
}
</i18n>
