import { constructEnum } from '@/lib/enum';

export default constructEnum('ChannelCohortStatus', {
  NONE: 0,
  PENDING: 10,
  PREPARING: 20,
  READY: 30,
  COMPLETE: 100,
  COMPLETE_BUT_REGENERATING: 110,
  ERROR: 1000,
  ERROR_TIMEOUT: 1100,
  ERROR_PREPARING: 1200,
  ERROR_READY: 1300
});
