<template>
  <nav class="TheMainMenu">
    <template v-for="mainMenuItem in mainMenuItems">
      <AppOldAdminLink
        v-if="mainMenuItem.isOldAdmin"
        :key="mainMenuItem.id"
        :to="mainMenuItem.path"
        class="TheMainMenu__item"
      >
        <AppSvgIcon
          :name="iconsMap[mainMenuItem.id]"
          :height="24"
          class="TheMainMenu__icon"
        />
        <div class="TheMainMenu__label">{{ mainMenuItem.label }}</div>
      </AppOldAdminLink>
      <AppInternalLink
        v-else
        :key="mainMenuItem.id"
        :to="mainMenuItem.path"
        :class="[
          'TheMainMenu__item',
          {
            'TheMainMenu__item--active':
              mainMenuItem.id === selectedMainMenuItemId
          },
          { 'super-admin__item': mainMenuItem.superAdmin }
        ]"
      >
        <AppSvgIcon
          :name="iconsMap[mainMenuItem.id]"
          :height="24"
          class="TheMainMenu__icon"
        />
        <div class="TheMainMenu__label">{{ mainMenuItem.label }}</div>
      </AppInternalLink>
    </template>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheMainMenu',
  data() {
    return {
      iconsMap: {
        target: 'nav-target',
        review: 'nav-review',
        fit: 'nav-fit',
        display: 'nav-display',
        channel: 'nav-ad',
        product: 'nav-product',
        analytics: 'nav-data',
        connect: 'nav-connect',
        ui: 'nav-menu',
        qa: 'icon-check-tiny-checked'
      }
    };
  },
  computed: {
    ...mapGetters('menu', ['mainMenuItems', 'selectedMainMenuItemId'])
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_transitions.scss';
@import '@/scss/mixins/_breakpoints.scss';

.TheMainMenu {
  display: flex;
  flex-wrap: wrap;
}
.TheMainMenu__item {
  @include text-main-navigation;
  @include transition-basic(color);

  display: block;
  flex: 1;
  min-width: 64px;
  height: 76px;
  padding: 16px 0;
  text-decoration: none;
  text-align: center;

  &:hover {
    color: $color-content-text-dark;
  }

  @include media-breakpoint-each(tablet, mobile) {
    height: 60;
    padding: 8px 0;
  }
}

.TheMainMenu__item--active {
  color: $color-primary;

  &:hover {
    color: $color-primary;
  }
}

.TheMainMenu__icon {
  display: block;
  margin: 0 auto;
}

.TheMainMenu__label {
  margin-top: 4px;
}
</style>
