import { constructEnum } from '@/lib/enum';

export default constructEnum('SocialMediaType', {
  NONE: 0,
  UNKNOWN: 5,
  FACEBOOK: 10,
  INSTAGRAM: 20,
  NAVER_CAFE: 30,
  NAVER_BLOG: 40,
  YOUTUBE: 100,
  VIMEO: 110
});
