import { constructEnum } from '@/lib/enum';

export default constructEnum('OrderStatus', {
  NONE: 0,
  PAID: 10,
  NOT_PAID: 20,
  NOT_PAID_CANCELLED_BY_BUYER: 25,
  NOT_PAID_CANCELLED_BY_SYSTEM: 27,
  REFUND_REQUESTED: 30,
  REFUND_DEFERRED: 35,
  RETURNED: 40,
  CANCELLED: 45,
  CANCELLED_REFUNDED: 50,
  PAYMENT_FAILED: 60,
  NOT_DELIVERED: 80,
  DELIVERY_PREPARING: 90,
  DELIVERY_STARTED: 100,
  DELIVERY_FINISHED: 110,
  EXCHANGE_REQUESTED: 190,
  EXCHANGE_ACCEPTED: 200,
  EXCHANGE_RECEIVED: 210
});
