<template>
  <AppAjaxContent :is-loading="isLoading">
    <CustomContentHeader
      :resource="campaign"
      resource-type="review_campaign"
      :is-name-editable="false"
    >
      <template #actions>
        <ReviewMessagesCampaignsManageButton
          :campaign="campaign"
          show-status-label
          @change-status="changeStatus"
        />
      </template>
    </CustomContentHeader>
    <AppInfoBox class="ReviewMessagesCampaignShow__info-box">
      {{ $t(`${campaignTypeLocaleKey}.info_box`) }}
      <AppButton
        v-if="isNthStepReviewCampaign"
        button-style="underline"
        :label="$t('nth_step_review.info_box_button')"
        @click="openNthStepReviewPreviewPopup"
      />
    </AppInfoBox>
    <ReviewMessagesMemberReviewCampaignReport v-if="isMemberReviewCampaign" />
    <template v-else-if="isNthStepReviewCampaign">
      <ReviewMessagesNthStepReviewCampaignStatCards
        class="ReviewMessagesCampaignShow__stats-cards"
      />
      <ReviewMessagesNthStepReviewCampaignCategorySettings
        :campaign-id="campaignId"
      />
    </template>
    <AppButton
      :label="$t('button.add_campaign_message')"
      button-style="red"
      :disabled="isAddButtonDisabled"
      :tooltip="isAddButtonDisabled ? $t('button.disabled_tooltip') : null"
      @click="openCreateCampaignMessageDialog"
    />
    <ReviewMessagesCampaignMessagesTable
      :campaign-id="campaignId"
      :campaign-type="campaign.campaign_type"
      :event-bus="tableEventBus"
      @update-messages-count="updateCampaignMessagesCount"
    />
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import Vue from 'vue';
import { mapActions } from 'vuex';
import ReviewCampaignType from '@/enums/ReviewCampaignType';
import ReviewCampaignStatus from '@/enums/ReviewCampaignStatus';
import ReviewMessagesMemberReviewCampaignReport from './components/ReviewMessagesMemberReviewCampaignReport';
import ReviewMessagesNthStepReviewCampaignStatCards from './components/ReviewMessagesNthStepReviewCampaignStatCards';
import ReviewMessagesCampaignMessagesTable from './components/ReviewMessagesCampaignMessagesTable.vue';
import ReviewMessagesCampaignsManageButton from './components/ReviewMessagesCampaignsManageButton.vue';
import ReviewMessagesNthStepReviewCampaignCategorySettings from './components/ReviewMessagesNthStepReviewCampaignCategorySettings';

const MAX_NTH_REVIEW_MESSAGE_COUNT = 4;

export default {
  name: 'ReviewMessagesCampaignShow',
  components: {
    ReviewMessagesMemberReviewCampaignReport,
    ReviewMessagesNthStepReviewCampaignStatCards,
    ReviewMessagesCampaignMessagesTable,
    ReviewMessagesCampaignsManageButton,
    ReviewMessagesNthStepReviewCampaignCategorySettings
  },
  data: () => ({
    isLoading: true,
    campaign: {},
    tableEventBus: new Vue()
  }),
  computed: {
    campaignId() {
      return Number(this.$route.params.id);
    },
    campaignTypeLocaleKey() {
      if (!this.campaign.campaign_type) return '';

      return ReviewCampaignType.toKey(
        this.campaign.campaign_type
      ).toLowerCase();
    },
    isNthStepReviewCampaign() {
      return this.campaign.campaign_type === ReviewCampaignType.NTH_STEP_REVIEW;
    },
    isMemberReviewCampaign() {
      return this.campaign.campaign_type === ReviewCampaignType.MEMBER_REVIEW;
    },
    isAddButtonDisabled() {
      return (
        this.isNthStepReviewCampaign &&
        this.campaign.messages_count >= MAX_NTH_REVIEW_MESSAGE_COUNT
      );
    }
  },
  mounted() {
    api
      .get(`/review/campaigns/${this.campaignId}`)
      .then(({ data }) => {
        this.campaign = {
          ...data.campaign,
          name: ReviewCampaignType.t(data.campaign.campaign_type)
        };
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'confirm']),
    updateCampaignMessagesCount(messagesCount) {
      this.campaign = { ...this.campaign, messages_count: messagesCount };
      if (
        this.campaign.status === ReviewCampaignStatus.ENABLED &&
        messagesCount === 0
      )
        this.changeStatus(ReviewCampaignStatus.DISABLED);
    },
    changeStatus(status) {
      this.campaign = { ...this.campaign, status };
    },
    openCreateCampaignMessageDialog() {
      this.openDialog([
        'ReviewMessagesMessageFormDialog',
        { campaignType: this.campaign.campaign_type }
      ]).then(eventBus => {
        eventBus.$on('submit', () => this.tableEventBus.$emit('refresh'));
      });
    },
    openNthStepReviewPreviewPopup() {
      this.confirm({
        type: 'alert',
        title: this.$t('nth_step_review.popup_title'),
        message: this.$t('nth_step_review.popup_message')
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewMessagesCampaignShow__info-box {
  @include text-label;

  margin: 20px 0;
  padding: 20px 0;
}

.ReviewMessagesCampaignShow__stats-cards {
  margin: 20px 0;
}
</style>

<i18n locale="ko">
{
  "member_review": {
    "info_box": "💡 리뷰를 작성하지 않은 쇼핑몰 회원에게 반복적으로 메시지를 보내 리뷰 작성을 유도할 수 있습니다."
  },
  "nth_step_review": {
    "info_box": "💡 리뷰를 작성한 회원에게 추가 리뷰 작성을 유도하여 더 생생한 사용 경험을 다른 고객에게 전달할 수 있습니다.",
    "info_box_button": "n차 리뷰 미리보기",
    "popup_title": "n차 리뷰 미리보기",
    "popup_message": "n차 리뷰는 다음과 같이 표시됩니다.<br />1. n차 리뷰 배지가 표시됩니다.<br />2. 이전에 작성된 리뷰를 연결해 보여줍니다.<br />3. n차 리뷰 작성 기간 동안 누적된 도움돼요, 댓글을 모두 보여줍니다.<br /><br />![](https://assets.cre.ma/m/admin/v2/nth_review_preview.png)"
  },
  "button": {
    "add_campaign_message": "발송 메시지 추가",
    "disabled_tooltip": "설정할 수 있는 모든 발송 메시지를 추가하였습니다."
  }
}
</i18n>
