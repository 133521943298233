const isAccessibleToCombinedWidget = ({ state }) =>
  state.session.fitSettings.enable_combined_widget;
const isAccessibleToNonCombinedWidget = ({ state }) =>
  !state.session.fitSettings.enable_combined_widget;
const isAccessibleWhenUsingLegacyReviewOptionTypes = ({ state }) =>
  !state.session.reviewSettings.use_product_category_product_type;
const isAccessibleWhenUsingRenewalReviewOptionTypes = ({ state }) =>
  state.session.reviewSettings.use_product_category_product_type;

export default [
  {
    id: 'fit_settings_basic',
    menuItemId: 'fit_settings_basic',
    sections: [
      {
        groups: [
          { id: 'size_product_option_type_ids' },
          { id: 'download_fit_products' },
          { id: 'edit_descriptions_all' }
        ]
      }
    ]
  },
  {
    id: 'fit_settings_review_options_info',
    menuItemId: 'fit_settings_review_options_info',
    sections: [
      {
        id: 'review_option_settings',
        groups: [
          {
            id: 'review_option_types',
            superAdmin: true,
            isAccessible: isAccessibleWhenUsingLegacyReviewOptionTypes
          },
          {
            id: 'review_option_types',
            isAccessible: isAccessibleWhenUsingRenewalReviewOptionTypes
          },
          {
            id: 'category_configs',
            isAccessible: isAccessibleWhenUsingLegacyReviewOptionTypes
          }
        ]
      },
      {
        id: 'review_option_popup',
        groups: [
          { id: 'enable_review_option_popup' },
          { id: 'review_option_popup_target' },
          { id: 'review_option_popup_excluding_category_ids' },
          { id: 'review_option_popup_lock_interval_days' },
          { id: 'review_option_popup_scroll_condition' },
          { id: 'review_option_popup_fullscreen' },
          { id: 'review_option_popup_direction' },
          { id: 'review_option_popup_offset_x' },
          { id: 'review_option_popup_offset_y' },
          { id: 'review_option_popup_check_min_pageviews' }
        ]
      }
    ]
  },
  {
    id: 'fit_settings_widgets',
    menuItemId: 'fit_settings_widgets',
    sections: [
      {
        id: 'common',
        groups: [
          { id: 'exclude_category_ids' },
          { id: 'exclude_product_ids' },
          { id: 'module_settings' },
          { id: 'show_fit_product_additional_info_toggle' }
        ]
      },
      {
        id: 'summary',
        groups: [
          { id: 'size_summary_widget_style' },
          { id: 'show_no_item_image_summary' },
          { id: 'enable_module_summary' },
          { id: 'css_summary_pc' },
          { id: 'css_summary_mobile' },
          { id: 'javascript_summary_pc' },
          { id: 'javascript_summary_mobile' }
        ],
        isAccessible: isAccessibleToNonCombinedWidget
      },
      {
        id: 'detail',
        groups: [
          { id: 'enable_module_detail' },
          { id: 'show_no_item_image_detail' },
          { id: 'pc_title_image' },
          { id: 'mobile_title_image' },
          { id: 'css_detail_pc' },
          { id: 'css_detail_mobile' },
          { id: 'javascript_detail_pc' },
          { id: 'javascript_detail_mobile' }
        ],
        isAccessible: isAccessibleToNonCombinedWidget
      },
      {
        id: 'combined_summary',
        groups: [
          { id: 'size_summary_widget_style' },
          { id: 'show_no_item_image_summary' },
          { id: 'enable_module_summary' },
          { id: 'css_summary_pc', superAdmin: true },
          { id: 'css_summary_mobile', superAdmin: true },
          { id: 'javascript_summary_pc', superAdmin: true },
          { id: 'javascript_summary_mobile', superAdmin: true },
          { id: 'combined_css_summary_pc' },
          { id: 'combined_css_summary_mobile' },
          { id: 'combined_javascript_summary_pc' },
          { id: 'combined_javascript_summary_mobile' }
        ],
        isAccessible: isAccessibleToCombinedWidget
      },
      {
        id: 'floating',
        groups: [
          { id: 'use_custom_floating_widget' },
          { id: 'floating_settings' }
        ],
        superAdmin: true
      },
      {
        id: 'combined_detail',
        groups: [
          { id: 'enable_module_detail' },
          { id: 'show_no_item_image_detail' },
          { id: 'pc_title_image' },
          { id: 'mobile_title_image' },
          { id: 'css_detail_pc', superAdmin: true },
          { id: 'css_detail_mobile', superAdmin: true },
          { id: 'javascript_detail_pc', superAdmin: true },
          { id: 'javascript_detail_mobile', superAdmin: true },
          { id: 'combined_css_detail_pc' },
          { id: 'combined_css_detail_mobile' },
          { id: 'combined_javascript_detail_pc' },
          { id: 'combined_javascript_detail_mobile' }
        ],
        isAccessible: isAccessibleToCombinedWidget
      },
      {
        id: 'fit_product',
        groups: [
          { id: 'fit_product_pc_widget_css' },
          { id: 'fit_product_pc_widget_javascript' }
        ],
        isAccessible: isAccessibleToNonCombinedWidget
      },
      {
        id: 'fit_product',
        groups: [
          { id: 'fit_product_pc_widget_css', superAdmin: true },
          { id: 'fit_product_pc_widget_javascript', superAdmin: true }
        ],
        isAccessible: isAccessibleToCombinedWidget
      }
    ]
  },
  {
    id: 'fit_settings_fit_product_api',
    menuItemId: 'fit_settings_fit_product_api',
    sections: [{ groups: [{ id: 'product_category_id_to_template_id_map' }] }]
  },
  {
    id: 'fit_settings_admin',
    menuItemId: 'fit_settings_admin',
    superAdmin: true,
    sections: [
      {
        groups: [
          { id: 'fit_launched_at' },
          { id: 'enable_combined_widget' },
          { id: 'fit_category_ids_using_similar_products' },
          { id: 'fit_category_ids_using_predict_stats' },
          { id: 'prediction_model_version' },
          { id: 'hide_size_widgets' },
          { id: 'reimport_fit_size_feedbacks' },
          { id: 'sync_fit_size_data' }
        ]
      }
    ]
  }
];
