<template>
  <table class="TargetCampaignsCountsGraph">
    <tr v-for="({ label, value, color }, i) in graphs" :key="i">
      <th class="TargetCampaignsCountsGraph__name">
        {{ label }}
      </th>
      <td class="TargetCampaignsCountsGraph__graph">
        <AppChartSmallBar
          :numerator="value"
          :denominator="maxCount"
          :color="color"
        />
      </td>
      <td class="TargetCampaignsCountsGraph__count">
        {{ $t('counts', [value]) }}
      </td>
    </tr>
  </table>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';

export default {
  name: 'TargetCampaignsCountsGraph',
  props: {
    campaign: { type: Object, required: true },
    samplingMethod: { type: Number, required: true }
  },
  computed: {
    ...mapGetters('targetCampaigns', ['campaignText']),
    effectsType() {
      return this.campaignText(this.campaign, 'kpi.name_short');
    },
    maxCount() {
      return _.maxBy(this.graphs, 'value').value;
    },
    graphs() {
      const { campaign, samplingMethod } = this;

      switch (samplingMethod) {
        case TargetSamplingMethod.MESSAGE_CHECKED:
          return [
            {
              label: this.$t('sent'),
              value: campaign.recent_sent_count,
              color: 'grey'
            },
            {
              label: this.$t('checked'),
              value: campaign.recent_checked_count,
              color: 'green'
            },
            {
              label: this.effectsType,
              value: campaign.recent_effect,
              color: 'blue'
            }
          ];
        case TargetSamplingMethod.POPUP_CHECKED:
        case TargetSamplingMethod.POPUP_CLICKED:
          return [
            {
              label: this.$t('popup_checked'),
              value: campaign.recent_checked_count,
              color: 'grey'
            },
            {
              label: this.$t('popup_clicked'),
              value: campaign.recent_clicked_count,
              color: 'green'
            },
            {
              label: this.effectsType,
              value:
                samplingMethod === TargetSamplingMethod.POPUP_CHECKED
                  ? campaign.recent_effect
                  : campaign.recent_clicked_effect,
              color: 'blue'
            }
          ];
        case TargetSamplingMethod.WIDGET_CLICKED:
          return [
            {
              label: this.$t('widget_checked'),
              value: campaign.recent_checked_count,
              color: 'grey'
            },
            {
              label: this.$t('widget_clicked'),
              value: campaign.recent_clicked_count,
              color: 'green'
            },
            {
              label: this.effectsType,
              value: campaign.recent_clicked_effect,
              color: 'blue'
            }
          ];
        default:
          return [];
      }
    }
  },
  methods: {
    barWidth(count) {
      const { maxCount } = this;
      if (count === 0 || maxCount === 0) return '0';
      if (count === maxCount) return '100%';

      const percentage = _.clamp(Math.round((count / maxCount) * 100), 1, 99);

      return `${percentage}%`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';

.TargetCampaignsCountsGraph {
  table-layout: fixed;
  width: 100%;
  max-width: 230px;

  @include media-breakpoint-each(tablet) {
    max-width: 180px;
  }

  @include media-breakpoint-each(mobile) {
    width: 180px;
  }
}

.TargetCampaignsCountsGraph__name {
  width: 36px;
  font-weight: bold;
  color: $color-grey-80;
  margin-right: 8px;
}

.TargetCampaignsCountsGraph__graph {
  padding: 0 8px;
}

.TargetCampaignsCountsGraph__count {
  width: 64px;
  color: $color-grey-70;
  text-align: right;
}
</style>

<i18n locale="ko">
{
  "sent": "발송",
  "checked": "확인",
  "popup_checked": "노출",
  "popup_clicked": "클릭",
  "widget_checked": "노출",
  "widget_clicked": "클릭",
  "counts": "{0} 건"
}
</i18n>
