<template>
  <AppSelectSearchable
    v-bind="{
      id,
      title,
      name,
      items: products,
      disabled,
      invalid,
      placeholder,
      multiple,
      sort,
      packingMethod,
      defaultFilters,
      selectButtonLabel: $t('select_product')
    }"
    :columns="[
      { id: 'code', label: $t('products.code') },
      { id: 'product', label: $t('products.name'), type: 'product' }
    ]"
    :search-types="[
      { value: 'name', label: $t('products.name') },
      { value: 'code', label: $t('products.code') }
    ]"
    v-on="$listeners"
    @search="search"
  />
</template>

<script>
import api from '@/lib/api';
import ArrayPackable from '@/mixins/ArrayPackable';

export default {
  name: 'AppSelectProduct',
  mixins: [ArrayPackable],
  props: {
    id: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default() {
        return this.$t('select_product');
      }
    },
    name: {
      type: String,
      default: null
    },
    products: {
      type: Array,
      default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('select_product_placeholder');
      }
    },
    multiple: { type: Boolean, default: false },
    sort: { type: Boolean, default: true },
    defaultFilters: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    search({ params, onSuccess, onFailure }) {
      api
        .get('/products', {
          params: { ...params, default_filters: this.defaultFilters }
        })
        .then(({ data }) =>
          onSuccess({
            ...data.products,
            items: data.products.items.map(p => ({
              id: p.id,
              name: p.name,
              code: p.code,
              product: p
            }))
          })
        )
        .catch(onFailure);
    }
  }
};
</script>

<i18n locale="ko">
{
  "select_product": "상품 선택",
  "select_product_placeholder": "상품을 선택해주세요."
}
</i18n>
