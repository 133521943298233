<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <AppSearchbar>
      <li><AppForm v-bind="formProps" v-on="formEvents" /></li>
      <li>
        <AppButtonSubmit
          :submit-label="$t('form.submit_label')"
          :submitting-label="$t('form.submitting_label')"
          :is-submitting="isSubmitting"
          :disabled="!formObject.campaign_id"
          @click="formEventBus.$emit('submit')"
        />
      </li>
    </AppSearchbar>
    <AppResourceTable
      table-id="simulations-table"
      :table-name="$t('table.name')"
      :resources="cohorts"
      :columns="columns"
      :rows="cohorts.items"
      @paginate="paginate"
    >
      <template #cell="{ row, column }">
        <template v-if="column === 'campaign'">
          <TargetCampaignNameCell
            :campaign-group-type="
              row.campaign_group_type || TargetCampaignGroupType.CUSTOM_DM
            "
            :campaign-name="row.campaign_name"
          />
        </template>
        <template v-else-if="column === 'status'">
          <TargetDispatchStatusBadge
            :campaign-type="row.campaign_type"
            :dispatch-status="row.dispatch_status"
          />
        </template>
        <template v-else-if="column === 'actions'">
          <AppButton
            :label="$t('app.detail_breakdown')"
            @click="clickDetailButton(row)"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import FormView from '@/mixins/FormView';
import ResourceView from '@/mixins/ResourceView';
import TargetContentView from '@/mixins/TargetContentView';
import TargetCampaignGroupType from '@/enums/TargetCampaignGroupType';
import TargetDispatchStatusBadge from '../components/TargetDispatchStatusBadge';

export default {
  name: 'TargetAdminSettingsSimulation',
  components: { TargetDispatchStatusBadge },
  mixins: [FormView, ResourceView, TargetContentView],
  data: () => ({
    isLoading: true,
    campaigns: [],
    cohorts: nullResources,
    orgFormObject: { campaign_id: null },
    detectFormDataChange: false
  }),
  computed: {
    TargetCampaignGroupType: () => TargetCampaignGroupType,
    formSections() {
      return [
        {
          groups: [
            {
              id: 'campaign_id',
              label: '',
              type: 'select_filterable',
              options: this.campaigns.map(({ id, name }) => ({
                label: name,
                value: id
              })),
              placeholder: this.$t('form.campaign_id_placeholder'),
              fieldStyle: 'mt0'
            }
          ]
        }
      ];
    },
    columns() {
      return [
        { id: 'id', label: this.$t('table.id') },
        {
          id: 'send_at',
          label: this.$t('table.send_at'),
          type: 'datetime'
        },
        { id: 'campaign', label: this.$t('table.campaign'), align: 'left' },
        {
          id: 'total_count',
          label: this.$t('table.total_count'),
          type: 'number',
          default: '-'
        },
        { id: 'status', label: this.$t('app.status') },
        { id: 'actions', label: this.$t('app.actions') }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchResource(inParams) {
      const params = {
        ...inParams,
        ...(this.campaigns.length ? {} : { init: 1 })
      };
      api
        .get('/target/simulations', { params })
        .then(({ data }) => {
          this.cohorts = data.cohorts;
          if (params.init) this.campaigns = data.campaigns;
        })
        .finally(() => (this.isLoading = false));
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/target/simulations', formData, {
          successMessage: this.$t('simulation_enqueued')
        })
        .then(() => (this.orgFormObject = { campaign_id: null }))
        .finally(() => (this.isSubmitting = false));
    },
    clickDetailButton({ id, campaign_name }) {
      this.openDialog([
        'TargetReportsDispatchResultDialog',
        {
          cohortId: id,
          campaignName: campaign_name
        }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .simulations-table__id {
    flex: 0 0 120px;
  }

  .simulations-table__created-at {
    flex: 0 0 200px;
  }

  .simulations-table__campaign {
    flex: 1 0 220px;
  }

  .simulations-table__messages-count {
    flex: 0 0 140px;
  }

  .simulations-table__status {
    flex: 0 0 140px;
  }

  .simulations-table__actions {
    flex: 0 0 140px;
  }
}
</style>

<i18n locale="ko">
{
  "form": {
    "campaign_id_placeholder": "모의 발송할 캠페인을 선택해주세요.",
    "submit_label": "모의 발송",
    "submitting_label": "모의 발송 중.."
  },
  "simulation_enqueued": "모의 발송을 위한 BG Job 을 생성했습니다. 잠시 후 새로고침을 통해 목록을 확인해주세요.",
  "table": {
    "name": "모의 발송 결과",
    "id": "발송 ID",
    "send_at": "발송 시작일",
    "campaign": "캠페인",
    "total_count": "전체발송수"
  }
}
</i18n>
