import { constructEnum } from '@/lib/enum';

export default constructEnum(
  'TargetInCategoriesProductRecommendationFilterType',
  {
    NONE: 0,
    CLICK_CLICK: 10,
    CLICK_PURCHASE: 20,
    PURCHASE_CLICK: 30,
    PURCHASE_PURCHASE: 40
  }
);
