<template>
  <div class="DisplayCurrentCornerSnapshot">
    <AppInfoBox
      >{{ $t('info_message')
      }}<AppButton
        class="DisplayCurrentCornerSnapshot__info-box-button"
        button-style="underline"
        :label="$t('copy_displayed_products.button.label')"
        @click="confirmCopyDisplayedProducts"
      />
    </AppInfoBox>
    <AppAjaxContent
      :class="[
        'DisplayCurrentCornerSnapshot__content',
        displayedProducts
          ? ''
          : 'DisplayCurrentCornerSnapshot__content--no-content'
      ]"
      :is-loading="isLoading"
    >
      <DisplayCornerSnapshot
        v-if="displayedProducts"
        :products="displayedProducts"
      />
      <span v-else>{{ $t('no_content') }}</span>
    </AppAjaxContent>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import api from '@/lib/api';
import DisplayPollProductsView from '@/mixins/DisplayPollProductsView';

export default {
  name: 'DisplayCurrentCornerSnapshot',
  mixins: [DisplayPollProductsView],
  data: () => ({ isLoading: true }),
  computed: {
    ...mapState('displayProductDisplay', ['cornerId']),
    ...mapState('displayProducts', ['displayedProducts'])
  },
  watch: {
    cornerId() {
      this.fetchCurrentCornerSnapshot();
    }
  },
  mounted() {
    this.fetchCurrentCornerSnapshot();
  },
  methods: {
    ...mapMutations('displayProducts', [
      'SET_DISPLAYED_PRODUCTS',
      'SET_DISPLAYED_PRODUCTS_CORNERS_MAP',
      'SET_SCHEDULED_PRODUCTS_CORNERS_MAP'
    ]),
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('displayProductDisplay', ['copyDisplayedProducts']),
    fetchCurrentCornerSnapshot() {
      if (!this.cornerId) return;

      this.isLoading = true;
      api
        .get(`/display/corners/${this.cornerId}/displayed_products`)
        .then(({ data }) => {
          this.SET_DISPLAYED_PRODUCTS(data.products);
          this.SET_DISPLAYED_PRODUCTS_CORNERS_MAP(
            data.displayed_products_corner_map
          );
          this.SET_SCHEDULED_PRODUCTS_CORNERS_MAP(
            data.scheduled_products_corner_map
          );

          this.setImageDownloadingProductIds(data.products);
        })
        .finally(() => (this.isLoading = false));
    },
    doUpdateImageDownloadedProducts(downloadedProductsMap) {
      const updatedProducts = this.displayedProducts.map(product => ({
        ...product,
        ...(downloadedProductsMap[product.id] || {})
      }));
      this.SET_DISPLAYED_PRODUCTS(updatedProducts);
    },
    confirmCopyDisplayedProducts() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          width: 'small',
          title: this.$t('copy_displayed_products.confirm_dialog.title'),
          markdownText: this.$t(
            'copy_displayed_products.confirm_dialog.description_html'
          ),
          closeButtonLabel: this.$t('app.apply'),
          snoozeId: 'DisplayCurrentCornerSnapshot.copy_displayed_products'
        }
      ])
        .then(eventBus => eventBus.$on('close', this.copyDisplayedProducts))
        .catch(this.copyDisplayedProducts);
    }
  }
};
</script>

<style lang="scss" scoped>
.DisplayCurrentCornerSnapshot__info-box-button {
  position: absolute;
  right: 16px;
}

.DisplayCurrentCornerSnapshot__content {
  height: calc(100% - 44px);
  margin-top: 24px;

  &--no-content {
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<i18n locale="ko">
{
  "info_message": "현재 쇼핑몰에 진열된 상품을 보여줍니다.",
  "no_content": "현재 쇼핑몰 진열 내용이 없습니다.",
  "copy_displayed_products": {
    "button": {
      "label": "미리보기에 적용"
    },
    "confirm_dialog": {
      "title": "현재 쇼핑몰 진열을 미리보기에 적용하시겠습니까?",
      "description_html": "현재 쇼핑몰 진열탭에 보이는 상품, 순서대로 미리보기에 적용합니다.<br />미리보기에 상품이 있는 경우 덮어쓰게 됩니다."
    }
  }
}
</i18n>
