export default {
  data() {
    return {
      iframeHeight: '0px'
    };
  },
  created() {
    this.resetIframeHeight();
  },
  mounted() {
    window.addEventListener('message', this.handleMessage);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage);
  },
  methods: {
    handleMessage(e) {
      if (!this.requiresCalculation) return;

      const hit = typeof e.data === 'string' && e.data.match(/height=(\d+)/);
      if (hit) this.iframeHeight = `${hit[1]}px`;
    },
    resetIframeHeight() {
      this.iframeHeight = this.defaultIframeHeight;
    }
  },
  computed: {
    requiresCalculation() {
      return true;
    }
  }
};
