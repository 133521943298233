import { mapGetters, mapState } from 'vuex';
import api from '@/lib/api';
import parseSkinPath from '@/lib/parseSkinPath';
import WidgetPageType from '@/enums/WidgetPageType';

export default {
  data() {
    return { product_code: null };
  },
  mounted() {
    api.get('/products/random_product_code').then(({ data }) => {
      this.product_code = data.product_code;
    });
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoryByRandom']),
    ...mapState('session', ['currentBrand', 'reviewSettings']),
    ...mapGetters('reviewWidgets', ['reviewWidget']),
    widget() {
      return this.reviewWidget(this.widgetId);
    },
    brandShopBuilder() {
      return this.currentBrand.shop_builder;
    }
  },
  methods: {
    insertedUrl(installation) {
      if (!this.insertedUrlEnabled(installation.page_type)) return;

      let url = new URL(this.currentBrand.url);
      const widget_id = this.widgetId || installation.widget_id;

      switch (this.brandShopBuilder) {
        case 'cafe24':
          url = this.cafe24InsertedUrl(installation, url);
          break;
        case 'makeshop':
          url = this.makeshopInsertedUrl(installation, url);
          break;
        case 'godo':
          url = this.godoInsertedUrl(installation, url);
          break;
      }

      url = this.urlWithCremaSearchParams(widget_id, url);

      return url.href.replace('www.', '');
    },
    insertedUrlEnabled(pageType) {
      switch (pageType) {
        case WidgetPageType.PRODUCT_LIST:
          return !!this.productCategoryByRandom;
        case WidgetPageType.PRODUCT_DETAIL:
          return !!this.product_code;
        case WidgetPageType.REVIEW_BOARD:
          return !!this.reviewSettings.reviews_index_url;
        case WidgetPageType.EVENT:
          return false;
      }
      return true;
    },
    insertedUrlTooltipMessage(pageType) {
      if (this.insertedUrlEnabled(pageType)) return;
      if (pageType == WidgetPageType.EVENT) return;

      return this.$t('smart_installations.not_found_inserted_url');
    },
    cafe24InsertedUrl(installation, url) {
      const page_type = installation.page_type;
      const skin_id = installation.skin_id;
      const isMobile = installation.isMobile;

      switch (page_type) {
        case WidgetPageType.PRODUCT_LIST: {
          url.pathname = '/product/list.html';
          url.searchParams.set('cate_no', this.productCategoryByRandom.code);
          break;
        }
        case WidgetPageType.PRODUCT_DETAIL: {
          url.pathname = '/product/detail.html';
          url.searchParams.set('product_no', this.product_code);
          break;
        }
        case WidgetPageType.REVIEW_BOARD: {
          const reviewUrl = new URL(this.reviewSettings.reviews_index_url);
          url.pathname = reviewUrl.pathname;
          url.search = reviewUrl.search;
          break;
        }
        case WidgetPageType.MYSHOP_MAIN: {
          url.pathname = '/myshop/index.html';
          break;
        }
        case WidgetPageType.MY_REVIEWS: {
          url.pathname = '/myshop/board_list.html';
          break;
        }
        case WidgetPageType.MY_ORDERS: {
          url.pathname = '/myshop/order/list.html';
          break;
        }
        case WidgetPageType.SPECIFIC: {
          url = new URL(installation.page_path, url.origin);
          break;
        }
      }
      if (skin_id) {
        url.pathname = `/skin-${installation.skin_id}${url.pathname}`;
      } else if (isMobile) {
        url.host = `m.${url.host}`;
      }
      return url;
    },
    makeshopInsertedUrl(installation, url) {
      const page_type = installation.page_type;
      const skin_id = installation.skin_id;
      const isMobile = installation.isMobile;

      switch (page_type) {
        case WidgetPageType.PRODUCT_LIST: {
          isMobile
            ? (url.pathname = '/product_list.html')
            : (url.pathname = '/shop/shopbrand.html');
          url.searchParams.set('xcode', this.productCategoryByRandom.code);
          break;
        }
        case WidgetPageType.PRODUCT_DETAIL: {
          isMobile
            ? (url.pathname = '/product.html')
            : (url.pathname = '/shop/shopdetail.html');
          url.searchParams.set('branduid', this.product_code);
          break;
        }
        case WidgetPageType.REVIEW_BOARD: {
          const reviewUrl = new URL(this.reviewSettings.reviews_index_url);
          isMobile
            ? (url.pathname = '/board.html')
            : (url.pathname = reviewUrl.pathname);
          url.search = reviewUrl.search;
          break;
        }
        case WidgetPageType.MYSHOP_MAIN: {
          isMobile
            ? (url.pathname = '/mypage.html')
            : (url.pathname = '/shop/mypage.html');
          break;
        }
        case WidgetPageType.MY_REVIEWS: {
          isMobile
            ? (url.pathname = '/mypage.html')
            : (url.pathname = '/shop/mypage.html');
          url.searchParams.set('mypage_type', 'myarticle');
          break;
        }
        case WidgetPageType.MY_ORDERS: {
          isMobile
            ? (url.pathname = '/order_list.html')
            : (url.pathname = '/shop/mypage.html');
          url.searchParams.set('mypage_type', 'myorder');
          break;
        }
        case WidgetPageType.SPECIFIC: {
          url = new URL(installation.page_path, url.origin);
          break;
        }
      }
      if (skin_id) {
        url.searchParams.set('dgnset_id', installation.skin_id);
        if (installation.isMobile) {
          url.pathname = `/m${url.pathname}`;
          url.searchParams.set('dgnset_type', 'MOBILE');
          url.searchParams.set('design_id', '1');
        }
      } else if (isMobile) {
        url.pathname = `/m${url.pathname}`;
      }
      return url;
    },
    godoInsertedUrl(installation, url) {
      const page_type = installation.page_type;
      const isMobile = installation.isMobile;

      switch (page_type) {
        case WidgetPageType.PRODUCT_LIST: {
          url.pathname = '/goods/goods_list.php';
          url.searchParams.set('cateCd', this.productCategoryByRandom.code);
          break;
        }
        case WidgetPageType.PRODUCT_DETAIL: {
          url.pathname = '/goods/goods_view.php';
          url.searchParams.set('goodsNo', this.product_code);
          break;
        }
        case WidgetPageType.REVIEW_BOARD: {
          const reviewUrl = new URL(this.reviewSettings.reviews_index_url);
          url.pathname = reviewUrl.pathname;
          url.search = reviewUrl.search;
          break;
        }
        case WidgetPageType.MYSHOP_MAIN: {
          url.pathname = '/mypage/index.php';
          break;
        }
        case WidgetPageType.MY_REVIEWS: {
          url.pathname = '/mypage/mypage_goods_review.php';
          break;
        }
        case WidgetPageType.MY_ORDERS: {
          url.pathname = '/mypage/order_list.php';
          break;
        }
        case WidgetPageType.SPECIFIC: {
          url = new URL(installation.page_path, url.origin);
          break;
        }
      }
      if (isMobile) {
        url.host = `m.${url.host}`;
      }
      return url;
    },
    urlWithCremaSearchParams(widget_id, url) {
      const testEnv = location.host.replace('admin.cre.ma', '');
      url.searchParams.set('crema-test', testEnv);
      if (widget_id) {
        url.searchParams.set('crema-scroll-to-widget-id', widget_id);
      }
      return url;
    },
    getPageTypeOrPath(installation) {
      if (installation.page_type == WidgetPageType.SPECIFIC) {
        return installation.page_path;
      }
      return WidgetPageType.t(installation.page_type);
    },
    parsePagePath(urlOrPath) {
      const [path, skinId] = parseSkinPath(urlOrPath);
      return [path, skinId || this.formObject.skin_id];
    }
  }
};
