<template>
  <AppModalDefault
    width="wide"
    :title="$t('title')"
    :form-props="{
      ...formProps
    }"
    @close="close"
  >
    <template #body>
      <div class="ReviewSettingsInitialSetupResultDialog__summary">
        {{ $t('summary') }}
      </div>
      <AppResourceTable
        table-id="result-table"
        :table-name="$t('table.title')"
        :columns="columns"
        :rows="rows"
      />
      <AppButton
        button-style="blue"
        :label="$t('app.confirmed')"
        @click="close"
      />
    </template>
  </AppModalDefault>
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';
import WidgetType from '@/enums/WidgetType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import WidgetStyle from '@/enums/WidgetStyle';
import WidgetPageType from '@/enums/WidgetPageType';
import WidgetInsertType from '@/enums/WidgetInsertType';
import DefaultPositionCheckResult from '@/enums/DefaultPositionCheckResult';
export default {
  name: 'ReviewSettingsInitialSetupResultDialog',
  mixins: [DialogFormView],
  props: {
    widgetInitialInstallations: { type: Array, default: null }
  },
  computed: {
    columns() {
      return [
        { id: 'widget_id', label: this.$t('table.widget_id') },
        { id: 'widget_type', label: this.$t('table.widget_type') },
        { id: 'device_type', label: this.$t('table.device_type') },
        { id: 'widget_style', label: this.$t('table.widget_style') },
        { id: 'page_type', label: this.$t('table.page_type') },
        { id: 'result_message', label: this.$t('table.result_message') }
      ].map(column => ({ ...column, align: 'center' }));
    },
    rows() {
      return this.widgetInitialInstallations
        .filter(
          w =>
            w.installation_result !==
            DefaultPositionCheckResult.DEFAULT_SELECTOR_FOUND
        )
        .map(w => ({
          widget_id: w.id,
          widget_type: WidgetType.t(w.widget_type),
          device_type: WidgetDeviceType.t(w.widget_device_type),
          widget_style: this.widget_style(w.widget_style, w.widget_sub_style),
          page_type: WidgetPageType.t(w.smart_installations[0].page_type),
          result_message: this.result_message(
            w.installation_result,
            w.smart_installations[0].element_selector,
            w.smart_installations[0].install_position
          )
        }));
    }
  },
  methods: {
    widget_style(widget_style, widget_sub_style) {
      const val = widget_sub_style || widget_style;
      return val || val === 0 ? WidgetStyle.t(val) : '';
    },
    result_message(installation_result, element_selector, install_position) {
      if (
        installation_result ===
        DefaultPositionCheckResult.DEFAULT_SELECTOR_NOT_FOUND
      ) {
        return this.$t('result_message.default_selector_not_found', {
          default_selector: element_selector,
          insert_type: WidgetInsertType.key(install_position)
        });
      } else if (
        installation_result === DefaultPositionCheckResult.URL_NOT_FOUND
      ) {
        return this.$t('result_message.url_not_found');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

::v-deep {
  .result-table__result-message {
    flex: 4 0 0;
  }
}

.ReviewSettingsInitialSetupResultDialog__summary {
  @include text-content;
  text-align: center;
  background-color: $color-grey-15;
  border-radius: 3px;
  padding: 12px;
  margin-bottom: 20px;
}

.AppButton {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "title": "리뷰 위젯 설치 결과 안내",
  "table": {
    "title": "오류 내역",
    "widget_id": "위젯 ID",
    "widget_type": "위젯 종류",
    "device_type": "디바이스",
    "widget_style": "위젯 스타일",
    "page_type": "설치 페이지",
    "result_message": "오류"
  },
  "result_message": {
    "default_selector_not_found": "기본위치({default_selector} {insert_type}) 찾을 수 없음",
    "url_not_found": "페이지 찾을 수 없음"
  },
  "summary": "위젯 생성 후 설치 과정 중 오류가 발생했습니다. 설치 오류 위젯은 [위젯 설정 화면 > 각 위젯 Tab > 간편 설치] 기능으로 다시 설치 진행해주세요.",
  "confirm": "확인"
}
</i18n>
