<template>
  <div class="TheBreadcrumb">
    <ul class="TheBreadcrumb__list">
      <li
        v-for="breadcrumbItem in breadcrumbItems"
        :key="breadcrumbItem.id"
        class="TheBreadcrumb__item"
      >
        <AppInternalLink :to="breadcrumbItem.path">{{
          breadcrumbItem.label
        }}</AppInternalLink>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'TheBreadcrumb',
  computed: {
    ...mapState('pageBreadcrumb', ['pageBreadcrumbs']),
    ...mapGetters('menu', [
      'menuItemWithCurrentPathParams',
      'selectedSubMenuItemId',
      'selectedTabItemId'
    ]),
    breadcrumbItems() {
      const breadcrumbItems = [];

      let menuItemId = this.selectedTabItemId || this.selectedSubMenuItemId;
      while (menuItemId) {
        const menuItem = this.menuItemWithCurrentPathParams(menuItemId);
        breadcrumbItems.unshift(menuItem);
        menuItemId = menuItem.parentId;
      }

      return breadcrumbItems.concat(this.pageBreadcrumbs);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_transitions.scss';

.TheBreadcrumb__list {
  display: flex;
}

.TheBreadcrumb__item {
  flex: 0 0 auto;
  color: $color-button-text;
  @include transition-basic(color);

  & + .TheBreadcrumb__item::before {
    content: '/';
    padding-left: 6px;
    padding-right: 6px;
  }

  &:hover {
    color: $color-button-text-dark;
  }

  &:active {
    color: $color-button-text;
    transition: none;
  }

  &:last-child {
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
