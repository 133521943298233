var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.productManagement.name,"form-props":_vm.formProps},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
var disabled = ref.disabled;
return [(id === 'icon_ids')?[_c('AppTable',{class:[
          'AppForm__group-field',
          'AppForm__group-field--mt8',
          disabled ? 'AppForm__group-field--disabled' : ''
        ],attrs:{"columns":_vm.columns,"rows":_vm.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
        var column = ref.column;
        var row = ref.row;
        var value = ref.value;
return [(column.id === 'icons')?[_vm._l((value),function(icon){return _c('AppProductIcon',{key:icon,staticClass:"DisplayIconManagementFormDialog__icon",attrs:{"icon-id":icon}})}),(!value.length)?_c('span',[_vm._v(_vm._s(_vm.$t('form.icon_ids.icons.select_icon')))]):_vm._e()]:(column.id === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('form.icon_ids.button.select_icon'),"disabled":disabled},on:{"click":function($event){return _vm.openSelectIconDialog(row.id, row.icons)}}})]:_vm._e()]}}],null,true)})]:_vm._e()]}},{key:"group-sub-item",fn:function(ref){
        var group = ref.group;
        var value = ref.value;
return [(group.id === 'use_icon_overflow_management' && value)?[_c('AppButton',{attrs:{"label":_vm.$t('form.use_icon_overflow_management.button'),"disabled":group.disabled,"type":"external_link","to":_vm.pathWithBrandParams('/v2/product/icons')}})]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }