<template>
  <AppContainer class="AppDataSection">
    <AppDataItem :label="title" :gap="gap">
      <slot />
    </AppDataItem>
  </AppContainer>
</template>

<script>
export default {
  name: 'AppDataSection',
  props: {
    title: { type: String, required: true },
    gap: {
      type: String,
      default: 'default',
      validator: v => ['narrow', 'default', 'wide'].includes(v)
    }
  }
};
</script>

<style scoped>
.AppDataSection {
  position: relative;
  height: 100%;
}
</style>
