var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AnalyticsInsightNoticeView',{on:{"normalize":_vm.refresh}},[_c('AnalyticsInsightAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AnalyticsInsightMainSearchbar',_vm._g(_vm._b({},'AnalyticsInsightMainSearchbar',_vm.searchBarProps,false),_vm.searchBarEvents)),_c('AnalyticsInsightInfoBox',_vm._b({},'AnalyticsInsightInfoBox',{ categoriesCount: _vm.categoriesCount, keywordsCount: _vm.keywordsCount },false)),_c('AnalyticsInsightDashboardMetrics',_vm._b({},'AnalyticsInsightDashboardMetrics',{
        dateRange: _vm.dateRange,
        reviewsCountArray: _vm.reviewsCountArray,
        avgReviewTagsCountArray: _vm.avgReviewTagsCountArray,
        reviewTagsCountArray: _vm.reviewTagsCountArray,
        mediaReviewsRatioArray: _vm.mediaReviewsRatioArray
      },false)),_c('AnalyticsInsightDashboardKeywords',_vm._b({},'AnalyticsInsightDashboardKeywords',{ dateRange: _vm.dateRange, myBrandKeywordsDetails: _vm.myBrandKeywordsDetails, brandsKeywordsDetails: _vm.brandsKeywordsDetails },false)),_c('AnalyticsInsightDashboardProducts',_vm._b({},'AnalyticsInsightDashboardProducts',{
        dateRange: _vm.dateRange,
        popularProducts: _vm.popularProducts,
        riskyProducts: _vm.riskyProducts,
        potentialProducts: _vm.potentialProducts
      },false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }