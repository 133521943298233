<template>
  <AppResourceTable
    table-id="dispatch-results-table-realtime"
    :columns="columns"
    v-bind="{ rows, resources }"
    v-on="$listeners"
  >
    <template #cell="cellProps">
      <slot name="cell" v-bind="cellProps" />
    </template>
  </AppResourceTable>
</template>

<script>
export default {
  name: 'TargetReportsDispatchResultsTableRealtime',
  props: {
    rows: { type: Array, required: true },
    resources: { type: Object, required: true }
  },
  computed: {
    columns() {
      return [
        { id: 'id' },
        { id: 'send_at', type: 'datetime' },
        { id: 'campaign', align: 'left' },
        { id: 'checks_count', type: 'number' },
        { id: 'dispatch_status' },
        { id: 'actions', label: this.$t('app.actions') }
      ].map(c => ({
        ...c,
        label: c.label || this.$t(`table.${c.id}`)
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dispatch-results-table-realtime__id {
    flex-basis: 120px;
  }

  .dispatch-results-table-realtime__send-at {
    flex-basis: 170px;
  }

  .dispatch-results-table-realtime__campaign {
    flex-basis: 300px;
  }

  .dispatch-results-table-realtime__checks-count {
    flex-basis: 120px;
  }

  .dispatch-results-table-realtime__dispatch-status {
    flex-basis: 120px;
  }

  .dispatch-results-table-realtime__actions {
    flex-basis: 176px;
  }
}
</style>

<i18n locale="ko">
{
  "table": {
    "id": "발송 ID",
    "send_at": "발송 시작일",
    "campaign": "캠페인",
    "checks_count": "노출수",
    "dispatch_status": "상태"
  }
}
</i18n>
