<template>
  <div class="TargetCampaignStatsRecentReport">
    <div class="TargetCampaignStatsRecentReport__info">
      {{
        $t('effect_period_info', [
          TargetCampaignMediaType.effectPeriodByDays(
            campaign.campaign_media_type
          )
        ])
      }}
    </div>
    <AppGrid>
      <div class="AppGrid__col AppGrid__col--4 AppGrid__col--tablet-12">
        <TargetCampaignStatsSectionKpi
          v-bind="{
            isLoading,
            report: finalReports[0],
            campaign,
            samplingMethod
          }"
          @click-item="clickItem"
        />
      </div>
      <div class="AppGrid__col AppGrid__col--4 AppGrid__col--tablet-12">
        <TargetCampaignStatsSectionPurchase
          v-bind="{
            isLoading,
            reports: finalReports,
            campaign,
            samplingMethod
          }"
        />
      </div>
      <div class="AppGrid__col AppGrid__col--4 AppGrid__col--tablet-12">
        <TargetCampaignStatsSectionReach
          v-bind="{ isLoading, reports: finalReports, campaign }"
          @click-item="clickItem"
        />
      </div>
    </AppGrid>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';
import TargetCampaignStatsSectionKpi from './TargetCampaignStatsSectionKpi';
import TargetCampaignStatsSectionReach from './TargetCampaignStatsSectionReach';
import TargetCampaignStatsSectionPurchase from './TargetCampaignStatsSectionPurchase';

export default {
  name: 'TargetCampaignStatsRecentReport',
  components: {
    TargetCampaignStatsSectionKpi,
    TargetCampaignStatsSectionReach,
    TargetCampaignStatsSectionPurchase
  },
  props: {
    campaign: { type: Object, required: true },
    samplingMethod: { type: Number, required: true }
  },
  data: () => ({ isLoading: true, reports: [] }),
  computed: {
    TargetCampaignMediaType: () => TargetCampaignMediaType,
    finalReports() {
      return this.reports.map(r =>
        TargetSamplingMethod.report(this.samplingMethod, r)
      );
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaign.id}/reports_kpi`)
      .then(({ data }) => (this.reports = data.reports))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    clickItem({ column, label }) {
      this.openDialog([
        'TargetCampaignKpiDialog',
        {
          campaign: this.campaign,
          column: TargetSamplingMethod.column(this.samplingMethod, column),
          title: label,
          samplingMethod: this.samplingMethod
        }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetCampaignStatsRecentReport__info {
  @include text-caption;
  margin-bottom: 4px;
}
</style>

<i18n locale="ko">
{
  "effect_period_info": "* 기본 효율 수집 기간은 발송 후 {0}일 동안입니다."
}
</i18n>
