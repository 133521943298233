import { render, staticRenderFns } from "./AppDataSectionItem.vue?vue&type=template&id=885951c6&scoped=true&"
import script from "./AppDataSectionItem.vue?vue&type=script&lang=js&"
export * from "./AppDataSectionItem.vue?vue&type=script&lang=js&"
import style0 from "./AppDataSectionItem.vue?vue&type=style&index=0&id=885951c6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "885951c6",
  null
  
)

export default component.exports