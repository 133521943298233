<template>
  <AppInfoBox v-if="infoText" class="TargetCampaignShowInfoBox">
    <b>{{ infoText }}</b>
    <div
      v-if="subInfoText"
      v-tooltip="tooltip"
      class="TargetCampaignShowInfoBox__sub-info"
    >
      <span>{{ subInfoText }}</span>
      <AppSvgIcon
        name="icon-info-tooltip"
        class="TargetCampaignShowInfoBox__sub-info-icon"
      />
    </div>
    <a
      v-if="displayHelperMessage"
      class="TargetCampaignShowInfoBox__detail"
      @click="openCampaignInfoDialog"
      >{{ $t('app.detail') }}</a
    >
  </AppInfoBox>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'TargetCampaignShowInfoBox',
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    ...mapGetters('targetCampaigns', ['campaignTextSafe']),
    infoText() {
      return this.campaignTextSafe(this.campaign, 'info');
    },
    subInfoText() {
      return this.campaignTextSafe(this.campaign, 'sub_info');
    },
    displayHelperMessage() {
      return this.campaign.display_helper_message;
    },
    tooltip() {
      return this.campaignTextSafe(this.campaign, 'tooltip');
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openCampaignInfoDialog() {
      this.openDialog([
        'TargetCampaignShowInfoDetailDialog',
        {
          message: this.campaign.campaign_helper_message,
          campaignName: this.campaign.name
        }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetCampaignShowInfoBox {
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.TargetCampaignShowInfoBox__sub-info {
  @include text-caption-dark;
  display: inline-block;
  margin-left: 8px;
}

.TargetCampaignShowInfoBox__sub-info-icon {
  margin-left: 4px;
  position: relative;
  top: 2px;
}

.TargetCampaignShowInfoBox__detail {
  margin-left: 8px;
  font-size: 12px;
  color: #777575;
  text-decoration: underline;
}
</style>
