<template>
  <AppModalDefault :title="$t('title')" :width="width" @close="close">
    <template #body>
      <AppImage :src="positionPreviewImageUrl" />
    </template>
    <template #foot>
      <AppButton :label="$t('close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';
import WidgetPageType from '@/enums/WidgetPageType';

export default {
  name: 'ReviewDashboardRequestInstallationPositionPreviewDialog',
  mixins: [DialogView],
  props: {
    isMobile: { type: Boolean, default: false },
    pageType: { type: Number, default: 1 }
  },
  computed: {
    width() {
      return this.isMobile ? 'small' : 'wide';
    },
    positionPreviewImageUrl() {
      return `https://assets.cre.ma/m/admin/v2/position_preview_${this.device}_${this.widgetPageTypeKey}.png`;
    },
    device() {
      return this.isMobile ? 'mobile' : 'pc';
    },
    widgetPageTypeKey() {
      return WidgetPageType.toKey(this.pageType).toLowerCase();
    }
  }
};
</script>

<i18n locale="ko">
  {
    "title": "설치 위치 안내",
    "close": "닫기"
  }
</i18n>
