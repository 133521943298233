var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.$t('sub_title', { count: _vm.reviews.length }),"form-props":Object.assign({}, _vm.formProps,
    {submitLabel: _vm.$t('submit_label')})},scopedSlots:_vm._u([{key:"group",fn:function(ref){
  var id = ref.id;
  var inputId = ref.inputId;
  var type = ref.type;
  var digits = ref.digits;
  var error = ref.error;
return [(id === 'mileage_amount')?_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectRadio',{attrs:{"options":[
          { label: _vm.$t('use_calculated'), value: true },
          { value: false }
        ]},on:{"change":function($event){return _vm.validateField(id)}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
        var value = ref.value;
        var checked = ref.checked;
return [(value === false && type === 'currency')?_c('AppCurrencyInput',{staticClass:"app-input-inline",attrs:{"invalid":!!error,"disabled":!checked,"digits":digits},on:{"change":function($event){return _vm.validateField(id)}},model:{value:(_vm.batchMileage.mileage_amount),callback:function ($$v) {_vm.$set(_vm.batchMileage, "mileage_amount", $$v)},expression:"batchMileage.mileage_amount"}}):(value === false && type === 'number')?_c('AppNumberInput',{staticClass:"app-input-inline",attrs:{"invalid":!!error,"disabled":!checked,"digits":digits},on:{"change":function($event){return _vm.validateField(id)}},model:{value:(_vm.batchMileage.mileage_amount),callback:function ($$v) {_vm.$set(_vm.batchMileage, "mileage_amount", $$v)},expression:"batchMileage.mileage_amount"}}):_vm._e()]}}],null,true),model:{value:(_vm.batchMileage.use_calculated),callback:function ($$v) {_vm.$set(_vm.batchMileage, "use_calculated", $$v)},expression:"batchMileage.use_calculated"}})],1):(id === 'mileage_message')?[_c('div',{staticClass:"AppForm__group-field AppForm__group-field--right"},[_c('AppCheckbox',{attrs:{"label":_vm.$t('save_mileage_message')},model:{value:(_vm.batchMileage.save_mileage_message),callback:function ($$v) {_vm.$set(_vm.batchMileage, "save_mileage_message", $$v)},expression:"batchMileage.save_mileage_message"}})],1),_c('div',{staticClass:"AppForm__group-field"},[_c('AppTextInput',{directives:[{name:"focus",rawName:"v-focus"}],attrs:{"id":inputId,"invalid":!!error},on:{"change":function($event){return _vm.validateField(id)}},model:{value:(_vm.batchMileage.mileage_message),callback:function ($$v) {_vm.$set(_vm.batchMileage, "mileage_message", $$v)},expression:"batchMileage.mileage_message"}})],1)]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }