<template>
  <div
    v-tooltip="tooltip"
    class="DisplayCornerPreviewSelectbarButton"
    @click="click"
  >
    <AppSvgIcon
      :class="[
        'DisplayCornerPreviewSelectbarButton__icon',
        { 'DisplayCornerPreviewSelectbarButton__icon--disabled': disabled }
      ]"
      :name="icon"
    />
    <span
      :class="[
        'DisplayCornerPreviewSelectbarButton__label',
        { 'DisplayCornerPreviewSelectbarButton__label--disabled': disabled }
      ]"
      >{{ label }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'DisplayCornerPreviewSelectbarButton',
  props: {
    icon: { type: String, required: true },
    label: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    tooltip: { type: String, default: null }
  },
  methods: {
    click() {
      if (this.disabled) return;

      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.DisplayCornerPreviewSelectbarButton {
  display: flex;
  cursor: pointer;

  & + & {
    margin-left: 24px;
  }
}

.DisplayCornerPreviewSelectbarButton__icon {
  margin: 2px 0;
  fill: $color-grey-80;

  &--disabled {
    fill: $color-disable-text;
  }
}

.DisplayCornerPreviewSelectbarButton__label {
  margin-left: 8px;

  &--disabled {
    color: $color-disable-text;
  }
}
</style>
