<template>
  <div>
    <div v-for="textStyle in TEXT_STYLES" :key="textStyle" class="ui-section">
      <div class="ui-section__title">
        <label for="basic">text-{{ textStyle }}</label>
      </div>
      <div class="ui-section__item">
        <AppText
          :class="`UiText__${textStyle}`"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse auctor condimentum urna. Nam condimentum ultrices sagittis. Donec viverra tortor nisi, tincidunt sodales neque lacinia at."
        />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="bold">Bold</label>
      </div>
      <div class="ui-section__item">
        <AppText text="This is **bold**, right?" />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="new_lines">New lines</label>
      </div>
      <div class="ui-section__item">
        <AppText :text="TEXT_NEW_LINE" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiText',
  data() {
    return {
      TEXT_STYLES: [
        'title',
        'modal-title',
        'modal-title-light',
        'sub-title',
        'sub-title-light',
        'main-navigation',
        'sub-navigation',
        'sub-navigation-light',
        'utility-navigation',
        'content',
        'content-light',
        'content-emphasis',
        'placeholder',
        'label',
        'table-head',
        'table-sub-head',
        'tab',
        'tab-small',
        'button',
        'caption',
        'caption-dark',
        'caption-darker',
        'caption-small',
        'caption-small-dark',
        'caption-small-darker',
        'caption-red',
        'caption-tiny-dark',
        'tag'
      ],
      TEXT_NEW_LINE: 'Line1\nLine2\n\nLine4\n\n\nLine7'
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.UiText__title {
  @include text-title;
}

.UiText__modal-title {
  @include text-modal-title;
}

.UiText__modal-title-light {
  @include text-modal-title-light;
}

.UiText__sub-title {
  @include text-sub-title;
}

.UiText__sub-title-light {
  @include text-sub-title-light;
}

.UiText__main-navigation {
  @include text-main-navigation;
}

.UiText__sub-navigation {
  @include text-sub-navigation;
}

.UiText__sub-navigation-light {
  @include text-sub-navigation-light;
}

.UiText__utility-navigation {
  @include text-utility-navigation;
}

.UiText__content {
  @include text-content;
}

.UiText__content-light {
  @include text-content-light;
}

.UiText__content-emphasis {
  @include text-content-emphasis;
}

.UiText__placeholder {
  @include text-placeholder;
}

.UiText__label {
  @include text-label;
}

.UiText__table-head {
  @include text-table-head;
}

.UiText__table-sub-head {
  @include text-table-sub-head;
}

.UiText__tab {
  @include text-tab;
}

.UiText__tab-small {
  @include text-tab-small;
}

.UiText__button {
  @include text-button;
}

.UiText__caption {
  @include text-caption;
}

.UiText__caption-dark {
  @include text-caption-dark;
}

.UiText__caption-darker {
  @include text-caption-darker;
}

.UiText__caption-small {
  @include text-caption-small;
}

.UiText__caption-small-dark {
  @include text-caption-small-dark;
}

.UiText__caption-small-darker {
  @include text-caption-small-darker;
}

.UiText__caption-red {
  @include text-caption-red;
}

.UiText__caption-tiny-dark {
  @include text-caption-tiny-dark;
}

.UiText__tag {
  @include text-tag;
}
</style>
