<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{
      ...formProps,
      objectId: 'review_transaction',
      detectFormDataChange: false,
      submitLabel: $t('app.save'),
      submittingLabel: $t('app.saving')
    }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="review_transaction[review_event_type]"
      :value="ReviewTransactionType.SCHEDULED_TERMINATION"
    />
    <input
      type="hidden"
      name="review_transaction[end_at]"
      :value="reviewEndAt"
    />
    <input
      type="hidden"
      name="review_transaction[description]"
      :value="description"
    />
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import moment from 'moment';
import DialogFormView from '@/mixins/DialogFormView';
import ReviewTransactionType from '@/enums/ReviewTransactionType';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'ThePaymentHistoryTerminationReviewDialog',
  mixins: [DialogFormView],
  computed: {
    ...mapState('session', ['reviewStatus']),
    ReviewTransactionType() {
      return ReviewTransactionType;
    },
    formSections() {
      return [
        {
          id: 'default',
          groups: [
            {
              id: 'description',
              name: null,
              label: this.$t('review_transaction.description'),
              type: 'text',
              placeholder: this.$t('description_placeholder')
            },
            {
              id: 'end_date',
              name: null,
              label: this.$t('review_transaction.end_date'),
              type: 'date',
              minDate: moment().toVal(),
              maxDate: moment(this.reviewStatus.review_expires_at).toVal(),
              groupDescription: this.$t('end_date_description'),
              hint: this.$t('end_date_hint', [
                this.formatDate(this.reviewStatus.review_expires_at)
              ]),
              validate: ['required']
            }
          ]
        }
      ];
    },
    description() {
      const prefix =
        this.formObject.description || this.$t('description_placeholder');

      const postfix = this.$t('description_postfix', [
        this.formatDate(this.reviewStatus.review_expires_at),
        this.formatDate(this.formObject.end_date)
      ]);
      return `${prefix}${postfix}`;
    },
    reviewEndAt() {
      return moment(this.formObject.end_date)
        .hour(23)
        .minute(59)
        .second(59);
    }
  },
  methods: {
    ...mapMutations('session', ['SET_REVIEW_STATUS']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/payment_history/review_transactions', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          if (data.review_status) this.SET_REVIEW_STATUS(data.review_status);
          this.eventBus.$emit('update');
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "중도 환불 예약",
  "sub_title": "리뷰 이용내역",
  "description_placeholder": "중도 환불 예약",
  "description_postfix": " (기존 만료일: {0} → 변경 만료일: {1})",
  "end_date_hint": "기존 만료일: {0}",
  "end_date_description": "중도 환불의 경우 만료일이 이탈일로 설정이 되며, 설정된 환불일 이후에 이탈작업이 실행됩니다.",
   "review_transaction": {
    "description": "설명 입력",
    "end_date": "환불일 설정"
  }
}
</i18n>
