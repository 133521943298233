<template>
  <div class="AppCopyableTextarea">
    <div class="AppCopyableTextarea__textarea-wrapper">
      <AppTextarea :value="value" type="text" readonly :rows="1" />
    </div>
    <div class="AppCopyableTextarea__button-wrapper">
      <AppButtonCopyable :value="value" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCopyableTextarea',
  props: { value: { type: String, required: true } }
};
</script>

<style lang="scss" scoped>
$button-width: 56px;
$button-margin: 8px;

.AppCopyableTextarea__textarea-wrapper {
  width: calc(100% - #{$button-width} - #{$button-margin});
  display: inline-block;
}

.AppCopyableTextarea__button-wrapper {
  width: $button-width;
  margin-left: $button-margin;
  display: inline-block;
  vertical-align: top;
}
</style>
