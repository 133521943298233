export default [
  {
    id: 'channel_settings_basic',
    menuItemId: 'channel_settings_basic',
    sections: [
      {
        groups: [
          { id: 'excluding_category_ids' },
          { id: 'in_categories' },
          { id: 'excluding_product_keywords' },
          { id: 'product_categories_csv' }
        ]
      }
    ]
  },
  {
    id: 'channel_settings_admin',
    menuItemId: 'channel_settings_admin',
    sections: [
      {
        groups: [
          { id: 'launch_ad_menu' },
          { id: 'skip_product_image_download' }
        ]
      }
    ]
  }
];
