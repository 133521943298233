<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="templateTypeName"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId: 'product_management',
      focusGroupId: 'name'
    }"
    width="default"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="product_management[template_type]"
      :value="templateType"
    />
  </AppModalForm>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import DisplayProductManagementTemplateType from '@/enums/DisplayProductManagementTemplateType';
import DialogFormView from '@/mixins/DialogFormView';
import NameFormView from '@/mixins/NameFormView';

export default {
  name: 'DisplayProductManagementCreateDialog',
  mixins: [DialogFormView, NameFormView],
  props: { templateType: { type: Number, required: true } },
  data: () => ({ isLoading: true, orgFormObject: { name: '' } }),
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    ...mapGetters('displayProductManagement', [
      'isProductManagementNameDuplicated'
    ]),
    templateTypeName() {
      return DisplayProductManagementTemplateType.t(this.templateType);
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              label: this.$t('display_product_management_name'),
              type: 'text',
              validate: [
                'required',
                { rule: 'max_length', limit: 30 },
                {
                  rule: v => this.isNameNotDuplicated(v),
                  errorMessage: this.$t(
                    'target_errors.duplicated_product_management_name'
                  )
                }
              ]
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get('display/product_managements/new', {
        params: { template_type_name: this.templateTypeName }
      })
      .then(({ data }) => {
        this.formObject.name = data.default_name;
        this.existingNames = data.created_names;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('display/product_managements', formData)
        .then(({ data }) => {
          this.close(true);
          this.createToast(this.$t('created'));

          const { product_management } = data;
          this.$router.push(
            this.pathWithBrandParams(
              `/display/product-managements/${product_management.id}`
            )
          );
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "상품 관리 규칙 추가",
  "display_product_management_name": "상품 관리 규칙 이름",
  "created": "상품 관리 규칙을 생성했습니다. 설정을 완료해 주세요."
}
</i18n>
