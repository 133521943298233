<template>
  <AppModalDefault :title="$t('title')" :sub-title="abTest.name" @close="close">
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <div class="AnalyticsAbTestsTargetStatsDialog__summary">
          <div class="AnalyticsAbTestsTargetStatsDialog__summary-section">
            <div class="AnalyticsAbTestsTargetStatsDialog__title">
              {{ $t('total_sent_cohorts_count') }}
            </div>
            <div class="AnalyticsAbTestsTargetStatsDialog__body">
              {{ $t('sent_count', [reports.total_sent_cohorts_count]) }}
            </div>
          </div>
          <div class="AnalyticsAbTestsTargetStatsDialog__summary-section">
            <div class="AnalyticsAbTestsTargetStatsDialog__title">
              {{ $t('total_targeted_users_count') }}
            </div>
            <div class="AnalyticsAbTestsTargetStatsDialog__body">
              {{
                $t('sent_targeted_users_count', [
                  reports.total_targeted_users_count,
                  reports.total_sent_users_count
                ])
              }}
            </div>
          </div>
          <div class="AnalyticsAbTestsTargetStatsDialog__summary-section">
            <div class="AnalyticsAbTestsTargetStatsDialog__title">
              {{ $t('targeted_users_count_by_group') }}
            </div>
            <div
              v-for="(data, index) in reports.ab_test_reports"
              :key="index"
              class="AnalyticsAbTestsTargetStatsDialog__body"
            >
              {{ $t('group', [index + 1]) }} :
              {{
                $t('sent_targeted_users_count_by_group', [
                  data.total_targeted_users_count,
                  sentPercentage(
                    data.total_targeted_users_count,
                    reports.total_targeted_users_count
                  ),
                  data.total_sent_users_count,
                  sentPercentage(
                    data.total_sent_users_count,
                    reports.total_sent_users_count
                  )
                ])
              }}
            </div>
          </div>
        </div>
        <AppResourceTable
          table-id="ab-test-stats-table"
          :columns="COLUMNS"
          :rows="reports_group_by_date"
        >
          <template #cell="{ row, column }">
            <div v-if="column === 'date'">
              {{ formatDate(row[0].date) }}
            </div>
            <div v-else-if="column === 'targeted_users_count'">
              <div v-for="(data, index) in row" :key="index">
                {{ $t('group', [index + 1]) }} :
                {{
                  $t('sent_targeted_users_count', [
                    data.targeted_users_count,
                    data.sent_users_count
                  ])
                }}
              </div>
            </div>
            <div v-else-if="column === 'detail'">
              <div v-for="(data, index) in row" :key="index">
                <AppButton
                  button-style="mango-outline"
                  :label="$t('button.group_detail', [index + 1])"
                  @click="openAbTestDetailDialog(data, index + 1, abTest)"
                />
              </div>
            </div>
          </template>
        </AppResourceTable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'AnalyticsAbTestsTargetStatsDialog',
  mixins: [DialogView],
  props: {
    abTest: { type: Object, required: true }
  },
  data() {
    return {
      isLoading: false,
      reports: {},
      reports_group_by_date: [],
      COLUMNS: [
        { id: 'date', label: this.$t('table.column.date') },
        {
          id: 'targeted_users_count',
          label: this.$t('table.column.targeted_users_count')
        },
        { id: 'detail', label: this.$t('table.column.detail') }
      ]
    };
  },
  mounted() {
    this.isLoading = true;
    api
      .get(`/analytics/ab_tests/${this.abTest.id}/target_reports`)
      .then(({ data }) => {
        const reports = data.reports;
        this.reports = reports;
        const reports_group_by_date = _.zip(
          ...reports.ab_test_reports.map(v => v.reports)
        );
        this.reports_group_by_date = reports_group_by_date.filter(
          v => !_.isEmpty(v[0])
        );
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    sentPercentage(totalUsersCountForGroup, totalUsersCount) {
      if (totalUsersCount == 0) {
        return 0;
      } else {
        return ((totalUsersCountForGroup * 100) / totalUsersCount).toFixed(1);
      }
    },
    openAbTestDetailDialog(report, groupIndex, abTest) {
      this.openDialog([
        'AnalyticsAbTestsTargetStatsDetailDialog',
        { cohortId: report.cohort_id, groupIndex, abTest }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
.AnalyticsAbTestsTargetStatsDialog__summary-section {
  margin-bottom: 20px;
}

.AnalyticsAbTestsTargetStatsDialog__title {
  font-weight: bold;
}
</style>

<i18n locale="ko">
{
  "title": "A/B 테스트 통계",
  "total_sent_cohorts_count": "전체 발송 횟수",
  "sent_count": "{0} 건",
  "targeted_users_count": "{0} 명",
  "sent_targeted_users_count": "({0} / {1}) 명",
  "total_targeted_users_count": "전체 발송 인원수 (실제 발송 대상 / 전체 발송 대상)",
  "targeted_users_count_by_group": "그룹별 발송 인원수",
  "sent_targeted_users_count_by_group": "{0} ({1}%) / {2} ({3}%) 명",
  "group": "그룹 {0}",
  "table": {
    "column": {
      "date": "날짜",
      "targeted_users_count": "발송 인원수 (실제 발송 대상 / 전체 발송 대상)",
      "detail": "상세보기"
    }
  },
  "button": {
    "group_detail": "그룹 {0} 상세보기"
  }
}
</i18n>
