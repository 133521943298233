<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="productName"
    width="middle"
    @close="close"
  >
    <template slot="body">
      <AppAjaxContent :is-loading="isLoading">
        <AppContainer>
          <div class="ReviewReviewsPinDialog__description">
            {{ $t('description', { reviews_count: pinnedReviews.length }) }}
          </div>
        </AppContainer>
        <AppResourceTable
          table-id="review-reviews-pin-table"
          :event-bus="tableEventBus"
          :columns="COLUMNS"
          :rows="rows"
          :no-data="$t('no_data')"
          @select-rows="selectRows"
        >
          <template #cell="{ row, column }">
            <ReviewReviewsDateCell v-if="column === 'date'" :review="row" />
            <ReviewReviewsCustomerCell
              v-else-if="column === 'customer'"
              :review="row"
            />
            <ReviewReviewsReviewCell
              v-else-if="column === 'review'"
              :review="row"
              :clickable="false"
            />
            <ReviewReviewsStatusCell
              v-else-if="column === 'status'"
              :review="row"
            />
            <template v-else-if="column === 'actions'">
              <AppButtonDraggable
                :drag-text="row.message"
                :disabled="selectedReviews.length > 0"
                @sort="sort"
              />
              <AppButton :label="$t('unpin')" @click="unpin(row.id)" />
            </template>
          </template>
        </AppResourceTable>
      </AppAjaxContent>
    </template>
    <template slot="foot">
      <ReviewReviewsPinDialogSelectBar
        v-if="selectedReviews.length > 0"
        class="ReviewReviewsPinDialog__select-bar"
        :product-name="productName"
        :selected-reviews="selectedReviews"
        @turn-off="unselectRows"
      />
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import { arrayMoveIndex } from '@/lib/array';
import DialogView from '@/mixins/DialogView';
import globalEventBus from '@/lib/globalEventBus';

export default {
  name: 'ReviewReviewsPinDialog',
  mixins: [DialogView],
  props: {
    productName: {
      type: String,
      required: true
    },
    productId: {
      type: Number,
      default: null
    },
    orgReviews: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'row_select', type: 'row_select' },
        { id: 'index1', label: this.$t('index1') },
        { id: 'date', label: this.$t('date') },
        { id: 'customer', label: this.$t('customer') },
        { id: 'review', label: this.$t('review') },
        { id: 'status', label: this.$t('app.status') },
        { id: 'actions', label: this.$t('app.actions') }
      ],
      isLoading: false,
      selectedReviews: [],
      tableEventBus: globalEventBus
    };
  },
  computed: {
    ...mapGetters('reviewPinnedReviews', ['pinnedReviews']),
    rows() {
      return this.pinnedReviews.map((r, i) => ({ ...r, index1: i + 1 }));
    }
  },
  mounted() {
    this.SET_PINNED_REVIEWS(
      _.cloneDeep(this.orgReviews).map((r, i) => ({ ...r, idx: i }))
    );

    if (this.orgReviews.length || !this.productId) return;

    this.isLoading = true;
    api
      .get('/review/products/pinned_reviews', {
        params: {
          id: this.productId
        }
      })
      .then(({ data }) => {
        this.SET_PINNED_REVIEWS(
          data.pinned_reviews.map((r, i) => ({ ...r, idx: i }))
        );
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('review', ['unpinReview']),
    ...mapMutations('reviewPinnedReviews', [
      'UNPIN_REVIEW',
      'SET_PINNED_REVIEWS'
    ]),
    unpin(reviewId) {
      this.unpinReview(reviewId).then(() => {
        this.UNPIN_REVIEW(reviewId);
      });
    },
    sort({ srcIndex, dstIndex }) {
      const maxPosition = _.chain(this.pinnedReviews)
        .map('pin_position')
        .max()
        .value();
      const sortedPinnedReviews = arrayMoveIndex(
        this.pinnedReviews,
        srcIndex,
        dstIndex
      ).map((r, i) => ({ ...r, pin_position: maxPosition - i, idx: i }));
      const reorderedReviews = sortedPinnedReviews.slice(
        _.min([srcIndex, dstIndex]),
        _.max([srcIndex, dstIndex]) + 1
      );
      api
        .patch('/review/reviews/sort_pin', {
          id_to_pin_position: reorderedReviews.map(r => [r.id, r.pin_position])
        })
        .then(() => {
          this.SET_PINNED_REVIEWS(sortedPinnedReviews);
        });
    },
    selectRows(rows) {
      this.selectedReviews = rows;
    },
    unselectRows() {
      this.tableEventBus.$emit('select-rows', false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/vars/_z-indexes.scss';

.ReviewReviewsPinDialog__description {
  white-space: pre-wrap;
  text-align: center;
}

::v-deep {
  .review-reviews-pin-table__row-select {
    flex: 1 0 30px;
  }

  .review-reviews-pin-table__index-1 {
    flex: 1 0 40px;
  }

  .review-reviews-pin-table__date {
    flex: 1 0 110px;
  }

  .review-reviews-pin-table__customer {
    flex: 1 0 113px;
  }

  .review-reviews-pin-table__review {
    flex: 1 0 180px;
  }

  .review-reviews-pin-table__status {
    flex: 1 0 82px;
  }

  .review-reviews-pin-table__actions {
    flex: 1 0 159px;
  }

  .AppButton__button {
    &:disabled {
      border-color: $color-grey-25;
      color: $color-disable-text;
    }
  }
}

.ReviewReviewsPinDialog__select-bar {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
  z-index: $z-index-tooltip-modal;
}
</style>

<i18n locale="ko">
{
  "title": "추천 리뷰 관리",
  "description": "해당 상품에 추천 리뷰로 설정한 리뷰가 {reviews_count}개 있습니다.\n위젯에서 추천순으로 정렬 시, 목록의 순서대로 노출됩니다.\n리뷰 앞에는 [쇼핑몰 추천 리뷰]가 자동으로 표시됩니다.",
  "index1": "순서",
  "date": "작성일",
  "customer": "고객정보",
  "review": "리뷰 상세 내용",
  "unpin": "추천 해제",
  "no_data": "더 이상 고정된 리뷰가 없습니다."
}
</i18n>
<i18n locale="en">
{
  "title": "Manage pinned review",
  "description": "There are {reviews_count} reviews set as pinned reviews for the product.\nWhen sorted in order of recommendation on the widget, they will be exposed in the order of the list.\n[Shopping mall recommended reviews] are automatically displayed before the reviews.",
  "index1": "Index",
  "date": "Date",
  "customer": "Customer",
  "review": "Message",
  "unpin": "Unpin",
  "no_data": "No pinned reviews."
}
</i18n>
