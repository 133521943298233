var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppButton',{attrs:{"label":_vm.$t('button.add_unit'),"disabled":_vm.cannotAddUnit,"tooltip":_vm.cannotAddUnit ? _vm.$t('tooltip.add_unit') : '',"bubble-message":_vm.units.length > 0 ? '' : _vm.$t('bubble.add_unit')},on:{"click":_vm.addUnit}}),_c('AppTable',{staticClass:"DisplayProductManagementUnits__table",attrs:{"columns":_vm.columns,"rows":_vm.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
var rowIndex = ref.rowIndex;
return [(column.id === 'filter')?[_c('div',{staticClass:"DisplayProductManagementUnits__filter"},[(rowIndex > 0)?_c('span',{staticClass:"DisplayProductManagementUnits__filter-prefix"},[_vm._v(_vm._s(_vm.$t('filter.or')))]):_vm._e(),_c('DisplayUnitFilterCell',_vm._b({staticClass:"DisplayProductManagementUnits__filter-inputs",on:{"change":function($event){return _vm.UPDATE_UNIT({ unit: $event, index: rowIndex })},"validate-unit-field":function($event){return _vm.$emit('validate-unit-field', {
                column: $event,
                index: rowIndex
              })}}},'DisplayUnitFilterCell',row.filterCellProps,false))],1)]:(column.id === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('app.delete'),"disabled":row.buttonDisabled,"tooltip":row.buttonDisabled ? _vm.disabledTooltip : ''},on:{"click":function($event){return _vm.DELETE_UNIT(rowIndex)}}}),_vm._l(([
            'id',
            'recent_days',
            'filter_type',
            'stock_count',
            'stock_compare_type',
            'product_option_type_id',
            'max_products'
          ]),function(key,i){return _c('input',{key:("key-" + i),attrs:{"type":"hidden","name":("product_management[units][][" + key + "]")},domProps:{"value":row.unit[key]}})}),(row.unit.product_option_value)?_c('input',{attrs:{"type":"hidden","name":"product_management[units][][product_option_value_id]"},domProps:{"value":row.unit.product_option_value.id}}):_vm._e()]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }