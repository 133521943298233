<template>
  <div class="KakaoStyleSettings">
    <AppSummaryBox v-if="isEnabled">
      <template #title>
        <div v-text="$t('info.title')" />
      </template>
      <template #default>
        <AppMarked :text="$t('info.message')" />
      </template>
    </AppSummaryBox>
    <AppAjaxContent :is-loading="isLoading">
      <template #default>
        <KakaoStyleSettingsForm class="KakaoStyleSettings__form" />
        <KakaoStyleApiKeyForm
          class="KakaoStyleSettings__form"
          :access-key="accessKey"
          :secret-key="secretKey"
        />
      </template>
    </AppAjaxContent>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/lib/api';
import KakaoStyleReviewImportStatus from '@/enums/KakaoStyleReviewImportStatus';
import KakaoStyleSettingsForm from './KakaoStyleSettingsForm';
import KakaoStyleApiKeyForm from './KakaoStyleApiKeyForm';

export default {
  name: 'KakaoStyleSettings',
  components: { KakaoStyleSettingsForm, KakaoStyleApiKeyForm },
  data() {
    return { isLoading: true, accessKey: '', secretKey: '' };
  },
  computed: {
    ...mapState('kakaoStyle', ['reviewImportStatus']),
    isEnabled() {
      return this.reviewImportStatus === KakaoStyleReviewImportStatus.ENABLED;
    }
  },
  mounted() {
    api
      .get('/kakao_style/api_keys')
      .then(({ data }) => {
        this.accessKey = data.access_key;
        this.secretKey = data.secret_key;
      })
      .finally(() => (this.isLoading = false));
  }
};
</script>

<style scoped>
.KakaoStyleSettings__form {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "info": {
    "title": "카카오스타일(지그재그) 리뷰 연동 신청이 완료되었습니다. 🎉",
    "message": "카카오스타일 파트너센터에서 자사몰 상품번호를 등록하면 익일부터 리뷰 연동이 실행됩니다.\n\n자사몰 상품등록 안내는 [\\[카카오스타일: 크리마 리뷰 연동 서비스 안내\\] 문서](https://crema.notion.site/1b4d3d91a83146a7aa4997f57e2813c6)를 참고해주세요."
  }
}
</i18n>
