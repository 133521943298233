var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalFormLivePreview',_vm._g({attrs:{"title":_vm.title,"sub-title":_vm.$t('subtitle'),"form-props":Object.assign({}, _vm.formProps,
    {objectId: 'review_campaign_message'}),"width":"440px","is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoadingPreview}},[_c('BizMessageTemplatePreview',{attrs:{"template":_vm.template}})],1)]},proxy:true},{key:"group",fn:function(group){return [_c(_vm.reviewMessageFormName,_vm._b({tag:"Component",on:{"change-template":_vm.onChangeTemplate,"blur":function($event){return _vm.validateField($event)},"change":function($event){return _vm.validateField($event)}}},'Component',{
        group: group,
        message: _vm.message,
        mileageExiryDays: _vm.mileageExiryDays,
        nthStepReviewMileage: _vm.nthStepReviewMileage,
        messageNthStep: _vm.messageNthStep
      },false))]}}])},_vm.formEvents),[_c('input',{attrs:{"type":"hidden","name":"message[campaign_type]"},domProps:{"value":_vm.campaignType}}),_c('input',{attrs:{"type":"hidden","name":"message[biz_message_template_type]"},domProps:{"value":_vm.formObject.biz_message_template_type}}),_c('input',{attrs:{"type":"hidden","name":"message[biz_message_template_id]"},domProps:{"value":_vm.formObject.biz_message_template_id}})])}
var staticRenderFns = []

export { render, staticRenderFns }