<template>
  <div :class="['AppCarousel', `AppCarousel--${type}`]">
    <Flicking
      class="AppCarousel__viewport"
      :plugins="flickingPlugins"
      :options="defaultOptions"
    >
      <slot />
      <div
        slot="viewport"
        :class="[
          'AppCarousel__pagination',
          showPagination ? null : 'AppCarousel__pagination--hidden'
        ]"
      />
    </Flicking>
  </div>
</template>

<script>
import { AutoPlay, Pagination } from '@egjs/flicking-plugins';

export default {
  name: 'AppCarousel',
  props: {
    showPagination: { type: Boolean, default: true },
    type: {
      type: String,
      default: 'default',
      validator: v => ['default', 'notice'].includes(v)
    }
  },
  data() {
    return {
      defaultOptions: {
        align: 'prev',
        circular: true,
        panelsPerView: 1,
        moveType: 'strict'
      },
      flickingPlugins: [
        new AutoPlay({ duration: 10000, direction: 'NEXT', stopOnHover: true }),
        new Pagination({
          type: 'bullet',
          selector: '.AppCarousel__pagination',
          classPrefix: 'AppCarousel__pagination'
        })
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/vars/_z-indexes.scss';

.AppCarousel--notice {
  z-index: $z-index-mini-notice;
}

.AppCarousel__pagination {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 22px;
  text-align: center;
  font-size: 0;

  &--hidden {
    display: none;
  }
}

::v-deep {
  .flicking-camera {
    z-index: auto;
  }

  .AppCarousel__pagination-bullet {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 8px 0;
    border-radius: 50%;
    background-color: $color-grey-32;
    cursor: pointer;
    opacity: 0.4;
  }

  .AppCarousel__pagination-bullet + .AppCarousel__pagination-bullet {
    margin-left: 4px;
  }

  .AppCarousel__pagination-bullet-active {
    background-color: $color-grey-32;
    opacity: 1;
  }
}
</style>
