<template>
  <AppModalDefault :title="$t('title')" :sub-title="subtitle" @close="close">
    <template #body>
      <template v-for="(material, i) in materials">
        <div :key="i" class="TheBrandMaterialHistoryDialog__item">
          <TheBrandMaterial
            :material-type="type"
            :material="material"
            hide-status-badge
          />
        </div>
      </template>
    </template>
    <template #foot>
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';
import TheBrandMaterial from '@/layouts/TheBrandMaterial';

export default {
  name: 'TheBrandMaterialHistoryDialog',
  components: { TheBrandMaterial },
  mixins: [DialogView],
  props: {
    type: { type: String, default: null }
  },
  data: () => ({ materials: [] }),
  computed: {
    subtitle() {
      return this.type === 'corporate_cert'
        ? this.$t('corporate_cert')
        : this.type === 'telecom_cert'
        ? this.$t('telecom_cert')
        : '';
    }
  },
  created() {
    const url =
      this.type === 'corporate_cert'
        ? '/brand_corporate_number_change_requests'
        : this.type === 'telecom_cert'
        ? '/brand_phone_change_requests'
        : '';

    api.get(url).then(({ data }) => {
      this.materials =
        data.corporate_number_change_requests || data.phone_change_requests;
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheBrandMaterialHistoryDialog__item {
  & + & {
    border-top: 1px solid $color-layout-section;
    margin-top: 30px;
    padding-top: 33px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "자료 변경 이력",
  "corporate_cert": "세금계산서 발급 자료",
  "telecom_cert": "통신서비스 이용증명원(가입증명서)"
}
</i18n>
