var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppRadioList',{class:_vm.enableSelectAll && _vm.enableIndent
        ? "AppMultipleSelectCheckbox__indent"
        : null,attrs:{"items":_vm.checkboxes,"inline":_vm.inline},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var option = ref.item;
        var index = ref.index;
return [(option.selectAll)?_c('AppCheckboxPartial',{attrs:{"check-status":_vm.checkStatus,"label":_vm.selectAllLabel,"input-style":null},on:{"change":function($event){return _vm.toggleAll($event)}},scopedSlots:_vm._u([(_vm.$scopedSlots['partial-label'])?{key:"label",fn:function(labelProps){return [_vm._t("partial-label",null,null,labelProps)]}}:null],null,true)}):(option.options)?_c('AppMultipleSelectCheckbox',{attrs:{"name":_vm.name,"options":option.options,"enable-select-all":true,"enable-indent":true,"select-all-label":option.label},on:{"change":function($event){return _vm.childChanged($event, option.options)}},scopedSlots:_vm._u([(_vm.$scopedSlots['partial-label'])?{key:"partial-label",fn:function(labelProps){return [_vm._t("partial-label",null,null,labelProps)]}}:null],null,true),model:{value:(_vm.childValue[index]),callback:function ($$v) {_vm.$set(_vm.childValue, index, $$v)},expression:"childValue[index]"}}):_c('AppCheckbox',{attrs:{"name":_vm.packingMethod === 'array' ? _vm.name : null,"value":option.value,"disabled":option.disabled,"label":option.label,"checked":_vm.isSelected(option),"invalid":_vm.invalid &&
            (_vm.requiredItems.length === 0 ||
              _vm.requiredItems.includes(option.value))},on:{"change":function($event){$event ? _vm.select(option) : _vm.unselect(option)}},scopedSlots:_vm._u([(_vm.$scopedSlots.label)?{key:"label",fn:function(labelProps){return [_vm._t("label",null,null,labelProps)]}}:null,(_vm.$scopedSlots['sub-item'])?{key:"sub-item",fn:function(subItemProps){return [_vm._t("sub-item",null,null,subItemProps)]}}:null],null,true)})]}}])}),(_vm.name)?[(_vm.packingMethod !== 'array')?_c('input',{attrs:{"type":"hidden","name":_vm.name},domProps:{"value":_vm.value}}):(_vm.currentValue.length === 0)?_c('input',{attrs:{"type":"hidden","name":_vm.name}}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }