<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('subtitle')"
    :form-props="{
      ...formProps,
      id: 'biz_message_template_button',
      submitLabel
    }"
    v-on="formEvents"
  >
    <template #group="{ id, errors, fields }">
      <template v-if="id === 'links'">
        <template
          v-if="
            formObject.button_type === BizMessageTemplateButtonType.APP_LINK
          "
        >
          <div class="AppForm__sub-group">
            <div
              v-for="field in fields"
              :key="field.id"
              class="AppForm__sub-group-item"
            >
              <label
                class="AppForm__sub-group-title"
                :for="`biz_message_template_button_${field.id}`"
                >{{ $t(`${field.id}.label`) }}</label
              >
              <AppTextInput
                :id="`biz_message_template_button_${field.id}`"
                v-model="formObject[field.id]"
                :type="field.type"
                :placeholder="$t(`${field.id}.placeholder`)"
                :invalid="
                  (!!errors.links && !formObject[field.id]) ||
                    !!errors[field.id]
                "
                @blur="validateField('links', field.id)"
                @change="validateField('links', field.id)"
              />
              <AppFormError :error="errors[field.id]" />
            </div>
          </div>
        </template>
        <template
          v-else-if="
            formObject.button_type === BizMessageTemplateButtonType.WEB_LINK
          "
        >
          <div class="AppForm__sub-group">
            <div
              v-for="field in fields"
              :key="field.id"
              class="AppForm__sub-group-item"
            >
              <label
                class="AppForm__sub-group-title"
                :for="`biz_message_template_button_${field.id}`"
                >{{ $t(`${field.id}.label`) }}</label
              >
              <AppTextInput
                :id="`biz_message_template_button_${field.id}`"
                v-model="formObject[field.id]"
                :type="field.type"
                :placeholder="$t(`${field.id}.placeholder`)"
                :invalid="!!errors[field.id]"
                @blur="validateField(field.id)"
                @change="validateField(field.id)"
              />
              <AppFormError :error="errors[field.id]" />
            </div>
          </div>
        </template>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import BizMessageTemplateButtonType from '@/enums/BizMessageTemplateButtonType';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'BizMessageTemplateButtonFormDialog',
  mixins: [DialogFormView],
  props: {
    button: {
      type: Object,
      default: () => ({
        button_type: BizMessageTemplateButtonType.WEB_LINK, // TODO: 알림톡 2차 개선 작업 시 null로 변경
        name: ''
      })
    }
  },
  computed: {
    groupOptions() {
      const { button_type } = this.formObject;
      return {
        button_type: {
          type: 'select',
          options: BizMessageTemplateButtonType.options(
            this.buttonTypeOptionValues
          ),
          description: this.linkTypeDescription,
          validate: ['required'],
          disabled: true // TODO: 알림톡 2차 개선 작업 시 활성화
        },
        name:
          button_type === BizMessageTemplateButtonType.ADD_CHANNEL
            ? {
                type: 'static',
                groupTooltip: this.$t('add_button_tooltip')
              }
            : {
                type: 'text',
                placeholder: this.namePlaceholder,
                description: this.nameDescription,
                validate: ['required'],
                maxlength: 14
              },
        links: this.linksOptions
      };
    },
    buttonTypeOptionValues() {
      if (this.button.button_type === BizMessageTemplateButtonType.ADD_CHANNEL)
        return [BizMessageTemplateButtonType.ADD_CHANNEL];
      else
        return BizMessageTemplateButtonType.values.filter(
          e => e !== BizMessageTemplateButtonType.ADD_CHANNEL
        );
    },
    groupIds() {
      switch (this.formObject.button_type) {
        case BizMessageTemplateButtonType.BOT_KEYWORD:
        case BizMessageTemplateButtonType.MESSAGE_DELIVERY:
        case BizMessageTemplateButtonType.ADD_CHANNEL:
          return ['button_type', 'name'];
        default:
          return ['button_type', 'name', 'links'];
      }
    },
    formSections() {
      return [
        {
          groups: this.groupIds.map(id => ({
            id,
            label: this.$t(id),
            ...this.groupOptions[id]
          }))
        }
      ];
    },
    linkTypeDescription() {
      switch (this.formObject.button_type) {
        case BizMessageTemplateButtonType.ADD_CHANNEL:
          return this.$t('button_type_description_add_channel');
        default:
          return null;
      }
    },
    namePlaceholder() {
      switch (this.formObject.button_type) {
        case BizMessageTemplateButtonType.APP_LINK:
          return this.$t('name_placeholder_app_link');
        case BizMessageTemplateButtonType.WEB_LINK:
          return this.$t('name_placeholder_web_link');
        case BizMessageTemplateButtonType.BOT_KEYWORD:
          return this.$t('name_placeholder_bot_keyword');
        default:
          return this.$t('name_placeholder');
      }
    },
    nameDescription() {
      switch (this.formObject.button_type) {
        case BizMessageTemplateButtonType.BOT_KEYWORD:
          return this.$t('name_description_bot_keyword');
        case BizMessageTemplateButtonType.MESSAGE_DELIVERY:
          return this.$t('name_description_message_delivery');
        default:
          return null;
      }
    },
    linksOptions() {
      switch (this.formObject.button_type) {
        case BizMessageTemplateButtonType.DELIVERY_STATUS:
          return { hint: this.$t('links_hint_delivery_status') };
        case BizMessageTemplateButtonType.APP_LINK:
          return {
            required: true,
            groupDescription: this.$t('links_group_description_app_link'),
            validate: [
              {
                rule: () => {
                  const validLinksCount = [
                    'android_link',
                    'ios_link',
                    'mobile_link'
                  ].filter(e => !!this.formObject[e]).length;
                  return 2 <= validLinksCount;
                },
                errorMessage: this.$t('links_error_app_link')
              }
            ],
            fields: [
              { id: 'android_link', type: 'text' },
              { id: 'ios_link', type: 'text' },
              { id: 'mobile_link', type: 'url', validate: ['url_format'] },
              { id: 'pc_link', type: 'url', validate: ['url_format'] }
            ]
          };
        case BizMessageTemplateButtonType.WEB_LINK:
          return {
            required: true,
            fields: [
              {
                id: 'mobile_link',
                type: 'url',
                validate: ['required', 'url_format']
              },
              {
                id: 'pc_link',
                type: 'url',
                validate: ['required', 'url_format']
              }
            ]
          };
        default:
          return {};
      }
    },
    submitLabel() {
      return this.button.id ? this.$t('app.apply') : this.$t('add_button');
    }
  },
  watch: {
    'formObject.button_type'(newValue, oldValue) {
      if (newValue === BizMessageTemplateButtonType.ADD_CHANNEL)
        this.formObject.name = BizMessageTemplateButtonType.t(
          BizMessageTemplateButtonType.ADD_CHANNEL
        );
      else if (oldValue === BizMessageTemplateButtonType.ADD_CHANNEL) {
        this.formObject.name = '';
      }
    }
  },
  created() {
    this.BizMessageTemplateButtonType = BizMessageTemplateButtonType;
  },
  mounted() {
    this.orgFormObject = { ...this.button };
  },
  methods: {
    submit() {
      this.eventBus.$emit('save', this.formObject);
      this.close(true);
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "버튼 설정",
  "subtitle": "알림톡 템플릿 등록",
  "button_type": "버튼 타입",
  "button_type_description_add_channel": "<ul><li>채널추가 버튼은 첫 번째 버튼에만 사용할 수 있습니다.</li><li>채널추가가 안된 고객에게만 채널추가 버튼이 표시됩니다.</li></ul>",
  "name": "버튼명",
  "name_placeholder": "버튼명을 입력해주세요.",
  "name_placeholder_app_link": "ex) 쇼핑몰 앱 연결",
  "name_placeholder_web_link": "ex) 홈페이지 바로가기",
  "name_placeholder_bot_keyword": "ex) 챗봇 시작하기",
  "name_description_bot_keyword": "버튼을 클릭하면 버튼명에 작성된 텍스트가 상담원에게 전송됩니다.",
  "name_description_message_delivery": "버튼을 클릭하면 버튼명에 작성된 텍스트와 본문 내용이 상담원에게 전송됩니다.",
  "links": "버튼 링크",
  "links_hint_delivery_status": "메시지(템플릿 내용)에 송장번호를 포함하면 자동으로 배송조회 링크가 생성됩니다.",
  "links_group_description_app_link": "안드로이드, iOS, 모바일 중 2개의 링크를 필수로 입력해야 합니다.",
  "android_link": {
    "label": "안드로이드",
    "placeholder": "안드로이드 앱 Custom Scheme"
  },
  "ios_link": {
    "label": "iOS",
    "placeholder": "iOS 앱 Custom Scheme"
  },
  "mobile_link": {
    "label": "모바일",
    "placeholder": "http(s)://url 링크"
  },
  "pc_link": {
    "label": "PC",
    "placeholder": "http(s)://url 링크"
  },
  "links_error_app_link": "안드로이드, iOS, 모바일 중 2개 이상 입력해야합니다.",
  "add_button": "버튼 추가",
  "add_button_tooltip": "채널추가 버튼은 버튼명을 바꿀 수 없습니다."
}
</i18n>
