<template>
  <Component
    :is="clickable ? 'a' : 'div'"
    :class="[
      'ReviewReviewsReviewCell',
      { 'ReviewReviewsReviewCell--clickable': clickable }
    ]"
    @click="click"
  >
    <div
      v-tooltip="
        reviewSettings.admin_show_entire_message
          ? null
          : {
              message: review.message,
              maxWidth: '440px',
              textAlign: 'left',
              horizontal: true
            }
      "
    >
      <AppReviewMediaCell :review="review" />
      <div class="table-line table-line--mt4">
        <div class="table-line">
          <span
            :class="[
              'ReviewReviewsReviewCell__message',
              {
                'ReviewReviewsReviewCell__message--show-entire-message':
                  reviewSettings.admin_show_entire_message
              }
            ]"
            >{{ review.message }}</span
          >
        </div>
        <div class="table-line table-line--light">
          {{ reviewMessageLength }}, {{ $t('comments_count') }}:
          {{ review.comments_count.toLocaleString() }}
        </div>
        <div class="table-line table-line--mt4">
          <AppRate v-if="review.score" :score="review.score" />
          <span
            v-if="reviewSettings.use_likes_count"
            class="ReviewReviewsReviewCell__likes-score"
            >{{ $t('likes_score') }}: {{ likesScore.toLocaleString() }}</span
          >
        </div>
      </div>
    </div>
    <div v-if="isBookmarked || review.pinned || review.note">
      <AppBadge
        v-if="isBookmarked"
        :tooltip="$t('bookmark_tooltip')"
        badge-style="grey"
        :label="$t('bookmark')"
      />
      <AppBadge
        v-if="review.pinned"
        :tooltip="$t('pinned_to_top_tooltip')"
        badge-style="grey-outline"
        :label="$t('pinned_to_top')"
      />
      <AppBadge
        v-if="review.note"
        :tooltip="{
          message: noteTooltip,
          textAlign: 'left',
          maxWidth: '440px'
        }"
        badge-style="grey-outline"
        :label="$t('note')"
      />
    </div>
  </Component>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'ReviewReviewsReviewCell',
  props: {
    review: {
      type: Object,
      required: true
    },
    clickable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('session', ['currentBrand', 'reviewSettings']),
    ...mapGetters('review', ['isBookmarkedReview']),
    likesScore() {
      return this.reviewSettings.use_unlikes_count
        ? this.review.plus_likes_count * 2 - this.review.likes_count
        : this.review.plus_likes_count;
    },
    reviewMessageLength() {
      if (this.reviewSettings.auto_mileage_use_valid_review_message_length) {
        return `${this.$t(
          'valid_message_length'
        )}: ${this.review.valid_message_length.toLocaleString()}`;
      } else {
        return `${this.$t(
          'message_length'
        )}: ${this.review.message_length.toLocaleString()}`;
      }
    },
    noteTooltip() {
      const { formatDateTime } = this.$options.filters;
      return this.$t('note_tooltip', {
        timestamp: formatDateTime(this.review.note.updated_at),
        note: this.review.note.message
      });
    },
    isBookmarked() {
      return this.isBookmarkedReview(this.review);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    click() {
      if (!this.clickable) return;

      this.openDialog(['ReviewReviewDialog', { reviewId: this.review.id }]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.ReviewReviewsReviewCell {
  display: block;

  &--clickable:hover {
    .ReviewReviewsReviewCell__message {
      text-decoration: underline;
    }
  }
}

.ReviewReviewsReviewCell__images {
  line-height: 0;
  margin-top: 4px;
}

.ReviewReviewsReviewCell__image {
  vertical-align: middle;
  line-height: 52px;
  width: 52px;
  height: 52px;

  & + & {
    margin-left: 4px;
  }
}

.ReviewReviewsReviewCell__message {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color-content-text-dark;

  &--show-entire-message {
    white-space: pre-wrap;
    word-break: break-word;
    overflow: visible;
  }
}

.ReviewReviewsReviewCell__likes-score {
  margin-left: 8px;
  vertical-align: middle;
}

.ReviewReviewsReviewCell__message-tooltip {
  display: block;
  white-space: pre-wrap;
  max-width: 440px;
}
</style>

<style>
.ReviewReviewsReviewCell__image-tooltip-image,
.ReviewReviewsReviewCell__video-tooltip {
  width: 200px;
}

.ReviewReviewsReviewCell__image-tooltip-screen {
  width: 100%;
  height: 92%;
  transform: translate(0, -100%);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
}

.ReviewReviewsReviewCell__image-tooltip-svg {
  fill: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>

<i18n locale="ko">
{
  "valid_message_length": "유효 글자수",
  "message_length": "글자수",
  "comments_count": "댓글",
  "likes_score": "도움",
  "bookmark": "책갈피",
  "pinned_to_top": "추천 리뷰",
  "note": "메모",
  "bookmark_tooltip": "오른쪽 끝 [관리] 버튼을 클릭해서\n이 리뷰에 설정한 책갈피를 해제할 수 있습니다.",
  "pinned_to_top_tooltip": "오른쪽 끝 [관리] 버튼을 클릭해서\n추천 리뷰를 해제할 수 있습니다.",
  "note_tooltip": "입력: {timestamp}\n{note}\n\n오른쪽 끝 [관리] 버튼을 클릭해서\n메모 내용을 편집할 수 있습니다."
}
</i18n>
<i18n locale="en">
{
  "valid_message_length": "Valid message length",
  "message_length": "Message length",
  "comments_count": "Comments count",
  "likes_score": "Likes score",
  "bookmark": "Bookmark",
  "pinned_to_top": "Pinned to top",
  "note": "Note",
  "bookmark_tooltip": "Click the [Manage] button on the right\nto release the bookmarks set for this review.",
  "pinned_to_top_tooltip": "You can cancel pinned reviews by clicking the [Manage] button on the right.",
  "note_tooltip": "Input: {timestamp}\n{note}\n\nClick the [Manage] button on the right\nYou can edit the content of the note."
}
</i18n>
