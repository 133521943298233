<template>
  <div class="the-drawers">
    <template v-for="drawer in visibleDrawers">
      <Component
        :is="drawer.component.name"
        :key="drawer.component.name"
        v-bind="drawer.drawerProps"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import components from '@/drawers';

export default {
  components,
  computed: {
    ...mapState('drawer', ['drawers']),
    visibleDrawers() {
      return Object.keys(this.drawers).map(name => ({
        component: components[name],
        drawerProps: this.drawers[name]
      }));
    }
  },
  watch: {
    visibleDrawers() {
      if (!this.visibleDrawers.length) return;

      this.$nextTick(() =>
        this.$el
          .querySelector(`.AppDrawer:nth-child(${this.visibleDrawers.length})`)
          .focus()
      );
    }
  }
};
</script>
