const state = { productIcons: [] };

const mutations = {
  SET_PRODUCT_ICONS(state, productIcons) {
    state.productIcons = productIcons;
  }
};

const getters = {
  productIconImageUrl: ({ productIcons }) => id => {
    const icon = productIcons.find(i => i.id === id);
    return icon ? icon.image_url : null;
  }
};

export default { namespaced: true, state, mutations, getters };
