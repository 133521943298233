<template>
  <AppModalDefault :title="$t('title')" width="wide" @close="close">
    <template #body>
      <div class="ReviewInstallSimulationConfirmDialog__test-link">
        <div class="ReviewInstallSimulationConfirmDialog__test-link-title">
          {{ $t('test_link.title') }}
        </div>
        <div
          class="ReviewInstallSimulationConfirmDialog__test-link-description"
        >
          {{ $t('test_link.description') }}
        </div>
        <AppSingleLineFields>
          <AppTextInput v-model="urlInput" placeholder="http://" />
          <AppButton @click="openTestLinkWindow">
            {{ $t('test_link.button_label') }}
            <AppExternalLinkIcon />
          </AppButton>
        </AppSingleLineFields>
      </div>
      <AppAjaxContent :is-loading="isLoading">
        <AppResourceTable
          table-id="simulation-confirms-table"
          :columns="COLUMNS"
          :rows="rows"
          :batch-buttons="batchButtons"
          @select-rows="selectRows"
        >
          <template #cell="{ column, rowIndex }">
            <template v-if="column === 'id'">
              <span v-tooltip="idTooltip(rowIndex)">
                {{ simulations[rowIndex].id }}
              </span>
            </template>
            <template v-else-if="column === 'detail'">
              <span
                v-for="(value, key) in rows[rowIndex].detail.summary"
                :key="`summary_${key}`"
                class="ReviewInstallSimulationConfirmDialog__detail-summary"
              >
                <span
                  :class="[
                    'ReviewInstallSimulationConfirmDialog__detail-value',
                    `ReviewInstallSimulationConfirmDialog__detail-value--${key}`
                  ]"
                  >{{ value }}
                </span>
              </span>
              <br />
              <span
                v-for="(value, key) in rows[rowIndex].detail.diff"
                :key="`diff_${key}`"
                class="ReviewInstallSimulationConfirmDialog__detail-diff"
              >
                <span class="ReviewInstallSimulationConfirmDialog__detail-key"
                  >{{ key }}:
                </span>
                <span
                  :class="[
                    'ReviewInstallSimulationConfirmDialog__detail-value',
                    `ReviewInstallSimulationConfirmDialog__detail-value--${key}`
                  ]"
                >
                  {{ value.original || 'x' }}
                </span>
                →
                <span
                  :class="[
                    'ReviewInstallSimulationConfirmDialog__detail-value',
                    `ReviewInstallSimulationConfirmDialog__detail-value--${key}`
                  ]"
                >
                  {{ value.simulation || 'x' }}
                </span>
                <br />
              </span>
            </template>
            <template v-else-if="column === 'actions'">
              <AppButton
                :label="$t('table.actions.apply')"
                @click="clickApplySimulation(rowIndex)"
              />
              <AppButton
                :label="$t('table.actions.revert')"
                button-style="red-outline"
                @click="clickRevertSimulation(rowIndex)"
              />
            </template>
          </template>
        </AppResourceTable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';
import DialogSize from '@/enums/DialogSize';
import { mapState, mapActions } from 'vuex';
import SmartInstallationType from '@/enums/SmartInstallationType';
import SmartInstallationSimulationType from '@/enums/SmartInstallationSimulationType';
import SmartInstallationSimulationDetailParser from '@/mixins/SmartInstallationSimulationDetailParser';

export default {
  name: 'ReviewInstallSimulationConfirmDialog',
  mixins: [DialogView, SmartInstallationSimulationDetailParser],
  data() {
    return {
      isLoading: false,
      simulations: [],
      selectedRowIds: [],
      urlInput: ''
    };
  },
  computed: {
    ...mapState('reviewInstallSimulation', ['menuType']),
    batchButtons() {
      return [
        {
          label: this.$t('table.actions.apply'),
          clickHandler: () => this.clickApplySimulations(this.selectedRowIds)
        },
        {
          label: this.$t('table.actions.revert'),
          clickHandler: () => this.clickRevertSimulations(this.selectedRowIds)
        }
      ];
    },
    rows() {
      return this.simulations.map(simulation => ({
        installationType: SmartInstallationType.t(simulation.installation_type),
        simulationType: {
          label: SmartInstallationSimulationType.t(simulation.simulation_type),
          badgeStyle: this.badgeStyle(simulation)
        },
        id: simulation.id,
        detail: this.simulationDetail(simulation)
      }));
    }
  },
  created() {
    this.COLUMNS = [
      { id: 'row_select', type: 'row_select', width: '32px' },
      {
        id: 'installationType',
        label: this.$t('table.columns.installation_type'),
        width: '150px'
      },
      {
        id: 'simulationType',
        type: 'badge',
        label: this.$t('table.columns.simulation_type'),
        width: '70px'
      },
      {
        id: 'id',
        label: this.$t('table.columns.id'),
        width: '70px'
      },
      {
        id: 'detail',
        type: 'html',
        label: this.$t('table.columns.detail.label'),
        tooltip: this.$t('table.columns.detail.tooltip'),
        align: 'left'
      },
      {
        id: 'actions',
        label: this.$t('table.columns.actions'),
        width: '210px'
      }
    ];
  },
  mounted() {
    this.fetchResource();
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    ...mapActions('reviewInstallSimulation', [
      'fetchInstallSimulationsWidgets',
      'fetchInstallSimulationsNonWidgets'
    ]),
    openTestLinkWindow() {
      try {
        const url = new URL(this.urlInput);
        url.searchParams.set('crema-sim', 1);
        window.open(url, '_blank');
      } catch (error) {
        alert('Invalid URL');
        return;
      }
    },
    fetchResource() {
      this.isLoading = true;
      api
        .get('/install_simulation/simulation_confirms')
        .then(({ data }) => {
          this.simulations = data.simulations;
        })
        .catch(error => {
          alert('Failed to fetch simulations:', error);
          // TODO : Handle the error (e.g., show a notification to the user)
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectRows(rows) {
      this.selectedRowIds = rows.map(row => row.id);
    },
    badgeStyle(simulation) {
      switch (simulation.simulation_type) {
        case SmartInstallationSimulationType.CREATE:
          return 'blue';
        case SmartInstallationSimulationType.UPDATE:
          return 'blue-outline';
        case SmartInstallationSimulationType.DELETE:
          return 'red';
        default:
          return 'default';
      }
    },
    idTooltip(rowIndex) {
      return {
        message: this.simulations[rowIndex].original
          ? `original_id: ${this.simulations[rowIndex].original.id}`
          : '',
        textAlign: 'left'
      };
    },
    clickApplySimulation(rowIndex) {
      this.clickApplySimulations([this.simulations[rowIndex].id]);
    },
    clickRevertSimulation(rowIndex) {
      this.clickRevertSimulations([this.simulations[rowIndex].id]);
    },
    clickApplySimulations(simulationIds) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t('confirm_dialog.apply.title'),
          width: DialogSize.SMALL,
          markdownText: this.$t('confirm_dialog.apply.description', [
            simulationIds.length
          ]),
          closeButtonLabel: this.$t('confirm_dialog.apply.confirm_label')
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => {
          this.applySimulations(simulationIds);
        });
        eventBus.$on('cancel', () => (this.isSubmitting = false));
      });
    },
    clickRevertSimulations(simulationIds) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('confirm_dialog.revert.title'),
          width: DialogSize.SMALL,
          markdownText: this.$t('confirm_dialog.revert.description', [
            simulationIds.length
          ]),
          closeButtonLabel: this.$t('confirm_dialog.revert.confirm_label')
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => {
          this.revertSimulations(simulationIds);
        });
        eventBus.$on('cancel', () => (this.isSubmitting = false));
      });
    },
    applySimulations(ids) {
      this.isSubmitting = true;
      api
        .post('/install_simulation/simulation_confirms/apply', { ids })
        .then(() => {
          this.createToast({
            type: 'success',
            message: this.$t('confirm_dialog.apply.success_message', [
              ids.length
            ])
          });
          this.fetchResource();
          this.refreshCurrentInstallation();
          this.isSubmitting = false;
        })
        .catch(error => {
          alert('Failed to apply simulations:', error);
          this.isSubmitting = false;
        });
    },
    revertSimulations(ids) {
      this.isSubmitting = true;
      api
        .post('/install_simulation/simulation_confirms/revert', { ids })
        .then(() => {
          this.createToast({
            type: 'success',
            message: this.$t('confirm_dialog.revert.success_message', [
              ids.length
            ])
          });
          this.fetchResource();
          this.refreshCurrentInstallation();
          this.isSubmitting = false;
        })
        .catch(error => {
          alert('Failed to revert simulations:', error);
          this.isSubmitting = false;
        });
    },
    refreshCurrentInstallation() {
      switch (this.menuType) {
        case 'attach_widget':
          this.fetchInstallSimulationsWidgets();
          break;
        case 'hide_widget':
          this.fetchInstallSimulationsNonWidgets(
            SmartInstallationType.HIDE_ELEMENT
          );
          break;
        case 'etc':
          [
            SmartInstallationType.REPLACE_REVIEWS_COUNT,
            SmartInstallationType.NEW_REVIEW_LINK,
            SmartInstallationType.PRODUCT_REVIEWS_LINK,
            SmartInstallationType.MY_PENDING_REVIEWS_COUNT,
            SmartInstallationType.MY_WRITTEN_REVIEWS_COUNT
          ].forEach(installationType =>
            this.fetchInstallSimulationsNonWidgets(installationType)
          );
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.ReviewInstallSimulationConfirmDialog__test-link {
  margin-bottom: 16px;
}

.ReviewInstallSimulationConfirmDialog__test-link-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.ReviewInstallSimulationConfirmDialog__test-link-description {
  margin-bottom: 8px;
}

::v-deep {
  .simulation-confirms-table__detail {
    white-space: pre-wrap;
  }
}

.ReviewInstallSimulationConfirmDialog__detail-value--element_selector {
  line-height: normal;
  background: rgba(135, 131, 120, 0.15);
  color: #eb5757;
  border-radius: 4px;
  font-size: 85%;
  padding: 1px 4px;
}

.ReviewInstallSimulationConfirmDialog__detail-summary:not(:first-child)::before {
  content: ', ';
}

.ReviewInstallSimulationConfirmDialog__detail-key {
  font-weight: bold;
}
</style>

<i18n locale="ko">
{
  "title": "설치 테스트 내역",
  "test_link": {
    "title": "설치 페이지 미리보기",
    "description": "위젯을 설치 테스트한 페이지의 URL을 입력해주세요. 설치 테스트 내역이 적용된 쇼핑몰 페이지의 모습을 미리 볼 수 있습니다.",
    "button_label": "미리보기"
  },
  "table": {
    "columns": {
      "installation_type": "설치 종류",
      "simulation_type": "동작",
      "id": "설치 id",
      "detail": {
        "label": "상세 내역",
        "tooltip": "상세 내역은 설치 페이지, 설치 위치, 위젯 ID, 상품 번호 주변 요소에서 매칭 여부, 적용 스킨 정보 (모든 스킨일 경우 표시 안 함) 순으로 노출됩니다."
      },
      "actions": "기능"
    },
    "actions": {
      "apply": "라이브 적용",
      "revert": "되돌리기"
    }
  },
  "confirm_dialog": {
    "apply": {
      "title": "설치 테스트 내역 라이브 적용",
      "description": "선택한 {0}개의 설치 테스트 내역을 라이브에 적용하시겠습니까?",
      "confirm_label": "라이브 적용",
      "success_message": "선택한 {0}개의 설치 테스트 내역이 라이브에 적용되었습니다."
    },
    "revert": {
      "title": "설치 테스트 내역 되돌리기",
      "description": "선택한 {0}개의 설치 테스트 내역을 되돌리시겠습니까?",
      "confirm_label": "되돌리기",
      "success_message": "선택한 {0}개의 설치 테스트 내역을 되돌렸습니다."
    }
  }
}
</i18n>
