<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm
      :id="SETTINGS_ID"
      v-bind="formProps"
      submit-button
      object-id="product_settings"
      v-on="formEvents"
    />
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';

export default {
  name: 'ProductSettings',
  mixins: [FormView, SettingsView],
  data: () => ({
    isLoading: true,
    excludeProducts: {}
  }),
  computed: {
    SETTINGS_ID() {
      return 'product_settings';
    },
    groupOptions() {
      return {
        recommendation_excluding_category_ids: {
          type: 'select_product_category',
          multiple: true,
          hint: this.$t('recommendation_excluding_category_ids_hint')
        },
        recommendation_excluding_product_ids: {
          type: 'select_product',
          multiple: true,
          products: this.excludeProducts,
          eventHandlers: {
            'change-items': products =>
              this.$set(this, 'excludeProducts', products)
          },
          hint: this.$t('recommendation_excluding_product_ids_hint')
        }
      };
    }
  },
  mounted() {
    api
      .get('/product/settings')
      .then(({ data }) => {
        const { product_settings } = data;
        this.excludeProducts = data.exclude_products;
        this.orgFormObject = {
          ...product_settings
        };
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/product/settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          const { product_settings } = data;
          this.excludeProducts = data.exclude_products;
          this.orgFormObject = {
            ...product_settings
          };
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>
<i18n locale="ko">
{
  "recommendation_excluding_category_ids_hint": "선택한 카테고리에 포함된 상품은 리뷰 메시지와 타겟 캠페인의 상품 추천 영역에 노출하지 않습니다.",
  "recommendation_excluding_product_ids_hint": "선택한 상품은 리뷰 메시지와 타겟 캠페인의 상품 추천 영역에 노출하지 않습니다."
}
</i18n>
