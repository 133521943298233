<template>
  <AppModalForm
    :title="$t('title')"
    width="wide"
    :form-props="{ formStyle: 'wide', ...formProps }"
    v-on="formEvents"
  >
    <template #group-label="{ group }">
      <template v-if="group.id === 'selected_product_categories'">
        <AppCheckbox v-model="filterZeroProduct" :label="$t('label_filter')" />
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'AppMultipleSelectProductCategoryDialog',
  mixins: [DialogFormView],
  props: {
    productCategories: { type: Array, default: () => [] },
    useCode: { type: Boolean, default: false },
    excludeCategoryValues: { type: Array, default: null }
  },
  data: () => ({ filterZeroProduct: true }),
  computed: {
    ...mapGetters('productCategory', [
      'productCategoryOptions',
      'productCategoryCodeOptions',
      'productCategoryOptionsExceptZeroProduct',
      'productCategoryCodeOptionsExceptZeroProduct'
    ]),
    options() {
      const exceptZeroProductOptions = this.useCode
        ? this.productCategoryCodeOptionsExceptZeroProduct
        : this.productCategoryOptionsExceptZeroProduct;

      const options = this.useCode
        ? this.productCategoryCodeOptions
        : this.productCategoryOptions;

      const result = this.filterZeroProduct
        ? exceptZeroProductOptions
        : options;

      return result.map(option => {
        if (this.excludeCategoryValues?.includes(option.value))
          return { ...option, disabled: true };
        return option;
      });
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'info',
              label: null,
              value: this.$t('info'),
              type: 'infobox'
            },
            {
              id: 'selected_product_categories',
              label: null,
              type: 'multiple_select_box',
              options: this.options,
              queryPlaceholder: this.$t('query_placeholder'),
              selectedOptionsPlaceholder: this.$t(
                'selected_options_placeholder'
              ),
              searchOptionsLabel: this.$t('box.search'),
              selectedOptionsLabel: this.$t('box.selected')
            }
          ]
        }
      ];
    }
  },
  created() {
    this.orgFormObject = {
      ...this.orgFormObject,
      selected_product_categories: this.productCategories
    };
  },
  methods: {
    submit() {
      this.emit('submit', this.formObject.selected_product_categories);
      this.close(true);
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "상품 카테고리 선택",
  "info": "상위 카테고리 선택 시 하위 카테고리 상품도 모두 포함됩니다.",
  "label_filter": "상품수가 0개인 카테고리 제외",
  "box": {
    "search": "쇼핑몰 상품 카테고리",
    "selected": "선택한 상품 카테고리"
  },
  "query_placeholder": "카테고리 이름 또는 코드를 검색 해주세요.",
  "selected_options_placeholder": "상품 카테고리를 선택해주세요."
}
</i18n>
