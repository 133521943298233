<template>
  <div :style="contentStyle">
    <DisplaySyncMainCornersButton />
    <AppButton
      :label="$t('button.deduplicate')"
      @click="openDuplicationCheckPriorityFormDialog"
    />
    <DisplayCornersTable />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import DisplayPageType from '@/enums/DisplayPageType';
import TargetContentView from '@/mixins/TargetContentView';

export default {
  name: 'DisplayMainPage',
  mixins: [TargetContentView],
  created() {
    this.SET_CORNER_PAGE_TYPE(DisplayPageType.MAIN);
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('displayProductDisplays', ['SET_CORNER_PAGE_TYPE']),
    openDuplicationCheckPriorityFormDialog() {
      this.openDialog(['DisplayCornerDuplicationCheckPriorityFormDialog']);
    }
  }
};
</script>

<i18n locale="ko">
{
  "button": {
    "deduplicate": "진열 위치간 중복 방지"
  }
}
</i18n>
