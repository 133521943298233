export default [
  {
    id: 'review_smart_installation_settings_installation',
    menuItemId: 'review_smart_installation_settings_installation',
    sections: [
      {
        groups: [
          {
            id: 'popup_install_position',
            superAdmin: true,
            installationAgency: true
          },
          {
            id: 'enable_widget_observe_install',
            superAdmin: true,
            installationAgency: true
          },
          {
            id: 'use_score_widget_v2',
            superAdmin: true,
            installationAgency: true
          }
        ]
      }
    ]
  }
];
