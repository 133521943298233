<template>
  <div class="AppResourceTablePagination">
    <div class="AppResourceTablePagination__left">
      <AppSelect
        v-if="enablePer"
        :value="per"
        :options="perOptions"
        @change="$emit('paginate', { per: $event, page: 1 })"
      />
      <template v-if="enableSummary">
        <span v-if="totalCount" class="AppResourceTablePagination__summary">{{
          paginationSummary
        }}</span>
      </template>
    </div>
    <div class="AppResourceTablePagination__right">
      <AppPagination
        v-bind="{ currentPage, per, totalCount, enableRoute }"
        @paginate="$emit('paginate', { per, page: $event })"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppResourceTablePagination',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    per: {
      type: Number,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    },
    enablePer: {
      type: Boolean,
      required: true
    },
    enableSummary: {
      type: Boolean,
      required: true
    },
    enableRoute: {
      type: Boolean,
      default: true
    },
    pers: {
      type: Array,
      default() {
        return [20, 50, 100];
      }
    }
  },
  computed: {
    perOptions() {
      return this.pers.map(per => ({
        label: this.$t('per', { per: per }),
        value: per
      }));
    },
    paginationSummary() {
      const start_offset = this.per * (this.currentPage - 1) + 1;
      const end_offset = Math.min(start_offset + this.per - 1, this.totalCount);
      return this.$t('pagination_summary', {
        total_count: this.totalCount,
        start_offset,
        end_offset
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_clearfix.scss';
@import '@/scss/mixins/_texts.scss';

.AppResourceTablePagination {
  @include clearfix;
  padding: 16px 15px;
}

.AppResourceTablePagination__left {
  float: left;
}

.AppResourceTablePagination__right {
  float: right;
}

.AppResourceTablePagination__summary {
  @include text-caption-dark;
  margin-left: 12px;
}
</style>

<i18n locale="ko">
{
  "per": "{per}개씩 보기",
  "pagination_summary": "{total_count}개의 결과 중 {start_offset} 부터 {end_offset} 까지 표시"
}
</i18n>
<i18n locale="en">
{
  "per": "View by {per}",
  "pagination_summary": "Display {start_offset} to {end_offset} out of {total_count} results"
}
</i18n>
