<template>
  <div class="SummaryBoxInstallConfirmed">
    <AppSummaryBox>
      <template #title>
        {{ $t('title') }}
      </template>
      <div class="SummaryBoxInstallConfirmed__form">
        <AppForm
          :submit-button="{ submitLabel: $t('app.complete') }"
          v-bind="formProps"
          v-on="formEvents"
        >
          <template #group="{ id }">
            <template v-if="id === 'launch_date'">
              <pre class="SummaryBoxInstallConfirmed__description">{{
                $t('description')
              }}</pre>
              <AppDatePicker
                v-model="formObject.launch_date"
                class="AppForm__group-field"
                :min-date="minDate"
              />
            </template>
          </template>
        </AppForm>
      </div>
    </AppSummaryBox>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import FormView from '@/mixins/FormView';

export default {
  name: 'SummaryBoxInstallConfirmed',
  mixins: [FormView],
  computed: {
    minDate() {
      return moment().toVal();
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'launch_date',
              label: this.$t('label')
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions('easyJoin', ['requestLaunchDate']),
    submit() {
      this.isSubmitting = true;
      this.requestLaunchDate(this.formObject.launch_date).finally(() => {
        this.isSubmitting = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.SummaryBoxInstallConfirmed__form {
  margin-top: 8px;
}

.SummaryBoxInstallConfirmed__description {
  margin-top: 4px;
}

::v-deep {
  .AppFormHint {
    margin-top: 0;
  }

  .AppForm__form-controls {
    padding-top: 16px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "사용 시작 요청일을 알려주세요. 🗓️",
  "label": "사용 시작 요청일",
  "description": "사용 시작은 영업일 오전 10시 ~ 오후 3시에 가능합니다.\n사용 시작 이후에는 이용 기간이 차감되며 ‘일시중단’ 할 수 없습니다."
}
</i18n>
