import _ from 'lodash';
import Vue from 'vue';
import qs from 'qs';
import router from '@/router';
import DialogSize from '@/enums/DialogSize';

const state = { dialogs: {} };

const getters = {
  isDialogVisible: state => dialogId => !!state.dialogs[dialogId],
  dialogMenuItems(_state, _getters, _rootState, rootGetters) {
    return rootGetters['menu/dialogItems'];
  },
  dialogMenuItemByMenuItemId: (_state, { dialogMenuItems }) => menuItemId =>
    dialogMenuItems.find(i => i.id === menuItemId),
  dialogMenuItemByDialogId: (_state, { dialogMenuItems }) => dialogId =>
    dialogMenuItems.find(i => i.dialog === dialogId)
};

const mutations = {
  OPEN_DIALOG(state, { dialogId, dialogProps }) {
    Vue.set(state.dialogs, dialogId, dialogProps);
  },
  CLOSE_DIALOG(state, dialogId) {
    Vue.delete(state.dialogs, dialogId);
  }
};

const dialogToHash = (id, params) =>
  `${_.kebabCase(id)}${_.isEmpty(params) ? '' : `?${qs.stringify(params)}`}`;
const hashToDialog = hash => {
  const [id, params] = hash.replace(/^#/, '').split('?');
  return [_.snakeCase(id), qs.parse(params)];
};

const actions = {
  openDialog({ commit, getters }, args) {
    const [dialogId, dialogProps] =
      typeof args === 'string' ? [args, {}] : [args[0], { ...args[1] }];

    const hiddenDialogs =
      JSON.parse(localStorage.getItem('hidden_dialogs')) || [];
    if (dialogProps.snoozeId && hiddenDialogs.includes(dialogProps.snoozeId))
      return new Promise((_resolve, reject) => reject({ reason: 'snoozed' }));

    dialogProps.eventBus = new Vue();
    commit('OPEN_DIALOG', { dialogId, dialogProps });

    const dialogMenuItem = getters.dialogMenuItemByDialogId(dialogId);
    if (dialogMenuItem) {
      const hash = dialogToHash(
        dialogMenuItem.id,
        _.omit(dialogProps, ['eventBus'])
      );
      const { currentRoute } = router;
      const currentHash = currentRoute.hash.replace(/^#/, '');
      if (currentHash !== hash) router.replace({ ...currentRoute, hash });
    }

    return new Promise(resolve => resolve(dialogProps.eventBus));
  },
  openDialogFromHash({ dispatch, getters }) {
    const [dialogId, dialogProps] = hashToDialog(router.currentRoute.hash);
    const menuItem = getters.dialogMenuItemByMenuItemId(dialogId);
    if (!menuItem) return;

    dispatch('openDialog', [menuItem.dialog, dialogProps]);
  },
  closeDialog({ commit, getters }, dialogId) {
    const dialogMenuItem = getters.dialogMenuItemByDialogId(dialogId);
    if (dialogMenuItem?.id === hashToDialog(router.currentRoute.hash)[0])
      router.replace({ ...router.currentRoute, hash: '' });
    commit('CLOSE_DIALOG', dialogId);
  },
  alert({ dispatch }, args) {
    return new Promise((resolve, reject) => {
      dispatch('openDialog', [
        'AppMessageDialog',
        {
          type: 'alert',
          title: args.title,
          markdownText: args.message,
          markdownOption: { breaks: true },
          width: args.width || DialogSize.SMALL,
          snoozeId: args.snoozeId,
          closeButtonLabel: args.closeButtonLabel,
          closeButtonStyle: args.closeButtonStyle
        }
      ])
        .then(event => event.$on('close', () => resolve(true)))
        .catch(args => {
          // Ignoring inconsistency on the argument type. 'snoozed' being truthy is good enough - JM
          if (args.reason === 'snoozed') resolve('snoozed');
          else reject(args);
        });
    });
  },
  confirm({ dispatch }, args) {
    return new Promise((resolve, reject) => {
      dispatch('openDialog', [
        'AppMessageDialog',
        {
          type: args.type || 'confirm',
          title: args.title,
          markdownText: args.message,
          markdownOption: { breaks: true },
          width: args.width || DialogSize.SMALL,
          snoozeId: args.snoozeId,
          closeButtonLabel: args.closeButtonLabel,
          cancelButtonLabel: args.cancelButtonLabel,
          closeButtonStyle: args.closeButtonStyle,
          checkboxLabel: args.checkboxLabel
        }
      ])
        .then(event => {
          event.$on('close', () => resolve(true));
          event.$on('cancel', () => resolve(false));
        })
        .catch(args => {
          // Ignoring inconsistency on the argument type. 'snoozed' being truthy is good enough - JM
          if (args.reason === 'snoozed') resolve('snoozed');
          else reject(args);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
