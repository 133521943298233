<template>
  <AppModalForm
    :title="$t('modal_title')"
    width="middle"
    :is-loading="isLoading"
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #head>
      <AppInfoBox class="AnalyticsInsightCompetingBrandsDialog__infobox">
        {{ $t('infobox') }}
      </AppInfoBox>
    </template>
    <template #foot>
      <div class="AnalyticsInsightCompetingBrandsDialog__button">
        <AppButton
          :label="$t('initialize_to_default')"
          button-style="grey-clear"
          @click="initializeToDefault"
        />
      </div>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import { mapState } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'AnalyticsInsightCompetingBrandsDialog',
  mixins: [DialogFormView],
  props: {
    brandIds: { type: Array, default: () => [] }
  },
  data() {
    return {
      isLoading: true,
      brands: []
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'brand_ids',
              label: this.$t('competing_brands'),
              placeholder: this.$t('multiple_select_placeholder'),
              value: this.formObject.brand_ids,
              type: 'multiple_select',
              options: this.brands.map(b => ({
                label: b.name,
                value: b.id
              }))
            }
          ]
        }
      ];
    }
  },
  mounted() {
    this.orgFormObject = { brand_ids: this.brandIds };

    api
      .get('/analytics/brands', { params: { brand_id: this.currentBrand.id } })
      .then(({ data }) => (this.brands = data.brands))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    initializeToDefault() {
      this.formObject.brand_ids = [];
    },
    submit() {
      this.close(true);
      this.emit('submit', { brand_ids: this.formObject.brand_ids });
    }
  }
};
</script>

<style lang="scss" scoped>
.AnalyticsInsightCompetingBrandsDialog__infobox {
  white-space: pre-wrap;
  word-break: break-word;
  margin-bottom: 24px;
}

.AnalyticsInsightCompetingBrandsDialog__button {
  float: right;

  ::v-deep .AppButton__button {
    text-decoration: underline;
  }
}
</style>

<i18n locale="ko">
{
  "modal_title": "경쟁 쇼핑몰 설정",
  "infobox": "크리마 인사이트에서 자사와 비교분석할 경쟁 쇼핑몰을 지정하는 최고 관리자 기능입니다.\n설정값이 있는 경우 경쟁 그룹 설정보다 우선하여 적용됩니다.",
  "initialize_to_default": "기본값으로 초기화",
  "competing_brands": "경쟁 쇼핑몰",
  "multiple_select_placeholder": "쇼핑몰을 선택하세요."
}
</i18n>
