<template>
  <div class="AppProgressSteps">
    <div v-for="(step, i) in steps" :key="i" class="AppProgressSteps__item">
      <div class="AppProgressSteps__index-container">
        <AppSvgIcon
          :class="[
            'AppProgressSteps__index-icon',
            isFutureStep(i) ? 'AppProgressSteps__index-icon--future' : ''
          ]"
          :name="getIconName(i)"
        />
        <span
          v-if="isCurrentOrFutureStep(i)"
          :class="[
            'AppProgressSteps__index',
            isFutureStep(i) ? 'AppProgressSteps__index--future' : ''
          ]"
          >{{ i + 1 }}</span
        >
      </div>
      <span
        :class="[
          i === currentStep
            ? 'AppProgressSteps__step--current'
            : 'AppProgressSteps__step'
        ]"
        >{{ step }}</span
      >
      <AppSvgIcon
        v-if="i !== steps.length - 1"
        class="AppProgressSteps__line"
        name="icon-line"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppProgressSteps',
  props: {
    steps: { type: Array, required: true },
    currentStep: { type: Number, required: true }
  },
  methods: {
    isFutureStep(index) {
      return index > this.currentStep;
    },
    isCurrentOrFutureStep(index) {
      return index >= this.currentStep;
    },
    getIconName(index) {
      if (index > this.currentStep) {
        return 'icon-ellipse-outline';
      } else if (index === this.currentStep) {
        return 'icon-ellipse-filled';
      } else {
        return 'icon-ellipse-checked';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppProgressSteps {
  display: flex;
  gap: 8px;
}

.AppProgressSteps__item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.AppProgressSteps__step {
  color: $color-grey-50;

  &--current {
    color: $color-blue;
    font-weight: bold;
  }
}

.AppProgressSteps__index-container {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.AppProgressSteps__index {
  font-size: 11px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  color: $color-review-white;

  &--future {
    color: $color-grey-35;
  }
}

.AppProgressSteps__index-icon {
  position: absolute;
  top: 0;
  left: 0;
  stroke-width: 0;
  vertical-align: middle;

  &--future {
    stroke-width: 1px;
  }
}

.AppProgressSteps__line {
  vertical-align: middle;
}
</style>
