<template>
  <div class="ChannelProductsUpdatedAtCell">
    <div class="ChannelProductsUpdatedAtCell__product-updated_at">
      {{ updatedAt }}
    </div>
    <AppBadge v-bind="{ label, badgeStyle, tooltip }" />
  </div>
</template>

<script>
export default {
  name: 'ChannelProductsUpdatedAtCell',
  props: {
    product: { type: Object, required: true }
  },
  computed: {
    updatedAt() {
      const { shop_builder_updated_at } = this.product;

      return this.formatDateTimeWithoutSecond(shop_builder_updated_at);
    },
    label() {
      return this.$t('detail_badge');
    },
    badgeStyle() {
      return 'grey';
    },
    tooltip() {
      const {
        shop_builder_updated_at,
        shop_builder_created_at,
        displayed_at
      } = this.product;
      return this.$t('tooltip', [
        this.formatDateTimeWithoutSecond(shop_builder_created_at),
        this.formatDateTimeWithoutSecond(
          displayed_at || shop_builder_created_at
        ),
        this.formatDateTimeWithoutSecond(shop_builder_updated_at)
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ChannelProductsUpdatedAtCell__product-updated_at {
  @include text-content;
}
</style>

<i18n locale="ko">
{
  "detail_badge": "상세정보",
  "tooltip": "등록일: {0}\n노출일: {1}\n수정일: {2}"
}
</i18n>
