<template>
  <div class="DisplayCornerProduct">
    <DisplayCornerItemIndexColorBox
      v-if="product.color_index >= 0"
      class="DisplayCornerProduct__label"
      :color-index="isPinned ? 0 : product.color_index"
    >
      <AppBubble
        v-if="markedProduct.index === product.index"
        :side="isFarLeft ? 'right' : 'left'"
        >{{
          DisplayCornerProductMarkedStatus.t(markedProduct.markedStatus)
        }}</AppBubble
      >
      <template v-if="isPinned">
        <AppSvgIcon class="DisplayCornerProduct__label-icon" name="icon-lock" />
      </template>
      <template v-else>
        {{ product.index + 1 }}
      </template>
    </DisplayCornerItemIndexColorBox>
    <div class="DisplayCornerProduct__image-container">
      <div v-if="product.sold_out" class="DisplayCornerProduct__sold-out">
        {{ $t('sold_out_product') }}
      </div>
      <img
        v-if="hasPreviewImage"
        class="DisplayCornerProduct__image"
        :src="productImageUrl"
      />
      <div
        v-else-if="product.image_source_url"
        class="DisplayCornerProduct__no-image"
      >
        <div class="DisplayCornerProduct__spinner-wrapper">
          <AppSpinner class="DisplayCornerProduct__spinner" />
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          :class="[
            'DisplayCornerProduct__no-image-hint',
            isImageOverlayed
              ? 'DisplayCornerProduct__no-image-hint--overlayed'
              : null
          ]"
        >
          <pre>{{ $t('downloading_image') }}</pre>
        </div>
        <!-- eslint-disable vue/no-v-html -->
      </div>
      <div v-else class="DisplayCornerProduct__deleted">
        <pre>{{ $t('shop_deleted_product') }}</pre>
      </div>
      <div v-if="isProductDeleted" class="DisplayCornerProduct__image-overlay">
        <div class="DisplayCornerProduct__image-overlay--content">
          {{ $t('deleted_product') }}
        </div>
        <div class="DisplayCornerProduct__image-overlay--hint">
          {{ $t('unpin_deleted_product') }}
        </div>
      </div>
      <div
        v-else-if="isProductUndisplayed"
        class="DisplayCornerProduct__image-overlay"
      >
        <div class="DisplayCornerProduct__image-overlay--content">
          {{ $t('undisplayed_product') }}
        </div>
        <div class="DisplayCornerProduct__image-overlay--hint">
          {{ $t('update_when_displayed') }}
        </div>
      </div>
    </div>
    <div class="DisplayCornerProduct__name">
      {{ product.name }}
    </div>
    <s
      v-if="product.final_price !== product.org_price"
      class="DisplayCornerProduct__org-price"
      ><AppNumber :value="product.org_price" unit="currency.krw"/></s
    ><AppNumber
      class="DisplayCornerProduct__final-price"
      :value="product.final_price"
      unit="currency.krw"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DisplayCornerProductMarkedStatus from '@/enums/DisplayCornerProductMarkedStatus';

export default {
  name: 'DisplayCornerProduct',
  props: { product: { type: Object, required: true } },
  computed: {
    ...mapState('displayProductDisplay', ['markedProduct']),
    ...mapState('displayPreviewSettings', ['previewSettings']),
    ...mapGetters('displayProductDisplay', ['isPinnedProduct']),
    DisplayCornerProductMarkedStatus: () => DisplayCornerProductMarkedStatus,
    isPinned() {
      return this.isPinnedProduct(this.product);
    },
    isFarLeft() {
      return (
        this.product.index %
          this.previewSettings.preview_products_count_in_line ===
        0
      );
    },
    isProductDeleted() {
      return this.product.deleted && this.isPinned;
    },
    isProductUndisplayed() {
      return this.product.display === false;
    },
    isImageOverlayed() {
      return this.isProductDeleted || this.isProductUndisplayed;
    },
    hasPreviewImage() {
      return (
        (this.previewSettings.use_original_product_image &&
          this.product.image_source_url) ||
        this.product.image_width
      );
    },
    productImageUrl() {
      if (this.previewSettings.use_original_product_image)
        return this.product.image_source_url;
      else return this.product.image_url;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayCornerProduct {
  line-height: 0;
  position: relative;
}

.DisplayCornerProduct__label {
  position: absolute;
  left: 4px;
  top: 3px;
  z-index: 1;
}

.DisplayCornerProduct__label-icon {
  margin: 6px;
}

.DisplayCornerProduct__sold-out {
  @include text-caption;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -50px;
  width: 100px;
  text-align: center;
  background: rgba(82, 83, 85, 0.7);
  padding: 4px 8px;
  color: white;
}

.DisplayCornerProduct__image-container {
  position: relative;
}

.DisplayCornerProduct__image {
  border: 1px solid $color-grey-25;
  border-radius: 4px;
  width: 100%;
  min-height: 188px;
}

.DisplayCornerProduct__no-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  min-height: 188px;
  background-color: $color-grey-25;
}

.DisplayCornerProduct__spinner-wrapper {
  height: 48px;
}

.DisplayCornerProduct__spinner {
  font-size: 48px;
}

.DisplayCornerProduct__no-image-hint {
  @include text-content;
  margin-top: 8px;
  text-align: center;

  &--overlayed {
    margin-bottom: 52px;
  }
}

.DisplayCornerProduct__deleted {
  @include text-content;

  border-radius: 4px;
  width: 100%;
  height: 188px;
  background: $color-grey-25;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DisplayCornerProduct__image-overlay {
  position: absolute;
  padding: 8px 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
  bottom: 0;
}

.DisplayCornerProduct__image-overlay--content {
  @include text-caption-darker;
}

.DisplayCornerProduct__image-overlay--hint {
  @include text-caption-dark;
}

.DisplayCornerProduct__name {
  font-family: NotoSansCJKkr;
  margin-top: 8px;
  font-size: 13px;
  line-height: 18px;
}

.DisplayCornerProduct__org-price {
  @include text-content;

  font-family: NotoSansCJKkr;
  margin-top: 4px;
  color: $color-grey-35;
  font-size: 11px;
  line-height: 15px;
  margin-right: 3px;
}

.DisplayCornerProduct__final-price {
  margin-top: 2px;
  color: $color-grey-75;
  font-family: NotoSansCJKkr;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
}
</style>

<i18n locale="ko">
{
  "downloading_image": "상품 사진을\n불러오는 중입니다.",
  "shop_deleted_product": "쇼핑몰에서\n삭제된 상품입니다.",
  "deleted_product": "삭제된 상품입니다.",
  "sold_out_product": "품절 상품입니다.",
  "unpin_deleted_product": "상품 고정을 해제해주세요.",
  "undisplayed_product": "미노출 상태 상품입니다.",
  "update_when_displayed": "진열 시 상품을 노출합니다."
}
</i18n>
