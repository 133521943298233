<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic</div>
      <div class="UiCarousel">
        <AppCarousel>
          <img
            src="https://assets.cre.ma/m/220330_CREMA_Review_detail_x2_5.png"
          />
          <img
            src="https://assets.cre.ma/m/220330_CREMA_Review_detail_x2_6.png"
          />
          <img
            src="https://assets.cre.ma/m/220330_CREMA_Review_detail_x2_7.png"
          />
        </AppCarousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiCarousel'
};
</script>

<style lang="scss" scoped>
.UiCarousel {
  width: 300px;
}
</style>
