<template>
  <div class="the-dialogs">
    <template v-for="dialog in visibleDialogs">
      <Component
        :is="dialog.component.name"
        :key="dialog.component.name"
        v-bind="dialog.dialogProps"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import components from '@/dialogs';

export default {
  components,
  computed: {
    ...mapState('dialog', ['dialogs']),
    visibleDialogs() {
      return Object.keys(this.dialogs).map(name => ({
        component: components[name],
        dialogProps: this.dialogs[name]
      }));
    }
  },
  watch: {
    visibleDialogs() {
      if (!this.visibleDialogs.length) return;

      this.$nextTick(() =>
        this.$el
          .querySelector(`.AppModal:nth-child(${this.visibleDialogs.length})`)
          ?.focus()
      );
    }
  }
};
</script>
