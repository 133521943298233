<template>
  <div class="ReviewReviewsPinDialogSelectBar">
    <div class="ReviewReviewsPinDialogSelectBar__content">
      <button
        class="ReviewReviewsPinDialogSelectBar__button"
        @click="$emit('turn-off')"
      >
        <AppSvgIcon
          class="ReviewReviewsPinDialogSelectBar__button_icon"
          name="icon-close"
        />
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="ReviewReviewsPinDialogSelectBar__button_label"
          v-html="$t('selected', { count: selectedReviews.length })"
        />
        <!-- eslint-enable vue/no-v-html -->
      </button>
      <div class="ReviewReviewsPinDialogSelectBar__vertical-line" />
      <button
        class="ReviewReviewsPinDialogSelectBar__button"
        @click="openSortPinnedReviewsDialog"
      >
        <AppSvgIcon
          class="ReviewReviewsPinDialogSelectBar__button_icon"
          name="icon-move"
        />
        <span class="ReviewReviewsPinDialogSelectBar__button_label">
          {{ $t('button.sort') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ReviewReviewsPinDialogSelectBar',
  props: {
    productName: { type: String, required: true },
    selectedReviews: { type: Array, required: true }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openSortPinnedReviewsDialog() {
      this.openDialog([
        'ReviewPinnedReviewsSortDialog',
        {
          productName: this.productName,
          selectedReviews: this.selectedReviews
        }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.ReviewReviewsPinDialogSelectBar {
  height: 48px;
}

.ReviewReviewsPinDialogSelectBar__content {
  padding: 7px 16px;
  border-radius: 32px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
  display: inline-flex;
}

.ReviewReviewsPinDialogSelectBar__button {
  padding: 5px 10px;
  display: flex;
}

.ReviewReviewsPinDialogSelectBar__button_icon {
  margin: auto 0;
}

.ReviewReviewsPinDialogSelectBar__button_label {
  margin-left: 8px;
  line-height: 24px;
  white-space: nowrap;
}

.ReviewReviewsPinDialogSelectBar__vertical-line {
  width: 1px;
  height: 24px;
  background-color: $color-grey-25;
  margin: auto 24px;
}
</style>

<i18n locale="ko">
{
  "selected": "<b>{count}</b> 개 선택됨",
  "button": {
    "sort": "위치 이동"
  }
}
</i18n>
<i18n locale="en">
{
  "selected": "<b>{count}</b> selected",
  "button": {
    "sort": "Move"
  }
}
</i18n>
