<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar>
      <template #default>
        <li v-if="isSuperAdminAccessible" class="super-admin__item">
          <AppButton
            :label="$t('add_bonus_transaction')"
            button-style="red"
            :tooltip="addBonusButtonTooltip"
            :disabled="!!addBonusButtonTooltip"
            @click="clickAddBonusTransaction"
          />
        </li>
        <li v-if="isSuperAdminAccessible" class="super-admin__item">
          <AppButton
            :label="$t('add_termination_transaction')"
            button-style="mint-green"
            :disabled="disabledTerminationButton"
            :tooltip="terminationButtonTooltip"
            @click="clickAddTerminationTransaction"
          />
        </li>
        <li v-if="isSuperAdminAccessible" class="super-admin__item">
          <AppButton
            :label="$t('add_review_transaction')"
            @click="clickAddTransaction"
          />
        </li>
      </template>
      <template #right>
        <li>
          <AppSelect
            v-model="resourceParams.review_event_type"
            :options="ReviewTransactionType.options()"
            :placeholder="$t('transaction_type_placeholder')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppDateRangePicker
            v-model="resourceParams"
            @update="searchResource"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppResourceTable
      table-id="review-transactions-table"
      :resources="transactions"
      :rows="rows"
      :columns="columns"
      :enable-total-count="false"
      :no-data="$t('no_data')"
      @paginate="paginate"
    >
      <template #cell="{ row, column }">
        <template v-if="column === 'transaction_type'">
          <AppBadge
            :label="ReviewTransactionType.t(row.review_event_type)"
            :badge-style="getBadgeStyle(row.review_event_type)"
          />
        </template>
        <template v-else-if="column === 'actions'">
          <div class="super-admin__item">
            <AppButton
              v-if="isEditButtonsVisible(row)"
              :label="$t('app.edit')"
              @click="clickEditTransaction(row)"
            />
            <AppButton
              v-if="isDeleteButtonsVisible(row)"
              :label="$t('app.delete')"
              button-style="red-outline"
              @click="clickDeleteTransaction(row)"
            />
          </div>
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import api from '@/lib/api';
import moment from 'moment';
import { nullResources } from '@/lib/resources';
import BrandReviewStatus from '@/enums/BrandReviewStatus';
import ReviewTransactionType from '@/enums/ReviewTransactionType';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'ThePaymentHistoryDialogReview',
  mixins: [ResourceViewNoRoute],
  data() {
    return {
      isLoading: true,
      transactions: nullResources
    };
  },
  computed: {
    ...mapState('session', ['reviewStatus', 'currentBrand']),
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('session', ['isShopifyBrand']),
    ReviewTransactionType() {
      return ReviewTransactionType;
    },
    columns() {
      return [
        { id: 'date', label: this.$t('app.date'), type: 'datetime' },
        {
          id: 'transaction_type',
          label: this.$t('table.transaction_type')
        },
        {
          id: 'description',
          label: this.$t('app.description'),
          align: 'left',
          type: 'html'
        },
        {
          id: 'balance_count',
          label: this.$t('table.balance_count'),
          align: 'right'
        },
        {
          id: 'paid_amount',
          label: this.$t('table.paid_amount'),
          type: 'number',
          unit: 'currency.krw',
          align: 'right'
        },
        {
          id: 'balance_amount',
          label: this.$t('table.balance_amount'),
          type: 'number',
          unit: 'currency.krw',
          align: 'right'
        },
        {
          id: 'service_date_range',
          label: this.$t('table.service_date_range'),
          align: 'left',
          type: 'html'
        },
        { id: 'actions', label: this.$t('app.actions'), superAdmin: true }
      ].filter(v => v);
    },
    rows() {
      return this.transactions.items
        .filter(
          v =>
            v.review_event_type !==
              ReviewTransactionType.SCHEDULED_TERMINATION ||
            this.isSuperAdminAccessible
        )
        .map(item => ({
          ...item,
          date: item.created_at,
          balance_count: this.$t('unit.count', [item.review_default_sms_count]),
          paid_amount: item.review_paid_sms_sent_price,
          balance_amount: item.review_paid_sms_price,
          service_date_range: this.dateRangeDescription(item),
          superAdmin:
            item.review_event_type ===
            ReviewTransactionType.SCHEDULED_TERMINATION
        }));
    },
    terminationButtonTooltip() {
      if (this.currentBrand.is_review_free_trial) {
        return this.$t('add_termination_tooltip.free_trial');
      } else if (this.reviewStatus?.scheduled_review_expires_at) {
        return this.$t('add_termination_tooltip.already_exists');
      } else if (
        BrandReviewStatus.EXPIRED_STATUSES.includes(
          this.currentBrand.review_status
        )
      ) {
        return this.$t('add_termination_tooltip.expired_status');
      } else if (
        this.currentBrand.review_status !== BrandReviewStatus.OPERATING
      ) {
        return this.$t('add_termination_tooltip.operating_status');
      } else return null;
    },
    disabledTerminationButton() {
      return (
        this.currentBrand.is_review_free_trial ||
        !!this.reviewStatus?.scheduled_review_expires_at ||
        this.currentBrand.review_status !== BrandReviewStatus.OPERATING
      );
    },
    addBonusButtonTooltip() {
      if (this.isShopifyBrand) {
        return this.$t('add_bonus_tooltip');
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapMutations('session', ['SET_REVIEW_STATUS']),
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/payment_history/review_transactions', { params })
        .then(({ data }) => (this.transactions = data.review_transactions))
        .finally(() => (this.isLoading = false));
    },
    getBadgeStyle(transactionType) {
      switch (transactionType) {
        case ReviewTransactionType.PAYMENT:
        case ReviewTransactionType.FEE_PAYMENT:
        case ReviewTransactionType.SMS_PAYMENT:
          return 'blue';
        case ReviewTransactionType.SMS_RESET:
        case ReviewTransactionType.SMS_BONUS:
        case ReviewTransactionType.SMS_AMOUNT_BONUS:
        case ReviewTransactionType.PERIOD_BONUS:
        case ReviewTransactionType.FREE_TRIAL:
          return 'blue-outline';
        case ReviewTransactionType.RETURN:
        case ReviewTransactionType.FEE_RETURN:
        case ReviewTransactionType.SMS_RETURN:
        case ReviewTransactionType.FORCE_TERMINATION:
          return 'red';
        case ReviewTransactionType.EXPIRED:
        case ReviewTransactionType.SCHEDULED_TERMINATION:
          return 'red-outline';
        case ReviewTransactionType.SMS_SEND:
          return 'grey-outline';
        default:
          return 'default';
      }
    },
    isEditableAndDeletableEventType(review_event_type) {
      return ReviewTransactionType.editableTypes.includes(review_event_type);
    },
    isEditButtonsVisible({ review_event_type, manually_created }) {
      return (
        manually_created &&
        this.isEditableAndDeletableEventType(review_event_type)
      );
    },
    isDeletablePeriodBonus(review_event_type, start_at, created_at) {
      const reviewTerminatedAt = this.currentBrand.review_terminated_at;
      const isCreatedAfterTermination =
        !reviewTerminatedAt ||
        moment(created_at).isAfter(moment(reviewTerminatedAt), 'seconds');
      const isBeforeStartAt =
        start_at && moment(start_at).isAfter(moment(), 'day');

      return (
        review_event_type == ReviewTransactionType.PERIOD_BONUS &&
        isCreatedAfterTermination &&
        (isBeforeStartAt ||
          !BrandReviewStatus.isOperating(this.currentBrand.review_status))
      );
    },
    isDeletableTermination(review_event_type, end_at) {
      return (
        review_event_type == ReviewTransactionType.SCHEDULED_TERMINATION &&
        moment().isBefore(moment(end_at))
      );
    },
    isDeleteButtonsVisible({
      review_event_type,
      manually_created,
      start_at,
      end_at,
      created_at
    }) {
      return (
        manually_created &&
        (this.isEditableAndDeletableEventType(review_event_type) ||
          this.isDeletablePeriodBonus(
            review_event_type,
            start_at,
            created_at
          ) ||
          this.isDeletableTermination(review_event_type, end_at))
      );
    },
    clickAddBonusTransaction() {
      this.openDialog('ThePaymentHistoryBonusReviewDialog').then(eventBus => {
        this.orgResourceParams = this.resourceParams = this.defaultResourceParams;
        eventBus.$on('update', this.refreshResource);
      });
    },
    clickAddTerminationTransaction() {
      this.openDialog('ThePaymentHistoryTerminationReviewDialog').then(
        eventBus => {
          this.orgResourceParams = this.resourceParams = this.defaultResourceParams;
          eventBus.$on('update', this.refreshResource);
        }
      );
    },
    clickAddTransaction() {
      this.openDialog('ThePaymentHistoryAddReviewTransactionDialog').then(
        eventBus => {
          this.orgResourceParams = this.resourceParams = this.defaultResourceParams;
          eventBus.$on('update', this.refreshResource);
        }
      );
    },
    clickEditTransaction(row) {
      this.openDialog([
        'ThePaymentHistoryEditReviewTransactionDialog',
        { transaction: row }
      ]).then(eventBus => {
        this.orgResourceParams = this.resourceParams = this.defaultResourceParams;
        eventBus.$on('update', this.refreshResource);
      });
    },
    clickDeleteTransaction({ id, description }) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      api
        .delete(`/payment_history/review_transactions/${id}`, {
          params: { description },
          successMessage: this.$t('app.deleted')
        })
        .then(({ data }) => {
          if (data.review_status) this.SET_REVIEW_STATUS(data.review_status);
          this.orgResourceParams = this.resourceParams = this.defaultResourceParams;
          this.refreshResource();
        });
    },
    dateRangeDescription(item) {
      if (!item.start_at && !item.end_at) return '-';
      const description = [];
      if (item.start_at) description.push(this.formatDateTime(item.start_at));
      if (item.end_at)
        description.push(`~ <br>${this.formatDateTime(item.end_at)}`);
      return description.join(' ');
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .review-transactions-table__date {
    flex: 0 0 156px;
  }

  .review-transactions-table__transaction-type {
    flex: 0 0 180px;
  }

  .review-transactions-table__description {
    flex: 0 0 260px;
  }

  .review-transactions-table__balance-count {
    flex: 0 0 80px;
  }

  .review-transactions-table__paid-amount {
    flex: 0 0 104px;
  }

  .review-transactions-table__balance-amount {
    flex: 0 0 104px;
  }

  .review-transactions-table__service-date-range {
    flex: 0 0 186px;
  }

  .review-transactions-table__actions {
    flex: 0 0 138px;
  }
}
</style>

<i18n locale="ko">
{
  "table": {
    "transaction_type": "유형",
    "balance_count": "잔여건수",
    "paid_amount": "차감금액",
    "balance_amount": "잔여금액",
    "service_date_range": "이용기간"
  },
  "add_bonus_transaction": "충전하기",
  "add_bonus_tooltip": "쇼피파이 고객사에게 적용되지 않는 서비스입니다.",
  "add_termination_transaction": "중도 환불 예약",
  "add_termination_tooltip": {
    "already_exists": "이미 생성된 중도 환불 예약건이 있습니다.",
    "free_trial": "무료체험시 이탈은 호스팅사를 통해 처리가 가능합니다.",
    "expired_status": "만료일이 지난 고객사입니다.",
    "operating_status": "서비스 사용을 시작하지 않은 고객사입니다."
  },
  "add_review_transaction": "이용내역 추가하기",
  "transaction_type_placeholder": "전체 내역",
  "no_data": "리뷰 이용내역이 없습니다."
}
</i18n>
