var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.clickable ? 'a' : 'div',{tag:"Component",class:[
    'ReviewReviewsReviewCell',
    { 'ReviewReviewsReviewCell--clickable': _vm.clickable }
  ],on:{"click":_vm.click}},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
      _vm.reviewSettings.admin_show_entire_message
        ? null
        : {
            message: _vm.review.message,
            maxWidth: '440px',
            textAlign: 'left',
            horizontal: true
          }
    ),expression:"\n      reviewSettings.admin_show_entire_message\n        ? null\n        : {\n            message: review.message,\n            maxWidth: '440px',\n            textAlign: 'left',\n            horizontal: true\n          }\n    "}]},[_c('AppReviewMediaCell',{attrs:{"review":_vm.review}}),_c('div',{staticClass:"table-line table-line--mt4"},[_c('div',{staticClass:"table-line"},[_c('span',{class:[
            'ReviewReviewsReviewCell__message',
            {
              'ReviewReviewsReviewCell__message--show-entire-message':
                _vm.reviewSettings.admin_show_entire_message
            }
          ]},[_vm._v(_vm._s(_vm.review.message))])]),_c('div',{staticClass:"table-line table-line--light"},[_vm._v(" "+_vm._s(_vm.reviewMessageLength)+", "+_vm._s(_vm.$t('comments_count'))+": "+_vm._s(_vm.review.comments_count.toLocaleString())+" ")]),_c('div',{staticClass:"table-line table-line--mt4"},[(_vm.review.score)?_c('AppRate',{attrs:{"score":_vm.review.score}}):_vm._e(),(_vm.reviewSettings.use_likes_count)?_c('span',{staticClass:"ReviewReviewsReviewCell__likes-score"},[_vm._v(_vm._s(_vm.$t('likes_score'))+": "+_vm._s(_vm.likesScore.toLocaleString()))]):_vm._e()],1)])],1),(_vm.isBookmarked || _vm.review.pinned || _vm.review.note)?_c('div',[(_vm.isBookmarked)?_c('AppBadge',{attrs:{"tooltip":_vm.$t('bookmark_tooltip'),"badge-style":"grey","label":_vm.$t('bookmark')}}):_vm._e(),(_vm.review.pinned)?_c('AppBadge',{attrs:{"tooltip":_vm.$t('pinned_to_top_tooltip'),"badge-style":"grey-outline","label":_vm.$t('pinned_to_top')}}):_vm._e(),(_vm.review.note)?_c('AppBadge',{attrs:{"tooltip":{
        message: _vm.noteTooltip,
        textAlign: 'left',
        maxWidth: '440px'
      },"badge-style":"grey-outline","label":_vm.$t('note')}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }