<template>
  <div class="BizMessageTemplatePreview">
    <AppMobilePhoneBizMessagePreview
      :brand-name="currentBrand.name"
      :dispatch-time="$t('time')"
      :show-message-head="showMessageHead"
    >
      <template v-if="shouldDisplayTitles">
        <div class="BizMessageTemplatePreview__subtitle">
          {{ template.subtitle || $t('subtitle_placeholder') }}
        </div>
        <div class="BizMessageTemplatePreview__title">
          {{ template.title || $t('title_placeholder') }}
        </div>
        <div class="BizMessageTemplatePreview__hr" />
      </template>
      <template v-if="shouldDisplayImage">
        <div
          :class="[
            'BizMessageTemplatePreview__image',
            {
              'BizMessageTemplatePreview__image--without-header': !showMessageHead
            }
          ]"
        >
          <template v-if="template.image">
            <img
              :src="template.image"
              class="BizMessageTemplatePreview__image-img"
            />
          </template>
          <template v-else>
            <div class="BizMessageTemplatePreview__image-placeholder">
              {{ $t('image_placeholder') }}
            </div>
          </template>
        </div>
      </template>
      <template v-if="shouldDisplayHeader">
        <div class="BizMessageTemplatePreview__header">
          {{ template.header }}
        </div>
        <div class="BizMessageTemplatePreview__hr" />
      </template>
      <template v-if="shouldDisplayItemListTitles">
        <div class="BizMessageTemplatePreview__item-list-title-container">
          <div class="BizMessageTemplatePreview__item-list-title-and-subtitle">
            <div class="BizMessageTemplatePreview__item-list-title">
              {{ template.title || $t('title_placeholder') }}
            </div>
            <div class="BizMessageTemplatePreview__item-list-subtitle">
              {{ template.subtitle || $t('subtitle_placeholder') }}
            </div>
          </div>
          <template v-if="shouldDisplayHighlightImage">
            <img
              :src="template.highlight_image"
              class="BizMessageTemplatePreview__highlight-image-img"
            />
          </template>
        </div>
        <div class="BizMessageTemplatePreview__hr" />
      </template>
      <template v-if="shouldDisplayItemList">
        <div class="BizMessageTemplatePreview__item-list">
          <div
            v-for="(item, i) in itemList"
            :key="i"
            class="BizMessageTemplatePreview__item"
          >
            <span class="BizMessageTemplatePreview__item-title">{{
              item.title
            }}</span>
            <span class="BizMessageTemplatePreview__item-description">{{
              item.description
            }}</span>
          </div>
        </div>
      </template>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="BizMessageTemplatePreview__content"
        v-html="templateContent || $t('content_placeholder')"
      />
      <!-- eslint-enable vue/no-v-html -->
      <template v-if="shouldDisplayExtra">
        <div class="BizMessageTemplatePreview__extra">
          {{ template.extra || $t('extra_placeholder') }}
        </div>
      </template>
      <template v-if="shouldDisplayButtons">
        <div class="BizMessageTemplatePreview__buttons">
          <div
            v-for="(button, i) in template.buttons"
            :key="i"
            class="BizMessageTemplatePreview__button"
          >
            {{ button.name }}
          </div>
        </div>
      </template>
    </AppMobilePhoneBizMessagePreview>
  </div>
</template>

<script>
import moment from 'moment';
import BizMessageTemplateEmphasizeType from '@/enums/BizMessageTemplateEmphasizeType';
import BizMessageTemplateMessageType from '@/enums/BizMessageTemplateMessageType';
import ReviewMessageDispatchMethod from '@/enums/ReviewMessageDispatchMethod';
import CremaServiceType from '@/enums/CremaServiceType';
import { mapState } from 'vuex';

export default {
  name: 'BizMessageTemplatePreview',
  props: {
    template: { type: Object, required: true },
    showMessageHead: { type: Boolean, default: true }
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    shouldDisplayTitles() {
      return (
        this.template.emphasize_type === BizMessageTemplateEmphasizeType.TEXT
      );
    },
    shouldDisplayImage() {
      return (
        this.template.emphasize_type === BizMessageTemplateEmphasizeType.IMAGE
      );
    },
    shouldDisplayHighlightImage() {
      return (
        this.template.emphasize_type ===
          BizMessageTemplateEmphasizeType.ITEM_LIST &&
        !!this.template.highlight_image &&
        !this.template.remove_highlight_image
      );
    },
    shouldDisplayHeader() {
      return (
        this.template.emphasize_type ===
          BizMessageTemplateEmphasizeType.ITEM_LIST && !!this.template.header
      );
    },
    shouldDisplayItemListTitles() {
      return (
        this.template.emphasize_type ===
        BizMessageTemplateEmphasizeType.ITEM_LIST
      );
    },
    shouldDisplayItemList() {
      return (
        this.template.emphasize_type ===
        BizMessageTemplateEmphasizeType.ITEM_LIST
      );
    },
    shouldDisplayExtra() {
      return [
        BizMessageTemplateMessageType.EXTRA_INFO,
        BizMessageTemplateMessageType.MIXED
      ].includes(this.template.message_type);
    },
    shouldDisplayButtons() {
      return this.template.buttons && this.template.buttons.length > 0;
    },
    reviewInterpolations() {
      if (
        this.template.dispatchMethod === ReviewMessageDispatchMethod.CHATBOT
      ) {
        return {
          user: this.$t('sample_name'),
          brand: this.currentBrand.name,
          products: this.$t('sample_products')
        };
      } else {
        return {
          user: this.$t('sample_name'),
          brand: this.currentBrand.name,
          brand_phone: this.currentBrand.phone || '',
          link_id: 'VyC7y6'
        };
      }
    },
    targetInterpolations() {
      return {
        user: this.$t('sample_name'),
        user_id: 'crema',
        brand: this.currentBrand.name,
        brand_phone: this.currentBrand.phone || '',
        url: 'cre.ma/VyC7y6',
        link_id: 'VyC7y6',
        unpaid_orders_count: '2',
        days_before_inactivation: '7',
        coupon_name: this.$t('sample_coupon_name', [this.currentBrand.name]),
        coupon_expire: moment()
          .add(5, 'day')
          .format('YYYY.MM.DD'),
        days_before_coupon_expiration: '5',
        days_before_expiration: '6'
      };
    },
    templateContent() {
      const interpolations =
        this.template.service_type ===
        CremaServiceType.t(CremaServiceType.TARGET)
          ? this.targetInterpolations
          : this.reviewInterpolations;
      return Object.keys(interpolations)
        .reduce(
          (content, key) =>
            content.replaceAll(`%{${key}}`, interpolations[key]),
          this.template.content || ''
        )
        .replaceAll(/(cre\.ma\/[a-zA-Z0-9]{6})/g, '<a>$1</a>');
    },
    itemList() {
      return 0 < this.template.item_list.length
        ? this.template.item_list.map(e => ({
            title: e.title || this.$t('app.title'),
            description: e.description || this.$t('app.content')
          }))
        : [];
    }
  },
  created() {
    this.BizMessageTemplateMessageType = BizMessageTemplateMessageType;
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.BizMessageTemplatePreview__subtitle {
  @include text-button;
  color: $color-grey-40;
  padding-top: 10px;
}

.BizMessageTemplatePreview__title {
  @include text-sub-title;
  padding-bottom: 10px;
}

.BizMessageTemplatePreview__hr {
  margin: 10px 0;
  border-top: 1px solid $color-grey-15;
}

.BizMessageTemplatePreview__image {
  margin: -16px -20px 16px -20px;

  &--without-header {
    aspect-ratio: 2 / 1;
    overflow: hidden;

    .BizMessageTemplatePreview__image-img {
      border-radius: 16px 16px 0 0;
      height: 100%;
      object-fit: cover;
    }
  }
}

.BizMessageTemplatePreview__image-img {
  width: 100%;
}

.BizMessageTemplatePreview__image-placeholder {
  text-align: center;
  color: $color-grey-40;
  background-color: #f9f9f9;
  height: 150px;
  line-height: 150px;
}

.BizMessageTemplatePreview__header {
  @include text-sub-title;
  color: $color-grey-70;
}

.BizMessageTemplatePreview__item-list-title-container {
  display: flex;
}

.BizMessageTemplatePreview__item-list-title-and-subtitle {
  flex: 1 1 0%;
}

.BizMessageTemplatePreview__highlight-image-img {
  width: 44px;
  height: 44px;
}

.BizMessageTemplatePreview__item-list-title {
  @include text-sub-title;
  font-weight: normal;
  color: $color-grey-70;
}

.BizMessageTemplatePreview__item-list-subtitle {
  @include text-button;
  color: $color-grey-40;
}

.BizMessageTemplatePreview__item-list {
  margin-bottom: 10px;
}

.BizMessageTemplatePreview__item {
  & + & {
    margin-top: 4px;
  }
}

.BizMessageTemplatePreview__item-title {
  color: $color-content-text-light;
}

.BizMessageTemplatePreview__item-description {
  margin-left: 8px;
  color: $color-content-text-dark;
}

.BizMessageTemplatePreview__extra {
  margin-top: 10px;
  word-break: break-word;
  @include text-caption;
}

.BizMessageTemplatePreview__content {
  word-break: break-word;
  white-space: pre-line;

  ::v-deep a {
    color: $color-brand-main;
  }
}

.BizMessageTemplatePreview__buttons {
  margin-top: 10px;
}

.BizMessageTemplatePreview__button {
  & + & {
    margin-top: 6px;
  }

  @include text-button;
  color: $color-button-text-dark;
  height: 32px;
  line-height: 32px;
  border-radius: 3px;
  text-align: center;
  background-color: $color-grey-15;
}
</style>

<i18n locale="ko">
{
  "time": "오전 10:00",
  "subtitle_placeholder": "서브 타이틀",
  "title_placeholder": "타이틀",
  "image_placeholder": "800x400 (2:1비율)",
  "content_placeholder": "안내 메세지 본문입니다.",
  "extra_placeholder": "부가정보입니다.",
  "sample_name": "홍길동",
  "sample_coupon_name": "{0} 쿠폰",
  "sample_products": "스트라이프 니트 외 2건"
}
</i18n>
