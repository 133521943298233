<template>
  <div class="TheEasyJoinWithBrand">
    <div class="TheEasyJoinWithBrand__inner">
      <AppSvgIcon
        name="icon-crema-logo"
        :height="30"
        class="TheEasyJoinWithBrand__logo"
      />
      <!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->
      <AppInfoBox
        class="TheEasyJoinWithBrand__info-box"
        v-html="$t('info_html')"
      />
      <!-- eslint-enable vue/no-v-text-v-html-on-component vue/no-v-html -->
      <AppForm
        id="easyJoinWithBrand"
        object-id="user"
        v-bind="formProps"
        :submit-button="{
          submitLabel: $t('complete')
        }"
        v-on="formEvents"
        @blur-group="formGroupBlurred"
      >
        <template #group="{ id, error }">
          <template v-if="id === 'terms'">
            <AppTermsAgreementCheckbox
              id="terms"
              v-model="formObject.terms"
              name="user[terms][]"
              :invalid="!!error"
              @change="validateField('terms')"
            />
          </template>
        </template>
      </AppForm>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import { mapActions } from 'vuex';
import FormView from '@/mixins/FormView';
export default {
  name: 'TheEasyJoinWithBrand',
  mixins: [FormView],
  data() {
    return {
      terms: []
    };
  },
  computed: {
    formSections() {
      return [
        {
          id: 'easy_join_with_brand_section',
          groups: [
            {
              id: 'name',
              label: this.$t('name.label'),
              type: 'text',
              placeholder: this.$t('name.placeholder'),
              validate: ['required'],
              required: true
            },
            {
              id: 'phone',
              label: this.$t('phone.label'),
              type: 'text',
              placeholder: this.$t('phone.placeholder'),
              validate: ['required', 'korean_phone_format'],
              required: true
            },
            {
              id: 'terms',
              label: '',
              validate: [
                {
                  rule: v =>
                    _.difference(['age', 'use', 'privacy', 'outsource'], v)
                      .length === 0,
                  errorMessage: this.$t('validations.required')
                }
              ]
            }
          ]
        }
      ];
    }
  },
  methods: {
    formGroupBlurred(id) {
      if (id == 'phone') {
        const orgPhone = this.formObject.phone;
        const newPhone = orgPhone.replaceAll('-', '');
        this.formObject.phone = newPhone;
      }
    },
    ...mapActions('session', ['fetchSession', 'redirectToHome']),
    submit(formData) {
      this.isSubmitting = true;

      api
        .patch('/easy_join_with_brand/user', formData, { silent: true })
        .then(() => {
          this.setOrgFormObject(this.formObject);
          this.fetchSession()
            .then(() => this.redirectToHome())
            .finally(() => (this.isSubmitting = false));
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
    setOrgFormObject(orgFormObject) {
      this.orgFormObject = orgFormObject;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';
.TheEasyJoinWithBrand {
  margin: 0 auto;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  text-align: center;
  padding-bottom: 25px;
}

.TheEasyJoinWithBrand__inner {
  width: 500px;
  margin: 0 auto;
  position: relative;
}

.TheEasyJoinWithBrand__logo {
  display: block;
  margin: 53px auto 40px;
  color: $color-review-black;
}

.TheEasyJoinWithBrand__info-box {
  margin-bottom: 32px;
}
</style>

<i18n locale="ko">
{
  "info_html": "<b>크리마 리뷰 사용 준비를 시작합니다.</b><br>이용 중 도움이 필요하실 경우 연락드릴 수 있는 담당자 정보를 입력해주세요.",
  "name": {
    "label": "담당자 이름",
    "placeholder": "담당자 이름을 입력해주세요."
  },
  "phone": {
    "label": "담당자 휴대전화 번호",
    "placeholder": "연락 가능한 휴대전화 번호를 입력해주세요."
  },
  "complete": "시작하기"
}
</i18n>
