import moment from 'moment';

export default {
  methods: {
    isScheduledToSendIn3Hours({ dispatch_weekdays_value, dispatch_hour }) {
      const wday = moment().day() + 1;
      const isScheduledToSendToday = !!(dispatch_weekdays_value & wday);
      if (!isScheduledToSendToday) return false;

      const hourDiff = dispatch_hour - moment().hour();
      return 0 < hourDiff && hourDiff <= 3;
    },
    confirmSubmit(campaign = this.campaign) {
      if (!this.isScheduledToSendIn3Hours(campaign)) return true;

      return confirm(
        this.$t('target_campaign.confirm_scheduled_in_3_hours', [
          campaign.dispatch_hour
        ])
      );
    }
  }
};
