<template>
  <AppAjaxContent :is-loading="isLoading" class="TheSettingsDialogMaterial">
    <AppForm :sections="sections">
      <template #group="{ id }">
        <template v-if="id === 'corporate_cert'">
          <AppCorporateNumberChangeRequest
            :status="corporateNumberChangeRequest.status"
            :reject-message="corporateNumberChangeRequest.reject_message"
            @requested="setCorporateNumberChangeRequest"
          />
        </template>
        <template v-else-if="id === 'telecom_cert'">
          <AppPhoneChangeRequest
            :phone-change-status="phoneChangeRequest.status"
            :reject-message="phoneChangeRequest.reject_message"
            @requested="setPhoneChangeRequest"
          />
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapState } from 'vuex';
import TheSettingsDialogView from './TheSettingsDialogView';

export default {
  name: 'TheSettingsDialogMaterial',
  mixins: [TheSettingsDialogView],
  data() {
    return {
      SETTINGS_ID: 'brand_settings',
      SETTINGS_URL: '/brand_settings'
    };
  },
  computed: {
    ...mapState('session', ['brandSettings']),
    sections() {
      return [
        {
          groups: [
            { id: 'corporate_cert', label: null },
            {
              id: 'telecom_cert',
              label: null,
              superAdmin: !this.brandSettings.phone_number_required
            }
          ]
        }
      ];
    },
    corporateNumberChangeRequest() {
      return (
        this.data?.last_corporate_number_change_request || {
          status: 'none'
        }
      );
    },
    phoneChangeRequest() {
      return (
        this.data?.last_phone_change_request || {
          status: 'none'
        }
      );
    }
  },
  methods: {
    setCorporateNumberChangeRequest(payload) {
      this.$set(this.data, 'last_corporate_number_change_request', payload);
    },
    setPhoneChangeRequest(phone_change_request) {
      this.$set(this.data, 'last_phone_change_request', phone_change_request);
    }
  }
};
</script>
