<template>
  <AppForm v-bind="syncFormProps" v-on="formEvents">
    <template #group="{ id }">
      <template v-if="id === 'brand_review_sync'">
        <i18n path="brand_review_sync.description">
          <AppDatePicker
            v-model="formObject.start_date"
            :min-date="syncMinDate"
            :max-date="syncMaxDate"
            inline
          />
          <AppSelect
            v-model="formObject.date_range"
            :options="dateRangeOptions"
            inline
          />
          <span>{{ hostingName }}</span>
        </i18n>
        <div class="AppForm__sub-group-hint">
          {{ brandReviewSyncHint }}
        </div>
        <div class="AppForm__group-field--mt16">
          <AppButtonSyncReview
            :start-date="formObject.start_date"
            :end-date="formObject.end_date"
          />
        </div>
      </template>
      <template v-else-if="id === 'brand_order_sync'">
        <div class="AppForm__group-field">
          <AppButtonSyncOrder />
        </div>
      </template>
    </template>
  </AppForm>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';

export default {
  name: 'ReviewSettingsSync',
  mixins: [FormView, SettingsView],
  data() {
    return {
      detectFormDataChange: false,
      SETTINGS_ID: 'review_settings_sync',
      orgFormObject: {
        date_range: 30,
        start_date: null,
        end_date: null
      }
    };
  },
  computed: {
    ...mapGetters('session', ['hostingName']),
    syncFormProps() {
      return {
        ...this.formProps,
        id: this.SETTINGS_ID,
        objectId: 'sync_settings',
        formStyle: 'hor'
      };
    },
    syncMinDate() {
      return moment()
        .subtract(1, 'year')
        .toVal();
    },
    syncMaxDate() {
      return moment().toVal();
    },
    dateRangeOptions() {
      const days = [1, 3, 7, 14, 30, 60, 90, 180, 365];
      return days.map(value => ({
        label:
          value === 365
            ? this.$t('brand_review_sync.one_year')
            : this.$t('unit.days', [value]),
        value
      }));
    },
    brandReviewSyncHint() {
      return this.formObject.start_date && this.formObject.end_date
        ? this.$t('brand_review_sync.hint_with_date', [
            this.formatDate(moment(this.formObject.start_date)),
            this.formatDate(moment(this.formObject.end_date))
          ])
        : this.$t('brand_review_sync.hint');
    },
    groupOptions() {
      return {
        brand_order_sync: {
          groupDescription: this.$t('brand_order_sync.description')
        }
      };
    }
  },
  watch: {
    'formObject.start_date'() {
      this.resetEndDate();
    },
    'formObject.date_range'() {
      this.resetEndDate();
    }
  },
  methods: {
    resetEndDate() {
      let endDate = null;
      if (this.formObject.start_date && this.formObject.date_range) {
        if (this.formObject.date_range === 365) {
          endDate = moment(this.formObject.start_date).add(1, 'year');
        } else {
          endDate = moment(this.formObject.start_date).add(
            this.formObject.date_range,
            'days'
          );
        }
        if (endDate.isAfter(moment(), 'day')) endDate = moment();
      }
      this.formObject.end_date = endDate?.toVal();
    }
  }
};
</script>

<i18n locale="ko">
{
  "brand_review_sync": {
    "description": " {0}부터 {1}일 동안 {2}에 등록/수정된 리뷰 정보를 동기화 합니다.",
    "one_year": "1년",
    "hint": "리뷰를 가져오기 시작할 날짜를 선택해주세요. 최대 1년 전부터 선택 가능합니다.",
    "hint_with_date": "{0} - {1} 사이에 등록/수정된 리뷰를 동기화 합니다."
  },
  "brand_order_sync": {
    "description": "버튼 클릭 시 어제부터 오늘까지 2일간 등록/수정된 주문 정보를 동기화 합니다."
  }
}
</i18n>
