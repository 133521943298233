<template>
  <div class="TheMainTopbar">
    <button
      type="button"
      class="TheMainTopbar__toggle-sub-menu"
      @click="$emit('toggle-sub-menu')"
    >
      <AppSvgIcon name="nav-menu" />
    </button>
    <TheBreadcrumb class="TheMainTopbar__breadcrumb" />
    <TheUtilityMenu class="TheMainTopbar__utility-menu" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TheBreadcrumb from './TheBreadcrumb';
import TheUtilityMenu from './TheUtilityMenu';

export default {
  name: 'TheMainTopbar',
  components: { TheBreadcrumb, TheUtilityMenu },
  computed: mapGetters('menu', ['selectedSubMenuItemId'])
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_layouts.scss';
@import '@/scss/mixins/_breakpoints.scss';
@import '@/scss/mixins/_buttons.scss';

.TheMainTopbar {
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  padding: 12px 0;
  height: $topbar-height;
}

.TheMainTopbar__toggle-sub-menu {
  flex: 0 0 auto;
  position: relative;
  top: -10px;

  @include svg-button-container;
}

.TheMainTopbar__breadcrumb {
  flex: 1 1 auto;
  padding-left: 2px;
  padding-right: 12px;
  vertical-align: top;

  @include media-breakpoint-each(tablet, mobile) {
    display: none;
  }
}

.TheMainTopbar__utility-menu {
  flex: 0 0 auto;
}
</style>
