<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template>
        <li>
          <AppSelectProductCategory
            v-model="resourceParams.product_category_id"
            :placeholder="$t('app.all')"
            @change="searchResource"
          />
        </li>
      </template>
      <template #right>
        <li>
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="searchTypes"
          />
        </li>
        <li>
          <AppButtonToggle
            v-model="advancedSearchVisible"
            :label="$t('app.advanced_search')"
          />
        </li>
      </template>

      <template #advanced-search>
        <AppAdvancedSearch
          v-model="resourceParams"
          :visible="advancedSearchVisible"
          :sections="advancedSearchSections"
          @submit="searchResource"
        />
      </template>
    </AppSearchbar>
    <AppResourceTable
      table-id="feed-product-search-table"
      :resources="products"
      :columns="columns"
      :rows="rows"
      :batch-buttons="batchButtons"
      @paginate="paginate"
      @select-rows="selectedProducts = $event"
    >
      <template #additional-table-searchbar>
        <AppSelect
          v-if="sortTypeDateRequired"
          v-model="resourceParams.recent_days"
          class="ChannelFeedProductSearch__custom-search-bar-item"
          size="small"
          :options="recentDaysOptions"
          @change="searchResource"
        />
        <AppSelect
          v-model="resourceParams.sort_type"
          class="ChannelFeedProductSearch__custom-search-bar-item"
          size="small"
          :options="sortTypeOptions"
          @change="searchResource"
        />
      </template>
      <template #cell="{ row, column, value }">
        <template v-if="column === 'product_image'">
          <ChannelProductsImageCell :product="row" @refresh="refreshResource" />
        </template>
        <template v-else-if="column === 'product_info'">
          <ChannelProductsInfoCell :product="row" />
        </template>
        <template v-else-if="column === 'category_ids'">
          <template v-if="!value.length">-</template>
          <ChannelProductsCategoryCell :category-ids="value" />
        </template>
        <template v-else-if="column === 'status'">
          <ChannelProductsStatusCell :product="row" />
        </template>
        <template v-else-if="column === 'updated_at'">
          <ChannelProductsUpdatedAtCell :product="row" />
        </template>
        <template v-else-if="column === 'actions'">
          <ChannelProductsActionsCell
            :product="row"
            :show-exclude-menu="!row.is_excluding_product"
            :no-tooltip="false"
            :always-use-dialog="false"
            v-bind="{ feed }"
            @refresh="refreshResource"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceView from '@/mixins/ResourceView';
import DialogSize from '@/enums/DialogSize';
import DisplayProductSortType from '@/enums/DisplayProductSortType';
import ChannelExcludeStatus from '@/enums/ChannelExcludeStatus';

export default {
  name: 'ChannelFeedProductSearch',
  mixins: [ResourceView],
  props: { feed: { type: Object, required: true } },
  data() {
    return {
      isLoading: true,
      products: nullResources,
      selectedProducts: [],
      advancedSearchVisible: ['selected', 'exclude_status'].some(
        key => key in this.$route.query
      )
    };
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoryNamesMap']),
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      sort_type: DisplayProductSortType.DISPLAYED_DESC,
      recent_days: 3,
      search_type: 'name'
    }),
    searchTypes() {
      return ['name', 'code'].map(value => ({
        value,
        label: this.$t(`product_search_type.${value}`)
      }));
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    sortTypeDateRequired() {
      const { sort_type } = this.resourceParams;

      return !DisplayProductSortType.isSortRecentDaysDisabled(
        parseInt(sort_type)
      );
    },
    sortTypeOptions() {
      return DisplayProductSortType.options();
    },
    recentDaysOptions() {
      return [1, 3, 7, 14, 30, 60, 90].map(value => ({
        label: this.$t('recent_days', [value]),
        value
      }));
    },
    columns() {
      return [
        { id: 'row_select', type: 'row_select', width: '48px' },
        { id: 'code', label: this.$t('table_header.code'), width: '72px' },
        {
          id: 'product_image',
          label: this.$t('table_header.product_image'),
          width: '80px'
        },
        {
          id: 'product_info',
          label: this.$t('table_header.product_info')
        },
        {
          id: 'category_ids',
          type: 'badges',
          label: this.$t('table_header.category_ids'),
          width: '288px'
        },
        {
          id: 'updated_at',
          label: this.$t('table_header.updated_at'),
          width: '135px'
        },
        {
          id: 'status',
          type: 'badges',
          label: this.$t('table_header.status'),
          width: '130px'
        },
        {
          id: 'actions',
          label: this.$t('table_header.actions'),
          width: '160px'
        }
      ];
    },
    rows() {
      return this.products.items.map(item => ({
        ...item,
        product_image: item
      }));
    },
    advancedSearchSections() {
      return [
        {
          groups: [
            {
              id: 'selected',
              label: this.$t('advanced_search.selected'),
              type: 'hash_select_button',
              selectButtons: [
                {
                  id: 'selected',
                  options: [
                    {
                      label: this.$t('advanced_search.selected_product'),
                      value: true
                    },
                    {
                      label: this.$t('advanced_search.unselected_product'),
                      value: false
                    }
                  ]
                }
              ]
            },
            {
              id: 'exclude_status',
              label: this.$t('advanced_search.exclude_status'),
              type: 'hash_select_button',
              selectButtons: [
                {
                  id: 'exclude_status',
                  options: ChannelExcludeStatus.options()
                }
              ]
            },
            {
              id: 'channel_image_created',
              label: this.$t('advanced_search.channel_image_created'),
              type: 'hash_select_button',
              selectButtons: [
                {
                  id: 'channel_image_created',
                  options: [
                    {
                      label: this.$t('advanced_search.created'),
                      value: true
                    },
                    {
                      label: this.$t('advanced_search.original'),
                      value: false
                    }
                  ]
                }
              ]
            }
          ]
        }
      ];
    },
    batchButtons() {
      return [
        {
          label: this.$t('batch.exclude.label'),
          tooltip: this.$t('batch.exclude.tooltip'),
          clickHandler: this.openExcludeProductsConfirm
        },
        {
          label: this.$t('batch.include.label'),
          tooltip: this.$t('batch.include.tooltip'),
          clickHandler: this.cancelExcludeProducts
        },
        {
          label: this.$t('batch.destroy_image.label'),
          tooltip: this.$t('batch.destroy_image.tooltip'),
          clickHandler: this.openDestroyImagesConfirm
        }
      ];
    }
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/channel/feeds/${this.feed.id}/product_search`, { params })
        .then(({ data }) => {
          this.products = data.products;
        })
        .finally(() => (this.isLoading = false));
    },
    openExcludeProductsConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('exclude_products_dialog.title'),
          markdownText: this.$t('exclude_products_dialog.context', [
            this.selectedProducts.length
          ]),
          closeButtonLabel: this.$t('exclude_products_dialog.close_button'),
          cancelButtonStyle: 'grey-outline',
          width: DialogSize.MIDDLE
        }
      ]).then(eventBus => {
        eventBus.$on('close', this.excludeProducts);
      });
    },
    excludeProducts() {
      const excludeProducts = this.selectedProducts.filter(
        product => !product.is_excluding_product
      );
      const excludeProductIds = excludeProducts.map(product => product.id);
      const excludeProductCount = excludeProductIds.length;

      if (excludeProductCount) {
        const formData = new FormData();
        excludeProductIds.forEach(id => formData.append('product_ids[]', id));
        api
          .post(`/channel/feeds/${this.feed.id}/exclude_products`, formData, {
            successMessage: this.$t('batch.exclude.toast', [
              excludeProductCount
            ])
          })
          .finally(this.refreshResource);
      } else {
        this.createToast(this.$t('batch.exclude.toast', [excludeProductCount]));
      }
    },
    cancelExcludeProducts() {
      const cancelExcludeProducts = this.selectedProducts.filter(
        product => product.is_excluding_product
      );
      const cancelExcludeProductsIds = cancelExcludeProducts.map(
        product => product.id
      );
      const cancelExcludeProductsCount = cancelExcludeProductsIds.length;

      if (cancelExcludeProductsCount) {
        const formData = new FormData();
        cancelExcludeProductsIds.forEach(id =>
          formData.append('product_ids[]', id)
        );
        api
          .post(
            `/channel/feeds/${this.feed.id}/cancel_exclude_products`,
            formData,
            {
              successMessage: this.$t('batch.include.toast', [
                cancelExcludeProductsCount
              ])
            }
          )
          .finally(this.refreshResource);
      } else {
        this.createToast(
          this.$t('batch.include.toast', [cancelExcludeProductsCount])
        );
      }
    },
    openDestroyImagesConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('destroy_image_dialog.title'),
          markdownText: this.$t('destroy_image_dialog.context'),
          width: DialogSize.MIDDLE
        }
      ]).then(eventBus => {
        eventBus.$on('close', this.destroyImages);
      });
    },
    destroyImages() {
      const formData = new FormData();
      this.selectedProducts.forEach(p => {
        formData.append('product_ids[]', p.id);
      });

      api
        .post('/channel/product/images/destroy_images', formData)
        .then(({ data }) => {
          const imageDestroyedProductsCount =
            data.image_destroyed_products_count;
          this.createToast(
            this.$t('batch.destroy_image.toast', [imageDestroyedProductsCount])
          );
        })
        .finally(this.refreshResource);
    }
  }
};
</script>

<style lang="scss" scoped>
.ChannelFeedProductSearch__custom-search-bar-item {
  vertical-align: middle;
  line-height: 0;

  & + & {
    margin-left: 4px;
  }
}

::v-deep {
  .feed-product-search-table__product-info {
    min-width: 195px;
  }
}
</style>

<i18n locale="ko">
{
  "recent_days": "최근 {0}일",
  "product_search_type": {
    "name": "상품명",
    "code": "상품번호"
  },
  "table_header": {
    "code": "상품번호",
    "product_image": "상품 이미지",
    "product_info": "상품",
    "category_ids": "카테고리",
    "updated_at": "최종 수정일자",
    "status": "상태",
    "actions": "기능"
  },
  "batch": {
    "exclude": {
      "label": "제외 상품으로 고정",
      "tooltip": "선택한 상품이 앞으로 영영 피드에 노출되지 않습니다.",
      "toast": "총 {0}개의 상품을 제외 상품으로 고정했습니다."
    },
    "include": {
      "label": "제외 상품 고정 해제",
      "tooltip": "선택한 상품 중 제외 상품으로 고정된 상품이 있는 경우 이를 해제합니다.",
      "toast": "총 {0}개의 상품을 제외 상품 고정 해제하였습니다."
    },
    "destroy_image": {
      "label": "대체 상품 이미지 삭제",
      "tooltip": "선택한 상품 중 대체 상품 이미지가 등록된 상품이 있는 경우 이를 삭제합니다.",
      "toast": "총 {0}개의 상품의 대체 상품 이미지를 삭제하였습니다."
    }
  },
  "exclude_products_dialog": {
    "title": "제외 상품으로 일괄 고정합니다.",
    "context": "총 {0}개의 상품을 제외 상품으로 고정합니다.<br>현재 피드에 등록된 상품이 있다면 피드에서 제외하고 제외 상품으로 고정합니다.<br>제외 상품으로 고정되면 앞으로 피드 선정 조건에 해당 하더라도 더이상 선정되지 않습니다.<br>제외 상품으로 고정된 상품은 상품 검색에서 조회 및 관리하실 수 있습니다.",
    "close_button": "제외 상품으로 고정"
  },
  "destroy_image_dialog": {
    "title": "대체 상품 이미지를 삭제하시겠습니까?",
    "context": "선택한 상품 중 대체 상품 이미지가 등록된 경우 일괄 삭제합니다.<br>대체 상품 이미지 삭제 시 쇼핑몰로 부터 동기화 된 기본 이미지가 노출됩니다."
  },
  "advanced_search": {
    "selected": "상품 선정 여부",
    "exclude_status": "상품 제외 조건",
    "selected_product" : "선정됨",
    "unselected_product" : "선정되지 않음",
    "channel_image_created": "대체 상품 이미지 등록 여부",
    "created": "등록됨",
    "original": "등록되지 않음"
  }
}
</i18n>
