<template>
  <div class="AnalyticsInsightNoticeView">
    <slot v-if="!notice" />
    <div v-else class="AnalyticsInsightNoticeView__wrapper">
      <AppSvgIcon class="AnalyticsInsightNoticeView__icon" name="icon-alert" />
      <div class="AnalyticsInsightNoticeView__title">{{ notice.title }}</div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="AnalyticsInsightNoticeView__message"
        v-html="notice.message"
      />
      <div
        v-if="notice.message_detail"
        class="AnalyticsInsightNoticeView__message-detail"
        v-html="notice.message_detail"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

const POLL_KEY = 'insight_notices';

export default {
  name: 'AnalyticsInsightNoticeView',
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState('session', ['insightNotices']),
    notice() {
      return this.insightNotices.filter(n => this.showable(n))[0];
    }
  },
  watch: {
    insightNotices: {
      deep: true,
      handler(_newNotices, oldNotices) {
        if (!this.notice && oldNotices.filter(n => this.showable(n)).length)
          this.$emit('normalize');
      }
    }
  },
  mounted() {
    this.SET_POLL_PARAMS({ key: POLL_KEY, value: 1 });
  },
  beforeDestroy() {
    this.UNSET_POLL_PARAMS(POLL_KEY);
  },
  methods: {
    ...mapMutations('poll', ['SET_POLL_PARAMS', 'UNSET_POLL_PARAMS']),
    showable(notice) {
      const now = new Date();
      const startTime = new Date(notice.start_time);
      const endTime = new Date(notice.end_time || '2999-12-31');

      return startTime <= now && now <= endTime;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightNoticeView {
  position: relative;
}

.AnalyticsInsightNoticeView__wrapper {
  text-align: center;
  position: absolute;
  top: 200px;
  height: 430px;
  width: 100%;
  min-width: 340px;
}

.AnalyticsInsightNoticeView__icon {
  stroke: transparent;
  display: block;
  margin: 0 auto;
}

.AnalyticsInsightNoticeView__title {
  @include text-sub-navigation;
  font-weight: bold;
  margin-top: 12px;
}

.AnalyticsInsightNoticeView__message {
  @include text-content;
  margin-top: 12px;
}

.AnalyticsInsightNoticeView__message-detail {
  @include text-caption-darker;
  display: inline-block;
  margin-top: 16px;
  border: solid 1px $color-grey-25;
  border-radius: 3px;
  min-width: 340px;
  max-width: 520px;
  padding: 12px 16px;
}
</style>
