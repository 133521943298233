<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="name"
    can-maximize
    @close="close"
  >
    <iframe
      slot="body"
      class="FitSizeProductPreviewDialog__iframe"
      scrolling="no"
      :src="iframeUrl"
      :style="{ height: iframeHeight }"
    />
  </AppModalDefault>
</template>

<script>
import qs from 'qs';
import DialogView from '@/mixins/DialogView';
import IframeHeightSetter from '@/mixins/IframeHeightSetter';

export default {
  name: 'FitSizeProductPreviewDialog',
  mixins: [DialogView, IframeHeightSetter],
  props: {
    name: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  data() {
    const [path, query] = this.url.split('?');
    const params = {
      ...qs.parse(query),
      iframe: 1,
      parent_url: location.href,
      admin_preview: 1
    };
    return {
      iframeUrl: `${path}?${qs.stringify(params)}`,
      defaultIframeHeight: 'calc(100vh - 128px)'
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep .AppModalDefault__body {
  min-height: calc(100vh - 104px);
  padding: 12px;
}

.FitSizeProductPreviewDialog__iframe {
  width: 100%;
}
</style>

<i18n locale="ko">
{
  "title": "미리보기"
}
</i18n>
