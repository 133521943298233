import { constructEnum } from '@/lib/enum';

const BrandSyncType = constructEnum('BrandSyncType', {
  PRODUCT: 10,
  ORDER: 40,
  REVIEW: 50,
  PRODUCT_CATEGORY: 70,
  PRODUCT_ICON: 120,
  MAIN_CORNER: 1000,
  PRODUCT_DATA: 1100
});

BrandSyncType.isCsvType = function(brandSyncType) {
  return [
    BrandSyncType.PRODUCT,
    BrandSyncType.ORDER,
    BrandSyncType.REVIEW,
    BrandSyncType.PRODUCT_CATEGORY,
    BrandSyncType.PRODUCT_ICON,
    BrandSyncType.PRODUCT_DATA
  ].includes(brandSyncType);
};

BrandSyncType.showDisplayRunngingTime = function(brandSyncType) {
  return [
    BrandSyncType.PRODUCT,
    BrandSyncType.PRODUCT_CATEGORY,
    BrandSyncType.PRODUCT_ICON,
    BrandSyncType.MAIN_CORNER,
    BrandSyncType.PRODUCT_DATA
  ].includes(brandSyncType);
};

export default BrandSyncType;
