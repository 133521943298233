<template>
  <AppModalDefault
    :title="isGroupTemplate ? $t('title_group') : $t('title_normal')"
    :sub-title="currentBrand.name"
    width="1446px"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading" class="BizMessageTemplatesDialog">
        <template>
          <AppSearchbar>
            <li>
              <AppButton
                :label="$t('new_biz_message_template')"
                button-style="red"
                @click="clickNewTemplate"
              />
            </li>
          </AppSearchbar>
          <div
            v-if="templateType === BizMessageTemplateType.CUSTOM"
            class="BizMessageTemplatesDialog__section"
          >
            <BizMessageDefaultTemplatesTable :service-type="serviceType" />
          </div>
          <div class="BizMessageTemplatesDialog__section">
            <BizMessageTemplatesTable
              :service-type="serviceType"
              :title="$t('approved_templates')"
              :templates="approvedTemplates"
              :template-type="templateType"
              is-approved
            />
          </div>
          <div class="BizMessageTemplatesDialog__section">
            <BizMessageTemplatesTable
              :service-type="serviceType"
              :title="$t('unapproved_templates')"
              :templates="unapprovedTemplates"
              :template-type="templateType"
              :is-approved="false"
            />
          </div>
        </template>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import DialogView from '@/mixins/DialogView';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';
import BizMessageTemplatesTable from '../components/BizMessageTemplatesTable';
import BizMessageDefaultTemplatesTable from '../components/BizMessageDefaultTemplatesTable';

export default {
  name: 'BizMessageTemplatesDialog',
  components: { BizMessageTemplatesTable, BizMessageDefaultTemplatesTable },
  mixins: [DialogView],
  props: {
    templateType: { type: Number, required: true },
    serviceType: { type: Number, default: null }
  },
  data() {
    return { isLoading: true };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('bizMessageTemplate', ['getTemplates']),
    BizMessageTemplateType: () => BizMessageTemplateType,
    isGroupTemplate() {
      return BizMessageTemplateType.isGroupTemplateType(this.templateType);
    },
    templates() {
      return this.getTemplates(this.templateType);
    },
    approvedTemplates() {
      return this.templates.filter(
        e => e.inspection_status === BizMessageTemplateInspectionStatus.APPROVED
      );
    },
    unapprovedTemplates() {
      return this.templates.filter(
        e => e.inspection_status !== BizMessageTemplateInspectionStatus.APPROVED
      );
    }
  },
  mounted() {
    this.isLoading = true;
    const promise = this.isGroupTemplate
      ? this.fetchAllGroupTemplates({ serviceType: this.serviceType })
      : this.fetchTemplates({ serviceType: this.serviceType });
    promise.finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('bizMessageTemplate', [
      'fetchTemplates',
      'fetchAllGroupTemplates'
    ]),
    clickNewTemplate() {
      this.openDialog([
        'BizMessageTemplateFormDialog',
        { serviceType: this.serviceType, templateType: this.templateType }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
.BizMessageTemplatesDialog__section {
  & + & {
    margin-top: 32px;
  }
}
</style>

<i18n locale="ko">
{
  "title_normal": "알림톡 템플릿 관리",
  "title_group": "알림톡 그룹 템플릿 관리",
  "new_biz_message_template": "템플릿 추가",
  "approved_templates": "검수가 완료된 템플릿",
  "unapproved_templates": "미검수 템플릿"
}
</i18n>
