import ReviewSentimentType from '@/enums/ReviewSentimentType';

export default {
  data() {
    return {
      offset: 3,
      maxSentiment: ReviewSentimentType.STRONG_POSITIVE
    };
  },
  computed: {
    formatMax() {
      return this.formatMean(this.maxSentiment);
    }
  },
  methods: {
    formatBadgeStyle(value) {
      switch (value) {
        case ReviewSentimentType.STRONG_POSITIVE:
        case ReviewSentimentType.POSITIVE:
          return 'mint-green-outline';
        case ReviewSentimentType.STRONG_NEGATIVE:
        case ReviewSentimentType.NEGATIVE:
          return 'red-outline';
        default:
          return 'grey-outline';
      }
    },
    formatSentiment(value) {
      return value + this.offset;
    },
    formatMean(value) {
      return this.formatSentiment(value).toFixed(1);
    }
  }
};
