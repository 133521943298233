<template>
  <div class="TargetReportsTotalReportsChart">
    <AppChart :options="chartOptions" :tooltip="tooltip" @tooltip="onTooltip" />
  </div>
</template>

<script>
import ReportsView from '@/mixins/ReportsView';

export default {
  name: 'TargetReportsTotalReportsChart',
  mixins: [ReportsView],
  props: {
    chartHeight: { type: String, required: true },
    seriesColumns: { type: Array, required: true },
    yAxis: { type: Array, required: true }
  },
  data: () => ({ tooltip: null }),
  computed: {
    chartOptions() {
      return {
        chart: { height: this.chartHeight, spacing: [-4, 0, 0, 0] },
        title: { text: '' },
        plotOptions: {
          series: {
            states: { hover: { lineWidthPlus: 0, halo: { size: 0 } } },
            marker: { enabled: false, symbol: 'circle', radius: 2 }
          }
        },
        series: this.seriesColumns.map((column, index) => ({
          unit: column.unit,
          type: column.columnType ? column.columnType : 'line',
          data: this.chartData(column.id, column.valueType),
          name: column.name,
          colorIndex: index,
          pointInterval: this.pointInterval,
          pointStart: this.pointStart,
          yAxis: column.yAxis
        })),
        legend: {
          align: 'right',
          verticalAlign: 'top',
          symbolHeight: 8,
          symbolWidth: 8
        },
        xAxis: {
          crosshair: { dashStyle: 'solid' },
          type: 'datetime',
          dateTimeLabelFormats: {
            day: '%m. %d.',
            week: '%m. %d.',
            month: '%Y. %m.'
          }
        },
        yAxis: this.yAxis,
        tooltip: { enabled: false }
      };
    }
  },
  methods: {
    onTooltip({ x, index, series }) {
      this.tooltip = {
        title: this.getFormattedDate(new Date(x)),
        items: series.chart.series.map(({ data, name, userOptions }) => {
          const value = data[index].y;
          const { unit } = userOptions;

          return {
            symbol: 'circle',
            name,
            value:
              unit === 'currency'
                ? this.formatCurrency(value)
                : unit
                ? this.$t(`unit.${unit}`, [value])
                : value.toLocaleString()
          };
        })
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

@mixin highcharts-color($num, $color) {
  .highcharts-color-#{$num} {
    fill: $color;
    stroke: $color;
  }
}

::v-deep {
  @include highcharts-color(0, $color-blue);
  @include highcharts-color(1, $color-mint-green);
  @include highcharts-color(2, $color-mustard);
}
</style>
