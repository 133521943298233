<template>
  <div>
    <div class="TargetCampaignsTableCampaignInfoCell__name">
      {{ campaign.name }}
    </div>
    <div class="TargetCampaignsTableCampaignInfoCell__info-container">
      <div class="TargetCampaignsTableCampaignInfoCell__group-name">
        {{ groupName }}
      </div>
      <AppBadge
        v-if="isKpiRankShowable"
        class="TargetCampaignsTableCampaignInfoCell__kpi-rank"
        :label="kpiRankLabel"
        :tooltip="kpiRankTooltip"
        :badge-style="kpiRankBadgeStyle"
        :kpi-rank="recentKpiRank"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignGroupType from '@/enums/TargetCampaignGroupType';
import TargetCampaignDispatchType from '@/enums/TargetCampaignDispatchType';
import TargetKpiRank from '@/enums/TargetKpiRank';

export default {
  name: 'TargetCampaignsTableCampaignInfoCell',
  props: { campaign: { type: Object, required: true } },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    groupName() {
      return this.campaign.campaign_group_type === TargetCampaignGroupType.MASS
        ? TargetCampaignType.shortName(this.campaign.campaign_type)
        : TargetCampaignGroupType.t(this.campaign.campaign_group_type);
    },
    recentKpiRank() {
      const realtimeCampaignMediaTypes = TargetCampaignDispatchType.campaignMediaTypes(
        TargetCampaignDispatchType.REALTIME
      );
      return realtimeCampaignMediaTypes.includes(
        this.campaign.campaign_media_type
      )
        ? this.campaign.recent_clicked_kpi_rank
        : this.campaign.recent_kpi_rank;
    },
    kpiRankLabel() {
      return TargetKpiRank.t(this.recentKpiRank);
    },
    kpiRankTooltip() {
      return {
        message: this.$t(
          `tooltip.kpi_rank.${TargetKpiRank.key(this.recentKpiRank)}`
        ),
        info: this.kpiRankDateInfo
      };
    },
    kpiRankDateInfo() {
      if (
        this.campaign.recent_kpi_start_date &&
        this.campaign.recent_kpi_end_date
      ) {
        const startDays = moment().diff(
          moment(this.campaign.recent_kpi_start_date),
          'days'
        );
        const endDays = moment().diff(
          moment(this.campaign.recent_kpi_end_date),
          'days'
        );
        return this.$t('tooltip.kpi_rank_info', [
          startDays,
          endDays,
          startDays - endDays
        ]);
      }
      return '';
    },
    kpiRankBadgeStyle() {
      switch (this.recentKpiRank) {
        case TargetKpiRank.CHECK_NEEDED:
          return 'red';
        case TargetKpiRank.NORMAL:
        case TargetKpiRank.GOOD:
          return 'blue';
        case TargetKpiRank.EXCELLENT:
          return 'mint-green';
        default:
          return 'default';
      }
    },
    isKpiRankShowable() {
      if (this.recentKpiRank === undefined) return false;
      if (
        TargetCampaignType.isProductRecommendationWidget(
          this.campaign.campaign_type
        ) &&
        !this.isSuperAdminAccessible
      )
        return false;
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetCampaignsTableCampaignInfoCell__name {
  @include text-sub-title;
}

.TargetCampaignsTableCampaignInfoCell__info-container {
  line-height: 0;
  margin-top: 8px;
  white-space: nowrap;
}

.TargetCampaignsTableCampaignInfoCell__group-name {
  display: inline-block;
  height: 20px;
  border: 1px solid $color-grey-35;
  border-radius: 2px;
  padding: 0 7px;
  line-height: 18px;
  @include text-caption-dark;
}

.TargetCampaignsTableCampaignInfoCell__kpi-rank {
  margin-left: 8px;
}
</style>

<i18n locale="ko">
{
  "button": {
    "detail": "자세히"
  },
  "tooltip": {
    "kpi_rank": {
      "NONE": "최근 캠페인을 발송하지 않았거나 아직 효율 분석이 완료되지 않은 상태입니다.",
      "CHECK_NEEDED": "핵심 효과가 1% 미만으로 추천 조건이나 디자인, 위치 변경과 같은 점검이 필요한 캠페인입니다.",
      "NOT_BAD": "핵심 효과가 1% ~ 1.5% 이내인 보통 수준의 캠페인입니다.",
      "NORMAL": "핵심 효과가 1.5% ~ 3.5% 이내인 양호한 수준의 캠페인입니다.",
      "GOOD": "핵심 효과가 3.5% ~ 7% 이내인 좋은 수준의 캠페인입니다.",
      "EXCELLENT": "핵심 효과가 7% 이상인 우수한 캠페인입니다."
    },
    "kpi_rank_info": "기간: {0}일 전부터 {1}일 전까지 {2}일간"
  }
}
</i18n>
