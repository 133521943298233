<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="campaign.name || campaignName"
    :is-loading="isLoading"
    :form-props="{ ...formProps, objectId: 'campaign' }"
    v-on="formEvents"
  />
</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'TargetCampaignSettingsCouponSelectDialog',
  mixins: [DialogFormView],
  props: {
    campaignId: { type: Number, required: true },
    campaignName: { type: String, required: true }
  },
  data() {
    return { isLoading: true };
  },
  computed: {
    campaign() {
      return this.formObject;
    },
    formSections() {
      if (this.isLoading) return [];

      return [
        {
          groups: [
            {
              id: 'coupon_id',
              label: this.$t('coupon_id'),
              type: 'select_filterable',
              placeholder: this.$t('coupon_id_placeholder'),
              options: this.campaign.unselected_coupons.map(c => ({
                label: c.name,
                value: c.id
              })),
              validate: ['required']
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaignId}/coupon_select`)
      .then(({ data }) => (this.orgFormObject = data.campaign))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .post(`/target/campaigns/${this.campaignId}/coupon_select`, formData)
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "쿠폰/이벤트 추가",
  "coupon_id": "기존 쿠폰/이벤트 불러오기",
  "coupon_id_placeholder": "캠페인에 적용할 쿠폰/이벤트를 선택해주세요."
}
</i18n>
