<template>
  <div class="InstallRequestRenewalDirectionDialogConfirm">
    InstallRequestRenewalDirectionDialogConfirm
  </div>
</template>

<script>
export default {
  name: 'InstallRequestRenewalDirectionDialogConfirm'
};
</script>
