<template>
  <AppAjaxContent :is-loading="isLoading">
    <TheLoginView
      :is-loading="isSubmitting"
      :title="$t('title')"
      :sub-title="$t('title')"
    >
      <AppForm
        id="user"
        v-bind="formProps"
        object-id="user"
        form-style="wide"
        validate-only-on-submit
        no-screen
        :submit-button="{
          submitLabel: $t('submit'),
          submittingLabel: $t('submitting')
        }"
        v-on="formEvents"
      >
        <input type="hidden" name="user[token]" :value="$route.query.token" />
        <input
          v-show="false"
          type="email"
          name="user[email]"
          autocomplete="username"
          :value="$route.query.email"
        />
      </AppForm>
    </TheLoginView>
  </AppAjaxContent>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import HttpStatus from '@/enums/HttpStatus';
import FormView from '@/mixins/FormView';
import PasswordField from '@/mixins/PasswordField';
import TheLoginView from './TheLoginView';

export default {
  name: 'ThePasswordRecovery',
  components: { TheLoginView },
  mixins: [FormView, PasswordField],
  data() {
    return {
      isLoading: true,
      detectFormDataChange: false
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            this.newPasswordGroup(true),
            this.newPasswordRequirementsGroup
          ]
        }
      ];
    }
  },
  created() {
    const { email, token } = this.$route.query;
    api
      .get('/user/password/edit', { params: { email, token }, silent: true })
      .catch(this.handleError)
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('session', ['fetchSession', 'redirectToNextPage']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/user/password', formData, { silent: true })
        .then(() =>
          this.fetchSession()
            .then(() => this.redirectToNextPage('/'))
            .finally(() => (this.isSubmitting = false))
        )
        .catch(this.handleError);
    },
    handleError(error) {
      if (error.response.status === HttpStatus.UNAUTHORIZED) {
        alert(this.$t('expired'));
        this.$router.push('/account-recovery');
      } else error.errorHandler();
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "비밀번호 변경",
  "submit": "비밀번호 변경",
  "submitting": "비밀번호 변경 중..",
  "expired": "비밀번호 재설정 링크가 만료되었습니다. 다시 발급 후 시도해주세요."
}
</i18n>
