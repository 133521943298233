import UnloadConfirmable from '@/mixins/UnloadConfirmable';

export default {
  mixins: [UnloadConfirmable],
  data: () => ({ isNavigateConfirmationEnabled: false }),
  beforeRouteLeave(_to, _from, next) {
    this.onNavigate(next);
  },
  methods: {
    enableNavigateConfirmation() {
      this.isNavigateConfirmationEnabled = true;
      this.enableUnloadConfirmation();
    },
    disableNavigateConfirmation() {
      this.isNavigateConfirmationEnabled = false;
      this.disableUnloadConfirmation();
    },
    onNavigate(next) {
      if (
        this.isNavigateConfirmationEnabled &&
        !confirm(this.navigateConfirmationMessage)
      )
        next(false);
      else next();
    }
  },
  computed: {
    navigateConfirmationMessage() {
      return this.$t('app.confirm_discard_change_navigate');
    }
  }
};
