<template>
  <SlotModalForm
    class="InstallRequestRenewalSlotProductDetailProductThumbnailAfterDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #head>
      <SlotInfoCard :image-url="cardImageUrl" :name="$t('card_name')" />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'pc_product_detail_product_thumbnail_after'">
        <SlotTable
          :device-type="WidgetDeviceType.PC"
          :slot-type="InstallSlotType.PRODUCT_DETAIL_PRODUCT_THUMBNAIL_AFTER"
        />
      </template>
      <template
        v-else-if="id === 'mobile_product_detail_product_thumbnail_after'"
      >
        <SlotTable
          :device-type="WidgetDeviceType.MOBILE"
          :slot-type="InstallSlotType.PRODUCT_DETAIL_PRODUCT_THUMBNAIL_AFTER"
        />
      </template>
    </template>
  </SlotModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations, mapGetters } from 'vuex';
import WidgetPageType from '@/enums/WidgetPageType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import InstallSlotType from '@/enums/InstallSlotType';
import DialogFormView from '@/mixins/DialogFormView';
import SlotModalForm from './components/SlotModalForm';
import SlotInfoCard from './components/SlotInfoCard';
import SlotTable from './components/SlotTable';

export default {
  name: 'InstallRequestRenewalSlotProductDetailProductThumbnailAfterDialog',
  components: {
    SlotModalForm,
    SlotInfoCard,
    SlotTable
  },
  mixins: [DialogFormView],
  data() {
    return {
      cardImageUrl:
        'https://assets.cre.ma/m/admin/v2/install_request/product_detail_product_thumbnail_after_slot.png',
      oldInstallations: []
    };
  },
  computed: {
    ...mapState('installRequest', ['installDeviceTypes']),
    ...mapGetters('installRequest', ['currentInstallations']),
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    InstallSlotType() {
      return InstallSlotType;
    },
    formSections() {
      return [
        {
          id: 'product_detail_product_thumbnail_after_slot',
          label: this.$t('select_widget'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('tip')
            },
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
              ? [
                  {
                    id: 'pc_product_detail_product_thumbnail_after',
                    label: this.$t('pc')
                  }
                ]
              : []),
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
              ? [
                  {
                    id: 'mobile_product_detail_product_thumbnail_after',
                    label: this.$t('mobile')
                  }
                ]
              : [])
          ]
        }
      ];
    },
    isFormDataChanged() {
      const orgSortedInstallations = _.sortBy(this.oldInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const sortedInstallations = _.sortBy(this.currentInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const result = !_.isEqual(orgSortedInstallations, sortedInstallations);
      return result;
    }
  },
  created() {
    this.SET_CURRENT_PAGE_TYPE(WidgetPageType.PRODUCT_DETAIL);
    this.SET_CURRENT_SLOT_TYPES([
      InstallSlotType.PRODUCT_DETAIL_PRODUCT_THUMBNAIL_AFTER
    ]);
    this.oldInstallations = this.currentInstallations || [];
  },
  methods: {
    ...mapMutations('installRequest', [
      'ADD_INSTALLATIONS',
      'REMOVE_INSTALLATION',
      'SET_CURRENT_PAGE_TYPE',
      'SET_CURRENT_SLOT_TYPES'
    ]),
    beforeCloseDialog() {
      this.currentInstallations.forEach(installation =>
        this.REMOVE_INSTALLATION(installation)
      );
      this.ADD_INSTALLATIONS(this.oldInstallations);
    },
    submit() {
      this.close(true);
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "sub_title": "상품 상세 페이지",
  "card_name": "상품 섬네일 하단",
  "select_widget": "위젯 선택",
  "tip": "설치할 위젯을 선택해 주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해주세요.",
  "pc": "PC",
  "mobile": "모바일"
}
</i18n>
