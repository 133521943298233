var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AnalyticsInsightKeywordsSummary',_vm._b({},'AnalyticsInsightKeywordsSummary',{
    title: _vm.title,
    dateRange: _vm.dateRange,
    summary: _vm.summary
  },false),[_c('AppTable',{class:[
      'AnalyticsInsightDashboardKeywordsDetails',
      { 'AnalyticsInsightDashboardKeywordsDetails__no-data': _vm.hasNoData }
    ],attrs:{"table-id":"keywords-details-table","columns":_vm.COLUMNS,"rows":_vm.rows,"no-data":_vm.$t('insight.no_data')},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
    var row = ref.row;
    var column = ref.column;
return [(column.id !== 'rank')?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(row[((column.id) + "_detail")]),expression:"row[`${column.id}_detail`]"}]},[_vm._v(" "+_vm._s(row[column.id])+" ")])]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }