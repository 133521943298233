<template>
  <AppModalDefault :title="$t('title', [groupIndex])" @close="close">
    <template #body>
      <Component
        :is="
          isTargetPopupCampaign
            ? 'TargetReportsDispatchResultDialogTablePopup'
            : 'TargetReportsDispatchResultDialogTableAll'
        "
        :cohort="{ id: cohortId }"
      />
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';
import AbTestType from '@/enums/AbTestType';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';
import TargetReportsDispatchResultDialogTablePopup from '@/views/target/reports/TargetReportsDispatchResultDialogTablePopup';
import TargetReportsDispatchResultDialogTableAll from '@/views/target/reports/TargetReportsDispatchResultDialogTableAll';

export default {
  name: 'AnalyticsAbTestsTargetStatsDetailDialog',
  components: {
    TargetReportsDispatchResultDialogTablePopup,
    TargetReportsDispatchResultDialogTableAll
  },
  mixins: [DialogView],
  props: {
    cohortId: { type: Number, required: true },
    groupIndex: { type: Number, required: true },
    abTest: { type: Object, required: true }
  },
  computed: {
    isTargetPopupCampaign() {
      return (
        this.abTest.ab_test_type === AbTestType.TARGET_DISABLE_DISPATCH &&
        this.abTest.campaign_media_types.includes(TargetCampaignMediaType.POPUP)
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "그룹 {0} 발송 내역 상세"
}
</i18n>
