<template>
  <AppInfoBox class="AppTipBox" :style="tipBoxStyle">
    <div class="AppTipBox__label">Tip.</div>
    <!-- eslint-disable vue/no-v-html -->
    <div class="AppTipBox__content" v-html="text" />
    <!-- eslint-enable vue/no-v-html -->
  </AppInfoBox>
</template>

<script>
export default {
  name: 'AppTipBox',
  props: {
    text: { type: String, required: true },
    marginBottom: { type: Number, default: 24 }
  },
  computed: {
    tipBoxStyle() {
      return {
        marginBottom: `${this.marginBottom}px`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppTipBox {
  text-align: left;
  padding: 8px 16px;
  display: flex;
}

.AppTipBox__label {
  font-weight: bold;
  color: $color-blue;
}

.AppTipBox__content {
  margin-left: 4px;
  word-break: keep-all;
}
</style>
