<template>
  <AppModalDefault
    class="SmartstorePricingDialog"
    :title="$t('title')"
    width="small"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <template>
          <AppDataList :data="SECTIONS">
            <template #value="{ id }">
              <template v-if="id === 'daily'">
                <div class="SmartstorePricingDialog__discount_message">
                  {{ $t('section_daily_discount_message') }}
                </div>
                <AppTable :columns="DAILY_COLUMNS" :rows="daily_rows" />
              </template>
              <template v-else-if="id === 'starter'">
                <div class="SmartstorePricingDialog__discount_message">
                  {{ $t('section_starter_discount_message') }}
                </div>
                <AppTable :columns="STARTER_COLUMNS" :rows="starter_rows" />
              </template>
            </template>
          </AppDataList>
        </template>
      </AppAjaxContent>
    </template>
    <template #foot>
      <AppButton
        button-style="blue"
        :label="$t('app.confirmed')"
        @click="close"
      />
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'SmartstorePricingDialog',
  mixins: [DialogView],
  data() {
    return {
      isLoading: true,
      starterPackages: [],
      dailyPackages: []
    };
  },
  computed: {
    starter_rows() {
      return [
        ...this.starterPackages.map(e => {
          return {
            reviews_count: this.$t('reviews_count', {
              min: e.min_reviews_count,
              max: e.max_reviews_count
            }),
            price:
              e.final_price === 0
                ? this.$t('app.free')
                : this.formatCurrency(e.final_price)
          };
        }),
        {
          reviews_count: this.$t('gte', [
            this.starterPackages[this.starterPackages.length - 1]
              .max_reviews_count + 1
          ]),
          price: this.$t('contact_sales')
        }
      ];
    },
    daily_rows() {
      return [
        ...this.dailyPackages.map(e => {
          return {
            months: this.$t('months', [e.months]),
            price:
              e.final_price === 0
                ? this.$t('app.free')
                : this.formatCurrency(e.final_price)
          };
        })
      ];
    }
  },
  created() {
    this.SECTIONS = [
      { id: 'daily', label: this.$t('section_daily') },
      { id: 'starter', label: this.$t('section_starter') }
    ];
    this.STARTER_COLUMNS = [
      {
        id: 'reviews_count',
        label: this.$t('reviews_count_title'),
        align: 'left'
      },
      {
        id: 'price',
        label: this.$t('app.price'),
        align: 'right'
      }
    ];
    this.DAILY_COLUMNS = [
      {
        id: 'months',
        label: this.$t('months_title'),
        align: 'left'
      },
      {
        id: 'price',
        label: this.$t('app.price'),
        align: 'right'
      }
    ];
  },
  mounted() {
    api
      .get('/smartstore/pricing')
      .then(({ data }) => {
        this.starterPackages = data.starter_packages;
        this.dailyPackages = data.daily_packages;
      })
      .finally(() => (this.isLoading = false));
  }
};
</script>

<i18n locale="ko">
{
  "title": "연동 비용 기준",
  "section_daily": "매일 리뷰 연동 (부가세 별도)",
  "section_daily_discount_message": "기본 월 100,000원 / 월 평균 리뷰수 200건 이하는 특별가가 적용됩니다.",
  "section_starter": "과거 리뷰 연동 비용 (부가세 별도)",
  "section_starter_discount_message": "론칭 프로모션으로 50% 할인이 적용된 혜택가 입니다.",
  "daily_pricing": "기본 가격: {base_price}원/월",
  "reviews_count_title": "연동 리뷰수",
  "reviews_count": "{min}~{max}개",
  "months_title": "연동 개월수",
  "months": "{0}개월",
  "gte": "{0}개 이상",
  "contact_sales": "별도 협의"
}
</i18n>

<style lang="scss" scoped>
.SmartstorePricingDialog__marked ::v-deep ul {
  display: block;
  list-style-type: disc;
  padding-left: 20px;
}

.SmartstorePricingDialog__discount_message {
  margin-bottom: 8px;
}
</style>
