<template>
  <AppModalDefault
    width="760px"
    :title="$t('title')"
    :sub-title="
      $t('sub_title', {
        widget_name: widget.name,
        widget_id: widgetId
      })
    "
    :form-props="{ ...formProps, objectId: 'smart_installations' }"
    v-on="formEvents"
  >
    <template #body>
      <div
        class="ReviewSmartInstallationAttachWidgetManagerFormDialog__summary"
      >
        {{ $t('summary') }}
      </div>
      <AppSearchbar>
        <li>
          <AppButton
            :label="$t('head.new_widget')"
            button-style="red"
            @click="newWidgetInstallationClicked"
          />
        </li>
      </AppSearchbar>
      <AppAjaxContent :is-loading="false">
        <AppResourceTable
          table-id="smart-installaions-table"
          :columns="COLUMNS"
          :rows="rows"
          :event-bus="eventBus"
        >
          <template #cell="{ column, rowIndex }">
            <template v-if="column === 'actions'">
              <AppButton
                :label="$t('app.settings')"
                @click="smartInstallationSettingsClicked(rowIndex)"
              />
              <AppDropdownMenuButton
                :label="$t('app.manage')"
                :menu-items="installationManageDropdownMenuItems(rowIndex)"
              />
            </template>
          </template>
        </AppResourceTable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import WidgetInsertType from '@/enums/WidgetInsertType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import DialogFormView from '@/mixins/DialogFormView';
import SmartInstallationHelper from '@/mixins/SmartInstallationHelper';

export default {
  name: 'ReviewSmartInstallationAttachWidgetManagerFormDialog',
  mixins: [DialogFormView, SmartInstallationHelper],
  props: {
    widgetId: {
      type: Number,
      required: true
    },
    smartInstallations: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      product_code: null,
      COLUMNS: [
        { id: 'installation_id', label: this.$t('table_head.installation_id') },
        { id: 'page_type', label: this.$t('table_head.page_type') },
        { id: 'insert_type', label: this.$t('table_head.insert_type') },
        { id: 'skin_id', label: this.$t('table_head.skin_id') },
        { id: 'actions', label: this.$t('app.actions') }
      ]
    };
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    rows() {
      return this.smartInstallations.map(smart_installation => {
        const installation = {
          ...smart_installation,
          isMobile: this.widget.widget_device_type === WidgetDeviceType.MOBILE
        };
        return {
          installation_id: smart_installation.id,
          page_type: this.getPageTypeOrPath(smart_installation),
          insert_type: `${
            smart_installation.element_selector
          } ${WidgetInsertType.key(smart_installation.install_position)}`,
          skin_id: this.formatSkinId(smart_installation.skin_id),
          inserted_url: this.insertedUrl(installation),
          element_selector: smart_installation.element_selector,
          custom_installed: smart_installation.custom_installed
        };
      });
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('reviewWidgets', [
      'DELETE_REVIEW_WIDGET_SMART_INSTALLATION'
    ]),
    smartInstallationSettingsClicked(index) {
      this.openDialog([
        'ReviewSmartInstallationAttachWidgetFormDialog',
        {
          widgetId: this.widgetId,
          installationId: this.smartInstallations[index].id
        }
      ]);
    },
    newWidgetInstallationClicked() {
      this.openDialog([
        'ReviewSmartInstallationAttachWidgetFormDialog',
        {
          widgetId: this.widgetId
        }
      ]).then(eventBus =>
        eventBus.$on('focus-row', () => {
          this.$nextTick(() =>
            this.eventBus.$emit('focus-row', this.smartInstallations.length - 1)
          );
        })
      );
    },
    deleteSmartInstallation(index, close) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      const installationId = this.smartInstallations[index].id;

      api.delete(`/smart_installations/${installationId}`).then(() => {
        this.DELETE_REVIEW_WIDGET_SMART_INSTALLATION({
          widgetId: this.widgetId,
          installationIndex: index
        });
        close();
      });
    },
    formatSkinId(skin_id) {
      return skin_id || this.$t('smart_installations.all_skin');
    },
    installationManageDropdownMenuItems(rowIndex) {
      return [
        {
          label: this.$t('review_widgets.move_to_widget_inserted_url'),
          type: 'external_link',
          to: this.insertedUrl(this.smartInstallations[rowIndex]),
          disabled: !this.insertedUrl(this.smartInstallations[rowIndex]),
          tooltip: this.insertedUrlTooltipMessage(
            this.smartInstallations[rowIndex].page_type
          )
        },
        {
          label: this.$t('app.delete'),
          clickHandler: ({ close }) =>
            this.deleteSmartInstallation(rowIndex, close),
          style: 'danger'
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewSmartInstallationAttachWidgetManagerFormDialog__summary {
  @include text-content;
  text-align: center;
  background-color: $color-grey-15;
  border-radius: 3px;
  padding: 12px;
  margin-bottom: 20px;
}
.smart-installaions-table__actions {
  flex: 0 0 244px;
}

::v-deep {
  .smart-installaions-table__installation-id {
    flex: 1 0 10%;
    min-width: 71px;
  }
  .smart-installaions-table__page-type {
    flex: 1 0 20%;
    min-width: 142px;
  }
  .smart-installaions-table__insert-type {
    flex: 1 0 30%;
    min-width: 213px;
  }
  .smart-installaions-table__skin-id {
    flex: 1 0 15%;
    min-width: 106px;
  }
  .smart-installaions-table__actions {
    flex: 1 0 25%;
    min-width: 178px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "위젯 간편 설치 관리",
  "sub_title": "{widget_name} (위젯 ID {widget_id})",
  "head": {
    "new_widget": "간편 설치 추가"
  },
  "table_head": {
    "installation_id": "설치 ID",
    "page_type": "설치 페이지",
    "insert_type": "설치 위치",
    "skin_id": "적용 스킨"
  },
  "table_body": {
    "copy_script": "스크립트 복사"
  },
  "summary": "'간편 설치 추가'설정 저장 후 [관리 > 위젯으로 이동] 기능을 통해 쇼핑몰 페이지에 위젯이 잘 설치되어있는지 확인해 주세요."
}
</i18n>
