<template>
  <form class="DisplayProductDisplayActions" @submit.prevent="submit">
    <div class="DisplayProductDisplayActions__button-wrapper">
      <AppButtonSubmit
        :is-submitting="isSubmitting"
        :disabled="!isDisplayDataChanged"
      />
      <AppButton :label="$t('app.close')" @click="$emit('close')" />
    </div>
    <div
      class="DisplayProductDisplayActions__button-wrapper DisplayProductDisplayActions__button-wrapper--right"
    >
      <template v-if="scheduledAt">
        <AppDropdownMenuButton
          :label="$t('scheduled.button.label')"
          :tooltip="
            $t(
              `scheduled.button.tooltip.${
                display.auto_scheduling ? 'auto' : 'once'
              }`,
              [formatDateTimeWithoutSecond(scheduledAt)]
            )
          "
          :menu-items="scheduledCohortMenuItems"
        />
      </template>
      <template v-else>
        <AppButton
          :label="$t('schedule.button')"
          :disabled="isProductsEmpty"
          @click="confirmSchedule(openScheduleDialog)"
        />
      </template>
      <AppButton
        :label="$t('schedule_now.button')"
        :disabled="isProductsEmpty"
        button-style="blue"
        @click="confirmSchedule(openScheduleNowDialog)"
      />
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';
import Weekday from '@/enums/Weekday';

export default {
  name: 'DisplayProductDisplayActions',
  data() {
    return { isSubmitting: false };
  },
  computed: {
    ...mapState('displayProductDisplay', ['display', 'orgDisplay']),
    ...mapGetters('displayProductDisplay', [
      'isDisplayDataChanged',
      'displayFormData',
      'subCategoryDisplayCorners'
    ]),
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    productDisplayName() {
      return this.display.name;
    },
    isProductsEmpty() {
      return this.display.products.length === 0;
    },
    scheduledAt() {
      return this.display.next_execute_time;
    },
    scheduledCohortMenuItems() {
      if (this.display.auto_scheduling)
        return [
          {
            label: this.$t('scheduled.menu.update_schedule'),
            clickHandler: this.openScheduleDialog
          },
          {
            label: this.$t('scheduled.menu.cancel'),
            style: 'danger',
            clickHandler: this.openCancelAutoScheduleDialog
          }
        ];
      else
        return [
          {
            label: this.$t('scheduled.menu.scheduled_cohort'),
            clickHandler: this.openScheduledCohortDialog
          },
          {
            label: this.$t('scheduled.menu.cancel'),
            style: 'danger',
            clickHandler: this.cancelScheduleOnce
          }
        ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    ...mapMutations('displayProductDisplay', [
      'SET_ORG_DISPLAY',
      'UPDATE_ORG_DISPLAY',
      'UPDATE_ORG_DISPLAY_CORNER_DETAILS',
      'UPDATE_IS_STATUS_UPDATED'
    ]),
    formData(data) {
      const defaultFormData = this.displayFormData;
      _.forEach(data || {}, (v, k) =>
        defaultFormData.append(`product_display[${k}]`, v)
      );

      return defaultFormData;
    },
    submit() {
      this.isSubmitting = true;

      api
        .patch(
          `/display/product_displays/${this.display.id}/update_products`,
          this.formData(),
          { successMessage: this.$t('app.saved') }
        )
        .then(() => this.updateDisplay())
        .finally(() => (this.isSubmitting = false));
    },
    confirmSchedule(schedule) {
      const { subCategoryDisplayCorners } = this;
      if (subCategoryDisplayCorners.length) {
        const cornerNamesCount = 3;
        const cornersName = subCategoryDisplayCorners
          .slice(0, cornerNamesCount)
          .map(({ name }) => name)
          .join('<br />');

        this.openDialog([
          'AppMessageDialog',
          {
            type: 'alert',
            width: DialogSize.SMALL,
            title: this.$t('alert_sub_category_display.title'),
            markdownText: this.$t('alert_sub_category_display.content_html', [
              subCategoryDisplayCorners.length > cornerNamesCount
                ? this.$t('alert_sub_category_display.omitted_count', [
                    cornersName,
                    subCategoryDisplayCorners.length - cornerNamesCount
                  ])
                : cornersName
            ])
          }
        ]).then(eventBus =>
          eventBus.$on('close', () => this.confirmUndisplayedProducts(schedule))
        );
      } else this.confirmUndisplayedProducts(schedule);
    },
    confirmUndisplayedProducts(schedule) {
      const isUndisplayedProductExist = this.display.products.some(
        ({ display }) => !display
      );
      if (isUndisplayedProductExist)
        this.openDialog([
          'AppMessageDialog',
          {
            type: 'confirm',
            width: DialogSize.SMALL,
            title: this.$t('confirm_undisplayed_products.title'),
            markdownText: this.$t('confirm_undisplayed_products.content_html')
          }
        ]).then(eventBus =>
          eventBus.$on('close', () => this.$nextTick(schedule))
        );
      else schedule();
    },
    openScheduleDialog() {
      this.openDialog([
        'DisplayProductDisplayScheduleDialog',
        { productDisplayName: this.productDisplayName }
      ]).then(eventBus => {
        eventBus.$on('schedule', displayTime => this.schedule(displayTime));
        eventBus.$on('auto-schedule', displayWeekdays =>
          this.registerAutoSchedule(displayWeekdays)
        );
      });
    },
    openScheduleNowDialog() {
      if (this.isCategoryPage)
        this.openDialog([
          'DisplayProductDisplayScheduleNowDialog'
        ]).then(eventBus => eventBus.$on('submit', this.scheduleNow));
      else
        this.openDialog([
          'AppMessageDialog',
          {
            type: 'confirm',
            title: this.$t('schedule_now.confirm.title'),
            markdownText: this.$t('schedule_now.confirm.content_html', [
              this.productDisplayName
            ]),
            closeButtonLabel: this.$t('schedule_now.button'),
            width: DialogSize.AUTO
          }
        ]).then(eventBus => eventBus.$on('close', this.scheduleNow));
    },
    schedule(displayTime) {
      this.isSubmitting = true;

      api
        .post(
          `/display/product_displays/${this.display.id}/schedule_once`,
          this.formData(displayTime)
        )
        .then(({ data }) => {
          this.updateDisplay();
          this.UPDATE_ORG_DISPLAY(data);
          this.UPDATE_IS_STATUS_UPDATED(true);
          this.openDialog([
            'AppMessageDialog',
            {
              type: 'alert',
              title: this.$t('schedule.complete.title'),
              markdownText: this.$t('schedule.complete.content_html', [
                this.productDisplayName,
                this.formatDateTimeWithoutSecond(this.scheduledAt)
              ]),
              width: DialogSize.AUTO
            }
          ]);
        })
        .finally(() => (this.isSubmitting = false));
    },
    registerAutoSchedule(displayWeekdays) {
      this.isSubmitting = true;

      api
        .post(
          `/display/product_displays/${this.display.id}/register_auto_schedule`,
          this.formData(displayWeekdays)
        )
        .then(({ data }) => {
          const { execute_weekdays_value } = displayWeekdays;
          const weekdaysText = Weekday.fromBitFlag(execute_weekdays_value)
            .map(wday => Weekday.t(wday))
            .join(', ');

          this.updateDisplay();
          this.UPDATE_ORG_DISPLAY(data);
          this.UPDATE_IS_STATUS_UPDATED(true);
          this.openDialog([
            'AppMessageDialog',
            {
              type: 'alert',
              title: this.$t('register_auto_schedule.complete.title'),
              markdownText: this.isCategoryPage
                ? this.$t(
                    'register_auto_schedule.complete.content_html.category',
                    [this.display.corners.length, weekdaysText]
                  )
                : this.$t('register_auto_schedule.complete.content_html.main', [
                    this.productDisplayName,
                    weekdaysText
                  ]),
              width: DialogSize.AUTO
            }
          ]);
        })
        .finally(() => (this.isSubmitting = false));
    },
    scheduleNow() {
      this.isSubmitting = true;

      api
        .post(
          `/display/product_displays/${this.display.id}/schedule_now`,
          this.formData()
        )
        .then(() => {
          this.updateDisplay();
          this.UPDATE_IS_STATUS_UPDATED(true);
          this.openDialog([
            'AppMessageDialog',
            {
              type: 'alert',
              title: this.$t('schedule_now.complete.title'),
              markdownText: this.$t('schedule_now.complete.content_html', [
                this.productDisplayName
              ]),
              width: DialogSize.AUTO
            }
          ]).then(eventBus => eventBus.$on('close', () => this.$emit('close')));
        })
        .finally(() => (this.isSubmitting = false));
    },
    updateDisplay() {
      this.SET_ORG_DISPLAY(this.display);
      this.UPDATE_ORG_DISPLAY_CORNER_DETAILS(this.display.corners);
    },
    openScheduledCohortDialog() {
      this.openDialog([
        'DisplayProductDisplayCohortDialog',
        { cohortId: this.display.scheduled_cohort_id }
      ]);
    },
    cancelScheduleOnce() {
      api
        .post(
          `/display/product_displays/${this.display.id}/cancel_schedule_once`,
          { cohort_id: this.display.scheduled_cohort_id }
        )
        .then(({ data }) => {
          if (data.result === 'canceled')
            this.createToast(
              this.$t('product_display.cancel_schedule_once.canceled')
            );
          else
            this.openDialog([
              'AppMessageDialog',
              {
                title: this.$t(
                  'product_display.cancel_schedule_once.failed_alert.title'
                ),
                type: 'alert',
                markdownText: this.$t(
                  `product_display.cancel_schedule_once.failed_alert.${data.result}`
                ),
                width: DialogSize.SMALL
              }
            ]);
          this.scheduleCanceled(data);
        });
    },
    openCancelAutoScheduleDialog() {
      this.openDialog([
        'DisplayProductDisplayCancelAutoScheduleDialog',
        {
          productDisplayId: this.display.id,
          productDisplayName: this.display.name,
          scheduledAt: this.scheduledAt
        }
      ]).then(eventBus =>
        eventBus.$on('schedule-canceled', this.scheduleCanceled)
      );
    },
    scheduleCanceled(data) {
      this.UPDATE_ORG_DISPLAY(data);
      this.UPDATE_IS_STATUS_UPDATED(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.DisplayProductDisplayActions {
  display: flex;
}

.DisplayProductDisplayActions__button-wrapper {
  flex: 1;

  &--right {
    text-align: right;
  }
}
</style>

<i18n locale="ko">
{
  "schedule": {
    "button": "예약 진열",
    "complete": {
      "title": "진열 예약 완료",
      "content_html": "{0}에 대한 진열 예약을 완료했습니다.<br /><br />1회 진열 예약: {1}"
    }
  },
  "register_auto_schedule": {
    "complete": {
      "title": "진열 예약 완료",
      "content_html": {
        "main": "{0}에 대한 진열 예약을 완료했습니다.<br /><br />반복 진열 예약:<br />매주 {1}요일의 오전 8시부터 오후 11시까지<br />3시간에 한번씩 진열합니다.",
        "category": "총 {0}개의 카테고리에 대한 진열 예약을 완료하였습니다.<br /><br />반복 진열 예약:<br />매주 {1}요일의 오전 8시부터 오후 11시까지<br />3시간에 한번씩 진열합니다."
      }
    }
  },
  "alert_sub_category_display": {
    "title": "하위분류진열 분류가 포함 되어있습니다.",
    "content_html": "{0}<br /><br />카페24에서 [하위분류 상품진열 - 진열함] 설정된 카테고리는<br />미리보기에 설정한 상품 순서대로 진열되지 않을 수 있습니다.",
    "omitted_count": "{0}<br />외 {1}개"
  },
  "confirm_undisplayed_products": {
    "title": "미노출 상태인 상품이 포함되어 있습니다.",
    "content_html": "솔루션사에서 미노출 상태로 설정된 상품이 포함되어 있습니다.<br />계속 진행할 경우 미노출 상태인 상품도 쇼핑몰 페이지에 노출 및 진열되니 유의하시기 바랍니다."
  },
  "scheduled": {
    "button": {
      "label": "예약됨",
      "tooltip": {
        "once": "1회 진열 예약: {0}",
        "auto": "반복 진열 예약: {0}"
      }
    },
    "menu": {
      "scheduled_cohort": "진열 예약 내역 보기",
      "update_schedule": "반복 진열 설정 변경",
      "cancel": "진열 예약 취소"
    }
  },
  "schedule_now": {
    "button": "즉시 진열",
    "confirm": {
      "title": "즉시 진열",
      "content_html": "{0}에 대한 진열을 진행할 준비가 완료 되었습니다.<br />즉시 진열 버튼을 누르면 시작합니다!"
    },
    "complete": {
      "title": "완료했습니다.",
      "content_html": "{0}에 대한 진열이 진행 중입니다.<br />곧 해당 진열이 쇼핑몰에 반영됩니다."
    }
  }
}
</i18n>
