<template>
  <AppAjaxContent
    :is-loading="isLoading"
    class="ReviewSmartInstallationSettingsInstallation"
  >
    <AppForm
      :id="SETTINGS_ID"
      v-bind="formProps"
      submit-button
      v-on="formEvents"
    >
      <template #group="{ id }">
        <template v-if="id === 'use_score_widget_v2'">
          <div class="AppForm__group-description">
            {{ $t('use_score_widget_v2.description') }}
            <!-- eslint-disable vue/no-v-html -->
            <span
              class="ReviewSmartInstallationSettingsInstallation__show-more-link"
              @click="openAlignStarIconsGuideDialog"
              v-html="$t('show_more_link')"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </template>
        <template v-else-if="id === 'enable_remove_hard_coded_crema_class'">
          <div
            :class="[
              'AppForm__group-field',
              {
                'AppForm__group-field--disabled': !formObject[
                  'enable_remove_hard_coded_crema_class'
                ]
              }
            ]"
          >
            <div class="AppForm__sub-group">
              <div class="AppForm__sub-group-item">
                <AppTags
                  v-model="formObject['remove_hard_coded_crema_class_skins']"
                  name="remove_hard_coded_crema_class_skins[]"
                  :disabled="
                    !formObject['enable_remove_hard_coded_crema_class']
                  "
                  :placeholder="
                    $t('remove_hard_coded_crema_class_skins.placeholder')
                  "
                />
                <div class="AppForm__sub-group-hint">
                  {{ $t('remove_hard_coded_crema_class_skins.hint') }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import ReviewPopupPageType from '@/enums/ReviewPopupPageType';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'ReviewSmartInstallationSettingsInstallation',
  mixins: [FormView],
  data() {
    return {
      isLoading: true
    };
  },
  computed: {
    SETTINGS_ID() {
      return 'review_smart_installation_settings_installation';
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'popup_install_position',
              groupDescription: this.$t('popup_install_position.description'),
              type: 'multiple_select_checkbox',
              packingMethod: 'bit_flag',
              options: ReviewPopupPageType.options()
            },
            {
              id: 'enable_widget_observe_install',
              groupDescription: this.$t(
                'enable_widget_observe_install.description'
              ),
              type: 'checkbox'
            },
            {
              id: 'use_score_widget_v2',
              type: 'checkbox'
            },
            {
              id: 'enable_remove_hard_coded_crema_class',
              type: 'checkbox',
              groupDescription: this.$t(
                'enable_remove_hard_coded_crema_class.description'
              ),
              superAdmin: true
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get('/smart_installation/settings')
      .then(({ data }) => this.setOrgFormObject(data))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    isInstallPositionDeleted() {
      return (
        (this.formObject.popup_install_position &
          this.orgFormObject.popup_install_position) <
        this.orgFormObject.popup_install_position
      );
    },
    deletedInstallPositions() {
      const deletedPositions = ReviewPopupPageType.options()
        .filter(
          option =>
            (option.value & this.formObject.popup_install_position) === 0 &&
            (option.value & this.orgFormObject.popup_install_position) > 0
        )
        .map(option => option.label);
      return deletedPositions.join(', ');
    },
    submit(formData) {
      if (
        this.isInstallPositionDeleted() &&
        !confirm(
          this.$t('popup_install_position.delete_confirm_message', [
            this.deletedInstallPositions()
          ])
        )
      ) {
        return;
      }
      this.isSubmitting = true;
      api
        .patch('/smart_installation/settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.setOrgFormObject(this.formObject);
        })
        .finally(() => (this.isSubmitting = false));
    },
    setOrgFormObject(orgFormObject) {
      this.orgFormObject = orgFormObject;
    },
    openAlignStarIconsGuideDialog() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('use_score_widget_v2.title'),
          markdownText: this.$t('use_score_widget_v2.message_markdown'),
          width: DialogSize.SMALL
        }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewSmartInstallationSettingsInstallation__show-more-link {
  @include text-caption;

  text-decoration: underline;
  &:hover {
    @include text-caption-dark;
    cursor: pointer;
    user-select: none;
  }
}
</style>

<i18n locale="ko">
  {
    "popup_install_position": {
      "description": "선택한 페이지 및 버튼에 작성 팝업이 노출되도록 설치합니다. 체크 박스를 해지할 경우 작성 팝업이 제거됩니다.",
      "delete_confirm_message": "{0}에 설치된 작성 팝업을 제거하시겠습니까?"
    },
    "enable_widget_observe_install": {
      "description": "페이지 로딩이 종료된 이후에 추가되는 요소에도 크리마 위젯 및 쇼핑몰 기존 위젯 숨김 등 간편 설치를 진행할 수 있습니다."
    },
    "use_score_widget_v2": {
      "title": "별점 아이콘 정렬 개선",
      "description": "리뷰 수 위젯, 평점 위젯에서 별점 아이콘과 리뷰 수, 평점 텍스트 정보의 정렬이 어긋날 경우 이 설정을 통해 개선할 수 있습니다.",
      "message_markdown": "아래 이미지와 같이 리뷰 수 위젯, 평점 위젯에서 별점 아이콘과 리뷰 수, 평점 텍스트 정보의 정렬이 어긋날 경우 이 설정을 통해 정렬을 개선할 수 있습니다.<br><br>![](https://assets.cre.ma/m/admin/v2/use_score_widget_v2.png)"
    },
    "enable_remove_hard_coded_crema_class": {
      "description": "수동 설치 크리마 요소를 동적 제거하기 위한 설정입니다. 설정시 수동 설치된 위젯이 쇼핑몰에 노출되지 않으니 주의해주세요."
    },
    "remove_hard_coded_crema_class_skins": {
      "placeholder": "특정 스킨에만 적용할 경우 스킨 정보를 입력해주세요.",
      "hint": "스킨 정보를 입력하지 않을 경우 모든 스킨에 수동 설치된 크리마 요소가 동적 제거됩니다."
    },
    "show_more_link": "자세히"
  }
</i18n>
