<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{
      ...formProps,
      submitLabel: $t('execute'),
      submittingLabel: $t('executing'),
      objectId: 'smart_installation_inspect'
    }"
    v-on="formEvents"
  />
</template>

<script>
import api from '@/lib/api';

import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'TheSmartInstallationInspectDialog',
  mixins: [DialogFormView],
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'asana_task_url',
              label: this.$t('asana_task.label'),
              type: 'text',
              groupDescription: this.$t('asana_task.description'),
              placeholder: this.$t('asana_task.placeholder'),
              validate: [
                'required',
                {
                  rule: v => v && this.isValidAsanaUrl(v),
                  errorMessage: this.$t('asana_task.error')
                }
              ],
              required: true
            }
          ]
        }
      ];
    }
  },
  methods: {
    submit() {
      this.isSubmitting = true;
      const asanaTaskId = this.getAsanaTaskId(this.formObject.asana_task_url);

      api
        .post(
          '/smart_installation/inspects',
          { asana_task_id: asanaTaskId },
          {
            successMessage: this.$t('app.created')
          }
        )
        .then(() => this.close(true))
        .finally(() => (this.isSubmitting = false));
    },
    isValidAsanaUrl(url) {
      return /^https:\/\/app\.asana\.com\/0\/[0-9]+\/[0-9]+/.test(url);
    },
    getAsanaTaskId(taskUrl) {
      const taskUrlPath = new URL(taskUrl).pathname;
      return taskUrlPath
        .replace('/f', '')
        .split('/')
        .pop();
    }
  }
};
</script>

<i18n locale="ko">
  {
    "title": "위젯 설치 검수",
    "execute": "실행",
    "executing": "실행중",
    "asana_task": {
      "label" : "Asana Task",
      "description" : "검수 과정 중 캡쳐한 스크린샷을 기록할 Task Link를 입력해주세요.",
      "placeholder" : "https://app.asana.com/",
      "error": "잘못된 형식의 url입니다."
    }
  }
</i18n>
