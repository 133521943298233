var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TheEasyJoin"},[_c('div',{staticClass:"TheEasyJoin__inner"},[_c('AppSvgIcon',{staticClass:"TheEasyJoin__logo",attrs:{"name":"icon-crema-logo","height":30}}),(_vm.shopBuilder !== 'makeshop')?_c('AppInfoBox',{staticClass:"TheEasyJoin__info-box"},[_c('i18n',{attrs:{"path":"info.content"}},[_c('b',[_vm._v(_vm._s(_vm.$t('info.complete')))])])],1):_vm._e(),_c('AppProgressSteps',{staticClass:"TheEasyJoin__progress-step",attrs:{"steps":[
        _vm.$t('easy_join_progress_step.create_account'),
        _vm.$t('easy_join_progress_step.manager_info')
      ],"current-step":0}}),_c('AppSpinnerContent',{attrs:{"description":_vm.$t('submit_content.description'),"sub-descriptions":_vm.subDescriptions,"is-loading":_vm.isSubmitting}},[_c('AppForm',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.failureCount === 0 && _vm.errorType === null),expression:"failureCount === 0 && errorType === null"}],attrs:{"id":"easyJoin","object-id":"registration_user","submit-button":{
          submitLabel: _vm.$t('submit'),
          buttonClasses: ['TheEasyJoin__submit']
        }},on:{"blur-group":_vm.formGroupBlurred},scopedSlots:_vm._u([{key:"group",fn:function(ref){
        var id = ref.id;
        var error = ref.error;
return [(id === 'email')?[_c('AppEmailVerificationInput',{attrs:{"id":"email","name":"registration_user[email]","placeholder":_vm.$t('email.placeholder'),"enable-verification":false,"invalid":error && !error.after},on:{"blur":function($event){return _vm.validateField('email')},"change":function($event){return _vm.validateField('email')}},model:{value:(_vm.formObject.email),callback:function ($$v) {_vm.$set(_vm.formObject, "email", $$v)},expression:"formObject.email"}})]:(id === 'terms')?[_c('AppTermsAgreementCheckbox',{attrs:{"id":"terms","name":"registration_user[terms][]","invalid":!!error},on:{"change":function($event){return _vm.validateField('terms')}},model:{value:(_vm.formObject.terms),callback:function ($$v) {_vm.$set(_vm.formObject, "terms", $$v)},expression:"formObject.terms"}})]:_vm._e()]}},{key:"description",fn:function(ref){
        var id = ref.id;
return [(_vm.shopBuilder === 'godo' && id === 'mall_id')?_c('div',{staticClass:"AppForm__description"},[_c('AppInlineButton',{attrs:{"button-style":"caption","label":_vm.$t('godo.mall_id.description')},on:{"click":_vm.clickMallIdDescription}})],1):_vm._e(),(_vm.shopBuilder === 'makeshop' && id === 'api_key')?_c('div',{staticClass:"TheEasyJoin__makeshop-api-key-description"},[_c('i18n',{attrs:{"path":"makeshop.api_key.description"}},[_c('AppExternalLink',{attrs:{"label":_vm.$t('makeshop.api_key.help_center'),"to":_vm.help_center_link}})],1)],1):_vm._e()]}}])},'AppForm',_vm.formProps,false),_vm.formEvents),[_c('input',{attrs:{"type":"hidden","name":"registration_user[shop_key]"},domProps:{"value":_vm.shop_key}}),(_vm.shopBuilder == 'godo')?_c('input',{attrs:{"type":"hidden","name":"registration_user[service_code]"},domProps:{"value":_vm.service_code}}):_vm._e()]),(_vm.errorType !== null)?_c('TheEasyJoinError',_vm._b({on:{"submit":function($event){return _vm.formEventBus.$emit('submit')}}},'TheEasyJoinError',{ errorType: _vm.errorType, shopBuilder: _vm.shopBuilder },false)):(_vm.failureCount > 0)?_c('TheEasyJoinRetry',{on:{"submit":function($event){return _vm.formEventBus.$emit('submit')}}}):_vm._e()],1)],1),_c('TheDialogs')],1)}
var staticRenderFns = []

export { render, staticRenderFns }