<template>
  <div class="TheLoginView">
    <div class="TheLoginView__inner">
      <AppSvgIcon
        name="icon-crema-logo"
        :height="38"
        class="TheLoginView__logo"
      />
      <div class="TheLoginView__sub-title">{{ subTitle }}</div>
      <AppInfoBox v-if="isIdleBrand" class="TheLoginView__idle-brand-summary">
        {{ $t('idle_brand_summary') }}
      </AppInfoBox>
      <div v-if="message || subMessage" class="TheLoginView__messages">
        <div v-if="message" class="TheLoginView__message">
          <pre>{{ message }}</pre>
        </div>
        <div v-if="subMessage" class="TheLoginView__sub-message">
          <pre>{{ subMessage }}</pre>
        </div>
      </div>
      <div class="TheLoginView__content">
        <slot />
        <AppLoadingScreen :is-loading="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheLoginView',
  props: {
    title: {
      type: String,
      default() {
        return this.$t('title');
      }
    },
    subTitle: {
      type: String,
      default() {
        return this.$t('sub_title');
      }
    },
    message: { type: String, default: null },
    subMessage: { type: String, default: null },
    isLoading: { type: Boolean, default: false }
  },
  data() {
    return {
      isIdleBrand: 'idle_brand' in this.$route.query
    };
  },
  mounted() {
    document.title = this.title;
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheLoginView {
  width: 320px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.TheLoginView__inner {
  flex-grow: 1;
  position: relative;
  top: -40px;
}

.TheLoginView__logo {
  display: block;
  margin: 0 auto;
  color: black;
}

.TheLoginView__sub-title {
  @include text-sub-navigation;
  margin-top: 25px;
  margin-bottom: 40px;
  letter-spacing: -0.5px;
}

.TheLoginView__messages {
  margin-bottom: 32px;
}

.TheLoginView__message {
  @include text-label;
}

.TheLoginView__sub-message {
  margin-top: 12px;
  @include text-content;
}

.TheLoginView__content {
  position: relative;
}

.TheLoginView__idle-brand-summary {
  margin-bottom: 24px;
  white-space: pre-line;
}
::v-deep {
  .TheLoginView__link {
    @include text-caption;
    margin-top: 20px;
  }

  .TheLoginView__separator {
    border-top: 1px solid $color-layout-section;
    border-bottom: 0;
    margin: 24px 0;
  }

  .TheLoginView__action-message {
    @include text-caption;
  }

  .TheLoginView__action-button {
    margin-top: 8px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "로그인",
  "sub_title": "크리마 관리페이지 로그인",
  "idle_brand_summary": "이미 가입된 쇼핑몰입니다.\n로그인하여 크리마 서비스 사용을 시작하세요."
}
</i18n>
