var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalFormLivePreview',_vm._g({attrs:{"is-loading":_vm.isLoading,"title":_vm.$t('title'),"sub-title":_vm.campaign.name,"form-props":Object.assign({}, _vm.formProps,
    {objectId: _vm.objectId}),"can-maximize":""},on:{"error-summary":_vm.updatePreviewIfValid},scopedSlots:_vm._u([{key:"group",fn:function(ref){
  var id = ref.id;
  var errors = ref.errors;
return [(id === 'widget_recommendation_units')?_c('TargetWidgetCampaignsWidgetRecommendationUnits',{attrs:{"campaign-id":_vm.campaign.id,"campaign-type":_vm.campaign.campaign_type},on:{"change":function($event){return _vm.validateField('widget_recommendation_units')}},model:{value:(_vm.formObject.widget_recommendation_units),callback:function ($$v) {_vm.$set(_vm.formObject, "widget_recommendation_units", $$v)},expression:"formObject.widget_recommendation_units"}}):(id === 'min_products_count')?_c('div',{staticClass:"AppForm__group-field"},[_c('i18n',{attrs:{"path":"min_products_count.description"}},[_c('AppNumberInput',{attrs:{"name":"campaign[widget_recommendation_settings][min_products_count]","inline":"","digits":2,"invalid":!!errors['min_products_count']},on:{"blur":function($event){return _vm.validateField('min_products_count')},"change":function($event){return _vm.validateField('min_products_count')}},model:{value:(
            _vm.formObject.widget_recommendation_settings.min_products_count
          ),callback:function ($$v) {_vm.$set(_vm.formObject.widget_recommendation_settings, "min_products_count", $$v)},expression:"\n            formObject.widget_recommendation_settings.min_products_count\n          "}}),_vm._v(" "+_vm._s(_vm.defaultRecommendationUnitName))],1)],1):(id === 'alternative_recommendation_count')?_c('div',{staticClass:"AppForm__group-field"},[_c('i18n',{attrs:{"path":"alternative_recommendation_count.description"}},[_vm._v(" "+_vm._s(_vm.defaultRecommendationUnitName)+" "),_c('AppNumberInput',{attrs:{"name":"campaign[widget_recommendation_settings][alternative_recommendation_count]","inline":"","digits":2,"invalid":!!errors['alternative_recommendation_count']},on:{"blur":function($event){return _vm.validateField('alternative_recommendation_count')},"change":function($event){return _vm.validateField('alternative_recommendation_count')}},model:{value:(
            _vm.formObject.widget_recommendation_settings
              .alternative_recommendation_count
          ),callback:function ($$v) {_vm.$set(_vm.formObject.widget_recommendation_settings
              , "alternative_recommendation_count", $$v)},expression:"\n            formObject.widget_recommendation_settings\n              .alternative_recommendation_count\n          "}})],1),(_vm.isReviewHighlightWidgetCampaign)?_c('AppFormHint',{attrs:{"message":_vm.$t('alternative_recommendation_count.review_highlight_caption')}}):_vm._e(),_c('div',{staticClass:"AppForm__group-field"},[_c('div',[_vm._v(_vm._s(_vm.$t('alternative_main_title_text.label')))]),_c('AppKeywordTextarea',{staticClass:"AppForm__group-field",attrs:{"name":"campaign[widget_recommendation_settings][alternative_main_title_text]","keywords":_vm.alternativeMainTitleTextKeywords,"single-line":""},model:{value:(
            _vm.formObject.widget_recommendation_settings
              .alternative_main_title_text
          ),callback:function ($$v) {_vm.$set(_vm.formObject.widget_recommendation_settings
              , "alternative_main_title_text", $$v)},expression:"\n            formObject.widget_recommendation_settings\n              .alternative_main_title_text\n          "}}),_c('AppFormHint',{attrs:{"message":_vm.$t('alternative_main_title_text.hint')}})],1)],1):_vm._e()]}}])},_vm.formEvents),[_c('TargetCampaignProductRecommendationWidgetPreview',{attrs:{"slot":"left","show-pc-as-default":false,"current-version":_vm.currentVersion,"display-version":_vm.displayVersion},slot:"left"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }