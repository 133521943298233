<template>
  <button type="button" class="TheHelpButton" @click="$emit('click')">
    <AppSvgIcon name="icon-help" class="TheHelpButton__icon" />
  </button>
</template>

<script>
export default {
  name: 'TheHelpButton'
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_transitions.scss';

$help-button-size: 49px;

.TheHelpButton {
  @include transition-basic(box-shadow);

  display: inline-block;
  position: relative;

  width: $help-button-size;
  height: $help-button-size;

  border: solid 1px #ceced0;
  border-radius: 50%;

  background: white;
  color: $color-button-text;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2);
  }

  &,
  &:active {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.TheHelpButton__icon {
  display: block;
  margin: auto;
}

.TheHelpButton__badge {
  position: absolute;
  top: 0;
  right: -4px;
}
</style>
