<template>
  <div class="AppDateRangeWithType">
    <AppInputGroupItem>
      <AppSelect
        class="AppDateRangeWithType__date-type"
        :options="dateTypes"
        :value="value.date_type"
        @change="$emit('change', { ...value, date_type: $event })"
      />
    </AppInputGroupItem>
    <AppInputGroupItem class="AppDateRangeWithType__date-range">
      <AppDateRangePicker
        v-model="dateRange"
        class="AppDateRangeWithType__date-range-picker"
      />
    </AppInputGroupItem>
  </div>
</template>

<script>
export default {
  name: 'AppDateRangeWithType',
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    dateTypes: {
      type: Array,
      required: true
    }
  },
  computed: {
    dateRange: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('change', { ...this.value, ...newValue });
        this.$emit('apply');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_breakpoints.scss';

.AppDateRangeWithType__date-type,
.AppDateRangeWithType__date-range-picker {
  vertical-align: middle;
}

@include media-breakpoint-each(mobile) {
  .AppDateRangeWithType {
    display: flex;
  }

  .AppDateRangeWithType__date-range {
    flex-grow: 1;
  }

  .AppDateRangeWithType__date-range-picker {
    display: block;
  }
}
</style>
