<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="campaign.name"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId: 'widget_recommendation_settings'
    }"
    v-on="formEvents"
  >
    <template #group-label="{ group, value }">
      <template v-if="group.id === 'use_exclude_category_ids' && !value">
        {{ $t('use_exclude_category_ids.false') }}
        <AppInternalLink :to="`/product/settings`" target="_blank">
          <AppExternalLinkIcon
            size="small"
            class="TargetCampaignSettingsWidgetExcludeCategoryIdsDialog__link-icon"
          />
        </AppInternalLink>
      </template>
    </template>
    <template #group-sub-item="{ group, value, checked, errors }">
      <template v-if="group.id === 'use_exclude_category_ids'">
        <template v-if="value">
          <AppSelectProductCategory
            v-model="formObject.exclude_category_ids"
            multiple
            :disabled="!formObject.use_exclude_category_ids"
            :invalid="!!errors.exclude_category_ids"
            name="campaign[widget_recommendation_settings][exclude_category_ids][]"
            @change="validateField('exclude_category_ids')"
          />
          <AppFormHint :message="$t('hint')" :disabled="!checked" />
          <AppFormError :error="errors.exclude_category_ids" />
        </template>
        <template v-else>
          <AppFormHint :message="productSettingsText" :disabled="!checked" />
        </template>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'TargetCampaignSettingsWidgetExcludeCategoryIdsDialog',
  mixins: [DialogFormView],
  props: {
    campaign: { type: Object, required: true },
    recommendationExcludingCategoryInfo: { type: Object, required: true }
  },
  data: () => ({ isLoading: true }),
  computed: {
    productSettingsText() {
      if (!this.recommendationExcludingCategoryInfo) return;

      const count =
        this.recommendationExcludingCategoryInfo.count == 1
          ? 'one'
          : 'multiple';
      return this.$t(`product_settings_text.${count}`, [
        this.recommendationExcludingCategoryInfo.name,
        this.recommendationExcludingCategoryInfo.count - 1
      ]);
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'use_exclude_category_ids',
              type: 'select_radio',
              name:
                'campaign[widget_recommendation_settings][use_exclude_category_ids]',
              label: this.$t('use_exclude_category_ids.label'),
              options: [
                {
                  value: false
                },
                {
                  label: this.$t('use_exclude_category_ids.true'),
                  value: true
                }
              ],
              fields: this.formObject.use_exclude_category_ids
                ? [
                    {
                      id: 'exclude_category_ids',
                      validate: ['required']
                    }
                  ]
                : []
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get(
        `/target/campaigns/${this.campaign.id}/widget_recommendation_settings`
      )
      .then(({ data }) => {
        this.orgFormObject = data.widget_recommendation_settings;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/target/campaigns/${this.campaign.id}/widget_recommendation_settings`,
          formData,
          { successMessage: this.$t('app.saved') }
        )
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style scoped>
.TargetCampaignSettingsWidgetExcludeCategoryIdsDialog__link-icon {
  vertical-align: top;
  margin-top: 4px;
  margin-left: 4px;
}
</style>

<i18n locale="ko">
{
  "title": "추천 기준 제외 카테고리",
  "use_exclude_category_ids": {
    "label": "카테고리 선택",
    "true": "해당 캠페인에서 추가로 제외할 카테고리 선택",
    "false": "상품 설정 메뉴에 따름"
  },
  "hint": "[상품 설정] 메뉴에서 선택된 카테고리 외 추가로 제외할 카테고리를 선택할 수 있습니다."  ,
  "product_settings_text": {
    "one": "현재 {0} 카테고리가 제외되어 있습니다.",
    "multiple": "현재 {0} 카테고리 외 {1}개가 제외되어 있습니다."
  }
}
</i18n>
