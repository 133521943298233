<template>
  <AppTabContent menu-item-id="review_reports" :params="tabParams">
    <AppAjaxContent :is-loading="isLoading">
      <RouterView
        :download-excel-url-base="downloadExcelUrlBase"
        :reports-range="reportsRange"
        :reports="reports"
        :resource-params="resourceParams"
        :pagination="pagination"
        @change-resource-params="changeResourceParams"
        @change-pagination="changePagination"
      />
    </AppAjaxContent>
  </AppTabContent>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import api from '@/lib/api';
import { trimParams } from '@/lib/params';

const DEFAULT_PAGINATION = { page: 1, per: 20 };

export default {
  name: 'ReviewReports',
  data() {
    return {
      downloadExcelUrlBase: '/api/review/reports/download_excel.xlsx',
      reports: [],
      reportsRange: {},
      isLoading: true,
      resourceParams: {},
      pagination: DEFAULT_PAGINATION
    };
  },
  computed: {
    defaultResourceParams: () => ({
      interval: 'daily',
      start_date: moment()
        .subtract(8, 'day')
        .toVal(),
      end_date: moment()
        .subtract(1, 'day')
        .toVal()
    }),
    tabParams() {
      return trimParams(
        _.omit(this.resourceParams, 'page'),
        this.defaultResourceParams
      );
    }
  },
  watch: {
    $route() {
      this.changeRoute();
    }
  },
  created() {
    this.changeRoute();
  },
  methods: {
    changeRoute() {
      this.setResourceParams({
        ...this.defaultResourceParams,
        ..._.omit(this.$route.query, 'per', 'page')
      });
      this.pagination = {
        ...DEFAULT_PAGINATION,
        ..._.chain(this.$route.query)
          .pick('page', 'per')
          .mapValues(v => parseInt(v))
          .value()
      };
    },
    fetchResource() {
      this.isLoading = true;
      api
        .get('/review/reports', {
          params: {
            ...this.$store.state.brandParams,
            ...this.resourceParams
          }
        })
        .then(({ data }) => {
          this.reportsRange = { ...this.resourceParams };
          this.reports = data.review_reports;
        })
        .finally(() => (this.isLoading = false));
    },
    setResourceParams(params) {
      const newParams = { ...this.resourceParams, ...params };
      if (_.isEqual(this.resourceParams, newParams)) return;

      this.resourceParams = newParams;
      this.fetchResource();
    },
    changeResourceParams(params) {
      this.setResourceParams(params);
      this.$set(this.pagination, 'page', 1);
      this.pushRoute();
    },
    changePagination(newPagination) {
      this.pagination = newPagination;
      this.pushRoute();
    },
    pushRoute() {
      const defaultParams = {
        ...this.defaultResourceParams,
        ..._.mapValues(DEFAULT_PAGINATION, v => v.toString())
      };
      const query = trimParams(
        {
          ...this.$store.state.brandParams,
          ...this.resourceParams,
          per: this.pagination.per.toString(),
          page: this.pagination.page.toString()
        },
        defaultParams
      );
      this.$router.push({ query });
    }
  }
};
</script>
