<template>
  <AppModalDefault
    class="InstallRequestRenewalDirectionDialog"
    :title="$t('title')"
    is-maximized-by-default
    @close="clickCloseButton"
  >
    <template #sub-head>
      <AppProgressSteps
        class="InstallRequestRenewalDirectionDialog__progress-step"
        :steps="steps"
        :current-step="installRequestCurrentStep"
      />
    </template>
    <template #body>
      <div class="InstallRequestRenewalDirectionDialog__containter">
        <div class="InstallRequestRenewalDirectionDialog__title">
          {{ stepName(installRequestCurrentStep) }}
        </div>
        <AppInfoBox
          v-if="infoBoxMessage()"
          class="InstallRequestRenewalDirectionDialog__info-box"
        >
          <AppMarked :text="infoBoxMessage()" />
        </AppInfoBox>
        <Component :is="currentStepComponent" />
      </div>
    </template>
    <template #foot>
      <div class="InstallRequestRenewalDirectionDialog__buttons">
        <AppButton
          v-if="!isFirstStep"
          :label="$t('button.prev')"
          button-style="grey-outline"
          @click="clickPrevPageButton"
        />
        <AppButton
          v-if="!isLastStep"
          :label="$t('button.next')"
          button-style="blue"
          @click="clickNextPageButton"
        />
        <AppButton
          v-if="isLastStep"
          :label="$t('button.complete')"
          button-style="blue"
        />
      </div>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import DialogView from '@/mixins/DialogView';
import InstallRequestProgressStep from '@/enums/InstallRequestProgressStep';
import InstallRequestRenewalDirectionDialogProductDetail from './InstallRequestRenewalDirectionDialogProductDetail';
import InstallRequestRenewalDirectionDialogReviewBoard from './InstallRequestRenewalDirectionDialogReviewBoard';
import InstallRequestRenewalDirectionDialogMain from './InstallRequestRenewalDirectionDialogMain';
import InstallRequestRenewalDirectionDialogEtc from './InstallRequestRenewalDirectionDialogEtc';
import InstallRequestRenewalDirectionDialogCountAndScore from './InstallRequestRenewalDirectionDialogCountAndScore';
import InstallRequestRenewalDirectionDialogConfirm from './InstallRequestRenewalDirectionDialogConfirm';

export default {
  name: 'InstallRequestRenewalDirectionDialog',
  components: {
    InstallRequestRenewalDirectionDialogProductDetail,
    InstallRequestRenewalDirectionDialogReviewBoard,
    InstallRequestRenewalDirectionDialogMain,
    InstallRequestRenewalDirectionDialogEtc,
    InstallRequestRenewalDirectionDialogCountAndScore,
    InstallRequestRenewalDirectionDialogConfirm
  },
  mixins: [DialogView],
  computed: {
    ...mapState('installRequest', [
      'installRequestCurrentStep',
      'bubbleVisibility'
    ]),
    steps() {
      return InstallRequestProgressStep.values.map(
        InstallRequestProgressStep.t
      );
    },
    isFirstStep() {
      return (
        this.installRequestCurrentStep ===
        InstallRequestProgressStep.PRODUCT_DETAIL
      );
    },
    isLastStep() {
      return (
        this.installRequestCurrentStep === InstallRequestProgressStep.CONFIRM
      );
    },
    currentStepComponent() {
      switch (this.installRequestCurrentStep) {
        case InstallRequestProgressStep.REVIEW_BOARD:
          return InstallRequestRenewalDirectionDialogReviewBoard;
        case InstallRequestProgressStep.MAIN:
          return InstallRequestRenewalDirectionDialogMain;
        case InstallRequestProgressStep.ETC:
          return InstallRequestRenewalDirectionDialogEtc;
        case InstallRequestProgressStep.COUNT_AND_SCORE:
          return InstallRequestRenewalDirectionDialogCountAndScore;
        case InstallRequestProgressStep.CONFIRM:
          return InstallRequestRenewalDirectionDialogConfirm;
        default:
          return InstallRequestRenewalDirectionDialogProductDetail;
      }
    }
  },
  created() {
    this.SET_ALL_BUBBLE_VISIBLE();
  },
  methods: {
    ...mapActions('dialog', ['confirm']),
    ...mapMutations('installRequest', [
      'SET_INSTALL_REQUEST_STEP',
      'SET_BUBBLE_INVISIBLE',
      'SET_ALL_BUBBLE_VISIBLE',
      'CLEAR_INSTALLATIONS'
    ]),
    stepName(step) {
      return InstallRequestProgressStep.t(step);
    },
    infoBoxMessage() {
      if (
        this.installRequestCurrentStep ===
        InstallRequestProgressStep.COUNT_AND_SCORE
      )
        return this.$t('widget_install_info_box.count_and_score');
      else if (
        this.installRequestCurrentStep === InstallRequestProgressStep.CONFIRM
      )
        return this.$t('widget_install_info_box.confirm');
      else return this.$t('widget_install_info_box.message');
    },
    clickPrevPageButton() {
      this.SET_INSTALL_REQUEST_STEP(this.installRequestCurrentStep - 1);
    },
    clickNextPageButton() {
      if (this.bubbleVisibility.cardButton)
        this.SET_BUBBLE_INVISIBLE('cardButton');
      this.SET_INSTALL_REQUEST_STEP(this.installRequestCurrentStep + 1);
    },
    clickCloseButton() {
      this.confirm({
        title: this.$t('close_confirm.title'),
        message: this.$t('close_confirm.message'),
        closeButtonLabel: this.$t('close_confirm.button')
      }).then(result => {
        if (!result) return;

        this.CLEAR_INSTALLATIONS();
        this.close();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
::v-deep {
  .AppModalDefault__body {
    padding: 0;
  }
}

.InstallRequestRenewalDirectionDialog__progress-step {
  padding: 16px 0 3px 0;
}

.InstallRequestRenewalDirectionDialog__containter {
  width: 900px;
  margin: 0 auto;
}

.InstallRequestRenewalDirectionDialog__title {
  @include text-title;

  padding-top: 32px;
  text-align: center;
}

.InstallRequestRenewalDirectionDialog__info-box {
  margin-top: 8px;
}

.InstallRequestRenewalDirectionDialog__buttons {
  text-align: center;
}
</style>

<i18n locale="ko">
{
  "title": "위젯 설치 요청",
  "widget_install_step": {
    "product_detail": "상품 상세 페이지",
    "main": "메인 페이지",
    "review_board": "전체 리뷰 페이지",
    "count_and_score": "리뷰 수 위젯",
    "etc": "기타 페이지",
    "confirm": "요청 내용 확인"
  },
  "widget_install_info_box": {
    "count_and_score": "리뉴얼 스킨의 디자인(레이아웃)을 고려하여 리뷰 수 위젯 설치 요청 내용을 입력해주세요.<br /><b>상품 상세 페이지에 노출되는 리뷰 수는 크리마 리뷰 수로 교체해드립니다.</b>",
    "confirm": "요청 내용을 최종 확인해주세요. 접수된 수정 요청은 변경이 번거로우니 꼼꼼히 확인해주세요 🙌<br /><b>리뉴얼 스킨의 디자인(레이아웃)에 알맞지 않은 요청은 반영되지 않을 수 있습니다.</b>",
    "message": "리뉴얼 스킨의 디자인(레이아웃)을 고려하여 설치할 위치와 위젯을 선택해주세요."
  },
  "button": {
    "prev": "\u003C 이전으로 돌아가기",
    "next": "저장 후 다음으로 이동 \u003E",
    "complete": "완료"
  },
  "close_confirm": {
    "title": "다음에 다시 하시겠습니까?",
    "message": "현재까지 입력한 정보는 저장되지 않습니다. 다음에 다시 하시겠습니까?",
    "button": "다음에 다시하기"
  }
}
</i18n>
