var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['AppTabs', { 'AppTabs--sticky': _vm.sticky }]},[_c('div',{ref:"scroll",staticClass:"AppTabs__scroll"},[_c('ul',{staticClass:"AppTabs__list"},_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.id,ref:"tabElements",refInFor:true,class:[
          'AppTabs__tab',
          {
            'AppTabs__tab--active': tab.id === _vm.activeTab,
            'super-admin__item': tab.superAdmin
          }
        ]},[_c(tab.path ? 'AppInternalLink' : 'a',{tag:"Component",staticClass:"AppTabs__tab-link",attrs:{"to":tab.path},on:{"click":function($event){return _vm.$emit('change', tab.id)}}},[_vm._v(_vm._s(tab.label))]),(tab.badge)?_c('AppBadge',{staticClass:"AppTabs__tab-badge",attrs:{"badge-style":tab.badge.style,"label":tab.badge.label}}):_vm._e()],1)}),0),_c('div',{class:[
        'AppTabs__ink-bar',
        { 'AppTabs__ink-bar--animation': _vm.enableInkBarAnimation }
      ],style:({
        left: (_vm.activeTabOffsetLeft + "px"),
        width: (_vm.activeTabWidth + "px")
      })})])])}
var staticRenderFns = []

export { render, staticRenderFns }