<template>
  <div>
    <AppTable
      table-id="review-settings-widgets-normal-form-order-types-table"
      :columns="COLUMNS"
      :rows="addTooltipToRows(options)"
    >
      <template #cell="{ row, column }">
        <template v-if="column.id === 'types_in_use'">
          <AppCheckbox
            :checked="isSelected(row)"
            :name="packingMethod === 'array' ? name : null"
            :value="row.value"
            :disabled="isMostScoresDisabled(row)"
            @change="$event ? select(row) : unselect(row)"
          />
        </template>
        <template v-else-if="column.id === 'default_type'">
          <AppRadio
            :value="row.value"
            :selected-value="defaultType"
            :disabled="!isSelected(row) || isMostScoresDisabled(row)"
            input-style="standalone"
            @change="defaultType = $event"
          />
        </template>
        <template v-else-if="column.id === 'type_name'">
          <div>
            <slot name="label" :value="row.value" :checked="isSelected(row)">
              {{ row.label }}
            </slot>
          </div>
        </template>
      </template>
    </AppTable>
    <template v-if="name">
      <input
        v-if="packingMethod !== 'array'"
        type="hidden"
        :name="name"
        :value="value"
      />
      <input v-else-if="currentValue.length === 0" type="hidden" :name="name" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import FormView from '@/mixins/FormView';
import MultipleSelectable from '@/mixins/MultipleSelectable';
import ProductSortType from '@/enums/ProductSortType';
import ReviewSort from '@/enums/ReviewSort';

export default {
  name: 'ReviewSettingsWidgetsNormalFormOrderTypes',
  mixins: [FormView, MultipleSelectable],
  props: {
    name: { type: String, default: null },
    orgDefaultType: { type: Number, default: null }
  },
  data() {
    return {
      COLUMNS: ['types_in_use', 'default_type', 'type_name'].map(c => ({
        id: c,
        label: this.$t(`column.${c}`)
      }))
    };
  },
  computed: {
    ...mapState('session', ['reviewSettings']),
    defaultType: {
      get() {
        return this.orgDefaultType;
      },
      set(value) {
        this.$emit('change-default-type', value);
      }
    }
  },
  methods: {
    isProductMostScoresDisabled(sortValue) {
      return (
        !this.reviewSettings.show_score_summary &&
        sortValue === ProductSortType.MOST_SCORES
      );
    },
    isReviewMostScoresDisabled(sortValue) {
      return (
        !this.reviewSettings.show_score && sortValue === ReviewSort.MOST_SCORES
      );
    },
    isMostScoresDisabled(row) {
      return (
        this.isProductMostScoresDisabled(row.value) ||
        this.isReviewMostScoresDisabled(row.value)
      );
    },
    tooltipText(sortValue) {
      if (this.isProductMostScoresDisabled(sortValue)) {
        return this.$t('tooltip.show_score_summary_disabled');
      } else if (this.isReviewMostScoresDisabled(sortValue)) {
        return this.$t(
          'review_widgets.settings.tooltip.disabled_review_sort_most_score_tooltip'
        );
      }

      return null;
    },
    addTooltipToRows(rows) {
      return rows.map(row => ({
        ...row,
        tooltip: this.tooltipText(row.value)
      }));
    }
  }
};
</script>

<style scoped>
.review-settings-widgets-normal-form-order-types-table {
  margin-top: 12px;
}
</style>

<i18n locale="ko">
{
  "column" : {
    "types_in_use": "사용 여부",
    "default_type": "기본 방식",
    "type_name": "정렬 방식"
  },
  "tooltip": {
    "show_score_summary_disabled": "표시정보 및 디자인 > 상품 평점 표시 미사용으로 인해 '상품 평점순' 정렬 사용이 불가능합니다."
  }
}
</i18n>
