import { render, staticRenderFns } from "./DisplayProductManagementUnits.vue?vue&type=template&id=334d0a88&scoped=true&"
import script from "./DisplayProductManagementUnits.vue?vue&type=script&lang=js&"
export * from "./DisplayProductManagementUnits.vue?vue&type=script&lang=js&"
import style0 from "./DisplayProductManagementUnits.vue?vue&type=style&index=0&id=334d0a88&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "334d0a88",
  null
  
)

/* custom blocks */
import block0 from "./DisplayProductManagementUnits.vue?vue&type=custom&index=0&blockType=i18n&locale=ko"
if (typeof block0 === 'function') block0(component)

export default component.exports