<template>
  <div class="KakaoStyleAgreement">
    <AppSummaryBox>
      <template #title>{{ $t('info.title') }}</template>
      <AppMarked :text="$t('info.message')" />
    </AppSummaryBox>
    <KakaoStyleApiKeyForm class="KakaoStyleAgreement__form" />
  </div>
</template>

<script>
import KakaoStyleApiKeyForm from './KakaoStyleApiKeyForm';

export default {
  name: 'KakaoStyleAgreement',
  components: { KakaoStyleApiKeyForm }
};
</script>

<style lang="scss" scoped>
.KakaoStyleAgreement__form {
  margin-top: 24px;
}
</style>

<i18n locale="ko">
{
  "info": {
    "title": "🙋‍♀️ 카카오스타일에서 뷰티 셀러로 분류된 쇼핑몰만 리뷰 연동 기능을 사용할 수 있습니다.",
    "message": "신청 방법을 포함한 자세한 안내는 [\\[카카오스타일: 크리마 리뷰 연동 서비스 안내\\] 문서](https://crema.notion.site/1b4d3d91a83146a7aa4997f57e2813c6)를 확인해주세요."
  }
}
</i18n>
