<template>
  <div>
    <div
      :class="[
        'TargetWidgetDesignFontFields__fields',
        { 'TargetWidgetDesignFontFields__fields--disabled': disabled }
      ]"
    >
      <div class="TargetWidgetDesignFontFields__field">
        <label class="TargetWidgetDesignFontFields__label" :for="fontSizeId">
          {{ $t('font_size') }}
        </label>
        <AppNumberInput
          :id="fontSizeId"
          :default="defaultObject[fontSizeId]"
          class="TargetWidgetDesignFontFields__number"
          :value="formObject[fontSizeId]"
          :name="`widget_design_attributes[${fontSizeId}]`"
          :digits="4"
          :invalid="!!errors[fontSizeId]"
          :min="1"
          :disabled="disabled"
          @blur="update(fontSizeId, formObject[fontSizeId])"
          @change="update(fontSizeId, $event)"
        />
      </div>
      <div class="TargetWidgetDesignFontFields__field">
        <label class="TargetWidgetDesignFontFields__label" :for="fontWeightId">
          {{ $t('font_weight') }}
        </label>
        <AppSelect
          :id="fontWeightId"
          :value="formObject[fontWeightId]"
          :options="TargetWidgetFontWeight.fontWeightOptions(fontWeightId)"
          :name="`widget_design_attributes[${fontWeightId}]`"
          :disabled="disabled"
          @change="update(fontWeightId, $event)"
        />
      </div>
      <div class="TargetWidgetDesignFontFields__field">
        <label class="TargetWidgetDesignFontFields__label" :for="fontColorId">
          {{ $t('font_color') }} </label
        ><AppColorPicker
          :id="fontColorId"
          :value="formObject[fontColorId]"
          :name="`widget_design_attributes[${fontColorId}]`"
          :label="$t('font_color')"
          :disabled="disabled"
          @input="update(fontColorId, $event)"
        />
      </div>
      <AppFormError
        :error="
          errors[fontSizeId] || errors[fontWeightId] || errors[fontColorId]
        "
      />
    </div>
    <div
      v-if="paddingTopId || paddingBottomId"
      class="TargetWidgetDesignFontFields__fields"
    >
      <div v-if="paddingTopId" class="TargetWidgetDesignFontFields__field">
        <label class="TargetWidgetDesignFontFields__label" :for="paddingTopId">
          {{ $t('padding_top') }}
        </label>
        <AppNumberInput
          :id="paddingTopId"
          :default="defaultObject[paddingTopId]"
          class="TargetWidgetDesignFontFields__number"
          :value="formObject[paddingTopId]"
          :name="`widget_design_attributes[${paddingTopId}]`"
          :digits="4"
          :invalid="!!errors[paddingTopId]"
          :disabled="disabled"
          @blur="update(paddingTopId, formObject[paddingTopId])"
          @change="update(paddingTopId, $event)"
        />
      </div>
      <div class="TargetWidgetDesignFontFields__field">
        <label
          class="TargetWidgetDesignFontFields__label"
          :for="paddingBottomId"
        >
          {{ $t('padding_bottom') }}
        </label>
        <AppNumberInput
          :id="paddingBottomId"
          :default="defaultObject[paddingBottomId]"
          class="TargetWidgetDesignFontFields__number"
          :value="formObject[paddingBottomId]"
          :name="`widget_design_attributes[${paddingBottomId}]`"
          :digits="4"
          :invalid="!!errors[paddingBottomId]"
          :disabled="disabled"
          @blur="update(paddingBottomId, formObject[paddingBottomId])"
          @change="update(paddingBottomId, $event)"
        />
      </div>
      <AppFormError :error="errors[paddingTopId] || errors[paddingBottomId]" />
    </div>
  </div>
</template>

<script>
import TargetWidgetFontWeight from '@/enums/TargetWidgetFontWeight';

export default {
  name: 'TargetWidgetDesignFontFields',
  props: {
    fontSizeId: { type: String, default: null },
    paddingTopId: { type: String, default: null },
    paddingBottomId: { type: String, default: null },
    fontColorId: { type: String, default: null },
    fontWeightId: { type: String, default: null },
    formObject: { type: Object, required: true },
    defaultObject: { type: Object, required: true },
    errors: { type: Object, required: true },
    disabled: { type: Boolean, defualt: false }
  },
  computed: {
    TargetWidgetFontWeight() {
      return TargetWidgetFontWeight;
    }
  },
  methods: {
    update(id, value) {
      this.$emit('update', { id, value });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
.TargetWidgetDesignFontFields__fields {
  margin-top: 4px;

  & + & {
    margin-top: 8px;
  }

  &--disabled {
    color: $color-disable-text;
  }
}

.TargetWidgetDesignFontFields__label {
  display: block;
  margin-bottom: 4px;
}

.TargetWidgetDesignFontFields__field {
  display: inline-block;
  vertical-align: top;
  & + & {
    margin-left: 24px;
  }
}

.TargetWidgetDesignFontFields__number {
  width: 68px;
}
</style>

<i18n locale="ko">
{
  "font_size": "글자 크기(px)",
  "font_weight": "글자 굵기",
  "font_color": "글자 색상",
  "padding_top": "상단 간격(px)",
  "padding_bottom": "하단 간격(px)"
}
</i18n>
