<template>
  <div class="BizMessageTemplateComments">
    <AppTable :columns="COLUMNS" :rows="rows" />
  </div>
</template>

<script>
import BizMessageTemplateCommentStatus from '@/enums/BizMessageTemplateCommentStatus';

export default {
  name: 'BizMessageTemplateComments',
  props: { comments: { type: Array, required: true } },
  computed: {
    rows() {
      return this.comments.map(comment => {
        return {
          user_name: comment.user_name,
          created_at: comment.created_at,
          status: {
            label: BizMessageTemplateCommentStatus.t(comment.status),
            badgeStyle: BizMessageTemplateCommentStatus.badgeStyle(
              comment.status
            )
          },
          content: comment.content
        };
      });
    }
  },
  created() {
    this.COLUMNS = [
      {
        id: 'user_name',
        type: 'text',
        label: this.$t('app.author'),
        align: 'left',
        width: '100px'
      },
      {
        id: 'created_at',
        type: 'datetime',
        label: this.$t('app.date'),
        width: '96px'
      },
      {
        id: 'status',
        type: 'badge',
        label: this.$t('app.type'),
        width: '60px'
      },
      {
        id: 'content',
        type: 'text',
        label: this.$t('app.content'),
        align: 'left'
      }
    ];
  }
};
</script>
