<template>
  <AppDropdownMenuButton v-bind="{ label, buttonStyle, menuItems }" />
</template>

<script>
import api from '@/lib/api';
import ReviewCampaignStatus from '@/enums/ReviewCampaignStatus';

export default {
  name: 'ReviewMessagesCampaignsManageButton',
  props: {
    campaign: { type: Object, required: true },
    showStatusLabel: { type: Boolean, default: false }
  },
  computed: {
    label() {
      if (this.showStatusLabel)
        return ReviewCampaignStatus.t(this.campaign.status);
      return this.$t('app.manage');
    },
    buttonStyle() {
      if (!this.showStatusLabel) return;
      return this.campaign.status == ReviewCampaignStatus.ENABLED
        ? 'mint-green'
        : 'red-outline';
    },
    menuItems() {
      const { messages_count } = this.campaign;
      return [
        {
          ...this.menuItem(ReviewCampaignStatus.ENABLED),
          disabled: !messages_count,
          tooltip: messages_count ? null : this.$t('tooltip.disabled')
        },
        this.menuItem(ReviewCampaignStatus.DISABLED)
      ];
    }
  },
  methods: {
    menuItem(campaignStatus) {
      return {
        label: ReviewCampaignStatus.t(campaignStatus),
        selected: this.campaign.status === campaignStatus,
        clickHandler: ({ close }) => {
          this.updateCampaignStatus(campaignStatus);
          close();
        }
      };
    },
    updateCampaignStatus(status) {
      if (status === this.campaign.status) return;

      api
        .patch(`/review/campaigns/${this.campaign.id}/status`, {
          campaign: { status }
        })
        .then(() => this.$emit('change-status', status));
    }
  }
};
</script>

<i18n locale="ko">
{
  "tooltip": {
    "disabled": "등록된 발송 메시지가 없습니다."
  }
}
</i18n>
