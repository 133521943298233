<template>
  <AppModalFormLivePreview
    :is-loading="isLoading"
    :title="$t('title')"
    :sub-title="campaign.name"
    :form-props="{
      ...formProps,
      objectId
    }"
    can-maximize
    v-on="formEvents"
    @error-summary="updatePreviewIfValid"
  >
    <TargetCampaignProductRecommendationWidgetPreview
      slot="left"
      :show-pc-as-default="false"
      :current-version="currentVersion"
      :display-version="displayVersion"
    />
    <template #group="{ id, errors }">
      <TargetWidgetCampaignsWidgetRecommendationUnits
        v-if="id === 'widget_recommendation_units'"
        v-model="formObject.widget_recommendation_units"
        :campaign-id="campaign.id"
        :campaign-type="campaign.campaign_type"
        @change="validateField('widget_recommendation_units')"
      />
      <div v-else-if="id === 'min_products_count'" class="AppForm__group-field">
        <i18n path="min_products_count.description">
          <AppNumberInput
            v-model="
              formObject.widget_recommendation_settings.min_products_count
            "
            name="campaign[widget_recommendation_settings][min_products_count]"
            inline
            :digits="2"
            :invalid="!!errors['min_products_count']"
            @blur="validateField('min_products_count')"
            @change="validateField('min_products_count')"
          />
          {{ defaultRecommendationUnitName }}</i18n
        >
      </div>
      <div
        v-else-if="id === 'alternative_recommendation_count'"
        class="AppForm__group-field"
      >
        <i18n path="alternative_recommendation_count.description">
          {{ defaultRecommendationUnitName }}
          <AppNumberInput
            v-model="
              formObject.widget_recommendation_settings
                .alternative_recommendation_count
            "
            name="campaign[widget_recommendation_settings][alternative_recommendation_count]"
            inline
            :digits="2"
            :invalid="!!errors['alternative_recommendation_count']"
            @blur="validateField('alternative_recommendation_count')"
            @change="validateField('alternative_recommendation_count')"
        /></i18n>
        <AppFormHint
          v-if="isReviewHighlightWidgetCampaign"
          :message="
            $t('alternative_recommendation_count.review_highlight_caption')
          "
        />
        <div class="AppForm__group-field">
          <div>{{ $t('alternative_main_title_text.label') }}</div>
          <AppKeywordTextarea
            v-model="
              formObject.widget_recommendation_settings
                .alternative_main_title_text
            "
            name="campaign[widget_recommendation_settings][alternative_main_title_text]"
            class="AppForm__group-field"
            :keywords="alternativeMainTitleTextKeywords"
            single-line
          />
          <AppFormHint :message="$t('alternative_main_title_text.hint')" />
        </div>
      </div>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import IframePreviewHelper from '@/mixins/IframePreviewHelper';
import TargetCampaignType from '@/enums/TargetCampaignType';
import api from '@/lib/api';

export default {
  name: 'TargetCampaignSettingsProductRecommendationDialog',
  mixins: [DialogFormView, IframePreviewHelper],
  props: { campaign: { type: Object, required: true } },
  data: () => ({
    isLoading: true
  }),
  computed: {
    ...mapGetters('targetCampaign', [
      'isCategoryPageWidgetCampaign',
      'isReviewHighlightWidgetCampaign'
    ]),
    objectId() {
      return 'campaign';
    },
    campaignId() {
      return this.campaign.id;
    },
    defaultRecommendationUnitName() {
      return this.$t(
        `default_recommendation_unit.${TargetCampaignType.key(
          this.campaign.campaign_type
        )}`
      );
    },
    formSections() {
      if (this.isLoading) return [];
      return [
        {
          groups: [
            {
              type: 'infobox',
              label: '',
              value: this.$t('disclaimer_html')
            },
            {
              id: 'widget_recommendation_units',
              label: ''
            },
            {
              id: 'min_products_count',
              label: this.$t('min_products_count.label'),
              value: this.formObject.widget_recommendation_settings
                .min_products_count,
              validate: [
                'required',
                { rule: 'min_max_integer', min: 1, max: 20 },
                {
                  rule: v =>
                    v <=
                    this.formObject.widget_recommendation_settings
                      .alternative_recommendation_count,
                  errorMessage: this.$t(
                    'min_products_count.greater_than_alternative_recommendation_count'
                  )
                }
              ]
            },
            {
              id: 'alternative_recommendation_count',
              label: this.$t('alternative_recommendation_count.label'),
              value: this.formObject.widget_recommendation_settings
                .alternative_recommendation_count,
              validate: [
                'required',
                { rule: 'min_max_integer', min: 1, max: 20 },
                {
                  rule: v =>
                    v >=
                    this.formObject.widget_recommendation_settings
                      .min_products_count,
                  errorMessage: this.$t(
                    'alternative_recommendation_count.less_than_alternative_recommendation_count'
                  )
                }
              ]
            },
            {
              id: 'exclude_purchased_products',
              type: 'checkbox',
              label: this.$t('exclude_purchased_products.label'),
              description: this.$t('exclude_purchased_products.description'),
              value: this.formObject.widget_recommendation_settings
                .exclude_purchased_products,
              name:
                'campaign[widget_recommendation_settings][exclude_purchased_products]'
            },
            {
              id: 'recommend_duplicate_products',
              type: 'checkbox',
              label: this.$t('recommend_duplicate_products.label'),
              description: this.$t('recommend_duplicate_products.description'),
              value: this.formObject.widget_recommendation_settings
                .recommend_duplicate_products,
              name:
                'campaign[widget_recommendation_settings][recommend_duplicate_products]'
            }
          ]
        }
      ];
    },
    alternativeMainTitleTextKeywords() {
      const keywordIds = ['user', 'user_id', 'brand'];
      if (
        this.isCategoryPageWidgetCampaign ||
        this.isReviewHighlightWidgetCampaign
      )
        keywordIds.push('target_category');

      return keywordIds.map(key => ({
        key,
        desc: this.$t(`keywords.${key}`),
        value: '%{key}'
      }));
    }
  },
  mounted() {
    this.orgFormObject = {
      widget_recommendation_settings: this.campaign
        .widget_recommendation_settings,
      widget_recommendation_units: this.campaign.widget_recommendation_units
    };
    this.isLoading = false;
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    updatePreview() {
      if (this.hasError) return;

      const formData = new FormData(this.$el.getElementsByTagName('form')[0]);
      const version = this.currentVersion;
      api
        .patch(
          `/target/campaigns/${this.campaignId}/widget_recommendation_settings/preview`,
          formData
        )
        .then(() => (this.displayVersion = version));
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/target/campaigns/${this.campaignId}/widget_recommendation_settings`,
          formData,
          {
            successMessage: this.$t('app.saved')
          }
        )
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppModal__left {
    padding-top: 24px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "추천 상품 설정",
  "disclaimer_html": "해당 위젯에서 추천할 상품을 설정해 주세요.<br />최소 1개 이상의 추천 항목이 필요합니다.",
  "exclude_purchased_products": {
    "label": "구매한 상품 노출 제외",
    "description": "이미 구매한 상품은 추천 위젯에 노출하지 않습니다. (추천)"
  },
  "recommend_duplicate_products": {
    "label": "중복 추천 허용",
    "description": "하나의 상품이 여러 추천 기준에 해당하는 경우 이 상품을 여러 번 노출 합니다."
  },
  "min_products_count": {
    "label": "추천 항목 노출",
    "description": "추천 가능한 상품이 {0}개 이상인 항목만 위젯에 노출합니다.",
    "greater_than_alternative_recommendation_count": "대체 항목 노출에 설정된 값 보다 작거나 같은 값이어야 합니다."
  },
  "alternative_recommendation_count": {
    "label": "대체 항목 노출",
    "description": "위젯에 노출 가능한 추천 항목이 하나도 없을 시 {0} {1}개를 대체 항목으로 노출합니다.",
    "less_than_alternative_recommendation_count": "추천 항목 노출에 설정된 값 보다 크거나 같은 값이어야 합니다.",
    "review_highlight_caption": "대체 항목은 리뷰 이미지 및 본문이 표시되지 않으며, 기본 위젯(상품 이미지, 상품명, 가격)으로 표시됩니다."
  },
  "alternative_main_title_text": {
    "label": "대체 항목 이름",
    "hint": "대체 항목 노출 시 추천 항목 상단에 노출할 이름을 입력해주세요."
  },
  "keywords": {
    "user": "고객명",
    "user_id": "고객아이디",
    "brand": "쇼핑몰명",
    "target_category": "카테고리명"
  },
  "default_recommendation_unit": {
    "PRODUCT_RECOMMENDATION_DETAIL_PAGE": "쇼핑몰 전체 인기 상품",
    "PRODUCT_RECOMMENDATION_CATEGORY_PAGE" : "카테고리 인기 상품",
    "PRODUCT_RECOMMENDATION_MAIN_PAGE": "쇼핑몰 전체 인기 상품",
    "PRODUCT_RECOMMENDATION_CART_PAGE": "쇼핑몰 전체 인기 상품",
    "PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT": "쇼핑몰 전체 인기 상품 또는 위젯이 노출되는 카테고리의 인기 상품",
    "PRODUCT_RECOMMENDATION_CART_POPUP": "쇼핑몰 전체 인기 상품"
  }
}
</i18n>
