var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.campaign.name,"is-loading":_vm.isLoading,"form-props":Object.assign({}, _vm.formProps,
    {objectId: 'widget_recommendation_settings'})},scopedSlots:_vm._u([{key:"group-label",fn:function(ref){
  var group = ref.group;
  var value = ref.value;
return [(group.id === 'use_exclude_product_ids' && !value)?[_vm._v(" "+_vm._s(_vm.$t('use_exclude_product_ids.false'))+" "),_c('AppInternalLink',{attrs:{"to":"/product/settings","target":"_blank"}},[_c('AppExternalLinkIcon',{staticClass:"TargetCampaignSettingsWidgetExcludeProductIdsDialog__link-icon",attrs:{"size":"small"}})],1)]:_vm._e()]}},{key:"group-sub-item",fn:function(ref){
  var group = ref.group;
  var value = ref.value;
  var checked = ref.checked;
  var errors = ref.errors;
return [(group.id === 'use_exclude_product_ids')?[(value)?[_c('AppSelectProduct',{attrs:{"multiple":"","products":_vm.excludeProducts,"disabled":!_vm.formObject.use_exclude_product_ids,"invalid":!!errors.exclude_product_ids,"name":"campaign[widget_recommendation_settings][exclude_product_ids][]"},on:{"change-items":function (products) { return (_vm.formObject.exclude_product_ids = products.map(
                function (product) { return product.id; }
              )); }},model:{value:(_vm.formObject.exclude_product_ids),callback:function ($$v) {_vm.$set(_vm.formObject, "exclude_product_ids", $$v)},expression:"formObject.exclude_product_ids"}}),_c('AppFormHint',{attrs:{"message":_vm.$t('hint'),"disabled":!checked}}),_c('AppFormError',{attrs:{"error":errors.exclude_product_ids}})]:[_c('AppFormHint',{attrs:{"message":("" + _vm.recommendationExcludingProductNames),"disabled":!checked}})]]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }