import { constructEnum } from '@/lib/enum';
import TargetCampaignType from './TargetCampaignType';

const TargetDispatchLimitType = constructEnum('TargetDispatchLimitType', {
  NONE: 0,
  PER_COHORT: 10,
  DAILY: 20,
  MONTHLY: 30
});

TargetDispatchLimitType.fromCampaignType = function(campaignType) {
  if (TargetCampaignType.isMass(campaignType)) {
    return [this.NONE, this.PER_COHORT];
  } else {
    return this.values;
  }
};

export default TargetDispatchLimitType;
