<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="orgCategory.name"
    :form-props="{
      ...formProps,
      objectId: 'category',
      focusGroupId: 'name'
    }"
    v-on="formEvents"
  />
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewCategoryEditDialog',
  mixins: [DialogFormView],
  props: {
    orgCategory: {
      type: Object,
      required: true
    }
  },
  data() {
    return { orgFormObject: this.orgCategory };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              type: 'text',
              validate: ['required'],
              label: this.$t('name')
            }
          ]
        }
      ];
    },
    category() {
      return this.formObject;
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(`/product_categories/${this.category.id}`, formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.eventBus.$emit('save', data.name);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "카테고리 이름 변경하기",
  "name": "변경할 카테고리 이름"
}
</i18n>
