import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import DisplayProductFilterType from '@/enums/DisplayProductFilterType';

export default {
  computed: {
    ...mapState('displayUnits', ['units']),
    unitFields() {
      return _.flatten(
        (this.formObject.units || []).map((unit, index) => [
          {
            id: `${index}[stock_count]`,
            value: unit.stock_count,
            validate: ['required', { rule: 'lt', allowed: 1000 }]
          },
          {
            id: `${index}[product_option_type_id]`,
            validate: [
              {
                rule: () => this.isProductOptionTypeIdValid(unit),
                errorMessage: this.$t('validations.required')
              }
            ]
          },
          {
            id: `${index}[product_option_value_id]`,
            validate: [
              {
                rule: () => this.isProductOptionValueValid(unit),
                errorMessage: this.$t('validations.required')
              }
            ]
          },
          {
            id: `${index}[max_products]`,
            value: unit.max_products,
            validate: [
              { rule: 'lt', allowed: 1000 },
              {
                rule: () => this.isMaxProductsValid(unit),
                errorMessage: this.$t('validations.required')
              }
            ]
          }
        ])
      );
    }
  },
  watch: {
    units: {
      handler() {
        this.formObject.units = _.cloneDeep(this.units);
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations('displayUnits', ['SET_UNITS']),
    validateUnitField({ column, index }) {
      this.validateField(`${index}[${column}]`);
    },
    isMaxProductsValid(unit) {
      if (this.useDisplayOption) return unit.max_products !== null;
      else
        return (
          DisplayProductFilterType.isStockFilterType(unit.filter_type) ||
          DisplayProductFilterType.isProductOptionFilterType(
            unit.filter_type
          ) ||
          unit.max_products !== null
        );
    },
    isProductOptionTypeIdValid(unit) {
      return (
        !DisplayProductFilterType.isProductOptionFilterType(unit.filter_type) ||
        unit.product_option_type_id
      );
    },
    isProductOptionValueValid(unit) {
      return (
        !DisplayProductFilterType.isProductOptionFilterType(unit.filter_type) ||
        unit.product_option_value
      );
    }
  }
};
