<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="campaign.name"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId: 'widget_recommendation_settings'
    }"
    v-on="formEvents"
  >
    <template #group="{ id, errors }">
      <template v-if="id === 'display_rate'">
        <div class="AppForm__group-field">
          <i18n path="form.display_rate.description">
            <AppNumberInput
              v-model="formObject.display_rate"
              name="campaign[widget_recommendation_settings][display_rate]"
              inline
              :digits="3"
              :invalid="!!errors['display_rate']"
              @blur="validateField('display_rate')"
              @change="validateField('display_rate')"
            />
          </i18n>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'TargetCampaignSettingsWidgetDisplayRateDialog',
  mixins: [DialogFormView],
  props: { campaign: { type: Object, required: true } },
  data: () => ({ isLoading: true }),
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'display_rate',
              label: this.$t('form.display_rate.label'),
              validate: [
                'required',
                { rule: 'min_max_integer', min: 0, max: 100 }
              ]
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get(
        `/target/campaigns/${this.campaign.id}/widget_recommendation_settings`
      )
      .then(({ data }) => {
        this.orgFormObject = data.widget_recommendation_settings;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/target/campaigns/${this.campaign.id}/widget_recommendation_settings`,
          formData,
          { successMessage: this.$t('app.saved') }
        )
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "위젯 노출 빈도",
  "form": {
    "display_rate": {
      "label": "위젯 노출 빈도",
      "description": "위젯 요청 중 {0} % 노출"
    }
  }
}
</i18n>
