<template>
  <Component
    :is="component"
    :to="to"
    class="AppEditLink"
    @click="$emit('click')"
  >
    <AppSvgIcon class="AppEditLink__icon" name="nav-review" :height="14" />
    <span>{{ label }}</span>
  </Component>
</template>

<script>
export default {
  name: 'AppEditLink',
  props: {
    label: { type: String, required: true },
    type: {
      type: String,
      default: 'a',
      validator: v => ['external_link', 'a'].includes(v)
    },
    to: { type: String, default: null }
  },
  computed: {
    component() {
      return this.type === 'external_link' ? 'AppExternalLink' : 'a';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppEditLink {
  padding: 5px 4px;
  @include text-caption-dark;
}

.AppEditLink__icon {
  margin-right: 4px;
  transform: translateY(calc(9px - 50%));
}
</style>
