<template>
  <div
    :class="['AppDateInput', { 'AppDateInput--invalid': invalid }]"
    @focusout="focusout"
  >
    <template v-for="(v, i) in values">
      <template v-if="i !== 0">&nbsp;</template>
      <input
        :key="i"
        ref="inputs"
        :class="[
          'AppDateInput__value',
          { 'AppDateInput__value--placeholder': v === null }
        ]"
        tabindex="0"
        :value="itemText(i)"
        readonly
        @keydown="keydown($event)"
        @focus="focus(i)"
        @blur="blur"
        @paste="paste"
      /><span
        :key="`dot${i}`"
        :class="[
          'AppDateInput__dot',
          { 'AppDateInput__dot--placeholder': v === null }
        ]"
        >.</span
      >
      <span :key="`sizer${i}`" ref="sizers" class="AppDateInput__value-sizer">{{
        itemText(i)
      }}</span>
    </template>
    <input
      v-bind="{ id, name, value }"
      tabindex="-1"
      class="AppDateInput__input"
      type="text"
      @focus="focusNextInput"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import KeyCode from '@/enums/KeyCode';

const DEFAULT_ITEMS = [null, null, null];
const DIGITS = [4, 2, 2];

const clamp = (val, min, max) => (val < min ? min : val > max ? max : val);
const numberToString = (v, i) => v.toString().padStart(DIGITS[i], '0');

export default {
  name: 'AppDateInput',
  model: { event: 'change' },
  props: {
    id: { type: String, default: null },
    name: { type: String, default: null },
    value: { type: String, default: null },
    invalid: { type: Boolean, default: false }
  },
  data: () => ({ values: [...DEFAULT_ITEMS], focusIndex: -1, cursorIndex: 0 }),
  watch: {
    value(val) {
      this.decomposeValue(val);
    },
    values() {
      this.$nextTick(this.resizeInputs);
    }
  },
  mounted() {
    if (this.value) this.decomposeValue(this.value);
    this.resizeInputs();
  },
  methods: {
    focusout({ currentTarget, relatedTarget }) {
      if (!currentTarget.contains(relatedTarget)) this.$emit('blur');
    },
    resizeInputs() {
      const { sizers, inputs } = this.$refs;
      sizers.forEach((s, i) => {
        const { width } = s.getBoundingClientRect();
        inputs[i].style.width = `${width}px`;
      });
    },
    decomposeValue(val) {
      this.values = [...DEFAULT_ITEMS];
      if (val) {
        const hit = val.match(/^(\d{4})-(\d{2})-(\d{2})$/);
        if (hit) this.values = hit.slice(1, 4).map(i => parseInt(i) || null);
      }
      this.change();
    },
    isKeyCodeFromNumRow(keyCode) {
      return KeyCode.DIGIT_0 <= keyCode && keyCode <= KeyCode.DIGIT_9;
    },
    isKeyCodeFromNumPad(keyCode) {
      return KeyCode.NUMPAD_0 <= keyCode && keyCode <= KeyCode.NUMPAD_9;
    },
    isNumberKeyCode(keyCode) {
      return (
        this.isKeyCodeFromNumRow(keyCode) || this.isKeyCodeFromNumPad(keyCode)
      );
    },
    getDigitFromKeyCode(keyCode) {
      if (this.isKeyCodeFromNumRow(keyCode)) {
        return keyCode - KeyCode.DIGIT_0;
      } else {
        return keyCode - KeyCode.NUMPAD_0;
      }
    },
    keydown(event) {
      const { focusIndex, cursorIndex, values } = this;
      const { keyCode } = event;
      let isHandled = false;
      switch (keyCode) {
        case KeyCode.ARROW_RIGHT:
          this.focusNextInput();
          isHandled = true;
          break;
        case KeyCode.ARROW_LEFT:
          this.focusPrevInput();
          isHandled = true;
          break;
        case KeyCode.ARROW_UP:
          this.setFocusValue(values[focusIndex] + 1);
          this.change();
          isHandled = true;
          break;
        case KeyCode.ARROW_DOWN:
          this.setFocusValue(Math.max(values[focusIndex] - 1, 1));
          this.change();
          isHandled = true;
          break;
        default:
          if (this.isNumberKeyCode(keyCode)) {
            const digit = this.getDigitFromKeyCode(keyCode);

            this.setFocusValue(
              cursorIndex == 0 ? digit : values[focusIndex] * 10 + digit
            );

            const isComplete =
              (focusIndex === 1 && digit > 1) ||
              (focusIndex === 2 && digit > 3) ||
              cursorIndex + 1 >= DIGITS[focusIndex];

            if (isComplete) {
              if (!this.focusNextInput()) this.$emit('focus-next');
            } else this.cursorIndex += 1;

            isHandled = true;
          }
      }

      if (isHandled) event.preventDefault();
    },
    change() {
      this.validateValues();

      const { values } = this;
      if (_.isEqual(values, DEFAULT_ITEMS)) return;

      this.$emit(
        'change',
        values.map((v, i) => numberToString(v || 0, i)).join('-')
      );
    },
    focus(i) {
      this.focusIndex = i;
      this.cursorIndex = 0;
    },
    blur() {
      this.focusIndex = -1;
      this.change();
    },
    paste({ clipboardData }) {
      const val = clipboardData.getData('text');
      const date = moment(val);
      if (date.isValidDate()) this.decomposeValue(date.toVal());
    },
    setFocusValue(value) {
      this.$set(this.values, this.focusIndex, value);
    },
    validateValues() {
      let [year, month, day] = this.values;
      if (year !== null) year = clamp(year, 1900, 2100);
      if (month !== null) month = clamp(month, 1, 12);
      if (day !== null)
        day = clamp(
          day,
          1,
          month === 2
            ? year !== null && year % 4 === 0
              ? 29
              : 28
            : [4, 6, 9, 11].includes(month)
            ? 30
            : 31
        );
      this.values = [year, month, day];
    },
    itemText(i) {
      const val = this.values[i];
      return val === null
        ? this.$t(i === 0 ? 'year' : i === 1 ? 'month' : 'day')
        : numberToString(val, i);
    },
    focusNextInput() {
      const { focusIndex, $refs } = this;
      const { inputs } = $refs;

      if (focusIndex < inputs.length - 1) {
        inputs[focusIndex + 1].focus();
        return true;
      } else {
        this.cursorIndex = 0;
        this.change();
        return false;
      }
    },
    focusPrevInput() {
      const { focusIndex, $refs } = this;
      const { inputs } = $refs;

      if (focusIndex > 0) inputs[focusIndex - 1].focus();
      else {
        this.cursorIndex = 0;
        this.change();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppDateInput {
  @include input-base;
  @include input-placeholder;
  padding: 5px 11px;
  width: 110px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.AppDateInput__value {
  @include text-content;
  border: 0;
  padding: 0;

  &:focus {
    outline: none;
    background-color: $color-highlight;
  }

  &::selection {
    background-color: transparent;
  }

  &--placeholder {
    color: $color-grey-40;
  }
}

.AppDateInput__dot {
  color: $color-content-text;

  &--placeholder {
    color: $color-grey-40;
  }
}

.AppDateInput__value-sizer {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.AppDateInput__input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
</style>

<i18n locale="ko">
{
  "year": "연도",
  "month": "월",
  "day": "일"
}
</i18n>
<i18n locale="en">
{
  "year": "Year",
  "month": "Month",
  "day": "Day"
}
</i18n>
