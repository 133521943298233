import { constructEnum } from '@/lib/enum';

const BrandInsightStatus = constructEnum('BrandInsightStatus', {
  NONE: 0,
  REQUESTED: 10,
  TESTING: 15,
  FREE_TRIAL_OPERATING: 20,
  FREE_TRIAL_EXPIRED: 25,
  OPERATING: 30,
  EXPIRED: 40,
  EXPIRED_AND_ADMIN_BLOCKED: 50,
  TERMINATED: 60
});

BrandInsightStatus.MENU_ACCESSIBLE_STATUSES = [
  BrandInsightStatus.TESTING,
  BrandInsightStatus.FREE_TRIAL_OPERATING,
  BrandInsightStatus.FREE_TRIAL_EXPIRED,
  BrandInsightStatus.OPERATING,
  BrandInsightStatus.EXPIRED
];

BrandInsightStatus.BONUS_ADDABLE_STATUSES = [
  BrandInsightStatus.OPERATING,
  BrandInsightStatus.EXPIRED,
  BrandInsightStatus.EXPIRED_AND_ADMIN_BLOCKED
];

BrandInsightStatus.isMenuAccessible = function(insightStatus) {
  return BrandInsightStatus.MENU_ACCESSIBLE_STATUSES.includes(insightStatus);
};

BrandInsightStatus.isBonusAddable = function(insightStatus) {
  return BrandInsightStatus.BONUS_ADDABLE_STATUSES.includes(insightStatus);
};

export default BrandInsightStatus;
