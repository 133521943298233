<template>
  <AppMultipleSelectButton
    v-model="currentValue"
    packing-method="bit_flag"
    :name="name"
    :options="Weekday.options()"
    :disabled="disabled"
    :invalid="invalid"
  />
</template>

<script>
import Weekday from '@/enums/Weekday';

export default {
  name: 'AppWeekdaySelectButton',
  model: { event: 'change' },
  props: {
    name: { type: String, default: '' },
    value: { type: Number, required: true },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false }
  },
  data() {
    return { currentValue: this.value };
  },
  computed: {
    Weekday: () => Weekday
  },
  watch: {
    value() {
      this.currentValue = this.value;
    },
    currentValue() {
      this.$emit('change', this.currentValue);
    }
  }
};
</script>
