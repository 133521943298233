import { constructEnum } from '@/lib/enum';

const LunasoftBizMessageResultCode = constructEnum(
  'LunasoftBizMessageResultCode',
  {
    SUCCESS: 0,
    NO_JSON_BODY: 1001,
    INVALID_HUB_PARTNER_KEY: 1002,
    INVALID_SENDER_KEY: 1003,
    NO_VALUE_JSON_ELEMENT: 1004,
    DELETED_SENDER: 1006,
    STOPPED_SENDER: 1007,
    CONTRACT_NOT_FOUND: 1011,
    INVALID_USER_KEY_EXCEPTION: 1012,
    INVALID_APP_LINK: 1013,
    INVALID_BIZ_NUM: 1014,
    TALK_USER_ID_NOT_FOUND: 1015,
    BIZ_NUM_NOT_EQUAL: 1016,
    INVALID_RECEIVE_USER: 1020,
    BLOCKED_PROFILE: 1021,
    DEACTIVATED_PROFILE: 1022,
    DELETED_PROFILE: 1023,
    DELETING_PROFILE: 1024,
    SPAMMED_PROFILE: 1025,
    MESSAGE_SPAMMED_PROFILE_EXCEPTION: 1027,
    INVALID_PARAMETER_EXCEPTION: 1030,
    FAILED_TO_SEND_MESSAGE_BY_NO_FRIENDSHIP_EXCEPTION: 2003,
    FAILED_TO_MATCH_TEMPLATE_EXCEPTION: 2004,
    FAILED_TO_MATCH_SERIAL_NUMBER_PREFIX_PATTERN: 2006,
    UNEXPECTED_EXCEPTION: 3000,
    ACK_TIMEOUT_EXCEPTION: 3005,
    FAILED_TO_SEND_MESSAGE_EXCEPTION: 3006,
    INVALID_PHONE_NUMBER_EXCEPTION: 3008,
    JSON_PARSE_EXCEPTION: 3010,
    MESSAGE_NOT_FOUND_EXCEPTION: 3011,
    SERIAL_NUMBER_DULPLICATED_EXCEPTION: 3012,
    MESSAGE_EMPTY_EXCEPTION: 3013,
    MESSAGE_LENGTH_OVER_LIMIT_EXCEPTION: 3014,
    TEMPLATE_NOT_FOUND_EXCEPTION: 3015,
    NO_MATCHED_TEMPLATE_EXCEPTION: 3016,
    NO_SEND_AVAILABLE_EXCEPTION: 3018,
    EXCEED_MAX_VARIABLE_LENGTHEXCEPTION: 3025,
    BUTTON_INVAID_EXTRA_EXCEPTION: 3026,
    NO_MATCHED_TEMPLATE_BUTTON_EXCEPTION: 3027,
    NO_MATCHED_TEMPLATE_TITLE_EXCEPTION: 3028,
    EXCEED_MAX_TITLE_LENGTHEXCEPTION: 3029,
    NO_MATCHED_TEMPLATE_WITH_MESSAGE_TYPE_EXCEPTION: 3030,
    NO_MATCHED_TEMPLATE_HEADER_EXCEPTION: 3031,
    EXCEED_MAX_HEADER_LENGTH_EXCEPTION: 3032,
    NO_MATCHED_TEMPALTE_ITEM_HIGHLIGHT_EXCEPTION: 3033,
    EXCEED_MAX_ITEM_HIGHLIGHT_TITLE_LENGTH_EXCEPTION: 3034,
    EXCEED_MAX_ITEM_HIGHLIGHT_DESCRIPTION_LENGTH_EXCEPTION: 3035,
    NO_MATCHED_TEMPALTE_ITEM_LIST_EXCEPTION: 3036,
    EXCEED_MAX_ITEM_DESCRIPTION_LENGTH_EXCEPTION: 3037,
    NO_MATCHED_TEMPALTE_ITEM_SUMMARY_EXCEPTION: 3038,
    EXCEED_MAX_ITEM_SUMMARY_DESCRIPTION_LENGTH_EXCEPTION: 3039,
    INVALID_ITEM_SUMMARY_DESCRIPTION_EXCEPTION: 3040,
    RESPONSE_HISTORY_NOT_FOUND_EXCEPTION: 4000,
    UNKNOWN_MESSAGE_STATUS_ERROR: 4001,
    INVALID_TEST_USER: 5000,
    DAILY_TEST_LIMIT_EXCEEDED: 5001,
    CURRENTLY_NOT_PROVIDING_SERVICE: 9998,
    UNKNOWN_EXCEPTION: 9999,
    SUCCESS_SENDING: 10000,
    WRONG_QUERYSTRING: 10001,
    INVALID_AGENT_KEY: 10002,
    TIMEOUT_EXCEPTION: 10101,
    HTTP_EXCEPTION: 10102,
    INTERNAL_ERROR: 19999
  }
);

LunasoftBizMessageResultCode.isSuccessful = function(resultCode) {
  return [
    LunasoftBizMessageResultCode.SUCCESS,
    LunasoftBizMessageResultCode.SUCCESS_SENDING
  ].includes(resultCode);
};

export default LunasoftBizMessageResultCode;
