<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="productName"
    :form-props="formProps"
    width="middle"
    v-on="formEvents"
  >
    <template #group="{ id, error }">
      <template v-if="id === 'selected_reviews'">
        <AppContainer>
          <div class="ReviewPinnedReviewsSortDialog__selected-reviews-title">
            {{ $t('selected_reviews_title') }}
          </div>
          <AppTable
            table-id="review-selected-reviews-table"
            :rows="rows"
            :columns="COLUMNS"
            no-head
            align="left"
          />
        </AppContainer>
      </template>
      <template v-else-if="id === 'sort_target_index'">
        <i18n path="sort_target_index_description">
          {{ pinnedReviewsCount }}
          <AppNumberInput
            v-model="formObject.sort_target_index"
            class="ReviewPinnedReviewsSortDialog__sort-target-index"
            :invalid="!!error"
            :min="1"
            :max="pinnedReviewsCount"
            :digits="3"
            @change="validateField(id)"
            @blur="validateField(id)"
          />
        </i18n>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';
import _ from 'lodash';
import { arrayMoveIndexes } from '@/lib/array';
import api from '@/lib/api';
import { mapGetters, mapMutations } from 'vuex';
import globalEventBus from '@/lib/globalEventBus';

export default {
  name: 'ReviewPinnedReviewsSortDialog',
  mixins: [DialogFormView],
  props: {
    productName: { type: String, required: true },
    selectedReviews: { type: Array, required: true }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'created_at', type: 'date' },
        { id: 'user_name' },
        { id: 'user_username' },
        { id: 'brand_user_grade_name' },
        { id: 'shortend_message' },
        { id: 'message_length_str' },
        { id: 'comments_count_str' }
      ],
      formObject: { sort_target_index: 1 },
      detectFormDataChange: false
    };
  },
  computed: {
    ...mapGetters('reviewPinnedReviews', ['pinnedReviews']),
    rows() {
      return this.selectedReviews.map(review => ({
        ...review,
        shortend_message: review.message.substr(0, 13),
        message_length_str: this.$t('message_length', {
          length: review.message.length
        }),
        comments_count_str: this.$t('comments_count', {
          count: review.comments_count
        })
      }));
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'selected_reviews',
              label: null
            },
            {
              id: 'sort_target_index',
              label: this.$t('sort_target_index_title'),
              validate: [
                'required',
                {
                  rule: v => v >= 1 && v <= this.pinnedReviewsCount,
                  errorMessage: this.$t(
                    'message_on_invalid_sort_target_index',
                    [this.pinnedReviewsCount]
                  )
                }
              ]
            }
          ]
        }
      ];
    },
    pinnedReviewsCount() {
      return this.pinnedReviews.length;
    }
  },
  methods: {
    ...mapMutations('reviewPinnedReviews', ['SET_PINNED_REVIEWS']),
    submit() {
      const srcIndexes = this.selectedReviews.map(r => r.idx);
      const dstIndex = this.formObject.sort_target_index - 1;
      const maxPosition = _.chain(this.pinnedReviews)
        .map('pin_position')
        .max()
        .value();
      const sortedPinnedReviews = arrayMoveIndexes(
        this.pinnedReviews,
        srcIndexes,
        dstIndex
      ).map((r, i) => ({ ...r, pin_position: maxPosition - i, idx: i }));
      const minIndex = _.min(_.flattenDeep([srcIndexes, dstIndex]));
      const maxIndex = _.max(_.flattenDeep([srcIndexes, dstIndex]));
      const reorderedReviews = sortedPinnedReviews.slice(
        minIndex,
        maxIndex + 1
      );
      api
        .patch('/review/reviews/sort_pin', {
          id_to_pin_position: reorderedReviews.map(r => [r.id, r.pin_position])
        })
        .then(() => {
          this.SET_PINNED_REVIEWS(sortedPinnedReviews);
          globalEventBus.$emit(
            'highlight-rows',
            this.selectedReviews.map(r => r.idx)
          );
        })
        .finally(() => {
          this.close();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

::v-deep {
  .AppTable--no-head {
    border-top: 0px;
  }

  .AppTable__body-row {
    border-bottom: 0px;
  }

  .AppTable__body-col {
    padding: 6px 0px;
  }
}

.ReviewPinnedReviewsSortDialog__selected-reviews-title {
  @include text-label;
  text-align: left;
  padding-bottom: 10px;
}

.ReviewPinnedReviewsSortDialog__sort-target-index {
  margin-left: 10px;
}
</style>

<i18n locale="ko">
{
  "title": "추천 리뷰 순서 변경",
  "selected_reviews_title": "순서 변경할 리뷰",
  "message_length": "글자수: {length}",
  "comments_count": "댓글: {count}",
  "sort_target_index_title": "이동 위치",
  "sort_target_index_description": "{0}개의 추천리뷰 중 {1} 번째로 이동",
  "message_on_invalid_sort_target_index": "이동 위치는 1 에서 {0} 사이의 값이어야 합니다."
}
</i18n>
<i18n locale="en">
{
  "title": "Change the order of pinned reviews",
  "selected_reviews_title": "Reviews for changing order",
  "message_length": "Message length: {length}",
  "comments_count": "Comments count: {count}",
  "sort_target_index_title": "Moving position",
  "sort_target_index_description": "Moved to {1} of {0} pinned reviews",
  "message_on_invalid_sort_target_index": "Move position must be a value between 1 and {0}."
}
</i18n>
