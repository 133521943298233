<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        {{ $t('mobile_section.title') }}
      </div>
      <AppButton
        :label="$t('mobile_section.sms')"
        @click="openMobileSmsPreviewDialog"
      />
      <AppButton
        :label="$t('mobile_section.biz_message')"
        @click="openMobileBizMessagePreviewDialog"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UiPreview',
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openMobileSmsPreviewDialog() {
      this.openDialog('UiMobileSmsPreviewDialog');
    },
    openMobileBizMessagePreviewDialog() {
      this.openDialog('UiMobileBizMessagePreviewDialog');
    }
  }
};
</script>

<i18n locale="ko">
{
  "mobile_section": {
    "title": "Mobile Preview",
    "sms": "문자(SMS)",
    "biz_message": "알림톡"
  }
}
</i18n>
