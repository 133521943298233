<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppResourceTable
      table-id="review-user-grades-table"
      :columns="COLUMNS"
      :rows="rows"
    >
      <template #cell="{ column, value }">
        <AppButton
          v-if="column === 'actions'"
          :label="$t('app.edit_icon')"
          @click="editIcon(value)"
        />
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import ResourceView from '@/mixins/ResourceView';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'ReviewUserGrades',
  mixins: [ResourceView],
  data() {
    return {
      COLUMNS: [
        { id: 'name', label: this.$t('review_user_grade.name') },
        {
          id: 'usersCount',
          label: this.$t('review_user_grade.users_count'),
          type: 'number'
        },
        { id: 'icon', label: this.$t('review_user_grade.icon'), type: 'img' },
        { id: 'actions', label: this.$t('app.actions') }
      ],
      isLoading: true
    };
  },
  computed: {
    ...mapState('userGrade', ['userGrades']),
    ...mapGetters('userGrade', ['isFetchingReviewUserGrades']),
    rows() {
      return this.userGrades.map(userGrade => ({
        name: userGrade.name,
        usersCount: userGrade.users_count,
        icon: userGrade.icon_url,
        actions: userGrade
      }));
    }
  },
  watch: {
    isFetchingReviewUserGrades() {
      this.isLoading = this.isFetchingReviewUserGrades;
    }
  },
  methods: {
    ...mapActions('userGrade', ['fetchUserGrades']),
    ...mapActions('dialog', ['openDialog']),
    fetchResource() {
      this.fetchUserGrades();
    },
    editIcon(orgUserGrade) {
      this.openDialog(['ReviewUserGradeEditDialog', { orgUserGrade }]);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .review-user-grades-table__icon img {
    max-width: 70px;
    max-height: 40px;
  }

  .AppResourceTableCell {
    flex: 1 0 90px;
  }

  .review-user-grades-table__users-count {
    flex-basis: 120px;
  }

  .review-user-grades-table__actions {
    flex-basis: 110px;
  }
}
</style>
