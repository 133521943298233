<template>
  <TargetSectionKpi
    v-bind="{
      isLoading,
      title: campaignText('title'),
      barTooltip,
      kpiData,
      barData
    }"
    :bar-title="campaignText('bar_title', [currentMonth])"
    :kpi-title="campaignText('kpi_title', [currentMonth])"
    @click-item="$emit('click-item', $event)"
  />
</template>

<script>
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';
import TargetSectionKpi from './components/TargetSectionKpi';

export default {
  name: 'TargetCampaignStatsSectionReach',
  components: { TargetSectionKpi },
  props: {
    campaign: { type: Object, required: true },
    isLoading: { type: Boolean, required: true },
    reports: { type: Array, required: true }
  },
  computed: {
    barData() {
      if (this.isLoading) return [];

      if (this.isDm) {
        const {
          targeted_users_count,
          unique_click_count,
          unique_check_count,
          unsubscribed_users_count
        } = this.reports[0];
        return [
          {
            label: this.campaignText('clicked.legend'),
            value: unique_click_count
          },
          {
            label: this.campaignText('checked.legend'),
            value:
              unique_check_count - unique_click_count - unsubscribed_users_count
          },
          {
            label: this.campaignText('unchecked.legend'),
            value: targeted_users_count - unique_check_count
          },
          {
            label: this.campaignText('unsubscribed.legend'),
            value: unsubscribed_users_count
          }
        ];
      } else if (this.isWidget) {
        const {
          targeted_users_count,
          unique_check_count,
          unique_click_count
        } = this.reports[0];
        return [
          {
            label: this.campaignText('clicked.legend'),
            value: unique_click_count
          },
          {
            label: this.campaignText('checked.legend'),
            value: unique_check_count - unique_click_count
          },
          {
            label: this.campaignText('unchecked.legend'),
            value: targeted_users_count - unique_check_count
          }
        ];
      } else {
        const { unique_click_count, unique_check_count } = this.reports[0];
        return [
          {
            label: this.campaignText('clicked.legend'),
            value: unique_click_count
          },
          {
            label: this.campaignText('checked.legend'),
            value: unique_check_count - unique_click_count
          }
        ];
      }
    },
    kpiData() {
      if (this.isLoading) return [];

      const report = this.reports[0];
      const columns = this.kpiColumns;
      return columns.map(column => ({
        column,
        label: this.campaignText(`${column}.label`),
        tooltip: this.campaignText(`${column}.tooltip`),
        value: report[column] * 100
      }));
    },
    kpiColumns() {
      if (this.isDm) {
        return this.campaign.campaign_type ===
          TargetCampaignType.MILEAGE_EXPIRING
          ? ['check_rate', 'unsubscribed_rate']
          : ['check_rate', 'click_per_check', 'unsubscribed_rate'];
      } else {
        return this.isWidget
          ? ['reach_rate', 'click_per_check']
          : ['click_per_check'];
      }
    },
    barTooltip() {
      const total = this.barData.reduce((s, { value }) => s + value, 0);
      return {
        title: this.formatMonthWithYear(new Date()),
        items: this.barData.map(({ label, value }) => {
          const percentage =
            total === 0 ? 0 : ((value / total) * 100).toFixed(2);
          return {
            name: label,
            value: `${this.$t('unit.people', [
              value
            ])} (${this.$t('unit.percentage', [percentage])})`
          };
        })
      };
    },
    currentMonth() {
      return this.formatMonth(new Date());
    },
    isDm() {
      return (
        this.campaign.campaign_media_type ===
        TargetCampaignMediaType.DIRECT_MESSAGE
      );
    },
    isWidget() {
      return (
        this.campaign.campaign_media_type === TargetCampaignMediaType.WIDGET
      );
    }
  },
  methods: {
    campaignText(key, interpolations) {
      return this.$store.getters['targetCampaigns/campaignText'](
        this.campaign,
        `reach.${key}`,
        interpolations
      );
    }
  }
};
</script>
