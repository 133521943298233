<template>
  <div />
</template>

<script>
import _ from 'lodash';
import Highcharts from 'highcharts';

export default {
  name: 'AppHighcharts',
  props: {
    options: { type: Object, required: true },
    callback: { type: Function, default: null }
  },
  computed: {
    finalOptions() {
      return { ...this.options, accessibility: { enabled: false } };
    }
  },
  watch: {
    options: {
      handler() {
        this.chart.update(_.cloneDeep(this.finalOptions), true, true);
      },
      deep: true
    }
  },
  mounted() {
    this.chart = Highcharts.chart(
      this.$el,
      _.cloneDeep(this.finalOptions),
      this.callback
    );
  },
  beforeDestroy() {
    const TRANSITION_DELAY = 100;
    const chart = this.chart;
    setTimeout(() => chart.destroy(), TRANSITION_DELAY);
  }
};
</script>
