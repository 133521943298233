<template>
  <AppSelectButton
    :value="value"
    :options="INTERVAL_OPTIONS"
    :disabled="disabled"
    @change="changeInterval"
  />
</template>

<script>
export default {
  name: 'AppSelectButtonInterval',
  model: { event: 'change' },
  props: {
    value: {
      type: String,
      required: true
    },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      INTERVAL_OPTIONS: [
        {
          value: 'daily',
          label: this.$t('daily')
        },
        {
          value: 'weekly',
          label: this.$t('weekly')
        },
        {
          value: 'monthly',
          label: this.$t('monthly')
        }
      ]
    };
  },
  methods: {
    changeInterval(newValue) {
      this.$emit('change', newValue);
    }
  }
};
</script>

<i18n locale="ko">
{
  "daily": "일별",
  "weekly": "주별",
  "monthly": "월별"
}
</i18n>
<i18n locale="en">
{
  "daily": "Daily",
  "weekly": "Weekly",
  "monthly": "Monthly"
}
</i18n>
