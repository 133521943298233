import Vue from 'vue';
import api from '@/lib/api';

const REQUEST_ID = 'FETCH_JOB_DATA';
const REQUEST_INTERVAL = 5000;

const state = { timer: null, params: {} };

const mutations = {
  SET_TIMER(state, timer) {
    state.timer = timer;
  },
  SET_JOB_PARAMS(state, { key, value }) {
    state.params[key] = value;
  },
  UNSET_JOB_PARAMS(state, key) {
    Vue.delete(state.params, key);
  }
};

const actions = {
  startJobRequest({ state, commit, dispatch }) {
    if (state.timer) return;

    dispatch('request');
    const timer = setInterval(() => dispatch('request'), REQUEST_INTERVAL);
    commit('SET_TIMER', timer);
  },
  stopJobRequest({ state, commit }) {
    api.cancel(REQUEST_ID);
    clearInterval(state.timer);
    commit('SET_TIMER', null);
  },
  request({ dispatch }) {
    api
      .get('/user/job', { requestId: REQUEST_ID, params: state.params })
      .then(({ data }) => {
        if (!data) return;

        dispatch('manualSyncJobs/setManualSyncJobs', data.manual_sync_infos, {
          root: true
        });

        dispatch(
          'displayProductManagementCohortJobs/setScheduledCohorts',
          data.scheduled_cohorts,
          { root: true }
        );

        dispatch(
          'targetProductDataCalculationJobs/enqueueCalculationJobs',
          data.product_data_calculations,
          { root: true }
        );
      });
  }
};

export default { namespaced: true, state, mutations, actions };
