<template>
  <div class="ReviewWidgetPreview">
    <template v-if="previewNotSupported">
      <div class="EmptyState PreviewNotSupported">
        <div class="PreviewNotSupported__container">
          <p class="PreviewNotSupported__title">
            {{ $t('preview_not_supported.title') }}
          </p>
          <p>
            {{ $t('preview_not_supported.content') }}
          </p>
          <AppExternalLink
            :to="sampleUrl"
            target="_blank"
            class="PreviewNotSupported__link"
          >
            <AppExternalLinkIcon
              size="small"
              class="PreviewNotSupported__link-icon"
            />{{ $t('preview_not_supported.button') }}
          </AppExternalLink>
        </div>
      </div>
    </template>
    <div
      v-else-if="widgetStyleNotSelected"
      class="EmptyState WidgetStyleNotSelected"
    >
      <span class="WidgetStyleNotSelected__message">{{
        $t('widget_style_not_selected')
      }}</span>
    </div>
    <template v-else>
      <AppInfoBox
        class="ReviewWidgetPreview__guide"
        hide-type="instant"
        info-box-id="review-widget-preview-guide"
        :event-bus="eventBus"
      >
        <i18n path="preview_guide.description_html">
          <AppInternalLink
            v-tooltip="$t('preview_guide.tooltip_message')"
            :to="previewGuideUrl"
            target="_blank"
            class="ReviewWidgetPreview__guide-link"
          >
            <span>{{ $t('preview_guide.page_name_renewed') }}</span>
            <AppExternalLinkIcon
              size="small"
              class="ReviewWidgetPreview__guide-link-icon"
            />
          </AppInternalLink>
        </i18n>
      </AppInfoBox>
      <div
        v-if="showVueWidgetSetting"
        class="ReviewWidgetPreview__display-guide"
      >
        <span class="ReviewWidgetPreview__badge">
          <AppBadge :label="badgeLabel" :badge-style="badgeStyle" />
        </span>
        <span>{{ displayGuide }}</span>
      </div>
      <Component
        :is="isPc ? 'div' : 'AppMobilePhonePreview'"
        :class="[
          'ReviewWidgetPreview__iframe',
          isPc
            ? 'ReviewWidgetPreview__iframe--pc'
            : 'ReviewWidgetPreview__iframe--mobile'
        ]"
      >
        <AppIframeViewer
          :src="previewUrl"
          :is-loading-forced="displayVersion !== currentVersion"
        />
      </Component>
    </template>
  </div>
</template>
<script>
import qs from 'qs';
import { mapState } from 'vuex';

export default {
  name: 'ReviewWidgetPreview',
  props: {
    useVuejs: { type: Boolean, required: true },
    showVueWidgetSetting: { type: Boolean, required: true },
    previewWidgetUrl: { type: String, default: null },
    previewNotSupported: { type: Boolean, required: true },
    widgetStyleNotSelected: { type: Boolean, default: false },
    isPc: { type: Boolean, default: true },
    currentVersion: { type: Number, default: 0 },
    displayVersion: { type: Number, default: 0 },
    eventBus: { type: Object, default: null }
  },
  computed: {
    ...mapState('session', ['currentUser']),
    previewUrl() {
      const params = qs.stringify({
        iframe_id: '',
        iframe: '1',
        parent_url: location.href,
        v: this.displayVersion
      });

      return `${this.previewWidgetUrl}&${params}`;
    },
    sampleUrl() {
      return this.currentUser.admin_locale === 'ko'
        ? 'https://sample.cre.ma/v2/review'
        : 'https://help-global.cre.ma/hc/en-us/articles/17904644613401-Display-widgets-showcase';
    },
    previewGuideUrl() {
      return '/review/settings/design';
    },
    badgeLabel() {
      return this.useVuejs ? this.$t('badge.new') : this.$t('badge.old');
    },
    badgeStyle() {
      return this.useVuejs ? 'blue' : 'grey-outline';
    },
    displayGuide() {
      return this.useVuejs
        ? this.$t('display_guide_description.new')
        : this.$t('display_guide_description.old');
    }
  }
};
</script>
<style scoped lang="scss">
@import '@/scss/mixins/_texts.scss';

$right-panel-width: 680px;

.ReviewWidgetPreview {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow-y: hidden;

  @media (min-width: 1400px) {
    min-width: 766px;
  }

  @media (min-width: 1024px) and (max-width: 1399px) {
    width: calc(100vw - $right-panel-width);
  }
}

.ReviewWidgetPreview__guide {
  margin: 67px 23px 24px 23px;
  white-space: pre-line;
}

.ReviewWidgetPreview__guide-link {
  text-decoration: underline;
  margin: 0 6px;
}

.ReviewWidgetPreview__guide-link-icon {
  margin-left: 4px;
}
.ReviewWidgetPreview__display-guide {
  text-align: center;
}

.ReviewWidgetPreview__badge {
  margin-right: 4px;
}

.ReviewWidgetPreview__iframe {
  flex-grow: 1;

  &--pc {
    height: auto;
    max-height: calc(100vh - 40px);
    padding: 23px 23px 0px 23px;
  }

  &--mobile {
    padding: 23px 0 0 0;
    margin: 0 auto 60px;
    overflow-y: scroll;
    width: 100%;
  }
}

.EmptyState {
  height: inherit;
}

.PreviewNotSupported {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PreviewNotSupported__container {
  display: block;
  text-align: center;
}

.PreviewNotSupported__title {
  @include text-title;
  margin-bottom: 24px;
}

.PreviewNotSupported__link {
  @include text-content;
  text-decoration: underline;
  margin-top: 4px;
}

.PreviewNotSupported__link-icon {
  margin-right: 4px;
}

.WidgetStyleNotSelected {
  @include text-title;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<i18n locale="ko">
{
  "preview_guide": {
    "description_html": "아래 미리보기는 디자인 참고용으로, 실제 위젯에 적용되는 모습과 차이가 있을 수 있습니다. \n 모든 위젯에 공통으로 적용되는 설정은 {0}에서 변경할 수 있습니다.",
    "page_name": "표시 정보 및 디자인",
    "page_name_renewed": "기본 설정",
    "tooltip_message": "클릭 시 새탭에서 해당 페이지로 이동합니다."
  },
  "preview_not_supported": {
    "title": "해당 위젯은 미리보기를 지원하지 않습니다.",
    "content": "미리보기를 지원하는 위젯을 선택하면 이 영역이 활성화됩니다.",
    "button": "샘플 페이지에서 위젯 확인하기"
  },
  "widget_style_not_selected": "위젯 스타일을 선택해 주세요.",
  "display_guide_description": {
    "new": "새로운 크리마 위젯 표시 중입니다.",
    "old": "이전 크리마 위젯 표시 중입니다."
  },
  "badge": {
    "new": "NEW",
    "old": "OLD"
  }
}
</i18n>

<i18n locale="en">
{
  "preview_guide": {
    "description_html": "This preview is for reference only and actual widget may vary.\n You can configure the general widget settings in {0}.",
    "page_name": "Look & Feel",
    "page_name_renewed": "General settings",
    "tooltip_message": "If clicked, you will be redirected to the settings page in a new tab."
  },
  "preview_not_supported": {
    "title": "No preview is available for this widget.",
    "content": "Preview pane will be enabled if there's a preview available for the selected widget.",
    "button": "Go to the showcase page to preview the widget"
  },
  "widget_style_not_selected": "Please select the widget style."
}
</i18n>
