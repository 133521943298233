<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{
      ...formProps,
      id: 'feed_settings',
      objectId: 'feed_settings'
    }"
    v-bind="{ isLoading }"
    v-on="formEvents"
  >
    <AppTabs v-model="activeTab" :tabs="tabOptions" />
    <template #group="{ id, errors, value }">
      <template v-if="id === 'category_filter_type'">
        <AppSelect
          v-model="formObject.category_filter_type"
          class="AppForm__group-field"
          name="feed_settings[category_filter_type]"
          :options="ChannelCategoryFilterType.options()"
        />
        <template v-if="value === ChannelCategoryFilterType.INCLUDE">
          <div class="AppForm__group-field">
            <AppMultipleSelectProductCategory
              v-model="formObject.including_category_ids"
              name="feed_settings[including_category_ids][]"
              :invalid="!!errors.including_category_ids"
              @blur="validateField('including_category_ids')"
              @change="validateField('including_category_ids')"
            />
            <AppFormError :error="errors.including_category_ids" />
          </div>
        </template>
        <div class="AppForm__group-field">
          <AppCheckbox
            v-model="formObject.use_excluding_category_ids"
            name="feed_settings[use_excluding_category_ids]"
            :label="$t('form.use_excluding_category_ids.label')"
          >
            <template #sub-item="{ checked }">
              <AppMultipleSelectProductCategory
                v-model="formObject.excluding_category_ids"
                name="feed_settings[excluding_category_ids][]"
                :placeholder="$t('form.excluding_category_ids.placeholder')"
                :disabled="!checked"
                :invalid="!!errors.excluding_category_ids"
                :exclude-category-values="formObject.including_category_ids"
                @blur="validateField('excluding_category_ids')"
                @change="validateField('excluding_category_ids')"
              />
              <AppFormHint
                :message="$t('form.excluding_category_ids.hint')"
                :disabled="!checked"
              />
              <AppFormError :error="errors.excluding_category_ids" />
            </template>
          </AppCheckbox>
        </div>
      </template>
      <template v-else-if="id === 'min_products_count'">
        <div class="AppForm__group-field">
          <i18n path="unit.count">
            <AppNumberInput
              v-model="formObject.min_products_count"
              name="feed_settings[min_products_count]"
              inline
              :digits="4"
              :max="9999"
              :invalid="!!errors.min_products_count"
              @change="validateField('min_products_count')"
              @blur="validateField('min_products_count')"
            />
          </i18n>
        </div>
      </template>
      <template v-else-if="id === 'proper_products_count'">
        <div class="AppForm__group-field">
          <i18n path="unit.count">
            <AppNumberInput
              v-model="formObject.proper_products_count"
              name="feed_settings[proper_products_count]"
              inline
              :digits="4"
              :max="9999"
              :invalid="!!errors.proper_products_count"
              @change="validateField('proper_products_count')"
              @blur="validateField('proper_products_count')"
            />
          </i18n>
        </div>
      </template>
      <template v-else-if="id === 'filter_units'">
        <AppSelectRadio
          v-model="formObject.feed_unit_filter_type"
          class="ChannelFeedSettingsDialog__feed-unit-filter-type-radio"
          name="feed_settings[feed_unit_filter_type]"
          :options="ChannelUnitFilterType.options()"
        />
        <ChannelFeedFilterUnitsFormGroup
          v-model="formObject.units"
          v-bind="{ productOptionTypes, errors }"
          name="feed_settings[filter_units]"
          @validate-unit-field="validateField($event)"
        />
      </template>
      <template v-else-if="id === 'purchase_rate_filter_criteria'">
        <div class="AppForm__group-field">
          <i18n path="form.purchase_rate_filter_criteria.field">
            <AppSelect
              v-model="formObject.purchase_rate_filter_type"
              name="feed_settings[purchase_rate_filter_type]"
              :options="purchaseRateFilterTypeOptions"
              inline
            />
            <AppNumberInput
              v-model="formObject.purchase_rate_filter_min_count"
              name="feed_settings[purchase_rate_filter_min_count]"
              :digits="3"
              :max="999"
              :invalid="!!errors.purchase_rate_filter_min_count"
              inline
              @change="validateField('purchase_rate_filter_min_count')"
              @blur="validateField('purchase_rate_filter_min_count')"
            />
          </i18n>
          <AppFormError :error="errors.purchase_rate_filter_min_count" />
        </div>
      </template>
    </template>
    <template #group-foot="{ id }">
      <template v-if="id === 'min_products_count'">
        <AppCheckbox
          v-model="formObject.check_products_count"
          class="AppForm__group-field"
          :label="$t('form.check_products_count.label')"
          name="feed_settings[check_products_count]"
          :disabled="!formObject.min_products_count"
        />
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import ChannelProductFilterType from '@/enums/ChannelProductFilterType';
import ChannelCategoryFilterType from '@/enums/ChannelCategoryFilterType';
import ChannelUnitFilterType from '@/enums/ChannelUnitFilterType';

const FILTER_UNITS_TAB = 'filter_units';
const BASIC_TAB = 'basic';

export default {
  name: 'ChannelFeedSettingsDialog',
  mixins: [DialogFormView],
  props: { feedId: { type: Number, required: true } },
  data: () => ({
    isLoading: true,
    activeTab: FILTER_UNITS_TAB,
    productOptionTypes: []
  }),
  computed: {
    tabOptions() {
      return [
        {
          id: FILTER_UNITS_TAB,
          label: this.$t(`tabs.${FILTER_UNITS_TAB}`)
        },
        {
          id: BASIC_TAB,
          label: this.$t(`tabs.${BASIC_TAB}`)
        }
      ];
    },
    formSections() {
      return this.activeTab === BASIC_TAB
        ? this.basicSections
        : this.filterUnitsSections;
    },
    basicSections() {
      return [
        {
          groups: [
            {
              id: 'purchase_rate_filter_criteria',
              fields: [
                {
                  id: 'purchase_rate_filter_min_count',
                  validate: [
                    'required',
                    { rule: 'min_max_integer', min: 0, max: 999 }
                  ]
                }
              ]
            },
            {
              id: 'min_products_count',
              validate: [{ rule: 'min_max_integer', min: 0, max: 9999 }]
            },
            {
              id: 'proper_products_count',
              validate: [{ rule: 'min_max_integer', min: 0, max: 9999 }]
            }
          ]
        }
      ];
    },
    filterUnitsSections() {
      return [
        {
          groups: [
            {
              id: 'category_filter_type',
              validate:
                this.formObject.category_filter_type !==
                  ChannelCategoryFilterType.ALL ||
                this.formObject.use_excluding_category_ids
                  ? ['required']
                  : [],
              fields: [
                ...(this.formObject.category_filter_type ===
                ChannelCategoryFilterType.INCLUDE
                  ? [
                      {
                        id: 'including_category_ids',
                        validate: ['required']
                      }
                    ]
                  : []),
                ...(this.formObject.use_excluding_category_ids
                  ? [
                      {
                        id: 'excluding_category_ids',
                        validate: ['required']
                      }
                    ]
                  : [])
              ]
            },
            {
              id: 'excluding_product_keywords',
              type: 'tags',
              placeholder: this.$t(
                'form.excluding_product_keywords.placeholder'
              ),
              searchPlaceholder: this.$t(
                'form.excluding_product_keywords.search_placeholder'
              ),
              searchNoResults: this.$t(
                'form.excluding_product_keywords.search_no_results'
              ),
              tagAlreadyExistsMessage: this.$t(
                'form.excluding_product_keywords.tag_already_exists'
              )
            },
            {
              id: 'filter_units',
              groupDescription: this.$t('form.filter_units.description'),
              fields: this.unitFields
            }
          ]
        }
      ];
    },
    ChannelCategoryFilterType: () => ChannelCategoryFilterType,
    purchaseRateFilterTypeOptions() {
      return [
        {
          label: this.$t('purchase_rate_filter_type.purchase_count'),
          value: ChannelProductFilterType.MOST_PURCHASED
        },
        {
          label: this.$t('purchase_rate_filter_type.click_count'),
          value: ChannelProductFilterType.MOST_CLICKED
        }
      ];
    },
    unitFields() {
      return _.flatten(
        (this.formObject.units || []).map((unit, index) => [
          {
            id: `${index}[filter_compare_value]`,
            value: unit.filter_compare_value,
            validate: [
              'required',
              {
                rule: () => this.isFilterCompareValueValid(unit),
                errorMessage: this.filterCompareValueErrorMessage(unit)
              }
            ]
          },
          {
            id: `${index}[date_compare_value]`,
            value: unit.date_compare_value,
            validate: [
              'required',
              { rule: 'min_max_integer', min: 0, max: 999 }
            ]
          },
          {
            id: `${index}[product_option_type_id]`,
            validate: [
              {
                rule: () => this.isProductOptionTypeIdValid(unit),
                errorMessage: this.$t('validations.required')
              }
            ]
          },
          {
            id: `${index}[product_option_value_id]`,
            validate: [
              {
                rule: () => this.isProductOptionValueValid(unit),
                errorMessage: this.$t('validations.required')
              }
            ]
          }
        ])
      );
    },
    ChannelUnitFilterType: () => ChannelUnitFilterType
  },
  watch: {
    'formObject.min_products_count'() {
      if (this.formObject.min_products_count === null)
        this.formObject.check_products_count = false;
    }
  },
  mounted() {
    api
      .get(`/channel/feeds/${this.feedId}/settings`)
      .then(({ data }) => {
        this.orgFormObject = {
          ...data.feed,
          units: data.units
            .map((unit, groupIndex) => [
              { ...unit, group_index: groupIndex },
              ...(unit.child_units || []).map(childUnit => ({
                ...childUnit,
                group_index: groupIndex
              }))
            ])
            .flat()
        };
        this.productOptionTypes = data.product_option_types;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(`/channel/feeds/${this.feedId}/settings`, formData)
        .then(() => {
          this.orgFormObject = this.formObject;
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    filterCompareValueErrorMessage(unit) {
      if (
        ChannelProductFilterType.isCompareDecimalValueFilterType(
          unit.filter_type
        )
      ) {
        return this.$t('validations.decimal_filter_value');
      }
      if (
        ChannelProductFilterType.HIGHEST_PURCHASED_PRICE === unit.filter_type
      ) {
        return this.$t('validations.highest_purchased_price');
      }
      return this.$t('validations.filter_value');
    },
    isFilterCompareValueValid(unit) {
      if (
        ChannelProductFilterType.isCompareDecimalValueFilterType(
          unit.filter_type
        )
      ) {
        const decimal_place_count =
          unit.filter_compare_value.toString().split('.')[1]?.length || 0;
        return (
          0 <= unit.filter_compare_value &&
          unit.filter_compare_value <= 100 &&
          decimal_place_count < 2
        );
      }
      if (
        ChannelProductFilterType.HIGHEST_PURCHASED_PRICE === unit.filter_type
      ) {
        return (
          0 <= unit.filter_compare_value &&
          unit.filter_compare_value <= 999999999999
        );
      }
      return 0 <= unit.filter_compare_value && unit.filter_compare_value <= 999;
    },
    isProductOptionTypeIdValid(unit) {
      return (
        !ChannelProductFilterType.isProductOptionFilterType(unit.filter_type) ||
        unit.product_option_type_id
      );
    },
    isProductOptionValueValid(unit) {
      return (
        !ChannelProductFilterType.isProductOptionFilterType(unit.filter_type) ||
        unit.product_option_value
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.ChannelFeedSettingsDialog__feed-unit-filter-type-radio {
  margin-top: 9px;
}
</style>

<i18n locale="ko">
{
  "title": "광고 피드 설정",
  "tabs": {
    "filter_units": "광고 피드 규칙",
    "basic": "기본 설정"
  },
  "form": {
    "purchase_rate_filter_criteria": {
      "field": "{0}{1}회 이상인 상품 중 클릭한 사람수 대비 주문 비율"
    },
    "check_products_count": {
      "label": "광고 피드에 포함된 상품이 최소 상품 수 이하일 때 알림을 발송합니다."
    },
    "excluding_product_keywords": {
      "placeholder": "상품 키워드를 선택해주세요.",
      "search_placeholder": "노출 제한할 키워드를 입력해 주세요.",
      "search_no_results": "입력한 키워드를 추가할 수 있습니다.",
      "tag_already_exists": "이미 포함된 키워드입니다."
    },
    "use_excluding_category_ids": {
      "label": "다음 상품 카테고리에 포함되는 상품 제외"
    },
    "excluding_category_ids": {
      "placeholder": "제외할 상품 카테고리를 선택해주세요.",
      "hint": "예를들어 아우터 카테고리에 포함된 상품 중 SALE에도 포함된 상품을 제외 하려면<br/>위에서 SALE카테고리를 선택하세요."
    },
    "filter_units": {
      "description": "다수의 상위 조건 추가 시 “또는” 관계를 가집니다. 하나의 상품이 여러 선정 조건에 해당하는 경우 한번만 선정됩니다."
    }
  },
  "purchase_rate_filter_type": {
    "click_count": "상품 조회수",
    "purchase_count": "판매량"
  },
  "validations": {
    "filter_value": "0과 999사이 정수만 입력 가능합니다.",
    "highest_purchased_price": "0과 999,999,999,999사이 정수만 입력 가능합니다.",
    "decimal_filter_value": "0과 100사이 소수점 1번째 자리까지만 입력 가능합니다."
  }
}
</i18n>
