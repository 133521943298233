<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Normal</label>
      </div>
      <AppSelectSearchable
        id="normal"
        :items="itemsNormal"
        title="Select Normal"
        :search-types="SEARCH_TYPES"
        :columns="COLUMNS"
        @search="search"
        @change-items="itemsNormal = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ itemsNormal.map(i => i.id)[0] }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppSelectSearchable
        id="disabled"
        disabled
        :items="itemsDisabled"
        title="Select Disabled"
        :search-types="SEARCH_TYPES"
        :columns="COLUMNS"
        @search="search"
        @change-items="itemsDisabled = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ itemsDisabled.map(i => i.id)[0] }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppSelectSearchable
        id="invalid"
        invalid
        :items="itemsInvalid"
        title="Select Invalid"
        :search-types="SEARCH_TYPES"
        :columns="COLUMNS"
        @search="search"
        @change-items="itemsInvalid = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ itemsInvalid.map(i => i.id)[0] }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="multiple">Multiple</label>
      </div>
      <AppSelectSearchable
        id="multiple"
        multiple
        :items="itemsMultiple"
        title="Select Multiple"
        :search-types="SEARCH_TYPES"
        :columns="COLUMNS"
        @search="search"
        @change-items="itemsMultiple = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ itemsMultiple.map(i => i.id)[0] }}
        <AppMultipleSelect
          :value="itemsMultiple.map(i => i.id)"
          :options="itemsMultiple.map(i => ({ label: i.name, value: i.id }))"
          @change="
            itemsMultiple = itemsMultiple.filter(i => $event.includes(i.id))
          "
        />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="multiple_stringify">Multiple + Stringify</label>
      </div>
      <AppSelectSearchable
        id="multiple_stringify"
        multiple
        packing-method="string"
        :items="itemsMultipleStringify"
        title="Select Multiple"
        :search-types="SEARCH_TYPES"
        :columns="COLUMNS"
        @search="search"
        @change="valueMultipleStringify = $event"
        @change-items="itemsMultipleStringify = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueMultipleStringify }}
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/lib/api';

export default {
  name: 'UiSelectSearchable',
  data() {
    const ITEMS = [
      { id: 1, name: 'Ant-Man' },
      { id: 2, name: 'Black Panther' }
    ];
    return {
      ITEMS,
      SEARCH_TYPES: [
        { value: 'name', label: this.$t('products.name') },
        { value: 'code', label: this.$t('products.code') }
      ],
      COLUMNS: [
        { id: 'code', label: this.$t('products.code') },
        { id: 'product', label: this.$t('products.name'), type: 'product' }
      ],
      itemsNormal: ITEMS.filter(i => i.id === 1),
      itemsDisabled: ITEMS.filter(i => i.id === 2),
      itemsInvalid: ITEMS.filter(i => i.id === 1),
      itemsMultiple: [...ITEMS],
      itemsMultipleStringify: [...ITEMS],
      valueMultipleStringify: '1,2'
    };
  },
  methods: {
    search({ params, onSuccess, onFailure }) {
      api
        .get('/products', { params })
        .then(({ data }) =>
          onSuccess({
            ...data.products,
            items: data.products.items.map(p => ({
              id: p.id,
              name: p.name,
              code: p.code,
              product: p
            }))
          })
        )
        .catch(onFailure);
    }
  }
};
</script>
