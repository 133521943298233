<template>
  <div>
    <div class="ThePaymentDialogTargetPackagesTable__description">
      {{ billingPlanDescription }}
      <div v-if="isBillingPlanCriterionVisible">
        <template v-if="isBuyable">
          {{
            $t('billing_plan_criterion', {
              nextDauCalculationDate: formatDate(nextDauCalculationDate)
            })
          }}
        </template>
        <span
          v-if="
            !isTargetConfiguationRequired &&
              !targetPayment.target_billing_info.is_custom
          "
          class="ThePaymentDialogTargetPackagesTable__detail-criterion-button"
          @click="openDetailCriterionDialog"
        >
          {{ $t('detail_criterion_button') }}
        </span>
      </div>
    </div>
    <ThePaymentDialogPackagesTable
      :ids="targetPackageIds"
      :packages="targetPackages"
      :name="name"
      @change="$emit('change', $event)"
    >
      <template v-if="isRequestableCustomerService || noDataMessage" #no-data>
        <div class="ThePaymentDialogTargetPackagesTable__no-data">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-if="noDataMessage" v-html="noDataMessage" />
          <div v-else>
            <div>
              <b>{{ $t('interested_in_target') }}</b>
            </div>
            <AppButton
              class="ThePaymentDialogTargetPackagesTable__request-customer-service-button"
              @click="openRequestCustomerServiceDialog"
              >{{ $t('request_customer_service') }}</AppButton
            >
          </div>
          <div v-if="nextBuyableDateMessage">
            {{ nextBuyableDateMessage }}
            <AppSvgIcon
              v-if="tooltipNextBuyableDateMessage"
              v-tooltip="tooltipNextBuyableDateMessage"
              name="icon-info-tooltip"
              class="ThePaymentDialogTargetPackagesTable__tootip_icon"
            />
          </div>
        </div>
      </template>
    </ThePaymentDialogPackagesTable>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import ThePaymentDialogPackagesTable from './ThePaymentDialogPackagesTable';

export default {
  name: 'ThePaymentDialogTargetPackagesTable',
  components: { ThePaymentDialogPackagesTable },
  props: {
    targetPackageIds: { type: Array, required: true },
    name: { type: String, required: true },
    targetPackages: { type: Array, required: true },
    nextDauCalculationDate: { type: String, default: '' },
    smsPriceCents: { type: Number, default: 15 }
  },
  data() {
    return { isCustomerServiceRequested: false };
  },
  computed: {
    ...mapState('session', ['targetStatus', 'targetPayment']),
    ...mapGetters('session', [
      'isTargetFreeTrial',
      'isTargetFreeTrialOperating',
      'isTargetFreeTrialExpired',
      'targetFreeTrialStartedAt',
      'isTargetOperating',
      'isTargetExpired',
      'isTargetBillingTablePrepared',
      'isTargetConfiguationRequired',
      'isTargetNone',
      'isTargetRequested',
      'isTargetExpiredAndAdminBlocked',
      'isTargetTerminated',
      'isTargetTesting',
      'isTargetPaymentDisabled',
      'targetRestorableDays'
    ]),
    isBillingPlanCriterionVisible() {
      return (
        !this.isTargetFreeTrialOperating &&
        !this.isRequestableCustomerService &&
        !this.isTargetTesting
      );
    },
    billingPlanDescription() {
      return this.$t('billing_plan_description');
    },
    tooltipNextBuyableDateMessage() {
      if (this.isTargetPaymentDisabled && this.isTargetOperating)
        return {
          message: this.$t('prepare_payment_disabled_tooltip'),
          textAlign: 'left'
        };
      return '';
    },
    noDataMessage() {
      if (this.isTargetFreeTrialExpired && this.isTargetConfiguationRequired)
        return this.$t('free_trial_expired_description_html');
      if (this.isTargetFreeTrial) return this.trialDescription;
      if (this.isTargetOperating) return this.operatingDescription;
      if (this.isCustomerServiceRequested)
        return this.$t('complete_request_description_html');
      if (this.isTargetTesting) return this.testingDescription;
      return null;
    },
    nextBuyableDateMessage() {
      if (this.isTargetFreeTrial || this.isTargetOperating)
        return this.$t('next_buyable_date', {
          nextBuyableDate:
            this.isTargetPaymentDisabled && this.isTargetOperating
              ? this.$t('prepare_payment_disabled')
              : this.nextBuyableDate
        });
      return '';
    },
    trialDescription() {
      const { target_expires_at } = this.targetStatus;

      const trialStartDate = moment(this.targetFreeTrialStartedAt);

      return this.$t('free_trial_description_html', {
        trialStartDate: this.formatDate(trialStartDate),
        trialEndDate: this.formatDate(target_expires_at)
      });
    },
    operatingDescription() {
      const { billing_start_at } = this.targetPayment.target_billing_info;
      const { target_expires_at } = this.targetStatus;

      return this.$t('operating_description_html', {
        planStartDate: this.formatDate(billing_start_at),
        targetExpiresDate: this.formatDate(target_expires_at),
        monthlyBonusPoint: this.monthlyBonusPoint,
        monthlyFreeDispatchableSmsCount: this.monthlyFreeDispatchableSmsCount
      });
    },
    monthlyBonusPoint() {
      const { monthly_bonus_point } = this.targetPayment.target_billing_info;
      return monthly_bonus_point;
    },
    monthlyFreeDispatchableSmsCount() {
      const { monthlyBonusPoint } = this;
      return Math.floor(monthlyBonusPoint / this.smsPriceCents);
    },
    testingDescription() {
      return this.$t('testing_description_html', {
        targetRestorableDays: this.targetRestorableDays,
        monthlyBonusPoint: this.monthlyBonusPoint,
        monthlyFreeDispatchableSmsCount: this.monthlyFreeDispatchableSmsCount
      });
    },
    isBuyable() {
      return this.isTargetBillingTablePrepared;
    },
    isRequestableCustomerService() {
      return (
        this.isTargetNone ||
        this.isTargetRequested ||
        this.isTargetExpiredAndAdminBlocked ||
        this.isTargetTerminated
      );
    },
    nextBuyableDate() {
      const { target_expires_at } = this.targetStatus;
      let date = '';
      if (this.isTargetOperating)
        date = moment(target_expires_at).subtract(15, 'day');
      else if (this.isTargetFreeTrial)
        date = moment(target_expires_at)
          .add(1, 'day')
          .toDate();
      return this.formatDate(date);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openDetailCriterionDialog() {
      this.openDialog(['TargetPaymentDetailCriterionDialog']);
    },
    openRequestCustomerServiceDialog() {
      this.openDialog(['TargetRequestCustomerServiceDialog', {}]).then(
        eventBus => {
          eventBus.$on('submit', () => {
            this.isCustomerServiceRequested = true;
          });
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
.ThePaymentDialogTargetPackagesTable__description {
  margin-bottom: 16px;
}

.ThePaymentDialogTargetPackagesTable__no-data {
  display: inline-block;
  margin: 58px auto;
  text-align: left;
}

.ThePaymentDialogTargetPackagesTable__detail-criterion-button {
  font-size: 12px;
  color: $color-blue;
  cursor: pointer;
}

.ThePaymentDialogTargetPackagesTable__request-customer-service-button {
  margin-top: 8px;
}

.ThePaymentDialogTargetPackagesTable__tootip_icon {
  position: relative;
  top: 2px;
}
</style>

<i18n locale="ko">
{
  "billing_plan_description": "크리마 타겟 이용료는 쇼핑몰의 일일 이용자수(DAU)를 기준으로 산정되며 통상 결제 시점 이전 3개월간의 데이터를 활용합니다.",
  "billing_plan_criterion": "해당 금액은 {nextDauCalculationDate} 이후 변경 될 수 있습니다.",
  "detail_criterion_button": "이용료 산정 기준 상세 보기",
  "free_trial_expired_description_html": "<b>크리마 타겟 무료 체험이 만료되었습니다.</b><br />현재 측정한 DAU를 기반으로 요금을 계산 중입니다.<br />잠시만 기다려 주세요.",
  "free_trial_description_html": "<b>크리마 타겟 무료 체험 중입니다.</b><br />이용기간: {trialStartDate} - {trialEndDate}",
  "operating_description_html": "<b>크리마 타겟 이용 중입니다.</b><br />이용기간: {planStartDate} - {targetExpiresDate}<br />매달 {monthlyBonusPoint}포인트 제공 (SMS 기준 {monthlyFreeDispatchableSmsCount}건 발송 가능, 팝업, 위젯 노출 무제한)",
  "complete_request_description_html": "<b>크리마 타겟 서비스 상담 신청이 완료되었습니다.</b><br />며칠 이내에 담당자가 연락드리도록 하겠습니다.",
  "testing_description_html": "<b>크리마 타겟 테스트 중 입니다.</b><br />테스트 중일 때는 사용기간이 차감되지 않습니다.<br />사용 시작 시 만료일이 계산됩니다.<br />이용기간: {targetRestorableDays}<br />매달 {monthly_bonus_point}포인트 제공 (SMS 기준 {monthlyFreeDispatchableSmsCount}건 발송 가능, 팝업, 위젯 노출 무제한)",
  "interested_in_target": "크리마 타겟에 관심이 있으신가요?",
  "request_customer_service": "크리마 타겟 상담 신청",
  "prepare_payment_disabled": "서비스 종료",
  "next_buyable_date": "다음 결제 가능일: {nextBuyableDate}",
  "prepare_payment_disabled_tooltip": "그동안 크리마 타겟 서비스를 이용해 주셔서 진심으로 감사합니다.\n아쉽게도 크리마 타겟 메시지 발송 및 팝업 캠페인은 서비스 종료 예정으로 결제가 불가합니다.\n남은 이용기간까지는 정상적으로 서비스 이용이 가능합니다.\n앞으로도 쇼핑몰 성장에 도움이 되는 서비스를 제공하기 위해 최선을 다하겠습니다.\n관련 문의는 크리마 타겟 운영팀으로 문의 바랍니다.\n메일: target@cre.ma\n전화: 1522-0305 (ARS > 2번)"
}
</i18n>
