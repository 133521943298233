<template>
  <i />
</template>

<script>
export default {
  name: 'AppSpinner'
};
</script>

<style scoped>
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

i {
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner 1s infinite linear;
}
</style>
