<template>
  <div class="ReviewMessagesCampaignsEffectCell">
    {{ effectText }}
  </div>
</template>

<script>
export default {
  name: 'ReviewMessagesCampaignsEffectCell',
  props: { effect: { type: Object, required: true } },
  computed: {
    effectText() {
      return this.$t('effect', {
        sent_count: this.effect.sent_count,
        check_rate: Math.round(this.effect.check_rate * 100),
        review_rate: Math.round(this.effect.review_rate * 100)
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "effect": "발송수: {sent_count}개 확인율: {check_rate}% 리뷰 작성률: {review_rate}%"
}
</i18n>
