import { constructEnum } from '@/lib/enum';

export default constructEnum('FitTransactionType', {
  PAYMENT: 10,
  FEE_PAYMENT: 11,
  PERIOD_BONUS: 45,
  EXPIRED: 50,
  RETURN: 60,
  FEE_RETURN: 61,
  ETC: 100
});
