<template>
  <div class="TheEasyJoin">
    <div class="TheEasyJoin__inner">
      <AppSvgIcon
        name="icon-crema-logo"
        :height="30"
        class="TheEasyJoin__logo"
      />
      <AppInfoBox
        v-if="shopBuilder !== 'makeshop'"
        class="TheEasyJoin__info-box"
      >
        <i18n path="info.content">
          <b>{{ $t('info.complete') }}</b>
        </i18n>
      </AppInfoBox>
      <AppProgressSteps
        class="TheEasyJoin__progress-step"
        :steps="[
          $t('easy_join_progress_step.create_account'),
          $t('easy_join_progress_step.manager_info')
        ]"
        :current-step="0"
      />
      <AppSpinnerContent
        :description="$t('submit_content.description')"
        :sub-descriptions="subDescriptions"
        :is-loading="isSubmitting"
      >
        <AppForm
          v-show="failureCount === 0 && errorType === null"
          id="easyJoin"
          object-id="registration_user"
          v-bind="formProps"
          :submit-button="{
            submitLabel: $t('submit'),
            buttonClasses: ['TheEasyJoin__submit']
          }"
          v-on="formEvents"
          @blur-group="formGroupBlurred"
        >
          <input
            type="hidden"
            name="registration_user[shop_key]"
            :value="shop_key"
          />
          <input
            v-if="shopBuilder == 'godo'"
            type="hidden"
            name="registration_user[service_code]"
            :value="service_code"
          />
          <template #group="{ id, error }">
            <template v-if="id === 'email'">
              <AppEmailVerificationInput
                id="email"
                v-model="formObject.email"
                name="registration_user[email]"
                :placeholder="$t('email.placeholder')"
                :enable-verification="false"
                :invalid="error && !error.after"
                @blur="validateField('email')"
                @change="validateField('email')"
              />
            </template>
            <template v-else-if="id === 'terms'">
              <AppTermsAgreementCheckbox
                id="terms"
                v-model="formObject.terms"
                name="registration_user[terms][]"
                :invalid="!!error"
                @change="validateField('terms')"
              />
            </template>
          </template>
          <template #description="{id}">
            <div
              v-if="shopBuilder === 'godo' && id === 'mall_id'"
              class="AppForm__description"
            >
              <AppInlineButton
                button-style="caption"
                :label="$t('godo.mall_id.description')"
                @click="clickMallIdDescription"
              />
            </div>
            <div
              v-if="shopBuilder === 'makeshop' && id === 'api_key'"
              class="TheEasyJoin__makeshop-api-key-description"
            >
              <i18n path="makeshop.api_key.description">
                <AppExternalLink
                  :label="$t('makeshop.api_key.help_center')"
                  :to="help_center_link"
                />
              </i18n>
            </div>
          </template>
        </AppForm>
        <TheEasyJoinError
          v-if="errorType !== null"
          v-bind="{ errorType, shopBuilder }"
          @submit="formEventBus.$emit('submit')"
        />
        <TheEasyJoinRetry
          v-else-if="failureCount > 0"
          @submit="formEventBus.$emit('submit')"
        />
      </AppSpinnerContent>
    </div>
    <TheDialogs />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { isDomainOnlyUrlFormatValid } from '@/lib/urlFormatValidator';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import PasswordField from '@/mixins/PasswordField';
import TheDialogs from './TheDialogs';
import AppSpinnerContent from '@/components/AppSpinnerContent';
import TheEasyJoinError from './components/TheEasyJoin/TheEasyJoinError.vue';
import TheEasyJoinRetry from './components/TheEasyJoin/TheEasyJoinRetry.vue';
import parseDomain from '@/lib/parseDomain';
import ShopBuilder from '@/enums/ShopBuilder';
import DialogSize from '@/enums/DialogSize';
import HttpStatus from '@/enums/HttpStatus';
import EasyJoinErrorType from '@/enums/EasyJoinErrorType';

export default {
  name: 'TheEasyJoin',
  components: {
    TheDialogs,
    AppSpinnerContent,
    TheEasyJoinError,
    TheEasyJoinRetry
  },
  mixins: [FormView, PasswordField],
  data() {
    const { shop_key, service_code } = this.$route.query;
    return {
      shop_key,
      service_code,
      terms: [],
      errorType: null,
      failureCount: parseInt(sessionStorage.getItem('failure_count')) || 0,
      isApiKeyValid: true,
      isMallIdValid: true
    };
  },
  computed: {
    help_center_link() {
      return 'https://help-review.cre.ma/hc/ko/articles/9991731029529--리뷰-메이크샵-API-권한-부여-안내';
    },
    shopBuilder() {
      return this.shop_key.split('/')[0];
    },
    formSections() {
      return [
        {
          id: 'user_section',
          groups: [
            {
              id: 'email',
              label: this.$t('email.label'),
              type: 'email_verification',
              validate: [
                'required',
                'email_format',
                { rule: 'check_email_duplication', async: true }
              ]
            },
            this.newPasswordGroup(true, this.$t('password_field.password')),
            this.formObject.password
              ? {
                  id: 'new_password_requirements',
                  type: 'new_password_requirements',
                  label: ''
                }
              : null,
            this.shouldDisplayApiKey(this.shopBuilder)
              ? {
                  id: 'api_key',
                  label: this.$t(`${this.shopBuilder}.api_key.label`),
                  type: 'text',
                  placeholder: this.$t(
                    `${this.shopBuilder}.api_key.placeholder`
                  ),
                  validate: [
                    'required',
                    {
                      rule: () => this.isApiKeyValid,
                      errorMessage:
                        this.shopBuilder === 'imweb'
                          ? this.$t('imweb.api_key.error')
                          : this.$t('makeshop.api_key.error')
                    }
                  ]
                }
              : null,
            this.shopBuilder === 'imweb'
              ? {
                  id: 'secret_key',
                  label: this.$t('imweb.secret_key.label'),
                  type: 'text',
                  placeholder: this.$t('imweb.secret_key.placeholder'),
                  validate: [
                    'required',
                    {
                      rule: () => this.isApiKeyValid,
                      errorMessage: this.$t('imweb.api_key.error')
                    }
                  ]
                }
              : null,
            this.shopBuilder === 'makeshop'
              ? {
                  id: 'domain',
                  label: this.$t('makeshop.domain.label'),
                  type: 'text',
                  placeholder: this.$t('makeshop.domain.placeholder'),
                  hint: this.$t('makeshop.domain.hint'),
                  validate: [
                    'required',
                    {
                      rule: v => isDomainOnlyUrlFormatValid(v),
                      errorMessage: this.$t('makeshop.domain.error')
                    },
                    this.urlChanged(
                      this.orgFormObject.domain,
                      this.formObject.domain
                    )
                      ? { rule: 'check_brand_url_duplication', async: true }
                      : null
                  ].filter(v => v)
                }
              : null,
            this.shopBuilder === 'godo'
              ? {
                  id: 'mall_id',
                  name: 'godo_mall_id',
                  label: this.$t('godo.mall_id.label'),
                  type: 'text',
                  placeholder: this.$t('godo.mall_id.placeholder'),
                  validate: [
                    'required',
                    {
                      rule: () => this.isMallIdValid,
                      errorMessage: this.$t('godo.mall_id.error')
                    }
                  ]
                }
              : null
          ].filter(v => v)
        },
        {
          id: 'terms_section',
          groups: [
            {
              id: 'terms',
              label: '',
              validate: [
                {
                  rule: v =>
                    _.difference(['age', 'use', 'privacy', 'outsource'], v)
                      .length === 0,
                  errorMessage: this.$t('validations.required')
                }
              ]
            }
          ]
        }
      ];
    },
    subDescriptions() {
      return [...Array(2)].map((_, i) =>
        this.$t(`submit_content.sub_description.${i}`)
      );
    }
  },
  watch: {
    'formObject.api_key'() {
      this.isApiKeyValid = true;
    },
    'formObject.secret_key'() {
      this.isApiKeyValid = true;
    },
    'formObject.mall_id'() {
      this.isMallIdValid = true;
    }
  },
  methods: {
    ...mapActions('session', ['fetchSession', 'redirectToNextPage']),
    ...mapActions('dialog', ['alert']),
    createAccount(formData) {
      api
        .post('/easy_join/registration', formData, { silent: true })
        .then(() => {
          this.fetchSession()
            .then(() => this.redirectToNextPage('/easy-join-manager-info'))
            .finally(() => (this.isSubmitting = false));
        })
        .catch(error => {
          this.isSubmitting = false;
          this.orgFormObject = { ...this.formObject };
          this.processCreateAccountError(error);
        });
    },
    createAccountForImweb(formData) {
      api
        .post('/imweb/api_key_validations/validate', formData, {
          silent: true
        })
        .then(() => {
          this.createAccount(formData);
        })
        .catch(error => {
          this.isSubmitting = false;

          if (
            error.response.data.errors[0] ===
            this.$t('ImwebApiResponseCode.INVALID_API_KEY_OR_SECRET')
          ) {
            this.isApiKeyValid = false;
          }
        })
        .finally(() => {
          this.validateField('api_key');
          this.validateField('secret_key');
        });
    },
    createAccountForMakeshop(formData) {
      api
        .post('/makeshop/api_key_validations/validate', formData, {
          silent: true
        })
        .then(() => {
          this.createAccount(formData);
        })
        .catch(error => {
          this.isSubmitting = false;

          if (
            error.response.data.errors[0] ===
            this.$t('MakeshopApiResponseCode.API_KEY_NOT_FOUND')
          ) {
            this.isApiKeyValid = false;
            this.validateField('api_key');
          }
        });
    },
    createAccountForGodo(formData) {
      const { shopBuilder } = ShopBuilder.parseShopKey(this.shop_key);

      const newShopKey = ShopBuilder.shopKey(
        shopBuilder,
        this.formObject.mall_id
      );
      formData.append('registration_user[shop_key]', newShopKey);

      api
        .post('/godo/mall_id_validations/validate', formData, {
          silent: true
        })
        .then(() => {
          this.createAccount(formData);
        })
        .catch(() => {
          this.isSubmitting = false;
          this.isMallIdValid = false;
        })
        .finally(() => {
          this.validateField('mall_id');
        });
    },
    submit(formData) {
      this.isSubmitting = true;

      switch (this.shopBuilder) {
        case 'imweb':
          return this.createAccountForImweb(formData);
        case 'makeshop':
          return this.createAccountForMakeshop(formData);
        case 'godo':
          return this.createAccountForGodo(formData);
        default:
          return this.createAccount(formData);
      }
    },
    shouldDisplayApiKey(shop_builder) {
      return ['imweb', 'makeshop'].includes(shop_builder);
    },
    urlChanged(orgUrl, newUrl) {
      if (!orgUrl || !newUrl) return orgUrl !== newUrl;
      return (
        orgUrl.replace(/(http(s?)):\/\//, '') !==
        newUrl.replace(/(http(s?)):\/\//, '')
      );
    },
    formGroupBlurred(id) {
      if (id === 'domain') {
        const newDomain = parseDomain(this.formObject.domain);
        this.formObject.domain = newDomain;
      }
    },
    clickMallIdDescription() {
      this.alert({
        title: this.$t('godo.dialog.title'),
        width: DialogSize.MIDDLE,
        message: this.$t('godo.dialog.message')
      });
    },
    processCreateAccountError(error) {
      const errorMessage = error?.response?.data?.errors?.[0];
      if (error?.response?.status === HttpStatus.UNPROCESSABLE_ENTITY) {
        if (errorMessage.includes('쇼핑몰명'))
          this.errorType = EasyJoinErrorType.STORE_NAME_MISSING;
        else if (errorMessage.includes('쇼핑몰 URL'))
          this.errorType = EasyJoinErrorType.STORE_URL_MISSING;
      }

      if (this.shopBuilder === 'cafe24') {
        sessionStorage.setItem('failure_count', ++this.failureCount);
        if (this.failureCount > 3) {
          this.$router.push({
            path: '/easy-join-failure',
            query: { shop_key: this.shop_key, email: this.formObject.email }
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.TheEasyJoin {
  margin: 0 auto;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  text-align: center;
  padding-bottom: 25px;
}

.TheEasyJoin__inner {
  width: 500px;
  margin: 0 auto;
  position: relative;
}

.TheEasyJoin__logo {
  display: block;
  margin: 53px auto 40px;
  color: black;
}

.TheEasyJoin__info-box {
  margin-bottom: 32px;
  white-space: pre-line;
}

.TheEasyJoin__progress-step {
  margin-bottom: 32px;
  padding-bottom: 12px;
  border-bottom: 1px solid $color-grey-25;
  text-align: left;
}

.TheEasyJoin__makeshop-api-key-description {
  @include text-caption;
  margin-top: 4px;

  a {
    color: $color-blue;
  }
}

::v-deep {
  #user_section--new_password_requirements {
    margin-top: 4px;
  }
}
</style>

<i18n locale="ko">
{
  "info": {
    "content": "{0} \n계정을 생성하여 크리마 리뷰를 쇼핑몰에 도입해보세요.",
    "complete": "크리마 리뷰 앱 설치가 완료되었습니다🎉"
  },
  "email": {
    "label": "로그인 아이디 (이메일)",
    "placeholder": "이메일 주소를 입력해주세요."
  },
  "imweb": {
    "api_key": {
      "label": "아임웹 Api Key",
      "placeholder": "아임웹에서 부가서비스 신청 시 발급받은 Rest Api Key를 입력해주세요.",
      "error": "Api Key 또는 Secret Key가 유효하지 않습니다."
    },
    "secret_key": {
      "label": "아임웹 Secret Key",
      "placeholder": "아임웹에서 부가서비스 신청 시 발급받은 Secret Key를 입력해주세요."
    }
  },
  "makeshop": {
    "api_key": {
      "label": "메이크샵 상점 키",
      "placeholder": "메이크샵 오픈 API 메뉴에서 발급받은 크리마 상점 키를 입력해주세요.",
      "error": "메이크샵 상점 키가 유효하지 않습니다.",
      "description": "상점키 발급 안내는 다음 링크를 참고해주세요. {0}",
      "help_center": "헬프센터 바로가기 >"
    },
    "domain": {
      "label": "쇼핑몰 URL",
      "placeholder": "메이크샵 관리자 페이지에 등록된 '내 쇼핑몰 주도메인 주소'를 입력해주세요.",
      "error": "잘못된 형식의 url입니다. (http:// 혹은 https://로 시작하는 주소를 입력해주세요)",
      "hint": "http://혹은 https://로 시작하는 주소를 입력해주세요."
    }
  },
  "godo": {
    "mall_id": {
      "label": "mall id",
      "placeholder": "mall id를 입력해주세요.",
      "error": "잘못된 mall id입니다. 다시 확인 후 입력해주세요.",
      "description": "mall id 확인방법 안내"
    },
    "dialog": {
      "title": "mall id 확인방법 안내",
      "message": "고도몰 관리자 페이지 접속 <br/>1)쇼핑몰 목록 -> 2)서비스 관리 -> 3)DB NAME 확인<br/>mall id_godomall_com으로 구성되어 있음 <br/><br/> <img src='https://assets.cre.ma/m/godo_mall_id_description@2x.png'/>"
    }
  },
  "submit": "크리마 계정 생성",
  "submit_content": {
    "description": "계정 생성 중입니다.",
    "sub_description": {
      "0": "쇼핑몰 정보를 가져오는 중입니다.",
      "1": "쇼핑몰 데이터 동기화를 진행합니다."
    }
  }
}
</i18n>
