<template>
  <div class="WidgetInstallRequestDialogEtc">
    <AppTable :columns="COLUMNS" :rows="ROWS" />
  </div>
</template>

<script>
export default {
  name: 'WidgetInstallRequestDialogEtc',
  data() {
    return {
      isLoading: false,
      COLUMNS: [],
      ROWS: []
    };
  }
};
</script>
