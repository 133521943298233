<template>
  <AppModalForm
    :title="$t('modal_title')"
    width="middle"
    :is-loading="false"
    :form-props="{
      ...formProps,
      submitLabel: $t('app.save')
    }"
    v-on="formEvents"
  >
    <template #head>
      <AppInfoBox class="AnalyticsInsightCompetingGroupDialog__infobox">
        {{ $t('infobox') }}
      </AppInfoBox>
    </template>
    <template #group="{ id, errors }">
      <div v-if="id === 'turnover'" class="AppForm__group-field">
        <AppSelectRadio
          v-model="settings.turnover"
          name="settings[turnover]"
          :options="OPTIONS"
          @change="validateField('turnover_type')"
        >
          <template #sub-item="{ value, checked }">
            <div v-if="value === 1" class="AppForm__sub-group">
              <div class="AppForm__sub-group-field">
                <AppSelect
                  :id="id"
                  v-model="settings.turnover_type"
                  :options="turnoverOptions"
                  :disabled="!checked"
                  @change="validateField('turnover_type')"
                />
                <AppFormError :error="errors['turnover_type']" />
              </div>
            </div>
          </template>
        </AppSelectRadio>
      </div>
      <div v-else class="AppForm__group-field">
        <AppSelectRadio
          v-model="settings[id]"
          :name="`settings[${id}]`"
          :options="OPTIONS"
          @change="validateField(`${id}_category_ids`)"
        >
          <template #sub-item="{ value, checked }">
            <div v-if="value === 1" class="AppForm__sub-group">
              <div class="AppForm__sub-group-field">
                <AppMultipleSelect
                  :id="id"
                  v-model="settings[`${id}_category_ids`]"
                  v-bind="{ options: self[`${id}Options`] }"
                  packing-method="string"
                  :disabled="!checked"
                  :invalid="!!errors[`${id}_category_ids`]"
                  @change="validateField(`${id}_category_ids`)"
                />
                <AppFormError :error="errors[`${id}_category_ids`]" />
              </div>
            </div>
          </template>
        </AppSelectRadio>
      </div>
    </template>
    <template #foot>
      <div class="AnalyticsInsightCompetingGroupDialog__button">
        <AppButton
          :label="$t('initialize_to_default')"
          button-style="grey-clear"
          @click="initializeToDefault"
        />
      </div>
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import DialogSize from '@/enums/DialogSize';
import TurnoverType from '@/enums/TurnoverType';

export default {
  name: 'AnalyticsInsightCompetingGroupDialog',
  mixins: [DialogFormView],
  props: {
    dateRange: { type: Object, required: true },
    productCategoryIds: { type: String, required: true },
    genderCategoryIds: { type: String, required: true },
    ageCategoryIds: { type: String, required: true },
    styleCategoryIds: { type: String, required: true },
    turnoverType: { type: String, required: true }
  },
  data() {
    return {
      OPTIONS: [
        { label: this.$t('app.all'), value: 0 },
        { label: this.$t('select_categories'), value: 1 }
      ]
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('competingGroup', [
      'turnoverTypeValue',
      'turnoverTypeCountMap'
    ]),
    ...mapGetters('brandCategory', [
      'brandMarketCategories',
      'brandsProductCategories',
      'brandsGenderCategories',
      'brandsAgeCategories',
      'brandsStyleCategories',
      'myBrandProductCategories',
      'myBrandGenderCategories',
      'myBrandAgeCategories',
      'myBrandStyleCategories'
    ]),
    TurnoverType: () => TurnoverType,
    settings() {
      return this.formObject;
    },
    formSections() {
      const categoryGroups = [
        {
          id: 'product',
          label: this.$t('app.product')
        },
        {
          id: 'gender',
          label: this.$t('app.gender')
        },
        {
          id: 'age',
          label: this.$t('age')
        },
        {
          id: 'style',
          label: this.$t('app.style')
        }
      ].map(group => ({
        ...group,
        fields:
          this.settings[group.id] === 1
            ? [{ id: `${group.id}_category_ids`, validate: ['required'] }]
            : []
      }));
      const turnoverGroup = {
        id: 'turnover',
        label: this.$t('turnover'),
        fields:
          this.settings.turnover === 1
            ? [{ id: 'turnover_type', validate: ['required'] }]
            : [],
        hint: this.$t('turnover_description')
      };

      const groups = [...categoryGroups, turnoverGroup]
        .map(group => ({
          ...group,
          type: 'select_radio',
          validate: ['required']
        }))
        .filter(
          group =>
            this.currentBrand.is_fashion_mall ||
            ['product', 'turnover'].includes(group.id)
        );

      return [{ groups }];
    },
    productOptions() {
      const marketCategoryIdToNameMap = _.reduce(
        this.brandMarketCategories,
        (map, category) => {
          map[category.id] = category.category_value;
          return map;
        },
        {}
      );

      return this.brandsProductCategories
        .filter(c => c.brands_count > 0)
        .map(c => {
          const marketCategoryName =
            marketCategoryIdToNameMap[c.market_brand_category_id];
          return {
            value: c.id,
            label: `${marketCategoryName} > ${c.category_value} (${this.$t(
              'brands_count'
            )}: ${c.brands_count})`
          };
        });
    },
    genderOptions() {
      return this.brandsGenderCategories.map(c => ({
        value: c.id,
        label: `${c.category_value} (${this.$t('brands_count')}: ${
          c.brands_count
        })`
      }));
    },
    ageOptions() {
      return this.brandsAgeCategories.map(c => ({
        value: c.id,
        label: `${c.category_value} (${this.$t('brands_count')}: ${
          c.brands_count
        })`
      }));
    },
    styleOptions() {
      return this.brandsStyleCategories.map(c => ({
        value: c.id,
        label: `${c.category_value} (${this.$t('brands_count')}: ${
          c.brands_count
        })`
      }));
    },
    turnoverOptions() {
      return this.TurnoverType.options()
        .reverse()
        .map(option => {
          const brandsCount = this.turnoverTypeCountMap[String(option.value)];
          if (!brandsCount) return null;

          return {
            value: String(option.value),
            label: `${option.label} (${this.$t(
              'brands_count'
            )}: ${brandsCount})`
          };
        })
        .filter(o => o);
    },
    self() {
      return this;
    }
  },
  mounted() {
    this.orgFormObject = this.pickByKeys({
      product: this.productCategoryIds.length ? 1 : 0,
      gender: this.genderCategoryIds.length ? 1 : 0,
      age: this.ageCategoryIds.length ? 1 : 0,
      style: this.styleCategoryIds.length ? 1 : 0,
      turnover: this.turnoverType ? 1 : 0,
      product_category_ids: this.productCategoryIds,
      gender_category_ids: this.genderCategoryIds,
      age_category_ids: this.ageCategoryIds,
      style_category_ids: this.styleCategoryIds,
      turnover_type: this.turnoverType
    });
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('competingGroup', ['SET_FILTERED_BRANDS_COUNT']),
    initializeToDefault() {
      this.formObject = this.pickByKeys({
        product: this.myBrandProductCategories.length ? 1 : 0,
        gender: this.myBrandGenderCategories.length ? 1 : 0,
        age: this.myBrandAgeCategories.length ? 1 : 0,
        style: this.myBrandStyleCategories.length ? 1 : 0,
        turnover: 1,
        product_category_ids: this.myBrandProductCategories
          .map(c => c.id)
          .join(','),
        gender_category_ids: this.myBrandGenderCategories
          .map(c => c.id)
          .join(','),
        age_category_ids: this.myBrandAgeCategories.map(c => c.id).join(','),
        style_category_ids: this.myBrandStyleCategories
          .map(c => c.id)
          .join(','),
        turnover_type: this.turnoverTypeValue
      });
    },
    submit() {
      this.isSubmitting = true;
      api
        .get('/analytics/dashboard/brands_count', {
          params: this.pickByKeys({
            product_category_ids: this.settings.product
              ? this.settings.product_category_ids
              : '',
            gender_category_ids: this.settings.gender
              ? this.settings.gender_category_ids
              : '',
            age_category_ids: this.settings.age
              ? this.settings.age_category_ids
              : '',
            style_category_ids: this.settings.style
              ? this.settings.style_category_ids
              : '',
            turnover_type: this.settings.turnover
              ? this.settings.turnover_type
              : ''
          })
        })
        .then(({ data }) => {
          const filteredBrandsCount = data.brands_count;
          if (filteredBrandsCount >= 1)
            this.openConfirmDialog(filteredBrandsCount);
          else this.openAlertDialog();
        });
    },
    openConfirmDialog(filteredBrandsCount) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t('confirm_title'),
          markdownText: this.$t('confirm_text', [filteredBrandsCount]),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => {
          this.SET_FILTERED_BRANDS_COUNT(filteredBrandsCount);
          this.$nextTick(() => {
            this.close(true);
            this.emitSettings();
          });
        });
        eventBus.$on('cancel', () => (this.isSubmitting = false));
      });
    },
    openAlertDialog() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('alert_title'),
          markdownText: this.$t('alert_text'),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => (this.isSubmitting = false));
      });
    },
    emitSettings() {
      this.emit(
        'submit',
        this.pickByKeys({
          product_category_ids: this.settings.product
            ? this.settings.product_category_ids
            : 'all',
          gender_category_ids: this.settings.gender
            ? this.settings.gender_category_ids
            : 'all',
          age_category_ids: this.settings.age
            ? this.settings.age_category_ids
            : 'all',
          style_category_ids: this.settings.style
            ? this.settings.style_category_ids
            : 'all',
          turnover_type: this.settings.turnover
            ? this.settings.turnover_type
            : 'all'
        })
      );
    },
    pickByKeys(object) {
      return _.pickBy(
        object,
        (_v, k) =>
          this.currentBrand.is_fashion_mall ||
          k.includes('product') ||
          k.includes('turnover')
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.AnalyticsInsightCompetingGroupDialog__infobox {
  white-space: pre-wrap;
  word-break: break-word;
  margin-bottom: 24px;
}

.AnalyticsInsightCompetingGroupDialog__button {
  float: right;

  ::v-deep .AppButton__button {
    text-decoration: underline;
  }
}

::v-deep {
  .AppSelect {
    display: block;
  }
}
</style>

<i18n locale="ko">
{
  "modal_title": "경쟁 그룹 설정",
  "infobox": "크리마 인사이트에서 자사와 비교분석할 경쟁 그룹을 설정할 수 있습니다.\n자사 데이터를 기준으로 자동으로 기본값이 설정됩니다.",
  "initialize_to_default": "기본값으로 초기화",
  "select_categories": "특정 카테고리 선택",
  "brands_count": "쇼핑몰수",
  "age": "연령대",
  "turnover": "월 거래액",
  "turnover_description": "최근 3개월의 월 거래액 일 평균X30으로 계산하며, 3개월치 데이터가 없을 경우 데이터가 있는 기간의 일 평균으로 계산합니다.",
  "confirm_title": "경쟁 그룹 설정을 적용하시겠습니까?",
  "confirm_text": "선택된 경쟁 그룹에 포함된 쇼핑몰수는 {0}개입니다.<br>저장 시 크리마 인사이트의 모든 페이지에서 변경된 경쟁 그룹이 적용됩니다.",
  "alert_title": "선택된 경쟁 그룹에 포함된 쇼핑몰이 없습니다.",
  "alert_text": "선택된 경쟁 그룹에 포함된 쇼핑몰이 없습니다.<br>기본값으로 설정하거나 선택된 옵션을 변경해주세요."
}
</i18n>
