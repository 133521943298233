<template>
  <AppModalFormLivePreview
    :is-loading="isLoading"
    :title="$t(attributeName('title'))"
    :sub-title="campaign.name"
    :form-props="{ ...formProps, objectId: 'widget_design_attributes' }"
    can-maximize
    v-on="formEvents"
    @error-summary="updatePreviewIfValid"
  >
    <template #left>
      <TargetCampaignProductRecommendationWidgetPreview
        :current-version="currentVersion"
        :display-version="displayVersion"
        :show-device-type-select-button="false"
        :show-pc-as-default="deviceType == 'pc'"
      />
    </template>
    <AppTabs
      v-model="activeTab"
      :tabs="tabOptions"
      @change="validateTabFields"
    />
    <template #group="{ id, value, disabled, errors }">
      <template v-if="id === 'recommendation_unit_title'">
        <TargetWidgetDesignFontFields
          :default-object="defaultObject"
          :font-size-id="attributeName('recommendation_unit_title_font_size')"
          :padding-bottom-id="
            attributeName('recommendation_unit_title_padding_bottom')
          "
          :font-color-id="attributeName('recommendation_unit_title_font_color')"
          :font-weight-id="
            attributeName('recommendation_unit_title_font_weight')
          "
          :form-object="formObject"
          :errors="errors"
          @update="updateFontField"
        />
      </template>
      <template v-else-if="id === 'recommendation_unit_description'">
        <TargetWidgetDesignFontFields
          :default-object="defaultObject"
          :font-size-id="
            attributeName('recommendation_unit_description_font_size')
          "
          :padding-bottom-id="
            attributeName('recommendation_unit_description_padding_bottom')
          "
          :font-color-id="
            attributeName('recommendation_unit_description_font_color')
          "
          :font-weight-id="
            attributeName('recommendation_unit_description_font_weight')
          "
          :form-object="formObject"
          :errors="errors"
          @update="updateFontField"
        />
      </template>
      <template v-else-if="id === 'product_name_style'">
        <TargetWidgetDesignFontFields
          :default-object="defaultObject"
          :font-size-id="attributeName('product_name_font_size')"
          :font-color-id="attributeName('product_name_font_color')"
          :font-weight-id="attributeName('product_name_font_weight')"
          :padding-top-id="
            isReviewHighlightWidgetCampaign
              ? null
              : attributeName('product_name_padding_top')
          "
          :padding-bottom-id="
            isReviewHighlightWidgetCampaign
              ? null
              : attributeName('product_name_padding_bottom')
          "
          :form-object="formObject"
          :errors="errors"
          @update="updateFontField"
        />
      </template>
      <template v-else-if="id === 'banner_product_name_style'">
        <TargetWidgetDesignFontFields
          :default-object="defaultObject"
          :font-size-id="attributeName('banner_product_name_font_size')"
          :font-color-id="attributeName('banner_product_name_font_color')"
          :font-weight-id="attributeName('banner_product_name_font_weight')"
          :form-object="formObject"
          :errors="errors"
          @update="updateFontField"
        />
      </template>
      <template v-else-if="id === 'product_final_price_style'">
        <TargetWidgetDesignFontFields
          :default-object="defaultObject"
          :font-size-id="attributeName('product_final_price_font_size')"
          :font-color-id="attributeName('product_final_price_font_color')"
          :font-weight-id="attributeName('product_final_price_font_weight')"
          :padding-bottom-id="
            isReviewHighlightWidgetCampaign
              ? null
              : attributeName('product_final_price_padding_bottom')
          "
          :form-object="formObject"
          :errors="errors"
          :disabled="isDisabledByProductInfoType"
          @update="updateFontField"
        />
      </template>
      <template v-else-if="id === 'banner_product_final_price_style'">
        <TargetWidgetDesignFontFields
          :default-object="defaultObject"
          :font-size-id="attributeName('banner_product_final_price_font_size')"
          :font-color-id="
            attributeName('banner_product_final_price_font_color')
          "
          :font-weight-id="
            attributeName('banner_product_final_price_font_weight')
          "
          :form-object="formObject"
          :errors="errors"
          :disabled="isDisabledByProductInfoType"
          @update="updateFontField"
        />
      </template>
      <template v-else-if="id === 'product_original_price_style'">
        <TargetWidgetDesignFontFields
          :default-object="defaultObject"
          :font-size-id="attributeName('product_original_price_font_size')"
          :font-color-id="attributeName('product_original_price_font_color')"
          :font-weight-id="attributeName('product_original_price_font_weight')"
          :form-object="formObject"
          :errors="errors"
          :disabled="
            isDisabledByProductInfoType ||
              !formObject[attributeName('display_original_price')]
          "
          @update="updateFontField"
        />
      </template>
      <template v-else-if="id === 'banner_product_original_price_style'">
        <TargetWidgetDesignFontFields
          :default-object="defaultObject"
          :font-size-id="
            attributeName('banner_product_original_price_font_size')
          "
          :font-color-id="
            attributeName('banner_product_original_price_font_color')
          "
          :font-weight-id="
            attributeName('banner_product_original_price_font_weight')
          "
          :form-object="formObject"
          :errors="errors"
          :disabled="
            isDisabledByProductInfoType ||
              !formObject[attributeName('display_original_price')]
          "
          @update="updateFontField"
        />
      </template>
      <template v-else-if="id === 'review_message_style'">
        <TargetWidgetDesignFontFields
          :default-object="defaultObject"
          :font-size-id="attributeName('review_message_style_font_size')"
          :font-color-id="attributeName('review_message_style_font_color')"
          :font-weight-id="attributeName('review_message_style_font_weight')"
          :padding-top-id="
            isPc ? attributeName('review_message_style_padding_top') : null
          "
          :padding-bottom-id="
            isPc ? attributeName('review_message_style_padding_bottom') : null
          "
          :form-object="formObject"
          :errors="errors"
          @update="updateFontField"
        />
      </template>
      <template v-else-if="id === 'banner_review_message_style'">
        <TargetWidgetDesignFontFields
          :default-object="defaultObject"
          :font-size-id="attributeName('banner_review_message_style_font_size')"
          :font-color-id="
            attributeName('banner_review_message_style_font_color')
          "
          :font-weight-id="
            attributeName('banner_review_message_style_font_weight')
          "
          :form-object="formObject"
          :errors="errors"
          @update="updateFontField"
        />
      </template>
      <template v-else-if="id === attributeName('show_page_link_button')">
        <div class="AppForm__group-description">
          {{
            $t(
              'form.page_link_button_section.show_page_link_button.description'
            )
          }}
        </div>
        <div
          :class="[
            'AppForm__group-field',
            {
              'AppForm__group-field--disabled': !value
            }
          ]"
        >
          <div class="AppForm__sub-group">
            <div class="AppForm__sub-group-item">
              <label class="AppForm__sub-group-title">
                {{
                  $t(
                    'form.page_link_button_section.show_page_link_button.page_link_button_text.label'
                  )
                }}</label
              >
              <AppKeywordTextarea
                v-model="formObject[attributeName('page_link_button_text')]"
                :keywords="keywords"
                :name="
                  `widget_design_attributes[${attributeName(
                    'page_link_button_text'
                  )}]`
                "
                :placeholder="
                  $t(
                    'form.page_link_button_section.show_page_link_button.page_link_button_text.placeholder'
                  )
                "
                :single-line="true"
                :disabled="!value"
                :invalid="!!errors[attributeName('page_link_button_text')]"
                @blur="validateField(attributeName('page_link_button_text'))"
                @change="validateField(attributeName('page_link_button_text'))"
              />
              <AppFormError
                :error="errors[attributeName('page_link_button_text')]"
              />
              <AppFormHint
                :message="
                  $t(
                    'form.page_link_button_section.show_page_link_button.page_link_button_text.hint',
                    {
                      reviews_count: '%{reviews_count}'
                    }
                  )
                "
                :disabled="!value"
              />
            </div>
            <div class="AppForm__sub-group-item">
              <label class="AppForm__sub-group-title">
                {{
                  $t(
                    'form.page_link_button_section.show_page_link_button.page_link_button_url.label'
                  )
                }}</label
              >
              <AppTextInput
                v-model="formObject[attributeName('page_link_button_url')]"
                :name="
                  `widget_design_attributes[${attributeName(
                    'page_link_button_url'
                  )}]`
                "
                :placeholder="
                  $t(
                    'form.page_link_button_section.show_page_link_button.page_link_button_url.placeholder'
                  )
                "
                :disabled="!value"
                :invalid="!!errors[attributeName('page_link_button_url')]"
                @blur="validateField(attributeName('page_link_button_url'))"
                @change="validateField(attributeName('page_link_button_url'))"
              />
              <AppFormError
                :error="errors[attributeName('page_link_button_url')]"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="id === attributeName('page_link_button_style')">
        <TargetWidgetDesignPageLinkButtonStyle
          :default-object="defaultObject"
          :background-color-id="
            attributeName('page_link_button_background_color')
          "
          :border-color-id="attributeName('page_link_button_border_color')"
          :border-width-id="attributeName('page_link_button_border_width')"
          :use-full-width-id="attributeName('use_page_link_button_full_width')"
          :width-id="attributeName('page_link_button_width')"
          :horizontal-padding-id="
            attributeName('page_link_button_horizontal_padding')
          "
          :border-radius-id="attributeName('page_link_button_border_radius')"
          :font-size-id="attributeName('page_link_button_font_size')"
          :font-color-id="attributeName('page_link_button_font_color')"
          :font-weight-id="attributeName('page_link_button_font_weight')"
          :form-object="formObject"
          :disabled="!formObject[attributeName('show_page_link_button')]"
          :is-pc="isPc && !isCartPopupWidgetCampaign"
          :errors="errors"
          @update="updateFontField"
        />
      </template>
      <template v-else-if="id === 'widget_margin'">
        <div class="AppForm__group-field AppForm__group-field--mt12">
          <div class="TargetCampaignSettingsWidgetDesignDialog__input-section">
            <div
              class="TargetCampaignSettingsWidgetDesignDialog__advanced-input"
            >
              <label class="TargetCampaignSettingsWidgetDesignDialog__label">{{
                $t('form.admin_section.widget_margin.top')
              }}</label>
              <AppNumberInput
                id="widget_margin_top"
                v-model="formObject[attributeName('widget_margin_top')]"
                :allow-negative="true"
                :name="
                  `widget_design_attributes[${attributeName(
                    'widget_margin_top'
                  )}]`
                "
                :digits="3"
                :default="isPc ? 60 : 32"
                @blur="validateField(attributeName('widget_margin_top'))"
              />
            </div>
            <div
              class="TargetCampaignSettingsWidgetDesignDialog__advanced-input"
            >
              <label class="TargetCampaignSettingsWidgetDesignDialog__label">{{
                $t('form.admin_section.widget_margin.right')
              }}</label>
              <AppNumberInput
                id="widget_margin_right"
                v-model="formObject[attributeName('widget_margin_right')]"
                :allow-negative="true"
                :name="
                  `widget_design_attributes[${attributeName(
                    'widget_margin_right'
                  )}]`
                "
                :digits="3"
                :default="isPc ? 0 : 16"
                @blur="validateField(attributeName('widget_margin_right'))"
              />
            </div>
          </div>
          <div class="TargetCampaignSettingsWidgetDesignDialog__input-section">
            <div
              class="TargetCampaignSettingsWidgetDesignDialog__advanced-input"
            >
              <label class="TargetCampaignSettingsWidgetDesignDialog__label">{{
                $t('form.admin_section.widget_margin.bottom')
              }}</label>
              <AppNumberInput
                id="widget_margin_bottom"
                v-model="formObject[attributeName('widget_margin_bottom')]"
                :allow-negative="true"
                :name="
                  `widget_design_attributes[${attributeName(
                    'widget_margin_bottom'
                  )}]`
                "
                :digits="3"
                :default="isPc ? 60 : 32"
                @blur="validateField(attributeName('widget_margin_bottom'))"
              />
            </div>
            <div
              class="TargetCampaignSettingsWidgetDesignDialog__advanced-input"
            >
              <label class="TargetCampaignSettingsWidgetDesignDialog__label">{{
                $t('form.admin_section.widget_margin.left')
              }}</label>
              <AppNumberInput
                id="widget_margin_left"
                v-model="formObject[attributeName('widget_margin_left')]"
                :allow-negative="true"
                :name="
                  `widget_design_attributes[${attributeName(
                    'widget_margin_left'
                  )}]`
                "
                :digits="3"
                :default="isPc ? 0 : 16"
                @blur="validateField(attributeName('widget_margin_left'))"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="id === attributeName('widget_image_ratio')">
        <AppFormHint
          :message="$t('form.product_image_section.widget_image_ratio.hint')"
          :disabled="disabled"
        />
        <div
          :class="[
            'AppForm__group-field',
            { 'AppForm__group-field--disabled': disabled }
          ]"
        >
          {{
            $t('form.product_image_section.widget_image_ratio.product_image')
          }}
        </div>
        <div
          :class="[
            'AppForm__group-field',
            { 'AppForm__group-field--disabled': disabled }
          ]"
        >
          <TargetWidgetDesignImageRatioField
            :form-object="formObject"
            :image-x-id="attributeName('banner_product_image_ratio_x')"
            :image-y-id="attributeName('banner_product_image_ratio_y')"
            :disabled="disabled"
            :errors="errors"
            @update="updateImageRatioField"
          />
        </div>
        <div
          :class="[
            'AppForm__group-field',
            { 'AppForm__group-field--disabled': disabled }
          ]"
        >
          {{ $t('form.product_image_section.widget_image_ratio.review_image') }}
        </div>
        <div
          :class="[
            'AppForm__group-field',
            { 'AppForm__group-field--disabled': disabled }
          ]"
        >
          <TargetWidgetDesignImageRatioField
            :form-object="formObject"
            :image-x-id="attributeName('banner_review_image_ratio_x')"
            :image-y-id="attributeName('banner_review_image_ratio_y')"
            :disabled="disabled"
            :errors="errors"
            @update="updateImageRatioField"
          />
        </div>
      </template>
      <template v-else-if="id === attributeName('widget_main_image_ratio')">
        <div
          :class="[
            'AppForm__group-field',
            { 'AppForm__group-field--disabled': disabled }
          ]"
        >
          <TargetWidgetDesignImageRatioField
            :form-object="formObject"
            :image-x-id="attributeName('custom_product_image_ratio_x')"
            :image-y-id="attributeName('custom_product_image_ratio_y')"
            :disabled="disabled"
            :errors="errors"
            @update="updateImageRatioField"
          />
        </div>
      </template>
    </template>
    <template #group-sub-item="{ group, value, checked, errors }">
      <template v-if="group.id === attributeName('show_horizontal_scroll')">
        <template v-if="value">
          <div class="TargetCampaignSettingsWidgetDesignDialog__advanced-input">
            <label class="TargetCampaignSettingsWidgetDesignDialog__label">{{
              $t('form.basic_section.show_horizontal_scroll.true.description')
            }}</label>
            <AppNumberInput
              v-model="formObject[attributeName('products_count_per_page')]"
              :name="
                `widget_design_attributes[${attributeName(
                  'products_count_per_page'
                )}]`
              "
              allow-decimal
              :invalid="!!errors[attributeName('products_count_per_page')]"
              :digits="3"
              :disabled="!formObject[attributeName('show_horizontal_scroll')]"
              @blur="validateField(attributeName('products_count_per_page'))"
              @change="validateField(attributeName('products_count_per_page'))"
            />
          </div>
          <AppFormError
            :error="errors[attributeName('products_count_per_page')]"
          />
        </template>
      </template>
      <template
        v-if="group.id === attributeName('horizontal_scroll_enable_autoscroll')"
      >
        <template v-if="value">
          <i18n
            path="form.basic_section.horizontal_scroll_autoscroll_delay.label"
          >
            <AppNumberInput
              v-model="
                formObject[attributeName('horizontal_scroll_autoscroll_delay')]
              "
              :disabled="
                !formObject[
                  attributeName('horizontal_scroll_enable_autoscroll')
                ]
              "
              :name="
                `widget_design_attributes[${attributeName(
                  'horizontal_scroll_autoscroll_delay'
                )}]`
              "
              :min="1"
              :max="99"
              :digits="2"
              @blur="
                validateField(
                  attributeName('horizontal_scroll_autoscroll_delay')
                )
              "
              @change="
                validateField(
                  attributeName('horizontal_scroll_autoscroll_delay')
                )
              "
            />
          </i18n>
          <AppFormError
            :error="errors[attributeName('horizontal_scroll_autoscroll_delay')]"
          />
        </template>
      </template>
      <template v-if="group.id === attributeName('widget_main_image_type')">
        <template v-if="value === TargetWidgetMainImageType.REVIEW">
          <AppFormHint
            :message="
              $t(
                'form.product_image_section.widget_main_image_type.review.hint'
              )
            "
            :disabled="!checked"
          />
        </template>
      </template>
      <template
        v-if="group.id === attributeName('use_original_product_image_ratio')"
      >
        <template v-if="value">
          <AppFormHint
            :message="
              $t(
                'form.product_image_section.use_original_product_image_ratio.true.hint'
              )
            "
            :disabled="!checked"
          />
        </template>
        <template v-else>
          <AppFormHint
            :message="
              $t(
                'form.product_image_section.use_original_product_image_ratio.false.hint'
              )
            "
            :disabled="!checked"
          />
          <TargetWidgetDesignImageRatioField
            class="AppForm__group-field"
            :form-object="formObject"
            :image-x-id="attributeName('custom_product_image_ratio_x')"
            :image-y-id="attributeName('custom_product_image_ratio_y')"
            :disabled="!checked"
            :errors="errors"
            @update="updateImageRatioField"
          />
        </template>
      </template>
      <template v-else-if="group.id === attributeName('product_info_type')">
        <div
          v-if="value === TargetRecommendationProductInfoType.PRICE"
          class="AppForm__sub-group"
        >
          <div class="AppForm__sub-group-field">
            <AppCheckbox
              v-model="formObject[attributeName('display_original_price')]"
              :disabled="!checked"
              :name="
                `widget_design_attributes[${attributeName(
                  'display_original_price'
                )}]`
              "
              :label="
                $t(
                  'form.product_information_section.display_original_price.description'
                )
              "
              @change="validateField(attributeName('display_original_price'))"
            />
          </div>
        </div>
      </template>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import qs from 'qs';
import { mapMutations, mapState, mapGetters } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import IframePreviewHelper from '@/mixins/IframePreviewHelper';
import TargetCampaignSettingsWidgetDesignFormView from '@/mixins/TargetCampaignSettingsWidgetDesignFormView';
import TargetWidgetStyle from '@/enums/TargetWidgetStyle';
import TargetWidgetProductInformationDisplayStyle from '@/enums/TargetWidgetProductInformationDisplayStyle';
import TargetPositiveReviewKeywordDisplayType from '@/enums/TargetPositiveReviewKeywordDisplayType';
import TargetRecommendationProductInfoType from '@/enums/TargetRecommendationProductInfoType';
import TargetWidgetMainImageType from '@/enums/TargetWidgetMainImageType';
import BrandReviewStatus from '@/enums/BrandReviewStatus';

const BASIC_TAB = 'basic';
const DETAIL_TAB = 'detail';

export default {
  name: 'TargetCampaignSettingsWidgetDesignDialog',
  mixins: [
    DialogFormView,
    IframePreviewHelper,
    TargetCampaignSettingsWidgetDesignFormView
  ],
  props: {
    campaign: { type: Object, required: true },
    deviceType: { type: String, required: true }
  },
  data: () => ({
    isLoading: true,
    activeTab: BASIC_TAB,
    defaultObject: {}
  }),
  computed: {
    TargetWidgetMainImageType: () => TargetWidgetMainImageType,
    ...mapState('session', [
      'reviewSettings',
      'targetSettings',
      'currentBrand'
    ]),
    ...mapGetters('targetCampaign', [
      'isReviewHighlightWidgetCampaign',
      'isCartPopupWidgetCampaign'
    ]),
    TargetPositiveReviewKeywordDisplayType: () =>
      TargetPositiveReviewKeywordDisplayType,
    TargetRecommendationProductInfoType: () =>
      TargetRecommendationProductInfoType,
    tabOptions() {
      return [
        {
          id: BASIC_TAB,
          label: this.$t(`tabs.${BASIC_TAB}`)
        },
        {
          id: DETAIL_TAB,
          label: this.$t(`tabs.${DETAIL_TAB}`)
        }
      ];
    },
    advantageReviewScoreStandard() {
      return this.reviewSettings.perfect_score * 0.8;
    },
    isPcVerticalPaddingEnabled() {
      return (
        (this.isPc &&
          this.formObject.pc_widget_style === TargetWidgetStyle.GALLERY) ||
        (this.isCartPopupWidgetCampaign &&
          this.formObject.pc_horizontal_scroll_multiple_rows)
      );
    },
    isMobileVerticalPaddingEnabled() {
      const {
        mobile_widget_style,
        mobile_horizontal_scroll_multiple_rows
      } = this.formObject;
      return (
        !this.isPc &&
        (mobile_widget_style === TargetWidgetStyle.GALLERY ||
          TargetWidgetStyle.listStyles.includes(mobile_widget_style) ||
          mobile_horizontal_scroll_multiple_rows)
      );
    },
    isVerticalPaddingEnabled() {
      return (
        this.isPcVerticalPaddingEnabled || this.isMobileVerticalPaddingEnabled
      );
    },
    isWidgetMainImageTypeEmpty() {
      return (
        this.formObject[this.attributeName('widget_main_image_type')] ===
        TargetWidgetMainImageType.EMPTY
      );
    },
    isWidgetMainImageTypeReview() {
      return (
        this.formObject[this.attributeName('widget_main_image_type')] ===
        TargetWidgetMainImageType.REVIEW
      );
    },
    isHorizontalScrollStyle() {
      return (
        this.formObject[this.attributeName('widget_style')] ===
        TargetWidgetStyle.HORIZONTAL_SCROLL
      );
    },
    isListStyle() {
      return (
        TargetWidgetStyle.listStyles.includes(
          this.formObject.mobile_widget_style
        ) && !this.isPc
      );
    },
    isBannerStyle() {
      return (
        this.formObject[this.attributeName('widget_style')] ===
        TargetWidgetStyle.BANNER
      );
    },
    isPc() {
      return this.deviceType === 'pc';
    },
    campaignId() {
      return this.campaign.id;
    },
    previewUrl() {
      const params = qs.stringify({
        v: this.displayVersion,
        iframe: '1'
      });

      let previewUrl = this.campaign.widget_preview_url;
      previewUrl = this.isPc
        ? previewUrl
        : previewUrl.replace('/target/', '/target/mobile/');
      return `${previewUrl}&${params}`;
    },
    basicSections() {
      return [
        {
          groups: [
            'widget_style',
            ...this.horizontalStyleGroupIds,
            ...(!this.isReviewHighlightWidgetCampaign
              ? ['product_information_display_style']
              : []),
            ...(this.isListStyle
              ? ['show_horizontal_scroll']
              : this.isBannerStyle
              ? [
                  'reviews_count_per_product',
                  'vertical_padding_between_reviews'
                ]
              : [
                  'products_count_per_row',
                  'horizontal_padding_between_products'
                ]),
            ...(this.isVerticalPaddingEnabled
              ? ['vertical_padding_between_products']
              : [])
          ].map(key => ({
            id: this.attributeName(key),
            label: this.$t(`form.basic_section.${key}.label`),
            ...this.basicGroupOptions[key]
          }))
        }
      ];
    },
    formSections() {
      return this.activeTab === BASIC_TAB
        ? this.basicSections
        : this.detailSections;
    },
    detailSections() {
      return [
        {
          groups: [
            {
              id: 'infobox',
              type: 'infobox',
              label: '',
              value: this.$t('form.infobox_html')
            }
          ]
        },
        {
          id: 'widget_title',
          label: this.$t('form.widget_title_section.label'),
          groups: [
            {
              id: this.attributeName('recommendation_unit_title_align_left'),
              label: this.$t(
                'form.widget_title_section.recommendation_unit_title_align_left.label'
              ),
              type: 'select_radio',
              options: [
                {
                  label: this.$t(
                    'form.widget_title_section.recommendation_unit_title_align_left.true'
                  ),
                  value: true
                },
                {
                  label: this.$t(
                    'form.widget_title_section.recommendation_unit_title_align_left.false'
                  ),
                  value: false
                }
              ]
            },
            {
              id: 'recommendation_unit_title',
              label: this.$t(
                'form.widget_title_section.recommendation_unit_title.label'
              ),
              fields: [
                {
                  id: this.attributeName('recommendation_unit_title_font_size'),
                  validate: ['positive_integer']
                }
              ]
            },
            {
              id: 'recommendation_unit_description',
              label: this.$t(
                'form.widget_title_section.recommendation_unit_description.label'
              ),
              fields: [
                {
                  id: this.attributeName(
                    'recommendation_unit_description_font_size'
                  ),
                  validate: ['positive_integer']
                }
              ]
            }
          ]
        },
        {
          id: 'product_information',
          label: this.$t('form.product_information_section.label'),
          groups: [
            ...this.showPositiveReviewKeywordsSection,
            {
              id: this.attributeName('display_product_advantage_info'),
              label: this.$t(
                'form.product_information_section.display_product_advantage_info.label'
              ),
              type: 'checkbox',
              disabled: this.isWidgetMainImageTypeEmpty,
              groupDescription: this.$t(
                'form.product_information_section.display_product_advantage_info.description_html',
                [this.advantageReviewScoreStandard]
              )
            },
            ...this.productInformationAlignSection,
            ...this.productInformationDisplayStyleSection,
            ...this.productInformationStyleSection
          ]
        },
        {
          id: 'product_image_section',
          label: this.$t('form.product_image_section.label'),
          groups: [
            ...(this.isReviewHighlightWidgetCampaign
              ? [
                  {
                    id: this.attributeName('widget_main_image_type'),
                    label: this.$t(
                      `form.product_image_section.${
                        this.isBannerStyle
                          ? 'review_image_type'
                          : 'widget_main_image_type'
                      }.label`
                    ),
                    type: 'select_radio',
                    options: this.isBannerStyle
                      ? TargetWidgetMainImageType.options([
                          TargetWidgetMainImageType.REVIEW,
                          TargetWidgetMainImageType.EMPTY
                        ])
                      : TargetWidgetMainImageType.options()
                  },
                  {
                    id: this.attributeName('thumbnail_video_autoplay'),
                    label: this.$t(
                      'form.product_image_section.thumbnail_video_autoplay.label'
                    ),
                    groupDescription: this.$t(
                      'form.product_image_section.thumbnail_video_autoplay.description'
                    ),
                    type: 'select_radio',
                    disabled: !this.isWidgetMainImageTypeReview,
                    labelDisabled: !this.isWidgetMainImageTypeReview,
                    options: [
                      {
                        label: this.$t(
                          'form.product_image_section.thumbnail_video_autoplay.false'
                        ),
                        value: false
                      },
                      {
                        label: this.$t(
                          'form.product_image_section.thumbnail_video_autoplay.true'
                        ),
                        value: true
                      }
                    ],
                    superAdmin: true
                  },
                  ...(this.isBannerStyle
                    ? [
                        {
                          id: this.attributeName('widget_image_ratio'),
                          label: this.$t(
                            'form.product_image_section.widget_image_ratio.label'
                          ),
                          groupDescription: this.$t(
                            'form.product_image_section.widget_image_ratio.description'
                          ),
                          required: true,
                          fields: [
                            {
                              id: this.attributeName(
                                'banner_product_image_ratio_x'
                              ),
                              validate: ['required', { rule: 'gt', allowed: 0 }]
                            },
                            {
                              id: this.attributeName(
                                'banner_product_image_ratio_y'
                              ),
                              validate: ['required', { rule: 'gt', allowed: 0 }]
                            },
                            {
                              id: this.attributeName(
                                'banner_review_image_ratio_x'
                              ),
                              validate: ['required', { rule: 'gt', allowed: 0 }]
                            },
                            {
                              id: this.attributeName(
                                'banner_review_image_ratio_y'
                              ),
                              validate: ['required', { rule: 'gt', allowed: 0 }]
                            }
                          ]
                        }
                      ]
                    : [
                        {
                          id: this.attributeName('widget_main_image_ratio'),
                          label: this.$t(
                            'form.product_image_section.widget_main_image_ratio.label'
                          ),
                          groupDescription: this.$t(
                            'form.product_image_section.widget_main_image_ratio.description'
                          ),
                          required: !this.isWidgetMainImageTypeEmpty,
                          disabled: this.isWidgetMainImageTypeEmpty,
                          labelDisabled: this.isWidgetMainImageTypeEmpty,
                          fields: [
                            {
                              id: this.attributeName(
                                'custom_product_image_ratio_x'
                              ),
                              validate: ['required', { rule: 'gt', allowed: 0 }]
                            },
                            {
                              id: this.attributeName(
                                'custom_product_image_ratio_y'
                              ),
                              validate: ['required', { rule: 'gt', allowed: 0 }]
                            }
                          ]
                        }
                      ])
                ]
              : []),
            {
              id: this.attributeName('image_border_radius'),
              label: this.$t(
                'form.product_image_section.image_border_radius.label'
              ),
              groupDescription: this.$t(
                'form.product_image_section.image_border_radius.description'
              ),
              type: 'number',
              digits: 2,
              validate: [
                { rule: 'required' },
                { rule: 'min_max_integer', min: 0, max: 10 }
              ]
            },
            {
              id: this.attributeName('display_image_border'),
              label: this.$t(
                'form.product_image_section.display_image_border.label'
              ),
              type: 'checkbox',
              groupDescription: this.$t(
                'form.product_image_section.display_image_border.description'
              )
            },
            {
              id: this.attributeName('use_original_product_image'),
              label: this.$t(
                'form.product_image_section.use_original_product_image.label'
              ),
              type: 'checkbox',
              groupDescription: this.$t(
                'form.product_image_section.use_original_product_image.description'
              )
            },
            ...(this.isReviewHighlightWidgetCampaign
              ? []
              : [
                  {
                    id: this.attributeName('use_original_product_image_ratio'),
                    label: this.$t(
                      'form.product_image_section.use_original_product_image_ratio.label'
                    ),
                    type: 'select_radio',
                    validate: !this.formObject[
                      this.attributeName('use_original_product_image_ratio')
                    ]
                      ? ['required']
                      : null,
                    options: [
                      {
                        label: this.$t(
                          'form.product_image_section.use_original_product_image_ratio.true.label'
                        ),
                        value: true
                      },
                      {
                        label: this.$t(
                          'form.product_image_section.use_original_product_image_ratio.false.label'
                        ),
                        value: false
                      }
                    ],
                    fields: [
                      {
                        id: this.attributeName('custom_product_image_ratio_x'),
                        validate: [
                          { rule: 'required' },
                          { rule: 'gt', allowed: 0 }
                        ]
                      },
                      {
                        id: this.attributeName('custom_product_image_ratio_y'),
                        validate: [
                          { rule: 'required' },
                          { rule: 'gt', allowed: 0 }
                        ]
                      }
                    ]
                  }
                ])
          ]
        },
        {
          id: 'page_link_button_section',
          label: this.$t('form.page_link_button_section.label'),
          groups: [
            {
              id: this.attributeName('show_page_link_button'),
              label: this.$t(
                'form.page_link_button_section.show_page_link_button.label'
              ),
              type: 'checkbox',
              fields: this.formObject[
                this.attributeName('show_page_link_button')
              ]
                ? [
                    {
                      id: this.attributeName('page_link_button_text'),
                      validate: [
                        'required',
                        { rule: 'keywords', keywords: this.keywords },
                        {
                          rule: v =>
                            !this.keywords.some(
                              k => k.disabled && v.includes(`%{${k.key}}`)
                            ),
                          errorMessage: this.$t('validations.disabled_keywords')
                        }
                      ]
                    },
                    {
                      id: this.attributeName('page_link_button_url'),
                      validate: ['required', 'url_format']
                    }
                  ]
                : []
            },
            {
              id: this.attributeName('page_link_button_style'),
              label: this.$t(
                'form.page_link_button_section.page_link_button_style.label'
              ),
              labelDisabled: !this.formObject[
                this.attributeName('show_page_link_button')
              ],
              fields: [
                {
                  id: this.attributeName('page_link_button_font_size'),
                  validate: ['positive_integer']
                }
              ]
            }
          ]
        },
        {
          id: 'widget_margin_section',
          groups: [
            ...this.widgetMarginSection,
            {
              id: this.attributeName('css'),
              label: this.$t('form.admin_section.css'),
              type: 'code_editor',
              lang: 'css',
              superAdmin: true
            }
          ]
        }
      ];
    },
    productInformationDisplayStyleSection() {
      if (
        this.formObject[
          this.attributeName('product_information_display_style')
        ] !== TargetWidgetProductInformationDisplayStyle.IMAGE_ONLY
      )
        return [
          {
            id: this.attributeName('product_name_overflow_ellipsis'),
            type: 'select_radio',
            label: this.$t(
              'form.product_information_section.product_name_overflow_ellipsis.label'
            ),
            options: [true, false].map(value => ({
              label: value
                ? this.$t(
                    `form.product_information_section.product_name_overflow_ellipsis.${value}`
                  )
                : this.isListStyle
                ? this.$t(
                    `form.product_information_section.product_name_overflow_ellipsis.${value}.two_lines`
                  )
                : this.$t(
                    `form.product_information_section.product_name_overflow_ellipsis.${value}.all`
                  ),
              value
            }))
          },
          ...(!this.isReviewHighlightWidgetCampaign
            ? [
                {
                  id: this.attributeName('display_original_price'),
                  type: 'checkbox',
                  label: this.$t(
                    'form.product_information_section.display_original_price.label'
                  ),
                  description: this.$t(
                    'form.product_information_section.display_original_price.description'
                  )
                }
              ]
            : []),
          ...(this.isReviewHighlightWidgetCampaign
            ? [
                {
                  id: this.attributeName('product_info_type'),
                  type: 'select_radio',
                  label: this.$t(
                    'form.product_information_section.product_info_type.label'
                  ),
                  options: TargetRecommendationProductInfoType.options()
                }
              ]
            : [])
        ];
      else return [];
    },
    productInformationAlignSection() {
      if (
        this.formObject[
          this.attributeName('product_information_display_style')
        ] === TargetWidgetProductInformationDisplayStyle.DISPLAY &&
        !this.isReviewHighlightWidgetCampaign
      )
        return [
          {
            id: this.attributeName('product_information_align_left'),
            label: this.$t(
              'form.product_information_section.product_information_align_left.label'
            ),
            type: 'select_radio',
            options: [true, false].map(value => ({
              label: this.$t(
                `form.product_information_section.product_information_align_left.${value}`
              ),
              value
            }))
          }
        ];
      else return [];
    },
    productInformationStyleSection() {
      if (
        this.formObject[
          this.attributeName('product_information_display_style')
        ] === TargetWidgetProductInformationDisplayStyle.DISPLAY
      )
        return [
          {
            id: `${this.isBannerStyle ? 'banner_' : ''}product_name_style`,
            label: this.$t(
              'form.product_information_section.product_name_style.label'
            ),
            fields: [
              {
                id: this.attributeName(
                  `${this.isBannerStyle ? 'banner_' : ''}product_name_font_size`
                ),
                validate: ['positive_integer']
              }
            ]
          },
          {
            id: `${
              this.isBannerStyle ? 'banner_' : ''
            }product_final_price_style`,
            label: this.$t(
              'form.product_information_section.product_final_price_style.label'
            ),
            labelDisabled: this.isDisabledByProductInfoType,
            fields: [
              {
                id: this.attributeName(
                  `${
                    this.isBannerStyle ? 'banner_' : ''
                  }product_final_price_font_size`
                ),
                validate: ['positive_integer']
              }
            ]
          },
          {
            id: `${
              this.isBannerStyle ? 'banner_' : ''
            }product_original_price_style`,
            label: this.$t(
              'form.product_information_section.product_original_price_style.label'
            ),
            labelDisabled:
              this.isDisabledByProductInfoType ||
              !this.formObject[this.attributeName('display_original_price')],
            fields: [
              {
                id: this.attributeName(
                  `${
                    this.isBannerStyle ? 'banner_' : ''
                  }product_original_price_font_size`
                ),
                validate: ['positive_integer']
              }
            ]
          },
          ...(this.isReviewHighlightWidgetCampaign
            ? [
                {
                  id: `${
                    this.isBannerStyle ? 'banner_' : ''
                  }review_message_style`,
                  label: this.$t(
                    'form.product_information_section.review_message_style.label'
                  ),
                  fields: [
                    {
                      id: this.attributeName(
                        `${
                          this.isBannerStyle ? 'banner_' : ''
                        }review_message_style_font_size`
                      ),
                      validate: ['positive_integer']
                    }
                  ]
                }
              ]
            : [])
        ];
      else return [];
    },
    showPositiveReviewKeywordsSection() {
      if (
        this.formObject[
          this.attributeName('product_information_display_style')
        ] !== TargetWidgetProductInformationDisplayStyle.DISPLAY ||
        (this.isReviewHighlightWidgetCampaign && !this.isBannerStyle)
      )
        return [];
      else
        return [
          {
            id: this.attributeName('positive_review_keyword_display_type'),
            label: this.$t(
              'form.product_information_section.positive_review_keyword_display_type.label'
            ),
            groupDescription: this.$t(
              'form.product_information_section.positive_review_keyword_display_type.description'
            ),
            type: 'select_radio',
            options: TargetPositiveReviewKeywordDisplayType.options()
          },
          ...(this.formObject[
            this.attributeName('positive_review_keyword_display_type')
          ] === TargetPositiveReviewKeywordDisplayType.KEYWORD_ONLY
            ? [
                {
                  id: this.attributeName(
                    'maximum_positive_review_keywords_count'
                  ),
                  label: this.$t(
                    'form.product_information_section.maximum_positive_review_keywords_count.label'
                  ),
                  type: 'number',
                  digits: 3,
                  validate: ['required', 'positive_integer']
                },
                {
                  id: this.attributeName('positive_review_keywords_title'),
                  label: this.$t(
                    'form.product_information_section.positive_review_keywords_title.label'
                  ),
                  type: 'text',
                  validate: ['required'],
                  disabled: true
                }
              ]
            : [
                {
                  id: this.attributeName(
                    'maximum_positive_review_keywords_with_score_count'
                  ),
                  label: this.$t(
                    'form.product_information_section.maximum_positive_review_keywords_with_score_count.label'
                  ),
                  type: 'number',
                  digits: 3,
                  validate: ['required', 'positive_integer'],
                  labelDisabled:
                    this.formObject[
                      this.attributeName('positive_review_keyword_display_type')
                    ] !== TargetPositiveReviewKeywordDisplayType.WITH_SCORE,
                  disabled:
                    this.formObject[
                      this.attributeName('positive_review_keyword_display_type')
                    ] !== TargetPositiveReviewKeywordDisplayType.WITH_SCORE
                },
                {
                  id: this.attributeName(
                    'positive_review_keywords_with_score_title'
                  ),
                  label: this.$t(
                    'form.product_information_section.positive_review_keywords_with_score_title.label'
                  ),
                  type: 'text',
                  validate: ['required'],
                  labelDisabled:
                    this.formObject[
                      this.attributeName('positive_review_keyword_display_type')
                    ] !== TargetPositiveReviewKeywordDisplayType.WITH_SCORE,
                  disabled: true
                }
              ])
        ];
    },
    horizontalStyleGroupIds() {
      if (this.isCartPopupWidgetCampaign && this.isHorizontalScrollStyle)
        return [
          'horizontal_scroll_multiple_rows',
          'horizontal_scroll_enable_autoscroll'
        ];
      else if (
        (this.isHorizontalScrollStyle || this.isBannerStyle) &&
        this.isPc
      )
        return ['horizontal_scroll_button_style'];
      else if (this.isHorizontalScrollStyle)
        return ['horizontal_scroll_multiple_rows'];
      else return [];
    },
    widgetMarginSection() {
      return [
        {
          id: 'widget_margin',
          label: this.$t('form.admin_section.widget_margin.label'),
          superAdmin: true,
          groupDescription: this.$t(
            'form.admin_section.widget_margin.description'
          ),
          fields: [
            {
              id: this.attributeName('widget_margin_top')
            },
            {
              id: this.attributeName('widget_margin_right')
            },
            {
              id: this.attributeName('widget_margin_bottom')
            },
            {
              id: this.attributeName('widget_margin_left')
            }
          ]
        }
      ];
    },
    keywords() {
      const basicKeywords = ['reviews_count'];
      const disabled = !BrandReviewStatus.isOperating(
        this.currentBrand.review_status
      );

      return basicKeywords.map(key => ({
        key,
        desc: this.$t(`keywords.${key}`),
        value: '{{{key}}}',
        tooltip: this.keyword_tooltip,
        disabled: disabled
      }));
    },
    keyword_tooltip() {
      if (BrandReviewStatus.isPreparing(this.currentBrand.review_status))
        return this.$t('keywords.tooltip.preparing');
      else if (BrandReviewStatus.isOperating(this.currentBrand.review_status))
        return '';
      else return this.$t('keywords.tooltip.not_using');
    },
    isDisabledByProductInfoType() {
      return (
        this.isReviewHighlightWidgetCampaign &&
        this.formObject[this.attributeName('product_info_type')] !==
          TargetRecommendationProductInfoType.PRICE
      );
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaignId}/widget_design`)
      .then(({ data }) => {
        this.orgFormObject = data.widget_design;
        this.defaultObject = data.default_widget_design;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    updatePreview() {
      if (this.hasError) return;

      const version = this.currentVersion;
      api
        .patch(
          `/target/campaigns/${this.campaignId}/widget_design/preview`,
          { widget_design_attributes: this.formObject },
          { requestId: 'UPDATE_TARGET_WIDGET_DESIGN_PREVIEW' }
        )
        .then(() => (this.displayVersion = version));
    },
    submit() {
      this.isSubmitting = true;
      api
        .patch(
          `/target/campaigns/${this.campaignId}/widget_design`,
          { widget_design_attributes: this.formObject },
          {
            successMessage: this.$t('app.saved')
          }
        )
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    attributeName(name) {
      return `${this.deviceType}_${name}`;
    },
    updateFontField({ id, value }) {
      this.validateField(id);
      this.formObject[id] = value;
    },
    updateImageRatioField({ id, value }) {
      this.validateField(id);
      this.formObject[id] = value;
    },
    validateTabFields() {
      if (this.activeTab === BASIC_TAB) return;
      this.validateField(this.attributeName('page_link_button_text'));
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignSettingsWidgetDesignDialog__pc-preview {
  min-width: 574px;
  height: 100%;
}

.TargetCampaignSettingsWidgetDesignDialog__input-section {
  & + & {
    margin-top: 12px;
  }
}

.TargetCampaignSettingsWidgetDesignDialog__advanced-input {
  display: inline-block;
  vertical-align: top;
  & + & {
    margin-left: 24px;
  }
}

.TargetCampaignSettingsWidgetDesignDialog__label {
  display: block;
  margin-bottom: 4px;
}
</style>

<i18n locale="ko">
{
  "pc_title": "PC 위젯 디자인 설정",
  "mobile_title": "모바일 위젯 디자인 설정",
  "sub_title": "상품 상세 페이지 추천",
  "tabs": {
    "basic": "기본 설정",
    "detail": "상세 설정"
  },
  "form": {
    "infobox_html": "디자인 상세 설정 값을 조정하여 쇼핑몰 컨셉에 맞는<br />상품 추천 위젯을 완성할 수 있습니다.<br /><br />해당 설정을 잘 모르신다면 기본 설정 사용을 추천합니다.",
    "widget_title_section": {
      "label": "위젯 타이틀 설정",
      "recommendation_unit_title_align_left": {
        "label": "추천 항목 이름 정렬",
        "true": "왼쪽 정렬",
        "false": "가운데 정렬"
      },
      "recommendation_unit_title": {
        "label": "추천 항목 이름 스타일"
      },
      "recommendation_unit_description": {
        "label": "안내 문구 스타일"
      }
    },
    "product_information_section": {
      "label": "상품 정보 설정",
      "positive_review_keyword_display_type": {
        "label": "긍정 리뷰 키워드 표시",
        "description": "상품 리뷰를 분석하여 긍정적인 평가를 받은 키워드들을 위젯에 표시합니다."
      },
      "maximum_positive_review_keywords_count": {
        "label": "긍정 리뷰 키워드 노출 개수"
      },
      "positive_review_keywords_title": {
        "label": "긍정 리뷰 키워드 타이틀"
      },
      "maximum_positive_review_keywords_with_score_count": {
        "label": "긍정 리뷰 키워드 노출 개수"
      },
      "positive_review_keywords_with_score_title": {
        "label": "긍정 리뷰 키워드 타이틀"
      },
      "display_product_advantage_info": {
        "label": "상품 추천 시 유용한 정보 표시",
        "description_html": "상품의 유용한 정보 1개를 이미지 위에 선택적으로 표시합니다.<br />1)평점 {0}점 이상 2)리뷰 수 100개 이상일 때 유용한 정보로 판단하며, 우선순위는 1,2 순서입니다."
      },
      "product_information_align_left": {
        "label": "상품 정보 정렬",
        "true": "왼쪽 정렬",
        "false": "가운데 정렬"
      },
      "product_name_overflow_ellipsis": {
        "label": "상품명 표시",
        "true": "한 줄로 표시",
        "false": {
          "all": "전체 표시",
          "two_lines": "두 줄로 표시"
        }
      },
      "product_info_type": {
        "label": "상품 정보 표시"
      },
      "display_original_price": {
        "label": "상품 할인 전 가격 표시",
        "description": "상품 할인 전 가격을 현재 판매 가격과 함께 표시합니다."
      },
      "product_name_style": {
        "label": "상품명 스타일"
      },
      "product_final_price_style": {
        "label": "가격 스타일"
      },
      "product_original_price_style": {
        "label": "할인 전 가격 스타일"
      },
      "review_message_style": {
        "label": "리뷰 본문 스타일"
      }
    },
    "product_image_section": {
      "label": "이미지 설정",
      "widget_main_image_type": {
        "label": "위젯 메인 이미지 선택",
        "review": {
          "hint": "표시할 포토/동영상 리뷰가 없는 경우 상품 이미지를 대체 표시합니다."
        }
      },
      "review_image_type": {
        "label": "리뷰 이미지 표시"
      },
      "thumbnail_video_autoplay": {
        "label": "동영상 리뷰 썸네일 표시 방법",
        "description": "동영상 리뷰 노출 시 썸네일에서",
        "false": "동영상 멈춤 상태에서 재생 마크 노출",
        "true": "동영상 자동 재생"
      },
      "widget_main_image_ratio": {
        "label": "위젯 메인 이미지 비율",
        "description": "설정한 비율에 맞춰 위젯 메인 이미지를 크롭합니다.<br>가로 : 세로"
      },
      "widget_image_ratio": {
        "label": "이미지 비율",
        "description": "설정한 비율에 맞춰 상품 및 리뷰 이미지를 크롭합니다.",
        "hint": "가로 : 세로",
        "product_image": "상품 이미지",
        "review_image": "리뷰 이미지"
      },
      "image_border_radius": {
        "label": "이미지 모서리 둥글기(px)",
        "description": "위젯 내 표시되는 모든 이미지에 적용합니다."
      },
      "display_image_border": {
        "label": "이미지 테두리 선 표시",
        "description": "위젯 내 표시되는 모든 이미지 테두리에 회색 선을 표시합니다."
      },
      "use_original_product_image": {
        "label": "쇼핑몰 원본 이미지 사용",
        "description": "크리마 서버에 저장된 상품 이미지 대신 쇼핑몰에서 사용하는 원본 상품 이미지를 보여줍니다. 원본 이미지 사용 시 GIF 이미지 표시가 가능합니다."
      },
      "use_original_product_image_ratio": {
        "label": "상품 이미지 비율",
        "true": {
          "label": "쇼핑몰에서 등록한 오리지널 비율",
          "hint": "각 상품에 등록된 이미지 비율이 다르다면 위젯에 표시될 때 정렬이 어긋날 수 있습니다."
        },
        "false": {
          "label": "비율 직접 설정",
          "hint": "가로 : 세로"
        }
      }
    },
    "page_link_button_section": {
      "label": "페이지 링크 버튼 설정",
      "show_page_link_button": {
        "label": "페이지 링크 버튼 표시",
        "description": "원하는 페이지로 이동할 수 있는 페이지 링크 버튼을 위젯에 표시합니다. 추천 항목이 2개 이상인 경우, 버튼은 위젯 최하단에 표시됩니다.",
        "page_link_button_text": {
          "label": "버튼 문구",
          "placeholder": "버튼 문구를 입력해주세요.",
          "hint": "예약어 활용 예시: %{reviews_count}개의 후기 분석 결과 보러가기"
        },
        "page_link_button_url": {
          "label": "버튼 URL",
          "placeholder": "버튼 클릭 시 이동할 페이지의 URL을 입력해주세요."
        }
      },
      "page_link_button_style": {
        "label": "페이지 링크 버튼 스타일"
      }
    },
    "basic_section": {
      "widget_style": {
        "label": "위젯 스타일"
      },
      "show_horizontal_scroll": {
        "label": "상품 표시 방법",
        "true": {
          "label": "가로 스크롤",
          "description": "한 페이지에 보여줄 상품 수"
        },
        "false": {
          "label": "모두 보여주기"
        }
      },
      "product_information_display_style": {
        "label": "상품 정보 표시 스타일"
      },
      "horizontal_scroll_button_style": {
        "label": "슬라이드 버튼 위치"
      },
      "products_count_per_row": {
        "label": "한 줄에 표시할 상품 수"
      },
      "reviews_count_per_product": {
        "label": "상품에 표시할 리뷰 수"
      },
      "horizontal_padding_between_products": {
        "label": "상품 이미지 간 좌우 간격(px)"
      },
      "vertical_padding_between_products": {
        "label": "상품 이미지 간 상하 간격(px)"
      },
      "vertical_padding_between_reviews": {
        "label": "리뷰 이미지 간 상하 간격(px)"
      },
      "horizontal_scroll_multiple_rows": {
        "label": "가로 스크롤 줄 개수"
      },
      "horizontal_scroll_enable_autoscroll": {
        "label": "스크롤 동작 방식",
        "true": "자동 스크롤",
        "false": "수동 스크롤"
      },
      "horizontal_scroll_autoscroll_delay": {
        "label": "자동 스크롤 속도 {0} 초"
      }
    },
    "admin_section": {
      "css": "이 위젯에만 적용할 CSS",
      "widget_margin": {
        "label": "이 위젯에만 적용할 여백 값",
        "description": "위젯은 최상단의 추천 항목 이름부터 최하단의 로고까지를 뜻합니다.",
        "top": "상단 여백(px)",
        "right": "오른쪽 여백(px)",
        "bottom": "하단 여백(px)",
        "left": "왼쪽 여백(px)"
      }
    }
  },
  "keywords": {
    "reviews_count": "쇼핑몰 리뷰수(모션)",
    "tooltip": {
      "preparing": "CREMA Review 서비스 결제 및 테스트 중 상태입니다.<br>해당 항목은 쇼핑몰에 CREMA Review 서비스가<br>라이브된 이후 정상적으로 사용 가능합니다.",
      "not_using": "CREMA Review 서비스 사용이 필요합니다."
    }
  }
}
</i18n>
