import { constructEnum } from '@/lib/enum';

export default constructEnum('NineWayDirection', {
  TOP_LEFT: 11,
  TOP_CENTER: 12,
  TOP_RIGHT: 13,
  MIDDLE_LEFT: 21,
  MIDDLE_CENTER: 22,
  MIDDLE_RIGHT: 23,
  BOTTOM_LEFT: 31,
  BOTTOM_CENTER: 32,
  BOTTOM_RIGHT: 33
});
