var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"AppFileInput"},[_c('input',_vm._b({ref:"fileInput",staticClass:"AppFileInput__org-input",attrs:{"type":"file"},on:{"change":_vm.changeFile,"blur":function($event){return _vm.$emit('blur')}}},'input',{
      id: _vm.id,
      name: _vm.name,
      accept: _vm.accept,
      disabled: _vm.disabled,
      invalid: _vm.invalid
    },false)),_c('div',{class:[
      'AppFileInput__input',
      { 'AppFileInput__input--invalid': _vm.invalid }
    ]},[(_vm.value)?_c('span',[_vm._v(_vm._s(_vm.value))]):(_vm.disabled || _vm.placeholder)?_c('span',[_vm._v(_vm._s(_vm.placeholder))]):_vm._e(),_c('div',{staticClass:"AppFileInput__icon"})])])}
var staticRenderFns = []

export { render, staticRenderFns }