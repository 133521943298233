<template>
  <AppModalForm
    :form-props="formProps"
    :title="$t('title')"
    :sub-title="$t('request_page.review_board')"
    v-on="formEvents"
  >
    <template #group="{id}">
      <template v-if="id === 'page'">
        <div>{{ $t('request_page.review_board') }}</div>
      </template>
      <template v-else-if="id === 'placement'">
        <div class="AppForm__group-description">
          <AppInlineButton
            button-style="caption"
            :label="$t('placement.group_description')"
            @click="openPlacementInfoDialog"
          />
        </div>
        <div class="AppForm__group-field">
          <AppSelectRadio
            v-model="formObject.placement_value"
            :options="placementOptions"
          />
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapActions } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import InstallationPresetPlacementType from '@/enums/InstallationPresetPlacementType';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'WidgetInstallRequestChangePlacementFormDialog',
  mixins: [DialogFormView],
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'page',
              label: this.$t('request_page.label')
            },
            {
              id: 'placement',
              label: this.$t('placement.label')
            }
          ]
        }
      ];
    },
    placementOptions() {
      return InstallationPresetPlacementType.options([
        InstallationPresetPlacementType.REPLACE_PAGE,
        InstallationPresetPlacementType.REPLACE_PAGE_EXCEPT_TITLE,
        InstallationPresetPlacementType.REPLACE_PAGE_EXCEPT_TITLE_AND_IMAGE
      ]);
    }
  },
  mounted() {
    this.orgFormObject = {
      placement_value: InstallationPresetPlacementType.REPLACE_PAGE
    };
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'confirm']),
    submit() {
      this.orgFormObject = { ...this.formObject };
      this.close();
    },
    openPlacementInfoDialog() {
      this.confirm({
        title: this.$t('placement.info_modal_title'),
        message:
          '<center><img src="https://assets.cre.ma/m/review_board_location_info@2x.png"/></center>',
        width: DialogSize.WIDE
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "설치 위치 변경",
  "request_page": {
    "label": "설치 요청 페이지",
    "review_board": "전체 리뷰 페이지"
  },
  "placement": {
    "label": "설치 위치",
    "group_description": "설치 위치 자세히보기",
    "info_modal_title": "전체 리뷰 페이지 위젯 설치위치 안내"
  }
}
</i18n>
