<template>
  <div class="TargetReportsCampaignTotalReportsTable">
    <AppHeading :label="$t('title')" />
    <AppSmallDeviceScrollable>
      <AppTable :rows="rows" :columns="columns">
        <template #cell="{ row, column }">
          <template v-if="column.id === 'total_report_type'">
            <div>
              {{ TargetTotalReportType.t(row.totalReportTypeKey) }}
            </div>
          </template>
          <template v-else-if="column.id === 'kpi'">
            <div v-if="row.kpi !== null">
              {{ $t(`kpi.${row.totalReportTypeKey}`) }}:
              <AppNumber
                :value="row.kpi * 100"
                :precision="1"
                unit="percentage"
              />
            </div>
            <div v-else>-</div>
          </template>
          <template v-else-if="column.id === 'effect'">
            <div v-if="hasEffect(row.total_report_type)">
              {{ $t(`effect.${row.totalReportTypeKey}`) }}:
              <AppNumber
                :value="row.effect"
                :unit="effectUnit(row.total_report_type)"
              />
            </div>
            <div v-else>-</div>
          </template>
          <template v-else-if="column.id === 'actions'">
            <AppButton
              v-if="row.total_report_type !== TargetTotalReportType.TOTAL"
              :label="$t('actions.detail_button')"
              :disabled="row.sent_users_count === 0"
              @click="clickDetailButton(row)"
            />
          </template>
        </template>
      </AppTable>
    </AppSmallDeviceScrollable>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TargetTotalReportType from '@/enums/TargetTotalReportType';

export default {
  name: 'TargetReportsCampaignTotalReportsTable',
  props: {
    dateRange: { type: Object, required: true },
    campaignDispatchType: { type: Number, default: null },
    totalReports: { type: Array, required: true }
  },
  computed: {
    TargetTotalReportType: () => TargetTotalReportType,
    columns() {
      return [
        { id: 'total_report_type' },
        { id: 'sent_users_count', type: 'number' },
        { id: 'kpi' },
        { id: 'effect' },
        { id: 'actions', label: this.$t('app.actions') }
      ].map(column => ({
        ...column,
        label: column.label || this.$t(`table.${column.id}`)
      }));
    },
    rows() {
      return this.totalReports.map(report => ({
        ...report,
        totalReportTypeKey: TargetTotalReportType.key(report.total_report_type)
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    clickDetailButton(report) {
      this.openDialog([
        'TargetReportsTotalReportsDetailDialog',
        {
          totalReportType: report.total_report_type,
          dateRange: this.dateRange,
          campaignDispatchType: this.campaignDispatchType
        }
      ]);
    },
    hasEffect(total_report_type) {
      return ![
        TargetTotalReportType.MASS,
        TargetTotalReportType.TOTAL
      ].includes(total_report_type);
    },
    effectUnit(total_report_type) {
      switch (total_report_type) {
        case TargetTotalReportType.PURCHASE_PROMOTION:
        case TargetTotalReportType.RECOMMENDATION:
          return 'currency';
        case TargetTotalReportType.CHURNING_PREVENTION:
          return 'people';
        case TargetTotalReportType.USER_MANAGEMENT:
          return 'people';
        case TargetTotalReportType.REVIEW:
          return 'count';
        default:
          return null;
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "캠페인 목적별 통계",
  "table": {
    "total_report_type": "발송 목적",
    "sent_users_count": "발송 인원수",
    "kpi": "KPI",
    "effect": "효과"
  },
  "kpi": {
    "PURCHASE_PROMOTION": "구매 전환율",
    "CHURNING_PREVENTION": "이탈 방지율",
    "USER_MANAGEMENT": "회원 가입율",
    "REVIEW": "리뷰 작성율",
    "RECOMMENDATION": "구매 전환율"
  },
  "effect": {
    "PURCHASE_PROMOTION": "구매",
    "CHURNING_PREVENTION": "재방문",
    "USER_MANAGEMENT": "가입",
    "REVIEW": "리뷰",
    "RECOMMENDATION": "구매"
  },
  "actions": {
    "detail_button": "상세 내역"
  }
}
</i18n>
