<template>
  <div class="TheSplash">
    <div class="TheSplash__content">
      <div class="TheSplash__spinner-wrapper"><AppSpinner /></div>
      <div>Loading ...</div>
    </div>
    <div class="TheSplash__height-support" />
  </div>
</template>

<script>
export default {
  name: 'TheSplash'
};
</script>

<style lang="scss" scoped>
.TheSplash {
  text-align: center;
}

.TheSplash__content {
  position: relative;
  top: -20px;
  display: inline-block;
  vertical-align: middle;
}

.TheSplash__spinner-wrapper {
  font-size: 30px;
  margin-bottom: 4px;
}

.TheSplash__height-support {
  vertical-align: middle;
  display: inline-block;
  height: 100vh;
}
</style>
