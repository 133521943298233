<template>
  <AppAjaxContent :is-loading="isLoading" class="ReplaceReviewsCount">
    <AppRadioLabel
      class="ReplaceReviewsCount__radio_label"
      :label="$t('review_count_replacement.title')"
      :tooltip="$t('review_count_replacement.tooltip')"
      :checked="false"
    />
    <AppSearchbar>
      <AppButton
        button-style="grey-outline"
        :label="$t('smart_installation.create')"
        @click="newReviewsCountReplacementClicked"
      />
    </AppSearchbar>
    <AppTable
      table-id="review-widget-installations-etc-table"
      :columns="COLUMNS"
      :rows="rows"
      :event-bus="tableEventBus"
    >
      <template #cell="{ column, rowIndex }">
        <div v-if="column.id === 'reviews_count_replacement_feature'">
          <AppButton
            :label="$t('app.settings')"
            @click="reviewsCountReplacementSettingClicked(rowIndex)"
          />
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            @click="deleteReviewsCountReplacementClicked(rowIndex)"
          />
        </div>
      </template>
    </AppTable>
    <div class="ReplaceReviewsCount__install_link_caption">
      <i18n path="review_count_replacement.caption.description">
        <AppInternalLink
          class="ReplaceReviewsCount__install_link"
          :to="widgetInstallLink"
        >
          {{ $t('review_count_replacement.caption.page_name') }}
        </AppInternalLink>
      </i18n>
    </div>
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import api from '@/lib/api';
import { mapState, mapActions } from 'vuex';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import SmartInstallationHelper from '@/mixins/SmartInstallationHelper';
import SmartInstallationType from '@/enums/SmartInstallationType';

export default {
  name: 'ReplaceReviewsCount',
  mixins: [SmartInstallationHelper],
  data() {
    return {
      reviewsCountReplacements: [],
      widgetInstallLink: '',
      isLoading: true,
      tableEventBus: null,
      COLUMNS: [
        {
          id: 'installation_id',
          label: this.$t('table_head.reviews_count_replacement_installation_id')
        },
        {
          id: 'device_type',
          label: this.$t('table_head.reviews_count_replacement_device_type')
        },
        {
          id: 'page_type',
          label: this.$t('table_head.reviews_count_replacement_page_type')
        },
        {
          id: 'element_selector',
          label: this.$t(
            'table_head.reviews_count_replacement_element_selector'
          )
        },
        {
          id: 'skin_id',
          label: this.$t('table_head.reviews_count_replacement_skin_id')
        },
        {
          id: 'reviews_count_display_format',
          label: this.$t(
            'table_head.reviews_count_replacement_reviews_count_display_format'
          )
        },
        {
          id: 'reviews_count_replacement_feature',
          label: this.$t('table_head.reviews_count_replacement_feature')
        }
      ]
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    rows() {
      return this.reviewsCountReplacements.map(replacement => ({
        installation_id: replacement.id,
        device_type: WidgetDeviceType.t(replacement.device_type),
        page_type: this.getPageTypeOrPath(replacement),
        element_selector: `${replacement.element_selector}`,
        skin_id: this.formatSkinId(replacement.skin_id),
        reviews_count_display_format: `${replacement.reviews_count_display_format}`
      }));
    }
  },
  created() {
    this.tableEventBus = new Vue();
  },
  mounted() {
    this.widgetInstallLink = `/review/settings/widgets/etc?brand_id=${this.currentBrand.id}`;
    api
      .get('/smart_installations', {
        params: {
          installation_type: SmartInstallationType.REPLACE_REVIEWS_COUNT
        }
      })
      .then(
        ({ data }) => (this.reviewsCountReplacements = data.smart_installations)
      )
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    newReviewsCountReplacementClicked() {
      this.openDialog([
        'ReviewSmartInstallationReviewsCountReplacementFormDialog'
      ]).then(eventBus =>
        eventBus.$on('save', reviewsCountReplacement => {
          this.reviewsCountReplacements.push(reviewsCountReplacement);
          this.$nextTick(() => {
            this.tableEventBus.$emit(
              'focus-row',
              this.reviewsCountReplacements.length - 1
            );
          });
        })
      );
    },
    reviewsCountReplacementSettingClicked(index) {
      const replacement = this.reviewsCountReplacements[index];
      this.openDialog([
        'ReviewSmartInstallationReviewsCountReplacementFormDialog',
        { replacement: replacement }
      ]).then(eventBus =>
        eventBus.$on('update', reviewsCountReplacement => {
          Vue.set(
            this.reviewsCountReplacements,
            index,
            reviewsCountReplacement
          );
        })
      );
    },
    deleteReviewsCountReplacementClicked(index) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      api
        .delete(
          `/smart_installations/${this.reviewsCountReplacements[index].id}`,
          {
            successMessage: this.$t('app.deleted')
          }
        )
        .then(() => {
          Vue.delete(this.reviewsCountReplacements, index);
        });
    },
    formatSkinId(skin_id) {
      return skin_id || this.$t('smart_installations.all_skin');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppRadioLabel__icon {
  color: $color-grey-50;
}

.ReplaceReviewsCount__radio_label {
  font-weight: 600;
  margin-left: -8px;
  margin-bottom: 4px;
}

.ReplaceReviewsCount__install_link_caption {
  @include text-caption-dark;
}

.ReplaceReviewsCount__install_link {
  text-decoration: underline;

  &:hover {
    @include text-caption-darker;
  }
}

::v-deep {
  .review-widget-installations-etc-table__installation-id,
  .review-widget-installations-etc-table__device-type,
  .review-widget-installations-etc-table__page-type,
  .review-widget-installations-etc-table__element-selector,
  .review-widget-installations-etc-table__skin-id,
  .review-widget-installations-etc-table__reviews-count-display-format,
  .review-widget-installations-etc-table__reviews-count-replacement-feature {
    min-width: 132px;
  }
  .review-widget-installations-etc-table {
    table-layout: fixed;
    margin-top: 12px;
  }
}
</style>

<i18n locale="ko">
{
  "table_head": {
    "reviews_count_replacement_installation_id": "설치 ID",
    "reviews_count_replacement_device_type": "디바이스",
    "reviews_count_replacement_page_type": "설치 페이지",
    "reviews_count_replacement_element_selector": "설치 위치",
    "reviews_count_replacement_skin_id" : "적용 스킨",
    "reviews_count_replacement_reviews_count_display_format": "표시 정보",
    "reviews_count_replacement_feature": "기능"
  },
  "smart_installation": {
    "create": "+ 간편 설치 추가"
  },
  "review_count_replacement": {
    "tooltip": "쇼핑몰 상품 리뷰 수 노출 영역에 크리마 리뷰 수가 노출되도록 설치할 수 있습니다.",
    "title": "상품 리뷰 수 교체 설치",
    "caption": {
      "description": "리뷰 수 위젯 추가 및 설치는 {0} 메뉴에서 진행할 수 있습니다.",
      "page_name": "[리뷰 / 리뷰 설정 / 위젯 설정 / 기타 리뷰 위젯]"
    }
  }
}
</i18n>
