import axios from 'axios';

// http://1004lucifer.blogspot.com/2019/04/axios-file-download-with-vuejs.html

function getFilename(contentDisposition) {
  if (contentDisposition) {
    const tokens = contentDisposition.split(';');
    for (const token of tokens) {
      const hit = token.match(/filename\*=UTF-8''(.*)$/);
      if (hit) return decodeURI(hit[1]);
    }

    for (const token of tokens) {
      const hit = token.match(/filename="(.*)"$/);
      if (hit) return hit[1];
    }
  }

  return null;
}

export default function(url, filename) {
  return axios
    .request({ url, method: 'get', responseType: 'arraybuffer' })
    .then(res => {
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download =
        filename ||
        getFilename(res.headers['content-disposition']) ||
        'download';

      link.click();
      link.remove();
    });
}
