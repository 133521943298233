<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{ ...formProps, objectId: 'corner' }"
    :is-loading="isLoading"
    v-on="formEvents"
  >
    <template #group="{ id }">
      <template v-if="id === 'duplication_check_priority'">
        <AppButton
          :label="$t('form.button.add_corner')"
          :disabled="addableCorners.length === 0"
          @click="addDeduplicatableCorner"
        />
        <AppTable
          class="AppForm__group-field AppForm__group-field--mt12"
          :columns="columns"
          :rows="formObject.deduplicatableCorners"
          :no-data="$t('form.table.no_data')"
        >
          <template #cell="{ column, row, rowIndex }">
            <template v-if="column.id === 'duplication_check_priority'">
              <AppNumber :value="rowIndex + 1" />
            </template>
            <template v-else-if="column.id === 'corner'">
              <AppSelect
                v-model="row.id"
                class="DisplayCornerDuplicationCheckPriorityFormDialog__select"
                :options="cornerOptions(row.id)"
                :disabled="isDeleted(row)"
              />
            </template>
            <template v-else-if="column.id === 'actions'">
              <input type="hidden" name="corners[][id]" :value="row.id" />
              <input
                type="hidden"
                name="corners[][duplication_check_priority]"
                :value="rowIndex"
              />
              <AppButtonDraggable
                v-if="!isDeleted(row)"
                :label="$t('app.sort')"
                @sort="sortDeduplicatableCorners"
              />
              <AppButton
                :label="$t('app.delete')"
                @click="removeDeduplicatableCorner(row.id)"
              />
            </template>
          </template>
        </AppTable>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { arrayMoveIndex } from '@/lib/array';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import DisplayCornerStatus from '@/enums/DisplayCornerStatus';

export default {
  name: 'DisplayCornerDuplicationCheckPriorityFormDialog',
  mixins: [DialogFormView],
  data() {
    return { isLoading: true, corners: [] };
  },
  computed: {
    addableCorners() {
      const deduplicatableCornerIds = this.formObject.deduplicatableCorners.map(
        ({ id }) => id
      );
      return this.corners.filter(
        corner =>
          !deduplicatableCornerIds.includes(corner.id) &&
          !this.isDeleted(corner)
      );
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'description',
              label: '',
              type: 'infobox',
              value: this.$t('form.description_html')
            },
            { id: 'duplication_check_priority', label: '' }
          ]
        }
      ];
    },
    columns() {
      return [
        {
          id: 'duplication_check_priority',
          label: this.$t('form.table.duplication_check_priority'),
          width: '72px'
        },
        { id: 'corner', label: this.$t('form.table.corner') },
        { id: 'actions', label: this.$t('app.actions'), width: '140px' }
      ];
    }
  },
  mounted() {
    api
      .get('display/corners/duplication_check_priorities')
      .then(({ data }) => {
        this.corners = data.corners;
        this.orgFormObject = {
          deduplicatableCorners: data.deduplicatable_corners
        };
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    addDeduplicatableCorner() {
      this.formObject.deduplicatableCorners.push({ ...this.addableCorners[0] });
    },
    removeDeduplicatableCorner(id) {
      const { formObject } = this;
      formObject.deduplicatableCorners = formObject.deduplicatableCorners.filter(
        corner => corner.id !== id
      );
    },
    sortDeduplicatableCorners({ srcIndex, dstIndex }) {
      const { formObject } = this;
      formObject.deduplicatableCorners = arrayMoveIndex(
        formObject.deduplicatableCorners,
        srcIndex,
        dstIndex
      );
    },
    isDeleted(corner) {
      return DisplayCornerStatus.isDeleted(corner.corner_status);
    },
    cornerOptions(id) {
      const selectableCornerIds = [
        ...this.addableCorners.map(corner => corner.id),
        id
      ];
      const selectableCorners = this.corners.filter(corner =>
        selectableCornerIds.includes(corner.id)
      );
      return selectableCorners.map(corner => ({
        value: corner.id,
        label: corner.name + (this.isDeleted(corner) ? this.$t('deleted') : '')
      }));
    },
    submit(formData) {
      api
        .patch(
          'display/corners/update_duplication_check_priorities',
          formData,
          {
            successMessage: this.$t('app.saved')
          }
        )
        .then(() => this.close(true));
    }
  }
};
</script>

<style lang="css">
.DisplayCornerDuplicationCheckPriorityFormDialog__select {
  width: 388px;
}
</style>

<i18n locale="ko">
{
  "title": "진열 위치 간 중복 방지",
  "form": {
    "description_html": "메인페이지 진열 위치 간 상품 중복 진열을 방지합니다.<br />중복 상품이 있다면 우선순위 높은 진열 위치에만 노출합니다.",
    "button": {
      "add_corner": "+ 진열 위치 추가"
    },
    "table": {
      "duplication_check_priority": "우선순위",
      "corner": "진열 위치",
      "no_data": "상품 중복 진열 방지 기능을 사용할 진열 위치를 추가해주세요."
    }
  },
  "deleted": " (삭제됨)"
}
</i18n>
