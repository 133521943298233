<template>
  <AppForm
    class="BatchJobForm"
    :sections="formSections"
    :is-submitting="isSubmitting"
    @submit="submit"
  >
    <input
      type="hidden"
      name="batch_job[batch_job_type]"
      :value="batchJobType"
    />
    <template #group="{ id }">
      <div v-if="id === 'upload_description'" class="AppForm__group-field">
        <p>&nbsp;</p>
        <pre v-if="description">{{ description }}</pre>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="descriptionHtml"
          class="BatchJobForm__description--html"
          v-html="descriptionHtml"
        />
        <!-- eslint-enable vue/no-v-html -->
        <ul class="BatchJobForm__buttons">
          <li>
            <AppButtonDownload
              v-if="sampleFileUrl"
              :url="sampleFileUrl"
              :label="downloadLabel || $t('local.upload_example_csv_download')"
              :filename="filename"
            />
          </li>
          <slot name="additional-buttons" />
        </ul>
      </div>
      <div v-else-if="id === 'upload_file_input'" class="AppForm__group-field">
        <AppFileField
          id="batch-job-file"
          name="batch_job[file]"
          :placeholder="
            disabled
              ? $t('local.upload_file_input_placeholder.disabled')
              : $t('local.upload_file_input_placeholder.uploadable')
          "
          accept=".csv"
          :event-bus="fileEventBus"
          :is-submitting="isSubmitting"
          :disabled="disabled"
        />
      </div>
    </template>
    <template #group-foot="{ id }">
      <div
        v-if="id === 'upload_file_input' && uploadStateHint"
        :class="[
          'BatchJobForm__upload_state_hint',
          uploadStateHint.type
            ? `BatchJobForm__upload_state_hint--${uploadStateHint.type}`
            : null
        ]"
      >
        <div>{{ uploadStateHint.message }}</div>
        <a
          v-if="uploadStateHint.detailClickHandler"
          class="BatchJobForm__upload_state_hint_detail"
          @click="uploadStateHint.detailClickHandler"
          >{{ uploadStateHint.detailLabel }}</a
        >
      </div>
    </template>
  </AppForm>
</template>

<script>
import { mapMutations } from 'vuex';
import Vue from 'vue';
import FormView from '@/mixins/FormView';
import { nullResources } from '@/lib/resources';
import api from '@/lib/api';

export default {
  name: 'BatchJobForm',
  mixins: [FormView],
  props: {
    batchJobType: { type: Number, required: true },
    title: { type: String, required: true },
    description: { type: String, default: null },
    descriptionHtml: { type: String, default: null },
    sampleFileUrl: { type: String, default: null },
    successMessage: { type: String, required: true },
    filename: { type: String, default: null },
    downloadLabel: { type: String, default: null },
    uploadStateHint: { type: Object, default: null },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      fileEventBus: new Vue(),
      batchJobs: nullResources
    };
  },
  computed: {
    formSections() {
      return [
        ...(this.description || this.descriptionHtml
          ? [
              {
                id: 'upload_description',
                groups: [{ id: 'upload_description', label: this.title }]
              }
            ]
          : []),
        {
          id: 'upload_file_input',
          groups: [
            {
              id: 'upload_file_input',
              label: this.$t('local.upload_file_input_title')
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapMutations('batchJob', ['CREATE_BATCH_JOB']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/batch_jobs', formData, { successMessage: this.successMessage })
        .then(({ data }) => {
          this.CREATE_BATCH_JOB(data.batch_job);
          this.fileEventBus.$emit('reset');
          this.$emit('submitted');
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_breakpoints.scss';
@import '@/scss/mixins/_texts.scss';

.BatchJobForm__buttons {
  margin-top: 20px;

  ::v-deep {
    > li {
      display: inline-block;

      + li {
        margin-left: 8px;
      }
    }
  }
}

@include media-breakpoint-each(mobile) {
  .BatchJobForm__buttons {
    ::v-deep {
      > li {
        display: block;
        margin-top: 8px;

        + li {
          margin-left: 0;
        }

        > .AppButton {
          display: block;
        }
      }
    }
  }
}

.BatchJobForm__upload_state_hint {
  @include text-caption;

  &--started {
    color: $color-grey-40;
  }

  &--failed {
    color: $color-red;
  }
}

.BatchJobForm__upload_state_hint_detail {
  text-decoration: underline;
}
</style>

<i18n locale="ko">
{
  "local": {
    "upload_example_csv_download": "CSV 예시 파일 다운로드",
    "upload_file_input_title": "CSV 파일 업로드",
    "upload_file_input_placeholder": {
      "uploadable": "업로드할 CSV 파일을 등록해주세요.",
      "disabled": "현재 다른 파일을 등록할 수 없습니다."
    }
  }
}
</i18n>
