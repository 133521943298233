<template>
  <ReviewSection :title="$t('title')">
    <div class="ReviewSentimentAnalysisSection__analysis">
      <span class="ReviewSentimentAnalysisSection__analysis-title">{{
        $t('analysis.title')
      }}</span>
      <div class="ReviewSentimentAnalysisSection__analysis-content">
        <i
          :class="[
            'ReviewSentimentAnalysisSection__analysis-icon',
            iconByType(review.sentiment_type)
          ]"
        />
        <span class="ReviewSentimentAnalysisSection__analysis-detail">{{
          ReviewSentimentType.t(review.sentiment_type)
        }}</span>
      </div>
    </div>
    <AppMiniPercentageBarChart
      class="ReviewSentimentAnalysisSection__chart"
      :numerator="productData.mode_count"
      :denominator="productData.total_count"
      :chart-color="colorByType(productData.mode)"
      :title="$t('product.title')"
      :label="ReviewSentimentType.t(productData.mode)"
      :description="descriptionWithKey('product', productData.mode)"
    />
    <AppMiniPercentageBarChart
      v-if="!!brandUserData"
      class="ReviewSentimentAnalysisSection__chart"
      :numerator="brandUserData.mode_count"
      :denominator="brandUserData.total_count"
      :chart-color="colorByType(brandUserData.mode)"
      :title="$t('brand_user.title')"
      :label="ReviewSentimentType.t(brandUserData.mode)"
      :description="descriptionWithKey('brand_user', brandUserData.mode)"
    />
  </ReviewSection>
</template>

<script>
import { mapState } from 'vuex';
import ReviewSentimentTypeHelper from '@/mixins/ReviewSentimentTypeHelper';
import ReviewSentimentType from '@/enums/ReviewSentimentType';

import ReviewSection from './components/ReviewSection';

export default {
  name: 'ReviewSentimentAnalysisSection',
  components: { ReviewSection },
  mixins: [ReviewSentimentTypeHelper],
  props: {
    sentimentAnalysisReport: {
      type: Object,
      required: true
    },
    reviewIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState('review', ['reviews']),
    ReviewSentimentType() {
      return ReviewSentimentType;
    },
    brandUserData() {
      return this.sentimentAnalysisReport.brand_user;
    },
    productData() {
      return this.sentimentAnalysisReport.product;
    },
    review() {
      return this.reviews.items[this.reviewIndex];
    }
  },
  methods: {
    colorByType(type) {
      switch (type) {
        case ReviewSentimentType.STRONG_POSITIVE:
        case ReviewSentimentType.POSITIVE:
          return 'mint-green';
        case ReviewSentimentType.STRONG_NEGATIVE:
        case ReviewSentimentType.NEGATIVE:
          return 'red';
        default:
          return 'grey-60';
      }
    },
    descriptionWithKey(key, type) {
      return this.$t(
        `${key}.description.${this.review.sentiment_type === type}`,
        [
          `<span class='ReviewSentimentAnalysisSection__desc--${this.colorByType(
            type
          )}'>${ReviewSentimentType.t(type)}</span>`
        ]
      );
    },
    iconByType(type) {
      switch (type) {
        case ReviewSentimentType.STRONG_POSITIVE:
        case ReviewSentimentType.POSITIVE:
          return 'xi-emoticon-smiley-o';
        case ReviewSentimentType.STRONG_NEGATIVE:
        case ReviewSentimentType.NEGATIVE:
          return 'xi-emoticon-sad-o';
        default:
          return 'xi-emoticon-neutral-o';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.ReviewSentimentAnalysisSection__analysis {
  display: inline-block;
  margin-right: 70px;
  vertical-align: top;
}

.ReviewSentimentAnalysisSection__analysis-title {
  color: $color-grey-50;
  font-size: 12px;
  margin-bottom: 4px;
}

.ReviewSentimentAnalysisSection__analysis-content {
  margin-top: 4px;
}

.ReviewSentimentAnalysisSection__analysis-icon {
  font-size: 20px;
}

.ReviewSentimentAnalysisSection__analysis-detail {
  margin-left: 4px;
  font-size: 20px;
  font-weight: bold;
  color: $color-grey-80;
}

.ReviewSentimentAnalysisSection__chart {
  display: inline-block;

  & + & {
    margin-left: 60px;
  }
}

::v-deep {
  .ReviewSentimentAnalysisSection__chart {
    .AppChartPercentageBar__bar {
      width: 119px;
    }
  }

  .ReviewSentimentAnalysisSection__desc {
    &--mint-green {
      color: $color-mint-green;
    }

    &--grey-60 {
      color: $color-grey-60;
    }

    &--red {
      color: $color-red;
    }
  }
}
</style>

<i18n locale="ko">
{
  "title": "리뷰 본문 긍정/부정 분석",
  "analysis": {
    "title": "이 리뷰"
  },
  "product": {
    "title": "이 상품 전체 리뷰 분석",
    "description": {
      "true": "다른 사람들도 {0} 표현을 했습니다.",
      "false": "다른 사람들은 {0} 표현을 했습니다."
    }
  },
  "brand_user": {
    "title": "작성자의 다른 리뷰 분석",
    "description": {
      "true": "평소에도 {0} 표현을 했습니다.",
      "false": "평소에는 {0} 표현을 했습니다."
    }
  }
}
</i18n>
<i18n locale="en">
{
  "title": "Review Sentiment Analysis",
  "analysis": {
    "title": "This review"
  },
  "product": {
    "title": "Analysis of all reviews for this product",
    "description": {
      "true": "Others also expressed {0}.",
      "false": "Others have expressed {0}."
    }
  },
  "brand_user": {
    "title": "Analysis of other reviews by authors",
    "description": {
      "true": "Author used to express {0} as usual.",
      "false": "Author usually use {0} expression."
    }
  }
}
</i18n>
