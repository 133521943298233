<template>
  <AppModalFormLivePreview
    class="ChatbotMessageTemplateShowDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{ ...formProps, disabled: true }"
    width="560px"
    hide-close-button
    v-on="formEvents"
  >
    <template #left>
      <BizMessageTemplatePreview
        :template="{
          ...template,
          dispatchMethod: ReviewMessageDispatchMethod.CHATBOT
        }"
      />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'content'">
        <div
          class="ChatbotMessageTemplateShowDialog__content AppForm__group-field"
        >
          {{ template.content }}
        </div>
      </template>
      <template v-else-if="id === 'comments'">
        <div class="AppForm__group-field">
          <BizMessageTemplateComments :comments="comments" />
        </div>
      </template>
      <template v-else-if="id === 'qa_test'">
        <div class="AppForm__group-field">
          <AppButton
            :label="$t('qa_test.approve')"
            @click="onClickTestApprove"
          />
          <AppButton
            button-style="red-outline"
            :label="$t('qa_test.reject')"
            @click="onClickTestReject"
          />
        </div>
      </template>
    </template>
    <template #foot>
      <AppButton
        v-if="!isApproved"
        :label="$t('app.edit')"
        button-style="blue"
        @click="onClickEdit"
      />
      <AppButton :label="$t('app.close')" @click="close(true)" />
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';
import BizMessageTemplatePreview from '../../biz_messages/dialogs/BizMessageTemplatePreview.vue';
import ReviewMessageDispatchMethod from '@/enums/ReviewMessageDispatchMethod';
import api from '@/lib/api';

export default {
  name: 'ChatbotMessageTemplateShowDialog',
  components: { BizMessageTemplatePreview },
  mixins: [DialogFormView],
  props: {
    template: { type: Object, default: null }
  },
  data() {
    return {
      comments: []
    };
  },
  computed: {
    ...mapState(['env']),
    ReviewMessageDispatchMethod: () => ReviewMessageDispatchMethod,
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              type: 'static',
              label: this.$t('template.name'),
              value: this.template.name
            },
            {
              id: 'content',
              type: 'custom',
              label: this.$t('template.content'),
              value: this.template.content
            },
            {
              id: 'comments',
              type: 'custom',
              label: this.$t('template.comments')
            },
            ...(this.env !== 'production' &&
            (this.isRequested || this.isRejected)
              ? [
                  {
                    id: 'qa_test',
                    type: 'custom',
                    label: this.$t('template.qa_test'),
                    superAdmin: true
                  }
                ]
              : [])
          ]
        }
      ];
    },
    isApproved() {
      return (
        this.template.inspection_status ==
        BizMessageTemplateInspectionStatus.APPROVED
      );
    },
    isRequested() {
      return (
        this.template.inspection_status ==
        BizMessageTemplateInspectionStatus.REQUESTED
      );
    },
    isRejected() {
      return (
        this.template.inspection_status ==
        BizMessageTemplateInspectionStatus.REJECTED
      );
    }
  },
  mounted() {
    if (this.template.id) {
      api
        .get(`/review/chatbot_message_templates/${this.template.id}`)
        .then(({ data }) => {
          this.comments = data.template_comments;
        });
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    onClickEdit() {
      this.openDialog([
        'ChatbotMessageTemplateFormDialog',
        { templateId: this.template.id }
      ]);
      this.close(true);
    },
    onClickTestApprove() {
      const comment = window.prompt(this.$t('qa_test.approve_comment'));
      if (comment === null) return;

      api.post(
        `/chatbot_message/templates/${this.template.id}/template_test_approval`,
        {
          template_test_approval: { comment: comment }
        },
        { successMessage: this.$t('qa_test.approve_success') }
      );
    },
    onClickTestReject() {
      const comment = window.prompt(this.$t('qa_test.reject_comment'));
      if (comment === null) return;

      api.delete(
        `/chatbot_message/templates/${this.template.id}/template_test_approval`,
        {
          comment,
          successMessage: this.$t('qa_test.reject_success')
        }
      );
    }
  }
};
</script>

<style scoped>
.ChatbotMessageTemplateShowDialog__content {
  white-space: pre-line;
}
</style>

<i18n locale="ko">
{
  "title": "템플릿 상세보기",
  "sub_title": "챗봇",
  "template": {
    "name": "템플릿 명",
    "content": "템플릿 내용",
    "comments": "의견 또는 문의사항",
    "qa_test": "카카오 검수 테스트 (디자인검수, QA검수용)"
  },
  "qa_test": {
    "approve": "승인",
    "reject": "반려",
    "approve_comment": "승인 메시지를 입력해주세요. 테스트 서버에서만 적용됩니다.",
    "reject_comment": "반려 메시지를 입력해주세요. 테스트 서버에서만 적용됩니다.",
    "approve_success": "템플릿 검수를 승인했습니다.",
    "reject_success": "템플릿 검수를 반려했습니다."
  }
}
</i18n>
