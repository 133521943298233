<template>
  <div class="ReviewDashboard">
    <AppSpinnerContent :is-loading="isLoading" height="540px">
      <template>
        <AppProgressSteps
          v-if="!isImwebBrand"
          class="ReviewDashboard__progress-steps"
          :steps="[
            $t('easy_join_progress_step.prepare_crema_review_usage'),
            $t('easy_join_progress_step.proceed_with_widget_installation'),
            $t('easy_join_progress_step.complete_payment_and_widget_tryouts'),
            $t('easy_join_progress_step.start_crema_review_usage')
          ]"
          :current-step="currentStep"
        />
        <div class="ReviewDashboard__summary-box-container">
          <SummaryBoxRequestInstallationComplete
            v-if="shouldDisplaySummaryBoxRequestInstallationComplete"
            class="ReviewDashboard__summary-box"
          />
          <SummaryBoxInstallComplete
            v-if="isWidgetInstallStatusComplete"
            class="ReviewDashboard__summary-box"
          />
          <SummaryBoxPaymentRequested
            v-if="shouldDisplaySummaryBoxPaymentRequested"
            class="ReviewDashboard__summary-box"
          />
          <SummaryBoxWelcome
            v-if="shouldDisplaySummaryBoxWelcome"
            class="ReviewDashboard__summary-box"
          />
          <SummaryBoxPreparingPayment
            v-if="shouldDisplaySummaryBoxPreparingPayment"
            class="ReviewDashboard__summary-box"
          />
          <SummaryBoxLive
            v-if="shouldDisplaySummaryBoxLive"
            class="ReviewDashboard__summary-box"
          />
          <SummaryBoxFreeTrial
            v-if="shouldDisplaySummaryBoxFreeTrial"
            class="ReviewDashboard__summary-box"
            @loading="isLoading = $event"
          />
          <SummaryBoxDataSyncCheck
            v-if="shouldDisplaySummaryBoxDataSyncCheck"
            class="ReviewDashboard__summary-box"
          />
        </div>
        <template v-if="isEssentialAccordionCompleted">
          <div class="ReviewDashboard__section">
            <div class="ReviewDashboard__section-head">
              {{ $t('additional.title') }}
            </div>
            <div class="ReviewDashboard__section-body">
              <div
                v-for="option in accordionOptions.additional"
                :key="option.name"
                class="ReviewDashboard__accordion"
              >
                <NextStepBubble
                  v-if="bubbleLocation === option.name"
                  :text="$t('additional_next')"
                />
                <AppAccordion
                  :title="$t(`${option.name}.title`)"
                  :sub-title="accordionSubTitle(option.name)"
                  :type="option.type"
                  :component-name="option.component"
                  :is-completed="option.isCompleted"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="ReviewDashboard__section">
            <div class="ReviewDashboard__section-head">
              {{ $t('essential.title') }}
            </div>
            <div class="ReviewDashboard__section-body">
              <div
                v-for="option in accordionOptions.essential"
                :key="option.name"
                class="ReviewDashboard__accordion"
              >
                <NextStepBubble
                  v-if="bubbleLocation === option.name"
                  :text="essentialNext"
                />
                <AppAccordion
                  :title="$t(`${option.name}.title`)"
                  :sub-title="accordionSubTitle(option.name)"
                  :complete-sub-title="$t(`${option.name}.complete_sub_title`)"
                  :type="option.type"
                  :component-name="option.component"
                  :is-completed="option.isCompleted"
                  :disabled="option.disabled"
                  :disabled-tooltip="option.disabledTooltip"
                />
              </div>
            </div>
          </div>
        </template>
      </template>
    </AppSpinnerContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import { mapMutations, mapGetters, mapState } from 'vuex';
import SummaryBoxRequestInstallationComplete from './components/SummaryBoxRequestInstallationComplete';
import SummaryBoxInstallComplete from './components/SummaryBoxInstallComplete';
import SummaryBoxLive from './components/SummaryBoxLive';
import SummaryBoxFreeTrial from './components/SummaryBoxFreeTrial';
import SummaryBoxWelcome from './components/SummaryBoxWelcome';
import SummaryBoxPaymentRequested from './components/SummaryBoxPaymentRequested';
import SummaryBoxPreparingPayment from './components/SummaryBoxPreparingPayment';
import SummaryBoxDataSyncCheck from './components/SummaryBoxDataSyncCheck';
import NextStepBubble from './components/NextStepBubble.vue';
import ShopBuilder from '@/enums/ShopBuilder';
import AppSpinnerContent from '@/components/AppSpinnerContent';

export default {
  name: 'ReviewDashboard',
  components: {
    SummaryBoxRequestInstallationComplete,
    SummaryBoxInstallComplete,
    SummaryBoxWelcome,
    SummaryBoxPaymentRequested,
    SummaryBoxPreparingPayment,
    SummaryBoxLive,
    SummaryBoxFreeTrial,
    SummaryBoxDataSyncCheck,
    NextStepBubble,
    AppSpinnerContent
  },
  data() {
    return {
      bubbleLocation: 'shop_info',
      customerInputCompleted: false,
      isLoading: true
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('session', ['isImwebBrand', 'isGodoBrand']),
    ...mapGetters('easyJoin', [
      'shopInfoCompleted',
      'requestInstallationCompleted',
      'isMainColorCompleted',
      'mileageCompleted',
      'godoCsvCompleted',
      'isWidgetInstallStatusComplete',
      'isEssentialAccordionCompleted',
      'shouldDisplaySummaryBoxPaymentRequested',
      'shouldDisplaySummaryBoxPreparingPayment',
      'shouldDisplaySummaryBoxWelcome',
      'shouldDisplaySummaryBoxLive',
      'shouldDisplaySummaryBoxFreeTrial',
      'shouldDisplaySummaryBoxDataSyncCheck',
      'shouldDisplaySummaryBoxRequestInstallationComplete',
      'shouldDisableInstallRequestAccordion'
    ]),
    currentStep() {
      if (this.shouldDisplaySummaryBoxLive) return 3;
      if (this.isWidgetInstallStatusComplete) return 2;
      if (this.isEssentialAccordionCompleted) return 1;
      return 0;
    },
    accordionOptions() {
      return {
        essential: [
          {
            name: 'shop_info',
            type: 'dataEntry',
            component: 'ReviewDashboardShopInfo',
            isCompleted: this.shopInfoCompleted
          },
          ...(this.isGodoBrand
            ? [
                {
                  name: 'godo_review_csv_sync',
                  component: 'ReviewDashboardGodoReviewCsvSync',
                  isCompleted: this.godoCsvCompleted
                }
              ]
            : []),
          {
            name: 'request_installation',
            component: 'ReviewDashboardRequestInstallation',
            isCompleted: this.requestInstallationCompleted,
            disabled: this.shouldDisableInstallRequestAccordion,
            disabledTooltip: this.$t('request_installation.disabled_tooltip')
          }
        ].filter(Boolean),
        additional: [
          {
            name: 'mileage',
            type: 'dataEntry',
            component: 'ReviewDashboardMileage',
            isCompleted: this.mileageCompleted
          }
        ]
      };
    },
    essentialNext() {
      const shouldDisplayImwebEssentialNext =
        this.isImwebBrand && this.isMainColorCompleted;

      return shouldDisplayImwebEssentialNext
        ? this.$t('imweb_essential_next')
        : this.$t('essential_next');
    }
  },
  created() {
    this.isLoading = true;
    api
      .get('/easy_join/onboarding_progresses')
      .then(({ data }) =>
        this.SET_ONBOARDING_PROGRESS(data.onboarding_progress)
      )
      .finally(() => (this.isLoading = false));

    this.SET_POLL_PARAMS({ key: 'onboarding_progress', value: 1 });
  },
  beforeDestroy() {
    this.UNSET_POLL_PARAMS('onboarding_progress');
  },
  updated() {
    this.changeBubbleLocation();
  },
  methods: {
    ...mapMutations('poll', ['SET_POLL_PARAMS', 'UNSET_POLL_PARAMS']),
    ...mapMutations('easyJoin', ['SET_ONBOARDING_PROGRESS']),
    changeBubbleLocation() {
      const accordionOptions = Object.values(this.accordionOptions).flat();

      const incompletedOptions = accordionOptions.filter(
        option => !option.isCompleted
      );
      if (incompletedOptions.length === 0) {
        this.bubbleLocation = null;
      } else {
        this.bubbleLocation = incompletedOptions[0].name;
      }
    },
    accordionSubTitle(name) {
      switch (name) {
        case 'shop_info':
          return this.$t('shop_info.sub_title', [
            ShopBuilder.tk(this.currentBrand.shop_builder)
          ]);
        case 'message':
          return this.$t('message.sms_sub_title');
        default:
          return this.$t(`${name}.sub_title`);
      }
    },
    setIsLoading(status) {
      this.isLoading = status;
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.ReviewDashboard__progress-steps {
  margin-bottom: 32px;
}

.ReviewDashboard__summary-box-container {
  margin-bottom: 32px;

  &:empty {
    margin-bottom: 0;
  }
}

.ReviewDashboard__section {
  margin-bottom: 32px;
}

.ReviewDashboard__summary-box {
  margin-top: 10px;
}

.ReviewDashboard__accordion {
  margin-bottom: 12px;
}

.ReviewDashboard__section-head {
  @include text-sub-title;
}

.ReviewDashboard__section-body {
  margin-top: 14px;
}

.ReviewDashboard__test-message-button {
  margin-top: 8px;
}
</style>

<i18n locale="ko">
{
  "documents_title": "📄 크리마 서비스 이용을 위해 아래 자료를 제출해주세요.",
  "imweb_essential_next": "👇 크리마 리뷰 사용 시작 후 아래 작업을 진행해주세요.",
  "essential_next": "👇 아래 작업을 진행해주세요.",
  "additional_next": "👇 설치를 기다리는 동안 아래 설정을 검토해보세요.",
  "shop_info" : {
    "title": "쇼핑몰 정보 확인",
    "sub_title": "{0}에 저장된 쇼핑몰 정보를 가지고 왔습니다. 올바른 정보인지 확인해 주세요.",
    "complete_sub_title": "크리마에 등록된 쇼핑몰 정보를 확인했습니다."
  },
  "request_installation" : {
    "title": "위젯 설치",
    "sub_title": "쇼핑몰 방문 고객에게 리뷰를 더 효과적으로 수집하고 노출할 수 있도록 위젯 설치를 진행해주세요.",
    "complete_sub_title": "위젯 설치 요청을 완료했습니다.",
    "disabled_tooltip": "리뷰 데이터 동기화를 먼저 진행해주세요."
  },
  "godo_review_csv_sync": {
    "title": "리뷰 데이터 동기화",
    "sub_title": "크리마 리뷰를 원활하게 사용하기 위해 리뷰 데이터 동기화를 위한 작업을 진행해주세요.",
    "complete_sub_title": "리뷰 데이터 동기화를 위한 작업을 완료했습니다."
  },
  "essential":{
    "title": "📌 크리마 리뷰 사용 준비"
  },
  "additional": {
    "title": "💡️ 원활한 서비스 사용을 위한 설정 검토"
  },
  "mileage": {
    "title": "리뷰를 작성한 고객에게 적립금을 지급해 보세요.",
    "sub_title": "적립금 지급액을 설정하면 더 많은 리뷰 작성에 도움이 됩니다."
  },
  "message": {
    "title": "구매 고객에게 리뷰 작성 유도 메시지를 발송해 보세요.",
    "sms_sub_title": "문자로 리뷰 작성 유도 메시지를 발송할 수 있습니다."
  },
  "customer_input": {
    "title": "고객이 리뷰 작성 시 입력하는 정보를 검토해 보세요.",
    "sub_title": "리뷰 본문, 상품 평점, 평소 사이즈 등 고객이 리뷰 작성 시 입력하는 정보를 확인하고, 변경할 수 있어요."
  }
}
</i18n>
