var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SmartstoreProductMatch"},[_c('SmartstoreSummaryBox'),_c('BatchJobForm',_vm._b({attrs:{"title":"","description-html":_vm.uploadDescription,"sample-file-url":"/csv/smartstore-product-match-v4.csv","success-message":_vm.$t('batch_job_started'),"download-label":_vm.$t('download_label'),"filename":"smartstore-product-match-v4.csv","upload-state-hint":_vm.uploadStateHint,"disabled":_vm.uploadDisabled},on:{"submitted":_vm.submitted},scopedSlots:_vm._u([{key:"additional-buttons",fn:function(){return [_c('a',{staticClass:"SmartstoreProductMatch__file_upload_guide",on:{"click":_vm.openProductMatchGuide}},[_vm._v(" "+_vm._s(_vm.$t('smartstore.file_upload_guide.label'))+" ")])]},proxy:true}])},'BatchJobForm',{ batchJobType: _vm.batchJobType },false)),(_vm.smartstoreStatus !== _vm.SmartstoreStatus.OPERATING)?_c('AppForm',{staticClass:"SmartstoreProductMatch__estimation_form",attrs:{"sections":_vm.sections,"is-submitting":_vm.isSubmittingEstimation,"submit-button":{
      submitLabel: _vm.$t('estimation.submit'),
      submittingLabel: _vm.$t('estimation.submitting'),
      disabled: _vm.disabledSubmitEstimation,
      disabledTooltip: !_vm.smartstoreAccessAccepted
        ? _vm.$t('smartstore_invitation_pending.disabled_tooltip')
        : ''
    }},on:{"submit":_vm.submitEstimation},scopedSlots:_vm._u([{key:"group",fn:function(ref){
    var id = ref.id;
return [(id === 'smartstore_invitation_pending')?_c('div',[_c('div',{staticClass:"AppForm__group-field"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('smartstore_invitation_pending.smartstore_name.title'))+" ")]),_c('AppButton',{staticClass:"SmartstoreProductMatch__smartstore_invitation_pending_button",attrs:{"label":_vm.$t('smartstore_invitation_pending.smartstore_name.label')},on:{"click":_vm.openSmartstoreNameDialog}})],1),_c('div',{staticClass:"AppForm__group-field"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('smartstore_invitation_pending.invite_guide.title'))+" ")]),_c('AppButton',{staticClass:"SmartstoreProductMatch__smartstore_invitation_pending_button",attrs:{"label":_vm.$t('smartstore_invitation_pending.invite_guide.label')},on:{"click":_vm.openInviteGuideAlert}})],1),_c('div',{staticClass:"AppForm__group-description"},[_vm._v(" "+_vm._s(_vm.$t('smartstore_invitation_pending.hint_process_time'))+" ")])]):_vm._e()]}}],null,false,3487800684)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }