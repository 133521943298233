import BrandCategoryType from '@/enums/BrandCategoryType';

const state = {
  my_brand_categories: [],
  all_brand_categories: []
};

const mutations = {
  SET_MY_BRAND_CATEGORIES(state, brandCategories) {
    state.my_brand_categories = brandCategories;
  },
  SET_ALL_BRAND_CATEGORIES(state, brandCategories) {
    state.all_brand_categories = brandCategories;
  }
};

const productCategories = (brandCategories, brandMarketCategories) => {
  return brandCategories.filter(
    c =>
      c.category_type === BrandCategoryType.PRODUCT &&
      brandMarketCategories.map(c => c.id).includes(c.market_brand_category_id)
  );
};

const genderCategories = brandCategories => {
  return brandCategories.filter(
    c => c.category_type === BrandCategoryType.GENDER
  );
};

const ageCategories = brandCategories => {
  return brandCategories.filter(c => c.category_type === BrandCategoryType.AGE);
};

const styleCategories = brandCategories => {
  return brandCategories.filter(
    c => c.category_type === BrandCategoryType.STYLE
  );
};

const getters = {
  sportsLeisureProductCategory({ all_brand_categories }) {
    return all_brand_categories.find(
      c =>
        c.category_type === BrandCategoryType.PRODUCT &&
        c.category_value === '스포츠/레저 용품'
    );
  },
  isMyBrandSportsLeisureProductCategory({ my_brand_categories }, getters) {
    return (
      my_brand_categories.length === 1 &&
      my_brand_categories[0].id === getters.sportsLeisureProductCategory.id
    );
  },
  brandMarketCategories({ all_brand_categories }, getters, rootState) {
    const { currentBrand } = rootState['session'];
    const { is_beauty_mall, is_food_mall, is_common_mall } = currentBrand;

    if (getters.isMyBrandSportsLeisureProductCategory) {
      return all_brand_categories.filter(
        c =>
          c.category_type === BrandCategoryType.MARKET &&
          c.category_value === '스포츠/레저'
      );
    } else if (is_beauty_mall) {
      return all_brand_categories.filter(
        c =>
          c.category_type === BrandCategoryType.MARKET &&
          c.category_value === '뷰티'
      );
    } else if (is_food_mall) {
      return all_brand_categories.filter(
        c =>
          c.category_type === BrandCategoryType.MARKET &&
          c.category_value === '식품'
      );
    } else if (is_common_mall) {
      return all_brand_categories.filter(
        c =>
          c.category_type === BrandCategoryType.MARKET &&
          ['리빙', '디지털', '펫', '취미', '기타'].includes(c.category_value)
      );
    } else {
      return all_brand_categories.filter(
        c =>
          c.category_type === BrandCategoryType.MARKET &&
          ['패션', '스포츠/레저', '유아동/출산'].includes(c.category_value)
      );
    }
  },
  myBrandProductCategories({ my_brand_categories }, getters) {
    if (getters.isMyBrandSportsLeisureProductCategory)
      return my_brand_categories;

    return productCategories(
      my_brand_categories,
      getters.brandMarketCategories
    );
  },
  brandsProductCategories(
    { my_brand_categories, all_brand_categories },
    getters
  ) {
    if (getters.isMyBrandSportsLeisureProductCategory)
      return my_brand_categories;

    return productCategories(
      all_brand_categories,
      getters.brandMarketCategories
    );
  },
  myBrandGenderCategories({ my_brand_categories }) {
    return genderCategories(my_brand_categories);
  },
  brandsGenderCategories({ all_brand_categories }) {
    return genderCategories(all_brand_categories);
  },
  myBrandAgeCategories({ my_brand_categories }) {
    return ageCategories(my_brand_categories);
  },
  brandsAgeCategories({ all_brand_categories }) {
    return ageCategories(all_brand_categories);
  },
  myBrandStyleCategories({ my_brand_categories }) {
    return styleCategories(my_brand_categories);
  },
  brandsStyleCategories({ all_brand_categories }) {
    return styleCategories(all_brand_categories);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
