var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{staticClass:"ReviewSmartInstallationSettingsInstallation",attrs:{"is-loading":_vm.isLoading}},[_c('AppForm',_vm._g(_vm._b({attrs:{"id":_vm.SETTINGS_ID,"submit-button":""},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
return [(id === 'use_score_widget_v2')?[_c('div',{staticClass:"AppForm__group-description"},[_vm._v(" "+_vm._s(_vm.$t('use_score_widget_v2.description'))+" "),_c('span',{staticClass:"ReviewSmartInstallationSettingsInstallation__show-more-link",domProps:{"innerHTML":_vm._s(_vm.$t('show_more_link'))},on:{"click":_vm.openAlignStarIconsGuideDialog}})])]:(id === 'enable_remove_hard_coded_crema_class')?[_c('div',{class:[
            'AppForm__group-field',
            {
              'AppForm__group-field--disabled': !_vm.formObject[
                'enable_remove_hard_coded_crema_class'
              ]
            }
          ]},[_c('div',{staticClass:"AppForm__sub-group"},[_c('div',{staticClass:"AppForm__sub-group-item"},[_c('AppTags',{attrs:{"name":"remove_hard_coded_crema_class_skins[]","disabled":!_vm.formObject['enable_remove_hard_coded_crema_class'],"placeholder":_vm.$t('remove_hard_coded_crema_class_skins.placeholder')},model:{value:(_vm.formObject['remove_hard_coded_crema_class_skins']),callback:function ($$v) {_vm.$set(_vm.formObject, 'remove_hard_coded_crema_class_skins', $$v)},expression:"formObject['remove_hard_coded_crema_class_skins']"}}),_c('div',{staticClass:"AppForm__sub-group-hint"},[_vm._v(" "+_vm._s(_vm.$t('remove_hard_coded_crema_class_skins.hint'))+" ")])],1)])])]:_vm._e()]}}])},'AppForm',_vm.formProps,false),_vm.formEvents))],1)}
var staticRenderFns = []

export { render, staticRenderFns }