import Vue from 'vue';
import _ from 'lodash';
import api from '@/lib/api';
import i18n from '@/lib/i18n';

const FETCH_FIT_TEMPLATES_REQUEST_ID = 'FETCH_FIT_TEMPLATES';

const state = {
  fitTemplates: [],
  fitCategories: [],
  fitDefaultSampleImages: []
};

const mutations = {
  SET_FIT_TEMPLATES(state, fitTemplates) {
    state.fitTemplates = fitTemplates;
  },
  SET_FIT_CATEGORIES(state, fitCategories) {
    state.fitCategories = fitCategories;
  },
  SET_FIT_DEFAULT_SAMPLE_IMAGES(state, fitDefaultSampleImages) {
    state.fitDefaultSampleImages = fitDefaultSampleImages;
  },
  UPDATE_FIT_TEMPLATE({ fitTemplates }, newFitTemplate) {
    const index = fitTemplates.findIndex(i => i.id === newFitTemplate.id);
    Vue.set(fitTemplates, index, newFitTemplate);
  },
  ADD_FIT_TEMPLATE({ fitTemplates }, newFitTemplate) {
    fitTemplates.push(newFitTemplate);
  },
  DELETE_FIT_TEMPLATE({ fitTemplates }, fitTemplateId) {
    const index = fitTemplates.findIndex(i => i.id === fitTemplateId);
    Vue.delete(fitTemplates, index);
  }
};

const actions = {
  resetFitTemplates({ commit }) {
    api.cancel(FETCH_FIT_TEMPLATES_REQUEST_ID);
    commit('SET_FIT_TEMPLATES', []);
  },
  fetchFitTemplates({ commit }) {
    api
      .get('/fit/templates', {
        requestId: FETCH_FIT_TEMPLATES_REQUEST_ID
      })
      .then(function({ data }) {
        commit('SET_FIT_TEMPLATES', data.fit_templates);
        commit('SET_FIT_CATEGORIES', data.fit_categories);
        commit('SET_FIT_DEFAULT_SAMPLE_IMAGES', data.fit_default_sample_images);
      })
      .catch(error => {
        if (!api.isCancel(error)) alert(error);
      });
  },
  deleteFitTemplate({ commit }, fitTemplate) {
    api.delete(`/fit/templates/${fitTemplate.id}`, {
      successMessage: i18n.t('fit_template.deleted', {
        name: fitTemplate.name
      })
    });
    commit('DELETE_FIT_TEMPLATE', fitTemplate.id);
  },
  sortFitTemplates({ state, commit }, fitTemplateIds) {
    const itemMap = _.keyBy(state.fitTemplates, 'id');
    const sortedFitTemplates = fitTemplateIds.map(id => itemMap[id]);
    commit('SET_FIT_TEMPLATES', sortedFitTemplates);
    api.patch(
      '/fit/templates/sort',
      { ids: fitTemplateIds },
      { successMessage: i18n.t('fit_template.sorted') }
    );
  },
  cloneFitTemplate({ commit }, { fitTemplate, onComplete }) {
    const cloned_name = i18n.t('fit_template.cloned_name', {
      name: fitTemplate.name
    });
    api
      .post(
        '/fit/templates/clone',
        {
          id: fitTemplate.id,
          cloned_name: cloned_name
        },
        { successMessage: i18n.t('fit_template.cloned', { name: cloned_name }) }
      )
      .then(function({ data }) {
        commit('ADD_FIT_TEMPLATE', data.fit_template);
        onComplete();
      })
      .catch(function() {
        onComplete();
      });
  }
};

const getters = {
  isFetchingTemplates(_state, _getters, _rootState, rootGetters) {
    return rootGetters['request/isRequestPending'](
      FETCH_FIT_TEMPLATES_REQUEST_ID
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
