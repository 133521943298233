<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{
      ...formProps,
      objectId: 'review_transaction',
      detectFormDataChange: false,
      submitLabel: $t('app.edit'),
      submittingLabel: $t('app.editing')
    }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="review_transaction[description]"
      :value="description"
    />
  </AppModalForm>
</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import ReviewTransactionType from '@/enums/ReviewTransactionType';
import DialogFormView from '@/mixins/DialogFormView';
export default {
  name: 'ThePaymentHistoryEditReviewTransactionDialog',
  mixins: [DialogFormView],
  props: { transaction: { type: Object, required: true } },
  data() {
    return {
      orgFormObject: {
        review_event_type: this.transaction.review_event_type,
        description: this.transaction.description
      }
    };
  },
  computed: {
    formSections() {
      const transactionType = this.formObject.review_event_type;
      return [
        {
          id: 'default',
          groups: [
            {
              id: 'review_event_type',
              label: this.$t('review_transaction.review_event_type'),
              type: 'select',
              options: ReviewTransactionType.editableTypes.map(value => ({
                label: this.transactionTypeLabel(value),
                value
              }))
            },
            {
              id: 'description',
              name: null,
              label: this.$t('review_transaction.description'),
              type: 'text',
              placeholder: this.transactionTypeLabel(transactionType),
              validate:
                transactionType === ReviewTransactionType.ETC
                  ? ['required']
                  : []
            }
          ]
        }
      ];
    },
    description() {
      return this.formObject.description === ''
        ? this.transactionTypeLabel(this.formObject.review_event_type)
        : this.formObject.description;
    }
  },
  methods: {
    ...mapMutations('session', ['SET_REVIEW_STATUS']),
    transactionTypeLabel(transactionType) {
      switch (transactionType) {
        case ReviewTransactionType.PAYMENT:
          return this.$t('review_transaction_type_payment');
        case ReviewTransactionType.FEE_PAYMENT:
          return this.$t('review_transaction_type_fee_payment');
        case ReviewTransactionType.RETURN:
          return this.$t('review_transaction_type_return');
        case ReviewTransactionType.FEE_RETURN:
          return this.$t('review_transaction_type_fee_return');
        case ReviewTransactionType.ETC:
          return this.$t('review_transaction_type_etc');
        default:
          return '';
      }
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/payment_history/review_transactions/${this.transaction.id}`,
          formData,
          {
            successMessage: this.$t('app.saved')
          }
        )
        .then(({ data }) => {
          if (data.review_status) this.SET_REVIEW_STATUS(data.review_status);
          this.eventBus.$emit('update', data);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "이용내역 추가하기",
  "sub_title": "리뷰 이용내역",
  "review_transaction_type_payment": "서비스 결제내역 추가",
  "review_transaction_type_fee_payment": "서비스 API 사용 수수료 결제내역 추가",
  "review_transaction_type_return": "서비스 결제 취소 내역 추가",
  "review_transaction_type_fee_return": "서비스 API 사용 수수료 결제 취소 내역 추가",
  "review_transaction_type_etc": "기타 이용내역 추가",
  "review_transaction": {
    "review_event_type": "항목 선택",
    "description": "메시지 입력"
  }
}
</i18n>
