<template>
  <table
    :class="[
      'AppTable',
      tableId,
      align ? `AppTable--${align}` : null,
      valignBaseline ? `AppTable--valign-baseline` : null,
      { 'AppTable--no-head': noHead }
    ]"
  >
    <thead v-if="!noHead" class="AppTable__head">
      <tr class="AppTable__head-row">
        <template v-for="column in tableColumns">
          <!-- eslint-disable vue/no-v-html -->
          <th
            v-if="column.tooltip"
            :key="column.columnId"
            v-tooltip="column.tooltip"
            :class="[
              'AppTable__head-col',
              column.columnId,
              column.align ? `AppTable__head-col--${column.align}` : null
            ]"
            :style="column.width ? { width: column.width } : {}"
          >
            <span>{{ column.label }}</span>
            <AppSvgIcon
              name="icon-info-tooltip"
              class="AppTable__head-col-icon"
              :width="10"
              :height="10"
            />
          </th>
          <th
            v-else
            :key="column.columnId"
            :class="[
              'AppTable__head-col',
              column.columnId,
              column.align ? `AppTable__head-col--${column.align}` : null
            ]"
            :style="column.width ? { width: column.width } : {}"
            v-html="column.label"
          />
          <!-- eslint-enable vue/no-v-html -->
        </template>
      </tr>
    </thead>
    <tbody class="AppTable__body js-draggable">
      <tr
        v-for="(row, rowIndex) in rows"
        :key="row.id"
        v-tooltip="row.tooltip"
        :class="{
          'AppTable__body-row': true,
          'AppTable__body-row--highlight': row.highlight
        }"
      >
        <td
          v-for="column in tableColumns"
          :key="column.columnId"
          :class="[
            'AppTable__body-col',
            row[`${column.id}_class`],
            column.columnId,
            column.align ? `AppTable__body-col--${column.align}` : null,
            column.style ? `AppTable__body-col--${column.style}` : null,
            { 'AppTable__body-col--check': column.type === 'check' }
          ]"
          :style="column.width ? { width: column.width } : {}"
        >
          <slot
            name="cell"
            :value="row[column.id]"
            :column="column"
            :row="row"
            :row-index="rowIndex"
          >
            <!-- eslint-disable vue/no-v-html -->
            <span v-if="column.type === 'html'" v-html="row[column.id]" />
            <span
              v-else-if="column.type === 'pre'"
              class="AppTable__cell-pre"
              >{{ row[column.id] }}</span
            >
            <div
              v-else-if="column.type === 'nowrap'"
              :ref="`Table${row.id}${column.id}`"
              v-tooltip="{
                message: autoTooltip(
                  column.autoTooltip,
                  `Table${row.id}${column.id}`
                )
              }"
              class="AppTable__body-col--nowrap"
            >
              {{ row[column.id] }}
            </div>
            <AppTableCellProduct
              v-else-if="column.type === 'product'"
              :product="row[column.id]"
            />
            <!-- eslint-enable vue/no-v-html -->
            <template v-else-if="column.type === 'review_media'">
              <AppReviewMediaCell :review="row" />
            </template>
            <template v-else-if="column.type === 'img'">
              <AppImage
                v-if="!!row[column.id]"
                class="AppTable__cell-img"
                :src="row[column.id]"
                :style="{ height: column.imgHeight }"
              />
            </template>
            <template v-else-if="column.type === 'number'">
              <AppNumber
                :value="row[column.id]"
                :force-precision="column.forcePrecision"
                :precision="column.precision"
                :unit="column.unit"
                :placeholder="column.default"
              />
            </template>
            <template v-else-if="column.type === 'currency'">
              <AppNumber :value="row[column.id]" unit="currency" />
            </template>
            <template v-else-if="column.type === 'rate'">
              <AppNumber
                :value="row[column.id] ? row[column.id] * 100 : row[column.id]"
                :precision="1"
                force-precision
                unit="percentage"
              />
            </template>
            <template v-else-if="column.type === 'check'">
              <AppSvgIcon
                v-if="row[column.id]"
                name="icon-list-check"
                class="AppTable__check-icon"
              />
            </template>
            <template v-else-if="column.type === 'badge'">
              <AppBadge
                :label="row[column.id].label"
                :badge-style="row[column.id].badgeStyle"
                :tooltip="row[column.id].badgeTooltip"
              />
            </template>
            <template v-else-if="column.type === 'badges'">
              <ul>
                <li
                  v-for="item in row[column.id]"
                  :key="item.id"
                  class="AppTable__badges"
                >
                  <AppBadge
                    :label="item.label"
                    :badge-style="item.style"
                    :badge-size="item.size"
                    :tooltip="item.tooltip"
                  />
                </li>
              </ul>
            </template>
            <AppData v-else :type="column.type" :value="row[column.id]" />
          </slot>
        </td>
      </tr>
      <tr v-if="rows.length === 0" class="AppTable__body-row">
        <td
          class="AppTable__body-col AppTable__body-col--no-data"
          :colspan="columns.length"
        >
          <slot name="no-data">{{ noData }}</slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import _ from 'lodash';
import TooltipHelper from '@/mixins/TooltipHelper';

export default {
  name: 'AppTable',
  mixins: [TooltipHelper],
  props: {
    tableId: {
      type: String,
      default: null
    },
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    noData: {
      type: String,
      default() {
        return this.$t('app.no_data');
      }
    },
    noHead: { type: Boolean, default: false },
    align: {
      type: String,
      default: null,
      validator: v => v === 'left'
    },
    valignBaseline: { type: Boolean, default: false }
  },
  computed: {
    tableColumns() {
      const columnIdPrefix = this.tableId ? `${this.tableId}__` : '';
      return this.columns.map(column => ({
        ...column,
        columnId: `${columnIdPrefix}${_.kebabCase(column.id)}`
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_transitions.scss';
@import '@/scss/vars/_colors.scss';

.AppTable {
  background-color: white;
  text-align: center;
  width: 100%;

  &--left {
    text-align: left;
  }

  &--no-head {
    border-top: 1px solid $color-layout-section;
  }
}

.AppTable__head-row {
  border-top: 1px solid $color-layout-section-dark;
  border-bottom: 1px solid $color-layout-section-dark;
}

.AppTable__body-row {
  border-bottom: 1px solid $color-layout-section;
  @include transition-basic(background-color);

  &:hover,
  &--highlight {
    background-color: $color-grey-05;
  }
}

.AppTable__head-col {
  padding: 11px 8px;
  vertical-align: middle;

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }
}

.AppTable__head-col-icon {
  margin-left: 4px;
}

.AppTable__body-col {
  padding: 8px;
  vertical-align: middle;

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--check {
    font-size: 16px;
  }

  &--no-data {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  &--nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .AppTable--valign-baseline & {
    vertical-align: baseline;
  }
}

.AppTable__check-icon {
  display: block;
  margin: 0 auto;
}

.AppTable__badges {
  width: fit-content;
  margin: auto;
  font-size: 0;
  height: 19px;
}

::v-deep {
  .AppTable__select {
    display: block;
  }

  // HACK - 테이블 내부의 AppButton 간격 조정. BEM에 어긋나지만 더 좋은 방법을 찾지 못함
  .AppTable__body-col {
    .AppButton + .AppButton {
      margin-left: 4px;
    }
  }

  .AppTable__badges + .AppTable__badges {
    margin-top: 4px;
  }
}
</style>

<style>
.AppTable__cell-pre {
  white-space: pre-wrap;
  line-height: 20px;
  word-break: break-word;
}
</style>
