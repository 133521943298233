<template>
  <TargetSettingsSection :title="$t('title')" @open-settings="openPopupDialog">
    <template>
      <div>{{ $t('sub_title') }}</div>
      <div>
        <b>{{ popupStyleSummary }}</b>
      </div>
    </template>
  </TargetSettingsSection>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TargetSettingsSection from './components/TargetSettingsSection';
import TargetCampaignPopupStyle from '@/enums/TargetCampaignPopupStyle';

export default {
  name: 'TargetCampaignSettingsPopup',
  components: { TargetSettingsSection },
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    popupStyleSummary() {
      return this.campaign.popup_design.popup_style ===
        TargetCampaignPopupStyle.CONTENTS
        ? this.$t('contents_style')
        : this.$t('default_style');
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openPopupDialog() {
      this.openDialog([
        'TargetCampaignSettingsDesignPopupDialog',
        { campaign: this.campaign }
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "팝업",
  "sub_title": "스타일 구성",
  "default_style": "기본형 팝업",
  "contents_style": "콘텐츠형 팝업"
}
</i18n>
