import api from '@/lib/api';
import { DirectUpload } from '@rails/activestorage';

class DirectUploader {
  constructor(file, url, callback) {
    this.upload = new DirectUpload(file, url, this);
    this.callback = callback;
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', event => {
      const percentage = Math.floor((event.loaded / event.total) * 100);
      this.callback(percentage);
    });
  }
}

function deleteVideo(videoId) {
  requestDelete([videoId]);
}

function deleteVideos(videoIds) {
  requestDelete(videoIds);
}

function requestDelete(videoIds) {
  if (!videoIds.length) return;
  api
    .post('/video/delete', { video_ids: videoIds }, { silent: true })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('failed to delete the video(s)');
    });
}

export { DirectUploader, deleteVideo, deleteVideos };
