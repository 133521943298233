var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppTabContent',{attrs:{"menu-item-id":"target_reports_dispatch_results"}},[_c('AppAjaxContent',{style:(_vm.contentStyle),attrs:{"is-loading":_vm.isLoading}},[_c('AppSearchbar',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('li',[_c('AppSelectFilterable',{attrs:{"options":_vm.campaignTypesOptions,"placeholder":_vm.$t('searchbar.campaign_type_placeholder')},on:{"change":_vm.searchResource},model:{value:(_vm.resourceParams.campaign_type),callback:function ($$v) {_vm.$set(_vm.resourceParams, "campaign_type", $$v)},expression:"resourceParams.campaign_type"}})],1),_c('li',[_c('AppDateRangePicker',{on:{"update":_vm.searchResource},model:{value:(_vm.resourceParams),callback:function ($$v) {_vm.resourceParams=$$v},expression:"resourceParams"}})],1)]},proxy:true}])}),_c(_vm.isDm
          ? 'TargetReportsDispatchResultsTableDm'
          : 'TargetReportsDispatchResultsTableRealtime',{tag:"Component",attrs:{"resources":_vm.cohorts,"rows":_vm.cohorts.items},on:{"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
          var row = ref.row;
          var column = ref.column;
return [(column === 'campaign')?[_c('TargetCampaignNameCell',{attrs:{"campaign-group-type":row.campaign_group_type,"campaign-name":row.campaign_name}})]:(column === 'dispatch_status')?[_c('ul',{staticClass:"badges"},[(row.splited)?_c('li',[_c('AppBadge',{attrs:{"badge-style":"mango-outline","label":_vm.$t('dispatch_status.splited')}})],1):_vm._e(),_c('li',[_c('TargetDispatchStatusBadge',{attrs:{"campaign-type":row.campaign_type,"dispatch-status":row.dispatch_status}})],1),(row.is_not_enough_balance_sent_all)?_c('li',[_c('AppBadge',{attrs:{"label":_vm.$t('dispatch_status.is_not_enough_balance_sent_all'),"badge-style":"grey-outline"}})],1):_vm._e(),(_vm.isNoRecipients(row))?_c('li',[_c('AppBadge',{attrs:{"label":_vm.$t('dispatch_status.no_recipients'),"badge-style":"grey-outline"}})],1):(_vm.isAlternated(row))?_c('li',[_c('AppBadge',{attrs:{"label":_vm.$t('dispatch_status.alternated'),"badge-style":"grey-outline"}})],1):(_vm.isPendingRecalculate(row))?_c('li',[_c('AppBadge',{attrs:{"label":_vm.$t('dispatch_status.pending_recalculate'),"badge-style":"grey-outline"}})],1):_vm._e()])]:(column === 'actions')?[(_vm.isSuperAdminAccessible)?_c('AppDropdownMenuButton',{staticClass:"super-admin__item",attrs:{"label":_vm.$t('app.manage'),"disabled":!_vm.isActionButtonEnabled(row),"menu-items":_vm.manageButtonMenuItems(row)}}):_vm._e(),_c('AppButton',{attrs:{"label":_vm.$t('actions.detail_button.label'),"disabled":!_vm.isDetailButtonEnabled(row),"tooltip":_vm.isOutdated(row)
                ? _vm.$t('actions.detail_button.tooltip.outdated')
                : ''},on:{"click":function($event){return _vm.clickDetailButton(row)}}})]:_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }