<template>
  <AppMobilePhonePreview>
    <AppScrollBox>
      <div class="AppMobilePhoneSmsPreview">
        <div class="AppMobilePhoneSmsPreview__date">
          {{ $d(new Date(), 'date_with_wday') }}
        </div>
        <div class="AppMobilePhoneSmsPreview__message-section">
          <!-- eslint-disable vue/no-v-html -->
          <div class="AppMobilePhoneSmsPreview__message" v-html="messageHtml" />
          <!-- eslint-enable vue/no-v-html -->
          <div class="AppMobilePhoneSmsPreview__dispatch-time">
            {{ dispatchTime }}
          </div>
        </div>
      </div>
    </AppScrollBox>
  </AppMobilePhonePreview>
</template>

<script>
export default {
  name: 'AppMobilePhoneSmsPreview',
  props: {
    messageHtml: { type: String, default: null },
    dispatchTime: { type: String, default: null }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppMobilePhoneSmsPreview {
  padding: 0 24px;
}

.AppMobilePhoneSmsPreview__date {
  @include text-content-light;
  text-align: center;
  padding-top: 36px;
}

.AppMobilePhoneSmsPreview__message-section {
  width: 296px;
}

.AppMobilePhoneSmsPreview__message {
  margin-top: 12px;
  padding: 16px 20px;
  border-radius: 16px;
  background-color: $color-brand-lighter;

  ::v-deep a {
    color: #528fce;
  }
}

.AppMobilePhoneSmsPreview__dispatch-time {
  @include text-content-light;
  margin-top: 4px;
  margin-right: 10px;
  text-align: right;
}
</style>
