<template>
  <AppTabContent menu-item-id="target_popup_campaigns_situational">
    <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
      <TargetCampaignsSearchbar
        v-bind="searchbarProps"
        v-on="searchbarEvents"
      />
      <TargetCampaignsTable v-bind="tableProps">
        <template #head-cell="{ column }">
          <template v-if="column === 'effects'">
            <AppDropdownMenuButton
              button-style="table-head"
              :label="effectTypeLabel(samplingMethod)"
              v-bind="{ menuItems }"
            />
          </template>
        </template>
      </TargetCampaignsTable>
    </AppAjaxContent>
  </AppTabContent>
</template>

<script>
import _ from 'lodash';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignsView from './TargetCampaignsView';

export default {
  name: 'TargetPopupCampaignsSituational',
  mixins: [TargetCampaignsView],
  data: () => ({ samplingMethod: TargetSamplingMethod.POPUP_CLICKED }),
  computed: {
    campaignCategory: () => 'situational',
    campaignMediaType: () => 'popup',
    sortTypes: () => ['edited_at', 'name', 'group_name'],
    searchTypes: () => ['name'],
    columns() {
      return [
        { id: 'campaignInfo' },
        { id: 'effects', label: this.$t('app.effect') },
        { id: 'campaignId' },
        { id: 'editedAt' },
        { id: 'calculateStatus' },
        { id: 'actions' }
      ];
    },
    creatableCampaignTypes() {
      return [TargetCampaignType.UTM_POPUP];
    },
    rows() {
      return this.campaigns.map(campaign => {
        const stats = campaign.stats.map(stat => {
          const finalStat = _.omit(stat, 'clicked_effects');
          if (this.samplingMethod === TargetSamplingMethod.POPUP_CLICKED)
            finalStat.effects = stat.clicked_effects;
          return finalStat;
        });
        return { ...campaign, stats };
      });
    },
    menuItems() {
      return [
        TargetSamplingMethod.POPUP_CHECKED,
        TargetSamplingMethod.POPUP_CLICKED
      ].map(value => ({
        label: this.effectTypeLabel(value),
        value,
        selected: value === this.samplingMethod,
        tooltip: TargetSamplingMethod.tooltipText(value),
        clickHandler: ({ close }) => {
          this.setSamplingMethod(value);
          close();
        }
      }));
    }
  },
  methods: {
    setSamplingMethod(samplingMethod) {
      this.samplingMethod = samplingMethod;
    },
    effectTypeLabel(method) {
      return this.$t('effect_type', [TargetSamplingMethod.t(method)]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "effect_type": "{0} 효과"
}
</i18n>
