export default {
  ESCAPE: 27,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  DIGIT_0: 48,
  DIGIT_9: 57,
  NUMPAD_0: 96,
  NUMPAD_9: 105
};
