<template>
  <AppModalForm
    :title="$t(abTestId ? 'title_edit' : 'title_create')"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId: 'ab_test'
    }"
    v-on="formEvents"
  />
</template>

<script>
import { mapMutations } from 'vuex';
import moment from 'moment';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import AbTestType from '@/enums/AbTestType';
import ReviewAbTestMethod from '@/enums/ReviewAbTestMethod';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';

export default {
  name: 'AnalyticsAbTestsFormDialog',
  mixins: [DialogFormView],
  props: {
    abTestId: { type: Number, default: null }
  },
  data() {
    return {
      isLoading: true,
      reviewAbTestMethodOptions: ReviewAbTestMethod.options()
    };
  },
  computed: {
    isEdit() {
      return !!this.abTestId;
    },
    isTarget() {
      return (
        this.formObject.ab_test_type === AbTestType.TARGET_DISABLE_DISPATCH
      );
    },
    isReview() {
      return (
        this.formObject.ab_test_type === AbTestType.REVIEW_WIDGET_DIST_DISPLAY
      );
    },
    ratioCount() {
      return this.formObject.group_ratios.split(',').length;
    },
    formSections() {
      return [
        {
          id: 'ab_test',
          groups: [
            {
              id: 'name',
              type: 'text',
              label: this.$t('form.name'),
              validate: ['required']
            },
            {
              id: 'ab_test_type',
              label: this.$t('form.ab_test_type'),
              type: 'select',
              disabled: this.isEdit,
              options: AbTestType.options(),
              validate: ['required']
            },
            {
              id: 'start_time',
              label: this.$t('form.start_time'),
              type: 'date',
              disabled: this.isEdit,
              validate: [
                'required',
                {
                  rule: () =>
                    this.isEdit ||
                    moment(this.formObject.start_time).diff(moment()) > 0,
                  errorMessage: this.$t(
                    'period_start_time_must_be_greater_than_now'
                  )
                }
              ]
            },
            {
              id: 'end_time',
              label: this.$t('form.end_time'),
              type: 'date',
              validate: [
                {
                  rule: () =>
                    !!this.formObject.start_time ||
                    moment(this.formObject.end_time).diff(
                      moment(this.formObject.start_time)
                    ) > 0,
                  errorMessage: this.$t(
                    'period_end_time_must_be_greater_than_start_time'
                  )
                }
              ]
            },
            {
              id: 'group_ratios',
              label: this.$t('form.group_ratios.label'),
              type: 'text',
              disabled: this.isEdit,
              placeholder: this.$t('form.group_ratios.placeholder'),
              validate: ['required', this.groupRatioValidation]
            },
            ...(this.isTarget
              ? [
                  {
                    id: 'campaign_media_types',
                    label: this.$t('form.campaign_media_types'),
                    type: 'select',
                    disabled: this.isEdit,
                    options: TargetCampaignMediaType.options().filter(
                      o => o.value !== TargetCampaignMediaType.WIDGET
                    ),
                    validate: ['required']
                  }
                ]
              : this.isReview
              ? [
                  {
                    id: 'review_ab_test_methods',
                    label: this.$t('form.review_ab_test_methods'),
                    type: 'multiple_select_checkbox',
                    options: this.isEdit
                      ? this.reviewAbTestMethodOptions.map(v => ({
                          ...v,
                          disabled: true
                        }))
                      : this.reviewAbTestMethodOptions,
                    validate: ['required']
                  }
                ]
              : [])
          ]
        }
      ];
    },
    groupRatioValidation() {
      return this.isTarget
        ? {
            rule: () => this.ratioCount === 2,
            errorMessage: this.$t('target_ratio_count_must_match')
          }
        : this.isReview
        ? {
            rule: () =>
              this.ratioCount !== 0 &&
              this.ratioCount === this.formObject.review_ab_test_methods.length,
            errorMessage: this.$t('review_ratio_count_must_match')
          }
        : {};
    }
  },
  mounted() {
    if (this.abTestId) {
      api
        .get(`/analytics/ab_tests/${this.abTestId}/edit`)
        .then(({ data }) => {
          this.orgFormObject = {
            ...data.ab_test,
            group_ratios: data.ab_test.group_ratios.join(',')
          };
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.orgFormObject = {};
      this.isLoading = false;
    }
  },
  methods: {
    ...mapMutations('abTest', ['SET_AB_TESTS']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .request({
          url: this.abTestId
            ? `/analytics/ab_tests/${this.abTestId}`
            : '/analytics/ab_tests',
          method: this.abTestId ? 'patch' : 'post',
          data: formData,
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.SET_AB_TESTS(data.ab_tests);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_create": "A/B 테스트 생성",
  "title_edit": "A/B 테스트 수정",
  "period_start_time_must_be_greater_than_now": "시작일은 현재보다 이후여야 합니다.",
  "period_end_time_must_be_greater_than_start_time": "종료일은 시작일보다 이후여야 합니다.",
  "target_ratio_count_must_match": "그룹 비율의 길이가 2여야 합니다.",
  "review_ratio_count_must_match": "그룹 비율의 길이가 리뷰 노출 타입의 개수와 동일해야합니다.",
  "form": {
    "name": "이름",
    "ab_test_type": "종류",
    "start_time": "시작일",
    "end_time": "종료일",
    "group_ratios": {
      "label": "그룹별 비율",
      "placeholder": "그룹의 개수 및 비율을 선택해주세요. (',' 로 구분)"
    },
    "campaign_media_types": "발송 매체",
    "review_ab_test_methods": "리뷰 노출 타입"
  }
}
</i18n>
