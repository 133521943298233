<template>
  <AppAjaxContent :is-loading="!data" class="fit-settings-fit-product-api">
    <AppInfoBox class="FitSettingsFitProductApi__info-box">
      <span>{{ $t('description_segment1') }}</span>
      <AppInternalLink link-style="grey" to="/review/categories">{{
        $t('description_segment2')
      }}</AppInternalLink>
      <span>{{ $t('description_segment3') }}</span>
    </AppInfoBox>
    <AppForm
      v-if="data"
      v-model="fit_settings"
      v-bind="fitFormProps"
      :submit-button="false"
      :event-bus="eventBus"
      @submit="submit"
    >
      <template #group>
        <div class="AppForm__group-field AppForm__group-field--mt12">
          <AppResourceTable
            table-id="fit-settings-product-categories-table"
            :resources="data.product_category_options"
            :columns="columns"
            :rows="productCategories"
            :enable-total-count="true"
            enable-query
            @change-query="setQuery"
            @paginate="paginate"
          >
            <template #cell="{row, column}">
              <AppSelectFilterable
                v-if="column === 'fit_template'"
                name="fit_template_ids[]"
                :options="fitTemplatesOptions"
                :placeholder="$t('select_fit_template')"
                :value="row.fitTemplateId"
                :on-demand-options="true"
                @change="setFitTemplateId(row.id, $event)"
              />
              <template v-else-if="column === 'fit_category'">
                <AppSelect
                  v-if="row.fitCategoriesOptions.length"
                  name="fit_category_ids[]"
                  :placeholder="$t('select_fit_category')"
                  :options="row.fitCategoriesOptions"
                  :value="row.fitCategoryId"
                  @change="setFitCategoryId(row.id, $event)"
                />
                <input v-else type="hidden" name="fit_category_ids[]" />
                <input
                  type="hidden"
                  name="product_category_ids[]"
                  :value="row.id"
                />
              </template>
            </template>
          </AppResourceTable>
        </div>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import FitSettingsView from './FitSettingsView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import api from '@/lib/api';

export default {
  name: 'FitSettingsFitProductApi',
  mixins: [FitSettingsView, ResourceViewNoRoute],
  data() {
    return {
      SETTINGS_ID: 'fit_settings_fit_product_api',
      FIT_SETTINGS_URL: '/fit/settings/fit_product_api',
      eventBus: new Vue(),
      columns: [
        {
          id: 'product_category',
          label: this.$t('table_header.product_category'),
          align: 'left'
        },
        { id: 'fit_template', label: this.$t('table_header.fit_template') },
        { id: 'fit_category', label: this.$t('table_header.fit_category') }
      ],
      query: ''
    };
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoryOptions']),
    groupOptions() {
      return {
        product_category_id_to_template_id_map: { fieldStyle: 'mt12' }
      };
    },
    productCategories() {
      const fit_settings = this.fit_settings;
      const { fit_categories, product_category_options } = this.data;
      const items = product_category_options.items;
      return items.map(category => {
        const label = this.$t('app.product_category_info', [
          category.tree_name,
          category.code,
          category.products_count
        ]);
        let category_id = category.value;
        let fitTemplateId =
          fit_settings.product_category_id_to_template_id_map[category_id];
        if (this.data.fit_templates.every(t => t.id !== fitTemplateId))
          fitTemplateId = undefined;
        let fitCategoryId =
          fit_settings.product_category_id_to_fit_category_id_map[category_id];
        if (fitCategoryId == 0) fitCategoryId = null;

        let fitCategoriesOptions = [];
        if (fitTemplateId) {
          const parentFitCategoryId = this.fitTemplatesMap[fitTemplateId]
            .fit_category_id;
          fitCategoriesOptions = fit_categories
            .filter(c => c.parent_fit_category_id === parentFitCategoryId)
            .map(c => ({ label: c.name, value: c.id }));
        }

        return {
          id: category_id,
          product_category: label,
          fitTemplateId,
          fitCategoryId,
          fitCategoriesOptions
        };
      });
    },
    fitTemplatesMap() {
      return _.keyBy(this.data.fit_templates, 'id');
    },
    fitTemplatesOptions() {
      return this.data.fit_templates.map(t => ({ label: t.name, value: t.id }));
    }
  },
  methods: {
    setFitTemplateId(productCategoryId, fitTemplateId) {
      const map = this.fit_settings.product_category_id_to_template_id_map;
      if (fitTemplateId) {
        this.$set(map, productCategoryId, fitTemplateId);
      } else {
        this.$delete(map, productCategoryId);
        this.$delete(
          this.fit_settings.product_category_id_to_fit_category_id_map,
          productCategoryId
        );
      }

      this.saveCategory();
    },
    setFitCategoryId(productCategoryId, fitCategoryId) {
      const map = this.fit_settings.product_category_id_to_fit_category_id_map;
      if (fitCategoryId) {
        this.$set(map, productCategoryId, fitCategoryId);
      } else {
        this.$delete(map, productCategoryId);
      }

      this.saveCategory();
    },
    saveCategory() {
      this.$nextTick(() => {
        const formData = new FormData(this.$el.getElementsByTagName('form')[0]);
        this.isSubmitting = true;
        api
          .patch('/fit/settings', formData, {
            successMessage: this.$t('app.saved')
          })
          .then(() => {
            this.data.fit_settings = _.cloneDeep(this.fit_settings);
            this.updateFitSettings(this.fit_settings);
          })
          .finally(() => (this.isSubmitting = false));
      });
    },
    setQuery(query) {
      this.query = query;
    },
    submit() {
      this.fetchResource({
        page: 1,
        per: this.data.product_category_options.per
      });
    },
    fetchResource(params) {
      this.isSubmitting = true;
      params['query'] = this.query;
      api
        .get(this.FIT_SETTINGS_URL, { params })
        .then(({ data }) => {
          this.data = data;
          this.fit_settings = _.cloneDeep(data.fit_settings);
          this.query = data.product_category_options.query;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .fit-settings-product-categories-table {
    min-width: 640px;
  }

  .fit-settings-product-categories-table__fit-category {
    min-width: 176px;
  }

  .FitSettingsFitProductApi__info-box {
    margin-bottom: 24px;
  }
}
</style>

<i18n locale="ko">
{
  "description_segment1": "쇼핑몰 카테고리는 ",
  "description_segment2": "[리뷰 / 카테고리/회원등급 / 쇼핑몰 카테고리 관리]",
  "description_segment3": " 메뉴 정보를 기준으로 보여집니다.",
  "table_header": {
    "product_category": "쇼핑몰 카테고리",
    "fit_template": "템플릿",
    "fit_category": "핏 상세 카테고리(종류)"
  },
  "select_fit_template": "템플릿 선택",
  "select_fit_category": "핏 카테고리 선택"
}
</i18n>
