<template>
  <div class="ReviewSettingsWidgetsNormalFormReviewsButtonTitle">
    <AppTextInput
      v-model="widget.reviews_button_title"
      :placeholder="$t('placeholder')"
      :disabled="disabled"
      :invalid="!!error"
      name="widget[reviews_button_title]"
      @blur="blur()"
    />
    <AppFormHint :disabled="disabled">
      <span>
        {{ $t('hint.html') }}
      </span>
      <a
        class="ReviewSettingsWidgetsNormalFormReviewsButtonTitle-link"
        :href="reviewIndexUrlSettingPath"
      >
        {{ $t('hint.cta') }}
      </a>
    </AppFormHint>
    <slot />
    <AppFormError :error="error" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReviewSettingsWidgetsNormalFormReviewsButtonTitle',
  props: {
    widget: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: true
    },
    error: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    reviewIndexUrlSettingPath() {
      return `${this.pathWithBrandParams(
        '/v2/review/settings/widget'
      )}#utilized_url`;
    }
  },
  methods: {
    blur() {
      this.$emit('title-blur');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';
.ReviewSettingsWidgetsNormalFormReviewsButtonTitle-link {
  text-decoration: underline;
}
</style>

<i18n locale="ko">
{
  "placeholder": "전체 리뷰 게시판으로 이동하는 버튼 문구를 입력해주세요.",
  "hint": {
    "html": "전체 리뷰 게시판 URL이 설정되지 않은 경우 버튼이 노출되지 않습니다.",
    "cta": "전체 리뷰 게시판 URL 설정 바로가기"
  }
}
</i18n>
