<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="apiKey.name"
    width="middle"
    @close="close"
  >
    <template #body>
      <AppDataSection :title="$t('api_key_title')">
        <div
          v-if="isShopBuilderKey"
          class="TheSettingsDialogApiKeyInfoDialog__key-hint"
        >
          <pre>{{ $t('shop_builder_api_key_hint') }}</pre>
        </div>
        <div v-else class="TheSettingsDialogApiKeyInfoDialog__key-hint">
          <pre>{{ $t('api_key_hint') }}</pre>
          <AppExternalLink
            class="TheSettingsDialogApiKeyInfoDialog__link"
            to="https://dev.cre.ma/crema-api/authentication?atarget=content"
            >{{ $t('api_key_token_link') }}</AppExternalLink
          >
          <AppExternalLink
            class="TheSettingsDialogApiKeyInfoDialog__link"
            to="https://dev.cre.ma/crema-api/review?atarget=content"
            >{{ $t('api_key_docs') }}</AppExternalLink
          >
        </div>
      </AppDataSection>
      <AppForm
        class="TheSettingsDialogApiKeyInfoDialog__form"
        :sections="sections"
        form-style="narrow"
        section-style="narrow"
      />
    </template>
    <template #foot>
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import UserRole from '@/enums/UserRole';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'TheSettingsDialogApiKeyInfoDialog',
  mixins: [DialogFormView],
  props: {
    apiKey: { type: Object, required: true }
  },
  computed: {
    sections() {
      return [
        {
          id: 'key_info',
          label: this.$t('key_info'),
          hint: this.$t('key_info_hint'),
          groups: ['uid', 'secret'].map(id => ({
            id,
            type: 'copyable_textarea',
            label: this.$t(id),
            value: this.apiKey[id]
          }))
        }
      ];
    },
    isShopBuilderKey() {
      return this.apiKey.role === UserRole.SHOP_BUILDER;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheSettingsDialogApiKeyInfoDialog__key-hint {
  @include text-caption;
  margin-bottom: 4px;
}

.TheSettingsDialogApiKeyInfoDialog__link {
  text-decoration: underline;

  & + & {
    margin-left: 4px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "크리마 API키",
  "issue": "재발급 받기",
  "api_key_title": "안내",
  "api_key_hint": "크리마 API를 사용하기 위해서는 Access Token이 필요합니다.\nAccess Token은 아래 API Key(app id와 secret)을 이용해서 발급 가능합니다.\n아래 API Key정보를 연동할 서비스에 입력하거나 개발자에게 전달해주세요.",
  "shop_builder_api_key_hint": "호스팅사 동기화를 위한 목적으로만 사용합니다.\n필요시 해당키를 복사해 호스팅사 관리자 페이지에 입력합니다.\n해당 키는 최고관리자에게만 노출되며 해당 쇼핑몰의 모든 관리자 페이지에서 공통으로 사용됩니다.\n해당 키의 사용자 이메일은 [api_쇼핑몰Brand Code@cre.ma]로 자동 생성됩니다.",
  "api_key_token_link": "Access Token 발급법",
  "api_key_docs": "크리마 API 안내 문서",
  "key_info": "API 키",
  "key_info_hint": "크리마 API를 사용하기 위한 Access Token발급을 위해 사용합니다.",
  "uid": "App ID",
  "secret": "Secret key",
  "data_section": {
    "title": "크리마 API key",
    "hint": "크리마 데이터 동기화 시 위 API Key를 사용하세요."
  }
}
</i18n>
