import { constructEnum } from '@/lib/enum';

const ProductCategoryFilterType = constructEnum('ProductCategoryFilterType', {
  VIRTUAL: 10,
  MAIN: 20,
  MIDDLE: 30,
  SUB: 40
});

ProductCategoryFilterType.INCLUSIVE = [
  ProductCategoryFilterType.MAIN,
  ProductCategoryFilterType.MIDDLE,
  ProductCategoryFilterType.SUB
];

ProductCategoryFilterType.EXCLUSIVE = [ProductCategoryFilterType.VIRTUAL];

ProductCategoryFilterType.filter = function(categories, types) {
  const inclusiveFilterTypes = ProductCategoryFilterType.INCLUSIVE.filter(t =>
    types.includes(t)
  );
  const exclusiveFilterTypes = ProductCategoryFilterType.EXCLUSIVE.filter(
    t => !types.includes(t)
  );

  return categories
    .filter(
      category =>
        !!inclusiveFilterTypes.find(type => {
          switch (type) {
            case ProductCategoryFilterType.MAIN:
              return category.depth === 1;
            case ProductCategoryFilterType.MIDDLE:
              return category.depth === 2;
            case ProductCategoryFilterType.SUB:
              return category.depth === 3;
            default:
              return false;
          }
        })
    )
    .filter(
      category =>
        !exclusiveFilterTypes.find(type => {
          switch (type) {
            case ProductCategoryFilterType.VIRTUAL:
              return category.virtual;
            default:
              return false;
          }
        })
    );
};

export default ProductCategoryFilterType;
