<template>
  <SlotModalForm
    class="InstallRequestRenewalSlotEtcDialog"
    :title="$t('title')"
    :sub-title="WidgetPageType.t(pageType)"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #group="{ id }">
      <template v-if="id === 'pc_etc'">
        <SlotTable
          :device-type="WidgetDeviceType.PC"
          :slot-type="getInstallSlotType"
        />
      </template>
      <template v-else-if="id === 'mobile_etc'">
        <SlotTable
          :device-type="WidgetDeviceType.MOBILE"
          :slot-type="getInstallSlotType"
        />
      </template>
    </template>
    <template #description="{ id }">
      <div
        v-if="id === 'pc_custom_comment' || id === 'mobile_custom_comment'"
        class="AppForm__description"
      >
        <AppInlineButton
          button-style="caption"
          :label="$t('attachment_guide')"
          @click="openAlertDialog"
        />
      </div>
    </template>
  </SlotModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import SlotModalForm from './components/SlotModalForm';
import WidgetPageType from '@/enums/WidgetPageType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import InstallSlotType from '@/enums/InstallSlotType';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'InstallRequestRenewalSlotEtcDialog',
  components: {
    SlotModalForm
  },
  mixins: [DialogFormView],
  props: {
    pageType: { type: Number, required: true },
    customUrl: { type: String, default: null }
  },
  data() {
    return {
      oldInstallations: []
    };
  },
  computed: {
    ...mapState('installRequest', ['installDeviceTypes']),
    ...mapGetters('installRequest', [
      'currentInstallations',
      'currentDirections'
    ]),
    WidgetPageType() {
      return WidgetPageType;
    },
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    InstallSlotType() {
      return InstallSlotType;
    },
    getInstallSlotType() {
      return this.pageType === WidgetPageType.EVENT
        ? InstallSlotType.EVENT
        : InstallSlotType.CATEGORY;
    },
    formSections() {
      return [
        {
          id: 'custom_url',
          label: null,
          groups: [
            {
              id: 'custom_url',
              label: this.$t('custom_url.label'),
              type: 'text',
              placeholder: this.$t('custom_url.placeholder'),
              validate: ['required']
            }
          ]
        },
        ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
          ? [
              {
                id: 'pc_direction',
                groups: [
                  {
                    id: 'tip',
                    label: this.$t('direction.pc'),
                    type: 'tip_box',
                    value: this.$t('direction.tip'),
                    validate: [{ rule: 'required', value: () => true }]
                  },
                  {
                    id: 'pc_custom_image_url',
                    label: null,
                    type: 'image',
                    notRemovable: true,
                    placeholder: this.$t('direction.placeholder.pc_image_url'),
                    validate: ['required']
                  },
                  {
                    id: 'pc_custom_comment',
                    label: null,
                    type: 'text',
                    placeholder: this.$t('direction.placeholder.comment'),
                    validate: ['required']
                  }
                ]
              }
            ]
          : []),
        ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
          ? [
              {
                id: 'mobile_direction',
                groups: [
                  {
                    id: 'tip',
                    label: this.$t('direction.mobile'),
                    type: 'tip_box',
                    value: this.$t('direction.tip'),
                    validate: [{ rule: 'required', value: () => true }]
                  },
                  {
                    id: 'mobile_custom_image_url',
                    label: null,
                    type: 'image',
                    notRemovable: true,
                    placeholder: this.$t(
                      'direction.placeholder.mobile_image_url'
                    ),
                    validate: ['required']
                  },
                  {
                    id: 'mobile_custom_comment',
                    label: null,
                    type: 'text',
                    placeholder: this.$t('direction.placeholder.comment'),
                    validate: ['required']
                  }
                ]
              }
            ]
          : []),
        {
          id: 'etc_slot',
          label: this.$t('select_widget'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('select_widget_tip')
            },
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
              ? [
                  {
                    id: 'pc_etc',
                    label: this.$t('app.pc')
                  }
                ]
              : []),
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
              ? [
                  {
                    id: 'mobile_etc',
                    label: this.$t('app.mobile')
                  }
                ]
              : [])
          ]
        }
      ];
    },
    isDirectionChanged() {
      return this.installDeviceTypes.some(deviceType => {
        const direction = this.currentDirections.find(
          dir => dir.device_type === deviceType
        );

        if (direction) {
          return (
            this.formObject['custom_url'] !== direction.custom_url ||
            this.formObject[this.customImageUrlKey(deviceType)] !==
              direction.custom_img_url ||
            this.formObject[this.customCommentKey(deviceType)] !==
              direction.custom_comment
          );
        }
        return false;
      });
    },
    isInstallationChanged() {
      const orgSortedInstallations = _.sortBy(this.oldInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const sortedInstallations = _.sortBy(this.currentInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const result = !_.isEqual(orgSortedInstallations, sortedInstallations);
      return result;
    },
    isFormDataChanged() {
      return this.isInstallationChanged || this.isDirectionChanged;
    }
  },
  created() {
    this.SET_CURRENT_PAGE_TYPE(this.pageType);
    this.SET_CURRENT_SLOT_TYPES([this.getInstallSlotType]);
    this.oldInstallations = this.currentInstallations || [];
    this.setDirection();
  },
  methods: {
    ...mapActions('dialog', ['alert']),
    ...mapMutations('installRequest', [
      'SET_CURRENT_PAGE_TYPE',
      'SET_CURRENT_SLOT_TYPES',
      'ADD_INSTALLATIONS',
      'REMOVE_INSTALLATION',
      'APPEND_DIRECTION',
      'REMOVE_CURRENT_SLOT_DIRECTIONS'
    ]),
    customImageUrlKey(deviceType) {
      return `${WidgetDeviceType.key(
        deviceType
      ).toLowerCase()}_custom_image_url`;
    },
    customCommentKey(deviceType) {
      return `${WidgetDeviceType.key(deviceType).toLowerCase()}_custom_comment`;
    },
    openAlertDialog() {
      this.alert({
        title: this.$t('attachment_guide'),
        message: this.$t('alert_message'),
        width: DialogSize.MIDDLE,
        closeButtonStyle: 'default',
        closeButtonLabel: this.$t('app.close')
      });
    },
    beforeCloseDialog() {
      this.currentInstallations.forEach(installation =>
        this.REMOVE_INSTALLATION(installation)
      );
      this.ADD_INSTALLATIONS(this.oldInstallations);
    },
    setDirection() {
      this.installDeviceTypes.forEach(deviceType => {
        const direction = this.currentDirections.find(
          dir => dir.device_type === deviceType
        );

        if (direction) {
          this.$set(this.formObject, 'custom_url', direction.custom_url);
          this.$set(
            this.formObject,
            this.customImageUrlKey(deviceType),
            direction.custom_img_url
          );
          this.$set(
            this.formObject,
            this.customCommentKey(deviceType),
            direction.custom_comment
          );
        }
      });
    },
    appendDirection(deviceType, customUrl, customImgUrl, customComment) {
      if (customUrl) {
        this.APPEND_DIRECTION({
          device_type: deviceType,
          page_type: this.pageType,
          slot: this.getInstallSlotType,
          custom_url: customUrl,
          custom_img_url: customImgUrl,
          custom_comment: customComment
        });
      }
    },
    submit() {
      this.REMOVE_CURRENT_SLOT_DIRECTIONS();
      this.installDeviceTypes.forEach(deviceType => {
        this.appendDirection(
          deviceType,
          this.formObject['custom_url'],
          this.formObject[this.customImageUrlKey(deviceType)],
          this.formObject[this.customCommentKey(deviceType)]
        );
      });
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppForm__section:first-child {
    padding: 0;
  }

  #etc_slot--mobile_etc {
    margin-top: 32px;
  }

  .AppForm__section + .AppForm__section {
    padding-bottom: 0;
  }
}
</style>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "select_widget": "위젯 선택",
  "select_widget_tip": "설치할 위젯을 선택해 주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해주세요.",
  "custom_url":{
    "label": "페이지 URL",
    "placeholder": "페이지 URL을 입력해주세요."
  },
  "attachment_guide": "이미지 첨부 가이드",
  "alert_message": "아래 이미지와 같이 위젯을 설치하고 싶은 위치가 표시된 이미지를 첨부해주세요.<br><br>![](https://assets.cre.ma/m/admin/v2/install_request/attachment_guide_sample@3x.png)",
  "direction" : {
    "pc": "PC 설치 위치",
    "mobile": "MOBILE 설치 위치",
    "tip": "설치와 무관한 내용은 반영되지 않습니다. 설치가 어려운 위치는 설치가 진행되지 않을 수 있습니다.",
    "placeholder": {
      "pc_image_url": "PC 설치 위치 이미지 첨부",
      "mobile_image_url": "MOBILE 설치 위치 이미지 첨부",
      "comment": "크리마 팀이 참고할 코멘트가 있다면 남겨주세요."
    }
  }
}
</i18n>
