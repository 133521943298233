import { constructEnum } from '@/lib/enum';
import ShopBuilder from '@/enums/ShopBuilder';
import LogisticsSolutionType from '@/enums/LogisticsSolutionType';

const DisplayProductFilterType = constructEnum('DisplayProductFilterType', {
  CREATED: 0,
  UPDATED: 10,
  DISPLAYED: 20,
  MOST_CLICKED: 30,
  MOST_PURCHASED: 40,
  HIGHEST_PURCHASED_PRICE: 50,
  MOST_CARTED: 60,
  HIGHEST_PURCHASE_RATE: 70,
  MOST_REVIEWED: 100,
  HIGHEST_REVIEW_SCORE: 110,
  SHOP_BUILDER_STOCK_COUNT: 120,
  LOGISTICS_STOCK_COUNT: 125,
  SOLD_OUT_TO_DISPLAY: 130,
  SELECTED_PRODUCT_OPTION: 140
});

DisplayProductFilterType.stockFilterTypes = [
  DisplayProductFilterType.SHOP_BUILDER_STOCK_COUNT,
  DisplayProductFilterType.LOGISTICS_STOCK_COUNT
];

DisplayProductFilterType.activeTypeOptions = function(
  shopBuilder,
  logisticsSolution
) {
  return this.values
    .filter(
      value =>
        !(
          value === DisplayProductFilterType.LOGISTICS_STOCK_COUNT &&
          logisticsSolution === 'none'
        )
    )
    .map(value => {
      let args = [];
      switch (value) {
        case DisplayProductFilterType.SHOP_BUILDER_STOCK_COUNT:
          args = [ShopBuilder.tk(shopBuilder)];
          break;
        case DisplayProductFilterType.LOGISTICS_STOCK_COUNT:
          args = [LogisticsSolutionType.tk(logisticsSolution)];
          break;
      }

      return { value, label: DisplayProductFilterType.t(value, args) };
    });
};

DisplayProductFilterType.isStockFilterType = function(filterType) {
  return this.stockFilterTypes.includes(filterType);
};

DisplayProductFilterType.isProductOptionFilterType = function(filterType) {
  return filterType === DisplayProductFilterType.SELECTED_PRODUCT_OPTION;
};

export default DisplayProductFilterType;
