<template>
  <div class="TheContentAlerts">
    <TransitionGroup name="slide-up" tag="div">
      <div
        v-for="alert in alerts"
        :key="alert.id"
        class="TheContentAlerts__item-wrapper"
      >
        <div class="TheContentAlerts__item-margin" />
        <div
          :class="[
            'TheContentAlerts__item',
            `TheContentAlerts__item--${alert.color}`
          ]"
        >
          <span class="TheContentAlerts__message">{{
            $t(`${alert.type}.message`, alert.messageArgs)
          }}</span>
          <Component
            :is="alert.to ? 'AppInternalLink' : 'a'"
            v-if="alert.clickHandler || alert.to"
            :to="alert.to"
            class="TheContentAlerts__link"
            @click="actAndSnooze(alert)"
            >{{ $t(`${alert.type}.link`) }}</Component
          >
          <button
            v-tooltip="alert.skipSnooze ? null : $t('close_button_tooltip')"
            class="TheContentAlerts__close-button"
            @click="closeAndSnooze(alert)"
          >
            <AppSvgIcon name="icon-close-small" />
          </button>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import api from '@/lib/api';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import TargetWidgetRecommendationFilterServiceType from '@/enums/TargetWidgetRecommendationFilterServiceType';

export default {
  name: 'TheContentAlerts',
  data: () => ({ closedAlerts: {} }),
  computed: {
    ...mapState('session', ['currentUser', 'targetStatus', 'targetSettings']),
    ...mapState('review', ['keywordGroups']),
    ...mapGetters('alert', [
      'isAlertSnoozed',
      'reviewRemainingDates',
      'reviewSmsRemainingDates',
      'targetRemainingDates',
      'fitRemainingDates',
      'displayRemainingDates',
      'channelRemainingDates',
      'insightRemainingDates'
    ]),
    ...mapGetters('session', [
      'isTargetTesting',
      'isTargetFreeTrialOperating',
      'isDisplayTesting',
      'isDisplayFreeTrial',
      'isChannelTesting',
      'isChannelFreeTrial',
      'isInsightTesting',
      'isInsightFreeTrial',
      'isReviewTerminated'
    ]),
    ...mapGetters('menu', ['selectedMainMenuItemId', 'selectedSubMenuItemId']),
    alerts() {
      return [
        this.unreadNoticesAlert,
        ...this.unreadReviewsAlerts,
        this.reviewExpirationRemindingAlert,
        this.reviewExpiredSoonAlert,
        this.targetFreeTrialExpirationRemindingAlert,
        this.targetExpirationRemindingAlert,
        this.targetExpiredAlert,
        this.fitExpirationRemindingAlert,
        this.fitExpiredSoonAlert,
        this.reviewSmsRemindingAlert,
        this.testingTargetAlert,
        this.targetDispatchDisabledAlert,
        this.targetCampaignDisabledAlert,
        ...this.widgetRecommendationDisabledCampaignsAlerts,
        this.displayTestingAlert,
        this.displayExpirationAlert,
        this.displayFreeTrialAlert,
        this.channelTestingAlert,
        this.channelExpirationAlert,
        this.channelFreeTrialAlert,
        this.insightTestingAlert,
        this.insightExpirationAlert,
        this.insightFreeTrialAlert
      ]
        .filter(Boolean)
        .filter(({ id }) => !this.isAlertSnoozed(id));
    },
    unreadNoticesAlert() {
      const { unread_notices_count } = this.currentUser;
      if (unread_notices_count < 10) return null;

      return {
        id: 'unread_notices',
        type: 'unread_notices',
        color: 'blue',
        messageArgs: [unread_notices_count],
        clickHandler: () => this.openDialog('TheNoticesDialog')
      };
    },
    unreadReviewsAlerts() {
      return this.keywordGroups
        .filter(
          g =>
            g.enable_notification &&
            g.unread_reviews_count > 0 &&
            !this.closedAlerts[`unread_reviews_${g.id}`]
        )
        .map(({ id, name, unread_reviews_count }) => ({
          id: `unread_reviews_${id}`,
          type: 'unread_reviews',
          color: 'mango',
          messageArgs: { name, count: unread_reviews_count },
          to: `/review/reviews?keyword_group_id=${id}`,
          skipSnooze: true
        }));
    },
    reviewExpirationRemindingAlert() {
      if (this.selectedMainMenuItemId !== 'review') return null;

      const dates = this.reviewRemainingDates;
      if (dates === null || dates < 1 || dates > 20) return null;

      return {
        id: 'review_expiration_reminding',
        type: 'review_expiration_reminding',
        color: 'flamingo',
        messageArgs: [dates],
        clickHandler: () =>
          this.openDialog([
            'ThePaymentDialog',
            { activeTabId: 'review_packages' }
          ])
      };
    },
    reviewExpiredSoonAlert() {
      if (this.selectedMainMenuItemId !== 'review') return null;

      const dates = this.reviewRemainingDates;
      if (dates !== 0) return null;

      return {
        id: 'review_expired_soon',
        type: 'review_expired_soon',
        color: 'flamingo'
      };
    },
    reviewSmsRemindingAlert() {
      if (this.selectedMainMenuItemId !== 'review') return null;

      const dates = this.reviewSmsRemainingDates;
      if (dates === null || dates < 1 || dates > 7) return null;

      return {
        id: 'review_sms_reminding',
        type: 'review_sms_reminding',
        color: 'flamingo',
        messageArgs: [dates],
        clickHandler: () =>
          this.openDialog([
            'ThePaymentDialog',
            { activeTabId: 'review_sms_packages' }
          ])
      };
    },
    targetFreeTrialExpirationRemindingAlert() {
      if (
        !(
          this.selectedMainMenuItemId === 'target' &&
          this.isTargetFreeTrialOperating &&
          this.targetRemainingDates >= 0
        )
      )
        return null;

      let reminding_type;
      if (this.targetRemainingDates > 5) {
        reminding_type = 'target_freetrial_expiration_reminding';
      } else if (this.targetRemainingDates > 0) {
        reminding_type = 'target_freetrial_expiration_reminding_last_5days';
      } else {
        reminding_type = 'target_freetrial_expiration_reminding_last_day';
      }

      return {
        id: 'target_freetrial_expiration_reminding',
        type: reminding_type,
        color: 'blue',
        messageArgs: [this.targetRemainingDates]
      };
    },
    targetExpirationRemindingAlert() {
      if (
        this.selectedMainMenuItemId !== 'target' ||
        this.isTargetFreeTrialOperating
      )
        return null;

      const dates = this.targetRemainingDates;
      if (dates === null || dates < 1 || dates > 15) return null;

      return {
        id: 'target_expiration_reminding',
        type: 'target_expiration_reminding',
        color: 'flamingo',
        messageArgs: [dates],
        clickHandler: () =>
          this.openDialog([
            'ThePaymentDialog',
            { activeTabId: 'target_packages' }
          ])
      };
    },
    targetExpiredAlert() {
      if (
        this.selectedMainMenuItemId !== 'target' ||
        this.isTargetFreeTrialOperating
      )
        return null;

      const dates = this.targetRemainingDates;
      if (dates === null || dates > 0) return null;

      if (dates === 0)
        return {
          id: 'target_expired_soon',
          type: 'target_expired_soon',
          color: 'flamingo'
        };
      else
        return {
          id: 'target_expired',
          type: 'target_expired',
          color: 'flamingo'
        };
    },
    fitExpirationRemindingAlert() {
      if (this.selectedMainMenuItemId !== 'fit') return null;

      const dates = this.fitRemainingDates;
      if (dates === null || dates < 1 || dates > 15) return null;

      return {
        id: 'fit_expiration_reminding',
        type: 'fit_expiration_reminding',
        color: 'flamingo',
        messageArgs: [dates],
        clickHandler: () =>
          this.openDialog(['ThePaymentDialog', { activeTabId: 'fit_packages' }])
      };
    },
    fitExpiredSoonAlert() {
      if (this.selectedMainMenuItemId !== 'fit') return null;

      const dates = this.fitRemainingDates;
      if (dates !== 0) return null;

      return {
        id: 'fit_expired_soon',
        type: 'fit_expired_soon',
        color: 'flamingo'
      };
    },
    targetCampaignDisabledAlert() {
      if (this.selectedMainMenuItemId !== 'target' || !this.isReviewTerminated)
        return null;

      const campaignIds = this.targetSettings
        .disabled_campaign_ids_by_review_status;
      if (!campaignIds.length) return null;

      return {
        id: 'review_highlight_campaigns_disabled',
        type: 'review_highlight_campaigns_disabled',
        color: 'mango',
        messageArgs: [campaignIds.join(', ')]
      };
    },
    widgetRecommendationDisabledCampaignsAlerts() {
      if (this.selectedMainMenuItemId !== 'target' || !this.targetStatus)
        return [];
      const disabledCampaignIdsByService = {
        [TargetWidgetRecommendationFilterServiceType.FIT]: [],
        [TargetWidgetRecommendationFilterServiceType.REVIEW]: [],
        [TargetWidgetRecommendationFilterServiceType.REVIEW_ANALYSIS]: []
      };
      const campaigns = this.targetStatus
        .widget_recommendation_unit_disabled_campaigns;
      campaigns.forEach(({ campaign_id, disabled_recommendation_units }) => {
        disabled_recommendation_units.forEach(unit => {
          disabledCampaignIdsByService[unit.disabled_service_type] &&
            disabledCampaignIdsByService[unit.disabled_service_type].push(
              campaign_id
            );
        });
      });

      return Object.keys(disabledCampaignIdsByService)
        .map(serviceType => {
          const disabledCampaignIds = disabledCampaignIdsByService[serviceType];
          if (!disabledCampaignIds.length) return null;

          return {
            id: `recommendation_unit_disabled_campaigns_${TargetWidgetRecommendationFilterServiceType.key(
              serviceType
            )}`,
            type: `recommendation_unit_disabled_campaigns.${TargetWidgetRecommendationFilterServiceType.key(
              serviceType
            )}`,
            color: 'mango',
            messageArgs: [[...new Set(disabledCampaignIds)]]
          };
        })
        .filter(v => v);
    },
    testingTargetAlert() {
      if (
        this.selectedMainMenuItemId !== 'target' ||
        !this.targetStatus ||
        !this.isTargetTesting
      )
        return null;

      return {
        id: 'testing_target',
        type: 'testing_target',
        color: 'blue',
        skipSnooze: true,
        clickHandler: () => this.openDialog('TargetStatusUpdateDialog')
      };
    },
    targetDispatchDisabledAlert() {
      if (
        this.selectedMainMenuItemId !== 'target' ||
        this.targetSettings.enable_dispatch ||
        !this.targetStatus
      )
        return null;

      return {
        id: 'target_dispatch_disabled',
        type: 'target_dispatch_disabled',
        color: 'mango',
        clickHandler: () =>
          api
            .patch(
              '/target/dispatch_settings/enable_dispatch',
              {},
              { successMessage: this.$t('target_dispatch_resumed') }
            )
            .then(() => this.SET_TARGET_SETTINGS_ENABLE_DISPATCH(true))
      };
    },
    displayTestingAlert() {
      if (this.selectedMainMenuItemId !== 'display' || !this.isDisplayTesting)
        return null;

      return { id: 'display_testing', type: 'display_testing', color: 'blue' };
    },
    displayExpirationAlert() {
      if (
        this.selectedMainMenuItemId !== 'display' ||
        this.isDisplayFreeTrial ||
        this.isDisplayTesting
      )
        return null;

      const dates = this.displayRemainingDates;
      if (dates === null || dates > 15) return null;

      if (dates < 0)
        return {
          id: 'display_expired',
          type: 'display_expired',
          color: 'flamingo'
        };
      else if (dates == 0)
        return {
          id: 'display_expired_soon',
          type: 'display_expired_soon',
          color: 'flamingo'
        };
      else
        return {
          id: 'display_expiration_reminding',
          type: 'display_expiration_reminding',
          messageArgs: [dates],
          color: 'blue'
        };
    },
    displayFreeTrialAlert() {
      if (this.selectedMainMenuItemId !== 'display' || !this.isDisplayFreeTrial)
        return null;

      const dates = this.displayRemainingDates;

      if (dates === null || dates > 5)
        return {
          id: 'display_free_trial_operating',
          type: 'display_free_trial_operating',
          color: 'blue'
        };
      else if (dates > 0)
        return {
          id: 'display_free_trial_expiration_reminding',
          type: 'display_free_trial_expiration_reminding',
          messageArgs: [dates],
          color: 'blue'
        };
      else if (dates == 0)
        return {
          id: 'display_free_trial_expired_soon',
          type: 'display_free_trial_expired_soon',
          color: 'flamingo'
        };
      else
        return {
          id: 'display_free_trial_expired',
          type: 'display_free_trial_expired',
          color: 'flamingo'
        };
    },
    channelTestingAlert() {
      if (this.selectedMainMenuItemId !== 'channel' || !this.isChannelTesting)
        return null;

      return { id: 'channel_testing', type: 'channel_testing', color: 'blue' };
    },
    channelExpirationAlert() {
      if (
        this.selectedMainMenuItemId !== 'channel' ||
        this.isChannelFreeTrial ||
        this.isChannelTesting
      )
        return null;

      const dates = this.channelRemainingDates;
      if (dates === null || dates > 15) return null;

      if (dates < 0)
        return {
          id: 'channel_expired',
          type: 'channel_expired',
          color: 'flamingo'
        };
      else if (dates == 0)
        return {
          id: 'channel_expired_soon',
          type: 'channel_expired_soon',
          color: 'flamingo'
        };
      else
        return {
          id: 'channel_expiration_reminding',
          type: 'channel_expiration_reminding',
          messageArgs: [dates],
          color: 'blue'
        };
    },
    channelFreeTrialAlert() {
      if (this.selectedMainMenuItemId !== 'channel' || !this.isChannelFreeTrial)
        return null;

      const dates = this.channelRemainingDates;

      if (dates === null || dates > 5)
        return {
          id: 'channel_free_trial_operating',
          type: 'channel_free_trial_operating',
          color: 'blue'
        };
      else if (dates > 0)
        return {
          id: 'channel_free_trial_expiration_reminding',
          type: 'channel_free_trial_expiration_reminding',
          messageArgs: [dates],
          color: 'blue'
        };
      else if (dates == 0)
        return {
          id: 'channel_free_trial_expired_soon',
          type: 'channel_free_trial_expired_soon',
          color: 'flamingo'
        };
      else
        return {
          id: 'channel_free_trial_expired',
          type: 'channel_free_trial_expired',
          color: 'flamingo'
        };
    },
    isSelectedMenuItemInsight() {
      return (
        this.selectedMainMenuItemId === 'analytics' &&
        this.selectedSubMenuItemId.includes('analytics_insight')
      );
    },
    insightTestingAlert() {
      if (!this.isSelectedMenuItemInsight || !this.isInsightTesting)
        return null;

      return { id: 'insight_testing', type: 'insight_testing', color: 'blue' };
    },
    insightExpirationAlert() {
      if (
        !this.isSelectedMenuItemInsight ||
        this.isInsightFreeTrial ||
        this.isInsightTesting
      )
        return null;

      const dates = this.insightRemainingDates;
      if (dates === null || dates > 15) return null;

      if (dates < 0)
        return {
          id: 'insight_expired',
          type: 'insight_expired',
          color: 'flamingo'
        };
      else if (dates == 0)
        return {
          id: 'insight_expired_soon',
          type: 'insight_expired_soon',
          color: 'flamingo'
        };
      else
        return {
          id: 'insight_expiration_reminding',
          type: 'insight_expiration_reminding',
          messageArgs: [dates],
          color: 'blue'
        };
    },
    insightFreeTrialAlert() {
      if (!this.isSelectedMenuItemInsight || !this.isInsightFreeTrial)
        return null;

      const dates = this.insightRemainingDates;

      if (dates === null || dates > 5)
        return {
          id: 'insight_free_trial_operating',
          type: 'insight_free_trial_operating',
          color: 'blue'
        };
      else if (dates > 0)
        return {
          id: 'insight_free_trial_expiration_reminding',
          type: 'insight_free_trial_expiration_reminding',
          messageArgs: [dates],
          color: 'blue'
        };
      else if (dates == 0)
        return {
          id: 'insight_free_trial_expired_soon',
          type: 'insight_free_trial_expired_soon',
          color: 'flamingo'
        };
      else
        return {
          id: 'insight_free_trial_expired',
          type: 'insight_free_trial_expired',
          color: 'flamingo'
        };
    }
  },
  watch: {
    selectedMainMenuItemId() {
      this.$nextTick(() => (this.closedAlerts = {}));
    }
  },
  methods: {
    ...mapMutations('session', ['SET_TARGET_SETTINGS_ENABLE_DISPATCH']),
    ...mapActions('alert', ['snoozeAlert']),
    ...mapActions('dialog', ['openDialog']),
    actAndSnooze(alert) {
      if (alert.clickHandler) alert.clickHandler();
      this.closeAndSnooze(alert);
    },
    closeAndSnooze({ id, skipSnooze }) {
      if (skipSnooze) this.$set(this.closedAlerts, id, true);
      else this.snoozeAlert(id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_transitions.scss';

$height: 32px;
$margin: 8px;

.TheContentAlerts__item-wrapper {
  height: $height + $margin;
  overflow: hidden;
}

.TheContentAlerts__item-margin {
  height: $margin;
}

.TheContentAlerts__item {
  position: relative;
  line-height: $height;
  height: $height;
  text-align: center;
  border-radius: 6px;
  color: white;

  &--blue {
    background: $color-blue-darker;
  }

  &--mango {
    background: $color-mango-text;
  }

  &--flamingo {
    background: $color-flamingo;
  }
}

.TheContentAlerts__message {
  font-size: 12px;
  font-weight: bold;
}

.TheContentAlerts__link {
  margin-left: 12px;
  font-size: 11px;
  text-decoration: underline;
}

.TheContentAlerts__close-button {
  @include transition-basic(opacity);

  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 15px;
  line-height: 0;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    opacity: 1;
  }

  40% {
    height: $height + $margin;
    opacity: 0;
  }

  100% {
    opacity: 0;
    height: 0;
  }
}

.slide-up-leave-active {
  animation: slide-up $transition-duration-slow forwards;
}
</style>

<i18n locale="ko">
{
  "unread_notices": {
    "message": "확인하지 않은 공지사항이 {0}개 있습니다.",
    "link": "공지사항 보기"
  },
  "unread_reviews": {
    "message": "특정검색어 '{name}' 의 리뷰가 {count}건 등록되었습니다.",
    "link": "리뷰 보기"
  },
  "review_expiration_reminding": {
    "message": "리뷰 서비스 만료일까지 {0}일 남았습니다.",
    "link": "리뷰 결제"
  },
  "review_expired_soon": {
    "message": "리뷰 서비스 사용이 오늘 23시 59분 59초에 만료됩니다."
  },
  "target_expiration_reminding": {
    "message": "타겟 서비스 만료일까지 {0}일 남았습니다.",
    "link": "타겟 결제"
  },
  "target_freetrial_expiration_reminding": {
    "message": "타겟 무료 체험 중입니다."
  },
  "target_freetrial_expiration_reminding_last_5days": {
    "message": "타겟 무료체험 {0}일 남았습니다."
  },
  "target_freetrial_expiration_reminding_last_day": {
    "message": "타겟 무료체험이 오늘 23시 59분 59초에 만료됩니다."
  },
  "target_expired": {
    "message": "타겟 서비스 사용이 만료되어 모든 캠페인의 발송 및 노출이 중지 되었습니다."
  },
  "target_expired_soon": {
    "message": "타겟 서비스 사용이 오늘 23시 59분 59초에 만료됩니다."
  },
  "fit_expiration_reminding": {
    "message": "핏 서비스 만료일까지 {0}일 남았습니다.",
    "link": "핏 결제"
  },
  "fit_expired_soon": {
    "message": "핏 서비스 사용이 오늘 23시 59분 59초에 만료됩니다."
  },
  "review_sms_reminding": {
    "message": "리뷰 문자(알림톡) 예상 소진일까지 {0}일 남았습니다.",
    "link": "리뷰 문자(알림톡) 충전"
  },
  "testing_target": {
    "message": "타겟 테스트 중입니다. 설정이 완료되어 타겟 사용 준비가 되시면 타겟 사용 버튼을 눌러 주세요.",
    "link": "타겟 사용"
  },
  "target_dispatch_disabled": {
    "message": "모든 캠페인이 일시정지 중입니다.",
    "link": "발송 재개"
  },
  "close_button_tooltip": "오늘 하루 보지 않기",
  "target_dispatch_resumed": "캠페인 발송 및 노출을 재개했습니다.",
  "review_highlight_campaigns_disabled": {
    "message": "리뷰 서비스 사용 종료로 인해 리뷰 강조 추천 캠페인(캠페인 ID {0})은 노출 중단됩니다."
  },
  "recommendation_unit_disabled_campaigns": {
    "FIT": {
      "message": "핏 서비스 사용 종료로 인해 캠페인 ID {0}에서 사용 중인 '핏 데이터를 활용한 상품 추천 항목'은 노출 중단됩니다."
    },
    "REVIEW": {
      "message": "리뷰 서비스 사용 종료로 인해 캠페인 ID {0}에서 사용 중인 '리뷰 데이터를 활용한 상품 추천 항목'은 노출 중단됩니다."
    },
    "REVIEW_ANALYSIS": {
      "message": "리뷰 키워드 분석 설정 적용 종료로 인해 캠페인 ID {0}에서 사용 중인 'AI 리뷰 분석 데이터를 활용한 상품 추천 항목'은 노출 중단됩니다."
    }
  },
  "display_testing": {
    "message": "진열 서비스 테스트 중입니다. 모든 기능이 동작하나 쇼핑몰에 실제 적용되지 않습니다."
  },
  "display_expired": {
    "message": "진열 서비스 사용이 만료되어 모든 진열, 상품관리가 중단 되었습니다. 재결제는 크리마 진열 운영팀으로 문의해 주세요."
  },
  "display_expired_soon": {
    "message": "진열 서비스가 오늘 23시 59분 59초에 만료됩니다."
  },
  "display_expiration_reminding": {
    "message": "진열 서비스 만료일까지 {0}일 남았습니다."
  },
  "display_free_trial_expired": {
    "message": "진열 서비스 무료체험이 만료되어 모든 진열, 상품관리가 중단 되었습니다."
  },
  "display_free_trial_expired_soon": {
    "message": "진열 서비스 무료체험이 오늘 23시 59분 59초에 만료됩니다."
  },
  "display_free_trial_expiration_reminding": {
    "message": "진열 서비스 무료체험이 {0}일 남았습니다."
  },
  "display_free_trial_operating": {
    "message": "진열 서비스 무료체험 중입니다."
  },
  "channel_testing": {
    "message": "애드 서비스 테스트 중입니다."
  },
  "channel_expired": {
    "message": "애드 서비스 사용이 만료되어 모든 관리가 중단 되었습니다. 재결제는 크리마 애드 운영팀으로 문의해 주세요."
  },
  "channel_expired_soon": {
    "message": "애드 서비스가 오늘 23시 59분 59초에 만료됩니다."
  },
  "channel_expiration_reminding": {
    "message": "애드 서비스 만료일까지 {0}일 남았습니다."
  },
  "channel_free_trial_expired": {
    "message": "애드 서비스 무료체험이 만료되어 모든 관리가 중단 되었습니다."
  },
  "channel_free_trial_expired_soon": {
    "message": "애드 서비스 무료체험이 오늘 23시 59분 59초에 만료됩니다."
  },
  "channel_free_trial_expiration_reminding": {
    "message": "애드 서비스 무료체험이 {0}일 남았습니다."
  },
  "channel_free_trial_operating": {
    "message": "애드 서비스 무료체험 중입니다."
  },
  "insight_testing": {
    "message": "인사이트 서비스 테스트 중입니다."
  },
  "insight_expired": {
    "message": "인사이트 서비스 사용이 만료되어 서비스 접근이 차단될 예정입니다. 재결제는 크리마 인사이트 운영팀으로 문의해 주세요."
  },
  "insight_expired_soon": {
    "message": "인사이트 서비스가 오늘 23시 59분 59초에 만료됩니다."
  },
  "insight_expiration_reminding": {
    "message": "인사이트 서비스 만료일까지 {0}일 남았습니다."
  },
  "insight_free_trial_expired": {
    "message": "인사이트 서비스 무료체험이 만료되어 서비스 접근이 차단될 예정입니다."
  },
  "insight_free_trial_expired_soon": {
    "message": "인사이트 서비스 무료체험이 오늘 23시 59분 59초에 만료됩니다."
  },
  "insight_free_trial_expiration_reminding": {
    "message": "인사이트 서비스 무료체험이 {0}일 남았습니다."
  },
  "insight_free_trial_operating": {
    "message": "인사이트 서비스 무료체험 중입니다."
  }
}
</i18n>
