<template>
  <div>
    <AppInfoBox class="AnalyticsReviewKeywords__info-box">
      {{ $t('info_message') }}
    </AppInfoBox>
    <AppIframeViewer
      :src="brandSettings.review_keywords_bi_url"
      :class="[
        'AnalyticsReviewKeywords__iframe',
        {
          'AnalyticsReviewKeywords__iframe--beauty':
            currentBrand.is_beauty_mall,
          'AnalyticsReviewKeywords__iframe--fashion':
            currentBrand.is_fashion_mall
        }
      ]"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AnalyticsReviewKeywords',
  computed: {
    ...mapState('session', ['brandSettings', 'currentBrand'])
  }
};
</script>

<style lang="scss" scoped>
.AnalyticsReviewKeywords__info-box {
  margin-bottom: 20px;
}

.AnalyticsReviewKeywords__iframe {
  padding-top: 62%;

  &--beauty {
    padding-top: 59%;
  }

  &--fashion {
    padding-top: 68%;
  }
}

::v-deep {
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
</style>

<i18n locale="ko">
{
  "info_message": "대시보드 하단에서 페이지를 이동할 수 있습니다."
}
</i18n>
