<template>
  <div
    v-if="videos.length || review.images.length"
    class="table-line AppReviewMediaCell"
  >
    <AppVideoImage
      v-for="(video, i) in videos"
      :key="`video${i}`"
      v-tooltip="
        video.is_being_encoded
          ? videoThumbnailTooltip(video)
          : videoPreviewTooltip(video)
      "
      class="AppReviewMediaCell__image"
      background
      :show-play-button="!video.is_being_encoded"
      :src="video.thumbnail_url"
    />
    <AppImage
      v-for="(image, i) in review.images"
      :key="`image${i}`"
      v-tooltip="imageTooltip(image)"
      class="AppReviewMediaCell__image"
      :src="image.thumbnail_url"
    />
  </div>
</template>

<script>
export default {
  name: 'AppReviewMediaCell',
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  computed: {
    videos() {
      return this.review.videos;
    }
  },
  methods: {
    imageTooltip(image) {
      return `<div class="tooltip__image"><img src="${image.url}" class="ReviewReviewsReviewCell__image-tooltip-image" /></div>`;
    },
    videoThumbnailTooltip(video) {
      return `<div class="tooltip__image"><img src="${video.thumbnail_url}" class="ReviewReviewsReviewCell__image-tooltip-image" /></div>`;
    },
    videoPreviewTooltip(video) {
      return `<div class="tooltip__video">
        <video class="ReviewReviewsReviewCell__video-tooltip" playsinline autoplay loop muted>
          <source src="${video.preview_url}" type="video/mp4">
        </video>
      </div>`;
    }
  }
};
</script>

<style lang="scss" scoped>
.AppReviewMediaCell {
  display: block;
  line-height: 0;
  margin-top: 4px;
}

.AppReviewMediaCell__image {
  vertical-align: middle;
  line-height: 52px;
  width: 52px;
  height: 52px;

  & + & {
    margin-left: 4px;
  }
}
</style>

<style>
.AppReviewMediaCell__image-tooltip-image,
.AppReviewMediaCell__video-tooltip {
  width: 200px;
}

.AppReviewMediaCell__image-tooltip-screen {
  width: 100%;
  height: 92%;
  transform: translate(0, -100%);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
}

.AppReviewMediaCell__image-tooltip-svg {
  fill: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
