<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm :object-id="objectId" v-bind="formProps" v-on="formEvents" />
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import ShopBuilder from '@/enums/ShopBuilder';
import FormView from '@/mixins/FormView';

export default {
  name: 'ReviewMoveShopBuilderReview',
  mixins: [FormView],
  data() {
    return {
      isLoading: true,
      isConcatCodeJobRunning: false,
      orgFormObject: {
        prev_shop_builder: ShopBuilder.CAFE24
      },
      detectFormDataChange: false
    };
  },
  computed: {
    objectId() {
      return 'move_shop_builder_review';
    },
    formSections() {
      return [
        {
          id: 'move_shop_builder_review',
          label: null,
          groups: [
            {
              id: 'prev_shop_builder',
              type: 'select',
              options: ShopBuilder.options(),
              label: this.$t('prev_shop_builder.label'),
              groupDescription: this.$t('prev_shop_builder.description')
            },
            {
              id: 'execute',
              label: null,
              type: 'button',
              disabled: this.isConcatCodeJobRunning,
              buttonTooltip: this.isConcatCodeJobRunning
                ? this.$t('move_shop_builder_review.running')
                : null,
              value: this.$t('move_shop_builder_review.execute'),
              buttonStyle: 'blue',
              clickHandler: this.concatCodeWithPrefix
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get(
        '/review/move_shop_builder/concat_code_with_prefix_job_is_in_progress'
      )
      .then(({ data }) => {
        this.isConcatCodeJobRunning =
          data.concat_code_with_prefix_job_is_in_progress;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    concatCodeWithPrefix() {
      const formData = new FormData();
      formData.append(
        'move_shop_builder_review[prev_shop_builder]',
        this.formObject.prev_shop_builder
      );
      api
        .post('/review/move_shop_builder/concat_code_with_prefix', formData)
        .then(() => (this.isConcatCodeJobRunning = true));
    }
  }
};
</script>

<i18n locale="ko">
{
  "prev_shop_builder": {
    "label": "이전 사용 호스팅사 선택",
    "description": "이전하기 전 호스팅사의 리뷰번호에 prefix를 붙여 리뷰를 구분할 수 있도록 처리합니다."
  },
  "move_shop_builder_review": {
    "execute": "실행",
    "running": "리뷰 번호 변환 job이 진행 중입니다."
  }
}
</i18n>
