var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'AppMultipleSelectTable',
    { 'AppMultipleSelectTable--disabled': _vm.disabled }
  ]},[_c('div',{staticClass:"table-head-control"},[_c('AppButton',{attrs:{"label":_vm.selectButtonLabel,"disabled":_vm.disabled},on:{"click":_vm.openItemSelectDialog}})],1),_c('AppTable',_vm._b({attrs:{"table-id":"multiple-select-table","columns":[
      { id: 'position', label: _vm.$t('app.position'), width: '40px' } ].concat( _vm.columns,
      [{ id: 'actions', label: _vm.$t('app.actions') }]
    )},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
    var column = ref.column;
    var rowIndex = ref.rowIndex;
return [(column.id === 'actions')?[_c('AppButtonDraggable',{attrs:{"disabled":_vm.disabled,"drag-text":_vm.rows[rowIndex].message},on:{"sort":_vm.sort}}),_c('AppButton',{attrs:{"button-style":"red-outline","label":_vm.$t('app.delete'),"disabled":_vm.disabled},on:{"click":function($event){return _vm.remove(rowIndex)}}})]:_vm._e()]}}])},'AppTable',{ rows: _vm.rows, noData: _vm.noData },false)),(_vm.name)?_c('AppHiddenInputArray',{attrs:{"name":_vm.name,"values":_vm.items.map(function (i) { return i.id; })}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }