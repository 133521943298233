<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppResourceTable
      table-id="review-option-type-sets-table"
      :columns="COLUMNS"
      :rows="rows"
      :no-data="$t('no_data')"
      enable-query
      @change-query="setQuery"
    >
      <template #cell="{ column, value, rowIndex }">
        <template v-if="column === 'type'">
          <AppBadge
            :badge-style="
              value === ReviewOptionType.EVALUATION
                ? 'blue-outline'
                : 'mint-green-outline'
            "
            :label="ReviewOptionType.t(value)"
          />
        </template>
        <template v-else-if="column === 'action'">
          <AppButton
            :label="$t('edit_button')"
            @click="editBrandReviewOption(rowIndex)"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapActions } from 'vuex';
import { filterItems } from '@/lib/text-search';
import api from '@/lib/api';
import ResourceView from '@/mixins/ResourceView';
import { nullResources } from '@/lib/resources';
import ReviewOptionType from '@/enums/ReviewOptionType';

export default {
  name: 'ReviewSettingsReviewOptionTypeSets',
  mixins: [ResourceView],
  data() {
    return {
      COLUMNS: ['name', 'type', 'options', 'action'].map(c => ({
        id: c,
        label: this.$t(`table_header.${c}`)
      })),
      isLoading: true,
      resources: nullResources,
      query: ''
    };
  },
  computed: {
    ReviewOptionType() {
      return ReviewOptionType;
    },
    filteredBrandReviewOptions() {
      const nameFilteredIds = filterItems(
        this.resources.items,
        'name',
        this.query
      ).map(i => i.id);
      const optionsFilteredIds = filterItems(
        this.resources.items,
        'options',
        this.query
      ).map(i => i.id);

      const filteredIdsSet = new Set(
        nameFilteredIds.concat(optionsFilteredIds)
      );
      return this.resources.items.filter(i => filteredIdsSet.has(i.id));
    },
    rows() {
      return this.filteredBrandReviewOptions;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    setQuery(query) {
      this.query = query;
    },
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/review/option_type_set/options', { params })
        .then(({ data }) => (this.resources = data.option_type_sets))
        .finally(() => (this.isLoading = false));
    },
    editBrandReviewOption(rowIndex) {
      this.openDialog([
        'ReviewSettingsEditOptionTypeSetOptionsDialog',
        {
          type: this.rows[rowIndex].type,
          id: this.rows[rowIndex].id
        }
      ]).then(eventBus => eventBus.$on('save', this.refreshResource));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .review-option-type-sets-table__name,
  .review-option-type-sets-table__type {
    flex: 1 0 250px;
  }

  .review-option-type-sets-table__options {
    flex: 2 0 380px;
  }

  .review-option-type-sets-table__action {
    flex: 1 0 162px;
  }
}
</style>

<i18n locale="ko">
{
  "table_header": {
    "name": "추가 정보 항목명",
    "type": "항목 분류",
    "options": "선택 항목 문구",
    "action": "기능"
  },
  "edit_button": "문구 수정",
  "no_data": "설정된 추가 정보가 없습니다."
}
</i18n>
