<template>
  <AppAjaxContent :is-loading="isLoading">
    <ReviewMessagesSearchbar :search-types="searchTypes" v-on="searchBarEvents">
      <li v-if="isReviewCampaignEnabled">
        <AppSelect
          :options="campaignTypeOptions"
          @change="changeCampaignType"
        />
      </li>
    </ReviewMessagesSearchbar>
    <AppInfoBox>
      {{ infoMessage }}
    </AppInfoBox>
    <AppResourceTable
      table-id="review-messages-biz-messages-table"
      :resources="messages"
      :rows="bizMessageRows"
      :columns="columns"
      @paginate="paginate"
    />
  </AppAjaxContent>
</template>

<script>
import { mapGetters } from 'vuex';
import ReviewMessagesSearchbar from './ReviewMessagesSearchbar';
import ReviewMessagesView from './ReviewMessagesView';
import ReviewCampaignType from '@/enums/ReviewCampaignType';

export default {
  name: 'ReviewMessagesBizMessagesHistory',
  components: { ReviewMessagesSearchbar },
  mixins: [ReviewMessagesView],
  data() {
    return {
      MESSAGES_URL: '/review/biz_messages',
      MEDIA: 'biz_messages'
    };
  },
  computed: {
    ...mapGetters('session', ['isReviewCampaignEnabled']),
    campaignTypeOptions() {
      return [{ label: this.$t('app.all') }, ...ReviewCampaignType.options()];
    },
    bizMessageRows() {
      return this.rows.map(row => ({
        ...row,
        campaignType: row.campaign_type
          ? ReviewCampaignType.t(row.campaign_type)
          : null
      }));
    },
    columns() {
      return [
        { id: 'id', label: this.$t('app.id'), width: '80px' },
        { id: 'date', label: this.$t('app.date'), type: 'pre', width: '240px' },
        ...(this.isReviewCampaignEnabled
          ? [
              {
                id: 'campaignType',
                label: this.$t('message.campaign_type'),
                width: '200px'
              }
            ]
          : []),
        { id: 'userName', label: this.$t('message.user_name'), width: '120px' },
        {
          id: 'userUsername',
          label: this.$t('message.user_username'),
          width: '120px'
        },
        {
          id: 'to',
          label: this.$t('message.to_phone'),
          width: '120px'
        },
        {
          id: 'products',
          label: this.$t('message.products_writeable_review'),
          type: 'pre'
        }
      ];
    },
    searchTypes() {
      return [
        {
          value: 'to',
          label: this.$t('message.to_phone')
        },
        { value: 'order_code', label: this.$t('orders.code') },
        {
          value: 'user_name',
          label: this.$t('message.user_name')
        },
        { value: 'user_username', label: this.$t('message.user_username') }
      ];
    }
  },
  methods: {
    changeCampaignType(campaignType) {
      this.resourceParams = {
        ...this.resourceParams,
        campaign_type: campaignType
      };
      this.searchResource();
    }
  }
};
</script>
