<template>
  <AppModalFormLivePreview
    :title="$t('title')"
    :sub-title="campaign.name"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId,
      disabled: isArchivedCampaign
    }"
    v-on="formEvents"
  >
    <template #left>
      <AppEmailPreview
        :subject="interpolatedEmailSubject"
        :sender-name="currentBrand.name"
        :sender-email="emailPreview.sender_email"
      >
        <TargetEmailLivePreview
          :css="emailPreview.email_css"
          :templates="emailPreview.email_templates"
          :params="emailParams"
          :translations="emailPreview.email_translations"
        />
      </AppEmailPreview>
    </template>
    <template #group="{ id, disabled, errors }">
      <template v-if="id === 'product_recommendation_units'">
        <div class="AppForm__group-field AppForm__group-field--mt8">
          <TargetDmCampaignsRecommendedProducts
            v-model="formObject.product_recommendation_units"
            :disabled="disabled"
            @change="validateField('product_recommendation_units')"
          />
        </div>
      </template>
      <template v-else-if="id === 'enable_recommendation_button'">
        <div class="AppForm__group-field">
          <div class="AppForm__sub-group">
            <label
              class="AppForm__sub-group-title"
              for="email_design_recommendation_button_label"
              >{{ $t('recommendation_button_label') }}</label
            >
            <AppTextInput
              id="email_design_recommendation_button_label"
              v-model="formObject.recommendation_button_label"
              :name="`${objectId}[recommendation_button_label]`"
              :disabled="disabled || !formObject.enable_recommendation_button"
              :placeholder="$t('recommendation_button_label_placeholder')"
              :invalid="!!errors.recommendation_button_label"
              @blur="validateField('recommendation_button_label')"
              @change="validateField('recommendation_button_label')"
            />
            <AppFormError :error="errors.recommendation_button_label" />
          </div>
          <div class="AppForm__sub-group">
            <label
              class="AppForm__sub-group-title"
              for="email_design_recommendation_button_url"
              >{{ $t('recommendation_button_url') }}</label
            >
            <AppTextInput
              id="email_design_recommendation_button_url"
              v-model="formObject.recommendation_button_url"
              type="url"
              :name="`${objectId}[recommendation_button_url]`"
              :disabled="disabled || !formObject.enable_recommendation_button"
              :placeholder="$t('button_url_placeholder')"
              :invalid="!!errors.recommendation_button_url"
              @blur="validateField('recommendation_button_url')"
              @change="validateField('recommendation_button_url')"
            />
            <AppFormError :error="errors.recommendation_button_url" />
          </div>
        </div>
      </template>
    </template>
    <template #group-sub-item="{ group, errors, value, checked }">
      <template v-if="group.id === 'use_brand_logo'">
        <template v-if="value">
          <input
            type="hidden"
            :name="`${objectId}[remove_title_image]`"
            :value="true"
            :disabled="!checked"
          />
        </template>
        <template v-else>
          <AppImageInput
            :name="`${objectId}[title_image]`"
            :image-url="formObject.title_image"
            :placeholder="$t('title_image_placeholder')"
            :disabled="!checked"
            :invalid="!!errors.title_image"
            not-removable
            @change="changeTitleImage"
          />
          <AppFormError :error="errors.title_image" />
        </template>
      </template>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import interpolate from '@/lib/interpolate';
import jaso from '@/lib/jaso';
import { isUrlFormatValid } from '@/lib/urlFormatValidator';
import TargetCampaignType from '@/enums/TargetCampaignType';
import DialogFormView from '@/mixins/DialogFormView';
import TargetEmailLivePreview from './components/TargetEmailLivePreview';
import TargetDmCampaignsRecommendedProducts from './components/TargetDmCampaignsRecommendedProducts';

export default {
  name: 'TargetDmCampaignSettingsDesignEmailDialog',
  components: { TargetEmailLivePreview, TargetDmCampaignsRecommendedProducts },
  mixins: [DialogFormView],
  props: { campaign: { type: Object, required: true } },
  data() {
    return {
      isLoading: true,
      isLoadingEmailBody: true,
      emailBodyHtml: '',
      emailPreview: {}
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('targetCampaign', ['isArchivedCampaign']),
    objectId() {
      return 'campaign';
    },
    basicGroupConfigs() {
      const keywords = this.toKeywords(this.commonKeys);
      const subjectKeywords = this.toKeywords(this.subjectKeys);

      return {
        disclaimer: {
          type: 'infobox',
          label: '',
          value: this.$t('disclaimer_html')
        },
        enable_email: {
          type: 'checkbox',
          label: this.$t('enable_email'),
          superAdmin: true
        },
        email_subject: {
          label: this.$t('email_subject'),
          type: 'keyword_textarea',
          placeholder: this.$t('email_subject_placeholder'),
          keywords: subjectKeywords,
          validate: ['required']
        },
        use_brand_logo: {
          label: this.$t('title_image'),
          type: 'select_radio',
          name: null,
          options: [
            { label: this.$t('use_brand_logo_true'), value: true },
            { label: this.$t('use_brand_logo_false'), value: false }
          ],
          required: true,
          fields: this.formObject.use_brand_logo
            ? []
            : [{ id: 'title_image', validate: ['required'] }]
        },
        unpaid_order_title: {
          label: this.$t('unpaid_order_title'),
          type: 'keyword_textarea',
          placeholder: this.$t('title_placeholder'),
          keywords,
          singleLine: true
        },
        coupon_title: {
          label: this.$t('coupon_title'),
          type: 'keyword_textarea',
          placeholder: this.$t('title_placeholder'),
          keywords,
          singleLine: true
        },
        recommendation_title: {
          label: this.$t('recommendation_title'),
          type: 'keyword_textarea',
          placeholder: this.$t('title_placeholder'),
          keywords,
          singleLine: true
        },
        member_inactivation_prevention_description_html: {
          label: this.$t('member_inactivation_prevention_description_html'),
          type: 'keyword_textarea',
          placeholder: this.$t('title_placeholder'),
          keywords: this.toKeywords([
            ...this.commonKeys,
            'days_before_inactivation'
          ]),
          singleLine: true,
          validate: ['required']
        },
        member_inactivation_prevention_button_label: {
          label: this.$t('member_inactivation_prevention_button_label'),
          type: 'keyword_textarea',
          placeholder: this.$t('button_placeholder'),
          keywords,
          singleLine: true,
          validate: ['required']
        },
        member_inactivation_prevention_button_url: {
          label: this.$t('member_inactivation_prevention_button_url'),
          type: 'keyword_textarea',
          placeholder: this.$t('button_url_placeholder'),
          keywords: this.toKeywords(['login_url']),
          singleLine: true,
          validate: [
            'required',
            {
              rule: v =>
                isUrlFormatValid(
                  interpolate(v, { login_url: 'https://cre.ma' })
                ),
              errorMessage: this.$t('validations.url_format')
            }
          ]
        },
        mileage_expiring_title_html: {
          label: this.$t('mileage_expiring_title_html'),
          type: 'keyword_textarea',
          placeholder: this.$t('title_placeholder'),
          keywords: this.toKeywords([
            ...this.commonKeys,
            'days_before_expiration'
          ]),
          singleLine: true,
          validate: ['required']
        },
        mileage_expiring_description_html: {
          label: this.$t('mileage_expiring_description_html'),
          type: 'keyword_textarea',
          placeholder: this.$t('title_placeholder'),
          keywords: this.toKeywords([...this.commonKeys, 'max_best_mileage']),
          singleLine: true,
          validate: ['required']
        },
        mileage_expiring_button_label: {
          label: this.$t('mileage_expiring_button_label'),
          type: 'textarea',
          placeholder: this.$t('button_placeholder'),
          rows: 1,
          validate: ['required']
        }
      };
    },
    basicGroupIds() {
      switch (this.campaign.campaign_type) {
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
          return [
            'disclaimer',
            'enable_email',
            'email_subject',
            'use_brand_logo',
            'unpaid_order_title',
            'coupon_title',
            'recommendation_title'
          ];
        case TargetCampaignType.MILEAGE_EXPIRING:
          return [
            'disclaimer',
            'enable_email',
            'email_subject',
            'use_brand_logo',
            'mileage_expiring_title_html',
            'mileage_expiring_description_html',
            'mileage_expiring_button_label'
          ];
        case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
          return [
            'disclaimer',
            'enable_email',
            'email_subject',
            'use_brand_logo',
            'member_inactivation_prevention_description_html',
            'member_inactivation_prevention_button_label',
            'member_inactivation_prevention_button_url',
            'coupon_title',
            'recommendation_title'
          ];
        default:
          return [
            'disclaimer',
            'enable_email',
            'email_subject',
            'use_brand_logo',
            'coupon_title',
            'recommendation_title'
          ];
      }
    },
    advancedGroupIds() {
      switch (this.campaign.campaign_type) {
        case TargetCampaignType.CART_REMINDING:
          return [
            'advanced_settings_info',
            'display_recommendation_items',
            'product_recommendation_units',
            'enable_recommendation_button',
            'css'
          ];
        case TargetCampaignType.MILEAGE_EXPIRING:
          return [];
        default:
          return [
            'advanced_settings_info',
            'display_recommendation_items',
            'product_recommendation_units',
            'enable_recommendation_button',
            'display_brand_user_coupons',
            'css'
          ];
      }
    },
    advancedGroupConfigs() {
      return {
        advanced_settings_info: {
          label: '',
          type: 'infobox',
          value: this.$t('advanced_settings_info_html')
        },
        display_recommendation_items: {
          label: this.$t('display_recommendation_items'),
          type: 'checkbox'
        },
        product_recommendation_units: {
          label: this.$t('product_recommendation_units'),
          disabled: !this.formObject.display_recommendation_items,
          validate: this.formObject.display_recommendation_items
            ? ['required']
            : []
        },
        enable_recommendation_button: {
          label: this.$t('enable_recommendation_button'),
          type: 'checkbox',
          fields: this.formObject.enable_recommendation_button
            ? [
                {
                  id: 'recommendation_button_label',
                  validate: ['required']
                },
                {
                  id: 'recommendation_button_url',
                  validate: ['required', 'url_format']
                }
              ]
            : []
        },
        display_brand_user_coupons: {
          type: 'checkbox',
          label: this.$t('display_brand_user_coupons'),
          description: this.$t('display_brand_user_coupons_description')
        },
        css: {
          label: this.$t('css'),
          type: 'code_editor',
          lang: 'css',
          superAdmin: true
        }
      };
    },
    formSections() {
      if (this.isLoading) return [];

      return [
        {
          groups: this.basicGroupIds.map(id => {
            const { keywords, validate } = this.basicGroupConfigs[id];

            const newValidate = validate ? validate : [];
            if (keywords) newValidate.push({ rule: 'keywords', keywords });

            return {
              id,
              validate: newValidate,
              ...this.basicGroupConfigs[id]
            };
          })
        },
        ...(this.advancedGroupIds.length
          ? [
              {
                id: 'advanced',
                label: this.$t('app.advanced_settings'),
                groups: this.advancedGroupIds.map(id => ({
                  id,
                  ...this.advancedGroupConfigs[id]
                }))
              }
            ]
          : [])
      ];
    },
    commonKeys: () => ['user', 'user_id', 'brand'],
    subjectKeys() {
      const additionalKeys = [];

      switch (this.campaign.campaign_type) {
        case TargetCampaignType.MILEAGE_EXPIRING:
          additionalKeys.push('days_before_expiration');
          break;
        case TargetCampaignType.COUPON_EXPIRY:
          additionalKeys.push('days_before_coupon_expiration');
      }
      return ['prefix', ...this.commonKeys, ...additionalKeys];
    },
    interpolatedEmailSubject() {
      return this.interpolate(this.formObject.email_subject);
    },
    emailParams() {
      const vm = this;
      const {
        coupon_title,
        display_brand_user_coupons,
        display_recommendation_items,
        enable_recommendation_button,
        member_inactivation_prevention_description_html,
        member_inactivation_prevention_button_label,
        mileage_expiring_title_html,
        mileage_expiring_description_html,
        mileage_expiring_button_label,
        product_recommendation_units,
        recommendation_button_label,
        recommendation_title,
        title_image,
        unpaid_order_title,
        use_brand_logo
      } = this.formObject;
      const { email_params, sample_products } = this.emailPreview;

      const product_recommendation_sections = [];
      if (display_recommendation_items && sample_products.length > 0) {
        let i = -1;
        product_recommendation_units.forEach(unit =>
          product_recommendation_sections.push({
            title: this.interpolate(unit.title),
            products: _.times(unit.max_products, () => {
              i = (i + 1) % sample_products.length;
              const product = sample_products[i];
              return {
                ...product,
                name: `${vm.$t('sample_product_name_prefix')}${product.name}`
              };
            })
          })
        );
      }

      return {
        ...email_params,
        brand_user_coupons: email_params.brand_user_coupons,
        coupon_title_html: this.interpolate(coupon_title),
        display_brand_user_coupons,
        display_recommendation_items,
        enable_recommendation_button,
        member_inactivation_prevention_description_html: this.interpolate(
          member_inactivation_prevention_description_html
        ),
        member_inactivation_prevention_button_label: this.interpolate(
          member_inactivation_prevention_button_label
        ),
        mileage_expiring_title_html: this.interpolate(
          mileage_expiring_title_html
        ),
        mileage_expiring_description_html: this.interpolate(
          mileage_expiring_description_html
        ),
        mileage_expiring_button_label,
        product_recommendation_sections,
        recommendation_button_label,
        recommendation_title_html: this.interpolate(recommendation_title),
        title_image:
          use_brand_logo || !title_image ? null : { url: title_image },
        unpaid_order_title_html: this.interpolate(unpaid_order_title)
      };
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaign.id}/email_design`)
      .then(({ data }) => {
        const { email_design, email_preview } = data;
        this.orgFormObject = {
          ...email_design,
          use_brand_logo: !data.email_design.title_image
        };

        const { email_interpolation } = email_preview;
        const { max_best_mileage } = email_interpolation;
        if (max_best_mileage)
          email_interpolation.max_best_mileage = max_best_mileage.cents;

        this.emailPreview = {
          ...email_preview,
          sender_email: email_preview.sender_email || 'support@cre.ma'
        };
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(`/target/campaigns/${this.campaign.id}/email_design`, formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    toKeywords(keys) {
      return keys.map(key => ({ key, desc: this.$t(`keyword_desc.${key}`) }));
    },
    changeTitleImage({ imageUrl }) {
      this.formObject.title_image = imageUrl;
      this.validateField('title_image');
    },
    interpolate(text) {
      return jaso(interpolate(text, this.emailPreview.email_interpolation));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "이메일 설정",
  "disclaimer_html": "<b>통신법상 제목에 \"(광고)\"를 포함해야 합니다.<br />미포함에 의한 법적 문제는 크리마가 책임지지 않습니다.</b><br /><br />편의를 위해 예약어 입력시 자동으로 문구를 생성합니다.",
  "enable_email": "이메일 사용 여부",
  "email_subject": "이메일 제목",
  "email_subject_placeholder": "이메일 제목을 입력해주세요.",
  "use_brand_logo_true": "쇼핑몰 로고 사용",
  "use_brand_logo_false": "이미지 업로드",
  "title_image": "상단 타이틀 이미지",
  "title_image_placeholder": "타이틀로 사용할 이미지를 등록해주세요.",
  "unpaid_order_title": "미입금 안내 문구",
  "coupon_title": "쿠폰/이벤트 안내 문구",
  "recommendation_title": "추천 상품 안내 문구",
  "member_inactivation_prevention_description_html": "휴면 전환 안내 문구",
  "member_inactivation_prevention_button_label": "휴면 전환 방지 버튼 문구",
  "member_inactivation_prevention_button_url": "휴면 전환 방지 버튼 URL",
  "mileage_expiring_title_html": "적립금 지급 기한 만료 안내 문구",
  "mileage_expiring_description_html": "적립금 정보 안내 문구",
  "mileage_expiring_button_label": "리뷰 작성 유도 버튼 문구",
  "title_placeholder": "안내 문구를 입력해주세요.",
  "button_placeholder": "버튼 문구를 입력해주세요.",
  "button_url_placeholder": "버튼 클릭 시 이동할 URL을 입력해주세요.",
  "advanced_tooltip": "발송을 위한 필수 설정 이외의 고급 설정을 수정할 수 있습니다.",
  "keyword_desc": {
    "user": "고객명",
    "user_id": "고객아이디",
    "brand": "쇼핑몰명",
    "prefix": "(광고)쇼핑몰명",
    "days_before_expiration": "적립금 지급 기한 만료 잔여일 수",
    "days_before_inactivation": "휴면회원 전환 예정 일수",
    "days_before_coupon_expiration": "쿠폰 만료 잔여일",
    "login_url": "쇼핑몰 로그인페이지 URL",
    "max_best_mileage": "최대 적립금"
  },
  "advanced_settings_info_html": "고급 설정은 세밀한 캠페인 사용을 위한 설정모음 입니다.<br />해당 설정을 잘 모르신다면 기본 설정 사용을 추천합니다.",
  "display_recommendation_items": "추천 상품 노출",
  "product_recommendation_units": "추천 상품 설정",
  "enable_recommendation_button": "추천 상품 하단 버튼 사용",
  "recommendation_button_label": "버튼 문구",
  "recommendation_button_label_placeholder": "문구를 입력해주세요.",
  "recommendation_button_url": "버튼 URL",
  "display_brand_user_coupons": "사용 가능한 쿠폰 정보 노출",
  "display_brand_user_coupons_description": "발송 시점에 사용 가능한 쿠폰이 존재할 때 쿠폰 정보를 노출합니다.",
  "sample_product_name_prefix": "[예시 상품]",
  "css": "이 페이지에만 적용할 CSS"
}
</i18n>
