<template>
  <TargetReportsDispatchResultDialogTable
    table-id="dispatch-result-items-email-table"
    v-bind="{
      isLoading,
      cohortItems,
      columns,
      itemStatusOptions,
      dispatchMethod
    }"
    :item-status="resourceParams.item_status"
    @change-item-status="changeItemStatus"
    @paginate="paginate"
  />
</template>

<script>
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import TargetDispatchMethod from '@/enums/TargetDispatchMethod';
import TargetCohortItemStatus from '@/enums/TargetCohortItemStatus';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import TargetReportsDispatchResultDialogTable from './TargetReportsDispatchResultDialogTable';

export default {
  name: 'TargetReportsDispatchResultDialogTableEmail',
  components: { TargetReportsDispatchResultDialogTable },
  mixins: [ResourceViewNoRoute],
  props: { cohort: { type: Object, required: true } },
  data: () => ({ isLoading: true, cohortItems: nullResources }),
  computed: {
    dispatchMethod: () => TargetDispatchMethod.EMAIL,
    columns() {
      return [
        { id: 'id' },
        { id: 'date' },
        { id: 'from', label: this.$t('table.from') },
        { id: 'user' },
        { id: 'subject', align: 'left', label: this.$t('table.subject') },
        { id: 'status' }
      ];
    },
    itemStatusOptions() {
      const notUsed = [
        TargetCohortItemStatus.DUPLICATE_MSG_ID,
        TargetCohortItemStatus.SENDING,
        TargetCohortItemStatus.LMS_NOT_ALLOWED
      ];

      return TargetCohortItemStatus.values
        .filter(value => !notUsed.includes(value))
        .map(value => ({
          value,
          label: TargetCohortItemStatus.translateForDispatchMethod(
            value,
            TargetDispatchMethod.EMAIL
          )
        }));
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/target/cohorts/${this.cohort.id}/items/email`, { params })
        .then(({ data }) => (this.cohortItems = data.cohort_items))
        .finally(() => (this.isLoading = false));
    },
    changeItemStatus(status) {
      this.resourceParams.item_status = status;
      this.searchResource();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dispatch-result-items-email-table__id {
    flex-basis: 90px;
  }

  .dispatch-result-items-email-table__date {
    flex-basis: 200px;
  }

  .dispatch-result-items-email-table__from {
    flex-basis: 170px;
  }

  .dispatch-result-items-email-table__user {
    flex-basis: 178px;
  }

  .dispatch-result-items-email-table__subject {
    flex-basis: 154px;
  }

  .dispatch-result-items-email-table__status {
    flex-basis: 140px;
  }
}
</style>

<i18n locale="ko">
{
  "table": {
    "from": "발신 주소",
    "subject": "제목"
  }
}
</i18n>
