<template>
  <AppBubble v-show="isShowingBubble" @click="click">
    <div>
      {{ visibleBubbleType ? $t(`${visibleBubbleType}.main`) : '' }}
    </div>
    <div class="TheHelpBubble__cta">
      {{ visibleBubbleType ? $t(`${visibleBubbleType}.cta`) : '' }}
    </div>
  </AppBubble>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BrandReviewStatus from '@/enums/BrandReviewStatus';
import InquiryHelper from '@/mixins/InquiryHelper';

const shouldLimitByCounter = type =>
  ['review_operating', 'fit', 'target', 'display'].includes(type);

export default {
  name: 'TheHelpBubble',
  mixins: [InquiryHelper],
  props: { eventBus: { type: Object, required: true } },
  data() {
    return { visibleBubbleType: '', isShowingBubble: false };
  },
  computed: {
    ...mapGetters('editor', ['helpBubbleDisplayedDatesByBrand']),
    ...mapState('session', ['currentBrand']),
    ...mapGetters('menu', ['selectedMainMenuItemId']),
    bubbleType() {
      const isBubbleVisibleMenu = [
        'review',
        'fit',
        'target',
        'display'
      ].includes(this.selectedMainMenuItemId);
      if (!isBubbleVisibleMenu) return '';

      return this.selectedMainMenuItemId === 'review'
        ? this.reviewBubbleType
        : this.selectedMainMenuItemId;
    },
    reviewBubbleType() {
      return this.currentBrand.review_status === BrandReviewStatus.NONE
        ? 'review_introduction'
        : [
            BrandReviewStatus.ONBOARDING_NOT_PAID,
            BrandReviewStatus.ONBOARDING_PAID
          ].includes(this.currentBrand.review_status)
        ? 'review_onboarding'
        : [
            BrandReviewStatus.PAID,
            BrandReviewStatus.TESTING,
            BrandReviewStatus.OPERATING,
            BrandReviewStatus.EXPIRED_BUT_OPERATING,
            BrandReviewStatus.EXPIRED_AND_ADMIN_BLOCKED
          ].includes(this.currentBrand.review_status)
        ? 'review_operating'
        : '';
    }
  },
  watch: {
    bubbleType() {
      this.showBubble();
    }
  },
  mounted() {
    this.showBubble();
    this.eventBus.$on('hide', this.hideBubble);
  },
  methods: {
    ...mapActions('editor', ['setHelpBubbleDisplayedDate']),
    showBubble() {
      if (!this.isDisplayable(this.bubbleType)) {
        this.hideBubble();
        return;
      }

      this.visibleBubbleType = this.bubbleType;
      this.isShowingBubble = true;
      if (shouldLimitByCounter(this.visibleBubbleType))
        this.setHelpBubbleDisplayedDate(this.visibleBubbleType);
    },
    isDisplayable(bubbleType) {
      if (!bubbleType) return false;
      if (!shouldLimitByCounter(bubbleType)) return true;

      const lastDisplayedDate = this.helpBubbleDisplayedDatesByBrand[
        bubbleType
      ];
      if (!lastDisplayedDate) return true;

      const { lastSeen } = lastDisplayedDate;
      const today = new Date().setHours(0, 0, 0, 0);
      return !lastSeen || lastSeen < today;
    },
    click() {
      this.hideBubble();

      this.openInquiry();
    },
    hideBubble() {
      this.isShowingBubble = false;
    }
  }
};
</script>

<style scoped>
.TheHelpBubble__cta {
  margin-top: 4px;
  font-weight: bold;
}
</style>

<i18n locale="ko">
{
  "review_operating": {
    "main": "리뷰 서비스에 대해 궁금한게 있으신가요?",
    "cta": "리뷰 서비스 문의하기"
  },
  "review_introduction": {
    "main": "리뷰 서비스에 관심 있으신가요?",
    "cta": "리뷰 서비스 실시간 상담!"
  },
  "review_onboarding": {
    "main": "리뷰 사용 준비 중 궁금한게 있으신가요?",
    "cta": "크리마 운영팀과 채팅 상담하기"
  },
  "fit": {
    "main": "핏 서비스에 대해 궁금한게 있으신가요?",
    "cta": "핏 서비스 문의하기"
  },
  "target": {
    "main": "타겟 서비스에 대해 궁금한게 있으신가요?",
    "cta": "타겟 서비스 문의하기"
  },
  "display": {
    "main": "진열 서비스에 대해 궁금한게 있으신가요?",
    "cta": "진열 서비스 문의하기"
  }
}
</i18n>
