<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppDropdownDialogButton
        v-model="selectedCategoryValue"
        :dialog-items="DIALOG_ITEMS"
      />
      <div class="ui-section__description">
        Selected Value: {{ selectedCategoryValue }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UiDropdownDialogButton',
  data() {
    return {
      selectedCategoryValue: 0,
      DIALOG_ITEMS: [
        { label: '전체 카테고리', value: 0, parent_value: null },

        { label: '상의', value: 1, parent_value: null },
        { label: '하의', value: 2, parent_value: null },
        { label: '아우터', value: 3, parent_value: null },
        { label: '원피스', value: 4, parent_value: null },
        { label: '기타', value: 5, parent_value: null },

        { label: '니트/스웨터', value: 6, parent_value: 1 },
        { label: '맨투맨/후드', value: 7, parent_value: 1 },
        { label: '블라우스/셔츠', value: 8, parent_value: 1 },
        { label: '티셔츠', value: 9, parent_value: 1 },
        { label: '기타', value: 10, parent_value: 1 },

        { label: '바지', value: 11, parent_value: 2 },
        { label: '치마', value: 12, parent_value: 2 },
        { label: '레깅스', value: 13, parent_value: 2 },
        { label: '기타', value: 14, parent_value: 2 },

        { label: '가디건', value: 15, parent_value: 3 },
        { label: '자켓', value: 16, parent_value: 3 },
        { label: '패딩', value: 17, parent_value: 3 },
        { label: '코트', value: 18, parent_value: 3 },
        { label: '점퍼', value: 19, parent_value: 3 },
        { label: '기타', value: 20, parent_value: 3 },

        { label: '원피스', value: 21, parent_value: 4 },
        { label: '점프수트', value: 22, parent_value: 4 }
      ]
    };
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    clicked(buttonName, close) {
      this.createToast(`'${buttonName}' clicked`);
      close();
    }
  }
};
</script>
