import { constructEnum } from '@/lib/enum';

const DisplayProductDisplayExecuteStatus = constructEnum(
  'DisplayProductDisplayExecuteStatus',
  {
    NONE: 0,
    PREPARING: 10,
    WAITING_PRODUCT_MANAGEMENT: 19,
    CALCULATING: 20,
    READY: 30,
    CALCULATED: 31,
    DISPATCHING: 40,
    COMPLETE: 100,
    ERROR_PREPARING: 1000,
    ERROR_CALCULATING: 1050,
    ERROR_DISPATCHING: 1100,
    ERROR_SERVICE_EXPIRED: 1200,
    ERROR_CORNER_NOT_EXISTS: 1300,
    ERROR_LOGIN_BLOCKED: 1400,
    ERROR_SESSION_EXPIRED: 1500
  }
);

DisplayProductDisplayExecuteStatus.APPLYING_STATUSES = [
  DisplayProductDisplayExecuteStatus.PREPARING,
  DisplayProductDisplayExecuteStatus.WAITING_PRODUCT_MANAGEMENT,
  DisplayProductDisplayExecuteStatus.CALCULATING,
  DisplayProductDisplayExecuteStatus.CALCULATED,
  DisplayProductDisplayExecuteStatus.DISPATCHING
];

DisplayProductDisplayExecuteStatus.ERRORED_STATUSES = [
  DisplayProductDisplayExecuteStatus.ERROR_PREPARING,
  DisplayProductDisplayExecuteStatus.ERROR_CALCULATING,
  DisplayProductDisplayExecuteStatus.ERROR_DISPATCHING,
  DisplayProductDisplayExecuteStatus.ERROR_SERVICE_EXPIRED,
  DisplayProductDisplayExecuteStatus.ERROR_CORNER_NOT_EXISTS,
  DisplayProductDisplayExecuteStatus.ERROR_LOGIN_BLOCKED,
  DisplayProductDisplayExecuteStatus.ERROR_SESSION_EXPIRED
];

DisplayProductDisplayExecuteStatus.isApplying = function(executeStatus) {
  return DisplayProductDisplayExecuteStatus.APPLYING_STATUSES.includes(
    executeStatus
  );
};

DisplayProductDisplayExecuteStatus.isErrored = function(executeStatus) {
  return DisplayProductDisplayExecuteStatus.ERRORED_STATUSES.includes(
    executeStatus
  );
};

export default DisplayProductDisplayExecuteStatus;
