import { constructEnum } from '@/lib/enum';

const TargetProductDataCalculationStatus = constructEnum(
  'TargetProductDataCalculationStatus',
  {
    PENDING: 0,
    IN_PROGRESS: 100,
    COMPLETE: 200,
    ERROR: 300
  }
);

TargetProductDataCalculationStatus.completed = calculationStatus => {
  return [
    TargetProductDataCalculationStatus.COMPLETE,
    TargetProductDataCalculationStatus.ERROR
  ].includes(calculationStatus);
};

export default TargetProductDataCalculationStatus;
