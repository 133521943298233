<template>
  <AppButton
    :disabled="disabled || isSubmitting || !!errorSummary"
    :label="isSubmitting ? submittingLabel : submitLabel"
    :tooltip="tooltip"
    :button-style="errorSummary ? 'red' : buttonStyle"
    v-bind="{ type, size, buttonStyle, buttonClasses }"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  name: 'AppButtonSubmit',
  props: {
    type: { type: String, default: 'submit' },
    isSubmitting: { type: Boolean, default: false },
    submitLabel: {
      type: String,
      default() {
        return this.$t('app.save');
      }
    },
    submittingLabel: {
      type: String,
      default() {
        return this.$t('app.saving');
      }
    },
    buttonStyle: { type: String, default: 'blue' },
    disabled: { type: Boolean, default: false },
    disabledTooltip: { type: String, default: null },
    errorSummary: { type: String, default: null },
    size: { type: String, default: null },
    buttonClasses: { type: Array, default: () => [] }
  },
  computed: {
    tooltip() {
      return (this.disabled && this.disabledTooltip) || this.errorSummary;
    }
  }
};
</script>
