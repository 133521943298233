<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm
      :id="SETTINGS_ID"
      v-bind="formProps"
      submit-button
      :object-id="objectId"
      v-on="formEvents"
      @change-group="formGroupChanged"
    >
      <template #group="group">
        <div v-if="group.id === 'agency'">
          <div class="AppForm__group-field">
            <div>{{ $t('review_agency') }}</div>
            <AppSelect
              v-model="formObject.review_agency_id"
              name="move_shop_builder_settings[review_agency]"
              :placeholder="$t('agency_placeholder')"
              :options="agencyOptions"
              disabled
            />
          </div>
          <div class="AppForm__group-field">
            <div>{{ $t('target_agency') }}</div>
            <AppSelect
              v-model="formObject.target_agency_id"
              name="move_shop_builder_settings[target_agency]"
              :placeholder="$t('agency_placeholder')"
              :options="agencyOptions"
              disabled
            />
          </div>
          <div class="AppForm__group-field">
            <div>{{ $t('fit_agency') }}</div>
            <AppSelect
              v-model="formObject.fit_agency_id"
              name="move_shop_builder_settings[fit_agency]"
              :placeholder="$t('agency_placeholder')"
              :options="agencyOptions"
              disabled
            />
          </div>
        </div>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import moment from 'moment';
import api from '@/lib/api';
import ShopBuilder from '@/enums/ShopBuilder';
import FormView from '@/mixins/FormView';

export default {
  name: 'ReviewMoveShopBuilderBasic',
  mixins: [FormView],
  data: () => ({ isLoading: true, agencies: [], moveShobBuilderDate: null }),
  computed: {
    SETTINGS_ID() {
      return 'review_move_shop_builder_basic';
    },
    objectId() {
      return 'move_shop_builder_settings';
    },
    isCafe24() {
      return this.formObject.shop_builder === ShopBuilder.CAFE24;
    },
    isMakeshop() {
      return this.formObject.shop_builder === ShopBuilder.MAKESHOP;
    },
    agencyOptions() {
      return this.agencies.map(agency => ({
        label: agency.name,
        value: agency.id
      }));
    },
    formSections() {
      return [
        {
          id: 'review_move_shop_builder_basic',
          groups: [
            {
              id: 'shop_builder',
              type: 'select',
              options: ShopBuilder.options()
            },
            { id: 'use_cafe24_api', type: 'checkbox', disabled: true },
            {
              id: 'mall_id',
              type: 'text',
              placeholder: this.isMakeshop
                ? this.$t('mall_id.makeshop_placeholder')
                : this.$t('mall_id.placeholder'),
              description: this.isMakeshop
                ? this.$t('mall_id.makeshop_description')
                : null
            },
            {
              id: 'mall_admin_username',
              type: 'text',
              placeholder: this.$t('mall_admin_username_placeholder')
            },
            {
              id: 'mall_admin_password',
              type: 'text',
              placeholder: this.$t('mall_admin_password_placeholder')
            },
            { id: 'agency' },
            {
              id: 'mall_domain_prefix',
              type: 'text',
              placeholder: this.$t('mall_domain_prefix_placeholder')
            },
            {
              id: 'crm_host',
              type: 'text',
              placeholder: this.isCafe24
                ? this.$t('crm_host.cafe24_placeholder')
                : this.isMakeshop
                ? this.$t('crm_host.makeshop_placeholder')
                : null
            },
            {
              id: 'review_mall_board_code',
              type: 'text',
              description: this.$t('review_mall_board_code_description')
            },
            this.isMakeshop ? { id: 'makeshop_api_key', type: 'text' } : null,
            this.isCafe24
              ? {
                  id: 'cafe24_shop_no',
                  type: 'number',
                  digits: 2,
                  description: this.$t('cafe24_shop_no_description')
                }
              : null,
            { id: 'use_original_product_image', type: 'checkbox' },
            this.isCafe24
              ? {
                  id: 'include_order_code_on_mileage_action',
                  type: 'checkbox',
                  disabled: true,
                  description: this.$t(
                    'include_order_code_on_mileage_action_description',
                    [
                      this.moveShobBuilderDate
                        ? moment(this.moveShobBuilderDate).format('YYYY.MM.DD')
                        : '-'
                    ]
                  )
                }
              : null,
            {
              id: 'reviews_index_url',
              type: 'text',
              description: this.$t('reviews_index_url_description')
            },
            {
              id: 'mall_login_url',
              type: 'text',
              description: this.$t('mall_login_url_description')
            },
            {
              id: 'mall_login_url_mobile',
              type: 'text',
              description: this.$t('mall_login_url_mobile_description')
            }
          ].filter(g => g)
        }
      ];
    }
  },
  mounted() {
    api
      .get('/review/move_shop_builder/settings')
      .then(({ data }) => {
        this.orgFormObject = data.settings;
        this.agencies = data.agencies;
        this.moveShobBuilderDate = data.move_shop_builder_date;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    formGroupChanged(group) {
      if (group.id !== 'shop_builder') return;

      if (this.isCafe24) {
        const DEFAULT_SHOP_NO = 1;
        const DEFAULT_REVIEW_BOARD_CODE = '4';
        this.formObject.cafe24_shop_no = DEFAULT_SHOP_NO;
        this.formObject.use_cafe24_api = true;
        this.formObject.include_order_code_on_mileage_action = false;
        this.formObject.review_mall_board_code = DEFAULT_REVIEW_BOARD_CODE;
        this.setCommonDefaultSettings();
      } else if (this.isMakeshop) {
        this.formObject.use_cafe24_api = false;
        this.formObject.review_mall_board_code = '';
        this.setCommonDefaultSettings();
      } else {
        this.formObject.use_cafe24_api = false;
      }
    },
    setCommonDefaultSettings() {
      [
        'mall_id',
        'mall_admin_username',
        'mall_admin_password',
        'review_agency_id',
        'target_agency_id',
        'fit_agency_id',
        'mall_domain_prefix',
        'crm_host',
        'reviews_index_url',
        'mall_login_url',
        'mall_login_url_mobile'
      ].forEach(setting => {
        this.formObject[setting] = '';
      });
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/review/move_shop_builder/update_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>
<i18n locale="ko">
{
  "review_agency": "리뷰 운영대행사",
  "target_agency": "타겟 운영대행사",
  "fit_agency": "핏 운영대행사",
  "agency_placeholder": "없음",
  "mall_id": {
    "placeholder": "몰 ID를 입력해주세요.",
    "makeshop_placeholder": "https://cre.ma",
    "makeshop_description": "메이크샵의 경우, 몰 ID가 쇼핑몰 URL 형태입니다."
  },
  "mall_admin_username_placeholder": "부운영자 아이디를 입력해주세요.",
  "mall_admin_password_placeholder": "부운영자 비밀번호를 입력해주세요.",
  "mall_domain_prefix_placeholder": "쇼핑몰 호스팅사 사이트의 서브 도메인명을 입력해주세요.",
  "crm_host": {
    "cafe24_placeholder": "관리자 페이지 URL을 입력해주세요. (OOOO.cafe24.com)",
    "makeshop_placeholder": "관리자 페이지 URL을 입력해주세요. (OOOO.makeshop.co.kr)"
  },
  "review_mall_board_code_description": "리뷰 게시판 코드를 확인해주세요.",
  "cafe24_shop_no_description": "멀티샵인 경우에만 해당 쇼핑몰의 샵넘버로 변경해주세요.",
  "reviews_index_url_description": "상품 리뷰 위젯에서 '전체 상품 리뷰 보기' 버튼 클릭시 입력한 URL로 이동합니다.",
  "mall_login_url_description": "리뷰 작성 대상이 회원일 경우, '상품 리뷰 작성하기' 버튼 클릭시 입력한 PC URL로 이동합니다.",
  "mall_login_url_mobile_description": "리뷰 작성 대상이 회원일 경우, '상품 리뷰 작성하기' 버튼 클릭시 입력한 모바일 URL로 이동합니다.",
  "include_order_code_on_mileage_action_description": "설정 변경일 : {0} (180일 후 변경)"
}
</i18n>
