<template>
  <AppModalDefault :title="$t('title')" @close="close">
    <template #body>
      <AppForm :sections="sections" :object="scripts">
        <template #group="{ id }">
          <div v-if="id === 'environment'" class="AppForm__group-field">
            <AppSelectRadio
              v-model="scripts.environment"
              :options="[
                {
                  label: $t('environment_homepage'),
                  value: 'homepage'
                },
                {
                  label: $t('environment_market'),
                  value: 'market'
                }
              ]"
            >
              <template #sub-item="{ value, checked }">
                <div v-if="value === 'market'">
                  <div class="AppForm__sub-group">
                    <label>
                      <div class="AppForm__sub-group-title">
                        {{ $t('market_image_width') }}
                      </div>
                      <AppNumberInput
                        v-model="scripts.image_width"
                        :placeholder="$t('market_image_width_placeholder')"
                        :disabled="!checked"
                        :digits="4"
                        class="ReviewSettingsWidgetScriptDialog__image-width"
                      />
                    </label>
                  </div>
                  <div
                    v-if="widget.widget_style === WidgetStyle.OPEN_MARKET"
                    class="AppForm__sub-group"
                  >
                    <label>
                      <div class="AppForm__sub-group-title">
                        {{ $t('market_target_widget_id') }}
                      </div>
                      <AppNumberInput
                        v-model="scripts.target_widget_id"
                        :placeholder="$t('market_target_widget_id_placeholder')"
                        :disabled="!checked"
                        :digits="3"
                        class="ReviewSettingsWidgetScriptDialog__target-widget-id"
                      />
                    </label>
                  </div>
                </div>
              </template>
            </AppSelectRadio>
          </div>
        </template>
      </AppForm>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState } from 'vuex';
import DialogView from '@/mixins/DialogView';
import WidgetStyle from '@/enums/WidgetStyle';
import WidgetType from '@/enums/WidgetType';

export default {
  name: 'ReviewSettingsWidgetScriptDialog',
  mixins: [DialogView],
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  data() {
    return { scripts: {} };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    WidgetStyle() {
      return WidgetStyle;
    },
    sections() {
      const groupsScript = [
        {
          id: 'html_tag',
          label: 'HTML Tag',
          type: 'copyable_textarea',
          value: this.htmlTag
        },
        {
          id: 'script_tag',
          label: 'JavaScript',
          type: 'copyable_textarea'
        }
      ];

      const sectionsMarket = [
        {
          id: 'condition',
          label: this.$t('section_condition'),
          groups: [
            {
              id: 'environment',
              label: this.$t('environment')
            }
          ]
        },
        {
          id: 'script',
          label: this.$t('section_script'),
          groups:
            this.scripts.environment === 'market'
              ? groupsScript.filter(g => g.id === 'html_tag')
              : groupsScript
        }
      ];

      switch (this.widget.widget_type) {
        case WidgetType.PRODUCT_REVIEWS:
          if (
            [WidgetStyle.GRID, WidgetStyle.OPEN_MARKET].includes(
              this.widget.widget_style
            )
          )
            return sectionsMarket;
          else
            return [
              {
                id: 'script',
                label: this.$t('section_script'),
                groups: groupsScript
              }
            ];
        case WidgetType.REVIEWS:
        case WidgetType.CATEGORY_REVIEWS:
        case WidgetType.PRODUCTS:
          return [{ groups: groupsScript }];
        default:
          return [];
      }
    },
    htmlTag() {
      const { id, widget_type, widget_style, widget_sub_style } = this.widget;
      const { environment, image_width, target_widget_id } = this.scripts;
      if (environment === 'market') {
        const brandCode = this.currentBrand.code;
        const baseUrl = `https://review.cre.ma/${brandCode}/products/reviews?widget_env=220&product_code={상품번호}`;

        const widgetUrl = [
          baseUrl,
          target_widget_id ? `&widget_id=${target_widget_id}` : ''
        ].join('');

        const imageUrl = [
          baseUrl.replace('?', '.jpg?'),
          this.widget.default ? '' : `&widget_id="${id}"`,
          image_width ? `&width=${image_width}` : ''
        ].join('');
        return `<a target="_blank" rel="noopener" href="${widgetUrl}"><img style="border: none;" src="${imageUrl}"></a>`;
      } else {
        const html_class =
          widget_type === WidgetType.REVIEWS
            ? 'crema-reviews'
            : widget_type === WidgetType.PRODUCT_REVIEWS
            ? 'crema-product-reviews'
            : widget_type === WidgetType.CATEGORY_REVIEWS
            ? 'crema-category-reviews'
            : widget_type === WidgetType.PRODUCTS
            ? 'crema-products'
            : 'crema-product-score';
        const widget_style_attr = this.WidgetStyle.key(
          widget_sub_style || widget_style
        )?.toLowerCase();

        return [
          `<div class="${html_class}"`,
          ` data-widget-id="${id}"`,
          widget_style_attr ? ` data-widget-style="${widget_style_attr}"` : '',
          this.useProductCode ? ' data-product-code="{상품번호}"' : '',
          this.useCategoryCode ? ' data-category-code="{카테고리번호}"' : '',
          '></div>'
        ].join('');
      }
    },
    useProductCode() {
      return this.widget.widget_type === WidgetType.PRODUCT_REVIEWS;
    },
    useCategoryCode() {
      return this.widget.widget_type === WidgetType.CATEGORY_REVIEWS;
    },
    isVuejsWidget() {
      return this.widget.use_vuejs;
    }
  },
  mounted() {
    this.scripts = {
      environment: 'homepage',
      script_tag: `(function(i,s,o,g,r,a,m){if(s.getElementById(g)){return};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.id=g;a.async=1;a.src=r;m.parentNode.insertBefore(a,m)})(window,document,"script","crema-jssdk","//widgets.cre.ma/${this.currentBrand.code}/init.js");`
    };
  }
};
</script>

<style scoped>
.ReviewSettingsWidgetScriptDialog__product-code {
  width: 160px;
  margin-left: 8px;
}

.ReviewSettingsWidgetScriptDialog__image-width {
  width: 80px;
}

.ReviewSettingsWidgetScriptDialog__target-widget-id {
  width: 70px;
}
</style>

<i18n locale="ko">
{
  "title": "위젯 스크립트 복사하기",
  "section_script": "사이트에 삽입할 스크립트",
  "section_condition": "스크립트 조건 설정",
  "environment": "스크립트를 사용할 사이트 선택",
  "environment_homepage": "쇼핑몰",
  "environment_market": "오픈마켓",
  "market_image_width": "오픈마켓에 삽입할 이미지 가로 크기 (px)",
  "market_image_width_placeholder": "가로 크기",
  "market_target_widget_id": "오픈마켓에 삽입한 이미지를 클릭하면 이동할 위젯 선택",
  "market_target_widget_id_placeholder": "위젯 ID"
}
</i18n>
