var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppForm',_vm._g(_vm._b({attrs:{"id":_vm.SETTINGS_ID,"object-id":"user"},on:{"change-group":_vm.changeGroup},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
var options = ref.options;
var errors = ref.errors;
var error = ref.error;
return [(id === 'password_changed_at')?[_c('div',{staticClass:"AppForm__group-field"},[_vm._v(" "+_vm._s(_vm.$t('password_changed_at.info', [_vm.elapsedDaysSincePasswordChange]))+" ")]),(_vm.elapsedDaysSincePasswordChange > 180)?_c('AppFormError',{attrs:{"error":{ errorMessage: _vm.$t('password_changed_at.warning') }}}):_vm._e()]:(id === 'session_timeout_enabled')?[_c('div',{class:[
            'AppForm__group-field',
            {
              'AppForm__group-field--disabled': !_vm.formObject.session_timeout_enabled
            }
          ]},[_c('i18n',{attrs:{"path":"session_timeout_enabled.session_timeout_in_minutes"}},[_c('AppNumberInput',{attrs:{"name":"user[session_timeout_in_minutes]","digits":2,"invalid":!!errors.session_timeout_in_minutes,"disabled":!_vm.formObject.session_timeout_enabled,"inline":""},on:{"blur":function($event){return _vm.validateField('session_timeout_in_minutes')},"change":function($event){return _vm.validateField('session_timeout_in_minutes')}},model:{value:(_vm.formObject.session_timeout_in_minutes),callback:function ($$v) {_vm.$set(_vm.formObject, "session_timeout_in_minutes", $$v)},expression:"formObject.session_timeout_in_minutes"}})],1),_c('AppFormError',{attrs:{"error":errors.session_timeout_in_minutes}})],1)]:(id === 'email')?[_c('AppEmailVerificationInput',{attrs:{"id":"email","name":"user[email]","email-verification-status":_vm.formObject.email_verification_status,"placeholder":_vm.$t('email.placeholder'),"invalid":error && !error.after},on:{"blur":function($event){_vm.formObject.email_verification_status !== 'verified_and_used' &&
              _vm.validateField('email')},"change":function($event){return _vm.validateField('email')},"status":_vm.setEmailstatus},model:{value:(_vm.formObject.email),callback:function ($$v) {_vm.$set(_vm.formObject, "email", $$v)},expression:"formObject.email"}})]:(id === 'marketing')?[_c('div',{staticClass:"AppForm__group-title AppFrom__group-title--marketing"},[_vm._v(" "+_vm._s(_vm.$t('marketing.label'))+" "),(_vm.isAdminLocaleKo)?_c('AppButton',{attrs:{"label":_vm.$t('terms.link'),"type":"external_link","button-style":"blue-clear","to":"https://assets.cre.ma/m/crema_marketinguse.pdf"}}):_vm._e()],1),_c('div',{staticClass:"AppForm__group-field"},[_c('AppMultipleSelectCheckbox',{attrs:{"name":"user[marketing][]","options":options},model:{value:(_vm.formObject.terms_agreements),callback:function ($$v) {_vm.$set(_vm.formObject, "terms_agreements", $$v)},expression:"formObject.terms_agreements"}})],1)]:_vm._e()]}}])},'AppForm',_vm.formProps,false),_vm.formEvents))],1)}
var staticRenderFns = []

export { render, staticRenderFns }