<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="campaign.name"
    can-maximize
    @close="close"
  >
    <TargetCampaignProductRecommendationWidgetPreview
      slot="body"
      :show-pc-as-default="showPcAsDefault"
      :with-form="false"
    />
  </AppModalDefault>
</template>

<script>
import { mapState } from 'vuex';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'TargetCampaignProductRecommendationWidgetPreviewDialog',
  mixins: [DialogView],
  props: {
    showPcAsDefault: { type: Boolean, default: true, required: true }
  },
  computed: {
    ...mapState('targetCampaign', ['campaign'])
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppModalDefault__body {
    overflow-y: hidden;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  @media (max-height: 930px) {
    .AppModalDefault__body {
      overflow-y: scroll;
    }
  }
}
</style>

<i18n locale="ko">
{
  "title": "미리보기"
}
</i18n>
