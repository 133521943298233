<template>
  <AppDataSection :title="title">
    <div class="TargetSettingsSection__button-wrapper">
      <AppButton
        v-if="withPreview"
        :label="$t('preview')"
        @click="$emit('open-preview')"
      />
      <AppButton :label="$t('app.settings')" @click="$emit('open-settings')" />
    </div>
    <slot />
  </AppDataSection>
</template>

<script>
export default {
  name: 'TargetSettingsSection',
  props: {
    title: { type: String, required: true },
    withPreview: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
.TargetSettingsSection__button-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>

<i18n locale="ko">
{
  "preview": "미리보기"
}
</i18n>
