<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="name"
    :form-props="{ ...formProps, objectId: 'product' }"
    :is-loading="isLoading"
    v-on="formEvents"
  >
    <template #group="{ id, value }">
      <div v-if="id === 'image'" class="AppForm__group-field">
        <AppImage :src="product.image_url" />
      </div>
      <AppData
        v-else-if="id === 'discount_price_cents'"
        class="AppForm__group-field"
        type="currency"
        :value="value"
      />
      <div v-else-if="id === 'product_options'" class="AppForm__group-field">
        <div class="table-head-control">
          <AppButton
            :label="$t('product_options.add_option_button')"
            :disabled="!unselectedOptionTypeId"
            @click="addProductOption"
          />
        </div>
        <AppTable
          table-id="product-options-table"
          :columns="[
            { id: 'option_type', label: $t('product_options.option_type') },
            { id: 'option_values', label: $t('product_options.option_values') },
            { id: 'actions', label: $t('app.actions') }
          ]"
          :rows="productOptions"
          :no-data="$t('product_options.no_data')"
        >
          <template #cell="{ row, rowIndex, column }">
            <template v-if="column.id === 'option_type'">
              <AppSelect
                :options="productOptionTypeOptions(row.option_type_id)"
                :value="row.option_type_id"
                class="ProductProductEditDialog__option-type-select"
                @change="changeProductOption(rowIndex, $event)"
              />
            </template>
            <template v-else-if="column.id === 'option_values'">
              <AppSelectProductOptionValue
                :product-option-type-id="row.option_type_id"
                :product-option-values="row.option_values"
                multiple
                @change-items="changeProductOptionValues(rowIndex, $event)"
              />
            </template>
            <template v-else-if="column.id === 'actions'">
              <AppButton
                button-style="red-outline"
                :label="$t('app.delete')"
                @click="removeProductOption(rowIndex)"
              />
            </template>
          </template>
        </AppTable>
        <AppHiddenInputArray
          name="product[product_option_value_ids][]"
          :values="product.product_option_value_ids"
        />
      </div>
      <div v-else-if="id === 'colors'" class="AppForm__group-field">
        <AppColorChip
          v-for="(colors, i) in product.color_chips"
          :key="i"
          :colors="colors"
        />
      </div>
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ProductProductEditDialog',
  mixins: [DialogFormView],
  props: {
    id: { type: Number, required: true },
    name: { type: String, required: true }
  },
  data() {
    return {
      isLoading: true,
      productOptionTypes: [],
      productOptions: [],
      isProductCodeDuplicated: false,
      existingProductCode: null
    };
  },
  computed: {
    ...mapState('session', ['brandSettings', 'reviewSettings']),
    ...mapGetters('session', ['isShopifyBrand']),
    formSections() {
      if (this.isLoading) return [];

      return [
        {
          id: 'basic',
          groups: [
            { id: 'image', label: this.$t('form.image') },
            {
              id: 'name',
              label: this.$t('form.name'),
              type: 'text',
              validate: ['required']
            },
            {
              id: 'org_price_cents',
              label: this.$t('form.org_price'),
              type: 'currency',
              digits: 6,
              default: this.product.final_price_cents
            },
            {
              id: 'final_price_cents',
              label: this.$t('form.final_price'),
              type: 'currency',
              digits: 6,
              validate: ['required']
            },
            ...(this.isShopifyBrand
              ? []
              : [
                  {
                    id: 'discount_price_cents',
                    label: this.$t('form.discount_price'),
                    groupTooltip: this.$t('form.discount_price_tooltip')
                  }
                ]),
            { id: 'product_options', label: this.$t('form.product_options') },
            ...(this.isShopifyBrand
              ? []
              : [
                  {
                    id: 'category_ids',
                    label: this.$t('form.category_ids'),
                    type: 'select_product_category',
                    multiple: true,
                    name: 'product[product_category_ids]'
                  }
                ]),
            {
              id: 'sub_product_ids',
              label: this.$t('form.sub_product_ids'),
              type: 'select_product',
              title: this.$t('sub_product_ids.dialog_title'),
              products: this.product.sub_products,
              hint: this.$t('sub_product_ids.hint', { name: this.name }),
              multiple: true,
              defaultFilters: [`exclude_id: ${this.product.id}`],
              eventHandlers: {
                'change-items': products =>
                  this.$set(this.product, 'sub_products', products)
              }
            },
            ...(this.reviewSettings.translate_matched_products
              ? [
                  {
                    id: 'source_brand_product_code',
                    label: this.$t('form.source_brand_product_code'),
                    groupDescription: this.$t(
                      'form.source_brand_product_code_description'
                    ),
                    type: 'text',
                    validate: [
                      {
                        rule: () => !this.isProductCodeDuplicated,
                        errorMessage: this.$t(
                          'form.source_brand_product_code_error',
                          [this.existingProductCode]
                        )
                      }
                    ]
                  }
                ]
              : [])
          ]
        }
      ];
    },
    product() {
      return this.formObject;
    },
    unselectedOptionTypeId() {
      const selectedIds = this.productOptions.map(o => o.option_type_id);
      const unselectedOptionType = this.productOptionTypes.find(
        t => !selectedIds.includes(t.id)
      );
      return unselectedOptionType ? unselectedOptionType.id : null;
    }
  },
  mounted() {
    api
      .get(`/product/products/${this.id}/edit`)
      .then(({ data }) => {
        this.orgFormObject = {
          id: this.id,
          ..._.omit(data.product, 'product_option_values'),
          sub_product_ids: data.product.sub_products.map(p => p.id).sort(),
          product_option_value_ids: data.product.product_option_values
            .map(v => v.id)
            .sort()
        };
        this.productOptionTypes = data.product_option_types;

        this.productOptions = _.chain(data.product.product_option_values)
          .groupBy('product_option_type_id')
          .map((option_values, option_type_id) => ({
            option_type_id: _.toNumber(option_type_id),
            option_values: this.productOptionValuesSorted(option_values).map(
              v => ({
                id: v.id,
                value: v.value,
                name: v.value,
                position: v.position,
                parent_product_option_value_id: v.parent_product_option_value_id
              })
            )
          }))
          .value();
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    addProductOption() {
      this.productOptions.push({
        option_type_id: this.unselectedOptionTypeId,
        option_values: []
      });
    },
    removeProductOption(index) {
      this.clearProductOptionValues(index);
      this.$delete(this.productOptions, index);
    },
    changeProductOption(index, option_type_id) {
      this.clearProductOptionValues(index);
      this.$set(this.productOptions, index, {
        option_type_id,
        option_values: []
      });
    },
    changeProductOptionValues(index, newItems) {
      this.clearProductOptionValues(index);
      this.productOptions[index].option_values = this.productOptionValuesSorted(
        newItems
      );
      this.product.product_option_value_ids = [
        ...this.product.product_option_value_ids,
        ...newItems.map(i => i.id)
      ].sort();
    },
    clearProductOptionValues(index) {
      const oldIds = this.productOptions[index].option_values.map(v => v.id);
      this.product.product_option_value_ids = _.difference(
        this.product.product_option_value_ids,
        oldIds
      );
    },
    productOptionTypeOptions(selectedId) {
      const disabledIds = this.productOptions
        .filter(o => o.option_type_id !== selectedId)
        .map(o => o.option_type_id);
      return this.productOptionTypes.map(t => ({
        label: t.name,
        value: t.id,
        disabled: disabledIds.includes(t.id)
      }));
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(`/product/products/${this.id}`, formData, {
          successMessage: this.$t('app.saved'),
          silent: true
        })
        .then(({ data }) => {
          this.eventBus.$emit('save', data.product);
          this.close(true);
        })
        .catch(error => {
          this.isSubmitting = false;
          const errors = error.response.data.errors;
          if (
            errors.some(
              e => e.attribute === 'product_detail.source_brand_product_code'
            )
          ) {
            this.isProductCodeDuplicated = true;
            this.existingProductCode = errors.find(
              e => e.attribute === 'product_detail.source_brand_product_code'
            ).error_detail.existing_product_code;
            this.validateField('source_brand_product_code');
          }
        })
        .finally(() => {
          this.isSubmitting = false;
          this.isProductCodeDuplicated = false;
        });
    },
    productOptionValuesSorted(product_option_values) {
      return product_option_values.sort(
        (a, b) =>
          (a.parent_product_option_value_id || 0) -
            (b.parent_product_option_value_id || 0) ||
          a.position - b.position ||
          a.id - b.id
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.ProductProductEditDialog__option-type-select {
  width: 100%;
}

::v-deep {
  .product-options-table__option-type {
    width: 176px;
  }

  .product-options-table__actions {
    width: 72px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "상품 수정하기",
  "form": {
    "image": "상품 사진",
    "name": "상품명",
    "org_price": "소비자가",
    "final_price": "판매가",
    "discount_price": "할인 판매가",
    "discount_price_tooltip": "카페24와 메이크샵의 기간 할인 설정에 대해서만 계산할 수 있으며,\n해당 팝업에는 PC/비회원 기준의 가격을 표시합니다.",
    "product_options": "상품 옵션",
    "category_ids": "상품 카테고리",
    "sub_product_ids": "리뷰를 연동할 세트 상품",
    "source_brand_product_code": "연동 대상몰 원본 상품 번호",
    "source_brand_product_code_description": "원본 상품과 1:1로 매칭되므로 하나의 상품번호만 입력해주세요. 2개 이상 입력시 연동 자체가 불가합니다.",
    "source_brand_product_code_error": "연동 대상몰 원본 상품 번호가 이미 다른 상품 (상품번호: {0})에 저장되어 있습니다"
  },
  "product_options": {
    "add_option_button": "+ 상품 옵션 추가",
    "option_type": "상품 옵션",
    "option_values": "해당 옵션에 사용할 옵션값",
    "no_data": "상품 옵션이 없습니다."
  },
  "sub_product_ids": {
    "dialog_title": "리뷰를 연동할 세트 상품 선택",
    "hint": "선택한 상품의 리뷰 → 현재 상품({name})으로 연동합니다."
  }
}
</i18n>
