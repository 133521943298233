<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Normal</label>
      </div>
      <AppImageInput id="normal" @change="valueNormal = $event" />
      <div class="ui-section__description">
        Selected Value: {{ valueNormal }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppImageInput id="disabled" disabled @change="valueDisabled = $event" />
      <div class="ui-section__description">
        Selected Value: {{ valueDisabled }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppImageInput id="invalid" invalid @change="valueInvalid = $event" />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="preview">Preview</label>
      </div>
      <AppImageInput
        id="preview"
        image-url="https://assets.cre.ma/m/custom/sampler/crema_logo.png"
        name="preview"
        @change="valuePreview = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ valuePreview }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="not-removable">Not Removable</label>
      </div>
      <AppImageInput
        id="not-removable"
        :image-url="valueNotRemovable.imageUrl"
        name="not_removable"
        not-removable
        @change="valueNotRemovable = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueNotRemovable }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="no-preview">No Preview</label>
      </div>
      <AppImageInput
        id="no-preview"
        :image-url="valueNoPreview.imageUrl"
        name="no_preview"
        no-preview
        @change="valueNoPreview = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueNoPreview }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiImageInput',
  data() {
    return {
      valueNormal: {},
      valueDisabled: {},
      valueInvalid: {},
      valuePreview: {
        imageUrl: 'https://assets.cre.ma/m/custom/sampler/crema_logo.png'
      },
      valueNotRemovable: {
        imageUrl: 'https://assets.cre.ma/m/custom/sampler/crema_logo.png'
      },
      valueNoPreview: {
        imageUrl: 'https://assets.cre.ma/m/custom/sampler/crema_logo.png'
      }
    };
  }
};
</script>
