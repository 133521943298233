<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="cohort.product_display_name"
    width="middle"
    can-maximize
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <div class="DisplayProductDisplayCohortDialog__info">
          <div class="DisplayProductDisplayCohortDialog__display-range">
            <div class="DisplayProductDisplayCohortDialog__label">
              {{ $t('display_range.title') }}
            </div>
            <div class="DisplayProductDisplayCohortDialog__content">
              {{ displayRange }}
            </div>
          </div>
          <div class="DisplayProductDisplayCohortDialog__nav-button">
            <AppButton
              :label="$t('button.previous_cohort')"
              :disabled="!cohort.prev_cohort_id"
              @click="fetchData(cohort.prev_cohort_id)"
            />
            <AppButton
              :label="$t('button.next_cohort')"
              :disabled="!cohort.next_cohort_id"
              @click="fetchData(cohort.next_cohort_id)"
            />
          </div>
        </div>
        <div
          v-if="isCategoryPage"
          class="DisplayProductDisplayCohortDialog__corners"
        >
          <div class="DisplayProductDisplayCohortDialog__label">
            {{ $t('corners.title') }}
          </div>
          <div class="DisplayProductDisplayCohortDialog__content">
            <template v-if="corners.length > 1">
              <i18n path="unit.omitted_count">
                <AppSelect
                  v-model="cornerCode"
                  :options="
                    corners.map(corner => ({
                      label: corner.name,
                      value: corner.code
                    }))
                  "
                />
                {{ corners.length - 1 }}
              </i18n>
            </template>
            <template v-else-if="corners.length">
              {{ corners[0].name }}
            </template>
          </div>
          <div class="DisplayProductDisplayCohortDialog__content">
            <span
              v-for="key in [
                'products_count',
                'added_products_count',
                'removed_products_count'
              ]"
              :key="key"
              class="DisplayProductDisplayCohortDialog__corners-detail"
            >
              {{ $t(`corners.${key}`)
              }}<b
                class="DisplayProductDisplayCohortDialog__corners-detail-number"
                >{{ cornerSnapshot[key] }}</b
              >
            </span>
          </div>
        </div>
        <DisplayProductDisplayPreviewSettingsButton
          class="DisplayProductDisplayCohortDialog__preview-settings-button"
        />
        <DisplayCornerSnapshot
          :products="cornerSnapshot.products || []"
          :show-spare-items-separately="false"
        />
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';
import DisplayPollProductsView from '@/mixins/DisplayPollProductsView';
import DisplayProductDisplayPreviewSettingsButton from './DisplayProductDisplayPreviewSettingsButton';
import DisplayCornerSnapshot from './DisplayCornerSnapshot';

export default {
  name: 'DisplayProductDisplayCohortDialog',
  components: {
    DisplayProductDisplayPreviewSettingsButton,
    DisplayCornerSnapshot
  },
  mixins: [DialogView, DisplayPollProductsView],
  props: {
    cohortId: { type: Number, required: true },
    defaultCornerCode: { type: String, default: null }
  },
  data() {
    return { isLoading: true, cohort: {}, cornerCode: null };
  },
  computed: {
    ...mapState('displayProductDisplays', ['cornerPageType']),
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    displayRange() {
      const { cohort } = this;

      const displayAt = this.formatDateTimeWithoutSecond(cohort.display_at);
      const displayStartedAt = this.formatDateTimeWithoutSecond(
        cohort.display_started_at
      );
      const displayEndedAt = this.formatDateTimeWithoutSecond(
        cohort.display_ended_at
      );

      if (displayEndedAt) return `${displayStartedAt} - ${displayEndedAt}`;
      else if (displayStartedAt)
        return this.$t('display_range.displaying', [displayStartedAt]);
      else return this.$t('display_range.scheduled', [displayAt]);
    },
    corners() {
      return this.cohort.corners || [];
    },
    cornerSnapshot() {
      const { corner_snapshots } = this.cohort;
      if (!corner_snapshots) return {};

      return (
        corner_snapshots.find(
          corner => corner.corner_code === this.cornerCode
        ) || {}
      );
    }
  },
  watch: {
    cornerSnapshot() {
      if (!this.cornerSnapshot.products) return;

      this.setImageDownloadingProductIds(this.cornerSnapshot.products);
    }
  },
  mounted() {
    this.fetchData(this.cohortId);
  },
  methods: {
    ...mapMutations('displayPreviewSettings', ['UPDATE_PREVIEW_SETTINGS']),
    fetchData(id) {
      this.isLoading = true;

      api
        .get(`/display/product_display/cohorts/${id}`, {
          params: {
            page_type: this.cornerPageType,
            corner_code: this.defaultCornerCode
          }
        })
        .then(({ data }) => {
          this.cohort = data.cohort;
          this.cornerCode =
            this.defaultCornerCode || data.cohort.corners[0].code;
          this.UPDATE_PREVIEW_SETTINGS(data.preview_settings);
        })
        .finally(() => (this.isLoading = false));
    },
    doUpdateImageDownloadedProducts(downloadedProductsMap) {
      this.cornerSnapshot.products = this.cornerSnapshot.products.map(
        product => ({
          ...product,
          ...(downloadedProductsMap[product.id] || {})
        })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayProductDisplayCohortDialog__info {
  display: flex;
  justify-content: space-between;
}

.DisplayProductDisplayCohortDialog__corners {
  margin-top: 32px;
}

.DisplayProductDisplayCohortDialog__label {
  @include text-label;
}

.DisplayProductDisplayCohortDialog__content {
  @include text-main-navigation;

  margin-top: 4px;
}

.DisplayProductDisplayCohortDialog__nav-button {
  margin-top: 4px;
}

.DisplayProductDisplayCohortDialog__corners-detail {
  @include text-content;

  & + & {
    margin-left: 16px;
  }
}

.DisplayProductDisplayCohortDialog__corners-detail-number {
  margin-left: 4px;
}

.DisplayProductDisplayCohortDialog__preview-settings-button {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "title": "진열 이력 상세",
  "display_range": {
    "title": "진열 기간",
    "displaying": "{0} - 진열 중",
    "scheduled": "{0} 진열 예정"
  },
  "corners": {
    "title": "대상 카테고리",
    "products_count": "총 진열 상품 수:",
    "added_products_count": "카테고리에 추가한 상품 수:",
    "removed_products_count": "카테고리에서 제거한 상품 수:"
  },
  "button": {
    "previous_cohort": "이전 진열",
    "next_cohort": "다음 진열"
  }
}
</i18n>
