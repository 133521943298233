<template>
  <div class="TheToasts">
    <AppToastTransition :gap="10" :items="toasts">
      <template #item="{ item }">
        <div class="TheToasts__item">
          <AppToast :toast="item" />
        </div>
      </template>
    </AppToastTransition>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TheToasts',
  computed: mapState('toast', ['toasts'])
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_z-indexes.scss';
@import '@/scss/mixins/_breakpoints.scss';

.TheToasts {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 80px;
  pointer-events: none;
  z-index: $z-index-popover;
}

.TheToasts__item {
  pointer-events: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;

  @include media-breakpoint-each(mobile) {
    margin-left: 6px;
    margin-right: 6px;
    max-width: none;
  }
}
</style>
