<template>
  <TheLoginView
    :is-loading="isSubmitting"
    :title="$t('title')"
    :sub-title="$t('title')"
    :message="isSubmitted ? $t('message') : null"
    :sub-message="isSubmitted ? $t('sub_message', [formObject.email]) : null"
  >
    <AppButton
      v-if="isSubmitted"
      class="TheLoginView__action-button"
      type="link"
      to="/login"
      :label="$t('login_button')"
      size="large"
    />
    <template v-else>
      <AppForm
        id="user"
        v-bind="formProps"
        object-id="user"
        focus-group-id="email"
        form-style="wide"
        validate-only-on-submit
        no-screen
        :submit-button="{
          submitLabel: $t('submit'),
          submittingLabel: $t('submitting')
        }"
        v-on="formEvents"
      />
      <div class="TheLoginView__link">
        <AppInternalLink link-style="blue" to="/login">{{
          $t('back_to_login')
        }}</AppInternalLink>
      </div>
    </template>
  </TheLoginView>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import TheLoginView from './TheLoginView';

export default {
  name: 'TheAccountRecovery',
  components: { TheLoginView },
  mixins: [FormView],
  data() {
    return {
      formObject: { email: '' },
      detectFormDataChange: false,
      isSubmitted: false
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'email',
              label: this.$t('email'),
              placeholder: this.$t('email_placeholder'),
              type: 'email',
              autocomplete: 'username',
              required: false,
              validate: ['required', 'email_format']
            }
          ]
        }
      ];
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/user/password', formData)
        .then(() => (this.isSubmitted = true))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "비밀번호 찾기",
  "email": "이메일 주소",
  "email_placeholder": "가입할 때 사용한 이메일 주소를 입력해주세요.",
  "submit": "비밀번호 재설정 링크 발송",
  "submitting": "비밀번호 재설정 링크 발송 중..",
  "message": "다음 주소로 비밀번호 재설정 링크를 발송하였습니다.",
  "sub_message": "{0}\n\n\n이메일의 링크를 클릭하여 비밀번호를 재설정해주세요.\n메일이 보이지 않으면 휴지통, 스팸 메일함, 광고 메일함 등\n메일이 있을 만한 다른 곳을 확인해보세요.",
  "login_button": "로그인 페이지로 이동",
  "back_to_login": "로그인 페이지로 돌이가기"
}
</i18n>
