const state = {
  displayedProducts: [],
  displayedProductsCornersMap: {},
  scheduledProductsCornersMap: {}
};

const getters = {
  displayedCorner: ({ displayedProductsCornersMap }) => productId =>
    displayedProductsCornersMap[productId],
  scheduledCorner: ({ scheduledProductsCornersMap }) => productId =>
    scheduledProductsCornersMap[productId]
};

const mutations = {
  SET_DISPLAYED_PRODUCTS: (state, displayedProducts) =>
    (state.displayedProducts = displayedProducts),
  SET_DISPLAYED_PRODUCTS_CORNERS_MAP: (state, displayedProductsCornersMap) =>
    (state.displayedProductsCornersMap = displayedProductsCornersMap),
  SET_SCHEDULED_PRODUCTS_CORNERS_MAP: (state, scheduledProductsCornersMap) =>
    (state.scheduledProductsCornersMap = scheduledProductsCornersMap)
};

export default { namespaced: true, state, getters, mutations };
