<template>
  <div class="AppVideoInput">
    <AppFileInput
      v-bind="{
        disabled: disabled,
        invalid: video.invalid || video.isUploading || uploadFailed,
        placeholder: video.filename || $t('select_video'),
        selectOnMount
      }"
      accept="video/*"
      @change="changeFile"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import { mapState } from 'vuex';
import { DirectUploader, deleteVideo } from '@/lib/video';
import { pathWithParams } from '@/lib/params';

export default {
  name: 'AppVideoInput',
  model: { event: 'change' },
  props: {
    value: { type: Object, required: true },
    selectOnMount: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      video: _.cloneDeep(this.value),
      videoData: null,
      uploadFailed: false
    };
  },
  computed: {
    ...mapState('session', ['directUploadsUrl', 'reviewSettings'])
  },
  watch: {
    video: {
      handler() {
        this.$emit('change', this.video);
      },
      deep: true
    },
    'video.v_id'() {
      this.uploadVideo();
    }
  },
  methods: {
    changeFile(file) {
      this.$set(this.video, 'isUploading', true);
      this.getThumbnailFromVideo(file);
      this.videoData = file;
      this.createVideo(file);
    },
    getThumbnailFromVideo(file) {
      const reader = new FileReader();
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        const url = URL.createObjectURL(blob);
        const video = document.createElement('video');

        const captureImage = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas
            .getContext('2d')
            .drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageUrl = canvas.toDataURL();
          if (imageUrl.startsWith('data:image'))
            this.$set(this.video, 'thumbnail_url', imageUrl);
          else this.setDefaultThumnbnailUrl();

          URL.revokeObjectURL(url);
        };
        video.addEventListener('loadeddata', () => captureImage(), {
          once: true
        });

        video.preload = 'metadata';
        video.src = url;
        video.muted = true;
        video.playsInline = true;
        const playPromise = video.play();
        if (playPromise !== undefined)
          playPromise.catch(this.setDefaultThumnbnailUrl);
      };
      reader.readAsArrayBuffer(file);
    },
    setDefaultThumnbnailUrl() {
      this.$set(
        this.video,
        'thumbnail_url',
        this.reviewSettings.default_video_thumbnail_url
      );
    },
    createVideo({ size, type }) {
      api
        .post(
          '/video/create',
          { contents_size: size, file_type: type },
          { silent: true }
        )
        .then(({ data }) => {
          this.$set(this.video, 'v_id', data.video_id);
        })
        .catch(({ response }) => {
          this.$set(this.video, 'isUploading', false);
          this.uploadFailed = true;
          alert(this.$t(`alert.${response.data.errors[0]}`));
        });
    },
    uploadVideo() {
      const url = pathWithParams(this.directUploadsUrl, {
        video_id: this.video.v_id
      });
      const uploader = new DirectUploader(
        this.videoData,
        url,
        this.setUploadProgress
      );
      uploader.upload.create(error => {
        if (error) {
          alert(error);
          this.uploadFailed = true;
          this.deleteObsoleteVideo();
        } else {
          this.$delete(this.video, 'invalid');
          this.createUploadJob();
        }
        this.$set(this.video, 'isUploading', false);
      });
    },
    createUploadJob() {
      api.post(
        '/video/create_upload_job',
        { video_id: this.video.v_id },
        { silent: true }
      );
    },
    deleteObsoleteVideo() {
      deleteVideo(this.video.v_id);
    },
    setUploadProgress(percentage) {
      this.$emit('progress', percentage);
    }
  }
};
</script>

<style scoped>
.AppVideoInput {
  position: relative;
}
</style>

<i18n locale="ko">
{
  "select_video": "동영상을 등록해 주세요.",
  "alert": {
    "error": {
      "video": {
        "size_limit_exceeded": "동영상은 500mb까지 등록이 가능합니다.",
        "not_video_type": "동영상 파일이 아닙니다."
      }
    }
  }
}
</i18n>
<i18n locale="en">
{
  "select_video": "Please attach video",
  "alert": {
    "error": {
      "video": {
        "size_limit_exceeded": "Videos can be attached up to 500mb.",
        "not_video_type": "It is not a video file."
      }
    }
  }
}
</i18n>
