<template>
  <div class="TheSearchDialogKeywords">
    <div
      v-if="recentlyVisitedPages.length > 0"
      class="TheSearchDialogKeywords__section"
    >
      <div class="TheSearchDialogKeywords__section_title">
        {{ $t('keywords_section_recent') }}
      </div>
      <ul>
        <li
          v-for="(page, index) in recentlyVisitedPages"
          ref="items"
          :key="index"
          :class="[
            'search-dialog-item',
            'search-dialog-item--keyword',
            {
              'search-dialog-item--active': index === activeIndex
            }
          ]"
        >
          <TheSearchDialogResult
            :search-result="page"
            @mousemove-search-result="activateItem(index)"
            @select-search-result="selectItem"
          />
        </li>
      </ul>
    </div>
    <div
      v-if="recommendedKeywordsUsing.length > 0"
      class="TheSearchDialogKeywords__section"
    >
      <div class="TheSearchDialogKeywords__section_title">
        {{ $t('keywords_section_recommendation') }}
      </div>
      <ul>
        <li
          v-for="(keyword, index) in recommendedKeywordsUsing"
          ref="items"
          :key="index"
          :class="[
            'search-dialog-item',
            'search-dialog-item--keyword',
            {
              'search-dialog-item--active':
                recentlyVisitedPages.length + index === activeIndex
            }
          ]"
        >
          <a
            class="search-dialog-item__link"
            @mousemove="activateItem(recentlyVisitedPages.length + index)"
            @click="selectItem"
            >{{ keyword }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TheSearchDialogItemsView from './TheSearchDialogItemsView';
import TheSearchDialogResult from './TheSearchDialogResult';

export default {
  name: 'TheSearchDialogKeywords',
  components: { TheSearchDialogResult },
  mixins: [TheSearchDialogItemsView],
  computed: {
    ...mapState('search', ['recommendedKeywords']),
    ...mapState('editor', ['recentlyVisitedPages']),
    ...mapGetters('session', ['isShopifyBrand']),
    recommendedKeywordsUsing() {
      if (this.isShopifyBrand) return [];
      return this.recommendedKeywords;
    },
    itemsCount() {
      return this.recentlyVisitedPages.length + this.recommendedKeywords.length;
    }
  },
  methods: {
    selectItem() {
      if (this.activeIndex < this.recentlyVisitedPages.length) {
        const searchResult = this.recentlyVisitedPages[this.activeIndex];
        this.$emit('select-result', searchResult);
      } else {
        const keyword = this.recommendedKeywordsUsing[
          this.activeIndex - this.recentlyVisitedPages.length
        ];
        this.$emit('set-query', keyword);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheSearchDialogKeywords__section_title {
  font-size: 13px;
  color: $color-grey-50;
  padding: 16px 16px 8px 16px;
}

.search-dialog-item__link {
  .search-dialog-item--keyword & {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}
</style>

<i18n locale="ko">
{
  "keywords_section_recent": "최근 방문",
  "keywords_section_recommendation": "추천 검색어"
}
</i18n>
<i18n locale="en">
{
  "keywords_section_recent": "Recent Keyword",
  "keywords_section_recommendation": "Recommendation Keyword"
}
</i18n>
