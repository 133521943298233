<template>
  <AppRadioList :items="currentOptions">
    <template #item="{ item: option }">
      <AppCheckbox
        v-bind="option"
        @change="setCurrentHash({ ...currentHash, [option.id]: $event })"
      >
        <template v-if="$scopedSlots.label" #label="labelProps">
          <slot name="label" v-bind="{ ...labelProps, value: option.id }" />
        </template>
        <template v-if="$scopedSlots['sub-item']" #sub-item="subItemProps">
          <slot
            name="sub-item"
            v-bind="{ ...subItemProps, value: option.id }"
          />
        </template>
      </AppCheckbox>
    </template>
  </AppRadioList>
</template>

<script>
import HashSelectable from '@/mixins/HashSelectable';

export default {
  name: 'AppHashSelectCheckbox',
  mixins: [HashSelectable],
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  computed: {
    currentOptions() {
      return this.options.map(option => ({
        ...option,
        checked: !!this.currentHash[option.id]
      }));
    }
  },
  methods: {
    setCurrentHash(hash, emit = true) {
      this.currentHash = { ...hash };
      if (emit) this.$emit('change', this.currentHash);
    }
  }
};
</script>
