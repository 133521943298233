<template>
  <TargetSectionKpi
    v-bind="{ isLoading, title, barTooltip, kpiData, barData }"
    :bar-title="campaignText('bar_title', [currentMonth])"
    :kpi-title="$t('kpi_title', [currentMonth])"
    @click-item="$emit('click-item', $event)"
  />
</template>

<script>
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetSectionKpi from './components/TargetSectionKpi';

export default {
  name: 'TargetCampaignStatsSectionKpi',
  components: { TargetSectionKpi },
  props: {
    campaign: { type: Object, required: true },
    isLoading: { type: Boolean, required: true },
    report: { type: Object, default: null },
    samplingMethod: { type: Number, required: true }
  },
  computed: {
    campaignType() {
      return this.campaign.campaign_type;
    },
    title() {
      return this.campaignText('title');
    },
    campaignTypeKey() {
      return TargetCampaignType.toKey(this.campaignType);
    },
    barData() {
      if (this.isLoading) return [];

      return this.barItems.map(item => ({
        ...item,
        label: this.campaignText(`${item.column}.legend`),
        value: item.hasOwnProperty('value')
          ? item.value
          : this.report[item.column]
      }));
    },
    kpiData() {
      if (this.isLoading) return [];

      return this.kpiColumns.map(column => ({
        column,
        label: this.campaignText(`${column}.label`),
        tooltip: this.campaignText(`${column}.tooltip`),
        value: this.report[column] * 100
      }));
    },
    barTooltip() {
      const total = this.barData.reduce((s, { value }) => s + value, 0);
      return {
        title: this.formatMonthWithYear(new Date()),
        items: this.barData.map(({ label, value, colorIndex }) => {
          const percentage =
            total === 0 ? 0 : ((value / total) * 100).toFixed(2);
          return {
            name: label,
            value: `${this.$t('unit.people', [
              value
            ])} (${this.$t('unit.percentage', [percentage])})`,
            colorIndex
          };
        })
      };
    },
    currentMonth() {
      return this.formatMonth(new Date());
    },
    barItems() {
      switch (this.campaignType) {
        case TargetCampaignType.NEW_VISITOR_SIGNUP:
        case TargetCampaignType.NEW_MEMBER_REENGAGEMENT:
        case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
        case TargetCampaignType.INACTIVE_MEMBER_REENGAGEMENT:
          return [
            {
              column: 'respondents_count',
              colorIndex: 0
            },
            {
              column: 'ignored_count',
              value:
                this.report.targeted_users_count -
                this.report.respondents_count,
              colorIndex: 2
            }
          ];
        case TargetCampaignType.CART_REMINDING:
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
        case TargetCampaignType.COUPON_EXPIRY:
        case TargetCampaignType.INDUCING_REPURCHASE:
        case TargetCampaignType.INTERESTED_PRODUCTS:
        case TargetCampaignType.SELECTED_PRODUCTS:
        case TargetCampaignType.SELECTED_CATEGORIES:
        case TargetCampaignType.UTM_POPUP:
          return [
            {
              column: 'purchased_users_count',
              colorIndex: 0
            },
            {
              column: 'ignored_count',
              value:
                this.report.targeted_users_count -
                this.report.purchased_users_count,
              colorIndex: 2
            }
          ];
        case TargetCampaignType.MILEAGE_EXPIRING:
          return [
            {
              column: 'reviewed_sub_orders_count',
              colorIndex: 0
            },
            {
              column: 'ignored_sub_orders_count',
              value:
                this.report.targeted_reviewed_sub_orders_count -
                this.report.reviewed_sub_orders_count,
              colorIndex: 2
            }
          ];
        case TargetCampaignType.PRODUCT_RECOMMENDATION_DETAIL_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_CATEGORY_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_MAIN_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_POPUP:
          return [
            {
              column: 'purchased_users_count',
              colorIndex: 0
            },
            {
              column: 'unique_clicks_only_count',
              value:
                this.report.unique_click_count -
                this.report.purchased_users_count,
              colorIndex: 1
            },
            {
              column: 'ignored_count',
              value:
                this.report.unique_check_count - this.report.unique_click_count,
              colorIndex: 2
            }
          ];
        default:
          return [];
      }
    },
    kpiColumns() {
      switch (this.campaignType) {
        case TargetCampaignType.NEW_VISITOR_SIGNUP:
          return ['respond_rate'];
        case TargetCampaignType.NEW_MEMBER_REENGAGEMENT:
        case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
        case TargetCampaignType.INACTIVE_MEMBER_REENGAGEMENT:
          return ['respond_rate', 'purchase_rate'];
        case TargetCampaignType.CART_REMINDING:
        case TargetCampaignType.COUPON_EXPIRY:
        case TargetCampaignType.INDUCING_REPURCHASE:
        case TargetCampaignType.INTERESTED_PRODUCTS:
        case TargetCampaignType.SELECTED_PRODUCTS:
        case TargetCampaignType.SELECTED_CATEGORIES:
        case TargetCampaignType.UTM_POPUP:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_DETAIL_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_CATEGORY_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_MAIN_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_POPUP:
          return ['purchase_rate'];
        case TargetCampaignType.MILEAGE_EXPIRING:
          return ['review_rate', 'purchase_rate'];
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
          return ['purchase_rate', 'purchase_conversion_rate'];
        default:
          return [];
      }
    }
  },
  methods: {
    campaignText(key, interpolations) {
      return this.$store.getters['targetCampaigns/campaignText'](
        this.campaign,
        `kpi.${key}`,
        interpolations,
        this.samplingMethod
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "kpi_title": "{0} 주요 점검 지표"
}
</i18n>
