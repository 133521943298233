var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AnalyticsInsightDashboardProducts"},[_c('div',{staticClass:"AnalyticsInsightDashboardProducts__title"},[_vm._v(" "+_vm._s(_vm.$t('products_analysis'))+" ")]),_c('AppGrid',_vm._l((['popular', 'risky', 'potential']),function(type,index){return _c('div',{key:index,staticClass:"\n        AnalyticsInsightDashboardProducts__col AppGrid__col AppGrid__col--4\n      "},[_c('AnalyticsInsightSection',{attrs:{"title":_vm.$t(("menu.analytics_insight_products_" + type)),"button-props":{
          label: _vm.$t('view_products_analysis'),
          link: _vm.pathWithDateRangeParams(("insight_products/" + type))
        }}},[_c('AppTable',{staticClass:"AnalyticsInsightDashboardProducts__product-table",attrs:{"columns":_vm.COLUMNS,"rows":_vm.rows(type),"table-id":"analytics-insight-dashboard-products","no-data":_vm.$t('insight.no_data')},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
        var row = ref.row;
        var column = ref.column;
return [(column.id === 'name')?[_c('AppImage',{staticClass:"AnalyticsInsightDashboardProducts__product-image",attrs:{"src":row.src}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  message: _vm.autoTooltip(
                    true,
                    ("Table" + index + (row.rank) + (column.id))
                  )
                }),expression:"{\n                  message: autoTooltip(\n                    true,\n                    `Table${index}${row.rank}${column.id}`\n                  )\n                }"}],ref:("Table" + index + (row.rank) + (column.id)),refInFor:true,staticClass:"AnalyticsInsightDashboardProducts__product-name"},[_vm._v(" "+_vm._s(row.name)+" ")])]:_vm._e()]}}],null,true)})],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }