<template>
  <AppButton
    v-bind="{ id, disabled, buttonStyle }"
    :disabled="disabled || isDownloading"
  >
    <a :href="currentUrl" target="_blank" @click="click"
      >{{ isDownloading ? $t('downloading') : label }}
    </a>
  </AppButton>
</template>

<script>
import { mapGetters } from 'vuex';
import requestDownload from '@/lib/requestDownload';

export default {
  name: 'AppButtonDownload',
  props: {
    id: { type: String, default: null },
    buttonStyle: { type: String, default: undefined },
    url: { type: String, default: '' },
    filename: { type: String, default: null },
    label: {
      type: String,
      default() {
        return this.$t('download');
      }
    },
    disabled: { type: Boolean, default: false },
    eventBus: { type: Object, default: null }
  },
  data() {
    return { isDownloading: false };
  },
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    domainType() {
      return this.url.startsWith('/')
        ? 'same'
        : /^https:\/\/[a-z]?fit[0-9]*\.cre\.ma/.test(this.url)
        ? 'allowed'
        : 'other';
    },
    currentUrl() {
      return this.domainType === 'same'
        ? this.pathWithBrandParams(this.url)
        : this.url;
    }
  },
  watch: {
    eventBus() {
      this.addDownloadEvent();
    }
  },
  mounted() {
    if (this.eventBus) this.addDownloadEvent();
  },
  methods: {
    click(e) {
      if (
        this.domainType === 'other' ||
        e.metaKey ||
        e.altKey ||
        e.ctrlKey ||
        e.shiftKey
      )
        return;

      e.preventDefault();
      this.download(this.currentUrl);
    },
    download(url) {
      this.isDownloading = true;
      requestDownload(url, this.filename).finally(
        () => (this.isDownloading = false)
      );
    },
    addDownloadEvent() {
      this.eventBus.$on('download', url => this.download(url));
    }
  }
};
</script>

<i18n locale="ko">
{
  "download": "다운로드",
  "downloading": "다운로드 중.."
}
</i18n>
