<template>
  <CustomContentHeader
    :resource="campaign"
    resource-type="target_campaign"
    :is-name-editable="isNameEditable"
    @update-name="SET_CAMPAIGN_NAME"
  >
    <template #actions>
      <AppButton
        v-if="showStatsButton"
        class="TargetCampaignShowHead__action-button"
        :label="$t('button_stats')"
        @click="openStatsDialog"
      />
      <TargetCampaignManageButton
        class="TargetCampaignShowHead__action-button"
        :campaign="campaign"
        :label="statusButtonLabel"
        :button-style="statusButtonStyle"
        @delete="$emit('delete')"
        @change-status="SET_CAMPAIGN_STATUS"
      />
    </template>
  </CustomContentHeader>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import TargetCampaignStatus from '@/enums/TargetCampaignStatus';
import TargetCampaignType from '@/enums/TargetCampaignType';

export default {
  name: 'TargetCampaignShowHead',
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    ...mapGetters('targetCampaign', ['isOneTimeCampaign']),
    showStatsButton() {
      return (
        !this.isOneTimeCampaign ||
        this.campaign.status === TargetCampaignStatus.ARCHIVED
      );
    },
    statusButtonStyle() {
      let buttonStyle;
      switch (this.campaign.status) {
        case TargetCampaignStatus.TESTING:
        case TargetCampaignStatus.ARCHIVED:
          buttonStyle = 'grey';
          break;
        case TargetCampaignStatus.DISABLED:
          buttonStyle = 'red-outline';
          break;
        default:
          buttonStyle = 'mint-green';
          break;
      }
      return buttonStyle;
    },
    statusButtonLabel() {
      return TargetCampaignType.statusName(
        this.campaign.campaign_type,
        this.campaign.status
      );
    },
    isNameEditable() {
      return TargetCampaignType.isCreatable(this.campaign.campaign_type);
    }
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN_STATUS']),
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN_NAME']),
    ...mapActions('dialog', ['openDialog']),
    openStatsDialog() {
      this.openDialog([
        TargetCampaignType.isMass(this.campaign.campaign_type)
          ? 'TargetCampaignMassStatsDialog'
          : 'TargetCampaignStatsDialog',
        { campaignId: this.campaign.id }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignShowHead__action-button {
  & + & {
    margin-left: 12px;
  }
}
</style>

<i18n locale="ko">
{
  "back_button": "캠페인 리스트로 이동",
  "button_stats": "통계"
}
</i18n>
