<template>
  <AppModalFormLivePreview
    :title="$t('fit_template.title')"
    :sub-title="fitTemplateName"
    :width="fitTemplate && fitCategory ? null : 'default'"
    :form-props="{
      ...formProps,
      id: 'fit_template_form',
      objectId: 'fit_template'
    }"
    :is-loading="isLoading"
    can-maximize
    v-on="formEvents"
    @change-group="formGroupChanged"
  >
    <template #head>
      <input
        v-if="fitTemplate.size_option_key_ids.length === 0"
        type="hidden"
        name="fit_template[size_option_key_ids][]"
      />
      <input
        v-if="fitTemplate.size_option_key_ids_for_new_line.length === 0"
        type="hidden"
        name="fit_template[size_option_key_ids_for_new_line][]"
      />
      <input
        type="hidden"
        name="fit_template[display_names_json]"
        :value="JSON.stringify(fitTemplate.display_names)"
      />
    </template>
    <AppFitProductLivePreview
      v-if="fitTemplate && fitCategory"
      slot="left"
      v-bind="livePreviewProps"
    />
    <template #group="{ id, inputName, value, error, errors }">
      <div
        v-if="id.endsWith('_title_display_type')"
        class="AppForm__group-field"
      >
        <AppSelectRadio
          v-model="fitTemplate[id]"
          :name="`fit_template[${id}]`"
          :options="TITLE_OPTIONS"
        >
          <template #sub-item="{ value, checked }">
            <template v-if="value === 'text'">
              <AppTextarea
                v-model="fitTemplate[id.replace('_display_type', '')]"
                :name="`fit_template[${id.replace('_display_type', '')}]`"
                :maxlength="255"
                :disabled="!checked"
                :invalid="!!errors[id.replace('_display_type', '')]"
                @blur="validateField(id.replace('_display_type', ''))"
                @change="validateField(id.replace('_display_type', ''))"
              />
              <AppFormError :error="errors[id.replace('_display_type', '')]" />
            </template>
            <template v-else-if="value === 'image'">
              <AppImageInput
                :name="`fit_template[${id.replace('display_type', 'image')}]`"
                :image-url="
                  fitTemplate[id.replace('display_type', 'image_url')]
                "
                :invalid="!!errors[id.replace('display_type', 'image')]"
                :disabled="!checked"
                @change="
                  changeImage($event, id.replace('display_type', 'image'))
                "
                @blur="validateField(id.replace('display_type', 'image'))"
              />
              <AppFormError
                :error="errors[id.replace('display_type', 'image')]"
              />
            </template>
          </template>
        </AppSelectRadio>
        <input
          v-model="fitTemplate[`remove_${id.replace('display_type', 'image')}`]"
          type="hidden"
          :name="`fit_template[remove_${id.replace('display_type', 'image')}]`"
        />
      </div>
      <div
        v-else-if="id === 'fit_sample_image_source_type'"
        class="AppForm__group-field"
      >
        <AppSelectRadio
          v-model="fitTemplate.fit_sample_image_source_type"
          name="fit_template[fit_sample_image_source_type]"
          :options="SAMPLE_IMAGE_OPTIONS"
          @change="validateField(id)"
        >
          <template #sub-item="{ value, checked }">
            <template v-if="value === 'default'">
              <AppSelect
                v-model="fitTemplate.fit_default_sample_image_id"
                name="fit_template[fit_default_sample_image_id]"
                :options="fitDefaultSampleImageOptions"
                :placeholder="$t('app.select')"
                :invalid="!!errors.fit_default_sample_image_id"
                :disabled="!checked"
                @blur="validateField('fit_default_sample_image_id')"
                @change="validateField('fit_default_sample_image_id')"
              />
              <AppImage
                v-if="
                  fitTemplate.fit_sample_image_source_type === 'default' &&
                    defaultSampleImageUrl
                "
                :src="defaultSampleImageUrl"
                class="fit-template-edit-dialog__preview-fit-default-sample-image"
              />
              <AppFormError :error="errors.fit_default_sample_image_id" />
            </template>
            <template v-else-if="value === 'template'">
              <AppImageInput
                name="fit_template[sample_image]"
                :image-url="fitTemplate.sample_image_url"
                :invalid="!!errors.sample_image"
                :disabled="!checked"
                @blur="validateField('sample_image')"
                @change="changeImage($event, 'sample_image')"
              />
              <AppFormError :error="errors.sample_image" />
            </template>
          </template>
        </AppSelectRadio>
        <input
          v-model="fitTemplate.remove_sample_image"
          type="hidden"
          name="fit_template[remove_sample_image]"
        />
      </div>
      <div
        v-else-if="id === 'size_option_type_name_display_method'"
        class="AppForm__group-field"
      >
        <AppSelectRadio
          v-model="fitTemplate.size_option_type_name_display_method"
          name="fit_template[size_option_type_name_display_method]"
          :options="SIZE_OPTION_TYPE_NAME_DISPLAY_METHOD_OPTIONS"
          @change="validateField(id)"
        >
          <template #sub-item="{ value, checked }">
            <template v-if="value === 'custom'">
              <AppTextarea
                v-model="fitTemplate.size_option_type_name"
                name="fit_template[size_option_type_name]"
                :rows="2"
                :maxlength="255"
                :disabled="!checked"
                :invalid="!!errors.size_option_type_name"
                @blur="validateField('size_option_type_name')"
                @change="validateField('size_option_type_name')"
              />
              <AppFormError :error="errors.size_option_type_name" />
            </template>
          </template>
        </AppSelectRadio>
      </div>
      <template v-else-if="id === 'size_option_key_ids'">
        <div class="AppForm__group-field AppForm__group-field--mt12">
          <span
            class="fit-template-edit-dialog__size-option-keys-info-warning"
            >{{ $t('fit_template.size_option_keys_info.warning') }}</span
          >
        </div>
        <div class="AppForm__group-field">
          <AppTable
            :columns="
              [
                {
                  id: 'name',
                  label: $t(`fit_template.size_option_keys_info.name`)
                },
                {
                  id: 'display_name',
                  label: $t(`fit_template.size_option_keys_info.display_name`)
                },
                {
                  id: 'enabled',
                  label: $t(`fit_template.size_option_keys_info.enabled`)
                },
                isNewLineStyle
                  ? {
                      id: 'apply_new_line',
                      label: $t(
                        `fit_template.size_option_keys_info.apply_new_line`
                      )
                    }
                  : null,
                {
                  id: 'sort',
                  label: $t(`fit_template.size_option_keys_info.sort`)
                }
              ].filter(c => c)
            "
            :rows="sizeOptionKeys"
          >
            <template #cell="{ row, column, rowIndex }">
              <template v-if="column.id === 'display_name'">
                <AppTextarea
                  v-model="sizeOptionKeys[rowIndex].displayName"
                  :rows="1"
                  :placeholder="row.name"
                  :disabled="!row.checked"
                />
              </template>
              <template v-else-if="column.id === 'enabled'">
                <AppCheckbox
                  v-model="sizeOptionKeys[rowIndex].checked"
                  name="fit_template[size_option_key_ids][]"
                  :value="row.id"
                  input-style="standalone"
                  :invalid="!!error"
                  @change="validateField(id)"
                />
              </template>
              <template v-else-if="column.id === 'apply_new_line'">
                <AppCheckbox
                  v-model="sizeOptionKeys[rowIndex].applyNewLine"
                  name="fit_template[size_option_key_ids_for_new_line][]"
                  :value="row.id"
                  :disabled="row === firstCheckedSizeOptionKey"
                  input-style="standalone"
                />
              </template>
              <template v-else-if="column.id === 'sort'">
                <AppButtonDraggable
                  :drag-text="row.name"
                  @sort="sortSizeOptionKeys"
                />
              </template>
            </template>
          </AppTable>
        </div>
      </template>
      <div
        v-else-if="id === 'property_option_value_ids'"
        class="AppForm__group-field"
      >
        <AppMultipleSelectCheckbox
          :name="`${inputName}[]`"
          :value="propertyOptionValueIds(value)"
          :options="propertyOptionValueOptions(value)"
          @change="updatePropertyOptionValueIds(value, $event)"
        />
      </div>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import api from '@/lib/api';
import { arrayMoveIndex } from '@/lib/array';
import FitOptionKeyType from '@/enums/FitOptionKeyType';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'FitTemplateEditDialog',
  mixins: [DialogFormView],
  props: {
    fitTemplateId: {
      type: Number,
      default: null
    },
    fitTemplateName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      TITLE_OPTIONS: [
        { label: this.$t('fit_template.text_input'), value: 'text' },
        { label: this.$t('fit_template.image_input'), value: 'image' },
        { label: this.$t('app.none'), value: 'none' }
      ],
      SAMPLE_IMAGE_OPTIONS: [
        { label: this.$t('fit_template.default_image'), value: 'default' },
        { label: this.$t('fit_template.custom_upload'), value: 'template' },
        { label: this.$t('app.none'), value: 'none' }
      ],
      SIZE_OPTION_TYPE_NAME_DISPLAY_METHOD_OPTIONS: [
        {
          label: this.$t(
            'fit_template.size_option_type_name.display_method_default'
          ),
          value: 'default'
        },
        {
          label: this.$t(
            'fit_template.size_option_type_name.display_method_custom'
          ),
          value: 'custom'
        }
      ],
      isLoading: true,
      fitOptionKeys: [],
      sizeOptionKeys: [],
      widget: null,
      combinedFitProductLiquid: null
    };
  },
  computed: {
    ...mapState('fitTemplate', ['fitCategories', 'fitDefaultSampleImages']),
    fitTemplate() {
      return this.formObject;
    },
    orgFitTemplate() {
      return this.orgFormObject;
    },
    formSections() {
      const sections = [
        {
          id: 'basic_section',
          groups: [
            { id: 'name', type: 'text', validate: ['required'] },
            {
              id: 'template_style',
              type: 'select',
              options: ['basic', 'basic_vertical'].map(value => ({
                label: this.$t(`fit_template.template_style.${value}`),
                value
              }))
            },
            {
              id: 'root_fit_category_id',
              type: 'select_filterable',
              options: this.depth1FitCategoryOptions,
              placeholder: this.$t('fit_template.select_fit_category'),
              validate: ['required']
            },
            {
              id: 'fit_category_id',
              type: 'select_radio',
              options: this.depth2FitCategoryOptions
            },
            {
              id: 'enabled_sections',
              type: 'hash_select_checkbox',
              options: [
                'sample_enabled',
                'size_enabled',
                'info_enabled',
                'property_enabled',
                'etc_enabled'
              ].map(enabledSection => ({
                id: enabledSection,
                label: this.$t(`fit_template.${enabledSection}`)
              }))
            }
          ]
        }
      ];

      if (this.fitTemplate.sample_enabled) {
        const imageSourceType = this.fitTemplate.fit_sample_image_source_type;
        sections.push({
          id: 'sample_section',
          groups: [
            this.titleGroupOption('sample_title'),
            {
              id: 'fit_sample_image_source_type',
              fields:
                imageSourceType === 'default'
                  ? [
                      {
                        id: 'fit_default_sample_image_id',
                        validate: ['required']
                      }
                    ]
                  : imageSourceType === 'template'
                  ? [
                      {
                        id: 'sample_image',
                        value: () =>
                          this.fitTemplate.remove_sample_image
                            ? null
                            : this.fitTemplate.sample_image_url,
                        validate: ['required']
                      }
                    ]
                  : [],
              required: imageSourceType !== 'none'
            }
          ]
        });
      }

      if (this.fitTemplate.size_enabled) {
        const displayMethod = this.fitTemplate
          .size_option_type_name_display_method;
        sections.push({
          id: 'size_section',
          groups: [
            this.titleGroupOption('size_title'),
            {
              id: 'size_option_type_name_display_method',
              fields:
                displayMethod === 'custom'
                  ? [{ id: 'size_option_type_name', validate: ['required'] }]
                  : [],
              required: displayMethod === 'custom'
            },
            {
              id: 'size_option_key_ids',
              validate: [
                {
                  rule: () => {
                    return this.sizeOptionKeys.some(obj => obj.checked);
                  },
                  errorMessage: this.$t('validations.required')
                }
              ],
              required: true
            },
            { id: 'size_description_html', type: 'textarea', rows: 12 }
          ]
        });
      }

      if (this.fitTemplate.info_enabled) {
        sections.push({
          id: 'info_section',
          groups: [
            this.titleGroupOption('info_title'),
            {
              id: 'info_option_key_ids',
              type: 'multiple_select_box',
              options: this.infoOptionKeys,
              validate: ['required']
            }
          ]
        });
      }

      if (this.fitTemplate.property_enabled) {
        sections.push({
          id: 'property_section',
          groups: [
            this.titleGroupOption('property_title'),
            {
              id: 'property_option_key_ids',
              type: 'multiple_select_box',
              options: this.propertyOptions,
              validate: ['required'],
              eventHandlers: {
                select: this.propertyOptionKeySelected,
                unselect: this.propertyOptionKeyUnselected
              }
            }
          ]
        });

        sections.push({
          id: 'selected_property_options',
          groups: this.selectedPropertyOptionKeys.map(key => ({
            id: 'property_option_value_ids',
            label: key.display_name,
            value: key
          }))
        });
      }

      if (this.fitTemplate.etc_enabled) {
        sections.push({
          id: 'etc_section',
          groups: [
            { id: 'etc_description_html', type: 'textarea', rows: 20 },
            { id: 'css', type: 'code_editor', lang: 'css' }
          ]
        });

        sections.push({
          id: 'note',
          groups: [
            {
              id: 'note',
              type: 'textarea',
              rows: 20,
              placeholder: this.$t('fit_template.note_info')
            }
          ]
        });
      }

      return sections;
    },
    depth1FitCategoryOptions() {
      const depth1FitCategories = this.fitCategories.filter(
        fit_category => fit_category.parent_fit_category_id === null
      );
      return depth1FitCategories.map(category => ({
        label: category.name,
        value: category.id
      }));
    },
    depth2FitCategoryOptions() {
      return this.fitTemplate.root_fit_category_id
        ? this.fitCategories
            .filter(
              fit_category =>
                fit_category.parent_fit_category_id ==
                this.fitTemplate.root_fit_category_id
            )
            .map(c => ({ label: c.name, value: c.id }))
        : [];
    },
    fitCategory() {
      return this.fitCategories.find(
        fitCategory => fitCategory.id == this.fitTemplate.root_fit_category_id
      );
    },
    fitDefaultSampleImageOptions() {
      return this.fitDefaultSampleImages
        .filter(
          image => image.fit_category_id === this.fitTemplate.fit_category_id
        )
        .map(image => ({
          label: image.name,
          value: image.id
        }));
    },
    defaultSampleImageUrl() {
      const image = this.fitDefaultSampleImages.find(
        image => image.id == this.fitTemplate.fit_default_sample_image_id
      );
      return image ? image.image_url : null;
    },
    infoOptionKeys() {
      const filteredInfoOptionKeys = this.fitOptionKeys.filter(
        key => key.fit_option_key_type === FitOptionKeyType.INFO
      );

      return filteredInfoOptionKeys.map(key => ({
        label: key.display_name,
        value: key.id
      }));
    },
    selectedPropertyOptionKeys() {
      return this.propertyOptionKeys.filter(key =>
        this.fitTemplate.property_option_key_ids.includes(key.id)
      );
    },
    propertyOptionKeys() {
      return this.fitOptionKeys.filter(
        key => key.fit_option_key_type === FitOptionKeyType.PROPERTY
      );
    },
    propertyOptions() {
      return this.propertyOptionKeys.map(key => ({
        label: key.display_name,
        value: key.id
      }));
    },
    isNewLineStyle() {
      return this.fitTemplate.template_style !== 'basic_vertical';
    },
    livePreviewProps() {
      return {
        fitProduct: {
          fit_template_id: this.fitTemplate.id,
          fit_product_sizes: this.fitTemplate.size_option_key_ids.map(
            fit_option_key_id => ({
              fit_option_key_id,
              product_option_value_id: null,
              value: 1
            })
          ),
          fit_sample_image_source_type: 'template',
          product_option_types: [],
          product_option_type_id: null,
          fit_option_value_ids: [],
          fit_product_options: [],
          etc_description_html: this.fitTemplate.etc_description_html,
          size_description_html: this.fitTemplate.size_description_html
        },
        fitTemplate: {
          ...this.fitTemplate,
          size_option_keys: this.sizeOptionKeys.map(k => ({
            id: k.id,
            display_name: k.displayName || k.name
          })),
          info_option_keys: this.infoOptionKeys
            .filter(k => this.fitTemplate.info_option_key_ids.includes(k.value))
            .map(k => ({ id: k.value, display_name: k.label })),
          property_option_keys: this.selectedPropertyOptionKeys.map(k => ({
            display_name: k.display_name,
            option_values: k.fit_option_values.filter(v =>
              this.fitTemplate.property_option_value_ids.includes(v.id)
            )
          }))
        },
        fitDefaultSampleImages: this.fitDefaultSampleImages,
        widgetCss: this.widget.css,
        liquidTemplate: this.combinedFitProductLiquid || {
          fit_product_liquid: this.widget.fit_product_liquids[
            this.fitTemplate.template_style
          ]
        }
      };
    },
    firstCheckedSizeOptionKey() {
      return this.sizeOptionKeys.find(k => k.checked);
    }
  },
  watch: {
    sizeOptionKeys: {
      handler() {
        this.fitTemplate.display_names = this.sizeOptionKeys
          .filter(k => k.displayName && k.displayName !== k.display_name)
          .reduce((o, k) => {
            o[k.id] = k.displayName;
            return o;
          }, {});
        this.fitTemplate.size_option_key_ids = this.sizeOptionKeys
          .filter(k => k.checked)
          .map(k => k.id);
        this.fitTemplate.size_option_key_ids_for_new_line = this.sizeOptionKeys
          .filter(k => k.applyNewLine)
          .map(k => k.id);
      },
      deep: true
    }
  },
  mounted() {
    const url = this.fitTemplateId
      ? `/fit/templates/${this.fitTemplateId}/edit`
      : '/fit/templates/new';
    api
      .get(url)
      .then(({ data }) => {
        this.orgFormObject = data.fit_template;
        this.fitOptionKeys = data.fit_option_keys;
        this.widget = data.widget;
        this.combinedFitProductLiquid = data.combined_fit_product_liquid;
        this.$nextTick(this.updateSizeOptionKeys);
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('fitTemplate', ['ADD_FIT_TEMPLATE', 'UPDATE_FIT_TEMPLATE']),
    submit(formData) {
      this.isSubmitting = true;
      if (this.fitTemplate.id) {
        api
          .patch(`/fit/templates/${this.fitTemplate.id}`, formData, {
            successMessage: this.$t('fit_template.saved', {
              name: this.fitTemplate.name
            })
          })
          .then(({ data }) => {
            this.UPDATE_FIT_TEMPLATE(data.fit_template);
            this.close(true);
          })
          .finally(() => (this.isSubmitting = false));
      } else {
        api
          .post('/fit/templates', formData, {
            successMessage: this.$t('fit_template.created', {
              name: this.fitTemplate.name
            })
          })
          .then(({ data }) => {
            this.ADD_FIT_TEMPLATE(data.fit_template);
            this.close(true);
          })
          .finally(() => (this.isSubmitting = false));
      }
    },
    updateSizeOptionKeys() {
      this.sizeOptionKeys = this.fitCategory
        ? this.fitOptionKeys
            .filter(key =>
              this.fitCategory.size_option_key_ids.includes(key.id)
            )
            .map(key => ({
              id: key.id,
              name: key.display_name,
              displayName: this.fitTemplate.display_names[key.id],
              checked: this.fitTemplate.size_option_key_ids.includes(key.id),
              applyNewLine: this.fitTemplate.size_option_key_ids_for_new_line.includes(
                key.id
              )
            }))
        : [];
    },
    formGroupChanged(group) {
      if (group.id === 'root_fit_category_id') {
        this.updateSizeOptionKeys();
        this.fitTemplate.fit_category_id = this.depth2FitCategoryOptions.length
          ? this.depth2FitCategoryOptions[0].value
          : null;
      } else if (group.id === 'fit_category_id') {
        this.fitTemplate.fit_sample_image_source_type = 'none';
        this.fitTemplate.fit_default_sample_image_id = null;
      }
    },
    changeImage({ imageUrl, removeImage }, id) {
      const imageUrlKey = `${id}_url`;

      if (imageUrl) {
        this.$set(this.fitTemplate, imageUrlKey, imageUrl);
      } else {
        this.$delete(this.fitTemplate, imageUrlKey);
      }

      const removeKey = `remove_${id}`;
      if (removeImage) {
        this.$set(this.fitTemplate, removeKey, true);
      } else {
        this.$delete(this.fitTemplate, removeKey);
      }

      if (removeImage) {
        let typeKey = null;
        let typeValue = 'image';
        switch (id) {
          case 'sample_title_image':
            typeKey = 'sample_title_display_type';
            break;
          case 'size_title_image':
            typeKey = 'size_title_display_type';
            break;
          case 'info_title_image':
            typeKey = 'info_title_display_type';
            break;
          case 'property_title_image':
            typeKey = 'property_title_display_type';
            break;
          case 'sample_image':
            typeKey = 'fit_sample_image_source_type';
            typeValue = 'template';
            break;
        }

        if (this.fitTemplate[typeKey] === typeValue) {
          this.$set(this.fitTemplate, typeKey, 'none');
        }
      }

      this.validateField(id);
    },
    propertyOptionValueOptions(propertyOptionKey) {
      return propertyOptionKey.fit_option_values.map(value => ({
        label: value.display_name,
        value: value.id
      }));
    },
    propertyOptionValueIds(propertyOptionKey) {
      return _.intersection(
        this.fitTemplate.property_option_value_ids,
        propertyOptionKey.fit_option_values.map(v => v.id)
      );
    },
    updatePropertyOptionValueIds(propertyOptionKey, valueIds) {
      const otherPropertyValueIds = _.difference(
        this.fitTemplate.property_option_value_ids,
        propertyOptionKey.fit_option_values.map(v => v.id)
      );
      this.fitTemplate.property_option_value_ids = [
        ...valueIds,
        ...otherPropertyValueIds
      ].sort((a, b) => a - b);
    },
    sortSizeOptionKeys({ srcIndex, dstIndex }) {
      const sortedKeys = arrayMoveIndex(
        this.sizeOptionKeys,
        srcIndex,
        dstIndex
      );
      sortedKeys[0].applyNewLine = false;

      this.sizeOptionKeys = sortedKeys;
    },
    propertyOptionKeySelected(keyId) {
      const propertyOptionKey = this.propertyOptionKeys.find(
        k => k.id === keyId
      );

      let newValueIds = propertyOptionKey.fit_option_values.map(v => v.id);
      if (this.orgFitTemplate.property_option_key_ids.includes(keyId)) {
        newValueIds = _.intersection(
          newValueIds,
          this.orgFitTemplate.property_option_value_ids
        );
      }

      this.updatePropertyOptionValueIds(propertyOptionKey, newValueIds);
    },
    propertyOptionKeyUnselected(keyId) {
      const propertyOptionKey = this.propertyOptionKeys.find(
        k => k.id === keyId
      );

      this.updatePropertyOptionValueIds(propertyOptionKey, []);
    },
    titleGroupOption(key) {
      const id = `${key}_display_type`;
      const displayType = this.fitTemplate[id];
      return {
        id,
        fields:
          displayType === 'text'
            ? [{ id: key, validate: ['required'] }]
            : displayType === 'image'
            ? [
                {
                  id: `${key}_image`,
                  value: () =>
                    this.fitTemplate[`remove_${key}_image`]
                      ? null
                      : this.fitTemplate[`${key}_image_url`],
                  validate: ['required']
                }
              ]
            : [],
        required: displayType !== 'none'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_inputs.scss';

.fit-template-edit-dialog__preview-fit-default-sample-image {
  margin-top: 8px;
  max-width: 100%;
  border: solid 1px $color-grey-35;
}

.fit-template-edit-dialog__size-option-keys-info-warning {
  @include text-caption;
  color: $color-red;
}

::v-deep {
  .AppModal__left {
    width: 600px;
  }
}
</style>
