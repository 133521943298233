<template>
  <AppInfoBox class="AnalyticsInsightInfoBox">
    <!-- eslint-disable vue/no-v-html -->
    <p v-html="infoBoxText" />
    <!-- eslint-enable vue/no-v-html -->
  </AppInfoBox>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'AnalyticsInsightInfoBox',
  props: {
    categoriesCount: { type: Number, required: true },
    keywordsCount: { type: Number, required: true }
  },
  computed: {
    ...mapState('competingGroup', ['filteredBrandsCount']),
    ...mapState('session', ['currentBrand']),
    infoBoxText() {
      let text = '';
      if (this.categoriesCount) {
        text += this.$t('competing_group_prefix', {
          categories_count: this.categoriesCount
        });
      } else {
        text += this.$t('competing_brands_prefix');
      }
      text += this.$t('infobox_text', {
        brands_count: this.filteredBrandsCount,
        market_category: this.marketCategory,
        keywords_count: this.keywordsCount,
        date: this.date
      });

      return text;
    },
    marketCategory() {
      const {
        is_beauty_mall,
        is_food_mall,
        is_common_mall
      } = this.currentBrand;

      if (is_beauty_mall) {
        return this.$t('beauty');
      } else if (is_food_mall) {
        return this.$t('food');
      } else if (is_common_mall) {
        return this.$t('common');
      } else {
        return this.$t('fashion');
      }
    },
    date() {
      return moment()
        .subtract(1, 'day')
        .format('YYYY. MM. DD.');
    }
  }
};
</script>

<style lang="scss" scoped>
.AnalyticsInsightInfoBox {
  margin-bottom: 32px;
}
</style>

<i18n locale="ko">
{
  "competing_group_prefix": "경쟁 그룹 설정에서 선택된 {categories_count}개의 카테고리를 포함하는 ",
  "competing_brands_prefix": "경쟁 쇼핑몰 설정에서 선택된 ",
  "infobox_text": "{brands_count}개의 {market_category} 쇼핑몰 데이터와 비교분석한 통계치입니다.<br>전체 {market_category} 시장 기준 분석된 키워드수는 {keywords_count}개이며, {date} 에 갱신되었습니다.",
  "beauty": "뷰티",
  "fashion": "패션",
  "food": "식품",
  "common": "공통"
}
</i18n>
