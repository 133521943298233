<template>
  <div class="TargetCampaignProductRecommendationWidgetPreview">
    <AppSelectButton
      v-if="showDeviceTypeSelectButton"
      :value="deviceType"
      :options="widgetDeviceTypeOptions"
      class="TargetCampaignProductRecommendationWidgetPreview__device-type-selector"
      @change="changeDeviceType"
    />
    <div
      v-if="!showDeviceTypeSelectButton"
      class="TargetCampaignProductRecommendationWidgetPreview__empty-box"
    />
    <AppInfoBox
      class="TargetCampaignProductRecommendationWidgetPreview__guide"
      hide-type="snooze"
      info-box-id="target-product-recommendation-widget-guide"
    >
      {{ $t('preview_guide_content') }}
    </AppInfoBox>
    <Component
      :is="
        isCartPopupWidgetCampaign
          ? 'AppCartPopupPreview'
          : isPc
          ? 'div'
          : 'AppMobilePhonePreview'
      "
      :class="[
        'TargetCampaignProductRecommendationWidgetPreview__iframe',
        isCartPopupWidgetCampaign
          ? 'TargetCampaignProductRecommendationWidgetPreview__iframe--popup'
          : isPc
          ? 'TargetCampaignProductRecommendationWidgetPreview__iframe--pc'
          : 'TargetCampaignProductRecommendationWidgetPreview__iframe--mobile',
        !isCartPopupWidgetCampaign && !showDeviceTypeSelectButton
          ? isPc
            ? 'TargetCampaignProductRecommendationWidgetPreview__iframe--no-buttons-pc'
            : 'TargetCampaignProductRecommendationWidgetPreview__iframe--no-buttons-mobile'
          : null,
        withForm
          ? isCartPopupWidgetCampaign
            ? 'TargetCampaignProductRecommendationWidgetPreview__iframe--popup-with-form'
            : 'TargetCampaignProductRecommendationWidgetPreview__iframe--with-form'
          : null
      ]"
      :style="
        !isCartPopupWidgetCampaign && isPc ? { height: iframeHeight } : null
      "
    >
      <AppIframeViewer
        :src="previewUrl"
        :is-loading-forced="isLoading || displayVersion !== currentVersion"
      />
    </Component>
  </div>
</template>

<script>
import qs from 'qs';
import { mapState, mapGetters } from 'vuex';
import IframeHeightSetter from '@/mixins/IframeHeightSetter';
import api from '@/lib/api';

export default {
  name: 'TargetCampaignProductRecommendationWidgetPreview',
  mixins: [IframeHeightSetter],
  props: {
    currentVersion: { type: Number, default: 0 },
    displayVersion: { type: Number, default: 0 },
    showPcAsDefault: { type: Boolean, default: true },
    showDeviceTypeSelectButton: { type: Boolean, default: true },
    withForm: { type: Boolean, default: true }
  },
  data() {
    return {
      deviceType: this.showPcAsDefault ? 'PC' : 'MOBILE',
      defaultIframeHeight: '740px',
      isLoading: true
    };
  },
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    ...mapGetters('targetCampaign', ['isCartPopupWidgetCampaign']),
    requiresCalculation() {
      return this.isPc;
    },
    isPc() {
      return this.deviceType === 'PC';
    },
    previewUrl() {
      const params = qs.stringify({
        v: this.displayVersion,
        iframe: '1',
        parent_url: location.href
      });

      let previewUrl = this.campaign.widget_preview_url;
      previewUrl = this.isPc
        ? previewUrl
        : previewUrl.replace('/target/', '/target/mobile/');
      return `${previewUrl}&${params}`;
    },
    widgetDeviceTypeOptions() {
      return [
        {
          value: 'PC',
          label: this.$t('pc_widget_button')
        },
        {
          value: 'MOBILE',
          label: this.$t('mobile_widget_button')
        }
      ];
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaign.id}/widget_design`)
      .finally(() => (this.isLoading = false));
  },
  methods: {
    changeDeviceType(deviceType) {
      this.resetIframeHeight();
      this.deviceType = deviceType;
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignProductRecommendationWidgetPreview {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.TargetCampaignProductRecommendationWidgetPreview__empty-box {
  margin-top: 43px;
}
.TargetCampaignProductRecommendationWidgetPreview__guide {
  margin: 24px 24px 0px 24px;

  &--no-buttons {
    margin-top: 67px;
  }
}
.TargetCampaignProductRecommendationWidgetPreview__device-type-selector {
  align-self: flex-end;
  margin-right: 24px;
  flex-grow: 0;
}

.TargetCampaignProductRecommendationWidgetPreview__iframe {
  flex-grow: 1;
  margin-top: 24px;

  &--with-form {
    min-width: 599px;
  }

  &--pc {
    height: auto;
    min-height: 740px;
    max-height: calc(100vh - 40px);
  }

  &--mobile {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    overflow-y: scroll;
  }

  &--popup {
    margin: 32px auto;
  }

  &--no-buttons-mobile {
    margin-bottom: 67px;
  }

  &--no-buttons-pc {
    margin-bottom: 7px;
  }
}
</style>

<i18n locale="ko">
{
  "pc_widget_button": "PC 위젯",
  "mobile_widget_button": "모바일 위젯",
  "preview_guide_content": "아래 미리보기는 디자인 참고용으로, 실제 위젯에는 설정에 맞는 추천 상품을 표시합니다."
}
</i18n>
