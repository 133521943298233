<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>Render without Size Parameters</label>
      </div>
      <div class="UiSvgIcon__sub-title">
        <p>
          Component size will be calculated based on viewBox attribute of SVG
          element after mounted.
        </p>
      </div>
      <div class="UiSvgIcon__sample-image-wrapper">
        <AppSvgIcon name="icon-crema-logo" />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label>Render with Specific Height</label>
      </div>
      <div class="UiSvgIcon__sub-title">
        <p>
          The aspect ratio of SVG element is kept as the width varies along with
          the height property.
        </p>
      </div>
      <div class="UiSvgIcon__sample-image-wrapper">
        <AppSvgIcon name="icon-crema-logo" :height="20" />
      </div>
      <div class="UiSvgIcon__sample-image-wrapper">
        <AppSvgIcon name="icon-crema-logo" :height="30" />
      </div>
      <div class="UiSvgIcon__sample-image-wrapper">
        <AppSvgIcon name="icon-crema-logo" :height="40" />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label>Render with Specific Width and Height</label>
      </div>
      <div class="UiSvgIcon__sub-title">
        <p>Component size is set by specified width and height.</p>
      </div>
      <div class="UiSvgIcon__sample-image-wrapper">
        <AppSvgIcon name="icon-crema-logo" :width="50" :height="100" />
      </div>
      <div class="UiSvgIcon__sample-image-wrapper">
        <AppSvgIcon name="icon-crema-logo" :width="100" :height="100" />
      </div>
      <div class="UiSvgIcon__sample-image-wrapper">
        <AppSvgIcon name="icon-crema-logo" :width="200" :height="50" />
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'UiSvgIcon' };
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.UiSvgIcon__sub-title {
  @include text-sub-title-light;

  margin-bottom: 16px;
}

.UiSvgIcon__sample-image-wrapper {
  display: inline-block;
  border: 1px solid gray;
  background-color: white;
  background-image: linear-gradient(
      45deg,
      lightgray 25%,
      transparent 25%,
      transparent 75%,
      lightgray 75%,
      lightgray
    ),
    linear-gradient(
      45deg,
      lightgray 25%,
      transparent 25%,
      transparent 75%,
      lightgray 75%,
      lightgray
    );
  background-size: 12px 12px;
  background-position: 0 0, 6px 6px;
  line-height: 100%;

  > svg {
    vertical-align: middle;
  }

  & + & {
    margin-left: 8px;
  }
}
</style>
