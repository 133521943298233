<template>
  <ReviewSettingsWidgetsNormal
    :widget-type="widgetType"
    :widget-summary="widgetSummary"
  />
</template>

<script>
import { mapGetters } from 'vuex';

import ProductDisplayFilter from '@/enums/ProductDisplayFilter';
import ProductReviewDisplayFilter from '@/enums/ProductReviewDisplayFilter';
import WidgetType from '@/enums/WidgetType';

import ReviewSettingsWidgetsNormal from './ReviewSettingsWidgetsNormal';

export default {
  name: 'ReviewSettingsWidgetsProducts',
  components: { ReviewSettingsWidgetsNormal },
  data() {
    return { widgetType: WidgetType.PRODUCTS };
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoryName'])
  },
  methods: {
    widgetSummary(widget) {
      const { product_display_filter, product_review_display_filter } = widget;
      const per = widget.posts_per_page;
      const products1 =
        product_display_filter === ProductDisplayFilter.SELECTED_CATEGORY
          ? this.$t('product_display_filter.selected_category', [
              this.productCategoryName(widget.category_id)
            ])
          : product_display_filter === ProductDisplayFilter.SELECTED_PRODUCTS
          ? this.$t('product_display_filter.selected_products', [
              widget.products.map(p => p.name).join(', ')
            ])
          : this.$t('product_display_filter.all');

      if (product_review_display_filter === ProductDisplayFilter.ALL)
        return this.$t('summary.single_criteria', {
          products: products1,
          per
        });
      else {
        const products2 = ProductReviewDisplayFilter.t(
          product_review_display_filter
        );
        return product_display_filter === ProductDisplayFilter.ALL
          ? this.$t('summary.single_criteria', {
              products: products2,
              per
            })
          : this.$t('summary.dual_criteria', {
              products1,
              products2,
              per
            });
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "summary": {
    "single_criteria": "{products}을 {per}개씩 보여줍니다.",
    "dual_criteria": "{products1} 중 {products2}을 {per}개씩 보여줍니다."
  },
  "product_display_filter": {
    "all": "모든 상품",
    "selected_category": "{0} 카테고리 상품",
    "selected_products": "지정 상품({0})"
  }
}
</i18n>
