import _ from 'lodash';

const noSpaceFrontAndBack = value =>
  !value.startsWith(' ') && !value.endsWith(' ');
const asciiOnly = value => /^[\x20-\x7E]*$/.test(value);
const hasLowercase = value => /[a-z]/.test(value);
const hasUppercase = value => /[A-Z]/.test(value);
const hasNumber = value => /[0-9]/.test(value);
const hasSpecialCharacter = value => /[^a-zA-Z0-9]/.test(value);

export const MIN_PASSWORD_COMPLEXITY = 3;
export const MIN_PASSWORD_LENGTH = 8;

export const validatePassword = value => {
  const complexities = {
    uppercase: hasUppercase(value),
    lowercase: hasLowercase(value),
    number: hasNumber(value),
    special_character: hasSpecialCharacter(value)
  };
  const complexity = _.reduce(complexities, (a, v) => a + (v ? 1 : 0), 0);

  return {
    requirements: {
      no_space_front_and_back: !!value && noSpaceFrontAndBack(value),
      ascii_only: !!value && asciiOnly(value),
      enough_length: value.length >= MIN_PASSWORD_LENGTH,
      enough_complexity: complexity >= MIN_PASSWORD_COMPLEXITY
    },
    complexities
  };
};

export const isPasswordValid = value => {
  const { requirements } = validatePassword(value);
  return Object.values(requirements).every(Boolean);
};
