import { constructEnum } from '@/lib/enum';

export default constructEnum('BrandGrade', {
  NONE: 0,
  G: 9,
  F: 10,
  E: 11,
  D: 12,
  C: 13,
  B: 14,
  A: 15,
  S: 16
});
