<template>
  <AppTable
    table-id="TargetBillingPlanInfoTable"
    :columns="COLUMNS"
    :rows="rows"
  >
    <template #cell="{ column, row, value }">
      <template v-if="column.id === 'dau_interval'">
        <AppData
          :value="value"
          :class="{
            'TargetBillingPlanInfoTable__dau-interval': true,
            'TargetBillingPlanInfoTable__dau-interval--highlight': row.highlight
          }"
        />
      </template>
      <template v-else-if="column.id === 'promotion'">
        {{
          $t('body.promotion.monthly_bonus_point', [row.monthly_bonus_point])
        }}
        <div
          class="TargetBillingPlanInfoTable__promotion-monthly-free-dispatchable-sms-count"
        >
          {{
            $t('body.promotion.monthly_free_dispatchable_sms_count', [
              row.MonthlyFreeDispatchableSmsCount
            ])
          }}
        </div>
      </template>
      <template v-else-if="column.id === 'monthly_price'">
        <AppNumber
          class="TargetBillingPlanInfoTable__monthly-price"
          :value="Payment.priceWithVat(value)"
          unit="currency"
        />
        <div class="TargetBillingPlanInfoTable__monthly-price-calculation">
          <AppNumber :value="value" unit="currency" />
          <span>+{{ $t('vat') }} </span>
          <AppNumber
            :value="Payment.priceWithVat(value) - value"
            unit="currency"
          />
        </div>
      </template>
    </template>
  </AppTable>
</template>

<script>
import { mapGetters } from 'vuex';
import payment from '@/lib/payment';

export default {
  name: 'TargetBillingPlanInfoTable',
  props: {
    billingPlans: { type: Array, default: () => [] }
  },
  computed: {
    ...mapGetters('session', ['smsPrice', 'activeTargetBillingPlanId']),
    Payment() {
      return payment;
    },
    COLUMNS() {
      return [
        {
          id: 'dau_interval',
          label: this.$t('head.dau_interval'),
          align: 'left'
        },
        {
          id: 'promotion',
          label: this.$t('head.promotion'),
          align: 'right'
        },
        {
          id: 'monthly_price',
          label: this.$t('head.monthly_price'),
          align: 'right'
        }
      ];
    },
    rows() {
      return this.billingPlans.map(billing_plan => {
        const {
          id,
          minimum_dau,
          maximum_dau,
          monthly_price,
          monthly_bonus_point
        } = billing_plan;

        return {
          dau_interval: this.$t('body.dau_interval', [
            minimum_dau,
            maximum_dau
          ]),
          monthly_price,
          monthly_bonus_point,
          highlight: id === this.activeTargetBillingPlanId,
          MonthlyFreeDispatchableSmsCount: Math.floor(
            monthly_bonus_point / this.smsPrice
          )
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/vars/_colors.scss';

.TargetBillingPlanInfoTable__dau-interval {
  &--highlight {
    color: $color-blue;
    font-weight: bold;
  }
}

.TargetBillingPlanInfoTable__promotion-monthly-free-dispatchable-sms-count {
  font-size: 12px;
  color: $color-grey-50;
}

.TargetBillingPlanInfoTable__monthly-price {
  @include text-label;
}

.TargetBillingPlanInfoTable__monthly-price-calculation {
  @include text-caption;
}
</style>

<i18n locale="ko">
{
  "head": {
    "dau_interval": "이용료 구간",
    "promotion": "특별 혜택",
    "monthly_price": "월 이용료"
  },
  "body": {
    "dau_interval": "DAU {0} 이상 {1} 미만",
    "promotion": {
      "monthly_bonus_point": "매달 {0}포인트 제공",
      "monthly_free_dispatchable_sms_count": "SMS 기준 {0} 건 발송 가능"
    }
  },
  "vat": "부가세"
}
</i18n>
