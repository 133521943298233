<template>
  <AppMultipleSelectTable
    :dialog-title="title"
    :items="reviews"
    :columns="columns"
    :dialog-columns="columns"
    :no-data="$t('no_data')"
    search-types-align="right"
    :advanced-search-sections="advancedSearchSections"
    v-bind="{ name, disabled, selectButtonLabel, searchTypes, socialMediaOnly }"
    @change="selectReviews($event)"
    @search="search"
  />
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import ReviewSearch from '@/enums/ReviewSearch';

export default {
  name: 'AppMultipleSelectReview',
  model: { prop: 'reviews', event: 'change' },
  props: {
    name: { type: String, default: '' },
    title: {
      type: String,
      default() {
        return this.$t('select_review');
      }
    },
    reviews: { type: Array, required: true },
    disabled: { type: Boolean, default: false },
    selectButtonLabel: {
      type: String,
      default() {
        return this.$t('select_review');
      }
    },
    tags: { type: Array, required: true },
    socialMediaOnly: { type: Boolean, default: false }
  },
  data() {
    return {
      columns: [
        { id: 'id' },
        { id: 'product_name', type: 'nowrap', width: '66px' },
        { id: 'user', type: 'nowrap', width: '74px' },
        { id: 'tags', type: 'badges', width: '105px' },
        { id: 'media', type: 'review_media', width: '124px' },
        { id: 'message', type: 'nowrap', align: 'left' }
      ].map(c => ({
        ...c,
        label: this.$t(c.id),
        autoTooltip: c.type === 'nowrap'
      }))
    };
  },
  computed: {
    searchTypes() {
      return ReviewSearch.options();
    },
    advancedSearchSections() {
      return [
        {
          id: 'filter',
          groups: [
            {
              id: 'review_type',
              label: this.$t('review_type'),
              type: 'hash_select_button',
              selectButtons: [
                {
                  id: 'photo',
                  options: [2, 0].map(value => ({
                    label: this.$t(`photo.${value}`),
                    value
                  }))
                }
              ]
            },
            this.tags.length
              ? {
                  id: 'tag_ids',
                  label: this.$t('tags'),
                  type: 'multiple_select',
                  options: this.tags.map(t => ({
                    label: t.name,
                    value: t.id
                  })),
                  packingMethod: 'string'
                }
              : null
          ].filter(g => g)
        }
      ];
    },
    tagIdMap() {
      return _.keyBy(this.tags, 'id');
    }
  },
  methods: {
    selectReviews(event) {
      const newlySelectedReviews = _.chain(event)
        .differenceBy(this.reviews, 'id')
        .sortBy('id')
        .value();

      if (!newlySelectedReviews.length) return this.$emit('change', event);

      const selectedReviews = _.concat(newlySelectedReviews, this.reviews);
      return this.$emit('change', selectedReviews);
    },
    search({ params, onSuccess, onFailure }) {
      api
        .get('/reviews', { params })
        .then(({ data }) => {
          const { reviews } = data;
          onSuccess({
            ...reviews,
            items: reviews.items.map(r => ({
              ...r,
              user: this.formatUser(r),
              tags: this.formatTags(r)
            }))
          });
        })
        .catch(onFailure);
    },
    formatTags({ tag_ids }) {
      return tag_ids.map(id => ({
        id,
        label: this.tagIdMap[id.toString()].name,
        style: 'grey-outline',
        size: 'small'
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .multiple-select-table__product-name {
    width: 106px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .multiple-select-table__user {
    width: 92px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .multiple-select-table__message {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .multiple-select-table {
    .AppReviewMediaCell {
      margin-top: 0;
      text-align: left;
    }

    .AppReviewMediaCell__image {
      line-height: 24px;
      width: 24px;
      height: 24px;
    }
  }
}
</style>

<i18n locale="ko">
{
  "select_review": "리뷰 추가",
  "id": "ID",
  "product_name": "상품명",
  "review_type": "리뷰 종류",
  "tags": "태그",
  "media": "포토/동영상",
  "photo": {
    "2": "포토/동영상 리뷰",
    "0": "텍스트 리뷰"
  },
  "user": "작성자",
  "message": "리뷰 내용",
  "no_data": "설정한 리뷰가 없습니다."
}
</i18n>
