<template>
  <AppGrid>
    <div class="AppGrid__col AppGrid__col--4 AppGrid__col--tablet-12">
      <TargetSectionKpi
        :title="$t('dispatch.title')"
        :bar-title="$t('dispatch.bar_title')"
        :kpi-title="$t('detail')"
        v-bind="
          isLoading
            ? { isLoading: true, barData: [], barTooltip: {}, kpiData: [] }
            : {
                isLoading: false,
                barData: dispatchBarData,
                barTooltip: barTooltip(dispatchBarData),
                kpiData: dispatchKpiData
              }
        "
        @click-item="clickDetailButton()"
      />
    </div>
    <div class="AppGrid__col AppGrid__col--4 AppGrid__col--tablet-12">
      <TargetSectionKpi
        :title="$t('purchase.title')"
        :bar-title="$t('purchase.bar_title')"
        :kpi-title="$t('detail')"
        v-bind="
          isLoading
            ? { isLoading: true, barData: [], barTooltip: {}, kpiData: [] }
            : {
                isLoading: false,
                barData: purchaseBarData,
                barTooltip: barTooltip(purchaseBarData),
                kpiData: purchaseKpiData
              }
        "
        @click-item="openDetailDialog('purchased_orders')"
      />
    </div>
    <div class="AppGrid__col AppGrid__col--4 AppGrid__col--tablet-12">
      <TargetSectionKpi
        :title="$t('visit.title')"
        :bar-title="$t('visit.bar_title')"
        :kpi-title="$t('detail')"
        v-bind="
          isLoading
            ? { isLoading: true, barData: [], barTooltip: {}, kpiData: [] }
            : {
                isLoading: false,
                barData: visitBarData,
                barTooltip: barTooltip(visitBarData),
                kpiData: visitKpiData
              }
        "
        @click-item="openDetailDialog('visited_users')"
      />
    </div>
  </AppGrid>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TargetSectionKpi from './components/TargetSectionKpi';

export default {
  name: 'TargetCampaignMassStatsKpiGrid',
  components: { TargetSectionKpi },
  props: {
    isLoading: { type: Boolean, required: true },
    report: { type: Object, required: true },
    campaign: { type: Object, required: true }
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    dispatchBarData() {
      const { total_count, sent_count } = this.report;
      return [
        {
          label: this.$t('dispatch.dispatch_count'),
          value: sent_count,
          totalValue: total_count,
          colorIndex: 0
        },
        {
          label: this.$t('dispatch.no_dispatch_count'),
          value: total_count - sent_count,
          totalValue: total_count,
          colorIndex: 2
        }
      ];
    },
    purchaseBarData() {
      const { sent_count, purchased_users_count } = this.report;
      return [
        {
          label: this.$t('purchase.purchase_count'),
          value: purchased_users_count,
          totalValue: sent_count,
          colorIndex: 0
        },
        {
          label: this.$t('purchase.no_purchase_count'),
          value: sent_count - purchased_users_count,
          totalValue: sent_count,
          colorIndex: 2
        }
      ];
    },
    visitBarData() {
      const { respondents_count, sent_count } = this.report;
      return [
        {
          label: this.$t('visit.visit_count'),
          value: respondents_count,
          totalValue: sent_count,
          colorIndex: 0
        },
        {
          label: this.$t('visit.no_visit_count'),
          value: sent_count - respondents_count,
          totalValue: sent_count,
          colorIndex: 2
        }
      ];
    },
    dispatchKpiData() {
      return [{ id: 'dispatch', label: this.$t('dispatch.dispatch_detail') }];
    },
    purchaseKpiData() {
      return [{ id: 'purchase', label: this.$t('purchase.purchase_history') }];
    },
    visitKpiData() {
      return [{ id: 'visit', label: this.$t('visit.visit_history') }];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openDetailDialog(reportItemType) {
      const { last_sent_date } = this.campaign;
      const dateRange = {
        start_date: last_sent_date,
        end_date: last_sent_date
      };
      this.openDialog([
        'TargetCampaignStatsDetailDialog',
        { reportItemType, campaign: this.campaign, dateRange }
      ]);
    },
    clickDetailButton() {
      const { id, name } = this.campaign;
      this.openDialog([
        'TargetReportsDispatchResultDialog',
        {
          campaignId: id,
          campaignName: name,
          splited: true
        }
      ]);
    },
    barTooltip(items) {
      return {
        items: items.map(({ label, value, totalValue, colorIndex }) => {
          const percentage =
            totalValue === 0 ? 0 : ((value / totalValue) * 100).toFixed(2);
          return {
            name: label,
            value: `${this.$t('unit.people', [
              value
            ])} (${this.$t('unit.percentage', [percentage])})`,
            colorIndex
          };
        })
      };
    }
  }
};
</script>

<i18n locale="ko">
{
  "dispatch": {
    "title": "발송 성공",
    "bar_title": "발송 결과 분석",
    "dispatch_count": "발송 성공",
    "no_dispatch_count": "발송 실패",
    "dispatch_detail": "발송 내역 보기"
  },
  "purchase": {
    "title": "발송 후 구매",
    "bar_title": "발송 성공 회원 분석",
    "purchase_count": "구매",
    "no_purchase_count": "미구매",
    "purchase_history": "구매 정보 보기"
  },
  "visit": {
    "title": "발송 후 방문",
    "bar_title": "발송 성공 회원 분석",
    "visit_count": "방문",
    "no_visit_count": "미방문",
    "visit_history": "방문 정보 보기"
  },
  "detail": "상세 내역"
}
</i18n>
