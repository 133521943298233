<template>
  <AppIframeViewer
    src="https://connectcenter.cre.ma/?utm_source=admin"
    class="ConnectCenter__iframe"
  />
</template>

<script>
export default { name: 'ConnectCenter' };
</script>

<style lang="scss" scoped>
$main-head-height: 44px;
$content-title-height: 56px;
$main-body-inner-padding: 40px;
$footer-height: 138px;
$height-without-iframe: $main-head-height + $content-title-height +
  $main-body-inner-padding + $footer-height;

.ConnectCenter__iframe {
  height: calc(100vh - $height-without-iframe) !important;
}
</style>
