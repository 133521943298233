<template>
  <AppAjaxContent
    :is-loading="isLoading"
    class="ReviewDashboardGodoReviewCsvSync"
  >
    <AppDataList :data="items">
      <template #value="{ id }">
        <template v-if="id === 'review_csv'">
          <div class="ReviewDashboardGodoReviewCsvSync__guide">
            {{ guideDescription }}
            <template v-if="!isCompleted">
              <div
                v-if="
                  onboardingProgress.review_csv_sync_status ===
                    ReviewCsvSyncStatus.FAILED ||
                    onboardingProgress.review_csv_sync_status ===
                      ReviewCsvSyncStatus.NONE
                "
              >
                <AppButton
                  :label="$t('review_csv.label')"
                  @click="clickUploadCsv"
                />
              </div>
              <div
                v-if="
                  onboardingProgress.review_csv_sync_status ===
                    ReviewCsvSyncStatus.FAILED
                "
                class="ReviewDashboardGodoReviewCsvSync__error"
              >
                {{ $t('review_csv.error') }}
              </div>
            </template>
          </div>
        </template>
        <template v-if="id == 'review_count_update'">
          <div
            v-if="scoreSettingDiabled"
            class="ReviewDashboardGodoReviewCsvSync__guide--disabled"
          >
            {{ $t('review_count_update.disabled_guide') }}
          </div>
          <div v-else-if="godoReviewCountUpdated">
            {{ $t('review_count_update.complete_guide') }}
          </div>
          <div v-else class="ReviewDashboardGodoReviewCsvSync__guide">
            {{ $t('review_count_update.none_guide') }}
            <div>
              <AppButton
                :label="$t('review_count_update.button_label')"
                @click="clickHowToUpdate"
              />
            </div>
          </div>
        </template>
      </template>
    </AppDataList>
    <AppButtonSubmit
      v-if="!isCompleted"
      class="ReviewDashboardGodoReviewCsvSync__submit-button"
      :submit-label="$t('app.complete')"
      :disabled="!godoReviewCountUpdated || scoreSettingDiabled"
      @click="submit"
    />
  </AppAjaxContent>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ReviewCsvSyncStatus from '@/enums/ReviewCsvSyncStatus';

export default {
  name: 'ReviewDashboardGodoReviewCsvSync',
  props: {
    isLoading: { type: Boolean, required: true },
    isCompleted: { type: Boolean, required: true }
  },
  computed: {
    ...mapState('easyJoin', ['onboardingProgress']),
    ReviewCsvSyncStatus: () => ReviewCsvSyncStatus,
    items() {
      return [
        {
          id: 'review_csv',
          label: this.$t('review_csv.label'),
          labelDisabled: this.isCompleted,
          badges: [this.badgeData]
        },
        {
          id: 'review_count_update',
          label: this.$t('review_count_update.label'),
          labelDisabled: this.isCompleted || this.scoreSettingDiabled,
          badges: this.scoreSettingDiabled
            ? null
            : this.godoReviewCountUpdated
            ? [
                {
                  label: this.$t('review_count_update.complete_badge'),
                  badgeStyle: 'mint-green'
                }
              ]
            : [{ label: this.$t('review_count_update.none_badge') }]
        }
      ];
    },
    guideDescription() {
      switch (this.onboardingProgress.review_csv_sync_status) {
        case ReviewCsvSyncStatus.SKIPPED:
          return this.$t('review_csv.no_review_guide');
        case ReviewCsvSyncStatus.NONE:
          return this.$t('review_csv.guide');
        case ReviewCsvSyncStatus.FAILED:
          return this.$t('review_csv.failed_guide');
        case ReviewCsvSyncStatus.SUCCEEDED:
          return this.$t('review_csv.success_guide');
        default:
          return this.$t('review_csv.progress_guide');
      }
    },
    badgeData() {
      let badgeData = {};
      switch (this.onboardingProgress.review_csv_sync_status) {
        case ReviewCsvSyncStatus.SKIPPED:
          badgeData.label = this.$t('review_sync_status.no_reviews_to_sync');
          badgeData.badgeStyle = 'grey-outline';
          break;
        case ReviewCsvSyncStatus.NONE:
          badgeData.label = this.$t('review_sync_status.none');
          break;
        case ReviewCsvSyncStatus.SUCCEEDED:
          badgeData.label = this.$t('review_sync_status.succeeded');
          badgeData.badgeStyle = 'mint-green';
          break;
        case ReviewCsvSyncStatus.FAILED:
          badgeData.label = this.$t('review_sync_status.failed');
          badgeData.badgeStyle = 'red';
          break;
        default:
          badgeData.label = this.$t('review_sync_status.progress');
          badgeData.spinner = true;
          break;
      }
      return badgeData;
    },
    scoreSettingDiabled() {
      return [
        ReviewCsvSyncStatus.NONE,
        ReviewCsvSyncStatus.FAILED,
        ReviewCsvSyncStatus.PROGRESS
      ].includes(this.onboardingProgress.review_csv_sync_status);
    },
    godoReviewCountUpdated() {
      const { godo_reviews_count_updated } = this.onboardingProgress;
      return godo_reviews_count_updated;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('easyJoin', ['updateOnboardingProgress']),
    clickUploadCsv() {
      this.openDialog('ReviewDashboardGodoReviewCsvUploadDialog');
    },
    clickHowToUpdate() {
      this.openDialog('ReviewDashboardGodoReviewsCountUpdateDialog');
    },
    submit() {
      this.updateOnboardingProgress({
        godo_csv_completed: true
      })
        .then(() => this.$emit('submitted'))
        .finally(() => {
          this.$emit('loaded');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.ReviewDashboardGodoReviewCsvSync__guide {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &--disabled {
    color: $color-grey-40;
  }
}

.ReviewDashboardGodoReviewCsvSync__error {
  color: $color-red;
}

.ReviewDashboardGodoReviewCsvSync__submit-button {
  margin-top: 16px;
}
</style>

<i18n locale="ko">
  {
    "review_csv": {
      "label": "리뷰 CSV 등록",
      "guide": "리뷰 동기화를 위해 고도 관리자 페이지에서 CSV파일을 다운받아 크리마에 업로드 해주세요.",
      "success_guide": "등록 완료되었습니다. 서비스 비용 결제 후 데이터 검수 안내를 통해 기존 리뷰가 잘 동기화 되었는지 확인할 수 있습니다.",
      "progress_guide": "리뷰 CSV 등록 중입니다.",
      "failed_guide": "과거 리뷰 동기화를 위해 고도 관리자 페이지에서 CSV파일을 다운받아 크리마에 업로드 해주세요.",
      "no_review_guide": "등록할 리뷰가 없는 것으로 확인했습니다.",
      "error": "리뷰 CSV 등록에 실패했습니다. 다시 등록해주세요. 문제가 있을 경우 크리마 운영팀에 문의해주세요."
    },
    "review_count_update": {
      "label": "크리마리뷰 상품평 개수 업데이트",
      "disabled_guide": "리뷰 CSV 등록 완료 후 크리마리뷰 상품평 개수 업데이트를 진행해야합니다.",
      "none_guide": "네이버 쇼핑 리뷰 수 연동시 크리마 리뷰 수 정보를 반영하기 위해 반드시 업데이트 해주세요.",
      "none_badge": "진행 전",
      "complete_guide": "네이버 쇼핑 리뷰 수 연동시 크리마 리뷰 수 정보를 반영하기 위해 업데이트를 완료했습니다.",
      "complete_badge": "완료",
      "button_label": "업데이트 방법"
    },
    "review_sync_status": {
      "none": "등록 전",
      "succeeded": "등록 완료",
      "failed": "등록 실패",
      "progress" : "등록 중",
      "no_reviews_to_sync": "등록 제외"
    }
  }
</i18n>
