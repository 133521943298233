import Selectable from './Selectable';

export default {
  mixins: [Selectable],
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentValue: null
    };
  },
  mounted() {
    this.setCurrentValue(this.value, false);
  },
  watch: {
    value(newValue) {
      this.setCurrentValue(newValue, false);
    }
  },
  methods: {
    setCurrentValue(newValue, emit = true) {
      this.currentValue = this.castValue(newValue);
      if (emit) this.$emit('change', this.currentValue);
    }
  }
};
