import { constructEnum } from '@/lib/enum';

export default constructEnum('UserRole', {
  NONMEMBER: 5,
  USER: 10,
  SAMPLE_BRAND: 15,
  BRAND: 20,
  SHOP_BUILDER: 21,
  SUB_BRAND: 25,
  AGENCY: 30,
  CAFE24_API: 35,
  THIRD_PARTY: 40,
  ADMIN: 50
});
