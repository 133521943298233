<template>
  <AppModalDefault
    :title="$t('local.title')"
    :sub-title="$t('local.sub_title', { id: batchJobId })"
    width="middle"
    @close="close"
  >
    <AppAjaxContent slot="body" :is-loading="isLoading">
      <template #default>
        <BatchJobSummary v-bind="{ batchJob }" />
        <BatchJobResultTable
          v-bind="{ batchJob, successItemsColumns, failedItemsColumns }"
        >
          <template #failed-cell="{ row, column }">
            <template v-if="column.id === 'error'">
              <span v-tooltip="tooltip(row)"
                >{{ error(row)
                }}<AppSvgIcon
                  v-if="tooltip(row)"
                  name="icon-info-tooltip"
                  class="ProductBatchJobSourceProductCodesDialog__icon"
              /></span>
            </template>
          </template>
        </BatchJobResultTable>
      </template>
    </AppAjaxContent>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import BatchJobSummary from '@/views/components/BatchJobSummary';
import BatchJobResultTable from '@/views/components/BatchJobResultTable';
import BatchJobResultCode from '@/enums/BatchJobResultCode';
import DialogView from '@/mixins/DialogView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'ProductBatchJobSourceProductCodesDialog',
  components: { BatchJobSummary, BatchJobResultTable },
  mixins: [DialogView, ResourceViewNoRoute],
  props: { batchJobId: { type: Number, required: true } },
  data() {
    return {
      successItemsColumns: [
        { id: 'product_code', label: this.$t('local.product_code') },
        {
          id: 'product',
          label: this.$t('local.product'),
          type: 'product'
        },
        {
          id: 'source_product_code',
          label: this.$t('local.source_product_code')
        }
      ],
      failedItemsColumns: [
        {
          id: 'line_no',
          label: this.$t('local.error_line_no')
        },
        { id: 'product_code', label: this.$t('local.product_code') },
        {
          id: 'source_product_code',
          label: this.$t('local.source_product_code')
        },
        { id: 'error', label: this.$t('app.error') }
      ],
      isLoading: true,
      batchJob: null
    };
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/batch_jobs/${this.batchJobId}`, { params })
        .then(({ data }) => (this.batchJob = data.batch_job))
        .finally(() => (this.isLoading = false));
    },
    error(row) {
      switch (row.result_code) {
        case BatchJobResultCode.FAILURE_INVALID_PRODUCT_CODE:
          return this.$t('error.invalid_product_code');
        case BatchJobResultCode.FAILURE_EMPTY_PRODUCT_CODE:
          return this.$t('error.product_code_must_be_present');
        case BatchJobResultCode.FAILURE_ALREADY_EXISTS_PRODUCT_CODE:
          return this.$t('error.source_product_code_already_exists');
        default:
          return row.error_message;
      }
    },
    tooltip(row) {
      return row.result_code ===
        BatchJobResultCode.FAILURE_ALREADY_EXISTS_PRODUCT_CODE
        ? this.$t('error.source_product_code_already_exists_tooltip', [
            row.already_assigned_product_code
          ])
        : '';
    }
  }
};
</script>

<i18n locale="ko">
{
  "local": {
    "title": "일괄 등록 처리 결과",
    "sub_title": "#{id} 상세 내역",
    "product_code": "현재 쇼핑몰 상품 번호",
    "product": "현재 쇼핑몰 상품",
    "source_product_code": "리뷰를 가져올 상품 번호",
    "error_line_no": "CSV 줄 번호"
  },
  "error": {
    "invalid_product_code": "현재 쇼핑몰 상품 없음",
    "product_code_must_be_present": "현재 쇼핑몰 상품 미입력",
    "source_product_code_already_exists": "'리뷰를 가져올 쇼핑몰 상품 번호'를 이미 다른 상품에서 저장",
    "source_product_code_already_exists_tooltip": "리뷰 가져올 쇼핑몰 상품번호가 이미 다른 상품 (상품번호: {0})에 저장되어 있습니다."
  }
}
</i18n>

<style lang="scss" scoped>
.ProductBatchJobSourceProductCodesDialog__icon {
  position: relative;
  top: 2px;
  margin-left: 4px;
}
</style>
