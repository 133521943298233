import { mapState, mapGetters } from 'vuex';
import ReviewPointType from '@/enums/ReviewPointType';

export default {
  computed: {
    ...mapState('session', ['reviewSettings']),
    ...mapGetters('session', ['currencyKey', 'pointTypeText'])
  },
  beforeCreate() {
    const orgT = this.$t;
    this.$t = function(key, values) {
      return orgT.call(this, key, {
        ...values,
        point_type: this.$store.getters['session/pointTypeText']
      });
    };
  },
  methods: {
    formatMileage(amount) {
      const amountText =
        typeof amount === 'string'
          ? amount
          : this.formatCurrencyWithoutUnit(amount);

      return this.reviewSettings.review_point_type === ReviewPointType.POINT
        ? `${amountText} ${this.pointTypeText}`
        : this.$t(this.currencyKey, [amountText]);
    }
  }
};
