<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{
      ...formProps,
      submitLabel: $t('submit_label'),
      detectFormDataChange: false,
      objectId: 'widget_initializations'
    }"
    v-on="formEvents"
    @close="close"
  >
    <AppInfoBox class="ReviewSettingsInitialSetupDialog__info-box">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="$t('info_box.initial_setup_head_html')" />
      <!-- eslint-enable vue/no-v-html -->
      <AppExternalLink
        class="ReviewSettingsInitialSetupDialog__info-box-link"
        :to="sampleUrl"
        >{{ $t('info_box.initial_setup_cta') }}</AppExternalLink
      >
      <span>{{ $t('info_box.initial_setup_foot_html') }}</span>
    </AppInfoBox>
    <input
      v-if="formObject.reviews_count"
      type="hidden"
      name="widget_initial_intallation_types[]"
      :value="InstallationPresetWidgetType.PRODUCT_DETAIL_COUNT_WIDGET"
    />
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import { mapActions, mapMutations } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import InstallationPresetWidgetType from '@/enums/InstallationPresetWidgetType';
import DefaultPositionCheckResult from '@/enums/DefaultPositionCheckResult';
import globalEventBus from '@/lib/globalEventBus';

export default {
  name: 'ReviewSettingsInitialSetupDialog',
  mixins: [DialogFormView],
  data() {
    return {
      orgFormObject: {
        reviews_count_display_format: '%{reviews_count}'
      },
      sampleUrl: 'https://sample.cre.ma/v2/review'
    };
  },
  computed: {
    InstallationPresetWidgetType: () => InstallationPresetWidgetType,
    formSections() {
      return [
        {
          id: 'section_detail',
          label: this.$t('sections.section_detail'),
          groups: [
            {
              id: 'product_detail_default_widget',
              name: 'widget_initial_intallation_types',
              type: 'multiple_select_checkbox',
              options: InstallationPresetWidgetType.options([
                InstallationPresetWidgetType.PRODUCT_DETAIL_LIST_WIDGET,
                InstallationPresetWidgetType.PRODUCT_DETAIL_BOARD_WIDGET
              ]),
              label: this.$t('middle_sections.normal')
            },
            {
              id: 'product_detail_option_footer_widget',
              name: 'widget_initial_intallation_types',
              groupDescription: this.$t(
                'description.product_thumbnail_bottom_positioned_widget'
              ),
              type: 'multiple_select_checkbox',
              options: InstallationPresetWidgetType.options([
                InstallationPresetWidgetType.PRODUCT_DETAIL_SCORE_WIDGET,
                InstallationPresetWidgetType.PRODUCT_DETAIL_PREVIEW_WIDGET
              ]),
              label: this.$t('middle_sections.option')
            },
            {
              id: 'product_reviews_option_header_widget',
              name: 'widget_initial_intallation_types',
              groupDescription: this.$t(
                'description.product_reviews_top_positioned_widget'
              ),
              type: 'multiple_select_checkbox',
              options: InstallationPresetWidgetType.options([
                InstallationPresetWidgetType.PRODUCT_DETAIL_GALLERY_WIDGET
              ]),
              label: ''
            },
            {
              id: 'product_detail_option_header_widget',
              name: 'widget_initial_intallation_types',
              groupDescription: this.$t(
                'description.product_detail_top_positioned_widget'
              ),
              type: 'multiple_select_checkbox',
              options: InstallationPresetWidgetType.options([
                InstallationPresetWidgetType.PRODUCT_DETAIL_DETACHABLE_BOARD_WIDGET,
                InstallationPresetWidgetType.PRODUCT_DETAIL_HORIZONTAL_SCROLL_WIDGET,
                InstallationPresetWidgetType.PRODUCT_DETAIL_SOCIAL_WIDGET
              ]),
              label: '',
              hint: this.$t('hint.product_detail_option_widget')
            },
            {
              id: 'product_detail_option_footer_widget',
              name: 'widget_initial_intallation_types',
              groupDescription: this.$t(
                'description.product_option_footer_positioned_widget'
              ),
              type: 'multiple_select_checkbox',
              options: InstallationPresetWidgetType.options([
                InstallationPresetWidgetType.PRODUCT_DETAIL_OPTIONS_WIDGET
              ]),
              label: ''
            },
            {
              id: 'reviews_count',
              name: 'reviews_count',
              type: 'checkbox',
              label: this.$t('middle_sections.reviews_count')
            },
            {
              id: 'reviews_count_display_format',
              name: 'reviews_count_display_format',
              type: 'keyword_textarea',
              groupDescription: this.$t('reviews_count_display_format'),
              label: '',
              disabled: !this.formObject.reviews_count,
              keywords: this.keywords,
              singleLine: true,
              validate: [
                {
                  rule: 'keywords',
                  keywords: this.keywords
                },
                'required'
              ]
            }
          ]
        },
        {
          id: 'section_reviews',
          label: this.$t('sections.section_reviews'),
          groups: [
            {
              id: 'reviews_default_widget',
              name: 'widget_initial_intallation_types',
              type: 'multiple_select_checkbox',
              options: InstallationPresetWidgetType.options([
                InstallationPresetWidgetType.REVIEW_BOARD_LIST_WIDGET,
                InstallationPresetWidgetType.REVIEW_BOARD_BOARD_WIDGET,
                InstallationPresetWidgetType.REVIEW_BOARD_PRODUCTS_GALLERY_WIDGET
              ]),
              label: this.$t('middle_sections.normal')
            },
            {
              id: 'reviews_options_widget',
              name: 'widget_initial_intallation_types',
              groupDescription: this.$t(
                'description.list_widget_top_positioned_widget'
              ),
              type: 'multiple_select_checkbox',
              options: InstallationPresetWidgetType.options([
                InstallationPresetWidgetType.REVIEW_BOARD_HORIZONTAL_SCROLL_WIDGET,
                InstallationPresetWidgetType.REVIEW_BOARD_BEST_WIDGET,
                InstallationPresetWidgetType.REVIEW_BOARD_GALLERY_WIDGET
              ]),
              label: this.$t('middle_sections.option'),
              hint: this.$t('hint.review_board_option_widget')
            }
          ]
        },
        {
          id: 'section_main',
          label: this.$t('sections.section_main'),
          groups: [
            {
              id: 'main_footer_widget',
              name: 'widget_initial_intallation_types',
              groupDescription: this.$t(
                'description.main_footer_positioned_widget'
              ),
              type: 'multiple_select_checkbox',
              options: InstallationPresetWidgetType.options([
                InstallationPresetWidgetType.MAIN_GALLERY_WIDGET,
                InstallationPresetWidgetType.MAIN_ROUND_THUMBNAIL_GALLERY_WIDGET,
                InstallationPresetWidgetType.MAIN_SQUARE_WIDGET,
                InstallationPresetWidgetType.MAIN_PRODUCTS_GALLERY_WIDGET,
                InstallationPresetWidgetType.MAIN_HORIZONTAL_SCROLL_WIDGET
              ]),
              label: ''
            }
          ]
        },
        {
          id: 'section_etc',
          label: this.$t('sections.section_etc'),
          groups: [
            {
              id: 'my_reviews_widget',
              name: 'widget_initial_intallation_types',
              type: 'multiple_select_checkbox',
              options: InstallationPresetWidgetType.options([
                InstallationPresetWidgetType.MY_REVIEWS_MANAGING_REVIEWS_WIDGET,
                InstallationPresetWidgetType.MY_ORDERS_MANAGING_REVIEWS_WIDGET,
                InstallationPresetWidgetType.MYSHOP_MAIN_MANAGING_REVIEWS_WIDGET
              ]),
              label: this.$t('middle_sections.my_reviews')
            }
          ]
        }
      ];
    },
    keywords() {
      const basicKeywords = ['stars', 'score', 'reviews_count'];
      return basicKeywords.map(key => ({
        key,
        desc: this.$t(`keywords.${key}`),
        value: '{{{key}}}'
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('session', ['SET_EXECUTED_WIDGET_INITIAL_INSTALLATION']),
    submit(formData) {
      api
        .post('/widget_initial_installations', formData, {
          successMessage: this.$t('complete')
        })
        .then(({ data }) => {
          globalEventBus.$emit('refresh-content');
          this.SET_EXECUTED_WIDGET_INITIAL_INSTALLATION();
          this.close(true);

          const hasErrors = data.widget_initial_installations.some(
            i =>
              i.installation_result !==
              DefaultPositionCheckResult.DEFAULT_SELECTOR_FOUND
          );
          if (hasErrors)
            this.openDialog([
              'ReviewSettingsInitialSetupResultDialog',
              { widgetInitialInstallations: data.widget_initial_installations }
            ]);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewSettingsInitialSetupDialog__info-box {
  margin-bottom: 12px;
}

.ReviewSettingsInitialSetupDialog__info-box-link {
  text-decoration: underline;
}

::v-deep {
  #section_detail--reviews_count_display_format {
    margin-top: 0px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "리뷰 위젯 설치",
  "reviews_count_display_format": "표시 정보",
  "submit_label": "위젯 생성 및 설치",
  "info_box": {
    "initial_setup_head_html": "모든 위젯은 PC, 모바일별 크리마에서 지정한 쇼핑몰 기본 위치에 설치됩니다.<br />위젯에 대한 정보는 ",
    "initial_setup_foot_html": "에서 확인할 수 있습니다.",
    "initial_setup_cta": "리뷰 샘플페이지"
  },
  "sections": {
    "section_detail": "상품 상세 페이지",
    "section_reviews": "전체 리뷰 페이지",
    "section_main": "메인 페이지",
    "section_etc": "기타 리뷰 위젯"
  },
  "middle_sections": {
    "normal": "기본 위젯",
    "option": "옵션 위젯",
    "reviews_count": "리뷰 수 위젯 설치",
    "my_reviews": "내 리뷰 위젯"
  },
  "description": {
    "product_thumbnail_bottom_positioned_widget": "상품 이미지 하단에 설치할 위젯",
    "product_reviews_top_positioned_widget": "상품 리뷰 영역 최상단에 설치할 위젯",
    "product_detail_top_positioned_widget": "상품 정보 영역 최상단에 설치할 위젯",
    "product_option_footer_positioned_widget": "상품 옵션 영역 하단에 설치할 위젯",
    "list_widget_top_positioned_widget": "리스트 위젯 상단에 설치할 위젯",
    "main_footer_positioned_widget": "메인 페이지 가장 하단에 설치할 위젯"
  },
  "my_reviews_page_type": {
    "my_reviews": "내 리뷰 페이지",
    "order_list": "내 주문 페이지"
  },
  "hint": {
    "product_detail_option_widget": "각 영역에 위젯을 모두 설치할 경우 옵션 위젯 순서대로 노출됩니다.",
    "review_board_option_widget": "위젯을 모두 설치할 경우 옵션 위젯 순서대로 노출됩니다."
  },
  "keywords": {
    "stars": "별점 아이콘",
    "score": "평점",
    "reviews_count": "상품 리뷰 수"
  },
  "complete": "위젯 설치가 완료되었습니다."
}
</i18n>
