<template>
  <div class="AppSelectFilterable dropdown" :style="{ width }">
    <AppSelect
      v-bind="{
        id,
        name,
        value,
        disabled,
        options,
        placeholder,
        eventBus,
        invalid
      }"
      :is-focused="isShowingDropdown"
      :on-demand-options="onDemandOptions"
      :class="[
        'AppSelectFilterable__select',
        {
          'AppSelectFilterable__select--invalid': invalid
        }
      ]"
      @mousedown.native.prevent
      @click.native="click"
      @change="$emit('change', $event)"
    />
    <AppModeless
      v-if="isShowingDropdown"
      class="dropdown__dialog AppSelectFilterable__dialog"
      @close="closeDropdown"
    >
      <AppSelectSearchDropdown
        :data="optionsWithSelect"
        :dropdown-props="dropdownProps"
        :is-showing-dropdown="isShowingDropdown"
        :on-demand-options="onDemandOptions"
        @select="select"
        @close="closeDropdown"
      />
    </AppModeless>
    <AppSelectSearchDropdown
      v-if="width === null"
      :data="optionsWithSelect"
      :on-demand-options="onDemandOptions"
      class="AppSelectFilterable__size-holder"
    />
  </div>
</template>

<script>
import Vue from 'vue';

import AppSelectSearchDropdown from './AppSelect/AppSelectSearchDropdown';

export default {
  name: 'AppSelectFilterable',
  components: { AppSelectSearchDropdown },
  model: {
    event: 'change'
  },
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    onDemandOptions: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    },
    dropdownProps: { type: Object, default: undefined }
  },
  data() {
    return {
      isShowingDropdown: false,
      eventBus: new Vue()
    };
  },
  computed: {
    optionsWithSelect() {
      return this.options.map(option => ({
        ...option,
        isSelected: option.value === this.value
      }));
    }
  },
  methods: {
    select(value) {
      if (this.options.some(o => !o.disabled && o.value === value))
        this.eventBus.$emit('select', value);
      this.closeDropdown();
    },
    closeDropdown() {
      this.isShowingDropdown = false;
      this.$emit('blur');
    },
    click() {
      if (!this.disabled) this.isShowingDropdown = !this.isShowingDropdown;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_z-indexes.scss';

.AppSelectFilterable {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.AppSelectFilterable__select {
  width: 100%;
}

.AppSelectFilterable__dialog {
  width: 100%;
}

.AppSelectFilterable__size-holder {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
</style>
