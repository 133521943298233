<template>
  <div class="AppChartSmallBar">
    <div
      :class="['AppChartSmallBar__bar', `AppChartSmallBar__bar--${color}`]"
      :style="{ width: barWidth }"
    />
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'AppChartSmallBar',
  props: {
    color: {
      type: String,
      required: true,
      validator: v => ['grey', 'blue', 'green'].includes(v)
    },
    numerator: { type: Number, required: true },
    denominator: { type: Number, required: true }
  },
  computed: {
    barWidth() {
      const { numerator, denominator } = this;
      if (numerator === 0 || denominator === 0) return '0';
      if (numerator === denominator) return '100%';

      const percentage = _.clamp(
        Math.round((numerator / denominator) * 100),
        1,
        99
      );
      return `${percentage}%`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppChartSmallBar {
  width: 100%;
  height: 8px;
  border-radius: 2px;

  background-color: $color-grey-15;
}

.AppChartSmallBar__bar {
  height: 100%;
  border-radius: 2px;

  &--grey {
    background-color: $color-grey-32;
  }

  &--green {
    background-color: #cbe6c1;
  }

  &--blue {
    background-color: #b5d2ee;
  }
}
</style>
