<template>
  <AppModalForm
    :title="$t('app.edit_icon')"
    :sub-title="userGrade.name"
    :form-props="{ ...formProps, objectId: 'review_user_grade' }"
    v-on="formEvents"
  />
</template>

<script>
import { mapMutations } from 'vuex';

import DialogFormView from '@/mixins/DialogFormView';
import api from '@/lib/api';

export default {
  name: 'ReviewUserGradeEditDialog',
  mixins: [DialogFormView],
  props: {
    orgUserGrade: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      orgFormObject: this.orgUserGrade
    };
  },
  computed: {
    userGrade() {
      return this.formObject;
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'icon',
              type: 'image',
              label: this.$t('review_user_grade.icon'),
              value: this.userGrade.icon_url
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapMutations('userGrade', ['UPDATE_ICON_URL']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(`/review/user_grades/${this.userGrade.id}`, formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.UPDATE_ICON_URL({
            icon_url: data.user_grade.icon_url,
            id: this.userGrade.id
          });
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>
