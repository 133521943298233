<template>
  <AppModalForm
    :title="$t('title', [dispatchText])"
    :sub-title="campaign.name"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId: 'campaign',
      disabled: isArchivedCampaign
    }"
    v-on="formEvents"
  >
    <template #group="{ id, disabled, errors }">
      <template v-if="id === 'dispatch_weekdays_type'">
        <div class="AppForm__group-field">
          <AppSelectRadio
            v-model="formObject.dispatch_weekdays_type"
            :options="dispatchDateOptions"
            :disabled="disabled"
          >
            <template #sub-item="{ value, checked }">
              <template v-if="value === 'everyday'">
                <input
                  type="hidden"
                  name="campaign[dispatch_weekdays_value]"
                  :disabled="disabled || !checked"
                  :value="EVERYDAY_VALUE"
                />
              </template>
              <template v-else>
                <AppWeekdaySelectButton
                  v-model="formObject.dispatch_weekdays_value"
                  name="campaign[dispatch_weekdays_value]"
                  :disabled="disabled || !checked"
                  :invalid="!!errors.dispatch_weekdays_value"
                  @change="validateField('dispatch_weekdays_value')"
                />
                <AppFormError :error="errors.dispatch_weekdays_value" />
              </template>
            </template>
          </AppSelectRadio>
        </div>
      </template>
      <template v-else-if="id === 'popup_check_scroll'">
        <div class="AppForm__group-field">
          <i18n path="form.popup_check_scroll.message">
            <AppSelect
              v-model="formObject.popup_check_direction"
              name="campaign[popup_check_direction]"
              inline
              :options="PopupCheckDirection.options()"
            />
            <AppNumberInput
              v-model="formObject.popup_check_offset_y"
              name="campaign[popup_check_offset_y]"
              inline
              :digits="2"
              :default="0"
            />
          </i18n>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import TargetCampaignSettingsView from './TargetCampaignSettingsView';
import PopupCheckDirection from '@/enums/PopupCheckDirection';
import TargetCampaignType from '@/enums/TargetCampaignType';

export default {
  name: 'TargetCampaignSettingsDispatchDateTimeDialog',
  mixins: [DialogFormView, TargetCampaignSettingsView],
  props: { campaign: { type: Object, required: true } },
  data() {
    return {
      isLoading: true,
      EVERYDAY_VALUE: 127
    };
  },
  computed: {
    ...mapGetters('targetCampaign', [
      'isOneTimeCampaign',
      'isDmCampaign',
      'isPopupCampaign',
      'isArchivedCampaign'
    ]),
    ...mapGetters('targetCampaigns', ['campaignText']),
    PopupCheckDirection: () => PopupCheckDirection,
    dispatchText() {
      return this.campaignText(this.campaign, 'dispatch');
    },
    dispatchDateOptions() {
      return ['everyday', 'selected_weekdays'].map(value => ({
        label: this.$t(value, [this.dispatchText]),
        value
      }));
    },
    groupOptions() {
      return {
        dispatch_date: {
          label: this.$t('form.dispatch_schedule.label', [this.dispatchText]),
          type: 'date',
          minDate: moment().toVal(),
          validate: [
            'required',
            {
              rule: v => moment(v).isSameOrAfter(moment(), 'day'),
              errorMessage: this.$t(
                'validations.dispatch_date_must_be_gte_today'
              )
            }
          ],
          required: true
        },
        dispatch_weekdays_type: {
          label: this.$t('form.dispatch_schedule.label', [this.dispatchText]),
          required: true,
          fields:
            this.formObject.dispatch_weekdays_type === 'selected_weekdays'
              ? [
                  {
                    id: 'dispatch_weekdays_value',
                    validate: [
                      {
                        rule: 'positive_integer',
                        errorMessage: this.$t('validations.required')
                      }
                    ]
                  }
                ]
              : []
        },
        dispatch_hour: this.useDispatchTimeSettings
          ? {
              label: this.$t('form.dispatch_hour.label', [this.dispatchText]),
              type: 'select_hour',
              selectableHours: [...Array(13).keys()].map(h => h + 8),
              validate: [
                ...(this.isOneTimeCampaign &&
                moment(this.formObject.dispatch_date).isSame(moment(), 'day')
                  ? [
                      {
                        rule: v => v > moment().hour(),
                        errorMessage: this.$t(
                          'validations.dispatch_hour_must_be_gt_now'
                        )
                      }
                    ]
                  : [])
              ]
            }
          : {
              label: this.$t('form.dispatch_hour.label', [this.dispatchText]),
              type: 'static',
              value: this.$t('form.dispatch_hour.realtime_message')
            },
        popup_duration_in_hours: {
          type: 'number',
          digits: 2,
          i18n: 'unit.hour',
          validate: ['required', 'positive_integer']
        },
        popup_check_min_pageviews: {
          type: 'number',
          digits: 2,
          default: 0,
          i18n:
            'target_settings.campaign.dispatch/datetime.popup_check_min_pageviews',
          hint: this.$t('form.popup_check_min_pageviews.hint')
        }
      };
    },
    groupIds() {
      return this.isDmCampaign
        ? [
            this.isOneTimeCampaign ? 'dispatch_date' : 'dispatch_weekdays_type',
            'dispatch_hour'
          ]
        : this.isPopupCampaign
        ? [
            'dispatch_weekdays_type',
            'dispatch_hour',
            this.useDispatchTimeSettings ? 'popup_duration_in_hours' : null,
            'popup_check_min_pageviews',
            'popup_check_scroll'
          ]
        : [];
    },
    formSections() {
      return [
        {
          id: 'target_dispatch_date_time_settings',
          groups: this.groupIds
            .filter(v => v)
            .map(id => {
              const groupOption = this.groupOptions[id] || {};
              return {
                id,
                ...groupOption,
                label: groupOption.label || this.$t(`form.${id}.label`)
              };
            })
        }
      ];
    },
    useDispatchTimeSettings() {
      return (
        this.campaign.campaign_type !== TargetCampaignType.NEW_VISITOR_SIGNUP
      );
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaign.id}/dispatch_datetime`)
      .then(({ data }) => {
        const campaign = data.campaign;
        const { dispatch_weekdays_value } = campaign;
        const dispatch_weekdays_type =
          dispatch_weekdays_value == this.EVERYDAY_VALUE
            ? 'everyday'
            : 'selected_weekdays';
        this.orgFormObject = {
          ...campaign,
          dispatch_weekdays_type,
          dispatch_weekdays_value:
            dispatch_weekdays_type === 'everyday' ? 0 : dispatch_weekdays_value
        };
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      if (!this.confirmSubmit(this.campaign)) return;

      this.isSubmitting = true;
      api
        .patch(
          `/target/campaigns/${this.campaign.id}/dispatch_datetime`,
          formData,
          { successMessage: this.$t('app.saved') }
        )
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "{0} 시점",
  "form": {
    "dispatch_schedule": {
      "label": "{0} 일정"
    },
    "dispatch_hour": {
      "label": "{0} 시작 시간",
      "realtime_message": "대상 회원 발생 시 실시간 노출"
    },
    "popup_duration_in_hours": {
      "label": "노출 기간"
    },
    "popup_check_min_pageviews": {
      "label": "팝업 페이지 노출 조건",
      "hint": "최초 접속 후 24시간 누적 페이지 조회수 기준"
    },
    "popup_check_scroll": {
      "label": "팝업 스크롤 노출 조건",
      "message": "{0}에서 {1}px 지점에 스크롤되면 팝업을 띄웁니다."
    }
  },
  "everyday": "매일 {0}",
  "selected_weekdays": "매주 선택한 요일에 {0}",
  "hour": "{0}시",
  "validations": {
    "dispatch_date_must_be_gte_today": "오늘 또는 이후의 날짜를 지정해야 합니다.",
    "dispatch_hour_must_be_gt_now": "현재 시간 이후의 시간을 지정해야 합니다."
  }
}
</i18n>
