<template>
  <AppModalDefault
    class="TheInstallRequestRenewalDialog"
    :title="$t('title')"
    is-maximized-by-default
    is-maximized-modal-page
    @close="close"
  >
    <template #body>
      <div class="TheInstallRequestRenewalDialog__container">
        <div class="TheInstallRequestRenewalDialog__subtitle">
          {{ $t('subtitle') }}
        </div>
        <AppForm
          id="installRequestRenewal"
          class="TheInstallRequestRenewalDialog__form"
          object-id="install_request"
          v-bind="formProps"
          :submit-button="{
            submitLabel: $t('submit'),
            disabled: !widgetRequestCompleted,
            clickHandler: submit
          }"
          v-on="formEvents"
        >
          <template #group="{ id }">
            <template v-if="id === 'renewal_target_device' && isResponsive">
              <AppTipBox
                class="TheInstallRequestRenewalDialog__responsive-tip"
                :text="$t('renewal_target.device.responsive_tip')"
                :margin-bottom="4"
              />
              <AppMultipleSelectCheckbox
                v-model="formObject.renewal_target_device"
                :options="deviceOptions"
                :label="$t('renewal_target.device.label')"
              />
            </template>
            <template v-else-if="id === 'renewal_schedule'">
              <AppDatePicker
                v-model="formObject.renewal_schedule_date"
                class="TheInstallRequestRenewalDialog__renewal-schedule-date"
                :disabled="formObject.renewal_schedule_already_live"
                :min-date="renewalScheduleMinDate"
              />
              <AppCheckbox
                v-model="formObject.renewal_schedule_already_live"
                class="TheInstallRequestRenewalDialog__already-live"
                :label="$t('renewal_schedule.already_live_label')"
              />
            </template>
          </template>
        </AppForm>
      </div>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import DialogView from '@/mixins/DialogView';
import FormView from '@/mixins/FormView';
import WidgetDeviceType from '@/enums/WidgetDeviceType';

export default {
  name: 'TheInstallRequestRenewalDialog',
  mixins: [DialogView, FormView],
  data() {
    return {
      isLoading: true,
      widgetRequestCompleted: false,
      renewalScheduleMinDate: moment().toVal(),
      deviceOptions: [
        {
          id: 'pc',
          label: this.$t('renewal_target.device.options.pc'),
          value: WidgetDeviceType.PC
        },
        {
          id: 'mobile',
          label: this.$t('renewal_target.device.options.mobile'),
          value: WidgetDeviceType.MOBILE
        }
      ],
      pcSkinList: [],
      mobileSkinList: []
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    widgetRequestStatusBadgeLabel() {
      return this.$t(
        `install_request.status.${
          this.widgetRequestCompleted ? 'completed' : 'not_completed'
        }`
      );
    },
    formSections() {
      return [
        {
          id: 'manager_info',
          label: this.$t('manager_info.title'),
          groups: [
            {
              id: 'manager_info_tip',
              label: null,
              type: 'tip_box',
              value: this.$t('manager_info.tip')
            },
            {
              id: 'manager_info_name',
              label: this.$t('manager_info.name.label'),
              type: 'text',
              placeholder: this.$t('manager_info.name.placeholder'),
              validate: ['required']
            },
            {
              id: 'manager_info_email',
              label: this.$t('manager_info.email.label'),
              type: 'text',
              placeholder: this.$t('manager_info.email.placeholder'),
              validate: ['required', 'email_format']
            },
            {
              id: 'manager_info_phone',
              label: this.$t('manager_info.phone.label'),
              type: 'text',
              placeholder: this.$t('manager_info.phone.placeholder'),
              validate: ['required', 'mobile_phone_format']
            }
          ]
        },
        {
          id: 'renewal_target',
          label: this.$t('renewal_target.title'),
          groups: this.renewalTargetFormGroups.filter(g => g)
        },
        {
          id: 'install_request',
          label: this.$t('install_request.title'),
          groups: [
            {
              id: 'install_request_tip',
              label: null,
              type: 'tip_box',
              value: this.$t('install_request.tip')
            },
            {
              id: 'install_request',
              label: this.$t('install_request.label'),
              titleBadge: {
                label: this.widgetRequestStatusBadgeLabel,
                badgeStyle: this.widgetRequestCompleted
                  ? 'default'
                  : 'red-outline',
                badgeSize: 'small'
              },
              type: 'button',
              value: this.$t('install_request.label'),
              disabled: this.installRequestDisabled,
              buttonTooltip: this.installRequestDisabled
                ? this.$t('install_request.disabled_tooltip')
                : null,
              clickHandler: this.openInstallRequestRenewalDirectionDialog
            }
          ]
        },
        {
          id: 'renewal_schedule',
          label: this.$t('renewal_schedule.title'),
          groups: [
            {
              id: 'renewal_schedule',
              label: this.$t('renewal_schedule.label'),
              groupDescription: this.$t('renewal_schedule.description'),
              type: 'date',
              validate: this.formObject.renewal_schedule_already_live
                ? []
                : ['required']
            }
          ]
        }
      ];
    },
    renewalTargetFormGroups() {
      return [
        {
          id: 'renewal_target_responsiveness',
          label: this.$t('renewal_target.responsiveness.label'),
          type: 'select_radio',
          options: [
            {
              id: 'is_responsive',
              label: this.$t('renewal_target.responsiveness.is_responsive'),
              value: true
            },
            {
              id: 'is_not_responsive',
              label: this.$t('renewal_target.responsiveness.is_not_responsive'),
              value: false
            }
          ],
          validate: ['required']
        },
        ...(this.isResponsive || this.isNotResponsive
          ? [
              {
                id: 'renewal_target_device',
                label: this.$t('renewal_target.device.label'),
                type: 'multiple_select_checkbox',
                options: this.deviceOptions,
                validate: ['required']
              }
            ]
          : []),
        ...(this.isResponsive
          ? this.responsiveSkinListGroups
          : this.notResponsiveSkinListGroups)
      ];
    },
    responsiveSkinListGroups() {
      return this.isPcSelected && this.isMobileSelected
        ? [
            {
              id: 'renewal_target_skin_responsive',
              label: this.$t('renewal_target.skin.responsive_label'),
              type: 'select_filterable',
              options: this.pcSkinList.concat(this.mobileSkinList),
              placeholder: this.$t('renewal_target.skin.placeholder'),
              validate: ['required']
            }
          ]
        : this.isPcSelected
        ? [
            {
              id: 'renewal_target_skin_pc',
              label: this.$t('renewal_target.skin.pc_label'),
              type: 'select_filterable',
              options: this.pcSkinList.concat(this.mobileSkinList),
              placeholder: this.$t('renewal_target.skin.placeholder'),
              validate: ['required']
            }
          ]
        : this.isMobileSelected
        ? [
            {
              id: 'renewal_target_skin_mobile',
              label: this.$t('renewal_target.skin.mobile_label'),
              type: 'select_filterable',
              options: this.pcSkinList.concat(this.mobileSkinList),
              placeholder: this.$t('renewal_target.skin.placeholder'),
              validate: ['required']
            }
          ]
        : [];
    },
    notResponsiveSkinListGroups() {
      return [
        ...(this.isPcSelected
          ? [
              {
                id: 'renewal_target_skin_pc',
                label: this.$t('renewal_target.skin.pc_label'),
                type: 'select_filterable',
                options: this.pcSkinList,
                placeholder: this.$t('renewal_target.skin.placeholder'),
                validate: ['required']
              }
            ]
          : []),
        ...(this.isMobileSelected
          ? [
              {
                id: 'renewal_target_skin_mobile',
                label: this.$t('renewal_target.skin.mobile_label'),
                type: 'select_filterable',
                options: this.mobileSkinList,
                placeholder: this.$t('renewal_target.skin.placeholder'),
                validate: ['required']
              }
            ]
          : [])
      ];
    },
    isResponsive() {
      return this.formObject.renewal_target_responsiveness === true;
    },
    isNotResponsive() {
      return this.formObject.renewal_target_responsiveness === false;
    },
    isPcSelected() {
      return this.formObject.renewal_target_device?.includes(
        WidgetDeviceType.PC
      );
    },
    isMobileSelected() {
      return this.formObject.renewal_target_device?.includes(
        WidgetDeviceType.MOBILE
      );
    },
    installRequestDisabled() {
      return !this.formObject.renewal_target_device?.length;
    }
  },
  watch: {
    'formObject.renewal_target_responsiveness': {
      handler: 'resetSkinValues'
    },
    'formObject.renewal_target_device': {
      handler: 'resetSkinValues'
    }
  },
  mounted() {
    api.get('install_request/renewal/skin_lists').then(({ data }) => {
      this.pcSkinList = this.parseSkinList(data.pc_skin_list);
      this.mobileSkinList = this.parseSkinList(data.mobile_skin_list);
    });
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('installRequest', ['SET_INSTALL_DEVICE_TYPES']),
    parseSkinList(skinList) {
      return skinList.map(skin => ({
        label: this.skinLabel(skin),
        value: skin.skin_code,
        disabled: !this.isSkinAvailable(skin)
      }));
    },
    skinLabel(skin) {
      let label = `[${skin.skin_code}] ${skin.skin_name}`;

      if (skin.published_in === 'unpublished') {
        return label;
      } else if (
        parseInt(skin.published_in) === this.currentBrand.cafe24_shop_no
      ) {
        return (
          label +
          ` (${this.$t('renewal_target.skin.published_in_current_mall')})`
        );
      } else {
        return (
          label + ` (${this.$t('renewal_target.skin.published_in_multi_mall')})`
        );
      }
    },
    isSkinAvailable(skin) {
      return (
        skin.published_in === 'unpublished' ||
        parseInt(skin.published_in) === this.currentBrand.cafe24_shop_no
      );
    },
    resetSkinValues(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.formObject.renewal_target_skin_pc = null;
        this.formObject.renewal_target_skin_mobile = null;
        this.formObject.renewal_target_skin_responsive = null;
      }
    },
    openInstallRequestRenewalDirectionDialog() {
      this.widgetRequestCompleted = !this.widgetRequestCompleted; // TODO : 실제 로직으로 변경하고 테스트용 토글 로직 삭제
      this.SET_INSTALL_DEVICE_TYPES(this.formObject.renewal_target_device);
      this.openDialog('InstallRequestRenewalDirectionDialog');
    },
    submit() {
      alert('submitted formObject: ' + JSON.stringify(this.formObject));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.TheInstallRequestRenewalDialog__container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.TheInstallRequestRenewalDialog__subtitle {
  @include text-sub-title;
}

.TheInstallRequestRenewalDialog__responsive-tip {
  margin-top: 4px;
}

.TheInstallRequestRenewalDialog__renewal-schedule-date {
  margin: 4px 0;
}

.TheInstallRequestRenewalDialog__already-live {
  display: block;
}

::v-deep {
  .TheInstallRequestRenewalDialog__container {
    .AppForm__section {
      width: 680px;
      margin: 16px auto 32px auto;
      padding: 24px;
      border-radius: 4px;
      border-top: 0;
      background-color: #fff;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .AppForm__form-controls {
      text-align: center;
    }
  }
}
</style>

<i18n locale="ko">
{
  "title": "리뉴얼 위젯 설치 요청",
  "subtitle": "리뉴얼 위젯 설치 요청을 위해 아래 내용을 입력해주세요.",
  "submit": "리뉴얼 위젯 설치 요청",
  "manager_info": {
    "title": "담당자 정보",
    "tip": "리뉴얼 작업과 관련 주된 소통은 이메일로 진행됩니다.",
    "name": {
      "label": "담당자 이름",
      "placeholder": "담당자 이름을 입력해주세요."
    },
    "email": {
      "label": "담당자 이메일",
      "placeholder": "연락 가능한 이메일을 입력해주세요."
    },
    "phone": {
      "label": "담당자 휴대전화 번호",
      "placeholder": "연락 가능한 휴대전화 번호를 입력해주세요."
    }
  },
  "renewal_target": {
    "title": "리뉴얼 적용 정보",
    "responsiveness": {
      "label": "새롭게 적용하는 리뉴얼 스킨이 반응형인가요?",
      "is_responsive": "네",
      "is_not_responsive": "아니오"
    },
    "device": {
      "label": "리뉴얼 범위를 선택해주세요.",
      "responsive_tip": "리뉴얼 스킨이 반응형이더라도 PC, 모바일 위젯 설치는 각각 진행되므로 필요하다면 모두 선택해주세요.",
      "options": {
        "pc": "PC",
        "mobile": "모바일"
      }
    },
    "skin": {
      "label": "위젯을 설치할 리뉴얼 스킨 주소를 입력해주세요.",
      "pc_label": "PC 스킨",
      "mobile_label": "모바일 스킨",
      "responsive_label": "PC/모바일 스킨",
      "placeholder": "스킨을 선택해주세요.",
      "published_in_current_mall": "적용 중",
      "published_in_multi_mall": "멀티몰에 적용 중"
    }
  },
  "install_request": {
    "title": "위젯 설치 요청",
    "tip": "리뉴얼 위젯 설치는 이미 설치된 사이트 리스트 모두 적용되고 신규 사용자에게도 새롭게 보입니다.\n따라서 새로운 스크립트에 이미 그러한 부분이 노출되고 있어도 꼭 설치 요청 내용에 포함해주세요.",
    "label": "위젯 설치 요청",
    "disabled_tooltip": "리뉴얼 정보를 먼저 입력해주세요.",
    "status": {
      "not_completed": "진행 전",
      "completed": "입력 완료"
    }
  },
  "renewal_schedule": {
    "title": "디자인 작업 완료일",
    "label": "리뉴얼 스킨 디자인 작업 완료일을 입력해주세요.",
    "description": "디자인 작업 완료일을 참고하여 리뉴얼 위젯 설치 작업 예상 완료일을 산정합니다.",
    "date_label": "날짜 선택",
    "already_live_label": "리뉴얼 스킨을 이미 적용한 경우 체크해주세요."
  }
}
</i18n>
