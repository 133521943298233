import { constructEnum } from '@/lib/enum';

const DisplayProductDisplayStatus = constructEnum(
  'DisplayProductDisplayStatus',
  {
    NONE: 0,
    SCHEDULED: 10,
    APPLYING: 15,
    DISPLAYING_BUT_APPLYING: 17,
    DISPLAYING: 20,
    ERROR: 1000
  }
);

DisplayProductDisplayStatus.APPLYING_STATUSES = [
  DisplayProductDisplayStatus.APPLYING,
  DisplayProductDisplayStatus.DISPLAYING_BUT_APPLYING
];

DisplayProductDisplayStatus.isApplying = function(displayProductDisplayStatus) {
  return DisplayProductDisplayStatus.APPLYING_STATUSES.includes(
    displayProductDisplayStatus
  );
};

DisplayProductDisplayStatus.isErrored = function(displayProductDisplayStatus) {
  return DisplayProductDisplayStatus.ERROR === displayProductDisplayStatus;
};

export default DisplayProductDisplayStatus;
