import axios from 'axios';
import HttpStatus from '@/enums/HttpStatus';

export default axios.create({
  baseURL: '/api',
  validateStatus: function(status) {
    return (
      (status >= 200 && status < 300) || status === HttpStatus.NOT_MODIFIED
    );
  }
});
