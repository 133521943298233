<template>
  <div>
    <Component
      :is="product.is_combo ? 'AppDropdownMenuButton' : 'AppButton'"
      v-bind="componentProps"
      :label="$t('show_reviews.default')"
    />
  </div>
</template>

<script>
export default {
  name: 'ReviewProductsShowReviewsButton',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    menuItems() {
      return [
        {
          label: this.$t('show_reviews.single_product'),
          type: 'link',
          to: this.singleProductPath
        },
        {
          label: this.$t('show_reviews.combo_products'),
          type: 'link',
          to: this.comboProductPath
        }
      ];
    },
    componentProps() {
      return this.product.is_combo
        ? { 'menu-items': this.menuItems }
        : { to: this.singleProductPath, type: 'link' };
    },
    singleProductPath() {
      return `/review/reviews?search_type=product_code&search_query=${this.product.code}`;
    },
    comboProductPath() {
      return `/review/reviews?combo_product_id=${this.product.id}`;
    }
  }
};
</script>

<i18n locale="ko">
{
  "show_reviews": {
    "default": "리뷰 보기",
    "single_product": "이 상품리뷰 보기",
    "combo_products": "세트 상품리뷰 모두보기"
  }
}
</i18n>
<i18n locale="en">
{
  "show_reviews": {
    "default": "Show review",
    "single_product": "Show this product reviews",
    "combo_products": "Show all combo product reviews"
  }
}
</i18n>
