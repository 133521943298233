<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="campaignName"
    :is-loading="isLoading"
    :form-props="{ ...formProps, objectId: 'campaign', focusGroupId: 'name' }"
    width="middle"
    height="56px"
    v-on="formEvents"
  >
    <input type="hidden" name="campaign_type" :value="campaignType" />
  </AppModalForm>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import NameFormView from '@/mixins/NameFormView';
import TargetCampaignType from '@/enums/TargetCampaignType';

export default {
  name: 'TargetCampaignCreateDialog',
  mixins: [DialogFormView, NameFormView],
  props: { campaignType: { type: Number, required: true } },
  data: () => ({ isLoading: true, orgFormObject: { name: '' } }),
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    ...mapGetters('targetCampaigns', ['campaignPath']),
    campaignName() {
      return TargetCampaignType.t(this.campaignType);
    },
    isProductRecommendationWidget() {
      return TargetCampaignType.isProductRecommendationWidget(
        this.campaignType
      );
    },
    widgetCampaignNameProperty() {
      if (
        [
          TargetCampaignType.PRODUCT_RECOMMENDATION_DETAIL_PAGE,
          TargetCampaignType.PRODUCT_RECOMMENDATION_CATEGORY_PAGE
        ].includes(this.campaignType)
      )
        return this.$t(
          'product_recommendation_widget_campaign_name_property.top'
        );
      return this.$t(
        'product_recommendation_widget_campaign_name_property.default'
      );
    },
    widgetCampaignName() {
      if (
        this.campaignType ==
        TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT
      )
        return TargetCampaignType.shortName(this.campaignType);
      else return TargetCampaignType.t(this.campaignType);
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              label: this.$t('campaign_name'),
              type: 'text',
              validate: [
                'required',
                { rule: 'max_length', limit: 30 },
                {
                  rule: v => this.isNameNotDuplicated(v),
                  errorMessage: this.$t(
                    'target_errors.duplicated_campaign_name'
                  )
                }
              ],
              hint: this.isProductRecommendationWidget
                ? this.$t('product_recommendation_widget_naming_guide_html', [
                    this.widgetCampaignName,
                    this.widgetCampaignNameProperty
                  ])
                : null
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get('target/campaigns/new', {
        params: { campaign_type: this.campaignType }
      })
      .then(({ data }) => {
        this.formObject.name = data.default_name;
        this.existingNames = data.created_campaign_names;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .request({
          url: '/target/campaigns',
          method: 'post',
          data: formData,
          successMessage: this.$t('campaign_created')
        })
        .then(({ data }) => {
          this.close(true);
          const path = this.pathWithBrandParams(
            this.campaignPath(data.campaign)
          );
          this.$router.push(path);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "캠페인 추가",
  "campaign_name": "캠페인 이름",
  "campaign_created": "캠페인을 추가했습니다. 발송을 위해 설정을 완료해주세요.",
  "product_recommendation_widget_campaign_name_property": {
    "top": "상단",
    "default": "기본"
  },
  "product_recommendation_widget_default_name": "{0} - {1}",
  "product_recommendation_widget_naming_guide_html": "캠페인의 효과적인 관리를 위하여 [캠페인 명] - [캠페인 특성] 형식의 이름을 추천합니다.<br />예) {0} - {1} #1"
}
</i18n>
