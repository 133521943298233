<template>
  <TheLoginView
    :message="$t(`message.${failedReason || 'normal'}`)"
    :sub-message="isFailed ? null : otpEmail"
    :is-loading="isSubmitting || isResending"
  >
    <template v-if="!isFailed">
      <AppForm
        id="user"
        v-bind="formProps"
        object-id="user"
        focus-group-id="otp_code"
        form-style="wide"
        validate-only-on-submit
        no-screen
        :submit-button="{
          submitLabel: $t('authenticate'),
          submittingLabel: $t('authenticating')
        }"
        v-on="formEvents"
      />
      <div class="TheLoginOtp__remaining-time">
        {{ $t('remaining_time') }}
        <span class="TheLoginOtp__remaining-time-timer">{{
          remainingTime
        }}</span>
      </div>
      <hr class="TheLoginView__separator" />
      <div class="TheLoginView__action-message">
        {{ $t('resend_message') }}
      </div>
    </template>
    <AppButton
      class="TheLoginView__action-button"
      :label="$t(isResending ? 'resending' : 'resend')"
      :disabled="isResending"
      size="large"
      @click="resend"
    />
  </TheLoginView>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';
import { mapActions } from 'vuex';
import api from '@/lib/api';
import HttpStatus from '@/enums/HttpStatus';
import FormView from '@/mixins/FormView';
import TheLoginView from './TheLoginView';

const OTP_LIFETIME = 300;

export default {
  name: 'TheLoginOtp',
  components: { TheLoginView },
  mixins: [FormView],
  props: {
    otpEmail: { type: String, required: true },
    otpCode: { type: String, default: '' }
  },
  data() {
    return {
      formObject: { otp_code: '' },
      detectFormDataChange: false,
      remainingSeconds: OTP_LIFETIME,
      isResending: false,
      failedReason: null,
      autoFillOtpCode: this.otpCode
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'otp_code',
              label: this.$t('otp_code'),
              placeholder: this.$t('otp_code_placeholder'),
              type: 'text',
              required: false,
              validate: ['required']
            }
          ]
        }
      ];
    },
    remainingTime() {
      const minutes = Math.floor(this.remainingSeconds / 60);
      const seconds = this.remainingSeconds % 60;
      return `${_.padStart(minutes, 2, '0')}:${_.padStart(seconds, 2, '0')}`;
    },
    isFailed() {
      return !!this.failedReason;
    }
  },
  mounted() {
    this.restart();
  },
  methods: {
    ...mapActions('session', ['authenticateOtp', 'redirectToNextPage']),
    ...mapActions('toast', ['createToast']),
    submit(formData) {
      this.isSubmitting = true;
      this.authenticateOtp(formData)
        .then(() => {
          if (
            !!this.$route.query.callback &&
            this.$route.query.callback.startsWith('/')
          ) {
            const params = { data: this.$route.query.data };
            axios.get(this.$route.query.callback, { params });
          }
          if (!this.redirectToNextPage(this.$route.query.next || '/'))
            this.isSubmitting = false;
        })
        .catch(error => {
          this.isSubmitting = false;
          if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            const otp_error = error.response.data.errors[0];
            if (['expired', 'attempts_exceeded'].includes(otp_error)) {
              alert(this.$t(`alert.${otp_error}`));
              this.fail(otp_error);
            } else {
              alert(this.$t('alert.incorrect_code'));
              document.getElementById('user_otp_code').focus();
            }
          } else error.errorHandler();
        });
    },
    resend() {
      this.isResending = true;
      api
        .post('/session/resend_otp_code')
        .then(({ data }) => {
          this.autoFillOtpCode = data.otp_code;
          this.restart();
          this.createToast(this.$t('resent', { email: this.otpEmail }));
        })
        .finally(() => (this.isResending = false));
    },
    restart() {
      this.failedReason = null;
      this.formObject.otp_code = this.autoFillOtpCode;

      const startedAt = moment();
      this.remainingSeconds = OTP_LIFETIME;
      if (this.interval) clearInterval(this.interval);
      this.interval = setInterval(() => {
        const elapsed = moment().diff(startedAt, 'seconds');
        this.remainingSeconds = Math.max(OTP_LIFETIME - elapsed, 0);
        if (this.remainingSeconds === 0) this.fail('expired');
      }, 200);
    },
    fail(failedReason) {
      this.failedReason = failedReason;
      clearInterval(this.interval);
      this.interval = null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheLoginOtp__remaining-time {
  @include text-caption;
  margin-top: 12px;
}

.TheLoginOtp__remaining-time-timer {
  color: $color-red;
}
</style>

<i18n locale="ko">
{
  "message": {
    "normal": "다음 이메일 주소로 2차 인증코드를 발송했습니다.",
    "expired": "2차 인증코드 유효 시간이 만료되었습니다.",
    "attempts_exceeded": "인증 5회 실패로 인증코드가 만료되었습니다.\n새로운 인증코드를 발급받으세요."
  },
  "otp_code": "인증코드 입력",
  "otp_code_placeholder": "인증코드를 입력해주세요.",
  "authenticate": "인증하기",
  "authenticating": "인증하는 중..",
  "remaining_time": "인증코드 유효 시간",
  "resend_message": "인증코드 이메일을 받지 못하셨나요?",
  "resend": "인증코드 이메일로 다시 받기",
  "resending": "인증코드 요청 중..",
  "resent": "{email}로 2차 인증코드를 다시 발송했습니다.",
  "alert": {
    "incorrect_code": "인증코드가 일치하지 않습니다. 확인 후 다시 입력해주세요.",
    "expired": "인증코드 유효 시간이 만료되었습니다. 인증코드를 다시 받은 뒤 시도해주세요.",
    "attempts_exceeded": "인증 5회 실패로 인증코드가 만료되었습니다. 인증코드를 다시 받은 뒤 시도해주세요."
  }
}
</i18n>
