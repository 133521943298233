<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppProgressSteps
        class="progress-steps-margin"
        :steps="['Step 1', 'Step 2', 'Step 3', 'Step 4']"
        :current-step="0"
      />
      <AppProgressSteps
        class="progress-steps-margin"
        :steps="['Step 1', 'Step 2', 'Step 3', 'Step 4']"
        :current-step="1"
      />
      <AppProgressSteps
        class="progress-steps-margin"
        :steps="['Step 1', 'Step 2', 'Step 3', 'Step 4']"
        :current-step="2"
      />
      <AppProgressSteps
        class="progress-steps-margin"
        :steps="['Step 1', 'Step 2', 'Step 3', 'Step 4']"
        :current-step="3"
      />
    </div>
  </div>
</template>

<script>
export default { name: 'UiProgressSteps' };
</script>

<style>
.progress-steps-margin {
  margin-bottom: 10px;
}
</style>
