import { constructEnum } from '@/lib/enum';

const TargetCampaignMediaType = constructEnum('TargetCampaignMediaType', {
  DIRECT_MESSAGE: 0,
  POPUP: 10,
  WIDGET: 20
});

TargetCampaignMediaType.effectPeriodByDays = function(campaign_media_type) {
  switch (campaign_media_type) {
    case TargetCampaignMediaType.DIRECT_MESSAGE:
    case TargetCampaignMediaType.POPUP:
      return 14;
    case TargetCampaignMediaType.WIDGET:
      return 1;
    default:
      return null;
  }
};

export default TargetCampaignMediaType;
