<template>
  <AppResourceTable
    table-id="campaigns-table"
    :columns="finalColumns"
    :event-bus="eventBus"
    :rows="rows"
  >
    <template #head-cell="cellProps">
      <slot name="head-cell" v-bind="cellProps" />
    </template>
    <template #cell="{ row, column }">
      <TargetCampaignsTableCampaignInfoCell
        v-if="column === 'campaignInfo'"
        :campaign="row"
      />
      <template v-if="column === 'effects'">
        <TargetCampaignsTableEffectsCell
          v-if="isEffectAvailable(row)"
          :campaign="row"
        />
        <TargetCampaignsTableEffectUnavailableCell v-else :campaign="row" />
      </template>
      <div v-else-if="column === 'campaignId'">{{ row.id }}</div>
      <TargetCampaignsTableEditedAtCell
        v-else-if="column === 'editedAt'"
        :campaign="row"
      />
      <TargetCampaignsTableCalculateStatusCell
        v-else-if="column === 'calculateStatus'"
        :campaign="row"
      />
      <template v-else-if="column === 'actions'">
        <TargetCampaignManageButton
          :campaign="row"
          @delete="DELETE_CAMPAIGN(row.id)"
          @change-status="changeStatus(row, $event)"
          @change-calculate-status="changeCalculateStatus(row, $event)"
        />
        <AppButton
          v-if="showStats"
          :label="$t('button.stats')"
          :disabled="row.dispatched == false"
          :tooltip="
            row.dispatched == false ? $t('button.not_dispatched_tooltip') : null
          "
          @click="clickShowStats(row)"
        />
        <AppButton
          type="link"
          button-style="mango-outline"
          :label="$t('button.settings')"
          :to="campaignPath(row)"
        />
      </template>
      <slot v-else name="cell" v-bind="{ row, column }" />
    </template>
  </AppResourceTable>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import TargetCampaignGroupType from '@/enums/TargetCampaignGroupType';
import TargetCampaignsTableEffectsCell from './TargetCampaignsTableEffectsCell';
import TargetCampaignsTableEditedAtCell from './TargetCampaignsTableEditedAtCell';
import TargetCampaignsTableCalculateStatusCell from './TargetCampaignsTableCalculateStatusCell';
import TargetCampaignsTableCampaignInfoCell from './TargetCampaignsTableCampaignInfoCell';
import TargetCampaignManageButton from './TargetCampaignManageButton';

export default {
  name: 'TargetCampaignsTable',
  components: {
    TargetCampaignsTableEffectsCell,
    TargetCampaignsTableEditedAtCell,
    TargetCampaignsTableCalculateStatusCell,
    TargetCampaignsTableCampaignInfoCell,
    TargetCampaignManageButton
  },
  props: {
    eventBus: { type: Object, default: null },
    rows: { type: Array, required: true },
    columns: { type: Array, required: true },
    showStats: { type: Boolean, default: true }
  },
  data() {
    return {
      COLUMN_PROPS: {
        campaignInfo: {
          label: this.$t('target_campaign.campaign_info'),
          align: 'left'
        },
        campaignId: { label: this.$t('target_campaign.campaign_id') },
        editedAt: {
          label: this.$t('target_campaign.edited_at'),
          superAdmin: true
        },
        calculateStatus: {
          label: this.$t('target_campaign.calculate_status'),
          superAdmin: true
        },
        actions: { label: this.$t('target_campaign.actions'), align: 'right' }
      }
    };
  },
  computed: {
    ...mapGetters('targetCampaigns', ['campaignPath', 'isWidgetCampaign']),
    finalColumns() {
      return this.columns.map(column => ({
        ...column,
        ...this.COLUMN_PROPS[column.id]
      }));
    }
  },
  methods: {
    ...mapMutations('targetCampaigns', [
      'DELETE_CAMPAIGN',
      'UPDATE_CAMPAIGN_CALCULATE_STATUS'
    ]),
    ...mapActions('dialog', ['openDialog']),
    clickShowStats(campaign) {
      this.openDialog([
        campaign.campaign_group_type === TargetCampaignGroupType.MASS
          ? 'TargetCampaignMassStatsDialog'
          : 'TargetCampaignStatsDialog',
        { campaignId: campaign.id }
      ]);
    },
    changeStatus(campaign, status) {
      if (campaign.status !== status) this.DELETE_CAMPAIGN(campaign.id);
    },
    changeCalculateStatus(campaign, args) {
      this.UPDATE_CAMPAIGN_CALCULATE_STATUS({
        id: campaign.id,
        calculate_status: args.calculate_status,
        calculated_at: args.calculated_at
      });
    },
    isEffectAvailable(campaign) {
      if (this.isWidgetCampaign(campaign) && !campaign.inserted_url)
        return false;

      return campaign.configured;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  // FIX - 효과 툴팁 가려지는 문제
  .AppResourceTable__body-scroller {
    padding-bottom: 28px;
  }

  .AppResourceTable__body-row {
    $body-row-height: 94px;
    $body-row-border: 1px;
    height: $body-row-height + $body-row-border;
  }

  .campaigns-table__campaign-info {
    flex: 6 0 220px;
  }

  .campaigns-table__effects {
    flex: 5 0 364px;
    text-align: left;
  }

  .campaigns-table__campaign-id {
    flex: 5 0 76px;
  }

  .campaigns-table__edited-at {
    flex: 5 0 72px;
  }

  .campaigns-table__calculate-status {
    flex: 5 0 76px;
  }

  .campaigns-table__actions {
    flex: 1 0 212px;
  }
}
</style>

<i18n locale="ko">
{
  "button": {
    "stats": "통계",
    "settings": "설정",
    "not_dispatched_tooltip": "실제 발송하지 않고 종료된 캠페인입니다."
  }
}
</i18n>
