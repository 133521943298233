<template>
  <AppButton
    v-if="!(hideWhenDisabled && isDisabled)"
    button-style="grey"
    :tooltip="tooltip"
    :disabled="buttonDisabled"
    :label="label"
    @click="syncResource"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import BrandSyncStatus from '@/enums/BrandSyncStatus';
import BrandSyncType from '@/enums/BrandSyncType';
import ButtonSyncable from '@/mixins/ButtonSyncable';

export default {
  name: 'AppButtonSyncProduct',
  mixins: [ButtonSyncable],
  props: {
    calculateProductData: { type: Boolean, required: true },
    hideWhenDisabled: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('session', ['hostingName']),
    brandSyncType() {
      return this.calculateProductData
        ? BrandSyncType.PRODUCT_DATA
        : BrandSyncType.PRODUCT;
    },
    isDisabled() {
      return this.syncInfo?.sync_status === BrandSyncStatus.DISABLED;
    },
    tooltipIdleInfoText() {
      return this.$t('idle_info', [this.hostingName]);
    },
    syncConfirmMessage() {
      const startDate = this.formatDate(moment().subtract(1, 'days'));
      const endDate = this.formatDate(moment());
      return this.$t('sync_button.confirm.content_html_with_dates', [
        startDate,
        endDate,
        this.$t('name')
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "name": "상품 정보",
  "idle_info": "버튼 클릭 시 {0} 기준 어제부터 오늘까지 2일간 등록/수정된 상품 정보 동기화 됩니다."
}
</i18n>
