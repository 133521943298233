<template>
  <div>
    <div class="ui-section">
      <div v-for="(info, label) in SIZE_INFO" :key="label" class="ui-section">
        <div class="ui-section__title">
          <label>{{ label }}</label>
        </div>
        <AppButton @click="open(info)">미리보기</AppButton>
        <div class="UiModal__description">
          {{ info.description ? `width: ${info.description}` : null }}
        </div>
      </div>
    </div>
    <div v-if="Object.keys(SIZE_INFO_ETC).length" class="ui-section">
      <div class="ui-section__title" style="margin">
        <label>규칙 외 사용된 Modal 크기</label>
      </div>
      <div
        v-for="(info, label) in SIZE_INFO_ETC"
        :key="label"
        class="ui-section"
      >
        <div class="ui-section__title">
          <label>{{ label }}</label>
        </div>
        <AppButton @click="open(info)">미리보기</AppButton>
        <div class="UiModal__description">width: {{ info.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import DialogSize from '@/enums/DialogSize';

const SIZE_INFO = {
  Default: {
    description: '680px',
    width: DialogSize.DEFAULT,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris iaculis aliquet risus vel dignissim. Sed a nisl nec ipsum suscipit elementum quis sed nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce in urna fermentum, porta ante quis, iaculis eros. Aenean sed nulla sollicitudin, vestibulum felis at, faucibus dolor. Donec orci quam, scelerisque nec mauris nec, commodo tincidunt eros. Ut ultricies, nibh consequat dictum efficitur, odio purus tempor dui, quis eleifend quam nulla in libero. Aenean dolor sem, mattis sed viverra a, efficitur non nibh. Curabitur fringilla mi vel arcu bibendum gravida. Etiam ornare eu felis et pharetra. Curabitur et pretium ex, vel ultrices massa. Aliquam fermentum sem mauris, non scelerisque massa posuere sit amet. Sed convallis nunc justo, sit amet commodo lectus sodales in. Vivamus finibus viverra lacinia. Vestibulum cursus sit amet quam a euismod.'
  },
  Small: {
    description: '440px',
    width: DialogSize.SMALL,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris iaculis aliquet risus vel dignissim. Sed a nisl nec ipsum suscipit elementum quis sed nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce in urna fermentum, porta ante quis, iaculis eros. Aenean sed nulla sollicitudin, vestibulum felis at, faucibus dolor. Donec orci quam, scelerisque nec mauris nec, commodo tincidunt eros. Ut ultricies, nibh consequat dictum efficitur, odio purus tempor dui, quis eleifend quam nulla in libero. Aenean dolor sem, mattis sed viverra a, efficitur non nibh. Curabitur fringilla mi vel arcu bibendum gravida. Etiam ornare eu felis et pharetra. Curabitur et pretium ex, vel ultrices massa. Aliquam fermentum sem mauris, non scelerisque massa posuere sit amet. Sed convallis nunc justo, sit amet commodo lectus sodales in. Vivamus finibus viverra lacinia. Vestibulum cursus sit amet quam a euismod.'
  },
  Middle: {
    description: '760px',
    width: DialogSize.MIDDLE,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris iaculis aliquet risus vel dignissim. Sed a nisl nec ipsum suscipit elementum quis sed nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce in urna fermentum, porta ante quis, iaculis eros. Aenean sed nulla sollicitudin, vestibulum felis at, faucibus dolor. Donec orci quam, scelerisque nec mauris nec, commodo tincidunt eros. Ut ultricies, nibh consequat dictum efficitur, odio purus tempor dui, quis eleifend quam nulla in libero. Aenean dolor sem, mattis sed viverra a, efficitur non nibh. Curabitur fringilla mi vel arcu bibendum gravida. Etiam ornare eu felis et pharetra. Curabitur et pretium ex, vel ultrices massa. Aliquam fermentum sem mauris, non scelerisque massa posuere sit amet. Sed convallis nunc justo, sit amet commodo lectus sodales in. Vivamus finibus viverra lacinia. Vestibulum cursus sit amet quam a euismod.'
  },
  Wide: {
    description: '1000px',
    width: DialogSize.WIDE,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris iaculis aliquet risus vel dignissim. Sed a nisl nec ipsum suscipit elementum quis sed nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce in urna fermentum, porta ante quis, iaculis eros. Aenean sed nulla sollicitudin, vestibulum felis at, faucibus dolor. Donec orci quam, scelerisque nec mauris nec, commodo tincidunt eros. Ut ultricies, nibh consequat dictum efficitur, odio purus tempor dui, quis eleifend quam nulla in libero. Aenean dolor sem, mattis sed viverra a, efficitur non nibh. Curabitur fringilla mi vel arcu bibendum gravida. Etiam ornare eu felis et pharetra. Curabitur et pretium ex, vel ultrices massa. Aliquam fermentum sem mauris, non scelerisque massa posuere sit amet. Sed convallis nunc justo, sit amet commodo lectus sodales in. Vivamus finibus viverra lacinia. Vestibulum cursus sit amet quam a euismod.'
  },
  Auto: {
    description: '내부 콘텐츠의 크기에 맞춤',
    width: DialogSize.AUTO,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris iaculis aliquet risus vel dignissim. Sed a nisl nec ipsum suscipit elementum quis sed nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce in urna fermentum, porta ante quis, iaculis eros. Aenean sed nulla sollicitudin, vestibulum felis at, faucibus dolor. Donec orci quam, scelerisque nec mauris nec, commodo tincidunt eros. Ut ultricies, nibh consequat dictum efficitur, odio purus tempor dui, quis eleifend quam nulla in libero. Aenean dolor sem, mattis sed viverra a, efficitur non nibh. Curabitur fringilla mi vel arcu bibendum gravida. Etiam ornare eu felis et pharetra. Curabitur et pretium ex, vel ultrices massa. Aliquam fermentum sem mauris, non scelerisque massa posuere sit amet. Sed convallis nunc justo, sit amet commodo lectus sodales in. Vivamus finibus viverra lacinia. Vestibulum cursus sit amet quam a euismod.'
  },
  Maximize: {
    width: '440px',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris iaculis aliquet risus vel dignissim. Sed a nisl nec ipsum suscipit elementum quis sed nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce in urna fermentum, porta ante quis, iaculis eros. Aenean sed nulla sollicitudin, vestibulum felis at, faucibus dolor. Donec orci quam, scelerisque nec mauris nec, commodo tincidunt eros. Ut ultricies, nibh consequat dictum efficitur, odio purus tempor dui, quis eleifend quam nulla in libero. Aenean dolor sem, mattis sed viverra a, efficitur non nibh. Curabitur fringilla mi vel arcu bibendum gravida. Etiam ornare eu felis et pharetra. Curabitur et pretium ex, vel ultrices massa. Aliquam fermentum sem mauris, non scelerisque massa posuere sit amet. Sed convallis nunc justo, sit amet commodo lectus sodales in. Vivamus finibus viverra lacinia. Vestibulum cursus sit amet quam a euismod.',
    canMaximize: true
  }
};

const SIZE_INFO_ETC = {};

import { mapActions } from 'vuex';

export default {
  name: 'UiModal',
  data: () => ({
    SIZE_INFO,
    SIZE_INFO_ETC
  }),
  methods: {
    ...mapActions('dialog', ['openDialog']),
    open(info) {
      this.openDialog(['UiModalDefaultDialog', info]);
    }
  }
};
</script>
