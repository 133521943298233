<template>
  <Transition name="drawer-fade">
    <div v-if="show" ref="drawer" class="AppDrawer" @keyup.esc="close">
      <div class="AppDrawer__backdrop" @click="close" />
      <div class="AppDrawer__main-wrapper">
        <div ref="main" class="AppDrawer__main">
          <div class="AppDrawer__head">
            <div class="AppDrawer__head-wrapper">
              <div class="AppDrawer__head-title-wrapper">
                <span class="AppDrawer__head-title">{{ title }}</span>
                <span v-if="subTitle" class="AppDrawer__head-sub-title">{{
                  subTitle
                }}</span>
              </div>
              <div class="AppDrawer__head-button-wrapper">
                <AppModalHeadCloseButton @close="close" />
              </div>
            </div>
          </div>
          <div class="AppDrawer__body"><slot name="body" /></div>
          <div class="AppDrawer__foot"><slot name="foot" /></div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import AppModalHeadCloseButton from './AppModal/AppModalHeadCloseButton';

export default {
  name: 'AppDrawer',
  components: { AppModalHeadCloseButton },
  props: {
    title: { type: String, required: true },
    subTitle: { type: String, default: null }
  },
  data: () => ({ show: false }),
  mounted() {
    this.show = true;
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/vars/_z-indexes.scss';
@import '@/scss/mixins/_transitions.scss';
@import '@/scss/mixins/_texts.scss';

.AppDrawer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-drawer;
}

.AppDrawer__main-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: $color-background;
  text-align: left;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(15, 15, 15, 0.06),
    0 5px 10px 0 rgba(15, 15, 15, 0.12);
  border: solid 1px rgba(15, 15, 15, 0.04);
}

.AppDrawer__backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.AppDrawer__main {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 9px 32px;
}

.AppDrawer__head-wrapper {
  display: flex;
}

.AppDrawer__head-title-wrapper {
  padding-top: 7px;
  padding-bottom: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  flex: 1;
}

.AppDrawer__head-title {
  @include text-modal-title;
}

.AppDrawer__head-button-wrapper {
  margin-left: auto;
}

.AppDrawer__head-sub-title {
  margin-left: 8px;
  @include text-modal-title-light;
}

.AppDrawer__body {
  overflow-y: auto;
}

.drawer-fade-enter-active,
.drawer-fade-leave-active {
  @include transition-slow(transform);
}

.drawer-fade-enter,
.drawer-fade-leave-to {
  transform: translateX(100%);
}

.drawer-fade-enter-to,
.drawer-fade-leave {
  transform: translateX(0);
}
</style>
