var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AppNewPasswordRequirements"},[_c('ul',{staticClass:"AppNewPasswordRequirements__main-items"},_vm._l((_vm.passwordRequirements),function(item,i){return _c('li',{key:i},[_c('div',{class:[
          'AppNewPasswordRequirements__main-item',
          {
            'AppNewPasswordRequirements__main-item--satisfied': item.value
          }
        ]},[_c('AppSvgIcon',{staticClass:"AppNewPasswordRequirements__main-item-icon",attrs:{"name":item.value
              ? 'icon-check-tiny-checked'
              : 'icon-check-tiny-unchecked'}}),_c('span',{staticClass:"AppNewPasswordRequirements__main-item-text"},[_vm._v(_vm._s(item.label))])],1),(item.subItems)?_c('ul',{staticClass:"AppNewPasswordRequirements__sub-items"},_vm._l((item.subItems),function(subItem,j){return _c('li',{key:j,class:[
            'AppNewPasswordRequirements__sub-item',
            {
              'AppNewPasswordRequirements__sub-item--satisfied': subItem.value
            }
          ]},[_c('span',{staticClass:"AppNewPasswordRequirements__sub-item-text"},[_vm._v(_vm._s(subItem.label))])])}),0):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }