var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('recipients', [_vm.dispatchText]),"sub-title":_vm.campaign.name,"is-loading":_vm.isLoading,"form-props":Object.assign({}, _vm.formProps,
    {objectId: 'campaign',
    disabled: _vm.isArchivedCampaign})},on:{"blur-group":_vm.formGroupBlurred},scopedSlots:_vm._u([{key:"group",fn:function(ref){
  var id = ref.id;
  var disabled = ref.disabled;
  var fields = ref.fields;
  var errors = ref.errors;
return [(id === 'recipients')?[_c('div',{staticClass:"AppForm__group-field"},[_c('i18n',{attrs:{"path":_vm.descriptionPath,"tag":"pre"}},_vm._l((fields.filter(function (f) { return !f.isValidator; })),function(f){return _c(f.component || 'AppNumberInput',{key:f.id,tag:"Component",class:f.class,attrs:{"name":_vm.filterItemIdToName(f.id),"value":f.value,"digits":f.digits || 2,"inline":"","disabled":disabled || f.disabled,"invalid":!!(errors[f.id] || errors[f.validatorId])},on:{"blur":function($event){return _vm.validateField(f.id, f.validatorId)},"change":function($event){return _vm.setRecipientsValue(f.id, $event, f.validatorId)}}})}),1),_c('AppFormError',{attrs:{"error":fields.map(function (f) { return errors[f.id]; }).filter(function (e) { return e; })[0]}})],1)]:(id === 'lock_interval')?_vm._l((fields),function(ref){
  var id = ref.id;
return _c('div',{key:id,staticClass:"AppForm__group-field"},[_c('i18n',{attrs:{"path":id}},[_c('AppNumberInput',{attrs:{"name":("campaign[" + id + "]"),"digits":2,"inline":"","disabled":disabled,"invalid":!!errors[id]},on:{"blur":function($event){return _vm.validateField(id)},"change":function($event){return _vm.validateField(id)}},model:{value:(_vm.formObject[id]),callback:function ($$v) {_vm.$set(_vm.formObject, id, $$v)},expression:"formObject[id]"}})],1),_c('AppFormError',{attrs:{"error":errors[id]}})],1)}):(id === 'utm_popup_check_brand_user')?[_c('div',{staticClass:"AppForm__group-field"},[_c('div',{staticClass:"AppForm__sub-group"},[_c('AppSelectRadio',{attrs:{"name":"campaign[utm_popup_brand_user_only]","disabled":!_vm.formObject.utm_popup_check_brand_user,"options":[
              {
                label: _vm.$t('group.utm_popup_brand_user_only.true'),
                value: true
              },
              {
                label: _vm.$t('group.utm_popup_brand_user_only.false'),
                value: false
              }
            ]},model:{value:(_vm.formObject.utm_popup_brand_user_only),callback:function ($$v) {_vm.$set(_vm.formObject, "utm_popup_brand_user_only", $$v)},expression:"formObject.utm_popup_brand_user_only"}})],1)])]:_vm._e()]}},{key:"group-label",fn:function(ref){
            var group = ref.group;
            var disabled = ref.disabled;
            var value = ref.value;
            var checked = ref.checked;
            var errors = ref.errors;
return [(group.id === 'product_click_filter.date_range_type')?[(value === _vm.FilterDateRangeType.RECENT_DAYS)?[_c('i18n',{attrs:{"path":"FilterDateRangeType.RECENT_DAYS"}},_vm._l((['recent_days_since', 'recent_days_until']),function(id){return _c('AppNumberInput',{key:id,attrs:{"name":("campaign[product_click_filter][" + id + "]"),"digits":2,"inline":"","disabled":disabled || !checked,"invalid":!!errors[("product_click_filter." + id)] ||
                !!errors['product_click_filter.recent_days']},on:{"blur":function($event){return _vm.validateField(
                ("product_click_filter." + id),
                'product_click_filter.recent_days'
              )},"change":function($event){return _vm.validateField(
                ("product_click_filter." + id),
                'product_click_filter.recent_days'
              )}},model:{value:(_vm.formObject.product_click_filter[id]),callback:function ($$v) {_vm.$set(_vm.formObject.product_click_filter, id, $$v)},expression:"formObject.product_click_filter[id]"}})}),1),_c('AppFormError',{attrs:{"error":errors['product_click_filter.recent_days_since'] ||
              errors['product_click_filter.recent_days_until'] ||
              errors['product_click_filter.recent_days']}})]:_vm._e()]:_vm._e()]}},{key:"group-sub-item",fn:function(ref){
              var group = ref.group;
              var disabled = ref.disabled;
              var value = ref.value;
              var checked = ref.checked;
              var errors = ref.errors;
return [(group.id === 'product_click_filter.date_range_type')?[(value === _vm.FilterDateRangeType.DATE_RANGE)?[_c('AppDateRangePicker',{attrs:{"required":"","invalid":!!errors['product_click_filter.date_range'],"disabled":disabled || !checked},on:{"blur":function($event){return _vm.validateField('product_click_filter.date_range')},"change":function($event){return _vm.validateField('product_click_filter.date_range')}},model:{value:(_vm.formObject.product_click_filter),callback:function ($$v) {_vm.$set(_vm.formObject, "product_click_filter", $$v)},expression:"formObject.product_click_filter"}}),_c('input',{attrs:{"type":"hidden","name":"campaign[product_click_filter][start_date]"},domProps:{"value":_vm.formObject.product_click_filter.start_date}}),_c('input',{attrs:{"type":"hidden","name":"campaign[product_click_filter][end_date]"},domProps:{"value":_vm.formObject.product_click_filter.end_date}}),_c('AppFormError',{attrs:{"error":errors['product_click_filter.date_range']}})]:_vm._e()]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }