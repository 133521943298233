<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="subTitle"
    :form-props="{
      ...formProps,
      disabled: !isValid,
      submitLabel: $t('app.confirmed')
    }"
    v-on="formEvents"
  >
    <AppDataSection :title="$t('info.title')">
      {{ productsName }}
    </AppDataSection>
    <template #group="{ id, value, error }">
      <template v-if="id === 'dst_position'">
        <i18n path="form.dst_position.description">
          <AppNumberInput
            v-model="formObject.dst_position"
            class="AppForm__group-field DisplayCornerSortProductDialog__input"
            :digits="2"
            :invalid="!!error"
            @change="validateField(id)"
            @blur="validateField(id)"
          />
        </i18n>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapActions } from 'vuex';
import DisplayCornerHandleProductView from '@/mixins/DisplayCornerHandleProductView';

export default {
  name: 'DisplayCornerSortProductDialog',
  mixins: [DisplayCornerHandleProductView],
  computed: {
    formSections() {
      return this.isValid
        ? [
            {
              groups: [
                {
                  id: 'dst_position',
                  label: this.$t('form.dst_position.label'),
                  validate: ['required', 'positive_integer']
                }
              ]
            }
          ]
        : [];
    }
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapActions('displayProductDisplay', ['sortProducts']),
    submit() {
      const srcIndices = this.selectedProducts.map(product => product.index);
      const dstIndex = this.formObject.dst_position - 1;
      this.sortProducts({ srcIndices, dstIndex });

      this.orgFormObject = this.formObject;
      this.createToast(this.$t('sorted', [srcIndices.length]));
      this.eventBus.$emit('sort', dstIndex);
      this.close();
    }
  }
};
</script>

<style scoped>
.DisplayCornerSortProductDialog__input {
  margin-right: 8px;
}
</style>

<i18n locale="ko">
{
  "title": "상품 위치 이동",
  "info": {
    "title": "위치 이동 상품"
  },
  "form": {
    "dst_position": {
      "label": "이동 위치",
      "description": "{0}로 이동"
    }
  },
  "sorted": "{0}개의 상품의 위치를 이동했습니다."
}
</i18n>
