import { isDomainOnlyUrlFormatValid } from '@/lib/urlFormatValidator';

const urlToDomain = inputUrl => {
  let domain = inputUrl;
  if (isDomainOnlyUrlFormatValid(domain)) {
    const url = new URL(domain);
    domain = url.origin;
  }
  return domain;
};

export default url => {
  return urlToDomain(url);
};
