import { constructEnum } from '@/lib/enum';
import TargetCampaignType from './TargetCampaignType';

const TargetWidgetStyle = constructEnum('TargetWidgetStyle', {
  INFORMATION: 10,
  IMAGE: 20,
  HORIZONTAL_SCROLL: 30,
  GALLERY: 40,
  LIST_IMAGE_LAST: 50,
  LIST_TEXT_LAST: 60,
  BANNER: 70
});

TargetWidgetStyle.defaultStyles = [
  TargetWidgetStyle.HORIZONTAL_SCROLL,
  TargetWidgetStyle.GALLERY
];

TargetWidgetStyle.listStyles = [
  TargetWidgetStyle.LIST_IMAGE_LAST,
  TargetWidgetStyle.LIST_TEXT_LAST
];

TargetWidgetStyle.availableOptions = (campaign_type, is_mobile) => {
  if (
    campaign_type === TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT
  ) {
    const styles = is_mobile
      ? TargetWidgetStyle.listStyles
      : TargetWidgetStyle.defaultStyles;

    return TargetWidgetStyle.options([...styles, TargetWidgetStyle.BANNER]);
  } else return TargetWidgetStyle.options(TargetWidgetStyle.defaultStyles);
};
export default TargetWidgetStyle;
