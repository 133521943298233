var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalDefault',{attrs:{"title":_vm.$t('title'),"width":"wide"},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"ReviewInstallSimulationConfirmDialog__test-link"},[_c('div',{staticClass:"ReviewInstallSimulationConfirmDialog__test-link-title"},[_vm._v(" "+_vm._s(_vm.$t('test_link.title'))+" ")]),_c('div',{staticClass:"ReviewInstallSimulationConfirmDialog__test-link-description"},[_vm._v(" "+_vm._s(_vm.$t('test_link.description'))+" ")]),_c('AppSingleLineFields',[_c('AppTextInput',{attrs:{"placeholder":"http://"},model:{value:(_vm.urlInput),callback:function ($$v) {_vm.urlInput=$$v},expression:"urlInput"}}),_c('AppButton',{on:{"click":_vm.openTestLinkWindow}},[_vm._v(" "+_vm._s(_vm.$t('test_link.button_label'))+" "),_c('AppExternalLinkIcon')],1)],1)],1),_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppResourceTable',{attrs:{"table-id":"simulation-confirms-table","columns":_vm.COLUMNS,"rows":_vm.rows,"batch-buttons":_vm.batchButtons},on:{"select-rows":_vm.selectRows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var rowIndex = ref.rowIndex;
return [(column === 'id')?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.idTooltip(rowIndex)),expression:"idTooltip(rowIndex)"}]},[_vm._v(" "+_vm._s(_vm.simulations[rowIndex].id)+" ")])]:(column === 'detail')?[_vm._l((_vm.rows[rowIndex].detail.summary),function(value,key){return _c('span',{key:("summary_" + key),staticClass:"ReviewInstallSimulationConfirmDialog__detail-summary"},[_c('span',{class:[
                  'ReviewInstallSimulationConfirmDialog__detail-value',
                  ("ReviewInstallSimulationConfirmDialog__detail-value--" + key)
                ]},[_vm._v(_vm._s(value)+" ")])])}),_c('br'),_vm._l((_vm.rows[rowIndex].detail.diff),function(value,key){return _c('span',{key:("diff_" + key),staticClass:"ReviewInstallSimulationConfirmDialog__detail-diff"},[_c('span',{staticClass:"ReviewInstallSimulationConfirmDialog__detail-key"},[_vm._v(_vm._s(key)+": ")]),_c('span',{class:[
                  'ReviewInstallSimulationConfirmDialog__detail-value',
                  ("ReviewInstallSimulationConfirmDialog__detail-value--" + key)
                ]},[_vm._v(" "+_vm._s(value.original || 'x')+" ")]),_vm._v(" → "),_c('span',{class:[
                  'ReviewInstallSimulationConfirmDialog__detail-value',
                  ("ReviewInstallSimulationConfirmDialog__detail-value--" + key)
                ]},[_vm._v(" "+_vm._s(value.simulation || 'x')+" ")]),_c('br')])})]:(column === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('table.actions.apply')},on:{"click":function($event){return _vm.clickApplySimulation(rowIndex)}}}),_c('AppButton',{attrs:{"label":_vm.$t('table.actions.revert'),"button-style":"red-outline"},on:{"click":function($event){return _vm.clickRevertSimulation(rowIndex)}}})]:_vm._e()]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }