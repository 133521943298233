<template>
  <div class="ReviewImportSmartstoreProductCsv">
    <SmartstoreProductMatchOperating />
    <BatchJobTable
      v-bind="{ batchJobType }"
      :title="$t('table_title')"
      class="ReviewImportSmartstoreProductCsv__job_table"
      @show="openBatchJobSmartstoreProductMatchDialog"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BatchJobType from '@/enums/BatchJobType';
import SmartstoreProductMatchOperating from './components/SmartstoreProductMatchOperating';
import BatchJobTable from '@/views/components/BatchJobTable';

export default {
  name: 'ReviewImportSmartstoreProductCsv',
  components: { SmartstoreProductMatchOperating, BatchJobTable },
  computed: {
    batchJobType() {
      return BatchJobType.SMARTSTORE_PRODUCT_MATCH;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openBatchJobSmartstoreProductMatchDialog(batchJobId) {
      this.openDialog([
        'ProductBatchJobSmartstoreProductMatchDialog',
        { batchJobId }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewImportSmartstoreProductCsv__job_table {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
  {
    "table_title": "전체 상품 매칭표 등록 내역"
  }
</i18n>
