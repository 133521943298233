var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('div',[_c('AppButton',{attrs:{"button-style":"red","label":_vm.$t('button.create_ab_test')},on:{"click":_vm.clickCreateAbTestDialog}})],1),_c('AppResourceTable',{attrs:{"table-id":"ab-tests-table","columns":_vm.COLUMNS,"rows":_vm.abTests},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [(column === 'ab_test_type')?_c('div',[_vm._v(" "+_vm._s(_vm.abTestTypeString(row.ab_test_type))+" ")]):(column === 'duration')?_c('div',[_vm._v(" "+_vm._s(_vm.formatDateTime(row.start_time))+" - "+_vm._s(row.end_time ? _vm.formatDateTime(row.end_time) : _vm.$t('ongoing'))+" ")]):_vm._e(),(column === 'status')?_c('div',[_vm._v(" "+_vm._s(_vm.abTestStatusString(row.status))+" ")]):(column === 'actions')?_c('div',[_c('AppButton',{attrs:{"button-style":"mango-outline","label":_vm.$t('app.edit'),"disabled":_vm.abTestComplete(row.status)},on:{"click":function($event){return _vm.clickEditAbTestDialog(row.id)}}}),_c('AppDropdownMenuButton',{attrs:{"button-style":"red-outline","label":_vm.$t('button.manage'),"menu-items":[
            [
              {
                label: _vm.$t('app.delete'),
                clickHandler: function (ref) {
                          var close = ref.close;

                          return _vm.deleteAbTest(row.id, close);
}
              },
              {
                label: _vm.$t('button.terminate'),
                disabled: !_vm.abTestTesting(row.status),
                clickHandler: function (ref) {
                          var close = ref.close;

                          return _vm.terminateAbTest(row.id, close);
}
              }
            ]
          ]}}),_c('AppButton',{attrs:{"label":_vm.$t('button.stats'),"disabled":_vm.abTestPending(row.status)},on:{"click":function($event){return _vm.openStatsDialog(row)}}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }