<template>
  <TheLoginView
    :title="$t('title')"
    :sub-title="$t('title')"
    :message="$t(`${messageKey}.message`)"
    :sub-message="$t(`${messageKey}.sub_message`)"
  >
    <AppButton :label="$t('button')" size="large" type="link" to="/" />
  </TheLoginView>
</template>

<script>
import TheLoginView from './TheLoginView';

export default {
  name: 'TheEmailVerificationResult',
  components: { TheLoginView },
  computed: {
    messageKey() {
      switch (this.$route.path) {
        case '/aws-email-verification-success':
          return 'aws_success';
        case '/email-verification-success':
          return 'crema_success';
        default:
          return 'failure';
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "이메일 인증",
  "aws_success": {
    "message": "이메일 인증에 성공하였습니다.",
    "sub_message": "관리자 페이지에 접속하여\n인증된 이메일 주소로 변경되었는지 확인해보세요.\n변경 완료까지 최대 2분이 소요됩니다."
  },
  "crema_success": {
    "message": "이메일 인증에 성공하였습니다.",
    "sub_message": "관리자 페이지에 접속하여\n인증된 이메일 주소로 변경되었는지 확인해보세요."
  },
  "failure": {
    "message": "이메일 인증에 실패하였습니다.",
    "sub_message": "관리자 페이지에 접속하여 이메일 인증을 재시도해주세요."
  },
  "button": "크리마 관리자 페이지로 이동"
}
</i18n>
