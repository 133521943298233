var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.$t('sub_title', { count: _vm.reviews.length }),"form-props":Object.assign({}, _vm.formProps,
    {submitLabel: _vm.$t('submit_label')})},scopedSlots:_vm._u([{key:"group",fn:function(ref){
  var id = ref.id;
  var error = ref.error;
return [(id === 'use_comment_preset')?_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectRadio',{attrs:{"options":[
          { label: _vm.$t('use_comment_preset_true'), value: true },
          { label: _vm.$t('use_comment_preset_false'), value: false }
        ]},on:{"change":function($event){return _vm.validateField(id)}},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
        var value = ref.value;
        var checked = ref.checked;
return [(value)?_c('AppButton',{attrs:{"label":_vm.$t('comment_preset_button')},on:{"click":function($event){return _vm.openDialog('ReviewSettingsCommentPresetDialog')}}}):_c('AppTextarea',{attrs:{"placeholder":_vm.$t('comment_message_placeholder'),"rows":4,"autosize":false,"invalid":!!error,"disabled":!checked},on:{"change":function($event){return _vm.validateField(id)}},model:{value:(_vm.batchComment.comment_message),callback:function ($$v) {_vm.$set(_vm.batchComment, "comment_message", $$v)},expression:"batchComment.comment_message"}})]}}],null,true),model:{value:(_vm.batchComment.use_comment_preset),callback:function ($$v) {_vm.$set(_vm.batchComment, "use_comment_preset", $$v)},expression:"batchComment.use_comment_preset"}})],1):_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }