<template>
  <div class="ReviewReportsReviewRate">
    <AppReportsSearchbar v-bind="searchBarProps" v-on="searchBarEvents" />
    <ReviewReportsWarning />
    <AppChart
      :options="chartOptions"
      :tooltip="chartTooltip"
      @tooltip="onChartTooltip"
    />
    <AppResourceTable
      :resources="resources"
      :rows="rows"
      :main-columns="MAIN_COLUMNS"
      :columns="COLUMNS"
      :enable-total-count="false"
      table-style="stats"
      @paginate="paginate"
    />
  </div>
</template>

<script>
import _ from 'lodash';

import ReportsView from '@/mixins/ReportsView';
import ReviewReportsWarning from '@/views/review/reports/ReviewReportsWarning';

export default {
  name: 'ReviewReportsReviewRate',
  components: { ReviewReportsWarning },
  mixins: [ReportsView],
  data() {
    return {
      MAIN_COLUMNS: [
        {},
        {
          label: this.$t('based_on_sub_order_reviews_count'),
          colspan: 3
        },
        {
          label: this.$t('based_on_sub_order_users_count'),
          colspan: 3
        }
      ],
      COLUMNS: [
        { id: 'date', label: this.$t('app.date'), type: 'pre' },
        {
          id: 'sub_order_reviews_count',
          label: this.$t('sub_order_reviews_count'),
          type: 'number',
          default: '-',
          tooltip: this.$t('sub_order_reviews_count_tooltip')
        },
        {
          id: 'sub_orders_count',
          label: this.$t('sub_orders_count'),
          type: 'number',
          default: '-'
        },
        {
          id: 'sub_order_reviews_count_per_sub_orders_count',
          label: this.$t('review_rate'),
          type: 'percentage'
        },
        {
          id: 'sub_order_users_count_with_review',
          label: this.$t('sub_order_users_count_with_review'),
          type: 'number',
          default: '-'
        },
        {
          id: 'users_count_with_sub_order',
          label: this.$t('users_count_with_sub_order'),
          type: 'number',
          default: '-'
        },
        {
          id:
            'sub_order_users_count_with_review_per_users_count_with_sub_order',
          label: this.$t('review_rate'),
          type: 'percentage'
        }
      ],
      chartTooltip: null
    };
  },
  computed: {
    rows() {
      const sub_order_reviews_count = _.sumBy(
        this.reportsChart,
        'sub_order_reviews_count'
      );
      const sub_orders_count = _.sumBy(this.reportsChart, 'sub_orders_count');

      const sub_order_users_count_with_review = _.sumBy(
        this.reportsChart,
        'sub_order_users_count_with_review'
      );
      const users_count_with_sub_order = _.sumBy(
        this.reportsChart,
        'users_count_with_sub_order'
      );

      const totalRow = {
        date: this.$t('total'),
        rowStyle: 'stats-total',
        sub_order_reviews_count,
        sub_orders_count,
        sub_order_reviews_count_per_sub_orders_count: sub_orders_count
          ? (sub_order_reviews_count / sub_orders_count) * 100
          : 0,
        sub_order_users_count_with_review,
        users_count_with_sub_order,
        sub_order_users_count_with_review_per_users_count_with_sub_order: users_count_with_sub_order
          ? (sub_order_users_count_with_review / users_count_with_sub_order) *
            100
          : 0
      };
      return [totalRow].concat(
        this.reportsTable.map(item => ({
          ...item,
          date: this.getFormattedDate(item ? item.date : null),
          rowStyle: 'stats-summary'
        }))
      );
    },
    chartOptions() {
      return {
        title: { text: this.$t('review_rate') },
        xAxis: { type: 'datetime' },
        yAxis: [
          {
            title: {
              text: 'Percent ( % )'
            },
            labels: {
              format: '{value} %'
            },
            opposite: true
          }
        ],
        tooltip: {
          enabled: false
        },
        plotOptions: {
          line: {
            dataLabels: {
              format: '{y} %'
            },
            enableMouseTracking: true
          }
        },
        series: [
          {
            name: this.$t('review_per_sub_order'),
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData(
              'sub_order_reviews_count_per_sub_orders_count',
              'float'
            ),
            yAxis: 0,
            type: 'line'
          }
        ]
      };
    }
  },
  methods: {
    onChartTooltip({ x, index, series }) {
      this.chartTooltip = {
        title: this.getFormattedDate(new Date(x)),
        items: series.chart.series.map(({ data, name }) => ({
          symbol: 'circle',
          name,
          value: data[index].y,
          unit: 'percentage',
          precision: 2
        }))
      };
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .AppResourceTableCell {
  flex: 1 0 120px;
}
</style>

<i18n locale="ko">
{
  "total": "총 합계",
  "review_rate": "리뷰 작성률",
  "review_per_sub_order": "리뷰작성수/배송완료상품수",
  "based_on_sub_order_reviews_count": "리뷰수 기준",
  "based_on_sub_order_users_count": "인원수 기준",
  "sub_order_reviews_count": "리뷰수",
  "sub_order_reviews_count_tooltip": "해당 날짜에 배송완료된 주문 중 작성된 리뷰수를 뜻합니다.",
  "sub_orders_count": "배송완료된 상품수",
  "sub_order_users_count_with_review": "리뷰인원수",
  "users_count_with_sub_order": "배송완료된 인원수"
}
</i18n>
<i18n locale="en">
{
  "total": "Total",
  "review_rate": "Review writing rate",
  "review_per_sub_order": "Reviews count/Delivered orders count",
  "based_on_sub_order_reviews_count": "Based on number of reviews",
  "based_on_sub_order_users_count": "Based on number of people",
  "sub_order_reviews_count": "Reviews count",
  "sub_order_reviews_count_tooltip": "It refers to the number of reviews written among orders that were delivered on that date.",
  "sub_orders_count": "Number of products delivered",
  "sub_order_users_count_with_review": "Number of reviewers",
  "users_count_with_sub_order": "Number of people delivered"
}
</i18n>
