<template>
  <div class="DisplayCornerSnapshot">
    <DisplayCornerItemsContainer
      :items="products"
      :item-width="160"
      :show-spare-items-separately="showSpareItemsSeparately"
      :show-exceeded-max-products-items-separately="false"
    >
      <template #item="{ item }">
        <DisplayCornerProduct :product="item" />
      </template>
    </DisplayCornerItemsContainer>
  </div>
</template>

<script>
import DisplayCornerProduct from './DisplayCornerProduct';

export default {
  name: 'DisplayCornerSnapshot',
  components: { DisplayCornerProduct },
  props: {
    products: { type: Array, required: true },
    showSpareItemsSeparately: { type: Boolean, default: true }
  }
};
</script>

<style scoped>
.DisplayCornerSnapshot {
  margin-top: 32px;
  padding: 0 11px 60px;
}
</style>
