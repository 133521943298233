<template>
  <AppModalDefault title="알림톡 미리보기" width="640px" @close="close">
    <template #body>
      <AppMobilePhoneBizMessagePreview
        brand-name="CREMA Shop"
        dispatch-time="오전 10:00"
      >
        알림톡이 도착했습니다.
      </AppMobilePhoneBizMessagePreview>
    </template>
  </AppModalDefault>
</template>
<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'UiMobileBizMessagePreviewDialog',
  mixins: [DialogView]
};
</script>
