import api from '@/lib/api';

export default {
  data() {
    return {
      productSearchTypes: [
        { value: 'name', label: this.$t('products.name') },
        { value: 'code', label: this.$t('products.code') }
      ],
      productColumns: [
        { id: 'code', label: this.$t('products.code') },
        { id: 'product', label: this.$t('products.name'), type: 'product' }
      ]
    };
  },
  methods: {
    searchProducts({ params, onSuccess, onFailure }) {
      api
        .get('/products', {
          params: { ...params, default_filters: this.defaultFilters }
        })
        .then(({ data }) => {
          const { products } = data;
          onSuccess({
            ...products,
            items: products.items.map(p => ({ ...p, product: p }))
          });
        })
        .catch(onFailure);
    }
  }
};
