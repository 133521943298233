import _ from 'lodash';

export default {
  data() {
    return {
      currentVersion: 0,
      displayVersion: 0
    };
  },
  mounted() {
    this.updatePreviewDebounced = _.debounce(this.updatePreview, 500);
  },
  methods: {
    updatePreviewIfValid(errorSummary) {
      if (!errorSummary) {
        this.currentVersion++;
        this.updatePreviewDebounced();
      }
    }
  }
};
