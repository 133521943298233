<template>
  <AppSearchbar @submit="search">
    <template #default>
      <li v-if="menuItems.length">
        <AppDropdownMenuButton
          button-style="red"
          :menu-items="menuItems"
          :label="$t('button.add_campaign')"
        />
      </li>
    </template>
    <template #right>
      <li>
        <AppSelect
          :value="resourceParams.sort_type"
          :options="sortTypeOptions"
          @change="changeSortType"
        />
      </li>
      <li>
        <AppSearchWithType
          :value="resourceParams"
          :search-types="searchTypeOptions"
          @change="change"
        />
      </li>
    </template>
  </AppSearchbar>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import TargetCampaignType from '@/enums/TargetCampaignType';
import BrandReviewStatus from '@/enums/BrandReviewStatus';

export default {
  name: 'TargetCampaignsSearchbar',
  model: { prop: 'resourceParams', event: 'change' },
  props: {
    creatableCampaignTypes: { type: Array, required: true },
    sortTypes: { type: Array, required: true },
    searchTypes: { type: Array, required: true },
    resourceParams: { type: Object, required: true }
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('session', ['isUsingNlp']),
    ...mapGetters('targetCampaigns', ['campaignTextSafe']),
    sortTypeOptions() {
      return this.sortTypes.map(value => ({
        label: this.$t(`sort_types.${value}`),
        value
      }));
    },
    searchTypeOptions() {
      return this.searchTypes.map(value => ({
        label: this.$t(`search_types.${value}`),
        value
      }));
    },
    menuItems() {
      return this.creatableCampaignTypes.map(campaignType => ({
        label: TargetCampaignType.t(campaignType),
        tooltip: this.itemTooltip(campaignType),
        disabled: this.isItemDisabled(campaignType),
        clickHandler: ({ close }) => {
          this.openDialog(['TargetCampaignCreateDialog', { campaignType }]);
          close();
        }
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    changeSortType(sort_type) {
      this.change({ ...this.resourceParams, sort_type });
      this.search();
    },
    change(resourceParams) {
      this.$emit('change', resourceParams);
    },
    search() {
      this.$emit('search');
    },
    itemTooltip(campaignType) {
      if (this.isReviewHighlightCampaignType(campaignType))
        return this.reviewHighlightTooltip();
      else {
        return this.campaignTextSafe(
          { campaign_type: campaignType },
          'create.tooltip'
        );
      }
    },
    isItemDisabled(campaignType) {
      if (this.isReviewHighlightCampaignType(campaignType))
        return !(
          BrandReviewStatus.isOperating(this.currentBrand.review_status) &&
          this.isUsingNlp
        );
      else return false;
    },
    reviewHighlightTooltip() {
      if (BrandReviewStatus.isPreparing(this.currentBrand.review_status))
        return this.$t('tooltip.preparing');
      else if (BrandReviewStatus.isOperating(this.currentBrand.review_status))
        return this.isUsingNlp ? '' : this.$t('tooltip.not_using_nlp');
      else return this.$t('tooltip.not_using');
    },
    isReviewHighlightCampaignType(campaignType) {
      return (
        campaignType ===
        TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "button": {
    "add_campaign": "캠페인 추가"
  },
  "sort_types": {
    "created_at": "등록일순",
    "edited_at": "수정일순",
    "name": "이름순",
    "group_name": "캠페인 그룹순"
  },
  "search_types": {
    "name": "캠페인명",
    "group_name": "캠페인 그룹"
  },
  "tooltip": {
    "preparing": "CREMA Review 서비스 결제 및 테스트 중 상태입니다.<br>해당 항목은 쇼핑몰에 CREMA Review 서비스가<br>라이브된 이후 정상적으로 사용 가능합니다.",
    "not_using": "CREMA Review 서비스 사용이 필요합니다.",
    "not_using_nlp": "CREMA Review 서비스의 [AI 리뷰 분석] 설정을<br>적용한 이후 정상적으로 사용 가능합니다."
  }
}
</i18n>
