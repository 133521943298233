<template>
  <div>
    <AppButton
      class="ReviewSettingsBrandEvaluationTypes__add-button"
      :label="$t('add_brand_evaluation_type')"
      @click="addBrandEvaluationType"
    />
    <AppTable
      table-id="review-settings-brand_evaluation_types-table"
      :columns="COLUMNS"
      :rows="rows"
      :no-data="$t('no_data')"
    >
      <template #cell="{ row, column }">
        <template v-if="column.id === 'name'">
          <ul>
            <li class="table-line">
              <input type="hidden" :name="inputName('id')" :value="row.id" />
              <input
                type="hidden"
                :name="inputName('global_review_option_type_id')"
                :value="row.global_review_option_type_id"
              />
              <AppSelectFilterable
                :value="row.global_review_option_type_id"
                :options="
                  evaluationTypeOptions(row.global_review_option_type_id)
                "
                :disabled="isDisabled(row)"
                :invalid="
                  !!errors[
                    `brand_evaluation_types[${row.id}][global_review_option_type_id]`
                  ]
                "
                :placeholder="$t('placeholder')"
                @change="changeGlobalReviewOptionTypeId(row.id, $event)"
              />
              <AppFormError
                :error="
                  errors[
                    `brand_evaluation_types[${row.id}][global_review_option_type_id]`
                  ]
                "
              />
            </li>
          </ul>
        </template>
        <template v-else-if="column.id === 'options'">
          <div v-text="optionNames(row.global_review_option_type_id)" />
        </template>
        <template v-else-if="column.id === 'showable'">
          <AppCheckbox
            :checked="row.showable"
            :name="inputName('showable')"
            input-style="standalone"
            :disabled="
              isTextType(row.global_review_option_type_id) ||
                !(row.showable || enableToSetShowable)
            "
            @change="changeShowable(row.id, $event)"
          />
        </template>
        <template v-else-if="column.id === 'searchable'">
          <AppCheckbox
            :checked="row.searchable"
            :name="inputName('searchable')"
            input-style="standalone"
            :disabled="isTextType(row.global_review_option_type_id)"
            @change="changeSearchable(row.id, $event)"
          />
        </template>
        <template v-else-if="column.id === 'displayable'">
          <AppCheckbox
            :checked="row.displayable"
            :name="inputName('displayable')"
            input-style="standalone"
            @change="changeDisplayable(row.id, $event)"
          />
        </template>
        <template v-else-if="column.id === 'required'">
          <AppCheckbox
            :checked="row.required"
            :name="inputName('required')"
            input-style="standalone"
            @change="changeRequired(row.id, $event)"
          />
        </template>
        <template v-else-if="column.id === 'actions'">
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            :disabled="isDisabled(row)"
            @click="deleteBrandEvaluationType(row.id)"
          />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import _ from 'lodash';
import FormView from '@/mixins/FormView';
import ReviewOptionFieldType from '@/enums/ReviewOptionFieldType';

const MAX_SHOWABLE_COUNT = 3;

export default {
  name: 'ReviewSettingsBrandEvaluationTypes',
  mixins: [FormView],
  model: { prop: 'brandEvaluationTypes', event: 'change' },
  props: {
    brandEvaluationTypes: { type: Array, required: true },
    evaluationTypes: { type: Array, required: true },
    selectableEvaluationTypeIds: { type: Array, required: true },
    errors: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      COLUMNS: [
        ...[
          'name',
          'options',
          'showable',
          'searchable',
          'displayable',
          'required'
        ].map(c => ({
          id: c,
          label: this.$t(`brand_evaluation_types.${c}`)
        })),
        {
          id: 'actions',
          label: this.$t('app.actions')
        }
      ]
    };
  },
  computed: {
    ReviewOptionFieldType() {
      return ReviewOptionFieldType;
    },
    evaluationTypesMap() {
      return _.keyBy(this.evaluationTypes, 'id');
    },
    rows() {
      return this.brandEvaluationTypes;
    },
    enableToSetShowable() {
      const showableBrandEvaluationTypes = this.brandEvaluationTypes.filter(
        t => t.showable
      );

      return MAX_SHOWABLE_COUNT > showableBrandEvaluationTypes.length;
    }
  },
  methods: {
    evaluationTypeOptions(selectedId) {
      return this.selectableEvaluationTypeIds.map(id => ({
        label: this.evaluationTypesMap[id].name,
        value: id,
        disabled:
          selectedId !== id &&
          this.brandEvaluationTypes.some(
            e => e.global_review_option_type_id === id
          )
      }));
    },
    isDisabled({ global_review_option_type_id }) {
      return global_review_option_type_id
        ? this.evaluationTypesMap[global_review_option_type_id].disabled
        : false;
    },
    inputName(field) {
      return `product_category_product_type[brand_evaluation_types_attributes][][${field}]`;
    },
    isTextType(global_review_option_type_id) {
      return global_review_option_type_id
        ? this.evaluationTypesMap[global_review_option_type_id].field_type ===
            ReviewOptionFieldType.TEXT
        : false;
    },
    optionNames(global_review_option_type_id) {
      if (!global_review_option_type_id) return '-';

      const optionType = this.evaluationTypesMap[global_review_option_type_id];
      if (optionType.field_type !== ReviewOptionFieldType.SELECT) return '-';

      return optionType.option_values
        .map(e => e.display_name || e.name)
        .join(',');
    },
    addBrandEvaluationType() {
      this.$emit('change', [
        ...this.brandEvaluationTypes,
        { id: _.uniqueId('brand_evaluation_types_id') }
      ]);
    },
    changeGlobalReviewOptionTypeId(id, global_review_option_type_id) {
      this.$emit(
        'change',
        this.brandEvaluationTypes.map(e => {
          if (e.id !== id) return e;

          if (global_review_option_type_id) {
            const evaluationType = this.evaluationTypesMap[
              global_review_option_type_id
            ];
            return {
              id: e.id,
              global_review_option_type_id,
              displayable: evaluationType.displayable,
              required: evaluationType.required,
              searchable: evaluationType.searchable,
              showable:
                evaluationType.field_type !== ReviewOptionFieldType.TEXT &&
                this.brandEvaluationTypes.filter(e => e.id !== id && e.showable)
                  .length < MAX_SHOWABLE_COUNT
            };
          } else return { ...e, global_review_option_type_id: null };
        })
      );

      this.$nextTick(() =>
        this.validateField(id, 'global_review_option_type_id')
      );
    },
    changeShowable(id, showable) {
      this.$emit(
        'change',
        this.brandEvaluationTypes.map(e =>
          e.id === id ? { ...e, showable } : e
        )
      );
    },
    changeSearchable(id, searchable) {
      this.$emit(
        'change',
        this.brandEvaluationTypes.map(e =>
          e.id === id ? { ...e, searchable } : e
        )
      );
    },
    changeDisplayable(id, displayable) {
      this.$emit(
        'change',
        this.brandEvaluationTypes.map(e =>
          e.id === id ? { ...e, displayable } : e
        )
      );
    },
    changeRequired(id, required) {
      this.$emit(
        'change',
        this.brandEvaluationTypes.map(e =>
          e.id === id ? { ...e, required } : e
        )
      );
    },
    deleteBrandEvaluationType(id) {
      this.$emit(
        'change',
        this.brandEvaluationTypes.filter(e => e.id !== id)
      );
    },
    validateField(id, field) {
      this.$emit('validate-field', `brand_evaluation_types[${id}][${field}]`);
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewSettingsBrandEvaluationTypes__add-button {
  margin-bottom: 12px;
}

::v-deep {
  .review-settings-brand_evaluation_types-table__name {
    min-width: 191px;
  }

  .review-settings-brand_evaluation_types-table__options {
    min-width: 564px;
  }

  .review-settings-brand_evaluation_types-table__showable {
    min-width: 78px;
  }

  .review-settings-brand_evaluation_types-table__searchable,
  .review-settings-brand_evaluation_types-table__displayable,
  .review-settings-brand_evaluation_types-table__required {
    min-width: 51px;
  }

  .review-settings-brand_evaluation_types-table__actions {
    width: 78px;
  }
}
</style>

<i18n locale="ko">
{
  "placeholder": "평가 항목을 선택해주세요",
  "add_brand_evaluation_type": "평가 항목 추가",
  "brand_evaluation_types" : {
    "name": "평가 항목",
    "options": "선택 항목 표시 이름",
    "showable": "통계 위젯 노출",
    "searchable": "검색 허용",
    "displayable": "위젯 노출",
    "required": "필수 입력"
  },
  "no_data": "설정된 평가 항목이 없습니다."
}
</i18n>
