<template>
  <div>
    <div
      v-for="(rowItems, rowIndex) in itemMatrix"
      :key="rowIndex"
      class="DisplayCornerItemsMatrix__row"
      :style="isItemWidthFixed ? { width: `${rowWidth}px` } : {}"
    >
      <div
        v-for="(item, index) in rowItems"
        :key="index"
        class="DisplayCornerItemsMatrix__item"
        :style="`width: ${itemWidth}px`"
      >
        <slot name="item" :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisplayCornerItemsMatrix',
  props: {
    itemMatrix: { type: Array, required: true },
    itemWidth: { type: Number, required: true },
    rowWidth: { type: Number, required: true },
    isItemWidthFixed: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
.DisplayCornerItemsMatrix__row {
  display: flex;
  justify-content: center;
  margin: auto;

  & + & {
    margin-top: 20px;
  }
}

.DisplayCornerItemsMatrix__item {
  & + & {
    margin-left: 12px;
  }
}
</style>
