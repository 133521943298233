<template>
  <div>
    <TheAdvancedSettingsDialogInitJsInstaller
      v-if="isInitJsInstallableShopBuilder"
    />
    <TheAdvancedSettingsDialogShopbyExternalScripts
      v-if="shopBuilder === 'shopby'"
    />
    <TheAdvancedSettingsDialogCafe24SeoScripts
      v-else-if="shopBuilder === 'cafe24'"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TheAdvancedSettingsDialogShopbyExternalScripts from '@/layouts/components/TheAdvancedSettingsDialog/TheAdvancedSettingsDialogShopbyExternalScripts';
import TheAdvancedSettingsDialogCafe24SeoScripts from '@/layouts/components/TheAdvancedSettingsDialog/TheAdvancedSettingsDialogCafe24SeoScripts';
import TheAdvancedSettingsDialogInitJsInstaller from '@/layouts/components/TheAdvancedSettingsDialog/TheAdvancedSettingsDialogInitJsInstaller';

export default {
  name: 'TheAdvancedSettingsDialogWidgetInstallation',
  components: {
    TheAdvancedSettingsDialogInitJsInstaller,
    TheAdvancedSettingsDialogShopbyExternalScripts,
    TheAdvancedSettingsDialogCafe24SeoScripts
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    shopBuilder() {
      return this.currentBrand && this.currentBrand.shop_builder;
    },
    isInitJsInstallableShopBuilder() {
      return ['cafe24', 'godo', 'makeshop', 'shopby'].includes(
        this.shopBuilder
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheAdvancedSettingsDialogWidgetInstallation__form {
  width: 680px;
  border-radius: 4px;
  margin: 16px auto 32px auto;
  padding: 24px;
  background-color: $color-background;
}
</style>
