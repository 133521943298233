import { constructEnum } from '@/lib/enum';

const ShopifyWidgetInstallationType = constructEnum(
  'ShopifyWidgetInstallationType',
  {
    PRODUCT_DETAIL_POPUP_WIDGET: 100,
    PRODUCT_DETAIL_LIST_WIDGET: 110,
    PRODUCT_DETAIL_SCORE_WIDGET: 120,
    MAIN_POPUP_WIDGET: 200,
    MY_PAGE_MY_REVIEWS_WIDGET: 300,
    PRODUCT_DETAIL_AND_MAIN_POPUP_WIDGET: 100200
  }
);

export default ShopifyWidgetInstallationType;
