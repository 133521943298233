<template>
  <div class="ChannelProductsStatusCell">
    <ul>
      <li
        v-for="(item, i) in statusBadges"
        :key="i"
        class="ChannelProductsStatusCell__badge"
      >
        <AppBadge
          :label="item.label"
          :tooltip="item.tooltip"
          :badge-style="item.style"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ChannelProductsStatusCell',
  props: {
    product: { type: Object, required: true }
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoryNamesMap']),
    statusBadges() {
      let badges = [];
      badges.push(this.selectionStatus);

      if (this.product.excluding_category_ids?.length) {
        badges.push(this.excludingCategoryStatus);
      }

      if (this.product.is_excluding_product) {
        badges.push(this.excludingProductStatus);
      }

      if (this.product.excluding_product_keywords?.length) {
        badges.push(this.excludingProductKeywordStatus);
      }

      if (this.product.has_channel_product_image) {
        badges.push({
          label: this.$t('product_status.channel_product_image'),
          style: 'grey-outline',
          tooltip: this.$t('channel_product_image_tooltip')
        });
      }

      return badges;
    },
    selectionStatus() {
      const isSelected = this.product.is_selected;
      return {
        label: isSelected
          ? this.$t('product_status.selected')
          : this.$t('product_status.unselected'),
        style: isSelected ? 'mint-green' : 'grey-outline'
      };
    },
    excludingCategoryStatus() {
      const excludingCategoryIds = this.product.excluding_category_ids;
      const categoryNames = excludingCategoryIds.map(
        id => this.productCategoryNamesMap[id]
      );

      return {
        label: this.$t('product_status.excluding_category'),
        style: 'red-outline',
        tooltip: {
          textAlign: 'left',
          message: this.excludingItemTooltip(
            categoryNames,
            categoryNames.length
          ),
          info: this.$t('excluding_item.tooltip_info')
        }
      };
    },
    excludingProductStatus() {
      return {
        label: this.$t('product_status.excluding_product'),
        style: 'red-outline',
        tooltip: this.$t('excluding_product_tooltip')
      };
    },
    excludingProductKeywordStatus() {
      const excludingProductKeywords = this.product.excluding_product_keywords;

      return {
        label: this.$t('product_status.excluding_product_keywords'),
        style: 'red-outline',
        tooltip: {
          textAlign: 'left',
          message: this.excludingItemTooltip(
            excludingProductKeywords,
            excludingProductKeywords.length
          ),
          info: this.$t('excluding_item.tooltip_info')
        }
      };
    }
  },
  methods: {
    excludingItemTooltip(items, itemCount) {
      const countForTooptip = 2;
      if (itemCount > countForTooptip) {
        return this.$t('excluding_item.tooltip3', [
          items[0],
          items[1],
          itemCount - countForTooptip
        ]);
      } else {
        return this.$t(`excluding_item.tooltip${itemCount}`, items);
      }
    }
  }
};
</script>

<i18n locale="ko">
  {
    "product_status": {
      "selected": "선정됨",
      "unselected": "선정되지 않음",
      "excluding_category": "제외 카테고리",
      "excluding_product": "제외 상품 고정",
      "excluding_product_keywords": "제외 상품 키워드",
      "channel_product_image": "대체 상품 이미지"
    },
    "excluding_item": {
      "tooltip1": "{0}에 등록된 상품입니다.",
      "tooltip2": "{0}, {1}에 등록된 상품입니다.",
      "tooltip3": "{0}, {1} 외 {2}개에 등록된 상품입니다.",
      "tooltip_info": "제외 카테고리는 [애드 - 설정]에서 관리할 수 있습니다."
    },
    "excluding_product_tooltip": "제외 상품으로 고정된 상품입니다.",
    "channel_product_image_tooltip": "대체 상품 이미지가 등록된 상품입니다."
  }
  </i18n>
