<template>
  <div>
    <div class="table-head-control">
      <AppButton
        :label="$t('add_image')"
        v-bind="{ disabled: disabled || isEtcReview }"
        :tooltip="disabled && !isEtcReview ? $t('add_image_tooltip') : null"
        @click="addImage"
      />
    </div>
    <AppTable
      table-id="images-table"
      :columns="COLUMNS"
      :rows="images"
      :no-data="$t('no_data')"
    >
      <template #cell="{ column, rowIndex }">
        <template v-if="column.id === 'position'">{{ rowIndex + 1 }}</template>
        <template v-else-if="column.id === 'image_preview'">
          <input
            type="hidden"
            name="review[images_attributes][][rotation]"
            :value="images[rowIndex].rotation"
          />
          <input
            type="hidden"
            name="review[images_attributes][][url]"
            :value="images[rowIndex].url"
          />
          <div class="ReviewReviewEditImagesTable__image-preview">
            <AppImage
              v-if="hasImage(rowIndex)"
              class="ReviewReviewEditImagesTable__image-preview-image"
              :src="images[rowIndex].url || images[rowIndex].file"
              :style="{
                transform: `rotate(${images[rowIndex].rotation}deg)`
              }"
              @error="deleteErroredImage(rowIndex)"
            />
            <div
              v-else
              class="ReviewReviewEditImagesTable__image-preview-no-image"
            >
              -
            </div>
          </div>
        </template>
        <template v-else-if="column.id === 'image'">
          <AppImageInput
            :key="`review_image_file${images[rowIndex].id}`"
            :ref="`review_image_file${images[rowIndex].id}`"
            not-removable
            no-preview
            name="review[images_attributes][][file]"
            :select-on-mount="images[rowIndex].selectOnMount"
            :invalid="!hasImage(rowIndex)"
            :disabled="isEtcReview"
            @change="imageFileSelected(rowIndex, $event.imageUrl)"
          />
        </template>
        <template v-else-if="column.id === 'actions'">
          <AppButtonDraggable :disabled="isEtcReview" @sort="sort">
            <template #drag-image>
              <img
                style="padding: 4px"
                :src="images[rowIndex].url || images[rowIndex].file"
              />
            </template>
          </AppButtonDraggable>
          <AppDropdownMenuButton
            :label="$t('edit_delete_button')"
            :menu-items="[
              [
                {
                  label: $t('rotate_image_ccw'),
                  disabled: !hasImage(rowIndex) || isEtcReview,
                  clickHandler: ({ close }) =>
                    rotateImageCcwClicked(rowIndex, close)
                },
                {
                  label: $t('rotate_image_cw'),
                  disabled: !hasImage(rowIndex) || isEtcReview,
                  clickHandler: ({ close }) =>
                    rotateImageCwClicked(rowIndex, close)
                }
              ],
              [
                {
                  label: $t('app.delete'),
                  style: 'danger',
                  clickHandler: ({ close }) =>
                    deleteImageClicked(rowIndex, close)
                }
              ]
            ]"
          />
        </template>
      </template>
    </AppTable>
    <input
      v-if="!images.length"
      type="hidden"
      name="review[images_attributes][]"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { arrayMoveIndex } from '@/lib/array';

export default {
  name: 'ReviewReviewEditImagesTable',
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isEtcReview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'position', label: this.$t('app.position') },
        { id: 'image_preview', label: this.$t('image_preview') },
        { id: 'image', label: this.$t('image') },
        { id: 'actions', label: this.$t('actions') }
      ],
      images: _.cloneDeep(this.value)
    };
  },
  watch: {
    images: {
      handler() {
        this.$emit('change', this.images);
      },
      deep: true
    }
  },
  methods: {
    addImage() {
      this.images.push({
        id: _.uniqueId('image'),
        rotation: 0,
        selectOnMount: true
      });
    },
    imageFileSelected(rowIndex, file) {
      const image = this.images[rowIndex];
      this.$set(image, 'file', file);
      this.$delete(image, 'url');
    },
    rotateImage(image, rotation) {
      image.rotation = (image.rotation + rotation) % 360;
    },
    rotateImageCcwClicked(rowIndex, close) {
      this.rotateImage(this.images[rowIndex], 270);
      close();
    },
    rotateImageCwClicked(rowIndex, close) {
      this.rotateImage(this.images[rowIndex], 90);
      close();
    },
    deleteImageClicked(rowIndex, close) {
      this.images.splice(rowIndex, 1);
      close();
    },
    hasImage(rowIndex) {
      const image = this.images[rowIndex];
      return !!(image.file || image.url);
    },
    sort({ srcIndex, dstIndex }) {
      this.images = arrayMoveIndex(this.images, srcIndex, dstIndex);
    },
    deleteErroredImage(rowIndex) {
      const imageFile = this.$refs[
        `review_image_file${this.images[rowIndex].id}`
      ];
      alert(this.$t('image_error', { file_name: imageFile.fileName }));
      this.images.splice(rowIndex, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewReviewEditImagesTable__image-preview {
  line-height: 120px;
  font-size: 0;
}

.ReviewReviewEditImagesTable__image-preview-image {
  max-height: 120px;
  vertical-align: middle;
}

.ReviewReviewEditImagesTable__image-preview-no-image {
  font-size: 13px;
}

::v-deep {
  .images-table {
    table-layout: fixed;
  }
  .images-table__position {
    width: 40px;
  }
  .images-table__image-preview {
    width: 136px;
    line-height: 0;
  }
  .images-table__image {
    line-height: 0;
  }
  .images-table__actions {
    width: 182px;
  }
}
</style>

<i18n locale="ko">
{
  "add_image": "+ 이미지 추가",
  "add_image_tooltip": "첨부 포토/동영상은 최대 4개까지 가능합니다.",
  "no_data": "리뷰에 첨부한 이미지가 없습니다.",
  "image_preview": "이미지 미리보기",
  "image": "이미지 등록",
  "actions": "이미지 편집/삭제",
  "edit_delete_button": "편집/삭제",
  "rotate_image_ccw": "왼쪽으로 90도 회전",
  "rotate_image_cw": "오른쪽으로 90도 회전",
  "image_error": "이미지 형식의 파일만 첨부할 수 있습니다. (선택된 파일명:{file_name})"
}
</i18n>
<i18n locale="en">
{
  "add_image": "+ Add image",
  "add_image_tooltip": "Up to 4 photos/videos can be attached.",
  "no_data": "No attached image on review.",
  "image_preview": "Preview image",
  "image": "Attach image",
  "actions": "Edit/Delete image",
  "edit_delete_button": "Edit/Delete",
  "rotate_image_ccw": "Rotate 90 degrees to the left",
  "rotate_image_cw": "Rotate 90 degrees to the right",
  "image_error": "You must select an image file. (Selected file name:{file_name})"
}
</i18n>
