<template>
  <li
    v-tooltip="subMenuItem.disabled ? disableTooltip(subMenuItem.id) : null"
    :class="[
      'SubMenuItem',
      {
        'SubMenuItem--active': subMenuItem.id === selectedSubMenuItemId,
        'SubMenuItem--2depth': is2Depth,
        'SubMenuItem--disabled': subMenuItem.disabled,
        'super-admin__item': subMenuItem.superAdmin
      }
    ]"
    @click="click"
  >
    <Component
      :is="linkComponent"
      :to="subMenuItem.path"
      class="SubMenuItem__link"
      >{{ subMenuItem.label }}
      <AppSvgIcon
        v-if="hasChild"
        :name="
          subMenuItem.isChildSelected ? 'nav-chevron-down' : 'nav-chevron-right'
        "
        class="SubMenuItem__arrow_icon"
      />
    </Component>
    <slot />
  </li>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'SubMenuItem',
  props: {
    subMenuItem: {
      type: Object,
      required: true
    },
    is2Depth: {
      type: Boolean,
      default: false
    },
    hasChild: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('menu', ['selectedSubMenuItemId']),
    linkComponent() {
      const { isOldAdmin, dialog } = this.subMenuItem;
      return isOldAdmin ? 'AppOldAdminLink' : dialog ? 'a' : 'AppInternalLink';
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    disableTooltip(subMenuItemId) {
      if (subMenuItemId === 'review_user_grades') {
        const { shop_builder } = this.currentBrand;
        if (shop_builder === 'none') {
          return this.$t('disable_tooltip_review_user_grades_using_api');
        }
        return this.$t(
          'disable_tooltip_review_user_grades_using_shop_builder',
          { shop_builder }
        );
      } else return this.$t('disabled');
    },
    click() {
      if (this.subMenuItem.dialog) this.openDialog(this.subMenuItem.dialog);
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_transitions.scss';
@import '@/scss/mixins/_breakpoints.scss';

$sub-menu-item-height: 36px;
$sub-menu-item-padding-top: 7px;
$sub-menu-item-padding-bottom: 9px;

.SubMenuItem__link {
  @include text-sub-navigation;
  @include transition-basic(color, background-color);

  padding: $sub-menu-item-padding-top 12px $sub-menu-item-padding-bottom 12px;
  line-height: $sub-menu-item-height - $sub-menu-item-padding-top -
    $sub-menu-item-padding-bottom;
  display: block;
  margin: 0 7px;
  border-radius: 4px;
  position: relative;

  &:hover {
    background-color: $color-nav-selected-light;
  }

  .SubMenuItem--2depth & {
    @include text-sub-navigation-light;

    padding-left: 28px;
  }

  @include media-breakpoint-each(mobile, tablet) {
    font-size: 16px !important;
    line-height: 28px !important;
    margin: 0 12px;
  }

  .SubMenuItem--active & {
    background-color: $color-nav-selected;
    color: $color-primary;
    font-weight: bold;
  }

  .SubMenuItem--disabled & {
    color: $color-disable-text;
    pointer-events: none;
  }
}

$sub-menu-arrow-size: 10px;

.SubMenuItem__arrow_icon {
  position: absolute;
  top: ($sub-menu-item-height - $sub-menu-arrow-size) * 0.5;
  right: 12px;
  color: $color-grey-40;
}
</style>

<i18n locale="ko">
{
  "disabled": "사용하지 않는 메뉴입니다.",
  "disable_tooltip_review_user_grades_using_shop_builder": "회원등급은 {shop_builder}에서 설정할 수 있습니다.",
  "disable_tooltip_review_user_grades_using_api": "회원등급은 UserGrade API로 설정할 수 있습니다."
}
</i18n>
