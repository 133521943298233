import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import TargetCampaignSettingsView from './TargetCampaignSettingsView';

export default {
  mixins: [DialogFormView, TargetCampaignSettingsView],
  props: {
    campaign: { type: Object, required: true }
  },
  data() {
    return {
      isLoading: true,
      isShowingAdvancedSettings: false
    };
  },
  computed: {
    ...mapGetters('targetCampaigns', ['campaignTextPath']),
    campaignType() {
      return this.campaign.campaign_type;
    },
    descriptionPath() {
      return this.campaignTextPath(this.campaign, 'recipients');
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaign.id}/dispatch_recipients`)
      .then(({ data }) => (this.orgFormObject = data.campaign))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      if (!this.confirmSubmit()) return;

      this.isSubmitting = true;
      api
        .patch(
          `/target/campaigns/${this.campaign.id}/dispatch_recipients`,
          formData,
          {
            successMessage: this.$t('app.saved')
          }
        )
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    getRecipientsValue(id) {
      return _.get(this.formObject, id);
    },
    setRecipientsValue(id, value, validatorId) {
      _.set(this.formObject, id, value);
      this.validateField(id, validatorId);
    }
  }
};
