import pickBy from 'lodash/pickBy';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import parse from 'qs/lib/parse';
import stringify from 'qs/lib/stringify';

export const trimParams = function(params, defaultParams = {}) {
  return pickBy(
    params,
    (v, k) =>
      v !== '' && v !== undefined && v !== null && !isEqual(v, defaultParams[k])
  );
};

export const getParamsFromPath = function(path) {
  const [, query] = path.split('?');
  return query ? parse(query) : {};
};

export const pathWithParams = function(path, params, hash) {
  const [pathWithoutQuery, query] = path.split('?');
  const oldParams = parse(query);
  const newParams = trimParams(merge(oldParams, params));
  const paths = [pathWithoutQuery];
  if (!isEmpty(newParams)) paths.push(`?${stringify(newParams)}`);
  if (hash) paths.push(`#${hash}`);
  return paths.join('');
};

export const isHashChange = (to, from) =>
  to.path === from.path &&
  isEqual(to.query, from.query) &&
  to.hash !== from.hash;
