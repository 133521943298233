import api from '@/lib/api';

const cache = {};

function checkBrandUrlDuplicationCache(url) {
  if (!url) return true;
  return cache[url];
}

function checkBrandUrlDuplication(url) {
  return new Promise(function(resolve, reject) {
    if (cache[url]) return resolve(cache[url]);

    api
      .get('/validations/check_brand_url_duplication', { params: { url } })
      .then(({ data }) => {
        if (data.duplicated) {
          cache[url] = false;
          reject();
        } else {
          cache[url] = true;
          resolve();
        }
      })
      .catch(reject);
  });
}

export { checkBrandUrlDuplicationCache, checkBrandUrlDuplication };
