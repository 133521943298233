import _ from 'lodash';

const state = { requests: {} };

const mutations = {
  CLEAR_REQUEST(state, requestId) {
    state.requests = _.omit(state.requests, requestId);
  },
  SET_REQUEST_PENDING(state, requestId) {
    state.requests = { ...state.requests, [requestId]: { isPending: true } };
  },
  SET_REQUEST_SUCCESS(state, requestId) {
    state.requests = { ...state.requests, [requestId]: { isSuccess: true } };
  },
  SET_REQUEST_ERROR(state, requestId) {
    state.requests = { ...state.requests, [requestId]: { isError: true } };
  }
};

const getters = {
  isRequestPending: ({ requests }) => requestId => {
    const request = requests[requestId];
    return request ? !!request.isPending : false;
  },
  isRequestSuccess: ({ requests }) => requestId => {
    const request = requests[requestId];
    return request ? !!request.isSuccess : false;
  }
};

export default { namespaced: true, state, mutations, getters };
