var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.isCategoryPage ? _vm.cornerPreview.name : _vm.subTitle,"form-props":Object.assign({}, _vm.formProps,
    {disabled: !_vm.isValid,
    formStyle: 'narrow',
    submitLabel: _vm.$t('app.confirmed')})},scopedSlots:_vm._u([{key:"group",fn:function(ref){
  var id = ref.id;
  var value = ref.value;
  var error = ref.error;
  var options = ref.options;
return [(id === 'position')?[_c('i18n',{attrs:{"path":"form.position.description"}},[_c('AppNumberInput',{staticClass:"AppForm__group-field DisplayCornerAddProductDialog__position",attrs:{"digits":3,"invalid":!!error},on:{"change":function($event){return _vm.validateField('position')},"blur":function($event){return _vm.validateField('position')}},model:{value:(_vm.formObject.position),callback:function ($$v) {_vm.$set(_vm.formObject, "position", $$v)},expression:"formObject.position"}})],1)]:(id === 'method')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectRadio',{attrs:{"name":"method","options":options},scopedSlots:_vm._u([{key:"label",fn:function(ref){
  var value = ref.value;
return [_c('div',[_vm._v(_vm._s(_vm.$t(("form.method.options." + value + ".label"))))]),_c('div',{staticClass:"DisplayCornerAddProductDialog__method-description"},[_vm._v(" "+_vm._s(_vm.$t(("form.method.options." + value + ".description")))+" ")])]}}],null,true),model:{value:(_vm.formObject.method),callback:function ($$v) {_vm.$set(_vm.formObject, "method", $$v)},expression:"formObject.method"}})],1)]:_vm._e()]}}])},_vm.formEvents),[_c('AppInfoBox',{staticClass:"DisplayCornerAddProductDialog__info-box"},[_vm._v(" "+_vm._s(_vm.$t('info'))+" "),(_vm.isNewCategoryProduct)?_c('div',[_vm._v(_vm._s(_vm.$t('additional_info')))]):_vm._e()]),_c('AppDataSection',{attrs:{"title":_vm.$t('product_info.title')}},[_vm._v(_vm._s(_vm.productsName))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }