<template>
  <Component
    :is="multiple ? 'AppMultipleSelect' : 'AppSelectFilterable'"
    v-bind="$props"
    width="100%"
    :options="options"
    v-on="$listeners"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import ArrayPackable from '@/mixins/ArrayPackable';

export default {
  name: 'AppSelectProductCategory',
  mixins: [ArrayPackable],
  model: { event: 'change' },
  props: {
    id: { type: String, default: null },
    name: { type: String, default: null },
    value: { type: [String, Number, Boolean, Array], default: null },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default() {
        return this.$t('app.category_placeholder');
      }
    },
    multiple: { type: Boolean, default: false },
    showableCategoryIds: { type: Array, default: null },
    showNonCategoryOptions: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoryOptions']),
    options() {
      if (this.showNonCategoryOptions)
        return [...this.nonCategoryOptions, ...this.categoryOptions];
      else return this.categoryOptions;
    },
    nonCategoryOptions() {
      return [
        { label: this.$t('app.all'), value: null },
        { label: this.$t('uncategorized'), value: 'uncategorized' }
      ];
    },
    categoryOptions() {
      if (!this.showableCategoryIds) return this.productCategoryOptions;

      return this.productCategoryOptions.filter(option =>
        this.showableCategoryIds.includes(option.value)
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "uncategorized": "미분류"
}
</i18n>
