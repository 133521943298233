import _ from 'lodash';
import { constructEnum } from '@/lib/enum';
import i18n from '@/lib/i18n';

const TargetSamplingMethod = constructEnum('TargetSamplingMethod', {
  MESSAGE_CHECKED: 0,
  POPUP_CHECKED: 10,
  POPUP_CLICKED: 20,
  WIDGET_CLICKED: 30
});

TargetSamplingMethod.actionText = function(value) {
  return i18n.t(`TargetSamplingMethod.action.${this.toKey(value)}`);
};

TargetSamplingMethod.tooltipText = function(value) {
  return i18n.t('TargetSamplingMethod.tooltip', [this.actionText(value)]);
};

const CLICKED_TO_CHECKED_COLUMNS_MAP = {
  clicked_purchase_counts: 'purchase_counts',
  clicked_purchase_rate: 'purchase_rate',
  clicked_purchased_users_count: 'purchased_users_count',
  clicked_purchased_orders_count: 'purchased_orders_count',
  clicked_respond_rate: 'respond_rate',
  clicked_respondents_count: 'respondents_count',
  clicked_revenue: 'revenue',
  clicked_review_rate: 'review_rate',
  clicked_reviewed_sub_orders_count: 'reviewed_sub_orders_count',
  clicked_purchased_orders: 'purchased_orders',
  clicked_joined_users: 'joined_users',
  clicked_visited_users: 'visited_users',
  clicked_reviewed_sub_orders: 'reviewed_sub_orders',
  clicked_purchase_conversion_rate: 'purchase_conversion_rate',
  clicked_etc_purchased_orders_count: 'etc_purchased_orders_count'
};

const CHECKED_TO_CLICKED_COLUMNS_MAP = _.invert(CLICKED_TO_CHECKED_COLUMNS_MAP);

TargetSamplingMethod.isCheckedColumn = column =>
  column in CHECKED_TO_CLICKED_COLUMNS_MAP;

TargetSamplingMethod.isClickedColumn = column =>
  column in CLICKED_TO_CHECKED_COLUMNS_MAP;

TargetSamplingMethod.toCheckedColumn = column =>
  CLICKED_TO_CHECKED_COLUMNS_MAP[column] || column;

TargetSamplingMethod.toClickedColumn = column =>
  CHECKED_TO_CLICKED_COLUMNS_MAP[column] || column;

TargetSamplingMethod.report = function(samplingMethod, report) {
  return [this.POPUP_CLICKED, this.WIDGET_CLICKED].includes(samplingMethod)
    ? _.chain(report)
        .omitBy((_, k) => this.isCheckedColumn(k))
        .mapKeys((_, k) => this.toCheckedColumn(k))
        .value()
    : _.omitBy(report, (_, k) => this.isClickedColumn(k));
};

TargetSamplingMethod.column = function(samplingMethod, column) {
  return [this.POPUP_CLICKED, this.WIDGET_CLICKED].includes(samplingMethod)
    ? this.toClickedColumn(column)
    : this.toCheckedColumn(column);
};

export default TargetSamplingMethod;
