import { constructEnum } from '@/lib/enum';

export default constructEnum('BatchJobStatus', {
  CREATED: 0,
  PENDING: 10,
  PROCESSING: 20,

  COMPLETE: 100,
  FAILED: 110,
  PARTIALLY_FAILED: 120,

  CANCELED_WHILE_PENDING: 200,
  CANCELED_WHILE_PROCESSING: 210,

  ERROR_INVALID_FILE: 1000,
  ERROR_SERVER: 1010
});
