<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppNumberInput v-model="valueBasic" :digits="2" />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Disabled</div>
      <AppNumberInput v-model="valueDisabled" :digits="2" disabled />
      <div class="ui-section__description">
        Selected Value: {{ valueDisabled }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Invalid</div>
      <AppNumberInput v-model="valueInvalid" :digits="2" invalid />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">10 digits</div>
      <AppNumberInput v-model="valueDigits" :digits="10" />
      <div class="ui-section__description">
        Selected Value: {{ valueDigits }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">With default 5</div>
      <AppNumberInput v-model="valueDefault" :digits="2" :default="5" />
      <div class="ui-section__description">
        Selected Value: {{ valueDefault }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Allow Negative</div>
      <AppNumberInput v-model="valueAllowNegative" :digits="3" allow-negative />
      <div class="ui-section__description">
        Selected Value: {{ valueAllowNegative }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Disallow Negative</div>
      <AppNumberInput
        v-model="valueDisallowNegative"
        :digits="3"
        :allow-negative="false"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueDisallowNegative }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Allow Decimal</div>
      <AppNumberInput v-model="valueAllowDecimal" :digits="3" allow-decimal />
      <div class="ui-section__description">
        Selected Value: {{ valueAllowDecimal }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Disallow Decimal</div>
      <AppNumberInput
        v-model="valueDisallowDecimal"
        :digits="3"
        :allow-decimal="false"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueDisallowDecimal }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiNumberInput',
  data() {
    return {
      valueBasic: 0,
      valueDisabled: 0,
      valueInvalid: 0,
      valueDigits: 1234567890,
      valueDefault: null,
      valueAllowNegative: -123,
      valueDisallowNegative: 0,
      valueAllowDecimal: 0,
      valueDisallowDecimal: 0
    };
  }
};
</script>
