<template>
  <div class="AppFormGroupDescriptionBox">
    <AppMarked class="AppFormGroupDescriptionBox__markdown" :text="message" />
  </div>
</template>

<script>
export default {
  name: 'AppFormGroupDescriptionBox',
  props: {
    message: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.AppFormGroupDescriptionBox {
  border: 1px solid $color-grey-25;
  border-radius: 3px;
  padding: 8px 16px;
}

.AppFormGroupDescriptionBox__markdown {
  @include text-content;
}
</style>
