var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AppPasswordInput"},[_c('AppTextInput',_vm._g(_vm._b({staticClass:"AppPasswordInput__input",attrs:{"type":_vm.isPasswordVisible ? 'text' : 'password',"no-trim":""}},'AppTextInput',{
      id: _vm.id,
      name: _vm.name,
      value: _vm.value,
      placeholder: _vm.placeholder,
      invalid: _vm.invalid,
      inputSize: _vm.inputSize,
      autocomplete: _vm.autocomplete,
      disabled: _vm.disabled
    },false),_vm.$listeners)),_c('button',{staticClass:"AppPasswordInput__show-hide-button",attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}}},[_c('AppSvgIcon',{staticClass:"AppPasswordInput__show-hide-icon",attrs:{"name":_vm.isPasswordVisible ? 'icon-password-show' : 'icon-password-hide'}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }