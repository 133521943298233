<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{ ...formProps, objectId: 'smart_installation' }"
    :is-loading="isLoading"
    @change-group="formGroupChanged"
    @blur-group="formGroupBlurred"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="smart_installation[installation_type]"
      :value="installation_type"
    />
    <input
      type="hidden"
      name="smart_installation[widget_id]"
      :value="widgetId"
    />
    <template #group="{ id, errors }">
      <template v-if="id === 'install_position_specific'">
        <div class="AppForm__group-field">
          <AppTextInput
            v-model="formObject.element_selector"
            class="ReviewInstallSimulationAddAttachWidgetFormDialog__element-selector"
            name="smart_installation[element_selector]"
            :placeholder="
              $t('smart_installations.placement.specific.placeholder')
            "
            :invalid="!!errors.install_position_specific"
            @change="validateField('install_position_specific')"
            @blur="validateField('install_position_specific')"
          />
          <AppSelect
            v-model="formObject.install_position"
            class="ReviewInstallSimulationAddAttachWidgetFormDialog__install-position"
            name="smart_installation[install_position]"
            :options="WidgetInsertType.options()"
          />
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import SmartInstallationFormView from '@/views/review/smart_installation/mixins/SmartInstallationFormView';
import WidgetType from '@/enums/WidgetType';
import WidgetStyle from '@/enums/WidgetStyle';
import WidgetDeviceType from '@/enums/WidgetDeviceType';

export default {
  name: 'ReviewInstallSimulationAddAttachWidgetFormDialog',
  mixins: [SmartInstallationFormView],
  data() {
    return {
      widgetId: null,
      widgets: []
    };
  },
  computed: {
    widget() {
      return this.widgets.find(w => w.id === this.widgetId);
    },
    groupIds() {
      const groupIds = ['select_widget'];
      if (this.widgetId) return groupIds.concat(this.baseGroupIds);

      return groupIds;
    },
    groupOptions() {
      const selectWidget = {
        select_widget: {
          label: this.$t('select_widget.label'),
          type: 'select_filterable',
          placeholder: this.$t('select_widget.placeholder'),
          options: this.selectWidgetOptions,
          validate: ['required'],
          required: true
        }
      };

      return {
        ...selectWidget,
        ...this.baseGroupOptions
      };
    },
    selectWidgetOptions() {
      return this.widgets.map(widget => ({
        label: this.selectWidgetLabel(widget),
        value: widget.id
      }));
    },
    pageTypeOptions() {
      if (!this.widgetId) return [];

      return this.basePageTypeOptions;
    }
  },
  mounted() {
    api
      .get('/widgets')
      .then(({ data }) => {
        this.widgets = this.filterSmartInstallableWidgets(data.widgets);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    submit(formData) {
      this.appendDefaultPlacement(formData);

      api
        .post('/install_simulation/simulations', formData, {
          successMessage: this.$t('app.created')
        })
        .then(({ data }) => {
          this.eventBus.$emit('focus-row', data.smart_installation.id);
          this.close(true);
        });
    },
    selectWidgetLabel(widget) {
      const {
        id,
        name,
        widget_style,
        widget_sub_style,
        widget_type,
        widget_device_type
      } = widget;

      const widgetInfo = {
        widget_id: id,
        widget_name: name,
        widget_type: WidgetType.t(widget_type),
        widget_device_type: WidgetDeviceType.t(widget_device_type)
      };

      if (
        [
          WidgetType.REVIEWS,
          WidgetType.PRODUCT_REVIEWS,
          WidgetType.CATEGORY_REVIEWS
        ].includes(widget_type)
      ) {
        widgetInfo['widget_style'] = `(${WidgetStyle.t(
          widget_sub_style || widget_style
        )})`;
      }

      return this.$t('select_widget.info', widgetInfo);
    },
    filterSmartInstallableWidgets(widgets) {
      return widgets.filter(w =>
        WidgetType.smartInstallableType.includes(w.widget_type)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.AppForm__group-field {
  display: flex;
}

.ReviewInstallSimulationAddAttachWidgetFormDialog__install-position {
  margin-left: 8px;
}
</style>

<i18n locale="ko">
{
  "title": "위젯 간편 설치 추가",
  "select_widget": {
    "label": "설치 위젯",
    "placeholder": "위젯을 선택해주세요.",
    "info": "ID {widget_id} / {widget_device_type} {widget_type}{widget_style} / \"{widget_name}\""
  }
}
</i18n>
