<template>
  <div class="ReviewSettingsAiSubSetting">
    <div class="AppForm__sub-group">
      <label
        :class="[
          'AppForm__sub-group-title',
          { 'AppForm__sub-group-title--disabled': disabled }
        ]"
      >
        {{ $t('title.label') }}
      </label>
    </div>
    <AppTextInput
      v-model="widget.ai_title"
      :invalid="!!errorAiTitle"
      :disabled="disabled"
      name="widget[ai_title]"
      @change="$emit('change-title')"
    />
    <AppFormHint :message="$t('title.hint')" />
    <AppFormError :error="errorAiTitle" />
    <div class="AppForm__sub-group">
      <label
        :class="[
          'AppForm__sub-group-title',
          { 'AppForm__sub-group-title--disabled': disabled }
        ]"
      >
        {{ $t('icon.label') }}
      </label>
      <AppImageInput
        id="ai-icon"
        name="widget[ai_icon]"
        :disabled="disabled"
        :image-url="widget.ai_icon"
        :caption="$t('icon.caption')"
        @change="$emit('change-icon', $event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewSettingsAiSubSetting',
  props: {
    widget: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorAiTitle: {
      type: Object,
      default: null
    }
  }
};
</script>

<i18n locale="ko">
{
  "icon": {
    "label": "아이콘",
    "caption": "svg, jpg, jpeg, png, gif 파일을 사용해주세요.(권장 사이즈: 18*18px)<br />이미지가 등록되어 있지 않은 경우 기본 아이콘<img src='https://assets.cre.ma/m/defaults/ai_icon.svg' style='vertical-align: middle' /> 이 표시됩니다."
  },
  "title": {
    "label": "제목",
    "hint": "입력하지 않으면 제목을 표시하지 않습니다."
  }
}
</i18n>
