<template>
  <AppDrawer
    :title="$t('title')"
    :sub-title="productDisplay.name"
    @close="close"
  >
    <template #body>
      <AppInfoBox class="DisplayProductDisplayCohortsDrawer__info">{{
        $t('info')
      }}</AppInfoBox>
      <AppAjaxContent :is-loading="isLoading">
        <AppSearchbar class="DisplayProductDisplayCohortsDrawer__search-bar">
          <template #right>
            <AppSelect
              v-model="resourceParams.corner_id"
              :options="cornerOptions"
              @change="searchResource"
            />
          </template>
        </AppSearchbar>
        <DisplayProductDisplayCohortsTable
          :cohorts="cohorts"
          :show-product-display-name="false"
          @update="searchResource"
          @paginate="paginate"
        />
      </AppAjaxContent>
    </template>
  </AppDrawer>
</template>

<script>
import { nullResources } from '@/lib/resources';
import api from '@/lib/api';
import DrawerView from '@/mixins/DrawerView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import DisplayProductDisplayCohortsTable from '@/views/display/components/DisplayProductDisplayCohortsTable';

export default {
  name: 'DisplayProductDisplayCohortsDrawer',
  components: { DisplayProductDisplayCohortsTable },
  mixins: [DrawerView, ResourceViewNoRoute],
  props: { productDisplay: { type: Object, required: true } },
  data: () => ({ isLoading: true, cohorts: nullResources, corners: [] }),
  computed: {
    defaultResourceParams() {
      return {
        per: '20',
        page: '1',
        display_id: this.productDisplay.id,
        corner_id: this.cornerId,
        search_with: 'display_id'
      };
    },
    cornerOptions() {
      return [{ label: this.$t('corner_options.default'), value: null }].concat(
        this.corners.map(corner => ({
          label: corner.name,
          value: corner.id
        }))
      );
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('display/product_display/cohorts', { params })
        .then(({ data }) => {
          this.corners = data.searchable_resources;
          this.cohorts = data.cohorts;
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style scoped>
.DisplayProductDisplayCohortsDrawer__info {
  margin-top: 36px;
}

.DisplayProductDisplayCohortsDrawer__search-bar {
  margin-top: 16px;
}
</style>

<i18n locale="ko">
{
  "title": "진열 이력",
  "info": "해당 진열 설정을 적용한 카테고리 진열 이력입니다.",
  "corner_options": {
    "default": "모든 카테고리 보기"
  }
}
</i18n>
