import InstallationPresetWidgetType from '@/enums/InstallationPresetWidgetType';
import WidgetPageType from '@/enums/WidgetPageType';

const state = { requestInstallations: [] };

const mutations = {
  SET_INSTALLATIONS(state, requestInstallations) {
    state.requestInstallations = requestInstallations;
  },
  SET_PAGE_TYPE_INSTALLATIONS(state, { pageType, requestInstallations }) {
    state.requestInstallations = [
      ...state.requestInstallations.filter(
        i =>
          i.pageType !== pageType ||
          i.presetWidgetType ===
            InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
      ),
      ...requestInstallations
    ];
  },
  SET_COUNT_AND_SCORE_WIDGET_INSTALLATIONS(state, { requestInstallations }) {
    state.requestInstallations = [
      ...state.requestInstallations.filter(
        i =>
          i.presetWidgetType !==
          InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
      ),
      ...requestInstallations
    ];
  }
};

const getters = {
  productDetailPageTypeRequests({ requestInstallations }) {
    return requestInstallations.filter(
      i =>
        i.pageType === WidgetPageType.PRODUCT_DETAIL &&
        i.presetWidgetType !==
          InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
    );
  },
  mainPageTypeRequests({ requestInstallations }) {
    return requestInstallations.filter(
      i =>
        i.pageType === WidgetPageType.MAIN &&
        i.presetWidgetType !==
          InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
    );
  },
  reviewBoardPageTypeRequests({ requestInstallations }) {
    return requestInstallations.filter(
      i =>
        i.pageType === WidgetPageType.REVIEW_BOARD &&
        i.presetWidgetType !==
          InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
    );
  },
  countAndScoreWidgetRequests({ requestInstallations }) {
    return requestInstallations.filter(
      i =>
        i.presetWidgetType ===
        InstallationPresetWidgetType.COUNT_AND_SCORE_WIDGET
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
