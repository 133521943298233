<template>
  <div class="DisplayProductsStatusCell">
    <AppBadge
      v-if="product.sold_out"
      class="DisplayProductsStatusCell__item"
      badge-style="red"
      :label="$t('products.sold_out')"
      :tooltip="$t('sold_out_badge.tooltip')"
    />
    <AppBadge
      v-if="displayStatusBadgeConfig"
      v-bind="displayStatusBadgeConfig"
      class="DisplayProductsStatusCell__item"
    />
    <div
      v-if="
        product.displayStatus !== 'undisplayed' ||
          product.displayedCorner ||
          product.scheduledCorner
      "
      class="DisplayProductsStatusCell__info DisplayProductsStatusCell__item"
    >
      <template v-if="product.displayStatus === 'displayed'">
        {{ $t('display_index', [product.index + 1]) }}
      </template>
      <template v-else-if="product.displayStatus === 'pinned'">
        {{ $t('display_index', [product.index + 1]) }}
        <div>
          {{ pinUntil ? $t('until', [formatDate(pinUntil)]) : $t('unlimited') }}
        </div>
        <div
          class="DisplayProductsStatusCell__link"
          @click="openPinProductDialog"
        >
          {{ $t('set_pin_until') }}
        </div>
      </template>
      <template v-else-if="product.displayStatus === 'excluded'">
        <div>
          {{
            excludeUntil
              ? $t('until', [formatDate(excludeUntil)])
              : $t('unlimited')
          }}
        </div>
        <div
          class="DisplayProductsStatusCell__link"
          @click="openExcludingProductDialog"
        >
          {{ $t('set_exclude_until') }}
        </div>
      </template>
      <template v-else-if="product.displayStatus === 'undisplayed'">
        <div v-if="product.displayedCorner">
          {{ $t('displayed_corner', [product.displayedCorner]) }}
        </div>
        <div v-else-if="product.scheduledCorner">
          {{ $t('scheduled_corner', [product.scheduledCorner]) }}
        </div>
      </template>
    </div>
    <AppBadge
      v-if="categoryStatusBadgeConfig"
      v-bind="categoryStatusBadgeConfig"
      class="DisplayProductsStatusCell__item"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DisplayProductsStatusCell',
  props: {
    product: { type: Object, required: true }
  },
  computed: {
    ...mapGetters('displayProductDisplay', [
      'productExcludeUntil',
      'productPinUntil',
      'addedProductIds',
      'removedProductIds',
      'cornerPreview'
    ]),
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    excludeUntil() {
      return this.productExcludeUntil(this.product);
    },
    pinUntil() {
      return this.productPinUntil(this.product);
    },
    badgeStyle() {
      switch (this.product.displayStatus) {
        case 'undisplayed':
          return 'grey-outline';
        case 'excluded':
          return 'red-outline';
        case 'pinned':
          return 'blue-outline';
        default:
          return 'default';
      }
    },
    isRemovedFromCategory() {
      return this.removedProductIds.includes(this.product.id);
    },
    displayStatusBadgeConfig() {
      if (this.isCategoryPage && this.isRemovedFromCategory) return null;

      return {
        badgeStyle: this.badgeStyle,
        label: this.$t(this.product.displayStatus)
      };
    },
    categoryStatusBadgeConfig() {
      if (!this.isCategoryPage) return null;

      const isAddedToCategory = this.addedProductIds.includes(this.product.id);
      const { name } = this.cornerPreview;

      if (isAddedToCategory)
        return {
          badgeStyle: 'blue-outline',
          tooltip: this.$t('category_status_badge.tooltip.added', [name]),
          label: this.$t('category_status_badge.label.added')
        };
      else if (this.isRemovedFromCategory)
        return {
          badgeStyle: 'red-outline',
          tooltip: this.$t('category_status_badge.tooltip.removed', [name]),
          label: this.$t('category_status_badge.label.removed')
        };
      else return null;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openPinProductDialog() {
      this.openDialog([
        'DisplayCornerPinProductDialog',
        { selectedProducts: [this.product] }
      ]);
    },
    openExcludingProductDialog() {
      this.openDialog([
        'DisplayCornerExcludingProductDialog',
        { selectedProducts: [this.product] }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayProductsStatusCell {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DisplayProductsStatusCell__item {
  & + & {
    margin-left: 0px;
    margin-top: 4px;
  }
}

.DisplayProductsStatusCell__info {
  @include text-caption-small;
}

.DisplayProductsStatusCell__link {
  text-decoration: underline;
  cursor: pointer;
}
</style>

<i18n locale="ko">
{
  "sold_out_badge": {
    "tooltip": "[진열 - 설정]의 '품절 상품 기준'에 따른 품절 상품입니다."
  },
  "undisplayed": "미진열",
  "displayed": "진열중",
  "excluded": "진열 제외",
  "pinned": "진열 고정",
  "display_index": "진열 위치: {0}",
  "until": "{0} 까지",
  "unlimited": "무기한",
  "set_exclude_until": "제외 기간 설정",
  "set_pin_until": "고정 기간 설정",
  "displayed_corner": "상위 진열 중: {0}",
  "scheduled_corner": "상위 예약 중: {0}",
  "category_status_badge": {
    "label": {
      "added": "카테고리 추가",
      "removed": "카테고리 제외"
    },
    "tooltip": {
      "added": "진열 진행 시 해당 상품을 {0}에 추가합니다.",
      "removed": "진열 진행 시 해당 상품을 {0}에서 제외합니다."
    }
  }
}
</i18n>
