import api from '@/lib/api';

const cache = {};

function checkEmailDuplicationCache(email) {
  if (!email) return true;
  return cache[email];
}

function checkEmailDuplication(email) {
  return new Promise(function(resolve, reject) {
    if (cache[email]) return resolve(cache[email]);

    api
      .get('/validations/check_email_duplication', { params: { email } })
      .then(({ data }) => {
        if (data.duplicated) {
          cache[email] = false;
          reject();
        } else {
          cache[email] = true;
          resolve();
        }
      })
      .catch(reject);
  });
}

export { checkEmailDuplicationCache, checkEmailDuplication };
