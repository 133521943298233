<template>
  <div class="AppInputGroupItem"><slot /></div>
</template>

<script>
export default {
  name: 'AppInputGroupItem'
};
</script>

<style lang="scss" scoped>
$inputs: 'input[type=text], input[type=number], select, button, label';

.AppInputGroupItem {
  display: inline-block;

  &:hover {
    ::v-deep {
      #{$inputs} {
        z-index: 1;
      }
    }
  }

  &:focus-within {
    ::v-deep {
      #{$inputs} {
        z-index: 2;
      }
    }
  }

  &:not(:first-child) {
    margin-left: -1px;

    ::v-deep {
      #{$inputs} {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &:not(:last-child) {
    ::v-deep {
      #{$inputs} {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
</style>
