<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <AppForm
      :id="SETTINGS_ID"
      v-bind="formProps"
      submit-button
      :object-id="objectId"
      v-on="formEvents"
    >
      <template #group-label="{ group, value, checked, errors }">
        <template v-if="group.id === 'sold_out_criteria'">
          <template v-if="value === DisplaySoldOutCriteria.SOLD_OUT">
            <i18n path="DisplaySoldOutCriteria.SOLD_OUT">
              {{ ShopBuilder.tk(currentBrand.shop_builder) }}
            </i18n>
          </template>
          <template
            v-else-if="value === DisplaySoldOutCriteria.PARTIALLY_SOLD_OUT"
          >
            <i18n path="DisplaySoldOutCriteria.PARTIALLY_SOLD_OUT">
              <AppNumberInput
                v-model="formObject.sold_out_threshold"
                :name="`${objectId}[sold_out_threshold]`"
                :disabled="!checked"
                :digits="3"
                :invalid="!!errors.sold_out_threshold"
                inline
                @change="validateField('sold_out_threshold')"
                @blur="validateField('sold_out_threshold')"
              />
              <AppNumberInput
                v-model="formObject.sold_out_least_options_count"
                :name="`${objectId}[sold_out_least_options_count]`"
                :disabled="!checked"
                :digits="3"
                :invalid="!!errors.sold_out_least_options_count"
                inline
                @change="validateField('sold_out_least_options_count')"
                @blur="validateField('sold_out_least_options_count')"
              />
            </i18n>
            <AppFormError
              :error="
                errors.sold_out_threshold || errors.sold_out_least_options_count
              "
            />
            <AppFormHint
              :message="
                $t(
                  'form.sold_out_criteria.sold_out_least_options_count_description'
                )
              "
            />
          </template>
        </template>
      </template>
      <template #group="{ id, value, errors }">
        <template v-if="id === 'reselling_criteria'">
          <AppCheckbox
            class="AppForm__group-field"
            :label="$t('form.reselling_criteria.default')"
            disabled
            checked
          />
          <div class="AppForm__group-field">
            {{ $t('form.reselling_criteria.resell_excluding_category_ids') }}
            <AppSelectProductCategory
              v-model="formObject.resell_excluding_category_ids"
              class="AppForm__group-field"
              :name="`${objectId}[resell_excluding_category_ids][]`"
              multiple
            />
          </div>
        </template>
        <template v-else-if="id === 'main_page_design_url'">
          <div class="AppForm__group-field">
            <AppSelectRadio
              v-model="formObject.sync_main_page_design_url"
              :name="`${objectId}[sync_main_page_design_url]`"
              :options="mainPageDesignUrlOptions"
            >
              <template #sub-item="{ value, checked }">
                <template v-if="value === true">
                  <AppFormHint
                    :message="$t('form.main_page_design_url.hint', [syncedUrl])"
                  />
                </template>
                <template v-else-if="value === false">
                  <AppTextInput
                    v-model="formObject.main_page_design_url"
                    :name="`${objectId}[main_page_design_url]`"
                    type="url"
                    :placeholder="$t('form.main_page_design_url.placeholder')"
                    :disabled="!checked"
                    :invalid="!!errors.main_page_design_url"
                    @change="validateField('main_page_design_url')"
                    @blur="validateField('main_page_design_url')"
                  />
                </template>
              </template>
            </AppSelectRadio>
          </div>
        </template>
        <template v-else-if="id === 'enable_displaying_category_corners'">
          <AppCheckbox
            v-model="formObject.enable_not_deleted_category_corners"
            class="AppForm__group-field AppRadioLabel__sub-item"
            :name="`${objectId}[enable_not_deleted_category_corners]`"
            :label="$t('form.enable_not_deleted_category_corners.label')"
            :disabled="!value"
          />
        </template>
        <template v-else-if="id === 'purchase_rate_filter_criteria'">
          <div class="AppForm__group-field">
            <i18n path="form.purchase_rate_filter_criteria.field">
              <AppSelect
                v-model="formObject.purchase_rate_filter_type"
                :name="`${objectId}[purchase_rate_filter_type]`"
                :options="purchaseRateFilterTypeOptions"
                inline
              />
              <AppNumberInput
                v-model="formObject.purchase_rate_filter_min_count"
                :name="`${objectId}[purchase_rate_filter_min_count]`"
                :digits="3"
                :invalid="!!errors.purchase_rate_filter_min_count"
                inline
                @change="validateField('purchase_rate_filter_min_count')"
                @blur="validateField('purchase_rate_filter_min_count')"
              />
            </i18n>
            <AppFormError :error="errors.purchase_rate_filter_min_count" />
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';
import TargetContentView from '@/mixins/TargetContentView';
import DisplaySoldOutCriteria from '@/enums/DisplaySoldOutCriteria';
import DisplayProductFilterType from '@/enums/DisplayProductFilterType';
import ShopBuilder from '@/enums/ShopBuilder';

export default {
  name: 'DisplaySettingsBasic',
  mixins: [FormView, SettingsView, TargetContentView],
  data: () => ({ isLoading: true }),
  computed: {
    ...mapState('session', ['currentBrand']),
    ShopBuilder: () => ShopBuilder,
    DisplaySoldOutCriteria: () => DisplaySoldOutCriteria,
    SETTINGS_ID() {
      return 'display_settings_basic';
    },
    objectId() {
      return 'display_settings';
    },
    groupOptions() {
      if (this.isLoading) return {};

      return {
        main_page_design_url: {
          groupDescription: this.$t('form.main_page_design_url.description'),
          validate: this.formObject.sync_main_page_design_url
            ? []
            : ['required', 'url_format']
        },
        sold_out_criteria: {
          groupDescription: this.$t('form.sold_out_criteria.description'),
          type: 'multiple_select_checkbox',
          options: DisplaySoldOutCriteria.options().map(option => ({
            ...option,
            disabled: option.value === DisplaySoldOutCriteria.SOLD_OUT
          })),
          fields: this.formObject.sold_out_criteria.includes(
            DisplaySoldOutCriteria.PARTIALLY_SOLD_OUT
          )
            ? [
                {
                  id: 'sold_out_threshold',
                  validate: [
                    'required',
                    { rule: 'min_max_integer', min: 0, max: 999 }
                  ]
                },
                {
                  id: 'sold_out_least_options_count',
                  validate: [
                    'required',
                    { rule: 'min_max_integer', min: 1, max: 999 }
                  ]
                }
              ]
            : []
        },
        purchase_rate_filter_criteria: {
          fields: [
            {
              id: 'purchase_rate_filter_min_count',
              validate: [
                'required',
                { rule: 'min_max_integer', min: 0, max: 999 }
              ]
            }
          ]
        },
        enable_displaying_category_corners: {
          groupDescription: this.$t(
            'form.enable_displaying_category_corners.description_html'
          ),
          groupTooltip: {
            message: this.$t('form.enable_displaying_category_corners.info'),
            textAlign: 'left'
          },
          type: 'checkbox'
        },
        logistics_settings_button: {
          type: 'button',
          groupDescription: this.$t(
            'form.logistics_settings_button.description'
          ),
          value: this.$t('form.logistics_settings_button.label'),
          clickHandler: () =>
            this.openDialog([
              'TheSettingsDialog',
              {
                initialTab: 'settings_dialog_user',
                elementId: 'logistics_settings'
              }
            ])
        }
      };
    },
    mainPageDesignUrlOptions() {
      return [
        {
          label: this.$t('form.main_page_design_url.sync'),
          value: true
        },
        {
          label: this.$t('form.main_page_design_url.input'),
          value: false
        }
      ];
    },
    syncedUrl() {
      return (
        this.formObject.synced_main_page_design_url ||
        this.$t('form.main_page_design_url.not_synced')
      );
    },
    purchaseRateFilterTypeOptions() {
      return [
        {
          label: this.$t('form.purchase_rate_filter_type.click_count'),
          value: DisplayProductFilterType.MOST_CLICKED
        },
        {
          label: this.$t('form.purchase_rate_filter_type.purchase_count'),
          value: DisplayProductFilterType.MOST_PURCHASED
        }
      ];
    }
  },
  mounted() {
    api
      .get('/display/settings')
      .then(({ data }) => (this.orgFormObject = data.settings))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/display/settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "form": {
    "main_page_design_url": {
      "description": "메인 페이지 진열 위치 동기화 및 중복 진열 방지를 위해 사용중인 메인 페이지 디자인 스킨 URL이 필요합니다.",
      "placeholder": "현재 사용 중인 디자인 스킨의 URL을 입력해 주세요.",
      "sync": "메인 페이지 진열 위치 동기화 시 자동으로 불러오기",
      "not_synced": "동기화 되지 않음",
      "input": "직접 입력",
      "hint": "불러온 디자인 스킨 URL: {0}"
    },
    "sold_out_criteria": {
      "description": "물류 솔루션 연동 시 옵션별 재고량은 물류 솔루션의 재고를 기준으로 판단합니다.",
      "sold_out_least_options_count_description": "설정 값보다 옵션 수가 적은 상품은 상품의 모든 옵션이 조건을 충족하면 선정합니다."
    },
    "reselling_criteria": {
      "default": "품절 상품 중 품절 상품 기준에서 벗어난 상품",
      "resell_excluding_category_ids": "재판매 상품 대상 제외 카테고리"
    },
    "purchase_rate_filter_criteria": {
      "field": "{0}{1}회 이상인 상품 중 클릭한 사람수 대비 주문 비율이 높은 상품"
    },
    "purchase_rate_filter_type": {
      "click_count": "상품 조회수",
      "purchase_count": "판매량"
    },
    "enable_displaying_category_corners": {
      "label": "쇼핑몰 솔루션에서 사용 중인 카테고리는 자동으로 진열 사용 설정",
      "description_html": "상품 카테고리 동기화 완료 시, 쇼핑몰 솔루션에서 사용 중인 카테고리는 자동으로 '진열 사용'으로 설정됩니다.<br />사용 되지 않는 카테고리는 자동으로 '진열 사용'이 해제됩니다.",
      "info": "쇼핑몰 솔루션에서 사용 중인 카테고리란?\n쇼핑몰에 표시(노출)하고 있는 상품 분류로 쇼핑몰 솔루션 관리자 페이지에서 설정할 수 있습니다.\n메이크샵 - '쇼핑몰 노출': 쇼핑몰에 노출함\n카페24 - '표시상태': on"
    },
    "enable_not_deleted_category_corners": {
      "label": "숨김 카테고리도 포함하여 진열 사용 설정"
    },
    "logistics_settings_button": {
      "description": "물류 솔루션으로부터 재고 정보를 연동하여 진열 및 상품 관리에 사용합니다.",
      "label": "물류 솔루션 재고 정보 연동 설정"
    }
  }
}
</i18n>
