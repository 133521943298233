<template>
  <AppAjaxContent :is-loading="isLoading">
    <DisplayCornerInfoBox
      :info-type="isDeleted ? 'deleted' : 'product_displays'"
      :corner="corner"
      :event-bus="eventBus"
    />
    <DisplayProductDisplayCreateButton
      v-if="!isDeleted"
      :corner-id="corner.id"
      :corner-name="corner.name"
      class="DisplayCornerProductDisplays__button"
    />
    <DisplayProductDisplaysTable
      :event-bus="eventBus"
      :corner="corner"
      class="DisplayCornerProductDisplays__table"
    />
  </AppAjaxContent>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import api from '@/lib/api';
import DisplayCornerStatus from '@/enums/DisplayCornerStatus';

export default {
  name: 'DisplayCornerProductDisplays',
  props: {
    eventBus: { type: Object, required: true },
    corner: { type: Object, required: true }
  },
  data: () => ({ isLoading: true, timer: null }),
  computed: {
    ...mapState('displayProductDisplays', ['cornerPageType', 'isPolling']),
    ...mapState('displayProductDisplay', ['isStatusUpdated']),
    isDeleted() {
      return DisplayCornerStatus.isDeleted(this.corner.corner_status);
    }
  },
  watch: {
    isStatusUpdated() {
      if (this.isStatusUpdated) this.fetchProductDisplays();
    },
    isPolling() {
      if (this.isPolling) this.startPolling();
      else this.stopPolling();
    }
  },
  mounted() {
    this.fetchProductDisplays();
  },
  created() {
    this.startPolling();
  },
  beforeDestroy() {
    this.stopPolling();
  },
  methods: {
    ...mapMutations('displayProductDisplays', ['SET_PRODUCT_DISPLAYS']),
    ...mapMutations('displayProductDisplay', ['UPDATE_IS_STATUS_UPDATED']),
    fetchProductDisplays() {
      api
        .get('/display/product_displays', {
          params: { page_type: this.cornerPageType }
        })
        .then(({ data }) => {
          this.SET_PRODUCT_DISPLAYS(data.product_displays);
          this.UPDATE_IS_STATUS_UPDATED(false);
        })
        .finally(() => (this.isLoading = false));
    },
    startPolling() {
      if (this.timer) return;
      this.timer = setInterval(() => this.fetchProductDisplays(), 5000);
    },
    stopPolling() {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
};
</script>

<style scoped>
.DisplayCornerProductDisplays__button {
  margin-top: 16px;
}

.DisplayCornerProductDisplays__table {
  margin-top: 32px;
}
</style>
