<template>
  <AppModalForm
    :title="localizedDialogStrings.title"
    :form-props="{
      ...formProps,
      isSubmitting: isLoading,
      submittingLabel: localizedDialogStrings.submitting_label,
      submitLabel: localizedDialogStrings.submit_label,
      closeLabel: $t('cancel_label'),
      detectFormDataChange: false
    }"
    v-on="formEvents"
    @close="close"
  />
</template>

<script>
import { mapActions } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import api from '@/lib/api';

export default {
  name: 'ReviewShopifyWidgetUninstallationReinstallationDialog',
  mixins: [DialogFormView],
  props: {
    isReinstallation: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    localizedDialogStrings() {
      return this.isReinstallation
        ? {
            title: this.$t('reinstall.title'),
            description_html: this.$t('reinstall.description_html'),
            submit_label: this.$t('reinstall.submit_label'),
            submitting_label: this.$t('reinstall.submitting_label'),
            success_message: this.$t('reinstall.success_message'),
            error_message: this.$t('reinstall.error_message')
          }
        : {
            title: this.$t('uninstall.title'),
            description_html: this.$t('uninstall.description_html'),
            submit_label: this.$t('uninstall.submit_label'),
            submitting_label: this.$t('uninstall.submitting_label'),
            success_message: this.$t('uninstall.success_message'),
            error_message: this.$t('uninstall.error_message')
          };
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: '',
              label: '',
              description: this.localizedDialogStrings.description_html
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapActions('dialog', ['openDialog']),
    submit() {
      this.isReinstallation ? this.submitReinstall() : this.submitUninstall();
    },
    submitUninstall() {
      this.isLoading = true;
      api
        .delete('/shopify/widget_installation', {
          successMessage: this.localizedDialogStrings.success_message,
          silent: true
        })
        .then(() => {
          this.close();
        })
        .catch(() => {
          this.createToast({
            message: this.localizedDialogStrings.error_message,
            isError: true
          });
        })
        .finally(() => (this.isLoading = false));
    },
    submitReinstall() {
      this.close();
      this.openDialog([
        'ReviewShopifyWidgetInitialInstallationDialog',
        {
          isReinstallation: true
        }
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "uninstall": {
    "title": "모든 위젯 삭제",
    "description_html": "현재 쇼핑몰에 설치된 모든 크리마 리뷰 위젯을 삭제합니다.<br/>정말 삭제하시겠습니까?",
    "submit_label": "삭제",
    "submitting_label": "삭제 중..",
    "success_message": "위젯 삭제를 완료했습니다.",
    "error_message": "위젯 삭제에 실패했습니다. 다시 시도해 주세요."
  },
  "reinstall": {
    "title": "기본 위젯 재설치",
    "description_html": "기본 위젯 재설치를 실행하면,<br/>기존 쇼핑몰에 설치되어 있는 모든 리뷰 위젯을 삭제 후 재설치 합니다.",
    "submit_label": "재설치",
    "submitting_label": "재설치 중..",
    "success_message": "위젯 재설치를 완료했습니다.",
    "error_message": "위젯 재설치에 실패했습니다. 다시 시도해 주세요."
  },
  "cancel_label": "취소"
}
</i18n>
