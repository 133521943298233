var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title_edit'),"sub-title":_vm.widget
      ? _vm.$t('sub_title', {
          widget_name: _vm.widget.name,
          widget_id: _vm.widgetId
        })
      : '',"form-props":Object.assign({}, _vm.formProps, {objectId: 'smart_installation'}),"is-loading":_vm.isLoading},on:{"change-group":_vm.formGroupChanged,"blur-group":_vm.formGroupBlurred},scopedSlots:_vm._u([{key:"group",fn:function(ref){
      var id = ref.id;
      var errors = ref.errors;
return [(id === 'install_position_specific')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppTextInput',{staticClass:"ReviewInstallSimulationAttachWidgetSettingsFormDialog__element-selector",attrs:{"name":"smart_installation[element_selector]","placeholder":_vm.$t('smart_installations.placement.specific.placeholder'),"invalid":!!errors.install_position_specific},on:{"change":function($event){return _vm.validateField('install_position_specific')},"blur":function($event){return _vm.validateField('install_position_specific')}},model:{value:(_vm.formObject.element_selector),callback:function ($$v) {_vm.$set(_vm.formObject, "element_selector", $$v)},expression:"formObject.element_selector"}}),_c('AppSelect',{staticClass:"ReviewInstallSimulationAttachWidgetSettingsFormDialog__install-position",attrs:{"name":"smart_installation[install_position]","options":_vm.WidgetInsertType.options()},model:{value:(_vm.formObject.install_position),callback:function ($$v) {_vm.$set(_vm.formObject, "install_position", $$v)},expression:"formObject.install_position"}})],1)]:_vm._e()]}}])},_vm.formEvents),[_c('input',{attrs:{"type":"hidden","name":"smart_installation[installation_type]"},domProps:{"value":_vm.installation_type}}),_c('input',{attrs:{"type":"hidden","name":"smart_installation[widget_id]"},domProps:{"value":_vm.widgetId}}),_c('input',{attrs:{"type":"hidden","name":"smart_installation[id]"},domProps:{"value":_vm.installationId}})])}
var staticRenderFns = []

export { render, staticRenderFns }