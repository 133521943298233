<template>
  <div class="ReviewMessagesCampaignsUpdatedAtCell">
    <div>{{ updatedAt }}</div>
    <AppBadge v-bind="{ label, tooltip }" />
  </div>
</template>

<script>
export default {
  name: 'ReviewMessagesCampaignsUpdatedAtCell',
  props: {
    updatedAt: { type: String, default: '' },
    createdAt: { type: String, default: '' }
  },
  computed: {
    label() {
      return this.$t('app.detail');
    },
    tooltip() {
      return {
        message: this.$t('tooltip', [this.updatedAt]),
        info: this.$t('tooltip_info', [this.createdAt])
      };
    }
  }
};
</script>

<i18n locale="ko">
{
  "tooltip": "최근 수정일: {0}",
  "tooltip_info": "등록일: {0}"
}
</i18n>
