<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{
      ...formProps,
      objectId,
      submitLabel: $t('form.submit')
    }"
    :width="DialogSize.MIDDLE"
    v-on="formEvents"
  >
    <template #head>
      <AppContainer>
        <AppDataLabel :label="$t('info_box.label')" />
        <div>
          <b>{{ $t('info_box.description1') }}</b>
        </div>
        <div>
          <b>{{ $t('info_box.description2') }}</b>
        </div>
        <div>{{ $t('info_box.description3') }}</div>
        <i18n path="info_box.description4_1">
          <span class="TheCorporateNumberChangeRequestDialog__info-highlight">{{
            $t('info_box.description4_2')
          }}</span>
        </i18n>
      </AppContainer>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';
import DialogFormView from '@/mixins/DialogFormView';

const OBJECT_ID = 'corporate_number_change_request';

export default {
  name: 'TheCorporateNumberChangeRequestDialog',
  mixins: [DialogFormView],
  computed: {
    DialogSize: () => DialogSize,
    objectId() {
      return OBJECT_ID;
    },
    formSections() {
      return [
        {
          id: 'basic_section',
          groups: [
            {
              id: 'corporate_number',
              type: 'text',
              validate: [
                'required',
                {
                  rule: v => /^\d+$/.test(v) && v.length === 10,
                  errorMessage: this.$t('form.corporate_number.error')
                }
              ]
            },
            {
              id: 'company_name',
              type: 'textarea',
              rows: 1,
              showByteSize: true,
              maxBytes: 70,
              validate: ['required', { rule: 'max_bytes', limit: 70 }]
            },
            {
              id: 'company_president_name',
              type: 'textarea',
              rows: 1,
              showByteSize: true,
              maxBytes: 30,
              validate: ['required', { rule: 'max_bytes', limit: 30 }]
            },
            {
              id: 'company_address',
              type: 'textarea',
              rows: 1,
              showByteSize: true,
              maxBytes: 150,
              validate: ['required', { rule: 'max_bytes', limit: 150 }]
            },
            {
              id: 'tax_invoice_officer_email',
              type: 'textarea',
              rows: 1,
              showMaxBytes: true,
              maxBytes: 40,
              validate: [
                'required',
                'email_format',
                { rule: 'max_bytes', limit: 40 }
              ]
            },
            { id: 'industry', type: 'text' },
            { id: 'industry_detail', type: 'text' },
            {
              id: 'company_code',
              type: 'textarea',
              rows: 1,
              showByteSize: true,
              maxBytes: 4,
              validate: [{ rule: 'max_bytes', limit: 4 }]
            }
          ].map(group => ({
            ...group,
            label: this.$t(`form.${group.id}.label`),
            placeholder: this.$t(`form.${group.id}.placeholder`)
          }))
        }
      ];
    }
  },
  mounted() {
    api
      .get('/brand_corporate_number_change_requests/last', {
        params: { include_rejected: true }
      })
      .then(
        ({ data }) =>
          (this.orgFormObject = data.corporate_number_change_request)
      );
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/brand_corporate_number_change_requests', formData, {
          successMessage: this.$t('requested')
        })
        .then(({ data }) => {
          this.close(true);
          this.eventBus.$emit(
            'requested',
            data.corporate_number_change_request
          );
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheCorporateNumberChangeRequestDialog__info-highlight {
  color: $color-red;
}
</style>

<i18n locale="ko">
{
  "title": "세금계산서 발급 자료 변경 요청",
  "info_box": {
    "label": "세금계산서 발급 안내",
    "description1": "세금계산서는 ‘세금계산서 발급 자료’에 등록되어 있는 정보로 발급됩니다.",
    "description2": "사업자등록증에 있는 정확한 정보를 기입해주세요.",
    "description3": "세금계산서는 결제 승인일의 익월 초(일반적인 경우 6~8일 사이)에 발행되며, 등록한 메일 주소로 자동 발송됩니다.",
    "description4_1": "‘세금계산서 발급 자료’는 {0}까지 등록해주세요.",
    "description4_2": "결제 승인일의 당월 말일"
  },
  "form": {
    "corporate_number": {
      "label": "사업자등록번호",
      "placeholder": "띄어쓰기나 - 없이 10자리 숫자만 입력해주세요.",
      "error": "잘못된 사업자등록번호 형식입니다. 띄어쓰기나 - 없이 10자리 숫자만 입력해주세요."
    },
    "company_name": {
      "label": "상호",
      "placeholder": "세금계산서를 발급 받을 회사명을 입력해주세요."
    },
    "company_president_name": {
      "label": "대표자명",
      "placeholder": "사업자등록증에 표기된 대표자 이름을 입력해주세요."
    },
    "company_address": {
      "label": "사업장 주소",
      "placeholder": "사업자 등록증에 표기된 사업장 주소를 입력해주세요."
    },
    "tax_invoice_officer_email": {
      "label": "세금계산서 담당자 이메일 주소",
      "placeholder": "이메일 주소를 입력해주세요."
    },
    "industry": {
      "label": "업태",
      "placeholder": "사업자 등록증에 표기된 업태를 입력해주세요."
    },
    "industry_detail": {
      "label": "종목",
      "placeholder": "사업자 등록증에 표기된 종목을 입력해주세요."
    },
    "company_code": {
      "label": "종사업장 번호",
      "placeholder": "종사업장 번호를 입력해주세요."
    },
    "submit": "변경 요청"
  },
  "requested": "사업자등록번호 변경을 요청했습니다."
}
</i18n>
