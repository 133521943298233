<template>
  <AppContainer>
    <AppInfoBox
      v-if="isPopupCampaign"
      class="TargetCampaignSettingsCoupon__popup-info-box"
      >{{ $t('popup_description') }}
    </AppInfoBox>
    <AppDataItem :label="$t('title')">
      <div class="table-head-control">
        <AppDropdownMenuButton
          :label="$t('add_coupon')"
          :menu-items="[
            { label: $t('create_coupon'), clickHandler: clickCreateCoupon },
            { label: $t('select_coupon'), clickHandler: clickSelectCoupon }
          ]"
        />
      </div>
      <AppSmallDeviceScrollable>
        <AppTable
          :columns="[
            { id: 'name', label: $t('app.name'), align: 'left' },
            {
              id: 'image',
              label: $t('app.image'),
              type: 'img',
              imgHeight: '56px'
            },
            {
              id: 'period',
              label: $t('period'),
              align: 'right',
              width: '264px'
            },
            { id: 'actions', label: $t('app.actions'), width: '200px' }
          ]"
          :rows="rows"
        >
          <template #cell="{ column, row }">
            <template v-if="column.id === 'period'">
              <div class="TargetCampaignSettingsCoupon__period">
                <template v-if="row.start_at && row.end_at">
                  {{ row.start_at | formatDateTimeWithoutSecond }} ~
                  {{ row.end_at | formatDateTimeWithoutSecond }}
                </template>
                <template v-else>
                  {{ $t('unlimited') }}
                </template>
              </div>
              <div class="TargetCampaignSettingsCoupon__status">
                <span>{{ $t(`coupon_status.${row.coupon_status}`) }}</span>
                <i
                  :class="[
                    'TargetCampaignSettingsCoupon__status-icon',
                    `TargetCampaignSettingsCoupon__status-icon--${row.coupon_status}`
                  ]"
                />
              </div>
            </template>
            <template v-else-if="column.id === 'actions'">
              <AppButtonDraggable
                :disabled="isArchivedCampaign"
                @sort="sortCoupons"
              />
              <AppDropdownMenuButton
                :label="$t('app.manage')"
                :disabled="isArchivedCampaign"
                :menu-items="[
                  {
                    label: $t('app.settings'),
                    clickHandler: e => clickEditCoupon(e, row.id)
                  },
                  {
                    label: $t('app.delete'),
                    style: 'danger',
                    clickHandler: e => clickUnselectCoupon(e, row.id)
                  }
                ]"
              />
            </template>
          </template>
        </AppTable>
      </AppSmallDeviceScrollable>
    </AppDataItem>
  </AppContainer>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import api from '@/lib/api';
import { arrayMoveIndex } from '@/lib/array';

export default {
  name: 'TargetCampaignSettingsCoupon',
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    ...mapGetters('targetCampaign', ['isPopupCampaign', 'isArchivedCampaign']),
    rows() {
      return this.campaign.selected_coupons.map(coupon => ({
        ...coupon,
        image: coupon.image_url
      }));
    }
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    ...mapActions('dialog', ['openDialog']),
    clickCreateCoupon({ close }) {
      this.openDialog([
        'TargetCampaignSettingsCouponFormDialog',
        { campaignId: this.campaign.id, campaignName: this.campaign.name }
      ]);
      close();
    },
    clickSelectCoupon({ close }) {
      this.openDialog([
        'TargetCampaignSettingsCouponSelectDialog',
        { campaignId: this.campaign.id, campaignName: this.campaign.name }
      ]);
      close();
    },
    clickEditCoupon({ close }, couponId) {
      this.openDialog([
        'TargetCampaignSettingsCouponFormDialog',
        {
          campaignId: this.campaign.id,
          campaignName: this.campaign.name,
          couponId
        }
      ]);
      close();
    },
    clickUnselectCoupon({ close }, coupon_id) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      this.SET_CAMPAIGN({
        ...this.campaign,
        selected_coupons: this.campaign.selected_coupons.filter(
          c => c.id !== coupon_id
        )
      });

      api.delete(`/target/campaigns/${this.campaign.id}/coupon_select`, {
        params: { coupon_id }
      });

      close();
    },
    sortCoupons({ srcIndex, dstIndex }) {
      const selected_coupons = arrayMoveIndex(
        this.campaign.selected_coupons,
        srcIndex,
        dstIndex
      );

      this.SET_CAMPAIGN({ ...this.campaign, selected_coupons });

      api.patch(
        `/target/campaigns/${this.campaign.id}/coupon_select/sort`,
        { coupon_ids: selected_coupons.map(c => c.id) },
        { successMessage: this.$t('sorted_coupon') }
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "캠페인에 노출할 쿠폰/이벤트",
  "add_coupon": "+ 쿠폰/이벤트 추가",
  "create_coupon": "새로운 쿠폰/이벤트 추가",
  "select_coupon": "기존 쿠폰/이벤트 불러오기",
  "sorted_coupon": "쿠폰/이벤트의 순서를 변경했습니다.",
  "period": "노출 기간",
  "unlimited": "무제한",
  "coupon_status": {
    "pending": "적용 전",
    "active": "적용 중",
    "expired": "기한만료"
  },
  "popup_description": "설정한 쿠폰/이벤트 이미지는 콘텐츠형 팝업에만 표시됩니다."
}
</i18n>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetCampaignSettingsCoupon__period {
  @include text-label;
}

.TargetCampaignSettingsCoupon__status {
  @include text-tag;
}

$icon-size: 8px;
.TargetCampaignSettingsCoupon__status-icon {
  margin-left: 4px;

  width: $icon-size;
  height: $icon-size;
  border-radius: $icon-size;

  &--pending {
    background-color: $color-mango;
  }

  &--active {
    background-color: $color-mint-green;
  }

  &--expired {
    background-color: $color-red;
  }
}

.TargetCampaignSettingsCoupon__popup-info-box {
  margin-bottom: 24px;
}
</style>
