const state = { translations: {}, original_key_map: {} };

const getters = {
  message: ({ translations }, _getters, { editor }, rootGetters) => (
    locale,
    key
  ) =>
    rootGetters.isSuperAdminAccessible && editor.enableI18nKey
      ? key
      : locale in translations
      ? translations[locale][key]
      : undefined,
  original_to_key: ({ original_key_map }) => key =>
    key in original_key_map ? original_key_map[key] : undefined
};

const mutations = {
  SET_TRANSLATIONS(state, translations) {
    state.translations = translations;
  },
  SET_ORIGINAL_KEY_MAP(state, original_key_map) {
    state.original_key_map = original_key_map;
  }
};

export default { namespaced: true, state, getters, mutations };
