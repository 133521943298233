import { constructEnum } from '@/lib/enum';

const TargetCalculateStatus = constructEnum('TargetCalculateStatus', {
  NONE: 0,
  PENDING: 10,
  CALCULATING: 20,
  COMPLETE: 100,
  SIMULATION: 150,
  SAMPLE: 160,
  ERROR: 200,
  ERROR_PREPARING: 210
});

TargetCalculateStatus.isError = function(calculateStatus) {
  return [this.ERROR, this.ERROR_PREPARING].includes(calculateStatus);
};

export default TargetCalculateStatus;
