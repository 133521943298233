<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{
      ...formProps,
      objectId: 'fit_transaction',
      detectFormDataChange: false,
      submitLabel: $t('app.save'),
      submittingLabel: $t('app.saving')
    }"
    v-on="formEvents"
  >
    <input type="hidden" name="fit_transaction[amount]" :value="amount" />
    <input
      type="hidden"
      name="fit_transaction[description]"
      :value="description"
    />
  </AppModalForm>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import FitTransactionType from '@/enums/FitTransactionType';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ThePaymentHistoryBonusFitDialog',
  mixins: [DialogFormView],
  data() {
    const { fitStatus } = this.$store.state.session;
    return {
      orgFormObject: {
        fit_event_type: FitTransactionType.PERIOD_BONUS,
        expiry_date: fitStatus?.fit_expires_at
          ? moment(fitStatus.fit_expires_at).toVal()
          : null
      }
    };
  },
  computed: {
    ...mapGetters('session', ['currencyKey']),
    FitTransactionType() {
      return FitTransactionType;
    },
    formSections() {
      const transactionType = this.formObject.fit_event_type;
      return [
        {
          id: 'default',
          groups: [
            {
              id: 'fit_event_type',
              label: this.$t('fit_transaction.fit_event_type'),
              type: 'select',
              options: [
                {
                  label: this.transactionTypeLabel(
                    FitTransactionType.PERIOD_BONUS
                  ),
                  value: FitTransactionType.PERIOD_BONUS
                }
              ]
            },
            {
              id: 'description',
              name: null,
              label: this.$t('fit_transaction.description'),
              type: 'text',
              placeholder: this.transactionTypeLabel(transactionType)
            },
            ...(transactionType === FitTransactionType.PERIOD_BONUS
              ? [
                  {
                    id: 'expiry_date',
                    label: this.$t('fit_transaction.expiry_date'),
                    type: 'date',
                    validate: [
                      'required',
                      {
                        rule: () => !!this.amount && this.amount > 0,
                        errorMessage: this.$t('expiry_date_should_be_after', [
                          this.orgFormObject.expiry_date
                        ])
                      }
                    ]
                  }
                ]
              : [])
          ]
        }
      ];
    },
    amount() {
      return moment(this.formObject.expiry_date).diff(
        moment(this.orgFormObject.expiry_date),
        'days'
      );
    },
    description() {
      const prefix =
        this.formObject.description ||
        this.transactionTypeLabel(this.formObject.fit_event_type);
      const postfix = this.amount
        ? ` (${this.$t('unit.days', [this.amount])})`
        : '';
      return `${prefix}${postfix}`;
    }
  },
  methods: {
    ...mapMutations('session', ['SET_FIT_STATUS']),
    transactionTypeLabel(transactionType) {
      return FitTransactionType.t(transactionType);
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/payment_history/fit_transactions', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          if (data.fit_status) this.SET_FIT_STATUS(data.fit_status);
          this.eventBus.$emit('update', data);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>
<i18n locale="ko">
{
  "title": "충전하기",
  "sub_title": "핏 이용내역",
  "expiry_date_should_be_after": "{0} 이후의 날짜를 지정해야 합니다.",
  "fit_transaction": {
    "fit_event_type": "항목 선택",
    "description": "메시지 입력",
    "expiry_date": "만료일 변경"
  }
}
</i18n>
