<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppColorPicker id="basic" v-model="valueBasic" />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="right">Right Aligned</label>
      </div>
      <div class="ui-section__item ui-section__item--right">
        <AppColorPicker id="right" v-model="valueRight" />
        <div class="ui-section__description">
          Selected Value: {{ valueRight }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiColorPicker',
  data() {
    return {
      valueBasic: null,
      valueRight: 'red'
    };
  }
};
</script>
