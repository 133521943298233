<template>
  <AppSummaryBox class="SmartstoreSummaryBox">
    <template #title>{{ $t('title') }}</template>
    <AppMarked :text="$t('message')" />
    <template #buttons>
      <a class="SmartstoreSummaryBox__popup_button" @click="clickDetail">
        {{ $t('detail.label') }}
      </a>
      <a
        class="SmartstoreSummaryBox__popup_button"
        @click="clickOpenPricingDialog"
      >
        {{ $t('price_table.label') }}
      </a>
    </template>
  </AppSummaryBox>
</template>

<script>
import { mapActions } from 'vuex';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'SmartstoreSummaryBox',
  methods: {
    ...mapActions('dialog', ['alert', 'openDialog']),
    clickDetail() {
      this.alert({
        title: this.$t('detail.label'),
        message: this.$t('detail.message'),
        width: DialogSize.MIDDLE
      });
    },
    clickOpenPricingDialog() {
      this.openDialog('SmartstorePricingDialog');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.SmartstoreSummaryBox__popup_button {
  color: $color-grey-40;
  display: block;
  text-decoration: underline;
}
</style>

<i18n locale="ko">
{
  "title": "🙋‍♀️ 해당 부가 서비스는 유료로 제공하는 서비스입니다.",
  "message": "스마트스토어에 있는 과거 리뷰는 가져올 리뷰수에 따라 비용이 청구되며, 새롭게 쌓이는 매일 리뷰는 기간별로 비용을 청구합니다.<br />해당 상품은 크리마 리뷰의 부가서비스이므로 크리마 리뷰를 사용하는 경우에만 사용할 수 있습니다.",
  "detail": {
    "label": "스마트스토어 연동 기능이란?",
    "message": "네이버 스마트스토어에 있는 리뷰를 크리마 리뷰로 일방향 연동해 자사몰에 노출시켜주는 기능입니다.<br />네이버 스마트스토어 운영 중 자사몰을 오픈했거나, 자사몰보다 스마트스토어에 리뷰가 더 많이 쌓이나요?<br />리뷰 연동을 통해 자사몰의 부족한 리뷰를 빠르게 확보해보세요! 구매 전환율도 쑥 올라갈 거예요.<br /><br /> ![](https://assets.cre.ma/m/v1/smartstore_sync_detail.png)"
  },
  "price_table": {
    "label": "연동 비용 기준 보기"
  }
}
</i18n>
