const state = { pinnedReviews: [] };

const mutations = {
  SET_PINNED_REVIEWS(state, reviews) {
    state.pinnedReviews = reviews;
  },
  UNPIN_REVIEW(state, reviewId) {
    const index = state.pinnedReviews.findIndex(r => r.id === reviewId);
    state.pinnedReviews.splice(index, 1);
  }
};

const getters = {
  pinnedReviews(state) {
    return state.pinnedReviews;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
