import { constructEnum } from '@/lib/enum';

const ReviewBoardInstallOptionType = constructEnum(
  'ReviewBoardInstallOptionType',
  {
    REPLACE_PAGE: 10,
    REPLACE_PAGE_EXCEPT_TITLE: 11,
    REPLACE_PAGE_EXCEPT_TITLE_AND_IMAGE: 12
  }
);

export default ReviewBoardInstallOptionType;
