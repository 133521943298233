<template>
  <AppButton
    :label="$t('button.add_product_display')"
    button-style="red"
    @click="openCreateDialog"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DisplayProductDisplayCreateButton',
  props: {
    cornerId: { type: Number, default: null },
    cornerName: { type: String, default: null }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openCreateDialog() {
      const { cornerId, cornerName } = this;
      this.openDialog([
        'DisplayProductDisplayCreateDialog',
        { cornerId, cornerName }
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "button": {
    "add_product_display": "진열 설정 추가"
  }
}
</i18n>
