<template>
  <div
    v-tooltip="tooltip"
    :class="[
      'DisplaySyncProductsButton',
      buttonDisabled ? 'DisplaySyncProductsButton--synchronizing' : ''
    ]"
  >
    <button
      class="DisplaySyncProductsButton__label"
      :disabled="buttonDisabled"
      @click="syncResource"
    >
      <template v-if="buttonDisabled">
        <span>{{ $t('label.default') }}</span
        ><span
          class="DisplaySyncProductsButton__content DisplaySyncProductsButton__content--synchronizing"
          >{{ $t('label.synchronizing') }}</span
        >
      </template>
      <template v-else>
        <span>{{ $t('label.default') }}</span
        ><span class="DisplaySyncProductsButton__content">{{
          syncInfo.synced_at | formatDateTimeWithoutSecond
        }}</span>
      </template>
    </button>
    <button
      class="DisplaySyncProductsButton__refresh-icon"
      :disabled="buttonDisabled"
      @click="syncResource"
    >
      <AppSvgIcon name="icon-refresh-small" :height="18" />
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BrandSyncType from '@/enums/BrandSyncType';
import ButtonSyncable from '@/mixins/ButtonSyncable';
import BrandSyncStatus from '@/enums/BrandSyncStatus';

export default {
  name: 'DisplaySyncProductsButton',
  mixins: [ButtonSyncable],
  props: { syncProductsNow: { type: Boolean, default: false } },
  computed: {
    brandSyncType() {
      return BrandSyncType.PRODUCT_DATA;
    }
  },
  watch: {
    syncInfo() {
      this.$emit('update-product-synced-at', this.syncInfo?.synced_at);
    },
    syncProductsNow() {
      if (!this.syncProductsNow) return;

      switch (this.syncInfo.sync_status) {
        case BrandSyncStatus.IDLE:
          this.doSyncResource();
          return;
        case BrandSyncStatus.IN_PROGRESS:
          this.alert({
            title: this.$t('sync_products_now.in_progress.title'),
            message: this.$t('sync_products_now.in_progress.message_html')
          });
      }
      this.$emit('sync-products-now-updated');
    }
  },
  methods: mapActions('dialog', ['alert'])
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_buttons.scss';

.DisplaySyncProductsButton {
  cursor: pointer;
  height: 40px;

  &--synchronizing {
    cursor: default;
  }
}

.DisplaySyncProductsButton__label {
  vertical-align: sub;
  display: inline-block;
}

.DisplaySyncProductsButton__content {
  margin-left: 8px;

  &--synchronizing {
    @include text-table-sub-head;
  }
}

.DisplaySyncProductsButton__refresh-icon {
  @include svg-button-container;

  margin-left: 8px;

  &:disabled {
    opacity: 0.5;
  }
}
</style>

<i18n locale="ko">
{
  "name": "상품 정보",
  "label": {
    "default": "상품 정보 동기화:",
    "synchronizing": "동기화 진행 중..."
  },
  "sync_products_now": {
    "in_progress": {
      "title": "상품 재고 동기화를 실행 할 수 없습니다.",
      "message_html": "현재 진행 중인 상품 정보 동기화가 있어 상품 재고 동기화를 실행할 수 없습니다.\n지금 진행 중인 상품 정보 동기화 완료 후 다시 상품 정보 동기화를 실행 하시면 상품 재고 동기화를 함께 실행합니다."
    }
  }
}
</i18n>
