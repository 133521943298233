<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{
      ...formProps,
      submitLabel: $t('app.confirmed')
    }"
    v-on="formEvents"
  >
    <template #head>
      <div class="TargetRequestCustomerServiceDialog__head">
        <pre>{{ $t('description') }}</pre>
      </div>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'TargetRequestCustomerServiceDialog',
  mixins: [DialogFormView],
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'user_name',
              label: this.$t('user_name.label'),
              placeholder: this.$t('user_name.placeholder'),
              type: 'text',
              required: true,
              validate: ['required']
            },
            {
              id: 'user_phone',
              label: this.$t('user_phone.label'),
              placeholder: this.$t('user_phone.placeholder'),
              type: 'tel',
              required: true,
              validate: ['required', 'korean_phone_format']
            },
            {
              id: 'user_email',
              label: this.$t('user_email.label'),
              placeholder: this.$t('user_email.placeholder'),
              type: 'email',
              required: true,
              validate: ['required', 'email_format']
            }
          ]
        }
      ];
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/target/request_customer_service', formData)
        .then(() => {
          this.eventBus.$emit('submit');
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetRequestCustomerServiceDialog__head {
  @include text-content;
  margin-bottom: 25px;
}
</style>

<i18n locale="ko">
{
  "title": "크리마 타겟 상담 신청",
  "description": "크리마 타겟에 관심 가져주셔서 감사합니다.\n아래 내용을 입력해 신청해주시면 크리마 타겟 담당자가 연락 드리겠습니다.",
  "user_name": {
    "label": "담당자 이름",
    "placeholder": "연락 받으실 담당자 명을 입력해주세요."
  },
  "user_phone": {
    "label": "연락가능한 전화번호",
    "placeholder": "연락 받으실 담당자의 전화번호를 입력해주세요."
  },
  "user_email": {
    "label": "이메일",
    "placeholder": "연락 받으실 담당자의 이메일 주소를 입력해주세요."
  }
}
</i18n>
