var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"width":"middle","title":_vm.brandNotice.id ? _vm.$t('title_edit_record') : _vm.$t('title_new_record'),"sub-title":_vm.orgBrandNotice.title,"form-props":Object.assign({}, _vm.formProps,
    {id: 'review_settings_notice',
    objectId: 'brand_notice',
    focusGroupId: 'title'})},scopedSlots:_vm._u([{key:"group",fn:function(ref){
  var id = ref.id;
return [(id === 'pc')?_c('AppSelectRadio',{class:[
        'AppForm__group-field',
        { 'AppForm__group-field--disabled': !_vm.brandNotice.pc }
      ],attrs:{"disabled":!_vm.brandNotice.pc,"name":"brand_notice[expanded]","options":_vm.OPTIONS},model:{value:(_vm.brandNotice.expanded),callback:function ($$v) {_vm.$set(_vm.brandNotice, "expanded", $$v)},expression:"brandNotice.expanded"}}):_vm._e()]}}])},_vm.formEvents),[_c('input',{attrs:{"type":"hidden","name":"brand_notice[id]"},domProps:{"value":_vm.brandNotice.id}})])}
var staticRenderFns = []

export { render, staticRenderFns }