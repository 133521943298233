<template>
  <button type="button" class="AppAdvancedSettingsButton" @click="click">
    <AppSvgIcon
      class="AppAdvancedSettingsButton__icon"
      name="icon-setting-small"
    />
    <span class="AppAdvancedSettingsButton__text">{{
      $t(isShowingAdvancedSetting ? 'hide' : 'show')
    }}</span>
  </button>
</template>

<script>
export default {
  name: 'AppAdvancedSettingsButton',
  model: { prop: 'isShowingAdvancedSetting', event: 'change' },
  props: { isShowingAdvancedSetting: { type: Boolean, required: true } },
  methods: {
    click() {
      this.$emit('change', !this.isShowingAdvancedSetting);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppAdvancedSettingsButton {
  height: 18px;
  line-height: 18px;

  &:hover {
    text-decoration: underline;
  }
}

.AppAdvancedSettingsButton__icon {
  vertical-align: middle;
}

.AppAdvancedSettingsButton__text {
  @include text-caption-dark;
  margin-left: 5px;
}
</style>

<i18n locale="ko">
{
  "show": "고급 설정 보기",
  "hide": "고급 설정 숨기기"
}
</i18n>

<i18n locale="en">
  {
    "show": "Show advanced settings",
    "hide": "Hide advanced settings"
  }
  </i18n>
