<template>
  <AppModalForm
    class="InstallRequestRenewalSlotCountAndScoreCustomDialog"
    :title="$t('title')"
    :form-props="formProps"
    v-on="formEvents"
  >
    <AppInfoBox> {{ $t('info') }} </AppInfoBox>
    <template #description="{ id }">
      <div
        v-if="id === 'pc_custom_comment' || id === 'mobile_custom_comment'"
        class="AppForm__description"
      >
        <AppInlineButton
          button-style="caption"
          :label="$t('attachment_guide')"
          @click="openAlertDialog"
        />
      </div>
    </template>
  </AppModalForm>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import DialogSize from '@/enums/DialogSize';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallSlotType from '@/enums/InstallSlotType';

export default {
  name: 'InstallRequestRenewalSlotCountAndScoreCustomDialog',
  mixins: [DialogFormView],
  computed: {
    ...mapState('installRequest', [
      'installDeviceTypes',
      'countAndScoreCustomDeviceTypes'
    ]),
    ...mapGetters('installRequest', ['currentDirections']),
    formSections() {
      return [
        this.installDeviceTypes.length > 1
          ? {
              id: 'target_device_types',
              groups: [
                {
                  id: 'target_device_types',
                  label: this.$t('target_device_types_label'),
                  type: 'multiple_select_checkbox',
                  options: this.installDeviceTypes.map(deviceType => ({
                    label: WidgetDeviceType.t(deviceType),
                    value: deviceType
                  })),
                  required: true
                }
              ]
            }
          : null,
        this.formObject.target_device_types?.includes(WidgetDeviceType.PC)
          ? {
              id: 'pc_direction',
              groups: [
                {
                  id: 'pc_custom_image_url',
                  label: this.$t('pc_label'),
                  required: true,
                  type: 'image',
                  placeholder: this.$t('attachment_placeholder'),
                  notRemovable: true,
                  validate: ['required']
                },
                {
                  id: 'pc_custom_comment',
                  label: null,
                  type: 'text',
                  placeholder: this.$t('comment_placeholder'),
                  validate: ['required']
                }
              ]
            }
          : null,
        this.formObject.target_device_types?.includes(WidgetDeviceType.MOBILE)
          ? {
              id: 'mobile_direction',
              groups: [
                {
                  id: 'mobile_custom_image_url',
                  label: this.$t('mobile_label'),
                  required: true,
                  type: 'image',
                  placeholder: this.$t('attachment_placeholder'),
                  notRemovable: true,
                  validate: ['required']
                },
                {
                  id: 'mobile_custom_comment',
                  label: null,
                  type: 'text',
                  placeholder: this.$t('comment_placeholder'),
                  validate: ['required']
                }
              ]
            }
          : null
      ].filter(s => s);
    }
  },
  created() {
    this.SET_CURRENT_PAGE_TYPE(WidgetPageType.PRODUCT_DETAIL);
    this.SET_CURRENT_SLOT_TYPES([InstallSlotType.COUNT_AND_SCORE_CUSTOM]);
    this.setTargetDeviceTypes();
    this.setDirection();
  },
  methods: {
    ...mapActions('dialog', ['alert']),
    ...mapMutations('installRequest', [
      'SET_CURRENT_PAGE_TYPE',
      'SET_CURRENT_SLOT_TYPES',
      'APPEND_DIRECTION',
      'REMOVE_CURRENT_SLOT_DIRECTIONS',
      'SET_COUNT_AND_SCORE_CUSTOM_DEVICE_TYPES'
    ]),
    customImageUrlKey(deviceType) {
      return `${WidgetDeviceType.key(
        deviceType
      ).toLowerCase()}_custom_image_url`;
    },
    customCommentKey(deviceType) {
      return `${WidgetDeviceType.key(deviceType).toLowerCase()}_custom_comment`;
    },
    openAlertDialog() {
      this.alert({
        title: this.$t('attachment_guide'),
        message: this.$t('alert_message'),
        width: DialogSize.MIDDLE,
        closeButtonStyle: 'default',
        closeButtonLabel: this.$t('app.close')
      });
    },
    setTargetDeviceTypes() {
      if (this.installDeviceTypes.length < 2) {
        this.$set(
          this.orgFormObject,
          'target_device_types',
          this.installDeviceTypes
        );
      } else {
        this.$set(
          this.orgFormObject,
          'target_device_types',
          this.countAndScoreCustomDeviceTypes
        );
      }
    },
    setDirection() {
      this.installDeviceTypes.forEach(deviceType => {
        const direction = this.currentDirections.find(
          dir => dir.device_type === deviceType
        );

        if (direction) {
          this.$set(
            this.orgFormObject,
            this.customImageUrlKey(deviceType),
            direction.custom_img_url
          );
          this.$set(
            this.orgFormObject,
            this.customCommentKey(deviceType),
            direction.custom_comment
          );
        }
      });
    },
    appendDirection(deviceType, customUrl, customComment) {
      if (customUrl) {
        this.APPEND_DIRECTION({
          device_type: deviceType,
          page_type: WidgetPageType.PRODUCT_DETAIL,
          slot: InstallSlotType.COUNT_AND_SCORE_CUSTOM,
          custom_img_url: customUrl,
          custom_comment: customComment
        });
      }
    },
    submit() {
      this.REMOVE_CURRENT_SLOT_DIRECTIONS();
      this.SET_COUNT_AND_SCORE_CUSTOM_DEVICE_TYPES(
        this.formObject.target_device_types
      );
      this.formObject.target_device_types.forEach(deviceType => {
        if (this.formObject[this.customImageUrlKey(deviceType)]) {
          this.appendDirection(
            deviceType,
            this.formObject[this.customImageUrlKey(deviceType)],
            this.formObject[this.customCommentKey(deviceType)]
          );
        }
      });
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppForm__section:first-child {
    padding: 32px 0 0;
  }

  .AppForm__section + .AppForm__section {
    border-top: none;
    padding: 32px 0 0;
  }

  .AppForm__group + .AppForm__group {
    margin-top: 8px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "위치 입력",
  "info": "상품 상세 페이지에 노출되는 리뷰 수 중 크리마 담당자가 찾기 어려운 위치에 있는 경우 알려주세요.",
  "target_device_types_label": "찾기 어려운 위치에 리뷰 수가 있는 곳을 모두 선택해주세요.",
  "pc_label": "PC 상품 상세 페이지 리뷰 수 위치",
  "mobile_label": "MOBILE 상품 상세 페이지 리뷰 수 위치",
  "attachment_placeholder": "특이한 위치에 있는 리뷰 수 이미지를 첨부해주세요.",
  "comment_placeholder": "크리마 팀이 참고할 코멘트가 있다면 남겨주세요.",
  "attachment_guide": "이미지 첨부 가이드",
  "alert_message": "아래 이미지와 같이 위젯을 설치하고 싶은 위치가 표시된 이미지를 첨부해주세요.<br><br>![](https://assets.cre.ma/m/admin/v2/install_request/attachment_guide_sample@3x.png)"
}
</i18n>
