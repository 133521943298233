<template>
  <div class="TheEasyJoinManagerInfo">
    <div class="TheEasyJoinManagerInfo__inner">
      <AppSvgIcon
        name="icon-crema-logo"
        :height="30"
        class="TheEasyJoinManagerInfo__logo"
      />
      <!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->
      <AppInfoBox
        class="TheEasyJoinManagerInfo__info-box"
        v-html="$t('info_html')"
      />
      <!-- eslint-enable vue/no-v-text-v-html-on-component vue/no-v-html -->
      <AppProgressSteps
        class="TheEasyJoinManagerInfo__progress-step"
        :steps="[
          $t('easy_join_progress_step.create_account'),
          $t('easy_join_progress_step.manager_info')
        ]"
        :current-step="1"
      />
      <AppForm
        id="easyJoinManagerInfo"
        object-id="user"
        v-bind="formProps"
        :submit-button="{
          submitLabel: $t('complete')
        }"
        v-on="formEvents"
        @blur-group="formGroupBlurred"
      />
    </div>
  </div>
</template>

<script>
import api from '@/lib/api';
import { mapActions } from 'vuex';
import FormView from '@/mixins/FormView';

export default {
  name: 'TheEasyJoinManagerInfo',
  mixins: [FormView],
  data() {
    return {
      detectFormDataChange: false
    };
  },
  computed: {
    formSections() {
      return [
        {
          id: 'manager_info_section',
          groups: [
            {
              id: 'name',
              label: this.$t('name.label'),
              type: 'text',
              placeholder: this.$t('name.placeholder'),
              validate: ['required'],
              required: true
            },
            {
              id: 'phone',
              label: this.$t('phone.label'),
              type: 'text',
              placeholder: this.$t('phone.placeholder'),
              validate: ['required', 'mobile_phone_format'],
              required: true
            }
          ]
        }
      ];
    }
  },
  methods: {
    formGroupBlurred(id) {
      if (id == 'phone') {
        const originPhone = this.formObject.phone;
        const newPhone = originPhone.replaceAll('-', '');
        this.formObject.phone = newPhone;
      }
    },
    ...mapActions('session', ['fetchSession', 'redirectToHome']),

    submit(formData) {
      this.isSubmitting = true;

      api
        .patch('/easy_join/user', formData, { silent: true })
        .then(() => {
          this.fetchSession()
            .then(() => this.redirectToHome())
            .finally(() => (this.isSubmitting = false));
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheEasyJoinManagerInfo {
  margin: 0 auto;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  text-align: center;
  padding-bottom: 25px;
}

.TheEasyJoinManagerInfo__inner {
  width: 500px;
  margin: 0 auto;
  position: relative;
}

.TheEasyJoinManagerInfo__logo {
  display: block;
  margin: 53px auto 40px;
  color: black;
}

.TheEasyJoinManagerInfo__info-box {
  margin-bottom: 32px;
}

.TheEasyJoinManagerInfo__progress-step {
  margin-bottom: 32px;
  padding-bottom: 12px;
  border-bottom: 1px solid $color-grey-25;
  text-align: left;
}
</style>

<i18n locale="ko">
{
  "info_html": "<b>계정 생성이 완료되었습니다.</b><br>마지막으로 크리마 리뷰 이용 중 도움이 필요하신 경우 <br>연락드릴 수 있는 담당자 정보를 입력해주세요.",
  "name": {
    "label": "담당자 이름",
    "placeholder": "담당자 이름을 입력해주세요."
  },
  "phone": {
    "label": "담당자 휴대전화 번호",
    "placeholder": "연락 가능한 휴대전화 번호를 입력해주세요."
  },
  "complete": "완료"
}
</i18n>
