export default {
  setCurrentPath({ commit }, { path, query, params }) {
    commit('SET_CURRENT_PATH', path);
    commit('SET_CURRENT_QUERY', query);
    commit('SET_CURRENT_PATH_PARAMS', params);
  },
  resetTitle({ commit, rootGetters }) {
    const itemId = rootGetters['menu/selectedSubMenuItemId'];
    const subMenuItem = itemId ? rootGetters['menu/menuItem'](itemId) : null;
    commit('SET_TITLE', subMenuItem?.label);
  }
};
