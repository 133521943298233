<template>
  <AppTabContent menu-item-id="target_reports_dispatch_results">
    <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
      <AppSearchbar>
        <template #right>
          <li>
            <AppSelectFilterable
              v-model="resourceParams.campaign_type"
              :options="campaignTypesOptions"
              :placeholder="$t('searchbar.campaign_type_placeholder')"
              @change="searchResource"
            />
          </li>
          <li>
            <AppDateRangePicker
              v-model="resourceParams"
              @update="searchResource"
            />
          </li>
        </template>
      </AppSearchbar>
      <Component
        :is="
          isDm
            ? 'TargetReportsDispatchResultsTableDm'
            : 'TargetReportsDispatchResultsTableRealtime'
        "
        :resources="cohorts"
        :rows="cohorts.items"
        @paginate="paginate"
      >
        <template #cell="{ row, column }">
          <template v-if="column === 'campaign'">
            <TargetCampaignNameCell
              :campaign-group-type="row.campaign_group_type"
              :campaign-name="row.campaign_name"
            />
          </template>
          <template v-else-if="column === 'dispatch_status'">
            <ul class="badges">
              <li v-if="row.splited">
                <AppBadge
                  badge-style="mango-outline"
                  :label="$t('dispatch_status.splited')"
                />
              </li>
              <li>
                <TargetDispatchStatusBadge
                  :campaign-type="row.campaign_type"
                  :dispatch-status="row.dispatch_status"
                />
              </li>
              <li v-if="row.is_not_enough_balance_sent_all">
                <AppBadge
                  :label="$t('dispatch_status.is_not_enough_balance_sent_all')"
                  badge-style="grey-outline"
                />
              </li>
              <li v-if="isNoRecipients(row)">
                <AppBadge
                  :label="$t('dispatch_status.no_recipients')"
                  badge-style="grey-outline"
                />
              </li>
              <li v-else-if="isAlternated(row)">
                <AppBadge
                  :label="$t('dispatch_status.alternated')"
                  badge-style="grey-outline"
                />
              </li>
              <li v-else-if="isPendingRecalculate(row)">
                <AppBadge
                  :label="$t('dispatch_status.pending_recalculate')"
                  badge-style="grey-outline"
                />
              </li>
            </ul>
          </template>
          <template v-else-if="column === 'actions'">
            <AppDropdownMenuButton
              v-if="isSuperAdminAccessible"
              class="super-admin__item"
              :label="$t('app.manage')"
              :disabled="!isActionButtonEnabled(row)"
              :menu-items="manageButtonMenuItems(row)"
            />
            <AppButton
              :label="$t('actions.detail_button.label')"
              :disabled="!isDetailButtonEnabled(row)"
              :tooltip="
                isOutdated(row)
                  ? $t('actions.detail_button.tooltip.outdated')
                  : ''
              "
              @click="clickDetailButton(row)"
            />
          </template>
        </template>
      </Component>
    </AppAjaxContent>
  </AppTabContent>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceView from '@/mixins/ResourceView';
import TargetContentView from '@/mixins/TargetContentView';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetDispatchStatus from '@/enums/TargetDispatchStatus';
import TargetCalculateStatus from '@/enums/TargetCalculateStatus';
import TargetCampaignDispatchType from '@/enums/TargetCampaignDispatchType';
import TargetDispatchStatusBadge from '../components/TargetDispatchStatusBadge';

export default {
  name: 'TargetReportsDispatchResults',
  components: {
    TargetDispatchStatusBadge
  },
  mixins: [ResourceView, TargetContentView],
  data: () => ({
    isLoading: true,
    cohorts: nullResources,
    campaignDispatchType: null
  }),
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('menu', ['selectedTabItemId']),
    TargetCampaignType: () => TargetCampaignType,
    TargetCampaignMediaType: () => TargetCampaignMediaType,
    campaignTypesOptions() {
      const types = TargetCampaignDispatchType.campaignMediaTypes(
        this.campaignDispatchType
      ).reduce(
        (types, campaignMediaType) =>
          types.concat(
            TargetCampaignType.fromCampaignMediaType(campaignMediaType)
          ),
        []
      );
      return TargetCampaignType.options(types);
    },
    isDm() {
      return (
        this.selectedCampaignDispatchType ===
        TargetCampaignDispatchType.DIRECT_MESSAGE
      );
    },
    selectedCampaignDispatchType() {
      return this.selectedTabItemId === 'target_reports_dispatch_results_dm'
        ? TargetCampaignDispatchType.DIRECT_MESSAGE
        : TargetCampaignDispatchType.REALTIME;
    }
  },
  mounted() {
    this.campaignDispatchType = this.selectedCampaignDispatchType;
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      const campaignDispatchType = this.selectedCampaignDispatchType;
      api
        .get('/target/cohorts', {
          params: { ...params, campaign_dispatch_type: campaignDispatchType }
        })
        .then(({ data }) => {
          this.cohorts = data.cohorts;
          this.campaignDispatchType = campaignDispatchType;
        })
        .finally(() => (this.isLoading = false));
    },
    isNoRecipients({
      dispatch_status,
      total_count,
      checks_count,
      campaign_type
    }) {
      const isWidget = TargetCampaignType.isProductRecommendationWidget(
        campaign_type
      );
      const count = isWidget ? checks_count : total_count;
      return dispatch_status === TargetDispatchStatus.COMPLETE && count === 0;
    },
    isAlternated(cohort) {
      return (
        TargetCampaignType.isProductRecommendationWidget(
          cohort.campaign_type
        ) && cohort.alternated
      );
    },
    isPendingRecalculate({ campaign_type, dispatch_status, calculate_status }) {
      return (
        TargetCampaignType.isProductRecommendationWidget(campaign_type) &&
        dispatch_status === TargetDispatchStatus.DISPATCHED &&
        TargetCalculateStatus.isError(calculate_status)
      );
    },
    isActionButtonEnabled(cohort) {
      return cohort.campaign_type !== TargetCampaignType.NEW_VISITOR_SIGNUP;
    },
    isDetailButtonEnabled(cohort) {
      if (this.isNoRecipients(cohort)) return false;
      if (this.isOutdated(cohort)) return false;

      return [
        TargetDispatchStatus.DISPATCHING,
        TargetDispatchStatus.DISPATCHED,
        TargetDispatchStatus.PAYING,
        TargetDispatchStatus.COMPLETE
      ].includes(cohort.dispatch_status);
    },
    isOutdated(cohort) {
      if (
        !TargetCampaignType.isProductRecommendationWidget(cohort.campaign_type)
      )
        return false;

      return moment(cohort.send_at).add('month', 3) < moment();
    },
    clickDetailButton({ id, campaign_name, campaign_type, splited }) {
      this.openDialog([
        'TargetReportsDispatchResultDialog',
        {
          cohortId: id,
          campaignName: campaign_name,
          campaignType: campaign_type,
          splited: !!splited
        }
      ]);
    },
    clickViewCalculateReport(id, campaign_name) {
      this.openDialog([
        'TargetCampaignCalculateReportDialog',
        {
          campaignName: campaign_name,
          cohortId: id
        }
      ]);
    },
    manageButtonMenuItems(dispatchResult) {
      const {
        id,
        campaign_name,
        campaign_type,
        dispatch_status
      } = dispatchResult;
      const isRealtime = !TargetCampaignType.isRealtime(campaign_type);
      const isDeletable =
        (TargetDispatchStatus.isError(dispatch_status) &&
          dispatch_status !==
            TargetDispatchStatus.ERROR_SOME_SUB_COHORTS_FAILED) ||
        this.isNoRecipients(dispatchResult);
      return [
        ...(isRealtime
          ? [
              {
                label: this.$t('manage_button_menu.calculate_reports'),
                clickHandler: ({ close }) => {
                  this.clickViewCalculateReport(id, campaign_name);
                  close();
                }
              },
              {
                label: this.$t('manage_button_menu.csv'),
                clickHandler: ({ close }) => {
                  this.openDialog([
                    'TargetCohortBrandCsvsDialog',
                    { cohortId: id, campaignName: campaign_name }
                  ]);
                  close();
                }
              }
            ]
          : []),
        {
          label: this.$t('manage_button_menu.history'),
          clickHandler: ({ close }) => {
            this.openDialog([
              'TargetCohortHistoriesDialog',
              { cohortId: id, campaignName: campaign_name }
            ]);
            close();
          }
        },
        isDeletable
          ? {
              label: this.$t('manage_button_menu.delete'),
              style: 'danger',
              clickHandler: ({ close }) => {
                if (!confirm(this.$t('app.delete_confirm'))) return;

                api.delete(`/target/cohorts/${id}`).then(() => {
                  this.cohorts = {
                    ...this.cohorts,
                    items: this.cohorts.items.filter(i => i.id !== id),
                    total_count: this.cohorts.total_count - 1
                  };
                });
                close();
              }
            }
          : null
      ].filter(Boolean);
    }
  }
};
</script>

<i18n locale="ko">
{
  "searchbar": {
    "campaign_type_placeholder": "전체 캠페인"
  },
  "table": {
    "id": "발송 ID",
    "send_at_realtime": "발송 시작일",
    "send_at_dm": "발송일",
    "campaign": "캠페인",
    "checks_count": "노출수",
    "total_count": "전체 발송수",
    "success_count": "성공 발송수",
    "failure_count": "실패 발송수",
    "dispatch_status": "상태"
  },
  "dispatch_status": {
    "splited": "분할발송",
    "no_recipients": "발송대상없음",
    "alternated": "대체항목노출",
    "is_not_enough_balance_sent_all": "포인트및캐시부족",
    "pending_recalculate": "재계산대기"
  },
  "manage_button_menu": {
    "calculate_reports": "발송 대상 보기",
    "csv": "CSV 보기",
    "history": "발송 이력 보기",
    "delete": "내역 삭제"
  },
  "actions": {
    "detail_button": {
      "label": "상세 내역",
      "tooltip": {
        "outdated": "상세 내역은 최대 3개월까지 제공합니다."
      }
    }
  }
}
</i18n>
