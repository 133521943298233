<template>
  <div>
    <TheLoginIdPassword
      v-if="!otpEmail"
      @otp-email-sent="
        otpEmail = $event.otpEmail;
        otpCode = $event.otpCode;
      "
    />
    <TheLoginOtp v-else :otp-email="otpEmail" :otp-code="otpCode" />
  </div>
</template>

<script>
import TheLoginIdPassword from './TheLoginIdPassword';
import TheLoginOtp from './TheLoginOtp';

export default {
  name: 'TheLogin',
  components: { TheLoginIdPassword, TheLoginOtp },
  data() {
    return { otpEmail: null, otpCode: null };
  }
};
</script>
