var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('textarea',_vm._b({ref:"textarea",class:['AppTextarea', { 'AppTextarea--invalid': _vm.invalid }],domProps:{"value":_vm.currentValue},on:{"input":function($event){return _vm.change($event.target.value)},"blur":_vm.trimAndBlur}},'textarea',{
      id: _vm.id,
      name: _vm.name,
      value: _vm.value,
      rows: _vm.rows,
      placeholder: _vm.placeholder,
      maxlength: _vm.maxlength,
      disabled: _vm.disabled,
      readonly: _vm.readonly,
      spellcheck: _vm.spellcheck
    },false)),_vm._v(" "),(_vm.maxBytes)?_c('div',{staticClass:"AppKeywordTextarea__bytes"},[_vm._v(" "+_vm._s((_vm.byteSize + "/" + _vm.maxBytes + " bytes"))+" ")]):(_vm.maxlength)?_c('div',{staticClass:"AppKeywordTextarea__length"},[_vm._v(" "+_vm._s((_vm.length + "/" + _vm.maxlength))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }