<template>
  <div class="ChatbotMessageTemplates">
    <div class="ChatbotMessageTemplates__title">
      {{ $t('title') }}
    </div>
    <AppGrid>
      <div
        v-for="(section, i) in sections"
        :key="i"
        class="AppGrid__col AppGrid__col--4"
      >
        <AppDataSection :title="section.title">
          <AppButton
            :label="$t('app.settings')"
            class="ChatbotMessageTemplates__settings-button"
            @click="section.onClick"
          />
          <div>{{ section.content }}</div>
        </AppDataSection>
      </div>
    </AppGrid>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'ChatbotMessageTemplates',
  computed: {
    ...mapState('chatbotMessageTemplate', ['selectedTemplateName']),
    ...mapGetters('chatbotMessage', [
      'isFirstChatTextDirty',
      'isLastChatTextDirty'
    ]),
    sections() {
      return [
        {
          title: this.$t('biz_message'),
          content: this.selectedTemplateName,
          onClick: this.onClickBizMessageSettings
        },
        {
          title: this.$t('first_message'),
          content: this.isFirstChatTextDirty
            ? this.$t('custom_message')
            : this.$t('default_message'),
          onClick: this.onClickFirstMessageSettings
        },
        {
          title: this.$t('last_message'),
          content: this.isLastChatTextDirty
            ? this.$t('custom_message')
            : this.$t('default_message'),
          onClick: this.onClickLastMessageSettings
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    onClickBizMessageSettings() {
      this.openDialog('ChatbotMessageTemplatesFormDialog');
    },
    onClickFirstMessageSettings() {
      this.openDialog('ChatbotMessageFirstMessageFormDialog');
    },
    onClickLastMessageSettings() {
      this.openDialog('ChatbotMessageLastMessageFormDialog');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ChatbotMessageTemplates__title {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 8px;
  color: $color-grey-50;
}

.ChatbotMessageTemplates__settings-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>

<i18n locale="ko">
{
  "title": "챗봇 메시지 설정",
  "biz_message": "첫 메시지 (알림톡)",
  "first_message": "작성 시작 메시지",
  "last_message": "마지막 메시지",
  "default_message": "기본 메시지",
  "custom_message": "커스텀 메시지"
}
</i18n>
