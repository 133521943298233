var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalDefault',{attrs:{"title":_vm.$t('title'),"sub-title":_vm.optionTypeName,"width":"middle"},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppSearchbar',{on:{"submit":_vm.searchResource},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('li',[_c('AppButton',{attrs:{"button-style":"red","label":_vm.$t('add_option_value')},on:{"click":_vm.addOptionValue}})],1)]},proxy:true},{key:"right",fn:function(){return [_c('li',[_c('AppSearchWithType',{attrs:{"search-types":[
                {
                  value: 'value',
                  label: _vm.$t('product_option_value.value')
                },
                {
                  value: 'parent_option_value',
                  label: _vm.$t('product_option_value.parent_option_value')
                }
              ]},model:{value:(_vm.resourceParams),callback:function ($$v) {_vm.resourceParams=$$v},expression:"resourceParams"}})],1)]},proxy:true}])}),_c('AppResourceTable',{attrs:{"table-id":"product-option-values-table","event-bus":_vm.tableEventBus,"resources":_vm.optionValues,"columns":_vm.COLUMNS,"rows":_vm.rows,"batch-buttons":_vm.batchButtons,"selected-row-ids":_vm.selectedOptionValueIds,"pers":[20, 50, 100, 200, 500]},on:{"paginate":_vm.paginate,"select-rows":_vm.selectRows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
              var row = ref.row;
              var column = ref.column;
return [(column === 'actions')?[_c('AppButtonDraggable',{attrs:{"drag-text":row.name,"disabled":!!_vm.orgResourceParams.search_query},on:{"sort":_vm.sort}}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"menu-items":[
                [
                  {
                    label: _vm.$t('edit_option_value'),
                    clickHandler: function (ref) {
                      var close = ref.close;

                      _vm.editOptionValue(row.id, row.value);
                      close();
                    }
                  }
                ],
                [
                  {
                    label: _vm.$t('app.delete'),
                    style: 'danger',
                    clickHandler: function (ref) {
                      var close = ref.close;

                      _vm.deleteOptionValue(row.id);
                      close();
                    }
                  }
                ]
              ]}})]:_vm._e()]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }