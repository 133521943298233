<template>
  <ul
    :class="['AppRadioList', cols === 1 ? null : `AppRadioList--${cols}cols`]"
  >
    <li
      v-for="(item, i) in items"
      :key="item.id"
      :class="{
        AppRadioList__item: true,
        'AppRadioList__item--inline': inline
      }"
    >
      <slot name="item" :item="item" :index="i" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AppRadioList',
  props: {
    items: { type: Array, required: true },
    cols: { type: Number, default: 1 },
    inline: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
.AppRadioList {
  display: flex;
  flex-wrap: wrap;
  margin-top: -4px;
}

.AppRadioList__item {
  width: 100%;
  margin-top: 4px;

  .AppRadioList--3cols & {
    width: percentage(calc(1 / 3));
  }

  &--inline {
    display: inline-block;
    width: auto;

    & + & {
      margin-left: 16px;
    }
  }
}
</style>
