<template>
  <div class="AppAjaxContent">
    <Transition name="fade" @enter="$store.state.isContentReady = true">
      <div v-if="isReady">
        <slot />
        <AppLoadingScreen
          class="AppAjaxContent__screen"
          :is-loading="isLoading"
          :style="{ width: screenWidth }"
          @leave="$store.state.isContentReady = true"
        />
      </div>
    </Transition>
    <div
      v-if="!isReady"
      class="AppAjaxContent__spinner-wrapper"
      :style="{ height }"
    >
      <AppSpinner class="AppAjaxContent__spinner" />
    </div>
  </div>
</template>

<script>
import AppLoadingScreen from './AppLoadingScreen';

export default {
  name: 'AppAjaxContent',
  components: { AppLoadingScreen },
  props: {
    isLoading: { type: Boolean, required: true },
    height: { type: String, default: '430px' },
    loadingScreenWidthFixed: { type: Boolean, default: false }
  },
  data() {
    return {
      isReady: !this.isLoading,
      screenWidth: 0
    };
  },
  watch: {
    isLoading() {
      if (this.isLoading) {
        this.setLoadingScreenWidth();
      } else {
        this.isReady = true;
        this.$emit('ready');
      }
    }
  },
  mounted() {
    if (this.isReady) this.$store.state.isContentReady = true;
  },
  methods: {
    setLoadingScreenWidth() {
      if (this.loadingScreenWidthFixed) {
        this.screenWidth = `${this.$el.offsetWidth + 32}px`;
      } else {
        this.$el.style.setProperty('display', 'inline-block');
        this.$el.style.setProperty('min-width', '100%');
        this.screenWidth = `${this.$el.offsetWidth}px`;
        this.$el.style.setProperty('display', '');
        this.$el.style.setProperty('min-width', '');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.AppAjaxContent {
  position: relative;
}

.AppAjaxContent__spinner-wrapper {
  text-align: center;
}

$spinner-size: 30px;
.AppAjaxContent__spinner {
  position: relative;
  top: 50%;
  margin-top: $spinner-size * -0.5;
  font-size: $spinner-size;
}

.AppAjaxContent__screen {
  right: auto;
}
</style>
