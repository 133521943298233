<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #right>
        <li>
          <AppSelect
            v-model="resourceParams.smart_installation_type"
            :options="SmartInstallationType.options()"
            :placeholder="$t('smart_installation_type.all')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppSelect
            v-model="resourceParams.log_type"
            :options="logTypeOptions"
            :placeholder="$t('log_type.all')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppSearchWithType
            v-model="resourceParams"
            :search-types="SEARCH_TYPES"
          />
        </li>
        <li>
          <AppDateRangePicker
            v-model="resourceParams"
            @update="searchResource"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppResourceTable table-id="logs-table" :columns="columns" :rows="rows">
      <template #cell="{row, column}">
        <template v-if="column === 'log_type'">
          <AppBadge v-bind="logBadge(row.log_type)" />
        </template>
        <template v-if="column === 'description'">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="row.description" />
          <!-- eslint-enable vue/no-v-html -->
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import SmartInstallationType from '@/enums/SmartInstallationType';
import escapeHtml from '@/lib/vendor/escapeHtml';
import { nullResources } from '@/lib/resources';
import api from '@/lib/api';
import LogType from '@/enums/LogType';

const ORDERED_NAMES = [
  'installation_type',
  'widget_info',
  'device_type',
  'page_type',
  'page_path',
  'skin_id',
  'reviews_count_display_format',
  'element_selector'
];

export default {
  name: 'TheLogsDialogSmartInstallation',
  mixins: [ResourceViewNoRoute],
  data() {
    return {
      isLoading: true,
      logs: nullResources,
      SEARCH_TYPES: [
        'actor_email',
        'actor_ip',
        'smart_installation_id',
        'widget_id'
      ].map(value => ({
        value,
        label: this.$t(`search_type.${value}`)
      }))
    };
  },
  computed: {
    defaultResourceParams: () => ({
      search_type: 'actor_email'
    }),
    SmartInstallationType: () => SmartInstallationType,
    logTypeOptions() {
      return LogType.options([
        LogType.CREATE_SMART_INSTALLATION,
        LogType.UPDATE_SMART_INSTALLATION,
        LogType.DESTROY_SMART_INSTALLATION
      ]);
    },
    columns() {
      return [
        { id: 'date', label: this.$t('column.date') },
        { id: 'actor', type: 'html', label: this.$t('column.actor') },
        {
          id: 'smart_installation_type',
          label: this.$t('column.smart_installation_type')
        },
        { id: 'log_type', label: this.$t('column.log_type') },
        {
          id: 'smart_installation_id',
          label: this.$t('column.smart_installation_id')
        },
        {
          id: 'description',
          label: this.$t('column.description'),
          align: 'left'
        }
      ];
    },
    rows() {
      return this.logs.items.map(log => {
        return {
          date: this.formatDateTime(log.created_at),
          actor: this.$t('actor_content_html', {
            actor_email: log.email,
            actor_ip: log.ip
          }),
          smart_installation_type: SmartInstallationType.t(
            log.smart_installation_type
          ),
          log_type: log.log_type,
          smart_installation_id: log.smart_installation_id,
          description: this.description(log)
        };
      });
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      const type = 'smart_installation';
      api
        .get('/logs', { params: { ...params, type } })
        .then(({ data }) => (this.logs = data.logs))
        .finally(() => (this.isLoading = false));
    },
    logBadge(logType) {
      return {
        label: LogType.t(logType),
        badgeStyle:
          logType === LogType.CREATE_SMART_INSTALLATION
            ? 'blue'
            : logType === LogType.UPDATE_SMART_INSTALLATION
            ? 'blue-outline'
            : 'red'
      };
    },
    description(row) {
      const orderedNames = this.orderedSettingsNameMap();
      const newSettings = row.new_setting_name_value_map;
      const oldSettings = row.old_setting_name_value_map;
      let descriptions = [];

      for (const name of orderedNames) {
        if (!newSettings[name] && !oldSettings[name]) {
          continue;
        }

        const oldValue = escapeHtml(oldSettings[name]);
        const newValue = escapeHtml(newSettings[name]);

        if (row.log_type === LogType.UPDATE_SMART_INSTALLATION) {
          const changed_value_text = [oldValue, newValue]
            .map(v => (v === '' ? 'none' : v))
            .filter(v => v)
            .join(' -> ');

          if (newSettings[name] === undefined) {
            descriptions.push(`${name}: ${oldValue}`);
          } else {
            descriptions.push(`${name}: ${changed_value_text}`);
          }
        } else {
          descriptions.push(`
          ${name}: ${
            row.log_type === LogType.CREATE_SMART_INSTALLATION
              ? newValue
              : oldValue
          }
          `);
        }
      }

      return descriptions.join('<br>');
    },
    orderedSettingsNameMap() {
      return ORDERED_NAMES.map(o => this.$t(`order_name.${o}`));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .logs-table__date {
    flex: 0 0 15%;
    min-width: 143px;
  }

  .logs-table__actor {
    flex: 0 0 15%;
    min-width: 143px;
  }

  .logs-table__smart-installation-type {
    flex: 0 0 16%;
    min-width: 152px;
  }

  .logs-table__log-type {
    flex: 0 0 8%;
    min-width: 76px;
  }

  .logs-table__smart-installation-id {
    flex: 0 0 8%;
    min-width: 76px;
  }

  .logs-table__description {
    flex: 0 0 38%;
    min-width: 280px;
  }
}
</style>

<i18n locale="ko">
{
  "actor_content_html": "%{actor_email}<br>%{actor_ip}",
  "smart_installation_type": {
    "all": "전체 설치 종류"
  },
  "log_type": {
    "all": "전체 동작"
  },
  "search_type": {
    "actor_email": "관리자 ID",
    "actor_ip": "관리자 IP",
    "smart_installation_id": "설치 ID",
    "widget_id": "위젯 ID"
  },
  "column": {
    "date": "날짜",
    "actor": "관리자",
    "smart_installation_type": "설치 종류",
    "log_type": "동작",
    "smart_installation_id": "설치 ID",
    "description": "상세 이력"
  },
  "order_name": {
    "installation_type": "관련 메뉴",
    "widget_info": "위젯 정보",
    "device_type": "디바이스",
    "page_type": "설치 페이지",
    "page_path": "특정 페이지",
    "skin_id": "적용 스킨",
    "reviews_count_display_format": "표시 정보",
    "element_selector": "설치 위치"
  }
}
</i18n>
