<template>
  <div>
    <DisplayCornerInfoBox
      :info-type="isDeleted ? 'deleted' : 'cohorts'"
      :corner="corner"
      :event-bus="eventBus"
    />
    <AppAjaxContent :is-loading="isLoading">
      <AppSearchbar class="DisplayCornerCohorts__search-bar">
        <template #right>
          <AppSelect
            v-model="resourceParams.display_id"
            :options="productDisplayOptions"
            @change="searchResource"
          />
        </template>
      </AppSearchbar>
      <DisplayProductDisplayCohortsTable
        :cohorts="cohorts"
        :corner-code="corner.code"
        @update="searchResource"
        @paginate="paginate"
      />
    </AppAjaxContent>
  </div>
</template>

<script>
import { nullResources } from '@/lib/resources';
import api from '@/lib/api';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import DisplayCornerStatus from '@/enums/DisplayCornerStatus';

export default {
  name: 'DisplayCornerCohorts',
  mixins: [ResourceViewNoRoute],
  props: {
    corner: { type: Object, required: true },
    productDisplayId: { type: Number, default: null },
    eventBus: { type: Object, required: true }
  },
  data() {
    return { isLoading: true, cohorts: nullResources, productDisplays: [] };
  },
  computed: {
    isDeleted() {
      return DisplayCornerStatus.isDeleted(this.corner.corner_status);
    },
    defaultResourceParams() {
      return {
        per: '20',
        page: '1',
        corner_id: this.corner.id,
        display_id: this.productDisplayId,
        search_with: 'corner_id'
      };
    },
    productDisplayOptions() {
      return [
        { label: this.$t('product_display_options.default'), value: null }
      ].concat(
        this.productDisplays.map(productDisplay => ({
          label: productDisplay.name,
          value: productDisplay.id
        }))
      );
    }
  },
  methods: {
    fetchResource(params) {
      this.$emit('reset-product-display-id');
      this.isLoading = true;
      api
        .get('display/product_display/cohorts', { params })
        .then(({ data }) => {
          this.productDisplays = data.searchable_resources;
          this.cohorts = data.cohorts;
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style scoped>
.DisplayCornerCohorts__search-bar {
  margin-top: 16px;
}
</style>

<i18n locale="ko">
{
  "product_display_options": {
    "default": "모든 진열 설정 보기"
  }
}
</i18n>
