import Vue from 'vue';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';

const FETCH_FIT_CSVS_REQUEST_ID = 'FETCH_FIT_CSVS';

const state = {
  csvs: nullResources
};

const mutations = {
  SET_FIT_CSVS(state, csvs) {
    state.csvs = csvs;
  },
  UPDATE_FIT_CSV({ csvs }, csv) {
    const index = state.csvs.items.findIndex(c => c.id === csv.id);
    if (index !== -1) Vue.set(csvs.items, index, csv);
  }
};

const actions = {
  fetchFitCsvs({ commit }, params) {
    api.cancel(FETCH_FIT_CSVS_REQUEST_ID);
    api
      .get('/fit/csvs', {
        requestId: FETCH_FIT_CSVS_REQUEST_ID,
        params
      })
      .then(function({ data }) {
        commit('SET_FIT_CSVS', data.csvs);
      });
  },
  resetFitCsvs({ commit }) {
    api.cancel(FETCH_FIT_CSVS_REQUEST_ID);
    commit('SET_FIT_CSVS', nullResources);
  },
  createFitCsv({ state, commit }, newFitCsv) {
    const csvs = state.csvs;
    commit('SET_FIT_CSVS', {
      ...csvs,
      items: [newFitCsv, ...csvs.items],
      total_count: csvs.total_count + 1
    });
  }
};

const getters = {
  isFetchingFitCsvs(_state, _getters, _rootState, rootGetters) {
    return rootGetters['request/isRequestPending'](FETCH_FIT_CSVS_REQUEST_ID);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
