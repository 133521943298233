<template>
  <div class="AnalyticsInsightKeywordsSummary AppGrid__col AppGrid__col--6">
    <AnalyticsInsightSection :title="title">
      <span class="AnalyticsInsightKeywordsSummary__sub-title">
        {{ $t('insight.review_tags_sentiments_ratio') }}
        <AppSvgIcon
          v-tooltip="$t('review_tags_sentiments_ratio_tooltip')"
          name="icon-info-tooltip"
          :width="10"
          :height="10"
        />
      </span>
      <div class="AnalyticsInsightKeywordsSummary__data">
        <span class="AnalyticsInsightKeywordsSummary__data-main">
          {{ sentimentsRatioSummary }}
        </span>
      </div>
      <AppChartStackedBar
        :data="stackedBarData"
        :tooltip="graphTooltip"
        class="AnalyticsInsightKeywordsSummary__graph"
      />
      <slot />
    </AnalyticsInsightSection>
  </div>
</template>

<script>
import InsightKeywordsView from '@/mixins/InsightKeywordsView';

export default {
  name: 'AnalyticsInsightKeywordsSummary',
  mixins: [InsightKeywordsView],
  props: {
    title: { type: String, required: true },
    dateRange: { type: Object, required: true },
    summary: { type: Object, required: true }
  },
  computed: {
    sentimentsRatioSummary() {
      if (this.sentimentsRatio(this.summary))
        return (
          this.$t('positive') + ' ' + this.sentimentsRatioString(this.summary)
        );
      else return this.$t('positive') + ' -%';
    },
    stackedBarData() {
      return [
        {
          label: this.$t('positive_keyword'),
          value: this.summary.psentiments_count
        },
        {
          label: this.$t('neutral_keyword'),
          value: this.summary.zsentiments_count
        },
        {
          label: this.$t('negative_keyword'),
          value: this.summary.nsentiments_count
        }
      ];
    },
    graphTooltip() {
      const { start_date, end_date } = this.dateRange;
      const dateString = `${this.formatDate(start_date)} ~ ${this.formatDate(
        end_date
      )}`;

      const sentimentsSum = this.summary.sentiments_sum;
      const pCount = this.summary.psentiments_count;
      const zCount = this.summary.zsentiments_count;
      const nCount = this.summary.nsentiments_count;
      const pPercentage = this.percentage(pCount, sentimentsSum);
      const zPercentage = this.percentage(zCount, sentimentsSum);
      const nPercentage = this.percentage(nCount, sentimentsSum);

      return {
        title: dateString,
        items: [
          {
            name: this.$t('positive_keyword'),
            value: `${this.$t('unit.percentage', [
              pPercentage
            ])} (${this.$t('unit.count', [pCount])})`
          },
          {
            name: this.$t('neutral_keyword'),
            value: `${this.$t('unit.percentage', [
              zPercentage
            ])} (${this.$t('unit.count', [zCount])})`
          },
          {
            name: this.$t('negative_keyword'),
            value: `${this.$t('unit.percentage', [
              nPercentage
            ])} (${this.$t('unit.count', [nCount])})`
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightKeywordsSummary {
  margin-top: 20px;

  @media (max-width: 719px) {
    &.AppGrid__col {
      margin-top: 20px;
    }
  }

  @media (min-width: 720px) and (max-width: 963px) {
    & {
      flex: unset;
      max-width: 100%;
    }

    & + & {
      margin-top: 16px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1247px) {
    & {
      flex: unset;
      max-width: 100%;
    }

    & + & {
      margin-top: 16px;
    }
  }
}

.AnalyticsInsightKeywordsSummary__sub-title {
  @include text-caption;
}

.AnalyticsInsightKeywordsSummary__data {
  margin-top: 4px;
}

.AnalyticsInsightKeywordsSummary__data-main {
  color: $color-grey-80;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.AnalyticsInsightKeywordsSummary__graph {
  margin-top: 16px;
}

@mixin highcharts-color($num, $color) {
  .highcharts-color-#{$num} {
    fill: $color;
    stroke: $color;
  }
}

::v-deep {
  @include highcharts-color(0, $color-mint-green);
  @include highcharts-color(1, $color-grey-25);
  @include highcharts-color(2, $color-red);
}
</style>

<i18n locale="ko">
{
  "review_tags_sentiments_ratio_tooltip": "리뷰에서 분석된 모든 키워드들에 대한 긍정/부정 평가 비율입니다.",
  "positive": "긍정",
  "positive_keyword": "긍정 키워드",
  "neutral_keyword": "중립 키워드",
  "negative_keyword": "부정 키워드"
}
</i18n>
