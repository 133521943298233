<template>
  <AppModalForm
    :title="$t(switchWidgetId ? 'title_edit' : 'title_new')"
    :sub-title="
      switchWidgetId
        ? $t('sub_title', {
            widget_name: switchWidget.name,
            widget_id: switchWidgetId
          })
        : ''
    "
    :form-props="{ ...formProps, objectId: 'switch_widget' }"
    :is-loading="isLoading"
    :can-maximize="useWidgetPreview"
    :default-left-min-width="766"
    :default-main-min-width="680"
    v-on="formEvents"
  >
    <template v-if="useWidgetPreview" #left>
      <ReviewWidgetPreview :preview-not-supported="true" />
    </template>
  </AppModalForm>
</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import WidgetType from '@/enums/WidgetType';
import ReviewWidgetPreview from '@/views/review/reviews/components/ReviewWidgetPreview.vue';

export default {
  name: 'ReviewSettingsWidgetsSwitcherFormDialog',
  components: { ReviewWidgetPreview },
  mixins: [DialogFormView],
  props: {
    switchWidgetId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isLoading: true,
      switchableWidgetsPc: [],
      switchableWidgetsMobile: [],
      useWidgetPreview: false
    };
  },
  computed: {
    switchWidget() {
      return this.formObject;
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'switch_widget_type',
              type: 'select_radio',
              options: WidgetType.options(WidgetType.switchableWidgetType),
              validate: ['required']
            },
            {
              id: 'name',
              groupDescription: this.$t('form.name_group_description'),
              type: 'text',
              validate: ['required']
            },
            {
              id: 'switch_pc_widget_id',
              type: 'select',
              placeholder: this.$t('form.select_widget'),
              options: this.switchableWidgetOptions(this.switchableWidgetsPc),
              validate: ['required']
            },
            {
              id: 'switch_mobile_widget_id',
              type: 'select',
              placeholder: this.$t('form.select_widget'),
              options: this.switchableWidgetOptions(
                this.switchableWidgetsMobile
              ),
              validate: ['required']
            }
          ].map(g => ({ ...g, label: this.$t(`form_group.${g.id}`) }))
        }
      ];
    }
  },
  watch: {
    'switchWidget.switch_widget_type'(newValue) {
      if (this.switchWidget.switch_pc_widget_id) {
        const switchableWidgetPc = this.switchableWidgetsPc.find(
          w => w.id === this.switchWidget.switch_pc_widget_id
        );
        if (!switchableWidgetPc || switchableWidgetPc.widget_type !== newValue)
          this.switchWidget.switch_pc_widget_id = null;
      }

      if (this.switchWidget.switch_mobile_widget_id) {
        const switchableWidgetMobile = this.switchableWidgetsMobile.find(
          w => w.id === this.switchWidget.switch_mobile_widget_id
        );
        if (
          !switchableWidgetMobile ||
          switchableWidgetMobile.widget_type !== newValue
        )
          this.switchWidget.switch_mobile_widget_id = null;
      }
    }
  },
  mounted() {
    this.isLoading = true;
    api
      .get(
        this.switchWidgetId
          ? `/review/switch_widgets/${this.switchWidgetId}/edit`
          : '/review/switch_widgets/new'
      )
      .then(({ data }) => {
        this.orgFormObject = data.switch_widget || {};
        this.switchableWidgetsPc = data.switchable_widgets_pc;
        this.switchableWidgetsMobile = data.switchable_widgets_mobile;
        this.useWidgetPreview = data.use_widget_preview;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('reviewWidgets', [
      'CREATE_REVIEW_WIDGET',
      'UPDATE_REVIEW_WIDGET'
    ]),
    switchableWidgetOptions(widgets) {
      return widgets
        .filter(w => w.widget_type === this.switchWidget.switch_widget_type)
        .map(w => ({
          value: w.id,
          label: w.name || this.$t('form.default_widget_name', w)
        }));
    },
    submit(formData) {
      this.isSubmitting = true;
      const requestConfig = this.switchWidgetId
        ? {
            url: `/review/switch_widgets/${this.switchWidgetId}`,
            method: 'patch',
            successMessage: this.$t('app.saved')
          }
        : {
            url: '/review/switch_widgets',
            method: 'post',
            successMessage: this.$t('app.created')
          };
      requestConfig.data = formData;
      api
        .request(requestConfig)
        .then(({ data }) => {
          this.switchWidgetId
            ? this.UPDATE_REVIEW_WIDGET(data.switch_widget)
            : this.CREATE_REVIEW_WIDGET(data.switch_widget) &
              this.eventBus.$emit('focus-row');
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_edit": "접속 환경별 위젯 설정",
  "title_new": "접속 환경별 위젯 추가하기",
  "sub_title": "{widget_name} (위젯 ID {widget_id})",
  "form_group": {
    "switch_widget_type": "위젯 선택",
    "name": "위젯 이름",
    "switch_pc_widget_id": "PC에서 표시할 위젯",
    "switch_mobile_widget_id": "모바일에서 표시할 위젯"
  },
  "form": {
    "select_widget": "위젯을 선택해주세요",
    "name_group_description": "관리자 페이지 내 위젯 목록에서 표시하는 이름입니다.",
    "default_widget_name": "%{id}번 위젯"
  }
}
</i18n>
