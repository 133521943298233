<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="cornerPreview.name"
    @close="close"
  >
    <template #body>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <AppInfoBox v-html="$t('info_html')" />
      <AppDataSection
        class="DisplayCornerRemoveProductDialog__product-info"
        :title="$t('product_info.title')"
        >{{ productsName }}</AppDataSection
      >
      <div class="DisplayCornerRemoveProductDialog__buttons">
        <AppButton
          v-if="isValid"
          :label="$t('button.remove')"
          button-style="red"
          @click="submit"
        />
        <AppButton :label="$t('app.close')" @click="close" />
      </div>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DisplayCornerHandleProductView from '@/mixins/DisplayCornerHandleProductView';

export default {
  name: 'DisplayCornerRemoveProductDialog',
  mixins: [DisplayCornerHandleProductView],
  computed: mapGetters('displayProductDisplay', ['cornerPreview']),
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapActions('displayProductDisplay', ['removeProducts']),
    submit() {
      const indices = this.selectedProducts.map(({ index }) => index);
      this.removeProducts(indices);
      this.createToast(
        this.$t('toast.removed', [this.selectedProducts.length])
      );
      this.eventBus.$emit('remove');
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayCornerRemoveProductDialog__product-info {
  margin-top: 24px;
}

.DisplayCornerRemoveProductDialog__buttons {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "info_html": "선택한 상품을 해당 카테고리에서 제외합니다.<br />진열 진행 시 카테고리에서 제외되며 제외된 상품은 해당 카테고리 페이지에 표시되지 않습니다.",
  "title": "카테고리 제외",
  "product_info": {
    "title": "카테고리 제외 상품"
  },
  "toast": {
    "removed": "{0}개의 상품을 카테고리 제외합니다."
  },
  "button": {
    "remove": "제외"
  }
}
</i18n>
