<template>
  <AppModalDefault
    :title="$t('dialog_title')"
    is-maximized-by-default
    @close="close"
  >
    <template #body>
      <div class="WidgetInstallRequestDialog__title">
        {{ $t('title') }}
      </div>
      <AppProgressSteps
        class="WidgetInstallRequestDialog__progress-step"
        :steps="steps"
        :current-step="widgetInstallRequestCurrentStep"
      />
      <div class="WidgetInstallRequestDialog__containter">
        <div
          v-if="shouldDisplayStepTitle"
          class="WidgetInstallRequestDialog__step-title"
        >
          {{ $t(`step_title.${widgetInstallRequestCurrentStep}`) }}
        </div>
        <AppInfoBox
          v-if="shouldDisplayInfoBox"
          class="WidgetInstallRequestDialog__info-box"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="$t(`info_content.${widgetInstallRequestCurrentStep}`)" />
          <!-- eslint-disable vue/no-v-html -->
        </AppInfoBox>
        <Component :is="currentStepComponent" />
      </div>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState } from 'vuex';
import DialogView from '@/mixins/DialogView';
import WidgetPageType from '@/enums/WidgetPageType';
import WidgetInstallRequestProgressStep from '@/enums/WidgetInstallRequestProgressStep';
import WidgetInstallRequestDialogProductDetail from './WidgetInstallRequestDialogProductDetail';
import WidgetInstallRequestDialogReviewBoard from './WidgetInstallRequestDialogReviewBoard';
import WidgetInstallRequestDialogMain from './WidgetInstallRequestDialogMain';
import WidgetInstallRequestDialogEtc from './WidgetInstallRequestDialogEtc';
import WidgetInstallRequestDialogCountAndScore from './WidgetInstallRequestDialogCountAndScore';
import WidgetInstallRequestDialogComplete from './WidgetInstallRequestDialogComplete';

export default {
  name: 'WidgetInstallRequestDialog',
  components: {
    WidgetInstallRequestDialogProductDetail,
    WidgetInstallRequestDialogReviewBoard,
    WidgetInstallRequestDialogMain,
    WidgetInstallRequestDialogEtc,
    WidgetInstallRequestDialogCountAndScore,
    WidgetInstallRequestDialogComplete
  },
  mixins: [DialogView],
  computed: {
    ...mapState('widgetInstallRequest', ['widgetInstallRequestCurrentStep']),
    WidgetPageType() {
      return WidgetPageType;
    },
    steps() {
      return WidgetInstallRequestProgressStep.values.map(
        WidgetInstallRequestProgressStep.t
      );
    },
    currentStepComponent() {
      switch (this.widgetInstallRequestCurrentStep) {
        case WidgetInstallRequestProgressStep.REVIEW_BOARD:
          return WidgetInstallRequestDialogReviewBoard;
        case WidgetInstallRequestProgressStep.MAIN:
          return WidgetInstallRequestDialogMain;
        case WidgetInstallRequestProgressStep.ETC:
          return WidgetInstallRequestDialogEtc;
        case WidgetInstallRequestProgressStep.COUNT_AND_SCORE:
          return WidgetInstallRequestDialogCountAndScore;
        case WidgetInstallRequestProgressStep.CONFIRM:
          return WidgetInstallRequestDialogComplete;
        default:
          return WidgetInstallRequestDialogProductDetail;
      }
    },
    shouldDisplayStepTitle() {
      return (
        this.widgetInstallRequestCurrentStep !==
        WidgetInstallRequestProgressStep.CONFIRM
      );
    },
    shouldDisplayInfoBox() {
      return [
        WidgetInstallRequestProgressStep.PRODUCT_DETAIL,
        WidgetInstallRequestProgressStep.REVIEW_BOARD,
        WidgetInstallRequestProgressStep.MAIN,
        WidgetInstallRequestProgressStep.ETC
      ].includes(this.widgetInstallRequestCurrentStep);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
::v-deep {
  .AppModal__head {
    background-color: #fff;
    border-bottom: 1px solid $color-grey-25;
  }
}

.WidgetInstallRequestDialog__title {
  @include text-title;
  text-align: center;
  margin: 28px 0;
}

.WidgetInstallRequestDialog__progress-step {
  justify-content: center;
  margin-bottom: 32px;
}

.WidgetInstallRequestDialog__containter {
  width: 900px;
  margin: 0 auto;
}

.WidgetInstallRequestDialog__step-title {
  @include text-title;
}

.WidgetInstallRequestDialog__info-box {
  margin: 12px 0 24px;
}
</style>

<i18n locale="ko">
  {
    "dialog_title": "위젯 설치 요청",
    "title": "다양한 위젯을 살펴보고 설치하고 싶은 위젯을 모두 요청해 주세요.",
    "step_title": {
      "0": "상품 상세 페이지 위젯",
      "1": "전체 리뷰 페이지 위젯",
      "2": "메인 페이지 위젯",
      "3": "기타 페이지 위젯",
      "4": "리뷰수 + 평점 설치"
    },
    "info_content": {
      "0": "상품 페이지에는 지정된 위치에만 위젯을 설치할 수 있습니다.",
      "1": "목록에 추가한 순서대로 전체리뷰 게시판 전체에 설치됩니다.<br/>전체리뷰 게시판은 [기본 설정 > 위젯 공통 탭 > 전체리뷰 게시판 URL]에서 변경할 수 있습니다.",
      "2": "목록에 추가한 순서대로 메인 페이지 최하단에 설치됩니다. 다른 위치에 설치를 원할 경우, [설치위치 변경] 버튼을 클릭해주세요.",
      "3": "이벤트 페이지 또는 카테고리 페이지에 위젯을 설치할 수 있습니다."
    }
  }
</i18n>
