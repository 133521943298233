<template>
  <AppDropdownMenuButton
    :label="$t('manage_reviews')"
    :menu-items="menuItems"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ReviewProductsManageReviewsButton',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    menuItems() {
      return [
        {
          label: this.$t('manage_pinned_reviews'),
          clickHandler: this.managePinnedReviews,
          disabled: !this.product.has_pinned_reviews,
          tooltip: this.product.has_pinned_reviews
            ? null
            : this.$t('no_pinned_reviews')
        },
        {
          label: this.$t('manage_manager_reviews'),
          clickHandler: this.manageManagerReviews,
          disabled: !this.product.has_manager_reviews,
          tooltip: this.product.has_manager_reviews
            ? null
            : this.$t('no_manager_reviews')
        },
        {
          label: this.$t('write_manager_review'),
          clickHandler: this.writeManagerReview
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    managePinnedReviews({ close }) {
      close();
      this.openDialog([
        'ReviewReviewsPinDialog',
        {
          productName: this.product.name,
          productId: this.product.id
        }
      ]);
    },
    manageManagerReviews({ close }) {
      this.openDialog([
        'ReviewProductsManageReviewsDialog',
        { product: this.product }
      ]);
      close();
    },
    writeManagerReview({ close }) {
      this.openDialog([
        'ReviewReviewFormDialog',
        {
          productId: this.product.id,
          subTitle: this.product.name,
          focusGroupId: 'user_name'
        }
      ]).then(eventBus =>
        eventBus.$on('create', product => this.$emit('create', product))
      );
      close();
    }
  }
};
</script>

<i18n locale="ko">
{
  "manage_reviews": "리뷰 작성/관리",
  "manage_pinned_reviews": "추천 리뷰 관리하기",
  "no_pinned_reviews": "추천 리뷰가 없습니다.",
  "manage_manager_reviews": "스탭리뷰 관리하기",
  "no_manager_reviews": "스탭리뷰가 없습니다.",
  "write_manager_review": "스탭리뷰 작성하기"
}
</i18n>
<i18n locale="en">
{
  "manage_reviews": "Manage review",
  "manage_pinned_reviews": "Manager pinned reviews",
  "no_pinned_reviews": "No pinned reviews.",
  "manage_manager_reviews": "Manager staff reviews",
  "no_manager_reviews": "No staff reviews.",
  "write_manager_review": "Write staff review"
}
</i18n>
