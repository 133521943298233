<template>
  <AppResourceTable
    table-id="review-category-table"
    :resources="reviewCategories"
    :columns="COLUMNS"
    :rows="rows"
    :enable-total-count="false"
    :table-name="categoryId ? productCategoryName(categoryId) : null"
    :table-description="$t('description')"
    :no-data="categoryId ? $t('no_sub_categories') : $t('no_data')"
    @paginate="$emit('paginate', $event)"
    @refresh="$emit('refresh')"
  >
    <template #cell="{ row, column, value, rowIndex }">
      <AppBadge
        v-if="column === 'product_category_status'"
        :badge-style="value === 'visible' ? 'mint-green' : 'default'"
        :label="value === 'visible' ? $t('displayed') : $t('hidden')"
      />
      <AppInternalLink
        v-else-if="column === 'name'"
        :to="`/review/categories/${row.id}`"
        class="hover-underline"
      >
        {{ value }}
      </AppInternalLink>
      <template v-else-if="column === 'sub_categories'">
        <AppBadge
          v-for="(name, idx) in value"
          :key="idx"
          badge-style="grey-outline"
          :label="name"
        />
        <template v-if="!value">-</template>
      </template>
      <template v-else-if="column === 'actions'">
        <AppButtonDraggable :drag-text="row.name" @sort="sort" />
        <ReviewCategoryManageButton
          :category="row"
          @edit="editCategoryName(rowIndex, $event)"
          @change="changeCategoryStatus(rowIndex, $event)"
          @delete="deleteCategory(rowIndex)"
        />
      </template>
    </template>
  </AppResourceTable>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import { arrayMoveIndex } from '@/lib/array';
import { mapState, mapGetters, mapMutations } from 'vuex';
import ReviewCategoryManageButton from './ReviewCategoryManageButton';

export default {
  name: 'ReviewCategoryTable',
  components: { ReviewCategoryManageButton },
  props: {
    categoryId: {
      type: Number,
      default: null
    },
    categories: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'position', label: this.$t('app.position'), type: 'positionAsc' },
        { id: 'code', label: this.$t('app.code') },
        { id: 'product_category_status', label: this.$t('app.status') },
        { id: 'name', label: this.$t('name'), align: 'left' },
        { id: 'sub_categories', label: this.$t('sub_categories') },
        { id: 'products_count', label: this.$t('products_count') },
        { id: 'actions', label: this.$t('app.actions') }
      ]
    };
  },
  computed: {
    ...mapState('productCategory', ['productCategories']),
    ...mapGetters('productCategory', [
      'productCategoryName',
      'productCategoryAncestorsWithSelf'
    ]),
    reviewCategories() {
      return this.categories;
    },
    rows() {
      return this.reviewCategories.items.map((category, index) => ({
        ...category,
        code: category.code || '-',
        position: index + 1,
        sub_categories: this.childrenNamesMap[category.id].length
          ? this.childrenNamesMap[category.id]
          : null
      }));
    },
    childrenNamesMap() {
      return _.fromPairs(
        this.reviewCategories.items.map(category => [
          category.id,
          this.productCategories
            .filter(c => c.parent_category_id === category.id)
            .map(c => c.name)
        ])
      );
    }
  },
  mounted() {
    this.setPageBreadcrumbs();
  },
  updated() {
    this.setPageBreadcrumbs();
  },
  beforeDestroy() {
    this.SET_PAGE_BREADCRUMBS([]);
  },
  methods: {
    ...mapMutations('pageBreadcrumb', ['SET_PAGE_BREADCRUMBS']),
    ...mapMutations('productCategory', ['DELETE_PRODUCT_CATEGORY']),
    sort({ srcIndex, dstIndex }) {
      const orgCategories = this.reviewCategories.items;
      this.reviewCategories.items = arrayMoveIndex(
        this.reviewCategories.items,
        srcIndex,
        dstIndex
      );
      api
        .patch(
          '/product_categories/sort',
          { category_ids: this.reviewCategories.items.map(c => c.id) },
          { successMessage: this.$t('sorted') }
        )
        .catch(() => (this.reviewCategories.items = orgCategories));
    },
    editCategoryName(index, name) {
      this.$set(this.reviewCategories.items[index], 'name', name);
    },
    changeCategoryStatus(index, product_category_status) {
      this.$set(
        this.reviewCategories.items[index],
        'product_category_status',
        product_category_status
      );
    },
    deleteCategory(index) {
      const { items } = this.reviewCategories;
      const categoryId = items[index].id;

      api
        .delete(`/product_categories/${categoryId}`, {
          params: { last_id: items[items.length - 1].id },
          successMessage: this.$t('delete_success')
        })
        .then(({ data }) => {
          this.DELETE_PRODUCT_CATEGORY(categoryId);
          this.$delete(items, index);
          if (data.next_category) {
            items.push(data.next_category);
          }
        });
    },
    setPageBreadcrumbs() {
      if (!this.categoryId) return;

      this.$nextTick(() => {
        this.SET_PAGE_BREADCRUMBS(
          this.productCategoryAncestorsWithSelf(this.categoryId).map(
            category => ({
              id: _.uniqueId(),
              path: `/review/categories/${category.id}`,
              label: category.name
            })
          )
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .review-category-table__position {
    flex: 0 0 50px;
  }

  .review-category-table__code,
  .review-category-table__product-category-status {
    flex: 0 0 80px;
  }

  .review-category-table__name {
    flex: 2 0 120px;
  }

  .review-category-table__sub-categories {
    flex: 2 0 200px;
  }

  .review-category-table__products-count {
    flex: 1 0 90px;
  }

  .review-category-table__actions {
    flex: 0 0 180px;
  }

  .AppResourceTableCell {
    line-height: 23px;
  }
}
</style>

<i18n locale="ko">
{
  "no_data": "카테고리가 없습니다.",
  "no_sub_categories": "하위 카테고리가 없습니다.",
  "description": "상품수가 0개인 카테고리는 위젯의 카테고리 검색 목록에서 제외됩니다.",
  "name": "카테고리 이름",
  "sub_categories": "하위 카테고리",
  "products_count": "상품수",
  "displayed": "진열",
  "hidden": "숨김",
  "sorted": "카테고리 순서가 변경됐습니다.",
  "delete_success": "카테고리를 삭제했습니다."
}
</i18n>
