export default [
  {
    id: 'target_dashboard',
    type: 'sub_menu',
    path: '/target/dashboard',
    parentId: 'target',
    superAdmin: true
  },
  {
    id: 'target_widget_campaigns_product_recommendation',
    type: 'sub_menu',
    path: '/target/widget-campaigns/product-recommendation',
    parentId: 'target'
  },
  {
    id: 'target_widget_campaigns_product_recommendation_enabled',
    type: 'tab',
    path: '/target/widget-campaigns/product-recommendation',
    parentId: 'target_widget_campaigns_product_recommendation'
  },
  {
    id: 'target_widget_campaigns_product_recommendation_disabled',
    type: 'tab',
    path: '/target/widget-campaigns/product-recommendation?tab=disabled',
    parentId: 'target_widget_campaigns_product_recommendation'
  },
  {
    id: 'target_widget_campaigns_product_recommendation_testing',
    type: 'tab',
    path: '/target/widget-campaigns/product-recommendation?tab=testing',
    parentId: 'target_widget_campaigns_product_recommendation'
  },
  {
    id: 'target_popup_campaigns_situational',
    type: 'sub_menu',
    path: '/target/popup-campaigns/situational',
    parentId: 'target'
  },
  {
    id: 'target_popup_campaigns_situational_enabled',
    type: 'tab',
    path: '/target/popup-campaigns/situational',
    parentId: 'target_popup_campaigns_situational'
  },
  {
    id: 'target_popup_campaigns_situational_disabled',
    type: 'tab',
    path: '/target/popup-campaigns/situational?tab=disabled',
    parentId: 'target_popup_campaigns_situational'
  },
  {
    id: 'target_popup_campaigns_situational_testing',
    type: 'tab',
    path: '/target/popup-campaigns/situational?tab=testing',
    parentId: 'target_popup_campaigns_situational'
  },
  {
    id: 'target_dm_campaigns',
    type: 'sub_menu',
    path: '/target/dm-campaigns/situational',
    parentId: 'target'
  },
  {
    id: 'target_dm_campaigns_situational',
    type: 'sub_menu',
    path: '/target/dm-campaigns/situational',
    parentId: 'target_dm_campaigns'
  },
  {
    id: 'target_dm_campaigns_situational_enabled',
    type: 'tab',
    path: '/target/dm-campaigns/situational',
    parentId: 'target_dm_campaigns_situational'
  },
  {
    id: 'target_dm_campaigns_situational_disabled',
    type: 'tab',
    path: '/target/dm-campaigns/situational?tab=disabled',
    parentId: 'target_dm_campaigns_situational'
  },
  {
    id: 'target_dm_campaigns_situational_testing',
    type: 'tab',
    path: '/target/dm-campaigns/situational?tab=testing',
    parentId: 'target_dm_campaigns_situational'
  },
  {
    id: 'target_dm_campaigns_situational_archived',
    type: 'tab',
    path: '/target/dm-campaigns/situational?tab=archived',
    parentId: 'target_dm_campaigns_situational'
  },
  {
    id: 'target_dm_campaigns_mass',
    type: 'sub_menu',
    path: '/target/dm-campaigns/mass',
    parentId: 'target_dm_campaigns'
  },
  {
    id: 'target_dm_campaigns_mass_enabled',
    type: 'tab',
    path: '/target/dm-campaigns/mass',
    parentId: 'target_dm_campaigns_mass'
  },
  {
    id: 'target_dm_campaigns_mass_disabled',
    type: 'tab',
    path: '/target/dm-campaigns/mass?tab=disabled',
    parentId: 'target_dm_campaigns_mass'
  },
  {
    id: 'target_dm_campaigns_mass_archived',
    type: 'tab',
    path: '/target/dm-campaigns/mass?tab=archived',
    parentId: 'target_dm_campaigns_mass'
  },
  {
    id: 'target_dm_campaigns_custom',
    type: 'sub_menu',
    path: '/target/groups',
    parentId: 'target_dm_campaigns',
    isOldAdmin: true
  },
  {
    id: 'target_reports',
    type: 'sub_menu',
    path: '/target/reports/total-reports',
    parentId: 'target'
  },
  {
    id: 'target_reports_total_reports',
    type: 'sub_menu',
    path: '/target/reports/total-reports',
    parentId: 'target_reports'
  },
  {
    id: 'target_reports_dispatch_results',
    type: 'sub_menu',
    path: '/target/reports/dispatch-results',
    parentId: 'target_reports'
  },
  {
    id: 'target_reports_dispatch_results_realtime',
    type: 'tab',
    path: '/target/reports/dispatch-results',
    parentId: 'target_reports_dispatch_results'
  },
  {
    id: 'target_reports_dispatch_results_dm',
    type: 'tab',
    path: '/target/reports/dispatch-results?tab=dm',
    parentId: 'target_reports_dispatch_results'
  },
  {
    id: 'target_settings',
    type: 'sub_menu',
    path: '/target/settings',
    parentId: 'target'
  },
  {
    id: 'target_settings_basic',
    type: 'tab',
    path: '/target/settings',
    parentId: 'target_settings'
  },
  {
    id: 'target_settings_third_party',
    type: 'tab',
    path: '/target/settings/third_party',
    parentId: 'target_settings'
  },
  {
    id: 'target_admin_settings',
    type: 'sub_menu',
    path: '/target/admin-settings/simulation',
    parentId: 'target',
    superAdmin: true
  },
  {
    id: 'target_admin_settings_simulation',
    type: 'tab',
    path: '/target/admin-settings/simulation',
    parentId: 'target_admin_settings',
    superAdmin: true
  },
  {
    id: 'target_admin_settings_misc',
    type: 'tab',
    path: '/target/admin-settings/misc',
    parentId: 'target_admin_settings',
    superAdmin: true
  },
  {
    id: 'target_admin_settings_temp',
    type: 'tab',
    path: '/target/admin-settings/temp',
    parentId: 'target_admin_settings',
    superAdmin: true
  },
  // TODO: Uncomment when Target Op team writes some manuals
  // {
  //   id: 'target_manuals',
  //   type: 'sub_menu',
  //   parentId: 'target',
  //   dialog: ['TheManualsDialog', { initialTab: 'manuals_dialog_target' }],
  //   group: 1
  // },
  {
    id: 'target_intro',
    type: 'sub_menu',
    path: '/target/intro',
    parentId: 'target',
    group: 1
  },
  {
    id: 'target_campaign_stats_dialog',
    type: 'dialog',
    dialog: 'TargetCampaignStatsDialog',
    not_searchable: true
  }
].map(item => {
  switch (item.id) {
    case 'target_intro':
      return item;
    case 'target_dm_campaigns':
      // 임시 조치 - 이후 메뉴 노출 + 안내 문구 표시하는 방향으로 개선될 예정
      return {
        ...item,
        isAccessible: getters =>
          getters.isTargetAccessible && !getters.isTargetFreeTrial
      };
    case 'target_dm_campaigns_custom':
      return {
        ...item,
        isAccessible: getters =>
          getters.isTargetAccessible && getters.isTargetCustomCampaignEnabled
      };
    default:
      return {
        ...item,
        isAccessible: getters => getters.isTargetAccessible
      };
  }
});
