<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppPasswordInput v-model="valueBasic" input-size="large" />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Invalid</div>
      <AppPasswordInput v-model="valueInvalid" input-size="large" invalid />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiPasswordInput',
  data() {
    return { valueBasic: '', valueInvalid: '' };
  }
};
</script>
