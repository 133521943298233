<template>
  <ReviewSettingsWidgetsNormal
    :widget-type="WidgetType.REVIEWS"
    :widget-summary="widgetSummary"
  />
</template>

<script>
import { mapGetters } from 'vuex';

import ReviewSettingsWidgetsNormal from './ReviewSettingsWidgetsNormal';
import WidgetType from '@/enums/WidgetType';

export default {
  name: 'ReviewSettingsWidgetsReviews',
  components: { ReviewSettingsWidgetsNormal },
  computed: {
    ...mapGetters('productCategory', ['productCategoryName']),
    WidgetType() {
      return WidgetType;
    }
  },
  methods: {
    widgetSummary(widget) {
      if (widget.widget_type === WidgetType.MANAGING_REVIEWS) return '';
      else if (widget.tag_name)
        return this.$t(
          `summary.${widget.filter_photo_only ? 'tag_photo' : 'tag'}`,
          {
            tag: widget.tag_name,
            per: widget.posts_per_page
          }
        );
      else if (widget.category_id)
        return this.$t(
          `summary.${widget.filter_photo_only ? 'category_photo' : 'category'}`,
          {
            category: this.productCategoryName(widget.category_id),
            per: widget.posts_per_page
          }
        );
      else
        return this.$t(
          `summary.${widget.filter_photo_only ? 'default_photo' : 'default'}`,
          { per: widget.posts_per_page }
        );
    }
  }
};
</script>

<i18n locale="ko">
{
  "summary": {
    "default": "리뷰를 {per}개씩 보여줍니다.",
    "default_photo": "포토 리뷰를 {per}개씩 보여줍니다.",
    "tag": "'{tag}' 태그가 설정된 리뷰를 {per}개씩 보여줍니다.",
    "tag_photo": "'{tag}' 태그가 설정된 포토 리뷰를 {per}개씩 보여줍니다.",
    "category": "'{category}' 카테고리의 리뷰를 {per}개씩 보여줍니다.",
    "category_photo": "'{category}' 카테고리의 포토 리뷰를 {per}개씩 보여줍니다."
  }
}
</i18n>
