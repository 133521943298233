<template>
  <div class="FitReportsMainKpis">
    <AppReportsSearchbar
      v-bind="mainKpiSearchBarProps"
      :enable-interval-search="false"
      :enable-advanced-search="false"
      :resource-params="resourceParams"
      :min-date="fit_launched_at"
      :min-days="30"
      :disabled="!reportsReady"
      v-on="searchBarEvents"
      @submit="searchResource"
    />
    <AppInfoBox class="FitReportsInfo">
      {{ infoMessage }}
    </AppInfoBox>
    <AppGrid>
      <div class="AppGrid__col AppGrid__col--3">
        <AppDataSection :title="$t('kpi.title')" gap="wide">
          <AppDataSectionItem
            v-for="(item, i) in items"
            :key="i"
            :title="item.title"
          >
            <div class="FitReportsMainKpis__dashboard">
              <AppNumber
                v-if="item.numberProps.value"
                v-bind="item.numberProps"
              />
              <span v-else>-</span>
              <div
                v-if="item.comparedProps && diff_between_conversions !== 0"
                class="FitReportsMainKpis__dashboard--comparision"
              >
                {{ $t('kpi.comparison.label') }}
                <AppBadge
                  :label="item.comparedProps.value"
                  :badge-style="item.comparedProps.badgeStyle"
                  :tooltip="{
                    message: diff_tooltip,
                    maxWidth: '240px',
                    horizontal: true,
                    textAlign: 'left',
                    style: 'white'
                  }"
                />
                <AppInfoBox
                  v-if="diff_between_conversions < 0"
                  class="FitReportsMainKpis__dashboard--comparision-alert"
                >
                  <AppButton
                    :label="$t('kpi.comparison.alert')"
                    button-style="underline"
                    @click="openInquiry"
                  />
                </AppInfoBox>
              </div>
            </div>
          </AppDataSectionItem>
        </AppDataSection>
      </div>
      <div class="AppGrid__col AppGrid__col--9">
        <AppContainer>
          <AppChart
            :options="chartOptions"
            :tooltip="chartTooltip"
            @tooltip="onChartTooltip"
          />
        </AppContainer>
      </div>
    </AppGrid>
    <AppResourceTable
      :rows="rows"
      table-style="stats"
      :columns="COLUMNS"
      :resources="resources"
      :enable-total-count="false"
      :no-data="resourceTableNoDataMessage"
      class="FitReportsResourceTable"
      @paginate="paginate"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import qs from 'qs';

import ReportsView from '@/mixins/ReportsView';
import InquiryHelper from '@/mixins/InquiryHelper';

export default {
  name: 'FitReportsMainKpis',
  mixins: [ReportsView, InquiryHelper],
  props: {
    summary: { type: Object, default: null }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'date', label: this.$t('app.date'), type: 'pre' },
        {
          id: 'fitWidgetGUVRate',
          label: this.$t('kpi.widget_check_rate'),
          type: 'percentage',
          tooltip: {
            message: this.$t('kpi.tooltip_widget_check_rate'),
            info: this.$t('kpi.info_guv')
          }
        },
        {
          id: 'conversionWithFitWidgetRate',
          label: this.$t('kpi.conversion_rate'),
          type: 'percentage',
          tooltip: {
            message: this.$t('kpi.tooltip_conversion_rate'),
            info: this.$t('kpi.info_guv')
          }
        }
      ],
      showDetail: false,
      chartTooltip: null,
      downloadExcelUrl: '/api/fit/statistics/download_excel.xlsx'
    };
  },
  computed: {
    items() {
      return [
        {
          title: this.$t('kpi.widget_check_rate'),
          numberProps: {
            value: this.total_avg_fit_widget_guv_rate,
            precision: 2,
            unit: 'percentage'
          }
        },
        {
          title: this.$t('kpi.conversion_rate'),
          numberProps: {
            value: this.total_avg_conversion_with_fit_widget_rate,
            precision: 2,
            unit: 'percentage'
          },
          comparedProps: {
            value: this.diff_percentage_label,
            badgeStyle:
              this.diff_between_conversions > 0
                ? 'mint-green-outline'
                : 'red-outline',
            unit: 'percentage'
          }
        }
      ];
    },
    chartOptions() {
      return {
        title: '',
        legend: {
          align: 'right',
          verticalAlign: 'top',
          symbolHeight: 8,
          symbolWidth: 8
        },
        xAxis: {
          crosshair: { dashStyle: 'solid' },
          type: 'datetime',
          dateTimeLabelFormats: {
            day: '%m. %d.',
            week: '%m. %d.',
            month: '%Y. %m.'
          }
        },
        yAxis: [
          {
            title: {
              text: ''
            }
          }
        ],
        tooltip: {
          enabled: false
        },
        series: [
          {
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData('fit_widget_guv_rate', 'percentage'),
            name: this.$t('kpi.widget_check_rate'),
            type: 'line'
          },
          {
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData(
              'conversion_with_fit_widget_rate',
              'percentage'
            ),
            name: this.$t('kpi.conversion_rate'),
            type: 'line'
          }
        ],
        plotOptions: {
          series: {
            states: { hover: { lineWidthPlus: 0, halo: { size: 0 } } },
            marker: { symbol: 'circle', radius: 3 }
          }
        },
        showDetail: false,
        chartTooltip: null
      };
    },
    summaryRow() {
      return this.summary
        ? {
            date: `${this.summary.monthly.start_date} ~ ${this.summary.monthly.end_date}`,
            fitWidgetGUVRate:
              this.summary.monthly.avg_fit_widget_guv_rate * 100,
            conversionWithFitWidgetRate:
              this.summary.monthly.avg_conversion_with_fit_widget_rate * 100,
            rowStyle: 'stats-total-summary'
          }
        : {};
    },
    dataRow() {
      return this.reportsTable.map(fitStatistic => {
        const date = this.getFormattedDate(fitStatistic.date);
        return {
          date,
          fitWidgetGUVRate: fitStatistic.fit_widget_guv_rate * 100,
          conversionWithFitWidgetRate:
            fitStatistic.conversion_with_fit_widget_rate * 100
        };
      });
    },
    rows() {
      return this.reportsReady ? [this.summaryRow, ...this.dataRow] : [];
    },
    reportsReady() {
      return this.reportsStatus ? this.reportsStatus.data_ready : true;
    },
    fit_launched_at() {
      return this.reportsStatus ? this.reportsStatus.fit_launched_at : null;
    },
    infoMessage() {
      return this.reportsReady
        ? this.$t('min_range_message')
        : this.fit_launched_at
        ? this.$t('data_is_not_ready_message', [
            this.reportsStatus.data_collection_start_date,
            this.reportsStatus.data_collection_end_date
          ])
        : this.$t('no_data_collection_message');
    },
    total_avg_fit_widget_guv_rate() {
      return this.summary
        ? this.summary.total.avg_fit_widget_guv_rate * 100
        : null;
    },
    total_avg_conversion_with_fit_widget_rate() {
      return this.summary
        ? this.summary.total.avg_conversion_with_fit_widget_rate * 100
        : null;
    },
    total_avg_conversion_without_fit_widget_rate() {
      return this.summary
        ? this.summary.total.avg_conversion_without_fit_widget_rate * 100
        : null;
    },
    diff_between_conversions() {
      return (
        this.total_avg_conversion_with_fit_widget_rate -
        this.total_avg_conversion_without_fit_widget_rate
      );
    },
    diff_percentage_label() {
      const diff = this.diff_between_conversions;
      return diff > 0
        ? `${this.formatPercentage(diff)}% ${this.$t('kpi.comparison.higher')}`
        : `${this.formatPercentage(-1 * diff)}% ${this.$t(
            'kpi.comparison.lower'
          )}`;
    },
    diff_tooltip() {
      return this.diff_between_conversions > 0
        ? this.$t('kpi.comparison.higher_tooltip', [
            this.formatPercentage(this.diff_between_conversions),
            this.formatPercentage(
              this.total_avg_conversion_with_fit_widget_rate
            ),
            this.formatPercentage(
              this.total_avg_conversion_without_fit_widget_rate
            )
          ])
        : this.$t('kpi.comparison.lower_tooltip', [
            this.formatPercentage(this.diff_between_conversions),
            this.formatPercentage(
              this.total_avg_conversion_with_fit_widget_rate
            ),
            this.formatPercentage(
              this.total_avg_conversion_without_fit_widget_rate
            )
          ]);
    },
    resourceTableNoDataMessage() {
      return this.reportsReady
        ? ''
        : this.fit_launched_at
        ? this.$t('data_is_not_ready_message', [
            this.reportsStatus.data_collection_start_date,
            this.reportsStatus.data_collection_end_date
          ])
        : this.$t('no_data_collection_message');
    },
    mainKpiSearchBarProps() {
      return _.assign(this.searchBarProps, {
        downloadExcelUrl: `${this.downloadExcelUrl}?${qs.stringify(
          this.resourceParams
        )}`
      });
    }
  },
  methods: {
    onChartTooltip({ x, index, series }) {
      this.chartTooltip = {
        title: this.getFormattedDate(new Date(x)),
        items: series.chart.series.map(({ data, name }) => ({
          symbol: 'circle',
          name,
          value: data[index].y,
          unit: 'percentage',
          precision: 2
        }))
      };
    },
    formatPercentage(number) {
      return Number.parseFloat(number).toFixed(2);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.FitReportsInfo {
  margin-bottom: 32px;
}

.FitReportsResourceTable {
  margin-top: 32px;
}

.FitReportsMainKpis__dashboard {
  font-size: 20px;
}

.FitReportsMainKpis__dashboard--comparision {
  margin-top: 4px;
  font-size: 12px;
  color: $color-grey-55;
}

.FitReportsMainKpis__dashboard--comparision-alert {
  margin-top: 8px;
  padding: 8px 24px;
  border-radius: 3px;
  background-color: $color-grey-25;
  font-size: 12px;
  color: $color-grey-70;
}
</style>

<i18n locale="ko">
{
  "min_range_message": "최소 30일 이상의 기간에 대한 주요 KPI 정보를 조회할 수 있습니다.",
  "data_is_not_ready_message": "데이터를 수집하고 있습니다. (데이터 수집기간 {0} ~ {1})",
  "no_data_collection_message": "데이터는 서비스 이용 시작 시점부터 수집됩니다.",
  "kpi": {
    "title": "주요 KPI",
    "widget_check_rate": "위젯확인율",
    "tooltip_widget_check_rate": "위젯 확인율은 (핏 위젯 확인 GUV / 상품 상세 페이지 GUV) 로 계산합니다.",
    "conversion_rate": "구매전환율",
    "tooltip_conversion_rate": "구매전환율은 (핏 위젯을 확인하고 구매한 GUV / 핏 위젯 확인 GUV) 로 계산합니다.",
    "info_guv": "GUV란? (고객X상품) 조합별 unique한 page view 수를 의미합니다.",
    "comparison": {
      "label": "핏 위젯 미확인 고객 대비",
      "higher": "높음",
      "lower": "낮음",
      "higher_tooltip": "핏 위젯 확인 고객의 구매 전환율이 미확인 고객보다 약 {0}% 높습니다.<br><br>확인 고객의 구매전환율: <b>{1}%</b><br>미확인 고객의 구매전환율: <b>{2}%</b>",
      "lower_tooltip": "핏 위젯 확인 고객의 구매 전환율이 미확인 고객보다 약 {0}% 낮습니다.<br><br>확인 고객의 구매전환율: <b>{1}%</b><br>미확인 고객의 구매전환율: <b>{2}%</b>",
      "alert": "🚨 핏 운영팀과 점검해보세요"
    }
  }
}
</i18n>
