<template>
  <AppModalForm v-bind="[$props, $attrs]" v-on="$listeners">
    <template v-for="(_, name) in $scopedSlots" #[name]="slotProps">
      <slot :name="name" v-bind="slotProps" />
    </template>
  </AppModalForm>
</template>

<script>
import AppModalFormProps from './AppModalFormProps';

export default {
  name: 'AppModalFormLivePreview',
  mixins: [AppModalFormProps],
  props: { width: { type: String, default: 'auto' } }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_breakpoints.scss';

::v-deep {
  .AppModal__main {
    width: 418px;

    @include media-breakpoint-each(tablet) {
      width: 680px;
    }
  }

  .AppModal__handle {
    position: absolute;
    top: 0;
    right: 416px;
    width: 5px;
    height: 100%;
    cursor: ew-resize;
    &:hover,
    &.AppModal__handle--visible {
      background-color: rgba(0, 0, 0, 0.2);
    }
    &.AppModal__handle--visible {
      pointer-events: none;
    }
    z-index: 1;
  }
}
</style>
