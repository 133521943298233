import DisplayProductManagementDispatchType from '@/enums/DisplayProductManagementDispatchType';
import DisplayProductManagementCohortProgressStatus from '@/enums/DisplayProductManagementCohortProgressStatus';
import i18n from '@/lib/i18n';

const JOB_TYPE = 'product_management_cohort';
const jobId = cohortId => `${JOB_TYPE}-${cohortId}`;

const scheduledCohortTitle = function(cohort) {
  const argument = DisplayProductManagementDispatchType.isNormal(
    cohort.dispatch_type
  )
    ? cohort.prouduct_management_name
    : DisplayProductManagementDispatchType.t(cohort.dispatch_type);
  return i18n.t(
    `target_scheduled_product_management_cohorts.job.title.${DisplayProductManagementDispatchType.key(
      cohort.dispatch_type
    )}`,
    [argument]
  );
};

const actions = {
  setScheduledCohorts({ commit, dispatch }, cohorts) {
    if (cohorts.length)
      cohorts.forEach(cohort => dispatch('setScheduledCohort', cohort));
    else commit('multiAsyncJob/REMOVE_JOBS', JOB_TYPE, { root: true });
  },
  setScheduledCohort({ dispatch }, cohort) {
    dispatch(
      'multiAsyncJob/upsertJob',
      {
        title: scheduledCohortTitle(cohort),
        id: jobId(cohort.id),
        progressCount: cohort.progress.processed_count,
        totalCount: cohort.progress.total_count,
        displayRunningTime: true,
        cohortId: cohort.id,
        startedAt: cohort.created_at,
        progressStatus: DisplayProductManagementCohortProgressStatus.tByCohortStatus(
          cohort.execute_status,
          cohort.syncing_csv_type
        ),
        resultHandler() {
          dispatch(
            'dialog/openDialog',
            [
              'DisplayProductManagementCohortDialog',
              { productManagementCohortId: cohort.id }
            ],
            { root: true }
          );
        },
        type: JOB_TYPE,
        removeAfterConfirm: true
      },
      { root: true }
    );
  },
  removeScheduledCohort({ commit }, cohortId) {
    commit('multiAsyncJob/REMOVE_JOB', jobId(cohortId), { root: true });
  }
};

export default { namespaced: true, actions };
