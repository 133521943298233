<template>
  <AppModalForm
    :form-props="{ formStyle: 'wide', ...formProps }"
    :is-loading="isLoading"
    :title="$t(editing ? 'title_edit' : 'title_new')"
    v-on="formEvents"
  >
    <input type="hidden" name="page_type_regex[page_type]" :value="pageType" />
    <template #group="{ id, errors }">
      <template v-if="id === 'parameters'">
        <div class="AppForm__group-field">
          <div class="table-head-control">
            <AppButton
              :label="$t('button.add_url_param')"
              @click="addUrlPattern"
            />
          </div>
          <AppSmallDeviceScrollable>
            <AppTable
              valign-baseline
              :columns="COLUMNS"
              :rows="formObject.parameters"
            >
              <template #cell="{ row, column, rowIndex }">
                <template v-if="column.id === 'key'">
                  <input
                    type="hidden"
                    name="page_type_regex[parameters][][id]"
                    :value="row.id"
                  />
                  <AppTextInput
                    v-model="row.param_key"
                    :invalid="!!errors[`parameters[${row.index}][param_key]`]"
                    name="page_type_regex[parameters][][param_key]"
                    @blur="validateField(`parameters[${row.index}][param_key]`)"
                    @change="
                      validateField(`parameters[${row.index}][param_key]`)
                    "
                  />
                  <AppFormError
                    :error="errors[`parameters[${row.index}][param_key]`]"
                  />
                </template>
                <template v-else-if="column.id === 'value'">
                  <AppTextInput
                    v-model="row.param_value"
                    :invalid="!!errors[`parameters[${row.index}][param_value]`]"
                    name="page_type_regex[parameters][][param_value]"
                    @blur="
                      validateField(`parameters[${row.index}][param_value]`)
                    "
                    @change="
                      validateField(`parameters[${row.index}][param_value]`)
                    "
                  />
                  <AppFormError
                    :error="errors[`parameters[${row.index}][param_value]`]"
                  />
                </template>
                <template v-else-if="column.id === 'feature'">
                  <AppButton
                    button-style="red-outline"
                    :label="$t('app.delete')"
                    @click="formObject.parameters.splice(rowIndex, 1)"
                  />
                </template>
              </template>
            </AppTable>
          </AppSmallDeviceScrollable>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';
import { isRegexFormatValid } from '@/lib/regexFormatValidator';
import api from '@/lib/api';
import _ from 'lodash';

export default {
  name: 'ReviewSmartInstallationPageUrlPatternFormDialog',
  mixins: [DialogFormView],
  props: {
    pageType: {
      type: String,
      required: true
    },
    pageTypeRegexId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      isLoading: true,
      COLUMNS: [
        { id: 'key', label: 'Key' },
        { id: 'value', label: 'Value' },
        { id: 'feature', label: this.$t('app.actions') }
      ]
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'regex',
              name: 'page_type_regex[regex]',
              label: this.$t('groups.regex.label'),
              placeholder: this.$t('groups.regex.placeholder'),
              type: 'text',
              validate: [
                'required',
                {
                  rule: v =>
                    !v ||
                    v.split('\n').every(value => isRegexFormatValid(value)),
                  errorMessage: this.$t('groups.regex.format_invalid')
                }
              ]
            },
            {
              id: 'parameters',
              label: this.$t('groups.parameters'),
              fields: this.formObject.parameters
                ? _.flatten(
                    this.formObject.parameters.map(rule => [
                      {
                        id: `parameters[${rule.index}][param_key]`,
                        value: () => rule.param_key,
                        validate: ['required']
                      },
                      {
                        id: `parameters[${rule.index}][param_value]`,
                        value: () => rule.param_value,
                        validate: ['required']
                      }
                    ])
                  )
                : []
            }
          ]
        }
      ];
    },
    editing() {
      return !!this.pageTypeRegexId;
    }
  },
  created() {
    this.orgFormObject = { parameters: [] };
  },
  mounted() {
    if (this.editing) {
      api
        .get(`/page_type_regexes/${this.pageTypeRegexId}`)
        .then(({ data }) => {
          data.page_type_regex.parameters.forEach((parameter, index) => {
            parameter.index = index + 1;
          });

          this.orgFormObject = data.page_type_regex;
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    addUrlPattern() {
      const autoIndex = this.formObject.parameters.length + 1;
      this.formObject.parameters.push({
        index: autoIndex,
        param_key: null,
        param_value: null
      });
    },
    submit(formData) {
      if (this.editing) {
        api
          .patch(`/page_type_regexes/${this.pageTypeRegexId}`, formData, {
            successMessage: this.$t('app.saved')
          })
          .then(({ data }) => {
            this.eventBus.$emit('update', {
              ...data.page_type_regex,
              parameters: data.page_type_regex.parameters.join(', ')
            });
          })
          .finally(() => {
            this.close(true);
          });
      } else {
        api
          .post('/page_type_regexes', formData, {
            successMessage: this.$t('app.saved')
          })
          .then(({ data }) => {
            this.eventBus.$emit('save', {
              ...data.page_type_regex,
              parameters: data.page_type_regex.parameters.join(', ')
            });
          })
          .finally(() => {
            this.close(true);
          });
      }
    }
  }
};
</script>

<style scoped>
.ReviewSmartInstallationPageUrlPatternFormDialog__add-url-param {
  margin: 8px 0;
}
</style>

<i18n locale="ko">
{
  "title_new": "URL 패턴 및 파라미터 조건 추가",
  "title_edit": "URL 패턴 및 파라미터 조건 수정",
  "groups": {
    "regex": {
      "label": "URL 패턴",
      "placeholder": "URL 패턴(정규식)을 입력해주세요.",
      "format_invalid": "잘못된 형식의 URL 패턴입니다."
    },
    "parameters": "파라미터"
  },
  "button": {
    "add_url_param": "+ 파라미터 추가"
  }
}
</i18n>
