var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('modal_title'),"width":"middle","is-loading":false,"form-props":Object.assign({}, _vm.formProps,
    {submitLabel: _vm.$t('app.save')})},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('AppInfoBox',{staticClass:"AnalyticsInsightCompetingGroupDialog__infobox"},[_vm._v(" "+_vm._s(_vm.$t('infobox'))+" ")])]},proxy:true},{key:"group",fn:function(ref){
  var id = ref.id;
  var errors = ref.errors;
return [(id === 'turnover')?_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectRadio',{attrs:{"name":"settings[turnover]","options":_vm.OPTIONS},on:{"change":function($event){return _vm.validateField('turnover_type')}},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
  var value = ref.value;
  var checked = ref.checked;
return [(value === 1)?_c('div',{staticClass:"AppForm__sub-group"},[_c('div',{staticClass:"AppForm__sub-group-field"},[_c('AppSelect',{attrs:{"id":id,"options":_vm.turnoverOptions,"disabled":!checked},on:{"change":function($event){return _vm.validateField('turnover_type')}},model:{value:(_vm.settings.turnover_type),callback:function ($$v) {_vm.$set(_vm.settings, "turnover_type", $$v)},expression:"settings.turnover_type"}}),_c('AppFormError',{attrs:{"error":errors['turnover_type']}})],1)]):_vm._e()]}}],null,true),model:{value:(_vm.settings.turnover),callback:function ($$v) {_vm.$set(_vm.settings, "turnover", $$v)},expression:"settings.turnover"}})],1):_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectRadio',{attrs:{"name":("settings[" + id + "]"),"options":_vm.OPTIONS},on:{"change":function($event){return _vm.validateField((id + "_category_ids"))}},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
  var value = ref.value;
  var checked = ref.checked;
return [(value === 1)?_c('div',{staticClass:"AppForm__sub-group"},[_c('div',{staticClass:"AppForm__sub-group-field"},[_c('AppMultipleSelect',_vm._b({attrs:{"id":id,"packing-method":"string","disabled":!checked,"invalid":!!errors[(id + "_category_ids")]},on:{"change":function($event){return _vm.validateField((id + "_category_ids"))}},model:{value:(_vm.settings[(id + "_category_ids")]),callback:function ($$v) {_vm.$set(_vm.settings, (id + "_category_ids"), $$v)},expression:"settings[`${id}_category_ids`]"}},'AppMultipleSelect',{ options: _vm.self[(id + "Options")] },false)),_c('AppFormError',{attrs:{"error":errors[(id + "_category_ids")]}})],1)]):_vm._e()]}}],null,true),model:{value:(_vm.settings[id]),callback:function ($$v) {_vm.$set(_vm.settings, id, $$v)},expression:"settings[id]"}})],1)]}},{key:"foot",fn:function(){return [_c('div',{staticClass:"AnalyticsInsightCompetingGroupDialog__button"},[_c('AppButton',{attrs:{"label":_vm.$t('initialize_to_default'),"button-style":"grey-clear"},on:{"click":_vm.initializeToDefault}})],1)]},proxy:true}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }