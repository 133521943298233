var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.name,"form-props":Object.assign({}, _vm.formProps,
    {objectId: 'product',
    submitLabel: _vm.$t('app.copy'),
    submittingLabel: _vm.$t('copying')})},scopedSlots:_vm._u([{key:"group",fn:function(ref){
  var id = ref.id;
  var inputId = ref.inputId;
  var inputName = ref.inputName;
  var errors = ref.errors;
return [(id === 'dst_product_id')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectProduct',{attrs:{"id":inputId,"name":inputName},on:{"blur":function($event){return _vm.validateField('dst_product_id_field')},"change":_vm.changeDstProductId}})],1),_c('div',{staticClass:"ProductCopyReviewsDialog__description"},[_vm._v(" "+_vm._s(_vm.$t('description', [_vm.name]))+" ")]),_c('AppFormError',{attrs:{"error":errors.dst_product_id_field}}),_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectRadio',{attrs:{"options":_vm.REVIEW_FILTER_TYPE_OPTIONS,"name":"product[review_filter_type]","value":"all"},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
  var value = ref.value;
  var checked = ref.checked;
return [(value === 'timespan')?[_c('AppDateRangePicker',{attrs:{"disabled":!checked,"required":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('input',{attrs:{"type":"hidden","name":"product[start_date]","disabled":!checked},domProps:{"value":_vm.dateRange.start_date}}),_c('input',{attrs:{"type":"hidden","name":"product[end_date]","disabled":!checked},domProps:{"value":_vm.dateRange.end_date}})]:_vm._e()]}}],null,true)})],1)]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }