<template>
  <div>
    <AppSearchbar>
      <AppButtonSyncProductIcon />
    </AppSearchbar>
    <AppResourceTable
      :columns="COLUMNS"
      :rows="rows"
      table-id="product-icons-table"
      ><template #cell="{ column }">
        <AppButtonDraggable
          v-if="column === 'actions'"
          :tooltip="$t('sort_tooltip')"
          @sort="sort"
        />
      </template>
    </AppResourceTable>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import api from '@/lib/api';
import { arrayMoveIndex } from '@/lib/array';

export default {
  name: 'ProductIcons',
  data() {
    return {
      COLUMNS: [
        {
          id: 'priority',
          label: this.$t('table.priority.label'),
          tooltip: this.$t('table.priority.tooltip')
        },
        { id: 'code', label: this.$t('table.code') },
        { id: 'image_pc', label: this.$t('table.image_pc'), type: 'img' },
        {
          id: 'image_mobile',
          label: this.$t('table.image_mobile'),
          type: 'img'
        },
        {
          id: 'actions',
          label: this.$t('app.actions'),
          type: 'actions'
        }
      ]
    };
  },
  computed: {
    ...mapState('productIcons', ['productIcons']),
    rows() {
      return this.productIcons.map(icon => ({
        ...icon,
        image_pc: icon.mobile ? null : icon.image_url,
        image_mobile: icon.mobile ? icon.image_url : null
      }));
    }
  },
  methods: {
    ...mapMutations('productIcons', ['SET_PRODUCT_ICONS']),
    sort({ srcIndex, dstIndex }) {
      api
        .patch(
          '/product/product_icons/sort',
          { src_priority: srcIndex + 1, dst_priority: dstIndex + 1 },
          { successMessage: this.$t('sort_complete') }
        )
        .then(() => {
          const sortedIcons = arrayMoveIndex(
            this.productIcons,
            srcIndex,
            dstIndex
          ).map((icon, index) => ({ ...icon, priority: index + 1 }));
          this.SET_PRODUCT_ICONS(sortedIcons);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .product-icons-table__image-pc {
    .AppImage {
      vertical-align: middle;
      max-width: 70px;
      max-height: 20px;
    }
  }

  .product-icons-table__image-mobile {
    .AppImage {
      vertical-align: middle;
      max-width: 35px;
      max-height: 14px;
    }
  }
}
</style>

<i18n locale="ko">
{
  "table": {
    "priority": {
      "label": "우선순위",
      "tooltip": "쇼핑몰 솔루션의 상품당 아이콘 설정 최대값(최대 5개)에 의해 더 이상 아이콘을 추가할 수 없을 시\n낮은 우선순위의 아이콘들을 해제하고 새로운 아이콘을 추가합니다."
    },
    "code": "아이콘 코드",
    "image_pc": "아이콘 이미지 (PC)",
    "image_mobile": "아이콘 이미지 (모바일)"
  },
  "sort_tooltip": "클릭한 상태로 이동하면 우선순위를 변경할 수 있습니다.",
  "sort_complete": "아이콘 우선순위가 변경되었습니다."
}
</i18n>
