<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #default>
        <li>
          <AppButton
            button-style="red"
            :label="$t('add_option_type')"
            @click="addOptionType"
          />
        </li>
      </template>
      <template #right>
        <li>
          <AppSearchWithType
            v-model="resourceParams"
            :search-types="[
              { value: 'name', label: $t('product_option_type.name') }
            ]"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppResourceTable
      table-id="product-option-types-table"
      :event-bus="tableEventBus"
      :resources="optionTypes"
      :columns="COLUMNS"
      :rows="rows"
      @refresh="refreshResource"
      @paginate="paginate"
    >
      <template #cell="{ column, row }">
        <template v-if="column === 'actions'">
          <AppButton
            :label="$t('app.edit')"
            @click="editOptionType(row.id, row.name)"
          />
          <AppButton
            :label="$t('manage_option_values')"
            @click="manageOptionValues(row.id, row.name)"
          />
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            @click="deleteOptionType(row.id)"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceView from '@/mixins/ResourceView';

export default {
  name: 'ProductOptionTypes',
  mixins: [ResourceView],
  data() {
    return {
      COLUMNS: [
        { id: 'name', label: this.$t('product_option_type.name') },
        {
          id: 'option_values',
          label: this.$t('product_option_type.option_values')
        },
        {
          id: 'parent_option_values',
          label: this.$t('product_option_type.parent_option_values')
        },
        { id: 'actions', label: this.$t('app.actions') }
      ],
      isLoading: true,
      optionTypes: nullResources,
      tableEventBus: new Vue()
    };
  },
  computed: {
    defaultResourceParams: () => ({
      page: '1',
      per: '20',
      search_type: 'name'
    }),
    rows() {
      return this.optionTypes.items.map(item => ({
        ...item,
        option_values: this.optionValuesText(
          item.option_values,
          item.option_values_count
        ),
        parent_option_values: this.optionValuesText(
          item.parent_option_values,
          item.parent_option_values_count
        )
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/product/option_types', { params })
        .then(({ data }) => (this.optionTypes = data.option_types))
        .finally(() => (this.isLoading = false));
    },
    optionValuesText(items, count) {
      const values = items.join(', ');
      const etc_count = count - items.length;
      return etc_count > 0
        ? this.$t('option_values', { values, etc_count })
        : values;
    },
    addOptionType() {
      this.editOptionType(null, '');
    },
    editOptionType(id, name) {
      this.openDialog([
        'ProductOptionTypeFormDialog',
        { id, name, per: this.optionTypes.per }
      ]).then(eventBus => {
        eventBus.$on('update', ({ option_type, option_types }) => {
          this.optionTypes = option_types;
          this.$nextTick(() => {
            this.tableEventBus.$emit(
              'focus-row',
              option_types.items.findIndex(t => t.id === option_type.id)
            );
          });
        });
      });
    },
    manageOptionValues(id, name) {
      this.openDialog([
        'ProductOptionValuesDialog',
        { optionTypeId: id, optionTypeName: name }
      ]);
    },
    deleteOptionType(id) {
      if (confirm(this.$t('app.delete_confirm'))) {
        api
          .delete(`/product/option_types/${id}`, {
            params: {
              ..._.omit(this.resourceParams, 'page', 'per'),
              last_id: _.last(this.optionTypes.items).id
            }
          })
          .then(({ data }) => {
            this.optionTypes = {
              ...this.optionTypes,
              items: [
                ...this.optionTypes.items.filter(i => i.id !== id),
                ...(data.next_option_type ? [data.next_option_type] : [])
              ],
              total_count: this.optionTypes.total_count - 1
            };
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .product-option-types-table__name {
    flex: 1 1 0;
  }

  .product-option-types-table__parent-option-values,
  .product-option-types-table__option-values {
    flex: 4 1 0;
  }

  .product-option-types-table__actions {
    flex: 0 0 240px;
  }
}
</style>

<i18n locale="ko">
{
  "add_option_type": "상품 옵션 추가",
  "option_values": "{values} 외 {etc_count}개",
  "manage_option_values": "옵션값 관리"
}
</i18n>
