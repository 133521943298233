<template>
  <div class="AppHeading">
    <slot>
      <div class="AppHeading__label">{{ label }}</div>
    </slot>
    <div class="AppHeading__controls">
      <slot name="controls" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHeading',
  props: { label: { type: String, default: null } }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppHeading {
  position: relative;
  padding-bottom: 8px;
}

.AppHeading__label {
  @include text-sub-title;
  line-height: 32px;
}

.AppHeading__controls {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 0;
}
</style>
