<template>
  <AppRadioLabel
    :class="['AppRadio', inputStyle ? `AppRadio--${inputStyle}` : null]"
    v-bind="{ disabled, label, inputStyle, inputPosition, tooltip, value }"
    :checked="isChecked"
  >
    <input
      :checked="isChecked"
      :name="name"
      :value="value"
      :class="['AppRadio__input', { 'AppRadio__input--invalid': invalid }]"
      :disabled="disabled"
      type="radio"
      @input="$emit('change', value)"
    />
    <template v-if="$scopedSlots.label" #label="labelProps">
      <slot name="label" v-bind="labelProps" />
    </template>
    <template v-if="$scopedSlots['sub-item']" #sub-item="subItemProps">
      <slot name="sub-item" v-bind="subItemProps" />
    </template>
  </AppRadioLabel>
</template>

<script>
export default {
  name: 'AppRadio',
  model: { prop: 'selectedValue', event: 'change' },
  props: {
    invalid: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    name: { type: String, default: null },
    selectedValue: { type: [String, Number, Boolean], default: null },
    value: { type: [String, Number, Boolean], default: null },
    label: { type: String, default: null },
    tooltip: { type: [String, Object], default: null },
    inputStyle: { type: String, default: null },
    inputPosition: { type: String, default: null }
  },
  computed: {
    isChecked() {
      return this.selectedValue === this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppRadio__input {
  @include radio-checkbox-base;
  border-radius: 50%;

  .AppRadio--standalone & {
    width: $radio-checkbox-size-standalone;
    height: $radio-checkbox-size-standalone;
  }
}
</style>
