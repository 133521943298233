import { constructEnum } from '@/lib/enum';

const ReviewWidgetFont = constructEnum('ReviewWidgetFont', {
  NOTO_SANS_KR: 100,
  NANUM_GOTHIC: 101,
  PRETENDARD: 102,
  LATO: 200,
  MONTSERRAT: 201
});

ReviewWidgetFont.koreanFont = [
  ReviewWidgetFont.NOTO_SANS_KR,
  ReviewWidgetFont.NANUM_GOTHIC,
  ReviewWidgetFont.PRETENDARD
];

ReviewWidgetFont.englishNumberFont = [
  ReviewWidgetFont.LATO,
  ReviewWidgetFont.MONTSERRAT
];

export default ReviewWidgetFont;
