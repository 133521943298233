<template>
  <Component
    :is="component"
    v-bind="buttonProps"
    @click="click"
    @change="change"
  />
</template>

<script>
export default {
  name: 'AppResourceTableSearchbarCustomButton',
  props: {
    controlType: { type: String, default: 'button' },
    button: { type: Object, required: true }
  },
  computed: {
    component() {
      switch (this.controlType) {
        case 'select':
          return 'AppResourceTableSearchbarIconDropdownSelectButton';
        case 'toggle':
          return 'AppResourceTableSearchbarIconButtonToggle';
        default:
          return 'AppResourceTableSearchbarIconButton';
      }
    },
    buttonProps() {
      const { label, icon, tooltip, disabled, options, value } = this.button;

      switch (this.controlType) {
        case 'select':
          return { icon, options, value };
        case 'toggle':
          return { label, icon, tooltip, disabled, value };
        default:
          return { label, icon, tooltip, disabled };
      }
    }
  },
  methods: {
    click() {
      const { clickHandler } = this.button;
      if (clickHandler) clickHandler();
    },
    change(newValue) {
      this.button.value = newValue;
      const { changeHandler } = this.button;
      if (changeHandler) changeHandler(newValue);
    }
  }
};
</script>
