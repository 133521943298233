<template>
  <div class="AppEmailPreview">
    <div class="AppEmailPreview__head">
      <div class="AppEmailPreview__subject">{{ subject }}</div>
      <div class="AppEmailPreview__sender">
        <span class="AppEmailPreview__sender-name">{{ senderName }}</span>
        <span class="AppEmailPreview__sender-email">{{ senderEmailText }}</span>
      </div>
      <div class="AppEmailPreview__time">
        {{ currentTime | formatDateTime }}
      </div>
    </div>
    <div class="AppEmailPreview__body">
      <slot />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AppEmailPreview',
  props: {
    subject: { type: String, required: true },
    senderName: { type: String, required: true },
    senderEmail: { type: String, required: true }
  },
  computed: {
    senderEmailText() {
      return `<${this.senderEmail}>`;
    },
    currentTime() {
      return moment().toISOString();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_body.scss';
@import '@/scss/mixins/_texts.scss';

.AppEmailPreview {
  box-sizing: content-box;
  width: 568px;
  padding: 22px;
}

.AppEmailPreview__head {
  position: relative;
}

$time-width: 128px;

.AppEmailPreview__subject {
  padding-right: $time-width;
  @include text-sub-title;
}

.AppEmailPreview__sender {
  margin-top: 4px;
}

.AppEmailPreview__sender-name {
  @include text-caption-dark;
  font-weight: bold;
}

.AppEmailPreview__sender-email {
  margin-left: 4px;
  @include text-caption;
}

.AppEmailPreview__time {
  position: absolute;
  top: 3px;
  right: 0;
  width: $time-width;
  text-align: right;
  @include text-caption-dark;
}

.AppEmailPreview__body {
  margin-top: 16px;
  pointer-events: none;
}
</style>
