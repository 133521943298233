<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <template #default>
      <TargetCampaignShowHead @delete="campaignDeleted" />
      <TargetCampaignShowInfoBox />
      <TargetCampaignSituationalShowEffects />
      <TargetCampaignSettingsSection
        v-for="{ id, label } in sections"
        :id="id"
        :key="id"
        class="TargetCampaignProductRecommendationWidgetShow__settings-section"
        :title="label"
      >
        <TargetCampaignSettingsWidgetDesign v-if="id === 'design'" />
        <TargetCampaignSettingsProductRecommendation
          v-else-if="id === 'product_recommendation'"
        />
        <TargetCampaignSettingsProductRecommendationMisc
          v-else-if="id === 'misc'"
        />
      </TargetCampaignSettingsSection>
    </template>
  </AppAjaxContent>
</template>

<script>
import TargetCampaignShowView from './TargetCampaignShowView';
import TargetCampaignSettingsWidgetDesign from './TargetCampaignSettingsWidgetDesign';
import TargetCampaignSettingsSection from './components/TargetCampaignSettingsSection';
import TargetCampaignShowHead from './components/TargetCampaignShowHead';
import TargetCampaignShowInfoBox from './components/TargetCampaignShowInfoBox';
import TargetCampaignSituationalShowEffects from './TargetCampaignSituationalShowEffects';
import TargetCampaignSettingsProductRecommendation from './TargetCampaignSettingsProductRecommendation';
import TargetCampaignSettingsProductRecommendationMisc from './TargetCampaignSettingsProductRecommendationMisc';

export default {
  name: 'TargetCampaignProductRecommendationWidgetShow',
  components: {
    TargetCampaignSettingsWidgetDesign,
    TargetCampaignSettingsSection,
    TargetCampaignShowHead,
    TargetCampaignShowInfoBox,
    TargetCampaignSituationalShowEffects,
    TargetCampaignSettingsProductRecommendation,
    TargetCampaignSettingsProductRecommendationMisc
  },
  mixins: [TargetCampaignShowView],
  computed: {
    sections() {
      return [
        { id: 'design', label: this.$t('design') },
        {
          id: 'product_recommendation',
          label: this.$t('product_recommendation')
        },
        { id: 'misc', label: this.$t('misc') }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignProductRecommendationWidgetShow__settings-section {
  margin-top: 24px;
}
</style>

<i18n locale="ko">
{
  "design": "디자인",
  "product_recommendation": "상품 추천",
  "misc": "기타 설정"
}
</i18n>
