var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAdvancedSearch',_vm._g({attrs:{"resource-params":_vm.resourceParams,"visible":_vm.visible,"sections":_vm.advancedSearchSections},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
var inputId = ref.inputId;
return [(id === 'sub_brand_ids')?_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectSearchable',{attrs:{"title":_vm.$t('sub_brand_ids.select'),"placeholder":_vm.$t('sub_brand_ids.placeholder'),"items":_vm.subBrands,"columns":[{ id: 'name', label: _vm.$t('sub_brand_ids.name') }],"search-types":[{ value: 'name', label: _vm.$t('sub_brand_ids.name') }],"multiple":""},on:{"search":_vm.searchSubBrands,"change":function($event){_vm.$emit('change', Object.assign({}, _vm.resourceParams,
            {sub_brand_ids: $event.join(',')}))}}})],1):(id === 'review_keywords')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppButton',{attrs:{"label":_vm.$t('review_keywords.add_button')},on:{"click":_vm.addReviewKeyword}}),(_vm.reviewKeywordRows.length)?_c('AppTable',{attrs:{"table-id":"review-reviews-advanced-search-review-tags-table","rows":_vm.reviewKeywordRows,"columns":_vm.reviewKeywordColumns,"no-head":""},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
          var row = ref.row;
          var column = ref.column;
          var rowIndex = ref.rowIndex;
return [(column.id == 'nlp_category_type_id')?[_c('AppSelect',{attrs:{"options":_vm.nlpCategoryTypes.map(function (t) { return ({ label: t.name, value: t.id }); })},on:{"change":function($event){return _vm.changeReviewKeywordTypeId(rowIndex, null)}},model:{value:(row.nlp_category_type_id),callback:function ($$v) {_vm.$set(row, "nlp_category_type_id", $$v)},expression:"row.nlp_category_type_id"}})]:(column.id == 'review_tag_type_id')?[_c('AppSelectFilterable',{attrs:{"options":_vm.reviewKeywordTypeOptions[row.nlp_category_type_id] || [],"placeholder":_vm.$t('review_keywords.placeholder.review_tag_type_id')},on:{"change":function($event){return _vm.changeReviewKeywordTypeId(rowIndex, $event)}},model:{value:(row.review_tag_type_id),callback:function ($$v) {_vm.$set(row, "review_tag_type_id", $$v)},expression:"row.review_tag_type_id"}})]:(column.id == 'sentiments')?[_c('AppMultipleSelect',{attrs:{"options":_vm.ReviewSentimentType.options(),"placeholder":_vm.$t('review_keywords.placeholder.sentiments')},on:{"change":function($event){return _vm.changeReviewKeywordSentiments(rowIndex, $event)}},model:{value:(row.sentiments),callback:function ($$v) {_vm.$set(row, "sentiments", $$v)},expression:"row.sentiments"}})]:(column.id == 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('app.delete'),"button-style":"red-outline"},on:{"click":function($event){return _vm.deleteReviewKeyword(rowIndex)}}})]:_vm._e()]}}],null,true)}):_vm._e()],1)]:(id === 'keyword_group_id')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppButton',{attrs:{"id":inputId,"label":_vm.$t('keyword_group_button'),"button-style":"mint-green"},on:{"click":_vm.openKeywordGroupsDialog}})],1),_c('div',{staticClass:"AppForm__group-field"},[(_vm.keywordGroups.length)?_c('AppHashSelectButton',{attrs:{"hash":_vm.resourceParams,"select-buttons":[
            {
              id: 'keyword_group_id',
              options: _vm.keywordGroups.map(function (i) { return ({
                label: i.name,
                value: i.id
              }); })
            }
          ]},on:{"change":function($event){return _vm.$emit('change', $event)}}}):_vm._e()],1)]:_vm._e()]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }