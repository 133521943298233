import { constructEnum } from '@/lib/enum';

const ShopBuilder = constructEnum('ShopBuilder', {
  CAFE24: 10,
  MAKESHOP: 30,
  MAKEGLOB: 35,
  GODO: 40,
  WISA: 50,
  BIZHOST: 60,
  SHOPIFY: 70,
  SHOPBY: 80,
  IMWEB: 90,
  NONE: 0
});

ShopBuilder.shopKey = function(shopBuilder, mallId, shopNo = undefined) {
  shopBuilder = ShopBuilder.key(shopBuilder).toLowerCase();
  return [shopBuilder, mallId, shopNo].filter(Boolean).join('/');
};

ShopBuilder.parseShopKey = function(shopKey) {
  const [shopBuilder, mallId, shopNo] = shopKey.split('/');

  const parsed = {
    shopBuilder: ShopBuilder[shopBuilder.toUpperCase()],
    mallId: mallId
  };

  if (shopNo) {
    parsed.shopNo = parseInt(shopNo);
  }

  return parsed;
};

export default ShopBuilder;
