<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Default Row Table</div>
      <AppResourceTable :columns="DEFAULT_COLUMNS" :rows="DEFAULT_ROWS" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Wide Row Table</div>
      <AppResourceTable
        :columns="DEFAULT_COLUMNS"
        :rows="DEFAULT_ROWS"
        table-style="wide"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Main Columns In Head</div>
      <AppResourceTable
        :main-columns="MAIN_COLUMNS"
        :columns="MAIN_COLUMNS_TABLE_COLUMNS"
        :rows="DEFAULT_TABLE_ROWS"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Tooltip</div>
      <AppResourceTable :columns="TOOLTIP_TABLE_COLUMNS" :rows="[]" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Statistics Table</div>
      <AppResourceTable
        :columns="STATISTICS_TABLE_COLUMNS"
        :rows="STATISTICS_TABLE_ROWS"
        table-style="stats"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Depth Table</div>
      <AppResourceTable
        :columns="DEPTH_TABLE_COLUMNS"
        :rows="DEPTH_TABLE_ROWS"
        table-style="depth"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Custom Buttons</div>
      <AppResourceTable
        :columns="DEFAULT_COLUMNS"
        :rows="[]"
        :custom-buttons="[
          {
            label: '샘플1',
            icon: 'icon-close-small',
            clickHandler: clickSearchbarButton
          },
          {
            label: '샘플2',
            icon: 'icon-search-small',
            clickHandler: clickSearchbarButton
          },
          {
            icon: 'icon-calendar-small',
            controlType: 'select',
            options: [
              { label: '전체', value: 0 },
              { label: '1번', value: 1 },
              { label: '2번', value: 2 }
            ],
            value: 0
          }
        ]"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Refresh Button</div>
      <AppResourceTable
        :columns="DEFAULT_COLUMNS"
        :rows="[]"
        enable-refresh
        @refresh="refreshTable"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Column Settings Button</div>
      <AppResourceTable
        table-id="ui-column-settings-table"
        :columns="COLUMN_SETTINGS_TABLE_COLUMNS"
        :rows="[]"
        enable-column-settings
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Batch Buttons</div>
      <AppAjaxContent :is-loading="isLoading">
        <AppResourceTable
          table-id="ui-resource-table-batch"
          :resources="resources"
          :columns="PRODUCT_TABLE_COLUMNS"
          :rows="rows"
          :batch-buttons="[
            {
              label: 'batch1',
              clickHandler: batch1,
              tooltip: 'batch1 tooltip'
            },
            {
              label: 'batch2',
              controlType: 'menu',
              menuItems: [
                {
                  label: 'menu1',
                  tooltip: 'menu1 tooltip',
                  clickHandler: batch2
                }
              ]
            }
          ]"
          @paginate="paginate"
          @select-rows="selectedRows = $event"
        />
      </AppAjaxContent>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Filter Rows</div>
      <AppAjaxContent :is-loading="isLoading">
        <AppResourceTable
          :columns="DEFAULT_COLUMNS"
          :rows="filteredRows"
          :filter-values="filterValues"
          :filter-options="[
            {
              label: '첫 번째 행 보기',
              value: 1
            },
            {
              label: '두 번째 행 보기',
              value: 2
            }
          ]"
          @change-filter-values="filterValues = $event"
        />
      </AppAjaxContent>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Custom Searchbar</div>
      <AppAjaxContent :is-loading="isLoading">
        <AppResourceTable :columns="DEFAULT_COLUMNS" :rows="rows">
          <template #additional-table-searchbar>
            <AppSelect
              :options="[
                { label: '선택해보세요' },
                { label: '선택지1', value: 1 },
                { label: '선택지2', value: 2 }
              ]"
            />
          </template>
        </AppResourceTable>
      </AppAjaxContent>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceView from '@/mixins/ResourceView';

const SAMPLE_BRAND_ID = 1503;

export default {
  name: 'UiResourceTable',
  mixins: [ResourceView],
  data() {
    return {
      DEFAULT_COLUMNS: [
        { id: 'username', label: '아이디' },
        { id: 'name', label: '이름' },
        { id: 'tags', label: '태그', type: 'badges' },
        { id: 'description', label: '비고', type: 'nowrap', autoTooltip: true }
      ],
      DEFAULT_ROWS: [
        { username: 'joongimin', name: '민준기' },
        {
          username: 'illil',
          name: '김덕일',
          tags: [{ id: 1, label: '베스트 리뷰어', style: 'mint-green-outline' }]
        },
        {
          username: 'crema',
          name: '크리마',
          description:
            'Modern espresso, using hot water under pressure, as pioneered by Gaggia in the 1940s, was originally called crema caffè (in English, "cream coffee") as seen on old Gaggia machines, due to the crema.'
        }
      ],
      MAIN_COLUMNS: [
        {},
        { label: '리뷰수 기준', colspan: 3 },
        { label: '인원수 기준', colspan: 3 }
      ],
      MAIN_COLUMNS_TABLE_COLUMNS: [
        { id: 'date', label: '날짜' },
        { id: 'sub_order_reviews_count', label: '리뷰수' },
        { id: 'sub_orders_count', label: '배송완료된 상품수' },
        {
          id: 'sub_order_reviews_count_per_sub_orders_count',
          label: '리뷰 작성률',
          type: 'percentage'
        },
        { id: 'sub_order_users_count_with_review', label: '리뷰인원수' },
        { id: 'users_count_with_sub_order', label: '배송완료된 인원수' },
        {
          id:
            'sub_order_users_count_with_review_per_users_count_with_sub_order',
          label: '리뷰 작성률',
          type: 'percentage'
        }
      ],
      TOOLTIP_TABLE_COLUMNS: [
        { id: 'subject', type: 'pre', label: '과목', tooltip: '과목입니다.' },
        { id: 'score', type: 'number', label: '점수' }
      ],
      DEFAULT_TABLE_ROWS: [
        {
          date: '2020. 01. 01',
          sub_order_reviews_count: 3,
          sub_orders_count: 5,
          sub_order_reviews_count_per_sub_orders_count: 60,
          sub_order_users_count_with_review: 3,
          users_count_with_sub_order: 5,
          sub_orders_users_Count_with_review_per_users_count_with_sub_order: 60
        },
        {
          date: '2020. 01. 02',
          sub_order_reviews_count: 4,
          sub_orders_count: 5,
          sub_order_reviews_count_per_sub_orders_count: 80,
          sub_order_users_count_with_review: 4,
          users_count_with_sub_order: 5,
          sub_orders_users_count_with_review_per_users_count_with_sub_order: 80
        }
      ],
      STATISTICS_TABLE_COLUMNS: [
        { id: 'object', type: 'pre', label: '물건' },
        { id: 'price', type: 'number', label: '금액' }
      ],
      STATISTICS_TABLE_ROWS: [
        {
          object: '총합',
          price: 100000,
          rowStyle: 'stats-total'
        },
        {
          object: '지우개',
          price: 10000,
          rowStyle: 'stats-summary'
        },
        {
          object: '카드',
          price: 10000,
          rowStyle: 'stats-detail'
        },
        {
          object: '현금',
          price: 10000,
          rowStyle: 'stats-detail'
        },
        {
          object: '연필',
          price: 10000,
          rowStyle: 'stats-summary'
        },
        {
          object: '카드',
          price: 10000,
          rowStyle: 'stats-detail'
        },
        {
          object: '현금',
          price: 10000,
          rowStyle: 'stats-detail'
        },
        {
          object: '필통',
          price: 70000,
          rowStyle: 'stats-summary'
        },
        {
          object: '카드',
          price: 70000,
          rowStyle: 'stats-detail'
        },
        {
          object: '현금',
          price: 70000,
          rowStyle: 'stats-detail'
        }
      ],
      DEPTH_TABLE_COLUMNS: [
        { id: 'state', label: '주', depth: 1 },
        { id: 'city', label: '도시', depth: 2 }
      ],
      DEPTH_TABLE_ROWS: [
        { state: 'Illinois', depth: 1 },
        { state: 'Illinois', city: 'Chicago', depth: 2 },
        { state: 'Texas', depth: 1 },
        { state: 'Texas', city: 'San Antonio', depth: 2 },
        { state: 'California', depth: 1 },
        { state: 'California', city: 'Los Angeles', depth: 2 }
      ],
      PRODUCT_TABLE_COLUMNS: [
        { id: 'row_select', type: 'row_select' },
        { id: 'name', label: 'Name', align: 'left' },
        {
          id: 'image_url',
          label: 'Image(img)',
          type: 'img'
        },
        { id: 'summary', label: 'Summary(product)', type: 'product' },
        {
          id: 'org_price',
          label: 'Price(number)',
          type: 'number',
          align: 'right'
        },
        { id: 'created_at', label: 'Created At(datetime)', type: 'datetime' }
      ],
      isLoading: true,
      resources: nullResources,
      filterValues: [1, 2],
      selectedRows: []
    };
  },
  computed: {
    COLUMN_SETTINGS_TABLE_COLUMNS() {
      return [
        {
          id: 'column1',
          label: 'default'
        },
        {
          id: 'column2',
          label: 'required',
          required: true
        },
        {
          id: 'column3',
          label: 'hide by default',
          hideByDefault: true
        },
        {
          id: 'column4',
          label: 'button',
          buttonConfig: {
            icon: 'icon-setting-small',
            label: this.$t('app.settings'),
            clickHandler: () => console.log('clicked!')
          }
        }
      ];
    },
    rows() {
      return this.resources.items.map(item => ({ ...item, summary: item }));
    },
    filteredRows() {
      return [
        {
          username: 'hello',
          name: '1',
          value: 1,
          tags: ['ni hao', '안녕'].map(label => ({
            id: label,
            label,
            size: 'small'
          })),
          description: '영어, 중국어, 한국어'
        },
        { username: 'world', name: '2', value: 2 }
      ].filter(row => this.filterValues.includes(row.value));
    }
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    fetchResource(resourceParams) {
      this.isLoading = true;
      const params = { ...resourceParams, brand_id: SAMPLE_BRAND_ID };
      api
        .get('/products', { params })
        .then(({ data }) => (this.resources = data.products))
        .finally(() => (this.isLoading = false));
    },
    clickSearchbarButton() {
      console.log('clicked!');
    },
    refreshTable() {
      console.log('refresh!');
    },
    batch1() {
      this.createToast(
        `batch1 for ${this.selectedRows.map(r => r.id).join(', ')}`
      );
    },
    batch2() {
      this.createToast(
        `batch2 for ${this.selectedRows.map(r => r.id).join(', ')}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .ui-resource-table-batch__image-url {
  flex: 0 0 100px;
}
</style>
