import { constructEnum } from '@/lib/enum';

const ReviewCampaignStatus = constructEnum('ReviewCampaignStatus', {
  DISABLED: 0,
  ENABLED: 10
});

ReviewCampaignStatus.badgeStyle = status => {
  switch (status) {
    case ReviewCampaignStatus.DISABLED:
      return 'red-outline';
    case ReviewCampaignStatus.ENABLED:
      return 'mint-green';
    default:
      return 'default';
  }
};

export default ReviewCampaignStatus;
