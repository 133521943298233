<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppResourceTable
      class="DisplayCornersTable"
      :columns="columns"
      :rows="rows"
      :enable-total-count="false"
      :filter-options="filterOptions"
      :filter-values="categoryFilterValues"
      :custom-buttons="customButtons"
      table-id="corner-table"
      table-style="wide"
      @change-filter-values="categoryFilterValues = $event"
    >
      <template #cell="{ row, column, value }">
        <template v-if="column === 'favorite'">
          <button @click="requestFavorite(row)">
            <AppSvgIcon
              name="icon-star"
              :class="[
                'DisplayCornersTable__favorite-content',
                value ? 'DisplayCornersTable__favorite-content--favorited' : ''
              ]"
            />
          </button>
        </template>
        <template v-if="column === 'virtual'">
          <AppBadge
            v-if="value"
            badge-style="grey-outline"
            :label="$t('column.CATEGORY.virtual.tag')"
          />
        </template>
        <template v-else-if="column === 'name'">
          <span class="DisplayCornersTable__name-content">{{ value }}</span>
        </template>
        <template v-else-if="column === 'last_displayed_at'">
          <template v-if="value">
            <div>{{ value | formatDateTimeWithoutSecond }}</div>
            <AppBadge
              class="DisplayCornersTable__detail"
              :label="row.last_display_name"
              badge-style="grey-outline"
              with-max-width
            />
          </template>
          <template v-else>-</template>
        </template>
        <template v-else-if="column === 'scheduled_product_displays'">
          <template v-if="value.length > 0">
            <div>
              {{ value[0].display_at | formatDateTimeWithoutSecond }}
            </div>
            <DisplayCornerScheduledProductDisplaysSummary
              class="DisplayCornersTable__detail"
              :scheduled-product-displays="row.scheduled_product_displays"
            />
          </template>
          <template v-else>-</template>
        </template>
        <template v-else-if="column === 'corner_status'">
          <DisplayCornerStatusBadge
            :corner-status="row.corner_status"
            :cohort="row.applying_cohort"
          />
          <AppButton
            v-if="isErrored(row.corner_status)"
            :label="$t('show_detail')"
            button-style="underline"
            @click="openErrorDetailDialog(row)"
          />
          <DisplayCornerDeleteButton
            v-if="DisplayCornerStatus.isDeleted(row.corner_status)"
            class="DisplayCornersTable__detail"
            :corner="row"
            @delete="removeCornerFromList(row.id)"
          />
        </template>
        <template v-else-if="column === 'actions'">
          <AppButton
            :label="$t('button.manage', [Math.min(row.displays_count, 99)])"
            @click="openDisplayCornerShowDrawer(row, 'product_displays')"
          />
          <AppDropdownMenuButton
            v-if="isCategoryPage"
            :label="$t('app.manage')"
            :menu-items="[
              {
                id: 'favorite',
                label: $t(
                  `button.${row.favorite ? 'favorited' : 'unfavorited'}`
                ),
                selected: row.favorite,
                clickHandler: () => requestFavorite(row)
              },
              {
                id: 'history',
                label: $t('button.history'),
                clickHandler: () => openDisplayCornerShowDrawer(row, 'cohorts')
              }
            ]"
          />
          <AppButton
            v-else
            :label="$t('button.history')"
            :disabled="!row.cohorts_exist"
            @click="openDisplayCornerShowDrawer(row, 'cohorts')"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';
import ShopBuilder from '@/enums/ShopBuilder';
import DisplayProductDisplayExecuteStatus from '@/enums/DisplayProductDisplayExecuteStatus';
import { filterItems } from '@/lib/text-search';
import DisplayCornerStatus from '@/enums/DisplayCornerStatus';
import ProductCategoryFilterType from '@/enums/ProductCategoryFilterType';

export default {
  name: 'DisplayCornersTable',
  props: {
    searchType: { type: String, default: '' },
    searchQuery: { type: String, default: '' }
  },
  data: () => ({
    isLoading: true,
    corners: [],
    categoryFilterOptions: ProductCategoryFilterType.options(),
    categoryFilterValues: ProductCategoryFilterType.values,
    showFavoritesOnly: false,
    timer: null
  }),
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('displayProductDisplays', ['cornerPageType', 'isPolling']),
    ...mapState('displayProductDisplay', ['isStatusUpdated']),
    ...mapGetters('displayProductDisplays', [
      'isCategoryPage',
      'cornerPageTypeKey'
    ]),
    ...mapGetters('productCategory', ['productCategoriesWithTreeName']),
    DisplayCornerStatus: () => DisplayCornerStatus,
    rows() {
      return this.isCategoryPage ? this.filteredCategoryCorners : this.corners;
    },
    filteredCategoryCorners() {
      const filterValues = [
        ...this.categoryFilterValues,
        ProductCategoryFilterType.ZERO_PRODUCT
      ];

      let categories = ProductCategoryFilterType.filter(
        this.productCategoriesWithTreeName,
        filterValues
      );

      if (this.searchQuery && this.searchQuery.length > 0) {
        if (this.searchType === 'full_name')
          categories = filterItems(categories, 'treeName', this.searchQuery);
        else if (this.searchType === 'code')
          categories = filterItems(categories, 'code', this.searchQuery);
      }

      const cornersMap = {};
      this.corners.forEach(corner => (cornersMap[corner.code] = corner));
      let items = categories
        .map(category => {
          const corner = cornersMap[category.code];
          if (!corner) return;

          return {
            ...corner,
            name: category.treeName,
            virtual: category.virtual
          };
        })
        .filter(v => v);

      if (this.showFavoritesOnly) items = items.filter(item => item.favorite);

      return items;
    },
    filterOptions() {
      return this.isCategoryPage ? this.categoryFilterOptions : [];
    },
    customButtons() {
      return this.isCategoryPage
        ? [
            {
              controlType: 'toggle',
              label: this.$t('button.show_favorites_only'),
              icon: 'icon-star',
              tooltip: this.isFavoriteCornersExists
                ? ''
                : this.$t('button.show_favorites_only_tooltip'),
              disabled: !this.isFavoriteCornersExists,
              value: this.showFavoritesOnly,
              changeHandler: this.changeShowFavoritesOnly
            }
          ]
        : [];
    },
    isFavoriteCornersExists() {
      return this.corners.filter(corner => corner.favorite).length > 0;
    },
    mainColumns() {
      return [
        {
          id: 'name',
          label: this.$t('column.MAIN.name.title'),
          tooltip: {
            message: this.$t('column.MAIN.name.tooltip'),
            textAlign: 'left'
          },
          align: 'left',
          width: '280px'
        },
        { id: 'last_displayed_at', align: 'left', width: '240px' },
        { id: 'scheduled_product_displays', align: 'left', width: '240px' },
        { id: 'corner_status', width: '108px' },
        { id: 'actions', width: '240px' }
      ].map(column => ({
        ...column,
        label: column.label
          ? column.label
          : this.$t(`column.${column.id}.title`)
      }));
    },
    categoryColumns() {
      const categoryColumnConfigs = [
        { id: 'favorite', width: '44px' },
        ...(this.currentBrand.shop_builder === 'makeshop'
          ? [{ id: 'virtual', width: '60px' }]
          : []),
        { id: 'code', width: '88px' },
        { id: 'name', align: 'left', width: '228px' }
      ].map(column => ({
        ...column,
        label: this.$t(`column.${this.cornerPageTypeKey}.${column.id}.title`)
      }));

      const commonColumnConfigs = [
        { id: 'last_displayed_at', align: 'left', width: '168px' },
        { id: 'scheduled_product_displays', align: 'left', width: '200px' },
        { id: 'corner_status', width: '108px' },
        { id: 'actions', width: '212px' }
      ].map(column => ({
        ...column,
        label: this.$t(`column.${column.id}.title`)
      }));

      return [...categoryColumnConfigs, ...commonColumnConfigs];
    },
    columns() {
      return this.isCategoryPage ? this.categoryColumns : this.mainColumns;
    }
  },
  watch: {
    isStatusUpdated() {
      if (this.isStatusUpdated) this.fetchCorners();
    },
    isPolling() {
      if (this.isPolling) this.startPolling();
      else this.stopPolling();
    }
  },
  mounted() {
    this.fetchCorners();
    this.SET_POLLING(true);
    this.startPolling();
  },
  beforeDestroy() {
    this.SET_POLLING(false);
    this.stopPolling();
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('drawer', ['openDrawer']),
    ...mapMutations('displayProductDisplay', ['UPDATE_IS_STATUS_UPDATED']),
    ...mapMutations('displayProductDisplays', ['SET_POLLING']),
    fetchCorners() {
      api
        .get('display/corners', {
          params: { page_type: this.cornerPageType }
        })
        .then(({ data }) => {
          this.corners = data.corners;
          this.UPDATE_IS_STATUS_UPDATED(false);
        })
        .finally(() => (this.isLoading = false));
    },
    removeCornerFromList(cornerId) {
      this.corners = this.corners.filter(({ id }) => id !== cornerId);
    },
    openDisplayCornerShowDrawer(corner, tab) {
      this.openDrawer([
        'DisplayCornerShowDrawer',
        { corner, tab }
      ]).then(eventBus =>
        eventBus.$on('delete', () => this.removeCornerFromList(corner.id))
      );
    },
    requestFavorite(corner) {
      api
        .patch('display/corners/favorite', {
          id: corner.id,
          value: !corner.favorite
        })
        .then(({ data }) => {
          this.corners = this.corners.map(corner =>
            corner.id === data.id
              ? { ...corner, favorite: data.favorite }
              : corner
          );
        });
    },
    changeShowFavoritesOnly(newValue) {
      this.showFavoritesOnly = newValue;
    },
    startPolling() {
      if (this.timer) return;
      this.timer = setInterval(() => this.fetchCorners(), 5000);
    },
    stopPolling() {
      clearInterval(this.timer);
      this.timer = null;
    },
    isErrored(corner_status) {
      return DisplayCornerStatus.isErrored(corner_status);
    },
    openErrorDetailDialog(row) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t(`error_detail.${this.cornerPageTypeKey}.title`),
          markdownText: this.errorMessage(row),
          width: DialogSize.SMALL
        }
      ]);
    },
    errorMessage(row) {
      const erroredCohort = row.errored_cohort;
      let argument;
      switch (erroredCohort.execute_status) {
        case DisplayProductDisplayExecuteStatus.ERROR_DISPATCHING:
        case DisplayProductDisplayExecuteStatus.ERROR_LOGIN_BLOCKED:
        case DisplayProductDisplayExecuteStatus.ERROR_SESSION_EXPIRED:
          argument = ShopBuilder.tk(this.currentBrand.shop_builder);
          break;
        case DisplayProductDisplayExecuteStatus.ERROR_CORNER_NOT_EXISTS:
          argument = erroredCohort.corner_name;
          break;
      }

      return this.$t(
        `error_detail.${
          this.cornerPageTypeKey
        }.${DisplayProductDisplayExecuteStatus.key(
          erroredCohort.execute_status
        )}`,
        [argument]
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayCornersTable {
  margin-top: 32px;
}

.DisplayCornersTable__favorite-content {
  display: block;
  margin: auto;
  stroke: $color-grey-25;
  fill: $color-grey-25;

  &--favorited {
    stroke: $color-mustard;
    fill: $color-mustard;
  }
}

.DisplayCornersTable__name-content {
  @include text-sub-title;
}

.DisplayCornersTable__detail {
  margin-top: 4px;
  vertical-align: middle;
}
</style>

<i18n locale="ko">
{
  "button": {
    "manage": "{0}개의 설정 관리",
    "history": "진열 이력",
    "unfavorited": "관심 카테고리 설정",
    "favorited": "관심 카테고리 해제",
    "show_favorites_only": "관심 카테고리만 보기",
    "show_favorites_only_tooltip": "설정된 관심 카테고리가 없습니다.\n카테고리 리스트에서 관리 버튼을 눌러 설정해주세요"
  },
  "column": {
    "MAIN": {
      "name": {
        "title": "진열 위치",
        "tooltip": "빌더사에서 설정 해놓은 진열 위치를 가져옵니다.\n각 진열 위치에 진열 설정을 추가하여 상품을 진열할 수 있습니다.\n(예: 카페24의 메인 분류, 메이크샵의 메인 코너)"
      }
    },
    "CATEGORY": {
      "favorite": {
        "title": "관심"
      },
      "virtual": {
        "title": "분류",
        "tag": "가상"
      },
      "code": {
        "title": "코드"
      },
      "name": {
        "title": "카테고리"
      }
    },
    "last_displayed_at": {
      "title": "최근 진열 적용일"
    },
    "scheduled_product_displays": {
      "title": "진열 예약"
    },
    "corner_status": {
      "title": "상태"
    },
    "actions": {
      "title": "기능"
    }
  },
  "show_detail": "자세히 보기",
  "error_detail": {
    "MAIN": {
      "title": "메인 페이지 진열 중 문제가 발생했습니다.",
      "ERROR_PREPARING": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_CALCULATING": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_DISPATCHING": "{0} 메인페이지 진열 중 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_CORNER_NOT_EXISTS": "{0} 진열 위치를 찾을 수 없습니다.<br>[진열 위치 동기화] 후 다시 시도하시기 바랍니다.",
      "ERROR_LOGIN_BLOCKED": "{0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_SESSION_EXPIRED": "세션이 만료되어 {0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다."
    },
    "CATEGORY": {
      "title": "카테고리 페이지 진열 중 문제가 발생했습니다.",
      "ERROR_PREPARING": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_CALCULATING": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_DISPATCHING": "{0} 카테고리 페이지 진열 중 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_CORNER_NOT_EXISTS": "{0} 카테고리를 찾을 수 없습니다.<br>카테고리 정보 확인 후 다시 시도하시기 바랍니다.",
      "ERROR_LOGIN_BLOCKED": "{0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_SESSION_EXPIRED": "세션이 만료되어 {0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다."
    }
  }
}
</i18n>
