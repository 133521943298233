<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title', [formatUser(review)])"
    :form-props="{
      ...formProps,
      submitLabel: $t('apply_tags'),
      submittingLabel: $t('applying_tags')
    }"
    width="middle"
    v-on="formEvents"
  >
    <template #searchbar>
      <li>
        <AppButton button-style="red" :label="$t('add_tag')" @click="addTag" />
      </li>
    </template>
    <template #group="{ id, errors }">
      <AppTable
        v-if="id === 'tags'"
        :columns="COLUMNS"
        :rows="tags"
        :no-data="$t('no_data')"
      >
        <template #cell="{ row, column, rowIndex }">
          <template v-if="column.id === 'select'">
            <AppCheckbox
              v-model="tags[rowIndex].selected"
              input-style="standalone"
            />
          </template>
          <template v-else-if="column.id === 'name'">
            <AppTextInput
              :ref="`tagName${rowIndex}`"
              v-model="tags[rowIndex].name"
              :placeholder="$t('tag_name_placeholder')"
              :invalid="!!errors[`tags[${row.id}][name]`]"
              @blur="validateTagName(row, errors)"
              @change="validateTagName(row, errors)"
            />
            <AppFormError :error="errors[`tags[${row.id}][name]`]" />
          </template>
          <template v-else-if="column.id === 'remove_tag'">
            <div
              v-tooltip="
                row.reviews_count
                  ? $t('remove_tag_disable_tooltip', {
                      reviews_count: row.reviews_count
                    })
                  : null
              "
            >
              <AppButton
                button-style="red-outline"
                :disabled="!!row.reviews_count"
                :label="$t('app.delete')"
                @click="removeTag(rowIndex)"
              />
            </div>
          </template>
        </template>
      </AppTable>
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewReviewsTagsDialog',
  mixins: [DialogFormView],
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'select', label: this.$t('app.select') },
        { id: 'name', label: this.$t('tag_name') },
        { id: 'reviews_count', label: this.$t('reviews_count') },
        { id: 'remove_tag', label: this.$t('remove_tag') }
      ],
      removedTagIds: []
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'tags',
              label: null,
              fields: this.tags.map(tag => ({
                id: `tags[${tag.id}][name]`,
                value: () => tag.name,
                validate: [
                  'required',
                  {
                    rule: v => this.nameCountsMap[v] === 1,
                    errorMessage: this.$t('tag_name_must_be_unique')
                  }
                ]
              }))
            }
          ]
        }
      ];
    },
    tags() {
      return this.formObject.tags || [];
    },
    nameCountsMap() {
      return _.countBy(this.tags, 'name');
    }
  },
  mounted() {
    const selectedTagIds = this.review.tags.map(t => t.id);
    this.orgFormObject = {
      tags: this.$store.state.review.tags.map(tag => ({
        ...tag,
        selected: selectedTagIds.includes(tag.id) ? true : undefined
      }))
    };
  },
  methods: {
    ...mapActions('review', ['updateTagsReview']),
    addTag() {
      this.tags.unshift({ id: _.uniqueId('new_tag'), isNewTag: true });
      this.$nextTick(() => this.$refs.tagName0.$el.focus());
    },
    removeTag(index) {
      const id = this.tags[index].id;
      this.tags.splice(index, 1);

      if (id) this.removedTagIds.push(id);
      this.validateField('tags');
    },
    submit() {
      this.isSubmitting = true;
      this.updateTagsReview({
        reviewId: this.review.id,
        tags_attributes: [
          ...this.tags.map(({ id, name, selected, isNewTag }) => ({
            id: isNewTag ? null : id,
            name,
            selected
          })),
          ...this.removedTagIds.map(id => ({ id, removed: true }))
        ]
      })
        .then(() => this.close(true))
        .finally(() => (this.isSubmitting = false));
    },
    validateTagName(tag, errors) {
      this.validateField(`tags[${tag.id}][name]`);
      Object.keys(errors).forEach(id => this.validateField(id));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "태그 설정",
  "sub_title": "{0}님의 리뷰",
  "add_tag": "태그 추가",
  "tag_name": "태그 이름",
  "tag_name_placeholder": "태그 이름을 입력해주세요.",
  "tag_name_must_be_unique": "같은 이름의 태그가 있습니다.",
  "reviews_count": "태그한 리뷰수",
  "remove_tag": "태그 삭제",
  "remove_tag_disable_tooltip": "태그를 지정한 리뷰가 {reviews_count}개 있어 태그를 삭제할 수 없습니다. 태그를 삭제하려면 리뷰에 태그 지정을 해제해주세요.",
  "no_data": "등록된 태그가 없습니다.",
  "apply_tags": "태그 적용",
  "applying_tags": "태그 적용 중.."
}
</i18n>
<i18n locale="en">
{
  "title": "Add tag",
  "sub_title": "{0}'s review",
  "add_tag": "Add tag",
  "tag_name": "Tag name",
  "tag_name_placeholder": "Write tag name.",
  "tag_name_must_be_unique": "Tag name must be unique.",
  "reviews_count": "Tagged reviews count",
  "remove_tag": "Remove tag",
  "remove_tag_disable_tooltip": "Tag cannot be removed because it has {reviews_count} reviews with the tag. To remove tags, please untag the review.",
  "no_data": "No tags.",
  "apply_tags": "Apply tag",
  "applying_tags": "Applying tag.."
}
</i18n>
