<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{
      ...formProps,
      focusGroupId: 'smartstore_name'
    }"
    v-on="formEvents"
  />
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';
import api from '@/lib/api';

export default {
  name: 'TheSmartstoreNameDialog',
  mixins: [DialogFormView],
  props: {
    smartstoreName: {
      type: String,
      required: true
    }
  },
  data() {
    return { orgFormObject: { smartstore_name: this.smartstoreName } };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'smartstore_name',
              type: 'text',
              validate: ['required'],
              label: this.$t('name')
            }
          ]
        }
      ];
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/smartstore/home/update_smartstore_name', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.eventBus.$emit('save', data.smartstore_name);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "스마트스토어 이름 확인",
  "name": "스마트스토어 이름"
}
</i18n>
