import _ from 'lodash';
import Vue from 'vue';

const defaultState = { jobs: [] };

const mutations = {
  ADD_JOB({ jobs }, job) {
    jobs.push(job);
  },
  REMOVE_JOB(state, jobId) {
    state.jobs = state.jobs.filter(job => job.id !== jobId);
  },
  REMOVE_JOBS(state, jobType) {
    state.jobs = state.jobs.filter(job => job.type !== jobType);
  },
  UPDATE_PROGRESS(state, { id, progressCount, progressStatus, title }) {
    const job = state.jobs.find(j => j.id === id);
    if (job) {
      Vue.set(job, 'progressCount', progressCount);
      if (progressStatus) Vue.set(job, 'progressStatus', progressStatus);
      if (title) Vue.set(job, 'title', title);
    }
  },
  COMPLETE_JOB({ jobs }, { id, result }) {
    const job = jobs.find(j => j.id === id);
    if (job) Vue.set(job, 'result', result);
  }
};

const actions = {
  enqueueJob({ commit, dispatch }, job) {
    const id = job.id ? job.id : _.uniqueId('job');
    commit('ADD_JOB', { ...job, id });
    if (job.jobHandler) dispatch('runJob', id);
  },
  async runJob({ state, commit }, id) {
    const job = state.jobs.find(j => j.id === id);

    const result = await job.jobHandler({
      progressTo: ({ progressCount, progressStatus }) =>
        commit('UPDATE_PROGRESS', { id: job.id, progressCount, progressStatus })
    });

    const waitForTransition = () => new Promise(r => setTimeout(r, 50));
    await waitForTransition();

    commit('COMPLETE_JOB', { id: job.id, result });
  },
  upsertJob({ state, commit, dispatch }, job) {
    if (state.jobs.find(({ id }) => id === job.id))
      commit('UPDATE_PROGRESS', job);
    else dispatch('enqueueJob', job);
  },
  completeJob({ state, commit }, id) {
    const job = state.jobs.find(j => j.id === id);
    const resultHandler = job?.resultHandler;
    if (resultHandler) resultHandler(job.result);
    if (!job.removeAfterConfirm) commit('REMOVE_JOB', id);
  }
};

export default { namespaced: true, state: defaultState, mutations, actions };
