<template>
  <div class="QaJpegSimulationShow">
    <AppAjaxContent :is-loading="isLoading">
      <template #default>
        <AppDataList :data="jpegSimulationData">
          <template #value="item">
            <template v-if="item.id === 'versions'">
              <JpegSimulationVersionsTable
                :versions="jpeg_simulation.versions"
              />
            </template>
          </template>
        </AppDataList>
      </template>
    </AppAjaxContent>
  </div>
</template>

<script>
import JobStatus from '@/enums/JobStatus';
import api from '@/lib/api';
import { mapActions } from 'vuex';
import JpegSimulationVersionsTable from './components/JpegSimulationVersionsTable';

export default {
  name: 'QaJpegSimulationShow',
  comopnents: { JpegSimulationVersionsTable },
  data() {
    return {
      isLoading: true,
      jpegSimulationId: null,
      jpeg_simulation: null
    };
  },
  computed: {
    jpegSimulationData() {
      return [
        {
          id: 'timestamp',
          label: 'Timestamp',
          value: this.jpeg_simulation.timestamp,
          type: 'string'
        },
        {
          id: 'jpeg_processor',
          label: 'Jpeg Processor',
          value: this.jpeg_simulation.jpeg_processor,
          type: 'string'
        },
        {
          id: 'job_status',
          label: 'Job Status',
          value: JobStatus.key(this.jpeg_simulation.job_status),
          type: 'string'
        },
        {
          id: 'server_env',
          label: 'Server Env',
          value: this.jpeg_simulation.server_env,
          type: 'string'
        },
        {
          id: 'total_duration',
          label: 'Total Duration (sec)',
          value: this.jpeg_simulation.total_duration_ms / 1000,
          type: 'number'
        },
        {
          id: 'total_file_size',
          label: 'Total File Size (byte)',
          value: this.jpeg_simulation.total_file_size,
          type: 'number'
        },
        {
          id: 'versions',
          label: 'Versions',
          type: 'custom'
        }
      ];
    }
  },
  mounted() {
    this.jpegSimulationId = this.$route.params.id;
    this.fetchJpegSimulations();
    this.setPageBreadcrumbs([
      {
        id: 1,
        label: `Jpeg Simulation #${this.jpegSimulationId}`,
        path: `/qa/jpeg-simulations/${this.jpegSimulationId}`
      }
    ]);
  },
  beforeDestroy() {
    this.clearPageBreadcrumbs([]);
  },
  methods: {
    ...mapActions('pageBreadcrumb', [
      'setPageBreadcrumbs',
      'clearPageBreadcrumbs'
    ]),
    fetchJpegSimulations() {
      api
        .get(`/qa/jpeg_simulations/${this.jpegSimulationId}`)
        .then(({ data }) => (this.jpeg_simulation = data.jpeg_simulation))
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>
