<template>
  <div class="TheHelpBubbleWrap"><slot /></div>
</template>

<script>
export default { name: 'TheHelpBubbleWrap' };
</script>

<style lang="scss" scoped>
.TheHelpBubbleWrap {
  position: relative;
}
</style>
