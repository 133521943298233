<template>
  <AppBadge
    :label="DisplayCornerStatus.t(cornerStatus)"
    :badge-style="badgeStyle"
    :tooltip="tooltip"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import DisplayCornerStatus from '@/enums/DisplayCornerStatus';
import DisplayProductDisplayScheduleType from '@/enums/DisplayProductDisplayScheduleType';

export default {
  name: 'DisplayCornerStatusBadge',
  props: {
    cornerStatus: { type: Number, required: true },
    cohort: { type: Object, required: false, default: null }
  },
  computed: {
    ...mapGetters('displayProductDisplays', ['cornerPageTypeKey']),
    DisplayCornerStatus: () => DisplayCornerStatus,
    badgeStyle() {
      switch (this.cornerStatus) {
        case DisplayCornerStatus.NONE:
        case DisplayCornerStatus.READY:
          return 'grey-outline';
        case DisplayCornerStatus.APPLYING:
          return 'mint-green-outline';
        case DisplayCornerStatus.OPERATING:
          return 'mint-green';
        case DisplayCornerStatus.DELETED:
        case DisplayCornerStatus.ERROR:
          return 'red';
        default:
          return 'default';
      }
    },
    tooltip() {
      if (this.cornerStatus === DisplayCornerStatus.DELETED)
        return {
          message: this.$t(`tooltip.${this.cornerPageTypeKey}`),
          textAlign: 'left'
        };
      else if (this.cornerStatus === DisplayCornerStatus.APPLYING)
        return {
          message: this.$t(
            `tooltip.${DisplayProductDisplayScheduleType.key(
              this.cohort.schedule_type
            )}`,
            [this.formatDateTimeWithoutSecond(this.cohort.display_at)]
          )
        };
      else return null;
    }
  }
};
</script>

<i18n locale="ko">
{
  "tooltip": {
    "MAIN": "최근 동기화 결과 해당 진열 위치를 찾을 수 없습니다.\n해당 진열 위치를 더이상 사용하지 않으신다면\n리스트에서 제거 버튼을 눌러 진열 리스트에서\n제거하실 수 있습니다.",
    "CATEGORY": "최근 동기화 결과 해당 카테고리를 찾을 수 없습니다.\n해당 카테고리를 더이상 사용하지 않으신다면\n리스트에서 제거 버튼을 눌러 진열 리스트에서\n제거하실 수 있습니다.",
    "NOW": "{0}에 시작된 즉시 진열이 진행 중입니다.",
    "ONCE": "{0}에 1회 예약된 진열이 진행 중입니다.",
    "AUTO": "{0}에 반복 예약된 진열이 진행 중입니다."
  }
}
</i18n>
