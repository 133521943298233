import { constructEnum } from '@/lib/enum';

export default constructEnum('BizMessageTemplateButtonType', {
  DELIVERY_STATUS: 'DS',
  APP_LINK: 'AL',
  WEB_LINK: 'WL',
  BOT_KEYWORD: 'BK',
  MESSAGE_DELIVERY: 'MD',
  ADD_CHANNEL: 'AC'
});
