<template>
  <AppModalDefault :title="$t('title')" :width="width" @close="close">
    <template #body>
      <AppIframeViewer :src="previewUrl" height="800px" />
    </template>
    <template #foot>
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState } from 'vuex';
import DialogView from '@/mixins/DialogView';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallationPresetPlacementType from '@/enums/InstallationPresetPlacementType';

export default {
  name: 'ReviewDashboardRequestInstallationWidgetSetIframePreviewDialog',
  mixins: [DialogView],
  props: {
    isMobile: { type: Boolean, default: false },
    pageType: { type: Number, default: 1 },
    presetTypes: { type: Array, required: true }
  },
  computed: {
    ...mapState(['env']),
    width() {
      return this.isMobile ? 'small' : 'wide';
    },
    previewUrl() {
      const url = new URL(this.baseUrl);

      url.pathname = `/review/view/${this.device}/onboarding/${this.pageTypeUrl}.html`;

      url.searchParams.set(
        'presetPlacementTypes',
        this.presetPlacementTypeKeys
      );
      url.searchParams.set('presetWidgetTypes', this.presetWidgetTypes);

      return url.href;
    },
    baseUrl() {
      const env = this.env === 'production' ? '' : location.host[0];
      return `https://${env}sample.cre.ma`;
    },
    device() {
      return this.isMobile ? 'mobile' : 'pc';
    },
    pageTypeUrl() {
      return WidgetPageType.toKey(this.pageType).toLowerCase();
    },
    presetPlacementTypeKeys() {
      return this.presetTypes.map(presetType =>
        InstallationPresetPlacementType.toKey(presetType.presetPlacementType)
      );
    },
    presetWidgetTypes() {
      return this.presetTypes.map(presetType => presetType.presetWidgetType);
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "위젯 미리보기"
}
</i18n>
