<template>
  <AppContainer
    :class="['AppStatCard', { 'AppStatCard--clickable': isClickable }]"
  >
    <div @click="click">
      <span class="AppStatCard__title">{{ title }}</span>
      <AppSvgIcon
        v-if="tooltip"
        v-tooltip="tooltip"
        name="icon-info-tooltip"
        class="AppStatCard__tooltip-icon"
      />
      <div>
        <span class="AppStatCard__stat"
          >{{ `${displayStat}` }}{{ StatUnit.t(unit) }}</span
        >
        <span class="AppStatCard__detail">{{ detail }}</span>
      </div>
      <div v-if="isClickable" class="AppStatCard__arrow-icon">
        <AppSvgIcon name="nav-chevron-right" :height="16" />
      </div>
    </div>
  </AppContainer>
</template>

<script>
import _ from 'lodash';
import StatUnit from '@/enums/StatUnit';

export default {
  name: 'AppStatCard',
  props: {
    title: { type: String, required: true },
    stat: { type: Number, default: null },
    detail: { type: String, default: null },
    isClickable: { type: Boolean, default: false },
    unit: { type: Number, default: StatUnit.RATE },
    tooltip: { type: String, default: null }
  },
  computed: {
    displayStat() {
      if (this.stat === null) return '-';
      switch (this.unit) {
        case StatUnit.RATE:
          return _.round(this.stat * 100, 1);
        default:
          return this.stat;
      }
    },
    StatUnit() {
      return StatUnit;
    }
  },
  methods: {
    click() {
      if (!this.isClickable) return;
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_transitions.scss';

$stat-card-height: 86px;

.AppStatCard {
  @include transition-basic(box-shadow);

  position: relative;
  height: $stat-card-height;
  padding: 16px 20px;
  min-width: 190px;

  &--clickable {
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    }
  }
}

.AppStatCard__tooltip-icon {
  pointer-events: auto;
  position: relative;
  top: 2px;
  margin-left: 4px;
}

.AppStatCard__title {
  @include text-caption-darker;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.AppStatCard__stat {
  @include text-metric-large;
}

.AppStatCard__detail {
  @include text-caption;
  margin-left: 8px;
}

$stat-card-arrow-icon-width: 16px;

.AppStatCard__arrow-icon {
  position: absolute;
  right: 16px;
  top: ($stat-card-height - $stat-card-arrow-icon-width) * 0.5;
  color: $color-grey-40;
}
</style>
