<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <AppDropdownMenuButton
      button-style="red"
      :label="$t('button.add_display_product_management')"
      :menu-items="menuItems"
    />
    <AppButton
      :label="$t('button.copy_script.label')"
      :tooltip="$t('button.copy_script.tooltip')"
      @click="openDialog(['DisplayCopyFastDeliveryWidgetScriptDialog'])"
    />
    <AppResourceTable
      class="DisplayProductManagements__table"
      :columns="columns"
      :rows="rows"
      :enable-total-count="false"
      :no-data="$t('no_data')"
    >
      <template #cell="{ column, row }">
        <template v-if="column === 'category_management'">
          <AppBadge
            v-if="row.use_category_management"
            class="DisplayProductManagements__category"
            :label="productCategoryName(row.category_id)"
            badge-style="grey-outline"
          />
          <span v-else>{{ $t('disabled') }}</span>
        </template>
        <template v-else-if="column === 'icon_management'">
          <span v-if="!row.use_icon_management">{{ $t('disabled') }}</span>
          <DisplayProductManagementProductIcons
            v-else-if="row.pc_icon_ids.length > 0"
            :icon-ids="row.pc_icon_ids"
          />
          <DisplayProductManagementProductIcons
            v-else-if="row.mobile_icon_ids.length > 0"
            :icon-ids="row.mobile_icon_ids"
          />
        </template>
        <template v-else-if="column === 'widget_management'">
          <span v-if="row.use_widget_management">{{ $t('enabled') }}</span>
          <span v-else>{{ $t('disabled') }}</span>
        </template>
        <template v-else-if="column === 'last_applied_at'">
          <template v-if="row.scheduled_cohort_apply_at">
            <div v-if="row.last_applied_at">
              {{ row.last_applied_at | formatFromNow }}
            </div>
            <div v-else>-</div>
            <AppBadge
              :label="$t('app.detail')"
              :tooltip="{
                textAlign: 'left',
                message: $t('dispatching.tooltip.message'),
                info: $t('dispatching.tooltip.info', [
                  formatDateTime(row.scheduled_cohort_apply_at)
                ])
              }"
            />
          </template>
          <template v-else-if="row.last_applied_at">
            <div>
              {{ row.last_applied_at | formatFromNow }}
            </div>
            <AppBadge
              :label="$t('app.detail')"
              :tooltip="formatDateTimeWithoutSecond(row.last_applied_at)"
            />
          </template>
          <template v-else>-</template>
        </template>
        <template v-else-if="column === 'next_apply_at'">
          <template v-if="row.next_apply_at">
            <div>
              {{ row.next_apply_at | formatFromNow }}
            </div>
            <AppBadge
              :label="$t('app.detail')"
              :tooltip="formatDateTimeWithoutSecond(row.next_apply_at)"
            />
          </template>
          <template v-else>-</template>
        </template>
        <template v-else-if="column === 'product_management_status'">
          <DisplayProductManagementStatusBadge
            :product-management-status="row.product_management_status"
            :apply-at="row.scheduled_cohort_apply_at"
            :dispatch-type="row.scheduled_cohort_dispatch_type"
          />
          <AppButton
            v-if="isErrored(row.product_management_status)"
            :label="$t('show_detail')"
            button-style="underline"
            @click="openErrorDetailDialog(row)"
          />
          <AppButton
            v-if="
              isNormal(row.scheduled_cohort_dispatch_type) &&
                !hideByProgress(row)
            "
            :label="$t('show_detail')"
            button-style="underline"
            @click="showCohortProgress(row)"
          />
        </template>
        <template v-else-if="column === 'updated_info'">
          <div>
            {{ row.updated_at | formatFromNow }}
          </div>
          <AppBadge
            :label="$t('app.detail')"
            :tooltip="
              $t('updated_info_detail', [
                formatDateTime(row.updated_at),
                formatDateTime(row.created_at)
              ])
            "
          />
        </template>
        <template v-else-if="column === 'actions'">
          <AppButton
            :label="$t('app.edit')"
            type="link"
            :to="`/display/product-managements/${row.id}`"
          />
          <AppDropdownMenuButton
            :label="$t('app.manage')"
            :menu-items="[
              [
                {
                  label: $t('button.enabled'),
                  disabled: !row.can_enable,
                  tooltip: {
                    textAlign: 'left',
                    message: row.can_enable
                      ? null
                      : $t('button.disable_tooltip')
                  },
                  selected: row.enabled,
                  clickHandler: () => updateEnabled(true, row)
                },
                {
                  label: $t('button.disabled'),
                  selected: !row.enabled,
                  clickHandler: () => updateEnabled(false, row)
                }
              ],
              [
                {
                  label: $t('button.cohorts'),
                  disabled: row.last_applied_at === null,
                  clickHandler: () =>
                    openDrawer([
                      'DisplayProductManagementCohortsDrawer',
                      { productManagement: row }
                    ])
                },
                {
                  label: $t('app.delete'),
                  style: 'danger',
                  superAdmin: row.last_applied_at !== null,
                  clickHandler: () => deleteProductManagement(row)
                }
              ]
            ]"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';
import DisplayProductManagementTemplateType from '@/enums/DisplayProductManagementTemplateType';
import DisplayProductManagementStatus from '@/enums/DisplayProductManagementStatus';
import DisplayProductManagementExecuteStatus from '@/enums/DisplayProductManagementExecuteStatus';
import DisplayProductManagementDispatchType from '@/enums/DisplayProductManagementDispatchType';
import TargetContentView from '@/mixins/TargetContentView';
import PeriodicalRequest from '@/mixins/PeriodicalRequest';
import ShopBuilder from '@/enums/ShopBuilder';

export default {
  name: 'DisplayProductManagements',
  mixins: [TargetContentView, PeriodicalRequest],
  data: () => ({ isLoading: true, rows: [], show_progress_cohort_ids: [] }),
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('productCategory', ['productCategoryName']),
    DisplayProductManagementStatus: () => DisplayProductManagementStatus,
    menuItems() {
      return DisplayProductManagementTemplateType.options().map(options => ({
        ...options,
        clickHandler: ({ close }) => {
          this.openDialog([
            'DisplayProductManagementCreateDialog',
            { templateType: options.value }
          ]);
          close();
        }
      }));
    },
    columns() {
      return [
        { id: 'name', align: 'left' },
        { id: 'category_management', width: '148px' },
        { id: 'icon_management', width: '100px' },
        { id: 'widget_management', width: '100px' },
        { id: 'last_applied_at', width: '83px' },
        { id: 'next_apply_at', width: '83px' },
        { id: 'product_management_status', width: '83px' },
        { id: 'updated_info', superAdmin: true, width: '83px' },
        { id: 'actions', label: this.$t('app.actions'), width: '164px' }
      ].map(column => ({
        ...column,
        label: column.label ? column.label : this.$t(`column.${column.id}`)
      }));
    },
    periodicalRequestPath() {
      return 'display/product_managements';
    }
  },
  watch: {
    rows(newValues, oldValues) {
      const filteredIds = oldValues
        .filter(
          v =>
            DisplayProductManagementStatus.isApplying(
              v.product_management_status
            ) &&
            v.scheduled_cohort_dispatch_type !==
              DisplayProductManagementDispatchType.CONFIRM
        )
        .map(v => v.id);

      const erroredProductManagements = newValues.filter(
        v =>
          filteredIds.includes(v.id) &&
          DisplayProductManagementStatus.isErrored(v.product_management_status)
      );

      if (!erroredProductManagements.length) return;
      erroredProductManagements.map(m => this.openErrorDetailDialog(m));
    }
  },
  mounted() {
    api
      .get('display/product_managements')
      .then(({ data }) => (this.rows = data.product_managements))
      .finally(() => (this.isLoading = false));
  },
  beforeDestroy() {
    this.UNSET_JOB_PARAMS('product_management_cohort_ids');
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('drawer', ['openDrawer']),
    ...mapMutations('jobRequest', ['SET_JOB_PARAMS', 'UNSET_JOB_PARAMS']),
    deleteProductManagement(row) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('confirm_delete.title'),
          markdownText: this.$t('confirm_delete.content', [row.name]),
          width: DialogSize.AUTO
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => this.doDeleteProductManagement(row.id));
      });
    },
    doDeleteProductManagement(id) {
      api
        .delete(`/display/product_managements/${id}`, {
          successMessage: this.$t('app.deleted')
        })
        .then(() => (this.rows = this.rows.filter(row => row.id !== id)));
    },
    updateEnabled(enabled, row) {
      if (enabled === row.enabled) return;

      api
        .patch(`/display/product_managements/${row.id}/update_enabled`, {
          enabled: enabled ? '1' : null
        })
        .then(() => (row.enabled = enabled));
    },
    periodicalRequestCallback({ data }) {
      this.rows = data.product_managements;
    },
    isErrored(product_management_status) {
      return DisplayProductManagementStatus.isErrored(
        product_management_status
      );
    },
    isNormal(dispatch_type) {
      return DisplayProductManagementDispatchType.isNormal(dispatch_type);
    },
    openErrorDetailDialog(row) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('error_detail.title'),
          markdownText: this.errorMessage(row),
          width: DialogSize.SMALL
        }
      ]);
    },
    errorMessage(row) {
      const executeStatus = row.last_errored_cohort_execute_status;
      let argument;
      switch (executeStatus) {
        case DisplayProductManagementExecuteStatus.ERROR_PREPARING:
        case DisplayProductManagementExecuteStatus.ERROR_CALCULATING:
        case DisplayProductManagementExecuteStatus.ERROR_DISPATCHING:
          argument = row.name;
          break;
        case DisplayProductManagementExecuteStatus.ERROR_LOGIN_BLOCKED:
        case DisplayProductManagementExecuteStatus.ERROR_SESSION_EXPIRED:
          argument = ShopBuilder.tk(this.currentBrand.shop_builder);
          break;
        case DisplayProductManagementExecuteStatus.ERROR_TIMEOUT:
          break;
      }

      return this.$t(
        `error_detail.${DisplayProductManagementExecuteStatus.key(
          executeStatus
        )}`,
        [argument]
      );
    },
    showCohortProgress(row) {
      this.show_progress_cohort_ids.push(row.scheduled_cohort_id);
      this.SET_JOB_PARAMS({
        key: 'product_management_cohort_ids',
        value: this.show_progress_cohort_ids
      });
    },
    hideByProgress(row) {
      return this.show_progress_cohort_ids.includes(row.scheduled_cohort_id);
    }
  }
};
</script>

<style scoped>
.DisplayProductManagements__table {
  margin-top: 32px;
}

.DisplayProductManagements__category {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DisplayProductManagements__icon {
  height: 16px;
  vertical-align: middle;
}
</style>

<i18n locale="ko">
{
  "button": {
    "add_display_product_management": "상품 관리 규칙 추가",
    "copy_script": {
      "label": "위젯 스크립트 복사",
      "tooltip": "빠른 배송 안내 위젯을 노출하기 위한 스크립트를 제공합니다.\n설치를 원하시면 크리마 타겟 운영팀에 문의해주세요."
    },
    "enabled": "사용중",
    "disable_tooltip": "사용할 수 없습니다.\n모든 필수 설정값이 입력되고 자동 관리 항목이 1개 이상 사용 설정되어야 합니다.",
    "disabled": "미사용",
    "cohorts": "관리 이력"
  },
  "column": {
    "name": "이름",
    "category_management": "카테고리 이동",
    "icon_management": "아이콘 표시",
    "widget_management": "위젯 표시",
    "last_applied_at": "최근 실행일",
    "next_apply_at": "다음 실행일",
    "product_management_status": "상태",
    "updated_info": "수정일"
  },
  "confirm_delete": {
    "title": "상품 관리 규칙 삭제",
    "content": "{0}((을)) 삭제하시겠습니까?"
  },
  "no_data": "진열 상품 관리 설정이 없습니다.",
  "enabled": "사용 중",
  "disabled": "미사용",
  "dispatching": {
    "tooltip": {
      "message": "데이터 동기화 및 대상 상품을 선정하여 자동 관리 진행 중입니다.",
      "info": "시작시간: {0}"
    }
  },
  "updated_info_detail": "수정일: {0}\n등록일: {1}",
  "error_detail": {
    "title": "상품 자동 관리 중 문제가 발생했습니다.",
    "ERROR_PREPARING": "데이터 동기화 중 오류가 발생하였습니다.<br>설정, 권한 등을 확인 하시고 {0}((를)) 다시 시도하시기 바랍니다.<br>오류가 반복적으로 발생할 경우 진열 운영팀으로 문의하시기 바랍니다.",
    "ERROR_CALCULATING": "데이터 계산 중 일시적인 오류가 발생하였습니다.<br>잠시 후 {0}((를)) 다시 시도하시기 바랍니다.<br>오류가 반복적으로 발생할 경우 진열 운영팀으로 문의하시기 바랍니다.",
    "ERROR_DISPATCHING": "자동관리 결과 적용 중 오류가 발생하였습니다.<br>잠시 후 {0}((를)) 다시 시도하시기 바랍니다.<br>오류가 반복적으로 발생할 경우 진열 운영팀으로 문의하시기 바랍니다.",
    "ERROR_TIMEOUT": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
    "ERROR_LOGIN_BLOCKED": "{0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
    "ERROR_SESSION_EXPIRED": "세션이 만료되어 {0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다."
  },
  "show_detail": "자세히 보기"
}
</i18n>
