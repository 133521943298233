<template>
  <div class="TargetPopupLivePreview">
    target popup live preview
  </div>
</template>

<script>
export default {
  name: 'TargetPopupLivePreview'
};
</script>
