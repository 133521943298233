<template>
  <div class="AppToast" :class="{ 'AppToast--error': toast.isError }">
    <AppSvgIcon
      v-if="!toast.isError"
      class="AppToast__icon"
      name="icon-checkbox-checker"
      :height="20"
    />
    <AppMarked class="AppToast__message" :text="toast.message" />
    <button class="AppToast__close-button" @click="closeToast">
      <AppSvgIcon name="icon-close" />
    </button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'AppToast',
  props: {
    toast: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapMutations('toast', ['REMOVE_TOAST']),
    closeToast() {
      this.REMOVE_TOAST(this.toast.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_transitions.scss';

.AppToast {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
  background-color: rgba(33, 33, 35, 0.96);
}

.AppToast--error {
  background-color: $color-red;
}

.AppToast__icon {
  position: absolute;
  top: 10px;
  left: 13px;
  display: inline-block;
  border: 2px solid white;
  border-radius: 50%;
}

.AppToast__message {
  color: white;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 44px;
}

.AppToast__close-button {
  @include transition-basic(color);

  position: absolute;
  top: 9px;
  right: 13px;
  width: 25px;
  height: 25px;
  color: $color-grey-60;

  &:hover {
    color: $color-grey-40;
  }
}
</style>
