var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.campaign.name,"is-loading":_vm.isLoading,"form-props":Object.assign({}, _vm.formProps,
    {objectId: 'campaign',
    disabled: _vm.isArchivedCampaign})},scopedSlots:_vm._u([{key:"group",fn:function(ref){
  var id = ref.id;
  var disabled = ref.disabled;
  var inputName = ref.inputName;
  var errors = ref.errors;
return [(id === 'dispatch_limit_type')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectRadio',{attrs:{"disabled":disabled,"name":inputName,"options":_vm.dispatchLimitTypeOptions},scopedSlots:_vm._u([{key:"label",fn:function(ref){
  var value = ref.value;
  var checked = ref.checked;
return [(value !== _vm.TargetDispatchLimitType.NONE)?[_c('i18n',{attrs:{"path":("TargetDispatchLimitType." + (_vm.TargetDispatchLimitType.toKey(
                    value
                  )))}},[_c('AppNumberInput',{attrs:{"name":"campaign[dispatch_limit_amount]","inline":"","default":0,"disabled":disabled || !checked,"invalid":!!errors[_vm.dispatchLimitAmountKey(value)],"digits":6},on:{"blur":function($event){_vm.validateField(_vm.dispatchLimitAmountKey(value))},"change":function($event){_vm.validateField(_vm.dispatchLimitAmountKey(value))}},model:{value:(_vm.formObject[_vm.dispatchLimitAmountKey(value)]),callback:function ($$v) {_vm.$set(_vm.formObject, _vm.dispatchLimitAmountKey(value), $$v)},expression:"formObject[dispatchLimitAmountKey(value)]"}})],1),_c('AppFormError',{attrs:{"error":errors[_vm.dispatchLimitAmountKey(value)]}})]:_vm._e()]}}],null,true),model:{value:(_vm.formObject.dispatch_limit_type),callback:function ($$v) {_vm.$set(_vm.formObject, "dispatch_limit_type", $$v)},expression:"formObject.dispatch_limit_type"}}),(
            _vm.formObject.dispatch_limit_type === _vm.TargetDispatchLimitType.NONE
          )?_c('input',{attrs:{"type":"hidden","name":"formObject[dispatch_limit_amount]"},domProps:{"value":0}}):_vm._e()],1)]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }