<template>
  <div class="TheHelp">
    <TheHelpMenu v-if="isHelpMenuVisible" @close="isHelpMenuVisible = false" />
    <TheHelpBubbleWrap>
      <TheHelpButton @click="clickHelpButton" />
      <TheHelpBubble :event-bus="helpBubbleEventBus" />
    </TheHelpBubbleWrap>
  </div>
</template>

<script>
import Vue from 'vue';

import TheHelpButton from './TheHelpButton';
import TheHelpBubble from './TheHelpBubble';
import TheHelpBubbleWrap from './TheHelpBubbleWrap';
import TheHelpMenu from './TheHelpMenu';

export default {
  name: 'TheHelp',
  components: { TheHelpButton, TheHelpBubble, TheHelpBubbleWrap, TheHelpMenu },
  data() {
    return { isHelpMenuVisible: false, helpBubbleEventBus: new Vue() };
  },
  methods: {
    clickHelpButton() {
      this.isHelpMenuVisible = !this.isHelpMenuVisible;
      if (this.isHelpMenuVisible) this.helpBubbleEventBus.$emit('hide');
    }
  }
};
</script>

<style>
.TheHelp {
  justify-items: right;
}
</style>
