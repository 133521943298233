import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('session', ['adminSettings'])
  },
  methods: {
    openInquiry() {
      if (this.adminSettings.enable_zendesk) {
        if (window.zE) window.zE('messenger', 'open');
        else
          window.open(
            'https://help-review.cre.ma/hc/ko/requests/new?ticket_form_id=9877003532569'
          );
      }
    }
  }
};
