<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Normal</label>
      </div>
      <AppMultipleSelectReview id="normal" v-model="reviewsNormal" :tags="[]" />
      <div class="ui-section__description">
        Selected Value: {{ reviewsNormal }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppMultipleSelectReview
        id="disabled"
        v-model="reviewsDisabled"
        :tags="[]"
        disabled
      />
      <div class="ui-section__description">
        Selected Value: {{ reviewsDisabled }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'UiMultipleSelectReview',
  data() {
    const REVIEWS = [
      {
        id: 1,
        product_name: 'Ant-Man',
        message: 'Pick on someone your own size!',
        user_name: 'Scott Lang',
        user_username: 'exconvict',
        images: [],
        videos: []
      },
      {
        id: 2,
        product_name: 'Black Panther',
        message:
          "You are a good man, with a good heart. And it's hard for a good man to be a king.",
        user_name: "T'Challa",
        user_username: 'wakanda4ever',
        images: [],
        videos: []
      }
    ];
    return {
      reviewsNormal: _.cloneDeep(REVIEWS),
      reviewsDisabled: _.cloneDeep(REVIEWS)
    };
  }
};
</script>
