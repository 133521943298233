import { mapGetters, mapMutations, mapActions } from 'vuex';
import api from '@/lib/api';
import BrandSyncType from '@/enums/BrandSyncType';
import BrandSyncStatus from '@/enums/BrandSyncStatus';
import DialogSize from '@/enums/DialogSize';

export default {
  computed: {
    ...mapGetters('sync', ['brandSyncTypeInfo']),
    brandSyncTypeParams() {
      return { brand_sync_type: this.brandSyncType };
    },
    syncInfo() {
      return this.brandSyncTypeInfo(this.brandSyncType);
    },
    buttonDisabled() {
      return this.syncInfo?.sync_status !== BrandSyncStatus.IDLE;
    },
    label() {
      if (this.syncInfo?.sync_status === BrandSyncStatus.IN_PROGRESS)
        return this.$t('sync_button.label.in_progress');
      else return this.$t('sync_button.label.default', [this.$t('name')]);
    },
    tooltipIdleInfoText() {
      return this.$t('sync_button.tooltip.idle_info', [this.$t('name')]);
    },
    tooltip() {
      if (!this.syncInfo) return null;
      if (this.syncInfo.manual_sync_failed)
        return this.$t('sync_button.tooltip.manual_sync_failed');

      const { sync_status, synced_at } = this.syncInfo;

      switch (sync_status) {
        case BrandSyncStatus.IDLE:
          return {
            message: this.$t('sync_button.tooltip.idle', [
              this.formatDateTimeWithoutSecond(synced_at)
            ]),
            info: this.tooltipIdleInfoText
          };
        case BrandSyncStatus.IN_PROGRESS:
          return this.$t('sync_button.tooltip.in_progress');
        case BrandSyncStatus.LOGIN_BLOCKED:
          return this.$t('sync_button.tooltip.login_blocked');
        case BrandSyncStatus.DISABLED:
          return this.$t('sync_button.tooltip.disabled');
        default:
          return null;
      }
    },
    syncConfirmMessage() {
      return this.$t('sync_button.confirm.content_html', [this.$t('name')]);
    }
  },
  created() {
    this.checkSyncable();
  },
  beforeDestroy() {
    this.removeBrandSyncType(this.brandSyncType);
  },
  methods: {
    ...mapMutations('sync', ['SET_SYNC']),
    ...mapActions('sync', ['addBrandSyncType', 'removeBrandSyncType']),
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('manualSyncJobs', ['enqueueManualSyncJob']),
    checkSyncable() {
      this.isLoading = true;

      api
        .get('/sync', { params: this.brandSyncTypeParams })
        .then(({ data }) => {
          this.addBrandSyncType({
            brandSyncType: this.brandSyncType,
            sync: data.sync
          });
        })
        .finally(() => (this.isLoading = false));
    },
    syncResource() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t('sync_button.confirm.title', [this.$t('name')]),
          markdownText: this.syncConfirmMessage,
          width: DialogSize.AUTO
        }
      ]).then(eventBus => eventBus.$on('close', this.doSyncResource));
    },
    doSyncResource() {
      api.post('/sync', this.brandSyncTypeParams).then(({ data }) => {
        this.SET_SYNC({ brandSyncType: this.brandSyncType, sync: data.sync });
        this.enqueueManualSyncJob({
          brandSyncType: this.brandSyncType,
          manualSyncInfo: data.manual_sync_info,
          displayRunningTime: BrandSyncType.showDisplayRunngingTime(
            this.brandSyncType
          )
        });
      });
    }
  }
};
