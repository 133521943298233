<template>
  <div v-if="miniNotices.length" class="SubMenuNoticeList">
    <AppButton
      class="SubMenuNoticeList__button"
      button-style="grey-clear"
      @click="toggleButtonType"
    >
      <div class="SubMenuNoticeList__label">
        {{ buttonLabel }}
      </div>
      <AppSvgIcon
        name="nav-chevron-down"
        :class="[
          'SubMenuNoticeList__icon',
          isExpandedButtonType ? 'SubMenuNoticeList__icon--expanded' : null
        ]"
      />
    </AppButton>
    <AppCarousel
      class="SubMenuNoticeList__container"
      :show-pagination="showPagination"
      type="notice"
    >
      <div v-for="(notice, i) in miniNotices" :key="i" class="panel">
        <SubMenuNotice :notice="notice" :is-collapsed="isExpandedButtonType" />
      </div>
    </AppCarousel>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SubMenuNotice from './SubMenuNotice';
import CremaServiceType from '@/enums/CremaServiceType';
import MiniNoticeButtonType from '@/enums/MiniNoticeButtonType';

export default {
  name: 'SubMenuNoticeList',
  components: {
    SubMenuNotice
  },
  data() {
    return {
      buttonType:
        parseInt(sessionStorage.getItem('mini_notice_button_type')) ||
        MiniNoticeButtonType.COLLAPSED
    };
  },
  computed: {
    ...mapGetters('menu', ['selectedMainMenuItemId']),
    ...mapGetters('miniNotice', ['currentMiniNotices']),
    ...mapState('editor', ['isSubMenuVisibleDesktop']),
    miniNotices() {
      return this.currentMiniNotices(this.serviceType);
    },
    serviceType() {
      return CremaServiceType.nameToServiceType(this.selectedMainMenuItemId);
    },
    buttonLabel() {
      return MiniNoticeButtonType.t(this.buttonType);
    },
    isExpandedButtonType() {
      return this.buttonType === MiniNoticeButtonType.EXPANDED;
    },
    showPagination() {
      return this.miniNotices?.length > 1;
    }
  },
  watch: {
    selectedMainMenuItemId() {
      this.$nextTick(() => {
        this.showSubMenuNotice();
        this.setMiniNoticeCollapsedClass();
      });
    }
  },
  mounted() {
    this.setMiniNoticeCollapsedClass();
    this.showSubMenuNotice();
    this.bindTransitionEvent();
  },
  methods: {
    toggleButtonType() {
      if (this.isExpandedButtonType) {
        this.buttonType = MiniNoticeButtonType.COLLAPSED;
        sessionStorage.setItem(
          'mini_notice_button_type',
          MiniNoticeButtonType.COLLAPSED
        );
        this.$el.classList?.remove('SubMenuNoticeList--collapsed');
      } else {
        this.buttonType = MiniNoticeButtonType.EXPANDED;
        sessionStorage.setItem(
          'mini_notice_button_type',
          MiniNoticeButtonType.EXPANDED
        );
        this.$el.classList?.add('SubMenuNoticeList--collapsed');
      }
    },
    isMainMenuTransitionEvent(e) {
      return (
        e.target.classList.contains('TheMain__main') &&
        e.propertyName === 'left'
      );
    },
    setMiniNoticeCollapsedClass() {
      if (this.isExpandedButtonType) {
        this.$el.classList?.add('SubMenuNoticeList--collapsed');
      }
    },
    showSubMenuNotice() {
      if (this.isSubMenuVisibleDesktop) {
        this.$el.classList?.add('SubMenuNoticeList--visible');
      }
    },
    mainMenuTransitionStart(e) {
      if (this.isMainMenuTransitionEvent(e)) {
        if (!this.isSubMenuVisibleDesktop) {
          this.$el.classList?.remove('SubMenuNoticeList--visible');
        }
      }
    },
    mainMenuTransitionEnd(e) {
      if (this.isMainMenuTransitionEvent(e)) {
        this.showSubMenuNotice();
      }
    },
    bindTransitionEvent() {
      const $mainMenu = document.querySelector('.TheMain__main');
      if (!$mainMenu) return;

      $mainMenu.addEventListener('transitionstart', e =>
        this.mainMenuTransitionStart(e)
      );
      $mainMenu.addEventListener('transitionend', e =>
        this.mainMenuTransitionEnd(e)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/vars/_z-indexes.scss';
@import '@/scss/mixins/_transitions.scss';

.SubMenuNoticeList {
  opacity: 0;
  position: relative;
  height: 230px;
  margin-top: 16px;

  &--collapsed {
    height: 50px;
  }

  &--visible {
    opacity: 1;
  }

  &:hover {
    .SubMenuNoticeList__button {
      display: block;
    }
  }
}

.SubMenuNoticeList__button {
  display: none;
  position: absolute;
  top: -16px;
  right: 0;
}

.SubMenuNoticeList__label {
  display: inline-block;
  padding-right: 8px;
}

.SubMenuNoticeList__icon {
  pointer-events: none;

  &--expanded {
    transform: rotate(180deg);
  }
}

.SubMenuNoticeList__container {
  position: absolute;
  top: 16px;
  right: 0;
  width: 266px;
  height: 100%;
}
</style>
