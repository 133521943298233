import ProductCategoryFilterType from '@/enums/ProductCategoryFilterType';

export default {
  data: () => ({
    categories: [],
    searchWithType: { search_type: 'full_name', search_query: '' },
    categoryFilterValues: ProductCategoryFilterType.values
  }),
  computed: {
    searchTypes() {
      return ['full_name', 'code'].map(value => ({
        value,
        label: this.$t(`search_type.${value}`)
      }));
    },
    filteredCategories() {
      return ProductCategoryFilterType.filter(
        this.categories,
        this.categoryFilterValues
      );
    },
    columnsWidth() {
      return {};
    },
    columns() {
      return [
        { id: 'property', label: this.$t('product_category.property') },
        {
          id: 'code',
          label: this.$t('app.code')
        },
        {
          id: 'main_category_name',
          label: this.$t('product_category.main_category_name'),
          depth: 1,
          default: '-'
        },
        {
          id: 'middle_category_name',
          label: this.$t('product_category.middle_category_name'),
          depth: 2,
          default: '-'
        },
        {
          id: 'sub_category_name',
          label: this.$t('product_category.sub_category_name'),
          depth: 3,
          default: '-'
        },
        {
          id: 'products_count',
          label: this.$t('product_category.products_count'),
          default: 0
        }
      ].map(column => ({ ...column, width: this.columnsWidth[column.id] }));
    },
    items() {
      return [];
    },
    rows() {
      return this.items.map(category => ({
        ...category,
        main_category_name: category.treeNames[0],
        middle_category_name: category.treeNames[1],
        sub_category_name: category.treeNames[2]
      }));
    }
  }
};
