<template>
  <div
    :class="[
      'AppSearchDropdown',
      { 'AppSearchDropdown--no-results': !!noResults }
    ]"
  >
    <div class="AppSearchDropdown__header">
      <input
        ref="queryInput"
        v-focus
        :value="query"
        :type="number ? 'number' : 'text'"
        :min="number ? number.min : null"
        :max="number ? number.max : null"
        :placeholder="placeholder"
        class="AppSearchDropdown__header-input"
        @input="query = $event.target.value"
        @keydown.down.prevent="down"
        @keydown.up.prevent="up"
        @keypress.enter.prevent="enterClicked"
        @keyup.space.prevent
        @keydown.tab="$emit('close')"
      />
      <button
        v-if="!number"
        v-show="!!query"
        type="button"
        class="AppSearchDropdown__header-reset-button"
        @click="resetQuery"
      >
        <AppSvgIcon name="icon-close-small" />
      </button>
    </div>
    <ul
      v-if="sortedOptions.length"
      ref="options"
      class="AppSearchDropdown__list"
    >
      <li
        v-for="(item, i) in sortedOptions"
        :key="i"
        :class="[
          'AppSearchDropdown__item',
          {
            'AppSearchDropdown__item--not-selectable': !item.isSelectable,
            'AppSearchDropdown__item--disabled': item.disabled,
            'AppSearchDropdown__item--active': activeIndex === i,
            [`AppSearchDropdown__item--${item.style}`]: !!item.style
          }
        ]"
        @mousemove="activeIndex = i"
        @click="selectItem(i)"
      >
        <span v-if="item.isSelected">✓ </span
        ><span
          :class="[
            'AppSearchDropdown__item-label',
            {
              [`AppSearchDropdown__item-label--${item.style}`]: !!item.style,
              [`AppSearchDropdown__item-label--disabled`]: item.disabled
            }
          ]"
          >{{ item.label }}</span
        >
      </li>
    </ul>
    <div v-else-if="noResults" class="AppSearchDropdown__body-no-results">
      {{ noResults }}
    </div>
  </div>
</template>

<script>
import Scrollable from '@/mixins/Scrollable';

const PRIORITY_MAP = { info: 1, primary: 2 };
const LOWEST_PRIORITY = 999999;

export default {
  name: 'AppSearchDropdown',
  mixins: [Scrollable],
  props: {
    options: {
      type: Array,
      required: true
    },
    noResults: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    number: { type: Object, default: null }
  },
  data() {
    return {
      activeIndex: 0,
      query: ''
    };
  },
  computed: {
    sortedOptions() {
      return [...this.options]
        .sort((a, b) => {
          const aPriority = PRIORITY_MAP[a.priority] || LOWEST_PRIORITY;
          const bPriority = PRIORITY_MAP[b.priority] || LOWEST_PRIORITY;

          return aPriority - bPriority;
        })
        .map(o => ({
          ...o,
          style: this.computeStyle(o.priority),
          isSelectable: !(o.disabled || o.priority === 'info')
        }));
    }
  },
  watch: {
    query() {
      this.$emit('query', this.query);
      this.initializeActiveIndex();
    },
    activeIndex(newActiveIndex) {
      if (!this.$refs.options) return;

      const el = this.$refs.options.querySelector(
        `li:nth-child(${newActiveIndex + 1})`
      );
      this.scrollIntoView(el);
    }
  },
  created() {
    this.initializeActiveIndex();
  },
  methods: {
    initializeActiveIndex() {
      const index = this.sortedOptions.findIndex(o => o.isSelectable);
      this.activeIndex = Math.max(0, index);
    },
    resetQuery() {
      this.query = '';
      this.$refs.queryInput.focus();
    },
    down() {
      for (let i = this.activeIndex + 1; i < this.sortedOptions.length; i++) {
        if (this.sortedOptions[i].isSelectable) {
          this.activeIndex = i;
          break;
        }
      }
    },
    up() {
      for (let i = this.activeIndex - 1; i >= 0; i--) {
        if (this.sortedOptions[i].isSelectable) {
          this.activeIndex = i;
          break;
        }
      }
    },
    selectItem(index) {
      const selectedItem = this.sortedOptions[index];
      if (selectedItem) {
        this.$emit('select', selectedItem.value);
        this.query = '';
      }
    },
    enterClicked() {
      // Give change for IME to finish composing
      setTimeout(() => this.selectItem(this.activeIndex));
    },
    computeStyle(priority) {
      switch (priority) {
        case 'primary':
        case 'info':
          return 'primary';
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppSearchDropdown__header {
  position: relative;
  padding: 4px 0;

  .AppSearchDropdown--no-results & {
    border-bottom: 1px solid $color-grey-25;
  }
}

.AppSearchDropdown__header-input {
  @include text-content;
  @include input-placeholder;

  border: 0;
  appearance: none;
  padding: 4px 12px;
  width: 100%;

  &[type='text'] {
    padding-right: 36px;
  }
}

.AppSearchDropdown__header-reset-button {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 0;
  padding: 12px;
}

$body-padding-vertical: 9px;
.AppSearchDropdown__list {
  padding: $body-padding-vertical 7px;
  max-height: 32px * 7 + $body-padding-vertical * 2;
  overflow-y: auto;
}

$item-padding-vertical: 6px;
.AppSearchDropdown__item {
  padding: $item-padding-vertical;
  border-radius: 3px;
  cursor: pointer;

  &--active {
    background-color: $color-grey-15;
  }

  &--not-selectable {
    pointer-events: none;
  }

  &--disabled {
    color: $color-grey-25;
  }

  &--primary + &--primary + &:not(&--primary) {
    margin-top: 19px;

    &:before {
      content: '';
      width: calc(100% + 12px);
      height: 1px;
      position: relative;
      top: -16px;
      left: -6px;
      display: block;
      background: $color-grey-25;
    }
  }
}

.AppSearchDropdown__item-label {
  &--primary {
    color: $color-blue;
  }

  &--disabled {
    color: $color-grey-25;
    pointer-events: none;
  }
}

.AppSearchDropdown__body-no-results {
  padding: 15px 0 15px 16px;
}
</style>
