<template>
  <Transition name="float">
    <div
      v-if="!hideForTransition"
      :class="[
        'AppModeless',
        horizontalPlacement ? `AppModeless--${horizontalPlacement}` : null,
        `AppModeless--${verticalPlacement}`
      ]"
      :style="{ marginLeft }"
    >
      <slot />
    </div>
  </Transition>
</template>

<script>
import { getScrollableParent } from '@/lib/scroll';
import KeyCode from '@/enums/KeyCode';

export default {
  name: 'AppModeless',
  props: {
    align: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      isInDialog: false,
      hideForTransition: true,
      horizontalPlacement: this.align,
      verticalPlacement: 'bottom',
      marginLeft: null
    };
  },
  mounted() {
    this.hideForTransition = false;
    this.$nextTick(() => {
      const dialog = this.$el;

      this.initializeModal();

      const dialogWidth = dialog.offsetWidth;
      const dialogHeight = dialog.offsetHeight;

      const parent = dialog.offsetParent;
      if (!parent) return;

      const scrollParent = getScrollableParent(parent);

      const scrollRect = scrollParent.getBoundingClientRect();
      dialog.style.setProperty('display', 'none');
      const { scrollWidth, scrollHeight } = scrollParent;
      dialog.style.setProperty('display', '');

      const parentRect = parent.getBoundingClientRect();
      const parentLeft =
        scrollParent.scrollLeft + parentRect.left - scrollRect.left;
      const parentRight = parentLeft + parentRect.width;
      const parentTop =
        scrollParent.scrollTop + parentRect.top - scrollRect.top;
      const parentBottom = parentTop + parentRect.height;

      const buffer = scrollParent === document.body ? 12 : 0;

      if (this.align === 'center') {
        const minLeft = buffer;
        const maxLeft = scrollWidth - buffer - dialogWidth;

        const parentCenter = parentLeft + parentRect.width * 0.5;
        const minMarginLeft = minLeft - parentCenter;
        const maxMarginLeft = maxLeft - parentCenter;
        this.marginLeft = `${Math.min(
          Math.max(dialogWidth * -0.5, minMarginLeft),
          maxMarginLeft
        )}px`;
      } else {
        const marginWhenPlacedLeft = scrollWidth - (parentLeft + dialogWidth);
        const marginWhenPlacedRight = parentRight - dialogWidth;

        if (marginWhenPlacedRight > 0 || marginWhenPlacedLeft > 0) {
          if (this.align === 'right') {
            if (marginWhenPlacedRight > 0) this.horizontalPlacement = 'right';
            else this.horizontalPlacement = 'left';
          } else {
            if (marginWhenPlacedLeft > 0) this.horizontalPlacement = 'left';
            else this.horizontalPlacement = 'right';
          }
        } else {
          this.horizontalPlacement =
            marginWhenPlacedLeft < marginWhenPlacedRight ? 'right' : 'left';
        }
      }

      const hasEnoughSpaceBelow =
        parentBottom + dialogHeight <= scrollHeight - buffer;
      const hasEnoughSpaceAbove = parentTop - dialogHeight >= buffer;

      if (hasEnoughSpaceBelow) this.verticalPlacement = 'bottom';
      else if (hasEnoughSpaceAbove) this.verticalPlacement = 'top';
      else if (this.isInDialog) this.verticalPlacement = 'bottom';
      else {
        this.calculateFixedPosition(this.horizontalPlacement, parentRect);
        this.verticalPlacement = 'fixed';
        this.horizontalPlacement = null;
      }
    });
    setTimeout(() => {
      document.addEventListener('click', this.click, true);
      document.addEventListener('keyup', this.keyup);
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.click, true);
    document.removeEventListener('keyup', this.keyup);
    this.finalizeModal();
    if (this.verticalPlacement === 'fixed')
      document.getElementById('main-body').style.overflowY = '';
  },
  methods: {
    click(e) {
      if (
        !this.$el.contains(e.target) &&
        !this.$el.parentElement.contains(e.target)
      )
        this.$emit('close');
    },
    keyup(e) {
      if (e.keyCode === KeyCode.ESCAPE) this.$emit('close');
    },
    calculateFixedPosition(horizontalPlacement, parentRect) {
      const dialog = this.$el;
      if (horizontalPlacement === 'right') {
        dialog.style.setProperty(
          'left',
          `${parentRect.left - dialog.offsetWidth + parentRect.width}px`
        );
      } else if (horizontalPlacement === 'left') {
        dialog.style.setProperty('left', `${parentRect.left}px`);
      } else if (horizontalPlacement === 'center') {
        this.marginLeft = 0;
        dialog.style.setProperty(
          'left',
          `${parentRect.left + parentRect.width / 2}px`
        );
      }
      dialog.style.setProperty(
        'top',
        `${parentRect.top + parentRect.height}px`
      );
      document.getElementById('main-body').style.overflowY = 'hidden';
    },
    initializeModal() {
      const modalBody = this.$el.closest('.AppModal__body');
      if (modalBody) {
        this.isInDialog = true;
        this.$el.style.display = 'none';
        const isScrollable = modalBody.offsetHeight < modalBody.scrollHeight;
        this.$el.style.display = '';
        if (!isScrollable) modalBody.style.overflowY = 'visible';
      }
    },
    finalizeModal() {
      if (!this.isInDialog) return;

      const modalBody = this.$el.closest('.AppModal__body');
      modalBody.style.overflowY = '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_z-indexes.scss';
@import '@/scss/mixins/_dialog.scss';

.AppModeless {
  @include dialog;

  z-index: $z-index-dropdown;
  position: absolute;
  margin-top: 4px;

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }

  &--center {
    left: 50%;
  }

  &--top {
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 4px;
  }

  &--fixed {
    position: fixed;
  }
}
</style>
