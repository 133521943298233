<template>
  <div class="BizMessageTemplateItemList">
    <AppButton
      v-if="!readonly"
      :label="$t('add_item')"
      :disabled="10 <= rows.length"
      @click="clickAddItem"
    />
    <AppTable v-if="rows.length" :columns="COLUMNS" :rows="rows" class="mt8">
      <template #cell="{ row, column, rowIndex }">
        <template v-if="['title', 'description'].includes(column.id)">
          <template v-if="readonly">
            <div>{{ row[column.id] }}</div>
          </template>
          <template v-else>
            <AppTextInput
              :ref="column.id === 'title' ? `titleInput${rowIndex}` : undefined"
              :value="row[column.id]"
              :placeholder="column.placeholder"
              :maxlength="column.maxlength"
              :invalid="invalid && !row[column.id]"
              @change="changeItem(rowIndex, column.id, $event)"
            />
          </template>
        </template>
        <template v-else-if="column.id === 'actions'">
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            @click="clickDeleteItem(rowIndex)"
          />
        </template>
      </template>
    </AppTable>
    <div>
      <div v-for="(item, i) in itemFields" :key="i">
        <input
          type="hidden"
          name="template[item_list][][title]"
          :value="item.title"
        />
        <input
          type="hidden"
          name="template[item_list][][description]"
          :value="item.description"
        />
      </div>
    </div>
    <input v-if="!rows.length" type="hidden" name="template[item_list][]" />
  </div>
</template>

<script>
export default {
  name: 'BizMessageTemplateItemList',
  model: { prop: 'itemList', event: 'change' },
  props: {
    itemList: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false }
  },
  computed: {
    rows() {
      return this.itemList.map((e, i) => ({
        position: i + 1,
        title: e.title,
        description: e.description
      }));
    },
    itemFields() {
      return this.itemList.filter(e => e.title && e.description);
    }
  },
  created() {
    this.COLUMNS = [
      { id: 'position', type: 'number', label: this.$t('app.position') },
      {
        id: 'title',
        type: 'custom',
        label: this.$t('title'),
        placeholder: this.$t('title_placeholder'),
        maxlength: 6
      },
      {
        id: 'description',
        type: 'custom',
        label: this.$t('description'),
        placeholder: this.$t('description_placeholder'),
        maxlength: 23
      },
      this.readonly
        ? null
        : { id: 'actions', type: 'custom', label: this.$t('app.actions') }
    ].filter(v => !!v);
  },
  methods: {
    clickAddItem() {
      this.$emit('change', [...this.itemList, { title: '', description: '' }]);
      this.$nextTick(() => {
        this.$refs[`titleInput${this.itemList.length - 1}`].$el.focus();
      });
    },
    clickDeleteItem(index) {
      this.$emit(
        'change',
        this.itemList.filter((_, i) => i !== index)
      );
    },
    changeItem(rowIndex, attr, value) {
      this.$emit(
        'change',
        this.itemList.map((item, i) =>
          rowIndex === i ? { ...item, [attr]: value } : item
        )
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "add_item": "+ 아이템 추가",
  "title": "아이템명",
  "title_placeholder": "최대 6자",
  "description": "디스크립션",
  "description_placeholder": "최대 23자. 변수사용 가능"
}
</i18n>
