<template>
  <AppCheckbox
    v-model="widget.show_score_filter"
    name="widget[show_score_filter]"
    :label="label || $t('label')"
    :disabled="!reviewSettings.show_score || disabled"
    :tooltip="reviewSettings.show_score ? null : $t('tooltip')"
    @change="$emit('change')"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ReviewSettingsShowScoreFilter',
  props: {
    widget: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('session', ['reviewSettings'])
  }
};
</script>

<i18n locale="ko">
{
  "label": "별점 필터 사용",
  "tooltip": "리뷰 별점 표시 설정을 사용하지 않으므로 별점 필터 사용이 불가합니다."
}
</i18n>
