<template>
  <AppContainer>
    <AppDataList :data="summaryDataList" />
  </AppContainer>
</template>

<script>
export default {
  name: 'BatchJobSummary',
  props: { batchJob: { type: Object, required: true } },
  computed: {
    summaryDataList() {
      return [
        {
          label: this.$t('batch_job.created_at'),
          value: this.batchJob.created_at,
          type: 'datetime'
        },
        {
          label: this.$t('batch_job.finished_at'),
          value: this.batchJob.finished_at,
          type: 'datetime'
        },
        {
          label: this.$t('app.result'),
          value: [
            this.fileErrorMessage,
            `${this.$t('batch_job.success_count', [
              this.batchJob.success_count
            ])}`,
            `${this.$t('batch_job.failure_count', [
              this.fileErrorMessage ? '-' : this.batchJob.failure_count
            ])}`
          ]
            .filter(Boolean)
            .join('\n'),
          type: 'pre'
        }
      ];
    },
    fileErrorMessage() {
      if (!this.batchJob.error_message) return null;

      let message_type;
      if (this.batchJob.error_message.includes('File not found'))
        message_type = 'error.file_not_found';
      else if (this.batchJob.error_message.includes('Invalid header'))
        message_type = 'error.invalid_headers';
      else if (this.batchJob.error_message.includes('Unrescuable bad encoding'))
        message_type = 'error.invalid_encoding';
      else return null;

      return this.$t('batch_job.error_message', [this.$t(message_type)]);
    }
  }
};
</script>

<i18n locale="ko">
  {
    "error": {
      "file_not_found": "csv 파일을 찾을 수 없습니다. 다시 시도해주세요.",
      "invalid_headers": "csv 파일 헤더에 다음 칼럼들 반드시 포함해야 합니다: shop_product_no, smartstore_product_no",
      "invalid_encoding": "csv 파일의 인코딩이 잘못돼 변환에 실패했습니다."
    }
  }
</i18n>
