<template>
  <div>
    <AppButton :label="label" button-style="blue" @click="toggle" />
    <AppDrawer
      :show="show"
      title="AppDrawer"
      sub-title="appdrawer"
      @close="toggle"
    />
  </div>
</template>

<script>
export default {
  name: 'UiDrawer',
  data: () => ({ show: false }),
  computed: {
    label() {
      return this.show ? 'hide' : 'show';
    }
  },
  methods: {
    toggle() {
      this.show = !this.show;
    }
  }
};
</script>
