var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('AppSearchbar',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('li',[_c('AppSelect',{attrs:{"options":_vm.optionTypes.map(function (t) { return ({ label: t.name, value: t.id }); })},on:{"change":_vm.searchResource},model:{value:(_vm.optionTypeId),callback:function ($$v) {_vm.optionTypeId=$$v},expression:"optionTypeId"}})],1),_c('li',[_c('AppButtonToggle',{attrs:{"label":_vm.$t('app.advanced_search')},model:{value:(_vm.isAdvancedSearchVisible),callback:function ($$v) {_vm.isAdvancedSearchVisible=$$v},expression:"isAdvancedSearchVisible"}})],1)]},proxy:true},{key:"advanced-search",fn:function(){return [_c('AppAdvancedSearch',{attrs:{"visible":_vm.isAdvancedSearchVisible,"sections":_vm.advancedSearchSections},on:{"submit":_vm.searchResource},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
var inputId = ref.inputId;
return [(id === 'product_filter')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelect',{attrs:{"id":inputId,"options":(_vm.isShopifyBrand
                      ? ['product']
                      : ['category', 'product']
                    ).map(function (value) { return ({
                      label: _vm.$t(("product_filter." + value)),
                      value: value
                    }); }),"placeholder":_vm.$t('search.all')},on:{"change":_vm.changeProductFilter},model:{value:(_vm.resourceParams.product_filter),callback:function ($$v) {_vm.$set(_vm.resourceParams, "product_filter", $$v)},expression:"resourceParams.product_filter"}})],1),(_vm.resourceParams.product_filter === 'category')?_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectProductCategory',{attrs:{"packing-method":"string","multiple":""},model:{value:(_vm.resourceParams.category_ids),callback:function ($$v) {_vm.$set(_vm.resourceParams, "category_ids", $$v)},expression:"resourceParams.category_ids"}})],1):(_vm.resourceParams.product_filter === 'product')?_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectProduct',{attrs:{"products":_vm.products,"packing-method":"string","multiple":""},on:{"change":function($event){_vm.resourceParams.product_ids = $event}}})],1):_vm._e()]:_vm._e(),(id === 'user_filter')?_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelect',{attrs:{"id":inputId,"options":['click', 'purchase'].map(function (value) { return ({
                    label: _vm.$t(("user_filter." + value)),
                    value: value
                  }); }),"placeholder":_vm.$t('search.all')},on:{"change":_vm.changeUserFilter},model:{value:(_vm.resourceParams.user_filter),callback:function ($$v) {_vm.$set(_vm.resourceParams, "user_filter", $$v)},expression:"resourceParams.user_filter"}}),(_vm.resourceParams.user_filter)?_c('i18n',{staticClass:"Analytics__sub-field",attrs:{"path":("user_filter_recent_days." + (_vm.resourceParams.user_filter))}},[_c('AppNumberInput',{attrs:{"default":1,"digits":2,"inline":""},model:{value:(_vm.resourceParams.recent_days),callback:function ($$v) {_vm.$set(_vm.resourceParams, "recent_days", $$v)},expression:"resourceParams.recent_days"}})],1):_vm._e()],1):_vm._e()]}}]),model:{value:(_vm.resourceParams),callback:function ($$v) {_vm.resourceParams=$$v},expression:"resourceParams"}})]},proxy:true}])}),_c('div',{staticClass:"Analytics__summary"},[(_vm.totalUsersCount)?[_vm._v(" "+_vm._s(_vm.$t('summary', [_vm.activeUsersCount, _vm.optionType.name, _vm.usersPercentage]))+" ")]:[_vm._v(_vm._s(_vm.$t('summary_no_data')))]],2),_c('AppChart',{attrs:{"options":_vm.chartOptions}}),_c('AppResourceTable',{attrs:{"table-name":_vm.$t('table.title', [_vm.optionType.name]),"rows":[_vm.totalRow ].concat( _vm.rows),"columns":_vm.COLUMNS,"enable-total-count":false,"table-style":"stats"}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }