<template>
  <div v-tooltip="tooltip" :class="switchClass" @click.prevent.stop="toggle">
    <div class="AppSwitch__inner" />
    <input
      :id="id"
      type="checkbox"
      :disabled="disabled"
      :name="name"
      :value="value"
      :checked="stateChecked"
      class="AppSwitch__checkbox"
    />
    <input
      v-if="value === '1' && !stateChecked"
      :name="name"
      value="0"
      type="hidden"
    />
  </div>
</template>

<script>
export default {
  name: 'AppSwitch',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    tooltip: {
      type: [String, Object],
      default: null
    },
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number, Boolean],
      default: '1'
    }
  },
  data() {
    return {
      stateChecked: this.checked
    };
  },
  computed: {
    switchClass() {
      return [
        'AppSwitch',
        {
          'AppSwitch--checked': this.stateChecked,
          'AppSwitch--disabled': this.disabled
        }
      ];
    }
  },
  watch: {
    checked(val) {
      this.stateChecked = val;
    }
  },
  methods: {
    toggle() {
      if (this.disabled) return;

      this.stateChecked = !this.stateChecked;
      this.$emit('change', this.stateChecked);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_transitions.scss';

.AppSwitch {
  @include transition-basic(background-color);

  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 28px;
  height: 16px;
  border-radius: 10px;
  background-color: $color-grey-35;
  cursor: pointer;

  &:hover {
    background-color: $color-grey-40;
  }
}

.AppSwitch__inner {
  @include transition-basic(left);

  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: white;
}

.AppSwitch__checkbox {
  display: none;
}

.AppSwitch--checked {
  &,
  &:hover {
    background-color: $color-primary;
  }

  .AppSwitch__inner {
    left: 14px;
  }
}

.AppSwitch--disabled {
  &,
  &:hover {
    background-color: $color-grey-25;
    cursor: default;
  }
}

.AppSwitch--disabled.AppSwitch--checked {
  &,
  &:hover {
    background-color: $color-primary-light;
  }
}
</style>
