import WidgetStyle from '@/enums/WidgetStyle';

export default {
  formSections({
    isGodo,
    isDeviceMobile,
    isDevicePc,
    isShopifyBrand,
    useVuejs
  }) {
    return {
      common: ['no_item_action'],
      display_products: [
        'product_display_filter',
        'product_review_display_filter',
        'exclude_product_ids',
        isShopifyBrand ? null : 'exclude_category_ids',
        'show_sold_out_products'
      ],
      sort: ['sort_types', 'recommendation_product_sort'],
      feature: [
        isGodo || useVuejs ? null : 'use_original_product_image',
        'review_show_photo_first',
        'item_display_fields',
        'list_display_fields'
      ],
      design: [
        useVuejs ? null : 'align_row',
        useVuejs ? 'product_thumbnail_ratio' : null,
        'posts_per_page',
        isDevicePc ? 'posts_per_line' : null,
        isDeviceMobile ? 'mobile_posts_per_line' : null,
        useVuejs ? null : 'max_pages',
        isDevicePc ? 'pagination_method' : null,
        isDeviceMobile ? 'pagination_method_for_mobile' : null
      ]
    };
  },
  widgetStyles() {
    return [WidgetStyle.GALLERY];
  },
  displayOrderOptions() {
    return [];
  },
  showHeaderSectionSubSettings() {
    return [];
  },
  useBackgroundColor() {
    return true;
  }
};
