import { constructEnum } from '@/lib/enum';

const GiveMileageExpiresFromSoldoutType = constructEnum(
  'GiveMileageExpiresFromSoldoutType',
  {
    ALWAYS: 0,
    NDAYS: 10,
    NONE: 20
  }
);

export default GiveMileageExpiresFromSoldoutType;
