<template>
  <AppSelectFilterable
    width="64px"
    v-bind="{ value, options, placeholder }"
    :dropdown-props="{ placeholder, noResults: '', number: { min, max } }"
    @change="$emit('change', $event)"
  />
</template>

<script>
import _ from 'lodash';

export default {
  name: 'AppNumberSearchable',
  model: { event: 'change' },
  props: {
    value: { type: Number, default: null },
    min: { type: Number, required: true },
    max: { type: Number, required: true },
    placeholder: { type: String, default: null }
  },
  computed: {
    options() {
      return _.range(this.min, this.max + 1).map(value => ({
        label: String(value).padStart(2, '0'),
        value
      }));
    }
  }
};
</script>
