<template>
  <div class="ReviewReportsMileage">
    <AppReportsSearchbar v-bind="searchBarProps" v-on="searchBarEvents" />
    <ReviewReportsWarning />
    <AppChart
      :options="chartOptions"
      :tooltip="chartTooltip"
      @tooltip="onChartTooltip"
    />
    <AppResourceTable
      :resources="resources"
      :rows="rows"
      :main-columns="MAIN_COLUMNS"
      :columns="COLUMNS"
      :enable-total-count="false"
      table-style="stats"
      @paginate="paginate"
    />
  </div>
</template>

<script>
import _ from 'lodash';

import ReportsView from '@/mixins/ReportsView';
import ReviewReportsWarning from '@/views/review/reports/ReviewReportsWarning';

export default {
  name: 'ReviewReportsMileage',
  components: { ReviewReportsWarning },
  mixins: [ReportsView],
  data() {
    return {
      MAIN_COLUMNS: [
        {},
        { label: this.$t('mileage_amount'), colspan: 3 },
        { label: this.$t('mileage_count'), colspan: 3 }
      ],
      COLUMNS: [
        { id: 'date', label: this.$t('app.date'), type: 'pre' },
        {
          id: 'mileage_amount_with_review',
          label: this.$t('mileage_amount_with_review'),
          type: 'number',
          default: '-'
        },
        {
          id: 'mileage_amount_with_review_text',
          label: this.$t('mileage_amount_with_review_text'),
          type: 'number',
          default: '-'
        },
        {
          id: 'mileage_amount_with_review_media',
          label: this.$t('mileage_amount_with_review_media'),
          type: 'text',
          default: '-'
        },
        {
          id: 'mileage_count_with_review',
          label: this.$t('total_mileage_count_with_review'),
          type: 'number',
          default: '-'
        },
        {
          id: 'mileage_count_with_review_text',
          label: this.$t('mileage_count_with_review_text'),
          type: 'number',
          default: '-'
        },
        {
          id: 'mileage_count_with_review_media',
          label: this.$t('mileage_count_with_review_media'),
          type: 'text',
          default: '-'
        }
      ],
      chartTooltip: null
    };
  },
  computed: {
    rows() {
      const mileageAmountWithReviewMedia = _.sumBy(
        this.reportsChart,
        'mileage_amount_with_review_media'
      );
      const mileageAmountWithReviewPhoto = _.sumBy(
        this.reportsChart,
        'mileage_amount_with_review_photo'
      );
      const mileageAmountWithReviewVideo = _.sumBy(
        this.reportsChart,
        'mileage_amount_with_review_video'
      );
      const mileageCountWithReviewMedia = _.sumBy(
        this.reportsChart,
        'mileage_count_with_review_media'
      );
      const mileageCountWithReviewPhoto = _.sumBy(
        this.reportsChart,
        'mileage_count_with_review_photo'
      );
      const mileageCountWithReviewVideo = _.sumBy(
        this.reportsChart,
        'mileage_count_with_review_video'
      );

      const totalRow = {
        date: this.$t('total'),
        rowStyle: 'stats-total',
        mileage_amount_with_review: _.sumBy(
          this.reportsChart,
          'mileage_amount_with_review'
        ),
        mileage_amount_with_review_text: _.sumBy(
          this.reportsChart,
          'mileage_amount_with_review_text'
        ),
        mileage_amount_with_review_media: mileageAmountWithReviewMedia
          ? `${mileageAmountWithReviewMedia} (${mileageAmountWithReviewPhoto}/${mileageAmountWithReviewVideo})`
          : '-',
        mileage_count_with_review: _.sumBy(
          this.reportsChart,
          'mileage_count_with_review'
        ),
        mileage_count_with_review_text: _.sumBy(
          this.reportsChart,
          'mileage_count_with_review_text'
        ),
        mileage_count_with_review_media: mileageCountWithReviewMedia
          ? `${mileageCountWithReviewMedia} (${mileageCountWithReviewPhoto}/${mileageCountWithReviewVideo})`
          : '-'
      };
      const rows = [totalRow];
      return rows.concat(
        this.reportsTable.map(item => ({
          ...item,
          mileage_amount_with_review_media: item[
            'mileage_amount_with_review_media'
          ]
            ? `${item['mileage_amount_with_review_media']} (${item['mileage_amount_with_review_photo']}/${item['mileage_amount_with_review_video']})`
            : '-',
          mileage_count_with_review_media: item[
            'mileage_count_with_review_media'
          ]
            ? `${item['mileage_count_with_review_media']} (${item['mileage_count_with_review_photo']}/${item['mileage_count_with_review_video']})`
            : '-',
          date: this.getFormattedDate(item ? item.date : null),
          rowStyle: 'stats-summary'
        }))
      );
    },
    chartOptions() {
      return {
        title: { text: this.$t('amount') },
        xAxis: { type: 'datetime' },
        yAxis: [
          {
            title: { text: this.$t('mileage_amount') },
            labels: { format: `{value:,.0f} ${this.$t('money')}` }
          },
          {
            title: { text: this.$t('mileage_count') },
            labels: { format: `{value:,.0f} ${this.$t('count')}` },
            opposite: true
          }
        ],
        tooltip: {
          enabled: false
        },
        plotOptions: {
          line: {
            enableMouseTracking: true
          }
        },
        series: [
          {
            name: this.$t('mileage_amount_with_review'),
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData('mileage_amount_with_review'),
            type: 'column',
            yAxis: 0
          },
          {
            name: this.$t('mileage_amount_with_review_text'),
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData('mileage_amount_with_review_text'),
            type: 'column',
            yAxis: 0
          },
          {
            name: this.$t('mileage_amount_with_review_media'),
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData('mileage_amount_with_review_media'),
            type: 'column',
            yAxis: 0
          },
          {
            name: this.$t('total_mileage_count_with_review'),
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData('mileage_count_with_review'),
            type: 'line',
            yAxis: 1
          }
        ]
      };
    }
  },
  methods: {
    onChartTooltip({ x, index, series }) {
      this.chartTooltip = {
        title: this.getFormattedDate(new Date(x)),
        items: series.chart.series.map(({ data, name }) => ({
          symbol: 'circle',
          name,
          value: data[index].y
        }))
      };
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .AppResourceTableCell {
  flex: 1 0 120px;
}
</style>

<i18n locale="ko">
{
  "total": "총 합계",
  "amount": "적립금",
  "mileage_amount": "지급액",
  "mileage_count": "지급건수",
  "mileage_amount_with_review": "총지급액",
  "mileage_amount_with_review_text": "텍스트리뷰 지급액",
  "mileage_amount_with_review_media": "포토/동영상 리뷰 지급액",
  "total_mileage_count_with_review": "총건수",
  "mileage_count_with_review_text": "텍스트리뷰 지급건수",
  "mileage_count_with_review_media": "포토/동영상 리뷰 지급건수",
  "count": "건",
  "money": "원"
}
</i18n>
