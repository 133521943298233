var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DisplayCornerItemsContainer"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowingDropBar),expression:"isShowingDropBar"}],ref:"dropBar",staticClass:"DisplayCornerItemsContainer__drop-bar"}),_c('DisplayCornerItemsMatrix',_vm._b({attrs:{"item-matrix":_vm.itemMatrix},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item)?_c('DisplayCornerItemWrapper',{staticClass:"DisplayCornerItemsContainer__item",attrs:{"data-item-index":item.index},nativeOn:{"drag":function($event){return _vm.drag.apply(null, arguments)},"dragstart":function($event){return _vm.dragstart.apply(null, arguments)},"dragend":function($event){return _vm.dragend.apply(null, arguments)}}},[_vm._t("item",null,{"item":item})],2):_vm._e()]}}],null,true)},'DisplayCornerItemsMatrix',_vm.previewItemsProps,false)),_c('AppPagination',{staticClass:"DisplayCornerItemsContainer__pagination",attrs:{"current-page":_vm.currentPage,"per":_vm.previewSettings.preview_products_count_in_page,"total-count":_vm.itemZones[0].length,"enable-route":false},on:{"paginate":_vm.updateCurrentPage}}),_vm._l((_vm.surplusItemMatrices),function(ref,i){
var matrix = ref.matrix;
var label = ref.label;
return [_c('AppInfoBox',{key:("infobox-" + i),staticClass:"DisplayCornerItemsContainer__info-box",style:(("max-width: " + _vm.rowWidth + "px"))},[_vm._v(_vm._s(_vm.$t(label)))]),_c('DisplayCornerItemsMatrix',_vm._b({key:("surplus-items-" + i),attrs:{"item-matrix":matrix},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item)?_c('DisplayCornerItemWrapper',{staticClass:"DisplayCornerItemsContainer__item",attrs:{"data-item-index":item.index},nativeOn:{"drag":function($event){return _vm.drag.apply(null, arguments)},"dragstart":function($event){return _vm.dragstart.apply(null, arguments)},"dragend":function($event){return _vm.dragend.apply(null, arguments)}}},[_vm._t("item",null,{"item":item})],2):_vm._e()]}}],null,true)},'DisplayCornerItemsMatrix',_vm.previewItemsProps,false))]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }