<template>
  <div class="AppSearchInput">
    <input
      class="AppSearchInput__input"
      type="text"
      :value="currentValue"
      :placeholder="placeholder"
      @input="change($event.target.value)"
    />
    <button type="submit" class="AppSearchInput__button">
      <AppSvgIcon
        class="AppSearchInput__button-icon"
        name="icon-search-small"
      />
    </button>
  </div>
</template>

<script>
import TextInput from '@/mixins/TextInput';
export default { name: 'AppSearchInput', mixins: [TextInput] };
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppSearchInput {
  position: relative;
  display: inline-block;
}

.AppSearchInput__input {
  @include input-base;

  display: block;
  outline: none;
  padding: 3px 35px 3px 11px;
  box-sizing: border-box;

  &::placeholder {
    color: $color-disable-text;
  }
}

.AppSearchInput__button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 12px;
}

.AppSearchInput__button-icon {
  stroke: $color-grey-40;
}
</style>
