<template>
  <div>
    <div class="ReviewShopifyOnboardGuide__title">{{ $t('title') }}</div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      ref="content"
      class="ReviewShopifyOnboardGuide__content"
      v-html="shopifyOnboardGuide"
    />
    <!-- eslint-enable vue/no-v-html -->

    <ReviewShopifyWidgetInstallation />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import ReviewShopifyWidgetInstallation from './ReviewShopifyWidgetInstallation.vue';

export default {
  name: 'ReviewShopifyOnboardGuide',
  components: { ReviewShopifyWidgetInstallation },
  data() {
    return { shopifyOnboardGuide: null };
  },
  mounted() {
    this.SET_SHOW_CUSTOM_TITLE(true);
    this.fetchShopifyOnboardGuide();
  },
  beforeDestroy() {
    this.SET_SHOW_CUSTOM_TITLE(false);
  },
  methods: {
    ...mapMutations('menu', ['SET_SHOW_CUSTOM_TITLE']),
    fetchShopifyOnboardGuide() {
      api
        .get('/review/shopify_onboard_guide')
        .then(
          ({ data }) => (this.shopifyOnboardGuide = data.shopify_onboard_guide)
        );
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewShopifyOnboardGuide__title {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #424244;
  padding-bottom: 28px;
}
</style>

<i18n locale="ko">
{
  "title": "크리마 리뷰 설치를 환영합니다!"
}
</i18n>
<i18n locale="en">
{
  "title": "Welcome to CREMA Review!"
}
</i18n>
