<template>
  <div>
    <AppContainer>
      <AppDataList :data="infoData" />
    </AppContainer>
    <AppEmbedPdf
      v-if="isPdf(attachmentUrl)"
      :url="attachmentUrl"
      :width="635"
      :height="819"
    />
    <AppImage v-else-if="attachmentUrl" :src="attachmentUrl" />
    <template v-for="(extraDoc, index) in extraDocs">
      <AppEmbedPdf
        v-if="isPdf(extraDoc.url)"
        :key="index"
        :url="extraDoc.url"
        :width="635"
        :height="819"
      />
      <AppImage v-else-if="extraDoc.url" :key="index" :src="extraDoc.url" />
    </template>
  </div>
</template>

<script>
export default {
  name: 'TheBrandMaterial',
  props: {
    materialType: { type: String, required: true },
    material: { type: Object, required: true },
    hideStatusBadge: { type: Boolean, default: false }
  },
  computed: {
    infoData() {
      return [
        {
          label: this.$t('box.uploaded_at'),
          value: this.material.created_at,
          type: 'datetime'
        },
        ...(this.hideStatusBadge
          ? []
          : [
              {
                label: this.$t('box.status.label'),
                value: {
                  label: this.$t(`box.status.${this.material.status}`),
                  style: this.badgeStyle
                },
                type: 'badge'
              }
            ]),
        ...(this.materialType === 'corporate_cert' &&
        !this.material.cert_image_url
          ? [
              'corporate_number',
              'company_name',
              'company_president_name',
              'company_address',
              'tax_invoice_officer_email',
              'industry',
              'industry_detail',
              'company_code'
            ].map(key => ({
              label: this.$t(`box.${key}`),
              value: this.material[key] || this.$t('box.empty')
            }))
          : [])
      ];
    },
    attachmentUrl() {
      return this.material.cert_image_url;
    },
    extraDocs() {
      return this.material.extra_docs || [];
    },
    badgeStyle() {
      switch (this.material.status) {
        case 'none':
          return 'red-outline';
        case 'requested':
          return 'mango-outline';
        case 'approved':
          return 'default';
        default:
          return '';
      }
    }
  },
  methods: {
    isPdf(url) {
      return url?.endsWith('pdf');
    }
  }
};
</script>

<i18n locale="ko">
{
  "box": {
    "uploaded_at": "등록일",
    "status": {
      "label": "등록 상태",
      "none": "등록 전",
      "requested": "관리자 확인 중",
      "approved": "등록 완료"
    },
    "corporate_number": "사업자등록번호",
    "company_name": "상호",
    "company_president_name": "대표자명",
    "company_address": "사업장 주소",
    "tax_invoice_officer_email": "세금계산서 담당자 이메일 주소",
    "industry": "업태",
    "industry_detail": "종목",
    "company_code": "종사업장 번호",
    "empty": "정보없음"
  }
}
</i18n>
