<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{
      ...formProps,
      objectId: 'target_transaction',
      detectFormDataChange: false,
      submitLabel: $t('submit'),
      submittingLabel: $t('submitting')
    }"
    v-on="formEvents"
  >
    <input type="hidden" name="target_transaction[message]" :value="message" />
  </AppModalForm>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ThePaymentHistoryBonusTargetDialog',
  mixins: [DialogFormView],
  data() {
    return {
      orgFormObject: { target_transaction_type: 'bonus_point' }
    };
  },
  computed: {
    ...mapGetters('session', ['currencyKey']),
    formSections() {
      return [
        {
          id: 'default',
          groups: [
            {
              id: 'target_transaction_type',
              label: this.$t('target_transaction.target_transaction_type'),
              type: 'select',
              options: ['bonus_point', 'bonus_cash'].map(value => ({
                label: this.$t(`target_transaction_type.label.${value}`),
                value
              }))
            },
            {
              id: 'message',
              name: null,
              label: this.$t('target_transaction.message'),
              type: 'text',
              placeholder: this.transactionTypePlaceholder(
                this.formObject.target_transaction_type
              )
            },
            {
              id: 'amount',
              label: this.$t('target_transaction.amount'),
              digits: 9,
              allowNegative: true,
              type: 'number',
              validate: ['required']
            }
          ]
        }
      ];
    },
    message() {
      const { message } = this.formObject;

      return (
        message ||
        this.transactionTypePlaceholder(this.formObject.target_transaction_type)
      );
    }
  },
  methods: {
    ...mapMutations('session', ['SET_TARGET_STATUS']),
    transactionTypePlaceholder(transactionType) {
      return this.$t(`target_transaction_type.placeholder.${transactionType}`);
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/payment_history/target_transactions', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          if (data.target_status) this.SET_TARGET_STATUS(data.target_status);
          this.eventBus.$emit('update', data);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "충전 및 추가하기",
  "sub_title": "타겟 이용내역",
  "submit": "충전하기",
  "submitting": "충전 중..",
  "target_transaction": {
    "target_transaction_type": "항목 선택",
    "message": "메시지 입력",
    "amount": "충전/차감 금액(원)"
  },
  "target_transaction_type": {
    "label": {
      "bonus_point": "보상 포인트",
      "bonus_cash": "캐시"
    },
    "placeholder": {
      "bonus_point": "운영팀 수동 추가 보상 포인트 지급",
      "bonus_cash": "운영팀 수동 추가 캐시 지급"
    }
  }
}
</i18n>
