<template>
  <div>
    <AppInfoBox class="AnalyticsReviewStatistics__info-box">
      {{ $t('info_message') }}
    </AppInfoBox>
    <AppIframeViewer
      :src="brandSettings.review_statistics_bi_url"
      class="AnalyticsReviewStatistics__iframe"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AnalyticsReviewStatistics',
  computed: {
    ...mapState('session', ['brandSettings'])
  }
};
</script>

<style lang="scss" scoped>
.AnalyticsReviewStatistics__info-box {
  margin-bottom: 20px;
}

.AnalyticsReviewStatistics__iframe {
  padding-top: 62%;
}

::v-deep {
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
</style>

<i18n locale="ko">
{
  "info_message": "대시보드 하단에서 페이지를 이동할 수 있습니다."
}
</i18n>
