var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AnalyticsInsightDashboardKeywords"},[_c('div',{staticClass:"AnalyticsInsightDashboardKeywords__title"},[_vm._v(" "+_vm._s(_vm.$t('keywords_analysis'))+" ")]),_c('AppButton',{staticClass:"AnalyticsInsightDashboardKeywords__button",attrs:{"type":"link","to":_vm.pathWithDateRangeParams('insight_keywords'),"label":_vm.$t('view_keywords_analysis')}}),_c('AppGrid',[_c('AnalyticsInsightDashboardKeywordsDetails',_vm._b({},'AnalyticsInsightDashboardKeywordsDetails',{
        dateRange: _vm.dateRange,
        title: _vm.$t('insight.my_brand'),
        details: _vm.myBrandKeywordsDetails
      },false)),_c('AnalyticsInsightDashboardKeywordsDetails',_vm._b({},'AnalyticsInsightDashboardKeywordsDetails',{
        dateRange: _vm.dateRange,
        title: _vm.$t('insight.brands'),
        details: _vm.brandsKeywordsDetails
      },false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }