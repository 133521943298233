<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppDateRangePicker v-model="dateRangeBasic" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Required</div>
      <AppDateRangePicker v-model="dateRangeRequired" required />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Disabled</div>
      <AppDateRangePicker v-model="dateRangeDisabled" disabled />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Invalid</div>
      <AppDateRangePicker v-model="dateRangeInvalid" invalid required />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Preset Ranges</div>
      <AppDateRangePicker
        v-model="dateRangePreset"
        :presets="['last_7days', 'last_30days']"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Preset Value</div>
      <AppDateRangePicker v-model="dateRangeDatePreset" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Right Aligned</div>
      <div class="ui-section__item ui-section__item--right">
        <AppDateRangePicker v-model="dateRangeRight" />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Min</div>
      <div class="ui-section__item">
        <AppDateRangePicker v-model="dateRangeMin" min-date="2020-01-10" />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Min Days (7 days)</div>
      <div class="ui-section__item">
        <AppDateRangePicker v-model="dateRangeMin" :min-days="7" />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Max Days (7 days)</div>
      <div class="ui-section__item">
        <AppDateRangePicker v-model="dateRangeMax" :max-days="7" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiDateRangePicker',
  data() {
    return {
      dateRangeBasic: {},
      dateRangeRequired: {},
      dateRangeDisabled: {},
      dateRangeInvalid: {},
      dateRangePreset: {},
      dateRangeDatePreset: { start_date: '2018-05-10', end_date: '2018-05-10' },
      dateRangeRight: { start_date: '2003-02-25', end_date: '2008-02-24' },
      dateRangeMin: { start_date: '2020-01-13', end_date: '2020-01-15' },
      dateRangeMax: { start_date: '2020-01-13', end_date: '2020-01-30' }
    };
  }
};
</script>
