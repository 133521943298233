<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic</label>
      </div>
      <AppKeywordTextarea
        id="basic"
        v-model="valueBasic"
        :keywords="KEYWORDS"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="placeholder">Placeholder</label>
      </div>
      <AppKeywordTextarea
        id="placeholder"
        v-model="valuePlaceholder"
        placeholder="Hi, there!"
        :keywords="KEYWORDS"
      />
      <div class="ui-section__description">
        Selected Value: {{ valuePlaceholder }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppKeywordTextarea
        id="disabled"
        v-model="valueDisabled"
        :disabled="isDisabled"
        :keywords="KEYWORDS"
      />
      <AppCheckbox v-model="isDisabled" label="Disabled" class="mt4" />
      <div class="ui-section__description">
        Selected Value: {{ valueDisabled }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppKeywordTextarea
        id="invalid"
        v-model="valueInvalid"
        invalid
        :keywords="KEYWORDS"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="max_bytes">Max Bytes</label>
      </div>
      <AppKeywordTextarea
        id="max_bytes"
        v-model="valueMaxBytes"
        placeholder="10 bytes 이내로 입력해주세요."
        :max-bytes="10"
        :keywords="KEYWORDS"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueMaxBytes }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="max_length">Max Characters</label>
      </div>
      <AppKeywordTextarea
        id="max_length"
        v-model="valueMaxLength"
        placeholder="70자 이내로 입력해주세요."
        :maxlength="70"
        :keywords="KEYWORDS"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueMaxLength }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="textarea_single_line">Single Line</label>
      </div>
      <AppKeywordTextarea
        id="textarea_single_line"
        v-model="valueLongSingleLine"
        :keywords="KEYWORDS"
        single-line
      />
      <div class="ui-section__description">
        Selected Value: {{ valueLongSingleLine }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="textarea_multi_line">Multi Line</label>
      </div>
      <AppKeywordTextarea
        id="textarea_multi_line"
        v-model="valueLongMultiLine"
        :keywords="KEYWORDS"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueLongMultiLine }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiKeywordTextarea',
  data() {
    return {
      KEYWORDS: [
        { key: 'user', desc: '고객명', value: 'Mahatma Gandhi' },
        { key: 'user_id', desc: '고객 아이디', value: 'mgandhi' },
        {
          key: 'veeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeery_long_keyword',
          desc: 'Veeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeery Long Keyword',
          value: 'piece'
        },
        {
          key: 'very_long_keyword',
          desc: 'Very Long Keyword',
          value: 'piece'
        }
      ],
      valueBasic:
        'not supported => %{blahblah}\ncorrect<br /> => %{user}, %{user_id}',
      valuePlaceholder: '',
      valueDisabled:
        'not supported => %{blahblah}\ncorrect<br /> => %{user}, %{user_id}',
      valueInvalid:
        'not supported => %{blahblah}\ncorrect<br /> => %{user}, %{user_id}',
      valueMaxBytes: '%{veeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeery_long_keyword}',
      valueMaxLength: '%{very_long_keyword}',
      valueLongSingleLine:
        'Lorem ipsum %{invalid} sit amet, consectetur %{user} elit. Vivamus finibus sit amet arcu nec commodo. Aliquam non dui cursus, finibus sapien ornare, commodo odio. In iaculis convallis ullamcorper. Sed tellus odio, facilisis gravida %{user_id} suscipit, aliquet sit amet urna. %{veeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeery_long_keyword} non enim at massa aliquet mattis nec ut arcu. Curabitur mollis nec dui in semper. Cras eget molestie purus, eu porta nisl. Nam rutrum eu ipsum ut suscipit. Maecenas vitae eros eros. Aliquam facilisis ante at nulla tempus, id accumsan magna malesuada. Cras mattis, nulla vel tincidunt dictum, est arcu mattis velit, eu rhoncus ex odio aliquet diam. Interdum et malesuada fames ac ante ipsum primis in faucibus.',
      valueLongMultiLine:
        'Lorem ipsum %{invalid} sit amet, consectetur %{user} elit.\nVivamus finibus sit amet arcu nec commodo.\nAliquam non dui cursus, finibus sapien ornare, commodo odio.\nIn iaculis convallis ullamcorper.\nSed tellus odio, facilisis gravida %{user_id} suscipit, aliquet sit amet urna.\n%{veeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeery_long_keyword} non enim at massa aliquet mattis nec ut arcu.\nCurabitur mollis nec dui in semper.\nCras eget molestie purus, eu porta nisl.\nNam rutrum eu ipsum ut suscipit.\nMaecenas vitae eros eros.\nAliquam facilisis ante at nulla tempus, id accumsan magna malesuada.\nCras mattis, nulla vel tincidunt dictum, est arcu mattis velit, eu rhoncus ex odio aliquet diam.\nInterdum et malesuada fames ac ante ipsum primis in faucibus.',
      isDisabled: true
    };
  }
};
</script>
