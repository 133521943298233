import { constructEnum } from '@/lib/enum';

const BrandDisplayStatus = constructEnum('BrandDisplayStatus', {
  NONE: 0,
  REQUESTED: 10,
  TESTING: 15,
  FREE_TRIAL_OPERATING: 20,
  FREE_TRIAL_EXPIRED: 24,
  OPERATING: 30,
  EXPIRED: 40,
  EXPIRED_AND_ADMIN_BLOCKED: 80,
  TERMINATED: 90
});

BrandDisplayStatus.MENU_ACCESSIBLE_STATUSES = [
  BrandDisplayStatus.TESTING,
  BrandDisplayStatus.FREE_TRIAL_OPERATING,
  BrandDisplayStatus.FREE_TRIAL_EXPIRED,
  BrandDisplayStatus.OPERATING,
  BrandDisplayStatus.EXPIRED
];

BrandDisplayStatus.isMenuAccessible = function(brandDisplayStatus) {
  return BrandDisplayStatus.MENU_ACCESSIBLE_STATUSES.includes(
    brandDisplayStatus
  );
};
export default BrandDisplayStatus;
