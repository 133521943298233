<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <AppForm
      :id="SETTINGS_ID"
      v-bind="formProps"
      submit-button
      object-id="settings"
      v-on="formEvents"
    >
      <input
        type="hidden"
        name="settings[enable_dispatch]"
        :value="!formObject.disable_dispatch"
      />
      <template #group="{ id, errors }">
        <template v-if="id === 'sender_email'">
          <AppSenderEmailInput
            v-model="senderEmail.last_change_request"
            :email="senderEmail.email"
            :verification-status="senderEmail.verification_status"
          />
        </template>
        <template v-else-if="id === 'enable_popular_products_limit_of_period'">
          <div class="AppForm__group-field">
            <i18n path="form.enable_popular_products_limit_of_period.field">
              <AppSelect
                v-model="formObject.popular_products_selection_criteria"
                name="settings[popular_products_selection_criteria]"
                :options="productSelectionCriteriaOptions"
                :disabled="!formObject.enable_popular_products_limit_of_period"
                inline
              />
              <AppNumberInput
                v-model="formObject.popular_products_limit_of_period"
                name="settings[popular_products_limit_of_period]"
                :disabled="!formObject.enable_popular_products_limit_of_period"
                :digits="2"
                :invalid="!!errors.popular_products_limit_of_period"
                inline
                @change="validateField('popular_products_limit_of_period')"
                @blur="validateField('popular_products_limit_of_period')"
              />
            </i18n>
            <AppFormError :error="errors.popular_products_limit_of_period" />
          </div>
          <input
            v-if="!formObject.enable_popular_products_limit_of_period"
            type="hidden"
            name="settings[popular_products_limit_of_period]"
            value=""
          />
        </template>
        <template
          v-else-if="id === 'enable_recommendation_products_limit_of_period'"
        >
          <div class="AppForm__group-field">
            <i18n
              path="form.enable_recommendation_products_limit_of_period.field"
            >
              <AppSelect
                v-model="formObject.recommendation_products_selection_criteria"
                name="settings[recommendation_products_selection_criteria]"
                :options="productSelectionCriteriaOptions"
                :disabled="
                  !formObject.enable_recommendation_products_limit_of_period
                "
                inline
              />
              <AppNumberInput
                v-model="formObject.recommendation_products_limit_of_period"
                name="settings[recommendation_products_limit_of_period]"
                :disabled="
                  !formObject.enable_recommendation_products_limit_of_period
                "
                :digits="2"
                :invalid="!!errors.recommendation_products_limit_of_period"
                inline
                @change="
                  validateField('recommendation_products_limit_of_period')
                "
                @blur="validateField('recommendation_products_limit_of_period')"
              />
            </i18n>
            <AppFormError
              :error="errors.recommendation_products_limit_of_period"
            />
          </div>
          <input
            v-if="!formObject.enable_recommendation_products_limit_of_period"
            type="hidden"
            name="settings[recommendation_products_limit_of_period]"
            value=""
          />
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';
import TargetContentView from '@/mixins/TargetContentView';

export default {
  name: 'TargetSettingsBasic',
  mixins: [FormView, SettingsView, TargetContentView],
  data: () => ({
    SETTINGS_ID: 'target_settings',
    isLoading: true,
    senderEmail: null
  }),
  computed: {
    ...mapState('session', ['targetSettings']),
    ...mapGetters(['pathWithBrandParams']),
    groupOptions() {
      return {
        disable_dispatch: { type: 'checkbox', name: null },
        unsubscribe_phone: {
          type: 'tel',
          placeholder: this.$t('form.unsubscribe_phone.placeholder'),
          hint: {
            message: this.$t('form.unsubscribe_phone.hint'),
            priority: 'danger'
          },
          validate: ['required', 'korean_phone_format']
        },
        popup_blacklist_usernames: {
          type: 'tags',
          placeholder: this.$t('form.popup_blacklist_usernames.placeholder'),
          searchPlaceholder: this.$t(
            'form.popup_blacklist_usernames.search_placeholder'
          ),
          searchNoResults: this.$t(
            'form.popup_blacklist_usernames.search_no_results'
          )
        },
        enable_popular_products_limit_of_period: {
          type: 'checkbox',
          name: null,
          fields: this.formObject.enable_popular_products_limit_of_period
            ? [
                {
                  id: 'popular_products_limit_of_period',
                  validate: ['required', 'positive_integer']
                }
              ]
            : []
        },
        enable_recommendation_products_limit_of_period: {
          type: 'checkbox',
          name: null,
          fields: this.formObject.enable_recommendation_products_limit_of_period
            ? [
                {
                  id: 'recommendation_products_limit_of_period',
                  validate: ['required', 'positive_integer']
                }
              ]
            : []
        }
      };
    },
    productSelectionCriteriaOptions() {
      return [
        { label: this.$t('product_selection_creteria.created_at'), value: 10 },
        { label: this.$t('product_selection_creteria.updated_at'), value: 20 }
      ];
    }
  },
  watch: {
    targetSettings: {
      deep: true,
      handler() {
        const disable_dispatch = !this.targetSettings.enable_dispatch;
        this.orgFormObject.disable_dispatch = disable_dispatch;
        this.$set(this.formObject, 'disable_dispatch', disable_dispatch);
      }
    }
  },
  mounted() {
    api
      .get('/target/dispatch_settings')
      .then(({ data }) => {
        const { settings, sender_email } = data;
        this.orgFormObject = {
          ...settings,
          disable_dispatch: !settings.enable_dispatch,
          enable_popular_products_limit_of_period: !!settings.popular_products_limit_of_period,
          enable_recommendation_products_limit_of_period: !!settings.recommendation_products_limit_of_period
        };
        this.senderEmail = sender_email;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('session', ['SET_TARGET_SETTINGS_ENABLE_DISPATCH']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/target/dispatch_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
          this.SET_TARGET_SETTINGS_ENABLE_DISPATCH(
            !this.formObject.disable_dispatch
          );
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetSettings__csv-file-input {
  display: inline-block;
  width: 372px;
  margin-right: 8px;
}

::v-deep {
  #target_settings_unsubscribe_phone {
    width: 150px;
  }
}
</style>

<i18n locale="ko">
{
  "form": {
    "unsubscribe_phone": {
      "placeholder": "번호를 입력해주세요.",
      "hint": "정보통신망법 제 50조에 의해 광고성 메시지의 수신 거부 번호는 무료번호(예: 080) 사용이 필수입니다."
    },
    "popup_blacklist_usernames": {
      "placeholder": "ID를 입력해주세요.",
      "search_placeholder": "ID를 입력해주세요.",
      "search_no_results": "DM/팝업 캠페인 발송 및 노출을 제한할 ID를 추가할 수 있습니다."
    },
    "enable_popular_products_limit_of_period": {
      "field": "{0}기준 {1}개월 이내 상품 중에서 선정"
    },
    "enable_recommendation_products_limit_of_period": {
      "field": "{0}기준 {1}개월 이내 상품 중에서 선정"
    }
  },
  "product_selection_creteria": {
    "created_at": "등록일",
    "updated_at": "최근 입력일"
  }
}
</i18n>
