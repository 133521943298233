var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppSearchbar',{on:{"submit":_vm.searchResource},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('li',[_c('AppSearchWithType',{attrs:{"search-types":_vm.SEARCH_TYPES},model:{value:(_vm.searchWithType),callback:function ($$v) {_vm.searchWithType=$$v},expression:"searchWithType"}})],1),_c('li',[_c('AppButtonToggle',{attrs:{"label":_vm.$t('app.advanced_search')},model:{value:(_vm.advancedSearchVisible),callback:function ($$v) {_vm.advancedSearchVisible=$$v},expression:"advancedSearchVisible"}})],1)]},proxy:true},{key:"advanced-search",fn:function(){return [_c('ReviewProductsAdvancedSearch',{attrs:{"visible":_vm.advancedSearchVisible},on:{"submit":_vm.searchResource},model:{value:(_vm.resourceParams),callback:function ($$v) {_vm.resourceParams=$$v},expression:"resourceParams"}})]},proxy:true}])}),_c('AppResourceTable',{attrs:{"table-id":"review-products-table","enable-refresh":"","enable-column-settings":"","resources":_vm.products,"columns":_vm.COLUMNS,"rows":_vm.rows},on:{"refresh":_vm.refreshResource,"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
var rowIndex = ref.rowIndex;
return [(column === 'code')?[_c('div',[_vm._v(_vm._s(row.code))]),(row.deleted)?_c('AppBadge',{staticClass:"mt4",attrs:{"badge-style":"red-outline","label":_vm.$t('deleted.label'),"tooltip":_vm.$t('deleted.tooltip')}}):_vm._e()]:(column === 'message_sentiment_analysis')?[(
            Object.keys(row.review_messages_sentiment_analysis_reports)
              .length === 0
          )?[_vm._v(" "+_vm._s('-')+" ")]:_vm._l(([
              _vm.ReviewSentimentType.POSITIVE,
              _vm.ReviewSentimentType.NEUTRAL,
              _vm.ReviewSentimentType.NEGATIVE
            ]),function(sentiment){return _c('AppBadge',{key:(rowIndex + "_" + sentiment),attrs:{"badge-style":row.review_messages_sentiment_analysis_reports.mode ===
              sentiment
                ? _vm.formatBadgeStyle(sentiment)
                : 'grey-outline',"label":_vm.messageSentimentAnalysisBadge(row, sentiment)}})})]:(column === 'keyword_sentiment_analysis')?[(row.review_keywords_sentiment_analysis_reports.length)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            message: row.keywordSentimentAnalysisTooltip,
            info: ("\n" + (_vm.$t('app.crema_ai_review'))),
            textAlign: 'left'
          }),expression:"{\n            message: row.keywordSentimentAnalysisTooltip,\n            info: `\\n${$t('app.crema_ai_review')}`,\n            textAlign: 'left'\n          }"}]},_vm._l((row.review_keywords_sentiment_analysis_reports),function(report){return _c('AppBadge',{key:report.key,attrs:{"badge-style":_vm.formatBadgeStyle(report.type),"label":_vm.reviewTagTypeNameMap[report.key]}})}),1):_c('div',[_vm._v(_vm._s('-'))])]:(column === 'status')?[_c('ul',{staticClass:"badges"},[(row.has_pinned_reviews)?_c('li',[_c('AppBadge',{attrs:{"badge-style":"grey-outline","label":_vm.$t('review_pinned')}})],1):_vm._e(),(row.is_combo)?_c('li',[_c('AppBadge',{attrs:{"badge-style":"grey-outline","label":_vm.$t('combo_product')}})],1):_vm._e(),(row.has_manager_reviews)?_c('li',[_c('AppBadge',{attrs:{"badge-style":"grey-outline","label":_vm.$t('manager_reviewed')}})],1):_vm._e()])]:(column === 'show_reviews')?_c('ReviewProductsShowReviewsButton',{attrs:{"product":row}}):(column === 'manage')?_c('ReviewProductsManageReviewsButton',{attrs:{"product":row},on:{"create":function($event){return _vm.managerReviewCreated(rowIndex, $event)}}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }