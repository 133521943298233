<template>
  <AppModalDefault
    :title="isConfirmableCohort ? $t('title.confirmable') : $t('title.default')"
    :sub-title="productManagement.name"
    @close="close"
  >
    <template #body>
      <!-- eslint-disable vue/no-v-html -->
      <AppInfoBox
        v-if="isConfirmableCohort"
        class="DisplayProductManagementCohortDialog__info-box"
        v-html="$t('confirm.info_html')"
      />
      <!-- eslint-enable vue/no-v-html -->
      <AppAjaxContent :is-loading="isLoading">
        <section
          v-for="sectionId in sectionIds"
          :key="sectionId"
          class="DisplayProductManagementCohortDialog__section"
        >
          <div class="DisplayProductManagementCohortDialog__label">
            {{ $t(`section.${sectionId}.title`) }}
          </div>
          <template v-if="sectionId === 'apply_at'">
            <div class="DisplayProductManagementCohortDialog__content">
              {{ formatDateTimeWithoutSecond(cohort.apply_at) }}
            </div>
          </template>
          <template v-else-if="sectionId === 'units'">
            <div class="DisplayProductManagementCohortDialog__content">
              <div
                v-for="(categoryDescription, i) in categoryDescriptions"
                :key="`category_description_${i}`"
                class="DisplayProductManagementCohortDialog__content-item"
              >
                {{ categoryDescription }}
              </div>
              <div
                v-for="(unitDescription, i) in unitDescriptions"
                :key="`unit_description_${i}`"
                class="DisplayProductManagementCohortDialog__content-item"
              >
                {{ unitDescription }}
              </div>
            </div>
          </template>
          <template v-else-if="sectionId === 'managed_products'">
            <div
              v-for="item in usingManagements"
              :key="item"
              class="DisplayProductManagementCohortDialog__content"
            >
              <div class="DisplayProductManagementCohortDialog__content-item">
                <template v-if="item === 'category'">
                  {{
                    $t('section.managed_products.category', [
                      productCategoryName(cohort.category_id)
                    ])
                  }}
                </template>
                <template v-else>
                  {{ $t(`section.managed_products.${item}`) }}
                </template>
              </div>
              <template v-if="item === 'icon'">
                <div
                  v-for="device in iconDevices"
                  :key="device"
                  class="DisplayProductManagementCohortDialog__content-item"
                >
                  {{ $t(`section.managed_products.icon_${device}`)
                  }}<AppProductIcon
                    v-for="id in cohort[`${device}_icon_ids`]"
                    :key="`${device}_icon_${id}`"
                    class="DisplayProductManagementCohortDialog__icon"
                    :icon-id="id"
                  />
                </div>
              </template>
              <div class="DisplayProductManagementCohortDialog__sub-section">
                <div
                  v-for="(managedProduct, j) in managedProducts[item]"
                  :key="`managed_product_${j}`"
                  class="DisplayProductManagementCohortDialog__sub-section-content"
                >
                  <div>{{ managedProduct.title }}</div>
                  <div>{{ managedProduct.content }}</div>
                </div>
              </div>
            </div>
          </template>
        </section>
      </AppAjaxContent>
    </template>
    <template v-if="isConfirmableCohort" #foot>
      <AppButton
        button-style="blue"
        :label="$t('app.apply')"
        @click="confirm"
      />
      <AppButton :label="$t('app.close')" @click="destroyCohort" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';
import DisplayProductManagementCohortReportCalculator from '@/mixins/DisplayProductManagementCohortReportCalculator';
import DialogSize from '@/enums/DialogSize';
import DisplayCategoryFilterType from '@/enums/DisplayCategoryFilterType';
import DisplayProductFilterType from '@/enums/DisplayProductFilterType';
import ValueCompareType from '@/enums/ValueCompareType';
import DisplayProductManagementExecuteStatus from '@/enums/DisplayProductManagementExecuteStatus';

export default {
  name: 'DisplayProductManagementCohortDialog',
  mixins: [DialogView, DisplayProductManagementCohortReportCalculator],
  props: {
    productManagementCohortId: { type: Number, required: true }
  },
  data: () => ({ cohort: {}, isLoading: true, productManagement: {} }),
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('productCategory', ['productCategoryName']),
    sectionIds() {
      return ['apply_at', 'units', 'managed_products'];
    },
    categoryDescriptions() {
      const {
        category_filter_type,
        including_category_ids,
        use_excluding_category_ids,
        excluding_category_ids
      } = this.cohort;
      if (category_filter_type === undefined) return;

      const result = [DisplayCategoryFilterType.t(category_filter_type)];
      if (category_filter_type === DisplayCategoryFilterType.INCLUDE)
        result.push(this.productCategoryNames(including_category_ids));
      if (use_excluding_category_ids)
        result.push(
          this.$t('section.units.use_excluding_category_ids', [
            this.productCategoryNames(excluding_category_ids)
          ])
        );

      return result;
    },
    unitDescriptions() {
      return (this.cohort.unit_config || []).map(unit => {
        const { recent_days, filter_type } = unit;
        const result = [
          recent_days === 0
            ? this.$t('section.units.recent_days_zero')
            : this.$t('section.units.recent_days', [recent_days]),
          DisplayProductFilterType.t(filter_type)
        ];

        if (DisplayProductFilterType.isStockFilterType(filter_type)) {
          result.push(this.$t('unit.count', [unit.stock_count]));
          result.push(ValueCompareType.t(unit.stock_compare_type));
        }

        if (filter_type === DisplayProductFilterType.SELECTED_PRODUCT_OPTION)
          result.push(unit.product_option_value);

        return result.join(' ');
      });
    },
    iconDevices() {
      if (this.currentBrand.shop_builder === 'makeshop')
        return ['pc', 'mobile'];
      else return ['pc'];
    },
    usingManagements() {
      return ['category', 'icon', 'widget'].filter(
        item => this.cohort[`use_${item}_management`]
      );
    },
    managedProducts() {
      const { cohort } = this;
      const result = {};
      this.usingManagements.forEach(item => {
        result[item] = {};
        ['added_product_codes', 'removed_product_codes'].forEach(type => {
          const productCodes = this.productCodes(cohort.report, item, type);
          result[item][type] = {
            title: this.$t(`section.managed_products.${type}`, [
              productCodes.length
            ]),
            content: productCodes.join(', ')
          };
        });
      });

      return result;
    },
    isConfirmableCohort() {
      return (
        this.cohort.execute_status ===
        DisplayProductManagementExecuteStatus.CONFIRM
      );
    }
  },
  mounted() {
    api
      .get(
        `display/product_management/cohorts/${this.productManagementCohortId}`
      )
      .then(({ data }) => {
        this.productManagement = data.product_management;
        this.cohort = data.cohort;

        if (!this.isConfirmableCohort) this.confirmed();
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('displayProductManagementCohortJobs', [
      'removeScheduledCohort'
    ]),
    confirmed() {
      this.removeScheduledCohort(this.cohort.id);
      if (this.isConfirmableCohort) this.close();
    },
    productCategoryNames(productCategoryIds) {
      return (productCategoryIds || [])
        .map(id => this.productCategoryName(id))
        .join(', ');
    },
    confirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t('confirm.title'),
          markdownText: this.$t('confirm.description_html'),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', () =>
          api
            .post(
              `display/product_management/cohorts/${this.productManagementCohortId}/confirm`,
              {},
              {
                successMessage: this.$t('confirm.toast', [
                  this.productManagement.name
                ])
              }
            )
            .then(this.confirmed)
        );
      });
    },
    destroyCohort() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('destroy_cohort.title'),
          markdownText: this.$t('destroy_cohort.description_html'),
          width: DialogSize.SMALL,
          snoozeId: 'DisplayProductManagementCohortDialog.destroy_cohort'
        }
      ])
        .then(eventBus => {
          eventBus.$on('close', this.requestDeleteCohort);
        })
        .catch(this.requestDeleteCohort);
    },
    requestDeleteCohort() {
      api
        .delete(
          `display/product_management/cohorts/${this.productManagementCohortId}`,
          {
            successMessage: this.$t('destroy_cohort.toast', [
              this.productManagement.name
            ])
          }
        )
        .then(this.confirmed);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayProductManagementCohortDialog__info-box {
  margin-bottom: 32px;
}

.DisplayProductManagementCohortDialog__section {
  & + & {
    margin-top: 32px;
  }
}

.DisplayProductManagementCohortDialog__label {
  @include text-label;
}

.DisplayProductManagementCohortDialog__content {
  margin-top: 4px;

  & + & {
    margin-top: 32px;
  }
}

.DisplayProductManagementCohortDialog__content-item {
  & + & {
    margin-top: 4px;
  }
}

.DisplayProductManagementCohortDialog__icon {
  margin-left: 4px;
}

.DisplayProductManagementCohortDialog__sub-section {
  margin-top: 4px;
}

.DisplayProductManagementCohortDialog__sub-section-content {
  margin-top: 4px;

  & + & {
    margin-top: 32px;
  }
}
</style>

<i18n locale="ko">
{
  "title": {
    "default": "관리 이력 상세",
    "confirmable": "즉시 실행 - 결과 확인 후 적용"
  },
  "section": {
    "apply_at": {
      "title": "자동관리 실행일"
    },
    "units": {
      "title": "대상 상품 선정",
      "use_excluding_category_ids": "{0}에 포함된 상품 제외",
      "recent_days": "최근 {0}일",
      "recent_days_zero": "계산 시점"
    },
    "managed_products": {
      "title": "자동 관리 항목",
      "category": "가상 카테고리 이동: {0} 카테고리로 이동",
      "icon": "상품에 아이콘 표시",
      "icon_pc": "상품 아이콘:",
      "icon_mobile": "모바일 아이콘:",
      "widget": "빠른 배송 안내 위젯 표시",
      "added_product_codes": "추가된 상품 ({0}개)",
      "removed_product_codes": "제외된 상품 ({0}개)"
    }
  },
  "confirm": {
    "title": "계산 결과 적용",
    "description_html": "즉시 진열을 통해 계산한 결과를 쇼핑몰에 반영합니다.<br />진행 시 변경사항은 되돌릴 수 없습니다.<br />진행하시겠습니까?",
    "toast": "{0}의 계산결과를 쇼핑몰에 적용중입니다.",
    "info_html": "즉시 진열 기능을 통해 계산은 완료하였으나 아직 쇼핑몰에 반영되지는 않았습니다.<br />확인 후 [적용]을 누르시면 계산 결과를 쇼핑몰에 반영합니다."
  },
  "destroy_cohort": {
    "title": "즉시 실행 적용 취소",
    "description_html": "즉시 실행을 통해 계산한 결과를 적용하지 않고 종료합니다.<br />계산 결과는 삭제됩니다.<br />진행 하시겠습니까?",
    "toast": "{0}의 즉시 실행 계산 결과를 삭제했습니다."
  }
}
</i18n>
