<template>
  <AppModalDefault
    :title="$t('title')"
    width="wide"
    :sub-title="subTitle"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <template #default>
          <AppHeading :label="$t('dispatch_result.title')" />
          <AppContainer>
            <AppDataList :data="dispatchResultData" />
          </AppContainer>
          <template v-if="showBalanceResult">
            <AppHeading :label="$t('balance_result.title')" />
            <AppContainer>
              <AppDataList :data="balanceResultData" />
            </AppContainer>
          </template>
          <template v-if="isWidget">
            <AppHeading :label="$t('settings_summary.title')" />
            <AppContainer>
              <AppDataList :data="widgetSettingsSummary" />
            </AppContainer>
          </template>
          <template v-if="isDm">
            <AppTabs v-model="activeTab" :tabs="tabs" />
            <Component :is="tableComponent" :cohort="cohort" />
          </template>
          <TargetReportsDispatchResultDialogTablePopup
            v-else-if="isPopup"
            :cohort="cohort"
          />
          <TargetReportsDispatchResultDialogTableWidget
            v-else-if="isWidget"
            :cohort="cohort"
          />
        </template>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';
import TargetDispatchMethod from '@/enums/TargetDispatchMethod';
import TargetDispatchStatus from '@/enums/TargetDispatchStatus';
import TargetMessageMedia from '@/enums/TargetMessageMedia';
import TargetWidgetRecommendationFilterType from '@/enums/TargetWidgetRecommendationFilterType';
import TargetWidgetRecommendationFilterServiceType from '@/enums/TargetWidgetRecommendationFilterServiceType';
import TargetWidgetStyle from '@/enums/TargetWidgetStyle';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'TargetReportsDispatchResultDialog',
  mixins: [DialogView],
  props: {
    cohortId: { type: Number, default: null },
    campaignId: { type: Number, default: null },
    campaignName: { type: String, required: true },
    campaignType: { type: Number, default: null },
    splited: { type: Boolean, default: false }
  },
  data: () => ({ isLoading: true, activeTab: 'all', cohort: null }),
  computed: {
    TargetDispatchMethod: () => TargetDispatchMethod,
    isDm() {
      return this.campaignMediaType === TargetCampaignMediaType.DIRECT_MESSAGE;
    },
    isPopup() {
      return this.campaignMediaType === TargetCampaignMediaType.POPUP;
    },
    isWidget() {
      return this.campaignMediaType === TargetCampaignMediaType.WIDGET;
    },
    tabs() {
      return [
        { id: 'all', label: this.$t('app.all') },
        ...this.cohort.dispatch_methods.map(id => ({
          id,
          label: TargetDispatchMethod.t(id)
        }))
      ];
    },
    subTitle() {
      const cohortIdString = this.cohortId
        ? this.$t('cohort_id', [this.cohortId.toString()])
        : '';
      return this.$t('sub_title', [this.campaignName]) + cohortIdString;
    },
    showBalanceResult() {
      return this.isDm;
    },
    dispatchResultCountsData() {
      if (this.isDm) {
        const {
          total_count,
          total_count_by_dispatch_method,
          success_count,
          success_count_by_dispatch_method,
          failure_count,
          failure_count_by_dispatch_method
        } = this.cohort;

        return [
          {
            label: this.$t('dispatch_result.total_count'),
            value: this.dispatchCount(
              total_count,
              total_count_by_dispatch_method
            )
          },
          {
            label: this.$t('dispatch_result.success_count'),
            value: this.dispatchCount(
              success_count,
              success_count_by_dispatch_method
            )
          },
          {
            label: this.$t('dispatch_result.failure_count'),
            value: this.dispatchCount(
              failure_count,
              failure_count_by_dispatch_method
            )
          }
        ];
      } else
        return [
          {
            label: this.$t('dispatch_result.checks_count'),
            value: this.cohort.checks_count
          }
        ];
    },
    dispatchResultData() {
      const status =
        TargetCampaignType.isRealtime(this.campaignType) &&
        this.cohort.dispatch_status == TargetDispatchStatus.DISPATCHED
          ? this.$t('dispatch_result.realtime_dispatched')
          : TargetDispatchStatus.t(this.cohort.dispatch_status);
      return [
        ...this.dispatchResultCountsData,
        {
          label: this.$t('dispatch_result.dispatch_status'),
          value: this.splited
            ? this.$t('dispatch_result.splited_dispatch', [status])
            : status
        },
        ...this.bizMessageTemplateCode
      ];
    },
    bizMessageTemplateCode() {
      const { dispatch_methods, biz_message_template_code } = this.cohort;
      if (
        !this.isDm ||
        !dispatch_methods.includes(TargetDispatchMethod.BIZ_MESSAGE)
      )
        return [];

      return [
        {
          label: this.$t('dispatch_result.biz_message_template_code'),
          value: biz_message_template_code
        }
      ];
    },
    dispatchPrice() {
      return _.chain(this.cohort.price_by_dispatch_method)
        .map((v, k) => {
          const dispatch_method = parseInt(k);
          if (dispatch_method === TargetDispatchMethod.SMS) {
            const messageMedia = this.cohort.message_type_lms
              ? TargetMessageMedia.LMS
              : TargetMessageMedia.SMS;
            return `${TargetDispatchMethod.t(
              dispatch_method
            )}(${TargetMessageMedia.t(messageMedia)}): ${v.toLocaleString()}`;
          } else {
            return `${TargetDispatchMethod.t(
              parseInt(k)
            )}: ${v.toLocaleString()}`;
          }
        })
        .join(' / ')
        .value();
    },
    usedPointByDispatchMethod() {
      return this.cohort.dispatch_methods.reduce((obj, dispatch_method) => {
        return {
          ...obj,
          [dispatch_method]: Math.min(
            this.cohort.ad_spending_by_dispatch_method[dispatch_method],
            this.cohort.used_point
          )
        };
      }, {});
    },
    usedCashByDispatchMethod() {
      return this.cohort.dispatch_methods.reduce((obj, dispatch_method) => {
        return {
          ...obj,
          [dispatch_method]:
            this.cohort.ad_spending_by_dispatch_method[dispatch_method] -
            this.usedPointByDispatchMethod[dispatch_method]
        };
      }, {});
    },
    balanceResultData() {
      return [
        {
          label: this.$t('balance_result.dispatch_price'),
          value: this.dispatchPrice
        },
        {
          label: this.$t('balance_result.used_point'),
          value: this.usedMoneyString(this.usedPointByDispatchMethod)
        },
        {
          label: this.$t('balance_result.point_balance'),
          value: this.cohort.point_balance,
          type: 'number'
        },
        {
          label: this.$t('balance_result.used_cash'),
          value: this.usedMoneyString(this.usedCashByDispatchMethod)
        },
        {
          label: this.$t('balance_result.cash_balance'),
          value: this.cohort.credit_balance,
          type: 'number'
        }
      ];
    },
    widgetSettingsSummary() {
      return [
        {
          label: this.$t('settings_summary.recommendation_unit'),
          value: this.recommendationUnits
        },
        {
          label: this.$t('settings_summary.pc_widget_design'),
          value: this.widgetDesign('pc')
        },
        {
          label: this.$t('settings_summary.mobile_widget_design'),
          value: this.widgetDesign('mobile')
        }
      ].map(summary => ({ ...summary, type: 'pre' }));
    },
    tableComponent() {
      switch (this.activeTab) {
        case 'all':
          return 'TargetReportsDispatchResultDialogTableAll';
        case TargetDispatchMethod.SMS:
          return 'TargetReportsDispatchResultDialogTableSms';
        case TargetDispatchMethod.EMAIL:
          return 'TargetReportsDispatchResultDialogTableEmail';
        case TargetDispatchMethod.BIZ_MESSAGE:
          return 'TargetReportsDispatchResultDialogTableBizMessage';
        default:
          return null;
      }
    },
    campaignMediaType() {
      return this.cohort.campaign_media_type;
    },
    recommendationUnits() {
      const { recommendation_units } = this.cohort;
      return recommendation_units
        .map((unit, i) => {
          let filterTitle = `${i + 1}. ${TargetWidgetRecommendationFilterType.t(
            unit.recommendation_filter_type
          )}`;
          if (!unit.enabled) {
            const serviceType = TargetWidgetRecommendationFilterServiceType.key(
              unit.disabled_service_type
            );
            if (serviceType) {
              filterTitle += ` - ${this.$t(
                `dispatch_result.disabled.${serviceType}`
              )}`;
            }
          }
          return filterTitle;
        })
        .join('\n');
    }
  },
  mounted() {
    const url = this.cohortId
      ? `/target/cohorts/${this.cohortId}`
      : `/target/campaigns/${this.campaignId}/cohort`;
    api
      .get(url)
      .then(({ data }) => (this.cohort = data.cohort))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    dispatchCount(count, countByDispatchMethod) {
      const detail = this.countByDispatchMethods(countByDispatchMethod);
      return `${count.toLocaleString()} (${detail})`;
    },
    countByDispatchMethods(countByDispatchMethod) {
      return _.chain(countByDispatchMethod)
        .map(
          (v, k) =>
            `${TargetDispatchMethod.t(parseInt(k))} ${v.toLocaleString()}`
        )
        .join(' / ')
        .value();
    },
    usedMoneyString(pricesByDispatchMethod) {
      return this.cohort.dispatch_methods
        .map(
          dispatch_method =>
            `${TargetDispatchMethod.t(
              dispatch_method
            )} ${pricesByDispatchMethod[dispatch_method].toLocaleString()}`
        )
        .join(' / ');
    },
    widgetDesign(deviceType) {
      const widgetStyle = this.cohort[`${deviceType}_widget_style`];

      const widgetStyleDescription = this.settingsSummaryDescription(
        'widget_style',
        TargetWidgetStyle.t(widgetStyle)
      );
      const productsCountPerRowDescription = this.settingsSummaryDescription(
        'products_count_per_row',
        this.cohort[`${deviceType}_products_count_per_row`]
      );

      if (
        deviceType === 'mobile' &&
        widgetStyle === TargetWidgetStyle.HORIZONTAL_SCROLL
      )
        return [
          widgetStyleDescription,
          this.settingsSummaryDescription(
            'horizontal_scroll_multiple_rows',
            this.cohort['mobile_horizontal_scroll_multiple_rows'] ? 2 : 1
          ),
          productsCountPerRowDescription
        ].join('\n');
      else
        return [widgetStyleDescription, productsCountPerRowDescription].join(
          '\n'
        );
    },
    settingsSummaryDescription(key, value) {
      return this.$t(`settings_summary.${key}`, [value]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "상세 내역",
  "sub_title": "{0}",
  "cohort_id": " (발송 ID {0})",
  "dispatch_result": {
    "title": "발송 결과",
    "total_count": "전체 발송수",
    "success_count": "성공 발송수",
    "failure_count": "실패 발송수",
    "checks_count": "노출수",
    "dispatch_status": "상태",
    "splited_dispatch": "분할발송 - {0}",
    "biz_message_template_code": "알림톡 템플릿 코드",
    "realtime_dispatched": "발송중",
    "disabled": {
      "FIT": "핏 서비스 사용 종료로 인해 해당 항목은 노출 중단됩니다.",
      "REVIEW": "리뷰 서비스 사용 종료로 인해 해당 항목은 노출 중단됩니다.",
      "REVIEW_ANALYSIS": "[AI 리뷰 분석] 설정 적용 종료로 인해 해당 항목은 노출 중단됩니다."
    }
  },
  "balance_result": {
    "title": "정산 결과",
    "dispatch_price": "발송 단가",
    "used_point": "차감 포인트",
    "point_balance": "잔여 포인트",
    "used_cash": "차감 캐시",
    "cash_balance": "잔여 캐시"
  },
  "settings_summary": {
    "title": "발송 시 설정 요약",
    "recommendation_unit": "추천 상품 설정",
    "pc_widget_design": "PC 위젯 디자인",
    "mobile_widget_design": "모바일 위젯 디자인",
    "widget_style": "위젯 스타일 : {0}",
    "horizontal_scroll_multiple_rows": "가로 스크롤 줄 개수 : {0}",
    "products_count_per_row": "한 줄에 표시할 상품 수 : {0}"
  }
}
</i18n>
