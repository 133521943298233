<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppSelect id="basic" v-model="valueBasic" :options="OPTIONS_BASIC" />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}, Type: {{ typeof valueBasic }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="long">Long Option Name</label>
      </div>
      <AppSelect id="long" v-model="valueLong" :options="OPTIONS_LONG" />
      <div class="ui-section__description">Selected Value: {{ valueLong }}</div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="placeholder">Placeholder</label>
      </div>
      <AppSelect
        id="placeholder"
        v-model="valuePlaceholder"
        placeholder="Please Select"
        :options="OPTIONS_BASIC"
      />
      <div class="ui-section__description">
        Selected Value: {{ valuePlaceholder }}, Type:
        {{ typeof valuePlaceholder }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppSelect
        id="disabled"
        v-model="valueDisabled"
        disabled
        :options="OPTIONS_BASIC"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueDisabled }}, Type: {{ typeof valueDisabled }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Invalid</label>
      </div>
      <AppSelect
        id="disabled"
        v-model="valueInvalid"
        placeholder="Please Select"
        invalid
        :options="OPTIONS_BASIC"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}, Type: {{ typeof valueInvalid }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="string">Number Value</label>
      </div>
      <AppSelect id="string" v-model="valueNumber" :options="OPTIONS_NUMER" />
      <div class="ui-section__description">
        Selected Value: {{ valueNumber }}, Type: {{ typeof valueNumber }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="string">Number Value with Placeholder</label>
      </div>
      <AppSelect
        id="string"
        v-model="valueNumberPlaceholder"
        placeholder="Please Select"
        :options="OPTIONS_NUMER"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueNumberPlaceholder }}, Type:
        {{ typeof valueNumberPlaceholder }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="string">String Value</label>
      </div>
      <AppSelect id="string" v-model="valueString" :options="OPTIONS_STRING" />
      <div class="ui-section__description">
        Selected Value: {{ valueString }}, Type: {{ typeof valueString }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="string">String Value with Placeholder</label>
      </div>
      <AppSelect
        id="string"
        v-model="valueStringPlaceholder"
        placeholder="Please Select"
        :options="OPTIONS_NUMER"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueStringPlaceholder }}, Type:
        {{ typeof valueStringPlaceholder }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="boolean">Boolean Value</label>
      </div>
      <AppSelect
        id="boolean"
        v-model="valueBoolean"
        :options="OPTIONS_BOOLEAN"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueBoolean }}, Type: {{ typeof valueBoolean }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="string">Boolean Value with Placeholder</label>
      </div>
      <AppSelect
        id="string"
        v-model="valueBooleanPlaceholder"
        placeholder="Please Select"
        :options="OPTIONS_BOOLEAN"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueBooleanPlaceholder }}, Type:
        {{ typeof valueBooleanPlaceholder }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="small">Small</label>
      </div>
      <AppSelect
        id="small"
        v-model="valueSmall"
        size="small"
        :options="OPTIONS_BASIC"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueSmall }}, Type: {{ typeof valueSmall }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiSelect',
  data() {
    return {
      OPTIONS_BASIC: [
        { label: '테스트', value: 1 },
        { label: 'ㅌㅅㅌ', value: 2 },
        { label: 'test', value: 3 },
        { label: 'tes', value: 4 },
        { label: '우왕', value: 5 },
        { label: '왕우', value: 6 },
        { label: '우웅', value: 7, disabled: true },
        { label: '그만', value: 8 }
      ],
      OPTIONS_LONG: [
        { label: '테스트', value: 1 },
        { label: 'ㅌㅅㅌ', value: 2 },
        { label: 'test', value: 3 },
        { label: 'tes', value: 4 },
        { label: '우왕', value: 5 },
        { label: '왕우', value: 6 },
        { label: '우웅', value: 7, disabled: true },
        { label: '그만', value: 8 },
        {
          label:
            'a very very very very very very very very very very very very very very long label',
          value: 9
        }
      ],
      OPTIONS_NUMER: [
        { label: 'Option 1', value: 1 },
        { label: 'Option 2', value: 2 }
      ],
      OPTIONS_STRING: [
        { label: 'Korean', value: 'ko' },
        { label: 'English', value: 'en' }
      ],
      OPTIONS_BOOLEAN: [
        { label: '선택', value: true },
        { label: '해제', value: false }
      ],
      valueBasic: 1,
      valueLong: 2,
      valuePlaceholder: null,
      valueDisabled: 1,
      valueInvalid: null,
      valueNumber: 1,
      valueNumberPlaceholder: null,
      valueString: 'ko',
      valueStringPlaceholder: null,
      valueBoolean: true,
      valueBooleanPlaceholder: null,
      valueSmall: 1
    };
  }
};
</script>
