<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppMultipleSelectCheckbox
        id="basic"
        v-model="valueBasic"
        :options="OPTIONS_BASIC"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="string-value">String Value</label>
      </div>
      <AppMultipleSelectCheckbox
        id="string-value"
        v-model="valueString"
        :options="OPTIONS_STRING"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueString }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="stringify">Stringify</label>
      </div>
      <AppMultipleSelectCheckbox
        id="stringify"
        v-model="valueStringify"
        :options="OPTIONS_BASIC"
        packing-method="string"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueStringify }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppMultipleSelectCheckbox
        id="invalid"
        v-model="valueInvalid"
        :options="OPTIONS_BASIC"
        invalid
      />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="sub-item">SubItem</label>
      </div>
      <AppMultipleSelectCheckbox
        id="sub-item"
        v-model="valueSubItem"
        name="sub_item"
        :options="OPTIONS_BASIC"
      >
        <template #sub-item="{ value, checked }">
          <AppTextInput
            v-if="value === 1"
            :disabled="!checked"
            :placeholder="`SubItem for ${value}`"
          />
          <AppTextInput
            v-else-if="value === 2"
            :disabled="!checked"
            :placeholder="`SubItem for ${value}`"
          />
        </template>
      </AppMultipleSelectCheckbox>
      <div class="ui-section__description">
        Selected Value: {{ valueSubItem }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="bit-flag">Bit Flag</label>
      </div>
      <AppMultipleSelectCheckbox
        id="bit-flag"
        v-model="valueBitFlag"
        name="bit_flag"
        :options="OPTIONS_BIT_FLAG"
        packing-method="bit_flag"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueBitFlag }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="bit-flag">Bit Flag With With Checkbox To Select All</label>
      </div>
      <AppMultipleSelectCheckbox
        id="bit-flag-with-checkbox-to-select-all"
        v-model="valueBitFlagWithCheckboxToSelectAll"
        name="bit_flag_with_checkbox_to_select_all"
        :options="OPTIONS_BIT_FLAG_WITH_CHECKBOX_TO_SELECT_ALL"
        packing-method="bit_flag"
        :enable-select-all="true"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueBitFlagWithCheckboxToSelectAll }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="indent">SelectAll with indent and customized label</label>
      </div>
      <AppMultipleSelectCheckbox
        id="indent"
        v-model="valueIndent"
        :options="OPTIONS_INDENT"
        :enable-select-all="true"
        :enable-indent="true"
        select-all-label="전체 선택"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueIndent }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="indent">SelectAll with nested checkboxes</label>
      </div>
      <AppMultipleSelectCheckbox
        id="nested"
        v-model="valueNested"
        :options="OPTIONS_NESTED"
        :enable-select-all="true"
        :enable-indent="true"
        select-all-label="전체 선택"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueNested }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Inline</label>
      </div>
      <AppMultipleSelectCheckbox
        v-model="valueBasic"
        :options="OPTIONS_BASIC"
        :inline="true"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiMultipleSelectCheckbox',
  data() {
    return {
      OPTIONS_BASIC: [
        { value: 1, label: 'Ant-Man' },
        { value: 2, label: 'Black Panther' },
        { value: 3, label: '블랙 위도우' },
        { value: 4, label: '캡틴 아메리카' },
        { value: 5, label: 'Captain Marvel' },
        { value: 6, label: 'Doctor Strange' },
        { value: 8, label: 'Falcon', disabled: true },
        {
          value: 9,
          label:
            'Guardians of the Galaxy - Drax the Destroyer, Gamora, Groot, Star-Lord, Rocket'
        },
        { value: 11, label: '호크아이' },
        { value: 12, label: '헐크' },
        { value: 13, label: '아이언맨' },
        { value: 16, label: 'Scarlet Witch' },
        { value: 17, label: 'Spider-Man' },
        { value: 19, label: '토르' },
        { value: 20, label: 'Vision' },
        { value: 21, label: 'War Machine' },
        { value: 22, label: 'Wasp' },
        { value: 23, label: 'Winter Soldier' }
      ],
      OPTIONS_STRING: [
        { value: 'Ant-Man', label: 'Ant-Man' },
        { value: 'Black Panther', label: 'Black Panther' },
        { value: '블랙 위도우', label: '블랙 위도우' },
        { value: '캡틴 아메리카', label: '캡틴 아메리카' },
        { value: 'Captain Marvel', label: 'Captain Marvel' },
        { value: 'Doctor Strange', label: 'Doctor Strange' },
        { value: 'Falcon', label: 'Falcon', disabled: true },
        {
          value:
            'Guardians of the Galaxy - Drax the Destroyer, Gamora, Groot, Star-Lord, Rocket',
          label:
            'Guardians of the Galaxy - Drax the Destroyer, Gamora, Groot, Star-Lord, Rocket'
        },
        { value: '호크아이', label: '호크아이' },
        { value: '헐크', label: '헐크' },
        { value: '아이언맨', label: '아이언맨' },
        { value: 'Scarlet Witch', label: 'Scarlet Witch' },
        { value: 'Spider-Man', label: 'Spider-Man' },
        { value: '토르', label: '토르' },
        { value: 'Vision', label: 'Vision' },
        { value: 'War Machine', label: 'War Machine' },
        { value: 'Wasp', label: 'Wasp' },
        { value: 'Winter Soldier', label: 'Winter Soldier' }
      ],
      OPTIONS_BIT_FLAG: [
        { value: 1, label: 'Ant-Man' },
        { value: 2, label: 'Black Panther' },
        { value: 4, label: '캡틴 아메리카' },
        { value: 8, label: 'Falcon', disabled: true },
        { value: 16, label: 'Scarlet Witch' }
      ],
      OPTIONS_BIT_FLAG_WITH_CHECKBOX_TO_SELECT_ALL: [
        { value: 1, label: 'Ant-Man' },
        { value: 2, label: 'Black Panther' },
        { value: 4, label: '캡틴 아메리카' },
        { value: 8, label: 'Scarlet Witch' }
      ],
      OPTIONS_INDENT: [
        { value: 1, label: 'Ant-Man' },
        { value: 2, label: 'Black Panther' },
        { value: 3, label: '블랙 위도우' },
        { value: 4, label: '캡틴 아메리카' },
        { value: 5, label: 'Captain Marvel' }
      ],
      OPTIONS_NESTED: [
        { value: 1, label: '아이언맨' },
        { value: 2, label: 'Doctor Strange' },
        { value: 3, label: '블랙 위도우' },
        { value: 4, label: '캡틴 아메리카' },
        {
          value: null,
          label: '초록 괴물',
          options: [
            { value: 5, label: '헐크' },
            { value: 6, label: '브루스 배너' },
            {
              value: null,
              label: '이름',
              options: [
                { value: 7, label: '마크' },
                { value: 8, label: '러팔로' }
              ]
            }
          ]
        },
        {
          value: null,
          label: '캡틴 아메리카',
          options: [
            { value: 9, label: '어벤져스' },
            { value: 10, label: '하이드라' }
          ]
        }
      ],
      valueBasic: [4, 13],
      valueString: ['캡틴 아메리카', '아이언맨'],
      valueStringify: '4,13',
      valueInvalid: [],
      valueSubItem: [4, 13],
      valueBitFlag: 10,
      valueBitFlagWithCheckboxToSelectAll: 15,
      valueIndent: [1, 3],
      valueNested: [1, 2, 7, 10]
    };
  }
};
</script>
