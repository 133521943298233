<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic</label>
      </div>
      <div id="basic" class="ui_section__item">
        <AppGrid>
          <div class="AppGrid__col AppGrid__col--8">
            <div class="desktop">desktop (8 of 12)</div>
            <div class="tablet">tablet (12 of 12)</div>
            <div class="mobile">mobile (12 of 12)</div>
          </div>
          <div class="AppGrid__col AppGrid__col--4">
            <div class="desktop">desktop (4 of 12)</div>
            <div class="tablet">tablet (12 of 12)</div>
            <div class="mobile">mobile (12 of 12)</div>
          </div>
        </AppGrid>
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="wide">AppGrid[gutter-width=wide]</label>
      </div>
      <div id="wide" class="ui_section__item">
        <AppGrid gutter-width="wide">
          <div class="AppGrid__col AppGrid__col--8">
            <div class="desktop">desktop (8 of 12)</div>
            <div class="tablet">tablet (12 of 12)</div>
            <div class="mobile">mobile (12 of 12)</div>
          </div>
          <div class="AppGrid__col AppGrid__col--4">
            <div class="desktop">desktop (4 of 12)</div>
            <div class="tablet">tablet (12 of 12)</div>
            <div class="mobile">mobile (12 of 12)</div>
          </div>
        </AppGrid>
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="custom">Custom</label>
      </div>
      <div id="custom" class="ui_section__item">
        <div class="container">
          <div class="row">
            <div class="col col--1-1">
              <div class="desktop">desktop (8 of 12)</div>
              <div class="tablet">tablet (12 of 12)</div>
              <div class="mobile">mobile (12 of 12)</div>
            </div>
            <div class="col col--1-2">
              <div class="desktop">desktop (4 of 12)</div>
              <div class="tablet">tablet (12 of 12)</div>
              <div class="mobile">mobile (12 of 12)</div>
            </div>
          </div>

          <div class="row">
            <div class="col col--2-1">
              <div class="desktop">desktop (2 of 12)</div>
              <div class="tablet">tablet (6 of 12)</div>
              <div class="mobile">mobile (12 of 12)</div>
            </div>
            <div class="col col--2-2">
              <div class="desktop">desktop (2 of 12)</div>
              <div class="tablet">tablet (6 of 12)</div>
              <div class="mobile">mobile (12 of 12)</div>
            </div>
            <div class="col col--2-3">
              <div class="desktop">desktop (2 of 12)</div>
              <div class="tablet">tablet (4 of 12)</div>
              <div class="mobile">mobile (4 of 12)</div>
            </div>
            <div class="col col--2-4">
              <div class="desktop">desktop (6 of 12)</div>
              <div class="tablet">tablet (8 of 12)</div>
              <div class="mobile">mobile (8 of 12)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiGrid'
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_grid.scss';

.AppGrid__col div {
  background-color: #eee;
}

.container {
  margin-top: 50px;
  @include make-container();
}
.row {
  @include make-row();
}
.col {
  @include make-col-ready();
  line-height: 24px;
  div {
    background-color: #eee;
  }
}
.col {
  &--1-1 {
    // desktop (8 of 12), tablet (12 of 12)
    @include make-col(8);
    @include media-breakpoint-each(tablet, mobile) {
      @include make-col(12);
    }
  }
  &--1-2 {
    // desktop (4 of 12), tablet (12 of 12)
    @include make-col(4);
    @include media-breakpoint-each(tablet, mobile) {
      @include make-col(12);
    }
  }
  &--2-1 {
    // desktop (2 of 12), tablet (6 of 12), mobile (12 of 12)
    @include make-col(2);
    @include media-breakpoint-each(tablet) {
      @include make-col(6);
    }
    @include media-breakpoint-each(mobile) {
      @include make-col(12);
    }
  }
  &--2-2 {
    // desktop (2 of 12), tablet (6 of 12), mobile (12 of 12)
    @include make-col(2);
    @include media-breakpoint-each(tablet) {
      @include make-col(6);
    }
    @include media-breakpoint-each(mobile) {
      @include make-col(12);
    }
  }
  &--2-3 {
    // desktop (2 of 12), tablet (4 of 12), mobile (4 of 12)
    @include make-col(2);
    @include media-breakpoint-each(tablet) {
      @include make-col(4);
    }
    @include media-breakpoint-each(mobile) {
      @include make-col(4);
    }
  }
  &--2-4 {
    // desktop (6 of 12), tablet (8 of 12), mobile (8 of 12)
    @include make-col(6);
    @include media-breakpoint-each(tablet) {
      @include make-col(8);
    }
    @include media-breakpoint-each(mobile) {
      @include make-col(8);
    }
  }
}

.desktop,
.tablet,
.mobile {
  opacity: 0.5;
}
@include media-breakpoint-each(desktop) {
  .desktop {
    opacity: 1;
  }
}
@include media-breakpoint-each(tablet) {
  .tablet {
    opacity: 1;
  }
}
@include media-breakpoint-each(mobile) {
  .mobile {
    opacity: 1;
  }
}
</style>
