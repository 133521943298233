<template>
  <AnalyticsInsightNoticeView @normalize="refresh">
    <AnalyticsInsightAjaxContent :is-loading="isLoading">
      <AnalyticsInsightMainSearchbar
        v-bind="searchBarProps"
        v-on="searchBarEvents"
      />
      <AnalyticsInsightInfoBox v-bind="{ categoriesCount, keywordsCount }" />
      <AnalyticsInsightDashboardMetrics
        v-bind="{
          dateRange,
          reviewsCountArray,
          avgReviewTagsCountArray,
          reviewTagsCountArray,
          mediaReviewsRatioArray
        }"
      />
      <AnalyticsInsightDashboardKeywords
        v-bind="{ dateRange, myBrandKeywordsDetails, brandsKeywordsDetails }"
      />
      <AnalyticsInsightDashboardProducts
        v-bind="{
          dateRange,
          popularProducts,
          riskyProducts,
          potentialProducts
        }"
      />
    </AnalyticsInsightAjaxContent>
  </AnalyticsInsightNoticeView>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import InsightProductsFilterView from '@/mixins/InsightProductsFilterView';
import InsightCompetingGroupView from '@/mixins/InsightCompetingGroupView';

export default {
  name: 'AnalyticsInsightDashboard',
  mixins: [InsightProductsFilterView, InsightCompetingGroupView],
  data() {
    return {
      isLoading: true,
      reviewsCountArray: [],
      avgReviewTagsCountArray: [],
      reviewTagsCountArray: [],
      mediaReviewsRatioArray: [],
      myBrandKeywordsDetails: [],
      brandsKeywordsDetails: [],
      popularProducts: [],
      riskyProducts: [],
      potentialProducts: []
    };
  },
  computed: {
    defaultResourceParams() {
      return this.pickByKeys({
        ...this.defaultResourceCommonParams,
        products_popular_min: this.minReviewsCountPreset('popular'),
        products_popular_max: this.maxReviewsCountPreset('popular'),
        products_risky_min: this.minReviewsCountPreset('risky'),
        products_risky_max: this.maxReviewsCountPreset('risky'),
        products_potential_min: this.minReviewsCountPreset('potential'),
        products_potential_max: this.maxReviewsCountPreset('potential')
      });
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/analytics/dashboard', {
          params: _.pickBy(params, v => v !== 'all')
        })
        .then(({ data }) => {
          this.SET_FILTERED_BRANDS_COUNT(data.filtered_brands_count);
          this.keywordsCount = data.total_keywords_count;

          this.reviewsCountArray = data.reviews_count_array;
          this.avgReviewTagsCountArray = data.avg_review_tags_count_array;
          this.reviewTagsCountArray = data.review_tags_count_array;
          this.mediaReviewsRatioArray = data.media_reviews_ratio_array;

          this.popularProducts = data.popular_products;
          this.riskyProducts = data.risky_products;
          this.potentialProducts = data.potential_products;

          this.myBrandKeywordsDetails = data.my_brand_keywords_details;
          this.brandsKeywordsDetails = data.brands_keywords_details;
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>
