<template>
  <AppTabContent
    menu-item-id="review_smart_installation_widget_management"
    :params="tabParams"
  >
    <AppAjaxContent
      :is-loading="isLoading"
      class="ReviewSmartInstallationWidgetManagement"
    >
      <AppSearchbar>
        <li>
          <AppButton
            :label="$t('new_smart_installation')"
            button-style="red"
            @click="newSmartInstallationClicked"
          />
        </li>
        <template #right>
          <li>
            <AppButtonToggle
              v-model="advancedSearchVisible"
              :label="$t('app.advanced_search')"
            />
          </li>
        </template>
        <template #advanced-search>
          <AppAdvancedSearch
            v-model="resourceParams"
            :visible="advancedSearchVisible"
            :sections="advancedSearchSections"
            @submit="searchResource"
          />
        </template>
      </AppSearchbar>
      <AppResourceTable
        table-id="review-widget-smart-installations-table"
        :resources="resources"
        :columns="columns"
        :rows="rows"
        :batch-buttons="batchButtons"
        :custom-buttons="[sortButton]"
        :event-bus="tableEventBus"
        @select-rows="selectRows"
        @paginate="pagination = $event"
      >
        <template #cell="{ row, column }">
          <div v-if="column === 'feature'">
            <AppButtonDraggable
              v-if="sortButton.value === 'widget_exposure_order'"
              :drag-text="row.title"
              :tooltip="
                buttonDisabled(row)
                  ? $t('tooltip.sort_disabled')
                  : $t('tooltip.sort')
              "
              :disabled="buttonDisabled(row)"
              @sort="sort"
            />
            <AppDropdownMenuButton
              :label="$t('app.manage')"
              :menu-items="installationManageDropdownMenuItems(row)"
            />
          </div>
        </template>
      </AppResourceTable>
    </AppAjaxContent>
  </AppTabContent>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import api from '@/lib/api';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import DialogSize from '@/enums/DialogSize';
import { arrayMoveIndex } from '@/lib/array';
import { trimParams } from '@/lib/params';
import WidgetPageType from '@/enums/WidgetPageType';
import WidgetInsertType from '@/enums/WidgetInsertType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import WidgetStyle from '@/enums/WidgetStyle';
import WidgetType from '@/enums/WidgetType';
import ResourceView from '@/mixins/ResourceView';
import SmartInstallationHelper from '@/mixins/SmartInstallationHelper';

export default {
  name: 'ReviewSmartInstallationWidgetManagement',
  mixins: [ResourceView, SmartInstallationHelper],
  data() {
    return {
      isLoading: true,
      isNewSmartInstallationCreated: false,
      tableEventBus: null,
      selectedInstallations: [],
      pagination: { page: 1, per: 20 },
      advancedSearchVisible: ['search_device_type', 'search_skin_id'].some(
        key => key in this.$route.query
      ),
      sortButton: {
        icon: 'icon-sort',
        controlType: 'select',
        options: ['widget_exposure_order', 'skin_id_desc'].map(value => ({
          label: this.$t(`button.sort.${value}`),
          value
        })),
        value: 'widget_exposure_order'
      }
    };
  },
  computed: {
    ...mapState('session', ['currentBrand', 'reviewSettings']),
    ...mapGetters('reviewWidgets', [
      'reviewWidget',
      'reviewWidgetSmartInstallation',
      'reviewWidgetSmartInstallations'
    ]),
    ...mapGetters('session', [
      'isSmartInstallationSupported',
      'isCurrentUserInstallationAgency'
    ]),
    ...mapGetters(['isSuperAdminAccessible']),
    defaultResourceParams: () => ({
      tab: ''
    }),
    tabParams() {
      return trimParams(
        _.omit(this.resourceParams, 'tab'),
        this.defaultResourceParams
      );
    },
    resources() {
      const { per, page } = this.pagination;

      const indexStart = per * (page - 1);
      const indexEnd = per * page;
      const sorted_installations = _.sortBy(
        this.reviewWidgetSmartInstallations,
        this.sortRules
      );

      return {
        ...this.pagination,
        total_count: this.reviewWidgetSmartInstallations.length,
        items: sorted_installations.slice(indexStart, indexEnd)
      };
    },
    batchButtons() {
      return [
        {
          label: this.$t('button.batch.change_skin_id'),
          clickHandler: () =>
            this.changeSkinIdClicked(this.selectedInstallations.map(i => i.id))
        },
        {
          label: this.$t('button.batch.duplicate'),
          clickHandler: () =>
            this.duplicateInstallationsClicked(
              this.selectedInstallations.map(i => i.id)
            )
        },
        {
          label: this.$t('button.batch.delete'),
          clickHandler: () =>
            this.deleteInstallationsClicked(
              this.selectedInstallations.map(i => i.id)
            )
        }
      ];
    },
    columns() {
      return [
        { id: 'row_select', type: 'row_select' },
        {
          id: 'id',
          label: this.$t('table_head.id')
        },
        {
          id: 'widget_id',
          label: this.$t('table_head.widget_id'),
          hideByDefault: true
        },
        {
          id: 'device_type',
          label: this.$t('table_head.device_type')
        },
        {
          id: 'page_type',
          label: this.$t('table_head.page_type')
        },
        {
          id: 'skin_id',
          label: this.$t('table_head.skin_id')
        },
        {
          id: 'position',
          label: this.$t('table_head.position'),
          align: 'left'
        },
        {
          id: 'widget_info',
          label: this.$t('table_head.widget_info'),
          type: 'html',
          align: 'left'
        },
        this.sortButton.value === 'widget_exposure_order'
          ? {
              id: 'display_order',
              label: this.$t('table_head.display_order'),
              tooltip: this.$t('tooltip.display_order'),
              default: '-'
            }
          : null,
        {
          id: 'feature',
          label: this.$t('table_head.feature')
        }
      ].filter(c => c);
    },
    rows() {
      return this.resources.items.map(installation => {
        const widget = this.reviewWidget(installation.widget_id);
        return {
          id: installation.id,
          widget_id: installation.widget_id,
          device_type: WidgetDeviceType.t(widget.widget_device_type),
          page_type: this.getPageTypeOrPath(installation),
          skin_id: this.formatSkinId(installation.skin_id),
          position: `${installation.element_selector} ${WidgetInsertType.key(
            installation.install_position
          )}`,
          widget_info: this.widgetInfoString(widget),
          display_order: this.hasInstallationsWithSamePositions(installation)
            ? installation.install_order
            : null
        };
      });
    },
    sortRules() {
      return this.sortButton.value === 'widget_exposure_order'
        ? this.widgetDisplayOrder()
        : this.skinIdDesc();
    },
    advancedSearchSections() {
      return [
        {
          groups: [
            {
              id: 'search_device_type',
              label: this.$t('search.device_type'),
              type: 'hash_select_button',
              selectButtons: [
                {
                  id: 'search_device_type',
                  options: [
                    {
                      label: this.$t('search.pc'),
                      value: WidgetDeviceType.PC
                    },
                    {
                      label: this.$t('search.mobile'),
                      value: WidgetDeviceType.MOBILE
                    }
                  ]
                }
              ]
            },
            {
              id: 'search_skin_id',
              label: this.$t('search.skin_id'),
              type: 'text',
              placeholder: this.$t('search.skin_id_placeholder'),
              groupTooltip: this.$t('tooltip.search_skin_id')
            }
          ]
        }
      ];
    }
  },
  created() {
    this.tableEventBus = new Vue();
  },
  mounted() {
    this.isLoading = true;
    this.fetchSmartInstallations()
      .then(() => (this.isLoading = false))
      .catch(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    ...mapActions('asyncJob', ['enqueueJob']),
    ...mapMutations('reviewWidgets', [
      'SET_REVIEW_WIDGETS',
      'UPDATE_REVIEW_WIDGET_SMART_INSTALLATION',
      'DELETE_REVIEW_WIDGET_SMART_INSTALLATION'
    ]),
    selectRows(rows) {
      this.selectedInstallations = rows;
    },
    fetchResource(params) {
      this.isLoading = true;
      this.$set(this.pagination, 'page', 1);
      api
        .get('/smart_installation/widgets', {
          params: {
            page_type: this.tabValue(this.resourceParams.tab),
            widget_device_type: params.search_device_type,
            skin_id: params.search_skin_id
          }
        })
        .then(({ data }) => {
          const widgets = this.widgetsWithInstallations(
            data.widgets,
            data.smart_installations
          );
          this.SET_REVIEW_WIDGETS(widgets);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    tabValue(tab) {
      return tab ? WidgetPageType[tab.toUpperCase()] : null;
    },
    buttonDisabled(row) {
      return !row.display_order;
    },
    fetchSmartInstallations() {
      return new Promise((resolve, reject) => {
        api
          .get('/smart_installation/widgets', {
            params: {
              page_type: this.tabValue(this.resourceParams.tab),
              widget_device_type: this.resourceParams.search_device_type,
              skin_id: this.resourceParams.search_skin_id
            }
          })
          .then(({ data }) => {
            const widgets = this.widgetsWithInstallations(
              data.widgets,
              data.smart_installations
            );
            this.SET_REVIEW_WIDGETS(widgets);
            resolve();
          })
          .catch(reject);
      });
    },
    newSmartInstallationClicked() {
      this.isNewSmartInstallationCreated = false;

      this.openDialog([
        'ReviewSmartInstallationAttachWidgetSelectionFormDialog'
      ]).then(eventBus => {
        eventBus.$on('focus-row', id => {
          this.isNewSmartInstallationCreated = true;
          this.isLoading = true;
          this.fetchSmartInstallations()
            .then(() => {
              this.isLoading = false;
              this.focusOnRow(id);
            })
            .catch(() => (this.isLoading = false));
        });
        eventBus.$on('close', () => {
          if (this.isNewSmartInstallationCreated) return;

          this.isLoading = true;
          this.fetchSmartInstallations()
            .then(() => (this.isLoading = false))
            .catch(() => (this.isLoading = false));
        });
      });
    },
    focusOnRow(installationId) {
      const index = this.rows.findIndex(e => e.id === installationId);
      this.$nextTick(() => this.tableEventBus.$emit('focus-row', index));
    },
    smartInstallationSettingsClicked(widgetId, installationId, close) {
      this.openDialog([
        'ReviewSmartInstallationAttachWidgetFormDialog',
        {
          widgetId: widgetId,
          installationId: installationId
        }
      ]);
      close();
    },
    widgetSettingsClicked(widgetId, close) {
      this.openDialog([
        'ReviewSettingsWidgetsNormalFormDialog',
        {
          widgetId: widgetId,
          widgetType: this.reviewWidget(widgetId).widget_type
        }
      ]);
      close();
    },
    deleteSmartInstallation(widgetId, installationId, close) {
      if (!confirm(this.$t('app.delete_confirm'))) return;
      api.delete(`/smart_installations/${installationId}`).then(() => {
        const installationIndex = this.reviewWidget(
          widgetId
        ).smart_installations.findIndex(i => i.id === installationId);
        this.DELETE_REVIEW_WIDGET_SMART_INSTALLATION({
          widgetId: widgetId,
          installationIndex: installationIndex
        });
        close();
      });
    },
    widgetDisplayOrder() {
      return [
        i => this.reviewWidget(i.widget_id).widget_device_type,
        i => WidgetPageType.displayOrder.indexOf(i.page_type),
        i => {
          if (i.page_type == WidgetPageType.SPECIFIC) {
            return i.page_path;
          }
        },
        i => i.element_selector,
        i => WidgetInsertType.displayOrder.indexOf(i.install_position),
        i => i.install_order,
        i => i.skin_id || ''
      ];
    },
    skinIdDesc() {
      return [
        i => i.skin_id || '',
        i => this.reviewWidget(i.widget_id).widget_device_type,
        i => WidgetPageType.displayOrder.indexOf(i.page_type),
        i => {
          if (i.page_type == WidgetPageType.SPECIFIC) {
            return i.page_path;
          }
        },
        i => i.element_selector,
        i => WidgetInsertType.displayOrder.indexOf(i.install_position),
        i => i.install_order
      ];
    },
    hasInstallationsWithSamePositions(installation) {
      return this.reviewWidgetSmartInstallations.some(
        i =>
          i.id !== installation.id &&
          this.reviewWidget(i.widget_id).widget_device_type ===
            this.reviewWidget(installation.widget_id).widget_device_type &&
          i.page_type === installation.page_type &&
          i.element_selector === installation.element_selector &&
          i.install_position === installation.install_position
      );
    },
    changeSkinIdClicked(installationIds) {
      this.openDialog([
        'ReviewSmartInstallationChangeSkinIdFormDialog',
        { installationIds }
      ]).then(eventBus =>
        eventBus.$on('submit', () => {
          this.runAsyncJob(
            installationIds.length,
            this.$t('async_job.change_skin_id.title'),
            this.$t('async_job.change_skin_id.toast', {
              count: installationIds.length
            })
          );
        })
      );
    },
    duplicateInstallationsClicked(installationIds) {
      this.openDialog([
        'ReviewSmartInstallationDuplicateInstallationsFormDialog',
        { installationIds }
      ]).then(eventBus =>
        eventBus.$on('submit', () => {
          this.runAsyncJob(
            installationIds.length,
            this.$t('async_job.duplicate_installations.title'),
            this.$t('async_job.duplicate_installations.toast', {
              count: installationIds.length
            })
          );
        })
      );
    },
    deleteInstallationsClicked(installationIds) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('button.batch.delete_title'),
          markdownText: this.$t('button.batch.delete_confirm', {
            count: installationIds.length
          }),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('cancel', () => this.createToast('취소'));
        eventBus.$on('close', () => {
          this.deleteSmartInstallations(installationIds);
          this.runAsyncJob(
            installationIds.length,
            this.$t('async_job.delete_installations.title'),
            this.$t('async_job.delete_installations.toast', {
              count: installationIds.length
            })
          );
        });
      });
    },
    runAsyncJob(totalCount, title, resultMessage) {
      this.enqueueJob({
        title,
        totalCount,
        jobHandler: async ({ progress }) => {
          for (let i = 0; i < totalCount; ++i) {
            await new Promise(resolve =>
              setTimeout(() => {
                progress();
                resolve();
              }, 700)
            );
          }
          return resultMessage;
        },
        resultHandler: this.createToast
      });
    },
    deleteSmartInstallations(ids) {
      api
        .patch('/smart_installations/destroy_installations', { ids })
        .then(() => {
          ids.forEach(id => {
            const widgetId = this.reviewWidgetSmartInstallation(id).widget_id;
            const installationIndex = this.reviewWidget(
              widgetId
            ).smart_installations.findIndex(i => i.id === id);
            this.DELETE_REVIEW_WIDGET_SMART_INSTALLATION({
              widgetId: widgetId,
              installationIndex: installationIndex
            });
          });
        });
    },
    sort({ srcIndex, dstIndex }) {
      this.isSubmitting = true;
      const sortedRows = arrayMoveIndex(this.rows, srcIndex, dstIndex);
      api
        .patch('/smart_installations/sort', {
          ids: sortedRows.map(i => i.id)
        })
        .then(({ data }) => {
          const smartInstallations = data.smart_installations;
          smartInstallations.forEach(i => {
            this.UPDATE_REVIEW_WIDGET_SMART_INSTALLATION({
              widgetId: i.widget_id,
              installation: i
            });
          });
        })
        .finally(() => (this.isSubmitting = false));
    },
    widgetsWithInstallations(widgets, installations) {
      return widgets.map(w => {
        return {
          ...w,
          smart_installations: installations.filter(i => i.widget_id == w.id)
        };
      });
    },
    formatSkinId(skin_id) {
      return skin_id || this.$t('smart_installations.all_skin');
    },
    installationManageDropdownMenuItems(row) {
      return [
        [
          {
            label: this.$t('table_body.smart_installation_settings'),
            clickHandler: ({ close }) =>
              this.smartInstallationSettingsClicked(
                row.widget_id,
                row.id,
                close
              )
          },
          {
            label: this.$t('table_body.widget_settings'),
            clickHandler: ({ close }) =>
              this.widgetSettingsClicked(row.widget_id, close)
          },
          {
            label: this.$t('table_body.move_to_widget_inserted_url'),
            type: 'external_link',
            to: this.insertedUrl(this.reviewWidgetSmartInstallation(row.id)),
            disabled: !this.insertedUrl(
              this.reviewWidgetSmartInstallation(row.id)
            ),
            tooltip: this.insertedUrlTooltipMessage(
              this.reviewWidgetSmartInstallation(row.id).page_type
            )
          }
        ],
        [
          {
            label: this.$t('app.delete'),
            clickHandler: ({ close }) =>
              this.deleteSmartInstallation(row.widget_id, row.id, close),
            style: 'danger'
          }
        ]
      ];
    },
    widgetInfoString(widget) {
      const { id, name, widget_style, widget_sub_style, widget_type } = widget;
      const widgetStyle = widget_sub_style || widget_style;
      const widgetInfo = {
        widget_id: id,
        widget_type: WidgetType.t(widget_type),
        widget_name: name
      };
      if (!_.isNil(widgetStyle))
        widgetInfo['widget_style'] = `(${WidgetStyle.t(widgetStyle)})`;

      return this.$t('table_body.widget_info', widgetInfo);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
::v-deep {
  .review-widget-smart-installations-table__row-select {
    flex: 0 0 4%;
    min-width: 48px;
  }
  .review-widget-smart-installations-table__id {
    flex: 0 0 7%;
    min-width: 77px;
  }
  .review-widget-smart-installations-table__device-type {
    flex: 0 0 7%;
    min-width: 77px;
  }
  .review-widget-smart-installations-table__page-type {
    flex: 0 0 12.5%;
    min-width: 138px;
  }
  .review-widget-smart-installations-table__skin-id {
    flex: 0 0 8%;
    min-width: 88px;
  }
  .review-widget-smart-installations-table__position {
    flex: 0 0 20%;
    min-width: 221px;
  }
  .review-widget-smart-installations-table__widget-info {
    flex: 1 1 18%;
    min-width: 200px;
  }
  .review-widget-smart-installations-table__display-order {
    flex: 0 0 10%;
    min-width: 110px;
  }
  .review-widget-smart-installations-table__feature {
    flex: 0 0 13.5%;
    min-width: 150px;
  }
  .AppTabs__scroll {
    overflow-x: auto;
    white-space: nowrap;
  }
}
</style>

<i18n locale="ko">
{
  "new_smart_installation": "위젯 간편 설치 추가",
  "table_head": {
    "id": "설치 ID",
    "widget_id": "위젯 ID",
    "device_type": "디바이스",
    "page_type": "설치 페이지",
    "skin_id": "적용 스킨",
    "position": "설치 위치",
    "widget_info": "설치 위젯 정보",
    "display_order": "노출 순위",
    "feature": "기능"
  },
  "table_body": {
    "widget_info": "위젯 ID : {widget_id}<br>위젯 종류 : {widget_type}{widget_style}<br>위젯 이름 : {widget_name}",
    "smart_installation_settings": "위젯 간편 설치 설정",
    "widget_settings": "위젯 설정",
    "move_to_widget_inserted_url": "위젯으로 이동"
  },
  "tooltip": {
    "display_order": "같은 디바이스, 설치 페이지, 설치 위치에 여러 개의 위젯이 설치된 경우 설정된 노출 순위대로 노출됩니다.",
    "search_skin_id": "특정 스킨에 설치된 내역을 검색할 수 있습니다. ‘모든 스킨' 설치 내역도 포함되어 검색됩니다.",
    "sort_disabled": "같은 디바이스, 설치 페이지, 설치 위치에 여러 개의 위젯이 설치된 경우만 노출 순위를 변경할 수 있습니다.",
    "sort": "클릭한 상태로 이동하면 노출 순위를 변경할 수 있습니다."
  },
  "button": {
    "sort": {
      "widget_exposure_order": "노출 순",
      "skin_id_desc": "적용 스킨 순"
    },
    "batch": {
      "change_skin_id": "적용 스킨 변경",
      "duplicate": "복제",
      "delete": "삭제",
      "duplicate_title": "선택 내역 복제",
      "delete_title": "선택 내역 삭제",
      "delete_confirm": "선택한 {count}개의 설치 내역을 삭제하시겠습니까?"
    }
  },
  "async_job": {
    "change_skin_id": {
      "title": "선택 설치 내역 적용 스킨 변경",
      "toast": "{count}개의 설치 내역 적용 스킨 정보를 변경했습니다."
    },
    "duplicate_installations": {
      "title": "선택 설치 내역 복제",
      "toast": "{count}개의 설치 내역을 복제했습니다."
    },
    "delete_installations": {
      "title": "선택 설치 내역 삭제",
      "toast": "{count}개의 설치 내역을 삭제했습니다."
    }
  },
  "search": {
    "device_type": "디바이스",
    "pc": "PC",
    "mobile": "모바일",
    "skin_id": "적용 스킨",
    "skin_id_placeholder": "스킨 정보를 입력해주세요."
  }
}
</i18n>
