import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import i18n from '@/lib/i18n';

const FETCH_THIRD_PARTY_REVIEW_CSVS_REQUEST_ID =
  'FETCH_THIRD_PARTY_REVIEW_CSVS';

const state = {
  third_party_review_csvs: nullResources
};

const mutations = {
  SET_THIRD_PARTY_REVIEW_CSVS(state, third_party_review_csvs) {
    state.third_party_review_csvs = third_party_review_csvs;
  }
};

const actions = {
  fetchThirdPartyReviewCsvs({ commit }, params) {
    api.cancel(FETCH_THIRD_PARTY_REVIEW_CSVS_REQUEST_ID);
    api
      .get('/review/third_party_review_csvs', {
        requestId: FETCH_THIRD_PARTY_REVIEW_CSVS_REQUEST_ID,
        params
      })
      .then(function({ data }) {
        commit('SET_THIRD_PARTY_REVIEW_CSVS', data.third_party_review_csvs);
      });
  },
  createThirdPartyReviewCsv({ state, commit }, { formData, onComplete }) {
    api
      .post('/review/third_party_review_csvs', formData, {
        successMessage: i18n.t('review_third_party_review_csv.created')
      })
      .then(function({ data }) {
        const { third_party_review_csvs } = state;
        commit('SET_THIRD_PARTY_REVIEW_CSVS', {
          ...third_party_review_csvs,
          items: [
            data.third_party_review_csv,
            ...third_party_review_csvs.items
          ],
          total_count: third_party_review_csvs.total_count + 1
        });
        onComplete();
      })
      .catch(function() {
        onComplete();
      });
  }
};

const getters = {
  isFetchingThirdPartyReviewCsvs(_state, _getters, _rootState, rootGetters) {
    return rootGetters['request/isRequestPending'](
      FETCH_THIRD_PARTY_REVIEW_CSVS_REQUEST_ID
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
