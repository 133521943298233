import Vue from 'vue';
import api from '@/lib/api';
import WidgetInstallStatus from '@/enums/WidgetInstallStatus';
import ShopBuilder from '@/enums/ShopBuilder';
import BrandReviewStatus from '@/enums/BrandReviewStatus';
import ReviewCsvSyncStatus from '@/enums/ReviewCsvSyncStatus';

const state = {
  onboardingProgress: {
    shop_info_completed: false,
    request_installation_completed: false,
    main_color_completed: false,
    mileage_completed: false,
    godo_csv_completed: false,
    widget_install_status: WidgetInstallStatus.NONE,
    main_color: '#14161a',
    review_status: BrandReviewStatus.ONBOARDING_NOT_PAID,
    shop_builder: ShopBuilder.NONE,
    payment_notification_sent: false,
    market_category_id: null,
    mall_admin_username: '',
    mall_admin_password: '',
    product_category_id: null,
    auto_mileage_basic_settings_data: {},
    launch_date_requested: false,
    review_csv_sync_status: ReviewCsvSyncStatus.NONE,
    no_reviews_to_sync: false,
    godo_reviews_count_updated: false
  }
};

const mutations = {
  SET_ONBOARDING_PROGRESS(state, onboardingProgress) {
    state.onboardingProgress = onboardingProgress;
  },
  SET_WIDGET_INSTALL_STATUS({ onboardingProgress }, widgetInstallStatus) {
    Vue.set(onboardingProgress, 'widget_install_status', widgetInstallStatus);
  },
  SET_REQUEST_LAUNCH_DATE_COMPLETED(
    { onboardingProgress },
    requestLaunchDateCompleted
  ) {
    Vue.set(
      onboardingProgress,
      'launch_date_requested',
      requestLaunchDateCompleted
    );
  }
};

const getters = {
  shopInfoCompleted: ({ onboardingProgress }) =>
    onboardingProgress.shop_info_completed,
  marketCategoryId: ({ onboardingProgress }) =>
    onboardingProgress.market_category_id,
  productCategoryId: ({ onboardingProgress }) =>
    onboardingProgress.product_category_id,
  requestInstallationCompleted: ({ onboardingProgress }) =>
    onboardingProgress.request_installation_completed,
  godoCsvCompleted: ({ onboardingProgress }) =>
    onboardingProgress.godo_csv_completed,
  isWidgetInstallStatusRequested: ({ onboardingProgress }) =>
    onboardingProgress.widget_install_status === WidgetInstallStatus.REQUESTED,
  isWidgetInstallStatusComplete: ({ onboardingProgress }) =>
    onboardingProgress.widget_install_status === WidgetInstallStatus.COMPLETE,
  mileageCompleted: ({ onboardingProgress }) =>
    onboardingProgress.mileage_completed,
  autoMileageBasicSettingsData: ({ onboardingProgress }) =>
    onboardingProgress.auto_mileage_basic_settings_data,
  shouldDisplaySummaryBoxPaymentRequested: (
    { onboardingProgress },
    getters
  ) => {
    const { review_status, payment_notification_sent } = onboardingProgress;

    return (
      !getters.isFreeTrialSupported &&
      payment_notification_sent &&
      review_status === BrandReviewStatus.ONBOARDING_NOT_PAID
    );
  },
  mainColor: ({ onboardingProgress }) => onboardingProgress.main_color,
  widgetInstallStatus: ({ onboardingProgress }) =>
    onboardingProgress.widget_install_status,
  mallAdminUsername: ({ onboardingProgress }) =>
    onboardingProgress.mall_admin_username,
  mallAdminPassword: ({ onboardingProgress }) =>
    onboardingProgress.mall_admin_password,
  shouldDisplaySummaryBoxPreparingPayment: (
    { onboardingProgress },
    getters
  ) => {
    return (
      !getters.isFreeTrialSupported &&
      getters.isWidgetInstallStatusRequested &&
      onboardingProgress.review_status ===
        BrandReviewStatus.ONBOARDING_NOT_PAID &&
      !onboardingProgress.payment_notification_sent
    );
  },
  shouldDisplaySummaryBoxWelcome: ({ onboardingProgress }, getters) => {
    if (getters.isFreeTrialSupported)
      return (
        onboardingProgress.review_status ===
        BrandReviewStatus.ONBOARDING_NOT_PAID
      );
    else
      return (
        onboardingProgress.review_status ===
          BrandReviewStatus.ONBOARDING_NOT_PAID &&
        !onboardingProgress.payment_notification_sent &&
        onboardingProgress.widget_install_status == WidgetInstallStatus.NONE
      );
  },
  isFreeTrialSupported: ({ onboardingProgress }) =>
    onboardingProgress.shop_builder === ShopBuilder.IMWEB,
  isEssentialAccordionCompleted: (
    { onboardingProgress },
    _getters,
    _rootState,
    rootGetters
  ) =>
    onboardingProgress.shop_info_completed &&
    onboardingProgress.request_installation_completed &&
    (!rootGetters['session/isGodoBrand'] ||
      onboardingProgress.godo_csv_completed),
  isWidgetInstallStatusConfirmed: ({ onboardingProgress }) =>
    onboardingProgress.widget_install_status === WidgetInstallStatus.CONFIRMED,
  isPaymentCompleted: ({ onboardingProgress }) =>
    [BrandReviewStatus.TESTING, BrandReviewStatus.ONBOARDING_PAID].includes(
      onboardingProgress.review_status
    ),
  isMainColorCompleted: ({ onboardingProgress }) =>
    onboardingProgress.main_color_completed,
  shouldDisplaySummaryBoxLive: (_state, getters) =>
    !getters.isFreeTrialSupported &&
    getters.isEssentialAccordionCompleted &&
    getters.isPaymentCompleted &&
    getters.isWidgetInstallStatusConfirmed,
  shouldDisplaySummaryBoxFreeTrial: (_state, getters, rootState) => {
    const { imwebApiKeyValidity } = rootState['session'];

    return (
      imwebApiKeyValidity &&
      getters.isFreeTrialSupported &&
      getters.isEssentialAccordionCompleted
    );
  },
  shouldDisplaySummaryBoxDataSyncCheck: ({ onboardingProgress }) => {
    const { review_status } = onboardingProgress;
    return review_status === BrandReviewStatus.ONBOARDING_PAID;
  },
  shouldDisplaySummaryBoxRequestInstallationComplete: (_state, getters) =>
    getters.isWidgetInstallStatusRequested,
  shouldDisplaySummaryBoxInstallComfirmed: ({ onboardingProgress }, getters) =>
    getters.isWidgetInstallStatusConfirmed &&
    !onboardingProgress.launch_date_requested,
  shouldDisplaySummaryBoxRequestLaunchDateComplete: (
    { onboardingProgress },
    getters
  ) =>
    getters.isWidgetInstallStatusConfirmed &&
    onboardingProgress.launch_date_requested,
  shouldDisableInstallRequestAccordion(
    { onboardingProgress },
    _getters,
    _rootState,
    rootGetters
  ) {
    if (!rootGetters['session/isGodoBrand']) return false;

    const {
      review_csv_sync_status,
      no_reviews_to_sync,
      godo_reviews_count_updated,
      godo_csv_completed
    } = onboardingProgress;
    return !(
      godo_csv_completed &&
      godo_reviews_count_updated &&
      (review_csv_sync_status === ReviewCsvSyncStatus.SUCCEEDED ||
        no_reviews_to_sync)
    );
  }
};

const actions = {
  updateOnboardingProgress({ commit }, payload) {
    return api
      .patch('/easy_join/onboarding_progresses', {
        onboarding_progress: payload
      })
      .then(({ data }) =>
        commit('SET_ONBOARDING_PROGRESS', data.onboarding_progress)
      );
  },
  requestWidgetInstall({ commit }, install_requests) {
    return api
      .post('/easy_join/widget_install_requests', { install_requests })
      .then(({ data }) =>
        commit('SET_WIDGET_INSTALL_STATUS', data.widget_install_status)
      );
  },
  requestLaunchDate({ commit }, launch_date) {
    return api
      .post('/easy_join/scheduled_launches', { launch_date })
      .then(() => commit('SET_REQUEST_LAUNCH_DATE_COMPLETED', true));
  }
};

export default { namespaced: true, state, mutations, getters, actions };
