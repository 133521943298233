import api from '@/lib/api';

const state = {
  firstChatText: '',
  firstChatTextDefault: '',
  lastChatText: '',
  lastChatTextDefault: ''
};

const mutations = {
  SET_CHATBOT_MESSAGE_SETTINGS(state, chatbotMessageSettings) {
    state.firstChatText = chatbotMessageSettings.first_chat_text;
    state.firstChatTextDefault = chatbotMessageSettings.first_chat_text_default;
    state.lastChatText = chatbotMessageSettings.last_chat_text;
    state.lastChatTextDefault = chatbotMessageSettings.last_chat_text_default;
  },
  SET_FIRST_CHAT_TEXT(state, firstChatText) {
    state.firstChatText = firstChatText;
  },
  SET_LAST_CHAT_TEXT(state, lastChatText) {
    state.lastChatText = lastChatText;
  }
};

const getters = {
  isFirstChatTextDirty: state => {
    return state.firstChatText !== state.firstChatTextDefault;
  },
  isLastChatTextDirty: state => {
    return state.lastChatText !== state.lastChatTextDefault;
  }
};

const actions = {
  updateMessageSettings({ commit }, { formData }) {
    api.patch('/review/chatbot_settings', formData).then(({ data }) => {
      const { chatbot_settings } = data;
      commit('SET_FIRST_CHAT_TEXT', chatbot_settings.first_chat_text);
      commit('SET_LAST_CHAT_TEXT', chatbot_settings.last_chat_text);
    });
  }
};

export default { namespaced: true, state, mutations, getters, actions };
