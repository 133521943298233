<template>
  <AppProgressSteps
    class="SmartstoreProgressSteps"
    :steps="steps"
    :current-step="currentStep"
  />
</template>

<script>
import { mapState } from 'vuex';
import SmartstoreStatus from '@/enums/SmartstoreStatus';

export default {
  name: 'SmartstoreProgressSteps',
  data() {
    return {
      steps: [
        this.$t('steps.agreement.title'),
        this.$t('steps.product_match.title'),
        this.$t('steps.payment.title')
      ]
    };
  },
  computed: {
    ...mapState('smartstore', ['smartstoreStatus']),
    currentStep() {
      switch (this.smartstoreStatus) {
        case SmartstoreStatus.NONE:
          return 0;
        case SmartstoreStatus.PRODUCT_MATCH_PENDING:
        case SmartstoreStatus.PRODUCT_MATCH_STARTED:
        case SmartstoreStatus.PRODUCT_MATCH_FAILED:
        case SmartstoreStatus.PRODUCT_MATCH_FINISHED:
          return 1;
        default:
          // SmartstoreStatus.ESTIMATION_QUEUED
          // SmartstoreStatus.ESTIMATION_STARTED
          // SmartstoreStatus.ESTIMATION_FAILED
          // SmartstoreStatus.PAYMENT_PENDING
          // SmartstoreStatus.STARTER_IMPORT_QUEUED
          // SmartstoreStatus.STARTER_IMPORT_STARTED
          // SmartstoreStatus.STARTER_IMPORT_FAILED
          return 2;
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "steps": {
    "agreement": {
      "title": "스마트스토어 권한 초대"
    },
    "product_match": {
      "title": "상품 매칭표 등록"
    },
    "payment": {
      "title": "결제 및 연동 시작"
    }
  }
}
</i18n>
