<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <AppForm
      :id="SETTINGS_ID"
      v-bind="formProps"
      submit-button
      :object-id="objectId"
      v-on="formEvents"
    >
      <template #group="{ id, errors }">
        <template v-if="id === 'product_categories_csv'">
          <AppButton class="AppForm__group-field">
            <a :href="productCategoriesCsvDownloadUrl">
              {{ $t('csv_download_button') }}
            </a>
          </AppButton>
          <div class="AppForm__group-field">
            <AppFileInput
              id="product-categories-csv-file"
              class="ChannelSettings__csv-file-input"
              :event-bus="fileEventBus"
              :accept="'.csv'"
              :placeholder="$t('csv_upload_file_input_placeholder')"
              @change="changeCategoriesCsvFile"
            />
            <AppButton
              :disabled="!isCategoriesCsvSelected"
              :label="$t('app.upload')"
              @click="uploadCategoriesCsv"
            />
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';
import TargetContentView from '@/mixins/TargetContentView';
import ProductThumbnail from '@/enums/ProductThumbnail';

export default {
  name: 'ChannelSettingsBasic',
  mixins: [FormView, SettingsView, TargetContentView],
  data: () => ({
    isLoading: true,
    isCategoriesCsvSelected: false,
    fileEventBus: new Vue(),
    selectedCategoriesCsvFile: null
  }),
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    productThumbnailOptions() {
      return ProductThumbnail.options();
    },
    SETTINGS_ID() {
      return 'channel_settings_basic';
    },
    objectId() {
      return 'channel_settings';
    },
    groupOptions() {
      if (this.isLoading) return {};

      return {
        excluding_category_ids: { type: 'multiple_select_product_category' },
        in_categories: { type: 'checkbox' },
        excluding_product_keywords: {
          type: 'tags',
          placeholder: this.$t('form.excluding_product_keywords.placeholder'),
          searchPlaceholder: this.$t(
            'form.excluding_product_keywords.search_placeholder'
          ),
          searchNoResults: this.$t(
            'form.excluding_product_keywords.search_no_results'
          ),
          tagAlreadyExistsMessage: this.$t(
            'form.excluding_product_keywords.tag_already_exists'
          )
        },
        product_categories_csv: {
          groupDescription: {
            message: this.$t('form.product_categories_csv.description'),
            mode: 'markdown'
          }
        }
      };
    },
    productCategoriesCsvDownloadUrl() {
      return this.pathWithBrandParams(
        '/api/channel/product_categories/download_csv'
      );
    }
  },
  mounted() {
    api
      .get('/channel/settings')
      .then(({ data }) => {
        this.orgFormObject = data.settings;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/channel/settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
        })
        .finally(() => (this.isSubmitting = false));
    },
    changeCategoriesCsvFile(file) {
      this.isCategoriesCsvSelected = true;
      this.selectedCategoriesCsvFile = file;
    },
    uploadCategoriesCsv() {
      var data = new FormData();
      data.append('file', this.selectedCategoriesCsvFile);
      api
        .post('/channel/product_categories/upload_csv', data, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.isCategoriesCsvSelected = false;
          this.fileEventBus.$emit('reset');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.ChannelSettings__csv-file-input {
  display: inline-block;
  width: 372px;
  margin-right: 8px;
}
</style>

<i18n locale="ko">
{
  "form": {
    "excluding_product_keywords": {
      "placeholder": "상품 키워드를 선택해주세요.",
      "search_placeholder": "노출 제한할 키워드를 입력해 주세요.",
      "search_no_results": "입력한 키워드를 추가할 수 있습니다.",
      "tag_already_exists": "이미 포함된 키워드입니다."
    },
    "product_categories_csv": {
      "description": "CSV 파일 열기 또는 편집에 대한 도움이 필요하시다면 [CSV 파일 열기 또는 편집 가이드](https://www.notion.so/crema/CSV-fd014f3ae3aa4185bc8ecf53b1d82080)를 참고해주세요."
    }
  },
  "purchase_rate_filter_type": {
    "click_count": "상품 조회수",
    "purchase_count": "판매량"
  },
  "csv_download_button": "CSV 다운로드",
  "csv_upload_file_input_placeholder": "업로드할 CSV 파일을 등록해주세요."
}
</i18n>
