<template>
  <AppButtonToggle
    :tooltip="tooltip"
    :disabled="disabled"
    :value="value"
    :label="label"
    size="small"
    @change="$emit('change', $event)"
  >
    <AppSvgIcon
      :class="[
        'AppResourceTableSearchbarIconButtonToggle__icon',
        { 'AppResourceTableSearchbarIconButtonToggle__icon--pushed': value }
      ]"
      :name="icon"
    />
    <span>{{ label }}</span>
  </AppButtonToggle>
</template>

<script>
export default {
  name: 'AppResourceTableSearchbarIconButtonToggle',
  props: {
    label: { type: String, default: null },
    icon: { type: String, required: true },
    tooltip: { type: [String, Object], default: null },
    disabled: { type: Boolean, default: false },
    value: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppResourceTableSearchbarIconButtonToggle__icon {
  position: relative;
  top: 2px;
  margin-right: 8px;
  fill: transparent;

  &--pushed {
    fill: $color-mustard;
    stroke: $color-mustard;
  }
}
</style>
