<template>
  <AppSearchbar @submit="changeSearchData">
    <template #default>
      <li v-if="downloadExcelUrl">
        <AppButtonDownload
          button-style="grey"
          :label="$t('app.download_excel')"
          :url="downloadExcelUrl"
        />
      </li>
    </template>

    <template #right>
      <li>
        <AppSearchWithType
          v-model="searchWithType"
          :search-types="SEARCH_TYPES"
        />
      </li>
      <li>
        <AppDateRangePicker
          v-model="dateRange"
          :presets="[
            'recent_year',
            'last_7days',
            'last_30days',
            'this_month',
            'last_month',
            'last_quarter',
            'last_year'
          ]"
          min-date="2021-06-01"
          @update="changeDateRange"
        />
      </li>
      <li>
        <AppButtonToggle
          v-model="isAdvancedSearchVisible"
          :label="$t('app.advanced_search')"
        />
      </li>
    </template>

    <template #advanced-search>
      <AppAdvancedSearch
        v-model="resourceParams"
        :visible="isAdvancedSearchVisible"
        :sections="advancedSearchSections"
        :event-bus="eventBus"
        @submit="changeAdvancedSearch"
      >
        <template #group="{ id, errors }">
          <template v-if="id === 'reviews_count'">
            <div class="AppForm__group-field">
              <i18n path="unit.count">
                <span>
                  <AppNumberInput
                    v-model="resourceParams.min_reviews_count"
                    inline
                    :digits="8"
                    :default="defaultMinReviewsCount"
                    :allow-negative="false"
                    :allow-decimal="false"
                    :invalid="!!errors.min_reviews_count"
                    @blur="validateReviewsCount()"
                    @change="validateReviewsCount()"
                  />
                  ~&nbsp;
                  <AppNumberInput
                    v-model="resourceParams.max_reviews_count"
                    inline
                    :digits="8"
                    :allow-negative="false"
                    :allow-decimal="false"
                    :invalid="!!errors.max_reviews_count"
                    @blur="validateReviewsCount()"
                    @change="validateReviewsCount()"
                  />
                </span>
              </i18n>
              <template
                v-if="errors.min_reviews_count && errors.max_reviews_count"
              >
                <AppFormError :error="errors.min_reviews_count" />
              </template>
              <template v-else>
                <AppFormError :error="errors.min_reviews_count" />
                <AppFormError :error="errors.max_reviews_count" />
              </template>
            </div>
          </template>
        </template>
      </AppAdvancedSearch>
    </template>
  </AppSearchbar>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';

export default {
  name: 'AnalyticsInsightProductsSearchbar',
  props: {
    downloadExcelUrl: { type: String, default: null },
    resourceParams: { type: Object, required: true },
    defaultMinReviewsCount: { type: Number, required: true }
  },
  data() {
    return {
      SEARCH_TYPES: ['product_code', 'product_name'].map(value => ({
        value,
        label: this.$t(`search_type.${value}`)
      })),
      searchWithType: _.pick(this.resourceParams, [
        'search_type',
        'search_query'
      ]),
      dateRange: _.pick(this.resourceParams, ['start_date', 'end_date']),
      isAdvancedSearchVisible: false,
      eventBus: new Vue()
    };
  },
  computed: {
    advancedSearchSections() {
      const minMaxReviewsCountRule = () => {
        const { min_reviews_count, max_reviews_count } = this.resourceParams;
        if (min_reviews_count && max_reviews_count)
          return max_reviews_count >= min_reviews_count;
        else return true;
      };
      return [
        {
          id: 'filter',
          groups: [
            {
              id: 'reviews_count',
              label: this.$t('advanced_search.reviews_count'),
              fields: [
                {
                  id: 'min_reviews_count',
                  validate: [
                    'positive_integer',
                    { rule: 'gte', allowed: 1 },
                    {
                      rule: minMaxReviewsCountRule,
                      errorMessage: this.$t('max_reviews_count_validation')
                    }
                  ]
                },
                {
                  id: 'max_reviews_count',
                  validate: [
                    'positive_integer',
                    {
                      rule: minMaxReviewsCountRule,
                      errorMessage: this.$t('max_reviews_count_validation')
                    }
                  ]
                }
              ]
            }
          ]
        }
      ];
    }
  },
  watch: {
    searchWithType: {
      handler(searchWithType) {
        this.searchWithType = searchWithType;
      },
      deep: true
    }
  },
  methods: {
    changeSearchData() {
      this.$emit('change-search-data', this.searchWithType);
    },
    changeDateRange(dateRange) {
      this.$emit('change-date-range', dateRange);
    },
    changeAdvancedSearch() {
      if (
        !this.resourceParams.min_reviews_count &&
        !this.resourceParams.max_reviews_count
      )
        this.isAdvancedSearchVisible = false;
      this.$emit('change-advanced-search', this.resourceParams);
    },
    validateReviewsCount() {
      this.eventBus.$emit('validate-field', 'min_reviews_count');
      this.eventBus.$emit('validate-field', 'max_reviews_count');
    }
  }
};
</script>

<i18n locale="ko">
{
  "search_type": {
    "product_code": "상품 번호",
    "product_name": "상품명"
  },
  "advanced_search": {
    "reviews_count": "리뷰수 범위"
  },
  "max_reviews_count_validation": "최소 리뷰수는 최대 리뷰수보다 작거나 같은 값이어야 합니다."
}
</i18n>
