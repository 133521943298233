var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppSelectSearchable',_vm._g(_vm._b({attrs:{"columns":[{ id: 'value', label: _vm.$t('product_option_value.value') }],"search-types":[
    { value: 'value', label: _vm.$t('product_option_value.value') }
  ]},on:{"search":_vm.search}},'AppSelectSearchable',{
    id: _vm.id,
    title: _vm.title,
    name: _vm.name,
    items: _vm.productOptionValues,
    disabled: _vm.disabled,
    invalid: _vm.invalid,
    placeholder: _vm.placeholder,
    multiple: _vm.multiple,
    selectButtonLabel: _vm.$t('select_product_option_value')
  },false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }