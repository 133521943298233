import _ from 'lodash';
import { endsWithConsonant } from 'hangul-js';

function hasJong(c) {
  if (/[가-힣]/.test(c)) {
    return endsWithConsonant(c);
  } else {
    return /[lmnr136780]/.test(c);
  }
}

const POSTPOSITIONS_WITHOUT_JONG = {
  이: '가',
  은: '는',
  을: '를',
  과: '와',
  으로: '로'
};
const POSTPOSITIONS_WITH_JONG = _.invert(POSTPOSITIONS_WITHOUT_JONG);
function getPostposition(c, postposition) {
  if (hasJong(c)) {
    return POSTPOSITIONS_WITH_JONG[postposition] || postposition;
  } else {
    return POSTPOSITIONS_WITHOUT_JONG[postposition] || postposition;
  }
}

export default function(str) {
  if (!str) return str;

  if (str.match(/\(\([이가은는을를과와로]\)\)/)) {
    return str.replace(
      /(.)\(\(([이가은는을를과와로])\)\)/g,
      (_, c, p) => `${c}${getPostposition(c, p)}`
    );
  } else {
    return str;
  }
}
