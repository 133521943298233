<template>
  <AppModalDefault :title="$t('title')" width="900px" @close="close">
    <template #body>
      <div class="TheFreeTrialDialog__content">
        <div class="TheFreeTrialDialog__content-title">
          {{ $t('content_1') }}
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div ref="content" v-html="$t('content_2', [startDate, endDate])" />
        <br />
        <div ref="content" v-html="$t('content_3')" />
        <!-- eslint-enable vue/no-v-html -->
        <AppButton
          class="TheFreeTrialDialog__button"
          type="external_link"
          :to="imwebAdminUrl"
        >
          {{ $t('button_label') }}
          <AppExternalLinkIcon />
        </AppButton>
      </div>
    </template>
    <template #foot>
      <AppButton
        button-style="blue"
        :label="$t('app.confirmed')"
        @click="closeAndSnooze"
      />
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'TheFreeTrialDialog',
  mixins: [DialogView],
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    imwebAdminUrl() {
      return `${this.currentBrand.url}/admin/design`;
    }
  },
  methods: {
    ...mapActions('alert', ['snoozeAlert']),
    ...mapActions('dialog', ['openDialog']),
    closeAndSnooze() {
      this.snoozeAlert('free_trial_popup');
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
.TheFreeTrialDialog__content-title {
  font-weight: bolder;
  margin-bottom: 5px;
}

.TheFreeTrialDialog__content {
  border-radius: 5px;
  border: 1px solid $color-grey-25;
  padding: 20px 20px;
  @include text-content;
}

.TheFreeTrialDialog__button {
  margin-top: 10px;
}
</style>

<i18n locale="ko">
{
  "title": "크리마 리뷰 무료체험 시작",
  "content_1": "크리마 리뷰 무료체험 중입니다.",
  "content_2": "지금부터 크리마 리뷰의 모든 기능을 사용해 볼 수 있습니다.<br> 무료체험 시작일: <b>{0}</b><br>무료체험 만료일: <b>{1}</b>",
  "content_3": "아직 크리마 리뷰 위젯을 설치하지 않으셨다면<br>아임웹 디자인 페이지에서 쇼핑몰 페이지 별로 원하는 크리마 리뷰 위젯을 추가해주세요.",
  "button_label": "아임웹 디자인 페이지 바로가기"
}
</i18n>
