var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{style:(_vm.contentStyle),attrs:{"is-loading":_vm.isLoading}},[_c('AppSearchbar',{on:{"submit":_vm.search},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('AppButtonSyncCategory')]},proxy:true},{key:"right",fn:function(){return [_c('AppSearchWithType',{staticClass:"DisplaySettingsIncludingCategories__search-bar-item",attrs:{"search-types":_vm.searchTypes},model:{value:(_vm.searchWithType),callback:function ($$v) {_vm.searchWithType=$$v},expression:"searchWithType"}}),_c('AppButtonToggle',{staticClass:"DisplaySettingsIncludingCategories__search-bar-item",attrs:{"label":_vm.$t('app.advanced_search')},model:{value:(_vm.advancedSearchVisible),callback:function ($$v) {_vm.advancedSearchVisible=$$v},expression:"advancedSearchVisible"}})]},proxy:true},{key:"advanced-search",fn:function(){return [_c('AppAdvancedSearch',{attrs:{"visible":_vm.advancedSearchVisible,"sections":_vm.advancedSearchSections},on:{"submit":_vm.search},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
return [(id === 'products_count_range')?[_c('div',{staticClass:"AppForm__group-field"},[_c('i18n',{attrs:{"path":"unit.count"}},[_c('span',[_c('AppNumberInput',{attrs:{"inline":"","digits":6},model:{value:(_vm.searchParams.min_products_count),callback:function ($$v) {_vm.$set(_vm.searchParams, "min_products_count", $$v)},expression:"searchParams.min_products_count"}}),_vm._v(" ~  "),_c('AppNumberInput',{attrs:{"inline":"","digits":6},model:{value:(_vm.searchParams.max_products_count),callback:function ($$v) {_vm.$set(_vm.searchParams, "max_products_count", $$v)},expression:"searchParams.max_products_count"}})],1)])],1)]:_vm._e()]}}]),model:{value:(_vm.searchParams),callback:function ($$v) {_vm.searchParams=$$v},expression:"searchParams"}})]},proxy:true}])}),_c('AppForm',_vm._b({attrs:{"submit-button":""},on:{"submit":_vm.submit}},'AppForm',_vm.formProps,false),[_c('AppSelectResourceTable',{staticClass:"DisplaySettingsIncludingCategories__category-table",attrs:{"table-id":"category-table","table-style":"depth","columns":_vm.columns,"rows":_vm.rows,"filter-options":_vm.categoryFilterOptions,"filter-values":_vm.categoryFilterValues,"selected-item-column-label":_vm.$t('enable_display_category'),"enable-batch-button-label":_vm.$t('batch_buttons.enable_selected_categories'),"disable-batch-button-label":_vm.$t('batch_buttons.disable_selected_categories')},on:{"change-filter-values":function($event){_vm.categoryFilterValues = $event}},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var value = ref.value;
var row = ref.row;
return [(column === 'property')?[(row.deleted_at)?_c('AppBadge',{attrs:{"badge-style":"red-outline","label":_vm.$t('product_category.deleted'),"tooltip":_vm.$t('product_category.deleted_tooltip')}}):_vm._e(),(row.virtual)?_c('AppBadge',{attrs:{"badge-style":"grey-outline","label":_vm.$t('product_category.virtual')}}):_vm._e(),(row.sub_category_display)?_c('AppBadge',{attrs:{"badge-style":"grey-outline","label":_vm.$t('product_category.sub_category_display'),"tooltip":{
              message: _vm.$t('product_category.sub_category_display_tooltip'),
              textAlign: 'left'
            }}}):_vm._e()]:_vm._e()]}}]),model:{value:(_vm.formObject.enabled_category_ids),callback:function ($$v) {_vm.$set(_vm.formObject, "enabled_category_ids", $$v)},expression:"formObject.enabled_category_ids"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }