<template>
  <div>
    <div class="AppForm__group-field">
      <AppTextInput
        :placeholder="email || $t('placeholder')"
        type="email"
        readonly
      />
    </div>
    <AppFormHint
      :message="hint"
      :priority="changeRequest.status === 'rejected' ? 'danger' : null"
    />
    <div class="AppForm__group-field">
      <AppButton :label="$t('edit_button')" @click="clickEditButton" />
    </div>
  </div>
</template>

<script>
import { isEmailFormatValid } from '@/lib/emailFormatValidator';
import api from '@/lib/api';

export default {
  name: 'AppSenderEmailInput',
  model: { prop: 'lastChangeRequest', event: 'change' },
  props: {
    email: { type: String, required: true },
    verificationStatus: { type: String, required: true },
    lastChangeRequest: { type: Object, default: null }
  },
  computed: {
    changeRequest() {
      const { lastChangeRequest } = this;
      if (lastChangeRequest)
        return {
          ...lastChangeRequest,
          ...(lastChangeRequest.status === 'approved' && !this.email
            ? { status: 'none' }
            : {})
        };

      return {
        email: this.email,
        status: this.email
          ? this.verificationStatus === 'verified'
            ? 'approved'
            : 'requested_aws_verification'
          : 'none'
      };
    },
    hint() {
      const { status, email } = this.changeRequest;
      return status === 'none' ? '' : this.$t(`hint.${status}`, { email });
    }
  },
  methods: {
    clickEditButton() {
      const email = (window.prompt(this.$t('prompt')) || '').trim();
      if (!email) return;

      if (!isEmailFormatValid(email))
        return window.alert(this.$t('validations.email_format'));

      if (email === this.email && this.verificationStatus === 'verified')
        return window.alert(this.$t('must_be_different'));

      api
        .post(
          '/brand_sender_email_change_requests',
          { email },
          { successMessage: this.$t('requested') }
        )
        .then(({ data }) =>
          this.$emit('change', data.brand_sender_email_change_request)
        );
    }
  }
};
</script>

<i18n locale="ko">
{
  "placeholder": "이메일을 설정해주세요.",
  "hint": {
    "requested_aws_verification": "**{email}**로 인증 요청 메일이 발송됐습니다. 이메일의 링크를 클릭하여 인증을 완료해주세요.",
    "requested_crema_verification": "**{email}**로 인증 요청 메일이 발송됐습니다. 이메일의 링크를 클릭하여 인증을 완료해주세요.",
    "rejected": "인증 링크가 만료되어 **{email}** 인증에 실패했습니다. (사유: 이메일 인증 링크 유효기간 만료)\n'변경 요청' 버튼을 눌러 다시 진행해주세요.",
    "approved": "인증된 이메일입니다. 변경을 원하면 '변경 요청' 버튼을 눌러 재인증받으세요."
  },
  "edit_button": "변경 요청",
  "prompt": "변경할 발송 이메일 주소를 입력해주세요.",
  "must_be_different": "현재 발송 이메일 주소와 동일합니다. 다른 이메일 주소를 입력해주세요.",
  "requested": "인증 이메일이 발송되었습니다."
}
</i18n>
