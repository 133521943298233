<template>
  <AppChart :options="options" :tooltip="barTooltip" />
</template>

<script>
export default {
  name: 'AppChartStackedBar',
  props: {
    data: { type: Array, required: true },
    tooltip: { type: Object, default: undefined }
  },
  computed: {
    options() {
      const height = 42;
      const pointWidth = 16;
      return {
        chart: {
          height,
          margin: [pointWidth - height, 0, 0, 0],
          spacing: [0, 0, 0, 0],
          type: 'bar'
        },
        exporting: false,
        legend: {
          enabled: true,
          align: 'right',
          itemDistance: 8,
          navigation: { enabled: false },
          padding: 0,
          reversed: true,
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 0
        },
        plotOptions: {
          series: {
            animation: false,
            stacking: 'percent',
            pointWidth,
            pointPadding: 0,
            groupPadding: 0,
            events: {
              legendItemClick: function() {
                return false;
              }
            }
          }
        },
        title: { text: '' },
        tooltip: { enabled: false },
        xAxis: { visible: false },
        yAxis: { visible: false },
        series: this.series
      };
    },
    series() {
      const isEmpty = this.data.every(({ value }) => !value);
      return [
        ...(isEmpty
          ? [{ data: [1], colorIndex: 999, showInLegend: false }]
          : []),
        ...[...this.data].reverse().map((d, i) => ({
          name: d.label,
          data: [d.value],
          colorIndex: d.colorIndex || this.data.length - i - 1
        }))
      ];
    },
    barTooltip() {
      const tooltip = this.tooltip;
      if (!tooltip) return tooltip;

      return {
        ...tooltip,
        items: tooltip.items.map(item => ({ ...item, symbol: 'square' }))
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

@mixin highcharts-color($num, $color) {
  .highcharts-color-#{$num} {
    fill: $color;
    stroke: $color;
  }
}

::v-deep {
  @include highcharts-color(0, $color-mustard);
  @include highcharts-color(1, $color-mustard-light);
  @include highcharts-color(2, $color-grey-15);
  @include highcharts-color(3, $color-grey-40);
  @include highcharts-color(999, $color-mango-lighter);

  .highcharts-legend-item.highcharts-bar-series {
    cursor: auto;
  }

  .highcharts-legend-item.highcharts-bar-series > text {
    cursor: auto;
    fill: $color-grey-60;
  }

  .highcharts-legend-series-active
    g.highcharts-bar-series:not(.highcharts-series-hover) {
    opacity: 1;
  }
}
</style>
