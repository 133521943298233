var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.$t('sub_title', [_vm.formatUser(_vm.review)]),"form-props":Object.assign({}, _vm.formProps,
    {submitLabel: _vm.$t('apply_tags'),
    submittingLabel: _vm.$t('applying_tags')}),"width":"middle"},scopedSlots:_vm._u([{key:"searchbar",fn:function(){return [_c('li',[_c('AppButton',{attrs:{"button-style":"red","label":_vm.$t('add_tag')},on:{"click":_vm.addTag}})],1)]},proxy:true},{key:"group",fn:function(ref){
  var id = ref.id;
  var errors = ref.errors;
return [(id === 'tags')?_c('AppTable',{attrs:{"columns":_vm.COLUMNS,"rows":_vm.tags,"no-data":_vm.$t('no_data')},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
  var row = ref.row;
  var column = ref.column;
  var rowIndex = ref.rowIndex;
return [(column.id === 'select')?[_c('AppCheckbox',{attrs:{"input-style":"standalone"},model:{value:(_vm.tags[rowIndex].selected),callback:function ($$v) {_vm.$set(_vm.tags[rowIndex], "selected", $$v)},expression:"tags[rowIndex].selected"}})]:(column.id === 'name')?[_c('AppTextInput',{ref:("tagName" + rowIndex),attrs:{"placeholder":_vm.$t('tag_name_placeholder'),"invalid":!!errors[("tags[" + (row.id) + "][name]")]},on:{"blur":function($event){return _vm.validateTagName(row, errors)},"change":function($event){return _vm.validateTagName(row, errors)}},model:{value:(_vm.tags[rowIndex].name),callback:function ($$v) {_vm.$set(_vm.tags[rowIndex], "name", $$v)},expression:"tags[rowIndex].name"}}),_c('AppFormError',{attrs:{"error":errors[("tags[" + (row.id) + "][name]")]}})]:(column.id === 'remove_tag')?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              row.reviews_count
                ? _vm.$t('remove_tag_disable_tooltip', {
                    reviews_count: row.reviews_count
                  })
                : null
            ),expression:"\n              row.reviews_count\n                ? $t('remove_tag_disable_tooltip', {\n                    reviews_count: row.reviews_count\n                  })\n                : null\n            "}]},[_c('AppButton',{attrs:{"button-style":"red-outline","disabled":!!row.reviews_count,"label":_vm.$t('app.delete')},on:{"click":function($event){return _vm.removeTag(rowIndex)}}})],1)]:_vm._e()]}}],null,true)}):_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }