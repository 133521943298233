var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalFormLivePreview',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.productManagement.name,"width":"420px","form-props":_vm.formProps},on:{"error-summary":_vm.updatePreviewIfValid},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('AppIframeViewer',{staticClass:"DisplayWidgetManagementFormDialog__preview",attrs:{"src":_vm.previewUrl,"is-loading-forced":_vm.isLoading || _vm.displayVersion !== _vm.currentVersion}})]},proxy:true},{key:"group",fn:function(ref){
var id = ref.id;
var disabled = ref.disabled;
var errors = ref.errors;
return [(id === 'pc_margin' || id === 'mobile_margin')?_vm._l(([
          ['top', 'right'],
          ['bottom', 'left']
        ]),function(directions){return _c('div',{key:directions.join('-'),class:[
          'DisplayWidgetManagementFormDialog__margin-row',
          'AppForm__group-field',
          'AppForm__group-field--mt12',
          disabled ? 'AppForm__group-field--disabled' : ''
        ]},_vm._l((directions),function(direction){return _c('div',{key:direction,staticClass:"DisplayWidgetManagementFormDialog__margin-input"},[_c('div',[_vm._v(_vm._s(_vm.$t(("form.margin.fields." + direction))))]),_c('AppNumberInput',{staticClass:"AppForm__group-field",attrs:{"allow-negative":"","disabled":disabled,"placeholder":_vm.marginDefault[direction],"digits":2},model:{value:(_vm.formObject[(id + "_" + direction)]),callback:function ($$v) {_vm.$set(_vm.formObject, (id + "_" + direction), $$v)},expression:"formObject[`${id}_${direction}`]"}})],1)}),0)}):_vm._e()]}}])},_vm.formEvents),_vm._l(([
      'widget_name',
      'order_time',
      'pc_margin_top',
      'pc_margin_right',
      'pc_margin_bottom',
      'pc_margin_left',
      'mobile_margin_top',
      'mobile_margin_right',
      'mobile_margin_bottom',
      'mobile_margin_left',
      'pc_css',
      'mobile_css'
    ]),function(key){return _c('input',{key:key,attrs:{"type":"hidden","name":("product_management[" + key + "]")},domProps:{"value":_vm.formObject[key]}})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }