<template>
  <AppAjaxContent :is-loading="!data">
    <AppForm
      v-if="data"
      v-model="fit_settings"
      v-bind="fitFormProps"
      @submit="submit"
    >
      <template #group="{ id, inputId }">
        <template v-if="id === 'fit_launched_at'" class="AppForm__group-field">
          <input
            type="hidden"
            :value="fit_settings.fit_launched_at"
            name="fit_settings[fit_launched_at]"
          />
          <AppDatePicker :id="inputId" v-model="fitLaunchedAtDate" />
          <div class="FitSettingsAdmin__fit-launched-at-time">
            <AppNumberSearchable
              v-model="fitLaunchedAtHour"
              :min="0"
              :max="23"
              :placeholder="$t('app.time_hour')"
            />
            :
            <AppNumberSearchable
              v-model="fitLaunchedAtMinute"
              :min="0"
              :max="59"
              :placeholder="$t('app.time_minute')"
            />
            :
            <AppNumberSearchable
              v-model="fitLaunchedAtSecond"
              :min="0"
              :max="59"
              :placeholder="$t('app.time_second')"
            />
          </div>
        </template>
        <template v-else-if="id === 'sync_fit_size_data'">
          <div class="AppForm__group-field">
            <AppSelectFilterable
              v-model="fit_settings.source_brand_id"
              name="fit_settings[source_brand_id]"
              :options="brandOptions"
              :placeholder="$t('source_brand_id_placeholder')"
            />
          </div>
          <div class="AppForm__group-field AppForm__group-field--mt8">
            <AppButton
              :label="$t('sync_size_data_now')"
              :disabled="!fit_settings.source_brand_id"
              @click="syncSizeData"
            />
          </div>
          <div class="AppForm__group-field">
            <AppCheckbox
              v-model="fit_settings.sync_size_data_daily"
              :disabled="!fit_settings.source_brand_id"
              name="fit_settings[sync_size_data_daily]"
              :label="$t('sync_size_data_daily')"
            />
          </div>
          <div class="AppForm__group-field">
            <AppCheckbox
              v-model="fit_settings.sync_size_data_several_options"
              :disabled="!fit_settings.source_brand_id"
              name="fit_settings[sync_size_data_several_options]"
              :label="$t('sync_size_data_several_options')"
            />
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import { mapState, mapActions } from 'vuex';
import api from '@/lib/api';
import FitSettingsView from './FitSettingsView';

export default {
  name: 'FitSettingsAdmin',
  mixins: [FitSettingsView],
  data() {
    return {
      SETTINGS_ID: 'fit_settings_admin',
      FIT_SETTINGS_URL: '/fit/settings/admin'
    };
  },
  computed: {
    ...mapState('fitProduct', ['products']),
    fitLaunchedAt: {
      get() {
        return this.fit_settings.fit_launched_at
          ? moment(this.fit_settings.fit_launched_at)
          : null;
      },
      set(newValue) {
        this.fit_settings.fit_launched_at = newValue.toISOString();
      }
    },
    fitLaunchedAtDate: {
      get() {
        return this.fitLaunchedAt ? this.fitLaunchedAt.toVal() : null;
      },
      set(newValue) {
        const newDate = moment(newValue);
        this.fitLaunchedAt = this.fitLaunchedAt
          .clone()
          .year(newDate.year())
          .month(newDate.month())
          .date(newDate.date());
      }
    },
    fitLaunchedAtHour: {
      get() {
        return this.fitLaunchedAt ? this.fitLaunchedAt.hour() : 0;
      },
      set(hour) {
        if (hour === undefined || hour < 0 || hour > 23) return;
        this.fitLaunchedAt = this.fitLaunchedAt.clone().hour(hour);
      }
    },
    fitLaunchedAtMinute: {
      get() {
        return this.fitLaunchedAt ? this.fitLaunchedAt.minute() : 0;
      },
      set(minute) {
        if (minute === undefined || minute < 0 || minute > 59) return;
        this.fitLaunchedAt = this.fitLaunchedAt.clone().minute(minute);
      }
    },
    fitLaunchedAtSecond: {
      get() {
        return this.fitLaunchedAt ? this.fitLaunchedAt.second() : 0;
      },
      set(second) {
        if (second === undefined || second < 0 || second > 59) return;
        this.fitLaunchedAt = this.fitLaunchedAt.clone().second(second);
      }
    },
    groupOptions() {
      return {
        enable_combined_widget: {
          type: 'checkbox'
        },
        fit_category_ids_using_similar_products: {
          type: 'multiple_select',
          options: this.fitCategoriesOptions
        },
        fit_category_ids_using_predict_stats: {
          type: 'multiple_select',
          options: this.fitCategoriesOptions
        },
        prediction_model_version: {
          type: 'select_radio',
          options: [
            { value: 1, label: '버전 1' },
            { value: 2, label: '버전 2' },
            { value: 3, label: '버전 3' }
          ]
        },
        hide_size_widgets: {
          type: 'button',
          value: this.$t('run_hide_size_widgets_with_template'),
          clickHandler: this.hideSizeWidgetsWithTemplate
        },
        reimport_fit_size_feedbacks: {
          type: 'button',
          value: this.$t('reimport_fit_size_feedbacks'),
          clickHandler: this.reimportFitSizeFeedbacks
        }
      };
    },
    brandGroupOptions() {
      return this.data.brand_groups.map(b => ({
        label: b.name,
        value: b.id
      }));
    },
    brandOptions() {
      if (!this.data) return [];

      return [
        { label: this.$t('source_brand_id_placeholder'), value: null },
        ...this.data.brands.map(b => ({ label: b.name, value: b.id }))
      ];
    },
    fitCategoriesOptions() {
      return this.fitCategoriesOptionsWithTreeName.map(c => ({
        label: c.treeName,
        value: c.id
      }));
    },
    fitCategoriesOptionsWithTreeName() {
      const fitCategories = this.data.fit_categories;
      const fitCategoriesMap = _.keyBy(fitCategories, 'id');

      return _.chain(fitCategories)
        .map(function(fitCategory) {
          const names = [];
          let category = fitCategory;
          while (category) {
            names.unshift(category.name);
            category = fitCategoriesMap[category.parent_fit_category_id];
          }

          return { ...fitCategory, treeName: names.join(' > ') };
        })
        .sortBy('treeName')
        .value();
    }
  },
  beforeDestroy() {
    this.resetProducts();
  },
  methods: {
    ...mapActions('fitProduct', ['fetchProducts', 'resetProducts']),
    hideSizeWidgetsWithTemplate() {
      this.fetchProducts({ fit_template: 'yes' }).then(() => {
        if (
          confirm(
            this.$t('confirm.run_hide_size_widgets_with_template', {
              total_count: this.products.total_count
            })
          )
        ) {
          api.post(
            '/fit/products/hide_size_widgets',
            { fit_template: 'yes' },
            {
              successMessage: this.$t('fit_product.size_widgets_hidden')
            }
          );
        }
      });
    },
    reimportFitSizeFeedbacks() {
      api.post(
        '/fit/size_feedback/products/reimport_fit_size_feedbacks',
        {},
        {
          successMessage: this.$t('reimporting_fit_size_feedbacks_completed')
        }
      );
    },
    syncSizeData() {
      const { source_brand_id } = this.fit_settings;
      const brand_name = this.data.brands.find(b => b.id === source_brand_id)
        .name;

      if (!confirm(this.$t('sync_size_data_confirm', { brand_name }))) return;
      api
        .post(
          'fit/settings/sync_size_data',
          { source_brand_id },
          { successMessage: this.$t('sync_size_data_job_enqueued') }
        )
        .then(() => (this.orgFormObject.source_brand_id = source_brand_id));
    }
  }
};
</script>

<style scoped>
.FitSettingsAdmin__fit-launched-at-time {
  display: inline-block;
  margin-left: 8px;
}
</style>

<i18n locale="ko">
{
  "run_hide_size_widgets_with_template": "일괄 숨김 실행",
  "confirm": {
    "run_hide_size_widgets_with_template": "템플릿이 적용된 {total_count}개 상품의 실측 사이즈 위젯을 숨김처리 합니다. 숨김처리를 해제하고자 하는 경우 상품별로 해제해주셔야 합니다. 진행하시겠습니까?"
  },
  "reimport_fit_size_feedbacks": "동기화 실행",
  "reimporting_fit_size_feedbacks_completed": "사이즈 피드백 동기화를 완료했습니다.",
  "source_brand_id_placeholder": "쇼핑몰을 선택해주세요.",
  "sync_size_data_now": "지금 가져오기",
  "sync_size_data_confirm": "{brand_name}의 실측 정보를 가지고 옵니다. 계속하시겠습니까?",
  "sync_size_data_job_enqueued": "실측치수 가져오기 job이 등록되었습니다.",
  "sync_size_data_daily": "매일 새로 등록된 상품의 실측 정보를 가져옵니다.",
  "sync_size_data_several_options": "free 사이즈가 아닌 상품의 실측 사이즈 데이터를 함께 가져옵니다."
}
</i18n>
