<template>
  <AppContainer class="AnalyticsInsightKeywordsAnalysis">
    <div class="AnalyticsInsightKeywordsAnalysis__title">
      {{ $t('keywords_analysis') }}
    </div>
    <div class="AnalyticsInsightKeywordsAnalysis__toolbar">
      <div class="AnalyticsInsightKeywordsAnalysis__dropdowns">
        <AppSelect
          v-model="sortBasis"
          :options="InsightKeywordsSortBasis.options()"
          class="AnalyticsInsightKeywordsAnalysis__dropdown"
        />
        <AppSelect
          v-model="sortType"
          :options="InsightKeywordsSortType.options()"
          class="AnalyticsInsightKeywordsAnalysis__dropdown"
        />
      </div>
      <div class="AnalyticsInsightKeywordsAnalysis__checkboxes">
        <AppCheckbox
          v-model="toggleBrandsVisible"
          :label="$t('insight.brands') + ' ' + $t('app.show')"
          class="AnalyticsInsightKeywordsAnalysis__checkbox"
        />
        <AppCheckbox
          v-model="toggleKeywordSentimentsVisible"
          :label="$t('see_keyword_sentiments')"
          class="AnalyticsInsightKeywordsAnalysis__checkbox"
        />
      </div>
    </div>
    <AppChart
      :class="[
        'AnalyticsInsightKeywordsAnalysis__chart',
        `AnalyticsInsightKeywordsAnalysis__chart--${InsightKeywordsSortType.key(
          sortType
        ).toLowerCase()}`,
        {
          'AnalyticsInsightKeywordsAnalysis__chart--sentiments-invisible': !toggleKeywordSentimentsVisible
        }
      ]"
      :options="chartOptions"
      :tooltip="chartTooltip"
      @tooltip="onChartTooltip"
    />
  </AppContainer>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import InsightKeywordsView from '@/mixins/InsightKeywordsView';
import InsightKeywordsSortType from '@/enums/InsightKeywordsSortType';
import InsightKeywordsSortBasis from '@/enums/InsightKeywordsSortBasis';

export default {
  name: 'AnalyticsInsightKeywordsAnalysis',
  mixins: [InsightKeywordsView],
  props: {
    dateRange: { type: Object, required: true },
    myBrandKeywordsDetails: { type: Array, required: true },
    brandsKeywordsDetails: { type: Array, required: true }
  },
  data() {
    return {
      sortBasis: InsightKeywordsSortBasis.MY_BRAND_BASIS,
      sortType: InsightKeywordsSortType.TOTAL,
      toggleBrandsVisible: true,
      toggleKeywordSentimentsVisible: true,
      chartTooltip: null
    };
  },
  computed: {
    ...mapGetters('session', ['brandReviewTagTypes']),
    InsightKeywordsSortType() {
      return InsightKeywordsSortType;
    },
    InsightKeywordsSortBasis() {
      return InsightKeywordsSortBasis;
    },
    chartSeriesReversed() {
      return this.sortType === InsightKeywordsSortType.NEGATIVE;
    },
    chartHeight() {
      return this.brandReviewTagTypes.length * 60;
    },
    chartOptions() {
      return {
        chart: {
          height: this.chartHeight,
          spacing: [0, 0, 0, 0],
          marginRight: 1,
          type: 'bar'
        },
        exporting: false,
        title: { text: '' },
        tooltip: { enabled: false },
        xAxis: {
          categories: this.myBrandKeywordsDetailsSorted.map(
            detail => detail.review_tag_type_name
          )
        },
        yAxis: {
          labels: { enabled: true },
          title: { enabled: false }
        },
        legend: { enabled: false },
        plotOptions: {
          series: {
            animation: false,
            stacking: 'normal',
            dataLabels: {
              enabled: true,
              format: '{point.y:,.2f}%',
              allowOverlap: true,
              filter: {
                property: 'y',
                operator: '>=',
                value: this.highestSentimentsRatio * 0.06 || Infinity
              },
              y: 1
            },
            pointWidth: 20,
            pointPadding: 1,
            groupPadding: 0.1
          }
        },
        series: [
          ...this.myBrandSeries,
          ...(this.toggleBrandsVisible
            ? this.brandsSeries
            : this.brandsSeriesWithoutData)
        ]
      };
    },
    highestSentimentsRatio() {
      const keywordsDetailsCombined = _.concat(
        this.myBrandKeywordsDetails,
        this.brandsKeywordsDetails
      );
      return _.max(keywordsDetailsCombined.map(d => d.sentiments_ratio));
    },
    myBrandSeries() {
      let series =
        !this.toggleKeywordSentimentsVisible &&
        this.sortType === InsightKeywordsSortType.TOTAL
          ? [
              {
                name: this.$t('sentiments_ratio'),
                data: this.myBrandKeywordsDetailsSorted.map(
                  d => d.sentiments_ratio
                )
              },
              { data: this.emptyDataArray },
              { data: this.emptyDataArray }
            ]
          : [
              {
                name: this.$t('nsentiments_ratio'),
                data: this.myBrandKeywordsDetailsSorted.map(
                  d => d.nsentiments_ratio
                )
              },
              {
                name: this.$t('zsentiments_ratio'),
                data: this.myBrandKeywordsDetailsSorted.map(
                  d => d.zsentiments_ratio
                )
              },
              {
                name: this.$t('psentiments_ratio'),
                data: this.myBrandKeywordsDetailsSorted.map(
                  d => d.psentiments_ratio
                )
              }
            ];
      series = series.map(o => ({ ...o, stack: 'my_brand' }));
      return this.chartSeriesReversed ? series.reverse() : series;
    },
    brandsSeries() {
      let series =
        !this.toggleKeywordSentimentsVisible &&
        this.sortType === InsightKeywordsSortType.TOTAL
          ? [
              {
                name: this.$t('sentiments_ratio'),
                data: this.brandsKeywordsDetailsSorted.map(
                  d => d.sentiments_ratio
                )
              },
              { data: this.emptyDataArray },
              { data: this.emptyDataArray }
            ]
          : [
              {
                name: this.$t('nsentiments_ratio'),
                data: this.brandsKeywordsDetailsSorted.map(
                  d => d.nsentiments_ratio
                )
              },
              {
                name: this.$t('zsentiments_ratio'),
                data: this.brandsKeywordsDetailsSorted.map(
                  d => d.zsentiments_ratio
                )
              },
              {
                name: this.$t('psentiments_ratio'),
                data: this.brandsKeywordsDetailsSorted.map(
                  d => d.psentiments_ratio
                )
              }
            ];
      series = series.map(o => ({ ...o, stack: 'brands' }));
      return this.chartSeriesReversed ? series.reverse() : series;
    },
    brandsSeriesWithoutData() {
      return this.brandsSeries.map(object => ({
        ...object,
        data: this.emptyDataArray
      }));
    },
    emptyDataArray() {
      return new Array(this.brandReviewTagTypes.length).fill(0);
    },
    myBrandKeywordsDetailsSorted() {
      return _.sortBy(this.myBrandKeywordsDetails, detail =>
        _.indexOf(this.sortedReviewTagTypeIds, detail.review_tag_type_id)
      );
    },
    brandsKeywordsDetailsSorted() {
      return _.sortBy(this.brandsKeywordsDetails, detail =>
        _.indexOf(this.sortedReviewTagTypeIds, detail.review_tag_type_id)
      );
    },
    seriesPrimarySortType() {
      switch (this.sortType) {
        case InsightKeywordsSortType.TOTAL:
          return 'sentiments_ratio';
        case InsightKeywordsSortType.POSITIVE:
          return 'psentiments_ratio';
        case InsightKeywordsSortType.NEGATIVE:
        default:
          return 'nsentiments_ratio';
      }
    },
    seriesSecondarySortType() {
      switch (this.sortType) {
        case InsightKeywordsSortType.TOTAL:
          return 'psentiments_ratio';
        case InsightKeywordsSortType.POSITIVE:
        case InsightKeywordsSortType.NEGATIVE:
        default:
          return 'sentiments_ratio';
      }
    },
    sortedReviewTagTypeIds() {
      let details;
      switch (this.sortBasis) {
        case InsightKeywordsSortBasis.MY_BRAND_BASIS:
          details = this.myBrandKeywordsDetails;
          break;
        case InsightKeywordsSortBasis.BRANDS_BASIS:
          details = this.brandsKeywordsDetails;
          break;
        case InsightKeywordsSortBasis.BRANDS_COMPARISON:
        default:
          details = _.zip(
            this.myBrandKeywordsDetails,
            this.brandsKeywordsDetails
          ).map(zippedDetail => ({
            review_tag_type_id: zippedDetail[0].review_tag_type_id,
            [this.seriesPrimarySortType]:
              zippedDetail[0][this.seriesPrimarySortType] -
              zippedDetail[1][this.seriesPrimarySortType]
          }));
          break;
      }
      return _.orderBy(
        details,
        [this.seriesPrimarySortType, this.seriesSecondarySortType],
        ['desc', 'desc']
      ).map(detail => detail.review_tag_type_id);
    },
    tooltipItemHidindIndexes() {
      if (this.toggleKeywordSentimentsVisible) return [];

      // 0(긍정 키워드), 1(중립 키워드), 2(부정 키워드)
      switch (this.sortType) {
        case InsightKeywordsSortType.TOTAL:
          return [0, 1, 2];
        case InsightKeywordsSortType.POSITIVE:
          return [1, 2];
        case InsightKeywordsSortType.NEGATIVE:
        default:
          return [0, 1];
      }
    }
  },
  methods: {
    onChartTooltip(target) {
      const { start_date, end_date } = this.dateRange;
      this.chartTooltip = {
        title: `${this.formatDate(start_date)} ~ ${this.formatDate(end_date)}`,
        items: [
          ...this.myBrandItems(target),
          ...(this.toggleBrandsVisible ? this.brandsItems(target) : [])
        ],
        className: 'AnalyticsInsightKeywordsAnalysis__tooltip'
      };
    },
    myBrandItems({ index, series }) {
      const myBrandSeries = series.chart.series.slice(0, 3).reverse();
      if (this.chartSeriesReversed) myBrandSeries.reverse();

      const {
        review_tag_type_name,
        sentiments_sum,
        psentiments_count,
        zsentiments_count,
        nsentiments_count,
        sentiments_ratio,
        psentiments_ratio,
        zsentiments_ratio,
        nsentiments_ratio
      } = this.myBrandKeywordsDetailsSorted[index];
      const counts = [psentiments_count, zsentiments_count, nsentiments_count];
      const ratios = [psentiments_ratio, zsentiments_ratio, nsentiments_ratio];

      return [
        {
          className: 'AnalyticsInsightKeywordsAnalysis__tooltip-sub-title',
          name:
            review_tag_type_name +
            ' ' +
            this.$t('sentiments_ratio') +
            `(${this.$t('insight.my_brand')})`,
          value:
            `<b>${this.$t('unit.count', [sentiments_sum])}` +
            `(${this.toRatioString(sentiments_ratio)}</b>)`
        },
        ...myBrandSeries
          .map(({ name }, i) => {
            if (this.tooltipItemHidindIndexes.includes(i)) return null;
            return {
              symbol: 'square',
              name,
              value:
                `<b>${this.$t('unit.count', [counts[i]])}` +
                `(${this.toRatioString(ratios[i])})</b>`,
              colorIndex: this.chartSeriesReversed ? i : 2 - i
            };
          })
          .filter(v => v)
      ];
    },
    brandsItems({ index, series }) {
      const brandsSeries = series.chart.series.slice(3, 6).reverse();
      if (this.chartSeriesReversed) brandsSeries.reverse();

      const {
        review_tag_type_name,
        sentiments_sum,
        psentiments_count,
        zsentiments_count,
        nsentiments_count,
        sentiments_ratio,
        psentiments_ratio,
        zsentiments_ratio,
        nsentiments_ratio
      } = this.brandsKeywordsDetailsSorted[index];
      const counts = [psentiments_count, zsentiments_count, nsentiments_count];
      const ratios = [psentiments_ratio, zsentiments_ratio, nsentiments_ratio];

      return [
        {
          className: 'AnalyticsInsightKeywordsAnalysis__tooltip-sub-title',
          name:
            review_tag_type_name +
            ' ' +
            this.$t('sentiments_ratio') +
            `(${this.$t('insight.brands')})`,
          value:
            `<b>${this.$t('unit.count', [sentiments_sum])}` +
            `(${this.toRatioString(sentiments_ratio)}</b>)`
        },
        ...brandsSeries
          .map(({ name }, i) => {
            if (this.tooltipItemHidindIndexes.includes(i)) return null;
            return {
              symbol: 'square',
              name,
              value:
                `<b>${this.$t('unit.count', [counts[i]])}` +
                `(${this.toRatioString(ratios[i])})</b>`,
              colorIndex: this.chartSeriesReversed ? i + 3 : 5 - i
            };
          })
          .filter(v => v)
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightKeywordsAnalysis {
  margin-top: 24px;
}

.AnalyticsInsightKeywordsAnalysis__title {
  @include text-label;
  margin-bottom: 24px;
}

.AnalyticsInsightKeywordsAnalysis__toolbar {
  display: flex;
  flex-wrap: wrap;
}

.AnalyticsInsightKeywordsAnalysis__dropdowns {
  flex: 0 0 270px;
  margin-right: 28px;
  padding-bottom: 16px;
}

.AnalyticsInsightKeywordsAnalysis__dropdown {
  & + & {
    margin-left: 8px;
  }
}

.AnalyticsInsightKeywordsAnalysis__checkboxes {
  flex: 1 0 312px;
  text-align: right;
  padding-bottom: 16px;
}

.AnalyticsInsightKeywordsAnalysis__checkbox {
  & + & {
    margin-left: 16px;
  }
}

@mixin highcharts-color($num, $color) {
  .highcharts-color-#{$num} {
    fill: $color;
    stroke: $color;
  }
}

@mixin highcharts-text-color($num, $color) {
  .highcharts-color-#{$num} text {
    fill: $color;
  }
}

.AnalyticsInsightKeywordsAnalysis__chart {
  margin-top: 8px;

  ::v-deep {
    .highcharts-axis-labels text {
      font-size: 13px;
      fill: $color-grey-80;
    }

    .highcharts-xaxis-labels text {
      transform: translateY(-10.5px);
    }

    .highcharts-data-label {
      font-weight: 400;
    }
  }

  ::v-deep {
    @include highcharts-color(0, $color-red);
    @include highcharts-color(1, $color-grey-25);
    @include highcharts-color(2, $color-mint-green);
    @include highcharts-color(3, $color-red-light);
    @include highcharts-color(4, $color-grey-15);
    @include highcharts-color(5, $color-mint-green-light);

    @include highcharts-text-color(0, $color-grey-05);
    @include highcharts-text-color(1, $color-grey-80);
    @include highcharts-text-color(2, $color-grey-80);
    @include highcharts-text-color(3, $color-grey-75);
    @include highcharts-text-color(4, $color-grey-75);
    @include highcharts-text-color(5, $color-grey-75);
  }

  &--negative {
    ::v-deep {
      @include highcharts-color(0, $color-mint-green);
      @include highcharts-color(2, $color-red);
      @include highcharts-color(3, $color-mint-green-light);
      @include highcharts-color(5, $color-red-light);

      @include highcharts-text-color(0, $color-grey-80);
      @include highcharts-text-color(2, $color-grey-05);
    }
  }

  &--sentiments-invisible.AnalyticsInsightKeywordsAnalysis__chart--total {
    ::v-deep {
      @include highcharts-color(0, $color-grey-32);
      @include highcharts-color(3, $color-grey-15);

      @include highcharts-text-color(0, $color-grey-80);
      @include highcharts-text-color(3, $color-grey-75);
    }
  }

  &--sentiments-invisible.AnalyticsInsightKeywordsAnalysis__chart--positive {
    ::v-deep {
      @include highcharts-color(0, $color-grey-25);
      @include highcharts-color(1, $color-grey-25);
      @include highcharts-color(2, $color-mint-green);
      @include highcharts-color(3, $color-grey-15);
      @include highcharts-color(4, $color-grey-15);
      @include highcharts-color(5, $color-mint-green-light);

      @include highcharts-text-color(0, $color-grey-25);
      @include highcharts-text-color(1, $color-grey-25);
      @include highcharts-text-color(2, $color-grey-80);
      @include highcharts-text-color(3, $color-grey-15);
      @include highcharts-text-color(4, $color-grey-15);
      @include highcharts-text-color(5, $color-grey-75);
    }
  }

  &--sentiments-invisible.AnalyticsInsightKeywordsAnalysis__chart--negative {
    ::v-deep {
      @include highcharts-color(0, $color-grey-25);
      @include highcharts-color(1, $color-grey-25);
      @include highcharts-color(2, $color-red);
      @include highcharts-color(3, $color-grey-15);
      @include highcharts-color(4, $color-grey-15);
      @include highcharts-color(5, $color-red-light);

      @include highcharts-text-color(0, $color-grey-25);
      @include highcharts-text-color(1, $color-grey-25);
      @include highcharts-text-color(2, $color-grey-05);
      @include highcharts-text-color(3, $color-grey-15);
      @include highcharts-text-color(4, $color-grey-15);
      @include highcharts-text-color(5, $color-grey-75);
    }
  }
}

::v-deep {
  .AnalyticsInsightKeywordsAnalysis__tooltip-sub-title {
    td {
      @include text-caption-darker;
    }

    &:not(:first-child) {
      td {
        padding-top: 12px;
      }
    }
  }

  .AppChartTooltip__table {
    td + td {
      padding-left: 8px;
    }
  }

  .AppChartTooltip__value {
    font-weight: normal;
    @include text-caption-dark;
  }
}
</style>

<i18n locale="ko">
{
  "keywords_analysis": "경쟁 그룹 대비 키워드별 분석",
  "see_keyword_sentiments": "긍정/중립/부정 키워드 모두 보기",
  "sentiments_ratio": "언급",
  "psentiments_ratio": "긍정 키워드",
  "zsentiments_ratio": "중립 키워드",
  "nsentiments_ratio": "부정 키워드"
}
</i18n>
