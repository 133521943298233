<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Single</label>
      </div>
      <AppProductSelectButton
        title="Select Product Title"
        sub-title="Select Product Sub Title"
        :value="productSingle ? productSingle.id : null"
        @select="productSingle = $event"
      />
      <div class="ui-section__description">
        Selected Product : {{ productSingle }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Multiple</label>
      </div>
      <AppProductSelectButton
        title="Select Product Title"
        sub-title="Select Product Sub Title"
        :value="productsMultiple.map(p => p.id)"
        multiple
        @select="productsMultiple = $event"
      />
      <div class="ui-section__description">
        Selected Products : {{ productsMultiple }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiProductSelectButton',
  data() {
    return {
      productSingle: null,
      productsMultiple: []
    };
  }
};
</script>
