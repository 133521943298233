<template>
  <div class="SummaryBoxInstallComplete">
    <AppAjaxContent :is-loading="isSubmitting">
      <AppSummaryBox>
        <template #title>{{ $t('title') }}</template>
        <pre>{{ $t('content') }}</pre>
        <template #buttons>
          <AppButton
            button-style="blue-outline"
            :label="$t('test_start_button')"
            @click="clickWidgetTryout"
          />
        </template>
      </AppSummaryBox>
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import WidgetInstallStatus from '@/enums/WidgetInstallStatus';
import { mapMutations, mapActions } from 'vuex';

export default {
  name: 'SummaryBoxInstallComplete',
  data() {
    return { isSubmitting: false };
  },
  methods: {
    ...mapActions('dialog', ['confirm', 'openDialog']),
    ...mapMutations('easyJoin', ['SET_WIDGET_INSTALL_STATUS']),
    clickWidgetTryout() {
      this.openDialog('ReviewDashboardWidgetTryoutDialog');
    },
    clickInstallConfirm() {
      this.confirm({
        title: this.$t('install_confirm_title'),
        message: this.$t('install_confirm_message'),
        closeButtonLabel: this.$t('app.complete')
      }).then(result => {
        if (result) this.requestInstallConfirm();
      });
    },
    requestInstallConfirm() {
      this.isSubmitting = true;
      api
        .post('/easy_join/widget_install_confirm')
        .then(() =>
          this.SET_WIDGET_INSTALL_STATUS(WidgetInstallStatus.CONFIRMED)
        )
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "위젯 설치가 완료되었습니다. 🖥️",
  "content": "위젯 설치 테스트 체험을 진행해주세요. 테스트 체험 완료 후 크리마 리뷰를 사용 시작할 수 있습니다.",
  "test_start_button": "테스트 체험 시작",
  "install_confirm_button": "확인 완료",
  "install_confirm_title": "위젯 설치 확인을 완료하시겠습니까?",
  "install_confirm_message": "완료할 경우 확인하신 테스트 페이지에 설치된 내용대로 쇼핑몰에 적용될 예정입니다. 완료하시겠습니까?"
}
</i18n>
