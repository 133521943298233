<template>
  <AppButton
    :label="label"
    :tooltip="copied ? $t('copied') : $t('click_to_copy')"
    @click="copy"
    @mouseleave.native="copied = false"
  />
</template>

<script>
export default {
  name: 'AppButtonCopyable',
  props: {
    label: {
      type: String,
      default() {
        return this.$t('app.copy');
      }
    },
    value: { type: String, required: true }
  },
  data() {
    return { copied: false };
  },
  methods: {
    copy() {
      this.$copyText(this.value).then(() => (this.copied = true));
    }
  }
};
</script>

<i18n locale="ko">
{
  "click_to_copy": "클릭해서 클립보드에 복사하기",
  "copied": "복사완료"
}
</i18n>
