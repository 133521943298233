<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{
      ...formProps,
      objectId: 'brand_settings',
      detectFormDataChange: false,
      submitLabel: $t('pay_with_bank')
    }"
    v-on="formEvents"
  >
    <template #group="{ id, error }">
      <template v-if="id === 'packages'">
        <div v-for="p in packages" :key="p.id">
          {{ packageName(p) }}
        </div>
      </template>
      <template v-else-if="id === 'bank_account'">
        <div>{{ $t('bank_account.account_number') }}</div>
        <div class="mt4">
          <AppButtonCopyable
            :label="$t('bank_account_copy_button')"
            :value="$t('bank_account.account_number')"
          />
        </div>
      </template>
      <template v-else-if="id === 'price'">
        {{ price | formatCurrency }}
      </template>
      <template v-else-if="id === 'payment_sender_name'">
        <div class="AppForm__group-field">
          <AppTextInput
            v-model="formObject.payment_sender_name"
            name="brand_settings[payment_sender_name]"
            :invalid="!!error"
            :placeholder="$t('payment_sender_name.placeholder')"
            @change="validateField('payment_sender_name')"
          />
        </div>
        <div class="AppForm__group-field">
          <AppCheckbox
            v-model="save_payment_sender_name"
            :label="$t('save_payment_sender_name')"
          />
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ThePaymentBankFormDialog',
  mixins: [DialogFormView],
  props: {
    packages: { type: Array, required: true },
    price: { type: Number, required: true },
    dueDate: { type: Object, default: () => moment().add(3, 'days') }
  },
  data() {
    const currentBrand = this.$store.state.session.currentBrand;
    return {
      isLoading: true,
      save_payment_sender_name: false,
      orgFormObject: {
        payment_sender_name: currentBrand.payment_sender_name,
        tax_invoice_officer_email: currentBrand.tax_invoice_officer_email
      }
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('payment', ['packageName']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'packages',
              label: this.$t('packages'),
              type: 'static',
              value: this.packages
            },
            {
              id: 'price',
              label: this.$t('price')
            },
            {
              id: 'bank_account',
              label: this.$t('bank_account.label')
            },
            {
              id: 'payment_sender_name',
              type: 'text',
              label: this.$t('payment_sender_name.label'),
              placeholder: this.$t('payment_sender_name.placeholder'),
              hint: this.$t('sender_name_info'),
              validate: ['required']
            },
            {
              id: 'due_date',
              label: this.$t('due_date'),
              type: 'static',
              value: this.dueDate.format('YYYY. MM. DD').toString(),
              hint: this.$t('due_date_info')
            },
            {
              id: 'tax_invoice_officer_email',
              type: 'email',
              label: this.$t('tax_invoice_officer_email.label'),
              placeholder: this.$t('tax_invoice_officer_email.placeholder'),
              hint: this.$t('tax_invoice_officer_email.hint'),
              validate: ['required', 'email_format']
            }
          ]
        }
      ];
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;

      if (!this.save_payment_sender_name)
        formData.delete('brand_settings[payment_sender_name]');

      // 오류 처리 안하고 있음. 실패해도 무통장 입금 진행에 별 문제 없으니 이대로 둬도 괜찮을 것 같다.
      api
        .patch('/brand_settings', formData, { silent: true })
        .then(() =>
          this.eventBus.$emit('save', this.formObject.payment_sender_name)
        )
        .finally(() => {
          this.isSubmitting = false;
          this.closeDialog();
        });
    },
    close() {
      if (confirm(this.$t('confirm_not_saving_close'))) {
        this.closeDialog();
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "무통장 입금 정보",
  "bank_account_copy_button": "계좌번호 복사",
  "sender_name_info": "실제 입금자명을 입력해야 입금 확인이 가능합니다.",
  "due_date_info": "입금이 확인되면 자동으로 서비스가 활성화됩니다.",
  "packages": "구매 상품",
  "price": "총 입금 금액",
  "bank_account": {
    "label": "입금 정보",
    "account_number": "기업은행 541-030979-01-042 (주)크리마"
  },
  "payment_sender_name": {
    "label": "입금자명",
    "placeholder": "입금자명을 입력해주세요."
  },
  "save_payment_sender_name": "이 입금자명을 다음에도 사용",
  "due_date": "입금기한",
  "tax_invoice_officer_email": {
    "label": "세금계산서 담당자 이메일 주소",
    "placeholder": "이메일 주소를 입력해주세요.",
    "hint": "세금계산서 담당자 이메일 주소가 없는 경우 세금계산서 발행이 되지 않습니다."
  },
  "pay_with_bank": "무통장 입금 신청",
  "confirm_not_saving_close": "무통장 입금이 신청되지 않았습니다. 신청을 취소하시겠습니까?"
}
</i18n>
