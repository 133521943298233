<template>
  <AppSearchbar>
    <template #default>
      <li>
        <AppSelectButton
          :value="filterValue"
          :options="reportsFilterOptions"
          @change="changeFilter"
        />
      </li>
    </template>
    <template #right>
      <li>
        <AppSelectButtonInterval :value="interval" @change="changeInterval" />
      </li>
      <li>
        <AppDateRangePicker
          v-model="dateRange"
          :presets="['last_7days', 'last_30days', 'last_90days']"
          @update="changeDateRange"
        />
      </li>
    </template>
    <template v-if="filterValue === 'custom'" #advanced-search>
      <AppEventReportsFilter
        :event-filters="eventFilters"
        @fetch-result="fetchResult"
      />
    </template>
  </AppSearchbar>
</template>

<script>
export default {
  name: 'AppEventReportsSearchbar',
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    interval: { type: String, default: 'daily' },
    reportsFilterOptions: { type: Array, default: () => [] },
    filterValue: { type: String, default: null },
    eventType: { type: String, default: null },
    aggregateMethod: { type: String, default: null },
    aggregateColumn: { type: String, default: null },
    columnFilters: { type: Array, default: () => [] }
  },
  data() {
    return {
      dateRange: {
        start_date: this.startDate,
        end_date: this.endDate
      },
      eventFilters: {
        event_type: this.eventType,
        aggregate_method: this.aggregateMethod,
        aggregate_column: this.aggregateColumn,
        column_filters: this.columnFilters
      }
    };
  },
  methods: {
    changeDateRange(dateRange) {
      this.$emit('change-date-range', dateRange);
    },
    changeInterval(interval) {
      this.$emit('change-interval', interval);
    },
    changeFilter(filter) {
      this.$emit('change-filter', filter);
    },
    fetchResult(params) {
      this.$emit('fetch-result', params);
    }
  }
};
</script>
