import { constructEnum } from '@/lib/enum';

export default constructEnum('SmartInstallationType', {
  ATTACH_WIDGET: 10,
  HIDE_ELEMENT: 20,
  REPLACE_REVIEWS_COUNT: 30,
  NEW_REVIEW_LINK: 40,
  PRODUCT_REVIEWS_LINK: 50,
  MY_PENDING_REVIEWS_COUNT: 60,
  MY_WRITTEN_REVIEWS_COUNT: 70
});
