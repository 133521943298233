<template>
  <div>
    <div class="table-head-control">
      <AppButton
        :label="$t('add_button')"
        :disabled="!unselectedUserGradeIds.length"
        @click="add"
      />
    </div>
    <AppTable
      table-id="user-grade-bonus-table"
      :columns="COLUMNS"
      :rows="currentBonusList"
      :no-data="$t('no_data')"
    >
      <template #cell="{ row, column, rowIndex }">
        <template v-if="column.id === 'user_grade'">
          <AppSelect
            v-model="currentBonusList[rowIndex].id"
            :options="userGradesOptionsWithId(row.id)"
          />
        </template>
        <template v-else-if="column.id === 'amount'">
          <AppCurrencyInput
            v-model="currentBonusList[rowIndex].amount"
            :invalid="!!errors[`auto_mileage_user_grade_bonuses[${row.id}]`]"
            :digits="6"
            :default="0"
            @blur="validateField(row.id)"
            @change="validateField(row.id)"
          />
          <AppFormError
            :error="errors[`auto_mileage_user_grade_bonuses[${row.id}]`]"
          />
        </template>
        <template v-else-if="column.id === 'delete'">
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            @click="$delete(currentBonusList, rowIndex)"
          />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ReviewSettingsMileageAmountUserGradeBonusTable',
  model: { prop: 'bonusMap', event: 'change' },
  props: {
    bonusMap: { type: Object, default: () => ({}) },
    userGrades: { type: Array, required: true },
    errors: { type: Object, required: true }
  },
  data() {
    return {
      COLUMNS: [
        {
          id: 'user_grade',
          label: this.$t('table_head.user_grade'),
          align: 'left'
        },
        { id: 'amount', label: this.$t('table_head.amount'), align: 'left' },
        { id: 'delete', label: this.$t('table_head.delete') }
      ],
      currentBonusList: _.map(this.bonusMap, (amount, id) => ({ id, amount }))
    };
  },
  computed: {
    userGradesOptions() {
      return this.userGrades.map(b => ({ label: b.name, value: b.id }));
    },
    unselectedUserGradeIds() {
      return _.difference(
        this.userGrades.map(g => g.id),
        this.currentBonusList.map(b => b.id)
      );
    }
  },
  watch: {
    currentBonusList: {
      handler(value) {
        const newBonusMap = _.fromPairs(value.map(e => [e.id, e.amount]));
        this.$emit('change', newBonusMap);
      },
      deep: true
    }
  },
  methods: {
    userGradesOptionsWithId(id) {
      return this.userGradesOptions.map(option => ({
        ...option,
        disabled:
          option.value !== id &&
          !this.unselectedUserGradeIds.includes(option.value)
      }));
    },
    add() {
      this.currentBonusList.push({
        id: this.unselectedUserGradeIds[0],
        amount: 0
      });
    },
    change({ key }, value) {
      this.$emit('change', { key, value });
      this.validateField(key);
    },
    validateField(key) {
      this.$emit('validate-field', `auto_mileage_user_grade_bonuses[${key}]`);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .user-grade-bonus-table__amount {
    width: 40%;
  }

  .user-grade-bonus-table__delete {
    width: 76px;
  }
}
</style>

<i18n locale="ko">
{
  "add_button": "+ 회원 등급 기준 조건 추가",
  "table_head": {
    "user_grade": "회원 등급",
    "amount": "추가 지급액",
    "delete": "조건 삭제"
  },
  "no_data": "추가한 기준이 없습니다."
}
</i18n>
