<template>
  <AppModeless align="center" class="TheNotificationPopup" @close="close">
    <div class="TheNotificationPopup__head">
      {{
        currentUser.unchecked_notifications_count
          ? $t('title_unchecked_notifications', {
              count: currentUser.unchecked_notifications_count
            })
          : $t('app.notification')
      }}
    </div>
    <div class="TheNotificationPopup__body">
      <Transition name="fade" mode="out-in">
        <div v-if="isReady" key="content">
          <div v-if="notifications.length">
            <ul class="TheNotificationPopup__notifications">
              <li
                v-for="notification in notifications"
                :key="notification.id"
                class="TheNotificationPopup__notification"
              >
                <NotificationItem :notification="notification" @close="close" />
              </li>
            </ul>
          </div>
          <div v-else class="TheNotificationPopup__no-data">
            {{ $t('no_data') }}
          </div>
        </div>
        <div v-else key="spinner" class="TheNotificationPopup__loading">
          <AppSpinner />
        </div>
      </Transition>
    </div>
    <div class="TheNotificationPopup__foot">
      <button class="TheNotificationPopup__close-button" @click="close">
        {{ $t('app.close') }}
      </button>
    </div>
  </AppModeless>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import NotificationItem from './TheNotificationPopup/NotificationItem';

export default {
  name: 'TheNotificationPopup',
  components: { NotificationItem },
  data() {
    return {
      isReady: false,
      width: 320
    };
  },
  computed: {
    ...mapState('session', ['currentUser']),
    ...mapState('notification', ['notifications']),
    ...mapGetters('request', ['isRequestPending']),
    isLoading() {
      return this.isRequestPending('FETCH_NOTIFICATIONS');
    }
  },
  watch: {
    isLoading() {
      if (!this.isLoading) this.isReady = true;
    }
  },
  mounted() {
    this.isReady = false;
    this.fetchNotifications();
  },
  beforeDestroy() {
    this.resetNotifications();
  },
  methods: {
    ...mapActions('notification', ['fetchNotifications', 'resetNotifications']),
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.TheNotificationPopup {
  width: 320px;
  margin-top: 12px;
}

.TheNotificationPopup__head {
  @include text-content;
  padding: 15px;
}

.TheNotificationPopup__notifications {
  margin-bottom: 14px;
}

.TheNotificationPopup__notification {
  padding: 0 7px;

  & + .TheNotificationPopup__notification {
    margin-top: 8px;
  }
}

.TheNotificationPopup__no-data {
  @include text-content;
  font-weight: bold;
  text-align: center;
  padding-top: 17px;
  padding-bottom: 41px;
}

.TheNotificationPopup__close-button {
  width: 100%;
  padding: 12px 0;
  border-top: 1px solid $color-layout-section;
}

.TheNotificationPopup__loading {
  text-align: center;
  font-size: 24px;
  padding-top: 17px;
  padding-bottom: 37px;
}

.TheNotificationPopup__body {
  max-height: 358px;
  overflow-y: auto;
}
</style>

<i18n locale="ko">
{
  "title_unchecked_notifications": "확인하지 않은 알림 ({count})",
  "no_data": "알림이 없습니다."
}
</i18n>
<i18n locale="en">
{
  "title_unchecked_notifications": "Unchecked Notifications ({count})",
  "no_data": "No notifications"
}
</i18n>
