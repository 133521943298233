<template>
  <div>
    <div v-if="fitProduct" v-tooltip="tooltip">
      <div class="FitSizeProductsDataInsertedAtCell__date">
        {{ fitProduct.data_inserted_at | formatDate }}
      </div>
      <div class="FitSizeProductsDataInsertedAtCell__detail">
        <AppBadge :label="$t('app.detail_info')" />
      </div>
    </div>
    <span v-else>-</span>
  </div>
</template>

<script>
export default {
  name: 'FitSizeProductsDataInsertedAtCell',
  props: {
    fitProduct: {
      type: Object,
      default: null
    }
  },
  computed: {
    tooltip() {
      const { formatDateTime } = this.$options.filters;
      return [
        `${this.$t('fit_product.fit_data_inserted_at')}: ${formatDateTime(
          this.fitProduct.data_inserted_at
        )}`,
        `Email: ${this.fitProduct.author_email}`,
        `IP: ${this.fitProduct.author_ip}`
      ].join('\n');
    }
  }
};
</script>

<style scoped>
.FitSizeProductsDataInsertedAtCell__date {
  line-height: 20px;
}

.FitSizeProductsDataInsertedAtCell__detail {
  line-height: 20px;
  margin-top: 4px;
}
</style>
