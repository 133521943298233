const state = { productManagement: {} };

const mutations = {
  SET_PRODUCT_MANAGEMENT(state, productManagement) {
    state.productManagement = productManagement;
  },
  UPDATE_PRODUCT_MANAGEMENT(state, productManagement) {
    state.productManagement = {
      ...state.productManagement,
      ...productManagement
    };
  }
};

export default { namespaced: true, state, mutations };
