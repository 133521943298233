import isEqual from 'lodash/isEqual';
import { trimParams, isHashChange } from '@/lib/params';
import ResourceParams from '@/mixins/ResourceParams';

export default {
  mixins: [ResourceParams],
  created() {
    this.setOrgResourceParams({
      ...this.defaultResourceParams,
      ...this.$route.query
    });
    this.fetchResource(this.resourceParams);
  },
  watch: {
    $route(to, from) {
      if (isHashChange(to, from)) return;

      const newParams = trimParams(to.query, this.defaultResourceParams);
      if (!isEqual(newParams, to.query)) {
        this.$router.replace({ query: newParams });
        return;
      }

      this.setOrgResourceParams({
        ...this.defaultResourceParams,
        ...to.query
      });
      this.fetchResource(this.resourceParams);
    }
  },
  methods: {
    refreshResourceWithParams(params) {
      const oldParams = this.$route.query;
      const newParams = trimParams(
        { ...this.$store.state.brandParams, ...params },
        this.defaultResourceParams
      );
      this.convertParams(oldParams, newParams);
      if (isEqual(oldParams, newParams)) this.fetchResource(params);
      else this.$router.push({ query: newParams });
    },
    convertParams(_oldParams, _newParams) {}
  }
};
