<template>
  <div class="FitReportsReturnRates">
    <AppReportsSearchbar
      v-bind="searchBarProps"
      :enable-advanced-search="true"
      :enable-interval-search="false"
      :resource-params="resourceParams"
      :products="products"
      :min-date="minDate"
      v-on="searchBarEvents"
      @submit="searchResource"
    />
    <AppChart
      :options="chartOptions"
      :tooltip="chartTooltip"
      @tooltip="onChartTooltip"
    />
    <AppResourceTable
      :rows="rows"
      table-style="stats"
      :columns="COLUMNS"
      :resources="resources"
      :enable-total-count="false"
      @paginate="paginate"
    />
  </div>
</template>

<script>
import ReportsView from '@/mixins/ReportsView';
import { mapState } from 'vuex';

export default {
  name: 'FitReportsReturnRates',
  mixins: [ReportsView],
  props: {
    products: {
      type: Array,
      default() {
        return [];
      }
    },
    minDate: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'date', label: this.$t('app.date'), type: 'pre' },
        {
          id: 'subOrdersCount',
          label: this.$t('sub_orders_count'),
          type: 'number',
          default: '-',
          align: 'right'
        },
        {
          id: 'returnedSubOrdersCount',
          label: this.$t('returned_sub_orders_count'),
          type: 'number',
          default: '-',
          align: 'right'
        },
        {
          id: 'averageReturnPercentage',
          label: this.$t('avg_return_percentage'),
          type: 'percentage'
        }
      ],
      showDetail: false,
      chartTooltip: null
    };
  },
  computed: {
    ...mapState('session', ['fitSettings']),
    rows() {
      return this.reportsTable.map(fitStatistic => {
        const date = this.getFormattedDate(fitStatistic.date);
        return {
          date,
          subOrdersCount: fitStatistic.sub_orders_count,
          returnedSubOrdersCount: fitStatistic.returned_sub_orders_count,
          averageReturnPercentage: fitStatistic.avg_return_rate * 100
        };
      });
    },
    series() {
      return [
        {
          pointInterval: this.pointInterval,
          pointStart: this.pointStart,
          data: this.chartData('avg_return_rate', 'percentage'),
          name: this.$t('avg_return_percentage'),
          type: 'line',
          marker: {
            symbol: 'circle'
          }
        }
      ];
    },
    chartOptions() {
      const result = {
        title: {
          text: this.$t('return_rate_title')
        },
        xAxis: { type: 'datetime' },
        yAxis: [
          {
            title: {
              text: 'Percent ( % )'
            },
            labels: {
              format: '{value} %'
            },
            opposite: true
          }
        ],
        tooltip: {
          enabled: false
        },
        plotOptions: {
          line: {
            dataLabels: {
              format: '{y} %'
            },
            enableMouseTracking: true
          }
        },
        series: this.series
      };

      return result;
    }
  },
  methods: {
    onChartTooltip({ x, index, series }) {
      this.chartTooltip = {
        title: this.getFormattedDate(new Date(x)),
        items: series.chart.series.map(({ data, name }) => ({
          symbol: 'circle',
          name,
          value: data[index].y,
          unit: 'percentage',
          precision: 2
        }))
      };
    }
  }
};
</script>

<i18n locale="ko">
{
  "return_rate_title": "월별 반품률",
  "sub_orders_count": "주문 상품수",
  "returned_sub_orders_count": "반품/교환된 주문 상품수",
  "avg_return_percentage": "반품률",
  "using_fit_avg_return_percentage": "핏 사용 쇼핑몰 반품률",
  "not_using_fit_avg_return_percentage": "핏 미사용 쇼핑몰 반품률"
}
</i18n>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

@mixin highcharts-color($num, $color) {
  .highcharts-color-#{$num} {
    fill: $color;
    stroke: $color;
  }
}

::v-deep {
  @include highcharts-color(1, #90ed7d);
  @include highcharts-color(2, #434348);

  .not-using-fit-only {
    @include highcharts-color(1, #434348);
  }
}
</style>
