<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="cornerName || $t('sub_title.CATEGORY')"
    width="middle"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      formStyle: 'narrow',
      objectId: 'product_display',
      focusGroupId: 'name'
    }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="product_display[page_type]"
      :value="cornerPageType"
    />
    <input
      type="hidden"
      name="product_display[corner_ids][]"
      :value="cornerId"
    />
  </AppModalForm>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'DisplayProductDisplayCreateDialog',
  mixins: [DialogFormView],
  props: {
    cornerId: { type: Number, default: null },
    cornerName: { type: String, default: null }
  },
  data() {
    return {
      isLoading: true,
      orgFormObject: { name: '', apply_child_categories: false },
      existingNames: []
    };
  },
  computed: {
    ...mapState('displayProductDisplays', ['cornerPageType']),
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              label: this.$t('form.product_display_name.label'),
              type: 'text',
              validate: [
                'required',
                { rule: 'max_length', limit: 30 },
                {
                  rule: v => !this.existingNames.includes(v.trim()),
                  errorMessage: this.$t(
                    'errors.duplicated_product_display_name'
                  )
                }
              ],
              hint:
                this.isCategoryPage && !this.cornerId
                  ? this.$t('form.product_display_name.description')
                  : ''
            },
            ...(this.isCategoryPage && this.cornerId
              ? [
                  {
                    id: 'apply_child_categories',
                    label: '',
                    type: 'select_radio',
                    options: [false, true].map(value => ({
                      value,
                      label: this.$t(`form.apply_child_categories.${value}`)
                    }))
                  }
                ]
              : [])
          ]
        }
      ];
    },
    defaultName() {
      if (this.cornerName) return this.cornerName;
      else return this.$t('form.default_name.CATEGORY');
    }
  },
  mounted() {
    const { cornerPageType, defaultName } = this;

    api
      .get('display/product_displays/new', {
        params: { page_type: cornerPageType, default_name: defaultName }
      })
      .then(({ data }) => {
        this.formObject.name = data.default_name;
        this.existingNames = data.created_display_names;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('displayProductDisplays', ['ADD_PRODUCT_DISPLAY']),
    ...mapMutations('displayProductDisplay', ['UPDATE_IS_STATUS_UPDATED']),
    submit(formData) {
      if (this.isCategoryPage && !this.cornerId)
        this.openDialog([
          'DisplayProductDisplaySelectCategoryCornersDialog'
        ]).then(eventBus => {
          eventBus.$on('submit', cornerCodes => {
            cornerCodes.forEach(code =>
              formData.append('product_display[corner_codes][]', code)
            );
            this.createProductDisplay(formData);
            eventBus.$emit('close-dialog', true);
          });
          eventBus.$on('close', () => this.close(true));
        });
      else this.createProductDisplay(formData);
    },
    createProductDisplay(formData) {
      this.isSubmitting = true;
      api
        .post('display/product_displays', formData)
        .then(({ data }) => {
          this.close(true);

          const { product_display } = data;
          this.ADD_PRODUCT_DISPLAY(product_display);
          this.UPDATE_IS_STATUS_UPDATED(true);
          this.openDialog([
            'DisplayProductDisplayPreviewDialog',
            {
              productDisplayId: product_display.id,
              cornerPageType: this.cornerPageType
            }
          ]);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "진열 설정 추가",
  "sub_title": {
    "CATEGORY": "카테고리 페이지 진열"
  },
  "form": {
    "product_display_name": {
      "label": "진열 설정 이름",
      "description": "저장 후 다음 페이지에서 대상 카테고리를 선택할 수 있습니다."
    },
    "default_name": {
      "CATEGORY": "카테고리 진열 설정"
    },
    "apply_child_categories": {
      "false": "이 카테고리에만 적용",
      "true": "이 카테고리 및 이 카테고리의 모든 하위 카테고리에 적용"
    }
  },
  "errors": {
    "duplicated_product_display_name": "이미 사용중인 진열 설정 이름입니다."
  }
}
</i18n>
