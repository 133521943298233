<template>
  <AppModalForm
    :title="$t('title')"
    width="middle"
    :form-props="{
      ...formProps,
      submitLabel: $t('issue'),
      detectFormDataChange: false
    }"
    v-on="formEvents"
  />
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'TheSettingsDialogShopBuilderApiKeyFormDialog',
  mixins: [DialogFormView],
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              type: 'static',
              value: this.$t('name'),
              label: this.$t('name_label')
            },
            {
              id: 'ability',
              type: 'infobox',
              label: this.$t('ability.label'),
              value: this.$t('ability.info_box'),
              groupDescription: this.$t('ability.description')
            }
          ]
        }
      ];
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/user/api_key/create_shop_builder_key', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.eventBus.$emit('update', data.api_key);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "동기화용 API키 발급",
  "name_label": "이름",
  "name": "호스팅사 동기화용 API키",
  "ability": {
    "label": "권한 설정",
    "info_box": "⚠️  이 키는 모든 크리마 정보를 읽기, 쓰기, 삭제할 수 있습니다.<br />호스팅사 동기화를 위한 목적으로만 사용합니다.",
    "description": "CREMA 서버에 등록된 정보를 항목 별로 읽고 쓰기 권한을 설정할 수 있습니다."
  },
  "issue": "API키 발급"
}
</i18n>
