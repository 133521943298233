import DialogSize from '@/enums/DialogSize';

export default {
  props: {
    width: {
      type: String,
      default: DialogSize.DEFAULT
    }
  },
  computed: {
    currentWidth() {
      switch (this.width) {
        case DialogSize.DEFAULT:
          return '680px';
        case DialogSize.SMALL:
          return '440px';
        case DialogSize.MIDDLE:
          return '760px';
        case DialogSize.WIDE:
          return '1000px';
        case DialogSize.AUTO:
          return '';
        default:
          return this.width;
      }
    }
  }
};
