import { constructEnum } from '@/lib/enum';

const DisplayProductManagementDispatchType = constructEnum(
  'DisplayProductManagementDispatchType',
  {
    NORMAL: 0,
    IMMEDIATE: 100,
    CONFIRM: 200
  }
);

DisplayProductManagementDispatchType.isNormal = function(dispatch_type) {
  return DisplayProductManagementDispatchType.NORMAL === dispatch_type;
};

export default DisplayProductManagementDispatchType;
