var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showable)?_c('div',{class:_vm.className},[_c('AppCheckbox',{attrs:{"name":("widget[" + (_vm.isDevicePc
          ? 'enable_pagination_scroll'
          : 'enable_pagination_scroll_for_mobile') + "]"),"checked":_vm.widget[
        _vm.isDevicePc
          ? 'enable_pagination_scroll'
          : 'enable_pagination_scroll_for_mobile'
      ],"label":_vm.$t('enable_pagination_scroll'),"disabled":_vm.disabled,"tooltip":_vm.tooltip},on:{"change":function($event){_vm.widget[
        _vm.isDevicePc
          ? 'enable_pagination_scroll'
          : 'enable_pagination_scroll_for_mobile'
      ] = $event}},scopedSlots:_vm._u([(_vm.$scopedSlots.label)?{key:"label",fn:function(){return [_vm._t("label")]},proxy:true}:null,{key:"sub-item",fn:function(enablePaginationScrollSubItem){return [_c('div',{staticClass:"AppForm__sub-group"},[_c('div',{staticClass:"AppForm__sub-group-title"},[_vm._v(" "+_vm._s(_vm.$t('fixed_top_menu_height'))+" ")]),_c('AppNumberInput',{attrs:{"default":0,"digits":4,"disabled":_vm.disabled || !enablePaginationScrollSubItem.checked,"name":("widget[" + (_vm.isDevicePc
                ? 'fixed_top_menu_height'
                : 'fixed_top_menu_height_for_mobile') + "]")},model:{value:(
            _vm.widget[
              _vm.isDevicePc
                ? 'fixed_top_menu_height'
                : 'fixed_top_menu_height_for_mobile'
            ]
          ),callback:function ($$v) {_vm.$set(_vm.widget, 
              _vm.isDevicePc
                ? 'fixed_top_menu_height'
                : 'fixed_top_menu_height_for_mobile'
            , $$v)},expression:"\n            widget[\n              isDevicePc\n                ? 'fixed_top_menu_height'\n                : 'fixed_top_menu_height_for_mobile'\n            ]\n          "}}),_c('div',{staticClass:"AppForm__sub-group-hint"},[_vm._v(" "+_vm._s(_vm.$t('fixed_top_menu_height_hint'))+" ")])],1)]}}],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }