var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.badgeTooltip),expression:"badgeTooltip"}],class:[
    'AppBadge',
    ("AppBadge--" + _vm.badgeStyle),
    _vm.badgeSize ? ("AppBadge--" + _vm.badgeSize) : '',
    { 'AppBadge--spinner': _vm.spinner },
    { 'AppBadge--clickable': _vm.clickable },
    { 'AppBadge--with-max-width': _vm.withMaxWidth }
  ],on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.spinner)?_c('AppSpinner',{staticClass:"AppBadge__spinner"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }