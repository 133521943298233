<template>
  <div>
    <div class="table-line">
      {{ review.user_name }}
    </div>
    <div class="table-line table-line--light">
      {{ review.user_username }}
    </div>
    <div v-if="phone" class="table-line table-line--light">
      {{ phone | formatKoreanPhone }}
    </div>
    <div v-if="review.brand_user_grade_name" class="table-line">
      {{ review.brand_user_grade_name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewReviewsCustomerCell',
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  computed: {
    phone() {
      return this.review.order ? this.review.order.phone : null;
    }
  }
};
</script>
