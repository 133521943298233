<template>
  <AppModalDefault
    :title="$t('menu.review_settings_comment_preset_dialog')"
    width="middle"
    @close="close"
  >
    <template #body>
      <AppTabs
        v-model="activeTab"
        :tabs="childTabItems('review_settings_comment_preset_dialog')"
      />
      <AppContainer class="ReviewSettingsCommentPresetDialog__description">
        <AppDataList
          :data="
            activePresetType === 'smart'
              ? [
                  { value: $t('smart.comment_description') },
                  {
                    label: $t('smart.auto_conversion_rule'),
                    value: $t(
                      isShopifyBrand
                        ? 'smart.auto_conversion_rule_content_shopify'
                        : 'smart.auto_conversion_rule_content',
                      {
                        user: '%{user}',
                        grade: '%{grade}',
                        mileage: '%{mileage}',
                        type: '%{type}'
                      }
                    ),
                    type: 'pre'
                  },
                  {
                    label: $t('smart.example'),
                    value: $t(
                      isShopifyBrand
                        ? 'smart.example_content_html_shopify'
                        : 'smart.example_content_html',
                      {
                        user: '%{user}',
                        grade: '%{grade}',
                        mileage: '%{mileage}',
                        type: '%{type}',
                        sampleUserDisplayName
                      }
                    ),
                    type: 'html'
                  }
                ]
              : [{ value: $t('no_interpolation.comment_description') }]
          "
        />
      </AppContainer>
      <AppSearchbar>
        <li>
          <AppButton
            button-style="red"
            :label="$t(`${activePresetType}.add_comment_preset`)"
            @click="addCommentPreset"
          />
        </li>
      </AppSearchbar>
      <AppAjaxContent :is-loading="isLoading">
        <AppTable
          table-id="review-comment-presets-table"
          :columns="COLUMNS"
          :rows="rows"
        >
          <template #cell="{ column, row, rowIndex }">
            <template v-if="column.id === 'message'">
              <AppTextarea
                v-if="row.isEditing"
                :ref="`textInput${rowIndex}`"
                v-model="rows[rowIndex].message"
                :placeholder="
                  $t(`${activePresetType}.comment_preset_placeholder`)
                "
                :disabled="row.isSaving || row.isDeleting"
              />
            </template>
            <template v-else-if="column.id === 'actions'">
              <AppButton
                v-if="row.isEditing"
                :disabled="row.isSaving || row.isCanceling"
                :label="$t('app.cancel')"
                @click="cancelCommentPreset(rowIndex)"
              />
              <AppButton
                v-else
                :disabled="row.isSaving || row.isDeleting"
                :label="$t('app.edit')"
                @click="editCommentPreset(rowIndex)"
              />
              <AppButton
                v-if="row.isEditing"
                button-style="blue"
                :disabled="row.isSaving || row.isCanceling"
                :label="row.isSaving ? $t('app.saving') : $t('app.save')"
                @click="saveCommentPreset(rowIndex)"
              />
              <AppButton
                v-else
                button-style="red-outline"
                :disabled="row.isSaving || row.isDeleting"
                :label="row.isDeleting ? $t('app.deleting') : $t('app.delete')"
                @click="deleteCommentPreset(rowIndex)"
              />
            </template>
          </template>
        </AppTable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewSettingsCommentPresetDialog',
  mixins: [DialogFormView],
  props: {
    initialTab: {
      type: String,
      default: 'review_settings_comment_preset_dialog_smart'
    }
  },
  data() {
    return {
      COLUMNS: [
        {
          id: 'message',
          type: 'pre',
          label: this.$t('comment_preset_message'),
          align: 'left'
        },
        {
          id: 'actions',
          label: this.$t('app.actions')
        }
      ],
      activeTab: this.initialTab,
      isLoading: true,
      commentPresets: [],
      sampleUserDisplayName: ''
    };
  },
  computed: {
    ...mapGetters('menu', ['childTabItems']),
    ...mapGetters('session', ['isShopifyBrand']),
    rows() {
      return this.commentPresets
        .filter(p => p.preset_type === this.activePresetType)
        .reverse();
    },
    isFormDataChanged() {
      return this.commentPresets.some(p => p.isEditing);
    },
    activePresetType() {
      return this.activeTab === 'review_settings_comment_preset_dialog_smart'
        ? 'smart'
        : 'no_interpolation';
    }
  },
  mounted() {
    this.isLoading = true;
    api
      .get('/review/comment_presets')
      .then(({ data }) => {
        this.commentPresets = data.comment_presets;
        this.sampleUserDisplayName = data.sample_user_display_name;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    focusPresetRow(rowIndex) {
      this.$refs[`textInput${rowIndex}`].focus();
    },
    addCommentPreset() {
      const rowIndex = this.rows.findIndex(p => !p.id);
      if (rowIndex >= 0) {
        this.focusPresetRow(rowIndex);
        return;
      }

      this.commentPresets.push({
        preset_type: this.activePresetType,
        isEditing: true
      });
      this.$nextTick(() => this.focusPresetRow(0));
    },
    editCommentPreset(rowIndex) {
      const preset = this.rows[rowIndex];
      this.$set(preset, 'isEditing', true);
      this.$set(preset, 'originalMessage', preset.message);
      this.$nextTick(() => this.focusPresetRow(rowIndex));
    },
    cancelCommentPreset(rowIndex) {
      const preset = this.rows[rowIndex];
      this.$set(preset, 'isCanceling', true);

      if (preset.id) this.cancelEditCommentPreset(preset);
      else this.cancelCreateCommentPreset(preset);
    },
    cancelEditCommentPreset(preset) {
      if (preset.message !== preset.originalMessage) {
        this.showCancelConfirm(preset);
      } else {
        this.rollbackCommentPreset(preset);
      }
    },
    cancelCreateCommentPreset(preset) {
      if (preset.message) this.showCancelConfirm(preset);
      else this.removeCommentPresetFromTable(preset);
    },
    rollbackCommentPreset(preset) {
      preset.message = preset.originalMessage;
      this.$delete(preset, 'isEditing');
      this.$delete(preset, 'isCanceling');
    },
    saveCommentPreset(rowIndex) {
      const preset = this.rows[rowIndex];
      if (!_.trim(preset.message)) {
        alert(this.$t('message_is_required'));
        return;
      }

      this.$set(preset, 'isSaving', true);
      if (!preset.id) {
        api
          .post('review/comment_presets', {
            message: preset.message,
            preset_type: preset.preset_type
          })
          .then(({ data }) => {
            this.$set(preset, 'id', data.id);
            this.$delete(preset, 'isEditing');
          })
          .finally(() => {
            this.$delete(preset, 'isSaving');
          });
      } else {
        api
          .patch(`/review/comment_presets/${preset.id}`, {
            message: preset.message
          })
          .then(() => this.$delete(preset, 'isEditing'))
          .finally(() => this.$delete(preset, 'isSaving'));
      }
    },
    deleteCommentPreset(rowIndex) {
      const preset = this.rows[rowIndex];
      if (preset.id && !confirm(this.$t('app.delete_confirm'))) return;

      if (!preset.id) {
        this.removeCommentPresetFromTable(preset);
        return;
      }

      this.$set(preset, 'isDeleting', true);
      api
        .delete(`/review/comment_presets/${preset.id}`)
        .then(() => this.removeCommentPresetFromTable(preset))
        .catch(() => this.$delete(preset, 'isDeleting'));
    },
    removeCommentPresetFromTable(preset) {
      const index = this.commentPresets.findIndex(p => p === preset);
      this.commentPresets.splice(index, 1);
    },
    showCancelConfirm(preset) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          width: '400px',
          title: preset.id
            ? this.$t('cancel_confirm.edit_title')
            : this.$t('cancel_confirm.create_title'),
          markdownText: preset.id
            ? this.$t('cancel_confirm.edit_content')
            : this.$t('cancel_confirm.create_content'),
          closeButtonLabel: this.$t('cancel_confirm.close_button_label'),
          cancelButtonLabel: this.$t('cancel_confirm.cancel_button_label')
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => {
          if (preset.id) this.rollbackCommentPreset(preset);
          else this.removeCommentPresetFromTable(preset);
        });
        eventBus.$on('cancel', () => {
          this.$delete(preset, 'isCanceling');
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewSettingsCommentPresetDialog__description {
  margin-top: 24px;
}

::v-deep .review-comment-presets-table__actions {
  width: 158px;
}
</style>

<i18n locale="ko">
{
  "smart": {
    "comment_description": "일괄 댓글달기 기능 사용 시, 미리 입력된 스마트 댓글 템플릿 중 하나가 랜덤하게 등록됩니다.",
    "auto_conversion_rule": "자동 변환 규칙",
    "auto_conversion_rule_content": "고객명: {user}\n고객 등급: {grade}\n지급 적립금: {mileage}\n리뷰 유형 (동영상/포토/텍스트): {type}",
    "auto_conversion_rule_content_shopify": "고객명: {user}\n지급 적립금: {mileage}\n리뷰 유형 (동영상/포토/텍스트): {type}",
    "example": "적용 예시",
    "example_content_html": "<b>{grade}</b>이신 <b>{user}</b>고객님, 저희 쇼핑몰에 <b>{type}</b>를 작성해주셔서 감사합니다. <b>{mileage}</b>원을 지급해드렸습니다.<br />-> <b>VIP</b>이신 <b>{sampleUserDisplayName}</b>고객님, 저희 쇼핑몰에 <b>포토리뷰</b>를 작성해주셔서 감사합니다. <b>3,000원</b>을 지급해드렸습니다.",
    "example_content_html_shopify": "<b>{user}</b>고객님, 저희 쇼핑몰에 <b>{type}</b>를 작성해주셔서 감사합니다. <b>{mileage}</b>원을 지급해드렸습니다.<br />-> <b>{sampleUserDisplayName}</b>고객님, 저희 쇼핑몰에 <b>포토리뷰</b>를 작성해주셔서 감사합니다. <b>3,000원</b>을 지급해드렸습니다.",
    "add_comment_preset": "템플릿 추가",
    "comment_preset_placeholder": "템플릿으로 등록할 문구를 입력해주세요."
  },
  "no_interpolation": {
    "comment_description": "자동 변환할 정보가 없어 스마트 댓글을 사용할 수 없는 리뷰에는 기본 댓글 중 하나가 랜덤하게 등록됩니다.",
    "add_comment_preset": "템플릿 추가",
    "comment_preset_placeholder": "템플릿으로 등록할 문구를 입력해주세요."
  },
  "cancel_confirm": {
    "create_title": "템플릿 작성을 취소하시겠습니까?",
    "edit_title": "템플릿 수정을 취소하시겠습니까?",
    "create_content": "현재 작성중인 내용이 삭제됩니다.",
    "edit_content": "지금 취소하면 변경내용이 삭제됩니다.",
    "close_button_label": "취소",
    "cancel_button_label": "계속 작성"
  },
  "comment_preset_message": "템플릿",
  "message_is_required": "메시지를 입력해주세요."
}
</i18n>
<i18n locale="en">
{
  "smart": {
    "comment_description": "When using the batch comment, one of the Smart Comment predefined text is randomly registered.",
    "auto_conversion_rule": "Auto conversion rule",
    "auto_conversion_rule_content": "Customer name: {user}\nCustomer grade: {grade}\nReward points: {mileage}\nReview type (video/photo/text): {type}",
    "auto_conversion_rule_content_shopify": "Customer name: {user}\nReward points: {mileage}\nReview type (video/photo/text): {type}",
    "example": "Example",
    "example_content_html": "<b>Dear {user}</b>, Thank you for writing <b>{type}</b> to our shop. We gave reward <b>{mileage}</b>.<br />-> <b>Dear {sampleUserDisplayName}</b>, Thank you for writing <b>photo review</b> to our shop. We gave reward <b>$1</b>.",
    "example_content_html_shopify": "<b>Dear {user}</b>, Thank you for writing <b>{type}</b> to our shop. We gave reward <b>{mileage}</b>.<br />-> <b>Dear {sampleUserDisplayName}</b>, Thank you for writing <b>photo review</b> to our shop. We gave reward <b>$1</b>.",
    "add_comment_preset": "Add predefined text",
    "comment_preset_placeholder": "Type your message for predefined text."
  },
  "no_interpolation": {
    "comment_description": "One of the basic comments is randomly commented for reviews that cannot use smart comments because there is no information to be automatically converted.",
    "add_comment_preset": "Add predefined text",
    "comment_preset_placeholder": "Type your message for predefined text."
  },
  "cancel_confirm": {
    "create_title": "Discard this text?",
    "edit_title": "Discard changes?",
    "create_content": "If you discard now, your text will be lost.",
    "edit_content": "If you cancel now, your changes will be discarded.",
    "close_button_label": "Discard",
    "cancel_button_label": "Keep Editing"
  },
  "comment_preset_message": "Predefined text",
  "message_is_required": "Message is required."
}
</i18n>
