import { constructEnum } from '@/lib/enum';

export default constructEnum('BatchJobResultCode', {
  NONE: 0,
  SUCCESS: 1,
  FAILURE_INVALID_PRODUCT_CODE: 3,
  FAILURE_EMPTY_PRODUCT_CODE: 4,
  FAILURE_SERVER: 100,
  FAILURE_PRODUCT_CODE_MUST_NOT_BE_THE_SAME: 200,
  FAILURE_ALREADY_EXISTS_PRODUCT_CODE: 300,
  FAILURE_EMPTY_SMARTSTORE_PRODUCT_NO: 400,
  FAILURE_DUPLICATE_SMARTSTORE_PRODUCT_NO: 402,
  FAILURE_CRAWL_SMARTSTORE_ORIGIN_PRODUCT_NO: 410
});
