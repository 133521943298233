var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TargetCampaignStatsRecentReport"},[_c('div',{staticClass:"TargetCampaignStatsRecentReport__info"},[_vm._v(" "+_vm._s(_vm.$t('effect_period_info', [ _vm.TargetCampaignMediaType.effectPeriodByDays( _vm.campaign.campaign_media_type ) ]))+" ")]),_c('AppGrid',[_c('div',{staticClass:"AppGrid__col AppGrid__col--4 AppGrid__col--tablet-12"},[_c('TargetCampaignStatsSectionKpi',_vm._b({on:{"click-item":_vm.clickItem}},'TargetCampaignStatsSectionKpi',{
          isLoading: _vm.isLoading,
          report: _vm.finalReports[0],
          campaign: _vm.campaign,
          samplingMethod: _vm.samplingMethod
        },false))],1),_c('div',{staticClass:"AppGrid__col AppGrid__col--4 AppGrid__col--tablet-12"},[_c('TargetCampaignStatsSectionPurchase',_vm._b({},'TargetCampaignStatsSectionPurchase',{
          isLoading: _vm.isLoading,
          reports: _vm.finalReports,
          campaign: _vm.campaign,
          samplingMethod: _vm.samplingMethod
        },false))],1),_c('div',{staticClass:"AppGrid__col AppGrid__col--4 AppGrid__col--tablet-12"},[_c('TargetCampaignStatsSectionReach',_vm._b({on:{"click-item":_vm.clickItem}},'TargetCampaignStatsSectionReach',{ isLoading: _vm.isLoading, reports: _vm.finalReports, campaign: _vm.campaign },false))],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }