import { constructEnum } from '@/lib/enum';

const WidgetCombinationType = constructEnum('WidgetCombinationType', {
  LIST: 10,
  BOARD: 11,
  EXTERNAL: 12,
  GALLERY: 13,
  BEST_GALLERY: 14,
  SQUARE_GALLERY: 15,
  PRODUCTS: 16,
  HORIZONTAL_SCROLL: 17,
  PHOTO_THUMBNAIL: 18,
  COUNT_AND_SCORE: 19,
  PHOTO_THUMBNAIL_AND_LIST: 110,
  HORIZONTAL_SCROLL_AND_LIST: 140,
  GALLERY_AND_LIST: 150,
  EXTERNAL_AND_LIST: 160,
  PHOTO_THUMBNAIL_AND_GALLERY_AND_LIST: 170,
  PHOTO_THUMBNAIL_AND_HORIZONTAL_SCROLL_AND_LIST: 180,
  PHOTO_THUMBNAIL_AND_EXTERNAL_AND_DETACHABLE_BOARD_AND_LIST: 190,
  BEST_GALLERY_AND_GALLERY_AND_LIST: 510,
  BEST_GALLERY_AND_PRODUCTS: 520
});

WidgetCombinationType.productDetailTypes = [
  WidgetCombinationType.PHOTO_THUMBNAIL_AND_LIST,
  WidgetCombinationType.GALLERY_AND_LIST,
  WidgetCombinationType.HORIZONTAL_SCROLL_AND_LIST,
  WidgetCombinationType.EXTERNAL_AND_LIST,
  WidgetCombinationType.PHOTO_THUMBNAIL_AND_GALLERY_AND_LIST,
  WidgetCombinationType.PHOTO_THUMBNAIL_AND_HORIZONTAL_SCROLL_AND_LIST,
  WidgetCombinationType.PHOTO_THUMBNAIL_AND_EXTERNAL_AND_DETACHABLE_BOARD_AND_LIST
];

WidgetCombinationType.reviewBoardTypes = [
  WidgetCombinationType.GALLERY_AND_LIST,
  WidgetCombinationType.BEST_GALLERY_AND_GALLERY_AND_LIST,
  WidgetCombinationType.BEST_GALLERY_AND_PRODUCTS
];

export default WidgetCombinationType;
