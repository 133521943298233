<template>
  <AppAjaxContent :is-loading="isLoading">
    <template>
      <div class="ReviewDashboardRequestInstallation">
        <StepMainColor
          v-if="currentStep === 1"
          @next="onNext"
          @modify="setComplete(false)"
        />
        <StepWidgetInstall
          v-else
          @next="setComplete(true)"
          @modify="setComplete(false)"
          @prev="onPrev"
        />
      </div>
    </template>
  </AppAjaxContent>
</template>

<script>
import StepMainColor from './components/StepMainColor';
import StepWidgetInstall from './components/StepWidgetInstall';
import WidgetInstallStatus from '@/enums/WidgetInstallStatus';
import { mapState, mapActions, mapGetters } from 'vuex';
import BrandReviewStatus from '@/enums/BrandReviewStatus';

export default {
  name: 'ReviewDashboardRequestInstallation',
  components: { StepMainColor, StepWidgetInstall },
  props: {
    isLoading: { type: Boolean, required: true }
  },
  data() {
    return {
      currentStep: 1
    };
  },
  computed: {
    ...mapGetters('session', ['isImwebBrand']),
    ...mapState('easyJoin', ['onboardingProgress'])
  },
  methods: {
    ...mapActions('session', ['fetchSession']),
    ...mapActions('easyJoin', ['updateOnboardingProgress']),
    onNext() {
      this.currentStep += 1;

      this.updateOnboardingProgress({ main_color_completed: true });
    },
    onPrev() {
      this.currentStep -= 1;

      this.updateOnboardingProgress({ main_color_completed: false });
    },
    setComplete(isComplete) {
      const params = {
        request_installation_completed: isComplete ? '1' : '0',
        ...(this.isImwebBrand && {
          widget_install_status: WidgetInstallStatus.CONFIRMED
        })
      };
      this.updateOnboardingProgress(params)
        .then(() => {
          this.$emit('submitted');
        })
        .finally(() => {
          this.$emit('loaded');
          if (
            this.isImwebBrand &&
            this.onboardingProgress.review_status ==
              BrandReviewStatus.ONBOARDING_PAID
          )
            this.fetchSession();
        });
    }
  }
};
</script>
