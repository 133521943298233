<template>
  <AppModalDefault
    :title="$t('dialog_title')"
    is-maximized-by-default
    is-maximized-modal-page
    @close="close(true)"
  >
    <template #body>
      <div class="RequestInstallWidgetSelectionDialog__title">
        {{ $t('title', [WidgetPageType.t(pageType)]) }}
      </div>
      <AppInfoBox
        :class="[
          'RequestInstallWidgetSelectionDialog__info-box',
          {
            'RequestInstallWidgetSelectionDialog__info-box--wide': isPageTypeMain
          }
        ]"
      >
        {{ $t('info_content') }}
      </AppInfoBox>
      <AppForm v-bind="formProps" v-on="formEvents">
        <template #group="{ id, options }">
          <template v-if="id === 'widget_set_type'">
            <div
              class="RequestInstallWidgetSelectionDialog__widget-set-type-container"
            >
              <div
                v-for="(option, i) in options"
                :key="i"
                class="RequestInstallWidgetSelectionDialog__widget-set-type-item"
              >
                <div :class="widgetSetTypeClasses(option.value)">
                  <AppImage
                    :src="widgetSetTypeImageUrl(option.value)"
                    class="RequestInstallWidgetSelectionDialog__widget-set-type-image"
                  />
                </div>
                <AppRadio
                  v-model="formObject.widget_set_type"
                  name="widget_set_type"
                  :label="option.label"
                  :value="option.value"
                  @change="changeWidgetSetType"
                />
                <div
                  class="RequestInstallWidgetSelectionDialog__widget-set-type-description"
                >
                  {{ option.description }}
                  <div v-if="option.value === WidgetSetType.DEFAULT">
                    <AppButton
                      :label="$t('preview.pc')"
                      button-style="underline"
                      @click="
                        openWidgetSetPreviewDialog(false, option.presetTypes)
                      "
                    />
                    <AppButton
                      :label="$t('preview.mobile')"
                      button-style="underline"
                      @click="
                        openWidgetSetPreviewDialog(true, option.presetTypes)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="id === 'detail_widget_set_type'">
            <div v-if="showDetailWidgetSet">
              <div
                v-if="!isPageTypeMain"
                class="RequestInstallWidgetSelectionDialog__detail-widget-set-type-title"
              >
                {{ $t('select_detail_widget_set') }}
              </div>
              <div
                class="RequestInstallWidgetSelectionDialog__detail-widget-set-type-container"
              >
                <div v-for="(groupOptions, i) in options" :key="i">
                  <div
                    class="RequestInstallWidgetSelectionDialog__detail-widget-set-type-row"
                  >
                    <div
                      v-for="(option, idx) in groupOptions"
                      :key="`row-${idx}`"
                      class="RequestInstallWidgetSelectionDialog__detail-widget-set-type-item"
                    >
                      <div
                        class="RequestInstallWidgetSelectionDialog__widget-set-type-image-wrapper"
                      >
                        <div :class="widgetSetTypeClasses(option.value)">
                          <AppImage
                            :src="widgetSetTypeImageUrl(option.value)"
                            class="RequestInstallWidgetSelectionDialog__widget-set-type-image"
                          />
                        </div>
                        <div
                          :class="[
                            'RequestInstallWidgetSelectionDialog__hover-background',
                            isReviewBoardDetailWidgetSet(option.value)
                              ? 'RequestInstallWidgetSelectionDialog__hover-background--squared'
                              : ''
                          ]"
                        >
                          <div
                            class="RequestInstallWidgetSelectionDialog__widget-preview-button-container"
                          >
                            <div>
                              <AppButton
                                class="RequestInstallWidgetSelectionDialog__widget-preview-button"
                                :label="$t('preview.pc_widget')"
                                button-style="transparent"
                                @click="
                                  openWidgetSetPreviewDialog(
                                    false,
                                    option.presetTypes
                                  )
                                "
                              />
                            </div>
                            <div>
                              <AppButton
                                class="RequestInstallWidgetSelectionDialog__widget-preview-button"
                                :label="$t('preview.mobile_widget')"
                                button-style="transparent"
                                @click="
                                  openWidgetSetPreviewDialog(
                                    true,
                                    option.presetTypes
                                  )
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <AppRadio
                        v-model="formObject.detail_widget_set_type"
                        name="detail_widget_set_type"
                        :label="option.label"
                        :value="option.value"
                        @change="changeDetailWidgetSetType(idx)"
                      />
                    </div>
                  </div>
                  <div
                    v-if="showInstallPoistion(groupOptions)"
                    :class="[
                      'RequestInstallWidgetSelectionDialog__install-position-container',
                      {
                        'RequestInstallWidgetSelectionDialog__install-position-container--left':
                          selectedDetailWidgetSetIndex === 0,
                        'RequestInstallWidgetSelectionDialog__install-position-container--middle':
                          selectedDetailWidgetSetIndex === 1,
                        'RequestInstallWidgetSelectionDialog__install-position-container--right':
                          selectedDetailWidgetSetIndex === 2
                      }
                    ]"
                  >
                    <div
                      class="RequestInstallWidgetSelectionDialog__item-title"
                    >
                      {{
                        $t('check_position', [
                          widgetSetTypeLabel(formObject.detail_widget_set_type)
                        ])
                      }}
                    </div>
                    <AppTable
                      table-id="request-installation-select-widget-table"
                      :columns="columns"
                      :rows="rows"
                    >
                      <template #cell="{ column, row, rowIndex }">
                        <template v-if="column.id === 'position'">
                          <AppSelect
                            :key="
                              `${formObject.detail_widget_set_type}-${rowIndex}`
                            "
                            v-model="widgets[rowIndex].position"
                            :options="row.options"
                            @change="changeWidgetPosition"
                          />
                          <AppButton
                            v-if="
                              widgets[rowIndex].position ===
                                InstallationPresetPlacementType.CUSTOM
                            "
                            class="RequestInstallWidgetSelectionDialog__custom-position-button"
                            :label="$t('input_custom_position')"
                            @click="openInputCustomPositionDialog(rowIndex)"
                          />
                        </template>
                      </template>
                    </AppTable>
                    <div v-if="showPreviewInstallPositionInfo">
                      <div
                        class="RequestInstallWidgetSelectionDialog__item-title"
                      >
                        {{ $t('preview.install_position_info') }}
                      </div>
                      <div>
                        <AppButton
                          :label="$t('pc')"
                          @click="openPositionPreviewDialog(false)"
                        />
                        <AppButton
                          :label="$t('mobile')"
                          @click="openPositionPreviewDialog(true)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </AppForm>
      <div class="RequestInstallWidgetSelectionDialog__button">
        <AppButton
          :label="$t('submit_label')"
          :button-style="'blue'"
          :disabled="submitButtonDisabled"
          @click="submit()"
        />
      </div>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import WidgetSetType from '@/enums/WidgetSetType';
import InstallationPresetWidgetType from '@/enums/InstallationPresetWidgetType';
import InstallationPresetPlacementType from '@/enums/InstallationPresetPlacementType';
import WidgetPageType from '@/enums/WidgetPageType';

export default {
  name: 'RequestInstallWidgetSelectionDialog',
  mixins: [DialogFormView],
  props: {
    pageType: { type: Number, required: true }
  },
  data() {
    return {
      widgets: [],
      selectedDetailWidgetSetIndex: 0,
      isCustomPositionInserted: false
    };
  },
  computed: {
    formSections() {
      return this.isPageTypeMain
        ? this.mainPageFormSections
        : this.defaultFormSections;
    },
    defaultFormSections() {
      return [
        {
          groups: [
            {
              id: 'widget_set_type',
              label: '',
              options: this.widgetSetTypeOptions
            },
            {
              id: 'detail_widget_set_type',
              label: '',
              options: this.groupedDetailWidgetSetTypeOptions
            }
          ]
        }
      ];
    },
    mainPageFormSections() {
      return [
        {
          groups: [
            {
              id: 'detail_widget_set_type',
              label: '',
              options: this.groupedDetailWidgetSetTypeOptions
            }
          ]
        }
      ];
    },
    columns() {
      return [
        { id: 'widget', label: this.$t('install.widget') },
        { id: 'position', label: this.$t('install.position') }
      ];
    },
    rows() {
      return this.widgets.map(widget => ({
        widget: InstallationPresetWidgetType.t(widget.presetWidgetType),
        options: this.positionOptions(widget.presetWidgetType)
      }));
    },
    WidgetSetType: () => WidgetSetType,
    WidgetPageType: () => WidgetPageType,
    InstallationPresetPlacementType: () => InstallationPresetPlacementType,
    widgetPageTypeKey() {
      return WidgetPageType.toKey(this.pageType).toLowerCase();
    },
    widgetSetTypeOptions() {
      return [WidgetSetType.DEFAULT, WidgetSetType.RECOMMENDATION].map(
        widgetSetType => {
          const presetWidgetType = WidgetSetType.defaultWidgetType(
            this.pageType
          );
          const presetPlacementType = InstallationPresetWidgetType.installPositions(
            widgetSetType,
            presetWidgetType
          )[0];

          return {
            label: this.widgetSetTypeLabel(widgetSetType),
            description: this.widgetSetTypeDescription(widgetSetType),
            presetTypes: [
              {
                presetWidgetType,
                presetPlacementType
              }
            ],
            value: widgetSetType
          };
        }
      );
    },
    detailWidgetSetTypeOptions() {
      let widgetSetTypes = [];
      switch (this.pageType) {
        case WidgetPageType.PRODUCT_DETAIL:
          widgetSetTypes = WidgetSetType.productDetailTypes;
          break;
        case WidgetPageType.REVIEW_BOARD:
          widgetSetTypes = WidgetSetType.reviewBoardTypes;
          break;
        case WidgetPageType.MAIN:
          widgetSetTypes = WidgetSetType.mainTypes;
          break;
      }

      return widgetSetTypes.map(widgetSetType => {
        const presetWidgetTypes = WidgetSetType.installationTypes(
          widgetSetType
        );

        const presetTypes = presetWidgetTypes.map(presetWidgetType => {
          return {
            presetWidgetType,
            presetPlacementType: InstallationPresetWidgetType.installPositions(
              widgetSetType,
              presetWidgetType
            )[0]
          };
        });

        return {
          label: this.widgetSetTypeLabel(widgetSetType),
          presetTypes,
          value: widgetSetType
        };
      });
    },
    groupedDetailWidgetSetTypeOptions() {
      const optionsPerGroup = 3;
      const options = this.detailWidgetSetTypeOptions;
      const groupedOptions = [];
      for (let i = 0; i < options.length; i += optionsPerGroup) {
        groupedOptions.push(options.slice(i, i + optionsPerGroup));
      }
      return groupedOptions;
    },
    selectedInstallationTypes() {
      return WidgetSetType.installationTypes(
        this.formObject.detail_widget_set_type
      );
    },
    defaultWidget() {
      const presetWidgetType =
        this.pageType === WidgetPageType.REVIEW_BOARD
          ? InstallationPresetWidgetType.REVIEW_BOARD_LIST_WIDGET
          : InstallationPresetWidgetType.PRODUCT_DETAIL_LIST_WIDGET;

      return [
        {
          pageType: this.pageType,
          position: InstallationPresetPlacementType.REPLACE_REVIEW_SECTION,
          widgetSetType: WidgetSetType.DEFAULT,
          presetWidgetType
        }
      ];
    },
    isPageTypeMain() {
      return this.pageType === WidgetPageType.MAIN;
    },
    showDetailWidgetSet() {
      return (
        this.isPageTypeMain ||
        this.widgetSetTypeChecked(WidgetSetType.RECOMMENDATION)
      );
    },
    showPreviewInstallPositionInfo() {
      return !this.isPageTypeMain || !this.isCustomPositionWidgetExists;
    },
    isCustomPositionWidgetExists() {
      return this.widgets.some(
        widget => widget.position === InstallationPresetPlacementType.CUSTOM
      );
    },
    submitButtonDisabled() {
      return !this.isInstallWidgetSetSelected || this.isCustomePositionRequired;
    },
    isInstallWidgetSetSelected() {
      return (
        this.formObject.widget_set_type === WidgetSetType.DEFAULT ||
        this.formObject.detail_widget_set_type
      );
    },
    isCustomePositionRequired() {
      return (
        this.isCustomPositionWidgetExists && !this.isCustomPositionInserted
      );
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('requestInstallations', ['SET_PAGE_TYPE_INSTALLATIONS']),
    widgetSetTypeToKey(value) {
      return WidgetSetType.toKey(value).toLowerCase();
    },
    changeWidgetSetType() {
      this.widgets = this.defaultWidget;
    },
    changeDetailWidgetSetType(selectedDetailWidgetSetIndex) {
      this.selectedDetailWidgetSetIndex = selectedDetailWidgetSetIndex;
      this.widgets = this.selectedInstallationTypes.map(presetWidgetType => ({
        pageType: this.pageType,
        position: this.positionOptions(presetWidgetType)[0].value,
        widgetSetType: this.formObject.detail_widget_set_type,
        presetWidgetType
      }));
    },
    changeWidgetPosition() {
      this.isCustomPositionInserted = false;
    },
    positionOptions(installationType) {
      return InstallationPresetPlacementType.options(
        InstallationPresetWidgetType.installPositions(
          this.formObject.detail_widget_set_type,
          installationType
        )
      );
    },
    showInstallPoistion(groupOptions) {
      return groupOptions
        .map(option => option.value)
        .includes(this.formObject.detail_widget_set_type);
    },
    widgetSetTypeChecked(widgetSetType) {
      return (
        this.formObject.widget_set_type === widgetSetType ||
        this.formObject.detail_widget_set_type === widgetSetType
      );
    },
    widgetSetTypeClasses(widgetSetType) {
      return {
        'RequestInstallWidgetSelectionDialog__widget-set-type-image-container': true,
        'RequestInstallWidgetSelectionDialog__widget-set-type-image-container--detail': !WidgetSetType.commonTypes.includes(
          widgetSetType
        ),
        'RequestInstallWidgetSelectionDialog__widget-set-type-image-container--squared': this.isReviewBoardDetailWidgetSet(
          widgetSetType
        ),
        'RequestInstallWidgetSelectionDialog__widget-set-type-image-container--checked': this.widgetSetTypeChecked(
          widgetSetType
        )
      };
    },
    widgetSetTypeLabel(widgetSetType) {
      const label = WidgetSetType.t(widgetSetType);
      if (WidgetSetType.commonTypes.includes(widgetSetType)) {
        return this.$t('install.label', [label]);
      } else {
        return label;
      }
    },
    widgetSetTypeDescription(widgetSetType) {
      const widgetSetTypeKey = this.widgetSetTypeToKey(widgetSetType);
      return this.$t(`widget_set_type.${widgetSetTypeKey}.description`);
    },
    widgetSetTypeImageUrl(widgetSetType) {
      const widgetSetTypeKey = this.widgetSetTypeToKey(widgetSetType);
      const { widgetPageTypeKey } = this;
      return `https://assets.cre.ma/m/admin/v2/${widgetPageTypeKey}_${widgetSetTypeKey}@3x.png`;
    },
    isReviewBoardDetailWidgetSet(widgetSetType) {
      return (
        this.pageType == WidgetPageType.REVIEW_BOARD &&
        !WidgetSetType.commonTypes.includes(widgetSetType)
      );
    },
    openWidgetSetPreviewDialog(isMobile, presetTypes) {
      this.openDialog([
        'ReviewDashboardRequestInstallationWidgetSetIframePreviewDialog',
        {
          isMobile,
          pageType: this.pageType,
          presetTypes
        }
      ]);
    },
    openPositionPreviewDialog(isMobile) {
      this.openDialog([
        'ReviewDashboardRequestInstallationPositionPreviewDialog',
        {
          isMobile,
          pageType: this.pageType
        }
      ]);
    },
    openInputCustomPositionDialog(rowIndex) {
      this.openDialog(['RequestInstallCustomPositionFormDialog']).then(
        eventBus => {
          eventBus.$on('change', customPosition => {
            this.$set(this.widgets, rowIndex, {
              ...this.widgets[rowIndex],
              pc_image_url: customPosition.pc_image_url,
              pc_comment: customPosition.pc_comment,
              mobile_image_url: customPosition.mobile_image_url,
              mobile_comment: customPosition.mobile_comment
            });
            this.isCustomPositionInserted = true;
          });
        }
      );
    },
    submit() {
      this.SET_PAGE_TYPE_INSTALLATIONS({
        pageType: this.pageType,
        requestInstallations: this.widgets
      });
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.RequestInstallWidgetSelectionDialog__title {
  @include text-sub-title;
  text-align: center;
  margin-top: 23px;
}

.RequestInstallWidgetSelectionDialog__info-box {
  width: 664px;
  margin: 16px auto;
  white-space: pre-line;

  &--wide {
    width: 1008px;
  }
}
.RequestInstallWidgetSelectionDialog__widget-set-type-description {
  @include text-caption;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
  white-space: pre-wrap;
}

.RequestInstallWidgetSelectionDialog__widget-set-type-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.RequestInstallWidgetSelectionDialog__item-title,
.RequestInstallWidgetSelectionDialog__detail-widget-set-type-title {
  @include text-label;
  margin-bottom: 16px;
  text-align: center;
}

.RequestInstallWidgetSelectionDialog__detail-widget-set-type-container {
  width: 1008px;
  margin: 16px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 16px;
}

.RequestInstallWidgetSelectionDialog__detail-widget-set-type-row {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex-direction: row;
}

.RequestInstallWidgetSelectionDialog__detail-widget-set-type-item,
.RequestInstallWidgetSelectionDialog__widget-set-type-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.RequestInstallWidgetSelectionDialog__widget-set-type-image-wrapper {
  position: relative;
}

.RequestInstallWidgetSelectionDialog__widget-set-type-image {
  width: 200px;
}

.RequestInstallWidgetSelectionDialog__hover-background {
  width: 320px;
  height: 240px;
  border-radius: 3px;
  position: absolute;
  display: none;
  top: 0;
  opacity: 0.7;
  background-color: $color-review-black;

  &--squared {
    height: 320px;
  }

  &:hover {
    display: block;
  }
}

.RequestInstallWidgetSelectionDialog__widget-preview-button-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 8px;
}

.RequestInstallWidgetSelectionDialog__widget-preview-button {
  width: 146px;
}

.RequestInstallWidgetSelectionDialog__widget-set-type-image-container {
  width: 320px;
  height: 240px;
  border-radius: 3px;
  background-color: #e7eaed;
  display: flex;
  align-items: center;
  justify-content: center;

  &--squared {
    height: 320px;
  }

  &--detail {
    &:hover + .RequestInstallWidgetSelectionDialog__hover-background {
      display: block;
    }
  }

  &--checked {
    background-color: #c9ddf1;
  }
}

$image-container-width: 1008px;
$image-width: 320px;
$triangle-width: 22px;

.RequestInstallWidgetSelectionDialog__install-position-container {
  border-radius: 3px;
  margin: 34px 0 18px 0;
  border: 1px solid $color-grey-25;
  background: $color-background;
  display: flex;
  width: $image-container-width;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -16px;
    border-bottom: 18px solid $color-review-white;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
  }

  &:before {
    top: -18px;
    border-bottom-color: $color-grey-25;
  }

  &--left:before,
  &--left:after {
    left: calc(($image-width - $triangle-width) / 2);
  }

  &--middle:before,
  &--middle:after {
    left: calc(($image-container-width - $triangle-width) / 2);
  }

  &--right:before,
  &--right:after {
    right: calc(($image-width - $triangle-width) / 2);
  }
}

.RequestInstallWidgetSelectionDialog__custom-position-button {
  margin-left: 8px;
}

.RequestInstallWidgetSelectionDialog__button {
  text-align: center;
  margin-top: 54px;
}

::v-deep {
  .request-installation-select-widget-table__widget,
  .request-installation-select-widget-table__position {
    width: 50%;
  }
}
</style>

<i18n locale="ko">
  {
    "title": "{0}에 어떤 위젯을 설치할까요?",
    "dialog_title": "위젯 설치 요청",
    "info_content": "PC에서 모바일 레이아웃을 사용하는 경우 모바일 위젯이 PC에 설치됩니다.\n아래 제공되는 옵션 외 다른 구성으로 위젯 설치를 원할 경우 크리마 운영팀에 문의해 주세요.",
    "widget_set_type": {
      "default": {
        "description": "크리마 이용 쇼핑몰이 가장 많이 설치하는 리스트 위젯 입니다."
      },
      "recommendation": {
        "description": "기본 위젯과 함께 사용하면 효과적인 위젯 세트 중\n설치할 위젯을 선택합니다."
      }
    },
    "select_detail_widget_set" : "설치할 위젯 세트를 선택해 주세요.",
    "input_custom_position" : "설치 위치 입력",
    "check_position" : "{0}의 설치 위치를 확인해주세요.",
    "install": {
      "label": "{0} 설치",
      "widget": "설치 위젯",
      "position": "설치 위치"
    },
    "preview": {
      "pc": "PC 미리보기",
      "pc_widget": "PC 위젯 미리보기",
      "mobile": "모바일 미리보기",
      "mobile_widget": "모바일 위젯 미리보기",
      "install_position_info": "설치 위치 안내"
    },
    "pc": "PC",
    "mobile": "모바일",
    "submit_label": "완료"
  }
</i18n>
