export default [
  {
    id: 'display_main_page',
    type: 'sub_menu',
    path: '/display/main-page/corners',
    parentId: 'display'
  },
  {
    id: 'display_category_page',
    type: 'sub_menu',
    path: '/display/category-page/product-displays',
    parentId: 'display'
  },
  {
    id: 'display_category_page_product_displays',
    type: 'tab',
    path: '/display/category-page/product-displays',
    parentId: 'display_category_page'
  },
  {
    id: 'display_category_page_corners',
    type: 'tab',
    path: '/display/category-page/corners',
    parentId: 'display_category_page'
  },
  {
    id: 'display_product_display_preview_dialog',
    type: 'dialog',
    dialog: 'DisplayProductDisplayPreviewDialog',
    not_searchable: true
  },
  {
    id: 'display_product_managements',
    type: 'sub_menu',
    path: '/display/product-managements',
    parentId: 'display'
  },
  {
    id: 'display_settings',
    type: 'sub_menu',
    path: '/display/settings/basic',
    parentId: 'display'
  },
  {
    id: 'display_settings_basic',
    type: 'tab',
    path: '/display/settings/basic',
    parentId: 'display_settings'
  },
  {
    id: 'display_settings_including_categories',
    type: 'tab',
    path: '/display/settings/including-categories',
    parentId: 'display_settings'
  },
  {
    id: 'display_settings_admin',
    type: 'tab',
    path: '/display/settings/admin',
    parentId: 'display_settings',
    superAdmin: true
  },
  {
    id: 'display_intro',
    type: 'sub_menu',
    path: '/display/intro',
    parentId: 'display',
    group: 1
  }
].map(item => {
  const isAccessible =
    item.id === 'display_intro'
      ? getters => getters.isDisplayMenuAccessibleByShopBuilder
      : getters =>
          getters.isDisplayAccessible &&
          getters.isDisplayMenuAccessibleByShopBuilder;
  return {
    ...item,
    isAccessible,
    isLaunched: getters => getters.isDisplayMenuLaunched
  };
});
