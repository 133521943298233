import { constructEnum } from '@/lib/enum';

const DisplayPageType = constructEnum('DisplayPageType', {
  MAIN: 0,
  CATEGORY: 10
});

DisplayPageType.isCategoryPage = function(type) {
  return type === DisplayPageType.CATEGORY;
};

export default DisplayPageType;
