<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppEmailVerificationInput v-model="valueBasic" />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>
  </div>
</template>

<script>
import AppEmailVerificationInput from '../../components/AppEmailVerificationInput.vue';
export default {
  name: 'UiEmailVerificationInput',
  components: { AppEmailVerificationInput },
  data() {
    return { valueBasic: '', valueInvalid: '' };
  }
};
</script>
