import { constructEnum } from '@/lib/enum';

export default constructEnum('PaymentStatus', {
  NOT_PAID: 10,
  NOT_PAID_REQUESTED_PAYMENT: 20,
  NOT_PAID_PROCESSED_PAYMENT: 30,
  PENDING_BANK_REQUESTED: 500,
  PENDING_VACCOUNT_REQUESTED: 510,
  SUCCESS_PAID: 1000,
  SUCCESS_PAID_NOT_SHIPPED: 1010,
  ERROR: 2000,
  ERROR_FROM_PROCESS_PAYMENT: 2020,
  ERROR_FROM_COMPLETE: 2030,
  ERROR_USER_CANCELLED: 2120,
  FATAL_AMOUNT_MISMATCH: 2010,
  FAILURE_TIMEOUT: 4000,
  ADMIN_CANCELLED: 5000,
  VACCOUNT_USER_CANCELLED: 5010
});
