<template>
  <AppResourceTable
    :table-id="tableId"
    :table-style="tableStyle"
    :batch-buttons="batchButtons"
    :columns="tableColumns"
    :rows="rows"
    :resources="resources"
    :filter-options="filterOptions"
    :filter-values="filterValues"
    :selected-row-ids="selectedRowIds"
    @change-filter-values="$emit('change-filter-values', $event)"
    @paginate="$emit('paginate', $event)"
    @select-rows="updateSelectedRowIds"
  >
    <template #cell="props">
      <AppSwitch
        v-if="props.column === 'selected_item'"
        :checked="selectedItemIds.includes(props.row.id)"
        :disabled="props.row.disabled"
        :tooltip="props.row.disabled ? props.row.disabledTooltip : null"
        @change="
          updateSelectedItemIds({ ids: [props.row.id], enabled: $event })
        "
      />
      <slot v-else name="cell" v-bind="props" />
    </template>
  </AppResourceTable>
</template>

<script>
export default {
  name: 'AppSelectResourceTable',
  model: { prop: 'selectedItemIds', event: 'update' },
  props: {
    tableId: { type: String, default: null },
    tableStyle: { type: String, default: null },
    columns: { type: Array, required: true },
    rows: { type: Array, required: true },
    resources: { type: Object, default: null },
    filterOptions: { type: Array, default: () => [] },
    filterValues: { type: Array, default: () => [] },
    selectedItemIds: { type: Array, required: true },
    selectedItemColumnLabel: { type: String, required: true },
    enableBatchButtonLabel: { type: String, default: null },
    disableBatchButtonLabel: { type: String, default: null }
  },
  data: () => ({ selectedRowIds: [] }),
  computed: {
    batchButtons() {
      return [
        {
          label:
            this.enableBatchButtonLabel ||
            this.$t('batch_buttons.enable_batch'),
          clickHandler: () =>
            this.updateSelectedItemIds({
              ids: this.selectedRowIds,
              enabled: true
            })
        },
        {
          label:
            this.disableBatchButtonLabel ||
            this.$t('batch_buttons.disable_batch'),
          clickHandler: () =>
            this.updateSelectedItemIds({
              ids: this.selectedRowIds,
              enabled: false
            })
        }
      ];
    },
    tableColumns() {
      return [
        { id: 'row_select', type: 'row_select' },
        ...this.columns,
        {
          id: 'selected_item',
          label: this.selectedItemColumnLabel,
          width: '90px'
        }
      ];
    }
  },
  methods: {
    addIds(oldIds, ids) {
      return [...new Set(oldIds.concat(ids))].sort((a, b) => a - b);
    },
    removeIds(oldIds, ids) {
      return oldIds.filter(id => !ids.includes(id));
    },
    updateSelectedRowIds(selectedRows) {
      let ids = this.selectedRowIds;

      const selectedRowIds = selectedRows.map(row => row.id);
      ids = this.addIds(ids, selectedRowIds);

      const unselectedRowIds = this.rows
        .filter(row => !selectedRowIds.includes(row.id))
        .map(row => row.id);
      ids = this.removeIds(ids, unselectedRowIds);

      this.selectedRowIds = ids;
    },
    updateSelectedItemIds({ ids, enabled }) {
      const selectedItemIds = enabled
        ? this.addIds(this.selectedItemIds, ids)
        : this.removeIds(this.selectedItemIds, ids);

      this.$emit('update', selectedItemIds);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

::v-deep {
  .AppResourceTable__cell:last-child {
    border-left: 1px solid $color-layout-section;
  }
}
</style>

<i18n locale="ko">
{
  "batch_buttons": {
    "enable_batch": "일괄 대상 설정",
    "disable_batch": "일괄 대상 해제"
  }
}
</i18n>
