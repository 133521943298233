<template>
  <TheNoticeView v-bind="$props" @close="close" @click="close" />
</template>

<script>
import DialogView from '@/mixins/DialogView';
import TheNoticeView from './TheNoticeView';

export default {
  name: 'TheNoticeDialog',
  components: { TheNoticeView },
  mixins: [DialogView],
  props: {
    noticeTitle: { type: String, required: true },
    noticeId: { type: Number, required: true },
    hideShowAllButton: { type: Boolean, default: false }
  }
};
</script>
