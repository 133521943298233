<template>
  <AppAjaxContent :is-loading="isLoading">
    <CustomContentHeader
      :resource="feed"
      resource-type="feed"
      @update-name="feed.name = $event"
    >
      <template #actions>
        <ChannelFeedManageButton
          :feed="feed"
          show-status-label
          @change-status="changeStatus"
          @deleted="deleteFeed"
        />
      </template>
    </CustomContentHeader>
    <AppTabs v-model="activeTab" class="ChannelFeedShow__tab" :tabs="tabs" />
    <Component
      :is="currentTabComponent"
      :key="activeTab"
      class="ChannelFeedShow__tab-content"
      :feed="feed"
    />
  </AppAjaxContent>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/lib/api';

export default {
  name: 'ChannelFeedShow',
  data: () => ({ isLoading: true, feed: {}, activeTab: 'products' }),
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    channelFeedId() {
      return this.$route.params.id;
    },
    tabs() {
      return [
        { id: 'products', label: this.$t('tabs.products') },
        { id: 'product_search', label: this.$t('tabs.product_search') }
      ];
    },
    currentTabComponent() {
      switch (this.activeTab) {
        case 'products':
          return 'ChannelFeedProducts';
        case 'product_search':
          return 'ChannelFeedProductSearch';
        default:
          return 'div';
      }
    }
  },
  mounted() {
    api
      .get(`/channel/feeds/${this.channelFeedId}`)
      .then(({ data }) => (this.feed = data.feed))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    changeStatus(status) {
      this.feed = { ...this.feed, status };
    },
    deleteFeed() {
      this.$router.replace(this.pathWithBrandParams('/channel/feeds'));
    }
  }
};
</script>

<style scoped>
.ChannelFeedShow__tab {
  margin-top: 20px;
}

.ChannelFeedShow__tab-content {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "tabs": {
    "products": "광고 피드 리스트",
    "product_search": "상품 검색"
  }
}
</i18n>
