var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppSearchbar',{on:{"submit":_vm.searchResource},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('li',[_c('AppSearchWithType',{attrs:{"search-types":_vm.searchTypes},model:{value:(_vm.searchWithType),callback:function ($$v) {_vm.searchWithType=$$v},expression:"searchWithType"}})],1)]},proxy:true}])},[_c('AppButton',{attrs:{"label":_vm.$t('button.unit_settings')},on:{"click":_vm.openChannelFeedSettingsDialog}}),_c('AppButton',{attrs:{"label":_vm.$t('button.apply_unit'),"disabled":_vm.cannotApplyUnit,"tooltip":_vm.applyUnitTooltip},on:{"click":_vm.requestRegenerateCsv}}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.else'),"menu-items":[
        {
          label: _vm.$t('button.download_csv'),
          tooltip: _vm.products.items.length
            ? _vm.$t('tooltip.download_csv')
            : _vm.$t('tooltip.create_cohort'),
          type: 'file_link',
          url: _vm.csvDownloadUrl,
          disabled: !_vm.products.items.length,
          clickHandler: function (ref) {
            var close = ref.close;
            var click = ref.click;

            click();
            close();
          }
        }
      ]}})],1),_c('AppResourceTable',{attrs:{"table-id":"feed-products-table","resources":_vm.products,"columns":_vm.columns,"rows":_vm.rows,"no-data":_vm.$t('no_data')},on:{"refresh":_vm.refreshResource,"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"additional-table-searchbar",fn:function(){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          message: _vm.$t('tooltip.feed_created_at.message'),
          info: _vm.$t('tooltip.feed_created_at.info', [_vm.nextFeedCreateAt])
        }),expression:"{\n          message: $t('tooltip.feed_created_at.message'),\n          info: $t('tooltip.feed_created_at.info', [nextFeedCreateAt])\n        }"}],staticClass:"ChannelProducts__feed-created-at"},[_vm._v(" "+_vm._s(_vm.$t('feed_created_at', [_vm.feedCreatedAt]))+" ")]),(_vm.sortTypeDateRequired)?_c('AppSelect',{staticClass:"ChannelProducts__custom-search-bar-item",attrs:{"size":"small","options":_vm.recentDaysOptions},on:{"change":_vm.searchResource},model:{value:(_vm.resourceParams.recent_days),callback:function ($$v) {_vm.$set(_vm.resourceParams, "recent_days", $$v)},expression:"resourceParams.recent_days"}}):_vm._e(),_c('AppSelect',{staticClass:"ChannelProducts__custom-search-bar-item",attrs:{"size":"small","options":_vm.sortTypeOptions},on:{"change":_vm.searchResource},model:{value:(_vm.resourceParams.sort_type),callback:function ($$v) {_vm.$set(_vm.resourceParams, "sort_type", $$v)},expression:"resourceParams.sort_type"}})]},proxy:true},{key:"cell",fn:function(ref){
        var row = ref.row;
        var column = ref.column;
        var value = ref.value;
        var rowIndex = ref.rowIndex;
return [(column === 'product_image')?[_c('ChannelProductsImageCell',{attrs:{"product":row},on:{"refresh":_vm.refreshResource}})]:(column === 'product_info')?[_c('ChannelProductsInfoCell',{attrs:{"product":row}})]:(column === 'category_ids')?[(!value.length)?[_vm._v("-")]:_vm._e(),_c('ChannelProductsCategoryCell',{attrs:{"category-ids":value}})]:(column === 'updated_at')?[_c('ChannelProductsUpdatedAtCell',{attrs:{"product":row}})]:(column === 'actions')?[_c('ChannelProductsActionsCell',_vm._b({attrs:{"product":row},on:{"refresh":_vm.refreshResource}},'ChannelProductsActionsCell',{ feed: _vm.feed },false))]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }