<template>
  <AppMobilePhonePreview app-style="biz-message">
    <AppScrollBox>
      <div class="AppMobilePhoneBizMessagePreview">
        <div class="AppMobilePhoneBizMessagePreview__date">
          {{ $d(new Date(), 'date_with_wday') }}
        </div>
        <div class="AppMobilePhoneBizMessagePreview__mall-name">
          {{ brandName }}
        </div>
        <div class="AppMobilePhoneBizMessagePreview__message-section">
          <div class="AppMobilePhoneBizMessagePreview__message">
            <div
              v-if="showMessageHead"
              class="AppMobilePhoneBizMessagePreview__message-head"
            >
              {{ $t('biz_message_arrived') }}
            </div>
            <div class="AppMobilePhoneBizMessagePreview__message-body">
              <slot />
            </div>
          </div>
          <div class="AppMobilePhoneBizMessagePreview__dispatch-time">
            {{ dispatchTime }}
          </div>
        </div>
      </div>
    </AppScrollBox>
  </AppMobilePhonePreview>
</template>

<script>
export default {
  name: 'AppMobilePhoneBizMessagePreview',
  props: {
    brandName: { type: String, required: true },
    dispatchTime: { type: String, required: true },
    showMessageHead: { type: Boolean, default: true }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppMobilePhoneBizMessagePreview {
  padding: 0 24px;
}

.AppMobilePhoneBizMessagePreview__date {
  @include text-content;
  color: $color-grey-75;
  text-align: center;
  padding-top: 36px;
}

.AppMobilePhoneBizMessagePreview__mall-name {
  @include text-sub-navigation;
  font-size: 13px;
  margin-top: 12px;
  margin-left: 10px;
}

.AppMobilePhoneBizMessagePreview__message-section {
  width: 296px;
}

.AppMobilePhoneBizMessagePreview__message {
  border-radius: 16px;
  background-color: #ffffff;
}

.AppMobilePhoneBizMessagePreview__message-head {
  @include text-label;
  height: 35px;
  background-color: #ffeb33;
  border-radius: 16px 16px 0 0;
  line-height: 35px;
  padding: 0 20px;
}

.AppMobilePhoneBizMessagePreview__message-body {
  padding: 16px 20px;
}

.AppMobilePhoneBizMessagePreview__dispatch-time {
  @include text-content-light;
  color: $color-grey-60;
  margin-top: 4px;
  margin-right: 10px;
  text-align: right;
}
</style>

<i18n locale="ko">
{
  "biz_message_arrived": "알림톡 도착"
}
</i18n>
