<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="optionType.name"
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #group="{ id, value, errors }">
      <template v-if="id === 'options'">
        <AppTable
          table-id="review-settings-edit-option-type-set-options-table"
          class="ReviewSettingsEditOptionTypeSetOptionsDialog__table"
          :columns="COLUMNS"
          :rows="rows"
        >
          <template #cell="{ row, column }">
            <template v-if="column.id === 'display_name'">
              <input
                type="hidden"
                name="options_attributes[][id]"
                :value="row.id"
              />
              <input
                type="hidden"
                name="options_attributes[][value]"
                :value="row.value"
              />
              <AppTextInput
                v-model="row.display_name"
                name="options_attributes[][display_name]"
                :invalid="!!errors[`options[${row.id}][display_name]`]"
                :maxlength="30"
                @change="changeDisplayName(row.id)"
              />
            </template>
            <template v-else-if="column.id === 'name'">
              {{ optionsMap[row.value].name }}
            </template>
          </template>
        </AppTable>
      </template>
      <template v-else>
        {{ value }}
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import ReviewOptionType from '@/enums/ReviewOptionType';

export default {
  name: 'ReviewSettingsEditOptionTypeSetOptionsDialog',
  mixins: [DialogFormView],
  props: {
    type: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      COLUMNS: [
        {
          id: 'name',
          label: this.$t('table_head.name')
        },
        {
          id: 'display_name',
          label: this.$t('table_head.display_name')
        }
      ],
      isLoading: true,
      orgFormObject: null,
      optionType: {}
    };
  },
  computed: {
    optionsMap() {
      return _.keyBy(this.optionType.options, 'value');
    },
    rows() {
      return this.formObject.option_type_set_options || [];
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              label: this.$t('group.name'),
              value: this.optionType.name
            },
            {
              id: 'type',
              label: this.$t('group.type'),
              value: ReviewOptionType.t(this.type)
            },
            {
              id: 'options',
              label: this.$t('group.options'),
              fields: this.rows.map(r => ({
                id: `options[${r.id}][display_name]`,
                value: r.display_name,
                validate: ['required']
              })),
              validate: [
                {
                  rule: () =>
                    [
                      ...new Set(
                        this.formObject.option_type_set_options.map(
                          o => o.display_name
                        )
                      )
                    ].length === this.formObject.option_type_set_options.length,
                  errorMessage: this.$t('duplicate_display_name_error')
                }
              ]
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get(`/review/option_type_set/options/${this.id}/edit`)
      .then(({ data }) => {
        this.orgFormObject = {
          option_type_set_options: data.option_type_set_options
        };
        this.optionType = data.option_type;
      })
      .finally(() => {
        this.isLoading = false;
        this.setFormObject();
      });
  },
  methods: {
    setFormObject() {
      const options = this.optionType.options;
      const brandOptions = this.orgFormObject.option_type_set_options;
      if (options.length === brandOptions.length) return;

      const brandOptionsMap = _.keyBy(brandOptions, 'value');
      const newBrandOptions = options.map(o => ({
        ...(brandOptionsMap[o.value]
          ? brandOptionsMap[o.value]
          : { id: _.uniqueId('new'), display_name: o.name, value: o.value })
      }));
      this.$set(this.formObject, 'option_type_set_options', newBrandOptions);
    },
    changeDisplayName(rowId) {
      this.validateField('options');
      this.validateField(`options[${rowId}][display_name]`);
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(`/review/option_type_set/options/${this.id}`, formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.eventBus.$emit('save');
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style>
.ReviewSettingsEditOptionTypeSetOptionsDialog__table {
  margin-top: 16px;
}
</style>

<i18n locale="ko">
{
  "title": "선택 항목 문구 수정",
  "group": {
    "name": "추가 정보 항목명",
    "type": "항목 분류",
    "options": "선택 항목 문구"
  },
  "table_head": {
    "name": "선택 항목명",
    "display_name": "표시 이름"
  },
  "duplicate_display_name_error": "표시 이름은 중복 될 수 없습니다."
}
</i18n>
