<template>
  <AppModal :width="width">
    <div slot="head" class="AppModalTutorial__head">
      <div class="AppModalTutorial__head-wrapper">
        <div class="AppModalTutorial__head-title-wrapper">
          <span class="AppModalTutorial__head-title">{{ title }}</span>
        </div>
      </div>
    </div>
    <div slot="body" class="AppModalTutorial__body">
      <slot name="body" />
    </div>
    <div slot="foot" class="AppModalTutorial__foot">
      <slot name="foot" />
    </div>
  </AppModal>
</template>

<script>
export default {
  name: 'AppModalTutorial',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      width: '250px'
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

::v-deep {
  .AppModal__wrapper {
    border-radius: 8px;
  }
}

.AppModalTutorial__head {
  padding: 23px 27px 12px 27px;
}

.AppModalTutorial__head-wrapper {
  position: relative;
  display: flex;
}

.AppModalTutorial__head-title-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.AppModalTutorial__head-title {
  @include text-label;
}

.AppModalTutorial__body {
  @include text-caption-bold-dark;

  padding: 11px 23px;
}

.AppModalTutorial__foot {
  position: relative;
  padding: 11px 23px 0 23px;
}
</style>
