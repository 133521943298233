<template>
  <div class="AppTextEditor" tabindex="-1">
    <Editor
      ref="Editor"
      :value="currentValue"
      language="en"
      font-size="13px"
      placeholder=" "
      :toolbars="TOOLBARS"
      :ishljs="codeHighlighting"
      @imgAdd="uploadImage"
      @change="change"
    />
    <input v-if="name" type="hidden" :value="currentValue" :name="name" />
  </div>
</template>

<script>
import { mavonEditor } from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

import api from '@/lib/api';
import TextInput from '@/mixins/TextInput';

export default {
  name: 'AppTextEditor',
  components: { Editor: mavonEditor },
  mixins: [TextInput],
  data() {
    return {
      TOOLBARS: {
        bold: true,
        italic: true,
        header: true,
        underline: true,
        strikethrough: true,
        quote: false,
        ol: true,
        ul: true,
        link: true,
        imagelink: true,
        code: false,
        table: false,
        undo: true,
        redo: true,
        trash: true
      },
      codeHighlighting: false
    };
  },
  mounted() {
    const textarea = document.getElementsByClassName('auto-textarea-input')[0];
    if (textarea) textarea.addEventListener('blur', e => this.handleBlur(e));
  },
  beforeDestroy() {
    const textarea = document.getElementsByClassName('auto-textarea-input')[0];
    if (textarea) textarea.removeEventListener('blur', this.handleBlur);
  },
  methods: {
    uploadImage(tempPath, image) {
      const formData = new FormData();
      formData.append('brand_image[image]', image);
      api
        .post('/brand_images', formData)
        .then(({ data }) =>
          this.$refs.Editor.$img2Url(tempPath, data.image_url)
        );
    },
    handleBlur(event) {
      if (this.$el.contains(event.relatedTarget)) return;

      const newValue = this.conformMdSyntax(this.currentValue).trim();
      if (this.currentValue !== newValue) this.change(newValue);
      this.blur();
    },
    conformMdSyntax(text) {
      const result = this.conformUnderlineSyntax(text);
      return this.conformNewLineSyntax(result);
    },
    conformUnderlineSyntax(text) {
      return text.replace(/\+\+(.*?)\+\+/g, '<u>$1</u>');
    },
    conformNewLineSyntax(text) {
      return text.replace(/( )*\n/g, '  \n');
    }
  }
};
</script>

<style lang="scss" scoped>
.AppTextEditor {
  &:focus {
    outline: none;
  }

  ::v-deep {
    .markdown-body {
      z-index: auto;
    }

    .v-note-show {
      font-size: 14px;
    }

    em {
      font-style: italic;
      background-color: unset;
    }

    ol {
      display: block;
      list-style-type: decimal;
      margin: 1em 0;
      padding-left: 40px;
    }

    ul {
      display: block;
      list-style-type: disc;
      margin: 1em 0;
      padding-left: 40px;
    }

    a:link,
    a:visited {
      color: blue;
      text-decoration: underline;
    }

    a:link:active,
    a:visited:active {
      color: blue;
    }
  }
}
</style>
