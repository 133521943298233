export default {
  data: () => ({ existingNames: [] }),
  methods: {
    isNameNotDuplicated(newName, orgName) {
      return (
        (orgName && newName.trim() === orgName) ||
        !this.existingNames.includes(newName.trim())
      );
    }
  }
};
