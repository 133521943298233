var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppResourceTable',{attrs:{"columns":_vm.columns,"resources":_vm.cohorts,"rows":_vm.rows,"enable-total-count":false,"table-id":"cohorts"},on:{"paginate":function($event){return _vm.$emit('paginate', $event)}},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [(column === 'corners')?[_c('AppSummaryBadge',{attrs:{"items":row.corners,"unit-path":"count"}})]:(column === 'actions')?[(row.isScheduleCancelable)?_c('AppButton',{attrs:{"label":_vm.$t('button.cancel_schedule'),"button-style":"red-outline"},on:{"click":function($event){return _vm.cancelSchedule(row)}}}):_vm._e(),(row.isRetriable)?_c('AppButton',{attrs:{"label":_vm.$t('button.retriable'),"disabled":row.isRetriableCleared || row.isProductDisplayApplying,"tooltip":row.isRetriableCleared
            ? _vm.$t('button.retriable_cleared')
            : row.isProductDisplayApplying
            ? _vm.$t('button.product_display_applying')
            : null},on:{"click":function($event){return _vm.confirmRetry(row)}}}):_vm._e(),_c('AppButton',{attrs:{"label":_vm.$t('button.detail'),"disabled":row.isDetailCleared || row.isAutoScheduled || row.isApplying,"tooltip":row.isApplying
            ? _vm.applyingTooltip(row)
            : row.isDetailCleared
            ? _vm.$t('button.detail_cleared')
            : row.isAutoScheduled
            ? _vm.$t('button.auto_scheduled')
            : null},on:{"click":function($event){return _vm.openDetailDialog(row)}}})]:_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }