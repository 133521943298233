<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{ ...formProps, objectId: 'custom_position' }"
    hide-close-button
    v-on="formEvents"
  >
    <template #group="{ id, errors }">
      <template v-if="id === 'infobox'">
        <AppInfoBox>
          {{ $t('infobox_content') }}
          <AppImage
            class="AppForm__group-field"
            src="//assets.cre.ma/m/admin/v2/widget_custom_position_info_image@3x.png"
          />
        </AppInfoBox>
      </template>
      <template v-if="id === 'pc_custom_position'">
        <template>
          <AppImageInput
            class="AppForm__group-field"
            :image-url="formObject.pc_image_url"
            name="files[]"
            not-removable
            :placeholder="$t('image.placeholder.pc')"
            :invalid="!!errors.pc_image_url"
            @change="changePcCustomPositionImage"
          />
          <AppFormError :error="errors.pc_image_url" />
        </template>
        <template>
          <AppTextInput
            v-model="formObject.pc_comment"
            class="AppForm__group-field"
            :placeholder="$t('text.placeholder')"
            :invalid="!!errors.pc_comment"
            @change="validateField('pc_comment')"
            @blur="validateField('pc_comment')"
          />
          <AppFormError :error="errors.pc_comment" />
        </template>
      </template>
      <template v-if="id === 'mobile_custom_position'">
        <template>
          <AppImageInput
            class="AppForm__group-field"
            :image-url="formObject.mobile_image_url"
            name="files[]"
            not-removable
            :placeholder="$t('image.placeholder.mobile')"
            :invalid="!!errors.mobile_image_url"
            @change="changeMobileCustomPositionImage"
          />
          <AppFormError :error="errors.mobile_image_url" />
        </template>
        <template>
          <AppTextInput
            v-model="formObject.mobile_comment"
            class="AppForm__group-field"
            :placeholder="$t('text.placeholder')"
            :invalid="!!errors.mobile_comment"
            @change="validateField('mobile_comment')"
            @blur="validateField('mobile_comment')"
          />
          <AppFormError :error="errors.mobile_comment" />
        </template>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'RequestInstallCustomPositionFormDialog',
  mixins: [DialogFormView],
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'infobox',
              label: ''
            },
            {
              id: 'pc_custom_position',
              label: this.$t('custom_position.pc'),
              required: true,
              fields: [
                {
                  id: 'pc_image_url',
                  validate: ['required']
                },
                {
                  id: 'pc_comment',
                  validate: ['required']
                }
              ]
            },
            {
              id: 'mobile_custom_position',
              label: this.$t('custom_position.mobile'),
              required: true,
              fields: [
                {
                  id: 'mobile_image_url',
                  validate: ['required']
                },
                {
                  id: 'mobile_comment',
                  validate: ['required']
                }
              ]
            }
          ]
        }
      ];
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      return api
        .post('/temp_files', formData)
        .then(({ data }) => {
          const { urls } = data;
          this.eventBus.$emit('change', {
            ...this.formObject,
            pc_image_url: urls[0],
            mobile_image_url: urls[1]
          });
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    changePcCustomPositionImage({ imageUrl }) {
      this.formObject.pc_image_url = imageUrl;
      this.validateField('pc_image_url');
    },
    changeMobileCustomPositionImage({ imageUrl }) {
      this.formObject.mobile_image_url = imageUrl;
      this.validateField('mobile_image_url');
    }
  }
};
</script>

<i18n locale="ko">
  {
    "title": "위젯 설치 위치 입력",
    "infobox_content": "아래 이미지와 같이 위젯의 설치 위치가 표시된 이미지를 첨부해주세요.",
    "custom_position": {
      "pc": "페이지 명, 위젯 종류, PC 설치 위치",
      "mobile": "페이지 명, 위젯 종류, 모바일 설치 위치"
    },
    "image": {
      "placeholder": {
        "pc": "PC 설치 위치 이미지 첨부",
        "mobile": "모바일 설치 위치 이미지 첨부"
      }
    },
    "text": {
      "placeholder": "크리마 팀이 참고할 코멘트가 있다면 남겨주세요."
    }
  }
</i18n>
