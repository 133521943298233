import ResourceParams from '@/mixins/ResourceParams';

export default {
  mixins: [ResourceParams],
  created() {
    this.setOrgResourceParams(this.defaultResourceParams);
    setTimeout(() => this.fetchResource(this.resourceParams));
  },
  methods: {
    refreshResourceWithParams(params) {
      this.fetchResource(params);
    }
  }
};
