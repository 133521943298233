<template>
  <AppMarked
    :class="['AppMarkdown', `AppMarkdown--style-${displayStyle}`]"
    v-bind="{ text, markdownOption }"
  />
</template>

<script>
export default {
  name: 'AppMarkdown',
  props: {
    text: {
      type: String,
      required: true
    },
    markdownOption: {
      type: Object,
      default: null
    },
    displayStyle: {
      type: String,
      default: 'default',
      validator: v => ['default', 'data-item'].includes(v)
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_links.scss';

.AppMarkdown {
  &--style-default {
    color: black;

    strong {
      font-weight: bold;
    }

    em {
      font-style: italic;
      background-color: unset;
    }

    h1 {
      display: block;
      font-size: 2em;
      margin: 0.67em 0;
      font-weight: bold;
    }

    h2 {
      display: block;
      font-size: 1.5em;
      margin: 0.83em 0;
      font-weight: bold;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin: 1em 0;
      font-weight: bold;
    }

    h4 {
      display: block;
      font-size: 1em;
      margin: 1.33em 0;
      font-weight: bold;
    }

    h5 {
      display: block;
      font-size: 0.83em;
      margin: 1.67em 0;
      font-weight: bold;
    }

    h6 {
      display: block;
      font-size: 0.67em;
      margin: 2.33em 0;
      font-weight: bold;
    }

    ol {
      display: block;
      list-style-type: decimal;
      margin: 1em 0;
      padding-left: 40px;
    }

    ul {
      display: block;
      list-style-type: disc;
      margin: 1em 0;
      padding-left: 40px;
    }

    a {
      @include link-blue;
    }

    img {
      max-width: 100%;
    }
  }

  &--style-data-item {
    ul {
      display: block;
      list-style-type: disc;
      padding-left: 18px;
    }

    em {
      background-color: transparent;
      @include text-caption;
    }

    a {
      @include link-blue;
    }
  }
}
</style>
