import _ from 'lodash';
import Vue from 'vue';
import i18n from '@/lib/i18n';

const state = {
  productCategories: []
};

const mutations = {
  SET_PRODUCT_CATEGORIES(state, productCategories) {
    state.productCategories = productCategories;
  },
  UPDATE_PRODUCT_CATEGORY({ productCategories }, productCategory) {
    const index = productCategories.findIndex(c => c.id === productCategory.id);
    Vue.set(productCategories, index, productCategory);
  },
  UPDATE_PRODUCT_CATEGORY_NAME({ productCategories }, { id, name }) {
    const category = productCategories.find(c => c.id === id);
    Vue.set(category, 'name', name);
  },
  DELETE_PRODUCT_CATEGORY({ productCategories }, id) {
    const index = productCategories.findIndex(c => c.id === id);
    Vue.delete(productCategories, index);
  }
};

const getters = {
  productCategoryByRandom({ productCategories }) {
    return _.find(productCategories, c => c.products_count > 0 && !c.virtual);
  },
  productCategoriesWithTreeName({ productCategories }) {
    const childCategoriesMap = _.groupBy(
      productCategories,
      'parent_category_id'
    );

    const sortWithChildCategories = category => [
      category,
      ..._.chain(childCategoriesMap[category.id] || [])
        .sortBy('code')
        .map(childCategory => {
          const treeNames = [...category.treeNames, childCategory.name];
          return {
            ...childCategory,
            treeNames,
            treeName: treeNames.join(' > ')
          };
        })
        .map(sortWithChildCategories)
        .flatten()
        .value()
    ];

    const root = { id: null, treeNames: [] };
    return sortWithChildCategories(root).filter(({ id }) => id !== root.id);
  },
  productCategoryOptions(_state, { productCategoriesWithTreeName }) {
    return productCategoriesWithTreeName.map(c => ({
      label: i18n.t('app.product_category_info', [
        c.treeName,
        c.code,
        c.products_count
      ]),
      value: c.id
    }));
  },
  productCategoryOptionsExceptZeroProduct(
    _state,
    { productCategoriesWithTreeName }
  ) {
    return productCategoriesWithTreeName
      .filter(({ products_count }) => products_count > 0)
      .map(c => ({
        label: i18n.t('app.product_category_info', [
          c.treeName,
          c.code,
          c.products_count
        ]),
        value: c.id
      }));
  },
  productCategoryCodeOptionsExceptZeroProduct(
    _state,
    { productCategoriesWithTreeName }
  ) {
    return productCategoriesWithTreeName
      .filter(({ products_count }) => products_count > 0)
      .map(c => ({
        label: i18n.t('app.product_category_info', [
          c.treeName,
          c.code,
          c.products_count
        ]),
        value: c.code
      }));
  },
  productCategoryCodeOptions(_state, { productCategoriesWithTreeName }) {
    return productCategoriesWithTreeName.map(c => ({
      label: i18n.t('app.product_category_info', [
        c.treeName,
        c.code,
        c.products_count
      ]),
      value: c.code
    }));
  },
  productCategoryShortInfo: (
    _state,
    { productCategoriesWithTreeName }
  ) => id => {
    const category = productCategoriesWithTreeName.find(c => c.id === id);
    return category
      ? i18n.t('app.product_category_short_info', [
          category.name,
          category.code
        ])
      : null;
  },
  productCategoryInfo: (_state, { productCategoriesWithTreeName }) => id => {
    const category = productCategoriesWithTreeName.find(c => c.id === id);
    return category
      ? i18n.t('app.product_category_info', [
          category.name,
          category.code,
          category.products_count
        ])
      : null;
  },
  productCategoryName: ({ productCategories }) => id => {
    const category = productCategories.find(c => c.id === id);
    return category ? category.name : null;
  },
  productCategoryIdFromPath(_state, _getters, rootState) {
    const hit = rootState.currentPath.match(/\d+$/);
    return hit ? parseInt(hit[0]) : null;
  },
  productCategoryAncestorsWithSelf: ({ productCategories }) => id => {
    const productCategoriesMap = _.keyBy(productCategories, 'id');

    let category = productCategories.find(c => c.id === id);
    const categories = [
      {
        id: category.id,
        name: category.name
      }
    ];

    while (category.parent_category_id) {
      category = productCategoriesMap[category.parent_category_id];
      categories.unshift({
        id: category.id,
        name: category.name
      });
    }
    return categories;
  },
  productCategoryNamesMap(_state, { productCategoriesWithTreeName }) {
    return productCategoriesWithTreeName.reduce(
      (result, category) => ({
        ...result,
        [category.id]: category.name
      }),
      {}
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
