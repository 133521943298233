<template>
  <Component :is="component" v-bind="buttonProps" size="small" @click="click" />
</template>

<script>
export default {
  name: 'AppResourceTableSearchbarBatchButton',
  props: {
    controlType: { type: String, default: 'button' },
    button: { type: Object, required: true }
  },
  computed: {
    component() {
      switch (this.controlType) {
        case 'menu':
          return 'AppDropdownMenuButton';
        default:
          return 'AppButton';
      }
    },
    buttonProps() {
      const { label, tooltip, disabled, menuItems } = this.button;

      switch (this.controlType) {
        case 'menu':
          return {
            label,
            menuItems: menuItems.map(item => ({
              ...item,
              clickHandler: ({ close }) => {
                close();
                if (item.clickHandler) item.clickHandler();
              }
            }))
          };
        default:
          return { label, tooltip, disabled };
      }
    }
  },
  methods: {
    click() {
      if (this.controlType !== 'button') return;

      const { clickHandler } = this.button;
      if (clickHandler) clickHandler();
    }
  }
};
</script>
