<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="campaign.name"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId: 'campaign[widget_recommendation_settings]'
    }"
    v-on="formEvents"
  />
</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'TargetCampaignSettingsWidgetShowLoaderImageDialog',
  mixins: [DialogFormView],
  props: { campaign: { type: Object, required: true } },
  data: () => ({ isLoading: true }),
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'show_loader_image',
              type: 'checkbox',
              label: this.$t('form.show_loader_image.label'),
              description: this.$t('form.show_loader_image.description')
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get(
        `/target/campaigns/${this.campaign.id}/widget_recommendation_settings`
      )
      .then(({ data }) => {
        this.orgFormObject = data.widget_recommendation_settings;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/target/campaigns/${this.campaign.id}/widget_recommendation_settings`,
          formData,
          { successMessage: this.$t('app.saved') }
        )
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "로딩 중 표시 설정",
  "form": {
    "show_loader_image": {
      "label": "로딩 중 표시",
      "description": "쇼핑몰 페이지에 위젯이 로딩되기 전까지 로딩 중 GIF를 표시합니다.<br />한 페이지에 설치된 여러 개의 캠페인에 로딩 중 표시를 설정하는 경우, 로딩 중 GIF가 중복 표시되니 유의하시기 바랍니다."
    }
  }
}
</i18n>
