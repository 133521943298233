<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <div class="ui-section__item">
        <AppButton
          :tooltip="{ message: TOOLTIP, maxWidth: '440px' }"
          label="Hover on me"
        />
      </div>
      <div class="ui-section__item--center">
        <AppButton
          :tooltip="{ message: TOOLTIP, maxWidth: '440px' }"
          label="Hover on me"
        />
      </div>
      <div class="ui-section__item--right">
        <AppButton
          :tooltip="{ message: TOOLTIP, maxWidth: '440px' }"
          label="Hover on me"
        />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Horizontal</label>
      </div>
      <div class="ui-section__item">
        <AppButton
          :tooltip="{ message: TOOLTIP, maxWidth: '440px', horizontal: true }"
          label="Hover on me"
        />
      </div>
      <div class="ui-section__item ui-section__item--center">
        <AppButton
          :tooltip="{ message: TOOLTIP, maxWidth: '440px', horizontal: true }"
          label="Hover on me"
        />
      </div>
      <div class="ui-section__item ui-section__item--right">
        <AppButton
          :tooltip="{ message: TOOLTIP, maxWidth: '440px', horizontal: true }"
          label="Hover on me"
        />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Various Samples</label>
      </div>
      <div class="ui-section__item">
        <AppButton tooltip="This is a tooltip" label="Hover on me" />
      </div>
      <div class="ui-section__item">
        <AppButton
          :tooltip="
            '최근 입력일: 2019.5.16 13:32:36\nEmail: someone@cre.ma\nIP: 127.0.0.1'
          "
          label="Multi-line Tooltip"
        />
      </div>
      <div class="ui-section__item">
        <AppButton
          :tooltip="{ message: '메뉴 닫기', info: 'cmd + /' }"
          label="Tooltip with Additional Info"
        />
      </div>
      <div class="ui-section__item">
        <AppButton
          :tooltip="{
            textAlign: 'left',
            message:
              '어떤 정보는 좌측 정렬이 좋습니다\n1. 가독성 확보가 중요하거나\n2. 기타 이유'
          }"
          label="Left-aligned Tooltip"
        />
      </div>
      <div class="ui-section__item">
        <AppButton
          :tooltip="
            'This is a very very very very very very very very very very very very very very very very long tooltip.'
          "
          label="Long Tooltip"
        />
      </div>
      <div class="ui-section__item">
        <AppButton
          :tooltip="{ message: asyncMessage('crema', 'CREMA') }"
          label="Tooltip with Promise"
        />
      </div>
      <div class="ui-section__item">
        <AppButton
          :tooltip="{ message: 'White background is awesome!', style: 'white' }"
          label="Tooltip Styling"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTooltip',
  data: function() {
    return {
      TOOLTIP:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tortor vitae purus faucibus ornare. Sed risus pretium quam vulputate dignissim suspendisse in. Lacus sed viverra tellus in hac habitasse. Aliquam purus sit amet luctus venenatis lectus magna fringilla. Placerat in egestas erat imperdiet. Hendrerit dolor magna eget est lorem ipsum dolor sit amet. Non sodales neque sodales ut. Facilisi cras fermentum odio eu feugiat. Et magnis dis parturient montes nascetur. Mauris a diam maecenas sed enim ut. Cursus euismod quis viverra nibh cras pulvinar mattis. Lobortis mattis aliquam faucibus purus in massa tempor. Id porta nibh venenatis cras sed felis eget velit. Adipiscing commodo elit at imperdiet. Lobortis mattis aliquam faucibus purus in massa. Velit euismod in pellentesque massa placerat duis ultricies. Nullam eget felis eget nunc lobortis mattis. Nam libero justo laoreet sit amet cursus sit amet.'
    };
  },
  methods: {
    asyncMessage(...params) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(
            `Hi, I'm a message from async function!<br>Params: ${params}`
          );
        }, 2000);
      });
    }
  }
};
</script>
