<template>
  <div class="TargetSettingsThirdParty">
    <div class="TargetSettingsThirdParty__head">{{ $t('title') }}</div>
    <BizMessageSettings :service-type="CremaServiceType.TARGET" />
  </div>
</template>

<script>
import CremaServiceType from '@/enums/CremaServiceType';
import BizMessageSettings from '@/views/biz_messages/components/BizMessageSettings.vue';

export default {
  name: 'TargetSettingsThirdParty',
  components: { BizMessageSettings },
  computed: {
    CremaServiceType: () => CremaServiceType
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetSettingsThirdParty__head {
  @include text-sub-title;
  margin-bottom: 32px;
}
</style>

<i18n locale="ko">
{
  "title": "알림톡"
}
</i18n>
