<template>
  <div :class="['AppColorChip', size ? `AppColorChip--${size}` : null]">
    <div
      v-for="(color, i) in colors"
      :key="i"
      :style="{
        height: `${100 / colors.length}%`,
        backgroundColor: color
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'AppColorChip',
  props: {
    colors: { type: Array, required: true },
    size: {
      type: String,
      default: 'medium',
      validator: v => ['small', 'medium'].includes(v)
    }
  }
};
</script>

<style lang="scss" scoped>
.AppColorChip {
  width: 1em;
  height: 1em;
  display: inline-block;
  line-height: 0;
  overflow: hidden;

  & + & {
    margin-left: 4px;
  }

  &--small {
    width: 14px;
    height: 14px;
    border-radius: 2px;
  }

  &--medium {
    width: 32px;
    height: 32px;
    border-radius: 3px;
  }
}
</style>
