<template>
  <div>
    <AppSearchbar>
      <li>
        <AppButton
          button-style="red"
          :label="$t('add_brand_notice')"
          @click="addBrandNotice"
        />
      </li>
    </AppSearchbar>
    <AppAjaxContent :is-loading="isLoading || isSubmitting">
      <AppSmallDeviceScrollable>
        <AppTable
          table-id="review-settings-brand-notice-table"
          :columns="COLUMNS"
          :rows="rows"
        >
          <template #cell="{ row, value, column, rowIndex }">
            <template v-if="column.id === 'title'">
              <a class="hover-underline" @click="showBrandNotice(row)">
                {{ value }}
              </a>
            </template>
            <template v-else-if="column.id === 'actions'">
              <AppButtonDraggable
                :drag-text="row.title"
                :tooltip="$t('tooltip')"
                @sort="sort"
              />
              <AppButton
                :label="$t('app.show')"
                @click="showBrandNotice(row)"
              />
              <AppButton
                :label="$t('app.edit')"
                @click="editBrandNotice(rowIndex)"
              />
              <AppButton
                button-style="red-outline"
                :label="$t('app.delete')"
                @click="deleteBrandNotice(rowIndex)"
              />
            </template>
          </template>
        </AppTable>
      </AppSmallDeviceScrollable>
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import { arrayMoveIndex } from '@/lib/array';
import { mapActions } from 'vuex';

export default {
  name: 'ReviewSettingsNotice',
  data() {
    return {
      COLUMNS: [
        { id: 'position', label: this.$t('position') },
        { id: 'title', label: this.$t('title'), align: 'left' },
        { id: 'created_at', label: this.$t('app.created_at'), type: 'date' },
        { id: 'pc', label: this.$t('pc'), type: 'check' },
        { id: 'mobile', label: this.$t('mobile'), type: 'check' },
        { id: 'actions', label: this.$t('app.actions') }
      ],
      isLoading: true,
      isSubmitting: false,
      brandNotices: []
    };
  },
  computed: {
    rows() {
      return this.brandNotices.map(
        ({ id, title, message, created_at, pc, mobile }, index) => ({
          id,
          position: index + 1,
          title,
          message,
          created_at,
          pc,
          mobile
        })
      );
    }
  },
  mounted() {
    api
      .get('/brand_notices')
      .then(({ data }) => (this.brandNotices = data.brand_notices))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    addBrandNotice() {
      this.openDialog('ReviewSettingsNoticeFormDialog').then(eventBus => {
        eventBus.$on('save', brandNotice =>
          this.brandNotices.unshift(brandNotice)
        );
      });
    },
    showBrandNotice(brandNotice) {
      this.openDialog(['ReviewSettingsNoticeShowDialog', { brandNotice }]);
    },
    editBrandNotice(rowIndex) {
      this.openDialog([
        'ReviewSettingsNoticeFormDialog',
        { orgBrandNotice: this.brandNotices[rowIndex] }
      ]).then(eventBus => {
        eventBus.$on('save', brandNotice =>
          this.brandNotices.splice(rowIndex, 1, brandNotice)
        );
      });
    },
    sort({ srcIndex, dstIndex }) {
      this.brandNotices = arrayMoveIndex(this.brandNotices, srcIndex, dstIndex);
      api.patch('/brand_notices/sort', {
        ids: this.brandNotices.map(({ id }) => id)
      });
    },
    deleteBrandNotice(rowIndex) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      this.isSubmitting = true;
      api
        .delete(`/brand_notices/${this.brandNotices[rowIndex].id}`, {
          successMessage: this.$t('deleted')
        })
        .then(() => this.brandNotices.splice(rowIndex, 1))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .review-settings-brand-notice-table__position {
    width: 68px;
  }

  .review-settings-brand-notice-table__created-at {
    width: 95px;
  }

  .review-settings-brand-notice-table__pc,
  .review-settings-brand-notice-table__mobile {
    width: 103px;
  }

  .review-settings-brand-notice-table__actions {
    width: 288px;
  }
}
</style>

<i18n locale="ko">
{
  "add_brand_notice": "공지사항 등록",
  "position": "표시 순서",
  "title": "제목",
  "pc": "PC에서 표시",
  "mobile": "모바일에서 표시",
  "tooltip": "클릭한 상태로 이동하면 순서를 변경할 수 있습니다.",
  "deleted": "공지사항이 삭제됐습니다."
}
</i18n>
