<template>
  <AppModalForm
    :title="reviewId ? $t('title_edit') : $t('title_create')"
    :sub-title="subTitle"
    :form-props="{
      ...formProps,
      objectId: 'review',
      groupLabelNamespace: 'review',
      focusGroupId
    }"
    :is-loading="isLoading"
    v-on="formEvents"
  >
    <template v-if="!reviewId">
      <input type="hidden" name="review[product_id]" :value="productId" />
    </template>
    <template #group="{ id, review_option, value, errors }">
      <div v-if="!reviewId && id == 'review_type'" class="AppForm__group-field">
        <AppSelect
          :options="ManagerReviewType.options()"
          :value="review.review_type"
          @change="$set(review, 'review_type', $event)"
        />
        <div class="AppForm__description">
          {{ $t('review_type.description') }}
          <span
            class="ReviewReviewFormDialog__show_example"
            @click="alertAboutManagerReview"
          >
            {{ $t('review_type.open_dialog_text') }}
          </span>
        </div>
      </div>
      <div
        v-else-if="id.startsWith('option_type_')"
        class="AppForm__group-field"
      >
        <input
          type="hidden"
          name="review[review_options_attributes][][id]"
          :value="review_option.id"
        />
        <input
          type="hidden"
          :name="
            reviewSettings.use_product_category_product_type
              ? 'review[review_options_attributes][][review_option_type_set_id]'
              : 'review[review_options_attributes][][review_option_type_id]'
          "
          :value="review_option.type_id"
        />
        <Component
          :is="
            review_option.field_type === 'number'
              ? 'AppNumberInput'
              : review_option.field_type === 'select'
              ? 'AppSelect'
              : 'AppTextInput'
          "
          :value="value"
          v-bind="
            review_option.field_type === 'number'
              ? { digits: 7 }
              : review_option.field_type === 'select'
              ? { options: review_option.options }
              : {}
          "
          name="review[review_options_attributes][][value]"
          @change="setOptionValue(review_option, $event)"
        />
        <span
          v-if="
            ['number', 'select'].includes(review_option.field_type) &&
              review_option.unit
          "
          >&nbsp;{{ review_option.unit }}</span
        >
      </div>
      <div v-else-if="id == 'product_options'" class="AppForm__group-field">
        <div class="table-head-control">
          <AppButton
            :label="$t('product_options.add_button')"
            @click="addProductOption"
          />
        </div>
        <AppTable
          valign-baseline
          :columns="[
            { id: 'key', label: $t('product_options.key') },
            { id: 'value', label: $t('product_options.value') },
            { id: 'actions', label: $t('app.actions') }
          ]"
          :rows="productOptions"
          :no-data="$t('product_options.no_data')"
        >
          <template #cell="{ row, column, rowIndex }">
            <template v-if="column.id === 'key'">
              <AppTextInput
                :ref="`productOptionKey${row.id}`"
                v-model="productOptions[rowIndex].key"
                name="review[product_options_attributes][][key]"
                :placeholder="$t('product_options.key_placeholder')"
                :invalid="!!errors[`product_options[${row.id}][key]`]"
                @blur="validateField(`product_options[${row.id}][key]`)"
                @change="validateProductOptionKey(row, errors)"
              />
              <AppFormError
                :error="errors[`product_options[${row.id}][key]`]"
              />
            </template>
            <template v-else-if="column.id === 'value'">
              <AppTextInput
                v-model="productOptions[rowIndex].value"
                name="review[product_options_attributes][][value]"
                :placeholder="$t('product_options.value_placeholder')"
                :invalid="!!errors[`product_options[${row.id}][value]`]"
                @blur="validateField(`product_options[${row.id}][value]`)"
                @change="validateField(`product_options[${row.id}][value]`)"
              />
              <AppFormError
                :error="errors[`product_options[${row.id}][value]`]"
              />
            </template>
            <template v-else-if="column.id === 'actions'">
              <AppButton
                button-style="red-outline"
                :label="$t('app.delete')"
                @click="productOptions.splice(rowIndex, 1)"
              />
            </template>
          </template>
        </AppTable>
      </div>
      <ReviewReviewEditVideosTable
        v-else-if="id == 'videos'"
        ref="videos"
        v-model="review.videos"
        :disabled="videoTableDisabled"
        :is-etc-review="isEtcReview()"
        class="AppForm__group-field"
        @change="validateField('videos')"
      />
      <ReviewReviewEditImagesTable
        v-else-if="id == 'images'"
        ref="images"
        v-model="review.images"
        :disabled="imageTableDisabled"
        :is-etc-review="isEtcReview()"
        class="AppForm__group-field"
        @change="validateField('images')"
      />
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import api from '@/lib/api';
import { deleteVideos } from '@/lib/video';
import AuthorGrade from '@/enums/AuthorGrade';
import DialogSize from '@/enums/DialogSize';
import ReviewSource from '@/enums/ReviewSource';
import SocialMediaType from '@/enums/SocialMediaType';
import ManagerReviewType from '@/enums/ManagerReviewType';
import ReviewOptionType from '@/enums/ReviewOptionType';

import DialogFormView from '@/mixins/DialogFormView';
import Scrollable from '@/mixins/Scrollable';
import ReviewReviewEditImagesTable from './ReviewReviewEditImagesTable';

export default {
  name: 'ReviewReviewFormDialog',
  components: { ReviewReviewEditImagesTable },
  mixins: [DialogFormView, Scrollable],
  props: {
    reviewId: {
      type: Number,
      default: null
    },
    productId: {
      type: Number,
      default: null
    },
    subTitle: {
      type: String,
      required: true
    },
    focusGroupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      productOptions: null,
      userGrades: null,
      authorTypes: null,
      AUTO_COMPLETE_FIELDS: [
        'user_name',
        'user_username',
        'brand_user_grade_id',
        'brand_author_type_id',
        'author_grade'
      ]
    };
  },
  computed: {
    ...mapState('session', ['reviewSettings']),
    ...mapGetters('session', [
      'isShopifyBrand',
      'adminLocale',
      'isListV3InstallationEnabled'
    ]),
    ReviewSource() {
      return ReviewSource;
    },
    ReviewOptionType() {
      return ReviewOptionType;
    },
    ManagerReviewType() {
      return ManagerReviewType;
    },
    reviewType() {
      if (this.isManagerReview) {
        return ManagerReviewType.t(
          this.isSocialReview
            ? ManagerReviewType.SOCIAL
            : ManagerReviewType.NORMAL
        );
      } else {
        return this.isSocialReview
          ? this.$t('review_type.social_review')
          : this.$t('review_type.normal_review');
      }
    },
    isManagerReview() {
      return this.review.review_source === ReviewSource.MANAGER;
    },
    isSocialReview() {
      return this.review.social_media_type !== SocialMediaType.NONE;
    },
    isImportedReview() {
      return this.review.review_source === ReviewSource.IMPORTED;
    },
    optionTypeStorageNamePrefix() {
      return `option_type_${this.reviewSettings.use_product_category_product_type}_`;
    },
    formSections() {
      if (this.isLoading) return [];

      const USER_NAME_LENGTH_LIMIT = 32;
      const USER_USERNAME_LENGTH_LIMIT = 100;
      const PERFECT_SCORE = 5;
      return [
        {
          id: 'review_type',
          label: null,
          groups: (this.reviewId
            ? [
                { id: 'review_type', type: 'static', value: this.reviewType },
                this.isSocialReview
                  ? { id: 'review_source_url', type: 'static' }
                  : null
              ]
            : [
                { id: 'review_type' },
                this.review.review_type === ManagerReviewType.SOCIAL
                  ? {
                      id: 'review_source_url',
                      type: 'text',
                      tipMessage: this.$t('review_source_url_tip_message'),
                      placeholder: this.$t('review_source_url_placeholder'),
                      hint: this.reviewSettings.use_video_reviews
                        ? this.$t(
                            'review_source_url_hint.use_video_reviews_true'
                          )
                        : this.$t(
                            'review_source_url_hint.use_video_reviews_false'
                          ),
                      validate: [
                        'required',
                        'url_format',
                        {
                          rule: v => this.isSupportVideoReviewSource(v),
                          errorMessage: this.$t(
                            'unsupported_video_review_source_url'
                          )
                        },
                        { rule: 'check_social_url', async: true }
                      ]
                    }
                  : null
              ]
          ).filter(v => v)
        },
        {
          id: 'review_info',
          label: this.$t('sections.review_info'),
          groups: [
            {
              id: 'user_name',
              type: 'text',
              placeholder: this.$t('user_name_placeholder'),
              validate: [
                'required',
                { rule: 'max_length', limit: USER_NAME_LENGTH_LIMIT }
              ]
            },
            Number(this.review.author_grade) !== AuthorGrade.NONMEMBER
              ? {
                  id: 'user_username',
                  type: 'text',
                  placeholder: this.$t('user_username_placeholder'),
                  validate: [
                    'required',
                    { rule: 'max_length', limit: USER_USERNAME_LENGTH_LIMIT }
                  ]
                }
              : {
                  id: 'user_username',
                  type: 'static',
                  value: '-'
                },
            this.isShopifyBrand
              ? null
              : {
                  id: 'brand_user_grade_id',
                  label: this.$t('review.review_user_grade'),
                  type: 'select',
                  options: this.userGrades.map(({ id, name }) => ({
                    label: name,
                    value: id
                  })),
                  placeholder: this.$t('brand_user_grade_id_placeholder')
                },
            this.authorTypes.length
              ? {
                  id: 'brand_author_type_id',
                  label: this.$t('review.author_type'),
                  type: 'select',
                  options: this.authorTypes.map(({ id, title }) => ({
                    label: title,
                    value: id
                  })),
                  placeholder: this.$t('brand_author_type_id_placeholder')
                }
              : null,
            {
              id: 'author_grade',
              ...(this.isManagerReview
                ? {
                    type: 'select',
                    options: AuthorGrade.options().filter(
                      o => o.value !== AuthorGrade.BEST_REVIEWER
                    )
                  }
                : {
                    type: 'static',
                    value: AuthorGrade.t(this.review.author_grade)
                  })
            },
            this.reviewId
              ? {
                  id: 'posted_at',
                  type: 'static',
                  value: this.formatDateTime(this.review.created_at)
                }
              : null,
            this.isManagerReview
              ? {
                  id: 'score',
                  type: 'number',
                  digits: 1,
                  validate: [
                    'required',
                    'positive_integer',
                    { rule: 'lte', allowed: 5 }
                  ]
                }
              : {
                  id: 'score',
                  type: 'static',
                  value: this.review.score
                    ? `${this.review.score}/${PERFECT_SCORE}`
                    : this.$t('without_score')
                },
            this.reviewId
              ? {
                  id: 'likes_score',
                  type: 'static',
                  value: this.$t('review.likes_score_text', this.review)
                }
              : null,
            ...this.review.review_options.map(review_option => {
              const { type_id } = review_option;
              return {
                id: `option_type_${type_id}`,
                review_option,
                label: review_option.name,
                value: review_option.value,
                validate:
                  review_option.required && !this.isImportedReview
                    ? ['required']
                    : null
              };
            }),
            {
              id: 'product_options',
              fields: _.flatten(
                this.productOptions.map(option => [
                  {
                    id: `product_options[${option.id}][key]`,
                    value: option.key,
                    validate: [
                      'required',
                      {
                        rule: v => this.productOptionKeyCountsMap[v] === 1,
                        errorMessage: this.$t(
                          'product_option_key_must_be_unique'
                        )
                      }
                    ]
                  },
                  {
                    id: `product_options[${option.id}][value]`,
                    value: option.value,
                    validate: ['required']
                  }
                ])
              )
            }
          ].filter(v => v)
        },
        this.review.review_type === ManagerReviewType.SOCIAL
          ? null
          : {
              id: 'review_media',
              label: this.$t('sections.review_media.label'),
              ...(this.isEtcReview()
                ? { hint: this.$t('sections.review_media.hint') }
                : {}),
              groups: [
                this.reviewSettings.use_video_reviews ||
                this.review.videos.length > 0
                  ? {
                      id: 'videos',
                      validate: [
                        {
                          rule: () =>
                            this.review.videos.every(
                              v => !v.invalid && !v.isUploading
                            ),
                          errorMessage: this.$t('validations.video_uploading')
                        }
                      ]
                    }
                  : null,
                {
                  id: 'images',
                  validate: [
                    {
                      rule: () =>
                        this.review.images.every(i => i.url || i.file),
                      errorMessage: this.$t('validations.required')
                    }
                  ]
                }
              ].filter(v => v)
            },
        {
          id: 'review_message',
          label: this.$t('sections.review_message'),
          groups: [
            {
              id: 'message',
              type: 'textarea',
              rows: 13,
              placeholder: this.$t('message_placeholder'),
              validate: ['required']
            }
          ]
        },
        !this.reviewId && this.isManagerReview
          ? {
              id: 'review_setting',
              label: this.$t('sections.review_setting'),
              groups: [
                {
                  id: 'pin',
                  name: 'pin',
                  type: 'checkbox',
                  label: this.$t('review.pin')
                }
              ]
            }
          : null
      ].filter(v => v);
    },
    review() {
      return this.formObject;
    },
    productOptionKeyCountsMap() {
      return _.countBy(this.productOptions, 'key');
    },
    videoTableDisabled() {
      return (
        this.review.videos.length + this.review.images.length >= 4 ||
        !this.reviewSettings.use_video_reviews
      );
    },
    imageTableDisabled() {
      return this.review.videos.length + this.review.images.length >= 4;
    },
    managerReviewDescImage() {
      return this.adminLocale === 'ko' && this.isListV3InstallationEnabled
        ? 'https://assets.cre.ma/m/admin/v4/manager_review_alert.png'
        : 'https://assets.cre.ma/m/admin/v3/manager_review_alert.png';
    }
  },
  watch: {
    productOptions: {
      handler(newProductOptions, oldProductOptions) {
        if (!oldProductOptions) return;

        this.review.product_options = _.fromPairs(
          this.productOptions.map(o => [o.key, o.value])
        );
      },
      deep: true
    }
  },
  mounted() {
    api
      .get(
        this.reviewId
          ? `/review/reviews/${this.reviewId}/edit`
          : '/review/reviews/new?product_id=' + this.productId
      )
      .then(({ data }) => {
        const { review, review_option_types, user_grades, author_types } = data;
        const review_options = this.reviewOptions(review, review_option_types);
        this.productOptions = _.map(
          this.reviewId
            ? review.product_options
            : JSON.parse(localStorage.getItem(`product_id_${this.productId}`)),
          (value, key) => ({
            id: _.uniqueId('product_option'),
            value,
            key
          })
        );
        this.userGrades = user_grades;
        this.authorTypes = author_types;
        this.orgFormObject = {
          ...review,
          review_options,
          images: review.images.map(({ url }) => ({
            id: _.uniqueId('image'),
            url,
            rotation: 0
          })),
          videos: review.videos.map(
            ({ id, filename, is_being_encoded, thumbnail_url }) => ({
              uniqueId: id,
              v_id: id,
              filename,
              is_being_encoded,
              thumbnail_url
            })
          ),
          review_type: this.reviewId ? null : ManagerReviewType.NORMAL
        };
        if (!this.reviewId) {
          this.AUTO_COMPLETE_FIELDS.forEach(
            field => (this.orgFormObject[field] = localStorage.getItem(field))
          );
        }
        this.isLoading = false;
        this.focusOnContext();
      })
      .catch(this.close);
  },
  methods: {
    ...mapActions('review', ['updateReview']),
    ...mapActions('dialog', ['openDialog']),
    reviewOptions({ review_options }, review_option_types) {
      return review_option_types.map(({ type_id, options, ...optionType }) => {
        const value = this.reviewId
          ? null
          : localStorage[`${this.optionTypeStorageNamePrefix}${type_id}`];
        const option = review_options.find(o => o.type_id === type_id) || {
          type_id,
          value
        };

        return {
          ...optionType,
          ...option,
          options: _.map(options, (v, k) => ({ label: k, value: v }))
        };
      });
    },
    submit(formData) {
      this.isSubmitting = true;
      if (this.reviewId) this.submitUpdate(formData);
      else this.submitCreate(formData);
    },
    submitUpdate(formData) {
      this.updateReview({
        reviewId: this.review.id,
        formData,
        successMessage: this.$t('app.saved')
      })
        .then(({ data }) => {
          this.eventBus.$emit('save', { ...this.review, ...data.review });
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    submitCreate(formData) {
      this.saveFormDataToLocalStorage(formData);

      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t('manager_review.confirm_title'),
          markdownText: this.$t('manager_review.markdown_text', {
            image_asset_link: this.managerReviewDescImage
          }),
          snoozeId: 'ReviewReviewFormDialog.manager_review',
          closeButtonLabel: this.$t('app.save'),
          width: DialogSize.SMALL
        }
      ])
        .then(eventBus => {
          eventBus.$on('cancel', () => (this.isSubmitting = false));
          eventBus.$on('close', () => this.post(formData));
        })
        .catch(() => this.post(formData));
    },
    saveFormDataToLocalStorage(formData) {
      this.AUTO_COMPLETE_FIELDS.forEach(field =>
        localStorage.setItem(field, formData.get(`review[${field}]`))
      );
      this.review.review_options
        .filter(o => o.value)
        .forEach(o =>
          localStorage.setItem(
            `${this.optionTypeStorageNamePrefix}${o.type_id}`,
            o.value
          )
        );
      const productOptionsMap = _.fromPairs(
        this.productOptions.map(o => [o.key, o.value])
      );
      localStorage.setItem(
        `product_id_${this.productId}`,
        JSON.stringify(productOptionsMap)
      );
    },
    post(formData) {
      api
        .post('/review/reviews', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.eventBus.$emit('create', data.product);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    setOptionValue({ type_id }, newValue) {
      const option = this.formObject.review_options.find(
        o => o.type_id === type_id
      );
      option.value = _.toString(newValue);
      this.validateField(`option_type_${type_id}`);
    },
    addProductOption() {
      const id = _.uniqueId('product_option');
      this.productOptions.unshift({ id });
      this.$nextTick(() => {
        this.$refs[`productOptionKey${id}`].$el.focus();
      });
    },
    focusOnContext() {
      this.$nextTick(() => {
        if (this.focusGroupId === 'images')
          this.scrollToElement(this.$refs.images.$el);
        else if (this.focusGroupId === 'videos')
          this.scrollToElement(this.$refs.videos.$el);
      });
    },
    validateProductOptionKey(productOption, errors) {
      this.validateField(`product_options[${productOption.id}][key]`);
      Object.keys(errors).forEach(id => this.validateField(id));
    },
    beforeCloseDialog() {
      const orgVideoIds = this.orgFormObject.videos.map(v => v.v_id);
      const videoIds = this.review.videos.map(v => v.v_id).filter(id => id);

      if (this.reviewId) {
        const newVideoIds = videoIds.filter(id => !orgVideoIds.includes(id));
        deleteVideos(newVideoIds);
      } else {
        deleteVideos(videoIds);
      }
    },
    isSupportVideoReviewSource(url) {
      if (url && url.match(/youtu/)) {
        return this.reviewSettings.use_video_reviews;
      }
      return true;
    },
    alertAboutManagerReview() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('manager_review.alert_title'),
          width: DialogSize.SMALL,
          markdownText: this.$t('manager_review.markdown_text', {
            image_asset_link: this.managerReviewDescImage
          })
        }
      ]);
    },
    isEtcReview() {
      return this.review.review_source === ReviewSource.ETC;
    }
  }
};
</script>

<style scoped>
.ReviewReviewFormDialog__show_example {
  cursor: pointer;
  text-decoration: underline;
}
</style>

<i18n locale="ko">
{
  "title_edit": "리뷰 수정",
  "title_create": "스탭 리뷰 작성",
  "review_type": {
    "normal_review": "일반 리뷰",
    "social_review": "소셜 리뷰",
    "description": "스탭 리뷰로 작성할 경우, 위젯에서 스탭 리뷰로 등록한 리뷰라는 안내문구를 표시합니다.",
    "open_dialog_text": "적용 예시 확인하기"
  },
  "manager_review": {
    "alert_title": "스탭 리뷰 작성시 안내 문구가 표시됩니다",
    "confirm_title": "스탭 리뷰를 작성하시겠습니까?",
    "markdown_text": " 스탭 리뷰를 작성하면 아래 이미지와 같이 쇼핑몰 리뷰 위젯에<br>**‘스탭 리뷰로 등록한 리뷰’** 라는 안내 문구를 표시합니다.<br><br>![]({image_asset_link})"
  },
  "sections": {
    "review_info": "리뷰 정보",
    "review_media": {
      "label": "리뷰 첨부 포토/동영상",
      "hint": "외부 연동한 리뷰의 경우, 첨부 포토/동영상의 삭제만 가능합니다."
    },
    "review_message": "리뷰 본문",
    "review_setting": "리뷰 설정"
  },
  "product_options": {
    "add_button": "+ 구매 옵션 추가",
    "key": "상품 옵션",
    "key_placeholder": "옵션 이름",
    "value": "구매 시 선택한 상품 옵션",
    "value_placeholder": "선택 옵션 내용",
    "no_data": "구매 옵션이 없습니다."
  },
  "review_source_url_hint": {
    "use_video_reviews_false": "공유하고 싶은 Instagram, 네이버 블로그의 게시물 주소를 입력해 주세요",
    "use_video_reviews_true": "공유하고 싶은 Instagram, Youtube, 네이버 블로그의 게시물 주소를 입력해 주세요"
  },
  "review_source_url_tip_message": "인스타그램은 본문을 가져올 수 없으므로, 본문이 필요한 경우 직접 복사하여 아래의 리뷰 작성 본문에 입력해주세요.",
  "review_source_url_placeholder": "주소를 입력해주세요.",
  "unsupported_video_review_source_url": "등록할 수 없는 게시물의 주소입니다. 유튜브 링크는 동영상 리뷰를 사용하시면 등록할 수 있습니다. 자세한 사항은 크리마 운영팀으로 연락주세요.",
  "user_name_placeholder": "이름을 입력해주세요.",
  "user_username_placeholder": "아이디를 입력해주세요.",
  "brand_user_grade_id_placeholder": "등급을 선택해주세요.",
  "brand_author_type_id_placeholder": "타입을 선택해주세요.",
  "message_placeholder": "본문을 입력해주세요.",
  "product_option_key_must_be_unique": "같은 이름의 상품 옵션이 있습니다.",
  "without_score": "별점없음"
}
</i18n>
<i18n locale="en">
{
  "title_edit": "Edit review",
  "title_create": "Write a staff review",
  "review_type": {
    "normal_review": "Review",
    "social_review": "Social media review",
    "description": "If you write as a staff review, the widget displays a notice saying that the review is registered as a staff review.",
    "open_dialog_text": "Show example"
  },
  "manager_review": {
    "alert_title": "A guide message is displayed when writing a staff review",
    "confirm_title": "Would you like to write a staff review?",
    "markdown_text": "If you write a staff review, as shown in the image below, <br>**‘Review registered as staff review’** is displayed on the review widget.<br><br>![]({image_asset_link})"
  },
  "sections": {
    "review_info": "Review info",
    "review_media": {
      "label": "Video/Image",
      "hint": "For externally linked reviews, only the deletion of attached photos/videos is possible."
  },
    "review_message": "Review message",
    "review_setting": "Review setting"
  },
  "product_options": {
    "add_button": "+ Add product options",
    "key": "Product options",
    "key_placeholder": "Product option name",
    "value": "Product options selected when purchased",
    "value_placeholder": "Selection option",
    "no_data": "No product options"
  },
  "review_source_url_hint": {
    "use_video_reviews_false": "Please enter the address of the Instagram post you would like to share",
    "use_video_reviews_true": "Please enter the address of the Instagram, Youtube post you would like to share."
  },
  "review_source_url_placeholder": "Please enter an address.",
  "unsupported_video_review_source_url": "This address cannot be allowed. YouTube links can be registered by using video reviews. For details, please contact the CREMA CS team.",
  "user_name_placeholder": "Customer's name",
  "user_username_placeholder": "Customer's ID",
  "brand_user_grade_id_placeholder": "Select user grade.",
  "brand_author_type_id_placeholder": "Select author type.",
  "message_placeholder": "Message",
  "product_option_key_must_be_unique": "There is a product option with the same name.",
  "without_score": "No score"
}
</i18n>
