<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #right>
        <li>
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="SEARCH_TYPES"
          />
        </li>
        <li>
          <AppButtonToggle
            v-model="advancedSearchVisible"
            :label="$t('app.advanced_search')"
          />
        </li>
      </template>
      <template #advanced-search>
        <ReviewProductsAdvancedSearch
          v-model="resourceParams"
          :visible="advancedSearchVisible"
          @submit="searchResource"
        />
      </template>
    </AppSearchbar>

    <AppResourceTable
      table-id="review-products-table"
      enable-refresh
      enable-column-settings
      :resources="products"
      :columns="COLUMNS"
      :rows="rows"
      @refresh="refreshResource"
      @paginate="paginate"
    >
      <template #cell="{ row, column, rowIndex }">
        <template v-if="column === 'code'">
          <div>{{ row.code }}</div>
          <AppBadge
            v-if="row.deleted"
            class="mt4"
            badge-style="red-outline"
            :label="$t('deleted.label')"
            :tooltip="$t('deleted.tooltip')"
          />
        </template>
        <template v-else-if="column === 'message_sentiment_analysis'">
          <template
            v-if="
              Object.keys(row.review_messages_sentiment_analysis_reports)
                .length === 0
            "
          >
            {{ '-' }}
          </template>
          <template v-else>
            <AppBadge
              v-for="sentiment in [
                ReviewSentimentType.POSITIVE,
                ReviewSentimentType.NEUTRAL,
                ReviewSentimentType.NEGATIVE
              ]"
              :key="`${rowIndex}_${sentiment}`"
              :badge-style="
                row.review_messages_sentiment_analysis_reports.mode ===
                sentiment
                  ? formatBadgeStyle(sentiment)
                  : 'grey-outline'
              "
              :label="messageSentimentAnalysisBadge(row, sentiment)"
            />
          </template>
        </template>
        <template v-else-if="column === 'keyword_sentiment_analysis'">
          <div
            v-if="row.review_keywords_sentiment_analysis_reports.length"
            v-tooltip="{
              message: row.keywordSentimentAnalysisTooltip,
              info: `\n${$t('app.crema_ai_review')}`,
              textAlign: 'left'
            }"
          >
            <AppBadge
              v-for="report in row.review_keywords_sentiment_analysis_reports"
              :key="report.key"
              :badge-style="formatBadgeStyle(report.type)"
              :label="reviewTagTypeNameMap[report.key]"
            />
          </div>
          <div v-else>{{ '-' }}</div>
        </template>
        <template v-else-if="column === 'status'">
          <ul class="badges">
            <li v-if="row.has_pinned_reviews">
              <AppBadge
                badge-style="grey-outline"
                :label="$t('review_pinned')"
              />
            </li>
            <li v-if="row.is_combo">
              <AppBadge
                badge-style="grey-outline"
                :label="$t('combo_product')"
              />
            </li>
            <li v-if="row.has_manager_reviews">
              <AppBadge
                badge-style="grey-outline"
                :label="$t('manager_reviewed')"
              />
            </li>
          </ul>
        </template>
        <ReviewProductsShowReviewsButton
          v-else-if="column === 'show_reviews'"
          :product="row"
        />
        <ReviewProductsManageReviewsButton
          v-else-if="column === 'manage'"
          :product="row"
          @create="managerReviewCreated(rowIndex, $event)"
        />
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import api from '@/lib/api';
import _ from 'lodash';

import ResourceView from '@/mixins/ResourceView';
import ReviewSentimentTypeHelper from '@/mixins/ReviewSentimentTypeHelper';
import { nullResources } from '@/lib/resources';
import { REVIEW_PERFECT_SCORE } from '@/constants/review';
import ReviewSentimentType from '@/enums/ReviewSentimentType';

import ReviewProductsAdvancedSearch from './ReviewProductsAdvancedSearch';
import ReviewProductsShowReviewsButton from './ReviewProductsShowReviewsButton';

export default {
  name: 'ReviewProducts',
  components: { ReviewProductsAdvancedSearch, ReviewProductsShowReviewsButton },
  mixins: [ResourceView, ReviewSentimentTypeHelper],
  data() {
    return {
      SEARCH_TYPES: ['name', 'code', 'id'].map(value => ({
        value,
        label: this.$t(`product_search_type.${value}`)
      })),
      COLUMNS: [
        { id: 'id', label: this.$t('products.id'), hideByDefault: true },
        {
          id: 'product',
          label: this.$t('app.product'),
          type: 'product',
          required: true
        },
        {
          id: 'code',
          label: this.$t('products.code'),
          required: true,
          type: 'pre'
        },
        this.$store.state.session.brandSettings.show_sub_brand
          ? {
              id: 'sub_brand_name',
              label: this.$t('sub_brand_name'),
              hideByDefault: true
            }
          : null,
        {
          id: 'admin_reviews_count',
          label: this.$t('products.admin_reviews_count'),
          required: true
        },
        {
          id: 'admin_photo_reviews_count',
          label: this.$t('products.admin_photo_reviews_count')
        },
        {
          id: 'reviewed_sub_orders_percentage',
          label: this.$t('reviewed_sub_orders_percentage'),
          type: 'percentage'
        },
        { id: 'score', label: this.$t('products.score') },
        this.$store.state.session.reviewSettings.use_nlp_sentiment
          ? {
              id: 'message_sentiment_analysis',
              label: this.$t('message_sentiment_analysis.title')
            }
          : null,
        this.$store.state.session.reviewSettings.use_nlp_by_openai
          ? {
              id: 'keyword_sentiment_analysis',
              label: this.$t('keyword_sentiment_analysis.title')
            }
          : null,
        { id: 'status', label: this.$t('app.status') },
        { id: 'show_reviews', label: this.$t('show_reviews'), required: true },
        { id: 'manage', label: this.$t('manage_reviews'), required: true }
      ].filter(c => c),
      advancedSearchVisible: [
        'review_pinned',
        'combo_product',
        'manager_reviewed',
        'sub_brand_ids'
      ].some(key => key in this.$route.query),
      isLoading: true,
      products: nullResources
    };
  },
  computed: {
    ...mapGetters('session', ['reviewTagTypeNameMap']),
    ReviewSentimentType() {
      return ReviewSentimentType;
    },
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      search_type: 'name',
      sort: 'created_at_desc'
    }),
    rows() {
      return this.products.items.map(product => ({
        ...product,
        score: product.score
          ? `${product.score.toFixed(1)}/${REVIEW_PERFECT_SCORE.toFixed(1)}`
          : '-',
        product: _.pick(product, ['name', 'url', 'image_url']),
        keywordSentimentAnalysisTooltip: this.keywordSentimentAnalysisTooltip(
          product
        )
      }));
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    }
  },
  methods: {
    ...mapMutations('review', ['SET_SUB_BRANDS']),
    fetchResource(params) {
      this.isLoading = true;
      const init = this.products.isNull ? 1 : null;
      api
        .get('/review/products', { params: { ...params, init } })
        .then(({ data }) => {
          this.products = data.products;
          if (init) {
            this.SET_SUB_BRANDS(data.sub_brands || []);
          }
        })
        .finally(() => (this.isLoading = false));
    },
    managerReviewCreated(rowIndex, product) {
      this.$set(this.products.items, rowIndex, product);
    },
    messageSentimentAnalysisBadge(row, sentiment) {
      const report = row.review_messages_sentiment_analysis_reports;
      const rate = (report.detail[sentiment] || 0) / report.total_count;
      return `${ReviewSentimentType.t(sentiment)} ${Math.round(rate * 100)}%`;
    },
    keywordSentimentAnalysisTooltip({
      review_keywords_sentiment_analysis_reports
    }) {
      if (!review_keywords_sentiment_analysis_reports.length) return;

      return [ReviewSentimentType.POSITIVE, ReviewSentimentType.NEGATIVE]
        .map(v => {
          const tagTypes = review_keywords_sentiment_analysis_reports
            .filter(r => r.type === v)
            .map(r => this.reviewTagTypeNameMap[r.key]);
          if (!tagTypes.length) return;

          const keywordMessages = [];
          const keywordLabel = ReviewSentimentType.t(v);
          keywordMessages.push(
            this.$t('keyword_sentiment_analysis.analyzed_message', {
              type: keywordLabel
            })
          );
          keywordMessages.push(tagTypes.map(t => ` - ${t}`).join('\n'));
          return keywordMessages.join('\n');
        })
        .filter(v => v)
        .join('\n\n');
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppResourceTableCell {
    line-height: 23px;
    flex: 1 0 80px;
  }

  .review-products-table__id,
  .review-products-table__code,
  .review-products-table__sub-brand-name {
    flex-basis: 120px;
  }

  .review-products-table__reviews-count-detail {
    flex-basis: 150px;
  }

  .review-products-table__reviews-count,
  .review-products-table__review-rate,
  .review-products-table__score,
  .review-products-table__status {
    flex-basis: 90px;
  }

  .review-products-table__product {
    flex: 3 0 220px;
  }

  .review-products-table__show-reviews,
  .review-products-table__manage {
    flex-basis: 160px;
  }
}
</style>

<i18n locale="ko">
{
  "product_search_type": {
    "name": "상품명",
    "code": "상품 번호",
    "id": "상품 ID"
  },
  "sub_brand_name": "서브 브랜드",
  "reviewed_sub_orders_percentage": "리뷰 작성률",
  "review_pinned": "추천 리뷰",
  "combo_product": "세트상품",
  "manager_reviewed": "스탭리뷰",
  "deleted": {
    "label": "삭제됨",
    "tooltip": "상품 정보 동기화 결과\n쇼핑몰 솔루션에서 삭제된 상품입니다."
  },
  "message_sentiment_analysis": {
    "title": "리뷰 본문 분석"
  },
  "keyword_sentiment_analysis": {
    "title": "키워드별 분석",
    "analyzed_message": "다음 키워드에 대한 {type} 표현을 감지했습니다."
  },
  "show_reviews": "리뷰 보기",
  "manage_reviews": "리뷰 작성/관리"
}
</i18n>
<i18n locale="en">
{
  "product_search_type": {
    "name": "Product name",
    "code": "Product code",
    "id": "Product ID"
  },
  "sub_brand_name": "Sub brand name",
  "reviewed_sub_orders_percentage": "Review writing rate",
  "review_pinned": "Pinned review",
  "combo_product": "Combo product",
  "manager_reviewed": "Staff review",
  "deleted": {
    "label": "Deleted",
    "tooltip": "Result of synchronizing product information\nThe product was deleted from the shopping mall solution."
  },
  "message_sentiment_analysis": {
    "title": "Review analysis"
  },
  "keyword_sentiment_analysis": {
    "title": "Analysis by keyword",
    "analyzed_message": "Detected {type} expression for the following keyword."
  },
  "show_reviews": "Show reviews",
  "manage_reviews": "Manage review"
}
</i18n>
