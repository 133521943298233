<template>
  <AppModalDefault
    :title="$t('title')"
    :width="DialogSize.MIDDLE"
    @close="close"
  >
    <template #body>
      <AppInfoBox>
        {{ $t('notice') }}
      </AppInfoBox>
      <AppContainer class="TheRequiredMaterialPopupDialog__required">
        <AppDataList :data="requiredData">
          <template #value="{ value }">
            <template v-if="value === 'corporate'">
              <div>
                <b>{{ $t('corporate.description1') }}</b>
              </div>
              <div>
                <b>{{ $t('corporate.description2') }}</b>
              </div>
              <div>{{ $t('corporate.description3') }}</div>
              <i18n path="corporate.description4_1">
                <span class="TheRequiredMaterialPopupDialog__info-highlight">{{
                  $t('corporate.description4_2')
                }}</span>
              </i18n>
              <AppInternalLink
                v-if="isBizMessageProfileRequired"
                class="TheRequiredMaterialPopupDialog__link"
                to=""
                link-style="blue"
                @click="openSettingsDialogMaterial"
                >{{ $t('corporate.link') }}</AppInternalLink
              >
            </template>
            <template v-else-if="value === 'phone'">
              <div>{{ $t('phone.description') }}</div>
              <AppInternalLink
                v-if="isBizMessageProfileRequired"
                class="TheRequiredMaterialPopupDialog__link"
                to=""
                link-style="blue"
                @click="openSettingsDialogMaterial"
                >{{ $t('phone.link') }}</AppInternalLink
              >
            </template>
            <template v-else-if="value === 'biz_message_profile'">
              <div>{{ $t('biz_message_profile.description1') }}</div>
              <i18n path="biz_message_profile.description2_1">
                <AppInternalLink
                  class="TheRequiredMaterialPopupDialog__text-link"
                  :to="reviewMessageSettingsUrl"
                  >{{
                    $t('biz_message_profile.description2_2')
                  }}</AppInternalLink
                >
              </i18n>
              <AppInternalLink
                v-if="isCorporateOrPhoneRequired"
                class="TheRequiredMaterialPopupDialog__link"
                to=""
                link-style="blue"
                @click="redirectBizMessageSettingsUrl"
                >{{ $t('biz_message_profile.link') }}</AppInternalLink
              >
            </template>
            <template v-else>
              {{ $t('phone.description') }}
            </template>
          </template>
        </AppDataList>
      </AppContainer>
    </template>
    <template #foot>
      <AppButton
        v-if="isBizMessageProfileRequired ^ isCorporateOrPhoneRequired"
        :label="$t('request_now')"
        button-style="blue"
        @click="clickRequestNow"
      />
      <AppButton :label="$t('request_next_time')" @click="closeAndSnooze" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DialogSize from '@/enums/DialogSize';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'TheRequiredMaterialPopupDialog',
  mixins: [DialogView],
  props: {
    materials: { type: Array, required: true }
  },
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    DialogSize: () => DialogSize,
    requiredData() {
      return this.materials.map(material => ({
        label: this.$t(`${material}.label`),
        value: material
      }));
    },
    isBizMessageProfileRequired() {
      return this.materials.includes('biz_message_profile');
    },
    isCorporateOrPhoneRequired() {
      return ['corporate', 'phone'].some(material =>
        this.materials.includes(material)
      );
    },
    reviewMessageSettingsUrl() {
      return '/review/settings/renewed/message';
    }
  },
  methods: {
    ...mapActions('alert', ['snoozeAlert']),
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('session', ['redirectToNextPage']),
    clickRequestNow() {
      this.materials[0] === 'biz_message_profile'
        ? this.redirectBizMessageSettingsUrl()
        : this.openSettingsDialogMaterial();
    },
    openSettingsDialogMaterial() {
      this.openSettingsDialog('settings_dialog_material');
    },
    redirectBizMessageSettingsUrl() {
      this.redirectToNextPage(
        this.pathWithBrandParams('/review/messages/biz_messages/settings')
      );
      this.close();
    },
    openSettingsDialog(initialTab) {
      this.openDialog(['TheSettingsDialog', { initialTab }]);
      this.close();
    },
    closeAndSnooze() {
      this.snoozeAlert('material_popup');
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheRequiredMaterialPopupDialog__required {
  margin-top: 20px;
}

.TheRequiredMaterialPopupDialog__info-highlight {
  color: $color-red;
}

.TheRequiredMaterialPopupDialog__link {
  display: block;
}

.TheRequiredMaterialPopupDialog__text-link {
  text-decoration: underline;
}
</style>

<i18n locale="ko">
  {
    "title": "필수 자료 등록 안내",
    "corporate": {
      "label": "세금계산서 발급 자료",
      "description1": "세금계산서는 ‘세금계산서 발급 자료’에 등록되어 있는 정보로 발급됩니다.",
      "description2": "사업자등록증에 있는 정확한 정보를 기입해주세요.",
      "description3": "세금계산서는 결제 승인일의 익월 초(일반적인 경우 6~8일 사이)에 발행되며, 등록한 메일 주소로 자동 발송됩니다.",
      "description4_1": "‘세금계산서 발급 자료’는 {0}까지 등록해주세요.",
      "description4_2": "결제 승인일의 당월 말일",
      "link": "자료 등록하러 가기 >"
    },
    "phone": {
      "label": "통신서비스 이용증명원(가입증명서)",
      "description": "SMS 발송 서비스 이용을 위한 필수 등록 자료입니다.",
      "link": "자료 등록하러 가기 >"
    },
    "biz_message_profile": {
      "label": "알림톡 발신 프로필",
      "description1": "알림톡 발송을 위한 필수 등록 자료입니다.",
      "description2_1": "(메시지 발송을 원하지 않을경우 {0} 설정을 꺼주세요.)",
      "description2_2": "배송 완료 고객에게 메시지 발송",
      "link": "프로필 등록하러 가기 >"
    },
    "notice": "💡 원활한 크리마 서비스 이용을 위해 아래 자료 등록을 안내드립니다.",
    "request_now": "등록하러 가기",
    "request_next_time": "다음에 등록"
  }
</i18n>
