<template>
  <div class="ReviewWidgetInstallationRequest">
    <AppAjaxContent :is-loading="isLoading">
      <template>
        <SummaryBoxRequestInstallationComplete
          v-if="shouldDisplaySummaryBoxRequestInstallationComplete"
          class="ReviewWidgetInstallationRequest__summary-box"
        />
        <SummaryBoxInstallComplete
          v-else-if="isWidgetInstallStatusComplete && isWidgetTryoutSupported"
          class="ReviewWidgetInstallationRequest__summary-box"
        />
        <SummaryBoxInstallConfirmed
          v-else-if="shouldDisplaySummaryBoxInstallComfirmed"
          class="ReviewWidgetInstallationRequest__summary-box"
        />
        <SummaryBoxRequestLaunchDateComplete
          v-else-if="shouldDisplaySummaryBoxRequestLaunchDateComplete"
          class="ReviewWidgetInstallationRequest__summary-box"
        />
      </template>
      <NextStepBubble
        v-if="!isWidgetInstallRequested"
        :text="$t('bubble_text')"
      />
      <AppAccordion
        :title="$t('title')"
        :sub-title="$t('sub_title')"
        :complete-sub-title="$t('complete_sub_title')"
        component-name="ReviewDashboardRequestInstallation"
        :default-expanded="!requestInstallationCompleted"
        :is-completed="requestInstallationCompleted"
      />
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import { mapState, mapGetters, mapMutations } from 'vuex';
import WidgetInstallStatus from '@/enums/WidgetInstallStatus';
import NextStepBubble from '@/views/review/dashboard/components/NextStepBubble.vue';
import SummaryBoxRequestInstallationComplete from '@/views/review/dashboard/components/SummaryBoxRequestInstallationComplete.vue';
import SummaryBoxInstallComplete from '@/views/review/dashboard/components/SummaryBoxInstallComplete.vue';
import SummaryBoxInstallConfirmed from '@/views/review/widget_installation_request/components/SummaryBoxInstallConfirmed.vue';
import SummaryBoxRequestLaunchDateComplete from '@/views/review/widget_installation_request/components/SummaryBoxRequestLaunchDateComplete.vue';

export default {
  name: 'ReviewWidgetInstallationRequest',
  components: {
    NextStepBubble,
    SummaryBoxRequestInstallationComplete,
    SummaryBoxInstallComplete,
    SummaryBoxInstallConfirmed,
    SummaryBoxRequestLaunchDateComplete
  },
  data() {
    return {
      isLoading: true
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('easyJoin', [
      'widgetInstallStatus',
      'requestInstallationCompleted',
      'shouldDisplaySummaryBoxRequestInstallationComplete',
      'isWidgetInstallStatusComplete',
      'shouldDisplaySummaryBoxInstallComfirmed',
      'shouldDisplaySummaryBoxRequestLaunchDateComplete'
    ]),
    isWidgetInstallRequested() {
      return this.widgetInstallStatus !== WidgetInstallStatus.NONE;
    },
    isWidgetTryoutSupported() {
      return ['cafe24', 'makeshop', 'godo'].includes(
        this.currentBrand.shop_builder
      );
    }
  },
  created() {
    api
      .get('/easy_join/onboarding_progresses')
      .then(({ data }) => {
        this.SET_ONBOARDING_PROGRESS(data.onboarding_progress);
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('easyJoin', ['SET_ONBOARDING_PROGRESS'])
  }
};
</script>

<style lang="scss">
.ReviewWidgetInstallationRequest__summary-box {
  margin-bottom: 32px;
}
</style>

<i18n locale="ko">
  {
    "bubble_text": "👇 아래 작업을 진행해주세요.",
    "title": "위젯 설치 요청",
    "sub_title": "쇼핑몰 방문 고객에게 리뷰를 더 효과적으로 수집하고 노출할 수 있도록 위젯 설치 요청을 진행해주세요.",
    "complete_sub_title": "위젯 설치 요청을 완료했습니다."
  }
</i18n>
