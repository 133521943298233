<template>
  <AppForm :sections="[{ groups: formGroups }]">
    <template #group="{ id }">
      <template v-if="id === 'install_front_api_script'">
        <AppButton
          class="AppForm__group-field"
          :label="$t('install')"
          button-style="red-outline"
          @click="installScriptTag"
        />
      </template>
      <template v-else-if="id === 'install_init_js_v2'">
        <div class="AppForm__group-field">
          <AppCheckbox :label="$t('is_responsive')" :checked="true" disabled />
        </div>
        <div class="AppForm__group-field">
          <AppButton
            :label="$t('install')"
            button-style="red-outline"
            @click="installInitJs"
          />
        </div>
      </template>
      <template v-else-if="id === 'install_crema_app_script'">
        <div class="AppForm__group-field">
          <AppCheckbox :label="$t('need_skin_id')" :checked="false" disabled />
        </div>
        <div class="AppForm__group-field">
          <AppButton
            :label="$t('install')"
            button-style="red-outline"
            :disabled="isInstallingMakeshopScript"
            @click="reqeustMakeshopScriptInstallation"
          />
        </div>
        <pre class="AppForm__group-field">{{ installationResultMessage }}</pre>
      </template>
    </template>
  </AppForm>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import MakeshopScriptInstallStatus from '@/enums/MakeshopScriptInstallStatus';
import { mapState } from 'vuex';

export default {
  name: 'ReviewMoveShopBuilderScript',
  data: () => ({
    isInstallingMakeshopScript: false,
    installationResultMessage: null
  }),
  computed: {
    ...mapState('session', ['currentBrand']),
    cafe24FormGroups() {
      return [
        {
          id: 'install_front_api_script',
          label: this.$t('cafe24.install_front_api_script')
        },
        {
          id: 'install_init_js_v2',
          label: this.$t('cafe24.install_init_js_v2')
        }
      ];
    },
    makeshopFormGroups() {
      return [
        {
          id: 'install_crema_app_script',
          label: this.$t('makeshop.install_crema_app_script')
        }
      ];
    },
    formGroups() {
      switch (this.currentBrand.shop_builder) {
        case 'cafe24':
          return this.cafe24FormGroups;
        case 'makeshop':
          return this.makeshopFormGroups;
        default:
          return [];
      }
    }
  },
  methods: {
    ...mapActions('dialog', ['alert']),
    installScriptTag() {
      api
        .post('/review/move_shop_builder/install_cafe24_script_tag')
        .then(() => {
          this.alert({
            title: this.$t('move_shop_builder.install_cafe24_script_tag.title'),
            message: this.$t(
              'move_shop_builder.install_cafe24_script_tag.success'
            )
          });
        });
    },
    installInitJs() {
      api.post('/review/move_shop_builder/install_cafe24_init_js').then(() => {
        this.alert({
          title: this.$t('move_shop_builder.install_cafe24_init_js.title'),
          message: this.$t('move_shop_builder.install_cafe24_init_js.success')
        });
      });
    },
    reqeustMakeshopScriptInstallation() {
      this.isInstallingMakeshopScript = true;
      api
        .get('/review/move_shop_builder/makeshop_dgnset_ids')
        .then(({ data }) => {
          const all_dgnset_ids = data.all_dgnset_ids;
          if (all_dgnset_ids.length) {
            this.alert({
              title: this.$t('makeshop.install_script_dialog.title'),
              message: this.$t(
                'makeshop.install_script_dialog.start_to_install',
                [all_dgnset_ids.length, all_dgnset_ids.join(',')]
              )
            }).then(() => {
              this.installMakeshopScript();
            });
          } else {
            this.alert({
              title: this.$t('makeshop.install_script_dialog.title'),
              message: this.$t(
                'makeshop.install_script_dialog.dgnset_id_not_found'
              )
            }).then(() => {
              this.isInstallingMakeshopScript = false;
            });
          }
        })
        .catch(() => {
          this.isInstallingMakeshopScript = false;
        });
    },
    installMakeshopScript() {
      api
        .post('/review/move_shop_builder/install_makeshop_script')
        .then(({ data }) => {
          this.setInstallationResultMessage(data.installation_result_map);
          this.isInstallingMakeshopScript = false;
        })
        .catch(() => {
          this.isInstallingMakeshopScript = false;
        });
    },
    setInstallationResultMessage(installation_result_map) {
      const messages = [];
      Object.entries(installation_result_map).forEach(
        ([device_type, dgnset_installation_results]) => {
          Object.entries(dgnset_installation_results).forEach(
            ([dgnset_id, page_installation_results]) => {
              page_installation_results.forEach(result => {
                const message = `[${device_type.toUpperCase()} ${dgnset_id} ${
                  result.page_type
                }] `;
                const statusMessage = MakeshopScriptInstallStatus.message(
                  result.status,
                  result.type,
                  result.skin_id,
                  result.message
                );
                messages.push(message.concat(statusMessage));
              });
            }
          );
        }
      );
      this.installationResultMessage = messages.join('\n');
    }
  }
};
</script>

<i18n locale="ko">
{
  "install": "설치",
  "is_responsive": "반응형 여부",
  "need_skin_id": "skin id 설정 필요 여부",
  "cafe24": {
    "install_front_api_script": "카페24 Front API 사용을 위한 스크립트 설치",
    "install_init_js_v2": "카페24 init.js 스크립트 v2 설치"
  },
  "makeshop": {
    "install_crema_app_script": "메이크샵 크리마앱 스크립트 설치",
    "install_script_dialog": {
      "title": "메이크샵 스크립트 설치",
      "start_to_install": "{0}({1})개 스크립트를 설치합니다.",
      "dgnset_id_not_found": "dgnset_id를 찾을 수 없어 스크립트 설치가 불가합니다."
    }
  }
}
</i18n>
