<template>
  <div>
    <div class="ui-section">
      <AppMarkdown :text="basicText" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiMarkdown',
  data() {
    return {
      basicText: `
# Markdown

## Sub nosces buxum mittit operum

Lorem markdownum ipse sparsit ramos ter mentis lumina dubitati renovata cecidere
dolores quoque oraque; quo plangore, cum mundi quos quid Cinyra. Ponit montis
**inde**: Boebes antiquas huic, et nata sunt non manibus quis.

## Per per aspicit deum removit Iove comitem

Somnique culpam amplexus inluxisse herbas, mutato opus tenuit ausa quem
Aeolidae. Prece aetate confesso et votis nitidos referre hoc esse sumit fors
Achivos ablata, *adiecisse occidit*.

    var outbox_big_packet = usPrimaryLeopard(led,
            isa_windows.myspace_phishing_simplex(-5, oemMirror));
    monitor = modem_mysql(1, servicesCompression + boot_cross,
            sidebarApplication);

Volat oculis, equi erat voces *aevum* iugales, et sibila moriens amnes nostri:
videt per Farfarus domui. Ferenti duris corrumpere lacertis. [Premente
cum](http://est.net/matrepervenientia).
      `
    };
  }
};
</script>
