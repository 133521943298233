import { constructEnum } from '@/lib/enum';

const BizMessageTemplateStatus = constructEnum('BizMessageTemplateStatus', {
  STOPPED: 'S',
  ACTIVATED: 'A',
  READY: 'R'
});

BizMessageTemplateStatus.badgeStyle = status => {
  switch (status) {
    case BizMessageTemplateStatus.STOPPED:
      return 'red';
    case BizMessageTemplateStatus.ACTIVATED:
    case BizMessageTemplateStatus.READY:
      return 'mint-green';
    default:
      return 'default';
  }
};

export default BizMessageTemplateStatus;
