<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="notice ? notice.title : noticeTitle"
    width="1000px"
    @close="$emit('close')"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <template #default>
          <!-- eslint-disable vue/no-v-html -->
          <div
            ref="content"
            class="TheNoticeView__content"
            v-html="notice.message"
          />
          <!-- eslint-enable vue/no-v-html -->
        </template>
      </AppAjaxContent>
    </template>
    <template #foot>
      <AppButton
        v-if="!hideShowAllButton"
        button-style="blue"
        :label="$t('show_all')"
        @click="clickShowAll"
      />
      <AppButton :label="confirmLabel" @click="clickConfirm" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';

export default {
  name: 'TheNoticeView',
  props: {
    noticeTitle: {
      type: String,
      required: true
    },
    noticeId: {
      type: Number,
      required: true
    },
    confirmLabel: {
      type: String,
      default() {
        return this.$t('app.confirmed');
      }
    },
    hideShowAllButton: { type: Boolean, default: false }
  },
  data() {
    return { isLoading: true, notice: null };
  },
  watch: {
    noticeId() {
      this.fetchNotice();
    },
    isLoading() {
      if (!this.isLoading) {
        api.post(`/help/notices/${this.noticeId}/check`);
        this.addEventListenerToLink();
      }
    }
  },
  mounted() {
    this.fetchNotice();
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('notification', ['markNotificationChecked']),
    addEventListenerToLink() {
      this.$nextTick(() => {
        const { content } = this.$refs;
        const links = content.querySelectorAll('a');
        links.forEach(link =>
          link.addEventListener('click', this.markClickedNotice)
        );
      });
    },
    markClickedNotice() {
      api.post(`/help/notices/${this.noticeId}/click`);
    },
    fetchNotice() {
      this.isLoading = true;
      api
        .get(`/help/notices/${this.noticeId}`)
        .then(({ data }) => (this.notice = data))
        .finally(() => (this.isLoading = false));
    },
    clickConfirm() {
      if (this.notice)
        this.markNotificationChecked(this.notice.notification_id);
      this.$emit('click');
    },
    clickShowAll() {
      this.openDialog('TheNoticesDialog');
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheNoticeView__content {
  @include text-content;

  ::v-deep img {
    display: block;
  }
}
</style>

<i18n locale="ko">
{
  "title": "공지사항",
  "show_all": "공지사항 모두 보기"
}
</i18n>
