<template>
  <i18n :path="currencyKey">
    <AppNumberInput
      :value="currentValue"
      v-bind="{
        id,
        name,
        disabled,
        placeholder,
        digits,
        invalid,
        default: this.default,
        allowNegative
      }"
      inline
      :float-precision="useCents ? 2 : 0"
      @change="change"
    />
  </i18n>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'AppCurrencyInput',
  model: {
    event: 'change'
  },
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    digits: {
      type: Number,
      required: true
    },
    value: {
      type: [String, Number],
      default: null
    },
    invalid: {
      type: Boolean,
      default: false
    },
    default: {
      type: Number,
      default: null
    },
    allowNegative: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('session', ['brandSettings']),
    ...mapGetters('session', ['currencyKey']),
    currentValue() {
      return this.value === null
        ? null
        : (this.value * (this.useCents ? 0.01 : 1)).toFixed(2);
    },
    useCents() {
      return this.brandSettings.use_cents;
    }
  },
  methods: {
    change(newValue) {
      this.$emit(
        'change',
        this.useCents ? _.toInteger((newValue * 100).toFixed(2)) : newValue
      );
    }
  }
};
</script>
