<template>
  <AppModalForm
    :title="$t('menu.laboratory_dialog')"
    :form-props="{
      ...formProps,
      objectId: 'laboratory',
      submitLabel: $t('app.confirmed'),
      closeLabel: $t('app.cancel')
    }"
    v-on="formEvents"
  >
    <template #default>
      <AppInfoBox class="ThelaboratoryDialog__info-box">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="$t('info_box_text')" />
        <!-- eslint-enable vue/no-v-html -->
      </AppInfoBox>
    </template>
    <template #group-foot="{ id }">
      <template v-if="id === 'ai_review_comment'">
        <div class="ThelaboratoryDialog__links">
          <span
            class="ThelaboratoryDialog__link"
            @click="openAiCommentHelpDialog"
            >{{ $t('open_help_dialog') }}</span
          >
          <AppExternalLink
            class="ThelaboratoryDialog__link"
            to="https://forms.gle/qgaFqav1uZr3GBuH7"
            >{{ $t('apply_for_official_release') }}</AppExternalLink
          >
        </div>
      </template>
      <template v-else-if="id === 'ai_recommendation_sort'">
        <div class="ThelaboratoryDialog__links">
          <span
            class="ThelaboratoryDialog__link"
            @click="openAiRecommendationSortHelpDialog"
            >{{ $t('open_help_dialog') }}</span
          >
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import SettingsView from '@/mixins/SettingsView';

export default {
  name: 'ThelaboratoryDialog',
  mixins: [DialogFormView, SettingsView],
  computed: {
    ...mapState('session', ['laboratory']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'ai_review_comment',
              label: this.$t('laboratory.ai_review_comment'),
              description: this.$t('laboratory.descrption.ai_review_comment'),
              type: 'checkbox'
            },
            {
              id: 'ai_recommendation_sort',
              label: this.$t('laboratory.ai_recommendation_sort'),
              description: this.$t(
                'laboratory.descrption.ai_recommendation_sort'
              ),
              type: 'checkbox'
            }
          ]
        }
      ];
    }
  },
  mounted() {
    this.orgFormObject = { ...this.laboratory };
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapActions('session', ['updateLaboratory']),
    ...mapActions('dialog', ['openDialog']),
    openAiCommentHelpDialog() {
      this.openDialog('TheAiCommentHelpDialog');
    },
    openAiRecommendationSortHelpDialog() {
      this.openDialog('TheAiRecommendationSortHelpDialog');
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/laboratory', formData)
        .then(() => {
          this.orgFormObject = this.formObject;
          this.updateLaboratory(this.formObject);
          this.close(true);
          this.createToast(this.$t('app.saved'));
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ThelaboratoryDialog__info-box {
  margin-bottom: 20px;
}

.ThelaboratoryDialog__links {
  margin-top: 4px;
}

.ThelaboratoryDialog__link {
  @include text-content-light;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  & + & {
    margin-left: 8px;
  }
}
</style>

<i18n locale="ko">
{
  "info_box_text": "출시 준비중인 기능을 먼저 체험해 볼 수 있습니다.<br>실험실 기능은 원하는 때에 켜고 끌 수 있습니다.",
  "open_help_dialog": "상세 설명",
  "apply_for_official_release": "정식 출시 사용 대기 신청"
}
</i18n>
