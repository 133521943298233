<template>
  <button class="AppModalHeadCloseButton" @click="$emit('close')">
    <AppSvgIcon name="icon-close" />
  </button>
</template>

<script>
export default {
  name: 'AppModalHeadCloseButton'
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_buttons.scss';

.AppModalHeadCloseButton {
  @include svg-button-container;
}
</style>
