import { render, staticRenderFns } from "./ReviewReviewsAdvancedSearch.vue?vue&type=template&id=1df1b914&scoped=true&"
import script from "./ReviewReviewsAdvancedSearch.vue?vue&type=script&lang=js&"
export * from "./ReviewReviewsAdvancedSearch.vue?vue&type=script&lang=js&"
import style0 from "./ReviewReviewsAdvancedSearch.vue?vue&type=style&index=0&id=1df1b914&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1df1b914",
  null
  
)

/* custom blocks */
import block0 from "./ReviewReviewsAdvancedSearch.vue?vue&type=custom&index=0&blockType=i18n&locale=ko"
if (typeof block0 === 'function') block0(component)
import block1 from "./ReviewReviewsAdvancedSearch.vue?vue&type=custom&index=1&blockType=i18n&locale=en"
if (typeof block1 === 'function') block1(component)

export default component.exports