import { constructEnum } from '@/lib/enum';

export default constructEnum('BrandTargetStatus', {
  NONE: 0,
  REQUESTED: 10,
  OPERATING: 30,
  EXPIRED: 40,
  FREE_TRIAL_OPERATING: 50,
  FREE_TRIAL_EXPIRED: 60,
  TESTING: 70,
  EXPIRED_AND_ADMIN_BLOCKED: 80,
  TERMINATED: 90
});
