import { constructEnum } from '@/lib/enum';

export default constructEnum('TargetSubReportType', {
  PC: 10,
  MOBILE: 20,
  D1: 30,
  D3: 40,
  D7: 50,
  SMS: 1000,
  EMAIL: 1010,
  POPUP: 1020,
  BIZ_MESSAGE: 1030
});
