<template>
  <div
    :class="[
      'AppImage',
      {
        'AppImage--visible': isReady,
        'AppImage--background': background,
        'AppImage--opacity-transition': enableOpacityTransition
      }
    ]"
    :style="{
      ...backgroundImageStyle,
      ...styleProp
    }"
  >
    <img
      ref="image"
      :class="[
        'AppImage__img',
        { 'AppImage__img--square': square },
        { 'AppImage__img--original-ratio': originalRatio }
      ]"
      :src="src"
      :alt="alt"
      :style="rotation ? { transform: `rotate(${rotation}deg)` } : null"
      :srcset="srcset"
      @load="load"
      @error="$emit('error')"
    />
  </div>
</template>

<script>
import { getOrientation } from '@/lib/vendor/exifOrientation';

export default {
  name: 'AppImage',
  props: {
    background: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'sharp',
      validator: v => ['sharp', 'rounded', 'circle'].includes(v)
    },
    borderRadius: {
      type: Number,
      default: 4
    },
    square: { type: Boolean, default: false },
    originalRatio: { type: Boolean, default: false },
    resolutions: { type: Array, default: null }
  },
  data() {
    return {
      isReady: false,
      rotation: 0,
      enableOpacityTransition: false
    };
  },
  computed: {
    backgroundImageStyle() {
      if (!this.background) return null;
      return { backgroundImage: `url("${this.src}")` };
    },
    requiresOrientationCheck() {
      return /^data:/.test(this.src);
    },
    styleProp() {
      let currentBorderRadius;
      switch (this.type) {
        case 'rounded':
          currentBorderRadius = `${this.borderRadius}px`;
          break;
        case 'circle':
          currentBorderRadius = '100%';
          break;
        default:
          currentBorderRadius = '0';
      }
      return { '--border-radius': currentBorderRadius };
    },
    srcset() {
      if (!this.resolutions) return null;

      return [
        `${this.src} 1x`,
        ...this.resolutions.map(
          v => `${this.src.replace(/\.([a-z]+?)$/, `@${v}x.$1`)} ${v}x`
        )
      ].join(', ');
    }
  },
  watch: {
    isReady() {
      this.checkOrientation();
    }
  },
  mounted() {
    // Only use opacity transition if the image takes longer than 0.1s to load
    setTimeout(() => {
      if (!this.isReady) this.enableOpacityTransition = true;
    }, 100);
  },
  methods: {
    checkOrientation() {
      if (!this.requiresOrientationCheck) return;

      const { image } = this.$refs;
      getOrientation(image.src).then(orientation => {
        if (!orientation) return;

        // https://www.impulseadventure.com/photo/exif-orientation.html
        if (orientation === 3) this.rotation = 180;
        else if (orientation === 6) this.rotation = 90;
        else if (orientation === 8) this.rotation = 270;
      });
    },
    load() {
      this.isReady = true;
      this.$emit('load');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_transitions.scss';

.AppImage {
  border-radius: var(--border-radius);
  display: inline-block;
  line-height: 0;

  &--opacity-transition {
    @include transition-slow(opacity);
    opacity: 0;
  }

  &--background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &--visible {
    opacity: 1;
  }
}

.AppImage__img {
  width: 100%;
  max-height: inherit;
  height: inherit;
  border-radius: var(--border-radius);

  &--square {
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  &--original-ratio {
    object-fit: contain;
  }
}

.AppImage--background .AppImage__img {
  visibility: hidden;
  position: absolute;
}
</style>
