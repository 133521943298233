import { constructEnum } from '@/lib/enum';
import i18n from '@/lib/i18n';
import TargetDispatchMethod from '@/enums/TargetDispatchMethod';

const TargetCohortItemStatus = constructEnum('TargetCohortItemStatus', {
  NONE: 0,
  CREATED: 5,
  SENDING: 7,
  SENT: 10,
  ERROR: 20,
  ERROR_SENDING: 25,
  UNSUBSCRIBED: 30,
  EMPTY_TARGET: 33,
  INVALID_TARGET: 36,
  NOT_ENOUGH_CREDIT: 40,
  PRICE_LIMIT_EXCEEDED: 50,
  PRICE_LIMIT_PER_DISPATCH_EXCEEDED: 60,
  PRICE_LIMIT_PER_CAMPAIGN_EXCEEDED: 65,
  SENT_TODAY: 70,
  DUPLICATE_MSG_ID: 80,
  LOCK_INTERVAL: 90,
  OUTDATED: 100,
  LMS_NOT_ALLOWED: 110,
  ERROR_NOT_SENDABLE_TIME: 120
});

TargetCohortItemStatus.popup = [
  TargetCohortItemStatus.NONE,
  TargetCohortItemStatus.CREATED,
  TargetCohortItemStatus.SENT,
  TargetCohortItemStatus.ERROR,
  TargetCohortItemStatus.LOCK_INTERVAL
];

TargetCohortItemStatus.translateForDispatchMethod = function(status, method) {
  const methodKey = TargetDispatchMethod.key(method);
  const statusKey = this.key(status);
  return i18n.t(`TargetCohortItemStatus.${methodKey}.${statusKey}`);
};

export default TargetCohortItemStatus;
