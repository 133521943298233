<template>
  <div class="SummaryBoxPaymentRequested">
    <AppSummaryBox>
      <template #title>
        {{ $t('title') }}
      </template>
      {{ content }}
      <template #buttons>
        <AppButton
          button-style="blue-outline"
          :label="$t('button')"
          @click="openThePaymentDialog"
        />
      </template>
    </AppSummaryBox>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SummaryBoxPaymentRequested',
  computed: {
    ...mapGetters('easyJoin', ['isEssentialAccordionCompleted']),
    content() {
      return this.isEssentialAccordionCompleted
        ? this.$t('content.essential_completed')
        : this.$t('content.essential_incompleted');
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openThePaymentDialog() {
      this.openDialog('ThePaymentDialog');
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "결제를 진행해주세요. 💳",
  "content": {
    "essential_completed": "서비스 결제를 진행해주세요. 결제 후 요청하신 위젯 설치 작업이 진행됩니다.",
    "essential_incompleted": "크리마 리뷰 서비스 사용을 위해 서비스 비용을 결제해주세요."
  },
  "button": "서비스 비용 결제"
}
</i18n>
