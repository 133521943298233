<template>
  <AppModalDefault :title="$t('title')" width="middle" @close="close">
    <!-- eslint-disable vue/no-v-html -->
    <template #body>
      <AppInfoBox>
        <span>{{ $t('info_box_text_1') }}</span
        ><br />
        <span>{{ $t('info_box_text_2') }}</span
        ><br /><br />
        <span>
          {{ $t('info_box_text_3-1') }}
          <u
            ><a @click="click">{{ $t('info_box_text_3-2') }}</a></u
          >{{ $t('info_box_text_3-3') }}
        </span>
      </AppInfoBox>
      <div
        v-for="(desc, i) in descriptions"
        :key="i"
        class="TheAiRecommendationSortHelpDialog__description-container"
      >
        <span class="TheAiRecommendationSortHelpDialog__description-title">
          {{ $t(`${desc}.title`) }}
        </span>
        <div class="TheAiRecommendationSortHelpDialog__description">
          <div
            class="TheAiRecommendationSortHelpDialog__description-text"
            v-html="$t(`${desc}.description`)"
          />
        </div>
      </div>
    </template>
    <!-- eslint-enable vue/no-v-html -->
    <template #foot>
      <AppExternalLink
        class="TheAiRecommendationSortHelpDialog__form-link"
        :to="applicationFormUrl"
      >
        <AppButton
          button-style="blue"
          :label="$t('apply_for_official_release')"
        />
      </AppExternalLink>
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';
import { pathWithParams } from '@/lib/params';

export default {
  name: 'TheAiRecommendationSortHelpDialog',
  mixins: [DialogView],
  data() {
    return {
      applicationFormUrl: 'https://forms.gle/P1CatVmSvueaHNsw9',
      descriptions: ['what_is_it', 'how_to_use']
    };
  },
  computed: {
    ...mapState('session', ['currentBrand', 'currentUser'])
  },
  watch: {
    $route() {
      this.openDialogFromHash();
    }
  },
  mounted() {
    const { code, event_base_url } = this.currentBrand;
    const url = pathWithParams(`${event_base_url}/${code}`, {
      type: 'page',
      event: 'ai_sort_help',
      referrer: document.referrer,
      username: this.currentUser.name
    });
    api.post(url);
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'openDialogFromHash']),
    click() {
      this.openDialog('ThelaboratoryDialog');
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheAiRecommendationSortHelpDialog__description-container {
  margin-top: 40px;
}

.TheAiRecommendationSortHelpDialog__description-title {
  @include text-sub-title;
}

.TheAiRecommendationSortHelpDialog__description {
  margin-top: 8px;
}

.TheAiRecommendationSortHelpDialog__form-link {
  margin-right: 8px;
}
</style>

<i18n locale="ko">
{
  "title": "AI 추천순 리뷰 보기",
  "info_box_text_1": "정식 출시 전 실험실 기능으로 상시 기능의 추가 및 변경이 있을 수 있으며 상황에 따라 동작이 불안정할 수 있습니다.",
  "info_box_text_2": "현재 AI 추천순 리뷰 보기 기능은 리뷰 > 관리 > 목록 및 적립금 주기 페이지에 적용되어있습니다",
  "info_box_text_3-1": "AI 추천순 리뷰 보기를 사용하지 않으려면, ",
  "info_box_text_3-2": "여기를 클릭",
  "info_box_text_3-3": "하여 사용 설정을 해제해 주세요.",
  "what_is_it": {
    "title": "AI 추천순 리뷰란?",
    "description": "콘텐츠 지수, 인기 지수, 최신/긍정 지수 등을 점수화하여 점수가 높은 우수한 리뷰 순서대로 정렬하는 것을 의미합니다.<br>해당 리뷰는 상품에 대해 긍정적인 다양한 정보를 포함하고 있어 쇼핑몰 고객의 구매 결정에 도움을 줄 수 있습니다.<br><br><b>콘텐츠 지수</b><br>• 리뷰 본문에 포함된 주요 정보의 수, 적절한 본문 길이, 첨부 포토/동영상 수<br><br><b>인기 지수</b><br>• 열람, 좋아요 클릭 등 다양한 쇼핑몰 방문 고객의 긍정적 상호 작용 정도<br><br><b>최신/긍정 지수</b><br>• 최신 리뷰 여부, 리뷰 본문 내용의 긍정도"
  },
  "how_to_use": {
    "title": "활용 방법",
    "description": "<b>상품 상세 페이지 추천 리뷰 지정</b><br>• 특정 상품의 모든 리뷰를 AI 추천순으로 정렬한 다음 적합한 리뷰 목록 오른쪽의 관리 > 추천 리뷰 지정 버튼을 클릭해 상품 상세페이지 리뷰 위젯 상단에 해당 리뷰가 늘 노출 되도록 추천 리뷰로 지정.<br><br><b>리뷰 활용한 광고/콘텐츠 제작 시 소재로 사용할 리뷰 발굴</b><br>• 실제 상품에 작성된 리뷰를 활용한 광고/콘텐츠는 효과가 뛰어난 반면, 적절하지 못한 리뷰를 사용하면 역효과가 발생하므로, 상품에 대해 긍정적이면서 양질의 정보를 갖춘 리뷰를 발굴하는 것이 중요.<br><br><b>우수 리뷰 탐색</b><br>• 전체 상품, 특정 카테고리/상품의 리뷰 중 우수 리뷰를 확인하고 싶은 니즈 충족"
  },
  "apply_for_official_release": "AI 추천순 리뷰 보기에 대한 피드백 남기기"
}
</i18n>
