export default {
  methods: {
    autoTooltip(autoTooltip, refName) {
      return autoTooltip ? this.refTooltip(refName) : null;
    },
    async refTooltip(refName) {
      await this.$nextTick();

      let ref = this.$refs[refName];
      ref = Array.isArray(ref) ? ref[0] : ref;

      return ref && this.isEllipsisActive(ref) ? ref.innerText : null;
    },
    isEllipsisActive(e) {
      return e.clientWidth < e.scrollWidth;
    }
  }
};
