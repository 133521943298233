<template>
  <TransitionExpand>
    <div v-show="visible" class="AppAdvancedSearch">
      <div class="AppAdvancedSearch__body">
        <AppForm
          :object="resourceParams"
          :event-bus="eventBus"
          form-style="narrow"
          :submit-button="{ submitLabel: $t('app.search') }"
          :sections="sections"
          @change="$emit('change', $event)"
          @submit="$emit('submit')"
        >
          <template #group="groupProps">
            <slot name="group" v-bind="groupProps" />
          </template>
        </AppForm>
      </div>
    </div>
  </TransitionExpand>
</template>

<script>
import TransitionExpand from '@/transitions/TransitionExpand';

export default {
  name: 'AppAdvancedSearch',
  components: { TransitionExpand },
  model: {
    prop: 'resourceParams',
    event: 'change'
  },
  props: {
    resourceParams: {
      type: Object,
      required: true
    },
    sections: {
      type: Array,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    eventBus: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppAdvancedSearch {
  padding-top: 16px;
  padding-bottom: 12px;
}

.AppAdvancedSearch__body {
  padding: 23px 27px;
  border: 1px solid $color-form-border;
  border-radius: 3px;
}
</style>
