<template>
  <div class="InstallRequestRenewalDirectionDialogEtc">
    <AppDropdownMenuButton
      class="InstallRequestRenewalDirectionDialogEtc_add-page-button"
      button-style="blue-outline"
      :label="$t('add_page')"
      :menu-items="dropdownMenuItems"
    />
    <AppTable :columns="columns" :rows="rows">
      <template #cell="{ column, row }">
        <template v-if="column.id === 'actions'">
          <AppButton
            :label="$t('app.edit')"
            :button-style="'grey-outline'"
            @click="
              openInstallRequestRenewalSlotDialog(row.page_type, row.custom_url)
            "
          />
          <AppButton :label="$t('app.delete')" :button-style="'red-outline'" />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import WidgetPageType from '@/enums/WidgetPageType';

export default {
  name: 'InstallRequestRenewalDirectionDialogEtc',
  data() {
    return {
      rows: [
        {
          page_type: WidgetPageType.EVENT,
          page_type_name: WidgetPageType.t(WidgetPageType.EVENT),
          custom_url: 'http://event.cre.ma',
          widget_to_install: '(PC) 게시판, 소셜 / (모바일) 게시판, 소셜'
        },
        {
          page_type: WidgetPageType.PRODUCT_LIST,
          page_type_name: WidgetPageType.t(WidgetPageType.PRODUCT_LIST),
          custom_url: 'http://category.cre.ma',
          widget_to_install: '(PC) 게시판, 소셜 / (모바일) 게시판, 소셜'
        }
      ]
    };
  },
  computed: {
    WidgetPageType() {
      return WidgetPageType;
    },
    dropdownMenuItems() {
      return [WidgetPageType.EVENT, WidgetPageType.PRODUCT_LIST].map(
        pageType => ({
          label: WidgetPageType.t(pageType),
          clickHandler: () => this.openInstallRequestRenewalSlotDialog(pageType)
        })
      );
    },
    columns() {
      return [
        {
          id: 'page_type_name',
          label: this.$t('table.page_type'),
          width: '20%'
        },
        {
          id: 'custom_url',
          label: this.$t('table.custom_url'),
          width: '30%'
        },
        {
          id: 'widget_to_install',
          type: 'html',
          label: this.$t('table.widget_to_install'),
          width: '30%'
        },
        {
          id: 'actions',
          label: this.$t('app.actions'),
          width: '20%'
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openInstallRequestRenewalSlotDialog(pageType, customUrl) {
      this.openDialog([
        'InstallRequestRenewalSlotEtcDialog',
        { pageType, customUrl }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
.InstallRequestRenewalDirectionDialogEtc_add-page-button {
  margin: 32px 0 24px;
}
</style>

<i18n locale="ko">
  {
    "table": {
      "page_type": "페이지 종류",
      "custom_url": "설치 페이지 URL",
      "widget_to_install": "설치 위젯"
    },
    "add_page": "설치 요청 페이지 추가"
  }
</i18n>
