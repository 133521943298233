<template>
  <div>
    <AppSvgIcon
      name="icon-crema-logo"
      :width="54"
      :height="18"
      class="TheFooter__logo"
    />
    <AppExternalLink
      :label="$t('tos')"
      :to="crema_tos_link"
      class="TheFooter__link hover-underline"
    />
    <AppExternalLink
      :label="$t('privacy')"
      :to="crema_privacy_link"
      class="TheFooter__link TheFooter__link-privacy hover-underline"
    />
    <template v-if="isAdminLocaleKo">
      <AppExternalLink
        :label="$t('outsource')"
        :to="crema_outsource_link"
        class="TheFooter__link TheFooter__link-outsource hover-underline"
      />
      <AppExternalLink
        :label="$t('marketing')"
        :to="crema_marketing_link"
        class="TheFooter__link TheFooter__link-marketing hover-underline"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheFooter',
  computed: {
    ...mapGetters('session', ['isShopifyBrand', 'adminLocale']),
    crema_tos_link() {
      return this.isShopifyBrand
        ? 'https://crema.notion.site/CREMA-Global-Term-of-Use-d6f21b7f928443d1b1a1eb60457c50e5'
        : 'https://assets.cre.ma/m/pdf/crema_tos.pdf';
    },
    crema_privacy_link() {
      return this.isShopifyBrand
        ? 'https://crema.notion.site/CREMA-Global-Privacy-Policy-125a3fb30b10424fb48a6c8c5edeb488'
        : 'https://assets.cre.ma/m/crema_privacy.pdf';
    },
    crema_outsource_link() {
      return 'https://assets.cre.ma/m/crema_entrustment.pdf';
    },
    crema_marketing_link() {
      return 'https://assets.cre.ma/m/crema_marketinguse.pdf';
    },
    isAdminLocaleKo() {
      return this.adminLocale === 'ko';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheFooter__logo {
  vertical-align: middle;
  position: relative;
  top: -1px;
  color: #0f141b;
}

.TheFooter__link {
  margin-left: 24px;
  font-size: 12px;
  color: $color-grey-50;

  &:hover {
    color: $color-button-text-dark;
  }
}

.TheFooter__link-privacy {
  font-weight: bold;
}
</style>

<i18n locale="ko">
{
  "tos": "이용약관",
  "privacy": "개인정보 처리방침",
  "outsource": "개인정보 위탁계약 동의",
  "marketing": "마케팅 수신 동의"
}
</i18n>
