<template>
  <div class="ChannelProductsImageCell">
    <div class="ChannelProductsImageCell__image-wrapper">
      <AppExternalLink :to="product.image_url">
        <AppImage
          v-if="product.image_url"
          class="ChannelProductsImageCell__image"
          :src="product.image_url"
          :alt="product.name"
          type="rounded"
        />
        <div
          v-if="product.image_width"
          class="ChannelProductsImageCell__image-overlay"
        >
          {{ product.image_width }} x {{ product.image_height }}
        </div>
      </AppExternalLink>
    </div>
    <AppButton
      button-style="label"
      :label="$t('change_image')"
      @click="openProductImageUploadDialog"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'ChannelProductsImageCell',
  props: {
    product: { type: Object, required: true }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openProductImageUploadDialog() {
      this.openDialog([
        'ChannelFeedProductsImageUploadDialog',
        {
          productId: this.product.id,
          channelImageUrl: this.product.has_channel_product_image
            ? this.product.image_url
            : null,
          channelImageName: this.product.has_channel_product_image
            ? this.product.channel_product_image_name
            : null
        }
      ]).then(eventBus => eventBus.$on('submit', () => this.$emit('refresh')));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ChannelProductsImageCell__image-wrapper {
  width: 64px;
  height: 84px;
  font-size: 0;
  line-height: 0;
  position: relative;
}

.ChannelProductsImageCell__image {
  width: 100%;
  height: 100%;
}

.ChannelProductsImageCell__image-overlay {
  @include text-caption-tiny-darker;
  position: absolute;
  top: 60px;
  width: 100%;
  padding: 3px 0px;
  text-align: center;
  background-color: white;
  opacity: 0.7;
}

::v-deep {
  .AppButton__button--style-label {
    @include text-button;
  }
}
</style>

<i18n locale="ko">
{
  "change_image": "이미지 수정"
}
</i18n>
