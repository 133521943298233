<template>
  <div class="TargetCampaignSettingsSection">
    <div class="TargetCampaignSettingsSection__title">
      {{ title }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TargetCampaignSettingsSection',
  props: { title: { type: String, required: true } }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetCampaignSettingsSection {
  & + & {
    margin-top: 40px;
  }
}

.TargetCampaignSettingsSection__title {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 8px;
  color: $color-grey-50;
}
</style>
