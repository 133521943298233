import { constructEnum } from '@/lib/enum';

export default constructEnum('ProductSortType', {
  RECENTLY_CREATED: 2,
  RECENTLY_REVIEWED: 32,
  ALPHABETICAL: 4,
  LOW_PRICE: 8,
  HIGH_PRICE: 16,
  MOST_REVIEWED: 128,
  MOST_SCORES: 256,
  RECENTLY_MOST_REVIEWED: 64,
  MOST_PURCHASED: 512,
  RECOMMENDATION: 1
});
