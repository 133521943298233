<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <template #default>
      <TargetCampaignShowHead @delete="campaignDeleted" />
      <TargetCampaignShowInfoBox />
      <TargetCampaignSituationalShowEffects />
      <div class="TargetCampaignSituationalShow__tabs">
        <AppTabsScrollable v-if="isDmCampaign" :tabs="sections" />
        <TargetCampaignSettingsSection
          v-for="{ id, label } in sections"
          :id="id"
          :key="id"
          :title="label"
        >
          <TargetCampaignSettingsDispatch v-if="id === 'dispatch'" />
          <template v-if="id === 'design'">
            <TargetDmCampaignSettingsDesign v-if="isDmCampaign" />
            <TargetCampaignSettingsPopup v-else />
          </template>
          <TargetCampaignSettingsCoupon v-if="id === 'coupon'" />
          <TargetCampaignSettingsMisc v-if="id === 'misc'" />
        </TargetCampaignSettingsSection>
      </div>
    </template>
  </AppAjaxContent>
</template>

<script>
import { mapGetters } from 'vuex';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignShowView from './TargetCampaignShowView';
import TargetCampaignShowHead from './components/TargetCampaignShowHead';
import TargetCampaignShowInfoBox from './components/TargetCampaignShowInfoBox';
import TargetCampaignSettingsSection from './components/TargetCampaignSettingsSection';
import TargetCampaignSettingsDispatch from './TargetCampaignSettingsDispatch';
import TargetDmCampaignSettingsDesign from './TargetDmCampaignSettingsDesign';
import TargetCampaignSettingsCoupon from './TargetCampaignSettingsCoupon';
import TargetCampaignSettingsMisc from './TargetCampaignSettingsMisc';

export default {
  name: 'TargetCampaignSituationalShow',
  components: {
    TargetCampaignShowHead,
    TargetCampaignShowInfoBox,
    TargetCampaignSettingsSection,
    TargetCampaignSettingsDispatch,
    TargetDmCampaignSettingsDesign,
    TargetCampaignSettingsCoupon,
    TargetCampaignSettingsMisc
  },
  mixins: [TargetCampaignShowView],
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('targetCampaign', ['isDmCampaign', 'isUtmPopupCampaign']),
    sections() {
      return this.sectionIds.map(id => {
        const titlePath =
          id === 'dispatch'
            ? this.isDmCampaign
              ? 'dispatch_dm'
              : 'dispatch_popup'
            : id;

        return { id, label: this.$t(titlePath) };
      });
    },
    sectionIds() {
      const { campaign_type } = this.campaign;
      const campaignTypesWithoutCoupon = this.isDmCampaign
        ? [
            TargetCampaignType.MILEAGE_EXPIRING,
            TargetCampaignType.COUPON_EXPIRY
          ]
        : [
            TargetCampaignType.MILEAGE_EXPIRING,
            TargetCampaignType.NEW_VISITOR_SIGNUP,
            TargetCampaignType.UNPAID_ORDER_REMINDING,
            TargetCampaignType.MEMBER_INACTIVATION_PREVENTION,
            TargetCampaignType.UTM_POPUP
          ];
      const useCoupon = !campaignTypesWithoutCoupon.includes(campaign_type);
      return [
        'dispatch',
        'design',
        useCoupon ? 'coupon' : null,
        this.isDmCampaign ||
        (this.isSuperAdminAccessible && !this.isUtmPopupCampaign)
          ? 'misc'
          : null
      ].filter(Boolean);
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignSituationalShow__tabs {
  margin-top: 24px;
}
</style>

<i18n locale="ko">
{
  "dispatch_dm": "발송 설정",
  "dispatch_popup": "노출 설정",
  "design": "디자인",
  "coupon": "쿠폰/이벤트",
  "misc": "기타 설정"
}
</i18n>
