<template>
  <div class="SmartstorePaymentExpired">
    <SmartstoreWaiting
      :is-waiting="false"
      :message="$t('message')"
      :button-props="buttonProps"
      @click="click"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SmartstoreWaiting from './SmartstoreWaiting';

export default {
  name: 'SmartstorePaymentExpired',
  components: { SmartstoreWaiting },
  data() {
    return { isSubmitting: false };
  },
  computed: {
    buttonProps() {
      return {
        buttonStyle: 'default',
        submitLabel: this.$t('submit_label'),
        submittingLabel: this.$t('submitting_label'),
        isSubmitting: this.isSubmitting
      };
    }
  },
  methods: {
    ...mapActions('smartstore', ['requestRetryProductMatchJob']),
    click() {
      this.isSubmitting = true;
      this.requestRetryProductMatchJob().finally(
        () => (this.isSubmitting = false)
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "message": "결제 가능한 시점이 지났습니다. 상품 매칭표 등록부터 다시 시작해주세요.",
  "submit_label": "상품 매칭표 다시 등록",
  "submitting_label": "상품 매칭표 다시 등록 요청 중.."
}
</i18n>
