<template>
  <AppAjaxContent :is-loading="isLoading">
    <ReviewMessagesSearchbar
      :search-types="SEARCH_TYPES"
      v-on="searchBarEvents"
    />
    <AppInfoBox>
      {{ infoMessage }}
    </AppInfoBox>
    <AppResourceTable
      table-id="review-messages-chatbot-messages-history-table"
      :resources="messages"
      :rows="rows"
      :columns="COLUMNS"
      @paginate="paginate"
    />
  </AppAjaxContent>
</template>

<script>
import ReviewMessagesSearchbar from './ReviewMessagesSearchbar';
import ReviewMessagesView from './ReviewMessagesView';

export default {
  name: 'ReviewMessagesChatbotMessagesHistory',
  components: { ReviewMessagesSearchbar },
  mixins: [ReviewMessagesView],
  data() {
    return {
      MESSAGES_URL: '/review/chatbot_messages',
      MEDIA: 'chatbot_messages',
      COLUMNS: [
        { id: 'id', label: this.$t('app.id') },
        { id: 'date', label: this.$t('app.date'), type: 'pre' },
        { id: 'userName', label: this.$t('message.user_name') },
        { id: 'userUsername', label: this.$t('message.user_username') },
        {
          id: 'to',
          label: this.$t('message.to_phone')
        },
        {
          id: 'products',
          label: this.$t('message.products_writeable_review'),
          type: 'pre'
        }
      ],
      SEARCH_TYPES: [
        {
          value: 'to',
          label: this.$t('message.to_phone')
        },
        { value: 'order_code', label: this.$t('orders.code') },
        {
          value: 'user_name',
          label: this.$t('message.user_name')
        },
        { value: 'user_username', label: this.$t('message.user_username') }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .review-messages-chatbot-messages-history-table__id {
    flex: 1 0 80px;
  }

  .review-messages-chatbot-messages-history-table__date {
    flex: 5 0 240px;
  }

  .review-messages-chatbot-messages-history-table__user-name {
    flex: 2 0 80px;
  }

  .review-messages-chatbot-messages-history-table__user-username {
    flex: 2 0 110px;
  }

  .review-messages-chatbot-messages-history-table__to {
    flex: 2 0 120px;
  }

  .review-messages-chatbot-messages-history-table__products {
    flex: 20 0 220px;
  }
}
</style>
