import { constructEnum } from '@/lib/enum';

const DisplayProductSortType = constructEnum('DisplayProductSortType', {
  CREATED_DESC: 0,
  UPDATED_DESC: 10,
  DISPLAYED_DESC: 20,
  MOST_CLICKED: 30,
  MOST_PURCHASED: 40,
  HIGHEST_PURCHASED_PRICE: 50,
  MOST_CARTED: 60,
  HIGHEST_PURCHASE_RATE: 70,
  MOST_REVIEWED: 80,
  HIGHEST_REVIEW_SCORE: 90
});

DisplayProductSortType.isSortRecentDaysDisabled = function(sortType) {
  return [
    DisplayProductSortType.CREATED_DESC,
    DisplayProductSortType.UPDATED_DESC,
    DisplayProductSortType.DISPLAYED_DESC
  ].includes(sortType);
};

export default DisplayProductSortType;
