import _ from 'lodash';

export default {
  methods: {
    productCodes(report, item, column) {
      if (_.isEmpty(report)) return [];
      if (item === 'icon') {
        return _.compact(
          _.chain(report[item])
            .keys()
            .flatMap(iconCode => report[item][iconCode][column])
            .uniq()
            .value()
        );
      }
      return report[item][column] ? report[item][column] : [];
    }
  }
};
