export default {
  model: {
    prop: 'hash',
    event: 'change'
  },
  props: {
    hash: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      currentHash: {}
    };
  },
  mounted() {
    this.setCurrentHash(this.hash, false);
  },
  watch: {
    hash(newHash) {
      this.setCurrentHash(newHash, false);
    }
  }
};
