import { constructEnum } from '@/lib/enum';

const MakeshopScriptInstallStatus = constructEnum(
  'MakeshopScriptInstallStatus',
  {
    SUCCESS: 0,
    SKIN_ID_TYPE_ERROR: 10,
    SKIN_ID_NOT_AVAILABLE_ERROR: 20,
    ALREADY_INSTALLED_ERROR: 30,
    ERROR: 40
  }
);

MakeshopScriptInstallStatus.message = (status, type, skin_id, message) => {
  let args = null;
  switch (status) {
    case MakeshopScriptInstallStatus.SUCCESS:
      args = [type];
      break;
    case MakeshopScriptInstallStatus.SKIN_ID_NOT_AVAILABLE_ERROR:
      args = [skin_id];
      break;
    case MakeshopScriptInstallStatus.ERROR:
      args = [message];
      break;
  }
  return MakeshopScriptInstallStatus.t(status, args);
};
export default MakeshopScriptInstallStatus;
