import { constructEnum } from '@/lib/enum';

const DisplayProductManagementExecuteStatus = constructEnum(
  'DisplayProductManagementExecuteStatus',
  {
    NONE: 0,
    PENDING: 100,
    PREPARING: 200,
    CALCULATING: 300,
    CALCULATED: 400,
    DISPATCHING: 500,
    CONFIRM: 600,
    DISPATCH_CONFIRMED: 610,
    COMPLETE_BUT_REQUIRED_CONFIRM: 650,
    COMPLETE: 700,
    ERROR_PREPARING: 1000,
    ERROR_CALCULATING: 1100,
    ERROR_DISPATCHING: 1500,
    ERROR_TIMEOUT: 1600,
    ERROR_LOGIN_BLOCKED: 1800,
    ERROR_SESSION_EXPIRED: 1900,
    SAMPLE: 10_000
  }
);

DisplayProductManagementExecuteStatus.ERRORED_STATUSES = [
  DisplayProductManagementExecuteStatus.ERROR_PREPARING,
  DisplayProductManagementExecuteStatus.ERROR_CALCULATING,
  DisplayProductManagementExecuteStatus.ERROR_DISPATCHING,
  DisplayProductManagementExecuteStatus.ERROR_TIMEOUT,
  DisplayProductManagementExecuteStatus.ERROR_LOGIN_BLOCKED,
  DisplayProductManagementExecuteStatus.ERROR_SESSION_EXPIRED
];

DisplayProductManagementExecuteStatus.isErrored = function(executeStatus) {
  return DisplayProductManagementExecuteStatus.ERRORED_STATUSES.includes(
    executeStatus
  );
};

export default DisplayProductManagementExecuteStatus;
