var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":!_vm.data}},[(_vm.data)?_c('AppForm',_vm._b({on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
return [(id === 'download_fit_products')?[_c('div',{staticClass:"AppForm__group-field"},[_c('i18n',{attrs:{"path":"download_fit_products.field"}},[_c('AppSelect',{attrs:{"options":_vm.dateTypeOptions,"inline":""},model:{value:(_vm.fit_products.date_type),callback:function ($$v) {_vm.$set(_vm.fit_products, "date_type", $$v)},expression:"fit_products.date_type"}}),_c('AppDateRangePicker',{model:{value:(_vm.fit_products),callback:function ($$v) {_vm.fit_products=$$v},expression:"fit_products"}}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.isDownloading
                  ? _vm.$t('downloading')
                  : _vm.$t('download_fit_products.button'),"disabled":_vm.isDownloading,"menu-items":[
                [
                  {
                    label: _vm.$t('download_fit_products.excel_download'),
                    clickHandler: function (ref) {
                      var close = ref.close;

                      _vm.download_fit_products_file('excel');
                      close();
                    }
                  },
                  {
                    label: _vm.$t('download_fit_products.csv_download'),
                    clickHandler: function (ref) {
                      var close = ref.close;

                      _vm.download_fit_products_file('csv');
                      close();
                    }
                  }
                ]
              ]}})],1),_c('div',{staticClass:"FitSettingsBasic__download-fit-products-description"},[_c('i18n',{attrs:{"path":"download_fit_products.description"}},[_c('AppInternalLink',{staticClass:"FitSettingsBasic__download-fit-products-description-link",attrs:{"to":"/fit/size/products"}},[_vm._v(_vm._s(_vm.$t('download_fit_products.fit_products_size_menu')))])],1)],1)],1)]:_vm._e()]}}],null,false,3030202196),model:{value:(_vm.fit_settings),callback:function ($$v) {_vm.fit_settings=$$v},expression:"fit_settings"}},'AppForm',_vm.fitFormProps,false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }