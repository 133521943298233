<template>
  <div class="ReviewImportAdminSmartstore">
    <AppAjaxContent :is-loading="isLoading">
      <template>
        <AppForm
          object-id="smartstore_settings"
          v-bind="formProps"
          submit-button
          v-on="formEvents"
        >
          <template #group="{ type, dataType, value }">
            <template v-if="type === 'static'">
              <AppData v-if="value !== null" :type="dataType" :value="value" />
              <div v-else>-</div>
            </template>
          </template>
        </AppForm>
      </template>
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SmartstoreStatus from '@/enums/SmartstoreStatus';

export default {
  name: 'ReviewImportAdminSmartstore',
  mixins: [FormView],
  data() {
    return { isLoading: true };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            { id: 'smartstore_name', type: 'text', validate: ['required'] },
            {
              id: 'smartstore_url',
              type: 'url',
              validate: [
                {
                  rule: v => {
                    if (!v) return true;

                    const regex = /^https:\/\/(smartstore|brand)\.naver\.com\/[a-zA-Z0-9-_]+\/?$/;
                    return regex.test(v);
                  },
                  errorMessage: this.$t('validations.url_format')
                }
              ]
            },
            {
              id: 'smartstore_status',
              type: 'select',
              options: SmartstoreStatus.options()
            },
            { id: 'smartstore_access_accepted', type: 'checkbox' },
            {
              id: 'estimation_average_count_per_day',
              type: 'static',
              dataType: 'number'
            },
            { id: 'starter_info', type: 'static' },
            {
              id: 'estimation_calculated_at',
              type: 'static',
              dataType: 'datetime'
            },
            { id: 'starter_balance', type: 'static' },
            { id: 'enable_smartstore_review_sync', type: 'checkbox' },
            {
              id: 'smartstore_expires_at',
              type: 'static',
              dataType: 'datetime'
            }
          ].map(e => ({ ...e, label: this.$t(e.id) }))
        }
      ];
    }
  },
  mounted() {
    api
      .get('smartstore/admin_settings')
      .then(
        ({ data }) =>
          (this.orgFormObject = this.smartstoreObject(data.smartstore_settings))
      )
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('smartstore/admin_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(
          ({ data }) =>
            (this.orgFormObject = this.smartstoreObject(
              data.smartstore_settings
            ))
        )
        .finally(() => (this.isSubmitting = false));
    },
    smartstoreObject(smartstoreSettings) {
      const starterInfo = this.$t('starter_info_data', {
        month: smartstoreSettings.smartstore_sync_months,
        estimation_total_count: smartstoreSettings.estimation_total_count
      });
      const starterBalanceInfo = this.$t('starter_balance_info_data', {
        starter_balance: smartstoreSettings.starter_balance,
        min_reviews_count: smartstoreSettings.starter_min_reviews_count,
        max_reviews_count: smartstoreSettings.starter_max_reviews_count
      });
      return {
        ...smartstoreSettings,
        starter_info: starterInfo,
        starter_balance: starterBalanceInfo
      };
    }
  }
};
</script>

<i18n locale="ko">
{
  "smartstore_name": "스마트스토어 이름",
  "smartstore_url": "스마트스토어 URL",
  "smartstore_status": "스마트스토어 상태",
  "smartstore_access_accepted": "대행사 권한 초대 완료",
  "estimation_average_count_per_day": "하루 평균 연동 리뷰수",
  "starter_info": "스마트스토어 과거 리뷰 연동 정보",
  "starter_info_data": "%{month}개월, %{estimation_total_count}개 리뷰",
  "starter_balance_info_data": "%{starter_balance}개 (결제구간: %{min_reviews_count} ~ %{max_reviews_count})",
  "estimation_calculated_at": "리뷰수 계산 시각",
  "starter_balance": "잔여 과거 리뷰 연동수",
  "enable_smartstore_review_sync": "스마트스토어 리뷰 매일 연동 사용",
  "smartstore_expires_at": "스마트스토어 리뷰 매일 연동 만료일"
}
</i18n>
