<template>
  <AppModalDefault
    :title="title"
    :sub-title="campaign.name"
    width="wide"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppSearchbar @submit="search">
          <li>
            <AppSearchWithType
              v-model="searchWithType"
              :search-types="searchTypes"
            />
          </li>
        </AppSearchbar>
        <TargetCampaignStatsDetailTable
          v-bind="{
            campaign,
            isMassCampaign,
            pagination,
            resources,
            reportItemType
          }"
          :table-name="
            subReportType ? TargetSubReportType.t(subReportType) : $t('app.all')
          "
          @change-pagination="pagination = $event"
        />
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import { filterItems } from '@/lib/text-search';
import DialogView from '@/mixins/DialogView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';
import TargetSubReportType from '@/enums/TargetSubReportType';
import TargetCampaignGroupType from '@/enums/TargetCampaignGroupType';
import TargetCampaignStatsDetailTable from './TargetCampaignStatsDetailTable';

export default {
  name: 'TargetCampaignStatsDetailDialog',
  components: { TargetCampaignStatsDetailTable },
  mixins: [DialogView, ResourceViewNoRoute],
  props: {
    reportItemType: {
      type: String,
      required: true,
      validator: v =>
        [
          'checked_users',
          'clicked_purchased_orders',
          'clicked_users',
          'purchased_orders',
          'visited_users',
          'clicked_visited_users',
          'reviewed_sub_orders',
          'clicked_reviewed_sub_orders',
          'joined_users'
        ].includes(v)
    },
    dateRange: { type: Object, default: () => ({}) },
    subReportType: { type: Number, default: null },
    campaign: { type: Object, required: true }
  },
  data() {
    return {
      isLoading: true,
      pagination: { page: 1, per: 100 },
      searchTypes: [
        { value: 'username', label: this.$t('app.username') },
        { value: 'name', label: this.$t('app.name') }
      ],
      totalItems: []
    };
  },
  computed: {
    TargetSubReportType: () => TargetSubReportType,
    title() {
      const { formatDate } = this.$options.filters;

      let prefix = '';
      if (!this.isMassCampaign) {
        const { start_date, end_date } = this.dateRange;
        prefix =
          start_date === end_date
            ? `${formatDate(start_date)} `
            : `${formatDate(start_date)} ~ ${formatDate(end_date)} `;
      }
      const reportItemType = TargetSamplingMethod.toCheckedColumn(
        this.reportItemType
      );
      return `${prefix}${this.campaignText(
        `detail_dialog_title.${reportItemType}`
      )}`;
    },
    reportItems() {
      const { search_type, search_query } = this.orgResourceParams;
      return filterItems(this.totalItems, search_type, search_query);
    },
    resources() {
      const { per, page } = this.pagination;

      const indexStart = per * (page - 1);
      const indexEnd = per * page;

      return {
        ...this.pagination,
        total_count: this.reportItems.length,
        items: this.reportItems.slice(indexStart, indexEnd)
      };
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    isMassCampaign() {
      return this.campaign.campaign_group_type === TargetCampaignGroupType.MASS;
    }
  },
  created() {
    this.setOrgResourceParams({
      ...this.dateRange,
      report_item_type: this.reportItemType,
      search_type: 'username',
      search_query: '',
      sub_report_type: this.subReportType
    });
  },
  methods: {
    campaignText(key) {
      return this.$store.getters['targetCampaigns/campaignText'](
        this.campaign,
        key
      );
    },
    search() {
      this.orgResourceParams = this.resourceParams;
    },
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/target/campaigns/${this.campaign.id}/report_items`, { params })
        .then(({ data }) => (this.totalItems = data.report_items))
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>
