<template>
  <div v-if="html || src" class="AppIframeViewer" :style="{ height }">
    <iframe
      v-if="!isLoadingForced"
      ref="iframe"
      class="AppIframeViewer__iframe"
      v-bind="src ? { src } : { srcdoc: html }"
      :style="{ opacity: isShowingSpinner ? 0 : 1 }"
      @load="isLoading = false"
    />
    <div v-if="isShowingSpinner" class="AppIframeViewer__spinner-wrapper">
      <AppSpinner class="AppIframeViewer__spinner" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppIframeViewer',
  props: {
    src: { type: String, default: null },
    html: { type: String, default: null },
    height: { type: String, default: '100%' },
    isLoadingForced: { type: Boolean, default: false }
  },
  data: () => ({ isLoading: true }),
  computed: {
    origin() {
      const url = new URL(this.src);
      return url.origin;
    },
    isShowingSpinner() {
      return this.isLoading || this.isLoadingForced;
    }
  },
  watch: {
    src() {
      this.isLoading = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_transitions.scss';

.AppIframeViewer {
  position: relative;
}

.AppIframeViewer__iframe {
  width: 100%;
  height: 100%;
  @include transition-basic(opacity);
}

.AppIframeViewer__spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
}

$spinner-size: 30px;
.AppIframeViewer__spinner {
  margin-top: $spinner-size * -0.5;
  font-size: $spinner-size;
}
</style>
