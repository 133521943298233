import { constructEnum } from '@/lib/enum';

const ReviewBonusPeriod = constructEnum('ReviewBonusPeriod', {
  ONE_WEEK: 7,
  TWO_WEEKS: 14,
  THREE_WEEKS: 21,
  FOUR_WEEKS: 28,
  ONE_MONTH: 100,
  TWO_MONTHS: 200,
  THREE_MONTHS: 300,
  FOUR_MONTHS: 400,
  FIVE_MONTHS: 500,
  SIX_MONTHS: 600,
  TWELVE_MONTHS: 1200
});

ReviewBonusPeriod.WEEK_PERIOD = [
  ReviewBonusPeriod.ONE_WEEK,
  ReviewBonusPeriod.TWO_WEEKS,
  ReviewBonusPeriod.THREE_WEEKS,
  ReviewBonusPeriod.FOUR_WEEKS
];

ReviewBonusPeriod.bonusAmount = function(bonusPeriod) {
  if (ReviewBonusPeriod.WEEK_PERIOD.includes(bonusPeriod)) return bonusPeriod;
  return bonusPeriod / 100;
};

export default ReviewBonusPeriod;
