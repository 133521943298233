var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppSearchbar',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('li',[_c('AppDateRangePicker',{attrs:{"presets":[
          'recent_year',
          'last_7days',
          'last_30days',
          'this_month',
          'last_month',
          'last_quarter',
          'last_year'
        ],"min-date":"2021-06-01"},on:{"update":_vm.changeDateRange},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('li',[_c('AppButton',{attrs:{"label":_vm.$t('set_competing_group'),"button-style":"grey-outline","disabled":!!_vm.brandIds.length,"tooltip":!!_vm.brandIds.length ? _vm.$t('set_competing_group_tooltip') : null},on:{"click":_vm.openCompetingGroupModal}})],1),(_vm.isSuperAdminAccessible)?_c('li',[_c('AppButton',{staticClass:"super-admin__item",attrs:{"label":_vm.$t('set_competing_brands')},on:{"click":_vm.openCompetingBrandsModal}})],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }