<template>
  <AppButton
    button-style="grey-outline"
    :tooltip="tooltip"
    :disabled="buttonDisabled"
    :label="label"
    @click="syncResource"
  />
</template>

<script>
import moment from 'moment';
import BrandSyncType from '@/enums/BrandSyncType';
import ButtonSyncable from '@/mixins/ButtonSyncable';

export default {
  name: 'AppButtonSyncOrder',
  mixins: [ButtonSyncable],
  computed: {
    brandSyncType() {
      return BrandSyncType.ORDER;
    },
    tooltipIdleInfoText() {
      return this.$t('tooltip.idle_info', [this.$t('sync_target')]);
    },
    syncConfirmMessage() {
      const startDate = this.formatDate(moment().subtract(1, 'days'));
      const endDate = this.formatDate(moment());
      return this.$t('sync_button.confirm.content_html_with_dates', [
        startDate,
        endDate,
        this.$t('sync_target')
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "name": "주문 정보 수동",
  "sync_target": "주문 정보",
  "tooltip" : {
    "idle_info": "버튼 클릭 시 어제부터 오늘까지 2일간 등록/수정된 {0}((가)) 동기화 됩니다."
  }
}
</i18n>
