<template>
  <AppModeless @close="$emit('close')">
    <AppCalendar
      v-bind="calendarProps"
      @date-click="dateClick"
      @click-prev-month="clickPrevMonth"
      @click-next-month="clickNextMonth"
    />
    <div class="AppDatePickerPopup__foot">
      <AppForm v-bind="formProps" v-on="formEvents">
        <template #group="{ id, value, error }">
          <template v-if="id === 'date'">
            <div class="AppDatePickerPopup__foot-date">
              <div class="AppDatePickerPopup__foot-date-left">
                <AppDateInput
                  v-focus
                  :value="value"
                  :invalid="!!error"
                  @change="setSelectedDate"
                  @blur="blur"
                  @focus-next="focusNext"
                />
              </div>
              <div class="AppDatePickerPopup__foot-date-right">
                <AppButton :label="$t('app.close')" @click="$emit('close')" />
                <AppButtonSubmit
                  ref="submitButton"
                  :error-summary="errorSummary"
                  :submit-label="$t('app.apply')"
                />
              </div>
            </div>
          </template>
        </template>
      </AppForm>
    </div>
  </AppModeless>
</template>

<script>
import moment from 'moment';
import FormView from '@/mixins/FormView';
import { getMonthDate } from '@/lib/date';
import focusChild from '@/lib/focusChild';

export default {
  name: 'AppDatePickerPopup',
  mixins: [FormView],
  props: {
    date: { type: String, default: null },
    minDate: { type: String, default: null },
    maxDate: { type: String, default: null }
  },
  data() {
    let month;
    if (this.date) month = moment(this.date);
    else {
      month = moment();
      if (this.maxDate) {
        const maxDate = moment(this.maxDate);
        if (month.isAfter(maxDate)) month = maxDate;
      }
      if (this.minDate) {
        const minDate = moment(this.minDate);
        if (month.isBefore(minDate)) month = minDate;
      }
    }

    return {
      orgFormObject: { date: this.date },
      monthDate: month.startOf('month').toVal(),
      detectFormDataChange: false
    };
  },
  computed: {
    calendarProps() {
      const { date } = this.formObject;
      const start = date ? moment(date).toDate() : null;
      return {
        monthDate: moment(this.monthDate)
          .add(1, 'day') // HACK - timezone 다를 때 하루 전 날짜 보여주는 버그가 있다.
          .toDate(),
        start,
        end: start,
        minDate: this.minDate ? moment(this.minDate).toDate() : null,
        maxDate: this.maxDate ? moment(this.maxDate).toDate() : null
      };
    },
    formSections() {
      const { minDate, maxDate } = this;
      return [
        {
          groups: [
            {
              id: 'date',
              label: '',
              validate: [
                {
                  rule: v => v && moment(v).isValidDate(),
                  errorMessage: this.$t('validations.date_format')
                },
                ...(minDate && maxDate
                  ? [
                      {
                        rule: v =>
                          moment(v).isSameOrAfter(minDate, 'day') &&
                          moment(v).isSameOrBefore(maxDate, 'day'),
                        errorMessage: this.$t(
                          'validations.selected_date_should_included_in_date_range',
                          [this.formatDate(minDate), this.formatDate(maxDate)]
                        )
                      }
                    ]
                  : minDate
                  ? [
                      {
                        rule: v => moment(v).isSameOrAfter(minDate, 'day'),
                        errorMessage: this.$t(
                          'validations.selected_date_should_be_gte',
                          [this.formatDate(minDate)]
                        )
                      }
                    ]
                  : maxDate
                  ? [
                      {
                        rule: v => moment(v).isSameOrBefore(maxDate, 'day'),
                        errorMessage: this.$t(
                          'validations.selected_date_should_be_lte',
                          [this.formatDate(maxDate)]
                        )
                      }
                    ]
                  : [])
              ]
            }
          ]
        }
      ];
    }
  },
  methods: {
    clickNextMonth() {
      this.monthDate = moment(this.monthDate)
        .add(1, 'month')
        .toVal();
    },
    clickPrevMonth() {
      this.monthDate = moment(this.monthDate)
        .subtract(1, 'month')
        .toVal();
    },
    dateClick(value) {
      const date = moment(value);
      this.setSelectedDate(
        moment(this.formObject.date).isSame(date) ? null : date.toVal()
      );
    },
    setSelectedDate(selectedDate) {
      this.formObject.date = selectedDate;
      const monthDate = getMonthDate(selectedDate);
      if (monthDate) this.monthDate = monthDate;

      if (this.errorSummary) this.validateField('date');
    },
    blur() {
      if (moment(this.formObject.date).isValidDate())
        this.validateField('date');
    },
    submit() {
      this.$emit('apply', this.formObject.date);
    },
    focusNext() {
      focusChild(this.$refs.submitButton.$el);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_clearfix.scss';

.AppDatePickerPopup__foot {
  padding: 8px;
  border-top: 1px solid #ddd;
}

.AppDatePickerPopup__foot-date {
  @include clearfix;
}

.AppDatePickerPopup__foot-date-left {
  float: left;
}

.AppDatePickerPopup__foot-date-right {
  float: right;
}
</style>
