<template>
  <div class="TargetEmailLivePreview">
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
      <tr>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <td align="center" v-html="html" />
      </tr>
    </table>
  </div>
</template>

<script>
import _ from 'lodash';
import Liquid from 'liquidjs';
import juice from 'juice';

export default {
  name: 'TargetEmailLivePreview',
  props: {
    css: { type: String, required: true },
    templates: { type: Object, required: true },
    params: { type: Object, required: true },
    translations: { type: Object, required: true }
  },
  data() {
    const liquidEngine = new Liquid({
      fs: {
        exists: async () => true,
        readFile: async filepath => this.templates[filepath],
        resolve: (_root, file) => file
      }
    });
    liquidEngine.registerFilter('t', (key, ...args) =>
      args.reduce(
        (s, [k, v]) => s.replace(new RegExp(`%{${k}}`, 'g'), v),
        this.translations[key]
      )
    );

    const liquidTemplate = liquidEngine.parse(
      this.templates[`campaign/_${this.params.campaign_view}`]
    );

    return { html: '', liquidEngine, liquidTemplate };
  },
  computed: {
    escapedParams() {
      const escapeParams = params =>
        _.reduce(
          params,
          (m, v, k) => {
            m[k] = _.isPlainObject(v)
              ? escapeParams(v)
              : typeof v === 'string' && !k.endsWith('_html')
              ? _.escape(v)
              : v;
            return m;
          },
          {}
        );
      return escapeParams(this.params);
    }
  },
  watch: {
    params() {
      this.recalcHtmlDebounced();
    }
  },
  created() {
    this.recalcHtml();
    this.recalcHtmlDebounced = _.debounce(this.recalcHtml, 200);
  },
  methods: {
    recalcHtml() {
      this.liquidEngine
        .render(this.liquidTemplate, this.escapedParams)
        .then(html => (this.html = juice.inlineContent(html, this.css)));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_body.scss';

.TargetEmailLivePreview {
  @include body;
}
</style>
