<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppDatePicker id="basic" v-model="dateBasic" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="preset">Preset Value</label>
      </div>
      <AppDatePicker id="preset" v-model="datePreset" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="right">Right Aligned</label>
      </div>
      <div class="ui-section__item ui-section__item--right">
        <AppDatePicker id="right" v-model="dateRight" />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppDatePicker id="disabled" v-model="dateDisabled" disabled />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Invalid</label>
      </div>
      <AppDatePicker id="disabled" v-model="dateInvalid" invalid />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Min</label>
      </div>
      <AppDatePicker id="disabled" v-model="dateMin" min-date="2020-01-10" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Max</label>
      </div>
      <AppDatePicker id="disabled" v-model="dateMax" max-date="2024-02-10" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Min, Max</label>
      </div>
      <AppDatePicker
        id="disabled"
        v-model="dateMinMax"
        min-date="2024-01-10"
        max-date="2024-02-10"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiDatePicker',
  data() {
    return {
      dateBasic: null,
      datePreset: '2019-08-11',
      dateRight: '1945-08-15',
      dateDisabled: null,
      dateInvalid: null,
      dateMin: null,
      dateMax: null,
      dateMinMax: null
    };
  }
};
</script>
