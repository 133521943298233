import _ from 'lodash';

export default {
  computed: {
    isNumber() {
      return (
        this.options.length &&
        this.options.every(o => o.value === null || typeof o.value === 'number')
      );
    },
    isBoolean() {
      return (
        this.options.length &&
        this.options.every(
          o => o.value === null || typeof o.value === 'boolean'
        )
      );
    }
  },
  methods: {
    castValue(value) {
      if (value !== null && value !== undefined && value !== '') {
        if (this.isNumber) {
          return typeof value === 'number' ? value : _.toNumber(value);
        } else if (this.isBoolean) {
          return typeof value === 'boolean' ? value : value === 'true';
        } else {
          return typeof value === 'string' ? value : _.toString(value);
        }
      } else {
        return null;
      }
    }
  }
};
