<template>
  <AppCheckbox v-bind="{ checked }" v-on="$listeners">
    <template #label>
      <div>
        <span v-text="label" />
        <AppExternalLink
          class="AppAgreementCheckbox__terms-link"
          :label="$t('terms.link')"
          :to="termsLink"
          link-style="blue"
        />
      </div>
    </template>
  </AppCheckbox>
</template>

<script>
export default {
  name: 'AppAgreementCheckbox',
  model: { prop: 'checked', event: 'change' },
  props: {
    checked: { type: Boolean, required: true },
    label: { type: String, required: true },
    termsLink: { type: String, required: true }
  }
};
</script>

<style scoped>
.AppAgreementCheckbox__terms-link {
  margin-left: 8px;
}
</style>
