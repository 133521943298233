var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppDataItem',{attrs:{"label":_vm.$t('label'),"badges":[{ label: _vm.badgeLabel, badgeStyle: _vm.badgeStyle }]}},[(_vm.isRegistered)?[_c('div',[_vm._v(_vm._s(_vm.$t('registered', [_vm.bizMessageProfile.yellow_id])))]),_c('AppButton',{staticClass:"BizMessageSettings__button",attrs:{"label":_vm.$t('button_show')},on:{"click":_vm.clickShow}}),(!_vm.bizMessageProfile.grouped)?_c('AppButton',{staticClass:"BizMessageSettings__button",attrs:{"label":_vm.$t('button_add_group_profile'),"bubble-message":_vm.serviceType !== null && _vm.showAddGroupProfileBubbleMessage
            ? _vm.$t('button_add_group_profile_bubble')
            : null,"bubble-side":"bottom-right"},on:{"click":_vm.clickAddGroupProfile}}):_vm._e()]:[_c('div',[_vm._v(_vm._s(_vm.$t('not_registered')))]),_c('AppButton',{staticClass:"BizMessageSettings__button",attrs:{"label":_vm.$t('button_register'),"bubble-message":_vm.serviceType !== null && _vm.showRegisterBubbleMessage
            ? _vm.$t('button_register_bubble')
            : null,"bubble-side":"bottom-right"},on:{"click":_vm.clickRegister}})]],2),(_vm.isRegistered && (_vm.isServiceTypeTarget || _vm.isSuperAdminAccessible))?_c('AppDataItem',{class:[
      'BizMessageSettings__templates',
      { 'super-admin__item': !_vm.isServiceTypeTarget }
    ],attrs:{"label":_vm.$t('templates_label')}},[_c('div',[_vm._v(_vm._s(_vm.$t('templates_description')))]),_c('AppButton',{staticClass:"BizMessageSettings__button",attrs:{"label":_vm.$t('templates_button'),"disabled":!_vm.isTemplateManagable,"tooltip":_vm.manageButtonDisabledTooltip},on:{"click":function($event){return _vm.clickTemplates(_vm.BizMessageTemplateType.CUSTOM)}}}),(_vm.isSuperAdminAccessible)?_c('AppButton',{class:[
        'BizMessageSettings__button',
        { 'super-admin__item': _vm.isSuperAdminAccessible }
      ],attrs:{"label":_vm.$t('group_templates_button'),"disabled":!_vm.isTemplateManagable,"tooltip":_vm.manageButtonDisabledTooltip},on:{"click":function($event){return _vm.clickTemplates(_vm.BizMessageTemplateType.GROUP)}}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }