import { constructEnum } from '@/lib/enum';
import TargetCampaignType from '@/enums/TargetCampaignType';
import i18n from '@/lib/i18n';

const TargetWidgetRecommendationFilterType = constructEnum(
  'TargetWidgetRecommendationFilterType',
  {
    POPULAR: 10,
    NEW_REGISTERED: 20,
    POPULAR_CATEGORIES: 30,
    NEW_REGISTERED_CATEGORIES: 40,
    SAME_CATEGORY_POPULAR: 130,
    SIMILAR_SIZE: 140,
    PURCHASED_TOGETHER: 150,
    CLICKED_BY_CLICKED_USER: 160,
    PURCHASED_BY_CLICKED_USER: 170,
    HAND_PICKED_PRODUCTS: 190,
    PERIODICAL_POPULAR: 200,
    PERIODICAL_POPULAR_IN_CATEGORY: 210,
    NEW_REGISTERED_IN_CATEGORY: 220,
    HIGH_SCORED_PRODUCTS_IN_CATEGORY: 230,
    POPULAR_PRODUCTS_BY_CATEGORY: 240,
    NEW_REGISTERED_PRODUCTS_BY_CATEGORY: 250,
    SAME_CATEGORY_NEW_REGISTERED: 260,
    RECENTLY_POPULAR: 270,
    REVIEW_KEYWORD: 280,
    POTENTIAL_PRODUCTS: 290
  }
);

TargetWidgetRecommendationFilterType.isInitialSettingsRequired = function(
  filterType
) {
  return [
    TargetWidgetRecommendationFilterType.HAND_PICKED_PRODUCTS,
    TargetWidgetRecommendationFilterType.POPULAR_PRODUCTS_BY_CATEGORY,
    TargetWidgetRecommendationFilterType.NEW_REGISTERED_PRODUCTS_BY_CATEGORY
  ].includes(filterType);
};

TargetWidgetRecommendationFilterType.fromCampaignType = function(
  campaignType,
  features_under_construction
) {
  let types = [];
  switch (campaignType) {
    case TargetCampaignType.PRODUCT_RECOMMENDATION_DETAIL_PAGE:
    case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_POPUP:
      types = [
        TargetWidgetRecommendationFilterType.CLICKED_BY_CLICKED_USER,
        TargetWidgetRecommendationFilterType.PURCHASED_BY_CLICKED_USER,
        TargetWidgetRecommendationFilterType.PURCHASED_TOGETHER,
        TargetWidgetRecommendationFilterType.SIMILAR_SIZE,
        TargetWidgetRecommendationFilterType.SAME_CATEGORY_POPULAR,
        TargetWidgetRecommendationFilterType.SAME_CATEGORY_NEW_REGISTERED,
        TargetWidgetRecommendationFilterType.REVIEW_KEYWORD,
        TargetWidgetRecommendationFilterType.POTENTIAL_PRODUCTS,
        TargetWidgetRecommendationFilterType.HIGH_SCORED_PRODUCTS_IN_CATEGORY,
        TargetWidgetRecommendationFilterType.RECENTLY_POPULAR,
        TargetWidgetRecommendationFilterType.PERIODICAL_POPULAR,
        TargetWidgetRecommendationFilterType.NEW_REGISTERED,
        TargetWidgetRecommendationFilterType.POPULAR_PRODUCTS_BY_CATEGORY,
        TargetWidgetRecommendationFilterType.NEW_REGISTERED_PRODUCTS_BY_CATEGORY,
        TargetWidgetRecommendationFilterType.HAND_PICKED_PRODUCTS
      ];
      break;
    case TargetCampaignType.PRODUCT_RECOMMENDATION_CATEGORY_PAGE:
      types = [
        TargetWidgetRecommendationFilterType.PERIODICAL_POPULAR_IN_CATEGORY,
        TargetWidgetRecommendationFilterType.NEW_REGISTERED_IN_CATEGORY,
        TargetWidgetRecommendationFilterType.REVIEW_KEYWORD,
        TargetWidgetRecommendationFilterType.POTENTIAL_PRODUCTS,
        TargetWidgetRecommendationFilterType.HIGH_SCORED_PRODUCTS_IN_CATEGORY
      ];
      break;
    case TargetCampaignType.PRODUCT_RECOMMENDATION_MAIN_PAGE:
      types = [
        TargetWidgetRecommendationFilterType.RECENTLY_POPULAR,
        TargetWidgetRecommendationFilterType.PERIODICAL_POPULAR,
        TargetWidgetRecommendationFilterType.NEW_REGISTERED,
        TargetWidgetRecommendationFilterType.POPULAR_PRODUCTS_BY_CATEGORY,
        TargetWidgetRecommendationFilterType.NEW_REGISTERED_PRODUCTS_BY_CATEGORY,
        TargetWidgetRecommendationFilterType.REVIEW_KEYWORD,
        TargetWidgetRecommendationFilterType.POTENTIAL_PRODUCTS,
        TargetWidgetRecommendationFilterType.HIGH_SCORED_PRODUCTS_IN_CATEGORY,
        TargetWidgetRecommendationFilterType.HAND_PICKED_PRODUCTS
      ];
      break;
    case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_PAGE:
      types = [
        TargetWidgetRecommendationFilterType.RECENTLY_POPULAR,
        TargetWidgetRecommendationFilterType.PERIODICAL_POPULAR,
        TargetWidgetRecommendationFilterType.NEW_REGISTERED,
        TargetWidgetRecommendationFilterType.POPULAR_PRODUCTS_BY_CATEGORY,
        TargetWidgetRecommendationFilterType.NEW_REGISTERED_PRODUCTS_BY_CATEGORY,
        TargetWidgetRecommendationFilterType.REVIEW_KEYWORD,
        TargetWidgetRecommendationFilterType.POTENTIAL_PRODUCTS,
        TargetWidgetRecommendationFilterType.HIGH_SCORED_PRODUCTS_IN_CATEGORY,
        TargetWidgetRecommendationFilterType.HAND_PICKED_PRODUCTS
      ];
      break;
    case TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT:
      types = [
        TargetWidgetRecommendationFilterType.REVIEW_KEYWORD,
        TargetWidgetRecommendationFilterType.POTENTIAL_PRODUCTS
      ];
      break;
  }

  features_under_construction.forEach(feature => {
    feature;
  });

  return types;
};

TargetWidgetRecommendationFilterType.title = function(filterType) {
  const service = this.service(filterType);
  return service ? `[${i18n.t(`app.${service}`)}]` : '';
};

TargetWidgetRecommendationFilterType.service = function(filterType) {
  switch (filterType) {
    case TargetWidgetRecommendationFilterType.SIMILAR_SIZE:
      return 'fit';
    case TargetWidgetRecommendationFilterType.HIGH_SCORED_PRODUCTS_IN_CATEGORY:
      return 'review';
    case TargetWidgetRecommendationFilterType.REVIEW_KEYWORD:
    case TargetWidgetRecommendationFilterType.POTENTIAL_PRODUCTS:
      return 'review_analysis';
  }
  return '';
};

TargetWidgetRecommendationFilterType.tWithTitle = function(filterType) {
  const title = this.title(filterType);
  if (!title) return this.t(filterType);

  return `${title} ${this.t(filterType)}`;
};

export default TargetWidgetRecommendationFilterType;
