var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TargetWidgetCampaignsWidgetRecommendationUnits"},[_c('div',{staticClass:"table-head-control TargetWidgetCampaignsWidgetRecommendationUnits__button-wrapper"},[_c('AppDropdownMenuButton',{staticClass:"TargetWidgetCampaignsWidgetRecommendationUnits__button",attrs:{"label":_vm.$t('add_recommendation_unit.label'),"menu-items":_vm.unitOptions}})],1),(!_vm.recommendationUnits.length)?_c('input',{attrs:{"type":"hidden","name":"campaign[widget_recommendation_units][]","value":""}}):_vm._e(),_c('AppTable',{attrs:{"columns":[
      {
        id: 'filterName',
        label: _vm.$t('filter_name'),
        align: 'left'
      },
      {
        id: 'maxProducts',
        label: _vm.$t('max_products')
      },
      { id: 'actions', label: _vm.$t('actions') }
    ],"rows":_vm.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
    var column = ref.column;
    var row = ref.row;
    var rowIndex = ref.rowIndex;
return [(column.id === 'filterName')?[_c('div',[_vm._v(" "+_vm._s(row.filterName)+" ")]),(_vm.isCategoryPageWidgetCampaign)?_c('AppBadge',{attrs:{"label":row.displayableCategoriesInfo.label,"tooltip":{
            textAlign: 'left',
            message: row.displayableCategoriesInfo.tooltip
          }}}):_vm._e()]:(column.id === 'actions')?[_vm._l(([
            'use_displayable_category_ids',
            'main_title_text',
            'max_products',
            'title_type',
            'sub_title_text',
            'show_product_rank',
            'use_round_button',
            'show_review',
            'show_manager_review'
          ]),function(key,i){return _c('input',{key:("key-" + i),attrs:{"type":"hidden","name":("campaign[widget_recommendation_units][][" + key + "]")},domProps:{"value":row[key]}})}),_c('AppHiddenInputArray',{attrs:{"name":"campaign[widget_recommendation_units][][displayable_category_ids][]","values":row['displayable_category_ids']}}),_c('AppHiddenInputArray',{attrs:{"name":"campaign[widget_recommendation_units][][precompute_category_ids][]","values":row['precompute_category_ids']}}),(row.title_type === _vm.TargetWidgetRecommendationTitleType.IMAGE)?_c('input',{attrs:{"type":"hidden","name":"campaign[widget_recommendation_units][][title_image]"},domProps:{"value":row.title_image}}):_vm._e(),_c('input',{attrs:{"type":"hidden","name":"campaign[widget_recommendation_units][][recommendation_config]"},domProps:{"value":JSON.stringify(row.recommendation_config)}}),_c('AppButtonDraggable',{attrs:{"drag-text":row.filterName},on:{"sort":_vm.sortProductRecommendationUnit}}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"menu-items":[
            Object.assign({}, {label: _vm.$t('app.settings'),
              clickHandler: function (e) { return _vm.clickEditRecommendationUnit(e, row, rowIndex); }},
              _vm.recommendationUnitSettingsButtonData(
                row.recommendation_config.recommendation_filter_type
              )),
            Object.assign({}, {label: _vm.$t('app.delete'),
              style: 'danger',
              clickHandler: function (e) { return _vm.clickDeleteRecommendationUnit(e, rowIndex); }},
              (_vm.recommendationUnits.length === 1 && {
                disabled: true,
                tooltip: _vm.$t('cannot_delete_recommendation_unit_html')
              }))
          ]}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }