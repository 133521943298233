<template>
  <div class="TargetReportsTotalReports">
    <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
      <template #default>
        <AppSearchbar>
          <template #default>
            <li>
              <AppButtonDownload
                button-style="grey"
                :label="$t('app.download_excel')"
                :url="downloadExcelUrl"
              />
            </li>
            <li v-if="isSuperAdminAccessible" class="super-admin__item">
              <AppButtonDownload
                :label="$t('download_detail_excel')"
                :url="downloadFullExcelUrl"
              />
            </li>
          </template>
          <template #right>
            <li>
              <AppSelect
                v-model="campaignDispatchType"
                :options="campaignDispatchTypeOptions"
                :placeholder="$t('searchbar.campaign_type_placeholder')"
              />
            </li>
            <li>
              <AppDateRangePicker v-model="dateRange" required />
            </li>
          </template>
        </AppSearchbar>
        <AppGrid>
          <div class="AppGrid__col AppGrid__col--3">
            <TargetReportsCampaignKpi
              :kpis="kpis"
              :date-range="dateRange"
              :campaign-dispatch-type="campaignDispatchType"
            />
          </div>
          <div class="AppGrid__col AppGrid__col--9">
            <TargetReportsCampaignSectionChart
              :date-range="dateRange"
              :campaign-dispatch-type="campaignDispatchType"
            />
          </div>
        </AppGrid>
        <AppGrid>
          <div class="AppGrid__col">
            <TargetReportsCampaignTotalReportsTable
              :date-range="dateRange"
              :campaign-dispatch-type="campaignDispatchType"
              :total-reports="totalReportsByCampaignDispatchType"
            />
          </div>
        </AppGrid>
      </template>
    </AppAjaxContent>
  </div>
</template>

<script>
import _ from 'lodash';
import qs from 'qs';
import api from '@/lib/api';
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import ResourceView from '@/mixins/ResourceView';
import TargetContentView from '@/mixins/TargetContentView';
import TargetReportsCampaignTotalReportsTable from './TargetReportsCampaignTotalReportsTable';
import TargetReportsCampaignKpi from './TargetReportsCampaignKpi';
import TargetReportsCampaignSectionChart from './TargetReportsCampaignSectionChart';
import TargetCampaignDispatchType from '@/enums/TargetCampaignDispatchType';
import TargetTotalReportType from '@/enums/TargetTotalReportType';

export default {
  name: 'TargetReportsTotalReports',
  components: {
    TargetReportsCampaignTotalReportsTable,
    TargetReportsCampaignKpi,
    TargetReportsCampaignSectionChart
  },
  mixins: [ResourceView, TargetContentView],
  data: () => ({ isLoading: true, totalReports: [], kpis: {} }),
  computed: {
    ...mapState('session', ['targetSettings']),
    ...mapGetters(['isSuperAdminAccessible']),
    TargetCampaignDispatchType: () => TargetCampaignDispatchType,
    defaultResourceParams: () => ({
      start_date: moment()
        .subtract(30, 'day')
        .toVal(),
      end_date: moment()
        .subtract(1, 'day')
        .toVal(),
      campaign_dispatch_type: null
    }),
    dateRange: {
      get() {
        return _.pick(this.resourceParams, 'start_date', 'end_date');
      },
      set(val) {
        this.resourceParams = { ...this.resourceParams, ...val };
        this.searchResource();
      }
    },
    campaignDispatchType: {
      get() {
        const { campaign_dispatch_type } = this.resourceParams;
        return campaign_dispatch_type === null
          ? null
          : parseInt(campaign_dispatch_type);
      },
      set(val) {
        this.resourceParams = {
          ...this.resourceParams,
          campaign_dispatch_type: val
        };
        this.searchResource();
      }
    },
    campaignDispatchTypeOptions() {
      return TargetCampaignDispatchType.options().map(option => ({
        ...option,
        label:
          option.value === TargetCampaignDispatchType.REALTIME
            ? this.$t('searchbar.realtime')
            : option.label
      }));
    },
    downloadExcelUrl() {
      return `/api/target/total_reports/download_excel?${qs.stringify(
        this.orgResourceParams
      )}`;
    },
    downloadFullExcelUrl() {
      return `/api/target/total_reports/download_full_excel?${qs.stringify(
        this.orgResourceParams
      )}`;
    },
    totalReportsByCampaignDispatchType() {
      switch (this.campaignDispatchType) {
        case TargetCampaignDispatchType.DIRECT_MESSAGE:
          return this.totalReports.filter(
            r => r.total_report_type !== TargetTotalReportType.RECOMMENDATION
          );
        case TargetCampaignDispatchType.REALTIME:
          return this.totalReports.filter(
            r => r.total_report_type !== TargetTotalReportType.MASS
          );
        default:
          return this.totalReports;
      }
    }
  },
  created() {
    this.setOrgResourceParams({
      ...this.dateRange,
      campaign_dispatch_type: this.campaignDispatchType
    });
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/target/total_reports', { params })
        .then(({ data }) => {
          this.totalReports = data.total_reports;
          this.kpis = data.kpis;
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "download_detail_excel": "상세 내역 Excel 다운로드",
  "searchbar": {
    "campaign_type_placeholder": "전체 캠페인",
    "realtime": "팝업/위젯 캠페인"
  }
}
</i18n>
