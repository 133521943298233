<template>
  <div>
    <AppButton
      class="DisplayProductDisplayUnits__add-button"
      :label="$t('add_unit.label')"
      :disabled="cannotAddUnit"
      :tooltip="cannotAddUnit ? $t('add_unit.tooltip') : ''"
      :bubble-message="units.length > 0 ? '' : $t('add_unit.bubble')"
      bubble-side="right"
      @click="addUnit"
    />
    <AppTable
      table-id="units"
      class="DisplayProductDisplayUnits__table"
      :columns="columns"
      :rows="units"
    >
      <template #cell="{ column, row, rowIndex }">
        <template v-if="column.id === 'color'">
          <DisplayCornerItemIndexColorBox :color-index="row.color_index" />
        </template>
        <template v-else-if="column.id === 'filter'">
          <DisplayUnitFilterCell
            :unit="row"
            :unit-index="rowIndex"
            :product-option-types="productOptionTypes"
            :errors="errors"
            @change="updateUnit($event, rowIndex)"
            @validate-unit-field="
              $emit('validate-unit-field', { column: $event, index: rowIndex })
            "
          />
        </template>
        <template v-else-if="column.id === 'actions'">
          <input
            v-for="(key, i) in [
              'id',
              'recent_days',
              'filter_type',
              'max_products',
              'stock_count',
              'stock_compare_type',
              'color_index',
              'sort_type',
              'sort_recent_days',
              'product_option_type_id'
            ]"
            :key="`key-${i}`"
            type="hidden"
            :name="`product_display[units][][${key}]`"
            :value="row[key]"
          />
          <input
            v-if="row.product_option_value"
            type="hidden"
            name="product_display[units][][product_option_value_id]"
            :value="row.product_option_value.id"
          />
          <input
            v-for="(position, i) in itemPositions[row.color_index] || []"
            :key="`position-${row.color_index}-${i}`"
            type="hidden"
            name="product_display[units][][item_positions][]"
            :value="position"
          />
          <AppButtonDraggable @sort="sortUnits" />
          <AppButton
            :label="$t('app.delete')"
            @click="deleteUnit(rowIndex, row.color_index)"
          />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { arrayMoveIndex } from '@/lib/array';
import DisplayProductSortType from '@/enums/DisplayProductSortType';
import DisplayProductFilterType from '@/enums/DisplayProductFilterType';
import ValueCompareType from '@/enums/ValueCompareType';

export default {
  name: 'DisplayProductDisplayUnits',
  props: {
    itemPositions: { type: Object, required: true },
    productOptionTypes: { type: Array, required: true },
    errors: { type: Object, required: true }
  },
  data: () => ({ itemList: [] }),
  computed: {
    ...mapState('displayUnits', ['units']),
    ...mapGetters('displayUnits', ['cannotAddUnit']),
    columns() {
      return [
        { id: 'color', label: this.$t('column.color'), width: '52px' },
        { id: 'filter', label: this.$t('column.filter'), align: 'left' },
        { id: 'actions', label: this.$t('app.actions'), width: '132px' }
      ];
    },
    maxUnitCount() {
      return 7;
    },
    availableColorIndex() {
      const colorIndices = _.range(1, this.maxUnitCount + 1);
      const usingColorIndices = this.units.map(unit => unit.color_index);

      return colorIndices.find(i => !usingColorIndices.includes(i));
    },
    itemListFromItemPositions() {
      return _.chain(this.itemPositions)
        .reduce(
          (result, itemIndices, colorIndex) => [
            ...result,
            ...itemIndices.map(index => ({
              colorIndex: Number(colorIndex),
              index
            }))
          ],
          []
        )
        .sortBy('index')
        .value();
    }
  },
  watch: {
    itemPositions() {
      this.itemList = this.itemListFromItemPositions;
    }
  },
  mounted() {
    this.updateItemList(this.itemListFromItemPositions);
  },
  methods: {
    ...mapMutations('displayUnits', [
      'SET_UNITS',
      'ADD_UNIT',
      'UPDATE_UNIT',
      'DELETE_UNIT'
    ]),
    addUnit() {
      const newUnit = this.defaultUnit();

      this.updateItemList(
        this.insertUnitItems(
          this.itemList,
          newUnit.color_index,
          newUnit.max_products,
          this.itemList.length
        )
      );

      this.ADD_UNIT(newUnit);
    },
    updateUnit(newUnit, index) {
      const oldUnit = this.units[index];

      this.UPDATE_UNIT({ unit: newUnit, index });
      this.updateMaxProducts(newUnit.max_products, oldUnit, index);
    },
    defaultUnit() {
      return {
        recent_days: 3,
        filter_type: DisplayProductFilterType.DISPLAYED,
        max_products: 10,
        stock_count: 3,
        stock_compare_type: ValueCompareType.GTE,
        sort_type: DisplayProductSortType.HIGHEST_PURCHASED_PRICE,
        sort_recent_days: 3,
        color_index: this.availableColorIndex
      };
    },
    updateMaxProducts(newMaxProducts, row, rowIndex) {
      const { max_products, color_index } = row;
      let newItemList = this.itemList;

      if (!max_products && newMaxProducts) {
        newItemList = this.insertUnitItems(
          newItemList,
          color_index,
          newMaxProducts,
          this.nextUnitFirstItemIndex(rowIndex, newItemList)
        );
      } else if (max_products < newMaxProducts) {
        const curUnitLastItemIndex = _.findLast(
          this.itemList,
          item => item.colorIndex === color_index
        ).index;
        newItemList = this.insertUnitItems(
          newItemList,
          color_index,
          newMaxProducts - max_products,
          curUnitLastItemIndex + 1
        );
      } else if (max_products > newMaxProducts) {
        let counter = 0;
        newItemList = newItemList.filter(item => {
          if (item.colorIndex !== color_index) return true;

          if (counter >= newMaxProducts) return false;
          else {
            counter++;
            return true;
          }
        });
      }

      row.max_products = newMaxProducts;
      this.updateItemList(newItemList);
    },
    sortUnits({ srcIndex, dstIndex }) {
      this.SET_UNITS(arrayMoveIndex(this.units, srcIndex, dstIndex));

      const unit = this.units[dstIndex];

      let newItemList = this.removeUnitItems(this.itemList, unit.color_index);
      newItemList = this.insertUnitItems(
        newItemList,
        unit.color_index,
        unit.max_products,
        this.nextUnitFirstItemIndex(dstIndex, newItemList)
      );
      this.updateItemList(newItemList);
    },
    nextUnitFirstItemIndex(unitIndex, itemList) {
      return unitIndex === this.units.length - 1
        ? itemList.length
        : (
            itemList.find(
              i => i.colorIndex === this.units[unitIndex + 1].color_index
            ) || {}
          ).index || 0;
    },
    deleteUnit(rowIndex, colorIndex) {
      this.DELETE_UNIT(rowIndex);

      this.updateItemList(this.removeUnitItems(this.itemList, colorIndex));
    },
    insertUnitItems(itemList, colorIndex, count, dstIndex) {
      const newArray = _.cloneDeep(itemList);
      const newItems = Array(count).fill({ colorIndex });
      newArray.splice(dstIndex, 0, ...newItems);
      return this.arrangeItemList(newArray);
    },
    removeUnitItems(itemList, colorIndex) {
      return this.arrangeItemList(
        itemList.filter(item => item.colorIndex !== colorIndex)
      );
    },
    arrangeItemList(itemList) {
      return itemList.map((item, index) => ({ ...item, index }));
    },
    updateItemList(itemList) {
      this.itemList = this.arrangeItemList(itemList);
      this.$emit('update-item-list', this.itemList);
    }
  }
};
</script>

<style scoped>
.DisplayProductDisplayUnits__add-button {
  margin-top: 9px;
}

.DisplayProductDisplayUnits__table {
  margin-top: 12px;
}
</style>

<i18n locale="ko">
{
  "add_unit": {
    "label": "+ 조건 추가",
    "tooltip": "최대 7개의 진열 조건만 추가할 수 있습니다.",
    "bubble": "진열 조건을 추가해 보세요!"
  },
  "column": {
    "color": "구분색",
    "filter": "선정 조건"
  }
}
</i18n>
