import _ from 'lodash';

export default () => {
  const locales = require.context(
    '../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched?.length > 1) {
      const locale = matched[1];
      messages[locale] = messages[locale] || {};
      messages[locale] = _.merge(messages[locale], locales(key));
    }
  });
  return messages;
};
