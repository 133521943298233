<template>
  <div v-tooltip="tooltip">
    <div class="table-line">{{ review.created_at | formatDate }}</div>
    <div v-if="reviewDelay" class="table-line table-line--light">
      {{ reviewDelay }}
    </div>
    <div class="table-line table-line--mt4">
      <AppBadge :label="$t('app.detail_info')" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ReviewReviewsDateCell',
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  computed: {
    reviewDelay() {
      const { sub_order } = this.review;
      if (!sub_order || !sub_order.delivered_at) return;

      const deliveryDate = moment(sub_order.delivered_at).startOf('day');
      const reviewedDate = moment(this.review.created_at).startOf('day');

      return this.$t('review_delay', [
        deliveryDate.isSame(reviewedDate)
          ? this.$t('same_day')
          : this.$t('days_ago', [reviewedDate.diff(deliveryDate, 'days')])
      ]);
    },
    tooltip() {
      const { formatDateTime } = this.$options.filters;
      const messages = [
        `${this.$t('posted_at')}: ${formatDateTime(this.review.created_at)}`
      ];

      const purchasedAt = this.review.order
        ? this.review.order.purchased_at
        : null;
      if (purchasedAt) {
        messages.push(
          `${this.$t('purchased_at')}: ${formatDateTime(purchasedAt)}`
        );
      }

      const deliveredAt = this.review.sub_order
        ? this.review.sub_order.delivered_at
        : null;
      if (deliveredAt) {
        messages.push(
          `${this.$t('delivered_at')}: ${formatDateTime(deliveredAt)}`
        );
      }
      return messages.join('\n');
    }
  }
};
</script>

<i18n locale="ko">
{
  "review_delay": "배송완료 {0}",
  "same_day": "당일",
  "days_ago": "{0}일 후",
  "posted_at": "작성일",
  "purchased_at": "구매일",
  "delivered_at": "배송완료일"
}
</i18n>
<i18n locale="en">
{
  "review_delay": "{0} after delivered",
  "same_day": "Same day",
  "days_ago": "{0} days",
  "posted_at": "Posted at",
  "purchased_at": "Purchased at",
  "delivered_at": "Delivered at"
}
</i18n>
