<template>
  <AppModalDefault :title="$t('sub_title')" :sub-title="name" @close="close">
    <AppForm
      id="fit_product_tags"
      slot="body"
      :sections="groupFilteredSections"
    >
      <template #group="{ id, value }">
        <template
          v-if="id.startsWith('img_tag') || id.startsWith('iframe_tag')"
        >
          <div class="AppForm__group-field">
            <div class="fit-size-product-tags-dialog__option">
              <div>
                <AppInputGroupItem>
                  <label
                    class="fit-size-product-tags-dialog__input"
                    :for="value.size.key"
                  >
                    {{ value.size.label }}
                  </label>
                </AppInputGroupItem>
                <AppInputGroupItem>
                  <AppNumberInput
                    :id="value.size.key"
                    v-model="options[value.size.key]"
                    :digits="4"
                    class="fit-size-product-tags-dialog__input"
                  />
                </AppInputGroupItem>
              </div>
              <AppCheckbox
                v-model="options[value.hideOnlyDomesticBrand.key]"
                class="fit-size-product-tags-dialog__checkbox fit-size-product-tags-dialog__hide-only-domestic-brand"
                :label="value.hideOnlyDomesticBrand.label"
              />
            </div>
          </div>
          <div class="AppForm__group-field">
            <AppCopyableTextarea :value="value.tag" />
          </div>
        </template>
      </template>
    </AppForm>
  </AppModalDefault>
</template>

<script>
import { mapState } from 'vuex';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'FitSizeProductTagsDialog',
  mixins: [DialogView],
  props: {
    name: {
      type: String,
      required: true
    },
    code: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      options: {
        imgWidth: 780,
        imgHideOnlyDomesticBrand: false,
        iframeHeight: 1600,
        iframeHideOnlyDomesticBrand: false,
        iframeForTranslationHeight: 1600,
        iframeForTranslationHideOnlyDomesticBrand: false
      },
      hideOnlyDomesticBrand: ' class="crema-hide-only-domestic-brand"'
    };
  },
  computed: {
    ...mapState('session', ['currentBrand', 'fitSettings']),
    divTag() {
      const tag = `<div
      class="crema-product-fit"
      data-product-code="${this.code}"></div>`;
      return this.remove_unnecessary_white_space(tag);
    },
    imgTag() {
      let tag = `<img
      src="${this.url}.jpg?width=${this.options.imgWidth}"`;
      if (this.options.imgHideOnlyDomesticBrand)
        tag = tag.concat(this.hideOnlyDomesticBrand);

      return this.remove_unnecessary_white_space(tag.concat('>'));
    },
    iframeTag() {
      let tag = `<iframe
      src="${this.url}"
      width="100%" height="${this.options.iframeHeight}"`;
      if (this.options.iframeHideOnlyDomesticBrand)
        tag = tag.concat(this.hideOnlyDomesticBrand);

      return this.remove_unnecessary_white_space(tag.concat('></iframe>'));
    },
    divTagForTranslation() {
      const tag = `<div
      class="crema-product-fit"
      data-product-code="${this.code}"
      data-brand-code="${this.currentBrand.code}"></div>`;
      return this.remove_unnecessary_white_space(tag);
    },
    iframeTagForTranslation() {
      let tag = `<iframe
      src="${this.url}?use-google-translate-button=1"
      width="100%" height="${this.options.iframeForTranslationHeight}"
      style="border: 0;"`;
      if (this.options.iframeForTranslationHideOnlyDomesticBrand)
        tag = tag.concat(this.hideOnlyDomesticBrand);

      return this.remove_unnecessary_white_space(tag.concat('></iframe>'));
    },
    sections() {
      return [
        {
          id: 'product_tag',
          groups: [
            {
              id: 'div_tag',
              type: 'copyable_textarea',
              groupDescription: this.$t('div_tag_description'),
              value: this.divTag,
              isAccessible: !this.isAccessibleToCombinedWidget
            },
            {
              id: 'img_tag',
              value: {
                tag: this.imgTag,
                size: {
                  key: 'imgWidth',
                  label: this.$t('img_width')
                },
                hideOnlyDomesticBrand: {
                  key: 'imgHideOnlyDomesticBrand',
                  label: this.$t('hide_only_domestic_brand')
                }
              }
            },
            {
              id: 'iframe_tag',
              value: {
                tag: this.iframeTag,
                size: {
                  key: 'iframeHeight',
                  label: this.$t('app.height')
                },
                hideOnlyDomesticBrand: {
                  key: 'iframeHideOnlyDomesticBrand',
                  label: this.$t('hide_only_domestic_brand')
                }
              }
            }
          ]
        },
        {
          id: 'product_tag_for_translation',
          groups: [
            {
              id: 'div_tag_for_translation',
              type: 'copyable_textarea',
              value: this.divTagForTranslation,
              isAccessible: !this.isAccessibleToCombinedWidget
            },
            {
              id: 'iframe_tag_for_translation',
              value: {
                tag: this.iframeTagForTranslation,
                size: {
                  key: 'iframeForTranslationHeight',
                  label: this.$t('app.height')
                },
                hideOnlyDomesticBrand: {
                  key: 'iframeForTranslationHideOnlyDomesticBrand',
                  label: this.$t('hide_only_domestic_brand')
                }
              }
            }
          ]
        }
      ];
    },
    groupFilteredSections() {
      const result = this.sections.map(section => ({
        ...section,
        groups: section.groups.filter(group => this.isAccessible(group))
      }));

      return result;
    },
    isAccessibleToCombinedWidget() {
      return this.fitSettings.enable_combined_widget;
    }
  },
  methods: {
    remove_unnecessary_white_space(str) {
      return str.replace(/(\s+\s)/g, ' ');
    },
    isAccessible(item) {
      if (item.isAccessible === undefined) return true;
      return item.isAccessible;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.fit-size-product-tags-dialog__option {
  position: relative;
}

.fit-size-product-tags-dialog__input {
  @include input-base;

  display: inline-block;
  padding: 5px 11px;
  width: auto;
}

.fit-size-product-tags-dialog__hide-only-domestic-brand {
  position: absolute;
  right: 0;
  bottom: 0;
}

.fit-size-product-tags-dialog__checkbox {
  margin-right: 8px;
}
</style>

<i18n locale="ko">
{
  "sub_title": "태그 복사",
  "img_width": "가로 길이",
  "hide_only_domestic_brand": "외부몰에만 노출하고 자사몰에 노출하지 않음",
  "div_tag_description": "자사몰 상품 상세페이지 중간에 위젯을 삽입하고 싶을 경우 사용합니다."
}
</i18n>
