<template>
  <AppSvgIcon
    name="icon-external-link"
    :class="['AppExternalLinkIcon', `AppExternalLinkIcon--${size}`]"
    :height="size === 'small' ? 10 : 14"
  />
</template>

<script>
export default {
  name: 'AppExternalLinkIcon',
  props: {
    size: {
      type: String,
      default: 'default',
      validator: v => ['default', 'small'].includes(v)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppExternalLinkIcon {
  color: $color-disable-text;
  vertical-align: middle;

  &--default {
    margin-left: 8px;
  }
}
</style>
