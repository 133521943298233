var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'AppSelect',
    { 'AppSelect--disabled': _vm.disabled, 'AppSelect--inline': _vm.inline }
  ]},[_c('select',{class:[
      'AppSelect__select',
      ( _obj = {
        'AppSelect__select--focus': _vm.isFocused,
        'AppSelect__select--invalid': _vm.invalid
      }, _obj[("AppSelect__select--" + _vm.size)] = !!_vm.size, _obj )
    ],attrs:{"id":_vm.id,"name":_vm.name,"disabled":_vm.disabled},domProps:{"value":_vm.currentValue},on:{"change":function($event){return _vm.change($event.target.value)},"blur":function($event){return _vm.$emit('blur')}}},[(_vm.placeholder !== null)?_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),_vm._l((_vm.items),function(option,i){return _c('option',{key:i,attrs:{"disabled":option.disabled},domProps:{"value":option.value,"selected":option.value === _vm.currentValue}},[_vm._v(_vm._s(option.label))])})],2),(_vm.placeholder && (_vm.currentValue || _vm.currentValue === 0))?_c('button',{staticClass:"AppSelect__clear-button",attrs:{"type":"button","disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.clear.apply(null, arguments)}}},[_c('AppSvgIcon',{attrs:{"name":"icon-close-small"}})],1):_c('AppSvgIcon',{class:['AppSelect__icon', ( _obj$1 = {}, _obj$1[("AppSelect__icon--" + _vm.size)] = !!_vm.size, _obj$1 )],attrs:{"name":"nav-chevron-down"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }