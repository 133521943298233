import moment from 'moment';
import i18n from '@/lib/i18n';

const isIOS = () =>
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
const isAndroid = () => navigator.userAgent.toLowerCase().includes('android');
const isChrome = () => navigator.userAgent.includes('Chrome');
const isSupportedBrowser = () => isIOS() || isAndroid() || isChrome();

const remainingDates = timestamp => {
  if (timestamp === null) return null;

  const date = moment(timestamp).startOf('day');
  const today = moment().startOf('day');
  return date.diff(today, 'days');
};

const remainingMonths = timestamp => {
  if (timestamp === null) return null;

  const date = moment(timestamp)
    .add(1, 'day')
    .startOf('day');
  const today = moment().startOf('day');
  return date.diff(today, 'months');
};

const getters = {
  mainAlert(_state, getters, _rootState, rootGetters) {
    let type = null;
    const messageArgs = {};

    const {
      reviewRemainingDates,
      targetRemainingDates,
      fitRemainingDates,
      displayRemainingDates,
      channelRemainingDates,
      insightRemainingDates,
      reviewSmsRemainingDates,
      isCorporateMaterialRequired,
      isPhoneMaterialRequired,
      isBizMessageProfileRequired,
      isCorporateMaterialRejected,
      isPhoneMaterialRejected,
      isImwebApiKeyInvalid,
      isBizMessageCampaignStopped
    } = getters;

    const rejectedMaterials = [];
    if (isCorporateMaterialRejected) rejectedMaterials.push('corporate');
    if (isPhoneMaterialRejected) rejectedMaterials.push('phone');

    if (rejectedMaterials.length > 1) type = 'materials_rejected';
    else if (rejectedMaterials.length === 1)
      type =
        rejectedMaterials[0] === 'corporate'
          ? 'corporate_material_rejected'
          : 'phone_material_rejected';
    else if (isBizMessageCampaignStopped) type = 'biz_message_campaign_stopped';
    else {
      const requiredMaterials = [];
      if (isCorporateMaterialRequired)
        requiredMaterials.push('corporate_material');
      if (isPhoneMaterialRequired) requiredMaterials.push('phone_material');
      if (isBizMessageProfileRequired)
        requiredMaterials.push('biz_message_profile');

      if (requiredMaterials.length)
        type = `${requiredMaterials.join('_and_')}_required`;
    }

    if (type === null) {
      const expiredServices = [];
      if (reviewRemainingDates !== null && reviewRemainingDates < 0)
        expiredServices.push('review');

      if (targetRemainingDates !== null && targetRemainingDates < 0)
        expiredServices.push('target');

      const isReviewPreparing = rootGetters['session/isReviewPreparing'];
      if (
        !isReviewPreparing &&
        reviewSmsRemainingDates !== null &&
        reviewSmsRemainingDates <= 0
      )
        expiredServices.push('review_sms');

      const isFitTesting = rootGetters['session/isFitTesting'];
      if (!isFitTesting && fitRemainingDates !== null && fitRemainingDates < 0)
        expiredServices.push('fit');

      if (displayRemainingDates !== null && displayRemainingDates < 0)
        expiredServices.push('display');

      if (channelRemainingDates !== null && channelRemainingDates < 0)
        expiredServices.push('channel');

      if (insightRemainingDates !== null && insightRemainingDates < 0)
        expiredServices.push('insight');

      if (expiredServices.length > 1) {
        type = 'services_expired';
        messageArgs.services = expiredServices
          .map(service => i18n.t(`menu.${service}`))
          .join(', ');
      } else if (expiredServices.length === 1)
        type =
          expiredServices[0] === 'review'
            ? 'review_expired'
            : expiredServices[0] === 'target'
            ? `target_expired${
                rootGetters['session/isTargetLastPaidBillingInfoCustom']
                  ? '_custom_billing_plan'
                  : ''
              }`
            : expiredServices[0] === 'credit'
            ? 'credit_depleted'
            : expiredServices[0] === 'review_sms'
            ? 'review_sms_depleted'
            : expiredServices[0] === 'fit'
            ? 'fit_expired'
            : expiredServices[0] === 'display'
            ? 'display_expired'
            : expiredServices[0] === 'channel'
            ? 'channel_expired'
            : 'insight_expired';
    }

    if (type === null && !isSupportedBrowser()) type = 'unsupported_browser';
    if (type === null && isImwebApiKeyInvalid) type = 'imweb_api_key_invalid';

    return type ? { type, messageArgs } : null;
  },
  isAlertSnoozed: (_state, _getters, { editor }) => id => {
    const { snoozedAlerts } = editor;
    const alert = snoozedAlerts[id];
    return alert && moment() < moment(alert.until);
  },
  fitRemainingMonths(_state, _getters, { session }) {
    const { fitStatus } = session;
    return fitStatus ? remainingMonths(fitStatus.fit_expires_at) : null;
  },
  reviewRemainingDates(_state, _getters, { session }) {
    const { reviewStatus } = session;
    return reviewStatus ? remainingDates(reviewStatus.review_expires_at) : null;
  },
  reviewRemainingMonths(_state, _getters, { session }) {
    const { reviewStatus } = session;
    return reviewStatus
      ? remainingMonths(reviewStatus.review_expires_at)
      : null;
  },
  reviewSmsRemainingDates(_state, _getters, { session }) {
    const { reviewStatus } = session;
    return reviewStatus ? reviewStatus.review_sms_remaining_dates : null;
  },
  targetRemainingDates(_state, _getters, { session }) {
    const { targetStatus } = session;
    return targetStatus ? remainingDates(targetStatus.target_expires_at) : null;
  },
  fitRemainingDates(_state, _getters, { session }) {
    const { fitStatus } = session;
    return fitStatus ? remainingDates(fitStatus.fit_expires_at) : null;
  },
  displayRemainingDates(_state, _getters, { session }) {
    const { displayStatus } = session;
    return displayStatus
      ? remainingDates(displayStatus.display_expires_at)
      : null;
  },
  channelRemainingDates(_state, _getters, { session }) {
    const { channelStatus } = session;
    return channelStatus
      ? remainingDates(channelStatus.channel_expires_at)
      : null;
  },
  insightRemainingDates(_state, _getters, { session }) {
    const { insightStatus } = session;
    return insightStatus
      ? remainingDates(insightStatus.insight_expires_at)
      : null;
  },
  isCorporateMaterialRequired(_state, _getters, { session }) {
    const { brandSettings } = session;
    return brandSettings.corporate_number_required;
  },
  isPhoneMaterialRequired(_state, _getters, { session }) {
    const { brandSettings } = session;
    return brandSettings.phone_number_not_registered;
  },
  isBizMessageProfileRequired(_state, _getters, { session }) {
    const { brandSettings } = session;
    return brandSettings.biz_message_profile_required;
  },
  isCorporateMaterialRejected(_state, _getters, { session }) {
    const { brandSettings } = session;
    return brandSettings.corporate_number_change_rejected;
  },
  isPhoneMaterialRejected(_state, _getters, { session }) {
    const { brandSettings } = session;
    return brandSettings.phone_change_rejected;
  },
  isImwebApiKeyInvalid(_state, _getters, { session }) {
    const { imwebApiKeyValidity } = session;
    return imwebApiKeyValidity === false;
  },
  isBizMessageCampaignStopped(_state, _getters, { session }) {
    const { isBizMessageCampaignStopped } = session;
    return isBizMessageCampaignStopped;
  }
};

const actions = {
  snoozeAlert({ commit, dispatch }, id) {
    const alert = {
      until: moment()
        .add(1, 'day')
        .toISOString()
    };
    commit('editor/SET_SNOOZE_ALERT', { id, alert }, { root: true });
    dispatch('editor/saveEditorConfig', null, { root: true });
  }
};

export default { namespaced: true, getters, actions };
