<template>
  <AppModalDefault :title="$t('title')" @close="close">
    <template slot="body">
      <AppAjaxContent :is-loading="isLoading">
        <AppIframeViewer :html="emailHtml" height="calc(100vh - 128px)" />
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'ReviewSettingsEmailPreviewDialog',
  mixins: [DialogView],
  data() {
    return {
      isLoading: true,
      emailHtml: ''
    };
  },
  mounted() {
    this.isLoading = true;
    api
      .get('review/sample_email')
      .then(({ data }) => {
        this.emailHtml = data.email.html.replace(/http:/g, 'https:');
      })
      .catch(() => {
        this.emailHtml = 'Something went wrong. Please try again.';
      })
      .finally(() => (this.isLoading = false));
  }
};
</script>

<i18n locale="ko">
{
  "title": "이메일 미리보기"
}
</i18n>
