<template>
  <div
    :class="[
      'AppFormHint',
      priority ? `AppFormHint--${priority}` : null,
      disabled ? 'AppFormHint--disabled' : null
    ]"
  >
    <slot />
    <AppMarked
      v-if="message"
      class="AppFormHint__text"
      :text="message"
      :markdown-option="{ breaks: true }"
    />
  </div>
</template>

<script>
export default {
  name: 'AppFormHint',
  props: {
    message: { type: String, default: null },
    priority: {
      type: String,
      default: null,
      validator: v => ['danger', 'admin'].includes(v)
    },
    disabled: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppFormHint {
  @include text-caption;
  margin-top: 4px;

  &--danger {
    color: $color-red;
  }

  &--disabled {
    color: $color-disable-text;
  }

  &--admin {
    background-color: $color-highlight;
  }
}

.AppFormHint__text {
  strong {
    font-weight: bold;
  }

  ::v-deep {
    ul {
      list-style: inside;
    }
  }
}
</style>
