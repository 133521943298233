<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppAlertBox title="Title" content="Contents" />
    </div>
  </div>
</template>

<script>
export default { name: 'UiAlertBox' };
</script>
