<template>
  <div class="TransitionCrossfade">
    <template v-for="(item, index) in items">
      <Transition :key="index" name="crossfade">
        <div v-show="index === visibleIndex" class="TransitionCrossfade__item">
          <slot :item="item" />
        </div>
      </Transition>
    </template>
    <div class="TransitionCrossfade__height-support">
      <slot :item="items[visibleIndex]" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransitionCrossfade',
  props: {
    items: { type: Array, required: true },
    visibleIndex: { type: Number, required: true }
  }
};
</script>

<style lang="scss" scoped>
.TransitionCrossfade {
  position: relative;
}

.TransitionCrossfade__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.TransitionCrossfade__height-support {
  visibility: hidden;
}

@keyframes crossfade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.crossfade-enter-active {
  animation: crossfade-in 1s;
}
.crossfade-leave-active {
  animation: crossfade-in 1s reverse;
}
</style>
