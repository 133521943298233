import Vue from 'vue';

const state = {
  maxToastId: 0,
  toasts: []
};

const mutations = {
  ADD_TOAST(state, toast) {
    state.toasts = [...state.toasts, toast];
  },
  REMOVE_TOAST(state, toastId) {
    const index = state.toasts.findIndex(n => n.id === toastId);
    Vue.delete(state.toasts, index);
  },
  SET_MAX_TOAST_ID(state, maxToastId) {
    state.maxToastId = maxToastId;
  }
};

const actions = {
  createToast({ state, commit }, payload) {
    if (typeof payload === 'string')
      payload = { message: payload, isError: false };
    const newToast = {
      id: state.maxToastId + 1,
      message: payload.message,
      isError: payload.isError
    };
    commit('ADD_TOAST', newToast);
    commit('SET_MAX_TOAST_ID', newToast.id);
    setTimeout(() => commit('REMOVE_TOAST', newToast.id), 5000);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
