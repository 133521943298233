import { constructEnum } from '@/lib/enum';

const TargetPositiveReviewKeywordDisplayType = constructEnum(
  'TargetPositiveReviewKeywordDisplayType',
  {
    NONE: 0,
    WITH_SCORE: 10,
    KEYWORD_ONLY: 20
  }
);

export default TargetPositiveReviewKeywordDisplayType;
