<template>
  <AppAdvancedSearch
    :resource-params="resourceParams"
    :visible="visible"
    :sections="advancedSearchSections"
    v-on="$listeners"
  >
    <template #group="{ id, inputId }">
      <div v-if="id === 'sub_brand_ids'" class="AppForm__group-field">
        <AppSelectSearchable
          :title="$t('sub_brand_ids.select')"
          :placeholder="$t('sub_brand_ids.placeholder')"
          :items="subBrands"
          :columns="[{ id: 'name', label: $t('sub_brand_ids.name') }]"
          :search-types="[{ value: 'name', label: $t('sub_brand_ids.name') }]"
          multiple
          @search="searchSubBrands"
          @change="
            $emit('change', {
              ...resourceParams,
              sub_brand_ids: $event.join(',')
            })
          "
        />
      </div>
      <template v-else-if="id === 'review_keywords'">
        <div class="AppForm__group-field">
          <AppButton
            :label="$t('review_keywords.add_button')"
            @click="addReviewKeyword"
          />
          <AppTable
            v-if="reviewKeywordRows.length"
            table-id="review-reviews-advanced-search-review-tags-table"
            :rows="reviewKeywordRows"
            :columns="reviewKeywordColumns"
            no-head
          >
            <template #cell="{ row, column, rowIndex }">
              <template v-if="column.id == 'nlp_category_type_id'">
                <AppSelect
                  v-model="row.nlp_category_type_id"
                  :options="
                    nlpCategoryTypes.map(t => ({ label: t.name, value: t.id }))
                  "
                  @change="changeReviewKeywordTypeId(rowIndex, null)"
                />
              </template>
              <template v-else-if="column.id == 'review_tag_type_id'">
                <AppSelectFilterable
                  v-model="row.review_tag_type_id"
                  :options="
                    reviewKeywordTypeOptions[row.nlp_category_type_id] || []
                  "
                  :placeholder="
                    $t('review_keywords.placeholder.review_tag_type_id')
                  "
                  @change="changeReviewKeywordTypeId(rowIndex, $event)"
                />
              </template>
              <template v-else-if="column.id == 'sentiments'">
                <AppMultipleSelect
                  v-model="row.sentiments"
                  :options="ReviewSentimentType.options()"
                  :placeholder="$t('review_keywords.placeholder.sentiments')"
                  @change="changeReviewKeywordSentiments(rowIndex, $event)"
                />
              </template>
              <template v-else-if="column.id == 'actions'">
                <AppButton
                  :label="$t('app.delete')"
                  button-style="red-outline"
                  @click="deleteReviewKeyword(rowIndex)"
                />
              </template>
            </template>
          </AppTable>
        </div>
      </template>
      <template v-else-if="id === 'keyword_group_id'">
        <div class="AppForm__group-field">
          <AppButton
            :id="inputId"
            :label="$t('keyword_group_button')"
            button-style="mint-green"
            @click="openKeywordGroupsDialog"
          />
        </div>
        <div class="AppForm__group-field">
          <AppHashSelectButton
            v-if="keywordGroups.length"
            :hash="resourceParams"
            :select-buttons="[
              {
                id: 'keyword_group_id',
                options: keywordGroups.map(i => ({
                  label: i.name,
                  value: i.id
                }))
              }
            ]"
            @change="$emit('change', $event)"
          />
        </div>
      </template>
    </template>
  </AppAdvancedSearch>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import api from '@/lib/api';
import PurchasePurpose from '@/enums/PurchasePurpose';
import OrderStatus from '@/enums/OrderStatus';
import ReviewSentimentType from '@/enums/ReviewSentimentType';
import ReviewSource from '@/enums/ReviewSource';

export default {
  name: 'ReviewReviewsAdvancedSearch',
  model: {
    prop: 'resourceParams',
    event: 'change'
  },
  props: {
    resourceParams: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return { reviewKeywords: [] };
  },
  computed: {
    ...mapState('session', ['brandSettings', 'reviewSettings']),
    ...mapState('review', [
      'reviewTagTypes',
      'tags',
      'subBrands',
      'userGrades',
      'keywordGroups',
      'comboProduct',
      'nlpCategoryTypes'
    ]),
    ...mapState('thirdParties', ['thirdParties']),
    ...mapGetters('session', [
      'isShopifyBrand',
      'isUsingNlp',
      'isNthStepReviewCampaignEnabled'
    ]),
    ReviewSentimentType() {
      return ReviewSentimentType;
    },
    reviewKeywordColumns() {
      return [
        'nlp_category_type_id',
        'review_tag_type_id',
        'sentiments',
        'actions'
      ].map(id => ({
        id
      }));
    },
    reviewKeywordRows() {
      return this.reviewKeywords;
    },
    reviewKeywordTypeOptions() {
      const selectedReviewKeywordTypeIdSet = new Set(
        _.map(this.reviewKeywordRows, 'review_tag_type_id')
      );
      return _.chain(this.reviewTagTypes)
        .map(type => ({
          label: type.name,
          value: type.id,
          nlp_category_type_id: type.nlp_category_type_id,
          disabled: selectedReviewKeywordTypeIdSet.has(type.id)
        }))
        .groupBy('nlp_category_type_id')
        .value();
    },
    sourcesOptions() {
      return ReviewSource.options([
        ReviewSource.BRAND_MOBILE,
        ReviewSource.BRAND_PC,
        ReviewSource.SMS,
        ReviewSource.EMAIL,
        ReviewSource.IMPORTED,
        ReviewSource.SYNCHRONIZED,
        ReviewSource.BULK_IMPORTED,
        ReviewSource.MANAGER,
        ReviewSource.UNPURCHASED,
        ReviewSource.ETC
      ]);
    },
    thirdPartyOptions() {
      return this.thirdParties.map(({ id, name }) => ({
        value: id,
        label: name
      }));
    },
    advancedSearchSections() {
      const cremaAIBadge = {
        label: this.$t('app.crema_ai'),
        badgeStyle: 'red',
        badgeSize: 'small'
      };

      let selectButtons = [
        { id: 'mileage', values: [1, 0] },
        {
          id: 'auto_mileage_zero',
          options: [2, 0, 1].map(value => ({
            label: this.$t(`auto_mileage_zero.${value}`, [
              this.formatCurrency(0)
            ]),
            value
          }))
        },
        { id: 'admin_mileage_set', values: [1] },
        { id: 'duplicate', values: [1] },
        { id: 'first', values: [1] },
        { id: 'unchecked', values: [1, 0] },
        { id: 'photo', values: [1, 0, -1, 2] },
        { id: 'first_photo', values: [1] },
        { id: 'is_copied', values: [1, 0] },
        { id: 'refunded', values: [1, 0] },
        { id: 'visible', values: [1, 0, -1] },
        this.isNthStepReviewCampaignEnabled
          ? { id: 'nth_step', values: [1] }
          : { id: 'after_use', values: [1] },
        { id: 'pinned_location', values: [1] },
        { id: 'reorder', values: [1] },
        this.reviewSettings.use_purchase_purpose_badge
          ? {
              id: 'purchase_purpose',
              values: PurchasePurpose.SEARCHABLE_PURPOSES
            }
          : null,
        { id: 'member', values: [1, 0] },
        { id: 'picked_up', values: [1] },
        { id: 'social_media', values: [1, 0] },
        { id: 'score', values: [5, 4, 3, 2, 1, 0] }
      ].filter(b => b);

      if (this.isShopifyBrand)
        selectButtons = selectButtons.filter(g => g.id !== 'picked_up');

      return [
        {
          id: 'filter',
          groups: [
            {
              id: 'review_type',
              label: this.$t('review_type'),
              type: 'hash_select_button',
              selectButtons: selectButtons.map(({ id, options, values }) => ({
                id,
                options:
                  options ||
                  values.map(value => ({
                    label: this.$t(`${id}.${value}`),
                    value
                  }))
              }))
            },
            {
              id: 'sources',
              label: this.$t('sources'),
              type: 'multiple_select',
              options: this.sourcesOptions,
              packingMethod: 'string'
            },
            this.isShopifyBrand
              ? null
              : {
                  id: 'third_party_ids',
                  label: this.$t('third_party_ids'),
                  type: 'multiple_select',
                  options: this.thirdPartyOptions,
                  packingMethod: 'string'
                },
            {
              id: 'order_status',
              label: this.$t('order_status'),
              type: 'multiple_select',
              options: OrderStatus.options(),
              packingMethod: 'string'
            },
            this.tags.length
              ? {
                  id: 'tag_ids',
                  label: this.$t('tag_ids'),
                  type: 'multiple_select',
                  options: this.tags.map(t => ({ label: t.name, value: t.id })),
                  packingMethod: 'string'
                }
              : null,
            this.brandSettings.show_sub_brand
              ? {
                  id: 'sub_brand_ids',
                  label: this.$t('sub_brand_ids.title')
                }
              : null,
            this.isShopifyBrand
              ? null
              : {
                  id: 'admin_category_id',
                  label: this.$t('admin_category_id'),
                  type: 'select_product_category',
                  placeholder: this.$t('app.all')
                },
            this.userGrades.length
              ? {
                  id: 'user_grade_id',
                  label: this.$t('user_grade_id'),
                  type: 'select',
                  placeholder: this.$t('app.all'),
                  options: this.userGrades.map(i => ({
                    label: i.name,
                    value: i.id
                  }))
                }
              : null,
            {
              id: 'review_comments',
              label: this.$t('review_comments'),
              type: 'hash_select_button',
              selectButtons: [
                { id: 'admin_commented', values: [1, 0] },
                { id: 'user_commented', values: [1, 0] }
              ].map(({ id, options, values }) => ({
                id,
                options:
                  options ||
                  values.map(value => ({
                    label: this.$t(`${id}.${value}`),
                    value,
                    tooltip: this.$t(`${id}.tooltip`)
                  }))
              }))
            },
            this.reviewSettings.use_nlp_sentiment || this.isUsingNlp
              ? {
                  id: 'analysis_status',
                  label: this.$t('analysis_status'),
                  titleBadge: cremaAIBadge,
                  type: 'hash_select_button',
                  selectButtons: [
                    ...(this.reviewSettings.use_nlp_sentiment
                      ? [
                          {
                            id: 'message_sentiment_analysis_status',
                            options: [1, 0].map(value => ({
                              label: this.$t(
                                `message_sentiment_analysis_status.${value}`
                              ),
                              value
                            }))
                          }
                        ]
                      : []),
                    ...(this.isUsingNlp
                      ? [
                          {
                            id: 'review_keyword_analysis_status',
                            options: [1, 0].map(value => ({
                              label: this.$t(
                                `review_keyword_analysis_status.${value}`
                              ),
                              value
                            }))
                          }
                        ]
                      : [])
                  ]
                }
              : null,
            this.reviewSettings.use_nlp_sentiment
              ? {
                  id: 'review_message_sentiment',
                  label: this.$t('review_message_sentiment.title'),
                  placeholder: this.$t('review_message_sentiment.placeholder'),
                  titleBadge: cremaAIBadge,
                  type: 'multiple_select',
                  options: ReviewSentimentType.options([
                    ReviewSentimentType.POSITIVE,
                    ReviewSentimentType.NEUTRAL,
                    ReviewSentimentType.NEGATIVE
                  ]),
                  packingMethod: 'string'
                }
              : null,
            this.isUsingNlp
              ? {
                  id: 'review_keywords',
                  label: this.$t('review_keywords.title'),
                  titleBadge: cremaAIBadge
                }
              : null,
            {
              id: 'keyword_group_id',
              label: this.$t('keyword_group_id'),
              options: this.keywordGroups.map(i => ({
                label: i.name,
                value: i.id
              }))
            }
          ].filter(g => g)
        },
        {
          id: 'sort',
          groups: [
            {
              id: 'combo_product_id',
              label: this.$t('combo_product_id'),
              type: 'select_product',
              products: this.comboProduct ? [this.comboProduct] : [],
              value: this.comboProduct ? this.comboProduct.id : null,
              placeholder: this.$t('combo_product_id_placeholder'),
              defaultFilters: ['combo_product:1'],
              eventHandlers: {
                'change-items': products =>
                  this.SET_COMBO_PRODUCT(products.length ? products[0] : null)
              }
            }
          ]
        }
      ];
    }
  },
  watch: {
    keywordGroups(newKeywordGroups) {
      if (
        newKeywordGroups.every(
          g => g.id !== this.resourceParams.keyword_group_id
        )
      ) {
        this.$emit('change', _.omit(this.resourceParams, 'keyword_group_id'));
      }
    }
  },
  mounted() {
    const { review_tags } = this.resourceParams;
    const reviewKeywords = review_tags ? review_tags.split('&') : [];
    const reviewTagTypesMap = _.keyBy(this.reviewTagTypes, 'id');
    this.reviewKeywords = reviewKeywords
      .map(t => {
        const tag = t.split('=');
        if (2 !== tag.length || _.isNaN(parseInt(tag[0]))) return;
        const review_tag_type_id = parseInt(tag[0]);
        const { nlp_category_type_id } = reviewTagTypesMap[review_tag_type_id];
        return {
          id: review_tag_type_id,
          review_tag_type_id,
          nlp_category_type_id,
          sentiments: tag[1].split(',')
        };
      })
      .filter(t => t);
    this.$emit('review-tags-length', this.reviewKeywordRows.length);
  },
  methods: {
    ...mapMutations('review', ['SET_COMBO_PRODUCT']),
    ...mapActions('dialog', ['openDialog']),
    openKeywordGroupsDialog() {
      this.openDialog('ReviewKeywordGroupsDialog');
    },
    searchSubBrands({ params, onSuccess, onFailure }) {
      api
        .get('/sub_brands', { params })
        .then(({ data }) => onSuccess(data.sub_brands))
        .catch(onFailure);
    },
    addReviewKeyword() {
      this.reviewKeywordRows.push({
        id: _.uniqueId('review_tag_type_id'),
        nlp_category_type_id: this.nlpCategoryTypes.length
          ? this.nlpCategoryTypes[0].id
          : null
      });
      this.$emit('review-tags-length', this.reviewKeywordRows.length);
    },
    changeReviewKeywords() {
      const review_tags = this.reviewKeywordRows
        .map(({ review_tag_type_id, sentiments }) =>
          review_tag_type_id && sentiments && !_.isEmpty(sentiments)
            ? `${review_tag_type_id}=${sentiments.join(',')}`
            : null
        )
        .filter(t => t)
        .join('&');
      if (review_tags !== this.resourceParams.review_tags) {
        this.$emit('change', { ...this.resourceParams, review_tags });
      }
    },
    changeReviewKeywordSentimentsOnReviewKeywordTypeIdChanaged(
      rowIndex,
      reviewKeywordTypeId
    ) {
      if (reviewKeywordTypeId !== null) {
        this.reviewKeywordRows[rowIndex].sentiments =
          ReviewSentimentType.values;
      } else {
        this.reviewKeywordRows[rowIndex].sentiments = [];
      }
    },
    changeReviewKeywordTypeId(rowIndex, value) {
      this.$set(this.reviewKeywordRows[rowIndex], 'review_tag_type_id', value);
      this.changeReviewKeywordSentimentsOnReviewKeywordTypeIdChanaged(
        rowIndex,
        value
      );
      this.changeReviewKeywords();
    },
    changeReviewKeywordSentiments(rowIndex, value) {
      this.$set(this.reviewKeywordRows[rowIndex], 'sentiments', value);
      this.changeReviewKeywords();
    },
    deleteReviewKeyword(rowIndex) {
      this.reviewKeywordRows.splice(rowIndex, 1);
      this.changeReviewKeywords();
      this.$emit('review-tags-length', this.reviewKeywordRows.length);
    }
  }
};
</script>

<style lang="scss" scoped>
.review-reviews-advanced-search-review-tags-table {
  &.AppTable--no-head {
    border-top: none;
    margin-top: 4px;
  }
}

::v-deep {
  .review-reviews-advanced-search-review-tags-table {
    tbody tr {
      border-bottom: none;

      &:hover {
        background-color: white;
      }
    }
  }

  .review-reviews-advanced-search-review-tags-table__nlp-category-type-id {
    width: 73px;
    padding: 8px 4px 8px 0;
  }

  .review-reviews-advanced-search-review-tags-table__review-tag-type-id {
    width: 170px;
    padding: 8px 0;
  }

  .review-reviews-advanced-search-review-tags-table__sentiments {
    min-width: 130px;
    padding: 8px 4px;
  }

  .review-reviews-advanced-search-review-tags-table__actions {
    width: 58px;
    padding: 8px 0;
  }
}
</style>

<i18n locale="ko">
{
  "review_type": "리뷰 종류",
  "sources": "작성 경로",
  "third_party_ids": "외부 연동 출처",
  "order_status": "주문 상태",
  "tag_ids": "태그",
  "sub_brand_ids": {
    "title": "서브 브랜드",
    "placeholder": "서브 브랜드를 선택해주세요.",
    "select": "서브 브랜드 선택",
    "name": "서브 브랜드명"
  },
  "admin_category_id": "카테고리",
  "user_grade_id": "회원등급",
  "review_comments": "댓글 여부",
  "analysis_status": "리뷰 분석 상태",
  "review_message_sentiment": {
    "title": "리뷰 본문 긍정/부정 분석",
    "placeholder": "긍정/중립/부정 분석 결과를 선택해주세요."
  },
  "review_keywords": {
    "title": "주요 키워드별 분석",
    "add_button": "검색 항목 추가",
    "placeholder": {
      "review_tag_type_id": "키워드를 선택해주세요.",
      "sentiments": "긍정/중립/부정 분석 결과를 선택해주세요."
    }
  },
  "keyword_group_id": "특정검색어 리뷰그룹",
  "combo_product_id": "특정 상품의 세트 상품 리뷰 보기",
  "combo_product_id_placeholder": " 상품을 선택해주세요. 세트 상품이 있는 상품만 선택 가능합니다.",
  "keyword_group_button": "특정검색어 그룹설정",
  "mileage": {
    "1": "적립금 지급",
    "0": "적립금 미지급"
  },
  "auto_mileage_zero": {
    "2": "수정적립금 {0} 제외",
    "0": "예상적립금 {0} 제외",
    "1": "예상적립금 {0}"
  },
  "admin_mileage_set": {
    "1": "적립금 수정"
  },
  "duplicate": {
    "1": "중복"
  },
  "first": {
    "1": "최초 작성"
  },
  "unchecked": {
    "1": "미확인",
    "0": "확인"
  },
  "photo": {
    "1": "포토 리뷰",
    "0": "텍스트 리뷰",
    "-1": "동영상 리뷰",
    "2": "포토 또는 동영상 리뷰"
  },
  "first_photo": {
    "1": "1번째 포토"
  },
  "is_copied": {
    "1": "복사된 리뷰",
    "0": "복사된 리뷰 제외"
  },
  "refunded": {
    "1": "취소/환불",
    "0": "취소/환불 제외"
  },
  "visible": {
    "1": "진열",
    "0": "숨김",
    "-1": "상품정보없음"
  },
  "pinned_location": {
    "1": "추천 리뷰"
  },
  "after_use": {
    "1": "한달 사용 리뷰"
  },
  "nth_step": {
    "1": "n차 리뷰"
  },
  "reorder": {
    "1": "재구매"
  },
  "purchase_purpose": {
    "10": "선물한 리뷰",
    "20": "체험단 리뷰"
  },
  "admin_commented": {
    "0": "관리자 댓글 미작성",
    "1": "관리자 댓글 작성",
    "tooltip": "관리자 댓글은 크리마 리뷰 사용 시작 후 관리자 페이지에서 작성된 댓글입니다, 따라서 크리마 서비스 사용 전 리뷰에 작성한 댓글은 검색에서 제외됩니다."
  },
  "user_commented": {
    "0": "고객 댓글 미작성",
    "1": "고객 댓글 작성"
  },
  "member": {
    "1": "회원 작성",
    "0": "비회원 작성"
  },
  "picked_up": {
    "1": "매장구매"
  },
  "social_media": {
    "1": "소셜 리뷰",
    "0": "소셜리뷰 표시하지 않기"
  },
  "score": {
    "5": "5점",
    "4": "4점",
    "3": "3점",
    "2": "2점",
    "1": "1점",
    "0": "별점없음"
  },
  "message_sentiment_analysis_status": {
    "1": "본문 긍정/부정 분석 완료",
    "0": "분석중"
  },
  "review_keyword_analysis_status": {
    "1": "주요 키워드별 분석 완료",
    "0": "분석중"
  }
}
</i18n>
<i18n locale="en">
{
  "review_type": "Review type(s)",
  "sources": "Submission channel source",
  "order_status": "Order status",
  "tag_ids": "Tag(s)",
  "sub_brand_ids": {
    "title": "Sub Brand",
    "placeholder": "Choose Sub Brand",
    "select": "Select Sub Brand",
    "name": "Sub Brand Name"
  },
  "admin_category_id": "Category",
  "user_grade_id": "Membership level",
  "review_comments": "Commented reviews",
  "analysis_status": "Analysis Status",
  "review_message_sentiment": {
    "title": "Review Sentiment Analysis",
    "placeholder": "Choose sentiment analysis result."
  },
  "review_keywords": {
    "title": "Keyword Analysis",
    "add_button": "Add Search Item",
    "placeholder": {
      "review_tag_type_id": "Choose Keyword",
      "sentiments": "Choose keyword analysis result."
    }
  },
  "keyword_group_id": "Keyword review group",
  "combo_product_id": "View group product reviews",
  "combo_product_id_placeholder": "Select a product. You can only select grouped product",
  "keyword_group_button": "Keyword group setting",
  "duplicate": {
    "1": "Duplicated"
  },
  "first": {
    "1": "Best review"
  },
  "unchecked": {
    "1": "Uncheked",
    "0": "Checked"
  },
  "photo": {
    "1": "Photo review",
    "0": "Text review",
    "-1": "Video review",
    "2": "Photo or video review"
  },
  "first_photo": {
    "1": "First photo review"
  },
  "refunded": {
    "1": "Cancelled/refunded",
    "0": "Exclude cancelled/refunded"
  },
  "visible": {
    "1": "Published",
    "0": "Hidden",
    "-1": "No product info"
  },
  "pinned_location": {
    "1": "Top pinned"
  },
  "after_use": {
    "1": "One month use"
  },
  "nth_step": {
    "1": "Nth step"
  },
  "reorder": {
    "1": "Repurchase"
  },
  "purchase_purpose": {
    "10": "Gift review",
    "20": "Tester review"
  },
  "admin_commented": {
    "0": "Not commented by admin",
    "1": "Commented by admin",
    "tooltip": "‘Admin comment’ means the comment written in the admin page after starting to use CREMA service. Therefore, comments which were created before using CREMA service will be excluded from the search results."
  },
  "user_commented": {
    "0": "Not commented by customers",
    "1": "Commented by customers"
  },
  "member": {
    "1": "Member",
    "0": "Nonmember"
  },
  "picked_up": {
    "1": "Offline purchase"
  },
  "social_media": {
    "1": "Social media review",
    "0": "Hidden social media review"
  },
  "score": {
    "5": "5",
    "4": "4",
    "3": "3",
    "2": "2",
    "1": "1",
    "0": "No rating"
  },
  "message_sentiment_analysis_status": {
    "1": "Completed Sentiment Analysis",
    "0": "Analyzing"
  },
  "review_keyword_analysis_status": {
    "1": "Completed Keyword Analysis",
    "0": "Analyzing"
  }
}
</i18n>
