<template>
  <AppBadge v-bind="{ label, badgeStyle, tooltip }" />
</template>

<script>
import DisplayProductDisplayStatus from '@/enums/DisplayProductDisplayStatus';
import DisplayProductDisplayScheduleType from '@/enums/DisplayProductDisplayScheduleType';

export default {
  name: 'DisplayProductDisplayStatusBadge',
  props: {
    productDisplayStatus: { type: Number, required: true },
    editedAt: { type: String, default: '' },
    cohort: { type: Object, required: false, default: null }
  },
  computed: {
    label() {
      return DisplayProductDisplayStatus.t(this.productDisplayStatus);
    },
    badgeStyle() {
      switch (this.productDisplayStatus) {
        case DisplayProductDisplayStatus.NONE:
          return 'grey-outline';
        case DisplayProductDisplayStatus.SCHEDULED:
          return 'mango-outline';
        case DisplayProductDisplayStatus.APPLYING:
        case DisplayProductDisplayStatus.DISPLAYING_BUT_APPLYING:
          return 'mint-green-outline';
        case DisplayProductDisplayStatus.DISPLAYING:
          return 'mint-green';
        case DisplayProductDisplayStatus.ERROR:
          return 'red';
        default:
          return 'default';
      }
    },
    tooltip() {
      if (this.productDisplayStatus === DisplayProductDisplayStatus.NONE)
        return this.$t('tooltip.edited_at', [
          this.formatDateTime(this.editedAt)
        ]);
      else if (
        DisplayProductDisplayStatus.isApplying(this.productDisplayStatus)
      )
        return {
          message: this.$t(
            `tooltip.${DisplayProductDisplayScheduleType.key(
              this.cohort.schedule_type
            )}`,
            [this.formatDateTimeWithoutSecond(this.cohort.display_at)]
          )
        };
      else return null;
    }
  }
};
</script>

<i18n locale="ko">
{
  "tooltip": {
    "edited_at": "최근 저장일: {0}",
    "NOW": "{0}에 시작된 즉시 진열이 진행 중입니다.",
    "ONCE": "{0}에 1회 예약된 진열이 진행 중입니다.",
    "AUTO": "{0}에 반복 예약된 진열이 진행 중입니다."
  }
}
</i18n>
