<template>
  <AppModalDefault title="문자(SMS) 미리보기" width="640px" @close="close">
    <template #body>
      <AppMobilePhoneSmsPreview
        dispatch-time="오전 10:00"
        message-html="(광고)크리마몰<br />신사임당님께만 추천드려요!<br /><br /><a>☞ cre.ma/LbBfk3</a><br /><br />무료거부 0801234567"
      />
    </template>
  </AppModalDefault>
</template>
<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'UiMobileSmsPreviewDialog',
  mixins: [DialogView]
};
</script>
