<template>
  <div class="AppTableCellTextList">
    <div>{{ displayList }}</div>
    <div v-if="!isDisplayEntireList">
      <AppBadge :label="$t('app.detail')" :tooltip="list.join(', ')" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

const DISPLAY_COUNT = 3;

export default {
  name: 'AppTableCellTextList',
  props: { list: { type: Array, required: true } },
  computed: {
    displayList() {
      if (this.isDisplayEntireList) return this.list.join(', ');
      else {
        const displayList = _.take(this.list, DISPLAY_COUNT - 1).join(', ');
        return this.$t('unit.omitted_count', [
          displayList,
          this.list.length - DISPLAY_COUNT + 1
        ]);
      }
    },
    isDisplayEntireList() {
      return this.list.length <= DISPLAY_COUNT;
    }
  }
};
</script>
