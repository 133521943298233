<template>
  <div class="AppSmallDeviceScrollable">
    <div ref="wrapper" class="AppSmallDeviceScrollable__content-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'AppSmallDeviceScrollable',
  mounted() {
    this.throttledLayout = _.throttle(this.layout, 200);

    this.$nextTick(this.throttledLayout);
    window.addEventListener('resize', this.throttledLayout);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.throttledLayout);
    this.throttledLayout = null;
  },
  methods: {
    layout() {
      const { wrapper } = this.$refs;

      wrapper.style.width = 'auto';
      wrapper.firstChild.style.minWidth = 0;

      wrapper.classList.add(
        'AppSmallDeviceScrollable__content-wrapper--measure-wrapper'
      );
      const wrapperWidth = wrapper.getBoundingClientRect().width;
      wrapper.classList.remove(
        'AppSmallDeviceScrollable__content-wrapper--measure-wrapper'
      );

      wrapper.classList.add(
        'AppSmallDeviceScrollable__content-wrapper--measure-child'
      );
      const childWidth = wrapper.firstChild.getBoundingClientRect().width;
      wrapper.classList.remove(
        'AppSmallDeviceScrollable__content-wrapper--measure-child'
      );

      wrapper.style.width = wrapperWidth + 'px';
      wrapper.firstChild.style.minWidth = childWidth + 'px';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_breakpoints.scss';

@include media-breakpoint-each(tablet, mobile) {
  .AppSmallDeviceScrollable {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 8px;
  }
}

.AppSmallDeviceScrollable__content-wrapper {
  &--measure-wrapper {
    > * {
      position: absolute;
    }
  }

  &--measure-child {
    width: 10000px !important;
    > * {
      width: auto !important;
    }
  }
}
</style>
