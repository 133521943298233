<template>
  <AppTable class="JpegSimulationVersionsTable" :columns="COLUMNS" :rows="rows">
    <template #cell="{ column, value }">
      <template v-if="column.id === 'uploader'">
        <div>
          <div>Uploader: {{ value.name }}</div>
          <div>Version: {{ value.version }}</div>
          <div>
            <pre>{{ value.options }}</pre>
          </div>
        </div>
      </template>
      <template v-else-if="column.id === 'input_image'">
        <div>
          <div>
            <AppImage
              :src="value.url"
              class="JpegSimulationVersionsTable__image"
            />
          </div>
          <div>{{ value.name }}</div>
          <div>Dimension: {{ value.width }}x{{ value.height }}</div>
        </div>
      </template>
      <template v-else-if="column.id === 'output_image'">
        <div>
          <div>
            <AppImage
              :src="value.url"
              class="JpegSimulationVersionsTable__image"
            />
          </div>
          <div>Dimension: {{ value.width }}x{{ value.height }}</div>
        </div>
      </template>
    </template>
  </AppTable>
</template>

<script>
import _ from 'lodash';
import ImageGravity from '@/enums/ImageGravity';

export default {
  name: 'JpegSimulationVersionsTable',
  props: { versions: { type: Array, required: true } },
  computed: {
    rows() {
      return _.sortBy(this.versions, v => [v.input_image_name, v.uploader]).map(
        version => {
          return {
            uploader: {
              name: version.uploader,
              version: version.version,
              options: _.map(version.options, (v, k) => {
                return `${k}: ${k === 'gravity' ? ImageGravity.key(v) : v}`;
              }).join('\n')
            },
            input_image: {
              url: version.input_image_url,
              name: version.input_image_name,
              width: version.input_image_width,
              height: version.input_image_height
            },
            output_image: {
              url: version.output_image_url,
              width: version.output_image_width,
              height: version.output_image_height
            },
            file_size: version.output_image_file_size,
            duration: version.duration_ms / 1000
          };
        }
      );
    }
  },
  created() {
    this.COLUMNS = [
      { id: 'uploader', label: 'Uploader', align: 'left' },
      { id: 'input_image', label: 'InputImage' },
      { id: 'output_image', label: 'OutputImage' },
      { id: 'file_size', label: 'FileSize (byte)', type: 'number' },
      { id: 'duration', label: 'Duration (sec)', type: 'number' }
    ];
  }
};
</script>

<style lang="scss" scoped>
.JpegSimulationVersionsTable__image {
  max-height: 200px;
}
</style>
