<template>
  <div class="AccordionStepContent">
    <AccordionStepTitle
      v-bind="{ currentStep, stepsCount, isCompleted }"
      :text="title"
    />
    <AppTipBox v-if="!isCompleted" :text="tip" />
    <slot />
    <AccordionButtons
      v-bind="{
        currentStep,
        stepsCount,
        isCompleted,
        disableNextButton,
        completeButtonLabel
      }"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import AccordionStepTitle from './AccordionStepTitle';
import AccordionButtons from './AccordionButtons';

export default {
  name: 'AccordionStepContent',
  components: { AccordionStepTitle, AccordionButtons },
  props: {
    currentStep: { type: Number, required: true },
    stepsCount: { type: Number, required: true },
    title: { type: String, required: true },
    tip: { type: String, required: true },
    isCompleted: { type: Boolean, default: false },
    disableNextButton: { type: Boolean, default: false },
    completeButtonLabel: {
      type: String,
      default: function() {
        return this.$t('app.confirmed');
      }
    }
  }
};
</script>
