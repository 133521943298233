<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="productOptionType.name"
    :form-props="formProps"
    :is-loading="isLoading"
    v-on="formEvents"
  >
    <template #searchbar>
      <AppSearchbar @submit="searchResource">
        <AppSearchInput v-model="query" />
      </AppSearchbar>
    </template>
    <template #group="{ id }">
      <template v-if="id === 'table'">
        <AppResourceTable
          :columns="columns"
          :rows="rows"
          :resources="resources"
          @paginate="paginate"
        >
          <template #cell="{ row, column }">
            <template v-if="column === 'select'">
              <AppRadio v-model="formObject.id" name="value" :value="row.id" />
            </template>
          </template>
        </AppResourceTable>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'DisplaySelectProductOptionValueDialog',
  mixins: [DialogFormView, ResourceViewNoRoute],
  props: {
    productOptionType: { type: Object, required: true },
    productOptionValue: { type: Object, required: true }
  },
  data: () => ({
    isLoading: true,
    resources: nullResources,
    query: ''
  }),
  computed: {
    columns() {
      return [
        {
          id: 'value',
          label: this.$t('column.value')
        },
        { id: 'select', label: this.$t('app.select'), width: '64px' }
      ];
    },
    rows() {
      return this.resources.items;
    },
    formSections() {
      return [{ groups: [{ id: 'table', label: '' }] }];
    }
  },
  mounted() {
    this.orgFormObject = this.productOptionValue;
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;

      api
        .get('/product/option_values', {
          params: {
            ...params,
            option_type_id: this.productOptionType.id,
            search_type: 'value',
            search_query: this.query
          }
        })
        .then(({ data }) => (this.resources = data.option_values))
        .finally(() => (this.isLoading = false));
    },
    submit() {
      this.eventBus.$emit(
        'update-product-option-value',
        this.resources.items.find(({ id }) => id === this.formObject.id)
      );
      this.close(true);
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "상품 옵션값 선택",
  "column": {
    "value": "옵션값"
  }
}
</i18n>
