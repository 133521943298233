<template>
  <AppContainer>
    <div class="AnalyticsInsightProductDialogAnalysisTables__title">
      <span>{{
        $t(`insight.products.analysis_aggregated_value.${analyticsType}`)
      }}</span>
      <AppSvgIcon
        v-tooltip="$t(`analysis_aggregated_value.${analyticsType}`)"
        class="AnalyticsInsightProductDialogAnalysisTables__info-icon"
        name="icon-info-tooltip"
        :width="10"
        :height="10"
      />
    </div>
    <div class="AnalyticsInsightProductDialogAnalysisTables__aggregated-value">
      {{ analysisAggregatedValueString }}
    </div>
    <div class="AnalyticsInsightProductDialogAnalysisTables__tables">
      <AppDataSectionItem :title="$t(`analysis_values.${analyticsType}`)">
        <AppGrid>
          <div
            class="AnalyticsInsightProductDialogAnalysisTables__table-col AppGrid__col AppGrid__col--6"
          >
            <AppTable
              class="AnalyticsInsightProductDialogAnalysisTables__table"
              :columns="COLUMNS"
              :rows="rowsFirstHalf"
              no-head
            />
          </div>
          <div
            class="AnalyticsInsightProductDialogAnalysisTables__table-col AppGrid__col AppGrid__col--6"
          >
            <AppTable
              class="AnalyticsInsightProductDialogAnalysisTables__table"
              :columns="COLUMNS"
              :rows="rowsSecondHalf"
              no-head
            />
          </div>
        </AppGrid>
      </AppDataSectionItem>
    </div>
  </AppContainer>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'AnalyticsInsightProductDialogAnalysisTables',
  props: {
    product: { type: Object, required: true },
    analyticsType: { type: String, required: true },
    reviewTagTypeIdsOrder: { type: Array, required: true }
  },
  data() {
    return {
      COLUMNS: [{ id: 'keyword' }, { id: 'value' }]
    };
  },
  computed: {
    ...mapGetters('session', ['brandReviewTagTypes']),
    precision() {
      return this.analyticsType === 'potential' ? 2 : 1;
    },
    analysisAggregatedValueString() {
      let value = this.product.analysis_aggregated_value?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: this.precision,
          maximumFractionDigits: this.precision
        }
      );
      if (!value) return '-';

      return this.analyticsType === 'risky'
        ? this.$t('unit.percentage', [value])
        : this.$t('unit.points', [value]);
    },
    rows() {
      const reviewTagTypes = _.sortBy(this.brandReviewTagTypes, type =>
        _.indexOf(this.reviewTagTypeIdsOrder, type.id)
      );

      return reviewTagTypes.map(type => ({
        keyword: type.name,
        value:
          this.product[`analysis_value_${type.id}`]?.toLocaleString(undefined, {
            minimumFractionDigits: this.precision,
            maximumFractionDigits: this.precision
          }) || '-',
        value_class: this.product[`analysis_value_${type.id}_class`]
      }));
    },
    rowsFirstHalf() {
      return this.rows.slice(0, Math.ceil(this.rows.length / 2));
    },
    rowsSecondHalf() {
      return this.rows.slice(Math.ceil(this.rows.length / 2), this.rows.length);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightProductDialogAnalysisTables__info-icon {
  fill: $color-grey-50;
  margin-left: 4px;
}

.AnalyticsInsightProductDialogAnalysisTables__title {
  @include text-label;
}

.AnalyticsInsightProductDialogAnalysisTables__aggregated-value {
  @include text-caption-title;
  margin-top: 24px;
}

.AnalyticsInsightProductDialogAnalysisTables__tables {
  margin-top: 16px;
}

$table-body-col-width: 76px;
$table-col-side-padding: 8px;
.AnalyticsInsightProductDialogAnalysisTables__table-col {
  padding: 0 $table-col-side-padding;
  width: $table-body-col-width * 2 + ($table-col-side-padding * 2);
}

@media (min-width: 406px) and (max-width: 720px) {
  .AnalyticsInsightProductDialogAnalysisTables__table-col {
    max-width: 50%;
    margin-top: 0;
  }
}

@media (max-width: 405px) {
  .AnalyticsInsightProductDialogAnalysisTables__table-col {
    margin-top: 0;
  }
}

.AnalyticsInsightProductDialogAnalysisTables__table {
  margin-top: 8px;
}

::v-deep {
  .AppTable__body-row {
    height: 50px;
  }

  .AppTable__body-col {
    min-width: $table-body-col-width;
  }

  .AppDataSectionItem__title {
    margin-bottom: 0;
  }
}
</style>

<i18n locale="ko">
{
  "analysis_values": {
    "popular": "키워드별 만족도",
    "risky": "키워드별 부정 인지율",
    "potential": "키워드별 평균 만족도"
  },
  "analysis_aggregated_value": {
    "popular": "실제 고객이 남긴 리뷰에서 언급한 키워드의 긍정/부정 분석을 통해 평가한 수치로\n종합적으로 평가가 좋은 상품에 참고할 수 있는 지표입니다.",
    "risky": "실제 고객이 남긴 리뷰에서 부정적으로 언급한 키워드의 비율로\n상품의 부정적 평가 정도에 참고할 수 있는 지표입니다.",
    "potential": "리뷰당 평균 만족도로 리뷰수가 적어도 좋은 평가를 받아온\n잠재력있는 상품을 찾을 때 참고할 수 있는 지표입니다."
  }
}
</i18n>
