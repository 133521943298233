<template>
  <AppModalForm
    width="middle"
    :title="brandNotice.id ? $t('title_edit_record') : $t('title_new_record')"
    :sub-title="orgBrandNotice.title"
    :form-props="{
      ...formProps,
      id: 'review_settings_notice',
      objectId: 'brand_notice',
      focusGroupId: 'title'
    }"
    v-on="formEvents"
  >
    <input type="hidden" :value="brandNotice.id" name="brand_notice[id]" />
    <template #group="{ id }">
      <AppSelectRadio
        v-if="id === 'pc'"
        v-model="brandNotice.expanded"
        :class="[
          'AppForm__group-field',
          { 'AppForm__group-field--disabled': !brandNotice.pc }
        ]"
        :disabled="!brandNotice.pc"
        name="brand_notice[expanded]"
        :options="OPTIONS"
      />
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewSettingsNoticeFormDialog',
  mixins: [DialogFormView],
  props: {
    orgBrandNotice: {
      type: Object,
      default() {
        return {
          title: null,
          message: null,
          pc: true,
          expanded: false,
          mobile: false
        };
      }
    }
  },
  data() {
    return {
      OPTIONS: [
        { label: this.$t('expanded_false'), value: false },
        { label: this.$t('expanded_true'), value: true }
      ],
      orgFormObject: this.orgBrandNotice
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'title',
              type: 'text',
              placeholder: this.$t('title_placeholder'),
              validate: ['required']
            },
            { id: 'pc', type: 'checkbox' },
            { id: 'mobile', type: 'checkbox' },
            { id: 'message', type: 'text_editor', validate: ['required'] }
          ]
        }
      ];
    },
    brandNotice() {
      return this.formObject;
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/brand_notices/save', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.eventBus.$emit('save', data.brand_notice);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_new_record": "공지사항 등록하기",
  "title_edit_record": "공지사항 수정하기",
  "title_placeholder": "공지사항 제목을 입력해주세요.",
  "expanded_false": "접어서 보여주기",
  "expanded_true": "펼쳐서 보여주기"
}
</i18n>
