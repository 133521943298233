<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="campaign.name"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId: 'campaign',
      disabled: isArchivedCampaign
    }"
    v-on="formEvents"
  >
    <template #group="{ id, disabled, inputName, errors }">
      <template v-if="id === 'dispatch_limit_type'">
        <div class="AppForm__group-field">
          <AppSelectRadio
            v-model="formObject.dispatch_limit_type"
            :disabled="disabled"
            :name="inputName"
            :options="dispatchLimitTypeOptions"
          >
            <template #label="{ value, checked }">
              <template v-if="value !== TargetDispatchLimitType.NONE">
                <i18n
                  :path="
                    `TargetDispatchLimitType.${TargetDispatchLimitType.toKey(
                      value
                    )}`
                  "
                >
                  <AppNumberInput
                    v-model="formObject[dispatchLimitAmountKey(value)]"
                    name="campaign[dispatch_limit_amount]"
                    inline
                    :default="0"
                    :disabled="disabled || !checked"
                    :invalid="!!errors[dispatchLimitAmountKey(value)]"
                    :digits="6"
                    @blur="validateField(dispatchLimitAmountKey(value))"
                    @change="validateField(dispatchLimitAmountKey(value))"
                  />
                </i18n>
                <AppFormError :error="errors[dispatchLimitAmountKey(value)]" />
              </template>
            </template>
          </AppSelectRadio>
          <input
            v-if="
              formObject.dispatch_limit_type === TargetDispatchLimitType.NONE
            "
            type="hidden"
            name="formObject[dispatch_limit_amount]"
            :value="0"
          />
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import TargetDispatchLimitType from '@/enums/TargetDispatchLimitType';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'TargetDmCampaignSettingsMiscDispatchLimitDialog',
  mixins: [DialogFormView],
  props: {
    campaign: { type: Object, required: true }
  },
  data() {
    return { isLoading: true };
  },
  computed: {
    ...mapGetters('targetCampaign', ['isArchivedCampaign']),
    TargetDispatchLimitType: () => TargetDispatchLimitType,
    dispatchLimitTypeOptions() {
      const dispatchLimitTypes = TargetDispatchLimitType.fromCampaignType(
        this.campaign.campaign_type
      );

      return TargetDispatchLimitType.options(dispatchLimitTypes);
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'dispatch_limit_type',
              label: this.$t('dispatch_limit_type'),
              groupDescription: this.$t('dispatch_limit_type_description'),
              fields: [
                {
                  id: this.dispatchLimitAmountKey(
                    this.formObject.dispatch_limit_type
                  ),
                  validate: [{ rule: 'gt', allowed: 0 }]
                }
              ]
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaign.id}/dispatch_limit`)
      .then(({ data }) => {
        const { dispatch_limit_type, dispatch_limit_amount } = data.campaign;
        this.orgFormObject = {
          dispatch_limit_type,
          ...TargetDispatchLimitType.values.reduce((a, v) => {
            const key = this.dispatchLimitAmountKey(v);
            return key
              ? {
                  ...a,
                  [key]: dispatch_limit_type === v ? dispatch_limit_amount : 0
                }
              : a;
          }, {})
        };
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(`/target/campaigns/${this.campaign.id}/dispatch_limit`, formData)
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    dispatchLimitAmountKey(dispatch_limit_type) {
      switch (dispatch_limit_type) {
        case TargetDispatchLimitType.PER_COHORT:
          return 'dispatch_limit_amount_per_cohort';
        case TargetDispatchLimitType.DAILY:
          return 'dispatch_limit_amount_daily';
        case TargetDispatchLimitType.MONTHLY:
          return 'dispatch_limit_amount_monthly';
        default:
          return null;
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "발송 제한 설정",
  "dispatch_limit_type": "발송 비용 기준 발송 제한",
  "dispatch_limit_type_description": "계산된 발송 대상 전체에 대한 비용을 기준으로 제한합니다.",
  "dispatch_limit_message": {
    "NONE": "잔여 크레딧만큼 발송 후 중지",
    "PER_COHORT": "1회 발송당 최대 {0}크레딧 발송",
    "DAILY": "하루 최대 {0}크레딧 발송",
    "MONTHLY": "한달 동안 최대 {0}크레딧 발송"
  }
}
</i18n>
