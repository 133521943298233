<template>
  <Analytics :type="type" />
</template>

<script>
import Analytics from '../Analytics';
import ReviewOptionType from '@/enums/ReviewOptionType';

export default {
  name: 'AnalyticsProducts',
  components: { Analytics },
  data() {
    return {
      type: ReviewOptionType.EVALUATION
    };
  }
};
</script>
