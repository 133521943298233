<template>
  <div class="AnalyticsInsightDashboardKeywords">
    <div class="AnalyticsInsightDashboardKeywords__title">
      {{ $t('keywords_analysis') }}
    </div>
    <AppButton
      class="AnalyticsInsightDashboardKeywords__button"
      type="link"
      :to="pathWithDateRangeParams('insight_keywords')"
      :label="$t('view_keywords_analysis')"
    />
    <AppGrid>
      <AnalyticsInsightDashboardKeywordsDetails
        v-bind="{
          dateRange,
          title: $t('insight.my_brand'),
          details: myBrandKeywordsDetails
        }"
      />
      <AnalyticsInsightDashboardKeywordsDetails
        v-bind="{
          dateRange,
          title: $t('insight.brands'),
          details: brandsKeywordsDetails
        }"
      />
    </AppGrid>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AnalyticsInsightDashboardKeywords',
  props: {
    dateRange: { type: Object, required: true },
    myBrandKeywordsDetails: { type: Array, required: true },
    brandsKeywordsDetails: { type: Array, required: true }
  },
  computed: {
    ...mapGetters(['pathWithDateRangeParams'])
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightDashboardKeywords {
  position: relative;
  margin-top: 42px;
}

.AnalyticsInsightDashboardKeywords__title {
  @include text-sub-title;
}

.AnalyticsInsightDashboardKeywords__button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>

<i18n locale="ko">
{
  "keywords_analysis": "키워드 분석",
  "view_keywords_analysis": "키워드 분석 보기"
}
</i18n>
