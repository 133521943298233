var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalDefault',{attrs:{"title":_vm.$t('dialog_title'),"is-maximized-by-default":""},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__containter"},[_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__title"},[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")]),_c('AppInfoBox',{staticClass:"ReviewDashboardWidgetTryoutDialog__info-box",domProps:{"innerHTML":_vm._s(_vm.$t('info_content'))}}),_vm._l((_vm.requests),function(rows,pageType){return [(rows.length > 0)?_c('div',{key:pageType,staticClass:"ReviewDashboardWidgetTryoutDialog__image-table"},[_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__image"},[_c('AppImage',{attrs:{"src":_vm.imageUrl[pageType],"alt":"resolutions","resolutions":[2, 3]}})],1),_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__table"},[_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__table-topbar"},[_c('div',[_c('span',{staticClass:"ReviewDashboardWidgetTryoutDialog__table-topbar__table-name"},[_vm._v(" "+_vm._s(_vm.WidgetPageType.t(pageType))+" ")])])]),_c('AppTable',{attrs:{"table-id":"widget-tryout-table","columns":_vm.REQUEST_COLUMNS,"rows":rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [(column.id === 'tryout_link')?[(row.deviceType === _vm.WidgetDeviceType.PC)?[_c('AppButton',{attrs:{"to":row.tryoutLink,"type":"external_link"}},[_vm._v(" "+_vm._s(_vm.$t('app.pc'))+" "),_c('AppExternalLinkIcon',{staticClass:"AppDropdownMenu__menu-icon"})],1)]:[_c('AppButton',{attrs:{"type":"external_link"},on:{"click":function($event){return _vm.openMobileWindow(row.tryoutLink)}}},[_vm._v(" "+_vm._s(_vm.$t('app.mobile'))+" "),_c('AppExternalLinkIcon',{staticClass:"AppDropdownMenu__menu-icon"})],1)]]:_vm._e()]}}],null,true)})],1)]):_vm._e()]}),(_vm.countAndScoreWidgets.length !== 0)?_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__image-table"},[_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__image"},[_c('AppImage',{attrs:{"src":_vm.imageUrl['count_and_score']}})],1),_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__table"},[_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__table-topbar"},[_c('div',[_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__table-topbar__table-name"},[_vm._v(" "+_vm._s(_vm.$t('table.name.count_and_score'))+" ")])])]),_c('AppTable',{attrs:{"table-id":"widget-tryout-table","columns":_vm.COUNT_AND_SCORE_COLUMNS,"rows":_vm.countAndScoreWidgets},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [(column.id === 'tryout_link')?[_c('AppButton',{attrs:{"to":row.pcTryoutLink,"type":"external_link"}},[_vm._v(" "+_vm._s(_vm.$t('app.pc'))+" "),_c('AppExternalLinkIcon',{staticClass:"AppDropdownMenu__menu-icon"})],1),_c('AppButton',{attrs:{"type":"external_link"},on:{"click":function($event){return _vm.openMobileWindow(row.mobileTryoutLink)}}},[_vm._v(" "+_vm._s(_vm.$t('app.mobile'))+" "),_c('AppExternalLinkIcon',{staticClass:"AppDropdownMenu__menu-icon"})],1)]:_vm._e()]}}],null,false,2869144588)})],1)]):_vm._e(),_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__image-table"},[_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__image"},[_c('AppImage',{attrs:{"src":_vm.imageUrl['required'],"alt":"resolutions","resolutions":[2, 3]}})],1),_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__table"},[_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__table-topbar"},[_c('div',[_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__table-topbar__table-name"},[_vm._v(" "+_vm._s(_vm.$t('table.name.required_widgets'))+" ")])])]),_c('AppTipBox',{attrs:{"text":_vm.$t('required_widget_tip_box.text_html')}}),_c('AppTable',{attrs:{"table-id":"widget-tryout-table","columns":_vm.REQUIRED_WIDGETS_COLUMNS,"rows":_vm.requiredWidgets},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [(column.id === 'tryout_link')?[_c('AppButton',{attrs:{"to":row.pcTryoutLink,"type":"external_link"}},[_vm._v(" "+_vm._s(_vm.$t('app.pc'))+" "),_c('AppExternalLinkIcon',{staticClass:"AppDropdownMenu__menu-icon"})],1),_c('AppButton',{attrs:{"type":"external_link"},on:{"click":function($event){return _vm.openMobileWindow(row.mobileTryoutLink)}}},[_vm._v(" "+_vm._s(_vm.$t('app.mobile'))+" "),_c('AppExternalLinkIcon',{staticClass:"AppDropdownMenu__menu-icon"})],1)]:_vm._e()]}}])})],1)]),_c('AppSummaryBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('contact_summary_box.title')))]},proxy:true},{key:"buttons",fn:function(){return [_c('AppButton',{attrs:{"type":"external_link","label":_vm.$t('contact_summary_box.button_label')},on:{"click":_vm.openInquiry}})]},proxy:true}])},[_c('pre',[_vm._v(_vm._s(_vm.$t('contact_summary_box.content')))])])],2)])]},proxy:true},{key:"foot",fn:function(){return [_c('div',{staticClass:"ReviewDashboardWidgetTryoutDialog__button"},[_c('AppButton',{attrs:{"button-style":"blue","label":_vm.$t('close')},on:{"click":_vm.clickCompleteButton}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }