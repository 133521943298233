<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{
      ...formProps,
      isSubmitting: isLoading,
      submittingLabel: $t('submitting_label'),
      submitLabel: $t('submit_label'),
      closeLabel: $t('cancel_label'),
      detectFormDataChange: false,
      objectId: 'shopify_widget_installation'
    }"
    v-on="formEvents"
    @close="close"
  >
    <AppInfoBox class="ReviewShopifyWidgetInitialInstallationDialog__info-box">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="$t('info_box.initial_setup_head_html')" />
      <!-- eslint-enable vue/no-v-html -->
    </AppInfoBox>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import { mapActions, mapMutations } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import ShopifyWidgetInstallationType from '@/enums/ShopifyWidgetInstallationType';

export default {
  name: 'ReviewShopifyWidgetInitialInstallationDialog',
  mixins: [DialogFormView],
  props: {
    isReinstallation: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: false,
      orgFormObject: {
        required_widget_installation_types: [
          ShopifyWidgetInstallationType.PRODUCT_DETAIL_AND_MAIN_POPUP_WIDGET,
          ShopifyWidgetInstallationType.PRODUCT_DETAIL_LIST_WIDGET
        ]
      }
    };
  },
  computed: {
    ShopifyWidgetInstallationType: () => ShopifyWidgetInstallationType,
    formSections() {
      return [
        {
          id: 'section_widget_selection',
          groups: [
            {
              id: 'required_widget_installation_types',
              name: 'required_widget_installation_types',
              type: 'multiple_select_checkbox',
              options: [
                {
                  label: this.$t(
                    'required_widget_installation_types.main_and_product_detail_popup_widgets'
                  ),
                  value:
                    ShopifyWidgetInstallationType.PRODUCT_DETAIL_AND_MAIN_POPUP_WIDGET,
                  disabled: true
                },
                {
                  label: this.$t(
                    'required_widget_installation_types.product_detail_list_widget'
                  ),
                  value:
                    ShopifyWidgetInstallationType.PRODUCT_DETAIL_LIST_WIDGET,
                  disabled: true
                }
              ],
              label: this.$t('installation_sections.required')
            },
            {
              id: 'optional_widget_installation_types',
              name: 'optional_widget_installation_types',
              type: 'multiple_select_checkbox',
              options: [
                {
                  label: this.$t(
                    'optional_widget_installation_types.product_detail_score_widget'
                  ),
                  value:
                    ShopifyWidgetInstallationType.PRODUCT_DETAIL_SCORE_WIDGET
                },
                {
                  label: this.$t(
                    'optional_widget_installation_types.my_page_my_reviews_widget'
                  ),
                  value: ShopifyWidgetInstallationType.MY_PAGE_MY_REVIEWS_WIDGET
                }
              ],
              label: this.$t('installation_sections.optional')
            }
          ]
        }
      ];
    },
    installationType() {
      return this.isReinstallation ? 'reinstall' : 'install';
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    ...mapMutations('session', ['SET_EXECUTED_WIDGET_INITIAL_INSTALLATION']),
    submit(formData) {
      this.isLoading = true;
      api
        .post('/shopify/widget_installation', formData, {
          successMessage: this.$t(`${this.installationType}.success_message`),
          silent: true
        })
        .then(() => {
          this.SET_EXECUTED_WIDGET_INITIAL_INSTALLATION();
          this.close();
        })
        .catch(() => {
          this.createToast({
            message: this.$t(`${this.installationType}.error_message`),
            isError: true
          });
        })
        .finally(() => (this.isLoading = false));
    },
    close() {
      this.closeDialog();
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewShopifyWidgetInitialInstallationDialog__info-box {
  margin-bottom: 12px;
}
</style>

<i18n locale="ko">
{
  "title": "기본 위젯 설치",
  "submitting_label": "설치 중...",
  "submit_label": "위젯 설치",
  "cancel_label": "취소",
  "info_box": {
    "initial_setup_head_html": "쇼핑몰 사이트의 각 주요 페이지에 적합한 리뷰 위젯을 자동으로 설치할 수 있습니다.<br/>설치할 위젯 선택을 완료한 다음 위젯 설치 버튼을 클릭하면 설치를 시작합니다."
  },
  "installation_sections": {
    "required": "필수 설치 위젯",
    "optional": "선택 설치 위젯"
  },
  "required_widget_installation_types": {
    "main_and_product_detail_popup_widgets": "메인 페이지, 상품 상세 페이지 - 리뷰 작성 요청 팝업",
    "product_detail_list_widget": "상품 상세 페이지 - 리뷰 리스트 위젯"
  },
  "optional_widget_installation_types": {
    "product_detail_score_widget": "상품 상세 페이지 - 리뷰 수 및 평점 위젯",
    "my_page_my_reviews_widget": "마이 페이지 - 내 리뷰 위젯"
  },
  "install": {
    "success_message": "위젯 설치를 완료했습니다.",
    "error_message": "위젯 설치에 실패했습니다. 다시 시도해 주세요."
  },
  "reinstall": {
    "success_message": "위젯 재설치를 완료했습니다.",
    "error_message": "위젯 재설치에 실패했습니다. 다시 시도해 주세요."
  }
}
</i18n>
