import _ from 'lodash';

const state = { units: [] };

const getters = {
  cannotAddUnit: ({ units }) => {
    return units.length >= 7;
  }
};

const mutations = {
  SET_UNITS(state, units) {
    state.units = _.cloneDeep(units);
  },
  ADD_UNIT(state, unit) {
    state.units.push(unit);
  },
  UPDATE_UNIT(state, { unit, index }) {
    state.units.splice(index, 1, unit);
  },
  DELETE_UNIT(state, index) {
    state.units.splice(index, 1);
  }
};

export default { namespaced: true, state, getters, mutations };
