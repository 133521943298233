<template>
  <AppModalDefault :title="$t(`data.${id}.title`)" @close="close">
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppSearchbar v-if="categories">
          <template #default>
            <li>
              <AppSelect
                v-model="selectedCategoryId"
                :placeholder="$t('all_categories')"
                :options="categoryOptions"
              />
            </li>
          </template>
        </AppSearchbar>
        <AppContainer>
          <AppDataList :data="statData" />
        </AppContainer>
        <AppHeading :label="$t('related_campaigns')" />
        <AppTable :rows="campaigns" :columns="columns" no-head>
          <template #cell="{ row, column }">
            <template v-if="column.id === 'name'">
              <TargetCampaignNameCell
                :campaign-group-type="row.campaign_group_type"
                :campaign-name="row.name"
              />
            </template>
            <template v-else-if="column.id === 'campaign_status'">
              <AppBadge
                :badge-style="badgeStyle(row.status)"
                :label="TargetCampaignStatus.t(row.status)"
              />
            </template>
            <template v-else-if="column.id === 'actions'">
              <AppButton
                type="link"
                button-style="mango-outline"
                :label="$t('app.settings')"
                :to="campaignPath(row)"
                target="_blank"
              />
            </template>
          </template>
        </AppTable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import { mapGetters } from 'vuex';

import DialogView from '@/mixins/DialogView';
import ResourceView from '@/mixins/ResourceView';
import TargetCampaignStatus from '@/enums/TargetCampaignStatus';

export default {
  name: 'TargetDashboardStatDetailDialog',
  mixins: [DialogView, ResourceView],
  props: {
    id: { type: String, required: true }
  },
  data: () => ({
    isLoading: true,
    campaigns: [],
    totalAverage: null,
    similarAverage: null,
    categories: null,
    statDetail: {}
  }),
  computed: {
    ...mapGetters('targetCampaigns', ['campaignPath']),
    TargetCampaignStatus: () => TargetCampaignStatus,
    columns() {
      return [
        { id: 'name', align: 'left' },
        { id: 'campaign_status' },
        { id: 'actions' }
      ];
    },
    displayRate() {
      const { totalAverage, similarAverage, roundedRate } = this;
      const rate = this.calculateRate(this.statDetail);
      return !!totalAverage && !!similarAverage
        ? this.$t('display_rate_with_postfix', [
            roundedRate(rate),
            roundedRate(totalAverage),
            roundedRate(similarAverage)
          ])
        : this.$t('unit.percentage', [roundedRate(rate)]);
    },
    statData() {
      const { denominator, numerator } = this.statDetail;
      return [
        {
          label: this.$t(`data.${this.id}.denominator`),
          value: denominator
        },
        {
          label: this.$t(`data.${this.id}.numerator`),
          value: numerator
        },
        {
          label: this.$t(`data.${this.id}.rate`),
          value: this.displayRate
        }
      ];
    },
    categoryOptions() {
      return this.categories.map(c => ({ label: c.name, value: c.id }));
    },
    selectedCategoryId: {
      get() {
        const { category_id } = this.resourceParams;
        return category_id === undefined ? null : parseInt(category_id);
      },
      set(val) {
        this.resourceParams = { ...this.resourceParams, category_id: val };
        this.searchResource();
      }
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api.get(`/target/dashboard_kpis/${this.id}`).then(({ data }) => {
        this.campaigns = data.campaigns;
        this.totalAverage = data.total_average;
        this.similarAverage = data.similar_average;
        this.categories = data.categories;

        api
          .get(`/target/dashboard_kpis/${this.id}/kpi`, { params })
          .then(({ data }) => (this.statDetail = data.kpi))
          .finally(() => (this.isLoading = false));
      });
    },
    roundedRate: rate => _.round(rate * 100, 1),
    calculateRate: ({ numerator, denominator }) =>
      numerator && denominator ? numerator / denominator : 0,
    badgeStyle: status => {
      switch (status) {
        case TargetCampaignStatus.ENABLED:
          return 'mint-green';
        case TargetCampaignStatus.TESTING:
          return 'grey-outline';
        case TargetCampaignStatus.DISABLED:
          return 'default';
        default:
          return 'default';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.AppSearchbar {
  ::v-deep {
    & + * {
      margin-top: 8px;
    }
  }
}
</style>

<i18n locale="ko">
{
  "all_categories": "전체 카테고리",
  "related_campaigns": "관련 캠페인",
  "display_rate_with_postfix": "{0}% (전체 평균: {1}%, 유사 업계 평균: {2}%)",
  "data": {
    "customer_new_visitor": {
      "title": "신규 방문",
      "numerator": "신규 방문",
      "denominator": "총 방문",
      "rate": "신규 방문자 비율"
    },
    "customer_affiliate_conversion_rate": {
      "title": "가입 전환",
      "numerator": "회원 가입 방문",
      "denominator": "신규 및 비회원 방문",
      "rate": "가입 전환율"
    },
    "customer_drop_out_rate": {
      "title": "신규 회원 이탈",
      "numerator": "이탈 회원",
      "denominator": "가입 1개월차 회원",
      "rate": "가입 1개월 후 미방문률"
    },
    "customer_long_term": {
      "title": "장기 미방문",
      "numerator": "미방문 회원",
      "denominator": "전체 회원",
      "rate": "장기 미방문 회원 비율"
    },
    "order_drop_out_rate": {
      "title": "관심 상품 이탈",
      "numerator": "해당 상품을 구매하지 않은 회원",
      "denominator": "같은 상품을 3회 이상 열람한 회원",
      "rate": "관심 상품 구매 이탈률"
    },
    "category_drop_out_rate": {
      "title": "관심 카테고리 이탈",
      "numerator": "해당 카테고리 미구매 회원",
      "denominator": "해당 카테고리 5회 이상 열람 회원",
      "rate": "관심 카테고리 구매 이탈률"
    },
    "cart_purchase_conversion_rate": {
      "title": "장바구니 전환",
      "numerator": "그중 구매 상품",
      "denominator": "장바구니에 담긴 상품",
      "rate": "장바구니 전환율"
    },
    "bank_deposit_rate": {
      "title": "무통장 주문 입금",
      "numerator": "그중 입금 완료된 주문",
      "denominator": "무통장 입급 주문",
      "rate": "무통장 주문 입금률"
    }
  }
}
</i18n>
