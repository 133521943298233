import Vue from 'vue';

const state = { syncs: {} };

const getters = {
  brandSyncTypeInfo: state => brandSyncType => state.syncs[brandSyncType],
  brandSyncTypes: ({ syncs }) => Object.keys(syncs)
};

const mutations = {
  SET_SYNC(state, { brandSyncType, sync }) {
    Vue.set(state.syncs, brandSyncType, sync);
  },
  RESET_SYNC(state, brandSyncType) {
    Vue.delete(state.syncs, brandSyncType);
  }
};

const actions = {
  addBrandSyncType({ commit, getters }, { brandSyncType, sync }) {
    commit('SET_SYNC', { brandSyncType: brandSyncType, sync: sync });
    commit(
      'poll/SET_POLL_PARAMS',
      {
        key: 'brand_sync_types',
        value: getters.brandSyncTypes
      },
      {
        root: true
      }
    );
  },
  removeBrandSyncType({ commit, getters }, brandSyncType) {
    commit('RESET_SYNC', brandSyncType);
    commit(
      'poll/SET_POLL_PARAMS',
      {
        key: 'brand_sync_types',
        value: getters.brandSyncTypes
      },
      {
        root: true
      }
    );
  }
};

export default { namespaced: true, state, mutations, getters, actions };
