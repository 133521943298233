<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <DisplayProductManagementShowHead
      :is-form-valid="isFormValid"
      @dispatch-now="dispatchNow"
      @save="save"
    />
    <AppInfoBox class="DisplayProductManagementShow__info-box">{{
      information
    }}</AppInfoBox>
    <AppForm
      v-bind="formProps"
      class="DisplayProductManagementShow__form"
      object-id="product_management"
      form-style="hor"
      submit-button
      v-on="formEvents"
    >
      <template #group="{ id, errors }">
        <template v-if="id === 'execute_weekdays_value'">
          <i18n
            path="section.execute_time.groups.execute_weekdays_value.weekdays"
          >
            <AppWeekdaySelectButton
              v-model="formObject.execute_weekdays_value"
              class="AppForm__group-field DisplayProductManagementShow__execute-weekdays-value"
              name="product_management[execute_weekdays_value]"
              :invalid="!!errors.execute_weekdays_value"
              @change="validateField('execute_weekdays_value')"
            />
            <AppFormError :error="errors.execute_weekdays_value" />
          </i18n>
        </template>
        <template v-else-if="id === 'category_filter_type'">
          <AppSelectRadio
            v-model="formObject.category_filter_type"
            name="product_management[category_filter_type]"
            :options="
              DisplayCategoryFilterType.options([
                DisplayCategoryFilterType.ALL,
                DisplayCategoryFilterType.INCLUDE
              ])
            "
          >
            <template #sub-item="{ value, checked }">
              <template v-if="value === DisplayCategoryFilterType.INCLUDE">
                <AppSelectProductCategory
                  v-model="formObject.including_category_ids"
                  class="DisplayProductManagementShow__categories"
                  name="product_management[including_category_ids][]"
                  :disabled="!checked"
                  :invalid="!!errors.including_category_ids"
                  multiple
                  :showable-category-ids="displaySettings.enabled_category_ids"
                  @blur="validateField('including_category_ids')"
                  @change="validateField('including_category_ids')"
                />
                <AppFormError :error="errors.including_category_ids" />
              </template>
            </template>
          </AppSelectRadio>
          <AppCheckbox
            v-model="formObject.use_excluding_category_ids"
            class="AppForm__group-field"
            :label="
              $t(
                'section.filter_products.groups.use_excluding_category_ids.label'
              )
            "
            name="product_management[use_excluding_category_ids]"
          >
            <template #sub-item="{ checked }">
              <AppSelectProductCategory
                v-model="formObject.excluding_category_ids"
                class="DisplayProductManagementShow__categories"
                name="product_management[excluding_category_ids][]"
                :disabled="!checked"
                :invalid="!!errors.excluding_category_ids"
                multiple
                :showable-category-ids="displaySettings.enabled_category_ids"
                @blur="validateField('excluding_category_ids')"
                @change="validateField('excluding_category_ids')"
              />
              <AppFormHint
                :message="
                  $t(
                    'section.filter_products.groups.use_excluding_category_ids.description'
                  )
                "
                :disabled="!checked"
              />
              <AppFormError :error="errors.excluding_category_ids" />
            </template>
          </AppCheckbox>
        </template>
        <template v-else-if="id === 'product_management_units'">
          <DisplayProductManagementUnits
            class="DisplayProductManagementShow__units"
            :use-display-option="useDisplayOption"
            :product-option-types="productOptionTypes"
            :errors="errors"
            @validate-unit-field="validateUnitField"
          />
        </template>
        <template v-else-if="id === 'least_options_count'">
          <i18n
            path="section.filter_products.groups.least_options_count.description1"
          >
            <AppNumberInput
              v-model="formObject.least_options_count"
              class="AppForm__group-field app-input-inline app-input-inline--left"
              name="product_management[least_options_count]"
              :digits="3"
              :invalid="!!errors.least_options_count"
              @blur="validateField('least_options_count')"
              @change="validateField('least_options_count')"
            />
          </i18n>
        </template>
        <template v-else-if="id === 'managed_items_table'">
          <DisplayProductManagementManagedItemsTable
            :managed-items-data="formObject"
            @update-product-management="updateManagedItem"
          />
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import api from '@/lib/api';
import DisplayProductManagementTemplateType from '@/enums/DisplayProductManagementTemplateType';
import DisplayCategoryFilterType from '@/enums/DisplayCategoryFilterType';
import FormView from '@/mixins/FormView';
import TargetContentView from '@/mixins/TargetContentView';
import PeriodicalRequest from '@/mixins/PeriodicalRequest';
import DisplayUnitValidation from '@/mixins/DisplayUnitValidation';

export default {
  name: 'DisplayProductManagementShow',
  mixins: [
    TargetContentView,
    PeriodicalRequest,
    FormView,
    DisplayUnitValidation
  ],
  data: () => ({
    isLoading: true,
    productOptionTypes: []
  }),
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    ...mapState('session', ['displaySettings']),
    ...mapState('displayProductManagement', ['productManagement']),
    information() {
      const { template_type } = this.productManagement;
      const templateTypeKey = DisplayProductManagementTemplateType.key(
        template_type
      );
      if (!templateTypeKey) return null;

      return this.$t(`info.${templateTypeKey}`);
    },
    DisplayCategoryFilterType: () => DisplayCategoryFilterType,
    useDisplayOption: () => false,
    formSections() {
      return [
        {
          id: 'execute_time',
          groups: [
            {
              id: 'execute_weekdays_value',
              label: this.$t(
                'section.execute_time.groups.execute_weekdays_value.label'
              ),
              description: this.$t(
                'section.execute_time.groups.execute_weekdays_value.description_html'
              ),
              required: true,
              validate: [
                {
                  rule: 'positive_integer',
                  errorMessage: this.$t('validations.required')
                }
              ]
            }
          ]
        },
        {
          id: 'filter_products',
          groups: [
            {
              id: 'category_filter_type',
              label: this.$t(
                'section.filter_products.groups.category_filter_type.label'
              ),
              validate:
                this.formObject.category_filter_type !==
                  DisplayCategoryFilterType.ALL ||
                this.formObject.use_excluding_category_ids
                  ? ['required']
                  : [],
              fields: [
                ...(this.formObject.category_filter_type ===
                DisplayCategoryFilterType.INCLUDE
                  ? [{ id: 'including_category_ids', validate: ['required'] }]
                  : []),
                ...(this.formObject.use_excluding_category_ids
                  ? [{ id: 'excluding_category_ids', validate: ['required'] }]
                  : [])
              ]
            },
            {
              id: 'product_management_units',
              label: this.$t(
                'section.filter_products.groups.product_management_units.label'
              ),
              groupDescription: this.$t(
                'section.filter_products.groups.product_management_units.description'
              ),
              fields: this.unitFields
            },
            {
              id: 'least_options_count',
              label: this.$t(
                'section.filter_products.groups.least_options_count.label'
              ),
              description: this.$t(
                'section.filter_products.groups.least_options_count.description2'
              ),
              validate: [
                'required',
                'positive_integer',
                { rule: 'lt', allowed: 1000 }
              ]
            }
          ]
        },
        {
          id: 'managed_items',
          groups: [{ id: 'managed_items_table', label: '' }]
        },
        {
          id: 'etc',
          groups: [
            {
              id: 'info',
              label: null,
              type: 'infobox',
              value: this.$t('section.etc.groups.info.description')
            },
            {
              id: 'excluding_product_ids',
              label: this.$t('section.etc.groups.excluding_product_ids.label'),
              groupDescription: this.$t(
                'section.etc.groups.excluding_product_ids.description'
              ),
              type: 'select_product',
              multiple: true,
              products: this.formObject.excluding_products
            }
          ]
        }
      ].map(section => ({
        ...section,
        label: this.$t(`section.${section.id}.label`)
      }));
    },
    periodicalRequestPath() {
      return `display/product_managements/${this.$route.params.id}/scheduled_cohort`;
    },
    isFormValid() {
      const {
        use_category_management,
        use_icon_management,
        use_widget_management
      } = this.formObject;

      return (
        !this.hasError &&
        (use_category_management ||
          use_icon_management ||
          use_widget_management)
      );
    }
  },
  mounted() {
    const { id } = this.$route.params;
    api
      .get(`display/product_managements/${id}`)
      .then(({ data }) => {
        this.SET_PRODUCT_MANAGEMENT({ ...data.product_management, id });
        this.productOptionTypes = data.product_option_types;

        this.orgFormObject = data.product_management.form_data;
        this.SET_UNITS(this.orgFormObject.units);
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapMutations('displayProductManagement', [
      'SET_PRODUCT_MANAGEMENT',
      'UPDATE_PRODUCT_MANAGEMENT'
    ]),
    updateManagedItem(item) {
      this.formObject = { ...this.formObject, ...item };
    },
    submit(formData) {
      this.isSubmitting = true;

      api
        .patch(
          `display/product_managements/${this.productManagement.id}`,
          formData
        )
        .then(({ data }) => {
          this.orgFormObject = this.formObject;
          this.$nextTick(() =>
            this.$router.push(
              this.pathWithBrandParams('/display/product-managements')
            )
          );
          if (data.is_disabled) this.createToast(this.$t('disabled'));
          else this.createToast(this.$t('app.saved'));
        })
        .finally(() => (this.isSubmitting = false));
    },
    dispatchNow(dispatch_type) {
      this.formEventBus.$emit('validate-all');
      this.$nextTick(() => {
        if (!this.isFormValid) return;

        const formData = new FormData(this.$el.getElementsByTagName('form')[0]);
        formData.append('dispatch_type', dispatch_type);

        const { id } = this.productManagement;
        api
          .post(`display/product_managements/${id}/dispatch_now`, formData)
          .then(({ data }) =>
            this.UPDATE_PRODUCT_MANAGEMENT(data.product_management)
          );
      });
    },
    updateEnabled(enabled) {
      api
        .patch(
          `/display/product_managements/${this.productManagement.id}/update_enabled`,
          {
            enabled: enabled ? '1' : null
          }
        )
        .then(() => this.UPDATE_PRODUCT_MANAGEMENT({ enabled }));
    },
    save(value) {
      if (value === this.productManagement.enabled) return;

      this.formEventBus.$emit('validate-all');
      this.$nextTick(() => {
        if (this.hasError) return;
        if (this.isFormDataChanged) this.formEventBus.$emit('submit');

        this.updateEnabled(value);
      });
    },
    periodicalRequestCallback({ data: { product_management } }) {
      this.UPDATE_PRODUCT_MANAGEMENT(product_management);
    }
  }
};
</script>

<style scoped>
.DisplayProductManagementShow__info-box {
  margin-top: 28px;
  margin-bottom: 32px;
}

.DisplayProductManagementShow__form {
  width: 832px;
}

.DisplayProductManagementShow__execute-weekdays-value {
  width: 246px;
  margin-right: 4px;
  display: inline-block;
}

.DisplayProductManagementShow__categories {
  width: 456px;
}

.DisplayProductManagementShow__units {
  margin-top: 8px;
}
</style>

<i18n locale="ko">
{
  "info": {
    "FAST_DELIVERY": "빠른배송이 가능한 상품들을 자동으로 선정하고 관리할 수 있습니다.",
    "SELECTED_PRODUCT_OPTIONS": "특정 옵션을 포함한 상품을 자동으로 선정하고 관리할 수 있습니다.",
    "USER_DEFINITION": "필요에 따라 자유롭게 상품들을 자동으로 선정하고 관리할 수 있습니다."
  },
  "section": {
    "execute_time": {
      "label": "실행 시점",
      "groups": {
        "execute_weekdays_value": {
          "label" :"자동 관리 실행 시점",
          "weekdays": "{0}요일의",
          "description_html": "8, 11, 14, 17, 20, 23시에 동기화 및 대상 상품을 선정하여 자동 관리를 실행합니다.<br />즉시 실행이 필요하면 우측 상단의 [즉시 실행] 버튼을 클릭 하세요."
        }
      }
    },
    "filter_products": {
      "label": "대상 상품 선정",
      "groups": {
        "category_filter_type": {
          "label": "관리할 상품 카테고리"
        },
        "use_excluding_category_ids": {
          "label": "다음 카테고리에 포함된 상품 제외",
          "description": "예를들어 아우터 카테고리에 포함된 상품 중 SALE에도 포함된 상품을 제외 하려면 위에서 SALE카테고리를 선택하세요."
        },
        "product_management_units": {
          "label": "관리할 상품 선정 조건",
          "description": "관리할 상품 카테고리 안에서 상품을 선정할 조건을 입력합니다. 최대 7개까지 입력 가능합니다."
        },
        "least_options_count": {
          "label": "상품 선정 시 옵션 별 재고량 확인",
          "description1": "상품 선정 조건 중 재고량 기준에 해당하는 옵션을{0}개 이상 가지고 있는 상품만 선정합니다.",
          "description2": "설정 값보다 옵션 수가 적은 상품은 상품의 모든 옵션이 조건을 충족하면 선정합니다."
        }
      }
    },
    "managed_items": {
      "label": "자동 관리 항목",
      "groups": {
        "table": {
          "columns": {
            "using_management": "사용여부",
            "item": "자동 관리 항목",
            "description": "내용"
          },
          "using_management": {
            "true": "사용",
            "false": "미사용"
          },
          "items": {
            "category": "가상 카테고리 이동",
            "icon": "상품에 아이콘 표시",
            "widget": "빠른 배송 안내 위젯 표시"
          }
        }
      }
    },
    "etc": {
      "label": "기타 설정",
      "groups": {
        "info": {
          "description": "상품자동관리 예외 설정 등 필수 설정 이외의 설정을 할 수 있습니다."
        },
        "excluding_product_ids": {
          "label": "상품 자동 관리 예외 상품 설정",
          "description": "상품 자동 관리 진행 시 관리 대상으로 선정 되더라도 수정하지 않습니다. 빠른 배송 위젯이 표시 중이라면 미노출합니다."
        }
      }
    }
  },
  "disabled": "미사용 상태로 변경합니다. 모든 필수 설정값이 입력되고 자동 관리 항목이 1개 이상 사용 설정되어야 합니다."
}
</i18n>
