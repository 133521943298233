<template>
  <Transition name="fade">
    <a :class="['AppBubble', `AppBubble--${side}`]" @click="$emit('click')"
      ><slot
    /></a>
  </Transition>
</template>

<script>
export default {
  name: 'AppBubble',
  props: {
    side: {
      type: String,
      default: 'left',
      validator: v =>
        [
          'left',
          'right',
          'top-right',
          'top-center',
          'bottom-right',
          'bottom-center'
        ].includes(v)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_z-indexes.scss';

$color-bubble: #7a6eff;
$triangle-width: 7px;
$triangle-height: 4px;

.AppBubble {
  display: block;
  position: absolute;
  z-index: $z-index-help;

  padding: 8px 16px;
  background: $color-bubble;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(15, 15, 15, 0.08);

  color: white;
  line-height: 18px;
  white-space: nowrap;

  &--left {
    right: 100%;
    margin-right: 10px;
    text-align: right;
    top: 50%;
    transform: translateY(-50%);

    &:after {
      content: '';

      position: absolute;
      top: 50%;
      right: 0;

      width: 0;
      height: 0;

      border: $triangle-width solid transparent;
      border-left-color: $color-bubble;
      border-right: 0;
      border-top-width: $triangle-height;
      border-bottom-width: $triangle-height;

      margin-top: -$triangle-height;
      margin-right: -$triangle-width;
    }
  }

  &--right {
    left: 100%;
    margin-left: 10px;
    text-align: left;
    top: 50%;
    transform: translateY(-50%);

    &:before {
      content: '';

      position: absolute;
      top: 50%;
      left: 0;

      width: 0;
      height: 0;

      border: $triangle-width solid transparent;
      border-right-color: $color-bubble;
      border-left: 0;
      border-top-width: $triangle-height;
      border-bottom-width: $triangle-height;

      margin-top: -$triangle-height;
      margin-left: -$triangle-width;
    }
  }

  &--top-right {
    bottom: 100%;
    margin-bottom: 10px;
    left: 50%;
    transform: translateX(-$triangle-width * 3 + 1px);

    &:before {
      content: '';

      position: absolute;
      top: 100%;
      left: 20px;
      width: 0px;
      height: 0px;
      border: $triangle-width solid transparent;
      border-top-color: $color-bubble;
      border-top-width: $triangle-width;
      border-bottom-width: $triangle-width;
      margin-top: -1px;
      margin-left: -$triangle-width;
    }
  }

  &--top-center {
    bottom: 100%;
    margin-bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      content: '';

      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 0px;
      border: $triangle-width solid transparent;
      border-top-color: $color-bubble;
      border-top-width: $triangle-width;
      border-bottom-width: $triangle-width;
      margin-top: -1px;
    }
  }

  &--bottom-right {
    top: 100%;
    margin-top: 10px;
    left: 50%;
    transform: translateX(-$triangle-width * 3 + 1px);
    text-align: left;

    &:before {
      content: '';

      position: absolute;
      bottom: 100%;
      left: 20px;
      width: 0px;
      height: 0px;
      border: $triangle-width solid transparent;
      border-bottom-color: $color-bubble;
      border-bottom-width: $triangle-width;
      border-top-width: $triangle-width;
      margin-bottom: -1px;
      margin-left: -$triangle-width;
    }
  }

  &--bottom-center {
    top: 100%;
    margin-top: 10px;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      content: '';

      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 0px;
      border: $triangle-width solid transparent;
      border-bottom-color: $color-bubble;
      border-bottom-width: $triangle-width;
      border-top-width: $triangle-width;
      margin-bottom: -1px;
    }
  }
}
</style>
