<template>
  <AppModalDefault :title="title" :sub-title="campaign.name" @close="close">
    <template #body>
      <AppInfoBox v-if="infoText" class="TargetCampaignKpiDialog__info">
        <pre>{{ infoText }}</pre>
      </AppInfoBox>
      <AppAjaxContent :is-loading="isLoading" height="192px">
        <AppTable :columns="columns" :rows="rows">
          <template #cell="{ column, row }">
            <template v-if="column.id === 'value'">
              <AppChartPercentageBar
                :numerator="row.numerator"
                :denominator="row.denominator"
              />
            </template>
          </template>
        </AppTable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/lib/api';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'TargetCampaignKpiDialog',
  mixins: [DialogView],
  props: {
    campaign: { type: Object, required: true },
    title: { type: String, required: true },
    column: { type: String, required: true },
    samplingMethod: { type: Number, required: true }
  },
  data() {
    return { isLoading: true, reports: [] };
  },
  computed: {
    ...mapGetters('targetCampaigns', ['campaignText', 'isWidgetCampaign']),
    columns() {
      return [
        {
          id: 'date',
          label: this.$t(
            this.isWidgetCampaign ? 'checked_label' : 'date_label'
          ),
          align: 'left'
        },
        {
          id: 'value',
          label: this.kpiText('value_label'),
          align: 'left',
          width: '360px'
        }
      ];
    },
    rows() {
      return this.reports.map(report => ({
        ...report,
        date: this.formatMonthWithYear(report.date)
      }));
    },
    infoText() {
      try {
        return this.kpiText('info');
      } catch {
        return '';
      }
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaign.id}/reports_kpi/${this.column}`)
      .then(({ data }) => (this.reports = data.reports))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    kpiText(key) {
      const column = TargetSamplingMethod.toCheckedColumn(this.column);
      return this.campaignText(
        this.campaign,
        `kpi_dialog.${column}.${key}`,
        {},
        this.samplingMethod
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignKpiDialog__info {
  margin-bottom: 24px;
}
</style>

<i18n locale="ko">
{
  "date_label": "발송일",
  "checked_label": "노출월"
}
</i18n>
