<template>
  <AppGrid>
    <div class="AppGrid__col AppGrid__col--6">
      <TargetSettingsSection
        :title="$t('pc.title')"
        with-preview
        @open-settings="openWidgetDesignSettingsDialog('pc')"
        @open-preview="openPreview(true)"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="TargetCampaignSettingsWidgetDesign__description"
          v-html="widgetStyleSummary('pc')"
        />
        <div
          class="TargetCampaignSettingsWidgetDesign__description"
          v-html="widgetSummary('pc')"
        />
        <!-- eslint-enable vue/no-v-html -->
      </TargetSettingsSection>
    </div>
    <div class="AppGrid__col AppGrid__col--6">
      <TargetSettingsSection
        :title="$t('mobile.title')"
        with-preview
        @open-settings="openWidgetDesignSettingsDialog('mobile')"
        @open-preview="openPreview(false)"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="TargetCampaignSettingsWidgetDesign__description"
          v-html="widgetStyleSummary('mobile')"
        />
        <div
          class="TargetCampaignSettingsWidgetDesign__description"
          v-html="widgetSummary('mobile')"
        />
        <!-- eslint-enable vue/no-v-html -->
      </TargetSettingsSection>
    </div>
  </AppGrid>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TargetWidgetStyle from '@/enums/TargetWidgetStyle';
import TargetSettingsSection from './components/TargetSettingsSection';

export default {
  name: 'TargetCampaignSettingsWidgetDesign',
  components: { TargetSettingsSection },
  computed: {
    ...mapState('targetCampaign', ['campaign'])
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    widgetStyleSummary(type) {
      return this.$t('widget_style', [
        TargetWidgetStyle.t(
          this.campaign.widget_design_attributes[`${type}_widget_style`]
        )
      ]);
    },
    widgetSummary(type) {
      const { widget_design_attributes } = this.campaign;

      if (
        type === 'mobile' &&
        widget_design_attributes['mobile_widget_style'] ===
          TargetWidgetStyle.HORIZONTAL_SCROLL
      )
        return this.$t('widget_summary_mobile_horizontal_scroll', [
          widget_design_attributes['mobile_products_count_per_row'],
          widget_design_attributes['mobile_horizontal_scroll_multiple_rows']
            ? 2
            : 1
        ]);
      else if (
        type === 'mobile' &&
        TargetWidgetStyle.listStyles.includes(
          widget_design_attributes['mobile_widget_style']
        )
      ) {
        if (widget_design_attributes['mobile_show_horizontal_scroll'])
          return this.$t('widget_summary_mobile_list_horizontal_scroll', [
            widget_design_attributes['mobile_products_count_per_page']
          ]);
        else return this.$t('widget_summary_mobile_list_show_all');
      } else
        return this.$t('widget_summary', [
          widget_design_attributes[`${type}_products_count_per_row`]
        ]);
    },
    openWidgetDesignSettingsDialog(deviceType) {
      this.openDialog([
        'TargetCampaignSettingsWidgetDesignDialog',
        { campaign: this.campaign, deviceType: deviceType }
      ]);
    },
    openPreview(showPcAsDefault) {
      this.openDialog([
        'TargetCampaignProductRecommendationWidgetPreviewDialog',
        { showPcAsDefault }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignSettingsWidgetDesign__description {
  & + & {
    margin-top: 4px;
  }
}
</style>

<i18n locale="ko">
{
  "pc": {
    "title": "PC 위젯"
  },
  "mobile": {
    "title": "모바일 위젯"
  },
  "widget_style": "위젯 스타일: <b>{0}</b>",
  "widget_summary": "요약: <b>추천 상품을 한 줄에 {0}개씩 보여줍니다.</b>",
  "widget_summary_mobile_horizontal_scroll": "요약: <b>추천 상품을 {0}개씩 {1}줄로 보여줍니다.</b>",
  "widget_summary_mobile_list_horizontal_scroll": "요약: <b>추천 상품을 한 페이지에 {0}개씩 보여줍니다.</b>",
  "widget_summary_mobile_list_show_all": "요약: <b>추천 상품을 한 페이지에 모두 보여줍니다.</b>"
}
</i18n>
