<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="subTitle"
    :form-props="{ ...formProps, disabled: !isValid }"
    v-on="formEvents"
  >
    <AppDataSection
      class="DisplayCornerPinProductDialog__product-info"
      :title="$t('product_info.title')"
      >{{ productsName }}</AppDataSection
    >
    <template #group="{ id, errors }">
      <template v-if="id === 'pin_until_type'">
        <div class="AppForm__group-field">
          <AppSelectRadio
            v-model="formObject.pin_until_type"
            :options="pinUntilOptions"
          >
            <template #sub-item="{ value, checked }">
              <template v-if="value">
                <AppDatePicker
                  v-model="formObject.pin_until"
                  :min-date="minDate"
                  :disabled="!checked"
                  :invalid="!!errors.pin_until_date"
                  @blur="validateField('pin_until_date')"
                  @change="validateField('pin_until_date')"
                />
                <AppFormError :error="errors.pin_until_date" />
              </template>
            </template>
          </AppSelectRadio>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import moment from 'moment';
import { mapMutations } from 'vuex';
import DisplayCornerHandleProductView from '@/mixins/DisplayCornerHandleProductView';

export default {
  name: 'DisplayCornerPinProductDialog',
  mixins: [DisplayCornerHandleProductView],
  data: () => ({ formObject: { pin_until_type: false, pin_until: '' } }),
  computed: {
    minDate() {
      return moment().toVal();
    },
    formSections() {
      return this.isValid
        ? [
            {
              groups: [
                {
                  id: 'pin_until_type',
                  label: this.$t('form.pin_until_type.label'),
                  type: 'select_radio'
                }
              ]
            }
          ]
        : [];
    },
    pinUntilOptions() {
      return [
        {
          id: 'unlimited',
          label: this.$t('form.pin_until_type.options.unlimited'),
          value: false
        },
        {
          id: 'selected_date',
          label: this.$t('form.pin_until_type.options.selected_date'),
          value: true
        }
      ];
    }
  },
  methods: {
    ...mapMutations('displayProductDisplay', ['PIN_PRODUCTS']),
    submit() {
      const isUnlimited = this.formObject.pin_until_type == 0;
      this.PIN_PRODUCTS({
        products: this.selectedProducts,
        pinUntil: isUnlimited ? '' : this.formObject.pin_until
      });
      this.eventBus.$emit('pin');
      this.close(true);
    }
  }
};
</script>

<style scoped>
.DisplayCornerPinProductDialog__product-info {
  margin-top: 12px;
}
</style>

<i18n locale="ko">
{
  "title": "진열 고정",
  "product_info": {
    "title": "진열 고정 상품"
  },
  "form": {
    "pin_until_type": {
      "label": "상품 고정 기간",
      "options": {
        "unlimited": "무기한 상품 진열 고정",
        "selected_date": "선택한 날짜까지 상품 진열 고정"
      }
    }
  }
}
</i18n>
