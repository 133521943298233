import _ from 'lodash';
import Selectable from './Selectable';
import ArrayPackable from './ArrayPackable';

export default {
  mixins: [Selectable, ArrayPackable],
  model: {
    event: 'change'
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Array],
      default: null
    },
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentValue: []
    };
  },
  watch: {
    value(newValue) {
      this.setCurrentValue(this.unpackToArray(newValue), false);
    }
  },
  created() {
    this.currentValue = this.castValues(this.unpackToArray(this.value));
  },
  methods: {
    castValue(value) {
      if (this.isNumber) {
        return typeof value === 'number' ? value : _.toNumber(value);
      } else {
        return typeof value === 'string' ? value : _.toString(value);
      }
    },
    castValues(values) {
      if (values) {
        return values.map(this.castValue).sort((a, b) => (a > b ? 1 : -1));
      } else {
        return [];
      }
    },
    setCurrentValue(newValue, emit = true) {
      const newCurrentValue = this.castValues(newValue);
      if (_.isEqual(this.currentValue, newCurrentValue)) return;

      this.currentValue = newCurrentValue;
      if (emit) this.changed();
    },
    isSelected({ value }) {
      return this.currentValue.includes(value);
    },
    select(option) {
      if (this.isSelected(option)) return;
      if (option.value === null) return;

      const index = _.findIndex(this.currentValue, v => v > option.value);
      if (index === -1) {
        this.currentValue.push(option.value);
      } else {
        this.currentValue.splice(index, 0, option.value);
      }
      this.$emit('select', option.value);
      this.changed();
    },
    unselect({ value }) {
      const index = this.currentValue.indexOf(value);
      if (index === -1) return;

      this.$delete(this.currentValue, index);
      this.$emit('unselect', value);
      this.changed();
    },
    changed() {
      this.$emit('change', this.packFromArray(this.currentValue));
    },
    flattenOptions(options) {
      if (!options) return [];

      const queue = [...options];
      const result = [];

      while (queue.length > 0) {
        const o = queue.shift();
        if (o.value !== null) {
          result.push(o);
        } else if (o.options) {
          queue.push(...o.options);
        }
      }
      return result;
    },
    findIndexPath(options, target) {
      if (!options) return;

      const queue = options.map((o, i) => [o, [i + 1]]);
      while (queue.length > 0) {
        const [option, path] = queue.shift();
        if (option.value === target) {
          return path;
        }
        if (option.options) {
          queue.push(...option.options.map((o, i) => [o, [...path, i + 1]]));
        }
      }
    }
  }
};
