<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppSummaryBox>
        <template #title>Steve Rogers님, 환영합니다. 🎉</template>
        쇼핑몰에 크리마 리뷰를 사용할 수 있도록 사용 준비를 진행해주세요.
      </AppSummaryBox>
    </div>
  </div>
</template>

<script>
export default { name: 'UiSummaryBox' };
</script>
