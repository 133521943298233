<template>
  <AppTabContent menu-item-id="target_dm_campaigns_mass">
    <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
      <TargetCampaignsSearchbar
        v-bind="searchbarProps"
        v-on="searchbarEvents"
      />
      <TargetCampaignsTable
        v-bind="tableProps"
        :show-stats="resourceParams.tab === 'archived'"
      >
        <template #cell="{ row, column }">
          <template v-if="column === 'dispatch_date'">
            <template v-if="row.configured">
              {{
                `${row.dispatch_date} ${row.dispatch_hour}:00`
                  | formatDateTimeWithoutSecond
              }}
            </template>
            <TargetCampaignsTableEffectUnavailableCell v-else :campaign="row" />
          </template>
        </template>
      </TargetCampaignsTable>
    </AppAjaxContent>
  </AppTabContent>
</template>

<script>
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignsView from './TargetCampaignsView';
import TargetCampaignsTableEffectUnavailableCell from './components/TargetCampaignsTableEffectUnavailableCell';

export default {
  name: 'TargetDmCampaignsMass',
  components: { TargetCampaignsTableEffectUnavailableCell },
  mixins: [TargetCampaignsView],
  computed: {
    campaignCategory: () => 'mass',
    campaignMediaType: () => 'direct_message',
    sortTypes: () => ['created_at', 'edited_at'],
    searchTypes: () => ['name'],
    columns() {
      return [
        { id: 'campaignInfo' },
        { id: 'dispatch_date', label: this.$t('dispatch_date'), align: 'left' },
        { id: 'campaignId' },
        { id: 'editedAt' },
        { id: 'calculateStatus' },
        { id: 'actions' }
      ];
    },
    creatableCampaignTypes: () => TargetCampaignType.creatableMass
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .campaigns-table__dispatch-date {
    flex: 0 0 304px;
  }
}
</style>

<i18n locale="ko">
{
  "dispatch_date": "발송일"
}
</i18n>
