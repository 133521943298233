<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppAccordion
        class="UiAccordion__basic"
        title="Title"
        sub-title="sub-title"
      >
        contents
      </AppAccordion>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Data Entry</label>
      </div>
      <AppAccordion
        class="UiAccordion__data"
        title="Title"
        sub-title="sub-title"
        type="dataEntry"
        :is-completed="DataEntryIsCompleted"
        @toggleCompletion="dataToggleCompletion($event)"
      >
        contents
      </AppAccordion>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Data Entry (Progress Step)</label>
      </div>
      <AppAccordion
        class="UiAccordion__progress"
        title="Title"
        sub-title="sub-title"
      >
        <div v-for="(step, i) in STEPS" :key="step.title">
          <AccordionStepContent
            v-if="i === currentStep - 1"
            :current-step="currentStep"
            :steps-count="STEPS.length"
            :is-completed="progressIsCompleted"
            :title="step.title"
            :tip="step.tip"
            @modify="progressIsCompleted = false"
            @next="increaseStep"
            @prev="currentStep -= 1"
          >
            {{ step.content }}
          </AccordionStepContent>
        </div>
      </AppAccordion>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Disabled</label>
      </div>
      <AppAccordion
        class="UiAccordion__disabled"
        title="Title"
        sub-title="sub-title"
        disabled
        disabled-tooltip="disabled-tooltip"
      >
        contents
      </AppAccordion>
    </div>
  </div>
</template>

<script>
import AccordionStepContent from '@/views/review/dashboard/components/AccordionStepContent';

export default {
  name: 'UiAccordion',
  components: { AccordionStepContent },
  data() {
    return {
      DataEntryIsCompleted: false,
      progressIsCompleted: false,
      currentStep: 1
    };
  },
  created() {
    this.STEPS = [
      { title: 'step title1', tip: 'step tip1', content: 'step content1' },
      { title: 'step title2', tip: 'step tip2', content: 'step content2' },
      { title: 'step title3', tip: 'step tip3', content: 'step content3' }
    ];
  },
  methods: {
    stepChanged(step) {
      this.currentStep = step;
    },
    dataToggleCompletion(isCompleted) {
      this.DataEntryIsCompleted = isCompleted;
    },
    increaseStep() {
      if (this.currentStep < this.STEPS.length) this.currentStep += 1;
      else this.progressIsCompleted = true;
    }
  }
};
</script>

<style lang="scss">
.UiAccordion__basic,
.UiAccordion__data,
.UiAccordion__progress,
.UiAccordion__disabled {
  width: 600px;
}
</style>
