<template>
  <AppBadge v-bind="{ label, badgeStyle, tooltip }" />
</template>

<script>
import { mapGetters } from 'vuex';
import DisplayProductManagementStatus from '@/enums/DisplayProductManagementStatus';
import DisplayProductManagementDispatchType from '@/enums/DisplayProductManagementDispatchType';

export default {
  name: 'DisplayProductManagementStatusBadge',
  props: {
    productManagementStatus: { type: Number, required: true },
    applyAt: { type: String, default: '' },
    dispatchType: { type: Number, default: null }
  },
  computed: {
    ...mapGetters('displayProductDisplays', ['cornerPageTypeKey']),
    label() {
      return DisplayProductManagementStatus.t(this.productManagementStatus);
    },
    badgeStyle() {
      switch (this.productManagementStatus) {
        case DisplayProductManagementStatus.NONE:
          return 'grey-outline';
        case DisplayProductManagementStatus.APPLYING:
          return 'mint-green-outline';
        case DisplayProductManagementStatus.OPERATING:
          return 'mint-green';
        case DisplayProductManagementStatus.ERROR:
          return 'red';
        default:
          return 'default';
      }
    },
    tooltip() {
      if (
        this.productManagementStatus === DisplayProductManagementStatus.APPLYING
      )
        return this.$t(
          `tooltip.${DisplayProductManagementDispatchType.key(
            this.dispatchType
          )}`,
          [this.formatDateTime(this.applyAt)]
        );
      else return null;
    }
  }
};
</script>

<i18n locale="ko">
{
  "tooltip": {
    "NORMAL": "{0}에 시작된 상품 자동 관리가 진행 중입니다.",
    "IMMEDIATE": "{0}에 시작된 즉시 실행 - 바로적용이 진행 중입니다.",
    "CONFIRM": "{0}에 시작된 즉시 실행 - 결과 확인 후 적용이 진행 중입니다."
  }
}
</i18n>
