<template>
  <div :class="['AppMobilePhonePreview', `AppMobilePhonePreview--${appStyle}`]">
    <div class="AppMobilePhonePreview__phone">
      <div class="AppMobilePhonePreview__top-pane">
        <div class="AppMobilePhonePreview__time">
          {{ currentTime }}
        </div>
        <AppSvgIcon
          class="AppMobilePhonePreview__icons"
          name="icon-phone-status"
        />
      </div>
      <div class="AppMobilePhonePreview__body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AppMobilePhonePreview',
  props: {
    appStyle: {
      type: String,
      default: '',
      validator: v => ['biz-message', ''].includes(v)
    }
  },
  computed: {
    currentTime() {
      return moment().format('h:mm');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_clearfix.scss';
@import '@/scss/vars/_colors.scss';

.AppMobilePhonePreview {
  width: 599px;
  padding: 67px 0;
}

$phone-border-radius: 28px;
$phone-height: 740px;

.AppMobilePhonePreview__phone {
  margin: 0 auto;
  width: 375px;
  height: $phone-height;
}

$top-pane-height: 40px;
.AppMobilePhonePreview__top-pane {
  @include clearfix;
  height: $top-pane-height;
  border: 1px solid $color-grey-25;
  border-bottom: 0px;
  border-radius: $phone-border-radius $phone-border-radius 0 0;
  padding: 16px 24px 0 24px;

  .AppMobilePhonePreview--biz-message & {
    height: 39px;
    background-color: #e7ecf0;
  }
}

.AppMobilePhonePreview__time {
  float: left;
}

.AppMobilePhonePreview__icons {
  float: right;

  .AppMobilePhonePreview--biz-message & {
    position: relative;
    top: 1px;
  }
}

.AppMobilePhonePreview__body {
  height: $phone-height - $top-pane-height;
  border: 1px solid $color-grey-25;
  border-top: 0px;
  border-radius: 0 0 $phone-border-radius $phone-border-radius;
  overflow: hidden;

  .AppMobilePhonePreview--biz-message & {
    height: $phone-height - 39px;
    background-color: #d0dae2;
  }

  ::v-deep {
    > .AppScrollBox {
      height: 100%;
    }
  }
}
</style>
