<template>
  <div
    :class="[
      'DisplayCornerProductOverlay',
      isSelected ? 'DisplayCornerProductOverlay--selected' : ''
    ]"
    @click="$emit('click-product', $event)"
  >
    <div class="DisplayCornerProductOverlay__container">
      <div class="DisplayCornerProductOverlay__head">
        <DisplayCornerProductOverlayCheckbox
          :checked="isSelected"
          @click.stop="$emit('click-checkbox', $event)"
        />
        <div
          v-if="!isSelected && !isPinned"
          class="DisplayCornerProductOverlay__menu"
        >
          <DisplayCornerProductOverlayDropdownMenu
            class="DisplayCornerProductOverlay__menu-item"
            :product="product"
          />
          <div
            v-if="showCloseButton"
            v-tooltip="$t('tooltip.cancel')"
            class="DisplayCornerProductOverlay__menu-item"
            @click.stop="removeProducts([product.index])"
          >
            <AppSvgIcon name="icon-tag-close" />
          </div>
        </div>
      </div>
      <DisplayCornerProductOverlayData
        class="DisplayCornerProductOverlay__body"
        :product="product"
      />
      <div v-if="!isSelected" class="DisplayCornerProductOverlay__foot">
        <template v-if="isPinned">
          <span
            class="DisplayCornerProductOverlay__foot-component"
            @click.stop="unPinSelectedProduct"
            >{{ $t('button.unpin') }}</span
          ><span class="DisplayCornerProductOverlay__foot-component-border"
            >|</span
          ><span
            class="DisplayCornerProductOverlay__foot-component"
            @click.stop="updatePinUntil"
            >{{ $t('button.update_pin_until') }}</span
          >
        </template>
        <template v-else>
          <span
            class="DisplayCornerProductOverlay__foot-component"
            @click.stop="sortSelectedProduct"
            >{{ $t('button.sort') }}</span
          ><span class="DisplayCornerProductOverlay__foot-component-border"
            >|</span
          ><span
            class="DisplayCornerProductOverlay__foot-component"
            @click.stop="pinSelectedProduct"
            >{{ $t('button.pin') }}</span
          >
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'DisplayCornerProductOverlay',
  props: {
    product: { type: Object, required: true },
    isSelected: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('displayProductDisplay', ['isPinnedProduct']),
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    isPinned() {
      return this.isPinnedProduct(this.product);
    },
    showCloseButton() {
      return !this.isCategoryPage;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('displayProductDisplay', ['removeProducts']),
    ...mapMutations('displayProductDisplay', [
      'PIN_PRODUCTS',
      'UNPIN_PRODUCTS'
    ]),
    sortSelectedProduct() {
      this.openDialog([
        'DisplayCornerSortProductDialog',
        { selectedProducts: [this.product] }
      ]).then(eventBus =>
        eventBus.$on('sort', dstInedx => this.$emit('sort', dstInedx))
      );
    },
    pinSelectedProduct() {
      this.PIN_PRODUCTS({ products: [this.product], pinUntil: '' });
    },
    updatePinUntil() {
      this.openDialog([
        'DisplayCornerPinProductDialog',
        { selectedProducts: [this.product] }
      ]);
    },
    unPinSelectedProduct() {
      this.UNPIN_PRODUCTS([this.product]);
    }
  }
};
</script>

<style lang="scss" scoped>
.DisplayCornerProductOverlay {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  color: #fff;
  cursor: pointer;

  &--selected {
    display: block;
    background-color: rgba(41, 140, 231, 0.8);
  }
}

.DisplayCornerProductOverlay__container {
  height: 100%;
  position: relative;
}

.DisplayCornerProductOverlay__head {
  padding: 5px 5px 0 5px;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
}

.DisplayCornerProductOverlay__menu {
  margin-left: auto;
}

.DisplayCornerProductOverlay__menu-item {
  display: inline-block;
}

.DisplayCornerProductOverlay__body {
  padding-top: 32px;
  padding-bottom: 28px;
}

.DisplayCornerProductOverlay__foot {
  padding: 0 4px 8px 4px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}

.DisplayCornerProductOverlay__foot-component {
  flex: 1;
}

.DisplayCornerProductOverlay__foot-component-border {
  flex: 0;
}
</style>

<i18n locale="ko">
{
  "button": {
    "sort": "이동",
    "pin": "고정",
    "unpin": "고정 해제",
    "update_pin_until": "기간 설정"
  },
  "tooltip": {
    "cancel": "진열 취소"
  }
}
</i18n>
