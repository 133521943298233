<template>
  <AppModalForm
    :title="$t(editing ? 'title_edit' : 'title_new')"
    :form-props="{ ...formProps, objectId: 'smart_installation' }"
    v-on="formEvents"
    @blur-group="formGroupBlurred"
  >
    <input
      type="hidden"
      name="smart_installation[installation_type]"
      :value="installation_type"
    />
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import WidgetPageType from '@/enums/WidgetPageType';
import SmartInstallationType from '@/enums/SmartInstallationType';
import DialogFormView from '@/mixins/DialogFormView';
import SmartInstallationHelper from '@/mixins/SmartInstallationHelper';

export default {
  name: 'ReviewSmartInstallationHideWidgetFormDialog',
  mixins: [DialogFormView, SmartInstallationHelper],
  props: {
    hideWidget: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      installation_type: SmartInstallationType.HIDE_ELEMENT
    };
  },
  computed: {
    isMobile() {
      return this.formObject.device_type === WidgetDeviceType.MOBILE;
    },
    groupOptions() {
      return {
        device_type: {
          label: this.$t('device_type.label'),
          type: 'select_radio',
          options: WidgetDeviceType.options(),
          validate: ['required'],
          required: true
        },
        page_type: {
          label: this.$t('page_type.label'),
          type: 'select',
          placeholder: this.$t('page_type.placeholder'),
          options: WidgetPageType.options(),
          validate: ['required'],
          required: true
        },
        page_path: {
          label: this.$t('page_path.label'),
          type: 'text',
          groupTooltip: this.$t('page_path.tooltip'),
          placeholder: this.$t('page_path.placeholder'),
          validate: [
            'required',
            {
              rule: 'check_path_or_url',
              errorMessage: this.$t('page_path.error')
            }
          ],
          required: true
        },
        element_selector: {
          label: this.$t('element_selector.label'),
          type: 'text',
          placeholder: this.$t('element_selector.placeholder'),
          validate: [
            'required',
            {
              rule: 'css_selector_format',
              errorMessage: this.$t('validations.selector_format')
            }
          ]
        },
        skin_id: {
          groupDescription: this.$t('skin_id.description'),
          label: this.$t('skin_id.label'),
          type: 'text',
          placeholder: this.$t('skin_id.placeholder')
        }
      };
    },
    groupIds() {
      return this.formObject.page_type === WidgetPageType.SPECIFIC
        ? [
            'device_type',
            'page_type',
            'page_path',
            'element_selector',
            'skin_id'
          ]
        : ['device_type', 'page_type', 'element_selector', 'skin_id'];
    },
    formSections() {
      return [
        {
          id: 'default',
          groups: this.groupIds.map(id => {
            return {
              id,
              ...this.groupOptions[id]
            };
          })
        }
      ];
    },
    editing() {
      return this.formObject.id;
    }
  },
  mounted() {
    this.orgFormObject = this.hideWidget;
  },
  methods: {
    formGroupBlurred(id) {
      if (id === 'page_path') {
        const [newPagePath, newSkinId] = this.parsePagePath(
          this.formObject.page_path
        );
        this.formObject.page_path = newPagePath;
        this.formObject.skin_id = newSkinId;
      }
    },
    submit(formData) {
      if (this.editing) {
        api
          .patch(`/smart_installations/${this.hideWidget.id}`, formData, {
            successMessage: this.$t('app.saved')
          })
          .then(({ data }) => {
            this.eventBus.$emit('update', data.smart_installation);
            this.close(true);
          });
      } else {
        api
          .post('/smart_installations', formData, {
            successMessage: this.$t('app.created')
          })
          .then(({ data }) => {
            this.eventBus.$emit('save', data.smart_installation);
            this.close(true);
          });
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_new": "쇼핑몰 기존 위젯 숨김 추가",
  "title_edit": "쇼핑몰 기존 위젯 숨김 설정",
  "skin_id": {
    "label": "적용 스킨 정보",
    "placeholder": "스킨 정보를 입력해주세요.",
    "description": "입력하지 않을 경우 모든 스킨에 적용됩니다."
  },
  "device_type": {
    "label": "디바이스 선택"
  },
  "page_type": {
    "label": "관련 페이지",
    "placeholder": "페이지를 선택해주세요."
  },
  "page_path": {
    "label": "관련 페이지 URL",
    "placeholder": "쇼핑몰 주소를 제외한 URL을  입력해주세요.",
    "tooltip": "/board/review.html 와 같이 쇼핑몰 주소를 제외한 URL을 입력해주세요.\n예를들어 페이지 URL이 http://www.cre.ma/board/review.html라면\n/board/review.html 를 입력해야합니다.",
    "error": "잘못된 형식의 url입니다."
  },
  "element_selector": {
    "label": "숨김 위젯 입력",
    "placeholder": "숨김처리할 위젯의 CSS selector를 입력해주세요."
  }
}
</i18n>
