import $ from 'jquery';

export default value => {
  try {
    $(value);
    return true;
  } catch {
    return false;
  }
};
