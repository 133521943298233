var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{staticClass:"fit-size-feedback-products",attrs:{"is-loading":_vm.isLoading}},[_c('AppSearchbar',{on:{"submit":_vm.searchResource},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('li',[_c('AppSearchWithType',{attrs:{"search-types":_vm.searchTypes},model:{value:(_vm.searchWithType),callback:function ($$v) {_vm.searchWithType=$$v},expression:"searchWithType"}})],1),_c('li',[_c('AppButtonToggle',{attrs:{"label":_vm.$t('app.advanced_search')},model:{value:(_vm.advancedSearchVisible),callback:function ($$v) {_vm.advancedSearchVisible=$$v},expression:"advancedSearchVisible"}})],1)]},proxy:true},{key:"advanced-search",fn:function(){return [_c('AppAdvancedSearch',{attrs:{"visible":_vm.advancedSearchVisible,"sections":_vm.advancedSearchSections},on:{"submit":_vm.searchResource},model:{value:(_vm.resourceParams),callback:function ($$v) {_vm.resourceParams=$$v},expression:"resourceParams"}})]},proxy:true}])}),_c('AppResourceTable',{attrs:{"enable-refresh":"","resources":_vm.products_with_review_options,"columns":_vm.columns,"rows":_vm.rows},on:{"refresh":_vm.refreshResource,"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
var value = ref.value;
return [(column == 'feedback_last_updated_at')?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            ((_vm.$t(
              'fit_size_feedback_products.feedback_last_updated_at'
            )) + ": " + (_vm.formatDateTime(value)))
          ),expression:"\n            `${$t(\n              'fit_size_feedback_products.feedback_last_updated_at'\n            )}: ${formatDateTime(value)}`\n          "}]},[_c('div',{staticClass:"FitSizeFeedbackProducts__date"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]),_c('div',{staticClass:"FitSizeFeedbackProducts__detail"},[_c('AppBadge',{attrs:{"label":_vm.$t('app.detail_info')}})],1)])]:(column == 'max_fit_score')?[(value)?_c('div',[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              message: _vm.fitScorePercentagesText(row.fit_score_percentages),
              textAlign: 'left',
              horizontal: true
            }),expression:"{\n              message: fitScorePercentagesText(row.fit_score_percentages),\n              textAlign: 'left',\n              horizontal: true\n            }"}]},[_c('div',{staticClass:"FitSizeFeedbackProducts__max_fit_score"},[_vm._v(" "+_vm._s(((value.label) + " " + (value.value) + "%"))+" ")]),_c('div',{staticClass:"FitSizeFeedbackProducts__detail"},[_c('AppBadge',{attrs:{"label":_vm.$t('app.detail_info')}})],1)])]):_c('div',[_vm._v(" - ")])]:(column == 'fit_messages_count')?[_c('div',[_vm._v(_vm._s(("" + (value > 0 ? _vm.$t('unit.count', [value]) : '-'))))])]:(column == 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('app.detail_breakdown')},on:{"click":function($event){return _vm.openFitSizeFeedbackProductDialog(row.product)}}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }