import { constructEnum } from '@/lib/enum';

export default constructEnum('ChannelProductRankType', {
  DESIGN: 70,
  FIT: 80,
  FABRIC: 90,
  STYLE: 100,
  WEAR: 110,
  COLOR: 120,
  LENGTH: 130,
  SIZE: 140
});
