<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #default>
        <li>
          <AppButtonSyncProduct
            :calculate-product-data="currentBrand.use_product_data"
          />
        </li>
      </template>

      <template #right>
        <li>
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="SEARCH_TYPES"
          />
        </li>
        <li>
          <AppDateRangeWithType
            v-model="dateRangeWithType"
            :date-types="DATE_TYPES"
            @apply="searchResource"
          />
        </li>
        <li>
          <AppButtonToggle
            v-model="isAdvancedSearchVisible"
            :label="$t('app.advanced_search')"
          />
        </li>
      </template>

      <template #advanced-search>
        <AppAdvancedSearch
          v-model="resourceParams"
          :visible="isAdvancedSearchVisible"
          :sections="advancedSearchSections"
          @submit="searchResource"
        >
          <template #group="{ id }">
            <template v-if="id === 'org_price_range'">
              <div class="AppForm__group-field">
                <i18n :path="currencyKey">
                  <span>
                    <AppNumberInput
                      v-model="resourceParams.min_org_price"
                      inline
                      :digits="6"
                    />
                    ~&nbsp;
                    <AppNumberInput
                      v-model="resourceParams.max_org_price"
                      inline
                      :digits="6"
                    />
                  </span>
                </i18n>
              </div>
            </template>
            <template v-else-if="id === 'sub_brand_ids'">
              <div class="AppForm__group-field">
                <AppSelectSearchable
                  :title="$t('sub_brand_ids.select')"
                  :placeholder="$t('sub_brand_ids.placeholder')"
                  :items="subBrands"
                  :columns="[{ id: 'name', label: $t('sub_brand_ids.name') }]"
                  :search-types="[
                    { value: 'name', label: $t('sub_brand_ids.name') }
                  ]"
                  multiple
                  @search="searchSubBrands"
                  @change="
                    $set(resourceParams, 'sub_brand_ids', $event.join(','))
                  "
                />
              </div>
            </template>
            <template v-else-if="id === 'final_price_range'">
              <div class="AppForm__group-field">
                <i18n :path="currencyKey">
                  <span>
                    <AppNumberInput
                      v-model="resourceParams.min_final_price"
                      inline
                      :digits="6"
                    />
                    ~&nbsp;
                    <AppNumberInput
                      v-model="resourceParams.max_final_price"
                      inline
                      :digits="6"
                    />
                  </span>
                </i18n>
              </div>
            </template>
            <template v-else-if="id === 'misc'">
              <div class="AppForm__group-field">
                <AppHashSelectButton
                  v-model="resourceParams"
                  :select-buttons="[
                    {
                      id: 'misc',
                      options: [
                        {
                          label: $t('search.misc.not_deleted'),
                          value: 'not_deleted'
                        },
                        { label: $t('search.misc.deleted'), value: 'deleted' }
                      ]
                    }
                  ]"
                />
              </div>
            </template>
          </template>
        </AppAdvancedSearch>
      </template>
    </AppSearchbar>

    <AppResourceTable
      table-id="products-table"
      enable-refresh
      :resources="products"
      :columns="columns"
      :rows="rows"
      @refresh="refreshResource"
      @paginate="paginate"
    >
      <template #cell="{ row, column, value, rowIndex }">
        <template v-if="column === 'code'">
          <div>{{ row.code }}</div>
        </template>
        <template v-else-if="column === 'price'">
          <div class="table-line">
            {{ $t('org_price') }} {{ row.org_price_cents | formatCurrency }}
          </div>
          <div class="table-line">
            {{ $t('final_price') }} {{ row.final_price_cents | formatCurrency }}
          </div>
        </template>
        <template v-else-if="column === 'product_category_product_type'">
          <AppBadge
            v-if="row.product_category_product_type_id"
            badge-style="grey-outline"
            :label="
              productCategoryProductTypeNamesMap[
                row.product_category_product_type_id
              ]
            "
          />
          <template v-else>{{ '-' }}</template>
        </template>
        <template v-else-if="column === 'categories'">
          <template v-if="!value.length">-</template>
        </template>
        <template v-else-if="column === 'actions'">
          <AppButton
            :label="$t('actions.copy_review')"
            @click="
              openDialog([
                'ProductCopyReviewsDialog',
                { id: row.id, name: row.name }
              ])
            "
          />
          <AppButton
            :label="$t('actions.edit_product')"
            @click="clickEditProductButton(row, rowIndex)"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceView from '@/mixins/ResourceView';
import ProductStatusReason from '@/enums/ProductStatusReason';

export default {
  name: 'ProductProducts',
  mixins: [ResourceView],
  data() {
    return {
      SEARCH_TYPES: ['name', 'code', 'id'].map(value => ({
        value,
        label: this.$t(`product_search_type.${value}`)
      })),
      DATE_TYPES: ['created_at'].map(value => ({
        value,
        label: this.$t(`product_date_type.${value}`)
      })),
      isAdvancedSearchVisible: [
        'min_org_price',
        'max_org_price',
        'min_final_price',
        'max_final_price',
        'sub_brand_ids'
      ].some(k => k in this.$route.query),
      productCategoryProductTypeNamesMap: {},
      isLoading: true,
      products: nullResources
    };
  },
  computed: {
    ...mapState('session', ['currentBrand', 'brandSettings', 'reviewSettings']),
    ...mapState('review', ['subBrands']),
    ...mapGetters('session', ['currencyKey']),
    ...mapGetters('session', ['isShopifyBrand']),
    ...mapGetters('productCategory', ['productCategoryNamesMap']),
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      search_type: 'name',
      date_type: 'created_at',
      misc: null
    }),
    advancedSearchSections() {
      return [
        {
          id: 'sub_brand',
          groups: [
            ...(this.brandSettings.show_sub_brand
              ? [
                  {
                    id: 'sub_brand_ids',
                    label: this.$t('sub_brand_ids.title')
                  }
                ]
              : [])
          ]
        },
        {
          id: 'basic',
          groups: [
            { id: 'org_price_range', label: this.$t('search.org_price_range') },
            {
              id: 'final_price_range',
              label: this.$t('search.final_price_range')
            },
            {
              id: 'misc',
              label: this.$t('search.misc.label')
            }
          ]
        }
      ];
    },
    columns() {
      return [
        { id: 'code', label: this.$t('table_header.code') },
        {
          id: 'product',
          label: this.$t('table_header.product'),
          type: 'product'
        },
        ...(this.brandSettings.show_sub_brand
          ? [
              {
                id: 'sub_brand_name',
                label: this.$t('table_header.sub_brand_name')
              }
            ]
          : []),
        { id: 'price', label: this.$t('table_header.price') },
        {
          id: 'options',
          label: this.$t('table_header.options'),
          type: 'pre',
          align: 'left'
        },
        ...(this.isShopifyBrand
          ? []
          : [
              {
                id: 'categories',
                type: 'badges',
                label: this.$t('table_header.categories')
              }
            ]),
        {
          id: 'status',
          type: 'badges',
          label: this.$t('table_header.status')
        },
        ...(this.reviewSettings.use_product_category_product_type
          ? [
              {
                id: 'product_category_product_type',
                label: this.$t('table_header.product_category_product_type')
              }
            ]
          : []),
        { id: 'actions', label: this.$t('table_header.actions') }
      ];
    },
    rows() {
      return this.products.items.map(item => ({
        ...item,
        product: item,
        options: item.options
          .map(
            ({ option_type, option_values }) =>
              `${option_type} - ${option_values.join(', ')}`
          )
          .join('\n'),
        categories: item.category_ids.map(id => ({
          label: this.productCategoryNamesMap[id],
          style: 'grey-outline'
        })),
        status: [
          ...this.getProductStatuses(item),
          this.productReviewDisplayStatus(item)
        ]
      }));
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    dateRangeWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    }
  },
  methods: {
    ...mapMutations('review', ['SET_SUB_BRANDS']),
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      const init = this.products.isNull ? 1 : null;
      api
        .get('/product/products', { params: { ...params, init } })
        .then(({ data }) => {
          this.products = data.products;
          if (data.product_category_product_types) {
            this.setProductCategoryProductTypeNames(data);
          }
          if (init) this.SET_SUB_BRANDS(data.sub_brands || []);
        })
        .finally(() => (this.isLoading = false));
    },
    searchSubBrands({ params, onSuccess, onFailure }) {
      api
        .get('/sub_brands', { params })
        .then(({ data }) => onSuccess(data.sub_brands))
        .catch(onFailure);
    },
    setProductCategoryProductTypeNames({ product_category_product_types }) {
      product_category_product_types.forEach(({ id, product_category_id }) => {
        this.productCategoryProductTypeNamesMap[id] =
          this.productCategoryNamesMap[product_category_id] ||
          this.$t('unselected_categories');
      });
    },
    clickEditProductButton({ id, name }, index) {
      const args = { id, name };
      this.openDialog(['ProductProductEditDialog', args]).then(eventBus =>
        eventBus.$on('save', product =>
          this.$set(this.products.items, index, product)
        )
      );
    },
    getProductStatuses({ deleted, display, sold_out }) {
      const status = { deleted, sold_out, display, not_display: !display };
      const productStatusNames = [
        'deleted',
        'sold_out',
        'display',
        'not_display'
      ];
      return productStatusNames
        .filter(name => status[name])
        .map(name => ({
          label: this.$t(`status.${name}.label`),
          style: this.$t(`status.${name}.style`)
        }));
    },
    productReviewDisplayStatus(item) {
      const tooltip = this.productReviewDisplayTooltip(item);
      const statusName = tooltip ? 'review_not_display' : 'review_display';
      return {
        label: this.$t(`status.${statusName}.label`),
        style: this.$t(`status.${statusName}.style`),
        tooltip
      };
    },
    productReviewDisplayTooltip({ product_status_reason }) {
      return product_status_reason !== ProductStatusReason.REVIEW_DISPLAY
        ? ProductStatusReason.t(product_status_reason)
        : '';
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .products-table__code {
    flex: 1 0 64px;
  }

  .products-table__product {
    flex: 1 0 40px;
  }

  .products-table__price {
    flex: 1 0 112px;
  }

  .products-table__options {
    flex: 1 0 68px;
  }

  .products-table__brand-category-product-type {
    flex: 1 0 184px;
  }

  .products-table__actions {
    flex: 1 0 184px;
  }
}
</style>

<i18n locale="ko">
{
  "search": {
    "org_price_range": "소비자가 범위",
    "final_price_range": "판매가 범위",
    "misc": {
      "label": "상품 상태",
      "not_deleted": "삭제되지 않음",
      "deleted": "삭제됨"
    }
  },
  "sub_brand_ids": {
    "title": "서브 브랜드",
    "placeholder": "서브 브랜드를 선택해주세요.",
    "select": "서브 브랜드 선택",
    "name": "서브 브랜드명"
  },
  "product_search_type": {
    "name": "상품명",
    "code": "상품번호",
    "id": "상품 ID"
  },
  "product_date_type": {
    "created_at": "등록일"
  },
  "table_header": {
    "code": "상품번호",
    "product": "상품",
    "price": "가격",
    "options": "상품 옵션",
    "product_category_product_type": "추가 정보 적용 카테고리",
    "actions": "상품 관리",
    "sub_brand_name": "서브 브랜드",
    "categories": "카테고리",
    "status": "상태"
  },
  "actions": {
    "copy_review": "리뷰 복사",
    "edit_product": "상품 수정"
  },
  "status": {
    "deleted": {
      "label": "삭제됨",
      "style": "red-outline",
      "tooltip": "상품 정보 동기화 결과\n쇼핑몰 솔루션에서 삭제된 상품입니다."
    },
    "sold_out": {
      "label": "품절",
      "style": "mango-outline"
    },
    "display": {
      "label": "진열",
      "style": "mint-green"
    },
    "not_display": {
      "label": "미진열",
      "style": "default"
    },
    "review_display": {
      "label": "리뷰 진열",
      "style": "mint-green-outline"
    },
    "review_not_display": {
      "label": "리뷰 숨김",
      "style": "default"
    }
  },
  "org_price": "소비자가",
  "final_price": "판매가",
  "unselected_categories": "선택하지 않은 모든 카테고리"
}
</i18n>
