var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.name,"form-props":Object.assign({}, _vm.formProps, {objectId: 'product'}),"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
var value = ref.value;
return [(id === 'image')?_c('div',{staticClass:"AppForm__group-field"},[_c('AppImage',{attrs:{"src":_vm.product.image_url}})],1):(id === 'discount_price_cents')?_c('AppData',{staticClass:"AppForm__group-field",attrs:{"type":"currency","value":value}}):(id === 'product_options')?_c('div',{staticClass:"AppForm__group-field"},[_c('div',{staticClass:"table-head-control"},[_c('AppButton',{attrs:{"label":_vm.$t('product_options.add_option_button'),"disabled":!_vm.unselectedOptionTypeId},on:{"click":_vm.addProductOption}})],1),_c('AppTable',{attrs:{"table-id":"product-options-table","columns":[
          { id: 'option_type', label: _vm.$t('product_options.option_type') },
          { id: 'option_values', label: _vm.$t('product_options.option_values') },
          { id: 'actions', label: _vm.$t('app.actions') }
        ],"rows":_vm.productOptions,"no-data":_vm.$t('product_options.no_data')},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
        var row = ref.row;
        var rowIndex = ref.rowIndex;
        var column = ref.column;
return [(column.id === 'option_type')?[_c('AppSelect',{staticClass:"ProductProductEditDialog__option-type-select",attrs:{"options":_vm.productOptionTypeOptions(row.option_type_id),"value":row.option_type_id},on:{"change":function($event){return _vm.changeProductOption(rowIndex, $event)}}})]:(column.id === 'option_values')?[_c('AppSelectProductOptionValue',{attrs:{"product-option-type-id":row.option_type_id,"product-option-values":row.option_values,"multiple":""},on:{"change-items":function($event){return _vm.changeProductOptionValues(rowIndex, $event)}}})]:(column.id === 'actions')?[_c('AppButton',{attrs:{"button-style":"red-outline","label":_vm.$t('app.delete')},on:{"click":function($event){return _vm.removeProductOption(rowIndex)}}})]:_vm._e()]}}],null,true)}),_c('AppHiddenInputArray',{attrs:{"name":"product[product_option_value_ids][]","values":_vm.product.product_option_value_ids}})],1):(id === 'colors')?_c('div',{staticClass:"AppForm__group-field"},_vm._l((_vm.product.color_chips),function(colors,i){return _c('AppColorChip',{key:i,attrs:{"colors":colors}})}),1):_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }