<template>
  <SlotModalForm
    class="InstallRequestRenewalSlotMainBottomDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #head>
      <SlotInfoCard :image-url="cardImageUrl" :name="cardName" />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'pc_main_bottom'">
        <SlotTable
          :device-type="WidgetDeviceType.PC"
          :slot-type="InstallSlotType.MAIN_BOTTOM"
        />
      </template>
      <template v-else-if="id === 'mobile_main_bottom'">
        <SlotTable
          :device-type="WidgetDeviceType.MOBILE"
          :slot-type="InstallSlotType.MAIN_BOTTOM"
        />
      </template>
    </template>
  </SlotModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations, mapGetters } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import SlotModalForm from './components/SlotModalForm';
import SlotInfoCard from './components/SlotInfoCard';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';

export default {
  name: 'InstallRequestRenewalSlotMainBottomDialog',
  components: { SlotModalForm, SlotInfoCard },
  mixins: [DialogFormView],
  data() {
    return {
      cardImageUrl:
        'https://assets.cre.ma/m/admin/v2/install_request/main_bottom_slot@3x.png',
      oldInstallations: []
    };
  },
  computed: {
    ...mapState('installRequest', ['installDeviceTypes']),
    ...mapGetters('installRequest', ['currentInstallations']),
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    InstallSlotType() {
      return InstallSlotType;
    },
    cardName() {
      return this.$t('card_name');
    },
    formSections() {
      return [
        {
          id: 'bottom_slot',
          label: this.$t('select_widget'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('select_widget_tip')
            },
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
              ? [
                  {
                    id: 'pc_main_bottom',
                    label: this.$t('app.pc')
                  }
                ]
              : []),
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
              ? [
                  {
                    id: 'mobile_main_bottom',
                    label: this.$t('app.mobile')
                  }
                ]
              : [])
          ]
        }
      ];
    },
    isFormDataChanged() {
      const orgSortedInstallations = _.sortBy(this.oldInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const sortedInstallations = _.sortBy(this.currentInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const result = !_.isEqual(orgSortedInstallations, sortedInstallations);
      return result;
    }
  },
  created() {
    this.SET_CURRENT_PAGE_TYPE(WidgetPageType.MAIN);
    this.SET_CURRENT_SLOT_TYPES([InstallSlotType.MAIN_BOTTOM]);
    this.oldInstallations = this.currentInstallations || [];
  },
  methods: {
    ...mapMutations('installRequest', [
      'ADD_INSTALLATIONS',
      'REMOVE_INSTALLATION',
      'SET_CURRENT_PAGE_TYPE',
      'SET_CURRENT_SLOT_TYPES'
    ]),
    beforeCloseDialog() {
      this.currentInstallations.forEach(installation =>
        this.REMOVE_INSTALLATION(installation)
      );
      this.ADD_INSTALLATIONS(this.oldInstallations);
    },
    submit() {
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppForm__group:last-child {
    margin-top: 32px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "sub_title": "메인 페이지",
  "card_name": "페이지 최하단",
  "select_widget": "위젯 선택",
  "select_widget_tip": "설치할 위젯을 선택해 주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해주세요."
}
</i18n>
