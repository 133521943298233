import { constructEnum } from '@/lib/enum';

export default constructEnum('LogType', {
  NONE: 0,
  REVIEW_MILEAGE: 10,
  GIVE_MILEAGE: 11,
  CANCEL_MILEAGE: 12,
  CALCULATE_MILEAGE: 13,
  COMMON_SETTINGS: 20,
  ADVANCED_SETTINGS: 21,
  BASIC_SETTINGS: 22,
  WIDGET_SETTINGS: 30,
  CREATE_WIDGET: 31,
  UPDATE_WIDGET: 32,
  DESTROY_WIDGET: 33,
  CREATE_SMART_INSTALLATION: 41,
  DESTROY_SMART_INSTALLATION: 42,
  UPDATE_SMART_INSTALLATION: 43,
  BIZ_MESSAGE: 50,
  BIZ_MESSAGE_TEMPLATE_CREATED: 51,
  BIZ_MESSAGE_TEMPLATE_DELETED: 52,
  BIZ_MESSAGE_CAMPAIGN_UPDATED: 53
});
