<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="subTitle"
    :form-props="{
      ...formProps
    }"
    v-on="formEvents"
  >
    <template #group="{ id, selectOptions, errors }">
      <div v-if="id === 'popular'" class="AppForm__group-field">
        <i18n :path="recommendationI18nPath(id)">
          <AppNumberInput
            v-model="filterOptions.recent_days"
            class="app-input-inline app-input-inline--left"
            :digits="3"
            :invalid="!!errors.recent_days"
            @change="validateField('recent_days')"/>
          <AppSelect
            v-model="filterOptions.recommendation_method"
            class="app-input-inline app-input-inline--left"
            :options="selectOptions"
        /></i18n>
        <AppFormError :error="errors.recent_days" />
      </div>
      <div v-else-if="id === 'new_registered'" class="AppForm__group-field">
        <i18n :path="recommendationI18nPath(id)">
          <AppSelect
            v-model="filterOptions.use_registered_days"
            class="app-input-inline"
            :options="selectOptions"/>
          <AppNumberInput
            v-model="filterOptions[daysFieldName]"
            class="app-input-inline app-input-inline--left"
            :digits="3"
            :invalid="!!errors[daysFieldName]"
            @change="validateField(daysFieldName)"
        /></i18n>
        <AppFormError :error="errors[daysFieldName]" />
      </div>
      <div
        v-else-if="id === 'same_category_popular'"
        class="AppForm__group-field"
      >
        <i18n :path="recommendationI18nPath(id)">
          <AppNumberInput
            v-model="filterOptions.recent_days"
            class="app-input-inline app-input-inline--left"
            :digits="3"
            :invalid="!!errors.recent_days"
            @change="validateField('recent_days')"/>
          <AppSelect
            v-model="filterOptions.recommendation_method"
            class="app-input-inline app-input-inline--left"
            :options="selectOptions"
        /></i18n>
        <AppFormError :error="errors.recent_days" />
      </div>
      <div
        v-else-if="id === 'same_category_new_registered'"
        class="AppForm__group-field"
      >
        <i18n :path="recommendationI18nPath(id)">
          <AppSelect
            v-model="filterOptions.use_registered_days"
            class="app-input-inline app-input-inline--left"
            :options="selectOptions"/>
          <AppNumberInput
            v-model="filterOptions[daysFieldName]"
            class="app-input-inline app-input-inline--left"
            :digits="3"
            :invalid="!!errors[daysFieldName]"
            @change="validateField(daysFieldName)"
        /></i18n>
        <AppFormError :error="errors[daysFieldName]" />
      </div>
      <div
        v-else-if="id === 'clicked_by_clicked_user'"
        class="AppForm__group-field"
      >
        <i18n :path="recommendationI18nPath(id)">
          <AppNumberInput
            v-model="filterOptions.recent_days"
            class="app-input-inline app-input-inline--left"
            :digits="3"
            :invalid="!!errors.recent_days"
            @change="validateField('recent_days')"
        /></i18n>
        <AppFormError :error="errors.recent_days" />
      </div>
      <div
        v-else-if="id === 'purchased_by_clicked_user'"
        class="AppForm__group-field"
      >
        <i18n :path="recommendationI18nPath(id)">
          <AppNumberInput
            v-model="filterOptions.recent_days"
            class="app-input-inline app-input-inline--left"
            :digits="3"
            :invalid="!!errors.recent_days"
            @change="validateField('recent_days')"
          />
        </i18n>
        <AppFormError :error="errors.recent_days" />
      </div>
      <div v-else-if="id === 'purchased_together'" class="AppForm__group-field">
        <i18n :path="recommendationI18nPath(id)">
          <AppNumberInput
            v-model="filterOptions.recent_days"
            class="app-input-inline app-input-inline--left"
            :digits="3"
            :invalid="!!errors.recent_days"
            @change="validateField('recent_days')"
          />
        </i18n>
        <AppFormError :error="errors.recent_days" />
      </div>
      <template v-else-if="id === 'high_scored_products_in_category'">
        <i18n
          path="high_scored_products_in_category_recommendation_methods.field1"
          tag="div"
          class="AppForm__group-field"
        >
          <AppNumberInput
            v-model="filterOptions.recent_days"
            class="app-input-inline app-input-inline--left"
            :digits="3"
            :invalid="!!errors.recent_days"
            @change="validateField('recent_days')"
          /><AppNumberInput
            v-model="filterOptions.minimum_purchased_count"
            class="app-input-inline app-input-inline--left"
            :digits="3"
            :invalid="!!errors.minimum_purchased_count"
            @change="validateField('minimum_purchased_count')"
          />
        </i18n>
        <i18n
          path="high_scored_products_in_category_recommendation_methods.field2"
          tag="div"
          class="AppForm__group-field"
        >
          <AppSelect
            v-model="filterOptions.review_score"
            class="app-input-inline app-input-inline--left"
            :options="selectOptions"
          />
          <AppNumberInput
            v-model="filterOptions.reviews_count"
            class="app-input-inline app-input-inline--left"
            :digits="3"
            :invalid="!!errors.reviews_count"
            @change="validateField('reviews_count')"
          />
        </i18n>
        <AppFormError
          :error="
            errors.recent_days ||
              errors.minimum_purchased_count ||
              errors.reviews_count
          "
        />
      </template>
      <template v-else-if="id === 'review_keyword'">
        <i18n
          path="review_keyword_recommendation_methods.field1"
          tag="div"
          class="AppForm__group-field"
        >
          <AppSelect
            v-model="filterOptions.recent_days"
            class="app-input-inline app-input-inline--left"
            :options="
              [7, 15, 30, 60, 150].map(value => ({ value, label: value }))
            "
          />
        </i18n>
        <div class="AppForm__group-field">
          {{ $t('review_keyword_recommendation_methods.field2') }}
        </div>
      </template>
      <template v-else-if="id === 'review_keyword_legacy'">
        <i18n
          path="review_keyword_recommendation_methods_legacy.field1"
          tag="div"
          class="AppForm__group-field"
        >
          <AppNumberInput
            v-model="filterOptions.recent_days"
            class="app-input-inline app-input-inline--left"
            :digits="3"
            :invalid="!!errors.recent_days"
            @change="validateField('recent_days')"/><AppNumberInput
            v-model="filterOptions.minimum_purchased_count"
            class="app-input-inline app-input-inline--left"
            :digits="3"
            :invalid="!!errors.minimum_purchased_count"
            @change="validateField('minimum_purchased_count')"
        /></i18n>
        <div class="AppForm__group-field">
          {{ $t('review_keyword_recommendation_methods_legacy.field2') }}
        </div>
        <AppFormError
          :error="errors.recent_days || errors.minimum_purchased_count"
        />
      </template>
      <template v-else-if="id === 'potential_products'">
        <i18n
          path="potential_products_recommendation_methods.field1"
          tag="div"
          class="AppForm__group-field"
        >
          <AppSelect
            v-model="filterOptions.recent_days"
            class="app-input-inline app-input-inline--left"
            :options="
              [7, 15, 30, 60, 150].map(value => ({ value, label: value }))
            "
          />
        </i18n>
        <div class="AppForm__group-field">
          {{ $t('potential_products_recommendation_methods.field2') }}
        </div>
      </template>
      <template v-else-if="id === filterOptionsString('selected_category_ids')">
        <AppSelectProductCategory
          v-model="filterOptions.selected_category_ids"
          class="AppForm__group-field"
          multiple
        />
        <div class="AppForm__sub-group">
          <div class="AppForm__sub-group-item">
            <label
              :class="[
                'AppForm__sub-group-title',
                {
                  'AppForm__sub-group-title--disabled': disalbedPopularCategoryOrderedAutomatically
                }
              ]"
            >
              {{ $t('sort_category_in_popular_order.label') }}
            </label>
            <AppSelectRadio
              v-model="filterOptions.sort_category_in_popular_order"
              :disabled="disalbedPopularCategoryOrderedAutomatically"
              :options="SORT_CATEGORY_IN_POPULAR_ORDER_OPTIONS"
            />
            <AppFormHint
              :message="$t('sort_category_in_popular_order.hint')"
              :disabled="disalbedPopularCategoryOrderedAutomatically"
            />
          </div>
        </div>
      </template>
    </template>
    <template #group-sub-item="{ group, value, errors }">
      <template v-if="group.id === 'use_displayable_category_ids'">
        <template v-if="value">
          <AppSelectProductCategory
            v-model="formObject.displayable_category_ids"
            multiple
            :disabled="!formObject.use_displayable_category_ids"
            :invalid="!!errors.displayable_category_ids"
            @change="validateField('displayable_category_ids')"
          />
          <div
            class="TargetWidgetCampaignsWidgetRecommendationUnitsFormDialog__category-hint"
          >
            <AppFormHint
              :message="$t('displayable_category_ids.caption')"
              :disabled="!formObject.use_displayable_category_ids"
            />
            <AppInternalLink
              to=""
              @click="openUndisplayableCategoryIdsSettings"
            >
              <AppExternalLinkIcon size="small" />
            </AppInternalLink>
          </div>
          <AppFormError :error="errors.displayable_category_ids" />
        </template>
      </template>
      <template v-else-if="group.id === 'title_type'">
        <template v-if="isTextTitle(value)">
          <AppKeywordTextarea
            v-model="formObject.main_title_text"
            :keywords="titleKeywords"
            :placeholder="$t('text_title.placeholder')"
            :single-line="true"
            :disabled="!isTextTitleSelected"
            :invalid="!!errors.main_title_text"
            @blur="validateField('main_title_text')"
            @change="validateField('main_title_text')"
          />
          <AppFormError :error="errors.main_title_text" />
        </template>
        <template v-else>
          <AppImageInput
            :image-url="formObject.title_image"
            :placeholder="$t('title_image.placeholder')"
            :invalid="!!errors.title_image"
            :disabled="isTextTitleSelected"
            not-removable
            :caption="$t('title_image.caption')"
            @change="changeTitleImage"
          />
          <AppFormError :error="errors.title_image" />
        </template>
      </template>
      <template
        v-else-if="
          group.id === filterOptionsString('use_auto_review_keywords_selection')
        "
      >
        <template v-if="value">
          <div>{{ $t('maximum_keywords_count') }}</div>
          <AppNumberInput
            v-model="filterOptions.maximum_keywords_count"
            :digits="3"
            :invalid="!!errors.maximum_keywords_count"
            :disabled="!filterOptions.use_auto_review_keywords_selection"
            @change="validateField('maximum_keywords_count')"
          />
          <AppFormError :error="errors.maximum_keywords_count" />
          <div class="AppForm__description">
            {{ $t('excluding_keyword_ids.label') }}
          </div>
          <AppMultipleSelect
            v-for="nlpCategoryType in nlpCategoryTypes"
            :key="nlpCategoryType.id"
            v-model="
              filterOptions.excluding_keyword_ids_map[nlpCategoryType.id]
            "
            :options="nlpCategoryType.reviewTagTypes"
            class="AppForm__group-field"
            :placeholder="
              $t('excluding_keyword_ids.placeholder', [nlpCategoryType.name])
            "
            :disabled="!filterOptions.use_auto_review_keywords_selection"
          />
          <AppFormHint :message="$t('excluding_keyword_ids.description')" />
        </template>
        <template v-else>
          <div
            v-for="nlpCategoryType in nlpCategoryTypes"
            :key="nlpCategoryType.id"
          >
            <AppMultipleSelect
              v-model="filterOptions.keyword_ids_map[nlpCategoryType.id]"
              :options="nlpCategoryType.reviewTagTypes"
              class="AppForm__group-field"
              :placeholder="
                $t('keyword_ids.placeholder', [nlpCategoryType.name])
              "
              :invalid="!!errors[`keyword_ids[${nlpCategoryType.id}]`]"
              :disabled="filterOptions.use_auto_review_keywords_selection"
              @change="validateField(`keyword_ids[${nlpCategoryType.id}]`)"
            />
            <AppFormError
              :error="errors[`keyword_ids[${nlpCategoryType.id}]`]"
            />
          </div>
          <AppFormHint :message="$t('keyword_ids.description')" />
        </template>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import DialogFormView from '@/mixins/DialogFormView';
import { mapActions, mapState, mapGetters } from 'vuex';
import TargetWidgetRecommendationFilterType from '@/enums/TargetWidgetRecommendationFilterType';
import TargetWidgetRecommendationTitleType from '@/enums/TargetWidgetRecommendationTitleType';
import TargetProductRecommendationFilterType from '@/enums/TargetProductRecommendationFilterType';
import TargetProductRecommendationPeriod from '@/enums/TargetProductRecommendationPeriod';
import ProductSortType from '@/enums/ProductSortType';
import BrandType from '@/enums/BrandType';

export default {
  name: 'TargetWidgetCampaignsWidgetRecommendationUnitsFormDialog',
  mixins: [DialogFormView],
  props: { recommendationUnit: { type: Object, required: true } },
  data() {
    return {
      orgFormObject: this.recommendationUnit,
      SORT_CATEGORY_IN_POPULAR_ORDER_OPTIONS: [
        {
          label: this.$t('sort_category_in_popular_order.false'),
          value: false
        },
        {
          label: this.$t('sort_category_in_popular_order.true'),
          value: true
        }
      ]
    };
  },
  computed: {
    ...mapState('session', [
      'currentBrand',
      'reviewSettings',
      'targetSettings'
    ]),
    ...mapState('targetCampaign', ['campaign']),
    ...mapGetters('targetCampaign', [
      'isReviewHighlightWidgetCampaign',
      'isCategoryPageWidgetCampaign'
    ]),
    campaignType() {
      return this.campaign.campaign_type;
    },
    maxDays() {
      return 999;
    },
    daysFieldName() {
      return this.filterOptions.use_registered_days
        ? 'registered_days'
        : 'updated_days';
    },
    isTitleRequired() {
      return !this.isCategoryPageWidgetCampaign;
    },
    formSections() {
      return [
        {
          groups: [
            ...this.groupsByCampaignType,
            ...this.groupsByFilterType,
            ...this.recommendSameSubBrandProductsGroups,
            {
              id: 'max_products',
              type: 'number',
              digits: 3,
              label: this.$t('max_products.label'),
              groupDescription: this.$t('max_products.description'),
              validate: [
                'required',
                { rule: 'min_max_integer', min: 1, max: 20 }
              ]
            },
            {
              id: 'title_type',
              type: 'select_radio',
              label: this.$t('title_type.label'),
              groupDescription: this.$t('title_type.description'),
              options: [
                {
                  label: this.$t('title_type.text'),
                  value: TargetWidgetRecommendationTitleType.TEXT
                },
                {
                  label: this.$t('title_type.image'),
                  value: TargetWidgetRecommendationTitleType.IMAGE
                }
              ],
              validate: this.isTitleRequired ? ['required'] : null,
              fields:
                this.formObject.title_type ===
                TargetWidgetRecommendationTitleType.TEXT
                  ? [
                      {
                        id: 'main_title_text',
                        validate: this.isTitleRequired
                          ? [
                              'required',
                              {
                                rule: 'keywords',
                                keywords: this.titleKeywords
                              }
                            ]
                          : null
                      }
                    ]
                  : [{ id: 'title_image', validate: ['required'] }]
            },
            {
              id: 'sub_title_text',
              label: this.$t('sub_title_text.label'),
              groupDescription: this.$t('sub_title_text.description'),
              type: 'keyword_textarea',
              placeholder: this.$t('sub_title_text.placeholder'),
              singleLine: true,
              keywords: this.keywords,
              validate: [{ rule: 'keywords', keywords: this.keywords }]
            }
          ]
        },
        ...this.advancedSettingsGroups
      ];
    },
    groupsByCampaignType() {
      if (this.isCategoryPageWidgetCampaign) {
        return this.displayableCategoryGroup;
      } else {
        return [];
      }
    },
    formSectionsMap() {
      const map = {
        [this.filterOptionsString('period_options')]: {
          label: this.$t('period_options.label'),
          type: 'multiple_select',
          validate: ['required'],
          options: TargetProductRecommendationPeriod.options(),
          groupDescription: this.$t('period_options.description'),
          required: true
        },
        [this.filterOptionsString('selected_category_ids')]: {
          label: this.$t('select_category.label'),
          type: 'select_product_category',
          multiple: true,
          validate: ['required'],
          groupDescription: this.$t('select_category.description')
        },
        new_registered: {
          label: this.$t('recommendation_condition'),
          selectOptions: [true, false].map(value => ({
            label: this.$t(`use_registered_days.${value}`),
            value
          })),
          fields: [
            {
              id: 'registered_days',
              value: this.filterOptions.registered_days,
              validate: [
                { rule: 'required' },
                { rule: 'lte', allowed: this.maxDays }
              ]
            },
            {
              id: 'updated_days',
              value: this.filterOptions.updated_days,
              validate: [
                { rule: 'required' },
                { rule: 'lte', allowed: this.maxDays }
              ]
            }
          ],
          required: true
        },
        show_review: {
          id: 'show_review',
          type: 'checkbox',
          label: this.$t('show_review.label'),
          groupDescription: this.$t('show_review.description'),
          groupTooltip: this.$t('show_review.tooltip')
        },
        show_manager_review: {
          id: 'show_manager_review',
          type: 'checkbox',
          label: this.$t('show_manager_review.label'),
          groupDescription: this.$t('show_manager_review.description'),
          superAdmin: true
        },
        [this.filterOptionsString('include_manager_review')]: {
          id: 'include_manager_review',
          type: 'checkbox',
          label: this.$t('include_manager_review.label'),
          groupDescription: this.$t('include_manager_review.description'),
          superAdmin: true
        },
        precompute_category_ids: {
          id: 'precompute_category_ids',
          type: 'select_product_category',
          label: this.$t('precompute_category_ids.label'),
          multiple: true,
          groupDescription: this.isReviewHighlightWidgetCampaign
            ? this.$t('precompute_category_ids.review_highlight_description')
            : this.$t('precompute_category_ids.description'),
          superAdmin: true
        }
      };

      _.forOwn(map, (_value, key) => (map[key].id = key));
      return map;
    },
    groupsByFilterType() {
      const { formSectionsMap } = this;
      switch (this.recommendationFilterType) {
        case TargetWidgetRecommendationFilterType.POPULAR:
          return [
            {
              id: 'popular',
              label: this.$t('recommendation_condition'),
              selectOptions: TargetProductRecommendationFilterType.options([
                TargetProductRecommendationFilterType.CLICK,
                TargetProductRecommendationFilterType.CART,
                TargetProductRecommendationFilterType.PURCHASE
              ]),
              fields: [
                {
                  id: 'recent_days',
                  value: this.filterOptions.recent_days,
                  validate: [
                    { rule: 'required' },
                    { rule: 'lte', allowed: this.maxDays }
                  ]
                }
              ],
              required: true
            }
          ];
        case TargetWidgetRecommendationFilterType.NEW_REGISTERED:
        case TargetWidgetRecommendationFilterType.NEW_REGISTERED_IN_CATEGORY:
          return [formSectionsMap['new_registered']];
        case TargetWidgetRecommendationFilterType.SAME_CATEGORY_POPULAR:
          return [
            {
              id: 'same_category_popular',
              label: this.$t('recommendation_condition'),
              selectOptions: TargetProductRecommendationFilterType.options([
                TargetProductRecommendationFilterType.CLICK,
                TargetProductRecommendationFilterType.CART,
                TargetProductRecommendationFilterType.PURCHASE
              ]),
              fields: [
                {
                  id: 'recent_days',
                  value: this.filterOptions.recent_days,
                  validate: [
                    { rule: 'required' },
                    { rule: 'lte', allowed: this.maxDays }
                  ]
                }
              ],
              required: true
            }
          ];
        case TargetWidgetRecommendationFilterType.SAME_CATEGORY_NEW_REGISTERED:
          return [
            {
              id: 'same_category_new_registered',
              label: this.$t('recommendation_condition'),
              selectOptions: [true, false].map(value => ({
                label: this.$t(`use_registered_days.${value}`),
                value
              })),
              fields: [
                {
                  id: 'registered_days',
                  value: this.filterOptions.registered_days,
                  validate: [
                    { rule: 'required' },
                    { rule: 'lte', allowed: this.maxDays }
                  ]
                },
                {
                  id: 'updated_days',
                  value: this.filterOptions.updated_days,
                  validate: [
                    { rule: 'required' },
                    { rule: 'lte', allowed: this.maxDays }
                  ]
                }
              ],
              required: true
            }
          ];
        case TargetWidgetRecommendationFilterType.RECENTLY_POPULAR:
          return [
            {
              id: 'recently_popular',
              label: this.$t('recommendation_condition'),
              type: 'static',
              value: this.$t('recently_popular')
            }
          ];
        case TargetWidgetRecommendationFilterType.CLICKED_BY_CLICKED_USER:
          return [
            {
              id: 'clicked_by_clicked_user',
              label: this.$t('recommendation_condition'),
              fields: [
                {
                  id: 'recent_days',
                  value: this.filterOptions.recent_days,
                  validate: [
                    { rule: 'required' },
                    { rule: 'lte', allowed: this.maxDays }
                  ]
                }
              ],
              required: true
            }
          ];
        case TargetWidgetRecommendationFilterType.PURCHASED_BY_CLICKED_USER:
          return [
            {
              id: 'purchased_by_clicked_user',
              label: this.$t('recommendation_condition'),
              fields: [
                {
                  id: 'recent_days',
                  value: this.filterOptions.recent_days,
                  validate: [
                    { rule: 'required' },
                    { rule: 'lte', allowed: this.maxDays }
                  ]
                }
              ],
              required: true
            }
          ];
        case TargetWidgetRecommendationFilterType.PURCHASED_TOGETHER:
          return [
            {
              id: 'purchased_together',
              label: this.$t('recommendation_condition'),
              fields: [
                {
                  id: 'recent_days',
                  value: this.filterOptions.recent_days,
                  validate: [
                    { rule: 'required' },
                    { rule: 'lte', allowed: this.maxDays }
                  ]
                }
              ],
              required: true
            }
          ];
        case TargetWidgetRecommendationFilterType.SIMILAR_SIZE:
          return [
            {
              id: 'similar_size',
              label: this.$t('recommendation_condition'),
              type: 'static',
              value: this.$t('similar_size')
            }
          ];
        case TargetWidgetRecommendationFilterType.HAND_PICKED_PRODUCTS:
          return [
            {
              id: 'hand_picked_products',
              label: this.$t('select_product'),
              type: 'select_product',
              products: this.formObject.hand_picked_products,
              multiple: true,
              sort: false,
              validate: ['required'],
              eventHandlers: {
                'change-items': products => {
                  this.$set(
                    this.formObject,
                    'hand_picked_products',
                    products.map(({ id, name }) => ({ id, name }))
                  );
                  this.$set(
                    this.filterOptions,
                    'hand_picked_product_ids',
                    products.map(({ id }) => id)
                  );
                }
              }
            }
          ];
        case TargetWidgetRecommendationFilterType.PERIODICAL_POPULAR_IN_CATEGORY:
        case TargetWidgetRecommendationFilterType.PERIODICAL_POPULAR:
          return [
            {
              id: this.filterOptionsString('recommendation_methods'),
              label: this.$t('recommendation_condition'),
              type: 'multiple_select',
              validate: ['required'],
              options: [
                TargetProductRecommendationFilterType.PURCHASE,
                TargetProductRecommendationFilterType.CLICK,
                TargetProductRecommendationFilterType.CART
              ].map(value => {
                const key = TargetProductRecommendationFilterType.key(value);
                return {
                  value,
                  label: this.$t(`popular_recommendation_methods.${key}`)
                };
              }),
              groupDescription: this.$t(
                'popular_recommendation_methods.description'
              ),
              required: true
            },
            formSectionsMap[this.filterOptionsString('period_options')]
          ];
        case TargetWidgetRecommendationFilterType.HIGH_SCORED_PRODUCTS_IN_CATEGORY:
          return [
            {
              id: 'high_scored_products_in_category',
              label: this.$t('recommendation_condition'),
              selectOptions: [3.0, 3.5, 4.0, 4.5, 5.0].map(value => ({
                label: value * this.reviewSettings.score_multiplier,
                value
              })),
              fields: [
                {
                  id: 'recent_days',
                  value: this.filterOptions.recent_days,
                  validate: [
                    { rule: 'required' },
                    { rule: 'lte', allowed: this.maxDays }
                  ]
                },
                {
                  id: 'minimum_purchased_count',
                  value: this.filterOptions.minimum_purchased_count,
                  validate: [{ rule: 'required' }, 'positive_integer']
                },
                {
                  id: 'reviews_count',
                  value: this.filterOptions.reviews_count,
                  validate: ['required', 'positive_integer']
                }
              ],
              required: true
            },
            {
              id: this.filterOptionsString('sort_type'),
              label: this.$t('sort_type.label'),
              type: 'select_radio',
              options: ProductSortType.options([
                ProductSortType.RECENTLY_CREATED,
                ProductSortType.MOST_SCORES,
                ProductSortType.MOST_REVIEWED
              ])
            },
            formSectionsMap['show_review'],
            formSectionsMap['show_manager_review']
          ];
        case TargetWidgetRecommendationFilterType.POPULAR_PRODUCTS_BY_CATEGORY:
          return [
            formSectionsMap[this.filterOptionsString('selected_category_ids')],
            {
              id: this.filterOptionsString('recommendation_method'),
              label: this.$t('recommendation_condition'),
              type: 'select',
              options: [
                TargetProductRecommendationFilterType.PURCHASE,
                TargetProductRecommendationFilterType.CLICK,
                TargetProductRecommendationFilterType.CART
              ].map(value => {
                const key = TargetProductRecommendationFilterType.key(value);
                return {
                  value,
                  label: this.$t(`popular_recommendation_methods.${key}`)
                };
              })
            },
            formSectionsMap[this.filterOptionsString('period_options')]
          ];
        case TargetWidgetRecommendationFilterType.NEW_REGISTERED_PRODUCTS_BY_CATEGORY:
          return [
            formSectionsMap[this.filterOptionsString('selected_category_ids')],
            formSectionsMap['new_registered']
          ];
        case TargetWidgetRecommendationFilterType.REVIEW_KEYWORD:
          return [
            {
              id: 'review_keyword',
              label: this.$t('recommendation_condition')
            },
            this.reviewKeywordSelectionSection,
            this.isReviewHighlightWidgetCampaign
              ? formSectionsMap[
                  this.filterOptionsString('include_manager_review')
                ]
              : formSectionsMap['show_review'],
            this.isReviewHighlightWidgetCampaign
              ? null
              : formSectionsMap['show_manager_review']
          ].filter(v => v);
        case TargetWidgetRecommendationFilterType.POTENTIAL_PRODUCTS:
          return [
            {
              id: 'potential_products',
              label: this.$t('recommendation_condition')
            },
            this.reviewKeywordSelectionSection,
            this.isReviewHighlightWidgetCampaign
              ? formSectionsMap[
                  this.filterOptionsString('include_manager_review')
                ]
              : formSectionsMap['show_review'],
            this.isReviewHighlightWidgetCampaign
              ? null
              : formSectionsMap['show_manager_review']
          ].filter(v => v);
        default:
          return [];
      }
    },
    recommendSameSubBrandProductsGroups() {
      if (this.currentBrand.brand_type !== BrandType.BRAND) return [];

      if (
        [
          TargetWidgetRecommendationFilterType.SAME_CATEGORY_POPULAR,
          TargetWidgetRecommendationFilterType.SAME_CATEGORY_NEW_REGISTERED,
          TargetWidgetRecommendationFilterType.PURCHASED_TOGETHER,
          TargetWidgetRecommendationFilterType.SIMILAR_SIZE,
          TargetWidgetRecommendationFilterType.PURCHASED_BY_CLICKED_USER,
          TargetWidgetRecommendationFilterType.CLICKED_BY_CLICKED_USER,
          TargetWidgetRecommendationFilterType.RECENTLY_POPULAR
        ].includes(this.recommendationFilterType)
      )
        return [
          {
            id: this.filterOptionsString('recommend_same_sub_brand_products'),
            type: 'checkbox',
            label: this.$t('recommend_same_sub_brand_products.label'),
            groupDescription: this.$t(
              'recommend_same_sub_brand_products.description'
            )
          }
        ];
      else return [];
    },
    productRankGroup() {
      if (this.formObject.show_product_ranks)
        return [
          {
            id: 'show_product_rank',
            label: this.$t('advanced.show_product_rank.label'),
            type: 'checkbox',
            groupDescription: this.$t('advanced.show_product_rank.description')
          }
        ];
      return [];
    },
    roundButtonGroup() {
      if (this.formObject.show_tab_buttons)
        return [
          {
            id: 'use_round_button',
            label: this.$t('advanced.tab_button_style.label'),
            type: 'select_radio',
            groupDescription: this.$t('advanced.tab_button_style.description'),
            options: [
              {
                label: this.$t(
                  'advanced.tab_button_style.use_round_button.true'
                ),
                value: true
              },
              {
                label: this.$t(
                  'advanced.tab_button_style.use_round_button.false'
                ),
                value: false
              }
            ]
          }
        ];
      return [];
    },
    advancedDisplayableCategoryGroup() {
      if (this.isReviewHighlightWidgetCampaign) {
        return this.displayableCategoryGroup;
      } else {
        return [];
      }
    },
    displayableCategoryGroup() {
      return [
        {
          id: 'use_displayable_category_ids',
          type: 'select_radio',
          label: this.$t('displayable_category_ids.label'),
          groupDescription: this.isReviewHighlightWidgetCampaign
            ? this.$t('displayable_category_ids.description')
            : '',
          options: [
            {
              label: this.$t('displayable_category_ids.all'),
              value: false
            },
            {
              label: this.$t('displayable_category_ids.selected'),
              value: true
            }
          ],
          validate: this.formObject.use_displayable_category_ids
            ? ['required']
            : null,
          fields: this.formObject.use_displayable_category_ids
            ? [
                {
                  id: 'displayable_category_ids',
                  validate: ['required']
                }
              ]
            : []
        },
        this.formSectionsMap['precompute_category_ids']
      ];
    },
    advancedSettingsGroups() {
      const advancedGroups = [
        ...this.advancedDisplayableCategoryGroup,
        ...this.productRankGroup,
        ...this.roundButtonGroup
      ];
      if (advancedGroups.length)
        return [
          {
            id: 'advanced',
            label: this.$t('app.advanced_settings'),
            groups: [
              {
                id: 'advanced_settings_info',
                label: '',
                type: 'infobox',
                value: this.$t('advanced.advanced_settings_info_html')
              },
              ...advancedGroups
            ]
          }
        ];

      return [];
    },
    reviewKeywordSelectionSection() {
      const i18nKey = `${TargetWidgetRecommendationFilterType.key(
        this.recommendationFilterType
      )}.use_auto_review_keywords_selection`;
      return {
        id: this.filterOptionsString('use_auto_review_keywords_selection'),
        label: this.$t(`${i18nKey}.label`),
        validate: ['required'],
        type: 'select_radio',
        required: true,
        options: [true, false].map(value => ({
          label: this.$t(`${i18nKey}.${value}`),
          value
        })),
        fields: [
          {
            id: 'maximum_keywords_count',
            value: this.filterOptions.maximum_keywords_count,
            validate: ['required', 'positive_integer']
          },
          ...this.nlpCategoryTypes.map(nlpCategoryType => ({
            id: `keyword_ids[${nlpCategoryType.id}]`,
            value: this.filterOptions.keyword_ids_map[nlpCategoryType.id],
            validate: !this.filterOptions.use_auto_review_keywords_selection
              ? ['required']
              : []
          }))
        ]
      };
    },
    subTitle() {
      return TargetWidgetRecommendationFilterType.tWithTitle(
        this.recommendationFilterType
      );
    },
    keywords() {
      const basicKeywords = ['user', 'user_id', 'brand'];
      if (
        [
          TargetWidgetRecommendationFilterType.CLICKED_BY_CLICKED_USER,
          TargetWidgetRecommendationFilterType.PURCHASED_BY_CLICKED_USER,
          TargetWidgetRecommendationFilterType.PURCHASED_TOGETHER,
          TargetWidgetRecommendationFilterType.SIMILAR_SIZE
        ].includes(this.recommendationFilterType)
      ) {
        basicKeywords.push('target_product');
      }
      if (
        [
          TargetWidgetRecommendationFilterType.POPULAR_PRODUCTS_BY_CATEGORY,
          TargetWidgetRecommendationFilterType.NEW_REGISTERED_PRODUCTS_BY_CATEGORY
        ].includes(this.recommendationFilterType)
      ) {
        basicKeywords.push('selected_categories');
      }
      if (
        [
          TargetWidgetRecommendationFilterType.SAME_CATEGORY_POPULAR,
          TargetWidgetRecommendationFilterType.SAME_CATEGORY_NEW_REGISTERED
        ].includes(this.recommendationFilterType) ||
        this.isCategoryPageWidgetCampaign
      ) {
        basicKeywords.push('target_category');
      }
      return basicKeywords.map(key => ({
        key,
        desc: this.$t(`keywords.${key}`),
        value: '%{key}'
      }));
    },
    isTextTitleSelected() {
      return (
        this.formObject.title_type === TargetWidgetRecommendationTitleType.TEXT
      );
    },
    recommendationFilterType() {
      return this.formObject.recommendation_config.recommendation_filter_type;
    },
    filterOptions() {
      return this.formObject.recommendation_config.filter_data.filter_options;
    },
    nlpCategoryTypes() {
      return this.campaign.nlp_category_types.map(nlpCategoryType => {
        const tagTypes = nlpCategoryType.review_tag_types.map(
          reviewTagType => ({
            value: reviewTagType.id,
            label: reviewTagType.name,
            priority:
              (this.recommendationFilterType ===
                TargetWidgetRecommendationFilterType.REVIEW_KEYWORD &&
                this.campaign.satisfaction_recommendable_review_tag_type_ids_map[
                  this.filterOptions.recent_days
                ]?.includes(reviewTagType.id)) ||
              (this.recommendationFilterType ===
                TargetWidgetRecommendationFilterType.POTENTIAL_PRODUCTS &&
                this.campaign.potential_recommendable_review_tag_type_ids_map[
                  this.filterOptions.recent_days
                ]?.includes(reviewTagType.id))
                ? 'primary'
                : 'default'
          })
        );
        const reviewTagTypes = [
          {
            label: tagTypes.filter(tagType => tagType.priority === 'primary')
              .length
              ? this.$t('keyword_information.recommendation')
              : this.$t('keyword_information.no_recommendation'),
            priority: 'info',
            value: null
          }
        ].concat(tagTypes);

        return {
          ...nlpCategoryType,
          reviewTagTypes
        };
      });
    },
    titleKeywords() {
      const keywords = this.keywords;
      if (
        [
          TargetWidgetRecommendationFilterType.REVIEW_KEYWORD,
          TargetWidgetRecommendationFilterType.POTENTIAL_PRODUCTS
        ].includes(this.recommendationFilterType)
      )
        return [
          ...keywords,
          {
            key: 'keyword',
            desc: this.$t('keywords.keyword'),
            value: '%{key}'
          }
        ];
      else return keywords;
    },
    disalbedPopularCategoryOrderedAutomatically() {
      return this.filterOptions.selected_category_ids.length < 2;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    isTextTitle(v) {
      return v === TargetWidgetRecommendationTitleType.TEXT;
    },
    submit() {
      this.eventBus.$emit('change', this.formObject);
      this.close(true);
    },
    changeTitleImage({ imageUrl }) {
      this.formObject.title_image = imageUrl;
      this.validateField('title_image');
    },
    filterOptionsString(option) {
      return 'recommendation_config.filter_data.filter_options.' + option;
    },
    recommendationI18nPath(id) {
      id = id.toUpperCase();
      return `target_widget_recommendation_filter_summary.${id}`;
    },
    openUndisplayableCategoryIdsSettings() {
      this.openDialog([
        'TargetCampaignSettingsWidgetUndisplayableCategoryIdsDialog',
        { campaign: this.campaign }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetWidgetCampaignsWidgetRecommendationUnitsFormDialog__category-hint {
  display: flex;
  align-items: flex-end;
  column-gap: 4px;
}
</style>

<i18n locale="ko">
{
  "title": "추천 항목 수정/추가",
  "max_products": {
    "label": "추천 상품 수",
    "description": "추천 대상 상품이 설정 값보다 많으면 설정된 상품 수 만큼만 노출합니다."
  },
  "displayable_category_ids": {
    "label": "노출 카테고리 선택",
    "description": "이 추천 항목을 노출할 카테고리를 설정합니다. 카테고리 페이지에 설치하는 경우에만 적용되는 설정입니다.",
    "all": "이 추천 항목을 전체 카테고리에 노출합니다.",
    "selected": "이 추천 항목을 선택한 카테고리에서만 노출합니다.",
    "caption": "해당 설정 및 [기타 설정 > 추천 기준 제외 카테고리] 설정으로 인해<br />추천할 항목이 하나도 없는 페이지에는 카테고리 인기 상품을 대체 항목으로 노출합니다.<br />상품 추천 위젯을 노출하고 싶지 않은 카테고리가 있다면 [기타 설정 > 위젯 사용 카테고리]에서 선택해주세요."
  },
  "precompute_category_ids": {
    "label": "사전 계산 카테고리",
    "description": "BG에서 미리 계산하여 위젯 노출 속도를 향상시킬 수 있습니다.",
    "review_highlight_description": "BG에서 미리 계산하여 위젯 노출 속도를 향상시킬 수 있습니다. 카테고리 페이지에 설치하는 경우에만 적용되는 설정입니다."
  },
  "title_type": {
    "label": "추천 항목 이름",
    "description": "추천 항목 상단에 노출할 이름을 설정합니다.",
    "text": "텍스트 입력",
    "image": "직접 이미지 업로드"
  },
  "keywords": {
    "user": "고객명",
    "user_id": "고객아이디",
    "brand": "쇼핑몰명",
    "keyword": "선택된 리뷰 키워드명",
    "target_product_categories": "상품이 속한 카테고리",
    "target_product": "상품 이름",
    "target_category": "카테고리명",
    "selected_categories": "선택한 카테고리명"
  },
  "text_title": {
    "placeholder": "추천 항목 이름을 입력해주세요."
  },
  "title_image": {
    "placeholder": "추천 항목 이름으로 사용할 이미지를 등록해주세요. 위젯에는 jpg이미지만 표시됩니다.",
    "caption": "이미지는 PC기준으로 최대 600px x 50px 내의 사이즈를 권장합니다."
  },
  "sub_title_text": {
    "label": "안내 문구",
    "description": "추천 항목별로 이름 하단에 노출할 안내 문구를 설정합니다",
    "placeholder": "안내 문구를 입력해주세요."
  },
  "recommendation_condition": "추천 조건",
  "period_options": {
    "label": "인기 상품 선정 기간",
    "description": "기간을 2개 이상 선택할 경우 위젯에 버튼 형식으로 표시합니다."
  },
  "detail_settings": "상세 설정",
  "use_registered_days": {
    "true": "상품 등록일",
    "false": "상품 최근 입력일"
  },
  "select_category": {
    "label": "카테고리 선택",
    "description": "카테고리를 2개 이상 선택할 경우 위젯에 버튼 형식으로 표시합니다."
  },
  "sort_category_in_popular_order": {
    "true": "추천 조건을 분석하여 인기순으로 자동 조정",
    "false": "선택한 순서대로 노출",
    "label": "위젯 카테고리 버튼 노출 순서",
    "hint": "ex) 설정한 추천 조건이 ‘구매 기준’인 경우 카테고리에 포함된 상품의 구매수를 기준으로 점수를 계산합니다."
  },
  "recommend_same_sub_brand_products": {
    "label": "동일 브랜드 상품 추천",
    "description": "대상 유저가 본 상품과 같은 브랜드의 상품을 추천합니다."
  },
  "recently_popular": "최근 1시간 전부터 지금까지 사람들이 가장 많이 본 상품 추천",
  "similar_size": "대상 유저가 본 상품과 유사한 사이즈의 상품 추천",
  "select_product": "상품 선택",
  "popular_recommendation_methods": {
    "CLICK": "클릭기준",
    "CART": "장바구니기준",
    "PURCHASE": "구매기준",
    "description": "조건을 2개 이상 선택할 경우 위젯에 버튼 형식으로 표시합니다."
  },
  "high_scored_products_in_category_recommendation_methods": {
    "field1": "최근 {0}일간 {1}번 이상 구매된 상품 중",
    "field2": "리뷰 평점이 {0}점 이상이고, 리뷰 수가 최소 {1}개 이상인 상품 추천"
  },
  "review_keyword_recommendation_methods": {
    "field1": "최근{0}일간 작성된 리뷰의 키워드를 분석하며",
    "field2": "분석된 리뷰 키워드 개수가 5개 이상인 상품 중 긍정 점수가 높은 상품을 추천합니다."
  },
  "review_keyword_recommendation_methods_legacy": {
    "field1": "최근 {0}일간 {1}번 이상 구매된 상품의 리뷰 키워드를 분석하며",
    "field2": "키워드별 분석된 리뷰가 5개 이상인 상품 중 긍정 점수가 높은 상품을 추천합니다."
  },
  "potential_products_recommendation_methods": {
    "field1": "최근{0}일간 작성된 리뷰의 키워드를 분석하며",
    "field2": "분석된 리뷰 키워드 개수가 5개 이상인 상품 중 만족도 평균이 높은 상품을 추천합니다."
  },
  "sort_type": {
    "label": "정렬 조건"
  },
  "show_review": {
    "label": "위젯에 베스트 리뷰 본문 노출",
    "description": "위젯에 노출하는 베스트 리뷰 1개는 쇼핑몰 추천 리뷰를 최우선으로 선정하며, 추천 리뷰가 없다면 평점이 가장 높은 리뷰 중 1)동영상 리뷰 2)포토 리뷰 3)텍스트 리뷰를 우선순위로 선정합니다.",
    "tooltip": "디자인 설정 중 [상품 정보에 긍정 리뷰 키워드 표시] 설정 사용 시\n[위젯에 베스트 리뷰 본문 노출] 설정은 적용되지 않습니다."
  },
  "REVIEW_KEYWORD": {
    "use_auto_review_keywords_selection": {
      "label": "위젯에 노출할 리뷰 키워드",
      "true": "긍정 점수가 가장 높은 리뷰 키워드를 분석하여 자동 노출",
      "false": "키워드 직접 선택"
    }
  },
  "POTENTIAL_PRODUCTS": {
    "use_auto_review_keywords_selection": {
      "label": "위젯에 노출할 리뷰 키워드",
      "true": "만족도 평균이 가장 높은 리뷰 키워드를 분석하여 자동 노출",
      "false": "키워드 직접 선택"
    }
  },
  "maximum_keywords_count": "최대 노출 키워드 수",
  "excluding_keyword_ids": {
    "label": "제외 키워드 선택",
    "description": "추천 가능 여부와 상관 없이 제외 키워드로 선택한 키워드는 위젯에 노출하지 않습니다.",
    "placeholder": "{0} 키워드를 선택해주세요."
  },
  "keyword_ids": {
    "description": "주기적인 분석 결과에 따라 선택한 키워드 노출이 불가능할 수 있으니, 넉넉히 설정해두는 것을 권장합니다.",
    "placeholder": "{0} 키워드를 선택해주세요."
  },
  "keyword_information": {
    "recommendation": "리뷰 분석 결과에 따라 현재 상품 추천이 가능한 키워드입니다.",
    "no_recommendation": "리뷰 분석 결과, 현재 상품 추천이 가능한 키워드가 없습니다."
  },
  "include_manager_review": {
    "label": "스탭 리뷰 노출 허용",
    "description": "위젯에 노출할 리뷰 선정 시 스텝 리뷰를 제외하지 않습니다."
  },
  "show_manager_review": {
    "label": "스탭 리뷰 노출 허용",
    "description": "위젯에 노출할 리뷰 선정 시 스텝 리뷰를 제외하지 않습니다."
  },
  "advanced": {
    "advanced_settings_info_html": "고급 설정은 세밀한 캠페인 사용을 위한 설정모음입니다.<br />해당 설정을 잘 모르신다면 기본 설정 사용을 추천합니다.",
    "show_product_rank": {
      "label": "상품 순위 표시",
      "description": "추천 상품 이미지 위에 순위를 표시합니다"
    },
    "tab_button_style": {
      "label": "탭 버튼 스타일",
      "description": "위젯에 표시되는 탭 버튼 스타일을 선택합니다.",
      "use_round_button": {
        "true": "둥근 버튼",
        "false": "텍스트 버튼"
      }
    }
  }
}
</i18n>
