import { constructEnum } from '@/lib/enum';

const BrandCsvType = constructEnum('BrandCsvType', {
  PRODUCT: 10,
  DELETED_PRODUCT: 11,
  CART: 20,
  MEMBER: 30,
  ORDER: 40,
  REVIEW: 50,
  DISCOUNT_SETTING: 60,
  PRODUCT_CATEGORY: 70,
  COUPON: 80,
  PRODUCT_INQUIRY: 90,
  PUSH_REVIEWS: 100,
  PRODUCT_STOCK_LOGISTICS: 110,
  PRODUCT_STOCK_SHOP_BUILDER: 115,
  PRODUCT_ICON: 120,
  PROMOTION: 130,
  SHOPIFY_REVIEW: 140,
  JUDGE_ME_REVIEW: 141,
  BULK_REVIEW: 1000,
  BULK_V_REVIEW: 1010,
  BULK_ALPHA_REVIEW: 1020,
  BULK_SNAP_REVIEW: 1030,
  BULK_SHOPIFY_REVIEW: 1040,
  BULK_JUDGE_ME_REVIEW: 1050
});

BrandCsvType.THIRD_PARTY_REVIEW_CSVS = [
  BrandCsvType.SHOPIFY_REVIEW,
  BrandCsvType.JUDGE_ME_REVIEW
];

export default BrandCsvType;
