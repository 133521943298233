<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        Normal
      </div>
      <AppColorReportTable title="Normal" :columns="columns" :rows="rows" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiColorReportTable',
  computed: {
    columns() {
      return [0, 1, 2, 3, 4, 5];
    },
    rows() {
      return [
        [0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0]
      ].map((row, i) => ({
        label: i,
        summary: `${i} row`,
        summaryTooltip: `${i} row summary`,
        data: row.map((value, j) => {
          const mark = i + j === 10;

          return {
            value: mark ? '표시' : value,
            type: mark ? 'string' : 'percentage',
            mark,
            level: i + j,
            tooltip: `level ${i + j}`
          };
        })
      }));
    }
  }
};
</script>
