<template>
  <div class="AppTags dropdown">
    <AppMultipleSelection
      :id="id"
      :options="currentTags.map(t => ({ label: t, value: t }))"
      :disabled="disabled"
      :invalid="invalid"
      :placeholder="placeholder"
      @click="click"
      @remove="removeTag($event.value)"
    >
      <template #modeless>
        <AppModeless
          v-if="isDropdownVisible"
          class="AppTags__dialog dropdown__dialog"
          @close="closeDropdown"
        >
          <AppSearchDropdown
            :options="dropdownOptions"
            :no-results="searchNoResults"
            :placeholder="searchPlaceholder"
            @select="addTag($event)"
            @query="inputValue = $event"
            @close="closeDropdown"
          />
        </AppModeless>
      </template>
    </AppMultipleSelection>
    <AppHiddenInputArray v-if="name" :name="name" :values="currentTags" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'AppTags',
  model: {
    prop: 'tags',
    event: 'change'
  },
  props: {
    id: {
      type: String,
      default: null
    },
    tags: {
      type: Array,
      default: () => []
    },
    bannedTags: {
      type: Array,
      default: () => []
    },
    bannedTagsMessage: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    searchPlaceholder: {
      type: String,
      default() {
        return this.$t('search_placeholder');
      }
    },
    searchNoResults: {
      type: String,
      default() {
        return this.$t('search_no_results');
      }
    },
    name: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    tagAlreadyExistsMessage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isDropdownVisible: false,
      currentTags: this.availableTags(),
      inputValue: ''
    };
  },
  computed: {
    dropdownOptions() {
      const value = this.inputValue;
      return value
        ? [{ label: this.$t('search_add_tag', { value }), value }]
        : [];
    }
  },
  watch: {
    tags() {
      this.currentTags = this.availableTags();
    },
    bannedTags() {
      this.currentTags = this.availableTags();
    },
    currentTags() {
      if (!_.isEqual(this.tags, this.currentTags)) {
        this.$emit('change', this.currentTags);
      }
    }
  },
  mounted() {
    if (!_.isEqual(this.tags, this.currentTags)) {
      this.$emit('change', this.currentTags);
    }
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    availableTags() {
      return _.uniq(_.difference(this.tags, this.bannedTags));
    },
    addTag(value) {
      const newValue = _.trim(value);
      if (newValue) {
        if (this.currentTags.includes(newValue)) {
          this.createToast(
            this.tagAlreadyExistsMessage || this.$t('tag_already_exists')
          );
        } else if (this.bannedTags.includes(newValue)) {
          const tagBannedMessage =
            this.bannedTagsMessage || this.$t('tag_banned_default_message');
          this.createToast(tagBannedMessage);
        } else {
          this.$emit('add', newValue);
          this.currentTags.push(newValue);
        }
      }
      this.inputValue = '';
    },
    removeTag(tag) {
      this.$emit('remove', tag);
      this.currentTags = this.currentTags.filter(t => t !== tag);
    },
    click() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    closeDropdown() {
      this.inputValue = '';
      this.isDropdownVisible = false;
    }
  }
};
</script>

<style scoped>
.AppTags {
  display: block;
}

.AppTags__dialog {
  width: 100%;
}
</style>

<i18n locale="ko">
{
  "tag_already_exists": "이미 포함된 태그입니다.",
  "tag_banned_default_message": "금지된 태그입니다.",
  "search_add_tag": "+ '{value}' 추가",
  "search_no_results": "입력한 문구를 추가할 수 있습니다.",
  "search_placeholder": "문구를 입력해주세요."
}
</i18n>
