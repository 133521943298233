export default {
  props: {
    eventBus: {
      type: Object,
      required: true
    },
    keepOnRoute: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.eventBus.$on('close-dialog', this.close);
  },
  methods: {
    close() {
      this.closeDialog();
    },
    closeDialog() {
      this.emit('close');
      this.$store.dispatch('dialog/closeDialog', this.$options.name);
    },
    emit(event, args) {
      this.eventBus.$emit(event, args);
    }
  }
};
