export default [
  {
    id: 'product_products',
    type: 'sub_menu',
    path: '/product/products',
    parentId: 'product'
  },
  {
    id: 'product_sorted_products',
    type: 'sub_menu',
    path: '/product/sorted_products',
    parentId: 'product',
    superAdmin: true
  },
  {
    id: 'product_icons',
    type: 'sub_menu',
    path: '/product/icons',
    parentId: 'product',
    isAccessible: getters => !getters.isShopifyBrand
  },
  {
    id: 'product_option_types',
    type: 'sub_menu',
    path: '/product/option_types',
    parentId: 'product'
  },
  {
    id: 'product_batch_jobs',
    type: 'sub_menu',
    path: '/product/batch-jobs/sub-products',
    parentId: 'product',
    superAdmin: true
  },
  {
    id: 'product_batch_jobs_sub_products',
    type: 'sub_menu',
    path: '/product/batch-jobs/sub-products',
    parentId: 'product_batch_jobs',
    superAdmin: true
  },
  {
    id: 'product_batch_jobs_source_product_codes',
    type: 'sub_menu',
    path: '/product/batch-jobs/source-product-codes',
    parentId: 'product_batch_jobs'
  },
  {
    id: 'product_settings',
    type: 'sub_menu',
    path: '/product/settings',
    parentId: 'product',
    isAccessible: getters => !getters.isShopifyBrand
  }
];
