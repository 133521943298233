import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';

export default {
  computed: {
    columnConfigs() {
      const { campaign_type, visit_shop_before_landing_page } = this.campaign;
      return {
        date_range: {
          label: this.$t('app.period'),
          type: 'pre',
          align: 'left'
        },
        targeted_users_count: { type: 'number' },
        total_check_count: { type: 'number' },
        sent_count: { type: 'number' },
        check_counts: {
          type: 'number2',
          column1: 'unique_check_count',
          column2: 'total_check_count',
          detail_column: 'checked_users',
          tooltip: visit_shop_before_landing_page
        },
        check_rate: {
          type: 'rate',
          numerator: 'unique_check_count',
          denominator: 'targeted_users_count'
        },
        click_per_check: {
          type: 'rate',
          numerator: 'unique_click_count',
          denominator: 'unique_check_count'
        },
        click_counts: {
          type: 'number2',
          column1: 'unique_click_count',
          column2: 'total_click_count',
          detail_column: 'clicked_users',
          width: '140px'
        },
        respondents_count: {
          type: 'number',
          detail_column:
            campaign_type === TargetCampaignType.NEW_VISITOR_SIGNUP
              ? 'joined_users'
              : 'visited_users'
        },
        purchase_counts: {
          type: 'number2',
          column1: 'purchased_users_count',
          column2: 'purchased_orders_count',
          detail_column: 'purchased_orders'
        },
        revenue: { type: 'number', unit: 'currency.krw' },
        targeted_reviewed_sub_orders_count: { type: 'number', tooltip: true },
        reviewed_sub_orders_count: {
          type: 'number',
          tooltip: true,
          detail_column: 'reviewed_sub_orders'
        },
        respond_rate: {
          type: 'rate',
          numerator: 'respondents_count',
          denominator: this.isDm ? 'targeted_users_count' : 'unique_check_count'
        },
        purchase_rate: {
          type: 'rate',
          numerator: 'purchased_users_count',
          denominator: this.isDm
            ? 'targeted_users_count'
            : this.isWidget
            ? 'unique_click_count'
            : 'unique_check_count'
        },
        review_rate: {
          type: 'rate',
          numerator: 'reviewed_sub_orders_count',
          denominator: 'targeted_reviewed_sub_orders_count'
        },
        unique_check_count: { type: 'number' },
        unique_click_count: {
          type: 'number',
          detail_column:
            campaign_type === TargetCampaignType.NEW_VISITOR_SIGNUP
              ? null
              : 'clicked_users'
        }
      };
    },
    isDm() {
      return (
        this.campaign.campaign_media_type ===
        TargetCampaignMediaType.DIRECT_MESSAGE
      );
    },
    isWidget() {
      return (
        this.campaign.campaign_media_type === TargetCampaignMediaType.WIDGET
      );
    },
    columnIds() {
      if (this.isDm)
        switch (this.campaign.campaign_type) {
          case TargetCampaignType.NEW_MEMBER_REENGAGEMENT:
          case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
          case TargetCampaignType.INACTIVE_MEMBER_REENGAGEMENT:
            return [
              'targeted_users_count',
              'sent_count',
              'check_counts',
              'check_rate',
              'respondents_count',
              'respond_rate'
            ];
          case TargetCampaignType.CART_REMINDING:
          case TargetCampaignType.INDUCING_REPURCHASE:
          case TargetCampaignType.INTERESTED_PRODUCTS:
          case TargetCampaignType.SELECTED_PRODUCTS:
          case TargetCampaignType.SELECTED_CATEGORIES:
          case TargetCampaignType.COUPON_EXPIRY:
          case TargetCampaignType.UNPAID_ORDER_REMINDING:
            return [
              'targeted_users_count',
              'sent_count',
              'check_counts',
              'check_rate',
              'purchase_counts',
              'revenue',
              'purchase_rate'
            ];
          case TargetCampaignType.MILEAGE_EXPIRING:
            return [
              'targeted_users_count',
              'sent_count',
              'check_counts',
              'check_rate',
              'targeted_reviewed_sub_orders_count',
              'reviewed_sub_orders_count',
              'review_rate'
            ];
          case TargetCampaignType.ALL_MEMBERS:
          case TargetCampaignType.ACTIVE_MEMBERS:
          case TargetCampaignType.INACTIVE_MEMBERS:
            return [
              'targeted_users_count',
              'sent_count',
              'respondents_count',
              'purchase_counts',
              'revenue'
            ];
        }
      else if (this.isWidget)
        switch (this.campaign.campaign_type) {
          case TargetCampaignType.PRODUCT_RECOMMENDATION_DETAIL_PAGE:
          case TargetCampaignType.PRODUCT_RECOMMENDATION_CATEGORY_PAGE:
          case TargetCampaignType.PRODUCT_RECOMMENDATION_MAIN_PAGE:
          case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_PAGE:
          case TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT:
          case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_POPUP:
            return [
              'unique_check_count',
              'total_check_count',
              'click_counts',
              'click_per_check',
              'purchase_counts',
              'revenue',
              'purchase_rate'
            ];
        }
      else
        switch (this.campaign.campaign_type) {
          case TargetCampaignType.NEW_VISITOR_SIGNUP:
            return [
              'unique_check_count',
              'unique_click_count',
              'click_per_check',
              'respondents_count',
              'respond_rate'
            ];
          case TargetCampaignType.CART_REMINDING:
          case TargetCampaignType.UNPAID_ORDER_REMINDING:
          case TargetCampaignType.COUPON_EXPIRY:
          case TargetCampaignType.UTM_POPUP:
            return [
              'unique_check_count',
              'unique_click_count',
              'click_per_check',
              'purchase_counts',
              'revenue',
              'purchase_rate'
            ];
          case TargetCampaignType.MILEAGE_EXPIRING:
            return [
              'unique_check_count',
              'unique_click_count',
              'click_per_check',
              'targeted_reviewed_sub_orders_count',
              'reviewed_sub_orders_count',
              'review_rate'
            ];
          case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
            return [
              'unique_check_count',
              'unique_click_count',
              'click_per_check',
              'respondents_count',
              'respond_rate'
            ];
        }

      return [];
    },
    columns() {
      return ['date_range', ...this.columnIds].map(id => {
        const column = this.columnConfigs[id];
        return {
          ...column,
          id,
          label: column.label || this.campaignText(id),
          tooltip: column.tooltip ? this.campaignText(`tooltip.${id}`) : null,
          align: column.align || 'right',
          width: column.width || null,
          default: '-',
          linkIcon: column.detail_column
            ? {
                handler: (_, { date, subReportType }) =>
                  this.openDetailDialog(column.detail_column, {
                    date,
                    subReportType
                  })
              }
            : false
        };
      });
    }
  },
  methods: {
    campaignText(key) {
      return this.$store.getters['targetCampaigns/campaignText'](
        this.campaign,
        `table.${key}`,
        {},
        this.samplingMethod
      );
    }
  }
};
