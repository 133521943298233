var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppSearchbar',{on:{"submit":_vm.searchResource},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('li',[(_vm.isSuperAdminAccessible)?_c('AppButton',{staticClass:"super-admin__item",attrs:{"label":_vm.$t('add_product_category_product_type'),"button-style":"red"},on:{"click":_vm.addProductCategoryProductType}}):_vm._e()],1)]},proxy:true},{key:"right",fn:function(){return [_c('li',[_c('AppSearchWithType',{attrs:{"search-types":_vm.SEARCH_TYPES},model:{value:(_vm.searchWithType),callback:function ($$v) {_vm.searchWithType=$$v},expression:"searchWithType"}})],1)]},proxy:true}])}),_c('AppInfoBox',[_vm._v(" "+_vm._s(_vm.$t('hint'))+" ")]),_c('AppResourceTable',{attrs:{"table-id":"brand-category-product-types-table","resources":_vm.resources,"columns":_vm.columns,"rows":_vm.rows,"no-data":_vm.$t('no_data')},on:{"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
var value = ref.value;
var rowIndex = ref.rowIndex;
return [(column === 'product_category_name')?[_vm._v(" "+_vm._s(_vm.categoryNamesMap[row.product_category_id] || _vm.$t('unselected_categories'))+" ")]:(column === 'brand_evaluation_type_names')?_vm._l((row.brand_evaluation_types),function(type,i){return _c('AppBadge',{key:i,attrs:{"badge-style":"grey-outline","label":type.name}})}):(column === 'brand_user_property_type_names')?_vm._l((row.brand_user_property_types),function(type,i){return _c('AppBadge',{key:i,attrs:{"badge-style":type.default ? 'mango-outline' : 'grey-outline',"label":type.name,"tooltip":{
            message: _vm.brandUserPropertyTypeTooltips[rowIndex][i],
            textAlign: 'left',
            maxWidth: '200px'
          }}})}):(column === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('actions.edit')},on:{"click":function($event){return _vm.editProductCategoryProductType(row.id)}}}),(!_vm.isUnselectedCategories(rowIndex))?_c('AppButton',{attrs:{"button-style":"red-outline","label":_vm.$t('actions.delete')},on:{"click":function($event){return _vm.deleteProductCategoryProductType(row.id)}}}):_vm._e()]:[_vm._v(" "+_vm._s(value)+" ")]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }