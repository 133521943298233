<template>
  <AppButton
    button-style="grey"
    :tooltip="tooltip"
    :disabled="buttonDisabled"
    :label="label"
    @click="syncResource"
  />
</template>

<script>
import BrandSyncType from '@/enums/BrandSyncType';
import ButtonSyncable from '@/mixins/ButtonSyncable';

export default {
  name: 'AppButtonSyncProductIcon',
  mixins: [ButtonSyncable],
  computed: {
    brandSyncType() {
      return BrandSyncType.PRODUCT_ICON;
    }
  }
};
</script>

<i18n locale="ko">
{
  "name": "상품 아이콘"
}
</i18n>
