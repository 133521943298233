<template>
  <div
    :class="[
      'AccordionStepTitle',
      isCompleted ? 'AccordionStepTitle--completed' : ''
    ]"
  >
    {{ `${currentStep}/${stepsCount} - ${text}` }}
  </div>
</template>

<script>
export default {
  name: 'AccordionStepTitle',
  props: {
    currentStep: { type: Number, required: true },
    stepsCount: { type: Number, required: true },
    isCompleted: { type: Boolean, required: true },
    text: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AccordionStepTitle {
  @include text-caption;
  font-weight: bold;
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: solid 1px $color-grey-25;

  &--completed {
    color: $color-grey-40;
  }
}
</style>
