var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppResourceTable',{attrs:{"rows":_vm.cohortItems.items,"resources":_vm.cohortItems,"columns":['id', 'user', 'status'].map(function (id) { return ({
          id: id,
          label: _vm.$t(("table." + id))
        }); })},on:{"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
        var row = ref.row;
        var column = ref.column;
return [(column === 'user')?[(row.name)?_c('div',[_vm._v(_vm._s(row.name))]):_vm._e(),(row.username)?_c('div',[_vm._v(_vm._s(row.username))]):_vm._e(),(row.phone)?_c('div',[_vm._v(_vm._s(row.phone))]):_vm._e()]:(column === 'status')?[_c('ul',{staticClass:"badges"},_vm._l((row.statuses),function(ref){
        var dispatch_method = ref.dispatch_method;
        var status = ref.status;
return _c('li',{key:dispatch_method},[_c('TargetReportsDispatchResultDialogTableStatusBadge',{attrs:{"status":status,"dispatch-method":dispatch_method}})],1)}),0)]:_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }