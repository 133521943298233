var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ReviewDashboardMileage"},[_c('AppTipBox',{attrs:{"text":_vm.$t('mileage_tip_box.text')}}),_c('div',{class:[
      'ReviewDashboardMileage__mileage-criteria',
      {
        'ReviewDashboardMileage__mileage-criteria--disabled': _vm.isCompleted
      }
    ]},[_vm._v(" "+_vm._s(_vm.$t('mileage_criteria'))+" ")]),_c('AppForm',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"group",fn:function(){return [_c('div',{class:[
          'ReviewDashboardMileage__amount-label',
          {
            'ReviewDashboardMileage__amount-label--disabled': _vm.isCompleted
          }
        ]},[_c('i18n',{attrs:{"path":"min_message_length_above"}},[_c('AppNumberInput',{attrs:{"digits":3,"disabled":_vm.isCompleted},model:{value:(_vm.mileage.minimum_message_length),callback:function ($$v) {_vm.$set(_vm.mileage, "minimum_message_length", $$v)},expression:"mileage.minimum_message_length"}})],1)],1),_c('AppTable',{class:[
          'ReviewDashboardMileage__table',
          {
            'ReviewDashboardMileage__table--disabled': _vm.isCompleted
          }
        ],attrs:{"columns":_vm.COLUMNS,"rows":_vm.rows,"align":"left"},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
        var column = ref.column;
return [(column.id === 'auto_mileage_product_price_cents')?[_c('i18n',{attrs:{"path":"product_price_upper"}},[_c('AppCurrencyInput',{attrs:{"digits":6,"disabled":_vm.isCompleted},model:{value:(_vm.mileage.auto_mileage_product_price_cents),callback:function ($$v) {_vm.$set(_vm.mileage, "auto_mileage_product_price_cents", $$v)},expression:"mileage.auto_mileage_product_price_cents"}})],1)]:[_c('AppCurrencyInput',{attrs:{"digits":6,"disabled":_vm.isCompleted},model:{value:(_vm.mileage[column.id]),callback:function ($$v) {_vm.$set(_vm.mileage, column.id, $$v)},expression:"mileage[column.id]"}})]]}}])})]},proxy:true}])},'AppForm',Object.assign({}, _vm.formProps, {disabled: _vm.isCompleted}),false),_vm.formEvents))],1)}
var staticRenderFns = []

export { render, staticRenderFns }