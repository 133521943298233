<template>
  <SlotModalForm
    class="InstallRequestRenewalSlotMainCustomDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #head>
      <SlotInfoCard :image-url="cardImageUrl" :name="cardName" />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'pc_main_custom'">
        <SlotTable
          :device-type="WidgetDeviceType.PC"
          :slot-type="InstallSlotType.MAIN_CUSTOM"
        />
      </template>
      <template v-else-if="id === 'mobile_main_custom'">
        <SlotTable
          :device-type="WidgetDeviceType.MOBILE"
          :slot-type="InstallSlotType.MAIN_CUSTOM"
        />
      </template>
    </template>
    <template #description="{ id }">
      <div
        v-if="id === 'pc_custom_comment' || id === 'mobile_custom_comment'"
        class="AppForm__description"
      >
        <AppInlineButton
          button-style="caption"
          :label="$t('attachment_guide')"
          @click="openAlertDialog"
        />
      </div>
    </template>
  </SlotModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import SlotModalForm from './components/SlotModalForm';
import SlotInfoCard from './components/SlotInfoCard';
import DialogSize from '@/enums/DialogSize';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';

export default {
  name: 'InstallRequestRenewalSlotMainCustomDialog',
  components: { SlotModalForm, SlotInfoCard },
  mixins: [DialogFormView],
  data() {
    return {
      cardImageUrl:
        'https://assets.cre.ma/m/admin/v2/install_request/main_custom_slot@3x.png',
      oldInstallations: []
    };
  },
  computed: {
    ...mapState('installRequest', ['installDeviceTypes']),
    ...mapGetters('installRequest', [
      'currentInstallations',
      'currentDirections'
    ]),
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    InstallSlotType() {
      return InstallSlotType;
    },
    cardName() {
      return this.$t('card_name');
    },
    formSections() {
      return [
        ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
          ? [
              {
                id: 'pc_direction',
                groups: [
                  {
                    id: 'tip',
                    label: this.$t('direction.pc'),
                    type: 'tip_box',
                    value: this.$t('direction.tip'),
                    validate: [{ rule: 'required', value: () => true }]
                  },
                  {
                    id: 'pc_custom_image_url',
                    label: null,
                    type: 'image',
                    notRemovable: true,
                    placeholder: this.$t('direction.placeholder.pc_image_url'),
                    validate: ['required']
                  },
                  {
                    id: 'pc_custom_comment',
                    label: null,
                    type: 'text',
                    placeholder: this.$t('direction.placeholder.comment'),
                    validate: ['required']
                  }
                ]
              }
            ]
          : []),
        ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
          ? [
              {
                id: 'mobile_direction',
                groups: [
                  {
                    id: 'tip',
                    label: this.$t('direction.mobile'),
                    type: 'tip_box',
                    value: this.$t('direction.tip'),
                    validate: [{ rule: 'required', value: () => true }]
                  },
                  {
                    id: 'mobile_custom_image_url',
                    label: null,
                    type: 'image',
                    notRemovable: true,
                    placeholder: this.$t(
                      'direction.placeholder.mobile_image_url'
                    ),
                    validate: ['required']
                  },
                  {
                    id: 'mobile_custom_comment',
                    label: null,
                    type: 'text',
                    placeholder: this.$t('direction.placeholder.comment'),
                    validate: ['required']
                  }
                ]
              }
            ]
          : []),
        {
          id: 'custom_slot',
          label: this.$t('select_widget'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('select_widget_tip')
            },
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
              ? [
                  {
                    id: 'pc_main_custom',
                    label: this.$t('app.pc')
                  }
                ]
              : []),
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
              ? [
                  {
                    id: 'mobile_main_custom',
                    label: this.$t('app.mobile')
                  }
                ]
              : [])
          ]
        }
      ];
    },
    isDirectionChanged() {
      return this.installDeviceTypes.some(deviceType => {
        const direction = this.currentDirections.find(
          dir => dir.device_type === deviceType
        );

        if (direction) {
          return (
            this.formObject[this.customImageUrlKey(deviceType)] !==
              direction.custom_img_url ||
            this.formObject[this.customCommentKey(deviceType)] !==
              direction.custom_comment
          );
        }
        return false;
      });
    },
    isInstallationChanged() {
      const orgSortedInstallations = _.sortBy(this.oldInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const sortedInstallations = _.sortBy(this.currentInstallations, v => [
        v.id,
        v.widget_device_type
      ]);
      const result = !_.isEqual(orgSortedInstallations, sortedInstallations);
      return result;
    },
    isFormDataChanged() {
      return this.isInstallationChanged || this.isDirectionChanged;
    }
  },
  created() {
    this.SET_CURRENT_PAGE_TYPE(WidgetPageType.MAIN);
    this.SET_CURRENT_SLOT_TYPES([InstallSlotType.MAIN_CUSTOM]);
    this.oldInstallations = this.currentInstallations || [];
    this.setDirection();
  },
  methods: {
    ...mapActions('dialog', ['alert']),
    ...mapMutations('installRequest', [
      'ADD_INSTALLATIONS',
      'REMOVE_INSTALLATION',
      'SET_CURRENT_PAGE_TYPE',
      'SET_CURRENT_SLOT_TYPES',
      'APPEND_DIRECTION',
      'REMOVE_CURRENT_SLOT_DIRECTIONS'
    ]),
    customImageUrlKey(deviceType) {
      return `${WidgetDeviceType.key(
        deviceType
      ).toLowerCase()}_custom_image_url`;
    },
    customCommentKey(deviceType) {
      return `${WidgetDeviceType.key(deviceType).toLowerCase()}_custom_comment`;
    },
    openAlertDialog() {
      this.alert({
        title: this.$t('attachment_guide'),
        message: this.$t('alert_message'),
        width: DialogSize.MIDDLE,
        closeButtonStyle: 'default',
        closeButtonLabel: this.$t('app.close')
      });
    },
    beforeCloseDialog() {
      this.currentInstallations.forEach(installation =>
        this.REMOVE_INSTALLATION(installation)
      );
      this.ADD_INSTALLATIONS(this.oldInstallations);
    },
    setDirection() {
      this.installDeviceTypes.forEach(deviceType => {
        const direction = this.currentDirections.find(
          dir => dir.device_type === deviceType
        );

        if (direction) {
          this.$set(
            this.formObject,
            this.customImageUrlKey(deviceType),
            direction.custom_img_url
          );
          this.$set(
            this.formObject,
            this.customCommentKey(deviceType),
            direction.custom_comment
          );
        }
      });
    },
    appendDirection(deviceType, customUrl, customComment) {
      if (customUrl) {
        this.APPEND_DIRECTION({
          device_type: deviceType,
          page_type: WidgetPageType.MAIN,
          slot: InstallSlotType.MAIN_CUSTOM,
          custom_img_url: customUrl,
          custom_comment: customComment
        });
      }
    },
    submit() {
      this.REMOVE_CURRENT_SLOT_DIRECTIONS();
      this.installDeviceTypes.forEach(deviceType => {
        this.appendDirection(
          deviceType,
          this.formObject[this.customImageUrlKey(deviceType)],
          this.formObject[this.customCommentKey(deviceType)]
        );
      });
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  #custom_slot--mobile_main_custom {
    margin-top: 32px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "sub_title": "메인 페이지",
  "card_name": "직접 입력",
  "select_widget": "위젯 선택",
  "select_widget_tip": "설치할 위젯을 선택해 주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해주세요.",
  "attachment_guide": "이미지 첨부 가이드",
  "alert_message": "아래 이미지와 같이 위젯을 설치하고 싶은 위치가 표시된 이미지를 첨부해주세요.<br><br>![](https://assets.cre.ma/m/admin/v2/install_request/attachment_guide_sample@3x.png)",
  "direction" : {
    "pc": "PC 설치 위치",
    "mobile": "MOBILE 설치 위치",
    "tip": "설치와 무관한 내용은 반영되지 않습니다. 설치가 어려운 위치는 설치가 진행되지 않을 수 있습니다.",
    "placeholder": {
      "pc_image_url": "PC 설치 위치 이미지 첨부",
      "mobile_image_url": "MOBILE 설치 위치 이미지 첨부",
      "comment": "크리마 팀이 참고할 코멘트가 있다면 남겨주세요."
    }
  }
}
</i18n>
