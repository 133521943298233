<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm v-bind="designFormProps" v-on="formEvents">
      <template #head>
        <AppTipBox class="ReviewsettingsDesign__tip-box" :text="$t('tip')" />
        <div class="ReviewSettingsDesign__image">
          <div
            class="ReviewSettingsDesign__image-background"
            :style="{ backgroundColor: formObject.main_color }"
          >
            <AppImage :src="tipBoxImageLink" :resolutions="[2, 3]" />
          </div>
        </div>
      </template>
      <template #group="{ id }">
        <template v-if="id === 'main_color'">
          <div class="AppForm__group-field">
            <AppColorPicker
              name="design_settings[main_color]"
              :value="formObject.main_color"
              :label="$t('settings.review_settings_design.groups.main_color')"
              @input="setMainColorToAll"
            />
          </div>
        </template>
        <template v-else-if="id === 'product_score_filter_color_settings'">
          <span
            class="AppForm__sub-group ReviewSettingsDesign__advanced-setting-settings"
          >
            <div>{{ $t('product_score_filter_highlight_color.label') }}</div>
            <AppColorPicker
              v-model="formObject.product_score_filter_highlight_color"
              class="ReviewSettingsDesign__advanced-setting-field ReviewSettingsRenewdDesign__advanced-setting-field--left"
              name="design_settings[product_score_filter_highlight_color]"
              :label="$t('product_score_filter_highlight_color.label')"
            />
          </span>
          <span class="AppForm__sub-group">
            <div>{{ $t('product_score_filter_color.label') }}</div>
            <AppColorPicker
              v-model="formObject.product_score_filter_color"
              class="ReviewSettingsDesign__advanced-setting-field"
              name="design_settings[product_score_filter_color]"
              :label="$t('product_score_filter_color.label')"
            />
          </span>
        </template>
        <template v-else-if="id === 'review_write_button_color_settings'">
          <span
            class="AppForm__sub-group ReviewSettingsDesign__advanced-setting-settings"
          >
            <div>{{ $t('review_write_button_color.label') }}</div>
            <AppColorPicker
              v-model="formObject.review_write_button_color"
              class="ReviewSettingsDesign__advanced-setting-field ReviewSettingsRenewdDesign__advanced-setting-field--left"
              name="design_settings[review_write_button_color]"
              :label="$t('review_write_button_color.label')"
            />
          </span>
          <span class="AppForm__sub-group">
            <div>{{ $t('review_write_button_text_color.label') }}</div>
            <AppColorPicker
              v-model="formObject.review_write_button_text_color"
              class="ReviewSettingsDesign__advanced-setting-field"
              name="design_settings[review_write_button_text_color]"
              :label="$t('review_write_button_text_color.label')"
            />
          </span>
        </template>
        <template v-else-if="id === 'new_review_popup_button_color_settings'">
          <span
            class="AppForm__sub-group ReviewSettingsDesign__advanced-setting-settings"
          >
            <div>{{ $t('new_review_popup_button_color.label') }}</div>
            <AppColorPicker
              v-model="formObject.new_review_popup_button_color"
              class="ReviewSettingsDesign__advanced-setting-field ReviewSettingsRenewdDesign__advanced-setting-field--left"
              name="design_settings[new_review_popup_button_color]"
              :label="$t('new_review_popup_button_color.label')"
            />
          </span>
          <span class="AppForm__sub-group">
            <div>{{ $t('new_review_popup_button_text_color.label') }}</div>
            <AppColorPicker
              v-model="formObject.new_review_popup_button_text_color"
              class="ReviewSettingsDesign__advanced-setting-field"
              name="design_settings[new_review_popup_button_text_color]"
              :label="$t('new_review_popup_button_text_color.label')"
            />
          </span>
        </template>
        <template v-else-if="id === 'keyword_filter_tag_color_settings'">
          <span
            class="AppForm__sub-group ReviewSettingsDesign__advanced-setting-settings"
          >
            <div>{{ $t('ai_keyword_filter_background_color.label') }}</div>
            <AppColorPicker
              v-model="formObject.ai_keyword_filter_background_color"
              class="ReviewSettingsDesign__advanced-setting-field ReviewSettingsRenewdDesign__advanced-setting-field--left"
              name="design_settings[ai_keyword_filter_background_color]"
              :label="$t('ai_keyword_filter_background_color.label')"
            />
          </span>
          <span class="AppForm__sub-group">
            <div>{{ $t('ai_keyword_filter_text_color.label') }}</div>
            <AppColorPicker
              v-model="formObject.ai_keyword_filter_text_color"
              class="ReviewSettingsDesign__advanced-setting-field"
              name="design_settings[ai_keyword_filter_text_color]"
              :label="$t('ai_keyword_filter_text_color.label')"
            />
          </span>
        </template>
        <template v-else-if="id === 'review_tag_color_settings'">
          <span
            class="AppForm__sub-group ReviewSettingsDesign__advanced-setting-settings"
          >
            <div>{{ $t('review_keyword_background_color.label') }}</div>
            <AppColorPicker
              v-model="formObject.review_keyword_background_color"
              class="ReviewSettingsDesign__advanced-setting-field ReviewSettingsRenewdDesign__advanced-setting-field--left"
              name="design_settings[review_keyword_background_color]"
              :label="$t('review_keyword_background_color.label')"
            />
          </span>
          <span class="AppForm__sub-group">
            <div>{{ $t('review_keyword_text_color.label') }}</div>
            <AppColorPicker
              v-model="formObject.review_keyword_text_color"
              class="ReviewSettingsDesign__advanced-setting-field"
              name="design_settings[review_keyword_text_color]"
              :label="$t('review_keyword_text_color.label')"
            />
          </span>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import api from '@/lib/api';
import _ from 'lodash';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';

export default {
  name: 'ReviewSettingsDesign',
  mixins: [FormView, SettingsView],
  data() {
    return {
      isLoading: true,
      SETTINGS_ID: 'review_settings_design'
    };
  },
  computed: {
    ...mapGetters('session', ['adminLocale']),
    designFormProps() {
      return {
        ...this.formProps,
        id: this.SETTINGS_ID,
        submitButton: true,
        objectId: 'design_settings'
      };
    },
    groupOptions() {
      return {
        advanced_tip_box: {
          type: 'tip_box',
          label: '',
          value: this.$t('advanced_tip_box.text')
        },
        main_color: {
          type: 'color',
          groupDescription: this.$t('main_color.description'),
          hint: this.isMainColorValid
            ? ''
            : this.$t('main_color.hint_not_valid')
        },
        product_score_filter_color_settings: {
          type: 'color',
          groupDescription: this.$t(
            'product_score_filter_color_settings.description'
          ),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('product_score_filter_color_settings')
        },
        link_color: {
          type: 'color',
          groupDescription: this.$t('link_color.description'),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('link_color')
        },
        star_color: {
          type: 'color',
          groupDescription: this.$t('star_color.description'),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('star_color')
        },
        review_write_button_color_settings: {
          type: 'color',
          groupDescription: this.$t(
            'review_write_button_color_settings.description'
          ),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('review_write_button_color_settings')
        },
        new_review_popup_button_color_settings: {
          type: 'color',
          groupDescription: this.$t(
            'new_review_popup_button_color_settings.description'
          ),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('new_review_popup_button_color_settings')
        },
        keyword_filter_tag_color_settings: {
          type: 'color',
          groupDescription: this.$t(
            'keyword_filter_tag_color_settings.description'
          ),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('keyword_filter_tag_color_settings')
        },
        review_tag_color_settings: {
          type: 'color',
          groupDescription: this.$t('review_tag_color_settings.description'),
          groupDescriptionDetailClickHandler: () =>
            this.openDetailDialog('review_tag_color_settings')
        }
      };
    },
    isMainColorValid() {
      const {
        main_color,
        product_score_filter_highlight_color,
        link_color,
        star_color,
        review_write_button_color,
        new_review_popup_button_color,
        review_keyword_text_color,
        review_keyword_background_color,
        ai_keyword_filter_background_color
      } = this.formObject;
      const mainColorOpacity8 = this.applyOpacityToHex(main_color, 0.08);

      return (
        main_color === product_score_filter_highlight_color &&
        main_color === link_color &&
        main_color === star_color &&
        main_color === review_write_button_color &&
        main_color === new_review_popup_button_color &&
        main_color === review_keyword_text_color &&
        mainColorOpacity8 === review_keyword_background_color &&
        mainColorOpacity8 === ai_keyword_filter_background_color
      );
    },
    isAdminLocaleKo() {
      return this.adminLocale === 'ko';
    },
    tipBoxImageLink() {
      const localePrefix = this.isAdminLocaleKo ? '' : 'eng-';

      return `https://assets.cre.ma/m/admin/v2/${localePrefix}onboarding-main-color-v4.png`;
    }
  },
  mounted() {
    api
      .get('/review/design_settings')
      .then(({ data }) => {
        this.orgFormObject = _.cloneDeep(data.design_settings);
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapActions('dialog', ['confirm']),
    ...mapActions('session', ['updateReviewSettings']),
    openDetailDialog(id) {
      this.confirm({
        type: 'alert',
        title: this.$t(`${id}.popup_title`),
        message: this.$t(`${id}.popup_message`, {
          image_asset_link: this.imageAssetLink(id)
        })
      });
    },
    imageAssetLink(id) {
      const imageName = this.isAdminLocaleKo ? id : `en_${id}`;

      return `\n\n<br />![](https://assets.cre.ma/m/admin/v2/${imageName}_dialog@2x.png)`;
    },
    submit(formData) {
      this.isLoading = true;
      api
        .patch('/review/design_settings', formData)
        .then(() => {
          this.orgFormObject = _.cloneDeep(this.formObject);
          this.updateReviewSettings(this.formObject);
          this.createToast(this.$t('app.saved'));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setMainColorToAll(mainColor) {
      const mainColorOpacity8 = this.applyOpacityToHex(mainColor, 0.08);

      this.formObject = {
        ...this.formObject,
        main_color: mainColor,
        product_score_filter_highlight_color: mainColor,
        link_color: mainColor,
        star_color: mainColor,
        review_write_button_color: mainColor,
        new_review_popup_button_color: mainColor,
        review_keyword_text_color: mainColor,
        review_keyword_background_color: mainColorOpacity8,
        ai_keyword_filter_background_color: mainColor
      };
    },
    applyOpacityToHex(hexColor, opacity) {
      const alpha = Math.round(opacity * 255)
        .toString(16)
        .padStart(2, '0');

      return `${hexColor}${alpha}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.ReviewsettingsDesign__tip-box {
  white-space: pre-line;
}

.ReviewSettingsDesign__image {
  padding: 24px 32px;
  text-align: center;
  background-color: $color-grey-05;
  line-height: 0;
  margin-bottom: 24px;
}

.ReviewSettingsDesign__image-background {
  display: inline-block;
}

.ReviewSettingsDesign__advanced-setting-settings {
  float: left;
}

.ReviewSettingsDesign__advanced-setting-field {
  margin-top: 4px;
}

.ReviewSettingsRenewdDesign__advanced-setting-field--left {
  margin-right: 20px;
}
</style>

<i18n locale="ko">
{
  "tip": "쇼핑몰 메인 색상은 작성 팝업 및 위젯의 버튼 색상, 위젯 상단의 평점 그래프 평점 그래프 바 하이라이트 색상, 별점 아이콘 색상에 적용됩니다.\n각 항목별 상세한 색상을 설정하고 싶으면 아래 고급 설정 보기를 클릭 한 다음 설정할 수 있습니다.",
  "main_color": {
    "description": "메인 색상을 설정하면 고급 설정의 요소별 색상이 모두 같은 색으로 일괄 설정됩니다.",
    "hint_not_valid": "고급 설정의 요소별 색상이 각기 다른 색상으로 설정되어 있습니다. 색상을 일괄 변경하고 싶은 경우 이 설정의 색상을 다시 설정한 후 하단 [저장] 버튼을 클릭해 주세요."
  },
  "product_score_filter_color_settings": {
    "description": "리스트 위젯 상단에 위치한 평점 그래프 바의 색상을 설정할 수 있습니다.",
    "popup_title": "위젯 상단 평점 그래프 바 색상",
    "popup_message": "리스트 위젯 상단에 위치한 평점 그래프 바의 색상을 설정할 수 있습니다.{image_asset_link}"
  },
  "product_score_filter_highlight_color": {
    "label": "하이라이트 색상"
  },
  "product_score_filter_color": {
    "label": "기본 색상"
  },
  "link_color": {
    "label": "더보기/접기 문구 색상",
    "description": "리뷰가 접혀있을 때, 노출되는 리뷰 더보기/리뷰 접기 버튼의 색상을 설정할 수 있습니다.",
    "popup_title": "리뷰가 접혀있을 때, 더보기/접기 문구 색상",
    "popup_message": "리뷰 위젯에 리뷰가 접혀있을 때 노출되는 '더보기/접기' 문구의 색상을 설정할 수 있습니다.{image_asset_link}"
  },
  "star_color": {
    "label": "별점 아이콘 색상",
    "description": "위젯 및 작성 팝업에 노출되는 별점 아이콘 색상을 설정할 수 있습니다.",
    "popup_title": "별점 아이콘 색상",
    "popup_message": "리뷰 위젯 및 작성 팝업에 노출되는 별점 아이콘 색상을 설정할 수 있습니다.{image_asset_link}"
  },
  "review_write_button_color_settings": {
    "description": "위젯에 있는 리뷰 작성 버튼의 색상을 설정할 수 있습니다.",
    "popup_title": "위젯의 리뷰 작성 버튼 색상",
    "popup_message": "위젯에 있는 리뷰 작성 버튼의 색상을 설정할 수 있습니다.{image_asset_link}"
  },
  "review_write_button_color": {
    "label": "배경 색상"
  },
  "review_write_button_text_color": {
    "label": "문구 색상"
  },
  "new_review_popup_button_color_settings": {
    "description": "작성 팝업 및 작성 유도 팝업 버튼의 색상을 설정할 수 있습니다.",
    "popup_title": "작성 팝업 버튼 색상",
    "popup_message": "작성 팝업 및 작성 유도 팝업 버튼의 색상을 설정할 수 있습니다.{image_asset_link}"
  },
  "keyword_filter_tag_color_settings": {
    "description": "리스트 위젯 V3의 AI 키워드 필터 버튼의 색상을 설정할 수 있습니다.",
    "popup_title": "위젯의 AI 키워드 필터 버튼 색상",
    "popup_message": "리스트 위젯 V3의 AI 키워드 필터 버튼의 색상을 설정할 수 있습니다.{image_asset_link}"
  },
  "review_tag_color_settings": {
    "description": "리스트 위젯 V3의 배지 색상을 설정할 수 있습니다.",
    "popup_title": "위젯의 배지 색상",
    "popup_message": "리스트 위젯 V3의 배지 색상을 설정할 수 있습니다.{image_asset_link}"
  },
  "new_review_popup_button_color": {
    "label": "배경 색상"
  },
  "new_review_popup_button_text_color": {
    "label": "문구 색상"
  },
  "advanced_tip_box" : {
    "text": "위젯 및 작성 팝업에 사용되는 디자인 요소의 색상을 설정할 수 있습니다."
  },
  "review_keyword_background_color": {
    "label": "배경 색상"
  },
  "review_keyword_text_color": {
    "label": "문구 색상"
  },
  "ai_keyword_filter_background_color":{
    "label": "배경 색상"
  },
  "ai_keyword_filter_text_color": {
    "label": "문구 색상"
  }
}
</i18n>
