<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <p v-html="$t('description_html')" />
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'ErrorUnauthorized',
  mounted() {
    this.SET_TITLE(this.$t('title'));
  },
  methods: {
    ...mapMutations(['SET_TITLE'])
  }
};
</script>

<i18n locale="ko">
{
  "title": "접근 권한이 없습니다.",
  "description_html": "이 페이지에 대한 접근 권한이 없습니다."
}
</i18n>
