<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__item">
        <AppEmbedPdf
          url="https://assets.cre.ma/m/about-crema.pdf"
          :width="800"
          :height="1000"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'UiEmbedPdf' };
</script>
