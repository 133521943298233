<template>
  <AppSelectSearchable
    v-bind="{
      id,
      title,
      name,
      items: productOptionValues,
      disabled,
      invalid,
      placeholder,
      multiple,
      selectButtonLabel: $t('select_product_option_value')
    }"
    :columns="[{ id: 'value', label: $t('product_option_value.value') }]"
    :search-types="[
      { value: 'value', label: $t('product_option_value.value') }
    ]"
    v-on="$listeners"
    @search="search"
  />
</template>

<script>
import api from '@/lib/api';

export default {
  name: 'AppSelectProductOptionValue',
  props: {
    productOptionTypeId: { type: Number, required: true },
    id: { type: String, default: null },
    title: {
      type: String,
      default() {
        return this.$t('select_product_option_value');
      }
    },
    name: { type: String, default: null },
    productOptionValues: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default() {
        return this.$t('placeholder');
      }
    },
    multiple: { type: Boolean, default: false },
    defaultParams: { type: Object, default: () => ({}) }
  },
  methods: {
    search({ params, onSuccess, onFailure }) {
      api
        .get('/product_option_values', {
          params: {
            ...this.defaultParams,
            ...params,
            product_option_type_id: this.productOptionTypeId
          }
        })
        .then(({ data }) =>
          onSuccess({
            ...data.product_option_values,
            items: data.product_option_values.items.map(item => ({
              ...item,
              name: item.value
            }))
          })
        )
        .catch(onFailure);
    }
  }
};
</script>

<i18n locale="ko">
{
  "select_product_option_value": "상품 옵션값 선택",
  "placeholder": "상품 옵션값을 선택해주세요."
}
</i18n>
