var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppResourceTable',{staticClass:"DisplayCornersTable",attrs:{"columns":_vm.columns,"rows":_vm.rows,"enable-total-count":false,"filter-options":_vm.filterOptions,"filter-values":_vm.categoryFilterValues,"custom-buttons":_vm.customButtons,"table-id":"corner-table","table-style":"wide"},on:{"change-filter-values":function($event){_vm.categoryFilterValues = $event}},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
var value = ref.value;
return [(column === 'favorite')?[_c('button',{on:{"click":function($event){return _vm.requestFavorite(row)}}},[_c('AppSvgIcon',{class:[
              'DisplayCornersTable__favorite-content',
              value ? 'DisplayCornersTable__favorite-content--favorited' : ''
            ],attrs:{"name":"icon-star"}})],1)]:_vm._e(),(column === 'virtual')?[(value)?_c('AppBadge',{attrs:{"badge-style":"grey-outline","label":_vm.$t('column.CATEGORY.virtual.tag')}}):_vm._e()]:(column === 'name')?[_c('span',{staticClass:"DisplayCornersTable__name-content"},[_vm._v(_vm._s(value))])]:(column === 'last_displayed_at')?[(value)?[_c('div',[_vm._v(_vm._s(_vm._f("formatDateTimeWithoutSecond")(value)))]),_c('AppBadge',{staticClass:"DisplayCornersTable__detail",attrs:{"label":row.last_display_name,"badge-style":"grey-outline","with-max-width":""}})]:[_vm._v("-")]]:(column === 'scheduled_product_displays')?[(value.length > 0)?[_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutSecond")(value[0].display_at))+" ")]),_c('DisplayCornerScheduledProductDisplaysSummary',{staticClass:"DisplayCornersTable__detail",attrs:{"scheduled-product-displays":row.scheduled_product_displays}})]:[_vm._v("-")]]:(column === 'corner_status')?[_c('DisplayCornerStatusBadge',{attrs:{"corner-status":row.corner_status,"cohort":row.applying_cohort}}),(_vm.isErrored(row.corner_status))?_c('AppButton',{attrs:{"label":_vm.$t('show_detail'),"button-style":"underline"},on:{"click":function($event){return _vm.openErrorDetailDialog(row)}}}):_vm._e(),(_vm.DisplayCornerStatus.isDeleted(row.corner_status))?_c('DisplayCornerDeleteButton',{staticClass:"DisplayCornersTable__detail",attrs:{"corner":row},on:{"delete":function($event){return _vm.removeCornerFromList(row.id)}}}):_vm._e()]:(column === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('button.manage', [Math.min(row.displays_count, 99)])},on:{"click":function($event){return _vm.openDisplayCornerShowDrawer(row, 'product_displays')}}}),(_vm.isCategoryPage)?_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"menu-items":[
            {
              id: 'favorite',
              label: _vm.$t(
                ("button." + (row.favorite ? 'favorited' : 'unfavorited'))
              ),
              selected: row.favorite,
              clickHandler: function () { return _vm.requestFavorite(row); }
            },
            {
              id: 'history',
              label: _vm.$t('button.history'),
              clickHandler: function () { return _vm.openDisplayCornerShowDrawer(row, 'cohorts'); }
            }
          ]}}):_c('AppButton',{attrs:{"label":_vm.$t('button.history'),"disabled":!row.cohorts_exist},on:{"click":function($event){return _vm.openDisplayCornerShowDrawer(row, 'cohorts')}}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }