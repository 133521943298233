<template>
  <a
    :class="['AppInlineButton', `AppInlineButton--style-${buttonStyle}`]"
    @click="$emit('click', $event)"
    >{{ label }}</a
  >
</template>

<script>
export default {
  name: 'AppInlineButton',
  props: {
    label: { type: String, required: true },
    buttonStyle: {
      type: String,
      default: 'default',
      validator: v => ['default', 'blue', 'caption'].includes(v)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppInlineButton--style-default {
  &:hover {
    text-decoration: underline;
  }
}

.AppInlineButton--style-blue {
  text-decoration: underline;
  color: $color-blue;
}

.AppInlineButton--style-caption {
  @include text-caption;
  text-decoration: underline;

  &:hover {
    @include text-caption-dark;
  }
}
</style>
