<template>
  <div
    :class="['AppDatePicker', { 'AppDatePicker--inline': inline }, 'dropdown']"
  >
    <AppButton
      :id="id"
      :label="value ? formatDate(value) : $t('select_date')"
      :disabled="disabled"
      :button-style="invalid ? 'red-outline' : undefined"
      class="AppDatePicker__button"
      @click="isDatePickerPopupVisible = !isDatePickerPopupVisible"
      @blur="$emit('blur')"
    />
    <AppDatePickerPopup
      v-if="isDatePickerPopupVisible"
      class="dropdown__dialog"
      :date="value"
      :min-date="minDate"
      :max-date="maxDate"
      @close="isDatePickerPopupVisible = false"
      @apply="apply"
    />
    <input v-if="name" type="hidden" :name="name" :value="value" />
  </div>
</template>

<script>
export default {
  name: 'AppDatePicker',
  model: { event: 'change' },
  props: {
    id: { type: String, default: null },
    value: { type: String, default: null },
    name: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    minDate: { type: String, default: null },
    maxDate: { type: String, default: null },
    inline: { type: Boolean, default: false }
  },
  data() {
    return {
      isDatePickerPopupVisible: false
    };
  },
  methods: {
    apply(date) {
      this.isDatePickerPopupVisible = false;
      this.$emit('change', date);
    }
  }
};
</script>

<i18n locale="ko">
{
  "select_date": "날짜 선택"
}
</i18n>

<style lang="scss" scoped>
.AppDatePicker {
  &--inline {
    margin-right: 4px;
  }
}
</style>
