<template>
  <AppButton
    button-style="blue"
    :disabled="buttonDisabled"
    :tooltip="tooltip"
    :label="label"
    @click="syncResource"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import BrandSyncType from '@/enums/BrandSyncType';
import BrandSyncStatus from '@/enums/BrandSyncStatus';
import ButtonSyncable from '@/mixins/ButtonSyncable';

export default {
  name: 'AppButtonSyncReview',
  mixins: [ButtonSyncable],
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null }
  },
  computed: {
    ...mapGetters('session', ['hostingName']),
    brandSyncType() {
      return BrandSyncType.REVIEW;
    },
    brandSyncTypeParams() {
      return {
        brand_sync_type: this.brandSyncType,
        start_date: this.startDate,
        end_date: this.endDate
      };
    },
    reviewSyncable() {
      return this.startDate && this.endDate;
    },
    buttonDisabled() {
      return (
        !this.reviewSyncable ||
        this.syncInfo?.sync_status !== BrandSyncStatus.IDLE
      );
    },
    tooltipIdleInfoText() {
      return this.$t('tooltip.idle_info', [this.$t('name')]);
    },
    syncConfirmMessage() {
      return this.$t('confirm.message', [
        this.formatDate(moment(this.startDate)),
        this.formatDate(moment(this.endDate)),
        this.hostingName
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "name": "호스팅사 리뷰 정보 수동",
  "tooltip" : {
    "idle_info": "버튼 클릭 시 선택한 기간의 {0}((가)) 동기화 됩니다."
  },
  "confirm": {
    "message": "<p>{0} - {1}사이에</p>{2}에 등록/수정된 리뷰 정보를 동기화 합니다.<br /><br />리뷰 정보 동기화는 최소5분, 최대 30분 후 완료됩니다.<br />동기화 진행 중에는 새로운 동기화를 실행 할 수 없습니다."
  }
}
</i18n>
