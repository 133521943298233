<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm
      :id="SETTINGS_ID"
      object-id="user"
      v-bind="formProps"
      v-on="formEvents"
      @change-group="changeGroup"
    >
      <template #group="{ id, options, errors, error }">
        <template v-if="id === 'password_changed_at'">
          <div class="AppForm__group-field">
            {{
              $t('password_changed_at.info', [elapsedDaysSincePasswordChange])
            }}
          </div>
          <AppFormError
            v-if="elapsedDaysSincePasswordChange > 180"
            :error="{ errorMessage: $t('password_changed_at.warning') }"
          />
        </template>
        <template v-else-if="id === 'session_timeout_enabled'">
          <div
            :class="[
              'AppForm__group-field',
              {
                'AppForm__group-field--disabled': !formObject.session_timeout_enabled
              }
            ]"
          >
            <i18n path="session_timeout_enabled.session_timeout_in_minutes">
              <AppNumberInput
                v-model="formObject.session_timeout_in_minutes"
                name="user[session_timeout_in_minutes]"
                :digits="2"
                :invalid="!!errors.session_timeout_in_minutes"
                :disabled="!formObject.session_timeout_enabled"
                inline
                @blur="validateField('session_timeout_in_minutes')"
                @change="validateField('session_timeout_in_minutes')"
              />
            </i18n>
            <AppFormError :error="errors.session_timeout_in_minutes" />
          </div>
        </template>
        <template v-else-if="id === 'email'">
          <AppEmailVerificationInput
            id="email"
            v-model="formObject.email"
            name="user[email]"
            :email-verification-status="formObject.email_verification_status"
            :placeholder="$t('email.placeholder')"
            :invalid="error && !error.after"
            @blur="
              formObject.email_verification_status !== 'verified_and_used' &&
                validateField('email')
            "
            @change="validateField('email')"
            @status="setEmailstatus"
          />
        </template>
        <template v-else-if="id === 'marketing'">
          <div class="AppForm__group-title AppFrom__group-title--marketing">
            {{ $t('marketing.label') }}
            <AppButton
              v-if="isAdminLocaleKo"
              :label="$t('terms.link')"
              type="external_link"
              button-style="blue-clear"
              to="https://assets.cre.ma/m/crema_marketinguse.pdf"
            />
          </div>
          <div class="AppForm__group-field">
            <AppMultipleSelectCheckbox
              v-model="formObject.terms_agreements"
              name="user[marketing][]"
              :options="options"
            />
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import api from '@/lib/api';
import TheSettingsDialogView from './TheSettingsDialogView';
import PasswordField from '@/mixins/PasswordField';
import LogisticsSolutionType from '@/enums/LogisticsSolutionType';

export default {
  name: 'TheSettingsDialogUser',
  mixins: [TheSettingsDialogView, PasswordField],
  data() {
    return {
      SETTINGS_ID: 'user_settings',
      SETTINGS_URL: '/user',
      logisticsSettingsError: null
    };
  },
  computed: {
    ...mapGetters('session', ['isCurrentUserAdmin', 'adminLocale']),
    groupOptions() {
      if (this.isLoading) return {};

      const logisticsValidationRule = [
        {
          rule: () => !this.logisticsSettingsError,
          errorMessage: this.logisticsSettingsError
            ? this.$t(`logistics_settings.${this.logisticsSettingsError}`)
            : ''
        }
      ];

      return {
        image: { type: 'image' },
        email: {
          type: 'email',
          placeholder: this.$t('email.placeholder'),
          validate: [
            'required',
            'email_format',
            {
              rule: () =>
                ['verified_and_used', 'verified', 'none'].includes(
                  this.formObject.email_verification_status
                ),
              errorMessage: '이메일 인증이 필요합니다.',
              after: true
            },
            { rule: 'check_email_duplication', async: true }
          ]
        },
        name: {
          type: 'text',
          placeholder: this.$t('name.placeholder'),
          validate: ['required']
        },
        phone: {
          type: 'tel',
          placeholder: this.$t('phone.placeholder'),
          validate: ['required', 'korean_phone_format']
        },
        mall_username: { type: 'text', hint: this.$t('mall_username.hint') },
        mall_admin_username: {
          type: 'text',
          placeholder: this.$t('mall_admin_username.placeholder')
        },
        marketing: {
          label: null,
          options: [
            {
              label: 'SMS',
              value: 'sms'
            },
            {
              label: 'email',
              value: 'email'
            }
          ]
        },
        mall_admin_password: {
          type: 'password',
          placeholder: this.$t('mall_admin_password.placeholder')
        },
        'logistics_settings.enabled': {
          type: 'checkbox',
          groupDescription: this.$t('logistics_settings.enabled.description')
        },
        'logistics_settings.solution_type': {
          type: 'select_radio',
          options: LogisticsSolutionType.options([
            LogisticsSolutionType.SELLPIA,
            LogisticsSolutionType.EZADMIN
          ]),
          labelDisabled: !this.formObject.logistics_settings.enabled,
          disabled: !this.formObject.logistics_settings.enabled,
          value:
            this.formObject.logistics_settings.solution_type ===
            LogisticsSolutionType.NONE
              ? LogisticsSolutionType.SELLPIA
              : this.formObject.logistics_settings.solution_type
        },
        'logistics_settings.domain_for_login': {
          type: 'text',
          placeholder: this.$t(
            'logistics_settings.domain_for_login.placeholder'
          ),
          required: this.formObject.logistics_settings.enabled,
          labelDisabled: !this.formObject.logistics_settings.enabled,
          disabled: !this.formObject.logistics_settings.enabled,
          validate: [
            ...logisticsValidationRule,
            this.formObject.logistics_settings.enabled ? 'required' : null,
            this.formObject.logistics_settings.enabled &&
            this.formObject.logistics_settings.solution_type !==
              LogisticsSolutionType.EZADMIN
              ? {
                  rule: v => /^\w+\.sellpia\.com$/.test(v),
                  errorMessage: this.$t(
                    'logistics_settings.domain_for_login.invalid_format'
                  )
                }
              : null
          ].filter(Boolean)
        },
        'logistics_settings.username': {
          type: 'text',
          placeholder: this.$t('logistics_settings.username.placeholder'),
          required: this.formObject.logistics_settings.enabled,
          labelDisabled: !this.formObject.logistics_settings.enabled,
          disabled: !this.formObject.logistics_settings.enabled,
          validate: [
            ...logisticsValidationRule,
            this.formObject.logistics_settings.enabled ? 'required' : null
          ].filter(Boolean)
        },
        'logistics_settings.password': {
          type: 'password',
          placeholder: this.$t('logistics_settings.password.placeholder'),
          labelDisabled: !this.formObject.logistics_settings.enabled,
          disabled: !this.formObject.logistics_settings.enabled,
          validate: logisticsValidationRule
        },
        session_timeout_enabled: {
          type: 'checkbox',
          fields: this.formObject.session_timeout_enabled
            ? [
                {
                  id: 'session_timeout_in_minutes',
                  validate: ['required', 'positive_integer']
                }
              ]
            : []
        }
      };
    },
    elapsedDaysSincePasswordChange() {
      return moment().diff(this.formObject.password_changed_at, 'days');
    },
    isAdminLocaleKo() {
      return this.adminLocale === 'ko';
    }
  },
  watch: {
    data() {
      this.setOrgFormObject(this.data);
    },
    'formObject.logistics_settings.solution_type'() {
      this.logisticsSettingsError = null;
    },
    'formObject.logistics_settings.username'() {
      this.logisticsSettingsError = null;
    },
    'formObject.logistics_settings.domain_for_login'() {
      this.logisticsSettingsError = null;
    },
    'formObject.logistics_settings.password'() {
      this.logisticsSettingsError = null;
    }
  },
  methods: {
    formSectionGroups(section) {
      if (section.id === 'security') {
        const securityGroups = [];
        section.groups.forEach(group => {
          if (group.id === 'password') {
            const { current_password, password } = this.formObject;
            const newPassword = password?.password;
            const isCurrentPasswordRequired =
              !this.isCurrentUserAdmin && newPassword;
            securityGroups.push(
              this.currentPasswordGroup(isCurrentPasswordRequired)
            );
            securityGroups.push(this.newPasswordGroup(!!current_password));
            securityGroups.push(this.newPasswordRequirementsGroup);
          } else securityGroups.push(this.formSectionGroup(group));
        });
        return securityGroups;
      } else return section.groups.map(group => this.formSectionGroup(group));
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(this.SETTINGS_URL, formData, {
          successMessage: this.$t('app.saved'),
          silent: true
        })
        .then(() => {
          this.$emit('submitted');
          this.fetchSession();
        })
        .catch(error => {
          let isErrorHandled = false;
          if (
            error.response.data.errors.some(
              e => e.attribute === 'current_password'
            )
          ) {
            isErrorHandled = true;
            this.setCurrentPasswordInvalid();
            this.formEventBus.$emit('scroll-to-group', 'current_password');
          }
          const logisticsSettingsError = error.response.data.errors.find(e =>
            e.model.startsWith('logistics/settings')
          );
          if (logisticsSettingsError) {
            isErrorHandled = true;
            this.logisticsSettingsError = logisticsSettingsError.error_type;
            this.validateField('logistics_settings.domain_for_login');
            this.validateField('logistics_settings.username');
            this.validateField('logistics_settings.password');
          }
          if (isErrorHandled) return;

          error.errorHandler();
        })
        .finally(() => (this.isSubmitting = false));
    },
    setOrgFormObject(orgFormObject) {
      this.orgFormObject = {
        ...orgFormObject,
        current_password: '',
        password: { password: '', password_confirmation: '' }
      };
    },
    setEmailstatus(status) {
      this.formObject.email_verification_status = status;
      this.validateField('email');
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppFrom__group-title--marketing .AppButton {
    vertical-align: unset;
  }
  .AppFrom__group-title--marketing .AppButton__button {
    border: none;
    padding: 0;
    margin-left: 4px;
  }
}
</style>

<i18n locale="ko">
{
  "email": {
    "placeholder": "사용하실 이메일 주소를 입력해주세요.",
    "edit_button": "변경 요청"
  },
  "name": {
    "placeholder": "이름을 입력해주세요."
  },
  "phone": {
    "placeholder": "전화번호를 입력해주세요."
  },
  "mall_username": {
    "hint": "관리자 리뷰작성이나 고객 리뷰 댓글 작성 시 입력됩니다. 관리자 이름 또는 아이디를 입력해주세요."
  },
  "marketing": {
    "label": "마케팅 수신동의"
  },
  "mall_admin_username": {
    "placeholder": "부운영자 아이디(ID)를 입력해주세요."
  },
  "mall_admin_password": {
    "placeholder": "부운영자 비밀번호를 입력해주세요."
  },
  "logistics_settings": {
    "enabled": {
      "description": "물류 솔루션으로부터 재고 정보를 연동하여 진열 및 상품 관리에 사용합니다."
    },
    "domain_for_login": {
      "placeholder": "물류 솔루션 도메인을 입력해주세요.",
      "invalid_format": "잘못된 형식의 도메인 입니다. (sample.sellpia.com 형태로 입력해주세요)"
    },
    "username": {
      "placeholder": "물류 솔루션 아이디를 입력해주세요."
    },
    "password": {
      "placeholder": "물류 솔루션 비밀번호를 입력해주세요."
    },
    "invalid": "물류 솔루션에 접속할 수 없습니다. 입력하신 정보를 확인해 주세요.",
    "internal_error": "서버로부터 응답이 없어 계정확인이 불가합니다. 잠시 후 다시 시도해주세요."
  },
  "password_changed_at": {
    "info": "{0}일 전에 마지막으로 수정되었습니다.",
    "warning": "안전한 개인정보 보호를 위해 비밀번호는 주기적(최소 3개월)으로 변경하는 것이 좋습니다."
  },
  "session_timeout_enabled": {
    "session_timeout_in_minutes": "{0}분 동안 동작이 없으면, 관리자 페이지에서 자동으로 로그아웃됩니다."
  }
}
</i18n>
