import Vue from 'vue';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';

const state = { batchJobs: nullResources };

const mutations = {
  SET_BATCH_JOBS(state, batchJobs) {
    state.batchJobs = batchJobs;
  },
  CREATE_BATCH_JOB({ batchJobs }, batchJob) {
    batchJobs.items.unshift(batchJob);
    Vue.set(batchJobs, 'total_count', batchJobs.total_count + 1);
  },
  UPDATE_BATCH_JOBS({ batchJobs }, updatedJobs) {
    updatedJobs.forEach(batchJob => {
      const index = batchJobs.items.findIndex(c => c.id === batchJob.id);
      if (index !== -1) Vue.set(batchJobs.items, index, batchJob);
    });
  }
};

const actions = {
  fetchBatchJobs({ commit }, params) {
    api
      .get('/batch_jobs', { requestId: 'BATCH_JOBS_REQUEST_ID', params })
      .then(({ data }) => commit('SET_BATCH_JOBS', data.batch_jobs));
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
