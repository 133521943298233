<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="subTitle"
    :form-props="{ ...formProps, disabled: !isValid }"
    v-on="formEvents"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <AppInfoBox v-html="$t('info_html')" />
    <AppDataSection
      class="DisplayCornerExcludingProductDialog__product-info"
      :title="$t('product_info.title')"
      >{{ productsName }}</AppDataSection
    >
    <template #group="{ id, errors }">
      <template v-if="id === 'excluded_until_type'">
        <div class="AppForm__group-field">
          <AppSelectRadio
            v-model="formObject.excluded_until_type"
            :options="excludedUntilOptions"
          >
            <template #sub-item="{ value, checked }">
              <template v-if="value">
                <AppDatePicker
                  v-model="formObject.excluded_until"
                  :min-date="minDate"
                  :disabled="!checked"
                  :invalid="!!errors.excluded_until_date"
                  @blur="validateField('excluded_until_date')"
                  @change="validateField('excluded_until_date')"
                />
                <AppFormError :error="errors.excluded_until_date" />
              </template>
            </template>
          </AppSelectRadio>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import DisplayCornerHandleProductView from '@/mixins/DisplayCornerHandleProductView';

export default {
  name: 'DisplayCornerExcludingProductDialog',
  mixins: [DisplayCornerHandleProductView],
  computed: {
    minDate() {
      return moment().toVal();
    },
    formSections() {
      return this.isValid
        ? [
            {
              groups: [
                {
                  id: 'excluded_until_type',
                  label: this.$t('excluded_until_type'),
                  type: 'select_radio'
                }
              ]
            }
          ]
        : [];
    },
    excludedUntilOptions() {
      return [
        { id: 'unlimited', label: this.$t('unlimited'), value: false },
        { id: 'selected_date', label: this.$t('selected_date'), value: true }
      ];
    }
  },
  mounted() {
    this.formObject = { excluded_until_type: false, excluded_until: '' };
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapActions('displayProductDisplay', ['excludeProducts']),
    submit() {
      const isUnlimited = this.formObject.excluded_until_type == 0;
      this.excludeProducts({
        productIds: this.selectedProducts.map(product => product.id),
        excludedUntil: isUnlimited ? '' : this.formObject.excluded_until
      });
      this.createToast(
        this.$t('toast.excluded', [this.selectedProducts.length])
      );
      this.eventBus.$emit('exclude');
      this.close(true);
    }
  }
};
</script>

<style scoped>
.DisplayCornerExcludingProductDialog__product-info {
  margin-top: 12px;
}
</style>

<i18n locale="ko">
{
  "info_html": "해당 진열 설정에 진열 제외할 상품을 추가합니다.<br />진열 제외된 상품은 진열 제외 탭에 등록되며 진열 규칙에 해당되도 표시되지 않습니다.",
  "title": "진열 제외",
  "product_info": {
    "title": "진열 제외 상품"
  },
  "excluded_product": "진열 제외 상품",
  "excluded_until_type": "제외 기간",
  "unlimited": "무기한 상품 제외",
  "selected_date": "선택한 날짜 까지 제외",
  "excluded_until_must_be_greater_than_today": "진열 제외일은 오늘보다 같거나 이후여야 합니다.",
  "toast": {
    "excluded": "{0}개의 상품을 진열 제외하였습니다."
  }
}
</i18n>
