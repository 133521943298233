<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <div class="UiRate__line">
        <span class="UiRate__label">1:</span><AppRate :score="1" />
      </div>
      <div class="UiRate__line">
        <span class="UiRate__label">2:</span><AppRate :score="2" />
      </div>
      <div class="UiRate__line">
        <span class="UiRate__label">3:</span><AppRate :score="3" />
      </div>
      <div class="UiRate__line">
        <span class="UiRate__label">4:</span><AppRate :score="4" />
      </div>
      <div class="UiRate__line">
        <span class="UiRate__label">5:</span><AppRate :score="5" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiRate'
};
</script>

<style scoped>
.UiRate__line {
  vertical-align: middle;
}

.UiRate__label {
  margin-right: 4px;
  vertical-align: middle;
  display: inline-block;
}
</style>
