import moment from 'moment';
import TargetCampaignStatus from '@/enums/TargetCampaignStatus';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';

const state = { campaign: null };

const getters = {
  isOneTimeCampaign: ({ campaign }) =>
    TargetCampaignType.isOneTime(campaign.campaign_type),
  isRecurringCampaign: (_state, { isOneTimeCampaign }) => !isOneTimeCampaign,
  isDmCampaign: ({ campaign }) =>
    campaign.campaign_media_type === TargetCampaignMediaType.DIRECT_MESSAGE,
  isPopupCampaign: ({ campaign }) =>
    campaign.campaign_media_type === TargetCampaignMediaType.POPUP,
  isWidgetCampaign: ({ campaign }) =>
    campaign.campaign_media_type === TargetCampaignMediaType.WIDGET,
  isArchivedCampaign: ({ campaign }) =>
    campaign.status === TargetCampaignStatus.ARCHIVED,
  isUtmPopupCampaign: ({ campaign }) =>
    campaign.campaign_type === TargetCampaignType.UTM_POPUP,
  isCategoryPageWidgetCampaign: ({ campaign }) =>
    campaign.campaign_type ===
    TargetCampaignType.PRODUCT_RECOMMENDATION_CATEGORY_PAGE,
  isReviewHighlightWidgetCampaign: ({ campaign }) =>
    campaign.campaign_type ===
    TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT,
  isCartPopupWidgetCampaign: ({ campaign }) =>
    campaign.campaign_type ===
    TargetCampaignType.PRODUCT_RECOMMENDATION_CART_POPUP
};

const couponStatus = ({ start_at, end_at }) => {
  if (start_at && end_at) {
    const now = moment();
    return now.diff(moment(start_at)) < 0
      ? 'pending'
      : now.diff(moment(end_at)) < 0
      ? 'active'
      : 'expired';
  } else return 'active';
};

const mutations = {
  SET_CAMPAIGN: (state, campaign) => {
    state.campaign = campaign
      ? {
          ...campaign,
          selected_coupons: campaign.selected_coupons.map(coupon => ({
            ...coupon,
            coupon_status: couponStatus(coupon)
          }))
        }
      : campaign;
  },
  SET_CAMPAIGN_NAME: (state, name) =>
    (state.campaign = { ...state.campaign, name }),
  SET_CAMPAIGN_STATUS: (state, status) =>
    (state.campaign = { ...state.campaign, status }),
  SET_DISPATCH_ESTIMATION_STATUS: (state, status) =>
    (state.campaign.dispatch_estimation_status = status)
};

export default { namespaced: true, state, getters, mutations };
