const isNotProductionEnvironment = ({ state }) => state.env !== 'production';

export default [
  {
    id: 'display_settings_basic',
    menuItemId: 'display_settings_basic',
    sections: [
      {
        groups: [
          { id: 'main_page_design_url' },
          { id: 'sold_out_criteria' },
          { id: 'reselling_criteria' },
          { id: 'purchase_rate_filter_criteria' },
          { id: 'enable_displaying_category_corners' },
          { id: 'logistics_settings_button' }
        ]
      }
    ]
  },
  {
    id: 'display_settings_admin',
    menuItemId: 'display_settings_admin',
    sections: [
      {
        groups: [
          { id: 'inactive_main_corner_codes' },
          {
            id: 'display_in_test_server',
            isAccessible: isNotProductionEnvironment
          },
          { id: 'launch_display_menu' },
          { id: 'recalculate_target_product_data' }
        ]
      }
    ]
  }
];
