<template>
  <div class="TargetCampaignNameCell">
    <div class="TargetCampaignNameCell__campaign-group-type">
      {{ TargetCampaignGroupType.t(campaignGroupType) }}
    </div>
    <div>{{ campaignName }}</div>
  </div>
</template>

<script>
import TargetCampaignGroupType from '@/enums/TargetCampaignGroupType';

export default {
  name: 'TargetCampaignNameCell',
  props: {
    campaignGroupType: { type: Number, required: true },
    campaignName: { type: String, required: true }
  },
  computed: { TargetCampaignGroupType: () => TargetCampaignGroupType }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetCampaignNameCell__campaign-group-type {
  @include text-caption;
}
</style>
