<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppSearchInput v-model="valueBasic" placeholder="Input query" />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiSearchInput',
  data() {
    return { valueBasic: '' };
  }
};
</script>
