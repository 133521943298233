<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppHashSelectCheckbox v-model="hashBasic" :options="OPTIONS_BASIC" />
      <div class="ui-section__description">Selected Value: {{ hashBasic }}</div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">SlotLabel</div>
      <AppHashSelectCheckbox v-model="hashSlotLabel" :options="OPTIONS_BASIC">
        <template #label="{ value, checked }">
          <div v-if="value === 'ant_man'">
            앤트맨
            <AppNumberInput :disabled="!checked" :digits="1" />
            호
          </div>
        </template>
      </AppHashSelectCheckbox>
      <div class="ui-section__description">
        Selected Value: {{ hashSlotLabel }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">SubItem</div>
      <AppHashSelectCheckbox v-model="hashSubItem" :options="OPTIONS_BASIC">
        <template #sub-item="{ value, checked }">
          <AppTextInput
            v-if="value === 'ant_man'"
            :disabled="!checked"
            :placeholder="`SubItem for ${value}`"
          />
          <AppTextInput
            v-else-if="value === 'black_widow'"
            :disabled="!checked"
            :placeholder="`SubItem for ${value}`"
          />
        </template>
      </AppHashSelectCheckbox>
      <div class="ui-section__description">
        Selected Value: {{ hashSubItem }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiHashSelectCheckbox',
  data() {
    return {
      OPTIONS_BASIC: [
        { id: 'ant_man', label: 'Ant-Man' },
        { id: 'black_panther', label: 'Black Panther' },
        { id: 'black_widow', label: '블랙 위도우' },
        { id: 'captain_america', label: '캡틴 아메리카' },
        { id: 'captain_marvel', label: 'Captain Marvel' },
        { id: 'doctor_strange', label: 'Doctor Strange' },
        { id: 'falcon', label: 'Falcon', disabled: true },
        {
          id: 'guardians_of_the_galaxy',
          label:
            'Guardians of the Galaxy - Drax the Destroyer, Gamora, Groot, Star-Lord, Rocket'
        },
        { id: 'hawkeye', label: '호크아이' },
        { id: 'hulk', label: '헐크' },
        { id: 'ironman', label: '아이언맨' },
        { id: 'scarlet_witch', label: 'Scarlet Witch' },
        { id: 'spider_man', label: 'Spider-Man' },
        { id: 'thor', label: '토르' },
        { id: 'vision', label: 'Vision' },
        { id: 'war_machine', label: 'War Machine' },
        { id: 'wasp', label: 'Wasp' },
        { id: 'winter_soldier', label: 'Winter Soldier' }
      ],
      hashBasic: {
        black_widow: true,
        captain_america: true,
        hawkeye: true,
        hulk: true,
        ironman: true,
        thor: true
      },
      hashSlotLabel: {
        black_widow: true,
        captain_america: true,
        hawkeye: true,
        hulk: true,
        ironman: true,
        thor: true
      },
      hashSubItem: {
        black_widow: true,
        captain_america: true,
        hawkeye: true,
        hulk: true,
        ironman: true,
        thor: true
      }
    };
  },
  methods: {
    change(options, option) {
      options.find(o => o.id === option.id).value = option.value;
    }
  }
};
</script>
