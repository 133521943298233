<template>
  <AppJob :job="job" @click="click" />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppAsyncJob',
  props: { job: { type: Object, required: true } },
  methods: {
    ...mapActions('asyncJob', ['cancelJob', 'completeJob']),
    ...mapActions('toast', ['createToast']),
    click() {
      if (this.job.status === 'complete') this.clickComplete();
      else if (this.job.status === 'pending') this.clickCancel();
    },
    clickComplete() {
      this.completeJob(this.job.id);
    },
    clickCancel() {
      const { title } = this.job;
      if (!confirm(this.$t('cancel_confirm', [title]))) return;

      if (this.cancelJob(this.job.id))
        this.createToast(this.$t('cancel_success', [title]));
      else alert(this.$t('cancel_failure', [title]));
    }
  }
};
</script>

<i18n locale="ko">
{
  "cancel_confirm": "{0}((을)) 취소하시겠습니까?",
  "cancel_success": "{0}((이)) 취소되었습니다.",
  "cancel_failure": "{0}((이)) 이미 실행되었기 때문에 취소할 수 없습니다."
}
</i18n>
