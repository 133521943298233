<template>
  <div class="TheReviewSettingsProgressStep">
    <div class="TheReviewSettingsProgressStep__bullet-point">
      <AppSvgIcon :width="16" :height="18" name="icon-bullet-point" />
    </div>
    <div class="TheReviewSettingsProgressStep__step">{{ step }}</div>
    <div class="TheReviewSettingsProgressStep__arrow">
      <AppSvgIcon :width="7" :height="18" name="nav-chevron-right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheReviewSettingsProgressStep',
  props: {
    step: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheReviewSettingsProgressStep {
  display: flex;
  padding: 7px 0;
}

.TheReviewSettingsProgressStep__bullet-point {
  margin-right: 8px;
}

.TheReviewSettingsProgressStep__step {
  @include text-caption-bold-dark;
}

.TheReviewSettingsProgressStep__arrow {
  margin-left: auto;
  color: $color-grey-35;
}
</style>
