<template>
  <AppModalDefault
    :title="dateString + ' ' + $t('modal_title')"
    :sub-title="orgProduct.name"
    width="wide"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <template #default>
          <AnalyticsInsightProductDialogProduct
            v-bind="{ product, dateRange, analyticsType }"
          />
          <AppGrid class="AnalyticsInsightProductDialogProduct__grid">
            <div
              class="AnalyticsInsightProductDialogProduct__tables AppGrid__col AppGrid__col--5"
            >
              <AnalyticsInsightProductDialogAnalysisTables
                v-bind="{ product, analyticsType, reviewTagTypeIdsOrder }"
              />
            </div>
            <div
              class="AnalyticsInsightProductDialogProduct__analysis-graph AppGrid__col AppGrid__col--7"
            >
              <AnalyticsInsightProductDialogAnalysisGraph
                v-bind="{ product, keywordAnalysisMap }"
              />
            </div>
          </AppGrid>
          <AnalyticsInsightProductDialogReviewsCountGraph
            v-bind="{ product, dateRange, reviewsCountArray }"
          />
        </template>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';
import AnalyticsInsightProductDialogProduct from './AnalyticsInsightProductDialogProduct';
import AnalyticsInsightProductDialogAnalysisTables from './AnalyticsInsightProductDialogAnalysisTables';
import AnalyticsInsightProductDialogAnalysisGraph from './AnalyticsInsightProductDialogAnalysisGraph';
import AnalyticsInsightProductDialogReviewsCountGraph from './AnalyticsInsightProductDialogReviewsCountGraph';

export default {
  name: 'AnalyticsInsightProductDialog',
  components: {
    AnalyticsInsightProductDialogProduct,
    AnalyticsInsightProductDialogAnalysisTables,
    AnalyticsInsightProductDialogAnalysisGraph,
    AnalyticsInsightProductDialogReviewsCountGraph
  },
  mixins: [DialogView],
  props: {
    orgProduct: { type: Object, required: true },
    dateRange: { type: Object, required: true },
    analyticsType: { type: String, required: true },
    reviewTagTypeIdsOrder: { type: Array, required: true }
  },
  data() {
    return {
      isLoading: true,
      product: null,
      keywordAnalysisMap: {},
      reviewsCountArray: []
    };
  },
  computed: {
    dateString() {
      const { start_date, end_date } = this.dateRange;
      const startDate = start_date.replace(/-/g, '. ');
      const endDate = end_date.replace(/-/g, '. ');

      return `${startDate} ~ ${endDate}`;
    }
  },
  mounted() {
    this.isLoading = true;
    api
      .get(`/analytics/products/${this.orgProduct.id}`, {
        params: {
          ...this.dateRange,
          analytics_type: this.analyticsType
        }
      })
      .then(({ data }) => {
        this.product = { ..._.cloneDeep(this.orgProduct), ...data };
        this.keywordAnalysisMap = data.keyword_analysis_map;
        this.reviewsCountArray = data.reviews_count_array;
      })
      .finally(() => (this.isLoading = false));
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 931px) {
  .AnalyticsInsightProductDialogProduct__grid {
    display: block;
  }

  .AnalyticsInsightProductDialogProduct__tables,
  .AnalyticsInsightProductDialogProduct__analysis-graph {
    flex: unset;
    max-width: unset;
  }

  .AnalyticsInsightProductDialogProduct__analysis-graph {
    margin-top: 16px;
  }
}
</style>

<i18n locale="ko">
{
  "modal_title": "상품별 상세 분석 정보"
}
</i18n>
