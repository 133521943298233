<template>
  <AppCheckbox
    :checked="currentCheckStatus !== 'unchecked'"
    :icon-name="
      currentCheckStatus === 'partial' ? 'icon-checkbox-clear' : undefined
    "
    :label="label"
    :input-style="inputStyle"
    @change="change"
  >
    <template v-if="$scopedSlots.label" #label="labelProps">
      <slot name="label" v-bind="labelProps" />
    </template>
  </AppCheckbox>
</template>

<script>
export default {
  name: 'AppCheckboxPartial',
  model: {
    prop: 'checkStatus',
    event: 'change'
  },
  props: {
    checkStatus: {
      type: String,
      required: true,
      validator(val) {
        return ['checked', 'partial', 'unchecked'].includes(val);
      }
    },
    label: {
      type: String,
      default: null
    },
    inputStyle: {
      type: String,
      default: 'standalone'
    }
  },
  data() {
    return {
      currentCheckStatus: this.checkStatus
    };
  },
  watch: {
    checkStatus() {
      this.currentCheckStatus = this.checkStatus;
    }
  },
  methods: {
    change(checked) {
      this.currentCheckStatus = checked ? 'checked' : 'unchecked';
      this.$emit('change', this.currentCheckStatus);
    }
  }
};
</script>
