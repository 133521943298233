<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm
      class="TheAdvancedSettingsDialogDangerZone__form"
      v-bind="formProps"
      v-on="formEvents"
    />
  </AppAjaxContent>
</template>

<script>
import DialogSize from '@/enums/DialogSize';
import FormView from '@/mixins/FormView';
import { mapActions } from 'vuex';
import api from '@/lib/api';

export default {
  name: 'TheAdvancedSettingsDialogDangerZone',
  mixins: [FormView],
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            'delete_auto_installations',
            'initialize_widgets',
            'delete_smart_installations'
          ].map(id => ({
            id,
            type: 'button',
            buttonStyle: 'red',
            label: this.$t(`sections.${id}.label`),
            value: this.$t(`sections.${id}.label`),
            clickHandler: () => this.handleClick(id)
          }))
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    handleClick(id) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'check_confirm',
          title: this.$t(`sections.${id}.label`),
          markdownText: '',
          checkboxLabel: this.$t(`sections.${id}.checker`),
          width: DialogSize.SMALL,
          closeButtonStyle: 'red'
        }
      ]).then(eventBus => {
        eventBus.$on('cancel', () => this.createToast(this.$t('app.cancel')));
        eventBus.$on('close', () => {
          api.post(`/admin/danger_zone_advanced_settings/${id}`);
          this.createToast(this.$t('app.deleted'));
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheAdvancedSettingsDialogDangerZone__form {
  width: 680px;
  border-radius: 4px;
  margin: 16px auto 32px auto;
  padding: 24px;
  background-color: $color-background;
}
</style>

<i18n locale="ko">
{
  "sections": {
    "delete_auto_installations": {
      "label":"반자동화 설치 제거",
      "checker": "반자동화 설치 제거를 진행하시겠습니까?"
    },
    "initialize_widgets": {
      "label": "위젯 초기화",
      "checker": "위젯 초기화를 진행하시겠습니까?"
    },
    "delete_smart_installations": {
      "label": "간편설치 제거",
      "checker" :"간편설치 제거를 진행하시겠습니까?"
    }
  }
}
</i18n>
