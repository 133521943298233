<template>
  <div class="SummaryBoxRequestLaunchDateComplete">
    <AppSummaryBox>
      <template #title>
        {{ $t('title') }}
      </template>
      <div class="SummaryBoxRequestLaunchDateComplete__content">
        {{ $t('content') }}
      </div>
    </AppSummaryBox>
  </div>
</template>

<script>
export default {
  name: 'SummaryBoxRequestLaunchDateComplete'
};
</script>

<style lang="scss" scoped>
.SummaryBoxRequestLaunchDateComplete__content {
  font-size: 14px;
}
</style>

<i18n locale="ko">
{
  "title": "모든 준비가 완료되었습니다. 🥳️",
  "content": "크리마 담당자가 사용 시작 처리 후 신청서에 기재한 메일 주소로 안내드리겠습니다."
}
</i18n>
