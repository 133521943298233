<template>
  <div class="TargetWidgetCampaignsWidgetRecommendationUnits">
    <div
      class="table-head-control TargetWidgetCampaignsWidgetRecommendationUnits__button-wrapper"
    >
      <AppDropdownMenuButton
        class="TargetWidgetCampaignsWidgetRecommendationUnits__button"
        :label="$t('add_recommendation_unit.label')"
        :menu-items="unitOptions"
      />
    </div>
    <input
      v-if="!recommendationUnits.length"
      type="hidden"
      name="campaign[widget_recommendation_units][]"
      value=""
    />
    <AppTable
      :columns="[
        {
          id: 'filterName',
          label: $t('filter_name'),
          align: 'left'
        },
        {
          id: 'maxProducts',
          label: $t('max_products')
        },
        { id: 'actions', label: $t('actions') }
      ]"
      :rows="rows"
    >
      <template #cell="{ column, row, rowIndex }">
        <template v-if="column.id === 'filterName'">
          <div>
            {{ row.filterName }}
          </div>
          <AppBadge
            v-if="isCategoryPageWidgetCampaign"
            :label="row.displayableCategoriesInfo.label"
            :tooltip="{
              textAlign: 'left',
              message: row.displayableCategoriesInfo.tooltip
            }"
          />
        </template>
        <template v-else-if="column.id === 'actions'">
          <input
            v-for="(key, i) in [
              'use_displayable_category_ids',
              'main_title_text',
              'max_products',
              'title_type',
              'sub_title_text',
              'show_product_rank',
              'use_round_button',
              'show_review',
              'show_manager_review'
            ]"
            :key="`key-${i}`"
            type="hidden"
            :name="`campaign[widget_recommendation_units][][${key}]`"
            :value="row[key]"
          />
          <AppHiddenInputArray
            :name="
              `campaign[widget_recommendation_units][][displayable_category_ids][]`
            "
            :values="row['displayable_category_ids']"
          />
          <AppHiddenInputArray
            :name="
              `campaign[widget_recommendation_units][][precompute_category_ids][]`
            "
            :values="row['precompute_category_ids']"
          />
          <input
            v-if="row.title_type === TargetWidgetRecommendationTitleType.IMAGE"
            type="hidden"
            name="campaign[widget_recommendation_units][][title_image]"
            :value="row.title_image"
          />
          <input
            type="hidden"
            name="campaign[widget_recommendation_units][][recommendation_config]"
            :value="JSON.stringify(row.recommendation_config)"
          />

          <AppButtonDraggable
            :drag-text="row.filterName"
            @sort="sortProductRecommendationUnit"
          />
          <AppDropdownMenuButton
            :label="$t('app.manage')"
            :menu-items="[
              {
                label: $t('app.settings'),
                clickHandler: e =>
                  clickEditRecommendationUnit(e, row, rowIndex),
                ...recommendationUnitSettingsButtonData(
                  row.recommendation_config.recommendation_filter_type
                )
              },
              {
                label: $t('app.delete'),
                style: 'danger',
                clickHandler: e => clickDeleteRecommendationUnit(e, rowIndex),
                ...(recommendationUnits.length === 1 && {
                  disabled: true,
                  tooltip: $t('cannot_delete_recommendation_unit_html')
                })
              }
            ]"
          />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { arrayMoveIndex } from '@/lib/array';
import TargetWidgetRecommendationFilterType from '@/enums/TargetWidgetRecommendationFilterType';
import TargetWidgetRecommendationTitleType from '@/enums/TargetWidgetRecommendationTitleType';
import BrandReviewStatus from '@/enums/BrandReviewStatus';
import BrandFitStatus from '@/enums/BrandFitStatus';
import api from '@/lib/api';

export default {
  name: 'TargetWidgetCampaignsWidgetRecommendationUnits',
  model: { prop: 'recommendationUnits', event: 'change' },
  props: {
    recommendationUnits: { type: Array, required: true },
    campaignId: { type: Number, required: true },
    campaignType: { type: Number, required: true }
  },
  computed: {
    ...mapState('session', ['currentUser', 'currentBrand', 'targetSettings']),
    ...mapGetters('session', ['isUsingNlp']),
    ...mapGetters('productCategory', [
      'productCategoryShortInfo',
      'productCategoryInfo'
    ]),
    ...mapGetters('targetCampaign', ['isCategoryPageWidgetCampaign']),

    unitOptions() {
      return TargetWidgetRecommendationFilterType.options(
        TargetWidgetRecommendationFilterType.fromCampaignType(
          this.campaignType,
          this.targetSettings.features_under_construction
        )
      ).map(o => {
        const isAlreadySelected = this.recommendationUnits.find(
          v => o.value === v.recommendation_config.recommendation_filter_type
        );
        return {
          ...o,
          disabled: isAlreadySelected,
          selected: isAlreadySelected,
          clickHandler: ({ close }) =>
            this.clickAddRecommendationUnit({ close, filterType: o.value }),
          ...this.additionalUnitOptionData(o),
          title: TargetWidgetRecommendationFilterType.title(o.value)
        };
      });
    },
    rows() {
      return this.recommendationUnits.map(v => ({
        ...v,
        filterName: TargetWidgetRecommendationFilterType.tWithTitle(
          v.recommendation_config.recommendation_filter_type
        ),
        maxProducts: v.max_products,
        displayableCategoriesInfo: this.displayableCategoriesInfo(v)
      }));
    },
    TargetWidgetRecommendationTitleType() {
      return TargetWidgetRecommendationTitleType;
    },
    isReviewApproved() {
      return BrandReviewStatus.isApproved(this.currentBrand.review_status);
    },
    isReviewPreparing() {
      return BrandReviewStatus.isPreparing(this.currentBrand.review_status);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    reviewKeywordUnitData(filterType) {
      if (this.isUsingNlp) {
        return {
          tooltip: this.$t(
            `review_unit_tooltip.${TargetWidgetRecommendationFilterType.key(
              filterType
            )}_html`
          )
        };
      }
      return {
        tooltip: this.$t('review_unit_tooltip.require_use_nlp_html'),
        disabled: true
      };
    },
    reviewUnitOptionData({ value }) {
      if (
        [
          TargetWidgetRecommendationFilterType.HIGH_SCORED_PRODUCTS_IN_CATEGORY,
          TargetWidgetRecommendationFilterType.REVIEW_KEYWORD,
          TargetWidgetRecommendationFilterType.POTENTIAL_PRODUCTS
        ].includes(value)
      ) {
        if (!this.isReviewApproved) {
          return {
            tooltip: this.$t('review_unit_tooltip.not_approved'),
            disabled: true
          };
        } else if (this.isReviewPreparing) {
          return { tooltip: this.$t('review_unit_tooltip.is_preparing_html') };
        }
      }
      return null;
    },
    additionalUnitOptionData(option) {
      if (this.reviewUnitOptionData(option)) {
        return this.reviewUnitOptionData(option);
      }

      if (
        [
          TargetWidgetRecommendationFilterType.REVIEW_KEYWORD,
          TargetWidgetRecommendationFilterType.POTENTIAL_PRODUCTS
        ].includes(option.value)
      ) {
        return this.reviewKeywordUnitData(option.value);
      }

      if (
        option.value === TargetWidgetRecommendationFilterType.SIMILAR_SIZE &&
        !this.currentUser.menu.fit_accessible
      ) {
        return {
          clickHandler: ({ close }) => {
            close();
            this.openDialog([
              'TargetWidgetCampaignWidgetRecommendationUnitNotUsingFitDialog',
              { title: TargetWidgetRecommendationFilterType.t(option.value) }
            ]);
          }
        };
      }

      return {};
    },
    productCategoryInfos(ids) {
      return ids.map(id => this.productCategoryInfo(id)).join('<br />');
    },
    displayableCategoriesInfo(unit) {
      if (!unit.use_displayable_category_ids) {
        return {
          label: this.$t('displayable_categories.all.label'),
          tooltip: this.$t('displayable_categories.all.tooltip')
        };
      }
      if (unit.displayable_category_ids.length === 1) {
        return {
          label: this.$t('displayable_categories.one.label', [
            this.productCategoryShortInfo(unit.displayable_category_ids[0])
          ]),
          tooltip: this.$t('displayable_categories.one.tooltip_html', [
            this.productCategoryInfo(unit.displayable_category_ids[0])
          ])
        };
      }
      return {
        label: this.$t('displayable_categories.multiple.label', [
          this.productCategoryShortInfo(unit.displayable_category_ids[0]),
          unit.displayable_category_ids.length - 1
        ]),
        tooltip: this.$t('displayable_categories.multiple.tooltip_html', [
          this.productCategoryInfos(unit.displayable_category_ids)
        ])
      };
    },
    clickAddRecommendationUnit({ close, filterType }) {
      api
        .get(
          `/target/campaigns/${this.campaignId}/widget_recommendation_settings/new`,
          { params: { filter_type: filterType } }
        )
        .then(({ data }) => {
          if (
            TargetWidgetRecommendationFilterType.isInitialSettingsRequired(
              filterType
            )
          ) {
            this.openDialog([
              'TargetWidgetCampaignsWidgetRecommendationUnitsFormDialog',
              { recommendationUnit: data.widget_recommendation_unit }
            ]).then(eventBus => {
              eventBus.$on('change', data => {
                this.change([...this.recommendationUnits, data]);
              });
            });
          } else {
            this.change([
              ...this.recommendationUnits,
              data.widget_recommendation_unit
            ]);
          }
        })
        .finally(close);
    },
    clickEditRecommendationUnit({ close }, recommendationUnit, index) {
      close();

      this.openDialog([
        'TargetWidgetCampaignsWidgetRecommendationUnitsFormDialog',
        { recommendationUnit }
      ]).then(eventBus => {
        eventBus.$on('change', data =>
          this.change(
            this.recommendationUnits.map((unit, i) => {
              if (i === index) return data;
              return unit;
            })
          )
        );
      });
    },
    clickDeleteRecommendationUnit({ close }, rowIndex) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      close();
      this.$nextTick(() =>
        this.change(this.recommendationUnits.filter((p, i) => i !== rowIndex))
      );
    },
    sortProductRecommendationUnit({ srcIndex, dstIndex }) {
      this.change(arrayMoveIndex(this.recommendationUnits, srcIndex, dstIndex));
    },
    change(value) {
      this.$emit('change', value);
    },
    recommendationUnitService(recommendation_filter_type) {
      return TargetWidgetRecommendationFilterType.service(
        recommendation_filter_type
      );
    },
    disabledRecommendationUnitFilterService(recommendation_filter_type) {
      switch (this.recommendationUnitService(recommendation_filter_type)) {
        case 'fit':
          return BrandFitStatus.isTargetUsable(this.currentBrand.fit_status)
            ? null
            : 'fit';
        case 'review':
          return BrandReviewStatus.isTargetUsable(
            this.currentBrand.review_status
          )
            ? null
            : 'review';
        case 'review_analysis':
          if (
            !BrandReviewStatus.isTargetUsable(this.currentBrand.review_status)
          )
            return 'review';
          else if (!this.isUsingNlp) return 'review_analysis';
      }
      return null;
    },
    recommendationUnitSettingsButtonData(recommendation_filter_type) {
      const disable_service_type = this.disabledRecommendationUnitFilterService(
        recommendation_filter_type
      );
      if (disable_service_type)
        return {
          disabled: true,
          tooltip: this.$t(`disabled_settings_tooltip.${disable_service_type}`)
        };
      return {};
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetWidgetCampaignsWidgetRecommendationUnits {
  width: 100%;
  overflow-x: auto;
}

.TargetWidgetCampaignsWidgetRecommendationUnits__button-wrapper {
  height: 32px;
}

.TargetWidgetCampaignsWidgetRecommendationUnits__button {
  position: absolute;
}

::v-deep {
  .filter-name {
    min-width: 160px;
  }
  .max-products {
    min-width: 60px;
  }
  .actions {
    min-width: 150px;
  }
}
</style>

<i18n locale="ko">
{
  "displayable_categories": {
    "all": {
      "label": "전체",
      "tooltip": "이 추천 항목을 쇼핑몰의 모든 카테고리 페이지에 노출합니다."
    },
    "one": {
      "label": "{0}",
      "tooltip_html": "이 추천 항목을 아래 카테고리 페이지에 노출합니다.<br />{0}"
    },
    "multiple": {
      "label": "{0} 외 {1}개",
      "tooltip_html": "이 추천 항목을 아래 카테고리 페이지에 노출합니다.<br />{0}"
    }
  },
  "add_recommendation_unit": {
    "label": "+추천 항목 추가"
  },
  "filter_name": "추천 항목 이름",
  "max_products": "상품 수",
  "actions": "기능",
  "cannot_delete_recommendation_unit_html": "삭제할 수 없습니다.<br />최소 1개 이상의 추천 항목이 필요합니다.",
  "review_unit_tooltip": {
    "REVIEW_KEYWORD_html" : "긍정/부정 리뷰 비율을 고려한 만족도 총점이 높은 상품을 추천하며<br />리뷰 수가 많은 인기 상품이 추천될 가능성이 높습니다.",
    "POTENTIAL_PRODUCTS_html" : "분석된 리뷰의 만족도 평균이 높은 상품을 추천하며<br />아직 리뷰 수가 적지만 평가가 좋은 상품을 추천할 수 있습니다.",
    "not_approved" : "CREMA Review 서비스 사용이 필요합니다.",
    "is_preparing_html": "CREMA Review 서비스 결제 및 테스트 중 상태입니다.<br />해당 항목은 쇼핑몰에 CREMA Review 서비스가<br />라이브된 이후 정상적으로 사용 가능합니다.",
    "require_use_nlp_html": "CREMA Review 서비스의 [AI 리뷰 분석] 설정을<br />적용한 이후 정상적으로 사용 가능합니다."
  },
  "disabled_settings_tooltip": {
    "fit": "CREMA Fit 서비스 사용이 필요합니다.",
    "review": "CREMA Review 서비스 사용이 필요합니다.",
    "review_analysis": "CREMA Review 서비스의 [AI 리뷰 분석] 설정을<br />적용한 이후 정상적으로 사용 가능합니다."
  }
}
</i18n>
