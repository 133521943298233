import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import api from '@/lib/api';
import ResourceView from '@/mixins/ResourceView';
import TooltipHelper from '@/mixins/TooltipHelper';
import InsightProductsFilterView from '@/mixins/InsightProductsFilterView';

export default {
  mixins: [ResourceView, TooltipHelper, InsightProductsFilterView],
  data() {
    return {
      DEFAULT_COLUMNS: [
        {
          id: 'code',
          label: this.$t('products.code'),
          type: 'nowrap',
          required: true,
          autoTooltip: true
        },
        {
          id: 'name',
          label: this.$t('products.name'),
          type: 'nowrap',
          width: '280px',
          align: 'left',
          required: true,
          autoTooltip: true
        },
        {
          id: 'sales',
          label: this.$t('insight.products.sales'),
          type: 'number',
          width: '102px',
          align: 'right',
          default: '0.00',
          precision: 2,
          forcePrecision: true
        },
        {
          id: 'sub_orders_count',
          label: this.$t('insight.products.sub_orders_count'),
          type: 'number',
          width: '96px',
          align: 'right',
          default: '0'
        },
        {
          id: 'reviews_count',
          label: this.$t('insight.reviews_count'),
          type: 'number',
          width: '96px',
          align: 'right',
          default: '0'
        },
        {
          id: 'review_tags_count',
          label: this.$t('insight.review_tags_count'),
          type: 'number',
          width: '96px',
          align: 'right',
          default: '0'
        }
      ].map(column => ({
        ...column,
        group: this.$t('insight.products.info'),
        sortable: true
      })),
      isLoading: true,
      products: { items: [] },
      reviewTagTypeIdsOrder: [],
      KeywordAnalysisValueMin: null,
      KeywordAnalysisValueMax: null
    };
  },
  computed: {
    ...mapState(['dateRangeParams']),
    ...mapGetters('session', ['brandReviewTagTypes']),
    defaultResourceParams() {
      return {
        page: '1',
        per: '20',
        analytics_type: this.analyticsType,
        search_type: 'product_code',
        search_query: '',
        start_date:
          this.dateRangeParams.start_date ||
          moment()
            .subtract(1, 'year')
            .toVal(),
        end_date:
          this.dateRangeParams.end_date ||
          moment()
            .subtract(1, 'day')
            .toVal(),
        min_reviews_count: this.minReviewsCount,
        max_reviews_count: this.maxReviewsCount,
        ...this.defaultColumnOrderingParams
      };
    },
    columns() {
      return [
        ...this.DEFAULT_COLUMNS,
        this.COLUMN,
        {
          id: 'actions',
          width: '142px',
          label: this.$t('app.actions'),
          group: this.$t('insight.products.info'),
          required: true
        },
        ..._.sortBy(this.keywordColumns, column =>
          _.indexOf(this.reviewTagTypeIdsOrder, column.typeId)
        )
      ].map(c => ({
        ...c,
        sorted:
          this.resourceParams.order_column === c.id
            ? this.resourceParams.order_type
            : 'normal'
      }));
    },
    searchBarProps() {
      return {
        downloadExcelUrl: `/api/analytics/products/download_excel?${qs.stringify(
          this.resourceParams
        )}`,
        resourceParams: {
          ...this.resourceParams,
          min_reviews_count: this.minReviewsCount,
          max_reviews_count: this.maxReviewsCount
        },
        defaultMinReviewsCount: this.defaultMinReviewsCount
      };
    },
    minReviewsCount() {
      const count =
        this.$route.query.min_reviews_count ||
        this.minReviewsCountPreset(this.analyticsType);
      return count ? Number(count) : null;
    },
    maxReviewsCount() {
      const count =
        this.$route.query.max_reviews_count ||
        this.maxReviewsCountPreset(this.analyticsType);
      return count ? Number(count) : null;
    },
    searchBarEvents() {
      return {
        'change-search-data': this.changeSearchData,
        'change-date-range': this.changeDateRange,
        'change-advanced-search': this.changeAdvancedSearch
      };
    },
    rows() {
      return this.products.items.map(item => ({
        ...item,
        ..._.reduce(
          this.analysisValueKeys,
          (map, key) => {
            map[`${key}_class`] = this.tableCellClass(item[key]);
            return map;
          },
          {}
        )
      }));
    },
    analysisValueKeys() {
      return Object.keys(this.products.items[0] || {}).filter(key =>
        key.includes('analysis_value_')
      );
    }
  },
  mounted() {
    this.SET_DATE_RANGE_PARAMS(this.resourceParams);
  },
  methods: {
    ...mapMutations(['SET_DATE_RANGE_PARAMS']),
    ...mapActions('dialog', ['openDialog']),
    refresh() {
      this.fetchResource();
    },
    fetchResource() {
      this.isLoading = true;
      api
        .get('/analytics/products', { params: this.resourceParams })
        .then(({ data }) => {
          this.products = data.products;
          this.reviewTagTypeIdsOrder = data.review_tag_type_ids_order;
          this.KeywordAnalysisValueMin = data.keyword_analysis_value_min;
          this.KeywordAnalysisValueMax = data.keyword_analysis_value_max;
        })
        .finally(() => (this.isLoading = false));
    },
    changeSearchData(searchWithType) {
      this.resourceParams = { ...this.resourceParams, ...searchWithType };
      this.refreshResourceWithParams(this.resourceParams);
    },
    changeDateRange(dateRange) {
      if (
        dateRange.start_date === this.resourceParams.start_date &&
        dateRange.end_date === this.resourceParams.end_date
      )
        return;
      this.SET_DATE_RANGE_PARAMS(dateRange);
      this.resourceParams = { ...this.resourceParams, ...dateRange };
      this.refreshResourceWithParams(this.resourceParams);
    },
    changeAdvancedSearch(reviewsCountRange) {
      this.saveAdvancedSearchConfig(reviewsCountRange);

      const { min_reviews_count, max_reviews_count } = reviewsCountRange;
      if (!min_reviews_count) delete this.resourceParams.min_reviews_count;
      if (!max_reviews_count) delete this.resourceParams.max_reviews_count;

      this.resourceParams = { ...this.resourceParams, ...reviewsCountRange };
      this.refreshResourceWithParams(this.resourceParams);
    },
    saveAdvancedSearchConfig({ min_reviews_count, max_reviews_count }) {
      this.saveMinReviewsCount(min_reviews_count);
      this.saveMaxReviewsCount(max_reviews_count);
    },
    saveMinReviewsCount(minReviewsCount) {
      const storageKey = `${this.analyticsType}.min_reviews_count`;
      if (minReviewsCount) localStorage.setItem(storageKey, minReviewsCount);
      else localStorage.setItem(storageKey, this.defaultMinReviewsCount);
    },
    saveMaxReviewsCount(maxReviewsCount) {
      const storageKey = `${this.analyticsType}.max_reviews_count`;
      if (maxReviewsCount) localStorage.setItem(storageKey, maxReviewsCount);
      else localStorage.removeItem(storageKey);
    },
    openModal(row) {
      this.openDialog([
        'AnalyticsInsightProductDialog',
        {
          orgProduct: row,
          dateRange: _.pick(this.resourceParams, ['start_date', 'end_date']),
          analyticsType: this.analyticsType,
          reviewTagTypeIdsOrder: this.reviewTagTypeIdsOrder
        }
      ]);
    },
    tableCellClass() {
      throw 'not implemented error!';
    },
    sort(sortParams) {
      this.refreshResourceWithParams(sortParams);
    }
  }
};
