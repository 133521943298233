<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Normal</label>
      </div>
      <AppSelectProductCategory id="normal" v-model="valueNormal" />
      <div class="ui-section__description">
        Selected Value: {{ valueNormal }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppSelectProductCategory
        id="disabled"
        v-model="valueDisabled"
        disabled
      />
      <div class="ui-section__description">
        Selected Value: {{ valueDisabled }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppSelectProductCategory id="invalid" v-model="valueInvalid" invalid />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="multiple">Multiple</label>
      </div>
      <AppSelectProductCategory
        id="multiple"
        v-model="valueMultiple"
        multiple
      />
      <div class="ui-section__description">
        Selected Value: {{ valueMultiple }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="showable-category-ids">showableCategoryIds</label>
      </div>
      <AppSelectProductCategory
        id="showable-category-ids"
        v-model="valueShowableCategoryIds"
        :showable-category-ids="[233, 227]"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueShowableCategoryIds }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="show-non-category-options">showNonCategoryOptions</label>
      </div>
      <AppSelectProductCategory
        id="show-non-category-options"
        v-model="valueShowNonCategoryOptions"
        show-non-category-options
      />
      <div class="ui-section__description">
        Selected Value: {{ valueShowNonCategoryOptions }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiSelectProductCategory',
  data() {
    return {
      valueNormal: null,
      valueDisabled: 233,
      valueInvalid: 227,
      valueMultiple: [233, 227],
      valueShowableCategoryIds: 233,
      valueShowNonCategoryOptions: null
    };
  }
};
</script>
