<template>
  <AnalyticsInsightNoticeView @normalize="refresh">
    <AnalyticsInsightAjaxContent
      :is-loading="isLoading"
      loading-screen-width-fixed
    >
      <AnalyticsInsightProductsSearchbar
        v-bind="searchBarProps"
        v-on="searchBarEvents"
      />
      <AppResourceTable
        table-id="analytics-insight-products"
        enable-column-settings
        enable-column-sorting
        :rows="rows"
        :columns="columns"
        :resources="products"
        @paginate="paginate"
        @sort="sort"
      >
        <template #cell="{ row, column }">
          <template v-if="column === 'actions'">
            <AppButton
              :label="$t('insight.products.open_modal')"
              @click="openModal(row)"
            />
          </template>
        </template>
      </AppResourceTable>
    </AnalyticsInsightAjaxContent>
  </AnalyticsInsightNoticeView>
</template>

<script>
import InsightProductsView from '@/mixins/InsightProductsView';

export default {
  name: 'AnalyticsInsightProductsRisky',
  mixins: [InsightProductsView],
  data() {
    return {
      COLUMN: {
        id: 'analysis_aggregated_value',
        label: this.$t('insight.products.analysis_aggregated_value.risky'),
        type: 'number',
        width: '122px',
        align: 'right',
        required: true,
        default: '-',
        precision: 1,
        forcePrecision: true,
        unit: 'percentage',
        group: this.$t('insight.products.info'),
        sortable: true
      },
      defaultColumnOrderingParams: {
        order_type: 'desc',
        order_column: 'analysis_aggregated_value'
      },
      analyticsType: 'risky'
    };
  },
  computed: {
    keywordColumns() {
      return this.brandReviewTagTypes.map(type => ({
        typeId: type.id,
        id: `analysis_value_${type.id}`,
        label: type.name,
        type: 'number',
        align: 'right',
        default: '-',
        precision: 1,
        forcePrecision: true,
        unit: 'percentage',
        group: this.$t('insight.products.keywords'),
        sortable: true
      }));
    }
  },
  methods: {
    tableCellClass(val) {
      if (val === null) return 'insight-table-cell-red-no-data';
      else return 'insight-table-cell-red-0' + Math.ceil(val / (100 / 9));
    }
  }
};
</script>
