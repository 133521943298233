<template>
  <AppModalForm
    :title="$t('dialog_title')"
    :form-props="{
      ...formProps,
      focusGroupId: 'title'
    }"
    v-on="formEvents"
  >
    <template #group="{ id }">
      <template v-if="id === 'popular_filter_type'">
        <div class="AppForm__group-field">
          <i18n path="popular_filter">
            <AppSelect
              v-model="formObject.popular_filter_type"
              :options="FILTER_TYPE2_OPTIONS"
            />
          </i18n>
        </div>
      </template>
      <template v-else-if="id === 'in_categories_filters'">
        <div class="AppForm__group-field">
          <i18n
            path="in_categories_filters"
            tag="pre"
            class="TargetDmCampaignsRecommendedProductsFormDialog__form-field"
          >
            <AppSelect
              v-model="formObject.in_categories_filter_type1"
              :options="IN_CATEGORIES_OPTIONS"
            />
            <AppSelect
              v-model="formObject.in_categories_filter_type2"
              :options="FILTER_TYPE2_OPTIONS"
            />
          </i18n>
        </div>
      </template>
      <template v-else-if="id === 'personalized_filters'">
        <div class="AppForm__group-field">
          <i18n
            path="personalized_filters"
            tag="pre"
            class="TargetDmCampaignsRecommendedProductsFormDialog__form-field"
          >
            <AppSelect
              v-model="formObject.personalized_filter_type1"
              :options="FILTER_TYPE2_OPTIONS"
            />
            <AppSelect
              v-model="formObject.personalized_filter_type2"
              :options="FILTER_TYPE1_OPTIONS"
            />
            <AppSelect
              v-model="formObject.personalized_filter_type3"
              :options="FILTER_TYPE2_OPTIONS"
            />
          </i18n>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapState } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetInCategoriesProductRecommendationFilterType from '@/enums/TargetInCategoriesProductRecommendationFilterType';
import TargetProductRecommendationFilterType from '@/enums/TargetProductRecommendationFilterType';
import TargetProductRecommendationMethod from '@/enums/TargetProductRecommendationMethod';

export default {
  name: 'TargetDmCampaignsRecommendedProductsFormDialog',
  mixins: [DialogFormView],
  props: {
    productRecommendationUnit: { type: Object, default: null }
  },
  data() {
    return {
      FILTER_TYPE1_OPTIONS: TargetProductRecommendationFilterType.options(),
      FILTER_TYPE2_OPTIONS: TargetProductRecommendationFilterType.options([
        TargetProductRecommendationFilterType.CLICK,
        TargetProductRecommendationFilterType.CART,
        TargetProductRecommendationFilterType.PURCHASE
      ]),
      IN_CATEGORIES_OPTIONS: TargetInCategoriesProductRecommendationFilterType.options()
    };
  },
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    productRecommendationMethodOptions() {
      let values = TargetProductRecommendationMethod.values;
      if (
        this.campaign.campaign_type !== TargetCampaignType.SELECTED_CATEGORIES
      )
        values = values.filter(
          v =>
            v !== TargetProductRecommendationMethod.IN_CATEGORIES_FROM_CAMPAIGN
        );

      return TargetProductRecommendationMethod.options(values);
    },
    productRecommendationMethodGroups() {
      const recMethod = this.formObject.product_recommendation_method;
      switch (recMethod) {
        case TargetProductRecommendationMethod.POPULAR:
          return [{ id: 'popular_filter_type', label: this.$t('detail') }];
        case TargetProductRecommendationMethod.IN_CATEGORIES:
          return [
            {
              id: 'category_ids',
              type: 'select_product_category',
              label: this.$t('select_category'),
              categories: this.formObject.category_ids,
              multiple: true,
              validate: ['required'],
              eventHandlers: {
                'change-items': categories =>
                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                  (this.formObject.category_ids = categories)
              }
            },
            { id: 'in_categories_filters', label: this.$t('detail') }
          ];
        case TargetProductRecommendationMethod.IN_CATEGORIES_FROM_CAMPAIGN:
          return [{ id: 'in_categories_filters', label: this.$t('detail') }];
        case TargetProductRecommendationMethod.PERSONALIZED:
          return [{ id: 'personalized_filters', label: this.$t('detail') }];
        case TargetProductRecommendationMethod.HAND_PICKED:
          return [
            {
              id: 'hand_picked_products',
              type: 'select_product',
              label: this.$t('select_product'),
              products: this.formObject.hand_picked_products,
              multiple: true,
              sort: false,
              validate: ['required'],
              eventHandlers: {
                'change-items': products =>
                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                  (this.formObject.hand_picked_products = products.map(
                    ({ id, name }) => ({ id, name })
                  ))
              }
            }
          ];
        default:
          return [];
      }
    },
    formSections() {
      return [
        {
          id: 'product_recommendation_unit',
          groups: [
            {
              id: 'title',
              label: this.$t('title'),
              type: 'text',
              placeholder: this.$t('title_placeholder'),
              validate: ['required']
            },
            {
              id: 'max_products',
              label: this.$t('max_products'),
              type: 'number',
              digits: 2,
              validate: [
                'required',
                { rule: 'min_max_integer', min: 1, max: 20 }
              ]
            },
            {
              id: 'product_recommendation_method',
              label: this.$t('product_recommendation_method'),
              type: 'select_radio',
              options: this.productRecommendationMethodOptions
            },
            ...this.productRecommendationMethodGroups
          ]
        }
      ];
    }
  },
  mounted() {
    this.orgFormObject = {
      max_products: 1,
      product_recommendation_method: TargetProductRecommendationMethod.LEGACY,
      popular_filter_type: TargetProductRecommendationFilterType.CLICK,
      in_categories_filter_type1:
        TargetInCategoriesProductRecommendationFilterType.NONE,
      in_categories_filter_type2: TargetProductRecommendationFilterType.CLICK,
      personalized_filter_type1: TargetProductRecommendationFilterType.CLICK,
      personalized_filter_type2: TargetProductRecommendationFilterType.CLICK,
      personalized_filter_type3: TargetProductRecommendationFilterType.CLICK,
      hand_picked_products: [],
      category_ids: []
    };
    if (this.productRecommendationUnit) {
      for (const k of Object.keys(this.productRecommendationUnit)) {
        if (this.productRecommendationUnit[k]) {
          this.orgFormObject[k] = this.productRecommendationUnit[k];
        }
      }
    }
  },
  methods: {
    submit() {
      const data = {
        id: this.formObject.id,
        title: this.formObject.title,
        max_products: this.formObject.max_products,
        product_recommendation_method: this.formObject
          .product_recommendation_method,
        popular_filter_type: null,
        in_categories_filter_type1: null,
        in_categories_filter_type2: null,
        personalized_filter_type1: null,
        personalized_filter_type2: null,
        personalized_filter_type3: null,
        category_ids: [],
        hand_picked_products: []
      };
      switch (this.formObject.product_recommendation_method) {
        case TargetProductRecommendationMethod.POPULAR:
          data.popular_filter_type = this.formObject.popular_filter_type;
          break;
        case TargetProductRecommendationMethod.PERSONALIZED:
          data.personalized_filter_type1 = this.formObject.personalized_filter_type1;
          data.personalized_filter_type2 = this.formObject.personalized_filter_type2;
          data.personalized_filter_type3 = this.formObject.personalized_filter_type3;
          break;
        case TargetProductRecommendationMethod.HAND_PICKED:
          data.hand_picked_products = this.formObject.hand_picked_products;
          break;
        case TargetProductRecommendationMethod.IN_CATEGORIES:
          data.in_categories_filter_type1 = this.formObject.in_categories_filter_type1;
          data.in_categories_filter_type2 = this.formObject.in_categories_filter_type2;
          data.category_ids = this.formObject.category_ids;
          break;
        case TargetProductRecommendationMethod.IN_CATEGORIES_FROM_CAMPAIGN:
          data.in_categories_filter_type1 = this.formObject.in_categories_filter_type1;
          data.in_categories_filter_type2 = this.formObject.in_categories_filter_type2;
          break;
        default:
          break;
      }
      this.eventBus.$emit('change', data);
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetDmCampaignsRecommendedProductsFormDialog__form-field {
  line-height: 36px;
}
</style>

<i18n locale="ko">
{
  "dialog_title": "추천 항목 수정/추가",
  "title": "추천 항목 타이틀",
  "title_placeholder": "추천 항목을 설명하는 타이틀을 입력해주세요.",
  "max_products": "추천 상품수",
  "product_recommendation_method": "유형",
  "detail": "상세설정",
  "popular_filter": "최근 사람들이 가장 많이 {0} 상품",
  "select_category": "카테고리 선택",
  "select_product": "상품 선택",
  "in_categories_filters": "{0} 사람들이 설정 카테고리에서 최근 많이\n{1} 상품",
  "personalized_filters": "대상 유저가 최근 {0}\n상품을(의) {1}\n다른 사람들이 최근 많이 {2} 상품"
}
</i18n>
