<template>
  <div class="ReviewMessagesAdminBizMessage">
    <AppAjaxContent :is-loading="isLoading">
      <template>
        <AppForm
          object-id="admin_settings"
          v-bind="formProps"
          submit-button
          v-on="formEvents"
        />
      </template>
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import BizMessageStatus from '@/enums/BizMessageStatus';
import BizMessageProfileStatus from '@/enums/BizMessageProfileStatus';

export default {
  name: 'ReviewMessagesAdminBizMessage',
  mixins: [FormView],
  data() {
    return { isLoading: true };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            { id: 'yellow_id', type: 'text', maxlength: 16 },
            { id: 'phone', type: 'text' },
            { id: 'category_code', type: 'text' },
            {
              id: 'biz_message_status',
              type: 'select',
              options: BizMessageStatus.options()
            },
            {
              id: 'biz_message_profile_status',
              type: 'select',
              options: BizMessageProfileStatus.options()
            },
            { id: 'biz_message_profile_key', type: 'text' },
            { id: 'biz_message_profile_created_at', type: 'text' },
            { id: 'token_requested_at', type: 'text' }
          ].map(e => ({ ...e, label: e.id }))
        }
      ];
    }
  },
  mounted() {
    api
      .get('/biz_message/admin_settings')
      .then(({ data }) => (this.orgFormObject = data.admin_settings))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/biz_message/admin_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => (this.orgFormObject = data.admin_settings))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>
