<template>
  <AppContainer class="TargetReportsCampaignSectionChart">
    <AppSearchbar>
      <template #default>
        <li>
          <AppDropdownMenuButton
            :label="$t(`chart_type.${chartType}`)"
            :menu-items="menuItems"
            button-style="label"
          />
        </li>
      </template>
      <template #right>
        <li>
          <AppSelectButtonInterval
            v-model="resourceParams.interval"
            @change="changeInterval"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppAjaxContent
      :is-loading="isLoading"
      class="TargetReportsCampaignSectionChart__body"
    >
      <template #default>
        <TargetReportsTotalReportsChart
          v-bind="{
            resourceParams,
            chartHeight,
            seriesColumns,
            yAxis,
            reports,
            reportsRange
          }"
        />
      </template>
    </AppAjaxContent>
  </AppContainer>
</template>

<script>
import api from '@/lib/api';
import { dateRangeWithInterval } from '@/lib/date';

import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import TargetReportsTotalReportsChart from './TargetReportsTotalReportsChart';

export default {
  name: 'TargetReportsCampaignSectionChart',
  components: { TargetReportsTotalReportsChart },
  mixins: [ResourceViewNoRoute],
  props: {
    dateRange: { type: Object, required: true },
    campaignDispatchType: { type: Number, default: null }
  },
  data: () => ({
    isLoading: false,
    chartType: 'kpis',
    reports: [],
    reportsRange: {}
  }),
  computed: {
    menuItems() {
      const chartTypes = [
        'kpis',
        'unsubscribed_rate',
        'sent_users_count',
        'sent_count_used_point_and_cash'
      ];
      return chartTypes.map(chartType => ({
        label: this.$t(`chart_type.${chartType}`),
        clickHandler: e => this.selectChartType(e, chartType)
      }));
    },
    chartHeight: () => '282px',
    seriesColumns() {
      const { chartType } = this;
      switch (chartType) {
        case 'kpis':
          return [
            {
              id: 'purchase_conversion_rate',
              name: this.$t('kpi.purchase_conversion_rate'),
              valueType: 'percentage',
              unit: 'percentage'
            },
            {
              id: 'inactivation_prevention_rate',
              name: this.$t('kpi.inactivation_prevention_rate'),
              valueType: 'percentage',
              unit: 'percentage'
            },
            {
              id: 'signup_conversion_rate',
              name: this.$t('kpi.signup_conversion_rate'),
              valueType: 'percentage',
              unit: 'percentage'
            }
          ];
        case 'unsubscribed_rate':
          return [
            {
              id: 'unsubscribed_rate',
              name: this.$t('chart_type.unsubscribed_rate'),
              valueType: 'percentage',
              unit: 'percentage'
            }
          ];
        case 'sent_users_count':
          return [
            {
              id: 'sent_users_count',
              name: this.$t('chart_type.sent_users_count')
            }
          ];
        case 'sent_count_ad_spending':
          return [
            {
              id: 'sent_count',
              name: this.$t('sent_count_ad_spending.sent_count'),
              columnType: 'column'
            },
            {
              id: 'ad_spending',
              name: this.$t('sent_count_ad_spending.ad_spending'),
              yAxis: 1
            }
          ];
        case 'sent_count_used_point_and_cash':
          return [
            {
              id: 'sent_count',
              name: this.$t('sent_count_used_point_and_cash.sent_count'),
              columnType: 'column',
              unit: 'cases'
            },
            {
              id: 'used_point',
              name: this.$t('sent_count_used_point_and_cash.used_point'),
              yAxis: 1,
              unit: 'currency'
            },
            {
              id: 'used_cash',
              name: this.$t('sent_count_used_point_and_cash.used_cash'),
              yAxis: 1,
              unit: 'currency'
            }
          ];
        default:
          return [];
      }
    },
    yAxis() {
      if (this.chartType === 'sent_count_ad_spending')
        return [
          { title: { text: this.$t('sent_count_ad_spending.sent_count') } },
          {
            title: { text: this.$t('sent_count_ad_spending.ad_spending') },
            labels: { format: '{value}' },
            opposite: true
          }
        ];
      else if (this.chartType === 'sent_count_used_point_and_cash')
        return [
          {
            title: {
              text: this.$t('sent_count_used_point_and_cash.sent_count')
            }
          },
          {
            title: {
              text: this.$t(
                'sent_count_used_point_and_cash.used_point_and_cash'
              )
            },
            labels: { format: '{value}' },
            opposite: true
          }
        ];
      else return [{ title: { text: '' } }];
    }
  },
  watch: {
    dateRange() {
      this.resourceParams = {
        ...this.resourceParams,
        ...dateRangeWithInterval(this.dateRange, this.resourceParams.interval)
      };
      this.searchResource();
    },
    campaignDispatchType() {
      this.resourceParams = {
        ...this.resourceParams,
        campaign_dispatch_type: this.campaignDispatchType
      };
      this.searchResource();
    }
  },
  created() {
    this.setOrgResourceParams({
      ...this.dateRange,
      interval: 'daily',
      campaign_dispatch_type: this.campaignDispatchType
    });
  },
  methods: {
    selectChartType({ close }, chartType) {
      this.chartType = chartType;
      close();
    },
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/target/total_reports/index_chart', { params })
        .then(({ data }) => {
          this.reportsRange = { ...this.resourceParams };
          this.reports = data.total_reports;
        })
        .finally(() => (this.isLoading = false));
    },
    changeInterval(interval) {
      this.resourceParams = {
        ...this.resourceParams,
        ...dateRangeWithInterval(this.dateRange, interval),
        interval
      };
      this.searchResource();
    }
  }
};
</script>

<style scoped>
.TargetReportsCampaignSectionChart {
  height: 100%;
}

.TargetReportsCampaignSectionChart__body {
  margin-top: 24px;
}
</style>

<i18n locale="ko">
{
  "chart_type": {
    "kpis": "주요 KPI",
    "unsubscribed_rate": "수신 거부율",
    "sent_users_count": "발송 인원수",
    "sent_count_used_point_and_cash": "발송 건수/발송 비용"
  },
  "kpi": {
    "purchase_conversion_rate": "구매 전환율",
    "inactivation_prevention_rate": "이탈 방지율",
    "signup_conversion_rate": "가입 전환율"
  },
  "sent_count_ad_spending": {
    "sent_count": "발송 건수",
    "ad_spending": "발송 크레딧"
  },
  "sent_count_used_point_and_cash": {
    "sent_count": "발송 건수",
    "used_point_and_cash": "발송 비용",
    "used_point": "발송 포인트",
    "used_cash": "발송 캐시"
  }
}
</i18n>
