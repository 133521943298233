import { constructEnum } from '@/lib/enum';

const SweetTrackerBizMessageResultCode = constructEnum(
  'SweetTrackerBizMessageResultCode',
  {
    SENDING: 10,
    EMPTY_SMS_MESSAGE: 62113,
    EMPTY_REQUIRED_PARAMETER: 62114,
    EMPTY_NO: 62115,
    EMPTY_TEL_NUM: 62116,
    EMPTY_USE_SMS: 62117,
    EMPTY_BTN_URL: 62118,
    OVER_LIMIT_BTN_URL_URL_PC: 62119,
    OVER_LIMIT_BTN_URL_URL_MOBILE: 62120,
    INVALID_BTN_URL_TYPE: 62121,
    OVER_LIMIT_NUMBER_OF_BTN: 62122,
    INVALID_MSG_CONTENT: 62123,
    INVALID_CARRIRER_PARAMETER: 62124,
    MALL_NOT_FOUND: 62125,
    USER_NOT_FOUND: 62126,
    DATABASE_EXCEPTION: 62127,
    EMPTY_CUSTOM_KEY: 62128,
    DUPLICATE_CUSTOM_KEY: 62129,
    TIMEOUT_KAKAOTALK: 62130,
    INVALID_BTN_URL: 62131,
    INVALID_DATA: 69101,
    INVALID_PROFILE_KEY: 69102,
    EMPTY_TEMPLATE_CODE: 69103,
    INVALID_PHONE_NUMBER: 69104,
    INVALID_SENDER_NUMBER: 69105,
    EMPTY_MESSAGE: 69106,
    SMS_EMPTY_MESSAGE: 69107,
    INVALID_RESERVATION_DATE: 69108,
    DUPLICATED_MSG_ID: 69109,
    REQUEST_MSG_ID_NOT_FOUND: 69110,
    REQUEST_IMG_NOT_FOUND: 69111,
    OVER_LIMIT_MESSAGE_LENGTH: 69112,
    OVER_LIMIT_MSG_ID_LENGTH: 69113,
    INVALID_SMS_PROFILE: 69120,
    ITEMS_NOT_JSON_FORMAT: 69121,
    ITEMS_LENGTH_OVER_LIMIT: 69122,
    HEADER_LENGTH_OVER_LIMIT: 69123,
    INVALID_BUTTON_JSON_OBJECT: 69124,
    INVALID_SMS_KIND: 69125,
    BAD_WORD_IN_MESSAGE: 69126,
    OVER_LIMIT_REQUEST: 69998,
    UNKNOWN_EXCEPTION: 69999,
    SUCCESS_KAKAOTALK: 75000,
    NOT_AVAILABLE_SEND_MESSAGE_KAKAOTALK: 75101,
    INVALID_PHONE_NUMBER_KAKAOTALK: 75102,
    OVER_LIMIT_MESSAGE_LENGTH_KAKAOTALK: 75103,
    TEMPLATE_NOT_FOUND_KAKAOTALK: 75104,
    NO_MATCHED_TEMPLATE_KAKAOTALK: 75105,
    INVALID_IMAGE: 75106,
    TALK_USER_ID_NOT_FOUND: 75107,
    NO_MATCHED_TEMPLATE_BUTTON_EXECPTION: 75108,
    NO_MATCHED_TEMPLATE_TITLE_EXECPTION: 75109,
    EXCEED_MAX_TITLE_LENGTH_EXCEPTION: 75110,
    NO_MATCHED_TEMPLATE_WITH_MESSAGE_TYPE_EXCEPTION: 75111,
    INVALID_USER_KEY_EXCEPTION: 75112,
    INVALID_APP_LINK: 75113,
    INVALID_BIZ_NUM: 75114,
    MESSAGE_EMPTY_EXCEPTION: 75115,
    NO_MATCHED_TEMPLATE_HEADER_EXCEPTION: 75131,
    EXCEED_MAX_HEADER_LENGTH_EXCEPTION: 75132,
    NO_MATCHED_TEMPLATE_HIGHLIGHT_EXCEPTION: 75133,
    EXCEED_MAX_ITEM_HIGHLIGHT_TITLE_LENGTH_EXCEPTION: 75134,
    EXCEED_MAX_ITEM_HIGHLIGHT_DESCRIPTION_LENGTH_EXCEPTION: 75135,
    NO_MATCHED_TEMPLATE_ITEM_LIST_EXCEPTION: 75136,
    EXCEED_MAX_ITEM_DESCRIPTION_LENGTH_EXCEPTION: 75137,
    NO_MATCHED_TEMPLATE_ITEM_SUMMARY_EXCEPTION: 75138,
    EXCEED_MAX_ITEM_SUMMRAY_DESCRIPTION_LENGTH_EXCEPTION: 75139,
    INVALID_ITEM_SUMMARY_DESCRIPTION: 75140,
    INVALID_SENDER_KEY: 75200,
    DELETED_SENDER: 75201,
    STOPPED_SENDER: 75202,
    BLOCKED_PROFILE: 75203,
    DEACTIVATED_PROFILE: 75204,
    DELETED_PROFILE: 75205,
    DELETING_PROFILE: 75206,
    SPAMMED_PROFILE: 75207,
    INVALID_PARAMETER_EXCEPTION: 75208,
    NO_VALUE_JSON_ELEMENT: 75209,
    MESSAGE_SPAMMED_PROFILE_EXCEPTION: 75210,
    INVALID_RECEIVE_USER: 75220,
    FAILED_TO_SEND_MESSAGE_BY_NO_FRIENDSHIP_EXCEPTION: 75301,
    FAILED_TO_MATCH_TEMPLATE_EXCEPTION: 75302,
    NO_SEND_AVAILABLE_TIME_EXCEPTION: 75303,
    EXCEED_MAX_VARIABLE_LENGTH_EXCEPTION: 75304,
    BUTTON_CHAT_EXTRA_INVALID_EXTRA_EXCEPTION: 75306,
    NO_MATCHED_TEMPLATE_REPRESENT_LINK_EXCEPTION: 75308,
    MESSAGE_INVALID_WIDE_ITEM_LIST_LENGTH_EXCEPTION: 75309,
    INVALIDATE_CAROUSEL_ITEM_MIN_EXCEPTION: 75310,
    CAROUSEL_MESSAGE_LENGTH_OVER_LIMIT_EXCEPTION: 75311,
    UNKNOWN_MESSAGE_STATUS_ERROR: 75401,
    INVALID_TEST_USER: 75500,
    DAILY_TEST_LIMIT_EXCEEDED: 75501,
    RESPONSE_TIMEOUT_EXCEPTION: 75997,
    FAILED_TO_SEND_MESSAGE_EXCEPTION: 75998,
    UNKNOWN_EXCEPTION_KAKAOTALK: 75999,
    SUCCESS_SMS: 77000,
    PROCESSING_MESSAGE_SEND_SMS: 77001,
    NOT_AVAILABLE_SEND_MESSAGE_SMS: 77101,
    INVALID_PHONE_NUMBER_SMS: 77102,
    DO_NOT_DISTURB_SMS: 77103,
    SPAM_MESSAGE_SMS: 77104,
    TURN_OFF_SMS: 77105,
    OVER_LIMIT_MESSAGE_LENGTH_SMS: 77106,
    DENIED_SENDER_NUMBER: 77107,
    INVALID_IMG_URL: 77108,
    SMS_INVALID_PARAMS: 77120,
    SMS_EMOJI_TEXT: 77121,
    SMS_EMOJI_SUBJECT: 77122,
    SMS_SERVER_INTERNAL_ERROR: 77128,
    SMS_SERVER_UNKNOWN_ERROR: 77129,
    INVALID_HEADER_FIELD: 77200,
    INVALID_BODY_FIELD: 77201,
    SPEC_ERROR: 77202,
    PHONE_NUMBER_ERROR: 77203,
    CONTENT_SIZE_ERROR: 77204,
    EXCEED_CONTENT_SIZE: 77205,
    ATTACHMENT_COUNT_ERROR: 77206,
    UNSUPPORTABLE_CONTENT_FOUND: 77207,
    CONTENT_RELATED_MISC_ERROR: 77208,
    SERIAL_NUMBER_ERROR: 77209,
    INVALID_MESSAGE_TYPE_ERROR: 77210,
    EXCEED_DUPLICATE_TRANSMISSION_COUNT_ERROR: 77211,
    EXCEED_CONTENT_PER_SECOND_ERROR: 77212,
    EXCEED_DAILY_SEND_LIMIT_ERROR: 77300,
    EXCEED_MONTHLY_SEND_LIMIT_ERROR: 77301,
    SEND_LIMITATION_TIME_ERROR: 77302,
    INSUFFICIENT_BALANCE_ERROR: 77303,
    DUPLICATE_SEND_ERROR: 77304,
    REQUEST_TIME_ERROR: 77305,
    PHONE_NUMBER_RULE_VIOLATION_ERROR: 77306,
    UNREGISTERED_SENDER_ERROR: 77307,
    SPOOFED_SENDER_ERROR: 77308,
    NUMBER_FORGERY_BLOCK_SERVICE_ERROR: 77309,
    NO_ROUTING_INFO_ERROR: 77400,
    EXCEED_MESSAGE_SIZE_ERROR: 77403,
    REAL_TIME_SEND_FAILURE_ERROR: 77404,
    GATEWAY_EXPIRED_NO_REPORT_RECEIVED_ERROR: 77405,
    GATEWAY_EXPIRED_NOT_TRANSMITTED_ERROR: 77406,
    ATTACHMENT_RELATED_ERROR: 77407,
    MISC_ERROR: 77408,
    SPAMMED_SENDER_PHONE_NUMBER_ERROR: 77409,
    EXPIRED_MOBILE_CARRIER_ERROR: 77504,
    NO_SUBSCRIBER_FOUND_ERROR: 77505,
    SHADOW_AREA_ERROR: 77507,
    EXCEED_RECEIVER_CAPACITY_ERROR: 77508,
    UNSUPPORTABLE_MMS_DEVICE_ERROR: 77509,
    UNSUPPORATBLE_DEVICE_ERROR: 77510,
    NO_RESPONSE_OR_IN_CALL_ERROR: 77511,
    CHANGED_PHONE_NUMBER_ERROR: 77512,
    NPDB_ERROR: 77513,
    MOBILE_CARRIER_CONTENT_ERROR: 77514,
    MOBILE_CARRIER_PHONE_NUMBER_RULE_VIOLATION_ERROR: 77515,
    MOBILE_CARRIER_UNREGISTERED_SENDER_ERROR: 77516,
    MOBILE_CARRIER_SPOOFED_SENDER_ERROR: 77517,
    MOBILE_NUMBER_FORGERY_BLOCK_SERVICE_ERROR: 77518,
    MOBILE_CARRIER_MISC_ERROR: 77519,
    TEMPORARILY_SUSPENDED_SUBSCRIBER_ERROR: 77520,
    TEMPORARILY_EXCEED_CAPACITY_ERROR: 77701,
    SUSPENDED_CLIENT_ERROR: 77703,
    UNREGISTERED_CLIENT_ERROR: 77704,
    ALREADY_CONNECTED_ERROR: 77705,
    UNSUPPORTED_VERSION_ERROR: 77706,
    SMS_PERMISSION_DENIED_ERROR: 77707,
    MMS_PERMISSION_DENIED_ERROR: 77708,
    ISMS_PERMISSION_DENIED_ERROR: 77709,
    NETWORK_ERRO: 77710,
    NOT_BOUND_ERROR: 77711,
    ENCRYPTION_ERROR: 77712,
    DECRYPTION_ERROR: 77713,
    UNSUPPORTED_EXTENSION_ERROR: 77714,
    SEND_PERMISSION_DENINED_ERROR: 77800,
    FILE_SIZE_ERROR: 77801,
    BLOCKED_SENDER_NUMBER_ERROR: 77803,
    KOREAN_ENCODING_ERROR: 77804,
    INVALID_RECEIVER_NUMBER_ERROR: 77805,
    INVALID_CONTENT_ERROR: 77806,
    MISSING_FILE_ERROR: 77807,
    MISSING_REPLY_NUMBER_ERROR: 77808,
    EXCEED_ATTACHMENT_COUNT_ERROR: 77809,
    DECRYPTION_RECEIVER_NUMBER_ERROR: 77811,
    DECRYPTION_SENDER_NUMBER_ERROR: 77812,
    DECRYPTION_COTENT_ERROR: 77813,
    REPORT_DELIVERY_TIMEOUT_ERROR: 77901,
    EXPIRED_REQUEST_ERROR: 77990,
    REPORT_TIMEOUT_EXCEPTION: 77998,
    UNKNOWN_EXCEPTION_SMS: 77999,
    RESERVED_SEND_SUCCESS: 78000,
    UNDEFINED_ERROR: 80000
  }
);

SweetTrackerBizMessageResultCode.SUCCESS = [
  SweetTrackerBizMessageResultCode.SUCCESS_KAKAOTALK,
  SweetTrackerBizMessageResultCode.SUCCESS_SMS
];

SweetTrackerBizMessageResultCode.isSuccessful = function(resultCode) {
  return SweetTrackerBizMessageResultCode.SUCCESS.includes(resultCode);
};

SweetTrackerBizMessageResultCode.isSending = function(resultCode) {
  return resultCode === SweetTrackerBizMessageResultCode.SENDING;
};

export default SweetTrackerBizMessageResultCode;
