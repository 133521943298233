import { constructEnum } from '@/lib/enum';

const BrandChannelStatus = constructEnum('BrandChannelStatus', {
  NONE: 0,
  REQUESTED: 10,
  TESTING: 20,
  FREE_TRIAL_OPERATING: 30,
  FREE_TRIAL_EXPIRED: 40,
  OPERATING: 50,
  EXPIRED: 60,
  EXPIRED_AND_ADMIN_BLOCKED: 70,
  TERMINATED: 80
});

BrandChannelStatus.MENU_ACCESSIBLE_STATUSES = [
  BrandChannelStatus.TESTING,
  BrandChannelStatus.FREE_TRIAL_OPERATING,
  BrandChannelStatus.FREE_TRIAL_EXPIRED,
  BrandChannelStatus.OPERATING,
  BrandChannelStatus.EXPIRED
];

BrandChannelStatus.BONUS_ADDABLE_STATUSES = [
  BrandChannelStatus.OPERATING,
  BrandChannelStatus.EXPIRED,
  BrandChannelStatus.EXPIRED_AND_ADMIN_BLOCKED
];

BrandChannelStatus.isMenuAccessible = function(brandChannelStatus) {
  return BrandChannelStatus.MENU_ACCESSIBLE_STATUSES.includes(
    brandChannelStatus
  );
};

BrandChannelStatus.isBonusAddable = function(brandChannelStatus) {
  return BrandChannelStatus.BONUS_ADDABLE_STATUSES.includes(brandChannelStatus);
};
export default BrandChannelStatus;
