var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppSelectSearchable',_vm._g(_vm._b({attrs:{"columns":[
    { id: 'code', label: _vm.$t('products.code') },
    { id: 'product', label: _vm.$t('products.name'), type: 'product' }
  ],"search-types":[
    { value: 'name', label: _vm.$t('products.name') },
    { value: 'code', label: _vm.$t('products.code') }
  ]},on:{"search":_vm.search}},'AppSelectSearchable',{
    id: _vm.id,
    title: _vm.title,
    name: _vm.name,
    items: _vm.products,
    disabled: _vm.disabled,
    invalid: _vm.invalid,
    placeholder: _vm.placeholder,
    multiple: _vm.multiple,
    sort: _vm.sort,
    packingMethod: _vm.packingMethod,
    defaultFilters: _vm.defaultFilters,
    selectButtonLabel: _vm.$t('select_product')
  },false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }