<template>
  <AppButton
    :tooltip="srcIndex !== null ? null : tooltip"
    class="AppButtonDraggable"
    :disabled="disabled"
    :draggable="!disabled"
    unactivable
    unfocusable
    @dragstart.native="dragstart"
    @dragend.native="dragend"
  >
    {{ $t('app.sort') }}
    <div v-show="false" ref="dragImage" class="AppButtonDraggable-drag-image">
      <slot name="drag-image">
        <div v-if="dragText" class="AppButtonDraggable-drag-image__text">
          {{ dragText }}
        </div>
      </slot>
    </div>
  </AppButton>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'AppButtonDraggable',
  props: {
    tooltip: {
      type: String,
      default() {
        return this.$t('tooltip');
      }
    },
    disabled: { type: Boolean, default: false },
    dragText: { type: String, default: null }
  },
  data() {
    return {
      srcIndex: null,
      dstIndex: null,
      dropBar: null,
      dragImage: null,
      draggable: null
    };
  },
  methods: {
    dragstart(e) {
      if (this.disabled) return;

      this.draggable = e.target.closest('.js-draggable');

      const { draggable } = this;
      _.forEach(draggable.children, (el, rowIndex) => {
        if (el.contains(e.target)) {
          el.classList.add('AppButtonDraggable-drag-src');
          this.srcIndex = rowIndex;
        }
        if (!el.querySelector(".AppButtonDraggable[draggable='false']")) {
          el.classList.add('AppButtonDraggable-drop-zone');
        }
        el.addEventListener('dragover', this.dragover);
        el.addEventListener('dragleave', this.dragleave);
        el.addEventListener('drop', this.drop);
      });

      const dropBar = document.createElement('div');
      dropBar.classList.add('AppButtonDraggable-drop-bar');
      if (draggable.closest('.AppModal'))
        dropBar.classList.add('AppButtonDraggable-drop-bar--in-dialog');
      dropBar.hidden = true;
      document.body.appendChild(dropBar);
      this.dropBar = dropBar;

      const dragImage = this.$refs.dragImage.cloneNode(true);
      dragImage.style.display = 'block';
      document.body.appendChild(dragImage);
      e.dataTransfer.setDragImage(dragImage, 0, 0);
      this.dragImage = dragImage;
    },
    dragover(e) {
      e.preventDefault();

      const dropZone = e.target.closest('.AppButtonDraggable-drop-zone');
      if (!dropZone) {
        this.dstIndex = this.srcIndex;
        this.dropBar.hidden = true;
        return;
      }

      const { draggable } = this;
      const rowIndex = _.findIndex(draggable.children, el =>
        el.contains(dropZone)
      );
      const insertBefore = e.offsetY < dropZone.offsetHeight * 0.5;
      this.dstIndex =
        this.srcIndex < rowIndex && insertBefore
          ? rowIndex - 1
          : rowIndex < this.srcIndex && !insertBefore
          ? rowIndex + 1
          : rowIndex;

      this.dropBar.hidden = false;

      const rect = dropZone.getBoundingClientRect();
      const top = insertBefore ? rect.top : rect.bottom;
      this.dropBar.style.left = `${rect.left}px`;
      this.dropBar.style.width = `${rect.width}px`;

      const isBorderCollapsed = draggable.tagName === 'TBODY';
      this.dropBar.style.top = `${isBorderCollapsed ? top : top - 1}px`;
    },
    dragleave() {
      this.dropBar.hidden = true;
    },
    drop(e) {
      e.preventDefault();

      if (this.srcIndex !== this.dstIndex)
        this.$emit('sort', {
          srcIndex: this.srcIndex,
          dstIndex: this.dstIndex
        });
    },
    dragend() {
      if (this.disabled) return;

      const { draggable } = this;

      _.forEach(draggable.children, el => {
        el.removeEventListener('dragover', this.dragover);
        el.removeEventListener('dragleave', this.dragleave);
        el.removeEventListener('drop', this.drop);
      });

      // 드래그하면서 다른 row로 이동했는데도 원래 row가 hover되는 문제 방지
      window.addEventListener(
        'mousemove',
        () => {
          _.forEach(draggable.children, el => {
            el.classList.remove('AppButtonDraggable-drag-src');
            el.classList.remove('AppButtonDraggable-drop-zone');
          });
        },
        { once: true }
      );

      this.srcIndex = null;
      this.dstIndex = null;

      this.dropBar.remove();
      this.dropBar = null;

      this.dragImage.remove();
      this.dragImage = null;

      this.draggable = null;
    }
  }
};
</script>

<i18n locale="ko">
{
  "tooltip": "클릭한 상태로 이동하면 순서를 변경할 수 있습니다."
}
</i18n>

<style lang="scss">
@import '@/scss/vars/_colors.scss';
@import '@/scss/vars/_z-indexes.scss';

.AppButtonDraggable-drop-zone {
  background: transparent !important;
}

.AppButtonDraggable-drag-src {
  background-color: $color-grey-05 !important;
}

.AppButtonDraggable-drop-bar {
  z-index: $z-index-tooltip;
  position: absolute;
  height: 1px;
  background: $color-grey-80;
  pointer-events: none;

  &--in-dialog {
    z-index: $z-index-tooltip-modal;
  }
}

.AppButtonDraggable-drag-image {
  position: absolute;
  top: -10000px;
  border: 1px solid $color-grey-25;
  border-radius: 3px;
  margin-left: 20px;
  background: white;
  line-height: 0;
}

.AppButtonDraggable-drag-image__text {
  line-height: 20px;
  padding: 5px 15px;
  color: $color-button-text-dark;
  white-space: nowrap;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
