<template>
  <AppModalFormLivePreview
    :title="$t('title')"
    :sub-title="campaign.name"
    :is-loading="isLoading"
    :form-props="{ ...formProps, objectId: 'campaign' }"
    v-on="formEvents"
    @error-summary="updatePreviewIfValid"
  >
    <template #left>
      <AppMobilePhonePreview>
        <template v-if="useLivePreview">
          <TargetPopupLivePreview />
        </template>
        <template v-else>
          <AppIframeViewer
            :src="popupSampleUrl"
            :is-loading-forced="currentVersion !== displayVersion"
          />
        </template>
      </AppMobilePhonePreview>
    </template>
    <input
      v-if="formObject.use_default_icon"
      type="hidden"
      :name="'campaign[remove_title_image]'"
      :value="true"
    />
    <template #group="{ id }">
      <template v-if="id === 'color'">
        <div class="AppForm__group-field">
          <AppColorPicker
            name="campaign[button_color]"
            :label="$t('form.color.button_color')"
            :value="formObject.button_color"
            @input="changeButtonColor"
          />
          <AppColorPicker
            name="campaign[font_color]"
            :label="$t('form.color.font_color')"
            :value="formObject.font_color"
            @input="changeFontColor"
          />
        </div>
      </template>
    </template>
    <template #group-sub-item="{ group, value, checked, errors }">
      <template v-if="group.id === 'use_default_icon'">
        <template v-if="!value">
          <AppImageInput
            :name="'campaign[title_image]'"
            :image-url="formObject.title_image"
            :placeholder="$t('form.use_default_icon.upload_placeholder')"
            :invalid="!!errors.title_image"
            :disabled="!checked"
            not-removable
            @change="changeTitleImage"
          />
          <AppFormError :error="errors.title_image" />
          <AppFormHint
            :disabled="!checked"
            :message="$t('form.use_default_icon.hint')"
          />
        </template>
      </template>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import _ from 'lodash';
import qs from 'qs';
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import IframePreviewHelper from '@/mixins/IframePreviewHelper';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignPopupStyle from '@/enums/TargetCampaignPopupStyle';
import TargetPopupBackground from '@/enums/TargetPopupBackground';

const UPDATE_TARGET_POPUP_PREVIEW_REQUEST_ID = 'UPDATE_TARGET_POPUP_PREVIEW';

export default {
  name: 'TargetCampaignSettingsDesignPopupDialog',
  mixins: [DialogFormView, IframePreviewHelper],
  props: { campaign: { type: Object, required: true } },
  data() {
    return {
      isLoading: true,
      useLivePreview: false
    };
  },
  computed: {
    groupIds() {
      const groupIds = [];
      const { campaign_type } = this.campaign;

      const supportsPopupStyleContents = [
        TargetCampaignType.CART_REMINDING,
        TargetCampaignType.COUPON_EXPIRY
      ].includes(campaign_type);
      if (supportsPopupStyleContents) groupIds.push('popup_style');

      groupIds.push(
        ...(this.formObject.popup_style === TargetCampaignPopupStyle.DEFAULT
          ? [
              'background',
              'use_default_icon',
              'title',
              'description',
              'link_text',
              'link_url',
              'color',
              'css'
            ]
          : [
              'background',
              'contents_popup_title',
              'contents_popup_link_text',
              'link_url',
              'color',
              'coupon_description_html',
              'recommendation_title',
              'css'
            ])
      );

      if (campaign_type === TargetCampaignType.COUPON_EXPIRY)
        _.remove(groupIds, id => id === 'description');
      else if (campaign_type === TargetCampaignType.MILEAGE_EXPIRING)
        _.remove(groupIds, id => id === 'link_url');

      return groupIds;
    },
    groupConfigs() {
      const campaignKeywords = this.campaignKeywords;
      const defaultKeywords = this.defaultKeywords;
      return {
        popup_style: {
          type: 'select',
          options: TargetCampaignPopupStyle.options()
        },
        background: {
          type: 'select_radio',
          options: TargetPopupBackground.options()
        },
        use_default_icon: {
          type: 'select_radio',
          required: true,
          options: [
            { label: this.$t('form.use_default_icon.default'), value: true },
            {
              label: this.$t('form.use_default_icon.upload'),
              value: false
            }
          ],
          fields: this.formObject.use_default_icon
            ? []
            : [{ id: 'title_image', validate: ['required'] }]
        },
        title: {
          type: 'keyword_textarea',
          placeholder: this.$t('form.title.placeholder'),
          keywords: campaignKeywords,
          validate: [
            'required',
            { rule: 'keywords', keywords: campaignKeywords }
          ]
        },
        description: {
          type: 'keyword_textarea',
          placeholder: this.$t('form.description.placeholder'),
          keywords: campaignKeywords,
          validate: [
            'required',
            { rule: 'keywords', keywords: campaignKeywords }
          ]
        },
        link_text: {
          type: 'text',
          placeholder: this.$t('form.link_text.placeholder'),
          validate: ['required']
        },
        link_url: {
          type: 'url',
          placeholder: this.$t('form.link_url.placeholder'),
          validate: ['required', 'url_format']
        },
        contents_popup_title: {
          type: 'keyword_textarea',
          label: this.$t('form.title.label'),
          placeholder: this.$t('form.title.placeholder'),
          keywords: campaignKeywords,
          validate: [
            'required',
            { rule: 'keywords', keywords: campaignKeywords }
          ]
        },
        contents_popup_link_text: {
          type: 'text',
          label: this.$t('form.link_text.label'),
          placeholder: this.$t('form.link_text.placeholder'),
          validate: ['required']
        },
        coupon_description_html: {
          type: 'keyword_textarea',
          placeholder: this.$t('form.description.placeholder'),
          keywords: defaultKeywords,
          validate: [{ rule: 'keywords', keywords: defaultKeywords }],
          singleLine: true
        },
        recommendation_title: {
          type: 'keyword_textarea',
          placeholder: this.$t('form.description.placeholder'),
          keywords: defaultKeywords,
          validate: [
            'required',
            { rule: 'keywords', keywords: defaultKeywords }
          ],
          singleLine: true
        },
        css: {
          type: 'code_editor',
          lang: 'css',
          superAdmin: true
        }
      };
    },
    formSections() {
      return [
        {
          id: 'popup_design',
          groups: this.groupIds.map(id => ({
            id,
            label: this.$t(`form.${id}.label`),
            ...this.groupConfigs[id]
          }))
        }
      ];
    },
    defaultKeywordIds: () => ['user', 'user_id', 'brand'],
    campaignKeywordIds() {
      switch (this.campaign.campaign_type) {
        case TargetCampaignType.CART_REMINDING:
          return [...this.defaultKeywordIds, 'cart_items_count'];
        case TargetCampaignType.COUPON_EXPIRY:
          return [...this.defaultKeywordIds, 'days_before_coupon_expiration'];
        case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
          return [...this.defaultKeywordIds, 'days_before_inactivation'];
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
          return [...this.defaultKeywordIds, 'unpaid_orders_count'];
        case TargetCampaignType.MILEAGE_EXPIRING:
          return [
            ...this.defaultKeywordIds,
            'max_best_mileage',
            'days_before_expiration'
          ];
        case TargetCampaignType.UTM_POPUP:
          return ['brand'];
        default:
          return this.defaultKeywordIds;
      }
    },
    campaignKeywords() {
      return this.keywords(this.campaignKeywordIds);
    },
    defaultKeywords() {
      return this.keywords(this.defaultKeywordIds);
    },
    popupSampleUrl() {
      const params = qs.stringify({
        v: this.displayVersion,
        iframe: '1',
        parent_origin: location.origin
      });
      return `${this.formObject.popup_sample_url}&${params}`;
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaign.id}/popup_design`)
      .then(({ data }) => {
        this.orgFormObject = data.campaign;
        this.useLivePreview = data.use_popup_live_preview;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(`/target/campaigns/${this.campaign.id}/popup_design`, formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    changeTitleImage({ imageUrl }) {
      this.formObject.title_image = imageUrl;
      this.validateField('title_image');
    },
    changeButtonColor(color) {
      this.formObject.button_color = color;
      this.validateField('button_color');
    },
    changeFontColor(color) {
      this.formObject.font_color = color;
      this.validateField('font_color');
    },
    keywords(keywordIds) {
      return keywordIds.map(key => ({
        key,
        desc: this.$t(`keywords.${key}`)
      }));
    },
    updatePreview() {
      const formData = new FormData(this.$el.getElementsByTagName('form')[0]);
      const version = this.currentVersion;
      api
        .patch(
          `/target/campaigns/${this.campaign.id}/popup_design/preview`,
          formData,
          { requestId: UPDATE_TARGET_POPUP_PREVIEW_REQUEST_ID }
        )
        .then(() => (this.displayVersion = version));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "팝업 설정",
  "form": {
    "popup_style": {
      "label": "팝업 스타일"
    },
    "background": {
      "label": "팝업 배경"
    },
    "use_default_icon": {
      "label": "팝업 아이콘",
      "default": "기본 아이콘 사용",
      "upload": "직접 이미지 업로드",
      "upload_placeholder": "아이콘 대신 사용할 이미지를 등록해주세요.",
      "hint": "이미지는 최대 800px x 400px 내의 사이즈를 권장합니다."
    },
    "title": {
      "label": "팝업 제목",
      "placeholder": "팝업 제목을 입력해주세요."
    },
    "description": {
      "label": "안내 문구",
      "placeholder": "안내 문구를 입력해주세요."
    },
    "link_text": {
      "label": "버튼 문구",
      "placeholder": "버튼 문구를 입력해주세요."
    },
    "link_url": {
      "label": "버튼 URL",
      "placeholder": "버튼 클릭 시 이동할 URL을 입력해주세요."
    },
    "coupon_description_html": {
      "label": "쿠폰/이벤트 안내 문구"
    },
    "recommendation_title": {
      "label": "추천 상품 안내 문구"
    },
    "color": {
      "label": "버튼 색상",
      "button_color": "배경 색상",
      "font_color": "글자 색상"
    },
    "css": {
      "label": "이 페이지에만 적용할 CSS"
    }
  },
  "keywords": {
    "user": "고객명",
    "user_id": "고객아이디",
    "brand": "쇼핑몰명",
    "cart_items_count": "장바구니 상품수",
    "days_before_coupon_expiration": "쿠폰 만료 잔여일",
    "unpaid_orders_count": "무통장 미입금 주문수",
    "max_best_mileage": "최대 적립금",
    "days_before_expiration": "적립금 지급 기한 만료 잔여일 수",
    "days_before_inactivation": "휴면회원 전환 예정 일수"
  }
}
</i18n>
