<template>
  <div class="AppCartPopupPreview">
    <div class="AppCartPopupPreview__popup">
      <div class="AppCartPopupPreview__header">
        <pre>{{ $t('cart_message') }}</pre>
        <div class="AppCartPopupPreview__button">{{ $t('cart_button') }}</div>
        <AppSvgIcon
          class="AppCartPopupPreview__close"
          name="icon-close-small"
          :height="20"
        />
      </div>
      <div class="AppCartPopupPreview__body" :style="{ height: previewHeight }">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import IframeHeightSetter from '@/mixins/IframeHeightSetter';

export default {
  name: 'AppCartPopupPreview',
  mixins: [IframeHeightSetter],
  computed: {
    previewHeight() {
      if (!this.iframeHeight) return '0px';

      const nowIframeHeight = parseInt(this.iframeHeight.replace('px', ''));
      const padding = 1;
      return `${nowIframeHeight + padding}px`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

$popup-modal-head: 64px;
$popup-modal-margin: 40px;
$popup-device-button-height: 55px;
$popup-info-box-height: 64px;
$popup-preview-margin: 64px;
$popup-height-without-preview: $popup-modal-margin + $popup-device-button-height +
  $popup-info-box-height + $popup-preview-margin;
.AppCartPopupPreview__popup {
  position: relative;
  padding: 30px 20px 24px 20px;
  width: 406px;
  max-height: calc(100vh - ($popup-modal-head + $popup-height-without-preview));
  border-radius: 16px;
  box-shadow: 0px 0px 33px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  overflow-y: auto;

  .TargetCampaignProductRecommendationWidgetPreview__iframe--popup-with-form & {
    max-height: calc(100vh - $popup-height-without-preview);
  }
}

.AppCartPopupPreview__header {
  font-size: 17px;
  font-weight: bold;
  line-height: 24px;
  color: $color-grey-50;
}

.AppSvgIcon.AppCartPopupPreview__close {
  position: absolute;
  top: 20px;
  right: 20px;
  fill: $color-grey-50;
}

.AppCartPopupPreview__button {
  margin-top: 16px;
  width: 100%;
  height: 45px;
  font-size: 14px;
  font-weight: bold;
  line-height: 45px;
  text-align: center;
  color: $color-grey-50;
  border-radius: 6px;
  border: 1px solid $color-grey-40;
  background-color: $color-background;
}

.AppCartPopupPreview__body {
  margin-top: 24px;
  width: 366px;
}
</style>

<i18n locale="ko">
{
  "cart_message": "상품이 장바구니에\n정상적으로 담겼습니다.",
  "cart_button": "장바구니 바로가기"
}
</i18n>
