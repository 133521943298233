<template>
  <div :class="['AppColorPicker', { 'AppColorPicker--disabled': disabled }]">
    <button
      ref="button"
      type="button"
      class="AppColorPicker__button"
      :disabled="disabled"
      @click="isColorPickerVisible = !isColorPickerVisible"
    >
      <div
        :class="[
          'AppColorPicker__color-box',
          currentValue === 'transparent'
            ? `AppColorPicker__color-box--${currentValue}`
            : null
        ]"
        :style="{ backgroundColor: currentValue }"
      />
      <div class="AppColorPicker__color-info">
        <div
          :class="[
            'AppColorPicker__button-text',
            { 'AppColorPicker__button-text--disabled': disabled }
          ]"
        >
          {{ label }}
        </div>
        <div
          :class="[
            'AppColorPicker__color-text',
            { 'AppColorPicker__color-text--disabled': disabled }
          ]"
        >
          {{ currentValue }}
        </div>
      </div>
    </button>
    <AppModeless
      v-if="isColorPickerVisible"
      ref="colorPicker"
      @close="isColorPickerVisible = false"
    >
      <Sketch disable-alpha :value="currentValue" @input="updateValue" />
    </AppModeless>
    <input type="hidden" :name="name" :value="currentValue" />
  </div>
</template>

<script>
import { Sketch } from 'vue-color';

export default {
  name: 'AppColorPicker',
  components: { Sketch },
  props: {
    value: {
      type: String,
      default: '#000000'
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default() {
        return this.$t('select_color');
      }
    },
    disabled: {
      type: Boolean,
      defualt: false
    }
  },
  data() {
    return {
      isColorPickerVisible: false
    };
  },
  computed: {
    currentValue() {
      return this.value || '#000000';
    }
  },
  methods: {
    updateValue({ hex, hex8 }) {
      const value = hex8 === '#00000000' ? 'transparent' : hex;
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_inputs.scss';

.AppColorPicker {
  position: relative;
  display: inline-block;

  & + & {
    margin-left: 8px;
  }

  &--disabled {
    pointer-events: none;
  }
}

.AppColorPicker__button {
  @include input-base;
  width: auto;
  position: relative;
  text-align: left;
}

.AppColorPicker__color-box {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.AppColorPicker__color-box--transparent {
  background-color: white;
  background-image: linear-gradient(
      45deg,
      lightgray 25%,
      transparent 25%,
      transparent 75%,
      lightgray 75%,
      lightgray
    ),
    linear-gradient(
      45deg,
      lightgray 25%,
      transparent 25%,
      transparent 75%,
      lightgray 75%,
      lightgray
    );
  background-size: 12px 12px;
  background-position: 0 0, 6px 6px;
}

.AppColorPicker__color-info {
  padding: 4px 8px 5px 36px;
}

.AppColorPicker__color-text {
  font-size: 10px;
  line-height: 10px;
  color: $color-grey-50;

  &--disabled {
    color: $color-disable-text;
  }
}

.AppColorPicker__button-text {
  @include text-tag;
  line-height: 13px;

  &--disabled {
    color: $color-disable-text;
  }
}
</style>

<i18n locale="ko">
{
  "select_color": "색상 선택"
}
</i18n>
