<template>
  <div class="BizMessageDefaultTemplatesTable">
    <AppHeading :label="$t('default_templates')" />
    <div class="BizMessageDefaultTemplatesTable__table-description">
      {{ $t('description') }}
    </div>
    <AppTable v-bind="{ columns, rows }">
      <template #cell="{ column, row }">
        <template v-if="column.id === 'service_type'">
          {{ CremaServiceType.t(row.service_type) }}
        </template>
        <template v-else-if="column.id === 'manage'">
          <AppButton :label="$t('detail')" @click="openDetailDialog(row.id)" />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';
import CremaServiceType from '@/enums/CremaServiceType';

export default {
  name: 'BizMessageDefaultTemplatesTable',
  props: { serviceType: { type: Number, default: null } },
  computed: {
    ...mapState('bizMessageTemplate', ['groupTemplates']),
    CremaServiceType: () => CremaServiceType,
    columns() {
      return [
        ...(this.serviceType === null
          ? [
              {
                id: 'service_type',
                type: 'text',
                label: this.$t('table.service_type')
              }
            ]
          : []),
        {
          id: 'name',
          type: 'text',
          label: this.$t('table.name')
        },
        {
          id: 'manage',
          type: 'custom',
          label: this.$t('app.manage'),
          width: '20%'
        }
      ];
    },
    rows() {
      return this.groupTemplates;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openDetailDialog(templateId) {
      this.openDialog([
        'BizMessageTemplateShowDialog',
        { templateId, templateType: BizMessageTemplateType.GROUP }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.BizMessageDefaultTemplatesTable__table-description {
  @include text-content-light;
  padding-bottom: 12px;
}
</style>

<i18n locale="ko">
{
  "default_templates": "기본 템플릿",
  "description": "기본 템플릿은 검수 없이 사용이 가능하며, 수정이 불가능합니다.",
  "table": {
    "service_type": "제품",
    "name": "템플릿 명"
  },
  "detail": "상세보기"
}
</i18n>
