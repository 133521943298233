<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="productDisplayName"
    :form-props="{
      ...formProps,
      formStyle: 'narrow',
      submitLabel: $t('submit_button.submit'),
      submittingLabel: $t('submit_button.submitting')
    }"
    v-on="formEvents"
  >
    <template #group="{ id, errors }">
      <template v-if="id === 'schedule'">
        <AppDatePicker
          v-model="formObject.display_date"
          class="AppForm__group-field--mt8 DisplayProductDisplayScheduleDialog__input"
          name="product_display[display_date]"
          :min-date="nextAvailableTime.toVal()"
        />
        <AppSelectHour
          v-model="formObject.display_hour"
          class="AppForm__group-field--mt8 DisplayProductDisplayScheduleDialog__input"
          name="product_display[display_hour]"
          :invalid="!!errors.display_hour"
          @change="validateField('display_hour')"
        />
        <AppFormError :error="errors.display_hour" />
      </template>
    </template>
    <template #group-sub-item="{ group, value, errors, checked }">
      <template v-if="group.id === 'schedule_type'">
        <template v-if="value === 'schedule'">
          <div>{{ $t('schedule.description') }}</div>
          <AppDatePicker
            v-model="formObject.display_date"
            class="AppForm__group-field DisplayProductDisplayScheduleDialog__input"
            name="product_display[display_date]"
            :disabled="!checked"
            :min-date="nextAvailableTime.toVal()"
          />
          <AppSelectHour
            v-model="formObject.display_hour"
            class="AppForm__group-field DisplayProductDisplayScheduleDialog__input"
            name="product_display[display_hour]"
            :invalid="!!errors.display_hour"
            :disabled="!checked"
            @change="validateField('display_hour')"
          />
          <AppFormError :error="errors.display_hour" />
        </template>
        <template v-else-if="value === 'auto_schedule'">
          {{ $t('auto_schedule.description') }}
          <i18n path="auto_schedule.weekdays" class="AppForm__group-field">
            <AppWeekdaySelectButton
              v-model="formObject.execute_weekdays_value"
              class="AppForm__group-field DisplayProductDisplayScheduleDialog__weekdays"
              name="product_display[execute_weekdays_value]"
              :invalid="!!errors.execute_weekdays_value"
              :disabled="!checked"
              @change="validateField('execute_weekdays_value')"
            />
          </i18n>
          <AppFormError :error="errors.execute_weekdays_value" />
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="AppForm__group-field"
            v-html="$t('auto_schedule.description_html')"
          />
          <!-- eslint-enable vue/no-v-html -->
        </template>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'DisplayProductDisplayScheduleDialog',
  mixins: [DialogFormView],
  props: { productDisplayName: { type: String, required: true } },
  computed: {
    ...mapState('displayProductDisplay', ['display']),
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    scheduleNextDisplayFields() {
      return [
        { id: 'display_date' },
        {
          id: 'display_hour',
          validate: moment(this.formObject.display_date).isSame(moment(), 'day')
            ? [
                {
                  rule: v => v > moment().hour(),
                  errorMessage: this.$t(
                    'schedule.validations.display_hour_must_be_gt_now'
                  )
                }
              ]
            : []
        }
      ];
    },
    autoScheduleDisplayFields() {
      return [
        {
          id: 'execute_weekdays_value',
          validate: [
            {
              rule: 'positive_integer',
              errorMessage: this.$t('validations.required')
            }
          ]
        }
      ];
    },
    formGroups() {
      return [
        {
          id: 'description',
          label: '',
          groupDescription: this.isCategoryPage
            ? this.$t('description.category', [this.display.corners.length])
            : this.$t('description.main')
        },
        {
          id: 'schedule_type',
          label: '',
          type: 'select_radio',
          options: [
            {
              label: this.$t('schedule.label'),
              value: 'schedule'
            },
            {
              label: this.$t('auto_schedule.label'),
              value: 'auto_schedule'
            }
          ],
          fields:
            this.formObject.schedule_type === 'schedule'
              ? this.scheduleNextDisplayFields
              : this.autoScheduleDisplayFields
        }
      ];
    },
    formSections() {
      return [{ groups: this.formGroups }];
    },
    nextAvailableTime() {
      return moment().add(1, 'hour');
    }
  },
  mounted() {
    this.formObject = {
      display_date: this.nextAvailableTime.toVal(),
      display_hour: this.nextAvailableTime.hour(),
      schedule_type: this.display.auto_scheduling
        ? 'auto_schedule'
        : 'schedule',
      execute_weekdays_value: this.display.execute_weekdays_value
    };
  },
  methods: {
    submit() {
      this.orgFormObject = this.formObject;

      if (this.formObject.schedule_type === 'schedule') {
        const { display_date, display_hour } = this.formObject;
        this.eventBus.$emit('schedule', { display_date, display_hour });
      } else {
        const { execute_weekdays_value } = this.formObject;
        this.eventBus.$emit('auto-schedule', {
          execute_weekdays_value
        });
      }
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.DisplayProductDisplayScheduleDialog__input {
  vertical-align: middle;

  & + & {
    margin-left: 8px;
  }
}

.DisplayProductDisplayScheduleDialog__weekdays {
  display: inline-block;
  margin-right: 4px;
}
</style>

<i18n locale="ko">
{
  "title": "예약 진열",
  "description": {
    "main": "지정된 시간에 자동으로 진열하도록 예약할 수 있습니다.<br />고정되지 않은 수동 진열 변경 사항은 사라집니다.",
    "category": "<b>총 {0}개의 카테고리</b>에 대해 지정된 시간에 진열 규칙에 따라 업데이트하여 진열합니다.<br />고정되지 않은 수동 진열 변경 사항은 사라집니다."
  },
  "schedule": {
    "label": "1회 진열 예약",
    "description": "현재 미리보기에 보여지는 진열 순서 그대로 지정된 시간에 진열 합니다.",
    "validations": {
      "display_hour_must_be_gt_now": "현재 시간 이후의 시간을 지정해야 합니다."
    }
  },
  "auto_schedule": {
    "label": "반복 진열 예약",
    "description": "지정된 시간에 최신 데이터를 동기화하여 진열 규칙에 따라 진열합니다.",
    "weekdays": "{0}요일의",
    "description_html": "오전 8시부터 오후 11시까지<br />3시간에 한번씩 진열합니다."
  },
  "submit_button": {
    "submit": "적용",
    "submitting": "적용 중.."
  }
}
</i18n>
