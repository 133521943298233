<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title', { count: reviews.length })"
    :form-props="{
      ...formProps,
      submitLabel: $t('submit_label')
    }"
    v-on="formEvents"
  >
    <template #group="{ id, error }">
      <div v-if="id === 'use_comment_preset'" class="AppForm__group-field">
        <AppSelectRadio
          v-model="batchComment.use_comment_preset"
          :options="[
            { label: $t('use_comment_preset_true'), value: true },
            { label: $t('use_comment_preset_false'), value: false }
          ]"
          @change="validateField(id)"
        >
          <template #sub-item="{ value, checked }">
            <AppButton
              v-if="value"
              :label="$t('comment_preset_button')"
              @click="openDialog('ReviewSettingsCommentPresetDialog')"
            />
            <AppTextarea
              v-else
              v-model="batchComment.comment_message"
              :placeholder="$t('comment_message_placeholder')"
              :rows="4"
              :autosize="false"
              :invalid="!!error"
              :disabled="!checked"
              @change="validateField(id)"
            />
          </template>
        </AppSelectRadio>
      </div>
    </template>
  </AppModalForm>
</template>

<script>
import { mapActions } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewReviewsBatchCommentDialog',
  mixins: [DialogFormView],
  props: {
    reviews: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      orgFormObject: {
        use_comment_preset: true,
        comment_message: null
      },
      detectFormDataChange: false
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'use_comment_preset',
              label: this.$t('use_comment_preset'),
              validate: this.batchComment.use_comment_preset
                ? []
                : [
                    {
                      rule: 'required',
                      value: () => this.batchComment.comment_message
                    }
                  ]
            }
          ]
        }
      ];
    },
    batchComment() {
      return this.formObject;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    submit() {
      this.close();
      const TRANSITION_DELAY = 100;
      setTimeout(
        () => this.emit('apply', { ...this.batchComment }),
        TRANSITION_DELAY
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "댓글 입력",
  "sub_title": "선택 리뷰 {count}개",
  "use_comment_preset": "선택한 리뷰에 입력할 댓글 내용",
  "use_comment_preset_true": "일괄 댓글 설정에 입력해둔 댓글 내용을 리뷰마다 랜덤하게 적용",
  "use_comment_preset_false": "댓글 내용 직접 입력",
  "comment_preset_button": "일괄 댓글 설정",
  "comment_message_placeholder": "댓글 내용을 입력해주세요.",
  "submit_label": "댓글 입력"
}
</i18n>
<i18n locale="en">
{
  "title": "Batch comment",
  "sub_title": "{count} reviews selected",
  "use_comment_preset": "Comment content to be entered for the selected review",
  "use_comment_preset_true": "Randomly apply the comments entered in the batch comment settings to each review",
  "use_comment_preset_false": "Direct input of comment",
  "comment_preset_button": "Batch comment settings",
  "comment_message_placeholder": "Please write comment.",
  "submit_label": "Submit"
}
</i18n>
