<template>
  <AppDataBox :title="$t('title')">
    <div class="TargetCampaignsPrices__inner">
      <div
        v-for="(price, i) in prices"
        :key="i"
        class="TargetCampaignsPrices__content"
      >
        <div class="TargetCampaignsPrices__type">
          {{ price.label }}
        </div>
        <div class="TargetCampaignsPrices__price">
          <span v-if="price.value === 0">{{ $t('free') }}</span>
          <AppNumber v-else :value="price.value" unit="currency" />
        </div>
      </div>
    </div>
  </AppDataBox>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'TargetCampaignsPrices',
  props: {
    campaign: { type: Object, required: true }
  },
  computed: {
    prices() {
      const priceKeys = [
        'sms_price',
        'lms_price',
        'biz_message_price',
        'email_price',
        'popup_price'
      ];
      return priceKeys
        .filter(key => _.isNumber(this.campaign[key]))
        .map(key => ({
          label: this.$t(`label.${key}`),
          value: this.campaign[key]
        }));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';

.TargetCampaignsPrices__inner {
  width: 100%;
  white-space: nowrap;

  @include media-breakpoint-each(tablet) {
    max-width: 150px;
  }

  @include media-breakpoint-each(mobile) {
    width: 150px;
  }
}

.TargetCampaignsPrices__content {
  display: inline-block;

  & + & {
    margin-left: 28px;
  }
}

.TargetCampaignsPrices__type {
  @include text-label;
}

.TargetCampaignsPrices__price {
  @include text-content;
}

.TargetCampaignsPrices__unit {
  @include media-breakpoint-each(tablet, mobile) {
    display: none;
  }
}
</style>

<i18n locale="ko">
{
  "title": "발송 매체 및 발송당 가격",
  "label": {
    "sms_price": "SMS",
    "lms_price": "LMS",
    "biz_message_price": "알림톡",
    "email_price": "이메일",
    "popup_price": "팝업"
  },
  "free": "무제한"
}
</i18n>
