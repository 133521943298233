<template>
  <div class="AppMultipleSelectBox">
    <div class="AppMultipleSelectBox__boxes">
      <div class="AppMultipleSelectBox__box">
        <div class="AppMultipleSelectBox__options-title">
          {{ searchOptionsLabel || $t('search_options') }}
          <div class="AppMultipleSelectBox__options-label-filter">
            <slot name="label" />
          </div>
        </div>
        <div class="AppMultipleSelectBox__search-options-content">
          <div class="AppMultipleSelectBox__search">
            <input
              v-model="query"
              class="AppMultipleSelectBox__search-input"
              type="text"
              :placeholder="queryPlaceholder || $t('query_placeholder')"
            />
            <button
              v-if="!!query"
              type="button"
              class="AppMultipleSelectBox__search-input-clear-button"
              @click="query = ''"
            >
              <AppSvgIcon name="icon-close-small" />
            </button>
          </div>
          <div class="AppMultipleSelectBox__search-options">
            <ul class="AppMultipleSelectBox__search-options-list">
              <li
                v-for="option in filteredOptions"
                :key="option.id"
                class="AppMultipleSelectBox__search-option-item"
                @mouseenter="onDrag(option)"
                @mousedown="onMousedown($event, option)"
              >
                <AppCheckbox
                  class="AppMultipleSelectBox__checkbox"
                  :checked="isSelected(option)"
                  :disabled="option.disabled"
                  :label="option.label"
                  :invalid="invalid"
                  input-style="block"
                  @change="$event ? select(option) : unselect(option)"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="AppMultipleSelectBox__box">
        <div class="AppMultipleSelectBox__options-title">
          {{ selectedOptionsLabel || $t('selected_options') }}
        </div>
        <div class="AppMultipleSelectBox__selected-options-content">
          <div
            v-if="!checkedOptions.length && selectedOptionsPlaceholder"
            class="AppMultipleSelectBox__selected-options-placeholder"
          >
            {{ selectedOptionsPlaceholder }}
          </div>
          <div class="AppMultipleSelectBox__selected-options">
            <ul>
              <li
                v-for="option in checkedOptions"
                :key="option.id"
                class="AppMultipleSelectBox__selected-option-item"
              >
                <AppSelection
                  :label="option.label"
                  @remove="unselect(option)"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <AppHiddenInputArray v-if="name" :name="name" :values="currentValue" />
  </div>
</template>

<script>
import MultipleSelectable from '@/mixins/MultipleSelectable';

export default {
  name: 'AppMultipleSelectBox',
  mixins: [MultipleSelectable],
  props: {
    invalid: { type: Boolean, default: false },
    queryPlaceholder: { type: String, default: null },
    selectedOptionsPlaceholder: { type: String, default: null },
    searchOptionsLabel: { type: String, default: null },
    selectedOptionsLabel: { type: String, default: null }
  },
  data() {
    return {
      query: '',
      draggingOption: null
    };
  },
  computed: {
    filteredOptions() {
      if (!this.query) return this.options;
      return this.options.filter(option => option.label.includes(this.query));
    },
    checkedOptions() {
      return this.options.filter(this.isSelected);
    }
  },
  methods: {
    onMousedown(e, option) {
      let clicked = true;

      document.addEventListener(
        'mouseup',
        () => {
          clicked = false;
          this.draggingOption = null;
        },
        { once: true }
      );

      e.currentTarget.addEventListener(
        'mouseleave',
        () => {
          if (!clicked) return;

          this.draggingOption = option;
          this.isSelected(option) ? this.unselect(option) : this.select(option);
        },
        { once: true }
      );
    },
    onDrag(option) {
      if (!this.draggingOption || option.disabled) return;

      this.isSelected(this.draggingOption)
        ? this.select(option)
        : this.unselect(option);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_clearfix.scss';
@import '@/scss/vars/_colors.scss';

.AppMultipleSelectBox__boxes {
  @include clearfix;
  display: flex;
}

.AppMultipleSelectBox__box {
  flex: 1;

  & + & {
    margin-left: 12px;
  }
}

.AppMultipleSelectBox__options-title {
  margin-bottom: 4px;
}

.AppMultipleSelectBox__options-label-filter {
  float: right;
}

.AppMultipleSelectBox__search-options-content,
.AppMultipleSelectBox__selected-options-content {
  border-radius: 3px;
  border: solid 1px $color-grey-35;
  height: 360px;
}

$search-height: 37px;

.AppMultipleSelectBox__search-options {
  height: calc(100% - #{$search-height});
  overflow-y: auto;
}

.AppMultipleSelectBox__search-options-list {
  display: flex;
  flex-wrap: wrap;
  padding: 9px 8px;
}

.AppMultipleSelectBox__selected-options-content {
  padding: 12px;
  overflow-y: auto;
}

.AppMultipleSelectBox__selected-options-placeholder {
  @include text-placeholder;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.AppMultipleSelectBox__search {
  position: relative;
  border-bottom: solid 1px $color-grey-35;
  height: $search-height;
}

.AppMultipleSelectBox__search-input {
  @include text-content;
  width: 100%;
  padding: 8px 36px 8px 17px;
  border: 0;

  &::placeholder {
    color: $color-grey-40;
  }
}

.AppMultipleSelectBox__search-input-clear-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  line-height: 0;
  color: $color-grey-70;
}

.AppMultipleSelectBox__search-option-item {
  width: 100%;
}

.AppMultipleSelectBox__checkbox {
  user-select: none;
  padding: 6px 9px;

  &:hover {
    background-color: $color-grey-15;
    border-radius: 3px;
  }
}

.AppMultipleSelectBox__selected-option-item {
  padding-bottom: 4px;

  &:last-child {
    padding-bottom: 0;
  }
}
</style>

<i18n locale="ko">
{
  "query_placeholder": "검색어를 입력해주세요",
  "search_options": "전체 항목",
  "selected_options": "선택 항목"
}
</i18n>
