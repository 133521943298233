<template>
  <AppModalForm
    :title="$t('title')"
    :is-loading="isLoading"
    :form-props="{ ...formProps, objectId: 'display_settings' }"
    v-on="formEvents"
  >
    <template #group="{ id, options, errors }">
      <template v-if="id === 'preview_limit_products_count'">
        <AppNumberInput
          v-model="formObject.preview_max_products"
          class="AppForm__group-field"
          name="display_settings[preview_max_products]"
          :digits="3"
          :disabled="!formObject.preview_limit_products_count"
          :invalid="!!errors.preview_max_products"
          @blur="validateField('preview_max_products')"
          @change="validateField('preview_max_products')"
        />
        <AppFormError :error="errors.preview_max_products" />
      </template>
    </template>
    <input type="hidden" name="page_type" :value="cornerPageType" />
  </AppModalForm>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'DisplayProductDisplayPreviewSettingsDialog',
  mixins: [DialogFormView],
  data() {
    return { isLoading: true };
  },
  computed: {
    ...mapState('displayProductDisplays', ['cornerPageType']),
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'info',
              label: '',
              value: this.$t('info_html'),
              type: 'infobox'
            },
            {
              id: 'preview_products_count_in_line',
              label: this.$t('columns.preview_products_count_in_line'),
              type: 'select',
              options: [1, 2, 3, 4, 5, 6].map(value => ({
                value,
                label: this.$t('unit.count', [value])
              }))
            },
            {
              id: 'preview_products_count_in_page',
              label: this.$t('columns.preview_products_count_in_page.label'),
              type: 'number',
              digits: 3,
              groupDescription: this.$t(
                'columns.preview_products_count_in_page.description'
              ),
              validate: [
                'required',
                { rule: 'min_max_integer', min: 1, max: 999 }
              ]
            },
            ...(this.isCategoryPage
              ? []
              : [
                  {
                    id: 'preview_limit_products_count',
                    label: this.$t(
                      'columns.preview_limit_products_count.label'
                    ),
                    type: 'checkbox',
                    groupDescription: this.$t(
                      'columns.preview_limit_products_count.description'
                    ),
                    fields: this.formObject.preview_limit_products_count
                      ? [
                          {
                            id: 'preview_max_products',
                            validate: [
                              'required',
                              { rule: 'min_max_integer', min: 0, max: 999 }
                            ]
                          }
                        ]
                      : []
                  }
                ]),
            {
              id: 'use_original_product_image',
              label: this.$t('columns.use_original_product_image.label'),
              type: 'checkbox',
              description: this.$t(
                'columns.use_original_product_image.description_html'
              )
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get('/display/settings/previews', {
        params: { page_type: this.cornerPageType }
      })
      .then(({ data }) => (this.orgFormObject = data.display_settings))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('displayPreviewSettings', ['UPDATE_PREVIEW_SETTINGS']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/display/settings/previews', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
          this.UPDATE_PREVIEW_SETTINGS(this.formObject);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "미리보기 설정",
  "info_html": "미리보기 설정은 쇼핑몰과 동일한 환경의 미리보기 화면을 만들기 위한 설정입니다.<br />쇼핑몰과 동일한 한 줄당 노출 상품 수 및 페이지당 노출 상품 수를 설정해주세요.",
  "columns": {
    "preview_products_count_in_line": "한 줄당 표시할 상품 수",
    "preview_products_count_in_page": {
      "label": "한 페이지에 표시할 상품 수",
      "description": "선택한 수 만큼만 표시하고 이보다 많은 상품이 있다면 다음 페이지에 표시됩니다."
    },
    "preview_limit_products_count": {
      "label": "미리보기 유지 상품 수 제한",
      "description": "저장, 또는 진열 시 미리보기에 유지하는 상품 수를 제한합니다. 설정된 값 이상의 상품은 미리보기에서 제외됩니다."
    },
    "use_original_product_image": {
      "label": "쇼핑몰 원본 이미지 사용",
      "description_html": "쇼핑몰 원본 이미지를 사용하여 미리보기에서 GIF 및 원본 이미지 비율대로 표시합니다.<br>각 상품에 등록된 이미지 비율이 다르다면 미리보기에 표시될 때 정렬이 어긋날 수 있습니다."
    }
  }
}
</i18n>
