<template>
  <AppExternalLink class="AppExternalLinkWithIcon" :to="to" v-on="$listeners">
    <div class="AppExternalLinkWithIcon__label">
      <slot>{{ label }}</slot>
    </div>
    <AppExternalLinkIcon />
  </AppExternalLink>
</template>

<script>
export default {
  name: 'AppExternalLinkWithIcon',
  props: {
    to: { type: String, required: true },
    label: { type: String, default: '' }
  }
};
</script>

<style lang="scss" scoped>
.AppExternalLinkWithIcon {
  vertical-align: text-bottom;
}

.AppExternalLinkWithIcon__label {
  vertical-align: text-top;
  display: inline-block;
}
</style>
