<template>
  <div class="DisplayCornerPreviewSelectbar">
    <div class="DisplayCornerPreviewSelectbar__content">
      <div class="DisplayCornerPreviewSelectbar__close-button" @click="turnOff">
        <AppSvgIcon name="icon-close" />
      </div>
      <span class="DisplayCornerPreviewSelectbar__selected-text">
        <b>{{ selectedProducts.length }}</b
        >{{ $t('selected') }}
      </span>
      <div class="DisplayCornerPreviewSelectbar__vertical-line" />
      <div class="DisplayCornerPreviewSelectbar__buttons">
        <DisplayCornerPreviewSelectbarButton
          v-if="!isPinnedProductIncluded"
          icon="icon-lock"
          :label="$t('button.pin')"
          @click="pinSelectedProducts"
        />
        <DisplayCornerPreviewSelectbarButton
          v-if="isPinnedProductIncluded"
          icon="icon-unlock"
          :label="$t('button.unpin')"
          @click="unpinSelectedProducts"
        />
        <DisplayCornerPreviewSelectbarButton
          v-if="!isPinnedProductIncluded && !isCategoryPage"
          icon="icon-emit"
          :label="$t('button.exclude')"
          @click="excludeSelectedProducts"
        />
        <DisplayCornerPreviewSelectbarButton
          v-if="!isPinnedProductIncluded && isCategoryPage"
          icon="icon-emit"
          :label="$t('button.remove')"
          :disabled="isRemoveButtonDisabled"
          :tooltip="isRemoveButtonDisabled ? $t('tooltip.remove') : null"
          @click="removeSelectedProducts"
        />
        <DisplayCornerPreviewSelectbarButton
          v-if="!isPinnedProductIncluded"
          icon="icon-move"
          :label="$t('button.sort')"
          @click="sortSelectedProducts"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'DisplayCornerPreviewSelectbar',
  props: {
    selectedProducts: { type: Array, required: true }
  },
  computed: {
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    ...mapGetters('displayProductDisplay', [
      'isPinnedProduct',
      'cornerProductCategory'
    ]),
    isPinnedProductIncluded() {
      return this.selectedProducts.some(product =>
        this.isPinnedProduct(product)
      );
    },
    isRemoveButtonDisabled() {
      return this.cornerProductCategory?.hasChildCategory || false;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('displayProductDisplay', ['UNPIN_PRODUCTS']),
    pinSelectedProducts() {
      this.openDialog([
        'DisplayCornerPinProductDialog',
        { selectedProducts: this.selectedProducts }
      ]).then(eventBus => eventBus.$on('pin', this.turnOff));
    },
    unpinSelectedProducts() {
      this.UNPIN_PRODUCTS(this.selectedProducts);
      this.turnOff();
    },
    excludeSelectedProducts() {
      this.openDialog([
        'DisplayCornerExcludingProductDialog',
        { selectedProducts: this.selectedProducts }
      ]).then(eventBus => eventBus.$on('exclude', this.turnOff));
    },
    removeSelectedProducts() {
      this.openDialog([
        'DisplayCornerRemoveProductDialog',
        { selectedProducts: this.selectedProducts }
      ]).then(eventBus => eventBus.$on('remove', this.turnOff));
    },
    sortSelectedProducts() {
      this.openDialog([
        'DisplayCornerSortProductDialog',
        { selectedProducts: this.selectedProducts }
      ]).then(eventBus =>
        eventBus.$on('sort', dstIndex => {
          this.turnOff();
          this.$emit('sort', dstIndex);
        })
      );
    },
    turnOff() {
      this.$emit('turn-off');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/vars/_z-indexes.scss';

.DisplayCornerPreviewSelectbar {
  position: sticky;
  height: 48px;
  z-index: $z-index-tooltip-modal;
}

.DisplayCornerPreviewSelectbar__content {
  padding: 12px 16px;
  border-radius: 32px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
  display: inline-flex;
}

.DisplayCornerPreviewSelectbar__close-button {
  width: 24px;
  height: 24px;
  padding: 5px;
  cursor: pointer;
}

.DisplayCornerPreviewSelectbar__selected-text {
  margin-left: 8px;
  padding: 2px 0;

  > b {
    margin-right: 4px;
  }
}

.DisplayCornerPreviewSelectbar__vertical-line {
  width: 1px;
  height: 24px;
  background-color: $color-grey-25;
  margin: 0 24px;
}

.DisplayCornerPreviewSelectbar__buttons {
  display: flex;
  padding: 2px 0;
}
</style>

<i18n locale="ko">
{
  "selected": "개 선택됨",
  "button": {
    "pin": "위치 고정",
    "unpin": "위치 고정 해제",
    "exclude": "진열 제외",
    "remove": "카테고리 제외",
    "sort": "위치 이동"
  },
  "tooltip": {
    "remove": "상품이 포함된 최하위 카테고리에서 제거할 수 있습니다."
  }
}
</i18n>
