import { isPasswordValid } from '@/lib/passwordValidator';

export default {
  data() {
    return { isCurrentPasswordInvalid: false };
  },
  computed: {
    newPasswordRequirementsGroup() {
      return {
        id: 'new_password_requirements',
        type: 'new_password_requirements',
        label: this.$t('password_field.new_password_requirements')
      };
    }
  },
  methods: {
    currentPasswordGroup(required) {
      return {
        id: 'current_password',
        type: 'password',
        label: this.$t('password_field.current_password'),
        placeholder: this.$t('password_field.current_password_placeholder'),
        required: false,
        validate: [
          ...(required ? ['required'] : []),
          {
            rule: () => !this.isCurrentPasswordInvalid,
            errorMessage: this.$t('password_field.invalid_password')
          }
        ]
      };
    },
    newPasswordGroup(required, label) {
      return {
        id: 'password',
        type: 'new_password',
        label: label || this.$t('settings.user_settings.groups.password'),
        fields: this.passwordFields(required),
        validate: required ? ['required'] : []
      };
    },
    passwordFields(required) {
      const passwordValue = this.formObject.password;
      return [
        {
          id: 'password[password]',
          value: passwordValue ? passwordValue.password : '',
          validate: required
            ? [
                'required',
                {
                  rule: v => isPasswordValid(v),
                  errorMessage: this.$t('validations.password')
                }
              ]
            : [
                {
                  rule: v => !v || isPasswordValid(v),
                  errorMessage: this.$t('validations.password')
                }
              ]
        },
        {
          id: 'password[password_confirmation]',
          value: passwordValue,
          validate: [
            ...(required
              ? [
                  {
                    rule: v => !!v && !!v.password_confirmation,
                    errorMessage: this.$t('validations.required')
                  }
                ]
              : []),
            {
              rule: v => !v || v.password === v.password_confirmation,
              errorMessage: this.$t('validations.password_confirmation')
            }
          ]
        }
      ];
    },
    changeGroup({ id }) {
      if (id === 'current_password') this.isCurrentPasswordInvalid = false;
    },
    setCurrentPasswordInvalid() {
      this.isCurrentPasswordInvalid = true;
      this.validateField('current_password');
    }
  }
};
