import Vue from 'vue';
import SearchCategory from '@/enums/SearchCategory';

const state = {
  isSubMenuVisibleDesktop: true,
  enableSuperAdminMode: true,
  enableSuperAdminHighlight: false,
  enableI18nKey: false,
  columnVisibleMap: {},
  miniNotices: [],
  savedScrollPosition: null,
  recentlyVisitedPages: [],
  snoozedAlerts: {},
  snoozedMiniNotices: {},
  helpBubbleDisplayedDate: {}
};

function helpBubbleDisplayedDateKey(session) {
  const userId = session.currentUser.id;
  const brandId = session.currentBrand.id;
  return `${userId}.${brandId}`;
}

const getters = {
  helpBubbleDisplayedDatesByBrand(
    { helpBubbleDisplayedDate },
    _getters,
    { session }
  ) {
    const displayedDates =
      helpBubbleDisplayedDate[helpBubbleDisplayedDateKey(session)] || {};
    return displayedDates;
  }
};

const MAX_RECENT_PAGES = 5;

const mutations = {
  INITIALIZE_EDITOR_CONFIG(state, editorConfig) {
    for (const key in editorConfig) {
      state[key] = editorConfig[key];
    }
  },
  SET_SUB_MENU_VISIBLE_DESKTOP(state, isSubMenuVisibleDesktop) {
    state.isSubMenuVisibleDesktop = isSubMenuVisibleDesktop;
  },
  SET_ENABLE_SUPER_ADMIN_MODE(state, enableSuperAdminMode) {
    state.enableSuperAdminMode = enableSuperAdminMode;
  },
  SET_ENABLE_SUPER_ADMIN_HIGHLIGHT(state, enableSuperAdminHighlight) {
    state.enableSuperAdminHighlight = enableSuperAdminHighlight;
  },
  SET_ENABLE_I18N_KEY(state, enableI18nKey) {
    state.enableI18nKey = enableI18nKey;
  },
  SET_COLUMN_VISIBILE(state, { columnId, visible }) {
    Vue.set(state.columnVisibleMap, columnId, visible);
  },
  SET_MINI_NOTICES(state, miniNotices) {
    state.miniNotices = miniNotices;
  },
  SET_SCROLL_POSITION(state, scrollPosition) {
    state.savedScrollPosition = scrollPosition;
  },
  FILTER_RECENT_PAGES(state, manuals) {
    const manualsTitles = manuals.map(manual => manual.title);
    state.recentlyVisitedPages = state.recentlyVisitedPages.filter(
      page =>
        page.category !== SearchCategory.MANUALS ||
        manualsTitles.includes(page.title)
    );
  },
  ADD_RECENT_PAGE(state, page) {
    state.recentlyVisitedPages = [
      page,
      ...state.recentlyVisitedPages.filter(k => k.title !== page.title)
    ].slice(0, MAX_RECENT_PAGES);
  },
  SET_SNOOZE_ALERT(state, { id, alert }) {
    Vue.set(state.snoozedAlerts, id, alert);
  },
  SET_HELP_BUBBLE_DISPLAYED_DATE(state, { helpBubbleType, displayedDatesKey }) {
    const oldDisplayedDatesByKey =
      state.helpBubbleDisplayedDate[displayedDatesKey] || {};

    Vue.set(state.helpBubbleDisplayedDate, displayedDatesKey, {
      ...oldDisplayedDatesByKey,
      [helpBubbleType]: {
        lastSeen: new Date().valueOf()
      }
    });
  }
};

const actions = {
  loadEditorConfig({ commit }) {
    const rawEditorConfig = localStorage.getItem('editor');
    if (rawEditorConfig) {
      try {
        const editorConfig = JSON.parse(rawEditorConfig);
        commit('INITIALIZE_EDITOR_CONFIG', editorConfig);
      } catch {
        localStorage.clear();
      }
    }
  },
  saveEditorConfig({ state }) {
    localStorage.setItem('editor', JSON.stringify(state));
  },
  setSubMenuVisibleDesktop({ commit, dispatch }, isSubMenuVisibleDesktop) {
    commit('SET_SUB_MENU_VISIBLE_DESKTOP', isSubMenuVisibleDesktop);
    dispatch('saveEditorConfig');
  },
  setEnableSuperAdminMode({ commit, dispatch }, enableSuperAdminMode) {
    commit('SET_ENABLE_SUPER_ADMIN_MODE', enableSuperAdminMode);
    dispatch('saveEditorConfig');
  },
  setEnableSuperAdminHighlight(
    { commit, dispatch },
    enableSuperAdminHighlight
  ) {
    commit('SET_ENABLE_SUPER_ADMIN_HIGHLIGHT', enableSuperAdminHighlight);
    dispatch('saveEditorConfig');
  },
  setEnableI18nKey({ commit, dispatch }, enableI18nKey) {
    commit('SET_ENABLE_I18N_KEY', enableI18nKey);
    dispatch('saveEditorConfig');
  },
  setColumnVisible({ commit, dispatch }, { columnId, visible }) {
    commit('SET_COLUMN_VISIBILE', { columnId, visible });
    dispatch('saveEditorConfig');
  },
  saveScrollPosition({ commit, dispatch }, scrollPosition) {
    commit('SET_SCROLL_POSITION', {
      ...scrollPosition,
      savedTime: Date.now(),
      url: location.href
    });
    dispatch('saveEditorConfig');
  },
  resetScrollPosition({ commit, dispatch }) {
    commit('SET_SCROLL_POSITION', null);
    dispatch('saveEditorConfig');
  },
  filterRecentPages({ commit, dispatch }, manuals) {
    commit('FILTER_RECENT_PAGES', manuals);
    dispatch('saveEditorConfig');
  },
  addRecentPage({ commit, dispatch }, page) {
    commit('ADD_RECENT_PAGE', page);
    dispatch('saveEditorConfig');
  },
  setHelpBubbleDisplayedDate(
    { commit, dispatch, rootState: { session } },
    helpBubbleType
  ) {
    const displayedDatesKey = helpBubbleDisplayedDateKey(session);
    commit('SET_HELP_BUBBLE_DISPLAYED_DATE', {
      helpBubbleType,
      displayedDatesKey
    });
    dispatch('saveEditorConfig');
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
