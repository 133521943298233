import moment from 'moment';
import i18n from '@/lib/i18n';
import api from '@/lib/api';
import Vue from 'vue';
import DialogSize from '@/enums/DialogSize';
import TargetProductDataCalculationStatus from '@/enums/TargetProductDataCalculationStatus';

const JOB_TYPE = 'product_data_calculation';
const jobId = calculationId => `${JOB_TYPE}_${calculationId}`;

const defaultState = { calculations: [] };

const mutations = {
  SET_CALCULATION({ calculations }, calculation) {
    calculations.push(calculation);
  },
  UPDATE_CALCULATION({ calculations }, calculation) {
    const index = calculations.findIndex(({ id }) => id === calculation.id);
    Vue.set(calculations, index, { ...calculations[index], ...calculation });
  },
  REMOVE_CALCULATION(state, calculationId) {
    state.calculations = state.calculations.filter(
      ({ id }) => id !== calculationId
    );
  }
};

const getters = {
  isJobInProgressExists({ calculations }) {
    return !!calculations.find(
      ({ status }) => !TargetProductDataCalculationStatus.completed(status)
    );
  }
};

const DATE_FORMAT = 'YYYY. MM. DD.';
const formattedDateRange = ({ start_date, end_date }) => {
  return [start_date, end_date].map(date =>
    moment(date)
      .utcOffset(9)
      .format(DATE_FORMAT)
  );
};

const checkProgress = ({
  progressTo,
  resolve,
  state,
  commit,
  dispatch,
  calculationId
}) => {
  const interval = setInterval(() => {
    api
      .get(`/target/product_data/calculations/${calculationId}`)
      .then(({ data }) => {
        const { calculation } = data;
        if (calculation) {
          commit('UPDATE_CALCULATION', { id: calculationId, ...calculation });
          progressTo({ progressCount: calculation.progress_count });

          if (
            TargetProductDataCalculationStatus.completed(calculation.status)
          ) {
            resolve({ state, dispatch, calculationId });
            clearInterval(interval);
          }
        } else {
          dispatch('removeCalculationJob', calculationId);
          clearInterval(interval);
        }
      });
  }, 3000);
};

const checkResult = ({ state, dispatch, calculationId }) => {
  api.delete(`/target/product_data/calculations/${calculationId}`);
  const calculation = state.calculations.find(({ id }) => id === calculationId);
  if (calculation.status === TargetProductDataCalculationStatus.COMPLETE)
    dispatch(
      'toast/createToast',
      i18n.t('target_product_data_calculation_jobs.finished.toast'),
      { root: true }
    );
  else
    dispatch(
      'dialog/openDialog',
      [
        'AppMessageDialog',
        {
          type: 'alert',
          title: i18n.t(
            'target_product_data_calculation_jobs.errored.message_dialog.title'
          ),
          markdownText: i18n.t(
            'target_product_data_calculation_jobs.errored.message_dialog.content_html',
            formattedDateRange(calculation)
          ),
          width: DialogSize.SMALL
        }
      ],
      { root: true }
    );
  dispatch('removeCalculationJob', calculationId);
};

const actions = {
  createCalculationJob({ dispatch }, dateRange) {
    api
      .post('/target/product_data/calculations', dateRange)
      .then(({ data }) => dispatch('enqueueCalculationJob', data.calculation));
  },
  enqueueCalculationJobs({ state, dispatch }, calculations) {
    const calculationIds = calculations.map(({ id }) => id);
    const queuedCalculationIds = state.calculations.map(({ id }) => id);
    const deletedCalculationIds = queuedCalculationIds.filter(
      id => !calculationIds.includes(id)
    );
    deletedCalculationIds.forEach(id => dispatch('removeCalculationJob', id));

    calculations.forEach(calculation => {
      if (!queuedCalculationIds.includes(calculation.id))
        dispatch('enqueueCalculationJob', calculation);
    });
  },
  enqueueCalculationJob({ state, commit, dispatch }, calculation) {
    commit('SET_CALCULATION', calculation);
    dispatch(
      'multiAsyncJob/enqueueJob',
      {
        title: i18n.t(
          'target_product_data_calculation_jobs.title',
          formattedDateRange(calculation)
        ),
        id: jobId(calculation.id),
        type: JOB_TYPE,
        startedAt: calculation.created_at,
        totalCount: calculation.total_count,
        progressCount: calculation.progress_count,
        displayRunningTime: false,
        jobHandler: async ({ progressTo }) =>
          await new Promise(resolve =>
            checkProgress({
              progressTo,
              resolve,
              state,
              commit,
              dispatch,
              calculationId: calculation.id
            })
          ),
        resultHandler: checkResult
      },
      { root: true }
    );
  },
  removeCalculationJob({ commit }, calculationId) {
    commit('multiAsyncJob/REMOVE_JOB', jobId(calculationId), { root: true });
    commit('REMOVE_CALCULATION', calculationId);
  }
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  getters,
  actions
};
