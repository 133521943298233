import _ from 'lodash';
import { pathWithParams } from '@/lib/params';

export default {
  pathWithCurrentPathParams: ({ currentPathParams }) => path => {
    return _.reduce(
      currentPathParams,
      (p, v, k) => p.replace(`:${k}`, v),
      path
    );
  },
  pathWithDateRangeParams: ({ brandParams, dateRangeParams }) => path => {
    return pathWithParams(path, { ...brandParams, ...dateRangeParams });
  },
  pathWithBrandParams: ({ brandParams }) => (path, hash) => {
    return pathWithParams(path, brandParams, hash);
  },
  oldAdminUrl: (_state, getters) => path => {
    return getters.pathWithBrandParams(path);
  },
  advancedSettingsUrl: (_state, { oldAdminUrl }) =>
    oldAdminUrl('/brands/advanced_settings'),
  isSuperAdminAccessible(_state, _getters, rootState, rootGetters) {
    return (
      rootGetters['session/isCurrentUserAdmin'] &&
      rootState.editor.enableSuperAdminMode
    );
  },
  isWindows() {
    return ['Win32', 'Win64', 'Windows', 'WinCE'].includes(
      window.navigator.platform
    );
  }
};
