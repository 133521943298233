<template>
  <AppTabContent menu-item-id="review_move_shop_builder">
    <RouterView />
  </AppTabContent>
</template>

<script>
export default {
  name: 'ReviewMoveShopBuilder'
};
</script>
