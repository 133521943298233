<template>
  <Transition name="slide-up">
    <div v-if="!isInfoBoxHidden" class="AppInfoBox">
      <slot />
      <button
        v-if="hideType"
        v-tooltip="closeButtonTooltip"
        class="AppInfoBox__close-button"
        @click="hideInfoBox"
      >
        <AppSvgIcon name="icon-close-small" />
      </button>
    </div>
  </Transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'AppInfoBox',
  props: {
    hideType: {
      type: String,
      default: null,
      valiator: v => ['snooze', 'close', 'instant'].includes(v)
    },
    infoBoxId: { type: String, default: null },
    eventBus: { type: Object, default: null }
  },
  data: () => ({
    hiddenIds: JSON.parse(localStorage.getItem('hidden_infos')) || [],
    isHidden: false
  }),
  computed: {
    ...mapGetters('alert', ['isAlertSnoozed']),
    isInfoBoxHidden() {
      switch (this.hideType) {
        case 'snooze':
          return this.isAlertSnoozed(this.infoBoxId);
        case 'close':
        case 'instant':
          return this.isHidden;
        default:
          return false;
      }
    },
    closeButtonTooltip() {
      if (this.hideType === 'snooze') return this.$t('close_button_tooltip');
      else return null;
    }
  },
  watch: {
    hiddenIds() {
      localStorage.setItem('hidden_infos', JSON.stringify(this.hiddenIds));
    }
  },
  mounted() {
    if (!this.eventBus) return;

    this.eventBus.$on('reset-hidden', this.resetHidden);
  },
  methods: {
    ...mapActions('alert', ['snoozeAlert']),
    hideInfoBox() {
      if (this.hideType === 'snooze') this.snoozeAlert(this.infoBoxId);
      else if (this.hideType === 'close') this.hiddenIds.push(this.infoBoxId);
      else if (this.hideType === 'instant') this.isHidden = true;
    },
    resetHidden() {
      this.isHidden = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_transitions.scss';

.AppInfoBox {
  @include text-content;

  padding: 12px 16px;
  border-radius: 3px;
  text-align: center;
  background-color: $color-grey-15;
  position: relative;
}
.AppInfoBox__close-button {
  @include transition-basic(opacity);

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 44px;
  height: 44px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    opacity: 1;
  }

  90% {
    height: 44px;
    opacity: 0;
  }

  100% {
    opacity: 0;
    height: 0;
  }
}

.slide-up-leave-active {
  animation: slide-up $transition-duration-slow forwards;
}
</style>

<i18n locale="ko">
{
  "close_button_tooltip": "오늘 하루 보지 않기"
}
</i18n>
