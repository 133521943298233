<template>
  <div>
    <div v-for="datum of data" :key="datum.title" class="ui-section">
      <div>
        <label for="basic">{{ datum.title }}</label>
      </div>
      <TargetSettingsSection
        v-bind="datum"
        @open-settings="datum.openSettings"
        @open-preview="datum.openPreview"
      />
    </div>
  </div>
</template>

<script>
import TargetSettingsSection from '../target/campaigns/components/TargetSettingsSection';

export default {
  name: 'UiTargetSettingsSection',
  components: { TargetSettingsSection },
  computed: {
    data() {
      return [
        {
          title: 'Basic Usage'
        },
        {
          title: 'With Preview',
          withPreview: true
        }
      ].map(datum => ({
        ...datum,
        openSettings() {
          // eslint-disable-next-line no-console
          console.log('settings', datum.title);
        },
        openPreview() {
          // eslint-disable-next-line no-console
          console.log('preview', datum.title);
        }
      }));
    }
  }
};
</script>
