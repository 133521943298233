<template>
  <ul class="AppCheckboxDropdown__items">
    <li
      v-for="item in items.filter(item => !!item.label)"
      :key="item.id"
      class="AppCheckboxDropdown__item"
    >
      <AppCheckbox
        :disabled="item.disabled"
        :checked="item.checked"
        :label="item.label"
        @change="$emit('update', { id: item.id, checked: $event })"
      />
      <button
        v-if="item.button"
        class="AppCheckboxDropdown__button"
        @click="item.button.clickHandler"
      >
        <AppSvgIcon
          class="AppCheckboxDropdown__icon"
          :name="item.button.icon"
        />
        <span class="AppCheckboxDropdown__text">{{ item.button.label }}</span>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AppCheckboxDropdown',
  props: { items: { type: Array, required: true } }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.AppCheckboxDropdown__items {
  padding: 15px;
  min-width: 160px;
}

.AppCheckboxDropdown__item {
  height: 20px;
  white-space: nowrap;

  & + & {
    margin-top: 10px;
  }
}

.AppCheckboxDropdown__button {
  height: 18px;
  line-height: 18px;
  float: right;

  &:hover {
    text-decoration: underline;
  }
}

.AppCheckboxDropdown__icon {
  position: relative;
  top: 2px;
}

.AppCheckboxDropdown__text {
  @include text-caption-dark;
  margin-left: 5px;
}
</style>
