<template>
  <div class="TheEasyJoinError">
    <AppText :text="message" />
    <div class="TheEasyJoinError__actions">
      <AppButton
        type="external_link"
        :to="adminPageLink"
        :label="
          $t('actions.open_admin_page_label', {
            shop_builder: ShopBuilder.tk(shopBuilder)
          })
        "
      />
      <AppButton
        v-if="shopBuilder === 'cafe24'"
        :label="$t('actions.open_guide_label')"
        @click="openGuide"
      />
    </div>
    <AppButtonSubmit
      class="TheEasyJoinError__submit-button"
      :submit-label="$t('submit_label')"
      @click="$emit('submit')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ShopBuilder from '@/enums/ShopBuilder';
import EasyJoinErrorType from '@/enums/EasyJoinErrorType';

export default {
  name: 'TheEasyJoinError',
  props: {
    shopBuilder: { type: String, required: true },
    errorType: { type: Number, required: true }
  },
  data() {
    return {
      ShopBuilder: ShopBuilder
    };
  },
  computed: {
    message() {
      if (this.errorType === EasyJoinErrorType.STORE_NAME_MISSING)
        return this.$t('message.missing_info', {
          missing_info: this.$t('message.store_name')
        });
      else if (this.errorType === EasyJoinErrorType.STORE_URL_MISSING)
        return this.$t('message.missing_info', {
          missing_info: this.$t('message.store_url')
        });

      return '';
    },
    adminPageLink() {
      switch (this.shopBuilder) {
        case 'cafe24':
          return 'https://eclogin.cafe24.com/Shop/';
        case 'makeshop':
          return 'https://www.makeshop.co.kr/login/';
        case 'godo':
          return 'https://www.nhn-commerce.com/mygodo/myGodo_shopMain.php';
        case 'shopby':
          return 'https://admin.shopby.co.kr/pro/';
        case 'imweb':
          return 'https://imweb.me/mysite';
        default:
          return null;
      }
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openGuide() {
      this.openDialog('TheEasyJoinErrorCafe24Dialog');
    }
  }
};
</script>

<style lang="scss" scoped>
.TheEasyJoinError {
  text-align: center;
  margin-top: 100px;
}

.TheEasyJoinError__actions,
.TheEasyJoinError__submit-button {
  margin-top: 20px;
}
</style>

<i18n locale="ko">
{
  "message": {
    "missing_info": "계정 생성을 위한 쇼핑몰 필수정보를 호스팅사에서 불러올 수 없습니다.\n호스팅사에 아래 정보가 잘 입력되었는지 확인 후 계정 생성을 다시 시도해주세요.\n\n불러올 수 없는 정보 : {missing_info}",
    "store_name": "쇼핑몰명",
    "store_url": "쇼핑몰 주소"
  },
  "actions": {
    "open_admin_page_label": "{shop_builder} 관리자페이지 바로가기",
    "open_guide_label": "쇼핑몰 필수정보 가이드"
  },
  "submit_label": "크리마 계정 생성"
}
</i18n>
