var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalDefault',{attrs:{"title":_vm.$t('fit_size_csv_dialog.title'),"sub-title":_vm.$t('fit_size_csv_dialog.sub_title', { csv_id: _vm.csv.id }),"width":"middle"},on:{"close":_vm.close}},[_c('AppAjaxContent',{attrs:{"slot":"body","is-loading":_vm.isLoading},slot:"body"},[_c('AppContainer',[_c('AppDataList',{attrs:{"data":_vm.csvData}})],1),_c('div',{staticClass:"FitSizeCsvDialog__table-head"},[_c('AppSelectButton',{staticClass:"FitSizeCsvDialog__select-list-type",attrs:{"options":_vm.availableListTypes},on:{"change":_vm.searchResource},model:{value:(_vm.listType),callback:function ($$v) {_vm.listType=$$v},expression:"listType"}}),_c('AppButtonDownload',{staticClass:"FitSizeCsvDialog__button-download-failed-items",attrs:{"label":_vm.$t('local.button_download_failed_items'),"url":("/api/fit/csvs/download_failed_items.csv?id=" + (_vm.csv.id))},on:{"click":function($event){return _vm.downloadFailedItemsCsv(_vm.csv.id)}}})],1),(_vm.listType === 'success')?_c('AppResourceTable',{staticClass:"tab-small__content",attrs:{"table-id":"fit-csv-success-products-table","enable-total-count":false,"resources":_vm.successProducts,"columns":_vm.successProductsColumns,"rows":_vm.successProductsRows},on:{"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var value = ref.value;
return [(column === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('local.button_edit')},on:{"click":function($event){return _vm.openDialog(['FitSizeProductEditDialog', { product: value }])}}}),(value.fit_product)?_c('AppButton',{attrs:{"label":_vm.$t('local.button_preview')},on:{"click":function($event){return _vm.openDialog([
                'FitSizeProductPreviewDialog',
                {
                  name: value.name,
                  url: value.fit_product.url
                }
              ])}}}):_vm._e()]:_vm._e()]}}],null,false,2905719065)}):_c('AppResourceTable',{staticClass:"tab-small__content",attrs:{"table-id":"fit-csv-error-products-table","enable-total-count":false,"resources":_vm.errorProducts,"columns":_vm.errorProductsColumns,"rows":_vm.errorProductsRows},on:{"paginate":_vm.paginate}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }