<template>
  <AppSummaryBox class="SummaryBoxDataSyncCheck">
    <template #title>{{ $t('title') }}</template>
    <div>{{ $t('content') }}</div>
    <template #buttons>
      <AppButton
        type="link"
        button-style="blue-outline"
        :label="$t('button_label')"
        :to="reviewsListLink"
      />
    </template>
  </AppSummaryBox>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'SummaryBoxDataSyncCheck',
  computed: {
    ...mapState('session', ['currentBrand']),
    reviewsListLink() {
      return `/review/reviews?brand_id=${this.currentBrand.id}`;
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "데이터 동기화가 잘 되었는지 확인해주세요 💡",
  "content": "[리뷰 목록 확인] 버튼을 통해 쇼핑몰의 기존 리뷰(리뷰 개수, 포토 리뷰 이미지, 댓글 등)가 크리마에 잘 동기화되었는지 확인해주세요.",
  "button_label": "리뷰 목록 확인"
}
</i18n>
