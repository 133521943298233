export default [
  {
    id: 'analytics_insight',
    type: 'sub_menu',
    path: '/analytics/insight_dashboard',
    parentId: 'analytics',
    isAccessible: getters => getters.isInsightAccessible
  },
  {
    id: 'analytics_insight_dashboard',
    type: 'sub_menu',
    path: '/analytics/insight_dashboard',
    parentId: 'analytics_insight',
    isAccessible: getters => getters.isInsightAccessible
  },
  {
    id: 'analytics_insight_keywords',
    type: 'sub_menu',
    path: '/analytics/insight_keywords',
    parentId: 'analytics_insight',
    isAccessible: getters => getters.isInsightAccessible
  },
  {
    id: 'analytics_insight_products',
    type: 'sub_menu',
    path: '/analytics/insight_products/popular',
    parentId: 'analytics_insight',
    isAccessible: getters => getters.isInsightAccessible
  },
  {
    id: 'analytics_insight_products_popular',
    type: 'tab',
    path: '/analytics/insight_products/popular',
    parentId: 'analytics_insight_products',
    isAccessible: getters => getters.isInsightAccessible
  },
  {
    id: 'analytics_insight_products_risky',
    type: 'tab',
    path: '/analytics/insight_products/risky',
    parentId: 'analytics_insight_products',
    isAccessible: getters => getters.isInsightAccessible
  },
  {
    id: 'analytics_insight_products_potential',
    type: 'tab',
    path: '/analytics/insight_products/potential',
    parentId: 'analytics_insight_products',
    isAccessible: getters => getters.isInsightAccessible
  },
  {
    id: 'analytics_insight_settings',
    type: 'sub_menu',
    path: '/analytics/settings',
    parentId: 'analytics_insight',
    superAdmin: true,
    isAccessible: getters => getters.isInsightAccessible
  },
  {
    id: 'analytics_statistics',
    type: 'sub_menu',
    path: '/analytics/users',
    parentId: 'analytics'
  },
  {
    id: 'analytics_users',
    type: 'sub_menu',
    path: '/analytics/users',
    parentId: 'analytics_statistics'
  },
  {
    id: 'analytics_products',
    type: 'sub_menu',
    path: '/analytics/products',
    parentId: 'analytics_statistics',
    isAccessible: getters => getters.isReviewOptionV2Enabled
  },
  {
    id: 'analytics_insight_lab',
    type: 'sub_menu',
    path: getters =>
      getters.isBrandSettingsHavingReviewKeywordsBiUrl
        ? '/analytics/review_keywords'
        : '/analytics/review_statistics',
    parentId: 'analytics',
    isAccessible: getters => getters.isAnalyticsInsightLabAccessible
  },
  {
    id: 'analytics_review_statistics',
    type: 'sub_menu',
    path: '/analytics/review_statistics',
    parentId: 'analytics_insight_lab',
    isAccessible: getters => getters.isBrandSettingsHavingReviewStatisticsBiUrl
  },
  {
    id: 'analytics_review_keywords',
    type: 'sub_menu',
    path: '/analytics/review_keywords',
    parentId: 'analytics_insight_lab',
    isAccessible: getters => getters.isBrandSettingsHavingReviewKeywordsBiUrl
  },
  {
    id: 'analytics_ab_tests',
    type: 'sub_menu',
    path: '/analytics/ab_tests/ab_tests',
    parentId: 'analytics',
    superAdmin: true
  },
  {
    id: 'analytics_ab_tests_ab_tests',
    type: 'sub_menu',
    path: '/analytics/ab_tests/ab_tests',
    parentId: 'analytics_ab_tests',
    superAdmin: true
  },
  {
    id: 'analytics_events',
    type: 'sub_menu',
    path: '/analytics/events',
    parentId: 'analytics',
    superAdmin: true
  },
  {
    id: 'insight_intro',
    type: 'sub_menu',
    path: '/analytics/insight_intro',
    parentId: 'analytics',
    group: 1,
    isAccessible: getters => getters.isInsightAccessible
  }
].map(item => {
  if (item.id.includes('insight') && item.id !== 'analytics_insight_lab')
    return {
      ...item,
      isLaunched: getters => getters.isInsightMenuAccessible
    };
  else return item;
});
