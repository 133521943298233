<template>
  <div class="WidgetCard">
    <div class="WidgetCard__image-box">
      <AppImage class="WidgetCard__image" :src="imgUrl" />
    </div>
    <div class="WidgetCard__description">
      <div class="WidgetCard__description-title">
        <div class="WidgetCard__description-title-text">
          {{ title }}
        </div>
        <AppBadge v-if="isBest" label="Best" badge-style="lavender" />
      </div>
      <div class="WidgetCard__description-content">
        {{ content }}
      </div>
      <div class="WidgetCard__description-actions">
        <AppButton
          :label="$t('preview_label')"
          type="external_link"
          :to="previewUrl"
        />
        <AppButton
          button-style="blue-outline"
          :label="$t('apply_install_label')"
          @click="$emit('apply')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WidgetCombinationType from '@/enums/WidgetCombinationType';
import WidgetPageType from '@/enums/WidgetPageType';

export default {
  name: 'WidgetCard',
  props: {
    combinationType: { type: Number, required: true },
    pageType: { type: Number, default: null },
    isSingleWidget: { type: Boolean, default: false }
  },
  computed: {
    title() {
      return WidgetCombinationType.t(this.combinationType);
    },
    combinationTypeToKey() {
      return WidgetCombinationType.toKey(this.combinationType).toLowerCase();
    },
    content() {
      return this.isSingleWidget
        ? this.$t(`${this.combinationTypeToKey}.content`)
        : null;
    },
    imgUrl() {
      return this.$t(`${this.combinationTypeToKey}.img_url`);
    },
    previewUrl() {
      return this.isSingleWidget
        ? `https://sample.cre.ma/v2/review/widget/${this.combinationTypeToKey}`
        : `https://sample.cre.ma/v2/review/recommendation/${WidgetPageType.toKey(
            this.pageType
          ).toLowerCase()}/${this.combinationType}`;
    },
    isBest() {
      return [
        WidgetCombinationType.LIST,
        WidgetCombinationType.PHOTO_THUMBNAIL,
        WidgetCombinationType.HORIZONTAL_SCROLL,
        WidgetCombinationType.EXTERNAL
      ].includes(this.combinationType);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.WidgetCard {
  width: 438px;
  overflow: hidden;
  border: 1px solid $color-grey-25;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.WidgetCard__image-box {
  display: flex;
  justify-content: center;
  height: 220px;
  padding-top: 20px;
  background-color: $color-brand-lighter;
}

.WidgetCard__image {
  width: 268px;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
}

.WidgetCard__description-title {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
}

.WidgetCard__description-title-text {
  @include text-sub-title;
}

.WidgetCard__description {
  padding: 20px 16px;
}

.WidgetCard__description-content {
  @include text-content;
}

.WidgetCard__description-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
</style>

<i18n locale="ko">
{
  "preview_label": "위젯 미리보기",
  "apply_install_label": "설치 신청",
  "list": {
    "content": "기본 위젯으로 누구에게나 익숙하고 인지도가 높은 디자인입니다.",
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "board": {
    "content": "전체 리뷰 정보를 요약해서 보여줄 수 있습니다.",
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "photo_thumbnail": {
    "content": "상세 페이지 상단에 고객의 리뷰를 썸네일로 노출해보세요.",
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "horizontal_scroll":{
    "content": "사진/동영상 리뷰를 좁은 공간에서 효과적으로 노출할 수 있습니다.",
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "external":{
    "content": "SNS에 등록된 리뷰를 모아서 노출할 수 있습니다.",
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "gallery":{
    "content": "사진/동영상 리뷰를 더욱 강조해서 보여줄 수 있습니다.",
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "square_gallery":{
    "content": "사진/동영상 리뷰를 모아 SNS 피드 느낌을 낼 수 있습니다.",
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "best_gallery":{
    "content": "베스트 리뷰어 선정 시 적극적으로 활용할 수 있습니다.",
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "products":{
    "content": "상품 리스트 화면에서 리뷰를 효과적으로 모아 노출해보세요.",
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "photo_thumbnail_and_list": {
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "horizontal_scroll_and_list": {
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "gallery_and_list": {
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "external_and_list": {
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "photo_thumbnail_and_gallery_and_list": {
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "photo_thumbnail_and_horizontal_scroll_and_list": {
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "photo_thumbnail_and_external_and_detachable_board_and_list": {
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "best_gallery_and_gallery_and_list": {
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  },
  "best_gallery_and_products": {
    "img_url": "https://assets.cre.ma/m/admin/v2/product_detail_default@3x.png"
  }
}
</i18n>
