import Scrollable from '@/mixins/Scrollable';
import Highlightable from '@/mixins/Highlightable';

export default {
  mixins: [Scrollable, Highlightable],
  methods: {
    focusOnElement(element, args) {
      this.scrollToElement(element, args);
      this.highlightElement(element);
    }
  }
};
