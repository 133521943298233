import Vue from 'vue';

const state = { drawers: {} };

const mutations = {
  OPEN_DRAWER(state, { drawerId, drawerProps }) {
    Vue.set(state.drawers, drawerId, drawerProps);
  },
  CLOSE_DRAWER(state, drawerId) {
    Vue.delete(state.drawers, drawerId);
  }
};

const actions = {
  openDrawer({ commit }, args) {
    const [drawerId, drawerProps] =
      typeof args === 'string' ? [args, {}] : [args[0], { ...args[1] }];

    drawerProps.eventBus = new Vue();
    commit('OPEN_DRAWER', { drawerId, drawerProps });

    return new Promise(resolve => resolve(drawerProps.eventBus));
  },
  closeDrawer({ commit }, drawerId) {
    commit('CLOSE_DRAWER', drawerId);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
