<template>
  <div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="ThePaymentDialogCreditPackagesTable__description"
      v-html="
        $t('description_html', {
          sms_price: formatCurrency(priceAttributes.sms_price),
          lms_price: formatCurrency(priceAttributes.lms_price),
          biz_message_price: formatCurrency(priceAttributes.biz_message_price)
        })
      "
    />
    <!-- eslint-enable vue/no-v-html -->
    <ThePaymentDialogPackagesTable
      :ids="creditPackageIds"
      :packages="creditPackages"
      :name="name"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
import ThePaymentDialogPackagesTable from './ThePaymentDialogPackagesTable';

export default {
  name: 'ThePaymentDialogCreditPackagesTable',
  components: { ThePaymentDialogPackagesTable },
  props: {
    creditPackageIds: { type: Array, required: true },
    name: { type: String, required: true },
    creditPackages: { type: Array, required: true },
    priceAttributes: { type: Object, required: true }
  }
};
</script>

<style scoped>
.ThePaymentDialogCreditPackagesTable__description {
  margin-bottom: 16px;
}
</style>

<i18n locale="ko">
{
  "description_html": "포인트가 모두 소진된 경우 캐시를 사용하여 메세지를 발송하게 됩니다.<br />SMS: %{sms_price}, LMS: {lms_price}, 알림톡: %{biz_message_price}"
}
</i18n>
