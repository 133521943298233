<template>
  <div :class="['AppTableTopbar', { 'AppTableTopbar--in-layer': isInLayer }]">
    <template>
      <div class="AppTableTopbar__left">
        <span v-if="tableName" class="AppTableTopbar__table-name">{{
          tableName
        }}</span>
        <span v-if="tooltip" v-tooltip="tooltip" class="AppTableTopbar__icon">
          <AppSvgIcon name="icon-info-tooltip" />
        </span>
      </div>
      <div class="AppTableTopbar__right">
        <AppButton
          v-for="(button, i) in customButtons"
          :key="i"
          :label="button.label"
          :button-style="button.buttonStyle"
          @click="click(button)"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AppTableTopbar',
  props: {
    tableName: { type: String, required: true },
    customButtons: { type: Array, required: true },
    isInLayer: { type: Boolean, required: true },
    tooltip: { type: [String, Object], default: null }
  },
  methods: {
    click(button) {
      const { clickHandler } = button;
      if (clickHandler) clickHandler();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_clearfix.scss';
@import '@/scss/mixins/_breakpoints.scss';

.AppTableTopbar {
  @include clearfix;
  padding: 8px 0;
  line-height: 34px;
  background: white;

  @include main-padding-horizontal;
  @include main-negative-margin-horizontal;

  &--in-layer {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.AppTableTopbar__left {
  float: left;
}

.AppTableTopbar__right {
  float: right;
}

.AppTableTopbar__table-name {
  @include text-sub-title;

  padding-right: 8px;
}

.AppTableTopbar__icon {
  position: relative;
  top: 2px;
  margin-left: 4px;
}
</style>
