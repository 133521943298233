import _ from 'lodash';
import moment from 'moment';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { trimParams } from '@/lib/params';
import api from '@/lib/api';

export default {
  data() {
    return {
      resourceParams: {},
      keywordsCount: 0
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('competingGroup', ['turnoverTypeValue']),
    ...mapState('brandCategory', ['all_brand_categories']),
    ...mapGetters('session', ['isCurrentUserAdmin']),
    ...mapGetters('brandCategory', [
      'brandsProductCategories',
      'brandsGenderCategories',
      'brandsAgeCategories',
      'brandsStyleCategories',
      'myBrandProductCategories',
      'myBrandGenderCategories',
      'myBrandAgeCategories',
      'myBrandStyleCategories'
    ]),
    defaultResourceCommonParams() {
      return {
        start_date: this.defaultStartDate,
        end_date: this.defaultEndDate,
        brand_ids: this.brandIds,
        product_category_ids: this.productCategoryIds,
        gender_category_ids: this.genderCategoryIds,
        age_category_ids: this.ageCategoryIds,
        style_category_ids: this.styleCategoryIds,
        turnover_type: this.turnoverType
      };
    },
    defaultStartDate() {
      return moment()
        .subtract(1, 'year')
        .toVal();
    },
    defaultEndDate() {
      return moment()
        .subtract(1, 'day')
        .toVal();
    },
    dateRange() {
      return _.pick(this.resourceParams, ['start_date', 'end_date']);
    },
    searchBarProps() {
      return {
        startDate: this.resourceParams.start_date,
        endDate: this.resourceParams.end_date,
        brandIds: this.resourceParams.brand_ids,
        productCategoryIds: this.resourceParams.product_category_ids,
        genderCategoryIds: this.resourceParams.gender_category_ids,
        ageCategoryIds: this.resourceParams.age_category_ids,
        styleCategoryIds: this.resourceParams.style_category_ids,
        turnoverType: this.resourceParams.turnover_type
      };
    },
    searchBarEvents() {
      return {
        'change-date-range': this.changeDateRange,
        'change-competing-group': this.changeCompetingGroup,
        'change-competing-brands': this.changeCompetingBrands
      };
    },
    brandIds() {
      if (!this.isCurrentUserAdmin) return [];

      const ids = this.getLocalStorageItem('brand_ids');
      return ids?.split(',').map(Number) || [];
    },
    productCategoryIds() {
      return this.categoryIds(
        this.myBrandProductCategories,
        this.brandsProductCategories,
        this.getLocalStorageItem('product_category_ids')
      );
    },
    genderCategoryIds() {
      return this.categoryIds(
        this.myBrandGenderCategories,
        this.brandsGenderCategories,
        this.getLocalStorageItem('gender_category_ids')
      );
    },
    ageCategoryIds() {
      return this.categoryIds(
        this.myBrandAgeCategories,
        this.brandsAgeCategories,
        this.getLocalStorageItem('age_category_ids')
      );
    },
    styleCategoryIds() {
      return this.categoryIds(
        this.myBrandStyleCategories,
        this.brandsStyleCategories,
        this.getLocalStorageItem('style_category_ids')
      );
    },
    turnoverType() {
      return (
        this.getLocalStorageItem('turnover_type') || this.turnoverTypeValue
      );
    },
    categoriesCount() {
      if (this.resourceParams.brand_ids?.length) return 0;

      return _.sum(
        ['product', 'gender', 'age', 'style'].map(str => {
          const key = `${str}_category_ids`;
          if (!this.resourceParams[key]) return 0;

          if (this.resourceParams[key] === 'all') {
            return this[`brands${_.capitalize(str)}Categories`].filter(
              c => c.brands_count
            ).length;
          } else {
            return this.resourceParams[key].split(',').length;
          }
        })
      );
    }
  },
  mounted() {
    this.mountedAction();
  },
  watch: {
    $route(to) {
      const newParams = trimParams(to.query, this.defaultResourceParams);
      if (!_.isEqual(newParams, to.query)) {
        this.$router.replace({ query: newParams });
        return;
      }
      this.resourceParams = { ...this.defaultResourceParams, ...to.query };
      this.fetchResource(this.resourceParams);
    }
  },
  methods: {
    ...mapMutations(['SET_DATE_RANGE_PARAMS']),
    ...mapMutations('brandCategory', ['SET_ALL_BRAND_CATEGORIES']),
    ...mapMutations('competingGroup', [
      'SET_FILTERED_BRANDS_COUNT',
      'SET_TURNOVER_TYPE_VALUE',
      'SET_TURNOVER_TYPE_COUNT_MAP'
    ]),
    mountedAction() {
      api.get('/analytics/brand_categories').then(({ data }) => {
        this.SET_ALL_BRAND_CATEGORIES(
          this.all_brand_categories.map(category => ({
            ...category,
            brands_count: data.brand_category_id_to_count_map[category.id]
          }))
        );
        this.SET_TURNOVER_TYPE_VALUE(String(data.turnover_type));
        this.SET_TURNOVER_TYPE_COUNT_MAP(data.turnover_type_count_map);

        this.resourceParams = {
          ...this.defaultResourceParams,
          ...this.$route.query
        };
        this.SET_DATE_RANGE_PARAMS(this.resourceParams);
        this.fetchResource(this.resourceParams);
      });
    },
    refresh() {
      this.isLoading = true;
      this.mountedAction();
    },
    refreshResourceWithParams(params) {
      const oldParams = this.$route.query;
      const newParams = trimParams(
        { ...this.$store.state.brandParams, ...this.resourceParams, ...params },
        this.defaultResourceParams
      );
      if (_.isEqual(oldParams, newParams)) this.fetchResource(params);
      else this.$router.push({ query: newParams });
    },
    changeDateRange(dateRange) {
      if (
        dateRange.start_date === this.resourceParams.start_date &&
        dateRange.end_date === this.resourceParams.end_date
      )
        return;

      this.SET_DATE_RANGE_PARAMS(dateRange);
      this.refreshResourceWithParams(dateRange);
    },
    changeCompetingGroup(params) {
      this.saveParamsToLocalStorage(params);
      this.refreshResourceWithParams(params);
    },
    changeCompetingBrands(params) {
      this.saveParamsToLocalStorage(params);
      this.refreshResourceWithParams(params);
    },
    saveParamsToLocalStorage(params) {
      _.each(params, (value, key) => {
        this.setLocalStorageItem(key, value);
      });
    },
    pickByKeys(object) {
      return _.pickBy(
        object,
        (_v, k) =>
          this.currentBrand.is_fashion_mall ||
          (!k.includes('gender') && !k.includes('age') && !k.includes('style'))
      );
    },
    categoryIds(myBrandCategories, brandsCategories, localStorageItem) {
      const myBrandCategoryIds = myBrandCategories.map(c => c.id);
      const brandsCategoryIds = brandsCategories.map(c => c.id);
      const localStorageIds = localStorageItem?.split(',').map(Number) || [];

      if (localStorageItem === 'all') return 'all';
      else if (_.intersection(localStorageIds, brandsCategoryIds).length)
        return _.intersection(localStorageIds, brandsCategoryIds).join(',');
      else if (myBrandCategoryIds.length) return myBrandCategoryIds.join(',');
      else return 'all';
    },
    setLocalStorageItem(key, value) {
      localStorage.setItem(`brand_${this.currentBrand.id}-${key}`, value);
    },
    getLocalStorageItem(key) {
      return localStorage.getItem(`brand_${this.currentBrand.id}-${key}`);
    }
  }
};
