<template>
  <TheLoginView :message="$t('message')" :sub-message="$t('sub_message')">
    <AppForm
      id="user"
      v-bind="formProps"
      object-id="user"
      focus-group-id="current_password"
      form-style="wide"
      validate-only-on-submit
      no-screen
      :submit-button="{
        submitLabel: $t('submit'),
        submittingLabel: $t('submitting')
      }"
      v-on="formEvents"
      @change-group="changeGroup"
    >
      <input
        v-show="false"
        type="email"
        :value="currentUser.email"
        autocomplete="username"
      />
    </AppForm>
    <AppButton
      v-if="!currentUser.under_strong_security_policy"
      class="ThePasswordReset__skip-reset-password-button"
      size="large"
      :label="$t('skip_reset_password')"
      @click="skipResetPassword"
    />
  </TheLoginView>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormView from '@/mixins/FormView';
import PasswordField from '@/mixins/PasswordField';
import HttpStatus from '@/enums/HttpStatus';
import TheLoginView from './TheLoginView';

export default {
  name: 'ThePasswordReset',
  components: { TheLoginView },
  mixins: [FormView, PasswordField],
  data() {
    return {
      formObject: { current_password: '' },
      detectFormDataChange: false
    };
  },
  computed: {
    ...mapState('session', ['currentUser']),
    formSections() {
      return [
        {
          groups: [
            this.currentPasswordGroup(true),
            this.newPasswordGroup(true),
            this.newPasswordRequirementsGroup
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions('session', ['skipResetPassword', 'updatePassword']),
    ...mapActions('toast', ['createToast']),
    submit(formData) {
      this.isSubmitting = true;
      this.updatePassword(formData)
        .catch(error => {
          if (error.response.status === HttpStatus.UNAUTHORIZED)
            this.setCurrentPasswordInvalid();
          else error.errorHandler();
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style scoped>
.ThePasswordReset__skip-reset-password-button {
  margin-top: 16px;
}
</style>

<i18n locale="ko">
{
  "message": "소중한 개인정보를 안전하게 보호하세요.",
  "sub_message": "안전한 개인정보 보호를 위해 3개월마다\n비밀번호 변경을 안내해드립니다.",
  "submit": "비밀번호 변경",
  "submitting": "비밀번호 변경 중..",
  "skip_reset_password": "3개월 뒤에 변경"
}
</i18n>
