var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AppSelectFilterable dropdown",style:({ width: _vm.width })},[_c('AppSelect',_vm._b({class:[
      'AppSelectFilterable__select',
      {
        'AppSelectFilterable__select--invalid': _vm.invalid
      }
    ],attrs:{"is-focused":_vm.isShowingDropdown,"on-demand-options":_vm.onDemandOptions},on:{"change":function($event){return _vm.$emit('change', $event)}},nativeOn:{"mousedown":function($event){$event.preventDefault();},"click":function($event){return _vm.click.apply(null, arguments)}}},'AppSelect',{
      id: _vm.id,
      name: _vm.name,
      value: _vm.value,
      disabled: _vm.disabled,
      options: _vm.options,
      placeholder: _vm.placeholder,
      eventBus: _vm.eventBus,
      invalid: _vm.invalid
    },false)),(_vm.isShowingDropdown)?_c('AppModeless',{staticClass:"dropdown__dialog AppSelectFilterable__dialog",on:{"close":_vm.closeDropdown}},[_c('AppSelectSearchDropdown',{attrs:{"data":_vm.optionsWithSelect,"dropdown-props":_vm.dropdownProps,"is-showing-dropdown":_vm.isShowingDropdown,"on-demand-options":_vm.onDemandOptions},on:{"select":_vm.select,"close":_vm.closeDropdown}})],1):_vm._e(),(_vm.width === null)?_c('AppSelectSearchDropdown',{staticClass:"AppSelectFilterable__size-holder",attrs:{"data":_vm.optionsWithSelect,"on-demand-options":_vm.onDemandOptions}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }