<template>
  <AppModalDefault :title="$t('title')" :width="width" @close="close">
    <template #body>
      <AppImage :src="previewUrl" />
    </template>
    <template #foot>
      <AppButton :label="$t('close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'RequestInstallCountAndScoreWidgetPreviewDialog',
  mixins: [DialogView],
  props: {
    device: { type: String, default: 'mobile' },
    placementSelectableType: { type: String, default: '' }
  },
  computed: {
    width() {
      return this.device === 'mobile' ? 'small' : 'wide';
    },
    previewUrl() {
      return `https://assets.cre.ma/m/admin/v2/count_and_score_preview_${this.placementSelectableType}_${this.device}@2x.png`;
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "설치 위치 안내",
  "close": "닫기"
}
</i18n>
