<template>
  <AppModal skip-backdrop width="440px">
    <template #head>
      <div class="FreeTrialConfirmDialog__head">
        <div class="FreeTrialConfirmDialog__title">
          {{ $t('title') }}
        </div>
      </div>
    </template>
    <template #body>
      <div class="FreeTrialConfirmDialog__body">
        <AppMarkdown
          class="FreeTrialConfirmDialog__text-viewer"
          :text="$t('content')"
        />
      </div>
    </template>
    <template #foot>
      <div class="FreeTrialConfirmDialog__foot">
        <div class="FreeTrialConfirmDialog__checkbox-container">
          <AppCheckbox v-model="syncChecked" :label="$t('sync_check_label')" />
          <AppCheckbox
            v-model="cautionChecked"
            :label="$t('caution_check_label')"
          />
        </div>
        <div class="FreeTrialConfirmDialog__check-confirm-buttons">
          <AppButton
            :button-style="'blue'"
            :label="$t('button_label')"
            :disabled="!enableConfirmButton"
            @click="onClickConfirm"
          />
          <AppButton :label="$t('app.cancel')" @click="close" />
        </div>
      </div>
    </template>
  </AppModal>
</template>

<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'FreeTrialConfirmDialog',
  mixins: [DialogView],
  data() {
    return {
      syncChecked: false,
      cautionChecked: false
    };
  },
  computed: {
    enableConfirmButton() {
      return this.syncChecked && this.cautionChecked;
    }
  },
  methods: {
    onClickConfirm() {
      this.emit('confirm');
      this.close();
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/mixins/_texts.scss';

.FreeTrialConfirmDialog__head {
  padding: 18px 10px 21px 23px;
  min-width: 0;
}

.FreeTrialConfirmDialog__title {
  @include text-sub-title;
}

.FreeTrialConfirmDialog__body {
  padding: 0 23px 4px 23px;
}

.FreeTrialConfirmDialog__text-viewer {
  color: $color-content-text;
}

.FreeTrialConfirmDialog__check-confirm-buttons {
  margin-top: 24px;
}

.FreeTrialConfirmDialog__foot {
  padding: 16px 23px 15px 23px;
}

.FreeTrialConfirmDialog__checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>

<i18n locale="ko">
  {
    "title": "30일 무료체험을 시작하시겠습니까?",
    "content": "무료체험 사용 시작 시 이전 단계로 돌아갈 수 없습니다. <br />무료 체험 시작 즉시 크리마 리뷰 위젯 노출 및 동작을 시작합니다.",
    "button_label": "사용 시작",
    "sync_check_label": "쇼핑몰의 기존 리뷰가 잘 동기화 되었는지 확인했습니다.",
    "caution_check_label": "주의 사항 확인 후 사용 시작합니다."
  }
</i18n>
