import MENU_ITEMS_MAIN from './MENU_ITEMS_MAIN';
import MENU_ITEMS_REVIEW from './MENU_ITEMS_REVIEW';
import MENU_ITEMS_FIT from './MENU_ITEMS_FIT';
import MENU_ITEMS_DISPLAY from './MENU_ITEMS_DISPLAY';
import MENU_ITEMS_CHANNEL from './MENU_ITEMS_CHANNEL';
import MENU_ITEMS_TARGET from './MENU_ITEMS_TARGET';
import MENU_ITEMS_PRODUCT from './MENU_ITEMS_PRODUCT';
import MENU_ITEMS_ANALYTICS from './MENU_ITEMS_ANALYTICS';
import MENU_ITEMS_CONNECT from './MENU_ITEMS_CONNECT';
import MENU_ITEMS_UI from './MENU_ITEMS_UI';
import MENU_ITEMS_QA from './MENU_ITEMS_QA';
import MENU_ITEMS_DIALOG from './MENU_ITEMS_DIALOG';

import TABS_REVIEW from './TABS_REVIEW';

export default [
  ...MENU_ITEMS_MAIN,
  ...MENU_ITEMS_REVIEW,
  ...MENU_ITEMS_FIT,
  ...MENU_ITEMS_DISPLAY,
  ...MENU_ITEMS_CHANNEL,
  ...MENU_ITEMS_TARGET,
  ...MENU_ITEMS_PRODUCT,
  ...MENU_ITEMS_ANALYTICS,
  ...MENU_ITEMS_CONNECT,
  ...MENU_ITEMS_UI,
  ...MENU_ITEMS_QA,
  ...MENU_ITEMS_DIALOG,
  ...TABS_REVIEW
];
