<template>
  <AppModalDefault
    :title="$t('menu.admin_audit_logs_dialog')"
    width="wide"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppSearchbar @submit="searchResource">
          <template #right>
            <li>
              <AppSelect
                v-model="resourceParams.action_type"
                :options="AdminActionType.options()"
                :placeholder="$t('all_action_type')"
                @change="searchResource"
              />
            </li>
            <li>
              <AppSearchWithType
                v-model="searchWithType"
                :search-types="SEARCH_TYPES"
              />
            </li>
            <li>
              <AppDateRangePicker
                v-model="resourceParams"
                :min-date="minDate"
                @update="searchResource"
              />
            </li>
          </template>
        </AppSearchbar>
        <AppInfoBox>
          {{ $t('info_box') }}
        </AppInfoBox>
        <AppResourceTable
          table-id="logs-table"
          :columns="columns"
          :resources="logs"
          :rows="rows"
          @paginate="paginate"
        >
          <template #cell="{row, column}">
            <template v-if="column === 'success'">
              <AppBadge v-bind="logBadge(row.success)" />
            </template>
          </template>
        </AppResourceTable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import moment from 'moment';
import { nullResources } from '@/lib/resources';
import DialogView from '@/mixins/DialogView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import AdminActionType from '@/enums/AdminActionType';

export default {
  name: 'TheAdminAuditLogsDialog',
  mixins: [DialogView, ResourceViewNoRoute],
  data() {
    return {
      isLoading: true,
      logs: nullResources,
      minDate: moment()
        .subtract(6, 'month')
        .add(1, 'day')
        .toDate()
        .toISOString(),
      SEARCH_TYPES: ['actor_email', 'actor_ip'].map(value => ({
        value,
        label: this.$t(`search_type.${value}`)
      }))
    };
  },
  computed: {
    AdminActionType: () => AdminActionType,
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      search_type: 'actor_email'
    }),
    columns() {
      return [
        { id: 'date', label: this.$t('date') },
        { id: 'actor', type: 'html', label: this.$t('actor') },
        { id: 'action_type', label: this.$t('action_type') },
        { id: 'success', label: this.$t('success.title') },
        {
          id: 'description',
          type: 'pre',
          label: this.$t('description'),
          align: 'left'
        }
      ];
    },
    rows() {
      return this.logs.items.map(log => {
        return {
          id: log.id,
          date: this.formatDateTime(log.created_at),
          actor: this.$t('actor_content', {
            actor_email: log.email,
            actor_ip: log.ip
          }),
          action_type: AdminActionType.t(log.action_type),
          success: log.success,
          description: log.description
        };
      });
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    }
  },
  methods: {
    logBadge(success) {
      return {
        label: this.$t(`success.${success}`),
        badgeStyle: success ? 'blue-outline' : 'red-outline'
      };
    },
    fetchResource(params) {
      this.isLoading = true;
      const type = 'admin_audit';
      api
        .get('/logs', { params: { ...params, type } })
        .then(({ data }) => (this.logs = data.logs))
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .logs-table__date {
    flex-basis: 155px;
  }
  .logs-table__actor {
    flex-basis: 170px;
  }
  .logs-table__action-type {
    flex-basis: 100px;
  }
  .logs-table__success {
    flex-basis: 100px;
  }
  .logs-table__description {
    flex-basis: 250px;
  }
}
</style>

<i18n locale="ko">
  {
    "search_type": {
      "actor_email": "관리자 ID",
      "actor_ip": "관리자 IP"
    },
    "date": "날짜",
    "actor": "관리자",
    "actor_content": "%{actor_email}<br />%{actor_ip}",
    "all_action_type": "전체 로그 유형",
    "action_type": "로그 유형",
    "success": {
      "title": "성공 여부",
      "true": "성공",
      "false": "실패"
    },
    "description": "상세 내용",
    "info_box": "감사 로그는 최근 6개월 간의 내역만 제공합니다."
  }
  </i18n>
