<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="campaign.name"
    :is-loading="isLoading"
    :form-props="{ ...formProps, objectId: 'campaign', disabled }"
    v-on="formEvents"
  >
    <template #group="{ id, disabled, errors }">
      <template v-if="id === 'dispatch_sample_enable_sms'">
        <div class="AppForm__group-field">
          <AppTags
            v-model="formObject.dispatch_sample_recipient_phones"
            name="campaign[dispatch_sample_recipient_phones][]"
            :disabled="disabled"
            :placeholder="$t('dispatch_sample_recipient_phones_placeholder')"
            :search-placeholder="
              $t('dispatch_sample_recipient_phones_placeholder')
            "
            :search-no-results="''"
            :invalid="!!errors.dispatch_sample_recipient_phones"
            @change="validateField('dispatch_sample_recipient_phones')"
          />
          <AppFormError :error="errors.dispatch_sample_recipient_phones" />
        </div>
      </template>
      <template v-else-if="id === 'dispatch_sample_enable_email'">
        <div class="AppForm__group-field">
          <AppTags
            v-model="formObject.dispatch_sample_recipient_emails"
            name="campaign[dispatch_sample_recipient_emails][]"
            :disabled="disabled"
            :placeholder="$t('dispatch_sample_recipient_emails_placeholder')"
            :search-placeholder="
              $t('dispatch_sample_recipient_emails_placeholder')
            "
            :search-no-results="''"
            :invalid="!!errors.dispatch_sample_recipient_emails"
            @change="validateField('dispatch_sample_recipient_emails')"
          />
          <AppFormError :error="errors.dispatch_sample_recipient_emails" />
        </div>
      </template>
      <template v-else-if="id === 'dispatch_sample_now'">
        <div class="AppForm__group-field">
          <template v-if="supportsSms">
            <div
              class="TargetDmCampaignSettingsMiscDispatchSampleDialog__dispatch-sample-now-title"
            >
              {{ $t('app.sms') }}
            </div>
            <div>
              <AppTags
                v-model="dispatchNowPhones"
                class="TargetDmCampaignSettingsMiscDispatchSampleDialog__dispatch-sample-now-tags"
                :disabled="disabledDispatchNow"
                :placeholder="
                  $t('dispatch_sample_recipient_phones_placeholder')
                "
                :search-placeholder="
                  $t('dispatch_sample_recipient_phones_placeholder')
                "
                :search-no-results="''"
                :tooltip="disabledDispatchNowTooltip"
              />
              <AppButton
                class="TargetDmCampaignSettingsMiscDispatchSampleDialog__dispatch-sample-now-button"
                :label="$t('dispatch_sample_now_button')"
                :disabled="
                  disabledDispatchNow ||
                    !dispatchNowPhones.length ||
                    dispatchingBizMessage
                "
                :tooltip="disabledDispatchNowTooltip"
                @click="clickDispatchNowSms"
              />
            </div>
          </template>
          <template v-if="supportsEmail">
            <div
              class="TargetDmCampaignSettingsMiscDispatchSampleDialog__dispatch-sample-now-title"
            >
              {{ $t('app.email') }}
            </div>
            <div>
              <AppTags
                v-model="dispatchNowEmails"
                class="TargetDmCampaignSettingsMiscDispatchSampleDialog__dispatch-sample-now-tags"
                :disabled="disabledDispatchNow"
                :placeholder="
                  $t('dispatch_sample_recipient_emails_placeholder')
                "
                :search-placeholder="
                  $t('dispatch_sample_recipient_emails_placeholder')
                "
                :search-no-results="''"
                :tooltip="disabledDispatchNowTooltip"
              />
              <AppButton
                class="TargetDmCampaignSettingsMiscDispatchSampleDialog__dispatch-sample-now-button"
                :label="$t('dispatch_sample_now_button')"
                :disabled="disabledDispatchNow || !dispatchNowEmails.length"
                :tooltip="disabledDispatchNowTooltip"
                @click="clickDispatchNowEmail"
              />
            </div>
          </template>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import { isKoreanPhoneFormatValid } from '@/lib/phoneFormatValidator';
import { isEmailFormatValid } from '@/lib/emailFormatValidator';
import TargetCampaignStatus from '@/enums/TargetCampaignStatus';
import TargetDispatchMethod from '@/enums/TargetDispatchMethod';
import TargetMessageMedia from '@/enums/TargetMessageMedia';
import LunasoftBizMessageResultCode from '@/enums/LunasoftBizMessageResultCode';

export default {
  name: 'TargetDmCampaignSettingsMiscDispatchSampleDialog',
  mixins: [DialogFormView],
  props: {
    campaign: { type: Object, required: true }
  },
  data() {
    return {
      isLoading: true,
      dispatchNowPhones: [],
      dispatchNowEmails: [],
      dispatchingBizMessage: false,
      bizMessageResult: {}
    };
  },
  computed: {
    ...mapGetters('targetCampaign', ['isArchivedCampaign']),
    supportsSms() {
      if (this.isLoading) return false;

      return this.formObject.dispatch_methods.includes(
        TargetDispatchMethod.SMS
      );
    },
    supportsEmail() {
      if (this.isLoading) return false;

      return this.formObject.dispatch_methods.includes(
        TargetDispatchMethod.EMAIL
      );
    },
    formSections() {
      return [
        {
          groups: [
            ...(this.supportsSms
              ? [
                  {
                    id: 'dispatch_sample_enable_sms',
                    label: this.$t('dispatch_sample_enable_sms'),
                    type: 'checkbox',
                    fields: [
                      {
                        id: 'dispatch_sample_recipient_phones',
                        validate: [
                          {
                            rule: () =>
                              this.formObject.dispatch_sample_recipient_phones.every(
                                isKoreanPhoneFormatValid
                              ),
                            errorMessage: this.$t(
                              'validations.korean_phone_format'
                            )
                          }
                        ]
                      }
                    ]
                  }
                ]
              : []),
            ...(this.supportsEmail
              ? [
                  {
                    id: 'dispatch_sample_enable_email',
                    label: this.$t('dispatch_sample_enable_email'),
                    type: 'checkbox',
                    fields: [
                      {
                        id: 'dispatch_sample_recipient_emails',
                        validate: [
                          {
                            rule: () =>
                              this.formObject.dispatch_sample_recipient_emails.every(
                                isEmailFormatValid
                              ),
                            errorMessage: this.$t('validations.email_format')
                          }
                        ]
                      }
                    ]
                  }
                ]
              : []),
            { id: 'dispatch_sample_now', label: this.$t('dispatch_sample_now') }
          ]
        }
      ];
    },
    disabled() {
      return this.isArchivedCampaign;
    },
    isDisabledCampaign() {
      return this.campaign.status === TargetCampaignStatus.DISABLED;
    },
    disabledDispatchNow() {
      return this.disabled || this.isDisabledCampaign;
    },
    disabledDispatchNowTooltip() {
      return this.isDisabledCampaign
        ? this.$t('dispatch_sample_now_disabled_tooltip')
        : null;
    },
    bizMessageResultMessage() {
      const {
        totalCount,
        successCount,
        failedCount,
        sendingCount,
        failedReasons
      } = this.bizMessageResult;
      const failedMessage =
        failedReasons.length > 0
          ? this.$t('dispatch_sample_now_result.BIZ_MESSAGE.failed_reason', [
              failedReasons.join(', ')
            ])
          : '';

      switch (totalCount) {
        case sendingCount:
          return this.$t('dispatch_sample_now_result.BIZ_MESSAGE.sending');
        case successCount:
          return this.$t('dispatch_sample_now_result.BIZ_MESSAGE.success');
        case failedCount:
          return (
            this.$t('dispatch_sample_now_result.BIZ_MESSAGE.failed') +
            failedMessage
          );
        default:
          return (
            this.$t('dispatch_sample_now_result.BIZ_MESSAGE.mixed', [
              successCount,
              failedCount,
              sendingCount
            ]) + failedMessage
          );
      }
    }
  },
  watch: {
    bizMessageResult() {
      const { totalCount, sendingCount } = this.bizMessageResult;
      if (totalCount === 0 || sendingCount > 0) return;

      this.stopCheckingBizMessageResult();
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaign.id}/dispatch_sample`)
      .then(({ data }) => (this.orgFormObject = data.campaign))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/target/campaigns/${this.campaign.id}/dispatch_sample`,
          formData
        )
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    clickDispatchNowSms() {
      if (!this.dispatchNowPhones.every(isKoreanPhoneFormatValid)) {
        alert(this.$t('validations.korean_phone_format'));
        return;
      }

      api
        .post(
          `/target/campaigns/${this.campaign.id}/dispatch_sample_now`,
          { phones: this.dispatchNowPhones },
          {
            successMessage: this.$t(
              `dispatch_sample_now_result.${TargetMessageMedia.key(
                this.campaign.message_type
              )}.dispatched`
            )
          }
        )
        .then(() => {
          if (this.campaign.message_type === TargetMessageMedia.BIZ_MESSAGE)
            this.startCheckingBizMessageResult();
        });
    },
    clickDispatchNowEmail() {
      if (!this.dispatchNowEmails.every(isEmailFormatValid)) {
        alert(this.$t('validations.email_format'));
        return;
      }

      api.post(
        `/target/campaigns/${this.campaign.id}/dispatch_sample_now`,
        { emails: this.dispatchNowEmails },
        { successMessage: this.$t('dispatch_sample_now_dispatched_emails') }
      );
    },
    startCheckingBizMessageResult() {
      this.dispatchingBizMessage = true;
      this.interval = setInterval(this.checkBizMessageResult, 1000);
      setTimeout(() => {
        this.stopCheckingBizMessageResult();
      }, 4100);
    },
    stopCheckingBizMessageResult() {
      if (this.dispatchingBizMessage) {
        clearInterval(this.interval);
        this.createToast(this.bizMessageResultMessage);

        this.dispatchingBizMessage = false;
        this.bizMessageResult = {};
      }
    },
    isSuccessful(code) {
      return LunasoftBizMessageResultCode.isSuccessful(code);
    },
    isFailed(code) {
      return !LunasoftBizMessageResultCode.isSuccessful(code);
    },
    failedReason(code) {
      return LunasoftBizMessageResultCode.t(code);
    },
    checkBizMessageResult() {
      api
        .get(
          `/target/campaigns/${this.campaign.id}/dispatch_sample_now/check_biz_message_result`,
          { params: { phones: this.dispatchNowPhones } }
        )
        .then(({ data }) => {
          const { result_codes } = data;

          const totalCount = result_codes.length;
          const successCount = result_codes.filter(code =>
            this.isSuccessful(code)
          ).length;
          const failedResultCodes = result_codes.filter(code =>
            this.isFailed(code)
          );
          const failedCount = failedResultCodes.length;
          const sendingCount = totalCount - failedCount - successCount;

          this.bizMessageResult = {
            totalCount,
            sendingCount,
            successCount,
            failedCount,
            failedReasons: failedResultCodes.map(code =>
              this.failedReason(code)
            )
          };
        });
    }
  }
};
</script>

<style lang="scss" scoped>
$button-width: 82px;
$button-margin: 8px;

.TargetDmCampaignSettingsMiscDispatchSampleDialog__dispatch-sample-now-title {
  margin-top: 8px;
  margin-bottom: 4px;
}

.TargetDmCampaignSettingsMiscDispatchSampleDialog__dispatch-sample-now-tags {
  width: calc(100% - #{$button-width} - #{$button-margin});
  display: inline-block;
}

.TargetDmCampaignSettingsMiscDispatchSampleDialog__dispatch-sample-now-button {
  margin-left: $button-margin;
  width: $button-width;
  display: inline-block;
  vertical-align: top;
}
</style>

<i18n locale="ko">
{
  "title": "테스트 발송 설정",
  "dispatch_sample_enable_sms": "문자 메시지 발송 3시간 전 테스트 메시지 발송",
  "dispatch_sample_recipient_phones_placeholder": "메시지 수신 번호를 입력해주세요. SMS/LMS/알림톡 중 설정해놓은 매체로 테스트합니다.",
  "dispatch_sample_enable_email": "이메일 발송 3시간 전 테스트 메시지 발송",
  "dispatch_sample_recipient_emails_placeholder": "메시지 수신 이메일 주소를 입력해주세요.",
  "dispatch_sample_now": "테스트 메시지 즉시 발송",
  "dispatch_sample_now_button": "즉시 발송",
  "dispatch_sample_now_dispatched_emails": "테스트 이메일을 발송했습니다.",
  "dispatch_sample_now_disabled_tooltip": "미사용 상태의 캠페인은 즉시 발송이 불가능합니다.",
  "dispatch_sample_now_result": {
    "SMS": {
      "dispatched": "테스트 SMS를 발송했습니다."
    },
    "LMS": {
      "dispatched": "테스트 LMS를 발송했습니다."
    },
    "BIZ_MESSAGE": {
      "dispatched": "테스트 알림톡 발송 요청 중입니다. 최대 4초까지 확인합니다.",
      "success": "테스트 알림톡을 발송했습니다.",
      "sending": "테스트 알림톡 발송 결과 확인이 불가능합니다.",
      "failed": "테스트 알림톡 발송에 실패했습니다.",
      "mixed": "테스트 알림톡 발송 성공 {0}건, 실패 {1}건, 결과 확인 불가 {2}건 입니다.",
      "failed_reason": "\n실패 사유: {0}"
    }
  }
}
</i18n>
