<template>
  <div class="TheSearchDialogResults">
    <div class="TheSearchDialogResults__tabs">
      <ul>
        <li
          v-for="searchCategory in searchCategories"
          :key="searchCategory"
          :class="[
            'search-results-tab',
            {
              'search-results-tab--active':
                selectedSearchCategory === searchCategory
            }
          ]"
        >
          <a
            class="search-results-tab__link"
            @click="selectedSearchCategory = searchCategory"
            >{{ SearchCategory.t(searchCategory) }}</a
          >
        </li>
      </ul>
    </div>
    <div class="TheSearchDialogResults__section">
      <ul v-if="searchResultsInCategory.length">
        <li
          v-for="(searchResult, index) in searchResultsInCategory"
          ref="items"
          :key="index"
          :class="[
            'search-dialog-item',
            { 'search-dialog-item--active': index === activeIndex }
          ]"
        >
          <TheSearchDialogResult
            :search-result="searchResult"
            @mousemove-search-result="activateItem(index)"
            @select-search-result="selectItem"
          />
        </li>
      </ul>
      <div v-else class="TheSearchDialogResults__no-data">
        {{ $t('app.no_data') }}
        <div
          v-if="showHelpButton"
          class="TheSearchDialogResults__help-chat-button"
        >
          <AppButton :label="helpChatLabel" @click="clickHelpChat" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SearchCategory from '@/enums/SearchCategory';
import TheSearchDialogItemsView from './TheSearchDialogItemsView';
import TheSearchDialogResult from './TheSearchDialogResult';
import InquiryHelper from '@/mixins/InquiryHelper';

export default {
  name: 'TheSearchDialogResults',
  components: { TheSearchDialogResult },
  mixins: [TheSearchDialogItemsView, InquiryHelper],
  props: {
    initialSearchCategory: { type: String, default: 'all' }
  },
  data() {
    return {
      selectedSearchCategory: this.initialSearchCategory,
      searchCategories: SearchCategory.values
    };
  },
  computed: {
    ...mapState('search', ['query']),
    ...mapGetters('menu', ['helpChatLabel']),
    ...mapGetters('search', ['searchResults']),
    SearchCategory() {
      return SearchCategory;
    },
    searchResultsInCategory() {
      return this.searchResults(this.selectedSearchCategory);
    },
    itemsCount() {
      return this.searchResultsInCategory.length;
    },
    showHelpButton() {
      return (
        this.selectedSearchCategory === SearchCategory.MANUALS &&
        this.helpChatLabel
      );
    }
  },
  watch: {
    searchResultsInCategory() {
      this.activeIndex = 0;
    }
  },
  methods: {
    selectItem() {
      const searchResult = this.searchResultsInCategory[this.activeIndex];
      if (searchResult) this.$emit('select-result', searchResult);
    },
    clickHelpChat() {
      this.openInquiry();
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheSearchDialogResults__tabs {
  padding: 12px 16px;
}

.search-results-tab {
  display: inline-block;

  & + .search-results-tab {
    margin-left: 32px;
  }
}

.search-results-tab__link {
  cursor: pointer;
  color: $color-button-text;
}

.search-results-tab--active .search-results-tab__link {
  color: $color-primary;
  font-weight: bold;
}

.TheSearchDialogResults__no-data {
  text-align: center;
  font-size: 16px;
  color: $color-content-text-dark;
  padding: 36px 0;
}

.TheSearchDialogResults__help-chat-button {
  margin-top: 16px;
}
</style>
