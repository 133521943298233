<template>
  <span>{{ valueText }}</span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppNumber',
  props: {
    value: { type: Number, default: null },
    precision: { type: Number, default: null },
    unit: { type: String, default: null },
    placeholder: { type: String, default: null },
    forcePrecision: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('session', ['currencyKey']),
    valueWithPrecision() {
      const { precision } = this;
      const options = {};
      if (precision) {
        options.maximumFractionDigits = precision;
        if (this.forcePrecision) options.minimumFractionDigits = precision;
      }

      return this.value.toLocaleString(undefined, options);
    },
    valueText() {
      const { unit } = this;
      return this.value === null
        ? this.placeholder
        : unit
        ? this.$t(unit === 'currency' ? this.currencyKey : `unit.${unit}`, [
            this.valueWithPrecision
          ])
        : this.valueWithPrecision;
    }
  }
};
</script>
