<template>
  <div
    :class="[
      'AppDataLabel',
      `AppDataLabel--gap-${gap}`,
      disabled ? `AppDataLabel--disabled` : null
    ]"
  >
    {{ label }}
    <template v-if="badges">
      <AppBadge
        v-for="(badge, i) in badges"
        :key="i"
        class="AppDataLabel__badge"
        v-bind="badge"
      />
    </template>
    <AppExternalLink
      v-if="labelLink"
      class="AppDataLabel__link"
      :to="labelLink.link"
      v-on="labelLink.clickHandler ? { click: labelLink.clickHandler } : {}"
    >
      {{ labelLink.label }}
    </AppExternalLink>
  </div>
</template>

<script>
export default {
  name: 'AppDataLabel',
  props: {
    label: { type: String, default: '' },
    labelLink: { type: Object, default: null },
    badges: { type: Array, default: null },
    gap: {
      type: String,
      default: 'default',
      validator: v => ['narrow', 'default', 'wide'].includes(v)
    },
    disabled: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppDataLabel {
  @include text-label;
  margin-bottom: 8px;

  &--gap-narrow {
    margin-bottom: 4px;
  }

  &--gap-wide {
    margin-bottom: 24px;
  }

  &--disabled {
    color: $color-grey-40;
  }
}

.AppDataLabel__badge {
  margin-left: 4px;
}

.AppDataLabel__link {
  @include text-content-light;
  font-weight: normal;
  text-decoration: underline;
  margin-left: 4px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}
</style>
