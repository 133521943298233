<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="
      installationIds
        ? $t('sub_title', {
            count: installationIds.length
          })
        : ''
    "
    :form-props="{ ...formProps }"
    v-on="formEvents"
  >
    <template>
      <AppInfoBox
        class="ReviewSmartInstallationChangeSkinIdFormDialog__info-box"
      >
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="$t('skin.info')" />
        <!-- eslint-enable vue/no-v-html -->
      </AppInfoBox>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import { mapMutations } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewSmartInstallationChangeSkinIdFormDialog',
  mixins: [DialogFormView],
  props: {
    installationIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      detectFormDataChange: false
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'skin_id',
              groupDescription: this.$t('skin.description'),
              label: this.$t('skin.label'),
              type: 'text',
              placeholder: this.$t('skin.placeholder')
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapMutations('reviewWidgets', [
      'UPDATE_REVIEW_WIDGET_SMART_INSTALLATION'
    ]),
    submit() {
      this.isSubmitting = true;
      api
        .patch('/smart_installations/update_skin_id', {
          ids: this.installationIds,
          skin_id: this.formObject.skin_id || ''
        })
        .then(({ data }) => {
          data.smart_installations.forEach(installation => {
            this.UPDATE_REVIEW_WIDGET_SMART_INSTALLATION({
              widgetId: installation.widget_id,
              installation: installation
            });
          });
          this.emit('submit');
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style scoped>
.ReviewSmartInstallationChangeSkinIdFormDialog__info-box {
  margin-bottom: 32px;
}
</style>

<i18n locale="ko">
{
  "title": "적용 스킨 변경",
  "sub_title": "선택 설치 내역 {count}개",
  "skin": {
    "label": "적용 스킨 정보",
    "info": "적용 스킨 정보를 작성하고 저장할 경우, 이미 적용된 스킨 정보가 덮어씌워집니다.",
    "placeholder": "스킨 정보를 입력해주세요.",
    "description": "특정 스킨에 위젯을 노출하고싶을 경우 입력해주세요. 입력하지 않을 경우 모든 스킨에 위젯이 노출됩니다.",
    "error": "잘못된 형식입니다."
  }
}
</i18n>
