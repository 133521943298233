<template>
  <AppGrid class="AnalyticsInsightDashboardMetrics">
    <div
      class="AnalyticsInsightDashboardMetrics__col-left AppGrid__col AppGrid__col--3"
    >
      <AppDataSection :title="$t('metrics')" gap="wide">
        <AppDataSectionItem
          v-for="(item, i) in items"
          :key="i"
          :title="item.title"
        >
          <div class="AnalyticsInsightDashboardMetrics__item">
            <AppNumber
              v-if="item.numberProps.value"
              v-bind="item.numberProps"
              class="AnalyticsInsightDashboardMetrics__item-value"
              force-precision
            />
            <span v-else class="AnalyticsInsightDashboardMetrics__item-value"
              >-</span
            >
            <div
              v-if="item.comparedProps"
              class="AnalyticsInsightDashboardMetrics__item-comparision"
            >
              <span
                class="AnalyticsInsightDashboardMetrics__item-comparison-text"
                >{{ $t('compare_to_competing_group') }}</span
              >
              <AppBadge
                class="AnalyticsInsightDashboardMetrics__item-comparison-badge"
                :label="item.comparedProps.value"
                :badge-style="item.comparedProps.badgeStyle"
              />
            </div>
          </div>
        </AppDataSectionItem>
      </AppDataSection>
    </div>
    <div
      class="AnalyticsInsightDashboardMetrics__col-right AppGrid__col AppGrid__col--9"
    >
      <AppContainer>
        <AppDropdownMenuButton
          :label="InsightDashboardChartType.t(chartType)"
          :menu-items="dropdownMenuItems"
          button-style="label"
        />
        <div class="AnalyticsInsightDashboardMetrics__graph">
          <AppChart
            :options="chartOptions"
            :tooltip="chartTooltip"
            @tooltip="onChartTooltip"
          />
        </div>
      </AppContainer>
    </div>
  </AppGrid>
</template>

<script>
import _ from 'lodash';
import InsightChartView from '@/mixins/InsightChartView';
import InsightDashboardChartType from '@/enums/InsightDashboardChartType';

export default {
  name: 'AnalyticsInsightDashboardMetrics',
  mixins: [InsightChartView],
  props: {
    reviewsCountArray: { type: Array, required: true },
    avgReviewTagsCountArray: { type: Array, required: true },
    reviewTagsCountArray: { type: Array, required: true },
    mediaReviewsRatioArray: { type: Array, required: true }
  },
  data() {
    return {
      chartType: null
    };
  },
  computed: {
    items() {
      return [
        {
          title: InsightDashboardChartType.t(
            InsightDashboardChartType.REVIEW_TAGS_COUNT
          ),
          numberProps: {
            value: _.sum(this.myBrandReviewTagsCountArray),
            unit: 'count'
          },
          comparedProps: this.reviewTagsCountDiffRate
            ? {
                value: this.diffPercentageLabel(this.reviewTagsCountDiffRate),
                badgeStyle:
                  this.reviewTagsCountDiffRate >= 0
                    ? 'mint-green-outline'
                    : 'red-outline',
                unit: 'percentage'
              }
            : null
        },
        {
          title: InsightDashboardChartType.t(
            InsightDashboardChartType.REVIEW_TAGS_COUNT_PER_REVIEW
          ),
          numberProps: {
            value: _.mean(this.myBrandReviewTagsCountPerReviewArray),
            precision: 2,
            unit: 'count'
          },
          comparedProps: this.reviewTagsCountPerReviewDiffRate
            ? {
                value: this.diffPercentageLabel(
                  this.reviewTagsCountPerReviewDiffRate
                ),
                badgeStyle:
                  this.reviewTagsCountPerReviewDiffRate >= 0
                    ? 'mint-green-outline'
                    : 'red-outline',
                unit: 'percentage'
              }
            : null
        },
        {
          title: InsightDashboardChartType.t(
            InsightDashboardChartType.REVIEWS_COUNT
          ),
          numberProps: {
            value: _.sum(this.myBrandReviewsCountArray),
            unit: 'count'
          },
          comparedProps: this.reviewsCountDiffRate
            ? {
                value: this.diffPercentageLabel(this.reviewsCountDiffRate),
                badgeStyle:
                  this.reviewsCountDiffRate >= 0
                    ? 'mint-green-outline'
                    : 'red-outline',
                unit: 'percentage'
              }
            : null
        }
      ];
    },
    myBrandReviewsCountArray() {
      return _.unzip(this.reviewsCountArray)[0];
    },
    brandsReviewsCountArray() {
      return _.unzip(this.reviewsCountArray)[1];
    },
    reviewsCountDiffRate() {
      const mc = _.sum(this.myBrandReviewsCountArray);
      const bc = _.sum(this.brandsReviewsCountArray);

      return bc ? ((mc - bc) * 100) / bc : null;
    },
    myBrandReviewTagsCountPerReviewArray() {
      return _.unzip(this.avgReviewTagsCountArray)[0];
    },
    brandsReviewTagsCountPerReviewArray() {
      return _.unzip(this.avgReviewTagsCountArray)[1];
    },
    reviewTagsCountPerReviewDiffRate() {
      const mc = _.mean(this.myBrandReviewTagsCountPerReviewArray);
      const bc = _.mean(this.brandsReviewTagsCountPerReviewArray);

      return bc ? ((mc - bc) * 100) / bc : null;
    },
    myBrandReviewTagsCountArray() {
      return _.unzip(this.reviewTagsCountArray)[0];
    },
    brandsReviewTagsCountArray() {
      return _.unzip(this.reviewTagsCountArray)[1];
    },
    myBrandMediaReviewsRatioArray() {
      return _.unzip(this.mediaReviewsRatioArray)[0];
    },
    brandsMediaReviewsRatioArray() {
      return _.unzip(this.mediaReviewsRatioArray)[1];
    },
    reviewTagsCountDiffRate() {
      const mc = _.sum(this.myBrandReviewTagsCountArray);
      const bc = _.sum(this.brandsReviewTagsCountArray);

      return bc ? ((mc - bc) * 100) / bc : null;
    },
    InsightDashboardChartType() {
      return InsightDashboardChartType;
    },
    chartTypes() {
      return InsightDashboardChartType.values;
    },
    dropdownMenuItems() {
      return this.chartTypes.map(chartType => ({
        label: InsightDashboardChartType.t(chartType),
        clickHandler: e => this.selectChartType(e, chartType)
      }));
    },
    chartOptions() {
      return {
        chart: { height: 330, spacing: [-4, 0, 0, 0] },
        title: { text: '' },
        exporting: false,
        plotOptions: {
          series: {
            states: { hover: { lineWidthPlus: 0, halo: { size: 0 } } },
            marker: { enabled: false, symbol: 'circle', radius: 2 }
          }
        },
        tooltip: { enabled: false },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          symbolHeight: 8,
          symbolWidth: 8
        },
        xAxis: {
          crosshair: { dashStyle: 'solid', width: 2 },
          type: 'datetime',
          categories: this.xAxes
        },
        yAxis: {
          title: { enabled: false }
        },
        series: [
          {
            name: this.myBrandlegendString,
            data: this[`myBrand${this.seriesDataString}Array`].slice(
              0,
              this.xAxes.length
            )
          },
          {
            name: this.brandsLegendString,
            data: this[`brands${this.seriesDataString}Array`].slice(
              0,
              this.xAxes.length
            )
          }
        ]
      };
    },
    seriesDataString() {
      const type = InsightDashboardChartType.key(this.chartType);
      const toPascalCase = _.flow([_.camelCase, _.upperFirst]);

      return toPascalCase(type);
    },
    myBrandlegendString() {
      const prefix = this.$t('insight.my_brand');
      const type = InsightDashboardChartType.t(this.chartType);

      return prefix + ' ' + type;
    },
    brandsLegendString() {
      const prefix = this.$t('insight.brands');
      const type = InsightDashboardChartType.t(this.chartType);
      const suffix =
        this.chartType ===
        InsightDashboardChartType.REVIEW_TAGS_COUNT_PER_REVIEW
          ? ''
          : this.$t('app.average');

      return prefix + ' ' + type + ' ' + suffix;
    }
  },
  created() {
    this.chartType = this.chartTypes[0];
  },
  methods: {
    selectChartType({ close }, chartType) {
      this.chartType = chartType;
      close();
    },
    diffPercentageLabel(diff) {
      return diff >= 0
        ? `${this.formatPercentage(diff)}% ${this.$t('higher')}`
        : `${this.formatPercentage(-1 * diff)}% ${this.$t('lower')}`;
    },
    formatPercentage(number) {
      return Number.parseFloat(number).toFixed(2);
    },
    onChartTooltip({ index, series }) {
      this.chartTooltip = {
        title: this.getFormattedDate(this.xAxisValues[index]),
        items: _.flatten(
          series.chart.series.map(({ data }, i) => {
            let fractionDigits;
            if (
              this.chartType ===
              InsightDashboardChartType.REVIEW_TAGS_COUNT_PER_REVIEW
            )
              fractionDigits = 2;
            else fractionDigits = 0;

            const item = {
              symbol: 'circle',
              name: i ? this.brandsLegendString : this.myBrandlegendString,
              value: this.$t('unit.count', [
                data[index].y.toLocaleString(undefined, {
                  minimumFractionDigits: fractionDigits,
                  maximumFractionDigits: fractionDigits
                })
              ]),
              colorIndex: i
            };

            if (this.chartType === InsightDashboardChartType.REVIEWS_COUNT) {
              let itemPrefix, itemValue;
              if (i === 0) {
                itemPrefix = this.$t('insight.my_brand');
                itemValue = this.myBrandMediaReviewsRatioArray[index];
              } else {
                itemPrefix = this.$t('insight.brands');
                itemValue = this.brandsMediaReviewsRatioArray[index];
              }
              return [
                item,
                {
                  symbol: null,
                  name: `${itemPrefix} ${this.$t(
                    'insight.media_reviews_ratio'
                  )}`,
                  value: this.$t('unit.percentage', [
                    itemValue.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  ])
                }
              ];
            } else return item;
          })
        )
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightDashboardMetrics__col-right {
  @media (max-width: 719px) {
    & {
      margin-top: 20px;
    }
  }
}

.AnalyticsInsightDashboardMetrics__item-value {
  display: inline-block;
  font-size: 20px;
  color: $color-grey-80;
  font-weight: 400;
}

.AnalyticsInsightDashboardMetrics__item-comparision {
  @include text-caption-dark;
  margin-top: 2px;
}

.AnalyticsInsightDashboardMetrics__item-comparison-text {
  margin-top: 2px;
  padding-right: 4px;
}

.AnalyticsInsightDashboardMetrics__item-comparison-badge {
  margin-top: 2px;
}

.AnalyticsInsightDashboardMetrics__graph {
  margin-top: 26px;
}

@mixin highcharts-color($num, $color) {
  .highcharts-color-#{$num} {
    fill: $color;
    stroke: $color;
  }
}

::v-deep {
  @include highcharts-color(0, $color-blue);
  @include highcharts-color(1, $color-grey-75);

  .highcharts-legend-item.highcharts-bar-series {
    cursor: auto;
  }

  .highcharts-legend-item.highcharts-bar-series > text {
    cursor: auto;
    fill: $color-grey-60;
  }
}
</style>

<i18n locale="ko">
{
  "metrics": "주요 지표",
  "compare_to_competing_group": "동기간 경쟁 그룹 대비",
  "higher": "높음",
  "lower": "낮음"
}
</i18n>
