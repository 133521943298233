<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        Basic Card
      </div>
      <div class="ui-section__item">
        <AppStatCard title="StatCard" :stat="0.5" detail="113,242/1,234,567" />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        Null Card
      </div>
      <div class="ui-section__item">
        <AppStatCard title="StatCard" detail="계산중..." />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        Clickable Card
      </div>
      <div class="ui-section__item">
        <AppStatCard
          title="StatCard"
          :stat="0.5"
          :detail="$t('unit.last_days', [30])"
          is-clickable
          @click="createToast('Clicked!')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UiStatCard',
  methods: mapActions('toast', ['createToast'])
};
</script>
