var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-head-control"},[_c('AppButton',{attrs:{"label":_vm.$t('add_recommendation_unit.label'),"disabled":_vm.disabled || _vm.isMaxReached,"tooltip":_vm.isMaxReached
          ? _vm.$t('add_recommendation_unit.tooltip', [_vm.maxUnitsCount])
          : null},on:{"click":_vm.clickAddRecommendationUnit}})],1),(!_vm.recommendedProducts.length)?_c('input',{attrs:{"type":"hidden","name":"campaign[product_recommendation_units][]","value":""}}):_vm._e(),_c('AppTable',{class:[
      _vm.disabled
        ? 'TargetDmCampaignsRecommendedProducts__table--disabled'
        : null
    ],attrs:{"columns":[
      { id: 'title', label: _vm.$t('title'), align: 'left', width: '144px' },
      { id: 'max_products', label: _vm.$t('max_products'), width: '52px' },
      { id: 'actions', label: _vm.$t('actions'), width: '150px' }
    ],"rows":_vm.recommendedProducts},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
    var column = ref.column;
    var row = ref.row;
    var rowIndex = ref.rowIndex;
return [(column.id === 'actions')?[_vm._l(([
            'id',
            'title',
            'max_products',
            'product_recommendation_method',
            'popular_filter_type',
            'in_categories_filter_type1',
            'in_categories_filter_type2',
            'personalized_filter_type1',
            'personalized_filter_type2',
            'personalized_filter_type3'
          ]),function(key,i){return _c('input',{key:("key-" + i),attrs:{"type":"hidden","name":("campaign[product_recommendation_units][][" + key + "]")},domProps:{"value":row[key]}})}),_c('AppHiddenInputArray',{attrs:{"name":"campaign[product_recommendation_units][][category_ids][]","values":row.category_ids}}),_c('AppHiddenInputArray',{attrs:{"name":"campaign[product_recommendation_units][][hand_picked_product_ids][]","values":row.hand_picked_products.map(function (p) { return p.id; })}}),_c('AppButtonDraggable',{attrs:{"drag-text":row.title,"disabled":_vm.disabled},on:{"sort":_vm.sortProductRecommendationUnit}}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"disabled":_vm.disabled,"menu-items":[
            {
              label: _vm.$t('app.settings'),
              clickHandler: function (e) { return _vm.clickEditRecommendationUnit(e, row, rowIndex); }
            },
            {
              label: _vm.$t('app.delete'),
              style: 'danger',
              clickHandler: function (e) { return _vm.clickDeleteRecommendationUnit(e, rowIndex); }
            }
          ]}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }