<template>
  <div class="SubMenuImageNotice">
    <Component
      :is="notice.button_url ? 'AppExternalLink' : 'div'"
      :to="notice.button_url"
      class="SubMenuImageNotice__link"
      @click="$emit('click')"
    >
      <AppImage
        :src="notice.image_url"
        :background="true"
        class="SubMenuImageNotice__image"
      />
    </Component>
  </div>
</template>

<script>
export default {
  name: 'SubMenuImageNotice',
  props: {
    notice: { type: Object, required: true }
  }
};
</script>

<style lang="scss" scoped>
.SubMenuImageNotice {
  height: 100%;
}

.SubMenuImageNotice__link {
  width: 100%;
  height: 100%;
}

.SubMenuImageNotice__image {
  width: 100%;
  height: 100%;
}
</style>
