<template>
  <div>
    <AppSelectSearchDropdown :data="SEARCH_LIST" @select="selectItem" />
  </div>
</template>

<script>
import AppSelectSearchDropdown from '@/components/AppSelect/AppSelectSearchDropdown';

export default {
  name: 'UiDataListSearchDropdown',
  components: { AppSelectSearchDropdown },
  data() {
    return {
      SEARCH_LIST: [
        { label: '테스트', value: 1 },
        { label: 'ㅌㅅㅌ', value: 2 },
        { label: 'test', value: 3 },
        { label: 'tes', value: 4 },
        { label: '우왕', value: 5 },
        { label: '왕우', value: 6 },
        { label: '우웅', value: 7, disabled: true },
        { label: '그만', value: 8 },
        { label: '그만~', value: 9 }
      ]
    };
  },
  methods: {
    selectItem(value) {
      // eslint-disable-next-line no-console
      console.log(value);
    }
  }
};
</script>
