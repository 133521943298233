<template>
  <div class="TargetReportsCampaignKpi">
    <AppDataSection :title="$t('title')" gap="wide">
      <AppDataSectionItem
        v-for="(item, i) in items"
        :key="i"
        :title="item.title"
      >
        <div class="AppDataSectionItem__content">
          <AppNumber v-bind="item.numberProps" />
          <AppExternalLink
            v-if="item.link"
            class="TargetReportsCampaignKpi__link"
            @click="clickDetailButton"
          >
            <AppExternalLinkIcon
              size="small"
              class="TargetReportsCampaignKpi__link-icon"
            />
          </AppExternalLink>
        </div>
      </AppDataSectionItem>
    </AppDataSection>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TargetTotalReportType from '@/enums/TargetTotalReportType';

export default {
  name: 'TargetReportsCampaignKpi',
  props: {
    kpis: { type: Object, required: true },
    dateRange: { type: Object, required: true },
    campaignDispatchType: { type: Number, default: null }
  },
  computed: {
    items() {
      const {
        purchase_conversion_rate,
        inactivation_prevention_rate,
        signup_conversion_rate,
        affected_purchase_count
      } = this.kpis;

      return [
        {
          title: this.$t('kpi.purchase_conversion_rate'),
          numberProps: {
            value: purchase_conversion_rate * 100,
            precision: 2,
            unit: 'percentage'
          }
        },
        {
          title: this.$t('kpi.inactivation_prevention_rate'),
          numberProps: {
            value: inactivation_prevention_rate * 100,
            precision: 2,
            unit: 'percentage'
          }
        },
        {
          title: this.$t('kpi.signup_conversion_rate'),
          numberProps: {
            value: signup_conversion_rate * 100,
            precision: 2,
            unit: 'percentage'
          }
        },
        {
          title: this.$t('kpi.affected_purchase_count'),
          link: true,
          numberProps: {
            value: affected_purchase_count,
            precision: 2,
            unit: 'cases'
          }
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    clickDetailButton() {
      this.openDialog([
        'TargetReportsTotalReportsDetailDialog',
        {
          totalReportType: TargetTotalReportType.TOTAL,
          dateRange: this.dateRange,
          campaignDispatchType: this.campaignDispatchType
        }
      ]);
    }
  }
};
</script>

<style scoped>
.TargetReportsCampaignKpi {
  height: 100%;
}

.TargetReportsCampaignKpi__link {
  display: inline-block;
  margin-left: 4px;
}

.TargetReportsCampaignKpi__link-icon {
  vertical-align: top;
}
</style>

<i18n locale="ko">
{
  "title": "주요 지표",
  "kpi": {
    "purchase_conversion_rate": "구매 전환율",
    "inactivation_prevention_rate": "이탈 방지율",
    "signup_conversion_rate": "가입 전환율",
    "affected_purchase_count": "타겟이 영향을 준 구매건"
  }
}
</i18n>
