<template>
  <div class="DisplayProductDisplayName">
    <template v-if="isEditing">
      <AppForm
        class="DisplayProductDisplayName__form"
        v-bind="formProps"
        focus-group-id="name"
        v-on="formEvents"
      />
      <AppButtonSubmit
        class="DisplayProductDisplayName__button"
        v-bind="{ isSubmitting, errorSummary }"
        :disabled="!isFormDataChanged"
        @click="formEventBus.$emit('submit')"
      />
      <AppButton
        class="DisplayProductDisplayName__button"
        :label="$t('app.cancel')"
        @click="stopEditing"
      />
    </template>
    <template v-else>
      <span class="DisplayProductDisplayName__title"> {{ title }} </span
      ><span class="DisplayProductDisplayName__sub-title">
        {{ display.name }}
      </span>
      <AppEditLink
        class="DisplayProductDisplayName__edit-link"
        :label="$t('change_name')"
        @click="startEditing"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';

export default {
  name: 'DisplayProductDisplayName',
  mixins: [FormView],
  data() {
    return { isEditing: false };
  },
  computed: {
    ...mapState('displayProductDisplay', ['display']),
    ...mapState('displayProductDisplays', ['productDisplays']),
    ...mapGetters('displayProductDisplays', ['cornerPageTypeKey']),
    title() {
      return this.$t(`title.${this.cornerPageTypeKey}`);
    },
    existingNames() {
      return this.productDisplays
        .map(display => display.name)
        .filter(name => name !== this.display.name);
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              label: '',
              type: 'text',
              validate: [
                'required',
                { rule: 'max_length', limit: 30 },
                {
                  rule: v => !this.existingNames.includes(v.trim()),
                  errorMessage: this.$t(
                    'errors.duplicated_product_display_name'
                  )
                }
              ]
            }
          ]
        }
      ];
    }
  },
  watch: {
    isFormDataChanged() {
      this.$emit('update-form-status', this.isFormDataChanged);
    }
  },
  methods: {
    ...mapActions('displayProductDisplay', ['updateDisplayName']),
    startEditing() {
      this.isEditing = true;
      this.orgFormObject = { name: this.display.name };
    },
    stopEditing() {
      this.isEditing = false;
      this.orgFormObject = {};
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/display/product_displays/${this.display.id}/update_name`,
          formData,
          {
            successMessage: this.$t('app.saved')
          }
        )
        .then(() => {
          this.updateDisplayName(this.formObject.name);
          this.stopEditing();
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayProductDisplayName {
  display: flex;
}

.DisplayProductDisplayName__title {
  @include text-modal-title;
}

.DisplayProductDisplayName__sub-title {
  @include text-modal-title-light;

  margin-left: 8px;
}

.DisplayProductDisplayName__edit-link {
  margin-left: 16px;
}

.DisplayProductDisplayName__form {
  ::v-deep .AppForm__group-field {
    margin-top: 0;
  }
}

.DisplayProductDisplayName__button {
  &,
  & + & {
    margin-left: 4px;
  }
}
</style>

<i18n locale="ko">
{
  "title": {
    "MAIN": "메인 페이지 상품 진열",
    "CATEGORY": "카테고리 페이지 상품 진열"
   },
  "change_name": "이름 변경",
  "errors": {
    "duplicated_product_display_name": "이미 사용중인 진열 설정 이름입니다."
  }
}
</i18n>
