<template>
  <AppModalDefault :title="$t('title')" width="middle" @close="close">
    <template #body>
      <pre>{{ $t('description') }}</pre>
      <AppResourceTable
        :columns="columns"
        :rows="rows"
        class="TheBizMessageTemplateStoppedDialog__table"
      >
        <template #cell="{ column, row }">
          <template v-if="column === 'actions'">
            <AppButton
              :label="$t('app.show_detail')"
              @click="
                openBizMessageTemplateShowDialog(row.id, row.template_type)
              "
            />
          </template>
        </template>
      </AppResourceTable>
    </template>
    <template #foot>
      <AppButton
        :label="$t('show_campaign')"
        button-style="blue"
        @click="openBizMessageCampaignFormDialog"
      />
      <AppButton :label="$t('check_next_time')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapActions } from 'vuex';
import DialogView from '@/mixins/DialogView';
import BizMessageTemplateStatus from '@/enums/BizMessageTemplateStatus';

export default {
  name: 'TheBizMessageTemplateStoppedDialog',
  mixins: [DialogView],
  props: { templates: { type: Array, required: true } },
  computed: {
    columns() {
      return [
        { id: 'name', label: this.$t('columns.name'), align: 'left' },
        { id: 'status', label: this.$t('columns.status'), type: 'badges' },
        { id: 'actions', label: this.$t('app.actions'), width: '130px' }
      ];
    },
    rows() {
      const templateStatus = BizMessageTemplateStatus.STOPPED;
      const templateIdentifiers = [
        ...new Set(
          this.templates.map(
            ({ id, template_type }) => `${id}_${template_type}`
          )
        )
      ];
      return templateIdentifiers.map(templateIdentifier => {
        const template = this.templates.find(
          ({ id, template_type }) =>
            `${id}_${template_type}` === templateIdentifier
        );
        return {
          ...template,
          status: [
            {
              style: BizMessageTemplateStatus.badgeStyle(templateStatus),
              label: BizMessageTemplateStatus.t(templateStatus)
            }
          ]
        };
      });
    }
  },
  mounted() {
    this.snoozeAlert('stopped_biz_message_templates');
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('alert', ['snoozeAlert']),
    openBizMessageCampaignFormDialog() {
      const campaignTypesByPriority = this.templates
        .map(template => template.biz_message_campaign_type)
        .sort();
      this.openDialog([
        'BizMessageCampaignFormDialog',
        { campaignType: campaignTypesByPriority[0] }
      ]);
      this.close();
    },
    openBizMessageTemplateShowDialog(templateId, templateType) {
      this.openDialog([
        'BizMessageTemplateShowDialog',
        { templateId, templateType, hideEditButton: true }
      ]);
    }
  }
};
</script>

<style scoped>
.TheBizMessageTemplateStoppedDialog__table {
  margin-top: 20px;
}
</style>

<i18n locale="ko">
{
  "title": "알림톡 발송이 중단되었습니다.",
  "description": "설정하신 알림톡 템플릿이 사용 중단 처리되어 알림톡 발송이 불가합니다.\n다른 템플릿으로 변경해주세요.",
  "columns": {
    "name": "템플릿 명",
    "status": "템플릿 상태"
  },
  "show_campaign": "발송설정 바로가기",
  "check_next_time": "다음에 설정"
}
</i18n>
