<template>
  <AppGrid>
    <div
      v-if="availableSections.includes('sms')"
      :class="['AppGrid__col', `AppGrid__col--${sectionGridWidth}`]"
    >
      <TargetDmCampaignSettingsSms />
    </div>
    <div
      v-if="availableSections.includes('landing_page')"
      :class="['AppGrid__col', `AppGrid__col--${sectionGridWidth}`]"
    >
      <TargetSettingsSection
        :title="$t('landing_page.title')"
        @open-settings="openLandingPageDialog"
      >
        <div>{{ $t('landing_page.sub_title') }}</div>
        <div>
          <b>{{ landingPageSummary }}</b>
        </div>
      </TargetSettingsSection>
    </div>
    <div
      v-if="availableSections.includes('email')"
      :class="['AppGrid__col', `AppGrid__col--${sectionGridWidth}`]"
    >
      <TargetSettingsSection
        :title="$t('email.title')"
        @open-settings="openEmailDialog"
      >
        <div v-if="campaign.enable_email">
          <div>{{ $t('email.sub_title') }}</div>
          <div>
            <b>{{ emailSummary }}</b>
          </div>
        </div>
        <template v-else>
          {{ $t('email.disabled') }}
        </template>
      </TargetSettingsSection>
    </div>
  </AppGrid>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetSettingsSection from './components/TargetSettingsSection';
import TargetDmCampaignSettingsSms from './TargetDmCampaignSettingsSms';

export default {
  name: 'TargetDmCampaignSettingsDesign',
  components: { TargetSettingsSection, TargetDmCampaignSettingsSms },
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    availableSections() {
      if (this.campaign.campaign_type === TargetCampaignType.MILEAGE_EXPIRING)
        return ['sms', 'email'];
      else return ['sms', 'landing_page', 'email'];
    },
    sectionGridWidth() {
      return 12 / this.availableSections.length;
    },
    landingPageSections() {
      switch (this.campaign.campaign_type) {
        case TargetCampaignType.CART_REMINDING:
          return ['cart', 'event', 'recommendation'];
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
          return ['unpaid_order', 'event', 'recommendation'];
        case TargetCampaignType.SELECTED_PRODUCTS:
        case TargetCampaignType.SELECTED_CATEGORIES:
          return ['event', 'recommendation'];
        case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
          return ['inactivation_info', 'coupon', 'event', 'recommendation'];
        default:
          return ['coupon', 'event', 'recommendation'];
      }
    },
    landingPageSummary() {
      return this.landingPageSections
        .filter(section => this.isLandingPageSectionActive(section))
        .map(section =>
          this.$t(
            `landing_page_section.${section}`,
            section === 'recommendation'
              ? [this.campaign.landing_page_design.recommendations_count]
              : []
          )
        )
        .join(', ');
    },
    emailSections() {
      switch (this.campaign.campaign_type) {
        case TargetCampaignType.CART_REMINDING:
          return ['cart', 'event', 'recommendation'];
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
          return ['unpaid_order', 'event', 'recommendation'];
        case TargetCampaignType.SELECTED_PRODUCTS:
        case TargetCampaignType.SELECTED_CATEGORIES:
          return ['event', 'recommendation'];
        case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
          return ['inactivation_info', 'coupon', 'event', 'recommendation'];
        default:
          return ['coupon', 'event', 'recommendation'];
      }
    },
    emailSummary() {
      return this.emailSections
        .filter(section => this.isEmailSectionActive(section))
        .map(section =>
          this.$t(
            `email_section.${section}`,
            section === 'recommendation'
              ? [this.campaign.email_design.recommendations_count]
              : []
          )
        )
        .join(', ');
    },
    hasActiveCoupons() {
      return this.campaign.selected_coupons.some(
        c => c.coupon_status === 'active'
      );
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openLandingPageDialog() {
      this.openDialog([
        'TargetDmCampaignSettingsDesignLandingPageDialog',
        { campaign: this.campaign }
      ]);
    },
    openEmailDialog() {
      this.openDialog([
        'TargetDmCampaignSettingsDesignEmailDialog',
        { campaign: this.campaign }
      ]);
    },
    isLandingPageSectionActive(section) {
      const {
        display_brand_user_coupons,
        display_recommendation_items,
        recommendations_count
      } = this.campaign.landing_page_design;

      switch (section) {
        case 'coupon':
          return display_brand_user_coupons;
        case 'event':
          return display_brand_user_coupons && this.hasActiveCoupons;
        case 'recommendation':
          return display_recommendation_items && recommendations_count > 0;
        default:
          return true;
      }
    },
    isEmailSectionActive(section) {
      const {
        display_brand_user_coupons,
        display_recommendation_items,
        recommendations_count
      } = this.campaign.email_design;

      switch (section) {
        case 'coupon':
          return display_brand_user_coupons;
        case 'event':
          return this.hasActiveCoupons;
        case 'recommendation':
          return display_recommendation_items && recommendations_count > 0;
        default:
          return true;
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "landing_page": {
    "title": "랜딩페이지",
    "sub_title": "페이지 구성"
  },
  "email": {
    "title": "이메일",
    "sub_title": "이메일 구성",
    "disabled": "미발송"
  },
  "landing_page_section": {
    "cart": "장바구니",
    "coupon": "미사용 쿠폰",
    "event": "쿠폰/이벤트",
    "inactivation_info": "휴면 정보",
    "recommendation": "추천상품 {0}개",
    "unpaid_order": "미입금 주문"
  },
  "email_section": {
    "cart": "장바구니",
    "coupon": "미사용 쿠폰",
    "event": "쿠폰/이벤트",
    "inactivation_info": "휴면 정보",
    "recommendation": "추천상품 {0}개",
    "unpaid_order": "미입금 주문"
  }
}
</i18n>
