<template>
  <div>
    <AppButton
      class="ReviewSettingsBrandUserPropertyTypes__add-button"
      :label="$t('add_brand_user_property_type')"
      @click="addBrandUserPropertyType"
    />
    <AppTable
      table-id="review-settings-brand-user-property-types-table"
      :columns="COLUMNS"
      :rows="rows"
      :no-data="$t('no_data')"
    >
      <template #cell="{ row, column, rowIndex }">
        <template v-if="column.id === 'name'">
          <ul>
            <li class="table-line">
              <input type="hidden" :name="inputName('id')" :value="row.id" />
              <input
                type="hidden"
                :name="inputName('global_review_option_type_id')"
                :value="row.global_review_option_type_id"
              />
              <AppSelectFilterable
                :value="row.global_review_option_type_id"
                :options="userPropertyTypeOptions"
                :placeholder="$t('placeholder.user_property_type')"
                @change="changeGlobalReviewOptionTypeId(row.id, $event)"
              />
            </li>
            <li class="table-line--mt4">
              <AppTextInput
                :ref="`textInput${rowIndex}`"
                :value="row.name"
                :name="inputName('name')"
                :invalid="!!error(row.id, 'name')"
                :placeholder="$t('placeholder.name')"
                :maxlength="30"
                @change="changeName(row.id, $event)"
              />
              <AppFormError :error="error(row.id, 'name')" />
            </li>
          </ul>
        </template>
        <template v-else-if="column.id === 'options'">
          <ul>
            <li class="table-line">
              <input
                type="hidden"
                :name="inputName('field_type')"
                :value="row.field_type"
              />
              <AppSelect
                :value="row.field_type"
                class="ReviewSettingsBrandUserPropertyTypes__flex"
                :disabled="!!row.global_review_option_type_id"
                :options="ReviewOptionFieldType.options()"
                @change="changeFieldType(row.id, $event)"
              />
            </li>
            <li class="table-line--mt4">
              <template v-if="row.field_type === ReviewOptionFieldType.NUMBER">
                <div class="ReviewSettingsBrandUserPropertyTypes__flex">
                  <i18n path="search_range_in_number">
                    <AppNumberInput
                      slot="min"
                      :value="row.min"
                      :name="inputName('min')"
                      :invalid="!!error(row.id, 'min')"
                      :digits="3"
                      @change="changeMin(row.id, $event)"
                    />
                    <AppNumberInput
                      slot="max"
                      :value="row.max"
                      :name="inputName('max')"
                      :invalid="!!error(row.id, 'max')"
                      :digits="3"
                      @change="changeMax(row.id, $event)"
                    />
                    <AppNumberInput
                      slot="interval"
                      :value="row.interval"
                      :name="inputName('interval')"
                      :invalid="!!error(row.id, 'interval')"
                      :digits="2"
                      @change="changeInterval(row.id, $event)"
                    />
                    <AppNumberInput
                      slot="display_interval"
                      :value="row.display_interval"
                      :name="inputName('display_interval')"
                      :invalid="!!error(row.id, 'display_interval')"
                      :digits="2"
                      @change="changeDisplayInterval(row.id, $event)"
                    />
                  </i18n>
                </div>
              </template>
              <template
                v-else-if="row.field_type === ReviewOptionFieldType.SELECT"
              >
                <template v-if="row.global_review_option_type_id">
                  <AppMultipleSelect
                    :value="row.option_values"
                    :options="
                      userPropertyTypesMap[row.global_review_option_type_id]
                        .options
                    "
                    :name="`${inputName('option_values')}[]`"
                    :invalid="!!error(row.id, 'option_values')"
                    @change="changeOptionValues(row.id, $event)"
                  />
                </template>
                <template v-else>
                  <AppTags
                    :tags="row.option_values"
                    :name="`${inputName('option_values')}[]`"
                    :placeholder="$t('placeholder.option_values')"
                    :invalid="!!error(row.id, 'option_values')"
                    @change="changeOptionValues(row.id, $event)"
                  />
                </template>
              </template>
            </li>
            <li>
              <AppFormError
                :error="
                  error(row.id, 'min') ||
                    error(row.id, 'max') ||
                    error(row.id, 'interval') ||
                    error(row.id, 'display_interval') ||
                    error(row.id, 'option_values')
                "
              />
            </li>
          </ul>
        </template>
        <template v-else-if="column.id === 'unit'">
          <AppTextInput
            :value="row.unit"
            :name="inputName('unit')"
            :disabled="row.field_type === ReviewOptionFieldType.TEXT"
            @change="changeUnit(row.id, $event)"
          />
        </template>
        <template v-else-if="column.id === 'default'">
          <input
            type="hidden"
            :name="inputName('default')"
            :value="row.selected"
          />
          <AppRadio
            :value="row.id"
            :selected-value="selectedType.id"
            :disabled="row.field_type === ReviewOptionFieldType.TEXT"
            input-style="standalone"
            @change="changeDefault(rowIndex)"
          />
        </template>
        <template v-else-if="column.id === 'storable'">
          <AppCheckbox
            :checked="row.storable"
            :name="inputName('storable')"
            input-style="standalone"
            @change="changeStorable(row.id, $event)"
          />
        </template>
        <template v-else-if="column.id === 'searchable'">
          <AppCheckbox
            :checked="row.searchable"
            :name="inputName('searchable')"
            input-style="standalone"
            @change="changeSearchable(row.id, $event)"
          />
        </template>
        <template v-else-if="column.id === 'displayable'">
          <AppCheckbox
            :checked="row.displayable"
            :name="inputName('displayable')"
            input-style="standalone"
            @change="changeDisplayable(row.id, $event)"
          />
        </template>
        <template v-else-if="column.id === 'required'">
          <AppCheckbox
            :checked="row.required"
            :name="inputName('required')"
            input-style="standalone"
            @change="changeRequired(row.id, $event)"
          />
        </template>
        <template v-else-if="column.id === 'actions'">
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            @click="deleteBrandUserPropertyType(row.id)"
          />
        </template>
      </template>
    </AppTable>
    <template v-if="brandUserPropertyTypes.length === 0">
      <input
        type="hidden"
        name="product_category_product_type[brand_user_property_types_attributes][]"
      />
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import FormView from '@/mixins/FormView';
import ReviewOptionFieldType from '@/enums/ReviewOptionFieldType';

export default {
  name: 'ReviewSettingsBrandUserPropertyTypes',
  mixins: [FormView],
  model: { prop: 'brandUserPropertyTypes', event: 'change' },
  props: {
    brandUserPropertyTypes: { type: Array, required: true },
    userPropertyTypes: { type: Array, required: true },
    errors: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      COLUMNS: [
        ...[
          'name',
          'options',
          'unit',
          'default',
          'storable',
          'searchable',
          'displayable',
          'required'
        ].map(c => ({
          id: c,
          label: this.$t(`brand_user_property_types.${c}`)
        })),
        {
          id: 'actions',
          label: this.$t('app.actions')
        }
      ]
    };
  },
  computed: {
    ReviewOptionFieldType() {
      return ReviewOptionFieldType;
    },
    userPropertyTypeOptions() {
      return this.userPropertyTypes.map(t => ({
        label: t.name,
        value: t.id,
        disabled: this.brandUserPropertyTypes.some(
          e => e.global_review_option_type_id === t.id
        )
      }));
    },
    rows() {
      return this.brandUserPropertyTypes;
    },
    userPropertyTypesMap() {
      return _.keyBy(this.userPropertyTypes, 'id');
    },
    selectedType() {
      return this.brandUserPropertyTypes.find(t => t.selected) || {};
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    inputName(field) {
      return `product_category_product_type[brand_user_property_types_attributes][][${field}]`;
    },
    error(id, field) {
      return this.errors[`brand_user_property_types[${id}][${field}]`];
    },
    addBrandUserPropertyType() {
      this.$emit('change', [
        ...this.brandUserPropertyTypes,
        {
          id: _.uniqueId('brand_user_property_type_id'),
          field_type: ReviewOptionFieldType.TEXT,
          selected: false
        }
      ]);
      this.$nextTick(() =>
        this.$refs[`textInput${this.rows.length - 1}`].$el.focus()
      );
    },
    changeGlobalReviewOptionTypeId(id, global_review_option_type_id) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e => {
          if (e.id !== id) return e;

          if (global_review_option_type_id) {
            const optionType = this.userPropertyTypesMap[
              global_review_option_type_id
            ];
            return {
              id: e.id,
              field_type: optionType.field_type,
              unit: optionType.unit,
              storable: optionType.storable,
              min: optionType.min,
              max: optionType.max,
              name: optionType.name,
              interval: optionType.interval,
              display_interval: optionType.display_interval,
              displayable: optionType.displayable,
              required: optionType.required,
              searchable: optionType.searchable,
              option_values: [],
              global_review_option_type_id,
              selected: e.selected
            };
          } else {
            return {
              ...e,
              option_values: [],
              global_review_option_type_id: null
            };
          }
        })
      );
      this.$nextTick(() => this.resetSelected());
    },
    resetSelected() {
      if (
        !_.isEmpty(this.selectedType) &&
        this.selectedType.field_type !== ReviewOptionFieldType.TEXT
      )
        return;

      const selectIdx = this.brandUserPropertyTypes.findIndex(
        e => e.field_type !== ReviewOptionFieldType.TEXT
      );
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map((e, i) => ({
          ...e,
          selected: i === selectIdx
        }))
      );
    },
    changeName(id, name) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e => (e.id === id ? { ...e, name } : e))
      );
      this.validateField(id, 'name');
    },
    changeFieldType(id, field_type) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e =>
          e.id === id ? { ...e, field_type } : e
        )
      );
      this.$nextTick(() => this.resetSelected());
    },
    changeMin(id, min) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e => (e.id === id ? { ...e, min } : e))
      );
      this.validateMinMax(id);
    },
    changeMax(id, max) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e => (e.id === id ? { ...e, max } : e))
      );
      this.validateMinMax(id);
    },
    changeInterval(id, interval) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e =>
          e.id === id ? { ...e, interval } : e
        )
      );
      this.validateField(id, 'interval');
    },
    changeDisplayInterval(id, display_interval) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e =>
          e.id === id ? { ...e, display_interval } : e
        )
      );
      this.validateField(id, 'display_interval');
    },
    changeOptionValues(id, option_values) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e =>
          e.id === id ? { ...e, option_values } : e
        )
      );
      this.validateField(id, 'option_values');
    },
    changeUnit(id, unit) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e => (e.id === id ? { ...e, unit } : e))
      );
    },
    changeDefault(rowIndex) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map((e, i) => ({
          ...e,
          selected: i === rowIndex
        }))
      );
    },
    changeStorable(id, storable) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e =>
          e.id === id ? { ...e, storable } : e
        )
      );
    },
    changeSearchable(id, searchable) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e =>
          e.id === id ? { ...e, searchable } : e
        )
      );
    },
    changeDisplayable(id, displayable) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e =>
          e.id === id ? { ...e, displayable } : e
        )
      );
    },
    changeRequired(id, required) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.map(e =>
          e.id === id ? { ...e, required } : e
        )
      );
    },
    deleteBrandUserPropertyType(id) {
      this.$emit(
        'change',
        this.brandUserPropertyTypes.filter(e => e.id !== id)
      );
    },
    validateMinMax(id) {
      this.validateField(id, 'min');
      this.validateField(id, 'max');
    },
    validateField(id, field) {
      this.$emit(
        'validate-field',
        `brand_user_property_types[${id}][${field}]`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewSettingsBrandUserPropertyTypes__add-button {
  margin-bottom: 12px;
}

.ReviewSettingsBrandUserPropertyTypes__flex {
  display: flex;
}

::v-deep {
  .review-settings-brand-user-property-types-table__name {
    min-width: 191px;
  }

  .review-settings-brand-user-property-types-table__unit,
  .review-settings-brand-user-property-types-table__storable,
  .review-settings-brand-user-property-types-table__searchable,
  .review-settings-brand-user-property-types-table__displayable,
  .review-settings-brand-user-property-types-table__required {
    min-width: 68px;
    vertical-align: middle;
  }

  .review-settings-brand-user-property-types-table__selected {
    width: 108px;
    vertical-align: middle;
  }

  .review-settings-brand-user-property-types-table__options {
    min-width: 438px;
  }

  .review-settings-brand-user-property-types-table__actions {
    width: 78px;
    vertical-align: middle;
  }
}
</style>

<i18n locale="ko">
{
  "option_label": "고객 속성명",
  "placeholder": {
    "user_property_type": "항목 선택",
    "name": "표시 정보명",
    "option_values": "선택 항목을 입력해주세요."
  },
  "brand_user_property_types" : {
    "name": "고객 속성 및 표시 정보명",
    "options": "입력 방식 및 입력 값",
    "unit": "사용 단위",
    "default": "대표 속성 지정",
    "storable": "저장 여부",
    "searchable": "검색 허용",
    "displayable": "위젯 노출",
    "required": "필수 입력"
  },
  "search_range_in_number": "{min} 부터 {max} 까지 {interval} 단위로 검색 {display_interval} 단위로 표시",
  "add_brand_user_property_type": "고객 속성 추가",
  "edit_review_user_property_type_options": "문구 수정",
  "no_data": "설정된 고객 속성이 없습니다."
}
</i18n>
