<template>
  <div class="ReviewDashboardCustomerInput">
    <AppInfoBox class="ReviewDashboardCustomerInput__info-box">
      <span class="ReviewDashboardCustomerInput__info-box-label"
        >{{ $t('tip.label') }}
      </span>
      <span class="ReviewDashboardCustomerInput__info-box-content">
        <span>{{ $t('tip.content_segment1') }}</span>
        <AppButton
          :label="$t('tip.content_segment2')"
          button-style="underline"
          @click="openCustomerCategoryDialog"
        />
        <span>{{ $t('tip.content_segment3') }}</span>
      </span>
    </AppInfoBox>
    <AppForm v-bind="formProps" v-on="formEvents">
      <template #group="{ id }">
        <template v-if="id === 'option_info'">
          <AppBadge
            v-for="(item, index) in optionTypes"
            :key="index"
            :label="item"
            badge-style="grey-outline"
          />
        </template>
      </template>
    </AppForm>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FormView from '@/mixins/FormView';

export default {
  name: 'ReviewDashboardCustomerInput',
  mixins: [FormView],
  data() {
    return {
      optionTypes: [
        '키',
        '몸무게',
        '평소사이즈-상의',
        '소재(촉감)',
        '사이즈 한줄평',
        '사이즈 어때요?',
        '색상(밝기)',
        '두께감'
      ]
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              type: 'static',
              label: this.$t('basic_info.label'),
              value: this.$t('basic_info.value')
            },
            {
              id: 'option_info',
              label: this.$t('option_info.label')
            },
            {
              type: 'button',
              label: this.$t('popup_preview.label'),
              groupDescription: this.$t('popup_preview.description'),
              value: this.$t('popup_preview.value'),
              clickHandler: () => {}
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openCustomerCategoryDialog() {}
  }
};
</script>

<style lang="scss">
@import '@/scss/vars/_colors.scss';

.ReviewDashboardCustomerInput__info-box {
  text-align: left !important;
  padding: 8px 16px !important;
  margin-bottom: 16px;
}

.ReviewDashboardCustomerInput__info-box-label {
  font-weight: bold;
  color: $color-blue;
}

.ReviewDashboardCustomerInput__info-box-content {
  margin-left: 4px;
  display: inline-block;
}

.ReviewDashboardCustomerInput__info-box-content .AppButton {
  vertical-align: unset;
}
</style>

<i18n locale="ko">
  {
    "tip": {
      "label": "Tip.",
      "content_segment1":"추가 정보는 입력한 쇼핑몰 카테고리 정보를 바탕으로 추가 정보를 셋팅했습니다. 추가 정보를 변경하고 싶다면 ",
      "content_segment2":"쇼핑몰 카테고리 정보를 변경",
      "content_segment3":"해보세요."
    },
    "basic_info": {
      "label": "기본 정보",
      "value": "리뷰 본문, 별점, 첨부 이미지/동영상"
    },
    "option_info": {
      "label": "추가 정보"
    },
    "popup_preview": {
      "label": "작성 팝업 미리보기",
      "description": "작성 팝업에서 고객이 입력하는 정보를 어떻게 수집하는지 확인해보세요.",
      "value": "작성 팝업 미리보기"
    }
  }
</i18n>
