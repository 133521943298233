<template>
  <div class="UiTransitionMain">
    <div class="ui-section">
      <div class="ui-section__title">fade</div>
      <div class="ui-section__item">
        <AppButton label="Toggle" @click="showFade = !showFade" />
      </div>
      <div class="ui-section__item">
        <Transition name="fade">
          <AppContainer v-show="showFade">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            auctor condimentum urna. Nam condimentum ultrices sagittis. Donec
            viverra tortor nisi, tincidunt sodales neque lacinia at.
          </AppContainer>
        </Transition>
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">fade out-in</div>
      <div class="ui-section__item">
        <AppButton label="Toggle" @click="showOutIn = !showOutIn" />
      </div>
      <div class="ui-section__item">
        <Transition name="fade" mode="out-in">
          <AppContainer :key="showOutIn ? '1' : '2'">
            {{
              showOutIn
                ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse auctor condimentum urna.'
                : 'Nam condimentum ultrices sagittis. Donec viverra tortor nisi, tincidunt sodales neque lacinia at.'
            }}
          </AppContainer>
        </Transition>
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">crossfade</div>
      <div class="ui-section__item">
        <AppButton label="Toggle" @click="showCrossfade = !showCrossfade" />
      </div>
      <div class="ui-section__item">
        <TransitionCrossfade
          :items="[
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse auctor condimentum urna.',
            'Nam condimentum ultrices sagittis. Donec viverra tortor nisi, tincidunt sodales neque lacinia at.'
          ]"
          :visible-index="showCrossfade ? 0 : 1"
        >
          <template #default="{ item }">
            <AppContainer>
              {{ item }}
            </AppContainer>
          </template>
        </TransitionCrossfade>
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">float</div>
      <div class="ui-section__item">
        <AppButton label="Toggle" @click="showFloat = !showFloat" />
      </div>
      <div class="ui-section__item">
        <Transition name="float">
          <AppContainer v-show="showFloat">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            auctor condimentum urna. Nam condimentum ultrices sagittis. Donec
            viverra tortor nisi, tincidunt sodales neque lacinia at.
          </AppContainer>
        </Transition>
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">basic-fade</div>
      <div class="ui-section__item">
        <AppButton label="Toggle" @click="showBasicFade = !showBasicFade" />
      </div>
      <div class="ui-section__item">
        <Transition name="basic-fade">
          <AppContainer v-show="showBasicFade">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            auctor condimentum urna. Nam condimentum ultrices sagittis. Donec
            viverra tortor nisi, tincidunt sodales neque lacinia at.
          </AppContainer>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionCrossfade from '@/transitions/TransitionCrossfade';

export default {
  name: 'UiTransitionMain',
  components: { TransitionCrossfade },
  data() {
    return {
      showFade: true,
      showOutIn: true,
      showFloat: true,
      showBasicFade: true,
      showCrossfade: true
    };
  }
};
</script>
