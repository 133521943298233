import { constructEnum } from '@/lib/enum';

const WidgetInsertType = constructEnum('WidgetInsertType', {
  BEFORE: 0,
  APPEND: 1,
  PREPEND: 2,
  AFTER: 3,
  REPLACE: 4
});

WidgetInsertType.displayOrder = [
  WidgetInsertType.BEFORE,
  WidgetInsertType.REPLACE,
  WidgetInsertType.PREPEND,
  WidgetInsertType.APPEND,
  WidgetInsertType.AFTER
];

export default WidgetInsertType;
