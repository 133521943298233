import { constructEnum } from '@/lib/enum';
import TargetCampaignType from './TargetCampaignType';
import TargetCampaignMediaType from './TargetCampaignMediaType';

const TargetCampaignChartType = constructEnum('TargetCampaignChartType', {
  INACTIVATION_PREVENTION_RATE: 10,
  CHECK_RATE: 20,
  CHECK_AND_CLICK_RATE: 30,
  UNSUBSCRIPTION_RATE: 40,
  DISPATCH_COUNT: 50,
  REVISIT_RATE: 60,
  PURCHASE_RATE: 70,
  REVIEW_RATE: 80,
  PAY_RATE: 90,
  CLICK_PER_CHECK: 100,
  UNIQUE_CHECK_COUNT: 110,
  SIGNUP_RATE: 120,
  REACH_RATE: 130
});

TargetCampaignChartType.kpi = campaignType => {
  switch (campaignType) {
    case TargetCampaignType.NEW_VISITOR_SIGNUP:
      return TargetCampaignChartType.SIGNUP_RATE;
    case TargetCampaignType.NEW_MEMBER_REENGAGEMENT:
    case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
      return TargetCampaignChartType.INACTIVATION_PREVENTION_RATE;
    case TargetCampaignType.CART_REMINDING:
    case TargetCampaignType.INTERESTED_PRODUCTS:
    case TargetCampaignType.INDUCING_REPURCHASE:
    case TargetCampaignType.COUPON_EXPIRY:
    case TargetCampaignType.SELECTED_PRODUCTS:
    case TargetCampaignType.SELECTED_CATEGORIES:
    case TargetCampaignType.UTM_POPUP:
    case TargetCampaignType.PRODUCT_RECOMMENDATION_DETAIL_PAGE:
    case TargetCampaignType.PRODUCT_RECOMMENDATION_CATEGORY_PAGE:
    case TargetCampaignType.PRODUCT_RECOMMENDATION_MAIN_PAGE:
    case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_PAGE:
    case TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT:
    case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_POPUP:
      return TargetCampaignChartType.PURCHASE_RATE;
    case TargetCampaignType.UNPAID_ORDER_REMINDING:
      return TargetCampaignChartType.PAY_RATE;
    case TargetCampaignType.INACTIVE_MEMBER_REENGAGEMENT:
      return TargetCampaignChartType.REVISIT_RATE;
    case TargetCampaignType.MILEAGE_EXPIRING:
      return TargetCampaignChartType.REVIEW_RATE;
  }
};

TargetCampaignChartType.response = (campaignType, campaignMediaType) => {
  return campaignMediaType === TargetCampaignMediaType.DIRECT_MESSAGE
    ? campaignType === TargetCampaignType.MILEAGE_EXPIRING
      ? TargetCampaignChartType.CHECK_RATE
      : TargetCampaignChartType.CHECK_AND_CLICK_RATE
    : campaignMediaType === TargetCampaignMediaType.WIDGET
    ? TargetCampaignChartType.CLICK_PER_CHECK
    : campaignMediaType === TargetCampaignMediaType.POPUP
    ? TargetCampaignChartType.CLICK_PER_CHECK
    : null;
};

export default TargetCampaignChartType;
