<template>
  <div class="AppCodeMirror">
    <textarea
      ref="textarea"
      v-bind="{ id, name, value }"
      class="AppCodeMirror__textarea"
      @focus="editor.focus()"
    />
  </div>
</template>

<script>
import CodeMirror from 'codemirror';
import TextInput from '@/mixins/TextInput';

export default {
  name: 'AppCodeMirror',
  mixins: [TextInput],
  props: { options: { type: Object, required: true } },
  data: () => ({ editor: null }),
  computed: {
    readOnly() {
      return this.disabled ? 'nocursor' : false;
    }
  },
  watch: {
    value() {
      if (this.currentValue !== this.editor.getValue()) {
        this.editor.setValue(this.currentValue);

        const { left, top } = this.editor.getScrollInfo();
        this.editor.scrollTo(left, top);
      }
    },
    disabled() {
      this.editor.setOption('readOnly', this.readOnly);
    }
  },
  mounted() {
    const { textarea } = this.$refs;
    const options = this.options;
    const editor = CodeMirror.fromTextArea(textarea, {
      ...options,
      readOnly: this.readOnly
    });
    textarea.style.display = ''; // For label focus
    this.editor = editor;
    editor.setValue(this.value || '');

    editor.on('change', () => this.change(editor.getValue()));
    editor.on('beforeChange', (_, change) =>
      this.$emit('before-change', change)
    );
    this.$emit('ready', editor);
  },
  beforeDestroy() {
    const element = this.editor.doc.cm.getWrapperElement();
    element?.remove?.();
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  @import 'codemirror/lib/codemirror';

  .CodeMirror {
    background: transparent;
    height: auto;
  }

  .CodeMirror-gutters {
    background-color: transparent;
  }
}

.AppCodeMirror__textarea {
  display: none;
}
</style>
