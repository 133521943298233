<template>
  <div class="SmartstoreProductMatch">
    <SmartstoreSummaryBox />
    <BatchJobForm
      v-bind="{ batchJobType }"
      title
      :description-html="uploadDescription"
      sample-file-url="/csv/smartstore-product-match-v4.csv"
      :success-message="$t('batch_job_started')"
      :download-label="$t('download_label')"
      filename="smartstore-product-match-v4.csv"
      :upload-state-hint="uploadStateHint"
      :disabled="uploadDisabled"
      @submitted="submitted"
    >
      <template #additional-buttons>
        <a
          class="SmartstoreProductMatch__file_upload_guide"
          @click="openProductMatchGuide"
        >
          {{ $t('smartstore.file_upload_guide.label') }}
        </a>
      </template>
    </BatchJobForm>
    <AppForm
      v-if="smartstoreStatus !== SmartstoreStatus.OPERATING"
      class="SmartstoreProductMatch__estimation_form"
      :sections="sections"
      :is-submitting="isSubmittingEstimation"
      :submit-button="{
        submitLabel: $t('estimation.submit'),
        submittingLabel: $t('estimation.submitting'),
        disabled: disabledSubmitEstimation,
        disabledTooltip: !smartstoreAccessAccepted
          ? $t('smartstore_invitation_pending.disabled_tooltip')
          : ''
      }"
      @submit="submitEstimation"
    >
      <template #group="{id}">
        <div v-if="id === 'smartstore_invitation_pending'">
          <div class="AppForm__group-field">
            <div>
              {{ $t('smartstore_invitation_pending.smartstore_name.title') }}
            </div>
            <AppButton
              class="SmartstoreProductMatch__smartstore_invitation_pending_button"
              :label="$t('smartstore_invitation_pending.smartstore_name.label')"
              @click="openSmartstoreNameDialog"
            />
          </div>
          <div class="AppForm__group-field">
            <div>
              {{ $t('smartstore_invitation_pending.invite_guide.title') }}
            </div>
            <AppButton
              class="SmartstoreProductMatch__smartstore_invitation_pending_button"
              :label="$t('smartstore_invitation_pending.invite_guide.label')"
              @click="openInviteGuideAlert"
            />
          </div>
          <div class="AppForm__group-description">
            {{ $t('smartstore_invitation_pending.hint_process_time') }}
          </div>
        </div>
      </template>
    </AppForm>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import api from '@/lib/api';
import BatchJobType from '@/enums/BatchJobType';
import BatchJobForm from '@/views/components/BatchJobForm';
import DialogSize from '@/enums/DialogSize';
import SmartstoreStatus from '@/enums/SmartstoreStatus';
import SmartstoreSummaryBox from './SmartstoreSummaryBox';

export default {
  name: 'SmartstoreProductMatch',
  components: { BatchJobForm, SmartstoreSummaryBox },
  data() {
    return { isSubmittingEstimation: false };
  },
  computed: {
    ...mapState(['brandParams']),
    ...mapState('batchJob', ['batchJobs']),
    ...mapState('smartstore', [
      'smartstoreStatus',
      'smartstoreAccessAccepted',
      'smartstoreName'
    ]),
    SmartstoreStatus: () => SmartstoreStatus,
    sections() {
      return [
        {
          groups: [
            {
              id: 'sync_months',
              name: 'smartstore[sync_months]',
              label: this.$t('sync_months.title'),
              type: 'select_button',
              groupDescription: this.$t('sync_months.hint'),
              value: 12,
              options: [
                { label: '1개월', value: 1 },
                { label: '3개월', value: 3 },
                { label: '6개월', value: 6 },
                { label: '12개월', value: 12 }
              ]
            },
            ...(!this.smartstoreAccessAccepted
              ? [
                  {
                    id: 'smartstore_invitation_pending',
                    label: this.$t('smartstore_invitation_pending.title'),
                    groupDescription: this.$t(
                      'smartstore_invitation_pending.hint_requirement'
                    )
                  }
                ]
              : [])
          ]
        }
      ];
    },
    batchJobType() {
      return BatchJobType.SMARTSTORE_PRODUCT_MATCH;
    },
    uploadStateHint() {
      if (this.smartstoreStatus === SmartstoreStatus.PRODUCT_MATCH_STARTED)
        return {
          message: this.$t('upload_state_message.started'),
          type: 'started'
        };
      if (this.smartstoreStatus === SmartstoreStatus.PRODUCT_MATCH_FAILED)
        return {
          message: this.$t('upload_state_message.failed'),
          type: 'failed',
          detailLabel: this.$t('upload_state_message.failed_detail'),
          detailClickHandler: this.openBatchJobSmartstoreProductMatchDialog
        };
      if (this.smartstoreStatus === SmartstoreStatus.PRODUCT_MATCH_FINISHED)
        return {
          message: this.$t('upload_state_message.finished')
        };
      return null;
    },
    uploadDescription() {
      return this.smartstoreStatus === SmartstoreStatus.OPERATING
        ? this.$t('upload_description.operating')
        : this.$t('upload_description.preparing');
    },
    uploadDisabled() {
      return this.smartstoreStatus === SmartstoreStatus.PRODUCT_MATCH_STARTED;
    },
    disabledSubmitEstimation() {
      return (
        this.smartstoreStatus !== SmartstoreStatus.PRODUCT_MATCH_FINISHED ||
        !this.smartstoreAccessAccepted
      );
    }
  },
  watch: {
    smartstoreStatus() {
      if (this.smartstoreStatus === SmartstoreStatus.PRODUCT_MATCH_STARTED) {
        this.SET_POLL_PARAMS({ key: 'smartstore_status', value: '1' });
      } else this.UNSET_POLL_PARAMS('smartstore_status');
      if (this.smartstoreStatus === SmartstoreStatus.PRODUCT_MATCH_FAILED) {
        this.fetchSmartstoreBatchJobs();
      }
    },
    smartstoreAccessAccepted() {
      if (!this.smartstoreAccessAccepted) {
        this.SET_POLL_PARAMS({ key: 'smartstore_access_accepted', value: '1' });
      } else this.UNSET_POLL_PARAMS('smartstore_access_accepted');
    }
  },
  created() {
    if (this.smartstoreStatus === SmartstoreStatus.PRODUCT_MATCH_STARTED) {
      this.SET_POLL_PARAMS({ key: 'smartstore_status', value: '1' });
    }
    if (!this.smartstoreAccessAccepted) {
      this.SET_POLL_PARAMS({ key: 'smartstore_access_accepted', value: '1' });
    }
    if (this.smartstoreStatus === SmartstoreStatus.PRODUCT_MATCH_FAILED) {
      this.fetchSmartstoreBatchJobs();
    }
  },
  beforeDestroy() {
    this.UNSET_POLL_PARAMS('smartstore_status');
    this.UNSET_POLL_PARAMS('smartstore_access_accepted');
  },
  methods: {
    ...mapActions('batchJob', ['fetchBatchJobs']),
    ...mapActions('dialog', ['alert', 'openDialog']),
    ...mapActions('smartstore', [
      'fetchSmartstoreStatus',
      'openProductMatchGuide'
    ]),
    ...mapMutations('smartstore', [
      'SET_SMARTSTORE_STATUS',
      'SET_SMARTSTORE_NAME'
    ]),
    ...mapMutations('poll', ['SET_POLL_PARAMS', 'UNSET_POLL_PARAMS']),
    fetchSmartstoreBatchJobs() {
      const { brand_id } = this.brandParams;
      this.fetchBatchJobs({
        brand_id,
        batch_job_type: BatchJobType.SMARTSTORE_PRODUCT_MATCH
      });
    },
    submitted() {
      this.fetchSmartstoreStatus();
    },
    openBatchJobSmartstoreProductMatchDialog() {
      const batchJobId = this.batchJobs.items[0]?.id;
      if (!batchJobId) {
        this.alert({
          title: 'error',
          message: this.$t('upload_state_message.failed_detail_error')
        });
        return;
      }

      this.openDialog([
        'ProductBatchJobSmartstoreProductMatchDialog',
        { batchJobId }
      ]);
    },
    openSmartstoreNameDialog() {
      this.openDialog([
        'TheSmartstoreNameDialog',
        { smartstoreName: this.smartstoreName }
      ]).then(eventBus => {
        eventBus.$on('save', smartstore_name => {
          this.SET_SMARTSTORE_NAME(smartstore_name);
        });
      });
    },
    openInviteGuideAlert() {
      this.alert({
        title: this.$t(
          'smartstore_invitation_pending.invite_guide.popup_title'
        ),
        message: this.$t(
          'smartstore_invitation_pending.invite_guide.popup_message'
        ),
        width: DialogSize.WIDE
      });
    },
    submitEstimation(formData) {
      this.isSubmittingEstimation = true;
      // 요청 보내기 확인 용로도 dummay end point로 요청
      api
        .post('/smartstore/estimations', formData, {
          successMessage: this.$t('estimation.success')
        })
        .then(({ data }) => {
          this.SET_SMARTSTORE_STATUS(data.smartstore_status);
          this.isSubmittingEstimation = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.SmartstoreProductMatch {
  margin-top: 32px;
}

.SmartstoreProductMatch__file_upload_guide {
  color: $color-grey-40;
  margin-left: 16px;
  text-decoration: underline;
}

.SmartstoreProductMatch__estimation_form {
  margin-top: 32px;
}

.SmartstoreProductMatch__smartstore_invitation_pending_button {
  margin: 4px 0 4px 8px;
}
</style>

<i18n locale="ko">
{
  "title": "상품 매칭표 등록",
  "upload_description": {
    "preparing": "쇼핑몰과 스마트스토어 간 리뷰 연동을 위해 상품 매칭표 CSV 파일을 업로드하면 매칭표 기준으로 연동할 리뷰 계산을 시작합니다.<br>연동하고자 하는 모든 상품을 등록해주세요.",
    "operating": "상품 매칭표 CSV 파일을 추가로 업로드하면 등록 시점부터 새롭게 저장된 매일 리뷰를 가져옵니다.<br>상품 매칭표에 등록된 상품의 과거 리뷰는 연동되지 않습니다."
  },
  "batch_job_started": "상품 매칭표 등록을 시작했습니다.",
  "download_label": "상품 매칭표 예시 CSV 파일 다운로드",
  "sync_months": {
    "title": "과거 리뷰 연동 기간 선택",
    "hint": "신청일로부터 최대 12개월까지의 과거 리뷰를 가져올 수 있습니다.<br>매일 리뷰 연동 기간은 리뷰수 계산 후 결제 페이지에서 선택 가능합니다."
  },
  "smartstore_invitation_pending": {
    "title": "✋ 잠깐! 스마트스토어 대행사 권한이 완료되지 않았습니다.",
    "hint_requirement": "스마트스토어 리뷰 연동 기능 사용 위해서는 스마트스토어에서 사용하는 스토어 이름과 동일한 이름으로 신청해야하며, 크리마를 귀사의 스마트스토어 대행사로 추가해주셔야 합니다.",
    "hint_process_time": "초대를 완료하였다면 영업일 기준 1일 내로 처리됩니다.",
    "disabled_tooltip": "권한 초대가 완료되지 않았습니다.\n대행사 초대를 완료한 다음에 리뷰수를 계산할 수 있습니다.",
    "smartstore_name": {
      "title": "1. 신청한 스마트스토어 이름 확인하기",
      "label": "스마트스토어 이름 확인하기"
    },
    "invite_guide": {
      "title": "2. 대행사 초대하기",
      "label": "초대방법 자세히 보기",
      "popup_title": "스마트스토어 대행사 초대 방법",
      "popup_message": "1. 스마트스토어센터 관리자 페이지 : 판매자 정보 > 매니저 관리\n2. 매니저 관리 화면 오른쪽 상단의 매니저 초대 클릭\n3. 크리마(010-3437-6008)를 [대행사] 권한으로 초대하기\n\n![](https://assets.cre.ma/m/v1/smartstore_invite.png)"
    }
  },
  "estimation": {
    "submit": "리뷰 수 계산",
    "submitting": "리뷰 수 계산 시작중...",
    "success": "리뷰 수 계산이 시작됐습니다."
  },
  "upload_state_message": {
    "started": "상품 매칭표 업로드중입니다.",
    "failed": "상품 매칭표 등록에 실패했습니다. 오류내역 수정 후 전체 상품 매칭표 파일을 재등록해주세요.",
    "failed_detail": "오류내역 자세히 보기",
    "failed_detail_error": "오류내역를 불러오는데 실패했습니다.",
    "finished": "상품 매칭표 업로드가 완료됐습니다."
  }
}
</i18n>
