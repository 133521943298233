<template>
  <div class="TargetDashboard">
    <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
      <AppInfoBox class="TargetDashboard__info-box"
        >{{
          $t('description', [
            formatDateTimeWithoutSecond(dashboard.updated_at),
            formatDateTimeWithoutSecond(dashboard.recalculation_time)
          ])
        }}
      </AppInfoBox>
      <AppGrid>
        <div
          v-for="(data, id) in dashboard.data"
          :key="id"
          class="AppGrid__col AppGrid__col--3 AppGrid__col--tablet-6 TargetDashboard__stat-card"
        >
          <AppStatCard
            :title="$t(id)"
            :stat="calculateRate(data)"
            :detail="$t('unit.last_days', [data.day])"
            is-clickable
            @click="clickShowStatDetail(id)"
          />
        </div>
      </AppGrid>
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import { mapActions } from 'vuex';
import TargetContentView from '@/mixins/TargetContentView';

export default {
  name: 'TargetDashboard',
  mixins: [TargetContentView],
  data: () => ({ isLoading: true, dashboard: {} }),
  mounted() {
    api
      .get('/target/dashboard_kpis')
      .then(({ data }) => (this.dashboard = data.dashboard))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    calculateRate({ first_indicator, second_indicator }) {
      return first_indicator && second_indicator
        ? first_indicator / second_indicator
        : 0;
    },
    clickShowStatDetail(id) {
      this.openDialog([
        'TargetDashboardStatDetailDialog',
        { id, keepOnRoute: true }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetDashboard__info-box {
  @include text-content;
  margin-bottom: 16px;
}

.TargetDashboard__stat-card {
  padding: 8px;
  min-width: 206px;
}
</style>

<i18n locale="ko">
{
  "description": "해당 지표들은 {0}에 계산했습니다. {1}에 재계산합니다.",
  "customer_new_visitor": "신규 방문자 비율",
  "customer_affiliate_conversion_rate": "가입 전환율",
  "customer_drop_out_rate": "가입 1개월 후 미방문률",
  "customer_long_term": "장기 미방문 회원 비율",
  "order_drop_out_rate": "관심 상품 구매 이탈률",
  "category_drop_out_rate": "관심 카테고리 구매 이탈률",
  "cart_purchase_conversion_rate": "장바구니 전환율",
  "bank_deposit_rate": "무통장 주문 입금률"
}
</i18n>
