<template>
  <AppSearchbar>
    <template #default>
      <li v-if="downloadExcelUrl">
        <AppButtonDownload
          button-style="grey"
          :label="$t('app.download_excel')"
          :url="downloadExcelUrl"
          :disabled="disabled"
        />
      </li>
    </template>

    <template #right>
      <li v-if="enableIntervalSearch">
        <AppSelectButtonInterval
          :value="interval"
          :disabled="disabled"
          @change="changeInterval"
        />
      </li>
      <li>
        <AppDateRangePicker
          v-model="dateRange"
          :min-date="minDate"
          :min-days="minDays"
          :disabled="disabled"
          @update="changeDateRange"
        />
      </li>
      <li v-if="enableAdvancedSearch">
        <AppButtonToggle
          v-model="isAdvancedSearchVisible"
          :label="$t('app.advanced_search')"
          :disabled="disabled"
        />
      </li>
    </template>

    <template #advanced-search>
      <AppAdvancedSearch
        v-model="resourceParams"
        :visible="isAdvancedSearchVisible"
        :sections="advancedSearchSections"
        @submit="$emit('submit')"
      >
        <template #group="{ id, inputId }">
          <div class="AppForm__group-field">
            <AppSelect
              :id="inputId"
              v-model="resourceParams.filter_type"
              :options="
                ['category', 'product'].map(value => ({
                  label: $t(`filter.${value}`),
                  value
                }))
              "
              :placeholder="$t('filter.all')"
              @change="changeFilter"
            />
          </div>
          <div
            v-if="resourceParams.filter_type === 'category'"
            class="AppForm__group-field"
          >
            <AppSelectProductCategory
              v-model="resourceParams.category_ids"
              packing-method="string"
              multiple
            />
          </div>
          <div
            v-else-if="resourceParams.filter_type === 'product'"
            class="AppForm__group-field"
          >
            <AppSelectProduct
              :products="products"
              packing-method="string"
              multiple
              @change="resourceParams.product_ids = $event"
            />
          </div>
        </template>
      </AppAdvancedSearch>
    </template>
  </AppSearchbar>
</template>

<script>
export default {
  name: 'AppReportsSearchbar',
  props: {
    downloadExcelUrl: { type: String, default: null },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    interval: { type: String, default: 'daily' },
    minDate: { type: String, default: null },
    minDays: { type: Number, default: null },
    enableAdvancedSearch: { type: Boolean, default: false },
    enableIntervalSearch: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    resourceParams: {
      type: Object,
      default() {
        return {};
      }
    },
    products: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      dateRange: {
        start_date: this.disabled ? null : this.startDate,
        end_date: this.disabled ? null : this.endDate
      },
      isAdvancedSearchVisible: 'filter_type' in this.$route.query
    };
  },
  computed: {
    advancedSearchSections() {
      return [
        {
          groups: [
            {
              id: 'filter_type',
              label: this.$t('filter.type')
            }
          ]
        }
      ];
    }
  },
  methods: {
    changeDateRange(dateRange) {
      this.$emit('change-date-range', dateRange);
    },
    changeInterval(interval) {
      this.$emit('change-interval', interval);
    },
    changeFilter() {
      if (this.resourceParams.filter_type !== 'category') {
        this.$delete(this.resourceParams, 'category_ids');
      }
      if (this.resourceParams.filter_type !== 'product') {
        this.$delete(this.resourceParams, 'product_ids');
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "filter": {
    "type": "통계 조건",
    "all": "전체",
    "category": "선택 카테고리 보기",
    "product": "선택 상품 보기"
  }
}
</i18n>
