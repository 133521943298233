<template>
  <AppModalForm
    :title="$t('title')"
    width="middle"
    :form-props="formProps"
    :is-loading="isLoading"
    v-on="formEvents"
  >
    <template #searchbar>
      <li>
        <AppButton
          button-style="red"
          :label="$t('add_keyword_group')"
          @click="addKeywordGroup"
        />
      </li>
    </template>
    <template #group="{ errors }">
      <AppTable
        table-id="review-keyword-groups-table"
        :columns="COLUMNS"
        :rows="keywordGroups"
        :no-data="$t('no_data')"
      >
        <template #cell="{ row, column, rowIndex }">
          <template v-if="column.id === 'name'">
            <input
              type="hidden"
              :value="keywordGroups[rowIndex].id"
              name="keyword_groups[][id]"
            />
            <AppTextInput
              :ref="rowIndex === 0 ? 'firstName' : null"
              v-model="keywordGroups[rowIndex].name"
              name="keyword_groups[][name]"
              :placeholder="$t('name_placeholder')"
              :invalid="!!errors[`keyword_groups[${row.id}][name]`]"
              @blur="validateKeyworGroupName(row, errors)"
              @change="validateKeyworGroupName(row, errors)"
            />
            <AppFormError :error="errors[`keyword_groups[${row.id}][name]`]" />
          </template>
          <template v-else-if="column.id === 'keywords'">
            <AppTags
              v-model="keywordGroups[rowIndex].keywords"
              name="keyword_groups[][keywords][]"
              :placeholder="$t('keywords_placeholder')"
              :invalid="!!errors[`keyword_groups[${row.id}][keywords]`]"
              @blur="validateField(`keyword_groups[${row.id}][keywords]`)"
              @change="validateField(`keyword_groups[${row.id}][keywords]`)"
            />
            <AppFormError
              :error="errors[`keyword_groups[${row.id}][keywords]`]"
            />
          </template>
          <template v-else-if="column.id === 'enable_notification'">
            <AppCheckbox
              v-model="keywordGroups[rowIndex].enable_notification"
              name="keyword_groups[][enable_notification]"
              input-style="standalone"
            />
          </template>
          <template v-else-if="column.id === 'actions'">
            <AppButton
              button-style="red-outline"
              :label="$t('app.delete')"
              @click="removeKeywordGroup(rowIndex)"
            />
          </template>
        </template>
      </AppTable>
      <input
        v-if="!keywordGroups.length"
        type="hidden"
        name="keyword_groups[]"
      />
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewKeywordGroupsDialog',
  mixins: [DialogFormView],
  data() {
    return {
      COLUMNS: [
        { id: 'name', label: this.$t('review_keyword_group.attributes.name') },
        {
          id: 'keywords',
          label: this.$t('review_keyword_group.attributes.keywords')
        },
        {
          id: 'enable_notification',
          label: this.$t('review_keyword_group.attributes.enable_notification')
        },
        { id: 'actions', label: this.$t('actions') }
      ],
      isLoading: true
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'keyword_groups',
              label: null,
              fields: _.flatten(
                this.keywordGroups.map(group => [
                  {
                    id: `keyword_groups[${group.id}][name]`,
                    value: () => group.name,
                    validate: [
                      'required',
                      {
                        rule: v => this.nameCountsMap[v] === 1,
                        errorMessage: this.$t('name_must_be_unique')
                      }
                    ]
                  },
                  {
                    id: `keyword_groups[${group.id}][keywords]`,
                    value: () => group.keywords,
                    validate: ['required']
                  }
                ])
              )
            }
          ]
        }
      ];
    },
    keywordGroups() {
      return this.formObject.keyword_groups || [];
    },
    nameCountsMap() {
      return _.countBy(this.keywordGroups, 'name');
    }
  },
  mounted() {
    api
      .get('/review/keyword_groups')
      .then(({ data }) => (this.orgFormObject = data))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('review', ['SET_KEYWORD_GROUPS']),
    addKeywordGroup() {
      this.keywordGroups.unshift({ name: '', keywords: [] });
      this.$nextTick(() => this.$refs.firstName.$el.focus());
    },
    removeKeywordGroup(index) {
      this.keywordGroups.splice(index, 1);
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/review/keyword_groups', formData, {
          successMessage: this.$t('saved_keywords')
        })
        .then(({ data }) => {
          this.SET_KEYWORD_GROUPS(data.keyword_groups);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    validateKeyworGroupName(group, errors) {
      this.validateField(`keyword_groups[${group.id}][name]`);
      Object.keys(errors).forEach(id => this.validateField(id));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .review-keyword-groups-table__name {
    width: 164px;
  }

  .review-keyword-groups-table__enable-notification {
    width: 44px;
    line-height: 0;
  }

  .review-keyword-groups-table__actions {
    width: 94px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "특정검색어 그룹 설정",
  "add_keyword_group": "그룹 추가",
  "no_data": "특정검색어 그룹이 없습니다.",
  "name_placeholder": "그룹 이름 입력",
  "name_must_be_unique": "같은 이름의 그룹이 있습니다.",
  "keywords_placeholder": "입력한 검색어를 포함하는 리뷰를 쉽게 확인할 수 있습니다.",
  "actions": "그룹 삭제",
  "saved_keywords": "특정검색어 그룹 설정을 저장했습니다."
}
</i18n>
