<template>
  <AppIntroContent
    document-url="https://assets.cre.ma/m/target/crema_target_introduction.pdf"
    :images="[
      { url: 'https://assets.cre.ma/m/admin/crema-target-intro-v4-01.png' }
    ]"
    :image-width="800"
  />
</template>

<script>
export default { name: 'TargetIntro' };
</script>
