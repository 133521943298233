var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'AppButton',
    _vm.size ? ("AppButton--" + _vm.size) : null,
    _vm.type === 'dropdown' ? 'dropdown' : null,
    {
      'AppButton--focusable': !_vm.unfocusable,
      'AppButton--activable': !_vm.unactivable
    }
  ]},[_c(_vm.disabled ? 'div' : _vm.buttonComponent,_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],tag:"Component",attrs:{"to":_vm.to}},'Component',_vm.type === 'link' ? { target: _vm.target } : {},false),[_c('button',{class:[
        'AppButton__button',
        ("AppButton__button--style-" + _vm.buttonStyle),
        _vm.size ? ("AppButton__button--" + _vm.size) : null,
        { 'AppButton__button--pushed': _vm.pushed } ].concat( _vm.buttonClasses
      ),attrs:{"id":_vm.id,"type":_vm.type === 'submit' ? 'submit' : 'button',"disabled":_vm.disabled,"tabIndex":_vm.unfocusable ? -1 : null},on:{"click":function($event){return _vm.$emit('click', $event)},"blur":function($event){return _vm.$emit('blur')}}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]}),(_vm.bubbleMessage)?_c('AppBubble',{attrs:{"side":_vm.bubbleSide},domProps:{"innerHTML":_vm._s(_vm.bubbleMessage)}}):_vm._e()],2)]),_vm._t("dropdown")],2)}
var staticRenderFns = []

export { render, staticRenderFns }