<template>
  <AppContainer class="AnalyticsInsightProductDialogReviewsCountGraph">
    <div class="AnalyticsInsightProductDialogReviewsCountGraph__title">
      {{ $t('insight.reviews_count') }}
    </div>
    <AppChart
      :options="chartOptions"
      :tooltip="chartTooltip"
      @tooltip="onChartTooltip"
    />
  </AppContainer>
</template>

<script>
import InsightChartView from '@/mixins/InsightChartView';

export default {
  name: 'AnalyticsInsightProductDialogReviewsCountGraph',
  mixins: [InsightChartView],
  props: {
    product: { type: Object, required: true },
    reviewsCountArray: { type: Array, required: true }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: 223,
          type: 'column',
          marginTop: 40
        },
        exporting: false,
        title: { text: '' },
        tooltip: { enabled: false },
        xAxis: { categories: this.xAxes },
        yAxis: {
          labels: { enabled: true },
          title: { enabled: false }
        },
        legend: {
          enabled: true,
          align: 'right',
          verticalAlign: 'top',
          layout: 'horizontal',
          itemDistance: 8,
          navigation: { enabled: false },
          padding: 0,
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 0
        },
        plotOptions: {
          series: {
            animation: false,
            stacking: 'normal',
            pointWidth: 16,
            pointPadding: 0,
            groupPadding: 0,
            events: {
              legendItemClick: function() {
                return false;
              }
            }
          }
        },
        series: [
          {
            name: this.$t('text_reviews_count'),
            data: this.reviewsCountArray.map(i => i[0])
          },
          {
            name: this.$t('media_reviews_count'),
            data: this.reviewsCountArray.map(i => i[1])
          }
        ]
      };
    }
  },
  methods: {
    onChartTooltip({ index, series }) {
      const [textReviewsCount, mediaReviewsCount] = series.chart.series.map(
        v => v.data[index].y
      );
      const reviewsCount = textReviewsCount + mediaReviewsCount;
      const mediaReviewsRatio = reviewsCount
        ? (mediaReviewsCount / reviewsCount) * 100
        : 0;

      this.chartTooltip = {
        title: this.getFormattedDate(this.xAxisValues[index]),
        items: [
          ...series.chart.series.map(({ data }, i) => ({
            symbol: 'square',
            name: i
              ? this.$t('media_reviews_count')
              : this.$t('text_reviews_count'),
            value: this.$t('unit.count', [data[index].y.toLocaleString()]),
            colorIndex: i
          })),
          {
            symbol: null,
            name: this.$t('insight.media_reviews_ratio'),
            value: this.$t('unit.percentage', [
              mediaReviewsRatio.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            ])
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightProductDialogReviewsCountGraph {
  margin-top: 16px;
  margin-bottom: 52px;
}

.AnalyticsInsightProductDialogReviewsCountGraph__title {
  @include text-label;
  margin-bottom: 24px;
}

@mixin highcharts-color($num, $color) {
  .highcharts-color-#{$num} {
    fill: $color;
    stroke: $color;
  }
}

::v-deep {
  @include highcharts-color(0, $color-mustard);
  @include highcharts-color(1, $color-mustard-light);

  .highcharts-column-series:not(.highcharts-legend-item) rect.highcharts-point {
    stroke-width: 0;
  }
}
</style>

<i18n locale="ko">
{
  "text_reviews_count": "텍스트 리뷰수",
  "media_reviews_count": "포토/동영상 리뷰수",
  "wdays": ["일", "월", "화", "수", "목", "금", "토"]
}
</i18n>

<i18n locale="en">
  {
    "wdays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  }
</i18n>
