<template>
  <AppModalDefault :title="$t('title')" :sub-title="subtitle" @close="close">
    <template #body>
      <AppHeading :label="$t('info.title')">
        <template #controls>
          <AppButton
            v-if="approvedRequestsCount > 1"
            :label="$t('info.history_button')"
            @click="clickHistoryButton"
          />
          <AppButton :label="$t('info.edit_button')" @click="clickEditButton" />
        </template>
      </AppHeading>
      <TheBrandMaterial :material-type="type" :material="material" />
    </template>
    <template #foot>
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';
import TheBrandMaterial from '@/layouts/TheBrandMaterial';

export default {
  name: 'TheBrandMaterialDialog',
  components: { TheBrandMaterial },
  mixins: [DialogView],
  props: {
    type: { type: String, default: null }
  },
  data() {
    return {
      material: { status: 'none', extra_docs: [] },
      approvedRequestsCount: 0
    };
  },
  computed: {
    subtitle() {
      return this.type === 'corporate_cert'
        ? this.$t('corporate_cert')
        : this.type === 'telecom_cert'
        ? this.$t('telecom_cert')
        : '';
    }
  },
  created() {
    const url =
      this.type === 'corporate_cert'
        ? '/brand_corporate_number_change_requests/last'
        : this.type === 'telecom_cert'
        ? '/brand_phone_change_requests/last'
        : '';

    api.get(url).then(({ data }) => {
      this.material =
        data.corporate_number_change_request || data.phone_change_request;
      this.approvedRequestsCount = data.approved_requests_count;
    });
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    clickHistoryButton() {
      this.openDialog(['TheBrandMaterialHistoryDialog', { type: this.type }]);
    },
    clickEditButton() {
      const dialogName =
        this.type === 'corporate_cert'
          ? 'TheCorporateNumberChangeRequestDialog'
          : 'ThePhoneChangeRequestDialog';

      this.openDialog([dialogName]).then(eventBus =>
        eventBus.$on('requested', material => (this.material = material))
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "등록한 자료 확인",
  "corporate_cert": "세금계산서 발급 자료",
  "telecom_cert": "통신서비스 이용증명원(가입증명서)",
  "info" : {
    "title": "등록정보",
    "history_button": "자료 변경 이력",
    "edit_button": "변경 요청"
  }
}
</i18n>
