<template>
  <TheAccountMenuPopupService
    v-if="targetStatus"
    service="target"
    :content-tooltip="contentTooltip"
    :items="items"
  />
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import TargetCreditStatus from '@/enums/TargetCreditStatus';
import TheAccountMenuPopupService from './TheAccountMenuPopupService';

export default {
  name: 'TheAccountMenuPopupServiceTarget',
  components: { TheAccountMenuPopupService },
  computed: {
    ...mapState('session', ['targetStatus', 'targetPayment']),
    ...mapGetters('alert', ['targetRemainingDates']),
    ...mapGetters('session', [
      'isTargetFreeTrial',
      'isTargetTesting',
      'targetRestorableDays'
    ]),
    contentTooltip() {
      if (this.isTargetFreeTrial) return '';

      if (this.isTargetTesting) return this.testingContentTooltip;
      else return this.operatingContentTooltip;
    },
    testingContentTooltip() {
      return this.$t('testing_tooltip', [
        this.targetRestorableDays,
        this.targetPayment.target_billing_info.monthly_bonus_point
      ]);
    },
    operatingContentTooltip() {
      const {
        average_spending_per_day,
        point_remaining_days,
        cash_remaining_days,
        free_point_balance,
        point_reset_day
      } = this.targetStatus;
      const { monthly_bonus_point } = this.targetPayment.target_billing_info;
      switch (this.targetStatus.credit_status) {
        case TargetCreditStatus.USING_POINT:
          if (free_point_balance > 0)
            return this.$t('using_point_with_free_point', {
              average_spending_per_day,
              point_reset_day,
              free_point_balance,
              monthly_bonus_point,
              deplete_date: this.formatDaysFromToday(point_remaining_days)
            });
          else
            return this.$t('using_point', {
              average_spending_per_day,
              point_reset_day,
              monthly_bonus_point,
              deplete_date: this.formatDaysFromToday(point_remaining_days)
            });
        case TargetCreditStatus.USING_CASH:
          return this.$t('using_cash', {
            average_spending_per_day,
            point_reset_day,
            monthly_bonus_point,
            deplete_date: this.formatDaysFromToday(cash_remaining_days)
          });
        case TargetCreditStatus.DEPLETED:
          return this.$t('point_and_cash_depleted', {
            point_reset_day,
            monthly_bonus_point
          });
        default:
          return '';
      }
    },
    items() {
      const { formatDate, formatCurrency } = this;
      const { target_expires_at, credit, point } = this.targetStatus;

      if (this.isTargetFreeTrial)
        return [
          {
            title: this.$t('free_trial_expires_on'),
            content: formatDate(target_expires_at)
          }
        ];
      else if (this.isTargetTesting)
        return [
          { title: this.$t('expires_on'), content: this.$t('testing') },
          { title: this.$t('point_balance'), content: this.$t('testing') },
          { title: this.$t('credit_balance'), content: formatCurrency(credit) }
        ];
      else {
        const items = [];

        if (target_expires_at)
          items.push({
            title: this.$t('expires_on'),
            content: formatDate(target_expires_at),
            contentTooltip: this.expirationTooltip
          });

        items.push({
          title: this.$t('point_balance'),
          content: formatCurrency(point)
        });
        items.push({
          title: this.$t('credit_balance'),
          content: formatCurrency(credit)
        });

        return items;
      }
    },
    expirationTooltip() {
      return this.targetRemainingDates >= 0
        ? this.$t('days_before_expiration', [this.targetRemainingDates])
        : this.$t('days_after_expiration', [-this.targetRemainingDates]);
    }
  },
  methods: {
    formatDaysFromToday(days) {
      return days !== null
        ? moment()
            .add(days, 'days')
            .toVal()
        : ' - ';
    }
  }
};
</script>

<i18n locale="ko">
{
  "expires_on": "만료일",
  "free_trial_expires_on": "무료 체험 종료일",
  "point_balance": "포인트",
  "credit_balance": "캐시",
  "days_before_expiration": "잔여 {0}일",
  "days_after_expiration": "{0}일 전 만료",
  "using_point": "포인트로 발송 중\n평균 1일당 {average_spending_per_day}원 사용 중 (소진 예상일: {deplete_date})\n포인트는 매달 {point_reset_day}일 {monthly_bonus_point}원 지급되며 남은 포인트는 이월되지않습니다.\n포인트가 모두 소진된 경우 캐시를 사용하게 됩니다.",
  "using_point_with_free_point": "포인트로 발송 중\n평균 1일당 {average_spending_per_day}원 사용 중 (소진 예상일: {deplete_date})\n포인트는 매달 {point_reset_day}일 {monthly_bonus_point}원 지급되며 남은 포인트는 이월되지않습니다.\n현재 보상 포인트: {free_point_balance}원 보유 (만료일 없음)\n포인트가 모두 소진된 경우 캐시를 사용하게 됩니다.",
  "using_cash": "캐시로 발송 중\n평균 1일당 {average_spending_per_day}원 사용 중 (소진 예상일: {deplete_date})\n포인트가 모두 소진된 경우 캐시를 사용하게 됩니다.\n포인트는 매달 {point_reset_day}일 0시, 이용료 구간에 의해 선정된 {monthly_bonus_point}원이 지급됩니다.\n아래 ‘서비스 결제’를 클릭하셔서 추가 캐시를 구매하실 수 있습니다.",
  "point_and_cash_depleted": "보유하신 포인트나 캐시가 없어 유료 메시지 발송이 불가합니다.\n쇼핑몰에 노출되는 팝업 캠페인과 위젯 및 이메일과 같은 무료 메시지는\n이용 기간 내 정상적으로 노출 및 발송됩니다.\n\n포인트는 매달 {point_reset_day}일 0시, 이용료 구간에 의해 선정된 {monthly_bonus_point}원이 지급됩니다.\n남은 기간 내, 추가로 메시지 발송을 원하신다면 아래 ‘서비스 결제’를 클릭하신 후\n캐시 구매를 통해 이용하실 수 있습니다.",
  "testing_tooltip": "테스트 중\n테스트 중일 때는 이용기간이 차감되지 않습니다.\n서비스 이용 시작 시점부터 만료일이 계산됩니다.\n결제한 서비스 이용기간: {0}\n포인트:  매달 {1}원 지급 예정",
  "testing": "테스트 중"
}
</i18n>
