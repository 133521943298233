import Vue from 'vue';
import { pathWithParams } from '@/lib/params';
import i18n from '@/lib/i18n';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';
import TargetCampaignStatus from '@/enums/TargetCampaignStatus';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';

const state = { campaigns: [] };

const campaignBasePath = campaign => {
  const mediaTypePath =
    campaign.campaign_media_type === TargetCampaignMediaType.DIRECT_MESSAGE
      ? 'dm-campaigns'
      : campaign.campaign_media_type === TargetCampaignMediaType.POPUP
      ? 'popup-campaigns'
      : 'widget-campaigns';

  const categoryPath = TargetCampaignType.isSituational(campaign.campaign_type)
    ? 'situational'
    : TargetCampaignType.isMass(campaign.campaign_type)
    ? 'mass'
    : 'product-recommendation';

  return `/target/${mediaTypePath}/${categoryPath}`;
};

const getters = {
  campaignsPath: () => campaign => {
    const path = campaignBasePath(campaign);
    const tab =
      campaign.status === TargetCampaignStatus.ENABLED
        ? ''
        : TargetCampaignStatus.key(campaign.status).toLowerCase();
    return pathWithParams(path, { tab });
  },
  campaignPath: () => campaign =>
    `${campaignBasePath(campaign)}/${campaign.id}`,
  campaignTextPath: () => (campaign, key) => {
    const campaignTypeKey = TargetCampaignType.key(campaign.campaign_type);
    const campaignMediaTypeKey = TargetCampaignMediaType.key(
      campaign.campaign_media_type
    );

    const campaignMediaPath = `target_campaign_text.${campaignTypeKey}/${campaignMediaTypeKey}.${key}`;
    if (i18n.te(campaignMediaPath)) return campaignMediaPath;

    const campaignPath = `target_campaign_text.${campaignTypeKey}.${key}`;
    if (i18n.te(campaignPath)) return campaignPath;

    const baseMediaPath = `target_campaign_text.BASE/${campaignMediaTypeKey}.${key}`;
    if (i18n.te(baseMediaPath)) return baseMediaPath;

    const basePath = `target_campaign_text.BASE.${key}`;
    if (i18n.te(basePath)) return basePath;

    throw `Invalid campaignTextPath with campaignType:${campaignTypeKey}, key:${key}`;
  },
  campaignText: (_state, { campaignTextPath }) => (
    campaign,
    key,
    interpolations,
    samplingMethod = TargetSamplingMethod.MESSAGE_CHECKED
  ) =>
    i18n.t(campaignTextPath(campaign, key), {
      ...interpolations,
      action: TargetSamplingMethod.actionText(samplingMethod),
      effect_period_by_days: TargetCampaignMediaType.effectPeriodByDays(
        campaign.campaign_media_type
      )
    }),
  campaignTextSafe: (_state, { campaignText }) => (
    campaign,
    key,
    interpolations
  ) => {
    try {
      return campaignText(campaign, key, interpolations);
    } catch {
      return '';
    }
  },
  isPopupCampaign: () => ({ campaign_media_type }) =>
    campaign_media_type === TargetCampaignMediaType.POPUP,
  isWidgetCampaign: () => ({ campaign_media_type }) =>
    campaign_media_type === TargetCampaignMediaType.WIDGET
};

const mutations = {
  SET_CAMPAIGNS(state, campaigns) {
    state.campaigns = campaigns;
  },
  DELETE_CAMPAIGN(state, id) {
    state.campaigns = state.campaigns.filter(c => c.id !== id);
  },
  UPDATE_CAMPAIGN_CALCULATE_STATUS(
    state,
    { id, calculate_status, calculated_at }
  ) {
    const campaign = state.campaigns.find(c => c.id === id);
    if (campaign) {
      Vue.set(campaign, 'calculate_status', calculate_status);
      Vue.set(campaign, 'calculated_at', calculated_at);
    }
  }
};

export default { namespaced: true, state, getters, mutations };
