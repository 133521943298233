<template>
  <AppModeless @close="$emit('close')">
    <div class="AppDateRangePickerPopup">
      <div class="AppDateRangePickerPopup__body">
        <div class="AppDateRangePickerPopup__ranges">
          <ul>
            <li v-for="(range, rangeIdx) in ranges" :key="rangeIdx">
              <a
                class="AppDateRangePickerPopup__range-link"
                @click="clickRange(range.value)"
                >{{ range.label }}</a
              >
            </li>
          </ul>
        </div>

        <div
          class="AppDateRangePickerPopup__calendar AppDateRangePickerPopup__calendar--left"
        >
          <AppCalendar
            v-bind="calendarPropsLeft"
            @date-click="dateClick"
            @hover-date="hoverDate"
            @click-prev-month="clickPrevMonth"
          />
        </div>

        <div
          class="AppDateRangePickerPopup__calendar AppDateRangePickerPopup__calendar--right"
        >
          <AppCalendar
            v-bind="calendarPropsRight"
            @date-click="dateClick"
            @hover-date="hoverDate"
            @click-next-month="clickNextMonth"
          />
        </div>
      </div>

      <div class="AppDateRangePickerPopup__foot">
        <div class="AppDateRangePickerPopup__foot-right">
          <AppForm v-bind="formProps" v-on="formEvents">
            <template #group="{ id, errors }">
              <template v-if="id === 'date'">
                <div>
                  <AppDateInput
                    v-focus
                    :value="startDate"
                    :invalid="!!errors.start_date || !!errors.date_range"
                    @change="setStartDate"
                    @focus-next="focusEndDateInput"
                  />
                  -
                  <AppDateInput
                    ref="endDateInput"
                    v-model="endDate"
                    :invalid="!!errors.end_date || !!errors.date_range"
                    @focus-next="focusApplyButton"
                  />
                  <AppButton
                    class="AppDataRangePickerPopup__close-button AppDataRangePickerPopup__button"
                    :label="$t('app.close')"
                    @click="$emit('close')"
                  />
                  <AppButtonSubmit
                    ref="applyButton"
                    class="AppDataRangePickerPopup__button"
                    :submit-label="$t('app.apply')"
                    :disabled="!!selectedDate"
                    :error-summary="errorSummary"
                  />
                </div>
                <AppFormError
                  :error="
                    errors.start_date || errors.end_date || errors.date_range
                  "
                />
              </template>
            </template>
          </AppForm>
        </div>
      </div>
    </div>
  </AppModeless>
</template>

<script>
import moment from 'moment';
import FormView from '@/mixins/FormView';
import { getMonthDate } from '@/lib/date';
import focusChild from '@/lib/focusChild';

export default {
  name: 'AppDateRangePickerPopup',
  mixins: [FormView],
  props: {
    dateRange: {
      type: Object,
      default() {
        return {};
      }
    },
    presets: { type: Array, required: true },
    minDate: { type: String, default: null },
    minDays: { type: Number, default: 1 },
    maxDays: { type: Number, default: null },
    required: { type: Boolean, default: false }
  },
  data() {
    const today = moment().toVal();
    const { minDate, minDays, isMaxDaysGreaterThan } = this;

    const rangePresets = {
      recent_year: {
        condition: !this.required,
        value: [
          moment()
            .subtract(1, 'year')
            .toVal(),
          moment()
            .subtract(1, 'day')
            .toVal()
        ]
      },
      entire_period: {
        condition: !this.required,
        label: this.$t('app.entire_period'),
        value: minDate ? [minDate, today] : [null, null]
      },
      today: {
        condition: minDays <= 1 && isMaxDaysGreaterThan(1),
        value: [today, today]
      },
      yesterday: {
        condition: minDays <= 1 && isMaxDaysGreaterThan(1),
        value: [
          moment()
            .subtract(1, 'days')
            .toVal(),
          moment()
            .subtract(1, 'days')
            .toVal()
        ]
      },
      last_7days: {
        condition: minDays <= 7 && isMaxDaysGreaterThan(7),
        value: [
          moment()
            .subtract(6, 'days')
            .toVal(),
          today
        ]
      },
      last_30days: {
        condition: minDays <= 30 && isMaxDaysGreaterThan(30),
        value: [
          moment()
            .subtract(29, 'days')
            .toVal(),
          today
        ]
      },
      last_90days: {
        condition: minDays <= 90 && isMaxDaysGreaterThan(90),
        value: [
          moment()
            .subtract(89, 'days')
            .toVal(),
          today
        ]
      },
      this_month: {
        condition: minDays <= 30 && isMaxDaysGreaterThan(30),
        value: [
          moment()
            .startOf('month')
            .toVal(),
          moment()
            .endOf('month')
            .toVal()
        ]
      },
      last_month: {
        condition: minDays <= 30 && isMaxDaysGreaterThan(30),
        value: [
          moment()
            .subtract(1, 'month')
            .startOf('month')
            .toVal(),
          moment()
            .subtract(1, 'month')
            .endOf('month')
            .toVal()
        ]
      },
      last_quarter: {
        condition: true,
        value: [
          moment()
            .subtract(1, 'quarter')
            .startOf('quarter')
            .toVal(),
          moment()
            .subtract(1, 'quarter')
            .endOf('quarter')
            .toVal()
        ]
      },
      last_year: {
        condition: true,
        value: [
          minDate &&
          moment(minDate) >
            moment()
              .subtract(1, 'year')
              .startOf('year')
            ? moment(minDate).toVal()
            : moment()
                .subtract(1, 'year')
                .startOf('year')
                .toVal(),
          moment()
            .subtract(1, 'year')
            .endOf('year')
            .toVal()
        ]
      }
    };

    const data = {
      ranges: this.presets
        .filter(preset => rangePresets[preset].condition)
        .map(preset => ({
          label: rangePresets[preset].label || this.$t(`ranges.${preset}`),
          value: rangePresets[preset].value
        })),
      startDate: this.dateRange.start_date,
      endDate: this.dateRange.end_date,
      monthDate:
        this.dateRange.start_date ||
        moment()
          .startOf('month')
          .toVal(),
      selectedDate: null,
      detectFormDataChange: false
    };
    return data;
  },
  computed: {
    calendarPropsCommon() {
      return {
        start: this.toDate(this.startDate),
        end: this.toDate(this.endDate),
        minDate: this.toDate(this.minDate)
      };
    },
    calendarPropsLeft() {
      return {
        ...this.calendarPropsCommon,
        monthDate: moment(this.monthDate)
          .add(1, 'day') // HACK - timezone 다를 때 하루 전 날짜 보여주는 버그가 있다.
          .toDate()
      };
    },
    calendarPropsRight() {
      return {
        ...this.calendarPropsCommon,
        monthDate: moment(this.monthDate)
          .add(1, 'month')
          .add(1, 'day') // HACK - timezone 다를 때 하루 전 날짜 보여주는 버그가 있다.
          .toDate()
      };
    },
    formSections() {
      const { startDate, endDate, minDate } = this;
      return [
        {
          groups: [
            {
              id: 'date',
              label: '',
              fields:
                startDate || endDate
                  ? [
                      {
                        id: 'start_date',
                        validate: [
                          {
                            rule: () => !!startDate,
                            errorMessage: this.$t('validations.required')
                          },
                          {
                            rule: () => moment(startDate).isValidDate(),
                            errorMessage: this.$t('validations.date_format')
                          },
                          ...(minDate
                            ? [
                                {
                                  rule: () =>
                                    moment(startDate).isSameOrAfter(
                                      minDate,
                                      'day'
                                    ),
                                  errorMessage: this.$t(
                                    'validations.selected_date_should_be_gte',
                                    [this.formatDate(minDate)]
                                  )
                                }
                              ]
                            : [])
                        ]
                      },
                      {
                        id: 'end_date',
                        validate: [
                          {
                            rule: () => !!endDate,
                            errorMessage: this.$t('validations.required')
                          },
                          {
                            rule: () => moment(endDate).isValidDate(),
                            errorMessage: this.$t('validations.date_format')
                          }
                        ]
                      },
                      {
                        id: 'date_range',
                        validate:
                          startDate && endDate
                            ? [
                                {
                                  rule: () =>
                                    moment(startDate).isSameOrBefore(
                                      endDate,
                                      'day'
                                    ),
                                  errorMessage: this.$t(
                                    'start_date_should_same_or_before_end_date'
                                  )
                                },
                                {
                                  rule: () =>
                                    moment(endDate)
                                      .subtract(this.minDays - 1, 'days')
                                      .isSameOrAfter(startDate, 'day'),
                                  errorMessage: this.$t(
                                    'date_range_should_gte_mindays',
                                    [this.minDays]
                                  )
                                },
                                ...(this.maxDays
                                  ? [
                                      {
                                        rule: () =>
                                          moment(endDate)
                                            .subtract(this.maxDays - 1, 'days')
                                            .isSameOrBefore(startDate, 'day'),
                                        errorMessage: this.$t(
                                          'date_range_should_lte_maxdays',
                                          [this.maxDays]
                                        )
                                      }
                                    ]
                                  : [])
                              ]
                            : []
                      }
                    ]
                  : []
            }
          ]
        }
      ];
    }
  },
  methods: {
    isMaxDaysGreaterThan(days) {
      return !this.maxDays || this.maxDays >= days;
    },
    toDate(str) {
      return str ? moment(str).toDate() : null;
    },
    clickNextMonth() {
      this.monthDate = moment(this.monthDate)
        .add(1, 'month')
        .toVal();
    },
    clickPrevMonth() {
      this.monthDate = moment(this.monthDate)
        .subtract(1, 'month')
        .toVal();
    },
    dateClick(value) {
      if (this.selectedDate) this.selectedDate = null;
      else {
        const date = moment(value).toVal();
        this.startDate = date;
        this.endDate = date;
        this.selectedDate = date;
      }
    },
    setStartDate(date) {
      this.startDate = date;
      const monthDate = getMonthDate(date);
      if (monthDate) this.monthDate = monthDate;
    },
    hoverDate(value) {
      const date = moment(value).toVal();
      if (this.selectedDate) {
        if (moment(date).isAfter(this.selectedDate)) {
          this.startDate = this.selectedDate;
          this.endDate = date;
        } else {
          this.startDate = date;
          this.endDate = this.selectedDate;
        }
      }
    },
    submit() {
      this.$emit('apply', { startDate: this.startDate, endDate: this.endDate });
    },
    clickRange(value) {
      this.startDate = value[0];
      this.endDate = value[1];
      this.formEventBus.$emit('submit');
    },
    focusEndDateInput() {
      focusChild(this.$refs.endDateInput.$el);
    },
    focusApplyButton() {
      focusChild(this.$refs.applyButton.$el);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';
@import '@/scss/mixins/_clearfix.scss';

.AppDataRangePickerPopup__button {
  ::v-deep {
    button {
      border-radius: 3px !important;
    }
  }
}

.AppDateRangePickerPopup__body {
  white-space: nowrap;

  @include media-breakpoint-each(mobile) {
    white-space: normal;
  }
}

.AppDateRangePickerPopup__calendar {
  display: inline-block;
  vertical-align: top;

  &--left {
    border-left: 1px solid #ddd;

    @include media-breakpoint-each(mobile) {
      border-left: none;
    }
  }
}

.AppDataRangePickerPopup__close-button {
  margin-left: 16px;
}

.AppDateRangePickerPopup__ranges {
  display: inline-block;
  text-align: left;
  width: 140px;
  padding: 9px 7px;
}

.AppDateRangePickerPopup__range-link {
  display: block;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 3px;
  color: $color-grey-70;

  &:hover {
    background-color: $color-grey-15;
  }
}

.AppDateRangePickerPopup__foot {
  @include clearfix;
  padding: 8px;
  border-top: 1px solid #ddd;
}

.AppDateRangePickerPopup__foot-right {
  float: right;
}
</style>

<i18n locale="ko">
{
  "ranges": {
    "today": "오늘",
    "yesterday": "어제",
    "last_7days": "지난 일주일",
    "last_30days": "지난 30일",
    "last_90days": "지난 90일",
    "this_month": "이번달",
    "last_month": "지난달",
    "last_quarter": "지난 분기",
    "last_year": "지난해",
    "recent_year": "최근 1년"
  },
  "start_date_should_same_or_before_end_date": "시작 날짜는 종료 날짜와 같거나 이전이어야 합니다.",
  "date_range_should_gte_mindays": "최소 {0}일 이상의 기간을 입력해주세요.",
  "date_range_should_lte_maxdays": "최대 {0}일 이하의 기간을 입력해주세요."
}
</i18n>
<i18n locale="en">
{
  "ranges": {
    "today": "Today",
    "yesterday": "Yesterday",
    "last_7days": "Last 7 days",
    "last_30days": "Last 30 days",
    "last_90days": "Last 90 days",
    "this_month": "This Month",
    "last_month": "Last Month",
    "last_quarter": "Last Quarter",
    "last_year": "Last Year",
    "recent_year": "Recent Year"
  },
  "start_date_should_same_or_before_end_date": "Start date should same or before end date.",
  "date_range_should_gte_mindays": "Please enter a period of at least {0} days.",
  "date_range_should_lte_maxdays": "Please enter a period of up to {0} days."
}
</i18n>
