import Hangul from 'hangul-js';
import _ from 'lodash';

// https://github.com/e-/Hangul.js/blob/b8d0d14711ca2c8fd2f552dc27f3ca204772296a/hangul.js#L396
export default function(haystack, needle) {
  const trimmedHaystack = haystack.replace(/\s+/g, ''),
    trimmedNeedle = needle.replace(/\s+/g, '');

  if (!trimmedNeedle.length) return [];

  var hex = Hangul.disassemble(trimmedHaystack).join(''),
    nex = Hangul.disassemble(trimmedNeedle).join(''),
    grouped = Hangul.disassemble(haystack, true),
    groupLengths = grouped.map(g => g.filter(c => !/\s/.test(c)).length),
    // FIX - Regexp escape 해서 `*` 와 같은 문자열에 대해서도 정상 동작하도록 수정
    re = new RegExp(_.escapeRegExp(nex), 'gi'),
    indices = [],
    result;

  while ((result = re.exec(hex))) {
    indices.push(result.index);
  }

  function findRange(index) {
    let startIndex = undefined;
    let endIndex = undefined;
    for (var i = 0, length = 0; i < groupLengths.length; ++i) {
      length += groupLengths[i];
      if (startIndex === undefined && index < length) startIndex = i;
      if (index + nex.length <= length) {
        endIndex = i;
        break;
      }
    }

    return [startIndex, endIndex];
  }

  return indices.map(findRange);
}
