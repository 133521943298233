<template>
  <div
    :id="`row_${index}`"
    :class="[
      'AppVirtualListResourceTableBodyRow',
      row.rowStyle
        ? `AppVirtualListResourceTableBodyRow--style-${row.rowStyle}`
        : null,
      row.superAdmin ? 'super-admin__item' : null
    ]"
  >
    <AppResourceTableCell
      v-for="column in visibleColumns"
      :key="`${row.id}_${column.id}`"
      cell-type="body"
      :class="[
        'AppVirtualListResourceTable__cell',
        tableId ? column.columnId : null,
        tableId ? `${column.columnId}--body` : null
      ]"
      :style="column.style"
      :column="column"
      :cell-style="column.columnStyle"
      :row="row"
      :value="
        column.type === 'row_select'
          ? selectedRowIdsSet.has(row.id)
          : row[column.id]
      "
      @check="selectRow(row.id, $event)"
      @click-link-icon="column.linkIcon.handler(column.id, row)"
    >
      <slot
        name="cell"
        :value="row[column.id]"
        :column="column.id"
        :row="row"
        :row-index="rowIndex"
      />
    </AppResourceTableCell>
  </div>
</template>
<script>
export default {
  name: 'AppVirtualListResourceTableBodyRow',
  props: {
    tableId: { type: String, default: null },
    visibleColumns: { type: Array, required: true },
    selectedRowIdsSet: { type: Set, default: () => new Set() },
    index: { type: Number, required: true },
    source: { type: Object, default: () => {} }
  },
  computed: {
    row() {
      return this.source;
    },
    rowIndex() {
      return this.index;
    }
  },
  methods: {
    selectRow(id, selected) {
      this.dispatch('AppVirtualListResourceTable', 'selectRow', id, selected);
    },
    dispatch(componentName, eventName, ...rest) {
      let parent = this.$parent || this.$root;
      let name = parent.$options.name;

      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent;
        if (parent) {
          name = parent.$options.name;
        }
      }

      if (parent) {
        parent.$emit.apply(parent, [eventName].concat(rest));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_transitions.scss';

$row-height-default: 50px;

.AppVirtualListResourceTableBodyRow {
  @include transition-basic(background-color);
  align-items: stretch;
  position: relative;

  @include text-content;

  &--style-stats-total-detail,
  &--style-stats-detail {
    @include text-caption;
  }

  &--style-stats-total,
  &--style-stats-total-summary,
  &--style-stats-total-detail {
    font-weight: bold;
    background-color: $color-grey-05;
  }

  &--style-stats-total-summary,
  &--style-stats-total-detail,
  &--style-stats-summary,
  &--style-stats-detail {
    border-bottom: 0;
  }

  &--style-stats-total-detail + &--style-stats-summary,
  &--style-stats-detail + &--style-stats-summary {
    border-top: 1px solid $color-layout-section;
  }
}

.hover .AppVirtualListResourceTableBodyRow {
  background-color: $color-grey-05;

  &--style-stats-total,
  &--style-stats-total-summary,
  &--style-stats-total-detail {
    background-color: $color-grey-15;
  }
}

.AppVirtualListResourceTableBodyRow {
  border-bottom: 1px solid $color-layout-section;
  display: flex;
  min-height: $row-height-default;
  box-sizing: content-box;
}

.AppVirtualListResourceTableBodyRow--hidden {
  display: none;
}

.AppVirtualListResourceTable__cell {
  padding: 8px;
  box-sizing: border-box;
}
</style>
