<template>
  <AppModalDefault
    :title="$t('local.title')"
    :sub-title="$t('local.sub_title', { id: batchJobId })"
    width="middle"
    @close="close"
  >
    <AppAjaxContent slot="body" :is-loading="isLoading">
      <template #default>
        <BatchJobSummary v-bind="{ batchJob }" />
        <BatchJobResultTable
          v-bind="{ batchJob, successItemsColumns, failedItemsColumns }"
        >
          <template #success-cell="{ row, column }">
            <template v-if="column.id === 'sub_product_codes'">
              <AppTableCellTextList :list="row.sub_product_codes" />
            </template>
          </template>
          <template #failed-cell="{ row, column }">
            <template v-if="column.id === 'sub_product_codes'">
              <AppTableCellTextList :list="row.sub_product_codes" />
            </template>
            <template v-else-if="column.id === 'error'">
              {{ error(row) }}
            </template>
          </template>
        </BatchJobResultTable>
      </template>
    </AppAjaxContent>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import BatchJobSummary from '@/views/components/BatchJobSummary';
import BatchJobResultTable from '@/views/components/BatchJobResultTable';
import BatchJobResultCode from '@/enums/BatchJobResultCode';
import DialogView from '@/mixins/DialogView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'ProductBatchJobSubProductsDialog',
  components: { BatchJobSummary, BatchJobResultTable },
  mixins: [DialogView, ResourceViewNoRoute],
  props: { batchJobId: { type: Number, required: true } },
  data() {
    return {
      successItemsColumns: [
        { id: 'product_code', label: this.$t('local.main_product_code') },
        {
          id: 'product',
          label: this.$t('local.main_product'),
          type: 'product'
        },
        { id: 'sub_product_codes', label: this.$t('local.sub_product_codes') }
      ],
      failedItemsColumns: [
        {
          id: 'line_no',
          label: this.$t('local.error_line_no')
        },
        { id: 'product_code', label: this.$t('local.main_product_code') },
        { id: 'sub_product_codes', label: this.$t('local.sub_product_codes') },
        { id: 'error', label: this.$t('app.error') }
      ],
      isLoading: true,
      batchJob: null
    };
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/batch_jobs/${this.batchJobId}`, { params })
        .then(({ data }) => (this.batchJob = data.batch_job))
        .finally(() => (this.isLoading = false));
    },
    error(row) {
      switch (row.result_code) {
        case BatchJobResultCode.FAILURE_INVALID_PRODUCT_CODE:
          if (row.error_message === row.product_code)
            return this.$t('error.no_product_code');
          else return this.$t('error.no_sub_product_code');
        case BatchJobResultCode.FAILURE_EMPTY_PRODUCT_CODE:
          return this.$t('error.product_code_must_be_present');
        case BatchJobResultCode.FAILURE_PRODUCT_CODE_MUST_NOT_BE_THE_SAME:
          return this.$t('error.product_code_must_not_be_the_same');
        default:
          return row.error_message;
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "local": {
    "title": "일괄 등록 처리 결과",
    "sub_title": "#{id} 상세 내역",
    "main_product_code": "메인 상품 번호",
    "main_product": "메인 상품",
    "sub_product_codes": "서브 상품 번호",
    "error_line_no": "CSV 줄 번호"
  },
  "error": {
    "no_product_code": "메인 상품 없음",
    "no_sub_product_code": "서브 상품 없음",
    "product_code_must_be_present": "메인 상품 미입력",
    "product_code_must_not_be_the_same": "메인, 서브 상품에 동일한 상품 번호 입력"
  }
}
</i18n>
