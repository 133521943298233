<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="display.name"
    width="middle"
    :form-props="{
      ...formProps,
      detectFormDataChange: false,
      submitLabel: $t('submit')
    }"
    v-on="formEvents"
  >
    <template #group="{ id }">
      <template v-if="id === 'schedule_now'">
        <AppResourceTable
          class="AppForm__group-field--mt16"
          :columns="columns"
          :rows="rows"
          :enable-total-count="false"
        />
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapState } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'DisplayProductDisplayScheduleNowDialog',
  mixins: [DialogFormView],
  computed: {
    ...mapState('displayProductDisplay', ['display']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'schedule_now',
              label: '',
              groupDescription: this.$t('description_html', [
                this.display.corners.length
              ])
            }
          ]
        }
      ];
    },
    columns() {
      return [
        { id: 'name', align: 'left', width: '216px' },
        { id: 'products_count', type: 'number', width: '160px' },
        { id: 'added_products_count', type: 'number', width: '172px' },
        { id: 'removed_products_count', type: 'number', width: '172px' }
      ].map(column => ({ ...column, label: this.$t(`columns.${column.id}`) }));
    },
    rows() {
      return this.display.corners.map(corner => ({
        name: corner.name,
        products_count: corner.products_count,
        added_products_count: corner.added_product_ids.length,
        removed_products_count: corner.removed_product_ids.length
      }));
    }
  },
  methods: {
    submit() {
      this.eventBus.$emit('submit');
      this.close();
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "즉시 진열",
  "description_html": "<b>총 {0}개의 카테고리</b>에 대한 진열을 진행할 준비가 완료되었습니다.<br />즉시 진열 버튼을 누르면 쇼핑몰에 진열을 시작합니다.",
  "columns": {
    "name": "카테고리명",
    "products_count": "총 진열 상품 수",
    "added_products_count": "카테고리에 추가한 상품 수",
    "removed_products_count": "카테고리에서 제거한 상품 수"
  },
  "submit": "즉시 진열"
}
</i18n>
