<template>
  <ul :class="['AppDataList', `AppDataList--gap-${gap}`]">
    <li
      v-for="(datum, index) in currentData"
      :key="index"
      :class="['AppDataList__datum', { 'super-admin__item': datum.superAdmin }]"
    >
      <AppDataItem v-bind="datum" :gap="labelGap">
        <template v-if="$scopedSlots.value" #default>
          <slot name="value" v-bind="datum" />
        </template>
      </AppDataItem>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppDataList',
  props: {
    data: {
      type: Array,
      required: true
    },
    gap: {
      type: String,
      default: 'default',
      validator: v => ['default', 'narrow', 'wide'].includes(v)
    },
    labelGap: {
      type: String,
      default: 'narrow',
      validator: v => ['narrow', 'default', 'wide'].includes(v)
    }
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    currentData() {
      return this.data.filter(
        d => this.isSuperAdminAccessible || d.superAdmin !== true
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.AppDataList__datum {
  & + & {
    margin-top: 16px;

    .AppDataList--gap-narrow & {
      margin-top: 12px;
    }

    .AppDataList--gap-wide & {
      margin-top: 32px;
    }
  }
}
</style>
