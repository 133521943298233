<template>
  <button
    v-tooltip="$t(`tooltip.${maximized ? 'exit' : 'enter'}`)"
    class="AppModalHeadMaximizeButton"
    @click="$emit('maximize', !maximized)"
  >
    <AppSvgIcon
      :name="maximized ? 'icon-exit-fullscreen' : 'icon-enter-fullscreen'"
    />
  </button>
</template>

<script>
export default {
  name: 'AppModalHeadMaximizeButton',
  props: {
    maximized: {
      type: Boolean,
      defalut: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_buttons.scss';

.AppModalHeadMaximizeButton {
  @include svg-button-container;
}
</style>

<i18n locale="ko">
{
  "tooltip": {
    "enter": "전체화면 시작",
    "exit": "전체화면 종료"
  }
}
</i18n>
