var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"AppColorReportTable"},[_c('thead',[_c('th',{staticClass:"AppColorReportTable__col-summary"},[_vm._v(_vm._s(_vm.title))]),_vm._l((_vm.columns),function(column,i){return _c('th',{key:("head-" + i),staticClass:"AppColorReportTable__col-data"},[_vm._v(" "+_vm._s(column)+" ")])})],2),_c('tbody',_vm._l((_vm.rows),function(row,i){return _c('tr',{key:("row-" + i)},[_c('td',{staticClass:"AppColorReportTable__col-summary"},[_c('span',[_vm._v(_vm._s(row.label))]),_c('span',{staticClass:"AppColorReportTable__summary"},[_vm._v(_vm._s(row.summary)),_c('AppSvgIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(row.summaryTooltip),expression:"row.summaryTooltip"}],staticClass:"AppColorReportTable__summary-icon",attrs:{"name":"icon-info-tooltip","width":10,"height":10}})],1)]),_vm._l((row.data),function(cell,j){return _c('td',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          cell.tooltipMessage
            ? {
                message: cell.tooltipMessage,
                info: cell.tooltipInfo,
                textAlign: 'left'
              }
            : null
        ),expression:"\n          cell.tooltipMessage\n            ? {\n                message: cell.tooltipMessage,\n                info: cell.tooltipInfo,\n                textAlign: 'left'\n              }\n            : null\n        "}],key:("column-" + j),class:[
          'AppColorReportTable__col-data',
          cell.mark
            ? 'AppColorReportTable__cell--mark'
            : ("AppColorReportTable__cell--level-" + (Math.min(
                _vm.maxLevel,
                cell.level
              )))
        ]},[(cell.type === 'percentage')?_c('AppNumber',{attrs:{"value":cell.value,"precision":1,"unit":"percentage"}}):_c('AppData',{attrs:{"value":cell.value,"type":cell.type}})],1)})],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }