<template>
  <div class="AppSpinnerContent">
    <Transition name="fade" @enter="$store.state.isContentReady = true">
      <div>
        <div v-if="!isLoading" class="AppSpinnerContent__slot">
          <slot />
        </div>
        <Transition
          class="AppSpinnerContent__screen"
          name="fade"
          @leave="$store.state.isContentReady = true"
        />
      </div>
    </Transition>
    <div
      v-if="!isReady || isLoading"
      class="AppSpinnerContent__wrapper"
      :style="{ height }"
    >
      <AppSpinner class="AppSpinnerContent__spinner" />
      <div v-if="description" class="AppSpinnerContent__descriptions_container">
        <div class="AppSpinnerContent__description">
          {{ description }}
        </div>
      </div>
      <div class="AppSpinnerContent__sub-description_container">
        <TransitionCrossfade
          :items="subDescriptions"
          :visible-index="subDescriptionIndex"
        >
          <template #default="{ item }">
            <div class="AppSpinnerContent__sub-description">
              {{ $t(item) }}
            </div>
          </template>
        </TransitionCrossfade>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionCrossfade from '@/transitions/TransitionCrossfade';

export default {
  name: 'AppSpinnerContent',
  components: { TransitionCrossfade },
  props: {
    isLoading: { type: Boolean, required: true },
    height: { type: String, default: '330px' },
    subDescriptions: { type: Array, default: () => [] },
    description: { type: String, default: '' }
  },
  data() {
    return {
      isReady: !this.isLoading,
      screenWidth: 0,
      subDescriptionIndex: 0,
      subDescriptionTimer: null
    };
  },
  watch: {
    isLoading() {
      if (this.isLoading) {
        this.setLoadingScreenWidth();
        this.$nextTick(() => {
          this.setSubDescriptionsTimer();
        });
      } else {
        this.isReady = true;
        clearInterval(this.tipTimer);
      }
    }
  },
  mounted() {
    if (this.isReady) this.$store.state.isContentReady = true;

    this.setSubDescriptionsTimer();
  },
  beforeDestroy() {
    clearInterval(this.subDescriptionTimer);
  },
  methods: {
    setLoadingScreenWidth() {
      this.$el.style.setProperty('display', 'inline-block');
      this.$el.style.setProperty('min-width', '100%');
      this.screenWidth = `${this.$el.offsetWidth}px`;
      this.$el.style.setProperty('display', '');
      this.$el.style.setProperty('min-width', '');
    },
    setSubDescriptionsTimer() {
      const subDescriptionLength = this.subDescriptions.length;

      if (subDescriptionLength === 0) return;

      this.subDescriptionTimer = setInterval(
        () =>
          (this.subDescriptionIndex =
            (this.subDescriptionIndex + 1) % subDescriptionLength),
        3500
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/vars/_z-indexes.scss';

.AppSpinnerContent {
  position: relative;
}

.AppSpinnerContent__wrapper {
  text-align: center;
  position: relative;
}

$spinner-size: 30px;
$top-height: 50%;
.AppSpinnerContent__spinner {
  position: relative;
  margin-top: $spinner-size * -0.5;
  font-size: $spinner-size;
  top: $top-height;
}

.AppSpinnerContent__screen {
  right: auto;
}

.AppSpinnerContent__screen_blurred {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: $z-index-screen;
}

.AppSpinnerContent__descriptions_container {
  position: relative;
  margin-top: 12px;
  top: $top-height;
}

.AppSpinnerContent__description {
  @include text-sub-navigation;
  font-weight: bold;
}

.AppSpinnerContent__sub-description_container {
  position: relative;
  margin-top: 20px;
  top: $top-height;
}

.AppSpinnerContent__sub-description {
  @include text-caption-dark;
}
</style>
