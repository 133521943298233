<template>
  <AppModalDefault
    :title="$t('campaign_stats')"
    :sub-title="campaign ? campaign.name : undefined"
    width="wide"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <template #default>
          <TargetCampaignMassStatsKpiGrid
            v-bind="{ isLoading, report, campaign }"
          />
          <div class="target-campaign-stats-table">
            <div class="table-head-control">
              <div class="table-head-control__right">
                <AppSelectButton
                  v-model="sub_report"
                  unselectable
                  :options="TargetSubReport.options([TargetSubReport.DAYS])"
                />
              </div>
            </div>
            <AppResourceTable
              table-id="target-dm-campaign-mass-stats-table"
              table-style="stats"
              v-bind="{ rows, columns }"
              :enable-total-count="false"
            />
          </div>
        </template>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import TargetSubReport from '@/enums/TargetSubReport';
import TargetSubReportType from '@/enums/TargetSubReportType';
import DialogView from '@/mixins/DialogView';
import TargetCampaignStatsTableColumns from '@/mixins/TargetCampaignStatsTableColumns';
import TargetCampaignMassStatsKpiGrid from './TargetCampaignMassStatsKpiGrid';

export default {
  name: 'TargetCampaignMassStatsDialog',
  components: { TargetCampaignMassStatsKpiGrid },
  mixins: [DialogView, TargetCampaignStatsTableColumns],
  props: { campaignId: { type: Number, required: true } },
  data: () => ({
    isLoading: true,
    report: {},
    sub_report: null,
    campaign: null
  }),
  computed: {
    TargetSubReport: () => TargetSubReport,
    rows() {
      if (this.isLoading) return [];

      const row = this.report['table'];
      row.date_range = this.formatDateWithWday(this.campaign.last_sent_date);
      if (this.sub_report) row.rowStyle = 'stats-summary';

      const detailRows = this.subReportTypes.map(subReportType => {
        const subReport = row.sub_reports.find(
          i => i.sub_report_type === subReportType
        );
        return {
          ...subReport,
          subReportType,
          date_range: TargetSubReportType.t(subReportType),
          rowStyle: 'stats-detail'
        };
      });

      return [row, ...detailRows];
    },
    subReportTypes() {
      return (
        TargetSubReport.subReportTypes(
          this.sub_report,
          this.campaign.campaign_media_type
        ) || []
      );
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaignId}/report_mass`)
      .then(({ data }) => {
        this.campaign = data.campaign;
        this.report = data.report;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openDetailDialog(reportItemType, { subReportType }) {
      const { last_sent_date } = this.campaign;
      const dateRange = {
        start_date: last_sent_date,
        end_date: last_sent_date
      };
      this.openDialog([
        'TargetCampaignStatsDetailDialog',
        {
          reportItemType,
          subReportType,
          campaign: this.campaign,
          dateRange
        }
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "campaign_stats": "캠페인 통계"
}
</i18n>

<style lang="scss" scoped>
::v-deep {
  .target-dm-campaign-mass-stats-table__date-range {
    flex: 0 0 140px;
  }

  .target-dm-campaign-mass-stats-table__targeted-users-count {
    flex: 1 0 84px;
  }

  .target-dm-campaign-mass-stats-table__respondents-count {
    flex: 1 0 80px;
  }

  .target-dm-campaign-mass-stats-table__purchase-counts {
    flex: 1 0 120px;
  }

  .target-dm-campaign-mass-stats-table__sent-count {
    flex: 1 0 80px;
  }

  .target-dm-campaign-mass-stats-table__revenue {
    flex: 1 0 120px;
  }
}
</style>
