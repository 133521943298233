<template>
  <TransitionGroup
    name="toast"
    tag="ul"
    class="AppToastTransition"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <li v-for="item in items" :key="item.id" class="AppToastTransition__item">
      <div :style="{ height: `${gap}px` }" class="AppToastTransition__gap" />
      <slot name="item" :item="item" />
    </li>
  </TransitionGroup>
</template>

<script>
export default {
  name: 'AppToastTransition',
  props: {
    gap: { type: Number, required: true },
    items: { type: Array, required: true }
  },
  methods: {
    enter(el) {
      const ul = el.parentElement;
      const bottom = this.items.length === 1 ? -15 : -el.offsetHeight;
      ul.style.bottom = `${bottom}px`;
      requestAnimationFrame(() => {
        ul.classList.add('toasts-enter-active');
        ul.style.bottom = '0px';
      });
    },
    afterEnter(el) {
      const ul = el.parentElement;
      ul.classList.remove('toasts-enter-active');
    },
    leave(el) {
      el.style.maxHeight = `${el.offsetHeight * 2}px`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_transitions.scss';

.AppToastTransition {
  position: relative;
}

.AppToastTransition__item {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.toasts-enter-active {
  @include transition-slow(bottom);
}

.toast-enter-active {
  @include transition-slow(opacity);
}

.toast-enter-to {
  opacity: 1;
}

.toast-enter {
  opacity: 0;
}

@keyframes toast-leave {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    max-height: 0;
  }
}

.toast-leave-active {
  animation: toast-leave $transition-duration-slow linear;
}
</style>
