<template>
  <div class="AppTableCellProduct">
    <div
      v-if="typeof product === 'string'"
      class="AppTableCellProduct__no-product"
    >
      {{ product }}
    </div>
    <AppExternalLink
      v-else
      class="AppTableCellProduct__product"
      :to="product.url"
    >
      <div class="AppTableCellProduct__image-wrapper">
        <AppImage
          v-if="product.image_url"
          class="AppTableCellProduct__image"
          :src="product.image_url"
          :alt="product.name"
        />
      </div>
      <div class="AppTableCellProduct__name">
        {{ product.name }}
      </div>
    </AppExternalLink>
  </div>
</template>

<script>
export default {
  name: 'AppTableCellProduct',
  props: {
    product: {
      type: [Object, String],
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.AppTableCellProduct {
  min-height: 104px;
  line-height: 104px;
}

.AppTableCellProduct__product {
  display: inline-block;
  vertical-align: middle;
}

.AppTableCellProduct__image-wrapper {
  font-size: 0;
  line-height: 0;
}

.AppTableCellProduct__image {
  max-height: 80px;
}

.AppTableCellProduct__name {
  margin-top: 4px;
  line-height: 20px;
}
</style>
