<template>
  <AppTabContent menu-item-id="fit_size_presets">
    <AppAjaxContent :is-loading="isLoading">
      <AppSearchbar @submit="searchResource">
        <template #default>
          <li>
            <AppButton
              button-style="red"
              :label="$t('button.new_preset')"
              @click="editFitPresetProduct(null)"
            />
          </li>
        </template>
        <template #right>
          <li>
            <AppSearchWithType
              v-model="searchWithType"
              :search-types="searchTypes"
            />
          </li>
          <li>
            <AppDateRangeWithType
              v-model="dateRangeWithType"
              :date-types="dateTypes"
              @apply="searchResource"
            />
          </li>
        </template>
      </AppSearchbar>
      <AppResourceTable
        table-id="fit-presets-table"
        enable-refresh
        enable-column-settings
        :resources="fitPresetProducts"
        :columns="columns"
        :rows="rows"
        @refresh="refreshResource"
        @paginate="paginate"
      >
        <template #cell="{ column, value }">
          <FitSizeProductsDataInsertedAtCell
            v-if="column === 'dataInsertedAt'"
            :fit-product="value"
          />
          <template v-else-if="column === 'actions'">
            <AppButton
              v-if="value.product"
              :label="$t('button.unlink_product')"
              @click="unlinkProduct(value)"
            />
            <AppProductSelectButton
              v-else
              :title="$t('button.link_product')"
              :sub-title="value.preset_code"
              :label="$t('button.link_product')"
              :default-filters="['fit_template:no', 'fit_exclude_disabled:1']"
              @select="linkProduct(value, $event)"
            />
            <AppButton
              :label="$t('button.edit_preset')"
              @click="editFitPresetProduct(value.id)"
            />
            <AppButton
              button-style="red-outline"
              :label="$t('app.delete')"
              :tooltip="value.product ? $t('cannot_delete') : null"
              :disabled="!!value.product"
              @click="deleteFitPresetProduct(value)"
            />
          </template>
        </template>
      </AppResourceTable>
    </AppAjaxContent>
  </AppTabContent>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import api from '@/lib/api';
import ResourceView from '@/mixins/ResourceView';
import FitSizeProductsDataInsertedAtCell from '../products/FitSizeProductsDataInsertedAtCell';

export default {
  name: 'FitSizePresets',
  mixins: [ResourceView, FitSizeProductsDataInsertedAtCell],
  data() {
    return {
      columns: [
        {
          id: 'presetCode',
          label: this.$t('fit_product.preset_code'),
          required: true
        },
        {
          id: 'product',
          label: this.$t('fit_product.product'),
          required: true,
          type: 'product'
        },
        { id: 'template', label: this.$t('fit_product.template') },
        {
          id: 'dataInsertedAt',
          label: this.$t('fit_product.fit_data_inserted_at')
        },
        { id: 'actions', label: this.$t('app.actions'), required: true }
      ],
      searchTypes: [
        { value: 'preset_code', label: this.$t('fit_product.preset_code') },
        { value: 'product_name', label: this.$t('products.name') }
      ],
      dateTypes: [
        { value: 'created_at', label: this.$t('app.created_at') },
        { value: 'updated_at', label: this.$t('app.inserted_at') }
      ]
    };
  },
  computed: {
    ...mapState('fitPresetProduct', ['fitPresetProducts']),
    ...mapGetters('fitPresetProduct', ['isFetchingFitPresetProducts']),
    defaultResourceParams: () => ({
      matched: '',
      search_type: 'preset_code',
      search_query: '',
      date_type: 'created_at',
      start_date: '',
      end_date: '',
      per: '20',
      page: '1'
    }),
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    dateRangeWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    isLoading() {
      return this.fitPresetProducts.isNull || this.isFetchingFitPresetProducts;
    },
    rows() {
      return this.fitPresetProducts.items.map(fitPresetProduct => ({
        id: fitPresetProduct.id,
        presetCode: fitPresetProduct.preset_code,
        product: fitPresetProduct.product || this.$t('no_product'),
        template: fitPresetProduct.template.name,
        dataInsertedAt: fitPresetProduct,
        actions: fitPresetProduct
      }));
    }
  },
  beforeDestroy() {
    this.resetFitPresetProducts();
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('fitPresetProduct', [
      'fetchFitPresetProducts',
      'resetFitPresetProducts'
    ]),
    editFitPresetProduct(fitProductId) {
      this.openDialog([
        'FitSizeProductEditDialog',
        { fitProductId, isPreset: true }
      ]);
    },
    fetchResource(params) {
      this.fetchFitPresetProducts(this.convertParamsTab(params));
    },
    linkProduct(fitPresetProduct, product) {
      api
        .get(
          `/fit/preset_products/${fitPresetProduct.id}/check_product_match`,
          { params: { product_id: product.id } }
        )
        .then(({ data }) => {
          let confirmLocale = null;
          if (data.unmatched_product_option_type_id) {
            confirmLocale =
              'link_product_confirm_unmatched_product_option_type';
          } else if (data.unmatched_product_option_value_ids.length) {
            confirmLocale =
              'link_product_confirm_unmatched_product_option_value';
          }

          if (!confirmLocale || confirm(this.$t(confirmLocale))) {
            this.$store.dispatch('fitPresetProduct/updateProduct', {
              fitProductId: fitPresetProduct.id,
              productId: product.id,
              successMessage: this.$t('fit_preset_product.linked', {
                preset_code: fitPresetProduct.preset_code,
                product_name: product.name
              })
            });
          }
        });
    },
    unlinkProduct(fitPresetProduct) {
      if (confirm(this.$t('confirm.unlink_product'))) {
        this.$store.dispatch('fitPresetProduct/updateProduct', {
          fitProductId: fitPresetProduct.id,
          successMessage: this.$t('fit_preset_product.unlinked', {
            preset_code: fitPresetProduct.preset_code
          })
        });
      }
    },
    deleteFitPresetProduct(fitPresetProduct) {
      if (confirm(this.$t('app.delete_confirm'))) {
        const { items } = this.fitPresetProducts;
        const lastId = items[items.length - 1].id;
        this.$store.dispatch('fitPresetProduct/deleteFitPresetProduct', {
          fitPresetProduct: fitPresetProduct,
          params: {
            ...this.convertParamsTab(this.orgResourceParams),
            page: null,
            per: null,
            last_id: lastId
          }
        });
      }
    },
    convertParamsTab(params) {
      let matched = '';
      if (params.tab === 'matched') matched = '1';
      else if (params.tab === 'unmatched') matched = '0';
      return { ...params, matched, tab: null };
    }
  }
};
</script>

<style scoped>
.fit-size-presets__date {
  line-height: 20px;
}

.fit-size-presets__detail {
  line-height: 20px;
  margin-top: 4px;
}
</style>

<style lang="scss" scoped>
::v-deep {
  .fit-presets-table__preset-code {
    flex: 1 0 100px;
  }

  .fit-presets-table__product {
    flex: 1 0 150px;
  }

  .fit-presets-table__template {
    flex: 1 0 100px;
  }

  .fit-presets-table__data-inserted-at {
    flex: 1 0 110px;
  }

  .fit-presets-table__actions {
    flex: 1 0 280px;
  }
}
</style>

<i18n locale="ko">
{
  "button": {
    "new_preset": "실측 치수 추가",
    "link_product": "상품 매칭",
    "unlink_product": "매칭 취소",
    "edit_preset": "치수 직접입력"
  },
  "confirm": {
    "unlink_product": "정말로 매칭 취소하시겠습니까?"
  },
  "no_product": "매칭 상품 없음",
  "cannot_delete": "상품과 매칭된 치수는 삭제할 수 없습니다. 매칭 취소 후 삭제해주세요.",
  "link_product_confirm_unmatched_product_option_type": "입력한 '치수 입력 기준이 되는 상품 옵션'이 연결할 상품에 없습니다.\n'치수 입력 기준이 되는 상품 옵션'이 '매칭안됨'으로 설정될 수 있습니다.\n상품 매칭을 계속하시겠습니까?",
  "link_product_confirm_unmatched_product_option_value": "입력한 '옵션값'이 연결할 상품에 없습니다.\n잘못된 상품 선택 혹은 대소문자 구분 등과 같은 오타가 원인일 수 있습니다.\n상품 매칭을 계속하시겠습니까?"
}
</i18n>
