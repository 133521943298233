var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'AppVirtualListResourceTableBodyRow',
    _vm.row.rowStyle
      ? ("AppVirtualListResourceTableBodyRow--style-" + (_vm.row.rowStyle))
      : null,
    _vm.row.superAdmin ? 'super-admin__item' : null
  ],attrs:{"id":("row_" + _vm.index)}},_vm._l((_vm.visibleColumns),function(column){return _c('AppResourceTableCell',{key:((_vm.row.id) + "_" + (column.id)),class:[
      'AppVirtualListResourceTable__cell',
      _vm.tableId ? column.columnId : null,
      _vm.tableId ? ((column.columnId) + "--body") : null
    ],style:(column.style),attrs:{"cell-type":"body","column":column,"cell-style":column.columnStyle,"row":_vm.row,"value":column.type === 'row_select'
        ? _vm.selectedRowIdsSet.has(_vm.row.id)
        : _vm.row[column.id]},on:{"check":function($event){return _vm.selectRow(_vm.row.id, $event)},"click-link-icon":function($event){return column.linkIcon.handler(column.id, _vm.row)}}},[_vm._t("cell",null,{"value":_vm.row[column.id],"column":column.id,"row":_vm.row,"rowIndex":_vm.rowIndex})],2)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }