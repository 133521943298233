var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppTabContent',{attrs:{"menu-item-id":"fit_size_products"}},[_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('AppSearchbar',{on:{"submit":_vm.searchResource},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('li',[_c('AppButtonSyncProduct',{attrs:{"calculate-product-data":false,"hide-when-disabled":true}})],1)]},proxy:true},{key:"right",fn:function(){return [_c('li',[_c('AppSearchWithType',{attrs:{"search-types":_vm.searchTypes},model:{value:(_vm.searchWithType),callback:function ($$v) {_vm.searchWithType=$$v},expression:"searchWithType"}})],1),_c('li',[_c('AppDateRangeWithType',{attrs:{"date-types":_vm.dateTypes},on:{"apply":_vm.searchResource},model:{value:(_vm.dateRangeWithType),callback:function ($$v) {_vm.dateRangeWithType=$$v},expression:"dateRangeWithType"}})],1),_c('li',[_c('AppButtonToggle',{attrs:{"label":_vm.$t('app.advanced_search')},model:{value:(_vm.advancedSearchVisible),callback:function ($$v) {_vm.advancedSearchVisible=$$v},expression:"advancedSearchVisible"}})],1)]},proxy:true},{key:"advanced-search",fn:function(){return [_c('AppAdvancedSearch',{attrs:{"visible":_vm.advancedSearchVisible,"sections":_vm.advancedSearchSections},on:{"submit":_vm.searchResource},model:{value:(_vm.resourceParams),callback:function ($$v) {_vm.resourceParams=$$v},expression:"resourceParams"}})]},proxy:true}])}),_c('AppResourceTable',{attrs:{"table-id":"fit-products-table","enable-refresh":"","enable-column-settings":"","resources":_vm.products,"columns":_vm.columns,"rows":_vm.rows},on:{"refresh":_vm.refreshResource,"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
var value = ref.value;
return [(column === 'dataStatus')?[_c('ul',{staticClass:"badges"},[_c('li',[(value.fit_disabled_product)?_c('AppBadge',{attrs:{"badge-style":"grey","label":_vm.$t('fit_data_status.disable')}}):(value.fit_product)?_c('AppBadge',{attrs:{"tooltip":_vm.$t('fit_product.fit_data_inserted_tooltip', {
                      total_count: value.fit_product.fit_data_total_count,
                      inserted_count:
                        value.fit_product.fit_data_inserted_count
                    }),"badge-style":_vm.dataStatusBadgeStyle(value.fit_product.data_status),"label":_vm.$t(("fit_data_status." + (value.fit_product.data_status)))}}):_c('AppBadge',{attrs:{"badge-style":"mango","label":_vm.$t('fit_data_status.none')}})],1),(value.fit_product && value.fit_product.hide_size_widget)?_c('li',[_c('AppBadge',{attrs:{"label":_vm.$t('fit_product.hide_size_widget')}})],1):_vm._e()])]:(column === 'dataInsertedAt')?_c('FitSizeProductsDataInsertedAtCell',{attrs:{"fit-product":value}}):(column === 'actions')?[(_vm.isPartialOrFullFitProduct(value))?_c('div',[_c('div',[_c('AppButton',{staticClass:"FitSizeProducts__action-button-copy-tag",attrs:{"label":_vm.$t('local.button_copy_tag')},on:{"click":function($event){return _vm.showTags(value)}}})],1),_c('div',[_c('AppButtonDownload',{staticClass:"FitSizeProducts__action-button-download-image",attrs:{"disabled":!_vm.downloadable(value),"label":_vm.$t('local.button_download_image'),"filename":("fit-" + (value.code) + ".jpg"),"event-bus":row.downloadEventBus},nativeOn:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.downloadable(value) &&
                      _vm.downloadImage(value, row.downloadEventBus)}}}),_c('AppButton',{staticClass:"FitSizeProducts__action-button-preview-fit-product",attrs:{"label":_vm.$t('local.button_preview')},on:{"click":function($event){return _vm.openDialog([
                      'FitSizeProductPreviewDialog',
                      {
                        name: value.name,
                        url: value.fit_product.url
                      }
                    ])}}})],1)]):_c('div',[_vm._v(_vm._s('-'))])]:(column === 'disable')?[(_vm.isPartialOrFullFitProduct(value))?_c('div',[_vm._v(_vm._s('-'))]):_c('div',[_c('AppButton',{attrs:{"button-style":value.fit_disabled_product
                    ? 'grey-outline'
                    : 'mango-outline',"label":value.fit_disabled_product
                    ? _vm.$t('local.button_restore')
                    : _vm.$t('local.button_disable')},on:{"click":function($event){return _vm.disableOrRestoreProduct(value)}}})],1)]:(column === 'size')?[(value.fit_disabled_product)?_c('div',[_vm._v(_vm._s('-'))]):_c('div',[_c('div',[_c('AppButton',{attrs:{"label":_vm.$t('local.button_edit')},on:{"click":function($event){return _vm.openDialog([
                      'FitSizeProductEditDialog',
                      { product: value }
                    ])}}})],1),_c('div',{staticClass:"mt4",on:{"!click":function($event){return _vm.confirmCopy(value, $event)}}},[_c('AppProductSelectButton',{attrs:{"title":_vm.$t('fit_product.select_src_product'),"sub-title":value.name,"label":_vm.$t('local.button_copy'),"default-filters":[
                    'fit_data:partial_or_full',
                    ("exclude_id:" + (value.id))
                  ]},on:{"select":function($event){return _vm.copyFitSizeProduct({
                      product: value,
                      srcProduct: $event
                    })}}})],1)])]:_vm._e()]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }