<template>
  <div class="ReviewDashboardMileage">
    <AppTipBox :text="$t('mileage_tip_box.text')" />
    <div
      :class="[
        'ReviewDashboardMileage__mileage-criteria',
        {
          'ReviewDashboardMileage__mileage-criteria--disabled': isCompleted
        }
      ]"
    >
      {{ $t('mileage_criteria') }}
    </div>
    <AppForm v-bind="{ ...formProps, disabled: isCompleted }" v-on="formEvents">
      <template #group>
        <div
          :class="[
            'ReviewDashboardMileage__amount-label',
            {
              'ReviewDashboardMileage__amount-label--disabled': isCompleted
            }
          ]"
        >
          <i18n path="min_message_length_above">
            <AppNumberInput
              v-model="mileage.minimum_message_length"
              :digits="3"
              :disabled="isCompleted"
            />
          </i18n>
        </div>
        <AppTable
          :columns="COLUMNS"
          :rows="rows"
          align="left"
          :class="[
            'ReviewDashboardMileage__table',
            {
              'ReviewDashboardMileage__table--disabled': isCompleted
            }
          ]"
        >
          <template #cell="{ column }">
            <template v-if="column.id === 'auto_mileage_product_price_cents'">
              <i18n path="product_price_upper">
                <AppCurrencyInput
                  v-model="mileage.auto_mileage_product_price_cents"
                  :digits="6"
                  :disabled="isCompleted"
                />
              </i18n>
            </template>
            <template v-else>
              <AppCurrencyInput
                v-model="mileage[column.id]"
                :digits="6"
                :disabled="isCompleted"
              />
            </template>
          </template>
        </AppTable>
      </template>
    </AppForm>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormView from '@/mixins/FormView';

export default {
  name: 'ReviewDashboardMileage',
  mixins: [FormView],
  props: {
    isLoading: { type: Boolean, required: true },
    isCompleted: { type: Boolean, required: true }
  },
  data() {
    return {
      COLUMNS: [
        'auto_mileage_product_price_cents',
        'auto_mileage_text_review_upper_product_price_cents',
        'auto_mileage_photo_review_upper_product_price_cents'
      ].map(id => ({ id, label: this.$t(id) }))
    };
  },
  computed: {
    ...mapGetters('easyJoin', ['autoMileageBasicSettingsData']),
    mileage() {
      return this.formObject;
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'mileage',
              label: null
            }
          ]
        }
      ];
    },
    rows() {
      return [
        {
          auto_mileage_product_price_cents: {},
          auto_mileage_text_review_upper_product_price_cents: {},
          auto_mileage_photo_review_upper_product_price_cents: {}
        }
      ];
    }
  },
  watch: {
    isLoading(newValue) {
      if (newValue && !this.hasError) {
        this.formEventBus.$emit('submit');
      } else this.$emit('loaded');
    },
    hasError(newValue) {
      this.$emit('errored', !!newValue);
      this.$emit('loaded');
    }
  },
  created() {
    this.orgFormObject = this.autoMileageBasicSettingsData;
  },
  methods: {
    ...mapActions('easyJoin', ['updateOnboardingProgress']),
    submit() {
      this.updateOnboardingProgress({
        mileage_completed: !this.isCompleted,
        auto_mileage_basic_settings_data: this.mileage
      })
        .then(() => {
          this.orgFormObject = { ...this.formObject };
          this.$emit('submitted');
        })
        .finally(() => {
          this.$emit('loaded');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewDashboardMileage__mileage-criteria {
  @include text-label;

  &--disabled {
    color: $color-grey-40;
  }
}
.ReviewDashboardMileage__amount-label {
  @include text-label;
  margin: 8px 0;

  &--disabled {
    color: $color-grey-40;
  }
}
.ReviewDashboardMileage__table {
  &--disabled {
    color: $color-grey-40;
  }
}
</style>

<i18n locale="ko">
{
  "mileage_criteria": "기본 적립금 기준",
  "auto_mileage_product_price_cents": "구매 당시 상품 가격 기준",
  "auto_mileage_text_review_upper_product_price_cents": "텍스트 리뷰 지급액",
  "auto_mileage_photo_review_upper_product_price_cents": "포토/동영상 리뷰 지급액",
  "min_message_length_above": "{0} 글자 이상일 경우",
  "product_price_upper": "{0} 이상일 때",
  "mileage_tip_box": {
    "text": "리뷰 사용 준비 완료 후 리뷰 설정 메뉴에서 더 상세한 적립금 조건 설정이 가능합니다."
  }
}
</i18n>
