<template>
  <div class="AppMiniPercentageBarChart">
    <span class="AppMiniPercentageBarChart__title">
      {{ title }}
    </span>
    <AppChartPercentageBar
      v-bind="{
        numerator,
        denominator,
        chartColor
      }"
    >
      <template v-if="label" #label="{ percentage }">
        <span class="AppMiniPercentageBarChart__label">
          {{ label }}
          <AppNumber
            :value="percentage"
            :precision="2"
            unit="percentage"
            placeholder="-%"
          />
        </span>
      </template>
    </AppChartPercentageBar>
    <!-- eslint-disable vue/no-v-html -->
    <span
      v-if="description"
      class="AppMiniPercentageBarChart__description"
      v-html="description"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
export default {
  name: 'AppMiniPercentageBarChart',
  props: {
    numerator: { type: Number, required: true },
    denominator: { type: Number, required: true },
    chartColor: { type: String, default: 'blue' },
    title: { type: String, default: null },
    label: { type: String, default: null },
    description: { type: String, default: null }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppMiniPercentageBarChart {
  position: relative;
  display: inline-block;

  & + & {
    margin-left: 68px;
  }
}

.AppMiniPercentageBarChart__title {
  color: $color-grey-50;
  font-size: 12px;
}

.AppMiniPercentageBarChart__label {
  margin-left: 16px;
  font-size: 11px;
  font-weight: bold;
  color: #777575;
  line-height: 18px;
}

.AppMiniPercentageBarChart__description {
  padding-top: 17px;
  font-size: 10px;
  color: #777575;
  line-height: 18px;
}

::v-deep {
  .AppChartPercentageBar__bar {
    border-radius: 2px;
    width: 100px;
  }

  .AppChartPercentageBar__fill {
    border-radius: 2px;
  }
}
</style>
