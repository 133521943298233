<template>
  <div class="ReviewBulkReviewCsvs">
    <AppTabContent menu-item-id="review_bulk_review_csvs" />
  </div>
</template>

<script>
export default {
  name: 'ReviewBulkReviewCsvs'
};
</script>
