<template>
  <div>
    <div class="AppForm__group-field">
      <AppTextInput
        type="email"
        v-bind="{ id, name, value, placeholder, disabled }"
        :invalid="invalid || isEmailInvalid || isEmailUnverified"
        :readonly="!isEmailInputAvailable"
        @blur="blur"
        @change="change"
      />
    </div>
    <AppFormHint
      :message="isShopifyBrand ? null : hint"
      :priority="isEmailInvalid || isEmailUnverified ? 'danger' : null"
    />
    <div v-if="enableVerification" class="AppForm__group-field">
      <span v-if="isShopifyBrand" />
      <AppButton
        v-else-if="status === 'verified_and_used'"
        :label="$t('edit_button')"
        @click="clear()"
      />
      <span
        v-else-if="status === 'verified'"
        class="AppEmailVerficationInput__email--verified"
      >
        {{ $t('verified') }}
      </span>
      <AppButton
        v-else-if="isEmailInputAvailable"
        :label="$t('request_button')"
        :disabled="currentValue === '' || invalid"
        @click="requestCode"
      />
      <div v-else>
        <AppButton :label="$t('other_email_button')" @click="clear" />
        <AppButton
          :label="$t('resend.button')"
          button-style="blue-clear"
          @click="reRequestCode"
        />
      </div>
    </div>
    <template v-if="showCodeInput">
      <div class="AppForm__group-title AppEmailVerficationInput__code-title">
        <label>{{ $t('verification_code') }}</label>
      </div>
      <div class="AppForm__group-field">
        <div class="AppEmailVerficationInput__code-input">
          <AppTextInput
            v-model="code"
            :placeholder="$t('code_placeholder')"
            :invalid="isCodeFailed"
            :disabled="isCodeExpired"
            type="text"
          />
          <span
            :class="[
              'AppEmailVerficationInput__code-timer',
              status === 'expired'
                ? 'AppEmailVerficationInput__code-timer--expired'
                : null
            ]"
          >
            {{ remainingTime }}
          </span>
        </div>
        <AppFormHint
          v-if="isCodeFailed"
          :message="$t(`error.${status}`)"
          :priority="isCodeFailed ? 'danger' : null"
        />
      </div>
      <div class="AppForm__group-field">
        <AppButton
          :label="$t('confirm_button')"
          :disabled="token === null || code === '' || status === 'expired'"
          @click="verifyCode"
        />
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { isEmailFormatValid } from '@/lib/emailFormatValidator';
import { mapActions, mapGetters } from 'vuex';
import api from '@/lib/api';
import HttpStatus from '@/enums/HttpStatus';
import DialogSize from '@/enums/DialogSize';
import TextInput from '@/mixins/TextInput';

const VERFIY_CODE_LIFETIME = 300;
export default {
  name: 'AppEmailVerificationInput',
  mixins: [TextInput],
  props: {
    emailVerificationStatus: { type: String, default: 'none' },
    enableVerification: { type: Boolean, default: true }
  },
  data() {
    return {
      status: this.emailVerificationStatus || 'none',
      code: '',
      remainingSeconds: VERFIY_CODE_LIFETIME,
      token: null
    };
  },
  computed: {
    ...mapGetters('session', ['isShopifyBrand']),
    hint() {
      if (this.status === 'invalid') return this.$t('validations.email_format');
      else if (
        ['duplicated', 'unverified', 'verified_and_used'].includes(this.status)
      )
        return this.$t(`hint.${this.status}`);
      else return '';
    },
    remainingTime() {
      const minutes = Math.floor(this.remainingSeconds / 60);
      const seconds = this.remainingSeconds % 60;
      return `${_.padStart(minutes, 2, '0')}:${_.padStart(seconds, 2, '0')}`;
    },
    isEmailInputAvailable() {
      return ['none', 'invalid', 'duplicated', 'unverified'].includes(
        this.status
      );
    },
    isEmailInvalid() {
      return this.status === 'invalid' || this.status === 'duplicated';
    },
    isEmailUnverified() {
      return this.status === 'unverified';
    },
    showCodeInput() {
      return ['requested', 'expired', 'incorrect_code'].includes(this.status);
    },
    isCodeExpired() {
      return this.status === 'expired';
    },
    isCodeFailed() {
      return ['expired', 'incorrect_code'].includes(this.status);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    requestCode() {
      const email = this.currentValue;
      if (!email) return;

      if (!isEmailFormatValid(email)) return this.setStatus('invalid');

      this.sendCode(email);
    },
    reRequestCode() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('resend.alert_title'),
          markdownText: this.$t('resend.alert_content', { email: this.value }),
          markdownOption: { gfm: false },
          width: DialogSize.AUTO
        }
      ]);

      this.sendCode(this.currentValue);
    },
    sendCode(email) {
      api
        .post(
          '/email_verification_code',
          { email: email },
          { silent: true },
          { successMessage: this.$t('requested') }
        )
        .then(({ data }) => {
          data.email_verification_code.email === email
            ? (this.token = data.email_verification_code.token)
            : null;
          this.restartCodeConfirm();
          this.setStatus('requested');

          if (data.email_verification_code.code) {
            this.code = data.email_verification_code.code;
          }
        })
        .catch(error => {
          if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.setStatus(error.response.data.errors[0].error_type);
          } else error.errorHandler();
        });
    },
    verifyCode() {
      if (!this.code || !this.token) return;

      api
        .get('/email_verification_code/verify', {
          params: { token: this.token, code: this.code },
          silent: true
        })
        .then(() => {
          this.setStatus('verified');
          this.stopTimer();
        })
        .catch(error => {
          if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.setStatus(error.response.data.errors[0]);
          } else error.errorHandler();
        });
    },
    restartCodeConfirm() {
      this.setStatus('requested');
      this.code = '';

      const startedAt = moment();
      if (this.interval) clearInterval(this.interval);
      this.interval = setInterval(() => {
        const elapsed = moment().diff(startedAt, 'seconds');
        this.remainingSeconds = Math.max(VERFIY_CODE_LIFETIME - elapsed, 0);
        if (this.remainingSeconds === 0) {
          this.setStatus('expired');
          this.code = '';
          this.token = null;
          this.stopTimer();
        }
      }, 200);
    },
    stopTimer() {
      clearInterval(this.interval);
      this.interval = null;
    },
    clear() {
      this.change('');
      this.code = '';
      this.setStatus('none');
      this.token = null;
      this.stopTimer();
    },
    blur() {
      this.$emit('blur');
    },
    setStatus(status) {
      this.status = status;
      this.$emit('status', status);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppEmailVerficationInput__email--verified {
  color: $color-grey-50;
}

.AppEmailVerficationInput__code-title {
  margin-top: 32px;
}

.AppEmailVerficationInput__code-input {
  position: relative;
}

.AppEmailVerficationInput__code-timer {
  color: $color-grey-70;
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);

  &--expired {
    color: $color-red;
  }
}
</style>

<i18n locale="ko">
{
  "placeholder": "이메일을 설정해주세요.",
  "hint": {
    "verified_and_used": "인증된 이메일입니다. 변경을 원하면 '변경 요청' 버튼을 눌러 재인증받으세요.",
    "duplicated": "이미 사용중인 이메일입니다. 다른 이메일 주소를 입력해주세요.",
    "unverified": "이메일 인증이 필요합니다."
  },
  "edit_button": "변경 요청",
  "request_button": "인증메일 발송",
  "other_email_button": "다른 이메일 주소로 인증",
  "resend": {
    "button": "인증메일 재발송",
    "alert_title": "인증메일 재발송",
    "alert_content": "<u>{email}</u> 으로 인증 메일을 다시 발송하였습니다.<br /><br />이메일 서비스로 인해 메일 수신이 늦어질 수 있습니다. 혹은 스팸 메일함을 확인해주세요.<br />그럼에도 이메일을 받지 못하셨다면, 크리마 운영팀 (support@cre.ma)로 연락주시기 바랍니다."
  },
  "requested": "인증 이메일을 발송했습니다.",
  "verified": "인증이 완료되었습니다.",
  "verification_code": "인증번호",
  "confirm_button": "인증번호 확인",
  "code_placeholder": "메일로 발송한 인증번호를 입력해주세요.",
  "error": {
    "expired": "인증번호의 입력 유효시간이 만료되었습니다. 인증메일을 다시 발송해주세요. ",
    "incorrect_code": "잘못된 인증번호입니다. 확인 후, 다시 입력해주세요."
  }
}
</i18n>
