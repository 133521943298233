<template>
  <AppAjaxContent :is-loading="isLoading" class="ProductReviewsLink">
    <AppRadioLabel
      class="ProductReviewsLink__radio-label"
      :label="$t('product_reviews_link.title')"
      :tooltip="$t('product_reviews_link.tooltip')"
      :checked="false"
    />
    <AppSearchbar>
      <AppButton
        button-style="grey-outline"
        :label="$t('smart_installation.create')"
        @click="openProductReviewsLinkFormDialog"
      />
    </AppSearchbar>
    <AppTable
      table-id="product-review-link-table"
      :columns="COLUMNS"
      :rows="rows"
      :event-bus="tableEventBus"
    >
      <template #cell="{ column, rowIndex }">
        <div v-if="column.id === 'feature'">
          <AppButton
            :label="$t('app.settings')"
            @click="openProductReviewsLinkSettings(rowIndex)"
          />
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            @click="clickDeleteProductReviewsLink(rowIndex)"
          />
        </div>
      </template>
    </AppTable>
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import api from '@/lib/api';
import { mapActions } from 'vuex';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import SmartInstallationType from '@/enums/SmartInstallationType';
import SmartInstallationHelper from '@/mixins/SmartInstallationHelper';

export default {
  name: 'ProductReviewsLink',
  mixins: [SmartInstallationHelper],
  data() {
    return {
      productReviewsLinks: [],
      isLoading: true,
      tableEventBus: new Vue(),
      COLUMNS: [
        {
          id: 'installation_id',
          label: this.$t('table_head.installation_id')
        },
        {
          id: 'device_type',
          label: this.$t('table_head.device_type')
        },
        {
          id: 'page_type',
          label: this.$t('table_head.page_type')
        },
        {
          id: 'element_selector',
          label: this.$t('table_head.element_selector')
        },
        {
          id: 'feature',
          label: this.$t('table_head.feature')
        }
      ]
    };
  },
  computed: {
    rows() {
      return this.productReviewsLinks.map(reviewLink => ({
        installation_id: reviewLink.id,
        device_type: WidgetDeviceType.t(reviewLink.device_type),
        page_type: this.getPageTypeOrPath(reviewLink),
        element_selector: `${reviewLink.element_selector}`
      }));
    }
  },
  mounted() {
    api
      .get('/smart_installations', {
        params: {
          installation_type: SmartInstallationType.PRODUCT_REVIEWS_LINK
        }
      })
      .then(({ data }) => (this.productReviewsLinks = data.smart_installations))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openProductReviewsLinkFormDialog() {
      this.openDialog(['ProductReviewsLinkFormDialog']).then(eventBus =>
        eventBus.$on('save', productReviewsLink => {
          this.productReviewsLinks.push(productReviewsLink);
          this.$nextTick(() => {
            this.tableEventBus.$emit(
              'focus-row',
              this.productReviewsLink.length - 1
            );
          });
        })
      );
    },
    openProductReviewsLinkSettings(index) {
      const productReviewsLink = this.productReviewsLinks[index];

      this.openDialog([
        'ProductReviewsLinkFormDialog',
        { productReviewsLink: productReviewsLink }
      ]).then(eventBus =>
        eventBus.$on('update', productReviewsLink => {
          Vue.set(this.productReviewsLinks, index, productReviewsLink);
        })
      );
    },
    clickDeleteProductReviewsLink(index) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      api
        .delete(`/smart_installations/${this.productReviewsLinks[index].id}`, {
          successMessage: this.$t('app.deleted')
        })
        .then(() => {
          Vue.delete(this.productReviewsLinks, index);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppRadioLabel__icon {
  color: $color-grey-50;
}

.ProductReviewsLink__radio-label {
  font-weight: 600;
  margin-top: 30px;
  margin-left: -8px;
  margin-bottom: 4px;
}

::v-deep {
  .product-review-link-table__installation-id,
  .product-review-link-table__device-type,
  .product-review-link-table__page-type,
  .product-review-link-table__element-selector,
  .product-review-link-table__feature {
    min-width: 132px;
  }
  .product-review-link-table {
    margin-top: 12px;
  }
}
</style>

<i18n locale="ko">
{
  "table_head": {
    "installation_id": "설치 ID",
    "device_type": "디바이스",
    "page_type": "설치 페이지",
    "element_selector": "설치 위치",
    "feature": "기능"
  },
  "smart_installation": {
    "create": "+ 간편 설치 추가"
  },
  "product_reviews_link": {
    "tooltip": "쇼핑몰 상품 리뷰 게시판 이동 버튼에 크리마 상품 리뷰 리스트 위젯이 노출되도록 설치할 수 있습니다.",
    "title": "상품 리뷰 리스트 위젯 팝업 설치"
  }
}
</i18n>
