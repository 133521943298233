<template>
  <div class="SummaryBoxFreeTrial">
    <AppSummaryBox>
      <template #title>
        {{ $t('title') }}
      </template>
      <pre>{{ $t('content.description') }}</pre>
      <i18n class="SummaryBoxFreeTrial__content-inquiry" path="content.inquiry">
        <AppExternalLink
          class="SummaryBoxFreeTrial__content-link"
          :to="imwebInquiryLink"
        >
          <span>{{ $t('content.link') }}</span>
        </AppExternalLink>
      </i18n>
      <template #buttons>
        <AppButton
          :label="$t('button_label')"
          button-style="blue"
          @click="openFreeTrialConfirmDialog"
        />
      </template>
    </AppSummaryBox>
  </div>
</template>

<script>
import api from '@/lib/api';
import { mapActions } from 'vuex';

export default {
  name: 'SummaryBoxFreeTrial',
  computed: {
    imwebInquiryLink() {
      return 'https://imweb.me/faq?mode=view&category=29&category2=47&idx=72184';
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('session', ['fetchSession', 'redirectToHome']),
    openFreeTrialConfirmDialog() {
      this.openDialog(['FreeTrialConfirmDialog']).then(eventBus =>
        eventBus.$on('confirm', () => this.startFreeTrial())
      );
    },
    startFreeTrial() {
      this.$emit('loading', true);

      api
        .post('/easy_join/free_trials')
        .then(() =>
          this.fetchSession().then(() => {
            this.$emit('loading', false);
            this.redirectToHome();
          })
        )
        .finally(() => this.$emit('loading', false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.SummaryBoxFreeTrial__content-inquiry {
  color: $color-grey-60;
  line-height: 200%;
}

.SummaryBoxFreeTrial__content-link {
  color: $color-brand-main;
  text-decoration: underline;
}
</style>

<i18n locale="ko">
  {
    "title": "📌 크리마 리뷰 사용 준비가 완료되었습니다.",
    "content": {
      "description": "아래 버튼을 눌러 무료체험을 시작해주세요.\n무료 체험 시작 즉시 크리마 리뷰 위젯 노출 및 동작을 시작합니다.",
      "inquiry": "설정 및 위젯 설치에 대한 질문이 있거나 무료 체험 시작 후 문제가 생긴 경우 {0}를 참고해주세요.",
      "link": "가이드 문서"
    },
    "button_label": "30일 무료 체험 시작"
  }
</i18n>
