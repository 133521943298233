<template>
  <AppModalForm
    :title="$t('recipients', [dispatchText])"
    :sub-title="campaign.name"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId: 'campaign',
      disabled: isArchivedCampaign
    }"
    v-on="formEvents"
    @blur-group="formGroupBlurred"
  >
    <template #group="{ id, disabled, fields, errors }">
      <template v-if="id === 'recipients'">
        <div class="AppForm__group-field">
          <i18n :path="descriptionPath" tag="pre">
            <Component
              :is="f.component || 'AppNumberInput'"
              v-for="f in fields.filter(f => !f.isValidator)"
              :key="f.id"
              :name="filterItemIdToName(f.id)"
              :value="f.value"
              :digits="f.digits || 2"
              inline
              :class="f.class"
              :disabled="disabled || f.disabled"
              :invalid="!!(errors[f.id] || errors[f.validatorId])"
              @blur="validateField(f.id, f.validatorId)"
              @change="setRecipientsValue(f.id, $event, f.validatorId)"
            />
          </i18n>
          <AppFormError
            :error="fields.map(f => errors[f.id]).filter(e => e)[0]"
          />
        </div>
      </template>
      <template v-else-if="id === 'lock_interval'">
        <div v-for="{ id } in fields" :key="id" class="AppForm__group-field">
          <i18n :path="id">
            <AppNumberInput
              v-model="formObject[id]"
              :name="`campaign[${id}]`"
              :digits="2"
              inline
              :disabled="disabled"
              :invalid="!!errors[id]"
              @blur="validateField(id)"
              @change="validateField(id)"
            />
          </i18n>
          <AppFormError :error="errors[id]" />
        </div>
      </template>
      <template v-else-if="id === 'utm_popup_check_brand_user'">
        <div class="AppForm__group-field">
          <div class="AppForm__sub-group">
            <AppSelectRadio
              v-model="formObject.utm_popup_brand_user_only"
              name="campaign[utm_popup_brand_user_only]"
              :disabled="!formObject.utm_popup_check_brand_user"
              :options="[
                {
                  label: $t('group.utm_popup_brand_user_only.true'),
                  value: true
                },
                {
                  label: $t('group.utm_popup_brand_user_only.false'),
                  value: false
                }
              ]"
            />
          </div>
        </div>
      </template>
    </template>
    <template #group-label="{ group, disabled, value, checked, errors }">
      <template v-if="group.id === 'product_click_filter.date_range_type'">
        <template v-if="value === FilterDateRangeType.RECENT_DAYS">
          <i18n path="FilterDateRangeType.RECENT_DAYS">
            <AppNumberInput
              v-for="id in ['recent_days_since', 'recent_days_until']"
              :key="id"
              v-model="formObject.product_click_filter[id]"
              :name="`campaign[product_click_filter][${id}]`"
              :digits="2"
              inline
              :disabled="disabled || !checked"
              :invalid="
                !!errors[`product_click_filter.${id}`] ||
                  !!errors['product_click_filter.recent_days']
              "
              @blur="
                validateField(
                  `product_click_filter.${id}`,
                  'product_click_filter.recent_days'
                )
              "
              @change="
                validateField(
                  `product_click_filter.${id}`,
                  'product_click_filter.recent_days'
                )
              "
            />
          </i18n>
          <AppFormError
            :error="
              errors['product_click_filter.recent_days_since'] ||
                errors['product_click_filter.recent_days_until'] ||
                errors['product_click_filter.recent_days']
            "
          />
        </template>
      </template>
    </template>
    <template #group-sub-item="{ group, disabled, value, checked, errors }">
      <template v-if="group.id === 'product_click_filter.date_range_type'">
        <template v-if="value === FilterDateRangeType.DATE_RANGE">
          <AppDateRangePicker
            v-model="formObject.product_click_filter"
            required
            :invalid="!!errors['product_click_filter.date_range']"
            :disabled="disabled || !checked"
            @blur="validateField('product_click_filter.date_range')"
            @change="validateField('product_click_filter.date_range')"
          />
          <input
            type="hidden"
            name="campaign[product_click_filter][start_date]"
            :value="formObject.product_click_filter.start_date"
          />
          <input
            type="hidden"
            name="campaign[product_click_filter][end_date]"
            :value="formObject.product_click_filter.end_date"
          />
          <AppFormError :error="errors['product_click_filter.date_range']" />
        </template>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TargetCampaignType from '@/enums/TargetCampaignType';
import FilterDateRangeType from '@/enums/FilterDateRangeType';
import TargetCampaignSettingsDispatchRecipientsDialogView from './TargetCampaignSettingsDispatchRecipientsDialogView';

export default {
  name: 'TargetCampaignSettingsDispatchRecipientsDialog',
  mixins: [TargetCampaignSettingsDispatchRecipientsDialogView],
  computed: {
    ...mapState(['env']),
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('targetCampaign', [
      'isRecurringCampaign',
      'isDmCampaign',
      'isArchivedCampaign'
    ]),
    ...mapGetters('targetCampaigns', ['campaignText']),
    FilterDateRangeType: () => FilterDateRangeType,
    dispatchText() {
      return this.campaignText(this.campaign, 'dispatch');
    },
    formSections() {
      if (this.isLoading) return [];

      return [
        {
          id: 'basic',
          groups: this.basicGroupIds.map(this.finalGroupOption)
        },
        ...(this.showAdvancedSettings
          ? [
              {
                id: 'advanced',
                label: this.$t('app.advanced_settings'),
                tooltip: this.$t('advanced_tooltip'),
                groups: this.advancedGroupIds.map(this.finalGroupOption)
              }
            ]
          : [])
      ];
    },
    showAdvancedSettings() {
      return this.isRecurringCampaign && this.advancedGroupIds.length > 0;
    },
    recipientsFields() {
      const dateRangeValidator = (minId, maxId) => ({
        rule: ([min, max]) => !min || !max || min <= max,
        errorMessage: this.$t('validations.max_days_should_gte_min_days'),
        value: () => [minId, maxId].map(id => this.getRecipientsValue(id))
      });

      switch (this.campaignType) {
        case TargetCampaignType.NEW_MEMBER_REENGAGEMENT:
          return [
            {
              id: 'signup_filter.days_since_signup',
              validate: ['required', 'positive_integer']
            }
          ];
        case TargetCampaignType.CART_REMINDING:
          return [
            {
              id: 'cart_filter.recent_days_since',
              validate: ['required'],
              validatorId: 'cart_filter.recent_days'
            },
            {
              id: 'cart_filter.recent_days_until',
              validate: ['required'],
              validatorId: 'cart_filter.recent_days'
            },
            {
              id: 'cart_filter.recent_days',
              isValidator: true,
              validate: [
                dateRangeValidator(
                  'cart_filter.recent_days_until',
                  'cart_filter.recent_days_since'
                )
              ]
            }
          ];
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
          return [
            {
              id: 'unpaid_order_filter.max_elapsed_days',
              validate: ['required', { rule: 'lte', allowed: 90 }],
              validatorId: 'unpaid_order_filter.elapsed_days'
            },
            {
              id: 'unpaid_order_filter.min_elapsed_days',
              validate: ['required'],
              validatorId: 'unpaid_order_filter.elapsed_days'
            },
            {
              id: 'unpaid_order_filter.elapsed_days',
              isValidator: true,
              validate: [
                dateRangeValidator(
                  'unpaid_order_filter.min_elapsed_days',
                  'unpaid_order_filter.max_elapsed_days'
                )
              ]
            }
          ];
        case TargetCampaignType.MILEAGE_EXPIRING:
          return [
            {
              id: 'mileage_expiring_filter.days_before_expiration_since',
              validate: ['required'],
              validatorId: 'mileage_expiring_filter.days_before_expiration'
            },
            {
              id: 'mileage_expiring_filter.days_before_expiration_until',
              validate: ['required'],
              validatorId: 'mileage_expiring_filter.days_before_expiration'
            },
            {
              id: 'mileage_expiring_filter.days_before_expiration',
              isValidator: true,
              validate: [
                dateRangeValidator(
                  'mileage_expiring_filter.days_before_expiration_until',
                  'mileage_expiring_filter.days_before_expiration_since'
                )
              ]
            }
          ];
        case TargetCampaignType.COUPON_EXPIRY:
          return [
            {
              id: 'coupon_expiry_filter.days_before_expiration',
              validate: ['required']
            }
          ];
        case TargetCampaignType.INDUCING_REPURCHASE:
          return [
            {
              id: 'order_filter.recent_days_since',
              digits: 3,
              validate: ['required'],
              validatorId: 'order_filter.recent_days'
            },
            {
              id: 'order_filter.recent_days_until',
              digits: 3,
              validate: ['required'],
              validatorId: 'order_filter.recent_days'
            },
            {
              id: 'order_filter.recent_days',
              isValidator: true,
              validate: [
                dateRangeValidator(
                  'order_filter.recent_days_until',
                  'order_filter.recent_days_since'
                )
              ]
            },
            {
              id: 'order_filter.sub_orders_count',
              validate: ['required', 'positive_integer']
            },
            {
              id: 'order_filter.recent_days_until_dup',
              component: 'AppNumber',
              value:
                this.getRecipientsValue('order_filter.recent_days_until') - 1
            },
            {
              id: 'product_click_filter.recent_days_since',
              validate: ['required'],
              validatorId: 'product_click_filter.recent_days'
            },
            {
              id: 'product_click_filter.recent_days_until',
              validate: ['required'],
              validatorId: 'product_click_filter.recent_days'
            },
            {
              id: 'product_click_filter.recent_days',
              isValidator: true,
              validate: [
                dateRangeValidator(
                  'product_click_filter.recent_days_until',
                  'product_click_filter.recent_days_since'
                )
              ]
            },
            {
              id: 'product_click_filter.clicks_count',
              validate: ['required', 'positive_integer']
            }
          ];
        case TargetCampaignType.INTERESTED_PRODUCTS:
          return [
            {
              id: 'repetitive_product_click_filter.recent_days_since',
              validate: ['required'],
              validatorId: 'repetitive_product_click_filter.recent_days'
            },
            {
              id: 'repetitive_product_click_filter.recent_days_until',
              validate: ['required'],
              validatorId: 'repetitive_product_click_filter.recent_days'
            },
            {
              id: 'repetitive_product_click_filter.recent_days',
              isValidator: true,
              validate: [
                dateRangeValidator(
                  'repetitive_product_click_filter.recent_days_until',
                  'repetitive_product_click_filter.recent_days_since'
                )
              ]
            },
            {
              id: 'repetitive_product_click_filter.clicks_count',
              validate: ['required', 'positive_integer']
            }
          ];
        case TargetCampaignType.SELECTED_PRODUCTS:
        case TargetCampaignType.SELECTED_CATEGORIES:
          return [
            {
              id: 'product_click_filter.clicks_count',
              validate: ['required', 'positive_integer']
            }
          ];
        case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
          return [
            {
              id: 'member_inactivation_filter.days_before_inactivation',
              disabled: !this.isSuperAdminAccessible,
              class: this.isSuperAdminAccessible ? 'super-admin__item' : null,
              validate: ['required']
            }
          ];
        case TargetCampaignType.ACTIVE_MEMBERS:
          return [
            {
              id: 'active_members_filter.active_since_days_ago',
              validate: ['required']
            }
          ];
        case TargetCampaignType.INACTIVE_MEMBERS:
          return [
            {
              id: 'inactive_members_filter.inactive_since_days_ago',
              validate: ['required']
            }
          ];
        default:
          return [];
      }
    },
    basicGroupIds() {
      switch (this.campaignType) {
        case TargetCampaignType.SELECTED_PRODUCTS:
          return [
            'recipients',
            'product_click_filter.product_ids',
            'product_click_filter.date_range_type'
          ];
        case TargetCampaignType.SELECTED_CATEGORIES:
          return [
            'recipients',
            'product_click_filter.category_ids',
            'product_click_filter.date_range_type'
          ];
        case TargetCampaignType.UTM_POPUP:
          return ['utm_term', 'utm_popup_check_brand_user'];
        default:
          return ['recipients'];
      }
    },
    advancedGroupIds() {
      switch (this.campaignType) {
        case TargetCampaignType.COUPON_EXPIRY:
          return this.isDmCampaign
            ? []
            : ['advanced_settings_info', 'lock_interval'];
        case TargetCampaignType.NEW_MEMBER_REENGAGEMENT:
        case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
        case TargetCampaignType.INACTIVE_MEMBER_REENGAGEMENT:
          return [];
        case TargetCampaignType.CART_REMINDING:
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
        case TargetCampaignType.INTERESTED_PRODUCTS:
          return [
            'advanced_settings_info',
            'restrict_targeted_item',
            'lock_interval'
          ];
        case TargetCampaignType.SELECTED_PRODUCTS:
        case TargetCampaignType.SELECTED_CATEGORIES:
          return [
            'advanced_settings_info',
            'product_click_filter.logout_data',
            'product_click_filter.exclude_purchased_product',
            'restrict_targeted_item',
            'lock_interval'
          ];
        case TargetCampaignType.INDUCING_REPURCHASE:
          return [
            'advanced_settings_info',
            'product_click_filter.logout_data',
            'restrict_targeted_item',
            'lock_interval'
          ];
        default:
          return ['advanced_settings_info', 'lock_interval'];
      }
    }
  },
  methods: {
    filterItemIdToName(id) {
      return `campaign${id
        .split('.')
        .map(id => `[${id}]`)
        .join('')}`;
    },
    finalGroupOption(id) {
      const option = this.groupOption(id);
      return {
        id,
        ...option,
        name: this.filterItemIdToName(id),
        label: 'label' in option ? option.label : this.$t(`group.${id}.label`)
      };
    },
    groupOption(id) {
      const { product_click_filter } = this.formObject;
      switch (id) {
        case 'recipients': {
          const fields = this.recipientsFields.map(field => ({
            ...field,
            value: field.value || this.getRecipientsValue(field.id)
          }));
          const options = {
            label: this.$t('recipients', [this.dispatchText]),
            required: !!fields.length,
            fields
          };
          const { repetitive_product_click_filter } = this.formObject;
          if (repetitive_product_click_filter?.logout_data) {
            options['groupDescription'] = this.$t(
              'group.product_click_filter.logout_data.description'
            );
          }
          return options;
        }
        case 'product_click_filter.product_ids':
          return {
            type: 'select_product',
            products: product_click_filter.products,
            eventHandlers: {
              'change-items': products =>
                this.$set(product_click_filter, 'products', products)
            },
            multiple: true,
            sort: false,
            validate: ['required']
          };
        case 'product_click_filter.category_ids':
          return {
            type: 'select_product_category',
            multiple: true,
            validate: ['required']
          };
        case 'product_click_filter.date_range_type':
          return {
            type: 'select_radio',
            options: FilterDateRangeType.options(),
            required: true,
            fields:
              product_click_filter.date_range_type ===
              FilterDateRangeType.RECENT_DAYS
                ? [
                    {
                      id: 'product_click_filter.recent_days_since',
                      validate: ['required']
                    },
                    {
                      id: 'product_click_filter.recent_days_until',
                      validate: ['required']
                    },
                    {
                      id: 'product_click_filter.recent_days',
                      validate: [
                        {
                          rule: () =>
                            product_click_filter.recent_days_since >=
                            product_click_filter.recent_days_until,
                          errorMessage: this.$t(
                            'validations.max_days_should_gte_min_days'
                          )
                        }
                      ]
                    }
                  ]
                : product_click_filter.date_range_type ===
                  FilterDateRangeType.DATE_RANGE
                ? [
                    {
                      id: 'product_click_filter.date_range',
                      validate: [
                        {
                          rule: () =>
                            !!(
                              product_click_filter.start_date &&
                              product_click_filter.end_date
                            ),
                          errorMessage: this.$t('validations.required')
                        }
                      ]
                    }
                  ]
                : []
          };
        case 'advanced_settings_info':
          return {
            label: '',
            type: 'infobox',
            value: this.$t('advanced_settings_info_html')
          };
        case 'product_click_filter.logout_data':
          return {
            type: 'checkbox',
            description: this.$t(
              'group.product_click_filter.logout_data.description'
            )
          };
        case 'product_click_filter.exclude_purchased_product':
          return {
            type: 'checkbox',
            description: this.$t(
              'group.product_click_filter.exclude_purchased_product.description'
            )
          };
        case 'restrict_targeted_item':
          return {
            type: 'checkbox',
            description: this.$t('group.restrict_targeted_item.description'),
            superAdmin: true
          };
        case 'lock_interval': {
          const isNoIntervalAllowed =
            this.isSuperAdminAccessible && this.env !== 'production';
          return this.isDmCampaign
            ? {
                label: this.$t('group.lock_interval.label', [
                  this.dispatchText
                ]),
                required: true,
                groupDescription: this.$t('group.lock_interval.description_dm'),
                hint: isNoIntervalAllowed
                  ? this.$t('group.lock_interval.hint_dm_qa')
                  : this.$t('group.lock_interval.hint_dm'),
                fields: [
                  {
                    id: 'sms_lock_interval',
                    validate: [
                      'required',
                      {
                        rule: 'min_max_integer',
                        min: isNoIntervalAllowed ? 0 : 6,
                        max: 365
                      }
                    ]
                  },
                  {
                    id: 'email_lock_interval',
                    validate: [
                      'required',
                      {
                        rule: 'min_max_integer',
                        min: isNoIntervalAllowed ? 0 : 6,
                        max: 365
                      }
                    ]
                  }
                ]
              }
            : {
                label: this.$t('group.lock_interval.label', [
                  this.dispatchText
                ]),
                required: true,
                groupDescription: this.$t(
                  'group.lock_interval.description_popup'
                ),
                fields: [
                  {
                    id: 'popup_lock_interval',
                    validate: [
                      'required',
                      {
                        rule: 'min_max_integer',
                        min: isNoIntervalAllowed ? 0 : 1,
                        max: 365
                      }
                    ]
                  }
                ]
              };
        }
        case 'utm_term':
          return {
            type: 'text',
            placeholder: this.$t('group.utm_term.placeholder'),
            groupDescription: this.$t('group.utm_term.description')
          };
        case 'utm_popup_check_brand_user':
          return {
            type: 'checkbox',
            groupDescription: this.$t(
              'group.utm_popup_check_brand_user.description'
            )
          };
      }
    },
    formGroupBlurred(id) {
      if (id !== 'utm_term') return;

      const utmTermValue = this.formObject.utm_term;
      this.$set(this.formObject, 'utm_term', utmTermValue.replace(/\s/g, ''));
    }
  }
};
</script>

<i18n locale="ko">
{
  "recipients": "{0} 대상",
  "group": {
    "product_click_filter": {
      "product_ids": {
        "label": "상품 선택"
      },
      "category_ids": {
        "label": "카테고리 선택"
      },
      "date_range_type": {
        "label": "기간 선택"
      },
      "logout_data": {
        "label": "비로그인 회원 데이터 사용",
        "description": "비로그인 회원의 상품 열람 데이터는 회원 브라우저의 쿠키를 통해 집계합니다."
      },
      "exclude_purchased_product": {
        "label": "구매한 상품 데이터 사용 제외",
        "description": "이미 구매한 상품 데이터는 타겟 발송의 근거로 사용하지 않습니다."
      }
    },
    "restrict_targeted_item": {
      "label": "이전 발송 시 사용한 데이터 사용 제외",
      "description": "이전 타겟 발송의 근거로 사용한 사용자 데이터를 제외합니다.<br />지난 발송 이후 사용한 적이 없거나 새로 수집한 데이터만 취합해 타겟 발송 여부를 판단합니다."
    },
    "lock_interval": {
      "label": "1명에 대한 {0} 간격",
      "description_dm": "이 캠페인을 발송한 사람에게 동일한 캠페인을 발송하지 않을 기간을 매체별로 설정합니다.<br />간격이 너무 짧거나 긴 경우 캠페인 효율 및 고객의 피로도에 영향을 줄 수 있습니다.",
      "description_popup": "이 캠페인 팝업을 노출한 사람에게 동일한 팝업을 노출하지 않을 기간을 설정합니다.",
      "hint_dm": "최소 6일부터 최대 365일까지 설정할 수 있습니다.",
      "hint_dm_qa": "최소 6일부터 최대 365일까지 설정할 수 있습니다.\n테스트 서버에서 최고관리자 계정으로 접속 중이기 때문에 최소값 제한 없이 설정할 수 있습니다."
    },
    "utm_term": {
      "label": "utm_term",
      "placeholder": "utm_term 값을 입력해주세요.",
      "description": "쇼핑몰 방문자 중 아래 입력한 utm_term을 가진 경우 팝업 캠페인을 실행합니다."
    },
    "utm_popup_check_brand_user": {
      "label": "회원, 비회원 구분",
      "description": "쇼핑몰 회원 정보와 연결된 device token을 기준으로 로그인 여부와 무관하게 회원, 비회원을 구분합니다."
    },
    "utm_popup_brand_user_only": {
      "true": "회원에게만 팝업을 노출합니다.",
      "false": "비회원에게만 팝업을 노출합니다."
    }
  },
  "advanced_tooltip": "발송을 위한 필수 설정 이외의 고급 설정을 수정할 수 있습니다.",
  "sms_lock_interval": "문자 메시지 {0}일",
  "email_lock_interval": "이메일 {0}일",
  "popup_lock_interval": "팝업 {0}일",
  "advanced_settings_info_html": "고급 설정은 세밀한 캠페인 사용을 위한 설정모음 입니다.<br />해당 설정을 잘 모르신다면 기본 설정 사용을 추천합니다."
}
</i18n>
