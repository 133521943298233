import { constructEnum } from '@/lib/enum';
import DisplayProductManagementExecuteStatus from './DisplayProductManagementExecuteStatus';
import BrandCsvType from './BrandCsvType';

const DisplayProductManagementCohortProgressStatus = constructEnum(
  'DisplayProductManagementCohortProgressStatus',
  {
    PENDING: 0,
    SYNCHRONIZING: 100,
    PREPARING_PRODUCT_DATA: 200,
    CALCULATING: 300,
    DISPATCHING: 400
  }
);

DisplayProductManagementCohortProgressStatus.tByCohortStatus = function(
  cohortStatus,
  csvType
) {
  switch (cohortStatus) {
    case DisplayProductManagementExecuteStatus.NONE:
    case DisplayProductManagementExecuteStatus.PENDING:
      return this.t(DisplayProductManagementCohortProgressStatus.PENDING);
    case DisplayProductManagementExecuteStatus.PREPARING:
      if (csvType)
        return this.t(
          DisplayProductManagementCohortProgressStatus.SYNCHRONIZING,
          [BrandCsvType.t(csvType)]
        );
      else
        return this.t(
          DisplayProductManagementCohortProgressStatus.PREPARING_PRODUCT_DATA
        );
    case DisplayProductManagementExecuteStatus.CALCULATING:
    case DisplayProductManagementExecuteStatus.CALCULATED:
      return this.t(DisplayProductManagementCohortProgressStatus.CALCULATING);
    case DisplayProductManagementExecuteStatus.DISPATCH_CONFIRMED:
    case DisplayProductManagementExecuteStatus.DISPATCHING:
      return this.t(DisplayProductManagementCohortProgressStatus.DISPATCHING);
  }
};

export default DisplayProductManagementCohortProgressStatus;
