<template>
  <AppLink
    :label="label"
    :href="to"
    target="_blank"
    :link-style="linkStyle"
    @click="$emit('click')"
  >
    <slot />
  </AppLink>
</template>

<script>
export default {
  name: 'AppExternalLink',
  props: {
    label: { type: String, default: '' },
    to: { type: String, default: null },
    linkStyle: { type: String, default: 'default' }
  }
};
</script>
