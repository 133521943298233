import Scrollable from '@/mixins/Scrollable';

export default {
  mixins: [Scrollable],
  props: {
    eventBus: { type: Object, required: true }
  },
  data() {
    return { activeIndex: 0 };
  },
  mounted() {
    this.eventBus.$on('select-item', this.selectItem);

    this.eventBus.$on(
      'activate-prev',
      () => (this.activeIndex = Math.max(this.activeIndex - 1, 0))
    );

    this.eventBus.$on('activate-next', () => {
      const maxIndex = this.itemsCount - 1;
      this.activeIndex = Math.min(this.activeIndex + 1, maxIndex);
    });
  },
  watch: {
    activeIndex() {
      const item = this.$refs.items[this.activeIndex];
      if (item) this.scrollIntoView(item);
    }
  },
  methods: {
    activateItem(index) {
      this.activeIndex = index;
    }
  }
};
