<template>
  <AppTable
    class="DisplayProductManagementManagedItemsTable"
    :columns="columns"
    :rows="rows"
  >
    <template #cell="{ column, row, value }">
      <template v-if="column.id === 'using_management'">
        {{ $t(`using_management.${value}`) }}
      </template>
      <template v-else-if="column.id === 'description'">
        <template v-if="row.id === 'category'">
          <div
            v-if="isCategorySelected"
            ref="categoryName"
            v-tooltip="{
              message: autoTooltip(true, 'categoryName')
            }"
            class="DisplayProductManagementManagedItemsTable__category"
          >
            {{ `${productCategoryName(managedItemsData.category_id)}&nbsp;` }}
          </div>
          <div
            class="DisplayProductManagementManagedItemsTable__category-description"
          >
            {{ $t(`description.category.${isCategorySelected}`) }}
          </div>
        </template>
        <template v-else-if="row.id === 'icon'">
          <div v-for="iconId in row.descriptionValueIds" :key="iconId">
            <template v-if="managedItemsData[iconId].length > 0">
              <span>{{ $t(`description.icon.${iconId}`) }}</span
              ><DisplayProductManagementProductIcons
                class="DisplayProductManagementManagedItemsTable__icons"
                :icon-ids="managedItemsData[iconId]"
              />
            </template>
          </div>
          <template
            v-if="
              !managedItemsData.pc_icon_ids.length &&
                !managedItemsData.mobile_icon_ids.length
            "
          >
            {{ $t('description.icon.none') }}
          </template>
          <input
            type="hidden"
            name="product_management[use_icon_overflow_management]"
            :value="managedItemsData.use_icon_overflow_management"
          />
        </template>
      </template>
      <template v-else-if="column.id === 'actions'">
        <AppButton
          :label="$t('app.settings')"
          @click="openManagementDialog(row.id)"
        />
        <input
          type="hidden"
          :name="`product_management[use_${row.id}_management]`"
          :value="row.using_management"
        />
        <template v-if="row.id === 'icon'">
          <input
            v-for="iconId in managedItemsData.pc_icon_ids"
            :key="`input-pc_icon_ids-${iconId}`"
            type="hidden"
            name="product_management[pc_icon_ids][]"
            :value="iconId"
          />
          <input
            v-for="iconId in managedItemsData.mobile_icon_ids"
            :key="`input-mobile_icon_ids-${iconId}`"
            type="hidden"
            name="product_management[mobile_icon_ids][]"
            :value="iconId"
          />
        </template>
        <template v-else>
          <input
            v-for="valueId in row.descriptionValueIds"
            :key="`input-${valueId}`"
            type="hidden"
            :name="`product_management[${valueId}]`"
            :value="managedItemsData[valueId]"
          />
        </template>
      </template>
    </template>
  </AppTable>
</template>

<script>
import TooltipHelper from '@/mixins/TooltipHelper';
import { mapState, mapGetters, mapActions } from 'vuex';
import DisplayProductManagementTemplateType from '@/enums/DisplayProductManagementTemplateType';

export default {
  name: 'DisplayProductManagementManagedItemsTable',
  mixins: [TooltipHelper],
  props: { managedItemsData: { type: Object, required: true } },
  computed: {
    ...mapState('displayProductManagement', ['productManagement']),
    ...mapGetters('productCategory', ['productCategoryName']),
    columns() {
      return [
        { id: 'using_management', width: '100px' },
        { id: 'item', width: '160px' },
        { id: 'description' },
        { id: 'actions', label: this.$t('app.actions'), width: '100px' }
      ].map(column => ({
        ...column,
        label: column.label ? column.label : this.$t(`columns.${column.id}`)
      }));
    },
    rows() {
      const rowIds = ['category', 'icon'];
      if (
        this.productManagement.template_type ===
        DisplayProductManagementTemplateType.FAST_DELIVERY
      )
        rowIds.push('widget');

      const descriptionValueIdsMap = {
        category: ['category_id'],
        icon: ['pc_icon_ids', 'mobile_icon_ids'],
        widget: [
          'widget_name',
          'order_time',
          'pc_margin_top',
          'pc_margin_right',
          'pc_margin_bottom',
          'pc_margin_left',
          'mobile_margin_top',
          'mobile_margin_right',
          'mobile_margin_bottom',
          'mobile_margin_left',
          'pc_css',
          'mobile_css'
        ]
      };

      return rowIds.map(id => ({
        id,
        using_management: this.managedItemsData[`use_${id}_management`],
        item: this.$t(`items.${id}`),
        descriptionValueIds: descriptionValueIdsMap[id]
      }));
    },
    isCategorySelected() {
      return !!this.managedItemsData.category_id;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openManagementDialog(id) {
      let dialogName = null;
      switch (id) {
        case 'category':
          dialogName = 'DisplayCategoryManagementFormDialog';
          break;
        case 'icon':
          dialogName = 'DisplayIconManagementFormDialog';
          break;
        case 'widget':
          dialogName = 'DisplayWidgetManagementFormDialog';
          break;
      }

      this.openDialog([dialogName, { item: this.managedItemsData }]).then(
        eventBus => {
          eventBus.$on('update-product-management', item =>
            this.$emit('update-product-management', item)
          );
        }
      );
    }
  }
};
</script>

<style scoped>
.DisplayProductManagementManagedItemsTable__category {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 256px;
  vertical-align: middle;
}

.DisplayProductManagementManagedItemsTable__category-description {
  display: inline-block;
  vertical-align: middle;
}

.DisplayProductManagementManagedItemsTable__icons {
  margin-left: 4px;
  display: inline-block;
}
</style>

<i18n locale="ko">
{
  "columns": {
    "using_management": "사용여부",
    "item": "자동 관리 항목",
    "description": "내용"
  },
  "using_management": {
    "true": "사용",
    "false": "미사용"
  },
  "items": {
    "category": "가상 카테고리 이동",
    "icon": "상품에 아이콘 표시",
    "widget": "빠른 배송 안내 위젯 표시"
  },
  "description": {
    "category": {
      "true": "카테고리로 이동",
      "false": "이동할 가상 카테고리를 설정해주세요."
    },
    "icon": {
      "pc_icon_ids": "상품 아이콘:",
      "mobile_icon_ids": "모바일 전용:",
      "many": "외 {0}개",
      "none": "상품에 표시할 아이콘을 선택해주세요."
    }
  }
}
</i18n>
