import { constructEnum } from '@/lib/enum';
import i18n from '@/lib/i18n';

const CountAndScoreFormat = constructEnum('CountAndScoreFormat', {
  STAR_BEHIND: 1,
  STAR_FRONT: 2,
  ONLY_COUNT: 3,
  ONLY_COUNT_ENG_LOWERCASE: 4,
  ONLY_COUNT_ENG_CAPITALIZED: 5,
  ONLY_COUNT_ENG_UPPERCASE: 6
});

CountAndScoreFormat.DEFAULT_FORMATS = [
  CountAndScoreFormat.STAR_BEHIND,
  CountAndScoreFormat.STAR_FRONT,
  CountAndScoreFormat.ONLY_COUNT,
  CountAndScoreFormat.ONLY_COUNT_ENG_LOWERCASE,
  CountAndScoreFormat.ONLY_COUNT_ENG_CAPITALIZED
];

CountAndScoreFormat.EN_FORMATS = [
  CountAndScoreFormat.STAR_BEHIND,
  CountAndScoreFormat.STAR_FRONT,
  CountAndScoreFormat.ONLY_COUNT_ENG_LOWERCASE,
  CountAndScoreFormat.ONLY_COUNT_ENG_CAPITALIZED,
  CountAndScoreFormat.ONLY_COUNT_ENG_UPPERCASE
];

CountAndScoreFormat.optionsWithLocale = function(locale) {
  const targetFormats =
    locale === 'en-US'
      ? CountAndScoreFormat.EN_FORMATS
      : CountAndScoreFormat.DEFAULT_FORMATS;

  return targetFormats.map(format => {
    const key = `CountAndScoreFormat.${CountAndScoreFormat.key(format)}`;
    const labelArray = i18n.i(key, locale);

    return {
      label: labelArray ? labelArray[0] : key,
      value: format
    };
  });
};

export default CountAndScoreFormat;
