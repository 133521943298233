var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppResourceTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"enable-total-count":false,"custom-buttons":[_vm.filterButton, _vm.sortButton],"table-id":"product_displays"},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [(column === 'name')?[_c('span',{class:("DisplayProductDisplaysTable__name-content--" + _vm.productDisplayRange)},[_vm._v(_vm._s(row.name))])]:(column === 'corners')?[(row.corners.length)?_c('AppSummaryBadge',{attrs:{"items":row.corners,"unit-path":"count"}}):_c('span',[_vm._v("-")])]:(column === 'status')?[_c('DisplayProductDisplayStatusBadge',{attrs:{"product-display-status":row.product_display_status,"edited-at":row.edited_at,"cohort":row.applying_cohort}}),(_vm.isErrored(row.product_display_status))?_c('AppButton',{attrs:{"label":_vm.$t('show_detail'),"button-style":"underline"},on:{"click":function($event){return _vm.openErrorDetailDialog(row)}}}):_vm._e()]:(column === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('app.edit'),"disabled":!row.corners.length},on:{"click":function($event){return _vm.openDisplayCornerProductsDialog(row)}}}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"menu-items":[
          {
            label: _vm.$t('cohorts'),
            disabled: !row.cohorts_exist,
            clickHandler: function (ref) {
              var close = ref.close;

              close();
              _vm.eventBus.$emit('show-cohorts', row);
            }
          },
          {
            label: _vm.$t('app.delete'),
            style: 'danger',
            clickHandler: function (ref) {
              var close = ref.close;

              close();
              _vm.deleteProductDisplay(row);
            }
          }
        ]}})]:_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }