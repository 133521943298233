import { constructEnum } from '@/lib/enum';

const Weekday = constructEnum('Weekday', {
  MONDAY: 2,
  TUESDAY: 4,
  WEDNESDAY: 8,
  THURSDAY: 16,
  FRIDAY: 32,
  SATURDAY: 64,
  SUNDAY: 1
});

Weekday.fromBitFlag = bitFlag => {
  const res = [];
  Weekday.options().forEach(({ value }) => {
    if (value & bitFlag) {
      res.push(value);
    }
  });
  return res;
};

export default Weekday;
