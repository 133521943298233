<template>
  <AnalyticsInsightNoticeView @normalize="refresh">
    <AnalyticsInsightAjaxContent :is-loading="isLoading">
      <AnalyticsInsightMainSearchbar
        v-bind="searchBarProps"
        v-on="searchBarEvents"
      />
      <AnalyticsInsightInfoBox v-bind="{ categoriesCount, keywordsCount }" />
      <AnalyticsInsightKeywordsComment
        v-bind="{
          reviewsCount,
          reviewTagsCount,
          myBrandKeywordsDetails,
          brandsKeywordsDetails
        }"
      />
      <AppGrid class="AnalyticsInsightKeywords__summaries">
        <AnalyticsInsightKeywordsSummary
          v-bind="{
            dateRange,
            title: $t('insight.my_brand'),
            summary: myBrandKeywordsSummary
          }"
        />
        <AnalyticsInsightKeywordsSummary
          v-bind="{
            dateRange,
            title: $t('insight.brands'),
            summary: brandsKeywordsSummary
          }"
        />
      </AppGrid>
      <AnalyticsInsightKeywordsAnalysis
        v-bind="{ dateRange, myBrandKeywordsDetails, brandsKeywordsDetails }"
      />
    </AnalyticsInsightAjaxContent>
  </AnalyticsInsightNoticeView>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import InsightCompetingGroupView from '@/mixins/InsightCompetingGroupView';
import InsightKeywordsView from '@/mixins/InsightKeywordsView';

export default {
  name: 'AnalyticsInsightKeywords',
  mixins: [InsightCompetingGroupView, InsightKeywordsView],
  data() {
    return {
      isLoading: true,
      reviewsCount: 0,
      reviewTagsCount: 0,
      myBrandKeywordsDetails: [],
      brandsKeywordsDetails: []
    };
  },
  computed: {
    defaultResourceParams() {
      return this.pickByKeys(this.defaultResourceCommonParams);
    },
    myBrandKeywordsSummary() {
      return this.keywordsSummary(this.myBrandKeywordsDetails);
    },
    brandsKeywordsSummary() {
      return this.keywordsSummary(this.brandsKeywordsDetails);
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/analytics/keywords', {
          params: _.pickBy(params, v => v !== 'all')
        })
        .then(({ data }) => {
          this.SET_FILTERED_BRANDS_COUNT(data.filtered_brands_count);
          this.keywordsCount = data.total_keywords_count;

          this.reviewsCount = data.reviews_count;
          this.reviewTagsCount = data.review_tags_count;

          this.myBrandKeywordsDetails = data.my_brand_keywords_details;
          this.brandsKeywordsDetails = data.brands_keywords_details;
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style scoped>
.AnalyticsInsightKeywords__summaries {
  margin-top: 4px;
}
</style>
