var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{staticClass:"ReviewSettingsWidgetsSwitcher",attrs:{"is-loading":_vm.isLoading}},[(!_vm.isImwebBrand)?_c('AppSearchbar',[_c('li',[_c('AppButton',{attrs:{"label":_vm.$t('head.new_widget'),"button-style":"red"},on:{"click":_vm.newWidgetClicked}})],1)]):_vm._e(),_c('AppResourceTable',{attrs:{"table-id":"widgets-table","columns":_vm.COLUMNS,"rows":_vm.rows,"event-bus":_vm.tableEventBus},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var rowIndex = ref.rowIndex;
return [(column === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('app.settings')},on:{"click":function($event){return _vm.widgetSettingsClicked(rowIndex)}}}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"menu-items":[
            [
              {
                label: _vm.$t('review_widgets.move_to_widget_inserted_url'),
                type: 'external_link',
                to: _vm.addParamsToUrlString(
                  _vm.reviewWidgets[rowIndex].inserted_url,
                  {
                    'crema-scroll-to-widget-id': _vm.reviewWidgets[rowIndex].id
                  }
                ),
                disabled: !_vm.reviewWidgets[rowIndex].inserted_url,
                tooltip: _vm.reviewWidgets[rowIndex].inserted_url
                  ? null
                  : _vm.$t('review_widgets.not_found_inserted_url')
              }
            ],
            [
              {
                label: _vm.$t('review_widgets.delete_widget'),
                clickHandler: function () { return _vm.deleteWidget(_vm.reviewWidgets[rowIndex].id, rowIndex); },
                style: 'danger',
                disabled:
                  _vm.reviewWidgets[rowIndex].default || !_vm.isSuperAdminAccessible,
                tooltip: _vm.reviewWidgets[rowIndex].default
                  ? _vm.$t('review_widgets.tooltip_cannot_delete_default')
                  : !_vm.isSuperAdminAccessible
                  ? _vm.$t('review_widgets.tooltip_must_be_admin')
                  : null
              }
            ]
          ]}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }