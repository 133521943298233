<template>
  <div class="TheWelcomeTutorialItem">
    <div class="TheWelcomeTutorialItem__icon">
      {{ icon }}
    </div>
    <div class="TheWelcomeTutorialItem__content">
      <div class="TheWelcomeTutorialItem__title">
        {{ title }}
      </div>
      <div class="TheWelcomeTutorialItem__sub-title">
        {{ subTitle }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheWelcomeTutorialItem',
  props: {
    title: { type: String, required: true },
    subTitle: { type: String, required: true },
    icon: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheWelcomeTutorialItem {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.TheWelcomeTutorialItem__icon {
  margin-right: 8px;
  font-size: 30px;
}

.TheWelcomeTutorialItem__content {
  display: flex;
  flex-direction: column;
}

.TheWelcomeTutorialItem__title {
  @include text-caption-darker;

  font-weight: bold;
}

.TheWelcomeTutorialItem__sub-title {
  @include text-caption-small-dark;
}
</style>
