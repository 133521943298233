<template>
  <div
    :class="[
      'AppForm__group-field',
      { 'AppForm__group-field--disabled': disabled }
    ]"
  >
    <div class="TargetWidgetDesignPageLinkButtonStyle__input-section">
      <div class="TargetWidgetDesignPageLinkButtonStyle__advanced-input">
        <label
          class="TargetWidgetDesignPageLinkButtonStyle__label"
          :for="backgroundColorId"
          >{{ $t('background_color') }}</label
        >
        <AppColorPicker
          :id="backgroundColorId"
          :value="formObject[backgroundColorId]"
          :name="`widget_design_attributes[${backgroundColorId}]`"
          :label="$t('background_color')"
          :disabled="disabled"
          @input="update(backgroundColorId, $event)"
        />
      </div>
    </div>
    <div class="TargetWidgetDesignPageLinkButtonStyle__input-section">
      <div class="TargetWidgetDesignPageLinkButtonStyle__advanced-input">
        <label
          class="TargetWidgetDesignPageLinkButtonStyle__label"
          :for="borderColorId"
          >{{ $t('border_color') }}</label
        >
        <AppColorPicker
          :id="borderColorId"
          :value="formObject[borderColorId]"
          :name="`widget_design_attributes[${borderColorId}]`"
          :label="$t('border_color')"
          :disabled="disabled"
          @input="update(borderColorId, $event)"
        />
      </div>
      <div class="TargetWidgetDesignPageLinkButtonStyle__advanced-input">
        <label
          class="TargetWidgetDesignPageLinkButtonStyle__label"
          :for="borderWidthId"
          >{{ $t('border_width') }}</label
        >
        <AppNumberInput
          :id="borderWidthId"
          :name="`widget_design_attributes[${borderWidthId}]`"
          :value="formObject[borderWidthId]"
          :digits="3"
          :default="0"
          :disabled="disabled"
          @blur="update(borderWidthId, formObject[borderWidthId])"
          @change="update(borderWidthId, $event)"
        />
      </div>
    </div>
    <template v-if="!isPc">
      <div class="TargetWidgetDesignPageLinkButtonStyle__input-section">
        <div class="TargetWidgetDesignPageLinkButtonStyle__advanced-input">
          <label
            class="TargetWidgetDesignPageLinkButtonStyle__label"
            :for="useFullWidthId"
            >{{ $t('width.mobile') }}</label
          >
          <AppSelectRadio
            :id="useFullWidthId"
            :name="`widget_design_attributes[${useFullWidthId}]`"
            :value="formObject[useFullWidthId]"
            :options="buttonStyleOptions"
            :disabled="disabled"
            @change="update(useFullWidthId, $event)"
          >
            <template #sub-item="{ value, checked }">
              <div v-if="value === false" class="AppForm__sub-group">
                <div class="AppForm__sub-group-field">
                  <AppNumberInput
                    :id="widthId"
                    :name="`widget_design_attributes[${widthId}]`"
                    :value="formObject[widthId]"
                    :digits="3"
                    :default="120"
                    :disabled="!checked"
                    @blur="update(widthId, formObject[widthId])"
                    @change="update(widthId, $event)"
                  />
                </div>
              </div>
            </template>
          </AppSelectRadio>
        </div>
      </div>
    </template>
    <div class="TargetWidgetDesignPageLinkButtonStyle__input-section">
      <template v-if="isPc">
        <div class="TargetWidgetDesignPageLinkButtonStyle__advanced-input">
          <label
            class="TargetWidgetDesignPageLinkButtonStyle__label"
            :for="widthId"
            >{{ $t('width.pc') }}</label
          >
          <AppNumberInput
            :id="widthId"
            :name="`widget_design_attributes[${widthId}]`"
            :value="formObject[widthId]"
            :digits="3"
            :default="400"
            :disabled="disabled"
            @blur="update(widthId, formObject[widthId])"
            @change="update(widthId, $event)"
          />
        </div>
      </template>
      <div class="TargetWidgetDesignPageLinkButtonStyle__advanced-input">
        <label
          class="TargetWidgetDesignPageLinkButtonStyle__label"
          :for="horizontalPaddingId"
          >{{ $t('horizontal_padding') }}</label
        >
        <AppNumberInput
          :id="horizontalPaddingId"
          :name="`widget_design_attributes[${horizontalPaddingId}]`"
          :value="formObject[horizontalPaddingId]"
          :digits="3"
          :default="isPc ? 20 : 16"
          :disabled="disabled"
          @blur="update(horizontalPaddingId, formObject[horizontalPaddingId])"
          @change="update(horizontalPaddingId, $event)"
        />
      </div>
      <div class="TargetWidgetDesignPageLinkButtonStyle__advanced-input">
        <label
          class="TargetWidgetDesignPageLinkButtonStyle__label"
          :for="borderRadiusId"
          >{{ $t('border_radius') }}</label
        >
        <AppNumberInput
          :id="borderRadiusId"
          :name="`widget_design_attributes[${borderRadiusId}]`"
          :value="formObject[borderRadiusId]"
          :digits="3"
          :default="4"
          :disabled="disabled"
          @blur="update(borderRadiusId, formObject[borderRadiusId])"
          @change="update(borderRadiusId, $event)"
        />
      </div>
    </div>
    <div class="TargetWidgetDesignPageLinkButtonStyle__input-section">
      <TargetWidgetDesignFontFields
        :default-object="defaultObject"
        :font-size-id="fontSizeId"
        :font-color-id="fontColorId"
        :font-weight-id="fontWeightId"
        :form-object="formObject"
        :errors="errors"
        :disabled="disabled"
        @update="updateFontField"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TargetWidgetDesignPageLinkButtonStyle',
  props: {
    backgroundColorId: { type: String, default: null },
    borderColorId: { type: String, default: null },
    borderWidthId: { type: String, default: null },
    useFullWidthId: { type: String, default: null },
    widthId: { type: String, default: null },
    horizontalPaddingId: { type: String, default: null },
    borderRadiusId: { type: String, default: null },
    fontSizeId: { type: String, default: null },
    fontColorId: { type: String, default: null },
    fontWeightId: { type: String, default: null },
    formObject: { type: Object, required: true },
    defaultObject: { type: Object, required: true },
    errors: { type: Object, required: true },
    disabled: { type: Boolean, defualt: false },
    isPc: { type: Boolean, default: true }
  },
  computed: {
    buttonStyleOptions() {
      return [
        {
          label: this.$t('width.max_width'),
          value: true
        },
        {
          label: this.$t('width.input'),
          value: false
        }
      ];
    }
  },
  methods: {
    update(id, value) {
      this.$emit('update', { id, value });
    },
    updateFontField({ id, value }) {
      this.update(id, value);
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetWidgetDesignPageLinkButtonStyle__input-section {
  & + & {
    margin-top: 12px;
  }
}

.TargetWidgetDesignPageLinkButtonStyle__advanced-input {
  display: inline-block;
  vertical-align: top;
  & + & {
    margin-left: 24px;
  }
}

.TargetWidgetDesignPageLinkButtonStyle__label {
  display: block;
  margin-bottom: 4px;
}
</style>

<i18n locale="ko">
{
  "background_color": "버튼 배경 색상",
  "border_color": "버튼 테두리 색상",
  "border_width": "버튼 테두리 굵기(px)",
  "horizontal_padding": "버튼 상하 여백(px)",
  "border_radius": "버튼 모서리 둥글기(px)",
  "width": {
    "pc": "버튼 너비(px)",
    "mobile": "버튼 너비",
    "max_width": "위젯 너비에 맞춰 100%로 표시",
    "input": "직접 입력(px)"
  }
}
</i18n>
