import ReviewSort from '@/enums/ReviewSort';
import WidgetStyle from '@/enums/WidgetStyle';
import store from '@/store';

export default {
  formSections({
    widgetStyle,
    isGodo,
    isDeviceMobile,
    isDevicePc,
    isRenewed,
    isGalleryRenewed,
    isShopifyBrand,
    useSubBrandFilter,
    useVuejs // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
  }) {
    const display_reviews = [
      isShopifyBrand ? null : 'category_id',
      isShopifyBrand ? null : 'exclude_category_ids',
      useSubBrandFilter ? 'sub_brand_ids' : null,
      'tag_id',
      'exclude_tag_id'
    ];
    const sort = [
      'display_orders_in_use',
      'recommendation_review_sort',
      useVuejs ? null : 'group_by_product_display_order' // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
    ];
    const isDevicePcOnly = isDevicePc && !isDeviceMobile;
    switch (widgetStyle) {
      case WidgetStyle.LIST:
        return {
          display_reviews: [...display_reviews, 'filter_photo_only'],
          sort,
          feature: [
            'show_header_section',
            isGodo || useVuejs ? null : 'use_original_product_image'
          ],
          design: [
            'posts_per_page',
            'message_initial_rows',
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null,
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null,
            isDeviceMobile ? 'keep_board_style_for_mobile' : null,
            isDevicePc && !isRenewed ? 'camera_icon' : null
          ]
        };
      case WidgetStyle.LIST_V3:
        return {
          display_reviews: [...display_reviews, 'filter_photo_only'],
          sort,
          feature: ['show_header_section'],
          design: [
            'posts_per_page',
            'message_initial_rows',
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null,
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null,
            isDeviceMobile ? 'keep_board_style_for_mobile' : null
          ]
        };
      case WidgetStyle.GALLERY:
      case WidgetStyle.ROUND_THUMBNAIL_GALLERY:
      case WidgetStyle.MAIN_GALLERY:
        return {
          display_reviews: [
            ...display_reviews,
            ...(isDeviceMobile &&
            useVuejs &&
            widgetStyle !== WidgetStyle.ROUND_THUMBNAIL_GALLERY
              ? ['show_video_only', 'mobile_review_popup_style']
              : [])
          ],
          sort: ['display_orders_in_use', 'recommendation_review_sort'],
          feature: [
            'show_header_section',
            isDevicePc && !useVuejs ? 'display_review_position' : null,
            isGodo || isGalleryRenewed || useVuejs
              ? null
              : 'use_original_product_image'
          ],
          design: [
            isDevicePcOnly ? null : 'posts_per_page',
            isDevicePcOnly && !isGalleryRenewed ? 'use_custom_layout' : null,
            isDevicePcOnly && isGalleryRenewed ? 'reviews_custom_layout' : null,
            isDeviceMobile ? 'mobile_posts_per_line' : null,
            'show_thumbnail_as_square',
            isGalleryRenewed ? 'product_thumbnail_shape' : null,
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null,
            isGalleryRenewed ? 'message_initial_rows' : null,
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.BEST_GALLERY:
        return {
          display_reviews,
          sort: ['display_orders_in_use', 'recommendation_review_sort'],
          feature: [
            'show_header_section',
            isDevicePc && !useVuejs ? 'display_review_position' : null,
            isGodo || isGalleryRenewed || useVuejs
              ? null
              : 'use_original_product_image'
          ],
          design: [
            isDevicePcOnly ? null : 'posts_per_page',
            isDevicePcOnly && !isGalleryRenewed ? 'use_custom_layout' : null,
            isDevicePcOnly && isGalleryRenewed ? 'reviews_custom_layout' : null,
            isDeviceMobile ? 'mobile_posts_per_line' : null,
            'show_thumbnail_as_square',
            isGalleryRenewed ? 'product_thumbnail_shape' : null,
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null,
            isGalleryRenewed ? 'message_initial_rows' : null,
            isDevicePc && !useVuejs ? 'pagination_method' : null,
            isDeviceMobile && !useVuejs ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.EXTERNAL:
        return {
          common: ['no_item_action'],
          display_reviews: [...display_reviews, 'filter_photo_only'],
          sort,
          feature: [
            'display_admin_comments_first',
            'display_share_message',
            'show_social_media_title'
          ],
          design: [
            'posts_per_page',
            isDevicePc ? 'posts_per_line' : null,
            'comments_count_on_list',
            'product_thumbnail_shape',
            'message_initial_rows',
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.SQUARE_GALLERY:
        return {
          display_reviews,
          sort: ['display_orders_in_use', 'recommendation_review_sort'],
          feature: [
            'show_header_section',
            isDevicePc && !useVuejs ? 'display_review_position' : null,
            isGodo || isGalleryRenewed || useVuejs
              ? null
              : 'use_original_product_image'
          ],
          design: [
            isDevicePcOnly ? null : 'posts_per_page',
            isDevicePcOnly && !isGalleryRenewed ? 'use_custom_layout' : null,
            isDevicePcOnly && isGalleryRenewed ? 'reviews_custom_layout' : null,
            isDeviceMobile ? 'mobile_posts_per_line' : null,
            useVuejs ? null : 'show_thumbnail_as_square',
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null,
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.BOARD:
        return {
          display_reviews: [...display_reviews, 'filter_photo_only'],
          sort,
          feature: [
            'show_header_section',
            isGodo || useVuejs ? null : 'use_original_product_image' // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          ],
          design: [
            'posts_per_page',
            isDevicePc ? 'pagination_method' : null,
            isDeviceMobile ? 'pagination_method_for_mobile' : null
          ]
        };
      case WidgetStyle.HORIZONTAL_SCROLL:
        return {
          common: ['posts_per_page'],
          display_reviews,
          sort: ['display_orders_in_use', 'recommendation_review_sort'],
          feature: [
            useVuejs ? 'show_header_section' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거 (수정)
            isGodo || useVuejs ? null : 'use_original_product_image',
            useVuejs ? null : 'show_title', // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
            'horizontal_scroll_enable_autoscroll'
          ],
          design: [
            'show_thumbnail_as_square',
            useVuejs ? 'product_thumbnail_shape' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거 (수정)
            useVuejs ? 'message_initial_rows' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거 (수정)
            isDeviceMobile ? 'review_popup_display_type_for_mobile' : null,
            'horizontal_scroll_contents_to_show',
            isDevicePc
              ? 'horizontal_scroll_initial_posts_on_page_for_pc'
              : null,
            isDeviceMobile ? 'horizontal_scroll_initial_posts_on_page' : null
          ]
        };
      default:
        return {};
    }
  },
  widgetStyles({
    showBoardStyle,
    isDevicePc,
    isSuperAdminAccessible,
    isListV3InstallationEnabled
  }) {
    const baseStyles = isDevicePc
      ? WidgetStyle.PC_REVIEWS_STYLES
      : WidgetStyle.MOBILE_REVIEWS_STYLES;
    const computedStyles = showBoardStyle
      ? [WidgetStyle.BOARD, ...baseStyles]
      : baseStyles;

    const widgetStyles = [
      ...computedStyles,
      isListV3InstallationEnabled ? WidgetStyle.LIST_V3 : null
    ].filter(v => v !== null && v !== undefined);

    return isSuperAdminAccessible
      ? [...widgetStyles, WidgetStyle.EXTERNAL]
      : widgetStyles;
  },
  displayOrderOptions({ displayOrdersInUse }) {
    const reviewSortOptions = store.getters['reviewWidgets/reviewSortOptions'];
    return reviewSortOptions([
      ReviewSort.RECENTLY_CREATED,
      ReviewSort.MOST_SCORES,
      ReviewSort.MOST_HELPS,
      ReviewSort.MOST_LIKES
    ]).map(option => ({
      ...option,
      disabled: !(displayOrdersInUse || []).includes(option.value)
    }));
  },
  showHeaderSectionSubSettings({
    widgetStyle,
    isRenewed,
    isDevicePc,
    isGalleryRenewed,
    isUsingProductCategoryProductType,
    useVuejs // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
  }) {
    switch (widgetStyle) {
      case WidgetStyle.LIST:
        return [
          'show_title',
          isRenewed ? 'description_text' : null,
          'show_notices',
          isRenewed || isDevicePc ? 'show_top_filter_menu' : null,
          isRenewed ? 'show_score_filter' : null
        ].filter(v => v);
      case WidgetStyle.HORIZONTAL_SCROLL:
        if (!useVuejs) return []; // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
        return [
          'show_title',
          'description_text',
          'show_notices',
          'show_top_filter_menu',
          'show_score_filter'
        ].filter(v => v);
      case WidgetStyle.GALLERY:
      case WidgetStyle.BEST_GALLERY:
      case WidgetStyle.SQUARE_GALLERY:
      case WidgetStyle.ROUND_THUMBNAIL_GALLERY:
      case WidgetStyle.MAIN_GALLERY:
        return [
          'show_title',
          isGalleryRenewed ? 'description_text' : null,
          'show_notices',
          isDevicePc || isGalleryRenewed ? 'show_top_filter_menu' : null,
          isGalleryRenewed ? 'show_score_filter' : null,
          isGalleryRenewed ? 'show_search_section' : null,
          isGalleryRenewed || isUsingProductCategoryProductType
            ? null
            : 'show_review_options_search'
        ].filter(v => v);
      case WidgetStyle.BOARD:
        return [
          'show_title',
          useVuejs ? 'description_text' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          'show_notices',
          useVuejs ? 'show_top_filter_menu' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          useVuejs ? 'show_score_filter' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          useVuejs ? 'show_search_section' : null, // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
          isUsingProductCategoryProductType || useVuejs // TODO: useVuejs vue위젯 포팅 작업 완료 후 제거
            ? null
            : 'show_review_options_search'
        ];
      default:
        return [];
    }
  },
  useBackgroundColor() {
    return true;
  }
};
