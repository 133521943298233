<template>
  <div
    :class="[
      'AppFormGroupDescription',
      disabled ? 'AppFormGroupDescription--disabled' : ''
    ]"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-if="mode === 'html'" v-html="message" />
    <AppMarked
      v-else-if="mode === 'markdown'"
      class="AppFormGroupDescription__markdown"
      :text="message"
    />
    <AppInlineButton
      v-if="detailButtonClickHandler"
      class="AppFormGroupDescription__detail-button"
      :label="$t('app.detail')"
      button-style="caption"
      @click="detailButtonClickHandler"
    />
  </div>
</template>

<script>
export default {
  name: 'AppFormGroupDescription',
  props: {
    disabled: { type: Boolean, required: true },
    message: { type: String, required: true },
    detailButtonClickHandler: { type: Function, default: null },
    mode: {
      type: String,
      required: true,
      validator: v => ['html', 'markdown'].includes(v)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_links.scss';

.AppFormGroupDescription--disabled {
  color: $color-disable-text;
}

.AppFormGroupDescription__detail-button {
  margin-left: 4px;
}

.AppFormGroupDescription__markdown ::v-deep a {
  @include link-blue;
}
</style>
