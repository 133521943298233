<template>
  <AppButton
    :label="label"
    :disabled="buttonDisabled"
    :tooltip="tooltip"
    button-style="grey"
    @click="syncResource"
  />
</template>

<script>
import BrandSyncType from '@/enums/BrandSyncType';
import ButtonSyncable from '@/mixins/ButtonSyncable';

export default {
  name: 'DisplaySyncMainCornersButton',
  mixins: [ButtonSyncable],
  computed: {
    brandSyncType() {
      return BrandSyncType.MAIN_CORNER;
    },
    tooltipIdleInfoText() {
      return this.$t('sync_button.tooltip.idle_info', [this.$t('name_detail')]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "name": "진열 위치",
  "name_detail": "메인 진열 위치"
}
</i18n>
