import { mapState } from 'vuex';

export default {
  props: { message: { type: String, required: true } },
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    dispatchTime() {
      const hour = this.campaign.dispatch_hour;
      return hour < 12
        ? this.$t('morning', [hour === 0 ? 12 : hour])
        : this.$t('afternoon', [hour === 12 ? 12 : hour - 12]);
    },
    messageHtml() {
      return this.message
        .replace(/(\r\n|\n)/g, '<br />')
        .replace(/(cre\.ma\/[a-zA-Z0-9]{6})/g, '<a>$1</a>');
    }
  }
};
