<template>
  <a
    :href="pagePath"
    :class="[
      'AppPaginationButton',
      {
        'AppPaginationButton--navigation': buttonType !== 'normal'
      },
      {
        'AppPaginationButton--current-page':
          buttonType === 'normal' && page === currentPage
      },
      { 'AppPaginationButton--disabled': disabled }
    ]"
    @click="click"
  >
    <AppSvgIcon v-if="buttonType === 'first'" name="icon-first-button" />
    <AppSvgIcon v-else-if="buttonType === 'prev'" name="icon-prev-button" />
    <AppSvgIcon v-else-if="buttonType === 'next'" name="icon-next-button" />
    <AppSvgIcon v-else-if="buttonType === 'last'" name="icon-last-button" />
    <template v-else>{{ page }}</template>
  </a>
</template>

<script>
export default {
  name: 'AppPaginationButton',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    lastPage: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    buttonType: {
      type: String,
      required: true
    },
    enableRoute: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    disabled() {
      switch (this.buttonType) {
        case 'first':
        case 'prev':
          return this.currentPage === 1;
        case 'last':
        case 'next':
          return this.currentPage === this.lastPage;
        default:
          return false;
      }
    },
    pagePath() {
      return this.enableRoute
        ? this.$router.resolve({
            query: {
              ...this.$route.query,
              page: this.page === 1 ? undefined : this.page
            }
          }).href
        : null;
    }
  },
  methods: {
    click(e) {
      if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) return;

      e.preventDefault();
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_transitions.scss';

.AppPaginationButton {
  @include text-button;
  @include transition-basic(color, border-color);

  display: inline-block;
  border: 1px solid $color-form-border;
  border-radius: 3px;
  padding: 5px 11px;
  vertical-align: middle;
  cursor: pointer;

  &:hover {
    border-color: $color-form-border-hover;
  }

  & + .AppPaginationButton {
    margin-left: 4px;
  }
}

.AppPaginationButton--disabled {
  pointer-events: none;
  border-color: $color-grey-15;
  color: $color-disable-text;
}

.AppPaginationButton--navigation {
  padding: 5px;
  font-size: 0;
  line-height: 0;
}

.AppPaginationButton--current-page {
  color: $color-primary;

  &,
  &:hover {
    border-color: $color-brand-main;
  }
}
</style>
