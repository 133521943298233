<template>
  <div class="ProductBatchJobsSourceProductCodes">
    <AppHeading :label="$t('local.title')" />
    <AppContainer>
      <BatchJobForm
        v-bind="{ batchJobType }"
        :title="$t('local.upload_description_title')"
        :description="$t('local.upload_description_content')"
        sample-file-url="/csv/source-product-codes-v1.csv"
        :success-message="$t('local.batch_job_started')"
        filename="source-product-codes-v1.csv"
      >
        <template #additional-buttons>
          <li>
            <AppButtonDownload
              url="/api/product/source_products.csv"
              :label="$t('local.download_current_settings')"
            />
          </li>
        </template>
      </BatchJobForm>
    </AppContainer>
    <BatchJobTable
      v-bind="{ batchJobType }"
      @show="openBatchJobSourceProductCodesDialog"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BatchJobType from '@/enums/BatchJobType';
import BatchJobForm from '@/views/components/BatchJobForm';

export default {
  name: 'ProductBatchJobsSourceProductCodes',
  components: { BatchJobForm },
  computed: {
    batchJobType() {
      return BatchJobType.SOURCE_PRODUCT_CODES;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openBatchJobSourceProductCodesDialog(batchJobId) {
      this.openDialog([
        'ProductBatchJobSourceProductCodesDialog',
        { batchJobId }
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "local": {
    "title": "CSV 업로드",
    "upload_description_title": "CSV 파일 업로드를 통해 현재 쇼핑몰 상품 번호와 리뷰를 가져올 원본 쇼핑몰의 상품 번호를 대량으로 연결할 수 있습니다.",
    "upload_description_content": "1. ‘CSV 예시 파일’ 과 동일한 형태로 양식에 맞게 CSV 파일을 작성해야 합니다.\n2. 현재 쇼핑몰의 상품과 리뷰를 가져올 쇼핑몰의 동일한 상품의 상품 번호를 입력합니다.\n3. 한 줄에 ‘현재 쇼핑몰의 상품 번호’, ‘리뷰를 가져올 쇼핑몰의 상품 번호’ 순으로 하나씩 입력합니다.\n4. ‘현재 쇼핑몰의 상품 번호’만 입력하고 ‘리뷰를 가져올 쇼핑몰의 상품 번호’를 비워놓으면 해당 상품의 원본 상품 설정이 삭제되어 초기화됩니다.",
    "batch_job_started": "원본 상품 번호 일괄 등록을 시작했습니다.",
    "download_current_settings": "현재 설정값 다운로드"
  }
}
</i18n>
