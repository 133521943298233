<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm
      :id="SETTINGS_ID"
      v-bind="insightFormProps"
      submit-button
      :object-id="objectId"
      v-on="formEvents"
    />
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';

export default {
  name: 'AnalyticsInsightSettings',
  mixins: [FormView, SettingsView],
  data: () => ({ isLoading: true }),
  computed: {
    SETTINGS_ID() {
      return 'analytics_insight_settings';
    },
    objectId() {
      return 'analytics_insight_settings';
    },
    groupOptions() {
      return {
        exclude_products_without_sub_orders: { type: 'checkbox' }
      };
    },
    insightFormProps() {
      return {
        ...this.formProps,
        formStyle: 'hor'
      };
    }
  },
  mounted() {
    api
      .get('/analytics/settings')
      .then(({ data }) => {
        this.orgFormObject = data.settings;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/analytics/settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>
