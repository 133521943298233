<template>
  <div>
    <AppExternalLink :to="url">
      <div class="Banner">
        <div class="Banner__text-wrapper">
          <div class="Banner__main-text">
            <i18n path="main_text">
              <span style="color: #4077ff">{{ $t('sample_page') }}</span>
            </i18n>
          </div>
          <div class="Banner__sub-text">
            {{ $t('sub_text') }}
          </div>
        </div>
        <div class="Banner__img-wrapper">
          <AppImage
            class="Banner__img"
            src="https://assets.cre.ma/m/admin/v2/install_request/banner.png"
          />
        </div>
      </div>
    </AppExternalLink>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    url: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
.Banner {
  display: flex;
  width: 900px;
  height: 160px;
  background-color: #ecf0fb;
  border-radius: 4px;
}

.Banner__text-wrapper {
  padding: 52px 0 52px 29px;
}

.Banner__main-text {
  color: #313e50;
  font-size: 32px;
  font-weight: 800;
  line-height: 32px;
}

.Banner__sub-text {
  color: #6a717b;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.25px;
  margin-top: 8px;
}

.Banner__img-wrapper {
  position: relative;
  width: 378px;
  overflow: hidden;
}

.Banner__img {
  position: absolute;
  width: 456px;
  height: 346px;
  top: -75px;
  left: -20px;
}
</style>

<i18n locale="ko">
{
  "sample_page": "리뷰 샘플 페이지",
  "main_text": "{0}를 참고하세요.",
  "sub_text": "위젯 선택이 어려우신가요? 이 배너 클릭 후 샘플 페이지의 추천 조합을 살펴보세요!"
}
</i18n>
