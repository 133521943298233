<template>
  <AppAjaxContent :is-loading="isLoading">
    <div class="review-smart-installation-page-url-pattern-management">
      <AppTabsScrollable :tabs="sections" />
      <AppInfoBox class="UrlPatternManagement__info-box">
        {{ $t('info_box.page_url_pattern') }}
      </AppInfoBox>
      <AppForm
        v-bind="formProps"
        form-style="hor"
        :sections="sections"
        v-on="formEvents"
      >
        <template #group="{ inputName }">
          <AppButton
            class="ReviewSmartInstallationPageUrlPatternV2Management__add-url-pattern"
            :label="$t('button.add_url_pattern')"
            @click="addUrlPattern(inputName)"
          />
          <AppTable
            table-id="page-url-pattern-table"
            :columns="COLUMNS"
            :rows="rows(inputName)"
          >
            <template #cell="{ column, rowIndex }">
              <div v-if="column.id === 'feature'">
                <AppButton
                  :label="$t('app.settings')"
                  @click="openPageTypeRegexSettings(inputName, rowIndex)"
                />
                <AppButton
                  button-style="red-outline"
                  :label="$t('app.delete')"
                  @click="clickDeletePageTypeRegex(inputName, rowIndex)"
                />
              </div>
            </template>
          </AppTable>
        </template>
      </AppForm>
    </div>
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import api from '@/lib/api';
import { mapActions } from 'vuex';
import FormView from '@/mixins/FormView';
import WidgetPageType from '@/enums/WidgetPageType';

export default {
  name: 'ReviewSmartInstallationPageUrlPatternV2Management',
  mixins: [FormView],
  data() {
    return {
      isLoading: true,
      COLUMNS: [
        { id: 'regex', label: this.$t('column.regex') },
        { id: 'parameters', label: this.$t('column.parameters') },
        { id: 'feature', label: this.$t('column.feature'), width: '285px' }
      ],
      pageTypeRegexMap: {}
    };
  },
  computed: {
    sections() {
      return WidgetPageType.usingPageUrlPatterns.map(pageType => ({
        id: `${WidgetPageType.key(pageType)}_url_pattern`,
        label: WidgetPageType.t(pageType),
        groups: [
          {
            id: WidgetPageType.key(pageType),
            label: this.$t('sections.label')
          }
        ]
      }));
    }
  },
  mounted() {
    api
      .get('/page_type_regexes')
      .then(({ data }) => {
        Object.keys(data.page_type_regex_map).forEach(key => {
          data.page_type_regex_map[key].forEach(regex => {
            regex.parameters = regex.parameters.join(', ');
          });
        });

        this.pageTypeRegexMap = data.page_type_regex_map;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    addUrlPattern(pageType) {
      const widgetPageType = this.widgetPageType(pageType);

      this.openDialog([
        'ReviewSmartInstallationPageUrlPatternFormDialog',
        { pageType: widgetPageType }
      ]).then(eventBus =>
        eventBus.$on('save', newPageTypeRegex => {
          if (!this.pageTypeRegexMap[widgetPageType]) {
            this.$set(this.pageTypeRegexMap, widgetPageType, []);
          }

          this.pageTypeRegexMap[widgetPageType].push(newPageTypeRegex);
        })
      );
    },
    rows(inputName) {
      const widgetPageType = this.widgetPageType(inputName);

      return this.pageTypeRegexMap[widgetPageType] || [];
    },
    widgetPageType(pageType) {
      return WidgetPageType[pageType].toString();
    },
    openPageTypeRegexSettings(inputName, rowIndex) {
      const widgetPageType = this.widgetPageType(inputName);
      const pageTypeRegex = this.pageTypeRegexMap[widgetPageType][rowIndex];

      this.openDialog([
        'ReviewSmartInstallationPageUrlPatternFormDialog',
        { pageType: widgetPageType, pageTypeRegexId: pageTypeRegex.id }
      ]).then(eventBus =>
        eventBus.$on('update', updatePageTypeRegex => {
          this.$set(
            this.pageTypeRegexMap[widgetPageType],
            rowIndex,
            updatePageTypeRegex
          );
        })
      );
    },
    clickDeletePageTypeRegex(inputName, rowIndex) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      const widgetPageType = this.widgetPageType(inputName);
      const pageTypeRegex = this.pageTypeRegexMap[widgetPageType][rowIndex];

      api
        .delete(`/page_type_regexes/${pageTypeRegex.id}`, {
          successMessage: this.$t('app.deleted')
        })
        .then(() => {
          Vue.delete(this.pageTypeRegexMap[widgetPageType], rowIndex);
        });
    }
  }
};
</script>

<style>
.UrlPatternManagement__info-box {
  margin-bottom: 28px;
}
.ReviewSmartInstallationPageUrlPatternV2Management__add-url-pattern {
  margin: 8px 0;
}
</style>

<i18n locale="ko">
{
  "info_box": {
    "page_url_pattern": "위젯 설치시 크리마에서 쇼핑몰 페이지 종류를 판단하는 URL 패턴 및 파라미터 조건을 확인하고, 추가 조건을 설정할 수 있습니다."
  },
  "sections": {
    "label": "URL 패턴 및 파라미터"
  },
  "button": {
    "add_url_pattern": "+ 조건 추가"
  },
  "column": {
    "regex": "URL 패턴",
    "parameters": "파라미터",
    "feature": "기능"
  }
}
</i18n>
