<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppDataItem
      :label="$t('label')"
      :badges="[{ label: badgeLabel, badgeStyle }]"
    >
      <template v-if="isRegistered">
        <div>{{ $t('registered', [bizMessageProfile.yellow_id]) }}</div>
        <AppButton
          class="BizMessageSettings__button"
          :label="$t('button_show')"
          @click="clickShow"
        />
        <AppButton
          v-if="!bizMessageProfile.grouped"
          class="BizMessageSettings__button"
          :label="$t('button_add_group_profile')"
          :bubble-message="
            serviceType !== null && showAddGroupProfileBubbleMessage
              ? $t('button_add_group_profile_bubble')
              : null
          "
          bubble-side="bottom-right"
          @click="clickAddGroupProfile"
        />
      </template>
      <template v-else>
        <div>{{ $t('not_registered') }}</div>
        <AppButton
          class="BizMessageSettings__button"
          :label="$t('button_register')"
          :bubble-message="
            serviceType !== null && showRegisterBubbleMessage
              ? $t('button_register_bubble')
              : null
          "
          bubble-side="bottom-right"
          @click="clickRegister"
        />
      </template>
    </AppDataItem>
    <AppDataItem
      v-if="isRegistered && (isServiceTypeTarget || isSuperAdminAccessible)"
      :class="[
        'BizMessageSettings__templates',
        { 'super-admin__item': !isServiceTypeTarget }
      ]"
      :label="$t('templates_label')"
    >
      <div>{{ $t('templates_description') }}</div>
      <AppButton
        class="BizMessageSettings__button"
        :label="$t('templates_button')"
        :disabled="!isTemplateManagable"
        :tooltip="manageButtonDisabledTooltip"
        @click="clickTemplates(BizMessageTemplateType.CUSTOM)"
      />
      <AppButton
        v-if="isSuperAdminAccessible"
        :class="[
          'BizMessageSettings__button',
          { 'super-admin__item': isSuperAdminAccessible }
        ]"
        :label="$t('group_templates_button')"
        :disabled="!isTemplateManagable"
        :tooltip="manageButtonDisabledTooltip"
        @click="clickTemplates(BizMessageTemplateType.GROUP)"
      />
    </AppDataItem>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BizMessageStatus from '@/enums/BizMessageStatus';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';
import BizMessageProfileStatus from '@/enums/BizMessageProfileStatus';
import CremaServiceType from '@/enums/CremaServiceType';

export default {
  name: 'BizMessageSettings',
  props: { serviceType: { type: Number, default: null } },
  data() {
    return {
      isLoading: true,
      showRegisterBubbleMessage: true,
      showAddGroupProfileBubbleMessage: true
    };
  },
  computed: {
    ...mapState('bizMessage', ['bizMessageProfile']),
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('session', [
      'isReviewUsingBizMessage',
      'isTargetOperatingOrExpired'
    ]),
    BizMessageTemplateType: () => BizMessageTemplateType,
    isServiceTypeTarget() {
      return this.serviceType === CremaServiceType.TARGET;
    },
    badgeLabel() {
      return this.$t(`badge.${this.badgeStatus}`);
    },
    badgeStyle() {
      switch (this.badgeStatus) {
        case 'sendable':
          return 'mint-green';
        case 'not_sendable':
          return 'red';
        default:
          return 'default';
      }
    },
    badgeStatus() {
      if (!this.isRegistered) return 'not_registered';

      return this.bizMessageProfile.biz_message_status ===
        BizMessageStatus.ACTIVE &&
        this.bizMessageProfile.status === BizMessageProfileStatus.ACTIVATED
        ? 'sendable'
        : 'not_sendable';
    },
    isRegistered() {
      return !!this.bizMessageProfile.yellow_id;
    },
    isTemplateManagable() {
      switch (this.serviceType) {
        case CremaServiceType.REVIEW:
          return this.isReviewUsingBizMessage;
        case CremaServiceType.TARGET:
          return this.isTargetOperatingOrExpired;
        default:
          return true;
      }
    },
    manageButtonDisabledTooltip() {
      if (this.isTemplateManagable) return null;

      return this.$t('template_not_managable', [
        CremaServiceType.t(this.serviceType)
      ]);
    }
  },
  mounted() {
    this.isLoading = true;
    this.fetchProfile().finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('bizMessage', ['fetchProfile', 'addGroupProfile']),
    clickShow() {
      this.openDialog('BizMessageProfileDialog');
    },
    clickRegister() {
      this.showRegisterBubbleMessage = false;
      this.openDialog('RegisterBizMessageProfileDialog');
    },
    clickAddGroupProfile() {
      this.showAddGroupProfileBubbleMessage = false;
      this.addGroupProfile(this.$t('group_profile_added'));
    },
    clickTemplates(templateType) {
      this.openDialog([
        'BizMessageTemplatesDialog',
        { templateType, serviceType: this.serviceType }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
.BizMessageSettings__templates {
  margin-top: 32px;
}

.BizMessageSettings__button {
  margin-top: 8px;
}
</style>

<i18n locale="ko">
{
  "label": "알림톡 발신 프로필",
  "badge": {
    "not_registered": "미등록",
    "sendable": "발송가능",
    "not_sendable": "발송오류"
  },
  "not_registered": "알림톡을 전송할 카카오 비지니스 계정을 등록해주세요.",
  "registered": "카카오 채널 아이디: {0}",
  "button_show": "프로필 상세보기",
  "button_register": "프로필 등록하기",
  "button_register_bubble": "알림톡을 사용하려면<br />먼저 프로필을 등록해주세요.",
  "button_add_group_profile": "그룹 프로필에 추가",
  "button_add_group_profile_bubble": "그룹 프로필 등록 중 오류가 발생했습니다.<br />해결하려면 이 버튼을 클릭해주세요.",
  "group_profile_added": "그룹 프로필에 성공적으로 추가되었습니다. 알림톡이 정상적으로 발송될 예정입니다.",
  "templates_label": "알림톡 템플릿 관리",
  "templates_description": "새로운 알림톡 템플릿을 추가하거나 관리할 수 있습니다.",
  "templates_button": "템플릿 관리하기",
  "group_templates_button": "그룹 템플릿 관리하기",
  "template_not_managable": "{0} 사용중이 아니므로 메뉴 접근이 불가합니다. 공통 설정을 통해 접속해주세요."
}
</i18n>
