<template>
  <form class="AppSearchbar" @submit.prevent="$emit('submit')">
    <div class="AppSearchbar__clearfix">
      <template v-if="$slots.default">
        <ul class="AppSearchbar__left">
          <slot />
        </ul>
      </template>
      <template v-if="$slots.right">
        <ul class="AppSearchbar__right">
          <slot name="right" />
        </ul>
      </template>
    </div>
    <template v-if="$slots['advanced-search']">
      <slot name="advanced-search" />
    </template>
  </form>
</template>

<script>
export default { name: 'AppSearchbar' };
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_clearfix.scss';
@import '@/scss/mixins/_breakpoints.scss';

.AppSearchbar {
  ::v-deep {
    & + * {
      margin-top: 32px;
    }

    // HACK
    & + .AppResourceTable {
      margin-top: 24px;
    }
  }
}

.AppSearchbar__clearfix {
  @include clearfix;
}

.AppSearchbar__left {
  float: left;
}

.AppSearchbar__right {
  float: right;
}

.AppSearchbar__left,
.AppSearchbar__right {
  ::v-deep {
    > li {
      display: inline-block;
      vertical-align: middle;

      & + li {
        margin-left: 8px;
      }
    }
  }
}

@include media-breakpoint-each(mobile) {
  .AppSearchbar__left,
  .AppSearchbar__right {
    float: none;
  }

  .AppSearchbar__left,
  .AppSearchbar__right {
    ::v-deep {
      > li {
        display: block;
        margin-top: 8px;

        & + li {
          margin-left: 0;
        }

        // HACK - 일일이 AppButton을 찾아서 style을 주는 것보다 낫다고 판단함
        > .AppButton {
          display: block !important;
        }

        > .AppSelect {
          display: block;
        }

        > .AppSelectButton {
          > div {
            display: flex;

            > .AppInputGroupItem {
              flex-grow: 1;

              > .AppButton {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .AppSearchbar__clearfix > ul:first-child > li:first-child {
    margin-top: 0;
  }
}
</style>
