<template>
  <a :href="oldAdminUrl(to)" @click="$emit('click')"><slot /></a>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppOldAdminLink',
  props: {
    to: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['oldAdminUrl'])
  }
};
</script>
