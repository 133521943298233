import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import VueI18n from 'vue-i18n';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import ActionCableVue from 'actioncable-vue';
import Flicking from '@egjs/vue-flicking';

import './scss/main.scss';
import './scss/animations.scss';
import './scss/transitions.scss';
import './scss/blocks/badges.scss';
import './scss/blocks/dropdown.scss';
import './scss/blocks/hover-underline.scss';
import './scss/blocks/input.scss';
import './scss/blocks/tables.scss';
import './scss/blocks/tab-small.scss';
import './scss/blocks/app-list.scss';
import './scss/blocks/super-admin.scss';
import '@/scss/blocks/tooltip.scss';
import './scss/blocks/ui-section.scss';
import './scss/blocks/insight-table.scss';
import '@egjs/vue-flicking/dist/flicking-inline.css';

import './components';
import VFocus from './directives/v-focus';
import VTooltip from '@/directives/v-tooltip';

import App from './App';
import i18n from './lib/i18n';
import store from './store';
import router from './router';
import mapFilters from '@/lib/mapFilters';
import './filters';
import './initializers/api';
import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');
moment.updateLocale('ko', {
  relativeTime: { s: '수초' },
  week: { dow: 1 } // Set first day of week to Monday
});
moment.tz.setDefault('Asia/Seoul');

moment.prototype.isValidDate = function() {
  return this.isValid() && this.year() !== 0;
};

moment.prototype.toVal = function() {
  return this.format('YYYY-MM-DD');
};

Vue.config.productionTip = false;

Vue.use(VueClipboard);
exportingInit(Highcharts);
Highcharts.setOptions({ lang: { thousandsSep: ',' } });

Vue.directive('focus', VFocus);
Vue.directive('tooltip', VTooltip);

Vue.mixin({
  methods: {
    ...mapFilters([
      'formatDate',
      'formatDateWithWday',
      'formatDateTime',
      'formatDateTimeWithoutSecond',
      'formatMonth',
      'formatMonthWithYear',
      'formatKoreanPhone',
      'formatUser',
      'formatCurrency',
      'formatCurrencyWithoutUnit',
      'formatFromNow'
    ])
  }
});

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: `wss://${location.host}/cable`,
  connectImmediately: true
});

Vue.use(Flicking);

const orgTranslate = Object.getPrototypeOf(i18n)._translate;
Object.getPrototypeOf(i18n)._translate = function(
  messages,
  locale,
  fallback,
  key,
  host,
  interpolateMode,
  args
) {
  const message = store.getters['translation/message'](locale, key);
  if (message !== undefined)
    return i18n._render(
      message,
      Array.isArray(message) ? 'object' : interpolateMode,
      args,
      key
    );

  return orgTranslate.call(
    this,
    messages,
    locale,
    fallback,
    key,
    host,
    interpolateMode,
    args
  );
};

const orgT = Vue.prototype.$t;
Vue.prototype.$t = function(key, ...args) {
  const namespacedKey = `${this.$options.name}.${key}`;
  const message = store.getters['translation/message'](
    this.$i18n.locale,
    namespacedKey
  );
  return orgT.call(this, message === undefined ? key : namespacedKey, ...args);
};

const orgI = VueI18n.prototype.i;
VueI18n.prototype.i = function(key, locale, values) {
  const i18nResultForLocaleKoKR = orgI.call(this, key, 'ko-KR', values);
  let originKoKRText = '';
  let box_cnt = 0;

  for (let i = 0; i < i18nResultForLocaleKoKR.length; i++) {
    if (typeof i18nResultForLocaleKoKR[i] === 'string') {
      originKoKRText += i18nResultForLocaleKoKR[i];
    } else {
      originKoKRText += `{${box_cnt}}`;
      box_cnt++;
    }
  }

  const namespacedKey = store.getters['translation/original_to_key'](
    originKoKRText
  );

  const message = store.getters['translation/message'](
    this._vm.locale,
    namespacedKey
  );

  return orgI.call(
    this,
    message === undefined ? key : namespacedKey,
    locale,
    values
  );
};

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
