var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AnalyticsInsightNoticeView',{on:{"normalize":_vm.refresh}},[_c('AnalyticsInsightAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AnalyticsInsightMainSearchbar',_vm._g(_vm._b({},'AnalyticsInsightMainSearchbar',_vm.searchBarProps,false),_vm.searchBarEvents)),_c('AnalyticsInsightInfoBox',_vm._b({},'AnalyticsInsightInfoBox',{ categoriesCount: _vm.categoriesCount, keywordsCount: _vm.keywordsCount },false)),_c('AnalyticsInsightKeywordsComment',_vm._b({},'AnalyticsInsightKeywordsComment',{
        reviewsCount: _vm.reviewsCount,
        reviewTagsCount: _vm.reviewTagsCount,
        myBrandKeywordsDetails: _vm.myBrandKeywordsDetails,
        brandsKeywordsDetails: _vm.brandsKeywordsDetails
      },false)),_c('AppGrid',{staticClass:"AnalyticsInsightKeywords__summaries"},[_c('AnalyticsInsightKeywordsSummary',_vm._b({},'AnalyticsInsightKeywordsSummary',{
          dateRange: _vm.dateRange,
          title: _vm.$t('insight.my_brand'),
          summary: _vm.myBrandKeywordsSummary
        },false)),_c('AnalyticsInsightKeywordsSummary',_vm._b({},'AnalyticsInsightKeywordsSummary',{
          dateRange: _vm.dateRange,
          title: _vm.$t('insight.brands'),
          summary: _vm.brandsKeywordsSummary
        },false))],1),_c('AnalyticsInsightKeywordsAnalysis',_vm._b({},'AnalyticsInsightKeywordsAnalysis',{ dateRange: _vm.dateRange, myBrandKeywordsDetails: _vm.myBrandKeywordsDetails, brandsKeywordsDetails: _vm.brandsKeywordsDetails },false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }