<template>
  <AppMobilePhoneSmsPreview
    :message-html="messageHtml"
    :dispatch-time="dispatchTime"
  />
</template>

<script>
import TargetMessagePreview from '@/mixins/TargetMessagePreview';

export default {
  name: 'TargetDmCampaignsSmsPreview',
  mixins: [TargetMessagePreview]
};
</script>

<i18n locale="ko">
{
  "morning": "오전 {0}:00",
  "afternoon": "오후 {0}:00"
}
</i18n>
