<template>
  <div class="DisplayProductDisplayUnitsLivePreview">
    <DisplayCornerItemsContainer
      :items="items"
      :item-width="100"
      :is-item-width-fixed="false"
      is-draggable
      :show-exceeded-max-products-items-separately="false"
      @sort="sortItems"
    >
      <template #item="{ item }">
        <div class="DisplayProductDisplayUnitsLivePreview__item">
          <DisplayCornerItemIndexColorBox
            class="DisplayProductDisplayUnitsLivePreview__item-label"
            :color-index="item.colorIndex"
          >
            {{ item.index + 1 }}
          </DisplayCornerItemIndexColorBox>
        </div>
      </template>
    </DisplayCornerItemsContainer>
  </div>
</template>

<script>
import _ from 'lodash';
import { arrayMoveIndex } from '@/lib/array';

export default {
  name: 'DisplayProductDisplayUnitsLivePreview',
  props: {
    itemList: { type: Array, required: true }
  },
  data() {
    return {
      items: _.cloneDeep(this.itemList),
      srcIndex: null,
      dstIndex: null,
      isShowingDropBar: false
    };
  },
  computed: {
    itemPositionsByColorIndex() {
      return _.reduce(
        this.items,
        (result, item, index) => {
          if (result[item.colorIndex]) result[item.colorIndex].push(index);
          else result[item.colorIndex] = [index];

          return result;
        },
        {}
      );
    }
  },
  watch: {
    itemList() {
      this.items = _.cloneDeep(this.itemList);
    },
    items() {
      this.$emit('sort', this.itemPositionsByColorIndex);
    }
  },
  methods: {
    sortItems({ srcIndex, dstIndex }) {
      this.items = arrayMoveIndex(
        this.items,
        srcIndex,
        srcIndex < dstIndex ? dstIndex - 1 : dstIndex
      ).map((item, index) => ({ ...item, index }));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.DisplayProductDisplayUnitsLivePreview {
  width: 600px;
  padding: 80px 12px;
  position: relative;
}

.DisplayProductDisplayUnitsLivePreview__item {
  height: 128px;
  border-radius: 1px;
  border: solid 1px $color-grey-35;
  background-color: $color-grey-15;
}

.DisplayProductDisplayUnitsLivePreview__item-label {
  margin-top: 8px;
  margin-left: 8px;
}
</style>
