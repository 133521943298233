<template>
  <TheLoginView :is-loading="isSubmitting">
    <AppForm
      id="user"
      v-bind="formProps"
      object-id="user"
      focus-group-id="email"
      form-style="wide"
      validate-only-on-submit
      no-screen
      :submit-button="{
        submitLabel: $t('login'),
        submittingLabel: $t('logging_in')
      }"
      v-on="formEvents"
    >
      <input type="hidden" name="user[remember_me]" value="1" />
    </AppForm>
    <div class="TheLoginView__link">
      <AppInternalLink link-style="blue" to="/account-recovery">{{
        $t('account_recovery')
      }}</AppInternalLink>
    </div>
    <hr class="TheLoginView__separator" />
    <div class="TheLoginView__action-message">
      {{ isIdleBrand ? $t('inquiry_message') : $t('join_message') }}
    </div>
    <template v-if="isIdleBrand">
      <AppButton
        class="TheLoginView__action-button"
        type="external_link"
        to="https://www.cre.ma/contact"
        :label="$t('inquiry_button')"
        size="large"
      />
    </template>
    <template v-else>
      <AppButton
        class="TheLoginView__action-button"
        type="old_admin_link"
        :to="'/v2/join'"
        :label="$t('join_button')"
        size="large"
      />
    </template>
  </TheLoginView>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import HttpStatus from '@/enums/HttpStatus';
import FormView from '@/mixins/FormView';
import TheLoginView from './TheLoginView';

export default {
  name: 'TheLoginIdPassword',
  components: { TheLoginView },
  mixins: [FormView],
  data() {
    return {
      formObject: { email: '', password: '' },
      detectFormDataChange: false,
      isIdleBrand: 'idle_brand' in this.$route.query
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'email',
              label: this.$t('email'),
              placeholder: this.$t('email_placeholder'),
              type: 'email',
              autocomplete: 'username',
              required: false,
              validate: ['required', 'email_format']
            },
            {
              id: 'password',
              label: this.$t('password'),
              placeholder: this.$t('password_placeholder'),
              type: 'password',
              autocomplete: 'current-password',
              required: false,
              validate: ['required']
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions('session', ['login', 'redirectToNextPage']),
    submit(formData) {
      this.isSubmitting = true;
      this.login(formData)
        .then(({ data }) => {
          if (data.otp_required) {
            this.$emit('otp-email-sent', {
              otpEmail: this.formObject.email,
              otpCode: data.otp_code
            });
            this.isSubmitting = false;
          } else {
            if (
              !!this.$route.query.callback &&
              this.$route.query.callback.startsWith('/')
            ) {
              const params = { data: this.$route.query.data };
              axios.get(this.$route.query.callback, { params });
            }
            if (!this.redirectToNextPage(this.$route.query.next || '/'))
              this.isSubmitting = false;
          }
        })
        .catch(error => {
          this.isSubmitting = false;
          if (error.response.status === HttpStatus.UNAUTHORIZED) {
            alert(this.$t('status_errors.error_401'));
            this.formObject.password = '';
          } else error.errorHandler();
        });
    }
  }
};
</script>

<i18n locale="ko">
{
  "email": "이메일 주소",
  "email_placeholder": "이메일 주소를 입력해주세요.",
  "password": "비밀번호",
  "password_placeholder": "비밀번호를 입력해주세요.",
  "login": "로그인",
  "logging_in": "로그인 중..",
  "account_recovery": "비밀번호를 잊으셨나요?",
  "join_message": "아직 크리마에 가입하지 않았다면,",
  "join_button": "서비스 가입하기",
  "inquiry_message": "로그인 정보를 모를 경우 문의를 남겨주세요.",
  "inquiry_button": "서비스 문의"
}
</i18n>
