export const isKoreanPhoneFormatValid = phone => {
  const re = /^1/.test(phone)
    ? /^1\d{7}$/
    : /^021[568]\d{6}/.test(phone)
    ? /^021[^568]\d{6}$/
    : /^02/.test(phone)
    ? /^02\d{7,8}$/
    : /^0[3-6][1-9]1[568]\d{6}/.test(phone)
    ? /^0[3-6][1-9]1[^568]\d{6}$/
    : /^0[35]0/.test(phone)
    ? /^0[35]0\d{8,9}$/
    : /^0[1-79]0/.test(phone)
    ? /^0[1-79]0\d{8}$/
    : /^080/.test(phone)
    ? /^080\d{7,8}$/
    : /^0[1-9]{2}\d{7,8}$/;
  return re.test(phone);
};

export const isMobilePhoneFormatValid = phone => /^010\d{8}$/.test(phone);
