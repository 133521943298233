<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppHashSelectButton
        v-model="basicValues"
        :select-buttons="BASIC_SELECT_BUTTONS"
      />
      <div class="ui-section__description">
        Selected Value: {{ basicValues }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiHashSelectButton',
  data() {
    return {
      BASIC_SELECT_BUTTONS: [
        {
          id: 'team',
          options: [
            { label: 'The Avengers', value: 'avengers' },
            { label: 'X-Men', value: 'x-men' },
            {
              label: 'The Guardians of the Galaxy',
              value: 'the-guardians-of-the-galaxy'
            },
            { label: 'S.H.I.E.L.D.', value: 'shield' },
            { label: 'Hydra', value: 'hydra' }
          ]
        },
        {
          id: 'flyable',
          options: [
            { label: 'Flying', value: true },
            { label: 'Ground', value: false }
          ]
        },
        {
          id: 'race',
          options: [
            { label: 'Asgardian', value: 'asgardian' },
            { label: 'Chitauri', value: 'chitauri' },
            { label: 'Dark Elf', value: 'dark-elf' },
            { label: 'Human', value: 'human' },
            { label: 'Kree', value: 'kree' },
            { label: 'Xandarian', value: 'xandarian' }
          ]
        }
      ],
      basicValues: {
        team: 'avengers',
        race: 'human'
      }
    };
  }
};
</script>
