<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Normal</label>
      </div>
      <AppMultipleSelectProduct id="normal" v-model="productsNormal" />
      <div class="ui-section__description">
        Selected Value: {{ productsNormal }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppMultipleSelectProduct
        id="disabled"
        v-model="productsDisabled"
        disabled
      />
      <div class="ui-section__description">
        Selected Value: {{ productsDisabled }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'UiMultipleSelectProduct',
  data() {
    const PRODUCTS = [
      { id: 1, code: '111', name: 'Ant-Man' },
      { id: 2, code: '222', name: 'Black Panther' }
    ];
    return {
      productsNormal: _.cloneDeep(PRODUCTS),
      productsDisabled: _.cloneDeep(PRODUCTS)
    };
  }
};
</script>
