<template>
  <div>
    <div class="ui-section">
      <div
        v-for="badgeStyle in BADGE_STYLES"
        :key="badgeStyle"
        class="ui-section__item"
      >
        <AppBadge
          class="ui-section__item"
          :label="badgeStyle"
          :badge-style="badgeStyle"
        />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Small</label>
      </div>
      <div
        v-for="badgeStyle in BADGE_STYLES"
        :key="badgeStyle"
        class="ui-section__item"
      >
        <AppBadge
          class="ui-section__item"
          :label="badgeStyle"
          :badge-style="badgeStyle"
          badge-size="small"
        />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Spinner</label>
      </div>
      <AppBadge label="Loading.." spinner />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Clickable</label>
      </div>
      <AppBadge label="Click me" clickable @click="createToast('Clicked!')" />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">With Max Width</label>
      </div>
      <div style="width: 150px">
        <AppBadge
          label="show label by tooltip if label is too long"
          with-max-width
        />
      </div>
      <div style="width: 150px">
        <AppBadge
          label="show tooltip if tooltip exists"
          tooltip="stronger tooltip"
          with-max-width
        />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Tooltip</label>
      </div>
      <AppBadge label="Hover me" tooltip="Hovered!" />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Summary</label>
      </div>
      <AppSummaryBadge
        :items="[
          { label: 'one', description: 'wa' },
          { label: 'two', description: 'na' }
        ]"
        unit-path="count"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UiBadge',
  data() {
    return {
      BADGE_STYLES: [
        'default',
        'mint-green',
        'mint-green-outline',
        'mango',
        'mango-outline',
        'blue',
        'blue-outline',
        'red',
        'red-outline',
        'lavender',
        'lavender-outline',
        'grey',
        'grey-outline'
      ]
    };
  },
  methods: {
    ...mapActions('toast', ['createToast'])
  }
};
</script>
