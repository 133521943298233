import { constructEnum } from '@/lib/enum';

const ServiceTransactionType = constructEnum('ServiceTransactionType', {
  PAYMENT: 10,
  PAYMENT_CANCELLED: 20,
  OPERATING: 30,
  EXPIRED: 40,
  BONUS: 50
});

ServiceTransactionType.badgeStyle = transactionType => {
  switch (transactionType) {
    case ServiceTransactionType.PAYMENT:
      return 'blue';
    case ServiceTransactionType.PAYMENT_CANCELLED:
      return 'red';
    case ServiceTransactionType.EXPIRED:
      return 'red-outline';
    case ServiceTransactionType.BONUS:
      return 'blue-outline';
    default:
      return 'default';
  }
};

export default ServiceTransactionType;
