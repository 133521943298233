<template>
  <AppModalForm
    :title="$t(id ? 'title_edit' : 'title_new')"
    :sub-title="value"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId: 'option_value',
      groupLabelNamespace: 'product_option_value',
      focusGroupId: 'value'
    }"
    v-on="formEvents"
  >
    <input type="hidden" name="option_type_id" :value="optionTypeId" />
    <template #group="group">
      <template v-if="group.id === 'parent_option_value'">
        <div
          v-tooltip="
            formObject.has_children ? $t('parent_option_value_tooltip') : null
          "
          class="AppForm__group-field"
        >
          <AppSelectProductOptionValue
            name="option_value[parent_product_option_value_id]"
            :product-option-type-id="optionTypeId"
            :product-option-values="
              formObject.parent_option_value
                ? [
                    {
                      id: formObject.parent_option_value.id,
                      name: formObject.parent_option_value.value
                    }
                  ]
                : []
            "
            :disabled="formObject.has_children"
            :default-params="{ parent_only: '1', exclude_id: id }"
            @change-items="changeParentOptionValue"
          />
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ProductOptionValueFormDialog',
  mixins: [DialogFormView],
  props: {
    optionTypeId: { type: Number, required: true },
    id: { type: Number, default: null },
    value: { type: String, required: true }
  },
  data() {
    return { optionValueValues: null, isLoading: true };
  },
  computed: {
    formSections() {
      return [
        {
          id: 'default',
          groups: [
            {
              id: 'value',
              type: 'text',
              validate: [
                'required',
                {
                  rule: v =>
                    v == this.value || !this.optionValueValues.has(v.trim()),
                  errorMessage: this.$t('errors.duplicated')
                }
              ]
            },
            { id: 'parent_option_value' }
          ]
        }
      ];
    }
  },
  mounted() {
    const url = this.id
      ? `/product/option_values/${this.id}/edit`
      : '/product/option_values/new';

    api
      .get(url, { params: { option_type_id: this.optionTypeId } })
      .then(({ data }) => {
        if (data.option_value) this.orgFormObject = data.option_value;
        this.optionValueValues = new Set(data.option_value_values);
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    changeParentOptionValue([parentOptionValue]) {
      this.formObject.parent_option_value = parentOptionValue
        ? {
            id: parentOptionValue.id,
            value: parentOptionValue.name
          }
        : null;
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .request({
          url: this.id
            ? `/product/option_values/${this.id}`
            : '/product/option_values',
          method: this.id ? 'patch' : 'post',
          data: formData,
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.eventBus.$emit('update', data);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_edit": "상품 옵션값 수정",
  "title_new": "상품 옵션값 추가",
  "parent_option_value_tooltip": "다른 옵션값의 대표 옵션값에 또다시 대표 옵션값을 지정할 수 없습니다.",
  "errors": {
    "duplicated": "이미 사용중인 옵션값 입니다."
  }
}
</i18n>
