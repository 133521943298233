<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="optionTypeName"
    width="middle"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppSearchbar @submit="searchResource">
          <template #default>
            <li>
              <AppButton
                button-style="red"
                :label="$t('add_option_value')"
                @click="addOptionValue"
              />
            </li>
          </template>

          <template #right>
            <li>
              <AppSearchWithType
                v-model="resourceParams"
                :search-types="[
                  {
                    value: 'value',
                    label: $t('product_option_value.value')
                  },
                  {
                    value: 'parent_option_value',
                    label: $t('product_option_value.parent_option_value')
                  }
                ]"
              />
            </li>
          </template>
        </AppSearchbar>
        <AppResourceTable
          table-id="product-option-values-table"
          :event-bus="tableEventBus"
          :resources="optionValues"
          :columns="COLUMNS"
          :rows="rows"
          :batch-buttons="batchButtons"
          :selected-row-ids="selectedOptionValueIds"
          :pers="[20, 50, 100, 200, 500]"
          @paginate="paginate"
          @select-rows="selectRows"
        >
          <template #cell="{ row, column }">
            <template v-if="column === 'actions'">
              <AppButtonDraggable
                :drag-text="row.name"
                :disabled="!!orgResourceParams.search_query"
                @sort="sort"
              />
              <AppDropdownMenuButton
                :label="$t('app.manage')"
                :menu-items="[
                  [
                    {
                      label: $t('edit_option_value'),
                      clickHandler: ({ close }) => {
                        editOptionValue(row.id, row.value);
                        close();
                      }
                    }
                  ],
                  [
                    {
                      label: $t('app.delete'),
                      style: 'danger',
                      clickHandler: ({ close }) => {
                        deleteOptionValue(row.id);
                        close();
                      }
                    }
                  ]
                ]"
              />
            </template>
          </template>
        </AppResourceTable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import { arrayMoveIndex, arrayMoveIndexes } from '@/lib/array';
import DialogView from '@/mixins/DialogView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'ProductOptionValuesDialog',
  mixins: [DialogView, ResourceViewNoRoute],
  props: {
    optionTypeId: { type: Number, required: true },
    optionTypeName: { type: String, required: true }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'row_select', type: 'row_select' },
        {
          id: 'value',
          label: this.$t('product_option_value.value'),
          type: 'table_line'
        },
        {
          id: 'parent_option_value',
          label: this.$t('product_option_value.parent_option_value'),
          type: 'table_line'
        },
        { id: 'actions', label: this.$t('app.actions') }
      ],
      isLoading: true,
      optionValues: nullResources,
      tableEventBus: new Vue(),
      allOptionValues: [],
      selectedOptionValueIds: []
    };
  },
  computed: {
    defaultResourceParams() {
      return {
        page: '1',
        per: '500',
        option_type_id: this.optionTypeId,
        search_type: 'value'
      };
    },
    rows() {
      return this.optionValues.items;
    },
    batchButtons() {
      const buttons = [];
      if (!this.orgResourceParams.search_query) {
        buttons.push({
          label: this.$t('batch_move'),
          tooltip: this.$t('tooltip_batch_move'),
          clickHandler: this.batchMove
        });
      }

      return buttons;
    },
    optionValueIndexMap() {
      const indexMap = {};
      this.allOptionValues.forEach((o, i) => (indexMap[o.id] = i));
      return indexMap;
    },
    notSelectedOptionValues() {
      return this.allOptionValues.filter(
        o => this.selectedOptionValueIds.indexOf(o.id) === -1
      );
    },
    notSelectedOptionValueIndexMap() {
      const indexMap = {};
      this.notSelectedOptionValues.forEach((o, i) => (indexMap[o.id] = i));
      return indexMap;
    }
  },
  mounted() {
    api
      .get('/product/option_values/all', {
        params: { option_type_id: this.optionTypeId }
      })
      .then(({ data }) => (this.allOptionValues = data.option_values));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    fetchResource(params) {
      api
        .get('/product/option_values', { params })
        .then(({ data }) => (this.optionValues = data.option_values))
        .finally(() => (this.isLoading = false));
    },
    addOptionValue() {
      this.editOptionValue(null, '');
    },
    editOptionValue(id, value) {
      this.openDialog([
        'ProductOptionValueFormDialog',
        {
          optionTypeId: this.optionTypeId,
          id,
          value,
          per: this.optionValues.per
        }
      ]).then(eventBus => {
        eventBus.$on('update', ({ option_value, option_values }) => {
          this.resourceParams = {
            ...this.defaultResourceParams,
            per: option_values.per,
            page: option_values.page
          };
          this.optionValues = option_values;

          if (option_values.total_count > this.allOptionValues.length) {
            this.allOptionValues.push(option_value);
          }

          this.$nextTick(() => {
            this.tableEventBus.$emit(
              'focus-row',
              option_values.items.findIndex(t => t.id === option_value.id)
            );
          });
        });
      });
    },
    deleteOptionValue(id) {
      if (confirm(this.$t('app.delete_confirm'))) {
        api
          .delete(`/product/option_values/${id}`, {
            params: {
              ...this.resourceParams,
              last_id: _.last(this.optionValues.items).id
            }
          })
          .then(({ data }) => {
            this.optionValues = {
              ...this.optionValues,
              items: [
                ...this.optionValues.items.filter(i => i.id !== id),
                ...(data.next_option_value ? [data.next_option_value] : [])
              ],
              total_count: this.optionValues.total_count - 1
            };
            _.remove(this.allOptionValues, v => v.id === id);
          });
      }
    },
    sort({ srcIndex, dstIndex }) {
      const orgAllOptionValues = this.allOptionValues;
      const srcIndexInAllValues = this.optionValueIndexMap[
        this.optionValues.items[srcIndex].id
      ];
      const dstIndexInAllValues = this.optionValueIndexMap[
        this.optionValues.items[dstIndex].id
      ];
      this.allOptionValues = arrayMoveIndex(
        this.allOptionValues,
        srcIndexInAllValues,
        dstIndexInAllValues
      );
      this.updatePositions(orgAllOptionValues);
    },
    batchSort({ srcIndexes, dstIndex }) {
      const orgAllOptionValues = this.allOptionValues;
      this.allOptionValues = arrayMoveIndexes(
        this.allOptionValues,
        srcIndexes,
        dstIndex
      );
      this.updatePositions(orgAllOptionValues);
    },
    updatePositions(orgAllOptionValues) {
      api
        .patch(
          '/product/option_values/sort',
          { option_value_ids: this.allOptionValues.map(c => c.id) },
          { successMessage: this.$t('sorted') }
        )
        .then(() => {
          const resourceParams = {
            ...this.orgResourceParams,
            per: this.optionValues.per,
            page: this.optionValues.page
          };
          this.refreshResourceWithParams(resourceParams);
        })
        .catch(() => {
          this.allOptionValues = orgAllOptionValues;
        });
    },
    batchMove() {
      this.openDialog([
        'ProductOptionValueMoveDialog',
        {
          optionValues: this.notSelectedOptionValues,
          selectedCount: this.selectedOptionValueIds.length
        }
      ]).then(eventBus => {
        eventBus.$on('move', ({ dstId, move }) => {
          this.batchSort({
            srcIndexes: this.selectedOptionValueIds.map(
              id => this.optionValueIndexMap[id]
            ),
            dstIndex: this.notSelectedOptionValueIndexMap[dstId] + move
          });
        });
      });
    },
    selectRows(rows) {
      const selectedRowIds = rows.map(row => row.id);
      const unselectedRowIds = this.rows
        .filter(row => !selectedRowIds.includes(row.id))
        .map(({ id }) => id);

      this.selectedOptionValueIds = [
        ...new Set([...this.selectedOptionValueIds, ...selectedRowIds])
      ].filter(id => !unselectedRowIds.includes(id));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .product-option-values-table__actions {
    flex: 0 0 160px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "상품 옵션값 관리",
  "add_option_value": "상품 옵션값 추가",
  "edit_option_value": "옵션값 수정",
  "sorted": "옵션값 순서가 변경되었습니다.",
  "batch_move": "특정 위치로 이동",
  "tooltip_batch_move": "여러 개의 옵션값을 특정 위치로 한 번에 이동할 수 있습니다."
}
</i18n>
