<template>
  <TheAccountMenuPopupService
    v-if="isInsightMenuAccessible"
    service="insight"
    :is-launched="false"
    :items="items"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TheAccountMenuPopupService from './TheAccountMenuPopupService';

export default {
  name: 'TheAccountMenuPopupServiceInsight',
  components: { TheAccountMenuPopupService },
  computed: {
    ...mapState('session', ['insightStatus']),
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('alert', ['insightRemainingDates']),
    ...mapGetters('session', [
      'isInsightTesting',
      'isInsightFreeTrial',
      'isInsightMenuAccessible'
    ]),
    items() {
      const { insight_expires_at } = this.insightStatus;

      if (this.isInsightTesting)
        return [{ title: this.$t('expires_on'), content: this.$t('testing') }];
      else if (this.isInsightFreeTrial)
        return [
          {
            title: this.$t('free_trial_expires_on'),
            content: this.formatDate(insight_expires_at),
            contentTooltip: this.expirationTooltip
          }
        ];
      else
        return [
          {
            title: this.$t('expires_on'),
            content: this.formatDate(insight_expires_at),
            contentTooltip: this.expirationTooltip
          }
        ];
    },
    expirationTooltip() {
      return this.insightRemainingDates >= 0
        ? this.$t('days_before_expiration', [this.insightRemainingDates])
        : this.$t('days_after_expiration', [-this.insightRemainingDates]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "expires_on": "만료일",
  "free_trial_expires_on": "무료체험 만료일",
  "testing": "테스트 중",
  "days_before_expiration": "잔여 {0}일",
  "days_after_expiration": "{0}일 전 만료"
}
</i18n>
