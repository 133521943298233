<template>
  <AppAjaxContent :is-loading="!data">
    <AppForm v-if="data" v-bind="reviewFormProps" v-on="formEvents" />
  </AppAjaxContent>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import interpolate from '@/lib/interpolate';
import ReviewSettingsView from './ReviewSettingsView';
import { MAX_BYTES_SMS } from '@/constants/MAX_BYTES';

export default {
  name: 'ReviewSettingsSuperAdminSms',
  mixins: [ReviewSettingsView],
  data() {
    return {
      SETTINGS_ID: 'review_settings_super_admin_sms',
      SETTINGS_URL: 'review/settings/super_admin_sms'
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    MAX_BYTES_SMS: () => MAX_BYTES_SMS,
    keywordsMap() {
      const sampleShortUrl = 'cre.ma/123456';
      return [
        'sms_message',
        'nonmember_sms_message',
        'offline_sms_message'
      ].reduce((map, attr) => {
        const keywords = [
          {
            key: 'brand_name',
            value: this.currentBrand.name,
            desc: this.$t('keyword.brand_name')
          },
          {
            key: 'brand_phone',
            value: this.reviewSettings.brand_phone,
            desc: this.$t('keyword.brand_phone')
          },
          {
            key: 'max_best_mileage',
            value: this.reviewSettings.max_best_mileage,
            desc: this.$t('keyword.max_best_mileage')
          },
          ...(attr === 'nonmember_sms_message'
            ? []
            : [
                {
                  key: 'user_name',
                  value: this.$t('sample_user_name'),
                  desc: this.$t('keyword.user_name')
                }
              ]),
          {
            key: 'review_url',
            value: sampleShortUrl,
            desc: this.$t('keyword.review_url')
          },
          {
            key: 'unsubscribe_url',
            value: sampleShortUrl,
            desc: this.$t('keyword.unsubscribe_url')
          }
        ];
        map[attr] = keywords;
        return map;
      }, {});
    },
    groupOptions() {
      return {
        sms_redirect_url: {
          type: 'url',
          placeholder: this.$t('sms_redirect_url.placeholder'),
          hint: this.$t('sms_redirect_url.hint'),
          validate: ['url_format']
        },
        sms_unit_price: {
          type: 'number',
          digits: 3,
          default: this.reviewSettings?.default_sms_unit_price,
          validate: ['positive_integer']
        },
        nonmember_sms_unit_price: {
          type: 'number',
          digits: 3,
          default: this.reviewSettings?.default_sms_unit_price,
          validate: ['positive_integer']
        },
        sms_message: {
          type: 'keyword_textarea',
          placeholder: this.$t('sms_message.placeholder'),
          keywords: this.keywordsMap.sms_message,
          maxBytes: this.MAX_BYTES_SMS,
          validate: [
            {
              rule: 'keywords',
              keywords: this.keywordsMap.sms_message
            },
            {
              rule: 'max_bytes',
              limit: this.MAX_BYTES_SMS,
              value: () => this.interpolatedMessage('sms_message')
            }
          ]
        },
        nonmember_sms_message: {
          type: 'keyword_textarea',
          placeholder: this.$t('nonmember_sms_message.placeholder'),
          keywords: this.keywordsMap.nonmember_sms_message,
          maxBytes: this.MAX_BYTES_SMS,
          validate: [
            {
              rule: 'keywords',
              keywords: this.keywordsMap.nonmember_sms_message
            },
            {
              rule: 'max_bytes',
              limit: this.MAX_BYTES_SMS,
              value: () => this.interpolatedMessage('nonmember_sms_message')
            }
          ]
        },
        offline_sms_message: {
          type: 'keyword_textarea',
          placeholder: this.$t('offline_sms_message.placeholder'),
          keywords: this.keywordsMap.offline_sms_message,
          maxBytes: this.MAX_BYTES_SMS,
          validate: [
            {
              rule: 'keywords',
              keywords: this.keywordsMap.offline_sms_message
            },
            {
              rule: 'max_bytes',
              limit: this.MAX_BYTES_SMS,
              value: () => this.interpolatedMessage('offline_sms_message')
            }
          ]
        }
      };
    }
  },
  methods: {
    interpolatedMessage(groupId) {
      const interpolation = _.fromPairs(
        this.keywordsMap[groupId].map(k => [k.key, k.value])
      );
      return interpolate(this.reviewSettings[groupId], interpolation);
    }
  }
};
</script>

<i18n locale="ko">
{
  "sms_redirect_url": {
    "placeholder": "리뷰 문자에 포함한 링크 클릭 시 이동할 URL을 설정합니다. 입력하지 않으면, 원래대로 리뷰 작성 링크로 이동합니다.",
    "hint": "크리마 리뷰 작성 페이지 대신 입력한 URL로 이동합니다. 쇼핑몰 요청 및 크리마 리뷰 작성 페이지 오류 시 사용합니다."
  },
  "sms_message": {
    "placeholder": "회원에게 발송할 SMS 메시지를 입력해주세요."
  },
  "nonmember_sms_message": {
    "placeholder": "비회원에게 발송할 SMS 메시지를 입력해주세요."
  },
  "offline_sms_message": {
    "placeholder": "매장 구매 고객에게 발송할 SMS 메시지를 입력해주세요."
  },
  "sample_user_name": "신사임당",
  "keyword": {
    "brand_name": "쇼핑몰명",
    "brand_phone": "쇼핑몰 대표 전화번호",
    "user_name": "고객명",
    "max_best_mileage": "최대 적립금",
    "review_url": "리뷰 작성 URL",
    "unsubscribe_url": "수신 거부 URL"
  }
}
</i18n>
