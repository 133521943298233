<template>
  <AppAjaxContent :is-loading="!data">
    <AppForm v-if="data" v-bind="reviewFormProps" v-on="formEvents">
      <template #group="{ id }">
        <template v-if="id === 'confirm_godo_api_registration'">
          <div class="AppForm__group-field">
            <AppDatePicker
              v-model="godoApiRegisteredDate"
              class="ReviewSettingsSuperAdminService__input-item"
            />
            <AppSelectHour
              v-model="godoApiRegisteredHour"
              class="ReviewSettingsSuperAdminService__input-item"
            />
            <AppButton
              class="ReviewSettingsSuperAdminService__input-item"
              :label="$t('confirm_godo_api_registration.button')"
              @click="confirmGodoApiRegistration"
            />
          </div>
        </template>
        <template v-else-if="id === 'download_unreviewed_sub_orders'">
          <div class="AppForm__group-field">
            <div class="AppForm__sub-group">
              <AppSelectProduct
                multiple
                packing-method="string"
                :products="downloadUnreviewedSubOrdersProducts"
                @change-items="downloadUnreviewedSubOrdersProducts = $event"
              />
            </div>
            <div class="AppForm__sub-group">
              <AppDateRangePicker
                v-model="downloadUnreviewedSubOrdersRange"
                required
              />
              <AppButtonDownload
                class="ReviewSettingsSuperAdminService__input-item"
                :label="$t('csv_download')"
                :disabled="!downloadableUnreviewedSubOrders"
                :url="downloadUnreviewedSubOrdersUrl"
              />
            </div>
          </div>
        </template>
        <template v-else-if="id === 'download_video_reviews'">
          <div class="AppForm__group-field">
            <div class="AppForm__sub-group">
              <AppDateRangePicker v-model="downloadVideoReviewsRange" />
              <AppButtonDownload
                class="ReviewSettingsSuperAdminService__input-item"
                :label="$t('csv_download')"
                :url="downloadVideoReviewsUrl"
              />
            </div>
          </div>
        </template>
        <template
          v-else-if="id === 'download_using_after_use_review_badge_brands'"
        >
          <div class="AppForm__group-field">
            <div class="AppForm__sub-group">
              <AppButtonDownload
                class="ReviewSettingsSuperAdminService__input-item"
                :label="$t('csv_download')"
                :url="downloadUsingAfterUserReviewBadgeBrands"
              />
            </div>
          </div>
        </template>
        <template v-else-if="id === 'download_using_review_sync_brands'">
          <div class="AppForm__group-field">
            <div class="AppForm__sub-group">
              <AppButtonDownload
                class="ReviewSettingsSuperAdminService__input-item"
                :label="$t('csv_download')"
                :url="downloadUsingReviewSyncBrands"
              />
            </div>
          </div>
        </template>
        <template v-else-if="id === 'download_user_statuses'">
          <div class="AppForm__group-field">
            <div class="AppForm__sub-group">
              <AppButtonDownload
                class="ReviewSettingsSuperAdminService__input-item"
                :label="$t('csv_download')"
                :url="downloadUserStatuses"
              />
            </div>
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import qs from 'qs';
import { mapState } from 'vuex';
import ReviewSettingsView from './ReviewSettingsView';
import GodoApiRegistrationConfirmationHelper from '@/mixins/GodoApiRegistrationConfirmationHelper';

export default {
  name: 'ReviewSettingsSuperAdminService',
  mixins: [ReviewSettingsView, GodoApiRegistrationConfirmationHelper],
  data() {
    return {
      SETTINGS_ID: 'review_settings_super_admin_service',
      SETTINGS_URL: 'review/settings/super_admin_service',
      downloadUnreviewedSubOrdersRange: {},
      downloadUnreviewedSubOrdersProducts: [],
      downloadVideoReviewsRange: {}
    };
  },
  computed: {
    ...mapState(['brandParams']),
    groupOptions() {
      return {
        confirm_godo_api_registration: {
          groupDescription: this.$t('confirm_godo_api_registration.description')
        }
      };
    },
    downloadableUnreviewedSubOrders() {
      return (
        this.downloadUnreviewedSubOrdersRange.start_date &&
        this.downloadUnreviewedSubOrdersRange.end_date &&
        this.downloadUnreviewedSubOrdersProducts.length > 0
      );
    },
    downloadUnreviewedSubOrdersUrl() {
      const params = {
        ...this.brandParams,
        ...this.downloadUnreviewedSubOrdersRange,
        product_ids: this.downloadUnreviewedSubOrdersProducts.map(
          product => product.id
        )
      };
      const path = '/api/review/service_runner/download_unreviewed_sub_orders';
      const url = `${path}?${qs.stringify(params, {
        arrayFormat: 'brackets'
      })}`;

      return url;
    },
    downloadVideoReviewsUrl() {
      const params = {
        ...this.brandParams,
        ...this.downloadVideoReviewsRange
      };
      const path = '/api/review/service_runner/download_video_reviews';
      const url = `${path}?${qs.stringify(params)}`;

      return url;
    },
    downloadUsingAfterUserReviewBadgeBrands() {
      return '/api/review/service_runner/download_using_after_use_review_badge_brands';
    },
    downloadUsingReviewSyncBrands() {
      return '/api/review/service_runner/download_using_review_sync_brands';
    },
    downloadUserStatuses() {
      return '/api/review/service_runner/download_user_statuses';
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewSettingsSuperAdminService__input-item {
  & + & {
    margin-left: 8px;
  }
  display: inline-block;
  vertical-align: middle;
}
</style>

<i18n locale="ko">
{
  "confirm_godo_api_registration": {
    "description": "고도 API 사용 승인을 위해 고도 가입 신청한 날짜를 기반으로 로그를 검색합니다.<br>정확한 날짜를 입력해주세요.",
    "button": "요청 승인"
  },
  "csv_download": "csv 다운로드"
}
</i18n>
