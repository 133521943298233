import i18n from '@/lib/i18n';
import _ from 'lodash';

export const constructEnum = (name, enums) => {
  const inverted = _.invert(enums);
  const t = (v, args) => {
    if (v === undefined) {
      // eslint-disable-next-line no-console
      console.error(`value must be provided for ${name}.t`);
      return `${name}.undefined`;
    }
    // TODO: retire this completely after Jan 28, 2024
    if (typeof v === 'string' && !(v in inverted)) {
      // eslint-disable-next-line no-console
      console.warn(
        `Using ${name}.t with a key is deprecated. Use ${name}.tk instead.`
      );
      return tk(v, args);
    }

    return tv(v, args);
  };
  const tk = (key, args) => {
    if (key === undefined) {
      // eslint-disable-next-line no-console
      console.error(`key must be provided for ${name}.t`);
      return `${name}.undefined`;
    }
    return i18n.t(`${name}.${key.toUpperCase()}`, args);
  };
  const tv = (value, args) => i18n.t(`${name}.${inverted[value]}`, args);
  const te = v =>
    i18n.te(`${name}.${typeof v === 'string' ? v.toUpperCase() : inverted[v]}`);
  const key = v => inverted[v];
  const values = _.values(enums);
  return {
    ...enums,
    t,
    tk,
    te,
    key,
    options(values = enums) {
      return _.map(values, value => ({ value, label: tv(value) }));
    },
    values,
    toKey: v => inverted[v],
    hasValue: v => values.includes(v)
  };
};
