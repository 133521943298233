<template>
  <AppModalDefault
    :title="$t('menu.payment_history_dialog')"
    width="auto"
    @close="close"
  >
    <template #body>
      <AppTabs
        v-model="activeTab"
        :tabs="childTabItems('payment_history_dialog')"
      />
      <Component :is="activeComponent" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogView from '@/mixins/DialogView';
import ThePaymentHistoryDialogPayments from './ThePaymentHistoryDialogPayments';
import ThePaymentHistoryDialogReview from './ThePaymentHistoryDialogReview';
import ThePaymentHistoryDialogFit from './ThePaymentHistoryDialogFit';
import ThePaymentHistoryDialogTarget from './ThePaymentHistoryDialogTarget';
import ThePaymentHistoryDialogDisplay from './ThePaymentHistoryDialogDisplay';
import ThePaymentHistoryDialogChannel from './ThePaymentHistoryDialogChannel';
import ThePaymentHistoryDialogInsight from './ThePaymentHistoryDialogInsight';
import ThePaymentHistoryDialogReviewAdditionalServices from './ThePaymentHistoryDialogReviewAdditionalServices';

export default {
  name: 'ThePaymentHistoryDialog',
  components: {
    ThePaymentHistoryDialogPayments,
    ThePaymentHistoryDialogReview,
    ThePaymentHistoryDialogFit,
    ThePaymentHistoryDialogTarget,
    ThePaymentHistoryDialogDisplay,
    ThePaymentHistoryDialogChannel,
    ThePaymentHistoryDialogInsight,
    ThePaymentHistoryDialogReviewAdditionalServices
  },
  mixins: [DialogView],
  props: {
    initialTab: { type: String, default: 'payment_history_dialog_payments' }
  },
  data() {
    return { activeTab: this.initialTab };
  },
  computed: {
    ...mapGetters('menu', ['childTabItems']),
    activeComponent() {
      switch (this.activeTab) {
        case 'payment_history_dialog_review':
          return 'ThePaymentHistoryDialogReview';
        case 'payment_history_dialog_fit':
          return 'ThePaymentHistoryDialogFit';
        case 'payment_history_dialog_target':
          return 'ThePaymentHistoryDialogTarget';
        case 'payment_history_dialog_display':
          return 'ThePaymentHistoryDialogDisplay';
        case 'payment_history_dialog_ad':
          return 'ThePaymentHistoryDialogChannel';
        case 'payment_history_dialog_insight':
          return 'ThePaymentHistoryDialogInsight';
        case 'payment_history_dialog_review_additional_services':
          return 'ThePaymentHistoryDialogReviewAdditionalServices';
        default:
          return 'ThePaymentHistoryDialogPayments';
      }
    }
  }
};
</script>
