<template>
  <AppResourceTable
    :columns="columns"
    :resources="cohorts"
    :rows="rows"
    :enable-total-count="false"
    table-id="cohorts"
    @paginate="$emit('paginate', $event)"
  >
    <template #cell="{ row, column }">
      <template v-if="column === 'corners'">
        <AppSummaryBadge :items="row.corners" unit-path="count" />
      </template>
      <template v-else-if="column === 'actions'">
        <AppButton
          v-if="row.isScheduleCancelable"
          :label="$t('button.cancel_schedule')"
          button-style="red-outline"
          @click="cancelSchedule(row)"
        />
        <AppButton
          v-if="row.isRetriable"
          :label="$t('button.retriable')"
          :disabled="row.isRetriableCleared || row.isProductDisplayApplying"
          :tooltip="
            row.isRetriableCleared
              ? $t('button.retriable_cleared')
              : row.isProductDisplayApplying
              ? $t('button.product_display_applying')
              : null
          "
          @click="confirmRetry(row)"
        />
        <AppButton
          :label="$t('button.detail')"
          :disabled="
            row.isDetailCleared || row.isAutoScheduled || row.isApplying
          "
          :tooltip="
            row.isApplying
              ? applyingTooltip(row)
              : row.isDetailCleared
              ? $t('button.detail_cleared')
              : row.isAutoScheduled
              ? $t('button.auto_scheduled')
              : null
          "
          @click="openDetailDialog(row)"
        />
      </template>
    </template>
  </AppResourceTable>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';
import DisplayCornerStatus from '@/enums/DisplayCornerStatus';
import DisplayProductDisplayScheduleType from '@/enums/DisplayProductDisplayScheduleType';
import DisplayProductDisplayExecuteStatus from '@/enums/DisplayProductDisplayExecuteStatus';
import ShopBuilder from '@/enums/ShopBuilder';

export default {
  name: 'DisplayProductDisplayCohortsTable',
  props: {
    cohorts: { type: Object, required: true },
    showProductDisplayName: { type: Boolean, default: true },
    cornerCode: { type: String, default: null }
  },
  computed: {
    ...mapGetters('displayProductDisplays', [
      'isCategoryPage',
      'cornerPageTypeKey'
    ]),
    ...mapState('session', ['currentBrand']),
    columnConfigs() {
      return {
        id: { label: this.$t('app.id'), superAdmin: true },
        product_display_name: {
          label: this.$t('column.product_display_name.label'),
          tooltip: this.isCategoryPage
            ? null
            : {
                message: this.$t('column.product_display_name.tooltip'),
                textAlign: 'left'
              },
          align: 'left'
        },
        corners: {
          label: this.$t('column.corners.label'),
          align: 'left'
        },
        display_range: {
          label: this.$t('column.display_range.label'),
          align: 'left'
        },
        actions: { label: this.$t('app.actions') }
      };
    },
    columnWidth() {
      if (this.isCategoryPage)
        return {
          id: '48px',
          product_display_name: '142px',
          corners: this.showProductDisplayName ? '180px' : '322px',
          display_range: '300px',
          actions: '200px'
        };
      else
        return {
          id: '48px',
          product_display_name: '282px',
          display_range: '300px',
          actions: '200px'
        };
    },
    columnIds() {
      if (this.isCategoryPage)
        return [
          'id',
          ...(this.showProductDisplayName ? ['product_display_name'] : []),
          'corners',
          'display_range',
          'actions'
        ];
      else return ['id', 'product_display_name', 'display_range', 'actions'];
    },
    columns() {
      return this.columnIds.map(id => ({
        id,
        ...this.columnConfigs[id],
        width: this.columnWidth[id]
      }));
    },
    rows() {
      return this.cohorts.items.map(cohort => ({
        ...cohort,
        display_range: this.displayRange(cohort),
        isScheduleCancelable: moment() < moment(cohort.display_at),
        isRetriable: cohort.is_last_errored,
        isRetriableCleared:
          moment().subtract(7, 'day') > moment(cohort.display_at),
        isProductDisplayApplying: cohort.is_product_display_applying,
        isAutoScheduled:
          cohort.schedule_type === DisplayProductDisplayScheduleType.AUTO &&
          !cohort.display_started_at &&
          !DisplayProductDisplayExecuteStatus.isErrored(cohort.execute_status),
        isDetailCleared: cohort.display_started_at
          ? moment().subtract(1, 'month') > moment(cohort.display_started_at)
          : false,
        isApplying: DisplayProductDisplayExecuteStatus.isApplying(
          cohort.execute_status
        ),
        corners: cohort.corners.map(corner => {
          const label = DisplayCornerStatus.isDeleted(corner.corner_status)
            ? `${corner.name} ${this.$t('column.corners.deleted')}`
            : corner.name;
          return { label, description: label };
        })
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    displayRange(cohort) {
      const displayAt = this.formatDateTimeWithoutSecond(cohort.display_at);
      const displayStartedAt = this.formatDateTimeWithoutSecond(
        cohort.display_started_at
      );
      const displayEndedAt = this.formatDateTimeWithoutSecond(
        cohort.display_ended_at
      );

      if (displayEndedAt) return `${displayStartedAt} - ${displayEndedAt}`;
      else if (displayStartedAt)
        return this.$t('display_range.displaying', [displayStartedAt]);
      else if (
        DisplayProductDisplayExecuteStatus.isErrored(cohort.execute_status)
      )
        return this.$t(
          `display_range.errored.${DisplayProductDisplayScheduleType.key(
            cohort.schedule_type
          )}`,
          [displayAt]
        );
      else {
        if (cohort.schedule_type === DisplayProductDisplayScheduleType.AUTO)
          return this.$t('display_range.auto_scheduled', [displayAt]);
        else return this.$t('display_range.scheduled', [displayAt]);
      }
    },
    isApplyingAlertDialog(cohort) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('button.applying.title'),
          markdownText: this.$t(
            `button.applying.${DisplayProductDisplayScheduleType.key(
              cohort.schedule_type
            )}`,
            [this.formatDateTimeWithoutSecond(cohort.display_at)]
          ),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => eventBus.$on('close', () => this.$emit('update')));
    },
    cancelSchedule(cohort) {
      api
        .get(`/display/product_display/cohorts/${cohort.id}/applying`)
        .then(({ data }) => {
          if (data.is_applying) {
            this.isApplyingAlertDialog(cohort);
          } else if (
            cohort.schedule_type === DisplayProductDisplayScheduleType.AUTO
          )
            this.cancelAutoSchedule(cohort);
          else this.cancelScheduleOnce(cohort);
        });
    },
    cancelAutoSchedule(cohort) {
      this.openDialog([
        'DisplayProductDisplayCancelAutoScheduleDialog',
        {
          productDisplayId: cohort.product_display_id,
          productDisplayName: cohort.product_display_name,
          scheduledAt: cohort.display_at
        }
      ]).then(eventBus =>
        eventBus.$on('schedule-canceled', () => this.$emit('update'))
      );
    },
    cancelScheduleOnce(cohort) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t('cancel_schedule.title'),
          markdownText: this.$t('cancel_schedule.content_html', [
            cohort.product_display_name,
            this.formatDateTimeWithoutSecond(cohort.display_at)
          ]),
          width: DialogSize.AUTO
        }
      ]).then(eventBus =>
        eventBus.$on('close', () => this.requestCancelScheduleOnce(cohort))
      );
    },
    requestCancelScheduleOnce(cohort) {
      api
        .post(
          `/display/product_displays/${cohort.product_display_id}/cancel_schedule_once`,
          { cohort_id: cohort.id }
        )
        .then(({ data }) => {
          if (data.result === 'canceled')
            this.createToast(
              this.$t('product_display.cancel_schedule_once.canceled')
            );
          else
            this.openDialog([
              'AppMessageDialog',
              {
                title: this.$t(
                  'product_display.cancel_schedule_once.failed_alert.title'
                ),
                type: 'alert',
                markdownText: this.$t(
                  `product_display.cancel_schedule_once.failed_alert.${data.result}`
                ),
                width: DialogSize.SMALL
              }
            ]);
        })
        .finally(() => this.$emit('update'));
    },
    openDetailDialog(cohort) {
      DisplayProductDisplayExecuteStatus.isErrored(cohort.execute_status)
        ? this.openErrorDetailDialog(cohort)
        : this.openCohortDialog(cohort);
    },
    openCohortDialog(cohort) {
      api
        .get(`/display/product_display/cohorts/${cohort.id}/applying`)
        .then(({ data }) => {
          if (data.is_applying) {
            this.isApplyingAlertDialog(cohort);
          } else
            this.openDialog([
              'DisplayProductDisplayCohortDialog',
              {
                cohortId: cohort.id,
                defaultCornerCode: this.cornerCode
              }
            ]);
        });
    },
    applyingTooltip(row) {
      return this.$t(
        `button.applying.${DisplayProductDisplayScheduleType.key(
          row.schedule_type
        )}`,
        [this.formatDateTimeWithoutSecond(row.display_at)]
      );
    },
    confirmRetry(row) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t('retry.title'),
          markdownText: this.$t(
            `retry.content.${DisplayProductDisplayScheduleType.key(
              row.schedule_type
            )}`
          ),
          closeButtonLabel: this.$t('retry.confirm'),
          cancelButtonLabel: this.$t('retry.cancel'),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', () =>
          api
            .post(
              `/display/product_displays/${
                row.product_display_id
              }/schedule_retry_${DisplayProductDisplayScheduleType.key(
                row.schedule_type
              ).toLowerCase()}`
            )
            .then(() => this.$emit('update'))
        );
      });
    },
    openErrorDetailDialog(cohort) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t(`error_detail.${this.cornerPageTypeKey}.title`),
          markdownText: this.errorMessage(cohort),
          width: DialogSize.SMALL
        }
      ]);
    },
    errorMessage(cohort) {
      let argument;
      switch (cohort.execute_status) {
        case DisplayProductDisplayExecuteStatus.ERROR_DISPATCHING:
        case DisplayProductDisplayExecuteStatus.ERROR_LOGIN_BLOCKED:
        case DisplayProductDisplayExecuteStatus.ERROR_SESSION_EXPIRED:
          argument = ShopBuilder.tk(this.currentBrand.shop_builder);
          break;
        case DisplayProductDisplayExecuteStatus.ERROR_CORNER_NOT_EXISTS:
          argument = cohort.corner_name;
          break;
      }

      return this.$t(
        `error_detail.${
          this.cornerPageTypeKey
        }.${DisplayProductDisplayExecuteStatus.key(cohort.execute_status)}`,
        [argument]
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "button": {
    "cancel_schedule": "예약 취소",
    "retriable": "재시도",
    "retriable_cleared": "재시도 기능은 가장 최근 이력에 대해서만 최대 7일까지 지원합니다.",
    "product_display_applying": "운영자 또는 시스템에 의해 자동으로 시작한 진열이 이미 진행 중입니다.",
    "detail": "상세 내역",
    "detail_cleared": "상세 내역은 최대 1개월까지 지원합니다.",
    "auto_scheduled": "반복 진열 상품은 예약시점의 데이터를 바탕으로 계산됩니다.",
    "applying": {
      "title": "현재 진열 중입니다.",
      "NOW": "{0}에 시작된 즉시 진열이 진행 중입니다.",
      "ONCE": "<p>{0}에 1회 예약된 진열이 진행 중입니다.</p>",
      "AUTO": "<p>{0}에 반복 예약된 진열이 진행 중입니다.</p>"
    }
  },
  "column": {
    "product_display_name": {
      "label": "설정 이름",
      "tooltip": "진열 위치 별로 어떤 상품을 어떤 식으로\n진열할지 결정할 수 있습니다.\n위치별로 다수의 진열 설정이 가능합니다."
    },
    "corners": {
      "label": "대상 카테고리",
      "deleted": "(삭제됨)"
    },
    "display_range": {
      "label": "진열 기간"
    }
  },
  "display_range": {
    "displaying": "{0} - 진열 중",
    "errored": {
      "NOW": "{0} - 즉시 진열 실패",
      "ONCE": "{0} - 1회 예약 진열 실패",
      "AUTO": "{0} - 반복 예약 진열 실패"
    },
    "scheduled": "{0} 진열 예정",
    "auto_scheduled": "{0} 반복 진열 예약"
  },
  "cancel_schedule": {
    "title": "진열 예약 취소",
    "content_html": "{0}에 대해 {1}에 예약된 작업을 취소합니다.<br />해당 작업은 되돌릴 수 없습니다.<br />진열 예약을 취소 하시겠습니까?"
  },
  "retry": {
    "title": "재시도합니다.",
    "content": {
      "NOW": "진열 설정 미리보기에 저장된 상품 그대로 진열합니다.<br>진행 하시겠습니까?",
      "ONCE": "진열 설정 미리보기에 저장된 상품 그대로 진열합니다.<br>진행 하시겠습니까?",
      "AUTO": "진열 설정에 저장된 진열 규칙을 바탕으로<br>상품 정보 동기화 및 진열을 진행합니다.<br>진행 하시겠습니까?"
    },
    "confirm": "재시도",
    "cancel": "나중에"
  },
  "error_detail": {
    "MAIN": {
      "title": "메인 페이지 진열 중 문제가 발생했습니다.",
      "ERROR_PREPARING": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_CALCULATING": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_DISPATCHING": "{0} 메인페이지 진열 중 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_CORNER_NOT_EXISTS": "{0} 진열 위치를 찾을 수 없습니다.<br>[진열 위치 동기화] 후 다시 시도하시기 바랍니다.",
      "ERROR_LOGIN_BLOCKED": "{0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_SESSION_EXPIRED": "세션이 만료되어 {0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다."
    },
    "CATEGORY": {
      "title": "카테고리 페이지 진열 중 문제가 발생했습니다.",
      "ERROR_PREPARING": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_CALCULATING": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_DISPATCHING": "{0} 카테고리 페이지 진열 중 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_CORNER_NOT_EXISTS": "{0} 카테고리를 찾을 수 없습니다.<br>카테고리 정보 확인 후 다시 시도하시기 바랍니다.",
      "ERROR_LOGIN_BLOCKED": "{0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
      "ERROR_SESSION_EXPIRED": "세션이 만료되어 {0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다."
    }
  }
}
</i18n>
