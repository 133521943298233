import DialogFormView from '@/mixins/DialogFormView';

export default {
  mixins: [DialogFormView],
  props: { selectedProducts: { type: Array, required: true } },
  computed: {
    isValid() {
      return this.selectedProducts.length;
    },
    totalProductNames() {
      return this.selectedProducts.map(product => product.name);
    },
    subTitle() {
      const { totalProductNames } = this;

      if (!this.isValid) return null;
      else if (totalProductNames.length === 1) return totalProductNames[0];
      else
        return this.$t('unit.omitted_count', [
          totalProductNames[0],
          totalProductNames.length - 1
        ]);
    },
    productsName() {
      const { totalProductNames } = this;

      if (!this.isValid) return this.$t('product_display.no_products');
      else if (totalProductNames.length > 20) {
        const slicedProductNames = totalProductNames.slice(0, 20);
        slicedProductNames.push('..');

        return slicedProductNames.join(', ');
      } else return totalProductNames.join(', ');
    }
  },
  mounted() {
    if (!this.isValid) this.detectFormDataChange = false;
  }
};
