<template>
  <div class="AppAlertBox">
    <div class="AppAlertBox__title">{{ title }}</div>
    <div class="AppAlertBox__content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: 'AppAlertBox',
  props: {
    title: { type: String, required: true },
    content: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppAlertBox {
  text-align: left;
  padding: 12px 16px;
  border-radius: 3px;
  background-color: $color-red-lighter;
  border: solid 1px $color-red-light;
}

.AppAlertBox__title {
  @include text-label;

  color: $color-red;
}

.AppAlertBox__content {
  @include text-content;

  white-space: pre-line;
}
</style>
