<template>
  <AppModalDefault :title="$t('title')" width="wide" @close="close">
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppResourceTable
          table-id="spam-reports-table"
          :columns="COLUMNS"
          :rows="rows"
        />
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'ReviewSpamReportsHistoryDialog',
  mixins: [DialogView],
  props: {
    spamReportableId: { type: Number, required: true },
    spamReportableType: { type: String, required: true }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'created_at', label: this.$t('app.date'), type: 'datetime' },
        { id: 'reporter', label: this.$t('spam_report.reporter') },
        { id: 'spam_type', label: this.$t('spam_report.spam_type') },
        {
          id: 'reason',
          label: this.$t('spam_report.reason'),
          align: 'left',
          type: 'pre'
        }
      ],
      spam_reports: [],
      isLoading: true
    };
  },
  computed: {
    rows() {
      return this.spam_reports;
    }
  },
  mounted() {
    api
      .get('/review/spam_reports/', {
        params: {
          spam_reportable_id: this.spamReportableId,
          spam_reportable_type: this.spamReportableType
        }
      })
      .then(({ data }) => (this.spam_reports = data.spam_reports))
      .finally(() => (this.isLoading = false));
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .spam-reports-table__created-at {
    flex-basis: 124px;
  }

  .spam-reports-table__reporter {
    flex-basis: 60px;
  }

  .spam-reports-table__spam-type {
    flex-basis: 80px;
  }

  .spam-reports-table__reason {
    flex-basis: 400px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "신고 이력",
  "spam_report": {
    "reporter": "신고자 ID",
    "spam_type": "사유",
    "reason": "내용"
  }
}
</i18n>
<i18n locale="en">
{
  "title": "Spam report history",
  "spam_report": {
    "reporter": "Reporter ID",
    "spam_type": "Type of spam",
    "reason": "Reason"
  }
}
</i18n>
