<template>
  <AppTabContent menu-item-id="fit_reports" :params="tabParams">
    <AppAjaxContent :is-loading="isLoading">
      <RouterView
        :reports="reports"
        :reports-range="reportsRange"
        :reports-status="reportsStatus"
        :resource-params="resourceParams[currentReport]"
        :pagination="pagination[currentReport]"
        :products="products"
        :summary="summary"
        :min-date="minDate"
        @change-resource-params="changeResourceParams"
        @change-pagination="changePagination"
        @search-resource="searchResource"
      />
    </AppAjaxContent>
  </AppTabContent>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import api from '@/lib/api';
import { trimParams } from '@/lib/params';
import { mapGetters, mapActions } from 'vuex';

const DEFAULT_PAGINATION = { page: 1, per: 20 };

export default {
  name: 'FitReports',
  data() {
    return {
      reportsRange: {},
      reports: [],
      reportsStatus: null,
      isLoading: true,
      resourceParams: { main_kpis: {}, size_feedbacks: {}, return_rates: {} },
      pagination: {
        main_kpis: DEFAULT_PAGINATION,
        size_feedbacks: DEFAULT_PAGINATION,
        return_rates: DEFAULT_PAGINATION
      },
      products: [],
      summary: {},
      minDate: '2019-12-01' // NOTE: 반품률은 데이터 동기화를 시작한 2019년 12월 1일부터 조회가 가능하도록 한다.
    };
  },
  computed: {
    ...mapGetters('menu', ['selectedTabItemId']),
    defaultResourceParams() {
      if (this.currentReport === 'main_kpis') {
        return {
          interval: 'monthly',
          start_date: this.getDateAgo(1, 'month'),
          end_date: this.getDateAgo(1, 'day')
        };
      } else if (this.currentReport === 'return_rates') {
        return {
          interval: 'monthly',
          start_date: this.getDateAgo(6, 'month'),
          end_date: this.getDateAgo(1, 'month')
        };
      } else {
        return {
          interval: 'daily',
          start_date: this.getDateAgo(8, 'day'),
          end_date: this.getDateAgo(1, 'day')
        };
      }
    },
    tabParams() {
      return this.resourceParams[this.currentReport];
    },
    currentReport() {
      return this.selectedTabItemId.replace('fit_reports_', '');
    }
  },
  watch: {
    $route() {
      const query = this.makeQuery();
      // NOTE: `this.$route.query`를 currentReport의 resourceParams로 업데이트한다.
      if (!_.isEqual(this.$route.query, query)) {
        this.$router.replace({
          query: query
        });
        this.fetchResource();
      }
      this.changeRoute();
    }
  },
  created() {
    this.changeRoute();
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    changeRoute() {
      this.setResourceParams({
        ...(_.isEmpty(this.resourceParams[this.currentReport])
          ? { ...this.defaultResourceParams, ...this.$route.query }
          : this.resourceParams[this.currentReport])
      });
    },
    fetchResource() {
      this.isLoading = true;
      api
        .get(`/fit/statistics/${this.currentReport}`, {
          params: {
            ...this.$store.state.brandParams,
            ...this.resourceParams[this.currentReport]
          }
        })
        .then(({ data }) => {
          this.reportsStatus = data.status;
          this.reports = data.statistics || [];
          this.reportsRange = { ...this.resourceParams[this.currentReport] };
          this.products = data.products;
          this.summary = data.summary;
        })
        .finally(() => (this.isLoading = false));
    },
    setResourceParams(params) {
      const newParams = {
        ...this.resourceParams[this.currentReport],
        ...params
      };
      if (_.isEqual(this.resourceParams[this.currentReport], newParams)) return;
      this.resourceParams[this.currentReport] = newParams;
      this.fetchResource();
    },
    changeResourceParams(params) {
      this.setResourceParams(params);
      this.$set(this.pagination[this.currentReport], 'page', 1);
      this.pushRoute();
    },
    changePagination(newPagination) {
      this.pagination[this.currentReport] = newPagination;
      this.pushRoute();
    },
    searchResource() {
      const query = this.makeQuery();
      if (_.isEqual(this.$route.query, query)) return;

      this.fetchResource();
      this.$set(this.pagination[this.currentReport], 'page', 1);
      this.pushRoute();
    },
    makeQuery() {
      const defaultParams = {
        ...this.defaultResourceParams,
        ..._.mapValues(DEFAULT_PAGINATION, v => v.toString())
      };

      const query = trimParams(
        {
          ...this.$store.state.brandParams,
          ...this.resourceParams[this.currentReport],
          per: this.pagination[this.currentReport].per.toString(),
          page: this.pagination[this.currentReport].page.toString()
        },
        defaultParams
      );
      return query;
    },
    pushRoute() {
      const query = this.makeQuery();
      this.$router.push({ query });
    },
    getDateAgo(duration, unit) {
      return moment()
        .subtract(duration, unit)
        .toVal();
    }
  }
};
</script>
