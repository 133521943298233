<template>
  <div class="TheEasyJoinRetry">
    <AppText :text="$t('message')" />
    <AppButtonSubmit
      class="TheEasyJoinRetry__submit-button"
      :submit-label="$t('submit_label')"
      @click="$emit('submit')"
    />
  </div>
</template>

<script>
export default {
  name: 'TheEasyJoinRetry'
};
</script>

<style lang="scss" scoped>
.TheEasyJoinRetry {
  text-align: center;
  margin-top: 100px;
}

.TheEasyJoinRetry__submit-button {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "message": "계정 생성 중 문제가 발생했습니다.<br>아래 버튼을 클릭하여 계정 생성을 재시도해주세요.",
  "submit_label": "크리마 계정 생성"
}
</i18n>
