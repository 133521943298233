<template>
  <ReviewSection :title="$t('title')">
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="isSuperAdminAccessible"
      class="ReviewReviewAnalysisSection__button super-admin__item"
      @click="openKeywordWidgetMessageDialog"
      v-html="$t('keyword_widget_message.button_html')"
    />
    <!-- eslint-enable vue/no-v-html -->
    <AppTable
      table-id="review-review-analysis-section-table"
      :columns="COLUMNS"
      :rows="rows"
    >
      <template #cell="{ row, column }">
        <template v-if="column.id === 'name'">
          {{ row.name }}
        </template>
        <template v-else-if="column.id === 'sentiment'">
          <AppBadge
            v-if="ReviewSentimentType.hasValue(row.sentiment)"
            :label="
              $t('sentiment_badge', {
                value: formatSentiment(row.sentiment),
                type: ReviewSentimentType.t(row.sentiment)
              })
            "
            :badge-style="formatBadgeStyle(row.sentiment)"
          />
        </template>
        <template v-else-if="column.id === 'product'">
          <div v-tooltip="row.product.tooltip">
            {{ `${row.product.mean}/${formatMax}` }}
          </div>
        </template>
        <template v-else-if="column.id === 'brand_user'">
          <div v-tooltip="row.brand_user.tooltip">
            {{ `${row.brand_user.mean}/${formatMax}` }}
          </div>
        </template>
      </template>
    </AppTable>
  </ReviewSection>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import DialogSize from '@/enums/DialogSize';
import ReviewSentimentType from '@/enums/ReviewSentimentType';
import ReviewSentimentTypeHelper from '@/mixins/ReviewSentimentTypeHelper';

import ReviewSection from './components/ReviewSection';

export default {
  name: 'ReviewReviewAnalysisSection',
  components: { ReviewSection },
  mixins: [ReviewSentimentTypeHelper],
  props: {
    reviewAnalysisReports: {
      type: Array,
      required: true
    },
    reviewIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    const columns = ['name', 'sentiment', 'product'];
    if (this.reviewAnalysisReports[0].brand_user) {
      columns.push('brand_user');
    }
    return {
      COLUMNS: columns.map(c => ({
        id: c,
        label: this.$t(`column.${c}`)
      }))
    };
  },
  computed: {
    ...mapState('review', ['reviews']),
    ...mapGetters('session', ['reviewTagTypeNameMap']),
    ...mapGetters(['isSuperAdminAccessible']),
    ReviewSentimentType() {
      return ReviewSentimentType;
    },
    reviewTagsMap() {
      return _.keyBy(
        this.reviews.items[this.reviewIndex].review_tags,
        'review_tag_type_id'
      );
    },
    rows() {
      return this.reviewAnalysisReports.map(({ key, product, brand_user }) => ({
        name: this.reviewTagTypeNameMap[key],
        sentiment: this.reviewTagsMap[key]
          ? this.reviewTagsMap[key].sentiment
          : null,
        product: {
          mean: this.formatMean(product.mean),
          tooltip: this.statisticsTooltipMessage(product)
        },
        ...(brand_user
          ? {
              brand_user: {
                mean: this.formatMean(brand_user.mean),
                tooltip: this.statisticsTooltipMessage(brand_user)
              }
            }
          : {})
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    statisticsTooltipMessage({ mean, mode, mode_rate }) {
      return {
        message: [
          this.$t('tooltip.mean'),
          `- ${this.formatMean(mean)}/${this.formatMax}`,
          '',
          this.$t('tooltip.mode'),
          `- ${this.$t('tooltip.mode_detail', {
            value: this.formatSentiment(mode),
            type: ReviewSentimentType.t(mode),
            rate: mode_rate * 100
          })}`
        ].join('\n'),
        textAlign: 'left'
      };
    },
    openKeywordWidgetMessageDialog() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('keyword_widget_message.title'),
          width: DialogSize.SMALL,
          markdownText: this.$t('keyword_widget_message.message_markdown')
        }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ReviewReviewAnalysisSection__button {
  @include text-caption;

  text-align: right;
  padding-bottom: 4px;
  margin-top: -6px;

  &:hover {
    @include text-caption-dark;
    cursor: pointer;
    user-select: none;
  }
}
</style>

<i18n locale="ko">
{
  "title": "주요 키워드별 분석",
  "column": {
    "name": "키워드",
    "sentiment": "이 리뷰",
    "product": "이 상품 평균",
    "brand_user": "작성자의 모든 리뷰 평균"
  },
  "tooltip": {
    "mean": "평균",
    "mode": "가장 많은 평가",
    "mode_detail": "{value}점({type}) {rate}%"
  },
  "keyword_widget_message": {
    "button_html": "<u>키워드별 리뷰 위젯</u>을 사용해보세요",
    "title": "키워드별 리뷰 위젯을 사용해보세요!",
    "message_markdown": "**'키워드별 리뷰 위젯'**은 CREMA AI분석을 통해 주요 키워드별 리뷰를 모아 볼 수 있는 위젯입니다.<br /><br />쇼핑몰 방문 고객이 알고 싶은 내용을 쉽게 상품의 매력과 함께 전달하세요.<br /><br />![](https://assets.cre.ma/m/admin/v2/preview_review_keyword_widget.png)"
  },
  "sentiment_badge": "{value}점 - {type}"
}
</i18n>
<i18n locale="en">
{
  "title": "Analysis by keywords",
  "column": {
    "name": "Keyword",
    "sentiment": "This review",
    "product": "Average for this product",
    "brand_user": "Average of all reviews by author"
  },
  "tooltip": {
    "mean": "Average",
    "mode": "Most rated",
    "mode_detail": "{value}({type}) {rate}%"
  },
  "keyword_widget_message": {
    "button_html": "Try <u>the Keyword Review Widget</u>",
    "title": "Try the Keyword Review Widget!",
    "message_markdown": "The **'Keyword Review Widget'** is a widget that allows you to collect and view reviews by major keywords through CREMA AI analysis.<br /><br />Convey what customers visiting the shopping mall want to know with the attractiveness of the product.<br /><br />![](https://assets.cre.ma/m/admin/v2/preview_review_keyword_widget.png)"
  },
  "sentiment_badge": "{value} - {type}"
}
</i18n>
