<template>
  <iframe
    :src="`https://www.googletagmanager.com/ns.html?id=${gtmKey}`"
    class="TheGoogleTagManager"
    height="0"
    width="0"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TheGoogleTagManager',
  computed: {
    ...mapState(['env']),
    gtmKey() {
      return this.env === 'production' ? 'GTM-WCZBHFF' : 'GTM-ND3RD65';
    },
    optimizeKey() {
      return this.env === 'production' ? 'OPT-KBXVTWT' : 'OPT-TRC3WT6';
    }
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'gtm.js',
      'gtm.start': new Date().getTime()
    });

    this.loadGtm();
    this.loadOptimize();
  },
  methods: {
    loadGtm() {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${this.gtmKey}`;
      document.body.appendChild(script);
    },
    loadOptimize() {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googleoptimize.com/optimize.js?id=${this.optimizeKey}`;
      document.body.appendChild(script);
    }
  }
};
</script>

<style scoped>
.TheGoogleTagManager {
  display: none;
  visibility: hidden;
}
</style>
