import i18n from '@/lib/i18n';
import CreditPackageGrade from '@/enums/CreditPackageGrade';
import PackageType from '@/enums/PackageType';

const getters = {
  packageName: () => pkg => {
    switch (pkg.package_type) {
      case PackageType.SPECIAL:
        return pkg.name;
      case PackageType.REVIEW:
        return i18n.t('payment.review_package_name', [pkg.months]);
      case PackageType.FIT:
        return i18n.t('payment.fit_package_name', [pkg.months]);
      case PackageType.TARGET: {
        return pkg.name;
      }
      case PackageType.CREDIT:
        if (pkg.credit_package_grade === CreditPackageGrade.CASH)
          return i18n.t('payment.credit_package_name', [pkg.paid_credit]);
        else
          return i18n.t('payment.credit_package_name_legacy_billing_plan', [
            pkg.paid_credit
          ]);
      case PackageType.REVIEW_SMS:
        return i18n.t('payment.review_sms_package_name', [pkg.price]);
      case PackageType.SMARTSTORE_STARTER: {
        return i18n.t('payment.smartstore_starter_package_name', pkg);
      }
      case PackageType.SMARTSTORE_DAILY: {
        return i18n.t('payment.smartstore_daily_package_name', pkg);
      }
      case PackageType.CAFE24_FEE: {
        if (pkg.original_price_percentage === pkg.final_price_percentage)
          return i18n.t('payment.cafe24_fee_same', [
            pkg.final_price_percentage,
            pkg.parent_package_name
          ]);
        else
          return i18n.t('payment.cafe24_fee_different', [
            pkg.original_price_percentage,
            pkg.final_price_percentage,
            pkg.parent_package_name
          ]);
      }
      default:
        return '';
    }
  },
  packageNameSpecialOfferPostfix: (_state, { packageName }) => pkg => {
    let name = packageName(pkg);
    if (pkg.is_special_offer) {
      name += i18n.t('payment.special_offer_postfix');
    }
    return name;
  },
  packageShortName: (_state, { packageName }) => pkg => {
    switch (pkg.package_type) {
      case PackageType.TARGET:
        return i18n.t('payment.target_package_short_name', [pkg.months]);
      case PackageType.CREDIT:
        return i18n.t('payment.credit_package_short_name', [pkg.paid_credit]);
    }
    return packageName(pkg);
  }
};

export default { namespaced: true, getters };
