<template>
  <AppAjaxContent is-loading />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/lib/api';

export default {
  name: 'TheExpirePasswordRecoveryToken',
  computed: {
    ...mapState('session', ['currentUser'])
  },
  created() {
    const { email, token } = this.$route.query;
    api
      .delete('/user/password', { params: { email, token }, silent: true })
      .then(() => {
        alert(this.$t('expired'));
        this.redirectToHome();
      });
  },
  methods: {
    ...mapActions('session', ['redirectToHome'])
  }
};
</script>

<i18n locale="ko">
{
  "expired": "비밀번호 재설정 링크가 비활성화되었습니다."
}
</i18n>
