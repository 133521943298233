var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'AppRadioLabel',
    ("AppRadioLabel--" + _vm.position),
    {
      'AppRadioLabel--disabled': _vm.disabled,
      'AppRadioLabel--checked': _vm.checked
    },
    _vm.inputStyle ? ("AppRadioLabel--" + _vm.inputStyle) : null
  ]},[_c('label',{staticClass:"AppRadioLabel__main-item",on:{"click":_vm.selectSubItem}},[(_vm.position === 'left')?_c('div',{staticClass:"AppRadioLabel__input"},[_vm._t("default")],2):_vm._e(),(_vm.label || _vm.$scopedSlots.label)?_c('div',{ref:"label",staticClass:"AppRadioLabel__label"},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label))]},{"value":_vm.value,"checked":_vm.checked,"label":_vm.label})],2):_vm._e(),(_vm.tooltip)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"AppRadioLabel__icon"},[_c('AppSvgIcon',{attrs:{"name":"icon-info-tooltip"}})],1):_vm._e(),(_vm.position === 'right')?_c('div',{staticClass:"AppRadioLabel__input"},[_vm._t("default")],2):_vm._e()]),(_vm.$scopedSlots['sub-item'])?_c('div',{class:[
      'AppRadioLabel__sub-item',
      { 'AppRadioLabel__sub-item--disabled': !_vm.checked }
    ]},[_vm._t("sub-item",null,{"value":_vm.value,"checked":_vm.checked})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }