<template>
  <div class="AppCheckboxDropdownGrouped">
    <ul
      v-for="group in groups"
      :key="group"
      class="AppCheckboxDropdownGrouped__group"
    >
      <li class="AppCheckboxDropdownGrouped__item">
        <span>{{ group }}</span>
      </li>
      <li
        v-for="item in items.filter(item => item.group === group)"
        :key="item.id"
        class="AppCheckboxDropdownGrouped__item"
      >
        <AppCheckbox
          :disabled="item.disabled"
          :checked="item.checked"
          :label="item.label"
          @change="$emit('update', { id: item.id, checked: $event })"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'AppCheckboxDropdownGrouped',
  props: { items: { type: Array, required: true } },
  computed: {
    groups() {
      return _.uniq(this.items.map(item => item.group));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppCheckboxDropdownGrouped {
  padding: 15px;
  max-height: 452px;
  overflow-y: scroll;
}

.AppCheckboxDropdownGrouped__group {
  min-width: 160px;

  & + & {
    margin-top: 16px;
  }
}

.AppCheckboxDropdownGrouped__item {
  padding-left: 4px;
  height: 20px;
  white-space: nowrap;

  & + & {
    margin-top: 10px;
  }

  &:nth-child(1) {
    @include text-caption;
    padding-left: 0;
  }

  &:nth-child(2) {
    margin-top: 12px;
  }
}
</style>
