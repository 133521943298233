<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <template #default>
      <AppForm
        id="target_admin_settings"
        v-bind="formProps"
        submit-button
        v-on="formEvents"
      >
        <template #group="{ id }">
          <template v-if="id === 'dispatch_now'">
            <div class="AppForm__group-field">
              <AppSelectFilterable
                v-model="selectedCampaignId"
                :options="campaignOptions"
                :placeholder="$t('form.dispatch_now.placeholder')"
                width="420px"
              />
              <AppButton
                class="TargetAdminSettingsMisc__dispatch-now-button"
                :label="$t('form.dispatch_now.label')"
                :disabled="!selectedCampaignId"
                button-style="blue"
                @click="dispatchNow"
              />
            </div>
          </template>
        </template>
      </AppForm>
    </template>
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import TargetContentView from '@/mixins/TargetContentView';
import HttpStatus from '@/enums/HttpStatus';
import TargetNotEnoughCreditAction from '@/enums/TargetNotEnoughCreditAction';
import TargetDisplayPriceInfo from '@/enums/TargetDisplayPriceInfo';

export default {
  name: 'TargetAdminSettingsMisc',
  mixins: [FormView, TargetContentView],
  data: () => ({
    isLoading: true,
    campaigns: [],
    selectedCampaignId: null,
    calculating_product_tag_rank_daily: true
  }),
  computed: {
    campaignOptions() {
      return this.campaigns.map(campaign => ({
        label: campaign.full_name,
        value: campaign.id
      }));
    },
    groupIds() {
      return [
        'dispatch_now',
        'not_enough_credit_action',
        'display_price_info',
        'calculate_product_tag_rank_daily'
      ];
    },
    groupConfigs() {
      return {
        display_price_info: {
          type: 'select_radio',
          options: TargetDisplayPriceInfo.options(),
          groupTooltip: this.$t('display_price_info.tooltip')
        },
        not_enough_credit_action: {
          label: this.$t(
            'settings.target_admin_settings.groups.not_enough_credit_action'
          ),
          type: 'select_radio',
          options: TargetNotEnoughCreditAction.options()
        },
        calculate_product_tag_rank_daily: {
          groupTooltip: this.$t('calculate_product_tag_rank_daily.tooltip'),
          groupDescription: this.$t(
            'calculate_product_tag_rank_daily.group_description_html'
          ),
          type: 'button',
          value: this.$t('calculate_product_tag_rank_daily.button'),
          clickHandler: this.calculateProductTagRankDaily,
          disabled: this.calculating_product_tag_rank_daily
        }
      };
    },
    formSections() {
      return [
        {
          groups: this.groupIds.map(id => ({
            id,
            ...this.groupConfigs[id]
          }))
        }
      ];
    }
  },
  mounted() {
    api
      .get('/target/admin_settings/misc')
      .then(({ data }) => {
        this.orgFormObject = data.settings;
        this.campaigns = data.campaigns;
        this.calculating_product_tag_rank_daily =
          data.calculating_product_tag_rank_daily;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/target/admin_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => (this.orgFormObject = this.formObject))
        .finally(() => (this.isSubmitting = false));
    },
    dispatchNow() {
      const selectedCampaignName = this.campaignOptions.find(
        c => c.value === this.selectedCampaignId
      ).label;

      api
        .post(
          `/target/campaigns/${this.selectedCampaignId}/dispatch_now`,
          {},
          {
            successMessage: this.$t('dispatch_now_success', [
              selectedCampaignName
            ]),
            silent: true
          }
        )
        .catch(error => {
          if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            const error_type = error.response.data.errors[0];
            if (error_type) alert(this.$t(`error.${error_type}`));
          } else error.errorHandler();
        });
    },
    calculateProductTagRankDaily() {
      api
        .post(
          '/target/admin_settings/calculate_product_tag_rank_daily',
          {},
          {
            successMessage: this.$t('calculate_product_tag_rank_daily.toast')
          }
        )
        .then(() => {
          this.calculating_product_tag_rank_daily = true;
        });
    }
  }
};
</script>

<style scoped>
.TargetAdminSettingsMisc__dispatch-now-button {
  margin-left: 8px;
}
</style>

<i18n locale="ko">
{
  "form": {
    "dispatch_now": {
      "label": "즉시 발송",
      "placeholder": "즉시 발송할 캠페인을 선택해주세요."
    }
  },
  "error": {
    "disabled": "발송이 일시 정지 상태이므로 즉시 발송할 수 없습니다. '발송 관리'에서 발송 일시 정지를 해제한 뒤 재시도해주세요.",
    "not_enough_balance": "보유 포인트 및 캐시가 부족하여 즉시 발송할 수 없습니다."
  },
  "dispatch_now_success": "'{0}' 즉시 발송을 시작합니다.",
  "not_enough_credit_action": {
    "stop": "보유 크레딧에 맞춰 발송",
    "send": "마이너스 크레딧일때도 발송"
  },
  "display_price_info": {
    "tooltip": "메시지 발송 캠페인에만 영향을 주는 설정입니다."
  },
  "calculate_product_tag_rank_daily": {
    "tooltip": "상품 추천 위젯의 [만족도가 높은 상품 추천] 항목과 [잠재력이 높은 상품 추천] 항목의 데이터 계산을 위한 스크립트 실행",
    "group_description_html": "라이브 서버의 해당 정보가 테스트 서버로 동기화되지 않으므로, [AI 리뷰 분석] 데이터를 활용한 상품 추천 항목을 검수하기 위해 스크립트를 수동 실행합니다.<br />실행 시 최근 150일간 작성된 리뷰 데이터를 기준으로 계산합니다.",
    "button": "스크립트 실행",
    "toast": "스크립트 실행을 완료했습니다."
  }
}
</i18n>
