<template>
  <AppModalDefault :title="$t('title')" :sub-title="subTitle" @close="close">
    <template #body>
      <AppAjaxContent :is-loading="isLoading || isSubmitting">
        <template #default>
          <div>
            <AppContainer>
              <div class="ReviewReviewDialog__product">
                <AppImage
                  :src="review.product.image_url"
                  background
                  class="ReviewReviewDialog__product-image"
                />
                <div class="ReviewReviewDialog__product-right">
                  <div class="ReviewReviewDialog__product-name">
                    {{ review.product.name }}
                  </div>
                  <div class="ReviewReviewDialog__product-price">
                    {{ review.product.final_price | formatCurrency }}
                  </div>
                  <div class="ReviewReviewDialog__product-right-bottom">
                    <div class="ReviewReviewDialog__product-buttons">
                      <AppProductSelectButton
                        :label="$t('product.change_product_button')"
                        :title="$t('product.change_product_button')"
                        :default-filters="[`exclude_id:${review.product.id}`]"
                        @select="changeProduct"
                      />
                      <AppProductSelectButton
                        :label="$t('product.copy_review_button')"
                        :title="$t('product.copy_review_button')"
                        :default-filters="[`exclude_id:${review.product.id}`]"
                        @select="copyReview"
                      />
                    </div>
                    <div
                      v-if="showReviewAnalysis"
                      class="ReviewReviewDialog__nlp_review_analysis"
                    >
                      <ul>
                        <li class="ReviewReviewDialog__crema_ai_mark">
                          {{ $t('app.crema_ai_review') }}
                        </li>
                        <li>
                          <AppBadge
                            :badge-style="formatBadgeStyle(reviewSentimentType)"
                            :label="reviewAnalysisBadge.label"
                            :tooltip="reviewAnalysisBadge.tooltip"
                          />
                          <AppButton
                            :label="$t('nlp_review_analysis.view_detail')"
                            @click="focusOnNlpAnalysis"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </AppContainer>
            <AppHeading :label="$t('info.title')">
              <template #controls>
                <AppButton
                  :label="$t('info.edit_author_grades_button')"
                  @click="editAuthorGradesClicked"
                />
                <AppButton
                  :label="$t('info.edit_info_button')"
                  @click="editInfoClicked"
                />
              </template>
            </AppHeading>
            <AppContainer>
              <AppDataList :data="infoData">
                <template #value="{type, value}">
                  <template v-if="type == 'spam_report'">
                    {{ value
                    }}<button
                      v-if="review.spam_reports_count > 0"
                      :class="[
                        'ReviewReviewDialog__review_spam_reports_history',
                        'ReviewReviewDialog__spam_reports_history'
                      ]"
                      @click="
                        openSpamReportsHistoryClicked(review.id, 'Review')
                      "
                    >
                      {{ $t('history') }}
                    </button>
                  </template>
                </template>
              </AppDataList>
            </AppContainer>
            <AppHeading :label="$t('medium.title')">
              <template #controls>
                <AppButton
                  :label="
                    $t(
                      review.videos.length || review.images.length
                        ? 'medium.edit_media_button'
                        : 'medium.add_media_button'
                    )
                  "
                  @click="editMediaClicked"
                />
              </template>
            </AppHeading>
            <AppContainer>
              <ul v-if="review.videos.length || review.images.length">
                <li
                  v-for="(video, i) in review.videos"
                  :key="`video${i}`"
                  class="ReviewReviewDialog__video-item"
                >
                  <video
                    class="ReviewReviewDialog__video"
                    :src="video.url"
                    playsinline
                    controls
                  />
                </li>
                <li
                  v-for="(image, i) in review.images"
                  :key="`image${i}`"
                  class="ReviewReviewDialog__image-item"
                >
                  <AppImage :src="image.url" />
                </li>
              </ul>
              <div v-else class="ReviewReviewDialog__no-data">
                {{ $t('medium.no_medium') }}
              </div>
            </AppContainer>
            <AppHeading :label="$t('message.title')">
              <template #controls>
                <AppButton
                  :label="$t('message.edit_message_button')"
                  @click="editMessageClicked"
                />
              </template>
            </AppHeading>
            <AppContainer>
              <div>
                <pre>{{ review.message }}</pre>
              </div>
              <div class="ReviewReviewDialog__message-length">
                {{
                  $t('message.message_length', {
                    message_length: review.message_length,
                    valid_message_length: review.valid_message_length
                  })
                }}
              </div>
            </AppContainer>
            <template v-if="showSentimentAnalysis || showReviewAnalysis">
              <AppHeading ref="nlpAnalysis">
                <span class="ReviewReviewDialog__nlp-analysis-title">{{
                  $t('nlp_analysis.title')
                }}</span>
                <AppBadge
                  badge-style="red"
                  badget-size="small"
                  :label="$t('app.crema_ai')"
                />
                <template #controls>
                  <AppButton
                    :label="$t('nlp_analysis.edit_analysis_button')"
                    :tooltip="
                      isAnalyzing
                        ? $t(
                            'nlp_analysis.edit_analysis_button_disabled_tooltip'
                          )
                        : $t('nlp_analysis.edit_analysis_button_tooltip')
                    "
                    :disabled="isAnalyzing"
                    @click="editAnalysisClicked"
                  />
                </template>
              </AppHeading>
              <AppContainer>
                <ReviewSentimentAnalysisSection
                  v-if="sentimentAnalysisReport"
                  v-bind="{ sentimentAnalysisReport, reviewIndex }"
                />
                <ReviewReviewAnalysisSection
                  v-if="reviewAnalysisReports"
                  v-bind="{ reviewAnalysisReports, reviewIndex }"
                />
                <div
                  v-else-if="showReviewAnalysis"
                  class="ReviewReviewDialog__no-data"
                >
                  {{
                    isAnalyzed
                      ? $t('nlp_analysis.no_reports')
                      : $t('nlp_analysis.analyzing')
                  }}
                </div>
              </AppContainer>
            </template>
            <template v-if="isSocialReview">
              <AppHeading :label="$t('social.title')">
                <template #controls>
                  <AppButton
                    type="external_link"
                    :to="review.review_source_url"
                    :label="$t('social.open_social_review_button')"
                  />
                </template>
              </AppHeading>
              <AppContainer>
                <pre>{{ review.review_source_message }}</pre>
              </AppContainer>
            </template>
            <AppHeading :label="$t('comment.title')">
              <template v-if="canManageComment" #controls>
                <AppButton
                  :label="
                    $t('settings.review_settings_manage.groups.comment_presets')
                  "
                  @click="openDialog('ReviewSettingsCommentPresetDialog')"
                />
                <AppButton
                  :label="$t('comment.add_comment_button')"
                  @click="addCommentClicked"
                />
              </template>
            </AppHeading>
            <AppContainer>
              <ul v-if="comments.length">
                <li
                  v-for="(comment, i) in comments"
                  :key="`comment${i}`"
                  class="ReviewReviewDialog__comment"
                >
                  <div ref="commentMessages">
                    <pre>{{ comment.message }}</pre>
                  </div>
                  <div class="ReviewReviewDialog__comment-info">
                    <div class="ReviewReviewDialog__comment-author">
                      {{ comment | formatUser }}
                    </div>
                    <div
                      v-if="reviewSettings.show_spam_report_button"
                      class="ReviewReviewDialog__comment-spam-report"
                    >
                      {{ `| ${$t('comments.spam_report_text', comment)}`
                      }}<button
                        v-if="comment.spam_reports_count > 0"
                        :class="[
                          'ReviewReviewDialog__comment_spam_reports_history',
                          'ReviewReviewDialog__spam_reports_history'
                        ]"
                        @click="
                          openSpamReportsHistoryClicked(comment.id, 'Comment')
                        "
                      >
                        {{ $t('history') }}
                      </button>
                    </div>
                    <div class="ReviewReviewDialog__comment-right">
                      <span class="ReviewReviewDialog__comment-date">
                        {{ comment.created_at | formatDateTime }}
                      </span>
                      <template v-if="canManageComment">
                        <AppButton
                          :label="$t('app.edit')"
                          size="small"
                          @click="editCommentClicked(comment)"
                        />
                        <AppButton
                          :label="
                            comment.isDeleting
                              ? $t('app.deleting')
                              : $t('app.delete')
                          "
                          :disabled="comment.isDeleting"
                          size="small"
                          button-style="red-outline"
                          @click="deleteCommentClicked(comment)"
                        />
                      </template>
                    </div>
                  </div>
                </li>
              </ul>
              <div v-else class="ReviewReviewDialog__no-data">
                {{ $t('comment.no_comment') }}
              </div>
            </AppContainer>
          </div>
        </template>
      </AppAjaxContent>
    </template>
    <template v-if="!isManagerReview" #foot>
      <AppButton
        v-if="isMovableToQna"
        :label="$t('foot.move_to_qna_button')"
        :tooltip="$t('foot.move_to_qna_button_tooltip')"
        @click="moveToQnaClicked"
      />
      <template v-if="!isLoading">
        <ReviewReviewMileageButton :review="review" no-tooltip />
        <ReviewReviewManageButton :review="review" no-tooltip @delete="close" />
      </template>
      <AppLoadingScreen :is-loading="isLoading || isSubmitting" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import _ from 'lodash';
import api from '@/lib/api';
import { REVIEW_PERFECT_SCORE } from '@/constants/review';
import AuthorGrade from '@/enums/AuthorGrade';
import ReviewAnalysisStatus from '@/enums/ReviewAnalysisStatus';
import ReviewSentimentType from '@/enums/ReviewSentimentType';
import ReviewSource from '@/enums/ReviewSource';
import SocialMediaType from '@/enums/SocialMediaType';
import HttpStatus from '@/enums/HttpStatus';

import DialogView from '@/mixins/DialogView';
import Focusable from '@/mixins/Focusable';
import Scrollable from '@/mixins/Scrollable';
import ReviewSentimentTypeHelper from '@/mixins/ReviewSentimentTypeHelper';

import ReviewReviewAnalysisSection from './ReviewReviewAnalysisSection';
import ReviewReviewMileageButton from './ReviewReviewMileageButton';
import ReviewReviewManageButton from './ReviewReviewManageButton';

export default {
  name: 'ReviewReviewDialog',
  components: {
    ReviewReviewAnalysisSection,
    ReviewReviewMileageButton,
    ReviewReviewManageButton
  },
  mixins: [DialogView, Focusable, Scrollable, ReviewSentimentTypeHelper],
  props: {
    reviewId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      focusReviewAnalsysis: false,
      reviewAnalysisReports: null,
      sentimentAnalysisReport: null,
      reviewIndex: null,
      reviewOptions: [],
      reviewOptionTypes: [],
      reviewSentimentType: null,
      comments: [],
      brandMovableToQna: false,
      isLoading: true,
      isSubmitting: false
    };
  },
  computed: {
    ...mapState('review', ['reviews']),
    ...mapState('session', ['reviewSettings']),
    ...mapGetters('session', [
      'canManageComment',
      'isUsingNlp',
      'reviewTagTypeNameMap'
    ]),
    ...mapGetters(['isSuperAdminAccessible']),
    review() {
      return this.reviews.items[this.reviewIndex];
    },
    reviewAnalysisBadge() {
      const label = this.isAnalyzed
        ? this.$t('nlp_review_analysis.analyzed', {
            type: ReviewSentimentType.t(this.reviewSentimentType)
          })
        : this.$t('nlp_review_analysis.analyzable');
      let badge = { label };

      const nlpMessages = [];
      if (
        this.showSentimentAnalysis &&
        ReviewSentimentType.hasValue(this.review.sentiment_type)
      ) {
        nlpMessages.push(
          this.$t('nlp_review_analysis.tooltip.sentiment_result', [
            ReviewSentimentType.t(this.review.sentiment_type)
          ])
        );
      }

      if (this.showReviewAnalysis && this.review.review_tags.length) {
        nlpMessages.push(
          [
            this.$t('nlp_review_analysis.tooltip.review_title'),
            ...this.review.review_tags.map(
              t =>
                `${
                  this.reviewTagTypeNameMap[t.review_tag_type_id]
                }: ${ReviewSentimentType.t(t.sentiment)}`
            )
          ].join('\n- ')
        );
      }

      if (nlpMessages.length > 0) {
        badge = {
          ...badge,
          tooltip: {
            message: nlpMessages.join('\n\n'),
            textAlign: 'left',
            info: this.$t('app.crema_ai_review')
          }
        };
      }

      return badge;
    },
    showSentimentAnalysis() {
      return (
        this.reviewSettings.use_nlp_sentiment &&
        this.review.sentiment_analyzed !== undefined
      );
    },
    showReviewAnalysis() {
      return (
        this.isUsingNlp &&
        this.review.analysis_status !== ReviewAnalysisStatus.NOT_ANALYZE
      );
    },
    subTitle() {
      return this.$t('sub_title', [this.formatUser(this.review)]);
    },
    isAnalyzed() {
      return (
        this.isUsingNlp &&
        this.review.analysis_status === ReviewAnalysisStatus.ANALYZED
      );
    },
    isAnalyzing() {
      return (
        (this.isUsingNlp &&
          this.review.analysis_status === ReviewAnalysisStatus.ANALYZING) ||
        (this.reviewSettings.use_nlp_sentiment &&
          this.review.sentiment_analyzed !== true)
      );
    },
    isSocialReview() {
      return this.review.social_media_type !== SocialMediaType.NONE;
    },
    isManagerReview() {
      return this.review?.review_source === ReviewSource.MANAGER;
    },
    infoData() {
      return [
        { label: this.$t('review.user_name'), value: this.review.user_name },
        {
          label: this.$t('review.user_username'),
          value: this.review.user_username
        },
        {
          label: this.$t('review.review_user_grade'),
          value: this.review.brand_user_grade_name
        },
        this.review.brand_user
          ? {
              label: this.$t('review.brand_user_user_grade'),
              value: this.review.brand_user.brand_user_grade_name,
              superAdmin: true
            }
          : {},
        {
          label: this.$t('review.author_grade'),
          value: AuthorGrade.t(this.review.author_grade)
        },
        {
          label: this.$t('review.author_type'),
          value: this.review.author_type_name
        },
        {
          label: this.$t('review.posted_at'),
          value: this.review.created_at,
          type: 'datetime'
        },
        {
          label: this.$t('review.score'),
          value: this.review.score
            ? `${this.review.score}/${REVIEW_PERFECT_SCORE}`
            : this.$t('without_score')
        },
        {
          label: this.$t('review.likes_score'),
          value: this.reviewSettings.use_likes_count
            ? this.$t(
                this.reviewSettings.use_unlikes_count
                  ? 'review.likes_score_text'
                  : 'review.likes_score_short_text',
                this.review
              )
            : null
        },
        this.reviewSettings.show_spam_report_button
          ? {
              label: this.$t('review.spam_report'),
              type: 'spam_report',
              value: this.$t('review.spam_report_text', this.review)
            }
          : {},
        ...this.reviewOptions.map(option => ({
          label: option.name,
          value: option.value
            ? option.unit
              ? `${option.value} ${option.unit}`
              : option.value
            : '-'
        })),
        {
          label: this.$t('review.product_options'),
          value:
            !_.isEmpty(this.review.product_options) &&
            _.map(this.review.product_options, (v, k) => `${k}: ${v}`).join(
              ', '
            )
        }
      ].filter(e => e.value);
    },
    isMovableToQna() {
      return (
        this.brandMovableToQna &&
        !!this.review.brand_user &&
        (this.review.display || this.review.force_display) &&
        this.review.product_visible
      );
    }
  },
  created() {
    this.reviewIndex = this.reviews.items.findIndex(
      r => r.id === this.reviewId
    );

    api
      .get(`/review/reviews/${this.reviewId}`)
      .then(({ data }) => {
        this.brandMovableToQna = data.brand_movable_to_qna;
        this.comments = data.comments;
        this.reviewOptionTypes = data.review_option_types;
        this.setReviewOptions(data.review);
        this.reviewSentimentType = data.review.review_sentiment_type;
        this.sentimentAnalysisReport =
          data.review_messages_sentiment_analysis_reports;
        this.reviewAnalysisReports = data.review_analysis_reports;
      })
      .catch(error => {
        this.close();
        throw error;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('review', ['CREATE_REVIEW', 'UPDATE_REVIEW']),
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('review', ['fetchCurrentPageReviews']),
    setReviewOptions({ review_options }) {
      this.reviewOptions = this.reviewOptionTypes.map(
        ({ type_id, option_values_map, ...optionType }) => {
          const option = review_options.find(o => o.type_id === type_id) || {
            type_id,
            value: null
          };
          const value = option_values_map
            ? option_values_map[option.value]
            : option.value;
          return { ...optionType, ...option, value };
        }
      );
    },
    changeProduct(product) {
      this.isSubmitting = true;
      api
        .patch(
          `/review/reviews/${this.reviewId}/change_product`,
          { product_id: product.id },
          { successMessage: this.$t('product.change_product_success') }
        )
        .then(response => {
          if (response.status === HttpStatus.OK) this.fetchCurrentPageReviews();
        })
        .finally(() => (this.isSubmitting = false));
    },
    copyReview(product) {
      this.isSubmitting = true;
      api
        .patch(
          `/review/reviews/${this.reviewId}/copy`,
          { product_id: product.id },
          {
            successMessage: this.$t('product.copy_review_success', {
              product_name: product.name
            })
          }
        )
        .then(({ data }) => this.CREATE_REVIEW(data.review))
        .finally(() => (this.isSubmitting = false));
    },
    focusOnNlpAnalysis() {
      this.$nextTick(() => {
        this.scrollToElement(this.$refs.nlpAnalysis.$el);
      });
    },
    editAuthorGradesClicked() {
      this.openDialog('ReviewAuthorTypesDialog');
    },
    openReviewEditDialog({ focusGroupId }) {
      this.openDialog([
        'ReviewReviewFormDialog',
        { reviewId: this.review.id, subTitle: this.subTitle, focusGroupId }
      ]).then(eventBus =>
        eventBus.$on('save', review => this.setReviewOptions(review))
      );
    },
    editInfoClicked() {
      this.openReviewEditDialog({ focusGroupId: 'user_name' });
    },
    editMediaClicked() {
      this.openReviewEditDialog({
        focusGroupId: this.review.videos.length ? 'videos' : 'images'
      });
    },
    editImagesClicked() {
      this.openReviewEditDialog({ focusGroupId: 'images' });
    },
    editMessageClicked() {
      this.openReviewEditDialog({ focusGroupId: 'message' });
    },
    editAnalysisClicked() {
      this.openDialog([
        'ReviewReviewEditAnalysisDialog',
        { reviewIndex: this.reviewIndex }
      ]).then(eventBus =>
        eventBus.$on('save', data => {
          this.sentimentAnalysisReport = data.sentimentAnalysisReport;
          this.reviewAnalysisReports = data.reviewAnalysisReports;
          this.reviewSentimentType = data.reviewSentimentType;
        })
      );
    },
    openSocialReviewClicked() {
      // eslint-disable-next-line no-console
      console.log('openSocialReviewClicked');
    },
    openSpamReportsHistoryClicked(spamReportableId, spamReportableType) {
      this.openDialog([
        'ReviewSpamReportsHistoryDialog',
        { spamReportableId, spamReportableType }
      ]);
    },
    addCommentClicked() {
      this.openDialog([
        'ReviewReviewEditCommentDialog',
        { review: this.review }
      ]).then(eventBus =>
        eventBus.$on('save', comment => {
          this.comments.push(comment);
          this.$nextTick(() =>
            this.focusOnElement(_.last(this.$refs.commentMessages))
          );
        })
      );
    },
    editCommentClicked(comment) {
      this.openDialog([
        'ReviewReviewEditCommentDialog',
        { review: this.review, orgComment: comment }
      ]).then(eventBus => {
        eventBus.$on('save', comment => {
          const index = this.comments.findIndex(c => c.id === comment.id);
          this.$set(this.comments, index, comment);
        });
      });
    },
    deleteCommentClicked(comment) {
      if (
        !confirm(
          this.$t(
            comment.author_grade === 'operator'
              ? 'comment.delete_confirm_operator'
              : 'comment.delete_confirm_user'
          )
        )
      )
        return;

      this.$set(comment, 'isDeleting', true);
      api
        .delete(`/review/comments/${comment.id}`, {
          successMessage: this.$t('comment.comment_deleted')
        })
        .then(({ data }) => {
          const index = this.comments.findIndex(c => c.id === comment.id);
          this.$delete(this.comments, index);

          this.UPDATE_REVIEW(data.review);
        })
        .finally(() => this.$delete(comment, 'isDeleting'));
    },
    moveToQnaClicked() {
      if (!confirm(this.$t('foot.move_to_qna_confirm'))) return;

      this.isSubmitting = true;
      api
        .patch(
          `/review/reviews/${this.reviewId}/move_to_qna`,
          {},
          {
            silent: true,
            successMessage: this.$t('foot.move_to_qna_success')
          }
        )
        .then(response => {
          if (response.status === HttpStatus.OK) this.fetchCurrentPageReviews();
        })
        .catch(error => {
          const error_message = error.response.data.move_to_qna_error;
          if (error_message)
            alert(
              this.$t('foot.move_to_qna_error', {
                error_message,
                customer_support: this.$t('app.customer_support')
              })
            );
          else error.errorHandler();
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_breakpoints.scss';
@import '@/scss/mixins/_clearfix.scss';
@import '@/scss/mixins/_texts.scss';

.ReviewReviewDialog__product {
  position: relative;
}

$product-image-size: 100px;
.ReviewReviewDialog__product-image {
  width: $product-image-size;
  height: $product-image-size;
  position: absolute;
  top: 0;
  left: 0;
}

.ReviewReviewDialog__product-right {
  position: relative;
  margin-left: $product-image-size + 16px;
  height: 100%;
}

.ReviewReviewDialog__product-name {
  @include text-label;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReviewReviewDialog__product-price {
  font-size: 13px;
  line-height: 13px;
  color: $color-title-text-light;
}

.ReviewReviewDialog__product-right-bottom {
  display: inline-block;
  width: 100%;
  padding-top: 5px;
}

.ReviewReviewDialog__product-buttons {
  float: left;
  margin-top: 26px;
}

.ReviewReviewDialog__nlp_review_analysis {
  float: right;

  .AppBadge {
    vertical-align: middle;
  }

  .AppButton {
    padding-left: 8px;
  }

  @include media-breakpoint-each(mobile) {
    display: block;
    margin-top: 24px;
  }
}

.ReviewReviewDialog__crema_ai_mark {
  @include text-caption-dark;
  text-align: right;
  padding: 4px;
}

.ReviewReviewDialog__video-item,
.ReviewReviewDialog__image-item {
  line-height: 0;
  text-align: center;

  & + & {
    margin-top: 16px;
  }
}

.ReviewReviewDialog__video {
  width: 100%;
}

.ReviewReviewDialog__no-data {
  text-align: center;
}

.ReviewReviewDialog__message-length {
  @include text-caption;
  margin-top: 8px;
}

.ReviewReviewDialog__nlp-analysis-title {
  @include text-sub-title;
  line-height: 32px;
  padding-right: 4px;
}

.ReviewReviewDialog__comment {
  & + & {
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid $color-layout-section;
  }
}

.ReviewReviewDialog__comment-info {
  @include text-caption;
  @include clearfix;
  margin-top: 8px;
}

.ReviewReviewDialog__comment-author {
  float: left;
}

.ReviewReviewDialog__comment-spam-report {
  float: left;
  margin-left: 4px;
}

.AppDataItem {
  font-size: 0;
}

.ReviewReviewDialog__spam_reports_history {
  text-decoration: underline;
  color: #9e9ea0;
  margin-left: 8px;
}

.ReviewReviewDialog__review_spam_reports_history {
  font-size: 12px;
  line-height: 18px;
}

.ReviewReviewDialog__comment_spam_reports_history {
  font-size: 10px;
  line-height: 15px;
}

.ReviewReviewDialog__comment-right {
  float: right;
}

.ReviewReviewDialog__comment-date {
  margin-right: 12px;
}
</style>

<i18n locale="ko">
{
  "title": "리뷰 상세",
  "sub_title": "{0}님의 리뷰",
  "product": {
    "change_product_button": "상품 변경",
    "change_product_success": "상품이 변경됐습니다.",
    "copy_review_button": "리뷰 복사",
    "copy_review_success": "해당 리뷰를 [{product_name}]으로 복사 하였습니다.\n실제 반영에는 다소 시간이 소요될 수 있습니다."
  },
  "nlp_review_analysis": {
    "analyzable": "리뷰 분석중",
    "analyzed": "{type} 리뷰",
    "view_detail": "상세보기",
    "tooltip": {
      "sentiment_result": "리뷰 본문 분석 결과 {0} 표현의 리뷰입니다.",
      "review_title": "주요 키워드별 분석결과"
    }
  },
  "info": {
    "title": "리뷰 정보",
    "edit_author_grades_button": "작성자 타입 설정",
    "edit_info_button": "리뷰 정보 수정"
  },
  "medium": {
    "title": "첨부 포토/동영상",
    "edit_media_button": "첨부 포토/동영상 편집",
    "add_media_button": "첨부 포토/동영상 추가",
    "no_medium": "리뷰에 첨부한 포토/동영상이 없습니다."
  },
  "message": {
    "title": "리뷰 본문",
    "edit_message_button": "리뷰 본문 수정",
    "message_length": "글자수: {message_length} (유효 글자수: {valid_message_length})"
  },
  "nlp_analysis": {
    "title": "리뷰 분석",
    "edit_analysis_button": "분석 결과 수정",
    "edit_analysis_button_tooltip": "CREMA AI는 계속 배우면서 발전하고 있습니다.\n잘못된 분석 결과를 수정해 주시면 더 똑똑한 인공지능이 될 것입니다.",
    "edit_analysis_button_disabled_tooltip": "아직 분석중인 항목이 있습니다.\n분석이 완료된 다음 수정할 수 있습니다.",
    "analyzing": "현재 분석이 진행 중 입니다.",
    "no_reports": "분석된 내용이 없습니다."
  },
  "social": {
    "title": "소셜 리뷰 원문",
    "open_social_review_button": "소셜 리뷰 원본 열기"
  },
  "comment": {
    "title": "리뷰 댓글",
    "add_comment_button": "댓글 작성",
    "no_comment": "리뷰에 작성된 댓글이 없습니다.",
    "delete_confirm_operator": "댓글을 정말 삭제하시겠습니까?",
    "delete_confirm_user": "고객이 작성한 댓글입니다. 정말 삭제하시겠습니까?",
    "comment_deleted": "댓글이 삭제되었습니다."
  },
  "foot": {
    "move_to_qna_button": "Q&A 게시판으로 이동",
    "move_to_qna_button_tooltip": "비밀글로 게시되며, 비밀번호는 '1004'로 설정됩니다.",
    "move_to_qna_confirm": "정말로 Q&A 게시판으로 이동하시겠습니까? 이동된 리뷰는 숨김처리됩니다.",
    "move_to_qna_success": "리뷰를 Q&A게시판으로 이동했습니다.",
    "move_to_qna_error": "리뷰를 Q&A게시판으로 이동 중 오류가 발생했습니다.\n\n오류내용: {error_message}\n\n오류 내용을 처리한 뒤에도 문제가 발생하면 아래로 문의부탁드립니다.\n{customer_support}"
  },
  "history": "내역보기",
  "without_score": "별점없음"
}
</i18n>
<i18n locale="en">
{
  "title": "Review details",
  "sub_title": "{0}'s review",
  "product": {
    "change_product_button": "Change product",
    "change_product_success": "Success to change product.",
    "copy_review_button": "Copy review",
    "copy_review_success": "The review has been copied to [{product_name}].\nActual reflection may take some time."
  },
  "nlp_review_analysis": {
    "analyzable": "Analyzing review",
    "analyzed": "{type} review",
    "view_detail": "View details",
    "tooltip": {
      "sentiment_result": "As a result of the analysis of the review, this is a review of {0} expression.",
      "review_title": "Analysis results by keywords"
    }
  },
  "info": {
    "title": "Review info",
    "edit_author_grades_button": "Edit author type",
    "edit_info_button": "Edit review info"
  },
  "medium": {
    "title": "Attached image/video",
    "edit_media_button": "Edit image/video",
    "add_media_button": "Add image/video",
    "no_medium": "No image/video attached."
  },
  "message": {
    "title": "Review message",
    "edit_message_button": "Edit review message",
    "message_length": "Message length: {message_length} (Valid length: {valid_message_length})"
  },
  "nlp_analysis": {
    "title": "Review analysis",
    "edit_analysis_button": "Edit result of analysis",
    "edit_analysis_button_tooltip": "CREMA AI continues to learn and develop.\nIf you correct the incorrect analysis results, it will become a smarter AI.",
    "edit_analysis_button_disabled_tooltip": "There are still items being analyzed.\nYou can fix them after the analysis is complete.",
    "analyzing": "Analysis is currently in progress.",
    "no_reports": "No results"
  },
  "social": {
    "title": "Social media original message",
    "open_social_review_button": "Open original social media review"
  },
  "comment": {
    "title": "Comment",
    "add_comment_button": "Add comment",
    "no_comment": "No comments",
    "delete_confirm_operator": "Are you sure you want to delete the comment?",
    "delete_confirm_user": "This is a customer comment. Are you sure you want to delete?",
    "comment_deleted": "Comment has been deleted."
  },
  "history": "Show history",
  "without_score": "No score"
}
</i18n>
