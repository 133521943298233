<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{
      ...formProps,
      focusGroupId: 'brand_name',
      submitLabel: $t('app.confirmed')
    }"
    v-on="formEvents"
  />
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import BrandTargetStatus from '@/enums/BrandTargetStatus';

export default {
  name: 'TargetStatusUpdateDialog',
  mixins: [DialogFormView],
  computed: {
    ...mapState('session', ['currentBrand']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'brand_name',
              label: this.$t('brand_name'),
              hint: this.$t('description', [this.brandName]),
              type: 'text',
              placeholder: this.brandName,
              validate: [
                'required',
                {
                  rule: v => this.brandName === v,
                  errorMessage: this.$t('errors.wrong_brand_name')
                }
              ]
            }
          ]
        }
      ];
    },
    brandName() {
      return this.currentBrand.name;
    }
  },
  methods: {
    ...mapMutations('session', ['SET_CURRENT_BRAND']),
    submit() {
      this.isSubmitting = true;
      api
        .request({
          url: 'brand_status/operate_target',
          method: 'patch'
        })
        .then(() => {
          this.SET_CURRENT_BRAND({
            ...this.currentBrand,
            target_status: BrandTargetStatus.OPERATING
          });
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "타겟 사용 시작",
  "description": "타겟 테스트를 마무리하고 사용 중으로 변경합니다.\n변경 시점을 기준으로 타겟 사용 기간 차감이 시작되며 결제한 요금제에 따른 포인트가 지급됩니다.\n사용중으로 변경을 원하시면 해당 쇼핑몰인 <b>{0}</b> 을 입력 후 확인을 눌러 주세요.",
  "brand_name": "쇼핑몰 이름",
  "errors": {
    "wrong_brand_name": "입력하신 쇼핑몰 명이 다릅니다. 다시 입력해주세요."
  }
}
</i18n>
