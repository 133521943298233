<template>
  <AppModalForm
    :title="$t('title')"
    width="middle"
    :form-props="{
      ...formProps,
      submitLabel: $t('issue'),
      objectId: 'api_key',
      detectFormDataChange: !!apiKey
    }"
    v-on="formEvents"
    @change-group="formGroupChanged"
  >
    <input type="hidden" name="api_key[name]" :value="apiKeyName" />
    <input
      type="hidden"
      name="api_key[using_blurred_review_images]"
      :value="formObject.using_blurred_review_images"
    />
    <div
      v-for="reviewSource in allowedReviewSources"
      :key="`review_source_input_${reviewSource}`"
    >
      <input
        type="hidden"
        :name="`api_key[${reviewSource}]`"
        :value="formObject[`${reviewSource}`]"
      />
    </div>
    <template #group="{ id }">
      <div v-if="id === 'ability'" class="AppForm__group-field">
        <div v-if="formObject.ability_type !== AbilityType.REVIEW">
          <AppInfoBox>
            <!-- eslint-disable vue/no-v-html -->
            <p
              v-html="
                formObject.ability_type === AbilityType.ALL
                  ? $t('ability_type.info_box')
                  : $t('ability_type.admin_edited_info_box')
              "
            />
            <!-- eslint-enable vue/no-v-html -->
          </AppInfoBox>
          <input
            type="hidden"
            name="api_key[review_api_usage_type]"
            :value="formObject.review_api_usage_type"
          />
        </div>
        <AppSelectRadio
          v-else
          v-model="formObject.review_api_usage_type"
          name="api_key[review_api_usage_type]"
          :options="reviewApiUsageTypeOptions"
          @change="changeReviewApiUsageType"
        >
          <template #sub-item="{ value }">
            <div
              v-if="value === formObject.review_api_usage_type"
              class="AppForm__sub-group"
            >
              <label class="AppForm__sub-group-title">
                {{ $t('ability.review_sources') }}
              </label>
              <div
                v-for="reviewSource in allowedReviewSources"
                :key="`review_source_${reviewSource}`"
                class="TheSettingsDialogApiKeyFormDialog__review-source"
              >
                <AppCheckbox
                  v-model="formObject[`${reviewSource}`]"
                  :label="$t(`ability.${reviewSource}`)"
                  :tooltip="reviewSourceTooltip(reviewSource)"
                  :disabled="!isCustomUsageType"
                  @change="validateField('ability')"
                />
              </div>
              <div class="AppForm__sub-group-item">
                <AppCheckbox
                  v-model="formObject.using_blurred_review_images"
                  :disabled="isThirdPartyUsageType || isBrandUsageType"
                  :label="$t('ability.using_blurred_review_images')"
                />
              </div>
              <div class="AppForm__sub-group-item">
                <AppCheckbox
                  v-model="formObject.api_comment_readable"
                  name="api_key[api_comment_readable]"
                  :label="$t('ability.api_comment_readable')"
                />
              </div>
            </div>
          </template>
        </AppSelectRadio>
      </div>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import AbilityType from '@/enums/AbilityType';
import ReviewApiUsageType from '@/enums/ReviewApiUsageType';

const allowedReviewSources = [
  'crema_review',
  'imported_review',
  'manager_review',
  'synchronized_review',
  'copied_review',
  'etc_review'
];

export default {
  name: 'TheSettingsDialogApiKeyFormDialog',
  mixins: [DialogFormView],
  props: {
    apiKey: { type: Object, default: null }
  },
  data() {
    return {
      orgFormObject: {
        name: null,
        ability_type: AbilityType.REVIEW,
        review_api_usage_type: ReviewApiUsageType.THIRD_PARTY,
        using_blurred_review_images: true,
        api_comment_readable: false,
        crema_review: true,
        imported_review: true,
        manager_review: false,
        synchronized_review: false,
        copied_review: false,
        etc_review: false
      }
    };
  },
  computed: {
    AbilityType() {
      return AbilityType;
    },
    allowedReviewSources() {
      return allowedReviewSources;
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              name: null,
              label: this.$t('name'),
              placeholder: this.$t('name_placeholder'),
              type: 'text',
              maxlength: 255
            },
            {
              id: 'ability_type',
              name: null,
              label: this.$t('ability_type.label'),
              groupDescription: this.$t('ability_type.description'),
              type: 'select_radio',
              options: [
                {
                  label: AbilityType.t(AbilityType.ALL),
                  value: AbilityType.ALL
                },
                {
                  label: AbilityType.t(AbilityType.REVIEW),
                  value: AbilityType.REVIEW
                },
                {
                  label: AbilityType.t(AbilityType.ADMIN_EDITED),
                  value: AbilityType.ADMIN_EDITED,
                  disabled: true,
                  tooltip: this.$t('ability_type.admin_edited_tooltip')
                }
              ],
              superAdmin: true
            },
            {
              id: 'ability',
              label: this.$t('ability.label'),
              groupDescription: this.$t('ability.description'),
              validate: this.isCustomUsageType
                ? [
                    {
                      rule: 'required',
                      value: () => this.formObject.review_api_usage_type
                    },
                    {
                      rule: () => {
                        let checkedReviewSourceCount = allowedReviewSources.filter(
                          reviewSource => !!this.formObject[`${reviewSource}`]
                        ).length;
                        return 0 < checkedReviewSourceCount;
                      },
                      errorMessage: this.$t('validations.required')
                    }
                  ]
                : []
            }
          ]
        }
      ];
    },
    apiKeyName() {
      return this.formObject.name || this.$t('name_placeholder');
    },
    reviewApiUsageTypeOptions() {
      return [
        {
          label: ReviewApiUsageType.t(ReviewApiUsageType.THIRD_PARTY),
          value: ReviewApiUsageType.THIRD_PARTY,
          tooltip: this.$t('ability.third_party_tooltip')
        },
        {
          label: ReviewApiUsageType.t(ReviewApiUsageType.BRAND),
          value: ReviewApiUsageType.BRAND
        },
        {
          label: ReviewApiUsageType.t(ReviewApiUsageType.CUSTOM),
          value: ReviewApiUsageType.CUSTOM
        }
      ];
    },
    isThirdPartyUsageType() {
      return (
        this.formObject.review_api_usage_type === ReviewApiUsageType.THIRD_PARTY
      );
    },
    isBrandUsageType() {
      return this.formObject.review_api_usage_type === ReviewApiUsageType.BRAND;
    },
    isCustomUsageType() {
      return (
        this.formObject.review_api_usage_type === ReviewApiUsageType.CUSTOM
      );
    }
  },
  created() {
    if (this.apiKey) {
      this.orgFormObject = {
        ...this.apiKey,
        ability_type:
          this.apiKey.review_api_usage_type === ReviewApiUsageType.NONE
            ? AbilityType.ALL
            : this.apiKey.review_api_usage_type ===
              ReviewApiUsageType.ADMIN_EDITED
            ? AbilityType.ADMIN_EDITED
            : AbilityType.REVIEW
      };
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      const promise = this.apiKey
        ? this.updateApiKey(formData)
        : this.createApiKey(formData);
      promise
        .then(({ data }) => {
          this.eventBus.$emit('update', data.api_key);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    createApiKey(formData) {
      return api.post('/user/api_key', formData, {
        successMessage: this.$t('app.saved')
      });
    },
    updateApiKey(formData) {
      return api.patch('/user/api_key', formData, {
        successMessage: this.$t('app.saved')
      });
    },
    changeReviewApiUsageType() {
      this.formObject = {
        ...this.formObject,
        using_blurred_review_images: this.isThirdPartyUsageType,
        api_comment_readable: false,
        crema_review: !this.isCustomUsageType,
        imported_review: !this.isCustomUsageType,
        manager_review: this.isBrandUsageType,
        synchronized_review: this.isBrandUsageType,
        copied_review: this.isBrandUsageType,
        etc_review: this.isBrandUsageType
      };
      this.validateField('ability');
    },
    formGroupChanged(group) {
      if (group.id === 'ability_type') this.changeAbilityType();
    },
    changeAbilityType() {
      this.formObject.review_api_usage_type =
        this.formObject.ability_type === AbilityType.ALL
          ? ReviewApiUsageType.NONE
          : this.formObject.ability_type === AbilityType.ADMIN_EDITED
          ? ReviewApiUsageType.ADMIN_EDITED
          : ReviewApiUsageType.THIRD_PARTY;
      this.changeReviewApiUsageType();
    },
    reviewSourceTooltip(reviewSource) {
      return this.$t(`ability.${reviewSource}_tooltip`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheSettingsDialogApiKeyFormDialog__review-source {
  margin-top: 4px;
  margin-left: 22px;

  & + & {
    margin-top: 8px;
  }
}

::v-deep {
  .AppRadioLabel--disabled .AppRadioLabel__icon {
    pointer-events: all;
    color: $color-grey-70;
  }
}
</style>

<i18n locale="ko">
{
  "title": "크리마 API키 발급",
  "name": "이름",
  "name_placeholder": "크리마 API키",
  "ability_type": {
    "label": "최고관리자 설정",
    "description": "모든 권한 부여 시 모든 크리마 데이터의 읽기, 쓰기, 삭제가 가능합니다.",
    "info_box": "⚠️ 이 키는 모든 크리마 정보를 읽기, 쓰기, 삭제할 수 있습니다.<br />무단 사용을 방지하려면 API를 사용할 수 있는 권한을 제한하는 것이 좋습니다.<br />권한 제한을 원하시면 크리마 운영팀에 문의하세요.",
    "admin_edited_info_box": "⚠️ 이 키의 권한은 최고관리자에 의해 수정되었습니다.<br />상세한 권한을 알고 싶다면 크리마 운영팀에 문의하세요.",
    "admin_edited_tooltip": "최고관리자에 의해 권한 수정 시 자동으로 선택됩니다."
  },
  "ability": {
    "label": "권한 설정",
    "description": "CREMA 서버에 등록된 정보를 항목 별로 권한을 설정할 수 있습니다.",
    "third_party_tooltip": "광고, 대행사와 같은 외부 업체에 크리마 리뷰 데이터를 공유할 때 추천하는 설정입니다.",
    "review_sources": "리뷰 데이터 읽기",
    "crema_review": "크리마 리뷰",
    "imported_review": "기존 리뷰",
    "manager_review": "스탭 리뷰",
    "synchronized_review": "연동 리뷰",
    "copied_review": "복사된 리뷰",
    "etc_review": "기타 리뷰",
    "crema_review_tooltip": "크리마 서비스 사용 중 모바일, PC, 문자, 이메일, 미구매 작성리뷰, Fit 리뷰 팝업을 통해 수집한 리뷰",
    "imported_review_tooltip": "동기화를 통해 호스팅사로부터 가져온 리뷰",
    "manager_review_tooltip": "관리자가 직접 작성한 리뷰",
    "synchronized_review_tooltip": "크리마 몰간 리뷰 연동을 이용해 다른 쇼핑몰로부터 가져온 리뷰",
    "copied_review_tooltip": "현재 쇼핑몰에 있는 리뷰의 복사본",
    "etc_review_tooltip": "외부 업체에서 가져온 리뷰",
    "using_blurred_review_images": "포토 리뷰 얼굴 모자이크 처리",
    "api_comment_readable": "(선택) 리뷰 댓글 데이터 읽기"
  },
  "issue": "API키 발급"
}
</i18n>
