<template>
  <div v-if="showable" :class="className">
    <AppCheckbox
      :name="
        `widget[${
          isDevicePc
            ? 'enable_pagination_scroll'
            : 'enable_pagination_scroll_for_mobile'
        }]`
      "
      :checked="
        widget[
          isDevicePc
            ? 'enable_pagination_scroll'
            : 'enable_pagination_scroll_for_mobile'
        ]
      "
      :label="$t('enable_pagination_scroll')"
      :disabled="disabled"
      :tooltip="tooltip"
      @change="
        widget[
          isDevicePc
            ? 'enable_pagination_scroll'
            : 'enable_pagination_scroll_for_mobile'
        ] = $event
      "
    >
      <template v-if="$scopedSlots.label" #label>
        <slot name="label" />
      </template>
      <template #sub-item="enablePaginationScrollSubItem">
        <div class="AppForm__sub-group">
          <div class="AppForm__sub-group-title">
            {{ $t('fixed_top_menu_height') }}
          </div>
          <AppNumberInput
            v-model="
              widget[
                isDevicePc
                  ? 'fixed_top_menu_height'
                  : 'fixed_top_menu_height_for_mobile'
              ]
            "
            :default="0"
            :digits="4"
            :disabled="disabled || !enablePaginationScrollSubItem.checked"
            :name="
              `widget[${
                isDevicePc
                  ? 'fixed_top_menu_height'
                  : 'fixed_top_menu_height_for_mobile'
              }]`
            "
          />
          <div class="AppForm__sub-group-hint">
            {{ $t('fixed_top_menu_height_hint') }}
          </div>
        </div>
      </template>
    </AppCheckbox>
  </div>
</template>

<script>
export default {
  name: 'ReviewSettingsWidgetEnablePaginationScrollCheckbox',
  props: {
    widget: {
      type: Object,
      required: true
    },
    isDevicePc: {
      type: Boolean,
      required: true
    },
    className: {
      type: String,
      default: 'AppForm__sub-group'
    },
    showable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: [String, Object],
      default: null
    }
  }
};
</script>

<i18n locale="ko">
{
  "enable_pagination_scroll": "페이지 이동 시, 최상단으로 자동 스크롤",
  "fixed_top_menu_height": "상단 고정된 메뉴 높이 (px)",
  "fixed_top_menu_height_hint": "홈페이지 상단에 고정한 메뉴가 있다면, 메뉴가 위젯을 가리지 않도록 메뉴의 높이를 입력하세요."
}
</i18n>
