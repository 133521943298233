<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <div class="ChannelFeeds__buttons">
      <AppButton
        :label="$t('button.add_feed')"
        button-style="red"
        @click="openCreateFeedDialog"
      />
      <AppButton :label="$t('button.generate_feeds')" @click="regenerateCsvs" />
    </div>
    <AppResourceTable
      :resources="feeds"
      :columns="columns"
      :rows="rows"
      :no-data="$t('no_data')"
      :custom-buttons="[sortButton]"
      table-id="feeds"
      @paginate="paginate"
    >
      <template #cell="{ row, column, value }">
        <AppBadge
          v-if="column === 'status'"
          :badge-style="
            value === ChannelFeedStatus.DISABLED ? 'red-outline' : 'mint-green'
          "
          :label="ChannelFeedStatus.t(value)"
        />
        <template v-else-if="column === 'last_cohort_sent_at'">
          <ChannelFeedsLastCohortSentAtCell
            :sent-at="value"
            :status="row.status"
          />
        </template>
        <template v-else-if="column === 'updated_at'">
          <ChannelFeedsUpdatedAtCell
            :updated-at="row.updated_at"
            :created-at="row.created_at"
          />
        </template>
        <template v-else-if="column === 'actions'">
          <ChannelFeedManageButton
            :feed="row"
            @change-status="refreshResource"
            @deleted="refreshResource"
            @cloned="refreshResource"
          />
          <AppButton
            :label="$t('app.settings')"
            button-style="mango-outline"
            type="link"
            :to="`/channel/feeds/${row.id}`"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import { mapActions, mapGetters } from 'vuex';
import ResourceView from '@/mixins/ResourceView';
import TargetContentView from '@/mixins/TargetContentView';
import ChannelFeedStatus from '@/enums/ChannelFeedStatus';

export default {
  name: 'ChannelFeeds',
  mixins: [ResourceView, TargetContentView],
  data() {
    return {
      feeds: nullResources,
      isLoading: true,
      sortButton: {
        icon: 'icon-sort',
        controlType: 'select',
        options: ['created_desc', 'updated_desc'].map(value => ({
          label: this.$t(`button.sort.${value}`),
          value
        })),
        value: 'created_desc',
        changeHandler: sortType => {
          this.resourceParams = { ...this.resourceParams, sort_type: sortType };
          this.searchResource();
        }
      }
    };
  },
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    ChannelFeedStatus: () => ChannelFeedStatus,
    columns() {
      return [
        {
          id: 'id',
          label: this.$t('column.id.label'),
          width: '64px'
        },
        {
          id: 'name',
          label: this.$t('column.name.label'),
          align: 'left'
        },
        {
          id: 'last_cohort_sent_at',
          label: this.$t('column.last_cohort_sent_at.label'),
          type: 'datetime',
          width: '200px'
        },
        {
          id: 'status',
          label: this.$t('column.status.label'),
          width: '80px'
        },
        {
          id: 'updated_at',
          label: this.$t('column.updated_at.label'),
          type: 'datetime',
          width: '160px'
        },
        {
          id: 'actions',
          label: this.$t('column.actions.label'),
          align: 'right',
          width: '160px'
        }
      ];
    },
    rows() {
      return this.feeds.items.map(feed => ({
        ...feed,
        created_at: this.formatDateTime(feed.created_at),
        updated_at: this.formatDateTime(feed.updated_at),
        last_cohort_sent_at: this.formatDateTime(feed.last_cohort_sent_at)
      }));
    },
    defaultResourceParams() {
      return {
        page: '1',
        per: '20',
        sort_type: this.sortButton.value
      };
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'confirm']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/channel/feeds', { params })
        .then(({ data }) => {
          this.feeds = data.feeds;
        })
        .finally(() => (this.isLoading = false));
    },
    openCreateFeedDialog() {
      this.openDialog(['ChannelFeedCreateDialog']).then(eventBus =>
        eventBus.$on('created', feedId =>
          this.$router.replace(
            this.pathWithBrandParams(`/channel/feeds/${feedId}`)
          )
        )
      );
    },
    regenerateCsvs() {
      this.confirm({
        title: this.$t('generate_csvs_dialog.title'),
        message: this.$t('generate_csvs_dialog.message')
      }).then(result => {
        if (result) this.requestRegenerateCsvs();
      });
    },
    requestRegenerateCsvs() {
      this.isLoading = true;
      api
        .post('/channel/feeds/regenerate_csvs')
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.ChannelFeeds__buttons {
  margin-bottom: 24px;
}
</style>

<i18n locale="ko">
{
  "column": {
    "id": {
      "label": "피드 ID"
    },
    "name": {
      "label": "광고 피드 이름"
    },
    "last_cohort_sent_at": {
      "label": "최근 피드 생성일"
    },
    "status": {
      "label": "상태"
    },
    "updated_at": {
      "label": "수정일"
    },
    "actions": {
      "label": "관리"
    }
  },
  "no_data": "등록된 광고 피드가 없습니다.",
  "button": {
    "add_feed": "광고 피드 추가",
    "generate_feeds": "모든 광고 피드 재생성",
    "sort": {
      "created_desc": "최근 생성일 순",
      "updated_desc": "최근 수정일 순"
    }
  },
  "generate_csvs_dialog": {
    "title": "모든 광고 피드를 재생성합니다.",
    "message": "발송중 상태인 모든 광고 피드를 재생성합니다.\n광고 피드 재생성은 추가 데이터 동기화 없이\n피드 상품 재계산만 진행합니다."
  }
}
</i18n>
