<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{
      ...formProps,
      objectId: 'feed'
    }"
    hide-head-close-button
    v-on="formEvents"
  />
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';
import NameFormView from '@/mixins/NameFormView';
import api from '@/lib/api';

export default {
  name: 'ChannelFeedCreateDialog',
  mixins: [DialogFormView, NameFormView],
  data: () => ({ isLoading: true }),
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              label: this.$t('form.feed_name.label'),
              type: 'text',
              validate: [
                'required',
                { rule: 'max_length', limit: 30 },
                {
                  rule: v => this.isNameNotDuplicated(v),
                  errorMessage: this.$t('target_errors.duplicated_feed_name')
                }
              ],
              hint: this.$t('form.feed_name.hint')
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get('/channel/feeds/new')
      .then(({ data }) => {
        this.formObject = { ...this.formObject, name: data.default_name };
        this.existingNames = data.created_feed_names;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/channel/feeds', formData)
        .then(({ data }) => {
          this.close(true);
          this.eventBus.$emit('created', data.feed.id);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "광고 피드 추가",
  "form": {
    "feed_name": {
      "label": "광고 피드 이름",
      "hint": "광고피드의 효과적인 관리를 위하여 [매체명] - [캠페인 특성] 형식의 이름을 추천합니다.<br>예) 메타 - 신상품 #1"
    }
  }
}
</i18n>
