import { constructEnum } from '@/lib/enum';

const PurchasePurpose = constructEnum('PurchasePurpose', {
  NONE: 0,
  GIFT: 10,
  EXPERIENCE: 20
});

PurchasePurpose.SEARCHABLE_PURPOSES = [
  PurchasePurpose.GIFT,
  PurchasePurpose.EXPERIENCE
];

export default PurchasePurpose;
