export default {
  bind: (el, binding) => {
    el.onWindowResize = () => {
      const duration = binding.value || 0;
      el.style.setProperty('transition', 'none');

      if (el.intervalRelease) clearTimeout(el.intervalRelease);

      el.intervalRelease = setTimeout(() => {
        el.style.removeProperty('transition');
        el.intervalRelease = null;
      }, duration);
    };
    window.addEventListener('resize', el.onWindowResize, false);
  },
  unbind: el => {
    window.removeEventListener('resize', el.onWindowResize);
    delete el.onWindowResize;
  }
};
