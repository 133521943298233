<template>
  <AppModalForm
    :title="$t(id ? 'title_edit' : 'title_new')"
    :sub-title="name"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId: 'option_type',
      groupLabelNamespace: 'product_option_type',
      focusGroupId: 'name'
    }"
    v-on="formEvents"
  >
    <input type="hidden" name="per" :value="per" />
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ProductOptionTypeFormDialog',
  mixins: [DialogFormView],
  props: {
    id: { type: Number, default: null },
    name: { type: String, required: true },
    per: { type: Number, required: true }
  },
  data() {
    return { isLoading: true, optionTypeNames: null };
  },
  computed: {
    formSections() {
      return [
        {
          id: 'default',
          groups: [
            {
              id: 'name',
              type: 'text',
              validate: [
                'required',
                {
                  rule: v =>
                    v == this.name || !this.optionTypeNames.has(v.trim()),
                  errorMessage: this.$t('errors.duplicated')
                }
              ]
            }
          ]
        }
      ];
    }
  },
  mounted() {
    this.orgFormObject = { name: this.name };
    api
      .get('/product/option_types/names')
      .then(
        ({ data }) => (this.optionTypeNames = new Set(data.option_type_names))
      )
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .request({
          url: this.id
            ? `/product/option_types/${this.id}`
            : '/product/option_types',
          method: this.id ? 'patch' : 'post',
          data: formData,
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.eventBus.$emit('update', data);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_edit": "상품 옵션 수정",
  "title_new": "상품 옵션 추가",
  "errors": {
    "duplicated": "이미 사용중인 옵션명 입니다."
  }
}
</i18n>
