<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppResourceTable
      table-id="review-category-products-table"
      class="ReviewCategoryTable__resource-table"
      :table-name="$t('review_category_products_table_title')"
      :resources="products"
      :columns="COLUMNS"
      :rows="rows"
      @paginate="paginate"
    >
      <template #cell="{ value, column, row }">
        <template v-if="column === 'category_names'">
          <ul class="badges">
            <li v-for="(name, idx) in value" :key="idx">
              <AppBadge badge-style="grey-outline" :label="name" />
            </li>
          </ul>
        </template>
        <template v-else-if="column === 'status'">
          <ul class="badges">
            <li>
              <AppBadge
                :badge-style="row.display ? 'mint-green' : 'default'"
                :label="
                  row.display
                    ? $t('products.displayed')
                    : $t('products.undisplayed')
                "
              />
            </li>
            <li v-if="row.sold_out">
              <AppBadge :label="$t('products.sold_out')" />
            </li>
            <li>
              <AppBadge
                :tooltip="
                  row.review_display
                    ? null
                    : ProductStatusReason.t(row.product_status_reason)
                "
                :badge-style="row.review_display ? 'mint-green' : 'default'"
                :label="
                  row.review_display
                    ? $t('products.review_displayed')
                    : $t('products.review_undisplayed')
                "
              />
            </li>
          </ul>
        </template>
        <ReviewCategoryProductManageButton
          v-else-if="column === 'actions'"
          :product="row"
          @change="change($event)"
        />
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ProductStatusReason from '@/enums/ProductStatusReason';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

import ReviewCategoryProductManageButton from './ReviewCategoryProductManageButton';

export default {
  name: 'ReviewCategoryProductsTable',
  components: { ReviewCategoryProductManageButton },
  mixins: [ResourceViewNoRoute],
  props: {
    categoryId: {
      type: Number,
      required: true
    },
    eventBus: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'id', label: this.$t('app.id') },
        { id: 'code', label: this.$t('products.code') },
        { id: 'updated_at', label: this.$t('updated_at') },
        { id: 'name', label: this.$t('products.name'), type: 'product' },
        {
          id: 'option_values',
          label: this.$t('products.option_values'),
          type: 'pre'
        },
        { id: 'category_names', label: this.$t('category_names') },
        { id: 'status', label: this.$t('app.status') },
        { id: 'actions', label: this.$t('app.actions'), superAdmin: true }
      ],
      products: nullResources,
      isLoading: true
    };
  },
  computed: {
    ProductStatusReason() {
      return ProductStatusReason;
    },
    rows() {
      return this.products.items.map(product => ({
        ...product,
        updated_at: this.formatDateTime(product.updated_at),
        name: _.pick(product, ['name', 'url', 'image_url']),
        option_values: product.option_values.join('\n')
      }));
    }
  },
  mounted() {
    this.eventBus.$on('refresh', () => this.fetchResource());
  },
  methods: {
    fetchResource(params) {
      api
        .get('/product_categories/products', {
          params: {
            ...params,
            id: this.categoryId
          }
        })
        .then(({ data }) => (this.products = data.products))
        .finally(() => (this.isLoading = false));
    },
    change(product) {
      const index = this.products.items.findIndex(p => p.id === product.id);
      this.$set(this.products.items, index, product);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .review-category-products-table__id {
    flex: 1 1 50px;
  }

  .review-category-products-table__code {
    flex: 0 0 120px;
  }

  .review-category-products-table__updated-at {
    flex: 0 0 150px;
  }

  .review-category-products-table__name {
    flex: 1 0 150px;
  }

  .review-category-products-table__option-values {
    flex: 0 0 150px;
  }

  .review-category-products-table__category-names {
    flex: 0 0 120px;
  }

  .review-category-products-table__status {
    flex: 0 0 110px;
  }

  .review-category-products-table__actions {
    flex: 0 0 200px;
  }

  .AppResourceTableCell {
    line-height: 23px;
  }
}
</style>

<i18n locale="ko">
{
  "updated_at": "최종 동기화 날짜",
  "category_names": "분류",
  "review_category_products_table_title": "이 상품 카테고리에 포함된 상품 목록"
}
</i18n>
