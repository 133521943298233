import { constructEnum } from '@/lib/enum';

const BrandFitStatus = constructEnum('BrandFitStatus', {
  NONE: 0,
  REQUESTED: 10,
  PAID: 12,
  TESTING: 15,
  IMPORTING: 20,
  OPERATING: 30,
  EXPIRED_BUT_OPERATING: 35,
  EXPIRED_AND_ADMIN_BLOCKED: 37,
  TERMINATED: 50,
  TERMINATED_ONLY_USING_FIT_PRODUCT_WIDGET: 53
});

BrandFitStatus.TARGET_USABLE_STATUSES = [
  BrandFitStatus.PAID,
  BrandFitStatus.TESTING,
  BrandFitStatus.IMPORTING,
  BrandFitStatus.OPERATING,
  BrandFitStatus.EXPIRED_BUT_OPERATING
];

BrandFitStatus.isTargetUsable = function(brandFitStatus) {
  return BrandFitStatus.TARGET_USABLE_STATUSES.includes(brandFitStatus);
};

export default BrandFitStatus;
