var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t(_vm.isEditing ? 'title_edit' : 'title_new'),"sub-title":_vm.isEditing
      ? _vm.$t('sub_title_edit', [_vm.formatUser(_vm.comment)])
      : _vm.$t('sub_title_new', [_vm.formatUser(_vm.review)]),"form-props":Object.assign({}, _vm.formProps, {objectId: 'comment', focusGroupId: 'message'})},scopedSlots:_vm._u([(_vm.useAiReviewComment)?{key:"head",fn:function(){return [_c('AppButton',{staticClass:"ReviewReviewEditCommentDialog__help-button",attrs:{"button-style":"grey-clear","label":_vm.$t('ai_comment_help')},on:{"click":_vm.openAiCommentHelpDialog}})]},proxy:true}:null],null,true)},_vm.formEvents),[(_vm.useAiReviewComment)?_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('ai_comment'),"menu-items":_vm.menuItems,"disabled":_vm.aiReviewCommentDisabled,"tooltip":!_vm.aiReviewCommentDisabled
        ? null
        : {
            message: _vm.$t('ai_comment_disabled_tooltip'),
            textAlign: 'left'
          },"button-style":"mango-outline"}}):_vm._e(),_c('AppButton',{attrs:{"label":_vm.$t('add_random_message_button')},on:{"click":_vm.addRandomMessage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }