import { constructEnum } from '@/lib/enum';

const DisplayCornerStatus = constructEnum('DisplayCornerStatus', {
  NONE: 0,
  READY: 10,
  APPLYING: 15,
  OPERATING: 20,
  DELETED: 30,
  ERROR: 1000
});

DisplayCornerStatus.isDeleted = function(cornerStatus) {
  return DisplayCornerStatus.DELETED === cornerStatus;
};

DisplayCornerStatus.isErrored = function(cornerStatus) {
  return DisplayCornerStatus.ERROR === cornerStatus;
};

export default DisplayCornerStatus;
