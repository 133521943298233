import _ from 'lodash';

export default {
  methods: {
    percentage(numerator, denominator) {
      return denominator ? ((numerator * 100) / denominator).toFixed(2) : 0;
    },
    sentimentsRatio(sentiments) {
      return this.percentage(
        sentiments.psentiments_count,
        sentiments.sentiments_sum
      );
    },
    sentimentsRatioString(sentiments) {
      return this.toRatioString(this.sentimentsRatio(sentiments));
    },
    toRatioString(ratio) {
      return this.$t('unit.percentage', [
        ratio.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      ]);
    },
    ratioDiff(a, b) {
      return Number.parseFloat(a - b).toFixed(2);
    },
    keywordsSummary(details) {
      const psentiments_count = _.sum(details.map(d => d.psentiments_count));
      const zsentiments_count = _.sum(details.map(d => d.zsentiments_count));
      const nsentiments_count = _.sum(details.map(d => d.nsentiments_count));
      const sentiments_sum = _.sum([
        psentiments_count,
        zsentiments_count,
        nsentiments_count
      ]);
      return {
        sentiments_sum,
        psentiments_count,
        zsentiments_count,
        nsentiments_count
      };
    },
    top3TotalKeywords(details) {
      return _.orderBy(
        details.filter(d => d.sentiments_sum),
        ['sentiments_sum', 'psentiments_count'],
        ['desc', 'desc']
      ).slice(0, 3);
    },
    top3PositiveKeywords(details) {
      return _.orderBy(
        details.filter(d => d.psentiments_count),
        ['psentiments_count', 'sentiments_sum'],
        ['desc', 'desc']
      ).slice(0, 3);
    },
    top3NegativeKeywords(details) {
      return _.orderBy(
        details.filter(d => d.nsentiments_count),
        ['nsentiments_count', 'sentiments_sum'],
        ['desc', 'desc']
      ).slice(0, 3);
    }
  }
};
