import moment from 'moment';

export default {
  props: {
    dateRange: { type: Object, required: true }
  },
  data() {
    return { chartTooltip: null };
  },
  computed: {
    xAxes() {
      switch (this.timeBucket) {
        case 'daily':
          return this.xAxisValues.map(v => v.format('MM. DD.'));
        case 'weekly':
          return this.xAxisValues.map(v => v.format('MM. DD.'));
        default:
          return this.xAxisValues.map(v => this.formatMonthWithYear(v));
      }
    },
    xAxisValues() {
      const { start_date, end_date } = this.dateRange;
      switch (this.timeBucket) {
        case 'daily': {
          return [...Array(this.datesLength)].map((_, i) =>
            moment(start_date).add(i, 'days')
          );
        }
        case 'weekly': {
          let weeksLength;
          if (moment(start_date).day() > moment(end_date).day())
            weeksLength = Math.ceil(this.datesLength / 7) + 1;
          else weeksLength = Math.floor(this.datesLength / 7) + 1;

          // main.js 에서 한 주의 시작을 월요일로 설정
          // 인사이트에서는 한 주가 일요일부터 시작
          let startDateOfWeek = moment(start_date)
            .add(1, 'day')
            .startOf('week')
            .subtract(1, 'day');

          return [...Array(weeksLength)].map((_, i) => {
            if (i === 0) return moment(start_date);
            else return moment(startDateOfWeek).add(i, 'weeks');
          });
        }
        default: {
          let monthsLength =
            (moment(end_date).year() - moment(start_date).year()) * 12 +
            (moment(end_date).month() - moment(start_date).month()) +
            1;
          let startDateOfMonth = moment(start_date).startOf('month');

          return [...Array(monthsLength)].map((_, i) =>
            moment(startDateOfMonth).add(i, 'months')
          );
        }
      }
    },
    datesLength() {
      const { start_date, end_date } = this.dateRange;

      return moment(end_date).diff(start_date, 'days') + 1;
    },
    timeBucket() {
      switch (true) {
        case this.datesLength <= 21:
          return 'daily';
        case this.datesLength <= 90:
          return 'weekly';
        default:
          return 'monthly';
      }
    }
  },
  methods: {
    getFormattedDate(date) {
      if (this.timeBucket === 'daily') return this.formatDateWithWday(date);
      else if (this.timeBucket === 'weekly')
        return `${this.formatDate(date)} ~ ${this.formatDate(
          this.getEndDate(date)
        )}`;
      else {
        return `${this.formatDate(this.getStartDate(date))} ~ ${this.formatDate(
          this.getEndDate(date)
        )}`;
      }
    },
    getStartDate(date) {
      const { start_date } = this.dateRange;

      if (moment(date).toVal() < start_date) return moment(start_date).toVal();
      else return moment(date).toVal();
    },
    getEndDate(date) {
      if (this.timeBucket === 'weekly') return this.weekEndDate(date).toVal();
      else return this.monthEndDate(date).toVal();
    },
    weekEndDate(date) {
      const { end_date } = this.dateRange;
      if (moment(date).toVal() === end_date) return moment(date);

      const endDate = moment(date)
        .add(1, 'day')
        .endOf('week')
        .subtract(1, 'day');
      if (endDate.toVal() > end_date) return moment(end_date);
      else return endDate;
    },
    monthEndDate(date) {
      const { end_date } = this.dateRange;
      const endDate = moment(date)
        .add(1, 'month')
        .subtract(1, 'day');
      if (endDate.toVal() >= end_date) return moment(end_date);
      else return endDate;
    }
  }
};
