<template>
  <AppModalDefault :title="$t('title')" :sub-title="abTest.name" @close="close">
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <div class="AnalyticsAbTestsReviewStatsDialog__summary">
          <div class="AnalyAnalyticsAbTestsReviewStatsDialog__summary-section">
            <div class="AnalyticsAbTestsReviewStatsDialog__title">
              {{ $t('section.total_assignments_count') }}
            </div>
            <div>
              {{ $t('assigned_users', [reports.total_assignments_count]) }}
            </div>
          </div>
          <div class="AnalyAnalyticsAbTestsReviewStatsDialog__summary-section">
            <div class="AnalyticsAbTestsReviewStatsDialog__title">
              {{ $t('section.assignments_per_group') }}
            </div>
            <AppButton
              v-for="(data, index) in reports.assignments_by_group"
              :key="index"
              button-style="grey-outline"
              :label="
                $t('assignments_per_group', [
                  index + 1,
                  data.total_count,
                  sentPercentage(data.total_count)
                ])
              "
            />
          </div>
        </div>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'AnalyticsAbTestsReviewStatsDialog',
  mixins: [DialogView],
  props: {
    abTest: { type: Object, required: true }
  },
  data() {
    return {
      isLoading: false,
      reports: {}
    };
  },
  mounted() {
    this.isLoading = true;
    api
      .get(`/analytics/ab_tests/${this.abTest.id}/review_reports`)
      .then(({ data }) => {
        this.reports = data.reports;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    sentPercentage(totalUsersCountForGroup) {
      const totalUsersCount = this.reports.total_assignments_count;
      if (totalUsersCount == 0) {
        return 0;
      } else {
        return ((totalUsersCountForGroup * 100) / totalUsersCount).toFixed(1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.AnalyticsAbTestsReviewStatsDialog__summary-section {
  margin-bottom: 20px;
}

.AnalyticsAbTestsReviewStatsDialog__title {
  font-weight: bold;
}
</style>

<i18n locale="ko">
{
  "title": "A/B 테스트 통계",
  "section": {
    "total_assignments_count": "전체 유저수",
    "assignments_per_group": "그룹별 유저수"
  },
  "assignments_per_group": "그룹 {0}: {1} 명 ({2}%)",
  "assigned_users": "{0} 명",
  "button": {
    "group_detail": "그룹 {0} 상세보기"
  }
}
</i18n>
