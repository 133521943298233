<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppCurrencyInput v-model="valueInCents" :digits="3" />
      <div class="ui-section__description">
        Selected Value: {{ valueInCents }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Allow Negative</div>
      <AppCurrencyInput
        v-model="valueAllowNegative"
        :digits="3"
        allow-negative
      />
      <div class="ui-section__description">
        Selected Value: {{ valueAllowNegative }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiCurrencyInput',
  data() {
    return {
      valueInCents: 700,
      valueAllowNegative: -123
    };
  }
};
</script>
