<template>
  <div v-show="false">
    <input
      v-for="(v, i) in values.length ? values : ['']"
      :key="i"
      :name="name"
      type="hidden"
      :value="v"
    />
  </div>
</template>

<script>
export default {
  name: 'AppHiddenInputArray',
  props: {
    name: { type: String, required: true },
    values: { type: Array, required: true }
  }
};
</script>
