<template>
  <div class="BizMessageCampaigns">
    <AppAjaxContent :is-loading="isLoading">
      <div class="BizMessagesCampaigns__title">
        {{ $t('title') }}
      </div>
      <AppGrid>
        <div
          v-for="(section, i) in sections"
          :key="i"
          class="AppGrid__col AppGrid__col--4"
        >
          <AppDataSection :title="section.title">
            <AppButton
              :label="$t('app.settings')"
              class="BizMessagesCampaigns__settings-button"
              :bubble-message="
                isDefaultCampaign(section.campaign) &&
                showCampaignSettingsBubbleMessage
                  ? $t('hide_campaign_settings_bubble')
                  : null
              "
              bubble-side="bottom-right"
              @click="clickSettings(section.campaign)"
            />
            <div>{{ section.content }}</div>
          </AppDataSection>
        </div>
      </AppGrid>
    </AppAjaxContent>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/lib/api';
import BizMessageCampaignType from '@/enums/BizMessageCampaignType';

const BUBBLE_STORAGE_KEY = 'hide_campaign_settings_bubble';
export default {
  name: 'BizMessageCampaigns',
  data() {
    return {
      isLoading: true,
      campaigns: [],
      showCampaignSettingsBubbleMessage: false
    };
  },
  computed: {
    ...mapState('session', ['reviewSettings']),
    sections() {
      return this.campaigns
        .filter(campaign => {
          switch (campaign.campaign_type) {
            case BizMessageCampaignType.REVIEW_NONMEMBER:
              return this.reviewSettings.nonmember_message_enabled;
            case BizMessageCampaignType.REVIEW_OFFLINE:
              return this.reviewSettings.offline_message_enabled;
            default:
              return true;
          }
        })
        .map(campaign => {
          return {
            campaign,
            title: BizMessageCampaignType.t(campaign.campaign_type),
            content: campaign.template
              ? campaign.template.name
              : this.$t('content_placeholder')
          };
        });
    }
  },
  mounted() {
    this.showCampaignSettingsBubbleMessage = !localStorage.getItem(
      BUBBLE_STORAGE_KEY
    );
    this.fetchCampaigns();
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchCampaigns() {
      this.isLoading = true;
      api
        .get('/biz_message/campaigns')
        .then(({ data }) => (this.campaigns = data.campaigns))
        .finally(() => (this.isLoading = false));
    },
    isDefaultCampaign(campaign) {
      return campaign.campaign_type === BizMessageCampaignType.REVIEW_MEMBER;
    },
    clickSettings(campaign) {
      if (this.isDefaultCampaign(campaign)) {
        this.showCampaignSettingsBubbleMessage = false;
        localStorage.setItem(BUBBLE_STORAGE_KEY, true);
      }

      this.openDialog([
        'BizMessageCampaignFormDialog',
        { campaignType: campaign.campaign_type, campaignId: campaign.id }
      ]).then(eventBus => {
        eventBus.$on('saved', () => this.fetchCampaigns());
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.BizMessagesCampaigns__title {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 8px;
  color: $color-grey-50;
}

.BizMessagesCampaigns__settings-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>

<i18n locale="ko">
{
  "title": "리뷰 작성 유도 메시지 설정",
  "content_placeholder": "템플릿 메시지를 설정해주세요.",
  "hide_campaign_settings_bubble": "발송될 알림톡 메시지를 확인하려면<br />이 곳을 클릭해주세요."
}
</i18n>
