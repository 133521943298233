import _ from 'lodash';
import Vue from 'vue';

const state = {
  installRequestCurrentStep: 0,
  currentPageType: null,
  currentSlotTypes: [],
  installDeviceTypes: [],
  countAndScoreCustomDeviceTypes: [],
  bubbleVisibility: { cardButton: true, preview: true },
  installations: [],
  directions: [],
  countAndScoreDirection: {}
};

const mutations = {
  SET_INSTALL_REQUEST_STEP(state, installRequestCurrentStep) {
    state.installRequestCurrentStep = installRequestCurrentStep;
  },
  SET_CURRENT_PAGE_TYPE(state, pageType) {
    state.currentPageType = pageType;
  },
  SET_CURRENT_SLOT_TYPES(state, slotTypes) {
    state.currentSlotTypes = slotTypes;
  },
  SET_INSTALL_DEVICE_TYPES(state, deviceTypes) {
    state.installDeviceTypes = deviceTypes;
  },
  SET_COUNT_AND_SCORE_CUSTOM_DEVICE_TYPES(state, deviceTypes) {
    state.countAndScoreCustomDeviceTypes = deviceTypes;
  },
  SET_BUBBLE_INVISIBLE(state, bubbleId) {
    Vue.set(state.bubbleVisibility, bubbleId, false);
  },
  SET_ALL_BUBBLE_VISIBLE(state) {
    state.bubbleVisibility = { cardButton: true, preview: true };
  },
  APPEND_INSTALLATION(state, installation) {
    state.installations = [...state.installations, installation];
  },
  ADD_INSTALLATIONS(state, installations) {
    state.installations = [...state.installations, ...installations];
  },
  REMOVE_INSTALLATION(state, installation) {
    state.installations = _.reject(
      state.installations,
      i =>
        i.page_type === installation.page_type &&
        i.slot_type === installation.slot_type &&
        i.widget_device_type === installation.widget_device_type &&
        i.id === installation.id
    );
  },
  UPDATE_INSTALLATION(state, installation) {
    let index = state.installations.findIndex(
      i =>
        i.page_type === installation.page_type &&
        i.slot_type === installation.slot_type &&
        i.widget_device_type === installation.widget_device_type &&
        i.id === installation.id
    );
    if (index === -1) index = state.installations.length;
    Vue.set(state.installations, index, installation);
  },
  CLEAR_INSTALLATIONS(state) {
    state.installations = [];
  },
  APPEND_DIRECTION(state, direction) {
    state.directions = [...state.directions, direction];
  },
  REMOVE_CURRENT_SLOT_DIRECTIONS(state) {
    state.directions = state.directions.filter(
      direction =>
        !(
          direction.page_type === state.currentPageType &&
          state.currentSlotTypes.includes(direction.slot)
        )
    );
  },
  SET_COUNT_AND_SCORE_DIRECTION(state, direction) {
    state.countAndScoreDirection = direction;
  }
};

const getters = {
  currentInstallations(state) {
    return state.installations.filter(
      installation =>
        installation.page_type === state.currentPageType &&
        state.currentSlotTypes.includes(installation.slot_type)
    );
  },
  currentDirections(state) {
    return state.directions.filter(
      direction =>
        direction.page_type === state.currentPageType &&
        state.currentSlotTypes.includes(direction.slot)
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
