import _ from 'lodash';

const state = {
  pageBreadcrumbs: []
};

const mutations = {
  SET_PAGE_BREADCRUMBS(state, pageBreadcrumbs) {
    state.pageBreadcrumbs = pageBreadcrumbs;
  }
};

const actions = {
  setPageBreadcrumbs({ commit }, pageBreadcrumbs) {
    commit('SET_PAGE_BREADCRUMBS', pageBreadcrumbs);
    commit('SET_TITLE', _.last(pageBreadcrumbs).label, { root: true });
  },
  clearPageBreadcrumbs({ commit, dispatch }) {
    commit('SET_PAGE_BREADCRUMBS', []);
    dispatch('resetTitle', null, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
