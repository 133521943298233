<template>
  <div class="WidgetInstallRequestDialogProductDetail">
    <AppTable
      table-id="widget-install-request-table"
      :columns="columns"
      :rows="rows"
    >
      <template #cell="{ column, row }">
        <template v-if="column.id === 'placement_preview'">
          <AppButton
            type="external_link"
            @click="openPositionPreviewDialog(false)"
          >
            {{ $t('app.pc') }}
          </AppButton>
          <AppButton
            type="external_link"
            @click="openPositionPreviewDialog(true)"
          >
            {{ $t('app.mobile') }}
          </AppButton>
        </template>
        <template v-else-if="column.id === 'installable_widget_list'">
          <AppButton
            button-style="blue-outline"
            :label="$t('button.installable_widget_list')"
            @click="showInstallableWidgetList(row)"
          />
        </template>
        <template v-else-if="column.id === 'selected_widget'">
          <div class="WidgetInstallRequestDialog__selected_widget">
            {{ $t('placeholder.selected_widget') }}
          </div>
        </template>
        <template v-else-if="column.id === 'function'">
          <AppButton button-style="red-outline" :label="$t('button.delete')" />
        </template>
      </template>
    </AppTable>
    <div class="WidgetInstallRequestDialogProductDetail__button">
      <AppButton
        button-style="blue"
        :label="$t('button.complete_and_move')"
        @click="clickCompleteAndMoveNext"
      />
    </div>
    <template v-if="activePlacementType">
      <div class="WidgetInstallRequestDialogProductDetail__widget_list_title">
        {{ $t('widget_list_title.installable_widget') }}
      </div>
      <div
        class="WidgetInstallRequestDialogProductDetail_widget-card-container"
      >
        <div v-for="style in installableWidgetStyles" :key="style">
          <WidgetCard :combination-type="style" is-single-widget />
        </div>
      </div>
      <div class="WidgetInstallRequestDialogProductDetail__widget_list_title">
        {{ $t('widget_list_title.recommendation') }}
      </div>
      <div
        class="WidgetInstallRequestDialogProductDetail_widget-card-container"
      >
        <div v-for="style in recommendationWidgetStyles" :key="style">
          <WidgetCard
            :combination-type="style"
            :page-type="WidgetPageType.PRODUCT_DETAIL"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import WidgetCombinationType from '@/enums/WidgetCombinationType';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallationPresetPlacementType from '@/enums/InstallationPresetPlacementType';
import WidgetInstallRequestProgressStep from '@/enums/WidgetInstallRequestProgressStep';

export default {
  name: 'WidgetInstallRequestDialogProductDetail',
  data() {
    return {
      isLoading: false,
      activePlacementType: null
    };
  },
  computed: {
    ...mapState('widgetInstallRequest', ['widgetInstallRequestCurrentStep']),
    WidgetPageType() {
      return WidgetPageType;
    },
    columns() {
      return [
        'label',
        'placement_preview',
        'installable_widget_list',
        'selected_widget',
        'function'
      ].map(id => ({ id, label: this.$t(`column.${id}`) }));
    },
    rows() {
      return InstallationPresetPlacementType.options(
        InstallationPresetPlacementType.PRODUCT_REVIEWS_PLACEMENTS
      );
    },
    installableWidgetStyles() {
      switch (this.activePlacementType) {
        case InstallationPresetPlacementType.AFTER_THUMBNAIL_IMAGE:
          return [WidgetCombinationType.PHOTO_THUMBNAIL];
        case InstallationPresetPlacementType.PREPEND_PRODUCT_DETAIL:
          return [
            WidgetCombinationType.BOARD,
            WidgetCombinationType.HORIZONTAL_SCROLL,
            WidgetCombinationType.EXTERNAL
          ];
        default:
          return [WidgetCombinationType.LIST, WidgetCombinationType.BOARD];
      }
    },
    recommendationWidgetStyles() {
      return WidgetCombinationType.productDetailTypes;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('widgetInstallRequest', [
      'SET_WIDGET_INSTALL_REQUEST_STEP'
    ]),
    openPositionPreviewDialog(isMobile) {
      this.openDialog([
        'ReviewDashboardRequestInstallationPositionPreviewDialog',
        {
          isMobile,
          pageType: WidgetPageType.PRODUCT_DETAIL
        }
      ]);
    },
    showInstallableWidgetList(row) {
      this.activePlacementType = row.value;
    },
    clickCompleteAndMoveNext() {
      this.SET_WIDGET_INSTALL_REQUEST_STEP(
        WidgetInstallRequestProgressStep.REVIEW_BOARD
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
.WidgetInstallRequestDialog__selected_widget {
  color: $color-grey-50;
}

.WidgetInstallRequestDialogProductDetail__button {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.WidgetInstallRequestDialogProductDetail__widget_list_title {
  @include text-title;
  text-align: left;
  margin: 28px 0;
}

.WidgetInstallRequestDialogProductDetail_widget-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}
</style>

<i18n locale="ko">
{
  "column": {
    "label": "설치 위치",
    "placement_preview": "위치 미리보기",
    "installable_widget_list": "설치 가능한 위젯 목록",
    "selected_widget": "선택한 위젯",
    "function": "기능"
  },
  "placeholder": {
    "selected_widget": "위젯을 선택해주세요."
  },
  "button": {
    "installable_widget_list": "위젯 목록 보기",
    "delete": "삭제",
    "complete_and_move": "선택 완료 / 다음 페이지로 이동 >"
  },
  "widget_list_title": {
    "installable_widget": "상품 리뷰 탭에 설치 가능한 위젯 목록",
    "recommendation": "크리마 추천 구성"
  }
}
</i18n>
