<template>
  <AppButton
    :class="['AppDateRangePicker', { 'AppDateRangePicker--invalid': invalid }]"
    type="dropdown"
    button-style="input"
    size="narrow"
    :disabled="disabled"
    @click="isDateRangePickerPopupVisible = !isDateRangePickerPopupVisible"
    @blur="$emit('blur')"
  >
    <span>{{ dateRangeText }}</span>
    <AppSvgIcon class="AppDateRangePicker__icon" name="icon-calendar-small" />
    <template #dropdown>
      <AppDateRangePickerPopup
        v-if="isDateRangePickerPopupVisible"
        :required="required"
        v-bind="{ dateRange, minDate, minDays, maxDays, presets }"
        class="dropdown__dialog"
        @close="isDateRangePickerPopupVisible = false"
        @apply="apply"
      />
    </template>
  </AppButton>
</template>

<script>
export default {
  name: 'AppDateRangePicker',
  model: {
    prop: 'dateRange',
    event: 'update'
  },
  props: {
    dateRange: {
      type: Object,
      required: true
    },
    presets: {
      type: Array,
      default: () => [
        'entire_period',
        'today',
        'yesterday',
        'last_7days',
        'last_30days',
        'this_month',
        'last_month'
      ]
    },
    minDate: { type: String, default: null },
    minDays: { type: Number, default: 1 },
    maxDays: { type: Number, default: null },
    invalid: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false }
  },
  data() {
    return {
      isDateRangePickerPopupVisible: false
    };
  },
  computed: {
    dateRangeText() {
      const { start_date, end_date } = this.dateRange;
      return start_date && end_date
        ? `${this.formatDate(start_date)} - ${this.formatDate(end_date)}`
        : this.$t(this.required ? 'select_date' : 'app.entire_period');
    }
  },
  methods: {
    apply({ startDate, endDate }) {
      this.$emit('update', {
        ...this.dateRange,
        start_date: startDate,
        end_date: endDate
      });
      this.isDateRangePickerPopupVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppDateRangePicker--invalid ::v-deep {
  > div > .AppButton__button {
    @include input-invalid;
  }
}

.AppDateRangePicker__icon {
  position: relative;
  top: 2px;
  margin-left: 7px;
}
</style>

<i18n locale="ko">
{
  "select_date": "날짜선택"
}
</i18n>
