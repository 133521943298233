<template>
  <AppDropdownMenuButton
    v-bind="{ label, buttonStyle, menuItems, ...archivedProps }"
  />
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import api from '@/lib/api';
import HttpStatus from '@/enums/HttpStatus';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';
import TargetCampaignStatus from '@/enums/TargetCampaignStatus';
import TargetCalculateStatus from '@/enums/TargetCalculateStatus';

export default {
  name: 'TargetCampaignManageButton',
  props: {
    campaign: { type: Object, required: true },
    label: {
      type: String,
      default() {
        return this.$t('app.manage');
      }
    },
    buttonStyle: { type: String, default: undefined }
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('session', [
      'isTargetTesting',
      'isReviewTerminated',
      'isReviewPreparing'
    ]),
    menuItemConfigs() {
      return {
        campaignStatusSection: this.campaignStatuses.map(status => {
          const selected = this.campaign.status === status;
          return {
            label: TargetCampaignType.statusName(
              this.campaign.campaign_type,
              status
            ),
            selected,
            ...(this.isTargetTesting
              ? {
                  disabled: !!this.enabledBlockedTooltip(status),
                  tooltip: this.enabledBlockedTooltip(status)
                }
              : selected
              ? {}
              : {
                  disabled: !!this.statusDisabledTooltip,
                  tooltip: this.statusDisabledTooltip
                }),
            clickHandler: ({ close }) => {
              this.updateCampaignStatus(status);
              close();
            }
          };
        }),
        calculateSection: [
          {
            label: this.$t('menu_item.calculate'),
            superAdmin: true,
            disabled: !!this.calculateDisabledTooltip,
            tooltip: this.calculateDisabledTooltip,
            clickHandler: ({ close }) => {
              this.clickRecalculate();
              close();
            }
          },
          ...(this.campaign.calculate_status === TargetCalculateStatus.COMPLETE
            ? [
                {
                  label: this.$t('menu_item.recipients'),
                  superAdmin: true,
                  clickHandler: ({ close }) => {
                    this.clickViewCalculateReport();
                    close();
                  }
                }
              ]
            : [])
        ],
        deleteSection: [
          {
            label: this.$t('menu_item.delete_campaign'),
            style: 'danger',
            clickHandler: ({ close }) => {
              this.deleteCampaign();
              close();
            }
          }
        ],
        archiveSection: [
          {
            label: this.$t('menu_item.archive_campaign'),
            clickHandler: ({ close }) => {
              this.archiveCampaign();
              close();
            }
          }
        ],
        widgetSection: [
          {
            label: this.$t('menu_item.copy_script'),
            clickHandler: ({ close }) => {
              this.openCopyWidgetScriptDialog();
              close();
            }
          },
          {
            label: this.$t('menu_item.move_to_widget_inserted_url'),
            type: 'external_link',
            to: this.campaign.inserted_url,
            disabled:
              this.isCartPopupWidgetCampaign || !this.campaign.inserted_url,
            tooltip: this.isCartPopupWidgetCampaign
              ? this.$t('menu_tooltip.disabled_cart_popup_widget')
              : this.campaign.inserted_url
              ? null
              : this.$t('menu_tooltip.not_found_inserted_url')
          }
        ]
      };
    },
    menuItems() {
      const { campaign, menuItemConfigs } = this;

      const result = [menuItemConfigs.campaignStatusSection];

      if (campaign.campaign_media_type === TargetCampaignMediaType.WIDGET)
        result.push(menuItemConfigs.widgetSection);
      else result.push(menuItemConfigs.calculateSection);

      if (campaign.deletable) result.push(menuItemConfigs.deleteSection);

      if (campaign.archivable) result.push(menuItemConfigs.archiveSection);

      return result;
    },
    reviewPreparingTooltip() {
      return this.isReviewPreparing &&
        this.campaign.campaign_type ===
          TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT
        ? this.$t('menu_tooltip.review_preparing')
        : null;
    },
    reviewTerminatedTooltip() {
      return this.isReviewTerminated &&
        this.campaign.campaign_type ===
          TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT
        ? this.$t('menu_tooltip.review_terminated')
        : null;
    },
    reviewDisabledTooltip() {
      return this.campaign.review_enabled === false
        ? this.$t('menu_tooltip.review_disabled')
        : null;
    },
    notConfiguredTooltip() {
      return this.campaign.configured
        ? null
        : this.$t('menu_tooltip.not_configured');
    },
    statusDisabledTooltip() {
      if (this.reviewPreparingTooltip) return this.reviewPreparingTooltip;
      if (this.reviewTerminatedTooltip) return this.reviewTerminatedTooltip;
      if (this.reviewDisabledTooltip) return this.reviewDisabledTooltip;
      if (this.notConfiguredTooltip) return this.notConfiguredTooltip;

      if (
        TargetCampaignType.isOneTime(this.campaign.campaign_type) &&
        this.campaign.status === TargetCampaignStatus.ARCHIVED
      )
        return this.$t('menu_tooltip.archived');

      const { dispatch_date, dispatch_hour } = this.campaign;
      if (
        dispatch_date &&
        moment(`${dispatch_date} ${dispatch_hour}:00`) < moment()
      )
        return this.$t('menu_tooltip.dispatch_date_passed');

      return null;
    },
    calculateDisabledTooltip() {
      return TargetCampaignType.isRealtime(this.campaign.campaign_type)
        ? this.$t('menu_tooltip.realtime_campaign_is_not_calculable')
        : this.notConfiguredTooltip;
    },
    campaignStatuses() {
      return TargetCampaignType.isOneTime(this.campaign.campaign_type)
        ? [TargetCampaignStatus.ENABLED, TargetCampaignStatus.DISABLED]
        : [
            TargetCampaignStatus.ENABLED,
            TargetCampaignStatus.TESTING,
            TargetCampaignStatus.DISABLED
          ];
    },
    archivedProps() {
      return this.campaign.status === TargetCampaignStatus.ARCHIVED
        ? { disabled: true, tooltip: this.$t('tooltip.archived') }
        : {};
    },
    isCartPopupWidgetCampaign() {
      return (
        this.campaign.campaign_type ===
        TargetCampaignType.PRODUCT_RECOMMENDATION_CART_POPUP
      );
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    updateCampaignStatus(status) {
      api
        .patch(
          `/target/campaigns/${this.campaign.id}/status`,
          { campaign: { status } },
          { successMessage: this.$t('app.saved') }
        )
        .then(() => this.$emit('change-status', status));
    },
    deleteCampaign() {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      api
        .delete(`/target/campaigns/${this.campaign.id}`, {
          successMessage: this.$t('app.deleted')
        })
        .then(() => this.$emit('delete'));
    },
    archiveCampaign() {
      if (confirm(this.$t('confirm_archive')))
        this.updateCampaignStatus(TargetCampaignStatus.ARCHIVED);
    },
    enabledBlockedTooltip(status) {
      if (status === TargetCampaignStatus.ENABLED) {
        if (this.isTargetTesting) return this.$t('menu_tooltip.testing');
      } else return '';
    },
    clickRecalculate() {
      api
        .post(
          `/target/campaigns/${this.campaign.id}/recalculate`,
          {},
          { successMessage: this.$t('recalculate_started'), silent: true }
        )
        .then(({ data }) => {
          const { calculate_status, calculated_at } = data.campaign;
          this.$emit('change-calculate-status', {
            calculate_status: calculate_status,
            calculated_at: calculated_at
          });
        })
        .catch(error => {
          if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            const error_type = error.response.data.errors[0];
            if (error_type) alert(this.$t(`error.${error_type}`));
          } else error.errorHandler();
        });
    },
    clickViewCalculateReport() {
      this.openDialog([
        'TargetCampaignCalculateReportDialog',
        {
          campaignName: this.campaign.name,
          campaignId: this.campaign.id
        }
      ]);
    },
    openCopyWidgetScriptDialog() {
      this.openDialog([
        'TargetCampaignCopyWidgetScriptDialog',
        { campaignId: this.campaign.id }
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "tooltip": {
    "archived": "종료한 캠페인은 발송 상태를 변경할 수 없습니다."
  },
  "menu_tooltip": {
    "testing": "타겟 상태가 사용중 일 때 설정이 가능합니다.",
    "not_configured": "발송 설정 및 디자인 항목의 설정을 완료해야 상태를 변경할 수 있습니다.",
    "archived": "종료한 대량 발송 캠페인은 발송 상태를 변경할 수 없습니다.",
    "dispatch_date_passed": "발송 시점이 현재보다 과거이므로 발송 상태를 변경할 수 없습니다.",
    "realtime_campaign_is_not_calculable": "실시간 발송 캠페인은 재계산할 수 없습니다.",
    "review_preparing": "CREMA Review 서비스 결제 및 테스트 중 상태입니다.\n해당 항목은 쇼핑몰에 CREMA Review 서비스가\n라이브된 이후 정상적으로 사용 가능합니다.",
    "review_terminated": "CREMA Review 서비스 사용이 필요합니다.",
    "review_disabled": "크리마 리뷰 이용 중인 업체만 사용할 수 있습니다.",
    "not_found_inserted_url": "위젯이 삽입된 페이지를 찾을 수 없습니다.",
    "disabled_cart_popup_widget": "쇼핑몰 페이지에서 장바구니에 담기 버튼 클릭 시 위젯을 확인하실 수 있습니다."
  },
  "menu_item": {
    "calculate": "재계산",
    "recipients": "발송 대상 보기",
    "delete_campaign": "캠페인 삭제",
    "archive_campaign": "발송 종료",
    "copy_script": "스크립트 복사",
    "move_to_widget_inserted_url": "위젯으로 이동"
  },
  "error": {
    "disabled": "발송이 일시 정지 상태이므로 재계산할수 없습니다. '발송 관리'에서 발송 일시 정지를 해제한 뒤 재시도해주세요.",
    "next_cohort_is_empty": "아직 등록된 다음 발송이 없어 재계산할 수 없습니다.",
    "not_required": "재계산할 필요가 없습니다.",
    "not_calculable": "사용하지 않는 그룹이므로 계산할 수 없습니다."
  },
  "confirm_archive": "정말로 발송 종료하시겠습니까?",
  "recalculate_started": "캠페인 계산을 시작했습니다."
}
</i18n>
