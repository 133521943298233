<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="brandNotice.title"
    @close="close"
  >
    <template #body>
      <AppMarkdown :text="brandNotice.message" />
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'ReviewSettingsNoticeShowDialog',
  mixins: [DialogView],
  props: {
    brandNotice: {
      type: Object,
      required: true
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "공지사항 보기"
}
</i18n>
