<template>
  <AppDropdownMenuButton
    v-bind="mileageButtonProps"
    :tooltip="
      noTooltip
        ? null
        : {
            message: mileageButtonTooltip,
            info: mileageButtonTooltipInfo,
            maxWidth: '440px',
            textAlign: 'left'
          }
    "
  />
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import api from '@/lib/api';
import i18n from '@/lib/i18n';
import ReviewPointTypeHelper from '@/mixins/ReviewPointTypeHelper';
import ReviewPointType from '@/enums/ReviewPointType';
import AuthorGrade from '@/enums/AuthorGrade';
import MileageActionType from '@/enums/MileageActionType';
import LogType from '@/enums/LogType';
import PurchasePurpose from '@/enums/PurchasePurpose';

export default {
  name: 'ReviewReviewMileageButton',
  mixins: [ReviewPointTypeHelper],
  props: {
    review: {
      type: Object,
      required: true
    },
    noTooltip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('session', ['reviewSettings', 'currentBrand']),
    ...mapGetters('session', ['canSaveMileage', 'accountConfiguredForMileage']),
    ...mapGetters('session', ['isShopifyBrand']),
    crmButtonTooltip() {
      return i18n.te(`crm_tooltip.${this.currentBrand.shop_builder}`)
        ? i18n.t(`crm_tooltip.${this.currentBrand.shop_builder}`)
        : null;
    },
    crmUrl() {
      return this.review.brand_user ? this.review.brand_user.crm_url : null;
    },
    isNonmember() {
      return this.review.author_grade === AuthorGrade.NONMEMBER;
    },
    isExperiencePurpose() {
      return (
        this.reviewSettings.use_purchase_purpose_badge &&
        this.review.sub_order?.purpose === PurchasePurpose.EXPERIENCE
      );
    },
    isReviewPointDiscountCodeType() {
      return (
        this.reviewSettings.review_point_type === ReviewPointType.DISCOUNT_CODE
      );
    },
    isDiscountCodeMileageAllowed() {
      return !this.isNonmember || this.isReviewPointDiscountCodeType;
    },
    mileageButtonTooltipInfo() {
      if (
        !this.isDiscountCodeMileageAllowed ||
        !this.canSaveMileage ||
        this.isExperiencePurpose
      ) {
        return null;
      }

      return this.$t(
        this.review.mileage.gave_mileage_at
          ? 'mileage_tooltip.mileage_additional_info.given'
          : 'mileage_tooltip.mileage_additional_info.default'
      );
    },
    mileageButtonProps() {
      const props = {
        menuItems: [],
        disabled:
          !this.isDiscountCodeMileageAllowed ||
          !this.canSaveMileage ||
          this.isExperiencePurpose
      };

      const review = this.review;
      const {
        gave_mileage_at,
        gave_mileage_amount,
        admin_mileage_amount,
        calculated_mileage_amount
      } = review.mileage;

      if (!this.isDiscountCodeMileageAllowed || this.isExperiencePurpose) {
        props.label = this.$t('mileage.calculated_mileage', {
          amount: this.formatMileage('-')
        });
      } else if (gave_mileage_at) {
        props.label = this.$t('mileage.gave_mileage', {
          amount: this.formatMileage(gave_mileage_amount)
        });
        props.menuItems.push({
          label: this.$t('mileage.give_mileage_again'),
          clickHandler: this.giveMileage
        });
        props.menuItems.push({
          label: this.$t('mileage.deduct_mileage'),
          clickHandler: this.openMileageDeductDialog
        });
      } else {
        props.label =
          admin_mileage_amount !== null
            ? this.$t('mileage.admin_mileage', {
                amount: this.formatMileage(admin_mileage_amount)
              })
            : this.$t('mileage.calculated_mileage', {
                amount: this.formatMileage(calculated_mileage_amount)
              });
        props.buttonStyle = 'mango-outline';
        props.menuItems.push({
          label: this.$t('mileage.give_mileage'),
          clickHandler: this.giveMileage
        });
        props.menuItems.push({
          label: this.$t('mileage.edit_admin_mileage'),
          clickHandler: this.editAdminMileage
        });
      }

      if (this.crmUrl) {
        props.menuItems.push({
          label: this.$t('mileage.open_crm'),
          clickHandler: this.openCrm,
          tooltip: this.crmButtonTooltip
        });
      }

      if (this.isShopifyBrand) {
        props.menuItems = props.menuItems.filter(
          g => g.label !== this.$t('mileage.give_mileage_again')
        );
      }

      return props;
    },
    mileageButtonTooltip() {
      if (!this.isDiscountCodeMileageAllowed)
        return this.$t('mileage_tooltip.nonmember_review');
      if (this.isExperiencePurpose) {
        return this.$t('mileage_tooltip.experience_review');
      }

      const { default_reward_message, mileage, mileage_logs } = this.review;
      const tooltips = [];
      if (mileage.gave_mileage_at) {
        if (mileage.mileage_action_count > 1) {
          this.multipleGaveMileageTooltip(tooltips, mileage, mileage_logs);
        } else {
          this.gaveMileageTooltip(tooltips, mileage, default_reward_message);
        }
      } else if (mileage.admin_mileage_amount) {
        const user = mileage.admin_mileage_updated_user;
        if (user) {
          tooltips.push(
            this.$t('mileage_tooltip.admin_mileage_updated_by_user', {
              name: user.name,
              email: user.email,
              timestamp: this.formatDateTime(mileage.admin_mileage_updated_at),
              amount: this.formatMileage(mileage.admin_mileage_amount)
            })
          );
        } else {
          tooltips.push(
            this.$t('mileage_tooltip.admin_mileage_updated_by_unknown')
          );
        }
      } else {
        if (
          this.reviewSettings.review_launched_at &&
          this.review.created_at < this.reviewSettings.review_launched_at
        ) {
          tooltips.push(this.$t('mileage_tooltip.legacy_review'));
        } else if (!this.isShopifyBrand) {
          tooltips.push(mileage.calculated_mileage_reasons.join(', '));
        }
      }

      return tooltips.filter(v => v).join('\n');
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openCrm({ close }) {
      const doOpenCrm = () => {
        const WINDOW_SIZES = {
          cafe24: { width: 700, height: 640 },
          makeshop: { width: 1215, height: 806 },
          godo: { width: 800, height: 760 },
          wisa: { width: 1612, height: 970 }
        };
        const windowSize = WINDOW_SIZES[this.currentBrand.shop_builder];
        window.open(
          this.crmUrl,
          'crm',
          `scrollbars=1,width=${windowSize.width},height=${windowSize.height}`
        );
        api.post('/event', { event_type: 'crm_opened' });
        close();
      };

      const { mileage_message } = this.review.mileage;
      if (mileage_message) {
        this.$copyText(mileage_message).finally(doOpenCrm);
      } else {
        doOpenCrm();
      }
    },
    gaveMileageTooltip(tooltips, mileage, default_reward_message) {
      tooltips.push(
        this.$t('mileage_tooltip.gave_mileage', {
          amount: this.formatMileage(mileage.gave_mileage_amount)
        })
      );
      if (mileage.gave_mileage_action_type) {
        const action_type_message = MileageActionType.isAdminAction(
          mileage.gave_mileage_action_type
        )
          ? this.$t('mileage_tooltip.gave_mileage_action_type.admin')
          : this.$t('mileage_tooltip.gave_mileage_action_type.system');
        tooltips.push(action_type_message);
      }

      const gave_mileage_message = this.$t(
        'mileage_tooltip.gave_mileage_message',
        {
          message: mileage.gave_mileage_message
        }
      );
      if (mileage.gave_mileage_message === default_reward_message) {
        tooltips.push(
          this.$t('mileage_tooltip.gave_mileage_default_message', {
            message: gave_mileage_message
          })
        );
      } else {
        tooltips.push(gave_mileage_message);
      }

      if (!this.isShopifyBrand) {
        tooltips.push(mileage.gave_mileage_reason);
      }

      tooltips.push(
        this.$t('mileage_tooltip.gave_mileage_at', {
          timestamp: this.formatDateTime(mileage.gave_mileage_at)
        })
      );
    },
    multipleGaveMileageTooltip(tooltips, mileage, mileage_logs) {
      tooltips.push(
        this.$t('mileage_tooltip.total_gave_mileage', {
          amount: this.formatMileage(mileage.gave_mileage_amount)
        })
      );

      if (mileage_logs) {
        mileage_logs.forEach(log => {
          tooltips.push(
            this.$t('mileage_tooltip.mileage_log_info', {
              timestamp: this.formatDate(log.created_at),
              amount: this.formatMileage(log.amount_cents),
              type: this.mileageLogTypeText(log.log_type)
            })
          );
        });
      }

      tooltips.push(this.$t('mileage_tooltip.multiple_log_check_info'));
    },
    mileageLogTypeText(log_type) {
      if (log_type === LogType.GIVE_MILEAGE) {
        return this.$t('mileage_tooltip.gave_mileage_text');
      } else {
        return this.$t('mileage_tooltip.deducted_mileage_text');
      }
    },
    openMileageDeductDialog() {
      this.openDialog([
        'ReviewReviewsMileageDeductDialog',
        { review: this.review }
      ]);
    },
    giveMileage({ close }) {
      if (!this.accountConfiguredForMileage) {
        if (confirm(this.$t('reviews.set_brand_manager_confirm'))) {
          this.openDialog([
            'TheSettingsDialog',
            { initialTab: 'settings_dialog_user' }
          ]);
          close();
        }
        return;
      }

      this.openDialog([
        'ReviewReviewsMileageGiveDialog',
        { review: this.review }
      ]);
      if (close) close();
    },
    editAdminMileage({ close }) {
      this.openDialog([
        'ReviewReviewsMileageEditDialog',
        { review: this.review }
      ]);
      if (close) close();
    }
  }
};
</script>

<i18n locale="ko">
{
  "crm_tooltip": {
    "cafe24": "CRM 접속 전 카페24 관리자 페이지에 로그인해주세요.",
    "makeshop":"CRM 접속 전 메이크샵 관리자 페이지에 로그인해주세요."
  },
  "mileage": {
    "gave_mileage": "{amount} 지급 완료",
    "admin_mileage": "직접 입력: {amount}",
    "calculated_mileage": "예상 {point_type}: {amount}",
    "give_mileage": "예상 {point_type} 지급",
    "edit_admin_mileage": "예상 {point_type} 수정",
    "give_mileage_again": "{point_type} 추가 지급",
    "deduct_mileage": "{point_type} 차감",
    "open_crm": "쇼핑몰 CRM 접속",
    "confirm_cancel": "{point_type} 지급을 취소하시겠습니까?",
    "confirm_unmark": "지급표시를 삭제하시겠습니까?",
    "cancelled": "{user}에게 지급했던 {amount}의 {point_type}((을)) 취소하였습니다.",
    "unmarked": "{user}에게 지급했던 {amount}의 {point_type}의 지급표시를 삭제하였습니다.",
    "cancel_failed": "{user}에게 지급했던 {point_type}((을)) 취소하지 못했습니다.\n\n부운영자 계정을 확인해주세요. 계정이 정상적으로 입력되어 있는데도 계속 문제가 발생하면 다음 연락처로 문의해주세요:\n{customer_support}"
  },
  "mileage_tooltip": {
    "gave_mileage_action_type": {
      "admin": "관리자 수동 지급",
      "system": "시스템 자동 지급"
    },
    "gave_mileage_text": "지급",
    "deducted_mileage_text": "차감",
    "gave_mileage": "{point_type} {amount} 지급됨",
    "total_gave_mileage": "총 지급액 {amount}\n",
    "gave_mileage_message": "지급 사유: {message}",
    "gave_mileage_default_message": "{message}의 리뷰 작성",
    "gave_mileage_at": "지급 일시: {timestamp}",
    "mileage_log_info": "{timestamp} {point_type} {amount} {type}",
    "admin_mileage_updated_by_user": "[{name}]님이 임의 수정\n시간: {timestamp}\n이메일: {email}\n수정{point_type}: {amount}",
    "admin_mileage_updated_by_unknown": "임의로 수정된 {point_type}",
    "legacy_review": "CREMA를 사용하기 전에 작성된 리뷰",
    "nonmember_review": "비회원 리뷰는 {point_type} 지급이 불가능합니다.",
    "experience_review": "체험 목적의 리뷰는 {point_type} 지급이 불가능합니다.",
    "multiple_log_check_info": "3개월 이전, 10건 이상의 내역은 쇼핑몰 솔루션에서 확인 가능\n",
    "mileage_additional_info":{
      "given": "버튼을 클릭해서 {point_type} 차감 및 추가 지급 하기",
      "default": "버튼을 클릭해서 {point_type} 수정 및 지급 하기"
    }
  }
}
</i18n>
