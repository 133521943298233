const VAT_RATE = 0.1;

export default {
  VAT_RATE,
  priceWithVat(price) {
    return Math.floor(price * (1 + VAT_RATE));
  },
  originalPrice(priceWithVat) {
    return Math.ceil(priceWithVat / (1 + VAT_RATE));
  },
  vat(priceWithVat) {
    return priceWithVat - this.originalPrice(priceWithVat);
  }
};
