<template>
  <div>
    <DisplayCategoryCornersSearchbar @search="search($event)" />
    <DisplayCornersTable
      :search-type="searchType"
      :search-query="searchQuery"
    />
  </div>
</template>

<script>
export default {
  name: 'DisplayCategoryPageCorners',
  data() {
    return { searchType: '', searchQuery: '' };
  },
  methods: {
    search(searchWithType) {
      this.searchType = searchWithType.search_type;
      this.searchQuery = searchWithType.search_query;
    }
  }
};
</script>
