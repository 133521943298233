<template>
  <div>
    <div v-tooltip="editedAtTooltip">
      <div class="table-line TargetCampaignsTableEditedAtCell__date">
        {{ campaign.edited_at | formatFromNow }}
      </div>
      <div class="table-line table-line--mt4">
        <AppBadge :label="$t('app.detail')" />
      </div>
    </div>
  </div>
</template>

<script>
import TargetCampaignType from '@/enums/TargetCampaignType';

export default {
  name: 'TargetCampaignsTableEditedAtCell',
  props: { campaign: { type: Object, required: true } },
  computed: {
    editedAtTooltip() {
      const { formatDateTime } = this.$options.filters;
      const { last_sent_at } = this.campaign;
      const texts = [
        `${this.$t('target_campaign.edited_at')}: ${formatDateTime(
          this.campaign.edited_at
        )}`,
        `${this.$t('target_campaign.created_at')}: ${formatDateTime(
          this.campaign.created_at
        )}`
      ];

      if (TargetCampaignType.isRealtime(this.campaign.campaign_type))
        texts.push(this.$t('tooltip.realtime_campaign'));
      else if (last_sent_at)
        texts.push(
          `${this.$t('target_campaign.last_sent_at')}: ${formatDateTime(
            last_sent_at
          )}`
        );

      return texts.join('\n');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TargetCampaignsTableEditedAtCell__date {
  color: $color-grey-70;
}
</style>

<i18n locale="ko">
{
  "tooltip": {
    "realtime_campaign": "실시간 발송 캠페인입니다."
  }
}
</i18n>
