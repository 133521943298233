<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        Small
      </div>
      <AppColorChip size="small" :colors="['#4a4a4a']" />
      <AppColorChip size="small" :colors="['#4a4a4a', '#8b572a']" />
      <AppColorChip size="small" :colors="['#4a4a4a', '#eeeeee', '#8b572a']" />
      <AppColorChip size="small" :colors="['#ffffff']" />
      <AppColorChip size="small" :colors="['#eeeeee']" />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        Medium
      </div>
      <AppColorChip size="medium" :colors="['#4a4a4a']" />
      <AppColorChip size="medium" :colors="['#4a4a4a', '#8b572a']" />
      <AppColorChip size="medium" :colors="['#4a4a4a', '#eeeeee', '#8b572a']" />
      <AppColorChip size="medium" :colors="['#ffffff']" />
      <AppColorChip size="medium" :colors="['#eeeeee']" />
    </div>
  </div>
</template>

<script>
export default { name: 'UiColorChip' };
</script>

<style scoped>
.UiColorChip__small {
  font-size: 14px;
}
</style>
