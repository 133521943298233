<template>
  <div @mousemove="mousemove">
    <AppHighcharts :options="chartOptions" :callback="onChartLoaded" />
    <AppChartTooltip
      v-if="isTooltipVisible && tooltip && tooltipPosition"
      v-bind="tooltip"
      :position="tooltipPosition"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import VTooltip from '@/directives/v-tooltip';
import { mapState } from 'vuex';

export default {
  name: 'AppChart',
  props: {
    options: { type: Object, required: true },
    tooltip: { type: Object, default: undefined }
  },
  data: function() {
    const defaultChartOptions = {
      chart: { styledMode: true },
      credits: { enabled: false },
      exporting: { enabled: false },
      xAxis: {
        dateTimeLabelFormats: {
          day: '%m. %d.',
          week: '%m. %d.',
          month: '%Y. %m.'
        }
      }
    };

    if (this.tooltip !== undefined)
      defaultChartOptions.plotOptions = {
        series: {
          point: {
            events: {
              mouseOver: ({ target }) => {
                this.isTooltipVisible = true;
                this.$emit('tooltip', target);
              },
              mouseOut: () => (this.isTooltipVisible = false)
            }
          }
        }
      };

    return {
      tooltipPosition: null,
      defaultChartOptions,
      isTooltipVisible: false
    };
  },
  computed: {
    ...mapState('editor', ['isSubMenuVisibleDesktop']),
    chartOptions() {
      return _.merge({}, this.defaultChartOptions, this.options);
    }
  },
  watch: {
    isSubMenuVisibleDesktop() {
      clearTimeout(this.timeout);

      const TRANSITION_DURATION = 250;
      this.timeout = setTimeout(() => {
        this.$children[0].chart.reflow();
        this.timeout = null;
      }, TRANSITION_DURATION);
    }
  },
  methods: {
    mousemove({ offsetX, offsetY }) {
      if (this.tooltip === undefined) return;

      const { x, y } = this.$el.getBoundingClientRect();
      this.tooltipPosition = { x: offsetX + x, y: offsetY + y };
    },
    onChartLoaded(chart) {
      this.addLegendTooltip(chart);
    },
    addLegendTooltip(chart) {
      const legendItemElements = chart.series.map(s => s.legendItem?.element);

      this.options.series.forEach((s, i) => {
        if (!s.legendTooltip) return;

        const legendItemElement = legendItemElements[i];
        if (!legendItemElement) return;

        VTooltip.bind(legendItemElement, { value: s.legendTooltip });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

::v-deep {
  @import '@/scss/highcharts.scss';

  .highcharts-container {
    font-family: inherit;
  }

  .highcharts-series-inactive {
    opacity: 1;
  }

  .highcharts-markers {
    stroke-width: 0;
  }

  .highcharts-point {
    stroke-width: 0;
  }

  .highcharts-column-series:not(.highcharts-legend-item) rect.highcharts-point {
    stroke-width: 1px;
  }

  .highcharts-legend-item {
    cursor: pointer;

    & > text {
      @include text-caption-small-dark;
      fill: $color-grey-60;
    }
  }

  .highcharts-xaxis-labels {
    @include text-caption-small-darker;
    fill: $color-grey-80;
  }

  .highcharts-yaxis-labels {
    @include text-caption-small;
    fill: $color-grey-50;
  }
}
</style>
