import { constructEnum } from '@/lib/enum';

const ChannelTransactionType = constructEnum('ChannelTransactionType', {
  FREE_TRIAL: 5,
  PAYMENT: 10,
  STARTED: 11,
  PERIOD_BONUS: 45,
  EXPIRED: 50,
  RETURN: 60,
  TERMINATED: 70,
  ETC: 100,
  SUPER_ADMIN_ETC: 110
});

ChannelTransactionType.badgeStyle = function(transactionType) {
  switch (transactionType) {
    case ChannelTransactionType.FREE_TRIAL:
    case ChannelTransactionType.PERIOD_BONUS:
      return 'blue-outline';
    case ChannelTransactionType.PAYMENT:
    case ChannelTransactionType.STARTED:
      return 'blue';
    case ChannelTransactionType.EXPIRED:
      return 'red-outline';
    case ChannelTransactionType.RETURN:
    case ChannelTransactionType.TERMINATED:
      return 'red';
    default:
      return 'default';
  }
};

export default ChannelTransactionType;
