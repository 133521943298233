<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="subTitle"
    width="default"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <template v-if="sections.length">
          <section
            v-for="(section, i) in sections"
            :key="`section_${i}`"
            class="TargetCampaignCalculateReportDialog__section"
          >
            <div class="TargetCampaignCalculateReportDialog__label">
              {{ section.title }}
            </div>
            <div
              v-for="(subSection, j) in section.subSections"
              :key="`sub_section_${j}`"
              class="TargetCampaignCalculateReportDialog__sub-section"
            >
              <div>{{ subSection.title }}</div>
              <div>{{ subSection.content }}</div>
            </div>
          </section>
        </template>
        <div v-else>{{ $t('no_report') }}</div>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';
import api from '@/lib/api';

export default {
  name: 'TargetCampaignCalculateReportDialog',
  mixins: [DialogView],
  props: {
    campaignName: { type: String, required: true },
    campaignId: { type: Number, default: null },
    cohortId: { type: Number, default: null }
  },
  data: () => ({ isLoading: true, calculateReport: null }),
  computed: {
    subTitle() {
      return `${this.campaignName}${
        this.cohortId ? this.$t('cohort_id', [this.cohortId.toString()]) : ''
      }`;
    },
    sections() {
      if (!this.calculateReport) return [];

      const lines = this.calculateReport.split(/\n+/);
      const sections = [];
      let section = null;
      lines.forEach(line => {
        const isSubSection = line.startsWith(' - ');
        if (isSubSection) {
          const [title, content] = line.replace(/^ - /, '').split(': ');
          const subSection = { title, content };
          section.subSections.push(subSection);
        } else {
          if (section) sections.push(section);
          section = { title: line, subSections: [] };
        }
      });
      if (section) sections.push(section);

      return sections;
    }
  },
  mounted() {
    let url = null;
    if (this.campaignId)
      url = `/target/campaigns/${this.campaignId}/calculate_report`;
    else url = `/target/cohorts/${this.cohortId}/calculate_report`;

    api
      .get(url)
      .then(({ data }) => (this.calculateReport = data.calculate_report))
      .finally(() => (this.isLoading = false))
      .catch(() => {
        alert(this.$t('error_loading'));
        this.close();
      });
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetCampaignCalculateReportDialog {
  @include text-content;
}

.TargetCampaignCalculateReportDialog__section {
  & + & {
    margin-top: 32px;
  }
}

.TargetCampaignCalculateReportDialog__label {
  @include text-label;
  margin-bottom: 10px;
}

.TargetCampaignCalculateReportDialog__sub-section {
  & + & {
    margin-top: 16px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "발송 대상",
  "cohort_id": " (발송 ID {0})",
  "no_report": "집계 결과 없음",
  "error_loading": "집계 결과를 불러올 수 없습니다."
}
</i18n>
