<template>
  <AppModalDefault :title="settingName" width="wide" @close="close">
    <template #body>
      <div class="TheLogsDialogSettingDiffDialog__containter">
        <div class="TheLogsDialogSettingDiffDialog__detail">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="old_setting_html" />
        </div>
        <div class="TheLogsDialogSettingDiffDialog__detail">
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="new_setting_html" />
        </div>
      </div>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';
import Focusable from '@/mixins/Focusable';

export default {
  name: 'TheLogsDialogSettingDiffDialog',
  mixins: [DialogView, Focusable],
  props: {
    logId: {
      type: Number,
      required: true
    },
    settingName: {
      type: String,
      required: true
    },
    logType: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      old_setting_html: '',
      new_setting_html: ''
    };
  },
  created() {
    const url = '/logs/setting_diff';
    api
      .get(url, {
        params: {
          id: this.logId,
          setting_name: this.settingName,
          type: this.logType
        }
      })
      .then(({ data }) => {
        this.old_setting_html = data.old_setting_html;
        this.new_setting_html = data.new_setting_html;
      });
  }
};
</script>

<style>
.TheLogsDialogSettingDiffDialog__containter {
  display: flex;
  justify-content: space-evenly;
}

.TheLogsDialogSettingDiffDialog__detail {
  width: 48%;
  border: solid 1px black;
}

.diff {
  overflow: auto;
  height: 100%;
}

.diff ul {
  background: #fff;
  overflow: auto;
  font-size: 13px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  width: 100%;
}

.diff del,
.diff ins {
  display: block;
  text-decoration: none;
}

.diff li {
  padding: 0;
  display: table-row;
  margin: 0;
  height: 1em;
}

.diff li.ins {
  background: #dfd;
  color: #080;
}

.diff li.del {
  background: #fee;
  color: #b00;
}

/* try 'whitespace:pre;' if you don't want lines to wrap */
.diff del,
.diff ins,
.diff span {
  white-space: pre-wrap;
  font-family: courier;
}

.diff del strong {
  font-weight: normal;
  background: #fcc;
}

.diff ins strong {
  font-weight: normal;
  background: #9f9;
}

.diff li.diff-comment {
  display: none;
}

.diff li.diff-block-info {
  background: none repeat scroll 0 0 gray;
}
</style>
