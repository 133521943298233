<template>
  <div class="TargetCampaignStatsChart">
    <AppChart :options="chartOptions" :tooltip="tooltip" @tooltip="onTooltip" />
  </div>
</template>

<script>
import ReportsView from '@/mixins/ReportsView';

export default {
  name: 'TargetCampaignStatsChart',
  mixins: [ReportsView],
  props: {
    chartHeight: { type: String, required: true },
    seriesColumns: { type: Array, required: true }
  },
  data: () => ({ tooltip: null }),
  computed: {
    chartOptions() {
      return {
        chart: { height: this.chartHeight, spacing: [-4, 0, 0, 0] },
        title: { text: '' },
        plotOptions: {
          series: {
            states: { hover: { lineWidthPlus: 0, halo: { size: 0 } } },
            marker: { enabled: false, symbol: 'circle', radius: 2 }
          }
        },
        series: this.seriesColumns.map((column, index) => ({
          unit: column.type === 'percentage' ? 'percentage' : null,
          data: this.chartData(column.id, column.type),
          name: column.name,
          className: column.dotted ? 'highcharts-series-dotted' : null,
          colorIndex: column.colorIndex || index
        })),
        legend: {
          align: 'right',
          verticalAlign: 'top',
          symbolHeight: 8,
          symbolWidth: 8
        },
        xAxis: {
          crosshair: { dashStyle: 'solid' },
          type: 'datetime'
        },
        yAxis: { title: { text: '' } },
        tooltip: { enabled: false }
      };
    }
  },
  methods: {
    onTooltip({ x, index, series }) {
      this.tooltip = {
        title: this.getFormattedDate(new Date(x)),
        items: series.chart.series.map(
          ({ data, name, colorIndex, userOptions }) => ({
            symbol: 'circle',
            name,
            colorIndex,
            value:
              userOptions.unit === 'percentage'
                ? `${this.$t('unit.percentage', [data[index].y])}`
                : data[index].y.toLocaleString()
          })
        )
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

@mixin highcharts-color($num, $color) {
  .highcharts-color-#{$num} {
    fill: $color;
    stroke: $color;
  }
}

::v-deep {
  .highcharts-series-dotted {
    stroke-dasharray: 8, 6;
  }

  @include highcharts-color(0, $color-grey-50);
  @include highcharts-color(1, $color-blue);
  @include highcharts-color(2, $color-blue-light);
}
</style>
