import { constructEnum } from '@/lib/enum';

const DisplayTransactionType = constructEnum('DisplayTransactionType', {
  FREE_TRIAL: 5,
  PAYMENT: 10,
  STARTED: 11,
  PERIOD_BONUS: 45,
  EXPIRED: 50,
  RETURN: 60,
  TERMINATED: 70,
  ETC: 100,
  SUPER_ADMIN_ETC: 110
});

DisplayTransactionType.badgeStyle = function(transactionType) {
  switch (transactionType) {
    case DisplayTransactionType.FREE_TRIAL:
    case DisplayTransactionType.PERIOD_BONUS:
      return 'blue-outline';
    case DisplayTransactionType.PAYMENT:
    case DisplayTransactionType.STARTED:
      return 'blue';
    case DisplayTransactionType.EXPIRED:
      return 'red-outline';
    case DisplayTransactionType.RETURN:
    case DisplayTransactionType.TERMINATED:
      return 'red';
    default:
      return 'default';
  }
};

export default DisplayTransactionType;
