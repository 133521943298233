var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppResourceTable',{attrs:{"enable-total-count":false,"columns":_vm.columns,"rows":_vm.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [(column === 'usage_status')?[_c('AppSwitch',{attrs:{"name":("widget[" + (row.key) + "]"),"disabled":!_vm.widget.show_header_section},on:{"change":function($event){return _vm.$emit('change', row.key)}},model:{value:(_vm.widget[row.key]),callback:function ($$v) {_vm.$set(_vm.widget, row.key, $$v)},expression:"widget[row.key]"}})]:(column === 'type')?[_c('div',{class:[
          'AppForm__sub-group-title',
          {
            'AppForm__sub-group-title--disabled': !(
              _vm.widget.show_header_section && _vm.widget[row.key]
            )
          }
        ]},[_vm._v(" "+_vm._s(row.type)+" ")])]:(column === 'sub_settings')?[_c('div',{class:[
          'ReviewSettingsShowHeaderSection__sub-settings',
          {
            'ReviewSettingsShowHeaderSection__sub-settings--direction-row': _vm.isDirectionRow(
              row.key
            )
          }
        ]},_vm._l((row.sub_settings),function(subSettingName){return _c('ReviewSettingsShowHeaderSectionSubSetting',_vm._b({key:subSettingName,on:{"change":function($event){return _vm.$emit('change', subSettingName)},"blur":function($event){return _vm.$emit('blur', subSettingName)}}},'ReviewSettingsShowHeaderSectionSubSetting',{ subSettingName: subSettingName, widget: _vm.widget, group: _vm.group },false))}),1)]:_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }