<template>
  <AppModal>
    <template #head>
      <div class="TheWelcomeTutorialDialog__head">
        <div class="TheWelcomeTutorialDialog__head-wrapper">
          <div class="TheWelcomeTutorialDialog__head-title-wrapper">
            <span class="TheWelcomeTutorialDialog__head-title">{{
              $t('title')
            }}</span>
            <div class="TheWelcomeTutorialDialog__head-sub-title">
              <i18n path="sub_title">
                <br />
              </i18n>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div class="TheWelcomeTutorialDialog__body">
        <TheWelcomeTutorialItem
          v-for="(item, index) in items"
          :key="index"
          :title="$t(`items.${item}.title`)"
          :sub-title="$t(`items.${item}.sub_title`)"
          :icon="$t(`items.${item}.icon`)"
        />
      </div>
    </template>
    <template #foot>
      <div class="TheWelcomeTutorialDialog__footer">
        <AppButton
          button-style="blue"
          :label="$t('button.continue')"
          @click="clickContinueButton"
        />
        <AppButton :label="$t('button.none')" @click="close" />
      </div>
    </template>
  </AppModal>
</template>

<script>
import api from '@/lib/api';
import TutorialStatus from '@/enums/TutorialStatus';
import DialogView from '@/mixins/DialogView';
import TheWelcomeTutorialItem from './TheWelcomeTutorialItem';

export default {
  name: 'TheWelcomeTutorialDialog',
  components: { TheWelcomeTutorialItem },
  mixins: [DialogView],
  data() {
    return {
      items: [
        'biz_message',
        'review_settings_popup_text',
        'review_mileage',
        'review_import'
      ]
    };
  },
  methods: {
    clickContinueButton() {
      api
        .patch('/tutorial/settings', {
          tutorial_status: TutorialStatus.WELCOME_COMPLETE_PROGRESS
        })
        .then(() => this.close());
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

::v-deep .AppModal__wrapper {
  width: 461px;
  border-radius: 8px;
  padding: 28px 34px;
}

.TheWelcomeTutorialDialog__head-wrapper {
  position: relative;
  display: flex;
  padding: 12px 0 18px 0;
}

.TheWelcomeTutorialDialog__head-title-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.TheWelcomeTutorialDialog__head-title {
  @include text-modal-title;
}

.TheWelcomeTutorialDialog__head-sub-title {
  @include text-content;

  padding-top: 8px;
}

.TheWelcomeTutorialDialog__body {
  padding: 8px 0;
}

.TheWelcomeTutorialDialog__footer {
  padding-top: 42px;
}
</style>

<i18n locale="ko">
{
  "title": "크리마 리뷰 사용을 환영합니다",
  "sub_title": "크리마 리뷰 활용에 유용한 기능 설정법을 안내드릴게요.{0}'계속' 버튼을 클릭해 주세요.",
  "items": {
    "biz_message": {
      "title": "알림톡 발송",
      "sub_title": "알림톡으로 쉽고 빠르게 리뷰를 수집하세요.",
      "icon": "📱"
    },
    "review_settings_popup_text": {
      "title": "리뷰 작성 팝업 문구",
      "sub_title": "쇼핑몰 컨셉에 맞는 문구를 자유롭게 설정하세요.",
      "icon": "📝"
    },
    "review_mileage": {
      "title": "리뷰 작성 적립금 금액",
      "sub_title": "적절한 적립금으로 더 많은 고객 리뷰를 끌어내세요.",
      "icon": "💵"
    },
    "review_import": {
      "title": "쇼핑몰 외부 리뷰 연동",
      "sub_title": "지그재그 등 다양한 채널의 리뷰를 쇼핑몰에 모아보세요!",
      "icon": "🖇️"
    }
  },
  "button": {
    "continue": "계속",
    "none": "나중에 하기"
  }
}
</i18n>
