<template>
  <div class="BizMessageTemplateButtons">
    <AppButton
      v-if="!readonly"
      :label="$t('add_button')"
      :disabled="disabled || 5 <= buttons.length"
      @click="$emit('add')"
    />
    <AppTable
      :columns="columns"
      :rows="rows"
      :class="[
        'mt8',
        { 'BizMessageTemplateButtons__table--disabled': disabled }
      ]"
    >
      <template #cell="{ column, rowIndex }">
        <template v-if="column.id === 'actions'">
          <AppButtonDraggable
            :drag-text="$t('app.sort')"
            v-bind="sortButtonProps(rowIndex)"
            :disabled="disabled"
            @sort="$emit('sort', $event)"
          />
          <AppDropdownMenuButton
            :label="$t('app.manage')"
            :menu-items="[
              {
                label: $t('app.edit'),
                clickHandler: ({ close }) => $emit('edit', { close, rowIndex })
              },
              {
                label: $t('app.delete'),
                style: 'danger',
                clickHandler: ({ close }) =>
                  $emit('delete', { close, rowIndex })
              }
            ]"
            :disabled="disabled"
          />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import BizMessageTemplateButtonType from '@/enums/BizMessageTemplateButtonType';

export default {
  name: 'BizMessageTemplateButtons',
  props: {
    buttons: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    columns() {
      return [
        { id: 'position', label: this.$t('app.position'), width: '40px' },
        { id: 'button_type', label: this.$t('button_type'), width: '80px' },
        { id: 'name', label: this.$t('name'), align: 'left' },
        {
          id: 'links',
          label: this.$t('links'),
          type: 'badges',
          width: this.readonly ? undefined : '126px',
          align: 'left'
        },
        this.readonly
          ? null
          : { id: 'actions', label: this.$t('app.actions'), width: '150px' }
      ].filter(v => !!v);
    },
    rows() {
      return this.buttons.map((button, i) => {
        return {
          id: button.id,
          position: i + 1,
          button_type: BizMessageTemplateButtonType.t(button.button_type),
          name: button.name,
          links: this.linkBadges(button)
        };
      });
    }
  },
  methods: {
    sortButtonProps(rowIndex) {
      return this.buttons[rowIndex].button_type ===
        BizMessageTemplateButtonType.ADD_CHANNEL
        ? {
            disabled: true,
            tooltip: this.$t('sort_button_tooltip_add_channel')
          }
        : {};
    },
    linkBadges(button) {
      switch (button.button_type) {
        case BizMessageTemplateButtonType.APP_LINK:
          return [
            { label: this.$t('pc'), tooltip: button.pc_link },
            { label: this.$t('mobile'), tooltip: button.mobile_link },
            { label: this.$t('android'), tooltip: button.android_link },
            { label: this.$t('ios'), tooltip: button.ios_link }
          ].filter(e => !!e.tooltip);
        case BizMessageTemplateButtonType.WEB_LINK:
          return [
            { label: this.$t('pc'), tooltip: button.pc_link },
            { label: this.$t('mobile'), tooltip: button.mobile_link }
          ];
        default:
          return [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

::v-deep .AppTable__badges {
  display: inline-block;
}

.BizMessageTemplateButtons__table--disabled {
  color: $color-disable-text;
}
</style>

<i18n locale="ko">
{
  "add_button": "+ 버튼 추가",
  "button_type": "버튼 타입",
  "name": "버튼명",
  "links": "버튼 링크",
  "sort_button_tooltip_add_channel": "채널 추가 버튼은 첫번째 버튼으로만 사용이 가능합니다.",
  "pc": "PC",
  "mobile": "모바일",
  "android": "안드로이드",
  "ios": "iOS"
}
</i18n>
