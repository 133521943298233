<template>
  <AppModalForm
    :title="$t('title', [product.index + 1])"
    :form-props="{ ...formProps, submitLabel: $t('submit') }"
    v-on="formEvents"
  >
    <DisplayProducts
      column-type="select"
      @select-product="updateSelectedProduct"
    />
  </AppModalForm>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import DisplayCornerProductMarkedStatus from '@/enums/DisplayCornerProductMarkedStatus';

export default {
  name: 'DisplayCornerReplaceProductDialog',
  mixins: [DialogFormView],
  props: { product: { type: Object, required: true } },
  computed: {
    formSections() {
      return [{ groups: [] }];
    }
  },
  methods: {
    ...mapActions('displayProductDisplay', [
      'addProducts',
      'removeProducts',
      'clearMarkedProduct'
    ]),
    ...mapMutations('displayProductDisplay', ['SET_MARKED_PRODUCT']),
    updateSelectedProduct(product) {
      this.formObject = { product };
    },
    submit() {
      const index = this.product.index;

      this.removeProducts([index]);
      this.addProducts({ index, products: [this.formObject.product] });
      this.SET_MARKED_PRODUCT({
        index,
        markedStatus: DisplayCornerProductMarkedStatus.REPLACED
      });

      setTimeout(() => {
        this.clearMarkedProduct(index);
      }, 3000);

      this.orgFormObject = this.formObject;
      this.close();
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "{0}번 위치에 대신 진열할 상품 선택",
  "submit": "상품 선택"
}
</i18n>
