<template>
  <AppDrawer
    :title="$t('title')"
    :sub-title="productManagement.name"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppInfoBox class="DisplayProductManagementCohortsDrawer__info-box">{{
          $t('info')
        }}</AppInfoBox>
        <AppResourceTable
          class="DisplayProductManagementCohortsDrawer__table"
          :columns="columns"
          :resources="cohorts"
          :rows="cohorts.items"
          :enable-total-count="false"
          @paginate="paginate"
        >
          <template #cell="{ column, row }">
            <template v-if="['category', 'icon', 'widget'].includes(column)">
              <template v-if="row[`use_${column}_management`]">
                <template v-if="isErrored(row)">
                  <div>
                    {{ $t('errored') }}
                  </div>
                  <AppBadge
                    :label="$t('app.detail')"
                    :tooltip="failedTooltip(row, column)"
                  />
                </template>
                <template v-else>
                  {{ productCodesText(row, column) }}
                </template>
              </template>
              <template v-else>-</template>
            </template>
            <template v-else-if="column === 'actions'">
              <AppButton
                :label="$t('button.detail')"
                :disabled="isOutdated(row.apply_at)"
                :tooltip="isOutdated(row.apply_at) ? $t('tooltip.detail') : ''"
                @click="openDetailDialog(row)"
              />
            </template>
          </template>
        </AppResourceTable>
      </AppAjaxContent>
    </template>
  </AppDrawer>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import api from '@/lib/api';
import DrawerView from '@/mixins/DrawerView';
import { nullResources } from '@/lib/resources';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import DisplayProductManagementCohortReportCalculator from '@/mixins/DisplayProductManagementCohortReportCalculator';
import DisplayProductManagementExecuteStatus from '@/enums/DisplayProductManagementExecuteStatus';
import ShopBuilder from '@/enums/ShopBuilder';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'DisplayProductManagementCohortsDrawer',
  mixins: [
    DrawerView,
    ResourceViewNoRoute,
    DisplayProductManagementCohortReportCalculator
  ],
  props: { productManagement: { type: Object, required: true } },
  data: () => ({
    isLoading: true,
    cohorts: nullResources
  }),
  computed: {
    ...mapState('session', ['currentBrand']),
    columns() {
      return [
        { id: 'id', label: this.$t('app.id'), width: '48px', superAdmin: true },
        { id: 'apply_at', type: 'datetime_hm', width: '152px' },
        { id: 'category', width: '176px' },
        { id: 'icon', width: '176px' },
        { id: 'widget', width: '176px' },
        { id: 'actions', label: this.$t('app.actions'), width: '102px' }
      ].map(column => ({
        ...column,
        label: column.label
          ? column.label
          : this.$t(`table.column.${column.id}`)
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('display/product_management/cohorts', {
          params: {
            ...params,
            product_management_id: this.productManagement.id
          }
        })
        .then(({ data }) => (this.cohorts = data.cohorts))
        .finally(() => (this.isLoading = false));
    },
    isOutdated(appliedAt) {
      return moment(appliedAt).add('month', 1) < moment();
    },
    failedTooltip(row, column) {
      var addedProductCodeCount = this.productCodes(
        row.report,
        column,
        'added_product_codes'
      ).length;
      var removedProductCodeCount = this.productCodes(
        row.report,
        column,
        'removed_product_codes'
      ).length;
      var failedProductCodeCount = this.productCodes(
        row.report,
        column,
        'failed_product_codes'
      ).length;
      var text_key =
        addedProductCodeCount ||
        failedProductCodeCount ||
        failedProductCodeCount
          ? 'tooltip.failed_products_count'
          : 'tooltip.none';
      return this.$t(text_key, [
        addedProductCodeCount,
        removedProductCodeCount,
        failedProductCodeCount
      ]);
    },
    productCodesText(row, column) {
      return this.$t('table.products_count', [
        this.productCodes(row.report, column, 'added_product_codes').length,
        this.productCodes(row.report, column, 'removed_product_codes').length
      ]);
    },
    isErrored(cohort) {
      return DisplayProductManagementExecuteStatus.isErrored(
        cohort.execute_status
      );
    },
    openDetailDialog(cohort) {
      this.isErrored(cohort)
        ? this.openErrorDetailDialog(cohort)
        : this.openCohortDialog(cohort);
    },
    openCohortDialog(cohort) {
      return this.openDialog([
        'DisplayProductManagementCohortDialog',
        { productManagementCohortId: cohort.id }
      ]);
    },
    openErrorDetailDialog(cohort) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('error_detail.title'),
          markdownText: this.errorMessage(cohort),
          width: DialogSize.SMALL
        }
      ]);
    },
    errorMessage(cohort) {
      const executeStatus = cohort.execute_status;
      let argument;
      switch (executeStatus) {
        case DisplayProductManagementExecuteStatus.ERROR_PREPARING:
        case DisplayProductManagementExecuteStatus.ERROR_CALCULATING:
        case DisplayProductManagementExecuteStatus.ERROR_DISPATCHING:
          argument = this.productManagement.name;
          break;
        case DisplayProductManagementExecuteStatus.ERROR_LOGIN_BLOCKED:
        case DisplayProductManagementExecuteStatus.ERROR_SESSION_EXPIRED:
          argument = ShopBuilder.tk(this.currentBrand.shop_builder);
          break;
        case DisplayProductManagementExecuteStatus.ERROR_TIMEOUT:
          break;
      }

      return this.$t(
        `error_detail.${DisplayProductManagementExecuteStatus.key(
          executeStatus
        )}`,
        [argument]
      );
    }
  }
};
</script>

<style scoped>
.DisplayProductManagementCohortsDrawer__info-box {
  margin-top: 24px;
}

.DisplayProductManagementCohortsDrawer__table {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "title": "상품 자동 관리 이력",
  "info": "해당 설정을 통해 관리된 이력을 보여줍니다. 상세 내역 조회는 최근 1개월만 확인 가능합니다.",
  "table": {
    "column": {
      "apply_at": "자동 관리 실행일",
      "category": "가상 카테고리 이동",
      "icon": "상품에 아이콘 표시",
      "widget": "빠른 배송 안내 위젯 표시"
    },
    "products_count": "추가: {0} / 제외: {1}"
  },
  "button": {
    "detail": "상세 내역"
  },
  "tooltip": {
    "detail": "상세 내역 조회는 최근 1개월만 가능합니다.",
    "failed_products_count": "추가: {0} / 제외: {1} / 실패: {2}",
    "none": "관리 대상 상품이 없습니다."
  },
  "errored": "실패",
  "error_detail": {
    "title": "상품 자동 관리 중 문제가 발생했습니다.",
    "ERROR_PREPARING": "데이터 동기화 중 오류가 발생하였습니다.<br>설정, 권한 등을 확인 하시고 {0}((를)) 다시 시도하시기 바랍니다.<br>오류가 반복적으로 발생할 경우 진열 운영팀으로 문의하시기 바랍니다.",
    "ERROR_CALCULATING": "데이터 계산 중 일시적인 오류가 발생하였습니다.<br>잠시 후 {0}((를)) 다시 시도하시기 바랍니다.<br>오류가 반복적으로 발생할 경우 진열 운영팀으로 문의하시기 바랍니다.",
    "ERROR_DISPATCHING": "자동관리 결과 적용 중 오류가 발생하였습니다.<br>잠시 후 {0}((를)) 다시 시도하시기 바랍니다.<br>오류가 반복적으로 발생할 경우 진열 운영팀으로 문의하시기 바랍니다.",
    "ERROR_TIMEOUT": "알 수 없는 이유로 일시적인 오류가 발생하였습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
    "ERROR_LOGIN_BLOCKED": "{0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다.",
    "ERROR_SESSION_EXPIRED": "세션이 만료되어 {0} 부운영자 계정 로그인에 실패했습니다.<br>크리마 진열 운영팀에 문의하시기 바랍니다."
  }
}
</i18n>
