<template>
  <div
    :class="[
      'AppMultipleSelectTable',
      { 'AppMultipleSelectTable--disabled': disabled }
    ]"
  >
    <div class="table-head-control">
      <AppButton
        :label="selectButtonLabel"
        :disabled="disabled"
        @click="openItemSelectDialog"
      />
    </div>
    <AppTable
      table-id="multiple-select-table"
      :columns="[
        { id: 'position', label: $t('app.position'), width: '40px' },
        ...columns,
        { id: 'actions', label: $t('app.actions') }
      ]"
      v-bind="{ rows, noData }"
    >
      <template #cell="{ column, rowIndex }">
        <template v-if="column.id === 'actions'">
          <AppButtonDraggable
            :disabled="disabled"
            :drag-text="rows[rowIndex].message"
            @sort="sort"
          />
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            :disabled="disabled"
            @click="remove(rowIndex)"
          />
        </template>
      </template>
    </AppTable>
    <AppHiddenInputArray
      v-if="name"
      :name="name"
      :values="items.map(i => i.id)"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { arrayMoveIndex } from '@/lib/array';

export default {
  name: 'AppMultipleSelectTable',
  props: {
    dialogTitle: { type: String, required: true },
    name: { type: String, required: true },
    items: { type: Array, required: true },
    disabled: { type: Boolean, default: false },
    columns: { type: Array, required: true },
    dialogColumns: { type: Array, required: true },
    searchTypes: { type: Array, required: true },
    searchTypesAlign: { type: String, default: 'default' },
    advancedSearchSections: { type: Array, default: () => [] },
    selectButtonLabel: { type: String, required: true },
    noData: { type: String, required: true },
    socialMediaOnly: { type: Boolean, default: false }
  },
  computed: {
    rows() {
      return this.items.map((item, i) => ({
        ...item,
        position: i + 1,
        user: this.formatUser(item)
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openItemSelectDialog() {
      this.openDialog([
        'AppSelectSearchableDialog',
        {
          title: this.dialogTitle,
          searchTypes: this.searchTypes,
          searchTypesAlign: this.searchTypesAlign,
          multiple: true,
          items: this.items,
          columns: this.dialogColumns,
          selectButtonLabel: this.selectButtonLabel,
          advancedSearchSections: this.advancedSearchSections,
          sort: false,
          socialMediaOnly: this.socialMediaOnly
        }
      ]).then(eventBus => {
        eventBus.$on('search', args => this.$emit('search', args));
        eventBus.$on('select', items => this.$emit('change', items));
      });
    },
    sort({ srcIndex, dstIndex }) {
      const items = arrayMoveIndex(this.items, srcIndex, dstIndex);
      this.$emit('change', items);
    },
    remove(index) {
      const items = this.items.filter((item, i) => i !== index);
      this.$emit('change', items);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppMultipleSelectTable--disabled {
  color: $color-disable-text;
  pointer-events: none;
}

::v-deep {
  .multiple-select-table {
    table-layout: fixed;
    white-space: nowrap;
  }

  .multiple-select-table__position {
    width: 52px;
  }

  .multiple-select-table__actions {
    width: 132px;
  }
}
</style>
