<template>
  <AppDataBox :title="$t('title')" class="TargetCampaignsKpi">
    <div class="TargetCampaignsKpi__title">
      {{ campaignText(campaign, 'kpi.name_long') }}
      <AppNumber :value="kpi" :precision="1" unit="percentage" />
    </div>
    <table class="TargetCampaignsKpi__table">
      <tr v-for="({ label, value, color }, i) in charts" :key="i">
        <th class="TargetCampaignsKpi__col-label">{{ label }}</th>
        <td class="TargetCampaignsKpi__col-bar">
          <AppChartSmallBar
            :numerator="value"
            :denominator="maxValue"
            :color="color"
          />
        </td>
        <td class="TargetCampaignsKpi__bar-value">
          <AppNumber :value="value" unit="percentage" />
        </td>
      </tr>
    </table>
  </AppDataBox>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'TargetCampaignsKpi',
  props: {
    campaign: { type: Object, required: true },
    kpi: { type: Number, required: true },
    averageKpi: { type: Number, required: true }
  },
  computed: {
    ...mapGetters('targetCampaigns', ['campaignText']),
    charts() {
      return [
        { label: this.$t('label.kpi'), value: this.kpi, color: 'green' },
        {
          label: this.$t('label.average_kpi'),
          value: this.averageKpi,
          color: 'blue'
        }
      ];
    },
    maxValue() {
      return _.maxBy(this.charts, 'value').value;
    }
  },
  methods: {
    barWidth(value) {
      const { maxValue } = this;
      if (value === 0 || maxValue === 0) return '0';
      if (value === maxValue) return '100%';

      const percentage = _.clamp(Math.round((value / maxValue) * 100), 1, 99);

      return `${percentage}%`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';

.TargetCampaignsKpi {
  margin-bottom: 8px;
}

.TargetCampaignsKpi__title {
  @include text-sub-title;
}

.TargetCampaignsKpi__table {
  margin-top: 8px;
}

.TargetCampaignsKpi__graph {
  padding: 0 8px;
}

.TargetCampaignsKpi__col-label {
  @include text-caption-small;
  white-space: nowrap;
}

.TargetCampaignsKpi__col-bar {
  padding: 0 8px;
  width: 117px;
}

.TargetCampaignsKpi__bar-value {
  @include text-caption-small;
  text-align: right;
}
</style>

<i18n locale="ko">
{
  "title": "캠페인 핵심 효과",
  "label": {
    "kpi": "캠페인",
    "average_kpi": "전체 평균"
  }
}
</i18n>
