var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{staticClass:"ReviewSmartInstallationHideWidgetManagement",attrs:{"is-loading":_vm.isLoading}},[_c('AppButton',{attrs:{"label":_vm.$t('title_new'),"button-style":"red"},on:{"click":_vm.newHideWidgetClicked}}),_c('AppInfoBox',{staticClass:"ReviewSmartInstallationHideWidgetManagement__info-box"},[_vm._v(" "+_vm._s(_vm.$t('info_box.hide_widget_management'))+" ")]),_c('AppResourceTable',{attrs:{"table-id":"hideWidgetTable","columns":_vm.COLUMNS,"rows":_vm.rows,"event-bus":_vm.tableEventBus},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var rowIndex = ref.rowIndex;
return [(column === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('app.settings')},on:{"click":function($event){return _vm.hideWidgetSettingsClicked(rowIndex)}}}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"menu-items":[
            [
              {
                label: _vm.$t('move_to_shop.label'),
                type: 'external_link',
                to: _vm.rows[rowIndex].inserted_url,
                disabled: !_vm.rows[rowIndex].inserted_url,
                tooltip: _vm.rows[rowIndex].inserted_url
                  ? null
                  : _vm.$t('move_to_shop.not_found_inserted_url')
              },
              {
                label: _vm.$t('app.delete'),
                style: 'danger',
                clickHandler: function (ref) {
                          var close = ref.close;

                          return _vm.deleteHideWidget(rowIndex, close);
}
              }
            ]
          ]}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }