<template>
  <AppModalFormLivePreview
    :title="$t('title')"
    :sub-title="$t('subtitle')"
    :form-props="{ ...formProps, disabled: true }"
    hide-close-button
    width="560px"
    v-bind="{ isLoading }"
    v-on="formEvents"
  >
    <template #left>
      <BizMessageTemplatePreview :template="template" />
    </template>
    <template #group="{ id, value }">
      <template v-if="id === 'item_list'">
        <div class="AppForm__group-field">
          <BizMessageTemplateItemList v-model="template.item_list" readonly />
        </div>
      </template>
      <template v-else-if="id === 'content'">
        <div class="BizMessageTemplateShowDialog__content AppForm__group-field">
          {{ template.content }}
        </div>
      </template>
      <template v-else-if="id === 'buttons'">
        <div class="AppForm__group-field">
          <BizMessageTemplateButtons :buttons="template.buttons" readonly />
        </div>
      </template>
      <template v-else-if="id === 'comments'">
        <div class="AppForm__group-field">
          <BizMessageTemplateComments :comments="comments" />
        </div>
      </template>
    </template>
    <template #foot>
      <AppButton
        v-if="!isApproved && !hideEditButton"
        :label="$t('app.edit')"
        button-style="blue"
        :disabled="!isEditable"
        :tooltip="isEditable ? null : $t('edit_tooltip_disabled')"
        @click="clickEdit"
      />
      <AppButton :label="$t('app.close')" @click="close(true)" />
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import BizMessageTemplateShowView from '@/mixins/BizMessageTemplateShowView';
import DialogFormView from '@/mixins/DialogFormView';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';
import BizMessageTemplatePreview from './BizMessageTemplatePreview';
import BizMessageTemplateItemList from './BizMessageTemplateItemList';
import BizMessageTemplateButtons from './BizMessageTemplateButtons';
import BizMessageTemplateComments from '../components/BizMessageTemplateComments';
import { mapActions } from 'vuex';

export default {
  name: 'BizMessageTemplateShowDialog',
  components: {
    BizMessageTemplatePreview,
    BizMessageTemplateItemList,
    BizMessageTemplateButtons,
    BizMessageTemplateComments
  },
  mixins: [DialogFormView, BizMessageTemplateShowView],
  props: {
    templateId: { type: Number, required: true },
    templateType: { type: Number, required: true },
    hideEditButton: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: true,
      categories: [],
      comments: []
    };
  },
  computed: {
    groupOptions() {
      return {
        service_type: { type: 'static' },
        name: { type: 'static' },
        ...this.templateOptions,
        comments: { type: 'custom' }
      };
    },
    groupIds() {
      return ['service_type', 'name', ...this.templateOptionIds, 'comments'];
    },
    formSections() {
      return [
        {
          groups: this.groupIds.map(id => ({
            id,
            label: this.$t(`template.${id}`),
            value: this.template[id],
            ...this.groupOptions[id]
          }))
        }
      ];
    },
    isEditable() {
      return (
        this.template.inspection_status !==
        BizMessageTemplateInspectionStatus.REQUESTED
      );
    },
    isApproved() {
      return (
        this.template.inspection_status ==
        BizMessageTemplateInspectionStatus.APPROVED
      );
    }
  },
  mounted() {
    return this.fetchTemplate({
      id: this.templateId,
      templateType: this.templateType
    })
      .then(({ data }) => {
        const { template, template_comments } = data;
        this.template = this.formatTemplate(template);
        this.comments = template_comments;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('bizMessageTemplate', ['fetchTemplate']),
    ...mapActions('dialog', ['openDialog']),
    clickEdit() {
      this.openDialog([
        'BizMessageTemplateFormDialog',
        { templateId: this.templateId, templateType: this.templateType }
      ]);
      this.close(true);
    }
  }
};
</script>

<style scoped>
.BizMessageTemplateShowDialog__content {
  white-space: pre-line;
}
</style>

<i18n locale="ko">
{
  "title": "템플릿 상세보기",
  "subtitle": "알림톡 템플릿 관리",
  "template": {
    "service_type": "제품",
    "name": "템플릿 명",
    "category_code": "템플릿 카테고리",
    "header": "헤더",
    "title": "타이틀",
    "subtitle": "서브 타이틀",
    "image": "이미지 첨부",
    "highlight_image": "썸네일 이미지 (1:1 비율)",
    "item_list": "아이템 리스트",
    "content": "템플릿 내용",
    "extra": "부가정보",
    "buttons": "버튼",
    "comments": "의견 또는 문의사항"
  },
  "edit_tooltip_disabled": "템플릿 상태가 검수중일 때엔 수정 및 편집이 불가합니다.\n검수 취소 후 템플릿을 수정해주세요."
}
</i18n>
