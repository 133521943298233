<template>
  <div>
    <template v-if="type === 'currency'">
      {{ value | formatCurrency }}
    </template>
    <template v-else-if="type === 'date'">
      {{ value | formatDate }}
    </template>
    <template v-else-if="type === 'datetime'">
      {{ value | formatDateTime }}
    </template>
    <template v-else-if="type === 'datetime_hm'">
      {{ value | formatDateTimeWithoutSecond }}
    </template>
    <template v-else-if="type === 'date_period'">
      {{ value[0] | formatDate }} - {{ value[1] | formatDate }}
    </template>
    <template v-else-if="type === 'number'">
      <AppNumber :value="value" />
    </template>
    <template v-else>{{ value }}</template>
  </div>
</template>

<script>
export default {
  name: 'AppData',
  props: {
    type: { type: String, default: null },
    value: { type: [Number, Date, String, Array, Boolean], default: null }
  }
};
</script>
