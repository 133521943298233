<template>
  <div class="ProductBatchJobsSubProducts">
    <AppHeading :label="$t('local.title')" />
    <AppContainer>
      <BatchJobForm
        v-bind="{ batchJobType }"
        :title="$t('local.upload_description_title')"
        :description-html="$t('local.upload_description_content')"
        sample-file-url="/csv/sub-products-v1.csv"
        :success-message="$t('local.batch_job_started')"
        filename="sub-products-v1.csv"
      >
        <template #additional-buttons>
          <li>
            <AppButtonDownload
              url="/api/product/sub_products.csv"
              :label="$t('local.download_current_settings')"
            />
          </li>
        </template>
      </BatchJobForm>
    </AppContainer>
    <BatchJobTable
      v-bind="{ batchJobType }"
      @show="openBatchJobSubProductsDialog"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BatchJobType from '@/enums/BatchJobType';
import BatchJobForm from '@/views/components/BatchJobForm';
import BatchJobTable from '@/views/components/BatchJobTable';

export default {
  name: 'ProductBatchJobsSubProducts',
  components: { BatchJobForm, BatchJobTable },
  computed: {
    batchJobType() {
      return BatchJobType.SUB_PRODUCTS;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openBatchJobSubProductsDialog(batchJobId) {
      this.openDialog(['ProductBatchJobSubProductsDialog', { batchJobId }]);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .BatchJobForm__description--html {
  ol {
    list-style: decimal;
    padding-inline-start: 20px;
  }
  ol > li > ol {
    list-style: disc;
    padding-inline-start: 20px;
  }
}
</style>

<i18n locale="ko">
{
  "local": {
    "title": "리뷰 연동 CSV 업로드",
    "upload_description_title": "CSV 파일에 리뷰를 연동할 상품을 등록하여 한 번에 상품을 연동합니다.",
    "upload_description_content": "\u003C필수 작업\u003E<br>'현재 설정값 다운로드'를 클릭하여, 백업 데이터를 반드시 미리 저장해주세요.<br>저장하지 않았을 경우, 기존의 연동 상품을 한 번에 확인할 수 없습니다.<br><br>\u003C동작 안내\u003E<br><ol><li>'메인 상품'으로 등록한 상품의 리뷰로 '서브 상품'으로 등록한 상품의 리뷰를 노출합니다.</li><li>'메인 상품'을 등록 후, '서브 상품'에 아무것도 등록하지 않으면, 해당 '메인 상품'의 상품 연동이 초기화됩니다.</li><li>'서브 상품'은 개수를 추가할 수 있습니다.<ol><li>단, 컬럼명을 '서브 상품' 으로 잘 기재해주셔야 메인 상품과 서브 상품의 리뷰가 정상 연동됩니다.</li></ol></li></ol>\u003C파일 등록 가이드\u003E<br><ol><li>'CSV 예시 파일'을 다운받아 양식에 맞게 상품을 등록하여 CSV 파일을 등록해주세요.</li><li>등록한 CSV 파일 제목에 한글이 포함되어 있을 경우 등록이 실패할 수 있습니다.</li><li>이미 연동 상품이 설정된 경우에는 '현재 설정값 다운로드' 를 통해 다운받은 CSV 파일에 상품 리뷰 연동을 추가로 등록하면 편리합니다.</li></ol>",
    "batch_job_started": "세트 상품 일괄 등록을 시작했습니다.",
    "download_current_settings": "현재 설정값 다운로드"
  }
}
</i18n>
