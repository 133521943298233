<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <template #default>
      <AppForm
        id="target_admin_settings"
        v-bind="formProps"
        submit-button
        v-on="formEvents"
      />
    </template>
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import TargetContentView from '@/mixins/TargetContentView';

export default {
  name: 'TargetAdminSettingsTemp',
  mixins: [FormView, TargetContentView],
  data: () => ({ isLoading: true }),
  computed: {
    groupIds() {
      return [
        'apply_promotion_info_on_content_popup',
        'show_popup_in_app',
        'visit_shop_before_landing_page',
        'prepare_payment_disabled',
        'marketing_code'
      ];
    },
    groupConfigs() {
      return {
        apply_promotion_info_on_content_popup: {
          type: 'checkbox',
          groupTooltip: this.$t('form.apply_promotion_info.tooltip')
        },
        show_popup_in_app: {
          type: 'checkbox',
          groupTooltip: this.$t('form.show_popup_in_app.tooltip')
        },
        visit_shop_before_landing_page: {
          type: 'checkbox',
          groupDescription: this.$t(
            'form.visit_shop_before_landing_page.description'
          ),
          groupTooltip: this.$t('form.visit_shop_before_landing_page.tooltip')
        },
        prepare_payment_disabled: {
          type: 'checkbox',
          groupDescription: this.$t('form.prepare_payment_disabled.description')
        },
        marketing_code: {
          type: 'text',
          placeholder: this.$t('form.marketing_code.placeholder'),
          hint: this.$t('form.marketing_code.hint')
        }
      };
    },
    formSections() {
      return [
        {
          groups: this.groupIds.map(id => ({
            id,
            ...this.groupConfigs[id]
          }))
        }
      ];
    }
  },
  mounted() {
    api
      .get('/target/admin_settings/temp')
      .then(({ data }) => {
        this.orgFormObject = data.settings;
        this.campaigns = data.campaigns;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/target/admin_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => (this.orgFormObject = this.formObject))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "form": {
    "apply_promotion_info": {
      "tooltip": "모든 업체에 적용한 후 해당 설정을 제거합니다."
    },
    "show_popup_in_app": {
      "tooltip": "타겟 팝업이 모든 앱에서 정상 동작할 경우 해당 설정을 제거합니다."
    },
    "visit_shop_before_landing_page": {
      "description": "쇼핑몰 유입 기록을 남기기 위해 구매 유도 목적 캠페인 랜딩페이지 접속시 쇼핑몰을 경유합니다.",
      "tooltip": "쇼핑몰 유입 통계와 무관하게 크리마 통계를 제공해도 괜찮은 시점에 해당 설정을 제거합니다."
    },
    "marketing_code": {
      "placeholder": "광고 코드를 입력해주세요.",
      "hint": "광고 코드는 크리마를 통해 쇼핑몰로 유입되는 트래픽을 외부 솔루션에서 추적하려 할 때 식별자로 사용하게 됩니다."
    },
    "prepare_payment_disabled": {
      "description": "타겟 사용중인 고객사에 대하여 재결제를 차단합니다. 만료일이 되어도 내부 재결제 프로세스가 진행되지 않습니다."
    }
  }
}
</i18n>
