import { constructEnum } from '@/lib/enum';

const BizMessageTemplateInspectionStatus = constructEnum(
  'BizMessageTemplateInspectionStatus',
  {
    NONE: 'NON',
    REGISTERED: 'REG',
    REQUESTED: 'REQ',
    APPROVED: 'APR',
    REJECTED: 'REJ'
  }
);

BizMessageTemplateInspectionStatus.badgeStyle = status => {
  switch (status) {
    case BizMessageTemplateInspectionStatus.NONE:
    case BizMessageTemplateInspectionStatus.REGISTERED:
      return 'default';
    case BizMessageTemplateInspectionStatus.REQUESTED:
      return 'mango';
    case BizMessageTemplateInspectionStatus.APPROVED:
      return 'mint-green';
    case BizMessageTemplateInspectionStatus.REJECTED:
      return 'red';
    default:
      return 'default';
  }
};

export default BizMessageTemplateInspectionStatus;
