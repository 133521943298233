<template>
  <AppModalForm
    :title="$t(`${prefix}.title`)"
    :form-props="{ ...formProps, submitLabel: $t('submit') }"
    v-on="formEvents"
  >
    <template #group="{ id }">
      <template v-if="id === 'icon_table'">
        <AppResourceTable
          :columns="columns"
          :rows="rows"
          :resources="{ total_count: totalCount, per, page }"
          :no-data="$t(`${prefix}.no_data`)"
          :filter-options="[{ label: $t('show_selected_icons'), value: 1 }]"
          :filter-values="filterValues"
          @change-filter-values="filterValues = $event"
          @paginate="paginate"
        >
          <template #cell="{ column, row }">
            <template v-if="column === 'select'">
              <AppCheckbox
                :checked="row.checked"
                name="icon"
                :value="row.id"
                :disabled="row.disabled"
                @change="updateSelectedIds($event, row.id)"
              />
            </template>
            <template v-else-if="column === 'icon'">
              <AppProductIcon :url="row.image_url" />
            </template>
          </template>
        </AppResourceTable>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapState } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'DisplayIconManagementSelectIconDialog',
  mixins: [DialogFormView],
  props: {
    iconIds: { type: Array, default: () => [] },
    isPc: { type: Boolean, default: true }
  },
  data: () => ({ per: 20, page: 1, filterValues: [] }),
  computed: {
    ...mapState('productIcons', ['productIcons']),
    icons() {
      let icons = this.productIcons;
      if (this.isShowSelectedIconsSelected)
        icons = icons.filter(({ id }) =>
          this.formObject.selectedIds.includes(id)
        );
      icons = icons.filter(({ mobile }) => mobile !== this.isPc);
      return icons;
    },
    prefix() {
      return this.isPc ? 'pc' : 'mobile';
    },
    totalCount() {
      return this.icons.length;
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'info',
              label: '',
              type: 'infobox',
              value: this.$t(`${this.prefix}.info`)
            },
            { id: 'icon_table', label: '' }
          ]
        }
      ];
    },
    columns() {
      return [
        { id: 'icon', label: this.$t('icon') },
        { id: 'select', label: this.$t('app.select'), width: '64px' }
      ];
    },
    isShowSelectedIconsSelected() {
      return !!this.filterValues[0];
    },
    rows() {
      const { page, per } = this;
      return this.icons.slice((page - 1) * per, page * per).map(icon => {
        const { selectedIds } = this.formObject;
        const isChecked = selectedIds.includes(icon.id);

        return {
          ...icon,
          checked: isChecked,
          disabled: selectedIds.length >= 5 && !isChecked
        };
      });
    }
  },
  watch: {
    isShowSelectedIconsSelected() {
      this.per = 20;
      this.page = 1;
    }
  },
  mounted() {
    this.orgFormObject = { selectedIds: this.iconIds };
  },
  methods: {
    paginate({ page, per }) {
      this.page = page;
      this.per = per;
    },
    updateSelectedIds(checked, value) {
      const { selectedIds } = this.formObject;

      if (checked) selectedIds.push(value);
      else this.formObject.selectedIds = selectedIds.filter(id => id !== value);
    },
    submit() {
      this.eventBus.$emit('update-selected-icons', this.formObject.selectedIds);
      this.close(true);
    }
  }
};
</script>

<i18n locale="ko">
{
  "pc": {
    "title": "상품 아이콘 선택",
    "info": "대상 상품에 표시할 아이콘을 선택해주세요. 최대 5개까지 선택할 수 있습니다.",
    "no_data": "등록된 상품 아이콘이 없습니다."
  },
  "mobile": {
    "title": "모바일 아이콘 선택",
    "info": "대상 상품에 표시할 모바일 전용 상품 아이콘을 선택해주세요. 최대 5개까지 선택할 수 있습니다.",
    "no_data": "등록된 모바일 전용 상품 아이콘이 없습니다."
  },
  "submit": "상품 아이콘 선택",
  "show_selected_icons": "선택한 아이콘만 보기",
  "no_selected_icons": "선택한 아이콘이 없습니다.",
  "icon": "아이콘"
}
</i18n>
