import _ from 'lodash';
import i18n from '@/lib/i18n';

export default {
  INIT_ENV(state) {
    switch (location.host) {
      case 'ladmin.cre.ma':
        state.env = 'development';
        break;
      case 'dadmin.cre.ma':
      case 'sadmin.cre.ma':
      case 'tadmin.cre.ma':
        state.env = 'staging';
        break;
      case 'admin.cre.ma':
        state.env = 'production';
        break;
      default:
        state.env = 'invalid';
    }
  },
  SET_BRAND_PARAMS(state, params) {
    state.brandParams = _.pick(params, [
      'brand_id',
      'brand_code',
      'user_id',
      'brand_url'
    ]);
  },
  SET_DATE_RANGE_PARAMS(state, params) {
    state.dateRangeParams = _.pick(params, ['start_date', 'end_date']);
  },
  SET_CURRENT_PATH(state, path) {
    state.currentPath = path;
  },
  SET_CURRENT_QUERY(state, query) {
    state.currentQuery = query;
  },
  SET_CURRENT_PATH_PARAMS(state, pathParams) {
    state.currentPathParams = pathParams;
  },
  SET_TITLE(state, title) {
    const newTitle = title || i18n.t('app.title_default');
    state.title = newTitle;
    document.title = newTitle;
  }
};
