<template>
  <div class="AppCorporateNumberChangeRequestForm">
    <AppChangeRequestStatus
      :title="$t('title')"
      :status="status"
      :hint="hint"
      :is-required="isRequired"
    />
    <div class="AppCorporateNumberChangeRequestForm__button">
      <AppButtonToggle v-if="isRequired" v-model="enableCloseButton">
        <AppSvgIcon
          class="AppCorporateNumberChangeRequestForm__button-icon"
          name="icon-list-check"
        />
        {{ $t(`enable_close_button.${status}`) }}
      </AppButtonToggle>
      <AppButton
        :label="changeCorporateNumberButtonLabel"
        @click="clickChangeCorporateNumber"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppCorporateNumberChangeRequestForm',
  props: {
    status: { type: String, default: 'none' },
    rejectMessage: { type: String, default: '' }
  },
  data: () => ({ enableCloseButton: true }),
  computed: {
    hint() {
      return this.$t(`hint.${this.status}`, [this.rejectMessage]);
    },
    changeCorporateNumberButtonLabel() {
      return this.$t(`change_corporate_number_button.${this.status}`);
    },
    isRequired() {
      return ['none', 'approved'].includes(this.status);
    }
  },
  watch: {
    enableCloseButton() {
      this.$emit('enable-close-button', this.enableCloseButton);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    clickChangeCorporateNumber() {
      this.openDialog('TheCorporateNumberChangeRequestDialog').then(eventBus =>
        eventBus.$on('requested', payload => this.$emit('requested', payload))
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.AppCorporateNumberChangeRequestForm__button {
  margin-top: 4px;
}

.AppCorporateNumberChangeRequestForm__button-icon {
  margin-right: 4px;
}
</style>

<i18n locale="ko">
{
  "title": "세금계산서 발급 자료",
  "hint": {
    "none": "무통장 입금 결제를 진행하신 경우, 세금계산서 발급이 필요한 사업자 정보를 등록해주세요.\n'나중에 하기'를 선택할 경우, 이후에 관리자페이지 진입시 자료 등록에 대한 안내창이 표시됩니다.",
    "requested": "크리마 운영진이 확인 후 처리해드립니다. 확인이 완료될 경우 [알림]을 통해 안내됩니다.",
    "required": "사업자등록정보가 등록되지 않은 경우 세금계산서 발행이 되지 않습니다.",
    "rejected": "변경 요청이 거절되었습니다. (사유 : {0})\n‘확인 및 변경’ 버튼을 눌러 다시 진행해주세요.",
    "approved": "등록일 이후 사업자 정보가 변경되었다면 정보를 업데이트 해주세요."
  },
  "enable_close_button": {
    "none": "나중에 하기",
    "approved": "현재 등록된 사업자등록번호 사용"
  },
  "change_corporate_number_button": {
    "none": "자료 등록",
    "required": "자료 등록",
    "rejected": "확인 및 변경",
    "requested": "확인 및 변경",
    "approved": "변경 요청"
  },
  "show_documents_button": "등록한 자료 확인"
}
</i18n>
