export default {
  props: {
    eventBus: {
      type: Object,
      required: true
    },
    keepOnRoute: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.eventBus.$on('close-drawer', this.close);
  },
  methods: {
    close() {
      this.closeDrawer();
    },
    closeDrawer() {
      this.emit('close');
      this.$store.dispatch('drawer/closeDrawer', this.$options.name);
    },
    emit(event, args) {
      this.eventBus.$emit(event, args);
    }
  }
};
