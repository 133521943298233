<template>
  <div>
    <AppSearchbar>
      <template #right>
        <li v-if="itemStatusOptions.length">
          <AppSelectFilterable
            :value="itemStatus"
            :options="itemStatusOptions"
            :placeholder="$t('searchbar.dispatch_result_placeholder')"
            @change="$emit('change-item-status', $event)"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppAjaxContent :is-loading="isLoading">
      <AppResourceTable
        :table-id="tableId"
        :resources="cohortItems"
        :columns="finalColumns"
        :rows="rows"
        @paginate="$emit('paginate', $event)"
      >
        <template #cell="{ row, column }">
          <template v-if="column === 'date'">
            <div v-if="row.created_at">
              {{ $t('date.created_at') }}:
              {{ row.created_at | formatDateTimeWithoutSecond }}
            </div>
            <div v-if="row.sent_at">
              {{ $t('date.sent_at') }}:
              {{ row.sent_at | formatDateTimeWithoutSecond }}
            </div>
            <div v-if="row.first_checked_at">
              {{ $t('date.first_checked_at') }}:
              {{ row.first_checked_at | formatDateTimeWithoutSecond }}
            </div>
            <div
              v-if="row.first_checked_ip && isSuperAdminAccessible"
              :class="[
                'super-admin__item',
                {
                  TargetReportsDispatchResultDialogTable__suspicious:
                    row.suspicious
                }
              ]"
            >
              {{ $t('date.first_checked_ip') }}:
              {{ row.first_checked_ip }}
            </div>
            <div v-if="row.last_checked_at">
              {{ $t('date.last_checked_at') }}:
              {{ row.last_checked_at | formatDateTimeWithoutSecond }}
            </div>
          </template>
          <template v-else-if="column === 'user'">
            <div v-if="row.name">{{ row.name }}</div>
            <div v-if="row.username">{{ row.username }}</div>
            <div v-if="row.to">{{ row.to }}</div>
          </template>
          <template v-else-if="column === 'status'">
            <TargetReportsDispatchResultDialogTableStatusBadge
              :status="row.status"
              :dispatch-method="dispatchMethod"
              :tooltip="row.errorTooltip"
            />
          </template>
        </template>
      </AppResourceTable>
    </AppAjaxContent>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TargetReportsDispatchResultDialogTableStatusBadge from './TargetReportsDispatchResultDialogTableStatusBadge';
import SmsCallStatus from '@/enums/SmsCallStatus';
import SweetTrackerBizMessageResultCode from '@/enums/SweetTrackerBizMessageResultCode';
import TargetDispatchMethod from '@/enums/TargetDispatchMethod';
import TargetCohortItemStatus from '@/enums/TargetCohortItemStatus';

export default {
  name: 'TargetReportsDispatchResultDialogTable',
  components: { TargetReportsDispatchResultDialogTableStatusBadge },
  props: {
    tableId: { type: String, required: true },
    isLoading: { type: Boolean, required: true },
    cohortItems: { type: Object, required: true },
    columns: { type: Array, required: true },
    itemStatus: { type: Number, default: null },
    itemStatusOptions: { type: Array, default: () => [] },
    dispatchMethod: { type: Number, required: true }
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    columnProps: () => ({
      message: { id: 'message', align: 'left', type: 'pre' }
    }),
    finalColumns() {
      return this.columns.map(column => ({
        ...column,
        ...this.columnProps[column.id],
        label: column.label || this.$t(`table.${column.id}`)
      }));
    },
    rows() {
      return this.cohortItems.items.map(item => ({
        ...item,
        ...(this.itemStatus === TargetCohortItemStatus.ERROR_SENDING
          ? { errorTooltip: this.errorTooltip(item) }
          : {})
      }));
    }
  },
  methods: {
    errorTooltip(item) {
      switch (this.dispatchMethod) {
        case TargetDispatchMethod.SMS:
          return SmsCallStatus.text(item);
        case TargetDispatchMethod.BIZ_MESSAGE:
          return SweetTrackerBizMessageResultCode.t(item.result_code);
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TargetReportsDispatchResultDialogTable__suspicious {
  color: $color-red;
}
</style>

<i18n locale="ko">
{
  "searchbar": {
    "dispatch_result_placeholder": "전체 발송 결과"
  },
  "table": {
    "id": "ID",
    "date": "날짜",
    "user": "고객 정보",
    "message": "메시지",
    "status": "발송 결과"
  },
  "date": {
    "created_at": "요청일",
    "sent_at": "발송일",
    "first_checked_at": "최초확인일",
    "first_checked_ip": "최초확인IP",
    "last_checked_at": "최종확인일"
  }
}
</i18n>
