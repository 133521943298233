<template>
  <AppModalFormLivePreview
    :title="$t('title')"
    :sub-title="productManagement.name"
    width="420px"
    :form-props="formProps"
    v-on="formEvents"
    @error-summary="updatePreviewIfValid"
  >
    <template #left>
      <AppIframeViewer
        class="DisplayWidgetManagementFormDialog__preview"
        :src="previewUrl"
        :is-loading-forced="isLoading || displayVersion !== currentVersion"
      />
    </template>
    <template #group="{ id, disabled, errors }">
      <template v-if="id === 'pc_margin' || id === 'mobile_margin'">
        <div
          v-for="directions in [
            ['top', 'right'],
            ['bottom', 'left']
          ]"
          :key="directions.join('-')"
          :class="[
            'DisplayWidgetManagementFormDialog__margin-row',
            'AppForm__group-field',
            'AppForm__group-field--mt12',
            disabled ? 'AppForm__group-field--disabled' : ''
          ]"
        >
          <div
            v-for="direction in directions"
            :key="direction"
            class="DisplayWidgetManagementFormDialog__margin-input"
          >
            <div>{{ $t(`form.margin.fields.${direction}`) }}</div>
            <AppNumberInput
              v-model="formObject[`${id}_${direction}`]"
              class="AppForm__group-field"
              allow-negative
              :disabled="disabled"
              :placeholder="marginDefault[direction]"
              :digits="2"
            />
          </div>
        </div>
      </template>
    </template>
    <input
      v-for="key in [
        'widget_name',
        'order_time',
        'pc_margin_top',
        'pc_margin_right',
        'pc_margin_bottom',
        'pc_margin_left',
        'mobile_margin_top',
        'mobile_margin_right',
        'mobile_margin_bottom',
        'mobile_margin_left',
        'pc_css',
        'mobile_css'
      ]"
      :key="key"
      type="hidden"
      :name="`product_management[${key}]`"
      :value="formObject[key]"
    />
  </AppModalFormLivePreview>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import IframePreviewHelper from '@/mixins/IframePreviewHelper';

export default {
  name: 'DisplayWidgetManagementFormDialog',
  mixins: [DialogFormView, IframePreviewHelper],
  props: { item: { type: Object, required: true } },
  data: () => ({ isLoading: true }),
  computed: {
    ...mapState('displayProductManagement', ['productManagement']),
    previewUrl() {
      return `${this.item.widget_preview_url}&v=${this.displayVersion}`;
    },
    marginDefault() {
      return { top: '20', left: '0', bottom: '20', right: '0' };
    },
    formSections() {
      const disabled = !this.formObject.use_widget_management;

      return [
        {
          groups: [
            {
              id: 'info',
              label: '',
              type: 'infobox',
              value: this.$t('form.information_html')
            },
            {
              id: 'use_widget_management',
              label: this.$t('form.use_widget_management.label'),
              type: 'checkbox',
              description: this.$t('form.use_widget_management.description')
            },
            {
              id: 'widget_name',
              label: this.$t('form.widget_name.label'),
              type: 'keyword_textarea',
              keywords: [
                {
                  key: 'order_time',
                  desc: this.$t('form.widget_name.keywords.order_time.desc')
                }
              ],
              singleLine: true,
              groupDescription: this.$t('form.widget_name.description'),
              placeholder: this.$t('form.widget_name.placeholder'),
              disabled,
              labelDisabled: disabled
            },
            {
              id: 'order_time',
              label: this.$t('form.order_time.label'),
              disabled,
              labelDisabled: disabled,
              type: 'select',
              options: [...Array(24).keys()].map(hour => ({
                value: hour,
                label: this.$t(
                  `form.order_time.options.${
                    hour < 12 ? 'morning' : 'afternoon'
                  }`,
                  [hour <= 12 ? hour : hour - 12]
                )
              })),
              groupDescription: this.$t('form.order_time.description_html', [
                '%{order_time}'
              ])
            },
            {
              id: 'pc_margin',
              label: this.$t('form.margin.label', [this.$t('app.pc')]),
              disabled,
              labelDisabled: disabled,
              groupDescription: this.$t('form.margin.description'),
              superAdmin: true
            },
            {
              id: 'mobile_margin',
              label: this.$t('form.margin.label', [this.$t('app.mobile')]),
              disabled,
              labelDisabled: disabled,
              groupDescription: this.$t('form.margin.description'),
              superAdmin: true
            },
            {
              id: 'pc_css',
              label: this.$t('form.css.label', [this.$t('app.pc')]),
              disabled,
              labelDisabled: disabled,
              type: 'code_editor',
              lang: 'css',
              superAdmin: true
            },
            {
              id: 'mobile_css',
              label: this.$t('form.css.label', [this.$t('app.mobile')]),
              disabled,
              labelDisabled: disabled,
              type: 'code_editor',
              lang: 'css',
              superAdmin: true
            }
          ]
        }
      ];
    }
  },
  mounted() {
    this.orgFormObject = { ..._.omit(this.item, 'widget_preview_url') };
    api
      .patch(
        `/display/product_managements/${this.productManagement.id}/initialize_sample_cohort`
      )
      .then(() => {
        this.isLoading = false;
        this.$nextTick(this.updatePreviewIfValid);
      });
  },
  methods: {
    updatePreview() {
      const version = this.currentVersion;

      api
        .patch(
          `/display/product_managements/${this.productManagement.id}/preview`,
          { product_management: this.formObject },
          { requestId: 'UPDATE_DISPLAY_WIDGET_MANAGEMENT_PREVIEW' }
        )
        .then(() => (this.displayVersion = version));
    },
    submit() {
      ['pc', 'mobile'].forEach(type => {
        ['top', 'right', 'bottom', 'left'].forEach(position => {
          const id = `${type}_margin_${position}`;

          if (!this.formObject[id])
            this.formObject[id] = this.marginDefault[position];
        });
      });
      this.eventBus.$emit('update-product-management', this.formObject);
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.DisplayWidgetManagementFormDialog__preview {
  width: 600px;
  padding-top: 92px;
  padding-left: 32px;
  padding-right: 32px;
}

.DisplayWidgetManagementFormDialog__margin-row {
  display: flex;
}

.DisplayWidgetManagementFormDialog__margin-input {
  & + & {
    margin-left: 24px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "빠른 배송 안내 위젯 표시",
  "form": {
    "information_html": "상품 선정 조건 중 재고량 관련 기준만을 사용하여<br />빠른 배송 가능한 상품을 위젯에 표시합니다.",
    "use_widget_management": {
      "label": "빠른 배송 안내 위젯 표시",
      "description": "대상 상품의 상세 페이지에 빠른 배송 안내 위젯을 표시합니다."
    },
    "widget_name": {
      "label": "빠른 배송 안내 위젯 이름",
      "description": "빠른 배송 안내 위젯에 표시할 이름을 설정합니다.",
      "placeholder": "빠른 배송 안내 위젯 이름을 입력해주세요.",
      "keywords": {
        "order_time": {
          "desc": "결제 완료 시간"
        }
      }
    },
    "order_time": {
      "label" :"결제 완료 시간",
      "description_html": "빠른 배송을 위해 결제가 완료되어야 하는 시간을 설정합니다.<br />예약어 {0}을 사용하여 위젯 이름에 표시할 수 있습니다.",
      "options": {
        "morning": "오전 {0}시",
        "afternoon": "오후 {0}시"
      }
    },
    "margin": {
      "label": "이 위젯에만 적용할 여백 값 ({0})",
      "description": "위젯은 최상단의 위젯 이름부터 최하단의 로고까지를 뜻합니다.",
      "fields": {
        "top": "상단 여백(px)",
        "right": "오른쪽 여백(px)",
        "bottom": "하단 여백(px)",
        "left": "왼쪽 여백(px)"
      }
    },
    "css": {
      "label": "이 위젯에만 적용할 CSS ({0})"
    }
  }
}
</i18n>
