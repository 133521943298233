import { constructEnum } from '@/lib/enum';

export default constructEnum('ReviewSearch', {
  USER_ID: 10,
  USER_NAME: 20,
  PHONE_NUMBER: 30,
  PRODUCT_NAME: 40,
  REVIEW_ID: 50,
  ORDER_CODE: 60,
  REVIEW_MESSAGE: 70,
  PRODUCT_CODE: 80
});
