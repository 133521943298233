<template>
  <div>
    <AppAjaxContent :is-loading="isLoading">
      <AppSearchbar>
        <template #right>
          <li>
            <AppSelect
              v-model="resourceParams.widget_alternated"
              :options="widgetAlteredOptions"
              :placeholder="$t('searchbar.dispatch_result_placeholder')"
              @change="searchResource"
            />
          </li>
        </template>
      </AppSearchbar>
      <AppResourceTable
        :rows="rows"
        :resources="widgets"
        :columns="columns"
        @paginate="paginate"
      >
        <template #cell="{ column, row, value }">
          <div
            v-if="column === 'parent_title'"
            v-tooltip="{
              message: row.parent_url,
              info: $t('parent_title_info')
            }"
          >
            {{ value }}
            <AppResourceTableLinkIcon @click="openParentUrl(row.parent_url)" />
          </div>
          <AppBadge
            v-else-if="column === 'widget_status'"
            :label="row.badgeLabel"
            :badge-style="row.badgeStyle"
            :tooltip="row.badgeTooltip"
          />
        </template>
      </AppResourceTable>
    </AppAjaxContent>
  </div>
</template>

<script>
import _ from 'lodash';
import { nullResources } from '@/lib/resources';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import api from '@/lib/api';
import TargetWidgetStatus from '@/enums/TargetWidgetStatus';
import TargetWidgetRecommendationFilterType from '@/enums/TargetWidgetRecommendationFilterType';

export default {
  name: 'TargetReportsDispatchResultDialogTableWidget',
  mixins: [ResourceViewNoRoute],
  props: { cohort: { type: Object, required: true } },
  data: () => ({ isLoading: true, widgets: nullResources }),
  computed: {
    TargetWidgetStatus: () => TargetWidgetStatus,
    columns() {
      return [
        { id: 'id' },
        { id: 'first_checked_at', type: 'datetime' },
        { id: 'user', type: 'pre' },
        { id: 'parent_title' },
        { id: 'widget_status' }
      ].map(column => ({
        ...column,
        label: this.$t(`table.${column.id}`)
      }));
    },
    widgetAlteredOptions() {
      return [
        {
          value: false,
          label: this.$t('widget_alternated_options.not_alternated')
        },
        { value: true, label: this.$t('widget_alternated_options.alternated') }
      ];
    },
    rows() {
      return this.widgets.items.map(widget => ({
        ...widget,
        user: (widget.brand_user
          ? _.chain(widget.brand_user)
              .pick('name', 'username', 'phone')
              .values()
          : [this.$t('app.nonmember'), widget.device_token]
        ).join('\n'),
        badgeLabel: widget.alternated
          ? this.$t('alternated_badge.label')
          : TargetWidgetStatus.t(widget.widget_status),
        badgeStyle: widget.alternated ? 'mango' : 'blue',
        badgeTooltip: widget.alternated
          ? this.$t('alternated_badge.tooltip', [
              TargetWidgetRecommendationFilterType.t(
                widget.alternated_filter_type ||
                  this.cohort.default_recommendation_unit_filter_type
              )
            ])
          : ''
      }));
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/target/cohorts/${this.cohort.id}/items/widgets`, { params })
        .then(({ data }) => (this.widgets = data.widgets))
        .finally(() => (this.isLoading = false));
    },
    openParentUrl(parent_url) {
      window.open(parent_url, '_blank');
    }
  }
};
</script>

<i18n locale="ko">
{
  "searchbar": {
    "dispatch_result_placeholder": "전체 발송 결과"
  },
  "widget_alternated_options": {
    "not_alternated": "노출됨",
    "alternated": "대체항목노출"
  },
  "alternated_badge": {
    "label": "대체항목노출",
    "tooltip": "추천 상품이 없어 '{0}' 대체 노출"
  },
  "table": {
    "id": "ID",
    "first_checked_at": "노출일",
    "user": "고객 정보",
    "parent_title": "노출 페이지",
    "widget_status": "발송 결과"
  },
  "parent_title_info": "우측 링크 아이콘 클릭 시 노출 페이지로 이동"
}
</i18n>
