<template>
  <div class="InstallRequestRenewalDirectionDialogCountAndScore">
    <SlotRequestCard
      v-bind="countAndScoreSlotRequestCardProps"
      class="InstallRequestRenewalDirectionDialogCountAndScore__slot"
      @click="openInstallRequestRenewalSlotCountAndScoreDialog()"
    />
    <SlotRequestCard
      v-bind="countAndScoreCustomSlotRequestCardProps"
      class="InstallRequestRenewalDirectionDialogCountAndScore__slot"
      @click="openInstallRequestRenewalSlotCountAndScoreCustomDialog()"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SlotRequestCard from './components/SlotRequestCard';
import InstallSlotType from '@/enums/InstallSlotType';

export default {
  name: 'InstallRequestRenewalDirectionDialogCountAndScore',
  components: { SlotRequestCard },
  computed: {
    countAndScoreSlotRequestCardProps() {
      return {
        slotType: InstallSlotType.COUNT_AND_SCORE,
        imageUrl:
          'https://assets.cre.ma/m/admin/v2/install_request/count_and_score_slot@3x.png',
        title: this.$t('count_and_score.title'),
        description: this.$t('count_and_score.description')
      };
    },
    countAndScoreCustomSlotRequestCardProps() {
      return {
        slotType: InstallSlotType.COUNT_AND_SCORE_CUSTOM,
        imageUrl:
          'https://assets.cre.ma/m/admin/v2/install_request/count_and_score_custom_slot@3x.png',
        title: this.$t('count_and_score_custom.title'),
        description: this.$t('count_and_score_custom.description'),
        buttonLabel: this.$t('count_and_score_custom.button_label')
      };
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openInstallRequestRenewalSlotCountAndScoreDialog() {
      this.openDialog('InstallRequestRenewalSlotCountAndScoreDialog');
    },
    openInstallRequestRenewalSlotCountAndScoreCustomDialog() {
      this.openDialog('InstallRequestRenewalSlotCountAndScoreCustomDialog');
    }
  }
};
</script>

<style lang="scss" scoped>
.InstallRequestRenewalDirectionDialogCountAndScore {
  margin-top: 24px;
}

.InstallRequestRenewalDirectionDialogCountAndScore__slot {
  & + & {
    margin-top: 12px;
  }
}
</style>

<i18n locale="ko">
  {
    "count_and_score": {
      "title": "상품 목록",
      "description": "상품에 리뷰 수와 평점을 노출하여 상품 주목도를 높여보세요."
    },
    "count_and_score_custom": {
      "title": "상품 상세 페이지",
      "description": "해당 페이지에 노출되는 리뷰 수 중 크리마 담당자가 찾기 어려운 위치에 있는 경우 알려주세요.",
      "button_label": "위치 입력"
    }
  }
</i18n>
