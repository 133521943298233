<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="$t('sub_title', { count: reviews.length })"
    @close="close"
  >
    <AppTable
      slot="body"
      :columns="COLUMNS"
      :rows="rows"
      :no-data="$t('no_data')"
    >
      <template #cell="{ row, column }">
        <template v-if="column.id === 'select'">
          <AppCheckboxPartial
            v-tooltip="
              row.checkStatus == 'partial' ? $t('tooltip_partial') : null
            "
            :check-status="row.checkStatus"
            @change="
              $event === 'checked' ? selectTag(row.id) : unselectTag(row.id)
            "
          />
        </template>
      </template>
    </AppTable>
    <template #foot>
      <AppButton button-style="blue" :label="$t('apply_tags')" @click="apply" />
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'ReviewReviewsBatchTagsDialog',
  mixins: [DialogView],
  props: {
    reviews: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'select', label: this.$t('app.select') },
        { id: 'name', label: this.$t('tag_name') }
      ],
      reviewIdTagIdsMap: _.fromPairs(
        this.reviews.map(({ id, tags }) => [id, tags.map(tag => tag.id)])
      )
    };
  },
  computed: {
    ...mapState('review', ['tags']),
    rows() {
      return this.tags.map(tag => ({
        ...tag,
        checkStatus: this.checkStatus(tag.id)
      }));
    }
  },
  methods: {
    apply() {
      this.emit('apply', _.cloneDeep(this.reviewIdTagIdsMap));
      this.close();
    },
    checkStatus(tagId) {
      const taggedReviewsCount = _.reduce(
        this.reviewIdTagIdsMap,
        (sum, tagIds) => sum + (tagIds.includes(tagId) ? 1 : 0),
        0
      );

      if (taggedReviewsCount === this.reviews.length) {
        return 'checked';
      } else if (taggedReviewsCount > 0) {
        return 'partial';
      } else {
        return 'unchecked';
      }
    },
    selectTag(tagId) {
      _.each(this.reviewIdTagIdsMap, tagIds => {
        if (!tagIds.includes(tagId)) tagIds.push(tagId);
      });
    },
    unselectTag(tagId) {
      _.each(this.reviewIdTagIdsMap, tagIds => {
        this.$delete(tagIds, tagIds.indexOf(tagId));
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "태그 수정",
  "sub_title": "선택 리뷰 {count}개",
  "tag_name": "태그 이름",
  "no_data": "등록된 태그가 없습니다.",
  "tooltip_partial": "선택한 리뷰 중 일부 리뷰에 적용한 태그입니다.\n클릭해서 모든 리뷰에서 제거하거나 추가할 수 있습니다.",
  "apply_tags": "태그 적용"
}
</i18n>
<i18n locale="en">
{
  "title": "Edit tag",
  "sub_title": "Selected {count} reviews",
  "tag_name": "Tag name",
  "no_data": "No tags",
  "tooltip_partial": "Tags applied to some of the selected reviews.\nClick to remove or add to all reviews.",
  "apply_tags": "Apply tags"
}
</i18n>
