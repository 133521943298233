<template>
  <AppModal
    width="680px"
    :margin-vertical="46"
    class="TheSearchDialog"
    @close="close"
  >
    <div slot="head">
      <TheSearchDialogHead
        :show-foot="showFoot"
        @close="close"
        @change-query="setQuery"
        @click-up="eventBus.$emit('activate-prev')"
        @click-down="eventBus.$emit('activate-next')"
        @click-enter="eventBus.$emit('select-item')"
      />
    </div>
    <div slot="body" class="TheSearchDialog__body">
      <TheSearchDialogResults
        v-if="isResultMode"
        :initial-search-category="initialSearchCategory"
        :event-bus="eventBus"
        @select-result="selectResult"
        @close="close"
      />
      <TheSearchDialogKeywords
        v-else
        :event-bus="eventBus"
        @select-result="selectResult"
        @set-query="setQuery"
      />
    </div>
    <div v-if="showFoot" slot="foot" class="TheSearchDialog__foot">
      <ul>
        <li>
          <AppSvgIcon
            class="TheSearchDialog__shortcut-icon"
            name="icon-updown-key"
          />
          <label>{{ $t('shortcut_move') }}</label>
        </li>
        <li>
          <AppSvgIcon
            class="TheSearchDialog__shortcut-icon"
            name="icon-enter-small"
          />
          <label>{{ $t('shortcut_search') }}&nbsp;</label>
        </li>
        <li>
          <AppSvgIcon class="TheSearchDialog__shortcut-icon" name="icon-esc" />
          <label>{{ $t('shortcut_close') }}</label>
        </li>
      </ul>
    </div>
  </AppModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import qs from 'qs';
import DialogView from '@/mixins/DialogView';
import Focusable from '@/mixins/Focusable';
import TheSearchDialogHead from './TheSearchDialogHead';
import TheSearchDialogKeywords from './TheSearchDialogKeywords';
import TheSearchDialogResults from './TheSearchDialogResults';

export default {
  name: 'TheSearchDialog',
  components: {
    TheSearchDialogHead,
    TheSearchDialogKeywords,
    TheSearchDialogResults
  },
  mixins: [DialogView, Focusable],
  props: {
    initialQuery: { type: String, default: '' },
    initialSearchCategory: { type: String, default: 'all' }
  },
  computed: {
    ...mapState('search', [
      'query',
      'recommendedKeywords',
      'isSearchDataReady'
    ]),
    ...mapState('editor', ['recentlyVisitedPages']),
    ...mapGetters('session', ['isShopifyBrand']),
    isResultMode() {
      return this.query && this.isSearchDataReady;
    },
    recommendedKeywordsUsing() {
      if (this.isShopifyBrand) return [];
      return this.recommendedKeywords;
    },
    showFoot() {
      return (
        this.isResultMode ||
        this.recentlyVisitedPages.length +
          this.recommendedKeywordsUsing.length >
          0
      );
    }
  },
  created() {
    this.setQuery(this.initialQuery);
  },
  beforeDestroy() {
    this.setQuery('');
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('editor', ['addRecentPage']),
    ...mapActions('search', ['searchQuery']),
    ...mapActions('form', ['setAdvancedSettingVisible']),
    selectResult(searchResult) {
      this.close();
      this.addRecentPage(
        _.omit(
          { ...searchResult, titleHighlighted: searchResult.title },
          'keywords'
        )
      );
      if (searchResult.dialog) this.openDialog(searchResult.dialog);
      else if (searchResult.isOldAdmin)
        window.location.assign(searchResult.path);
      else if (searchResult.url) window.open(searchResult.url, '_blank');
      else {
        const [path, hash] = searchResult.path.split('#');
        const [pathname] = path.split('?');
        if (pathname === this.$route.path) {
          if (hash) {
            this.setAdvancedSettingVisible(hash);
            this.$nextTick(() => {
              const element = document.getElementById(hash);
              this.focusOnElement(element);
            });
          }
        } else {
          this.$router.push(searchResult.path);
        }
      }
    },
    setQuery(query) {
      this.searchQuery(query);
    },
    isCurrentPath(fullPath) {
      const [path, query, hash] = fullPath.split(/[?#]/);
      if (this.$route.path !== path) return false;

      const params = qs.parse(query);
      for (const key in params) {
        if (this.$route.query[key] !== params[key]) return false;
      }

      if (this.$route.hash !== `#${hash}`) return false;

      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheSearchDialog__body {
  padding: 0 8px;
}

.TheSearchDialog__foot {
  font-size: 12px;
  color: $color-grey-50;
  padding: 18px 24px;

  li {
    display: inline;

    & + li {
      margin-left: 24px;
    }
  }

  label {
    margin-left: 4px;
    vertical-align: middle;
  }
}

.TheSearchDialog__shortcut-icon {
  vertical-align: middle;
}

::v-deep {
  .search-dialog-item__link {
    display: block;
    font-size: 14px;
    padding: 6px 16px;
    border-radius: 3px;
    cursor: pointer;
  }

  .search-dialog-item--active .search-dialog-item__link {
    background-color: $color-nav-selected;
  }
}
</style>

<i18n locale="ko">
{
  "shortcut_move": "이동",
  "shortcut_search": "선택",
  "shortcut_close": "검색창 닫기"
}
</i18n>
<i18n locale="en">
{
  "shortcut_move": "Move",
  "shortcut_search": "Search",
  "shortcut_close": "Close"
}
</i18n>
