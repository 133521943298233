import { constructEnum } from '@/lib/enum';

export default constructEnum('WidgetInstallRequestProgressStep', {
  PRODUCT_DETAIL: 0,
  REVIEW_BOARD: 1,
  MAIN: 2,
  ETC: 3,
  COUNT_AND_SCORE: 4,
  CONFIRM: 5
});
