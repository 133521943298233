import api from '@/lib/api';
import { nullResources } from '@/lib/resources';

const FETCH_FIT_SIZE_FEEDBACK_PRODUCTS_REQUEST_ID =
  'FETCH_FIT_SIZE_FEEDBACK_PRODUCTS';

const state = {
  products_with_review_options: nullResources
};

const mutations = {
  SET_PRODUCTS_WITH_REVIEW_OPTIONS(state, products_with_review_options) {
    state.products_with_review_options = products_with_review_options;
  }
};

const actions = {
  resetProductsWithReviewOption({ commit }) {
    api.cancel(FETCH_FIT_SIZE_FEEDBACK_PRODUCTS_REQUEST_ID);
    commit('SET_PRODUCTS_WITH_REVIEW_OPTIONS', nullResources);
  },
  fetchProductsWithReviewOption({ commit }, params) {
    api
      .get('/fit/size_feedback/products', {
        requestId: FETCH_FIT_SIZE_FEEDBACK_PRODUCTS_REQUEST_ID,
        params
      })
      .then(function({ data }) {
        commit(
          'SET_PRODUCTS_WITH_REVIEW_OPTIONS',
          data.products_with_review_options
        );
      });
  }
};

const getters = {
  isFetchingProductsWithReviewOption(
    _state,
    _getters,
    _rootState,
    rootGetters
  ) {
    return rootGetters['request/isRequestPending'](
      FETCH_FIT_SIZE_FEEDBACK_PRODUCTS_REQUEST_ID
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
