<template>
  <Transition
    name="fade-height"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <slot />
  </Transition>
</template>

<script>
export default {
  methods: {
    enter(el) {
      el.style.animation = 'none';
      el.style.maxHeight = `${el.offsetHeight}px`;
      el.style.animation = '';
    },
    afterEnter(el) {
      el.style.maxHeight = '';
    },
    leave(el) {
      el.style.maxHeight = `${el.offsetHeight}px`;
    },
    afterLeave(el) {
      el.style.maxHeight = '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_transitions.scss';

@keyframes fade-height-in {
  0% {
    max-height: 0;
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-height-enter-active {
  animation: fade-height-in $transition-duration-slow linear;
}
.fade-height-leave-active {
  animation: fade-height-in $transition-duration-slow linear reverse;
}
</style>
