<template>
  <AppContainer>
    <AppInfoBox v-if="isDmCampaign" class="TargetCampaignSettingsMisc__info-box"
      >{{ $t('description') }}
    </AppInfoBox>
    <div class="TargetCampaignSettingsMisc__items">
      <div
        v-if="isDispatchSampleVisible"
        :class="[
          'TargetCampaignSettingsMisc__item',
          {
            'super-admin__item':
              campaign.campaign_type === TargetCampaignType.MILEAGE_EXPIRING
          }
        ]"
      >
        <AppDataItem :label="$t('dispatch_sample_title')">
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="dispatchTestMediaText"
            v-html="$t('dispatch_sample_message_html', [dispatchTestMediaText])"
          />
          <!-- eslint-enable vue/no-v-html -->
          <div v-else>-</div>
        </AppDataItem>
        <AppButton
          class="TargetCampaignSettingsMisc__button"
          v-bind="
            campaign.configured_design
              ? {}
              : {
                  disabled: true,
                  tooltip: $t('dispatch_sample_not_configured_tooltip')
                }
          "
          :label="$t('app.settings')"
          @click="
            openDialog([
              'TargetDmCampaignSettingsMiscDispatchSampleDialog',
              { campaign }
            ])
          "
        />
      </div>
      <div v-if="isDmCampaign" class="TargetCampaignSettingsMisc__item">
        <AppDataItem :label="$t('dispatch_limit_title')">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="dispatchLimitMessage" />
        </AppDataItem>
        <AppButton
          class="TargetCampaignSettingsMisc__button"
          :label="$t('app.settings')"
          @click="
            openDialog([
              'TargetDmCampaignSettingsMiscDispatchLimitDialog',
              { campaign }
            ])
          "
        />
      </div>
      <div
        v-if="isSuperAdminAccessible"
        class="TargetCampaignSettingsMisc__item super-admin__item"
      >
        <AppDataItem :label="$t('dispatch_estimation_title')">
          {{ dispatchEstimationMessage }}
        </AppDataItem>
        <AppButton
          class="TargetCampaignSettingsMisc__button"
          :label="$t(dispatchEstimationStatus)"
          :disabled="isRequesting || dispatchEstimationStatus !== 'calculable'"
          @click="clickComputeDispatchEstimation"
        />
      </div>
    </div>
  </AppContainer>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import TargetDispatchLimitType from '@/enums/TargetDispatchLimitType';
import TargetDispatchMethod from '@/enums/TargetDispatchMethod';
import TargetCampaignType from '@/enums/TargetCampaignType';

export default {
  name: 'TargetCampaignSettingsMisc',
  data() {
    return {
      dispatchEstimation: {},
      isRequesting: false
    };
  },
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('targetCampaign', ['isDmCampaign']),
    TargetCampaignType: () => TargetCampaignType,
    isDispatchSampleVisible() {
      if (this.campaign.campaign_type === TargetCampaignType.MILEAGE_EXPIRING)
        return this.isDmCampaign && this.isSuperAdminAccessible;
      else return this.isDmCampaign;
    },
    dispatchTestMediaText() {
      const {
        dispatch_sample_enable_sms,
        dispatch_sample_enable_email
      } = this.campaign;
      return [
        ...(dispatch_sample_enable_sms ? [this.$t('app.sms')] : []),
        ...(dispatch_sample_enable_email ? [this.$t('app.email')] : [])
      ].join(', ');
    },
    dispatchLimitMessage() {
      return this.$t(
        `dispatch_limit_message.${TargetDispatchLimitType.toKey(
          this.campaign.dispatch_limit_type
        )}`,
        [this.campaign.dispatch_limit_amount]
      );
    },
    dispatchEstimationMessage() {
      return _.map(this.dispatchEstimation, (value, method) => {
        const dispatchMethod = TargetDispatchMethod[method.toUpperCase()];
        return this.$t('dispatch_estimation_message', [
          TargetDispatchMethod.t(dispatchMethod),
          value
        ]);
      }).join(', ');
    },
    dispatchEstimationStatus() {
      return this.campaign.dispatch_estimation_status;
    }
  },
  created() {
    const { dispatch_methods } = this.campaign;
    if (dispatch_methods.includes(TargetDispatchMethod.SMS))
      this.dispatchEstimation.sms = this.resultByMethod('sms');
    if (dispatch_methods.includes(TargetDispatchMethod.EMAIL))
      this.dispatchEstimation.email = this.resultByMethod('email');
    if (dispatch_methods.includes(TargetDispatchMethod.POPUP))
      this.dispatchEstimation.popup = this.resultByMethod('popup');
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('targetCampaign', ['SET_DISPATCH_ESTIMATION_STATUS']),
    clickComputeDispatchEstimation() {
      this.isRequesting = true;
      api
        .get(`/target/campaigns/${this.campaign.id}/dispatch_estimation`)
        .then(({ data: { dispatch_estimation_status } }) =>
          this.SET_DISPATCH_ESTIMATION_STATUS(dispatch_estimation_status)
        )
        .finally(() => (this.isRequesting = false));
    },
    resultByMethod(targetMethod) {
      return this.campaign.dispatch_estimation_result[targetMethod] || '-';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

::v-deep {
  .AppContainer__body {
    padding-bottom: 8px;
  }
}

.TargetCampaignSettingsMisc__info-box {
  margin-bottom: 8px;
}

.TargetCampaignSettingsMisc__item {
  position: relative;
  padding: 16px 0;

  & + & {
    border-top: solid 1px $color-grey-35;
  }
}

.TargetCampaignSettingsMisc__button {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}
</style>

<i18n locale="ko">
{
  "description": "발송 관리, 테스트 등 필수 설정 이외의 설정을 할 수 있습니다.",
  "dispatch_sample_title": "테스트 발송",
  "dispatch_sample_not_configured_tooltip": "디자인 항목의 설정을 완료하면 테스트 발송을 할 수 있습니다.",
  "dispatch_sample_message_html": "실제 발송 3시간 전 <b>{0}</b> 테스트 메시지 발송",
  "dispatch_limit_title": "발송 제한",
  "dispatch_limit_message": {
    "NONE": "발송 비용이 잔여 금액(포인트+캐시)을 넘지 않는 경우 발송",
    "PER_COHORT": "<b>1회 발송당</b> 최대 <b>{0}원</b> 발송",
    "DAILY": "<b>하루</b> 최대 <b>{0}원</b> 발송",
    "MONTHLY": "<b>한달 동안</b> 최대 <b>{0}원</b> 발송"
  },
  "dispatch_estimation_title": "예상 발송수",
  "dispatch_estimation_message": "{0}: {1}/일",
  "calculable": "계산",
  "pending": "계산 대기 중",
  "calculating": "계산 중"
}
</i18n>
