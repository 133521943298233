var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppStatCard',_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:(
      _vm.stats.campaign_start_date
        ? ((_vm.stats.campaign_start_date) + " ~ " + (_vm.stats.end_date))
        : null
    ),expression:"\n      stats.campaign_start_date\n        ? `${stats.campaign_start_date} ~ ${stats.end_date}`\n        : null\n    "}],staticClass:"ReviewMessagesCampaign__stat-card",attrs:{"title":_vm.$t('stats.review_with_message_rate')}},'AppStatCard',_vm.reviewWithMessageStat,false)),(_vm.isSuperAdminAccessible)?_c('AppStatCard',_vm._b({staticClass:"ReviewMessagesCampaign__stat-card super-admin__item",attrs:{"title":_vm.$t('stats.review_without_message_rate')}},'AppStatCard',_vm.reviewWithoutMessageStat,false)):_vm._e(),_c('div',{staticClass:"ReviewMessagesCampaign__stat-hint"},[_vm._v(" "+_vm._s(_vm.$t('date_range', [ _vm.formatDate(_vm.stats.start_date), _vm.formatDate(_vm.stats.end_date) ]))+" ")]),_c('AppResourceTable',{staticClass:"ReviewMessagesCampaign__table",attrs:{"columns":_vm.columns,"rows":_vm.rows,"resources":_vm.campaigns,"table-id":"campaigns"},on:{"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
    var row = ref.row;
    var column = ref.column;
    var value = ref.value;
return [(column === 'effect')?[_c('ReviewMessagesCampaignsEffectCell',{attrs:{"effect":row.effect}})]:(column === 'updated_at')?[_c('ReviewMessagesCampaignsUpdatedAtCell',{attrs:{"created-at":row.created_at,"updated-at":row.updated_at}})]:(column === 'manage')?[_c('ReviewMessagesCampaignsManageButton',{attrs:{"campaign":row},on:{"change-status":_vm.refreshResource}}),_c('AppButton',{attrs:{"label":_vm.$t('app.settings'),"button-style":"mango-outline","type":"link","to":("/review/messages/campaigns/" + (row.id))}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }