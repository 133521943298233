<template>
  <div class="TheSettingsDialogThirdParty">
    <div class="TheSettingsDialogThirdParty__head">{{ $t('title') }}</div>
    <BizMessageSettings />
  </div>
</template>

<script>
import BizMessageSettings from '../views/biz_messages/components/BizMessageSettings.vue';

export default {
  name: 'TheSettingsDialogThirdParty',
  components: { BizMessageSettings }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheSettingsDialogThirdParty__head {
  @include text-sub-title;
  margin-bottom: 32px;
}
</style>

<i18n locale="ko">
{
  "title": "알림톡"
}
</i18n>
