export default {
  methods: {
    isManualMileageMaxAmountConfirmNeeded(mileage_amount) {
      const {
        use_manual_mileage_max_amount,
        manual_mileage_max_amount_cents
      } = this.reviewSettings;

      return (
        use_manual_mileage_max_amount &&
        manual_mileage_max_amount_cents < mileage_amount
      );
    }
  }
};
