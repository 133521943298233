import { constructEnum } from '@/lib/enum';

export default constructEnum('ProductStatusReason', {
  REVIEW_DISPLAY: 100,
  FINAL_PRICE_ZERO: 200,
  NO_URL: 300,
  NO_NAME: 400,
  NO_PRODUCT_IMAGE: 500,
  BLACKLIST: 600,
  SOLD_OUT: 700,
  NOT_DISPLAY: 800
});
