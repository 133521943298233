<template>
  <div class="TheEasyJoinFailure">
    <div class="TheEasyJoinFailure__inner">
      <AppSvgIcon
        name="icon-crema-logo"
        :height="30"
        class="TheEasyJoinFailure__logo"
      />
      <!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->
      <AppInfoBox
        class="TheEasyJoinFailure__info-box"
        v-html="$t('info_html')"
      />
      <!-- eslint-enable vue/no-v-text-v-html-on-component vue/no-v-html -->
      <AppProgressSteps
        class="TheEasyJoinFailure__progress-step"
        :steps="[
          $t('easy_join_progress_step.create_account'),
          $t('easy_join_progress_step.manager_info')
        ]"
        :current-step="0"
      />
      <AppForm
        id="easyJoinFailure"
        object-id="registration_failure"
        v-bind="formProps"
        :submit-button="{
          submitLabel: $t('app.confirmed'),
          buttonClasses: ['TheEasyJoinFailure__submit']
        }"
        v-on="formEvents"
      >
        <input
          type="hidden"
          name="registration_failure[shop_key]"
          :value="shop_key"
        />
        <input
          type="hidden"
          name="registration_failure[email]"
          :value="email"
        />
      </AppForm>
    </div>
  </div>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';

export default {
  name: 'TheEasyJoinFailure',
  mixins: [FormView],
  data() {
    const { shop_key, email } = this.$route.query;
    return {
      shop_key,
      email
    };
  },
  computed: {
    formSections() {
      return [
        {
          id: 'failure_section',
          groups: [
            {
              id: 'guide',
              label: '',
              type: 'static',
              value: this.$t('failure_guide')
            },
            {
              id: 'phone',
              label: '',
              type: 'text',
              placeholder: this.$t('phone_placeholder')
            }
          ]
        }
      ];
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/easy_join/registration_failures/notify', formData)
        .then(() => (this.isSubmitted = true))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.TheEasyJoinFailure {
  margin: 0 auto;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  text-align: center;
  padding-bottom: 25px;
}

.TheEasyJoinFailure__inner {
  width: 500px;
  margin: 0 auto;
  position: relative;
}

.TheEasyJoinFailure__logo {
  display: block;
  margin: 53px auto 40px;
  color: black;
}

.TheEasyJoinFailure__info-box {
  margin-bottom: 32px;
}

.TheEasyJoinFailure__progress-step {
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid $color-grey-25;
  text-align: left;
}

::v-deep {
  #failure_section--guide {
    text-align: center;
    margin-top: 100px;
  }

  #failure_section--guide a {
    text-decoration: underline;
  }

  #failure_section--phone {
    width: 436px;
  }

  .AppForm__form-controls {
    float: right;
    padding-top: 0;
    margin-top: -36px;
  }
}
</style>

<i18n locale="ko">
{
  "info_html": "<b>크리마 리뷰 앱 설치가 완료되었습니다🎉</b><br>지금 계정을 생성해서, 오늘 크리마 리뷰를 쇼핑몰에 도입해보세요.",
  "failure_guide": "계정 생성 중 문제가 발생했습니다.<br>연락 가능한 휴대전화 번호를 남겨주시면 크리마에서 원인 확인 후 연락 드리겠습니다.<br>궁금한 점이 있으실 경우 <a href='https://www.cre.ma/contact-2' target='_blank'>크리마 홈페이지 서비스 문의</a>에 문의 남겨주시면<br>확인 후 답변드리겠습니다.",
  "phone_placeholder": "연락 가능한 휴대전화 번호를 입력해주세요."
}
</i18n>
