<template>
  <AppAjaxContent :is-loading="isLoading" class="fit-size-feedback-products">
    <AppSearchbar @submit="searchResource">
      <template #right>
        <li>
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="searchTypes"
          />
        </li>
        <li>
          <AppButtonToggle
            v-model="advancedSearchVisible"
            :label="$t('app.advanced_search')"
          />
        </li>
      </template>
      <template #advanced-search>
        <AppAdvancedSearch
          v-model="resourceParams"
          :visible="advancedSearchVisible"
          :sections="advancedSearchSections"
          @submit="searchResource"
        />
      </template>
    </AppSearchbar>

    <AppResourceTable
      enable-refresh
      :resources="products_with_review_options"
      :columns="columns"
      :rows="rows"
      @refresh="refreshResource"
      @paginate="paginate"
    >
      <template #cell="{ column, row, value }">
        <template v-if="column == 'feedback_last_updated_at'">
          <div
            v-tooltip="
              `${$t(
                'fit_size_feedback_products.feedback_last_updated_at'
              )}: ${formatDateTime(value)}`
            "
          >
            <div class="FitSizeFeedbackProducts__date">
              {{ value | formatDate }}
            </div>
            <div class="FitSizeFeedbackProducts__detail">
              <AppBadge :label="$t('app.detail_info')" />
            </div>
          </div>
        </template>
        <template v-else-if="column == 'max_fit_score'">
          <div v-if="value">
            <div
              v-tooltip="{
                message: fitScorePercentagesText(row.fit_score_percentages),
                textAlign: 'left',
                horizontal: true
              }"
            >
              <div class="FitSizeFeedbackProducts__max_fit_score">
                {{ `${value.label} ${value.value}%` }}
              </div>
              <div class="FitSizeFeedbackProducts__detail">
                <AppBadge :label="$t('app.detail_info')" />
              </div>
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template v-else-if="column == 'fit_messages_count'">
          <div>{{ `${value > 0 ? $t('unit.count', [value]) : '-'}` }}</div>
        </template>
        <template v-else-if="column == 'actions'">
          <AppButton
            :label="$t('app.detail_breakdown')"
            @click="openFitSizeFeedbackProductDialog(row.product)"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import ResourceView from '@/mixins/ResourceView';

export default {
  name: 'FitSizeFeedbackProducts',
  mixins: [ResourceView],
  data() {
    return {
      advancedSearchVisible: 'product_category_id' in this.$route.query,
      columns: [
        {
          id: 'product_code',
          label: this.$t('products.code'),
          type: 'pre'
        },
        {
          id: 'product',
          label: this.$t('app.product'),
          type: 'product'
        },
        {
          id: 'feedback_last_updated_at',
          label: this.$t('fit_size_feedback_products.feedback_last_updated_at')
        },
        {
          id: 'max_fit_score',
          label: this.$t('fit_size_feedback_products.max_fit_score')
        },
        {
          id: 'fit_messages_count',
          label: this.$t('fit_size_feedback_products.fit_messages_count'),
          type: 'number'
        },
        { id: 'actions', label: this.$t('app.actions') }
      ],
      searchTypes: [
        { value: 'code', label: this.$t('products.code') },
        { value: 'name', label: this.$t('products.name') }
      ]
    };
  },
  computed: {
    ...mapState('fitSizeFeedbackProduct', ['products_with_review_options']),
    ...mapGetters('fitSizeFeedbackProduct', [
      'isFetchingProductsWithReviewOption'
    ]),
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      search_type: 'name',
      search_query: ''
    }),
    isLoading() {
      return (
        this.products_with_review_options.isNull ||
        this.isFetchingProductsWithReviewOption
      );
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    advancedSearchSections() {
      return [
        {
          groups: [
            {
              id: 'product_category_id',
              label: this.$t('app.category'),
              type: 'select_product_category',
              placeholder: this.$t('app.all')
            }
          ]
        }
      ];
    },
    rows() {
      return this.products_with_review_options.items.map(product => {
        const fitScorePercentages = product.fit_score_percentages.map(
          fitScorePercentage => ({
            label: fitScorePercentage['fit_score_name'],
            value: fitScorePercentage['fit_score_value']
          })
        );

        let row = {
          product_code: product.code,
          product: product,
          feedback_last_updated_at: product.feedback_last_updated_at,
          fit_score_percentages: fitScorePercentages,
          fit_messages_count: product.fit_messages_count
        };

        if (fitScorePercentages) {
          row['max_fit_score'] = _.maxBy(fitScorePercentages, 'value');
        }

        return row;
      });
    }
  },
  beforeDestroy() {
    this.resetProductsWithReviewOption();
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('fitSizeFeedbackProduct', [
      'fetchProductsWithReviewOption',
      'resetProductsWithReviewOption'
    ]),
    fetchResource(params) {
      this.fetchProductsWithReviewOption(params);
    },
    fitScorePercentagesText(fitScorePercentages) {
      let result = [];
      fitScorePercentages.forEach(fitScorePercentage => {
        result.push(
          `${fitScorePercentage['label']} ${fitScorePercentage['value']}%`
        );
      });
      return result.join('\n');
    },
    openFitSizeFeedbackProductDialog(product) {
      this.openDialog(['FitSizeFeedbackProductDialog', { product }]);
    }
  }
};
</script>
