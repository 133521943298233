var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TargetCampaignProductRecommendationWidgetPreview"},[(_vm.showDeviceTypeSelectButton)?_c('AppSelectButton',{staticClass:"TargetCampaignProductRecommendationWidgetPreview__device-type-selector",attrs:{"value":_vm.deviceType,"options":_vm.widgetDeviceTypeOptions},on:{"change":_vm.changeDeviceType}}):_vm._e(),(!_vm.showDeviceTypeSelectButton)?_c('div',{staticClass:"TargetCampaignProductRecommendationWidgetPreview__empty-box"}):_vm._e(),_c('AppInfoBox',{staticClass:"TargetCampaignProductRecommendationWidgetPreview__guide",attrs:{"hide-type":"snooze","info-box-id":"target-product-recommendation-widget-guide"}},[_vm._v(" "+_vm._s(_vm.$t('preview_guide_content'))+" ")]),_c(_vm.isCartPopupWidgetCampaign
        ? 'AppCartPopupPreview'
        : _vm.isPc
        ? 'div'
        : 'AppMobilePhonePreview',{tag:"Component",class:[
      'TargetCampaignProductRecommendationWidgetPreview__iframe',
      _vm.isCartPopupWidgetCampaign
        ? 'TargetCampaignProductRecommendationWidgetPreview__iframe--popup'
        : _vm.isPc
        ? 'TargetCampaignProductRecommendationWidgetPreview__iframe--pc'
        : 'TargetCampaignProductRecommendationWidgetPreview__iframe--mobile',
      !_vm.isCartPopupWidgetCampaign && !_vm.showDeviceTypeSelectButton
        ? _vm.isPc
          ? 'TargetCampaignProductRecommendationWidgetPreview__iframe--no-buttons-pc'
          : 'TargetCampaignProductRecommendationWidgetPreview__iframe--no-buttons-mobile'
        : null,
      _vm.withForm
        ? _vm.isCartPopupWidgetCampaign
          ? 'TargetCampaignProductRecommendationWidgetPreview__iframe--popup-with-form'
          : 'TargetCampaignProductRecommendationWidgetPreview__iframe--with-form'
        : null
    ],style:(!_vm.isCartPopupWidgetCampaign && _vm.isPc ? { height: _vm.iframeHeight } : null)},[_c('AppIframeViewer',{attrs:{"src":_vm.previewUrl,"is-loading-forced":_vm.isLoading || _vm.displayVersion !== _vm.currentVersion}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }