<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppButton label="Run Job" @click="createBasicAsyncJob" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">With Custom Progress Status</label>
      </div>
      <AppButton label="Run Job" @click="createCustomAsyncJob('진행상태')" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Confirm Job Removal</label>
      </div>
      <AppButton label="Run Job" @click="createRemoveConfirmableAsyncJob" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'UiMultiAsyncJob',
  computed: {
    ...mapState('multiAsyncJob', ['jobs']),
    job() {
      return {
        title: '테스트',
        progressCount: 0,
        totalCount: 10,
        startedAt: new Date()
      };
    }
  },
  methods: {
    ...mapActions('multiAsyncJob', ['enqueueJob']),
    ...mapMutations('multiAsyncJob', ['REMOVE_JOB']),
    ...mapActions('dialog', ['openDialog']),
    createBasicAsyncJob() {
      this.enqueueJob({
        ...this.job,
        jobHandler: async ({ progressTo }) => {
          for (let i = 1; i <= this.job.totalCount; i++) {
            await new Promise(resolve =>
              setTimeout(() => {
                progressTo({ progressCount: i });
                resolve();
              }, 1000)
            );
          }
          return true;
        }
      });
    },
    createCustomAsyncJob(progressStatus) {
      this.enqueueJob({
        ...this.job,
        progressStatus: `${progressStatus} - 0`,
        jobHandler: async ({ progressTo }) => {
          for (let i = 1; i <= this.job.totalCount; i++) {
            await new Promise(resolve =>
              setTimeout(() => {
                progressTo({
                  progressCount: i,
                  progressStatus: `${progressStatus} - ${i}`
                });
                resolve();
              }, 1000)
            );
          }
          return true;
        }
      });
    },
    createRemoveConfirmableAsyncJob() {
      this.enqueueJob({
        ...this.job,
        id: 'remove_confirmable_job',
        jobHandler: async ({ progressTo }) => {
          for (let i = 1; i <= this.job.totalCount; i++) {
            await new Promise(resolve =>
              setTimeout(() => {
                progressTo({ progressCount: i });
                resolve();
              }, 100)
            );
          }
          return true;
        },
        resultHandler: () =>
          this.openDialog([
            'AppMessageDialog',
            {
              title: '끝!',
              type: 'confirm',
              markdownText: '',
              width: DialogSize.AUTO
            }
          ]).then(eventBus =>
            eventBus.$on('close', () =>
              this.REMOVE_JOB('remove_confirmable_job')
            )
          ),
        removeAfterConfirm: true
      });
    }
  }
};
</script>

<style scoped>
.section__title {
  position: relative;
}
</style>
