import { constructEnum } from '@/lib/enum';
import MessageServiceType from '@/enums/MessageServiceType';

const SmsCallStatus = constructEnum('SmsCallStatus', {
  NONE: 0,
  SMS_SENDING: 4000,
  SMS_SENT: 4100,
  SMS_TIMEOUT: 4421,
  SMS_RETRY_LIMIT_EXCEEDED: 4426,
  SMS_PROCESSING_DEVICE_CODE: 4425,
  SMS_SHADOW_AREA: 4400,
  SMS_DEVICE_POWER_OFF: 4401,
  SMS_DEVICE_MESSAGE_LIMIT_EXCEEDED: 4402,
  SMS_WRONG_NUMBER: 4410,
  SMS_DEVICE_PAUSED: 4422,
  SMS_DEVICE_ETC: 4427,
  SMS_DEVICE_BUSY: 4405,
  SMS_DEVICE_BLOCKED: 4423,
  SMS_BLOCKED: 4412,
  SMS_NPDB_ERROR: 4411,
  SMS_SYSTEM_ERROR: 4428,
  SMS_MEMBER_NO_GEO_INFO: 4404,
  SMS_SMSC_FORMAT_ERROR: 4413,
  SMS_NONMEMBER_DISCONNECTED_TERMINATED: 4414,
  SMS_URL_SMS_NOT_SUPPORTED: 4424,
  SMS_MESSAGE_DELETED: 4403,
  SMS_SPAM: 4430,
  SMS_BLIND_NUMBER_UNSUBSCRIBE: 4431,
  SMS_STEALING_NUMBER_BLOCKED_SERVICE_REGISTERED: 4433,
  SMS_BLOCKED_BY_UNREGISTERED_SENDER_NUMBER: 4434,
  SMS_SPAMED_SENDER_NUMBER_BY_KISA: 4435,
  SMS_SENDER_NUMBER_VIOLATED: 4436,
  SMS_ETC_ERROR: 4420,
  MMS_SENT: 6600,
  MMS_TIMEOUT: 6601,
  MMS_PROCESSING_DEVICE_CODE: 6602,
  MMS_SHADOW_AREA: 6603,
  MMS_DEVICE_POWER_OFF: 6604,
  MMS_DEVICE_MESSAGE_LIMIT_EXCEEDED: 6605,
  MMS_WRONG_NUMBER: 6606,
  MMS_DEVICE_PAUSED: 6607,
  MMS_DEVICE_ETC: 6608,
  MMS_BLOCKED: 6609,
  MMS_ETC_ERROR: 6610,
  MMS_SMC_FORMAT_ERROR: 6611,
  MMS_GATEWAY_FORMAT_ERROR: 6612,
  MMS_DEVICE_SERVICE_NOT_AVAILABLE: 6613,
  MMS_DEVICE_SIGNAL_NOT_AVAILABLE: 6614,
  MMS_DELETE_BY_SMC_MANAGER: 6615,
  MMS_MESSAGE_QUEUE_EXCEEDED: 6616,
  MMS_SPAM: 6617,
  MMS_SPAM_FROM_FAIR_TRADE_COMMISSION: 6618,
  MMS_SPAM_FROM_GATEWAY: 6619,
  MMS_SENT_LIMIT_EXCEEDED: 6620,
  MMS_MESSAGE_LENGTH_EXCEEDED: 6621,
  MMS_WRONG_NUMBER_FORMAT: 6622,
  MMS_WRONG_DATA_FORMAT: 6623,
  MMS_INFO_NOT_FOUND: 6624,
  MMS_NPDB_ERROR: 6625,
  MMS_080UNSUBSCRIBE: 6626,
  MMS_BLIND_NUMBER_UNSUBSCRIBE: 6627,
  MMS_STEALING_NUMBER_BLOCKED_SERVICE_REGISTERED: 6629,
  MMS_OUT_OF_SERVICE_NUMBER: 6630,
  MMS_BLOCKED_BY_UNREGISTERED_SENDER_NUMBER: 6631,
  MMS_SPAMED_SENDER_NUMBER_BY_KISA: 6632,
  MMS_SENDER_NUMBER_VIOLATED: 6633,
  MMS_IMAGE_FILE_SIZE_LIMIT: 6670,
  COMMON_PREPAID_NOT_ALLOWED: 9903,
  COMMON_BLOCK_TIME_DATE: 9904,
  COMMON_REVOKED: 9082,
  COMMON_IP_BLOCKED: 9083,
  COMMON_CALLBACK_ERROR: 9023,
  COMMON_BLOCK_TIME_WEEKDAY: 9905,
  COMMON_INVALID_ID: 9010,
  COMMON_INVALID_PASSWORD: 9011,
  COMMON_TOO_MANY_DUPLICATED_ACCESS: 9012,
  COMMON_DATA_TIMED_OUT: 9013,
  COMMON_TIME_LIMIT: 9014,
  COMMON_WRONG_DATA_FORMAT: 9020,
  COMMON_UNDEFINED: 9021,
  COMMON_WRONG_DATA_FORMAT_CINFO_NOT_VALID: 9022,
  COMMON_DENY_USER_ACK: 9080,
  COMMON_WRONG_PHONE_NUM: 9214,
  COMMON_FAX_FILE_NOT_FOUND: 9311,
  COMMON_PHONE_FAX_PREPAID_NOT_ALLOWED: 9908,
  COMMON_ETC_ERROR: 9090,
  COMMON_INVALID_DATA_FORMAT: -1,
  MAX_CHECK_REPORT_ATTEMPTS_EXCEEDED: 10000,
  ERROR_TIMEOUT: 10010,
  ERROR_SEND_SMS_FAILED: 10020,

  TERMINATED_USER: 20000,
  UNREGISTERED_USER: 20010,
  ALREADY_CONNECTED: 20020,
  UNSUPPORTED_VERSION: 20030,
  SMS_UNAUTHORIZED: 20040,
  MMS_UNAUTHORIZED: 20050,
  ISMS_UNAUTHORIZED: 20060,
  NETWORK_ERROR: 20070,
  NOT_BINDED: 20080,
  ENCRYPT_ERROR: 20090,
  DECRYPT_ERROR: 20100,
  INVALID_HEADER: 20110,
  INVALID_BODY: 20120,
  INVALID_FORMAT: 20130,
  SENDER_OR_RECEIVER_NUMBER_ERROR: 20140,
  CONTENT_SIZE_ERROR: 20150,
  ATTACHMENT_COUNT_ERROR: 20160,
  CONTENT_ETC: 20170,
  SERIAL_NUMBER_ERROR: 20180,
  INVALID_MESSAGE_TYPE: 20190,
  BROADCAST_LIMIT_EXCEEDED: 20200,
  REQUEST_PER_SEC_EXCEEDED: 20210,
  DAILY_LIMIT_EXCEEDED: 20220,
  MONTHLY_LIMIT_EXCEEDED: 20230,
  INVAILD_HOUR: 20240,
  NOT_ENOUGH_CREDIT: 20250,
  DUPLICATED: 20260,
  SEND_TIME_ERROR: 20270,
  UNLAWFUL_SENDER_NUMBER: 20280,
  UNREGISTERED_SENDER_NUMBER: 20290,
  DISGUISED_SENDER_NUMBER: 20300,
  STOLEN_SENDER_NUMBER: 20310,
  NO_ROUTING_INFO: 20320,
  REALTIME_DELIVERY_ERROR: 20330,
  GW_EXPIRED_NO_REPORT: 20340,
  GW_EXPIRED_NOT_SENT: 20350,
  ATTACHMENT_ERROR: 20360,
  TELECOM_SYSTEM_FAILURE: 20370,
  TELECOM_RECEIVER_NUMBER_SPAM: 20380,
  TELECOM_SPAM: 20390,
  TELECOM_EXPIRED: 20400,
  MMS_DEVICE_UNSUPPORTED: 20410,
  DEVICE_NOT_SUPPORTED: 20420,
  CHANGED_NUMBER: 20430,
  UNIDENTIFIED_TELECOM: 20440,
  TELECOM_CONTENT_ERROR: 20450,
  TELECOM_UNLAWFUL_SENDER_NUMBER: 20460,
  TELECOM_UNREGISTERED_SENDER_NUMBER: 20470,
  TELECOM_DISGUISED_SENDER_NUMBER: 20480,
  TELECOM_STOLEN_SENDER_NUMBER: 20490,
  TELECOM_ETC: 20500,
  INVALID_EXTENSION: 20510,
  FILE_SIZE_ERROR: 20520,
  BROADCAST_COUNT_MISMATCH: 20530,
  BLOCKED_SENDER_NUMBER: 20540,
  ENCODING_ERROR: 20550,
  INVALID_RECEIVER_NUMBER: 20560,
  MESSAGE_SIZE_ERROR: 20570,
  FILE_NOT_FOUND: 20580,
  REPLY_NUMBER_REQUIRED: 20590,
  ATTACHMENT_LIMIT_EXCEEDED: 20600,
  BROADCAST_KEY_ERROR: 20610,
  SEND_TIME_REPORT_NOT_RECEIVED: 20620,
  BROADCAST_MAX_COUNT_EXCEEDED: 20630,
  SPAM_SYSTEM: 20640,
  CAPACITY_EXCEEDED: 20650
});

SmsCallStatus.text = function({ call_status, message_service_type }) {
  const status = this.toKey(call_status);
  const messageKey = `${MessageServiceType.toKey(
    message_service_type
  )}.${status}`;

  return this.te(messageKey) ? this.t(messageKey) : status;
};

export default SmsCallStatus;
