<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{
      ...formProps,
      objectId: 'channel_transaction',
      detectFormDataChange: false
    }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="channel_transaction[transaction_type]"
      :value="transactionType"
    />
    <input
      type="hidden"
      name="channel_transaction[description]"
      :value="description"
    />
  </AppModalForm>
</template>

<script>
import { mapMutations } from 'vuex';
import moment from 'moment';
import api from '@/lib/api';
import ChannelTransactionType from '@/enums/ChannelTransactionType';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ThePaymentHistoryBonusChannelDialog',
  mixins: [DialogFormView],
  data() {
    const { channelStatus } = this.$store.state.session;
    return {
      orgFormObject: {
        expiry_date: channelStatus?.channel_expires_at
          ? moment(channelStatus.channel_expires_at).toVal()
          : null
      }
    };
  },
  computed: {
    transactionType() {
      return ChannelTransactionType.PERIOD_BONUS;
    },
    description() {
      return (
        this.formObject.description || this.$t('form.description.placeholder')
      );
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'description',
              name: null,
              label: this.$t('form.description.label'),
              type: 'text',
              placeholder: this.$t('form.description.placeholder')
            },
            {
              id: 'expiry_date',
              label: this.$t('form.expiry_date.label'),
              type: 'date',
              validate: [
                'required',
                {
                  rule: () =>
                    this.formObject.expiry_date >
                    this.orgFormObject.expiry_date,
                  errorMessage: this.$t(
                    'form.expiry_date.errors.expiry_date_should_be_after',
                    [this.orgFormObject.expiry_date]
                  )
                }
              ]
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapMutations('session', ['SET_CHANNEL_STATUS']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/payment_history/channel_transactions', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          if (data.channel_status) this.SET_CHANNEL_STATUS(data.channel_status);
          this.eventBus.$emit('update');
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "보너스 기간 충전하기",
  "sub_title": "애드 이용내역",
  "form": {
    "description": {
      "label": "메시지 입력",
      "placeholder": "보너스 기간 충전"
    },
    "expiry_date": {
      "label": "만료일 변경",
      "errors": {
        "expiry_date_should_be_after": "{0} 이후의 날짜를 지정해야 합니다."
      }
    }
  }
}
</i18n>
