<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm
      class="TheAdvancedSettingsDialogInitJsInstaller__form"
      v-bind="formProps"
      v-on="formEvents"
    >
      <template #group="{ id }">
        <div class="AppForm__group-field">
          <AppCheckbox :label="$t('is_responsive')" :checked="true" disabled />
        </div>
        <div class="AppForm__group-field">
          <AppButton
            class="TheAdvancedSettingsDialogInitJsInstaller__install-button"
            :label="$t('install')"
            button-style="red-outline"
            @click="installInitJs"
          />
        </div>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapState } from 'vuex';
import ShopBuilder from '@/enums/ShopBuilder';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';

export default {
  name: 'TheAdvancedSettingsDialogInitJsInstaller',
  mixins: [FormView],
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    formSections() {
      return [
        {
          id: 'install_init_js',
          label: `${ShopBuilder.t(this.currentBrand.shop_builder)} ${this.$t(
            'title'
          )}`,
          groups: [
            {
              id: 'install_init_js',
              label: ''
            }
          ]
        }
      ];
    }
  },
  methods: {
    installInitJs() {
      this.isLoading = true;
      api
        .patch(
          '/init_js_installers/install',
          {},
          {
            successMessage: this.$t('success')
          }
        )
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.TheAdvancedSettingsDialogInitJsInstaller__form {
  width: 680px;
  border-radius: 4px;
  margin: 16px auto 32px auto;
  padding: 24px;
  background-color: #fff;
}

.TheAdvancedSettingsDialogInitJsInstaller__install-button {
  margin: 4px 0;
}
</style>

<i18n locale="ko">
  {
    "title": "공통 스크립트 init.js 설치",
    "is_responsive": "반응형 여부",
    "install": "설치",
    "success": "init.js 설치가 완료되었습니다."
  }
</i18n>
