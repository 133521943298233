<template>
  <TheAccountMenuPopupService
    v-if="fitStatus"
    service="fit"
    :content-tooltip="isFitTesting ? testingContentTooltip : ''"
    :items="items"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TheAccountMenuPopupService from './TheAccountMenuPopupService';

export default {
  name: 'TheAccountMenuPopupServiceFit',
  components: { TheAccountMenuPopupService },
  computed: {
    ...mapState('session', ['fitStatus']),
    ...mapGetters('alert', ['fitRemainingDates', 'fitRemainingMonths']),
    ...mapGetters('session', ['isFitTesting']),
    items() {
      if (this.isFitTesting)
        return [{ title: this.$t('expires_on'), content: this.$t('testing') }];
      else
        return [
          {
            title: this.$t('expires_on'),
            content: this.formatDate(this.fitStatus.fit_expires_at),
            contentTooltip: this.operatingContentTooltip
          }
        ];
    },
    operatingContentTooltip() {
      return this.fitRemainingDates >= 0
        ? this.$t('days_before_expiration', [this.fitRemainingDates])
        : this.$t('days_after_expiration', [-this.fitRemainingDates]);
    },
    testingContentTooltip() {
      return this.$t('testing_tooltip', [this.fitRemainingMonths]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "expires_on": "만료일",
  "days_before_expiration": "잔여 {0}일",
  "days_after_expiration": "{0}일 전 만료",
  "testing": "테스트 중",
  "testing_tooltip": "테스트 중\n테스트 중일 때는 이용 기간이 차감되지 않습니다.\n서비스 이용 시작 시점부터 만료일이 계산됩니다.\n결제한 서비스 이용 기간: {0}개월 "
}
</i18n>
