export default {
  data() {
    return {
      defaultMinReviewsCount: 5
    };
  },
  methods: {
    minReviewsCountPreset(analyticsType) {
      return (
        localStorage.getItem(`${analyticsType}.min_reviews_count`) ||
        this.defaultMinReviewsCount
      );
    },
    maxReviewsCountPreset(analyticsType) {
      return localStorage.getItem(`${analyticsType}.max_reviews_count`);
    }
  }
};
