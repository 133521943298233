var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('input',_vm._b({class:[
    'AppNumberInput',
    ("AppNumberInput--digits-" + _vm.digits),
    { 'AppNumberInput--invalid': _vm.invalid, 'AppNumberInput--inline': _vm.inline }
  ],attrs:{"min":_vm.min !== null ? _vm.min : _vm.allowNegative ? null : 0,"max":_vm.max,"type":"number","step":Math.pow(10, -_vm.floatPrecision)},domProps:{"value":_vm.currentValue},on:{"paste":_vm.paste,"keydown":_vm.keydown,"input":function($event){return _vm.change($event.target.value)},"blur":_vm.blur}},'input',{
    id: _vm.id,
    name: _vm.name,
    disabled: _vm.disabled,
    placeholder: _vm.placeholder || _vm.defaultValue
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }