<template>
  <div v-if="product" v-tooltip="tooltip">
    <div class="table-line">
      <AppTableCellProduct :product="product" />
    </div>
    <div v-if="productOptions" class="table-line table-line--light">
      {{ productOptions }}
    </div>
    <div v-if="salePriceDisplayable" class="table-line table-line--light">
      {{ $t('sale_price', { price: formatCurrency(product.final_price) }) }}
    </div>
    <div v-if="subOrder" class="table-line table-line--light">
      {{ $t('purchase_price', { price: formatCurrency(subOrder.price) }) }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'ReviewReviewsProductCell',
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('session', ['isShopifyBrand']),
    productOptions() {
      return _.values(this.review.product_options).join(', ');
    },
    subOrder() {
      return this.review.sub_order;
    },
    product() {
      return this.review.product;
    },
    tooltip() {
      const messages = [this.product.name];
      if (this.salePriceDisplayable) {
        const salePrice = this.formatCurrency(this.product.final_price);
        messages.push(this.$t('sale_price', { price: salePrice }));
      }

      const purchasePrice = this.subOrder ? this.subOrder.price : null;
      if (purchasePrice) {
        const price = this.formatCurrency(purchasePrice);
        messages.push(this.$t('purchase_price', { price }));
      }

      const orderTotalPrice = this.review.order
        ? this.review.order.total_price
        : null;
      if (orderTotalPrice) {
        const price = this.formatCurrency(orderTotalPrice);
        messages.push(this.$t('order_total_price', { price }));
      }

      return messages.join('\n');
    },
    salePriceDisplayable() {
      return !this.isShopifyBrand;
    }
  }
};
</script>

<i18n locale="ko">
{
  "sale_price": "판매가: {price}",
  "purchase_price": "구매가: {price}",
  "order_total_price": "(주문 총액: {price})"
}
</i18n>
<i18n locale="en">
{
  "sale_price": "Sale price: {price}",
  "purchase_price": "Purchase price: {price}",
  "order_total_price": "(Order total price: {price})"
}
</i18n>
