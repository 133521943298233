var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ui-section"},[_vm._m(0),_c('AppDropdownMenuButton',{attrs:{"label":"Dropdown","menu-items":_vm.MENU_ITEMS}})],1),_c('div',{staticClass:"ui-section"},[_vm._m(1),_c('AppDropdownMenuButton',{attrs:{"label":"Dropdown","menu-items":_vm.MENU_ITEMS,"button-style":"mango-outline"}})],1),_c('div',{staticClass:"ui-section"},[_vm._m(2),_c('AppDropdownMenuButton',{attrs:{"label":"Dropdown","menu-items":_vm.MENU_ITEMS_WITH_SECTIONS,"button-style":"mango-outline"}})],1),_c('div',{staticClass:"ui-section"},[_vm._m(3),_c('div',{staticClass:"ui-section__item ui-section__item--right"},[_c('AppDropdownMenuButton',{attrs:{"label":"Dropdown","menu-items":_vm.MENU_ITEMS}})],1)]),_c('div',{staticClass:"ui-section"},[_vm._m(4),_c('div',{staticClass:"ui-section__item"},[_c('AppDropdownMenuButton',{attrs:{"label":"Dropdown","size":"small","menu-items":_vm.MENU_ITEMS}})],1)]),_c('div',{staticClass:"ui-section"},[_vm._m(5),_c('div',{staticClass:"ui-section__item"},[_c('AppDropdownMenuButton',{attrs:{"label":"Dropdown","button-style":"label","menu-items":_vm.MENU_ITEMS}})],1)]),_c('div',{staticClass:"ui-section"},[_vm._m(6),_c('div',{staticClass:"ui-section__item"},[_c('AppResourceTable',{attrs:{"columns":[{ id: 'col1' }, { id: 'col2', label: 'col2' }],"rows":[
          { col1: 'data1-1', col2: 'data1-2' },
          { col1: 'data2-1', col2: 'data2-2' }
        ]},scopedSlots:_vm._u([{key:"head-cell",fn:function(ref){
        var column = ref.column;
return [(column === 'col1')?[_c('AppDropdownMenuButton',{attrs:{"label":_vm.selectedColumn,"button-style":"table-head","menu-items":['col1(A)', 'col1(B)'].map(function (value) { return ({
                  label: value,
                  value: value,
                  selected: value === _vm.selectedColumn,
                  clickHandler: function (ref) {
                    var close = ref.close;

                    _vm.selectedColumn = value;
                    close();
                  }
                }); })}})]:_vm._e()]}}])})],1)]),_c('div',{staticClass:"ui-section"},[_vm._m(7),_c('div',{staticClass:"ui-section__item"},[_c('AppDropdownMenuButton',{attrs:{"label":"Dropdown","button-style":"label","menu-items":[
          {
            label: '1st menu item',
            title: '[Review]'
          },
          {
            label: '2nd menu item',
            title: '[Fit]'
          },
          {
            label: '3rd menu item',
            title: '[Target]'
          },
          {
            label: '4th menu item'
          },
          {
            label: '5th menu item',
            title: '[Target]',
            disabled: true
          }
        ]}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-section__title"},[_c('label',{attrs:{"for":"basic"}},[_vm._v("Basic Usage")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-section__title"},[_c('label',{attrs:{"for":"basic"}},[_vm._v("Different Color")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-section__title"},[_c('label',{attrs:{"for":"basic"}},[_vm._v("Menu with Sections")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-section__title"},[_c('label',{attrs:{"for":"basic"}},[_vm._v("Right Aligned")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-section__title"},[_c('label',{attrs:{"for":"basic"}},[_vm._v("Small")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-section__title"},[_c('label',{attrs:{"for":"basic"}},[_vm._v("[button-style=label]")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-section__title"},[_c('label',{attrs:{"for":"basic"}},[_vm._v("[button-style=table-head]")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-section__title"},[_c('label',{attrs:{"for":"basic"}},[_vm._v("with highlight title")])])}]

export { render, staticRenderFns }