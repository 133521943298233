export default [
  {
    component: 'DisplayProductManagementShow',
    path: '/display/product-managements/:id',
    subMenuId: 'display_product_managements'
  },
  {
    component: 'ChannelFeedShow',
    path: '/channel/feeds/:id',
    subMenuId: 'channel_feeds'
  },
  {
    component: 'ReviewMessagesCampaignShow',
    path: '/review/messages/campaigns/:id',
    subMenuId: 'review_messages_campaigns'
  },
  {
    component: 'QaJpegSimulationShow',
    path: '/qa/jpeg-simulations/:id',
    subMenuId: 'qa_jpeg_simulations'
  },
  {
    component: 'ReviewCategoryShow',
    path: '/review/categories/:id',
    subMenuId: 'review_categories'
  },
  {
    component: 'TargetCampaignSituationalShow',
    path: '/target/dm-campaigns/situational/:id',
    subMenuId: 'target_dm_campaigns_situational'
  },
  {
    component: 'TargetDmCampaignMassShow',
    path: '/target/dm-campaigns/mass/:id',
    subMenuId: 'target_dm_campaigns_mass'
  },
  {
    component: 'TargetCampaignProductRecommendationWidgetShow',
    path: '/target/widget-campaigns/product-recommendation/:id',
    subMenuId: 'target_widget_campaigns_product_recommendation'
  },
  {
    component: 'TargetCampaignSituationalShow',
    path: '/target/popup-campaigns/situational/:id',
    subMenuId: 'target_popup_campaigns_situational'
  }
];
