<template>
  <div>
    <div class="table-head-control">
      <AppButton :label="$t('add_button')" @click="add" />
    </div>
    <AppTable
      table-id="price-bonus-table"
      :columns="COLUMNS"
      :rows="currentBonusList"
      :no-data="$t('no_data')"
    >
      <template #cell="{ row, column, rowIndex }">
        <template v-if="column.id === 'price'">
          <i18n path="table_body.price">
            <AppCurrencyInput
              v-model="currentBonusList[rowIndex].price"
              name="mileage_settings[auto_mileage_by_prices][][price]"
              :invalid="!!errors[`auto_mileage_by_prices[${row.id}]`]"
              :digits="6"
              :default="0"
              @blur="validateField(row.id)"
              @change="validateFields"
            />
          </i18n>
          <AppFormError :error="errors[`auto_mileage_by_prices[${row.id}]`]" />
        </template>
        <template v-else-if="column.id === 'text_amount'">
          <AppCurrencyInput
            v-model="currentBonusList[rowIndex].text"
            name="mileage_settings[auto_mileage_by_prices][][text]"
            :digits="6"
            :default="0"
          />
        </template>
        <template v-else-if="column.id === 'photo_amount'">
          <AppCurrencyInput
            v-model="currentBonusList[rowIndex].photo"
            name="mileage_settings[auto_mileage_by_prices][][photo]"
            :digits="6"
            :default="0"
          />
        </template>
        <template v-else-if="column.id === 'delete'">
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            @click="$delete(currentBonusList, rowIndex)"
          />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ReviewSettingsMileageAmountPriceBonusTable',
  model: { prop: 'bonusList', event: 'change' },
  props: {
    bonusList: { type: Array, required: true },
    errors: { type: Object, required: true }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'price', label: this.$t('table_head.price'), align: 'left' },
        {
          id: 'text_amount',
          label: this.$t('table_head.text_amount'),
          align: 'left'
        },
        {
          id: 'photo_amount',
          label: this.$t('table_head.photo_amount'),
          align: 'left'
        },
        { id: 'delete', label: this.$t('table_head.delete') }
      ],
      currentBonusList: _.cloneDeep(this.bonusList)
    };
  },
  computed: {
    unselectedUserGradeIds() {
      return _.difference(
        this.userGrades.map(g => g.id),
        this.currentBonusList.map(b => b.id)
      );
    }
  },
  watch: {
    currentBonusList: {
      handler(value) {
        this.$emit('change', value);
      },
      deep: true
    }
  },
  methods: {
    add() {
      this.currentBonusList.push({
        id: _.uniqueId('price_bonus'),
        price: 0,
        text: 0,
        photo: 0
      });
    },
    change({ key }, value) {
      this.$emit('change', { key, value });
      this.validateField(key);
    },
    validateField(key) {
      this.$emit('validate-field', `auto_mileage_by_prices[${key}]`);
    },
    validateFields() {
      this.currentBonusList.forEach(({ id }) => this.validateField(id));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .price-bonus-table__text-amount,
  .price-bonus-table__photo-amount {
    width: 25%;
  }

  .price-bonus-table__delete {
    width: 76px;
  }
}
</style>

<i18n locale="ko">
{
  "add_button": "+ 구매금액 기준 조건 추가",
  "table_head": {
    "price": "구매 금액 기준",
    "text_amount": "텍스트 리뷰 추가 지급액",
    "photo_amount": "포토/동영상 리뷰 추가 지급액",
    "delete": "조건 삭제"
  },
  "table_body": {
    "price": "{0} 이상"
  },
  "no_data": "추가한 기준이 없습니다."
}
</i18n>
<i18n locale="en">
  {
    "add_button": "Add condition",
    "table_body": {
      "price": "when above {0}"
    }
  }
</i18n>
