<template>
  <div class="AppExpandableItem" @click="isVisible = !isVisible">
    <slot />
    <AppModeless
      v-if="isVisible"
      align="center"
      :style="{ marginLeft, marginTop }"
      @close="close"
    >
      <AppDropdownMenu :menu-items="menuItems" @close="close" />
    </AppModeless>
  </div>
</template>

<script>
export default {
  name: 'AppExpandableItem',
  props: {
    menuItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isVisible: false,
      marginLeft: null,
      marginTop: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      const parentDialog = this.$el.offsetParent;
      if (!parentDialog) return;

      const parentDialogRect = parentDialog.getBoundingClientRect();
      const marginLeftAlignOffset = 2;
      this.marginLeft = `-${parentDialogRect.width * 1.5 +
        marginLeftAlignOffset}px`;

      const expandableItemRect = this.$el.getBoundingClientRect();
      const menuItemsHeight = expandableItemRect.height * this.menuItems.length;
      const marginTopAlignOffset = 3;
      this.marginTop = `-${menuItemsHeight + marginTopAlignOffset}px`;
    });
  },
  methods: {
    close() {
      this.isVisible = false;
      this.$emit('close');
    }
  }
};
</script>
