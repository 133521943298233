<template>
  <AppModalDefault :title="$t('title')" @close="close">
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <template #default>
          <AppDataList :data="data" gap="wide">
            <template #value="{ id }">
              <template v-if="id === 'packages'">
                <div v-for="p in payment.packages" :key="p.id">
                  {{ packageName(p) }}
                </div>
              </template>
              <template v-else-if="id === 'bank_account'">
                <div>{{ $t('bank_account') }}</div>
                <div class="mt4">
                  <AppButtonCopyable
                    :label="$t('bank_account_copy_button')"
                    :value="$t('bank_account')"
                  />
                </div>
              </template>
            </template>
          </AppDataList>
        </template>
      </AppAjaxContent>
    </template>
    <template #foot>
      <AppButton :label="$t('app.confirmed')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import moment from 'moment';
import api from '@/lib/api';
import { mapState, mapGetters } from 'vuex';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'ThePaymentBankInfoDialog',
  mixins: [DialogView],
  props: { paymentId: { type: Number, required: true } },
  data() {
    return { isLoading: true, payment: null };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('payment', ['packageName']),
    data() {
      return [
        {
          id: 'packages',
          label: this.$t('data_label.packages')
        },
        {
          label: this.$t('data_label.price'),
          type: 'number',
          unit: 'currency.krw',
          value: this.payment.price
        },
        {
          id: 'bank_account',
          label: this.$t('data_label.bank_account')
        },
        {
          label: this.$t('data_label.sender_name'),
          value: this.payment.sender_name || this.currentBrand.name
        },
        {
          label: this.$t('data_label.due_date'),
          type: 'date',
          value: moment(this.payment.due_date).toDate(),
          info: this.$t('due_date_info')
        }
      ];
    }
  },
  created() {
    api
      .get(`/payment/payments/${this.paymentId}`)
      .then(({ data }) => (this.payment = data.payment))
      .finally(() => (this.isLoading = false));
  }
};
</script>

<i18n locale="ko">
{
  "title": "무통장 입금 정보",
  "bank_account": "기업은행 541-030979-01-042 (주)크리마",
  "bank_account_copy_button": "계좌번호 복사",
  "due_date_info": "입금이 확인되면 자동으로 서비스가 활성화됩니다.",
  "data_label": {
    "packages": "구매 상품",
    "price": "총 입금 금액",
    "bank_account": "입금 정보",
    "sender_name": "입금자명",
    "due_date": "입금기한"
  }
}
</i18n>
