var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalDefault',{attrs:{"title":_vm.totalReportType === _vm.TargetTotalReportType.TOTAL
      ? _vm.$t('title_total', [_vm.displayDate])
      : _vm.$t('title', [_vm.displayDate]),"sub-title":_vm.totalReportType !== _vm.TargetTotalReportType.TOTAL
      ? _vm.TargetTotalReportType.t(_vm.totalReportType)
      : null,"width":"wide"},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppInfoBox',{staticClass:"TargetReportsTotalReportsDetailDialog__info"},[_vm._v(_vm._s(_vm.$t('info_messsage', [ _vm.totalReportType !== _vm.TargetTotalReportType.TOTAL ? _vm.TargetTotalReportType.t(_vm.totalReportType) : _vm.$t('affected_purchase_count') ]))+" ")]),_c('AppSmallDeviceScrollable',[_c('AppTable',{staticClass:"TargetReportsTotalReportsDetailDialog__section-table",attrs:{"columns":_vm.columns,"rows":_vm.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
      var column = ref.column;
      var row = ref.row;
return [(column.id === 'name')?[_c('div',{staticClass:"TargetReportsTotalReportsDetailDialog__campaign-group-type"},[_vm._v(" "+_vm._s(row.campaign_group_type ? _vm.TargetCampaignGroupType.t(row.campaign_group_type) : _vm.$t('custom_campaign'))+" ")]),_c('div',[_vm._v(_vm._s(row.name))])]:(column.id === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('show_stats')},on:{"click":function($event){return _vm.clickShowStats(row)}}})]:_vm._e()]}}])})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }