import { constructEnum } from '@/lib/enum';

export default constructEnum('BrandScale', {
  SOHO_UNDER_30_DELIVERIES_PER_DAY: 8,
  SOHO_30_AND_OVER_DELIVERIES_PER_DAY: 9,
  SOHO_UNDER_50_DELIVERIES_PER_DAY: 10,
  SOHO_50_AND_OVER_DELIVERIES_PER_DAY: 11,
  SOHO_100_AND_OVER_DELIVERIES_PER_DAY: 12,
  SOHO_200_AND_OVER_DELIVERIES_PER_DAY: 13,
  BRAND_50K_AND_UNDER_DAU: 20,
  BRAND_OVER_50K_DAU: 21,
  BRAND_OVER_100K_DAU: 22
});
