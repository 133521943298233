var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalDefault',{attrs:{"title":_vm.$t('title'),"sub-title":_vm.cohort.product_display_name,"width":"middle","can-maximize":""},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('div',{staticClass:"DisplayProductDisplayCohortDialog__info"},[_c('div',{staticClass:"DisplayProductDisplayCohortDialog__display-range"},[_c('div',{staticClass:"DisplayProductDisplayCohortDialog__label"},[_vm._v(" "+_vm._s(_vm.$t('display_range.title'))+" ")]),_c('div',{staticClass:"DisplayProductDisplayCohortDialog__content"},[_vm._v(" "+_vm._s(_vm.displayRange)+" ")])]),_c('div',{staticClass:"DisplayProductDisplayCohortDialog__nav-button"},[_c('AppButton',{attrs:{"label":_vm.$t('button.previous_cohort'),"disabled":!_vm.cohort.prev_cohort_id},on:{"click":function($event){return _vm.fetchData(_vm.cohort.prev_cohort_id)}}}),_c('AppButton',{attrs:{"label":_vm.$t('button.next_cohort'),"disabled":!_vm.cohort.next_cohort_id},on:{"click":function($event){return _vm.fetchData(_vm.cohort.next_cohort_id)}}})],1)]),(_vm.isCategoryPage)?_c('div',{staticClass:"DisplayProductDisplayCohortDialog__corners"},[_c('div',{staticClass:"DisplayProductDisplayCohortDialog__label"},[_vm._v(" "+_vm._s(_vm.$t('corners.title'))+" ")]),_c('div',{staticClass:"DisplayProductDisplayCohortDialog__content"},[(_vm.corners.length > 1)?[_c('i18n',{attrs:{"path":"unit.omitted_count"}},[_c('AppSelect',{attrs:{"options":_vm.corners.map(function (corner) { return ({
                    label: corner.name,
                    value: corner.code
                  }); })},model:{value:(_vm.cornerCode),callback:function ($$v) {_vm.cornerCode=$$v},expression:"cornerCode"}}),_vm._v(" "+_vm._s(_vm.corners.length - 1)+" ")],1)]:(_vm.corners.length)?[_vm._v(" "+_vm._s(_vm.corners[0].name)+" ")]:_vm._e()],2),_c('div',{staticClass:"DisplayProductDisplayCohortDialog__content"},_vm._l(([
              'products_count',
              'added_products_count',
              'removed_products_count'
            ]),function(key){return _c('span',{key:key,staticClass:"DisplayProductDisplayCohortDialog__corners-detail"},[_vm._v(" "+_vm._s(_vm.$t(("corners." + key)))),_c('b',{staticClass:"DisplayProductDisplayCohortDialog__corners-detail-number"},[_vm._v(_vm._s(_vm.cornerSnapshot[key]))])])}),0)]):_vm._e(),_c('DisplayProductDisplayPreviewSettingsButton',{staticClass:"DisplayProductDisplayCohortDialog__preview-settings-button"}),_c('DisplayCornerSnapshot',{attrs:{"products":_vm.cornerSnapshot.products || [],"show-spare-items-separately":false}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }