var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ChannelFeedFilterUnitsFormGroup"},[_c('AppButton',{staticClass:"ChannelFeedFilterUnitsFormGroup__add-button",attrs:{"label":_vm.$t('add_unit.label'),"disabled":_vm.cannotAddUnit,"tooltip":_vm.cannotAddUnit ? _vm.$t('tooltip.add_parent_unit') : ''},on:{"click":_vm.addUnit}}),_c('AppTable',{attrs:{"table-id":"units","no-data":_vm.$t('no_data'),"columns":_vm.columns,"rows":_vm.value},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
var rowIndex = ref.rowIndex;
return [(column.id === 'filter')?[_c('ChannelUnitFilterCell',{attrs:{"unit":row,"unit-index":rowIndex,"product-option-types":_vm.productOptionTypes,"errors":_vm.errors},on:{"change":function($event){return _vm.updateUnit($event, rowIndex)},"validate-unit-field":function($event){return _vm.validateUnitField($event, rowIndex)}}})]:(column.id === 'actions')?[_vm._l(([
            'id',
            'channel_filter_unit_id',
            'group_index',
            'recent_days',
            'filter_type',
            'filter_compare_type',
            'filter_compare_value',
            'date_compare_value',
            'product_option_type_id'
          ]),function(key,i){return _c('input',{key:("key-" + i),attrs:{"type":"hidden","name":(_vm.name + "[][" + key + "]")},domProps:{"value":row[key]}})}),(row.product_option_value)?_c('input',{attrs:{"type":"hidden","name":(_vm.name + "[][product_option_value_id]")},domProps:{"value":row.product_option_value.id}}):_vm._e(),(_vm.isParentUnit(row))?_c('AppButton',{attrs:{"label":_vm.$t('add_child_unit'),"tooltip":_vm.$t('tooltip.add_child_unit'),"disabled":_vm.cannotAddUnit},on:{"click":function($event){return _vm.addChildUnit(row, rowIndex)}}}):_vm._e(),_c('AppButton',{staticClass:"ChannelFeedFilterUnitsFormGroup__delete-button",attrs:{"label":_vm.$t('app.delete'),"tooltip":_vm.isParentUnit(row)
              ? _vm.$t('tooltip.delete_parent_unit')
              : _vm.$t('tooltip.delete_child_unit')},on:{"click":function($event){return _vm.deleteUnit(row, rowIndex)}}})]:_vm._e()]}}])}),(!_vm.value.length)?_c('input',{attrs:{"type":"hidden","name":(_vm.name + "[]")}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }