<template>
  <nav class="TheSubMenu">
    <div class="TheSubMenu__head">{{ selectedMainMenuItem.label }}</div>
    <AppScrollBox class="TheSubMenu__body">
      <template
        v-for="(subMenuItemsInGroup, groupId, index) in subMenuItemGroups"
      >
        <hr
          v-if="index > 0"
          :key="`divider-${groupId}`"
          class="TheSubMenu__divider"
        />
        <ul :key="groupId">
          <SubMenuItem
            v-for="subMenuItem in subMenuItemsInGroup"
            :key="subMenuItem.id"
            :sub-menu-item="subMenuItem"
            :has-child="0 < subMenuItem.children.length"
            @click="click(subMenuItem)"
          >
            <TransitionExpand>
              <ul v-if="subMenuItem.isChildSelected">
                <SubMenuItem
                  v-for="childItem in subMenuItem.children"
                  :key="childItem.id"
                  :sub-menu-item="childItem"
                  is2-depth
                  @click="click(childItem)"
                />
              </ul>
            </TransitionExpand>
          </SubMenuItem>
        </ul>
      </template>
      <ul class="TheSubMenu__misc-items">
        <li class="TheSubMenu__misc-item">
          <SubMenuAiComment />
        </li>
        <li class="TheSubMenu__misc-item">
          <SubMenuNoticeList />
        </li>
      </ul>
    </AppScrollBox>
  </nav>
</template>

<script>
import _ from 'lodash';

import { mapGetters } from 'vuex';

import SubMenuItem from './TheSubMenu/SubMenuItem';
import SubMenuAiComment from './TheSubMenu/SubMenuAiComment';
import SubMenuNoticeList from './TheSubMenu/SubMenuNoticeList';
import TransitionExpand from '@/transitions/TransitionExpand';

export default {
  name: 'TheSubMenu',
  components: {
    SubMenuItem,
    SubMenuAiComment,
    SubMenuNoticeList,
    TransitionExpand
  },
  computed: {
    ...mapGetters('menu', [
      'selectedMainMenuItem',
      'selectedSubMenuItemId',
      'subMenuItems'
    ]),
    ...mapGetters(['isSuperAdminAccessible', 'oldAdminUrl']),
    subMenuItemGroups() {
      const menuItems = this.subMenuItems
        .filter(e => e.parentId === this.selectedMainMenuItem.id)
        .map(item => {
          const children = this.subMenuItems.filter(
            e => e.parentId === item.id
          );
          const isChildSelected = children.some(
            i => i.id === this.selectedSubMenuItemId
          );
          return { ...item, children, isChildSelected };
        });
      return _.groupBy(menuItems, item => item.group || 0);
    }
  },
  methods: {
    click(item) {
      this.$emit('click', item);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_layouts.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';

.TheSubMenu {
  position: relative;
  height: 100%;

  @include media-breakpoint-each(mobile, tablet) {
    height: calc(100% - #{$topbar-height});
  }
}

$head-height-desktop: 61px;
$head-height-tablet: 73px;

.TheSubMenu__head {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: $head-height-desktop;
  padding: 12px 19px;
  @include text-sub-title;

  @include media-breakpoint-each(mobile, tablet) {
    height: $head-height-tablet;
    padding: 20px 24px;
    @include text-title;
  }
}

.TheSubMenu__body {
  position: relative;
  top: $head-height-desktop;
  height: calc(100% - #{$head-height-desktop});

  @include media-breakpoint-each(mobile, tablet) {
    top: $head-height-tablet;
    height: calc(100% - #{$head-height-tablet});
  }
}

.TheSubMenu__divider {
  height: 1px;
  border: none;
  background-color: $color-layout-section;
  margin: 25px 17px;
}

.TheSubMenu__misc-items {
  position: absolute;
  width: 100%;
  bottom: 15px;
}

.TheSubMenu__misc-item {
  @include media-breakpoint-each(mobile, tablet) {
    display: none;
  }
}
</style>
