<template>
  <div class="SmartstoreWaiting">
    <AppSpinner
      :class="[
        'SmartstoreWaiting__spinner',
        isWaiting ? '' : 'SmartstoreWaiting__spinner--hidden'
      ]"
    />
    <AppText class="SmartstoreWaiting__estimating" :text="message" />
    <AppButton
      v-if="batchJobButtonProps"
      class="SmartstoreWaiting__button"
      v-bind="batchJobButtonProps"
      @click="$emit('show')"
    />
    <AppButtonSubmit
      v-if="buttonProps"
      class="SmartstoreWaiting__button"
      v-bind="buttonProps"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'SmartstoreWaiting',
  props: {
    isWaiting: { type: Boolean, default: true },
    message: { type: String, required: true },
    batchJobButtonProps: { type: Object, default: null },
    buttonProps: { type: Object, default: null }
  },
  watch: {
    isWaiting() {
      if (this.isWaiting)
        this.SET_POLL_PARAMS({ key: 'smartstore_status', value: '1' });
      else this.UNSET_POLL_PARAMS('smartstore_status');
    }
  },
  mounted() {
    if (this.isWaiting)
      this.SET_POLL_PARAMS({ key: 'smartstore_status', value: '1' });
  },
  beforeDestroy() {
    this.UNSET_POLL_PARAMS('smartstore_status');
  },
  methods: {
    ...mapMutations('poll', ['SET_POLL_PARAMS', 'UNSET_POLL_PARAMS'])
  }
};
</script>

<style lang="scss" scoped>
.SmartstoreWaiting {
  text-align: center;
  padding-top: 52px;
}

.SmartstoreWaiting__spinner {
  font-size: 30px;

  &--hidden {
    visibility: hidden;
  }
}

.SmartstoreWaiting__estimating {
  margin-top: 10px;
}

.SmartstoreWaiting__button {
  margin-top: 16px;
}
</style>
