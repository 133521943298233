<template>
  <AppDataSection :title="title" gap="wide">
    <AppAjaxContent v-bind="{ isLoading, height }">
      <div :style="{ height }">
        <AppDataSectionItem :title="barTitle">
          <AppChartStackedBar :data="barData" :tooltip="barTooltip" />
        </AppDataSectionItem>
        <AppDataSectionItem
          v-if="kpiData.length"
          :title="kpiTitle"
          class="TargetSectionKpi__kpi"
        >
          <dl class="AppDataSectionItem__description-list">
            <div v-for="(item, i) in kpiData" :key="i">
              <a v-tooltip="item.tooltip" @click="$emit('click-item', item)">
                <dt>{{ item.label }}</dt>
                <dd>
                  <template v-if="'value' in item">
                    <AppNumber
                      :value="item.value"
                      :precision="2"
                      unit="percentage"
                    />&nbsp;&nbsp;</template
                  >&gt;
                </dd>
              </a>
            </div>
          </dl>
        </AppDataSectionItem>
      </div>
    </AppAjaxContent>
  </AppDataSection>
</template>

<script>
export default {
  name: 'TargetSectionKpi',
  props: {
    isLoading: { type: Boolean, required: true },
    title: { type: String, required: true },
    barTitle: { type: String, required: true },
    barData: { type: Array, required: true },
    barTooltip: { type: Object, required: true },
    kpiTitle: { type: String, required: true },
    kpiData: { type: Array, required: true }
  },
  computed: { height: () => '262px' }
};
</script>

<style scoped>
.TargetSectionKpi__kpi {
  padding-top: 20px;
}
</style>
