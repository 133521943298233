import { constructEnum } from '@/lib/enum';

const PaymentMethod = constructEnum('PaymentMethod', {
  UNKNOWN: 0,
  CARD: 10,
  BANK: 20,
  VACCOUNT: 30
});

PaymentMethod.availableOptions = function(enableDanalVaccount) {
  const availableValues =
    enableDanalVaccount === false
      ? [PaymentMethod.BANK, PaymentMethod.CARD]
      : [PaymentMethod.VACCOUNT, PaymentMethod.CARD];
  return PaymentMethod.options(availableValues);
};

export default PaymentMethod;
