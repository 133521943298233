import { constructEnum } from '@/lib/enum';

const DisplayProductManagementStatus = constructEnum(
  'DisplayProductManagementStatus',
  {
    NONE: 0,
    APPLYING: 10,
    OPERATING: 20,
    ERROR: 1000
  }
);

DisplayProductManagementStatus.isApplying = function(productManagementStatus) {
  return DisplayProductManagementStatus.APPLYING === productManagementStatus;
};

DisplayProductManagementStatus.isErrored = function(productManagementStatus) {
  return DisplayProductManagementStatus.ERROR === productManagementStatus;
};

export default DisplayProductManagementStatus;
