<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="productManagement.name"
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #group="{ id, labelDisabled, errors }">
      <template v-if="id === 'category_id'">
        <AppSelectProductCategory
          v-model="formObject.category_id"
          class="AppForm__group-field"
          :showable-category-ids="managableCategoryIds"
          :disabled="labelDisabled"
          :invalid="!!errors.category_id"
          @blur="validateField('category_id')"
          @change="validateField('category_id')"
        />
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'DisplayCategoryManagementFormDialog',
  mixins: [DialogFormView],
  props: { item: { type: Object, required: true } },
  computed: {
    ...mapState('session', ['currentBrand', 'displaySettings']),
    ...mapState('productCategory', ['productCategories']),
    ...mapState('displayProductManagement', ['productManagement']),
    formSections() {
      const { formObject } = this;

      return [
        {
          groups: [
            {
              id: 'use_category_management',
              type: 'checkbox',
              description: this.$t(
                'form.use_category_management.description_html'
              )
            },
            {
              id: 'category_id',
              hint: this.$t('form.category_id.hint'),
              required: formObject.use_category_management,
              labelDisabled: !formObject.use_category_management,
              disabled: !formObject.use_category_management,
              validate: formObject.use_category_management ? ['required'] : []
            }
          ].map(item => ({
            ...item,
            label: this.$t(`form.${item.id}.label`)
          }))
        }
      ];
    },
    managableCategoryIds() {
      const { enabled_category_ids } = this.displaySettings;

      if (this.currentBrand.shop_builder === 'makeshop') {
        const childCategoriesMap = _.groupBy(
          this.productCategories,
          'parent_category_id'
        );

        return enabled_category_ids.filter(id => !childCategoriesMap[id]);
      } else return enabled_category_ids;
    }
  },
  mounted() {
    this.orgFormObject = this.item;
  },
  methods: {
    submit() {
      this.eventBus.$emit('update-product-management', this.formObject);
      this.close(true);
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "가상 카테고리 이동",
  "form": {
    "use_category_management": {
      "label": "가상 카테고리 이동 사용",
      "description_html": "조건에 의해 선정된 대상 상품을 특정 가상 카테고리에 추가합니다.<br />더 이상 해당하지 않는 상품은 해당 카테고리에서 제외합니다."
    },
    "category_id": {
      "label": "대상 상품에 추가 또는 제거할 가상 카테고리 선택",
      "hint": "진열 사용 설정된 가상 카테고리만 선택할 수 있습니다."
    }
  }
}
</i18n>
