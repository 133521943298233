<template>
  <AppResourceTableSearchbarIconButton
    button-type="dropdown"
    :label="label"
    :icon="icon"
    @click="clickButton"
  >
    <template #dropdown>
      <AppModeless v-if="isPopupVisible" @close="close">
        <AppDropdownMenu :menu-items="menuItems" @close="close" />
      </AppModeless>
    </template>
  </AppResourceTableSearchbarIconButton>
</template>

<script>
import SingleSelectable from '@/mixins/SingleSelectable';

export default {
  name: 'AppResourceTableSearchbarIconDropdownSelectButton',
  mixins: [SingleSelectable],
  props: {
    icon: { type: String, required: true },
    options: { type: Array, required: true }
  },
  data: () => ({ isPopupVisible: false }),
  computed: {
    label() {
      const selectedOption = this.options.find(
        option => option.value === this.currentValue
      );
      if (!selectedOption) return '';

      return selectedOption.label;
    },
    menuItems() {
      return this.options.map(option => ({
        label: option.label,
        badge: option.badge,
        clickHandler: ({ close }) => this.clickOption(option.value, close)
      }));
    }
  },
  methods: {
    clickButton() {
      this.isPopupVisible = !this.isPopupVisible;
      this.$emit('click');
    },
    clickOption(newValue, close) {
      this.setCurrentValue(newValue);
      close();
    },
    close() {
      this.isPopupVisible = false;
    }
  }
};
</script>
