<template>
  <AppModalDefault :title="$t('menu.logs_dialog')" width="wide" @close="close">
    <template #body>
      <AppTabs v-model="activeTab" :tabs="childTabItems('logs_dialog')" />
      <Component :is="activeComponent" :review-id="reviewId" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogView from '@/mixins/DialogView';

import TheLogsDialogReviewMileage from './TheLogsDialogReviewMileage';
import TheLogsDialogWidgetSettings from './TheLogsDialogWidgetSettings';
import TheLogsDialogCommonSettings from './TheLogsDialogCommonSettings';
import TheLogsDialogSmartInstallation from './TheLogsDialogSmartInstallation';
import TheLogsDialogSmartInstallationSettings from './TheLogsDialogSmartInstallationSettings';
import TheLogsDialogBizMessage from './TheLogsDialogBizMessage';

export default {
  name: 'TheLogsDialog',
  components: {
    TheLogsDialogReviewMileage,
    TheLogsDialogWidgetSettings,
    TheLogsDialogCommonSettings,
    TheLogsDialogSmartInstallation,
    TheLogsDialogSmartInstallationSettings,
    TheLogsDialogBizMessage
  },
  mixins: [DialogView],
  props: {
    initialTab: { type: String, default: 'logs_dialog_review_mileage' },
    reviewId: { type: Number, default: null, required: false }
  },
  data() {
    return {
      activeTab: this.initialTab
    };
  },
  computed: {
    ...mapGetters('menu', ['childTabItems']),
    activeComponent() {
      switch (this.activeTab) {
        case 'logs_dialog_review_mileage':
          return 'TheLogsDialogReviewMileage';
        case 'logs_dialog_widget_settings':
          return 'TheLogsDialogWidgetSettings';
        case 'logs_dialog_common_settings':
          return 'TheLogsDialogCommonSettings';
        case 'logs_dialog_smart_installation':
          return 'TheLogsDialogSmartInstallation';
        case 'logs_dialog_smart_installation_settings':
          return 'TheLogsDialogSmartInstallationSettings';
        case 'logs_dialog_biz_message':
          return 'TheLogsDialogBizMessage';
        default:
          return 'TheLogsDialogReviewMileage';
      }
    }
  }
};
</script>
