<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppDataSection title="Data Section" gap="default">
        <AppDataSectionItem
          v-for="(item, i) in SAMPLE_DATA"
          :key="i"
          :title="item.title"
        >
          {{ item.value }}
        </AppDataSectionItem>
      </AppDataSection>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiDataSection',
  data() {
    return {
      SAMPLE_DATA: [
        { title: 'PBW', value: '260.67%' },
        { title: 'TAN', value: '242.53%' },
        { title: 'QCLN', value: '228.68%' },
        { title: 'PALL', value: '166.91%' },
        { title: 'ICLN', value: '90.93%' }
      ]
    };
  }
};
</script>
