import Vue from 'vue';
import Router from 'vue-router';
import _ from 'lodash';
import qs from 'qs';
import MENU_ITEMS from '@/constants/MENU_ITEMS';
import CONTENT_PAGES from '@/constants/CONTENT_PAGES';
import store from '@/store';
import { pathWithParams, isHashChange } from '@/lib/params';
import TheLoggedIn from '@/layouts/TheLoggedIn';
import TheLogin from '@/layouts/TheLogin';
import TheJoin from '@/layouts/TheJoin';
import TheEasyJoin from '@/layouts/TheEasyJoin';
import TheEasyJoinFailure from '@/layouts/TheEasyJoinFailure';
import TheEasyJoinManagerInfo from '@/layouts/TheEasyJoinManagerInfo';
import TheEasyJoinWithBrand from '@/layouts/TheEasyJoinWithBrand';
import TheShopbyAuth from '@/layouts/TheShopbyAuth';
import TheAccountRecovery from '@/layouts/TheAccountRecovery';
import ThePasswordRecovery from '@/layouts/ThePasswordRecovery';
import TheExpirePasswordRecoveryToken from '@/layouts/TheExpirePasswordRecoveryToken';
import TheEmailVerificationResult from '@/layouts/TheEmailVerificationResult';

const requireComponent = require.context('./views', true, /\.vue$/);

requireComponent.keys().forEach(fileName => {
  const component = requireComponent(fileName).default;
  Vue.component(component.name, component);
});

const menuIdToComponentId = menuId =>
  _.chain(menuId)
    .camelCase()
    .upperFirst()
    .value();

const getComponentFromMenuId = menuId => {
  const componentId = menuIdToComponentId(menuId);
  return Vue.component(componentId);
};

const SUB_MENU_ROUTES = _.chain(MENU_ITEMS)
  .filter(({ type, path }) => type == 'sub_menu' && !!path)
  .map(menu => {
    const component = menu.component
      ? Vue.component(menu.component)
      : getComponentFromMenuId(menu.id);
    if (!component) return null;

    const childTabs = MENU_ITEMS.filter(
      item => item.parentId === menu.id && item.type === 'tab'
    );
    const children = _.chain(childTabs)
      .map(({ id, path }) => {
        const component = getComponentFromMenuId(id);
        return component ? { path, component, meta: { menu: id } } : null;
      })
      .compact()
      .value();

    return {
      path: menu.path,
      component: TheLoggedIn,
      children: [
        {
          path: '',
          component,
          meta: { menu: menu.id, loginRequirement: 'login_required' },
          children
        }
      ]
    };
  })
  .compact()
  .value();

const CONTENT_ROUTES = CONTENT_PAGES.map(item => ({
  path: item.path,
  component: TheLoggedIn,
  children: [
    {
      path: '',
      component: Vue.component(item.component),
      meta: { menu: item.subMenuId, loginRequirement: 'login_required' },
      children: []
    }
  ]
}));

const routes = [
  ...SUB_MENU_ROUTES,
  ...CONTENT_ROUTES,
  {
    path: '/join',
    component: TheJoin,
    meta: { loginRequirement: 'forced_logout_required' }
  },
  {
    path: '/easy-join',
    component: TheEasyJoin,
    meta: { loginRequirement: 'forced_logout_required' }
  },
  {
    path: '/easy-join-failure',
    component: TheEasyJoinFailure,
    meta: { loginRequirement: 'forced_logout_required' }
  },
  {
    path: '/easy-join-manager-info',
    component: TheEasyJoinManagerInfo,
    meta: { loginRequirement: 'login_required' }
  },
  {
    path: '/easy-join-with-brand',
    component: TheEasyJoinWithBrand,
    meta: { loginRequirement: 'login_required' }
  },
  {
    path: '/login',
    component: TheLogin,
    meta: { loginRequirement: 'logout_required' }
  },
  {
    path: '/account-recovery',
    component: TheAccountRecovery,
    meta: { loginRequirement: 'logout_required' }
  },
  {
    path: '/password-recovery',
    component: ThePasswordRecovery,
    meta: { loginRequirement: 'logout_required' }
  },
  {
    path: '/expire-password-recovery-token',
    component: TheExpirePasswordRecoveryToken
  },
  {
    path: '/aws-email-verification-success',
    component: TheEmailVerificationResult
  },
  {
    path: '/aws-email-verification-failure',
    component: TheEmailVerificationResult
  },
  {
    path: '/email-verification-success',
    component: TheEmailVerificationResult
  },
  {
    path: '/email-verification-failure',
    component: TheEmailVerificationResult
  },
  {
    path: '/shopby-auth',
    component: TheShopbyAuth,
    meta: { skipCheckSession: true }
  },
  {
    path: '*',
    component: TheLoggedIn,
    meta: { loginRequirement: 'login_required' }
  }
];

Vue.use(Router);

const router = new Router({
  routes,
  mode: 'history',
  base: process.env.BASE_URL
});

const convertNextToBrandParams = (to, next) => {
  if (to.path !== '/login' || !to.query.next) return false;

  const [nextPath, nextQuery] = to.query.next.split('?');
  const nextParams = qs.parse(nextQuery);
  if (!nextParams.brand_id && !nextParams.brand_code) return false;

  const newParams = _.omit(
    nextParams,
    'brand_id',
    'brand_code',
    'user_id',
    'brand_url'
  );
  next({
    ...to,
    query: {
      brand_id: nextParams.brand_id,
      brand_code: nextParams.brand_code,
      brand_url: nextParams.brand_url,
      ...to.query,
      next: pathWithParams(nextPath, newParams)
    }
  });
  return true;
};

router.beforeEach(function(to, from, next) {
  if (convertNextToBrandParams(to, next)) return;
  if (isHashChange(to, from)) return next();

  const dialogs = _.filter(store.state.dialog.dialogs, d => !d.keepOnRoute);
  _.each(dialogs, ({ eventBus }) => eventBus.$emit('close-dialog'));

  const drawers = _.filter(store.state.drawer.drawers, d => !d.keepOnRoute);
  _.each(drawers, ({ eventBus }) => eventBus.$emit('close-drawer'));

  if (_.isEmpty(dialogs)) {
    store.commit('menu/SET_ROUTER_MENU', to.meta.menu);
    next();
  } else next(false);
});

export default router;
