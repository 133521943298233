<template>
  <div>
    <DisplayCornerItemsContainer
      :items="products"
      :item-width="156"
      is-draggable
      @sort="sort"
    >
      <template #item="{ item }">
        <div
          :ref="productElementId(item.index)"
          class="DisplayCornerPreview__item"
          @click="clearMarkedProduct(item.index)"
        >
          <DisplayCornerProductOverlay
            class="DisplayCornerPreview__item-overlay"
            :product="item"
            :is-selected="isSelectedProduct(item)"
            @click-checkbox="clickProduct(item, $event, true)"
            @click-product="clickProduct(item, $event, false)"
            @sort="scrollTo"
          />
          <DisplayCornerProduct :product="item" />
        </div>
      </template>
    </DisplayCornerItemsContainer>
    <DisplayCornerPreviewSelectbar
      v-if="isSelectMode"
      class="DisplayCornerPreview__select-bar"
      :selected-products="selectedProducts"
      @turn-off="turnOffSelectMode"
      @sort="scrollTo"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Scrollable from '@/mixins/Scrollable';
import DisplayPollProductsView from '@/mixins/DisplayPollProductsView';

export default {
  name: 'DisplayCornerPreview',
  mixins: [Scrollable, DisplayPollProductsView],
  data() {
    return { selectedProductIds: [] };
  },
  computed: {
    ...mapState('displayProductDisplay', ['display', 'markedProduct']),
    ...mapGetters('displayProductDisplay', ['isPinnedProduct']),
    products() {
      return this.display.products.map(product => ({
        ...product,
        preventDragging: this.isPinnedProduct(product)
      }));
    },
    isSelectMode() {
      return this.selectedProductIds.length !== 0;
    },
    selectedProducts() {
      return this.selectedProductIds.map(id =>
        this.display.products.find(product => product.id === id)
      );
    }
  },
  watch: {
    'display.calculated_at'() {
      if (this.display.calculated_at) this.turnOffSelectMode();
    },
    products() {
      this.setImageDownloadingProductIds(this.products);
    }
  },
  mounted() {
    this.setImageDownloadingProductIds(this.products);
  },
  beforeDestroy() {
    this.clearMarkedProduct(this.markedProduct.index);
  },
  methods: {
    ...mapActions('displayProductDisplay', [
      'sortProducts',
      'clearMarkedProduct'
    ]),
    turnOffSelectMode() {
      this.selectedProductIds = [];
    },
    clickProduct(product, e, force) {
      if (!force && !this.isSelectMode) return;

      if (this.isSelectMode && e.shiftKey) {
        const lastSelectedProductIndex = this.selectedProducts[
          this.selectedProducts.length - 1
        ].index;

        const startIndex = Math.min(lastSelectedProductIndex, product.index);
        const endIndex = Math.max(lastSelectedProductIndex, product.index);
        for (let i = startIndex; i <= endIndex; i++) {
          const product = this.display.products[i];
          if (!this.isSelectedProduct(product))
            this.selectedProductIds.push(product.id);
        }
      } else {
        if (this.isSelectedProduct(product))
          this.selectedProductIds = this.selectedProductIds.filter(
            id => id !== product.id
          );
        else this.selectedProductIds.push(product.id);
      }
    },
    isSelectedProduct(product) {
      return this.selectedProductIds.includes(product.id);
    },
    sort({ srcIndex, dstIndex }) {
      const srcIndices = this.isSelectMode
        ? this.selectedProducts.map(({ index }) => index)
        : [srcIndex];
      if (!srcIndices.includes(srcIndex)) return;

      this.sortProducts({ srcIndices, dstIndex });
      this.turnOffSelectMode();
    },
    productElementId(index) {
      return `product-${index}`;
    },
    scrollTo(dstIndex) {
      const element = this.$refs[this.productElementId(dstIndex)];
      this.scrollToElement(element);
    },
    doUpdateImageDownloadedProducts(downloadedProductsMap) {
      this.display.products = this.display.products.map(product => ({
        ...product,
        ...(downloadedProductsMap[product.id] || {})
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_z-indexes.scss';

.DisplayCornerPreview__item {
  position: relative;

  &:hover > .DisplayCornerPreview__item-overlay {
    display: block;
  }
}

.DisplayCornerPreview__item-overlay {
  position: absolute;
  z-index: $z-index-tooltip-modal;
  display: none;
}

.DisplayCornerPreview__select-bar {
  text-align: center;
  bottom: 40px;
}
</style>
