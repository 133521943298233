<template>
  <AppTabContent menu-item-id="fit_display_names">
    <AppAjaxContent :is-loading="isLoading">
      <AppResourceTable
        table-id="fit-display-names-table"
        :columns="columns"
        :rows="rows"
        enable-query
        @change-query="query = $event"
      >
        <template #cell="{ column, value }">
          <template v-if="column === 'actions'">
            <AppButton
              :label="$t('edit_text_button')"
              @click="editFitOptionKey(value)"
            />
          </template>
        </template>
      </AppResourceTable>
    </AppAjaxContent>
  </AppTabContent>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import { filterItemsWithHighlightedText } from '@/lib/text-search';
import FitOptionKeyType from '@/enums/FitOptionKeyType';
import ResourceView from '@/mixins/ResourceView';

const FETCH_FIT_OPTION_KEYS_REQUEST_ID = 'FETCH_FIT_OPTION_KEYS';

export default {
  name: 'FitDisplayNames',
  mixins: [ResourceView],
  data() {
    return {
      query: '',
      fitOptionKeys: [],
      fitOptionKeyType: FitOptionKeyType.SIZE,
      isLoading: true
    };
  },
  computed: {
    defaultResourceParams: () => ({ tab: FitOptionKeyType.SIZE }),
    filteredFitOptionKeys() {
      return filterItemsWithHighlightedText(
        this.fitOptionKeys,
        'name',
        this.query
      );
    },
    columns() {
      const propertyColumns =
        this.fitOptionKeyType === FitOptionKeyType.PROPERTY
          ? [
              {
                id: 'optionValues',
                label: this.$t('fit_display_name.option_values')
              }
            ]
          : [];

      return [
        { id: 'name', label: this.$t('fit_display_name.name'), type: 'html' },
        {
          id: 'displayName',
          label: this.$t('fit_display_name.display_name'),
          type: 'pre'
        },
        ...propertyColumns,
        { id: 'actions', label: this.$t('app.actions') }
      ];
    },
    rows() {
      return this.filteredFitOptionKeys.map(fitOptionKey => ({
        id: fitOptionKey.id,
        name: fitOptionKey.nameHighlighted,
        displayName: fitOptionKey.display_name,
        optionValues: this.fitOptionValues(fitOptionKey),
        actions: fitOptionKey
      }));
    }
  },
  beforeDestroy() {
    api.cancel(FETCH_FIT_OPTION_KEYS_REQUEST_ID);
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchResource({ tab }) {
      this.isLoading = true;
      api
        .get('/fit/option_keys', {
          requestId: FETCH_FIT_OPTION_KEYS_REQUEST_ID,
          params: { fit_option_key_type: tab }
        })
        .then(({ data }) => {
          this.fitOptionKeys = data.fit_option_keys;
          this.fitOptionKeyType = tab;
        })
        .finally(() => (this.isLoading = false));
    },
    fitOptionValues(fitOptionKey) {
      if (!fitOptionKey.fit_option_values) return '';

      return fitOptionKey.fit_option_values.map(v => v.display_name).join(', ');
    },
    editFitOptionKey(orgFitOptionKey) {
      this.openDialog(['FitDisplayNameEditDialog', { orgFitOptionKey }]).then(
        eventBus => {
          eventBus.$on('save', newFitOptionKey => {
            const index = this.fitOptionKeys.findIndex(
              i => i.id === newFitOptionKey.id
            );
            this.$set(this.fitOptionKeys, index, newFitOptionKey);
          });
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .fit-display-names-table__name {
    flex: 1 0 110px;
  }

  .fit-display-names-table__display-name {
    flex: 1 0 100px;
  }

  .fit-display-names-table__option-values {
    flex: 3 0 180px;
  }

  .fit-display-names-table__actions {
    flex: 1 0 110px;
  }
}
</style>

<i18n locale="ko">
{
  "edit_text_button": "문구수정"
}
</i18n>
