<template>
  <div class="ui-components">
    <div class="ui-components__component">
      <div class="ui-components__component-title">AppTextarea</div>
      <div class="ui-components__component-content">
        <div class="ui-components__component-content-section">
          <div class="ui-components__component-content-section-title">
            Textarea
          </div>
          <label>
            <AppTextarea v-model="textarea1" />
            Textarea
          </label>
          <p>selected value : {{ textarea1 }}</p>
        </div>
      </div>
    </div>

    <div class="ui-components__component">
      <div class="ui-components__component-title">AppCopyableTextarea</div>
      <div class="ui-components__component-content">
        <div class="ui-components__component-content-section">
          <div class="ui-components__component-content-section-title">
            AppCopyableTextarea
          </div>
          <AppCopyableTextarea :value="copyable_textarea1" />
          <p>
            <label>
              Value:
              <AppTextarea v-model="copyable_textarea1" />
            </label>
          </p>
        </div>
      </div>
    </div>

    <div class="ui-components__component">
      <div class="ui-components__component-title">AppSelect</div>
      <div class="ui-components__component-content">
        <div class="ui-components__component-content-section">
          <div class="ui-components__component-content-section-title">
            Inside Label
          </div>
          <label>
            <AppSelect v-model="select1" :options="selectOptions" />
            Label(Select)
          </label>
          <p>selected value : {{ select1 }}</p>
        </div>

        <div class="ui-components__component-content-section">
          <div class="ui-components__component-content-section-title">
            Separate Label
          </div>
          <AppSelect id="select-2" v-model="select2" :options="selectOptions" />
          <label for="select-2"> Label(Select)</label>
          <p>selected value : {{ select2 }}</p>
        </div>

        <div class="ui-components__component-content-section">
          <div class="ui-components__component-content-section-title">
            Disabled
          </div>
          <AppSelect
            id="select-2"
            v-model="select2"
            :options="selectOptions"
            disabled
          />
          <label for="select-2"> Label(Select)</label>
          <p>selected value : {{ select2 }}</p>
        </div>

        <div class="ui-components__component-content-section">
          <div class="ui-components__component-content-section-title">
            Placeholder
          </div>
          <AppSelect :options="selectOptions" placeholder="Please Select" />
        </div>

        <div class="ui-components__component-content-section">
          <div class="ui-components__component-content-section-title">
            Long Label
          </div>
          <AppSelect :value="1" :options="selectOptionsWithLongLabel" />
        </div>
      </div>
    </div>

    <div class="ui-components__component">
      <div class="ui-components__component-title">AppSearchWithType</div>
      <div class="ui-components__component-content">
        <div class="ui-components__component-content-section">
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="searchTypes"
          />
          <p>selected search-with-type : {{ searchWithType }}</p>
        </div>
      </div>
    </div>

    <div class="ui-components__component">
      <div class="ui-components__component-title">AppDateRangeWithType</div>
      <div class="ui-components__component-content">
        <div
          class="ui-components__component-content-section ui-components__component-content-section--right"
        >
          <AppDateRangeWithType
            v-model="dateRangeWithType"
            :date-types="dateTypes"
          />
          <p>selected date-range-with-type : {{ dateRangeWithType }}</p>
        </div>
      </div>
    </div>

    <div class="ui-components__component">
      <div class="ui-components__component-title">AppCodeEditor</div>
      <div class="ui-components__component-content">
        <div class="ui-components__component-content-section">
          <label for="css_code_editor">CSS</label>
          <AppCodeEditor
            id="css_code_editor"
            v-model="cssCode"
            name="css_code"
            lang="css"
          />
          <p>CSS Code : {{ cssCode }}</p>
        </div>

        <div class="ui-components__component-content-section">
          <label>
            JavaScript
            <AppCodeEditor v-model="jsCode" name="js_code" lang="javascript" />
          </label>
          <p>JavaScript Code : {{ jsCode }}</p>
        </div>
      </div>
    </div>

    <div class="ui-components__component">
      <div class="ui-components__component-title">AppFileInput</div>
      <div class="ui-components__component-content">
        <div class="ui-components__component-content-section">
          <div class="ui-components__component-content-section-title">
            File Input
          </div>
          <AppFileInput id="input-file-1" placeholder="파일을 등록해주세요." />
        </div>
        <div class="ui-components__component-content-section">
          <div class="ui-components__component-content-section-title">
            Disabled File Input
          </div>
          <AppFileInput
            id="input-file-2"
            placeholder="파일을 등록해주세요."
            disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiComponents',
  data() {
    return {
      select1: 1,
      select2: 2,
      selectMultiple: [1, 2],
      selectOptions: [
        { value: 1, label: 'option1' },
        { value: 2, label: 'option2' },
        { value: 3, label: 'option3', disabled: true },
        { value: 4, label: 'option4' }
      ],
      selectOptionsWithLongLabel: [
        { value: 1, label: 'option1' },
        {
          value: 2,
          label:
            'option2 with a very very very very very very very very long label'
        },
        { value: 3, label: 'option3', disabled: true },
        { value: 4, label: 'option4' }
      ],
      searchWithType: {
        search_type: 'name',
        search_query: ''
      },
      searchTypes: [
        { value: 'name', label: 'name' },
        { value: 'content', label: 'content' }
      ],
      dateRangeWithType: {
        date_type: 'created_at',
        start_date: null,
        end_date: null
      },
      dateTypes: [
        { value: 'created_at', label: 'Created At' },
        { value: 'updated_at', label: 'Updated At' }
      ],
      textarea1: '',
      copyable_textarea1: 'Copy me!',
      cssCode: '.hidden { display: none; }',
      jsCode: "alert('Hello!');",
      advancedSearch: false
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ui-components {
  margin-bottom: 500px;
}

.ui-components__component {
  & + .ui-components__component {
    margin-top: 25px;
  }
}

.ui-components__component-title {
  @include text-sub-title;
}

.ui-components__component-content {
  margin-top: 15px;
}

.ui-components__component-content-section {
  & + .ui-components__component-content-section {
    margin-top: 10px;
  }
}

.ui-components__component-content-section--right {
  text-align: right;
}
</style>
