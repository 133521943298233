import _ from 'lodash';
import axios from 'axios';
import api from '@/lib/api';
import axiosCancel from 'axios-cancel';
import store from '@/store';
import i18n from '@/lib/i18n';
import HttpStatus from '@/enums/HttpStatus';
import DialogType from '@/enums/DialogType';

axiosCancel(api, { debug: false });
api.isCancel = axios.isCancel;

function setPending(requestId) {
  if (requestId) store.commit('request/SET_REQUEST_PENDING', requestId);
}

function setSuccess(requestId) {
  if (requestId) store.commit('request/SET_REQUEST_SUCCESS', requestId);
}

function setError(requestId) {
  if (requestId) store.commit('request/SET_REQUEST_ERROR', requestId);
}

function getResourceErrorMessage(response) {
  if (response.status !== HttpStatus.UNPROCESSABLE_ENTITY) return;

  const { data } = response;
  if (!data) return;

  return data.errors
    .map(function(error) {
      if (typeof error === 'string') {
        return i18n.te(error) ? i18n.t(error) : error;
      }

      const { model, attribute, error_type, error_detail } = error;

      const resourceErrorKey = `errors.${model}.${attribute}.${error_type}`;
      if (i18n.te(resourceErrorKey))
        return i18n.t(resourceErrorKey, {
          ...error_detail
        });

      const errorTypeKey = `resource_errors.${error_type}`;
      if (i18n.te(errorTypeKey)) {
        const attributeKey = `${model}.attributes.${attribute}`;
        const attributeName = i18n.te(attributeKey)
          ? i18n.t(attributeKey)
          : attribute;
        return i18n.t(errorTypeKey, {
          attribute: attributeName,
          ...error_detail
        });
      }
    })
    .filter(e => e)
    .join('\n');
}

function getStatusErrorMessage(status) {
  if (
    [
      HttpStatus.UNAUTHORIZED,
      HttpStatus.FORBIDDEN,
      HttpStatus.NOT_FOUND,
      HttpStatus.UNPROCESSABLE_ENTITY
    ].includes(status)
  ) {
    return i18n.t(`status_errors.error_${status}`);
  } else {
    return i18n.t('status_errors.error_etc');
  }
}

const orgCancel = api.cancel;
api.cancel = (requestId, reason) => {
  setError(requestId);
  orgCancel(requestId, reason);
};

api.interceptors.request.use(
  function(config) {
    setPending(config.requestId);

    config.params = {
      ...config.params,
      ...store.state.brandParams
    };
    return config;
  },
  function(error) {
    setError(error.config.requestId);
    return Promise.reject(error);
  }
);

const noop = function() {};

const basicAlert = (response, errorMessage) => {
  alert(
    store.state.env !== 'production' ||
      store.getters['session/isCurrentUserAdmin']
      ? [
          errorMessage,
          _.chain(response.config)
            .pick('url', 'params', 'method')
            .map((v, k) => `${k}: ${JSON.stringify(v)}`)
            .join('\n')
            .value()
        ].join('\n\n')
      : errorMessage
  );
};

const dialogAlert = response => {
  const dialogType = response.data.dialog_type;
  const { title, message, width } = response.data.errors[0];

  const dialogTitle = i18n.te(title) ? i18n.t(title) : title;
  const dialogMessage = i18n.te(message) ? i18n.t(message) : message;
  store.dispatch(
    'dialog/openDialog',
    [
      'AppMessageDialog',
      {
        type: dialogType,
        title: dialogTitle,
        markdownText: dialogMessage,
        width
      }
    ],
    { root: true }
  );
};

api.interceptors.response.use(
  function(response) {
    setSuccess(response.config.requestId);
    if (response.config.successMessage) {
      store.dispatch('toast/createToast', response.config.successMessage);
    }
    return response;
  },
  function(error) {
    if (axios.isCancel(error)) return;

    let errorHandler = noop;

    setError(error.config.requestId);

    const defaultErrorHandler = function() {
      const { response } = error;
      const errorMessage =
        getResourceErrorMessage(response) ||
        error.config.errorMessage ||
        getStatusErrorMessage(response.status);

      if (DialogType.hasValue(response.data.dialog_type)) {
        dialogAlert(response);
      } else {
        basicAlert(response, errorMessage);
      }

      if (response.status === HttpStatus.UNAUTHORIZED)
        store.dispatch('session/onLogout', {
          keepPathAfterLogin: true,
          redirectToLogin: true
        });
    };
    if (error.config.silent) errorHandler = defaultErrorHandler;
    else defaultErrorHandler();

    return Promise.reject({ ...error, errorHandler });
  }
);
