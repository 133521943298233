<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Weekday Values</label>
      </div>
      <AppWeekdaySelectButton id="basic" v-model="valuesBasic" />
      <div class="ui-section__description">
        Selected Value: {{ valuesBasic }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid Weekday Values</label>
      </div>
      <AppWeekdaySelectButton id="invalid" v-model="valuesInvalid" invalid />
      <div class="ui-section__description">
        Selected Value: {{ valuesInvalid }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled Weekday Values</label>
      </div>
      <AppWeekdaySelectButton id="disabled" v-model="valuesDisabled" disabled />
      <div class="ui-section__description">
        Selected Value: {{ valuesDisabled }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiWeekdaySelectButton',
  data() {
    return {
      valuesBasic: 0,
      valuesInvalid: 0,
      valuesDisabled: 0
    };
  }
};
</script>
