<template>
  <div class="ReviewMessagesNthStepReviewCampaignStatCards">
    <AppStatCard
      v-for="c in statCardCount"
      :key="c"
      class="ReviewMessagesNthStepReviewCampaignStatCards-card"
      :title="$t('stats.review_message_rate', [c])"
      :detail="$t('stats.empty')"
    />
    <AppStatCard
      v-if="isSuperAdminAccessible"
      :class="[
        'ReviewMessagesNthStepReviewCampaignStatCards-card',
        'super-admin__item'
      ]"
      :title="$t('stats.more_reviews_click')"
      :unit="StatUnit.COUNT"
      :tooltip="$t('stats.tooltip')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StatUnit from '@/enums/StatUnit';

export default {
  name: 'ReviewMessagesNthStepReviewCampaignStatCards',
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    StatUnit() {
      return StatUnit;
    }
  },
  created() {
    this.statCardCount = 3;
  }
};
</script>

<style scoped>
.ReviewMessagesNthStepReviewCampaignStatCards-card {
  width: 260px;
  display: inline-block;

  & + & {
    margin-left: 8px;
    margin-top: 0;
  }
}
</style>

<i18n locale="ko">
{
  "stats": {
    "review_message_rate": "{0}차 리뷰 작성률",
    "empty": "효과 수집 중입니다.",
    "more_reviews_click": "중간리뷰 더보기 클릭 수",
    "tooltip": "3차 리뷰 이상 작성 시 리뷰 위젯에 노출되는 '중간 리뷰 더보기' 클릭 수를 통해 잠재 고객이 n차 리뷰 내용에 얼마나 관심있는지 알 수 있습니다."
  }
}
</i18n>
