<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <AppButton
        :label="$t('button.unit_settings')"
        @click="openChannelFeedSettingsDialog"
      />
      <AppButton
        :label="$t('button.apply_unit')"
        :disabled="cannotApplyUnit"
        :tooltip="applyUnitTooltip"
        @click="requestRegenerateCsv"
      />
      <AppDropdownMenuButton
        :label="$t('app.else')"
        :menu-items="[
          {
            label: $t('button.download_csv'),
            tooltip: products.items.length
              ? $t('tooltip.download_csv')
              : $t('tooltip.create_cohort'),
            type: 'file_link',
            url: csvDownloadUrl,
            disabled: !products.items.length,
            clickHandler: ({ close, click }) => {
              click();
              close();
            }
          }
        ]"
      />
      <template #right>
        <li>
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="searchTypes"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppResourceTable
      table-id="feed-products-table"
      :resources="products"
      :columns="columns"
      :rows="rows"
      :no-data="$t('no_data')"
      @refresh="refreshResource"
      @paginate="paginate"
    >
      <template #additional-table-searchbar>
        <div
          v-tooltip="{
            message: $t('tooltip.feed_created_at.message'),
            info: $t('tooltip.feed_created_at.info', [nextFeedCreateAt])
          }"
          class="ChannelProducts__feed-created-at"
        >
          {{ $t('feed_created_at', [feedCreatedAt]) }}
        </div>
        <AppSelect
          v-if="sortTypeDateRequired"
          v-model="resourceParams.recent_days"
          class="ChannelProducts__custom-search-bar-item"
          size="small"
          :options="recentDaysOptions"
          @change="searchResource"
        />
        <AppSelect
          v-model="resourceParams.sort_type"
          class="ChannelProducts__custom-search-bar-item"
          size="small"
          :options="sortTypeOptions"
          @change="searchResource"
        />
      </template>
      <template #cell="{ row, column, value, rowIndex }">
        <template v-if="column === 'product_image'">
          <ChannelProductsImageCell :product="row" @refresh="refreshResource" />
        </template>
        <template v-else-if="column === 'product_info'">
          <ChannelProductsInfoCell :product="row" />
        </template>
        <template v-else-if="column === 'category_ids'">
          <template v-if="!value.length">-</template>
          <ChannelProductsCategoryCell :category-ids="value" />
        </template>
        <template v-else-if="column === 'updated_at'">
          <ChannelProductsUpdatedAtCell :product="row" />
        </template>
        <template v-else-if="column === 'actions'">
          <ChannelProductsActionsCell
            :product="row"
            v-bind="{ feed }"
            @refresh="refreshResource"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceView from '@/mixins/ResourceView';
import DialogSize from '@/enums/DialogSize';
import ChannelProductRankType from '@/enums/ChannelProductRankType';
import DisplayProductSortType from '@/enums/DisplayProductSortType';
import ChannelCohortStatus from '@/enums/ChannelCohortStatus';
import ChannelFeedStatus from '@/enums/ChannelFeedStatus';

export default {
  name: 'ChannelFeedProducts',
  mixins: [ResourceView],
  props: { feed: { type: Object, required: true } },
  data() {
    return {
      isLoading: true,
      products: nullResources,
      cohort: {}
    };
  },
  computed: {
    ...mapGetters(['pathWithBrandParams']),
    csvDownloadUrl() {
      return this.pathWithBrandParams(
        `/api/channel/feeds/${this.feed.id}/csvs/download`
      );
    },
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      sort_type: DisplayProductSortType.DISPLAYED_DESC,
      recent_days: 3,
      search_type: 'name'
    }),
    searchTypes() {
      return ['name', 'code'].map(value => ({
        value,
        label: this.$t(`product_search_type.${value}`)
      }));
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    sortTypeDateRequired() {
      const { sort_type } = this.resourceParams;

      return !DisplayProductSortType.isSortRecentDaysDisabled(
        parseInt(sort_type)
      );
    },
    sortTypeOptions() {
      return DisplayProductSortType.options();
    },
    recentDaysOptions() {
      return [1, 3, 7, 14, 30, 60, 90].map(value => ({
        label: this.$t('recent_days', [value]),
        value
      }));
    },
    columns() {
      return [
        { id: 'code', label: this.$t('table_header.code'), width: '72px' },
        {
          id: 'product_image',
          label: this.$t('table_header.product_image'),
          width: '80px'
        },
        {
          id: 'product_info',
          label: this.$t('table_header.product_info')
        },
        {
          id: 'category_ids',
          type: 'badges',
          label: this.$t('table_header.category_ids'),
          width: '288px'
        },
        {
          id: 'review_keyword',
          label: this.$t('table_header.review_keyword'),
          type: 'badges',
          width: '130px'
        },
        {
          id: 'updated_at',
          label: this.$t('table_header.updated_at'),
          width: '133px'
        },
        {
          id: 'actions',
          label: this.$t('table_header.actions'),
          width: '160px'
        }
      ];
    },
    rows() {
      return this.products.items.map(item => ({
        ...item,
        product_image: item,
        review_keyword: item.keyword_label_data.map(data => ({
          label: ChannelProductRankType.t(data.rank_type),
          style: 'grey-outline',
          tooltip: this.$t('tooltip.review_keyword', [
            data.day,
            ChannelProductRankType.t(data.rank_type),
            data.rank
          ])
        }))
      }));
    },
    feedCreatedAt() {
      return this.cohort.sent_at
        ? new Date(this.cohort.sent_at).toLocaleString()
        : '-';
    },
    cannotApplyUnit() {
      return (
        this.feed.status === ChannelFeedStatus.DISABLED ||
        this.cohort.status === ChannelCohortStatus.COMPLETE_BUT_REGENERATING
      );
    },
    applyUnitTooltip() {
      if (!this.cannotApplyUnit) {
        return '';
      }
      return this.feed.status === ChannelFeedStatus.DISABLED
        ? this.$t('tooltip.disabled')
        : this.cohort.regenerated_at
        ? this.$t('tooltip.regenerating', [
            new Date(this.cohort.regenerated_at).toLocaleString()
          ])
        : this.$t('tooltip.cohort_not_exist');
    },
    nextFeedCreateAt() {
      const now = new Date();
      now.setHours(now.getHours() + 1);
      now.setMinutes(0);
      now.setSeconds(0);
      return now.toLocaleString();
    }
  },
  mounted() {
    this.startPolling();
  },
  beforeDestroy() {
    this.stopPolling();
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/channel/feeds/${this.feed.id}/products`, { params })
        .then(({ data }) => {
          this.products = data.products;
          this.cohort = data.cohort || {};
        })
        .finally(() => (this.isLoading = false));
    },
    requestRegenerateCsv() {
      api
        .get(`/channel/feeds/${this.feed.id}/csvs/regenerate`, {})
        .finally(() => {
          this.cohort.status = ChannelCohortStatus.COMPLETE_BUT_REGENERATING;
          this.cohort.regenerated_at = new Date();
        });
    },
    openChannelFeedSettingsDialog() {
      this.openDialog(['ChannelFeedSettingsDialog', { feedId: this.feed.id }]);
    },
    openFailedCreateFeedDialog() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'alert',
          title: this.$t('dialog.title'),
          markdownText: this.$t('dialog.context', [this.cohort.regenerated_at]),
          width: DialogSize.SMALL
        }
      ]);
    },
    startPolling() {
      if (this.timer) return;
      this.timer = setInterval(() => this.fetchCohort(), 5000);
    },
    stopPolling() {
      clearInterval(this.timer);
      this.timer = null;
    },
    fetchCohort() {
      if (!this.cohort.id) return;
      api.get(`/channel/cohorts/${this.cohort.id}`).then(({ data }) => {
        if (
          this.cohort.status === ChannelCohortStatus.COMPLETE_BUT_REGENERATING
        ) {
          switch (data.cohort.status) {
            case ChannelCohortStatus.COMPLETE:
              this.createToast(this.$t('success_create_feed'));
              this.refreshResource();
              break;
            case ChannelCohortStatus.ERROR:
              this.openFailedCreateFeedDialog();
          }
        }
        this.cohort = data.cohort;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ChannelProducts__feed-created-at {
  display: inline-block;
}
.ChannelProducts__custom-search-bar-item {
  vertical-align: middle;
  margin-left: 8px;
  line-height: 0;

  & + & {
    margin-left: 4px;
  }
}
::v-deep {
  .feed-products-table__product-info {
    min-width: 248px;
  }
}
</style>

<i18n locale="ko">
{
  "recent_days": "최근 {0}일",
  "product_search_type": {
    "name": "상품명",
    "code": "상품번호"
  },
  "feed_created_at": "최근 생성 시간: {0}",
  "table_header": {
    "code": "상품번호",
    "product_image": "상품 이미지",
    "product_info": "상품",
    "category_ids": "카테고리",
    "review_keyword": "AI 리뷰 분석",
    "updated_at": "최종 수정일자",
    "actions": "기능"
  },
  "button": {
    "unit_settings": "광고 피드 규칙 설정",
    "apply_unit": "광고 피드 재생성",
    "download_csv": "광고 상품 CSV 다운로드"
  },
  "tooltip": {
    "download_csv": "현재 표시되고 있는 광고피드의 상품 리스트를 다운로드합니다.",
    "create_cohort": "먼저 광고 피드를 생성해주세요.",
    "disabled": "광고 피드가 [발송중]상태 일때만 사용가능합니다.",
    "regenerating": "사용자가 {0}에 시작한 광고 피드 재생성 중입니다.",
    "cohort_not_exist": "생성된 피드가 없습니다.",
    "review_keyword": "현재 피드에서 추가된 상품 중 최근 {0}일 {1} 기준 Top{2} 상품입니다.",
    "feed_created_at": {
      "message": "광고 피드 규칙에 따라 피드를 생성한 시간입니다.",
      "info": "다음 광고 피드 생성 시간: {0}"
    }
  },
  "dialog": {
    "title": "광고 피드 생성에 실패하였습니다.",
    "context": "{0}에 시작한 광고 피드 생성 및 업데이트에 실패하였습니다."
  },
  "final_price": "가격",
  "discount_price": "할인가",
  "no_data": "생성된 피드 상품이 없습니다.",
  "success_create_feed": "광고 피드 생성 및 업데이트에 성공하였습니다."
}
</i18n>
