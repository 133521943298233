import { constructEnum } from '@/lib/enum';
import TargetCampaignType from '@/enums/TargetCampaignType';

const TargetIndexPageType = constructEnum('TargetIndexPageType', {
  RECOMMENDATION: 0,
  CART: 1,
  COUPON: 2,
  UNPAID_ORDERS: 3
});

TargetIndexPageType.fromCampaignType = function(campaignType) {
  switch (campaignType) {
    case TargetCampaignType.CART_REMINDING:
      return [
        TargetIndexPageType.CART,
        TargetIndexPageType.RECOMMENDATION,
        TargetIndexPageType.COUPON
      ];
    case TargetCampaignType.UNPAID_ORDER_REMINDING:
      return [
        TargetIndexPageType.UNPAID_ORDERS,
        TargetIndexPageType.RECOMMENDATION,
        TargetIndexPageType.COUPON
      ];
    default:
      return [];
  }
};

export default TargetIndexPageType;
