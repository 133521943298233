<template>
  <div class="TheReviewSettingsProgress">
    <div class="TheReviewSettingsProgress__progress-graph">
      <div
        class="TheReviewSettingsProgress__progress-graph-complete"
        :style="{ width: `${rate * 100}%` }"
      />
    </div>
    <div class="AppProgress__progress-text">
      <div class="AppProgress__progress-step">
        {{ steps }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheReviewSettingsProgress',
  props: {
    totalCount: { type: Number, required: true },
    progressCount: { type: Number, required: true }
  },
  computed: {
    rate() {
      return this.progressCount / this.totalCount;
    },
    steps() {
      return `${this.progressCount}/${this.totalCount}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheReviewSettingsProgress {
  display: flex;
}

.TheReviewSettingsProgress__progress-graph {
  height: 8px;
  margin-top: 5px;
  background-color: $color-grey-15;
  border-radius: 12px;
  width: 170px;
  margin-right: 15px;
  position: relative;
}

.TheReviewSettingsProgress__progress-graph-complete {
  transition: width 0.05s;
  height: 100%;
  background-color: $color-mint-green;
  border-radius: 12px;
}

.AppProgress__progress-step {
  color: $color-grey-50;
}
</style>
