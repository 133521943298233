<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title', [formatUser(review)])"
    :form-props="{ ...formProps, focusGroupId: 'message' }"
    v-on="formEvents"
  />
</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewReviewsNoteEditDialog',
  mixins: [DialogFormView],
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      orgFormObject: this.review.note || { message: '' }
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'message',
              type: 'textarea',
              label: null,
              rows: 10,
              placeholder: this.$t('message_placeholder')
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapMutations('review', ['UPDATE_REVIEW']),
    submit() {
      this.isSubmitting = true;
      api
        .patch(`/review/reviews/${this.review.id}/update_note`, {
          message: this.formObject.message
        })
        .then(({ data }) => {
          this.UPDATE_REVIEW(data.review);
          this.eventBus.$emit('change', data.review);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "메모 작성",
  "sub_title": "{0}님의 리뷰",
  "message_placeholder": "리뷰에 대한 메모를 작성해주세요."
}
</i18n>
<i18n locale="en">
{
  "title": "Write note",
  "sub_title": "{0}'s review",
  "message_placeholder": "Please write a note about a review."
}
</i18n>
