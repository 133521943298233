var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TheSearchDialogResults"},[_c('div',{staticClass:"TheSearchDialogResults__tabs"},[_c('ul',_vm._l((_vm.searchCategories),function(searchCategory){return _c('li',{key:searchCategory,class:[
          'search-results-tab',
          {
            'search-results-tab--active':
              _vm.selectedSearchCategory === searchCategory
          }
        ]},[_c('a',{staticClass:"search-results-tab__link",on:{"click":function($event){_vm.selectedSearchCategory = searchCategory}}},[_vm._v(_vm._s(_vm.SearchCategory.t(searchCategory)))])])}),0)]),_c('div',{staticClass:"TheSearchDialogResults__section"},[(_vm.searchResultsInCategory.length)?_c('ul',_vm._l((_vm.searchResultsInCategory),function(searchResult,index){return _c('li',{key:index,ref:"items",refInFor:true,class:[
          'search-dialog-item',
          { 'search-dialog-item--active': index === _vm.activeIndex }
        ]},[_c('TheSearchDialogResult',{attrs:{"search-result":searchResult},on:{"mousemove-search-result":function($event){return _vm.activateItem(index)},"select-search-result":_vm.selectItem}})],1)}),0):_c('div',{staticClass:"TheSearchDialogResults__no-data"},[_vm._v(" "+_vm._s(_vm.$t('app.no_data'))+" "),(_vm.showHelpButton)?_c('div',{staticClass:"TheSearchDialogResults__help-chat-button"},[_c('AppButton',{attrs:{"label":_vm.helpChatLabel},on:{"click":_vm.clickHelpChat}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }