import _ from 'lodash';

const arrayMoveIndex = (array, srcIndex, dstIndex) => {
  const newArray = [...array];
  const item = newArray.splice(srcIndex, 1)[0];
  newArray.splice(dstIndex, 0, item);
  return newArray;
};

const arrayMoveIndexes = (array, srcIndexes, dstIndex) => {
  const newArray = [...array];
  const pulledItems = _.pullAt(newArray, srcIndexes);
  newArray.splice(dstIndex, 0, ...pulledItems);
  return newArray;
};

export { arrayMoveIndex, arrayMoveIndexes };
