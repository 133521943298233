<template>
  <a
    class="TheSearchDialogResult search-dialog-item__link"
    @mousemove="$emit('mousemove-search-result')"
    @click="$emit('select-search-result')"
  >
    <div class="TheSearchDialogResult__title">
      <!-- eslint-disable vue/no-v-html -->
      <span
        class="TheSearchDialogResult__title-text"
        v-html="highlightedTitleHtml"
      />
      <!-- eslint-enable vue/no-v-html -->
      <AppExternalLinkIcon v-if="searchResult.isOldAdmin || searchResult.url" />
    </div>
    <div class="TheSearchDialogResult__category">
      {{ SearchCategory.t(searchResult.category) }}
      <span v-if="searchResult.subCategory"
        >[{{ $t(`search_sub_category.${searchResult.subCategory}`) }}]</span
      >
    </div>
  </a>
</template>

<script>
import SearchCategory from '@/enums/SearchCategory';

export default {
  name: 'TheSearchDialogResult',
  props: {
    searchResult: {
      type: Object,
      required: true
    }
  },
  computed: {
    SearchCategory() {
      return SearchCategory;
    },
    highlightedTitleHtml() {
      return this.searchResult.titleHighlighted;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheSearchDialogResult__title {
  & ::v-deep b {
    font-weight: bold;
  }
}

.TheSearchDialogResult__title-text {
  vertical-align: middle;
}

.TheSearchDialogResult__category {
  color: $color-grey-50;
  font-size: 12px;
  line-height: 18px;
}
</style>

<i18n locale="ko">
{
  "search_sub_category": {
    "manuals": "주요기능 및 FAQ",
    "notices": "공지사항"
  }
}
</i18n>
