<template>
  <AppContainer class="AnalyticsInsightKeywordsComment">
    <AppGrid class="AnalyticsInsightKeywordsComment__grid">
      <!-- eslint-disable vue/no-v-html -->
      <template v-if="reviewTagsCount">
        <div class="AppGrid__col AppGrid__col--6">
          <div
            class="AnalyticsInsightKeywordsComment__grid-title"
            v-html="$t('comment.title')"
          />
          <div class="AnalyticsInsightKeywordsComment__grid-text">
            <div
              v-html="
                $t('comment.text', {
                  reviews_count: reviewsCount,
                  review_tags_count: reviewTagsCount
                })
              "
            />
            <div v-if="noBrandsKeywords" v-html="$t('comment.no_comparison')" />
            <div
              v-else
              v-html="
                $t('comment.comparison.text', {
                  review_tags_sentiments_ratio: sentimentsRatioString(
                    myBrandKeywordsSummary
                  ),
                  suffix: sentimentsRatioDiffString
                })
              "
            />
          </div>
        </div>
        <div class="AppGrid__col AppGrid__col--6">
          <div
            class="AnalyticsInsightKeywordsComment__grid-title"
            v-html="$t('keywords.title')"
          />
          <div class="AnalyticsInsightKeywordsComment__grid-text">
            <div v-html="$t('keywords.text', [myBrandTop3TotalNames])" />
            <div
              v-if="
                myBrandTop3PositiveNames.length &&
                  myBrandTop3NegativeNames.length
              "
              v-html="
                $t('keywords.description', [
                  myBrandTop3PositiveNames,
                  myBrandTop3NegativeNames
                ])
              "
            />
            <template v-if="!noBrandsKeywords">
              <div
                v-if="myBrandTop3InferiorNames.length"
                v-html="
                  $t('keywords.comparison_alert', [myBrandTop3InferiorNames])
                "
              />
              <div v-else v-html="$t('keywords.comparison_okay')" />
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="AppGrid__col">
          <div
            class="AnalyticsInsightKeywordsComment__grid-title"
            v-html="$t('comment.title')"
          />
          <div
            class="AnalyticsInsightKeywordsComment__grid-text"
            v-html="$t('comment.no_data_text')"
          />
        </div>
      </template>
      <!-- eslint-enable vue/no-v-html -->
    </AppGrid>
  </AppContainer>
</template>

<script>
import _ from 'lodash';
import InsightKeywordsView from '@/mixins/InsightKeywordsView';

export default {
  name: 'AnalyticsInsightKeywordsComment',
  mixins: [InsightKeywordsView],
  props: {
    reviewsCount: { type: Number, required: true },
    reviewTagsCount: { type: Number, required: true },
    myBrandKeywordsDetails: { type: Array, required: true },
    brandsKeywordsDetails: { type: Array, required: true }
  },
  computed: {
    myBrandKeywordsSummary() {
      return this.keywordsSummary(this.myBrandKeywordsDetails);
    },
    brandsKeywordsSummary() {
      return this.keywordsSummary(this.brandsKeywordsDetails);
    },
    noBrandsKeywords() {
      return Object.values(this.brandsKeywordsSummary).every(v => !v);
    },
    sentimentsRatioDiffString() {
      const myBrandRatio = this.sentimentsRatio(this.myBrandKeywordsSummary);
      const brandsRatio = this.sentimentsRatio(this.brandsKeywordsSummary);
      const diff = this.ratioDiff(myBrandRatio, brandsRatio);
      return diff > 0
        ? this.$t('comment.comparison.suffix.higher', [
            this.toRatioString(diff)
          ])
        : diff < 0
        ? this.$t('comment.comparison.suffix.lower', [
            this.toRatioString(-diff)
          ])
        : this.$t('comment.comparison.suffix.same');
    },
    myBrandTop3TotalNames() {
      return this.top3TotalKeywords(this.myBrandKeywordsDetails)
        .filter(d => d.sentiments_sum)
        .map(d => d.review_tag_type_name)
        .join(', ');
    },
    myBrandTop3PositiveNames() {
      return this.top3PositiveKeywords(this.myBrandKeywordsDetails)
        .filter(d => d.psentiments_count)
        .map(d => d.review_tag_type_name)
        .join(', ');
    },
    myBrandTop3NegativeNames() {
      return this.top3NegativeKeywords(this.myBrandKeywordsDetails)
        .filter(d => d.nsentiments_count)
        .map(d => d.review_tag_type_name)
        .join(', ');
    },
    myBrandTop3InferiorNames() {
      const nSentimentsRatioDiffs = _.zip(
        this.myBrandKeywordsDetails,
        this.brandsKeywordsDetails
      ).map(zipped => ({
        review_tag_type_name: zipped[0].review_tag_type_name,
        diff: zipped[0].nsentiments_ratio - zipped[1].nsentiments_ratio
      }));

      return _.orderBy(nSentimentsRatioDiffs, ['diff'], ['desc'])
        .slice(0, 3)
        .filter(d => d.diff > 0)
        .map(d => d.review_tag_type_name)
        .join(', ');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightKeywordsComment__grid {
  @media (max-width: 719px) {
    & .AppGrid__col:last-child {
      margin-top: 12px;
    }
  }
}

.AnalyticsInsightKeywordsComment__grid-title {
  @include text-label;
  margin-top: 4px;
  margin-bottom: 8px;
}

.AnalyticsInsightKeywordsComment__grid-text {
  @include text-content;
  padding-right: 20px;
  margin-bottom: 8px;
}
</style>

<i18n locale="ko">
{
  "comment": {
    "title": "💬 CREMA’s Comment",
    "text": "선택된 기간 내 총 {reviews_count}개의 리뷰에서 {review_tags_count}개의 키워드를 분석하였습니다.",
    "comparison": {
      "text": "분석된 전체 키워드 중 {review_tags_sentiments_ratio}가 긍정 키워드로, 경쟁 그룹 대비 {suffix}",
      "suffix": {
        "higher": "{0} 높습니다.",
        "same": "동일합니다.",
        "lower": "{0} 낮습니다."
      }
    },
    "no_comparison": "경쟁 그룹의 데이터가 부족하여 경쟁 그룹 비교 분석이 어렵습니다.<br>기간 설정을 변경하거나, 경쟁 그룹 설정을 변경해보세요.",
    "no_data_text": "선택된 기간 내 자사 리뷰수가 부족하여 키워드 분석이 어렵습니다.<br>기간 설정을 늘려보거나, 다른 기간을 선택해보세요."
  },
  "keywords": {
    "title": "다음 키워드에 주목하세요.",
    "text": "자사의 고객들은 ‘{0}’ 키워드를 가장 중요하게 생각합니다.",
    "description": "고객들이 긍정적으로 가장 많이 언급한 키워드는 ‘{0}’이며,<br>부정적으로 가장 많이 언급한 키워드는 ‘{1}’입니다.",
    "comparison_okay": "경쟁 그룹 대비 모든 키워드에서 부정 비율이 낮습니다. 경쟁 그룹 대비 리뷰 부정 비율을 우수하게 관리하고 있습니다.",
    "comparison_alert": "경쟁 그룹 대비 부정 비율이 가장 높은 ‘{0}’에 유의하세요."
  }
}
</i18n>
