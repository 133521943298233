export default [
  {
    id: 'target_settings',
    menuItemId: 'target_settings',
    sections: [
      {
        groups: [
          { id: 'disable_dispatch' },
          { id: 'unsubscribe_phone' },
          { id: 'sender_email' },
          { id: 'popup_blacklist_usernames' },
          { id: 'enable_recommendation_products_limit_of_period' },
          { id: 'enable_popular_products_limit_of_period' }
        ]
      }
    ]
  },
  {
    id: 'target_admin_settings',
    menuItemId: 'target_admin_settings_misc',
    sections: [
      {
        groups: [
          { id: 'dispatch_now' },
          { id: 'display_price_info' },
          { id: 'not_enough_credit_action' }
        ]
      }
    ]
  },
  {
    id: 'target_admin_settings',
    menuItemId: 'target_admin_settings_temp',
    sections: [
      {
        groups: [
          { id: 'apply_promotion_info_on_content_popup' },
          { id: 'show_popup_in_app' },
          { id: 'visit_shop_before_landing_page' },
          { id: 'prepare_payment_disabled' },
          { id: 'marketing_code' }
        ]
      }
    ]
  }
];
