<template>
  <div class="AppSearchWithType">
    <AppInputGroupItem>
      <AppSelect
        :options="searchTypes"
        :value="value.search_type"
        @change="$emit('change', { ...value, search_type: $event })"
      />
    </AppInputGroupItem>
    <AppInputGroupItem class="AppSearchWithType__search">
      <input
        v-focus="autofocus"
        class="AppSearchWithType__search-input"
        :value="value.search_query"
        type="text"
        @input="
          $emit('change', { ...value, search_query: $event.target.value })
        "
      />
      <button type="submit" class="AppSearchWithType__search-button">
        <AppSvgIcon
          class="AppSearchWithType__search-icon"
          name="icon-search-small"
        />
      </button>
    </AppInputGroupItem>
  </div>
</template>

<script>
export default {
  name: 'AppSearchWithType',
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    searchTypes: {
      type: Array,
      required: true
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';
@import '@/scss/mixins/_breakpoints.scss';

.AppSearchWithType__search {
  position: relative;
}

$button-width: 34px;
.AppSearchWithType__search-input {
  @include input-base;

  width: 150px;
  padding: 5px $button-width - 1 5px 11px;
}

.AppSearchWithType__search-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: $button-width;
}

$icon-size: 12px;
.AppSearchWithType__search-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: $icon-size * -0.5;
  margin-left: $icon-size * -0.5;
  color: $color-form-border-hover;
  stroke: $color-grey-40;
}

@include media-breakpoint-each(mobile) {
  .AppSearchWithType {
    display: flex;
  }

  .AppSearchWithType__search {
    flex-grow: 1;
  }

  .AppSearchWithType__search-input {
    width: 100%;
  }
}
</style>
