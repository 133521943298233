export default {
  methods: {
    highlightElement(element) {
      if (element.timer) {
        clearTimeout(element.timer);
        element.classList.remove('highlight');
        element.offsetHeight; // force reflow
      }

      element.classList.add('highlight');
      element.timer = setTimeout(function() {
        element.classList.remove('highlight');
      }, 3500);
    }
  }
};
