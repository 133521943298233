<template>
  <AppTable :columns="COLUMNS" :rows="rows" align="left">
    <template #cell="{ value }">
      <template v-if="value && value.key">
        <AppCurrencyInput
          :value="datum[value.key]"
          :digits="6"
          :default="0"
          :name="`${name}[${value.key.replace(/_cents$/, '')}]`"
          @change="change(value, $event)"
        />
      </template>
    </template>
  </AppTable>
</template>

<script>
export default {
  name: 'ReviewSettingsMileageAmountReviewWrittenOrderTable',
  props: {
    datum: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      COLUMNS: [
        'rank',
        'text_review_mileage',
        'photo_review_mileage'
      ].map(id => ({ id, label: this.$t(id) }))
    };
  },
  computed: {
    rows() {
      return [
        {
          rank: this.$t('rank_first'),
          text_review_mileage: {
            key: 'auto_mileage_first_text_review_in_order_cents'
          },
          photo_review_mileage: {
            key: 'auto_mileage_first_photo_review_in_order_cents'
          }
        },
        {
          rank: this.$t('rank_nth'),
          text_review_mileage: {
            key: 'auto_mileage_not_first_text_review_in_order_cents'
          },
          photo_review_mileage: {
            key: 'auto_mileage_not_first_photo_review_in_order_cents'
          }
        }
      ];
    }
  },
  methods: {
    change(cell, value) {
      this.$emit('change', { key: cell.key, value });
    }
  }
};
</script>

<i18n locale="ko">
{
  "rank": "작성 순서 기준",
  "text_review_mileage": "텍스트 리뷰 지급액",
  "photo_review_mileage": "포토/동영상 리뷰 지급액",
  "rank_first": "하나의 주문건 중 첫 번째 리뷰",
  "rank_nth": "하나의 주문건 중 두 번째 및 이후 리뷰"
}
</i18n>
