<template>
  <AppModalDefault
    :title="$t('title')"
    width="wide"
    :sub-title="$t('sub_title', [campaignName, cohortId.toString()])"
    @close="close"
  >
    <template #body>
      <div class="TargetCohortBrandCsvsDialog__brand">
        <span class="TargetCohortBrandCsvsDialog__brand-name"
          >{{ currentBrand.name
          }}<template v-if="currentBrand.shop_builder"
            >({{ ShopBuilder.tk(currentBrand.shop_builder) }})</template
          ></span
        >
        <AppEditLink
          type="external_link"
          :label="$t('brand_settings')"
          :to="advancedSettingsUrl"
        />
      </div>
      <AppAjaxContent :is-loading="isLoading">
        <AppSmallDeviceScrollable>
          <AppTable :columns="columns" :rows="rows">
            <template #cell="{ column, row }">
              <template v-if="column.id === 'created_at'">
                <div>
                  {{ $t('created_at') }}: {{ formatDateTime(row.created_at) }}
                </div>
                <div
                  v-if="
                    row.brand_csv_status === BrandCsvStatus.EXPORT_SCHEDULED
                  "
                >
                  {{ $t('export_scheduled_at') }}:
                  {{
                    row.export_scheduled_at
                      ? formatDateTime(row.export_scheduled_at)
                      : 'X'
                  }}
                </div>
                <div v-if="row.exported_at">
                  {{ $t('exported_at') }}: {{ formatDateTime(row.exported_at) }}
                </div>
                <div v-if="row.imported_at">
                  {{ $t('imported_at') }}: {{ formatDateTime(row.imported_at) }}
                </div>
              </template>
              <template v-else-if="column.id === 'brand_csv_type'">
                <div>
                  <span>{{ BrandCsvType.t(row.brand_csv_type) }}</span
                  ><span>({{ $t('unit.count', [row.rows_count]) }})</span>
                </div>
              </template>
              <template v-else-if="column.id === 'period'">
                <span v-if="!row.start_date || !row.end_date">
                  {{ $t('app.entire_period') }}
                </span>
                <span v-else-if="row.start_date === row.end_date">
                  {{ row.start_date }}
                </span>
                <div v-else>{{ row.start_date }} ~ {{ row.end_date }}</div>
                <span>
                  ({{ filterOrSortText(row) }})
                  <span
                    v-if="row.infoText"
                    v-tooltip="{
                      message: row.infoText,
                      maxWidth: '500px',
                      wordBreak: 'break-all'
                    }"
                  >
                    <AppSvgIcon
                      name="icon-info-tooltip"
                      class="TargetCohortBrandCsvsDialog__info-icon"
                    />
                  </span>
                </span>
              </template>
              <template v-else-if="column.id === 'file'">
                <AppButtonDownload
                  v-if="row.download_url"
                  :url="row.download_url"
                />
              </template>
              <template v-else-if="column.id === 'is_automatic'">
                <div>
                  {{
                    $t(
                      `is_automatic.${
                        row.is_automatic ? 'automatic' : 'manual'
                      }`
                    )
                  }}
                </div>
              </template>
              <template v-else-if="column.id === 'actions'">
                <AppDropdownMenuButton
                  :label="$t('app.manage')"
                  :menu-items="manageButtonMenuItems(row)"
                />
              </template>
            </template>
          </AppTable>
        </AppSmallDeviceScrollable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';
import ShopBuilder from '@/enums/ShopBuilder';
import BrandCsvType from '@/enums/BrandCsvType';
import BrandCsvSort from '@/enums/BrandCsvSort';
import BrandCsvStatus from '@/enums/BrandCsvStatus';

export default {
  name: 'TargetCohortBrandCsvsDialog',
  mixins: [DialogView],
  props: {
    cohortId: { type: Number, required: true },
    campaignName: { type: String, required: true }
  },
  data() {
    return {
      isLoading: true,
      brandCsvs: []
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters(['advancedSettingsUrl']),
    ShopBuilder: () => ShopBuilder,
    BrandCsvType: () => BrandCsvType,
    BrandCsvStatus: () => BrandCsvStatus,
    columns() {
      return [
        { id: 'id', label: this.$t('id') },
        { id: 'created_at', label: this.$t('created_at') },
        { id: 'brand_csv_type', label: this.$t('brand_csv_type') },
        { id: 'period', label: this.$t('app.period') },
        { id: 'file', label: this.$t('file') },
        { id: 'brand_csv_status', label: this.$t('brand_csv_status') },
        { id: 'is_automatic', label: this.$t('is_automatic.label') },
        { id: 'actions', label: this.$t('app.actions') }
      ];
    },
    rows() {
      return this.brandCsvs.map(brandCsv => ({
        ...brandCsv,
        brand_csv_status: BrandCsvStatus.t(brandCsv.brand_csv_status),
        infoText: this.infoText(brandCsv)
      }));
    }
  },
  mounted() {
    api
      .get(`/target/cohorts/${this.cohortId}/brand_csvs`)
      .then(({ data }) => (this.brandCsvs = data.brand_csvs))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    infoText({ brand_arguments }) {
      return JSON.stringify(brand_arguments);
    },
    filterOrSortText({ brand_csv_type, brand_arguments, sort }) {
      if (brand_arguments)
        switch (brand_csv_type) {
          case BrandCsvType.MEMBER:
            if (brand_arguments.selected_from_db == true)
              return this.$t('filter.selected_from_db');
            break;
          case BrandCsvType.ORDER:
            if (brand_arguments.filter == 'unpaid')
              return this.$t('filter.unpaid');
            break;
          case BrandCsvType.COUPON:
            if ('days_before_expiration' in brand_arguments)
              return this.$t('filter.expiry_date');
            break;
        }
      return BrandCsvSort.t(sort);
    },
    manageButtonMenuItems({
      id,
      reexportable,
      reimportable,
      export_cancelable
    }) {
      return [
        {
          label: this.$t('manage.reexport'),
          disabled: !reexportable,
          clickHandler: ({ close }) => {
            api
              .patch(
                `/target/cohorts/${this.cohortId}/brand_csvs/${id}/reexport`,
                {},
                { successMessage: this.$t('manage.reexport_started') }
              )
              .then(({ data }) => {
                this.brandCsvs = this.brandCsvs.map(csv =>
                  csv.id === id ? data.brand_csv : csv
                );
                close();
              });
          }
        },
        {
          label: this.$t('manage.reimport'),
          disabled: !reimportable,
          clickHandler: ({ close }) => {
            api
              .patch(
                `/target/cohorts/${this.cohortId}/brand_csvs/${id}/reimport`,
                {},
                { successMessage: this.$t('manage.reimport_started') }
              )
              .then(({ data }) => {
                this.brandCsvs = this.brandCsvs.map(csv =>
                  csv.id === id ? data.brand_csv : csv
                );
                close();
              });
          }
        },
        {
          label: this.$t('app.cancel'),
          disabled: !export_cancelable,
          clickHandler: ({ close }) => {
            api
              .patch(
                `/target/cohorts/${this.cohortId}/brand_csvs/${id}/cancel_export`,
                {},
                {
                  successMessage: this.$t('manage.export_cancelled'),
                  errorMessage: this.$t('manage.export_not_cancelled')
                }
              )
              .then(({ data }) => {
                this.brandCsvs = this.brandCsvs.map(csv =>
                  csv.id === id ? data.brand_csv : csv
                );
                close();
              });
            close();
          }
        },
        {
          label: this.$t('app.delete'),
          style: 'danger',
          clickHandler: ({ close }) => {
            if (!confirm(this.$t('app.delete_confirm'))) return;

            api
              .delete(`/target/cohorts/${this.cohortId}/brand_csvs/${id}`, {
                successMessage: this.$t('app.deleted')
              })
              .then(
                () =>
                  (this.brandCsvs = this.brandCsvs.filter(csv => csv.id !== id))
              );
            close();
          }
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetCohortBrandCsvsDialog__brand {
  margin-bottom: 32px;
}

.TargetCohortBrandCsvsDialog__brand-name {
  @include text-label;
  margin-right: 12px;
}

.TargetCohortBrandCsvsDialog__info-icon {
  position: relative;
  top: 2px;
}
</style>

<i18n locale="ko">
{
  "title": "CSV",
  "sub_title": "{0} (발송 ID {1})",
  "brand_settings": "설정 변경",
  "id": "ID",
  "created_at": "생성일",
  "export_scheduled_at": "예약일",
  "exported_at": "추출일",
  "imported_at": "저장일",
  "brand_csv_type": "종류",
  "filter": {
    "unpaid": "미입금",
    "expiry_date": "만료일",
    "selected_from_db": "DB대상자 동기화"
  },
  "file": "파일명",
  "download": "다운로드",
  "brand_csv_status": "상태",
  "is_automatic": {
    "label": "자동/수동",
    "automatic": "자동",
    "manual": "수동"
  },
  "manage": {
    "reexport": "Re-export",
    "reexport_started": "export를 시작했습니다.",
    "reimport": "Re-import",
    "reimport_started": "import를 시작했습니다.",
    "export_cancelled": "export를 취소했습니다.",
    "export_not_cancelled": "export를 취소하지 못했습니다."
  }
}
</i18n>
