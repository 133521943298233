<template>
  <div class="AppTextInputWithLength">
    <div><AppTextInput v-bind="[$props, $attrs]" v-on="$listeners" /></div>
    <div class="AppTextInputWithLength__length">
      {{ `${value.length}/${maxlength}` }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTextInputWithLength',
  inheritAttrs: false,
  model: { event: 'change' },
  props: {
    value: { type: String, required: true },
    maxlength: { type: Number, required: true }
  }
};
</script>

<style lang="scss" scoped>
.AppTextInputWithLength__length {
  text-align: right;
  margin-top: 2px;
}
</style>
