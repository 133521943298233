<template>
  <div class="SmartstoreEstimating">
    <SmartstoreWaiting
      :is-waiting="isWaiting"
      :message="message"
      :button-props="buttonProps"
      :batch-job-button-props="batchJobButtonProps"
      @click="click"
      @show="openBatchJobSmartstoreProductMatchDialog"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BatchJobType from '@/enums/BatchJobType';
import SmartstoreStatus from '@/enums/SmartstoreStatus';
import SmartstoreWaiting from './SmartstoreWaiting';

export default {
  name: 'SmartstoreEstimating',
  components: { SmartstoreWaiting },
  data() {
    return { isSubmitting: false };
  },
  computed: {
    ...mapState('smartstore', ['smartstoreStatus']),
    ...mapState('batchJob', ['batchJobs']),
    ...mapState(['brandParams']),
    isWaiting() {
      return [
        SmartstoreStatus.PRODUCT_MATCH_STARTED,
        SmartstoreStatus.ESTIMATION_QUEUED,
        SmartstoreStatus.ESTIMATION_STARTED
      ].includes(this.smartstoreStatus);
    },
    message() {
      return this.$t(`message.${SmartstoreStatus.key(this.smartstoreStatus)}`);
    },
    buttonProps() {
      return {
        isSubmitting: this.isSubmitting,
        submitLabel: this.$t('submit_label'),
        submittingLabel: this.$t('submitting_label'),
        buttonStyle: this.isWaiting ? 'red' : 'default'
      };
    },
    batchJobButtonProps() {
      if (SmartstoreStatus.PRODUCT_MATCH_FAILED !== this.smartstoreStatus)
        return null;

      return {
        label: this.$t('app.detail_breakdown'),
        buttonStyle: 'default'
      };
    }
  },
  mounted() {
    this.fetchSmartstoreBatchJobs();
  },
  methods: {
    ...mapActions('batchJob', ['fetchBatchJobs']),
    ...mapActions('dialog', ['confirm', 'openDialog']),
    ...mapActions('smartstore', [
      'requestCancelProductMatchJob',
      'requestRetryProductMatchJob',
      'requestCancelEstimation'
    ]),
    click() {
      switch (this.smartstoreStatus) {
        case SmartstoreStatus.PRODUCT_MATCH_STARTED:
          return this.cancelProductMatch();
        case SmartstoreStatus.PRODUCT_MATCH_FAILED:
        case SmartstoreStatus.ESTIMATION_FAILED:
          return this.retryProductMatch();
        case SmartstoreStatus.ESTIMATION_QUEUED:
        case SmartstoreStatus.ESTIMATION_STARTED:
          return this.cancelEstimation();
      }
    },
    openBatchJobSmartstoreProductMatchDialog() {
      const batchJobId = this.batchJobs.items[0].id;
      this.openDialog([
        'ProductBatchJobSmartstoreProductMatchDialog',
        { batchJobId }
      ]);
    },
    cancelProductMatch() {
      this.confirm({
        title: this.$t('submit_label'),
        message: this.$t('confirm_cancel_product_maching')
      }).then(result => {
        if (!result) return;

        this.isSubmitting = true;
        this.requestCancelProductMatchJob(
          this.$t('canceled_product_maching')
        ).finally(() => (this.isSubmitting = false));
      });
    },
    cancelEstimation() {
      this.confirm({
        title: this.$t('submit_label'),
        message: this.$t('confirm_cancel_estimation')
      }).then(result => {
        if (!result) return;

        this.isSubmitting = true;
        this.requestCancelEstimation(this.$t('canceled_estimation')).finally(
          () => (this.isSubmitting = false)
        );
      });
    },
    retryProductMatch() {
      this.isSubmitting = true;
      this.requestRetryProductMatchJob().finally(
        () => (this.isSubmitting = false)
      );
    },
    fetchSmartstoreBatchJobs() {
      const { brand_id } = this.brandParams;
      this.fetchBatchJobs({
        brand_id,
        batch_job_type: BatchJobType.SMARTSTORE_PRODUCT_MATCH
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "message": {
    "PRODUCT_MATCH_STARTED": "자사몰의 상품과 스마트스토어의 상품을 매칭하기 위한 상품 매칭표를 등록중입니다.",
    "PRODUCT_MATCH_FAILED": "상품 매칭표 등록에 실패했습니다.\n오류내역 참고하여 수정 후, 등록에 성공한 상품도 모두 포함한 전체 매칭표 파일을 재등록 해주세요.",
    "ESTIMATION_QUEUED": "상품 매칭표 등록을 완료했습니다. 연동할 리뷰수를 계산하기 위해 준비중입니다.",
    "ESTIMATION_STARTED": "쇼핑몰에 연동할 스마트스토어의 리뷰수를 계산 중입니다.",
    "ESTIMATION_FAILED": "스마트스토어의 리뷰수를 계산하던 중 오류가 발생했습니다.\n크리마 운영팀으로 연락해주세요."
  },
  "submit_label": "상품 매칭표 다시 등록",
  "submitting_label": "상품 매칭표 다시 등록 요청 중..",
  "confirm_cancel_product_maching": "등록 중인 상품 매칭표를 취소하시겠습니까?",
  "confirm_cancel_estimation": "정말 계산을 취소하고 새로운 상품 매칭표를 등록하시겠습니까?",
  "canceled_product_maching": "상품 매칭표 등록이 취소되었습니다.",
  "canceled_estimation": "리뷰수 계산이 취소되었습니다."
}
</i18n>
