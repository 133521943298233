import api from '@/lib/api';
import { mapState, mapGetters, mapMutations } from 'vuex';
import TargetCampaignStatus from '@/enums/TargetCampaignStatus';
import NavigateConfirmable from '@/mixins/NavigateConfirmable';

export default {
  data: () => ({ isLoading: true }),
  mixins: [NavigateConfirmable],
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    ...mapGetters(['pathWithBrandParams']),
    ...mapGetters('targetCampaigns', ['campaignPath', 'campaignsPath']),
    contentStyle: () => ({ maxWidth: '1088px' }),
    navigateConfirmationMessage() {
      return this.$t(
        this.campaign.configured
          ? 'target_campaign.confirmation_disabled'
          : 'target_campaign.confirmation_not_configured'
      );
    }
  },
  watch: {
    campaign() {
      const { configured, status } = this.campaign;
      if (configured && status !== TargetCampaignStatus.DISABLED)
        this.disableNavigateConfirmation();
      else this.enableNavigateConfirmation();
    }
  },
  mounted() {
    this.fetchCampaign();
  },
  beforeDestroy() {
    this.SET_CAMPAIGN(null);
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    fetchCampaign() {
      this.isLoading = true;
      const routes = this.$route.path.split('/');
      const campaignId = routes[routes.length - 1];
      api
        .get(`/target/campaigns/${campaignId}`)
        .then(({ data }) => this.SET_CAMPAIGN(data))
        .finally(() => (this.isLoading = false));
    },
    campaignDeleted() {
      this.disableNavigateConfirmation();
      const path = this.campaignsPath(this.campaign);
      this.$router.replace(this.pathWithBrandParams(path));
    }
  }
};
