<template>
  <label class="AppFileInput">
    <input
      ref="fileInput"
      v-bind="{
        id,
        name,
        accept,
        disabled,
        invalid
      }"
      class="AppFileInput__org-input"
      type="file"
      @change="changeFile"
      @blur="$emit('blur')"
    />
    <div
      :class="[
        'AppFileInput__input',
        { 'AppFileInput__input--invalid': invalid }
      ]"
    >
      <span v-if="value">{{ value }}</span>
      <span v-else-if="disabled || placeholder">{{ placeholder }}</span>
      <div class="AppFileInput__icon" />
    </div>
  </label>
</template>

<script>
export default {
  name: 'AppFileInput',
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    accept: {
      type: String,
      default: null
    },
    invalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    eventBus: {
      type: Object,
      default: null
    },
    selectOnMount: { type: Boolean, default: false }
  },
  data() {
    return {
      value: null
    };
  },
  mounted() {
    if (this.eventBus) {
      this.eventBus.$on('reset', () => {
        const { fileInput } = this.$refs;
        // The most compatible method to reset input[type=file]
        // Ref: https://stackoverflow.com/a/35323290
        fileInput.type = 'text';
        fileInput.type = 'file';
        this.value = null;
      });
    }

    if (this.selectOnMount) this.$refs.fileInput.click();
  },
  methods: {
    changeFile(e) {
      const files = e.target.files;
      if (files?.[0]) {
        this.value = files[0].name;
        this.$emit('change', files[0]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppFileInput {
  vertical-align: middle;
  display: inline-block;
  line-height: 0;
  width: 100%;
}

.AppFileInput__input {
  @include input-base;

  cursor: pointer;
  position: relative;
  display: inline-block;
  outline: none;
  padding: 5px 31px 5px 11px;
  color: $color-grey-40;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.AppFileInput__org-input {
  width: 0px;
  height: 0px;
}

.AppFileInput__org-input:disabled + .AppFileInput__input {
  border-color: $color-grey-25;
  color: $color-disable-text;
}

$icon-size: 24px;

.AppFileInput__icon {
  position: absolute;
  width: $icon-size;
  height: $icon-size;
  border-radius: 3px;
  background-color: $color-grey-50;

  top: 50%;
  right: 3px;
  margin-top: $icon-size * -0.5;
}

.AppFileInput__icon:before,
.AppFileInput__icon:after {
  content: '';
  position: absolute;
  width: 12px;
  height: 2px;
  border-radius: 1px;
  background-color: $color-background;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.AppFileInput__icon:after {
  transform: translate(-50%, -50%) rotate(90deg);
}
</style>
