<template>
  <div class="AppChangeRequestStatus">
    <div>
      <span class="AppChangeRequestStatus__title">{{ title }}</span
      ><span v-if="isRequired" class="AppChangeRequestStatus__required">
        *</span
      >
      <span class="AppChangeRequestStatus__badge">
        <AppBadge :label="$t(`status.${status}`)" :badge-style="badgeStyle" />
      </span>
    </div>
    <AppFormHint
      :message="hint"
      :priority="status === 'rejected' ? 'danger' : null"
    />
  </div>
</template>

<script>
export default {
  name: 'AppChangeRequestStatus',
  props: {
    title: { type: String, required: true },
    status: { type: String, required: true },
    hint: { type: String, required: true },
    isRequired: { type: Boolean, default: false }
  },
  computed: {
    badgeStyle() {
      switch (this.status) {
        case 'none':
          return 'red-outline';
        case 'rejected':
          return 'red';
        case 'requested':
          return 'mango-outline';
        default:
          return 'default';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppChangeRequestStatus__title {
  @include text-label;
}

.AppChangeRequestStatus__required {
  color: $color-red;
}

.AppChangeRequestStatus__badge {
  margin-left: 4px;
}
</style>

<i18n locale="ko">
{
  "status": {
    "none": "등록 전",
    "requested": "관리자 확인 중",
    "required": "등록 전",
    "rejected": "등록 실패",
    "approved": "등록 완료"
  }
}
</i18n>
