<template>
  <AppAjaxContent :is-loading="!data">
    <AppForm
      v-if="data"
      v-model="fit_settings"
      v-bind="fitFormProps"
      @submit="submit"
    >
      <template #group="{ id }">
        <template v-if="id === 'download_fit_products'">
          <div class="AppForm__group-field">
            <i18n path="download_fit_products.field">
              <AppSelect
                v-model="fit_products.date_type"
                :options="dateTypeOptions"
                inline
              />
              <AppDateRangePicker v-model="fit_products" />
              <AppDropdownMenuButton
                :label="
                  isDownloading
                    ? $t('downloading')
                    : $t('download_fit_products.button')
                "
                :disabled="isDownloading"
                :menu-items="[
                  [
                    {
                      label: $t('download_fit_products.excel_download'),
                      clickHandler: ({ close }) => {
                        download_fit_products_file('excel');
                        close();
                      }
                    },
                    {
                      label: $t('download_fit_products.csv_download'),
                      clickHandler: ({ close }) => {
                        download_fit_products_file('csv');
                        close();
                      }
                    }
                  ]
                ]"
              />
            </i18n>
            <div class="FitSettingsBasic__download-fit-products-description">
              <i18n path="download_fit_products.description">
                <AppInternalLink
                  class="FitSettingsBasic__download-fit-products-description-link"
                  to="/fit/size/products"
                  >{{
                    $t('download_fit_products.fit_products_size_menu')
                  }}</AppInternalLink
                >
              </i18n>
            </div>
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import FitSettingsView from './FitSettingsView';
import { mapState, mapActions, mapMutations } from 'vuex';
import qs from 'qs';
import requestDownload from '@/lib/requestDownload';
import moment from 'moment';

export default {
  name: 'FitSettingsBasic',
  mixins: [FitSettingsView],
  data() {
    return {
      SETTINGS_ID: 'fit_settings_basic',
      FIT_SETTINGS_URL: '/fit/settings/basic',
      fit_products: {
        date_type: 'created_at'
      },
      isDownloading: false
    };
  },
  computed: {
    ...mapState(['brandParams']),
    ...mapState('fitSettings', ['isUpdatingFitDescriptions']),
    groupOptions() {
      return {
        size_product_option_type_ids: {
          type: 'multiple_select',
          options: this.data.product_option_types.map(item => {
            return { value: item.id, label: item.name };
          })
        },
        edit_descriptions_all: {
          type: 'button',
          value: this.$t('edit_descriptions_all'),
          groupDescription: this.$t('edit_descriptions_all_info'),
          clickHandler: this.editDescriptionsButton
        }
      };
    },
    dateTypeOptions() {
      return [
        { label: this.$t('app.created_at'), value: 'created_at' },
        { label: this.$t('app.inserted_at'), value: 'fit_updated_at' }
      ];
    }
  },
  created() {
    this.SET_POLL_PARAMS({ key: 'update_fit_descriptions_job', value: '1' });
  },
  beforeDestroy() {
    this.UNSET_POLL_PARAMS('update_fit_descriptions_job');
  },

  methods: {
    ...mapMutations('poll', ['SET_POLL_PARAMS', 'UNSET_POLL_PARAMS']),
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    filename(type) {
      const brand_id = this.brandParams.brand_id;
      if (type === 'excel') type = 'xlsx';
      return `${brand_id}-fit-products-${moment().format('YMDHms')}.${type}`;
    },
    download_fit_products_file(type) {
      this.isDownloading = true;
      const params = { ...this.fit_products, ...this.brandParams };
      const url = `/api/fit/settings/download_fit_products_${type}?${qs.stringify(
        params
      )}`;
      requestDownload(url, this.filename(type)).finally(
        () => (this.isDownloading = false)
      );
    },
    editDescriptionsButton() {
      this.isUpdatingFitDescriptions === true
        ? this.createToast(
            this.$t('updating_fit_descriptions_is_already_running')
          )
        : this.openDialog(['FitDescriptionsEditDialog']);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.FitSettingsBasic__download-fit-products-description {
  margin-top: 4px;
  color: $color-content-text;
}

.FitSettingsBasic__download-fit-products-description-link {
  text-decoration: underline;
  color: $color-content-text-light;

  &:hover {
    color: $color-button-text;
  }
}

::v-deep .AppForm__group-description {
  color: $color-grey-60;
  font-size: 12px;
  line-height: 18px;
}
</style>

<i18n locale="ko">
{
  "download_fit_products": {
    "field": "{0} 기준 {1} 기간에 입력된 상품 정보{2}",
    "description": "선택된 기간에 {0} 메뉴에 입력된 상품별 실측치수 정보를 다운로드할 수 있습니다.",
    "fit_products_size_menu": "[핏 / 실측 치수 입력 / 상품별 입력]",
    "button": "파일 다운로드",
    "excel_download": "Excel 파일 다운로드",
    "csv_download": "CSV 파일 다운로드"
  },
  "downloading": "다운로드 중..",
  "edit_descriptions_all": "안내 문구 일괄 변경",
  "edit_descriptions_all_info": "등록되어 있는 모든 실측 치수 안내 문구, 기타 안내 사항을 일괄 수정하거나 삭제할 수 있습니다.",
  "updating_fit_descriptions_is_already_running": "안내 문구 일괄 변경 작업을 진행중입니다."
}
</i18n>
