import api from '@/lib/api';

const cache = {};

function checkImageCache(url) {
  return cache[url];
}

function checkImage(url) {
  return new Promise(function(resolve, reject) {
    if (url in cache) return resolve(cache[url]);

    api
      .get('/validations/check_image', { params: { url } })
      .then(({ data }) => {
        if (data.valid) {
          cache[url] = true;
          resolve();
        } else {
          cache[url] = false;
          reject();
        }
      })
      .catch(reject);
  });
}

export { checkImageCache, checkImage };
