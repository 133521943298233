import moment from 'moment';

const getMonthDate = date => {
  const monthDate = moment(date.replace(/-00-/, '-01-').replace(/00$/, '01'));
  return monthDate.isValidDate() ? monthDate.startOf('month').toVal() : null;
};

const dateRangeWithInterval = ({ start_date, end_date }, interval) => {
  const unit =
    interval === 'daily' ? 'day' : interval === 'weekly' ? 'week' : 'month';

  return {
    start_date: moment(start_date)
      .startOf(unit)
      .toVal(),
    end_date: moment(end_date)
      .endOf(unit)
      .toVal()
  };
};

export { getMonthDate, dateRangeWithInterval };
