<template>
  <AppTabContent menu-item-id="fit_size_products">
    <AppAjaxContent :is-loading="isLoading">
      <template #default>
        <AppSearchbar @submit="searchResource">
          <template #default>
            <li>
              <AppButtonSyncProduct
                :calculate-product-data="false"
                :hide-when-disabled="true"
              />
            </li>
          </template>

          <template #right>
            <li>
              <AppSearchWithType
                v-model="searchWithType"
                :search-types="searchTypes"
              />
            </li>
            <li>
              <AppDateRangeWithType
                v-model="dateRangeWithType"
                :date-types="dateTypes"
                @apply="searchResource"
              />
            </li>
            <li>
              <AppButtonToggle
                v-model="advancedSearchVisible"
                :label="$t('app.advanced_search')"
              />
            </li>
          </template>

          <template #advanced-search>
            <AppAdvancedSearch
              v-model="resourceParams"
              :visible="advancedSearchVisible"
              :sections="advancedSearchSections"
              @submit="searchResource"
            />
          </template>
        </AppSearchbar>

        <AppResourceTable
          table-id="fit-products-table"
          enable-refresh
          enable-column-settings
          :resources="products"
          :columns="columns"
          :rows="rows"
          @refresh="refreshResource"
          @paginate="paginate"
        >
          <template #cell="{ row, column, value }">
            <template v-if="column === 'dataStatus'">
              <ul class="badges">
                <li>
                  <AppBadge
                    v-if="value.fit_disabled_product"
                    badge-style="grey"
                    :label="$t('fit_data_status.disable')"
                  />
                  <AppBadge
                    v-else-if="value.fit_product"
                    :tooltip="
                      $t('fit_product.fit_data_inserted_tooltip', {
                        total_count: value.fit_product.fit_data_total_count,
                        inserted_count:
                          value.fit_product.fit_data_inserted_count
                      })
                    "
                    :badge-style="
                      dataStatusBadgeStyle(value.fit_product.data_status)
                    "
                    :label="
                      $t(`fit_data_status.${value.fit_product.data_status}`)
                    "
                  />
                  <AppBadge
                    v-else
                    badge-style="mango"
                    :label="$t('fit_data_status.none')"
                  />
                </li>
                <li
                  v-if="value.fit_product && value.fit_product.hide_size_widget"
                >
                  <AppBadge :label="$t('fit_product.hide_size_widget')" />
                </li>
              </ul>
            </template>
            <FitSizeProductsDataInsertedAtCell
              v-else-if="column === 'dataInsertedAt'"
              :fit-product="value"
            />
            <template v-else-if="column === 'actions'">
              <div v-if="isPartialOrFullFitProduct(value)">
                <div>
                  <AppButton
                    class="FitSizeProducts__action-button-copy-tag"
                    :label="$t('local.button_copy_tag')"
                    @click="showTags(value)"
                  />
                </div>
                <div>
                  <AppButtonDownload
                    class="FitSizeProducts__action-button-download-image"
                    :disabled="!downloadable(value)"
                    :label="$t('local.button_download_image')"
                    :filename="`fit-${value.code}.jpg`"
                    :event-bus="row.downloadEventBus"
                    @click.native.prevent.stop="
                      downloadable(value) &&
                        downloadImage(value, row.downloadEventBus)
                    "
                  />
                  <AppButton
                    class="FitSizeProducts__action-button-preview-fit-product"
                    :label="$t('local.button_preview')"
                    @click="
                      openDialog([
                        'FitSizeProductPreviewDialog',
                        {
                          name: value.name,
                          url: value.fit_product.url
                        }
                      ])
                    "
                  />
                </div>
              </div>
              <div v-else>{{ '-' }}</div>
            </template>
            <template v-else-if="column === 'disable'">
              <div v-if="isPartialOrFullFitProduct(value)">{{ '-' }}</div>
              <div v-else>
                <AppButton
                  :button-style="
                    value.fit_disabled_product
                      ? 'grey-outline'
                      : 'mango-outline'
                  "
                  :label="
                    value.fit_disabled_product
                      ? $t('local.button_restore')
                      : $t('local.button_disable')
                  "
                  @click="disableOrRestoreProduct(value)"
                />
              </div>
            </template>
            <template v-else-if="column === 'size'">
              <div v-if="value.fit_disabled_product">{{ '-' }}</div>
              <div v-else>
                <div>
                  <AppButton
                    :label="$t('local.button_edit')"
                    @click="
                      openDialog([
                        'FitSizeProductEditDialog',
                        { product: value }
                      ])
                    "
                  />
                </div>
                <div class="mt4" @click.capture="confirmCopy(value, $event)">
                  <AppProductSelectButton
                    :title="$t('fit_product.select_src_product')"
                    :sub-title="value.name"
                    :label="$t('local.button_copy')"
                    :default-filters="[
                      'fit_data:partial_or_full',
                      `exclude_id:${value.id}`
                    ]"
                    @select="
                      copyFitSizeProduct({
                        product: value,
                        srcProduct: $event
                      })
                    "
                  />
                </div>
              </div>
            </template>
          </template>
        </AppResourceTable>
      </template>
    </AppAjaxContent>
  </AppTabContent>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import BrandSyncStatus from '@/enums/BrandSyncStatus';
import DialogSize from '@/enums/DialogSize';
import ResourceView from '@/mixins/ResourceView';
import FitSizeProductsDataInsertedAtCell from './FitSizeProductsDataInsertedAtCell';

export default {
  name: 'FitSizeProducts',
  components: { FitSizeProductsDataInsertedAtCell },
  mixins: [ResourceView],
  data() {
    return {
      advancedSearchVisible: [
        'fit_size_widget_visibility',
        'fit_template'
      ].some(key => key in this.$route.query),
      columns: [
        {
          id: 'code',
          label: this.$t('fit_product.code'),
          type: 'code',
          required: true
        },
        {
          id: 'product',
          label: this.$t('fit_product.product'),
          type: 'product',
          required: true
        },
        { id: 'template', label: this.$t('fit_product.template') },
        { id: 'dataStatus', label: this.$t('fit_product.fit_data_status') },
        {
          id: 'dataInsertedAt',
          label: this.$t('fit_product.fit_data_inserted_at')
        },
        { id: 'actions', label: this.$t('app.actions'), required: true },
        { id: 'disable', label: this.$t('local.disable'), required: true },
        { id: 'size', label: this.$t('local.size'), required: true }
      ],
      searchTypes: [
        { value: 'code', label: this.$t('products.code') },
        { value: 'name', label: this.$t('products.name') }
      ],
      dateTypes: [
        { value: 'created_at', label: this.$t('app.created_at') },
        { value: 'fit_updated_at', label: this.$t('app.inserted_at') }
      ],
      isLoading: true
    };
  },
  computed: {
    ...mapState('fitProduct', ['products', 'fitTemplates']),
    ...mapState('session', ['currentBrand', 'fitSettings']),
    ...mapGetters('fitProduct', ['isFetchingProducts']),
    BrandSyncStatus: () => BrandSyncStatus,
    defaultResourceParams: () => ({
      tab: '',
      per: '20',
      page: '1',
      search_type: 'name',
      search_query: '',
      date_type: 'created_at',
      start_date: '',
      end_date: ''
    }),
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    dateRangeWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    rows() {
      return this.products.items.map(product => ({
        id: product.id,
        code: product.code,
        product: product,
        template: product.fit_product ? product.fit_product.template.name : '-',
        actions: product,
        disable: product,
        dataStatus: product,
        dataInsertedAt: product.fit_product,
        size: product,
        downloadEventBus: new Vue()
      }));
    },
    fitSizeWidgetVisibility: {
      get() {
        return this.resourceParams.fit_size_widget_visibility;
      },
      set(newValue) {
        this.resourceParams = {
          ...this.resourceParams,
          fit_size_widget_visibility: newValue
        };
      }
    },
    fitTemplateSelectOptions() {
      const defaultOptions = [
        {
          label: this.$t('local.no_template'),
          value: 'no'
        },
        {
          label: this.$t('local.yes_template'),
          value: 'yes'
        }
      ];
      const templateOptions = this.fitTemplates.map(t => ({
        label: t.name,
        value: t.id
      }));
      return [...defaultOptions, ...templateOptions];
    },
    advancedSearchSections() {
      return [
        {
          groups: [
            {
              id: 'fit_size_widget_visibility',
              label: this.$t('local.fit_size_widget_visibility'),
              type: 'hash_select_button',
              selectButtons: [
                {
                  id: 'fit_size_widget_visibility',
                  options: [
                    {
                      label: this.$t('local.fit_size_widget_visible'),
                      value: '1'
                    },
                    {
                      label: this.$t('local.fit_size_widget_invisible'),
                      value: '0'
                    }
                  ]
                }
              ]
            },
            {
              id: 'fit_template',
              label: this.$t('local.fit_template'),
              type: 'select_filterable',
              options: this.fitTemplateSelectOptions,
              placeholder: this.$t('app.all')
            }
          ]
        }
      ];
    }
  },
  beforeDestroy() {
    this.resetProducts();
  },
  methods: {
    ...mapActions('fitProduct', [
      'fetchProducts',
      'resetProducts',
      'disableProduct',
      'restoreProduct',
      'copyFitSizeProduct'
    ]),
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    fetchResource(params) {
      this.isLoading = true;
      this.fetchProducts({ ...params, fit_data: params.tab }).finally(
        () => (this.isLoading = false)
      );
    },
    dataStatusBadgeStyle(dataStatus) {
      switch (dataStatus) {
        case 'partial':
          return 'blue';
        case 'full':
          return 'mint-green';
        default:
          return 'mango';
      }
    },
    downloadImage(product, downloadEventBus) {
      const width = window.prompt(this.$t('local.input_image_width'));
      if (width) {
        const host = this.fitSettings.fit_host;
        const brandCode = this.currentBrand.code;
        const productCode = product.code;
        var url = '';
        if (this.fitSettings.enable_combined_widget) {
          url = `https://${host}/${brandCode}/fit/products/${productCode}/combined_fit_product.jpg?width=${width}`;
        } else {
          url = `https://${host}/${brandCode}/fit/products/${productCode}.jpg?width=${width}`;
        }
        downloadEventBus.$emit('download', url);
      }
    },
    showTags(product) {
      this.openDialog([
        'FitSizeProductTagsDialog',
        {
          name: product.name,
          code: product.code,
          url: product.fit_product.url
        }
      ]);
    },
    disableOrRestoreProduct(product) {
      const method = product.fit_disabled_product ? 'restore' : 'disable';
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t(`fit_product.confirm_${method}_title`),
          markdownText: this.$t(`fit_product.confirm_${method}_message`),
          snoozeId: 'FitSizeProducts.fit_product',
          width: DialogSize.SMALL
        }
      ])
        .then(eventBus => {
          eventBus.$on('close', () => {
            this.dispatchFitProductAction(method, product);
          });
        })
        .catch(() => this.dispatchFitProductAction(method, product));
    },
    dispatchFitProductAction(method, product) {
      this.$store.dispatch(`fitProduct/${method}Product`, {
        product,
        tab: this.resourceParams.tab
      });
    },
    confirmCopy(product, e) {
      const requiresConfirmation = product.fit_product?.data_status !== 'none';
      if (requiresConfirmation && !confirm(this.$t('fit_product.confirm_copy')))
        e.stopPropagation();
    },
    downloadable(product) {
      return product.fit_product && !product.fit_product.hide_size_widget;
    },
    isPartialOrFullFitProduct({ fit_product, fit_disabled_product }) {
      return (
        fit_product &&
        fit_product.data_status !== 'none' &&
        !fit_disabled_product
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.FitSizeProducts__action-button-copy-tag {
  width: 179px;
  margin-bottom: 4px;
}

.FitSizeProducts__action-button-download-image {
  width: 95px;
}

.FitSizeProducts__action-button-preview-fit-product {
  width: 80px;
}

::v-deep {
  .fit-products-table__product {
    flex: 1 0 160px;
  }

  .fit-products-table__template {
    flex: 1 0 140px;
  }

  .fit-products-table__data-status {
    flex: 1 0 120px;
  }

  .fit-products-table__actions {
    flex: 1 0 200px;
  }

  .fit-products-table__size {
    flex: 1 0 100px;
  }

  .fit-products-table__data-inserted-at {
    flex: 1 0 100px;
  }

  .fit-products-table__disable {
    flex: 1 0 110px;
  }
}
</style>

<i18n locale="ko">
{
  "fit_data_status": {
    "none": "미입력",
    "partial": "부분입력",
    "full": "입력",
    "disable": "사용안함"
  },
  "local": {
    "size": "실측치수",
    "button_copy_tag": "태그 복사",
    "button_download_image": "이미지 저장",
    "button_preview": "미리보기",
    "button_disable": "사용 안함",
    "button_restore": "상품 복원",
    "button_edit": "직접입력",
    "button_copy": "불러오기",
    "input_image_width": "이미지 가로 길이를 px단위로 입력해주세요",
    "fit_size_widget_visibility": "위젯 노출",
    "fit_template": "적용 템플릿",
    "no_template": "템플릿 미적용",
    "yes_template": "모든 템플릿",
    "fit_size_widget_visible": "사이즈 위젯 노출",
    "fit_size_widget_invisible": "사이즈 위젯 숨김",
    "disable": "사용 안함 / 상품 복원"
  }
}
</i18n>
