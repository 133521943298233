<template>
  <AppDropdownMenuButton :label="$t('app.manage')" :menu-items="menuItems" />
</template>

<script>
import api from '@/lib/api';

export default {
  name: 'ReviewCategoryProductManageButton',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    menuItems() {
      return [
        {
          label: this.product.display
            ? this.$t('undisplay')
            : this.$t('display'),
          clickHandler: ({ close }) =>
            this.updateProduct(close, {
              display: !this.product.display
            })
        },
        {
          label: this.product.sold_out
            ? this.$t('unsoldout')
            : this.$t('soldout'),
          clickHandler: ({ close }) =>
            this.updateProduct(close, {
              stock_count: this.product.sold_out ? 1 : 0
            })
        }
      ];
    }
  },
  methods: {
    updateProduct(close, params) {
      const key = Object.keys(params)[0];
      const val = params[key];

      if (!confirm(this.$t(`confirm.${key}.${val}`))) return;

      api
        .patch(
          `/review/products/${this.product.id}`,
          { ...params },
          { successMessage: this.$t(`success.${key}.${val}`) }
        )
        .then(({ data }) => this.$emit('change', data.product))
        .finally(() => close());
    }
  }
};
</script>

<i18n locale="ko">
{
  "display": "상품 숨김 해제",
  "undisplay": "상품 숨기기",
  "unsoldout": "상품 품절 해제",
  "soldout": "상품 품절 처리",
  "confirm": {
    "display": {
      "true": "상품 숨김 해제합니다. 진행하시겠습니까?",
      "false": "상품을 숨김 처리합니다. 진행하시겠습니까?"
    },
    "stock_count": {
      "1": "상품 품절 해제합니다. 진행하시겠습니까?",
      "0": "상품 품절 처리합니다. 진행하시겠습니까?"
    }
  },
  "success": {
    "display": {
      "true": "상품을 숨김 해제했습니다.",
      "false": "상품을 숨김 처리했습니다."
    },
    "stock_count": {
      "1": "상품 품절 해제했습니다.",
      "0": "상품 품절 처리했습니다."
    }
  }
}
</i18n>
