import { constructEnum } from '@/lib/enum';

const WidgetInstallMethod = constructEnum('WidgetInstallMethod', {
  NONE: 0,
  AUTO: 1,
  HARDCODED: 2,
  SMART: 4
});

WidgetInstallMethod.label = function(installMethod) {
  return WidgetInstallMethod.values
    .map(v => {
      if ((v & installMethod) !== 0) return WidgetInstallMethod.t(v);
      return null;
    })
    .filter(Boolean)
    .join(',');
};

export default WidgetInstallMethod;
