import { constructEnum } from '@/lib/enum';

export default constructEnum('InstallRequestProgressStep', {
  PRODUCT_DETAIL: 0,
  MAIN: 1,
  REVIEW_BOARD: 2,
  COUNT_AND_SCORE: 3,
  ETC: 4,
  CONFIRM: 5
});
