<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>Basic Usage</label>
      </div>
      <AppNumber :value="123456.789" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>Precision</label>
      </div>
      <div>
        <AppNumber :value="123456.789" :precision="2" />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>[precision=2][force-precision]</label>
      </div>
      <div>
        <AppNumber :value="1" :precision="2" force-precision />
      </div>
      <div>
        <AppNumber :value="1.2" :precision="2" force-precision />
      </div>
      <div>
        <AppNumber :value="1.23" :precision="2" force-precision />
      </div>
      <div>
        <AppNumber :value="1.234" :precision="2" force-precision />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>Unit</label>
      </div>
      <AppNumber :value="123456.789" unit="currency.usd" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>Placeholder</label>
      </div>
      <AppNumber placeholder="-" />
    </div>
  </div>
</template>

<script>
export default { name: 'UiNumber' };
</script>
