<template>
  <AppModalDefault
    :title="$t('title')"
    :sub-title="$t('sub_title', [campaignName, cohortId.toString()])"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppSmallDeviceScrollable>
          <AppTable :columns="columns" :rows="rows" />
        </AppSmallDeviceScrollable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';
import TargetCohortHistoryType from '@/enums/TargetCohortHistoryType';
import TargetDispatchStatus from '@/enums/TargetDispatchStatus';
import TargetCalculateStatus from '@/enums/TargetCalculateStatus';

export default {
  name: 'TargetCohortHistoriesDialog',
  mixins: [DialogView],
  props: {
    cohortId: { type: Number, required: true },
    campaignName: { type: String, required: true }
  },
  data: () => ({ isLoading: true, histories: [] }),
  computed: {
    columns() {
      return [
        {
          id: 'created_at',
          label: this.$t('created_at'),
          align: 'left',
          type: 'datetime'
        },
        { id: 'hostname', label: this.$t('hostname'), align: 'left' },
        { id: 'process', label: this.$t('process'), align: 'left' },
        { id: 'entry', label: this.$t('entry'), align: 'left' },
        {
          id: 'metadata',
          label: this.$t('metadata'),
          type: 'pre',
          align: 'left'
        }
      ];
    },
    rows() {
      return this.histories.map(history => ({
        ...history,
        entry: this.displayHistoryType(history),
        metadata: this.displayMetadata(history.metadata)
      }));
    }
  },
  mounted() {
    api
      .get(`/target/cohorts/${this.cohortId}/histories`)
      .then(({ data }) => (this.histories = data.histories))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    displayMetadata(metadata) {
      if (metadata) {
        if (metadata.filters) {
          return _.chain(metadata.filters)
            .map(filter =>
              this.$t(`target_campaign_filter.${_.snakeCase(filter)}`)
            )
            .join(', ')
            .value();
        } else if (metadata.dispatch_status)
          return TargetDispatchStatus.t(metadata.dispatch_status);
        else if (metadata.calculate_status)
          return TargetCalculateStatus.t(metadata.calculate_status);
      }
      return '';
    },
    displayHistoryType({ history_type, metadata }) {
      let entry = TargetCohortHistoryType.t(history_type);
      if (metadata && (metadata.begin || metadata.end)) {
        const postfix = metadata.begin ? 'begin' : 'end';
        entry = entry + this.$t(postfix);
      }
      return entry;
    }
  }
};
</script>

<style lang="scss" scoped></style>

<i18n locale="ko">
{
  "title": "발송 이력",
  "sub_title": "{0} (발송 ID {1})",
  "created_at": "시간",
  "hostname": "호스트",
  "process": "프로세스",
  "entry": "내역",
  "metadata": "정보",
  "begin": "시작",
  "end": "종료",
  "target_campaign_filter": {
    "default": "필터",
    "allow_message_filter": "SMS/이메일 발송 허용 필터",
    "outdate_filter": "회원 동기화 필터",
    "already_sent_today_filter": "오늘 이미 발송한 대상 필터",
    "base": "필터",
    "blacklist_filter": "타겟 제한 ID 필터",
    "campaign_media_filter": "캠페인 매체 필터",
    "cart_filter": "장바구니 미구매",
    "lock_interval_filter": "지난 발송 필터",
    "member_inactivation_filter": "휴면 전환 회원 필터",
    "order_by_joined_at_filter": "회원 가입후 상품 구매수",
    "order_filter": "상품 구매수",
    "product_click_filter": "여러 상품 열람",
    "purchase_price_filter": "상품 구매액",
    "repetitive_product_click_filter": "같은 상품 열람",
    "review_filter": "리뷰 작성수",
    "signup_filter": "회원가입 필터",
    "uniqueness_filter": "발송내 중복 대상 필터",
    "unpaid_order_filter": "무통장 미입금",
    "valid_recipient_filter": "올바른 전화번호/이메일 필터",
    "visit_filter": "방문 필터",
    "coupon_expiry_filter": "기한만료 쿠폰 필터",
    "invalid_member_filter": "휴면/삭제회원 필터",
    "mileage_expiring_filter": "적립금 지급 기한 만료",
    "all_members_filter": "전체회원",
    "active_members_filter": "최근 방문 회원",
    "inactive_members_filter": "장기 미방문 회원"
  }
}
</i18n>
