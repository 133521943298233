<template>
  <AppModalDefault
    :width="width"
    :title="description"
    :can-maximize="canMaximize"
    @close="close"
  >
    <div slot="body">
      {{ content }}
    </div>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'UiModalDefaultDialog',
  mixins: [DialogView],
  props: {
    width: { type: String, required: true },
    content: { type: String, required: true },
    canMaximize: { type: Boolean, default: false },
    description: { type: String, required: true }
  }
};
</script>
