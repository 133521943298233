<template>
  <div
    v-tooltip="badgeTooltip"
    :class="[
      'AppBadge',
      `AppBadge--${badgeStyle}`,
      badgeSize ? `AppBadge--${badgeSize}` : '',
      { 'AppBadge--spinner': spinner },
      { 'AppBadge--clickable': clickable },
      { 'AppBadge--with-max-width': withMaxWidth }
    ]"
    @click="$emit('click')"
  >
    {{ label }}
    <AppSpinner v-if="spinner" class="AppBadge__spinner" />
  </div>
</template>

<script>
export default {
  name: 'AppBadge',
  props: {
    badgeStyle: {
      type: String,
      default: 'default',
      validator: v =>
        [
          'default',
          'mint-green',
          'mint-green-outline',
          'mango',
          'mango-outline',
          'blue',
          'blue-outline',
          'red',
          'red-outline',
          'lavender',
          'lavender-outline',
          'grey',
          'grey-outline'
        ].includes(v)
    },
    badgeSize: { type: String, default: null },
    label: { type: String, required: true },
    spinner: { type: Boolean, default: false },
    clickable: { type: Boolean, default: false },
    withMaxWidth: { type: Boolean, default: false },
    tooltip: { type: [Object, String], default: null }
  },
  computed: {
    badgeTooltip() {
      if (this.tooltip) return this.tooltip;

      return { message: () => this.badgeTooltipMessage };
    },
    badgeTooltipMessage() {
      const { scrollWidth, clientWidth } = this.$el;
      return scrollWidth > clientWidth ? this.label : null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

@mixin badge-base($color) {
  border-color: $color;
  color: white;
  background-color: $color;
}

@mixin badge-outline-base($color) {
  border-color: $color;
  color: $color;
  background-color: white;
}

.AppBadge {
  display: inline-block;
  white-space: nowrap;
  font-size: 11px;
  font-weight: normal;
  line-height: 17px;
  padding: 0 7px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  user-select: none;

  &--small {
    font-size: 10px;
    line-height: 16px;
    padding: 0 4px;
  }

  &--default {
    @include badge-base($color-grey-25);
    color: $color-content-text-dark;
  }

  &--mint-green {
    @include badge-base($color-mint-green);
  }

  &--mint-green-outline {
    @include badge-outline-base($color-mint-green);
  }

  &--mango {
    @include badge-base($color-mango);
  }

  &--mango-outline {
    @include badge-outline-base($color-mango);
  }

  &--blue {
    @include badge-base($color-blue);
  }

  &--blue-outline {
    @include badge-outline-base($color-blue);
  }

  &--red {
    @include badge-base($color-red);
  }

  &--red-outline {
    @include badge-outline-base($color-red);
  }

  &--lavender {
    @include badge-base($color-lavender);
  }

  &--lavender-outline {
    @include badge-outline-base($color-lavender);
  }

  &--grey {
    @include badge-base($color-grey-60);
  }

  &--grey-outline {
    @include badge-outline-base($color-button-text);
    border-color: $color-grey-40;
  }

  &--spinner {
    position: relative;
    padding-right: 20px;
  }

  &--clickable {
    cursor: pointer;
  }

  &--with-max-width {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & + & {
    margin-left: 4px;
  }
}

.AppBadge__spinner {
  position: absolute;
  top: 4px;
  right: 5px;
  font-size: 10px;
  border-width: 1px;
  margin-left: 4px;
}
</style>
