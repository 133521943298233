<template>
  <div class="AppContainer"><slot /></div>
</template>

<script>
export default { name: 'AppContainer' };
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppContainer {
  border: solid 1px #ceced0;
  border-radius: 3px;
  padding: 20px;

  ::v-deep {
    + *:not(.AnalyticsInsightAjaxContent__screen) {
      margin-top: 24px;
    }

    + .AppHeading {
      margin-top: 16px;
    }
  }
}
</style>
