<template>
  <AppResourceTable
    :columns="columns"
    :rows="rows"
    :no-data="$t('no_data')"
    :enable-total-count="false"
  >
    <template #cell="{ column, row }">
      <template v-if="column === 'select'">
        <div v-tooltip="row.isSelectable ? null : $t('not_selectable')">
          <AppRadio
            input-style="standalone"
            :value="row.id"
            :selected-value="selectedValue"
            :disabled="!row.isSelectable"
            @change="$emit('change', $event)"
          />
        </div>
      </template>
      <template v-else-if="column === 'name'">
        <AppButton
          button-style="underline"
          :label="row.name"
          @click="$emit('show-template', row)"
        />
      </template>
      <template v-else-if="column === 'status'">
        <AppBadge v-bind="row.status" />
      </template>
      <template v-else-if="column === 'manage'">
        <AppButton
          v-if="row.isEditable"
          :label="$t('app.edit')"
          @click="$emit('edit-template', row)"
        />
        <AppButton
          v-if="row.isEditable"
          :label="$t('app.delete')"
          button-style="red-outline"
          @click="$emit('delete-template', row)"
        />
      </template>
    </template>
  </AppResourceTable>
</template>

<script>
import BizMessageTemplateStatus from '@/enums/BizMessageTemplateStatus';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';

export default {
  name: 'MessageTemplateSelectTable',
  model: { prop: 'selectedValue', event: 'change' },
  props: {
    templates: { required: true, type: Array },
    selectedValue: { type: Number, default: null }
  },
  computed: {
    columns() {
      return [
        { id: 'select', label: this.$t('app.select'), width: '48px' },
        { id: 'name', label: this.$t('columns.name'), align: 'left' },
        { id: 'status', label: this.$t('app.status'), width: '108px' },
        { id: 'manage', label: this.$t('app.manage'), width: '132px' }
      ];
    },
    rows() {
      return this.templates
        .filter(
          template =>
            ![
              BizMessageTemplateInspectionStatus.NONE,
              BizMessageTemplateInspectionStatus.REGISTERED
            ].includes(template.inspection_status)
        )
        .map(template => ({
          ...template,
          status:
            template.inspection_status ===
            BizMessageTemplateInspectionStatus.APPROVED
              ? {
                  badgeStyle: BizMessageTemplateStatus.badgeStyle(
                    template.status
                  ),
                  label: BizMessageTemplateStatus.t(template.status)
                }
              : {
                  badgeStyle: BizMessageTemplateInspectionStatus.badgeStyle(
                    template.inspection_status
                  ),
                  label: BizMessageTemplateInspectionStatus.t(
                    template.inspection_status
                  ),
                  tooltip: template.rejected_message
                },
          isSelectable:
            template.status !== BizMessageTemplateStatus.STOPPED &&
            template.inspection_status ===
              BizMessageTemplateInspectionStatus.APPROVED,
          isEditable:
            template.inspection_status !==
            BizMessageTemplateInspectionStatus.APPROVED
        }));
    }
  },
  mounted() {
    const selectableRows = this.rows.filter(({ isSelectable }) => isSelectable);
    this.$emit('invalid', !selectableRows.length);
  }
};
</script>

<i18n locale="ko">
{
  "no_data": "템플릿을 생성하거나 기본 메시지를 사용해주세요.",
  "columns": {
    "name": "템플릿 명"
  },
  "not_selectable": "상태가 '사용가능'인 템플릿만 선택이 가능합니다."
}
</i18n>
