<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar>
      <template #default>
        <li v-if="isSuperAdminAccessible" class="super-admin__item">
          <AppButton
            :label="$t('add_bonus')"
            button-style="red"
            @click="clickAddTransaction"
          />
        </li>
      </template>
      <template #right>
        <li>
          <AppSelect
            v-model="resourceParams.amount"
            :options="[
              { label: $t('amount.save'), value: 'save' },
              { label: $t('amount.use'), value: 'use' }
            ]"
            :placeholder="$t('transaction_type_placeholder')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppDateRangePicker
            v-model="resourceParams"
            @update="searchResource"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppResourceTable
      table-id="target-transactions-table"
      :resources="transactions"
      :rows="rows"
      :columns="columns"
      :enable-total-count="false"
      :no-data="$t('no_data')"
      @paginate="paginate"
    >
      <template #cell="{ row, column }">
        <template v-if="column === 'description'">
          <AppBadge
            v-if="row.pending"
            badge-style="grey-outline"
            :label="$t('pending.label')"
            :tooltip="$t('pending.tooltip')"
            class="ThePaymentHistoryDialogTarget__pending"
          />
          <span>{{ row.description }}</span>
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'ThePaymentHistoryDialogTarget',
  mixins: [ResourceViewNoRoute],
  data() {
    return {
      isLoading: true,
      transactions: nullResources
    };
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    columns() {
      return [
        { id: 'date', label: this.$t('app.date'), type: 'datetime' },
        {
          id: 'description',
          label: this.$t('table.description'),
          align: 'left'
        },
        {
          id: 'plus_point_amount',
          label: this.$t('table.plus_point_amount'),
          type: 'number',
          align: 'right',
          default: '-'
        },
        {
          id: 'minus_point_amount',
          label: this.$t('table.minus_point_amount'),
          type: 'number',
          align: 'right',
          default: '-'
        },
        {
          id: 'point_balance',
          label: this.$t('table.point_balance'),
          type: 'number',
          align: 'right'
        },
        {
          id: 'plus_credit_amount',
          label: this.$t('table.plus_cash_amount'),
          type: 'number',
          align: 'right',
          default: '-'
        },
        {
          id: 'minus_credit_amount',
          label: this.$t('table.minus_cash_amount'),
          type: 'number',
          align: 'right',
          default: '-'
        },
        {
          id: 'credit_balance',
          label: this.$t('table.cash_balance'),
          type: 'number',
          align: 'right'
        }
      ];
    },
    rows() {
      return this.transactions.items.map(item => ({
        ...item,
        date: item.created_at,
        plus_point_amount: item.point_amount > 0 ? item.point_amount : null,
        minus_point_amount: item.point_amount < 0 ? item.point_amount : null,
        plus_credit_amount: item.credit_amount > 0 ? item.credit_amount : null,
        minus_credit_amount: item.credit_amount < 0 ? item.credit_amount : null
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/payment_history/target_transactions', { params })
        .then(({ data }) => (this.transactions = data.target_transactions))
        .finally(() => (this.isLoading = false));
    },
    clickAddTransaction() {
      this.openDialog(['ThePaymentHistoryBonusTargetDialog']).then(eventBus => {
        this.orgResourceParams = this.resourceParams = this.defaultResourceParams;
        eventBus.$on('update', this.refreshResource);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ThePaymentHistoryDialogTarget__bonus-type {
  margin-right: 8px;
}

.ThePaymentHistoryDialogTarget__pending {
  margin-right: 8px;
}

::v-deep {
  .target-transactions-table__date {
    flex: 0 0 110px;
  }

  .target-transactions-table__plus-point-amount {
    flex: 0 0 104px;
  }

  .target-transactions-table__minus-point-amount {
    flex: 0 0 104px;
  }

  .target-transactions-table__point-balance {
    flex: 0 0 104px;
  }

  .target-transactions-table__plus-credit-amount {
    flex: 0 0 104px;
  }

  .target-transactions-table__minus-credit-amount {
    flex: 0 0 104px;
  }

  .target-transactions-table__credit-balance {
    flex: 0 0 104px;
  }
}
</style>

<i18n locale="ko">
{
  "table": {
    "description": "내역",
    "plus_point_amount": "충전 포인트",
    "minus_point_amount": "차감 포인트",
    "point_balance": "잔여 포인트",
    "plus_cash_amount": "충전 캐시",
    "minus_cash_amount": "차감 캐시",
    "cash_balance": "잔여 캐시"
  },
  "amount": {
    "save": "충전 내역",
    "use": "차감 내역"
  },
  "pending": {
    "label": "정산중",
    "tooltip": "SMS 발송 후, 통신사 및 발송 시스템 사정에 따라\n최대 96시간까지 메시지 발송/실패 여부를 업데이트하며,\n최종 실패하는 건의 경우 발송 비용은 일괄 복구 처리됩니다."
  },
  "add_bonus": "포인트/캐시 수동 변경",
  "transaction_type_placeholder": "전체 내역",
  "no_data": "타겟 이용내역이 없습니다."
}
</i18n>
