var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppSearchbar',{scopedSlots:_vm._u([{key:"right",fn:function(){return [(_vm.itemStatusOptions.length)?_c('li',[_c('AppSelectFilterable',{attrs:{"value":_vm.itemStatus,"options":_vm.itemStatusOptions,"placeholder":_vm.$t('searchbar.dispatch_result_placeholder')},on:{"change":function($event){return _vm.$emit('change-item-status', $event)}}})],1):_vm._e()]},proxy:true}])}),_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppResourceTable',{attrs:{"table-id":_vm.tableId,"resources":_vm.cohortItems,"columns":_vm.finalColumns,"rows":_vm.rows},on:{"paginate":function($event){return _vm.$emit('paginate', $event)}},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [(column === 'date')?[(row.created_at)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('date.created_at'))+": "+_vm._s(_vm._f("formatDateTimeWithoutSecond")(row.created_at))+" ")]):_vm._e(),(row.sent_at)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('date.sent_at'))+": "+_vm._s(_vm._f("formatDateTimeWithoutSecond")(row.sent_at))+" ")]):_vm._e(),(row.first_checked_at)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('date.first_checked_at'))+": "+_vm._s(_vm._f("formatDateTimeWithoutSecond")(row.first_checked_at))+" ")]):_vm._e(),(row.first_checked_ip && _vm.isSuperAdminAccessible)?_c('div',{class:[
              'super-admin__item',
              {
                TargetReportsDispatchResultDialogTable__suspicious:
                  row.suspicious
              }
            ]},[_vm._v(" "+_vm._s(_vm.$t('date.first_checked_ip'))+": "+_vm._s(row.first_checked_ip)+" ")]):_vm._e(),(row.last_checked_at)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('date.last_checked_at'))+": "+_vm._s(_vm._f("formatDateTimeWithoutSecond")(row.last_checked_at))+" ")]):_vm._e()]:(column === 'user')?[(row.name)?_c('div',[_vm._v(_vm._s(row.name))]):_vm._e(),(row.username)?_c('div',[_vm._v(_vm._s(row.username))]):_vm._e(),(row.to)?_c('div',[_vm._v(_vm._s(row.to))]):_vm._e()]:(column === 'status')?[_c('TargetReportsDispatchResultDialogTableStatusBadge',{attrs:{"status":row.status,"dispatch-method":_vm.dispatchMethod,"tooltip":row.errorTooltip}})]:_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }