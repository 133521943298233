<template>
  <div
    :class="[
      'AppMultipleSelectProductCategory',
      {
        'AppMultipleSelectProductCategory--disabled': disabled,
        'AppMultipleSelectProductCategory--invalid': invalid
      }
    ]"
    @click="openMultipleSelectProductCategoryDialog"
  >
    <div
      v-if="productCategories.length"
      class="AppMultipleSelectProductCategory__options"
    >
      <ul>
        <li
          v-for="(option, i) in selectedProductCategoryOptions"
          :key="option.value"
          class="AppMultipleSelectProductCategory__option"
        >
          <div
            class="AppMultipleSelectProductCategory__selection-wrapper"
            @click.stop
          >
            <AppSelection
              :label="option.label"
              @remove="removeProductCategory(option.value)"
            />
          </div>
          <span
            v-if="
              productCategories.length > MAX_PRODUCT_CATEGORIES_COUNT &&
                i === MAX_PRODUCT_CATEGORIES_COUNT - 1
            "
            class="AppMultipleSelectProductCategory__omitted-count"
            >{{
              $t('omitted_count', [
                productCategories.length - MAX_PRODUCT_CATEGORIES_COUNT
              ])
            }}</span
          >
        </li>
      </ul>
    </div>
    <div v-else class="AppMultipleSelectProductCategory__placeholder">
      {{ placeholder }}
    </div>
    <AppHiddenInputArray v-if="name" :name="name" :values="productCategories" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

const MAX_PRODUCT_CATEGORIES_COUNT = 3;

export default {
  name: 'AppMultipleSelectProductCategory',
  model: { prop: 'productCategories', event: 'change' },
  props: {
    productCategories: { type: Array, required: true },
    name: { type: String, default: null },
    invalid: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default() {
        return this.$t('placeholder');
      }
    },
    useCode: { type: Boolean, default: false },
    excludeCategoryValues: { type: Array, default: null }
  },
  computed: {
    ...mapGetters('productCategory', [
      'productCategoryOptions',
      'productCategoryCodeOptions'
    ]),
    MAX_PRODUCT_CATEGORIES_COUNT: () => MAX_PRODUCT_CATEGORIES_COUNT,
    selectedProductCategoryOptions() {
      const productOptions = this.useCode
        ? this.productCategoryCodeOptions
        : this.productCategoryOptions;

      return productOptions
        .filter(({ value }) => this.productCategories.includes(value))
        .slice(0, MAX_PRODUCT_CATEGORIES_COUNT);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openMultipleSelectProductCategoryDialog() {
      this.openDialog([
        'AppMultipleSelectProductCategoryDialog',
        {
          productCategories: this.productCategories,
          useCode: this.useCode,
          excludeCategoryValues: this.excludeCategoryValues
        }
      ]).then(eventBus =>
        eventBus.$on('submit', newProductCategories =>
          this.$emit('change', newProductCategories)
        )
      );
    },
    removeProductCategory(value) {
      this.$emit(
        'change',
        this.productCategories.filter(
          productCategoryValue => productCategoryValue !== value
        )
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppMultipleSelectProductCategory {
  @include input-base;
}

.AppMultipleSelectProductCategory__options {
  padding: 0 11px 4px;
}

.AppMultipleSelectProductCategory__option {
  margin-top: 4px;
}

.AppMultipleSelectProductCategory__selection-wrapper {
  display: inline-block;
}

.AppMultipleSelectProductCategory__omitted-count {
  margin-left: 4px;
}

.AppMultipleSelectProductCategory__placeholder {
  @include text-placeholder;
  padding: 5px 11px;
  pointer-events: none;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<i18n locale="ko">
{
  "placeholder": "상품 카테고리를 선택해주세요.",
  "omitted_count": "외 {0}개"
}
</i18n>
