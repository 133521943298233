import { constructEnum } from '@/lib/enum';

const TargetWidgetMainImageType = constructEnum('TargetWidgetMainImageType', {
  REVIEW: 0,
  PRODUCT: 10,
  EMPTY: 100
});

TargetWidgetMainImageType.bannerTypes = [
  TargetWidgetMainImageType.REVIEW,
  TargetWidgetMainImageType.EMPTY
];

export default TargetWidgetMainImageType;
