<template>
  <div
    v-if="!!message || !!info"
    :class="['tooltip', 'AppTooltip', { 'AppTooltip--down': down }]"
    :style="{ left, top }"
  >
    <div class="tooltip__message">{{ message }}</div>
    <div class="tooltip__info">{{ info }}</div>
  </div>
</template>

<script>
export default {
  name: 'AppTooltip',
  props: {
    message: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    },
    left: {
      type: String,
      default: '0'
    },
    top: {
      type: String,
      default: '0'
    },
    down: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss" scoped>
.AppTooltip {
  position: absolute;
  white-space: nowrap;
  text-align: center;
  transform: translate3d(-50%, -100%, 0);
  margin-top: -2px;
}

.AppTooltip--down {
  transform: translate3d(-50%, 0, 0);
  margin-top: 2px;
}
</style>
