<template>
  <embed :src="pdfUrl" type="application/pdf" :width="width" :height="height" />
</template>

<script>
export default {
  name: 'AppEmbedPdf',
  props: {
    url: { type: String, required: true },
    width: { type: Number, required: true },
    height: { type: Number, required: true }
  },
  computed: {
    pdfUrl() {
      return `${this.url}#toolbar=0&navpanes=1`;
    }
  }
};
</script>
