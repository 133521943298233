<template>
  <button
    type="button"
    class="AppMultipleSelection"
    :disabled="disabled"
    @click="click($event)"
  >
    <ul
      :class="[
        'AppMultipleSelection__choices',
        {
          'AppMultipleSelection__choices--disabled': disabled,
          'AppMultipleSelection__choices--invalid': invalid
        }
      ]"
    >
      <li
        v-for="option in options"
        :key="option.value"
        class="AppMultipleSelection__choice"
        @click.stop
      >
        <AppSelection
          v-tooltip="option.tooltip"
          :label="option.label"
          :disabled="option.disabled"
          :checked="option.checked"
          @check="$emit('check', option)"
          @remove="$emit('remove', option)"
        />
      </li>
    </ul>
    <div v-if="!options.length" class="AppMultipleSelection__placeholder">
      {{ placeholder }}
    </div>
    <template v-if="$scopedSlots.modeless">
      <div class="js-app-modeless">
        <slot name="modeless" />
      </div>
    </template>
  </button>
</template>

<script>
export default {
  name: 'AppMultipleSelection',
  props: {
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click(e) {
      if (!e.target.closest('.js-app-modeless')) this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppMultipleSelection {
  position: relative;
  width: 100%;
  display: block;
}

.AppMultipleSelection__choices {
  @include input-base;
  padding: 0 11px 4px;
  min-height: 32px;
  text-align: left;
}

.AppMultipleSelection__choice {
  display: inline-block;
  margin-right: 5px;
  margin-top: 4px;

  &:last-child {
    margin-right: 0;
  }
}

.AppMultipleSelection__placeholder {
  @include text-placeholder;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 12px;
  line-height: 32px;
  pointer-events: none;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
