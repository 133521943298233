<template>
  <div class="SummaryBoxPreparingPayment">
    <AppSummaryBox>
      <template #title>
        {{ $t('title') }}
      </template>
      {{ $t('content', { brand_name: currentBrand.name }) }}
    </AppSummaryBox>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SummaryBoxPreparingPayment',
  computed: {
    ...mapState('session', ['currentBrand'])
  }
};
</script>

<i18n locale="ko">
{
  "title": "결제 상품을 생성 중입니다. 💳",
  "content": "{brand_name}에 맞는 결제 상품을 생성 중이며, 영업일 기준 최대 2일이 소요될 수 있습니다."
}
</i18n>
