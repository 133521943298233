<template>
  <AppModalForm
    :title="$t('title')"
    width="auto"
    class="ReviewSettingsOptionTypeDialog"
    :form-props="formProps"
    :is-loading="isLoading"
    v-on="formEvents"
  >
    <template #searchbar>
      <li>
        <AppButton
          button-style="red"
          :label="$t('add_new_option_type')"
          @click="addReviewOptionType"
        />
      </li>
    </template>
    <template #group="{ errors }">
      <AppTable
        slot="group"
        table-id="review-settings-option-type-table"
        :columns="COLUMNS"
        :rows="rows"
        :no-data="$t('app.no_data')"
      >
        <template #cell="{ row, column, rowIndex }">
          <template v-if="column.id === 'name'">
            <ul>
              <li class="table-line">
                <input
                  type="hidden"
                  :value="row.id"
                  name="review_option_types[][id]"
                />
                <input
                  type="hidden"
                  :value="row.position"
                  name="review_option_types[][position]"
                />
                <AppSelect
                  v-model="rows[rowIndex].global_review_option_type_id"
                  name="review_option_types[][global_review_option_type_id]"
                  :options="
                    globalReviewOptionTypes.map(t => ({
                      label: t.name,
                      value: t.id
                    }))
                  "
                  :placeholder="$t('user_property_types.select_type')"
                  @change="userPropertyTypeChanged(rowIndex)"
                />
              </li>
              <li class="table-line--mt4">
                <AppTextInput
                  :ref="`textInput${rowIndex}`"
                  v-model="rows[rowIndex].name"
                  :invalid="!!errors[`option_types[${row.id}][name]`]"
                  name="review_option_types[][name]"
                  :placeholder="$t('user_property_types.name')"
                  @blur="validateField(`option_types[${row.id}][name]`)"
                  @change="validateField(`option_types[${row.id}][name]`)"
                />
                <AppFormError
                  :error="errors[`option_types[${row.id}][name]`]"
                />
              </li>
            </ul>
          </template>
          <template v-else-if="column.id === 'search_options_with_categories'">
            <ul>
              <li class="table-line">
                <div
                  class="ReviewSettingsOptionTypeDialog__search-options-with-categories"
                >
                  <AppSelect
                    v-model="rows[rowIndex].field_type"
                    name="review_option_types[][field_type]"
                    :disabled="!!row.global_review_option_type_id"
                    class="ReviewSettingsOptionTypeDialog__search-options-with-categories-left"
                    :options="
                      ['text', 'number', 'select'].map(value => ({
                        label: $t(`review_option_field_type.${value}`),
                        value
                      }))
                    "
                  />
                  <div
                    class="ReviewSettingsOptionTypeDialog__search-options-with-categories-right"
                  >
                    <template v-if="rows[rowIndex].field_type === 'number'">
                      <i18n path="search_range_in_number">
                        <AppNumberInput
                          slot="min"
                          v-model="rows[rowIndex].min"
                          name="review_option_types[][min]"
                          :invalid="!!errors[`option_types[${row.id}][min]`]"
                          :digits="3"
                          @blur="validateMinMax(row.id)"
                          @change="validateMinMax(row.id)"
                        />
                        <AppNumberInput
                          slot="max"
                          v-model="rows[rowIndex].max"
                          name="review_option_types[][max]"
                          :invalid="!!errors[`option_types[${row.id}][max]`]"
                          :digits="3"
                          @blur="validateMinMax(row.id)"
                          @change="validateMinMax(row.id)"
                        />
                        <AppNumberInput
                          slot="interval"
                          v-model="rows[rowIndex].interval"
                          name="review_option_types[][interval]"
                          :invalid="
                            !!errors[`option_types[${row.id}][interval]`]
                          "
                          :digits="2"
                          @blur="
                            validateField(`option_types[${row.id}][interval]`)
                          "
                          @change="
                            validateField(`option_types[${row.id}][interval]`)
                          "
                        />
                        <AppNumberInput
                          slot="display_interval"
                          v-model="rows[rowIndex].display_interval"
                          name="review_option_types[][display_interval]"
                          :invalid="
                            !!errors[
                              `option_types[${row.id}][display_interval]`
                            ]
                          "
                          :digits="2"
                          @blur="
                            validateField(
                              `option_types[${row.id}][display_interval]`
                            )
                          "
                          @change="
                            validateField(
                              `option_types[${row.id}][display_interval]`
                            )
                          "
                        />
                      </i18n>
                      <AppFormError
                        :error="
                          errors[`option_types[${row.id}][min]`] ||
                            errors[`option_types[${row.id}][max]`] ||
                            errors[`option_types[${row.id}][interval]`] ||
                            errors[`option_types[${row.id}][display_interval]`]
                        "
                      />
                    </template>
                    <template
                      v-else-if="rows[rowIndex].field_type === 'select'"
                    >
                      <AppMultipleSelect
                        v-if="orgOptionValues(rowIndex)"
                        v-model="rows[rowIndex].option_values"
                        :options="orgOptionValues(rowIndex)"
                        name="review_option_types[][option_values][]"
                        @change="validateOptionValues(rowIndex)"
                      />
                      <AppTags
                        v-else
                        v-model="rows[rowIndex].option_values"
                        :placeholder="$t('option_values_placeholder')"
                        name="review_option_types[][option_values][]"
                      />
                    </template>
                  </div>
                </div>
              </li>
              <li class="table-line--mt4">
                <div
                  class="ReviewSettingsOptionTypeDialog__search-options-with-categories"
                >
                  <AppSelect
                    v-model="rows[rowIndex].category_inclusion"
                    name="review_option_types[][category_inclusion]"
                    class="ReviewSettingsOptionTypeDialog__search-options-with-categories-left"
                    :options="[
                      {
                        label: $t('review_option_types.category_inclusion'),
                        value: true
                      },
                      {
                        label: $t('review_option_types.category_exclusion'),
                        value: false
                      }
                    ]"
                  />
                  <div
                    class="ReviewSettingsOptionTypeDialog__search-options-with-categories-right"
                  >
                    <AppSelectProductCategory
                      v-model="rows[rowIndex].display_category_ids"
                      name="review_option_types[][display_category_ids][]"
                      multiple
                    />
                  </div>
                </div>
              </li>
            </ul>
          </template>
          <template v-else-if="column.id === 'unit'">
            <AppTextInput
              v-model="rows[rowIndex].unit"
              name="review_option_types[][unit]"
              :disabled="row.field_type === 'text'"
            />
          </template>
          <template v-else-if="column.id === 'storable'">
            <AppCheckbox
              v-model="rows[rowIndex].storable"
              name="review_option_types[][storable]"
              input-style="standalone"
            />
          </template>
          <template v-else-if="column.id === 'searchable'">
            <AppCheckbox
              v-model="rows[rowIndex].searchable"
              name="review_option_types[][searchable]"
              input-style="standalone"
            />
          </template>
          <template v-else-if="column.id === 'displayable'">
            <AppCheckbox
              v-model="rows[rowIndex].displayable"
              name="review_option_types[][displayable]"
              input-style="standalone"
            />
          </template>
          <template v-else-if="column.id === 'required'">
            <AppCheckbox
              v-model="rows[rowIndex].required"
              name="review_option_types[][required]"
              input-style="standalone"
            />
          </template>
          <template v-else-if="column.id === 'actions'">
            <AppButtonDraggable :drag-text="row.name" @sort="sort" />
            <AppButton
              button-style="red-outline"
              :label="$t('app.delete')"
              @click="deleteReviewOptionType(rowIndex)"
            />
          </template>
        </template>
      </AppTable>
      <input
        v-if="reviewOptionTypes.length === 0"
        type="hidden"
        name="review_option_types[]"
      />
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import { arrayMoveIndex } from '@/lib/array';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewSettingsOptionTypeDialog',
  mixins: [DialogFormView],
  data() {
    return {
      COLUMNS: [
        {
          id: 'name',
          label: this.$t('review_option_types.name'),
          align: 'left'
        },
        {
          id: 'search_options_with_categories',
          label: this.$t('review_option_types.search_options_with_categories'),
          align: 'left'
        },
        {
          id: 'unit',
          label: this.$t('review_option_types.unit')
        },
        {
          id: 'storable',
          label: this.$t('review_option_types.storable')
        },
        {
          id: 'searchable',
          label: this.$t('review_option_types.searchable')
        },
        {
          id: 'displayable',
          label: this.$t('review_option_types.displayable')
        },
        {
          id: 'required',
          label: this.$t('review_option_types.required')
        },
        {
          id: 'actions',
          label: this.$t('app.actions')
        }
      ],
      isLoading: true,
      globalReviewOptionTypes: []
    };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'option_types',
              label: null,
              fields: _.flatten(
                this.reviewOptionTypes.map(optionType => [
                  {
                    id: `option_types[${optionType.id}][name]`,
                    value: () => optionType.name,
                    validate: ['required']
                  },
                  ...(optionType.field_type === 'number'
                    ? ['min', 'max', 'interval', 'display_interval'].map(
                        field => ({
                          id: `option_types[${optionType.id}][${field}]`,
                          value: () => optionType[field],
                          validate: [
                            'required',
                            field === 'display_interval'
                              ? {
                                  rule: 'gte',
                                  allowed: 0
                                }
                              : 'positive_integer',
                            field === 'max'
                              ? {
                                  rule: 'gt',
                                  allowed: optionType.min
                                }
                              : null
                          ].filter(v => v)
                        })
                      )
                    : [])
                ])
              )
            }
          ]
        }
      ];
    },
    rows() {
      return this.reviewOptionTypes;
    },
    reviewOptionTypes() {
      return this.formObject.review_option_types || [];
    }
  },
  mounted() {
    this.isLoading = true;
    api
      .get('/review/option_types')
      .then(({ data }) => {
        this.orgFormObject = { review_option_types: data.review_option_types };
        this.globalReviewOptionTypes = data.global_review_option_types;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    userPropertyType(rowIndex) {
      const optionType = this.rows[rowIndex];
      if (!optionType.global_review_option_type_id) return null;

      return this.globalReviewOptionTypes.find(
        t => t.id === optionType.global_review_option_type_id
      );
    },
    orgOptionValues(rowIndex) {
      const userPropertyType = this.userPropertyType(rowIndex);
      if (
        userPropertyType &&
        Object.keys(userPropertyType.option_values).length
      ) {
        return userPropertyType.option_values;
      }
      return null;
    },
    userPropertyTypeChanged(rowIndex) {
      const userPropertyType = this.userPropertyType(rowIndex);
      if (!userPropertyType) return;
      this.$set(this.reviewOptionTypes, rowIndex, {
        ...this.rows[rowIndex],
        ..._.pick(
          userPropertyType,
          'field_type',
          'unit',
          'min',
          'max',
          'interval',
          'display_interval',
          'storable'
        )
      });
      if (userPropertyType.field_type === 'select') {
        this.rows[rowIndex].option_values = _.map(
          this.orgOptionValues(rowIndex),
          o => o.value
        );
      }
    },
    addReviewOptionType() {
      this.reviewOptionTypes.push({
        id: _.uniqueId('option_id'),
        field_type: 'text',
        category_inclusion: true,
        position: this.reviewOptionTypes.length
      });
      this.$nextTick(() =>
        this.$refs[`textInput${this.rows.length - 1}`].$el.focus()
      );
    },
    deleteReviewOptionType(rowIndex) {
      this.reviewOptionTypes.splice(rowIndex, 1);
    },
    sort({ srcIndex, dstIndex }) {
      this.formObject.review_option_types = arrayMoveIndex(
        this.reviewOptionTypes,
        srcIndex,
        dstIndex
      ).map((t, i) => ({ ...t, position: i + 1 }));
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/review/option_types', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.eventBus.$emit('change', data.review_option_types);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    validateOptionValues(rowIndex) {
      const orgOptionValues = this.orgOptionValues(rowIndex);
      if (!orgOptionValues) return;
      this.rows[rowIndex].option_values = this.rows[
        rowIndex
      ].option_values.filter(option_value =>
        orgOptionValues.find(
          orgOptionValue => orgOptionValue.label === option_value
        )
      );
    },
    validateMinMax(id) {
      this.validateField(`option_types[${id}][min]`);
      this.validateField(`option_types[${id}][max]`);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_breakpoints.scss';

$dialog-min-width: 1238px;
$dialog-padding: 23px;

::v-deep .AppModal__main {
  min-width: $dialog-min-width;
  max-width: calc(100vw - #{$dialog-padding * 2});

  @media (max-width: $dialog-min-width) {
    min-width: 100vw;
    max-width: 100vw;
  }

  @media (min-width: $dialog-min-width - 1) and (max-width: $dialog-min-width + $dialog-padding * 2) {
    min-width: $dialog-min-width;
    max-width: $dialog-min-width;
  }
}

.ReviewSettingsOptionTypeDialog__search-options-with-categories {
  display: flex;
  align-items: flex-start;
  min-width: 500px;
}

.ReviewSettingsOptionTypeDialog__search-options-with-categories-left {
  flex: 0 0 120px;
}

.ReviewSettingsOptionTypeDialog__search-options-with-categories-right {
  margin-left: 8px;
  flex: auto;
}

::v-deep {
  .review-settings-option-type-table__name {
    min-width: 160px;
  }

  .review-settings-option-type-table__unit,
  .review-settings-option-type-table__storable,
  .review-settings-option-type-table__searchable,
  .review-settings-option-type-table__displayable,
  .review-settings-option-type-table__required {
    min-width: 54px;
    vertical-align: middle;
  }

  .review-settings-option-type-table__actions {
    min-width: 140px;
    vertical-align: middle;
  }
}
</style>

<i18n locale="ko">
{
  "title": "추가 정보 설정하기",
  "add_new_option_type": "항목 추가",
  "user_property_types": {
    "select_type": "항목 선택",
    "name": "표시 정보명"
  },
  "review_option_types" : {
    "category_inclusion" : "적용 카테고리",
    "category_exclusion" : "제외 카테고리"
  },
  "search_range_in_number": "{min} 부터 {max} 까지 {interval} 단위로 검색 {display_interval} 단위로 표시",
  "option_values_placeholder": "선택 항목을 입력해주세요."
}
</i18n>

<i18n locale="en">
{
  "search_range_in_number": "Ranges from {min} to {max} ; interval of search {interval} ; interval of display {display_interval}"
}
</i18n>
