<template>
  <AppTabContent menu-item-id="target_dm_campaigns_situational">
    <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
      <TargetCampaignsSearchbar
        v-bind="searchbarProps"
        v-on="searchbarEvents"
      />
      <TargetCampaignsTable v-bind="tableProps" />
    </AppAjaxContent>
  </AppTabContent>
</template>

<script>
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignsView from './TargetCampaignsView';

export default {
  name: 'TargetDmCampaignsSituational',
  mixins: [TargetCampaignsView],
  computed: {
    campaignCategory: () => 'situational',
    campaignMediaType: () => 'direct_message',
    sortTypes: () => ['created_at', 'edited_at', 'name', 'group_name'],
    searchTypes: () => ['name', 'group_name'],
    columns() {
      return [
        { id: 'campaignInfo' },
        { id: 'effects', label: this.$t('app.effect') },
        { id: 'campaignId' },
        { id: 'editedAt' },
        { id: 'calculateStatus' },
        { id: 'actions' }
      ];
    },
    creatableCampaignTypes: () => TargetCampaignType.creatableSituational
  }
};
</script>
