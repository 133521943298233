<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div class="AppText" v-html="html" />
</template>

<script>
export default {
  name: 'AppText',
  props: { text: { type: String, required: true } },
  computed: {
    html() {
      return this.text
        .replaceAll(/\*\*(.*?)\*\*/g, '<b>$1</b>')
        .replaceAll('\n', '<br>');
    }
  }
};
</script>
