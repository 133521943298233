var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('settings.fit_settings_widgets.groups.floating_settings'),"form-props":Object.assign({}, _vm.formProps, {id: 'floating_settings'}),"width":_vm.modalWidth},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('AppTabs',{attrs:{"tabs":_vm.tabOptions},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}})]},proxy:true},{key:"group",fn:function(ref){
var id = ref.id;
var errors = ref.errors;
return [(id === _vm.floatingOffsetId)?_c('div',_vm._l((['right', 'bottom']),function(position){return _c('div',{key:("offset_" + position),staticClass:"AppForm__group-field"},[_c('i18n',{attrs:{"path":("groups.offset." + position)}},[_c('AppNumberInput',{attrs:{"digits":2,"name":("fit_settings[" + (_vm.floatingOffsetPositionId(position)) + "]")},on:{"change":function($event){_vm.validateField(_vm.floatingOffsetPositionId(position))}},model:{value:(_vm.formObject[_vm.floatingOffsetPositionId(position)]),callback:function ($$v) {_vm.$set(_vm.formObject, _vm.floatingOffsetPositionId(position), $$v)},expression:"formObject[floatingOffsetPositionId(position)]"}})],1),_c('AppFormError',{attrs:{"error":errors[_vm.floatingOffsetPositionId(position)]}})],1)}),0):(id === _vm.floatingShapeId)?_c('div',[_c('AppSelectRadio',{staticClass:"AppForm__group-field",attrs:{"options":_vm.FLOATING_SHAPE_OPTIONS,"name":("fit_settings[" + _vm.floatingShapeId + "]")},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
var value = ref.value;
var checked = ref.checked;
return [(value === _vm.FitFloatingWidgetShape.SQUARE)?_c('div',{staticClass:"AppForm__sub-group"},[_c('div',{staticClass:"AppForm__sub-group-field"},[_c('i18n',{attrs:{"path":"groups.shape.radius"}},[_c('AppNumberInput',{attrs:{"name":("fit_settings[" + _vm.floatingShapeRadiusId + "]"),"digits":2,"disabled":!checked},on:{"change":function($event){return _vm.validateField(_vm.floatingShapeRadiusId)}},model:{value:(_vm.formObject[_vm.floatingShapeRadiusId]),callback:function ($$v) {_vm.$set(_vm.formObject, _vm.floatingShapeRadiusId, $$v)},expression:"formObject[floatingShapeRadiusId]"}})],1),_c('AppFormError',{attrs:{"error":errors[_vm.floatingShapeRadiusId]}})],1)]):_vm._e()]}}],null,true),model:{value:(_vm.formObject[_vm.floatingShapeId]),callback:function ($$v) {_vm.$set(_vm.formObject, _vm.floatingShapeId, $$v)},expression:"formObject[floatingShapeId]"}})],1):(id === _vm.floatingIconTypeId)?_c('div',[_c('AppSelectRadio',{staticClass:"AppForm__group-field",attrs:{"options":_vm.FLOATING_ICON_TYPE_OPTIONS,"name":("fit_settings[" + _vm.floatingIconTypeId + "]")},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
var value = ref.value;
var checked = ref.checked;
return [(value === _vm.FitFloatingIconType.CUSTOM)?_c('div',[_c('div',{staticClass:"AppForm__sub-group-field"},[_c('AppTextInput',{attrs:{"name":("fit_settings[" + _vm.floatingIconUrlId + "]"),"disabled":!checked,"placeholder":_vm.$t('groups.icon_type.placeholder')},on:{"change":function($event){return _vm.validateField(_vm.floatingIconUrlId)}},model:{value:(_vm.formObject[_vm.floatingIconUrlId]),callback:function ($$v) {_vm.$set(_vm.formObject, _vm.floatingIconUrlId, $$v)},expression:"formObject[floatingIconUrlId]"}}),_c('AppFormHint',{attrs:{"message":_vm.$t('groups.icon_type.hint'),"disabled":!checked}}),_c('AppFormError',{attrs:{"error":errors[_vm.floatingIconUrlId]}})],1)]):_vm._e()]}}],null,true),model:{value:(_vm.formObject[_vm.floatingIconTypeId]),callback:function ($$v) {_vm.$set(_vm.formObject, _vm.floatingIconTypeId, $$v)},expression:"formObject[floatingIconTypeId]"}})],1):_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }