<template>
  <AppForm
    class="KakaoStyleApiKeyForm"
    :object-id="requiresAgreement ? 'agreement' : 'api_keys'"
    v-bind="formProps"
    :submit-button="submitButtonProps"
    :disabled="isDisabled"
    v-on="formEvents"
  />
</template>

<script>
import api from '@/lib/api';
import KakaoStyleReviewImportStatus from '@/enums/KakaoStyleReviewImportStatus';
import KakaoStyleReviewErrorCode from '@/enums/KakaoStyleReviewErrorCode';
import FormView from '@/mixins/FormView';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'KakaoStyleApiKeyForm',
  mixins: [FormView],
  props: {
    accessKey: { type: String, default: '' },
    secretKey: { type: String, default: '' }
  },
  data: () => ({
    isApiKeyValid: true,
    errorMessage: null
  }),
  computed: {
    ...mapState('kakaoStyle', ['reviewImportStatus']),
    isDisabled() {
      return this.reviewImportStatus === KakaoStyleReviewImportStatus.DISABLED;
    },
    requiresAgreement() {
      return (
        this.reviewImportStatus === KakaoStyleReviewImportStatus.NOT_REQUESTED
      );
    },
    submitButtonProps() {
      return this.requiresAgreement
        ? {
            submitLabel: this.$t('submit'),
            disabled: !this.formObject.accepted_terms
          }
        : { submitLabel: this.$t('app.save') };
    },
    formSections() {
      return [
        {
          id: 'basic',
          groups: [
            {
              id: 'access_key',
              type: 'text',
              label: this.$t('access_key.label'),
              placeholder: this.$t('access_key.placeholder'),
              groupDescription: {
                message: this.$t('access_key.group_description'),
                mode: 'markdown'
              },
              validate: [
                'required',
                {
                  rule: () => this.isApiKeyValid,
                  errorMessage: this.errorMessage
                }
              ]
            },
            {
              id: 'secret_key',
              type: 'text',
              label: this.$t('secret_key.label'),
              placeholder: this.$t('secret_key.placeholder'),
              groupDescription: {
                message: this.$t('secret_key.group_description'),
                mode: 'markdown'
              },
              validate: [
                'required',
                {
                  rule: () => this.isApiKeyValid,
                  errorMessage: this.errorMessage
                }
              ]
            },
            this.requiresAgreement
              ? {
                  id: 'accepted_terms',
                  type: 'agreement_checkbox',
                  label: this.$t('accepted_terms.label'),
                  termsLink:
                    'https://assets.cre.ma/m/crema-kakao-style-review-sync-agreement.pdf'
                }
              : null
          ].filter(v => v)
        }
      ];
    }
  },
  watch: {
    isDisabled(value) {
      if (value) this.formObject = { ...this.orgFormObject };
    },
    'formObject.access_key'() {
      this.isApiKeyValid = true;
      this.errorMessage = null;
      this.validateField('secret_key');
    },
    'formObject.secret_key'() {
      this.isApiKeyValid = true;
      this.errorMessage = null;
      this.validateField('access_key');
    }
  },
  created() {
    this.orgFormObject = {
      access_key: this.accessKey,
      secret_key: this.secretKey,
      ...(this.requiresAgreement ? { accepted_terms: false } : {})
    };
    this.formObject = { ...this.orgFormObject };
  },
  methods: {
    ...mapMutations('kakaoStyle', ['SET_REVIEW_IMPORT_STATUS']),
    submit(formData) {
      this.isSubmitting = true;
      this.validateApiKey()
        .then(() => {
          const promise = this.requiresAgreement
            ? this.createAgreement(formData)
            : this.updateApiKeys(formData);
          promise.finally(() => (this.isSubmitting = false));
        })
        .catch(reason => {
          this.isApiKeyValid = false;
          this.errorMessage = KakaoStyleReviewErrorCode.t(reason);
          this.validateField('access_key');
          this.validateField('secret_key');
        })
        .finally(() => (this.isSubmitting = false));
    },
    createAgreement(formData) {
      return api
        .post('/kakao_style/agreements', formData)
        .then(() =>
          this.SET_REVIEW_IMPORT_STATUS(KakaoStyleReviewImportStatus.ENABLED)
        );
    },
    updateApiKeys(formData) {
      return api
        .patch('/kakao_style/api_keys', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => (this.orgFormObject = { ...this.formObject }));
    },
    validateApiKey() {
      const access_key = this.formObject.access_key;
      const secret_key = this.formObject.secret_key;
      return new Promise(function(resolve, reject) {
        api
          .post(
            '/kakao_style/api_keys/validate',
            { access_key, secret_key },
            {
              silent: true
            }
          )
          .then(({ data }) => {
            if (data.valid) {
              resolve();
            } else {
              reject(data.reason);
            }
          })
          .catch(reject);
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "access_key": {
    "label": "카카오스타일 API Key : 인증키 (Access Key)",
    "group_description": "카카오스타일 API Key 발급은 [\\[카카오스타일: 크리마 리뷰 연동 서비스 안내\\] 문서](https://crema.notion.site/1b4d3d91a83146a7aa4997f57e2813c6)를 참고해주세요.",
    "placeholder": "카카오스타일 파트너센터에서 발급받은 인증키를 입력해주세요."
  },
  "secret_key": {
    "label": "카카오스타일 API Key : 시크릿키 (Secret Key)",
    "group_description": "카카오스타일 API Key 발급은 [\\[카카오스타일: 크리마 리뷰 연동 서비스 안내\\] 문서](https://crema.notion.site/1b4d3d91a83146a7aa4997f57e2813c6)를 참고해주세요.",
    "placeholder": "카카오스타일 파트너센터에서 발급받은 시크릿키를 입력해주세요."
  },
  "accepted_terms": {
    "label": "[필수] 카카오 스타일 리뷰 연동 서비스에 동의합니다."
  },
  "submit": "카카오스타일 리뷰 연동 신청"
}
</i18n>
