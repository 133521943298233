<template>
  <div class="AppCalendar">
    <button
      v-if="$listeners['click-prev-month']"
      type="button"
      class="AppCalendar__arrow-button AppCalendar__arrow-button--prev"
      @click="$emit('click-prev-month')"
    >
      <i class="AppCalendar__arrow-icon AppCalendar__arrow-icon--prev" />
    </button>
    <button
      v-if="$listeners['click-next-month']"
      type="button"
      class="AppCalendar__arrow-button AppCalendar__arrow-button--next"
      @click="$emit('click-next-month')"
    >
      <i class="AppCalendar__arrow-icon AppCalendar__arrow-icon--next" />
    </button>
    <table>
      <thead>
        <tr>
          <th colspan="7" class="month">
            {{ $d(monthDate, 'year_month') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th v-for="weekDay in $t('wdays')" :key="weekDay">
            {{ weekDay }}
          </th>
        </tr>
        <tr v-for="(dateRow, index) in calendar" :key="index">
          <td
            v-for="(date, idx) in dateRow"
            :key="idx"
            :class="dayClass(date)"
            @click="$emit('date-click', new Date(date))"
            @mouseover="$emit('hover-date', new Date(date))"
          >
            {{ date | dateNum }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AppCalendar',
  filters: {
    dateNum(value) {
      return value.date();
    }
  },
  props: {
    monthDate: { type: Date, default: null },
    start: { type: Date, default: null },
    end: { type: Date, default: null },
    minDate: { type: Date, default: null },
    maxDate: { type: Date, default: null }
  },
  computed: {
    month() {
      return this.monthDate.getMonth();
    },
    calendar() {
      let month = this.month;
      let year = this.monthDate.getFullYear();
      let firstDay = new Date(year, month, 1);
      let lastMonth = moment(firstDay)
        .subtract(1, 'month')
        .month();
      let lastYear = moment(firstDay)
        .subtract(1, 'month')
        .year();
      let daysInLastMonth = moment([lastYear, lastMonth]).daysInMonth();

      let dayOfWeek = firstDay.getDay();

      let calendar = [];

      for (let i = 0; i < 6; i++) {
        calendar[i] = [];
      }

      let startDay = daysInLastMonth - dayOfWeek + 1;
      if (startDay > daysInLastMonth) startDay -= 7;

      if (dayOfWeek === 0) startDay = daysInLastMonth - 6;

      let curDate = moment([lastYear, lastMonth, startDay, 0, 0, 0]);
      for (
        let i = 0, col = 0, row = 0;
        i < 6 * 7;
        i++, col++, curDate = moment(curDate).add(1, 'day')
      ) {
        if (i > 0 && col % 7 === 0) {
          col = 0;
          row++;
        }
        calendar[row][col] = curDate.clone();
        curDate.hour(0);
      }

      return calendar;
    }
  },
  methods: {
    dayClass(date) {
      let dt = new Date(date);
      dt.setHours(0, 0, 0, 0);
      let start = new Date(this.start);
      start.setHours(0, 0, 0, 0);
      let end = new Date(this.end);
      end.setHours(0, 0, 0, 0);

      return {
        off: date.month() !== this.month,
        today: dt.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0),
        active:
          dt.setHours(0, 0, 0, 0) ==
            new Date(this.start).setHours(0, 0, 0, 0) ||
          dt.setHours(0, 0, 0, 0) == new Date(this.end).setHours(0, 0, 0, 0),
        disabled:
          (!!this.minDate &&
            moment(dt).isBefore(moment(this.minDate), 'day')) ||
          (!!this.maxDate && moment(dt).isAfter(moment(this.maxDate), 'day')),
        'in-range': dt >= start && dt <= end,
        'start-date': dt.getTime() === start.getTime(),
        'end-date': dt.getTime() === end.getTime()
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppCalendar {
  padding: 9px;
  position: relative;
}

.AppCalendar__arrow-button {
  position: absolute;
  top: 8px;
  width: 34px;
  height: 34px;
  line-height: 34px;

  &--prev {
    left: 8px;
  }

  &--next {
    right: 8px;
  }
}

.AppCalendar__arrow-icon {
  border: solid $color-grey-80;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  width: 8px;
  height: 8px;

  &--prev {
    transform: rotate(135deg);
  }

  &--next {
    transform: rotate(-45deg);
  }
}

th,
td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
  font-size: 11px;
  min-width: 34px;
  width: 34px;
  height: 34px;
}

td {
  color: $color-grey-60;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: $color-grey-15;
  }
}

th {
  color: $color-grey-80;
  font-weight: bold;
}

table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

td.today {
  font-weight: bold;
  color: $color-blue;
}

td.off,
td.off.in-range,
td.off.start-date,
td.off.end-date {
  color: $color-grey-35;
  background-color: inherit;

  &:hover {
    background-color: $color-grey-15;
  }
}

td.in-range {
  background-color: $color-primary-lighter;
  color: $color-grey-70;
  border-radius: 0;
}

td.start-date {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

td.end-date {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

td.active {
  background-color: $color-primary;
  color: white;
}

td.disabled {
  pointer-events: none;
  color: $color-grey-35;
  background-color: inherit;
}
</style>

<i18n locale="ko">
{
  "wdays": ["일", "월", "화", "수", "목", "금", "토"]
}
</i18n>

<i18n locale="en">
{
  "wdays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
}
</i18n>
