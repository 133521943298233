<template>
  <AppGrid>
    <div class="AppGrid__col AppGrid__col--6">
      <Component
        :is="
          campaign.campaign_type === TargetCampaignType.ALL_MEMBERS
            ? 'AppDataSection'
            : 'TargetSettingsSection'
        "
        :title="$t('recipients', [dispatchText])"
        @open-settings="openDispatchRecipientsDialog"
      >
        <div
          v-tooltip="dispatchRecipientsTextTooltip"
          class="TargetCampaignSettingsDispatch__recipients-text"
        >
          {{ dispatchRecipientsText }}
          <span v-if="isUtmPopupCampaign && campaign.utm_term">
            <strong>{{ campaign.utm_term }}</strong>
          </span>
        </div>
        <div
          v-if="dispatchRecipientsDescription"
          class="TargetCampaignSettingsDispatch__recipients-description"
        >
          {{ dispatchRecipientsDescription }}
        </div>
      </Component>
    </div>
    <div class="AppGrid__col AppGrid__col--6">
      <TargetSettingsSection
        :title="$t('schedule', [dispatchText])"
        @open-settings="openDispatchDateTimeDialog"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="dispatchScheduleHtml" />
      </TargetSettingsSection>
    </div>
  </AppGrid>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetSettingsSection from './components/TargetSettingsSection';
import Weekday from '@/enums/Weekday';

export default {
  name: 'TargetCampaignSettingsDispatch',
  components: { TargetSettingsSection },
  data() {
    return {
      dispatchRecipientsTextTooltip: null
    };
  },
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    ...mapGetters('targetCampaign', [
      'isOneTimeCampaign',
      'isPopupCampaign',
      'isUtmPopupCampaign'
    ]),
    ...mapGetters('targetCampaigns', ['campaignText']),
    TargetCampaignType: () => TargetCampaignType,
    dispatchText() {
      return this.campaignText(this.campaign, 'dispatch');
    },
    dispatchRecipientsText() {
      if (!this.campaign.configured_dispatch_recipients)
        return this.$t('dispatch_recipients_unconfigured', [this.dispatchText]);

      return this.campaignText(
        this.campaign,
        'recipients',
        this.dispatchRecipientsTextArgs
      );
    },
    dispatchRecipientsDescription() {
      return this.isUtmPopupCampaign && this.campaign.utm_popup_check_brand_user
        ? this.$t(
            `utm_popup_brand_user_only.${this.campaign.utm_popup_brand_user_only}`
          )
        : null;
    },
    dispatchRecipientsTextArgs() {
      const { campaign } = this;
      const { campaign_type } = campaign;
      switch (campaign_type) {
        case TargetCampaignType.NEW_MEMBER_REENGAGEMENT:
          return [campaign.signup_filter.days_since_signup];
        case TargetCampaignType.CART_REMINDING:
          return [
            campaign.cart_filter.recent_days_since,
            campaign.cart_filter.recent_days_until
          ];
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
          return [
            campaign.unpaid_order_filter.max_elapsed_days,
            campaign.unpaid_order_filter.min_elapsed_days
          ];
        case TargetCampaignType.MILEAGE_EXPIRING:
          return [
            campaign.mileage_expiring_filter.days_before_expiration_since,
            campaign.mileage_expiring_filter.days_before_expiration_until
          ];
        case TargetCampaignType.COUPON_EXPIRY:
          return [campaign.coupon_expiry_filter.days_before_expiration];
        case TargetCampaignType.INDUCING_REPURCHASE:
          return [
            campaign.order_filter.recent_days_since,
            campaign.order_filter.recent_days_until,
            campaign.order_filter.sub_orders_count,
            campaign.order_filter.recent_days_until - 1,
            campaign.product_click_filter.recent_days_since,
            campaign.product_click_filter.recent_days_until,
            campaign.product_click_filter.clicks_count
          ];
        case TargetCampaignType.INTERESTED_PRODUCTS:
          return [
            campaign.repetitive_product_click_filter.recent_days_since,
            campaign.repetitive_product_click_filter.recent_days_until,
            campaign.repetitive_product_click_filter.clicks_count
          ];
        case TargetCampaignType.SELECTED_PRODUCTS:
        case TargetCampaignType.SELECTED_CATEGORIES:
          return [campaign.product_click_filter.clicks_count];
        case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
          return [campaign.member_inactivation_filter.days_before_inactivation];
        case TargetCampaignType.ACTIVE_MEMBERS:
          return [campaign.active_members_filter.active_since_days_ago];
        case TargetCampaignType.INACTIVE_MEMBERS:
          return [campaign.inactive_members_filter.inactive_since_days_ago];
        default:
          return [];
      }
    },
    dispatchHourText() {
      const hour = this.campaign.dispatch_hour;
      return hour < 12
        ? this.$t('morning', [hour === 0 ? 12 : hour])
        : this.$t('afternoon', [hour === 12 ? 12 : hour - 12]);
    },
    dispatchScheduleOneTimeHtml() {
      return this.$t('dispatch_schedule_one_time_html', [
        this.formatDate(this.campaign.dispatch_date),
        this.dispatchHourText,
        this.dispatchText
      ]);
    },
    dispatchScheduleRecurringHtml() {
      const weekdaysText = Weekday.fromBitFlag(
        this.campaign.dispatch_weekdays_value
      )
        .map(Weekday.t)
        .join(', ');

      return this.$t('dispatch_schedule_recurring_html', [
        weekdaysText,
        this.dispatchHourText,
        this.dispatchText
      ]);
    },
    popupDispatchScheduleHtml() {
      const weekdaysText = Weekday.fromBitFlag(
        this.campaign.dispatch_weekdays_value
      )
        .map(Weekday.t)
        .join(', ');

      return this.$t('dispatch_schedule_recurring_popup_html', [
        weekdaysText,
        this.dispatchHourText,
        this.campaign.popup_duration_in_hours
      ]);
    },
    dispatchScheduleRealtimeHtml() {
      const weekdaysText = Weekday.fromBitFlag(
        this.campaign.dispatch_weekdays_value
      )
        .map(Weekday.t)
        .join(', ');

      return this.$t('dispatch_schedule_realtime_html', [
        weekdaysText,
        this.dispatchText
      ]);
    },
    dispatchScheduleHtml() {
      if (!this.campaign.configured_dispatch_schedule)
        return this.$t('dispatch_schedule_unconfigured', [this.dispatchText]);

      return this.isOneTimeCampaign
        ? this.dispatchScheduleOneTimeHtml
        : this.campaign.campaign_type === TargetCampaignType.NEW_VISITOR_SIGNUP
        ? this.dispatchScheduleRealtimeHtml
        : this.isPopupCampaign
        ? this.popupDispatchScheduleHtml
        : this.dispatchScheduleRecurringHtml;
    }
  },
  watch: {
    'campaign.utm_term'() {
      this.$nextTick(() => {
        this.setDispatchRecipientsTextTooltip();
      });
    }
  },
  mounted() {
    this.setDispatchRecipientsTextTooltip();
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openDispatchRecipientsDialog() {
      this.openDialog([
        'TargetCampaignSettingsDispatchRecipientsDialog',
        { campaign: this.campaign }
      ]);
    },
    openDispatchDateTimeDialog() {
      this.openDialog([
        'TargetCampaignSettingsDispatchDateTimeDialog',
        { campaign: this.campaign }
      ]);
    },
    setDispatchRecipientsTextTooltip() {
      const { scrollWidth, clientWidth } = this.$el.getElementsByClassName(
        'TargetCampaignSettingsDispatch__recipients-text'
      )[0];

      if (scrollWidth > clientWidth) {
        this.dispatchRecipientsTextTooltip = {
          message: this.campaign.utm_term,
          wordBreak: 'break-all'
        };
      } else {
        this.dispatchRecipientsTextTooltip = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignSettingsDispatch__recipients-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.TargetCampaignSettingsDispatch__recipients-description {
  font-weight: bold;
}
</style>

<i18n locale="ko">
{
  "recipients": "{0} 대상",
  "schedule": "{0} 시점",
  "dispatch_recipients_unconfigured": "{0} 대상을 선택해주세요.",
  "dispatch_schedule_unconfigured": "{0} 시점을 선택해주세요.",
  "dispatch_schedule_one_time_html": "<strong>{0} {1}</strong>에 {2} 시작",
  "dispatch_schedule_recurring_html": "매주 <strong>{0} {1}</strong>에 {2} 시작",
  "dispatch_schedule_recurring_popup_html": "매주 <strong>{0} {1}</strong>부터 <strong>{2}시간</strong> 동안 노출",
  "dispatch_schedule_realtime_html": "매주 <strong>{0}</strong>에 대상 회원 발생 시 실시간 노출",
  "morning": "오전 {0}시",
  "afternoon": "오후 {0}시",
  "utm_popup_brand_user_only": {
    "true": "회원에게만 팝업을 노출합니다.",
    "false": "비회원에게만 팝업을 노출합니다."
  }
}
</i18n>
