<template>
  <RouterLink v-slot="{ href, navigate }" :to="dstPath" custom>
    <AppLink
      class="AppInternalLink"
      v-bind="{ label, linkStyle, href, target }"
      @click="navigate"
    >
      <span @click="click"><slot /></span>
    </AppLink>
  </RouterLink>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import globalEventBus from '@/lib/globalEventBus';
import { pathWithParams } from '@/lib/params';

export default {
  name: 'AppInternalLink',
  props: {
    label: { type: String, default: '' },
    to: { type: String, required: true },
    linkStyle: { type: String, default: 'default' },
    target: { type: String, default: null }
  },
  computed: {
    ...mapGetters(['pathWithBrandParams', 'pathWithDateRangeParams']),
    dstPath() {
      if (this.to.includes('analytics/insight'))
        return this.pathWithDateRangeParams(this.to);
      else return this.pathWithBrandParams(this.to);
    },
    srcPath() {
      const { path, query, hash } = this.$route;
      return pathWithParams(path, query, hash.replace('#', ''));
    },
    isSamePath() {
      return this.srcPath === this.dstPath;
    }
  },
  methods: {
    ...mapActions('session', ['redirectToHome']),
    click(e) {
      if (!e.metaKey && this.to === '/') {
        this.redirectToHome();
        e.preventDefault();
      }

      if (this.isSamePath) globalEventBus.$emit('refresh-content');
      if (this.to === '') e.preventDefault();

      this.$emit('click');
    }
  }
};
</script>
