<template>
  <ReviewSettingsWidgetsNormal
    :widget-type="WidgetType.CATEGORY_REVIEWS"
    :widget-summary="widgetSummary"
  />
</template>

<script>
import { mapGetters } from 'vuex';

import ReviewSettingsWidgetsNormal from './ReviewSettingsWidgetsNormal';
import WidgetType from '@/enums/WidgetType';

export default {
  name: 'ReviewSettingsWidgetsCategoryReviews',
  components: { ReviewSettingsWidgetsNormal },
  computed: {
    ...mapGetters('productCategory', ['productCategoryName']),
    WidgetType() {
      return WidgetType;
    }
  },
  methods: {
    widgetSummary(widget) {
      const category_page = this.categoryPage(widget);
      if (widget.tag_name)
        return this.$t(
          `summary.${widget.filter_photo_only ? 'tag_photo' : 'tag'}`,
          {
            category_page,
            tag: widget.tag_name,
            per: widget.posts_per_page
          }
        );
      else
        return this.$t(
          `summary.${widget.filter_photo_only ? 'default_photo' : 'default'}`,
          { category_page, per: widget.posts_per_page }
        );
    },
    categoryPage(widget) {
      const {
        show_widget_on_all_categories,
        widget_inclusion_category_ids,
        enable_widget_exclusion_category_ids,
        widget_exclusion_category_ids
      } = widget;

      if (show_widget_on_all_categories)
        return enable_widget_exclusion_category_ids &&
          widget_exclusion_category_ids.length
          ? this.$t('category_page.exclude', [
              widget_exclusion_category_ids.length
            ])
          : this.$t('category_page.all');
      else
        return enable_widget_exclusion_category_ids &&
          widget_exclusion_category_ids.length
          ? this.$t('category_page.both', [
              widget_inclusion_category_ids.length,
              widget_exclusion_category_ids.length
            ])
          : this.$t('category_page.include', [
              widget_inclusion_category_ids.length
            ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "category_page": {
    "all": "전체 카테고리 페이지",
    "include": "{0}개 카테고리 페이지",
    "exclude": "{0}개를 제외한 나머지 카테고리 페이지",
    "both": "{0}개 카테고리 중 {1}개를 제외한 나머지 카테고리 페이지"
  },
  "summary": {
    "default": "{category_page}에 리뷰를 {per}개씩 보여줍니다.",
    "default_photo": "{category_page}에 포토 리뷰를 {per}개씩 보여줍니다.",
    "tag": "{category_page}에 '{tag}' 태그가 설정된 리뷰를 {per}개씩 보여줍니다.",
    "tag_photo": "{category_page}에 '{tag}' 태그가 설정된 포토 리뷰를 {per}개씩 보여줍니다."
  }
}
</i18n>
