<template>
  <button
    :class="['AppJob', `AppJob--${job.status}`]"
    :style="width ? `width: ${width}px` : ''"
    :disabled="job.status === 'running'"
    @click="$emit('click')"
  >
    <div class="AppJob__title">{{ job.title }}</div>
    <Transition name="fade">
      <div v-if="job.status !== 'running'" class="AppJob__cta">
        {{ $t(`cta.${job.status}`) }}
      </div>
    </Transition>
    <TransitionFadeHeight>
      <AppProgress
        v-if="job.status === 'running'"
        class="AppJob__progress"
        v-bind="job"
        :display-running-time="displayRunningTime"
      />
    </TransitionFadeHeight>
  </button>
</template>

<script>
import TransitionFadeHeight from '@/transitions/TransitionFadeHeight';

export default {
  name: 'AppJob',
  components: { TransitionFadeHeight },
  props: {
    job: { type: Object, required: true },
    displayRunningTime: { type: Boolean, default: false }
  },
  data: () => ({ width: null }),
  mounted() {
    const MINIMUM_TITLE_WIDTH = 221;
    const CTA_BUTTON_WIDTH = 59;

    const titleWidth = Math.max(this.$el.offsetWidth, MINIMUM_TITLE_WIDTH);
    this.width = titleWidth + CTA_BUTTON_WIDTH;
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_dialog.scss';
@import '@/scss/mixins/_texts.scss';

.AppJob {
  @include dialog;

  position: relative;
  padding: 12px;
  text-align: left;
  overflow: hidden;

  &--running {
    cursor: default;
  }
}

.AppJob__title {
  @include text-label;
}

.AppJob__progress::before {
  content: '';
  display: block;
  height: 12px;
}

.AppJob__cta {
  position: absolute;
  top: 12px;
  right: 12px;

  @include text-caption-dark;

  .AppJob:hover & {
    text-decoration: underline;
  }
}
</style>

<i18n locale="ko">
{
  "cta": {
    "pending": "예약 취소",
    "complete": "결과 확인"
  }
}
</i18n>
