import { isUrlFormatValid } from '@/lib/urlFormatValidator';

const urlToPath = urlOrPath => {
  if (isUrlFormatValid(urlOrPath)) {
    const url = new URL(urlOrPath);
    return url.pathname + url.search;
  } else return urlOrPath;
};

export default urlOrPath => {
  const path = urlToPath(urlOrPath);
  // 카페24 스킨값이 포함된 주소의 경우, 스킨값을 적용 스킨 정보로 치환
  // https://app.asana.com/0/75312375888163/1203362048869282/f
  const hit = path.match(/^\/skin-((base|mobile|skin)\d*)/);
  return hit ? [path.replace(hit[0], ''), hit[1]] : [path, null];
};
