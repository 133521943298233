<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="default">Default</label>
      </div>
      <div class="ui-section__item">
        <AppButtonSubmit id="default" />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="is_submitting">Is Submitting</label>
      </div>
      <div class="ui-section__item">
        <AppButtonSubmit id="is_submitting" is-submitting />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="with_error_summary">With Error Summary</label>
      </div>
      <div class="ui-section__item">
        <AppButtonSubmit
          id="with_error_summary"
          error-summary="1 개의 입력 오류가 있습니다."
        />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="danger">Danger</label>
      </div>
      <div class="ui-section__item">
        <AppButtonSubmit id="danger" button-style="red" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiButtonSubmit'
};
</script>
