<template>
  <div class="DisplayCornerItemsContainer">
    <div
      v-show="isShowingDropBar"
      ref="dropBar"
      class="DisplayCornerItemsContainer__drop-bar"
    />
    <DisplayCornerItemsMatrix
      v-bind="previewItemsProps"
      :item-matrix="itemMatrix"
    >
      <template #item="{ item }">
        <DisplayCornerItemWrapper
          v-if="item"
          class="DisplayCornerItemsContainer__item"
          :data-item-index="item.index"
          @drag.native="drag"
          @dragstart.native="dragstart"
          @dragend.native="dragend"
        >
          <slot name="item" :item="item" />
        </DisplayCornerItemWrapper>
      </template>
    </DisplayCornerItemsMatrix>
    <AppPagination
      class="DisplayCornerItemsContainer__pagination"
      :current-page="currentPage"
      :per="previewSettings.preview_products_count_in_page"
      :total-count="itemZones[0].length"
      :enable-route="false"
      @paginate="updateCurrentPage"
    />
    <template v-for="({ matrix, label }, i) in surplusItemMatrices">
      <AppInfoBox
        :key="`infobox-${i}`"
        class="DisplayCornerItemsContainer__info-box"
        :style="`max-width: ${rowWidth}px`"
        >{{ $t(label) }}</AppInfoBox
      >
      <DisplayCornerItemsMatrix
        :key="`surplus-items-${i}`"
        v-bind="previewItemsProps"
        :item-matrix="matrix"
      >
        <template #item="{ item }">
          <DisplayCornerItemWrapper
            v-if="item"
            class="DisplayCornerItemsContainer__item"
            :data-item-index="item.index"
            @drag.native="drag"
            @dragstart.native="dragstart"
            @dragend.native="dragend"
          >
            <slot name="item" :item="item" />
          </DisplayCornerItemWrapper>
        </template>
      </DisplayCornerItemsMatrix>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { getVerticallyScrollableParent } from '@/lib/scroll';
import Scrollable from '@/mixins/Scrollable';
import DisplayCornerItemsMatrix from './DisplayCornerItemsMatrix';

export default {
  name: 'DisplayCornerItemsContainer',
  components: { DisplayCornerItemsMatrix },
  mixins: [Scrollable],
  props: {
    items: { type: Array, required: true },
    itemWidth: { type: Number, required: true },
    isItemWidthFixed: { type: Boolean, default: true },
    isDraggable: { type: Boolean, default: false },
    showSpareItemsSeparately: { type: Boolean, default: true },
    showExceededMaxProductsItemsSeparately: { type: Boolean, default: true }
  },
  data() {
    return {
      srcIndex: null,
      dstIndex: null,
      isShowingDropBar: false,
      currentPage: 1
    };
  },
  computed: {
    ...mapState('displayPreviewSettings', ['previewSettings']),
    ...mapState('displayProductDisplay', ['markedProduct']),
    ...mapGetters('displayProductDisplay', ['cornerPreview']),
    previewItemsProps() {
      const { itemWidth, isItemWidthFixed, rowWidth } = this;

      return {
        itemWidth,
        rowWidth,
        isItemWidthFixed:
          isItemWidthFixed && this.previewSettings.use_original_product_image
      };
    },
    showSpareItemsZone() {
      return (
        this.showSpareItemsSeparately &&
        this.cornerPreview.max_products !== null
      );
    },
    showExceededMaxProductsItemsZone() {
      return (
        this.showExceededMaxProductsItemsSeparately &&
        this.previewSettings.preview_limit_products_count
      );
    },
    itemZoneInfos() {
      const { showSpareItemsZone, showExceededMaxProductsItemsZone } = this;
      const max_products = showSpareItemsZone
        ? this.cornerPreview.max_products
        : null;
      const { preview_max_products } = this.previewSettings;

      const result = [{ startIndex: 0, label: null }];
      const forceShowZone =
        showSpareItemsZone &&
        showExceededMaxProductsItemsZone &&
        max_products === preview_max_products &&
        this.items.length > 0;
      if (showSpareItemsZone)
        result.push({
          startIndex: max_products,
          label: 'spare',
          force: forceShowZone
        });
      if (showExceededMaxProductsItemsZone)
        result.push({
          startIndex: preview_max_products,
          label: 'exceeded_max_products',
          force: forceShowZone
        });

      return result.sort((o1, o2) => o1.startIndex - o2.startIndex);
    },
    itemZones() {
      const borderIndices = this.itemZoneInfos.map(
        ({ startIndex }) => startIndex
      );
      borderIndices.push(this.items.length);

      const result = [];
      for (let i = 0; i < borderIndices.length - 1; i++)
        result.push(this.items.slice(borderIndices[i], borderIndices[i + 1]));

      return result;
    },
    currentPageItems() {
      const { preview_products_count_in_page } = this.previewSettings;
      const items = this.itemZones[0];

      const startIndex =
        (this.currentPage - 1) * preview_products_count_in_page;
      const endIndex = this.currentPage * preview_products_count_in_page;

      return items.slice(startIndex, endIndex);
    },
    itemMatrix() {
      return this.itemArrayToMatrix(this.currentPageItems);
    },
    surplusItemMatrices() {
      return this.itemZones
        .map((items, i) => ({
          matrix: this.itemArrayToMatrix(items),
          ...this.itemZoneInfos[i]
        }))
        .slice(1)
        .filter(({ matrix, force }) => !!matrix.length || force);
    },
    rowWidth() {
      const columnCount = this.previewSettings.preview_products_count_in_line;
      const itemMargin = 12;

      return columnCount * (this.itemWidth + itemMargin) - itemMargin;
    }
  },
  watch: {
    markedProduct() {
      const { index } = this.markedProduct;

      if (Number.isInteger(index)) this.scrollToProduct(index);
    }
  },
  methods: {
    itemArrayToMatrix(items) {
      const columnCount = this.previewSettings.preview_products_count_in_line;
      while (items.length % columnCount > 0) items.push(null);

      return _.chunk(items, columnCount);
    },
    drag: _.throttle(function(e) {
      e.preventDefault();

      const parentEl = getVerticallyScrollableParent(this.$el);
      const parentRect = parentEl.getBoundingClientRect();
      const scrollAreaHeight = parentRect.height / 8;

      const isDraggingUp = e.clientY < parentRect.top + scrollAreaHeight;
      const isDraggingDown = e.clientY > parentRect.bottom - scrollAreaHeight;

      let mouseGap = null;
      if (isDraggingUp) mouseGap = Math.max(e.clientY - parentRect.top, 0);
      else if (isDraggingDown)
        mouseGap = Math.max(parentRect.bottom - e.clientY, 0);
      else return;

      const scrollDefaultSpeed = scrollAreaHeight / 4;
      const speedFactor = (scrollAreaHeight - mouseGap) / scrollAreaHeight + 1;
      const scrollSpeed = scrollDefaultSpeed * speedFactor;

      if (isDraggingUp) parentEl.scrollTop = parentEl.scrollTop - scrollSpeed;
      else if (isDraggingDown)
        parentEl.scrollTop = parentEl.scrollTop + scrollSpeed;
    }, 20),
    addEventListeners() {
      const throttledDragover = _.throttle(this.dragover, 100);
      this.dragOverEventListener = e => {
        e.preventDefault();
        throttledDragover(e);
      };
      const draggableElements = this.$el.getElementsByClassName(
        'DisplayCornerItemsContainer__item'
      );
      for (const $el of draggableElements) {
        $el.classList.add('js-draggable-item--active');
        $el.addEventListener('dragover', this.dragOverEventListener);
      }
    },
    removeEventListeners() {
      const draggableElements = this.$el.getElementsByClassName(
        'DisplayCornerItemsContainer__item'
      );
      for (const $el of draggableElements) {
        $el.classList.remove('js-draggable-item--active');
        $el.removeEventListener('dragover', this.dragOverEventListener);
      }
    },
    dragstart(e) {
      if (!this.isDraggable) {
        e.preventDefault();
        return;
      }

      this.srcIndex = Number(e.target.dataset.itemIndex);
      const srcItem = this.items[this.srcIndex];

      if (!srcItem || srcItem.preventDragging) {
        e.preventDefault();
        return;
      }

      this.addEventListeners();
    },
    dragover(e) {
      const currentItem = e.target.closest('.js-draggable-item--active');
      if (!currentItem) return;

      const curIndex = Number(currentItem.dataset.itemIndex);
      const insertBefore = e.offsetX < currentItem.offsetWidth * 0.5;

      this.dstIndex = insertBefore ? curIndex : curIndex + 1;

      const modalRect = this.$parent.$el.getBoundingClientRect();
      const rect = currentItem.getBoundingClientRect();

      const dropBar = this.$refs.dropBar;
      dropBar.style.top = `${rect.top - modalRect.top}px`;
      dropBar.style.height = `${rect.height}px`;
      dropBar.style.left = insertBefore
        ? `${rect.left - modalRect.left - 6}px`
        : `${rect.right - modalRect.left + 6}px`;
      this.isShowingDropBar = true;
    },
    dragend() {
      if (!this.isDraggable) return;

      this.$emit('sort', { srcIndex: this.srcIndex, dstIndex: this.dstIndex });
      this.removeEventListeners();

      this.srcIndex = null;
      this.dstIndex = null;
      this.isShowingDropBar = false;
    },
    scrollToProduct(productIndex) {
      const { preview_products_count_in_page } = this.previewSettings;

      this.currentPage =
        Math.floor(productIndex / preview_products_count_in_page) + 1;

      const index = productIndex % preview_products_count_in_page;
      const productElement = this.$el.getElementsByClassName(
        'DisplayCornerItemsContainer__item'
      )[index];
      this.scrollToElement(productElement);
    },
    updateCurrentPage(page) {
      this.currentPage = page;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.DisplayCornerItemsContainer {
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.DisplayCornerItemsContainer__drop-bar {
  width: 1px;
  position: absolute;
  background: $color-grey-80;
  pointer-events: none;
}

.DisplayCornerItemsContainer__pagination {
  margin-top: 32px;
  margin-bottom: 60px;
  text-align: center;
}

.DisplayCornerItemsContainer__info-box {
  margin: 24px auto;
}
</style>

<i18n locale="ko">
{
  "spare": "품절 등의 이유로 위 영역의 상품들을 진열할 수 없을 때 아래 영역의 상품들을 대신 노출합니다.",
  "exceeded_max_products": "미리보기 상품 수 제한 설정에 의해 저장 또는 진열 시 아래 영역의 상품들은 제외됩니다."
}
</i18n>
