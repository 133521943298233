<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="AppFitProductLivePreview" v-html="html" />
</template>

<script>
import _ from 'lodash';
import Liquid from 'liquidjs';
import juice from 'juice';
import FIT_CSS from '!raw-loader!@/scss/fit.css';

export default {
  name: 'AppFitProductLivePreview',
  props: {
    fitProduct: {
      type: Object,
      required: true
    },
    fitTemplate: {
      type: Object,
      default: null
    },
    fitDefaultSampleImages: {
      type: Array,
      required: true
    },
    widgetCss: {
      type: String,
      default: null
    },
    liquidTemplate: {
      type: Object,
      default: null
    }
  },
  data() {
    const liquidEngine = new Liquid({
      fs: {
        exists: async () => true,
        readFile: async filepath => this.liquidTemplate[filepath],
        resolve: (_root, file) => file
      }
    });
    liquidEngine.registerFilter('t', v => this.$t(v));
    liquidEngine.registerFilter('report_number', v => v || '-');

    return {
      html: '',
      liquidEngine,
      currentLiveData: _.cloneDeep({
        fitProduct: this.fitProduct,
        fitTemplate: this.fitTemplate
      })
    };
  },
  computed: {
    productOptionType() {
      return this.fitProduct.product_option_types.find(
        t => t.id == this.fitProduct.product_option_type_id
      );
    },
    liveData() {
      return {
        fitProduct: this.fitProduct,
        fitTemplate: this.fitTemplate
      };
    },
    template() {
      return this.liquidEngine.parse(this.liquidTemplate['fit_product_liquid']);
    }
  },
  watch: {
    liveData: {
      handler(newLiveData) {
        if (_.isEqual(this.currentLiveData, newLiveData)) return;

        this.recalcHtmlDebounced();
        this.currentLiveData = _.cloneDeep(newLiveData);
      },
      deep: true
    }
  },
  mounted() {
    this.recalcHtmlDebounced = _.debounce(this.recalcHtml, 200);
    this.recalcHtmlDebounced();
  },
  methods: {
    recalcHtml() {
      if (!this.template) {
        this.html = '';
        return;
      }

      const liquidParams = {
        ...this.sampleParams(),
        ...this.sizeParams(),
        ...this.infoParams(),
        ...this.propertyParams(),
        ...this.etcParams(),
        ...this.templateStyleParam()
      };

      const mergedCSS = FIT_CSS + this.widgetCss + this.fitTemplate.css;

      this.liquidEngine
        .render(this.template, liquidParams)
        .then(html => (this.html = juice.inlineContent(html, mergedCSS)));
    },
    sampleParams() {
      if (!this.fitTemplate.sample_enabled) return {};

      const sampleImage = {};
      if (this.fitProduct.fit_sample_image_source_type === 'template') {
        if (this.fitTemplate.fit_sample_image_source_type === 'default') {
          const image = this.fitDefaultSampleImages.find(
            i => i.id === this.fitTemplate.fit_default_sample_image_id
          );
          if (image) {
            sampleImage.sample_image_url = image.image_url;
            sampleImage.sample_image_height = image.image_height;
            sampleImage.sample_image_width = image.image_width;
          }
        } else if (
          this.fitTemplate.fit_sample_image_source_type === 'template'
        ) {
          sampleImage.sample_image_url = this.fitTemplate.sample_image_url;
          sampleImage.sample_image_height = this.fitTemplate.sample_image_height;
          sampleImage.sample_image_width = this.fitTemplate.sample_image_width;
        }
      } else if (this.fitProduct.fit_sample_image_source_type === 'default') {
        const image = this.fitDefaultSampleImages.find(
          i => i.id === this.fitProduct.fit_default_sample_image_id
        );
        if (image) {
          sampleImage.sample_image_url = image.image_url;
          sampleImage.sample_image_height = image.image_height;
          sampleImage.sample_image_width = image.image_width;
        }
      } else if (this.fitProduct.fit_sample_image_source_type === 'product') {
        sampleImage.sample_image_url = this.fitProduct.sample_image_url;
        sampleImage.sample_image_height = this.fitProduct.sample_image_height;
        sampleImage.sample_image_width = this.fitProduct.sample_image_width;
      }
      sampleImage['sample_image?'] = !!sampleImage.sample_image_url;

      return {
        sample_enabled: true,
        ...this.titleParams('sample'),
        ...sampleImage
      };
    },
    sizeParams() {
      if (!this.fitTemplate.size_enabled) return {};

      const optionKeyValidSet = new Set();
      this.fitProduct.fit_product_sizes.forEach(size => {
        if (size.value) optionKeyValidSet.add(size.fit_option_key_id);
      });

      const titleRows = [[]];
      let rowIndex = 0;
      this.fitTemplate.size_option_keys.forEach(key => {
        if (
          titleRows[rowIndex].length &&
          this.fitTemplate.size_option_key_ids_for_new_line.includes(key.id)
        ) {
          titleRows.push([]);
          rowIndex += 1;
        }
        if (optionKeyValidSet.has(key.id)) {
          titleRows[rowIndex].push(key);
        }
      });

      const size_data = {
        title: titleRows.map(row => row.map(e => this.toHtml(e.display_name))),
        no_size_option_display_name: false
      };

      const productOptionValues = this.productOptionType?.product_option_values
        .length
        ? this.productOptionType.product_option_values
        : [{ id: null, value: this.fitProduct.free_size_display_name }];

      if (this.productOptionType) {
        size_data.size_option_type =
          this.fitTemplate.size_option_type_name || this.productOptionType.name;
      } else {
        if (this.fitProduct.free_size_display_name) {
          size_data.size_option_type = this.fitTemplate.size_option_type_name;
        } else {
          size_data.no_size_option_display_name = true;
        }
      }
      size_data.size_option_type = this.toHtml(size_data.size_option_type);

      size_data.size_option_values = productOptionValues.map(v => v.value);

      size_data.items = productOptionValues.map(productOptionValue => {
        return titleRows.map(row => {
          return row.map(key => {
            const size = this.fitProduct.fit_product_sizes.find(
              size =>
                size.fit_option_key_id === key.id &&
                size.product_option_value_id === productOptionValue.id
            );
            return size
              ? {
                  value: size.value,
                  description: this.toHtml(size.description)
                }
              : { value: '-' };
          });
        });
      });

      return {
        size_enabled: true,
        ...this.titleParams('size'),
        size_data,
        size_description_html: this.toHtml(
          this.fitProduct.size_description_html
        )
      };
    },
    infoParams() {
      if (!this.fitTemplate.info_enabled) return {};
      const params = { ...this.titleParams('info') };
      params['info_data'] = this.fitTemplate.info_option_keys
        .map(key => {
          const option = this.fitProduct.fit_product_options.find(
            o => o.fit_option_key_id === key.id
          );
          if (!option) return;
          return {
            title: this.toHtml(key.display_name),
            value: option ? this.toHtml(option.value) : ''
          };
        })
        .filter(Boolean);
      params['info_enabled'] = params['info_data'].length > 0;

      return params;
    },
    propertyParams() {
      if (!this.fitTemplate.property_enabled) return {};

      const property_data = this.fitTemplate.property_option_keys.map(key => ({
        title: this.toHtml(key.display_name),
        values: key.option_values.map(value => ({
          name: this.toHtml(value.display_name),
          selected: this.fitProduct.fit_option_value_ids.includes(value.id)
        }))
      }));

      const filtered_property_data = property_data.filter(function(option) {
        const selected = option.values.map(v => v.selected);
        return selected.includes(true);
      });

      return {
        property_enabled: true,
        ...this.titleParams('property'),
        property_data: filtered_property_data,
        property_values_max_length: Math.max(
          ...property_data.map(k => k.values.length)
        )
      };
    },
    etcParams() {
      if (
        this.fitTemplate.etc_enabled &&
        this.fitProduct.etc_description_html
      ) {
        return {
          etc_enabled: true,
          etc_description_html:
            this.toHtml(this.fitProduct.etc_description_html) || null
        };
      } else {
        return {};
      }
    },
    templateStyleParam() {
      return { template_style: this.fitTemplate.template_style };
    },
    titleParams(section) {
      const result = {};

      const titleKey = `${section}_title`;
      result[titleKey] = this.toHtml(this.fitTemplate[titleKey]);

      return [
        `${section}_title_display_type`,
        `${section}_title_image_url`,
        `${section}_title_image_height`,
        `${section}_title_image_width`
      ].reduce((object, key) => {
        object[key] = this.fitTemplate[key];
        return object;
      }, result);
    },
    toHtml(string) {
      if (!string) return string;

      return _.escape(string)
        .replace(/\r\n|\r|\n/g, '<br />')
        .replace(/\*\*(?<word>[\S\s]*?)\*\*/g, '<b>$<word></b>');
    }
  }
};
</script>

<style lang="scss" scoped>
.AppFitProductLivePreview {
  padding: 12px;
  min-width: 599px;
  width: 100%;
}
</style>

<i18n locale="ko">
{
  "fit/products.show.unit": "단위",
  "fit/products.combined.fit_product.unit": "단위"
}
</i18n>
