<template>
  <AppButton
    :tooltip="tooltip"
    :disabled="disabled"
    :size="size"
    :button-style="buttonStyle === 'table-head' ? 'none' : buttonStyle"
    type="dropdown"
    :class="[
      'AppDropdownMenuButton',
      `AppDropdownMenuButton--style-${buttonStyle}`
    ]"
    @click="click"
  >
    <div
      :class="[
        'AppDropdownMenuButton__label',
        { [`AppDropdownMenuButton__label--${size}`]: !!size }
      ]"
    >
      {{ label }}
    </div>
    <AppSvgIcon
      name="nav-chevron-down"
      :class="[
        'AppDropdownMenuButton__icon',
        { [`AppDropdownMenuButton__icon--${size}`]: !!size }
      ]"
      :height="iconHeight"
    />
    <template #dropdown>
      <AppModeless v-if="isDropdownVisible" @close="close">
        <AppDropdownMenu :menu-items="menuItems" @close="close" />
      </AppModeless>
    </template>
  </AppButton>
</template>

<script>
export default {
  name: 'AppDropdownMenuButton',
  props: {
    label: { type: String, required: true },
    buttonStyle: { type: String, default: 'default' },
    menuItems: { type: Array, required: true },
    tooltip: { type: [String, Object], default: null },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: null }
  },
  data() {
    return {
      isDropdownVisible: false
    };
  },
  computed: {
    iconHeight() {
      return this.buttonStyle === 'label' ? 11 : null;
    }
  },
  methods: {
    click() {
      this.isDropdownVisible ? this.close() : this.open();
    },
    open() {
      this.isDropdownVisible = true;
    },
    close() {
      this.isDropdownVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.AppDropdownMenuButton__label {
  padding-right: 17px;

  &--small {
    padding-right: 13px;
  }

  .AppDropdownMenuButton--style-label &,
  .AppDropdownMenuButton--style-table-head & {
    padding-right: 19px;
  }
}

.AppDropdownMenuButton__icon {
  position: absolute;
  top: 10px;
  right: 10px;
  pointer-events: none;

  &--small {
    top: 9px;
    right: 8px;
  }

  .AppDropdownMenuButton--style-label & {
    top: 4px;
    right: 0px;
    stroke-width: 1.5px;
  }

  .AppDropdownMenuButton--style-label &,
  .AppDropdownMenuButton--style-table-head & {
    top: 5px;
    right: 0px;
    stroke-width: 1.5px;
  }
}

.AppDropdownMenuButton--style-table-head {
  vertical-align: baseline;

  ::v-deep .AppButton__button {
    font-weight: inherit;
  }
}
</style>
