import { constructEnum } from '@/lib/enum';

export default constructEnum('TargetProductRecommendationMethod', {
  LEGACY: 10,
  INTERESTED: 20,
  POPULAR: 30,
  PERSONALIZED: 100,
  HAND_PICKED: 200,
  IN_CATEGORIES: 300,
  IN_CATEGORIES_FROM_CAMPAIGN: 310
});
