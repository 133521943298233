<template>
  <div v-tooltip="totalItemsLabel" class="ChannelProductsCategoryCell">
    <ul>
      <li
        v-for="item in items"
        :key="item.id"
        class="ChannelProductsCategoryCell__badge"
      >
        <AppBadge
          :label="item.label"
          badge-style="grey-outline"
          with-max-width
        />
      </li>
      <li v-if="isAbbreviated">
        {{ $t('etc_count', [categoryIds.length - maxItemsCount]) }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ChannelProductsCategoryCell',
  props: {
    categoryIds: { type: Array, required: true }
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoryNamesMap']),
    maxItemsCount() {
      return 10;
    },
    isAbbreviated() {
      return this.categoryIds.length > this.maxItemsCount;
    },
    items() {
      return this.categoryIds.slice(0, this.maxItemsCount).map(id => ({
        id,
        label: this.productCategoryNamesMap[id]
      }));
    },
    totalItemsLabel() {
      if (!this.isAbbreviated) return null;

      return this.categoryIds
        .map(id => this.productCategoryNamesMap[id])
        .join('\n');
    }
  }
};
</script>

<style lang="scss" scoped>
.ChannelProductsCategoryCell__badge {
  width: 142px;
  margin: auto;
}
</style>

<i18n locale="ko">
{
  "etc_count": "외 {0}개"
}
</i18n>
