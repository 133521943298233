var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModal',{attrs:{"width":_vm.currentWidth,"left-width":_vm.leftWidth,"maximized":_vm.maximized,"default-left-min-width":_vm.defaultLeftMinWidth,"default-main-min-width":_vm.defaultMainMinWidth,"show-bubble":_vm.showBubbleMid},on:{"close":_vm.close}},[_c('template',{slot:"left"},[_vm._t("left")],2),_c('div',{staticClass:"AppModalDefault__head",attrs:{"slot":"head"},slot:"head"},[_c('div',{staticClass:"AppModalDefault__head-wrapper"},[(!_vm.useCustomTitle)?_c('div',{staticClass:"AppModalDefault__head-title-wrapper"},[_c('span',{staticClass:"AppModalDefault__head-title"},[_vm._v(_vm._s(_vm.title))]),(_vm.subTitle)?_c('span',{staticClass:"AppModalDefault__head-sub-title"},[_vm._v(_vm._s(_vm.subTitle))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"AppModalDefault__head-button-wrapper"},[_vm._t("head-button"),(_vm.canMaximize)?_c('AppModalHeadMaximizeButton',{staticClass:"AppModalDefault__head-maximize-button",attrs:{"maximized":_vm.maximized},on:{"maximize":_vm.toggleMaximized}}):_vm._e(),(!_vm.hideHeadCloseButton)?_c('AppModalHeadCloseButton',{staticClass:"AppModalDefault__head-close-button",attrs:{"disabled":_vm.closeButtonDisabled},on:{"close":_vm.close}}):_vm._e(),(!_vm.isBubbleHidden)?_c('AppBubble',{staticClass:"AppModalDefault__head-maximize-bubble",attrs:{"type":"left"},on:{"click":_vm.hideBubble}},[_vm._v(" "+_vm._s(this.$t('bubble_message'))+" ")]):_vm._e()],2)]),_vm._t("sub-head")],2),_c('div',{class:[
      'AppModalDefault__body',
      _vm.bodyStyle ? ("AppModalDefault__body--style-" + _vm.bodyStyle) : null,
      _vm.isMaximizedModalPage
        ? 'AppModalDefault__body--maximized-modal-page'
        : _vm.maximized
        ? 'AppModalDefault__body--maximized-modal'
        : ''
    ],attrs:{"slot":"body"},slot:"body"},[_vm._t("body")],2),(_vm.$slots.foot || _vm.$scopedSlots.foot)?_c('div',{staticClass:"AppModalDefault__foot",attrs:{"slot":"foot"},slot:"foot"},[_vm._t("foot")],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }