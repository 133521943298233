<template>
  <Component
    :is="isNewDocument ? 'AppExternalLink' : 'a'"
    :to="isNewDocument ? notification.target.url : null"
    :class="[
      'NotificationItem',
      `NotificationItem--${notification.notification_status}`
    ]"
    @click="showTarget(notification)"
  >
    <div
      :class="[
        'NotificationItem__priority-indicator',
        `NotificationItem__priority-indicator--${priority}`
      ]"
    />
    <div class="NotificationItem__title">
      <div class="NotificationItem__title-text">{{ title }}</div>
      <AppExternalLinkIcon v-if="isNewDocument" />
    </div>
    <div class="NotificationItem__message">{{ message }}</div>
    <div class="NotificationItem__date">
      {{ notification.created_at | formatDate }}
    </div>
    <div class="NotificationItem__call-to-action">{{ actionLabel }}</div>
  </Component>
</template>

<script>
import { mapActions } from 'vuex';
import BizMessageCampaignType from '@/enums/BizMessageCampaignType';

const NotificationCategory = {
  PAYMENT: 'payment',
  SETTINGS_CHANGE: 'settings_change',
  NEW_HELP_DOCUMENT: 'new_help_document',
  NEW_NOTICE: 'new_notice',
  BIZ_MESSAGE: 'biz_message'
};

export default {
  name: 'NotificationItem',
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  computed: {
    notificationCategory() {
      switch (this.notification.notification_type) {
        case 'payment_review':
        case 'payment_review_sms':
        case 'payment_fit':
          return NotificationCategory.PAYMENT;
        case 'phone_change_request_approved':
        case 'phone_change_request_rejected':
        case 'phone_changed_by_super_admin':
        case 'sender_email_change_request_approved':
        case 'sender_email_change_request_rejected':
        case 'corporate_number_change_request_approved':
        case 'corporate_number_change_request_rejected':
        case 'corporate_number_changed_by_super_admin':
          return NotificationCategory.SETTINGS_CHANGE;
        case 'new_review_manual':
        case 'new_target_manual':
        case 'new_fit_manual':
          return NotificationCategory.NEW_HELP_DOCUMENT;
        case 'biz_message_template_approved':
        case 'biz_message_template_rejected':
        case 'biz_message_template_stopped':
          return NotificationCategory.BIZ_MESSAGE;
        default:
          return NotificationCategory.NEW_NOTICE;
      }
    },
    isPayment() {
      return this.notificationCategory === NotificationCategory.PAYMENT;
    },
    isSettingsChange() {
      return this.notificationCategory === NotificationCategory.SETTINGS_CHANGE;
    },
    isNewDocument() {
      return (
        this.notificationCategory === NotificationCategory.NEW_HELP_DOCUMENT
      );
    },
    isBizMessage() {
      return this.notificationCategory === NotificationCategory.BIZ_MESSAGE;
    },
    priority() {
      if (this.isPayment) return this.isExpired ? 'urgent' : 'important';
      else
        return [
          'phone_change_request_rejected',
          'sender_email_change_request_rejected',
          'corporate_number_change_request_rejected'
        ].includes(this.notification.notification_type)
          ? 'urgent'
          : 'info';
    },
    title() {
      const { notification_type, message } = this.notification;
      if (this.isPayment) {
        return this.isExpired
          ? this.$t(`title.${notification_type}.expired`)
          : this.$t(`title.${notification_type}.expires_soon`, {
              days: parseInt(message)
            });
      } else {
        return this.$t(`title.${notification_type}`);
      }
    },
    message() {
      const { notification_type, message } = this.notification;
      if (this.isPayment) {
        return this.isExpired
          ? this.$t(`message.${notification_type}.expired`)
          : this.$t(`message.${notification_type}.expires_soon`, {
              days: parseInt(message)
            });
      } else if (this.isBizMessage)
        return this.$t(`message.${notification_type}`, [message]);
      else {
        const key = `message.${notification_type}`;
        return this.$te(key) ? this.$t(key) : message;
      }
    },
    actionLabel() {
      const key = this.isPayment
        ? 'make_payment'
        : this.isSettingsChange
        ? 'check'
        : 'show_content';
      return this.$t(`action_label.${key}`);
    },
    isExpired() {
      const { notification_type, message } = this.notification;
      if (
        notification_type === 'payment_review' ||
        notification_type === 'payment_fit'
      )
        return message === '-1';
      return message === '0';
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('notification', ['markNotificationChecked']),
    showTarget() {
      this.markNotificationChecked(this.notification.id);

      if (this.isPayment) this.openDialog('ThePaymentDialog');
      else if (this.isBizMessage) {
        const campaignType =
          this.notification.notification_type === 'biz_message_template_stopped'
            ? Number(this.notification.message)
            : BizMessageCampaignType.REVIEW_MEMBER;
        this.openDialog(['BizMessageCampaignFormDialog', { campaignType }]);
      } else if (this.isSettingsChange) this.openDialog('TheSettingsDialog');
      else if (!this.isNewDocument)
        this.openDialog([
          'TheNoticeDialog',
          {
            noticeTitle: this.notification.message,
            noticeId: this.notification.target_id
          }
        ]);

      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.NotificationItem {
  display: block;
  position: relative;
  text-align: left;
  padding: 6px 8px 6px 17px;
  cursor: pointer;

  &:hover {
    background-color: $color-grey-15;
  }
}

.NotificationItem--checked {
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.NotificationItem__priority-indicator {
  position: absolute;
  top: 11px;
  left: 8px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.NotificationItem__priority-indicator--info {
  background-color: $color-green;
}

.NotificationItem__priority-indicator--important {
  background-color: $color-mango;
}

.NotificationItem__priority-indicator--urgent {
  background-color: $color-red;
}

.NotificationItem__title {
  @include text-content;
  font-weight: bold;
}

.NotificationItem__title-text {
  display: inline-block;
  vertical-align: middle;
}

.NotificationItem__message {
  @include text-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.NotificationItem__date {
  @include text-caption;
}

.NotificationItem__call-to-action {
  @include text-caption-dark;
  display: none;
  position: absolute;
  right: 12px;
  bottom: 6px;
  text-decoration: underline;

  .NotificationItem:hover & {
    display: block;
  }
}
</style>

<i18n locale="ko">
{
  "title": {
    "new_notice": "새로운 공지가 있습니다.",
    "new_review_manual": "새로운 리뷰 기능 안내 글이 있습니다.",
    "new_target_manual": "새로운 타겟 기능 안내 글이 있습니다.",
    "new_fit_manual": "새로운 핏 기능 안내 글이 있습니다.",
    "payment_review": {
      "expired": "리뷰 서비스가 만료되었습니다.",
      "expires_soon": "리뷰 만료일이 {days}일 남았습니다."
    },
    "payment_review_sms": {
      "expired": "리뷰 작성 안내 메시지 발송이 중지됐습니다.",
      "expires_soon": "{days}일 후 리뷰 추가 메시지 발송이 중지될 예정입니다"
    },
    "payment_fit": {
      "expired": "핏 서비스가 만료되었습니다.",
      "expires_soon": "핏 만료일이 {days}일 남았습니다."
    },
    "phone_change_request_approved": "쇼핑몰 대표 전화번호가 변경됐습니다.",
    "phone_change_request_rejected": "쇼핑몰 대표 전화번호를 변경하지 못했습니다.",
    "phone_changed_by_super_admin": "쇼핑몰 대표 전화번호가 변경됐습니다.",
    "sender_email_change_request_approved": "발송 이메일 주소가 변경됐습니다.",
    "sender_email_change_request_rejected": "발송 이메일 주소를 변경하지 못했습니다.",
    "corporate_number_change_request_approved": "세금계산서 발급 정보가 변경됐습니다.",
    "corporate_number_change_request_rejected": "세금계산서 발급 정보를 변경하지 못했습니다.",
    "corporate_number_changed_by_super_admin": "세금계산서 발급 정보가 변경됐습니다.",
    "biz_message_template_approved": "템플릿이 승인되었습니다.",
    "biz_message_template_rejected": "템플릿이 반려되었습니다.",
    "biz_message_template_stopped": "알림톡 발송이 중단되었습니다."
  },
  "message": {
    "payment_review": {
      "expired": "리뷰 사용기간 연장 결제를 해주세요.",
      "expires_soon": "리뷰 사용기간 연장 결제를 해주세요."
    },
    "payment_review_sms": {
      "expired": "리뷰 추가 메시지를 결제하면 발송이 재개됩니다.",
      "expires_soon": "리뷰 추가 메시지를 결제하면 발송 중지를 방지할 수 있습니다."
    },
    "payment_fit": {
      "expired": "핏 사용기간 연장 결제를 해주세요.",
      "expires_soon": "핏 사용기간 연장 결제를 해주세요."
    },
    "phone_change_request_approved": "요청한 전화번호로 변경됐습니다.",
    "phone_change_request_rejected": "변경 실패 사유를 확인해주세요.",
    "phone_changed_by_super_admin": "크리마 관리자가 정보를 변경했습니다.",
    "sender_email_change_request_approved": "인증된 이메일 주소로 변경됐습니다.",
    "sender_email_change_request_rejected": "변경 실패 사유를 확인해주세요.",
    "corporate_number_change_request_approved": "요청한 세금계산서 발급 정보로 변경됐습니다.",
    "corporate_number_change_request_rejected": "변경 실패 사유를 확인해주세요.",
    "corporate_number_changed_by_super_admin": "크리마 관리자가 정보를 변경했습니다.",
    "biz_message_template_approved": "{0}((로)) 발송설정이 가능합니다.",
    "biz_message_template_rejected": "{0}의 반려사유를 참고하여 수정해주세요.",
    "biz_message_template_stopped": "설정하신 알림톡 템플릿이 사용 중단 처리되어 알림톡 발송이 불가합니다. 다른 템플릿으로 변경해주세요."
  },
  "action_label": {
    "show_content": "내용보기",
    "make_payment": "결제하기",
    "check": "확인하기"
  }
}
</i18n>
