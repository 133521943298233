<template>
  <AppButton
    :size="size"
    :button-style="buttonStyle"
    :disabled="disabled"
    :label="label"
    :pushed="value"
    :tooltip="tooltip"
    @click="$emit('change', !value)"
  >
    <slot>{{ label }}</slot>
  </AppButton>
</template>

<script>
export default {
  name: 'AppButtonToggle',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    buttonStyle: {
      type: String,
      default: 'grey-outline'
    },
    tooltip: {
      type: [String, Object],
      default: null
    },
    size: {
      type: String,
      default: 'narrow'
    }
  }
};
</script>
