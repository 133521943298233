<template>
  <AppModalFormLivePreview
    :title="$t('landing_page_design')"
    :sub-title="campaign.name"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      disabled: isArchivedCampaign,
      objectId: 'campaign'
    }"
    v-on="formEvents"
    @error-summary="updatePreviewIfValid"
  >
    <template #left>
      <AppMobilePhonePreview>
        <AppIframeViewer
          :src="landingPageUrl"
          :is-loading-forced="currentVersion !== displayVersion"
        />
      </AppMobilePhonePreview>
    </template>
    <template #group="{ id, disabled }">
      <template v-if="id === 'product_recommendation_units'">
        <div class="AppForm__group-field AppForm__group-field--mt8">
          <TargetDmCampaignsRecommendedProducts
            v-model="formObject.product_recommendation_units"
            :disabled="disabled"
            @change="validateField('product_recommendation_units')"
          />
        </div>
      </template>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import qs from 'qs';
import { mapGetters, mapMutations } from 'vuex';
import api from '@/lib/api';
import interpolate from '@/lib/interpolate';
import { isUrlFormatValid } from '@/lib/urlFormatValidator';
import DialogFormView from '@/mixins/DialogFormView';
import IframePreviewHelper from '@/mixins/IframePreviewHelper';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetIndexPageType from '@/enums/TargetIndexPageType';
import TargetDmCampaignsRecommendedProducts from './components/TargetDmCampaignsRecommendedProducts';

export default {
  name: 'TargetDmCampaignSettingsDesignLandingPageDialog',
  components: { TargetDmCampaignsRecommendedProducts },
  mixins: [DialogFormView, IframePreviewHelper],
  props: { campaign: { type: Object, required: true } },
  data() {
    return {
      isLoading: true,
      isSubmittingPreview: false,
      KEYWORDS: ['user', 'user_id', 'brand']
    };
  },
  computed: {
    ...mapGetters('targetCampaign', ['isArchivedCampaign']),
    basicGroupIds() {
      switch (this.campaign.campaign_type) {
        case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
          return [
            'member_inactivation_prevention_description_html',
            'member_inactivation_prevention_button_label',
            'member_inactivation_prevention_button_url',
            'coupon_description_html',
            'recommendation_description_html'
          ];
        case TargetCampaignType.CART_REMINDING:
          return [
            'design_title',
            'design_description',
            'cart_description_html',
            'recommendation_description_html',
            'popular_products_description_html',
            'coupon_description_html'
          ];
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
          return [
            'design_title',
            'design_description',
            'recommendation_description_html',
            'popular_products_description_html',
            'coupon_description_html'
          ];
        default:
          return ['coupon_description_html', 'recommendation_description_html'];
      }
    },
    designKeywords() {
      switch (this.campaign.campaign_type) {
        case TargetCampaignType.CART_REMINDING:
          return ['cart_items_count', 'cart_item'];
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
          return ['unpaid_orders_count'];
        default:
          return [];
      }
    },
    basicGroupConfigs() {
      return {
        design_title: {
          type: 'keyword_textarea',
          placeholder: this.$t('design_title_placeholder'),
          keywords: this.toKeywords([...this.KEYWORDS, ...this.designKeywords]),
          validate: ['required']
        },
        design_description: {
          type: 'keyword_textarea',
          placeholder: this.$t('design_description_placeholder'),
          keywords: this.toKeywords([...this.KEYWORDS, ...this.designKeywords]),
          validate: ['required']
        },
        cart_description_html: {
          type: 'keyword_textarea',
          placeholder: this.$t('description_html_placeholder'),
          keywords: this.toKeywords(this.KEYWORDS),
          singleLine: true,
          validate: ['required']
        },
        popular_products_description_html: {
          type: 'keyword_textarea',
          placeholder: this.$t('description_html_placeholder'),
          keywords: this.toKeywords(this.KEYWORDS),
          singleLine: true,
          validate: ['required']
        },
        member_inactivation_prevention_description_html: {
          type: 'keyword_textarea',
          placeholder: this.$t('description_html_placeholder'),
          keywords: this.toKeywords([
            ...this.KEYWORDS,
            'days_before_inactivation'
          ]),
          singleLine: true,
          validate: ['required']
        },
        member_inactivation_prevention_button_label: {
          type: 'keyword_textarea',
          placeholder: this.$t('button_label_placeholder'),
          keywords: this.toKeywords(this.KEYWORDS),
          singleLine: true,
          validate: ['required']
        },
        member_inactivation_prevention_button_url: {
          type: 'keyword_textarea',
          placeholder: this.$t('button_url_placeholder'),
          keywords: this.toKeywords(['login_url']),
          singleLine: true,
          validate: [
            'required',
            {
              rule: v =>
                isUrlFormatValid(
                  interpolate(v, { login_url: 'https://cre.ma' })
                ),
              errorMessage: this.$t('validations.url_format')
            }
          ]
        },
        coupon_description_html: {
          type: 'keyword_textarea',
          placeholder: this.$t('description_html_placeholder'),
          keywords: this.toKeywords(this.KEYWORDS),
          singleLine: true,
          validate: ['required']
        },
        recommendation_description_html: {
          type: 'keyword_textarea',
          placeholder: this.$t('description_html_placeholder'),
          keywords: this.toKeywords(this.KEYWORDS),
          singleLine: true,
          validate: ['required']
        }
      };
    },
    advancedGroupIds() {
      switch (this.campaign.campaign_type) {
        case TargetCampaignType.CART_REMINDING:
          return [
            'advanced_settings_info',
            'product_recommendation_units',
            'recommendation_title',
            'cart_title',
            'coupon_title',
            'index_page_type',
            'landing_page_css'
          ];
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
          return [
            'advanced_settings_info',
            'product_recommendation_units',
            'recommendation_title',
            'unpaid_orders_title',
            'coupon_title',
            'index_page_type',
            'landing_page_css'
          ];
        default:
          return [
            'advanced_settings_info',
            'display_recommendation_items',
            'product_recommendation_units',
            'display_brand_user_coupons',
            'landing_page_css'
          ];
      }
    },
    advancedGroupConfigs() {
      return {
        advanced_settings_info: {
          label: '',
          type: 'infobox',
          value: this.$t('advanced_settings_info_html')
        },
        display_recommendation_items: {
          type: 'checkbox',
          label: this.$t('display_recommendation_items')
        },
        recommendation_title: {
          label: this.$t('recommendation_title'),
          type: 'textarea',
          placeholder: this.$t('button_label_placeholder'),
          rows: 1,
          validate: ['required']
        },
        cart_title: {
          label: this.$t('cart_title'),
          type: 'textarea',
          placeholder: this.$t('button_label_placeholder'),
          rows: 1,
          validate: ['required']
        },
        unpaid_orders_title: {
          label: this.$t('unpaid_orders_title'),
          type: 'textarea',
          placeholder: this.$t('button_label_placeholder'),
          rows: 1,
          validate: ['required']
        },
        coupon_title: {
          label: this.$t('coupon_title'),
          type: 'textarea',
          placeholder: this.$t('button_label_placeholder'),
          rows: 1,
          validate: ['required']
        },
        index_page_type: {
          label: this.$t('index_page_type'),
          type: 'select_radio',
          options: TargetIndexPageType.options(
            TargetIndexPageType.fromCampaignType(this.campaign.campaign_type)
          ),
          superAdmin: true
        },
        product_recommendation_units: {
          label: this.$t('set_recommended_products'),
          disabled: !this.formObject.display_recommendation_items,
          validate: this.formObject.display_recommendation_items
            ? ['required']
            : []
        },
        display_brand_user_coupons: {
          type: 'checkbox',
          label: this.$t('display_brand_user_coupons'),
          description: this.$t('display_brand_user_coupons_description')
        },
        landing_page_css: {
          label: this.$t('landing_page_css'),
          type: 'code_editor',
          lang: 'css',
          superAdmin: true
        }
      };
    },
    formSections() {
      return [
        {
          id: 'landing_page_design',
          groups: this.basicGroupIds.map(id => {
            const { keywords, validate } = this.basicGroupConfigs[id];

            const newValidate = validate ? validate : [];
            if (keywords) newValidate.push({ rule: 'keywords', keywords });

            return {
              id,
              label: this.$t(id),
              validate: newValidate,
              ...this.basicGroupConfigs[id]
            };
          })
        },
        {
          id: 'advanced',
          label: this.$t('app.advanced_settings'),
          groups: this.advancedGroupIds.map(id => ({
            id,
            ...this.advancedGroupConfigs[id]
          }))
        }
      ];
    },
    landingPageUrl() {
      const params = qs.stringify({
        v: this.displayVersion,
        iframe: '1',
        parent_origin: location.origin
      });
      return `${this.formObject.landing_page_url}&${params}`;
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaign.id}/landing_page_design`)
      .then(({ data }) => (this.orgFormObject = data.campaign))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/target/campaigns/${this.campaign.id}/landing_page_design`,
          formData,
          { successMessage: this.$t('app.saved') }
        )
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    toKeywords(keys) {
      return keys.map(key => ({ key, desc: this.$t(key) }));
    },
    updatePreview() {
      const formData = new FormData(this.$el.getElementsByTagName('form')[0]);
      const version = this.currentVersion;
      api
        .patch(
          `/target/campaigns/${this.campaign.id}/landing_page_design/preview`,
          formData,
          { requestId: 'UPDATE_TARGET_LANDING_PAGE_PREVIEW' }
        )
        .then(() => (this.displayVersion = version));
    }
  }
};
</script>

<i18n locale="ko">
{
  "landing_page_design": "랜딩페이지 설정",
  "user": "고객명",
  "user_id": "고객아이디",
  "brand": "쇼핑몰명",
  "cart_items_count": "장바구니 상품 수",
  "cart_item": "장바구니 대표 상품명",
  "unpaid_orders_count": "무통장 미입금 주문 수",
  "days_before_inactivation": "휴면회원 전환 예정 일수",
  "login_url": "쇼핑몰 로그인페이지 URL",
  "coupon_description_html": "쿠폰/이벤트 안내 문구",
  "recommendation_description_html": "추천 상품 안내 문구",
  "design_title": "대표 문구",
  "design_title_placeholder": "대표 문구를 입력해주세요.",
  "design_description": "상세 문구",
  "design_description_placeholder": "상세 문구를 입력해주세요.",
  "cart_description_html": "장바구니 안내 문구",
  "popular_products_description_html": "인기 상품 안내 문구",
  "member_inactivation_prevention_description_html": "휴면 전환 안내 문구",
  "member_inactivation_prevention_button_label": "휴면 전환 방지 버튼 문구",
  "member_inactivation_prevention_button_url": "휴면 전환 방지 버튼 URL",
  "description_html_placeholder": "안내 문구를 입력해주세요.",
  "button_label_placeholder": "버튼 문구를 입력해주세요.",
  "button_url_placeholder": "버튼 클릭 시 이동할 URL을 입력해주세요.",
  "advanced_settings_info_html": "고급 설정은 세밀한 캠페인 사용을 위한 설정모음 입니다.<br />해당 설정을 잘 모르신다면 기본 설정 사용을 추천합니다.",
  "display_recommendation_items": "추천 상품 노출",
  "recommendation_title": "추천/인기 상품 탭 버튼 문구",
  "cart_title": "장바구니 탭 버튼 문구",
  "coupon_title": "쿠폰/이벤트 탭 버튼 문구",
  "unpaid_orders_title": "미입금 탭 버튼 문구",
  "index_page_type": "랜딩페이지 접속 시 기본 선택 탭",
  "set_recommended_products": "추천 상품 설정",
  "display_brand_user_coupons": "사용 가능한 쿠폰 정보 노출",
  "display_brand_user_coupons_description": "발송 시점에 사용 가능한 쿠폰이 존재할 때 쿠폰 정보를 노출합니다.",
  "landing_page_css": "이 페이지에만 적용할 CSS"
}
</i18n>
