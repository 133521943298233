<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{
      ...formProps,
      objectId: 'review_transaction',
      detectFormDataChange: false,
      submitLabel: $t('app.save'),
      submittingLabel: $t('app.saving')
    }"
    :submit-disabled="isPeriodBonusDisabled"
    :submit-disabled-tooltip="
      isPeriodBonusDisabled ? $t('bonus_period_submit_tooltip') : null
    "
    v-on="formEvents"
  >
    <input type="hidden" name="review_transaction[amount]" :value="amount" />
    <input
      type="hidden"
      name="review_transaction[description]"
      :value="description"
    />
    <input
      type="hidden"
      name="review_transaction[amount_type]"
      :value="amountType"
    />
  </AppModalForm>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapMutations, mapState } from 'vuex';
import api from '@/lib/api';
import ReviewTransactionType from '@/enums/ReviewTransactionType';
import ReviewBonusPeriod from '@/enums/ReviewBonusPeriod';
import ReviewAmountType from '@/enums/ReviewAmountType';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ThePaymentHistoryBonusReviewDialog',
  mixins: [DialogFormView],
  data() {
    const { reviewStatus } = this.$store.state.session;
    return {
      orgFormObject: {
        review_event_type: ReviewTransactionType.SMS_BONUS,
        expiry_date: reviewStatus?.review_expires_at
          ? moment(reviewStatus.review_expires_at).toVal()
          : null
      }
    };
  },
  computed: {
    ...mapState('session', ['reviewStatus']),
    ...mapGetters('session', ['currencyKey']),
    ReviewTransactionType() {
      return ReviewTransactionType;
    },
    formSections() {
      const transactionType = this.formObject.review_event_type;
      return [
        {
          id: 'default',
          groups: [
            {
              id: 'review_event_type',
              label: this.$t('review_transaction.review_event_type'),
              type: 'select',
              options: [
                ReviewTransactionType.SMS_BONUS,
                ReviewTransactionType.SMS_AMOUNT_BONUS,
                ReviewTransactionType.PERIOD_BONUS
              ].map(value => ({
                label: this.transactionTypeLabel(value),
                value
              }))
            },
            {
              id: 'description',
              name: null,
              label: this.$t('review_transaction.description'),
              type: 'text',
              placeholder: this.transactionTypeLabel(transactionType)
            },
            ...(transactionType === ReviewTransactionType.SMS_BONUS
              ? [
                  {
                    id: 'bonus_sms_count',
                    name: null,
                    label: this.$t('review_transaction.bonus_sms_count'),
                    digits: 9,
                    type: 'number',
                    validate: ['required']
                  }
                ]
              : transactionType === ReviewTransactionType.SMS_AMOUNT_BONUS
              ? [
                  {
                    id: 'bonus_sms_amount',
                    name: null,
                    label: this.$t('review_transaction.bonus_sms_amount'),
                    digits: 9,
                    type: 'currency',
                    allowNegative: true,
                    validate: ['required']
                  }
                ]
              : transactionType === ReviewTransactionType.PERIOD_BONUS
              ? [
                  {
                    id: 'bonus_period_amount',
                    name: null,
                    label: this.$t('review_transaction.bonus_period_amount'),
                    type: 'select',
                    placeholder: this.$t('bonus_period_amount_placeholder'),
                    hint: this.periodBonusExpiryDateHint,
                    options: ReviewBonusPeriod.options(),
                    validate: ['required']
                  }
                ]
              : [])
          ]
        }
      ];
    },
    amount() {
      switch (this.formObject.review_event_type) {
        case ReviewTransactionType.SMS_BONUS:
          return this.formObject.bonus_sms_count;
        case ReviewTransactionType.SMS_AMOUNT_BONUS:
          return this.formObject.bonus_sms_amount;
        case ReviewTransactionType.PERIOD_BONUS:
          return ReviewBonusPeriod.bonusAmount(
            this.formObject.bonus_period_amount
          );
      }

      return null;
    },
    amountUnit() {
      switch (this.formObject.review_event_type) {
        case ReviewTransactionType.SMS_BONUS:
          return 'unit.cases';
        case ReviewTransactionType.SMS_AMOUNT_BONUS:
          return this.currencyKey;
        case ReviewTransactionType.PERIOD_BONUS:
          return 'unit.days';
      }

      return null;
    },
    amountType() {
      if (
        this.formObject.review_event_type !==
          ReviewTransactionType.PERIOD_BONUS ||
        !this.formObject.bonus_period_amount
      )
        return ReviewAmountType.NONE;
      if (
        ReviewBonusPeriod.WEEK_PERIOD.includes(
          this.formObject.bonus_period_amount
        )
      )
        return ReviewAmountType.DAY;

      return ReviewAmountType.MONTH;
    },
    description() {
      const prefix =
        this.formObject.description ||
        this.transactionTypeLabel(this.formObject.review_event_type);
      const postfix =
        this.formObject.review_event_type == ReviewTransactionType.PERIOD_BONUS
          ? this.periodBonusDescription
          : this.amount
          ? ` (${this.$t(this.amountUnit, [this.amount])})`
          : '';
      return `${prefix}${postfix}`;
    },
    periodBonusExpiryDate() {
      if (this.amountType == ReviewAmountType.DAY) {
        return moment(this.reviewStatus.review_expires_at).add(
          'days',
          this.formObject.bonus_period_amount
        );
      } else {
        const startDate = moment(this.reviewStatus.review_expires_at).add(
          'days',
          1
        );
        let newExpireDate = moment(startDate).add('month', this.amount);
        if (startDate.date() === newExpireDate.date()) {
          newExpireDate = newExpireDate.subtract('days', 1);
        }
        return newExpireDate;
      }
    },
    periodBonusExpiryDateHint() {
      if (
        !this.orgFormObject.expiry_date ||
        !this.formObject.bonus_period_amount
      )
        return null;

      return this.$t('bonus_period_amount_hint', [
        this.formatDate(this.reviewStatus.review_expires_at),
        this.formatDate(this.periodBonusExpiryDate)
      ]);
    },
    periodBonusDescription() {
      return this.formObject.bonus_period_amount
        ? ` (${ReviewBonusPeriod.t(this.formObject.bonus_period_amount)})`
        : '';
    },
    isPeriodBonusDisabled() {
      return (
        this.formObject.review_event_type ===
          ReviewTransactionType.PERIOD_BONUS &&
        this.reviewStatus?.scheduled_review_expires_at
      );
    }
  },
  methods: {
    ...mapMutations('session', ['SET_REVIEW_STATUS']),
    transactionTypeLabel(transactionType) {
      return ReviewTransactionType.t(transactionType);
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/payment_history/review_transactions', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          if (data.review_status) this.SET_REVIEW_STATUS(data.review_status);
          this.eventBus.$emit('update', data);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "충전하기",
  "sub_title": "리뷰 이용내역",
  "expiry_date_should_be_after": "{0} 이후의 날짜를 지정해야 합니다.",
  "bonus_period_amount_placeholder": "충전할 기간을 입력하세요.",
  "bonus_period_amount_hint": "기존 만료일: {0} → 변경될 만료일: {1}",
  "bonus_period_submit_tooltip": "중도 환불 예약건을 삭제한 뒤 충전해주세요.",
  "review_transaction": {
    "review_event_type": "유형 선택",
    "description": "설명 입력",
    "bonus_sms_count": "충전 건수",
    "bonus_sms_amount": "충전 금액",
    "bonus_period_amount": "충전 기간",
    "expiry_date": "만료일 변경"
  }
}
</i18n>
