<template>
  <AppMultipleSelectCheckbox
    :name="name"
    :value="value"
    :enable-select-all="true"
    :select-all-label="$t('terms.label')"
    :enable-indent="true"
    :options="options"
    :invalid="invalid"
    @change="$emit('change', $event)"
  >
    <template #label="{ value }">
      <div>
        {{ $t(`terms.${value}`) }}
        <AppButton
          v-if="['use', 'privacy', 'outsource'].indexOf(value) !== -1"
          :label="$t('terms.link')"
          button-style="blue-clear"
          @click="openTerm(value)"
        />
      </div>
    </template>
    <template #partial-label="{ label }">
      <div v-if="label === $t('terms.marketing')">
        {{ label }}
        <AppButton
          :label="$t('terms.link')"
          button-style="blue-clear"
          @click="openTerm('marketing')"
        />
      </div>
      <div v-else>
        {{ label }}
      </div>
    </template>
  </AppMultipleSelectCheckbox>
</template>

<script>
export default {
  name: 'AppTermsAgreementCheckbox',
  model: { event: 'change' },
  props: {
    name: { type: String, required: true },
    value: { type: Array, default: null },
    invalid: { type: Boolean, required: true }
  },
  data() {
    return {
      options: [
        { value: 'age' },
        { value: 'use' },
        { value: 'privacy' },
        { value: 'outsource' },
        {
          value: null,
          label: this.$t('terms.marketing'),
          options: [
            { value: 'sms', label: this.$t('terms.sms') },
            { value: 'email', label: this.$t('terms.email') }
          ]
        }
      ]
    };
  },
  methods: {
    openTerm(termType) {
      const pdfUrl = {
        use: 'https://assets.cre.ma/m/pdf/crema_tos.pdf',
        privacy: 'https://assets.cre.ma/m/crema_privacy.pdf',
        outsource: 'https://assets.cre.ma/m/crema_entrustment.pdf',
        marketing: 'https://assets.cre.ma/m/crema_marketinguse.pdf'
      };
      window.open(pdfUrl[termType], '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

::v-deep {
  > ul > .AppRadioList__item:first-child {
    @include text-label;
  }
  .AppButton {
    vertical-align: unset;
  }
  .AppButton__button {
    border: none;
    padding: 0;
    margin-left: 8px;
  }
}
</style>
