<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic</label>
      </div>
      <div class="ui-section__item">
        <AppImage :src="SRC_BASIC" alt="basic" />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Background (Narrow)</label>
      </div>
      <div class="ui-section__item">
        <AppImage
          :src="SRC_BASIC"
          alt="background-narrow"
          background
          class="UiImage__background-narrow"
        />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Background (300x100)</label>
      </div>
      <div class="ui-section__item">
        <AppImage
          :src="SRC_BASIC"
          alt="background-tall"
          background
          class="UiImage__background-tall"
        />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="rounded">Rounded</label>
      </div>
      <div class="ui-section__item">
        <AppImage
          :src="SRC_BASIC"
          alt="background-tall"
          class="UiImage__square"
          type="rounded"
        />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="rounded">Rounded Modified</label>
      </div>
      <div class="ui-section__item">
        <AppImage
          :src="SRC_BASIC"
          alt="background-tall"
          class="UiImage__square"
          type="rounded"
          :border-radius="30"
        />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="circle">Circle</label>
      </div>
      <div class="ui-section__item">
        <AppImage
          :src="SRC_BASIC"
          alt="background-tall"
          class="UiImage__square"
          type="circle"
        />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="circle">Resolutions</label>
      </div>
      <div class="ui-section__item">
        <AppImage
          src="https://assets.cre.ma/m/admin/v2/onboarding-main-color.png"
          alt="resolutions"
          :resolutions="[2, 3]"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiImage',
  data() {
    return {
      SRC_BASIC:
        'https://assets1.cre.ma/p/cafe23-com/products/00/00/00/02/42/image/ca1a3d51e6331610.jpg'
    };
  }
};
</script>

<style scoped>
.UiImage__background-narrow {
  width: 100px;
  height: 300px;
}

.UiImage__background-tall {
  width: 300px;
  height: 100px;
}

.UiImage__square {
  width: 200px;
  height: 200px;
}
</style>
