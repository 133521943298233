<template>
  <div class="DisplayCornerProductOverlayData">
    <div
      v-if="isPinned || product.color_index === 0"
      class="DisplayCornerProductOverlayData__description"
    >
      <template v-if="isPinned">
        {{ $t('data.pinned') }}
        <div>{{ pinUntil }}</div>
      </template>
      <template v-else-if="product.color_index === 0">
        {{ $t('data.manually_displayed') }}
      </template>
      <div class="DisplayCornerProductOverlayData__detail">
        {{ $t('data.displayed_at', [formatDate(product.displayed_at) || '-']) }}
        <div>{{ $t('data.recent_clicks_count', [product.clicks_count]) }}</div>
      </div>
    </div>
    <div v-else class="DisplayCornerProductOverlayData__description">
      <div class="DisplayCornerProductOverlayData__description--item">
        {{ unitFilterType }}
        <div class="DisplayCornerProductOverlayData__detail">
          {{ filterTypeData }}
        </div>
      </div>
      <div class="DisplayCornerProductOverlayData__description--item">
        {{ unitSortType }}
        <div class="DisplayCornerProductOverlayData__detail">
          {{ sortTypeData }}
        </div>
      </div>
    </div>
    <div class="DisplayCornerProductOverlayData__stats">
      <div>{{ $t('data.recent_purchased_info.title') }}</div>
      <div class="DisplayCornerProductOverlayData__purchased-info">
        <i18n path="data.recent_purchased_info.content">
          <AppNumber :value="purchasedCount" />
          <AppNumber :value="product.purchase_rate * 100" :precision="1" />
        </i18n>
      </div>
      <AppMiniChart
        v-if="chartData.length > 0"
        :data="chartData"
        :show-point-value="false"
        :height="20"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import ShopBuilder from '@/enums/ShopBuilder';
import LogisticsSolutionType from '@/enums/LogisticsSolutionType';
import DisplayProductFilterType from '@/enums/DisplayProductFilterType';
import DisplayProductSortType from '@/enums/DisplayProductSortType';

export default {
  name: 'DisplayCornerProductOverlayData',
  props: {
    product: { type: Object, required: true }
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('displayProductDisplay', [
      'isPinnedProduct',
      'productPinUntil'
    ]),
    unitFilterType() {
      const { recent_days, filter_type } = this.product;

      const recentDaysText = recent_days
        ? this.$t('data.recent_days', [recent_days])
        : '';
      return recentDaysText + DisplayProductFilterType.t(filter_type);
    },
    filterTypeData() {
      const { currentBrand, formatPercentage, formatDate } = this;
      const { filter_type, filter_type_data } = this.product;

      let args = [];
      switch (filter_type) {
        case DisplayProductFilterType.SHOP_BUILDER_STOCK_COUNT:
          args = [ShopBuilder.tk(currentBrand.shop_builder), filter_type_data];
          break;
        case DisplayProductFilterType.LOGISTICS_STOCK_COUNT:
          args = [
            LogisticsSolutionType.tk(currentBrand.logistics_solution),
            filter_type_data
          ];
          break;
        case DisplayProductFilterType.SELECTED_PRODUCT_OPTION:
          args = [...filter_type_data];
          break;
        case DisplayProductFilterType.HIGHEST_PURCHASE_RATE:
          args = [formatPercentage(filter_type_data || 0)];
          break;
        case DisplayProductFilterType.CREATED:
        case DisplayProductFilterType.UPDATED:
        case DisplayProductFilterType.DISPLAYED:
        case DisplayProductFilterType.SOLD_OUT_TO_DISPLAY:
          args = [formatDate(filter_type_data)];
          break;
        default:
          args = [filter_type_data || 0];
      }

      return this.$t(
        `data.filter_type_data.${DisplayProductFilterType.key(filter_type)}`,
        args
      );
    },
    unitSortType() {
      const { product } = this;

      return (
        (DisplayProductSortType.isSortRecentDaysDisabled(product.sort_type)
          ? ''
          : this.$t('data.recent_days', [product.sort_recent_days])) +
        DisplayProductSortType.t(product.sort_type)
      );
    },
    sortTypeData() {
      const { formatPercentage, formatDate } = this;
      const { sort_type, sort_type_data } = this.product;

      let args = [];
      switch (sort_type) {
        case DisplayProductSortType.CREATED_DESC:
        case DisplayProductSortType.UPDATED_DESC:
        case DisplayProductSortType.DISPLAYED_DESC:
          args = [formatDate(sort_type_data)];
          break;
        case DisplayProductSortType.HIGHEST_PURCHASE_RATE:
          args = [formatPercentage(sort_type_data || 0)];
          break;
        default:
          args = [sort_type_data || 0];
      }

      return this.$t(
        `data.sort_type_data.${DisplayProductSortType.key(sort_type)}`,
        args
      );
    },
    chartData() {
      const { dates, purchased_counts, purchase_rates } = this.product;
      return dates.map((d, i) => ({
        y: purchased_counts[i],
        tooltipMessage: moment(d).format('YYYY.MM.DD'),
        tooltipInfo: this.$t('data.recent_purchased_info.content', [
          purchased_counts[i],
          this.formatPercentage(purchase_rates[i])
        ])
      }));
    },
    isPinned() {
      return this.isPinnedProduct(this.product);
    },
    pinUntil() {
      const productPinUntil = this.productPinUntil(this.product);

      return productPinUntil
        ? this.$t('data.pinned_until', [productPinUntil])
        : this.$t('data.pinned_unlimited');
    },
    purchasedCount() {
      return this.product.purchased_counts.reduce(
        (result, count) => result + count,
        0
      );
    }
  },
  methods: {
    formatPercentage(rate) {
      return (rate * 100).toLocaleString(undefined, {
        maximumFractionDigits: 1
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayCornerProductOverlayData {
  @include text-caption-small;

  color: #fff;
  margin-left: 8px;
  margin-right: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.DisplayCornerProductOverlayData__description {
  word-break: keep-all;

  &--item {
    margin-top: 4px;
  }
}

.DisplayCornerProductOverlayData__detail {
  color: $color-grey-40;
}

.DisplayCornerProductOverlayData__stats {
  padding-bottom: 10px;
}

.DisplayCornerProductOverlayData__purchased-info {
  @include text-content;

  color: #fff;
}
</style>

<i18n locale="ko">
{
  "data": {
    "recent_days": "{0}일간 ",
    "pinned": "상품 진열 고정됨",
    "pinned_unlimited": "무기한 고정",
    "pinned_until": "{0} 까지",
    "manually_displayed": "수동 진열",
    "recent_purchased_info": {
      "title": "최근 7일 판매량 (구매 전환율)",
      "content": "{0} ({1}%)"
    },
    "displayed_at": "노출일: {0}",
    "recent_clicks_count": "최근 7일 조회수: {0}",
    "filter_type_data": {
      "CREATED": "상품 등록일: {0}",
      "UPDATED": "상품 수정일: {0}",
      "DISPLAYED": "상품 노출일: {0}",
      "MOST_CLICKED": "조회수: {0}",
      "MOST_PURCHASED": "판매량: {0}",
      "HIGHEST_PURCHASED_PRICE": "누적 매출: {0}",
      "MOST_CARTED": "장바구니 담긴 수: {0}",
      "HIGHEST_PURCHASE_RATE": "구매 전환율: {0}%",
      "MOST_REVIEWED": "등록된 리뷰 수: {0}",
      "HIGHEST_REVIEW_SCORE": "리뷰 평점: {0}",
      "SHOP_BUILDER_STOCK_COUNT": "{0} 재고량: {1}",
      "LOGISTICS_STOCK_COUNT": "{0} 재고량: {1}",
      "SOLD_OUT_TO_DISPLAY": "재판매 변경일: {0}",
      "SELECTED_PRODUCT_OPTION": "{0} - {1} 포함"
    },
    "sort_type_data": {
      "CREATED_DESC": "상품 등록일: {0}",
      "UPDATED_DESC": "상품 수정일: {0}",
      "DISPLAYED_DESC": "상품 노출일: {0}",
      "MOST_CLICKED": "조회수: {0}",
      "MOST_PURCHASED": "판매량: {0}",
      "HIGHEST_PURCHASED_PRICE": "누적 매출: {0}",
      "MOST_CARTED": "장바구니 담긴 수: {0}",
      "HIGHEST_PURCHASE_RATE": "구매 전환율: {0}%",
      "MOST_REVIEWED": "등록된 리뷰 수: {0}",
      "HIGHEST_REVIEW_SCORE": "리뷰 평점: {0}"
    }
  }
}
</i18n>
