<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppTextInput v-model="valueBasic" />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Disabled</div>
      <AppTextInput v-model="valueDisabled" disabled />
      <div class="ui-section__description">
        Selected Value: {{ valueDisabled }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Invalid</div>
      <AppTextInput v-model="valueInvalid" invalid />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Readonly</div>
      <AppTextInput v-model="valueReadonly" readonly />
      <div class="ui-section__description">
        Selected Value: {{ valueReadonly }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Length</div>
      <AppTextInputWithLength v-model="valueLength" :maxlength="5" />
      <div class="ui-section__description">
        Selected Value: {{ valueLength }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Single Line</div>
      <AppSingleLineFields>
        <AppTextInput v-model="valueSingleLine" readonly />
        <AppButton label="Click" />
      </AppSingleLineFields>
      <div class="ui-section__description">
        Selected Value: {{ valueSingleLine }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTextInput',
  data() {
    return {
      valueBasic: '',
      valueDisabled: '',
      valueInvalid: '',
      valueReadonly: 'lorem ipsum',
      valueLength: '',
      valueSingleLine: ''
    };
  }
};
</script>
