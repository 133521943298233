import DisplayPageType from '@/enums/DisplayPageType';

const state = {
  productDisplays: [],
  cornerPageType: null,
  isPolling: false
};

const getters = {
  isCategoryPage: ({ cornerPageType }) => {
    return DisplayPageType.isCategoryPage(cornerPageType);
  },
  cornerPageTypeKey: ({ cornerPageType }) => {
    return DisplayPageType.key(cornerPageType);
  }
};

const mutations = {
  SET_PRODUCT_DISPLAYS: (state, productDisplays) =>
    (state.productDisplays = productDisplays),
  ADD_PRODUCT_DISPLAY: ({ productDisplays }, display) =>
    productDisplays.push(display),
  SET_PRODUCT_DISPLAY_NAME: (state, { id, name }) =>
    (state.productDisplays = state.productDisplays.map(display =>
      display.id === id ? { ...display, name } : display
    )),
  SET_PRODUCT_DISPLAY_CORNERS: (state, { id, corners }) =>
    (state.productDisplays = state.productDisplays.map(display =>
      display.id === id ? { ...display, corners } : display
    )),
  SET_CORNER_PAGE_TYPE(state, cornerPageType) {
    state.cornerPageType = cornerPageType;
  },
  SET_POLLING(state, isPolling) {
    state.isPolling = isPolling;
  }
};

export default { namespaced: true, state, getters, mutations };
