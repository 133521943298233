<template>
  <AppTable
    table-id="percentage-of-price-table"
    :columns="COLUMNS"
    :rows="rows"
    align="left"
  >
    <template #cell="{ value }">
      <template v-if="value && value.i18n">
        <i18n :path="value.i18n">
          <AppNumberInput
            :value="datum[value.key]"
            inline
            :digits="6"
            :default="0"
            :name="`${name}[${value.key}]`"
            :invalid="!!errors[`${datum.id}[${value.key}]`]"
            @change="change(value, $event)"
            @blur="validateField(value.key)"
          />
        </i18n>
        <AppFormError :error="errors[`${datum.id}[${value.key}]`]" />
      </template>
    </template>
  </AppTable>
</template>

<script>
export default {
  name: 'ReviewSettingsMileageAmountPercentageOfProductPriceTable',
  props: {
    datum: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      COLUMNS: ['text_review_mileage', 'photo_review_mileage'].map(id => ({
        id,
        label: this.$t(id)
      }))
    };
  },
  computed: {
    rows() {
      return [
        {
          text_review_mileage: {
            i18n: 'percentage_of_price',
            key: 'auto_mileage_text_review_percentage_of_product_price'
          },
          photo_review_mileage: {
            i18n: 'percentage_of_price',
            key: 'auto_mileage_photo_review_percentage_of_product_price'
          }
        }
      ];
    }
  },
  methods: {
    change({ key }, value) {
      this.$emit('change', { key, value });
      this.validateField(key);
    },
    validateField(key) {
      this.$emit('validate-field', `${this.datum.id}[${key}]`);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .percentage-of-price-table__text-review-mileage,
  .percentage-of-price-table__photo-review-mileage {
    width: 50%;
  }
}
</style>

<i18n locale="ko">
{
  "text_review_mileage": "텍스트 리뷰 지급액",
  "photo_review_mileage": "포토/동영상 리뷰 지급액",
  "percentage_of_price": "구매가의 {0}%"
}
</i18n>
<i18n locale="en">
{
  "percentage_of_price": "{0}% of purchase price"
}
</i18n>
