<template>
  <div class="SmartstoreImporting">
    <SmartstoreWaiting
      :is-waiting="isWaiting"
      :message="$t('message')"
      :button-props="buttonProps"
      @click="click"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SmartstoreStatus from '@/enums/SmartstoreStatus';
import SmartstoreWaiting from './SmartstoreWaiting';

export default {
  name: 'SmartstoreImporting',
  components: { SmartstoreWaiting },
  data() {
    return { isSubmitting: false };
  },
  computed: {
    ...mapState('smartstore', ['smartstoreStatus']),
    isWaiting() {
      return this.smartstoreStatus !== SmartstoreStatus.STARTER_IMPORT_FAILED;
    },
    buttonProps() {
      if (this.isWaiting) return;

      return {
        isSubmitting: this.isSubmitting,
        submitLabel: this.$t('submit_label'),
        submittingLabel: this.$t('submitting_label'),
        buttonStyle: 'default'
      };
    }
  },
  methods: {
    ...mapActions('smartstore', ['requestRetryImport']),
    click() {
      this.isSubmitting = true;
      this.requestRetryImport(this.$t('retried')).finally(
        () => (this.isSubmitting = false)
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "message": "결제한 리뷰를 저장하는 중입니다.",
  "submit_label": "리뷰 저장 다시 시도",
  "submitting_label": "리뷰 저장 다시 시도중..",
  "retried": "리뷰 저장을 다시 시도 요청했습니다."
}
</i18n>
