<template>
  <TheLoginView
    :title="$t('title')"
    :sub-title="''"
    :message="message"
    :is-loading="isLoading"
  />
</template>

<script>
import api from '@/lib/api';
import HttpStatus from '@/enums/HttpStatus';
import TheLoginView from './TheLoginView';

export default {
  name: 'TheShopbyAuth',
  components: { TheLoginView },
  data() {
    return {
      isLoading: true,
      message: this.$t('message.processing')
    };
  },
  mounted() {
    api
      .get('/shopby/auth/authorize', {
        params: { code: this.$route.query.code },
        silent: true
      })
      .then(({ data }) => {
        this.message = this.$t('message.success');

        if (data.idled) {
          window.location = '/v2/login?idle_brand';
        } else if (data.registered) {
          window.location = '/';
        } else {
          this.$router.push({
            path: '/easy-join',
            query: { shop_key: data.shop_key }
          });
        }
      })
      .catch(({ response }) => {
        this.isLoading = false;
        this.message = this.responseToErrorMessage(response);
      });
  },
  methods: {
    responseToErrorMessage({ status, data }) {
      const isAccountTaken = (status, data) =>
        status === HttpStatus.UNPROCESSABLE_ENTITY &&
        data?.errors?.[0]?.model === 'user' &&
        data?.errors?.[0]?.error_type === 'taken';

      if (status === HttpStatus.BAD_REQUEST)
        return this.$t('message.bad_request');
      else if (status === HttpStatus.FORBIDDEN)
        return this.$t('message.forbidden', {
          customer_support: this.$t('app.customer_support')
        });
      else if (isAccountTaken(status, data))
        return this.$t('message.account_taken', {
          email: data.errors[0].error_detail.value,
          customer_support: this.$t('app.customer_support')
        });
      else
        return this.$t('message.undefined', {
          customer_support: this.$t('app.customer_support')
        });
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "샵바이 인증",
  "message": {
    "processing": "인증 처리중입니다...",
    "success": "인증이 완료됐습니다. 다음으로 이동합니다...",
    "bad_request": "잘못된 접근입니다. 샵바이 관리자에서 앱을 실행해주세요.",
    "forbidden": "유효한 code가 아닙니다. 샵바이 관리자에서 앱을 다시 실행해주세요.\n문제가 계속되는 경우 크리마 고객센터로 문의해주세요:\n%{customer_support}",
    "account_taken": "'%{email}'로 이미 크리마에 가입되어 있습니다.\n샵바이 관리자에서 이메일을 변경하거나 크리마 고객센터로 문의해주세요:\n%{customer_support}",
    "undefined": "인증 처리 중 오류가 발생했습니다. 샵바이 관리자에서 앱을 다시 실행해주세요.\n문제가 계속되는 경우 크리마 고객센터로 문의해주세요:\n%{customer_support}"
  }
}
</i18n>
