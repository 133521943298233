<template>
  <div>
    <AppButton
      :label="$t('button.add_unit')"
      :disabled="cannotAddUnit"
      :tooltip="cannotAddUnit ? $t('tooltip.add_unit') : ''"
      :bubble-message="units.length > 0 ? '' : $t('bubble.add_unit')"
      @click="addUnit"
    />
    <AppTable
      class="DisplayProductManagementUnits__table"
      :columns="columns"
      :rows="rows"
    >
      <template #cell="{ column, row, rowIndex }">
        <template v-if="column.id === 'filter'">
          <div class="DisplayProductManagementUnits__filter">
            <span
              v-if="rowIndex > 0"
              class="DisplayProductManagementUnits__filter-prefix"
              >{{ $t('filter.or') }}</span
            >
            <DisplayUnitFilterCell
              class="DisplayProductManagementUnits__filter-inputs"
              v-bind="row.filterCellProps"
              @change="UPDATE_UNIT({ unit: $event, index: rowIndex })"
              @validate-unit-field="
                $emit('validate-unit-field', {
                  column: $event,
                  index: rowIndex
                })
              "
            />
          </div>
        </template>
        <template v-else-if="column.id === 'actions'">
          <AppButton
            :label="$t('app.delete')"
            :disabled="row.buttonDisabled"
            :tooltip="row.buttonDisabled ? disabledTooltip : ''"
            @click="DELETE_UNIT(rowIndex)"
          />
          <input
            v-for="(key, i) in [
              'id',
              'recent_days',
              'filter_type',
              'stock_count',
              'stock_compare_type',
              'product_option_type_id',
              'max_products'
            ]"
            :key="`key-${i}`"
            type="hidden"
            :name="`product_management[units][][${key}]`"
            :value="row.unit[key]"
          />
          <input
            v-if="row.unit.product_option_value"
            type="hidden"
            name="product_management[units][][product_option_value_id]"
            :value="row.unit.product_option_value.id"
          />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import DisplayProductFilterType from '@/enums/DisplayProductFilterType';
import ValueCompareType from '@/enums/ValueCompareType';
import DisplayProductManagementTemplateType from '@/enums/DisplayProductManagementTemplateType';

export default {
  name: 'DisplayProductManagementUnits',
  props: {
    useDisplayOption: { type: Boolean, required: true },
    productOptionTypes: { type: Array, required: true },
    errors: { type: Object, required: true }
  },
  computed: {
    ...mapState('displayUnits', ['units']),
    ...mapState('displayProductManagement', ['productManagement']),
    ...mapGetters('displayUnits', ['cannotAddUnit']),
    templateType() {
      return this.productManagement.template_type;
    },
    hasDefaultUnit() {
      return [
        DisplayProductManagementTemplateType.FAST_DELIVERY,
        DisplayProductManagementTemplateType.SELECTED_PRODUCT_OPTIONS
      ].includes(this.templateType);
    },
    columns() {
      return [
        {
          id: 'filter',
          label: this.$t('column.filter'),
          align: 'left'
        },
        {
          id: 'actions',
          label: this.$t('app.actions'),
          width: '100px'
        }
      ];
    },
    disabledTooltip() {
      return this.$t(
        `tooltip.delete_unit.${DisplayProductManagementTemplateType.key(
          this.templateType
        )}`
      );
    },
    rows() {
      return this.units.map((unit, index) => ({
        unit,
        filterCellProps: this.filterCellProps(unit, index),
        buttonDisabled: index === 0 && this.hasDefaultUnit
      }));
    }
  },
  methods: {
    ...mapMutations('displayUnits', ['ADD_UNIT', 'UPDATE_UNIT', 'DELETE_UNIT']),
    addUnit() {
      this.ADD_UNIT({
        recent_days: 3,
        filter_type: DisplayProductFilterType.DISPLAYED,
        stock_count: 3,
        stock_compare_type: ValueCompareType.GTE,
        max_products: 10
      });
    },
    filterCellProps(unit, index) {
      const props = {
        unit,
        unitIndex: index,
        useDisplayOption: this.useDisplayOption,
        productOptionTypes: this.productOptionTypes,
        errors: this.errors
      };

      if (index === 0) {
        switch (this.templateType) {
          case DisplayProductManagementTemplateType.FAST_DELIVERY:
            return {
              ...props,
              availableFilterTypes: DisplayProductFilterType.stockFilterTypes
            };
          case DisplayProductManagementTemplateType.SELECTED_PRODUCT_OPTIONS:
            return {
              ...props,
              availableFilterTypes: [
                DisplayProductFilterType.SELECTED_PRODUCT_OPTION
              ]
            };
        }
      }

      return props;
    }
  }
};
</script>

<style scoped>
.DisplayProductManagementUnits__table {
  margin-top: 12px;
}

.DisplayProductManagementUnits__filter {
  display: flex;
  align-items: center;
}

.DisplayProductManagementUnits__filter-prefix {
  margin-right: 4px;
  white-space: nowrap;
}

.DisplayProductManagementUnits__filter-inputs {
  display: inline-block;
}
</style>

<i18n locale="ko">
{
  "button": {
    "add_unit": "+ 조건 추가"
  },
  "column": {
    "filter": "선정 조건"
  },
  "tooltip": {
    "add_unit": "최대 7개의 상품 선정 조건만 추가 할 수 있습니다.",
    "delete_unit": {
      "FAST_DELIVERY": "재고량 기준 조건은 필수조건입니다.",
      "SELECTED_PRODUCT_OPTIONS": "상품 옵션 조건은 필수조건입니다."
    }
  },
  "bubble": {
    "add_unit": "상품 선정 조건을 추가해 보세요!"
  },
  "filter": {
    "or": "또는"
  }
}
</i18n>
