import { constructEnum } from '@/lib/enum';

export default constructEnum('TargetBillingStatus', {
  LEGACY: 0,
  CONFIGURATION_REQUIRED: 100,
  PREPARE_PAYMENT: 200,
  PAYMENT_PREPARED: 300,
  OPERATING: 400,
  SPECIAL_PACKAGE_PUBLISHED: 500
});
