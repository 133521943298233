<template>
  <div class="SummaryBoxWelcome">
    <AppSummaryBox v-if="isImwebBrand">
      <template #title>{{ $t('imweb.title') }}</template>
      <div>{{ $t('imweb.content') }}</div>
      <i18n class="SummaryBoxWelcome__content-inquiry" path="imweb.inquiry">
        <AppExternalLink
          class="SummaryBoxWelcome__content-link"
          :to="imwebInquiryLink"
        >
          <span>{{ $t('imweb.link') }}</span>
        </AppExternalLink>
      </i18n>
    </AppSummaryBox>
    <AppSummaryBox v-else>
      <template #title>
        {{ $t('welcome_title', { user_name: currentUser.name }) }}
      </template>
      {{ $t('welcome_content') }}
    </AppSummaryBox>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'SummaryBoxWelcome',
  computed: {
    ...mapState('session', ['currentUser']),
    ...mapGetters('session', ['isImwebBrand']),
    imwebInquiryLink() {
      return 'https://imweb.me/faq?mode=view&category=29&category2=47&idx=72184';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.SummaryBoxWelcome__content-inquiry {
  display: block;
  color: $color-grey-60;
  margin-top: 4px;
}

.SummaryBoxWelcome__content-link {
  color: $color-brand-main;
  text-decoration: underline;
}
</style>

<i18n locale="ko">
{
  "welcome_title" : "{user_name}님, 환영합니다. 🎉",
  "welcome_content":"쇼핑몰에 크리마 리뷰를 사용할 수 있도록 사용 준비를 진행해주세요.",
  "imweb": {
    "title": "🙌 크리마 리뷰 설치를 환영합니다!",
    "content": "다음 안내에 따라 기본 설정 및 위젯 설치를 완료해 주세요.",
    "inquiry": "설정 및 위젯 설치에 대한 질문이 있거나 무료 체험 시작 후 문제가 생긴 경우 {0}를 참고해주세요.",
    "link": "가이드 문서"
  }
}
</i18n>
