<template>
  <div
    :class="[
      'DisplayCornerItemIndexColorBox',
      `DisplayCornerItemIndexColorBox--${color}`
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DisplayCornerItemIndexColorBox',
  props: {
    colorIndex: { type: Number, required: true, validator: v => v <= 7 }
  },
  computed: {
    color() {
      const colors = [
        'grey',
        'red',
        'flamingo',
        'mustard',
        'green-darker',
        'blue',
        'blue-darker',
        'red-light'
      ];

      return colors[this.colorIndex];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayCornerItemIndexColorBox {
  @include text-label;

  width: 28px;
  height: 28px;
  border-radius: 3px;
  color: $color-background;
  text-align: center;
  vertical-align: center;
  line-height: 28px;

  &--grey {
    background-color: $color-grey-75;
  }

  &--red {
    background-color: $color-red;
  }

  &--flamingo {
    background-color: $color-flamingo;
  }

  &--mustard {
    background-color: $color-mustard;
  }

  &--green-darker {
    background-color: $color-green-darker;
  }

  &--blue {
    background-color: $color-blue;
  }

  &--blue-darker {
    background-color: $color-blue-darker;
  }

  &--red-light {
    background-color: $color-red-light;
  }
}
</style>
