import _ from 'lodash';

const state = { thirdParties: [] };

const mutations = {
  SET_THIRD_PARTIES(state, thirdParties) {
    state.thirdParties = thirdParties;
  }
};

const getters = {
  thirdPartyNameMap({ thirdParties }) {
    return thirdParties
      ? _.chain(thirdParties)
          .keyBy('id')
          .mapValues('name')
          .value()
      : {};
  }
};

export default { namespaced: true, state, mutations, getters };
