<template>
  <AnalyticsInsightKeywordsSummary
    v-bind="{
      title,
      dateRange,
      summary
    }"
  >
    <AppTable
      table-id="keywords-details-table"
      :columns="COLUMNS"
      :rows="rows"
      :no-data="$t('insight.no_data')"
      :class="[
        'AnalyticsInsightDashboardKeywordsDetails',
        { 'AnalyticsInsightDashboardKeywordsDetails__no-data': hasNoData }
      ]"
    >
      <template #cell="{ row, column }">
        <template v-if="column.id !== 'rank'">
          <span v-tooltip="row[`${column.id}_detail`]">
            {{ row[column.id] }}
          </span>
        </template>
      </template>
    </AppTable>
  </AnalyticsInsightKeywordsSummary>
</template>

<script>
import InsightKeywordsSortType from '@/enums/InsightKeywordsSortType';
import InsightKeywordsView from '@/mixins/InsightKeywordsView';

export default {
  name: 'AnalyticsInsightDashboardKeywordsDetails',
  mixins: [InsightKeywordsView],
  props: {
    title: { type: String, required: true },
    dateRange: { type: Object, required: true },
    details: { type: Array, required: true }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'rank', label: this.$t('keyword_rank') },
        ...InsightKeywordsSortType.values.map(v => {
          const key = InsightKeywordsSortType.key(v);
          return {
            id: key.toLowerCase(),
            label: InsightKeywordsSortType.t(v),
            tooltip: this.$t(`keyword_sort_type_tooltip.${key.toLowerCase()}`)
          };
        })
      ]
    };
  },
  computed: {
    summary() {
      return this.keywordsSummary(this.details);
    },
    hasNoData() {
      return Object.values(this.summary).every(v => !v);
    },
    rows() {
      if (this.hasNoData) return [];

      const top3T = this.top3TotalKeywords(this.details);
      const top3P = this.top3PositiveKeywords(this.details);
      const top3N = this.top3NegativeKeywords(this.details);

      const top3TNames = top3T.map(k => k.review_tag_type_name);
      const top3PNames = top3P.map(k => k.review_tag_type_name);
      const top3NNames = top3N.map(k => k.review_tag_type_name);

      const sumT = this.summary.sentiments_sum;
      const sumP = this.summary.psentiments_count;
      const sumN = this.summary.nsentiments_count;

      const top3TPercentages = top3T.map(k =>
        this.toRatioString(this.percentage(k.sentiments_sum, sumT))
      );
      const top3PPercentages = top3P.map(k =>
        this.toRatioString(this.percentage(k.psentiments_count, sumP))
      );
      const top3NPercentages = top3N.map(k =>
        this.toRatioString(this.percentage(k.nsentiments_count, sumN))
      );

      const top3TCounts = top3T.map(k =>
        this.$t('unit.count', [k.sentiments_sum])
      );
      const top3PCounts = top3P.map(k =>
        this.$t('unit.count', [k.psentiments_count])
      );
      const top3NCounts = top3N.map(k =>
        this.$t('unit.count', [k.nsentiments_count])
      );

      return [0, 1, 2].map(i => ({
        rank: i + 1,
        total: top3TNames[i] || '-',
        total_detail: `${top3TNames[i]} ${top3TPercentages[i]}(${top3TCounts[i]})`,
        positive: top3PNames[i] || '-',
        positive_detail: `${top3PNames[i]} ${top3PPercentages[i]}(${top3PCounts[i]})`,
        negative: top3NNames[i] || '-',
        negative_detail: `${top3NNames[i]} ${top3NPercentages[i]}(${top3NCounts[i]})`
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightDashboardKeywordsDetails {
  margin-top: 32px;
}

.AnalyticsInsightDashboardKeywordsDetails__no-data {
  margin-bottom: 62px;
}

::v-deep {
  .keywords-details-table__rank {
    flex: 1 0 40px;
  }

  .keywords-details-table__total,
  .keywords-details-table__positive,
  .keywords-details-table__negative {
    flex: 3 0 123px;
  }

  .AppTable__head-col-icon {
    color: $color-grey-40;
  }
}
</style>

<i18n locale="ko">
{
  "keyword_rank": "순위",
  "keyword_sort_type_tooltip": {
    "total": "리뷰에서 가장 많이 언급된 키워드로, 고객이 가장 중요하게 생각하는 키워드를 의미합니다.",
    "positive": "리뷰에서 긍정적으로 가장 많이 언급된 키워드로, 비율이 높을수록 강점으로 해석할 수 있습니다.",
    "negative": "리뷰에서 부정적으로 가장 많이 언급된 키워드로, 비율이 높을수록 약점으로 해석할 수 있습니다."
  }
}
</i18n>
