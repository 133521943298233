var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'TheMain super-admin',
    {
      'TheMain--sub-menu-visible-desktop':
        _vm.isSubMenuVisibleDesktop && _vm.selectedSubMenuItemId,
      'TheMain--sub-menu-visible-tablet':
        _vm.isSubMenuVisibleTablet && _vm.selectedSubMenuItemId,
      'super-admin--hide': !_vm.enableSuperAdminMode,
      'super-admin--highlight': _vm.enableSuperAdminHighlight,
      'TheMain--with-main-alert': !!_vm.mainAlert
    }
  ]},[_c('TheMainAlert'),_c('div',{staticClass:"TheMain__main-menu"},[_c('TheMainMenu')],1),(_vm.selectedSubMenuItemId)?_c('div',{directives:[{name:"skip-transition-on-window-resize",rawName:"v-skip-transition-on-window-resize"}],staticClass:"TheMain__sub-menu"},[_c('div',{staticClass:"TheMain__sub-menu-head"},[_c('button',{staticClass:"TheMain__close-sub-menu",attrs:{"type":"button"},on:{"click":_vm.toggleSubMenu}},[_c('AppSvgIcon',{attrs:{"name":"icon-close"}})],1)]),_c('TheSubMenu',{on:{"click":_vm.clickSubMenu}})],1):_vm._e(),_c('div',{directives:[{name:"skip-transition-on-window-resize",rawName:"v-skip-transition-on-window-resize"}],staticClass:"TheMain__main"},[_c('div',{staticClass:"TheMain__main-head"},[_c('TheMainTopbar',{on:{"toggle-sub-menu":_vm.toggleSubMenu}}),_c('TheContentAlerts')],1),_c('div',{ref:"body",staticClass:"TheMain__main-body",attrs:{"id":"main-body"}},[_c('div',{staticClass:"TheMain__main-body-inner"},[_c('TheContent')],1),_c('TheFooter',{staticClass:"TheMain__main-footer"})],1),_c('TheFloater')],1),_c('div',{staticClass:"TheMain__jobs"},[_c('TheAsyncJobs'),_c('TheMultiAsyncJobs')],1),_c('TheDialogs'),_c('TheDrawers'),_c('AppScriptLoader',{attrs:{"type":_vm.selectedMainMenuItemId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }