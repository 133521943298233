var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"BizMessageTemplateButtons"},[(!_vm.readonly)?_c('AppButton',{attrs:{"label":_vm.$t('add_button'),"disabled":_vm.disabled || 5 <= _vm.buttons.length},on:{"click":function($event){return _vm.$emit('add')}}}):_vm._e(),_c('AppTable',{class:[
      'mt8',
      { 'BizMessageTemplateButtons__table--disabled': _vm.disabled }
    ],attrs:{"columns":_vm.columns,"rows":_vm.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
    var column = ref.column;
    var rowIndex = ref.rowIndex;
return [(column.id === 'actions')?[_c('AppButtonDraggable',_vm._b({attrs:{"drag-text":_vm.$t('app.sort'),"disabled":_vm.disabled},on:{"sort":function($event){return _vm.$emit('sort', $event)}}},'AppButtonDraggable',_vm.sortButtonProps(rowIndex),false)),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"menu-items":[
            {
              label: _vm.$t('app.edit'),
              clickHandler: function (ref) {
                  var close = ref.close;

                  return _vm.$emit('edit', { close: close, rowIndex: rowIndex });
    }
            },
            {
              label: _vm.$t('app.delete'),
              style: 'danger',
              clickHandler: function (ref) {
                    var close = ref.close;

                    return _vm.$emit('delete', { close: close, rowIndex: rowIndex });
    }
            }
          ],"disabled":_vm.disabled}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }