import Vue from 'vue';

const state = {
  isAdvancedSectionVisible: false,
  advancedGroupVisibleMap: {}
};

const mutations = {
  SET_IS_ADVANCED_SECTION_VISIBLE(state, isAdvancedSectionVisible) {
    state.isAdvancedSectionVisible = isAdvancedSectionVisible;
  },
  SET_ADVANCED_GROUP_VISIBLE(state, { sectionId, visible }) {
    Vue.set(state.advancedGroupVisibleMap, sectionId, visible);
  }
};

const actions = {
  setAdvancedSettingVisible({ commit }, hash) {
    const sectionId = hash.split('--')?.[0];
    if (!sectionId) return;

    if (sectionId === 'advanced')
      commit('SET_IS_ADVANCED_SECTION_VISIBLE', true);
    else {
      commit('SET_ADVANCED_GROUP_VISIBLE', {
        sectionId,
        visible: true
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
