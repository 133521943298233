<template>
  <div>
    <AppAjaxContent :is-loading="isLoading">
      <AppResourceTable
        :rows="cohortItems.items"
        :resources="cohortItems"
        :columns="
          ['id', 'user', 'status'].map(id => ({
            id,
            label: $t(`table.${id}`)
          }))
        "
        @paginate="paginate"
      >
        <template #cell="{ row, column }">
          <template v-if="column === 'user'">
            <div v-if="row.name">{{ row.name }}</div>
            <div v-if="row.username">{{ row.username }}</div>
            <div v-if="row.phone">{{ row.phone }}</div>
          </template>
          <template v-else-if="column === 'status'">
            <ul class="badges">
              <li
                v-for="{ dispatch_method, status } in row.statuses"
                :key="dispatch_method"
              >
                <TargetReportsDispatchResultDialogTableStatusBadge
                  :status="status"
                  :dispatch-method="dispatch_method"
                />
              </li>
            </ul>
          </template>
        </template>
      </AppResourceTable>
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'TargetReportsDispatchResultDialogTableAll',
  mixins: [ResourceViewNoRoute],
  props: { cohort: { type: Object, required: true } },
  data: () => ({ isLoading: true, cohortItems: nullResources }),
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/target/cohorts/${this.cohort.id}/items`, { params })
        .then(({ data }) => (this.cohortItems = data.cohort_items))
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .AppResourceTableCell {
  flex: 1 0 120px;
}
</style>

<i18n locale="ko">
{
  "table": {
    "id": "ID",
    "user": "고객 정보",
    "status": "발송 결과"
  }
}
</i18n>
