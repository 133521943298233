<template>
  <div>
    <div class="table-head-control">
      <AppButton
        :label="$t('add_recommendation_unit.label')"
        :disabled="disabled || isMaxReached"
        :tooltip="
          isMaxReached
            ? $t('add_recommendation_unit.tooltip', [maxUnitsCount])
            : null
        "
        @click="clickAddRecommendationUnit"
      />
    </div>
    <input
      v-if="!recommendedProducts.length"
      type="hidden"
      name="campaign[product_recommendation_units][]"
      value=""
    />
    <AppTable
      :columns="[
        { id: 'title', label: $t('title'), align: 'left', width: '144px' },
        { id: 'max_products', label: $t('max_products'), width: '52px' },
        { id: 'actions', label: $t('actions'), width: '150px' }
      ]"
      :rows="recommendedProducts"
      :class="[
        disabled
          ? 'TargetDmCampaignsRecommendedProducts__table--disabled'
          : null
      ]"
    >
      <template #cell="{ column, row, rowIndex }">
        <template v-if="column.id === 'actions'">
          <input
            v-for="(key, i) in [
              'id',
              'title',
              'max_products',
              'product_recommendation_method',
              'popular_filter_type',
              'in_categories_filter_type1',
              'in_categories_filter_type2',
              'personalized_filter_type1',
              'personalized_filter_type2',
              'personalized_filter_type3'
            ]"
            :key="`key-${i}`"
            type="hidden"
            :name="`campaign[product_recommendation_units][][${key}]`"
            :value="row[key]"
          />
          <AppHiddenInputArray
            name="campaign[product_recommendation_units][][category_ids][]"
            :values="row.category_ids"
          />
          <AppHiddenInputArray
            name="campaign[product_recommendation_units][][hand_picked_product_ids][]"
            :values="row.hand_picked_products.map(p => p.id)"
          />
          <AppButtonDraggable
            :drag-text="row.title"
            :disabled="disabled"
            @sort="sortProductRecommendationUnit"
          />
          <AppDropdownMenuButton
            :label="$t('app.manage')"
            :disabled="disabled"
            :menu-items="[
              {
                label: $t('app.settings'),
                clickHandler: e => clickEditRecommendationUnit(e, row, rowIndex)
              },
              {
                label: $t('app.delete'),
                style: 'danger',
                clickHandler: e => clickDeleteRecommendationUnit(e, rowIndex)
              }
            ]"
          />
        </template>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { arrayMoveIndex } from '@/lib/array';

export default {
  name: 'TargetDmCampaignsRecommendedProducts',
  model: { prop: 'recommendedProducts', event: 'change' },
  props: {
    disabled: { type: Boolean, default: false },
    recommendedProducts: { type: Array, required: true }
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    maxUnitsCount: () => 6,
    isMaxReached() {
      return (
        !this.isSuperAdminAccessible &&
        this.recommendedProducts.length >= this.maxUnitsCount
      );
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    clickAddRecommendationUnit() {
      this.openDialog(['TargetDmCampaignsRecommendedProductsFormDialog']).then(
        eventBus => {
          eventBus.$on('change', data =>
            this.change([...this.recommendedProducts, data])
          );
        }
      );
    },
    clickEditRecommendationUnit(
      { close },
      productRecommendationUnit,
      rowIndex
    ) {
      this.openDialog([
        'TargetDmCampaignsRecommendedProductsFormDialog',
        { productRecommendationUnit }
      ]).then(eventBus => {
        eventBus.$on('change', data =>
          this.change(
            this.recommendedProducts.map((product, i) =>
              i === rowIndex ? data : product
            )
          )
        );
      });
      close();
    },
    clickDeleteRecommendationUnit({ close }, rowIndex) {
      if (!confirm(this.$t('app.delete_confirm'))) return;
      close();
      this.$nextTick(() =>
        this.change(this.recommendedProducts.filter((p, i) => i !== rowIndex))
      );
    },
    sortProductRecommendationUnit({ srcIndex, dstIndex }) {
      this.change(arrayMoveIndex(this.recommendedProducts, srcIndex, dstIndex));
    },
    change(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TargetDmCampaignsRecommendedProducts__table--disabled {
  border-color: $color-grey-25;
  color: $color-disable-text;
}
</style>

<i18n locale="ko">
{
  "add_recommendation_unit": {
    "label": "+추천 항목 추가",
    "tooltip": "추천 항목은 최대 {0}개까지만 추가 가능합니다."
  },
  "title": "추천 항목 타이틀",
  "max_products": "상품수",
  "actions": "기능"
}
</i18n>
