var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'AppKeywordTextarea',
    { 'AppKeywordTextarea--multi-line': !_vm.singleLine }
  ]},[(!_vm.disabled)?_c('span',{staticClass:"AppKeywordTextarea__show-keywords-dialog",on:{"click":function($event){_vm.isShowingKeywordsDialog = !_vm.isShowingKeywordsDialog}}},[_vm._v(_vm._s(_vm.$t('show_available_keywords')))]):_vm._e(),(_vm.isShowingKeywordsDialog)?_c('AppModeless',{attrs:{"align":"right"},on:{"close":_vm.closeKeywordsDialog}},[_c('AppDropdownMenu',{attrs:{"menu-items":_vm.menuItems},on:{"close":_vm.closeKeywordsDialog}})],1):_vm._e(),_c('div',{class:[
      'AppKeywordTextarea__input',
      { 'AppKeywordTextarea__input--disabled': _vm.disabled },
      { 'AppKeywordTextarea__input--invalid': _vm.invalid }
    ]},[_c('AppCodeMirror',_vm._b({ref:"editor",on:{"ready":function($event){_vm.codeMirror = $event},"change":_vm.change,"before-change":_vm.beforeChange}},'AppCodeMirror',{ id: _vm.id, name: _vm.name, value: _vm.value, disabled: _vm.disabled, options: _vm.options },false)),(_vm.placeholder && !_vm.value)?_c('div',{staticClass:"AppKeywordTextarea__placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e()],1),(_vm.maxBytes)?_c('div',{staticClass:"AppKeywordTextarea__bytes"},[_vm._v(" "+_vm._s((_vm.byteSize + "/" + _vm.maxBytes + " bytes"))+" ")]):(_vm.maxlength)?_c('div',{staticClass:"AppKeywordTextarea__length"},[_vm._v(" "+_vm._s((_vm.length + "/" + _vm.maxlength))+" ")]):_vm._e(),_c('AppTooltip',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isShowingTooltip),expression:"isShowingTooltip"}],ref:"tooltip"},'AppTooltip',_vm.tooltipProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }