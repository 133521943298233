import { constructEnum } from '@/lib/enum';
import BrandSyncType from '@/enums/BrandSyncType';

const ManualSyncStatus = constructEnum('ManualSyncStatus', {
  IDLE: 0,
  READY: 10,
  IN_PROGRESS: 20,
  CALCULATING_TARGET_DATA: 30,
  COMPLETED: 100,
  FAILED: 1000,
  EXPORT_FAILED: 1010,
  IMPORT_FAILED: 1020,
  CALCULATION_FAILED: 1030
});

ManualSyncStatus.FAILED_STATUSES = [
  ManualSyncStatus.FAILED,
  ManualSyncStatus.EXPORT_FAILED,
  ManualSyncStatus.IMPORT_FAILED,
  ManualSyncStatus.CALCULATION_FAILED
];

ManualSyncStatus.FINISHED_STATUSES = [
  ManualSyncStatus.COMPLETED,
  ...ManualSyncStatus.FAILED_STATUSES
];

ManualSyncStatus.progressStatuses = function(brandSyncType) {
  switch (brandSyncType) {
    case BrandSyncType.PRODUCT_DATA:
      return [
        ManualSyncStatus.READY,
        ManualSyncStatus.IN_PROGRESS,
        ManualSyncStatus.CALCULATING_TARGET_DATA
      ];
    default:
      return [ManualSyncStatus.READY, ManualSyncStatus.IN_PROGRESS];
  }
};

ManualSyncStatus.progressInfo = function(brandSyncType, manualSyncStatus) {
  const progressStatuses = ManualSyncStatus.progressStatuses(brandSyncType);
  const totalCount = progressStatuses.length + 1;

  if (ManualSyncStatus.FINISHED_STATUSES.includes(manualSyncStatus))
    return { totalCount, progressCount: totalCount };
  else
    return {
      totalCount,
      progressCount: progressStatuses.indexOf(manualSyncStatus) + 1
    };
};

ManualSyncStatus.isFailed = function(manualSyncStatus) {
  return ManualSyncStatus.FAILED_STATUSES.includes(manualSyncStatus);
};

export default ManualSyncStatus;
