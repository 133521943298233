import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';

export default {
  props: {
    downloadExcelUrlBase: { type: String, default: null },
    reports: { type: Array, required: true },
    reportsRange: { type: Object, required: true },
    reportsStatus: { type: Object, default: null },
    resourceParams: { type: Object, required: true },
    pagination: {
      type: Object,
      default: () => ({ page: 1, per: 20 })
    }
  },
  computed: {
    searchBarProps() {
      const { start_date } = this.resourceParams;
      const downloadExcelUrl = this.downloadExcelUrlBase
        ? `${this.downloadExcelUrlBase}?${qs.stringify(this.resourceParams)}`
        : null;
      return {
        downloadExcelUrl,
        startDate: start_date === 'none' ? null : start_date,
        endDate: this.resourceParams.end_date,
        interval: this.resourceParams.interval
      };
    },
    searchBarEvents() {
      return {
        'change-date-range': this.changeDateRange,
        'change-interval': this.changeInterval
      };
    },
    pointInterval() {
      return moment.duration(1, this.unit).asMilliseconds();
    },
    unit() {
      switch (this.reportsRange.interval) {
        case 'daily':
          return 'day';
        case 'weekly':
          return 'week';
        default:
          return 'month';
      }
    },
    pointStart() {
      return this.dateStart.unix() * 1000;
    },
    dateStart() {
      let { start_date } = this.reportsRange;
      if (start_date === 'none')
        start_date = this.reports.length
          ? this.reports[0].date
          : this.reportsRange.end_date;
      return moment.utc(start_date, 'YYYY-MM-DD').startOf(this.unit);
    },
    dateEnd() {
      return moment
        .utc(this.reportsRange.end_date, 'YYYY-MM-DD')
        .startOf(this.unit);
    },
    reportsMap() {
      return _.keyBy(this.reports, 'date');
    },
    reportsChart() {
      return this.reportsByDateRange(this.dateStart, this.dateEnd);
    },
    reportsTable() {
      const { per, page } = this.pagination;
      const dateEnd = this.dateEnd
        .clone()
        .subtract(per * (page - 1), this.unit);
      const dateStartPage = dateEnd.clone().subtract(per - 1, this.unit);
      const dateStart =
        this.dateStart.diff(dateStartPage) > 0 ? this.dateStart : dateStartPage;
      return this.reportsByDateRange(dateStart, dateEnd).reverse();
    },
    resources() {
      return {
        ...this.pagination,
        total_count:
          this.reportsStatus === null || this.reportsStatus.data_ready
            ? this.dateEnd.diff(this.dateStart, this.unit) + 1
            : 0
      };
    },
    defaultEndDate() {
      return moment()
        .subtract(1, 'day')
        .toVal();
    }
  },
  methods: {
    changeDateRange(dateRange) {
      dateRange = {
        start_date: dateRange.start_date || 'none',
        end_date: dateRange.end_date || this.defaultEndDate
      };
      if (
        dateRange.start_date === this.reportsRange.start_date &&
        dateRange.end_date === this.reportsRange.end_date
      )
        return;
      this.$emit('change-resource-params', dateRange);
    },
    changeInterval(interval) {
      if (interval === this.reportsRange.interval) return;
      this.$emit('change-resource-params', { interval });
    },
    searchResource() {
      this.$emit('search-resource');
    },
    paginate(pagination) {
      this.$emit('change-pagination', pagination);
    },
    getFormattedDate(date) {
      const {
        formatDate,
        formatDateWithWday,
        formatMonthWithYear
      } = this.$options.filters;

      if (this.reportsRange.interval === 'daily')
        return formatDateWithWday(date);
      else if (this.reportsRange.interval === 'weekly') {
        return `${formatDate(date)} ~\n${formatDate(this.getLastDay(date))}`;
      } else return formatMonthWithYear(date);
    },
    getLastDay(date) {
      if (this.reportsRange.interval === 'daily') return date;
      else if (this.reportsRange.interval === 'weekly') {
        return moment(date)
          .add(6, 'day')
          .toVal();
      } else {
        return moment(date)
          .add(1, 'month')
          .subtract(1, 'day')
          .toVal();
      }
    },
    reportsByDateRange(start, end) {
      const reports = [];
      for (const m = start.clone(); m.diff(end) <= 0; m.add(1, this.unit)) {
        const date = m.toVal();
        reports.push(this.reportsMap[date] || { date });
      }
      return reports;
    },
    chartData(itemName, type = 'integer') {
      if (this.reportsStatus !== null && !this.reportsStatus.data_ready)
        return [];

      let obj = {};
      switch (type) {
        case 'integer':
          obj = { multiplier: 1, precision: 0 };
          break;
        case 'float':
          obj = { multiplier: 1, precision: 2 };
          break;
        case 'percentage':
          obj = { multiplier: 100, precision: 2 };
          break;
        default:
      }
      const { multiplier, precision } = obj;
      return this.reportsChart.map(item => {
        if (!item) {
          return 0;
        }
        return [
          moment.utc(item['date']).unix() * 1000,
          parseFloat(((item[itemName] || 0) * multiplier).toFixed(precision))
        ];
      });
    }
  }
};
