var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppContainer',{staticClass:"TargetReportsCampaignSectionChart"},[_c('AppSearchbar',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('li',[_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t(("chart_type." + _vm.chartType)),"menu-items":_vm.menuItems,"button-style":"label"}})],1)]},proxy:true},{key:"right",fn:function(){return [_c('li',[_c('AppSelectButtonInterval',{on:{"change":_vm.changeInterval},model:{value:(_vm.resourceParams.interval),callback:function ($$v) {_vm.$set(_vm.resourceParams, "interval", $$v)},expression:"resourceParams.interval"}})],1)]},proxy:true}])}),_c('AppAjaxContent',{staticClass:"TargetReportsCampaignSectionChart__body",attrs:{"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('TargetReportsTotalReportsChart',_vm._b({},'TargetReportsTotalReportsChart',{
          resourceParams: _vm.resourceParams,
          chartHeight: _vm.chartHeight,
          seriesColumns: _vm.seriesColumns,
          yAxis: _vm.yAxis,
          reports: _vm.reports,
          reportsRange: _vm.reportsRange
        },false))]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }