<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppContainer>
      <AppForm
        v-bind="formProps"
        object-id="third_party_review_csv"
        :is-submitting="isSubmitting"
        :submit-button="{
          submitLabel: $t('app.upload'),
          submittingLabel: $t('app.uploading')
        }"
        v-on="formEvents"
      />
    </AppContainer>
    <AppResourceTable
      table-id="review-third-party-review-csvs-table"
      :table-name="$t('review_third_party_review_csv.upload_result')"
      :resources="third_party_review_csvs"
      :columns="COLUMNS"
      :rows="rows"
      @paginate="paginate"
    >
      <template #cell="{ column, value, row }">
        <AppBadge
          v-if="column === 'brandCsvStatus'"
          :badge-style="dataStatusBadgeStyle(value)"
          :label="BrandCsvStatus.t(value)"
        />
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BrandCsvStatus from '@/enums/BrandCsvStatus';
import BrandCsvType from '@/enums/BrandCsvType';
import FormView from '@/mixins/FormView';
import ResourceView from '@/mixins/ResourceView';

export default {
  name: 'ReviewThirdPartyReviewCsvs',
  mixins: [FormView, ResourceView],
  data() {
    return {
      COLUMNS: [
        { id: 'thirdPartyReviewCsvId', label: 'ID' },
        {
          id: 'timestamp',
          label: this.$t('review_third_party_review_csv.timestamp'),
          type: 'pre'
        },
        {
          id: 'brandCsvStatus',
          label: this.$t('review_third_party_review_csv.status')
        }
      ]
    };
  },
  computed: {
    ...mapState('reviewThirdPartyReviewCsv', ['third_party_review_csvs']),
    ...mapGetters('reviewThirdPartyReviewCsv', [
      'isFetchingThirdPartyReviewCsvs'
    ]),
    BrandCsvStatus: () => BrandCsvStatus,
    isLoading() {
      return (
        this.third_party_review_csvs.isNull ||
        this.isFetchingThirdPartyReviewCsvs
      );
    },
    rows() {
      const { formatDateTime } = this.$options.filters;
      const rows = this.third_party_review_csvs.items.map(
        third_party_review_csv => ({
          thirdPartyReviewCsvId: third_party_review_csv.id,
          timestamp: [
            `${this.$t(
              'review_third_party_review_csv.registered_at'
            )}: ${formatDateTime(third_party_review_csv.created_at)}`,
            `${this.$t(
              'review_third_party_review_csv.finished_at'
            )}: ${formatDateTime(third_party_review_csv.created_at)}`
          ].join('\n'),
          brandCsvStatus: third_party_review_csv.brand_csv_status
        })
      );
      return rows;
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'brand_csv_type',
              type: 'select',
              label: this.$t('local.third_party_review_csv_type_input_title'),
              placeholder: this.$t(
                'local.third_party_review_csv_type_placeholder'
              ),
              options: BrandCsvType.options(
                BrandCsvType.THIRD_PARTY_REVIEW_CSVS
              ),
              validate: ['required']
            },
            {
              id: 'file',
              type: 'file',
              label: this.$t(
                'local.third_party_review_csv_upload_file_input_title'
              ),
              placeholder: this.$t(
                'local.third_party_review_csv_upload_file_input_placeholder'
              ),
              accept: '.csv',
              validate: ['required']
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions('reviewThirdPartyReviewCsv', [
      'fetchThirdPartyReviewCsvs',
      'createThirdPartyReviewCsv'
    ]),
    fetchResource(params) {
      this.fetchThirdPartyReviewCsvs(params);
    },
    dataStatusBadgeStyle(dataStatus) {
      switch (dataStatus) {
        case BrandCsvStatus.IMPORTED:
          return 'mint-green';
        case BrandCsvStatus.IMPORT_FAILED:
          return 'red';
        default:
          return 'default';
      }
    },
    submit(formData) {
      this.isSubmitting = true;
      this.createThirdPartyReviewCsv({
        formData,
        onComplete: () => (this.isSubmitting = false)
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "local": {
    "third_party_review_csv_type_input_title": "CSV 파일 종류",
    "third_party_review_csv_type_placeholder": "리뷰 앱 선택",
    "third_party_review_csv_upload_file_input_title": "CSV 파일 업로드",
    "third_party_review_csv_upload_file_input_placeholder": "업로드할 CSV 파일을 등록해주세요."
  }
}
</i18n>
