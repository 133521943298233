<template>
  <AppChart
    :options="options"
    :tooltip="tooltip"
    @tooltip="$emit('tooltip', $event)"
  />
</template>

<script>
export default {
  name: 'AppChartColumn',
  props: {
    series: { type: Array, required: true },
    tooltip: { type: Object, default: undefined }
  },
  computed: {
    options() {
      return {
        chart: { height: 160, spacing: [0, 0, 0, 0], type: 'column' },
        exporting: false,
        legend: {
          enabled: true,
          align: 'right',
          verticalAlign: 'top',
          padding: 2,
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 0
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            pointWidth: 20,
            events: {
              legendItemClick: function() {
                return false;
              }
            }
          }
        },
        title: { text: '' },
        tooltip: { enabled: false },
        xAxis: { type: 'category' },
        yAxis: { labels: { enabled: false }, title: { enabled: false } },
        series: this.series
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

::v-deep {
  .highcharts-color-0 {
    fill: $color-mustard;
    stroke: $color-mustard;
  }

  .highcharts-color-1 {
    fill: $color-mustard-light;
    stroke: $color-mustard-light;
  }

  .highcharts-xaxis {
    display: none;
  }

  .highcharts-legend-item.highcharts-column-series {
    cursor: auto;
  }

  .highcharts-legend-item.highcharts-column-series > text {
    cursor: auto;
    fill: $color-grey-60;
  }

  .highcharts-column-series:not(.highcharts-legend-item) rect.highcharts-point {
    stroke-width: 0;
  }
}
</style>
