var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'AppImage',
    {
      'AppImage--visible': _vm.isReady,
      'AppImage--background': _vm.background,
      'AppImage--opacity-transition': _vm.enableOpacityTransition
    }
  ],style:(Object.assign({}, _vm.backgroundImageStyle,
    _vm.styleProp))},[_c('img',{ref:"image",class:[
      'AppImage__img',
      { 'AppImage__img--square': _vm.square },
      { 'AppImage__img--original-ratio': _vm.originalRatio }
    ],style:(_vm.rotation ? { transform: ("rotate(" + _vm.rotation + "deg)") } : null),attrs:{"src":_vm.src,"alt":_vm.alt,"srcset":_vm.srcset},on:{"load":_vm.load,"error":function($event){return _vm.$emit('error')}}})])}
var staticRenderFns = []

export { render, staticRenderFns }