import { render, staticRenderFns } from "./UiImage.vue?vue&type=template&id=909a83fe&scoped=true&"
import script from "./UiImage.vue?vue&type=script&lang=js&"
export * from "./UiImage.vue?vue&type=script&lang=js&"
import style0 from "./UiImage.vue?vue&type=style&index=0&id=909a83fe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "909a83fe",
  null
  
)

export default component.exports