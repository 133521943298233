<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm
      class="TheAdvancedSettingsDialogShopbyExternalScripts__form"
      :submit-button="{
        buttonStyle: 'red'
      }"
      v-bind="formProps"
      v-on="formEvents"
    >
      <template #group="{ id }">
        <AppButton
          class="TheAdvancedSettingsDialogShopbyExternalScripts__fetch-button"
          :label="$t('fetch_external_script')"
          @click="fetchExternalScript(id)"
        />
        <AppCodeEditor
          :id="id"
          v-model="formObject[id]"
          :name="id"
          class="AppForm__group-field"
          lang="htmlmixed"
        />
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';

export default {
  name: 'TheAdvancedSettingsDialogShopbyExternalScripts',
  mixins: [FormView],
  data() {
    return {
      isLoading: false,
      orgFormObject: { external_script_pc: null, external_script_mobile: null }
    };
  },
  computed: {
    formSections() {
      return [
        {
          id: 'shopby_external_scripts',
          label: this.$t('title'),
          groups: [
            {
              id: 'external_script_pc',
              label: this.$t('external_script_pc')
            },
            {
              id: 'external_script_mobile',
              label: this.$t('external_script_mobile')
            }
          ]
        }
      ];
    }
  },
  methods: {
    fetchExternalScript(inputId) {
      this.isLoading = true;
      api
        .get('admin/shopby_external_scripts', {
          params: {
            device_type: inputId === 'external_script_pc' ? 'PC' : 'MOBILE'
          }
        })
        .then(({ data }) => {
          this.orgFormObject[inputId] = data[inputId];
          this.formObject = { ...this.formObject, [inputId]: data[inputId] };
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submit() {
      const data = this.changedExternalScripts(
        this.orgFormObject,
        this.formObject
      );

      this.isSubmitting = true;
      api
        .patch('admin/shopby_external_scripts', data, {
          successMessage: this.$t('app.saved')
        })
        .then(() => (this.orgFormObject = { ...this.formObject }))
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    changedExternalScripts(original, current) {
      const data = {};
      if (original.external_script_pc !== current.external_script_pc)
        data.external_script_pc = current.external_script_pc;
      if (original.external_script_mobile !== current.external_script_mobile)
        data.external_script_mobile = current.external_script_mobile;
      return data;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.TheAdvancedSettingsDialogShopbyExternalScripts__form {
  width: 680px;
  border-radius: 4px;
  margin: 16px auto 32px auto;
  padding: 24px;
  background-color: #fff;
}

.TheAdvancedSettingsDialogShopbyExternalScripts__fetch-button {
  margin: 4px 0;
}

::v-deep {
  .AppForm__section-title {
    color: $color-red;
  }
}
</style>

<i18n locale="ko">
  {
    "title": "샵바이 init.js 스크립트 관리",
    "fetch_external_script": "스크립트 불러오기",
    "external_script_pc": "init.js 스크립트(PC)",
    "external_script_mobile": "init.js 스크립트(모바일)"
  }
  </i18n>
