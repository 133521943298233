import _ from 'lodash';

const isMenuItemAccessibleForInstallationAgency = (item, getters) => {
  // TODO refactor - 조건이 너무 복잡해서 무슨 의미인지는 정확히 모르겠지만 이전 코드가 이러하다..
  if (
    !getters.isReviewAccessible &&
    (!getters.isReviewSettingsAccessible || !item.installationAgency) &&
    item.id.startsWith('review_') &&
    item.id !== 'review_intro'
  )
    return false;

  if (item.superAdmin && !item.installationAgency) return false;

  return !item.isAccessible || item.isAccessible(getters);
};

const isMenuItemAccessible = (item, { isSuperAdminAccessible, getters }) => {
  if (item.superAdmin && !isSuperAdminAccessible) return false;

  // TODO move to isAccessible after removing depedendency for isCurrentUserInstallAgency
  if (
    !getters.isReviewAccessible &&
    item.id.startsWith('review_') &&
    item.id !== 'review_intro'
  )
    return false;

  return !item.isAccessible || item.isAccessible(getters);
};

const propagateSuperAdmin = items => {
  const itemsMap = _.keyBy(items, 'id');

  const ancestorIds = itemId => {
    if (!itemId) return [];

    const item = itemsMap[itemId];
    return [itemId, ...ancestorIds(item.parentId)];
  };

  return items.map(item => {
    if (ancestorIds(item.parentId).some(id => itemsMap[id].superAdmin))
      return { ...item, superAdmin: true };
    else return item;
  });
};

export {
  isMenuItemAccessible,
  isMenuItemAccessibleForInstallationAgency,
  propagateSuperAdmin
};
