<template>
  <AppDataSection :title="title" gap="wide">
    <AppAjaxContent
      v-if="!isUnderContruction"
      :is-loading="isLoading"
      height="262px"
    >
      <template #default>
        <AppDataSectionItem :title="purchaseCountTitle">
          <AppNumber
            :value="latestPurchaseCount"
            unit="cases"
            class="AppDataSectionItem__content"
          />
          <AppChartColumn
            class="TargetCampaignStatsSectionPurchase__chart"
            :series="chartSeries"
            :tooltip="chartTooltip"
            @tooltip="onChartTooltip"
          />
        </AppDataSectionItem>
      </template>
    </AppAjaxContent>
  </AppDataSection>
</template>

<script>
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';

export default {
  name: 'TargetCampaignStatsSectionPurchase',
  props: {
    campaign: { type: Object, required: true },
    samplingMethod: { type: Number, required: true },
    isLoading: { type: Boolean, required: true },
    reports: { type: Array, required: true }
  },
  data: () => ({ chartTooltip: null }),
  computed: {
    campaignType() {
      return this.campaign.campaign_type;
    },
    isUnderContruction() {
      return ![
        TargetCampaignType.NEW_VISITOR_SIGNUP,
        TargetCampaignType.NEW_MEMBER_REENGAGEMENT,
        TargetCampaignType.CART_REMINDING,
        TargetCampaignType.UNPAID_ORDER_REMINDING,
        TargetCampaignType.MILEAGE_EXPIRING,
        TargetCampaignType.COUPON_EXPIRY,
        TargetCampaignType.INDUCING_REPURCHASE,
        TargetCampaignType.INTERESTED_PRODUCTS,
        TargetCampaignType.SELECTED_PRODUCTS,
        TargetCampaignType.SELECTED_CATEGORIES,
        TargetCampaignType.UTM_POPUP,
        TargetCampaignType.MEMBER_INACTIVATION_PREVENTION,
        TargetCampaignType.INACTIVE_MEMBER_REENGAGEMENT,
        TargetCampaignType.PRODUCT_RECOMMENDATION_DETAIL_PAGE,
        TargetCampaignType.PRODUCT_RECOMMENDATION_CATEGORY_PAGE,
        TargetCampaignType.PRODUCT_RECOMMENDATION_MAIN_PAGE,
        TargetCampaignType.PRODUCT_RECOMMENDATION_CART_PAGE,
        TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT,
        TargetCampaignType.PRODUCT_RECOMMENDATION_CART_POPUP
      ].includes(this.campaignType);
    },
    title() {
      return this.isUnderContruction
        ? this.$t('app.under_construction')
        : this.campaignText('title');
    },
    purchaseCountTitle() {
      const month = this.formatMonth(new Date());
      return this.campaignText('purchase_count_title', [month]);
    },
    chartSeries() {
      switch (this.campaignType) {
        case TargetCampaignType.NEW_VISITOR_SIGNUP:
        case TargetCampaignType.NEW_MEMBER_REENGAGEMENT:
        case TargetCampaignType.MILEAGE_EXPIRING:
        case TargetCampaignType.COUPON_EXPIRY:
        case TargetCampaignType.INDUCING_REPURCHASE:
        case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
        case TargetCampaignType.INACTIVE_MEMBER_REENGAGEMENT:
        case TargetCampaignType.UTM_POPUP:
          return [
            {
              name: this.campaignText('purchased_orders_count.legend'),
              data: this.chartData.map(datum => [
                this.formatMonth(datum.date),
                datum.purchased_orders_count
              ])
            }
          ];
        case TargetCampaignType.INTERESTED_PRODUCTS:
        case TargetCampaignType.CART_REMINDING:
          return [
            {
              name: this.campaignText('effected_purchased_orders_count.legend'),
              data: this.chartData.map(datum => [
                this.formatMonth(datum.date),
                datum.purchased_orders_count - datum.etc_purchased_orders_count
              ]),
              colorIndex: 0,
              legendTooltip: this.campaignText(
                'effected_purchased_orders_count.legend_tooltip'
              )
            },
            {
              name: this.campaignText('etc_purchased_orders_count.legend'),
              data: this.chartData.map(datum => [
                this.formatMonth(datum.date),
                datum.etc_purchased_orders_count
              ]),
              colorIndex: 1
            }
          ];
        case TargetCampaignType.SELECTED_PRODUCTS:
        case TargetCampaignType.SELECTED_CATEGORIES:
          return [
            {
              name: this.campaignText(
                'selection_purchased_orders_count.legend'
              ),
              data: this.chartData.map(
                ({ date, selection_purchased_orders_count }) => [
                  this.formatMonth(date),
                  selection_purchased_orders_count
                ]
              ),
              colorIndex: 0,
              legendTooltip: this.campaignText(
                'selection_purchased_orders_count.legend_tooltip'
              )
            },
            {
              name: this.campaignText('etc_purchased_orders_count.legend'),
              data: this.chartData.map(
                ({
                  date,
                  purchased_orders_count,
                  selection_purchased_orders_count
                }) => [
                  this.formatMonth(date),
                  purchased_orders_count - selection_purchased_orders_count
                ]
              ),
              colorIndex: 1
            }
          ];
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
          return [
            {
              name: this.campaignText('purchased_orders_count.legend'),
              data: this.chartData.map(datum => [
                this.formatMonth(datum.date),
                datum.purchased_orders_count
              ]),
              colorIndex: 0
            },
            {
              name: this.campaignText('etc_purchased_orders_count.legend'),
              data: this.chartData.map(datum => [
                this.formatMonth(datum.date),
                datum.etc_purchased_orders_count
              ]),
              colorIndex: 1
            }
          ];
        case TargetCampaignType.PRODUCT_RECOMMENDATION_DETAIL_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_CATEGORY_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_MAIN_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_POPUP:
          if (this.isPurchasedOrdersCountZero)
            return [
              {
                name: this.campaignText(
                  'only_etc_purchased_orders_count.legend'
                ),
                data: this.chartData.map(datum => [
                  this.formatMonth(datum.date),
                  datum.etc_purchased_orders_count
                ]),
                colorIndex: 0
              }
            ];
          else
            return [
              {
                name: this.campaignText(
                  'effected_purchased_orders_count.legend'
                ),
                data: this.chartData.map(datum => [
                  this.formatMonth(datum.date),
                  datum.purchased_orders_count -
                    datum.etc_purchased_orders_count
                ]),
                colorIndex: 0,
                legendTooltip: this.campaignText(
                  'effected_purchased_orders_count.legend_tooltip'
                )
              },
              {
                name: this.campaignText('etc_purchased_orders_count.legend'),
                data: this.chartData.map(datum => [
                  this.formatMonth(datum.date),
                  datum.etc_purchased_orders_count
                ]),
                colorIndex: 1
              }
            ];
        default:
          return [];
      }
    },
    isPurchasedOrdersCountZero() {
      return (
        this.reports.reduce(
          (result, { purchased_orders_count }) =>
            result + purchased_orders_count,
          0
        ) === 0
      );
    },
    latestPurchaseCount() {
      if (this.campaignType == TargetCampaignType.UNPAID_ORDER_REMINDING)
        return (
          this.reports[0].purchased_orders_count +
          this.reports[0].etc_purchased_orders_count
        );

      return this.reports[0].purchased_orders_count;
    },
    chartData() {
      return [...this.reports].reverse();
    },
    userCountColumn() {
      return this.samplingMethod === TargetSamplingMethod.POPUP_CLICKED
        ? 'unique_click_count'
        : 'unique_check_count';
    }
  },
  methods: {
    campaignText(key, interpolations) {
      return this.$store.getters['targetCampaigns/campaignText'](
        this.campaign,
        `purchase.${key}`,
        interpolations,
        this.samplingMethod
      );
    },
    onChartTooltip({ index }) {
      const report = this.chartData[index];
      this.chartTooltip = {
        title: this.formatMonthWithYear(report.date),
        items: this.chartItems(report)
      };
    },
    chartItems(report) {
      switch (this.campaignType) {
        case TargetCampaignType.NEW_MEMBER_REENGAGEMENT:
        case TargetCampaignType.MEMBER_INACTIVATION_PREVENTION:
        case TargetCampaignType.INACTIVE_MEMBER_REENGAGEMENT:
        case TargetCampaignType.NEW_VISITOR_SIGNUP:
          return [
            {
              name: this.campaignText('purchased_orders_count.tooltip'),
              value: this.$t('unit.cases', [report.purchased_orders_count]),
              symbol: 'square'
            },
            {
              name: this.campaignText('revenue.tooltip'),
              value: this.formatCurrency(report.revenue)
            },
            {
              name: this.campaignText('respondents_count.tooltip'),
              value: this.$t('unit.people', [report.respondents_count])
            }
          ];
        case TargetCampaignType.COUPON_EXPIRY:
        case TargetCampaignType.MILEAGE_EXPIRING:
        case TargetCampaignType.INDUCING_REPURCHASE:
        case TargetCampaignType.UTM_POPUP:
          return [
            {
              name: this.campaignText('purchased_orders_count.tooltip'),
              value: this.$t('unit.cases', [report.purchased_orders_count]),
              symbol: 'square'
            },
            {
              name: this.campaignText('revenue.tooltip'),
              value: this.formatCurrency(report.revenue)
            },
            {
              name: this.campaignText(`${this.userCountColumn}.tooltip`),
              value: this.$t('unit.people', [report[this.userCountColumn]])
            }
          ];
        case TargetCampaignType.INTERESTED_PRODUCTS:
        case TargetCampaignType.CART_REMINDING:
          return [
            {
              name: this.campaignText(
                'effected_purchased_orders_count.tooltip'
              ),
              value: this.$t('unit.cases', [
                report.purchased_orders_count -
                  report.etc_purchased_orders_count
              ]),
              symbol: 'square',
              colorIndex: 0
            },
            {
              name: this.campaignText('etc_purchased_orders_count.tooltip'),
              value: this.$t('unit.cases', [report.etc_purchased_orders_count]),
              symbol: 'square',
              colorIndex: 1
            },
            {
              name: this.campaignText('revenue.tooltip'),
              value: this.formatCurrency(report.revenue)
            },
            {
              name: this.campaignText(`${this.userCountColumn}.tooltip`),
              value: this.$t('unit.people', [report[this.userCountColumn]])
            }
          ];
        case TargetCampaignType.SELECTED_PRODUCTS:
        case TargetCampaignType.SELECTED_CATEGORIES:
          return [
            {
              name: this.campaignText(
                'selection_purchased_orders_count.tooltip'
              ),
              value: this.$t('unit.cases', [
                report.selection_purchased_orders_count
              ]),
              symbol: 'square',
              colorIndex: 0
            },
            {
              name: this.campaignText('etc_purchased_orders_count.tooltip'),
              value: this.$t('unit.cases', [
                report.purchased_orders_count -
                  report.selection_purchased_orders_count
              ]),
              symbol: 'square',
              colorIndex: 1
            },
            {
              name: this.campaignText('revenue.tooltip'),
              value: this.formatCurrency(report.revenue)
            },
            {
              name: this.campaignText(`${this.userCountColumn}.tooltip`),
              value: this.$t('unit.people', [report[this.userCountColumn]])
            }
          ];
        case TargetCampaignType.UNPAID_ORDER_REMINDING:
          return [
            {
              name: this.campaignText('purchased_orders_count.tooltip'),
              value: this.$t('unit.cases', [report.purchased_orders_count]),
              symbol: 'square',
              colorIndex: 0
            },
            {
              name: this.campaignText('etc_purchased_orders_count.tooltip'),
              value: this.$t('unit.cases', [report.etc_purchased_orders_count]),
              symbol: 'square',
              colorIndex: 1
            },
            {
              name: this.campaignText('revenue.tooltip'),
              value: this.formatCurrency(report.revenue)
            },
            {
              name: this.campaignText('purchased_users_count.tooltip'),
              value: this.$t('unit.people', [report.purchased_users_count])
            }
          ];
        case TargetCampaignType.PRODUCT_RECOMMENDATION_DETAIL_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_CATEGORY_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_MAIN_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_PAGE:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT:
        case TargetCampaignType.PRODUCT_RECOMMENDATION_CART_POPUP:
          return [
            ...(this.isPurchasedOrdersCountZero
              ? [
                  {
                    name: this.campaignText(
                      'only_etc_purchased_orders_count.tooltip'
                    ),
                    value: this.$t('unit.cases', [
                      report.etc_purchased_orders_count
                    ]),
                    symbol: 'square',
                    colorIndex: 0
                  }
                ]
              : [
                  {
                    name: this.campaignText(
                      'effected_purchased_orders_count.tooltip'
                    ),
                    value: this.$t('unit.cases', [
                      report.purchased_orders_count -
                        report.etc_purchased_orders_count
                    ]),
                    symbol: 'square',
                    colorIndex: 0
                  },
                  {
                    name: this.campaignText(
                      'etc_purchased_orders_count.tooltip'
                    ),
                    value: this.$t('unit.cases', [
                      report.etc_purchased_orders_count
                    ]),
                    symbol: 'square',
                    colorIndex: 1
                  }
                ]),
            {
              name: this.campaignText('revenue.tooltip'),
              value: this.formatCurrency(report.revenue)
            },
            {
              name: this.campaignText('unique_click_count.tooltip'),
              value: this.$t('unit.people', [report.unique_click_count])
            }
          ];
        default:
          return [];
      }
    }
  }
};
</script>

<style scoped>
.TargetCampaignStatsSectionPurchase__chart {
  padding-top: 40px;
}
</style>
