<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar>
      <li>
        <AppButton
          :label="$t('button.new_fit_template')"
          button-style="red"
          @click="editFitTemplate(null)"
        />
      </li>
    </AppSearchbar>
    <AppResourceTable
      table-id="fit-template-table"
      :columns="columns"
      :rows="rows"
      enable-query
      @change-query="setQuery"
    >
      <template #cell="{ column, value }">
        <template v-if="column == 'fitProductsCount'">
          <AppButton
            v-if="value.products_count > 0"
            type="link"
            :label="$t('show_product', { count: value.products_count })"
            :to="fitProductsPath(value.id)"
          />
          <span v-else class="fit-templates__no-product">{{
            $t('no_product')
          }}</span>
        </template>
        <template v-else-if="column === 'actions'">
          <AppButtonDraggable
            class="FitTemplates__action-button"
            :drag-text="value.fitTemplate.name"
            :disabled="!!query"
            :tooltip="query ? $t('cannot_sort_when_filtered') : undefined"
            @sort="sort"
          />
          <AppButton
            class="FitTemplates__action-button"
            :label="value.isCloning ? $t('cloning') : $t('app.clone')"
            :disabled="value.isCloning"
            @click="clone(value.fitTemplate)"
          />
          <AppButton
            class="FitTemplates__action-button"
            :label="$t('app.edit')"
            @click="editFitTemplate(value.fitTemplate)"
          />
          <AppButton
            class="FitTemplates__action-button"
            button-style="red-outline"
            :label="$t('app.delete')"
            :disabled="value.fitTemplate.products_count > 0"
            :tooltip="
              value.fitTemplate.products_count > 0
                ? $t('cannot_delete_template')
                : null
            "
            @click="deleteFitTemplate(value.fitTemplate)"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { arrayMoveIndex } from '@/lib/array';
import { filterItemsWithHighlightedText } from '@/lib/text-search';
import { mapState, mapGetters, mapActions } from 'vuex';
import AppResourceTable from '@/components/AppResourceTable';

export default {
  name: 'FitTemplates',
  components: { AppResourceTable },
  data() {
    return {
      columns: [
        { id: 'name', label: this.$t('table_head.name'), type: 'html' },
        {
          id: 'fitProductsCount',
          label: this.$t('table_head.products_count')
        },
        { id: 'actions', label: this.$t('app.actions') }
      ],
      query: '',
      isCloningFitTemplatesMap: {},
      isLoading: true
    };
  },
  computed: {
    ...mapState('fitTemplate', ['fitTemplates']),
    ...mapGetters('fitTemplate', ['isFetchingTemplates']),
    ...mapGetters('menu', ['menuItem']),
    filteredFitTemplates() {
      return filterItemsWithHighlightedText(
        this.fitTemplates,
        'name',
        this.query
      );
    },
    rows() {
      return this.filteredFitTemplates.map(fitTemplate => ({
        id: fitTemplate.id,
        name: fitTemplate.nameHighlighted,
        fitProductsCount: fitTemplate,
        actions: {
          fitTemplate,
          isCloning: this.isCloningFitTemplatesMap[fitTemplate.id]
        }
      }));
    }
  },
  watch: {
    isFetchingTemplates() {
      this.isLoading = this.isFetchingTemplates;
    }
  },
  created() {
    this.fetchFitTemplates();
  },
  beforeDestroy() {
    this.resetFitTemplates();
  },
  methods: {
    ...mapActions('fitTemplate', [
      'fetchFitTemplates',
      'resetFitTemplates',
      'sortFitTemplates',
      'cloneFitTemplate'
    ]),
    ...mapActions('dialog', ['openDialog']),
    setQuery(query) {
      this.query = query;
    },
    editFitTemplate(fitTemplate) {
      this.openDialog([
        'FitTemplateEditDialog',
        fitTemplate
          ? { fitTemplateId: fitTemplate.id, fitTemplateName: fitTemplate.name }
          : {}
      ]);
    },
    deleteFitTemplate(fitTemplate) {
      if (confirm(this.$t('app.delete_confirm'))) {
        this.$store.dispatch('fitTemplate/deleteFitTemplate', fitTemplate);
      }
    },
    sort({ srcIndex, dstIndex }) {
      const sortedTemplates = arrayMoveIndex(
        this.fitTemplates,
        srcIndex,
        dstIndex
      );
      this.sortFitTemplates(sortedTemplates.map(r => r.id));
    },
    clone(fitTemplate) {
      if (confirm(this.$t('confirm_clone'))) {
        this.$set(this.isCloningFitTemplatesMap, fitTemplate.id, true);
        this.cloneFitTemplate({
          fitTemplate,
          onComplete: () =>
            this.$delete(this.isCloningFitTemplatesMap, fitTemplate.id)
        });
      }
    },
    fitProductsPath(fitTemplateId) {
      return `${
        this.menuItem('fit_size_products').path
      }?fit_template=${fitTemplateId}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';

.fit-templates__no-product {
  @include text-button;
}

@include media-breakpoint-each(mobile) {
  .FitTemplates__action-button {
    & + & {
      margin-left: 0;
    }
  }
}

::v-deep {
  .fit-template-table__name {
    text-align: left;
    flex: 1 0 100px;
  }

  .fit-template-table__fit-products-count {
    flex: 1 0 130px;
  }

  .fit-template-table__actions {
    flex: 1 0 270px;

    @include media-breakpoint-each(mobile) {
      flex: 0 0 75px;
    }
  }
}
</style>

<i18n locale="ko">
{
  "table_head": {
    "name": "템플릿 이름",
    "products_count": "템플릿 사용 상품 수"
  },
  "button": {
    "new_fit_template": "핏 템플릿 추가"
  },
  "cannot_sort_when_filtered": "검색어를 삭제한 뒤 이동해주세요.",
  "cannot_delete_template": "템플릿을 사용중인 상품이 있어 삭제할 수 없습니다.",
  "confirm_clone": "같은 설정으로 템플릿이 복제 생성됩니다. 계속하시겠습니까?",
  "show_product": "{count}개 상품 보기",
  "no_product": "0개 상품",
  "cloning": "복제 중.."
}
</i18n>
