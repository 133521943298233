var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t(_vm.id ? 'title_edit' : 'title_new'),"sub-title":_vm.value,"is-loading":_vm.isLoading,"form-props":Object.assign({}, _vm.formProps,
    {objectId: 'option_value',
    groupLabelNamespace: 'product_option_value',
    focusGroupId: 'value'})},scopedSlots:_vm._u([{key:"group",fn:function(group){return [(group.id === 'parent_option_value')?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.formObject.has_children ? _vm.$t('parent_option_value_tooltip') : null
        ),expression:"\n          formObject.has_children ? $t('parent_option_value_tooltip') : null\n        "}],staticClass:"AppForm__group-field"},[_c('AppSelectProductOptionValue',{attrs:{"name":"option_value[parent_product_option_value_id]","product-option-type-id":_vm.optionTypeId,"product-option-values":_vm.formObject.parent_option_value
              ? [
                  {
                    id: _vm.formObject.parent_option_value.id,
                    name: _vm.formObject.parent_option_value.value
                  }
                ]
              : [],"disabled":_vm.formObject.has_children,"default-params":{ parent_only: '1', exclude_id: _vm.id }},on:{"change-items":_vm.changeParentOptionValue}})],1)]:_vm._e()]}}])},_vm.formEvents),[_c('input',{attrs:{"type":"hidden","name":"option_type_id"},domProps:{"value":_vm.optionTypeId}})])}
var staticRenderFns = []

export { render, staticRenderFns }