var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppInfoBox',{staticClass:"AnalyticsReviewKeywords__info-box"},[_vm._v(" "+_vm._s(_vm.$t('info_message'))+" ")]),_c('AppIframeViewer',{class:[
      'AnalyticsReviewKeywords__iframe',
      {
        'AnalyticsReviewKeywords__iframe--beauty':
          _vm.currentBrand.is_beauty_mall,
        'AnalyticsReviewKeywords__iframe--fashion':
          _vm.currentBrand.is_fashion_mall
      }
    ],attrs:{"src":_vm.brandSettings.review_keywords_bi_url}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }