var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalDefault',{attrs:{"title":_vm.$t('fit_size_feedback_product_dialog.title'),"sub-title":_vm.product.name},on:{"close":_vm.close}},[_c('AppAjaxContent',{attrs:{"slot":"body","is-loading":_vm.isLoading},slot:"body"},[(_vm.sizeOptions.length > 0)?_c('AppSelectButton',{staticClass:"FitSizeFeedbackProducts__size-option-buttons",attrs:{"options":_vm.sizeOptions,"tooltip":_vm.$t('fit_size_feedback_product_dialog.no_size_feedbacks')},on:{"change":function($event){return _vm.filterBySizeOption($event)}},model:{value:(_vm.selectedSizeOptionId),callback:function ($$v) {_vm.selectedSizeOptionId=$$v},expression:"selectedSizeOptionId"}}):_vm._e(),(_vm.fitScorePercentages.length > 0)?_c('AppContainer',[_c('AppDataItem',{attrs:{"label":_vm.titleWithSizeOptionName(
            _vm.$t('fit_size_feedback_product_dialog.fit_score_stats')
          ),"gap":"narrow"}},[_c('ul',_vm._l((_vm.fitScorePercentages),function(item,i){return _c('li',{key:i,staticClass:"app-list__item"},[_vm._v(" "+_vm._s(item.fit_score_name)+" "+_vm._s(item.fit_score_value)+"% "),_c('span',{staticClass:"FitSizeFeedbackProductDialog__fit-score-count"},[_vm._v(_vm._s(("" + (item.fit_score_count) + (_vm.$t('unit.count')))))])])}),0)])],1):_vm._e(),_c('AppResourceTable',{attrs:{"table-name":_vm.titleWithSizeOptionName(
          _vm.$t('fit_size_feedback_product_dialog.size_feedbacks')
        ),"toggle-button":{
        label: _vm.$t(
          'fit_size_feedback_product_dialog.show_size_feedbacks_with_fit_message'
        )
      },"resources":_vm.sizeFeedbacks,"columns":_vm.columns,"rows":_vm.rows},on:{"paginate":_vm.paginate,"toggle":function($event){return _vm.filterOnlyFitMessages($event)}},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
      var column = ref.column;
      var value = ref.value;
return [(column == 'last_updated_at')?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              ((_vm.$t('size_feedback.last_updated_at')) + ": " + (_vm.formatDateTime(
                value
              )))
            ),expression:"\n              `${$t('size_feedback.last_updated_at')}: ${formatDateTime(\n                value\n              )}`\n            "}]},[_c('div',{staticClass:"FitSizeFeedbackProducts__date table-line"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]),_c('div',{staticClass:"FitSizeFeedbackProducts__detail table-line table-line--mt4"},[_c('AppBadge',{attrs:{"label":_vm.$t('app.detail_info')}})],1)])]:(column == 'user_property')?_vm._l((value),function(v,i){return _c('div',{key:i,staticClass:"table-line table-line--mt4"},[_vm._v(" "+_vm._s(v)+" ")])}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }