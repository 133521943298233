<template>
  <div v-if="lastPage > 1">
    <AppPaginationButton
      v-bind="buttonProps"
      :page="firstPage"
      button-type="first"
      @click="clickPage(firstPage)"
    />
    <AppPaginationButton
      v-bind="buttonProps"
      :page="prevPage"
      button-type="prev"
      @click="clickPage(prevPage)"
    />
    <AppPaginationButton
      v-for="page in pages"
      :key="page"
      v-bind="buttonProps"
      :page="page"
      button-type="normal"
      @click="clickPage(page)"
    />
    <AppPaginationButton
      v-bind="buttonProps"
      :page="nextPage"
      button-type="next"
      @click="clickPage(nextPage)"
    />
    <AppPaginationButton
      v-bind="buttonProps"
      :page="lastPage"
      button-type="last"
      @click="clickPage(lastPage)"
    />
  </div>
</template>

<script>
export default {
  name: 'AppPagination',
  props: {
    currentPage: { type: Number, required: true },
    per: { type: Number, required: true },
    totalCount: { type: Number, required: true },
    enableRoute: { type: Boolean, required: true }
  },
  computed: {
    firstPage() {
      return 1;
    },
    prevPage() {
      return this.currentPage - 1;
    },
    nextPage() {
      return this.currentPage + 1;
    },
    pages() {
      const startPage = Math.max(this.currentPage - 4, this.firstPage);
      const endPage = Math.min(this.currentPage + 4, this.lastPage);
      return Array.from(
        { length: endPage - startPage + 1 },
        (v, k) => k + startPage
      );
    },
    lastPage() {
      return Math.floor((this.totalCount - 1) / this.per) + 1;
    },
    buttonProps() {
      return {
        currentPage: this.currentPage,
        lastPage: this.lastPage,
        enableRoute: this.enableRoute
      };
    }
  },
  methods: {
    clickPage(page) {
      this.$emit('paginate', page);
    }
  }
};
</script>
