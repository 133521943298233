import api from '@/lib/api';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';

const state = {
  templates: [],
  selectedTemplateCode: '',
  selectedTemplateName: ''
};

const mutations = {
  SET_TEMPLATES(state, templates) {
    state.templates = templates;
  },
  APPEND_TEMPLATE(state, template) {
    state.templates = [...state.templates, template];
  },
  UPDATE_TEMPLATE(state, template) {
    state.templates = state.templates.map(t => {
      if (!t.id || t.id !== template.id) return t;

      return {
        ...t,
        name: template.name,
        content: template.content,
        inspection_status: template.inspection_status
      };
    });
  },
  DELETE_TEMPLATE(state, templateId) {
    state.templates = state.templates.filter(t => !t.id || t.id !== templateId);
  },
  SET_SELECTED_TEMPLATE_CODE(state, templateCode) {
    state.selectedTemplateCode = templateCode;
  },
  SET_SELECTED_TEMPLATE_NAME(state, templateName) {
    state.selectedTemplateName = templateName;
  }
};

const getters = {
  templateCodeToId: state => templateCode => {
    return state.templates.find(t => t.code === templateCode)?.id;
  },
  templateNames: state => ({ templateIdToExclude }) => {
    let result = state.templates;
    if (templateIdToExclude) {
      result = result.filter(t => t.id !== templateIdToExclude);
    }
    return result.map(t => t.name);
  }
};

const actions = {
  fetchTemplates: ({ commit }) => {
    return api.get('/review/chatbot_message_templates').then(({ data }) => {
      commit('SET_TEMPLATES', data.chatbot_message_templates);
    });
  },
  createTemplate: ({ commit }, { formData, successMessage }) => {
    return new Promise(resolve => {
      api
        .post('/review/chatbot_message_templates', formData, { successMessage })
        .then(({ data }) => {
          commit('APPEND_TEMPLATE', data.chatbot_message_template);
          resolve(data.chatbot_message_template);
        });
    });
  },
  updateTemplate: ({ commit }, { templateId, formData, successMessage }) => {
    return new Promise(resolve => {
      api
        .patch(`/review/chatbot_message_templates/${templateId}`, formData, {
          successMessage
        })
        .then(({ data }) => {
          const template = data.chatbot_message_template;
          commit('UPDATE_TEMPLATE', template);
          resolve(template);
        });
    });
  },
  deleteTemplate: ({ commit }, { templateId, successMessage }) => {
    api
      .delete(`/review/chatbot_message_templates/${templateId}`, {
        successMessage
      })
      .then(() => {
        commit('DELETE_TEMPLATE', templateId);
      });
  },
  selectTemplate: ({ commit, state }, { templateId, successMessage }) => {
    const template = templateId
      ? state.templates.find(t => t.id?.toString() === templateId)
      : state.templates.find(t => !t.id);

    return api
      .post(
        '/review/chatbot_message_templates/select',
        { id: templateId },
        {
          successMessage
        }
      )
      .then(() => {
        commit('SET_SELECTED_TEMPLATE_CODE', template.code);
        commit('SET_SELECTED_TEMPLATE_NAME', template.name);
      });
  },
  requestTemplateInspection: ({ state, commit }, { templateId, formData }) => {
    return api
      .post(
        `/chatbot_message/templates/${templateId}/template_inspection`,
        formData
      )
      .then(() => {
        const template = state.templates.find(e => e.id === templateId);
        commit('UPDATE_TEMPLATE', {
          ...template,
          inspection_status: BizMessageTemplateInspectionStatus.REQUESTED
        });
      });
  }
};

export default { namespaced: true, state, mutations, getters, actions };
