<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppDataList :data="SAMPLE_DATA" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">[gap=narrow]</label>
      </div>
      <AppDataList :data="SAMPLE_DATA" gap="narrow" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>Date Period</label>
      </div>
      <AppDataList :data="SAMPLE_DATE_PERIOD_DATA" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>Badge</label>
      </div>
      <AppDataList :data="SAMPLE_BADGE_DATA" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>Label Badge</label>
      </div>
      <AppDataList :data="SAMPLE_LABEL_DATA" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>SuperAdmin Data</label>
      </div>
      <AppDataList :data="SAMPLE_SUPERADMIN_DATA" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiDataList',
  data() {
    return {
      SAMPLE_DATA: [
        { label: 'Text', value: 'text value' },
        { label: 'Datetime', value: new Date(), type: 'datetime' }
      ],
      SAMPLE_DATE_PERIOD_DATA: [
        {
          label: 'Date Period',
          value: [new Date(), new Date()],
          type: 'date_period'
        }
      ],
      SAMPLE_BADGE_DATA: [
        {
          label: 'Badge',
          value: { label: 'value', style: 'mango' },
          type: 'badge'
        }
      ],
      SAMPLE_SUPERADMIN_DATA: [
        { label: 'Text', value: 'text value', superAdmin: true }
      ]
    };
  },
  created() {
    this.SAMPLE_LABEL_DATA = [
      {
        label: 'Label Badge',
        badge: { label: 'badge', badgeStyle: 'red-outline' },
        value: 'label badge'
      }
    ];
  }
};
</script>
