import { constructEnum } from '@/lib/enum';

const DialogSize = constructEnum('DialogSize', {
  DEFAULT: 'default',
  SMALL: 'small',
  MIDDLE: 'middle',
  WIDE: 'wide',
  AUTO: 'auto'
});

export default DialogSize;
