<template>
  <div class="TargetCampaignSituationalShowEffects">
    <div
      v-if="isPopupCampaign"
      class="TargetCampaignSituationalShowEffects__searchbar"
    >
      <AppSelectButton
        v-model="samplingMethod"
        :options="sampleMethodOptions"
      />
    </div>
    <AppContainer class="TargetCampaignSituationalShowEffects__effect-list">
      <ul>
        <li>
          <TargetCampaignsKpi
            :campaign="campaign"
            :kpi="recentKpi"
            :average-kpi="recentAverageKpi"
          />
        </li>
        <li>
          <AppDataBox :title="$t('campaign_effects.counts')">
            <TargetCampaignsCountsGraph v-bind="{ campaign, samplingMethod }" />
          </AppDataBox>
        </li>
        <li v-if="!isWidgetCampaign">
          <TargetCampaignsPrices :campaign="campaign" />
        </li>
      </ul>
    </AppContainer>
    <div class="TargetCampaignSituationalShowEffects__effects-text">
      {{
        $t('effects_text', [
          formatDate(campaign.recent_kpi_start_date),
          formatDate(campaign.recent_kpi_end_date)
        ])
      }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';
import TargetCampaignsKpi from './components/TargetCampaignsKpi';
import TargetCampaignsCountsGraph from './components/TargetCampaignsCountsGraph';
import TargetCampaignsPrices from './components/TargetCampaignsPrices';

export default {
  name: 'TargetCampaignSituationalShowEffects',
  components: {
    TargetCampaignsKpi,
    TargetCampaignsCountsGraph,
    TargetCampaignsPrices
  },
  data: () => ({ samplingMethod: null }),
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    ...mapGetters('targetCampaign', ['isPopupCampaign', 'isWidgetCampaign']),
    sampleMethodOptions() {
      return [
        TargetSamplingMethod.POPUP_CHECKED,
        TargetSamplingMethod.POPUP_CLICKED
      ].map(value => ({
        label: TargetSamplingMethod.t(value),
        value,
        tooltip: TargetSamplingMethod.tooltipText(value)
      }));
    },
    recentKpi() {
      const val = this.useClickedKpi()
        ? this.campaign.recent_clicked_kpi
        : this.campaign.recent_kpi;
      return _.round(val * 100, 1);
    },
    recentAverageKpi() {
      const val = this.useClickedKpi()
        ? this.campaign.recent_average_clicked_kpi
        : this.campaign.recent_average_kpi;
      return _.round(val * 100, 1);
    }
  },
  created() {
    this.samplingMethod = this.isPopupCampaign
      ? TargetSamplingMethod.POPUP_CLICKED
      : this.isWidgetCampaign
      ? TargetSamplingMethod.WIDGET_CLICKED
      : TargetSamplingMethod.MESSAGE_CHECKED;
  },
  methods: {
    useClickedKpi() {
      return [
        TargetSamplingMethod.POPUP_CLICKED,
        TargetSamplingMethod.WIDGET_CLICKED
      ].includes(this.samplingMethod);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';
@import '@/scss/mixins/_clearfix.scss';

.TargetCampaignSituationalShowEffects {
  margin-top: 20px;
}

.TargetCampaignSituationalShowEffects__searchbar {
  text-align: right;
  margin-bottom: 12px;
}

.TargetCampaignSituationalShowEffects__effect-list {
  @include media-breakpoint-each(mobile) {
    overflow-x: scroll;
  }

  ul {
    @include clearfix;
    padding: 8px 24px;

    @include media-breakpoint-each(tablet, mobile) {
      padding: 8px;
    }

    @include media-breakpoint-each(mobile) {
      width: 720px;
    }
  }

  li {
    width: 33.3%;
    float: left;

    &:nth-child(1) {
      text-align: left;
    }

    &:nth-child(2) {
      text-align: center;
    }

    &:nth-child(3) {
      width: 33.4%;
      text-align: right;
    }
  }
}

.TargetCampaignSituationalShowEffects__effects-text {
  @include text-caption-small;
  margin-top: 4px;
}
</style>

<i18n locale="ko">
{
  "campaign_effects": {
    "effects": "캠페인 핵심 효과",
    "counts": "캠페인 주요 지표"
  },
  "effects_text": "*. 효과 수집을 완료한 {0} ~ {1} 사이의 값입니다."
}
</i18n>
