var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppButton',{class:[
    'AppDropdownMenuButton',
    ("AppDropdownMenuButton--style-" + _vm.buttonStyle)
  ],attrs:{"tooltip":_vm.tooltip,"disabled":_vm.disabled,"size":_vm.size,"button-style":_vm.buttonStyle === 'table-head' ? 'none' : _vm.buttonStyle,"type":"dropdown"},on:{"click":_vm.click},scopedSlots:_vm._u([{key:"dropdown",fn:function(){return [(_vm.isDropdownVisible)?_c('AppModeless',{on:{"close":_vm.close}},[_c('AppDropdownMenu',{attrs:{"menu-items":_vm.menuItems},on:{"close":_vm.close}})],1):_vm._e()]},proxy:true}])},[_c('div',{class:[
      'AppDropdownMenuButton__label',
      ( _obj = {}, _obj[("AppDropdownMenuButton__label--" + _vm.size)] = !!_vm.size, _obj )
    ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('AppSvgIcon',{class:[
      'AppDropdownMenuButton__icon',
      ( _obj$1 = {}, _obj$1[("AppDropdownMenuButton__icon--" + _vm.size)] = !!_vm.size, _obj$1 )
    ],attrs:{"name":"nav-chevron-down","height":_vm.iconHeight}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }