<template>
  <AppDropdownMenuButton :label="$t('app.manage')" :menu-items="menuItems" />
</template>

<script>
import api from '@/lib/api';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'ReviewCategoryManageButton',
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    menuItems() {
      return [
        {
          label: this.$t('edit_name'),
          clickHandler: this.editCategoryName
        },
        {
          label:
            this.category.product_category_status == 'visible'
              ? this.$t('hide_category')
              : this.$t('expose_category'),
          clickHandler:
            this.category.product_category_status == 'visible'
              ? this.hideCategory
              : this.exposeCategory
        },
        {
          label: this.$t('delete_category'),
          style: 'danger',
          clickHandler: this.deleteCategory
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('productCategory', ['UPDATE_PRODUCT_CATEGORY_NAME']),
    editCategoryName({ close }) {
      this.openDialog([
        'ReviewCategoryEditDialog',
        { orgCategory: this.category }
      ]).then(eventBus => {
        eventBus.$on('save', name => {
          this.$emit('edit', name);
          this.UPDATE_PRODUCT_CATEGORY_NAME({ id: this.category.id, name });
          close();
        });
      });
    },
    hideCategory({ close }) {
      if (!confirm(this.$t('hide_confirm'))) return;

      api
        .patch(
          '/product_categories/hide',
          { id: this.category.id },
          { successMessage: this.$t('hide_success') }
        )
        .then(() => this.$emit('change', 'hidden'))
        .finally(() => close());
    },
    exposeCategory({ close }) {
      if (!confirm(this.$t('expose_confirm'))) return;

      api
        .patch(
          '/product_categories/expose',
          { id: this.category.id },
          { successMessage: this.$t('expose_success') }
        )
        .then(() => this.$emit('change', 'visible'))
        .finally(() => close());
    },
    deleteCategory({ close }) {
      if (!confirm(this.$t('delete_confirm'))) return;

      this.$emit('delete');
      close();
    }
  }
};
</script>

<i18n locale="ko">
{
  "edit_name": "카테고리 이름 변경하기",
  "hide_category": "카테고리 숨기기",
  "hide_confirm": "카테고리를 숨김 처리합니다.\n상품이 많은 경우 시간이 소요될 수 있습니다.\n진행하시겠습니까?",
  "hide_success": "카테고리를 숨김 처리했습니다.",
  "expose_category": "카테고리 숨김 해제",
  "expose_confirm": "카테고리를 숨김 해제합니다.\n상품이 많은 경우 시간이 소요될 수 있습니다.\n진행하시겠습니까?",
  "expose_success": "카테고리를 숨김 해제했습니다.",
  "delete_category": "카테고리 삭제",
  "delete_confirm": "하위 카테고리들이 함께 삭제되며 상품은 삭제되지 않습니다. 삭제하시겠습니까?\n\n• 크리마 관리자페이지에서만 삭제되며, 솔루션 동기화 시 다시 목록에 나타날 수 있습니다. 완전히 삭제하고 싶다면 솔루션에서 진행해주세요."
}
</i18n>
