<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="default">Default</label>
      </div>
      <AppTable
        id="default"
        :rows="rows"
        :columns="[
          { id: 'id', label: 'id' },
          { id: 'sort', label: 'sort' }
        ]"
      >
        <template #cell="{ column, row }">
          <template v-if="column.id === 'sort'">
            <AppButtonDraggable
              :disabled="row.disabled"
              :tooltip="row.tooltip"
              @sort="sort"
            />
          </template>
        </template>
      </AppTable>
    </div>
  </div>
</template>

<script>
import { arrayMoveIndex } from '@/lib/array';

export default {
  name: 'UiButtonDraggable',
  data() {
    return {
      rows: [
        { id: 1, disabled: true, tooltip: null },
        { id: 2 },
        { id: 3 },
        { id: 4 }
      ]
    };
  },
  methods: {
    sort({ srcIndex, dstIndex }) {
      this.rows = arrayMoveIndex(this.rows, srcIndex, dstIndex);
    }
  }
};
</script>
