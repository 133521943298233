import cloneDeep from 'lodash/cloneDeep';

export default {
  data: () => ({ orgResourceParams: {}, resourceParams: {} }),
  computed: { defaultResourceParams: () => ({ per: '20', page: '1' }) },
  methods: {
    setOrgResourceParams(orgResourceParams) {
      this.orgResourceParams = cloneDeep(orgResourceParams);
      this.resourceParams = cloneDeep(this.orgResourceParams);
    },
    searchResource() {
      const params = { ...this.resourceParams };

      const { page } = this.defaultResourceParams;
      if (page) params.page = page;

      this.setOrgResourceParams(params);
      this.refreshResourceWithParams(params);
    },
    refreshResource() {
      this.refreshResourceWithParams(this.orgResourceParams);
    },
    paginate({ per, page }) {
      this.refreshResourceWithParams({
        ...this.orgResourceParams,
        per: per.toString(),
        page: page.toString()
      });
    },
    fetchFirstPage() {
      // Reset everything to default except "per"
      this.resourceParams = {
        ...this.defaultResourceParams,
        per: this.resourceParams.per
      };
      this.searchResource();
    }
  }
};
