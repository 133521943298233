<template>
  <ul class="AppHashSelectButton">
    <li class="AppHashSelectButton__item AppHashSelectButton__item--all">
      <AppSelectButton
        v-model="selectAll"
        :options="[{ label: $t('app.all'), value: true }]"
      />
    </li>
    <li
      v-for="selectButton in selectButtons"
      :key="selectButton.id"
      class="AppHashSelectButton__item"
    >
      <AppSelectButton
        :value="currentHash[selectButton.id]"
        :options="selectButton.options"
        :tooltip="selectButton.tooltip"
        unselectable
        @change="setCurrentHash({ ...currentHash, [selectButton.id]: $event })"
      />
    </li>
  </ul>
</template>

<script>
import HashSelectable from '@/mixins/HashSelectable';

export default {
  name: 'AppHashSelectButton',
  mixins: [HashSelectable],
  props: {
    selectButtons: {
      type: Array,
      required: true
    }
  },
  computed: {
    ids() {
      return this.selectButtons.map(i => i.id);
    },
    selectAll: {
      get() {
        return this.ids.every(id => this.currentHash[id] === null);
      },
      set() {
        const newHash = { ...this.currentHash };
        this.ids.forEach(id => (newHash[id] = null));
        this.setCurrentHash(newHash);
      }
    }
  },
  methods: {
    setCurrentHash(hash, emit = true) {
      const result = { ...hash };
      this.ids.forEach(id => {
        if (!(id in result)) result[id] = null;
      });
      this.currentHash = result;

      if (emit) this.$emit('change', this.currentHash);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_breakpoints.scss';

.AppHashSelectButton {
  margin-top: -8px;
}

.AppHashSelectButton__item {
  display: inline-block;
  margin-top: 8px;
  margin-right: 8px;

  &--all {
    margin-right: 16px;
  }

  &:last-item {
    margin-right: 0;
  }
}

@include media-breakpoint-each(mobile) {
  .AppHashSelectButton {
    margin-top: 0;
  }

  .AppHashSelectButton__item {
    display: block;
    margin-right: 0;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
