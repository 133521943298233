<template>
  <AppResourceTable
    table-id="dispatch-results-table-dm"
    :columns="columns"
    v-bind="{ rows, resources }"
    v-on="$listeners"
  >
    <template #cell="cellProps">
      <slot name="cell" v-bind="cellProps" />
    </template>
  </AppResourceTable>
</template>

<script>
export default {
  name: 'TargetReportsDispatchResultsTableDm',
  props: {
    rows: { type: Array, required: true },
    resources: { type: Object, required: true }
  },
  computed: {
    columns() {
      return [
        { id: 'id' },
        { id: 'send_at', type: 'datetime' },
        { id: 'campaign', align: 'left' },
        { id: 'total_count', type: 'number' },
        { id: 'success_count', type: 'number' },
        { id: 'failure_count', type: 'number' },
        { id: 'dispatch_status' },
        { id: 'actions', label: this.$t('app.actions') }
      ].map(c => ({
        ...c,
        label: c.label || this.$t(`table.${c.id}`)
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dispatch-results-table-dm__id {
    flex-basis: 100px;
  }

  .dispatch-results-table-dm__send-at {
    flex-basis: 160px;
  }

  .dispatch-results-table-dm__campaign {
    flex-basis: 240px;
  }

  .dispatch-results-table-dm__total-count {
    flex-basis: 80px;
  }

  .dispatch-results-table-dm__success-count {
    flex-basis: 80px;
  }

  .dispatch-results-table-dm__failure-count {
    flex-basis: 80px;
  }

  .dispatch-results-table-dm__dispatch-status {
    flex-basis: 100px;
  }

  .dispatch-results-table-dm__actions {
    flex-basis: 176px;
  }
}
</style>

<i18n locale="ko">
{
  "table": {
    "id": "발송 ID",
    "send_at": "발송일",
    "campaign": "캠페인",
    "total_count": "전체 발송수",
    "success_count": "성공 발송수",
    "failure_count": "실패 발송수",
    "dispatch_status": "상태"
  }
}
</i18n>
