var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalFormLivePreview',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.campaign.name,"is-loading":_vm.isLoading,"form-props":Object.assign({}, _vm.formProps, {objectId: 'campaign'})},on:{"error-summary":_vm.updatePreviewIfValid},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('AppMobilePhonePreview',[(_vm.useLivePreview)?[_c('TargetPopupLivePreview')]:[_c('AppIframeViewer',{attrs:{"src":_vm.popupSampleUrl,"is-loading-forced":_vm.currentVersion !== _vm.displayVersion}})]],2)]},proxy:true},{key:"group",fn:function(ref){
var id = ref.id;
return [(id === 'color')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppColorPicker',{attrs:{"name":"campaign[button_color]","label":_vm.$t('form.color.button_color'),"value":_vm.formObject.button_color},on:{"input":_vm.changeButtonColor}}),_c('AppColorPicker',{attrs:{"name":"campaign[font_color]","label":_vm.$t('form.color.font_color'),"value":_vm.formObject.font_color},on:{"input":_vm.changeFontColor}})],1)]:_vm._e()]}},{key:"group-sub-item",fn:function(ref){
var group = ref.group;
var value = ref.value;
var checked = ref.checked;
var errors = ref.errors;
return [(group.id === 'use_default_icon')?[(!value)?[_c('AppImageInput',{attrs:{"name":'campaign[title_image]',"image-url":_vm.formObject.title_image,"placeholder":_vm.$t('form.use_default_icon.upload_placeholder'),"invalid":!!errors.title_image,"disabled":!checked,"not-removable":""},on:{"change":_vm.changeTitleImage}}),_c('AppFormError',{attrs:{"error":errors.title_image}}),_c('AppFormHint',{attrs:{"disabled":!checked,"message":_vm.$t('form.use_default_icon.hint')}})]:_vm._e()]:_vm._e()]}}])},_vm.formEvents),[(_vm.formObject.use_default_icon)?_c('input',{attrs:{"type":"hidden","name":'campaign[remove_title_image]'},domProps:{"value":true}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }