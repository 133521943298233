<template>
  <AppJob
    :job="jobProps"
    :display-running-time="job.displayRunningTime"
    @click="click"
  />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppMultiAsyncJob',
  props: { job: { type: Object, required: true } },
  computed: {
    jobProps() {
      return {
        ...this.job,
        startedAt: new Date(this.job.startedAt),
        status:
          this.job.progressCount < this.job.totalCount ? 'running' : 'complete'
      };
    }
  },
  methods: {
    ...mapActions('multiAsyncJob', ['completeJob']),
    click() {
      if (!this.isRunning) this.completeJob(this.job.id);
    }
  }
};
</script>
