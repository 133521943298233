var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.subTitle,"form-props":_vm.formProps},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
var errors = ref.errors;
return [(id === 'review_tags')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppButton',{attrs:{"label":_vm.$t('review_tags.add_button')},on:{"click":_vm.addReviewTag}}),(_vm.reviewTagRows.length)?_c('AppTable',{attrs:{"table-id":"review-edit-analysis-review-tags-table","rows":_vm.reviewTagRows,"columns":_vm.reviewTagColumns,"no-head":""},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
var rowIndex = ref.rowIndex;
return [(column.id == 'nlp_category_type_id')?[_c('AppSelect',{attrs:{"options":_vm.nlpCategoryTypes.map(function (t) { return ({ label: t.name, value: t.id }); })},on:{"change":function($event){return _vm.resetReviewTagTypeId(rowIndex)}},model:{value:(row.nlp_category_type_id),callback:function ($$v) {_vm.$set(row, "nlp_category_type_id", $$v)},expression:"row.nlp_category_type_id"}})]:(column.id == 'review_tag_type_id')?[_c('AppSelectFilterable',{attrs:{"options":_vm.reviewTagTypeOptions[row.nlp_category_type_id] || [],"invalid":!!errors[("review_tags[" + rowIndex + "][review_tag_type_id]")],"placeholder":_vm.$t('review_tags.placeholder.review_tag_type_id')},on:{"change":function($event){return _vm.validateField(
                    ("review_tags[" + rowIndex + "][review_tag_type_id]")
                  )}},model:{value:(row.review_tag_type_id),callback:function ($$v) {_vm.$set(row, "review_tag_type_id", $$v)},expression:"row.review_tag_type_id"}}),_c('AppFormError',{attrs:{"error":errors[("review_tags[" + rowIndex + "][review_tag_type_id]")]}})]:(column.id == 'sentiment')?[_c('AppSelectFilterable',{attrs:{"options":_vm.reviewTagSentimentOpitons,"invalid":!!errors[("review_tags[" + rowIndex + "][sentiment]")],"placeholder":_vm.$t('review_tags.placeholder.sentiment')},on:{"change":function($event){return _vm.validateField(("review_tags[" + rowIndex + "][sentiment]"))}},model:{value:(row.sentiment),callback:function ($$v) {_vm.$set(row, "sentiment", $$v)},expression:"row.sentiment"}}),_c('AppFormError',{attrs:{"error":errors[("review_tags[" + rowIndex + "][sentiment]")]}})]:(column.id == 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('app.delete'),"button-style":"red-outline"},on:{"click":function($event){return _vm.deleteReviewTag(rowIndex)}}})]:_vm._e()]}}],null,true)}):_vm._e()],1)]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }