import { render, staticRenderFns } from "./TargetEmailLivePreview.vue?vue&type=template&id=99d4c37e&scoped=true&"
import script from "./TargetEmailLivePreview.vue?vue&type=script&lang=js&"
export * from "./TargetEmailLivePreview.vue?vue&type=script&lang=js&"
import style0 from "./TargetEmailLivePreview.vue?vue&type=style&index=0&id=99d4c37e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99d4c37e",
  null
  
)

export default component.exports