<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppDateInput id="basic" v-model="valueBasic" />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppDateInput id="invalid" v-model="valueInvalid" invalid />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="empty">Empty</label>
      </div>
      <AppDateInput id="empty" v-model="valueEmpty" />
      <div class="ui-section__description">
        Selected Value:
        <AppTextInput v-model="valueEmpty" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiDateInput',
  data: () => ({
    valueBasic: '2012-05-17',
    valueInvalid: '2011-04-16',
    valueEmpty: ''
  })
};
</script>
