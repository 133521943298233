<template>
  <div>
    <div v-if="description" class="ThePaymentDialogPackagesTable__description">
      {{ description }}
    </div>
    <AppTable
      table-id="ThePaymentDialogPackagesTable"
      :columns="COLUMNS"
      :rows="rows"
    >
      <template #cell="{ row, column }">
        <template v-if="column.id === 'select'">
          <AppCheckbox
            v-if="multiple"
            :value="row.id"
            input-style="standalone"
            :name="name"
            :checked="ids.includes(row.id)"
            :disabled="row.disabled"
            @change="
              $emit(
                'change',
                $event
                  ? [...ids, row.id].sort()
                  : ids.filter(id => id !== row.id)
              )
            "
          />
          <AppRadio
            v-else
            :value="row.id"
            input-style="standalone"
            :name="name"
            :selected-value="ids[0]"
            :disabled="disabledAll || row.disabled"
            @change="$emit('change', row.id ? [row.id] : [])"
          />
        </template>
        <template v-else-if="column.id === 'name'">
          <div
            :class="[
              'ThePaymentDialogPackagesTable__name',
              row.disabled
                ? 'ThePaymentDialogPackagesTable__name--disabled'
                : ''
            ]"
          >
            {{ row.name }}
          </div>
          <AppButton
            v-if="row.disabled"
            :label="$t('payment_cancel_button')"
            button-style="underline"
            @click="openCancelPaymentConfirm(row.payment)"
          />
        </template>
        <template v-else-if="column.id === 'promotion'">
          <template v-if="row.id">
            <template v-if="row.promotion === 'special_offer'">
              <div class="ThePaymentDialogPackagesTable__promotion-default">
                {{ $t('promotion.special_offer', [brand_name]) }}
              </div>
            </template>
            <template v-else-if="row.promotion === 'long_term_discount'">
              <div>
                <span
                  class="ThePaymentDialogPackagesTable__promotion-long-term-discount-percentage"
                  >{{
                    $t('promotion.long_term_discount', [
                      discountPercentage(row)
                    ])
                  }}</span
                >
                <AppNumber
                  class="ThePaymentDialogPackagesTable__promotion-long-term-discount-org-price"
                  :value="row.price"
                  unit="currency.krw"
                />
              </div>
              <div>
                <AppNumber :value="row.final_price" unit="currency.krw" />
                (<i18n path="promotion.long_term_discount_monthly_price"
                  ><AppNumber
                    :value="Math.floor(row.final_price / row.months)"
                    unit="currency.krw"/></i18n
                >)
              </div>
            </template>
            <template v-else-if="row.promotion === 'bonus_credits'">
              <div class="ThePaymentDialogPackagesTable__promotion-default">
                {{ $t('promotion.bonus_credits', [row.free_credit]) }}
              </div>
            </template>
            <div v-else-if="row.promotion === 'monthly_bonus_point'">
              {{
                $t('promotion.monthly_bonus_point', [row.monthly_bonus_point])
              }}
              <div
                class="ThePaymentDialogPackagesTable__promotion-monthly-free-dispatchable-sms-count"
              >
                {{
                  $t('promotion.monthly_free_dispatchable_sms_count', [
                    Math.floor(row.monthly_bonus_point / row.sms_price)
                  ])
                }}
              </div>
            </div>
            <template v-else-if="row.promotion === 'discount'">
              <div class="ThePaymentDialogPackagesTable__promotion-default">
                {{ $t('promotion.discount', [discountPercentage(row)]) }}
              </div>
            </template>
            <template v-else>
              -
            </template>
          </template>
        </template>
        <template v-else-if="column.id === 'price'">
          <template v-if="row.id">
            <div class="ThePaymentDialogPackagesTable__price">
              <AppNumber
                :value="row.final_price_with_vat"
                unit="currency.krw"
              />
            </div>
            <div class="ThePaymentDialogPackagesTable__price-calculation">
              <AppNumber :value="row.final_price" unit="currency.krw" />
              <span>+{{ $t('vat') }} </span>
              <AppNumber
                :value="row.final_price_with_vat - row.final_price"
                unit="currency.krw"
              />
            </div>
          </template>
        </template>
      </template>
      <template #no-data><slot name="no-data"/></template>
    </AppTable>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import PackageType from '@/enums/PackageType';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'ThePaymentDialogPackagesTable',
  model: { prop: 'ids', event: 'change' },
  props: {
    ids: { type: Array, required: true },
    name: { type: String, required: true },
    packages: { type: Array, required: true },
    multiple: { type: Boolean, default: false },
    description: { type: String, default: null },
    disabledAll: { type: Boolean, default: false }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'select', label: this.$t('column.select') },
        { id: 'name', label: this.$t('column.name') },
        { id: 'promotion', label: this.$t('column.promotion'), align: 'right' },
        { id: 'price', label: this.$t('column.price'), align: 'right' }
      ]
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('payment', ['packageShortName']),
    rows() {
      return [
        ...(this.multiple ||
        (!this.packages.length && this.$scopedSlots['no-data'])
          ? []
          : [{ name: this.$t('no_selection') }]),
        ...this.packages.map(p => ({
          ...p,
          name: this.packageShortName(p),
          disabled:
            this.isSpecialPackagePendingRequest(p) ||
            this.isFitPackagePendingRequest(p)
        }))
      ];
    },
    brand_name() {
      return this.currentBrand.name;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openCancelPaymentConfirm(payment) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('dialog.title'),
          markdownText: this.$t('dialog.context', [
            this.formatDateTime(payment.created_at),
            payment.danal_itemname
          ]),
          width: DialogSize.SMALL,
          closeButtonLabel: this.$t('dialog.close_button'),
          cancelButtonStyle: 'grey-outline'
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => this.cancelPayment(payment.id));
      });
    },
    discountPercentage({ price, final_price }) {
      return Math.floor(((price - final_price) / price) * 100);
    },
    cancelPayment(id) {
      api
        .post(`/payment/payments/${id}/cancel`)
        .finally(() => this.$emit('refresh'));
    },
    isSpecialPackagePendingRequest(p) {
      return p.package_type === PackageType.SPECIAL && p.is_pending_requested;
    },
    isFitPackagePendingRequest(p) {
      return (
        p.package_type === PackageType.FIT &&
        p.is_special_offer &&
        p.is_pending_requested
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
.ThePaymentDialogPackagesTable__description {
  margin-bottom: 16px;
}

.ThePaymentDialogPackagesTable__promotion-default {
  color: $color-blue;
}

.ThePaymentDialogPackagesTable__promotion-long-term-discount-percentage {
  font-size: 12px;
  color: $color-blue;
}

.ThePaymentDialogPackagesTable__promotion-long-term-discount-org-price {
  margin-left: 4px;
  font-size: 12px;
  text-decoration: line-through;
}

.ThePaymentDialogPackagesTable__promotion-monthly-free-dispatchable-sms-count {
  font-size: 12px;
  color: $color-grey-50;
}

.ThePaymentDialogPackagesTable__name--disabled {
  color: $color-grey-40;
}

.ThePaymentDialogPackagesTable__price {
  @include text-label;
}

.ThePaymentDialogPackagesTable__price-calculation {
  @include text-caption;
}

::v-deep {
  .ThePaymentDialogPackagesTable__select {
    width: 76px;
  }

  .ThePaymentDialogPackagesTable__name {
    width: 260px;
  }

  .ThePaymentDialogPackagesTable__price {
    width: 216px;
  }
}
</style>

<i18n locale="ko">
{
  "column": {
    "select": "상품 선택",
    "name": "상품 종류",
    "promotion": "특별 혜택",
    "price": "결제 가격"
  },
  "promotion": {
    "special_offer": "{0} 특별가격",
    "long_term_discount": "장기 계약 {0}% ↓",
    "long_term_discount_monthly_price": "월 {0}",
    "bonus_credits": "보너스 {0}크레딧",
    "monthly_bonus_point": "매달 {0}포인트 제공",
    "monthly_free_dispatchable_sms_count": "SMS 기준 {0} 건 발송 가능",
    "discount": "정상가 대비 {0}% 할인"
  },
  "dialog": {
    "title": "입금 신청을 취소하시겠습니까?",
    "context": "<pre>{0}에 신청한</pre>{1}<br>입금 신청을 취소합니다.",
    "close_button": "입금 신청 취소"
  },
  "no_selection": "결제할 상품을 선택해주세요.",
  "payment_cancel_button": "입금 신청 취소",
  "vat": "부가세"
}
</i18n>
