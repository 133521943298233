<template>
  <AppModalDefault :title="$t('title')" @close="close">
    <template #body>
      <AppForm :sections="sections" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState } from 'vuex';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'DisplayCopyFastDeliveryWidgetScriptDialog',
  mixins: [DialogView],
  computed: {
    ...mapState('session', ['currentBrand']),
    sections() {
      return [
        {
          id: 'script',
          groups: [
            {
              label: '',
              type: 'infobox',
              value: this.$t('info_html')
            },
            {
              id: 'html_tag',
              label: 'HTML Tag',
              type: 'copyable_textarea',
              value: '<div class="crema-display-fast-delivery-widget"></div>'
            },
            {
              id: 'script_tag',
              label: 'JavaScript',
              type: 'copyable_textarea',
              value: `(function(i,s,o,g,r,a,m){if(s.getElementById(g)){return};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.id=g;a.async=1;a.src=r;m.parentNode.insertBefore(a,m)})(window,document,"script","crema-jssdk","//widgets.cre.ma/${this.currentBrand.code}/init.js");`
            }
          ]
        }
      ];
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "위젯 스크립트 복사하기",
  "info_html": "빠른 배송 안내 위젯을 노출하고자하는 위치에 다음 스크립트를 삽입해주세요.<br />한개의 스크립트만 삽입하면 공동으로 활용됩니다."
}
</i18n>
