import { constructEnum } from '@/lib/enum';

export default constructEnum('TargetProductDataSortType', {
  MOST_CLICKED: 10,
  MOST_PURCHASED: 20,
  HIGHEST_REVENUE: 30,
  MOST_CARTED: 40,
  HIGHEST_PURCHASE_RATE: 50,
  MOST_REVIEWED: 60,
  HIGHEST_REVIEW_SCORE: 70,
  SOLD_OUT_TO_DISPLAY: 80,
  CREATED_AT: 90,
  UPDATED_AT: 100,
  DISPLAYED_AT: 110
});
