<template>
  <div
    :class="[
      'AppVirtualListResourceTable',
      tableId,
      tableStyle ? `AppVirtualListResourceTable--${tableStyle}` : null,
      { 'AppVirtualListResourceTable--in-dialog': isInLayer }
    ]"
  >
    <div class="AppVirtualListResourceTable__table">
      <div ref="stickyHead" class="AppVirtualListResourceTable__head-sticky">
        <AppResourceTableSearchbar
          v-bind="{
            tableName,
            tableDescription,
            enableRefresh,
            enableColumnSettings,
            enableQuery,
            customButtons,
            batchButtons,
            toggleButton,
            tableColumns,
            selectedRowIdsSet,
            totalCount: enableTotalCount
              ? resources
                ? resources.total_count
                : rows.length
              : null,
            isInLayer,
            filterOptions: [],
            filterValues: []
          }"
          @cancel-selection="selectedRowIdsSet = new Set()"
          v-on="$listeners"
        />
        <div
          ref="headScroller"
          class="AppVirtualListResourceTable__head-scroller"
          data-vertically-scrollable="no"
        >
          <div ref="head" class="AppVirtualListResourceTable__head">
            <div
              v-if="mainColumns.length"
              class="AppVirtualListResourceTable__head-row"
            >
              <AppResourceTableCell
                v-for="column in mainColumns"
                :key="column.id"
                cell-type="head"
                class="AppVirtualListResourceTable__cell"
                :colspan="column.colspan"
                :style="{ flexBasis: flexBasis(column.colspan || 1) }"
                :column="column"
                :cell-style="column.columnStyle"
                :value="column.label"
              />
            </div>
            <div
              ref="finalHeadRow"
              :class="[
                'AppVirtualListResourceTable__head-row',
                {
                  'AppVirtualListResourceTable__head-row--sub':
                    mainColumns.length
                }
              ]"
            >
              <AppResourceTableCell
                v-for="column in visibleColumns"
                :key="column.columnId"
                cell-type="head"
                :class="[
                  'AppVirtualListResourceTable__cell',
                  tableId ? column.columnId : null,
                  tableId ? `${column.columnId}--head` : null,
                  column.superAdmin ? 'super-admin__item' : null
                ]"
                :style="column.style"
                :column="column"
                :cell-style="column.columnStyle"
              >
                <slot name="head-cell" :column="column.id">
                  <AppCheckboxPartial
                    v-if="column.type === 'row_select'"
                    :check-status="
                      selectedRows.length
                        ? rows.length === selectedRows.length
                          ? 'checked'
                          : 'partial'
                        : 'unchecked'
                    "
                    @change="selectRows($event === 'checked')"
                  />
                  <div v-else-if="column.tooltip" v-tooltip="column.tooltip">
                    <span class="AppVirtualListResourceTable__head-col-label">{{
                      column.label
                    }}</span>
                    <AppSvgIcon
                      name="icon-info-tooltip"
                      class="AppVirtualListResourceTable__head-col-icon"
                    />
                  </div>
                  <div v-else>{{ column.label }}</div>
                </slot>
              </AppResourceTableCell>
            </div>
          </div>
        </div>
      </div>

      <div
        ref="bodyScroller"
        class="AppVirtualListResourceTable__body-scroller"
        data-vertically-scrollable="no"
      >
        <div ref="body" class="AppVirtualListResourceTable__body js-draggable">
          <VirtualList
            ref="virtualList"
            data-key="id"
            :data-sources="tableRows"
            :data-component="rowComponent"
            :estimate-size="minRowHeight"
            :keeps="20"
            :extra-props="{
              tableId,
              visibleColumns,
              selectedRowIdsSet
            }"
            :item-scoped-slots="$scopedSlots"
            parent-id="main-body"
          />
          <AppNoData
            v-if="rows.length === 0"
            class="AppVirtualListResourceTable__no-data"
            :message="noData"
          />
        </div>
      </div>
    </div>

    <AppResourceTablePagination
      v-if="resources"
      :total-count="resources.total_count"
      :per="resources.per"
      :current-page="resources.page"
      :enable-per="enablePer"
      :enable-summary="!isInLayer"
      :enable-route="!isInLayer"
      :pers="pers"
      @paginate="paginate"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import VirtualList from '@/lib/VirtualList';
import DialogSize from '@/enums/DialogSize';
import AppVirtualListResourceTableBodyRow from '@/components/AppVirtualListResourceTableBodyRow';
import Focusable from '@/mixins/Focusable';

export default {
  name: 'AppVirtualListResourceTable',
  components: { VirtualList },
  mixins: [Focusable],
  props: {
    advancedSearchHeight: { type: Number, default: 0 },
    advancedSearchVisible: { type: Boolean, default: false },
    tableId: { type: String, default: null },
    tableName: { type: String, default: null },
    tableDescription: { type: String, default: null },
    resources: { type: Object, default: null },
    mainColumns: { type: Array, default: () => [] },
    columns: { type: Array, required: true },
    rows: { type: Array, required: true },
    minRowHeight: { type: Number, default: 50 },
    noData: {
      type: String,
      default() {
        return this.$t('app.no_data');
      }
    },
    enableQuery: { type: Boolean, default: false },
    enableRefresh: { type: Boolean, default: false },
    enableColumnSettings: { type: Boolean, default: false },
    enableTotalCount: { type: Boolean, default: true },
    enablePer: { type: Boolean, default: true },
    customButtons: {
      type: Array,
      default() {
        return [];
      }
    },
    batchButtons: {
      type: Array,
      default() {
        return [];
      }
    },
    toggleButton: {
      type: Object,
      default: null
    },
    tableStyle: { type: String, default: null },
    eventBus: { type: Object, default: null },
    pers: { type: Array, default: undefined },
    selectedRowIds: { type: Array, default: null }
  },
  data() {
    return {
      isInLayer: false,
      selectedRowIdsObj: { set: new Set(this.selectedRowIds) },
      rowComponent: AppVirtualListResourceTableBodyRow,
      pressedKeys: {}
    };
  },
  computed: {
    ...mapState('editor', ['columnVisibleMap']),
    ...mapGetters(['isSuperAdminAccessible']),
    tableColumns() {
      const columnIdPrefix = this.tableId ? `${this.tableId}__` : '';
      return this.columns.map(column => {
        const columnId = `${columnIdPrefix}${_.kebabCase(column.id)}`;
        let visible = true;
        if (!column.required) {
          if (columnId in this.columnVisibleMap)
            visible = this.columnVisibleMap[columnId];
          else if (column.hideByDefault) visible = false;
        }

        if (!this.isSuperAdminAccessible && column.superAdmin) visible = false;

        return { ...column, columnId, visible };
      });
    },
    tableRows() {
      const columnTypes = this.columns.map(({ type }) => type);
      if (
        columnTypes.includes('position') ||
        columnTypes.includes('positionDesc')
      ) {
        const { total_count, page, per } = this.resources;
        return this.rows.map((row, i) => ({
          ...row,
          position: total_count - (page - 1) * per - i
        }));
      } else if (columnTypes.includes('positionAsc')) {
        const { page, per } = this.resources;
        return this.rows.map((row, i) => ({
          ...row,
          position: (page - 1) * per + i + 1
        }));
      } else return this.rows;
    },
    visibleColumns() {
      return this.tableColumns
        .filter(c => c.visible)
        .map(column => ({
          ...column,
          columnStyle:
            column.columnStyle ||
            (column.type === 'row_select'
              ? 'row-select'
              : column.type === 'position'
              ? 'position'
              : null),
          style: column.width
            ? { flexBasis: column.width, flexGrow: 0, flexShrink: 0 }
            : {},
          label:
            column.label ||
            (column.type === 'position' ? this.$t('app.position') : null)
        }));
    },
    selectedRowIdsSet: {
      get() {
        return this.selectedRowIdsObj.set;
      },
      set(set) {
        this.selectedRowIdsObj = { set };
      }
    },
    selectedRows() {
      return this.rows.filter(r => this.selectedRowIdsSet.has(r.id));
    }
  },
  watch: {
    advancedSearchVisible: {
      immediate: true,
      handler(value) {
        this.$nextTick(() =>
          typeof this.$refs.virtualList?.onSlotResized == 'function'
            ? this.$refs.virtualList?.onSlotResized(
                'header',
                value ? this.advancedSearchHeight : 0
              )
            : null
        );
      }
    },
    visibleColumns() {
      this.$nextTick(this.recalcTableMinWidth);
    },
    rows() {
      if (this.selectedRowIds) return;

      const ids = this.rows
        .map(r => r.id)
        .filter(id => this.selectedRowIdsSet.has(id));
      this.selectedRowIdsSet = new Set(ids);
    },
    selectedRows() {
      this.$emit('select-rows', this.selectedRows);
    }
  },
  mounted() {
    this.isInLayer = !!this.$el.closest('.AppModal');

    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);

    this.$nextTick(this.recalcTableMinWidth);
    this.onResizeEnd = _.debounce(this.recalcTableMinWidth, 200);
    window.addEventListener('resize', this.onResizeEnd);

    const { headScroller, bodyScroller } = this.$refs;
    headScroller.addEventListener(
      'scroll',
      () => (bodyScroller.scrollLeft = headScroller.scrollLeft)
    );
    bodyScroller.addEventListener(
      'scroll',
      () => (headScroller.scrollLeft = bodyScroller.scrollLeft)
    );

    if (this.eventBus) this.eventBus.$on('focus-row', this.focusRow);
    this.$on('selectRow', (id, selected) => this.selectRow(id, selected));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResizeEnd);
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    onKeyDown(e) {
      this.pressedKeys[e.key] = true;
      if (
        (this.pressedKeys['Meta'] || this.pressedKeys['Control']) &&
        e.key === 'f'
      ) {
        e.preventDefault();
        this.pressedKeys = {};
        this.openDialog([
          'AppMessageDialog',
          {
            type: 'alert',
            title: '',
            markdownText: this.$t('alert_message', [this.resources.per]),
            snoozeId: 'AppVirtualListResourceTable.alert',
            width: DialogSize.SMALL
          }
        ]);
        this.$refs.virtualList.scrollToIndex(0);
      } else if (
        (this.pressedKeys['Meta'] && this.pressedKeys['ArrowDown']) ||
        this.pressedKeys['End']
      ) {
        e.preventDefault();
        this.pressedKeys = {};
        this.$refs.virtualList.scrollToBottom();
      } else if (
        (this.pressedKeys['Meta'] && this.pressedKeys['ArrowUp']) ||
        this.pressedKeys['Home']
      ) {
        e.preventDefault();
        this.pressedKeys = {};
        this.$refs.virtualList.scrollToIndex(0);
      }
    },
    onKeyUp(e) {
      this.pressedKeys[e.key] = false;
    },
    paginate(pagination) {
      this.$refs.virtualList.scrollToIndex(0);
      this.$emit('paginate', pagination);
    },
    selectRow(id, selected) {
      const set = this.selectedRowIdsSet;
      selected ? set.add(id) : set.delete(id);
      this.selectedRowIdsSet = new Set(set);
    },
    selectRows(selected) {
      this.selectedRowIdsSet = new Set(
        selected ? this.rows.map(r => r.id) : []
      );
    },
    recalcTableMinWidth() {
      const DESKTOP_PADDING_HORIZONTAL = this.isInLayer ? 0 : 32;
      const { head, body, finalHeadRow } = this.$refs;
      finalHeadRow.style.position = 'absolute';
      const cols = finalHeadRow.querySelectorAll(
        '.AppVirtualListResourceTable__cell'
      );
      const width = _.chain(cols)
        .map(e => e.offsetWidth)
        .sum()
        .value();

      finalHeadRow.style.position = '';
      head.style.minWidth = `${width + DESKTOP_PADDING_HORIZONTAL * 2}px`;
      body.style.minWidth = `${width + DESKTOP_PADDING_HORIZONTAL * 2}px`;
    },
    flexBasis(colspan) {
      const cols = this.visibleColumns.length;
      return cols === 0 ? 0 : `${(100 / cols) * colspan}%`;
    },
    focusRow(rowIndex) {
      if (!this.$refs.virtualList) return;
      this.$refs.virtualList.scrollToIndex(rowIndex);
      this.$nextTick(() => {
        const that = this;
        setTimeout(function() {
          const rowEl = this.document.getElementById(`row_${rowIndex}`);
          if (rowEl) that.highlightElement(rowEl);
        }, 100);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_layouts.scss';
@import '@/scss/vars/_z-indexes.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';

$row-height-default: 50px;
$row-height-stats: 36px;
$row-height-wide: 72px;

.AppVirtualListResourceTable {
  &--stats {
    .AppVirtualListResourceTable__head-row--sub {
      min-height: $row-height-stats;
    }

    .AppVirtualListResourceTableBodyRow {
      min-height: $row-height-stats;
    }

    .AppVirtualListResourceTableBodyRow:last-child {
      border-bottom: 1px solid $color-layout-section-dark;
    }

    .AppVirtualListResourceTable__cell {
      border-left: 1px solid;
      border-color: transparent;

      &:first-child {
        text-align: left;
      }

      & + .AppVirtualListResourceTable__cell {
        border-color: $color-layout-section;
      }
    }
  }

  &--wide {
    .AppVirtualListResourceTableBodyRow {
      min-height: $row-height-wide;
    }
  }
}

.AppVirtualListResourceTable__table {
  background-color: white;
  text-align: center;
}

.AppVirtualListResourceTable__head-sticky {
  position: sticky;
  z-index: $z-index-sticky;
  top: 0px;
}

.AppVirtualListResourceTable__head-scroller,
.AppVirtualListResourceTable__body-scroller {
  overflow-x: auto;
  @include main-negative-margin-horizontal;

  .AppVirtualListResourceTable--in-dialog & {
    margin-left: 0;
    margin-right: 0;
  }
}

.AppVirtualListResourceTable__head-scroller::-webkit-scrollbar,
.AppVirtualListResourceTable__body-scroller::-webkit-scrollbar {
  display: none;
}

.AppVirtualListResourceTable__head {
  background: white;
}

.AppVirtualListResourceTable__head,
.AppVirtualListResourceTable__body {
  @include main-padding-horizontal;

  .AppVirtualListResourceTable--in-dialog & {
    padding-left: 0;
    padding-right: 0;
  }
}

.AppVirtualListResourceTable__head-row {
  border-bottom: 1px solid $color-layout-section;
  display: flex;
  min-height: $row-height-default;
  box-sizing: content-box;
}

.AppVirtualListResourceTable__head-row {
  @include text-table-head;

  &:first-child {
    border-top: 1px solid $color-layout-section-dark;
  }

  &:last-child {
    border-bottom-color: $color-layout-section-dark;
  }

  &--sub {
    @include text-table-sub-head;
  }
}

.AppVirtualListResourceTable__no-data {
  border-bottom: 1px solid $color-layout-section;
}

.AppVirtualListResourceTable__cell {
  padding: 8px;
  box-sizing: border-box;
}

.AppVirtualListResourceTable__head-col-label {
  vertical-align: middle;
}

.AppVirtualListResourceTable__head-col-icon {
  vertical-align: middle;
  margin-left: 4px;
}
</style>

<i18n locale="ko">
{
  "alert_message": "리뷰 목록의 한 페이지에서 {0}개씩 리뷰 보기를 설정한 경우에는 브라우저에서 제공하는 기본 검색이 불가능하므로, 관리자 페이지 검색 기능을 사용해주세요."
}
</i18n>
