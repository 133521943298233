<template>
  <AppModalDefault :title="$t('title')" width="middle" @close="close">
    <template #body>
      {{ $t('description') }}
      <AppResourceTable
        class="TheBizMessageTemplateInspectedDialog"
        :columns="columns"
        :rows="rows"
        :enable-total-count="false"
      >
        <template #cell="{ column, row }">
          <template v-if="column === 'actions'">
            <AppButton
              :label="$t('app.show_detail')"
              @click="openBizMessageTemplateShowDialog(row.id)"
            />
          </template>
        </template>
      </AppResourceTable>
    </template>
    <template #foot>
      <AppButton
        :label="$t('show_campaign')"
        button-style="blue"
        @click="openBizMessageCampaignFormDialog"
      />
      <AppButton :label="$t('check_next_time')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import BizMessageCampaignType from '@/enums/BizMessageCampaignType';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'TheBizMessageTemplateInspectedDialog',
  mixins: [DialogView],
  props: { templates: { type: Array, required: true } },
  computed: {
    columns() {
      return [
        { id: 'name', label: this.$t('columns.name'), align: 'left' },
        {
          id: 'inspection_status',
          label: this.$t('columns.inspection_status'),
          type: 'badges'
        },
        { id: 'actions', label: this.$t('app.actions'), width: '130px' }
      ];
    },
    rows() {
      return this.templates.map(template => ({
        ...template,
        inspection_status: [this.badge(template.inspection_status)]
      }));
    }
  },
  mounted() {
    api.patch('/biz_message/templates/clear_inspected_template_ids');
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    badge(inspectionStatus) {
      return {
        label: BizMessageTemplateInspectionStatus.t(inspectionStatus),
        style: BizMessageTemplateInspectionStatus.badgeStyle(inspectionStatus)
      };
    },
    openBizMessageCampaignFormDialog() {
      this.openDialog([
        'BizMessageCampaignFormDialog',
        { campaignType: BizMessageCampaignType.REVIEW_MEMBER }
      ]);
      this.close();
    },
    openBizMessageTemplateShowDialog(templateId) {
      this.openDialog([
        'BizMessageTemplateShowDialog',
        {
          templateId,
          templateType: BizMessageTemplateType.CUSTOM,
          hideEditButton: true
        }
      ]);
    }
  }
};
</script>

<style scoped>
.TheBizMessageTemplateInspectedDialog {
  margin-top: 20px;
}
</style>

<i18n locale="ko">
{
  "title": "알림톡 템플릿 검수가 완료되었습니다.",
  "description": "승인된 템플릿만 발송할 메시지로 설정 가능합니다.",
  "columns": {
    "name": "템플릿 명",
    "inspection_status": "검수결과"
  },
  "show_campaign": "발송설정 바로가기",
  "check_next_time": "다음에 설정"
}
</i18n>
