<template>
  <div>
    <SummaryBoxInstallSimulationConfirm />
    <ReplaceReviewsCount />
    <NewReviewLink />
    <ProductReviewsLink />
    <MyPendingReviewsCount />
    <MyWrittenReviewsCount />
  </div>
</template>

<script>
import SummaryBoxInstallSimulationConfirm from './SummaryBoxInstallSimulationConfirm';
import ProductReviewsLink from '@/views/review/install_simulation/components/ProductReviewsLink';
import NewReviewLink from '@/views/review/install_simulation/components/NewReviewLink';
import ReplaceReviewsCount from '@/views/review/install_simulation/components/ReplaceReviewsCount';
import MyPendingReviewsCount from '@/views/review/install_simulation/components/MyPendingReviewsCount';
import MyWrittenReviewsCount from '@/views/review/install_simulation/components/MyWrittenReviewsCount';
import { mapActions } from 'vuex';

export default {
  name: 'ReviewInstallSimulationEtc',
  components: {
    SummaryBoxInstallSimulationConfirm,
    ReplaceReviewsCount,
    NewReviewLink,
    ProductReviewsLink,
    MyPendingReviewsCount,
    MyWrittenReviewsCount
  },
  mounted() {
    this.setMenuType('etc');
  },
  methods: {
    ...mapActions('reviewInstallSimulation', ['setMenuType'])
  }
};
</script>
