import Vue from 'vue';
import VueI18n from 'vue-i18n';
import _ from 'lodash';
import jaso from '@/lib/jaso';
import loadLocaleMessages from '@/lib/loadLocaleMessages';

Vue.use(VueI18n);

const dateTimeFormats = {
  ko: {
    year_month: {
      year: 'numeric',
      month: 'short'
    },
    date_with_wday: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'long'
    }
  },
  en: {
    year_month: {
      year: 'numeric',
      month: 'short'
    },
    date_with_wday: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'long'
    }
  }
};

const vueI18n = new VueI18n({
  locale: 'ko',
  fallbackLocale: 'ko',
  silentFallbackWarn: true,
  messages: loadLocaleMessages(),
  dateTimeFormats
});

const orgInterpolate = vueI18n._formatter.interpolate;
vueI18n._formatter.interpolate = (message, values) => {
  if (Array.isArray(message)) return message;

  const numberFormattedValues =
    typeof values === 'object'
      ? _.mapValues(values, v => (_.isNumber(v) ? v.toLocaleString() : v))
      : values;
  const texts = orgInterpolate.apply(vueI18n._formatter, [
    message,
    numberFormattedValues
  ]);

  const mergedTexts = texts.reduce((a, t) => {
    const isMergeable = typeof t === 'string' && typeof _.last(a) === 'string';
    if (isMergeable) a[a.length - 1] += t;
    else a.push(t);
    return a;
  }, []);

  return mergedTexts.map(t => (typeof t === 'string' ? jaso(t) : t));
};

export default vueI18n;
