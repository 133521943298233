import i18n from '@/lib/i18n';

export default {
  env: null,
  currentPath: null,
  currentQuery: null,
  currentPathParams: {},
  brandParams: {},
  dateRangeParams: {},
  title: i18n.t('app.title_default'),
  isContentReady: false
};
