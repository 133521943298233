import { constructEnum } from '@/lib/enum';
import InstallationPresetPlacementType from '@/enums/InstallationPresetPlacementType';
import WidgetSetType from '@/enums/WidgetSetType';

const InstallationPresetWidgetType = constructEnum(
  'InstallationPresetWidgetType',
  {
    PRODUCT_DETAIL_LIST_WIDGET: 100,
    PRODUCT_DETAIL_COUNT_WIDGET: 110,
    PRODUCT_DETAIL_HORIZONTAL_SCROLL_WIDGET: 120,
    PRODUCT_DETAIL_BOARD_WIDGET: 130,
    PRODUCT_DETAIL_GALLERY_WIDGET: 140,
    PRODUCT_DETAIL_OPTIONS_WIDGET: 150,
    PRODUCT_DETAIL_PREVIEW_WIDGET: 160,
    PRODUCT_DETAIL_SCORE_WIDGET: 170,
    PRODUCT_DETAIL_DETACHABLE_BOARD_WIDGET: 180,
    PRODUCT_DETAIL_SOCIAL_WIDGET: 190,
    REVIEW_BOARD_GALLERY_WIDGET: 300,
    REVIEW_BOARD_BEST_WIDGET: 310,
    REVIEW_BOARD_LIST_WIDGET: 320,
    REVIEW_BOARD_HORIZONTAL_SCROLL_WIDGET: 330,
    REVIEW_BOARD_PRODUCTS_GALLERY_WIDGET: 340,
    REVIEW_BOARD_BOARD_WIDGET: 350,
    MAIN_GALLERY_WIDGET: 500,
    MAIN_ROUND_THUMBNAIL_GALLERY_WIDGET: 510,
    MAIN_SQUARE_WIDGET: 520,
    MAIN_PRODUCTS_GALLERY_WIDGET: 530,
    MAIN_HORIZONTAL_SCROLL_WIDGET: 540,
    MY_REVIEWS_MANAGING_REVIEWS_WIDGET: 700,
    MY_ORDERS_MANAGING_REVIEWS_WIDGET: 710,
    MYSHOP_MAIN_MANAGING_REVIEWS_WIDGET: 720,
    REVIEW_POPUP_WIDGET: 800,
    COUNT_AND_SCORE_WIDGET: 900
  }
);

InstallationPresetWidgetType.installPositions = (
  widgetSetType,
  presetWidgetType
) => {
  switch (presetWidgetType) {
    case InstallationPresetWidgetType.PRODUCT_DETAIL_LIST_WIDGET:
      return [
        InstallationPresetPlacementType.REPLACE_REVIEW_SECTION,
        InstallationPresetPlacementType.PREPEND_PRODUCT_DETAIL,
        InstallationPresetPlacementType.APPEND_PAGE
      ];
    case InstallationPresetWidgetType.PRODUCT_DETAIL_PREVIEW_WIDGET:
    case InstallationPresetWidgetType.PRODUCT_DETAIL_SCORE_WIDGET:
      return [
        InstallationPresetPlacementType.AFTER_THUMBNAIL_IMAGE,
        InstallationPresetPlacementType.AFTER_BUY_BUTTON
      ];
    case InstallationPresetWidgetType.PRODUCT_DETAIL_DETACHABLE_BOARD_WIDGET:
    case InstallationPresetWidgetType.PRODUCT_DETAIL_SOCIAL_WIDGET:
    case InstallationPresetWidgetType.PRODUCT_DETAIL_HORIZONTAL_SCROLL_WIDGET:
      return [
        InstallationPresetPlacementType.PREPEND_PRODUCT_DETAIL,
        InstallationPresetPlacementType.BEFORE_REVIEW_SECTION
      ];
    case InstallationPresetWidgetType.REVIEW_BOARD_GALLERY_WIDGET:
      return [InstallationPresetPlacementType.BEFORE_LIST_WIDGET];
    case InstallationPresetWidgetType.REVIEW_BOARD_BEST_WIDGET:
      if (widgetSetType == WidgetSetType.BEST_GALLERY_AND_PRODUCTS_GALLERY) {
        return [InstallationPresetPlacementType.BEFORE_PRODUCTS_GALLERY_WIDGET];
      } else {
        return [InstallationPresetPlacementType.BEFORE_GALLERY_WIDGET];
      }
    case InstallationPresetWidgetType.REVIEW_BOARD_PRODUCTS_GALLERY_WIDGET:
    case InstallationPresetWidgetType.REVIEW_BOARD_LIST_WIDGET:
      return [
        InstallationPresetPlacementType.REPLACE_PAGE,
        InstallationPresetPlacementType.REPLACE_PAGE_EXCEPT_TITLE,
        InstallationPresetPlacementType.REPLACE_PAGE_EXCEPT_TITLE_AND_IMAGE
      ];
    case InstallationPresetWidgetType.MAIN_GALLERY_WIDGET:
    case InstallationPresetWidgetType.MAIN_ROUND_THUMBNAIL_GALLERY_WIDGET:
    case InstallationPresetWidgetType.MAIN_SQUARE_WIDGET:
    case InstallationPresetWidgetType.MAIN_PRODUCTS_GALLERY_WIDGET:
    case InstallationPresetWidgetType.MAIN_HORIZONTAL_SCROLL_WIDGET:
      return [
        InstallationPresetPlacementType.REPLACE_REVIEW_SECTION,
        InstallationPresetPlacementType.APPEND_PAGE,
        InstallationPresetPlacementType.CUSTOM
      ];
  }
};

export default InstallationPresetWidgetType;
