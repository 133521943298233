<template>
  <div class="ReviewMessagesBizMessagesSettings">
    <BizMessageSettings :service-type="CremaServiceType.REVIEW" />
    <template v-if="isRegistered">
      <div>
        <div class="ReviewMessagesBizMessagesSettings__campaigns">
          <BizMessageCampaigns />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CremaServiceType from '@/enums/CremaServiceType';
import BizMessageSettings from '../../biz_messages/components/BizMessageSettings.vue';
import BizMessageCampaigns from '../../biz_messages/components/BizMessageCampaigns.vue';

export default {
  name: 'ReviewMessagesBizMessagesSettings',
  components: { BizMessageSettings, BizMessageCampaigns },
  data() {
    return { isLoading: true };
  },
  computed: {
    ...mapState('bizMessage', ['bizMessageProfile']),
    CremaServiceType: () => CremaServiceType,
    isRegistered() {
      return !!this.bizMessageProfile.yellow_id;
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewMessagesBizMessagesSettings__campaigns {
  margin-top: 32px;
}
</style>
