<template>
  <div class="AppTableWithTopbar">
    <AppTableTopbar v-bind="{ tableName, isInLayer, customButtons, tooltip }" />
    <AppTable v-bind="{ tableId, columns, rows }">
      <template #cell="cellProps">
        <slot name="cell" v-bind="cellProps" />
      </template>
    </AppTable>
  </div>
</template>

<script>
export default {
  name: 'AppTableWithTopbar',
  props: {
    tableId: { type: String, default: null },
    columns: { type: Array, required: true },
    rows: { type: Array, required: true },
    tableName: { type: String, required: true },
    customButtons: { type: Array, required: true },
    tooltip: { type: [String, Object], required: true }
  },
  data: () => ({ isInLayer: false }),
  mounted() {
    this.isInLayer =
      !!this.$el.closest('.AppModal') || !!this.$el.closest('.AppDrawer');
  }
};
</script>
