<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic</div>
      <div class="ui-section__item">
        <AppTable :columns="COLUMNS" :rows="ROWS" />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Basic with Topbar</div>
      <div class="ui-section__item">
        <AppTableWithTopbar
          :columns="COLUMNS"
          :rows="ROWS"
          :table-name="TABLENAME"
          :tooltip="TOOLTIP"
          :custom-buttons="CUSTOM_BUTTONS"
        />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">No Head</div>
      <div class="ui-section__item">
        <AppTable :columns="COLUMNS" :rows="ROWS" no-head />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Tooltip</div>
      <div class="ui-section__item">
        <AppTable :columns="TOOLTIP_COLUMNS" :rows="[]" />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Highlight Row</div>
      <div class="ui-section__item">
        <AppTable :columns="COLUMNS" :rows="ROWS_ACTIVE" />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">No Data With Custom Slot</div>
      <div class="ui-section__item">
        <AppTable :columns="COLUMNS" :rows="[]">
          <template #no-data>
            데이터가 없습니다. 이 것은 슬롯으로 정의된 내용입니다.
          </template>
        </AppTable>
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Badge Column</div>
      <div class="ui-section__item">
        <AppTable
          :columns="[{ id: 'badge', label: 'Badge', type: 'badge' }]"
          :rows="[{ badge: { label: 'badge', badgeStyle: 'red' } }]"
        />
      </div>
    </div>
  </div>
</template>

<script>
const COLUMNS = [
  { id: 'string', label: 'Item' },
  { id: 'check', label: 'Purchased', type: 'check' },
  { id: 'price', label: 'Price', type: 'currency' },
  { id: 'rate', label: '%', type: 'rate' },
  { id: 'tags', label: 'Tags', type: 'badges' },
  {
    id: 'description',
    label: 'Description',
    type: 'nowrap',
    autoTooltip: true,
    width: '150px'
  }
];

export default {
  name: 'UiTable',
  data() {
    return {
      TABLENAME: 'Table Name',
      COLUMNS: COLUMNS,
      TOOLTIP: 'tooltip',
      TOOLTIP_COLUMNS: COLUMNS.map(column => ({
        ...column,
        tooltip: column.label
      })),
      ROWS: [
        {
          id: 1,
          string: 'Carrot',
          tags: [{ id: 1, label: 'vegetable', style: 'mint-green-outline' }],
          check: true,
          price: 1000,
          rate: 0.4
        },
        {
          id: 2,
          string: 'Strawberry',
          tags: [
            { id: 2, label: 'fruit', style: 'mango-outline' },
            { id: 3, label: 'daily', style: 'red-outline' }
          ],
          check: false,
          price: 1200,
          rate: 0.2455,
          description: 'I can see all.'
        },
        { id: 3, string: 'Milk', check: true, price: 800, rate: 0.3545 },
        {
          id: 4,
          string: 'Croissant',
          price: 3900,
          rate: 0,
          description:
            'A croissant is a buttery, flaky, viennoiserie pastry of Austrian origin, named for its historical crescent shape.'
        }
      ],
      ROWS_ACTIVE: [
        {
          id: 1,
          string: 'Carrot',
          tags: [{ id: 1, label: 'vegetable', style: 'mint-green-outline' }],
          check: true,
          price: 1000,
          rate: 0.4
        },
        {
          id: 2,
          string: 'Strawberry',
          tags: [
            { id: 2, label: 'fruit', style: 'grey-outline', size: 'small' },
            { id: 3, label: 'daily', size: 'small' }
          ],
          check: false,
          price: 1200,
          rate: 0.2455
        },
        {
          id: 3,
          string: 'Milk',
          check: true,
          price: 800,
          rate: 0.3545,
          highlight: true
        },
        {
          id: 4,
          string: 'Croissant',
          price: 3900,
          rate: 0,
          description: 'My favorite!'
        }
      ],
      CUSTOM_BUTTONS: [
        {
          label: 'Button1',
          buttonStyle: 'default'
        },
        {
          label: 'Button2',
          buttonStyle: 'blue'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppTable__body-col.description {
    .AppTable__body-col--nowrap {
      width: 150px;
    }
  }
}
</style>
