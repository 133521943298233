<template>
  <TargetSettingsSection
    :title="$t('title')"
    class="TargetCampaignSettingsProductRecommendation"
    @open-settings="openSettings"
  >
    <div>{{ $t('description', [defaultFilterName]) }}</div>
    <AppTable
      v-bind="{ columns, rows }"
      table-id="target-campaign-settings-recommendation-product-table"
    >
      <template #cell="{ row, column }">
        <template v-if="column.id === 'displayable_categories'">
          <div
            v-tooltip="{
              textAlign: 'left',
              message: row.displayableCategoriesInfo.tooltip
            }"
          >
            {{ row.displayableCategoriesInfo.label }}
          </div>
        </template>
      </template>
    </AppTable>
  </TargetSettingsSection>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import TargetWidgetRecommendationFilterType from '@/enums/TargetWidgetRecommendationFilterType';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetProductRecommendationFilterType from '@/enums/TargetProductRecommendationFilterType';
import TargetProductRecommendationPeriod from '@/enums/TargetProductRecommendationPeriod';

export default {
  name: 'TargetCampaignSettingsProductRecommendation',
  computed: {
    ...mapState('session', ['reviewSettings']),
    ...mapState('targetCampaign', ['campaign']),
    ...mapGetters('productCategory', [
      'productCategoryShortInfo',
      'productCategoryInfo'
    ]),
    ...mapGetters('targetCampaign', ['isCategoryPageWidgetCampaign']),
    displayableCategoriesColumn() {
      if (!this.isCategoryPageWidgetCampaign) return [];

      return [
        {
          id: 'displayable_categories',
          label: this.$t('displayable_categories.label')
        }
      ];
    },
    columns() {
      return [
        ...this.displayableCategoriesColumn,
        { id: 'name', label: this.$t('name') },
        {
          id: 'max_products',
          type: 'number',
          label: this.$t('max_products')
        },
        { id: 'summary', type: 'pre', label: this.$t('app.summary') }
      ].map(column => ({ ...column, align: 'left' }));
    },
    rows() {
      return this.campaign.widget_recommendation_units.map(unit => ({
        displayableCategoriesInfo: this.displayableCategoriesInfo(unit),
        name: TargetWidgetRecommendationFilterType.tWithTitle(
          unit.recommendation_config.recommendation_filter_type
        ),
        max_products: unit.max_products,
        summary: this.summary(unit.recommendation_config)
      }));
    },
    defaultFilterName() {
      return this.$t(
        `default_recommendation_unit.${TargetCampaignType.key(
          this.campaign.campaign_type
        )}`
      );
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openSettings() {
      this.openDialog([
        'TargetCampaignSettingsProductRecommendationDialog',
        { campaign: this.campaign }
      ]);
    },
    productCategoryInfos(ids) {
      return ids.map(id => this.productCategoryInfo(id)).join('<br />');
    },
    displayableCategoriesInfo(unit) {
      if (!unit.use_displayable_category_ids) {
        return {
          label: this.$t('displayable_categories.all.label'),
          tooltip: this.$t('displayable_categories.all.tooltip')
        };
      }
      if (unit.displayable_category_ids.length === 1) {
        return {
          label: this.$t('displayable_categories.one.label', [
            this.productCategoryShortInfo(unit.displayable_category_ids[0])
          ]),
          tooltip: this.$t('displayable_categories.one.tooltip_html', [
            this.productCategoryInfo(unit.displayable_category_ids[0])
          ])
        };
      }
      return {
        label: this.$t('displayable_categories.multiple.label', [
          this.productCategoryShortInfo(unit.displayable_category_ids[0]),
          unit.displayable_category_ids.length - 1
        ]),
        tooltip: this.$t('displayable_categories.multiple.tooltip_html', [
          this.productCategoryInfos(unit.displayable_category_ids)
        ])
      };
    },
    summary(recommendationConfig) {
      const filterOptions = recommendationConfig.filter_data.filter_options;
      const filterType = recommendationConfig.recommendation_filter_type;
      const filterName = TargetWidgetRecommendationFilterType.key(filterType);
      const filterCondition = `target_widget_recommendation_filter_summary.${filterName}`;
      switch (filterType) {
        case TargetWidgetRecommendationFilterType.POPULAR:
          return this.$t(filterCondition, [
            filterOptions.recent_days,
            TargetProductRecommendationFilterType.t(
              filterOptions.recommendation_method
            )
          ]);
        case TargetWidgetRecommendationFilterType.NEW_REGISTERED:
        case TargetWidgetRecommendationFilterType.NEW_REGISTERED_IN_CATEGORY:
        case TargetWidgetRecommendationFilterType.NEW_REGISTERED_PRODUCTS_BY_CATEGORY:
          return this.$t(
            'target_widget_recommendation_filter_summary.NEW_REGISTERED',
            [
              this.$t(
                `NEW_REGISTERED.use_registered_days.${filterOptions.use_registered_days}`
              ),
              filterOptions[
                filterOptions.use_registered_days
                  ? 'registered_days'
                  : 'updated_days'
              ]
            ]
          );
        case TargetWidgetRecommendationFilterType.SAME_CATEGORY_POPULAR:
          return this.$t(filterCondition, [
            filterOptions.recent_days,
            TargetProductRecommendationFilterType.t(
              filterOptions.recommendation_method
            )
          ]);
        case TargetWidgetRecommendationFilterType.SAME_CATEGORY_NEW_REGISTERED:
          return this.$t(filterCondition, [
            this.$t(
              `NEW_REGISTERED.use_registered_days.${filterOptions.use_registered_days}`
            ),
            filterOptions[
              filterOptions.use_registered_days
                ? 'registered_days'
                : 'updated_days'
            ]
          ]);
        case TargetWidgetRecommendationFilterType.PURCHASED_TOGETHER:
        case TargetWidgetRecommendationFilterType.CLICKED_BY_CLICKED_USER:
        case TargetWidgetRecommendationFilterType.PURCHASED_BY_CLICKED_USER:
          return this.$t(filterCondition, [filterOptions.recent_days]);
        case TargetWidgetRecommendationFilterType.HAND_PICKED_PRODUCTS:
          return this.$t('HAND_PICKED_PRODUCTS.summary', [
            filterOptions.hand_picked_product_ids.length
          ]);
        case TargetWidgetRecommendationFilterType.HIGH_SCORED_PRODUCTS_IN_CATEGORY:
          return this.$t(filterCondition, [
            filterOptions.review_score * this.reviewSettings.score_multiplier,
            filterOptions.reviews_count
          ]);
        case TargetWidgetRecommendationFilterType.PERIODICAL_POPULAR_IN_CATEGORY:
        case TargetWidgetRecommendationFilterType.PERIODICAL_POPULAR:
          return this.$t('PERIODICAL_POPULAR.summary', [
            filterOptions.period_options
              .map(period => TargetProductRecommendationPeriod.t(period))
              .join('/'),
            filterOptions.recommendation_methods
              .map(method => TargetProductRecommendationFilterType.t(method))
              .join('/')
          ]);
        case TargetWidgetRecommendationFilterType.POPULAR_PRODUCTS_BY_CATEGORY:
          return this.$t('POPULAR_PRODUCTS_BY_CATEGORY.summary', [
            filterOptions.period_options
              .map(period => TargetProductRecommendationPeriod.t(period))
              .join('/'),
            TargetProductRecommendationFilterType.t(
              filterOptions.recommendation_method
            )
          ]);
        default:
          return this.$t(filterCondition);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignSettingsProductRecommendation {
  padding-bottom: 24px;
}

::v-deep {
  .target-campaign-settings-recommendation-product-table {
    margin-top: 20px;
  }
}
</style>

<i18n locale="ko">
{
  "name": "추천 항목 이름",
  "title": "캠페인에 노출할 추천 상품",
  "description": "기준에 맞춰 노출할 수 있는 항목이 하나도 없는 경우 {0}을 대체 상품으로 노출합니다.",
  "default_recommendation_unit": {
    "PRODUCT_RECOMMENDATION_DETAIL_PAGE": "쇼핑몰 전체 인기 상품",
    "PRODUCT_RECOMMENDATION_CATEGORY_PAGE" : "카테고리 인기 상품",
    "PRODUCT_RECOMMENDATION_MAIN_PAGE": "쇼핑몰 전체 인기 상품",
    "PRODUCT_RECOMMENDATION_CART_PAGE": "쇼핑몰 전체 인기 상품",
    "PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT": "쇼핑몰 전체 인기 상품",
    "PRODUCT_RECOMMENDATION_CART_POPUP": "쇼핑몰 전체 인기 상품"
  },
  "displayable_categories": {
    "label": "노출 카테고리",
    "all": {
      "label": "전체",
      "tooltip": "이 추천 항목을 쇼핑몰의 모든 카테고리 페이지에 노출합니다."
    },
    "one": {
      "label": "{0}",
      "tooltip_html": "이 추천 항목을 아래 카테고리 페이지에 노출합니다.<br />{0}"
    },
    "multiple": {
      "label": "{0} 외 {1}개",
      "tooltip_html": "이 추천 항목을 아래 카테고리 페이지에 노출합니다.<br />{0}"
    }
  },
  "max_products": "추천 상품 수",
  "NEW_REGISTERED": {
    "use_registered_days": {
      "true": "상품 등록일",
      "false": "상품 최근 입력일"
    }
  },
  "HAND_PICKED_PRODUCTS": {
    "summary": "직접 선택한 추천상품 {0}개"
  },
  "PERIODICAL_POPULAR": {
    "summary": "최근 {0} 사람들이 가장 많이 {1} 상품 추천"
  },
  "POPULAR_PRODUCTS_BY_CATEGORY": {
    "summary": "최근 {0} 사람들이 설정 카테고리에서 가장 많이 {1} 상품 추천"
  }
}
</i18n>
