<template>
  <div>
    <template v-for="buttonStyle in BUTTON_STYLES">
      <div :key="buttonStyle" class="ui-section">
        <div class="ui-section__title">
          <label for="basic">{{ buttonStyle }}</label>
        </div>
        <div
          :class="[
            'ui-section__item',
            buttonStyle === 'transparent' ? 'UiButton__transparent' : null
          ]"
        >
          <AppButton :button-style="buttonStyle" label="Normal" />
          <AppButton :button-style="buttonStyle" label="Disabled" disabled />
          <template v-if="isToggleable(buttonStyle)">
            <AppButtonToggle
              v-model="toggleButtons[buttonStyle]"
              :button-style="buttonStyle"
              label="Toggle"
            />
            <AppButtonToggle
              v-model="toggleButtons[buttonStyle]"
              :button-style="buttonStyle"
              label="Toggle(Disabled)"
              disabled
            />
          </template>
        </div>
        <div class="ui-section__description">
          Selected Value: {{ toggleButtons[buttonStyle] }}
        </div>
      </div>
    </template>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Disabled with Tooltip</label>
      </div>
      <AppButton
        label="Click me"
        disabled
        tooltip="This button is disabled and has tooltip"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Disabled link</label>
      </div>
      <AppButton
        label="Click me to go to button-download"
        type="link"
        to="/ui/button-download"
      />
      <AppButton
        label="Click me and noting happens"
        disabled
        type="link"
        to="/ui/button-download"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">External link</label>
      </div>
      <AppButton
        label="Click me to open a new tab"
        type="external_link"
        to="http://cre.ma"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">External link</label>
      </div>
      <AppButton
        label="Click me to open a new tab"
        type="external_link"
        to="http://cre.ma"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Bubble</label>
      </div>
      <div>
        <AppButton
          label="Left Bubble"
          bubble-message="message!"
          style="float: right;"
        />
      </div>
      <div>
        <AppButton
          label="Right Bubble"
          bubble-message="message!"
          bubble-side="right"
        />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="inline_button_default"
          >AppInlineButton[button-style=default]</label
        >
      </div>
      <div class="ui-section__item">
        <AppInlineButton
          id="inline_button_default"
          label="Click me"
          @click="createToast('Clicked!')"
        />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="inline_button_caption"
          >AppInlineButton[button-style=caption]</label
        >
      </div>
      <div class="ui-section__item">
        <AppInlineButton
          id="inline_button_caption"
          label="Click me"
          button-style="caption"
          @click="createToast('Clicked!')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'UiButton',
  data() {
    const BUTTON_STYLES = [
      'default',
      'input',
      'label',
      'grey',
      'grey-outline',
      'mango-outline',
      'blue-clear',
      'blue-outline',
      'blue',
      'red-outline',
      'red',
      'green',
      'mint-green',
      'grey-clear',
      'black-opaque',
      'underline',
      'transparent'
    ];
    return {
      BUTTON_STYLES,
      toggleButtons: _.fromPairs(BUTTON_STYLES.map(s => [s, false]))
    };
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    isToggleable(buttonStyle) {
      return buttonStyle !== 'label';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.UiButton__transparent {
  background-color: $color-review-black;
  padding: 8px;
}
</style>
