import { mapGetters } from 'vuex';

import ResourceView from '@/mixins/ResourceView';
import { nullResources } from '@/lib/resources';
import api from '@/lib/api';
import moment from 'moment';

export default {
  mixins: [ResourceView],
  data() {
    return {
      isLoading: true,
      messages: nullResources,
      infoMessage: this.$t('message.info')
    };
  },
  computed: {
    ...mapGetters('request', ['isRequestPending']),
    defaultResourceParams() {
      return {
        start_date: moment()
          .subtract(364, 'days')
          .toVal(),
        end_date: moment().toVal()
      };
    },
    searchBarEvents() {
      return {
        'change-date': this.changeDate,
        'change-search-data': this.changeSearchData,
        submit: this.searchResource
      };
    },
    rows() {
      return this.messages.items.map(message => {
        const date = [];
        const { formatDateTime } = this.$options.filters;
        if (message.created_at) {
          date.push(
            `${this.$t('message.created_at')}: ${formatDateTime(
              message.created_at
            )}`
          );
        }
        if (message.sent_at) {
          date.push(
            `${this.$t('message.sent_at')}: ${formatDateTime(message.sent_at)}`
          );
        }
        if (message.first_checked_at) {
          date.push(
            `${this.$t('message.first_checked_at')}: ${formatDateTime(
              message.first_checked_at
            )}`
          );
        }
        if (message.last_checked_at) {
          date.push(
            `${this.$t('message.last_checked_at')}: ${formatDateTime(
              message.last_checked_at
            )}`
          );
        }
        const products = message.products.map(
          product =>
            `${product.sub_brand_name ? `[${product.sub_brand_name}]` : ''} ${
              product.name
            }`
        );
        return {
          ...message,
          date: date.join('\n'),
          userName: message.brand_user.user_name,
          userUsername:
            message.brand_user.user_username || this.$t('message.nonmember'),
          products: products.join('\n')
        };
      });
    }
  },
  methods: {
    changeDate(dateRange) {
      this.resourceParams = {
        ...this.resourceParams,
        ...dateRange
      };
      this.searchResource();
    },
    changeSearchData(searchWithType) {
      this.resourceParams = { ...this.resourceParams, ...searchWithType };
    },
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(this.MESSAGES_URL, {
          params
        })
        .then(({ data }) => {
          this.messages = data[this.MEDIA];
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
