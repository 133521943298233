import api from '@/lib/api';
import moment from 'moment';
import HttpStatus from '@/enums/HttpStatus';
import DialogSize from '@/enums/DialogSize';
import { mapActions } from 'vuex';

const datetimeToDate = datetime => moment(datetime).toVal();
const datetimeToTime = datetime => moment(datetime).format('HH:00');
const datetimeToHour = datetime => moment(datetime).hour();
const hourToTime = hour => `${hour.toString().padStart(2, '0')}:00`;
const toDatetime = (date, time) => moment(`${date} ${time}`).toISOString();

export default {
  data: () => ({ godoApiRegisteredAt: moment().toVal() }),
  computed: {
    godoApiRegisteredDate: {
      get() {
        return datetimeToDate(this.godoApiRegisteredAt);
      },
      set(date) {
        const time = datetimeToTime(this.godoApiRegisteredAt);
        this.godoApiRegisteredAt = toDatetime(date, time);
      }
    },
    godoApiRegisteredHour: {
      get() {
        return datetimeToHour(this.godoApiRegisteredAt);
      },
      set(hour) {
        const date = datetimeToDate(this.godoApiRegisteredAt);
        const time = hourToTime(hour);
        this.godoApiRegisteredAt = toDatetime(date, time);
      }
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    confirmGodoApiRegistration() {
      api
        .post(
          '/review/service_runner/confirm_godo_api_join_request',
          { join_requested_datetime: this.godoApiRegisteredAt },
          { silent: true },
          { successMessage: this.$t('confirm_godo_api_join_request.requested') }
        )
        .then(({ data }) => {
          this.openDialog([
            'AppMessageDialog',
            {
              type: 'alert',
              title: this.$t('confirm_godo_api_join_request.success'),
              markdownText: this.$t(
                'confirm_godo_api_join_request.service_code',
                [data.code]
              ),
              width: DialogSize.AUTO
            }
          ]);
        })
        .catch(error => {
          if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            alert(this.$t(error.response.data.errors[0]));
          } else error.errorHandler();
        });
    }
  }
};
