<template>
  <TargetSettingsSection :title="$t('title')" @open-settings="openSmsDialog">
    <template v-if="!campaign.enable_sms">
      {{ $t('disabled') }}
    </template>
    <template v-else-if="!campaign.configured_message">
      {{ $t('not_configured') }}
    </template>
    <div v-else>
      <div>{{ $t('sub_title') }}</div>
      <div>
        <b>{{ messageSummary }}</b>
      </div>
    </div>
  </TargetSettingsSection>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TargetSettingsSection from './components/TargetSettingsSection';
import TargetMessageMedia from '@/enums/TargetMessageMedia';

export default {
  name: 'TargetDmCampaignSettingsSms',
  components: { TargetSettingsSection },
  computed: {
    ...mapState('targetCampaign', ['campaign']),
    messageSummary() {
      return TargetMessageMedia.t(this.campaign.message_type);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openSmsDialog() {
      this.openDialog([
        'TargetDmCampaignSettingsDesignMessageDialog',
        { campaign: this.campaign }
      ]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "문자 메시지",
  "sub_title": "발송 매체",
  "disabled": "미발송",
  "not_configured": "메시지 종류와 문구를 설정해주세요."
}
</i18n>
