var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalDefault',{attrs:{"title":_vm.$t('menu.notices_dialog'),"width":"wide"},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppResourceTable',{attrs:{"table-id":"notices-table","columns":[
          { id: 'position', type: 'position' },
          { id: 'created_at', label: _vm.$t('app.created_at'), type: 'date' },
          { id: 'title', label: _vm.$t('app.title'), align: 'left' }
        ],"resources":_vm.notices,"rows":_vm.notices.items},on:{"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
        var column = ref.column;
        var row = ref.row;
return [(column === 'title')?[_c('a',{on:{"click":function($event){return _vm.clickNotice(row)}}},[_vm._v(_vm._s(row.title))])]:_vm._e()]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }