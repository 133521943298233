<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm :sections="formSections" form-style="hor" />
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';

export default {
  name: 'ReviewMoveShopBuilderAdditionalService',
  data: () => ({
    isLoading: true,
    sourceBrandOptions: [],
    sourceBrandId: null,
    sourceBrandSyncStartDate: null,
    syncBrandOptions: [],
    syncBrands: [],
    hasProductCodeConversion: false,
    isConvertingProductCode: false
  }),
  computed: {
    formSections() {
      return [
        {
          id: 'review_sync',
          label: this.$t('review_sync'),
          groups: [
            ...this.sourceBrandGroups,
            ...this.syncBrandGroups,
            {
              id: 'source_product_code_conversion',
              label: null,
              type: 'button',
              value: this.$t('source_product_code_conversion'),
              buttonStyle: 'blue',
              disabled: this.sourceProductCodeConversionDisabled,
              buttonTooltip: this.sourceProductCodeConversionTooltip,
              clickHandler: this.convertSourceProductCode
            }
          ].filter(g => g)
        }
      ];
    },
    sourceBrandGroups() {
      if (!this.sourceBrandId) return [];

      return [
        {
          id: 'source_brand',
          label: this.$t('source_brand'),
          type: 'select',
          value: this.sourceBrandId,
          options: this.sourceBrandOptions,
          disabled: true
        },
        {
          id: 'review_sync_start_date',
          label: this.$t('review_sync_start_date'),
          type: 'date',
          value: this.sourceBrandSyncStartDate,
          disabled: true
        }
      ];
    },
    syncBrandGroups() {
      const groups = [];
      this.syncBrands.forEach((brand, index) =>
        groups.push(
          {
            id: `sync_brands${index}`,
            label: this.$t('sync_brands'),
            type: 'select',
            value: brand.id,
            options: this.syncBrandOptions,
            disabled: true
          },
          {
            id: `sync_brand_review_sync_start_date${index}`,
            label: this.$t('review_sync_start_date'),
            type: 'date',
            value: brand.review_sync_start_date,
            disabled: true
          }
        )
      );

      return groups;
    },
    sourceProductCodeConversionTooltip() {
      if (this.hasProductCodeConversion) return;

      return this.$t('source_product_code_conversion_tooltip');
    },
    sourceProductCodeConversionDisabled() {
      return !(
        this.hasProductCodeConversion &&
        this.syncBrands.length &&
        !this.isConvertingProductCode
      );
    }
  },
  mounted() {
    api
      .get('/review/move_shop_builder/review_sync_info')
      .then(({ data }) => {
        this.isConvertingProductCode = data.is_converting_product_code;
        this.hasProductCodeConversion = data.has_product_code_conversion;
        this.setSourceBrand(data.source_brand_info_map);
        this.setSyncBrands(data.sync_brands_info_map);
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    setSourceBrand(source_brand_info_map) {
      if (!source_brand_info_map) return;

      this.sourceBrandOptions = [
        {
          label: source_brand_info_map.name,
          value: source_brand_info_map.id
        }
      ];
      this.sourceBrandSyncStartDate =
        source_brand_info_map.review_sync_start_date;
      this.sourceBrandId = source_brand_info_map.id;
    },
    setSyncBrands(sync_brands_info_map) {
      for (const brandId in sync_brands_info_map) {
        this.syncBrandOptions.push({
          label: sync_brands_info_map[brandId].name,
          value: sync_brands_info_map[brandId].id
        });
        this.syncBrands.push(sync_brands_info_map[brandId]);
      }
    },
    convertSourceProductCode() {
      api
        .post('review/move_shop_builder/convert_source_product_code')
        .then(() => (this.isConvertingProductCode = true));
    }
  }
};
</script>

<i18n locale="ko">
{
  "review_sync": "쇼핑몰간 리뷰연동",
  "source_brand": "리뷰를 가져오는 쇼핑몰",
  "review_sync_start_date": "리뷰 연동 시작일",
  "sync_brands": "리뷰를 보내주는 쇼핑몰",
  "source_product_code_conversion": "상품번호 매칭하기",
  "source_product_code_conversion_tooltip": "상품번호 변환을 먼저 진행해주세요."
}
</i18n>
