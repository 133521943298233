<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppDropdownMenuButton label="Dropdown" :menu-items="MENU_ITEMS" />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Different Color</label>
      </div>
      <AppDropdownMenuButton
        label="Dropdown"
        :menu-items="MENU_ITEMS"
        button-style="mango-outline"
      />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Menu with Sections</label>
      </div>
      <AppDropdownMenuButton
        label="Dropdown"
        :menu-items="MENU_ITEMS_WITH_SECTIONS"
        button-style="mango-outline"
      />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Right Aligned</label>
      </div>
      <div class="ui-section__item ui-section__item--right">
        <AppDropdownMenuButton label="Dropdown" :menu-items="MENU_ITEMS" />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Small</label>
      </div>
      <div class="ui-section__item">
        <AppDropdownMenuButton
          label="Dropdown"
          size="small"
          :menu-items="MENU_ITEMS"
        />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">[button-style=label]</label>
      </div>
      <div class="ui-section__item">
        <AppDropdownMenuButton
          label="Dropdown"
          button-style="label"
          :menu-items="MENU_ITEMS"
        />
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">[button-style=table-head]</label>
      </div>
      <div class="ui-section__item">
        <AppResourceTable
          :columns="[{ id: 'col1' }, { id: 'col2', label: 'col2' }]"
          :rows="[
            { col1: 'data1-1', col2: 'data1-2' },
            { col1: 'data2-1', col2: 'data2-2' }
          ]"
        >
          <template #head-cell="{ column }">
            <template v-if="column === 'col1'">
              <AppDropdownMenuButton
                :label="selectedColumn"
                button-style="table-head"
                :menu-items="
                  ['col1(A)', 'col1(B)'].map(value => ({
                    label: value,
                    value,
                    selected: value === selectedColumn,
                    clickHandler: ({ close }) => {
                      selectedColumn = value;
                      close();
                    }
                  }))
                "
              />
            </template>
          </template>
        </AppResourceTable>
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">with highlight title</label>
      </div>
      <div class="ui-section__item">
        <AppDropdownMenuButton
          label="Dropdown"
          button-style="label"
          :menu-items="[
            {
              label: '1st menu item',
              title: '[Review]'
            },
            {
              label: '2nd menu item',
              title: '[Fit]'
            },
            {
              label: '3rd menu item',
              title: '[Target]'
            },
            {
              label: '4th menu item'
            },
            {
              label: '5th menu item',
              title: '[Target]',
              disabled: true
            }
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UiDropdownMenuButton',
  data() {
    return {
      MENU_ITEMS: [
        {
          label: '1st menu item',
          tooltip: 'basic item',
          clickHandler: ({ close }) => this.clicked('1st menu item', close)
        },
        {
          label: '2nd menu item',
          selected: true,
          tooltip: 'selected item',
          clickHandler: ({ close }) => this.clicked('2nd menu item', close)
        },
        {
          label: '3rd menu item',
          disabled: true,
          tooltip: 'disabled item',
          clickHandler: ({ close }) => this.clicked('3rd menu item', close)
        },
        {
          label: '4th menu item',
          selected: true,
          disabled: true,
          tooltip: 'disabled & selected item',
          clickHandler: ({ close }) => this.clicked('4th menu item', close)
        },
        {
          label: '5th menu item',
          style: 'danger',
          tooltip: 'dangerous item',
          clickHandler: ({ close }) => this.clicked('5th menu item', close)
        }
      ],
      MENU_ITEMS_WITH_SECTIONS: [
        [
          {
            label: '1st menu item',
            clickHandler: ({ close }) => this.clicked('1st menu item', close)
          },
          {
            label: '2nd menu item',
            selected: true,
            clickHandler: ({ close }) => this.clicked('2nd menu item', close)
          }
        ],
        [
          {
            label: '3rd menu item',
            disabled: true,
            clickHandler: ({ close }) => this.clicked('3rd menu item', close)
          },
          {
            label: '4th menu item',
            selected: true,
            disabled: true,
            clickHandler: ({ close }) => this.clicked('4th menu item', close)
          },
          {
            label: '5th menu item',
            style: 'danger',
            clickHandler: ({ close }) => this.clicked('5th menu item', close)
          }
        ]
      ],
      selectedColumn: 'col1(A)'
    };
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    clicked(buttonName, close) {
      this.createToast(`'${buttonName}' clicked`);
      close();
    }
  }
};
</script>
