<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppInfoBox>InfoBox Content</AppInfoBox>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">With Snooze Button</label>
      </div>
      <AppInfoBox hide-type="snooze" info-box-id="ui">
        InfoBox Content With Snooze Button
      </AppInfoBox>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Don't Show Again</label>
      </div>
      <AppInfoBox hide-type="close" info-box-id="ui">
        InfoBox Content With Don't Show Again
      </AppInfoBox>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">localStorage</label>
      </div>
      <AppButton label="Clear localStorage" @click="clearLocalStorage" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiInfoBox',
  methods: {
    clearLocalStorage() {
      localStorage.removeItem('hidden_infos');
    }
  }
};
</script>
