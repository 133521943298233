<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic</div>
      <template v-for="(option, i) in OPTIONS">
        <div :key="i" class="ui-section__item">
          <AppChart
            :options="option"
            :tooltip="chartTooltip"
            @tooltip="onChartTooltip"
          />
        </div>
      </template>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Legend Item Tooltip</div>
      <div class="ui-section__item">
        <AppChart
          :options="legendItemTooltipOptions"
          :tooltip="chartTooltip"
          @tooltip="onChartTooltip"
        />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Mini</div>
      <AppMiniChart
        :data="DATA_MINI_CHART1"
        :type="'spline'"
        :focused-index="1"
        :value-label="'발송'"
        :value-unit="'건'"
      />
      <br /><br />
      <AppMiniChart
        :data="DATA_MINI_CHART2"
        :focused-index="3"
        :value-label="'확인'"
        :value-unit="'%'"
      />
      <br /><br />
      <AppMiniChart
        :data="DATA_MINI_CHART3"
        :type="'column'"
        :focused-index="5"
        :value-label="'이탈방지'"
        :value-unit="'%'"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Mini no value</div>
      <AppMiniChart
        :data="DATA_MINI_CHART1"
        :type="'spline'"
        :show-point-value="false"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">Mini (Linked)</div>
      <AppMiniChart
        :data="DATA_MINI_CHART1"
        :type="'spline'"
        :focused-index="focusedIndex"
        :value-label="'발송'"
        :value-unit="'건'"
        @change="updateFocusedIndex"
      />
      <AppMiniChart
        :data="DATA_MINI_CHART2"
        :focused-index="focusedIndex"
        :value-label="'확인'"
        :value-unit="'%'"
        @change="updateFocusedIndex"
      />
      <AppMiniChart
        :data="DATA_MINI_CHART3"
        :type="'column'"
        :focused-index="focusedIndex"
        :value-label="'이탈방지'"
        :value-unit="'%'"
        @change="updateFocusedIndex"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">AppChartStackedBar (4 items)</div>
      <AppChartStackedBar
        :data="[
          { label: 'Sleep', value: 0.3 },
          { label: 'Eat', value: 0.1 },
          { label: 'Work', value: 0.4 },
          { label: 'Play', value: 0.2 }
        ]"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">AppChartStackedBar (3 items)</div>
      <AppChartStackedBar
        :data="[
          { label: 'Sleep', value: 0.4 },
          { label: 'Eat', value: 0.1 },
          { label: 'Work', value: 0.5 }
        ]"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">AppChartStackedBar (zeroes)</div>
      <AppChartStackedBar
        :data="[
          { label: 'Sleep', value: 0 },
          { label: 'Eat', value: 0 },
          { label: 'Work', value: 0 }
        ]"
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">AppChartPercentageBar</div>
      <AppChartPercentageBar :numerator="80" :denominator="100" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">AppChartPercentageBar (Label slot)</div>
      <AppChartPercentageBar
        :numerator="80"
        :denominator="100"
        chart-color="red"
      >
        <template #label="{ percentage }">
          {{ `A type (${percentage}%)` }}
        </template>
      </AppChartPercentageBar>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">AppMiniPercentageBarChart</div>
      <AppMiniPercentageBarChart
        :numerator="80"
        :denominator="100"
        chart-color="mint-green"
        title="이 상품 전체 리뷰 분석"
        label="긍정"
        description="다른 사람들도 <span style='color: #48c192'>긍정</span> 표현을 했습니다."
      />
      <AppMiniPercentageBarChart
        :numerator="10"
        :denominator="100"
        chart-color="grey-60"
        title="이 상품 전체 리뷰 분석"
        label="중립"
        description="다른 사람들은 <b>긍정</b> 표현을 했습니다."
      />
      <AppMiniPercentageBarChart
        :numerator="10"
        :denominator="100"
        chart-color="red"
        title="이 상품 전체 리뷰 분석"
        label="부정"
        description="다른 사람들은 <span style='color: #48c192'>긍정</span> 표현을 했습니다."
      />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">AppChartSmallBar</div>
      <div>
        <AppChartSmallBar :numerator="80" :denominator="100" color="grey" />
      </div>
      <div class="mt4">
        <AppChartSmallBar :numerator="40" :denominator="100" color="blue" />
      </div>
      <div class="mt4">
        <AppChartSmallBar :numerator="60" :denominator="100" color="green" />
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">AppChartColumn</div>
      <AppChartColumn
        :series="CHART_SERIES"
        :tooltip="columnTooltip"
        @tooltip="onColumnTooltip"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiChart',
  data() {
    return {
      OPTIONS: [
        {
          title: {
            text: '리뷰수'
          },
          series: [
            {
              pointInterval: 1000 * 60 * 60 * 24,
              pointStart: Date.UTC(2019, 7, 25),
              data: [100, 200, 300, 1500, 200, 300],
              name: '리뷰수',
              type: 'column',
              yAxis: 1
            },
            {
              pointInterval: 1000 * 60 * 60 * 24,
              pointStart: Date.UTC(2019, 7, 25),
              data: [1, 2, 3, 10, 20, 30],
              name: '포토/동영상 리뷰수',
              type: 'column',
              yAxis: 1
            },
            {
              pointInterval: 1000 * 60 * 60 * 24,
              pointStart: Date.UTC(2019, 7, 25),
              data: [0, 1, 2, 3, 4, 5],
              name: '평균 리뷰수',
              yAxis: 0
            }
          ],
          xAxis: { type: 'datetime' },
          tooltip: {
            enabled: false
          },
          yAxis: [
            {
              title: { text: '평균 리뷰수' },
              labels: { format: '{value} 개/명' },
              opposite: true
            },
            {
              labels: { format: '{value:,.0f} 개' },
              title: { text: '리뷰수' }
            }
          ]
        }
      ],
      focusedIndex: 5,
      DATA_MINI_CHART1: [
        {
          tooltipMessage: '7주 전',
          y: 6
        },
        {
          tooltipMessage: '6주 전',
          y: 1
        },
        {
          tooltipMessage: '5주 전',
          y: 5
        },
        {
          tooltipMessage: '4주 전',
          y: 3
        },
        {
          tooltipMessage: '3주 전',
          y: 2
        },
        {
          tooltipMessage: '2주 전',
          y: 4
        },
        {
          tooltipMessage: '지난주',
          y: 1
        },
        {
          tooltipMessage: '이번주',
          tooltipInfo: '효과 수집 중',
          y: 2
        }
      ],
      DATA_MINI_CHART2: [
        {
          tooltipMessage: '7주 전',
          y: 60
        },
        {
          tooltipMessage: '6주 전',
          y: 10
        },
        {
          tooltipMessage: '5주 전',
          y: 50
        },
        {
          tooltipMessage: '4주 전',
          y: 30
        },
        {
          tooltipMessage: '3주 전',
          y: 20
        },
        {
          tooltipMessage: '2주 전',
          y: 40
        },
        {
          tooltipMessage: '지난주',
          y: 10
        },
        {
          tooltipMessage: '이번주',
          tooltipInfo: '효과 수집 중',
          y: 20
        }
      ],
      DATA_MINI_CHART3: [
        {
          tooltipMessage: '7주 전',
          y: 600
        },
        {
          tooltipMessage: '6주 전',
          y: 100
        },
        {
          tooltipMessage: '5주 전',
          y: 500
        },
        {
          tooltipMessage: '4주 전',
          y: 300
        },
        {
          tooltipMessage: '3주 전',
          y: 200
        },
        {
          tooltipMessage: '2주 전',
          y: 400
        },
        {
          tooltipMessage: '지난주',
          y: 100
        },
        {
          tooltipMessage: '이번주',
          tooltipInfo: '효과 수집 중',
          y: 200
        }
      ],
      CHART_SERIES: [
        {
          name: 'Revenue',
          data: [
            ['Jan', 10],
            ['Feb', 30],
            ['Mar', 20],
            ['Apr', 25],
            ['May', 40]
          ]
        }
      ],
      chartTooltip: null,
      columnTooltip: null
    };
  },
  computed: {
    legendItemTooltipOptions() {
      const option = this.OPTIONS[0];
      return {
        ...option,
        series: option.series.map((s, i) => ({
          ...s,
          legendTooltip: `tooltip-${i}`
        }))
      };
    }
  },
  methods: {
    updateFocusedIndex(index) {
      this.focusedIndex = index;
    },
    onChartTooltip({ x, index, series }) {
      this.chartTooltip = {
        title: this.formatDate(new Date(x)),
        items: series.chart.series.map(({ data, name }, i) => ({
          symbol: i === 0 ? 'circle' : i === 1 ? 'square' : null,
          name,
          value: data[index].y
        }))
      };
    },
    onColumnTooltip({ name, y }) {
      this.columnTooltip = {
        title: name,
        items: [{ symbol: 'square', name, value: y }]
      };
    }
  }
};
</script>
