<template>
  <div>
    <AppAjaxContent :is-loading="isLoading">
      <AppSearchbar>
        <template #right>
          <li>
            <AppSelect
              v-model="resourceParams.popup_status"
              :options="popupStatusOptions"
              :placeholder="$t('searchbar.dispatch_result_placeholder')"
              @change="searchResource"
            />
          </li>
        </template>
      </AppSearchbar>
      <AppResourceTable
        :rows="cohortItems.items"
        :resources="cohortItems"
        :columns="columns"
        @paginate="paginate"
      >
        <template #cell="{ row, column }">
          <template v-if="column === 'user'">
            <div v-if="row.name">{{ row.name }}</div>
            <div v-if="row.username">{{ row.username }}</div>
            <div v-if="row.phone">{{ row.phone }}</div>
          </template>
          <template v-else-if="column === 'popup_status'">
            <AppBadge
              :label="TargetPopupStatus.t(row.popup_status)"
              :badge-style="popupStatusBadgeStyle(row.popup_status)"
            />
          </template>
        </template>
      </AppResourceTable>
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import { mapGetters } from 'vuex';
import { nullResources } from '@/lib/resources';
import TargetDispatchMethod from '@/enums/TargetDispatchMethod';
import TargetPopupStatus from '@/enums/TargetPopupStatus';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'TargetReportsDispatchResultDialogTablePopup',
  mixins: [ResourceViewNoRoute],
  props: { cohort: { type: Object, required: true } },
  data: () => ({ isLoading: true, cohortItems: nullResources }),
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    TargetDispatchMethod: () => TargetDispatchMethod,
    TargetPopupStatus: () => TargetPopupStatus,
    columns() {
      return [
        { id: 'id' },
        { id: 'checked_at', type: 'datetime' },
        { id: 'user' },
        { id: 'popup_status' }
      ].map(column => ({
        ...column,
        label: this.$t(`table.${column.id}`)
      }));
    },
    popupStatusOptions() {
      return TargetPopupStatus.options(
        TargetPopupStatus.values.filter(v => v !== TargetPopupStatus.SAMPLE)
      );
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/target/cohorts/${this.cohort.id}/items/popup`, { params })
        .then(({ data }) => (this.cohortItems = data.cohort_items))
        .finally(() => (this.isLoading = false));
    },
    popupStatusBadgeStyle(status) {
      switch (status) {
        case TargetPopupStatus.CHECKED:
          return 'blue';
        case TargetPopupStatus.EXPIRED:
          return 'grey';
        case TargetPopupStatus.PAID:
          return 'mint-green';
        default:
          return 'default';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .AppResourceTableCell {
  flex: 1 0 110px;
}
</style>

<i18n locale="ko">
{
  "searchbar": {
    "dispatch_result_placeholder": "전체 발송 결과"
  },
  "table": {
    "id": "ID",
    "checked_at": "노출일",
    "user": "고객 정보",
    "popup_status": "발송 결과"
  }
}
</i18n>
