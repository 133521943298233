<template>
  <AppAdvancedSearch
    :resource-params="resourceParams"
    :visible="visible"
    :sections="advancedSearchSections"
    v-on="$listeners"
  >
    <template #group="{ id }">
      <div v-if="id === 'sub_brand_ids'" class="AppForm__group-field">
        <AppSelectSearchable
          :title="$t('sub_brand_ids.select')"
          :placeholder="$t('sub_brand_ids.placeholder')"
          :items="subBrands"
          :columns="[{ id: 'name', label: $t('sub_brand_ids.name') }]"
          :search-types="[{ value: 'name', label: $t('sub_brand_ids.name') }]"
          multiple
          @search="searchSubBrands"
          @change="
            $emit('change', {
              ...resourceParams,
              sub_brand_ids: $event.join(',')
            })
          "
        />
      </div>
    </template>
  </AppAdvancedSearch>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import api from '@/lib/api';
import ReviewSentimentType from '@/enums/ReviewSentimentType';

export default {
  name: 'ReviewProductsAdvancedSearch',
  model: {
    prop: 'resourceParams',
    event: 'change'
  },
  props: {
    resourceParams: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('session', [
      'brandSettings',
      'reviewSettings',
      'reviewTagTypes'
    ]),
    ...mapState('review', ['subBrands']),
    ...mapGetters('session', ['isUsingNlp']),
    advancedSearchSections() {
      const cremaAIBadge = {
        label: this.$t('app.crema_ai'),
        badgeStyle: 'red',
        badgeSize: 'small'
      };

      return [
        {
          id: 'filter',
          groups: [
            {
              id: 'product_type',
              label: this.$t('product_type'),
              type: 'hash_select_button',
              selectButtons: [
                { id: 'review_pinned', values: [1, 0] },
                { id: 'combo_product', values: [1] },
                { id: 'manager_reviewed', values: [1] }
              ].map(({ id, values }) => ({
                id,
                options: values.map(value => ({
                  label: this.$t(`${id}.${value}`),
                  value
                }))
              }))
            },
            this.reviewSettings.use_nlp_sentiment
              ? {
                  id: 'review_message_sentiment',
                  label: this.$t('review_message_sentiment.title'),
                  placeholder: this.$t('review_message_sentiment.placeholder'),
                  titleBadge: cremaAIBadge,
                  type: 'multiple_select',
                  options: ReviewSentimentType.options([
                    ReviewSentimentType.POSITIVE,
                    ReviewSentimentType.NEUTRAL,
                    ReviewSentimentType.NEGATIVE
                  ]),
                  packingMethod: 'string'
                }
              : null,
            ...(this.isUsingNlp
              ? [
                  {
                    id: 'positive_review_keywords',
                    label: this.$t('positive_review_keywords.title'),
                    placeholder: this.$t(
                      'positive_review_keywords.placeholder'
                    ),
                    titleBadge: cremaAIBadge,
                    type: 'multiple_select',
                    options: this.reviewKeywordTypeOptions,
                    packingMethod: 'string'
                  },
                  {
                    id: 'negative_review_keywords',
                    label: this.$t('negative_review_keywords.title'),
                    placeholder: this.$t(
                      'negative_review_keywords.placeholder'
                    ),
                    titleBadge: cremaAIBadge,
                    type: 'multiple_select',
                    options: this.reviewKeywordTypeOptions,
                    packingMethod: 'string'
                  }
                ]
              : []),
            this.brandSettings.show_sub_brand
              ? {
                  id: 'sub_brand_ids',
                  label: this.$t('sub_brand_ids.title')
                }
              : null,
            {
              id: 'sort',
              label: this.$t('sort'),
              type: 'select',
              options: [
                'created_at_desc',
                'code_asc',
                'reviews_count_desc',
                'reviews_count_asc',
                'photo_reviews_count_desc',
                'photo_reviews_count_asc',
                'review_rate_desc',
                'score_desc',
                'score_asc'
              ].map(value => ({
                label: this.$t(`product_sort.${value}`),
                value
              }))
            },
            {
              id: 'misc',
              label: this.$t('misc.label'),
              type: 'hash_select_button',
              selectButtons: [
                {
                  id: 'misc',
                  options: [
                    {
                      label: this.$t('misc.not_deleted'),
                      value: 'not_deleted'
                    }
                  ]
                }
              ]
            }
          ].filter(g => g)
        }
      ];
    },
    reviewKeywordTypeOptions() {
      return _.map(this.reviewTagTypes, type => ({
        label: type.format_name,
        value: parseInt(type.id)
      }));
    }
  },
  methods: {
    searchSubBrands({ params, onSuccess, onFailure }) {
      api
        .get('/sub_brands', { params })
        .then(({ data }) => onSuccess(data.sub_brands))
        .catch(onFailure);
    }
  }
};
</script>

<i18n locale="ko">
{
  "product_type": "상태별 검색",
  "review_pinned": {
    "1": "추천 리뷰 있음",
    "0": "추천 리뷰 없음"
  },
  "combo_product": {
    "1": "세트상품"
  },
  "manager_reviewed": {
    "1": "스탭리뷰"
  },
  "sub_brand_ids": {
    "title": "서브 브랜드",
    "placeholder": "서브 브랜드를 선택해주세요.",
    "select": "서브 브랜드 선택",
    "name": "서브 브랜드명"
  },
  "sort": "정렬 방식",
  "product_sort": {
    "created_at_desc": "최신순",
    "code_asc": "상품번호 순",
    "reviews_count_desc": "리뷰 많은 순",
    "reviews_count_asc": "리뷰 적은 순",
    "photo_reviews_count_desc": "포토/동영상 리뷰 많은 순",
    "photo_reviews_count_asc": "포토/동영상 리뷰 적은 순",
    "review_rate_desc": "리뷰 작성률 높은 순",
    "score_desc": "상품 평점 높은 순",
    "score_asc": "상품 평점 낮은 순"
  },
  "misc": {
    "label": "상품 상태",
    "not_deleted": "삭제되지 않음"
  },
  "review_message_sentiment": {
    "title": "리뷰 본문 긍정/부정 분석",
    "placeholder": "긍정/중립/부정 분석 결과를 선택해주세요."
  },
  "positive_review_keywords": {
    "title": "긍정 우세 키워드",
    "placeholder": "상품 긍정 우세 키워드를 선택해주세요."
  },
  "negative_review_keywords": {
    "title": "부정 우세 키워드",
    "placeholder": "상품 부정 우세 키워드를 선택해주세요."
  }
}
</i18n>
<i18n locale="en">
{
  "product_type": "Search type",
  "review_pinned": {
    "1": "With featured reviews",
    "0": "Without featured reviews"
  },
  "combo_product": {
    "1": "Group product"
  },
  "manager_reviewed": {
    "1": "Staff review"
  },
  "sub_brand_ids": {
    "title": "Sub brand id",
    "placeholder": "Select sub brand.",
    "select": "Select sub brand",
    "name": "Sub brand name"
  },
  "sort": "Sort",
  "product_sort": {
    "created_at_desc": "Created at desc",
    "code_asc": "Product code asc",
    "reviews_count_desc": "Reviews count desc",
    "reviews_count_asc" : "Reviews count asc",
    "photo_reviews_count_desc": "Image/Video reviews count desc",
    "photo_reviews_count_asc": "Image/Video reviews count asc",
    "review_rate_desc": "Review writing rate desc",
    "score_desc": "Product score desc",
    "score_asc": "Product score asc"
  },
  "misc": {
    "label": "Product status",
    "not_deleted": "Not deleted"
  },
  "review_message_sentiment": {
    "title": "Review positive/negative analysis",
    "placeholder": "Please select a positive/neutral/negative analysis result."
  },
  "positive_review_keywords": {
    "title": "Positive dominant keywords",
    "placeholder": "Please select a product positive advantage keyword."
  },
  "negative_review_keywords": {
    "title": "Negative dominant keyword",
    "placeholder": "Please select a product negative advantage keyword."
  }
}
</i18n>
