<template>
  <div>
    <AppToastTransition :gap="16" :items="jobs">
      <template #item="{ item }">
        <AppAsyncJob class="TheAsyncJobs__item" :job="item" />
      </template>
    </AppToastTransition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UnloadConfirmable from '@/mixins/UnloadConfirmable';

export default {
  name: 'TheAsyncJobs',
  mixins: [UnloadConfirmable],
  computed: {
    ...mapState('asyncJob', ['jobs']),
    hasIncompleteJobs() {
      return this.jobs.some(j => j.status !== 'complete');
    }
  },
  watch: {
    hasIncompleteJobs(val) {
      if (val) this.enableUnloadConfirmation();
      else this.disableUnloadConfirmation();
    }
  }
};
</script>

<style lang="scss" scoped>
.TheAsyncJobs__item {
  pointer-events: all;
}
</style>
