<template>
  <div
    :class="[
      'TheSearchDialogHead',
      { 'TheSearchDialogHead--no-border': !showFoot }
    ]"
  >
    <AppSpinner v-if="isLoading" class="TheSearchDialogHead__loading-icon" />
    <AppSvgIcon
      v-else
      name="icon-search-big"
      class="TheSearchDialogHead__search-icon"
    />
    <div class="TheSearchDialogHead__query-wrapper">
      <input
        v-focus
        :value="query"
        :placeholder="$t('search_placeholder')"
        class="TheSearchDialogHead__query"
        type="text"
        autofocus
        @blur="refocus"
        @input="$emit('change-query', $event.target.value)"
        @keydown.up.prevent="$emit('click-up')"
        @keydown.down.prevent="$emit('click-down')"
        @keydown.enter.prevent="$emit('click-enter')"
      />
    </div>
    <AppModalHeadCloseButton @close="$emit('close')" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AppModalHeadCloseButton from '@/components/AppModal/AppModalHeadCloseButton';

export default {
  name: 'TheSearchDialogHead',
  components: { AppModalHeadCloseButton },
  props: {
    showFoot: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('search', ['query']),
    ...mapGetters('request', ['isRequestPending']),
    ...mapGetters('dialog', ['isDialogVisible']),
    isLoading() {
      return this.query && this.isRequestPending('FETCH_SEARCH');
    }
  },
  methods: {
    refocus(event) {
      if (this.isDialogVisible('TheSearchDialog'))
        setTimeout(() => event.target.focus());
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.TheSearchDialogHead {
  border-bottom: 1px solid $color-layout-section;
  height: 56px;
  position: relative;
  padding: 16px 0;
}

.TheSearchDialogHead--no-border {
  border-bottom: none;
}

.TheSearchDialogHead__loading-icon {
  position: absolute;
  top: 18px;
  left: 22px;
  font-size: 18px;
  color: $color-grey-60;
}

.TheSearchDialogHead__search-icon {
  position: absolute;
  top: 18px;
  left: 24px;
}

.TheSearchDialogHead__query-wrapper {
  padding-left: 60px;
  padding-right: 50px;
}

.TheSearchDialogHead__query {
  font-size: 16px;
  line-height: 24px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  color: $color-content-text-dark;

  &::placeholder {
    color: $color-grey-40;
  }

  &:focus {
    outline: none;
  }
}

.AppModalHeadCloseButton {
  position: absolute;
  top: 8px;
  right: 10px;
}
</style>

<i18n locale="ko">
{
  "search_placeholder": "검색어를 입력해주세요."
}
</i18n>
<i18n locale="en">
{
  "search_placeholder": "Please enter a search term."
}
</i18n>
