<template>
  <AppModal skip-backdrop min-width="440px" :width="currentWidth">
    <div slot="head" class="AppMessageDialog__head">
      <div class="AppMessageDialog__title">{{ title }}</div>
    </div>
    <div slot="body" class="AppMessageDialog__body">
      <AppMarkdown
        class="AppMessageDialog__text-viewer"
        :text="markdownText"
        :markdown-option="markdownOption"
      />
    </div>
    <div slot="foot" class="AppMessageDialog__foot">
      <template v-if="type === 'alert'">
        <AppButton
          :button-style="closeButtonStyle || 'blue'"
          :label="closeButtonLabel || $t('app.confirmed')"
          @click="close"
        />
      </template>
      <template v-else-if="type === 'delete_confirm'">
        <AppButton
          :button-style="closeButtonStyle || 'red'"
          :label="closeButtonLabel || $t('app.delete')"
          @click="close"
        />
        <AppButton
          :button-style="cancelButtonStyle"
          :label="cancelButtonLabel || $t('app.cancel')"
          @click="cancel"
        />
      </template>
      <template v-else-if="type === 'check_confirm'">
        <div>
          <AppCheckbox v-model="enableCloseButton" :label="checkboxLabel" />
        </div>
        <div class="AppMessageDialog__check-confirm-buttons">
          <AppButton
            :button-style="closeButtonStyle || 'blue'"
            :label="closeButtonLabel || $t('app.confirmed')"
            :disabled="!enableCloseButton"
            @click="close"
          />
          <AppButton
            :button-style="cancelButtonStyle"
            :label="cancelButtonLabel || $t('app.cancel')"
            @click="cancel"
          />
        </div>
      </template>
      <template v-else>
        <AppButton
          :button-style="closeButtonStyle || 'blue'"
          :label="closeButtonLabel || $t('app.confirmed')"
          @click="close"
        />
        <AppButton
          :button-style="cancelButtonStyle"
          :label="cancelButtonLabel || $t('app.cancel')"
          @click="cancel"
        />
      </template>
      <div v-if="snoozeId" class="AppMessageDialog__dont-show-this-again">
        <AppCheckbox
          :label="$t('dont_show_this_again')"
          @change="dontShowThisAgain = $event"
        />
      </div>
    </div>
  </AppModal>
</template>

<script>
import DialogView from '@/mixins/DialogView';
import ModalSizeHelper from '@/mixins/ModalSizeHelper';

export default {
  name: 'AppMessageDialog',
  mixins: [DialogView, ModalSizeHelper],
  props: {
    type: { type: String, required: true },
    title: { type: String, required: true },
    markdownText: { type: String, required: true },
    markdownOption: { type: Object, default: null },
    snoozeId: { type: String, default: '' },
    checkboxLabel: { type: String, default: '' },
    closeButtonLabel: { type: String, default: '' },
    closeButtonStyle: { type: String, default: '' },
    cancelButtonLabel: { type: String, default: '' },
    cancelButtonStyle: { type: String, default: 'default' }
  },
  data() {
    return {
      dontShowThisAgain: false,
      enableCloseButton: false
    };
  },
  methods: {
    close() {
      this.setDontShowThisAgain();
      this.closeDialog();
    },
    setDontShowThisAgain() {
      if (!this.dontShowThisAgain) return;

      var hiddenDialogs =
        JSON.parse(localStorage.getItem('hidden_dialogs')) || [];
      hiddenDialogs.push(this.snoozeId);
      localStorage.setItem('hidden_dialogs', JSON.stringify(hiddenDialogs));
    },
    cancel() {
      this.setDontShowThisAgain();
      this.emit('cancel');
      this.$store.dispatch('dialog/closeDialog', this.$options.name);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppMessageDialog__head {
  padding: 18px 10px 21px 23px;
  min-width: 0;
}

.AppMessageDialog__title {
  @include text-sub-title;
}

.AppMessageDialog__body {
  padding: 0 23px 4px 23px;
}

.AppMessageDialog__text-viewer {
  color: $color-content-text;
}

.AppMessageDialog__foot {
  padding: 16px 23px 15px 23px;
}

.AppMessageDialog__dont-show-this-again {
  float: right;
  padding: 6px;
  margin-left: 10px;
}

.AppMessageDialog__check-confirm-buttons {
  margin-top: 24px;
}
</style>

<i18n locale="ko">
{
  "dont_show_this_again": "이 메시지를 다시 표시하지 않습니다."
}
</i18n>
