<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div class="AppMarked" v-html="markedText" />
</template>

<script>
import marked from 'marked';

export default {
  name: 'AppMarked',
  props: {
    text: { type: String, required: true },
    markdownOption: { type: Object, default: null }
  },
  computed: {
    markedText() {
      return marked.parse(this.text, {
        ...this.markdownOption,
        renderer: this.renderer
      });
    }
  },
  created() {
    this.renderer = new marked.Renderer();
    const orgLink = this.renderer.link;
    this.renderer.link = (href, title, text) =>
      orgLink
        .call(this.renderer, href, title, text)
        .replace('<a ', '<a target="_blank"');
  }
};
</script>
