<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm v-bind="settingsFormProps" v-on="formEvents">
      <template #group="{ id }">
        <template v-if="id === 'show_write_popup'">
          <div class="AppForm__group-field">
            <div class="AppForm__sub-group">
              <AppButton
                :label="$t('sub_groups.show_write_popup')"
                :disabled="!formObject['show_write_popup']"
                @click="openWritePopupSettings"
              />
            </div>
          </div>
        </template>
        <template v-else-if="id === 'popup_period'">
          <i18n path="sub_groups.popup_period">
            <AppNumberInput
              v-model="formObject.pending_starts_after_days"
              name="popup_settings[pending_starts_after_days]"
              :disabled="!formObject['show_write_popup']"
              :digits="3"
              @blur="validateField('pending_starts_after_days')"
              @change="validateField('pending_starts_after_days')"
            />
            <AppNumberInput
              v-model="formObject.pending_expires_after_days"
              name="popup_settings[pending_expires_after_days]"
              :disabled="!formObject['show_write_popup']"
              :digits="3"
              @blur="validateField('pending_expires_after_days')"
              @change="validateField('pending_expires_after_days')"
            />
          </i18n>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import NineWayDirection from '@/enums/NineWayDirection';
import SettingsView from '@/mixins/SettingsView';
import { mapActions, mapGetters, mapState } from 'vuex';
import FormView from '@/mixins/FormView';
import api from '@/lib/api';
import _ from 'lodash';

export default {
  name: 'ReviewSettingsPopup',
  mixins: [FormView, SettingsView],
  data() {
    return {
      isLoading: true,
      SETTINGS_ID: 'review_settings_popup'
    };
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapState('session', ['currentBrand']),
    settingsFormProps() {
      return {
        ...this.formProps,
        id: this.SETTINGS_ID,
        submitButton: true,
        objectId: 'popup_settings',
        formStyle: 'hor'
      };
    },
    groupOptions() {
      return {
        advanced_tip_box: {
          type: 'tip_box',
          label: '',
          value: this.$t('review_settings_advanced_tip_box.text')
        },
        show_write_popup: {
          type: 'checkbox'
        },
        max_popup_count_per_day: {
          type: 'number',
          digits: 2,
          i18n: 'review_settings_popup.max_popup_count_per_day'
        },
        popup_excluding_category_ids: {
          type: 'select_product_category',
          multiple: true
        },
        popup_blacklist_usernames: {
          type: 'tags',
          placeholder: this.$t(
            'sub_groups.popup_blacklist_usernames.placeholder'
          ),
          hint: this.$t('sub_groups.popup_blacklist_usernames.hint')
        },
        popup_direction: {
          type: 'select_radio',
          options: NineWayDirection.options(),
          cols: 3
        },
        popup_offset_x: {
          type: 'number',
          digits: 3,
          default: 0,
          allowNegative: true
        },
        popup_offset_y: {
          type: 'number',
          digits: 3,
          default: 0,
          allowNegative: true
        }
      };
    }
  },
  mounted() {
    api
      .get('/review/popup_settings')
      .then(({ data }) => {
        this.orgFormObject = _.cloneDeep(data.popup_settings);
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapActions('session', ['updateReviewSettings']),
    ...mapActions('dialog', ['openDialog']),
    openWritePopupSettings() {
      this.openDialog(['WritePopupSettingsDialog']);
    },
    submit(formData) {
      this.isLoading = true;
      api
        .patch('/review/popup_settings', formData)
        .then(() => {
          this.orgFormObject = _.cloneDeep(this.formObject);
          this.updateReviewSettings(this.orgFormObject);
          this.createToast(this.$t('app.saved'));
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<i18n locale="ko">
{
  "sub_groups": {
    "show_write_popup": "작성 팝업 문구 설정",
    "popup_period": "배송 완료일을 기준으로 {0} 일 후부터, {1} 일 후까지 노출",
    "popup_blacklist_usernames": {
      "hint": "팝업을 제한한 ID에게는 리뷰 문자도 발송되지 않습니다.",
      "placeholder": "ID를 선택해주세요."
    }
  }
}
</i18n>
