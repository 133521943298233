import _ from 'lodash';

export default (str, interpolation) => {
  if (!str || _.isEmpty(interpolation)) return str;

  return _.reduce(
    interpolation,
    (a, v, k) => a.replace(new RegExp(`%{${k}}`, 'g'), v),
    str
  );
};
