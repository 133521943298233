import { constructEnum } from '@/lib/enum';

export default constructEnum('PackageType', {
  REVIEW: 100,
  REVIEW_SMS: 110,
  SMARTSTORE_STARTER: 120,
  SMARTSTORE_DAILY: 122,
  CREDIT: 200,
  TARGET: 210,
  FIT: 300,
  SPECIAL: 10_000,
  CAFE24_FEE: 11_000
});
