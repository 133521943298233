<template>
  <AppDataSection :title="$t('title')" gap="wide">
    <AppAjaxContent :is-loading="isLoading" height="218px">
      <AppDataSectionItem
        v-for="(item, i) in items"
        :key="i"
        :title="item.title"
      >
        <AppNumber
          class="AppDataSectionItem__content"
          v-bind="_.omit(item, 'title')"
        />
      </AppDataSectionItem>
    </AppAjaxContent>
  </AppDataSection>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import api from '@/lib/api';
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';

export default {
  name: 'TargetCampaignStatsSectionSummary',
  mixins: [ResourceViewNoRoute],
  props: {
    campaign: { type: Object, required: true },
    dateRange: { type: Object, required: true },
    samplingMethod: { type: Number, required: true }
  },
  data() {
    return { isLoading: true, summary: {} };
  },
  computed: {
    ...mapGetters('targetCampaigns', ['campaignText']),
    _: () => _,
    defaultResourceParams: () => ({}),
    TargetCampaignType: () => TargetCampaignType,
    items() {
      const campaign_media_type = this.campaign.campaign_media_type;
      return [
        ...(campaign_media_type === TargetCampaignMediaType.DIRECT_MESSAGE
          ? [
              { title: this.$t('sent_count'), value: this.summary.sent_count },
              {
                title: this.$t('check_rate'),
                value: this.summary.check_rate * 100,
                precision: 2,
                unit: 'percentage'
              }
            ]
          : campaign_media_type === TargetCampaignMediaType.WIDGET
          ? [
              {
                title: this.$t('reach_rate'),
                value: this.summary.reach_rate * 100,
                precision: 2,
                unit: 'percentage'
              },
              {
                title: this.$t('click_per_check'),
                value: this.summary.click_per_check * 100,
                precision: 2,
                unit: 'percentage'
              }
            ]
          : [
              {
                title: this.$t('unique_check_count'),
                value: this.summary.unique_check_count
              },
              {
                title: this.$t('click_per_check'),
                value: this.summary.click_per_check * 100,
                precision: 2,
                unit: 'percentage'
              }
            ]),
        {
          title: this.campaignText(this.campaign, 'kpi.name_long'),
          value:
            ([
              TargetSamplingMethod.POPUP_CLICKED,
              TargetSamplingMethod.WIDGET_CLICKED
            ].includes(this.samplingMethod)
              ? this.summary.clicked_kpi
              : this.summary.kpi) * 100,
          precision: 2,
          unit: 'percentage'
        }
      ];
    }
  },
  watch: {
    dateRange() {
      this.resourceParams = { ...this.resourceParams, ...this.dateRange };
      this.searchResource();
    }
  },
  created() {
    this.setOrgResourceParams(this.dateRange);
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/target/campaigns/${this.campaign.id}/reports_summary`, {
          params
        })
        .then(({ data }) => {
          this.reportsRange = { ...this.resourceParams };
          this.summary = data;
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "주요 지표",
  "sent_count": "발송수",
  "check_rate": "확인율",
  "unique_check_count": "노출수",
  "click_per_check": "클릭률",
  "reach_rate": "도달률"
}
</i18n>
