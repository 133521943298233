<template>
  <ReviewSettingsWidgetsNormal
    :widget-type="WidgetType.PRODUCT_REVIEWS"
    :widget-summary="widgetSummary"
  />
</template>

<script>
import ReviewSettingsWidgetsNormal from './ReviewSettingsWidgetsNormal';
import WidgetType from '@/enums/WidgetType';

export default {
  name: 'ReviewSettingsWidgetsProductReviews',
  components: { ReviewSettingsWidgetsNormal },
  computed: {
    WidgetType() {
      return WidgetType;
    }
  },
  methods: {
    widgetSummary(widget) {
      if (widget.tag_name)
        return this.$t(
          `summary.${widget.filter_photo_only ? 'tag_photo' : 'tag'}`,
          {
            tag: widget.tag_name,
            per: widget.posts_per_page
          }
        );
      else
        return this.$t(
          `summary.${widget.filter_photo_only ? 'default_photo' : 'default'}`,
          { per: widget.posts_per_page }
        );
    }
  }
};
</script>

<i18n locale="ko">
{
  "summary": {
    "default": "상품의 리뷰를 {per}개씩 보여줍니다.",
    "default_photo": "상품의 포토 리뷰를 {per}개씩 보여줍니다.",
    "tag": "'{tag}' 태그가 설정된 리뷰를 {per}개씩 보여줍니다.",
    "tag_photo": "'{tag}' 태그가 설정된 포토 리뷰를 {per}개씩 보여줍니다."
  }
}
</i18n>
