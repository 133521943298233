<template>
  <div class="AppSummaryBox">
    <div class="AppSummaryBox__title"><slot name="title" /></div>
    <div class="AppSummaryBox__content"><slot /></div>
    <div v-if="$slots.buttons" class="AppSummaryBox__buttons">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
export default { name: 'AppSummaryBox' };
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_links.scss';

.AppSummaryBox {
  line-height: 20px;
  padding: 24px 32px;
  border-radius: 4px;
  border: 1px solid $color-grey-25;
  text-align: left;
  font-size: 13px;
  color: $color-content-text;
  background-color: $color-grey-05;

  & + & {
    margin-top: 10px;
  }
}

.AppSummaryBox__title {
  @include text-modal-title;
  color: $color-grey-80;
  margin-bottom: 4px;
  white-space: pre-line;
}

.AppSummaryBox__content {
  @include text-content;
  color: $color-grey-80;

  ::v-deep a {
    @include link-blue;
  }
}

.AppSummaryBox__buttons {
  margin-top: 8px;
}
</style>
