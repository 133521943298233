<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar @submit="searchResource">
      <template #default>
        <li>
          <AppButton
            v-if="isSuperAdminAccessible"
            class="super-admin__item"
            :label="$t('add_product_category_product_type')"
            button-style="red"
            @click="addProductCategoryProductType"
          />
        </li>
      </template>
      <template #right>
        <li>
          <AppSearchWithType
            v-model="searchWithType"
            :search-types="SEARCH_TYPES"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppInfoBox>
      {{ $t('hint') }}
    </AppInfoBox>
    <AppResourceTable
      table-id="brand-category-product-types-table"
      :resources="resources"
      :columns="columns"
      :rows="rows"
      :no-data="$t('no_data')"
      @paginate="paginate"
    >
      <template #cell="{ row, column, value, rowIndex }">
        <template v-if="column === 'product_category_name'">
          {{
            categoryNamesMap[row.product_category_id] ||
              $t('unselected_categories')
          }}
        </template>
        <template v-else-if="column === 'brand_evaluation_type_names'">
          <AppBadge
            v-for="(type, i) in row.brand_evaluation_types"
            :key="i"
            badge-style="grey-outline"
            :label="type.name"
          />
        </template>
        <template v-else-if="column === 'brand_user_property_type_names'">
          <AppBadge
            v-for="(type, i) in row.brand_user_property_types"
            :key="i"
            :badge-style="type.default ? 'mango-outline' : 'grey-outline'"
            :label="type.name"
            :tooltip="{
              message: brandUserPropertyTypeTooltips[rowIndex][i],
              textAlign: 'left',
              maxWidth: '200px'
            }"
          />
        </template>
        <template v-else-if="column === 'actions'">
          <AppButton
            :label="$t('actions.edit')"
            @click="editProductCategoryProductType(row.id)"
          />
          <AppButton
            v-if="!isUnselectedCategories(rowIndex)"
            button-style="red-outline"
            :label="$t('actions.delete')"
            @click="deleteProductCategoryProductType(row.id)"
          />
        </template>
        <template v-else>
          {{ value }}
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import ResourceView from '@/mixins/ResourceView';
import { nullResources } from '@/lib/resources';
import ReviewOptionFieldType from '@/enums/ReviewOptionFieldType';

export default {
  name: 'ReviewSettingsReviewOptionCategoryInclusion',
  mixins: [ResourceView],
  data() {
    return {
      SEARCH_TYPES: [
        'product_category_name',
        'product_type_name',
        'brand_user_property_type_name',
        'brand_evaluation_type_name'
      ].map(value => ({
        value,
        label: this.$t(`search_type.${value}`)
      })),
      isLoading: true,
      resources: nullResources
    };
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoriesWithTreeName']),
    ...mapGetters(['isSuperAdminAccessible']),
    columns() {
      return [
        ...[
          'product_category_name',
          'product_type_name',
          'brand_user_property_type_names',
          'brand_evaluation_type_names'
        ].map(c => ({ id: c, label: this.$t(`table_header.${c}`) })),
        this.isSuperAdminAccessible
          ? {
              id: 'actions',
              label: this.$t('table_header.actions'),
              superAdmin: true
            }
          : null
      ].filter(c => c);
    },
    defaultResourceParams: () => ({
      per: '20',
      page: '1',
      search_type: 'product_category_name'
    }),
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.resourceParams = { ...this.resourceParams, ...newValue };
      }
    },
    rows() {
      return this.resources.items;
    },
    brandUserPropertyTypeTooltips() {
      return this.rows.map(row =>
        row.brand_user_property_types.map(t =>
          this.brandUserPropertyTypeTooltipMessage(t)
        )
      );
    },
    categoryNamesMap() {
      const categoryNamesMap = {};
      this.productCategoriesWithTreeName.forEach(
        ({ id, treeName }) => (categoryNamesMap[id] = treeName)
      );
      return categoryNamesMap;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    isUnselectedCategories(rowIndex) {
      return !this.rows[rowIndex].product_category_id;
    },
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/product_category_product_types', { params })
        .then(
          ({ data }) => (this.resources = data.product_category_product_types)
        )
        .finally(() => (this.isLoading = false));
    },
    brandUserPropertyTypeTooltipMessage(type) {
      const texts = [
        `${this.$t('tooltip.id')}: ${type.id}`,
        `${this.$t('tooltip.field_type')}: ${ReviewOptionFieldType.t(
          type.field_type
        )}`,
        type.search_options
          ? `${this.$t('tooltip.search_options')}: ${type.search_options}`
          : null,
        ...['storable', 'searchable', 'displayable', 'required'].map(
          o => `${this.$t(`tooltip.${o}`)}: ${type[o] ? 'O' : 'X'}`
        )
      ].filter(t => t);

      return texts.map(text => `-${text}`).join('\n');
    },
    addProductCategoryProductType() {
      this.openDialog(
        'ReviewSettingsProductCategoryProductTypeDialog'
      ).then(eventBus => eventBus.$on('save', this.refreshResource));
    },
    editProductCategoryProductType(id) {
      this.openDialog([
        'ReviewSettingsProductCategoryProductTypeDialog',
        { id }
      ]).then(eventBus => eventBus.$on('save', this.refreshResource));
    },
    deleteProductCategoryProductType(id) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      api
        .delete(`/product_category_product_types/${id}`, {
          successMessage: this.$t('app.deleted')
        })
        .then(this.refreshResource);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .brand-category-product-types-table__product-category-name {
    min-width: 205px;
  }

  .brand-category-product-types-table__product-type-name {
    min-width: 139px;
  }

  .brand-category-product-types-table__brand-evaluation-type-names {
    min-width: 316px;
  }

  .brand-category-product-types-table__brand-user-property-type-names {
    min-width: 250px;
  }

  .brand-category-product-types-table__actions {
    min-width: 134px;
  }
}
</style>

<i18n locale="ko">
{
  "search_type": {
    "product_category_name": "카테고리",
    "product_type_name": "제품 타입",
    "brand_user_property_type_name": "고객 속성",
    "brand_evaluation_type_name": "평가 항목"
  },
  "table_header": {
    "product_category_name": "카테고리",
    "product_type_name": "제품 타입",
    "brand_user_property_type_names": "고객정보 및 표시 정보명",
    "brand_evaluation_type_names": "평가정보",
    "actions": "기능"
  },
  "actions": {
    "edit": "설정",
    "delete": "삭제"
  },
  "tooltip": {
    "id": "id",
    "field_type": "입력 방식",
    "search_options": "입력 값",
    "storable": "저장 여부",
    "searchable": "검색 허용",
    "displayable": "위젯 노출",
    "required": "필수 입력"
  },
  "unselected_categories": "선택하지 않은 모든 카테고리",
  "add_product_category_product_type": "세트 추가",
  "no_data": "설정된 추가 정보 세트가 없습니다.",
  "hint": "상품에 카테고리가 설정되어 있지 않은 경우, 추가 수집정보 설정이 적용되지 않습니다."
}
</i18n>
