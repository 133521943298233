import { constructEnum } from '@/lib/enum';

const TargetCampaignStatus = constructEnum('TargetCampaignStatus', {
  ENABLED: 0,
  TESTING: 10,
  DISABLED: 100,
  ARCHIVED: 200
});

TargetCampaignStatus.notCalculable = [TargetCampaignStatus.ARCHIVED];

TargetCampaignStatus.isCalculable = function(campaignStatus) {
  return !this.notCalculable.includes(campaignStatus);
};

export default TargetCampaignStatus;
