import { constructEnum } from '@/lib/enum';

const TargetWidgetHorizontalScrollButtonStyle = constructEnum(
  'TargetWidgetHorizontalScrollButtonStyle',
  {
    OUTSIDE: 10,
    INSIDE: 20,
    BORDER: 30
  }
);

export default TargetWidgetHorizontalScrollButtonStyle;
