<template>
  <AppSearchbar>
    <template #right>
      <li>
        <AppDateRangePicker
          v-model="dateRange"
          :presets="[
            'recent_year',
            'last_7days',
            'last_30days',
            'this_month',
            'last_month',
            'last_quarter',
            'last_year'
          ]"
          min-date="2021-06-01"
          @update="changeDateRange"
        />
      </li>
      <li>
        <AppButton
          :label="$t('set_competing_group')"
          button-style="grey-outline"
          :disabled="!!brandIds.length"
          :tooltip="
            !!brandIds.length ? $t('set_competing_group_tooltip') : null
          "
          @click="openCompetingGroupModal"
        />
      </li>
      <li v-if="isSuperAdminAccessible">
        <AppButton
          :label="$t('set_competing_brands')"
          class="super-admin__item"
          @click="openCompetingBrandsModal"
        />
      </li>
    </template>
  </AppSearchbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AnalyticsInsightMainSearchbar',
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    brandIds: { type: Array, default: () => [] },
    productCategoryIds: { type: String, default: '' },
    genderCategoryIds: { type: String, default: '' },
    ageCategoryIds: { type: String, default: '' },
    styleCategoryIds: { type: String, default: '' },
    turnoverType: { type: String, default: '' }
  },
  data() {
    return {
      dateRange: {
        start_date: this.startDate,
        end_date: this.endDate
      }
    };
  },
  computed: { ...mapGetters(['isSuperAdminAccessible']) },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    changeDateRange(dateRange) {
      this.$emit('change-date-range', dateRange);
    },
    changeCompetingGroup(params) {
      this.$emit('change-competing-group', params);
    },
    changeCompetingBrands(params) {
      this.$emit('change-competing-brands', params);
    },
    openCompetingGroupModal() {
      this.openDialog([
        'AnalyticsInsightCompetingGroupDialog',
        {
          dateRange: this.dateRange,
          productCategoryIds:
            this.productCategoryIds === 'all' ? '' : this.productCategoryIds,
          genderCategoryIds:
            this.genderCategoryIds === 'all' ? '' : this.genderCategoryIds,
          ageCategoryIds:
            this.ageCategoryIds === 'all' ? '' : this.ageCategoryIds,
          styleCategoryIds:
            this.styleCategoryIds === 'all' ? '' : this.styleCategoryIds,
          turnoverType: this.turnoverType === 'all' ? '' : this.turnoverType
        }
      ]).then(eventBus => {
        eventBus.$on('submit', params => this.changeCompetingGroup(params));
      });
    },
    openCompetingBrandsModal() {
      this.openDialog([
        'AnalyticsInsightCompetingBrandsDialog',
        { brandIds: this.brandIds }
      ]).then(eventBus =>
        eventBus.$on('submit', params => this.changeCompetingBrands(params))
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "set_competing_group": "경쟁 그룹 설정",
  "set_competing_group_tooltip": "경쟁 쇼핑몰 설정이 적용되어 있습니다.\n경쟁 그룹 설정을 적용하려면 경쟁 쇼핑몰 설정값을 초기화해주세요.",
  "set_competing_brands": "경쟁 쇼핑몰 설정"
}
</i18n>
