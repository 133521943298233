<template>
  <AppModalFormLivePreview
    :title="$t('message_settings')"
    :sub-title="campaign.name"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      disabled: isArchivedCampaign,
      objectId: 'campaign'
    }"
    v-on="formEvents"
  >
    <template #left>
      <BizMessageTemplatePreview v-if="isBizMessage" :template="template" />
      <TargetDmCampaignsSmsPreview v-else :message="interpolatedMessage" />
    </template>
    <template #group-foot="{ id }">
      <template v-if="id === 'biz_message_template'">
        <input
          type="hidden"
          name="campaign[biz_message_template_id]"
          :value="bizMessageTemplateId"
        />
        <input
          type="hidden"
          name="campaign[biz_message_template_type]"
          :value="bizMessageTemplateType"
        />
      </template>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { MAX_BYTES_SMS, MAX_BYTES_LMS } from '@/constants/MAX_BYTES';
import api from '@/lib/api';
import interpolate from '@/lib/interpolate';
import DialogFormView from '@/mixins/DialogFormView';
import BizMessageTemplateShowView from '@/mixins/BizMessageTemplateShowView';
import TargetMessageMedia from '@/enums/TargetMessageMedia';
import TargetDmCampaignsSmsPreview from './components/TargetDmCampaignsSmsPreview';

export default {
  name: 'TargetDmCampaignSettingsDesignMessageDialog',
  components: {
    TargetDmCampaignsSmsPreview
  },
  mixins: [DialogFormView, BizMessageTemplateShowView],
  props: {
    campaign: { type: Object, required: true }
  },
  data() {
    return {
      isLoading: true,
      sampleInterpolations: {},
      lmsAdditionalPrice: 0,
      prices: {},
      bizMessageTemplates: []
    };
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('targetCampaign', ['isArchivedCampaign']),
    isBizMessage() {
      return this.formObject.message_type === TargetMessageMedia.BIZ_MESSAGE;
    },
    availableKeywords() {
      return [
        { key: 'prefix', desc: this.$t('prefix') },
        { key: 'unsubscribe', desc: this.$t('unsubscribe') },
        { key: 'user', desc: this.$t('user') },
        { key: 'user_id', desc: this.$t('user_id') },
        { key: 'brand', desc: this.$t('brand') },
        { key: 'url', desc: this.$t('url') },
        { key: 'cart_items_count', desc: this.$t('cart_items_count') },
        { key: 'unpaid_orders_count', desc: this.$t('unpaid_orders_count') },
        {
          key: 'days_before_inactivation',
          desc: this.$t('days_before_inactivation')
        },
        { key: 'review_url', desc: this.$t('review_url') },
        { key: 'coupon_name', desc: this.$t('coupon_name') },
        { key: 'coupon_expire', desc: this.$t('coupon_expire') },
        {
          key: 'days_before_expiration',
          desc: this.$t('days_before_expiration')
        },
        {
          key: 'days_before_coupon_expiration',
          desc: this.$t('days_before_coupon_expiration')
        },
        { key: 'brand_phone', desc: this.$t('brand_phone') },
        { key: 'link_id', desc: this.$t('link_id') }
      ];
    },
    formSections() {
      if (this.isLoading) return [];

      return [
        {
          id: 'target_message_settings',
          groups: [
            {
              id: 'enable_sms',
              label: this.$t('enable_sms'),
              type: 'checkbox',
              superAdmin: true
            },
            {
              id: 'message_type',
              label: this.$t('message_type'),
              type: 'select_radio',
              options: TargetMessageMedia.options([
                TargetMessageMedia.SMS,
                TargetMessageMedia.LMS,
                ...(this.formObject.supports_biz_message
                  ? [TargetMessageMedia.BIZ_MESSAGE]
                  : [])
              ]).map(({ value }) => {
                return {
                  label: this.$t(TargetMessageMedia.key(value), [
                    this.prices[value]
                  ]),
                  value: value
                };
              })
            },
            ...(this.formObject.message_type === TargetMessageMedia.SMS
              ? this.groupsSms
              : this.formObject.message_type === TargetMessageMedia.LMS
              ? this.groupsLms
              : this.groupsBizMessage)
          ]
        }
      ];
    },
    groupsSms() {
      const { keywords } = this;

      return [
        {
          type: 'infobox',
          label: '',
          value: this.$t('disclaimer_html')
        },
        {
          id: 'message_body',
          label: this.$t('message_body'),
          type: 'keyword_textarea',
          keywords,
          maxBytes: MAX_BYTES_SMS,
          validate: [
            'required',
            { rule: 'keywords', keywords },
            {
              rule: 'max_bytes',
              limit: MAX_BYTES_SMS,
              value: () => this.interpolatedMessage
            }
          ]
        }
      ];
    },
    groupsLms() {
      const { keywords } = this;

      return [
        { id: 'disclaimer', label: '' },
        {
          id: 'message_subject',
          label: this.$t('message_subject'),
          type: 'keyword_textarea',
          keywords,
          singleLine: true,
          validate: ['required', { rule: 'keywords', keywords }]
        },
        {
          id: 'message_body',
          label: this.$t('message_body'),
          type: 'keyword_textarea',
          maxBytes: MAX_BYTES_LMS,
          keywords,
          validate: [
            'required',
            { rule: 'keywords', keywords },
            {
              rule: 'max_bytes',
              limit: MAX_BYTES_LMS,
              value: () => this.interpolatedMessage
            }
          ]
        }
      ];
    },
    groupsBizMessage() {
      return [
        ...[
          !this.isSuperAdminAccessible && {
            type: 'infobox',
            label: '',
            value: this.$t('editing_biz_message_info_html')
          }
        ].filter(v => v),
        {
          id: 'biz_message_template',
          label: this.$t('biz_message_template'),
          type: 'select',
          options: this.bizMessageTemplates.map(v => ({
            label: v.name,
            value: this.bizMessageTemplateToString(v)
          })),
          placeholder:
            this.bizMessageTemplates.length > 0
              ? this.$t('biz_message_template_placeholder')
              : this.$t('biz_message_template_blank_placeholder'),
          validate: ['required'],
          hint: this.$t('biz_message_template_hint')
        }
      ].map(v => ({ ...v, disabled: !this.isSuperAdminAccessible }));
    },
    interpolatedMessage() {
      const { message_body, biz_message_text } = this.formObject;

      return interpolate(
        this.isBizMessage ? biz_message_text : message_body || '',
        this.sampleInterpolation
      );
    },
    sampleInterpolation() {
      return this.sampleInterpolations[
        this.isBizMessage ? 'biz_message' : 'sms'
      ];
    },
    keywords() {
      return this.availableKeywords
        .map(keyword => {
          return keyword.key in this.sampleInterpolation
            ? { ...keyword, value: this.sampleInterpolation[keyword.key] }
            : null;
        })
        .filter(k => k);
    },
    bizMessageTemplateId() {
      if (!this.formObject.biz_message_template) return null;
      return Number(this.formObject.biz_message_template.split('-')[0]);
    },
    bizMessageTemplateType() {
      if (!this.formObject.biz_message_template) return null;
      return Number(this.formObject.biz_message_template.split('-')[1]);
    }
  },
  watch: {
    'formObject.biz_message_template'() {
      if (!this.bizMessageTemplateId) return;

      this.fetchTemplate({
        id: this.bizMessageTemplateId,
        templateType: this.bizMessageTemplateType
      }).then(
        ({ data }) => (this.template = this.formatTemplate(data.template))
      );
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaign.id}/message_settings`)
      .then(({ data }) => {
        const { biz_message_template } = data.campaign;
        delete data.campaign.biz_message_template;

        this.orgFormObject = data.campaign;
        this.orgFormObject.biz_message_template = this.bizMessageTemplateToString(
          biz_message_template
        );
        this.lmsAdditionalPrice = data.lms_additional_price;
        this.prices[TargetMessageMedia.SMS] = data.campaign.sms_price;
        this.prices[TargetMessageMedia.LMS] = data.campaign.lms_price;
        this.prices[TargetMessageMedia.BIZ_MESSAGE] =
          data.campaign.biz_message_price;
        this.sampleInterpolations = data.sample_interpolations;
        this.bizMessageTemplates = data.biz_message_templates;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    ...mapActions('bizMessageTemplate', ['fetchTemplate']),
    bizMessageTemplateToString(bizMessageTemplate) {
      if (!bizMessageTemplate?.id) return null;
      return [bizMessageTemplate.id, bizMessageTemplate.template_type].join(
        '-'
      );
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/target/campaigns/${this.campaign.id}/message_settings`,
          formData,
          { successMessage: this.$t('app.saved') }
        )
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "message_settings": "문자 메시지 설정",
  "enable_sms": "문자 메시지 사용 여부",
  "message_type": "메시지 종류",
  "message_subject": "문자 메시지 제목",
  "message_body": "문자 메시지",
  "SMS": "SMS - 90Bytes 이하, 발송당 {0}원",
  "LMS": "LMS - 2,000Bytes 이하, 발송당 {0}원",
  "BIZ_MESSAGE": "알림톡 - 발송당 {0}원",
  "prefix": "(광고)쇼핑몰명",
  "unsubscribe": "수신거부번호",
  "user": "고객명",
  "user_id": "고객 아이디",
  "url": "랜딩페이지 URL",
  "brand": "쇼핑몰명",
  "brand_phone": "쇼핑몰 대표 전화번호",
  "cart_items_count": "장바구니 상품 수",
  "unpaid_orders_count": "무통장 미입금 주문 수",
  "days_before_inactivation": "휴면회원 전환예정 일수",
  "days_before_expiration": "적립금 지급 기한 만료 잔여일 수",
  "days_before_coupon_expiration": "쿠폰 만료 잔여일",
  "coupon_name": "쿠폰명",
  "coupon_expire": "쿠폰 만료일",
  "max_best_mileage": "최대 적립금",
  "review_url": "리뷰 랜딩페이지 URL",
  "link_id": "링크 ID",
  "disclaimer_html": "<b>통신법상 (광고)/수신거부번호를 포함해야 합니다.<br />미포함에 의한 법적 문제는 크리마가 책임지지 않습니다.</b><br /><br />편의를 위해 예약어 입력시 자동으로 문구를 생성합니다.",
  "biz_message_template": "알림톡 템플릿(루나소프트)",
  "biz_message_template_placeholder": "알림톡 템플릿을 선택해주세요.",
  "biz_message_template_blank_placeholder": "등록된 알림톡 템플릿이 없습니다.",
  "biz_message_template_hint": "알림톡 템플릿은 [타겟 설정]메뉴 [외부서비스]탭에 있는\n[알림톡 템플릿 관리]에서 관리할 수 있습니다.",
  "editing_biz_message_info_html": "<b>알림톡 발송 문구는 카카오의 승인 후 사용이 가능합니다.</b><br />문구 수정이 필요한 경우 타겟 운영팀으로 문의해 주세요.",
  "link_id": "짧은 URL을 위한 Key값"
}
</i18n>
