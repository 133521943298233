<template>
  <AppModal width="280px" :margin-vertical="140">
    <div slot="body" class="AppProgressDialog__body">
      <div class="AppProgressDialog__title">{{ title }}</div>
      <AppProgress
        :total-count="totalCount"
        :progress-count="progressCount"
        :started-at="startedAt"
      />
    </div>
  </AppModal>
</template>

<script>
import DialogView from '@/mixins/DialogView';
import UnloadConfirmable from '@/mixins/UnloadConfirmable';

export default {
  name: 'AppProgressDialog',
  mixins: [DialogView, UnloadConfirmable],
  props: {
    title: {
      type: String,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      startedAt: new Date(),
      progressCount: 0
    };
  },
  mounted() {
    this.enableUnloadConfirmation();
    this.eventBus.$on('progress', this.progress);
    this.eventBus.$on('abort', this.closeDialog);
  },
  methods: {
    progress() {
      const TRANSITION_DELAY = 50;
      this.progressCount += 1;
      if (this.progressCount === this.totalCount)
        setTimeout(() => this.close(true), TRANSITION_DELAY);
    },
    close(force) {
      if (force || confirm(this.$t('confirm', { title: this.title })))
        this.closeDialog();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppProgressDialog__body {
  padding: 12px;
}

.AppProgressDialog__title {
  @include text-label;
  margin-bottom: 12px;
}
</style>

<i18n locale="ko">
{
  "confirm": "{title} 중입니다. 중단하고 페이지를 나가겠습니까?"
}
</i18n>
