<template>
  <AppModalDefault :title="$t('title')" @close="close">
    <template #body>
      <AppDataList :data="data" gap="wide" />
    </template>
    <template #foot>
      <AppButton :label="$t('app.confirmed')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import DialogView from '@/mixins/DialogView';

export default {
  name: 'ThePaymentReviewPackageInfoDialog',
  mixins: [DialogView],
  props: {
    reviewPackage: { type: Object, required: true },
    reviewExpiresAt: { type: String, required: true }
  },
  data() {
    return { dateFormat: 'YYYY.MM.DD' };
  },
  computed: {
    ...mapGetters('payment', ['packageName']),
    data() {
      return [
        {
          id: 'expired_date',
          label: this.$t('data_label.expired_date'),
          value: moment(this.reviewExpiresAt).format(this.dateFormat)
        },
        {
          id: 'package_name',
          label: this.$t('data_label.package_name'),
          value: this.packageName(this.reviewPackage)
        },
        {
          id: 'operating_term',
          label: this.$t('data_label.operating_term'),
          value: `${moment(this.reviewExpiresAt)
            .add(1, 'day')
            .format(this.dateFormat)}~${this.newReviewExpiredAtStr}`
        }
      ];
    },
    newReviewExpiredAtStr() {
      return moment(this.reviewExpiresAt)
        .add(this.reviewPackage.months, 'month')
        .format(this.dateFormat);
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "리뷰 서비스 기간 반영 안내",
  "data_label": {
    "expired_date": "기간 만료일",
    "package_name": "결제한 상품 종류",
    "operating_term": "서비스 반영 기간"
  }
}
</i18n>
