<template>
  <AppContainer class="TargetCampaignStatsSectionChart">
    <AppDropdownMenuButton
      :label="TargetCampaignChartType.t(chartType)"
      :menu-items="menuItems"
      button-style="label"
    />
    <div class="TargetCampaignStatsSectionChart__control">
      <AppSelectButton
        v-if="isShowMediaSubReportType(chartType)"
        v-model="subReportType"
        class="TargetCampaignStatsSectionChart__control-item"
        :options="MEDIA_OPTIONS"
      />
      <AppSelectButtonInterval
        :value="resourceParams.interval"
        class="TargetCampaignStatsSectionChart__control-item"
        @change="changeInterval"
      />
    </div>
    <AppAjaxContent
      class="TargetCampaignStatsSectionChart__body"
      :is-loading="isLoading"
      :height="chartHeight"
    >
      <TargetCampaignStatsChart
        v-bind="{
          resourceParams,
          chartHeight,
          seriesColumns: finalSeriesColumns,
          reports,
          reportsRange
        }"
      />
    </AppAjaxContent>
  </AppContainer>
</template>

<script>
import api from '@/lib/api';
import { dateRangeWithInterval } from '@/lib/date';

import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';
import TargetCampaignChartType from '@/enums/TargetCampaignChartType';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';

import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import TargetCampaignStatsChart from './TargetCampaignStatsChart';

export default {
  name: 'TargetCampaignStatsSectionChart',
  components: { TargetCampaignStatsChart },
  mixins: [ResourceViewNoRoute],
  props: {
    campaign: { type: Object, required: true },
    dateRange: { type: Object, required: true },
    samplingMethod: { type: Number, required: true }
  },
  data() {
    return {
      isLoading: true,
      MEDIA_OPTIONS: [
        { value: 'sms', label: this.$t('media_option_sms') },
        { value: 'email', label: this.$t('media_option_email') }
      ],
      reports: [],
      reportsRange: {},
      subReportType: null,
      chartType: null
    };
  },
  computed: {
    defaultResourceParams: () => ({}),
    TargetCampaignChartType: () => TargetCampaignChartType,
    chartTypes() {
      const { campaign_type, campaign_media_type } = this.campaign;
      const chartTypeKpi = TargetCampaignChartType.kpi(campaign_type);
      const chartTypeResponse = TargetCampaignChartType.response(
        campaign_type,
        campaign_media_type
      );
      return [
        ...(chartTypeKpi ? [chartTypeKpi] : []),
        ...(chartTypeResponse ? [chartTypeResponse] : []),
        ...(campaign_media_type === TargetCampaignMediaType.DIRECT_MESSAGE
          ? [
              TargetCampaignChartType.UNSUBSCRIPTION_RATE,
              TargetCampaignChartType.DISPATCH_COUNT
            ]
          : campaign_media_type == TargetCampaignMediaType.WIDGET
          ? [TargetCampaignChartType.REACH_RATE]
          : campaign_media_type === TargetCampaignMediaType.POPUP
          ? [TargetCampaignChartType.UNIQUE_CHECK_COUNT]
          : [])
      ];
    },
    menuItems() {
      return this.chartTypes.map(chartType => ({
        label: TargetCampaignChartType.t(chartType),
        clickHandler: e => this.selectChartType(e, chartType)
      }));
    },
    chartHeight: () => '218px',
    finalSeriesColumns() {
      return this.seriesColumns.map(column => ({
        ...column,
        id: TargetSamplingMethod.column(this.samplingMethod, column.id)
      }));
    },
    seriesColumns() {
      const { campaign_media_type } = this.campaign;
      switch (campaign_media_type) {
        case TargetCampaignMediaType.DIRECT_MESSAGE:
          return this.seriesColumnsDm;
        case TargetCampaignMediaType.WIDGET:
          return this.seriesColumnsWidget;
        case TargetCampaignMediaType.POPUP:
          return this.seriesColumnsPopup;
        default:
          return [];
      }
    },
    seriesColumnsDm() {
      const { chartType, subReportType } = this;
      switch (chartType) {
        case TargetCampaignChartType.INACTIVATION_PREVENTION_RATE:
          return [
            {
              id: 'respond_rate',
              name: this.$t('sum'),
              type: 'percentage',
              dotted: true
            },
            {
              id: 'respond_rate_sms',
              name: this.$t('sms'),
              type: 'percentage'
            },
            {
              id: 'respond_rate_email',
              name: this.$t('email'),
              type: 'percentage'
            }
          ];
        case TargetCampaignChartType.REVISIT_RATE:
          return [
            {
              id: 'respond_rate',
              name: this.$t('sum'),
              type: 'percentage',
              dotted: true
            },
            {
              id: 'respond_rate_sms',
              name: this.$t('sms'),
              type: 'percentage'
            },
            {
              id: 'respond_rate_email',
              name: this.$t('email'),
              type: 'percentage'
            }
          ];
        case TargetCampaignChartType.PURCHASE_RATE:
          return [
            {
              id: 'purchase_rate',
              name: this.$t('sum'),
              type: 'percentage',
              dotted: true
            },
            {
              id: 'purchase_rate_sms',
              name: this.$t('sms'),
              type: 'percentage'
            },
            {
              id: 'purchase_rate_email',
              name: this.$t('email'),
              type: 'percentage'
            }
          ];
        case TargetCampaignChartType.REVIEW_RATE:
          return [
            {
              id: 'review_rate',
              name: this.$t('sum'),
              type: 'percentage',
              dotted: true
            },
            { id: 'review_rate_sms', name: this.$t('sms'), type: 'percentage' },
            {
              id: 'review_rate_email',
              name: this.$t('email'),
              type: 'percentage'
            }
          ];
        case TargetCampaignChartType.PAY_RATE:
          return [
            {
              id: 'purchase_rate',
              name: this.$t('sum'),
              type: 'percentage',
              dotted: true
            },
            {
              id: 'purchase_rate_sms',
              name: this.$t('sms'),
              type: 'percentage'
            },
            {
              id: 'purchase_rate_email',
              name: this.$t('email'),
              type: 'percentage'
            }
          ];
        case TargetCampaignChartType.CHECK_RATE:
          return subReportType === 'sms'
            ? [
                {
                  id: 'check_rate_sms',
                  name: this.$t('check_rate'),
                  type: 'percentage',
                  colorIndex: 1
                }
              ]
            : [
                {
                  id: 'check_rate_email',
                  name: this.$t('check_rate'),
                  type: 'percentage',
                  colorIndex: 1
                }
              ];
        case TargetCampaignChartType.CHECK_AND_CLICK_RATE:
          return subReportType === 'sms'
            ? [
                {
                  id: 'check_rate_sms',
                  name: this.$t('check_rate'),
                  type: 'percentage',
                  colorIndex: 1
                },
                {
                  id: 'click_rate_sms',
                  name: this.$t('click_rate'),
                  type: 'percentage',
                  colorIndex: 2
                }
              ]
            : [
                {
                  id: 'check_rate_email',
                  name: this.$t('check_rate'),
                  type: 'percentage',
                  colorIndex: 1
                },
                {
                  id: 'click_rate_email',
                  name: this.$t('click_rate'),
                  type: 'percentage',
                  colorIndex: 2
                }
              ];
        case TargetCampaignChartType.UNSUBSCRIPTION_RATE:
          return [
            {
              id: 'unsubscribed_rate',
              name: this.$t('sum'),
              type: 'percentage',
              dotted: true
            },
            {
              id: 'unsubscribed_rate_sms',
              name: this.$t('sms'),
              type: 'percentage'
            },
            {
              id: 'unsubscribed_rate_email',
              name: this.$t('email'),
              type: 'percentage'
            }
          ];
        case TargetCampaignChartType.DISPATCH_COUNT:
          return [
            { id: 'sent_count', name: this.$t('sum'), dotted: true },
            { id: 'sent_count_sms', name: this.$t('sms') },
            { id: 'sent_count_email', name: this.$t('email') }
          ];
        case TargetCampaignChartType.SIGNUP_RATE:
          return [
            {
              id: 'respond_rate',
              name: TargetCampaignChartType.t(
                TargetCampaignChartType.SIGNUP_RATE
              ),
              type: 'percentage',
              colorIndex: 1
            }
          ];
        default:
          return [];
      }
    },
    seriesColumnsPopup() {
      const { chartType } = this;
      const common = {
        name: TargetCampaignChartType.t(chartType),
        type: 'percentage',
        colorIndex: 1
      };
      switch (chartType) {
        case TargetCampaignChartType.INACTIVATION_PREVENTION_RATE:
          return [{ ...common, id: 'respond_rate' }];
        case TargetCampaignChartType.REVISIT_RATE:
          return [{ ...common, id: 'respond_rate' }];
        case TargetCampaignChartType.PURCHASE_RATE:
          return [{ ...common, id: 'purchase_rate' }];
        case TargetCampaignChartType.REVIEW_RATE:
          return [{ ...common, id: 'review_rate' }];
        case TargetCampaignChartType.PAY_RATE:
          return [{ ...common, id: 'purchase_rate' }];
        case TargetCampaignChartType.UNSUBSCRIPTION_RATE:
          return [{ ...common, id: 'unsubscribed_rate' }];
        case TargetCampaignChartType.CLICK_PER_CHECK:
          return [{ ...common, id: 'click_per_check' }];
        case TargetCampaignChartType.UNIQUE_CHECK_COUNT:
          return [{ ...common, id: 'unique_check_count', type: undefined }];
        case TargetCampaignChartType.SIGNUP_RATE:
          return [{ ...common, id: 'respond_rate' }];
        default:
          return [];
      }
    },
    seriesColumnsWidget() {
      const { chartType } = this;
      const common = {
        name: TargetCampaignChartType.t(chartType),
        type: 'percentage',
        colorIndex: 1
      };
      switch (chartType) {
        case TargetCampaignChartType.PURCHASE_RATE:
          return [{ ...common, id: 'purchase_rate' }];
        case TargetCampaignChartType.CLICK_PER_CHECK:
          return [{ ...common, id: 'click_per_check' }];
        case TargetCampaignChartType.REACH_RATE:
          return [{ ...common, id: 'reach_rate' }];
        default:
          return [];
      }
    }
  },
  watch: {
    dateRange() {
      this.resourceParams = {
        ...this.resourceParams,
        ...dateRangeWithInterval(this.dateRange, this.resourceParams.interval)
      };
      this.searchResource();
    }
  },
  created() {
    this.setOrgResourceParams({ ...this.dateRange, interval: 'daily' });
    this.chartType = this.chartTypes[0];
  },
  methods: {
    selectChartType({ close }, chartType) {
      this.chartType = chartType;
      this.subReportType = this.isShowMediaSubReportType(chartType)
        ? 'sms'
        : null;
      close();
    },
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/target/campaigns/${this.campaign.id}/reports_chart`, { params })
        .then(({ data }) => {
          this.reportsRange = { ...this.resourceParams };
          this.reports = data.reports;
        })
        .finally(() => (this.isLoading = false));
    },
    changeInterval(interval) {
      this.resourceParams = {
        ...this.resourceParams,
        ...dateRangeWithInterval(this.dateRange, interval),
        interval
      };
      this.searchResource();
    },
    isShowMediaSubReportType(chartType) {
      return [
        TargetCampaignChartType.CHECK_RATE,
        TargetCampaignChartType.CHECK_AND_CLICK_RATE
      ].includes(chartType);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetCampaignStatsSectionChart {
  position: relative;
  height: 100%;
}

.TargetCampaignStatsSectionChart__body {
  margin-top: 24px;
}

.TargetCampaignStatsSectionChart__control {
  position: absolute;
  top: 10px;
  right: 12px;
}

.TargetCampaignStatsSectionChart__control-item {
  float: left;

  & + & {
    margin-left: 8px;
  }
}
</style>

<i18n locale="ko">
{
  "media_option_sms": "문자",
  "media_option_email": "이메일",
  "sum": "합계",
  "sms": "문자 메시지",
  "email": "이메일",
  "check_rate": "확인율",
  "click_rate": "클릭률"
}
</i18n>
