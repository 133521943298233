<template>
  <AppModalForm
    :title="$t('settings.fit_settings_widgets.groups.module_settings')"
    :form-props="{ ...formProps, id: 'fit_module_settings' }"
    v-on="formEvents"
  />
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'FitModuleSettingsDialog',
  mixins: [DialogFormView],
  props: {
    orgFitSettings: { type: Object, required: true },
    reviewOptionTypes: { type: Array, required: true }
  },
  data() {
    return {
      orgFormObject: this.orgFitSettings
    };
  },
  computed: {
    ...mapState('session', ['reviewSettings']),
    reviewOptionTypesOptions() {
      return this.reviewOptionTypes.map(t => ({
        label: t.name,
        value: t.id
      }));
    },
    formSections() {
      return [
        {
          groups: _.flatten([
            this.reviewSettings.use_product_category_product_type
              ? []
              : [
                  {
                    id: 'fit_message_review_option_type_id',
                    type: 'select',
                    options: this.reviewOptionTypesOptions,
                    placeholder: ''
                  },
                  {
                    id: 'fit_score_review_option_type_id',
                    type: 'select',
                    options: this.reviewOptionTypesOptions,
                    placeholder: ''
                  }
                ],
            {
              id: 'feedback_show_author_name',
              type: 'select_radio',
              options: [
                {
                  label: this.$t('feedback_show_author_name_show'),
                  value: true
                },
                {
                  label: this.$t('feedback_show_author_name_hide'),
                  value: false
                }
              ]
            },
            { id: 'feedback_emphasizing_color', type: 'color' }
          ])
        }
      ];
    }
  },
  methods: {
    submit() {
      this.eventBus.$emit('save', this.formObject);
      this.close(true);
    }
  }
};
</script>

<i18n locale="ko">
{
  "feedback_show_author_name_show": "리뷰 위젯 설정과 동일하게 표시",
  "feedback_show_author_name_hide": "표시하지 않음"
}
</i18n>
