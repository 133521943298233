<template>
  <div class="UiTabsScrollable">
    <AppTabsScrollable :tabs="TABS" />
    <div v-for="tab in TABS" :id="tab.id" :key="tab.id" class="ui-section">
      <div class="ui-section__title">{{ tab.label }}</div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque in
        neque dolor. Aliquam erat volutpat. Duis at urna aliquet, dignissim
        lorem consectetur, iaculis nunc. Phasellus facilisis leo sit amet eros
        tristique, non facilisis nisl viverra. Etiam a tellus purus. Aenean
        elementum auctor dui ut auctor. Ut nec leo dapibus nulla imperdiet
        vestibulum. Pellentesque auctor et metus sit amet iaculis. Fusce aliquet
        ac lectus nec porttitor. Integer elit nisl, feugiat in enim quis,
        condimentum condimentum elit. Quisque molestie vulputate pulvinar.
      </p>
      <p>
        Aenean mollis tempus odio in semper. Nullam tellus urna, interdum at
        accumsan eget, fermentum vitae diam. Suspendisse condimentum semper
        erat, at hendrerit arcu venenatis eu. Ut ac diam facilisis, tempus nulla
        sit amet, vulputate nisi. Vivamus iaculis, nisl id vulputate laoreet,
        justo augue feugiat magna, eu rhoncus magna augue at lectus. Sed nec
        libero vitae ligula suscipit lobortis nec eget orci. Vivamus nec lacus
        nec mauris vestibulum blandit.
      </p>
      <p>
        Morbi rhoncus orci consectetur, consequat mi sed, volutpat leo. Quisque
        luctus, dolor ut luctus porta, quam sem gravida nibh, ut ornare sapien
        justo vel nisl. Aliquam sed ullamcorper erat, eu sodales erat. Nulla id
        ipsum ultricies, lacinia lorem ac, porttitor leo. Nunc sagittis mattis
        est, sit amet condimentum neque lacinia a. Vivamus tincidunt neque eu
        felis imperdiet aliquam. Sed fermentum egestas ex, ut sodales nisl. Duis
        malesuada orci at diam gravida, a facilisis lorem fringilla. Praesent
        vulputate gravida nunc, in luctus est iaculis vel. Quisque maximus nunc
        eu nibh tincidunt mollis. Cras egestas gravida leo quis aliquam.
        Pellentesque in condimentum eros, vitae rhoncus nulla. Vivamus dictum
        convallis risus vitae rutrum. Pellentesque maximus lorem a ante
        sollicitudin vehicula. Aenean et diam nisi. In non sodales metus.
      </p>
      <p>
        Duis ut eleifend arcu, sit amet placerat lectus. Duis euismod lectus
        magna, sit amet interdum mauris posuere vel. Suspendisse gravida egestas
        ipsum. Nam non tempor tellus, eget porta purus. Quisque blandit
        facilisis erat vel hendrerit. Quisque semper leo in nisi vehicula
        semper. Curabitur tincidunt sem in ante suscipit rutrum. Cras vel
        tincidunt mauris. Nullam eros quam, ullamcorper eu lobortis vel,
        faucibus eu tortor. Nulla malesuada at augue sit amet imperdiet.
      </p>
      <p>
        Vivamus sit amet fermentum ex. Nullam eros nulla, rhoncus lacinia nulla
        at, gravida volutpat lectus. Vestibulum maximus erat eget mi rutrum, ac
        placerat metus dapibus. Vestibulum eu ullamcorper massa. Nunc a
        consectetur est. Nulla mattis, elit a fermentum venenatis, turpis diam
        pretium lorem, quis imperdiet justo lectus quis ante. Morbi a neque
        ligula. Integer bibendum purus vitae turpis tincidunt, a hendrerit nunc
        fringilla. Etiam laoreet gravida nisi venenatis finibus. Praesent
        facilisis magna ac dolor tincidunt, ac fermentum enim scelerisque.
        Nullam a libero sit amet mauris vestibulum viverra. Etiam lobortis at
        mauris posuere rutrum. Phasellus sollicitudin, nisl eu rutrum posuere,
        arcu nunc mattis orci, sed tempus enim elit nec felis. Sed a arcu purus.
        Interdum et malesuada fames ac ante ipsum primis in faucibus. Phasellus
        luctus ornare massa in lobortis.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTabsScrollable',
  data() {
    return {
      TABS: new Array(5).fill(null).map((_, i) => {
        return {
          id: `paragraph${i + 1}`,
          label: `Paragraph${i + 1}`
        };
      })
    };
  }
};
</script>

<style lang="scss" scoped>
.UiTabsScrollable {
  max-height: 500px;
  overflow-y: scroll;
}
</style>
