<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppMultipleSelectBox
        id="basic"
        v-model="valueBasic"
        :options="OPTIONS_BASIC"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="string">String Value</label>
      </div>
      <AppMultipleSelectBox
        id="string"
        v-model="valueString"
        :options="OPTIONS_STRING"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueString }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="stringify">Stringify</label>
      </div>
      <AppMultipleSelectBox
        id="stringify"
        v-model="valueStringify"
        :options="OPTIONS_BASIC"
        packing-method="string"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueStringify }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppMultipleSelectBox
        id="invalid"
        v-model="valueInvalid"
        :options="OPTIONS_BASIC"
        invalid
      />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="custom">Custom</label>
      </div>
      <AppMultipleSelectBox
        id="new_label"
        v-model="valueCustom"
        :options="OPTIONS_BASIC"
        query-placeholder="query placeholder"
        selected-options-placeholder="selected options placeholder"
        search-options-label="serach options label"
        selected-options-label="selected options label"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueCustom }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiMultipleSelectBox',
  data() {
    return {
      OPTIONS_BASIC: [
        { value: 1, label: 'Ant-Man' },
        { value: 2, label: 'Black Panther' },
        { value: 3, label: '블랙 위도우' },
        { value: 4, label: '캡틴 아메리카' },
        { value: 5, label: 'Captain Marvel' },
        { value: 6, label: 'Doctor Strange' },
        { value: 8, label: 'Falcon', disabled: true },
        {
          value: 9,
          label:
            'Guardians of the Galaxy - Drax the Destroyer, Gamora, Groot, Star-Lord, Rocket'
        },
        { value: 11, label: '호크아이' },
        { value: 12, label: '헐크' },
        { value: 13, label: '아이언맨' },
        { value: 16, label: 'Scarlet Witch' },
        { value: 17, label: 'Spider-Man' },
        { value: 19, label: '토르' },
        { value: 20, label: 'Vision' },
        { value: 21, label: 'War Machine' },
        { value: 22, label: 'Wasp' },
        { value: 23, label: 'Winter Soldier' }
      ],
      OPTIONS_STRING: [
        { value: 'Ant-Man', label: 'Ant-Man' },
        { value: 'Black Panther', label: 'Black Panther' },
        { value: '블랙 위도우', label: '블랙 위도우' },
        { value: '캡틴 아메리카', label: '캡틴 아메리카' },
        { value: 'Captain Marvel', label: 'Captain Marvel' },
        { value: 'Doctor Strange', label: 'Doctor Strange' },
        { value: 'Falcon', label: 'Falcon', disabled: true },
        {
          value:
            'Guardians of the Galaxy - Drax the Destroyer, Gamora, Groot, Star-Lord, Rocket',
          label:
            'Guardians of the Galaxy - Drax the Destroyer, Gamora, Groot, Star-Lord, Rocket'
        },
        { value: '호크아이', label: '호크아이' },
        { value: '헐크', label: '헐크' },
        { value: '아이언맨', label: '아이언맨' },
        { value: 'Scarlet Witch', label: 'Scarlet Witch' },
        { value: 'Spider-Man', label: 'Spider-Man' },
        { value: '토르', label: '토르' },
        { value: 'Vision', label: 'Vision' },
        { value: 'War Machine', label: 'War Machine' },
        { value: 'Wasp', label: 'Wasp' },
        { value: 'Winter Soldier', label: 'Winter Soldier' }
      ],
      valueBasic: [4, 13],
      valueString: ['캡틴 아메리카', '아이언맨'],
      valueStringify: '4,13',
      valueInvalid: [],
      valueCustom: []
    };
  }
};
</script>
