<template>
  <AppModalForm
    :title="$t(isEditing ? 'title_edit' : 'title_new')"
    :form-props="{ ...formProps, objectId: 'smart_installation' }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="smart_installation[installation_type]"
      :value="smartInstallationType"
    />
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import WidgetPageType from '@/enums/WidgetPageType';
import SmartInstallationType from '@/enums/SmartInstallationType';
import _ from 'lodash';

export default {
  name: 'ReviewInstallSimulationMyWrittenReviewsCountFormDialog',
  mixins: [DialogFormView],
  props: {
    myWrittenReviewsCount: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      smartInstallationType: SmartInstallationType.MY_WRITTEN_REVIEWS_COUNT
    };
  },
  computed: {
    groupOptions() {
      return {
        device_type: {
          type: 'select_radio',
          options: WidgetDeviceType.options(),
          validate: ['required'],
          required: true
        },
        page_type: {
          type: 'select',
          placeholder: this.$t('page_type.placeholder'),
          options: this.widgetPageTypes,
          validate: ['required'],
          required: true
        },
        page_path: {
          type: 'text',
          groupTooltip: this.$t('page_path.tooltip'),
          placeholder: this.$t('page_path.placeholder'),
          validate: [
            'required',
            {
              rule: 'check_path_or_url',
              errorMessage: this.$t('page_path.error')
            }
          ],
          required: true
        },
        element_selector: {
          type: 'text',
          placeholder: this.$t('element_selector.placeholder'),
          validate: [
            'required',
            {
              rule: 'css_selector_format',
              errorMessage: this.$t('validations.selector_format')
            }
          ]
        },
        skin_id: {
          groupDescription: this.$t('skin_id.description'),
          type: 'text',
          placeholder: this.$t('skin_id.placeholder')
        },
        reviews_count_display_format: {
          type: 'keyword_textarea',
          required: true,
          keywords: this.keywords,
          singleLine: true,
          validate: [
            'required',
            {
              rule: 'html_format',
              errorMessage: this.$t('validations.html_format')
            }
          ],
          value: this.formObject.reviews_count_display_format
        }
      };
    },
    groupIds() {
      return this.formObject.page_type === WidgetPageType.SPECIFIC
        ? [
            'device_type',
            'page_type',
            'page_path',
            'element_selector',
            'skin_id',
            'reviews_count_display_format'
          ]
        : [
            'device_type',
            'page_type',
            'element_selector',
            'skin_id',
            'reviews_count_display_format'
          ];
    },
    formSections() {
      return [
        {
          id: 'default',
          groups: this.groupIds.map(id => {
            return {
              id,
              label: this.$t(`${id}.label`),
              ...this.groupOptions[id]
            };
          })
        }
      ];
    },
    widgetPageTypes() {
      return WidgetPageType.options([
        WidgetPageType.MYSHOP_MAIN,
        WidgetPageType.MY_REVIEWS,
        WidgetPageType.MY_ORDERS,
        WidgetPageType.SPECIFIC
      ]);
    },
    keywords() {
      return [
        {
          key: 'count',
          desc: this.$t('keywords.count'),
          value: '%{count}'
        }
      ];
    },
    isEditing() {
      return !!this.formObject.id;
    }
  },
  mounted() {
    this.orgFormObject = _.cloneDeep(this.myWrittenReviewsCount);
  },
  methods: {
    submit(formData) {
      if (this.isEditing) {
        api
          .patch(
            '/install_simulation/simulations/update_installation',
            formData,
            {
              successMessage: this.$t('app.saved')
            }
          )
          .then(({ data }) => {
            this.eventBus.$emit('update', data.smart_installation);
            this.close(true);
          });
      } else {
        api
          .post('/install_simulation/simulations', formData, {
            successMessage: this.$t('app.created')
          })
          .then(({ data }) => {
            this.eventBus.$emit('save', data.smart_installation);
            this.close(true);
          });
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_new": "내가 작성한 리뷰 수 교체 설치 추가",
    "title_edit": "내가 작성한 리뷰 수 교체 설치 설정",
  "device_type": {
    "label": "디바이스 선택"
  },
  "page_type": {
    "label": "설치 페이지",
    "placeholder": "페이지를 선택해주세요."
  },
  "element_selector": {
    "label": "설치 위치 입력",
    "placeholder": "설치 위치를 입력해주세요."
  },
  "page_path": {
    "label": "설치 페이지 URL",
    "placeholder": "쇼핑몰 주소를 제외한 URL을 입력해주세요.",
    "tooltip": "/board/review.html 와 같이 쇼핑몰 주소를 제외한 URL을 입력해주세요.\n예를들어 페이지 URL이 http://www.cre.ma/board/review.html라면\n/board/review.html 를 입력해야합니다.",
    "error": "잘못된 형식의 url입니다."
  },
  "skin_id": {
    "label": "적용 스킨 정보",
    "placeholder": "스킨 정보를 입력해주세요.",
    "description": "입력하지 않을 경우 모든 스킨에 적용됩니다."
  },
  "reviews_count_display_format": {
    "label": "표시 정보"
  },
  "keywords": {
    "count": "내가 작성한 리뷰 수"
  }
}
</i18n>
