<template>
  <div class="TargetCampaignsTableEffectUnavailableCell">
    <pre v-if="!campaign.configured">{{ $t('not_configured') }}</pre>
    <pre v-else>{{ $t('inserted_url_not_found') }}</pre>
  </div>
</template>

<script>
export default {
  name: 'TargetCampaignsTableEffectUnavailableCell',
  props: {
    campaign: { type: Object, required: true }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetCampaignsTableEffectUnavailableCell {
  @include text-caption;
}
</style>

<i18n locale="ko">
{
  "inserted_url_not_found": "위젯이 삽입된 페이지를 찾을 수 없습니다.\n[관리-스크립트 복사]를 통해 해당 스크립트를 삽입해 주세요.",
  "not_configured": "설정을 완료하지 않은 캠페인입니다.\n우측 설정 버튼을 눌러 설정을 완료하면 발송을 시작합니다."
}
</i18n>
