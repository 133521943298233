<template>
  <div class="SlotPreview">
    <div class="SlotPreview__header">
      <div class="SlotPreview__title" v-text="$t('preview')" />
      <AppSelectButton
        v-model="previewDevice"
        class="SlotPreview__device"
        :options="WidgetDeviceType.options(previewDeviceOptions)"
      />
      <AppBubble v-if="isBubbleVisible" side="right" @click="hideBubble">{{
        $t('message')
      }}</AppBubble>
    </div>
    <div class="SlotPreview__preview">
      <div class="SlotPreview__iframes">
        <AppIframeViewer
          v-for="(url, index) in previewUrls"
          :key="index"
          class="SlotPreview__iframe"
          :src="url"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapMutations } from 'vuex';
import WidgetDeviceType from '@/enums/WidgetDeviceType';

export default {
  name: 'SlotPreview',
  data() {
    return {
      previewDevice: null,
      previewDeviceOptions: null
    };
  },
  computed: {
    ...mapState('installRequest', ['installDeviceTypes', 'bubbleVisibility']),
    ...mapGetters('installRequest', ['currentInstallations']),
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    isBubbleVisible() {
      return this.bubbleVisibility.preview;
    },
    previewUrls() {
      const urls = _.chain(this.currentInstallations)
        .filter(i => i.widget_device_type === this.previewDevice)
        .orderBy(['slot_type', 'position'], ['desc', 'asc'])
        .map(i => i.widget_shortcut_url)
        .filter(v => v)
        .value();

      return urls;
    }
  },
  mounted() {
    this.previewDevice = this.installDeviceTypes[0];
    if (this.installDeviceTypes && this.installDeviceTypes.length > 0)
      this.previewDeviceOptions = this.installDeviceTypes;
  },
  beforeDestroy() {
    if (this.bubbleVisibility.preview) this.SET_BUBBLE_INVISIBLE('preview');
  },
  methods: {
    ...mapMutations('installRequest', ['SET_BUBBLE_INVISIBLE']),
    hideBubble() {
      this.SET_BUBBLE_INVISIBLE('preview');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.SlotPreview {
  background-color: #f9f9fb;
  height: 100%;
  width: 100%;
  padding: 16px 22px 0 22px;
}

.SlotPreview__header {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
}

.SlotPreview__title {
  @include text-sub-title;
}

.SlotPreview__device {
  margin-left: 15px;
}

$header-height: 50px;
$preview-margin: 32px;
.SlotPreview__preview {
  margin: $preview-margin 0;
  background-color: $color-background;
  border-radius: 8px;
  height: calc(100% - $header-height - ($preview-margin * 2));
}

.SlotPreview__iframes {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
</style>

<i18n locale="ko">
{
  "preview": "위젯 미리보기",
  "message": "선택한 위젯이 어떻게 보여질지 미리 확인해보세요."
}
</i18n>
