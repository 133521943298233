<template>
  <AppModalForm
    :title="$t(editing ? 'title_edit' : 'title_new')"
    :form-props="{ ...formProps, objectId: 'smart_installation' }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="smart_installation[installation_type]"
      :value="installation_type"
    />
    <input
      type="hidden"
      name="smart_installation[id]"
      :value="newReviewLink.id"
    />
  </AppModalForm>
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';
import WidgetPageType from '@/enums/WidgetPageType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import SmartInstallationType from '@/enums/SmartInstallationType';
import api from '@/lib/api';
import _ from 'lodash';

export default {
  name: 'ReviewInstallSimulationNewReviewLinkFormDialog',
  mixins: [DialogFormView],
  props: {
    newReviewLink: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      installation_type: SmartInstallationType.NEW_REVIEW_LINK
    };
  },
  computed: {
    groupOptions() {
      return {
        device_type: {
          label: this.$t('device_type.label'),
          type: 'select_radio',
          options: WidgetDeviceType.options(),
          validate: ['required'],
          required: true
        },
        page_type: {
          label: this.$t('page_type.label'),
          type: 'select',
          placeholder: this.$t('page_type.placeholder'),
          options: this.widgetPageTypes(),
          validate: ['required'],
          required: true
        },
        page_path: {
          label: this.$t('page_path.label'),
          type: 'text',
          groupTooltip: this.$t('page_path.tooltip'),
          placeholder: this.$t('page_path.placeholder'),
          validate: [
            'required',
            {
              rule: 'check_path_or_url',
              errorMessage: this.$t('page_path.error')
            }
          ],
          required: true
        },
        element_selector: {
          label: this.$t('element_selector.label'),
          type: 'text',
          placeholder: this.$t('element_selector.placeholder'),
          validate: [
            'required',
            {
              rule: 'css_selector_format',
              errorMessage: this.$t('validations.selector_format')
            }
          ]
        }
      };
    },
    groupIds() {
      return this.formObject.page_type === WidgetPageType.SPECIFIC
        ? ['device_type', 'page_type', 'page_path', 'element_selector']
        : ['device_type', 'page_type', 'element_selector'];
    },
    formSections() {
      return [
        {
          id: 'default',
          groups: this.groupIds.map(id => {
            return {
              id,
              ...this.groupOptions[id]
            };
          })
        }
      ];
    },
    editing() {
      return this.formObject.id;
    }
  },
  mounted() {
    this.orgFormObject = _.cloneDeep(this.newReviewLink);
  },
  methods: {
    widgetPageTypes() {
      const widgetPageTypes = [
        WidgetPageType.MY_ORDERS,
        WidgetPageType.MY_REVIEWS,
        WidgetPageType.SPECIFIC
      ];
      return WidgetPageType.options(widgetPageTypes);
    },
    submit(formData) {
      if (this.editing) {
        api
          .patch(
            '/install_simulation/simulations/update_installation',
            formData,
            {
              successMessage: this.$t('app.saved')
            }
          )
          .then(({ data }) => {
            this.eventBus.$emit('update', data.smart_installation);
            this.close(true);
          });
      } else {
        api
          .post('/install_simulation/simulations', formData, {
            successMessage: this.$t('app.created')
          })
          .then(({ data }) => {
            this.eventBus.$emit('save', data.smart_installation);
            this.close(true);
          });
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_new": "작성 팝업 설치 추가",
  "title_edit": "작성 팝업 설치 설정",
  "device_type": {
    "label": "디바이스 선택"
  },
  "page_type": {
    "label": "설치 페이지",
    "placeholder": "페이지를 선택해주세요."
  },
  "element_selector": {
    "label": "설치 위치 입력",
    "placeholder": "설치 위치를 입력해주세요."
  },
  "page_path": {
    "label": "설치 페이지 URL",
    "placeholder": "쇼핑몰 주소를 제외한 URL을 입력해주세요.",
    "tooltip": "/board/review.html 와 같이 쇼핑몰 주소를 제외한 URL을 입력해주세요.\n예를들어 페이지 URL이 http://www.cre.ma/board/review.html라면\n/board/review.html 를 입력해야합니다.",
    "error": "잘못된 형식의 url입니다."
  }
}
</i18n>
