var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{staticClass:"TheSettingsDialogApiKey",attrs:{"is-loading":_vm.isLoading}},[_c('AppButton',{attrs:{"button-style":"red","label":_vm.$t('button.issue'),"disabled":!!_vm.apiKey,"tooltip":_vm.apiKey ? _vm.$t('tooltip.issue') : null},on:{"click":_vm.openApiKeyFormDialog}}),(_vm.isSuperAdminAccessible)?_c('AppButton',{staticClass:"super-admin__item",attrs:{"button-style":"red","label":_vm.$t('button.shop_builder_issue'),"disabled":!!_vm.shopBuilderApiKey,"tooltip":_vm.shopBuilderApiKey
        ? _vm.$t('tooltip.shop_builder_disabled')
        : _vm.$t('tooltip.shop_builder_issue')},on:{"click":_vm.openShopBuilderApiKeyFormDialog}}):_vm._e(),_c('AppResourceTable',{staticClass:"TheSettingsDialogApiKey__table",attrs:{"table-id":"api-key-setting-table","columns":_vm.columns,"rows":_vm.rows,"enable-total-count":false,"no-data":_vm.$t('no_data')},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
        var column = ref.column;
        var row = ref.row;
return [(column === 'updated_at')?[_vm._v(" "+_vm._s(row.updated_at)+" "),_c('AppSvgIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            _vm.$t('tooltip.updated_at', [row.updated_at, row.created_at])
          ),expression:"\n            $t('tooltip.updated_at', [row.updated_at, row.created_at])\n          "}],staticClass:"TheSettingsDialogApiKey__icon",attrs:{"name":"icon-info-tooltip"}})]:(column === 'manage')?_c('div',[_c('AppButton',{attrs:{"label":_vm.$t('button.checked')},on:{"click":function($event){return _vm.openApiKeyInfoDialog(row)}}}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('button.manage'),"menu-items":[
            [
              {
                label: _vm.$t('button.edit'),
                clickHandler: function () { return _vm.openApiKeyEditDialog(row); }
              },
              {
                label: _vm.$t('button.delete'),
                style: 'danger',
                clickHandler: function () { return _vm.confirmDeleteApiKey(row); }
              }
            ]
          ]}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }