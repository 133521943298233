<template>
  <div
    class="DisplayProductDisplayPreviewSettingsButton"
    @click="openPreviewSettingsDialog"
  >
    <AppSvgIcon
      class="DisplayProductDisplayPreviewSettingsButton__icon"
      name="icon-setting-small"
    /><span class="DisplayProductDisplayPreviewSettingsButton__text">{{
      $t('text')
    }}</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DisplayProductDisplayPreviewSettingsButton',
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openPreviewSettingsDialog() {
      this.openDialog(['DisplayProductDisplayPreviewSettingsDialog']);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayProductDisplayPreviewSettingsButton {
  cursor: pointer;
  width: 100px;
}

.DisplayProductDisplayPreviewSettingsButton__icon {
  vertical-align: middle;
}

.DisplayProductDisplayPreviewSettingsButton__text {
  @include text-button;

  margin-left: 6px;
  vertical-align: middle;
}
</style>

<i18n locale="ko">
{
  "text": "미리보기 설정"
}
</i18n>
