<template>
  <AppAjaxContent :is-loading="!data">
    <AppForm
      v-if="data"
      v-model="fit_settings"
      v-bind="fitFormProps"
      :event-bus="formEventBus"
      @submit="submit"
    >
      <input
        type="hidden"
        name="fit_settings[fit_message_review_option_type_id]"
        :value="fit_settings.fit_message_review_option_type_id"
      />
      <input
        type="hidden"
        name="fit_settings[fit_score_review_option_type_id]"
        :value="fit_settings.fit_score_review_option_type_id"
      />
      <input
        type="hidden"
        name="fit_settings[feedback_show_author_name]"
        :value="fit_settings.feedback_show_author_name"
      />
      <input
        type="hidden"
        name="fit_settings[feedback_emphasizing_color]"
        :value="fit_settings.feedback_emphasizing_color"
      />
      <template #group="{ id, errors }">
        <div
          v-if="id === 'use_custom_floating_widget'"
          class="AppForm__group-title"
          @click.capture="confirmCustomFloatingWidget($event)"
        >
          <AppCheckbox
            id="use_custom_floating_widget"
            v-model="fit_settings.use_custom_floating_widget"
            name="fit_settings[use_custom_floating_widget]"
            :label="
              $t(
                'settings.fit_settings_widgets.groups.use_custom_floating_widget'
              )
            "
            class="AppForm__group-title-checkbox"
          />
        </div>
        <div
          v-else-if="id === 'show_no_item_image_summary'"
          :class="[
            'AppForm__group-field',
            {
              'AppForm__group-field--disabled': !fit_settings.show_no_item_image_summary
            }
          ]"
        >
          <div class="AppForm__sub-group">
            <label
              class="AppForm__sub-group-title"
              for="fit_settings_no_item_image_url_summary_pc"
              >{{ $t('app.pc') }}</label
            >
            <AppTextInput
              id="fit_settings_no_item_image_url_summary_pc"
              v-model="fit_settings.no_item_image_url_summary_pc"
              name="fit_settings[no_item_image_url_summary_pc]"
              :disabled="!fit_settings.show_no_item_image_summary"
              :placeholder="$t('image_url_placeholder')"
              :invalid="!!errors.no_item_image_url_summary_pc"
              @blur="validateField('no_item_image_url_summary_pc')"
              @change="validateField('no_item_image_url_summary_pc')"
            />
            <AppFormError :error="errors.no_item_image_url_summary_pc" />
          </div>
          <div class="AppForm__sub-group">
            <label
              class="AppForm__sub-group-title"
              for="fit_settings_no_item_image_url_summary_mobile"
              >{{ $t('app.mobile') }}</label
            >
            <AppTextInput
              id="fit_settings_no_item_image_url_summary_mobile"
              v-model="fit_settings.no_item_image_url_summary_mobile"
              name="fit_settings[no_item_image_url_summary_mobile]"
              :disabled="!fit_settings.show_no_item_image_summary"
              :placeholder="$t('image_url_placeholder')"
              :invalid="!!errors.no_item_image_url_summary_mobile"
              @blur="validateField('no_item_image_url_summary_mobile')"
              @change="validateField('no_item_image_url_summary_mobile')"
            />
            <AppFormError :error="errors.no_item_image_url_summary_mobile" />
          </div>
        </div>
        <div
          v-else-if="id === 'show_no_item_image_detail'"
          :class="[
            'AppForm__group-field',
            {
              'AppForm__group-field--disabled': !fit_settings.show_no_item_image_detail
            }
          ]"
        >
          <div class="AppForm__sub-group">
            <label
              class="AppForm__sub-group-title"
              for="fit_settings_no_item_image_url_detail_pc"
              >{{ $t('app.pc') }}</label
            >
            <AppTextInput
              id="fit_settings_no_item_image_url_detail_pc"
              v-model="fit_settings.no_item_image_url_detail_pc"
              name="fit_settings[no_item_image_url_detail_pc]"
              :disabled="!fit_settings.show_no_item_image_detail"
              :placeholder="$t('image_url_placeholder')"
              :invalid="!!errors.no_item_image_url_detail_pc"
              @blur="validateField('no_item_image_url_detail_pc')"
              @change="validateField('no_item_image_url_detail_pc')"
            />
            <AppFormError :error="errors.no_item_image_url_detail_pc" />
          </div>
          <div class="AppForm__sub-group">
            <label
              class="AppForm__sub-group-title"
              for="fit_settings_no_item_image_url_detail_mobile"
              >{{ $t('app.mobile') }}</label
            >
            <AppTextInput
              id="fit_settings_no_item_image_url_detail_mobile"
              v-model="fit_settings.no_item_image_url_detail_mobile"
              name="fit_settings[no_item_image_url_detail_mobile]"
              :disabled="!fit_settings.show_no_item_image_detail"
              :placeholder="$t('image_url_placeholder')"
              :invalid="!!errors.no_item_image_url_detail_mobile"
              @blur="validateField('no_item_image_url_detail_mobile')"
              @change="validateField('no_item_image_url_detail_mobile')"
            />
            <AppFormError :error="errors.no_item_image_url_detail_mobile" />
          </div>
        </div>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import FitSettingsView from './FitSettingsView';

export default {
  name: 'FitSettingsWidgets',
  mixins: [FitSettingsView],
  data() {
    return {
      SETTINGS_ID: 'fit_settings_widgets',
      FIT_SETTINGS_URL: '/fit/settings/widgets',
      appFormEventBus: new Vue()
    };
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoryOptions']),
    groupOptions() {
      return {
        exclude_category_ids: {
          type: 'multiple_select',
          options: this.productCategoryOptions,
          placeholder: this.$t('exclude_category_ids_placeholder')
        },
        exclude_product_ids: {
          type: 'select_product',
          multiple: true,
          title: this.$t(
            'settings.fit_settings_widgets.groups.exclude_product_ids'
          ),
          products: this.fit_settings.exclude_products,
          eventHandlers: {
            'change-items': products =>
              this.$set(this.fit_settings, 'exclude_products', products)
          }
        },
        module_settings: {
          type: 'button',
          value: this.$t(
            'settings.fit_settings_widgets.groups.module_settings'
          ),
          clickHandler: this.openModuleSettingsDialog
        },
        show_fit_product_additional_info_toggle: {
          type: 'checkbox',
          description: this.$t(
            'show_fit_product_additional_info_toggle_description'
          )
        },
        size_summary_widget_style: {
          type: 'select_radio',
          options: [
            {
              label: this.$t('summary_widget_style.default'),
              value: 100
            },
            {
              label: this.$t('summary_widget_style.feedback'),
              value: 200
            }
          ]
        },
        show_no_item_image_summary: this.noItemActionTypeGroupOption('summary'),
        enable_module_summary: {
          type: 'hash_select_checkbox',
          options: [
            {
              id: 'enable_feedback_module_summary',
              label: this.$t('fit_size_module.feedback')
            },
            {
              id: 'enable_comparison_module_summary',
              label: this.$t('fit_size_module.comparison')
            },
            {
              id: 'enable_stats_module_summary',
              label: this.$t('fit_size_module.stats')
            },
            ...(this.fit_settings.enable_combined_widget
              ? [
                  {
                    id: 'enable_fit_product_module_summary',
                    label: this.$t('fit_size_module.fit_product')
                  }
                ]
              : [])
          ]
        },
        css_summary_pc: { type: 'code_editor', lang: 'css' },
        css_summary_mobile: { type: 'code_editor', lang: 'css' },
        javascript_summary_pc: { type: 'code_editor', lang: 'javascript' },
        javascript_summary_mobile: { type: 'code_editor', lang: 'javascript' },
        enable_module_detail: {
          type: 'hash_select_checkbox',
          options: [
            {
              id: 'enable_feedback_module_detail',
              label: this.$t('fit_size_module.feedback')
            },
            {
              id: 'enable_comparison_module_detail',
              label: this.$t('fit_size_module.comparison')
            },
            {
              id: 'enable_stats_module_detail',
              label: this.$t('fit_size_module.stats')
            },
            ...(this.fit_settings.enable_combined_widget
              ? [
                  {
                    id: 'enable_fit_product_module_detail',
                    label: this.$t('fit_size_module.fit_product')
                  }
                ]
              : [])
          ]
        },
        show_no_item_image_detail: this.noItemActionTypeGroupOption('detail'),
        pc_title_image: { type: 'image' },
        mobile_title_image: { type: 'image' },
        css_detail_pc: { type: 'code_editor', lang: 'css' },
        css_detail_mobile: { type: 'code_editor', lang: 'css' },
        javascript_detail_pc: { type: 'code_editor', lang: 'javascript' },
        javascript_detail_mobile: { type: 'code_editor', lang: 'javascript' },
        fit_product_pc_widget_css: { type: 'code_editor', lang: 'css' },
        fit_product_pc_widget_javascript: {
          type: 'code_editor',
          lang: 'javascript'
        },
        combined_css_summary_pc: {
          type: 'code_editor',
          lang: 'css'
        },
        combined_css_summary_mobile: {
          type: 'code_editor',
          lang: 'css'
        },
        combined_javascript_summary_pc: {
          type: 'code_editor',
          lang: 'javascript'
        },
        combined_javascript_summary_mobile: {
          type: 'code_editor',
          lang: 'javascript'
        },
        combined_css_detail_pc: {
          type: 'code_editor',
          lang: 'css'
        },
        combined_css_detail_mobile: {
          type: 'code_editor',
          lang: 'css'
        },
        combined_javascript_detail_pc: {
          type: 'code_editor',
          lang: 'javascript'
        },
        combined_javascript_detail_mobile: {
          type: 'code_editor',
          lang: 'javascript'
        },
        use_custom_floating_widget: {
          label: null
        },
        floating_settings: this.hideFloatingSettings
          ? { label: null }
          : {
              type: 'button',
              value: this.$t(
                'settings.fit_settings_widgets.groups.floating_settings'
              ),
              clickHandler: this.openFloatingSettingsDialog
            }
      };
    },
    hideFloatingSettings() {
      return !this.fit_settings.use_custom_floating_widget;
    }
  },
  methods: {
    ...mapActions('dialog', ['confirm', 'openDialog']),
    openModuleSettingsDialog() {
      this.openDialog([
        'FitModuleSettingsDialog',
        {
          orgFitSettings: this.fit_settings,
          reviewOptionTypes: this.data.review_option_types
        }
      ]).then(eventBus => {
        eventBus.$on('save', fitSettings => (this.fit_settings = fitSettings));
      });
    },
    openFloatingSettingsDialog() {
      this.openDialog(['FitFloatingStyleSettingsDialog']);
    },
    noItemActionTypeGroupOption(id) {
      const groupId = `show_no_item_image_${id}`;
      const checked = this.fit_settings[groupId];
      const fieldPc = `no_item_image_url_${id}_pc`;
      const fieldMobile = `no_item_image_url_${id}_mobile`;
      return {
        type: 'checkbox',
        label: this.$t('show_no_item_image'),
        fields: [
          {
            id: fieldPc,
            validate: checked
              ? ['required', 'url_format', { rule: 'check_image', async: true }]
              : []
          },
          {
            id: fieldMobile,
            validate: checked
              ? ['required', 'url_format', { rule: 'check_image', async: true }]
              : []
          }
        ],
        required: checked
      };
    },
    confirmCustomFloatingWidget(event) {
      if (this.fit_settings.use_custom_floating_widget) return;

      event.preventDefault();
      this.confirm({
        title: this.$t('custom_floating_widget_confirm.title'),
        message: this.$t('custom_floating_widget_confirm.message'),
        closeButtonLabel: this.$t('custom_floating_widget_confirm.confirmed')
      }).then(result => {
        if (!result) return;

        this.fit_settings.use_custom_floating_widget = true;
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "summary_widget_style": {
    "default": "기본 버튼",
    "feedback": "사이즈 피드백 정보 표시"
  },
  "show_no_item_image": "위젯이 표시되지 않을 때 대체 이미지 표시",
  "exclude_category_ids_placeholder": "카테고리를 선택해주세요.",
  "image_url_placeholder": "이미지 URL을 입력해주세요.",
  "fit_size_module": {
    "feedback": "사이즈 피드백 모듈",
    "comparison": "사이즈 비교 모듈",
    "stats": "사이즈 추천 모듈",
    "fit_product": "실측 사이즈 모듈"
  },
  "show_fit_product_additional_info_toggle_description": "모바일 실측 사이즈 모듈의 상세정보 더보기 버튼을 펼쳐진 상태로 표시합니다.",
  "custom_floating_widget_confirm": {
    "title": "플로팅 위젯 커스텀 기능을 사용하시겠습니까?",
    "message": "기능 사용 시 '통합 요약 위젯/플로팅 위젯 (PC 또는 모바일) CSS' 설정에 플로팅 위젯의 CSS가 적용되지 않습니다. 사용하시겠습니까?",
    "confirmed": "진행"
  }
}
</i18n>
