<template>
  <AppTabContent menu-item-id="analytics_insight_products" :params="tabParams">
    <RouterView />
  </AppTabContent>
</template>

<script>
import _ from 'lodash';
import { getParamsFromPath } from '@/lib/params';

export default {
  name: 'AnalyticsInsightProducts',
  computed: {
    tabParams() {
      return _.omit(
        getParamsFromPath(this.$route.path),
        'page',
        'min_reviews_count',
        'max_reviews_count',
        'start_date',
        'end_date',
        'order_type',
        'order_column'
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_breakpoints.scss';
@import '@/scss/vars/_z-indexes.scss';
@import '@/scss/vars/_colors.scss';

$normal-cell-width: 92px;
$code-cell-width: 80px;

::v-deep {
  .AppResourceTableCell {
    flex: 0 0 $normal-cell-width;
  }

  .analytics-insight-products__code {
    flex: 0 0 $code-cell-width;
  }

  @include media-breakpoint-each(tablet, desktop) {
    .analytics-insight-products__code {
      left: 0;
      position: sticky;
      z-index: #{$z-index-sticky - 1};
      background-color: white;
    }

    .analytics-insight-products__name {
      left: $code-cell-width;
      position: sticky;
      z-index: #{$z-index-sticky - 1};
      background-color: white;
      border-right: 1px solid $color-layout-section-dark;
    }
  }
}
</style>
