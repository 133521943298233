<template>
  <AppModalForm
    :title="$t('title', [$t(`${target}`), $t(`${descriptionType}`)])"
    :form-props="{ ...formProps }"
    v-on="formEvents"
  />
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'FitDescriptionsTextEditDialog',
  mixins: [DialogFormView],
  props: {
    target: {
      type: String,
      required: true
    },
    descriptionType: {
      type: String,
      required: true
    }
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'content',
              label: this.$t('group_title', [this.$t(this.descriptionType)]),
              type: 'textarea',
              groupDescription: this.$t('group_description', [
                this.$t(this.target),
                this.$t(this.descriptionType)
              ]),
              rows: 6
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    submit() {
      this.openEditConfirm();
    },
    openEditConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t('edit_confirm_title', [
            this.$t(this.target),
            this.$t(this.descriptionType)
          ]),
          closeButtonLabel: this.$t('app.save'),
          markdownText: this.$t('edit_confirm_description', [
            this.$t(this.target),
            this.$t(this.descriptionType)
          ]),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => {
          this.isSubmitting = true;
          this.editDescriptions();
        });
      });
    },
    editDescriptions() {
      const { content } = this.formObject;
      api
        .patch('/fit/settings/update_descriptions', {
          description_type: this.descriptionType,
          target: this.target,
          content: content
        })
        .finally(() => {
          this.close(true);
          this.createToast(this.$t('edited'));
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

::v-deep .AppForm__group-description {
  color: $color-grey-60;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 8px;
}
</style>

<i18n locale="ko">
{
  "title": "{0} {1} 문구 수정",
  "group_title": "{0} 문구 일괄 수정",
  "group_description": "모든 {0}에 등록된 {1} 문구를 수정합니다.",
  "template": "템플릿",
  "fit_product": "상품",
  "size": "실측 치수 안내",
  "etc": "기타 안내 사항",
  "edit_confirm_title": "모든 {0}에 등록된 {1} 문구를 수정하시겠습니까?",
  "edit_confirm_description": "<span style='color: #616163'>모든 {0}에 등록된 {1} 문구가 수정됩니다. <br> 이 작업은 되돌릴 수 없습니다. 계속하시겠습니까?</span>",
  "edited": "수정되었습니다."
}
</i18n>
