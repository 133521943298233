var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{style:(_vm.contentStyle),attrs:{"is-loading":_vm.isLoading}},[_c('DisplayProductManagementShowHead',{attrs:{"is-form-valid":_vm.isFormValid},on:{"dispatch-now":_vm.dispatchNow,"save":_vm.save}}),_c('AppInfoBox',{staticClass:"DisplayProductManagementShow__info-box"},[_vm._v(_vm._s(_vm.information))]),_c('AppForm',_vm._g(_vm._b({staticClass:"DisplayProductManagementShow__form",attrs:{"object-id":"product_management","form-style":"hor","submit-button":""},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
var errors = ref.errors;
return [(id === 'execute_weekdays_value')?[_c('i18n',{attrs:{"path":"section.execute_time.groups.execute_weekdays_value.weekdays"}},[_c('AppWeekdaySelectButton',{staticClass:"AppForm__group-field DisplayProductManagementShow__execute-weekdays-value",attrs:{"name":"product_management[execute_weekdays_value]","invalid":!!errors.execute_weekdays_value},on:{"change":function($event){return _vm.validateField('execute_weekdays_value')}},model:{value:(_vm.formObject.execute_weekdays_value),callback:function ($$v) {_vm.$set(_vm.formObject, "execute_weekdays_value", $$v)},expression:"formObject.execute_weekdays_value"}}),_c('AppFormError',{attrs:{"error":errors.execute_weekdays_value}})],1)]:(id === 'category_filter_type')?[_c('AppSelectRadio',{attrs:{"name":"product_management[category_filter_type]","options":_vm.DisplayCategoryFilterType.options([
              _vm.DisplayCategoryFilterType.ALL,
              _vm.DisplayCategoryFilterType.INCLUDE
            ])},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
            var value = ref.value;
            var checked = ref.checked;
return [(value === _vm.DisplayCategoryFilterType.INCLUDE)?[_c('AppSelectProductCategory',{staticClass:"DisplayProductManagementShow__categories",attrs:{"name":"product_management[including_category_ids][]","disabled":!checked,"invalid":!!errors.including_category_ids,"multiple":"","showable-category-ids":_vm.displaySettings.enabled_category_ids},on:{"blur":function($event){return _vm.validateField('including_category_ids')},"change":function($event){return _vm.validateField('including_category_ids')}},model:{value:(_vm.formObject.including_category_ids),callback:function ($$v) {_vm.$set(_vm.formObject, "including_category_ids", $$v)},expression:"formObject.including_category_ids"}}),_c('AppFormError',{attrs:{"error":errors.including_category_ids}})]:_vm._e()]}}],null,true),model:{value:(_vm.formObject.category_filter_type),callback:function ($$v) {_vm.$set(_vm.formObject, "category_filter_type", $$v)},expression:"formObject.category_filter_type"}}),_c('AppCheckbox',{staticClass:"AppForm__group-field",attrs:{"label":_vm.$t(
              'section.filter_products.groups.use_excluding_category_ids.label'
            ),"name":"product_management[use_excluding_category_ids]"},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
            var checked = ref.checked;
return [_c('AppSelectProductCategory',{staticClass:"DisplayProductManagementShow__categories",attrs:{"name":"product_management[excluding_category_ids][]","disabled":!checked,"invalid":!!errors.excluding_category_ids,"multiple":"","showable-category-ids":_vm.displaySettings.enabled_category_ids},on:{"blur":function($event){return _vm.validateField('excluding_category_ids')},"change":function($event){return _vm.validateField('excluding_category_ids')}},model:{value:(_vm.formObject.excluding_category_ids),callback:function ($$v) {_vm.$set(_vm.formObject, "excluding_category_ids", $$v)},expression:"formObject.excluding_category_ids"}}),_c('AppFormHint',{attrs:{"message":_vm.$t(
                  'section.filter_products.groups.use_excluding_category_ids.description'
                ),"disabled":!checked}}),_c('AppFormError',{attrs:{"error":errors.excluding_category_ids}})]}}],null,true),model:{value:(_vm.formObject.use_excluding_category_ids),callback:function ($$v) {_vm.$set(_vm.formObject, "use_excluding_category_ids", $$v)},expression:"formObject.use_excluding_category_ids"}})]:(id === 'product_management_units')?[_c('DisplayProductManagementUnits',{staticClass:"DisplayProductManagementShow__units",attrs:{"use-display-option":_vm.useDisplayOption,"product-option-types":_vm.productOptionTypes,"errors":errors},on:{"validate-unit-field":_vm.validateUnitField}})]:(id === 'least_options_count')?[_c('i18n',{attrs:{"path":"section.filter_products.groups.least_options_count.description1"}},[_c('AppNumberInput',{staticClass:"AppForm__group-field app-input-inline app-input-inline--left",attrs:{"name":"product_management[least_options_count]","digits":3,"invalid":!!errors.least_options_count},on:{"blur":function($event){return _vm.validateField('least_options_count')},"change":function($event){return _vm.validateField('least_options_count')}},model:{value:(_vm.formObject.least_options_count),callback:function ($$v) {_vm.$set(_vm.formObject, "least_options_count", $$v)},expression:"formObject.least_options_count"}})],1)]:(id === 'managed_items_table')?[_c('DisplayProductManagementManagedItemsTable',{attrs:{"managed-items-data":_vm.formObject},on:{"update-product-management":_vm.updateManagedItem}})]:_vm._e()]}}])},'AppForm',_vm.formProps,false),_vm.formEvents))],1)}
var staticRenderFns = []

export { render, staticRenderFns }