import { render, staticRenderFns } from "./AppDropdownMenuButton.vue?vue&type=template&id=8ba5b56c&scoped=true&"
import script from "./AppDropdownMenuButton.vue?vue&type=script&lang=js&"
export * from "./AppDropdownMenuButton.vue?vue&type=script&lang=js&"
import style0 from "./AppDropdownMenuButton.vue?vue&type=style&index=0&id=8ba5b56c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ba5b56c",
  null
  
)

export default component.exports