<template>
  <div class="ReviewHomeAdminOnboarding">
    <AppAjaxContent :is-loading="isLoading">
      <template>
        <AppForm
          object-id="admin_settings"
          v-bind="formProps"
          submit-button
          v-on="formEvents"
        />
      </template>
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import { mapActions, mapGetters } from 'vuex';
import FormView from '@/mixins/FormView';
import BrandReviewStatus from '@/enums/BrandReviewStatus';
import WidgetInstallStatus from '@/enums/WidgetInstallStatus';
import ReviewCsvSyncStatus from '@/enums/ReviewCsvSyncStatus';

export default {
  name: 'ReviewHomeAdminOnboarding',
  mixins: [FormView],
  data() {
    return { isLoading: true };
  },
  computed: {
    ...mapGetters('session', ['isGodoBrand']),
    formSections() {
      return [
        {
          groups: [
            { id: 'manager_info_submitted', type: 'checkbox' },
            { id: 'shop_info_completed', type: 'checkbox' },
            { id: 'request_installation_completed', type: 'checkbox' },
            { id: 'mileage_completed', type: 'checkbox' },
            this.isGodoBrand
              ? { id: 'godo_csv_completed', type: 'checkbox' }
              : null,
            {
              id: 'widget_install_status',
              type: 'select',
              options: WidgetInstallStatus.options()
            },
            ...(this.isGodoBrand
              ? [
                  {
                    id: 'review_csv_sync_status',
                    type: 'select',
                    options: ReviewCsvSyncStatus.options()
                  },
                  {
                    id: 'godo_reviews_count_updated',
                    label: this.$t('godo_reviews_count_updated.label'),
                    type: 'select',
                    options: [false, true].map(value => ({
                      label: this.$t(
                        `godo_reviews_count_updated_options.${value}`
                      ),
                      value
                    }))
                  }
                ]
              : []),
            {
              id: 'review_status',
              type: 'select',
              options: BrandReviewStatus.options()
            }
          ]
            .filter(v => Boolean(v))
            .map(e => ({ ...e, label: this.$t(e.id) }))
        }
      ];
    }
  },
  mounted() {
    this.fetchAdminSettings();
  },
  methods: {
    ...mapActions('easyJoin', ['updateOnboardingProgress']),
    fetchAdminSettings() {
      this.isLoading = true;
      return api
        .get('/easy_join/admin_settings')
        .then(({ data }) => (this.orgFormObject = data.admin_settings))
        .finally(() => (this.isLoading = false));
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/easy_join/admin_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.updateOnboardingProgress({ ...this.formObject });
          this.orgFormObject = data.admin_settings;
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "manager_info_submitted": "담당자 정보 입력 단계 완료",
  "shop_info_completed": "쇼핑몰 정보 확인 아코디언 완료",
  "request_installation_completed": "위젯 설치 요청 아코디언 완료",
  "mileage_completed": "적립금 설정 아코디언 완료",
  "godo_csv_completed": "리뷰 데이터 동기화 아코디언 완료",
  "widget_install_status": "위젯 설치 및 테스트 체험 상태",
  "review_status": "리뷰 상태",
  "review_csv_sync_status": "리뷰 CSV 등록 상태",
  "godo_reviews_count_updated": "크리마리뷰 상품평 개수 업데이트 등록 상태",
  "godo_reviews_count_updated_options": {
    "false": "진행 전",
    "true": "완료"
  }
}
</i18n>
