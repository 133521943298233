<template>
  <div
    :class="[
      'AppResourceTable',
      tableId,
      tableStyle ? `AppResourceTable--${tableStyle}` : null,
      { 'AppResourceTable--in-layer': isInLayer }
    ]"
  >
    <div class="AppResourceTable__table">
      <div ref="stickyHead" class="AppResourceTable__head-sticky">
        <AppResourceTableSearchbar
          v-bind="{
            tableName,
            tableDescription,
            enableRefresh,
            enableColumnSettings,
            enableQuery,
            customButtons,
            batchButtons,
            toggleButton,
            tableColumns,
            selectedRowIdsSet,
            totalCount: enableTotalCount
              ? resources
                ? resources.total_count
                : rows.length
              : null,
            isInLayer,
            filterOptions,
            filterValues
          }"
          @cancel-selection="selectedRowIdsSet = new Set()"
          v-on="$listeners"
        >
          <template #bubble>
            <slot name="searchbar-bubble" />
          </template>
          <template #additional-searchbar>
            <slot name="additional-table-searchbar" />
          </template>
        </AppResourceTableSearchbar>
        <div
          ref="headScroller"
          class="AppResourceTable__head-scroller"
          data-vertically-scrollable="no"
        >
          <div ref="head" class="AppResourceTable__head">
            <div v-if="mainColumns.length" class="AppResourceTable__head-row">
              <AppResourceTableCell
                v-for="column in mainColumns"
                :key="column.id"
                cell-type="head"
                class="AppResourceTable__cell"
                :colspan="column.colspan"
                :style="{ flexBasis: flexBasis(column.colspan || 1) }"
                :column="column"
                :cell-style="column.columnStyle"
                :value="column.label"
              />
            </div>
            <div
              ref="finalHeadRow"
              :class="[
                'AppResourceTable__head-row',
                { 'AppResourceTable__head-row--sub': mainColumns.length }
              ]"
            >
              <AppResourceTableCell
                v-for="column in visibleColumns"
                :key="column.columnId"
                v-bind="{ sortable: enableColumnSorting }"
                cell-type="head"
                :class="[
                  'AppResourceTable__cell',
                  tableId ? column.columnId : null,
                  tableId ? `${column.columnId}--head` : null,
                  column.superAdmin ? 'super-admin__item' : null
                ]"
                :style="column.style"
                :column="column"
                :cell-style="column.columnStyle"
              >
                <slot name="head-cell" :column="column.id">
                  <AppCheckboxPartial
                    v-if="column.type === 'row_select'"
                    :check-status="
                      selectedRows.length
                        ? rows.length === selectedRows.length
                          ? 'checked'
                          : 'partial'
                        : 'unchecked'
                    "
                    @change="selectRows($event === 'checked')"
                  />
                  <div v-else-if="column.tooltip" v-tooltip="column.tooltip">
                    <span class="AppResourceTable__head-col-label">{{
                      column.label
                    }}</span>
                    <AppSvgIcon
                      name="icon-info-tooltip"
                      class="AppResourceTable__head-col-icon"
                    />
                  </div>
                  <div
                    v-else-if="enableColumnSorting && column.sortable"
                    class="AppResourceTable__sortable-head-col"
                    @click="emitSort(column)"
                  >
                    <span class="AppResourceTable__head-col-label">{{
                      column.label
                    }}</span>
                    <AppSvgIcon
                      :name="`icon-column-sort-${column.sorted}`"
                      class="AppResourceTable__head-col-icon"
                    />
                  </div>
                  <div v-else>{{ column.label }}</div>
                </slot>
              </AppResourceTableCell>
            </div>
          </div>
        </div>
      </div>

      <div
        ref="bodyScroller"
        class="AppResourceTable__body-scroller"
        data-vertically-scrollable="no"
      >
        <div ref="body" class="AppResourceTable__body js-draggable">
          <div
            v-for="(row, rowIndex) in tableRows"
            :key="row.id"
            :class="[
              'AppResourceTable__body-row',
              row.rowStyle
                ? `AppResourceTable__body-row--style-${row.rowStyle}`
                : null,
              row.superAdmin ? 'super-admin__item' : null
            ]"
          >
            <AppResourceTableCell
              v-for="(column, colIndex) in visibleColumns"
              :key="colIndex"
              cell-type="body"
              :class="[
                'AppResourceTable__cell',
                tableId ? column.columnId : null,
                tableId ? `${column.columnId}--body` : null,
                tableStyle === 'depth' && column.depth === row.depth
                  ? 'AppResourceTable__cell--bold'
                  : null
              ]"
              :style="column.style"
              :column="column"
              :cell-class="row[`${column.id}_class`]"
              :cell-style="column.columnStyle"
              :row="row"
              :value="
                column.type === 'row_select'
                  ? selectedRowIdsSet.has(row.id)
                  : row[column.id]
              "
              @check="selectRow(row.id, $event)"
              @click-link-icon="column.linkIcon.handler(column.id, row)"
            >
              <slot
                name="cell"
                :value="row[column.id]"
                :column="column.id"
                :row="row"
                :row-index="rowIndex"
              />
            </AppResourceTableCell>
          </div>
          <AppNoData
            v-if="rows.length === 0"
            class="AppResourceTable__no-data"
            :message="noData"
          />
        </div>
      </div>
    </div>

    <AppResourceTablePagination
      v-if="resources && rows.length > 0"
      :total-count="resources.total_count"
      :per="resources.per"
      :current-page="resources.page"
      :enable-per="enablePer"
      :enable-summary="!isInLayer"
      :enable-route="!isInLayer"
      :pers="pers"
      @paginate="paginate"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';

import Focusable from '@/mixins/Focusable';

export default {
  name: 'AppResourceTable',
  mixins: [Focusable],
  props: {
    tableId: { type: String, default: null },
    tableName: { type: String, default: null },
    tableDescription: { type: String, default: null },
    resources: { type: Object, default: null },
    mainColumns: { type: Array, default: () => [] },
    columns: { type: Array, required: true },
    rows: { type: Array, required: true },
    noData: {
      type: String,
      default() {
        return this.$t('app.no_data');
      }
    },
    enableQuery: { type: Boolean, default: false },
    enableRefresh: { type: Boolean, default: false },
    enableColumnSettings: { type: Boolean, default: false },
    enableColumnSorting: { type: Boolean, default: false },
    enableTotalCount: { type: Boolean, default: true },
    enablePer: { type: Boolean, default: true },
    customButtons: {
      type: Array,
      default() {
        return [];
      }
    },
    batchButtons: {
      type: Array,
      default() {
        return [];
      }
    },
    toggleButton: {
      type: Object,
      default: null
    },
    tableStyle: { type: String, default: null },
    eventBus: { type: Object, default: null },
    pers: { type: Array, default: undefined },
    selectedRowIds: { type: Array, default: null },
    filterOptions: { type: Array, default: () => [] },
    filterValues: { type: Array, default: () => [] }
  },
  data() {
    return {
      isInLayer: false,
      selectedRowIdsObj: { set: new Set(this.selectedRowIds) }
    };
  },
  computed: {
    ...mapState('editor', ['columnVisibleMap']),
    ...mapGetters(['isSuperAdminAccessible']),
    tableColumns() {
      const columnIdPrefix = this.tableId ? `${this.tableId}__` : '';
      return this.columns.map(column => {
        const columnId = `${columnIdPrefix}${_.kebabCase(column.id)}`;
        let visible = true;
        if (!column.required) {
          if (columnId in this.columnVisibleMap)
            visible = this.columnVisibleMap[columnId];
          else if (column.hideByDefault) visible = false;
        }

        if (!this.isSuperAdminAccessible && column.superAdmin) visible = false;

        return { ...column, columnId, visible };
      });
    },
    tableRows() {
      let { rows } = this;

      if (this.tableStyle === 'depth')
        rows = rows.map(row => ({
          ...row,
          rowStyle: row.depth === 1 ? 'depth-main' : 'depth-sub'
        }));

      const columnTypes = this.columns.map(({ type }) => type);
      if (
        columnTypes.includes('position') ||
        columnTypes.includes('positionDesc')
      ) {
        const { total_count, page, per } = this.resources;
        return rows.map((row, i) => ({
          ...row,
          position: total_count - (page - 1) * per - i
        }));
      } else if (columnTypes.includes('positionAsc')) {
        const { page, per } = this.resources;
        return rows.map((row, i) => ({
          ...row,
          position: (page - 1) * per + i + 1
        }));
      } else return rows;
    },
    visibleColumns() {
      return this.tableColumns
        .filter(c => c.visible)
        .map(column => ({
          ...column,
          columnStyle:
            column.columnStyle ||
            (column.type === 'row_select'
              ? 'row-select'
              : column.type === 'position'
              ? 'position'
              : null),
          style: column.width
            ? { flexBasis: column.width, flexGrow: 0, flexShrink: 0 }
            : {},
          label:
            column.label ||
            (column.type === 'position' ? this.$t('app.position') : null)
        }));
    },
    selectedRowIdsSet: {
      get() {
        return this.selectedRowIdsObj.set;
      },
      set(set) {
        this.selectedRowIdsObj = { set };
      }
    },
    selectedRows() {
      return this.rows.filter(r => this.selectedRowIdsSet.has(r.id));
    }
  },
  watch: {
    visibleColumns() {
      this.$nextTick(this.recalcTableMinWidth);
    },
    rows() {
      if (this.selectedRowIds) return;

      const ids = this.rows
        .map(r => r.id)
        .filter(id => this.selectedRowIdsSet.has(id));
      this.selectedRowIdsSet = new Set(ids);
    },
    selectedRows() {
      this.$emit('select-rows', this.selectedRows);
    }
  },
  mounted() {
    this.isInLayer =
      !!this.$el.closest('.AppModal') || !!this.$el.closest('.AppDrawer');

    this.$nextTick(this.recalcTableMinWidth);
    this.onResizeEnd = _.debounce(this.recalcTableMinWidth, 200);
    window.addEventListener('resize', this.onResizeEnd);

    const { headScroller, bodyScroller } = this.$refs;
    headScroller.addEventListener(
      'scroll',
      () => (bodyScroller.scrollLeft = headScroller.scrollLeft)
    );
    bodyScroller.addEventListener(
      'scroll',
      () => (headScroller.scrollLeft = bodyScroller.scrollLeft)
    );

    if (this.eventBus) {
      this.eventBus.$on('focus-row', this.focusRow);
      this.eventBus.$on('select-rows', this.selectRows);
      this.eventBus.$on('highlight-rows', this.highlightRows);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResizeEnd);
  },
  methods: {
    paginate(pagination) {
      this.scrollToTop(this.$el);
      this.$emit('paginate', pagination);
    },
    selectRow(id, selected) {
      const set = this.selectedRowIdsSet;
      selected ? set.add(id) : set.delete(id);
      this.selectedRowIdsSet = set;
    },
    selectRows(selected) {
      const set = this.selectedRowIdsSet;
      this.rows
        .filter(({ disabled }) => !disabled)
        .forEach(({ id }) => (selected ? set.add(id) : set.delete(id)));
      this.selectedRowIdsSet = set;
    },
    recalcTableMinWidth() {
      const DESKTOP_PADDING_HORIZONTAL = this.isInLayer ? 0 : 32;
      const { head, body, finalHeadRow } = this.$refs;
      finalHeadRow.style.position = 'absolute';
      const cols = finalHeadRow.querySelectorAll('.AppResourceTable__cell');
      const width = _.chain(cols)
        .map(e => e.offsetWidth)
        .sum()
        .value();

      finalHeadRow.style.position = '';
      head.style.minWidth = `${width + DESKTOP_PADDING_HORIZONTAL * 2}px`;
      body.style.minWidth = `${width + DESKTOP_PADDING_HORIZONTAL * 2}px`;
    },
    flexBasis(colspan) {
      const cols = this.visibleColumns.length;
      return cols === 0 ? 0 : `${(100 / cols) * colspan}%`;
    },
    focusRow(rowIndex) {
      const rowEl = this.$refs.body.childNodes[rowIndex];
      if (!rowEl) return;

      this.focusOnElement(rowEl, {
        scrollOffsetTop: -this.$refs.stickyHead.offsetHeight
      });
    },
    highlightRows(rowIndexes) {
      rowIndexes.forEach(rowIndex => {
        const rowEl = this.$refs.body.childNodes[rowIndex];
        if (!rowEl) return;

        this.highlightElement(rowEl);
      });
    },
    emitSort(column) {
      let orderType;
      if (column.sorted === 'desc') orderType = 'asc';
      else orderType = 'desc';

      this.scrollToTop(this.$el);
      this.$emit('sort', { order_type: orderType, order_column: column.id });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_layouts.scss';
@import '@/scss/vars/_z-indexes.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_transitions.scss';
@import '@/scss/mixins/_breakpoints.scss';

$row-height-default: 50px;
$row-height-stats: 36px;
$row-height-depth: 36px;
$row-height-wide: 72px;

.AppResourceTable {
  &--stats {
    .AppResourceTable__head-row--sub {
      min-height: $row-height-stats;
    }

    .AppResourceTable__body-row {
      min-height: $row-height-stats;
    }

    .AppResourceTable__body-row:last-child {
      border-bottom: 1px solid $color-layout-section-dark;
    }

    .AppResourceTable__cell {
      border-left: 1px solid;
      border-color: transparent;

      &:first-child {
        text-align: left;
      }

      & + .AppResourceTable__cell {
        border-color: $color-layout-section;
      }
    }
  }

  &--depth {
    .AppResourceTable__head-row--sub {
      min-height: $row-height-depth;
    }

    .AppResourceTable__body-row {
      min-height: $row-height-depth;
    }

    .AppResourceTable__body-row:last-child {
      border-bottom: 1px solid $color-layout-section-dark;
    }

    .AppResourceTable__cell {
      &--bold {
        font-weight: bold;
      }
    }
  }

  &--wide {
    .AppResourceTable__body-row {
      min-height: $row-height-wide;
    }
  }
}

.AppResourceTable__table {
  background-color: white;
  text-align: center;
}

.AppResourceTable__head-sticky {
  position: sticky;
  z-index: $z-index-sticky;
  top: 0px;
}

.AppResourceTable__head-scroller,
.AppResourceTable__body-scroller {
  overflow-x: auto;
  @include main-negative-margin-horizontal;

  .AppResourceTable--in-layer & {
    margin-left: 0;
    margin-right: 0;
  }
}

.AppResourceTable__head-scroller::-webkit-scrollbar {
  display: none;
}

.AppResourceTable__head {
  background: white;
}

.AppResourceTable__head,
.AppResourceTable__body {
  @include main-padding-horizontal;

  .AppResourceTable--in-layer & {
    padding-left: 0;
    padding-right: 0;
  }
}

.AppResourceTable__head-row,
.AppResourceTable__body-row {
  border-bottom: 1px solid $color-layout-section;
  display: flex;
  min-height: $row-height-default;
  box-sizing: content-box;
}

.AppResourceTable__head-row {
  @include text-table-head;

  &:first-child {
    border-top: 1px solid $color-layout-section-dark;
  }

  &:last-child {
    border-bottom-color: $color-layout-section-dark;
  }

  &--sub {
    @include text-table-sub-head;
  }
}

.AppResourceTable__body-row {
  @include transition-basic(background-color);
  align-items: stretch;
  position: relative;

  @include text-content;

  &:hover {
    background-color: $color-grey-05;
  }

  &--style-stats-total-detail,
  &--style-stats-detail {
    @include text-caption;
  }

  &--style-stats-total,
  &--style-stats-total-summary,
  &--style-stats-total-detail {
    font-weight: bold;
    background-color: $color-grey-05;

    &:hover {
      background-color: $color-grey-15;
    }
  }

  &--style-stats-total,
  &--style-stats-total-summary,
  &--style-stats-total-detail,
  &--style-stats-summary,
  &--style-stats-detail {
    border-bottom: 0;
  }

  &--style-stats-summary {
    border-top: 1px solid $color-layout-section;
  }

  &--style-depth-main {
    border-bottom: 0;
    border-top: 1px solid $color-layout-section;
  }

  &--style-depth-sub {
    @include text-caption;

    border-bottom: 0;
  }
}

.AppResourceTable__no-data {
  border-bottom: 1px solid $color-layout-section;
}

.AppResourceTable__cell {
  padding: 8px;
  box-sizing: border-box;
}

.AppResourceTable__head-col-label {
  vertical-align: middle;
}

.AppResourceTable__head-col-icon {
  vertical-align: middle;
  margin-left: 4px;
}

.AppResourceTable__sortable-head-col {
  cursor: pointer;
}
</style>
