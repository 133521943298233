import Vue from 'vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import api from '@/lib/api';

const REQUEST_ID = 'FETCH_WIDGETS';

export default {
  data() {
    return { isLoading: true, tableEventBus: null };
  },
  created() {
    this.tableEventBus = new Vue();
    this.SET_REVIEW_WIDGETS([]);
  },
  beforeDestroy() {
    api.cancel(REQUEST_ID);
  },
  mounted() {
    api
      .get(this.WIDGETS_URL, { requestId: REQUEST_ID })
      .then(({ data }) => this.SET_REVIEW_WIDGETS(data.widgets))
      .finally(() => {
        this.isLoading = false;
        this.$nextTick(this.processWidgetQueryParams);
      });
  },
  methods: {
    ...mapMutations('reviewWidgets', [
      'SET_REVIEW_WIDGETS',
      'CREATE_REVIEW_WIDGET',
      'UPDATE_REVIEW_WIDGET',
      'DELETE_REVIEW_WIDGET'
    ]),
    processWidgetQueryParams() {
      const { widget_id, widget_style, widget_device_type } = this.$route.query;
      if (!(widget_id || widget_style || widget_device_type)) return;

      const parsedWidgetId = Number(widget_id);
      const widgetIndex = this.reviewWidgets.findIndex(
        widget => widget.id === parsedWidgetId
      );
      if (widgetIndex >= 0) {
        this.tableEventBus.$emit('focus-row', widgetIndex);
        return;
      }

      this.openWidgetSettingsUsingQueryParams(widget_style, widget_device_type);
    },
    focusOnRow() {
      this.$nextTick(() =>
        this.tableEventBus.$emit('focus-row', this.reviewWidgets.length - 1)
      );
    },
    openWidgetSettingsUsingQueryParams(widget_style, widget_device_type) {
      if (!this.isShopifyBrand) return;

      const parsedWidgetStyle = Number(widget_style);
      const parsedWidgetDeviceType = Number(widget_device_type);
      const widgetIndex = this.reviewWidgets.findIndex(
        w =>
          w.widget_style === parsedWidgetStyle &&
          w.widget_device_type === parsedWidgetDeviceType
      );
      if (widgetIndex >= 0) {
        this.widgetSettingsClicked(widgetIndex);
      }
    },
    deleteWidget(widgetId, rowIndex) {
      if (!confirm(this.$t('app.delete_confirm'))) return;
      api
        .delete(`/widgets/${widgetId}`)
        .then(() => this.DELETE_REVIEW_WIDGET(rowIndex));
    }
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapState('reviewWidgets', ['reviewWidgets'])
  }
};
