<template>
  <div class="FitReportsSizeFeedback">
    <AppReportsSearchbar
      v-bind="searchBarProps"
      :enable-advanced-search="true"
      :resource-params="resourceParams"
      :products="products"
      v-on="searchBarEvents"
      @submit="searchResource"
    />
    <AppChart
      :options="chartOptions"
      :tooltip="chartTooltip"
      @tooltip="onChartTooltip"
    />
    <AppResourceTable
      :rows="rows"
      table-style="stats"
      :columns="COLUMNS"
      :resources="resources"
      :enable-total-count="false"
      @paginate="paginate"
    />
  </div>
</template>

<script>
import ReportsView from '@/mixins/ReportsView';

export default {
  name: 'FitReportsSizeFeedbacks',
  mixins: [ReportsView],
  props: {
    products: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'date', label: this.$t('app.date'), type: 'pre' },
        {
          id: 'scoresCount',
          label: this.$t('scores_count'),
          type: 'number',
          default: '-',
          align: 'right'
        },
        {
          id: 'messagesCount',
          label: this.$t('messages_count'),
          type: 'number',
          default: '-',
          align: 'right'
        },
        {
          id: 'deliveredSubOrdersCount',
          label: this.$t('delivered_sub_orders_count'),
          type: 'number',
          default: '-',
          align: 'right'
        },
        {
          id: 'scoresToDeliveredSubOrdersPercentage',
          label: this.$t('scores_to_delivered_sub_orders_rate'),
          type: 'percentage'
        },
        {
          id: 'messagesToDeliveredSubOrdersPercentage',
          label: this.$t('messages_to_delivered_sub_orders_rate'),
          type: 'percentage'
        }
      ],
      showDetail: false,
      chartTooltip: null
    };
  },
  computed: {
    rows() {
      return this.reportsTable.map(fitStatistic => {
        const date = this.getFormattedDate(fitStatistic.date);
        return {
          date,
          scoresCount: fitStatistic.scores_count,
          messagesCount: fitStatistic.messages_count,
          deliveredSubOrdersCount: fitStatistic.delivered_sub_orders_count,
          scoresToDeliveredSubOrdersPercentage:
            fitStatistic.scores_to_delivered_sub_orders_rate * 100,
          messagesToDeliveredSubOrdersPercentage:
            fitStatistic.messages_to_delivered_sub_orders_rate * 100
        };
      });
    },
    chartOptions() {
      return {
        title: { text: this.$t('size_feedback_title') },
        xAxis: { type: 'datetime' },
        yAxis: [
          {
            title: {
              text: 'Percent ( % )'
            },
            labels: {
              format: '{value} %'
            },
            opposite: true
          }
        ],
        tooltip: {
          enabled: false
        },
        plotOptions: {
          line: {
            dataLabels: {
              format: '{y} %'
            },
            enableMouseTracking: true
          }
        },
        series: [
          {
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData(
              'scores_to_delivered_sub_orders_rate',
              'percentage'
            ),
            name: this.$t('scores_to_delivered_sub_orders_rate'),
            type: 'line'
          },
          {
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData(
              'messages_to_delivered_sub_orders_rate',
              'percentage'
            ),
            name: this.$t('messages_to_delivered_sub_orders_rate'),
            type: 'line'
          }
        ]
      };
    }
  },
  methods: {
    onChartTooltip({ x, index, series }) {
      this.chartTooltip = {
        title: this.getFormattedDate(new Date(x)),
        items: series.chart.series.map(({ data, name }) => ({
          symbol: 'circle',
          name,
          value: data[index].y,
          unit: 'percentage',
          precision: 2
        }))
      };
    }
  }
};
</script>

<i18n locale="ko">
{
  "scores_count": "사이즈 평가수",
  "messages_count": "사이즈 한줄평수",
  "delivered_sub_orders_count": "배송 완료된 상품수",
  "scores_to_delivered_sub_orders_rate": "사이즈 평가 작성률",
  "messages_to_delivered_sub_orders_rate": "사이즈 한줄평 작성률",
  "size_feedback_title": "사이즈 피드백"
}
</i18n>
