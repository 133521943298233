import Vue from 'vue';
import moment from 'moment';
import i18n from '@/lib/i18n';
import store from '@/store';

function formatDate(value, format) {
  if (value) {
    const DATE_FORMATS = {
      date: 'YYYY. MM. DD.',
      date_w: 'YYYY. MM. DD. ddd',
      datetime: 'YYYY. MM. DD. HH:mm:ss',
      datetime_hm: 'YYYY. MM. DD. HH:mm',
      month: 'MMM',
      month_y: 'YYYY. MM.'
    };
    const dateString = typeof value === 'string' ? value : value.toISOString();
    moment.locale(i18n.locale);
    return moment(dateString)
      .utcOffset(9) // Fix to KST
      .format(DATE_FORMATS[format]);
  }
}

Vue.filter('formatDate', v => formatDate(v, 'date'));
Vue.filter('formatDateWithWday', v => formatDate(v, 'date_w'));
Vue.filter('formatDateTime', v => formatDate(v, 'datetime'));
Vue.filter('formatDateTimeWithoutSecond', v => formatDate(v, 'datetime_hm'));
Vue.filter('formatMonth', v => formatDate(v, 'month'));
Vue.filter('formatMonthWithYear', v => formatDate(v, 'month_y'));

Vue.filter('formatKoreanPhone', function(value) {
  if (value) {
    if (value.length === 11) {
      return value.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');
    } else if (value.length === 10) {
      if (value.startsWith('02')) {
        return value.replace(/^02(\d{4})(\d{4})$/, '02-$1-$2');
      } else {
        return value.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
      }
    } else if (value.length === 9 && value.startsWith('02')) {
      return value.replace(/^02(\d{3})(\d{4})$/, '02-$1-$2');
    } else if (value.length === 8) {
      return value.replace(/^(\d{4})(\d{4})$/, '$1-$2');
    } else {
      return value;
    }
  }
});

Vue.filter('formatUser', ({ user_name, user_username }) =>
  [user_name, user_username ? `(${user_username})` : ''].join('')
);

function formatCurrencyWithoutUnit(cents) {
  const { use_cents } = store.state.session.brandSettings;
  return use_cents
    ? (cents / 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : cents
    ? cents.toLocaleString()
    : '0';
}

Vue.filter('formatCurrencyWithoutUnit', formatCurrencyWithoutUnit);

Vue.filter('formatCurrency', cents => {
  const value = formatCurrencyWithoutUnit(cents);
  const key = store.getters['session/currencyKey'];
  return i18n.t(key, [value]);
});

Vue.filter('formatFromNow', dateTime => {
  return moment(dateTime).fromNow();
});
