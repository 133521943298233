<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="name"
    :form-props="{
      ...formProps,
      objectId: 'product',
      submitLabel: $t('app.copy'),
      submittingLabel: $t('copying')
    }"
    v-on="formEvents"
  >
    <template #group="{ id, inputId, inputName, errors }">
      <template v-if="id === 'dst_product_id'">
        <div class="AppForm__group-field">
          <AppSelectProduct
            :id="inputId"
            :name="inputName"
            @blur="validateField('dst_product_id_field')"
            @change="changeDstProductId"
          />
        </div>
        <div class="ProductCopyReviewsDialog__description">
          {{ $t('description', [name]) }}
        </div>
        <AppFormError :error="errors.dst_product_id_field" />
        <div class="AppForm__group-field">
          <AppSelectRadio
            :options="REVIEW_FILTER_TYPE_OPTIONS"
            name="product[review_filter_type]"
            value="all"
          >
            <template #sub-item="{ value, checked }">
              <template v-if="value === 'timespan'">
                <AppDateRangePicker
                  v-model="dateRange"
                  :disabled="!checked"
                  required
                />
                <input
                  type="hidden"
                  name="product[start_date]"
                  :disabled="!checked"
                  :value="dateRange.start_date"
                />
                <input
                  type="hidden"
                  name="product[end_date]"
                  :disabled="!checked"
                  :value="dateRange.end_date"
                />
              </template>
            </template>
          </AppSelectRadio>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ProductCopyReviewsDialog',
  mixins: [DialogFormView],
  props: {
    id: { type: Number, required: true },
    name: { type: String, required: true }
  },
  data() {
    return {
      dateRange: {
        start_date: moment()
          .subtract(1, 'year')
          .toVal(),
        end_date: moment().toVal()
      },
      REVIEW_FILTER_TYPE_OPTIONS: ['all', 'timespan'].map(value => ({
        label: this.$t(`review_filter_type.${value}`),
        value
      })),
      detectFormDataChange: false
    };
  },
  computed: {
    formSections() {
      return [
        {
          id: 'basic',
          groups: [
            {
              id: 'dst_product_id',
              label: this.$t('dst_product_id'),
              fields: [
                {
                  id: 'dst_product_id_field',
                  validate: ['required'],
                  value: () => this.formObject.dst_product_id
                }
              ]
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    changeDstProductId(id) {
      this.formObject.dst_product_id = id;
      this.validateField('dst_product_id_field');
    },
    submit(formData) {
      if (!confirm(this.$t('confirm'))) return;

      this.isSubmitting = true;
      api
        .post(`/product/products/${this.id}/copy_reviews`, formData)
        .then(({ data }) => {
          this.createToast(
            data.copy_reviews_count === 0
              ? this.$t('copy_reviews_enqueued.zero')
              : this.$t('copy_reviews_enqueued.other', [
                  data.copy_reviews_count
                ])
          );
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.ProductCopyReviewsDialog__description {
  margin-top: 4px;
  color: $color-grey-40;
}
</style>

<i18n locale="ko">
{
  "title": "리뷰 복사하기",
  "copying": "복사 요청 중",
  "dst_product_id": "리뷰를 복사할 상품 선택",
  "description": "현재 상품 ({0})의 리뷰를 선택한 상품의 리뷰로 복사합니다.",
  "review_filter_type": {
    "all": "전체 리뷰 복사",
    "timespan": "선택한 기간 동안 작성한 리뷰 복사"
  },
  "copy_reviews_enqueued": {
    "zero": "복사할 리뷰가 없습니다.",
    "other": "{0}개의 리뷰에 대한 복사가 시작됐습니다. 리뷰 개수에 따라 몇분 혹은 몇시간까지 소요될 수 있습니다."
  },
  "confirm": "한번 복사한 리뷰는 자동으로 되돌릴 수 없습니다.\n[어드민 - 리뷰 - 리뷰 목록 및 적립금 주기] 메뉴에서 해당 상품명과 함께 [상세검색 - 리뷰 작성경로 - 복사] 를 선택해 복사한 리뷰를 검색한다음 수동으로 삭제 해야 합니다.\n정말로 복사하시겠습니까?"
}
</i18n>
