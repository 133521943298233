<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{ ...formProps, disabled: true }"
    :is-loading="isLoading"
    hide-close-button
    v-on="formEvents"
  >
    <template #group="{ id, value }">
      <template v-if="id === 'payment_status'">
        <AppBadge
          class="AppForm__group-field"
          :label="
            $t(
              `vaccount_info.payment_status.badge.${
                isPaid ? 'success' : 'pending'
              }`
            )
          "
          :badge-style="isPaid ? 'default' : 'mint-green'"
        />
      </template>
      <template v-else-if="id === 'copy_vaccount'">
        <AppButtonCopyable
          v-if="!isPaid"
          class="AppForm__group-field"
          :label="$t('vaccount_info.copy_vaccount.button')"
          :value="value"
        />
      </template>
      <template v-else-if="id === 'tax_invoice_info'">
        <AppCorporateNumberChangeRequestForm
          :status="corporateNumberChangeRequest.status"
          :reject-message="corporateNumberChangeRequest.reject_message"
          @requested="lastCorporateNumberChangeRequest = $event"
          @enable-close-button="enableCloseButton = $event"
        />
        <TheBrandMaterial
          v-if="corporateNumberChangeRequest.status == 'approved'"
          class="mt8"
          material-type="corporate_cert"
          :material="lastCorporateNumberChangeRequest"
        />
      </template>
    </template>
    <template #foot>
      <AppButton
        :label="$t('app.confirmed')"
        button-style="blue"
        :disabled="!enableCloseButton"
        @click="close"
      />
    </template>
  </AppModalForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import api from '@/lib/api';
import payment from '@/lib/payment';
import PaymentStatus from '@/enums/PaymentStatus';
import DialogFormView from '@/mixins/DialogFormView';
import DialogSize from '@/enums/DialogSize';
import TheBrandMaterial from '@/layouts/TheBrandMaterial';

export default {
  name: 'ThePaymentVaccountInfoDialog',
  components: { TheBrandMaterial },
  mixins: [DialogFormView],
  props: {
    paymentId: { type: Number, required: true },
    showPaymentCompletedMessage: { type: Boolean, default: false }
  },
  data: () => ({
    isLoading: true,
    payment: null,
    lastCorporateNumberChangeRequest: null,
    enableCloseButton: true
  }),
  computed: {
    ...mapGetters('payment', ['packageName']),
    corporateNumberChangeRequest() {
      return (
        this.lastCorporateNumberChangeRequest || {
          status: 'none'
        }
      );
    },
    isPaid() {
      return this.payment.payment_status === PaymentStatus.SUCCESS_PAID;
    },
    formSections() {
      if (this.isLoading) return [];

      return [
        ...(this.showPaymentCompletedMessage
          ? [
              {
                id: 'completed_message',
                label: this.$t('completed_message.label'),
                groups: []
              }
            ]
          : []),
        {
          id: 'payment_info',
          groups: [
            {
              id: 'payment_name',
              label: this.$t('payment_info.payment_name.label'),
              type: 'static',
              value: this.payment.packages
                .map(p => this.packageName(p))
                .join('<br />')
            },
            {
              id: 'payment_amount',
              label: this.$t('payment_info.payment_amount.label'),
              type: 'static',
              value: this.$t('payment_info.payment_amount.description', [
                this.payment.price,
                payment.originalPrice(this.payment.price),
                payment.vat(this.payment.price)
              ])
            }
          ]
        },
        {
          id: 'vaccount_info',
          label: this.$t('vaccount_info.label'),
          groups: [
            {
              id: 'payment_status',
              label: this.$t('vaccount_info.payment_status.label')
            },
            {
              id: 'copy_vaccount',
              label: this.$t('vaccount_info.copy_vaccount.label'),
              groupDescription: this.payment.danal_vaccount,
              value: this.payment.danal_vaccount
            },
            {
              id: 'vaccount_expires_at',
              label: this.$t('vaccount_info.vaccount_expires_at.label'),
              hint: this.isPaid
                ? ''
                : this.$t('vaccount_info.vaccount_expires_at.hint'),
              type: 'static',
              value: this.formatDateTime(this.payment.danal_vaccount_expires_at)
            },
            ...(this.isPaid
              ? [
                  {
                    id: 'vaccount_paid_at',
                    label: this.$t('vaccount_info.vaccount_paid_at.label'),
                    type: 'static',
                    value: this.formatDateTime(
                      this.payment.danal_vaccount_paid_at
                    )
                  }
                ]
              : [
                  {
                    id: 'cancel_button',
                    label: null,
                    type: 'button',
                    value: this.$t('vaccount_info.cancel_button.button'),
                    clickHandler: this.openCancelPaymentConfirm
                  }
                ])
          ]
        },
        {
          id: 'tax_invoice_info',
          label: this.$t('tax_invoice_info.label'),
          groups: [{ id: 'tax_invoice_info', label: null }]
        }
      ];
    }
  },
  mounted() {
    api
      .get(`/payment/payments/${this.paymentId}`)
      .then(({ data }) => {
        this.payment = data.payment;
        this.lastCorporateNumberChangeRequest =
          data.last_corporate_number_change_request;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openCancelPaymentConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('vaccount_info.cancel_button.confirm_dialog.title'),
          markdownText: this.$t(
            'vaccount_info.cancel_button.confirm_dialog.context',
            [
              this.formatDateTime(this.payment.created_at),
              this.payment.danal_itemname
            ]
          ),
          width: DialogSize.SMALL,
          closeButtonLabel: this.$t(
            'vaccount_info.cancel_button.confirm_dialog.close_button'
          ),
          cancelButtonStyle: 'grey-outline'
        }
      ]).then(eventBus => eventBus.$on('close', this.cancelPayment));
    },
    cancelPayment() {
      api.post(`/payment/payments/${this.payment.id}/cancel`).finally(() => {
        this.emit('refresh');
        this.close(true);
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "가상계좌 (무통장 입금)",
  "completed_message": {
    "label": "가상계좌 결제 신청이 완료되었습니다."
  },
  "payment_info": {
    "payment_name": {
      "label": "구매 상품"
    },
    "payment_amount": {
      "label": "총 입금 금액",
      "description": "{0}원 (상품가격 {1}원 + 부가세 {2}원)"
    }
  },
  "vaccount_info": {
    "label": "입금 정보",
    "payment_status": {
      "label": "입금 상태",
      "badge": {
        "pending": "결제 대기중",
        "success": "결제 완료"
      }
    },
    "copy_vaccount": {
      "label": "입금계좌번호",
      "button": "계좌번호 복사"
    },
    "vaccount_expires_at": {
      "label": "입금기한",
      "hint": "입금이 확인되면 자동으로 서비스가 활성화됩니다."
    },
    "vaccount_paid_at": {
      "label": "입금 완료일"
    },
    "email": {
      "label": "입금 정보 수신 이메일"
    },
    "cancel_button": {
      "button": "입금 신청 취소하기",
      "confirm_dialog": {
        "title": "입금 신청을 취소하시겠습니까?",
        "context": "<pre>{0}에 신청한</pre>{1}<br>입금 신청을 취소합니다.",
        "close_button": "입금 신청 취소"
      }
    }
  },
  "tax_invoice_info": {
    "label": "세금계산서 발급 준비"
  }
}
</i18n>
