<template>
  <AppModalForm
    :title="$t('title')"
    width="1200px"
    :form-props="formProps"
    :is-loading="isLoading"
    v-on="formEvents"
  >
    <template #group>
      <AppResourceTable
        slot="group"
        table-id="review-settings-option-type-sets-table"
        :columns="COLUMNS"
        :rows="rows"
        :no-data="$t('no_data')"
        enable-query
        @change-query="setQuery"
      >
        <template #cell="{ row, column }">
          <template v-if="column === 'product_category_names'">
            <AppBadge
              v-for="(name, i) in row.product_category_names"
              :key="i"
              badge-style="grey-outline"
              :label="name"
            />
          </template>
          <template v-else-if="column === 'type'">
            <AppBadge
              :badge-style="
                row.type === ReviewOptionType.EVALUATION
                  ? 'blue-outline'
                  : 'mint-green-outline'
              "
              :label="ReviewOptionType.t(row.type)"
            />
          </template>
          <template v-else-if="column === 'required_for_mileage'">
            <input
              type="hidden"
              name="option_type_sets[][id]"
              :value="row.id"
            />
            <AppCheckbox
              v-model="row.required_for_mileage"
              name="option_type_sets[][required_for_mileage]"
              input-style="standalone"
            />
          </template>
        </template>
      </AppResourceTable>
    </template>
  </AppModalForm>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import { filterItems } from '@/lib/text-search';
import DialogFormView from '@/mixins/DialogFormView';
import ResourceView from '@/mixins/ResourceView';
import ReviewOptionType from '@/enums/ReviewOptionType';

export default {
  name: 'ReviewSettingsOptionTypeSetDialog',
  mixins: [DialogFormView, ResourceView],
  data() {
    return {
      COLUMNS: [
        'name',
        'product_category_names',
        'type',
        'required_for_mileage'
      ].map(c => ({
        id: c,
        label: this.$t(`table_header.${c}`)
      })),
      isLoading: true,
      resources: nullResources,
      query: ''
    };
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoriesWithTreeName']),
    ReviewOptionType() {
      return ReviewOptionType;
    },
    formSections() {
      return [{ groups: [{ id: 'option_type_sets', label: null }] }];
    },
    filteredOptionTypeSets() {
      const nameFilteredIds = filterItems(
        this.formObject.option_type_sets,
        'name',
        this.query
      ).map(i => i.id);
      const categoryFilteredIds = filterItems(
        this.formObject.option_type_sets,
        'searchableProductCategoryNames',
        this.query
      ).map(i => i.id);

      const filteredIdsSet = new Set(
        nameFilteredIds.concat(categoryFilteredIds)
      );
      return this.formObject.option_type_sets.filter(({ id }) =>
        filteredIdsSet.has(id)
      );
    },
    rows() {
      return this.filteredOptionTypeSets;
    },
    categoryNamesMap() {
      const categoryNamesMap = {};
      this.productCategoriesWithTreeName.forEach(
        ({ id, treeName }) => (categoryNamesMap[id] = treeName)
      );
      return categoryNamesMap;
    }
  },
  methods: {
    setQuery(query) {
      this.query = query;
    },
    initialResources({ option_type_sets }) {
      option_type_sets.items = option_type_sets.items.map(
        ({ product_category_ids, ...item }) => {
          const product_category_names = product_category_ids.map(
            id => this.categoryNamesMap[id] || this.$t('unselected_categories')
          );
          return {
            ...item,
            product_category_names,
            searchableProductCategoryNames: product_category_names.join(',')
          };
        }
      );
      return option_type_sets;
    },
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/review/option_type_sets', { params })
        .then(({ data }) => {
          this.resources = this.initialResources(data);
          this.orgFormObject = { option_type_sets: this.resources.items };
        })
        .finally(() => (this.isLoading = false));
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/review/option_type_sets', formData)
        .then(() => {
          alert(
            this.$t('ReviewSettingsView.mileage_recalculation_alert_message')
          );
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .review-settings-option-type-sets-table__name {
    flex: 1 0 190px;
    vertical-align: middle;
  }

  .review-settings-option-type-sets-table__brand-category-names {
    flex: 2 0 630px;
    vertical-align: middle;
  }

  .review-settings-option-type-sets-table__type {
    flex: 1 0 100px;
  }

  .review-settings-option-type-sets-table__required-for-mileage {
    flex: 1 0 230px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "적립금 지급을 위한 필수 추가 정보",
  "table_header": {
    "name": "추가 정보 항목명",
    "product_category_names": "적용 카테고리",
    "type": "항목 분류",
    "required_for_mileage": "적립금 지급을 위한 필수 입력"
  },
  "unselected_categories": "선택하지 않은 모든 카테고리",
  "no_data": "설정된 추가 정보가 없습니다."
}
</i18n>
