<template>
  <div id="app" class="App" :data-os="isWindows ? 'windows' : null">
    <TheGoogleTagManager />
    <TheSplash v-if="isLoading" />
    <RouterView v-else-if="isReady" />
    <TheToasts />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import TheSplash from './layouts/TheSplash';
import TheToasts from './layouts/TheToasts';
import TheGoogleTagManager from './layouts/TheGoogleTagManager';

export default {
  name: 'App',
  components: { TheSplash, TheToasts, TheGoogleTagManager },
  data() {
    return { isLoading: true, operatingSystem: null };
  },
  computed: {
    ...mapState('session', ['currentUser', 'currentBrand']),
    ...mapState(['brandParams']),
    ...mapGetters(['isWindows']),
    ...mapGetters('request', ['isRequestPending']),
    ...mapGetters('menu', ['selectedSubMenuItemId']),
    isReady() {
      if (this.isLoading) return false;

      switch (this.$route.meta.loginRequirement) {
        case 'login_required':
          return !!this.currentUser && !!this.currentBrand;
        case 'logout_required':
          return !this.currentUser;
        case 'forced_logout_required':
          return !this.currentUser;
        default:
          return true;
      }
    }
  },
  watch: {
    $route() {
      this.locationChanged();
    },
    selectedSubMenuItemId() {
      this.resetTitle();
    }
  },
  created() {
    this.INIT_ENV();
    this.SET_BRAND_PARAMS(this.$route.query);
    this.locationChanged();
    this.loadEditorConfig();
    this.checkSession();
  },
  methods: {
    ...mapMutations(['INIT_ENV', 'SET_BRAND_PARAMS']),
    ...mapMutations('translation', [
      'SET_TRANSLATIONS',
      'SET_ORIGINAL_KEY_MAP'
    ]),
    ...mapActions(['setCurrentPath', 'resetTitle']),
    ...mapActions('editor', ['loadEditorConfig']),
    ...mapActions('session', [
      'fetchSession',
      'redirectToLogin',
      'redirectToNextPage',
      'logout',
      'logoutOnly'
    ]),
    ...mapActions('toast', ['createToast']),
    locationChanged() {
      this.setCurrentPath(this.$route);
    },
    checkSession() {
      if (this.$route.meta.skipCheckSession) {
        this.isLoading = false;
        return;
      }

      this.isLoading = true;
      this.fetchSession()
        .then(() => {
          const { loginRequirement } = this.$route.meta;
          const { forceLogin } = this.$route.query;
          const isLoggedIn = !!this.currentUser;

          if (forceLogin === 'true') {
            this.logout();
          } else if (isLoggedIn) {
            if (loginRequirement === 'logout_required') {
              this.createToast(this.$t('already_logged_in'));
              this.redirectToNextPage('/');
            } else if (loginRequirement === 'forced_logout_required') {
              this.logoutOnly();
            } else this.redirectToNextPage(null);
          } else if (loginRequirement === 'login_required') {
            if (this.$route.path !== '/') alert(this.$t('login_required'));
            this.redirectToLogin(true);
          }
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "already_logged_in": "이미 로그인되어 있습니다.",
  "login_required": "로그인이 필요한 서비스입니다. 로그인 후 다시 시도해주세요."
}
</i18n>
