<template>
  <AppButton
    :type="buttonType"
    :tooltip="tooltip"
    :disabled="disabled"
    size="small"
    @click="$emit('click')"
  >
    <AppSvgIcon
      class="AppResourceTableSearchbarIconButton__icon"
      :name="icon"
    />
    <span>{{ label }}</span>
    <template #dropdown>
      <slot name="dropdown" />
    </template>
  </AppButton>
</template>

<script>
export default {
  name: 'AppResourceTableSearchbarIconButton',
  props: {
    buttonType: { type: String, default: null },
    label: { type: String, required: true },
    icon: { type: String, required: true },
    tooltip: { type: [String, Object], default: null },
    disabled: { type: Boolean, default: false }
  }
};
</script>

<style scoped>
.AppResourceTableSearchbarIconButton__icon {
  position: relative;
  top: 2px;
  margin-right: 8px;
}
</style>
