<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppSelectRadio v-model="valueBasic" :options="BASIC_OPTIONS" />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Right Position Radio</div>
      <AppSelectRadio
        v-model="valueBasic"
        :options="BASIC_OPTIONS"
        :input-position="'right'"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Multiple Columns</div>
      <AppSelectRadio
        v-model="valueMultipleColumn"
        :cols="3"
        :options="MULTIPLE_COLUMNS_OPTIONS"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueMultipleColumn }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Long Label</div>
      <AppSelectRadio v-model="valueLongLabel" :options="LONG_LABEL_OPTIONS" />
      <div class="ui-section__description">
        Selected Value: {{ valueLongLabel }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Tooltip</div>
      <AppSelectRadio v-model="valueTooltip" :options="optionsTooltip" />
      <div class="ui-section__description">
        Selected Value: {{ valueTooltip }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">SubItem</div>
      <AppSelectRadio v-model="valueSubItem" :options="BASIC_OPTIONS">
        <template #sub-item="{ value, checked }">
          <AppTextInput
            v-if="value === 1"
            :disabled="!checked"
            :placeholder="`SubItem for ${value}`"
          />
          <AppTextInput
            v-else-if="value === 2"
            :disabled="!checked"
            :placeholder="`SubItem for ${value}`"
          />
        </template>
      </AppSelectRadio>
      <div class="ui-section__description">
        Selected Value: {{ valueSubItem }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Invalid</div>
      <AppSelectRadio v-model="valueInvalid" :options="BASIC_OPTIONS" invalid />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">SlotLabel</div>
      <AppSelectRadio v-model="valueSlotLabel" :options="BASIC_OPTIONS">
        <template #label="{ value, checked }">
          <div v-if="value === 1">
            Option with input
            <AppNumberInput :disabled="!checked" :digits="2" />
          </div>
        </template>
      </AppSelectRadio>
      <div class="ui-section__description">
        Selected Value: {{ valueSlotLabel }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">Super Admin Option</div>
      <AppSelectRadio
        v-model="valueSuperAdmin"
        :options="SUPER_ADMIN_OPTIONS"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueSuperAdmin }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiSelectRadio',
  data() {
    return {
      BASIC_OPTIONS: [
        { label: 'Option A', value: 1 },
        { label: 'Option B', value: 2 },
        { label: 'Disabled Option', value: 3, disabled: true },
        { label: '한글 옵션', value: 4 }
      ],
      MULTIPLE_COLUMNS_OPTIONS: [
        { label: 'Top Left', value: 1 },
        { label: 'Top Center', value: 2 },
        { label: 'Top Right', value: 3 },
        { label: 'Middle Left', value: 4 },
        { label: 'Middle Center', value: 5 },
        { label: 'Middle Right', value: 6 },
        { label: 'Bottom Left', value: 7 },
        { label: 'Bottom Center', value: 8 },
        { label: 'Bottom Right', value: 9 }
      ],
      LONG_LABEL_OPTIONS: [
        {
          label:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris lobortis sed quam eget laoreet. Aliquam eu metus ac sem feugiat eleifend id aliquam sem. Quisque rhoncus nisi vitae feugiat efficitur. In facilisis diam eu turpis vulputate pellentesque. Curabitur vestibulum, orci nec tempus pretium, odio odio consectetur nibh, nec viverra dolor massa nec mi. Cras interdum velit lacus, id tincidunt metus euismod vitae. Sed leo mi, dictum ac lacus nec, eleifend porta libero.',
          value: 1
        },
        {
          label:
            'Sed eget accumsan ante. Quisque felis mi, rhoncus vel felis eget, tincidunt condimentum diam. Duis semper nec ipsum quis convallis.',
          value: 2
        },
        {
          label: 'Option 3',
          value: 3
        }
      ],
      SUPER_ADMIN_OPTIONS: [
        { label: 'Option A', value: 1 },
        { label: 'Option B', value: 2 },
        { label: 'Super Admin Option', value: 3, superAdmin: true }
      ],
      valueBasic: 1,
      valueMultipleColumn: 1,
      valueLongLabel: 1,
      valueTooltip: 'B',
      valueSubItem: 1,
      valueInvalid: null,
      valueSlotLabel: 1,
      valueSuperAdmin: 1
    };
  },
  computed: {
    optionsTooltip: () => [
      { label: 'Option A', value: 'A', tooltip: 'This is Option A' },
      { label: 'Option B', value: 'B', tooltip: 'This is Option B' }
    ]
  }
};
</script>
