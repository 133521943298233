<template>
  <div
    :class="[
      'SubMenuTextAndImageNotice',
      notice.image_url ? 'SubMenuTextAndImageNotice--image' : null
    ]"
  >
    <div v-if="notice.tag_name" class="SubMenuTextAndImageNotice__tag">
      {{ notice.tag_name }}
    </div>
    <div class="SubMenuTextAndImageNotice__title">{{ notice.title }}</div>
    <div class="SubMenuTextAndImageNotice__message-container">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="SubMenuTextAndImageNotice__message"
        :style="{ '-webkit-line-clamp': messageLineClamp }"
        v-html="notice.message"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
    <div
      v-if="notice.image_url"
      class="SubMenuTextAndImageNotice__image-container"
    >
      <AppImage
        :src="notice.image_url"
        :original-ratio="true"
        class="SubMenuTextAndImageNotice__image"
      />
    </div>
    <AppButton
      v-else-if="notice.button_text"
      class="SubMenuTextAndImageNotice__button"
      :label="notice.button_text"
      type="external_link"
      :disabled="!notice.button_url"
      :to="notice.button_url"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
const TITLE_HEIGHT_THRESHOLD = 24;
export default {
  name: 'SubMenuTextAndImageNotice',
  props: {
    notice: { type: Object, required: true }
  },
  data() {
    return {
      titleHeight: null
    };
  },
  computed: {
    isTitleExceedThreshold() {
      return this.titleHeight && this.titleHeight > TITLE_HEIGHT_THRESHOLD;
    },
    messageLineClamp() {
      if (this.notice.image_url) return 2;
      if (this.notice.tag_name) return this.messageLineClampWithTag;

      return this.messageLineClampWithTag + 1;
    },
    messageLineClampWithTag() {
      if (this.isTitleExceedThreshold) {
        return this.notice.button_text ? 4 : 7;
      } else {
        return this.notice.button_text ? 6 : 8;
      }
    }
  },
  mounted() {
    this.titleHeight = this.$el.getElementsByClassName(
      'SubMenuTextAndImageNotice__title'
    )[0].clientHeight;
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

@mixin line-with-ellipsis($line) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

.SubMenuTextAndImageNotice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 24px 22px 24px;
  height: 100%;

  &--image {
    padding: 12px 24px 0 24px;
  }
}

.SubMenuTextAndImageNotice__tag {
  flex: 0 0 15px;
  width: fit-content;
  margin: 0 auto 6px auto;
  padding: 1px 8px 1px 8px;
  border-radius: 70px;
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: -0.3px;
  color: #ffffff;
  background-color: #105ef6;
}

.SubMenuTextAndImageNotice__title {
  @include line-with-ellipsis(2);
  flex: 0 0 auto;
  max-height: 48px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #000000;
}

.SubMenuTextAndImageNotice__message-container {
  margin-top: 4px;
  text-align: center;
  flex: 0 0 auto;
}

.SubMenuTextAndImageNotice__message {
  @include text-caption-dark;
  @include line-with-ellipsis(2);
  height: 100%;
  letter-spacing: -0.3px;

  .SubMenuTextAndImageNotice--image & {
    color: #000000;
  }
}

.SubMenuTextAndImageNotice__image-container {
  position: relative;
  flex: 1 0 auto;
  margin-top: 4px;
}

.SubMenuTextAndImageNotice__image {
  position: absolute;
  top: 0px;
  left: -24px;
  width: 266px;
  height: 100%;
}

.SubMenuTextAndImageNotice__button {
  display: block;
  margin-top: 10px;
  text-align: center;
}

::v-deep {
  .AppButton__button {
    width: auto;
    max-width: 218px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: $color-grey-60;
    padding: 4px 16px;
    border: 1px solid $color-grey-25;
    background-color: #ffffff;
  }
}
</style>
