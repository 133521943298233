var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Default Row Table")]),_c('AppResourceTable',{attrs:{"columns":_vm.DEFAULT_COLUMNS,"rows":_vm.DEFAULT_ROWS}})],1),_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Wide Row Table")]),_c('AppResourceTable',{attrs:{"columns":_vm.DEFAULT_COLUMNS,"rows":_vm.DEFAULT_ROWS,"table-style":"wide"}})],1),_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Main Columns In Head")]),_c('AppResourceTable',{attrs:{"main-columns":_vm.MAIN_COLUMNS,"columns":_vm.MAIN_COLUMNS_TABLE_COLUMNS,"rows":_vm.DEFAULT_TABLE_ROWS}})],1),_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Tooltip")]),_c('AppResourceTable',{attrs:{"columns":_vm.TOOLTIP_TABLE_COLUMNS,"rows":[]}})],1),_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Statistics Table")]),_c('AppResourceTable',{attrs:{"columns":_vm.STATISTICS_TABLE_COLUMNS,"rows":_vm.STATISTICS_TABLE_ROWS,"table-style":"stats"}})],1),_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Depth Table")]),_c('AppResourceTable',{attrs:{"columns":_vm.DEPTH_TABLE_COLUMNS,"rows":_vm.DEPTH_TABLE_ROWS,"table-style":"depth"}})],1),_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Custom Buttons")]),_c('AppResourceTable',{attrs:{"columns":_vm.DEFAULT_COLUMNS,"rows":[],"custom-buttons":[
        {
          label: '샘플1',
          icon: 'icon-close-small',
          clickHandler: _vm.clickSearchbarButton
        },
        {
          label: '샘플2',
          icon: 'icon-search-small',
          clickHandler: _vm.clickSearchbarButton
        },
        {
          icon: 'icon-calendar-small',
          controlType: 'select',
          options: [
            { label: '전체', value: 0 },
            { label: '1번', value: 1 },
            { label: '2번', value: 2 }
          ],
          value: 0
        }
      ]}})],1),_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Refresh Button")]),_c('AppResourceTable',{attrs:{"columns":_vm.DEFAULT_COLUMNS,"rows":[],"enable-refresh":""},on:{"refresh":_vm.refreshTable}})],1),_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Column Settings Button")]),_c('AppResourceTable',{attrs:{"table-id":"ui-column-settings-table","columns":_vm.COLUMN_SETTINGS_TABLE_COLUMNS,"rows":[],"enable-column-settings":""}})],1),_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Batch Buttons")]),_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppResourceTable',{attrs:{"table-id":"ui-resource-table-batch","resources":_vm.resources,"columns":_vm.PRODUCT_TABLE_COLUMNS,"rows":_vm.rows,"batch-buttons":[
          {
            label: 'batch1',
            clickHandler: _vm.batch1,
            tooltip: 'batch1 tooltip'
          },
          {
            label: 'batch2',
            controlType: 'menu',
            menuItems: [
              {
                label: 'menu1',
                tooltip: 'menu1 tooltip',
                clickHandler: _vm.batch2
              }
            ]
          }
        ]},on:{"paginate":_vm.paginate,"select-rows":function($event){_vm.selectedRows = $event}}})],1)],1),_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Filter Rows")]),_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppResourceTable',{attrs:{"columns":_vm.DEFAULT_COLUMNS,"rows":_vm.filteredRows,"filter-values":_vm.filterValues,"filter-options":[
          {
            label: '첫 번째 행 보기',
            value: 1
          },
          {
            label: '두 번째 행 보기',
            value: 2
          }
        ]},on:{"change-filter-values":function($event){_vm.filterValues = $event}}})],1)],1),_c('div',{staticClass:"ui-section"},[_c('div',{staticClass:"ui-section__title"},[_vm._v("Custom Searchbar")]),_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppResourceTable',{attrs:{"columns":_vm.DEFAULT_COLUMNS,"rows":_vm.rows},scopedSlots:_vm._u([{key:"additional-table-searchbar",fn:function(){return [_c('AppSelect',{attrs:{"options":[
              { label: '선택해보세요' },
              { label: '선택지1', value: 1 },
              { label: '선택지2', value: 2 }
            ]}})]},proxy:true}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }