var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppGrid',[_c('div',{staticClass:"AppGrid__col AppGrid__col--4 AppGrid__col--tablet-12"},[_c('TargetSectionKpi',_vm._b({attrs:{"title":_vm.$t('dispatch.title'),"bar-title":_vm.$t('dispatch.bar_title'),"kpi-title":_vm.$t('detail')},on:{"click-item":function($event){return _vm.clickDetailButton()}}},'TargetSectionKpi',
        _vm.isLoading
          ? { isLoading: true, barData: [], barTooltip: {}, kpiData: [] }
          : {
              isLoading: false,
              barData: _vm.dispatchBarData,
              barTooltip: _vm.barTooltip(_vm.dispatchBarData),
              kpiData: _vm.dispatchKpiData
            }
      ,false))],1),_c('div',{staticClass:"AppGrid__col AppGrid__col--4 AppGrid__col--tablet-12"},[_c('TargetSectionKpi',_vm._b({attrs:{"title":_vm.$t('purchase.title'),"bar-title":_vm.$t('purchase.bar_title'),"kpi-title":_vm.$t('detail')},on:{"click-item":function($event){return _vm.openDetailDialog('purchased_orders')}}},'TargetSectionKpi',
        _vm.isLoading
          ? { isLoading: true, barData: [], barTooltip: {}, kpiData: [] }
          : {
              isLoading: false,
              barData: _vm.purchaseBarData,
              barTooltip: _vm.barTooltip(_vm.purchaseBarData),
              kpiData: _vm.purchaseKpiData
            }
      ,false))],1),_c('div',{staticClass:"AppGrid__col AppGrid__col--4 AppGrid__col--tablet-12"},[_c('TargetSectionKpi',_vm._b({attrs:{"title":_vm.$t('visit.title'),"bar-title":_vm.$t('visit.bar_title'),"kpi-title":_vm.$t('detail')},on:{"click-item":function($event){return _vm.openDetailDialog('visited_users')}}},'TargetSectionKpi',
        _vm.isLoading
          ? { isLoading: true, barData: [], barTooltip: {}, kpiData: [] }
          : {
              isLoading: false,
              barData: _vm.visitBarData,
              barTooltip: _vm.barTooltip(_vm.visitBarData),
              kpiData: _vm.visitKpiData
            }
      ,false))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }