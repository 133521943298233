<template>
  <AppModalDefault :title="$t('title')" :width="width" @close="close">
    <template #body>
      <AppIframeViewer :src="previewUrl" height="800px" />
    </template>
    <template #foot>
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState } from 'vuex';
import DialogView from '@/mixins/DialogView';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallationPresetPlacementType from '@/enums/InstallationPresetPlacementType';

export default {
  name: 'ReviewDashboardRequestWidgetInstallationPreviewDialog',
  mixins: [DialogView],
  props: {
    isMobile: { type: Boolean, required: true },
    pageType: { type: Number, required: true },
    presetPlacementType: { type: Number, required: true },
    presetWidgetType: { type: Number, required: true },
    displayFormat: { type: String, default: '' }
  },
  computed: {
    ...mapState(['env']),
    width() {
      return this.isMobile ? 'small' : 'wide';
    },
    previewUrl() {
      const url = new URL(this.baseUrl);

      url.pathname = `/review/view/${this.device}/onboarding/${this.pageTypeUrl}.html`;

      url.searchParams.set('presetPlacementTypes', this.presetPlacementTypeKey);
      url.searchParams.set('presetWidgetTypes', this.presetWidgetType);
      url.searchParams.set('displayFormat', this.displayFormat);

      return url.href;
    },
    baseUrl() {
      const env = this.env === 'production' ? '' : location.host[0];
      return `https://${env}sample.cre.ma`;
    },
    device() {
      return this.isMobile ? 'mobile' : 'pc';
    },
    presetPlacementTypeKey() {
      return InstallationPresetPlacementType.toKey(this.presetPlacementType);
    },
    pageTypeUrl() {
      return WidgetPageType.toKey(this.pageType).toLowerCase();
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "위젯 미리보기"
}
</i18n>
