<template>
  <AppModalDefault
    :title="
      totalReportType === TargetTotalReportType.TOTAL
        ? $t('title_total', [displayDate])
        : $t('title', [displayDate])
    "
    :sub-title="
      totalReportType !== TargetTotalReportType.TOTAL
        ? TargetTotalReportType.t(totalReportType)
        : null
    "
    width="wide"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppInfoBox class="TargetReportsTotalReportsDetailDialog__info"
          >{{
            $t('info_messsage', [
              totalReportType !== TargetTotalReportType.TOTAL
                ? TargetTotalReportType.t(totalReportType)
                : $t('affected_purchase_count')
            ])
          }}
        </AppInfoBox>
        <AppSmallDeviceScrollable>
          <AppTable
            class="TargetReportsTotalReportsDetailDialog__section-table"
            :columns="columns"
            :rows="rows"
          >
            <template #cell="{ column, row }">
              <template v-if="column.id === 'name'">
                <div
                  class="TargetReportsTotalReportsDetailDialog__campaign-group-type"
                >
                  {{
                    row.campaign_group_type
                      ? TargetCampaignGroupType.t(row.campaign_group_type)
                      : $t('custom_campaign')
                  }}
                </div>
                <div>{{ row.name }}</div>
              </template>
              <template v-else-if="column.id === 'actions'">
                <AppButton
                  :label="$t('show_stats')"
                  @click="clickShowStats(row)"
                />
              </template>
            </template>
          </AppTable>
        </AppSmallDeviceScrollable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import { mapGetters, mapActions } from 'vuex';
import DialogView from '@/mixins/DialogView';
import TargetCampaignGroupType from '@/enums/TargetCampaignGroupType';
import TargetTotalReportType from '@/enums/TargetTotalReportType';

export default {
  name: 'TargetReportsTotalReportsDetailDialog',
  mixins: [DialogView],
  props: {
    dateRange: { type: Object, required: true },
    campaignDispatchType: { type: Number, default: null },
    totalReportType: { type: Number, required: true }
  },
  data: () => ({ isLoading: true, reports: [] }),
  computed: {
    ...mapGetters(['oldAdminUrl']),
    TargetCampaignGroupType: () => TargetCampaignGroupType,
    TargetTotalReportType: () => TargetTotalReportType,
    columnProps() {
      const { totalReportType } = this;
      const totalReportTypeKey = TargetTotalReportType.key(totalReportType);
      return {
        name: { align: 'left' },
        sent_users_count: { type: 'number' },
        checked_users_rate: { type: 'rate' },
        purchased_users_count: { type: 'number' },
        revenue: { type: 'currency' },
        effect: {
          label: this.$t(`effect.${totalReportTypeKey}`),
          type:
            totalReportType === TargetTotalReportType.PURCHASE_PROMOTION
              ? 'currency'
              : null
        },
        kpi: {
          type: 'rate',
          label: this.$t(`kpi.${totalReportTypeKey}`)
        }
      };
    },
    columnIds() {
      switch (this.totalReportType) {
        case TargetTotalReportType.PURCHASE_PROMOTION:
          return [
            'checked_users_rate',
            'purchased_users_count',
            'effect',
            'kpi'
          ];
        case TargetTotalReportType.CHURNING_PREVENTION:
          return ['checked_users_rate', 'effect', 'kpi'];
        case TargetTotalReportType.REVIEW:
          return ['checked_users_rate', 'effect', 'kpi'];
        case TargetTotalReportType.USER_MANAGEMENT:
          return ['effect', 'kpi'];
        case TargetTotalReportType.MASS:
          return ['effect', 'revenue'];
        case TargetTotalReportType.TOTAL:
          return ['purchased_orders_count', 'revenue'];
        default:
          return [];
      }
    },
    columns() {
      return ['name', 'sent_users_count', ...this.columnIds, 'actions'].map(
        id => {
          const props = this.columnProps[id] || {};
          return { id, ...props, label: props.label || this.$t(`table.${id}`) };
        }
      );
    },
    rows() {
      return this.reports.map(r => ({
        ...r,
        checked_users_rate:
          r.checked_users_count && r.sent_users_count
            ? r.checked_users_count / r.sent_users_count
            : null
      }));
    },
    displayDate() {
      const { start_date, end_date } = this.dateRange;
      return start_date === end_date
        ? this.formatDate(start_date)
        : `${this.formatDate(start_date)} ~ ${this.formatDate(end_date)}`;
    }
  },
  mounted() {
    api
      .get('/target/total_reports/detail_reports', {
        params: {
          ...this.dateRange,
          total_report_type: this.totalReportType,
          campaign_dispatch_type: this.campaignDispatchType
        }
      })
      .then(({ data }) => (this.reports = data.reports_by_campaign))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    clickShowStats(campaign) {
      const { start_date, end_date } = this.dateRange;
      if (!campaign.campaign_group_type)
        window.location.assign(
          this.oldAdminUrl(
            `/target/reports?start_date=${start_date}&end_date=${end_date}`
          )
        );
      else if (campaign.campaign_group_type === TargetCampaignGroupType.MASS)
        this.openDialog([
          'TargetCampaignMassStatsDialog',
          { campaignId: campaign.id }
        ]);
      else
        this.openDialog([
          'TargetCampaignStatsDialog',
          { campaignId: campaign.id, startDate: start_date, endDate: end_date }
        ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TargetReportsTotalReportsDetailDialog__info {
  margin-bottom: 24px;
}

.TargetReportsTotalReportsDetailDialog__campaign-group-type {
  @include text-caption;
}
</style>

<i18n locale="ko">
{
  "title": "{0} 캠페인 목적별 통계",
  "title_total": "{0} 타겟이 영향을 준 구매건",
  "table": {
    "name": "캠페인 이름",
    "sent_users_count": "발송 인원수",
    "checked_users_rate": "확인율",
    "purchased_users_count": "구매자수",
    "purchased_orders_count": "구매건수",
    "actions": "상세 통계",
    "revenue": "구매 금액"
  },
  "effect": {
    "PURCHASE_PROMOTION": "구매 금액",
    "CHURNING_PREVENTION": "재방문 회원수",
    "USER_MANAGEMENT": "가입 회원수",
    "REVIEW": "작성 리뷰수",
    "MASS": "방문자수"
  },
  "kpi": {
    "PURCHASE_PROMOTION": "구매 전환율",
    "CHURNING_PREVENTION": "이탈 방지율",
    "USER_MANAGEMENT": "가입 전환율",
    "REVIEW": "리뷰 작성률"
  },
  "info_messsage": "선택 기간중 {0}((를)) 목적으로 발송한 캠페인의 목록입니다.",
  "affected_purchase_count": "발송한 캠페인과 직간접적으로 관련이 있는 구매건",
  "show_stats": "통계 보기",
  "custom_campaign": "커스텀 캠페인"
}
</i18n>
