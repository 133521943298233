<template>
  <AppContainer class="AnalyticsInsightProductDialogAnalysisGraph">
    <div class="AnalyticsInsightProductDialogAnalysisGraph__title">
      {{ $t('reviews_analysis') }}
    </div>
    <AppGrid class="AnalyticsInsightProductDialogAnalysisGraph__summary">
      <div
        class="AppGrid__col AnalyticsInsightProductDialogAnalysisGraph__summary-left"
      >
        <AppDataItem gap="wide">
          <AppDataSectionItem
            v-for="(item, i) in analysisItems"
            :key="i"
            :title="item.title"
          >
            <template #info>
              <AppSvgIcon
                v-tooltip="item.info"
                class="AnalyticsInsightProductDialogAnalysisGraph__summary-icon"
                name="icon-info-tooltip"
                :width="10"
                :height="10"
              />
            </template>
            <div class="AppDataSectionItem__content">
              <span v-if="item.prefix">{{ item.prefix }} </span>
              <span v-if="!product.review_tags_count && i === 1">-%</span>
              <AppNumber v-else v-bind="item.numberProps" />
            </div>
          </AppDataSectionItem>
        </AppDataItem>
      </div>
      <div
        class="AppGrid__col AnalyticsInsightProductDialogAnalysisGraph__summary-right"
      >
        <AppChart
          :options="chartOptions"
          :tooltip="chartTooltip"
          @tooltip="onChartTooltip"
        />
      </div>
    </AppGrid>
  </AppContainer>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import ReviewSentimentType from '@/enums/ReviewSentimentType';

export default {
  name: 'AnalyticsInsightProductDialogAnalysisGraph',
  props: {
    product: { type: Object, required: true },
    keywordAnalysisMap: { type: Object, required: true }
  },
  data() {
    return {
      sentiemtsCountSum: 0,
      pSentiemtsCountSum: 0,
      zSentiemtsCountSum: 0,
      nSentiemtsCountSum: 0,
      chartTooltip: null
    };
  },
  computed: {
    ...mapGetters('session', ['brandReviewTagTypes']),
    analysisItems() {
      return [
        {
          title: this.$t('insight.review_tags_count'),
          info: this.$t('analysis_info.review_tags_count'),
          numberProps: {
            value: this.product.review_tags_count,
            unit: 'count'
          }
        },
        {
          title: this.$t('keywords_positive_negative_ratio'),
          info: this.$t('analysis_info.keywords_positive_negative_ratio'),
          prefix: this.keywordsPositiveNegativeRatioPrefix,
          numberProps: {
            value: this.keywordsPositiveNegativeRatio,
            precision: 2,
            unit: 'percentage'
          }
        }
      ];
    },
    keywordsPositiveNegativeRatioPrefix() {
      switch (this.maxSentimentsCountSum) {
        case this.pSentiemtsCountSum:
          return ReviewSentimentType.t(ReviewSentimentType.POSITIVE);
        case this.zSentiemtsCountSum:
          return ReviewSentimentType.t(ReviewSentimentType.NEUTRAL);
        case this.nSentiemtsCountSum:
          return ReviewSentimentType.t(ReviewSentimentType.NEGATIVE);
        default:
          return '';
      }
    },
    keywordsPositiveNegativeRatio() {
      return this.sentiemtsCountSum
        ? (this.maxSentimentsCountSum / this.sentiemtsCountSum) * 100
        : 0;
    },
    maxSentimentsCountSum() {
      return _.max([
        this.pSentiemtsCountSum,
        this.zSentiemtsCountSum,
        this.nSentiemtsCountSum
      ]);
    },
    chartOptions() {
      const chartHeight =
        50 * Math.ceil(this.brandReviewTagTypes.length / 2) + 75;

      const types = _.orderBy(
        this.brandReviewTagTypes,
        t => this.keywordAnalysisMap[t.id]?.sentiments_count || 0,
        ['desc']
      );

      return {
        chart: {
          height: chartHeight,
          spacing: [0, 0, 0, 0],
          marginRight: 1,
          type: 'bar'
        },
        exporting: false,
        title: { text: '' },
        tooltip: { enabled: false },
        xAxis: { categories: types.map(t => t.name) },
        yAxis: {
          labels: { enabled: true },
          title: { enabled: false }
        },
        legend: { enabled: false },
        plotOptions: {
          series: {
            animation: false,
            stacking: 'normal',
            pointWidth: 16,
            pointPadding: 0,
            groupPadding: 0
          }
        },
        series: [
          {
            name: ReviewSentimentType.t(ReviewSentimentType.NEGATIVE),
            data: types.map(
              t => this.keywordAnalysisMap[t.id]?.nsentiments_count || 0
            )
          },
          {
            name: ReviewSentimentType.t(ReviewSentimentType.NEUTRAL),
            data: types.map(
              t => this.keywordAnalysisMap[t.id]?.zsentiments_count || 0
            )
          },
          {
            name: ReviewSentimentType.t(ReviewSentimentType.POSITIVE),
            data: types.map(
              t => this.keywordAnalysisMap[t.id]?.psentiments_count || 0
            )
          }
        ]
      };
    }
  },
  mounted() {
    _.each(this.keywordAnalysisMap, countMap => {
      this.sentiemtsCountSum += countMap.sentiments_count;
      this.pSentiemtsCountSum += countMap.psentiments_count;
      this.zSentiemtsCountSum += countMap.zsentiments_count;
      this.nSentiemtsCountSum += countMap.nsentiments_count;
    });
  },
  methods: {
    onChartTooltip({ index, series }) {
      const chartSeries = _.cloneDeep(series.chart.series).reverse();
      this.chartTooltip = {
        title: chartSeries[0].data[index].category,
        items: chartSeries.map(({ data, name }, i) => {
          const sum = _.sum(chartSeries.map(s => s.data[index].y));
          const val = data[index].y;
          const percentage = Math.round((val / sum) * 10000) / 100;
          const tooltipText =
            this.$t('unit.count', [val.toLocaleString()]) +
            ` (${this.$t('unit.percentage', [
              percentage.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            ])})`;
          return {
            symbol: 'square',
            name: name + ' ' + this.$t('keyword'),
            value: tooltipText,
            colorIndex: chartSeries.length - (i + 1)
          };
        })
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightProductDialogAnalysisGraph {
  height: 100%;
}

.AnalyticsInsightProductDialogAnalysisGraph__title {
  @include text-label;
}

.AnalyticsInsightProductDialogAnalysisGraph__summary {
  height: 100%;
  margin-top: 24px;
}

.AnalyticsInsightProductDialogAnalysisGraph__summary-left {
  flex: 0 0 144px;
}

.AnalyticsInsightProductDialogAnalysisGraph__summary-right {
  flex: 1 0 0;
}

.AnalyticsInsightProductDialogAnalysisGraph__summary-icon {
  fill: $color-grey-50;
  margin-left: 4px;
}

::v-deep {
  .AppDataSectionItem {
    padding-top: 0;
  }

  .AppDataSectionItem + .AppDataSectionItem {
    margin-top: 24px;
  }
}

@media (min-width: 406px) and (max-width: 999px) {
  ::v-deep {
    .AppDataSectionItem {
      display: inline-block;
    }

    .AppDataSectionItem + .AppDataSectionItem {
      margin-top: 0;
      margin-left: 28px;
    }
  }

  .AnalyticsInsightProductDialogAnalysisGraph__summary {
    display: block;
  }

  .AnalyticsInsightProductDialogAnalysisGraph__summary-left {
    flex: unset;
  }

  .AnalyticsInsightProductDialogAnalysisGraph__summary-right {
    flex: unset;
    margin-top: 24px;
  }
}

@mixin highcharts-color($num, $color) {
  .highcharts-color-#{$num} {
    fill: $color;
    stroke: $color;
  }
}

::v-deep {
  @include highcharts-color(0, $color-red);
  @include highcharts-color(1, $color-grey-25);
  @include highcharts-color(2, $color-mint-green);
}
</style>

<i18n locale="ko">
{
  "reviews_analysis": "리뷰 키워드 분석",
  "keywords_positive_negative_ratio": "긍정/부정 비율",
  "analysis_info": {
    "review_tags_count": "선택된 기간 내 해당 상품의 리뷰에서 분석된 키워드 개수입니다.",
    "keywords_positive_negative_ratio": "리뷰에서 분석된 모든 키워드들에 대한 긍정/부정 평가 비율입니다."
  },
  "keyword": "키워드"
}
</i18n>
