<template>
  <AppForm
    class="KakaoStyleSettingsForm"
    v-bind="formProps"
    v-on="formEvents"
    @change-group="formGroupChanged"
  />
</template>

<script>
import api from '@/lib/api';
import KakaoStyleReviewImportStatus from '@/enums/KakaoStyleReviewImportStatus';
import FormView from '@/mixins/FormView';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'KakaoStyleSettingsForm',
  mixins: [FormView],
  computed: {
    ...mapState('kakaoStyle', ['reviewImportStatus']),
    formSections() {
      return [
        {
          id: 'basic',
          groups: [
            { id: 'enabled', type: 'switch', label: this.$t('enabled.label') }
          ]
        }
      ];
    }
  },
  created() {
    this.orgFormObject = {
      enabled: this.reviewImportStatus === KakaoStyleReviewImportStatus.ENABLED
    };
    this.formObject = { ...this.orgFormObject };
  },
  methods: {
    ...mapMutations('kakaoStyle', ['SET_REVIEW_IMPORT_STATUS']),
    formGroupChanged(group) {
      api
        .patch(
          '/kakao_style/settings/toggle_review_import',
          {
            enabled: group.value ? '1' : '0'
          },
          {
            successMessage: group.value
              ? this.$t('success_message.enabled')
              : this.$t('success_message.disabled')
          }
        )
        .then(() =>
          this.SET_REVIEW_IMPORT_STATUS(
            this.formObject.enabled
              ? KakaoStyleReviewImportStatus.ENABLED
              : KakaoStyleReviewImportStatus.DISABLED
          )
        );
    }
  }
};
</script>

<i18n locale="ko">
{
  "enabled": {
    "label": "카카오스타일 연동 사용"
  },
  "success_message": {
    "enabled": "카카오스타일 리뷰 연동을 사용합니다.",
    "disabled": "카카오스타일 리뷰 연동을 중지합니다."
  }
}
</i18n>
