var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"form-props":Object.assign({}, {formStyle: 'wide'}, _vm.formProps)},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
var options = ref.options;
var value = ref.value;
var errors = ref.errors;
return [(id === 'use_including_all_product_categories')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelect',{staticClass:"AppForm__group-field",attrs:{"name":"campaign_settings[use_including_all_product_categories]","options":options},model:{value:(_vm.formObject.use_including_all_product_categories),callback:function ($$v) {_vm.$set(_vm.formObject, "use_including_all_product_categories", $$v)},expression:"formObject.use_including_all_product_categories"}})],1),(!value)?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppMultipleSelectProductCategory',{attrs:{"name":"campaign_settings[including_product_category_ids][]","placeholder":_vm.$t('including_product_category_ids.placeholder'),"invalid":!!errors.including_product_category_ids},on:{"blur":function($event){return _vm.validateField('including_product_category_ids')},"change":function($event){return _vm.validateField('including_product_category_ids')}},model:{value:(_vm.formObject.including_product_category_ids),callback:function ($$v) {_vm.$set(_vm.formObject, "including_product_category_ids", $$v)},expression:"formObject.including_product_category_ids"}}),_c('AppFormError',{attrs:{"error":errors.including_product_category_ids}})],1)]:_vm._e(),_c('div',{staticClass:"AppForm__group-field"},[_c('AppCheckbox',{attrs:{"name":"campaign_settings[use_excluding_product_category_ids]","label":_vm.$t('use_excluding_product_category_ids.label')},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
var checked = ref.checked;
return [_c('AppMultipleSelectProductCategory',{attrs:{"name":"campaign_settings[excluding_product_category_ids][]","placeholder":_vm.$t('excluding_product_category_ids.placeholder'),"disabled":!checked,"invalid":!!errors.excluding_product_category_ids,"exclude-category-values":_vm.formObject.including_product_category_ids},on:{"blur":function($event){return _vm.validateField('excluding_product_category_ids')},"change":function($event){return _vm.validateField('excluding_product_category_ids')}},model:{value:(_vm.formObject.excluding_product_category_ids),callback:function ($$v) {_vm.$set(_vm.formObject, "excluding_product_category_ids", $$v)},expression:"formObject.excluding_product_category_ids"}}),_c('AppFormHint',{attrs:{"message":_vm.$t('excluding_product_category_ids.hint'),"disabled":!checked}}),_c('AppFormError',{attrs:{"error":errors.excluding_product_category_ids}})]}}],null,true),model:{value:(_vm.formObject.use_excluding_product_category_ids),callback:function ($$v) {_vm.$set(_vm.formObject, "use_excluding_product_category_ids", $$v)},expression:"formObject.use_excluding_product_category_ids"}})],1)]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }