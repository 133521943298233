<template>
  <AppRadioLabel
    :class="['AppCheckbox', inputStyle ? `AppCheckbox--${inputStyle}` : null]"
    :disabled="disabled"
    :label="label"
    :input-style="inputStyle"
    :input-position="inputPosition"
    :checked="currentChecked"
    :value="value"
    :tooltip="tooltip"
  >
    <div>
      <input
        :id="id"
        :checked="currentChecked"
        :name="name"
        :disabled="disabled"
        :value="value"
        :class="[
          'AppCheckbox__input',
          { 'AppCheckbox__input--invalid': invalid }
        ]"
        type="checkbox"
        @change="change"
      />
      <input
        v-if="value === '1' && !currentChecked"
        :name="name"
        value="0"
        type="hidden"
      />
      <!-- Use v-show because it can be toggled frequently -->
      <!-- https://vuejs.org/v2/guide/conditional.html#v-if-vs-v-show -->
      <AppSvgIcon
        v-show="currentChecked"
        class="AppCheckbox__check-icon"
        :name="iconName"
        :height="inputStyle === 'standalone' ? 16 : null"
      />
    </div>
    <template v-if="$scopedSlots.label" #label="labelProps">
      <slot name="label" v-bind="labelProps" />
    </template>
    <template v-if="$scopedSlots['sub-item']" #sub-item="subItemProps">
      <slot name="sub-item" v-bind="subItemProps" />
    </template>
  </AppRadioLabel>
</template>

<script>
export default {
  name: 'AppCheckbox',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    invalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    checked: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Boolean],
      default: '1'
    },
    tooltip: {
      type: [String, Object],
      default: null
    },
    iconName: {
      type: String,
      default: 'icon-checkbox-checker'
    },
    label: {
      type: String,
      default: null
    },
    inputStyle: { type: String, default: null },
    inputPosition: { type: String, default: null }
  },
  data() {
    return {
      currentChecked: this.checked
    };
  },
  watch: {
    checked() {
      this.currentChecked = this.checked;
    }
  },
  methods: {
    change(e) {
      this.currentChecked = e.target.checked;
      this.$emit('change', this.currentChecked);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppCheckbox__check-icon {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.AppCheckbox__input {
  @include radio-checkbox-base;
  display: block;

  .AppCheckbox--standalone & {
    width: $radio-checkbox-size-standalone;
    height: $radio-checkbox-size-standalone;
  }

  &:checked {
    background-color: $color-primary;
  }

  &:disabled {
    background-color: white;

    &:checked {
      background-color: $color-grey-25;
    }
  }
}
</style>
