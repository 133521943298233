import Vue from 'vue';
import api from '@/lib/api';

const FETCH_NOTIFICATIONS_REQUEST_ID = 'FETCH_NOTIFICATIONS';

const state = {
  notifications: []
};

const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
  MARK_NOTIFICATION_CHECKED({ notifications }, notificationId) {
    const notification = notifications.find(n => n.id === notificationId);
    if (notification) Vue.set(notification, 'notification_status', 'checked');
  }
};

const actions = {
  fetchNotifications({ rootGetters, commit }) {
    api
      .get('/notifications', { requestId: FETCH_NOTIFICATIONS_REQUEST_ID })
      .then(function({ data }) {
        commit('SET_NOTIFICATIONS', data.notifications);
        if (!rootGetters['session/isCurrentUserAdmin'])
          commit('session/RESET_UNLISTED_NOTIFICATIONS_COUNT', null, {
            root: true
          });
      });
  },
  resetNotifications({ commit }) {
    api.cancel(FETCH_NOTIFICATIONS_REQUEST_ID);
    commit('SET_NOTIFICATIONS', []);
  },
  markNotificationChecked({ state, commit, rootGetters }, id) {
    if (rootGetters['session/isCurrentUserAdmin']) return;

    const notification = state.notifications.find(n => n.id === id);
    if (notification?.notification_status === 'checked') return;

    commit('MARK_NOTIFICATION_CHECKED', id);
    commit('session/DEDUCT_UNCHECKED_NOTIFICATIONS_COUNT', null, {
      root: true
    });
    api.patch(`/notifications/${id}/mark_checked`, {}, { silent: true });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
