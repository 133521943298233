<template>
  <AppModalDefault
    :title="$t('menu.notices_dialog')"
    width="wide"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppResourceTable
          table-id="notices-table"
          :columns="[
            { id: 'position', type: 'position' },
            { id: 'created_at', label: $t('app.created_at'), type: 'date' },
            { id: 'title', label: $t('app.title'), align: 'left' }
          ]"
          :resources="notices"
          :rows="notices.items"
          @paginate="paginate"
        >
          <template #cell="{ column, row }">
            <template v-if="column === 'title'">
              <a @click="clickNotice(row)">{{ row.title }}</a>
            </template>
          </template>
        </AppResourceTable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import DialogView from '@/mixins/DialogView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'TheNoticesDialog',
  mixins: [DialogView, ResourceViewNoRoute],
  data() {
    return { isLoading: true, notices: nullResources };
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/help/notices', { params })
        .then(({ data }) => (this.notices = data.notices))
        .finally(() => (this.isLoading = false));
    },
    clickNotice(notice) {
      this.openDialog([
        'TheNoticeDialog',
        {
          noticeId: notice.id,
          noticeTitle: notice.title,
          hideShowAllButton: true
        }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .notices-table__created-at {
    flex: 0 0 140px;
  }
}
</style>
