var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppSearchbar',{scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.isSuperAdminAccessible)?_c('li',{staticClass:"super-admin__item"},[_c('AppButton',{attrs:{"label":_vm.$t('button.add_bonus_transaction'),"button-style":"red"},on:{"click":_vm.openBonusDialog}})],1):_vm._e(),(_vm.isSuperAdminAccessible)?_c('li',{staticClass:"super-admin__item"},[_c('AppButton',{attrs:{"label":_vm.$t('button.add_display_transaction')},on:{"click":_vm.openAddDisplayTransactionDialog}})],1):_vm._e()]},proxy:true},{key:"right",fn:function(){return [_c('li',[_c('AppSelect',{attrs:{"options":_vm.isSuperAdminAccessible
              ? _vm.DisplayTransactionType.options()
              : _vm.DisplayTransactionType.options().filter(
                  function (o) { return o.value !== _vm.DisplayTransactionType.SUPER_ADMIN_ETC; }
                ),"placeholder":_vm.$t('transaction_type_placeholder')},on:{"change":_vm.searchResource},model:{value:(_vm.resourceParams.transaction_type),callback:function ($$v) {_vm.$set(_vm.resourceParams, "transaction_type", $$v)},expression:"resourceParams.transaction_type"}})],1),_c('li',[_c('AppDateRangePicker',{on:{"update":_vm.searchResource},model:{value:(_vm.resourceParams),callback:function ($$v) {_vm.resourceParams=$$v},expression:"resourceParams"}})],1)]},proxy:true}])}),_c('AppResourceTable',{attrs:{"table-id":"display-transactions-table","resources":_vm.resources,"rows":_vm.rows,"columns":_vm.columns,"enable-total-count":false,"no-data":_vm.$t('no_data')},on:{"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
                var column = ref.column;
                var value = ref.value;
                var row = ref.row;
return [(column === 'transaction_type')?[_c('AppBadge',{attrs:{"label":_vm.DisplayTransactionType.t(value),"badge-style":_vm.DisplayTransactionType.badgeStyle(value)}})]:(column === 'display_expires_at')?[(row.isExpiresAtNotChanged && value)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.formatDate(value)),expression:"formatDate(value)"}]},[_vm._v(" "+_vm._s(_vm.$t('not_changed'))+" ")]):(value)?_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(value)))]):_vm._e()]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }