import SmartInstallationSimulationType from '@/enums/SmartInstallationSimulationType';
import WidgetPageType from '@/enums/WidgetPageType';
import WidgetInsertType from '@/enums/WidgetInsertType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';

const SUMMARY_KEYS = [
  'page_type',
  'element_selector',
  'install_position',
  'widget_id',
  'skip_product_no_from_url',
  'skin_id'
];
const DIFF_KEYS = ['page_path']
  .concat(SUMMARY_KEYS)
  .concat([
    'device_type',
    'custom_installed',
    'install_order',
    'reviews_count_display_format'
  ]);

export default {
  methods: {
    parseDetailFormat(key, value) {
      switch (key) {
        case 'page_type':
          return value.page_type === WidgetPageType.SPECIFIC
            ? `SPECIFIC(${value.page_path})`
            : WidgetPageType.key(value.page_type);
        case 'install_position':
          return WidgetInsertType.key(value);
        case 'widget_id':
          return value ? `widget ${value}` : '';
        case 'skip_product_no_from_url':
          return value ? 'skip_product_no_from_url' : '';
        case 'device_type':
          return WidgetDeviceType.key(value);
        case 'custom_installed':
          return value ? 'custom_installed' : 'default_position';
        case 'install_order':
          return `#${value}`;
        default:
          return value ? value : '';
      }
    },
    simulationDetail(simulation) {
      switch (simulation.simulation_type) {
        case SmartInstallationSimulationType.CREATE:
          return {
            summary: this.summary(simulation)
          };
        case SmartInstallationSimulationType.UPDATE:
          return {
            summary: this.summary(simulation.original),
            diff: this.diff(simulation)
          };
        case SmartInstallationSimulationType.DELETE:
          return {
            summary: this.summary(simulation.original)
          };
        default:
          return { summary: {}, diff: {} };
      }
    },
    summary(installation) {
      const summary = {};

      SUMMARY_KEYS.forEach(key => {
        let valueToParse = installation[key];
        if (key == 'page_type') {
          valueToParse = {
            page_type: installation.page_type,
            page_path: installation.page_path
          };
        }

        const value = this.parseDetailFormat(key, valueToParse);
        if (value) summary[key] = value;
      });

      return summary;
    },
    diff(simulation) {
      const diff = {};

      DIFF_KEYS.forEach(key => {
        let orgValue = simulation.original[key];
        let simValue = simulation[key];

        if (orgValue !== simValue) {
          if (key == 'page_type') {
            orgValue = {
              page_type: simulation.original.page_type,
              page_path: simulation.original.page_path
            };
            simValue = {
              page_type: simulation.page_type,
              page_path: simulation.page_path
            };
          }

          diff[key] = {
            original: this.parseDetailFormat(key, orgValue),
            simulation: this.parseDetailFormat(key, simValue)
          };
        }
      });

      return diff;
    }
  }
};
