var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title', [_vm.dispatchText]),"sub-title":_vm.campaign.name,"is-loading":_vm.isLoading,"form-props":Object.assign({}, _vm.formProps,
    {objectId: 'campaign',
    disabled: _vm.isArchivedCampaign})},scopedSlots:_vm._u([{key:"group",fn:function(ref){
  var id = ref.id;
  var disabled = ref.disabled;
  var errors = ref.errors;
return [(id === 'dispatch_weekdays_type')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectRadio',{attrs:{"options":_vm.dispatchDateOptions,"disabled":disabled},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
  var value = ref.value;
  var checked = ref.checked;
return [(value === 'everyday')?[_c('input',{attrs:{"type":"hidden","name":"campaign[dispatch_weekdays_value]","disabled":disabled || !checked},domProps:{"value":_vm.EVERYDAY_VALUE}})]:[_c('AppWeekdaySelectButton',{attrs:{"name":"campaign[dispatch_weekdays_value]","disabled":disabled || !checked,"invalid":!!errors.dispatch_weekdays_value},on:{"change":function($event){return _vm.validateField('dispatch_weekdays_value')}},model:{value:(_vm.formObject.dispatch_weekdays_value),callback:function ($$v) {_vm.$set(_vm.formObject, "dispatch_weekdays_value", $$v)},expression:"formObject.dispatch_weekdays_value"}}),_c('AppFormError',{attrs:{"error":errors.dispatch_weekdays_value}})]]}}],null,true),model:{value:(_vm.formObject.dispatch_weekdays_type),callback:function ($$v) {_vm.$set(_vm.formObject, "dispatch_weekdays_type", $$v)},expression:"formObject.dispatch_weekdays_type"}})],1)]:(id === 'popup_check_scroll')?[_c('div',{staticClass:"AppForm__group-field"},[_c('i18n',{attrs:{"path":"form.popup_check_scroll.message"}},[_c('AppSelect',{attrs:{"name":"campaign[popup_check_direction]","inline":"","options":_vm.PopupCheckDirection.options()},model:{value:(_vm.formObject.popup_check_direction),callback:function ($$v) {_vm.$set(_vm.formObject, "popup_check_direction", $$v)},expression:"formObject.popup_check_direction"}}),_c('AppNumberInput',{attrs:{"name":"campaign[popup_check_offset_y]","inline":"","digits":2,"default":0},model:{value:(_vm.formObject.popup_check_offset_y),callback:function ($$v) {_vm.$set(_vm.formObject, "popup_check_offset_y", $$v)},expression:"formObject.popup_check_offset_y"}})],1)],1)]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }