<template>
  <AppModalDefault
    :title="$t(isGiveMileage ? 'title_give_mileage' : 'title_deduct_mileage')"
    @close="close"
  >
    <div slot="body">
      <AppContainer>
        <AppDataList :data="DATA_LIST" />
      </AppContainer>
      <AppSelectButton v-model="tab" :options="TABS" />
      <AppTable
        table-id="review-reviews-batch-mileage-result-table"
        class="tab-small__content"
        :columns="columns"
        :rows="rows"
      />
    </div>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';
import ReviewPointTypeHelper from '@/mixins/ReviewPointTypeHelper';

export default {
  name: 'ReviewReviewsBatchMileageResultDialog',
  mixins: [DialogView, ReviewPointTypeHelper],
  props: {
    isGiveMileage: {
      type: Boolean,
      required: true
    },
    successReviews: {
      type: Array,
      required: true
    },
    skipReviews: {
      type: Array,
      required: true
    },
    errorReviews: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      DATA_LIST: [
        {
          label: this.$t('app.success'),
          value: this.$t('unit.cases', [this.successReviews.length])
        },
        {
          label: `${this.$t('skip')} (${this.$t(
            this.isGiveMileage
              ? 'skip_nonmember_and_detail_give_mileage'
              : 'skip_detail_deduct_mileage'
          )})`,
          value: this.$t('unit.cases', [this.skipReviews.length])
        },
        {
          label: this.$t('error'),
          value: this.$t('unit.cases', [this.errorReviews.length])
        }
      ],
      TABS: [
        {
          label: this.$t('app.success'),
          value: 'success',
          disabled: this.successReviews.length === 0
        },
        {
          label: this.$t('skip'),
          value: 'skip',
          disabled: this.skipReviews.length === 0
        },
        {
          label: this.$t('error'),
          value: 'error',
          disabled: this.errorReviews.length === 0
        }
      ],
      tab: this.errorReviews.length
        ? 'error'
        : this.successReviews.length
        ? 'success'
        : 'skip'
    };
  },
  computed: {
    columns() {
      const columns = [
        { id: 'id', label: this.$t('table.id') },
        { id: 'created_at', label: this.$t('table.created_at'), type: 'date' },
        { id: 'user_username', label: this.$t('table.username') },
        { id: 'product', label: this.$t('table.product'), style: 'nowrap' }
      ];

      if (this.tab === 'success') {
        columns.push(
          this.isGiveMileage
            ? {
                id: 'gave_mileage_amount',
                label: this.$t('table.gave_mileage_amount'),
                align: 'right'
              }
            : {
                id: 'cancelled_mileage_amount',
                label: this.$t('table.deducted_mileage_amount'),
                align: 'right'
              }
        );
      } else if (this.tab === 'error') {
        columns.push({
          id: 'error_message',
          label: this.$t('table.error_message'),
          align: 'right'
        });
      }

      return columns;
    },
    reviews() {
      if (this.tab === 'success') {
        return this.successReviews;
      } else if (this.tab === 'skip') {
        return this.skipReviews;
      } else {
        return this.errorReviews;
      }
    },
    rows() {
      return this.reviews.map(review => ({
        ...review,
        product: review.product.name,
        gave_mileage_amount: this.formatMileage(
          review.mileage.gave_mileage_amount
        ),
        cancelled_mileage_amount: this.formatMileage(
          review.cancelled_mileage_amount
        )
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .review-reviews-batch-mileage-result-table__product {
    max-width: 106px;
  }
}
</style>

<i18n locale="ko">
{
  "title_give_mileage": "선택 리뷰 {point_type} 지급 결과",
  "title_deduct_mileage": "선택 리뷰 {point_type} 차감 결과",
  "skip": "생략",
  "error": "실패",
  "skip_nonmember_and_detail_give_mileage": "비회원 및 이미 {point_type} 지급한 리뷰",
  "skip_detail_deduct_mileage": "{point_type} 지급 내역이 없는 리뷰",
  "table": {
    "id": "리뷰 ID",
    "created_at": "작성일",
    "username": "고객 ID",
    "product": "상품",
    "gave_mileage_amount": "{point_type} 지급액",
    "deducted_mileage_amount": "{point_type} 차감액",
    "error_message": "실패 사유"
  }
}
</i18n>
