<template>
  <div class="AppTabsScrollable">
    <div class="AppTabsScrollable__tabs">
      <button
        v-for="tab in tabs"
        :key="tab.id"
        :class="[
          'AppTabsScrollable__tab',
          { 'AppTabsScrollable__tab--active': tab.id === activeTabId }
        ]"
        @click="clickTab(tab.id)"
      >
        {{ tab.label }}
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { getVerticallyScrollableParent } from '@/lib/scroll';
import Scrollable from '@/mixins/Scrollable';

export default {
  name: 'AppTabsScrollable',
  mixins: [Scrollable],
  props: { tabs: { type: Array, required: true } },
  data() {
    return {
      activeTabId: this.tabs[0].id,
      parentElement: null,
      isScrollingByClick: false
    };
  },
  mounted() {
    this.parentElement = getVerticallyScrollableParent(this.$el);
    this.parentElement.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    this.parentElement.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    clickTab(id) {
      this.activeTabId = id;
      const tabElement = this.parentElement.querySelector(`#${id}`);
      this.isScrollingByClick = true;
      const scrollOffsetTop =
        this.parentElement.getBoundingClientRect().top -
        this.$el.getBoundingClientRect().bottom;
      this.scrollToElement(tabElement, { scrollOffsetTop }).then(
        () => (this.isScrollingByClick = false)
      );
    },
    onScroll() {
      if (this.isScrollingByClick) return;

      const THRESHOLD = 100;
      const zeroY = this.$el.getBoundingClientRect().bottom;
      const nearestTab = _.findLast(this.tabs, tab => {
        const tabElement = this.parentElement.querySelector(`#${tab.id}`);
        const distance = tabElement.getBoundingClientRect().top - zeroY;
        return distance < THRESHOLD;
      });

      if (nearestTab) this.activeTabId = nearestTab.id;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_z-indexes.scss';
@import '@/scss/vars/_colors.scss';

.AppTabsScrollable {
  position: sticky;
  z-index: $z-index-sticky;
  top: 0px;
  padding-bottom: 22px;
}

.AppTabsScrollable__tabs {
  background-color: $color-grey-15;
  padding: 8px 12px;
  white-space: nowrap;
  overflow-x: auto;
}

.AppTabsScrollable__tab {
  background-color: transparent;
  color: $color-grey-50;
  padding: 6px 12px;
  border-radius: 3px;

  & + & {
    margin-left: 20px;
  }

  &--active {
    background-color: $color-grey-75;
    color: white;
  }
}
</style>
