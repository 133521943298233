<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Alert Dialog</label>
      </div>
      <AppButton label="Basic Alert" @click="openBasicAlert" />
      <AppButton
        label="Alert with [Don't Show This Again] Checkbox"
        @click="openAdvancedAlert"
      />
      <AppButton
        label="Alert with default style close button"
        @click="openDefaultStyleCloseButtonAlert"
      />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Confirm Dialog</label>
      </div>
      <AppButton label="Basic Confirm" @click="openBasicConfirm" />
      <AppButton
        label="Basic Confirm with Save Label"
        @click="openBasicConfirmWithSaveLabel"
      />
      <AppButton
        label="Confirm with [Don't Show This Again] Checkbox"
        @click="openAdvancedConfirm"
      />
      <AppButton
        label="Confirm with Checkbox which enables submit button"
        @click="openCheckConfirm"
      />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Delete Confirm Dialog</label>
      </div>
      <AppButton label="Basic Confirm" @click="openBasicDeleteConfirm" />
      <AppButton
        label="Basic Confirm with Save Label"
        @click="openBasicDeleteConfirmWithSaveLabel"
      />
      <AppButton
        label="Confirm with [Don't Show This Again] Checkbox"
        @click="openAdvancedDeleteConfirm"
      />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">localStorage</label>
      </div>
      <AppButton label="Clear localStorage" @click="clearLocalStorage" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'UiMessageDialog',
  data() {
    return {
      basicMarkdownText:
        '스탭 리뷰를 작성하면 아래 이미지와 같이 쇼핑몰 리뷰 위젯에<br>**‘스탭 리뷰로 등록한 리뷰’** 라는 안내 문구를 표시합니다.<br><br>![](https://assets.cre.ma/m/admin/v3/manager_review_alert.png)',
      additionalMarkdownText:
        "<br><br>'이 메시지를 다시 표시하지 않습니다.'를 체크하고 '확인' 시 이 alert 은 다시 표시되지 않습니다.",
      checkMarkdownText:
        '지급하려는 금액이 맞는지 확인해주세요.<br>아래의 체크박스를 누르면 입력된 금액을 지급할 수 있습니다.<br>만약 잘못 입력하셨다면 닫기 버튼을 눌러주세요.'
    };
  },
  computed: {
    advancedMarkdownText() {
      return `${this.basicMarkdownText}${this.additionalMarkdownText}`;
    }
  },
  methods: {
    ...mapActions('dialog', ['alert', 'confirm', 'openDialog']),
    ...mapActions('toast', ['createToast']),
    openBasicAlert() {
      this.alert({
        title: '스탭 리뷰 작성시 안내 문구가 표시됩니다',
        message: this.basicMarkdownText
      });
    },
    openAdvancedAlert() {
      this.alert({
        title: '스탭 리뷰 작성시 안내 문구가 표시됩니다',
        message: this.advancedMarkdownText,
        snoozeId: 'UiMessageDialog.advanced_alert'
      }).then(result => {
        if (result === 'snoozed') this.showToastInstead();
      });
    },
    openBasicConfirm() {
      this.confirm({
        title: '스탭 리뷰를 작성하시겠습니까?',
        message: this.basicMarkdownText
      }).then(result => this.createToast(result ? '저장' : '취소'));
    },
    openBasicConfirmWithSaveLabel() {
      this.confirm({
        title: '스탭 리뷰를 작성하시겠습니까?',
        message: this.basicMarkdownText,
        closeButtonLabel: this.$t('app.save')
      }).then(result => this.createToast(result ? '저장' : '취소'));
    },
    openAdvancedConfirm() {
      this.confirm({
        title: '스탭 리뷰를 작성하시겠습니까?',
        message: this.advancedMarkdownText,
        snoozeId: 'UiMessageDialog.advanced_confirm',
        closeButtonLabel: this.$t('app.save')
      }).then(result => {
        if (result === 'snoozed') this.showToastInstead();
        else this.createToast(result ? '저장' : '취소');
      });
    },
    openCheckConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'check_confirm',
          title: '평소보다 큰 금액이 입력되었습니다.',
          markdownText: this.checkMarkdownText,
          checkboxLabel: '110,000원을 지급하겠습니다.',
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('cancel', () => this.createToast('취소'));
        eventBus.$on('close', () => this.createToast('저장'));
      });
    },
    openDefaultStyleCloseButtonAlert() {
      this.alert({
        title: '스탭 리뷰 작성시 안내 문구가 표시됩니다',
        message: this.basicMarkdownText,
        width: DialogSize.SMALL,
        closeButtonStyle: 'default',
        closeButtonLabel: this.$t('app.close')
      }).then(eventBus => {
        eventBus.$on('cancel', () => this.createToast('취소'));
        eventBus.$on('close', () => this.createToast('저장'));
      });
    },
    openBasicDeleteConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: '스탭 리뷰를 삭제하시겠습니까?',
          markdownText: this.basicMarkdownText,
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('cancel', () => this.createToast('취소'));
        eventBus.$on('close', () => this.createToast('삭제'));
      });
    },
    openBasicDeleteConfirmWithSaveLabel() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: '스탭 리뷰를 삭제하시겠습니까?',
          markdownText: this.basicMarkdownText,
          closeButtonLabel: this.$t('app.delete_confirm'),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('cancel', () => this.createToast('취소'));
        eventBus.$on('close', () => this.createToast('정중한 삭제'));
      });
    },
    openAdvancedDeleteConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: '스탭 리뷰를 삭제하시겠습니까?',
          markdownText: this.advancedMarkdownText,
          snoozeId: 'UiMessageDialog.advanced_delete_confirm',
          width: DialogSize.SMALL
        }
      ])
        .then(eventBus => {
          eventBus.$on('cancel', () => this.createToast('취소'));
          eventBus.$on('close', () => this.createToast('삭제'));
        })
        .catch(this.showToastInstead);
    },
    clearLocalStorage() {
      localStorage.removeItem('hidden_dialogs');
    },
    showToastInstead() {
      this.createToast(
        "'이 메시지를 다시 표시하지 않습니다.' 설정이 저장된 상태입니다. 'Clear localStorage' 버튼을 클릭하여 저장 내역을 삭제해주세요."
      );
    }
  }
};
</script>
