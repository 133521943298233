import UnloadConfirmable from '@/mixins/UnloadConfirmable';
import PaymentMethod from '@/enums/PaymentMethod';

export default {
  mixins: [UnloadConfirmable],
  methods: {
    payWithDanal(paymentId) {
      let paymentWindow = null;
      let interval = null;
      let onUnload = null;

      return new Promise((resolve, reject) => {
        window.callbackPaymentCompleted = resolve;
        window.callbackPaymentFailed = reject;

        onUnload = () => reject('cancelled');
        this.enableUnloadConfirmation();
        window.addEventListener('unload', onUnload);

        if (this.paymentMethod === PaymentMethod.CARD)
          paymentWindow = this.openCardPaymentWindow(paymentId);
        else paymentWindow = this.openVaccountPaymentWindow(paymentId);

        interval = setInterval(() => {
          if (paymentWindow.closed) reject('cancelled');
        }, 500);
      }).finally(() => {
        window.callbackPaymentCompleted = null;
        window.callbackPaymentFailed = null;

        this.disableUnloadConfirmation();
        window.removeEventListener('unload', onUnload);

        paymentWindow.close();

        clearInterval(interval);
      });
    },
    openCardPaymentWindow(paymentId) {
      const path = `/api/payment/cards/start?payment_id=${paymentId}`;
      return window.open(path, 'payment-card-window', 'width=690, height=490');
    },
    openVaccountPaymentWindow(paymentId) {
      const path = `/api/payment/vaccounts/start?payment_id=${paymentId}`;
      return window.open(
        path,
        'payment-vaccount-window',
        'width=400, height=640'
      );
    }
  }
};
