import Vue from 'vue';
import _ from 'lodash';
import api from '@/lib/api';
import i18n from '@/lib/i18n';
import { nullResources } from '@/lib/resources';
import HttpStatus from '@/enums/HttpStatus';

const FETCH_REVIEWS_REQUEST_ID = 'FETCH_REVIEWS';

const getDefaultState = () => ({
  reviews: nullResources,
  reviewTagTypes: null,
  tags: [],
  userGrades: [],
  subBrands: [],
  keywordGroups: [],
  comboProduct: null,
  nlpCategoryTypes: []
});

const state = getDefaultState();

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_REVIEWS(state, reviews) {
    state.reviews = reviews;
  },
  CREATE_REVIEW({ reviews }, review) {
    reviews.items.unshift(review);
    Vue.set(reviews, 'total_count', reviews.total_count + 1);
  },
  UPDATE_REVIEW(state, review) {
    const index = state.reviews.items.findIndex(r => r.id === review.id);
    Vue.set(state.reviews.items, index, review);
  },
  DELETE_REVIEW({ reviews }, id) {
    const { items } = reviews;
    const index = items.findIndex(i => i.id === id);
    Vue.delete(items, index);
    Vue.set(reviews, 'total_count', reviews.total_count - 1);
  },
  DELETE_REVIEWS({ reviews }, ids) {
    const { items } = reviews;
    ids.forEach(id => {
      const index = items.findIndex(i => i.id === id);
      Vue.delete(items, index);
    });
    Vue.set(reviews, 'total_count', reviews.total_count - ids.length);
  },
  PUSH_REVIEW(state, review) {
    state.reviews.items.push(review);
  },
  PUSH_REVIEWS(state, reviews) {
    state.reviews.items.push(...reviews);
  },
  SET_REVIEW_TAG_TYPES(state, reviewTagTypes) {
    state.reviewTagTypes = reviewTagTypes;
  },
  SET_TAGS(state, tags) {
    state.tags = tags;
  },
  SET_SUB_BRANDS(state, subBrands) {
    state.subBrands = subBrands;
  },
  SET_USER_GRADES(state, userGrades) {
    state.userGrades = userGrades;
  },
  SET_KEYWORD_GROUPS(state, keywordGroups) {
    state.keywordGroups = keywordGroups;
  },
  SET_COMBO_PRODUCT(state, comboProduct) {
    state.comboProduct = comboProduct;
  },
  SET_NLP_CATEGORY_TYPES(state, nlpCategoryTypes) {
    state.nlpCategoryTypes = nlpCategoryTypes;
  }
};

const runBatchReviewsJob = ({
  dispatch,
  title,
  reviews,
  jobHandler,
  resultHandler,
  showResultImmediatly
}) =>
  dispatch(
    'asyncJob/enqueueJob',
    {
      title,
      totalCount: reviews.length,
      jobHandler,
      resultHandler,
      showResultImmediatly
    },
    { root: true }
  );

const giveMileageHandler = async ({
  dispatch,
  reviews,
  batchMileage,
  progress
}) => {
  const result = {
    successReviews: [],
    skipReviews: [],
    errorReviews: []
  };
  for (const review of reviews) {
    try {
      let currentReview;
      await api
        .get(`/review/reviews/${review.id}`)
        .then(({ data }) => (currentReview = data.review))
        .catch(() => (currentReview = null));

      validateReviewForGivingMileage({
        useCalculated: batchMileage.use_calculated,
        orgMileage: review.mileage,
        curMileage: currentReview ? currentReview.mileage : null
      });

      const response = await dispatch('giveMileageReview', {
        reviewId: review.id,
        mileage: {
          mileage_amount_cents: batchMileage.use_calculated
            ? review.mileage.admin_mileage_amount === null
              ? review.mileage.calculated_mileage_amount
              : review.mileage.admin_mileage_amount
            : batchMileage.mileage_amount,
          mileage_message: batchMileage.mileage_message,
          updated_at: currentReview.updated_at,
          skip_if_given: true
        }
      });

      if (response.status === HttpStatus.NOT_MODIFIED) {
        result.skipReviews.push(review);
      } else if (response.data.review.error_message)
        result.errorReviews.push(review);
      else result.successReviews.push(response.data.review);
    } catch (error) {
      result.errorReviews.push({
        ...review,
        error_message: batchMileageErrorMessage({ error })
      });
    }
    progress();
  }
  return result;
};

const batchGiveMileageHandler = async ({
  dispatch,
  reviews,
  batchMileage,
  progress
}) => {
  const result = {
    successReviews: [],
    skipReviews: [],
    errorReviews: [],
    error_before_request: null
  };
  const batches = [];
  for (const review of reviews) {
    try {
      let currentReview;
      await api
        .get(`/review/reviews/${review.id}`)
        .then(({ data }) => (currentReview = data.review))
        .catch(() => (currentReview = null));

      validateReviewForGivingMileage({
        useCalculated: batchMileage.use_calculated,
        orgMileage: review.mileage,
        curMileage: currentReview ? currentReview.mileage : null
      });

      batches.push({
        amount: batchMileage.use_calculated
          ? review.mileage.admin_mileage_amount === null
            ? review.mileage.calculated_mileage_amount
            : review.mileage.admin_mileage_amount
          : batchMileage.mileage_amount,
        message: batchMileage.mileage_message,
        updated_at: currentReview.updated_at,
        review_id: review.id
      });
      progress();
    } catch (error) {
      result.errorReviews.push({
        ...review,
        error_message: batchMileageErrorMessage({ error })
      });
    }
  }

  await dispatch('batchGiveMileageReview', { batches })
    .then(res => {
      result.successReviews = res.succeeded_batches;
      result.skipReviews = res.skipped_batches;
      result.errorReviews = result.errorReviews.concat(res.failed_batches);
    })
    .catch(error => {
      result.error_before_request = error.response.data.errors[0];
    });

  return result;
};

const cancelMileageHandler = async ({ dispatch, reviews, progress }) => {
  const result = {
    successReviews: [],
    skipReviews: [],
    errorReviews: []
  };

  for (const review of reviews) {
    try {
      const response = await dispatch('cancelMileageReview', {
        reviewId: review.id,
        params: { skip_if_not_given: true },
        silent: true
      });

      if (response.status === HttpStatus.NOT_MODIFIED)
        result.skipReviews.push(review);
      else
        result.successReviews.push({
          ...response.data.review,
          cancelled_mileage_amount: response.data.mileage_amount
        });
    } catch (error) {
      result.errorReviews.push({
        ...review,
        error_message: batchMileageErrorMessage({ error })
      });
    }
    progress();
  }
  return result;
};

const batchCancelMileageHandler = async ({ dispatch, reviews, progress }) => {
  const batches = reviews.map(({ id }) => ({ review_id: id }));
  const result = {
    successReviews: [],
    skipReviews: [],
    errorReviews: [],
    error_before_request: null
  };
  await dispatch('batchCancelMileageReview', { batches })
    .then(res => {
      result.successReviews = res.succeeded_batches.map(batch => ({
        ...batch,
        cancelled_mileage_amount: batch.mileage_amount
      }));
      result.skipReviews = res.skipped_batches;
      result.errorReviews = result.errorReviews.concat(res.failed_batches);
    })
    .catch(error => {
      result.error_before_request = error.response.data.errors[0];
    });

  progress();
  return result;
};

const validateReviewForGivingMileage = ({
  useCalculated,
  orgMileage,
  curMileage
}) => {
  if (!curMileage)
    throw new Error(
      i18n.t('store.review.batch_mileage_failure.deleted_review')
    );
  const isMileageAmountChanged =
    useCalculated &&
    (curMileage.admin_mileage_amount !== orgMileage.admin_mileage_amount ||
      curMileage.calculated_mileage_amount !==
        orgMileage.calculated_mileage_amount);
  if (isMileageAmountChanged)
    throw new Error(
      i18n.t('store.review.batch_mileage_failure.conflict_amount')
    );
};

const batchMileageErrorMessage = ({ error }) => {
  const { response } = error;
  if (!response) return error.message;

  const { status } = response;
  switch (status) {
    case HttpStatus.UNPROCESSABLE_ENTITY:
      return response.data.errors[0] === 'mileage_amount_blank'
        ? i18n.t('store.review.batch_mileage_failure.empty_amount')
        : response.data.errors[0];
    case HttpStatus.NOT_FOUND:
      return i18n.t('store.review.batch_mileage_failure.deleted_review');
    case HttpStatus.CONFLICT:
      return i18n.t('store.review.batch_mileage_failure.conflict_review');
    default:
      return i18n.t('store.review.batch_mileage_failure.default', { status });
  }
};

const actions = {
  resetReviews({ commit }) {
    api.cancel(FETCH_REVIEWS_REQUEST_ID);
    commit('RESET_STATE');
  },
  fetchReviews({ state, commit }, inParams) {
    const params = { ...inParams };
    if (state.reviews.isNull) params.init = 1;
    return api
      .get('/review/reviews', { requestId: FETCH_REVIEWS_REQUEST_ID, params })
      .then(function({ data }) {
        commit('SET_REVIEWS', data.reviews);
        if (params.init) {
          commit('SET_REVIEW_TAG_TYPES', data.review_tag_types || []);
          commit('SET_TAGS', data.tags);
          commit('SET_SUB_BRANDS', data.sub_brands || []);
          commit('SET_USER_GRADES', data.user_grades);
          commit('SET_KEYWORD_GROUPS', data.keyword_groups);
          commit('SET_COMBO_PRODUCT', data.combo_product);
          commit('SET_NLP_CATEGORY_TYPES', data.nlp_category_types);
        }
      });
  },
  fetchCurrentPageReviews({ state, commit }) {
    return api
      .get('/review/reviews', {
        params: {
          search_type: 'review_id',
          search_query: state.reviews.items.map(i => i.id).toString(),
          per: state.reviews.per
        }
      })
      .then(({ data }) =>
        data.reviews.items.forEach(review => commit('UPDATE_REVIEW', review))
      );
  },
  updateReview({ dispatch }, { reviewId, formData, successMessage }) {
    return api
      .patch(`/review/reviews/${reviewId}`, formData, { successMessage })
      .then(response => {
        dispatch('fetchCurrentPageReviews');
        return response;
      });
  },
  unpinReview({ commit }, reviewId) {
    return api
      .delete(`/review/reviews/${reviewId}/unpin`, {
        successMessage: i18n.t('store.review.unpinned')
      })
      .then(({ data }) => commit('UPDATE_REVIEW', data.review));
  },
  deleteReview(
    { state, commit },
    { reviewId, resourceParams, silent, successMessage }
  ) {
    const { items } = state.reviews;
    return api
      .delete(`/review/reviews/${reviewId}`, {
        params: { last_id: items[items.length - 1].id, ...resourceParams },
        silent,
        successMessage
      })
      .then(response => {
        commit('DELETE_REVIEW', reviewId);
        if (response.data.next_review)
          commit('PUSH_REVIEW', response.data.next_review);
        if (response.data.tags) commit('SET_TAGS', response.data.tags);
        return response;
      });
  },
  updateAnalysisReview(
    { commit },
    { reviewId, analysis_attributes, successMessage }
  ) {
    return api
      .patch(
        `/review/reviews/${reviewId}/update_analysis`,
        { analysis_attributes },
        {
          successMessage
        }
      )
      .then(response => {
        commit('UPDATE_REVIEW', response.data.review);
        return response;
      });
  },
  updateTagsReview({ commit }, { reviewId, tags_attributes }) {
    const tag_ids = tags_attributes
      .map(attr => attr.selected && attr.id)
      .filter(id => id);

    return api
      .patch(`/review/reviews/${reviewId}/update_tags`, {
        tags_attributes,
        tag_ids
      })
      .then(response => {
        commit('UPDATE_REVIEW', response.data.review);
        commit('SET_TAGS', response.data.tags);
        return response;
      });
  },
  batchGiveMileageReview({ commit }, { batches }) {
    return api
      .post('/review/reviews/batch_give_mileage', { batches })
      .then(response => {
        const { succeeded_batches, failed_batches } = response.data;
        [...succeeded_batches, ...failed_batches].forEach(review =>
          commit('UPDATE_REVIEW', review)
        );
        return response.data;
      });
  },
  giveMileageReview({ commit }, { reviewId, mileage, successMessage }) {
    return api
      .post(`/review/reviews/${reviewId}/give_mileage`, mileage, {
        successMessage,
        silent: true
      })
      .then(response => {
        if (response.status !== HttpStatus.NOT_MODIFIED)
          commit('UPDATE_REVIEW', response.data.review);
        return response;
      })
      .catch(error => {
        const { review } = error.response.data;
        if (review) commit('UPDATE_REVIEW', review);
        throw error;
      });
  },
  batchCancelMileageReview({ commit }, { batches }) {
    return api
      .post('/review/reviews/batch_cancel_mileage', { batches })
      .then(response => {
        const { succeeded_batches, failed_batches } = response.data;
        [...succeeded_batches, ...failed_batches].forEach(review =>
          commit('UPDATE_REVIEW', review)
        );
        return response.data;
      });
  },
  cancelMileageReview({ commit }, { reviewId, params, silent, errorMessage }) {
    return api
      .delete(`/review/reviews/${reviewId}/cancel_mileage`, {
        params,
        silent,
        errorMessage
      })
      .then(response => {
        if (response.status !== HttpStatus.NOT_MODIFIED)
          commit('UPDATE_REVIEW', response.data.review);
        return response;
      })
      .catch(error => {
        const { review } = error.response.data;
        if (review) commit('UPDATE_REVIEW', review);
        throw error;
      });
  },
  createCommentReview({ commit }, { reviewId, params, silent }) {
    return api
      .post(`/review/comments?review_id=${reviewId}`, params, { silent })
      .then(response => {
        if (response.status !== HttpStatus.NOT_MODIFIED)
          commit('UPDATE_REVIEW', response.data.review);
        return response;
      });
  },
  updateDisplayReview(
    { commit },
    { reviewId, display, is_batch, silent, successMessage }
  ) {
    return api
      .patch(
        `/review/reviews/${reviewId}/update_display`,
        { display, is_batch },
        { silent, successMessage }
      )
      .then(response => {
        commit('UPDATE_REVIEW', response.data.review);
        return response;
      });
  },
  giveMileageReviews(
    { dispatch, rootGetters },
    { title, reviews, batchMileage }
  ) {
    runBatchReviewsJob({
      dispatch,
      title,
      reviews,
      jobHandler: async ({ progress }) => {
        const handler = rootGetters['session/isBatchMileageAPIAvailable']
          ? batchGiveMileageHandler
          : giveMileageHandler;
        return await handler({
          dispatch,
          reviews,
          batchMileage,
          progress
        });
      },
      resultHandler: result => {
        if (result.error_before_request) {
          alert(
            i18n.t('store.review.batch_mileage_failure.batch_api_not_available')
          );
        } else {
          dispatch(
            'dialog/openDialog',
            [
              'ReviewReviewsBatchMileageResultDialog',
              { isGiveMileage: true, ...result }
            ],
            { root: true }
          );
        }
      },
      showResultImmediatly: true
    });
  },
  cancelMileageReviews({ dispatch, rootGetters }, { title, reviews }) {
    runBatchReviewsJob({
      dispatch,
      title,
      reviews,
      jobHandler: async ({ progress }) => {
        const handler = rootGetters['session/isBatchMileageAPIAvailable']
          ? batchCancelMileageHandler
          : cancelMileageHandler;
        return await handler({
          dispatch,
          reviews,
          progress
        });
      },
      resultHandler: result => {
        if (result.error_before_request) {
          alert(
            i18n.t('store.review.batch_mileage_failure.batch_api_not_available')
          );
        } else {
          dispatch(
            'dialog/openDialog',
            [
              'ReviewReviewsBatchMileageResultDialog',
              { isGiveMileage: false, ...result }
            ],
            { root: true }
          );
        }
      },
      showResultImmediatly: true
    });
  },
  createCommentReviews({ dispatch }, { title, reviews, batchComment }) {
    runBatchReviewsJob({
      dispatch,
      title,
      reviews,
      jobHandler: async ({ progress }) => {
        const result = { isFailed: false, skip_count: 0 };
        const params = {
          skip_if_exists: true,
          use_comment_preset: batchComment.use_comment_preset,
          comment: { message: batchComment.comment_message }
        };

        for (const review of reviews) {
          try {
            const response = await dispatch('createCommentReview', {
              reviewId: review.id,
              params
            });

            if (response.status === HttpStatus.NOT_MODIFIED)
              result.skip_count += 1;
          } catch (error) {
            result.isFailed = true;
            break;
          }
          progress();
        }

        return result;
      },
      resultHandler: ({ isFailed, skip_count }) => {
        if (isFailed) return;

        const count = reviews.length;
        let toastMessage = '';
        if (skip_count === count)
          toastMessage = i18n.t('store.review.batch_comment_skip');
        else if (skip_count) {
          const success_count = count - skip_count;
          toastMessage = i18n.t('store.review.batch_comment_success_skip', {
            success_count,
            skip_count
          });
        } else
          toastMessage = i18n.t('store.review.batch_comment_success', {
            count
          });
        dispatch('toast/createToast', toastMessage, { root: true });
      }
    });
  },
  updateDisplayReviews({ dispatch }, { title, reviews, display }) {
    runBatchReviewsJob({
      dispatch,
      title,
      reviews,
      jobHandler: async ({ progress }) => {
        let successCount = 0;
        let errorsCount = 0;
        const product_ids = reviews.map(r => r.product.id);

        for (const review of reviews) {
          try {
            await dispatch('updateDisplayReview', {
              reviewId: review.id,
              display,
              is_batch: true,
              silent: true
            });
            successCount += 1;
          } catch (error) {
            errorsCount += 1;
          }
          progress();
        }

        await api.patch('/review/products/run_skipped_callbacks', {
          ids: product_ids
        });

        if (successCount) dispatch('fetchCurrentPageReviews');

        return errorsCount;
      },
      resultHandler: errors_count => {
        const count = reviews.length;
        if (errors_count)
          alert(
            i18n.t(
              display
                ? 'store.review.batch_show_failure'
                : 'store.review.batch_hide_failure',
              { count, errors_count }
            )
          );
        else
          dispatch(
            'toast/createToast',
            i18n.t(
              display
                ? 'store.review.batch_show_success'
                : 'store.review.batch_hide_success',
              { count }
            ),
            { root: true }
          );
      }
    });
  },
  updateTagReviews(
    { dispatch, commit },
    { title, reviews, reviewIdTagIdsMap }
  ) {
    runBatchReviewsJob({
      dispatch,
      title,
      reviews,
      jobHandler: async ({ progress }) => {
        let errors_count = 0;
        const product_ids = reviews.map(r => r.product.id);

        for (const review of reviews) {
          try {
            await api
              .patch(`/review/reviews/${review.id}/update_tag_ids`, {
                tag_ids: reviewIdTagIdsMap[review.id],
                is_batch: true
              })
              .then(({ data }) => commit('UPDATE_REVIEW', data.review));
          } catch (error) {
            errors_count += 1;
          }
          progress();
        }

        await api.patch('/review/products/run_skipped_callbacks', {
          ids: product_ids,
          run_only_invalidate_cache: true
        });

        return errors_count;
      },
      resultHandler: errors_count => {
        const count = reviews.length;
        if (errors_count)
          alert(
            i18n.t('store.review.batch_tag_failure', { count, errors_count })
          );
        else
          dispatch(
            'toast/createToast',
            i18n.t('store.review.batch_tag_success', { count }),
            { root: true }
          );
      }
    });
  },
  deleteReviews({ dispatch }, { title, reviews, resourceParams }) {
    runBatchReviewsJob({
      dispatch,
      title,
      reviews,
      jobHandler: async ({ progress }) => {
        let errors_count = 0;

        const product_ids = reviews.map(r => r.product.id);
        resourceParams['is_batch'] = true;

        for (const review of reviews) {
          try {
            await dispatch('deleteReview', {
              reviewId: review.id,
              resourceParams,
              silent: true
            });
          } catch (error) {
            errors_count += 1;
          }
          progress();
        }

        await api.patch('/review/products/run_skipped_callbacks', {
          ids: product_ids
        });

        return errors_count;
      },
      resultHandler: errors_count => {
        const count = reviews.length;
        if (errors_count)
          alert(
            i18n.t('store.review.batch_delete_failure', { count, errors_count })
          );
        else
          dispatch(
            'toast/createToast',
            i18n.t('store.review.batch_delete_success', { count }),
            { root: true }
          );
      }
    });
  }
};

const getters = {
  isFetchingReviews(_state, _getters, _rootState, rootGetters) {
    return rootGetters['request/isRequestPending'](FETCH_REVIEWS_REQUEST_ID);
  },
  isBookmarkedReview: (_state, _getters, rootState) => ({ id }) => {
    return rootState.session.reviewSettings.bookmarked_review_id === id;
  },
  reviewTagTypeNameMap({ reviewTagTypes }) {
    return reviewTagTypes
      ? _.chain(reviewTagTypes)
          .keyBy('id')
          .mapValues('name')
          .value()
      : null;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
