<template>
  <div class="ChannelFeedsLastCohortSentAtCell">
    <div>{{ timeInfo }}</div>
    <AppBadge v-bind="{ label, tooltip }" />
  </div>
</template>

<script>
import ChannelFeedStatus from '@/enums/ChannelFeedStatus';

export default {
  name: 'ChannelFeedsLastCohortSentAtCell',
  props: {
    sentAt: { type: String, default: '' },
    status: {
      type: Number,
      required: true,
      validator: v =>
        [ChannelFeedStatus.DISABLED, ChannelFeedStatus.ENABLED].includes(v)
    }
  },
  computed: {
    timeInfo() {
      return this.sentAt || '-';
    },
    label() {
      return this.$t('app.detail');
    },
    tooltip() {
      return {
        message: this.$t('tooltip'),
        info: this.$t('tooltipInfo', [this.nextFeedCreateAt])
      };
    },
    nextFeedCreateAt() {
      if (this.status === ChannelFeedStatus.DISABLED) return '-';
      else {
        const now = new Date();
        now.setHours(now.getHours() + 1);
        now.setMinutes(0);
        now.setSeconds(0);
        return now.toLocaleString();
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "tooltip": "광고 피드 규칙에 따라 피드를 생성한 시간입니다.",
  "tooltipInfo": "다음 광고 피드 생성 시간: {0}"
}
</i18n>
