import WidgetStyle from '@/enums/WidgetStyle';

export default {
  formSections() {
    return {
      sort: ['managing_reviews_sort_asc'],
      design: ['use_renewed_header_style', 'enable_tab_scroll']
    };
  },
  widgetStyles() {
    return [WidgetStyle.MANAGING_REVIEWS];
  },
  displayOrderOptions() {
    return [];
  },
  showHeaderSectionSubSettings() {
    return [];
  },
  useBackgroundColor() {
    return false;
  }
};
