<template>
  <AppModalDefault :title="title" @close="close">
    <template #body>
      <video
        class="TheEnlargedVideoDialog__video"
        playsinline
        autoplay
        loop
        muted
        @click="close"
      >
        <source :src="url" type="video/mp4" />
      </video>
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'TheEnlargedVideoDialog',
  mixins: [DialogView],
  props: {
    title: { type: String, required: true },
    url: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
.TheEnlargedVideoDialog__video {
  width: 100%;
  cursor: pointer;
}
</style>
