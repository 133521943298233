import { constructEnum } from '@/lib/enum';

export default constructEnum('BizMessageProfileStatus', {
  NONE: 'N',
  ACTIVATED: 'A',
  DEACTIVATED: 'C',
  BLOCK: 'B',
  DELETING: 'E',
  DELETED: 'D'
});
