<template>
  <AppModalForm
    :title="$t(editing ? 'title_edit' : 'title_new')"
    :form-props="{ ...formProps, objectId: 'smart_installation' }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="smart_installation[installation_type]"
      :value="installation_type"
    />
  </AppModalForm>
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';
import WidgetPageType from '@/enums/WidgetPageType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import SmartInstallationType from '@/enums/SmartInstallationType';
import api from '@/lib/api';
import _ from 'lodash';

export default {
  name: 'ProductReviewsLinkFormDialog',
  mixins: [DialogFormView],
  props: {
    productReviewsLink: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      installation_type: SmartInstallationType.PRODUCT_REVIEWS_LINK
    };
  },
  computed: {
    formSections() {
      return [
        {
          id: 'default',
          groups: [
            {
              id: 'device_type',
              type: 'static',
              value: WidgetDeviceType.t(WidgetDeviceType.MOBILE)
            },
            {
              id: 'page_type',
              type: 'static',
              value: WidgetPageType.t(WidgetPageType.PRODUCT_DETAIL)
            },
            {
              id: 'element_selector',
              type: 'text',
              placeholder: this.$t('element_selector.placeholder'),
              validate: [
                'required',
                {
                  rule: 'css_selector_format',
                  errorMessage: this.$t('validations.selector_format')
                }
              ]
            },
            {
              id: 'skin_id',
              groupDescription: this.$t('skin_id.description'),
              type: 'text',
              placeholder: this.$t('skin_id.placeholder')
            }
          ].map(group => ({
            ...group,
            label: this.$t(`${group.id}.label`)
          }))
        }
      ];
    },
    editing() {
      return this.formObject.id;
    }
  },
  mounted() {
    this.orgFormObject = _.cloneDeep(this.productReviewsLink);
  },
  methods: {
    submit(formData) {
      formData.append(
        'smart_installation[page_type]',
        WidgetPageType.PRODUCT_DETAIL
      );
      formData.append(
        'smart_installation[device_type]',
        WidgetDeviceType.MOBILE
      );

      if (this.editing) {
        api
          .patch(`/smart_installations/${this.formObject.id}`, formData, {
            successMessage: this.$t('app.saved')
          })
          .then(({ data }) => {
            this.eventBus.$emit('update', data.smart_installation);
            this.close(true);
          });
      } else {
        api
          .post('/smart_installations', formData, {
            successMessage: this.$t('app.created')
          })
          .then(({ data }) => {
            this.eventBus.$emit('save', data.smart_installation);
            this.close(true);
          });
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_new": "상품 리뷰 리스트 위젯 팝업 설치 추가",
  "title_edit": "상품 리뷰 리스트 위젯 팝업 설치 설정",
  "device_type": {
    "label": "디바이스 선택"
  },
  "page_type": {
    "label": "설치 페이지"
  },
  "element_selector": {
    "label": "설치 위치 입력",
    "placeholder": "설치 위치를 입력해주세요."
  },
  "page_path": {
    "label": "설치 페이지 URL"
  },
  "skin_id": {
    "label": "적용 스킨 정보",
    "placeholder": "스킨 정보를 입력해주세요.",
    "description": "입력하지 않을 경우 모든 스킨에 적용됩니다."
  }
}
</i18n>
