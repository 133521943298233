var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"BizMessageTemplatesTable"},[_c('AppHeading',{attrs:{"label":_vm.title}}),(!_vm.isApproved)?_c('div',{staticClass:"BizMessageTemplatesTable__table-description"},[_vm._v(" "+_vm._s(_vm.$t('unapproved_template_description'))+" ")]):_vm._e(),_c('AppTable',_vm._b({scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var row = ref.row;
var column = ref.column;
var rowIndex = ref.rowIndex;
return [(column.id === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('app.show_detail')},on:{"click":function($event){return _vm.clickShowTemplate(row.id)}}}),(_vm.isApproved)?[(_vm.isGroupTemplate)?_c('AppButton',{attrs:{"label":_vm.$t('app.edit')},on:{"click":function($event){return _vm.clickEditTemplate(row.id)}}}):_c('AppButton',{attrs:{"button-style":"red-outline","label":_vm.$t('app.delete'),"disabled":row.is_selected,"tooltip":row.is_selected ? _vm.$t('selected_not_deletable') : null},on:{"click":function($event){return _vm.clickDeleteTemplate(row.id)}}})]:_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"menu-items":[
            (_vm.isInspectionRequestable(row.inspection_status.value)
                ? [
                    {
                      label: _vm.$t('request_inspection'),
                      disabled: _vm.isInspectionRejected(
                        row.inspection_status.value
                      ),
                      tooltip: _vm.isInspectionRejected(
                        row.inspection_status.value
                      )
                        ? _vm.$t('rejected_tooltip')
                        : null,
                      clickHandler: function (ref) {
                        var close = ref.close;

                        close();
                        _vm.clickRequestInspection(row.id);
                      }
                    }
                  ]
                : _vm.isInspectionRequested(row.inspection_status.value)
                ? [
                    {
                      label: _vm.$t('cancel_inspection'),
                      clickHandler: function (ref) {
                        var close = ref.close;

                        close();
                        _vm.clickCancelInspection(row.id);
                      }
                    }
                  ]
                : []).concat( [{
                label: _vm.$t('app.edit'),
                disabled: !_vm.isEditable(rowIndex),
                tooltip: _vm.isEditable(rowIndex)
                  ? null
                  : _vm.$t('edit_tooltip_disabled'),
                clickHandler: function (ref) {
                  var close = ref.close;

                  close();
                  _vm.clickEditTemplate(row.id);
                }
              }]
            ),
            [
              {
                label: _vm.$t('app.delete'),
                style: 'danger',
                disabled: row.is_selected,
                tooltip: row.is_selected
                  ? _vm.$t('selected_not_deletable')
                  : null,
                clickHandler: function (ref) {
                  var close = ref.close;

                  close();
                  _vm.clickDeleteTemplate(row.id);
                }
              }
            ]
          ]}})]:(column.id === 'admin')?[(_vm.isSuperAdminAccessible)?_c('div',{staticClass:"super-admin__item"},[(_vm.isInspectionRequested(row.inspection_status.value))?_c('AppButton',{attrs:{"label":"승인"},on:{"click":function($event){return _vm.clickApprove(row.id)}}}):_vm._e(),(_vm.isInspectionRequested(row.inspection_status.value))?_c('AppButton',{attrs:{"label":"반려"},on:{"click":function($event){return _vm.clickReject(row.id)}}}):_vm._e()],1):_vm._e()]:_vm._e()]}}])},'AppTable',{ columns: _vm.columns, rows: _vm.rows },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }