import api from '@/lib/api';
import BizMessageProfileStatus from '@/enums/BizMessageProfileStatus';
import BizMessageStatus from '@/enums/BizMessageStatus';

const state = {
  bizMessageProfile: {
    yellow_id: null,
    biz_message_status: BizMessageStatus.NONE,
    status: BizMessageProfileStatus.NONE,
    created_at: null
  }
};

const mutations = {
  SET_BIZ_MESSAGE_PROFILE(state, bizMessageProfile) {
    state.bizMessageProfile = bizMessageProfile;
  }
};

const actions = {
  fetchProfile: ({ commit }) => {
    return api
      .get('/biz_message/profile')
      .then(({ data }) => commit('SET_BIZ_MESSAGE_PROFILE', data.profile));
  },
  createProfile: ({ commit }, { data, successMessage }) => {
    return api
      .post('/biz_message/profile', data, { successMessage, silent: true })
      .then(({ data }) => commit('SET_BIZ_MESSAGE_PROFILE', data.profile));
  },
  addGroupProfile: ({ state, commit }, successMessage) => {
    return api
      .patch('/biz_message/profile/add_group_profile', {}, { successMessage })
      .then(({ data }) => {
        commit('SET_BIZ_MESSAGE_PROFILE', {
          ...state.bizMessageProfile,
          grouped: data.grouped
        });
      });
  },
  deleteProfile: ({ commit }, successMessage) => {
    return api
      .delete('/biz_message/profile', { successMessage })
      .then(({ data }) => commit('SET_BIZ_MESSAGE_PROFILE', data.profile));
  }
};

const getters = {
  yellowId: ({ bizMessageProfile }) => bizMessageProfile.yellow_id
};

export default { namespaced: true, state, getters, mutations, actions };
