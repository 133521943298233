<template>
  <AppAjaxContent :is-loading="isLoading" class="MyWrittenReviewsCount">
    <AppRadioLabel
      class="MyWrittenReviewsCount__radio-label"
      :label="$t('my_written_reviews_count.title')"
      :tooltip="$t('my_written_reviews_count.tooltip')"
      :checked="false"
    />
    <AppSearchbar>
      <AppButton
        button-style="grey-outline"
        :label="$t('smart_installation.create')"
        @click="openMyWrittenReviewsCountFormDialog"
      />
    </AppSearchbar>
    <AppTable
      table-id="my-written-reviews-count-table"
      :columns="COLUMNS"
      :rows="rows"
      :event-bus="tableEventBus"
    >
      <template #cell="{ column, rowIndex }">
        <div v-if="column.id === 'feature'">
          <AppButton
            :label="$t('app.settings')"
            @click="openMyWrittenReviewsCountSettings(rowIndex)"
          />
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            @click="clickDeleteMyWrittenReviewsCount(rowIndex)"
          />
        </div>
      </template>
    </AppTable>
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import api from '@/lib/api';
import { mapActions } from 'vuex';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import SmartInstallationType from '@/enums/SmartInstallationType';
import SmartInstallationHelper from '@/mixins/SmartInstallationHelper';

export default {
  name: 'MyWrittenReviewsCount',
  mixins: [SmartInstallationHelper],
  data() {
    return {
      myWrittenReviewsCounts: [],
      isLoading: true,
      tableEventBus: new Vue(),
      COLUMNS: [
        'installation_id',
        'device_type',
        'page_type',
        'element_selector',
        'skin_id',
        'display_format',
        'feature'
      ].map(id => ({
        id,
        label: this.$t(`table_head.${id}`)
      }))
    };
  },
  computed: {
    rows() {
      return this.myWrittenReviewsCounts.map(myWrittenReviewsCount => ({
        installation_id: myWrittenReviewsCount.id,
        device_type: WidgetDeviceType.t(myWrittenReviewsCount.device_type),
        page_type: this.getPageTypeOrPath(myWrittenReviewsCount),
        skin_id: this.formatSkinId(myWrittenReviewsCount.skin_id),
        display_format: myWrittenReviewsCount.reviews_count_display_format,
        element_selector: myWrittenReviewsCount.element_selector
      }));
    }
  },
  mounted() {
    api
      .get('/smart_installations', {
        params: {
          installation_type: SmartInstallationType.MY_WRITTEN_REVIEWS_COUNT
        }
      })
      .then(
        ({ data }) => (this.myWrittenReviewsCounts = data.smart_installations)
      )
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openMyWrittenReviewsCountFormDialog() {
      this.openDialog(['MyWrittenReviewsCountFormDialog']).then(eventBus =>
        eventBus.$on('save', myWrittenReviewsCount => {
          this.myWrittenReviewsCounts.push(myWrittenReviewsCount);
          this.$nextTick(() => {
            this.tableEventBus.$emit(
              'focus-row',
              this.myWrittenReviewsCounts.length - 1
            );
          });
        })
      );
    },
    openMyWrittenReviewsCountSettings(index) {
      const myWrittenReviewsCount = this.myWrittenReviewsCounts[index];
      this.openDialog([
        'MyWrittenReviewsCountFormDialog',
        { myWrittenReviewsCount }
      ]).then(eventBus =>
        eventBus.$on('update', myWrittenReviewsCount => {
          Vue.set(this.myWrittenReviewsCounts, index, myWrittenReviewsCount);
        })
      );
    },
    clickDeleteMyWrittenReviewsCount(index) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      api
        .delete(
          `/smart_installations/${this.myWrittenReviewsCounts[index].id}`,
          {
            successMessage: this.$t('app.deleted')
          }
        )
        .then(() => {
          Vue.delete(this.myWrittenReviewsCounts, index);
        });
    },
    formatSkinId(skin_id) {
      return skin_id || this.$t('smart_installations.all_skin');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppRadioLabel__icon {
  color: $color-grey-50;
}

.MyWrittenReviewsCount__radio-label {
  font-weight: 600;
  margin-top: 30px;
  margin-left: -8px;
  margin-bottom: 4px;
}

::v-deep {
  .my-written-reviews-count-table__installation-id,
  .my-written-reviews-count-table__device-type,
  .my-written-reviews-count-table__page-type,
  .my-written-reviews-count-table__skin-id,
  .my-written-reviews-count-table__element-selector,
  .my-written-reviews-count-table__display-format,
  .my-written-reviews-count-table__feature {
    min-width: 132px;
  }
  .my-written-reviews-count-table {
    table-layout: fixed;
    margin-top: 12px;
  }
}
</style>

<i18n locale="ko">
{
  "table_head": {
    "installation_id": "설치 ID",
    "device_type": "디바이스",
    "page_type": "설치 페이지",
    "element_selector": "설치 위치",
    "skin_id": "적용 스킨",
    "display_format": "표시 정보",
    "feature": "기능"
  },
  "smart_installation": {
    "create": "+ 간편 설치 추가"
  },
  "my_written_reviews_count": {
    "tooltip": "쇼핑몰 내가 작성한 리뷰 수 노출 영역에 크리마 리뷰 수가 노출되도록 설치할 수 있습니다.",
    "title": "내가 작성한 리뷰 수 교체 설치"
  }
}
</i18n>
