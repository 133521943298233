var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Controls")]),_c('AppCheckbox',{attrs:{"label":"isSubmitting"},model:{value:(_vm.isSubmitting),callback:function ($$v) {_vm.isSubmitting=$$v},expression:"isSubmitting"}}),_c('h3',[_vm._v("Groups (1 Untitled Section)")]),_c('AppForm',{attrs:{"id":"form1","is-submitting":_vm.isSubmitting,"submit-button":"","sections":[{ groups: _vm.groups }]}}),_c('h3',[_vm._v("Multi Sections")]),_c('AppForm',_vm._b({attrs:{"id":"form2","sections":[
      {
        id: 'section1',
        label: 'Section Title #1',
        hint: 'Section Hint #1',
        groups: _vm.groups
      },
      {
        id: 'section2',
        label: 'Section Title #2',
        hint: 'Section Hint #2',
        groups: _vm.groups
      }
    ]}},'AppForm',{ isSubmitting: _vm.isSubmitting, submitButton: _vm.submitButton },false)),_c('h3',[_vm._v("Multi Sections - Narrow")]),_c('AppForm',_vm._b({attrs:{"id":"form6","form-style":"narrow","sections":[
      {
        id: 'section1',
        label: 'Section Title #1',
        hint: 'Section Hint #1',
        groups: _vm.groups
      },
      {
        id: 'section2',
        label: 'Section Title #2',
        hint: 'Section Hint #1',
        groups: _vm.groups
      }
    ]}},'AppForm',{ isSubmitting: _vm.isSubmitting, submitButton: _vm.submitButton },false)),_c('h3',[_vm._v("Multi Sections - Horizontal")]),_c('AppForm',_vm._b({attrs:{"id":"form3","form-style":"hor","sections":[
      {
        id: 'section1',
        label: 'Section Title #1',
        hint: 'Section Hint #1',
        groups: _vm.groups
      },
      {
        id: 'section2',
        label: 'Section Title #2',
        hint: 'Section Hint #1',
        groups: _vm.groups
      }
    ]}},'AppForm',{ isSubmitting: _vm.isSubmitting, submitButton: _vm.submitButton },false)),_c('h3',[_vm._v("Untitled Section")]),_c('AppForm',_vm._b({attrs:{"id":"form4","sections":[
      { id: 'section1', groups: _vm.groups },
      { id: 'section2', label: 'Other Options', groups: _vm.groups }
    ]}},'AppForm',{ isSubmitting: _vm.isSubmitting, submitButton: _vm.submitButton },false)),_c('h3',[_vm._v("Untitled Section - Horizontal")]),_c('AppForm',_vm._b({attrs:{"id":"form5","form-style":"hor","sections":[
      { id: 'section1', label: 'Section Title #1', groups: _vm.groups },
      { id: 'section2', groups: _vm.groups }
    ]}},'AppForm',{ isSubmitting: _vm.isSubmitting, submitButton: _vm.submitButton },false)),_c('h3',[_vm._v("Section with Hidden Title")]),_c('AppForm',_vm._b({attrs:{"id":"form6","sections":[
      { id: 'section1', hideLabel: true, label: 'Section Title #1', groups: _vm.groups },
      { id: 'section2', hideLabel: true, groups: _vm.groups }
    ]}},'AppForm',{ isSubmitting: _vm.isSubmitting, submitButton: _vm.submitButton },false)),_c('h3',[_vm._v("Group With Tip Box")]),_c('AppForm',{attrs:{"sections":[
      {
        groups: [
          { id: 'g1', label: 'group label', tipMessage: 'group tip box' }
        ]
      }
    ]}}),_c('h3',[_vm._v("Custom Description")]),_c('AppForm',{attrs:{"sections":[{ groups: [{ id: 'g1', label: 'group label' }] }]},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',{staticClass:"AppForm__description"},[_c('AppExternalLink',{staticClass:"AppForm__description-link",attrs:{"to":"https://cre.ma"}},[_vm._v("Description Link")])],1)]},proxy:true}])}),_c('h3',[_vm._v("Inputs")]),_c('AppForm',_vm._b({attrs:{"id":"form_with_inputs","object-id":"object","form-style":"hor","sections":[
      {
        id: 'static',
        label: 'static',
        groups: [
          {
            id: 'static1',
            type: 'static',
            label: 'Static Text'
          },
          {
            id: 'static2',
            type: 'static',
            label: 'Static Text with HTML'
          },
          {
            id: 'static3',
            type: 'static',
            label: 'Static with description',
            description:
              'This is a description. Use this to describe how to use the above input field.'
          },
          {
            id: 'static4',
            type: 'static',
            label: 'Static with group description',
            groupDescription:
              'This is a group description. Use this to describe about this group.'
          },
          {
            id: 'static5',
            type: 'static',
            label: 'Static with detail button group description',
            groupDescription:
              'This is a detail button group description. Use this to describe about this group with detail info.',
            groupDescriptionDetailClickHandler: _vm.groupDescriptionDetailClickHandler
          },
          {
            id: 'static6',
            type: 'static',
            label: 'Static with hint',
            hint: 'This is a **hint**'
          },
          {
            id: 'static7',
            type: 'static',
            label: 'Required Static',
            required: true
          },
          {
            id: 'static8',
            type: 'static',
            label: 'Required And Tooltip Static',
            required: true,
            groupTooltip: 'i am tooltip'
          },
          {
            id: 'static9',
            type: 'static',
            label: 'Required And Badge Static',
            required: true,
            titleBadge: {
              label: 'i am badge',
              badgeStyle: 'red',
              badgeSize: 'small'
            }
          },
          {
            id: 'static10',
            type: 'static',
            label: 'Static with everything',
            required: true,
            description: 'This is a description',
            groupDescription: 'This is a group description',
            groupDescriptionDetailClickHandler: _vm.groupDescriptionDetailClickHandler,
            hint: 'This is a hint'
          },
          {
            id: 'static11',
            type: 'static',
            label: 'Static with group description box',
            groupDescriptionBox: 'This is a group description'
          }
        ]
      },
      {
        id: 'list',
        label: 'list',
        groups: [{ id: 'list', type: 'list', label: 'list' }]
      },
      {
        id: 'text',
        label: 'text',
        groups: [
          {
            id: 'text1',
            type: 'text',
            label: 'text'
          },
          {
            id: 'text2',
            type: 'text',
            label: 'text with maxlength=2',
            maxlength: 2
          },
          {
            id: 'text3',
            type: 'text',
            label: 'text with placeholder(nullable)',
            placeholder: 'This is a placeholder'
          },
          {
            id: 'text4',
            type: 'text',
            digits: 3,
            label: 'text with invalid=true',
            invalid: true
          },
          {
            id: 'text5',
            type: 'text',
            digits: 3,
            label: 'text with invalid=[string]',
            invalid: 'can display error message on first load'
          },
          {
            id: 'text6',
            type: 'text',
            digits: 3,
            label: 'text with valdiate=[required]',
            validate: ['required']
          }
        ]
      },
      {
        id: 'number',
        label: 'number',
        groups: [
          {
            id: 'number1',
            type: 'number',
            digits: 3,
            label: 'number'
          },
          {
            id: 'number2',
            type: 'number',
            digits: 2,
            label: 'number with digits=2'
          },
          {
            id: 'number3',
            type: 'number',
            digits: 15,
            label: 'number with placeholder',
            placeholder: 'This is a placeholder'
          },
          {
            id: 'number4',
            type: 'number',
            digits: 3,
            label: 'number with invalid=true',
            invalid: true
          },
          {
            id: 'number5',
            type: 'number',
            digits: 3,
            label: 'number with invalid=[string]',
            invalid: 'can display error message on first load'
          },
          {
            id: 'number6',
            type: 'number',
            digits: 3,
            label: 'number with validate=[required]',
            validate: ['required']
          }
        ]
      },
      {
        id: 'textarea',
        label: 'textarea',
        groups: [
          {
            id: 'textarea1',
            type: 'textarea',
            label: 'textarea',
            autosize: false
          },
          {
            id: 'textarea2',
            type: 'textarea',
            label: 'textarea with rows=5',
            rows: 5,
            autosize: false
          },
          {
            id: 'textarea3',
            type: 'textarea',
            label: 'textarea with placeholder(nullable)',
            placeholder: 'This is a placeholder',
            autosize: false
          },
          {
            id: 'textarea4',
            type: 'textarea',
            label: 'textarea with autosize(nullable)'
          },
          {
            id: 'textarea5',
            type: 'textarea',
            label: 'textarea with invalid=true',
            invalid: true
          },
          {
            id: 'textarea6',
            type: 'textarea',
            label: 'textarea with invalid=[string]',
            invalid: 'can display error message on first load'
          },
          {
            id: 'textarea7',
            type: 'textarea',
            label: 'textarea with valdiate=[required]',
            validate: ['required']
          }
        ]
      },
      {
        id: 'checkbox',
        label: 'checkbox',
        groups: [
          {
            id: 'checkbox1',
            type: 'checkbox',
            label: 'Checkbox without description'
          },
          {
            id: 'checkbox2',
            type: 'checkbox',
            label: 'Checkbox with description',
            description: 'This is a checkbox'
          },
          {
            id: 'checkbox3',
            type: 'checkbox',
            label: 'Checkbox with invalid=true',
            invalid: true
          },
          {
            id: 'checkbox4',
            type: 'checkbox',
            label: 'checkbox with valdiate=[required]',
            validate: ['required']
          }
        ]
      },
      {
        id: 'hash_select_button',
        label: 'hash_select_button',
        groups: [
          {
            id: 'hash_select_button',
            type: 'hash_select_button',
            label: 'hash_select_button',
            selectButtons: [
              {
                id: 'hash_select_button_sex',
                options: [
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' }
                ]
              },
              {
                id: 'hash_select_button_grade',
                options: [
                  { label: 'A', value: 'a' },
                  { label: 'B', value: 'b' },
                  { label: 'C', value: 'c' },
                  { label: 'D', value: 'd' },
                  { label: 'F', value: 'f' }
                ]
              }
            ]
          }
        ]
      },
      {
        id: 'hash_select_checkbox',
        label: 'hash_select_checkbox',
        groups: [
          {
            id: 'hash_select_checkbox',
            type: 'hash_select_checkbox',
            label: 'hash_select_checkbox',
            options: [
              {
                id: 'hash_select_checkbox1',
                label: 'option1'
              },
              {
                id: 'hash_select_checkbox2',
                label: 'option2'
              }
            ]
          }
        ]
      },
      {
        id: 'image',
        label: 'image',
        groups: [
          {
            id: 'image1',
            type: 'image',
            label: 'new image'
          },
          {
            id: 'image2',
            type: 'image',
            label: 'saved image',
            value:
              '//assets.cre.ma/p/users/00/03/67/13/24/image/v60x60_d36a17f095deb209.jpg'
          },
          {
            id: 'image3',
            type: 'image',
            label: 'image with invalid=true',
            invalid: true
          },
          {
            id: 'image4',
            type: 'image',
            label: 'image with invalid=[string]',
            invalid: 'can display error message on first load'
          },
          {
            id: 'image5',
            type: 'static_image',
            label: 'Static Image',
            value:
              '//assets.cre.ma/p/users/00/03/67/13/24/image/v60x60_d36a17f095deb209.jpg'
          }
        ]
      },
      {
        id: 'select',
        label: 'select',
        groups: [
          {
            id: 'select1',
            type: 'select',
            label: 'select',
            options: _vm.options
          },
          {
            id: 'select2',
            type: 'select',
            label: 'select with placeholder',
            options: _vm.options,
            placeholder: 'Please Select'
          },
          {
            id: 'select3',
            type: 'select',
            label: 'select with string value',
            options: _vm.OPTIONS_WITH_STRING_VALUE,
            placeholder: 'Please Select'
          },
          {
            id: 'select4',
            type: 'select',
            label: 'select with invalid=true',
            options: _vm.OPTIONS_WITH_STRING_VALUE,
            placeholder: 'Please Select',
            invalid: true
          },
          {
            id: 'select5',
            type: 'select',
            label: 'select with invalid=[string]',
            options: _vm.OPTIONS_WITH_STRING_VALUE,
            placeholder: 'Please Select',
            invalid: 'can display error message on first load'
          },
          {
            id: 'select6',
            type: 'select',
            label: 'select with valdiate=[required]',
            options: _vm.OPTIONS_WITH_STRING_VALUE,
            placeholder: 'Please Select',
            validate: ['required']
          }
        ]
      },
      {
        id: 'select_filterable',
        label: 'select_filterable',
        groups: [
          {
            id: 'select_filterable1',
            type: 'select_filterable',
            label: 'select_filterable',
            options: _vm.options
          },
          {
            id: 'select_filterable2',
            type: 'select_filterable',
            label: 'select_filterable with placeholder',
            options: _vm.options,
            placeholder: 'Please Select'
          },
          {
            id: 'select_filterable3',
            type: 'select_filterable',
            label: 'select_filterable with string value',
            options: _vm.OPTIONS_WITH_STRING_VALUE,
            placeholder: 'Please Select'
          },
          {
            id: 'select_filterable4',
            type: 'select_filterable',
            label: 'select_filterable with invalid=true',
            options: _vm.OPTIONS_WITH_STRING_VALUE,
            placeholder: 'Please Select',
            invalid: true
          },
          {
            id: 'select_filterable5',
            type: 'select_filterable',
            label: 'select_filterable with invalid=[string]',
            options: _vm.OPTIONS_WITH_STRING_VALUE,
            placeholder: 'Please Select',
            invalid: 'can display error message on first load'
          },
          {
            id: 'select_filterable6',
            type: 'select_filterable',
            label: 'select_filterable with valdiate=[required]',
            options: _vm.OPTIONS_WITH_STRING_VALUE,
            placeholder: 'Please Select',
            validate: ['required']
          }
        ]
      },
      {
        id: 'select_hour',
        label: 'select_hour',
        groups: [
          { id: 'select_hour', type: 'select_hour', label: 'select_hour' }
        ]
      },
      {
        id: 'select_radio',
        label: 'select_radio',
        groups: [
          {
            id: 'select_radio1',
            type: 'select_radio',
            label: 'select_radio',
            options: _vm.options
          },
          {
            id: 'select_radio2',
            type: 'select_radio',
            label: 'select_radio with disabled select',
            options: _vm.options
          },
          {
            id: 'select_radio3',
            type: 'select_radio',
            label: 'select_radio with string value',
            options: _vm.OPTIONS_WITH_STRING_VALUE
          },
          {
            id: 'select_radio4',
            type: 'select_radio',
            label: 'select_radio with invalid=true',
            options: _vm.OPTIONS_WITH_STRING_VALUE,
            invalid: true
          },
          {
            id: 'select_radio5',
            type: 'select_radio',
            label: 'select_radio with invalid=[string]',
            options: _vm.OPTIONS_WITH_STRING_VALUE,
            invalid: 'can display error message on first load'
          }
        ]
      },
      {
        id: 'tags',
        label: 'tags',
        groups: [
          {
            id: 'tags1',
            type: 'tags',
            label: 'tags'
          }
        ]
      },
      {
        id: 'multiple_select',
        label: 'multiple_select',
        groups: [
          {
            id: 'multiple_select1',
            type: 'multiple_select',
            label: 'multiple_select',
            options: _vm.multipleOptions
          },
          {
            id: 'multiple_select2',
            type: 'multiple_select',
            label: 'multiple_select with selected value',
            options: _vm.multipleOptions
          },
          {
            id: 'multiple_select3',
            type: 'multiple_select',
            label: 'multiple_select with string value',
            options: _vm.MULTIPLE_OPTIONS_WITH_STRING_VALUE
          }
        ]
      },
      {
        id: 'multiple_select_box',
        label: 'multiple_select_box',
        groups: [
          {
            id: 'box_multiple_select1',
            type: 'multiple_select_box',
            label: 'multiple_select_box',
            options: _vm.multipleOptions
          },
          {
            id: 'box_multiple_select2',
            type: 'multiple_select_box',
            label: 'multiple_select_box with selected option',
            options: _vm.multipleOptions
          },
          {
            id: 'box_multiple_select3',
            type: 'multiple_select_box',
            label: 'multiple_select_box with many option',
            options: _vm.multipleOptionsLong
          },
          {
            id: 'box_multiple_select4',
            type: 'multiple_select_box',
            label: 'multiple_select_box with string value',
            options: _vm.MULTIPLE_OPTIONS_WITH_STRING_VALUE
          },
          {
            id: 'box_multiple_select5',
            type: 'multiple_select_box',
            label: 'multiple_select_box with invalid=true',
            options: _vm.multipleOptions,
            invalid: true
          },
          {
            id: 'box_multiple_select6',
            type: 'multiple_select_box',
            label: 'multiple_select_box with validate: [required]',
            options: _vm.multipleOptions,
            validate: ['required']
          },
          {
            id: 'box_multiple_select7',
            type: 'multiple_select_box',
            label: 'multiple_select_box with new label',
            options: _vm.multipleOptions,
            searchOptionsLabel: 'AAA',
            selectedOptionsLabel: 'BBB'
          }
        ]
      },
      {
        id: 'multiple_select_checkbox',
        label: 'multiple_select_checkbox',
        groups: [
          {
            id: 'multiple_select_checkbox1',
            type: 'multiple_select_checkbox',
            label: 'multiple_select_checkbox',
            options: _vm.multipleOptions
          },
          {
            id: 'multiple_select_checkbox2',
            type: 'multiple_select_checkbox',
            label: 'multiple_select_checkbox with selected option',
            options: _vm.multipleOptions
          },
          {
            id: 'multiple_select_checkbox3',
            type: 'multiple_select_checkbox',
            label: 'multiple_select_checkbox with string value',
            options: _vm.MULTIPLE_OPTIONS_WITH_STRING_VALUE
          },
          {
            id: 'multiple_select_checkbox4',
            type: 'multiple_select_checkbox',
            label: 'multiple_select_checkbox with invalid',
            options: _vm.multipleOptions,
            invalid: true
          },
          {
            id: 'multiple_select_checkbox5',
            type: 'multiple_select_checkbox',
            label: 'multiple_select_checkbox with validate: [required]',
            options: _vm.multipleOptions,
            validate: ['required']
          }
        ]
      },
      {
        id: 'select_product',
        label: 'select_product',
        groups: [
          {
            id: 'select_product1',
            type: 'select_product',
            multiple: true,
            label: 'select_product',
            title: 'Select Product #1'
          },
          {
            id: 'select_product2',
            type: 'select_product',
            multiple: true,
            label: 'select_product with selected value',
            title: 'Select Product #2',
            products: [
              { id: 1, name: 'Product #1' },
              { id: 2, name: 'Product #2' }
            ]
          }
        ]
      },
      {
        id: 'tip_box',
        label: 'tip_box',
        groups: [
          {
            id: 'tip_box',
            type: 'tip_box',
            label: 'tip_box',
            value: 'This is TipBox'
          }
        ]
      },
      {
        id: 'alert_box',
        label: 'alert_box',
        groups: [
          {
            id: 'alert_box',
            type: 'alert_box',
            label: 'alert_box',
            title: 'AlertBox Title',
            value: 'This is AlertBox\nThis is AlertBox'
          }
        ]
      },
      {
        id: 'code_editor',
        label: 'code_editor',
        groups: [
          {
            id: 'code_editor_css',
            type: 'code_editor',
            label: 'code_editor with CSS',
            lang: 'css'
          },
          {
            id: 'code_editor_javascript',
            type: 'code_editor',
            label: 'code_editor with JavaScript',
            lang: 'javascript'
          }
        ]
      },
      {
        id: 'color',
        label: 'color',
        groups: [
          {
            id: 'color',
            type: 'color',
            label: 'color'
          },
          {
            id: 'color_with_intial_value',
            type: 'color',
            label: 'color_with_intial_value'
          }
        ]
      },
      {
        id: 'disabled',
        label: 'disabled',
        groups: [
          {
            id: 'label_disabled',
            labelDisabled: true,
            label: 'label_disabled'
          },
          {
            id: 'disabled',
            label: 'disabled',
            disabled: true,
            hint: 'This is a hint',
            groupDescription: 'This is a group description'
          }
        ]
      },
      {
        id: 'advanced-groups',
        label: 'advanced-groups',
        advancedGroups: [
          {
            id: 'advanced-group',
            label: 'This is advanced-groups!',
            type: 'text',
            validate: [
              {
                rule: function (v) { return v; },
                errorMessage: 'Validation error'
              }
            ]
          }
        ],
        groups: [
          {
            type: 'text',
            label: 'basic group text'
          },
          {
            type: 'static',
            label: 'basic group static'
          }
        ]
      },
      {
        id: 'switch',
        label: 'switch',
        groups: [
          {
            id: 'switch',
            type: 'switch',
            label: 'switch'
          }
        ]
      }
    ]},on:{"change-group":_vm.change},model:{value:(_vm.formObject),callback:function ($$v) {_vm.formObject=$$v},expression:"formObject"}},'AppForm',{ isSubmitting: _vm.isSubmitting, submitButton: _vm.submitButton },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }