<template>
  <div class="TargetWidgetDesignImageRatioField">
    <div class="TargetWidgetDesignImageRatioField__input">
      <AppNumberInput
        :value="formObject[imageXId]"
        :name="`widget_design_attributes[${imageXId}]`"
        allow-decimal
        :disabled="disabled"
        :invalid="!!errors[imageXId]"
        :digits="3"
        @blur="update(imageXId, formObject[imageXId])"
        @change="update(imageXId, $event)"
      />
    </div>
    <span class="TargetWidgetDesignImageRatioField__middle-colon">
      :
    </span>
    <div class="TargetWidgetDesignImageRatioField__input">
      <AppNumberInput
        :value="formObject[imageYId]"
        :name="`widget_design_attributes[${imageYId}]`"
        :disabled="disabled"
        :invalid="!!errors[imageYId]"
        :digits="3"
        allow-decimal
        @blur="update(imageYId, formObject[imageYId])"
        @change="update(imageYId, $event)"
      />
    </div>
    <AppFormError :error="errors[imageXId]" />
    <AppFormError :error="errors[imageYId]" />
  </div>
</template>

<script>
export default {
  name: 'TargetWidgetDesignImageRatioField',
  props: {
    formObject: { type: Object, required: true },
    imageXId: { type: String, required: true },
    imageYId: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    errors: { type: Object, required: true }
  },
  methods: {
    update(id, value) {
      this.$emit('update', { id, value });
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetWidgetDesignImageRatioField__input {
  display: inline-block;
  vertical-align: top;
}

.TargetWidgetDesignImageRatioField__middle-colon {
  line-height: 32px;
}
</style>
