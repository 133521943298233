<template>
  <ul class="AppRate">
    <li v-for="(star, i) in stars" :key="i" class="AppRate__item">
      <AppSvgIcon
        name="icon-star"
        :class="['AppRate__icon', { 'AppRate__icon--fill': star }]"
      />
    </li>
  </ul>
</template>

<script>
import AppSvgIcon from './AppSvgIcon';

export default {
  name: 'AppRate',
  components: { AppSvgIcon },
  props: {
    score: {
      type: Number,
      required: true
    }
  },
  computed: {
    stars() {
      return new Array(5).fill(true, 0, this.score);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppRate {
  line-height: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.AppRate__item {
  display: inline-block;

  & + & {
    margin-left: 2px;
  }
}

.AppRate__icon {
  stroke: $color-mango;
  fill: transparent;

  &--fill {
    fill: $color-mango;
  }
}
</style>
