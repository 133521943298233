var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.productDisplayName,"form-props":Object.assign({}, _vm.formProps,
    {formStyle: 'narrow',
    submitLabel: _vm.$t('submit_button.submit'),
    submittingLabel: _vm.$t('submit_button.submitting')})},scopedSlots:_vm._u([{key:"group",fn:function(ref){
  var id = ref.id;
  var errors = ref.errors;
return [(id === 'schedule')?[_c('AppDatePicker',{staticClass:"AppForm__group-field--mt8 DisplayProductDisplayScheduleDialog__input",attrs:{"name":"product_display[display_date]","min-date":_vm.nextAvailableTime.toVal()},model:{value:(_vm.formObject.display_date),callback:function ($$v) {_vm.$set(_vm.formObject, "display_date", $$v)},expression:"formObject.display_date"}}),_c('AppSelectHour',{staticClass:"AppForm__group-field--mt8 DisplayProductDisplayScheduleDialog__input",attrs:{"name":"product_display[display_hour]","invalid":!!errors.display_hour},on:{"change":function($event){return _vm.validateField('display_hour')}},model:{value:(_vm.formObject.display_hour),callback:function ($$v) {_vm.$set(_vm.formObject, "display_hour", $$v)},expression:"formObject.display_hour"}}),_c('AppFormError',{attrs:{"error":errors.display_hour}})]:_vm._e()]}},{key:"group-sub-item",fn:function(ref){
  var group = ref.group;
  var value = ref.value;
  var errors = ref.errors;
  var checked = ref.checked;
return [(group.id === 'schedule_type')?[(value === 'schedule')?[_c('div',[_vm._v(_vm._s(_vm.$t('schedule.description')))]),_c('AppDatePicker',{staticClass:"AppForm__group-field DisplayProductDisplayScheduleDialog__input",attrs:{"name":"product_display[display_date]","disabled":!checked,"min-date":_vm.nextAvailableTime.toVal()},model:{value:(_vm.formObject.display_date),callback:function ($$v) {_vm.$set(_vm.formObject, "display_date", $$v)},expression:"formObject.display_date"}}),_c('AppSelectHour',{staticClass:"AppForm__group-field DisplayProductDisplayScheduleDialog__input",attrs:{"name":"product_display[display_hour]","invalid":!!errors.display_hour,"disabled":!checked},on:{"change":function($event){return _vm.validateField('display_hour')}},model:{value:(_vm.formObject.display_hour),callback:function ($$v) {_vm.$set(_vm.formObject, "display_hour", $$v)},expression:"formObject.display_hour"}}),_c('AppFormError',{attrs:{"error":errors.display_hour}})]:(value === 'auto_schedule')?[_vm._v(" "+_vm._s(_vm.$t('auto_schedule.description'))+" "),_c('i18n',{staticClass:"AppForm__group-field",attrs:{"path":"auto_schedule.weekdays"}},[_c('AppWeekdaySelectButton',{staticClass:"AppForm__group-field DisplayProductDisplayScheduleDialog__weekdays",attrs:{"name":"product_display[execute_weekdays_value]","invalid":!!errors.execute_weekdays_value,"disabled":!checked},on:{"change":function($event){return _vm.validateField('execute_weekdays_value')}},model:{value:(_vm.formObject.execute_weekdays_value),callback:function ($$v) {_vm.$set(_vm.formObject, "execute_weekdays_value", $$v)},expression:"formObject.execute_weekdays_value"}})],1),_c('AppFormError',{attrs:{"error":errors.execute_weekdays_value}}),_c('div',{staticClass:"AppForm__group-field",domProps:{"innerHTML":_vm._s(_vm.$t('auto_schedule.description_html'))}})]:_vm._e()]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }