var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppButton',{staticClass:"DisplayProductDisplayUnits__add-button",attrs:{"label":_vm.$t('add_unit.label'),"disabled":_vm.cannotAddUnit,"tooltip":_vm.cannotAddUnit ? _vm.$t('add_unit.tooltip') : '',"bubble-message":_vm.units.length > 0 ? '' : _vm.$t('add_unit.bubble'),"bubble-side":"right"},on:{"click":_vm.addUnit}}),_c('AppTable',{staticClass:"DisplayProductDisplayUnits__table",attrs:{"table-id":"units","columns":_vm.columns,"rows":_vm.units},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
var rowIndex = ref.rowIndex;
return [(column.id === 'color')?[_c('DisplayCornerItemIndexColorBox',{attrs:{"color-index":row.color_index}})]:(column.id === 'filter')?[_c('DisplayUnitFilterCell',{attrs:{"unit":row,"unit-index":rowIndex,"product-option-types":_vm.productOptionTypes,"errors":_vm.errors},on:{"change":function($event){return _vm.updateUnit($event, rowIndex)},"validate-unit-field":function($event){return _vm.$emit('validate-unit-field', { column: $event, index: rowIndex })}}})]:(column.id === 'actions')?[_vm._l(([
            'id',
            'recent_days',
            'filter_type',
            'max_products',
            'stock_count',
            'stock_compare_type',
            'color_index',
            'sort_type',
            'sort_recent_days',
            'product_option_type_id'
          ]),function(key,i){return _c('input',{key:("key-" + i),attrs:{"type":"hidden","name":("product_display[units][][" + key + "]")},domProps:{"value":row[key]}})}),(row.product_option_value)?_c('input',{attrs:{"type":"hidden","name":"product_display[units][][product_option_value_id]"},domProps:{"value":row.product_option_value.id}}):_vm._e(),_vm._l((_vm.itemPositions[row.color_index] || []),function(position,i){return _c('input',{key:("position-" + (row.color_index) + "-" + i),attrs:{"type":"hidden","name":"product_display[units][][item_positions][]"},domProps:{"value":position}})}),_c('AppButtonDraggable',{on:{"sort":_vm.sortUnits}}),_c('AppButton',{attrs:{"label":_vm.$t('app.delete')},on:{"click":function($event){return _vm.deleteUnit(rowIndex, row.color_index)}}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }