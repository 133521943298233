import COMMON_SETTINGS from '@/constants/COMMON_SETTINGS';
import REVIEW_SETTINGS from '@/constants/REVIEW_SETTINGS';
import TARGET_SETTINGS from '@/constants/TARGET_SETTINGS';
import FIT_SETTINGS from '@/constants/FIT_SETTINGS';
import DISPLAY_SETTINGS from '@/constants/DISPLAY_SETTINGS';
import CHANNEL_SETTINGS from '@/constants/CHANNEL_SETTINGS';
import PRODUCT_SETTINGS from '@/constants/PRODUCT_SETTINGS';
import INSIGHT_SETTINGS from '@/constants/INSIGHT_SETTINGS';
import SMART_INSTALLATION_SETTINGS from '@/constants/SMART_INSTALLATION_SETTINGS';

const state = {
  settings: [
    ...COMMON_SETTINGS,
    ...TARGET_SETTINGS,
    ...FIT_SETTINGS,
    ...DISPLAY_SETTINGS,
    ...CHANNEL_SETTINGS,
    ...PRODUCT_SETTINGS,
    ...INSIGHT_SETTINGS,
    ...SMART_INSTALLATION_SETTINGS,
    ...REVIEW_SETTINGS
  ]
};

const isAccessible = (item, state, getters) => {
  if (item.superAdmin && !getters.isSuperAdminAccessible) return false;
  if (item.isAccessible && !item.isAccessible({ state, getters })) return false;
  return true;
};

const getters = {
  accessibleSettings(
    _state,
    { groupFilteredSettings },
    rootState,
    rootGetters
  ) {
    return groupFilteredSettings
      .filter(setting => isAccessible(setting, rootState, rootGetters))
      .map(setting => ({
        ...setting,
        sections: setting.sections
          .filter(section => isAccessible(section, rootState, rootGetters))
          .map(section => ({
            ...section,
            groups: section.groups.filter(group =>
              isAccessible(group, rootState, rootGetters)
            ),
            advancedGroups: section.advancedGroups?.filter(advancedGroup =>
              isAccessible(advancedGroup, rootState, rootGetters)
            )
          }))
      }));
  },
  groupFilteredSettings({ settings }, _getters, { session }) {
    const { review_pushable_to_shop_builder } = session.reviewSettings;
    const { shop_builder } = session.currentBrand;

    return settings.map(setting => ({
      ...setting,
      sections: setting.sections.map(section => ({
        ...section,
        groups: section.groups.filter(group => {
          switch (group.id) {
            case 'push_review_to_shop_builder':
              return review_pushable_to_shop_builder;
            case 'push_reviews_count_to_shop_builder':
            case 'update_godo_product_reviews_count':
              return shop_builder === 'godo';
            case 'enable_mileage_confirmation':
              return shop_builder === 'makeshop';
            case 'include_order_code_on_mileage_action':
              return shop_builder === 'cafe24';
            case 'sync_fit_size_data':
              return (
                shop_builder === 'makeshop' ||
                shop_builder === 'makeglob' ||
                shop_builder === 'cafe24' ||
                shop_builder === 'shopify'
              );
            case 'matching_review_with_order_code_in_my_orders':
              return (
                shop_builder === 'cafe24' ||
                shop_builder == 'none' ||
                shop_builder == 'godo' ||
                shop_builder == 'makeshop'
              );
            default:
              return true;
          }
        })
      }))
    }));
  }
};

export default {
  namespaced: true,
  state,
  getters
};
