<template>
  <AppModalForm
    :title="$t(couponId ? 'title_edit' : 'title_new')"
    :sub-title="campaignName"
    :is-loading="isLoading"
    :form-props="{ ...formProps, objectId: 'coupon', focusGroupId: 'name' }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="coupon[start_at]"
      :value="coupon.period_type === 'limited' ? coupon.start_at : ''"
    />
    <input
      type="hidden"
      name="coupon[end_at]"
      :value="coupon.period_type === 'limited' ? coupon.end_at : ''"
    />
    <input
      v-if="!formObject.enable_mobile_url"
      type="hidden"
      name="coupon[mobile_url]"
    />
    <template #group="{ id, errors }">
      <template v-if="id === 'campaigns_count'">
        <AppInfoBox>
          <b>{{ $t('campaigns_count_info', [coupon.campaigns_count]) }}</b>
        </AppInfoBox>
      </template>
      <template v-else-if="id === 'enable_mobile_url'">
        <div class="AppForm__group-field">
          <AppTextInput
            v-model="formObject.mobile_url"
            name="coupon[mobile_url]"
            :placeholder="$t('mobile_url_placeholder')"
            :disabled="!formObject.enable_mobile_url"
            @change="validateField('mobile_url')"
            @blur="validateField('mobile_url')"
          />
          <AppFormError :error="errors.mobile_url" />
        </div>
      </template>
    </template>
    <template #group-sub-item="{ group, value, checked, errors }">
      <template v-if="group.id === 'period_type'">
        <template v-if="value === 'limited'">
          <div>
            <AppDatePicker
              v-model="startDate"
              class="TargetCampaignSettingsCouponFormDialog__period-item"
              :invalid="!!errors.period"
              :disabled="!checked"
              @blur="validateField('period')"
              @change="validateField('period')"
            />
            <AppSelectHour
              v-model="startHour"
              class="TargetCampaignSettingsCouponFormDialog__period-item"
              :invalid="!!errors.period"
              :disabled="!checked"
              @blur="validateField('period')"
              @change="validateField('period')"
            />
            <div class="TargetCampaignSettingsCouponFormDialog__period-item">
              -
            </div>
            <AppDatePicker
              v-model="endDate"
              class="TargetCampaignSettingsCouponFormDialog__period-item"
              :invalid="!!errors.period"
              :disabled="!checked"
              @blur="validateField('period')"
              @change="validateField('period')"
            />
            <AppSelectHour
              v-model="endHour"
              class="TargetCampaignSettingsCouponFormDialog__period-item"
              :invalid="!!errors.period"
              :disabled="!checked"
              @blur="validateField('period')"
              @change="validateField('period')"
            />
          </div>
          <AppFormError :error="errors.period" />
        </template>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import moment from 'moment';
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

const datetimeToDate = datetime => moment(datetime).toVal();
const datetimeToTime = datetime => moment(datetime).format('HH:00');
const datetimeToHour = datetime => moment(datetime).hour();
const hourToTime = hour => `${hour.toString().padStart(2, '0')}:00`;
const toDatetime = (date, time) => moment(`${date} ${time}`).toISOString();

export default {
  name: 'TargetCampaignSettingsCouponFormDialog',
  mixins: [DialogFormView],
  props: {
    campaignId: { type: Number, required: true },
    campaignName: { type: String, required: true },
    couponId: { type: Number, default: null }
  },
  data() {
    return { isLoading: true };
  },
  computed: {
    coupon() {
      return this.formObject;
    },
    formSections() {
      const COUPON_NAME_LENGTH_LIMIT = 32;

      return [
        {
          groups: [
            ...(this.couponId ? [{ id: 'campaigns_count', label: '' }] : []),
            {
              id: 'name',
              label: this.$t('name'),
              type: 'text',
              placeholder: this.$t('name_placeholder'),
              validate: [
                'required',
                { rule: 'max_length', limit: COUPON_NAME_LENGTH_LIMIT }
              ]
            },
            {
              id: 'period_type',
              name: null,
              label: this.$t('period_type'),
              type: 'select_radio',
              options: [
                { label: this.$t('period_type_unlimited'), value: 'unlimited' },
                { label: this.$t('period_type_limited'), value: 'limited' }
              ],
              fields:
                this.coupon.period_type === 'limited'
                  ? [
                      {
                        id: 'period',
                        validate: [
                          {
                            rule: () =>
                              moment(this.coupon.end_at).diff(
                                moment(this.coupon.start_at)
                              ) > 0,
                            errorMessage: this.$t(
                              'period_end_at_should_be_later_than_start_at'
                            )
                          }
                        ]
                      }
                    ]
                  : []
            },
            {
              id: 'image',
              label: this.$t('image'),
              type: 'image',
              notRemovable: true,
              validate: ['required']
            },
            {
              id: 'pc_url',
              label: this.$t('pc_url'),
              type: 'url',
              placeholder: this.$t('pc_url_placeholder'),
              validate: ['required', 'url_format']
            },
            {
              id: 'enable_mobile_url',
              label: this.$t('enable_mobile_url'),
              required: !!this.formObject.enable_mobile_url,
              name: null,
              type: 'checkbox',
              fields: [
                {
                  id: 'mobile_url',
                  validate: this.formObject.enable_mobile_url
                    ? ['required', 'url_format']
                    : []
                }
              ]
            }
          ]
        }
      ];
    },
    startDate: {
      get() {
        return datetimeToDate(this.coupon.start_at);
      },
      set(date) {
        const time = datetimeToTime(this.coupon.start_at);
        this.$set(this.coupon, 'start_at', toDatetime(date, time));
      }
    },
    startHour: {
      get() {
        return datetimeToHour(this.coupon.start_at);
      },
      set(hour) {
        const date = datetimeToDate(this.coupon.start_at);
        const time = hourToTime(hour);
        this.$set(this.coupon, 'start_at', toDatetime(date, time));
      }
    },
    endDate: {
      get() {
        return datetimeToDate(this.coupon.end_at);
      },
      set(date) {
        const time = datetimeToTime(this.coupon.end_at);
        this.$set(this.coupon, 'end_at', toDatetime(date, time));
      }
    },
    endHour: {
      get() {
        return datetimeToHour(this.coupon.end_at);
      },
      set(hour) {
        const date = datetimeToDate(this.coupon.end_at);
        const time = hourToTime(hour);
        this.$set(this.coupon, 'end_at', toDatetime(date, time));
      }
    }
  },
  mounted() {
    const timestamp = moment()
      .startOf('day')
      .toISOString();
    if (this.couponId) {
      api
        .get(
          `/target/campaigns/${this.campaignId}/coupons/${this.couponId}/edit`
        )
        .then(
          ({ data }) =>
            (this.orgFormObject = {
              ...data.coupon,
              period_type: data.coupon.start_at ? 'limited' : 'unlimited',
              start_at: data.coupon.start_at || timestamp,
              end_at: data.coupon.end_at || timestamp,
              enable_mobile_url: !!data.coupon.mobile_url
            })
        )
        .finally(() => (this.isLoading = false));
    } else {
      this.isLoading = false;
      this.orgFormObject = {
        period_type: 'unlimited',
        start_at: timestamp,
        end_at: timestamp
      };
    }
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .request({
          url: this.couponId
            ? `/target/campaigns/${this.campaignId}/coupons/${this.couponId}`
            : `/target/campaigns/${this.campaignId}/coupons`,
          method: this.couponId ? 'patch' : 'post',
          data: formData,
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignSettingsCouponFormDialog__period-item {
  & + & {
    margin-left: 8px;
  }

  display: inline-block;
  vertical-align: middle;
}
</style>

<i18n locale="ko">
{
  "title_edit": "쿠폰/이벤트 설정",
  "title_new": "쿠폰/이벤트 추가",
  "campaigns_count_info": "설정을 변경하면 이 쿠폰을 사용중인 {0}개 캠페인에 변경 사항이 반영됩니다.",
  "name": "쿠폰/이벤트 이름",
  "name_placeholder": "이름을 입력해주세요.",
  "period_type": "적용 기간",
  "period_type_unlimited": "무제한",
  "period_type_limited": "설정 기간 동안",
  "period_end_at_should_be_later_than_start_at": "시작 날짜는 끝 날짜 이전이어야 합니다.",
  "image": "쿠폰/이벤트 이미지",
  "image_placeholder": "이미지를 등록해주세요.",
  "pc_url": "이동 URL",
  "pc_url_placeholder": "쿠폰/이벤트 이미지를 클릭하면 이동할 URL을 입력해주세요.",
  "enable_mobile_url": "모바일 환경에서 별도 이동 URL 사용",
  "mobile_url_placeholder": "모바일 환경에서 별도의 URL로 이동해야 하는 경우 입력해주세요."
}
</i18n>
