<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="productManagement.name"
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #group="{ id, disabled }">
      <template v-if="id === 'icon_ids'">
        <AppTable
          :class="[
            'AppForm__group-field',
            'AppForm__group-field--mt8',
            disabled ? 'AppForm__group-field--disabled' : ''
          ]"
          :columns="columns"
          :rows="rows"
        >
          <template #cell="{ column, row, value }">
            <template v-if="column.id === 'icons'">
              <AppProductIcon
                v-for="icon in value"
                :key="icon"
                class="DisplayIconManagementFormDialog__icon"
                :icon-id="icon"
              />
              <span v-if="!value.length">{{
                $t('form.icon_ids.icons.select_icon')
              }}</span>
            </template>
            <template v-else-if="column.id === 'actions'">
              <AppButton
                :label="$t('form.icon_ids.button.select_icon')"
                :disabled="disabled"
                @click="openSelectIconDialog(row.id, row.icons)"
              />
            </template>
          </template>
        </AppTable>
      </template>
    </template>
    <template #group-sub-item="{ group, value }">
      <template v-if="group.id === 'use_icon_overflow_management' && value">
        <AppButton
          :label="$t('form.use_icon_overflow_management.button')"
          :disabled="group.disabled"
          type="external_link"
          :to="pathWithBrandParams('/v2/product/icons')"
        />
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import ShopBuilder from '@/enums/ShopBuilder';

export default {
  name: 'DisplayIconManagementFormDialog',
  mixins: [DialogFormView],
  props: { item: { type: Object, required: true } },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('displayProductManagement', ['productManagement']),
    ...mapGetters(['pathWithBrandParams']),
    formSections() {
      const { formObject } = this;
      const disabled = !formObject.use_icon_management;

      return [
        {
          groups: [
            {
              id: 'use_icon_management',
              type: 'checkbox',
              description: this.$t('form.use_icon_management.description_html')
            },
            {
              id: 'icon_ids',
              required: this.formObject.use_icon_management,
              disabled,
              labelDisabled: disabled,
              validate: formObject.use_icon_management
                ? [
                    {
                      rule: () =>
                        formObject.pc_icon_ids.length > 0 ||
                        formObject.mobile_icon_ids.length > 0,
                      errorMessage: this.$t('validations.required')
                    }
                  ]
                : []
            },
            {
              id: 'use_icon_overflow_management',
              disabled,
              labelDisabled: disabled,
              groupDescription: this.$t(
                'form.use_icon_overflow_management.description',
                {
                  shop_builder: ShopBuilder.tk(this.currentBrand.shop_builder)
                }
              ),
              type: 'select_radio',
              options: [true, false].map(value => ({
                label: this.$t(`form.use_icon_overflow_management.${value}`),
                value
              }))
            }
          ].map(item => ({ ...item, label: this.$t(`form.${item.id}.label`) }))
        }
      ];
    },
    columns() {
      return [
        {
          id: 'icon_type',
          label: this.$t('form.icon_ids.column.icon_type'),
          width: '160px'
        },
        { id: 'icons', label: this.$t('form.icon_ids.column.icons') },
        { id: 'actions', label: this.$t('app.actions'), width: '160px' }
      ];
    },
    rows() {
      return [
        {
          id: 'pc',
          icon_type: this.$t('form.icon_ids.icon_type.pc'),
          icons: this.formObject.pc_icon_ids
        },
        ...(this.currentBrand.shop_builder === 'makeshop'
          ? [
              {
                id: 'mobile',
                icon_type: this.$t('form.icon_ids.icon_type.mobile'),
                icons: this.formObject.mobile_icon_ids
              }
            ]
          : [])
      ];
    }
  },
  mounted() {
    this.orgFormObject = this.item;
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openSelectIconDialog(id, iconIds) {
      const isPc = id === 'pc';

      this.openDialog([
        'DisplayIconManagementSelectIconDialog',
        { iconIds, isPc }
      ]).then(eventBus => {
        eventBus.$on('update-selected-icons', iconIds => {
          if (isPc)
            this.formObject = { ...this.formObject, pc_icon_ids: iconIds };
          else
            this.formObject = { ...this.formObject, mobile_icon_ids: iconIds };
        });
      });
    },
    submit() {
      this.eventBus.$emit('update-product-management', this.formObject);
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.DisplayIconManagementFormDialog__icon {
  & + & {
    margin-left: 4px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "상품에 아이콘 표시",
  "form": {
    "use_icon_management": {
      "label": "상품에 아이콘 표시 사용",
      "description_html": "조건에 의해 선정된 대상 상품에 아이콘을 표시합니다.<br />더 이상 해당하지 않는 상품에서는 해당 아이콘을 제거합니다."
    },
    "icon_ids": {
      "label": "상품에 표시할 아이콘 선택",
      "button": {
        "select_icon": "아이콘 선택"
      },
      "column": {
        "icon_type": "종류",
        "icons": "아이콘"
      },
      "icon_type": {
        "pc": "상품 아이콘",
        "mobile": "모바일 전용 아이콘"
      },
      "icons": {
        "select_icon": "표시할 아이콘을 선택해 주세요."
      }
    },
    "use_icon_overflow_management": {
      "label": "더 이상 아이콘을 추가할 수 없을 시",
      "description": "{shop_builder}에서는 상품당 아이콘을 5개까지만 추가할 수 있습니다.",
      "true": "추가할 아이콘 보다 우선순위가 낮은 아이콘이 있으면 대체",
      "false": "새로운 아이콘을 추가하지 않음",
      "button": "아이콘 우선 순위 관리"
    }
  }
}
</i18n>
