<template>
  <AppSummaryBadge
    :items="productDisplays"
    unit-path="cases"
    :summary-description="$t('scheduled')"
  />
</template>

<script>
export default {
  name: 'DisplayCornerScheduledProductDisplaysSummary',
  props: { scheduledProductDisplays: { type: Array, required: true } },
  computed: {
    productDisplays() {
      return this.scheduledProductDisplays.map(productDisplay => ({
        label: productDisplay.name,
        description: [
          this.formatDateTimeWithoutSecond(productDisplay.display_at),
          `<span style="margin-left: 12px;">${productDisplay.name}</span>`
        ].join('')
      }));
    }
  }
};
</script>

<i18n locale="ko">
{
  "scheduled": "예약됨"
}
</i18n>
