import { constructEnum } from '@/lib/enum';

const InsightTransactionType = constructEnum('InsightTransactionType', {
  FREE_TRIAL: 5,
  PAYMENT: 10,
  STARTED: 11,
  PERIOD_BONUS: 45,
  EXPIRED: 50,
  RETURN: 60,
  TERMINATED: 70,
  ETC: 100,
  SUPER_ADMIN_ETC: 110
});

InsightTransactionType.badgeStyle = function(transactionType) {
  switch (transactionType) {
    case InsightTransactionType.FREE_TRIAL:
    case InsightTransactionType.PERIOD_BONUS:
      return 'blue-outline';
    case InsightTransactionType.PAYMENT:
    case InsightTransactionType.STARTED:
      return 'blue';
    case InsightTransactionType.EXPIRED:
      return 'red-outline';
    case InsightTransactionType.RETURN:
    case InsightTransactionType.TERMINATED:
      return 'red';
    default:
      return 'default';
  }
};

export default InsightTransactionType;
