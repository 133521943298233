<template>
  <AppModalFormLivePreview
    :title="$t('title')"
    :sub-title="subTitle"
    :is-loading="isLoading"
    :form-props="{ ...formProps, objectId: 'product_display' }"
    width="680px"
    can-maximize
    v-on="formEvents"
  >
    <template #left>
      <DisplayProductDisplayUnitsLivePreview
        :item-list="itemList"
        @sort="updateItemPositions"
      />
    </template>
    <template #group="{ id, errors }">
      <template v-if="id === 'category_filter_type'">
        <AppSelectRadio
          v-model="formObject.category_filter_type"
          name="product_display[category_filter_type]"
          :options="categoryFilterTypeOptions"
        >
          <template #sub-item="{ value, checked }">
            <template v-if="value === DisplayCategoryFilterType.INCLUDE">
              <AppSelectProductCategory
                v-model="formObject.including_category_ids"
                name="product_display[including_category_ids][]"
                :disabled="!checked"
                :invalid="!!errors.including_category_ids"
                multiple
                :showable-category-ids="displaySettings.enabled_category_ids"
                @blur="validateField('including_category_ids')"
                @change="validateField('including_category_ids')"
              />
              <AppFormError :error="errors.including_category_ids" />
            </template>
          </template>
        </AppSelectRadio>
        <AppCheckbox
          v-if="!isCategoryPage"
          v-model="formObject.use_excluding_category_ids"
          class="AppForm__group-field DisplayProductDisplayFormDialog__checkbox"
          :label="$t('form.use_excluding_category_ids.label')"
          name="product_display[use_excluding_category_ids]"
        >
          <template #sub-item="{ checked }">
            <AppSelectProductCategory
              v-model="formObject.excluding_category_ids"
              name="product_display[excluding_category_ids][]"
              :disabled="!checked"
              :invalid="!!errors.excluding_category_ids"
              multiple
              :showable-category-ids="displaySettings.enabled_category_ids"
              @blur="validateField('excluding_category_ids')"
              @change="validateField('excluding_category_ids')"
            />
            <AppFormHint
              :message="$t('form.use_excluding_category_ids.description')"
              :disabled="!checked"
            />
            <AppFormError :error="errors.excluding_category_ids" />
          </template>
        </AppCheckbox>
      </template>
      <template v-else-if="id === 'product_display_units'">
        <DisplayProductDisplayUnits
          :item-positions="formObject.itemPositions"
          :product-option-types="productOptionTypes"
          :errors="errors"
          @update-item-list="updateItemList"
          @validate-unit-field="validateUnitField"
        />
      </template>
      <template v-else-if="id === 'least_options_count'">
        <i18n path="form.least_options_count.description1">
          <AppNumberInput
            v-model="formObject.least_options_count"
            class="AppForm__group-field app-input-inline app-input-inline--left"
            name="product_display[least_options_count]"
            :digits="2"
            :invalid="!!errors.least_options_count"
            @blur="validateField('least_options_count')"
            @change="validateField('least_options_count')"
          />
        </i18n>
      </template>
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import DisplayUnitValidation from '@/mixins/DisplayUnitValidation';
import DisplayCategoryFilterType from '@/enums/DisplayCategoryFilterType';
import DisplaySoldOutProductsDisplayType from '@/enums/DisplaySoldOutProductsDisplayType';
import DisplayExtraProductsDisplayType from '@/enums/DisplayExtraProductsDisplayType';
import DisplayProductFilterType from '@/enums/DisplayProductFilterType';
import DisplayProductDisplayUnitsLivePreview from './DisplayProductDisplayUnitsLivePreview';

export default {
  name: 'DisplayProductDisplayFormDialog',
  components: { DisplayProductDisplayUnitsLivePreview },
  mixins: [DialogFormView, DisplayUnitValidation],
  props: { productDisplayId: { type: Number, required: true } },
  data() {
    return { isLoading: true, itemList: [], productOptionTypes: [] };
  },
  computed: {
    ...mapState('session', ['displaySettings']),
    ...mapState('displayProductDisplay', ['display']),
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    ...mapGetters('displayProductDisplay', ['cornerPreview']),
    DisplayCategoryFilterType: () => DisplayCategoryFilterType,
    useDisplayOption: () => true,
    subTitle() {
      if (this.isCategoryPage) return this.display.name;
      else return this.cornerPreview.name;
    },
    categoryFilterTypeOptions() {
      return this.isCategoryPage
        ? [
            {
              label: this.$t('form.category_filter_type.selected'),
              value: DisplayCategoryFilterType.ALL
            }
          ]
        : DisplayCategoryFilterType.options([
            DisplayCategoryFilterType.ALL,
            DisplayCategoryFilterType.INCLUDE
          ]);
    },
    soldOutProductsDisplayTypeValues() {
      if (this.isCategoryPage)
        return [
          DisplaySoldOutProductsDisplayType.MOVE_BACK,
          DisplaySoldOutProductsDisplayType.NONE
        ];
      else
        return [
          DisplaySoldOutProductsDisplayType.REMOVE,
          DisplaySoldOutProductsDisplayType.MOVE_BACK
        ];
    },
    groupConfigs() {
      return {
        info: {
          label: '',
          type: 'infobox',
          value: this.$t('form.info.description')
        },
        category_filter_type: {
          label: this.$t('form.category_filter_type.label'),
          validate:
            this.formObject.category_filter_type !==
              DisplayCategoryFilterType.ALL ||
            this.formObject.use_excluding_category_ids
              ? ['required']
              : [],
          fields: [
            ...(this.formObject.category_filter_type ===
            DisplayCategoryFilterType.INCLUDE
              ? [{ id: 'including_category_ids', validate: ['required'] }]
              : []),
            ...(this.formObject.use_excluding_category_ids
              ? [{ id: 'excluding_category_ids', validate: ['required'] }]
              : [])
          ]
        },
        product_display_units: {
          label: this.$t('form.product_display_units.label'),
          groupDescription: this.$t('form.product_display_units.description'),
          fields: this.unitFields
        },
        exclude_undisplayed_products: {
          label: this.$t('form.exclude_undisplayed_products.label'),
          groupDescription: this.$t(
            'form.exclude_undisplayed_products.description'
          ),
          type: 'select_radio',
          options: [
            {
              label: this.$t('form.exclude_undisplayed_products.true'),
              value: true
            },
            {
              label: this.$t('form.exclude_undisplayed_products.false'),
              value: false
            }
          ]
        },
        sold_out_products_display_type: {
          label: this.$t('form.sold_out_products_display_type.label'),
          type: 'select_radio',
          options: DisplaySoldOutProductsDisplayType.options(
            this.soldOutProductsDisplayTypeValues
          )
        },
        extra_products_display_type: {
          label: this.$t('form.extra_products_display_type.label'),
          type: 'select_radio',
          groupDescription: this.$t(
            'form.extra_products_display_type.description'
          ),
          options: DisplayExtraProductsDisplayType.options()
        },
        least_options_count: {
          label: this.$t('form.least_options_count.label'),
          description: this.$t('form.least_options_count.description2'),
          validate: ['required', 'positive_integer']
        }
      };
    },
    groupIds() {
      if (this.isCategoryPage)
        return [
          'info',
          'category_filter_type',
          'product_display_units',
          'exclude_undisplayed_products',
          'sold_out_products_display_type',
          'extra_products_display_type',
          'least_options_count'
        ];
      else
        return [
          'category_filter_type',
          'product_display_units',
          'exclude_undisplayed_products',
          'sold_out_products_display_type',
          'least_options_count'
        ];
    },
    formSections() {
      return [
        {
          groups: this.groupIds.map(id => ({ ...this.groupConfigs[id], id }))
        }
      ];
    },
    isStockUnitAdded() {
      const { formObject } = this;
      return (
        !formObject.preparing_stock_count &&
        formObject.units.some(({ filter_type }) =>
          DisplayProductFilterType.isStockFilterType(filter_type)
        )
      );
    }
  },
  mounted() {
    api
      .get(`/display/product_displays/${this.productDisplayId}`)
      .then(({ data }) => {
        this.orgFormObject = {
          ...data.product_display,
          units: data.product_display.units,
          itemPositions: data.product_display.units.reduce((result, unit) => {
            result[unit.color_index] = unit.item_positions;

            return result;
          }, {})
        };
        this.SET_UNITS(data.product_display.units);
        this.productOptionTypes = data.product_option_types;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(`display/product_displays/${this.productDisplayId}`, formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.eventBus.$emit('submit', {
            unitsCount: this.units.length,
            isStockUnitAdded: this.isStockUnitAdded
          });
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    updateItemList(itemList) {
      this.itemList = itemList;
    },
    updateItemPositions(itemPositionsByColorIndex) {
      this.formObject.itemPositions = itemPositionsByColorIndex;
    }
  }
};
</script>

<style scoped>
.DisplayProductDisplayFormDialog__checkbox {
  width: 100%;
}
</style>

<i18n locale="ko">
{
  "title": "진열 규칙 설정",
  "form": {
    "info": {
      "description": "선택한 모든 대상 카테고리에 동일한 진열 규칙이 적용됩니다."
    },
    "category_filter_type": {
      "label": "진열 상품 카테고리",
      "selected": "해당 상품 카테고리"
    },
    "use_excluding_category_ids": {
      "label": "다음 카테고리에 포함된 상품 제외",
      "description": "예를들어 아우터 카테고리에 포함된 상품 중 SALE에도 포함된 상품을 제외 하려면\n위에서 SALE카테고리를 선택하세요."
    },
    "product_display_units": {
      "label": "진열 상품 선정 및 정렬 조건",
      "description": "하나의 상품이 여러 진열 조건에 해당하는 경우 한 번만 진열합니다."
    },
    "exclude_undisplayed_products": {
      "label": "진열 상품 선정 시 미노출 상태인 상품 포함 여부",
      "description": "솔루션사에서 미노출 상태로 설정된 상품을 진열 상품 선정에서 포함 혹은 제외할 수 있습니다.",
      "true": "미노출 상태인 상품은 진열 상품 선정 시 제외",
      "false": "미노출 상태인 상품도 진열 상품으로 선정"
    },
    "sold_out_products_display_type": {
      "label": "품절 상품이 있을 시"
    },
    "extra_products_display_type": {
      "label": "나머지 상품 관리 방법",
      "description": "진열 규칙에 설정된 상품 수 보다 많은 상품이 해당 카테고리에 등록되어있을 시"
    },
    "least_options_count": {
      "label": "상품 선정 시 옵션 별 재고량 확인",
      "description1": "상품 선정 조건 중 재고량 기준에 해당하는 옵션을{0}개 이상 가지고 있는 상품만 선정합니다.",
      "description2": "설정 값보다 옵션 수가 적은 상품은 상품의 모든 옵션이 조건을 충족하면 선정합니다."
    }
  }
}
</i18n>
