import Vue from 'vue';
import api from '@/lib/api';
import i18n from '@/lib/i18n';
import { nullResources } from '@/lib/resources';

const FETCH_FIT_PRESET_PRODUCTS_REQUEST_ID = 'FETCH_FIT_PRESET_PRODUCTS';

const state = {
  fitPresetProducts: nullResources
};

const mutations = {
  SET_FIT_PRESET_PRODUCTS(state, fitPresetProducts) {
    state.fitPresetProducts = fitPresetProducts;
  },
  UPDATE_FIT_PRESET_PRODUCT({ fitPresetProducts }, newFitPresetProduct) {
    const { items, total_count } = fitPresetProducts;
    const index = items.findIndex(i => i.id === newFitPresetProduct.id);
    if (index === -1) {
      Vue.set(fitPresetProducts, 'items', [newFitPresetProduct, ...items]);
      Vue.set(fitPresetProducts, 'total_count', total_count + 1);
    } else {
      Vue.set(items, index, newFitPresetProduct);
    }
  },
  DELETE_FIT_PRESET_PRODUCT({ fitPresetProducts }, fitPresetProductId) {
    const { items } = fitPresetProducts;
    const index = items.findIndex(i => i.id === fitPresetProductId);
    Vue.delete(items, index);
    Vue.set(
      fitPresetProducts,
      'total_count',
      fitPresetProducts.total_count - 1
    );
  },
  PUSH_FIT_PRESET_PRODUCT(state, fitPresetProduct) {
    state.fitPresetProducts.items.push(fitPresetProduct);
  }
};

const actions = {
  resetFitPresetProducts({ commit }) {
    api.cancel(FETCH_FIT_PRESET_PRODUCTS_REQUEST_ID);
    commit('SET_FIT_PRESET_PRODUCTS', nullResources);
  },
  fetchFitPresetProducts({ commit }, params) {
    api
      .get('/fit/preset_products', {
        requestId: FETCH_FIT_PRESET_PRODUCTS_REQUEST_ID,
        params
      })
      .then(function({ data }) {
        commit('SET_FIT_PRESET_PRODUCTS', data.fit_preset_products);
      });
  },
  updateProduct({ commit }, { fitProductId, productId, successMessage }) {
    api
      .patch(
        `/fit/preset_products/${fitProductId}/update_product`,
        { product_id: productId },
        { successMessage }
      )
      .then(function({ data }) {
        commit('UPDATE_FIT_PRESET_PRODUCT', data.fit_product);
      });
  },
  deleteFitPresetProduct({ commit }, { fitPresetProduct, params }) {
    api
      .delete(`/fit/preset_products/${fitPresetProduct.id}`, {
        params,
        successMessage: i18n.t('fit_preset_product.deleted', {
          preset_code: fitPresetProduct.preset_code
        })
      })
      .then(function({ data }) {
        commit('DELETE_FIT_PRESET_PRODUCT', fitPresetProduct.id);
        if (data.next_fit_product) {
          commit('PUSH_FIT_PRESET_PRODUCT', data.next_fit_product);
        }
      });
  }
};

const getters = {
  isFetchingFitPresetProducts(_state, _getters, _rootState, rootGetters) {
    return rootGetters['request/isRequestPending'](
      FETCH_FIT_PRESET_PRODUCTS_REQUEST_ID
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
