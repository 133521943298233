<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{
      ...formProps,
      objectId: 'service_transaction',
      detectFormDataChange: false,
      submitLabel: $t('app.save'),
      submittingLabel: $t('app.saving')
    }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="service_transaction[service_type]"
      :value="CremaServiceType.SMARTSTORE_DAILY"
    />
    <input
      type="hidden"
      name="service_transaction[transaction_type]"
      :value="ServiceTransactionType.BONUS"
    />
  </AppModalForm>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import api from '@/lib/api';
import moment from 'moment';
import DialogFormView from '@/mixins/DialogFormView';
import CremaServiceType from '@/enums/CremaServiceType';
import ServiceTransactionType from '@/enums/ServiceTransactionType';

export default {
  name: 'ThePaymentHistoryAddSmartstoreBonusTransactionDialog',
  mixins: [DialogFormView],
  computed: {
    ...mapState('smartstore', ['smartstoreExpiresAt']),
    CremaServiceType: () => CremaServiceType,
    ServiceTransactionType: () => ServiceTransactionType,
    formSections() {
      return [
        {
          groups: [
            {
              id: 'service_type',
              label: this.$t('service_type.label'),
              type: 'static',
              value: this.$t('service_type.value')
            },
            {
              id: 'transaction_type',
              label: this.$t('transaction_type.label'),
              type: 'static',
              value: this.$t('transaction_type.value')
            },
            {
              id: 'description',
              name: 'service_transaction[description]',
              label: this.$t('description.label'),
              placeholder: this.$t('description.placeholder'),
              value: this.$t('description.placeholder'),
              type: 'text'
            },
            {
              id: 'bonus_period',
              name: 'service_transaction[bonus_period]',
              label: this.$t('bonus_period.label'),
              type: 'select',
              placeholder: this.$t('bonus_period.placeholder'),
              options: [1, 2, 3, 4, 5, 6].map(value => ({
                label: this.$t('bonus_period.option_label', { month: value }),
                value
              })),
              description: this.formObject.bonus_period
                ? this.$t('bonus_period.description', {
                    start_date: this.startDate,
                    end_date: this.endDate
                  })
                : null
            }
          ]
        }
      ];
    },
    startDate() {
      if (this.smartstoreExpiresAt)
        return moment(this.smartstoreExpiresAt).toVal();

      return moment().toVal();
    },
    endDate() {
      const baseExpiresAt = this.smartstoreExpiresAt
        ? moment(this.smartstoreExpiresAt)
        : moment();
      return baseExpiresAt
        .add(this.formObject.bonus_period, 'month')
        .subtract(1, 'day')
        .toVal();
    }
  },
  created() {
    this.fetchSmartstoreServiceInfo();
  },
  methods: {
    ...mapActions('smartstore', ['fetchSmartstoreServiceInfo']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/payment_history/service_transactions', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.eventBus.$emit('update', data);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "충전하기",
  "sub_title": "리뷰 부가서비스 이용내역",
  "service_type": {
    "label": "서비스 종류",
    "value": "스마트스토어 연동 - 매일 리뷰"
  },
  "transaction_type": {
    "label": "유형",
    "value": "보너스 충전"
  },
  "description": {
    "label": "설명 입력",
    "placeholder": "매일 리뷰 연동 보너스 충전"
  },
  "bonus_period": {
    "label": "충전 기간",
    "placeholder": "충전할 기간을 선택하세요.",
    "option_label": "%{month}개월",
    "description": "기존 만료일: %{start_date} -> 변경될 만료일: %{end_date}"
  }
}
</i18n>
