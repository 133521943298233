<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">Basic Usage</div>
      <AppTextEditor v-model="basicText" />
      <div class="ui-section__description">Text: {{ basicText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTextEditor',
  data() {
    return {
      basicText: 'Mavon Editor'
    };
  }
};
</script>
