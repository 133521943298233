<template>
  <div class="BatchJobResultTable">
    <div class="BatchJobResultTable__table-head">
      <AppSelectButton
        v-model="listType"
        :options="availableListTypes"
        class="BatchJobResultTable__select-list-type"
      />
    </div>
    <AppTable
      v-if="listType === 'success'"
      table-id="batch-job-success-items-table"
      class="tab-small__content"
      :enable-total-count="false"
      :columns="successItemsColumns"
      :rows="successItems"
    >
      <template #cell="{ row, column }">
        <slot name="success-cell" v-bind="{ row, column }" />
      </template>
    </AppTable>
    <AppTable
      v-else
      table-id="batch-job-failed-items-table"
      class="tab-small__content"
      :enable-total-count="false"
      :columns="failedItemsColumns"
      :rows="failedItems"
    >
      <template #cell="{ row, column }">
        <slot name="failed-cell" v-bind="{ row, column }" />
      </template>
    </AppTable>
  </div>
</template>

<script>
import BatchJobResultCode from '@/enums/BatchJobResultCode';

export default {
  name: 'BatchJobResultTable',
  props: {
    batchJob: { type: Object, required: true },
    successItemsColumns: { type: Array, required: true },
    failedItemsColumns: { type: Array, required: true }
  },
  data() {
    return { listType: 'success' };
  },
  computed: {
    availableListTypes() {
      return [
        { label: this.$t('local.list_type_success'), value: 'success' },
        { label: this.$t('local.list_type_error'), value: 'error' }
      ];
    },
    successItems() {
      return this.batchJob.results.filter(
        r => r.result_code === BatchJobResultCode.SUCCESS
      );
    },
    failedItems() {
      return this.batchJob.results
        .map((r, i) => ({ ...r, line_no: i + 1 }))
        .filter(r => r.result_code !== BatchJobResultCode.SUCCESS);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_clearfix.scss';

.BatchJobResultTable {
  position: relative;
}

.BatchJobResultTable__table-head {
  @include clearfix;
}

.BatchJobResultTable__select-list-type {
  float: left;
}

::v-deep {
  .batch-job-success-items-table__code,
  .batch-job-success-items-table__template,
  .batch-job-error-items-table__line-num,
  .batch-job-error-items-table__code {
    flex: 0 0 120px;
  }
}
</style>

<i18n locale="ko">
{
  "local": {
    "list_type_success": "등록 내역",
    "list_type_error": "오류 내역"
  }
}
</i18n>
