<template>
  <AppInfoBox class="ReviewReportsWarning">
    {{ $t('warning_message') }}
    <br />
    {{ $t('warning_deleted_review_message') }}
  </AppInfoBox>
</template>

<script>
export default { name: 'ReviewReportsWarning' };
</script>

<i18n locale="ko">
{
  "warning_message": "오늘자 통계는 갱신 시점에 따라 실시간 데이터와 차이가 있을 수 있습니다.",
  "warning_deleted_review_message": "관리자 페이지에서 숨김/삭제한 리뷰도 통계에 반영됩니다."
}
</i18n>
<i18n locale="en">
{
  "warning_message": "Today's statistics may differ from real-time data depending on when they are updated.",
  "warning_deleted_review_message": "Deleted reviews are not counted in stats."
}
</i18n>

<style scoped>
.ReviewReportsWarning {
  margin-bottom: 24px;
}
</style>
