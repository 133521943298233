var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.$t('subtitle'),"form-props":Object.assign({}, _vm.formProps,
    {id: 'biz_message_template_button',
    submitLabel: _vm.submitLabel})},scopedSlots:_vm._u([{key:"group",fn:function(ref){
  var id = ref.id;
  var errors = ref.errors;
  var fields = ref.fields;
return [(id === 'links')?[(
          _vm.formObject.button_type === _vm.BizMessageTemplateButtonType.APP_LINK
        )?[_c('div',{staticClass:"AppForm__sub-group"},_vm._l((fields),function(field){return _c('div',{key:field.id,staticClass:"AppForm__sub-group-item"},[_c('label',{staticClass:"AppForm__sub-group-title",attrs:{"for":("biz_message_template_button_" + (field.id))}},[_vm._v(_vm._s(_vm.$t(((field.id) + ".label"))))]),_c('AppTextInput',{attrs:{"id":("biz_message_template_button_" + (field.id)),"type":field.type,"placeholder":_vm.$t(((field.id) + ".placeholder")),"invalid":(!!errors.links && !_vm.formObject[field.id]) ||
                  !!errors[field.id]},on:{"blur":function($event){return _vm.validateField('links', field.id)},"change":function($event){return _vm.validateField('links', field.id)}},model:{value:(_vm.formObject[field.id]),callback:function ($$v) {_vm.$set(_vm.formObject, field.id, $$v)},expression:"formObject[field.id]"}}),_c('AppFormError',{attrs:{"error":errors[field.id]}})],1)}),0)]:(
          _vm.formObject.button_type === _vm.BizMessageTemplateButtonType.WEB_LINK
        )?[_c('div',{staticClass:"AppForm__sub-group"},_vm._l((fields),function(field){return _c('div',{key:field.id,staticClass:"AppForm__sub-group-item"},[_c('label',{staticClass:"AppForm__sub-group-title",attrs:{"for":("biz_message_template_button_" + (field.id))}},[_vm._v(_vm._s(_vm.$t(((field.id) + ".label"))))]),_c('AppTextInput',{attrs:{"id":("biz_message_template_button_" + (field.id)),"type":field.type,"placeholder":_vm.$t(((field.id) + ".placeholder")),"invalid":!!errors[field.id]},on:{"blur":function($event){return _vm.validateField(field.id)},"change":function($event){return _vm.validateField(field.id)}},model:{value:(_vm.formObject[field.id]),callback:function ($$v) {_vm.$set(_vm.formObject, field.id, $$v)},expression:"formObject[field.id]"}}),_c('AppFormError',{attrs:{"error":errors[field.id]}})],1)}),0)]:_vm._e()]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }