<template>
  <AppTabContent menu-item-id="fit_settings">
    <RouterView />
  </AppTabContent>
</template>

<script>
export default {
  name: 'FitSettings'
};
</script>
