<template>
  <AppModalDefault
    class="InstallRequestRenewalSlotCountAndScoreDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    is-maximized-by-default
    @close="close"
  >
    <template #body>
      <AppForm
        id="count_and_score"
        class="InstallRequestRenewalSlotCountAndScoreDialog__form"
        object-id="count_and_score"
        v-bind="formProps"
        v-on="formEvents"
      >
        <template #group="{ id }">
          <template v-if="id === 'page_types'">
            <AppImage
              class="InstallRequestRenewalSlotCountAndScoreDialog__img-wrapper"
              src="https://assets.cre.ma/m/admin/v2/install_request/count_and_score_slot/page_type_guide@3x.png"
              :alt="$t('page_types.label')"
            />
            <AppTipBox
              class="InstallRequestRenewalSlotCountAndScoreDialog__tip-box"
              :text="$t('page_types.tip_box')"
              :margin-bottom="4"
            />
            <AppMultipleSelectButton
              v-model="formObject.page_types"
              name="count_and_score[page_types]"
              :options="WidgetPageType.options(availablePageTypes)"
              @change="validateField('page_types')"
            />
          </template>
          <template v-else-if="id === 'font_size'">
            <AppTipBox
              class="InstallRequestRenewalSlotCountAndScoreDialog__tip-box"
              :text="$t('text_style.tip_box')"
              :margin-bottom="4"
            />
            <div class="AppForm__group-field">
              <AppCheckbox
                v-model="formObject.use_font_size"
                name="count_and_score[use_font_size]"
                :label="$t('font_size.label')"
              />
              <AppNumberInput
                v-model="formObject.font_size"
                class="InstallRequestRenewalSlotCountAndScoreDialog__number-input"
                name="count_and_score[font_size]"
                :digits="2"
                :min="1"
                :disabled="!formObject.use_font_size"
                :default="12"
              />
              px
            </div>
          </template>
          <template v-else-if="id === 'font_weight'">
            <div class="AppForm__group-field">
              <AppCheckbox
                v-model="formObject.use_font_weight"
                name="count_and_score[use_font_weight]"
                :label="$t('font_weight.label')"
              />
              <AppSelect
                v-model="formObject.font_weight"
                class="InstallRequestRenewalSlotCountAndScoreDialog__select"
                name="count_and_score[font_weight]"
                :options="ReviewWidgetFontWeight.options()"
                :disabled="!formObject.use_font_weight"
              />
            </div>
          </template>
          <template v-else-if="id === 'font_color'">
            <div class="AppForm__group-field">
              <AppCheckbox
                v-model="formObject.use_font_color"
                name="count_and_score[use_font_color]"
                :label="$t('font_color.label')"
              />
              <AppColorPicker
                v-model="formObject.font_color"
                class="InstallRequestRenewalSlotCountAndScoreDialog__color-picker"
                name="count_and_score[font_color]"
                :disabled="!formObject.use_font_color"
              />
            </div>
          </template>
        </template>
      </AppForm>
    </template>
    <template #foot>
      <AppButtonSubmit
        type="button"
        :disabled="!formProps.isFormDataChanged"
        :error-summary="formProps.errorSummary"
        @click="submit"
      />
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import WidgetPageType from '@/enums/WidgetPageType';
import ReviewWidgetFontWeight from '@/enums/ReviewWidgetFontWeight';
import CountAndScorePosition from '@/enums/CountAndScorePosition';
import CountAndScoreAlignment from '@/enums/CountAndScoreAlignment';
import CountAndScoreFormat from '@/enums/CountAndScoreFormat';

export default {
  name: 'InstallRequestRenewalSlotCountAndScoreDialog',
  mixins: [DialogFormView],
  data() {
    return {
      availablePageTypes: [
        WidgetPageType.MAIN,
        WidgetPageType.PRODUCT_LIST,
        WidgetPageType.PRODUCT_SEARCH,
        WidgetPageType.PRODUCT_DETAIL
      ]
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('installRequest', ['countAndScoreDirection']),
    formSections() {
      return [
        {
          id: 'count_and_score_page_types',
          groups: [
            {
              id: 'page_types',
              label: this.$t('page_types.label'),
              validate: ['required']
            }
          ]
        },
        this.formObject.page_types?.length > 0
          ? {
              id: 'count_and_score_appearance',
              groups: [
                {
                  id: 'position',
                  label: this.$t('appearance_label'),
                  groupDescription: this.$t('position.group_description'),
                  type: 'select_button',
                  options: CountAndScorePosition.options(),
                  validate: ['required']
                },
                {
                  id: 'format',
                  label: null,
                  groupDescription: this.$t('format.group_description'),
                  type: 'select_button',
                  options: CountAndScoreFormat.optionsWithLocale(
                    this.currentBrand.locale
                  ),
                  validate: ['required']
                },
                {
                  id: 'alignment',
                  label: null,
                  type: 'select_button',
                  options: CountAndScoreAlignment.options(),
                  validate: ['required']
                },
                {
                  id: 'font_size',
                  label: null,
                  groupDescription: this.$t('text_style.label')
                },
                {
                  id: 'font_weight',
                  label: null
                },
                {
                  id: 'font_color',
                  label: null
                }
              ]
            }
          : null
      ].filter(section => section);
    },
    WidgetPageType() {
      return WidgetPageType;
    },
    ReviewWidgetFontWeight() {
      return ReviewWidgetFontWeight;
    }
  },
  mounted() {
    if (Object.keys(this.countAndScoreDirection).length === 0) {
      this.setDefaults();
    } else {
      this.orgFormObject = { ...this.countAndScoreDirection };
    }
  },
  methods: {
    ...mapMutations('installRequest', ['SET_COUNT_AND_SCORE_DIRECTION']),
    setDefaults() {
      this.orgFormObject = {
        page_types: [],
        position: CountAndScorePosition.UNDER_IMAGE,
        format: CountAndScoreFormat.DEFAULT_FORMATS[0],
        alignment: CountAndScoreAlignment.LEFT,
        font_size: 12,
        font_weight: ReviewWidgetFontWeight.NORMAL,
        font_color: '#000000'
      };
    },
    submit() {
      this.SET_COUNT_AND_SCORE_DIRECTION({ ...this.formObject });
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

::v-deep {
  .AppModalDefault__body {
    background-color: $color-grey-05;
  }

  .AppForm__section + .AppForm__section {
    padding: 0;
    border-top: none;
  }

  .AppForm__group + .AppForm__group {
    margin-top: 8px;
  }

  .AppFormGroupDescription {
    margin: 16px 0 4px;
  }

  .AppForm .AppButton__button {
    background-color: $color-review-white;
    padding: 6px 16px;
  }
}

.InstallRequestRenewalSlotCountAndScoreDialog__form {
  margin: 32px auto 0;
  width: 680px;
}

.InstallRequestRenewalSlotCountAndScoreDialog__img-wrapper {
  display: flex;
  justify-content: center;
  margin: 4px 0;
  width: 100%;
  height: 248px;
  max-height: 248px;
  background-color: $color-background-card;

  ::v-deep {
    .AppImage__img {
      object-fit: contain;
    }
  }
}

.InstallRequestRenewalSlotCountAndScoreDialog__tip-box {
  white-space: pre-wrap;
}

.InstallRequestRenewalSlotCountAndScoreDialog__number-input,
.InstallRequestRenewalSlotCountAndScoreDialog__select,
.InstallRequestRenewalSlotCountAndScoreDialog__color-picker {
  background-color: $color-review-white;
  margin-left: 5px;
  vertical-align: middle;
}
</style>

<i18n locale="ko">
{
  "title": "설치 요청",
  "sub_title": "리뷰 수 위젯",
  "page_types": {
    "label": "리뷰 수 위젯을 설치할 상품 목록이 노출되는 페이지를 모두 선택해주세요.",
    "tip_box": "아래 페이지 외 상품 목록이 노출되는 페이지가 있다면 크리마 운영팀에 문의해주세요."
  },
  "appearance_label": "리뷰 수 위젯 설치 위치 및 표시 방법을 선택해주세요.",
  "position": {
    "group_description": "설치 위치"
  },
  "format": {
    "group_description": "표시 방법"
  },
  "text_style": {
    "label": "글자 스타일",
    "tip_box": "기본적으로 쇼핑몰 스타일(CSS)을 따라 글자 스타일이 자동 적용됩니다.\n스타일 변경을 원할 경우에만 변경하려는 옵션을 선택하여 값을 설정해주세요."
  },
  "font_size": {
    "label": "글자 크기"
  },
  "font_weight": {
    "label": "글자 굵기"
  },
  "font_color": {
    "label": "글자 색상"
  }
}
</i18n>
