<template>
  <div class="AppProgress">
    <div class="AppProgress__progress-text">
      {{ $t('progress', [status]) }}
      <div class="AppProgress__progress-percentage">
        {{ percentage }}
      </div>
    </div>
    <div class="AppProgress__progress-graph">
      <div
        class="AppProgress__progress-graph-complete"
        :style="{ transform: `scaleX(${rate})` }"
      />
    </div>
    <div class="AppProgress__time_info">
      {{ timeInfo }}
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AppProgress',
  props: {
    totalCount: { type: Number, required: true },
    progressCount: { type: Number, required: true },
    startedAt: { type: Date, required: true },
    progressStatus: { type: String, default: '' },
    displayRunningTime: { type: Boolean, default: false }
  },
  data() {
    return { now: moment() };
  },
  computed: {
    rate() {
      return this.progressCount / this.totalCount;
    },
    percentage() {
      return `${Math.round(this.rate * 100)}%`;
    },
    status() {
      if (this.progressStatus) return this.progressStatus;
      return `${this.progressCount}/${this.totalCount}`;
    },
    timeInfo() {
      if (this.displayRunningTime) {
        const durationMilliSeconds = moment
          .duration(this.now.diff(this.startedAt))
          .asMilliseconds();
        const duration = moment.utc(durationMilliSeconds).format('HH:mm:ss');
        return this.$t('running_time', [duration]);
      } else {
        return this.$t('started_at', {
          date: this.formatDateTime(this.startedAt)
        });
      }
    }
  },
  created() {
    if (!this.displayRunningTime) return;
    this.timeUpdater = setInterval(() => {
      this.now = moment();
    }, 1000);
  },
  beforeDestroy() {
    if (this.timeUpdater) clearInterval(this.timeUpdater);
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppProgress__progress-text {
  @include text-caption-dark;
  position: relative;
  padding-right: 34px;
}

.AppProgress__progress-percentage {
  position: absolute;
  top: 0;
  right: 0;
}

.AppProgress__progress-graph {
  height: 13px;
  margin-top: 4px;
  background-color: $color-grey-15;
}

.AppProgress__progress-graph-complete {
  transition: transform 0.05s;
  transform-origin: center left;
  height: 100%;
  background-color: $color-mint-green;
}

.AppProgress__time_info {
  @include text-caption;
  margin-top: 12px;
}
</style>

<i18n locale="ko">
{
  "progress": "진행 상태: {0}",
  "started_at": "시작일: {date}",
  "running_time": "진행 시간: {0}"
}
</i18n>
