<template>
  <div v-tooltip="detailTooltip" class="AppSummaryBadge">
    <AppBadge
      :label="items[0].label"
      :tooltip="itemsDetail ? { message: null } : null"
      badge-style="grey-outline"
      with-max-width
    /><span v-if="summarize" class="AppSummaryBadge__text">{{
      itemsSummary
    }}</span
    ><AppSvgIcon
      v-if="summarize"
      class="AppSummaryBadge__icon"
      name="nav-chevron-down"
    />
  </div>
</template>

<script>
export default {
  name: 'AppSummaryBadge',
  props: {
    items: { type: Array, required: true },
    unitPath: { type: String, required: true },
    summaryDescription: { type: String, default: null }
  },
  computed: {
    itemsCount() {
      return this.items.length;
    },
    summarize() {
      return this.itemsCount > 1;
    },
    itemsSummary() {
      const { summaryDescription } = this;
      const summary = this.$t('etc_count', [
        this.itemsCount - 1,
        this.$t(`unit.${this.unitPath}`)
      ]);

      return summaryDescription ? `${summary} ${summaryDescription}` : summary;
    },
    itemsDetail() {
      if (!this.summarize) return null;

      return this.items.map(item => item.description).join('\n');
    },
    detailTooltip() {
      const { itemsDetail } = this;
      if (itemsDetail) return { message: itemsDetail, textAlign: 'left' };
      else return null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppSummaryBadge {
  max-width: 100%;
  display: inline-flex;
  align-items: center;
}

.AppSummaryBadge__text {
  @include text-caption;

  margin-left: 4px;
  white-space: nowrap;
}

.AppSummaryBadge__icon {
  margin-left: 4px;
}
</style>

<i18n locale="ko">
{
  "etc_count": "외 {0}{1}"
}
</i18n>
