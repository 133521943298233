<template>
  <AppModalDefault
    :title="$t('menu.manuals_dialog')"
    width="wide"
    @close="close"
  >
    <template #body>
      <AppTabs
        :tabs="childTabItems('manuals_dialog')"
        :active-tab="activeTab"
        @change="setActiveTab"
      />
      <AppSearchbar>
        <template #right>
          <li>
            <AppSelect
              v-model="resourceParams.faq_category_id"
              :options="categoryOptions"
              :placeholder="$t('app.all')"
              @change="searchResource"
            />
          </li>
        </template>
      </AppSearchbar>
      <AppAjaxContent :is-loading="isLoading">
        <AppResourceTable
          table-id="manuals-table"
          :columns="columns"
          :resources="manuals"
          :rows="rows"
          @paginate="paginate"
        >
          <template #cell="{ column, row }">
            <template v-if="column === 'title'">
              <AppExternalLinkWithIcon
                v-if="row.url"
                :to="row.url"
                :label="row.title"
              />
              <template v-else>{{ row.title }}</template>
            </template>
          </template>
        </AppResourceTable>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import DialogView from '@/mixins/DialogView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'TheManualsDialog',
  mixins: [DialogView, ResourceViewNoRoute],
  props: {
    initialTab: { type: String, default: 'manuals_dialog_all' }
  },
  data() {
    return {
      activeTab: this.initialTab,
      isLoading: true,
      manuals: nullResources,
      faqCategories: {}
    };
  },
  computed: {
    ...mapGetters('menu', ['childTabItems']),
    categoryOptions() {
      return _.values(this.faqCategories).map(category => ({
        label: category.name,
        value: category.id
      }));
    },
    columns() {
      return [
        { id: 'position', type: 'position' },
        ...(this.activeTab === 'manuals_dialog_all'
          ? [{ id: 'manual_type', label: this.$t('manual_type') }]
          : []),
        { id: 'category', label: this.$t('app.category') },
        { id: 'title', label: this.$t('app.title'), align: 'left' }
      ];
    },
    rows() {
      return this.manuals.items.map(manual => {
        const faqCategory = this.faqCategories[manual.faq_category_id];
        return {
          ...manual,
          manual_type: this.$t(`menu.manuals_dialog_${manual.service_type}`),
          category: faqCategory ? faqCategory.name : '-'
        };
      });
    }
  },
  methods: {
    setActiveTab(activeTab) {
      this.activeTab = activeTab;
      this.$delete(this.resourceParams, 'category');
      this.searchResource();
    },
    fetchResource(params) {
      this.isLoading = true;
      const isInitialRequest = _.size(this.faqCategories) === 0;
      const manual_type = this.activeTab.replace('manuals_dialog_', '');
      api
        .get('/help/manuals', {
          params: {
            ...params,
            manual_type,
            init: isInitialRequest ? '1' : undefined
          }
        })
        .then(({ data }) => {
          this.manuals = data.manuals;
          if (isInitialRequest)
            this.faqCategories = _.keyBy(data.faq_categories, 'id');
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .manuals-table__manual-type {
    flex: 0 0 60px;
  }

  .manuals-table__category {
    flex: 0 0 140px;
  }
}
</style>

<i18n locale="ko">
{
  "manual_type": "제품"
}
</i18n>
