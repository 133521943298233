<template>
  <div class="target-campaign-stats-table">
    <div class="table-head-control">
      <AppButtonDownload
        button-style="grey"
        :label="$t('app.download_excel')"
        :url="downloadExcelUrl"
      />
      <div class="table-head-control__right">
        <div class="table-head-control__item">
          <AppSelectButton
            unselectable
            :value="resourceParams.sub_report"
            :options="subReportOptions"
            @change="changeSubReport"
          />
        </div>
        <div class="table-head-control__item">
          <AppSelectButtonInterval
            :value="resourceParams.interval"
            @change="changeInterval"
          />
        </div>
      </div>
    </div>
    <AppAjaxContent :is-loading="isLoading">
      <TargetCampaignStatsTable
        v-bind="{
          campaign,
          reportsRange,
          resourceParams,
          pagination,
          samplingMethod
        }"
        :reports="finalReports"
        @change-pagination="pagination = $event"
      />
    </AppAjaxContent>
  </div>
</template>

<script>
import _ from 'lodash';
import qs from 'qs';
import { dateRangeWithInterval } from '@/lib/date';
import api from '@/lib/api';
import { mapGetters } from 'vuex';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';
import TargetSubReport from '@/enums/TargetSubReport';
import TargetCampaignType from '@/enums/TargetCampaignType';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import TargetCampaignStatsTable from './TargetCampaignStatsTable';

export default {
  name: 'TargetCampaignStatsSectionTable',
  components: { TargetCampaignStatsTable },
  mixins: [ResourceViewNoRoute],
  props: {
    campaign: { type: Object, required: true },
    dateRange: { type: Object, required: true },
    samplingMethod: { type: Number, required: true }
  },
  data() {
    return {
      isLoading: true,
      pagination: { page: 1, per: 20 },
      reports: [],
      reportsRange: {}
    };
  },
  computed: {
    ...mapGetters('targetCampaigns', ['isPopupCampaign', 'isWidgetCampaign']),
    downloadExcelUrl() {
      return `/api/target/campaigns/${
        this.campaign.id
      }/reports_table/download_excel?${qs.stringify(this.orgResourceParams)}`;
    },
    finalReports() {
      const { samplingMethod } = this;
      return this.reports.map(report => {
        const finalReport = TargetSamplingMethod.report(samplingMethod, report);
        if (report.sub_reports)
          finalReport.sub_reports = _.mapValues(report.sub_reports, subReport =>
            TargetSamplingMethod.report(samplingMethod, subReport)
          );
        return finalReport;
      });
    },
    subReportOptions() {
      const isPopup = this.isPopupCampaign(this.campaign);
      const isWidget = this.isWidgetCampaign(this.campaign);
      return TargetSubReport.options(
        isWidget
          ? []
          : isPopup
          ? [TargetSubReport.DAYS]
          : this.campaign.campaign_type === TargetCampaignType.MILEAGE_EXPIRING
          ? [TargetSubReport.DAYS, TargetSubReport.MEDIA]
          : TargetSubReport.values
      );
    },
    unit() {
      switch (this.resourceParams.interval) {
        case 'daily':
          return 'day';
        case 'weekly':
          return 'week';
        default:
          return 'month';
      }
    }
  },
  watch: {
    dateRange() {
      this.resourceParams = {
        ...this.resourceParams,
        ...dateRangeWithInterval(this.dateRange, this.resourceParams.interval)
      };
      this.searchResource();
    }
  },
  created() {
    this.setOrgResourceParams({
      ...this.dateRange,
      interval: 'daily',
      sub_report: null
    });
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/target/campaigns/${this.campaign.id}/reports_table`, { params })
        .then(({ data }) => {
          this.reports = data.reports;
          this.reportsRange = { ...this.resourceParams };
        })
        .finally(() => (this.isLoading = false));
    },
    changeSubReport(sub_report) {
      this.resourceParams = { ...this.resourceParams, sub_report };
      this.searchResource();
    },
    changeInterval(interval) {
      this.resourceParams = {
        ...this.resourceParams,
        ...dateRangeWithInterval(this.dateRange, interval),
        interval
      };
      this.$set(this.pagination, 'page', 1);
      this.searchResource();
    }
  }
};
</script>

<style lang="scss">
.target-campaign-stats-table {
  margin-top: 24px;
}
</style>
