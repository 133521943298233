<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title', [formatUser(review)])"
    :form-props="{
      ...formProps,
      focusGroupId: 'admin_mileage_amount',
      submitLabel: $t('submit_label'),
      submittingLabel: $t('submitting_label')
    }"
    v-on="formEvents"
  />
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';
import ReviewPointType from '@/enums/ReviewPointType';
import DialogFormView from '@/mixins/DialogFormView';
import ReviewPointTypeHelper from '@/mixins/ReviewPointTypeHelper';
import ManualMileageMaxAmountConfirmShowable from '@/mixins/ManualMileageMaxAmountConfirmShowable';

export default {
  name: 'ReviewReviewsMileageEditDialog',
  mixins: [
    DialogFormView,
    ReviewPointTypeHelper,
    ManualMileageMaxAmountConfirmShowable
  ],
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      orgFormObject: this.review.mileage
    };
  },
  computed: {
    ...mapState('session', ['reviewSettings']),
    ...mapGetters('session', ['isShopifyBrand']),
    mileage() {
      return this.formObject;
    },
    isCurrencyType() {
      return [ReviewPointType.MILEAGE, ReviewPointType.DISCOUNT_CODE].includes(
        this.reviewSettings.review_point_type
      );
    },
    formSections() {
      const groups = [
        {
          id: 'calculated_mileage_amount',
          type: 'static',
          label: this.$t('calculated_mileage_amount'),
          value: this.formatMileage(this.mileage.calculated_mileage_amount)
        }
      ];
      if (!this.isShopifyBrand) {
        groups.push({
          id: 'calculated_mileage_reasons',
          type: 'list',
          label: this.$t('calculated_mileage_reasons'),
          value: this.mileage.calculated_mileage_reasons
        });
      }
      groups.push({
        id: 'admin_mileage_amount',
        type: this.isCurrencyType ? 'currency' : 'number',
        digits: 6,
        label: this.$t('admin_mileage_amount'),
        placeholder: this.formatCurrencyWithoutUnit(
          this.mileage.calculated_mileage_amount
        )
      });

      return [{ groups }];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapMutations('review', ['UPDATE_REVIEW']),
    submit() {
      if (
        this.isManualMileageMaxAmountConfirmNeeded(
          this.mileage.admin_mileage_amount
        )
      ) {
        return this.showManualMileageMaxAmountConfirm();
      }

      this.patchAdminMileageCount();
    },
    patchAdminMileageCount() {
      this.isSubmitting = true;
      api
        .patch(
          `/review/reviews/${this.review.id}/update_admin_mileage_amount`,
          { admin_mileage_amount: this.mileage.admin_mileage_amount }
        )
        .then(({ data }) => {
          this.UPDATE_REVIEW(data.review);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    showManualMileageMaxAmountConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'check_confirm',
          title: this.$t('check_confirm.title'),
          markdownText: this.$t('check_confirm.markdown_text'),
          checkboxLabel: this.$t('check_confirm.checkbox_label', [
            this.formatCurrency(this.mileage.admin_mileage_amount)
          ]),
          closeButtonLabel: this.$t('check_confirm.close_button_label'),
          cancelButtonLabel: this.$t('check_confirm.cancel_button_label'),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => {
        eventBus.$on('close', () => {
          this.patchAdminMileageCount();
        });
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "{point_type} 금액 설정",
  "sub_title": "{0}님의 리뷰",
  "calculated_mileage_amount": "예상 {point_type} 금액",
  "calculated_mileage_reasons": "예상 {point_type} 산정 근거",
  "admin_mileage_amount": "지급 예정 {point_type} 금액",
  "submit_label": "{point_type} 금액 변경",
  "submitting_label": "{point_type} 금액 변경 중..",
  "check_confirm": {
    "title": "평소보다 큰 금액이 입력되었습니다.",
    "markdown_text": "수정하려는 금액이 맞는지 확인해주세요.<br>아래의 체크박스를 누르면 입력된 금액으로 적용할 수 있습니다.<br>만약 잘못 입력하셨다면 닫기 버튼을 눌러주세요.",
    "checkbox_label": "{0}으로 수정하겠습니다.",
    "close_button_label": "예상 적립금 수정",
    "cancel_button_label": "닫기"
  }
}
</i18n>
