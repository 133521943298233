<template>
  <div class="AppNoData">{{ message }}</div>
</template>

<script>
export default {
  name: 'AppNoData',
  props: { message: { type: String, required: true } }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppNoData {
  @include text-sub-title-light;
  text-align: center;
  line-height: 104px;
  padding: 8px;
}
</style>
