var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppContainer',[(_vm.isDmCampaign)?_c('AppInfoBox',{staticClass:"TargetCampaignSettingsMisc__info-box"},[_vm._v(_vm._s(_vm.$t('description'))+" ")]):_vm._e(),_c('div',{staticClass:"TargetCampaignSettingsMisc__items"},[(_vm.isDispatchSampleVisible)?_c('div',{class:[
        'TargetCampaignSettingsMisc__item',
        {
          'super-admin__item':
            _vm.campaign.campaign_type === _vm.TargetCampaignType.MILEAGE_EXPIRING
        }
      ]},[_c('AppDataItem',{attrs:{"label":_vm.$t('dispatch_sample_title')}},[(_vm.dispatchTestMediaText)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('dispatch_sample_message_html', [_vm.dispatchTestMediaText]))}}):_c('div',[_vm._v("-")])]),_c('AppButton',_vm._b({staticClass:"TargetCampaignSettingsMisc__button",attrs:{"label":_vm.$t('app.settings')},on:{"click":function($event){return _vm.openDialog([
            'TargetDmCampaignSettingsMiscDispatchSampleDialog',
            { campaign: _vm.campaign }
          ])}}},'AppButton',
          _vm.campaign.configured_design
            ? {}
            : {
                disabled: true,
                tooltip: _vm.$t('dispatch_sample_not_configured_tooltip')
              }
        ,false))],1):_vm._e(),(_vm.isDmCampaign)?_c('div',{staticClass:"TargetCampaignSettingsMisc__item"},[_c('AppDataItem',{attrs:{"label":_vm.$t('dispatch_limit_title')}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.dispatchLimitMessage)}})]),_c('AppButton',{staticClass:"TargetCampaignSettingsMisc__button",attrs:{"label":_vm.$t('app.settings')},on:{"click":function($event){return _vm.openDialog([
            'TargetDmCampaignSettingsMiscDispatchLimitDialog',
            { campaign: _vm.campaign }
          ])}}})],1):_vm._e(),(_vm.isSuperAdminAccessible)?_c('div',{staticClass:"TargetCampaignSettingsMisc__item super-admin__item"},[_c('AppDataItem',{attrs:{"label":_vm.$t('dispatch_estimation_title')}},[_vm._v(" "+_vm._s(_vm.dispatchEstimationMessage)+" ")]),_c('AppButton',{staticClass:"TargetCampaignSettingsMisc__button",attrs:{"label":_vm.$t(_vm.dispatchEstimationStatus),"disabled":_vm.isRequesting || _vm.dispatchEstimationStatus !== 'calculable'},on:{"click":_vm.clickComputeDispatchEstimation}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }