<template>
  <AppSearchbar @submit="search">
    <template>
      <li
        :class="[
          'DisplayProductsSearchbar__category-wrapper',
          `DisplayProductsSearchbar__category-wrapper--${width}`
        ]"
      >
        <AppSelectProductCategory
          v-model="resourceParams.product_category_id"
          :showable-category-ids="selectableCategoryIds"
          show-non-category-options
          @change="search"
        />
      </li>
    </template>
    <template #right>
      <div class="DisplayProductsSearchbar__search-bar">
        <AppSearchWithType
          v-model="searchWithType"
          class="DisplayProductsSearchbar__search-bar-item"
          :search-types="searchTypes"
        />
        <AppButtonToggle
          v-model="advancedSearchVisible"
          class="DisplayProductsSearchbar__search-bar-item"
          :label="$t('app.advanced_search')"
        />
      </div>
    </template>
    <template #advanced-search>
      <AppAdvancedSearch
        v-model="resourceParams"
        :visible="advancedSearchVisible"
        :sections="advancedSearchSections"
        @submit="search"
      >
        <template #group="{ id }">
          <template v-if="id === 'date_range'">
            <AppDateRangeWithType
              v-model="dateRangeWithType"
              :date-types="dateTypes"
            />
          </template>
          <template v-else-if="id === 'org_price_range'">
            <div class="AppForm__group-field">
              <i18n :path="currencyKey">
                <span>
                  <AppNumberInput
                    v-model="resourceParams.min_org_price"
                    inline
                    :digits="6"
                  />
                  ~&nbsp;
                  <AppNumberInput
                    v-model="resourceParams.max_org_price"
                    inline
                    :digits="6"
                  />
                </span>
              </i18n>
            </div>
          </template>
          <template v-else-if="id === 'final_price_range'">
            <div class="AppForm__group-field">
              <i18n :path="currencyKey">
                <span>
                  <AppNumberInput
                    v-model="resourceParams.min_final_price"
                    inline
                    :digits="6"
                  />
                  ~&nbsp;
                  <AppNumberInput
                    v-model="resourceParams.max_final_price"
                    inline
                    :digits="6"
                  />
                </span>
              </i18n>
            </div>
          </template>
          <template v-else-if="id === 'discount_percentage_range'">
            <div class="AppForm__group-field">
              <AppNumberInput
                v-model="resourceParams.min_discount_percentage"
                inline
                :digits="6"
              />
              ~&nbsp;
              <AppNumberInput
                v-model="resourceParams.max_discount_percentage"
                inline
                :digits="6"
              />%
            </div>
          </template>
        </template>
      </AppAdvancedSearch>
    </template>
  </AppSearchbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DisplayProductsSearchbar',
  props: {
    resourceParams: { type: Object, required: true },
    selectableCategoryIds: { type: Array, required: true },
    width: { type: String, default: 'default' }
  },
  data: () => ({ advancedSearchVisible: false }),
  computed: {
    ...mapGetters('session', ['currencyKey']),
    searchTypes() {
      return ['name', 'code', 'id'].map(value => ({
        value,
        label: this.$t(`product_search_type.${value}`)
      }));
    },
    dateTypes() {
      return [
        'displayed_at',
        'shop_builder_created_at',
        'shop_builder_updated_at'
      ].map(value => ({
        value,
        label: this.$t(`product_date_type.${value}`)
      }));
    },
    searchWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.$emit('change', { ...this.resourceParams, ...newValue });
      }
    },
    dateRangeWithType: {
      get() {
        return this.resourceParams;
      },
      set(newValue) {
        this.$emit('change', { ...this.resourceParams, ...newValue });
      }
    },
    advancedSearchSections() {
      return [
        {
          id: 'search',
          groups: [
            'date_range',
            'org_price_range',
            'final_price_range',
            'discount_percentage_range'
          ].map(v => ({
            id: v,
            label: this.$t(`search.${v}`)
          }))
        }
      ];
    }
  },
  methods: {
    search() {
      this.$emit('search');
    }
  }
};
</script>

<style lang="scss" scoped>
.DisplayProductsSearchbar__category-wrapper {
  margin-left: 1px;

  &--default {
    width: 400px;
  }

  &--narrow {
    width: 218px;
  }
}

.DisplayProductsSearchbar__search-bar {
  text-align: right;
}

.DisplayProductsSearchbar__search-bar-item {
  display: inline-block;
  vertical-align: middle;

  & + & {
    margin-left: 8px;
  }
}
</style>

<i18n locale="ko">
{
  "product_search_type": {
    "name": "상품명",
    "code": "상품번호",
    "id": "상품 ID"
  },
  "product_date_type": {
    "shop_builder_created_at": "상품 등록일",
    "shop_builder_updated_at": "상품 수정일",
    "displayed_at": "상품 노출일"
  },
  "search": {
    "date_range" :"날짜",
    "org_price_range": "정상 가격 범위",
    "final_price_range": "할인 가격 범위",
    "discount_percentage_range": "할인 비율 범위"
  }
}
</i18n>
