<template>
  <div class="SummaryBoxRequestInstallationComplete">
    <AppSummaryBox>
      <template #title>{{ $t('title') }}</template>
      <pre>{{ $t('content') }}</pre>
    </AppSummaryBox>
  </div>
</template>

<script>
export default {
  name: 'SummaryBoxRequestInstallationComplete'
};
</script>

<i18n locale="ko">
{
  "title": "위젯 설치 신청이 완료되었습니다. 👏",
  "content": "위젯 설치는 결제 완료 후 접수되며, 설치 기간은 영업일 기준 최소 2일에서 최대 7일이 소요됩니다.\n설치 요청 내역은 가입시 입력한 이메일 주소로 발송되었습니다. 설치가 완료되면 가입 시 입력한 담당자 연락 정보(이메일 또는 전화번호)로 안내드립니다."
}
</i18n>
