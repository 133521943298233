var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppForm',{staticClass:"BatchJobForm",attrs:{"sections":_vm.formSections,"is-submitting":_vm.isSubmitting},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
return [(id === 'upload_description')?_c('div',{staticClass:"AppForm__group-field"},[_c('p',[_vm._v(" ")]),(_vm.description)?_c('pre',[_vm._v(_vm._s(_vm.description))]):_vm._e(),(_vm.descriptionHtml)?_c('div',{staticClass:"BatchJobForm__description--html",domProps:{"innerHTML":_vm._s(_vm.descriptionHtml)}}):_vm._e(),_c('ul',{staticClass:"BatchJobForm__buttons"},[_c('li',[(_vm.sampleFileUrl)?_c('AppButtonDownload',{attrs:{"url":_vm.sampleFileUrl,"label":_vm.downloadLabel || _vm.$t('local.upload_example_csv_download'),"filename":_vm.filename}}):_vm._e()],1),_vm._t("additional-buttons")],2)]):(id === 'upload_file_input')?_c('div',{staticClass:"AppForm__group-field"},[_c('AppFileField',{attrs:{"id":"batch-job-file","name":"batch_job[file]","placeholder":_vm.disabled
            ? _vm.$t('local.upload_file_input_placeholder.disabled')
            : _vm.$t('local.upload_file_input_placeholder.uploadable'),"accept":".csv","event-bus":_vm.fileEventBus,"is-submitting":_vm.isSubmitting,"disabled":_vm.disabled}})],1):_vm._e()]}},{key:"group-foot",fn:function(ref){
            var id = ref.id;
return [(id === 'upload_file_input' && _vm.uploadStateHint)?_c('div',{class:[
        'BatchJobForm__upload_state_hint',
        _vm.uploadStateHint.type
          ? ("BatchJobForm__upload_state_hint--" + (_vm.uploadStateHint.type))
          : null
      ]},[_c('div',[_vm._v(_vm._s(_vm.uploadStateHint.message))]),(_vm.uploadStateHint.detailClickHandler)?_c('a',{staticClass:"BatchJobForm__upload_state_hint_detail",on:{"click":_vm.uploadStateHint.detailClickHandler}},[_vm._v(_vm._s(_vm.uploadStateHint.detailLabel))]):_vm._e()]):_vm._e()]}}],null,true)},[_c('input',{attrs:{"type":"hidden","name":"batch_job[batch_job_type]"},domProps:{"value":_vm.batchJobType}})])}
var staticRenderFns = []

export { render, staticRenderFns }