var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"TheMainMenu"},[_vm._l((_vm.mainMenuItems),function(mainMenuItem){return [(mainMenuItem.isOldAdmin)?_c('AppOldAdminLink',{key:mainMenuItem.id,staticClass:"TheMainMenu__item",attrs:{"to":mainMenuItem.path}},[_c('AppSvgIcon',{staticClass:"TheMainMenu__icon",attrs:{"name":_vm.iconsMap[mainMenuItem.id],"height":24}}),_c('div',{staticClass:"TheMainMenu__label"},[_vm._v(_vm._s(mainMenuItem.label))])],1):_c('AppInternalLink',{key:mainMenuItem.id,class:[
        'TheMainMenu__item',
        {
          'TheMainMenu__item--active':
            mainMenuItem.id === _vm.selectedMainMenuItemId
        },
        { 'super-admin__item': mainMenuItem.superAdmin }
      ],attrs:{"to":mainMenuItem.path}},[_c('AppSvgIcon',{staticClass:"TheMainMenu__icon",attrs:{"name":_vm.iconsMap[mainMenuItem.id],"height":24}}),_c('div',{staticClass:"TheMainMenu__label"},[_vm._v(_vm._s(mainMenuItem.label))])],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }