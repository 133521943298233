<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="same-domain">Same Domain</label>
      </div>
      <AppButtonDownload
        id="same-domain"
        url="/api/review/reviews/download_csv.csv?date_type=review_created_at&start_date=2000-01-01&end_date=2000-01-01"
        label="Download"
      />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="allowed-domain">Allowed Domain</label>
      </div>
      <AppButtonDownload
        id="allowed-domain"
        url="https://dfit1.cre.ma/cafe23.com/fit/products/230.jpg?width=1000"
        filename="fit-product-230.jpg"
        label="Download"
      />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="other-domain">Other Domain</label>
      </div>
      <AppButtonDownload
        id="other-domain"
        url="https://assets.cre.ma/m/favicons/crema/v2/favicon_32.png"
        label="Download"
      />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppButtonDownload
        id="disabled"
        url="/api/review/reviews/download_csv.csv?date_type=review_created_at&start_date=2000-01-01&end_date=2000-01-01"
        label="Download"
        disabled
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiButtonDownload'
};
</script>
