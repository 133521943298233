import { constructEnum } from '@/lib/enum';

const BrandReviewStatus = constructEnum('BrandReviewStatus', {
  NONE: 0,
  REQUESTED: 10,
  PAID: 12,
  TESTING: 15,
  ONBOARDING_NOT_PAID: 20,
  ONBOARDING_PAID: 22,
  OPERATING: 30,
  EXPIRED_BUT_OPERATING: 35,
  EXPIRED_AND_ADMIN_BLOCKED: 37,
  TERMINATED: 50
});

BrandReviewStatus.PREPARING_STATUS = [
  BrandReviewStatus.REQUESTED,
  BrandReviewStatus.PAID,
  BrandReviewStatus.TESTING,
  BrandReviewStatus.ONBOARDING_NOT_PAID,
  BrandReviewStatus.ONBOARDING_PAID
];

BrandReviewStatus.OPERATING_STATUS = [
  BrandReviewStatus.OPERATING,
  BrandReviewStatus.EXPIRED_BUT_OPERATING,
  BrandReviewStatus.EXPIRED_AND_ADMIN_BLOCKED
];

BrandReviewStatus.EXPIRED_STATUSES = [
  BrandReviewStatus.EXPIRED_BUT_OPERATING,
  BrandReviewStatus.EXPIRED_AND_ADMIN_BLOCKED
];

BrandReviewStatus.TARGET_USABLE_STATUSES = [
  BrandReviewStatus.PAID,
  BrandReviewStatus.TESTING,
  BrandReviewStatus.OPERATING,
  BrandReviewStatus.EXPIRED_BUT_OPERATING
];

BrandReviewStatus.ONBOARDING_STATUSES = [
  BrandReviewStatus.ONBOARDING_NOT_PAID,
  BrandReviewStatus.ONBOARDING_PAID
];

BrandReviewStatus.BIZ_MESSAGE_USING_STATUSES = [
  BrandReviewStatus.PAID,
  BrandReviewStatus.TESTING,
  BrandReviewStatus.OPERATING,
  BrandReviewStatus.EXPIRED_BUT_OPERATING,
  BrandReviewStatus.EXPIRED_AND_ADMIN_BLOCKED,
  BrandReviewStatus.ONBOARDING_NOT_PAID,
  BrandReviewStatus.ONBOARDING_PAID
];

BrandReviewStatus.isPreparing = function(brandReviewStatus) {
  return BrandReviewStatus.PREPARING_STATUS.includes(brandReviewStatus);
};

BrandReviewStatus.isOperating = function(brandReviewStatus) {
  return BrandReviewStatus.OPERATING_STATUS.includes(brandReviewStatus);
};

BrandReviewStatus.isApproved = function(brandReviewStatus) {
  return (
    BrandReviewStatus.isPreparing(brandReviewStatus) ||
    BrandReviewStatus.isOperating(brandReviewStatus)
  );
};

BrandReviewStatus.isTargetUsable = function(brandReviewStatus) {
  return BrandReviewStatus.TARGET_USABLE_STATUSES.includes(brandReviewStatus);
};

BrandReviewStatus.isOnboarding = function(brandReviewStatus) {
  return BrandReviewStatus.ONBOARDING_STATUSES.includes(brandReviewStatus);
};

BrandReviewStatus.isUsingBizMessage = function(brandReviewStatus) {
  return BrandReviewStatus.BIZ_MESSAGE_USING_STATUSES.includes(
    brandReviewStatus
  );
};

export default BrandReviewStatus;
