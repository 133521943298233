var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppContainer',[_c('AppForm',_vm._g(_vm._b({attrs:{"object-id":"third_party_review_csv","is-submitting":_vm.isSubmitting,"submit-button":{
        submitLabel: _vm.$t('app.upload'),
        submittingLabel: _vm.$t('app.uploading')
      }}},'AppForm',_vm.formProps,false),_vm.formEvents))],1),_c('AppResourceTable',{attrs:{"table-id":"review-third-party-review-csvs-table","table-name":_vm.$t('review_third_party_review_csv.upload_result'),"resources":_vm.third_party_review_csvs,"columns":_vm.COLUMNS,"rows":_vm.rows},on:{"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
      var column = ref.column;
      var value = ref.value;
      var row = ref.row;
return [(column === 'brandCsvStatus')?_c('AppBadge',{attrs:{"badge-style":_vm.dataStatusBadgeStyle(value),"label":_vm.BrandCsvStatus.t(value)}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }