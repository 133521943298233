<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppSelectFilterable
        id="basic"
        v-model="valueBasic"
        :options="OPTIONS_BASIC"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="long">Long Option Name</label>
      </div>
      <AppSelectFilterable
        id="long"
        v-model="valueLong"
        :options="OPTIONS_LONG"
      />
      <div class="ui-section__description">Selected Value: {{ valueLong }}</div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="placeholder">Placeholder</label>
      </div>
      <AppSelectFilterable
        id="placeholder"
        v-model="valuePlaceholder"
        placeholder="Please Select"
        :options="OPTIONS_BASIC"
      />
      <div class="ui-section__description">
        Selected Value: {{ valuePlaceholder }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppSelectFilterable
        id="disabled"
        v-model="valueDisabled"
        disabled
        :options="OPTIONS_BASIC"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueDisabled }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Invalid</label>
      </div>
      <AppSelectFilterable
        id="disabled"
        v-model="valueInvalid"
        placeholder="Please Select"
        invalid
        :options="OPTIONS_BASIC"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UiSelectFilterable',
  data() {
    return {
      OPTIONS_BASIC: [
        { label: '테스트', value: 1 },
        { label: 'ㅌㅅㅌ', value: 2 },
        { label: 'test', value: 3 },
        { label: 'tes', value: 4 },
        { label: '우왕', value: 5 },
        { label: '왕우', value: 6 },
        { label: '우웅', value: 7, disabled: true },
        { label: '그만', value: 8 }
      ],
      OPTIONS_LONG: [
        { label: '테스트', value: 1 },
        { label: 'ㅌㅅㅌ', value: 2 },
        { label: 'test', value: 3 },
        { label: 'tes', value: 4 },
        { label: '우왕', value: 5 },
        { label: '왕우', value: 6 },
        { label: '우웅', value: 7, disabled: true },
        { label: '그만', value: 8 },
        {
          label:
            'a very very very very very very very very very very very very very very long label',
          value: 9
        }
      ],
      valueBasic: 1,
      valueLong: 2,
      valuePlaceholder: null,
      valueDisabled: 1,
      valueInvalid: null
    };
  }
};
</script>
