<template>
  <a class="AppResourceTableLinkIcon" v-on="$listeners">
    <AppExternalLinkIcon size="small" />
  </a>
</template>

<script>
export default { name: 'AppResourceTableLinkIcon' };
</script>

<style scoped>
.AppResourceTableLinkIcon {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 4px;
  line-height: 0px;
}
</style>
