<template>
  <div class="table-line">
    <AppBadge
      v-if="review.recommendation_score_dirty"
      :label="$t('score_calculating')"
    />
    <AppNumber
      v-else
      :value="review.recommendation_score"
      :precision="1"
      :force-precision="true"
    />
  </div>
</template>

<script>
export default {
  name: 'ReviewReviewsAiRecommendationScoreCell',
  props: {
    review: {
      type: Object,
      required: true
    }
  }
};
</script>

<i18n locale="ko">
{
  "score_calculating": "점수 계산 중"
}
</i18n>
<i18n locale="en">
{
  "score_calculating": "calculating"
}
</i18n>
