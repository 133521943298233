<template>
  <AppResourceTableSearchbarIconButton
    button-type="dropdown"
    :label="$t('column_settings')"
    icon="icon-setting-small"
    @click="isColumnSettingsPopupVisible = !isColumnSettingsPopupVisible"
  >
    <template #dropdown>
      <AppModeless
        v-if="isColumnSettingsPopupVisible"
        class="dropdown__dialog"
        @close="isColumnSettingsPopupVisible = false"
      >
        <Component
          :is="
            columnsGrouped
              ? 'AppCheckboxDropdownGrouped'
              : 'AppCheckboxDropdown'
          "
          :items="items"
          @update="setColumnVisible"
        />
      </AppModeless>
    </template>
  </AppResourceTableSearchbarIconButton>
</template>

<script>
export default {
  name: 'AppResourceTableColumnSettingsButton',
  props: {
    columns: { type: Array, required: true }
  },
  data: () => ({ isColumnSettingsPopupVisible: false }),
  computed: {
    items() {
      return this.columns.map(column => ({
        id: column.columnId,
        label: column.label,
        disabled: column.required,
        checked: column.visible,
        button: column.buttonConfig,
        group: column.group
      }));
    },
    columnsGrouped() {
      return this.columns.every(column => column.group);
    }
  },
  methods: {
    setColumnVisible({ id, checked }) {
      this.$store.dispatch('editor/setColumnVisible', {
        columnId: id,
        visible: checked
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "column_settings": "항목 편집"
}
</i18n>
<i18n locale="en">
{
  "column_settings": "Column Setting"
}
</i18n>
