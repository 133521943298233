var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalForm',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.installationIds
      ? _vm.$t('sub_title', {
          count: _vm.installationIds.length
        })
      : '',"form-props":Object.assign({}, _vm.formProps)},scopedSlots:_vm._u([{key:"group-sub-item",fn:function(ref){
      var group = ref.group;
      var value = ref.value;
      var checked = ref.checked;
return [(group.id === 'duplicate_type')?[(value === _vm.SmartInstallationDuplicateType.DIFFERENT_SKIN_ID)?[_c('div',{staticClass:"AppForm__description"},[_vm._v(_vm._s(_vm.$t('skin.label')))]),_c('div',{staticClass:"AppForm__description"},[_vm._v(_vm._s(_vm.$t('skin.description')))]),_c('div',{staticClass:"AppForm__group-field"},[_c('AppTextInput',{staticClass:"ReviewSmartInstallationDuplicateInstallationsFormDialog__skin-id",attrs:{"id":"skin_id","name":"skin_id","placeholder":_vm.$t('skin.placeholder'),"disabled":!checked,"type":"text"}})],1)]:_vm._e()]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }