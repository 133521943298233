<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm
      class="TheAdvancedSettingsDialogBasic__form"
      object-id="basic_advanced_settings"
      submit-button
      v-bind="formProps"
      v-on="formEvents"
    />
  </AppAjaxContent>
</template>

<script>
import FormView from '@/mixins/FormView';
import BrandGrade from '@/enums/BrandGrade';
import api from '@/lib/api';

export default {
  name: 'TheAdvancedSettingsDialogBasic',
  mixins: [FormView],
  data() {
    return {
      isLoading: true
    };
  },
  computed: {
    formSections() {
      return [
        {
          id: 'basic',
          label: this.$t('title'),
          groups: [
            {
              id: 'brand_grade',
              label: this.$t('brand_grade.label'),
              type: 'select',
              options: BrandGrade.options()
            }
          ]
        }
      ];
    }
  },
  mounted() {
    this.fetchBasicAdvancedSettings();
  },
  methods: {
    fetchBasicAdvancedSettings() {
      this.isLoading = true;
      api
        .get('/admin/basic_advanced_settings')
        .then(({ data }) => {
          this.orgFormObject = data.basic_advanced_settings;
        })
        .finally(() => (this.isLoading = false));
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/admin/basic_advanced_settings', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => (this.orgFormObject = data.basic_advanced_settings))
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.TheAdvancedSettingsDialogBasic__form {
  width: 680px;
  border-radius: 4px;
  margin: 16px auto 32px auto;
  padding: 24px;
  background-color: #fff;
}
</style>

<i18n locale="ko">
  {
    "title": "기본 설정",
    "brand_grade": {
      "label": "거래액 등급"
    }
  }
</i18n>
