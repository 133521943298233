export default [
  {
    id: 'analytics_insight_settings',
    menuItemId: 'analytics_insight_settings',
    sections: [
      {
        id: 'product_analysis',
        groups: [{ id: 'exclude_products_without_sub_orders' }]
      }
    ]
  }
];
