import Vue from 'vue';
import api from '@/lib/api';

const REQUEST_ID = 'FETCH_PERIODICAL_DATA';
const POLL_FREQUENCY_MS = 5000;

const state = { timer: null, params: {}, latestData: {} };

const mutations = {
  SET_TIMER(state, timer) {
    state.timer = timer;
  },
  SET_POLL_PARAMS(state, { key, value }) {
    state.params[key] = value;
  },
  UNSET_POLL_PARAMS(state, key) {
    Vue.delete(state.params, key);
  },
  SET_LATEST_DATA(state, latestData) {
    state.latestData = latestData;
  }
};

const actions = {
  startPolling({ state, commit, dispatch }) {
    if (state.timer) return;

    dispatch('poll');
    const timer = setInterval(() => dispatch('poll'), POLL_FREQUENCY_MS);
    commit('SET_TIMER', timer);
  },
  stopPolling({ state, commit }) {
    api.cancel(REQUEST_ID);
    clearInterval(state.timer);
    commit('SET_TIMER', null);
  },
  poll({ state, commit }) {
    api
      .get('/user/periodical', { requestId: REQUEST_ID, params: state.params })
      .then(({ data }) => {
        commit('SET_LATEST_DATA', data);

        if (data.syncs) {
          data.syncs.forEach(sync => {
            commit(
              'sync/SET_SYNC',
              { brandSyncType: sync.brand_sync_type, sync: sync },
              { root: true }
            );
          });
        }

        if (data.fit_csvs)
          data.fit_csvs.forEach(csv =>
            commit('fitCsv/UPDATE_FIT_CSV', csv, { root: true })
          );

        if (data.calculate_status_updated_campaigns)
          data.calculate_status_updated_campaigns.forEach(campaign => {
            commit(
              'targetCampaigns/UPDATE_CAMPAIGN_CALCULATE_STATUS',
              campaign,
              { root: true }
            );
          });

        if (data.insight_notices)
          commit('session/SET_INSIGHT_NOTICES', data.insight_notices, {
            root: true
          });

        if (data.is_updating_fit_descriptions !== null) {
          commit(
            'fitSettings/SET_IS_UPDATING_FIT_DESCRIPTIONS',
            data.is_updating_fit_descriptions,
            { root: true }
          );
        }

        if (data.smartstore_status)
          commit('smartstore/SET_SMARTSTORE_STATUS', data.smartstore_status, {
            root: true
          });

        if (data.smartstore_access_accepted)
          commit(
            'smartstore/SET_SMARTSTORE_ACCESS_ACCEPTED',
            data.smartstore_access_accepted,
            { root: true }
          );

        if (data.onboarding_progress)
          commit('easyJoin/SET_ONBOARDING_PROGRESS', data.onboarding_progress, {
            root: true
          });
      });
  }
};

export default { namespaced: true, state, mutations, actions };
