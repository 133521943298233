import { constructEnum } from '@/lib/enum';
import i18n from '@/lib/i18n';
import TargetCampaignStatus from '@/enums/TargetCampaignStatus';
import TargetCampaignMediaType from '@/enums/TargetCampaignMediaType';

const TargetCampaignType = constructEnum('TargetCampaignType', {
  NEW_VISITOR_SIGNUP: 1000,
  NEW_MEMBER_REENGAGEMENT: 1010,
  CART_REMINDING: 2000,
  UNPAID_ORDER_REMINDING: 2010,
  MILEAGE_EXPIRING: 2020,
  COUPON_EXPIRY: 3010,
  INDUCING_REPURCHASE: 3020,
  INTERESTED_PRODUCTS: 3030,
  SELECTED_PRODUCTS: 3040,
  SELECTED_CATEGORIES: 3050,
  UTM_POPUP: 3060,
  MEMBER_INACTIVATION_PREVENTION: 3000,
  INACTIVE_MEMBER_REENGAGEMENT: 5010,
  ALL_MEMBERS: 4000,
  ACTIVE_MEMBERS: 4010,
  INACTIVE_MEMBERS: 4020,
  PRODUCT_RECOMMENDATION_DETAIL_PAGE: 6010,
  PRODUCT_RECOMMENDATION_CATEGORY_PAGE: 6020,
  PRODUCT_RECOMMENDATION_MAIN_PAGE: 6030,
  PRODUCT_RECOMMENDATION_CART_PAGE: 6040,
  PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT: 6050,
  PRODUCT_RECOMMENDATION_CART_POPUP: 6060
});

TargetCampaignType.creatableSituational = [
  TargetCampaignType.SELECTED_PRODUCTS,
  TargetCampaignType.SELECTED_CATEGORIES
];

TargetCampaignType.creatableMass = [
  TargetCampaignType.ALL_MEMBERS,
  TargetCampaignType.ACTIVE_MEMBERS,
  TargetCampaignType.INACTIVE_MEMBERS
];

TargetCampaignType.creatableProductRecommendationWidget = [
  TargetCampaignType.PRODUCT_RECOMMENDATION_MAIN_PAGE,
  TargetCampaignType.PRODUCT_RECOMMENDATION_CATEGORY_PAGE,
  TargetCampaignType.PRODUCT_RECOMMENDATION_DETAIL_PAGE,
  TargetCampaignType.PRODUCT_RECOMMENDATION_CART_PAGE,
  TargetCampaignType.PRODUCT_RECOMMENDATION_CART_POPUP,
  TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT
];

TargetCampaignType.isCreatable = function(campaignType) {
  return (
    this.creatableSituational.includes(campaignType) ||
    this.creatableMass.includes(campaignType) ||
    this.creatableProductRecommendationWidget.includes(campaignType) ||
    campaignType === TargetCampaignType.UTM_POPUP
  );
};

TargetCampaignType.situational = [
  TargetCampaignType.NEW_VISITOR_SIGNUP,
  TargetCampaignType.NEW_MEMBER_REENGAGEMENT,
  TargetCampaignType.CART_REMINDING,
  TargetCampaignType.UNPAID_ORDER_REMINDING,
  TargetCampaignType.MILEAGE_EXPIRING,
  TargetCampaignType.COUPON_EXPIRY,
  TargetCampaignType.INDUCING_REPURCHASE,
  TargetCampaignType.INTERESTED_PRODUCTS,
  TargetCampaignType.SELECTED_PRODUCTS,
  TargetCampaignType.SELECTED_CATEGORIES,
  TargetCampaignType.UTM_POPUP,
  TargetCampaignType.MEMBER_INACTIVATION_PREVENTION,
  TargetCampaignType.INACTIVE_MEMBER_REENGAGEMENT
];

TargetCampaignType.mass = [
  TargetCampaignType.ALL_MEMBERS,
  TargetCampaignType.ACTIVE_MEMBERS,
  TargetCampaignType.INACTIVE_MEMBERS
];

TargetCampaignType.productRecommendationWidget = [
  TargetCampaignType.PRODUCT_RECOMMENDATION_DETAIL_PAGE,
  TargetCampaignType.PRODUCT_RECOMMENDATION_CATEGORY_PAGE,
  TargetCampaignType.PRODUCT_RECOMMENDATION_MAIN_PAGE,
  TargetCampaignType.PRODUCT_RECOMMENDATION_CART_PAGE,
  TargetCampaignType.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT,
  TargetCampaignType.PRODUCT_RECOMMENDATION_CART_POPUP
];

TargetCampaignType.realtime = [
  TargetCampaignType.NEW_VISITOR_SIGNUP,
  TargetCampaignType.UTM_POPUP
];

TargetCampaignType.isSituational = function(campaignType) {
  return this.situational.includes(campaignType);
};

TargetCampaignType.isMass = function(campaignType) {
  return this.mass.includes(campaignType);
};

TargetCampaignType.isOneTime = function(campaignType) {
  return this.isMass(campaignType);
};

TargetCampaignType.isRealtime = function(campaignType) {
  return (
    this.realtime.includes(campaignType) ||
    this.isProductRecommendationWidget(campaignType)
  );
};

TargetCampaignType.isProductRecommendationWidget = function(campaignType) {
  return this.productRecommendationWidget.includes(campaignType);
};

TargetCampaignType.statusName = function(campaignType, status) {
  return this.isOneTime(campaignType) && status === TargetCampaignStatus.ENABLED
    ? i18n.t('TargetCampaignStatus.PENDING')
    : TargetCampaignStatus.t(status);
};

TargetCampaignType.shortName = function(campaignType) {
  return i18n.t(`TargetCampaignType.short_name.${this.key(campaignType)}`);
};

TargetCampaignType.fromCampaignMediaType = function(campaignMediaType) {
  switch (campaignMediaType) {
    case TargetCampaignMediaType.DIRECT_MESSAGE:
      return [
        this.COUPON_EXPIRY,
        this.INDUCING_REPURCHASE,
        this.INTERESTED_PRODUCTS,
        this.SELECTED_CATEGORIES,
        this.SELECTED_PRODUCTS,
        this.CART_REMINDING,
        this.UNPAID_ORDER_REMINDING,
        this.MILEAGE_EXPIRING,
        this.NEW_MEMBER_REENGAGEMENT,
        this.MEMBER_INACTIVATION_PREVENTION,
        this.INACTIVE_MEMBER_REENGAGEMENT,
        this.ALL_MEMBERS,
        this.ACTIVE_MEMBERS,
        this.INACTIVE_MEMBERS
      ];
    case TargetCampaignMediaType.POPUP:
      return [
        this.COUPON_EXPIRY,
        this.CART_REMINDING,
        this.UNPAID_ORDER_REMINDING,
        this.MILEAGE_EXPIRING,
        this.NEW_VISITOR_SIGNUP,
        this.MEMBER_INACTIVATION_PREVENTION,
        this.UTM_POPUP
      ];
    case TargetCampaignMediaType.WIDGET:
      return [
        this.PRODUCT_RECOMMENDATION_MAIN_PAGE,
        this.PRODUCT_RECOMMENDATION_CATEGORY_PAGE,
        this.PRODUCT_RECOMMENDATION_DETAIL_PAGE,
        this.PRODUCT_RECOMMENDATION_CART_PAGE,
        this.PRODUCT_RECOMMENDATION_REVIEW_HIGHLIGHT,
        this.PRODUCT_RECOMMENDATION_CART_POPUP
      ];
    default:
      return [];
  }
};

export default TargetCampaignType;
