<template>
  <AppModalDefault :title="$t('title')" :width="width" @close="close">
    <template #body>
      <AppImage :src="widgetPreviewImageUrl" />
    </template>
    <template #foot>
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';
import InstallationPresetWidgetType from '@/enums/InstallationPresetWidgetType';

export default {
  name: 'ReviewDashboardRequestInstallationWidgetPreviewDialog',
  mixins: [DialogView],
  props: {
    isMobile: { type: Boolean, default: false },
    presetWidgetType: { type: Number, default: 1 }
  },
  computed: {
    width() {
      return this.isMobile ? 'small' : 'wide';
    },
    widgetPreviewImageUrl() {
      const device = this.isMobile ? 'mobile' : 'pc';
      const presetWidgetTypeKey = InstallationPresetWidgetType.toKey(
        this.presetWidgetType
      ).toLowerCase();
      return `https://assets.cre.ma/m/admin/v2/preview_${device}_${presetWidgetTypeKey}.png`;
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "위젯 미리보기"
}
</i18n>
