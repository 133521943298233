<template>
  <AppAjaxContent :is-loading="!data">
    <AppForm v-if="data" v-bind="reviewFormProps" v-on="formEvents" />
  </AppAjaxContent>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/lib/api';
import ReviewSettingsView from './ReviewSettingsView';
import BizMessageCampaignType from '@/enums/BizMessageCampaignType';
import HttpStatus from '@/enums/HttpStatus';

export default {
  name: 'ReviewSettingsSuperAdminBizMessage',
  mixins: [ReviewSettingsView],
  data() {
    return {
      SETTINGS_ID: 'review_settings_super_admin_biz_message',
      SETTINGS_URL: 'review/settings/super_admin_biz_message',
      isSendingTestBizMessage: false,
      testBizMessageType: ['member', 'nonmember', 'offline']
    };
  },
  computed: {
    ...mapState('session', ['brandSettings']),
    groupOptions() {
      return {
        biz_message_unit_price: {
          type: 'number',
          digits: 2,
          default: 11,
          validate: ['positive_integer']
        },
        biz_message_test: {
          type: 'button',
          value: this.$t('biz_message_test'),
          description: this.$t('biz_message_test_description'),
          clickHandler: this.sendTestBizMessage,
          disabled: this.isSendingTestBizMessage
        }
      };
    }
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    bizMessageCampaignType(messageType) {
      switch (messageType) {
        case 'member':
          return BizMessageCampaignType.REVIEW_MEMBER;
        case 'nonmember':
          return BizMessageCampaignType.REVIEW_NONMEMBER;
        case 'offline':
          return BizMessageCampaignType.REVIEW_OFFLINE;
      }
    },
    sendTestBizMessage() {
      let to = prompt(this.$t('biz_message_test_prompt'));
      if (!to) return;

      to = to.replace(/[-\s]/g, '');

      const PHONE_REGEX = /^01[0-9]{8,9}$/;
      if (!PHONE_REGEX.test(to)) {
        alert(this.$t('biz_message_test_phone_invalid'));
        return;
      }

      this.isSendingTestBizMessage = true;
      this.testBizMessageType.forEach((key, i) => {
        const test_biz_message = {
          biz_message_test_phone_number: to,
          biz_message_test_campaign_type: this.bizMessageCampaignType(key)
        };

        api
          .post('/test_biz_messages', { test_biz_message })
          .then(({ status }) => {
            if (status === HttpStatus.OK) {
              this.createToast(this.$t('biz_message_test_finished', { to }));
            }
          })
          .finally(() => {
            this.setTestBizMessageButtonEnable(i);
          });
      });
    },
    setTestBizMessageButtonEnable(index) {
      if (index == this.testBizMessageType.length - 1) {
        this.isSendingTestBizMessage = false;
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "biz_message_test": "테스트 알림톡 발송",
  "biz_message_test_description": "<ul><li>발송 조건: 메시지 발송 대상에 체크되어있으며 설정된 템플릿이 있을 경우</li><li>발송 순서: 회원 → 비회원 → 매장 회원</li></ul>",
  "biz_message_test_prompt": "테스트 알림톡을 받을 번호를 입력해주세요.",
  "biz_message_test_phone_invalid": "잘못된 형식의 전화번호입니다.",
  "biz_message_test_finished": "테스트 알림톡을 `{to}`로 발송했습니다."
}
</i18n>
