<template>
  <div class="DisplayCornerDeleteButton" @click="deleteCorner">
    {{ $t('title') }}
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'DisplayCornerDeleteButton',
  props: { corner: { type: Object, required: true } },
  computed: mapGetters('displayProductDisplays', ['cornerPageTypeKey']),
  methods: {
    ...mapActions('dialog', ['openDialog']),
    deleteCorner() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t('title'),
          markdownText: this.$t(
            `confirm_delete_html.${this.cornerPageTypeKey}`,
            [this.corner.name]
          ),
          width: DialogSize.AUTO
        }
      ]).then(eventBus => {
        eventBus.$on('close', this.doDeleteCorner);
      });
    },
    doDeleteCorner() {
      api
        .delete(`/display/corners/${this.corner.id}`, {
          successMessage: this.$t('app.deleted')
        })
        .then(() => this.$emit('delete'));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.DisplayCornerDeleteButton {
  cursor: pointer;
  color: $color-grey-60;

  &:hover {
    text-decoration: underline;
  }
}
</style>

<i18n locale="ko">
{
  "title": "리스트에서 제거",
  "confirm_delete_html": {
    "MAIN": "{0}((을)) 리스트에서 제거 하시겠습니까?<br />해당 진열 위치는 메인페이지 진열 리스트에서만 제거되며 되돌릴 수 없습니다.",
    "CATEGORY": "{0}((을)) 리스트에서 제거 하시겠습니까?<br />해당 카테고리는 카테고리 페이지 진열 리스트에서만 제거되며 되돌릴 수 없습니다."
  }
}
</i18n>
