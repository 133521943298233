<template>
  <div class="AppFileField">
    <AppFileInput
      v-bind="{ id, name, placeholder, accept, eventBus, disabled }"
      class="AppFileField__file-input"
      @change="isFileSelected = true"
    />
    <AppButtonSubmit
      class="AppFileField__upload-file-button"
      :disabled="!isFileSelected"
      v-bind="{ isSubmitting }"
      :submit-label="$t('app.upload')"
      :submitting-label="$t('app.uploading')"
    />
  </div>
</template>

<script>
import AppFileInput from './AppFileInput';

export default {
  name: 'AppFileField',
  components: { AppFileInput },
  props: {
    id: { type: String, default: null },
    name: { type: String, default: null },
    placeholder: { type: String, default: null },
    accept: { type: String, default: null },
    eventBus: { type: Object, default: null },
    isSubmitting: { type: Boolean, required: true },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return { isFileSelected: false };
  },
  mounted() {
    if (this.eventBus)
      this.eventBus.$on('reset', () => (this.isFileSelected = false));
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_breakpoints.scss';

.AppFileField__file-input {
  display: inline-block;
  width: 372px;
  margin-right: 8px;
}

@include media-breakpoint-each(mobile) {
  .AppFileField__file-input {
    width: 100%;
    margin: 0 0 8px 0;
  }

  .AppFileField__upload-file-button {
    width: 100%;
  }
}
</style>
