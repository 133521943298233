<template>
  <div class="ReviewShopifyWidgetInstallation">
    <div class="ReviewShopifyWidgetInstallation__title">
      {{ $t('title') }}
    </div>

    <div class="ReviewShopifyWidgetInstallation__content">
      <div v-if="isInitialInstallation">
        <AppText :text="$t('installation_description')" />
        <AppButton
          class="mt4"
          :label="$t('install')"
          @click="openInstallDialog"
        />
      </div>
      <div v-else class="ReviewShopifyWidgetInstallation__button-wrapper">
        <div
          class="ReviewShopifyWidgetInstallation__button"
          @click="openUninstallDialog"
        >
          {{ $t('uninstall') }}
        </div>
        <div
          class="ReviewShopifyWidgetInstallation__button"
          @click="openReinstallDialog"
        >
          {{ $t('reinstall') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ReviewShopifyWidgetInstallation',
  computed: {
    ...mapGetters('session', ['isShopifyWidgetInitialInstallationUsable']),
    isInitialInstallation() {
      return this.isShopifyWidgetInitialInstallationUsable;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openInstallDialog() {
      this.openDialog('ReviewShopifyWidgetInitialInstallationDialog');
    },
    openUninstallDialog() {
      this.openDialog('ReviewShopifyWidgetUninstallationReinstallationDialog');
    },
    openReinstallDialog() {
      this.openDialog([
        'ReviewShopifyWidgetUninstallationReinstallationDialog',
        {
          isReinstallation: true
        }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewShopifyWidgetInstallation {
  padding-top: 28px;
}

.ReviewShopifyWidgetInstallation__title {
  font-size: medium;
  font-weight: bold;
  color: #424244;
  padding-bottom: 12px;
}

.ReviewShopifyWidgetInstallation__button-wrapper {
  display: flex;
}

.ReviewShopifyWidgetInstallation__button {
  margin-right: 12px;
  text-decoration: underline;
  cursor: pointer;
}
</style>

<i18n locale="ko">
  {
    "title": "위젯 설치 및 삭제 (Shopify vintage themes 전용)",
    "installation_description": "Vintage themes 를 사용하는 경우 반드시 아레 버튼을 눌러 기본 위젯을 설치해주세요.\n위젯이 설치되어야 리뷰 수집 및 노출이 가능합니다.",
    "install": "기본 위젯 설치",
    "uninstall": "모든 위젯 삭제",
    "reinstall": "기본 위젯 재설치"
  }
</i18n>
