<template>
  <AppModalDefault
    title=""
    is-maximized-by-default
    use-custom-title
    width="50%"
    @close="close"
  >
    <template #left>
      <DisplayProductDisplayControlTab
        v-if="!isLoading"
        @update-name-form-status="updateIsEditingName"
      />
    </template>
    <template #head-button>
      <AppAjaxContent
        :is-loading="isLoading"
        class="DisplayProductDisplayPreviewDialog__sync-products"
      >
        <DisplaySyncProductsButton
          :sync-products-now="syncProductsNow"
          @sync-products-now-updated="syncProductsNow = false"
          @update-product-synced-at="updateProductSyncedAt"
        />
      </AppAjaxContent>
    </template>
    <template #sub-head>
      <AppAjaxContent :is-loading="isLoading">
        <div class="DisplayProductDisplayPreviewDialog__title-wrapper">
          <span class="DisplayProductDisplayPreviewDialog__title">
            {{ $t('title') }}
          </span>
          <div class="DisplayProductDisplayPreviewDialog__button-wrapper">
            <AppButton
              :label="$t('button.calculate')"
              :bubble-message="
                resourceStatus === 'product_display_updated'
                  ? $t('button.calculation_needed')
                  : ''
              "
              @click="confirmCalculateProducts('default')"
            />
            <AppButton
              :label="$t('button.update')"
              :bubble-message="
                resourceStatus === 'empty_unit' ? $t('button.empty_unit') : ''
              "
              @click="openDisplayDialog"
            />
          </div>
        </div>
      </AppAjaxContent>
    </template>
    <template #body>
      <AppAjaxContent :is-loading="isLoading || isLoadingProducts">
        <DisplayCornerPreview
          v-if="display.products && display.products.length"
        />
        <div v-else class="DisplayProductDisplayPreviewDialog__no-content">
          <div class="DisplayProductDisplayPreviewDialog__no-content-title">
            {{ $t('no_content.title') }}
          </div>
          <div class="DisplayProductDisplayPreviewDialog__no-content-message">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="$t('no_content.message1_html')" />
            <p>{{ $t('no_content.message2') }}</p>
            <p>{{ $t('no_content.message3') }}</p>
          </div>
          <AppButton
            class="DisplayProductDisplayPreviewDialog__no-content-copy-button"
            :label="$t('no_content.copy_displayed_products')"
            @click="copyDisplayedProducts"
          />
        </div>
      </AppAjaxContent>
    </template>
    <template #foot>
      <AppAjaxContent :is-loading="isLoading || isLoadingProducts">
        <DisplayProductDisplayActions @close="close" />
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';
import DialogView from '@/mixins/DialogView';
import DisplayProductDisplayActions from './components/DisplayProductDisplayActions';
import DisplaySyncProductsButton from './components/DisplaySyncProductsButton';

export default {
  name: 'DisplayProductDisplayPreviewDialog',
  components: { DisplayProductDisplayActions, DisplaySyncProductsButton },
  mixins: [DialogView],
  props: {
    productDisplayId: { type: [Number, String], required: true },
    cornerPageType: { type: [Number, String], required: true }
  },
  data() {
    return {
      isLoading: true,
      isEditingName: false,
      unitsCount: null,
      productSyncedAt: null,
      isStockUnitAdded: false,
      syncProductsNow: false
    };
  },
  computed: {
    ...mapState('displayProductDisplay', ['display', 'isLoadingProducts']),
    ...mapGetters('displayProductDisplay', [
      'isDisplayDataChanged',
      'displayCornersFormData'
    ]),
    calculationRequiredStatus() {
      return ['product_updated', 'product_display_updated'];
    },
    resourceStatus() {
      const {
        isLoading,
        unitsCount,
        isStockUnitAdded,
        display,
        productSyncedAt
      } = this;

      if (isLoading) return null;

      if (unitsCount === 0) return 'empty_unit';
      else if (isStockUnitAdded) return 'stock_unit_added';
      else if (display.calculated_at) {
        if (
          productSyncedAt &&
          moment(display.calculated_at) < moment(productSyncedAt)
        )
          return 'product_updated';
        else return null;
      } else return 'product_display_updated';
    }
  },
  watch: {
    resourceStatus() {
      if (this.calculationRequiredStatus.includes(this.resourceStatus))
        this.confirmCalculateProducts(this.resourceStatus);
      else if (this.resourceStatus === 'stock_unit_added')
        this.confirmSyncProducts(this.resourceStatus);
    }
  },
  created() {
    this.SET_CORNER_PAGE_TYPE(Number(this.cornerPageType));
    this.fetchDisplays();
  },
  beforeDestroy() {
    this.switchDisplayCorner({});
    this.SET_ORG_DISPLAY({});
    this.CLEAR_MARKED_PRODUCT();
  },
  methods: {
    ...mapMutations('displayProductDisplays', [
      'SET_PRODUCT_DISPLAYS',
      'SET_CORNER_PAGE_TYPE',
      'SET_POLLING'
    ]),
    ...mapMutations('displayPreviewSettings', ['UPDATE_PREVIEW_SETTINGS']),
    ...mapMutations('displayProductDisplay', [
      'SET_ORG_DISPLAY',
      'UPDATE_ORG_DISPLAY_CORNER_DETAILS',
      'UPDATE_DISPLAY',
      'CLEAR_MARKED_PRODUCT'
    ]),
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('displayProductDisplay', [
      'calculateProducts',
      'copyDisplayedProducts',
      'switchDisplayCorner'
    ]),
    openDisplayDialog() {
      this.openDialog([
        'DisplayProductDisplayFormDialog',
        { productDisplayId: Number(this.productDisplayId) }
      ]).then(eventBus => {
        eventBus.$on('submit', ({ unitsCount, isStockUnitAdded }) => {
          this.UPDATE_DISPLAY({ calculated_at: null });
          this.unitsCount = unitsCount;
          this.isStockUnitAdded = isStockUnitAdded;
        });
      });
    },
    fetchDisplays() {
      api
        .get('/display/product_displays', {
          params: { page_type: this.cornerPageType }
        })
        .then(({ data }) => {
          this.SET_PRODUCT_DISPLAYS(data.product_displays);
          const display = data.product_displays.find(
            ({ id }) => id === Number(this.productDisplayId)
          );
          this.fetchDisplayPreviewData(display);
        });
    },
    fetchDisplayPreviewData({ id, name, corners }) {
      api
        .get(`/display/product_displays/${id}/preview`, {
          params: { page_type: this.cornerPageType }
        })
        .then(({ data }) => {
          this.SET_ORG_DISPLAY({ id, name, corners, ...data.product_display });
          this.UPDATE_ORG_DISPLAY_CORNER_DETAILS(data.corner_previews);
          this.switchDisplayCorner(corners[0]);

          this.unitsCount = data.units_count;
          this.UPDATE_PREVIEW_SETTINGS(data.preview_settings);
        })
        .finally(() => (this.isLoading = false));
    },
    confirmCalculateProducts(key) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t(`calculate_dialog.${key}.title`),
          markdownText: this.$t(`calculate_dialog.${key}.content_html`),
          snoozeId: `DisplayProductDisplayPreviewDialog.calculate_dialog.${key}`,
          closeButtonLabel: this.$t('calculate_dialog.close'),
          cancelButtonLabel: this.$t('calculate_dialog.cancel'),
          width: DialogSize.AUTO
        }
      ])
        .then(eventBus => {
          eventBus.$on('close', this.calculateProducts);
        })
        .catch(() => {
          if (key === 'default') this.calculateProducts();
        });
    },
    confirmSyncProducts(key) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t(`sync_dialog.${key}.title`),
          markdownText: this.$t(`sync_dialog.${key}.content_html`),
          closeButtonLabel: this.$t(`sync_dialog.${key}.close`),
          cancelButtonLabel: this.$t('sync_dialog.cancel'),
          width: DialogSize.AUTO
        }
      ]).then(eventBus =>
        eventBus.$on('close', () => (this.syncProductsNow = true))
      );
    },
    updateIsEditingName(isEditingName) {
      this.isEditingName = isEditingName;
    },
    updateProductSyncedAt(productSyncedAt) {
      this.productSyncedAt = productSyncedAt;
    },
    close() {
      if (!this.isEditingName && !this.isDisplayDataChanged) this.closeDialog();
      else if (confirm(this.$t('app.confirm_discard_change_navigate')))
        this.closeDialog();
      this.SET_POLLING(true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.DisplayProductDisplayPreviewDialog__sync-products {
  display: inline-block;
}

.DisplayProductDisplayPreviewDialog__title-wrapper {
  display: flex;
  padding-right: 10px;
}

.DisplayProductDisplayPreviewDialog__title {
  @include text-sub-title;

  flex: 1;
}

.DisplayProductDisplayPreviewDialog__button-wrapper {
  flex: 1;
  margin-top: 4px;
  text-align: right;
}

.DisplayProductDisplayPreviewDialog__no-content {
  height: calc(100vh - 192px);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.DisplayProductDisplayPreviewDialog__no-content-title {
  @include text-title;
}

.DisplayProductDisplayPreviewDialog__no-content-message {
  margin-top: 24px;
  @include text-content;

  p + p {
    margin-top: 14px;
  }
}

.DisplayProductDisplayPreviewDialog__no-content-copy-button {
  margin-top: 14px;
}
</style>

<i18n locale="ko">
{
  "title": "미리보기",
  "button": {
    "calculate": "진열 규칙 적용",
    "calculation_needed": "변경된 진열 규칙이 있습니다!",
    "update": "진열 규칙 설정",
    "empty_unit": "먼저 진열 규칙을 설정해 주세요 :)"
  },
  "calculate_dialog": {
    "default": {
      "title": "설정된 진열 규칙을 바탕으로 미리보기를 불러옵니다.",
      "content_html": "고정, 진열 제외를 제외한 수정사항이 모두 초기화됩니다.<br />진열 규칙을 적용하시겠습니까?"
    },
    "product_display_updated": {
      "title": "진열 규칙을 바로 적용하시겠습니까?",
      "content_html": "변경한 진열 규칙을 바탕으로 미리보기를 생성하기 원하면<br />진열 규칙 적용 버튼을 눌러주세요."
    },
    "product_updated": {
      "title": "상품 정보가 업데이트 되었습니다.",
      "content_html": "지난 미리보기 생성 이후로 상품 정보 동기화를 통해 상품 정보가<br />업데이트 되었습니다. 진열 규칙 적용 버튼을 눌러 해당 상품 정보를<br />미리보기에 적용시킬 수 있습니다.<br /><br />미리보기 적용 시 진열 규칙을 바탕으로 미리보기가 갱신되며<br />위치 이동, 고정과 같은 이전 변경 사항은 모두 사라집니다."
    },
    "close": "진열 규칙 적용",
    "cancel": "나중에"
  },
  "sync_dialog": {
    "stock_unit_added": {
      "title": "상품 정보 동기화가 필요합니다.",
      "content_html": "재고 관련 규칙이 추가되어 상품 재고 정보 동기화가 필요합니다.<br />지금 상품 재고 동기화를 진행 하시겠습니까?",
      "close": "상품 재고 동기화 실행"
    },
    "cancel": "나중에"
  },
  "no_content": {
    "title": "설정된 진열이 없습니다!",
    "message1_html": "1. 상단 진열 '<b>규칙 설정 버튼</b>'을 눌러 규칙 설정 후",
    "message2": "2. 원하는 위치에 상품을 진열할 수 있도록 위치를 수정해보세요.",
    "message3": "또는 현재 쇼핑몰에 진열된 상품을 그대로 불러올 수 있습니다.",
    "copy_displayed_products": "현재 쇼핑몰 진열 상품 불러오기"
  }
}
</i18n>
