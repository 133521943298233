import _ from 'lodash';
import { filterItemsWithHighlightedText } from '@/lib/text-search';
import i18n from '@/lib/i18n';
import api from '@/lib/api';
import SearchCategory from '@/enums/SearchCategory';

const state = {
  query: '',
  recommendedKeywords: ['실측 사이즈 매뉴얼', '사이즈 순서 조정'],
  isSearchDataReady: false,
  manuals: []
};

const mutations = {
  SET_QUERY(state, query) {
    state.query = query;
  },
  SET_DATA(state, { manuals }) {
    state.manuals = manuals;
    state.isSearchDataReady = true;
  }
};

const actions = {
  searchQuery({ rootGetters, commit, dispatch }, query) {
    commit('SET_QUERY', query);

    const requestId = 'FETCH_SEARCH';
    if (
      rootGetters['request/isRequestPending'](requestId) ||
      rootGetters['request/isRequestSuccess'](requestId)
    )
      return;

    api.get('/search', { requestId: requestId }).then(({ data }) => {
      const manuals = data.manuals.map(item => ({
        ...item,
        category: SearchCategory.MANUALS,
        subCategory: 'manuals'
      }));

      commit('SET_DATA', { manuals: manuals });
      dispatch('editor/filterRecentPages', manuals, { root: true });
    });
  }
};

const getters = {
  unfilteredSearchResults({ manuals }, _getters, _rootState, rootGetters) {
    const toSearchItem = (menuItem, elementId) => {
      switch (menuItem.type) {
        case 'dialog':
          return { dialog: [menuItem.dialog, { elementId }] };
        case 'dialog_tab':
          return {
            dialog: [menuItem.dialog, { initialTab: menuItem.id, elementId }]
          };
        case 'external_link':
          return { url: menuItem.path };
        default:
          return { path: menuItem.path, isOldAdmin: menuItem.isOldAdmin };
      }
    };

    const menuItems = [
      ...rootGetters['menu/mainMenuItems'],
      ...rootGetters['menu/subMenuItems'],
      ...rootGetters['menu/dialogItems'],
      ...rootGetters['menu/tabItems'],
      ...rootGetters['menu/externalLinkItems']
    ].map(item => {
      const titles = [];
      let currentItem = item;
      while (currentItem) {
        titles.unshift(currentItem.label);
        currentItem = rootGetters['menu/menuItem'](currentItem.parentId);
      }

      const searchItem = {
        title: titles.join(' / '),
        category: SearchCategory.MENU,
        ...toSearchItem(item)
      };

      return searchItem;
    });

    const settingsItems = [];
    rootGetters['settings/accessibleSettings'].forEach(function(settings) {
      const menuItem = rootGetters['menu/menuItem'](settings.menuItemId);
      if (!menuItem) return;

      const menuItemTitles = [];
      let currentItem = menuItem;
      while (currentItem) {
        menuItemTitles.unshift(currentItem.label);
        currentItem = rootGetters['menu/menuItem'](currentItem.parentId);
      }

      settings.sections.forEach(function(section) {
        const sectionTitle =
          section.id && !section.hideLabel
            ? i18n.t(`settings.${settings.id}.sections.${section.id}`)
            : null;

        if (sectionTitle) {
          settingsItems.push({
            title: sectionTitle,
            category: SearchCategory.SETTINGS,
            ...toSearchItem(menuItem, section.id)
          });
        }

        section.groups.forEach(function(group) {
          const groupTitle = i18n.t(
            `settings.${settings.id}.groups.${group.id}`
          );
          const titles = [
            ...menuItemTitles,
            ...(sectionTitle ? [sectionTitle] : []),
            groupTitle
          ];
          const hash = `${section.id ? `${section.id}--` : ''}${group.id}`;
          settingsItems.push({
            title: titles.join(' / '),
            category: SearchCategory.SETTINGS,
            hash,
            ...toSearchItem(menuItem, hash)
          });
        });

        section.advancedGroups?.forEach(function(group) {
          const groupTitle = i18n.t(
            `settings.${settings.id}.groups.${group.id}`
          );
          const titles = [
            ...menuItemTitles,
            ...(sectionTitle ? [sectionTitle] : []),
            i18n.t('app.advanced_settings'),
            groupTitle
          ];
          const hash = `${section.id ? `${section.id}--` : ''}${group.id}`;
          settingsItems.push({
            title: titles.join(' / '),
            category: SearchCategory.SETTINGS,
            hash,
            ...toSearchItem(menuItem, hash)
          });
        });
      });
    });

    return [...menuItems, ...settingsItems, ...manuals];
  },
  searchResults: (
    { query },
    { unfilteredSearchResults },
    _rootState,
    { oldAdminUrl, pathWithBrandParams }
  ) => category => {
    let items = unfilteredSearchResults.filter(r => !r.not_searchable);
    if (category !== SearchCategory.ALL)
      items = items.filter(r => r.category === category);
    items = filterItemsWithHighlightedText(items, 'title', query, 'b');

    const MAX_SEARCH_RESULTS = 20;
    return _.take(items, MAX_SEARCH_RESULTS).map(item => ({
      ...item,
      path: item.path
        ? item.isOldAdmin
          ? oldAdminUrl(item.path)
          : pathWithBrandParams(item.path, item.hash)
        : null
    }));
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
