<template>
  <input
    :value="currentValue"
    v-bind="{
      id,
      name,
      disabled,
      placeholder: placeholder || defaultValue
    }"
    :class="[
      'AppNumberInput',
      `AppNumberInput--digits-${digits}`,
      { 'AppNumberInput--invalid': invalid, 'AppNumberInput--inline': inline }
    ]"
    :min="min !== null ? min : allowNegative ? null : 0"
    :max="max"
    type="number"
    :step="Math.pow(10, -floatPrecision)"
    @paste="paste"
    @keydown="keydown"
    @input="change($event.target.value)"
    @blur="blur"
  />
</template>

<script>
import _ from 'lodash';

export default {
  name: 'AppNumberInput',
  model: {
    event: 'change'
  },
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    digits: {
      type: Number,
      required: true
    },
    value: {
      type: [String, Number],
      default: null
    },
    invalid: {
      type: Boolean,
      default: false
    },
    default: {
      type: Number,
      default: null
    },
    allowNegative: {
      type: Boolean,
      default: false
    },
    allowDecimal: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    floatPrecision: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      currentValue: this.toValidNumber(this.value)
    };
  },
  computed: {
    defaultValue() {
      return _.toString(this.default);
    },
    forbiddenChars() {
      const chars = ['e'];
      if (!this.allowDecimal && !this.floatPrecision) chars.push('.');
      if (!this.allowNegative) chars.push('-');
      return chars;
    },
    isInsane() {
      if (this.allowDecimal || !this.currentValue) return false;

      return this.currentValue >= Math.pow(10, this.digits + 2);
    }
  },
  watch: {
    value() {
      if (this.value !== this.currentValue)
        this.currentValue = this.toValidNumber(this.value);
    }
  },
  methods: {
    paste(e) {
      const orgText = e.clipboardData.getData('text');
      const newText = this.forbiddenChars.reduce(
        (s, c) => s.replace(new RegExp(_.escapeRegExp(c), 'g'), ''),
        orgText
      );
      if (orgText === newText) return;

      this.change(newText);
      event.preventDefault();
    },
    change(newValue) {
      const oldValue = this.currentValue;
      this.currentValue = this.toNumber(newValue);
      this.$emit('change', this.currentValue);

      if (this.isInsane) {
        this.$nextTick(() => {
          this.currentValue = oldValue;
          this.$emit('change', oldValue);
        });
      }
    },
    toNumber(value) {
      return value || value === 0 ? _.toNumber(value) : null;
    },
    toValidNumber(value) {
      const number = this.toNumber(value);
      return number === null ? this.default : number;
    },
    keydown(event) {
      if (this.forbiddenChars.includes(event.key)) event.preventDefault();
      if (event.key === 'Enter') this.blur(event);
    },
    blur(event) {
      let newValue = this.toValidNumber(event.target.value);
      if (newValue && this.floatPrecision)
        newValue = newValue.toFixed(this.floatPrecision);
      if (this.currentValue != newValue) this.change(newValue);
      this.$emit('blur');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppNumberInput {
  @include input-base;
  @include input-placeholder;

  max-width: 100%;
  padding: 5px 2px 5px 11px;

  @for $i from 1 through 20 {
    &--digits-#{$i} {
      width: 10px * $i + 30px;
    }
  }
}
</style>
