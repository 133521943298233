import api from '@/lib/api';

export default {
  data() {
    return { periodicalRequestTimer: null };
  },
  created() {
    this.periodicalRequestTimer = setInterval(this.periodicalRequest, 3000);
    this.periodicalRequest();
  },
  beforeDestroy() {
    clearInterval(this.periodicalRequestTimer);
  },
  methods: {
    periodicalRequest() {
      api.get(this.periodicalRequestPath).then(this.periodicalRequestCallback);
    }
  }
};
