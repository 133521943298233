var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppSearchbar',{on:{"submit":_vm.searchResource},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('li',[_c('AppButtonSyncProduct',{attrs:{"calculate-product-data":_vm.currentBrand.use_product_data}})],1)]},proxy:true},{key:"right",fn:function(){return [_c('li',[_c('AppSearchWithType',{attrs:{"search-types":_vm.SEARCH_TYPES},model:{value:(_vm.searchWithType),callback:function ($$v) {_vm.searchWithType=$$v},expression:"searchWithType"}})],1),_c('li',[_c('AppDateRangeWithType',{attrs:{"date-types":_vm.DATE_TYPES},on:{"apply":_vm.searchResource},model:{value:(_vm.dateRangeWithType),callback:function ($$v) {_vm.dateRangeWithType=$$v},expression:"dateRangeWithType"}})],1),_c('li',[_c('AppButtonToggle',{attrs:{"label":_vm.$t('app.advanced_search')},model:{value:(_vm.isAdvancedSearchVisible),callback:function ($$v) {_vm.isAdvancedSearchVisible=$$v},expression:"isAdvancedSearchVisible"}})],1)]},proxy:true},{key:"advanced-search",fn:function(){return [_c('AppAdvancedSearch',{attrs:{"visible":_vm.isAdvancedSearchVisible,"sections":_vm.advancedSearchSections},on:{"submit":_vm.searchResource},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
return [(id === 'org_price_range')?[_c('div',{staticClass:"AppForm__group-field"},[_c('i18n',{attrs:{"path":_vm.currencyKey}},[_c('span',[_c('AppNumberInput',{attrs:{"inline":"","digits":6},model:{value:(_vm.resourceParams.min_org_price),callback:function ($$v) {_vm.$set(_vm.resourceParams, "min_org_price", $$v)},expression:"resourceParams.min_org_price"}}),_vm._v(" ~  "),_c('AppNumberInput',{attrs:{"inline":"","digits":6},model:{value:(_vm.resourceParams.max_org_price),callback:function ($$v) {_vm.$set(_vm.resourceParams, "max_org_price", $$v)},expression:"resourceParams.max_org_price"}})],1)])],1)]:(id === 'sub_brand_ids')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectSearchable',{attrs:{"title":_vm.$t('sub_brand_ids.select'),"placeholder":_vm.$t('sub_brand_ids.placeholder'),"items":_vm.subBrands,"columns":[{ id: 'name', label: _vm.$t('sub_brand_ids.name') }],"search-types":[
                  { value: 'name', label: _vm.$t('sub_brand_ids.name') }
                ],"multiple":""},on:{"search":_vm.searchSubBrands,"change":function($event){_vm.$set(_vm.resourceParams, 'sub_brand_ids', $event.join(','))}}})],1)]:(id === 'final_price_range')?[_c('div',{staticClass:"AppForm__group-field"},[_c('i18n',{attrs:{"path":_vm.currencyKey}},[_c('span',[_c('AppNumberInput',{attrs:{"inline":"","digits":6},model:{value:(_vm.resourceParams.min_final_price),callback:function ($$v) {_vm.$set(_vm.resourceParams, "min_final_price", $$v)},expression:"resourceParams.min_final_price"}}),_vm._v(" ~  "),_c('AppNumberInput',{attrs:{"inline":"","digits":6},model:{value:(_vm.resourceParams.max_final_price),callback:function ($$v) {_vm.$set(_vm.resourceParams, "max_final_price", $$v)},expression:"resourceParams.max_final_price"}})],1)])],1)]:(id === 'misc')?[_c('div',{staticClass:"AppForm__group-field"},[_c('AppHashSelectButton',{attrs:{"select-buttons":[
                  {
                    id: 'misc',
                    options: [
                      {
                        label: _vm.$t('search.misc.not_deleted'),
                        value: 'not_deleted'
                      },
                      { label: _vm.$t('search.misc.deleted'), value: 'deleted' }
                    ]
                  }
                ]},model:{value:(_vm.resourceParams),callback:function ($$v) {_vm.resourceParams=$$v},expression:"resourceParams"}})],1)]:_vm._e()]}}]),model:{value:(_vm.resourceParams),callback:function ($$v) {_vm.resourceParams=$$v},expression:"resourceParams"}})]},proxy:true}])}),_c('AppResourceTable',{attrs:{"table-id":"products-table","enable-refresh":"","resources":_vm.products,"columns":_vm.columns,"rows":_vm.rows},on:{"refresh":_vm.refreshResource,"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
                var row = ref.row;
                var column = ref.column;
                var value = ref.value;
                var rowIndex = ref.rowIndex;
return [(column === 'code')?[_c('div',[_vm._v(_vm._s(row.code))])]:(column === 'price')?[_c('div',{staticClass:"table-line"},[_vm._v(" "+_vm._s(_vm.$t('org_price'))+" "+_vm._s(_vm._f("formatCurrency")(row.org_price_cents))+" ")]),_c('div',{staticClass:"table-line"},[_vm._v(" "+_vm._s(_vm.$t('final_price'))+" "+_vm._s(_vm._f("formatCurrency")(row.final_price_cents))+" ")])]:(column === 'product_category_product_type')?[(row.product_category_product_type_id)?_c('AppBadge',{attrs:{"badge-style":"grey-outline","label":_vm.productCategoryProductTypeNamesMap[
              row.product_category_product_type_id
            ]}}):[_vm._v(_vm._s('-'))]]:(column === 'categories')?[(!value.length)?[_vm._v("-")]:_vm._e()]:(column === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('actions.copy_review')},on:{"click":function($event){return _vm.openDialog([
              'ProductCopyReviewsDialog',
              { id: row.id, name: row.name }
            ])}}}),_c('AppButton',{attrs:{"label":_vm.$t('actions.edit_product')},on:{"click":function($event){return _vm.clickEditProductButton(row, rowIndex)}}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }