var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppContainer',[(_vm.isPopupCampaign)?_c('AppInfoBox',{staticClass:"TargetCampaignSettingsCoupon__popup-info-box"},[_vm._v(_vm._s(_vm.$t('popup_description'))+" ")]):_vm._e(),_c('AppDataItem',{attrs:{"label":_vm.$t('title')}},[_c('div',{staticClass:"table-head-control"},[_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('add_coupon'),"menu-items":[
          { label: _vm.$t('create_coupon'), clickHandler: _vm.clickCreateCoupon },
          { label: _vm.$t('select_coupon'), clickHandler: _vm.clickSelectCoupon }
        ]}})],1),_c('AppSmallDeviceScrollable',[_c('AppTable',{attrs:{"columns":[
          { id: 'name', label: _vm.$t('app.name'), align: 'left' },
          {
            id: 'image',
            label: _vm.$t('app.image'),
            type: 'img',
            imgHeight: '56px'
          },
          {
            id: 'period',
            label: _vm.$t('period'),
            align: 'right',
            width: '264px'
          },
          { id: 'actions', label: _vm.$t('app.actions'), width: '200px' }
        ],"rows":_vm.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
        var column = ref.column;
        var row = ref.row;
return [(column.id === 'period')?[_c('div',{staticClass:"TargetCampaignSettingsCoupon__period"},[(row.start_at && row.end_at)?[_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutSecond")(row.start_at))+" ~ "+_vm._s(_vm._f("formatDateTimeWithoutSecond")(row.end_at))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('unlimited'))+" ")]],2),_c('div',{staticClass:"TargetCampaignSettingsCoupon__status"},[_c('span',[_vm._v(_vm._s(_vm.$t(("coupon_status." + (row.coupon_status)))))]),_c('i',{class:[
                  'TargetCampaignSettingsCoupon__status-icon',
                  ("TargetCampaignSettingsCoupon__status-icon--" + (row.coupon_status))
                ]})])]:(column.id === 'actions')?[_c('AppButtonDraggable',{attrs:{"disabled":_vm.isArchivedCampaign},on:{"sort":_vm.sortCoupons}}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"disabled":_vm.isArchivedCampaign,"menu-items":[
                {
                  label: _vm.$t('app.settings'),
                  clickHandler: function (e) { return _vm.clickEditCoupon(e, row.id); }
                },
                {
                  label: _vm.$t('app.delete'),
                  style: 'danger',
                  clickHandler: function (e) { return _vm.clickUnselectCoupon(e, row.id); }
                }
              ]}})]:_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }