import { constructEnum } from '@/lib/enum';

export default constructEnum('TargetCohortHistoryType', {
  CREATED: 10,
  CALCULATION: 20,
  PREPARATION: 30,
  PRECOMPUTATION: 40,
  DISPATCH: 50,
  ERROR_SCHEDULING: 1000,
  ERROR_CALCULATING: 1010,
  ERROR_PREPARING: 1020,
  ERROR_DISPATCHING: 1070
});
