export default [
  {
    id: 'qa_jpeg_simulations',
    type: 'sub_menu',
    path: '/qa/jpeg-simulations',
    parentId: 'qa',
    label: 'Jpeg Simulations'
  },
  {
    id: 'qa_new_feature_settings_server',
    type: 'sub_menu',
    path: '/qa/new-feature-settings-server',
    parentId: 'qa',
    label: '전체 적용 기능 ON/OFF 설정'
  },
  {
    id: 'qa_new_feature_settings_brand',
    type: 'sub_menu',
    path: '/qa/new-feature-settings-brand',
    parentId: 'qa',
    label: '쇼핑몰 적용 기능 ON/OFF 설정'
  }
].map(e => ({ ...e, superAdmin: true }));
