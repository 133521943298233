<template>
  <div class="AppChartPercentageBar">
    <div class="AppChartPercentageBar__bar">
      <div
        :class="[
          'AppChartPercentageBar__fill',
          `AppChartPercentageBar__fill--${chartColor}`
        ]"
        :style="{ width: `${percentage || 0}%` }"
      />
    </div>
    <slot name="label" v-bind="{ percentage }">
      <span class="AppChartPercentageBar__label">
        <AppNumber
          :value="percentage"
          :precision="2"
          unit="percentage"
          placeholder="-%"
        />
        (<AppNumber :value="currentNumerator" placeholder="-" />/<AppNumber
          :value="currentDenominator"
          placeholder="-"
        />)
      </span>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'AppChartPercentageBar',
  props: {
    numerator: { type: Number, required: true },
    denominator: { type: Number, required: true },
    chartColor: { type: String, default: 'blue' }
  },
  computed: {
    percentage() {
      return this.denominator
        ? (this.numerator / this.denominator) * 100
        : null;
    },
    currentNumerator() {
      return this.denominator ? this.numerator : null;
    },
    currentDenominator() {
      return this.denominator || null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppChartPercentageBar {
  white-space: nowrap;
}

.AppChartPercentageBar__bar {
  display: inline-block;
  vertical-align: middle;
  width: 180px;
  height: 12px;
  background-color: $color-grey-15;
}

.AppChartPercentageBar__fill {
  height: 100%;

  &--blue {
    background-color: $color-blue;
  }

  &--mint-green {
    background-color: $color-mint-green;
  }

  &--grey-60 {
    background-color: $color-grey-60;
  }

  &--red {
    background-color: $color-red;
  }
}

.AppChartPercentageBar__label {
  margin-left: 8px;
  vertical-align: middle;
}
</style>
