import WidgetInstallRequestProgressStep from '@/enums/WidgetInstallRequestProgressStep';

const state = {
  widgetInstallRequestCurrentStep:
    WidgetInstallRequestProgressStep.PRODUCT_DETAIL,
  reviewBoardWidgetCombinationTypes: []
};

const mutations = {
  SET_WIDGET_INSTALL_REQUEST_STEP(state, widgetInstallRequestCurrentStep) {
    state.widgetInstallRequestCurrentStep = widgetInstallRequestCurrentStep;
  },
  SET_REVIEW_BOARD_WIDGET_COMBINATION_TYPES(state, combinationTypes) {
    state.reviewBoardWidgetCombinationTypes = combinationTypes;
  },
  ADD_REVIEW_BOARD_WIDGET_COMBINATION_TYPES(state, combinationType) {
    state.reviewBoardWidgetCombinationTypes.push(combinationType);
  },
  DELETE_REVIEW_BOARD_WIDGET_COMBINATION_TYPES(state, index) {
    state.reviewBoardWidgetCombinationTypes.splice(index, 1);
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
