import { constructEnum } from '@/lib/enum';

const ActionForInsufficientReviewLengthToGetMileageType = constructEnum(
  'ActionForInsufficientReviewLengthToGetMileageType',
  {
    ALERT: 10,
    LIMIT: 20,
    DEFAULT: 30
  }
);

export default ActionForInsufficientReviewLengthToGetMileageType;
