import api from '@/lib/api';

const cache = {};

function checkBrandNameDuplicationCache(name) {
  if (!name) return true;
  return cache[name];
}

function checkBrandNameDuplication(name) {
  return new Promise(function(resolve, reject) {
    if (cache[name]) return resolve(cache[name]);

    api
      .get('/validations/check_brand_name_duplication', {
        params: { brand_name: name }
      })
      .then(({ data }) => {
        if (data.duplicated) {
          cache[name] = false;
          reject();
        } else {
          cache[name] = true;
          resolve();
        }
      })
      .catch(reject);
  });
}

export { checkBrandNameDuplicationCache, checkBrandNameDuplication };
