import api from '@/lib/api';
import SmartInstallationType from '@/enums/SmartInstallationType';
import WidgetPageType from '@/enums/WidgetPageType';

const state = {
  menuType: '',
  widgetInstallationParams: {
    pageType: null,
    widgetDeviceType: null,
    skinId: null
  },
  hideElements: [],
  replaceReviewsCounts: [],
  newReviewLinks: [],
  productReviewsLinks: [],
  myPendingReviewsCounts: [],
  myWrittenReviewsCounts: []
};

const mutations = {
  SET_MENU_TYPE(state, menuType) {
    state.menuType = menuType;
  },
  SET_WIDGET_INSTALLATION_PARAMS(state, params) {
    state.widgetInstallationParams.pageType =
      WidgetPageType[params.tab?.toUpperCase()];
    state.widgetInstallationParams.widgetDeviceType = params.search_device_type;
    state.widgetInstallationParams.skinId = params.search_skin_id;
  },
  SET_HIDE_ELEMENTS(state, installations) {
    state.hideElements = installations;
  },
  SET_REPLACE_REVIEWS_COUNTS(state, installations) {
    state.replaceReviewsCounts = installations;
  },
  SET_NEW_REVIEW_LINKS(state, installations) {
    state.newReviewLinks = installations;
  },
  SET_PRODUCT_REVIEWS_LINKS(state, installations) {
    state.productReviewsLinks = installations;
  },
  SET_MY_PENDING_REVIEWS_COUNTS(state, installations) {
    state.myPendingReviewsCounts = installations;
  },
  SET_MY_WRITTEN_REVIEWS_COUNTS(state, installations) {
    state.myWrittenReviewsCounts = installations;
  }
};

const actions = {
  setMenuType({ commit }, menuType) {
    commit('SET_MENU_TYPE', menuType);
  },
  setWidgetInstallationParams: ({ commit }, params) => {
    commit('SET_WIDGET_INSTALLATION_PARAMS', params);
  },
  fetchInstallSimulationsWidgets: ({ commit }) => {
    const params = {
      page_type: state.widgetInstallationParams.pageType,
      widget_device_type: state.widgetInstallationParams.widgetDeviceType,
      skin_id: state.widgetInstallationParams.skinId
    };

    return api
      .get('/install_simulation/widgets', { params })
      .then(({ data }) => {
        commit(
          'reviewWidgets/SET_REVIEW_WIDGETS',
          widgetsWithInstallations(data.widgets, data.smart_installations),
          { root: true }
        );
      });
  },
  fetchInstallSimulationsNonWidgets: ({ commit }, installationType) => {
    const params = { installation_type: installationType };
    return api
      .get('/install_simulation/simulations', { params })
      .then(({ data }) => {
        setNonWidgetInstallations(
          { commit },
          installationType,
          data.smart_installations
        );
      });
  }
};

function widgetsWithInstallations(widgets, installations) {
  return widgets.map(w => {
    return {
      ...w,
      smart_installations: installations.filter(i => i.widget_id == w.id)
    };
  });
}

function setNonWidgetInstallations(
  { commit },
  installationType,
  installations
) {
  switch (installationType) {
    case SmartInstallationType.HIDE_ELEMENT:
      commit('SET_HIDE_ELEMENTS', installations);
      break;
    case SmartInstallationType.REPLACE_REVIEWS_COUNT:
      commit('SET_REPLACE_REVIEWS_COUNTS', installations);
      break;
    case SmartInstallationType.NEW_REVIEW_LINK:
      commit('SET_NEW_REVIEW_LINKS', installations);
      break;
    case SmartInstallationType.PRODUCT_REVIEWS_LINK:
      commit('SET_PRODUCT_REVIEWS_LINKS', installations);
      break;
    case SmartInstallationType.MY_PENDING_REVIEWS_COUNT:
      commit('SET_MY_PENDING_REVIEWS_COUNTS', installations);
      break;
    case SmartInstallationType.MY_WRITTEN_REVIEWS_COUNT:
      commit('SET_MY_WRITTEN_REVIEWS_COUNTS', installations);
      break;
    default:
      break;
  }
}

export default { namespaced: true, state, mutations, actions };
