var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppTable',{staticClass:"DisplayProductManagementManagedItemsTable",attrs:{"columns":_vm.columns,"rows":_vm.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
var value = ref.value;
return [(column.id === 'using_management')?[_vm._v(" "+_vm._s(_vm.$t(("using_management." + value)))+" ")]:(column.id === 'description')?[(row.id === 'category')?[(_vm.isCategorySelected)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            message: _vm.autoTooltip(true, 'categoryName')
          }),expression:"{\n            message: autoTooltip(true, 'categoryName')\n          }"}],ref:"categoryName",staticClass:"DisplayProductManagementManagedItemsTable__category"},[_vm._v(" "+_vm._s(((_vm.productCategoryName(_vm.managedItemsData.category_id)) + " "))+" ")]):_vm._e(),_c('div',{staticClass:"DisplayProductManagementManagedItemsTable__category-description"},[_vm._v(" "+_vm._s(_vm.$t(("description.category." + _vm.isCategorySelected)))+" ")])]:(row.id === 'icon')?[_vm._l((row.descriptionValueIds),function(iconId){return _c('div',{key:iconId},[(_vm.managedItemsData[iconId].length > 0)?[_c('span',[_vm._v(_vm._s(_vm.$t(("description.icon." + iconId))))]),_c('DisplayProductManagementProductIcons',{staticClass:"DisplayProductManagementManagedItemsTable__icons",attrs:{"icon-ids":_vm.managedItemsData[iconId]}})]:_vm._e()],2)}),(
            !_vm.managedItemsData.pc_icon_ids.length &&
              !_vm.managedItemsData.mobile_icon_ids.length
          )?[_vm._v(" "+_vm._s(_vm.$t('description.icon.none'))+" ")]:_vm._e(),_c('input',{attrs:{"type":"hidden","name":"product_management[use_icon_overflow_management]"},domProps:{"value":_vm.managedItemsData.use_icon_overflow_management}})]:_vm._e()]:(column.id === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('app.settings')},on:{"click":function($event){return _vm.openManagementDialog(row.id)}}}),_c('input',{attrs:{"type":"hidden","name":("product_management[use_" + (row.id) + "_management]")},domProps:{"value":row.using_management}}),(row.id === 'icon')?[_vm._l((_vm.managedItemsData.pc_icon_ids),function(iconId){return _c('input',{key:("input-pc_icon_ids-" + iconId),attrs:{"type":"hidden","name":"product_management[pc_icon_ids][]"},domProps:{"value":iconId}})}),_vm._l((_vm.managedItemsData.mobile_icon_ids),function(iconId){return _c('input',{key:("input-mobile_icon_ids-" + iconId),attrs:{"type":"hidden","name":"product_management[mobile_icon_ids][]"},domProps:{"value":iconId}})})]:_vm._l((row.descriptionValueIds),function(valueId){return _c('input',{key:("input-" + valueId),attrs:{"type":"hidden","name":("product_management[" + valueId + "]")},domProps:{"value":_vm.managedItemsData[valueId]}})})]:_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }