<template>
  <AppAjaxContent :is-loading="isLoading" class="fit-size-csvs">
    <AppHeading :label="$t('fit_csv.upload_csv')" />
    <AppContainer>
      <AppForm
        id="fit_size_csvs"
        :sections="SECTIONS"
        :is-submitting="isSubmitting"
        @submit="save"
      >
        <template #group="{ id }">
          <div
            v-if="id === 'fit_csv_upload_description'"
            class="AppForm__group-field"
          >
            <p>&nbsp;</p>
            <p>{{ $t('local.fit_csv_upload_description_content_1') }}</p>
            <p>{{ $t('local.fit_csv_upload_description_content_2') }}</p>
            <p>{{ $t('local.fit_csv_upload_description_content_3') }}</p>
            <p>{{ $t('local.fit_csv_upload_description_content_4') }}</p>
            <AppButtonDownload
              url="/csv/template_v3.csv"
              class="fit-size-csvs__example-csv"
              :label="$t('local.fit_csv_upload_example_csv_download')"
              filename="template_v3.csv"
            />
          </div>
          <div
            v-else-if="id === 'fit_csv_upload_file_input'"
            class="AppForm__group-field"
          >
            <AppFileInput
              id="fit-csv-file"
              name="fit_csv[file]"
              class="fit-size-csvs__file-input"
              :event-bus="fileEventBus"
              :accept="'.csv'"
              :placeholder="$t('local.fit_csv_upload_file_input_placeholder')"
              @change="isFileSelected = true"
            />
            <AppButtonSubmit
              class="fit-size-csvs__upload-file-button"
              :disabled="!isFileSelected"
              :is-submitting="isSubmitting"
              :submit-label="$t('app.upload')"
              :submitting-label="$t('app.uploading')"
            />
          </div>
        </template>
      </AppForm>
    </AppContainer>
    <AppResourceTable
      table-id="fit-csvs-table"
      :table-name="$t('fit_csv.upload_result')"
      :resources="csvs"
      :columns="COLUMNS"
      :rows="rows"
      @paginate="paginate"
    >
      <template #cell="{ column, value, row }">
        <AppBadge
          v-if="column === 'csvStatus'"
          :tooltip="row.error_message"
          :badge-style="dataStatusBadgeStyle(value)"
          :label="$t(`fit_csv_status.${value}`)"
          :spinner="row.processing"
        />
        <template v-else-if="column === 'csvCreatedAt'">
          <div class="fit-csv__created-at">
            {{ $t('fit_csv.registered_at') }}:
            {{ value.created_at | formatDateTime }}
          </div>
          <div v-if="!value.processing" class="fit-csv__created-at">
            {{ $t('fit_csv.finished_at') }}:
            {{ value.updated_at | formatDateTime }}
          </div>
        </template>
        <template v-else-if="column === 'csvResult'">
          <template v-if="value.imported">
            <div class="fit-csv__result">
              {{
                $t('fit_csv.success_count', {
                  success_count: value.success_count
                })
              }}
            </div>
            <div class="fit-csv__result">
              {{
                $t('fit_csv.error_count', {
                  error_count: value.error_count
                })
              }}
            </div>
          </template>
          <template v-else-if="value.failed">
            {{ $t('fit_csv.failed') }}
          </template>
          <template v-else>
            -
          </template>
        </template>
        <AppButton v-else-if="column === 'csvDownload' && row.file_url">
          <a :href="row.csvUrl">
            {{ $t('fit_csv.download') }}
          </a>
        </AppButton>
        <AppButton
          v-else-if="column === 'actions' && row.imported"
          :label="$t('app.detail_breakdown')"
          @click="openFitSizeCsvDialog(row)"
        />
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import ResourceView from '@/mixins/ResourceView';

export default {
  name: 'FitSizeCsvs',
  mixins: [FormView, ResourceView],
  data() {
    return {
      COLUMNS: [
        { id: 'csvId', label: 'ID' },
        { id: 'csvCreatedAt', label: this.$t('fit_csv.created_at') },
        { id: 'csvStatus', label: this.$t('fit_csv.status') },
        { id: 'csvResult', label: this.$t('fit_csv.result') },
        { id: 'csvDownload', label: this.$t('fit_csv.download') },
        { id: 'actions', label: this.$t('app.actions') }
      ],
      SECTIONS: [
        {
          id: 'fit_csv_upload_description',
          groups: [
            {
              id: 'fit_csv_upload_description',
              label: this.$t('local.fit_csv_upload_description_title')
            }
          ]
        },
        {
          id: 'fit_csv_upload_file_input',
          groups: [
            {
              id: 'fit_csv_upload_file_input',
              label: this.$t('local.fit_csv_upload_file_input_title')
            }
          ]
        }
      ],
      isFileSelected: false,
      fileEventBus: new Vue()
    };
  },
  computed: {
    ...mapState('fitCsv', ['csvs']),
    ...mapGetters('fitCsv', ['isFetchingFitCsvs']),
    ...mapGetters(['pathWithBrandParams']),
    isLoading() {
      return this.csvs.isNull || this.isFetchingFitCsvs;
    },
    rows() {
      return this.csvs.items.map(csv => ({
        ...csv,
        csvId: csv.id,
        csvCreatedAt: csv,
        csvStatus: csv.csv_status,
        csvResult: csv,
        csvUrl: this.pathWithBrandParams(`/api/fit/csvs/${csv.id}/download`)
      }));
    }
  },
  watch: {
    csvs() {
      const value = this.csvs.items.filter(c => c.processing).map(c => c.id);
      this.SET_POLL_PARAMS({ key: 'fit_csv_ids', value });
    }
  },
  beforeDestroy() {
    this.resetFitCsvs();
    this.UNSET_POLL_PARAMS('fit_csv_ids');
  },
  methods: {
    ...mapMutations('poll', ['SET_POLL_PARAMS', 'UNSET_POLL_PARAMS']),
    ...mapActions('fitCsv', ['fetchFitCsvs', 'resetFitCsvs', 'createFitCsv']),
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.fetchFitCsvs(params);
    },
    dataStatusBadgeStyle(dataStatus) {
      switch (dataStatus) {
        case 'imported':
          return 'mint-green';
        case 'partially_imported':
          return 'mango';
        case 'import_failed_file':
          return 'red';
        case 'import_failed_general':
          return 'red';
        default:
          return 'default';
      }
    },
    openFitSizeCsvDialog(csv) {
      this.openDialog(['FitSizeCsvDialog', { csv }]);
    },
    save(formData) {
      this.isSubmitting = true;
      api
        .post('/fit/csvs', formData, {
          successMessage: this.$t('fit_csv.created')
        })
        .then(({ data }) => {
          this.createFitCsv(data);
          this.fileEventBus.$emit('reset');
          this.isFileSelected = false;
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_breakpoints.scss';

.fit-size-csvs {
  position: relative;
}

.fit-size-csvs__file-input {
  display: inline-block;
  width: 372px;
  margin-right: 8px;
}

@include media-breakpoint-each(mobile) {
  .fit-size-csvs__file-input {
    width: 100%;
    margin: 0 0 8px 0;
  }

  .fit-size-csvs__upload-file-button,
  .fit-size-csvs__example-csv {
    width: 100%;
  }
}

.fit-csv__created-at:not(:only-child) {
  line-height: 20px;
}

.fit-csv__result {
  line-height: 20px;
}

.fit-size-csvs__example-csv {
  display: inline-block;
  margin-top: 20px;
}

::v-deep {
  .fit-csvs-table__csv-id {
    flex: 12 0 60px;
  }

  .fit-csvs-table__csv-created-at {
    flex: 22 0 220px;
  }

  .fit-csvs-table__csv-status {
    flex: 22 0 100px;
  }

  .fit-csvs-table__csv-result {
    flex: 18 0 80px;
  }

  .fit-csvs-table__csv-download {
    flex: 15 0 110px;
  }

  .fit-csvs-table__actions {
    flex: 15 0 110px;
  }
}
</style>

<i18n locale="ko">
{
  "fit_csv_status": {
    "none": "대기중",
    "import_pending": "대기중",
    "importing": "처리중",
    "imported": "완료",
    "partially_imported": "일부 실패",
    "import_failed_file": "CSV 파일 오류",
    "import_failed_general": "실패"
  },
  "local": {
    "fit_csv_upload_description_title": "CSV 파일 업로드를 통해 대량으로 상품별 실측 치수 입력을 할 수 있습니다.",
    "fit_csv_upload_description_content_1": "1. 상품마다 적용할 FIT 템플릿을 미리 만들어 놓아야 합니다.",
    "fit_csv_upload_description_content_2": "2. 'CSV 예시 파일' 과 동일한 형태로 CSV 파일을 작성해야 합니다.",
    "fit_csv_upload_description_content_3": "3. CSV 파일 작성시 각 항목별 데이터를 양식에 맞게 입력해야 합니다.",
    "fit_csv_upload_description_content_4": "4. 업로드할 CSV 파일의 이름에 한글이 포함되어 있을 경우, 등록 과정에서 실패할 수 있습니다.",
    "fit_csv_upload_example_csv_download": "CSV 예시 파일 다운로드",
    "fit_csv_upload_file_input_title": "CSV 파일 업로드",
    "fit_csv_upload_file_input_placeholder": "업로드할 CSV 파일을 등록해주세요."
  }
}
</i18n>
