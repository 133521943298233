<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <AppForm
      :id="SETTINGS_ID"
      v-bind="formProps"
      submit-button
      :object-id="objectId"
      v-on="formEvents"
    />
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';
import TargetContentView from '@/mixins/TargetContentView';

export default {
  name: 'ChannelSettingsAdmin',
  mixins: [FormView, SettingsView, TargetContentView],
  data: () => ({ isLoading: true, feedUpdatedAt: null }),
  computed: {
    SETTINGS_ID() {
      return 'channel_settings_admin';
    },
    objectId() {
      return 'channel_settings';
    },
    groupOptions() {
      return {
        launch_ad_menu: { type: 'checkbox' },
        skip_product_image_download: {
          type: 'checkbox',
          description: this.$t('form.skip_product_image_download.description')
        }
      };
    }
  },
  mounted() {
    api
      .get('/channel/settings/admin')
      .then(({ data }) => (this.orgFormObject = data.settings))
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/channel/settings/update_admin', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
  {
    "form": {
      "skip_product_image_download": {
        "description": "애드에서는 상품 이미지 사이즈를 파악하기위해 상품 동기화 시 상품이미지를 다운로드합니다.<br />상품 동기화 시 상품 이미지 다운로드로 인해 부하가 일어날 시 해당 기능을 사용하여 상품 이미지 다운로드를 제한합니다.<br />상품 이미지 다운로드 스킵 시 이미지 주소를 이용해 상품 이미지를 파악하나 시간이 오래걸립니다.<br />[리뷰 > 최고관리자 설정 > 관리 기능 > 상품 이미지 다운로드 스킵]과 연결되어있습니다."
      }
    }
  }
</i18n>
