<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppForm
      :id="SETTINGS_ID"
      object-id="brand_settings"
      v-bind="formProps"
      v-on="formEvents"
    >
      <template #group="{ id }">
        <template v-if="id === 'phone' && !isSuperAdminAccessible">
          <div class="AppForm__group-field">
            <AppTextInput
              :placeholder="formObject.phone || $t('phone.placeholder')"
              readonly
            />
          </div>
          <AppFormHint
            :message="phoneHint"
            :priority="
              phoneChangeRequest.status === 'rejected' ? 'danger' : null
            "
          />
          <div class="AppForm__group-field">
            <AppButton
              :label="$t('phone.edit_button')"
              @click="clickEditPhone"
            />
          </div>
        </template>
        <template v-else-if="id === 'sender_email'">
          <AppSenderEmailInput
            v-model="data.last_sender_email_change_request"
            :email="formObject.sender_email"
            :verification-status="formObject.sender_email_verification_status"
          />
        </template>
        <template
          v-else-if="id === 'corporate_number' && !isSuperAdminAccessible"
        >
          <div class="AppForm__group-field">
            <AppTextInput
              :placeholder="
                formObject.corporate_number ||
                  $t('corporate_number.placeholder')
              "
              readonly
            />
          </div>
          <AppFormHint
            :message="corporateNumberHint"
            :priority="
              corporateNumberChangeRequest.status === 'rejected'
                ? 'danger'
                : null
            "
          />
          <div class="AppForm__group-field">
            <AppButton
              :label="$t('corporate_number.edit_button')"
              @click="clickEditCorporateNumber"
            />
          </div>
        </template>
      </template>
      <AppHiddenInputArray
        name="brand_settings[disable_init_js_urls][]"
        :values="hiddenDisableInitJsUrls"
      />
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import TheSettingsDialogView from './TheSettingsDialogView';
import { isUrlFormatValid } from '@/lib/urlFormatValidator';
import ShopBuilder from '@/enums/ShopBuilder';
import BrandType from '@/enums/BrandType';
import BrandProductType from '@/enums/BrandProductType';

export default {
  name: 'TheSettingsDialogBrand',
  mixins: [TheSettingsDialogView],
  data() {
    return { SETTINGS_ID: 'brand_settings', SETTINGS_URL: '/brand_settings' };
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapState('session', ['currentBrand']),
    groupOptions() {
      return {
        logo_image: {
          type: 'image',
          hint: this.$t('logo_image.hint')
        },
        name: {
          type: 'text',
          placeholder: this.$t('name.placeholder'),
          validate: ['required'],
          hint: this.$t('name.hint')
        },
        url: {
          type: 'url',
          placeholder: this.$t('url.placeholder'),
          validate: [
            'required',
            'url_format',
            this.urlChanged(this.orgFormObject.url, this.formObject.url)
              ? { rule: 'check_brand_url_duplication', async: true }
              : null
          ].filter(v => v),
          hint: this.urlHint
        },
        phone: this.isSuperAdminAccessible
          ? {
              type: 'tel',
              hint: {
                message: this.$t('phone.hint.super_admin'),
                priority: 'danger'
              },
              validate: ['korean_phone_format']
            }
          : null,
        shop_builder: {
          type: 'select',
          options: ShopBuilder.options(),
          placeholder: this.$t('shop_builder.placeholder'),
          validate: ['required'],
          disabled: true
        },
        brand_id: {
          type: 'copyable_textarea',
          value: `${this.currentBrand.id}`,
          hint: this.$t('brand_id.hint')
        },
        brand_type: {
          type: 'select',
          options: BrandType.options()
        },
        brand_product_type: {
          type: 'select',
          options: BrandProductType.options(),
          hint: this.$t('brand_product_type.hint')
        },
        company: {
          type: 'text',
          placeholder: this.$t('company.placeholder')
        },
        force_mobile_init_js: {
          type: 'checkbox',
          hint: this.$t('force_mobile_init_js.hint')
        },
        show_sub_brand: { type: 'checkbox' },
        use_original_product_image: {
          type: 'checkbox',
          description: this.$t('use_original_product_image.description'),
          hint: this.$t('use_original_product_image.hint')
        },
        disable_init_js_urls: {
          name: '',
          type: 'textarea',
          placeholder: this.$t('disable_init_js_urls.placeholder'),
          groupDescription: this.$t(
            'disable_init_js_urls.group_description_html'
          ),
          hint: this.$t('disable_init_js_urls.hint'),
          rows: 1,
          validate: [
            {
              rule: v =>
                !v || v.split('\n').every(value => isUrlFormatValid(value)),
              errorMessage: this.$t('disable_init_js_urls.error')
            }
          ]
        }
      };
    },
    urlHint() {
      const { shop_builder } = this.formObject;
      return shop_builder == ShopBuilder.NONE
        ? this.$t('url.hint.shop_builder_none', {
            review_url_settings_link: `https://admin.cre.ma/v2/review/settings/widget?brand_id=${this.currentBrand.id}`,
            mileage_url_settings_link: `https://admin.cre.ma/v2/review/settings/mileage?brand_id=${this.currentBrand.id}#mileage_condition--mileage_callback_url`
          })
        : this.$t('url.hint.default', {
            review_url_settings_link: `https://admin.cre.ma/v2/review/settings/widget?brand_id=${this.currentBrand.id}`
          });
    },
    phoneChangeRequest() {
      const lastRequest = this.data.last_phone_change_request;
      if (lastRequest) {
        return {
          ...lastRequest,
          ...(lastRequest.status === 'approved' && !this.formObject.phone
            ? { status: 'none' }
            : {})
        };
      }

      const { phone } = this.formObject;
      return phone ? { phone, status: 'approved' } : { status: 'none' };
    },
    phoneHint() {
      const { status, phone, reject_message } = this.phoneChangeRequest;
      return this.$t(`phone.hint.${status}`, { phone, reject_message });
    },
    corporateNumberChangeRequest() {
      const lastRequest = this.data.last_corporate_number_change_request;
      if (lastRequest) {
        return {
          ...lastRequest,
          ...(lastRequest.status === 'approved' &&
          !this.formObject.corporate_number
            ? { status: 'none' }
            : {})
        };
      }

      const { corporate_number } = this.formObject;
      return corporate_number
        ? { corporate_number, status: 'approved' }
        : { status: 'none' };
    },
    corporateNumberHint() {
      const {
        status,
        corporate_number,
        reject_message
      } = this.corporateNumberChangeRequest;
      return this.$t(`corporate_number.hint.${status}`, {
        corporate_number,
        reject_message
      });
    },
    hiddenDisableInitJsUrls() {
      if (!this.formObject.disable_init_js_urls) return [];
      return this.formObject.disable_init_js_urls.split(/\s+/);
    }
  },
  watch: {
    data() {
      this.setOrgFormObject(this.data.brand_settings);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    clickEditPhone() {
      this.openDialog(['ThePhoneChangeRequestDialog']).then(eventBus =>
        eventBus.$on('requested', phone_change_request =>
          this.$set(
            this.data,
            'last_phone_change_request',
            phone_change_request
          )
        )
      );
    },
    clickEditCorporateNumber() {
      this.openDialog([
        'TheCorporateNumberChangeRequestDialog'
      ]).then(eventBus =>
        eventBus.$on('requested', corporate_number_change_request =>
          this.$set(
            this.data,
            'last_corporate_number_change_request',
            corporate_number_change_request
          )
        )
      );
    },
    urlChanged(orgUrl, newUrl) {
      if (!orgUrl || !newUrl) return orgUrl !== newUrl;
      return (
        orgUrl.replace(/(http(s?)):\/\//, '') !==
        newUrl.replace(/(http(s?)):\/\//, '')
      );
    }
  }
};
</script>

<i18n locale="ko">
{
  "logo_image": {
    "hint": "가로 최대 150px, 세로 80px의 이미지를 등록 해주세요. 쇼핑몰명 변경 시, 쇼핑몰 로고를 삭제해주세요."
  },
  "name": {
    "placeholder": "쇼핑몰 명을 입력해주세요.",
    "hint": "쇼핑몰명 변경 시 '계정 설정 > 부운영자 이름 혹은 아이디'도 함께 변경해주세요."
  },
  "url": {
    "placeholder": "쇼핑몰 URL을 입력해주세요.",
    "hint": {
      "default": "쇼핑몰 URL 변경 시 리뷰 설정의 <u>[활용 URL]({review_url_settings_link})</u> 정보도 점검해주세요.",
      "shop_builder_none": "쇼핑몰 URL 변경 시 리뷰 설정의 <u>[활용 URL]({review_url_settings_link})</u>, <u>[적립금 지급을 위한 지급 요청 URL]({mileage_url_settings_link})</u> 정보도 점검해주세요."
    }
  },
  "phone": {
    "placeholder": "전화번호를 설정해주세요.",
    "hint": {
      "none": "대표 전화번호가 설정되지 않은 경우 크리마 문자 발송 서비스를 사용할 수 없습니다.",
      "requested": "**{phone}** 번호로 변경 요청되었습니다. 크리마 운영진이 확인 후 처리해드립니다.\n재요청을 원할 경우 '변경 요청' 버튼을 눌러 다시 진행해주세요.",
      "required": "대표 전화번호가 설정되지 않은 경우 크리마 문자 발송 서비스를 사용할 수 없습니다.",
      "rejected": "**{phone}** 번호 변경 요청이 거절되었습니다. (사유: {reject_message})\n'변경 요청' 버튼을 눌러 다시 진행해주세요.",
      "approved": "인증된 대표 전화번호입니다. 변경을 원하면 '변경 요청' 버튼을 눌러 재인증받으세요.",
      "super_admin": "쇼핑몰 대표 전화번호를 변경하면, '알림'을 통해 쇼핑몰의 모든 관리자에게 변경 사실이 안내됩니다."
    },
    "edit_button": "변경 요청"
  },
  "shop_builder": {
    "placeholder": "운영솔루션 선택"
  },
  "brand_id": {
    "hint": "브랜드 아이디(brand ID)는 크리마 서비스를 사용하는 각 쇼핑몰에 할당되는 고유한 식별값입니다.\n크리마 담당자와 소통할 때 브랜드 아이디를 함께 제공해 주시면 신속하게 확인할 수 있습니다."
  },
  "brand_product_type": {
    "hint": "Fit 서비스 사용 시, 사이즈 예측 모델 분류를 위한 설정입니다."
  },
  "company": {
    "placeholder": "회사명을 입력해주세요."
  },
  "force_mobile_init_js": {
    "hint": "체크 시, 모든 기기(PC/모바일)에서 모바일 위젯만 표시됩니다."
  },
  "use_original_product_image": {
    "description": "크리마 서버에 저장된 이미지 대신 쇼핑몰에서 사용하는 원본 이미지를 보여줍니다.\n원본 이미지 사용 시 GIF 이미지 표시가 가능합니다.",
    "hint": "호스팅사 정책 또는 쇼핑몰 유/무료 버전에 따라 상품이미지를 불러오지 못할 수 있습니다."
  },
  "disable_init_js_urls": {
    "group_description_html": "크리마 스크립트 수행 작업 중 크리마 위젯 노출 및 위젯 숨김 관련 작업을 차단합니다.<br>크리마 위젯을 설치하지 않은 페이지에서 쇼핑몰 기존 요소가 미노출 되는 등의 문제 발생 시 활용할 수 있습니다.",
    "placeholder": "페이지 URL을 입력해주세요.",
    "hint": "여러개 입력 시 줄바꿈하여 입력해주세요.",
    "error": "잘못된 형식의 url입니다."
  }
}
</i18n>
