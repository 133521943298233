<template>
  <AppModalForm
    :title="$t('menu.fit_display_names')"
    :sub-title="subTitle"
    :form-props="{
      ...formProps,
      id: 'fit_display_name',
      objectId: 'fit_option_key',
      focusGroupId: 'display_name'
    }"
    v-on="formEvents"
  >
    <template #group="{ id, errors }">
      <div
        v-if="id === 'option_values'"
        class="AppForm__group-field AppForm__group-field--mt12"
      >
        <AppTable
          :columns="[
            { id: 'name', label: $t('option_name') },
            {
              id: 'display_name',
              label: $t('fit_display_name.display_name')
            }
          ]"
          :rows="fitOptionKey.fit_option_values"
          :no-data="$t('no_option_value')"
        >
          <template #cell="{ row, column, rowIndex }">
            <template v-if="column.id === 'display_name'">
              <input
                type="hidden"
                name="fit_option_key[fit_option_values][][id]"
                :value="row.id"
              />
              <AppTextarea
                v-model="fitOptionKey.fit_option_values[rowIndex].display_name"
                :rows="1"
                :maxlength="255"
                name="fit_option_key[fit_option_values][][display_name]"
                :invalid="!!errors[`option_values[${row.id}][display_name]`]"
                @blur="validateField(`option_values[${row.id}][display_name]`)"
                @change="
                  validateField(`option_values[${row.id}][display_name]`)
                "
              />
              <AppFormError
                :error="errors[`option_values[${row.id}][display_name]`]"
              />
            </template>
          </template>
        </AppTable>
      </div>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import FitOptionKeyType from '@/enums/FitOptionKeyType';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'FitDisplayNameEditDialog',
  mixins: [DialogFormView],
  props: {
    orgFitOptionKey: {
      type: Object,
      required: true
    }
  },
  data() {
    return { orgFormObject: this.orgFitOptionKey };
  },
  computed: {
    subTitle() {
      return `${this.fitOptionKey.name}(${this.fitOptionKeyType})`;
    },
    fitOptionKeyType() {
      return FitOptionKeyType.t(this.fitOptionKey.fit_option_key_type);
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'name',
              type: 'static',
              label: this.$t('fit_display_name.name')
            },
            {
              id: 'display_name',
              type: 'textarea',
              label: this.$t('fit_display_name.display_name'),
              maxlength: 255,
              validate: ['required']
            },
            {
              id: 'fit_option_type',
              type: 'static',
              value: this.fitOptionKeyType
            },
            this.fitOptionKey.fit_option_key_type === FitOptionKeyType.PROPERTY
              ? {
                  id: 'option_values',
                  fields: this.fitOptionKey.fit_option_values.map(value => ({
                    id: `option_values[${value.id}][display_name]`,
                    value: () => value.display_name,
                    validate: ['required']
                  }))
                }
              : null
          ].filter(e => e)
        }
      ];
    },
    fitOptionKey() {
      return this.formObject;
    }
  },
  watch: {
    'fitOptionKey.fit_option_values': {
      handler() {
        this.validateField('option_values');
      },
      deep: true
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(`/fit/option_keys/${this.fitOptionKey.id}`, formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.eventBus.$emit('save', data);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "option_name": "선택 항목명",
  "no_option_value": "설정된 선택 항목이 없습니다."
}
</i18n>
