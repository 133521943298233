<template>
  <TheNoticeView
    :notice-title="notice.title"
    :notice-id="notice.id"
    :confirm-label="confirmLabel"
    @close="close"
    @click="confirm"
  />
</template>

<script>
import DialogView from '@/mixins/DialogView';
import TheNoticeView from './TheNoticeView';

export default {
  name: 'TheNoticePopupDialog',
  components: { TheNoticeView },
  mixins: [DialogView],
  props: {
    notices: {
      type: Array,
      required: true
    }
  },
  data() {
    return { noticeIndex: -1 };
  },
  computed: {
    notice() {
      return this.notices[this.noticeIndex];
    },
    hasNextNotice() {
      return this.notices.length > this.noticeIndex + 1;
    },
    confirmLabel() {
      if (this.hasNextNotice)
        return this.$t('show_next', {
          index: this.noticeIndex + 1,
          total: this.notices.length
        });
      return this.$t('app.confirmed');
    }
  },
  created() {
    this.updateNotice();
  },
  methods: {
    updateNotice() {
      this.noticeIndex += 1;
    },
    confirm() {
      if (this.hasNextNotice) this.updateNotice();
      else this.close();
    }
  }
};
</script>

<i18n locale="ko">
{
  "show_next": "다음 공지사항 확인하기 ({index}/{total})"
}
</i18n>
