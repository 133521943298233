import { constructEnum } from '@/lib/enum';

const CremaServiceType = constructEnum('CremaServiceType', {
  REVIEW: 1,
  TARGET: 2,
  FIT: 4,
  DISPLAY: 8,
  CHANNEL: 16,
  INSIGHT: 32,
  SMARTSTORE_STARTER: 64,
  SMARTSTORE_DAILY: 128,
  REVIEW_SYNC: 256
});

CremaServiceType.BIZMESSAGE_SERVICE_TYPES = [
  CremaServiceType.REVIEW,
  CremaServiceType.TARGET
];

CremaServiceType.nameToServiceType = function(serviceName) {
  switch (serviceName) {
    case 'review':
      return CremaServiceType.REVIEW;
    case 'fit':
      return CremaServiceType.FIT;
    case 'target':
      return CremaServiceType.TARGET;
    case 'display':
      return CremaServiceType.DISPLAY;
    case 'channel':
      return CremaServiceType.CHANNEL;
    case 'analytics':
      return CremaServiceType.INSIGHT;
    default:
      return null;
  }
};

export default CremaServiceType;
