<template>
  <div :class="['AppTabs', { 'AppTabs--sticky': sticky }]">
    <div ref="scroll" class="AppTabs__scroll">
      <ul class="AppTabs__list">
        <li
          v-for="tab in tabs"
          ref="tabElements"
          :key="tab.id"
          :class="[
            'AppTabs__tab',
            {
              'AppTabs__tab--active': tab.id === activeTab,
              'super-admin__item': tab.superAdmin
            }
          ]"
        >
          <Component
            :is="tab.path ? 'AppInternalLink' : 'a'"
            class="AppTabs__tab-link"
            :to="tab.path"
            @click="$emit('change', tab.id)"
            >{{ tab.label }}</Component
          ><AppBadge
            v-if="tab.badge"
            class="AppTabs__tab-badge"
            :badge-style="tab.badge.style"
            :label="tab.badge.label"
          />
        </li>
      </ul>
      <div
        :style="{
          left: `${activeTabOffsetLeft}px`,
          width: `${activeTabWidth}px`
        }"
        :class="[
          'AppTabs__ink-bar',
          { 'AppTabs__ink-bar--animation': enableInkBarAnimation }
        ]"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTabs',
  model: { prop: 'activeTab', event: 'change' },
  props: {
    tabs: { type: Array, required: true },
    activeTab: { type: [String, Number], required: true },
    sticky: { type: Boolean, default: false }
  },
  data() {
    return {
      activeTabOffsetLeft: 0,
      activeTabWidth: 0,
      enableInkBarAnimation: false
    };
  },
  watch: {
    activeTab() {
      this.computeActiveTabWidth();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.computeActiveTabWidth();
      if (document.fonts) {
        document.fonts.ready.then(() => {
          this.computeActiveTabWidth();
          this.$nextTick(() => (this.enableInkBarAnimation = true));
        });
      } else {
        this.$nextTick(() => (this.enableInkBarAnimation = true));
      }
    });
  },
  methods: {
    computeActiveTabWidth() {
      const index = this.tabs.findIndex(t => t.id === this.activeTab);
      const tabElement = this.$refs.tabElements[index];
      this.activeTabOffsetLeft = tabElement.offsetLeft;
      this.activeTabWidth = tabElement.offsetWidth;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_transitions.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';
@import '@/scss/mixins/_scrollbars.scss';

.AppTabs {
  position: relative;
  margin-bottom: 24px;

  &--sticky {
    @include media-breakpoint-each(tablet, mobile) {
      position: sticky;
      left: $padding-horizontal-tablet;
      width: calc(100vw - #{$padding-horizontal-tablet * 2});
      margin-bottom: 24px - 2px;
      white-space: nowrap;

      &:after {
        content: '';
        position: absolute;
        bottom: -18px;
        height: 20px;
        width: 100%;
        background-color: $color-background;
      }
    }
  }
}

.AppTabs__scroll {
  position: relative;

  @include media-breakpoint-each(tablet, mobile) {
    @include scrollbar-webkit-slim;
    overflow-x: scroll;
  }
}

.AppTabs__list {
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid $color-layout-section;
  }
}

.AppTabs__tab {
  display: inline-block;
  border-bottom: 1px solid $color-layout-section;

  & + .AppTabs__tab {
    margin-left: 32px;
  }
}

.AppTabs__tab-link {
  @include text-tab;
  @include transition-basic(color);

  display: inline-block;
  cursor: pointer;
  padding: 8px 0 9px;

  &:hover {
    color: $color-button-text-dark;
  }
}

.AppTabs__tab-badge {
  margin-left: 4px;
}

.AppTabs__tab--active .AppTabs__tab-link {
  color: $color-primary;
}

.AppTabs__ink-bar {
  position: absolute;
  height: 3px;
  background-color: $color-primary;
  bottom: 1px;
}

.AppTabs__ink-bar--animation {
  @include transition-basic(left, width);
}
</style>
