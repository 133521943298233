<template>
  <div class="SmartstoreProductMatchOperating">
    <div>{{ $t('description.main') }}</div>
    <div class="SmartstoreProductMatchOperating__guide_title">
      {{ $t('description.guide_title') }}
    </div>
    <ol class="SmartstoreProductMatchOperating__guide_containers">
      <li class="SmartstoreProductMatchOperating__guide">
        <div>{{ $t('description.step_one') }}</div>
        <div class="SmartstoreProductMatchOperating__guide_item">
          <AppButtonDownload
            :label="$t('download_matching_csv')"
            url="/api/smartstore/products/download_matching_csv"
          />
          <a
            class="SmartstoreProductMatchOperating__file_upload_guide"
            @click="openProductMatchGuide"
          >
            {{ $t('smartstore.file_upload_guide.label') }}
          </a>
        </div>
      </li>
      <li class="SmartstoreProductMatchOperating__guide">
        <div>{{ $t('description.step_two') }}</div>
        <div class="SmartstoreProductMatchOperating__guide_item">
          <AppImage
            class="SmartstoreProductMatchOperating__guide_image"
            src="https://assets.cre.ma/m/v3/smartstore_product_matching_guide_two.png"
          />
        </div>
      </li>
      <li class="SmartstoreProductMatchOperating__guide">
        <div>{{ $t('description.step_three') }}</div>
        <div class="SmartstoreProductMatchOperating__guide_item">
          <BatchJobForm
            title
            :batch-job-type="batchJobType"
            :success-message="$t('batch_job_started')"
          />
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BatchJobType from '@/enums/BatchJobType';
import BatchJobForm from '@/views/components/BatchJobForm';

export default {
  name: 'SmartstoreProductMatchOperating',
  components: { BatchJobForm },
  computed: {
    batchJobType: () => BatchJobType.SMARTSTORE_PRODUCT_MATCH
  },
  methods: {
    ...mapActions('smartstore', ['openProductMatchGuide'])
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/vars/_colors.scss';

.SmartstoreProductMatchOperating__guide_title {
  @include text-label;
  margin-top: 32px;
}

.SmartstoreProductMatchOperating__guide_containers {
  margin-top: 4px;
}

.SmartstoreProductMatchOperating__guide {
  & + & {
    margin-top: 32px;
  }
}

.SmartstoreProductMatchOperating__guide_item {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 23px;
}

.SmartstoreProductMatchOperating__guide_image {
  width: 525px;
  height: 225px;
}

.SmartstoreProductMatchOperating__file_upload_guide {
  color: $color-grey-40;
  line-height: 20px;
  margin-left: 16px;
  text-decoration: underline;
}
</style>

<i18n locale="ko">
{
  "description": {
    "main": "매일 리뷰를 연동할 상품을 추가합니다. 단, 추가한 상품의 과거 리뷰는 연동되지 않습니다.",
    "guide_title": "\u003C추가등록방법 안내\u003E",
    "step_one": "1. 현재 상품 매칭표를 다운로드하여 오픈합니다.",
    "step_two": "2. 기존 상품정보 하단에 추가할 상품을 입력 후 저장합니다.",
    "step_three": "3. 추가한 상품과 기존 매칭된 상품이 모두 등록된 파일을 업로드해주세요."
  },
  "download_matching_csv": "현재 상품 매칭표 다운로드",
  "batch_job_started": "상품 매칭표 등록을 시작했습니다."
}
</i18n>
