<template>
  <div
    :class="[
      'AppVideoImage',
      {
        'AppVideoImage--background': background,
        'AppVideoImage--cover': !showPlayButton
      }
    ]"
    :style="backgroundImageStyle"
  >
    <div class="AppVideoImage__screen" :style="screenStyle">
      <div
        v-if="progress"
        ref="progresBar"
        class="AppVideoImage__progress"
        :style="{ width: `${progress}%` }"
      />
    </div>
    <AppImage
      ref="videoImage"
      v-bind="{ src }"
      class="AppVideoImage__image"
      @load="imageLoaded"
    />
    <AppSvgIcon
      v-if="showPlayButton"
      class="AppVideoImage__play-icon"
      name="icon-play-button"
      :height="playIconSize"
    />
  </div>
</template>

<script>
export default {
  name: 'AppVideoImage',
  props: {
    src: { type: String, required: true },
    background: { type: Boolean, default: false },
    progress: { type: Number, default: 0 },
    showPlayButton: { type: Boolean, default: true }
  },
  data() {
    return {
      screenStyle: null,
      playIconSize: 0
    };
  },
  computed: {
    backgroundImageStyle() {
      if (!this.background) return null;
      return { backgroundImage: `url("${this.src}")` };
    }
  },
  watch: {
    progress() {
      if (this.progress === 100)
        setTimeout(() => this.$refs.progresBar.remove(), 1500);
    }
  },
  methods: {
    imageLoaded() {
      this.setScreen();
      this.setPlayIcon();
    },
    setScreen() {
      if (this.background) {
        this.screenStyle = {
          width: '100%',
          height: '100%'
        };
      } else {
        const screenWidth = this.$refs.videoImage.$el.clientWidth;
        const screenHeight = this.$refs.videoImage.$el.clientHeight;
        this.screenStyle = {
          width: `${screenWidth}px`,
          height: `${screenHeight}px`,
          top: `${(this.$el.clientHeight - screenHeight) / 2}px`
        };
      }
    },
    setPlayIcon() {
      this.playIconSize = this.$el.clientHeight / 3;
    }
  }
};
</script>

<style lang="scss" scoped>
.AppVideoImage {
  display: inline-block;
  position: relative;

  &--background {
    background-repeat: no-repeat;
    background-position: top;
    background-size: 320%;
  }

  &--cover {
    background-size: cover;
  }
}

.AppVideoImage__screen {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
}

.AppVideoImage__image {
  vertical-align: middle;
}

.AppVideoImage__play-icon {
  position: absolute;
  color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.AppVideoImage--background .AppVideoImage__image {
  visibility: hidden;
  position: absolute;
}

.AppVideoImage__progress {
  position: absolute;
  bottom: 0;
  height: 3px;
  background-color: red;
  transition: width 1s;
}
</style>
