import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';

export default {
  mixins: [FormView, SettingsView],
  data() {
    return {
      SETTINGS_URL: null,
      data: null
    };
  },
  watch: {
    isFormDataChanged() {
      this.$emit('changed', this.isFormDataChanged);
    }
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapActions('session', ['updateReviewSettings']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/review/settings', formData)
        .then(() => {
          if (this.isAlertMileageRecalculationNeeded) {
            alert(
              this.$t('ReviewSettingsView.mileage_recalculation_alert_message')
            );
          } else this.createToast(this.$t('app.saved'));
          this.removeReviewSyncPackageOption(this.formObject);

          this.setOrgFormObject(this.formObject);
          this.updateReviewSettings(this.reviewSettings);
        })
        .finally(() => (this.isSubmitting = false));
    },
    setOrgFormObject(orgFormObject) {
      this.orgFormObject = orgFormObject;
    },
    removeReviewSyncPackageOption(formObject) {
      if (this.SETTINGS_ID !== 'review_settings_super_admin_manage') return;

      const reviewSyncTransactionId = parseInt(
        formObject.review_sync_transaction_id
      );
      const hasSubmittedReviewSync = !!(
        formObject.source_brand_id &&
        reviewSyncTransactionId &&
        this.reviewSyncStartDates[reviewSyncTransactionId]
      );
      if (!hasSubmittedReviewSync) return;

      this.data = {
        ...this.data,
        review_sync_packages: this.data.review_sync_packages.filter(
          p => p.transaction_id !== reviewSyncTransactionId
        )
      };
      this.formObject = {
        ...this.formObject,
        review_sync_transaction_id: null
      };
    }
  },
  mounted() {
    api.get(this.SETTINGS_URL).then(({ data }) => {
      this.data = data;
      this.setOrgFormObject(data.review_settings);
    });
  },
  computed: {
    ...mapState('session', ['adminSettings']),
    reviewSettings() {
      return this.formObject;
    },
    reviewFormProps() {
      return {
        ...this.formProps,
        id: this.SETTINGS_ID,
        submitButton: true,
        objectId: 'review_settings',
        formStyle: 'hor'
      };
    },
    isAlertMileageRecalculationNeeded() {
      if (this.SETTINGS_ID === 'review_settings_mileage_condition') {
        return true;
      }

      if (this.SETTINGS_ID !== 'review_settings_mileage_amount') {
        return false;
      }

      const diffObject = _.fromPairs(
        _.differenceWith(
          _.toPairs(this.orgFormObject),
          _.toPairs(this.formObject),
          _.isEqual
        )
      );

      const keysToIgnoreAlert = [
        'use_manual_mileage_max_amount',
        'manual_mileage_max_amount_cents'
      ];
      keysToIgnoreAlert.forEach(key => delete diffObject[key]);

      return !_.isEmpty(diffObject);
    }
  }
};
