<template>
  <div class="NextStepBubble">
    <AppBubble class="NextStepBubble__bubble" side="top-center">
      {{ text }}
    </AppBubble>
  </div>
</template>

<script>
export default {
  name: 'NextStepBubble',
  props: {
    text: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.NextStepBubble {
  width: fit-content;
}

.NextStepBubble__bubble {
  @include text-label;

  color: #fff;
  position: relative;
  padding: 10px 16px;
}
</style>
