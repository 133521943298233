import Vue from 'vue';
import i18n from '@/lib/i18n';
import ReviewSort from '@/enums/ReviewSort';

const state = { reviewWidgets: [] };

const mutations = {
  CREATE_REVIEW_WIDGET({ reviewWidgets }, reviewWidget) {
    reviewWidgets.push(reviewWidget);
  },
  SET_REVIEW_WIDGETS(state, reviewWidgets) {
    state.reviewWidgets = reviewWidgets;
  },
  CREATE_REVIEW_WIDGET_SMART_INSTALLATION(
    { reviewWidgets },
    { widgetId, installation }
  ) {
    const index = reviewWidgets.findIndex(c => c.id === widgetId);
    reviewWidgets[index].smart_installations.push(installation);
    Vue.set(reviewWidgets, index, reviewWidgets[index]);
  },
  UPDATE_REVIEW_WIDGET_SMART_INSTALLATION(
    { reviewWidgets },
    { widgetId, installation }
  ) {
    const targetReviewWidget = reviewWidgets.find(c => c.id === widgetId);
    const installationIndex = targetReviewWidget.smart_installations.findIndex(
      c => c.id === installation.id
    );
    Vue.set(
      targetReviewWidget.smart_installations,
      installationIndex,
      installation
    );
  },
  UPDATE_REVIEW_WIDGET({ reviewWidgets }, reviewWidget) {
    const index = reviewWidgets.findIndex(c => c.id === reviewWidget.id);
    Vue.set(reviewWidgets, index, reviewWidget);
  },
  DELETE_REVIEW_WIDGET_SMART_INSTALLATION(
    { reviewWidgets },
    { widgetId, installationIndex }
  ) {
    const targetReviewWidget = reviewWidgets.find(c => c.id === widgetId);
    Vue.delete(targetReviewWidget.smart_installations, installationIndex);
  },
  DELETE_REVIEW_WIDGET({ reviewWidgets }, rowIndex) {
    Vue.delete(reviewWidgets, rowIndex);
  }
};

const getters = {
  reviewWidget: ({ reviewWidgets }) => id => {
    return reviewWidgets.find(i => i.id === id);
  },
  reviewWidgetSmartInstallation: ({ reviewWidgets }) => installationId => {
    return reviewWidgets
      .map(i => i.smart_installations)
      .flat()
      .find(i => i.id === installationId);
  },
  reviewWidgetSmartInstallations: ({ reviewWidgets }) => {
    return reviewWidgets.map(i => i.smart_installations).flat();
  },
  disableReviewSortMostScores: (
    _state,
    _getters,
    rootState
  ) => reviewSortValue => {
    const { reviewSettings } = rootState.session;
    return (
      !reviewSettings.show_score && reviewSortValue === ReviewSort.MOST_SCORES
    );
  },
  reviewSortOptions: (_state, { disableReviewSortMostScores }) => sortTypes => {
    return ReviewSort.options(sortTypes).map(reviewSortType => ({
      ...reviewSortType,
      disabled: disableReviewSortMostScores(reviewSortType.value),
      tooltip: disableReviewSortMostScores(reviewSortType.value)
        ? i18n.t(
            'review_widgets.settings.tooltip.disabled_review_sort_most_score_tooltip'
          )
        : null
    }));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
