var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppContainer',{staticClass:"AnalyticsInsightKeywordsAnalysis"},[_c('div',{staticClass:"AnalyticsInsightKeywordsAnalysis__title"},[_vm._v(" "+_vm._s(_vm.$t('keywords_analysis'))+" ")]),_c('div',{staticClass:"AnalyticsInsightKeywordsAnalysis__toolbar"},[_c('div',{staticClass:"AnalyticsInsightKeywordsAnalysis__dropdowns"},[_c('AppSelect',{staticClass:"AnalyticsInsightKeywordsAnalysis__dropdown",attrs:{"options":_vm.InsightKeywordsSortBasis.options()},model:{value:(_vm.sortBasis),callback:function ($$v) {_vm.sortBasis=$$v},expression:"sortBasis"}}),_c('AppSelect',{staticClass:"AnalyticsInsightKeywordsAnalysis__dropdown",attrs:{"options":_vm.InsightKeywordsSortType.options()},model:{value:(_vm.sortType),callback:function ($$v) {_vm.sortType=$$v},expression:"sortType"}})],1),_c('div',{staticClass:"AnalyticsInsightKeywordsAnalysis__checkboxes"},[_c('AppCheckbox',{staticClass:"AnalyticsInsightKeywordsAnalysis__checkbox",attrs:{"label":_vm.$t('insight.brands') + ' ' + _vm.$t('app.show')},model:{value:(_vm.toggleBrandsVisible),callback:function ($$v) {_vm.toggleBrandsVisible=$$v},expression:"toggleBrandsVisible"}}),_c('AppCheckbox',{staticClass:"AnalyticsInsightKeywordsAnalysis__checkbox",attrs:{"label":_vm.$t('see_keyword_sentiments')},model:{value:(_vm.toggleKeywordSentimentsVisible),callback:function ($$v) {_vm.toggleKeywordSentimentsVisible=$$v},expression:"toggleKeywordSentimentsVisible"}})],1)]),_c('AppChart',{class:[
      'AnalyticsInsightKeywordsAnalysis__chart',
      ("AnalyticsInsightKeywordsAnalysis__chart--" + (_vm.InsightKeywordsSortType.key(
        _vm.sortType
      ).toLowerCase())),
      {
        'AnalyticsInsightKeywordsAnalysis__chart--sentiments-invisible': !_vm.toggleKeywordSentimentsVisible
      }
    ],attrs:{"options":_vm.chartOptions,"tooltip":_vm.chartTooltip},on:{"tooltip":_vm.onChartTooltip}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }