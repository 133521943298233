import api from '@/lib/api';
import i18n from '@/lib/i18n';
import SmartstoreStatus from '@/enums/SmartstoreStatus';
import DialogSize from '@/enums/DialogSize';

const state = {
  smartstoreStatus: SmartstoreStatus.NONE,
  smartstoreAccessAccepted: false,
  smartstoreStartedAt: null,
  smartstoreExpiresAt: null,
  smartstoreName: '',
  enableSmartstoreReviewSync: false
};

const mutations = {
  SET_SMARTSTORE_STATUS(state, smartstoreStatus) {
    state.smartstoreStatus = smartstoreStatus;
  },
  SET_SMARTSTORE_ACCESS_ACCEPTED(state, smartstoreAccessAccepted) {
    state.smartstoreAccessAccepted = smartstoreAccessAccepted;
  },
  SET_SMARTSTORE_STARTED_AT(state, smartstoreStartedAt) {
    state.smartstoreStartedAt = smartstoreStartedAt;
  },
  SET_SMARTSTORE_EXPIRES_AT(state, smartstoreExpiresAt) {
    state.smartstoreExpiresAt = smartstoreExpiresAt;
  },
  SET_SMARTSTORE_NAME(state, smartstoreName) {
    state.smartstoreName = smartstoreName;
  },
  SET_ENABLE_SMARTSTORE_REVIEW_SYNC(state, enable_smartstore_review_sync) {
    state.enableSmartstoreReviewSync = enable_smartstore_review_sync;
  }
};

const actions = {
  fetchSmartstoreStatus: ({ commit }) => {
    return api.get('/smartstore/home').then(({ data }) => {
      commit('SET_SMARTSTORE_STATUS', data.smartstore_status);
      commit('SET_SMARTSTORE_ACCESS_ACCEPTED', data.smartstore_access_accepted);
      commit('SET_SMARTSTORE_NAME', data.smartstore_name);
      commit('SET_SMARTSTORE_EXPIRES_AT', data.smartstore_expires_at);
      commit(
        'SET_ENABLE_SMARTSTORE_REVIEW_SYNC',
        data.enable_smartstore_review_sync
      );
    });
  },
  requestCancelProductMatchJob: ({ commit }, successMessage) => {
    return api
      .patch('/smartstore/product_match_jobs/cancel', {}, { successMessage })
      .then(({ data }) =>
        commit('SET_SMARTSTORE_STATUS', data.smartstore_status)
      );
  },
  requestRetryProductMatchJob: ({ commit }) => {
    return api
      .patch('/smartstore/product_match_jobs/retry')
      .then(({ data }) =>
        commit('SET_SMARTSTORE_STATUS', data.smartstore_status)
      );
  },
  requestCancelEstimation: ({ commit }, successMessage) => {
    return api
      .patch('/smartstore/estimations/cancel', {}, { successMessage })
      .then(({ data }) =>
        commit('SET_SMARTSTORE_STATUS', data.smartstore_status)
      );
  },
  requestRetryImport: ({ commit }, successMessage) => {
    return api
      .patch('/smartstore/imports/retry', {}, { successMessage })
      .then(({ data }) =>
        commit('SET_SMARTSTORE_STATUS', data.smartstore_status)
      );
  },
  fetchSmartstoreServiceInfo: ({ commit }) => {
    return api.get('/smartstore/home').then(({ data }) => {
      commit('SET_SMARTSTORE_STARTED_AT', data.smartstore_started_at);
      commit('SET_SMARTSTORE_EXPIRES_AT', data.smartstore_expires_at);
    });
  },
  openProductMatchGuide({
    dispatch,
    rootState: {
      session: { currentBrand }
    }
  }) {
    dispatch(
      'dialog/alert',
      {
        title: i18n.t('smartstore.file_upload_guide.title'),
        message: i18n.t('smartstore.file_upload_guide.message', {
          products_link: `https://${location.host}/v2/product/products?brand_id=${currentBrand.id}`
        }),
        width: DialogSize.MIDDLE
      },
      { root: true }
    );
  }
};

export default { namespaced: true, state, mutations, actions };
