var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AppProgressSteps"},_vm._l((_vm.steps),function(step,i){return _c('div',{key:i,staticClass:"AppProgressSteps__item"},[_c('div',{staticClass:"AppProgressSteps__index-container"},[_c('AppSvgIcon',{class:[
          'AppProgressSteps__index-icon',
          _vm.isFutureStep(i) ? 'AppProgressSteps__index-icon--future' : ''
        ],attrs:{"name":_vm.getIconName(i)}}),(_vm.isCurrentOrFutureStep(i))?_c('span',{class:[
          'AppProgressSteps__index',
          _vm.isFutureStep(i) ? 'AppProgressSteps__index--future' : ''
        ]},[_vm._v(_vm._s(i + 1))]):_vm._e()],1),_c('span',{class:[
        i === _vm.currentStep
          ? 'AppProgressSteps__step--current'
          : 'AppProgressSteps__step'
      ]},[_vm._v(_vm._s(step))]),(i !== _vm.steps.length - 1)?_c('AppSvgIcon',{staticClass:"AppProgressSteps__line",attrs:{"name":"icon-line"}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }