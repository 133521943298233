import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';
import { mapActions } from 'vuex';

export default {
  mixins: [FormView, SettingsView],
  props: { eventBus: { type: Object, required: true } },
  data() {
    return { isLoading: true, data: null };
  },
  watch: {
    formProps() {
      this.emitFormProps();
    }
  },
  created() {
    this.emitFormProps();
    this.eventBus.$on('submit', () => this.formEventBus.$emit('submit'));
    api
      .get(this.SETTINGS_URL)
      .then(({ data }) => {
        if (this.SETTINGS_URL == '/brand_settings') {
          data.brand_settings.disable_init_js_urls = data.brand_settings.disable_init_js_urls.join(
            '\r\n'
          );
        }
        this.data = data;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('session', ['fetchSession']),
    emitFormProps() {
      this.$emit('form-props', this.formProps);
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(this.SETTINGS_URL, formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.$emit('submitted');
          this.fetchSession();
        })
        .finally(() => (this.isSubmitting = false));
    },
    setOrgFormObject(orgFormObject) {
      this.orgFormObject = orgFormObject;
    }
  }
};
