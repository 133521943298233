<template>
  <div
    :class="[
      'AppSelection',
      {
        'AppSelection--disabled': disabled
      },
      { 'AppSelection--checked': checked }
    ]"
    @click="disabled ? null : $emit('check')"
  >
    {{ label }}
    <button type="button" @click="disabled ? null : $emit('remove')">
      <AppSvgIcon name="icon-tag-close" class="AppSelection__icon" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'AppSelection',
  props: {
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.AppSelection {
  display: inline-block;
  position: relative;
  border: 1px solid $color-grey-35;
  border-radius: 3px;
  padding-left: 8px;
  padding-right: 20px;
  word-break: break-all;
  cursor: default;

  &--disabled {
    border-color: $color-grey-25;
    color: $color-disable-text;
  }

  &--checked {
    outline: 2px solid $color-mango;
  }
}

.AppSelection__icon {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
