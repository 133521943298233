var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppTable',{attrs:{"table-id":"TargetBillingPlanInfoTable","columns":_vm.COLUMNS,"rows":_vm.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
var value = ref.value;
return [(column.id === 'dau_interval')?[_c('AppData',{class:{
          'TargetBillingPlanInfoTable__dau-interval': true,
          'TargetBillingPlanInfoTable__dau-interval--highlight': row.highlight
        },attrs:{"value":value}})]:(column.id === 'promotion')?[_vm._v(" "+_vm._s(_vm.$t('body.promotion.monthly_bonus_point', [row.monthly_bonus_point]))+" "),_c('div',{staticClass:"TargetBillingPlanInfoTable__promotion-monthly-free-dispatchable-sms-count"},[_vm._v(" "+_vm._s(_vm.$t('body.promotion.monthly_free_dispatchable_sms_count', [ row.MonthlyFreeDispatchableSmsCount ]))+" ")])]:(column.id === 'monthly_price')?[_c('AppNumber',{staticClass:"TargetBillingPlanInfoTable__monthly-price",attrs:{"value":_vm.Payment.priceWithVat(value),"unit":"currency"}}),_c('div',{staticClass:"TargetBillingPlanInfoTable__monthly-price-calculation"},[_c('AppNumber',{attrs:{"value":value,"unit":"currency"}}),_c('span',[_vm._v("+"+_vm._s(_vm.$t('vat'))+" ")]),_c('AppNumber',{attrs:{"value":_vm.Payment.priceWithVat(value) - value,"unit":"currency"}})],1)]:_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }