<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{
      ...formProps,
      objectId: 'service_transaction',
      detectFormDataChange: false,
      submitLabel: $t('app.save'),
      submittingLabel: $t('app.saving')
    }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="service_transaction[transaction_type]"
      :value="ServiceTransactionType.PAYMENT_CANCELLED"
    />
    <input type="hidden" name="service_transaction[new_value]" :value="0" />
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import ServiceTransactionType from '@/enums/ServiceTransactionType';
import DialogFormView from '@/mixins/DialogFormView';
import CremaServiceType from '@/enums/CremaServiceType';

export default {
  name: 'ThePaymentHistoryAddSmartstoreTransactionDialog',
  mixins: [DialogFormView],
  data() {
    return {
      orgFormObject: {
        service_type: CremaServiceType.SMARTSTORE_STARTER
      }
    };
  },
  computed: {
    ServiceTransactionType: () => ServiceTransactionType,
    formSections() {
      const serviceType = this.formObject.service_type;
      return [
        {
          groups: [
            {
              id: 'service_type',
              label: this.$t('smartstore_transaction.service_type'),
              type: 'select',
              options: [
                CremaServiceType.SMARTSTORE_STARTER,
                CremaServiceType.SMARTSTORE_DAILY
              ].map(value => ({
                label: this.serviceTypeLabel(value),
                value
              }))
            },
            {
              id: 'description',
              type: 'text',
              name: 'service_transaction[description]',
              label: this.$t('smartstore_transaction.description'),
              value: this.description,
              placeholder: this.serviceTypeLabel(serviceType)
            }
          ]
        }
      ];
    },
    description() {
      return (
        this.formObject.description ||
        this.serviceTypeLabel(this.formObject.service_type)
      );
    }
  },
  methods: {
    serviceTypeLabel(serviceType) {
      switch (serviceType) {
        case CremaServiceType.SMARTSTORE_STARTER:
          return this.$t('smartstore_transaction_type_starter_payment_return');
        case CremaServiceType.SMARTSTORE_DAILY:
          return this.$t('smartstore_transaction_type_daily_payment_return');
        default:
          return '';
      }
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/payment_history/service_transactions', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          this.eventBus.$emit('update', data);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "이용내역 추가하기",
  "sub_title": "리뷰 부가서비스 이용내역",
  "smartstore_transaction_type_starter_payment_return": "과거 리뷰 연동 결제 취소 내역 추가",
  "smartstore_transaction_type_daily_payment_return": "매일 리뷰 연동 결제 취소 내역 추가",
  "smartstore_transaction": {
    "service_type": "항목 선택",
    "description": "메시지 입력"
  }
}
</i18n>
