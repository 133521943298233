<template>
  <AppModalDefault
    class="BizMessageProfileDialog"
    :title="$t('title')"
    :sub-title="$t('subtitle')"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppDataList :data="items">
          <template #value="{ id }">
            <template v-if="id === 'delete_profile'">
              <div>{{ $t('delete_profile_description') }}</div>
              <div class="mt4">
                <AppButton
                  button-style="red-outline"
                  :label="$t('delete_profile')"
                  @click="clickDeleteProfile"
                />
              </div>
            </template>
          </template>
        </AppDataList>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DialogView from '@/mixins/DialogView';
import BizMessageStatus from '@/enums/BizMessageStatus';
import BizMessageProfileStatus from '@/enums/BizMessageProfileStatus';

export default {
  name: 'BizMessageProfileDialog',
  mixins: [DialogView],
  data() {
    return { isLoading: false };
  },
  computed: {
    ...mapState('bizMessage', ['bizMessageProfile']),
    items() {
      return [
        {
          id: 'yellow_id',
          value: this.bizMessageProfile.yellow_id
        },
        {
          id: 'biz_message_status',
          value: this.bizMessageStatusValue
        },
        {
          id: 'status',
          value: BizMessageProfileStatus.t(this.bizMessageProfile.status)
        },
        {
          id: 'created_at',
          value: this.bizMessageProfile.created_at,
          type: 'datetime'
        },
        { id: 'delete_profile' }
      ].map(e => ({ ...e, label: this.$t(e.id) }));
    },
    bizMessageStatusValue() {
      const { biz_message_status } = this.bizMessageProfile;
      let translatedStatus = BizMessageStatus.t(biz_message_status);

      if (biz_message_status === BizMessageStatus.BLOCKED) {
        translatedStatus += this.$t('biz_message_status_blocked');
      }

      return translatedStatus;
    }
  },
  methods: {
    ...mapActions('bizMessage', ['deleteProfile']),
    ...mapActions('dialog', ['confirm']),
    clickDeleteProfile() {
      return this.confirm({
        type: 'delete_confirm',
        title: this.$t('delete_profile_confirm_title'),
        message: this.$t('delete_profile_confirm_message')
      }).then(result => {
        if (!result) return;

        this.isLoading = true;
        this.deleteProfile(this.$t('deleted_profile'))
          .then(() => this.close(true))
          .finally(() => (this.isLoading = false));
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "프로필 상세정보",
  "subtitle": "알림톡 프로필 관리",
  "yellow_id": "카카오톡 채널 아이디",
  "biz_message_status": "프로필 상태",
  "biz_message_status_blocked": " (카카오 정책에 의해 채널이 차단되었습니다.)",
  "status": "카카오톡 채널 상태",
  "created_at": "등록일시",
  "delete_profile": "발신 프로필 삭제",
  "delete_profile_confirm_title": "프로필을 삭제하시겠습니까?",
  "delete_profile_confirm_message": "알림톡 프로필 삭제 시 모든 알림톡 발송이 중단됩니다.",
  "deleted_profile": "알림톡 프로필이 삭제되어 모든 알림톡 발송이 중단됩니다.",
  "delete_profile_description": "프로필 변경을 원하시면 삭제 후 새로운 프로필을 등록해주세요."
}
</i18n>
