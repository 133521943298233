<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <AppForm
      :id="SETTINGS_ID"
      v-bind="formProps"
      submit-button
      :object-id="objectId"
      v-on="formEvents"
    >
      <template #group="{ id }">
        <template v-if="id === 'recalculate_target_product_data'">
          <AppDateRangePicker
            v-model="dateRange"
            class="AppForm__group-field"
            :max-days="90"
            required
          />
          <AppButton
            class="AppForm__group-field"
            :label="
              $t(
                `form.recalculate_target_product_data.button.${
                  isJobInProgressExists ? 'in_progress' : 'recalculate'
                }`
              )
            "
            :disabled="recalculateButtonDisabled"
            @click="openRecalculateDialog"
          />
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';
import TargetContentView from '@/mixins/TargetContentView';
import DialogSize from '@/enums/DialogSize';

export default {
  name: 'DisplaySettingsAdmin',
  mixins: [FormView, SettingsView, TargetContentView],
  data: () => ({ isLoading: true, mainCorners: [], dateRange: {} }),
  computed: {
    ...mapGetters('targetProductDataCalculationJobs', [
      'isJobInProgressExists'
    ]),
    SETTINGS_ID() {
      return 'display_settings_admin';
    },
    objectId() {
      return 'display_settings';
    },
    mainCornerOptions() {
      return this.mainCorners.map(corner => ({
        label: corner.name,
        value: corner.code
      }));
    },
    groupOptions() {
      if (this.isLoading) return {};

      return {
        inactive_main_corner_codes: {
          type: 'multiple_select',
          placeholder: this.$t('form.inactive_main_corner_codes.placeholder'),
          options: this.mainCornerOptions
        },
        display_in_test_server: {
          type: 'checkbox',
          description: this.$t('form.display_in_test_server.description')
        },
        launch_display_menu: { type: 'checkbox' },
        recalculate_target_product_data: {
          groupDescription: this.$t(
            'form.recalculate_target_product_data.description'
          ),
          hint: this.$t('form.recalculate_target_product_data.hint')
        }
      };
    },
    recalculateButtonDisabled() {
      return (
        !this.dateRange.start_date ||
        !this.dateRange.end_date ||
        this.isJobInProgressExists
      );
    }
  },
  mounted() {
    api
      .get('/display/settings/admin')
      .then(({ data }) => {
        this.orgFormObject = data.settings;
        this.mainCorners = data.main_corners;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('targetProductDataCalculationJobs', ['createCalculationJob']),
    openRecalculateDialog() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'confirm',
          title: this.$t(
            'form.recalculate_target_product_data.message_dialog.title'
          ),
          markdownText: this.$t(
            'form.recalculate_target_product_data.message_dialog.content_html'
          ),
          closeButtonLabel: this.$t(
            'form.recalculate_target_product_data.message_dialog.confirm'
          ),
          width: DialogSize.SMALL
        }
      ]).then(eventBus =>
        eventBus.$on('close', () => this.createCalculationJob(this.dateRange))
      );
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch('/display/settings/update_admin', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.orgFormObject = this.formObject;
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "form": {
    "inactive_main_corner_codes": {
      "placeholder": "리스트에 표시하지 않고자 하는 메인페이지 진열 위치를 추가해주세요."
    },
    "display_in_test_server": {
      "description": "테스트 서버에서도 실제 진열 및 상품 관리를 실행합니다."
    },
    "recalculate_target_product_data": {
      "description": "선택한 기간의 노출횟수, 구매횟수를 재계산 합니다.",
      "hint": "재계산 기간은 최대 90일까지 선택 가능합니다.",
      "button": {
        "recalculate": "재계산",
        "in_progress": "진행 중"
      },
      "message_dialog": {
        "title": "상품 데이터 재계산을 실행하시겠습니까?",
        "content_html": "상품 데이터 재계산은 최소 5분, 최대 30분 후 완료됩니다.<br />재계산 진행 중에는 새로운 재계산을 실행 할 수 없습니다.",
        "confirm": "재계산"
      }
    }
  }
}
</i18n>
