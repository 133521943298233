<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppInfoBox
      class="DisplayCategoryPageProductDisplays__info-box"
      hide-type="close"
      info-box-id="display-category-page-product-displays-info"
    >
      {{ $t('info') }}
    </AppInfoBox>
    <DisplayProductDisplayCreateButton
      class="DisplayCategoryPageProductDisplays__create-button"
    />
    <DisplayProductDisplaysTable
      product-display-range="general"
      :event-bus="eventBus"
    />
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import api from '@/lib/api';
import DisplayPageType from '@/enums/DisplayPageType';

export default {
  name: 'DisplayCategoryPageProductDisplays',
  data: () => ({ isLoading: true, eventBus: new Vue(), timer: null }),
  computed: {
    ...mapState('displayProductDisplay', ['isStatusUpdated']),
    ...mapState('displayProductDisplays', ['isPolling'])
  },
  watch: {
    isStatusUpdated() {
      if (this.isStatusUpdated) this.fetchDisplays();
    },
    isPolling() {
      if (this.isPolling) this.startPolling();
      else this.stopPolling();
    }
  },
  mounted() {
    this.fetchDisplays();
    this.eventBus.$on('show-cohorts', this.showCohorts);
    this.SET_POLLING(true);
    this.startPolling();
  },
  beforeDestroy() {
    this.SET_POLLING(false);
    this.stopPolling();
  },
  methods: {
    ...mapMutations('displayProductDisplays', [
      'SET_PRODUCT_DISPLAYS',
      'SET_POLLING'
    ]),
    ...mapMutations('displayProductDisplay', ['UPDATE_IS_STATUS_UPDATED']),
    ...mapActions('drawer', ['openDrawer']),
    showCohorts(row) {
      this.openDrawer([
        'DisplayProductDisplayCohortsDrawer',
        { productDisplay: row }
      ]);
    },
    fetchDisplays() {
      api
        .get('/display/product_displays', {
          params: { page_type: DisplayPageType.CATEGORY }
        })
        .then(({ data }) => {
          this.SET_PRODUCT_DISPLAYS(data.product_displays);
          this.UPDATE_IS_STATUS_UPDATED(false);
        })
        .finally(() => (this.isLoading = false));
    },
    startPolling() {
      if (this.timer) return;
      this.timer = setInterval(() => this.fetchDisplays(), 5000);
    },
    stopPolling() {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
};
</script>

<style scoped>
.DisplayCategoryPageProductDisplays__info-box {
  margin-bottom: 24px;
}

.DisplayCategoryPageProductDisplays__create-button {
  margin-bottom: 24px;
}
</style>

<i18n locale="ko">
{
  "info": "진열 설정을 생성해 원하는 카테고리에 적용할 수 있습니다. 카테고리 중심으로 관리가 필요하면 상단 ‘카테고리별 관리' 탭을 클릭해주세요."
}
</i18n>
