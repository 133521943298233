<template>
  <AppModalDefault :title="$t('title')" @close="close">
    <template #body>
      <AppInfoBox>{{ $t('description') }}</AppInfoBox>
      <AppImage
        src="https://assets.cre.ma/m/admin/crema-target-similar-size-not-using-fit.png"
        class="TargetWidgetCampaignWidgetRecommendationUnitNotUsingFitDialog__image"
      />
    </template>
    <template #foot>
      <AppButton :label="$t('app.confirmed')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'TargetWidgetCampaignWidgetRecommendationUnitNotUsingFitDialog',
  mixins: [DialogView],
  props: {
    title: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
.TargetWidgetCampaignWidgetRecommendationUnitNotUsingFitDialog__image {
  margin-top: 12px;
}
</style>

<i18n locale="ko">
{
  "title": "[Fit] 이 상품과 유사 사이즈 상품 추천",
  "description": "이 상품과 유사 사이즈 상품 추천은 Crema Fit 서비스의 데이터를 활용하므로 Fit 서비스 사용이 필요합니다."
}
</i18n>
