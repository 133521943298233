<template>
  <div class="StepMainColor">
    <AppAjaxContent :is-loading="isSubmitting">
      <AccordionStepContent
        :current-step="1"
        :steps-count="2"
        :is-completed="requestInstallationCompleted"
        :title="$t('title')"
        :tip="$t('tip')"
        @modify="$emit('modify')"
        @next="submit"
      >
        <div class="StepMainColor__image">
          <div
            class="StepMainColor__image-background"
            :style="{ backgroundColor: formObject.main_color }"
          >
            <AppImage
              src="https://assets.cre.ma/m/admin/v2/onboarding-main-color-v4.png"
              :resolutions="[2, 3]"
            />
          </div>
        </div>
        <AppForm
          class="StepMainColor__form"
          v-bind="formProps"
          :disabled="requestInstallationCompleted"
          v-on="formEvents"
        />
      </AccordionStepContent>
    </AppAjaxContent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormView from '@/mixins/FormView';
import AccordionStepContent from './AccordionStepContent';

export default {
  name: 'StepMainColor',
  components: { AccordionStepContent },
  mixins: [FormView],
  data() {
    return { isSubmitting: false };
  },
  computed: {
    ...mapGetters('easyJoin', ['mainColor', 'requestInstallationCompleted']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'main_color',
              type: 'color',
              label: this.$t('main_color'),
              labelDisabled: this.requestInstallationCompleted
            }
          ]
        }
      ];
    }
  },
  created() {
    this.orgFormObject = { main_color: this.mainColor };
  },
  methods: {
    ...mapActions('easyJoin', ['updateOnboardingProgress']),
    submit() {
      this.isSubmitting = true;
      this.updateOnboardingProgress({ main_color: this.formObject.main_color })
        .then(() => {
          this.orgFormObject = { ...this.formObject };
          this.$emit('next');
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.StepMainColor__image {
  padding: 20px 32px;
  text-align: center;
  background-color: $color-grey-05;
  line-height: 0;
}

.StepMainColor__image-background {
  display: inline-block;
}

.StepMainColor__form {
  margin-top: 16px;
}
</style>

<i18n locale="ko">
{
  "title": "위젯에 활용할 쇼핑몰 메인 색상",
  "tip": "쇼핑몰 메인 색상은 작성 팝업 및 위젯의 버튼 색상, 위젯 상단의 평점 그래프 평점 그래프 바 하이라이트 색상, 별점 아이콘 색상에 적용됩니다.",
  "main_color": "쇼핑몰 메인 색상"
}
</i18n>
