<template>
  <AppMultipleSelectTable
    :dialog-title="$t('select_product')"
    :items="products"
    :columns="[
      { id: 'code', label: $t('products.code') },
      { id: 'name', label: $t('products.name') }
    ]"
    :dialog-columns="productColumns"
    :search-types="productSearchTypes"
    :no-data="$t('no_data')"
    :select-button-label="selectButtonLabel"
    v-bind="{ name, disabled }"
    @change="$emit('change', $event)"
    @search="searchProducts"
  />
</template>

<script>
import ProductSelectable from '@/mixins/ProductSelectable';

export default {
  name: 'AppMultipleSelectProduct',
  mixins: [ProductSelectable],
  model: { prop: 'products', event: 'change' },
  props: {
    name: { type: String, default: '' },
    products: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    selectButtonLabel: {
      type: String,
      default() {
        return this.$t('select_product');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .multiple-select-table__name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

<i18n locale="ko">
{
  "select_product": "상품 추가",
  "no_data": "선택한 상품이 없습니다."
}
</i18n>
