<template>
  <AppModalForm
    :title="$t('menu.admin_locale_dialog')"
    :form-props="{ id: SETTINGS_ID, ...formProps }"
    v-on="formEvents"
  />
</template>

<script>
import { mapState } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';
import SettingsView from '@/mixins/SettingsView';

export default {
  name: 'TheAdminLocaleDialog',
  mixins: [DialogFormView, SettingsView],
  data: () => ({ SETTINGS_ID: 'admin_locale' }),
  computed: {
    ...mapState('session', ['currentUser']),
    groupOptions() {
      return {
        admin_locale_info: {
          type: 'infobox',
          label: '',
          value: this.$t('info')
        },
        admin_locale: {
          type: 'select_radio',
          options: [
            { value: 'ko', label: '한국어' },
            { value: 'en', label: 'English' },
            { value: 'ja', label: '日本語' }
          ]
        }
      };
    }
  },
  created() {
    this.orgFormObject = { admin_locale: this.currentUser.admin_locale };
  },
  methods: {
    submit() {
      const { admin_locale } = this.formObject;
      this.isSubmitting = true;
      api
        .patch('/user/update_admin_locale', { admin_locale })
        .then(() => {
          this.close(true);
          setTimeout(() => window.location.reload());
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "info": "관리자 페이지 메뉴 문구로 사용하는 언어를 변경합니다.<br />리뷰 본문과 같은 메뉴 이외의 콘텐츠, 화폐 단위 등은 변경되지 않습니다."
}
</i18n>
