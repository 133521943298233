<template>
  <AppModalDefault
    :title="$t('fit_size_csv_dialog.title')"
    :sub-title="$t('fit_size_csv_dialog.sub_title', { csv_id: csv.id })"
    width="middle"
    @close="close"
  >
    <AppAjaxContent slot="body" :is-loading="isLoading">
      <AppContainer>
        <AppDataList :data="csvData" />
      </AppContainer>
      <div class="FitSizeCsvDialog__table-head">
        <AppSelectButton
          v-model="listType"
          :options="availableListTypes"
          class="FitSizeCsvDialog__select-list-type"
          @change="searchResource"
        />
        <AppButtonDownload
          :label="$t('local.button_download_failed_items')"
          :url="`/api/fit/csvs/download_failed_items.csv?id=${csv.id}`"
          class="FitSizeCsvDialog__button-download-failed-items"
          @click="downloadFailedItemsCsv(csv.id)"
        />
      </div>
      <AppResourceTable
        v-if="listType === 'success'"
        table-id="fit-csv-success-products-table"
        class="tab-small__content"
        :enable-total-count="false"
        :resources="successProducts"
        :columns="successProductsColumns"
        :rows="successProductsRows"
        @paginate="paginate"
      >
        <template #cell="{ column, value }">
          <template v-if="column === 'actions'">
            <AppButton
              :label="$t('local.button_edit')"
              @click="
                openDialog(['FitSizeProductEditDialog', { product: value }])
              "
            />
            <AppButton
              v-if="value.fit_product"
              :label="$t('local.button_preview')"
              @click="
                openDialog([
                  'FitSizeProductPreviewDialog',
                  {
                    name: value.name,
                    url: value.fit_product.url
                  }
                ])
              "
            />
          </template>
        </template>
      </AppResourceTable>
      <AppResourceTable
        v-else
        table-id="fit-csv-error-products-table"
        class="tab-small__content"
        :enable-total-count="false"
        :resources="errorProducts"
        :columns="errorProductsColumns"
        :rows="errorProductsRows"
        @paginate="paginate"
      />
    </AppAjaxContent>
  </AppModalDefault>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import DialogView from '@/mixins/DialogView';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';

export default {
  name: 'FitSizeCsvDialog',
  mixins: [DialogView, ResourceViewNoRoute],
  props: {
    csv: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      successProductsColumns: [
        { id: 'code', label: this.$t('fit_product.code') },
        {
          id: 'product',
          label: this.$t('fit_product.product'),
          type: 'product'
        },
        { id: 'template', label: this.$t('fit_product.template') },
        { id: 'actions', label: this.$t('app.actions') }
      ],
      errorProductsColumns: [
        {
          id: 'lineNum',
          label: this.$t('local.error_product_line_num')
        },
        { id: 'code', label: this.$t('fit_product.code') },
        {
          id: 'errorMessage',
          label: this.$t('local.error_product_error_message')
        }
      ],
      listType: 'success',
      availableListTypes: [
        {
          label: this.$t('local.list_type_success'),
          value: 'success'
        },
        {
          label: this.$t('local.list_type_error'),
          value: 'error'
        }
      ],
      isLoading: true,
      successProducts: nullResources,
      errorProducts: nullResources
    };
  },
  computed: {
    defaultResourceParams() {
      return { page: '1', per: '20', id: this.csv.id };
    },
    successProductsRows() {
      return this.successProducts.items.map(product => ({
        code: product.code,
        product: product,
        template: product.fit_product
          ? product.fit_product.template.name
          : null,
        actions: product
      }));
    },
    errorProductsRows() {
      return this.errorProducts.items.map(product => ({
        lineNum: product.line_num,
        code: product.code,
        errorMessage: this.$t(
          `fit_csv_row_result_code.${product.error_message}`
        )
      }));
    },
    csvData() {
      return [
        {
          label: this.$t('fit_csv.registered_at'),
          value: this.csv.created_at,
          type: 'datetime'
        },
        {
          label: this.$t('fit_csv.finished_at'),
          value: this.csv.updated_at,
          type: 'datetime'
        },
        {
          label: this.$t('fit_csv.result'),
          value: [
            `${this.$t('fit_csv.success_count', {
              success_count: this.csv.success_count
            })}`,
            `${this.$t('fit_csv.error_count', {
              error_count: this.csv.error_count
            })}`
          ].join('\n'),
          type: 'pre'
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/fit/csvs/csv_products', { params })
        .then(({ data }) => {
          this.successProducts = data.success_products;
          this.errorProducts = data.error_products;
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_clearfix.scss';

.FitSizeCsvDialog {
  position: relative;
}

.FitSizeCsvDialog__table-head {
  @include clearfix;
}

.FitSizeCsvDialog__select-list-type {
  float: left;
}

.FitSizeCsvDialog__button-download-failed-items {
  float: right;
}

::v-deep {
  .fit-csv-success-products-table__code,
  .fit-csv-success-products-table__template,
  .fit-csv-error-products-table__line-num,
  .fit-csv-error-products-table__code {
    flex: 0 0 120px;
  }
}
</style>

<i18n locale="ko">
{
  "fit_size_csv_dialog": {
    "title": "일괄 등록 처리 결과",
    "sub_title": "#{csv_id} 상세 내역"
  },
  "local": {
    "button_download_failed_items": "오류 내역 다운로드(CSV)",
    "button_edit": "치수 직접입력",
    "button_preview": "미리보기",
    "error_product_line_num": "CSV 줄 번호",
    "error_product_error_message": "오류",
    "list_type_success": "등록 내역",
    "list_type_error": "오류 내역"
  }
}
</i18n>
