<template>
  <AppTabContent menu-item-id="display_category_page" :style="contentStyle">
    <RouterView />
  </AppTabContent>
</template>

<script>
import { mapMutations } from 'vuex';
import DisplayPageType from '@/enums/DisplayPageType';
import TargetContentView from '@/mixins/TargetContentView';

export default {
  name: 'DisplayCategoryPage',
  mixins: [TargetContentView],
  created() {
    this.SET_CORNER_PAGE_TYPE(DisplayPageType.CATEGORY);
  },
  methods: mapMutations('displayProductDisplays', ['SET_CORNER_PAGE_TYPE'])
};
</script>
