<template>
  <div>
    <AppRadioList
      :items="checkboxes"
      :class="
        enableSelectAll && enableIndent
          ? `AppMultipleSelectCheckbox__indent`
          : null
      "
      :inline="inline"
    >
      <template #item="{ item: option, index }">
        <AppCheckboxPartial
          v-if="option.selectAll"
          :check-status="checkStatus"
          :label="selectAllLabel"
          :input-style="null"
          @change="toggleAll($event)"
        >
          <template v-if="$scopedSlots['partial-label']" #label="labelProps">
            <slot name="partial-label" v-bind="labelProps" />
          </template>
        </AppCheckboxPartial>
        <AppMultipleSelectCheckbox
          v-else-if="option.options"
          v-model="childValue[index]"
          :name="name"
          :options="option.options"
          :enable-select-all="true"
          :enable-indent="true"
          :select-all-label="option.label"
          @change="childChanged($event, option.options)"
        >
          <template
            v-if="$scopedSlots['partial-label']"
            #partial-label="labelProps"
          >
            <slot name="partial-label" v-bind="labelProps" />
          </template>
        </AppMultipleSelectCheckbox>
        <AppCheckbox
          v-else
          :name="packingMethod === 'array' ? name : null"
          :value="option.value"
          :disabled="option.disabled"
          :label="option.label"
          :checked="isSelected(option)"
          :invalid="
            invalid &&
              (requiredItems.length === 0 ||
                requiredItems.includes(option.value))
          "
          @change="$event ? select(option) : unselect(option)"
        >
          <template v-if="$scopedSlots.label" #label="labelProps">
            <slot name="label" v-bind="labelProps" />
          </template>
          <template v-if="$scopedSlots['sub-item']" #sub-item="subItemProps">
            <slot name="sub-item" v-bind="subItemProps" />
          </template>
        </AppCheckbox>
      </template>
    </AppRadioList>
    <template v-if="name">
      <input
        v-if="packingMethod !== 'array'"
        type="hidden"
        :name="name"
        :value="value"
      />
      <input v-else-if="currentValue.length === 0" type="hidden" :name="name" />
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import MultipleSelectable from '@/mixins/MultipleSelectable';

export default {
  name: 'AppMultipleSelectCheckbox',
  mixins: [MultipleSelectable],
  props: {
    invalid: {
      type: Boolean,
      default: false
    },
    requiredItems: {
      type: Array,
      default: () => []
    },
    enableSelectAll: {
      type: Boolean,
      default: false
    },
    enableIndent: {
      type: Boolean,
      default: false
    },
    selectAllLabel: {
      type: String,
      default: function() {
        return this.$t('app.all');
      }
    },
    inline: { type: Boolean, default: false }
  },
  data() {
    return {
      childValue: {}
    };
  },
  computed: {
    checkboxes() {
      return [
        ...(this.enableSelectAll ? [{ selectAll: true }] : []),
        ...this.options.map(o => ({ ...o, id: o.value }))
      ];
    },
    checkStatus() {
      const allOptions = this.flattenOptions(this.options);
      const allOptionValues = _.map(allOptions, 'value');
      const currentValueSet = new Set(this.currentValue);
      const inCurrentValue = o => currentValueSet.has(o);

      return allOptionValues.every(inCurrentValue)
        ? 'checked'
        : allOptionValues.some(inCurrentValue)
        ? 'partial'
        : 'unchecked';
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.setChildValue();
      } else {
        this.childValue = {};
      }
    }
  },
  created() {
    this.setChildValue();
  },
  methods: {
    toggleAll(allStatus) {
      if (allStatus === 'checked') {
        const allOptions = this.flattenOptions(this.options);
        this.setCurrentValue(allOptions.map(o => o.value));
      } else if (allStatus === 'unchecked') {
        this.setCurrentValue([]);
        this.childValue = {};
      }
    },
    childChanged(newValue, childOptions) {
      const allChildOptions = this.flattenOptions(childOptions);
      const newChildValue = _.chain(this.currentValue)
        .cloneDeep()
        .pullAll(allChildOptions.map(o => o.value))
        .union(newValue)
        .value();
      this.setCurrentValue(newChildValue);
    },
    setChildValue() {
      if (this.packingMethod === 'array') {
        const optionValues = _.compact(_.map(this.options, 'value'));
        const childValues = _.reject(this.value, v => optionValues.includes(v));

        this.childValue = {};
        childValues.forEach(v => {
          const indexPath = this.findIndexPath(this.options, v);
          if (indexPath?.length > 0) {
            const childIndex = indexPath[0];
            this.childValue[childIndex] = this.childValue[childIndex] || [];
            this.childValue[childIndex].push(v);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.AppMultipleSelectCheckbox__indent {
  ::v-deep {
    li:not(:first-child) {
      margin-left: 12px;
    }
  }
}
</style>
