import { constructEnum } from '@/lib/enum';

const TargetWidgetFontWeight = constructEnum('TargetWidgetFontWeight', {
  NORMAL: 1,
  BOLD: 2,
  MEDIUM: 3
});

TargetWidgetFontWeight.fontWeightOptions = function(fontWeightId) {
  switch (fontWeightId) {
    case 'pc_review_message_style_font_weight':
    case 'mobile_review_message_style_font_weight':
    case 'pc_banner_review_message_style_font_weight':
    case 'mobile_banner_review_message_style_font_weight':
      return TargetWidgetFontWeight.options([
        TargetWidgetFontWeight.NORMAL,
        TargetWidgetFontWeight.MEDIUM,
        TargetWidgetFontWeight.BOLD
      ]);
    default:
      return TargetWidgetFontWeight.options([
        TargetWidgetFontWeight.NORMAL,
        TargetWidgetFontWeight.BOLD
      ]);
  }
};

export default TargetWidgetFontWeight;
