<template>
  <AppAjaxContent :is-loading="isLoading">
    <ReviewCategoryTable
      :categories="categories"
      @paginate="paginate"
      @refresh="refreshResource"
    />
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceView from '@/mixins/ResourceView';

import ReviewCategoryTable from './ReviewCategoryTable';

export default {
  name: 'ReviewCategories',
  components: { ReviewCategoryTable },
  mixins: [ResourceView],
  data() {
    return {
      isLoading: true,
      productCategories: nullResources
    };
  },
  computed: {
    categories() {
      return this.productCategories;
    }
  },
  methods: {
    fetchResource(params) {
      api
        .get('/product_categories', { params })
        .then(({ data }) => (this.productCategories = data.product_categories))
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>
