<template>
  <AppModalDefault
    :title="$t('settings.fit_settings_basic.groups.edit_descriptions_all')"
    width="middle"
    @close="close"
  >
    <template #body>
      <AppTabs
        v-model="activeTab"
        :tabs="childTabItems('fit_descriptions_edit_dialog')"
      />
      <AppInfoBox class="FitDescriptionsEditDialog__info-box">
        <i18n :path="`${activeType}.info_message`">
          <AppInternalLink
            class="FitDescriptionsEditDialog__info-box-link"
            :to="link"
            target="_blank"
            >{{ $t(`${activeType}.name`) }}</AppInternalLink
          >
        </i18n>
      </AppInfoBox>
      <AppTable
        class="fit_descriptions_edit_dialog__table"
        table-id="fit-descriptions-edit-table"
        :columns="COLUMNS"
        :rows="ROWS"
      >
        <template #cell="{ column, row }">
          <template v-if="column.id === 'actions'">
            <AppButton :label="$t('app.edit')" @click="openEditDialog(row)" />
            <AppButton
              button-style="red-outline"
              :label="row.isDeleting ? $t('app.deleting') : $t('app.delete')"
              @click="openDeleteConfirm(row)"
            />
          </template>
        </template>
      </AppTable>
    </template>
  </AppModalDefault>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import DialogSize from '@/enums/DialogSize';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'FitDescriptionsEditDialog',
  mixins: [DialogFormView],
  props: {
    initialTab: {
      type: String,
      default: 'fit_descriptions_edit_dialog_template'
    }
  },
  data() {
    return {
      activeTab: this.initialTab,
      COLUMNS: [
        {
          id: 'edit_type',
          type: 'pre',
          label: this.$t('edit_type'),
          align: 'left'
        },
        {
          id: 'actions',
          label: this.$t('app.actions')
        }
      ],
      ROWS: [
        {
          id: 1,
          edit_type: this.$t('size_descriptions'),
          value: 'size'
        },
        {
          id: 2,
          edit_type: this.$t('etc_descriptions'),
          value: 'etc'
        }
      ]
    };
  },
  computed: {
    ...mapGetters('menu', ['childTabItems']),
    activeType() {
      if (this.activeTab === 'fit_descriptions_edit_dialog_template')
        return 'template';

      return 'fit_product';
    },
    link() {
      if (this.activeTab === 'fit_descriptions_edit_dialog_template')
        return '/fit/templates';

      return '/fit/size/products';
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    openDeleteConfirm(row) {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('delete_confirm_title', [
            this.$t(`${this.activeType}.target_name`),
            row.edit_type
          ]),
          closeButtonLabel: this.$t('app.delete_confirm'),
          markdownText: this.$t('delete_confirm_description', [
            this.$t(`${this.activeType}.target_name`),
            row.edit_type
          ]),
          width: DialogSize.SMALL
        }
      ]).then(eventBus =>
        eventBus.$on('close', () => this.deleteDescriptions(row))
      );
    },
    deleteDescriptions(row) {
      this.$set(row, 'isDeleting', true);
      api
        .patch(
          `/fit/settings/update_descriptions?description_type=${row.value}&target=${this.activeType}`
        )
        .finally(() => {
          this.$delete(row, 'isDeleting');
          this.createToast(this.$t('deleted'));
        });
    },
    openEditDialog(row) {
      this.openDialog([
        'FitDescriptionsTextEditDialog',
        {
          target: this.activeType,
          descriptionType: row.value
        }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

.FitDescriptionsEditDialog__info-box-link {
  cursor: pointer;
  text-decoration: underline;
}

.fit_descriptions_edit_dialog__table {
  margin-top: 30px;
}

::v-deep {
  .AppTable__head-col {
    font-weight: bold;
    color: $color-grey-50;
  }

  .fit-descriptions-edit-table__actions {
    width: 158px;
  }
}
</style>

<i18n locale="ko">
{
  "template": {
    "name": "핏 템플릿",
    "info_message": "🚨 모든 {0}에 적용됩니다. 이 작업은 복원할 수 없습니다.",
    "target_name": "템플릿"
  },
  "fit_product": {
    "name": "실측이 입력된 모든 상품",
    "info_message": "🚨 {0}에 적용됩니다. 이 작업은 복원할 수 없습니다.",
    "target_name": "상품"
  },
  "edit_type": "수정 항목",
  "size_descriptions": "실측 치수 안내",
  "etc_descriptions": "기타 안내 사항",
  "delete_confirm_title": "모든 {0}에 등록된 {1} 문구를 삭제하시겠습니까?",
  "delete_confirm_description": "<span style='color: #616163'>모든 {0}에 등록된 {1} 문구가 삭제됩니다. <br> 이 작업은 되돌릴 수 없습니다. 계속하시겠습니까?</span>",
  "deleted": "삭제되었습니다."
}
</i18n>
