<template>
  <div class="ReviewReportsReviews">
    <AppReportsSearchbar v-bind="searchBarProps" v-on="searchBarEvents" />
    <ReviewReportsWarning />
    <AppChart
      :options="chartOptions"
      :tooltip="chartTooltip"
      @tooltip="onChartTooltip"
    />
    <AppButtonToggle
      v-model="showDetail"
      class="ReviewReportsReviews__toggle-button"
      :label="$t('toggle_detail_button')"
    />
    <AppResourceTable
      table-id="review-reports-reviews-table"
      :rows="rows"
      table-style="stats"
      :columns="COLUMNS"
      :resources="resources"
      :enable-total-count="false"
      @paginate="paginate"
    />
  </div>
</template>

<script>
import _ from 'lodash';

import ReportsView from '@/mixins/ReportsView';
import ReviewReportsWarning from '@/views/review/reports/ReviewReportsWarning';

export default {
  name: 'ReviewReportsReviews',
  components: { ReviewReportsWarning },
  mixins: [ReportsView],
  data() {
    return {
      COLUMNS: [
        { id: 'date', label: this.$t('app.date'), type: 'pre' },
        {
          id: 'reviewCount',
          label: this.$t('review_count'),
          type: 'number',
          default: '-',
          align: 'right'
        },
        {
          id: 'mediaReviewsCount',
          label: this.$t('media_review_count'),
          type: 'number',
          default: '-',
          align: 'right',
          tooltip: this.$t('media_review_count_tooltip')
        },
        {
          id: 'photoReviewsCount',
          label: this.$t('photo_review_count'),
          type: 'number',
          default: '-',
          align: 'right'
        },
        {
          id: 'videoReviewsCount',
          label: this.$t('video_review_count'),
          type: 'number',
          default: '-',
          align: 'right'
        },
        {
          id: 'textReviewsCount',
          label: this.$t('text_review_count'),
          type: 'number',
          default: '-',
          align: 'right'
        },
        {
          id: 'usersCountWithReview',
          label: this.$t('users_count_with_review'),
          type: 'number',
          default: '-',
          align: 'right'
        },
        {
          id: 'reviewCountPerPerson',
          label: this.$t('review_count_per_person'),
          type: 'number',
          forcePrecision: true,
          precision: 2,
          default: '-',
          align: 'right'
        },
        {
          id: 'hiddenDeletedRivewsCount',
          label: this.$t('hidden_deleted_rivew_count'),
          type: 'number',
          default: '-',
          align: 'right'
        }
      ],
      showDetail: false,
      chartTooltip: null
    };
  },
  computed: {
    rows() {
      const dataRowsWithDetail = this.dataRows.map((dataRow, i) => [
        dataRow,
        ...(this.showDetail ? this.detailRows[i] : [])
      ]);
      return [this.totalRow].concat(_.flatten(dataRowsWithDetail));
    },
    totalRow() {
      const reviewCount = _.sumBy(this.reportsChart, 'reviews_count');
      const usersCountWithReview = _.sumBy(
        this.reportsChart,
        'users_count_with_review'
      );
      const mediaReviewsCount = _.sumBy(
        this.reportsChart,
        'reviews_count_with_media'
      );
      const photoReviewsCount = _.sumBy(
        this.reportsChart,
        'reviews_count_with_photo'
      );
      const videoReviewsCount = _.sumBy(
        this.reportsChart,
        'reviews_count_with_video'
      );
      const textReviewsCount = this.subtraction(reviewCount, mediaReviewsCount);
      const hiddenDeletedRivewsCount = _.sumBy(
        this.reportsChart,
        'reviews_count_hidden_deleted'
      );

      return {
        date: this.$t('total'),
        rowStyle: 'stats-total',
        reviewCount,
        mediaReviewsCount,
        photoReviewsCount,
        videoReviewsCount,
        textReviewsCount,
        usersCountWithReview,
        reviewCountPerPerson: usersCountWithReview
          ? reviewCount / usersCountWithReview
          : null,
        hiddenDeletedRivewsCount
      };
    },
    dataRows() {
      return this.reportsTable.map(reviewReport => {
        const date = this.getFormattedDate(reviewReport.date);
        return {
          date,
          reviewCount: reviewReport.reviews_count,
          mediaReviewsCount: reviewReport.reviews_count_with_media,
          photoReviewsCount: reviewReport.reviews_count_with_photo,
          videoReviewsCount: reviewReport.reviews_count_with_video,
          textReviewsCount: this.subtraction(
            reviewReport.reviews_count,
            reviewReport.reviews_count_with_media
          ),
          usersCountWithReview: reviewReport.users_count_with_review,
          reviewCountPerPerson: reviewReport.reviews_count_per_person,
          hiddenDeletedRivewsCount: reviewReport.reviews_count_hidden_deleted,
          rowStyle: 'stats-summary'
        };
      });
    },
    detailRows() {
      return this.reportsTable.map(reviewReport => {
        const TYPES = ['pc', 'mobile', 'sms', 'email', 'imported'];
        return TYPES.map(type => ({
          date: this.$t(type),
          reviewCount: reviewReport[`reviews_count_${type}`],
          mediaReviewsCount: reviewReport[`reviews_count_with_media_${type}`],
          photoReviewsCount: reviewReport[`reviews_count_with_photo_${type}`],
          videoReviewsCount: reviewReport[`reviews_count_with_video_${type}`],
          textReviewsCount: this.subtraction(
            reviewReport[`reviews_count_${type}`],
            reviewReport[`reviews_count_with_media_${type}`]
          ),
          usersCountWithReview: reviewReport[`users_count_with_review_${type}`],
          reviewCountPerPerson:
            reviewReport[`reviews_count_per_person_${type}`],
          hiddenDeletedRivewsCount:
            reviewReport[`reviews_count_hidden_deleted_${type}`],
          rowStyle: 'stats-detail'
        }));
      });
    },
    chartOptions() {
      return {
        title: {
          text: this.$t('review_count')
        },
        series: [
          {
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData('reviews_count'),
            name: this.$t('review_count'),
            type: 'column',
            yAxis: 1
          },
          {
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData('reviews_count_with_media'),
            name: this.$t('media_review_count'),
            type: 'column',
            yAxis: 1
          },
          {
            pointInterval: this.pointInterval,
            pointStart: this.pointStart,
            data: this.chartData('reviews_count_per_person', 'float'),
            name: this.$t('average_review_count'),
            yAxis: 0
          }
        ],
        xAxis: { type: 'datetime' },
        yAxis: [
          {
            title: { text: this.$t('average_review_count') },
            labels: { format: `{value} ${this.$t('count_per_person')}` },
            opposite: true
          },
          {
            labels: {
              format: `{value:,.0f} ${this.$t('count')}`,
              style: {
                color: '#4572A7'
              }
            },
            title: {
              text: this.$t('review_count'),
              style: {
                color: '#4572A7'
              }
            }
          }
        ],
        tooltip: {
          enabled: false
        }
      };
    }
  },
  methods: {
    onChartTooltip({ x, index, series }) {
      this.chartTooltip = {
        title: this.getFormattedDate(new Date(x)),
        items: series.chart.series.map(({ data, name }) => ({
          symbol: 'circle',
          name,
          value: data[index].y
        }))
      };
    },
    subtraction(x, y) {
      if (x == undefined || x == null || y == undefined || y == null) return x;

      return x - y;
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewReportsReviews__toggle-button {
  background-color: white;
  padding-bottom: 12px;
}
::v-deep {
  .AppResourceTableCell {
    flex: 1 0 120px;
  }

  .review-reports-reviews-table__media-review-count {
    flex-basis: 200px;
  }
}
</style>

<i18n locale="ko">
{
  "review_count": "전체 리뷰수",
  "media_review_count": "포토/동영상 리뷰수",
  "media_review_count_tooltip": "포토/동영상 리뷰수 = 포토 리뷰 + 동영상 리뷰 - 둘 다 첨부한 리뷰",
  "photo_review_count": "포토 리뷰수",
  "video_review_count": "동영상 리뷰수",
  "text_review_count": "텍스트 리뷰수",
  "users_count_with_review": "작성 인원수",
  "review_count_per_person": "1인당 작성 리뷰수",
  "hidden_deleted_rivew_count": "숨김/삭제한 리뷰수", 
  "total": "총 합계",
  "average_review_count": "평균 리뷰수",
  "count_per_person": "개/명",
  "count": "개",
  "pc": "PC",
  "mobile": "모바일",
  "sms": "SMS + 알림톡",
  "email": "Email",
  "imported": "기존 리뷰",
  "toggle_detail_button": "작성 경로별 보기"
}
</i18n>
<i18n locale="en">
{
  "review_count": "Reviews count",
  "media_review_count": "Photo/Video reviews count",
  "media_review_count_tooltip": "Photo/Video reviews count = Photo reviews + Video reviews - Review attached to both",
  "photo_review_count": "Photo reviews count",
  "video_review_count": "Video reviews count",
  "text_review_count": "Text reviews count",
  "users_count_with_review": "Number of review written people",
  "review_count_per_person": "Reviews written per person",
  "total": "Total",
  "average_review_count": "Average reviews count",
  "count_per_person": "count/people",
  "count": "Count",
  "pc": "PC",
  "mobile": "Mobile",
  "sms": "SMS",
  "email": "Email",
  "imported": "Imported",
  "toggle_detail_button": "Show review written source"
}
</i18n>
