var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalDefault',{attrs:{"title":_vm.$t('menu.review_settings_comment_preset_dialog'),"width":"middle"},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('AppTabs',{attrs:{"tabs":_vm.childTabItems('review_settings_comment_preset_dialog')},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}}),_c('AppContainer',{staticClass:"ReviewSettingsCommentPresetDialog__description"},[_c('AppDataList',{attrs:{"data":_vm.activePresetType === 'smart'
            ? [
                { value: _vm.$t('smart.comment_description') },
                {
                  label: _vm.$t('smart.auto_conversion_rule'),
                  value: _vm.$t(
                    _vm.isShopifyBrand
                      ? 'smart.auto_conversion_rule_content_shopify'
                      : 'smart.auto_conversion_rule_content',
                    {
                      user: '%{user}',
                      grade: '%{grade}',
                      mileage: '%{mileage}',
                      type: '%{type}'
                    }
                  ),
                  type: 'pre'
                },
                {
                  label: _vm.$t('smart.example'),
                  value: _vm.$t(
                    _vm.isShopifyBrand
                      ? 'smart.example_content_html_shopify'
                      : 'smart.example_content_html',
                    {
                      user: '%{user}',
                      grade: '%{grade}',
                      mileage: '%{mileage}',
                      type: '%{type}',
                      sampleUserDisplayName: _vm.sampleUserDisplayName
                    }
                  ),
                  type: 'html'
                }
              ]
            : [{ value: _vm.$t('no_interpolation.comment_description') }]}})],1),_c('AppSearchbar',[_c('li',[_c('AppButton',{attrs:{"button-style":"red","label":_vm.$t((_vm.activePresetType + ".add_comment_preset"))},on:{"click":_vm.addCommentPreset}})],1)]),_c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppTable',{attrs:{"table-id":"review-comment-presets-table","columns":_vm.COLUMNS,"rows":_vm.rows},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
            var column = ref.column;
            var row = ref.row;
            var rowIndex = ref.rowIndex;
return [(column.id === 'message')?[(row.isEditing)?_c('AppTextarea',{ref:("textInput" + rowIndex),attrs:{"placeholder":_vm.$t((_vm.activePresetType + ".comment_preset_placeholder")),"disabled":row.isSaving || row.isDeleting},model:{value:(_vm.rows[rowIndex].message),callback:function ($$v) {_vm.$set(_vm.rows[rowIndex], "message", $$v)},expression:"rows[rowIndex].message"}}):_vm._e()]:(column.id === 'actions')?[(row.isEditing)?_c('AppButton',{attrs:{"disabled":row.isSaving || row.isCanceling,"label":_vm.$t('app.cancel')},on:{"click":function($event){return _vm.cancelCommentPreset(rowIndex)}}}):_c('AppButton',{attrs:{"disabled":row.isSaving || row.isDeleting,"label":_vm.$t('app.edit')},on:{"click":function($event){return _vm.editCommentPreset(rowIndex)}}}),(row.isEditing)?_c('AppButton',{attrs:{"button-style":"blue","disabled":row.isSaving || row.isCanceling,"label":row.isSaving ? _vm.$t('app.saving') : _vm.$t('app.save')},on:{"click":function($event){return _vm.saveCommentPreset(rowIndex)}}}):_c('AppButton',{attrs:{"button-style":"red-outline","disabled":row.isSaving || row.isDeleting,"label":row.isDeleting ? _vm.$t('app.deleting') : _vm.$t('app.delete')},on:{"click":function($event){return _vm.deleteCommentPreset(rowIndex)}}})]:_vm._e()]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }