<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppSelectHour id="basic" v-model="valueBasic" />
      <div class="ui-section__description">
        Selected Value: {{ valueBasic }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppSelectHour id="disabled" v-model="valueDisabled" disabled />
      <div class="ui-section__description">
        Selected Value: {{ valueDisabled }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppSelectHour id="invalid" v-model="valueInvalid" invalid />
      <div class="ui-section__description">
        Selected Value: {{ valueInvalid }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="selectable-hours">
          Selectable Hours (an hour interval)
        </label>
      </div>
      <AppSelectHour
        id="selectable-hours"
        v-model="valueSelectableHours"
        :selectable-hours="[9, 10, 11]"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueSelectableHours }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="selectable-hours">
          Selectable Hours (half an hour interval)
        </label>
      </div>
      <AppSelectHour
        id="selectable-hours"
        v-model="valueSelectableHoursHalf"
        interval="half"
        :selectable-hours="[8, 8.5, 9, 17.5, 18, 18.5, 19, 19.5, 20]"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueSelectableHoursHalf }}
      </div>
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="selectable-hours">
          Selectable Hours (a quarter of an hour interval)
        </label>
      </div>
      <AppSelectHour
        id="selectable-hours"
        v-model="valueSelectableHoursQuarter"
        interval="quarter"
        :selectable-hours="[15.25, 15.5, 15.75, 16, 16.25, 17]"
      />
      <div class="ui-section__description">
        Selected Value: {{ valueSelectableHoursQuarter }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiSelectHour',
  data() {
    return {
      valueBasic: 5,
      valueDisabled: 2,
      valueInvalid: 3,
      valueSelectableHours: 9,
      valueSelectableHoursHalf: 19.5,
      valueSelectableHoursQuarter: 15.75
    };
  }
};
</script>
