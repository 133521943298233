var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{style:(_vm.contentStyle),attrs:{"is-loading":_vm.isLoading}},[_c('AppDropdownMenuButton',{attrs:{"button-style":"red","label":_vm.$t('button.add_display_product_management'),"menu-items":_vm.menuItems}}),_c('AppButton',{attrs:{"label":_vm.$t('button.copy_script.label'),"tooltip":_vm.$t('button.copy_script.tooltip')},on:{"click":function($event){return _vm.openDialog(['DisplayCopyFastDeliveryWidgetScriptDialog'])}}}),_c('AppResourceTable',{staticClass:"DisplayProductManagements__table",attrs:{"columns":_vm.columns,"rows":_vm.rows,"enable-total-count":false,"no-data":_vm.$t('no_data')},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [(column === 'category_management')?[(row.use_category_management)?_c('AppBadge',{staticClass:"DisplayProductManagements__category",attrs:{"label":_vm.productCategoryName(row.category_id),"badge-style":"grey-outline"}}):_c('span',[_vm._v(_vm._s(_vm.$t('disabled')))])]:(column === 'icon_management')?[(!row.use_icon_management)?_c('span',[_vm._v(_vm._s(_vm.$t('disabled')))]):(row.pc_icon_ids.length > 0)?_c('DisplayProductManagementProductIcons',{attrs:{"icon-ids":row.pc_icon_ids}}):(row.mobile_icon_ids.length > 0)?_c('DisplayProductManagementProductIcons',{attrs:{"icon-ids":row.mobile_icon_ids}}):_vm._e()]:(column === 'widget_management')?[(row.use_widget_management)?_c('span',[_vm._v(_vm._s(_vm.$t('enabled')))]):_c('span',[_vm._v(_vm._s(_vm.$t('disabled')))])]:(column === 'last_applied_at')?[(row.scheduled_cohort_apply_at)?[(row.last_applied_at)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatFromNow")(row.last_applied_at))+" ")]):_c('div',[_vm._v("-")]),_c('AppBadge',{attrs:{"label":_vm.$t('app.detail'),"tooltip":{
              textAlign: 'left',
              message: _vm.$t('dispatching.tooltip.message'),
              info: _vm.$t('dispatching.tooltip.info', [
                _vm.formatDateTime(row.scheduled_cohort_apply_at)
              ])
            }}})]:(row.last_applied_at)?[_c('div',[_vm._v(" "+_vm._s(_vm._f("formatFromNow")(row.last_applied_at))+" ")]),_c('AppBadge',{attrs:{"label":_vm.$t('app.detail'),"tooltip":_vm.formatDateTimeWithoutSecond(row.last_applied_at)}})]:[_vm._v("-")]]:(column === 'next_apply_at')?[(row.next_apply_at)?[_c('div',[_vm._v(" "+_vm._s(_vm._f("formatFromNow")(row.next_apply_at))+" ")]),_c('AppBadge',{attrs:{"label":_vm.$t('app.detail'),"tooltip":_vm.formatDateTimeWithoutSecond(row.next_apply_at)}})]:[_vm._v("-")]]:(column === 'product_management_status')?[_c('DisplayProductManagementStatusBadge',{attrs:{"product-management-status":row.product_management_status,"apply-at":row.scheduled_cohort_apply_at,"dispatch-type":row.scheduled_cohort_dispatch_type}}),(_vm.isErrored(row.product_management_status))?_c('AppButton',{attrs:{"label":_vm.$t('show_detail'),"button-style":"underline"},on:{"click":function($event){return _vm.openErrorDetailDialog(row)}}}):_vm._e(),(
            _vm.isNormal(row.scheduled_cohort_dispatch_type) &&
              !_vm.hideByProgress(row)
          )?_c('AppButton',{attrs:{"label":_vm.$t('show_detail'),"button-style":"underline"},on:{"click":function($event){return _vm.showCohortProgress(row)}}}):_vm._e()]:(column === 'updated_info')?[_c('div',[_vm._v(" "+_vm._s(_vm._f("formatFromNow")(row.updated_at))+" ")]),_c('AppBadge',{attrs:{"label":_vm.$t('app.detail'),"tooltip":_vm.$t('updated_info_detail', [
              _vm.formatDateTime(row.updated_at),
              _vm.formatDateTime(row.created_at)
            ])}})]:(column === 'actions')?[_c('AppButton',{attrs:{"label":_vm.$t('app.edit'),"type":"link","to":("/display/product-managements/" + (row.id))}}),_c('AppDropdownMenuButton',{attrs:{"label":_vm.$t('app.manage'),"menu-items":[
            [
              {
                label: _vm.$t('button.enabled'),
                disabled: !row.can_enable,
                tooltip: {
                  textAlign: 'left',
                  message: row.can_enable
                    ? null
                    : _vm.$t('button.disable_tooltip')
                },
                selected: row.enabled,
                clickHandler: function () { return _vm.updateEnabled(true, row); }
              },
              {
                label: _vm.$t('button.disabled'),
                selected: !row.enabled,
                clickHandler: function () { return _vm.updateEnabled(false, row); }
              }
            ],
            [
              {
                label: _vm.$t('button.cohorts'),
                disabled: row.last_applied_at === null,
                clickHandler: function () { return _vm.openDrawer([
                    'DisplayProductManagementCohortsDrawer',
                    { productManagement: row }
                  ]); }
              },
              {
                label: _vm.$t('app.delete'),
                style: 'danger',
                superAdmin: row.last_applied_at !== null,
                clickHandler: function () { return _vm.deleteProductManagement(row); }
              }
            ]
          ]}})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }