<template>
  <AppSearchDropdown
    :options="filteredItems"
    v-bind="dropdownProps"
    @query="query = $event"
    @select="$emit('select', $event)"
    @close="$emit('close')"
  />
</template>

<script>
import { filterItems } from '@/lib/text-search';

export default {
  name: 'AppSelectSearchDropdown',
  props: {
    data: {
      type: Array,
      required: true
    },
    dropdownProps: {
      type: Object,
      default() {
        return {
          placeholder: this.$t('placeholder'),
          noResults: this.$t('no_results')
        };
      }
    },
    isShowingDropdown: {
      type: Boolean,
      default: false
    },
    onDemandOptions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { query: '' };
  },
  computed: {
    filteredItems() {
      return !this.onDemandOptions || this.isShowingDropdown
        ? filterItems(this.data, 'label', this.query)
        : [];
    }
  }
};
</script>

<i18n locale="ko">
{
  "no_results": "검색결과가 없습니다.",
  "placeholder": "검색어를 입력해주세요."
}
</i18n>
