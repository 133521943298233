<template>
  <div class="AnalyticsInsightAjaxContent">
    <Transition name="fade" @enter="$store.state.isContentReady = true">
      <div v-if="isReady">
        <slot />
        <Transition
          class="AnalyticsInsightAjaxContent__screen"
          name="fade"
          @leave="$store.state.isContentReady = true"
        >
          <div
            v-if="isLoading"
            class="AnalyticsInsightAjaxContent__screen_blurred"
            :style="{ width: screenWidth }"
          />
        </Transition>
      </div>
    </Transition>
    <div
      v-if="!isReady || isLoading"
      class="AnalyticsInsightAjaxContent__wrapper"
    >
      <AppSpinner class="AnalyticsInsightAjaxContent__spinner" />
      <div class="AnalyticsInsightAjaxContent__messages_container">
        <TransitionCrossfade :items="messages" :visible-index="messageIndex">
          <template #default="{ item }">
            <div class="AnalyticsInsightAjaxContent__message">
              {{ item }}
            </div>
          </template>
        </TransitionCrossfade>
      </div>
      <div class="AnalyticsInsightAjaxContent__tips_container">
        <TransitionCrossfade :items="tipMessages" :visible-index="tipIndex">
          <template #default="{ item }">
            <!-- eslint-disable vue/no-v-html -->
            <div class="AnalyticsInsightAjaxContent__tip" v-html="item" />
            <!-- eslint-enable vue/no-v-html -->
          </template>
        </TransitionCrossfade>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionCrossfade from '@/transitions/TransitionCrossfade';

const TIPS_COUNT = 4;

export default {
  name: 'AnalyticsInsightAjaxContent',
  components: { TransitionCrossfade },
  props: {
    isLoading: { type: Boolean, required: true },
    loadingScreenWidthFixed: { type: Boolean, default: false }
  },
  data() {
    return {
      isReady: !this.isLoading,
      screenWidth: 0,
      messageIndex: 0,
      messageTimer: null,
      tipIndex: 0,
      tipTimer: null
    };
  },
  computed: {
    messages() {
      return [...Array(2)].map((_, i) => this.$t(`messages.${i}`));
    },
    tipMessages() {
      return [...Array(TIPS_COUNT)].map((_, i) => this.$t(`tips.${i}`));
    }
  },
  watch: {
    isLoading() {
      if (this.isLoading) {
        this.setLoadingScreenWidth();
        this.$nextTick(() => {
          this.setMessagesTimer();
          this.setTipsTimer();
        });
      } else {
        this.isReady = true;
        clearTimeout(this.messageTimer);
        clearInterval(this.tipTimer);
      }
    }
  },
  created() {
    this.tipIndex = this.getRandomInt(TIPS_COUNT);
  },
  mounted() {
    if (this.isReady) this.$store.state.isContentReady = true;

    this.setMessagesTimer();
    this.setTipsTimer();
  },
  beforeDestroy() {
    clearTimeout(this.messageTimer);
    clearInterval(this.tipTimer);
  },
  methods: {
    setLoadingScreenWidth() {
      if (this.loadingScreenWidthFixed) {
        this.screenWidth = `${this.$el.offsetWidth + 32}px`;
      } else {
        this.$el.style.setProperty('display', 'inline-block');
        this.$el.style.setProperty('min-width', '100%');
        this.screenWidth = `${this.$el.offsetWidth}px`;
        this.$el.style.setProperty('display', '');
        this.$el.style.setProperty('min-width', '');
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    setMessagesTimer() {
      this.messageTimer = setTimeout(() => (this.messageIndex = 1), 10000);
    },
    setTipsTimer() {
      this.tipTimer = setInterval(
        () => (this.tipIndex = (this.tipIndex + 1) % TIPS_COUNT),
        5000
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/vars/_z-indexes.scss';

.AnalyticsInsightAjaxContent {
  position: relative;
}

.AnalyticsInsightAjaxContent__wrapper {
  text-align: center;
  position: absolute;
  top: 280px;
  height: 430px;
  width: 100%;
  z-index: $z-index-screen + 1;
}

.AnalyticsInsightAjaxContent__spinner {
  font-size: 30px;
}

.AnalyticsInsightAjaxContent__screen {
  right: auto;
}

.AnalyticsInsightAjaxContent__screen_blurred {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: $z-index-screen;
}

.AnalyticsInsightAjaxContent__messages_container {
  position: relative;
  margin-top: 12px;
}

.AnalyticsInsightAjaxContent__message {
  @include text-sub-navigation;
  font-weight: bold;
}

.AnalyticsInsightAjaxContent__tips_container {
  position: relative;
  margin-top: 20px;
}

.AnalyticsInsightAjaxContent__tip {
  @include text-caption-dark;
}
</style>

<i18n locale="ko">
{
  "messages": {
    "0": "데이터 분석 중입니다.",
    "1": "거의 완료되었습니다. 잠시만 기다려주세요."
  },
  "tips": {
    "0": "분석 데이터를 상품 기획, 마케팅, CRM 등 쇼핑몰 운영 전반에 활용하면<br>비즈니스를 더 효과적으로 성장시킬 수 있습니다.",
    "1": "로딩이 느린 경우, <b>상품 분석>상세검색>리뷰수</b> 범위를 조정해 분석할 상품 수를<br>줄여보세요. 최소 리뷰수를 기존보다 높게 설정하는 방법을 추천합니다.",
    "2": "제일 불만족한 고객에게서 가장 많은 것을 배울 수 있습니다.<br>부정 키워드에 유의하여 사업 전략과 개선방향을 고민해보세요.",
    "3": "데이터 조회 기간을 작게 설정해 분석할 데이터량을 조절하면<br>로딩 속도가 조금 더 개선될 수 있습니다."
  }
}
</i18n>
