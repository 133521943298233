<template>
  <div class="QaJpegSimulations">
    <AppAjaxContent :is-loading="isLoading">
      <AppSearchbar>
        <li>
          <AppButton
            label="Begin Simulation"
            button-style="red"
            @click="clickBeginSimulation"
          />
        </li>
      </AppSearchbar>
      <AppResourceTable
        :columns="COLUMNS"
        :rows="rows"
        :resources="jpeg_simulations"
        @paginate="paginate"
      >
        <template #cell="{ row, column }">
          <template v-if="column === 'actions'">
            <AppInternalLink :to="`/qa/jpeg-simulations/${row.id}`">
              <AppButton :label="$t('app.show')" />
            </AppInternalLink>
          </template>
        </template>
      </AppResourceTable>
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import JobStatus from '@/enums/JobStatus';
import ResourceView from '@/mixins/ResourceView';
import { nullResources } from '@/lib/resources';

export default {
  name: 'QaJpegSimulations',
  mixins: [ResourceView],
  data() {
    return { isLoading: true, jpeg_simulations: nullResources };
  },
  computed: {
    rows() {
      return this.jpeg_simulations.items.map(item => {
        return {
          id: item.id.toString(),
          timestamp: item.timestamp,
          jpeg_processor: item.jpeg_processor,
          job_status: JobStatus.key(item.job_status),
          total_duration: item.total_duration_ms / 1000,
          total_file_size: item.total_file_size,
          versions_count: item.versions_count
        };
      });
    }
  },
  created() {
    this.COLUMNS = [
      { id: 'id', label: 'ID', type: 'string' },
      { id: 'timestamp', label: 'Timestamp', type: 'string' },
      { id: 'jpeg_processor', label: 'Jpeg Processor', type: 'string' },
      { id: 'job_status', label: 'Status', type: 'string' },
      { id: 'versions_count', label: 'Versions Count', type: 'number' },
      { id: 'total_duration', label: 'Duration (sec)', type: 'number' },
      { id: 'total_file_size', label: 'Total Size (byte)', type: 'number' },
      { id: 'actions', label: 'Actions', type: 'custom' }
    ];
  },
  methods: {
    fetchResource(params) {
      return this.fetchJpegSimulations(params);
    },
    fetchJpegSimulations(params) {
      this.isLoading = true;
      return api
        .get('/qa/jpeg_simulations', { params })
        .then(({ data }) => (this.jpeg_simulations = data.jpeg_simulations))
        .finally(() => (this.isLoading = false));
    },
    clickBeginSimulation() {
      this.isLoading = true;
      return api
        .post('/qa/jpeg_simulations')
        .then(() => this.fetchFirstPage())
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>
