<template>
  <div>
    <div class="ui-section__title">default(left)</div>
    <div class="ui-section__item">
      <div class="UiBubble__target-component">
        <AppBubble>안내 메세지</AppBubble>
      </div>
    </div>
    <div class="ui-section__title">right</div>
    <div class="ui-section__item">
      <div class="UiBubble__target-component">
        <AppBubble side="right">안내 메세지</AppBubble>
      </div>
    </div>
    <div class="ui-section__title">top-right</div>
    <div class="ui-section__item">
      <div class="UiBubble__target-component">
        <AppBubble side="top-right">안내 메세지</AppBubble>
      </div>
    </div>
    <div class="ui-section__title">top-center</div>
    <div class="ui-section__item">
      <div class="UiBubble__target-component">
        <AppBubble side="top-center">안내 메세지</AppBubble>
      </div>
    </div>
    <div class="ui-section__title">bottom-right</div>
    <div class="ui-section__item">
      <div class="UiBubble__target-component">
        <AppBubble side="bottom-right">안내 메세지</AppBubble>
      </div>
    </div>
    <div class="ui-section__title">bottom-center</div>
    <div class="ui-section__item">
      <div class="UiBubble__target-component">
        <AppBubble side="bottom-center">안내 메세지</AppBubble>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'UiBubble' };
</script>

<style scoped>
.UiBubble__target-component {
  position: relative;
  width: 10px;
  height: 10px;
  border: 1px solid;
  border-radius: 5px;
  margin: 24px 0 24px 100px;
}
</style>
