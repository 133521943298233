import { constructEnum } from '@/lib/enum';

export default constructEnum('SmartstoreStatus', {
  NONE: 0,
  PRODUCT_MATCH_PENDING: 100,
  PRODUCT_MATCH_STARTED: 110,
  PRODUCT_MATCH_FAILED: 120,
  PRODUCT_MATCH_FINISHED: 130,
  ESTIMATION_QUEUED: 200,
  ESTIMATION_STARTED: 210,
  ESTIMATION_FAILED: 220,
  PAYMENT_PENDING: 300,
  PAYMENT_EXPIRED: 330,
  STARTER_IMPORT_QUEUED: 400,
  STARTER_IMPORT_STARTED: 410,
  STARTER_IMPORT_FAILED: 420,
  OPERATING: 10000,
  EXPIRED: 20000
});
