<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">icon by id</label>
      </div>
      <AppProductIcon v-if="productIconId" :icon-id="productIconId" />
      <div class="ui-section__description">
        <template v-if="productIconId"> id: {{ productIconId }} </template>
        <template v-else>
          {{ $t('no_product_icons') }}
        </template>
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">icon by url</label>
      </div>
      <AppProductIcon
        url="https://developer.pay.naver.com/static/img/logo_black.png"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UiProductIcon',
  computed: {
    ...mapState('productIcons', ['productIcons']),
    productIconId() {
      const icon = this.productIcons[0];
      return icon ? icon.id : null;
    }
  }
};
</script>

<i18n locale="ko">
{
  "no_product_icons": "상품 아이콘 데이터가 없습니다."
}
</i18n>
