import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import { arrayMoveIndexes } from '@/lib/array';
import api from '@/lib/api';

const state = {
  display: {},
  orgDisplay: {},
  markedProduct: {},
  cornerId: null,
  isLoadingProducts: true,
  isStatusUpdated: false,
  categoryProductIds: []
};

const getters = {
  cornerPreview: ({ display, cornerId }) => {
    return display.corners.find(({ id }) => id === cornerId);
  },
  cornerProductCategory: (
    _state,
    { cornerPreview },
    { productCategory, session },
    rootGetters
  ) => {
    if (rootGetters['displayProductDisplays/isCategoryPage']) {
      const cornerCategory = productCategory.productCategories.find(
        ({ code }) => code === cornerPreview.code
      );
      if (!cornerCategory) return null;

      if (session.currentBrand.shop_builder === 'makeshop')
        return {
          ...cornerCategory,
          hasChildCategory: productCategory.productCategories.some(
            category =>
              category.parent_category_id === cornerCategory.id &&
              !category.deleted_at
          )
        };
      else return cornerCategory;
    } else return null;
  },
  subCategoryDisplayCorners: (
    { display },
    _getters,
    { productCategory },
    rootGetters
  ) => {
    if (!rootGetters['displayProductDisplays/isCategoryPage']) return [];

    return display.corners.filter(
      corner =>
        productCategory.productCategories.find(
          ({ code }) => code === corner.code
        )?.sub_category_display
    );
  },
  isDisplayDataChanged: ({ display, orgDisplay }) => {
    return !_.isEqual(display, orgDisplay);
  },
  isCalculated: ({ display, orgDisplay }) => {
    return display.calculated_at !== orgDisplay.calculated_at;
  },
  isProductsDataChanged: ({ display, orgDisplay }) => {
    return !_.isEqual(display.products, orgDisplay.products);
  },
  isPinnedProduct: (_state, { cornerPreview }) => product => {
    return product.id in cornerPreview.pinned_product_ids_map;
  },
  isExcludingProduct: (_state, { cornerPreview }) => product => {
    return product.id in cornerPreview.excluding_product_ids_map;
  },
  pinnedProductIds: (_state, { cornerPreview }) => {
    return Object.keys(cornerPreview.pinned_product_ids_map || {});
  },
  excludingProductIds: (_state, { cornerPreview }) => {
    return Object.keys(cornerPreview.excluding_product_ids_map || {});
  },
  addedProductIds: (_state, { cornerPreview }) => {
    return cornerPreview.added_product_ids;
  },
  removedProductIds: (_state, { cornerPreview }) => {
    return cornerPreview.removed_product_ids;
  },
  productExcludeUntil: (_state, { cornerPreview }) => product => {
    return cornerPreview.excluding_product_ids_map[product.id];
  },
  productPinUntil: (_state, { cornerPreview }) => product => {
    return cornerPreview.pinned_product_ids_map[product.id].pin_until;
  },
  displayProductsFormData: ({ display, cornerId }) => {
    if (!cornerId) return null;

    const formData = new FormData();
    formData.append('product_display[product_display_corner_id]', cornerId);

    (display.products || []).forEach(product => {
      const keys = [
        'id',
        'color_index',
        'filter_type',
        'sort_type',
        'recent_days',
        'sort_recent_days',
        'product_option_type_id',
        'product_option_value_id'
      ];
      keys.forEach(key =>
        formData.append(`product_display[products][][${key}]`, product[key])
      );
    });

    return formData;
  },
  copyDisplayedProductsFormData: (_state, { cornerPreview }) => {
    if (!cornerPreview) return;

    const formData = new FormData();
    formData.append(
      'product_display[product_display_corner_id]',
      cornerPreview.id
    );

    const { excluding_product_ids_map, pinned_product_ids_map } = cornerPreview;
    for (const productId in excluding_product_ids_map) {
      formData.append('product_display[excluding_product_ids][]', productId);
      formData.append(
        'product_display[excluding_product_exclude_until][]',
        excluding_product_ids_map[productId]
      );
    }
    for (const productId in pinned_product_ids_map) {
      formData.append('product_display[pinned_product_ids][]', productId);
      formData.append(
        'product_display[pinned_product_pin_until][]',
        pinned_product_ids_map[productId].pin_until
      );
      formData.append(
        'product_display[pinned_product_positions][]',
        pinned_product_ids_map[productId].index
      );
    }

    return formData;
  },
  displayCornersFormData: (
    { display, cornerId },
    { isProductsDataChanged, isCalculated }
  ) => {
    const formData = new FormData();
    formData.append('product_display[corner_previews][]', []);

    display.corners.forEach(cornerPreview => {
      const {
        id,
        excluding_product_ids_map,
        pinned_product_ids_map,
        added_product_ids,
        removed_product_ids,
        isProductsChanged
      } = cornerPreview;

      formData.append('product_display[corner_previews][][id]', id);
      for (const productId in excluding_product_ids_map) {
        formData.append(
          'product_display[corner_previews][][excluding_product_ids][]',
          productId
        );
        formData.append(
          'product_display[corner_previews][][excluding_product_exclude_until][]',
          excluding_product_ids_map[productId]
        );
      }
      for (const productId in pinned_product_ids_map) {
        formData.append(
          'product_display[corner_previews][][pinned_product_ids][]',
          productId
        );
        formData.append(
          'product_display[corner_previews][][pinned_product_pin_until][]',
          pinned_product_ids_map[productId].pin_until
        );
        formData.append(
          'product_display[corner_previews][][pinned_product_positions][]',
          pinned_product_ids_map[productId].index
        );
      }
      for (const productId of added_product_ids)
        formData.append(
          'product_display[corner_previews][][added_product_ids][]',
          productId
        );
      for (const productId of removed_product_ids)
        formData.append(
          'product_display[corner_previews][][removed_product_ids][]',
          productId
        );

      const isManaging =
        isCalculated ||
        (id === cornerId ? isProductsDataChanged : isProductsChanged);
      formData.append(
        'product_display[corner_previews][][is_managing]',
        isManaging
      );
    });

    return formData;
  },
  displayFormData: (
    { display },
    { displayProductsFormData, displayCornersFormData }
  ) => {
    const formData = new FormData();
    formData.append('product_display[calculated_at]', display.calculated_at);

    displayProductsFormData.forEach((value, key) =>
      formData.append(key, value)
    );
    displayCornersFormData.forEach((value, key) => formData.append(key, value));

    return formData;
  }
};

const mutations = {
  SET_ORG_DISPLAY(state, display) {
    state.orgDisplay = _.cloneDeep(display);
    state.display = _.cloneDeep(display);
  },
  UPDATE_ORG_DISPLAY(state, data) {
    state.orgDisplay = { ...state.orgDisplay, ...data };
    state.display = { ...state.display, ...data };
  },
  UPDATE_ORG_DISPLAY_CORNER_DETAILS(
    { display, orgDisplay },
    displayCornerDetails
  ) {
    const displayCorners = orgDisplay.corners.map(corner => {
      const displayCornerDetail =
        displayCornerDetails.find(({ id }) => id === corner.id) || {};
      return { ...corner, ...displayCornerDetail, isProductsChanged: false };
    });

    orgDisplay.corners = _.cloneDeep(displayCorners);
    display.corners = _.cloneDeep(displayCorners);
  },
  UPDATE_DISPLAY_CORNER_DETAILS({ display }, displayCornerDetails) {
    display.corners = display.corners.map(corner => {
      const displayCornerDetail =
        displayCornerDetails.find(({ id }) => id === corner.id) || {};
      return { ...corner, ...displayCornerDetail };
    });
  },
  UPDATE_CATEGORY_PRODUCT_IDS(state, productIds) {
    state.categoryProductIds = productIds;
  },
  UPDATE_DISPLAY(state, data) {
    state.display = { ...state.display, ...data };
  },
  SWITCH_DISPLAY_CORNER(state, { id }) {
    state.cornerId = id;
  },
  MARK_DISPLAY_CORNER_PRODUCTS_CHANGED(
    { display, cornerId },
    isProductsChanged
  ) {
    if (!cornerId) return;

    display.corners = display.corners.map(corner => ({
      ...corner,
      isProductsChanged:
        cornerId === corner.id ? isProductsChanged : corner.isProductsChanged
    }));
  },
  UPDATE_PRODUCTS_COUNT({ display, cornerId }) {
    display.corners = display.corners.map(corner => ({
      ...corner,
      products_count:
        cornerId === corner.id ? display.products.length : corner.products_count
    }));
  },
  SORT_PRODUCTS(state, { srcIndices, dstIndex }) {
    const products = state.display.products.map((product, index) => {
      if (srcIndices.includes(index)) return { ...product, color_index: 0 };
      else return product;
    });

    const emptySlots = srcIndices.filter(index => index < dstIndex);
    state.display.products = arrayMoveIndexes(
      products,
      srcIndices,
      dstIndex - emptySlots.length
    );
  },
  ADD_PRODUCTS(state, { index, products }) {
    state.display.products.splice(
      index,
      0,
      ...products.map(product => ({ ...product, color_index: 0 }))
    );

    const cornerPreview = state.display.corners.find(
      ({ id }) => id === state.cornerId
    );
    products.forEach(product => {
      if (cornerPreview.removed_product_ids.includes(product.id))
        cornerPreview.removed_product_ids = cornerPreview.removed_product_ids.filter(
          id => id !== product.id
        );
      else if (!product.is_in_coner_product_category)
        cornerPreview.added_product_ids.push(product.id);
    });
  },
  REMOVE_PRODUCTS(state, productIds) {
    state.display.products = state.display.products.filter(
      product => !productIds.includes(product.id)
    );

    const cornerPreview = state.display.corners.find(
      ({ id }) => id === state.cornerId
    );
    productIds.forEach(productId => {
      if (cornerPreview.added_product_ids.includes(productId))
        cornerPreview.added_product_ids = cornerPreview.added_product_ids.filter(
          id => id !== productId
        );
      else cornerPreview.removed_product_ids.push(productId);
    });
  },
  EXCLUDE_PRODUCTS(state, { productIds, excludedUntil }) {
    const cornerPreview = state.display.corners.find(
      ({ id }) => id === state.cornerId
    );
    productIds.forEach(id =>
      Vue.set(cornerPreview.excluding_product_ids_map, id, excludedUntil)
    );
    state.display.products = state.display.products.filter(
      p => !productIds.includes(p.id)
    );
  },
  CANCEL_EXCLUDE_PRODUCTS(state, productIds) {
    const cornerPreview = state.display.corners.find(
      ({ id }) => id === state.cornerId
    );
    productIds.forEach(productId =>
      Vue.delete(cornerPreview.excluding_product_ids_map, productId)
    );
  },
  PIN_PRODUCTS(state, { products, pinUntil }) {
    const cornerPreview = state.display.corners.find(
      ({ id }) => id === state.cornerId
    );
    products.forEach(product =>
      Vue.set(cornerPreview.pinned_product_ids_map, product.id, {
        index: product.index,
        pin_until: pinUntil
      })
    );
  },
  UNPIN_PRODUCTS(state, products) {
    const cornerPreview = state.display.corners.find(
      ({ id }) => id === state.cornerId
    );
    products.forEach(product =>
      Vue.delete(cornerPreview.pinned_product_ids_map, product.id)
    );
  },
  REARRANGE_PRODUCTS({ display }) {
    const { pinned_product_ids_map } = display.corners.find(
      ({ id }) => id === state.cornerId
    );
    const pinnedIndexIds = _.chain(pinned_product_ids_map)
      .map(({ index }, id) => ({ index, id }))
      .sortBy('index')
      .value();
    const products = display.products.filter(
      product => !pinned_product_ids_map[product.id]
    );
    pinnedIndexIds.forEach(({ index, id }) => {
      const product = display.products.find(product => product.id == id);
      products.splice(index, 0, product);
    });

    display.products = products.map((product, index) => ({
      ...product,
      index
    }));
  },
  SET_MARKED_PRODUCT(state, markedProduct) {
    state.markedProduct = markedProduct;
  },
  CLEAR_MARKED_PRODUCT(state) {
    state.markedProduct = {};
  },
  UPDATE_IS_STATUS_UPDATED: (state, isStatusUpdated) =>
    (state.isStatusUpdated = isStatusUpdated)
};

const actions = {
  updateDisplayName({ state, commit }, name) {
    commit('UPDATE_ORG_DISPLAY', { name });
    commit('displayProductDisplays/SET_PRODUCT_DISPLAY_NAME', state.display, {
      root: true
    });
  },
  calculateProducts({ state, getters, dispatch }) {
    state.isLoadingProducts = true;

    const formData = getters.displayCornersFormData;
    formData.append('display_id', state.display.id);
    api
      .post('/display/product_display/calculation', formData)
      .then(() => dispatch('fetchCalculationResult'));
  },
  fetchCalculationResult({ state, commit }) {
    state.isLoadingProducts = true;

    api
      .get('/display/product_display/calculation/result', {
        params: {
          display_id: state.display.id,
          corner_id: state.cornerId
        }
      })
      .then(({ data }) => {
        commit('UPDATE_DISPLAY_CORNER_DETAILS', data.corner_previews);
        commit('UPDATE_DISPLAY', {
          calculated_at: moment().toISOString(),
          products: data.managing_products
        });
        commit('REARRANGE_PRODUCTS');
      })
      .finally(() => (state.isLoadingProducts = false));
  },
  switchProducts({ state, commit, getters }, prevProductsFormData) {
    state.isLoadingProducts = true;

    const { cornerPreview, isCalculated } = getters;
    const isManaging = cornerPreview.isProductsChanged || isCalculated;

    const formData = prevProductsFormData || new FormData();
    formData.append('next_product_display_corner_id', cornerPreview.id);
    formData.append('is_managing', isManaging);

    api
      .patch(
        `/display/product_displays/${state.display.id}/switch_products`,
        formData
      )
      .then(({ data }) => {
        commit('UPDATE_ORG_DISPLAY', { products: data.managed_products });
        commit('UPDATE_DISPLAY', { products: data.managing_products });
        commit('REARRANGE_PRODUCTS');
        commit('MARK_DISPLAY_CORNER_PRODUCTS_CHANGED', false);
        commit('UPDATE_CATEGORY_PRODUCT_IDS', data.category_product_ids);
      })
      .finally(() => (state.isLoadingProducts = false));
  },
  switchDisplayCorner({ commit, dispatch, getters }, nextDisplayCorner) {
    const { isProductsDataChanged, displayProductsFormData } = getters;
    commit('MARK_DISPLAY_CORNER_PRODUCTS_CHANGED', isProductsDataChanged);

    commit('SWITCH_DISPLAY_CORNER', nextDisplayCorner);
    if (!nextDisplayCorner.id) return;

    dispatch('switchProducts', displayProductsFormData);
  },
  copyDisplayedProducts({ state, getters, commit }) {
    state.isLoadingProducts = true;

    api
      .patch(
        `/display/product_displays/${state.display.id}/copy_displayed_products`,
        getters.copyDisplayedProductsFormData
      )
      .then(({ data }) => {
        commit('UPDATE_DISPLAY', { products: data.products });
        commit('REARRANGE_PRODUCTS');
        commit('UPDATE_PRODUCTS_COUNT');
        commit(
          'displayProducts/SET_DISPLAYED_PRODUCTS',
          data.displayed_products,
          {
            root: true
          }
        );
      })
      .finally(() => (state.isLoadingProducts = false));
  },
  updateDisplayCorners({ state, commit }, { corners, cornerDetails }) {
    commit('UPDATE_ORG_DISPLAY', { corners });
    commit('UPDATE_ORG_DISPLAY_CORNER_DETAILS', cornerDetails);
    commit(
      'displayProductDisplays/SET_PRODUCT_DISPLAY_CORNERS',
      state.display,
      {
        root: true
      }
    );
  },
  addProducts({ commit }, args) {
    commit('ADD_PRODUCTS', args);
    commit('REARRANGE_PRODUCTS');
    commit('UPDATE_PRODUCTS_COUNT');
  },
  removeProducts({ commit, getters, state }, indices) {
    const productIds = indices
      .map(index => {
        const product = state.display.products[index];
        if (!product || getters.isPinnedProduct(product)) return;

        return product.id;
      })
      .filter(id => id);

    commit('REMOVE_PRODUCTS', productIds);
    commit('REARRANGE_PRODUCTS');
    commit('UPDATE_PRODUCTS_COUNT');
  },
  excludeProducts({ commit }, args) {
    commit('EXCLUDE_PRODUCTS', args);
    commit('REARRANGE_PRODUCTS');
    commit('UPDATE_PRODUCTS_COUNT');
  },
  sortProducts({ commit }, args) {
    commit('SORT_PRODUCTS', args);
    commit('REARRANGE_PRODUCTS');
  },
  clearMarkedProduct({ commit, state }, index) {
    if (index === state.markedProduct.index) {
      commit('CLEAR_MARKED_PRODUCT');
    }
  }
};

export default { namespaced: true, state, getters, mutations, actions };
