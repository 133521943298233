<template>
  <div class="AppSingleLineFields">
    <slot />
  </div>
</template>

<script>
export default { name: 'AppSingleLineFields' };
</script>

<style lang="scss" scoped>
.AppSingleLineFields {
  display: flex;
  column-gap: 8px;
}
</style>
