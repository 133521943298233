<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title', [formatUser(review)])"
    :form-props="{
      ...formProps,
      focusGroupId: 'mileage_amount_cents',
      submitLabel: $t('submit_label'),
      submittingLabel: $t('submitting_label')
    }"
    v-on="formEvents"
  >
    <template #group="{ id }">
      <template v-if="id === 'mileage_amount_cents'">
        <div class="AppForm__group-field">
          <i18n path="mileage_amount_cents.info">
            <AppCurrencyInput
              v-if="isReviewPointTypeMileage"
              v-model="formObject.mileage_amount_cents"
              :digits="6"
              @blur="validateField(id)"
              @change="validateField(id)"
            />
            <AppNumberInput
              v-else
              v-model="formObject.mileage_amount_cents"
              :digits="6"
              @blur="validateField(id)"
              @change="validateField(id)"
            />
            <span>{{ $t('mileage_amount_cents.total_amount_label') }}</span>
            <span
              class="ReviewReviewsMileageDeductDialog__total_mileage_amount"
              >{{
                review.mileage.gave_mileage_amount | formatCurrencyWithoutUnit
              }}</span
            >
            <span>{{ reviewPointTypeUnit }}</span>
          </i18n>
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReviewPointType from '@/enums/ReviewPointType';
import DialogFormView from '@/mixins/DialogFormView';
import ReviewPointTypeHelper from '@/mixins/ReviewPointTypeHelper';
import ReviewMileageMessageValidationHelper from '@/mixins/ReviewMileageMessageValidationHelper';

export default {
  name: 'ReviewReviewsMileageDeductDialog',
  mixins: [
    DialogFormView,
    ReviewPointTypeHelper,
    ReviewMileageMessageValidationHelper
  ],
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  data() {
    const review = this.review;
    return {
      orgFormObject: {
        mileage_amount_cents: review.mileage.gave_mileage_amount,
        mileage_message: this.$t('deduct_mileage_default_message')
      },
      detectFormDataChange: false
    };
  },
  computed: {
    ...mapGetters('session', ['currencyKey', 'pointTypeText']),
    ...mapGetters('session', ['isShopifyBrand']),
    ReviewPointType() {
      return ReviewPointType;
    },
    formSections() {
      let groups = [
        {
          id: 'mileage_amount_cents',
          label: this.$t('mileage_amount_cents.label'),
          validate: [
            'required',
            {
              rule: v => v <= this.review.mileage.gave_mileage_amount,
              errorMessage: this.$t('mileage_amount_cents.error')
            }
          ]
        },
        {
          id: 'mileage_message',
          type: 'text',
          label: this.$t('mileage_message'),
          validate: ['required', this.mileageMessageBytesValidation].filter(
            v => v
          )
        }
      ];

      if (this.isShopifyBrand) {
        groups = groups.filter(g => g.id !== 'mileage_amount_cents');
      }

      return [
        {
          groups: groups
        }
      ];
    },
    isReviewPointTypeMileage() {
      return ReviewPointType.isCurrencyType(
        this.reviewSettings.review_point_type
      );
    },
    reviewPointTypeUnit() {
      if (this.isReviewPointTypeMileage) return this.$t(this.currencyKey);

      return this.pointTypeText;
    }
  },
  methods: {
    ...mapActions('review', ['cancelMileageReview']),
    submit() {
      this.isSubmitting = true;
      this.cancelMileageReview({
        reviewId: this.review.id,
        params: this.formObject,
        errorMessage: this.$t('deduct_failed', {
          user: this.formatUser(this.review),
          customer_support: this.$t('app.customer_support')
        })
      })
        .then(({ data }) => {
          alert(
            this.$t('deduct_success', {
              user: this.formatUser(this.review),
              amount: this.formatCurrency(data.mileage_amount)
            })
          );
          this.close();
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
<style lang="scss">
@import '@/scss/mixins/_texts.scss';

.ReviewReviewsMileageDeductDialog__total_mileage_amount {
  color: $color-red;
}
</style>

<i18n locale="ko">
{
  "title": "{point_type} 차감",
  "sub_title": "{0}님의 리뷰",
  "mileage_amount_cents": {
    "label": "차감 금액",
    "error": "{point_type} 차감액이 지급된 금액보다 큽니다. 더 낮은 금액으로 입력해주세요.",
    "info": "{0} ({1}: {2}{3})",
    "total_amount_label": "최종 지급 {point_type}"
  },
  "mileage_message": "{point_type} 차감 사유",
  "deduct_mileage_default_message": "잘못 지급된 {point_type}",
  "submit_label": "{point_type} 차감",
  "submitting_label": "{point_type} 차감 중..",
  "deduct_failed": "{user}에게 지급했던 {point_type}((을)) 취소하지 못했습니다.\n\n부운영자 계정을 확인해주세요. 계정이 정상적으로 입력되어 있는데도 계속 문제가 발생하면 다음 연락처로 문의해주세요:\n{customer_support}",
  "deduct_success":"{user}에게 지급했던 {point_type}에서 {amount}을 차감하였습니다."
}
</i18n>
