import { constructEnum } from '@/lib/enum';

const InstallationPresetAttachType = constructEnum(
  'InstallationPresetAttachType',
  {
    REPLACE: 0,
    REPLACE_AND_APPEND: 10,
    APPEND: 20
  }
);

export default InstallationPresetAttachType;
