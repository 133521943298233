<template>
  <div v-if="alert" :class="['TheMainAlert', `TheMainAlert--${alert.color}`]">
    <span class="TheMainAlert__message">{{
      $t(`${alert.type}.message`, alert.messageArgs)
    }}</span>
    <AppButton
      v-if="alert.clickHandler || alert.path"
      class="TheMainAlert__button"
      :type="alert.buttonType"
      button-style="black-opaque"
      size="small"
      :to="alert.path"
      :label="$t(`${alert.type}.button`)"
      @click="clickAlert(alert, $event)"
    />
    <button
      v-if="alert.closable"
      v-tooltip="$t('close_button_tooltip')"
      class="TheMainAlert__close-button"
      @click="closeAndSnooze(alert)"
    >
      <AppSvgIcon name="icon-close" />
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheMainAlert',
  computed: {
    ...mapGetters('alert', ['mainAlert']),
    alert() {
      if (!this.mainAlert) return;

      const ALERT_DEFINITIONS = {
        unsupported_browser: {
          path: 'https://www.google.com/intl/ko/chrome/',
          buttonType: 'external_link',
          color: 'grey'
        },
        review_expired: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'ThePaymentDialog',
              { activeTabId: 'review_packages' }
            ])
        },
        review_sms_depleted: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'ThePaymentDialog',
              { activeTabId: 'review_sms_packages' }
            ])
        },
        target_expired: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'ThePaymentDialog',
              { activeTabId: 'target_packages' }
            ])
        },
        target_expired_custom_billing_plan: { color: 'red' },
        credit_depleted: {
          color: 'red',
          closable: true,
          clickHandler: () =>
            this.openDialog([
              'ThePaymentDialog',
              { activeTabId: 'credit_packages' }
            ])
        },
        fit_expired: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'ThePaymentDialog',
              { activeTabId: 'fit_packages' }
            ])
        },
        display_expired: { color: 'red' },
        channel_expired: { color: 'red' },
        insight_expired: { color: 'red' },
        services_expired: {
          color: 'red',
          clickHandler: () => this.openDialog('ThePaymentDialog')
        },
        biz_message_campaign_stopped: {
          color: 'red',
          path: '/review/messages/biz_messages/settings',
          buttonType: 'link'
        },
        corporate_material_required: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'TheSettingsDialog',
              { initialTab: 'settings_dialog_material' }
            ])
        },
        phone_material_required: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'TheSettingsDialog',
              { initialTab: 'settings_dialog_material' }
            ])
        },
        biz_message_profile_required: {
          color: 'red',
          path: '/review/messages/biz_messages/settings',
          buttonType: 'link'
        },
        corporate_material_and_phone_material_required: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'TheSettingsDialog',
              { initialTab: 'settings_dialog_material' }
            ])
        },
        corporate_material_and_biz_message_profile_required: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'TheSettingsDialog',
              { initialTab: 'settings_dialog_material' }
            ])
        },
        phone_material_and_biz_message_profile_required: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'TheSettingsDialog',
              { initialTab: 'settings_dialog_material' }
            ])
        },
        corporate_material_and_phone_material_and_biz_message_profile_required: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'TheSettingsDialog',
              { initialTab: 'settings_dialog_material' }
            ])
        },
        corporate_material_rejected: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'TheSettingsDialog',
              { initialTab: 'settings_dialog_material' }
            ])
        },
        phone_material_rejected: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'TheSettingsDialog',
              { initialTab: 'settings_dialog_material' }
            ])
        },
        materials_rejected: {
          color: 'red',
          clickHandler: () =>
            this.openDialog([
              'TheSettingsDialog',
              { initialTab: 'settings_dialog_material' }
            ])
        },
        imweb_api_key_invalid: {
          color: 'red',
          clickHandler: () => this.openDialog(['ImwebApiKeyFormDialog'])
        }
      };
      return {
        ...this.mainAlert,
        ...ALERT_DEFINITIONS[this.mainAlert.type]
      };
    }
  },
  methods: {
    ...mapActions('alert', ['snoozeAlert']),
    ...mapActions('dialog', ['openDialog']),
    closeAndSnooze({ type }) {
      this.snoozeAlert(type);
    },
    clickAlert({ clickHandler }, e) {
      if (clickHandler) clickHandler(e);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_layouts.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_transitions.scss';

.TheMainAlert {
  line-height: 46px;
  height: $main-alert-height;
  text-align: center;

  &--grey {
    background: $color-grey-25;
  }

  &--red {
    color: white;
    background: $color-red;
  }
}

.TheMainAlert__message {
  vertical-align: middle;
}

.TheMainAlert__button {
  margin-left: 12px;
}

.TheMainAlert__close-button {
  @include transition-basic(opacity);

  position: absolute;
  top: 4px;
  right: 8px;
  width: 40px;
  height: 40px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}
</style>

<i18n locale="ko">
{
  "unsupported_browser": {
    "message": "크리마 관리자 페이지는 크롬 브라우저에 최적화되어있습니다.",
    "button": "크롬 브라우저 다운로드"
  },
  "review_expired": {
    "message": "리뷰 서비스 사용이 만료되었습니다.",
    "button": "리뷰 결제"
  },
  "target_expired": {
    "message": "타겟 서비스 사용이 만료되었습니다.",
    "button": "타겟 결제"
  },
  "target_expired_custom_billing_plan": {
    "message": "타겟 서비스 사용이 만료되었습니다. 재결제는 타겟 운영팀으로 문의해 주세요."
  },
  "review_sms_depleted": {
    "message": "리뷰 문자(알림톡)가 모두 소진되었습니다.",
    "button": "리뷰 문자(알림톡) 충전"
  },
  "credit_depleted": {
    "message": "크레딧이 모두 소진되어 타겟 발송이 중지되었습니다.",
    "button": "크레딧 충전"
  },
  "fit_expired": {
    "message": "핏 서비스 사용이 만료되었습니다.",
    "button": "핏 결제"
  },
  "display_expired": {
    "message": "진열 서비스 사용이 만료되었습니다."
  },
  "channel_expired": {
    "message": "애드 서비스 사용이 만료되었습니다."
  },
  "insight_expired": {
    "message": "인사이트 서비스 사용이 만료되었습니다."
  },
  "services_expired": {
    "message": "{services} 서비스 사용이 만료되었습니다.",
    "button": "서비스 결제"
  },
  "corporate_material_required": {
    "message": "세금계산서 발급 자료를 등록해주세요.",
    "button": "자료 등록하러 가기"
  },
  "phone_material_required": {
    "message": "통신서비스 이용증명원(가입증명서) 자료를 등록해주세요.",
    "button": "등록하러 가기"
  },
  "biz_message_profile_required": {
    "message": "알림톡 발송을 위해 발신 프로필을 등록해주세요.",
    "button": "등록하러 가기"
  },
  "corporate_material_and_phone_material_required": {
    "message": "세금계산서 발급 자료 및 통신서비스 이용증명원(가입증명서) 자료를 등록해주세요.",
    "button": "등록하러 가기"
  },
  "corporate_material_and_biz_message_profile_required": {
    "message": "세금계산서 발급 자료 및 알림톡 발신 프로필을 등록해주세요.",
    "button": "등록하러 가기"
  },
  "phone_material_and_biz_message_profile_required": {
    "message": "통신서비스 이용증명원 및 알림톡 발신 프로필을 등록해주세요.",
    "button": "등록하러 가기"
  },
  "corporate_material_and_phone_material_and_biz_message_profile_required": {
    "message": "세금계산서 발급 자료와 통신서비스 이용증명원 및 알림톡 발신 프로필을 등록해주세요.",
    "button": "등록하러 가기"
  },
  "biz_message_campaign_stopped": {
    "message": "설정하신 알림톡 템플릿이 사용 중단 처리되어 알림톡 발송이 불가합니다. 다른 템플릿으로 변경해주세요.",
    "button": "변경하러 가기"
  },
  "corporate_material_rejected": {
    "message": "세금계산서 발급 자료를 다시 등록해주세요.",
    "button": "자료 등록하러 가기"
  },
  "phone_material_rejected": {
    "message": "통신서비스 이용증명원(가입증명서) 자료를 다시 등록해주세요.",
    "button": "자료 등록하러 가기"
  },
  "materials_rejected": {
    "message": "세금계산서 발급 자료 및 통신서비스 이용증명원(가입증명서) 자료를 다시 등록해주세요.",
    "button": "자료 등록하러 가기"
  },
  "imweb_api_key_invalid": {
    "message": "등록된 아임웹 Api Key와 Secret Key가 유효하지 않습니다.",
    "button": "Api Key 입력"
  },
  "close_button_tooltip": "오늘 하루 보지 않기"
}
</i18n>
