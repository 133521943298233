<template>
  <AppModalForm
    :title="$t('settings.fit_settings_widgets.groups.floating_settings')"
    :form-props="{ ...formProps, id: 'floating_settings' }"
    :width="modalWidth"
    v-on="formEvents"
  >
    <template #head>
      <AppTabs v-model="activeTab" :tabs="tabOptions" />
    </template>
    <template #group="{ id, errors }">
      <div v-if="id === floatingOffsetId">
        <div
          v-for="position in ['right', 'bottom']"
          :key="`offset_${position}`"
          class="AppForm__group-field"
        >
          <i18n :path="`groups.offset.${position}`">
            <AppNumberInput
              v-model="formObject[floatingOffsetPositionId(position)]"
              :digits="2"
              :name="`fit_settings[${floatingOffsetPositionId(position)}]`"
              @change="validateField(floatingOffsetPositionId(position))"
            />
          </i18n>
          <AppFormError :error="errors[floatingOffsetPositionId(position)]" />
        </div>
      </div>
      <div v-else-if="id === floatingShapeId">
        <AppSelectRadio
          v-model="formObject[floatingShapeId]"
          :options="FLOATING_SHAPE_OPTIONS"
          :name="`fit_settings[${floatingShapeId}]`"
          class="AppForm__group-field"
        >
          <template #sub-item="{ value, checked }">
            <div
              v-if="value === FitFloatingWidgetShape.SQUARE"
              class="AppForm__sub-group"
            >
              <div class="AppForm__sub-group-field">
                <i18n path="groups.shape.radius">
                  <AppNumberInput
                    v-model="formObject[floatingShapeRadiusId]"
                    :name="`fit_settings[${floatingShapeRadiusId}]`"
                    :digits="2"
                    :disabled="!checked"
                    @change="validateField(floatingShapeRadiusId)"
                  />
                </i18n>
                <AppFormError :error="errors[floatingShapeRadiusId]" />
              </div>
            </div>
          </template>
        </AppSelectRadio>
      </div>
      <div v-else-if="id === floatingIconTypeId">
        <AppSelectRadio
          v-model="formObject[floatingIconTypeId]"
          :options="FLOATING_ICON_TYPE_OPTIONS"
          :name="`fit_settings[${floatingIconTypeId}]`"
          class="AppForm__group-field"
        >
          <template #sub-item="{ value, checked }">
            <div v-if="value === FitFloatingIconType.CUSTOM">
              <div class="AppForm__sub-group-field">
                <AppTextInput
                  v-model="formObject[floatingIconUrlId]"
                  :name="`fit_settings[${floatingIconUrlId}]`"
                  :disabled="!checked"
                  :placeholder="$t('groups.icon_type.placeholder')"
                  @change="validateField(floatingIconUrlId)"
                />
                <AppFormHint
                  :message="$t('groups.icon_type.hint')"
                  :disabled="!checked"
                />
                <AppFormError :error="errors[floatingIconUrlId]" />
              </div>
            </div>
          </template>
        </AppSelectRadio>
      </div>
    </template>
  </AppModalForm>
</template>

<script>
import _ from 'lodash';
import DialogFormView from '@/mixins/DialogFormView';
import { mapActions } from 'vuex';
import api from '@/lib/api';
import FitFloatingWidgetShape from '@/enums/FitFloatingWidgetShape';
import FitFloatingIconType from '@/enums/FitFloatingIconType';

const FETCH_FIT_SETTINGS_REQUEST_ID = 'FETCH_FIT_SETTINGS';

export default {
  name: 'FitFloatingStyleSettingsDialog',
  mixins: [DialogFormView],
  data() {
    return {
      modalWidth: 'middle',
      activeTab: 'pc',
      orgFormObject: null,
      FLOATING_SHAPE_OPTIONS: [
        {
          label: this.$t('groups.shape.circle'),
          value: FitFloatingWidgetShape.CIRCLE
        },
        {
          label: this.$t('groups.shape.square'),
          value: FitFloatingWidgetShape.SQUARE
        }
      ],
      FLOATING_SHADOW_OPTIONS: [
        {
          label: this.$t('groups.shadow.true'),
          value: true
        },
        {
          label: this.$t('groups.shadow.false'),
          value: false
        }
      ],
      FLOATING_ICON_TYPE_OPTIONS: [
        {
          label: this.$t('groups.icon_type.default'),
          value: FitFloatingIconType.DEFAULT
        },
        {
          label: this.$t('groups.icon_type.custom'),
          value: FitFloatingIconType.CUSTOM
        }
      ]
    };
  },
  computed: {
    tabOptions() {
      return ['pc', 'mobile'].map(id => ({
        id,
        label: id.toUpperCase()
      }));
    },
    pcTab() {
      return [this.commonFloatingWidgetSettings];
    },
    mobileTab() {
      return [this.commonFloatingWidgetSettings];
    },
    formSections() {
      return this.isPc ? this.pcTab : this.mobileTab;
    },
    FitFloatingWidgetShape() {
      return FitFloatingWidgetShape;
    },
    FitFloatingIconType() {
      return FitFloatingIconType;
    },
    isPc() {
      return this.activeTab === 'pc';
    },
    floatingOffsetId() {
      return this.isPc ? 'floating_offset_pc' : 'floating_offset_mobile';
    },
    floatingShapeId() {
      return this.isPc ? 'floating_shape_pc' : 'floating_shape_mobile';
    },
    floatingShapeRadiusId() {
      return this.isPc
        ? 'floating_shape_radius_pc'
        : 'floating_shape_radius_mobile';
    },
    floatingShadowId() {
      return this.isPc ? 'floating_shadow_pc' : 'floating_shadow_mobile';
    },
    floatingFontAndIconColorId() {
      return this.isPc
        ? 'floating_font_and_icon_color_pc'
        : 'floating_font_and_icon_color_mobile';
    },
    floatingBackgroundColorId() {
      return this.isPc
        ? 'floating_background_color_pc'
        : 'floating_background_color_mobile';
    },
    floatingFontSize() {
      return this.isPc ? 'floating_font_size_pc' : 'floating_font_size_mobile';
    },
    floatingIconTypeId() {
      return this.isPc ? 'floating_icon_type_pc' : 'floating_icon_type_mobile';
    },
    floatingIconUrlId() {
      return this.isPc ? 'floating_icon_url_pc' : 'floating_icon_url_mobile';
    },
    commonFloatingWidgetSettings() {
      return {
        id: 'common_settings',
        groups: [
          {
            id: this.floatingFontSize,
            type: 'number',
            digits: 2,
            label: this.$t('groups.font_size.title'),
            name: `fit_settings[${this.floatingFontSize}]`,
            value: this.formObject[this.floatingFontSize],
            validate: ['required'],
            i18n: 'fit_settings.floating_widget.font_size',
            hint: this.$t('groups.font_size.hint')
          },
          {
            id: this.floatingIconTypeId,
            label: this.$t('groups.icon_type.title'),
            fields: [
              {
                id: this.floatingIconUrlId,
                validate: [
                  ...(this.formObject[this.floatingIconTypeId] ===
                  FitFloatingIconType.CUSTOM
                    ? ['required', { rule: 'url_format' }]
                    : [])
                ]
              }
            ]
          },
          {
            id: this.floatingOffsetId,
            label: this.$t('groups.offset.title'),
            hint: this.$t('groups.offset.hint'),
            fields: [
              {
                id: this.floatingOffsetPositionId('right'),
                validate: ['required']
              },
              {
                id: this.floatingOffsetPositionId('bottom'),
                validate: ['required']
              }
            ]
          },
          {
            id: this.floatingShapeId,
            label: this.$t('groups.shape.title'),
            fields: [
              {
                id: this.floatingShapeRadiusId,
                validate: [
                  ...(this.formObject[this.floatingShapeId] ===
                  FitFloatingWidgetShape.SQUARE
                    ? ['required']
                    : [])
                ]
              }
            ]
          },
          {
            id: this.floatingShadowId,
            type: 'select_radio',
            label: this.$t('groups.shadow.title'),
            options: this.FLOATING_SHADOW_OPTIONS,
            name: `fit_settings[${this.floatingShadowId}]`
          },
          {
            id: this.floatingFontAndIconColorId,
            type: 'color',
            label: this.$t('groups.font_and_icon_color.title'),
            name: `fit_settings[${this.floatingFontAndIconColorId}]`
          },
          {
            id: this.floatingBackgroundColorId,
            type: 'color',
            label: this.$t('groups.background_color.title'),
            name: `fit_settings[${this.floatingBackgroundColorId}]`
          }
        ]
      };
    }
  },
  mounted() {
    api
      .get('/fit/settings/widgets', {
        requestId: FETCH_FIT_SETTINGS_REQUEST_ID
      })
      .then(({ data }) => {
        this.orgFormObject = _.cloneDeep(data.fit_settings);
      });
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    submit() {
      this.isSubmitting = true;
      api
        .patch(
          '/fit/settings',
          { fit_settings: this.formObject },
          {
            successMessage: this.$t('app.saved')
          }
        )
        .then(() => {
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    },
    floatingOffsetPositionId(position) {
      const postfix = `${position}_${this.activeTab}`;
      switch (postfix) {
        case 'right_pc':
          return 'floating_offset_right_pc';
        case 'right_mobile':
          return 'floating_offset_right_mobile';
        case 'bottom_pc':
          return 'floating_offset_bottom_pc';
        case 'bottom_mobile':
          return 'floating_offset_bottom_mobile';
      }
    }
  }
};
</script>

<i18n locale="ko">
  {
    "groups": {
      "offset": {
        "title": "위치",
        "right": "오른쪽 {0} px",
        "bottom": "아래 {0} px",
        "hint": "브라우저 화면의 오른쪽에서부터, 아래로부터 지정된 간격을 띄워 배치합니다."
      },
      "shape": {
        "title": "모양",
        "circle": "원형",
        "square": "사각형",
        "radius": "모서리 라운드 {0} px"
      },
      "shadow": {
        "title": "그림자",
        "true": "그림자 있음",
        "false": "그림자 없음"
      },
      "font_and_icon_color": {
        "title": "위젯 글씨색과 아이콘색"
      },
      "background_color": {
        "title": "위젯 배경색"
      },
      "font_size": {
        "title": "폰트 크기",
        "hint": "폰트 크기가 바뀌면 위젯과 아이콘의 크기도 같이 변합니다."
      },
      "icon_type": {
        "title": "아이콘",
        "default": "기본 제공 (카테고리에 따라 아이콘이 자동으로 변경됩니다)",
        "custom": "이미지 업로드",
        "placeholder": "이미지 주소를 입력해주세요.",
        "hint": "쇼핑몰 주소가 https://로 시작하는 경우에는 이미지 URL도 https://로 시작되게 입력해주세요.\n 이미지는 가로 32px, 세로 32px 사이즈의 png 또는 gif 이미지를 권장합니다."
      },
      "icon_size": {
        "title": "아이콘 크기",
        "hint": "아이콘은 동일한 가로, 세로 길이를 사용합니다."
      }
    },
    "floating_widget_type_manual": {
      "title": "플로팅 위젯 안내",
      "content": "플로팅 위젯은 상품 상세 페이지 진입 시 확장형으로 나타났다가, 기본형으로 변경됩니다.\n\n<br/>![](https://assets.cre.ma/m/fit/assets/floating_widget_detail.png)"
    }
  }
  </i18n>
