<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{ ...formProps, errorSummary }"
    width="wide"
    v-on="formEvents"
  >
    <AppInfoBox
      class="DisplayProductDisplaySelectCategoryCornersDialog__info"
      >{{ $t('info') }}</AppInfoBox
    >
    <AppSearchbar @submit="search">
      <template #right>
        <AppSearchWithType
          v-model="searchWithType"
          :search-types="searchTypes"
        />
      </template>
    </AppSearchbar>
    <AppSelectResourceTable
      v-model="formObject.selected_category_ids"
      table-id="category-table"
      table-style="depth"
      :columns="columns"
      :rows="rows"
      :resources="resources"
      :filter-options="categoryFilterOptions"
      :filter-values="categoryFilterValues"
      :selected-item-column-label="$t('selected_item')"
      @change-filter-values="categoryFilterValues = $event"
      @paginate="pagination = $event"
    >
      <template #cell="{ column, row }">
        <template v-if="column === 'property'">
          <AppBadge
            v-if="row.deleted_at"
            badge-style="red-outline"
            :label="$t('product_category.deleted')"
            :tooltip="$t('product_category.deleted_tooltip')"
          />
          <AppBadge
            v-if="row.virtual"
            badge-style="grey-outline"
            :label="$t('product_category.virtual')"
          />
          <AppBadge
            v-if="row.sub_category_display"
            badge-style="grey-outline"
            :label="$t('product_category.sub_category_display')"
            :tooltip="{
              message: $t('product_category.sub_category_display_tooltip'),
              textAlign: 'left'
            }"
          />
        </template>
      </template>
    </AppSelectResourceTable>
  </AppModalForm>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { filterItems } from '@/lib/text-search';
import ProductCategoryFilterType from '@/enums/ProductCategoryFilterType';
import DialogFormView from '@/mixins/DialogFormView';
import DisplayCategoriesTableView from '@/mixins/DisplayCategoriesTableView';

export default {
  name: 'DisplayProductDisplaySelectCategoryCornersDialog',
  mixins: [DialogFormView, DisplayCategoriesTableView],
  props: { selectedCategoryCodes: { type: Array, default: () => [] } },
  data() {
    return {
      orgFormObject: { selected_category_ids: [] },
      categoryFilterOptions: ProductCategoryFilterType.options(),
      pagination: { page: 1, per: 20 }
    };
  },
  computed: {
    ...mapState('session', ['displaySettings']),
    ...mapGetters('productCategory', ['productCategoriesWithTreeName']),
    resources() {
      return {
        ...this.pagination,
        total_count: this.filteredCategories.length
      };
    },
    totalCategories() {
      return this.productCategoriesWithTreeName
        .filter(({ id }) =>
          this.displaySettings.enabled_category_ids.includes(id)
        )
        .map(category => ({
          ...category,
          full_name: category.treeNames.join(' ')
        }));
    },
    columnsWidth() {
      return {
        property: '144px',
        code: '82px',
        main_category_name: '162px',
        middle_category_name: '162px',
        sub_category_name: '162px',
        products_count: '102px'
      };
    },
    items() {
      const { per, page } = this.pagination;

      const indexStart = per * (page - 1);
      const indexEnd = per * page;

      return this.filteredCategories.slice(indexStart, indexEnd);
    }
  },
  watch: {
    'formObject.selected_category_ids'() {
      this.errorSummary = this.formObject.selected_category_ids.length
        ? null
        : this.$t('select_categories');
    },
    categories() {
      this.pagination = { ...this.pagination, page: 1 };
    }
  },
  mounted() {
    this.orgFormObject = {
      selected_category_ids: this.productCategoriesWithTreeName
        .filter(({ code }) => this.selectedCategoryCodes.includes(code))
        .map(({ id }) => id)
        .sort((a, b) => a - b)
    };
    this.categories = this.totalCategories;
  },
  methods: {
    search() {
      const { search_type, search_query } = this.searchWithType;
      this.categories = filterItems(
        this.totalCategories,
        search_type,
        search_query
      );
    },
    submit() {
      const selectedCategoryCodes = this.productCategoriesWithTreeName
        .filter(({ id }) => this.formObject.selected_category_ids.includes(id))
        .map(({ code }) => code);

      this.eventBus.$emit('submit', selectedCategoryCodes);
    }
  }
};
</script>

<style scoped>
.DisplayProductDisplaySelectCategoryCornersDialog__info {
  margin-bottom: 32px;
}
</style>

<i18n locale="ko">
{
  "title": "진열 대상 카테고리 선택",
  "info": "해당 진열설정을 통해 진열할 카테고리를 선택해 주세요.",
  "search_type": {
    "full_name": "카테고리 명",
    "code": "카테고리 코드"
  },
  "selected_item": "진열 대상",
  "select_categories": "최소 1개 이상의 진열 대상 카테고리를 선택하여야 합니다."
}
</i18n>
