<template>
  <div>
    <AppInfoBox
      v-if="isInfoboxVisible"
      class="ReviewSettingsWidgets__info-box super-admin__item"
    >
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="$t('info_box.review_settings_html')" />
      <!-- eslint-enable vue/no-v-html -->
      <a
        class="ReviewSettingsWidgets__info-box-link"
        @click="initialSetupClicked()"
      >
        {{ $t('info_box.review_settings_cta') }}
      </a>
    </AppInfoBox>
    <AppTabContent menu-item-id="review_settings_widgets" />
    <AppSummaryBox
      v-if="isInstallRequestRenewalUsable"
      class="ReviewSettingsWidgets__install-request-renewal"
    >
      <template #title>{{ $t('summary_box.title') }}</template>
      <pre>{{ $t('summary_box.content') }}</pre>
      <template #buttons>
        <AppButton
          button-style="blue-outline"
          type="external_link"
          :label="$t('summary_box.button_label')"
          @click="openTheInstallRequestRenewalDialog"
        />
      </template>
    </AppSummaryBox>
  </div>
</template>

<script>
import api from '@/lib/api';
import { mapGetters, mapActions } from 'vuex';
import InquiryHelper from '@/mixins/InquiryHelper';

export default {
  name: 'ReviewSettingsWidgets',
  mixins: [InquiryHelper],
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('session', [
      'isSmartInstallationSupported',
      'isCurrentUserInstallationAgency',
      'isWidgetInitialInstallationUsable',
      'isInstallRequestRenewalUsable'
    ]),
    isInfoboxVisible() {
      if (!this.isSmartInstallationSupported) return false;
      if (this.isSuperAdminAccessible) return true;

      return (
        this.isCurrentUserInstallationAgency &&
        this.isWidgetInitialInstallationUsable
      );
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog', 'confirm']),
    initialSetupClicked() {
      this.openDialog(['ReviewSettingsInitialSetupDialog']);
    },
    confirmStoreScopeError() {
      this.confirm({
        title: this.$t('summary_box.confirm_dialog.store_scope_error.title'),
        message: this.$t(
          'summary_box.confirm_dialog.store_scope_error.content'
        ),
        closeButtonLabel: this.$t(
          'summary_box.confirm_dialog.store_scope_error.close_button'
        )
      }).then(result => {
        if (result) window.open('https://eclogin.cafe24.com/Shop/');
      });
    },
    confirmUnexpectedError() {
      this.confirm({
        title: this.$t('summary_box.confirm_dialog.unexpected_error.title'),
        message: this.$t('summary_box.confirm_dialog.unexpected_error.content'),
        closeButtonLabel: this.$t(
          'summary_box.confirm_dialog.unexpected_error.close_button'
        ),
        cancelButtonLabel: this.$t(
          'summary_box.confirm_dialog.unexpected_error.cancel_button'
        )
      }).then(result => {
        if (result) this.openInquiry();
      });
    },
    openTheInstallRequestRenewalDialog() {
      api
        .get('/install_request/renewal/verifications/verify', {
          params: {},
          silent: true
        })
        .then(() => {
          this.openDialog('TheInstallRequestRenewalDialog');
        })
        .catch(error => {
          if (error.response.data.errors[0] === 'store scope required') {
            this.confirmStoreScopeError();
          } else if (
            ['mall admin account required', 'invalid account'].includes(
              error.response.data.errors[0]
            )
          ) {
            this.openDialog('TheInstallRequestRenewalMallAdminAccountDialog');
          } else {
            this.confirmUnexpectedError();
          }
        });
    }
  }
};
</script>

<style scoped>
.ReviewSettingsWidgets__info-box {
  margin-bottom: 12px;
}

.ReviewSettingsWidgets__info-box-link {
  text-decoration: underline;
}

.ReviewSettingsWidgets__install-request-renewal {
  margin-top: 24px;
}
</style>

<i18n locale="ko">
{
  "info_box": {
    "review_settings_html": "크리마 리뷰를 처음 도입할 경우 설치 도우미 기능을 활용하여 <b>여러개의 리뷰 위젯을 한번에 생성/설치</b>할 수 있습니다. ",
    "review_settings_cta": "리뷰 위젯 설치 도우미"
  },
  "summary_box": {
    "title": "쇼핑몰 리뉴얼 예정이신가요? 🙋🏻‍♂️",
    "content": "쇼핑몰 리뉴얼 스킨에 크리마 위젯을 재설치 해야 합니다. 아래 버튼을 통해 리뉴얼 위젯 설치 요청을 진행해주세요.",
    "button_label": "리뉴얼 위젯 설치 요청",
    "confirm_dialog": {
      "store_scope_error":{
        "title": "상점 API 권한 동의가 필요합니다.",
        "content": "원활한 위젯 설치를 위해서는 카페24 상점 API 권한 동의가 필요합니다. 카페24 대표 운영자 계정으로 카페24 쇼핑몰 관리자에 접속하여 권한 동의를 완료한 후, 다시 크리마 관리자 페이지에서 [리뉴얼 위젯 설치 요청]을 진행해주세요.",
        "close_button": "권한 동의하러 가기"
      },
      "unexpected_error":{
        "title": "위젯 설치 요청을 진행할 수 없습니다.",
        "content": "입력된 부운영자 계정으로 호스팅사 접속이 불가하여 위젯 설치 요청을 진행할 수 없습니다. 크리마 운영팀에 문의해주세요.",
        "close_button": "문의하러 가기",
        "cancel_button": "닫기"
      }
    }
  }
}
</i18n>
