<template>
  <div class="ChannelUnitFilterCell__unit">
    <div v-if="isChildUnit" class="ChannelUnitFilterCell__child-unit-pad">
      <i18n path="and" />
    </div>
    <div>
      <div class="ChannelUnitFilterCell__unit">
        <Component
          :is="isDateCompareFilterType ? 'i18n' : 'div'"
          path="filter.is"
        >
          <AppSelect
            v-tooltip="
              isRecentDaysDisabled ? $t('tooltip.recent_days_disabled') : ''
            "
            :value="isRecentDaysDisabled ? 0 : unit.recent_days"
            :options="recentDaysOptions"
            :disabled="isRecentDaysDisabled"
            inline
            @change="updateUnit('recent_days', $event)"
          />
          <AppSelect
            v-if="!isRecentDaysDisabled"
            :value="RecentDayCompareType.GTE"
            :options="RecentDayCompareType.options()"
            disabled
            inline
          />
          <AppDropdownMenuButton
            class="ChannelUnitFilterCell__filter-type app-input-inline"
            :label="selectedFilterTypeOption.label"
            :menu-items="filterTypeOptions"
          />
        </Component>
      </div>
      <div class="ChannelUnitFilterCell__unit">
        <i18n v-if="isDateCompareFilterType" path="filter.date_compare_options">
          <AppNumberInput
            :digits="3"
            :value="unit.date_compare_value"
            :invalid="!!dateCompareValueError"
            inline
            @change="updateUnit('date_compare_value', $event)"
          />
          <AppSelect
            class="app-input-inline--left"
            :value="
              isRecentDayCompareTypeDisabled ? 0 : unit.filter_compare_type
            "
            :options="RecentDayCompareType.options()"
            :disabled="isRecentDayCompareTypeDisabled"
            inline
            @change="updateUnit('filter_compare_type', $event)"
          />
        </i18n>
        <i18n
          v-else-if="isValueCompareFilterType"
          path="filter.product_compare_options"
        >
          <i18n class="app-input-inline" :path="filterValueUnit">
            <AppNumberInput
              :digits="
                ChannelProductFilterType.HIGHEST_PURCHASED_PRICE ===
                unit.filter_type
                  ? 12
                  : 3
              "
              :float-precision="isCompareDecimalValueFilterType ? 1 : 0"
              :value="unit.filter_compare_value"
              :allow-decimal="isCompareDecimalValueFilterType"
              :invalid="!!filterCompareValueError"
              inline
              @change="updateUnit('filter_compare_value', $event)"
            />
          </i18n>
          <AppSelect
            :value="unit.filter_compare_type"
            :options="ValueCompareType.options()"
            inline
            @change="updateUnit('filter_compare_type', $event)"
          />
        </i18n>
        <i18n
          v-else-if="isProductOptionFilterType"
          path="filter.product_stock_options"
        >
          <AppSelectFilterable
            class="app-input-inline ChannelUnitFilterCell__options"
            :options="productOptionTypeOptions"
            :value="unit.product_option_type_id"
            :placeholder="$t('filter.product_option_type_placeholder')"
            :invalid="!!productOptionTypeError"
            @change="updateUnit('product_option_type_id', $event)"
          />
          <AppButton
            class="app-input-inline"
            :disabled="!unit.product_option_type_id"
            :label="
              unit.product_option_value
                ? unit.product_option_value.value
                : $t('filter.product_option_value_label')
            "
            @click="openSelectProductOptionValueDialog"
          />
        </i18n>
      </div>
      <AppFormError
        :error="
          dateCompareValueError ||
            filterCompareValueError ||
            productOptionTypeError ||
            productOptionValueError
        "
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import ChannelProductFilterType from '@/enums/ChannelProductFilterType';
import RecentDayCompareType from '@/enums/RecentDayCompareType';
import ValueCompareType from '@/enums/ValueCompareType';

export default {
  name: 'ChannelUnitFilterCell',
  props: {
    unit: { type: Object, required: true },
    unitIndex: { type: Number, required: true },
    productOptionTypes: { type: Array, required: true },
    availableFilterTypes: { type: Array, default: null },
    errors: { type: Object, required: true }
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('session', [
      'currencyKey',
      'isReviewPreparing',
      'isReviewOperating',
      'isUsingNlp'
    ]),
    ValueCompareType: () => ValueCompareType,
    RecentDayCompareType: () => RecentDayCompareType,
    ChannelProductFilterType: () => ChannelProductFilterType,
    isRecentDaysDisabled() {
      return this.isProductOptionFilterType || this.isDateCompareFilterType;
    },
    isRecentDayCompareTypeDisabled() {
      return (
        !this.isDateCompareFilterType ||
        ChannelProductFilterType.SOLD_OUT_TO_DISPLAY == this.unit.filter_type
      );
    },
    isDateCompareFilterType() {
      return ChannelProductFilterType.isDateCompareFilterType(
        this.unit.filter_type
      );
    },
    isProductOptionFilterType() {
      return ChannelProductFilterType.isProductOptionFilterType(
        this.unit.filter_type
      );
    },
    isValueCompareFilterType() {
      return ChannelProductFilterType.isValueCompareFilterType(
        this.unit.filter_type
      );
    },
    isCompareDecimalValueFilterType() {
      return ChannelProductFilterType.isCompareDecimalValueFilterType(
        this.unit.filter_type
      );
    },
    isChildUnit() {
      return this.unit.channel_filter_unit_id !== null;
    },
    defaultRecentDaysOptions() {
      return [1, 3, 7, 14, 30, 60, 90].map(day => ({
        label: this.$t('filter.recent_days', [day]),
        value: day
      }));
    },
    recentDaysOptions() {
      return this.isRecentDaysDisabled
        ? [{ label: this.$t('filter.recent_days_zero'), value: 0 }]
        : this.defaultRecentDaysOptions;
    },
    selectedFilterTypeOption() {
      return this.filterTypeOptions.find(
        ({ value }) => value === this.unit.filter_type
      );
    },
    filterTypeOptions() {
      const { availableFilterTypes } = this;

      const options = ChannelProductFilterType.options().map(option => ({
        ...option,
        label:
          option.value === ChannelProductFilterType.DISPLAYED &&
          this.currentBrand.shop_builder === 'cafe24'
            ? this.$t('filter.displayed_cafe24')
            : option.label,
        tooltip:
          ChannelProductFilterType.isReviewRequiredFilterType(option.value) &&
          this.reviewRequiredTooltip,
        disabled:
          ChannelProductFilterType.isReviewRequiredFilterType(option.value) &&
          this.reviewRequiredTooltip,
        clickHandler: ({ close }) => {
          close();
          this.$nextTick(() => this.updateUnit('filter_type', option.value));
        }
      }));

      return availableFilterTypes
        ? options.filter(({ value }) => availableFilterTypes.includes(value))
        : options;
    },
    reviewRequiredTooltip() {
      if (this.isReviewPreparing) return this.$t('tooltip.preparing_review');
      else if (this.isReviewOperating) {
        if (this.isUsingNlp) return null;
        else return this.$t('tooltip.not_using_nlp');
      } else return this.$t('tooltip.not_using_review');
    },
    productOptionTypeOptions() {
      return this.productOptionTypes.map(({ name, id }) => ({
        label: name,
        value: id
      }));
    },
    dateCompareValueError() {
      return this.error('date_compare_value');
    },
    filterCompareValueError() {
      return this.error('filter_compare_value');
    },
    productOptionTypeError() {
      return this.error('product_option_type_id');
    },
    productOptionValueError() {
      return this.error('product_option_value_id');
    },
    filterValueUnit() {
      switch (this.unit.filter_type) {
        case ChannelProductFilterType.MOST_CLICKED:
        case ChannelProductFilterType.MOST_CARTED:
          return 'unit.view';
        case ChannelProductFilterType.HIGHEST_PURCHASE_RATE:
          return 'unit.percentage';
        case ChannelProductFilterType.HIGHEST_PURCHASED_PRICE:
          return this.currencyKey;
        case ChannelProductFilterType.HIGHEST_REVIEW_SCORE:
          return 'unit.points';
        default:
          return 'unit.count';
      }
    }
  },
  watch: {
    'unit.filter_type'() {
      this.updateUnit('filter_compare_value', 0);
    },
    'unit.product_option_type_id'() {
      this.updateUnit('product_option_value', null);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    error(column) {
      return this.errors[`${this.unitIndex}[${column}]`];
    },
    updateUnit(column, value) {
      this.$emit('change', { ...this.unit, [column]: value });
      this.$emit('validate-unit-field', column);
    },
    openSelectProductOptionValueDialog() {
      this.openDialog([
        'DisplaySelectProductOptionValueDialog',
        {
          productOptionType: this.productOptionTypes.find(
            t => t.id === this.unit.product_option_type_id
          ),
          productOptionValue: this.unit.product_option_value || {}
        }
      ]).then(eventBus => {
        eventBus.$on('update-product-option-value', productOptionValue =>
          this.updateUnit('product_option_value', productOptionValue)
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ChannelUnitFilterCell__unit {
  display: flex;

  & + & {
    margin-top: 4px;
  }
}

.ChannelUnitFilterCell__filter-type {
  vertical-align: baseline;
}

.ChannelUnitFilterCell__options {
  max-width: 224px;
}
.ChannelUnitFilterCell__child-unit-pad {
  align-self: center;
  padding-right: 12px;
}
</style>

<i18n locale="ko">
{
  "and": "동시에",
  "filter": {
    "is": "{0}{1}{2}이",
    "displayed_cafe24": "상품 진열일",
    "recent_days": "최근 {0}일",
    "recent_days_zero": "계산 시점",
    "date_compare_options": "{0}일{1}인 상품",
    "product_compare_options": "{0}{1}인 상품",
    "product_stock_options": "{0}{1}을 포함한 상품",
    "product_option_type_placeholder": "옵션명",
    "product_option_value_label": "옵션값"
  },
  "tooltip": {
    "recent_days_disabled": "해당 옵션은 계산 시점 기준만 지원합니다.",
    "preparing_review": "CREMA Review 서비스 결제 및 테스트 중 상태입니다.\n해당 항목은 쇼핑몰에 CREMA Review 서비스가\n라이브된 이후 정상적으로 사용 가능합니다.",
    "not_using_nlp": "CREMA Review 서비스의 [AI 리뷰 분석] 설정을\n적용한 이후 정상적으로 사용 가능합니다.",
    "not_using_review": "CREMA Review 서비스 사용이 필요합니다."
  }
}
</i18n>
