<template>
  <AppModalForm
    :title="$t('title_edit')"
    :sub-title="
      widget
        ? $t('sub_title', {
            widget_name: widget.name,
            widget_id: widgetId
          })
        : ''
    "
    :form-props="{ ...formProps, objectId: 'smart_installation' }"
    :is-loading="isLoading"
    v-on="formEvents"
    @change-group="formGroupChanged"
    @blur-group="formGroupBlurred"
  >
    <input
      type="hidden"
      name="smart_installation[installation_type]"
      :value="installation_type"
    />
    <input
      type="hidden"
      name="smart_installation[widget_id]"
      :value="widgetId"
    />
    <input
      type="hidden"
      name="smart_installation[id]"
      :value="installationId"
    />
    <template #group="{ id, errors }">
      <template v-if="id === 'install_position_specific'">
        <div class="AppForm__group-field">
          <AppTextInput
            v-model="formObject.element_selector"
            class="ReviewInstallSimulationAttachWidgetSettingsFormDialog__element-selector"
            name="smart_installation[element_selector]"
            :placeholder="
              $t('smart_installations.placement.specific.placeholder')
            "
            :invalid="!!errors.install_position_specific"
            @change="validateField('install_position_specific')"
            @blur="validateField('install_position_specific')"
          />
          <AppSelect
            v-model="formObject.install_position"
            class="ReviewInstallSimulationAttachWidgetSettingsFormDialog__install-position"
            name="smart_installation[install_position]"
            :options="WidgetInsertType.options()"
          />
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import { mapGetters } from 'vuex';
import SmartInstallationFormView from '@/views/review/smart_installation/mixins/SmartInstallationFormView';
import WidgetPageType from '@/enums/WidgetPageType';

export default {
  name: 'ReviewInstallSimulationAttachWidgetSettingsFormDialog',
  mixins: [SmartInstallationFormView],
  props: {
    installationId: {
      type: Number,
      required: true
    },
    widgetId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapGetters('reviewWidgets', [
      'reviewWidget',
      'reviewWidgetSmartInstallation'
    ]),
    widget() {
      return this.reviewWidget(this.widgetId);
    },
    groupIds() {
      return this.baseGroupIds;
    },
    groupOptions() {
      return this.baseGroupOptions;
    },
    pageTypeOptions() {
      return this.basePageTypeOptions;
    }
  },
  mounted() {
    this.orgFormObject = this.reviewWidgetSmartInstallation(
      this.installationId
    );

    if (this.productDetailPageOnly) {
      this.orgFormObject.page_type = WidgetPageType.PRODUCT_DETAIL;
    }

    this.isLoading = false;
  },
  methods: {
    submit(formData) {
      this.appendDefaultPlacement(formData);

      api
        .patch(
          '/install_simulation/simulations/update_installation',
          formData,
          {
            successMessage: this.$t('app.saved')
          }
        )
        .then(() => {
          this.close(true);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.AppForm__group-field {
  display: flex;
}

.ReviewInstallSimulationAttachWidgetSettingsFormDialog__install-position {
  margin-left: 8px;
}
</style>

<i18n locale="ko">
{
  "title_edit": "위젯 간편 설치 설정",
  "sub_title": "{widget_name} (위젯 ID {widget_id})"
}
</i18n>
