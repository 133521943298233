<template>
  <ReviewSettingsWidgetsNormal :widget-type="WidgetType.ETC" />
</template>

<script>
import ReviewSettingsWidgetsNormal from './ReviewSettingsWidgetsNormal';
import WidgetType from '@/enums/WidgetType';

export default {
  name: 'ReviewSettingsWidgetsEtc',
  components: { ReviewSettingsWidgetsNormal },
  computed: {
    WidgetType() {
      return WidgetType;
    }
  }
};
</script>
