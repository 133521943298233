import _ from 'lodash';

export default {
  props: {
    packingMethod: {
      type: String,
      default: 'array',
      validator(val) {
        return ['array', 'string', 'bit_flag'].includes(val);
      }
    }
  },
  methods: {
    unpackToArray(packedValue) {
      if (!packedValue) return [];

      if (this.packingMethod === 'string')
        return packedValue.split(',').map(v => _.trim(v));
      else if (this.packingMethod === 'bit_flag') {
        const array = [];
        let mask = 1;
        let flag = packedValue;
        while (flag > 0) {
          if (flag & 1) array.push(mask);
          mask <<= 1;
          flag >>= 1;
        }
        return array;
      } else return packedValue;
    },
    packFromArray(array) {
      if (this.packingMethod === 'string') return array ? array.join(',') : '';
      else if (this.packingMethod === 'bit_flag')
        return array ? _.sum(array) : 0;
      else return [...array];
    }
  }
};
