<template>
  <AppAjaxContent :is-loading="isLoading" class="ReviewSettingsWidgetsSwitcher">
    <AppSearchbar v-if="!isImwebBrand">
      <li>
        <AppButton
          :label="$t('head.new_widget')"
          button-style="red"
          @click="newWidgetClicked"
        />
      </li>
    </AppSearchbar>
    <AppResourceTable
      table-id="widgets-table"
      :columns="COLUMNS"
      :rows="rows"
      :event-bus="tableEventBus"
    >
      <template #cell="{ column, rowIndex }">
        <template v-if="column === 'actions'">
          <AppButton
            :label="$t('app.settings')"
            @click="widgetSettingsClicked(rowIndex)"
          />
          <AppDropdownMenuButton
            :label="$t('app.manage')"
            :menu-items="[
              [
                {
                  label: $t('review_widgets.move_to_widget_inserted_url'),
                  type: 'external_link',
                  to: addParamsToUrlString(
                    reviewWidgets[rowIndex].inserted_url,
                    {
                      'crema-scroll-to-widget-id': reviewWidgets[rowIndex].id
                    }
                  ),
                  disabled: !reviewWidgets[rowIndex].inserted_url,
                  tooltip: reviewWidgets[rowIndex].inserted_url
                    ? null
                    : $t('review_widgets.not_found_inserted_url')
                }
              ],
              [
                {
                  label: $t('review_widgets.delete_widget'),
                  clickHandler: () =>
                    deleteWidget(reviewWidgets[rowIndex].id, rowIndex),
                  style: 'danger',
                  disabled:
                    reviewWidgets[rowIndex].default || !isSuperAdminAccessible,
                  tooltip: reviewWidgets[rowIndex].default
                    ? $t('review_widgets.tooltip_cannot_delete_default')
                    : !isSuperAdminAccessible
                    ? $t('review_widgets.tooltip_must_be_admin')
                    : null
                }
              ]
            ]"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import WidgetInstallMethod from '@/enums/WidgetInstallMethod';
import WidgetType from '@/enums/WidgetType';

import ReviewSettingsWidgetsView from './ReviewSettingsWidgetsView';

export default {
  name: 'ReviewSettingsWidgetsSwitcher',
  mixins: [ReviewSettingsWidgetsView],
  data() {
    return {
      WIDGETS_URL: '/review/switch_widgets',
      COLUMNS: [
        { id: 'id', label: this.$t('table_head.id') },
        {
          id: 'switch_widget_type',
          label: this.$t('table_head.switch_widget_type')
        },
        {
          id: 'switch_widget_install_method',
          label: this.$t('table_head.switch_widget_install_method'),
          superAdmin: true
        },
        { id: 'name', label: this.$t('app.name'), align: 'left' },
        {
          id: 'switch_pc_widget',
          label: this.$t('table_head.switch_pc_widget')
        },
        {
          id: 'switch_mobile_widget',
          label: this.$t('table_head.switch_mobile_widget')
        },
        { id: 'actions', label: this.$t('app.actions') }
      ]
    };
  },
  computed: {
    ...mapGetters('session', ['isShopifyBrand', 'isImwebBrand']),
    rows() {
      return this.reviewWidgets.map(widget => ({
        id: widget.id,
        switch_widget_type: WidgetType.t(widget.switch_widget_type),
        switch_widget_install_method: WidgetInstallMethod.label(
          widget.install_method
        ),
        name: widget.name,
        switch_pc_widget:
          widget.switch_pc_widget_id && widget.switch_pc_widget_name
            ? this.$t('table_body.switch_widget', {
                id: widget.switch_pc_widget_id,
                name: widget.switch_pc_widget_name
              })
            : '-',
        switch_mobile_widget:
          widget.switch_mobile_widget_id && widget.switch_mobile_widget_name
            ? this.$t('table_body.switch_widget', {
                id: widget.switch_mobile_widget_id,
                name: widget.switch_mobile_widget_name
              })
            : '-'
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    newWidgetClicked() {
      this.openDialog([
        'ReviewSettingsWidgetsSwitcherFormDialog'
      ]).then(eventBus => eventBus.$on('focus-row', this.focusOnRow));
    },
    widgetSettingsClicked(index) {
      const switchWidget = this.reviewWidgets[index];
      this.openDialog([
        'ReviewSettingsWidgetsSwitcherFormDialog',
        { switchWidgetId: switchWidget.id }
      ]);
    },
    addParamsToUrlString(urlString, params) {
      if (!urlString) return;

      const url = new URL(urlString);
      Object.keys(params).forEach(key => {
        url.searchParams.set(key, params[key]);
      });
      return url.href;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .widgets-table__id {
    flex: 0 0 60px;
  }

  .widgets-table__switch-widget-type {
    flex: 0 0 96px;
  }

  .widgets-table__actions {
    flex: 0 0 153px;
  }
}
</style>

<i18n locale="ko">
{
  "head": {
    "new_widget": "접속 환경별 위젯 추가"
  },
  "table_head": {
    "id": "위젯 ID",
    "switch_widget_install_method": "설치 방식",
    "switch_widget_type": "설정 위젯",
    "switch_pc_widget": "PC에서 보여줄 위젯",
    "switch_mobile_widget": "모바일에서 보여줄 위젯"
  },
  "table_body": {
    "switch_widget": "위젯 ID: {id}, {name}"
  }
}
</i18n>
