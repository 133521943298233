<template>
  <div class="AppPhoneChangeRequest">
    <AppChangeRequestStatus
      :title="$t('title')"
      :status="phoneChangeStatus"
      :hint="phoneHint"
    />
    <div class="AppPhoneChangeRequest__button">
      <AppButton
        v-if="['requested', 'approved'].includes(phoneChangeStatus)"
        :label="$t('show_documents_button')"
        @click="clickShowDocuments"
      />
      <AppButton
        v-else
        :label="$t('change_phone_button')"
        @click="clickChangePhone"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppPhoneChangeRequest',
  props: {
    phoneChangeStatus: { type: String, default: 'none' },
    rejectMessage: { type: String, default: '' }
  },
  computed: {
    phoneHint() {
      return this.$t(`hint.${this.phoneChangeStatus}`, [this.rejectMessage]);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    clickShowDocuments() {
      this.openDialog(['TheBrandMaterialDialog', { type: 'telecom_cert' }]);
    },
    clickChangePhone() {
      this.openDialog('ThePhoneChangeRequestDialog').then(eventBus =>
        eventBus.$on('requested', phone_change_request =>
          this.$emit('requested', phone_change_request)
        )
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.AppPhoneChangeRequest__button {
  margin-top: 4px;
}
</style>

<i18n locale="ko">
{
  "title": "통신서비스 이용증명원(가입증명서)",
  "hint": {
    "none": "대표 전화번호가 설정되지 않은 경우 크리마 문자 발송 서비스를 사용할 수 없습니다.",
    "requested": "크리마 운영진이 확인 후 처리해드립니다. 확인이 완료될 경우 [알림]을 통해 안내됩니다.",
    "required": "대표 전화번호가 설정되지 않은 경우 크리마 문자 발송 서비스를 사용할 수 없습니다.",
    "rejected": "변경 요청이 거절되었습니다. (사유 : {0})\n‘변경 요청’ 버튼을 눌러 다시 진행해주세요.",
    "approved": "변경이 완료되었습니다. 등록한 자료 변경은 아래 [등록한 자료 확인] 버튼을 통해 진행해주세요."
  },
  "change_phone_button": "변경 요청",
  "show_documents_button": "등록한 자료 확인"
}
</i18n>
