<template>
  <div class="SlotInfoCard">
    <div class="SlotInfoCard__image-box">
      <AppImage class="SlotInfoCard__image" :src="imageUrl" />
    </div>
    <div class="SlotInfoCard__content">
      <div class="SlotInfoCard__title">{{ $t('slot') }}</div>
      <div class="SlotInfoCard__name">{{ name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlotInfoCard',
  props: {
    imageUrl: { type: String, required: true },
    name: { type: String, required: true }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.SlotInfoCard {
  display: flex;
  min-height: 160px;
}

.SlotInfoCard__image-box {
  width: 260px;
  background-color: $color-background-card;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 0 0 6px;
}

.SlotInfoCard__content {
  padding: 20px 16px 0 16px;
  flex-grow: 1;
  background-color: $color-background;
  border: 1px solid $color-grey-25;
  border-radius: 0px 6px 6px 0px;
}

.SlotInfoCard__title {
  @include text-content-dark;
  font-weight: bold;
}

.SlotInfoCard__name {
  @include text-content-dark;
  margin-top: 4px;
}
</style>

<i18n locale="ko">
{
  "slot": "설치 위치"
}
</i18n>
