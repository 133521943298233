var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{class:[
    'AppPaginationButton',
    {
      'AppPaginationButton--navigation': _vm.buttonType !== 'normal'
    },
    {
      'AppPaginationButton--current-page':
        _vm.buttonType === 'normal' && _vm.page === _vm.currentPage
    },
    { 'AppPaginationButton--disabled': _vm.disabled }
  ],attrs:{"href":_vm.pagePath},on:{"click":_vm.click}},[(_vm.buttonType === 'first')?_c('AppSvgIcon',{attrs:{"name":"icon-first-button"}}):(_vm.buttonType === 'prev')?_c('AppSvgIcon',{attrs:{"name":"icon-prev-button"}}):(_vm.buttonType === 'next')?_c('AppSvgIcon',{attrs:{"name":"icon-next-button"}}):(_vm.buttonType === 'last')?_c('AppSvgIcon',{attrs:{"name":"icon-last-button"}}):[_vm._v(_vm._s(_vm.page))]],2)}
var staticRenderFns = []

export { render, staticRenderFns }