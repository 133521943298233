import { mapState } from 'vuex';
import api from '@/lib/api';

const POLL_FREQUENCY_MS = 5000;

export default {
  data: () => ({ imageDownloadingProductIds: [], timer: null }),
  computed: mapState('displayPreviewSettings', ['previewSettings']),
  mounted() {
    this.timer = setInterval(() => {
      if (!this.imageDownloadingProductIds.length) return;

      api
        .get('/display/products/products', {
          params: { product_ids: this.imageDownloadingProductIds }
        })
        .then(({ data }) => this.updateImageDownloadedProducts(data.products));
    }, POLL_FREQUENCY_MS);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    isImageDownloadingProduct(product) {
      return (
        !this.previewSettings.use_original_product_image && !product.image_width
      );
    },
    updateImageDownloadedProducts(products) {
      const downloadedProductsMap = {};
      products.forEach(product => {
        if (!this.isImageDownloadingProduct(product))
          downloadedProductsMap[product.id] = product;
      });

      const downloadedProductIds = Object.keys(
        downloadedProductsMap
      ).map(productId => Number(productId));
      if (!downloadedProductIds.length) return;

      this.imageDownloadingProductIds = this.imageDownloadingProductIds.filter(
        productId => !downloadedProductIds.includes(productId)
      );
      this.doUpdateImageDownloadedProducts(downloadedProductsMap);
    },
    setImageDownloadingProductIds(products) {
      this.imageDownloadingProductIds = products
        .filter(product => this.isImageDownloadingProduct(product))
        .map(({ id }) => id);
    },
    doUpdateImageDownloadedProducts() {}
  }
};
