import WidgetStyle from '@/enums/WidgetStyle';

export default {
  formSections() {
    return {
      common: ['reviews_count_no_item_action']
    };
  },
  widgetStyles() {
    return [WidgetStyle.COUNT_AND_SCORE];
  },
  displayOrderOptions() {
    return [];
  },
  showHeaderSectionSubSettings() {
    return [];
  },
  useBackgroundColor() {
    return false;
  }
};
