import { constructEnum } from '@/lib/enum';

const TargetDispatchStatus = constructEnum('TargetDispatchStatus', {
  NONE: 0,
  SCHEDULED: 10,
  READY: 20,
  DISPATCHING: 30,
  DISPATCHED: 40,
  PAYING: 50,
  COMPLETE: 100,
  DESTROYING: 500,
  ERROR_SYNCHRONIZING: 1015,
  ERROR_CALCULATING: 1020,
  ERROR_TIMEOUT: 1030,
  ERROR_NO_COHORT: 1031,
  ERROR_DISABLED: 1032,
  ERROR_NOT_ENOUGH_CREDIT: 1033,
  ERROR_PHONE_IS_BLANK: 1034,
  ERROR_UNSUBSCRIBE_PHONE_IS_BLANK: 1035,
  ERROR_SERVICE_EXPIRED: 1036,
  ERROR_SAMPLE_DISPATCHING: 1038,
  ERROR_DISPATCHING: 1040,
  ERROR_BIZ_MESSAGE_INSUFFICIENT_INPUTS: 1050,
  ERROR_SOME_SUB_COHORTS_FAILED: 2000,
  ERROR_ALL_SUB_COHORTS_FAILED: 2010
});

TargetDispatchStatus.isError = function(status) {
  return status >= this.ERROR_SYNCHRONIZING;
};

export default TargetDispatchStatus;
