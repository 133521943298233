import { constructEnum } from '@/lib/enum';

export default constructEnum('BrandCsvStatus', {
  NONE: -1,

  IMPORT_PENDING: 0,
  IMPORTED: 1,
  IMPORTED_PARTIAL: 5,
  IMPORTING: 10,

  EXPORT_SCHEDULED: 999,
  EXPORT_PENDING: 1000,
  EXPORT_STARTED: 1005,
  EXPORTING: 1010,
  EXPORTED: 1020,
  EXPORTED_PARTIAL: 1030,

  IMPORT_FAILED: 10000,
  EXPORT_FAILED: 10010,
  IMPORT_WARNING: 10020,

  EXPORT_CANCELED: 100000
});
