<template>
  <div>
    <textarea
      ref="textarea"
      :value="currentValue"
      v-bind="{
        id,
        name,
        value,
        rows,
        placeholder,
        maxlength,
        disabled,
        readonly,
        spellcheck
      }"
      :class="['AppTextarea', { 'AppTextarea--invalid': invalid }]"
      @input="change($event.target.value)"
      @blur="trimAndBlur"
    />
    <div v-if="maxBytes" class="AppKeywordTextarea__bytes">
      {{ `${byteSize}/${maxBytes} bytes` }}
    </div>
    <div v-else-if="maxlength" class="AppKeywordTextarea__length">
      {{ `${length}/${maxlength}` }}
    </div>
  </div>
</template>

<script>
import TextInput from '@/mixins/TextInput';
import getByteSize from '@/lib/vendor/getByteSize';

export default {
  name: 'AppTextarea',
  mixins: [TextInput],
  props: {
    rows: {
      type: Number,
      default: 2
    },
    autosize: {
      type: Boolean,
      default: true
    },
    invalid: {
      type: Boolean,
      default: false
    },
    readonly: { type: Boolean, default: false },
    maxBytes: { type: Number, default: 0 }
  },
  computed: {
    byteSize() {
      return getByteSize(this.currentValue).toLocaleString();
    },
    length() {
      return this.currentValue.length.toLocaleString();
    }
  },
  watch: {
    currentValue() {
      this.$nextTick(this.resize);
    }
  },
  mounted() {
    this.resize();
  },
  methods: {
    resize() {
      if (!this.autosize) return;

      const BORDER_HEIGHT = 2;
      const textarea = this.$refs.textarea;

      textarea.style.setProperty('height', 'auto');
      const oldRows = textarea.rows;
      textarea.rows = this.rows || 1;
      textarea.style.setProperty(
        'height',
        `${textarea.scrollHeight + BORDER_HEIGHT}px`
      );
      textarea.rows = oldRows;
    },
    focus() {
      this.$refs.textarea.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_inputs.scss';

.AppTextarea {
  @include input-base;
  @include input-placeholder;
  resize: none;
  padding: 5px 11px;
  vertical-align: middle;
}

.AppKeywordTextarea__bytes,
.AppKeywordTextarea__length {
  text-align: right;
  margin-top: 2px;
}
</style>
