<template>
  <AppTabContent menu-item-id="target_widget_campaigns_product_recommendation">
    <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
      <TargetCampaignsSearchbar
        v-bind="searchbarProps"
        v-on="searchbarEvents"
      />
      <TargetCampaignsTable v-bind="tableProps" />
    </AppAjaxContent>
  </AppTabContent>
</template>

<script>
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetCampaignsView from './TargetCampaignsView';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'TargetWidgetCampaignsProductRecommendation',
  mixins: [TargetCampaignsView],
  computed: {
    ...mapState('session', ['targetSettings']),
    ...mapGetters(['isSuperAdminAccessible']),
    campaignCategory: () => 'product_recommendation_widget',
    campaignMediaType: () => 'widget',
    sortTypes: () => ['created_at', 'edited_at', 'name'],
    searchTypes: () => ['name'],
    columns() {
      return [
        { id: 'campaignInfo' },
        { id: 'effects', label: this.$t('app.effect') },
        { id: 'campaignId' },
        { id: 'editedAt' },
        { id: 'actions' }
      ];
    },
    creatableCampaignTypes() {
      if (this.isSuperAdminAccessible)
        return TargetCampaignType.creatableProductRecommendationWidget;

      return TargetCampaignType.creatableProductRecommendationWidget.filter(
        campaign_type =>
          !this.targetSettings.widget_campaign_types_under_construction.includes(
            campaign_type
          )
      );
    }
  }
};
</script>
