import Vue from 'vue';
import api from '@/lib/api';
import i18n from '@/lib/i18n';
import { nullResources } from '@/lib/resources';

const FETCH_FIT_PRODUCTS_REQUEST_ID = 'FETCH_FIT_PRODUCTS';

const state = {
  products: nullResources,
  fitTemplates: []
};

const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_FIT_TEMPLATES(state, fitTemplates) {
    state.fitTemplates = fitTemplates;
  },
  UPDATE_FIT_SIZE_PRODUCT({ products }, newFitSizeProduct) {
    const { items } = products;
    const index = items.findIndex(i => i.id === newFitSizeProduct.id);
    Vue.set(items, index, newFitSizeProduct);
  },
  DISABLE_FIT_SIZE_PRODUCT({ products }, { product_id, tab }) {
    const { items } = products;
    const index = items.findIndex(i => i.id === product_id);
    if (tab === '') {
      Vue.set(items[index], 'fit_disabled_product', { product_id });
    } else {
      Vue.delete(items, index);
      Vue.set(products, 'total_count', products.total_count - 1);
    }
  },
  RESTORE_FIT_SIZE_PRODUCT({ products }, { product_id, tab }) {
    const { items } = products;
    const index = items.findIndex(i => i.id === product_id);
    if (tab === '') {
      Vue.set(items[index], 'fit_disabled_product', undefined);
    } else {
      Vue.delete(items, index);
      Vue.set(products, 'total_count', products.total_count - 1);
    }
  }
};

const actions = {
  resetProducts({ commit }) {
    api.cancel(FETCH_FIT_PRODUCTS_REQUEST_ID);
    commit('SET_PRODUCTS', nullResources);
  },
  fetchProducts({ commit }, params) {
    if (state.products.isNull) params.init = 1;

    return api
      .get('/fit/products', {
        requestId: FETCH_FIT_PRODUCTS_REQUEST_ID,
        params
      })
      .then(function({ data }) {
        commit('SET_PRODUCTS', data.products);
        commit('SET_FIT_TEMPLATES', data.fit_templates);
      });
  },
  disableProduct({ commit }, { product, tab }) {
    api
      .delete(`/fit/products/${product.id}/disable`, {
        successMessage: i18n.t('fit_product.disabled', {
          name: product.name
        })
      })
      .then(() => {
        commit('DISABLE_FIT_SIZE_PRODUCT', { product_id: product.id, tab });
      });
  },
  restoreProduct({ commit }, { product, tab }) {
    api
      .patch(
        `/fit/products/${product.id}/restore`,
        {},
        {
          successMessage: i18n.t('fit_product.restored', {
            name: product.name
          })
        }
      )
      .then(() => {
        commit('RESTORE_FIT_SIZE_PRODUCT', { product_id: product.id, tab });
      });
  },
  copyFitSizeProduct({ commit }, { product, srcProduct }) {
    api
      .post(
        '/fit/products/copy',
        {
          id: product.id,
          src_product_id: srcProduct.id
        },
        {
          successMessage: i18n.t('fit_product.copied', {
            src_product: srcProduct.name,
            dst_product: product.name
          })
        }
      )
      .then(function({ data }) {
        commit('UPDATE_FIT_SIZE_PRODUCT', data);
      });
  }
};

const getters = {
  isFetchingProducts(_state, _getters, _rootState, rootGetters) {
    return rootGetters['request/isRequestPending'](
      FETCH_FIT_PRODUCTS_REQUEST_ID
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
