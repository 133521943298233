<template>
  <div>
    <AppAjaxContent :is-loading="isLoading">
      <ReviewCategoryTable
        :category-id="categoryId"
        :categories="categories"
        @paginate="paginate"
        @refresh="refreshResource"
      />
    </AppAjaxContent>
    <ReviewCategoryProductsTable
      :category-id="categoryId"
      :event-bus="tableEventBus"
      class="ReviewCategoryShow__products-table"
    />
  </div>
</template>

<script>
import api from '@/lib/api';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { nullResources } from '@/lib/resources';
import ResourceView from '@/mixins/ResourceView';

import ReviewCategoryTable from './ReviewCategoryTable';
import ReviewCategoryProductsTable from './ReviewCategoryProductsTable';

export default {
  name: 'ReviewCategoryShow',
  components: { ReviewCategoryTable, ReviewCategoryProductsTable },
  mixins: [ResourceView],
  data() {
    return {
      productCategories: nullResources,
      isLoading: true,
      tableEventBus: new Vue()
    };
  },
  computed: {
    ...mapGetters('productCategory', ['productCategoryIdFromPath']),
    categoryId() {
      return this.productCategoryIdFromPath;
    },
    categories() {
      return this.productCategories;
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    if (to.path !== from.path) {
      this.$nextTick(() => this.tableEventBus.$emit('refresh'));
    }
  },
  methods: {
    fetchResource(params) {
      api
        .get(`/product_categories/${this.categoryId}`, { params })
        .then(({ data }) => (this.productCategories = data.product_categories))
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewCategoryShow__products-table {
  margin-top: 44px;
}
</style>
