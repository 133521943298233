<template>
  <div>
    <div class="table-head-control">
      <AppButton
        :label="$t('add_video')"
        v-bind="{ disabled: disabled || isEtcReview }"
        :tooltip="videoDisabledTooltip"
        @click="addVideo"
      />
    </div>
    <AppTable
      table-id="videos-table"
      :columns="columns"
      :rows="videos"
      :no-data="$t('no_data')"
    >
      <template #cell="{ column, rowIndex }">
        <template v-if="column.id === 'position'">{{ rowIndex + 1 }}</template>
        <template v-else-if="column.id === 'video_thumbnail'">
          <input
            type="hidden"
            name="review[videos_attributes][][id]"
            :value="videos[rowIndex].v_id"
          />
          <div class="ReviewReviewEditVideosTable__video-thumbnail">
            <AppVideoImage
              v-if="videos[rowIndex].thumbnail_url"
              :src="videos[rowIndex].thumbnail_url"
              :progress="videos[rowIndex].progress"
              :show-play-button="!videos[rowIndex].is_being_encoded"
            />
            <div
              v-else
              class="ReviewReviewEditVideosTable__video-thumbnail-no-image"
            >
              -
            </div>
          </div>
        </template>
        <template v-else-if="column.id === 'video'">
          <AppVideoInput
            :key="`review_video_file${videos[rowIndex].uniqueId}`"
            v-model="videos[rowIndex]"
            :select-on-mount="videos[rowIndex].selectOnMount"
            :disabled="videos[rowIndex].isUploading || isEtcReview"
            @progress="$set(videos[rowIndex], 'progress', $event)"
          />
        </template>
        <template v-else-if="column.id === 'actions'">
          <AppButtonDraggable :disabled="isEtcReview" @sort="sort">
            <template #drag-image>
              <img
                style="width: 136px; padding: 4px;"
                :src="videos[rowIndex].thumbnail_url"
              />
            </template>
          </AppButtonDraggable>
          <AppButton
            button-style="red-outline"
            :label="$t('app.delete')"
            @click="deleteVideoClicked(rowIndex)"
          />
        </template>
      </template>
    </AppTable>
    <input
      v-if="!videos.length"
      type="hidden"
      name="review[videos_attributes][]"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { arrayMoveIndex } from '@/lib/array';
import { deleteVideo } from '@/lib/video';

export default {
  name: 'ReviewReviewEditVideosTable',
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isEtcReview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      videos: _.cloneDeep(this.value),
      orgVideoIds: _.cloneDeep(this.value).map(v => v.v_id)
    };
  },
  computed: {
    ...mapState('session', ['reviewSettings']),
    columns() {
      return [
        { id: 'position', label: this.$t('app.position') },
        { id: 'video_thumbnail', label: this.$t('video_thumbnail') },
        this.reviewSettings.use_video_reviews
          ? { id: 'video', label: this.$t('video') }
          : null,
        { id: 'actions', label: this.$t('actions') }
      ].filter(c => c);
    },
    videoDisabledTooltip() {
      if (!this.disabled || this.isEtcReview) return '';

      if (!this.reviewSettings.use_video_reviews) {
        return this.$t('add_video_disabled_tooltip.not_use_video_reviews');
      }

      return this.$t('add_video_disabled_tooltip.max_length');
    }
  },
  watch: {
    videos: {
      handler() {
        this.$emit('change', this.videos);
      },
      deep: true
    }
  },
  methods: {
    addVideo() {
      this.videos.push({
        uniqueId: _.uniqueId('video'),
        invalid: true,
        selectOnMount: true
      });
    },
    sort({ srcIndex, dstIndex }) {
      this.videos = arrayMoveIndex(this.videos, srcIndex, dstIndex);
    },
    deleteVideoClicked(rowIndex) {
      const id = this.videos[rowIndex].v_id;
      this.videos.splice(rowIndex, 1);
      if (id && !this.orgVideoIds.includes(id)) deleteVideo(id);
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewReviewEditVideosTable__video-thumbnail {
  position: relative;
  line-height: 120px;
  font-size: 0;
}

.ReviewReviewEditVideosTable__video-thumbnail-no-image {
  font-size: 13px;
}

.ReviewReviewEditVideosTable__spinner {
  position: absolute;
  font-size: 56px;
  left: 32px;
  top: 32px;
}

::v-deep {
  .AppVideoImage__image {
    max-height: 120px;
  }
  .videos-table {
    table-layout: fixed;
  }
  .videos-table__position {
    width: 40px;
  }
  .videos-table__video-thumbnail {
    width: 136px;
    line-height: 0;
  }
  .videos-table__video {
    line-height: 0;
  }
  .videos-table__actions {
    width: 182px;
  }
}
</style>

<i18n locale="ko">
{
  "add_video": "+ 동영상 추가",
  "add_video_disabled_tooltip": {
    "max_length": "첨부 포토/동영상은 최대 4개까지 가능합니다.",
    "not_use_video_reviews": "동영상 리뷰 첨부가 켜져있지 않습니다."
  },
  "no_data": "리뷰에 첨부한 동영상 없습니다.",
  "video_thumbnail": "동영상 썸네일",
  "video": "동영상 등록",
  "actions": "이동/삭제",
  "edit_delete_button": "편집/삭제"
}
</i18n>
<i18n locale="en">
{
  "add_video": "+ Add video",
  "add_video_disabled_tooltip": {
    "max_length": "Up to 4 photos/videos can be attached.",
    "not_use_video_reviews": "Video review is not turned on."
  },
  "no_data": "No attached video on review.",
  "video_thumbnail": "Video thumbnail",
  "video": "Attach video",
  "actions": "Move/Remove",
  "edit_delete_button": "Edit/Remove"
}
</i18n>
