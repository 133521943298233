<template>
  <div class="AppTabContent">
    <AppTabs v-bind="{ tabs, activeTab }" sticky />
    <slot><RouterView /></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { pathWithParams } from '@/lib/params';

export default {
  name: 'AppTabContent',
  props: {
    menuItemId: { type: String, required: true },
    params: { type: Object, default: () => {} }
  },
  data() {
    return { activeTab: '' };
  },
  computed: {
    ...mapGetters('menu', ['selectedTabItemId', 'childTabItems']),
    tabs() {
      return this.childTabItems(this.menuItemId).map(tab => ({
        ...tab,
        path: pathWithParams(tab.path, this.params)
      }));
    }
  },
  watch: {
    selectedTabItemId() {
      this.activeTab = this.selectedTabItemId;
    }
  },
  created() {
    this.activeTab = this.selectedTabItemId;
  }
};
</script>
