var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalDefault',{attrs:{"title":_vm.$t('title')},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('AppForm',{attrs:{"sections":_vm.sections,"object":_vm.scripts},scopedSlots:_vm._u([{key:"group",fn:function(ref){
var id = ref.id;
return [(id === 'environment')?_c('div',{staticClass:"AppForm__group-field"},[_c('AppSelectRadio',{attrs:{"options":[
              {
                label: _vm.$t('environment_homepage'),
                value: 'homepage'
              },
              {
                label: _vm.$t('environment_market'),
                value: 'market'
              }
            ]},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
            var value = ref.value;
            var checked = ref.checked;
return [(value === 'market')?_c('div',[_c('div',{staticClass:"AppForm__sub-group"},[_c('label',[_c('div',{staticClass:"AppForm__sub-group-title"},[_vm._v(" "+_vm._s(_vm.$t('market_image_width'))+" ")]),_c('AppNumberInput',{staticClass:"ReviewSettingsWidgetScriptDialog__image-width",attrs:{"placeholder":_vm.$t('market_image_width_placeholder'),"disabled":!checked,"digits":4},model:{value:(_vm.scripts.image_width),callback:function ($$v) {_vm.$set(_vm.scripts, "image_width", $$v)},expression:"scripts.image_width"}})],1)]),(_vm.widget.widget_style === _vm.WidgetStyle.OPEN_MARKET)?_c('div',{staticClass:"AppForm__sub-group"},[_c('label',[_c('div',{staticClass:"AppForm__sub-group-title"},[_vm._v(" "+_vm._s(_vm.$t('market_target_widget_id'))+" ")]),_c('AppNumberInput',{staticClass:"ReviewSettingsWidgetScriptDialog__target-widget-id",attrs:{"placeholder":_vm.$t('market_target_widget_id_placeholder'),"disabled":!checked,"digits":3},model:{value:(_vm.scripts.target_widget_id),callback:function ($$v) {_vm.$set(_vm.scripts, "target_widget_id", $$v)},expression:"scripts.target_widget_id"}})],1)]):_vm._e()]):_vm._e()]}}],null,true),model:{value:(_vm.scripts.environment),callback:function ($$v) {_vm.$set(_vm.scripts, "environment", $$v)},expression:"scripts.environment"}})],1):_vm._e()]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }