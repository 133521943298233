<template>
  <div
    :class="[
      'DisplayCornerProductOverlayCheckbox',
      checked ? '' : 'DisplayCornerProductOverlayCheckbox--unchecked'
    ]"
    @click="$emit('click', $event)"
  >
    <AppSvgIcon v-if="checked" name="icon-checkbox-checker" />
  </div>
</template>

<script>
export default {
  name: 'DisplayCornerProductOverlayCheckbox',
  props: { checked: { type: Boolean, required: true } }
};
</script>

<style lang="scss" scoped>
.DisplayCornerProductOverlayCheckbox {
  width: 14px;
  height: 14px;
  margin: 3px;

  &--unchecked {
    border-radius: 2px;
    border: solid 2px;
  }
}
</style>
