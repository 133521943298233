<template>
  <div class="DisplayProductsActionsCell">
    <template v-if="product.displayStatus === 'excluded'">
      <AppButton
        :label="$t('cancel_exclude')"
        class="DisplayProductsActionsCell__button"
        button-style="red-outline"
        @click="CANCEL_EXCLUDE_PRODUCTS([product.id])"
      />
    </template>
    <template v-else-if="product.displayStatus === 'pinned'">
      <AppButton
        class="DisplayProductsActionsCell__button"
        :label="$t('unpin')"
        @click="UNPIN_PRODUCTS([product])"
      />
    </template>
    <template v-else-if="isDisplayCanceled">
      <AppButton
        class="DisplayProductsActionsCell__button"
        :label="$t('cancel_cancel_display')"
        button-style="red-outline"
        @click="openAddProductDialog"
      />
    </template>
    <template v-else>
      <div class="DisplayProductsActionsCell__button">
        <template v-if="displayed">
          <AppButton
            v-if="!isCategoryPage || addedProductIds.includes(product.id)"
            class="DisplayProductsActionsCell__button"
            :label="$t('cancel_display')"
            @click="removeProducts([product.index])"
          />
        </template>
        <template v-else>
          <AppButton
            class="DisplayProductsActionsCell__button"
            :label="$t('display')"
            :disabled="disableDisplayButton"
            :tooltip="
              disableDisplayButton ? $t('tooltip.display_disabled') : null
            "
            @click="openAddProductDialog"
          />
        </template>
      </div>
      <div class="DisplayProductsActionsCell__button">
        <AppDropdownMenuButton
          v-if="menuItems"
          class="DisplayProductsActionsCell__button"
          :label="$t('app.manage')"
          :menu-items="menuItems"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import DisplayCornerProductMarkedStatus from '@/enums/DisplayCornerProductMarkedStatus';

export default {
  name: 'DisplayProductsActionsCell',
  props: {
    product: { type: Object, required: true }
  },
  computed: {
    ...mapState('displayProductDisplay', ['categoryProductIds']),
    ...mapGetters('displayProductDisplays', ['isCategoryPage']),
    ...mapGetters('displayProductDisplay', [
      'addedProductIds',
      'removedProductIds',
      'cornerProductCategory'
    ]),
    isDisplayCanceled() {
      return (
        this.isCategoryPage && this.removedProductIds.includes(this.product.id)
      );
    },
    displayed() {
      return this.product.displayStatus === 'displayed';
    },
    hasChildCategory() {
      return this.cornerProductCategory?.hasChildCategory || false;
    },
    disableDisplayButton() {
      return (
        this.hasChildCategory &&
        !this.categoryProductIds.includes(this.product.id)
      );
    },
    menuItemIds() {
      if (this.isCategoryPage) {
        if (this.addedProductIds.includes(this.product.id))
          return ['change_position', 'pin'];
        else if (this.displayed)
          return ['change_position', 'pin', 'remove_from_category'];
        else return null;
      } else {
        if (this.displayed) return ['change_position', 'pin', 'exclude'];
        else return ['exclude'];
      }
    },
    menuItemConfigs() {
      return {
        change_position: {
          label: this.$t('change_position'),
          clickHandler: ({ close }) => {
            this.openDialog([
              'DisplayCornerSortProductDialog',
              { selectedProducts: [this.product] }
            ]);
            close();
          }
        },
        pin: {
          label: this.$t('pin'),
          clickHandler: ({ close }) => {
            this.PIN_PRODUCTS({ products: [this.product], pinUntil: '' });
            this.createToast(this.$t('toast.pin'));
            close();
          }
        },
        exclude: {
          label: this.$t('exclude'),
          style: 'danger',
          clickHandler: ({ close }) => {
            this.openDialog([
              'DisplayCornerExcludingProductDialog',
              { selectedProducts: [this.product] }
            ]);
            close();
          }
        },
        remove_from_category: {
          label: this.$t('remove_from_category'),
          style: 'danger',
          disabled: this.hasChildCategory,
          tooltip: this.hasChildCategory
            ? this.$t('tooltip.remove_from_category_disabled')
            : null,
          clickHandler: ({ close }) => {
            this.openDialog([
              'DisplayCornerRemoveProductDialog',
              { selectedProducts: [this.product] }
            ]);
            close();
          }
        }
      };
    },
    menuItems() {
      if (!this.menuItemIds) return null;

      return [
        this.displayed
          ? [
              {
                label: this.$t('move_to_preview'),
                clickHandler: ({ close }) => {
                  this.SET_MARKED_PRODUCT({
                    index: this.product.index,
                    markedStatus: DisplayCornerProductMarkedStatus.SELECTED
                  });
                  close();
                }
              }
            ]
          : [],
        this.menuItemIds.map(id => ({ id, ...this.menuItemConfigs[id] }))
      ];
    }
  },
  methods: {
    ...mapMutations('displayProductDisplay', [
      'CANCEL_EXCLUDE_PRODUCTS',
      'PIN_PRODUCTS',
      'UNPIN_PRODUCTS',
      'SET_MARKED_PRODUCT'
    ]),
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    ...mapActions('displayProductDisplay', ['removeProducts']),
    openAddProductDialog() {
      this.openDialog([
        'DisplayCornerAddProductDialog',
        { selectedProducts: [this.product] }
      ]);
    }
  }
};
</script>

<style lang="scss" scoped>
.DisplayProductsActionsCell {
  text-align: left;
}

.DisplayProductsActionsCell__button {
  width: 80px;
  & + & {
    margin-top: 4px;
  }
}
</style>

<i18n locale="ko">
{
  "display": "진열",
  "cancel_display": "진열 취소",
  "cancel_exclude": "제외 취소",
  "unpin": "고정 해제",
  "cancel_cancel_display": "제외 취소",
  "move_to_preview": "미리 보기 상품 위치로 이동",
  "change_position": "진열 위치 변경",
  "pin": "진열 고정",
  "exclude": "진열 제외",
  "remove_from_category": "카테고리 제외",
  "toast": {
    "pin": "해당 상품의 진열 위치를 고정합니다. 고정을 풀기 전까지 해당 위치에 계속해서 노출됩니다."
  },
  "tooltip": {
    "display_disabled": "메이크샵은 상품을 최하위 카테고리에만 추가할 수 있습니다.",
    "remove_from_category_disabled": "상품이 포함된 최하위 카테고리에서 제거할 수 있습니다."
  }
}
</i18n>
