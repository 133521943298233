<template>
  <div>
    <template v-for="linkStyle in LINK_STYLES">
      <div :key="linkStyle" class="ui-section">
        <div class="ui-section__title">
          <label for="basic">{{ linkStyle }}</label>
        </div>
        <div class="ui-section__item">
          <AppLink
            :link-style="linkStyle"
            href="https://www.cre.ma"
            target="_blank"
            label="Link"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UiLink',
  created() {
    this.LINK_STYLES = ['default', 'blue', 'grey'];
  }
};
</script>
