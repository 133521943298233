<template>
  <div v-tooltip="iconTooltip">
    <AppProductIcon
      v-for="icon in iconIds.slice(0, maxShowableIconsCount)"
      :key="icon"
      class="DisplayProductManagementProductIcons__icon"
      :icon-id="icon"
    /><span
      v-if="iconIds.length > maxShowableIconsCount"
      class="DisplayProductManagementProductIcons__icon"
      >{{ $t('many', [iconIds.length - maxShowableIconsCount]) }}</span
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DisplayProductManagementProductIcons',
  props: { iconIds: { type: Array, required: true } },
  computed: {
    ...mapGetters('productIcons', ['productIconImageUrl']),
    maxShowableIconsCount() {
      return 2;
    },
    iconTooltip() {
      if (this.iconIds.length <= this.maxShowableIconsCount) return;

      return {
        message: this.iconIds
          .map(
            (iconId, index) =>
              `<img src=${this.productIconImageUrl(
                iconId
              )} style="display: block; height: 16px; margin-top: ${
                index === 0 ? '2px' : '4px'
              };" />`
          )
          .join(''),
        marginTop: '2px'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.DisplayProductManagementProductIcons__icon {
  & + & {
    margin-left: 4px;
  }
}
</style>

<i18n locale="ko">
{
  "many": "외 {0}개"
}
</i18n>
