<template>
  <div>
    <div class="DisplayUnitFilterCell__unit">
      <AppSelect
        v-tooltip="
          isRecentDaysDisabled ? $t('tooltip.recent_days_disabled') : ''
        "
        :value="unit.recent_days"
        class="app-input-inline"
        :options="recentDaysOptions"
        :disabled="isRecentDaysDisabled"
        @change="updateUnit('recent_days', $event)"
      />
      <AppSelect
        :value="unit.filter_type"
        :options="filterTypeOptions"
        @change="updateUnit('filter_type', $event)"
      />
    </div>
    <div v-if="isStockFilterType" class="DisplayUnitFilterCell__unit">
      <i18n path="filter.stock_options">
        <AppNumberInput
          class="app-input-inline"
          :digits="3"
          :value="unit.stock_count"
          :invalid="!!stockCountError"
          @change="updateUnit('stock_count', $event)"
        />
        <AppSelect
          :value="unit.stock_compare_type"
          class="app-input-inline app-input-inline--left"
          :options="ValueCompareType.options()"
          @change="updateUnit('stock_compare_type', $event)"
        />
      </i18n>
    </div>
    <div v-if="isProductOptionFilterType" class="DisplayUnitFilterCell__unit">
      <i18n path="filter.product_option_options">
        <AppSelectFilterable
          class="app-input-inline DisplayUnitFilterCell__options"
          :options="productOptionTypeOptions"
          :value="unit.product_option_type_id"
          :placeholder="$t('filter.product_option_type_placeholder')"
          :invalid="!!productOptionTypeError"
          @change="updateUnit('product_option_type_id', $event)"
        />
        <AppButton
          class="app-input-inline"
          :disabled="!unit.product_option_type_id"
          :label="
            unit.product_option_value
              ? unit.product_option_value.value
              : $t('filter.product_option_value_label')
          "
          :button-style="productOptionValueError ? 'red-outline' : undefined"
          @click="openSelectProductOptionValueDialog"
        />
      </i18n>
    </div>
    <div v-if="showFilterDetail" class="DisplayUnitFilterCell__unit">
      <i18n v-if="showFilterDetail" path="unit.count">
        <AppNumberInput
          :value="unit.max_products"
          class="app-input-inline"
          :digits="3"
          :invalid="!!maxProductsError"
          @change="updateUnit('max_products', $event)"
        />
      </i18n>
      <i18n v-if="useDisplayOption" path="filter.description">
        <AppSelect
          :value="unit.sort_recent_days"
          class="app-input-inline app-input-inline--left"
          :options="sortRecentDaysOptions"
          :disabled="
            DisplayProductSortType.isSortRecentDaysDisabled(unit.sort_type)
          "
          @change="updateUnit('sort_recent_days', $event)"
        />
        <AppSelect
          :value="unit.sort_type"
          class="app-input-inline"
          :options="DisplayProductSortType.options()"
          @change="updateUnit('sort_type', $event)"
        />
      </i18n>
    </div>
    <AppFormError
      :error="
        stockCountError ||
          productOptionTypeError ||
          productOptionValueError ||
          maxProductsError
      "
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import DisplayProductSortType from '@/enums/DisplayProductSortType';
import DisplayProductFilterType from '@/enums/DisplayProductFilterType';
import ValueCompareType from '@/enums/ValueCompareType';

export default {
  name: 'DisplayUnitFilterCell',
  props: {
    unit: { type: Object, required: true },
    unitIndex: { type: Number, required: true },
    productOptionTypes: { type: Array, required: true },
    useDisplayOption: { type: Boolean, default: true },
    availableFilterTypes: { type: Array, default: null },
    errors: { type: Object, required: true }
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters(['isSuperAdminAccessible']),
    DisplayProductSortType: () => DisplayProductSortType,
    ValueCompareType: () => ValueCompareType,
    isRecentDaysDisabled() {
      return this.isStockFilterType || this.isProductOptionFilterType;
    },
    isStockFilterType() {
      return DisplayProductFilterType.isStockFilterType(this.unit.filter_type);
    },
    isProductOptionFilterType() {
      return DisplayProductFilterType.isProductOptionFilterType(
        this.unit.filter_type
      );
    },
    showFilterDetail() {
      return (
        this.useDisplayOption ||
        !(this.isStockFilterType || this.isProductOptionFilterType)
      );
    },
    defaultRecentDaysOptions() {
      return [1, 3, 7, 14, 30, 60, 90].map(day => ({
        label: this.$t('filter.recent_days', [day]),
        value: day
      }));
    },
    recentDaysOptions() {
      return this.isRecentDaysDisabled
        ? [{ label: this.$t('filter.recent_days_zero'), value: 0 }]
        : this.defaultRecentDaysOptions;
    },
    sortRecentDaysOptions() {
      return this.defaultRecentDaysOptions;
    },
    filterTypeOptions() {
      const { currentBrand, availableFilterTypes } = this;

      const options = DisplayProductFilterType.activeTypeOptions(
        currentBrand.shop_builder,
        currentBrand.logistics_solution
      );

      return availableFilterTypes
        ? options.filter(({ value }) => availableFilterTypes.includes(value))
        : options;
    },
    productOptionTypeOptions() {
      return this.productOptionTypes.map(({ name, id }) => ({
        label: name,
        value: id
      }));
    },
    stockCountError() {
      return this.error('stock_count');
    },
    productOptionTypeError() {
      return this.error('product_option_type_id');
    },
    productOptionValueError() {
      return this.error('product_option_value_id');
    },
    maxProductsError() {
      return this.error('max_products');
    }
  },
  watch: {
    unit() {
      const { recent_days } = this.unit;
      if (this.isRecentDaysDisabled) {
        if (recent_days === 0) return;

        this.updateUnit('recent_days', 0);
      }
    },
    'unit.product_option_type_id'() {
      this.updateUnit('product_option_value', null);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    error(column) {
      return this.errors[`${this.unitIndex}[${column}]`];
    },
    updateUnit(column, value) {
      this.$emit('change', { ...this.unit, [column]: value });
      this.$emit('validate-unit-field', column);
    },
    openSelectProductOptionValueDialog() {
      this.openDialog([
        'DisplaySelectProductOptionValueDialog',
        {
          productOptionType: this.productOptionTypes.find(
            t => t.id === this.unit.product_option_type_id
          ),
          productOptionValue: this.unit.product_option_value || {}
        }
      ]).then(eventBus => {
        eventBus.$on('update-product-option-value', productOptionValue =>
          this.updateUnit('product_option_value', productOptionValue)
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.DisplayUnitFilterCell__unit {
  display: flex;

  & + & {
    margin-top: 4px;
  }
}

.DisplayUnitFilterCell__options {
  max-width: 224px;
}
</style>

<i18n locale="ko">
{
  "filter": {
    "description": "를{0}{1}으로 진열",
    "recent_days": "최근 {0}일",
    "recent_days_zero": "계산 시점",
    "stock_options": "{0}개{1}인 상품",
    "product_option_options": "{0}{1}을 포함한 상품",
    "product_option_type_placeholder": "옵션명",
    "product_option_value_label": "옵션값"
  },
  "tooltip": {
    "recent_days_disabled": "해당 옵션은 계산 시점 기준만 지원합니다."
  }
}
</i18n>
