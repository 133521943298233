<template>
  <div class="AppMiniChart">
    <div v-if="showPointValue" class="AppMiniChart__point">
      <span class="AppMiniChart__label">{{ valueLabel }}</span>
      <span class="AppMiniChart__value"
        ><span class="AppMiniChart__value-number">{{ pointValue }}</span
        >{{ valueUnit }}</span
      >
    </div>
    <AppHighcharts :options="chartOptions" :callback="onChartLoaded" />
    <AppTooltip v-show="visibleTooltip" v-bind="tooltipProps" down />
  </div>
</template>

<script>
import _ from 'lodash';

const CHART_MARGIN = 5;

export default {
  name: 'AppMiniChart',
  props: {
    data: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: 'line'
    },
    focusedIndex: {
      type: Number,
      default: 0
    },
    valueLabel: {
      type: String,
      default: ''
    },
    valueUnit: {
      type: String,
      default: ''
    },
    showPointValue: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 40
    }
  },
  data() {
    return {
      chart: null,
      pointValue: 0,
      tooltipProps: {
        message: '',
        info: '',
        left: '0',
        top: `${this.height + (this.showPointValue ? 24 : 0) - 8}px`
      },
      lastIndex: -1,
      visibleTooltip: false
    };
  },
  computed: {
    chartOptions() {
      const minY = _.minBy(this.data, 'y').y;
      const maxY = _.maxBy(this.data, 'y').y;
      const marginY = (maxY - minY) * 0.05;

      const vm = this;
      return {
        chart: {
          backgroundColor: 'transparent',
          borderWidth: 0,
          type: 'line',
          margin: [CHART_MARGIN, CHART_MARGIN, CHART_MARGIN, CHART_MARGIN],
          width: 100,
          height: this.height,
          style: {
            overflow: 'visible'
          },
          skipClone: true,
          animation: false
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          labels: {
            enabled: false
          },
          tickLength: 0,
          lineWidth: 0
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          tickPositions: [],
          tickLength: 0,
          min: minY - marginY,
          max: maxY + marginY
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        exporting: false,
        plotOptions: {
          column: {
            states: {
              hover: {
                enabled: false
              },
              select: {
                borderWidth: 3,
                color: '#e3890b',
                borderColor: 'rgba(227, 137, 11, 0.5)'
              }
            }
          },
          series: {
            animation: false,
            lineWidth: 2,
            shadow: false,
            color: '#fea426',
            states: {
              hover: {
                enabled: false
              },
              select: {
                lineWidth: 2
              }
            },
            marker: {
              fillColor: '#fea426',
              radius: 0.5,
              lineWidth: 0,
              states: {
                hover: {
                  radius: 1,
                  lineWidth: 1,
                  fillColor: '#e3890b',
                  lineColor: 'rgba(227, 137, 11, 0.5)'
                },
                select: {
                  radius: 2,
                  lineWidth: 5,
                  fillColor: '#e3890b',
                  lineColor: 'rgba(227, 137, 11, 0.5)'
                }
              }
            },
            point: {
              events: {
                mouseOver: function() {
                  vm.focus(this.x);
                  vm.visibleTooltip = true;
                },
                click: function() {
                  vm.focus(this.x);
                  vm.visibleTooltip = true;
                },
                mouseOut: function() {
                  vm.focus(this.x);
                  vm.visibleTooltip = false;
                }
              }
            }
          }
        },
        series: [
          {
            data: this.data,
            type: this.type
          }
        ]
      };
    }
  },
  watch: {
    focusedIndex(index) {
      this.focus(index);
    }
  },
  methods: {
    onChartLoaded(chart) {
      this.chart = chart;
      this.focus(this.focusedIndex);
    },
    focus(index) {
      if (index !== this.lastIndex) {
        this.chart.series.forEach(item => {
          const point = item.data[index];

          this.pointValue = point.y;

          this.tooltipProps.message = point.tooltipMessage;
          this.tooltipProps.info = point.tooltipInfo;

          this.tooltipProps.left = `${point.plotX + CHART_MARGIN}px`;

          point.select();
        });
        this.lastIndex = index;
      }

      this.$emit('change', index);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppMiniChart {
  position: relative;
  display: inline-block;
}

.AppMiniChart__point {
  margin-bottom: 4px;
}

.AppMiniChart__label {
  @include text-caption-dark;
}

.AppMiniChart__value {
  margin-left: 4px;
  @include text-caption-dark;
}

.AppMiniChart__value-number {
  @include text-content-emphasis;
}
</style>
