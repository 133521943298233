<template>
  <div class="review-smart-installation-page-url-pattern-management">
    <AppInfoBox class="UrlPatternManagement__info-box">
      {{ $t('info_box.page_url_pattern') }}
    </AppInfoBox>
    <AppForm
      v-bind="formProps"
      form-style="hor"
      submit-button
      v-on="formEvents"
    />
  </div>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';
import WidgetPageType from '@/enums/WidgetPageType';
import { isRegexFormatValid } from '@/lib/regexFormatValidator';

export default {
  name: 'ReviewSmartInstallationPageUrlPatternManagement',
  mixins: [FormView],
  data() {
    return {
      defaultPageUrlPatternsMap: {}
    };
  },
  computed: {
    formSections() {
      return WidgetPageType.usingPageUrlPatterns.map(pageType => ({
        id: `sections_${pageType}`,
        label: WidgetPageType.t(pageType),
        groups: [
          {
            type: 'textarea',
            id: `${pageType}_default_url_pattern`,
            label: this.$t('sub_sections.default_url_pattern'),
            value: this.defaultPageUrlPatternsMap[pageType]?.join('\n'),
            disabled: true,
            rows: 1
          },
          {
            type: 'textarea',
            id: `${pageType}_url_pattern`,
            label: this.$t('sub_sections.url_pattern'),
            placeholder: this.$t('url_pattern.placeholder'),
            hint: this.$t('url_pattern.hint'),
            rows: 1,
            validate: [
              {
                rule: v =>
                  !v || v.split('\n').every(value => isRegexFormatValid(value)),
                errorMessage: this.$t('url_pattern.error')
              }
            ]
          }
        ]
      }));
    }
  },
  mounted() {
    api.get('/page_url_patterns').then(({ data }) => {
      WidgetPageType.usingPageUrlPatterns.forEach(pageType => {
        this.formObject[`${pageType}_url_pattern`] =
          data.page_url_pattern_map[pageType]?.join('\n') || '';
      });

      this.defaultPageUrlPatternsMap = data.default_page_url_pattern_map;
    });
  },
  methods: {
    submit() {
      this.isSubmitting = true;

      const formData = this.pageUrlPatternsFormData();
      api
        .patch('/page_url_patterns/update_page_url_patterns', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(() => {
          this.setOrgFormObject(this.formObject);
        })
        .finally(() => (this.isSubmitting = false));
    },
    setOrgFormObject(orgFormObject) {
      this.orgFormObject = orgFormObject;
    },
    pageUrlPatternsFormData() {
      const formData = new FormData();

      WidgetPageType.usingPageUrlPatterns.forEach(pageType => {
        const urlPatterns = this.formObject[`${pageType}_url_pattern`]?.split(
          '\n'
        ) || [''];

        formData.append('page_url_patterns[page_types][]', pageType);
        urlPatterns.forEach(urlPattern => {
          formData.append(
            `page_url_patterns[patterns][${pageType}][]`,
            urlPattern
          );
        });
      });

      return formData;
    }
  }
};
</script>

<style scoped>
.UrlPatternManagement__info-box {
  margin-bottom: 28px;
}
</style>

<i18n locale="ko">
{
  "info_box": {
    "page_url_pattern": "간편 설치와 반자동 설치에 활용하는 페이지별 기본 URL 패턴 확인 및 추가 URL 패턴을 설정할 수 있습니다."
  },
  "sub_sections": {
    "default_url_pattern": "기본 URL 패턴",
    "url_pattern": "추가 URL 패턴"
  },
  "url_pattern": {
    "placeholder": "추가할 URL 패턴(정규식)을 입력해주세요.",
    "hint": "여러개 입력 시 줄바꿈하여 입력해주세요.",
    "error": "잘못된 형식의 URL 패턴(정규식)입니다."
  }
}
</i18n>
