<template>
  <AppButton
    :tooltip="tooltip"
    :disabled="disabled"
    type="dropdown"
    class="AppDropdownDialogButton"
    @click="click"
  >
    <div class="AppDropdownDialogButton__label">
      {{ label || buttonLabel }}
    </div>
    <AppSvgIcon name="nav-chevron-down" class="AppDropdownDialogButton__icon" />
    <template #dropdown>
      <AppModeless v-if="isDropdownVisible" @close="close">
        <AppDropdownDialog
          :value="value"
          :dialog-items="dialogItems"
          @close="close"
          @change="$emit('change', $event)"
        />
      </AppModeless>
    </template>
  </AppButton>
</template>

<script>
export default {
  name: 'AppDropdownDialogButton',
  model: { event: 'change' },
  props: {
    value: { type: Number, default: null },
    label: { type: String, default: null },
    dialogItems: { type: Array, required: true },
    tooltip: { type: [String, Object], default: null },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      isDropdownVisible: false
    };
  },
  computed: {
    buttonLabel() {
      const selectedItem = this.dialogItems.find(i => i.value === this.value);
      const oneDepthItems = this.dialogItems.filter(i => !i.parent_value);
      const isSelectedItemOneDepth = !selectedItem.parent_value;

      if (isSelectedItemOneDepth) {
        return selectedItem.label;
      } else {
        const oneDepthItem = oneDepthItems.find(
          i => i.value === selectedItem.parent_value
        );
        return `${oneDepthItem.label} > ${selectedItem.label}`;
      }
    }
  },
  methods: {
    click() {
      this.isDropdownVisible ? this.close() : this.open();
    },
    open() {
      this.isDropdownVisible = true;
    },
    close() {
      this.isDropdownVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.AppDropdownDialogButton__label {
  padding-right: 17px;
}

.AppDropdownDialogButton__icon {
  position: absolute;
  top: 10px;
  right: 10px;
  pointer-events: none;
}
</style>
