import { constructEnum } from '@/lib/enum';

export default constructEnum('ReviewCampaignType', {
  MEMBER_REVIEW: 10,
  NTH_STEP_REVIEW: 20,

  LEGACY_MEMBER: -3,
  LEGACY_NONMEMBER: -2,
  LEGACY_OFFLINE: -1
});
