var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppModalFormLivePreview',_vm._g({attrs:{"title":_vm.$t('title'),"sub-title":_vm.subTitle,"is-loading":_vm.isLoading,"form-props":Object.assign({}, _vm.formProps, {objectId: 'product_display'}),"width":"680px","can-maximize":""},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('DisplayProductDisplayUnitsLivePreview',{attrs:{"item-list":_vm.itemList},on:{"sort":_vm.updateItemPositions}})]},proxy:true},{key:"group",fn:function(ref){
var id = ref.id;
var errors = ref.errors;
return [(id === 'category_filter_type')?[_c('AppSelectRadio',{attrs:{"name":"product_display[category_filter_type]","options":_vm.categoryFilterTypeOptions},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
var value = ref.value;
var checked = ref.checked;
return [(value === _vm.DisplayCategoryFilterType.INCLUDE)?[_c('AppSelectProductCategory',{attrs:{"name":"product_display[including_category_ids][]","disabled":!checked,"invalid":!!errors.including_category_ids,"multiple":"","showable-category-ids":_vm.displaySettings.enabled_category_ids},on:{"blur":function($event){return _vm.validateField('including_category_ids')},"change":function($event){return _vm.validateField('including_category_ids')}},model:{value:(_vm.formObject.including_category_ids),callback:function ($$v) {_vm.$set(_vm.formObject, "including_category_ids", $$v)},expression:"formObject.including_category_ids"}}),_c('AppFormError',{attrs:{"error":errors.including_category_ids}})]:_vm._e()]}}],null,true),model:{value:(_vm.formObject.category_filter_type),callback:function ($$v) {_vm.$set(_vm.formObject, "category_filter_type", $$v)},expression:"formObject.category_filter_type"}}),(!_vm.isCategoryPage)?_c('AppCheckbox',{staticClass:"AppForm__group-field DisplayProductDisplayFormDialog__checkbox",attrs:{"label":_vm.$t('form.use_excluding_category_ids.label'),"name":"product_display[use_excluding_category_ids]"},scopedSlots:_vm._u([{key:"sub-item",fn:function(ref){
var checked = ref.checked;
return [_c('AppSelectProductCategory',{attrs:{"name":"product_display[excluding_category_ids][]","disabled":!checked,"invalid":!!errors.excluding_category_ids,"multiple":"","showable-category-ids":_vm.displaySettings.enabled_category_ids},on:{"blur":function($event){return _vm.validateField('excluding_category_ids')},"change":function($event){return _vm.validateField('excluding_category_ids')}},model:{value:(_vm.formObject.excluding_category_ids),callback:function ($$v) {_vm.$set(_vm.formObject, "excluding_category_ids", $$v)},expression:"formObject.excluding_category_ids"}}),_c('AppFormHint',{attrs:{"message":_vm.$t('form.use_excluding_category_ids.description'),"disabled":!checked}}),_c('AppFormError',{attrs:{"error":errors.excluding_category_ids}})]}}],null,true),model:{value:(_vm.formObject.use_excluding_category_ids),callback:function ($$v) {_vm.$set(_vm.formObject, "use_excluding_category_ids", $$v)},expression:"formObject.use_excluding_category_ids"}}):_vm._e()]:(id === 'product_display_units')?[_c('DisplayProductDisplayUnits',{attrs:{"item-positions":_vm.formObject.itemPositions,"product-option-types":_vm.productOptionTypes,"errors":errors},on:{"update-item-list":_vm.updateItemList,"validate-unit-field":_vm.validateUnitField}})]:(id === 'least_options_count')?[_c('i18n',{attrs:{"path":"form.least_options_count.description1"}},[_c('AppNumberInput',{staticClass:"AppForm__group-field app-input-inline app-input-inline--left",attrs:{"name":"product_display[least_options_count]","digits":2,"invalid":!!errors.least_options_count},on:{"blur":function($event){return _vm.validateField('least_options_count')},"change":function($event){return _vm.validateField('least_options_count')}},model:{value:(_vm.formObject.least_options_count),callback:function ($$v) {_vm.$set(_vm.formObject, "least_options_count", $$v)},expression:"formObject.least_options_count"}})],1)]:_vm._e()]}}])},_vm.formEvents))}
var staticRenderFns = []

export { render, staticRenderFns }