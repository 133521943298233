import { constructEnum } from '@/lib/enum';

const ChannelProductFilterType = constructEnum('ChannelProductFilterType', {
  CREATED: 0,
  UPDATED: 10,
  DISPLAYED: 20,
  MOST_CLICKED: 30,
  MOST_PURCHASED: 40,
  HIGHEST_PURCHASED_PRICE: 50,
  MOST_CARTED: 60,
  HIGHEST_PURCHASE_RATE: 70,
  MOST_REVIEWED: 100,
  HIGHEST_REVIEW_SCORE: 110,
  SOLD_OUT_TO_DISPLAY: 130,
  SELECTED_PRODUCT_OPTION: 140
});

ChannelProductFilterType.dateCompareFilterTypes = [
  ChannelProductFilterType.CREATED,
  ChannelProductFilterType.UPDATED,
  ChannelProductFilterType.DISPLAYED,
  ChannelProductFilterType.SOLD_OUT_TO_DISPLAY
];

ChannelProductFilterType.valueCompareFilterTypes = [
  ChannelProductFilterType.MOST_CLICKED,
  ChannelProductFilterType.MOST_PURCHASED,
  ChannelProductFilterType.HIGHEST_PURCHASED_PRICE,
  ChannelProductFilterType.MOST_CARTED,
  ChannelProductFilterType.HIGHEST_PURCHASE_RATE,
  ChannelProductFilterType.MOST_REVIEWED,
  ChannelProductFilterType.HIGHEST_REVIEW_SCORE
];

ChannelProductFilterType.reviewRequiredFilterTypes = [
  ChannelProductFilterType.MOST_REVIEWED,
  ChannelProductFilterType.HIGHEST_REVIEW_SCORE
];

ChannelProductFilterType.isProductOptionFilterType = function(filterType) {
  return filterType === ChannelProductFilterType.SELECTED_PRODUCT_OPTION;
};

ChannelProductFilterType.isDateCompareFilterType = function(filterType) {
  return this.dateCompareFilterTypes.includes(filterType);
};

ChannelProductFilterType.isValueCompareFilterType = function(filterType) {
  return this.valueCompareFilterTypes.includes(filterType);
};

ChannelProductFilterType.isCompareDecimalValueFilterType = function(
  filterType
) {
  return ChannelProductFilterType.HIGHEST_PURCHASE_RATE === filterType;
};

ChannelProductFilterType.isReviewRequiredFilterType = function(filterType) {
  return ChannelProductFilterType.reviewRequiredFilterTypes.includes(
    filterType
  );
};

export default ChannelProductFilterType;
