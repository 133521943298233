import api from '@/lib/api';
import i18n from '@/lib/i18n';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';
import BizMessageTemplateStatus from '@/enums/BizMessageTemplateStatus';
import CremaServiceType from '@/enums/CremaServiceType';

const state = { customTemplates: [], groupTemplates: [] };

function isGroupTemplateType(templateType) {
  return BizMessageTemplateType.isGroupTemplateType(templateType);
}

const mutations = {
  SET_TEMPLATES(state, { templateType, templates }) {
    if (isGroupTemplateType(templateType)) state.groupTemplates = templates;
    else state.customTemplates = templates;
  },
  APPEND_TEMPLATE(state, { templateType, template }) {
    if (isGroupTemplateType(templateType))
      state.groupTemplates = [...state.groupTemplates, template];
    else state.customTemplates = [...state.customTemplates, template];
  },
  UPDATE_TEMPLATE(state, { templateType, template }) {
    if (isGroupTemplateType(templateType))
      state.groupTemplates = state.groupTemplates.map(t =>
        t.id === template.id ? { ...t, ...template } : t
      );
    else
      state.customTemplates = state.customTemplates.map(t =>
        t.id === template.id ? { ...t, ...template } : t
      );
  },
  DELETE_TEMPLATE(state, { templateType, id }) {
    if (isGroupTemplateType(templateType))
      state.groupTemplates = state.groupTemplates.filter(
        template => template.id !== id
      );
    else
      state.customTemplates = state.customTemplates.filter(
        template => template.id !== id
      );
  }
};

const getters = {
  getTemplates: ({ customTemplates, groupTemplates }) => templateType => {
    return isGroupTemplateType(templateType) ? groupTemplates : customTemplates;
  }
};

function bizMessageTemplateBaseUrl(templateType) {
  return isGroupTemplateType(templateType)
    ? '/biz_message/group_templates'
    : '/biz_message/templates';
}

const actions = {
  fetchTemplates: ({ commit }, { serviceType }) => {
    const templateType = BizMessageTemplateType.CUSTOM;
    return api
      .get(bizMessageTemplateBaseUrl(templateType), {
        params: { service_type: serviceType }
      })
      .then(({ data }) => {
        commit('SET_TEMPLATES', { templateType, templates: data.templates });
        commit('SET_TEMPLATES', {
          templateType: BizMessageTemplateType.GROUP,
          templates: data.group_templates
        });
      });
  },
  fetchAllGroupTemplates: ({ commit }, { serviceType }) => {
    const templateType = BizMessageTemplateType.GROUP;
    return api
      .get(bizMessageTemplateBaseUrl(templateType), {
        params: { service_type: serviceType }
      })
      .then(({ data }) =>
        commit('SET_TEMPLATES', { templateType, templates: data.templates })
      );
  },
  fetchTemplate: (_, { id, templateType }) =>
    api.get(`${bizMessageTemplateBaseUrl(templateType)}/${id}`),
  newTemplate: (_, { templateType }) =>
    api.get(`${bizMessageTemplateBaseUrl(templateType)}/new`),
  editTemplate: (_, { id, templateType }) =>
    api.get(`${bizMessageTemplateBaseUrl(templateType)}/${id}/edit`),
  createTemplate: ({ commit }, { templateType, formData, successMessage }) => {
    return new Promise(resolve => {
      api
        .post(bizMessageTemplateBaseUrl(templateType), formData, {
          successMessage
        })
        .then(({ data }) => {
          commit('APPEND_TEMPLATE', { templateType, template: data.template });
          resolve(data.template);
        });
    });
  },
  updateTemplate: (
    { commit },
    { id, templateType, formData, successMessage }
  ) => {
    return new Promise(resolve => {
      api
        .patch(`${bizMessageTemplateBaseUrl(templateType)}/${id}`, formData, {
          successMessage
        })
        .then(({ data }) => {
          commit('UPDATE_TEMPLATE', { templateType, template: data.template });
          resolve(data.template);
        });
    });
  },
  deleteTemplate: ({ commit }, { id, templateType }) => {
    api
      .delete(`${bizMessageTemplateBaseUrl(templateType)}/${id}`, {
        successMessage: i18n.t('app.deleted')
      })
      .then(() => commit('DELETE_TEMPLATE', { templateType, id }));
  },
  requestTemplateInspection: (
    { commit },
    { id, templateType, formData, successMessage }
  ) => {
    return api
      .post(
        `${bizMessageTemplateBaseUrl(templateType)}/${id}/template_inspection`,
        formData,
        {
          successMessage
        }
      )
      .then(() =>
        commit('UPDATE_TEMPLATE', {
          templateType,
          template: {
            id,
            inspection_status: BizMessageTemplateInspectionStatus.REQUESTED
          }
        })
      );
  },
  cancelTemplateInspection: (
    { commit },
    { id, templateType, successMessage }
  ) => {
    return api
      .delete(
        `${bizMessageTemplateBaseUrl(templateType)}/${id}/template_inspection`,
        {
          successMessage
        }
      )
      .then(() =>
        commit('UPDATE_TEMPLATE', {
          templateType,
          template: {
            id,
            inspection_status: BizMessageTemplateInspectionStatus.REGISTERED
          }
        })
      );
  },
  testApprovalTemplate: (
    { commit },
    { id, templateType, formData, successMessage }
  ) => {
    return api
      .post(
        `${bizMessageTemplateBaseUrl(
          templateType
        )}/${id}/template_test_approval`,
        formData,
        {
          successMessage
        }
      )
      .then(() =>
        commit('UPDATE_TEMPLATE', {
          templateType,
          template: {
            id,
            status: BizMessageTemplateStatus.READY,
            inspection_status: BizMessageTemplateInspectionStatus.APPROVED
          }
        })
      );
  },
  testRejectTemplate: (
    { commit },
    { id, templateType, comment, successMessage }
  ) => {
    return api
      .delete(
        `${bizMessageTemplateBaseUrl(
          templateType
        )}/${id}/template_test_approval`,
        { params: { comment } },
        { successMessage }
      )
      .then(() =>
        commit('UPDATE_TEMPLATE', {
          templateType,
          template: {
            id,
            status: BizMessageTemplateStatus.STOPPED,
            inspection_status: BizMessageTemplateInspectionStatus.REJECTED
          }
        })
      );
  },
  clickNewTemplate: ({ dispatch }) => {
    dispatch(
      'dialog/openDialog',
      [
        'BizMessageTemplateFormDialog',
        {
          serviceType: CremaServiceType.REVIEW,
          templateType: BizMessageTemplateType.CUSTOM,
          inspectDirectly: true
        }
      ],
      { root: true }
    );
  }
};

export default { namespaced: true, state, mutations, getters, actions };
