import { constructEnum } from '@/lib/enum';

const WidgetType = constructEnum('WidgetType', {
  REVIEWS: 0,
  PRODUCT_REVIEWS: 100,
  PRODUCTS: 300,
  MANAGING_REVIEWS: 530,
  ETC: 600,
  CATEGORY_REVIEWS: 700,
  SWITCHER: 10010
});

WidgetType.switchableWidgetType = [
  WidgetType.REVIEWS,
  WidgetType.PRODUCT_REVIEWS,
  WidgetType.PRODUCTS
];

WidgetType.smartInstallableType = [
  WidgetType.REVIEWS,
  WidgetType.PRODUCT_REVIEWS,
  WidgetType.PRODUCTS,
  WidgetType.MANAGING_REVIEWS,
  WidgetType.ETC,
  WidgetType.CATEGORY_REVIEWS
];

export default WidgetType;
