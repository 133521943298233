<template>
  <TheAccountMenuPopupService
    v-if="isChannelMenuAccessible"
    service="channel"
    :items="items"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TheAccountMenuPopupService from './TheAccountMenuPopupService';

export default {
  name: 'TheAccountMenuPopupServiceAd',
  components: { TheAccountMenuPopupService },
  computed: {
    ...mapState('session', ['channelStatus']),
    ...mapGetters('alert', ['channelRemainingDates']),
    ...mapGetters('session', [
      'isChannelTesting',
      'isChannelFreeTrial',
      'isChannelMenuAccessible'
    ]),
    items() {
      const { channel_expires_at } = this.channelStatus;

      if (this.isChannelTesting)
        return [{ title: this.$t('expires_on'), content: this.$t('testing') }];
      else if (this.isChannelFreeTrial)
        return [
          {
            title: this.$t('free_trial_expires_on'),
            content: this.formatDate(channel_expires_at),
            contentTooltip: this.expirationTooltip
          }
        ];
      else
        return [
          {
            title: this.$t('expires_on'),
            content: this.formatDate(channel_expires_at),
            contentTooltip: this.expirationTooltip
          }
        ];
    },
    expirationTooltip() {
      return this.channelRemainingDates >= 0
        ? this.$t('days_before_expiration', [this.channelRemainingDates])
        : this.$t('days_after_expiration', [-this.channelRemainingDates]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "expires_on": "만료일",
  "free_trial_expires_on": "무료체험 만료일",
  "testing": "테스트 중",
  "days_before_expiration": "잔여 {0}일",
  "days_after_expiration": "{0}일 전 만료"
}
</i18n>
