<template>
  <div class="ReviewMessagesMemberReviewCampaignForm">
    <template v-if="group.id === 'dispatch_recipent'">
      <div class="AppForm__group-field">
        <i18n :path="dispatchDescription">
          <AppSelect
            v-model="message.dispatch_days_type"
            name="message[dispatch_days_type]"
            :options="ReviewCampaignDispatchDaysType.options()"
            inline
          />
          <AppNumberInput
            v-model="message.dispatch_days"
            name="message[dispatch_days]"
            inline
            :digits="3"
            :invalid="!!group.errors.dispatch_days"
            @blur="$emit('blur', 'dispatch_days')"
            @change="$emit('change', 'dispatch_days')"
          />
        </i18n>
      </div>
      <AppFormError :error="group.errors.dispatch_days" />
      <AppFormHint
        :message="$t('dispatch_recipent.hint', [mileageExiryDays])"
      />
    </template>
    <template v-else-if="group.id === 'dispatch_hour'">
      <div class="AppForm__group-field">
        <i18n path="dispatch_hour.description">
          <AppSelectHour
            v-model="message.dispatch_hour"
            name="message[dispatch_hour]"
            :selectable-hours="SELECTABLE_DISPATCH_HOURS"
          />
        </i18n>
      </div>
      <AppFormHint :message="$t('dispatch_hour.hint')" />
    </template>
    <template v-else-if="group.id === 'biz_message_template_id'">
      <AppButton
        class="AppForm__group-field"
        :label="$t('add_template')"
        @click="clickNewTemplate"
      />
      <div class="AppForm__group-field">
        <ReviewMessagesBizMessageTemplateSelectTable
          :template="message"
          @change="changeSelectedTemplate"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ReviewCampaignDispatchDaysType from '@/enums/ReviewCampaignDispatchDaysType';

export default {
  name: 'ReviewMessagesMemberReviewCampaignForm',
  props: {
    group: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      default: () => {}
    },
    mileageExiryDays: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState('bizMessageTemplate', ['templates']),
    dispatchDescription() {
      return `dispatch_recipent.description.${ReviewCampaignDispatchDaysType.toKey(
        this.message.dispatch_days_type
      ).toLowerCase()}`;
    },
    ReviewCampaignDispatchDaysType: () => ReviewCampaignDispatchDaysType
  },
  created() {
    this.SELECTABLE_DISPATCH_HOURS = [...Array(12).keys()].map(h => h + 8);
  },
  methods: {
    ...mapActions('bizMessageTemplate', ['clickNewTemplate']),
    changeSelectedTemplate(template) {
      this.$emit('change-template', template);
    }
  }
};
</script>

<i18n locale="ko">
{
  "dispatch_recipent": {
    "description": {
      "order_completed": "{0} {1}일 지난 고객 중 리뷰 미작성 고객",
      "give_mileage_expired": "{0} {1}일 남은 고객 중 리뷰 미작성 고객"
    },
    "hint": "Tip. 현재 설정된 적립금 지급 기간은 배송 완료 후 {0}일 입니다."
  },
  "dispatch_hour": {
    "description": "{0} 시에 발송합니다.",
    "hint": "Tip. 쇼핑몰 고객이 여유를 가지고 리뷰를 작성할 수 있는 시간을 설정해보세요."
  },
  "errors": {
    "dispatch_days": "최대 365까지 입력 가능합니다."
  },
  "add_template": "+ 메시지 템플릿 생성"
}
</i18n>
