<template>
  <AppForm
    v-bind="formProps"
    form-style="hor"
    object-id="brand_csv"
    v-on="formEvents"
  >
    <template #group="{ id }">
      <template v-if="id === 'delete_all'">
        <AppAjaxContent :is-loading="isLoading" class="AppForm__group-field">
          <AppButton
            :label="$t('app.delete')"
            button-style="red-outline"
            :tooltip="
              isDeletingAllProductCategories
                ? $t('delete_product_categories.delete_all.tooltip')
                : null
            "
            :disabled="isDeletingAllProductCategories"
            @click="openDeleteProductCategoriesConfirm"
          />
        </AppAjaxContent>
      </template>
      <template v-else-if="id === 'submit_button'">
        <AppButton
          :label="$t('sync.submit')"
          button-style="blue"
          :disabled="isSubmitting"
          @click="formEventBus.$emit('submit')"
        />
        <AppButton
          :label="$t('sync.show_brand_csvs')"
          :tooltip="$t('sync.show_brand_csvs_tooltip')"
          type="old_admin_link"
          to="/admin/brand_csvs"
        />
      </template>
    </template>
  </AppForm>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import api from '@/lib/api';
import BrandCsvType from '@/enums/BrandCsvType';
import BrandCsvSort from '@/enums/BrandCsvSort';
import DialogSize from '@/enums/DialogSize';
import FormView from '@/mixins/FormView';

export default {
  name: 'ReviewMoveShopBuilderSync',
  mixins: [FormView],
  data: () => ({
    isLoading: true,
    isDeletingAllProductCategories: false,
    orgFormObject: {
      brand_csv_type: BrandCsvType.MEMBER,
      sort: BrandCsvSort.CREATED_AT,
      date_range: {
        start_date: moment()
          .subtract(7, 'days')
          .toVal(),
        end_date: moment().toVal()
      }
    }
  }),
  computed: {
    formSections() {
      return [
        {
          id: 'delete_product_categories',
          label: this.$t('delete_product_categories.label'),
          groups: [
            {
              id: 'delete_all',
              label: this.$t('delete_product_categories.delete_all.label'),
              groupDescription: this.$t(
                'delete_product_categories.delete_all.description'
              )
            }
          ]
        },
        {
          id: 'sync',
          label: this.$t('sync.label'),
          groups: [{ id: 'submit_button', label: null }]
        }
      ];
    }
  },
  mounted() {
    api
      .get('review/move_shop_builder/deleting_all_product_categories')
      .then(
        ({ data }) =>
          (this.isDeletingAllProductCategories =
            data.is_deleting_all_product_categories)
      )
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openDeleteProductCategoriesConfirm() {
      this.openDialog([
        'AppMessageDialog',
        {
          type: 'delete_confirm',
          title: this.$t('delete_product_categories.delete_all.label'),
          markdownText: this.$t('app.delete_confirm'),
          width: DialogSize.SMALL
        }
      ]).then(eventBus => eventBus.$on('close', this.deleteProductCategories));
    },
    deleteProductCategories() {
      api
        .post('review/move_shop_builder/delete_all_product_categories')
        .then(() => (this.isDeletingAllProductCategories = true));
    },
    submit() {
      this.isSubmitting = true;
      api
        .post(
          'review/move_shop_builder/sync_initial_data',
          {},
          {
            successMessage: this.$t('sync.sync_started')
          }
        )
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "delete_product_categories": {
    "label": "상품 카테고리 삭제",
    "delete_all": {
      "label": "전체 상품 카테고리 삭제",
      "description": "전체 카테고리를 일괄 삭제한 뒤, 카테고리를 다시 동기화해야합니다.",
      "tooltip": "전체 상품 카테고리가 삭제되는 중입니다."
    }
  },
  "sync": {
    "label": "데이터 동기화",
    "brand_csv_type": "데이터 종류",
    "sort": "날짜 기준",
    "date_range": "날짜",
    "submit": "전체 항목 동기화 실행",
    "sync_started": "전체 항목 동기화를 실행했습니다.",
    "show_brand_csvs": "동기화 결과 확인",
    "show_brand_csvs_tooltip": "최고관리자로 이동합니다."
  }
}
</i18n>
