<template>
  <AppBadge v-bind="badgeProps" />
</template>

<script>
import TargetCampaignType from '@/enums/TargetCampaignType';
import TargetDispatchStatus from '@/enums/TargetDispatchStatus';

export default {
  name: 'TargetDispatchStatusBadge',
  props: {
    campaignType: { type: Number, required: true },
    dispatchStatus: { type: Number, required: true }
  },
  computed: {
    badgeProps() {
      const status = this.dispatchStatus;

      const props = {};
      if (TargetDispatchStatus.isError(status)) {
        props.label = this.$t('error');
        props.badgeStyle = 'red';
        props.tooltip = TargetDispatchStatus.t(status);
      } else {
        props.label =
          TargetCampaignType.isRealtime(this.campaignType) &&
          status === TargetDispatchStatus.DISPATCHED
            ? this.$t('realtime_dispatched')
            : TargetDispatchStatus.t(status);

        switch (status) {
          case TargetDispatchStatus.DISPATCHING:
          case TargetDispatchStatus.DISPATCHED:
          case TargetDispatchStatus.PAYING:
            props.badgeStyle = 'blue';
            break;
          case TargetDispatchStatus.COMPLETE:
            props.badgeStyle = 'mint-green';
            break;
        }
      }
      return props;
    }
  }
};
</script>

<i18n locale="ko">
{
  "error": "발송실패",
  "realtime_dispatched": "발송중"
}
</i18n>
