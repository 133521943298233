<template>
  <div class="AppDataSectionItem">
    <div class="AppDataSectionItem__title">{{ title }}<slot name="info" /></div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppDataSectionItem',
  props: { title: { type: String, required: true } }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_clearfix.scss';
@import '@/scss/mixins/_texts.scss';

.AppDataSectionItem {
  padding-top: 4px;

  & + & {
    margin-top: 20px;
  }
}

.AppDataSectionItem__title {
  @include text-caption;
  margin-bottom: 4px;
}

::v-deep {
  .AppDataSectionItem__content {
    @include text-caption-title;
  }

  dl.AppDataSectionItem__description-list {
    @include text-content;
    font-weight: bold;

    div {
      padding: 12px 0;
    }

    a {
      @include clearfix;
      display: block;
    }

    dt {
      float: left;
    }

    dd {
      float: right;
    }
  }
}
</style>
