<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar>
      <template #default>
        <li v-if="isSuperAdminAccessible" class="super-admin__item">
          <AppButton
            :label="$t('button.add_bonus_transaction')"
            :disabled="!isChannelBonusAddable"
            :tooltip="
              isChannelBonusAddable ? '' : $t('add_bonus_disabled_tootip')
            "
            button-style="red"
            @click="openBonusDialog"
          />
        </li>
        <li v-if="isSuperAdminAccessible" class="super-admin__item">
          <AppButton
            :label="$t('button.add_channel_transaction')"
            @click="openAddChannelTransactionDialog"
          />
        </li>
      </template>
      <template #right>
        <li>
          <AppSelect
            v-model="resourceParams.transaction_type"
            :options="
              isSuperAdminAccessible
                ? ChannelTransactionType.options()
                : ChannelTransactionType.options().filter(
                    o => o.value !== ChannelTransactionType.SUPER_ADMIN_ETC
                  )
            "
            :placeholder="$t('transaction_type_placeholder')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppDateRangePicker
            v-model="resourceParams"
            @update="searchResource"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppResourceTable
      table-id="ad-transactions-table"
      :resources="resources"
      :rows="rows"
      :columns="columns"
      :enable-total-count="false"
      :no-data="$t('no_data')"
      @paginate="paginate"
    >
      <template #cell="{ column, value, row }">
        <template v-if="column === 'transaction_type'">
          <AppBadge
            :label="ChannelTransactionType.t(value)"
            :badge-style="ChannelTransactionType.badgeStyle(value)"
          />
        </template>
        <template v-else-if="column === 'channel_expires_at'">
          <div
            v-if="row.isExpiresAtNotChanged && value"
            v-tooltip="formatDate(value)"
          >
            {{ $t('not_changed') }}
          </div>
          <div v-else-if="value">{{ value | formatDate }}</div>
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import ChannelTransactionType from '@/enums/ChannelTransactionType';

export default {
  name: 'ThePaymentHistoryDialogChannel',
  mixins: [ResourceViewNoRoute],
  data() {
    return {
      isLoading: true,
      resources: nullResources
    };
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    ...mapGetters('session', ['isChannelBonusAddable']),
    ChannelTransactionType: () => ChannelTransactionType,
    columns() {
      return [
        {
          id: 'date',
          label: this.$t('app.date'),
          type: 'datetime',
          width: '156px'
        },
        {
          id: 'transaction_type',
          label: this.$t('column.transaction_type'),
          width: '108px'
        },
        {
          id: 'description',
          label: this.$t('app.description'),
          align: 'left',
          width: '580px'
        },
        {
          id: 'channel_expires_at',
          label: this.$t('column.expires_on'),
          default: '-',
          superAdmin: true,
          width: '108px'
        }
      ];
    },
    rows() {
      let items = this.resources.items;
      if (!this.isSuperAdminAccessible) {
        items = items.filter(
          item =>
            item.transaction_type !== ChannelTransactionType.SUPER_ADMIN_ETC
        );
      }
      return items.map(item => ({
        ...item,
        date: item.created_at,
        superAdmin:
          item.transaction_type === ChannelTransactionType.SUPER_ADMIN_ETC,
        isExpiresAtNotChanged:
          item.channel_expires_at === item.old_channel_expires_at
      }));
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/payment_history/channel_transactions', { params })
        .then(({ data }) => (this.resources = data.channel_transactions))
        .finally(() => (this.isLoading = false));
    },
    openBonusDialog() {
      this.openDialog('ThePaymentHistoryBonusChannelDialog').then(eventBus =>
        eventBus.$on('update', this.fetchFirstPage)
      );
    },
    openAddChannelTransactionDialog() {
      this.openDialog(
        'ThePaymentHistoryAddChannelTransactionDialog'
      ).then(eventBus => eventBus.$on('update', this.fetchFirstPage));
    }
  }
};
</script>

<i18n locale="ko">
{
  "button": {
    "add_bonus_transaction": "보너스 기간 충전하기",
    "add_channel_transaction": "이용내역 추가하기"
  },
  "transaction_type_placeholder": "전체 내역",
  "column": {
    "transaction_type": "유형",
    "expires_on": "만료일"
  },
  "no_data": "애드 이용내역이 없습니다.",
  "not_changed": "변경 없음",
  "add_bonus_disabled_tootip": "‘사용 중’, ‘기간 만료(사용중)‘, ‘기간 만료(관리자 차단)‘인 경우에만 추가 가능합니다."
}
</i18n>
