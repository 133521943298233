<template>
  <div class="AppCorporateNumberChangeRequest">
    <AppChangeRequestStatus
      :title="$t('title')"
      :status="status"
      :hint="hint"
    />
    <div class="AppCorporateNumberChangeRequest__button">
      <AppButton
        v-if="['requested', 'approved'].includes(status)"
        :label="$t('show_documents_button')"
        @click="clickShowDocuments"
      />
      <AppButton
        v-else
        :label="changeCorporateNumberButtonLabel"
        @click="clickChangeCorporateNumber"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppCorporateNumberChangeRequest',
  props: {
    status: { type: String, default: 'none' },
    rejectMessage: { type: String, default: '' }
  },
  computed: {
    hint() {
      return this.$t(`hint.${this.status}`, [this.rejectMessage]);
    },
    changeCorporateNumberButtonLabel() {
      return this.$t(`change_corporate_number_button.${this.status}`);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    clickShowDocuments() {
      this.openDialog(['TheBrandMaterialDialog', { type: 'corporate_cert' }]);
    },
    clickChangeCorporateNumber() {
      this.openDialog('TheCorporateNumberChangeRequestDialog').then(eventBus =>
        eventBus.$on('requested', payload => this.$emit('requested', payload))
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.AppCorporateNumberChangeRequest__button {
  margin-top: 4px;
}
</style>

<i18n locale="ko">
{
  "title": "세금계산서 발급 자료",
  "hint": {
    "none": "무통장 입금 결제를 진행하신 경우, 세금계산서 발급이 필요한 사업자 정보를 등록해주세요.",
    "requested": "크리마 운영진이 확인 후 처리해드립니다. 확인이 완료될 경우 [알림]을 통해 안내됩니다.",
    "required": "사업자등록정보가 등록되지 않은 경우 세금계산서 발행이 되지 않습니다.",
    "rejected": "변경 요청이 거절되었습니다. (사유 : {0})\n‘변경 요청’ 버튼을 눌러 다시 진행해주세요.",
    "approved": "변경이 완료되었습니다. 등록한 자료 변경은 아래 [등록한 자료 확인] 버튼을 통해 진행해주세요."
  },
  "change_corporate_number_button": {
    "none": "자료 등록",
    "required": "자료 등록",
    "rejected": "변경 요청"
  },
  "show_documents_button": "등록한 자료 확인"
}
</i18n>
