<template>
  <AppModalDefault
    :title="$t('campaign_stats')"
    :sub-title="campaign ? campaign.name : undefined"
    width="wide"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <template #default>
          <div
            v-if="isPopup || isWidget"
            class="TargetCampaignStatsDialog__searchbar"
          >
            <AppSelectButton
              v-model="samplingMethod"
              :options="sampleMethodOptions"
            />
          </div>
          <TargetCampaignStatsRecentReport
            v-bind="{ campaign, samplingMethod }"
            class="TargetCampaignStatsDialog__recent-report"
          />
          <AppGrid>
            <div class="AppGrid__col">
              <AppHeading :label="$t('date_range_stats')">
                <template #controls>
                  <AppDateRangePicker v-model="dateRange" />
                </template>
              </AppHeading>
            </div>
            <div class="AppGrid__col AppGrid__col--3">
              <TargetCampaignStatsSectionSummary
                v-bind="{ campaign, dateRange, samplingMethod }"
              />
            </div>
            <div class="AppGrid__col AppGrid__col--9">
              <TargetCampaignStatsSectionChart
                v-bind="{ campaign, dateRange, samplingMethod }"
              />
            </div>
            <div class="AppGrid__col">
              <TargetCampaignStatsSectionTable
                v-bind="{ campaign, dateRange, samplingMethod }"
              />
            </div>
          </AppGrid>
        </template>
      </AppAjaxContent>
    </template>
  </AppModalDefault>
</template>

<script>
import moment from 'moment';
import api from '@/lib/api';
import { mapGetters } from 'vuex';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';
import DialogView from '@/mixins/DialogView';
import TargetCampaignStatsSectionSummary from './TargetCampaignStatsSectionSummary';
import TargetCampaignStatsSectionChart from './TargetCampaignStatsSectionChart';
import TargetCampaignStatsRecentReport from './TargetCampaignStatsRecentReport';
import TargetCampaignStatsSectionTable from './TargetCampaignStatsSectionTable';

export default {
  name: 'TargetCampaignStatsDialog',
  components: {
    TargetCampaignStatsSectionSummary,
    TargetCampaignStatsSectionChart,
    TargetCampaignStatsRecentReport,
    TargetCampaignStatsSectionTable
  },
  mixins: [DialogView],
  props: {
    campaignId: { type: [Number, String], required: true },
    startDate: { type: String, default: '' },
    endDate: { type: String, default: '' }
  },
  data() {
    return {
      dateRange: {
        start_date: moment()
          .subtract(1, 'month')
          .toVal(),
        end_date: moment()
          .subtract(1, 'day')
          .toVal()
      },
      samplingMethod: null,
      campaign: null,
      isLoading: true
    };
  },
  computed: {
    ...mapGetters('targetCampaigns', ['isPopupCampaign', 'isWidgetCampaign']),
    sampleMethodOptions() {
      const samplingMethods = this.isPopup
        ? [
            TargetSamplingMethod.POPUP_CHECKED,
            TargetSamplingMethod.POPUP_CLICKED
          ]
        : [TargetSamplingMethod.WIDGET_CLICKED];
      return samplingMethods.map(value => ({
        label: TargetSamplingMethod.t(value),
        value,
        tooltip: TargetSamplingMethod.tooltipText(value)
      }));
    },
    isPopup() {
      return this.isPopupCampaign(this.campaign);
    },
    isWidget() {
      return this.isWidgetCampaign(this.campaign);
    }
  },
  created() {
    if (this.startDate && this.endDate) {
      this.dateRange = { start_date: this.startDate, end_date: this.endDate };
    }
  },
  mounted() {
    api
      .get(`/target/campaigns/${this.campaignId}`)
      .then(({ data }) => {
        this.campaign = data;
        this.samplingMethod = this.isWidget
          ? TargetSamplingMethod.WIDGET_CLICKED
          : this.isPopup
          ? TargetSamplingMethod.POPUP_CLICKED
          : TargetSamplingMethod.MESSAGE_CHECKED;
      })
      .finally(() => (this.isLoading = false));
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignStatsDialog__searchbar {
  text-align: right;
  margin-bottom: 12px;
}

.TargetCampaignStatsDialog__recent-report {
  margin-bottom: 24px;
}
</style>

<i18n locale="ko">
{
  "campaign_stats": "캠페인 통계",
  "date_range_stats": "기간별 통계"
}
</i18n>
