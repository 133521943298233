<template>
  <AppModalDefault :title="$t('menu.settings_dialog')" @close="close">
    <template #body>
      <AppTabs
        :tabs="childTabItems('settings_dialog')"
        :active-tab="activeTab"
        @change="setActiveTab"
      />
      <Component
        :is="activeComponent"
        :event-bus="formEventBus"
        @form-props="settingsFormProps = $event"
        @submitted="close(true)"
      />
    </template>
    <template #foot>
      <AppButtonSubmit
        v-if="settingsFormProps"
        type="button"
        :is-submitting="settingsFormProps.isSubmitting"
        :disabled="!settingsFormProps.isFormDataChanged"
        :error-summary="settingsFormProps.errorSummary"
        @click="formEventBus.$emit('submit')"
      />
      <AppButton :label="$t('app.close')" @click="close" />
    </template>
  </AppModalDefault>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import TheSettingsDialogBrand from './TheSettingsDialogBrand';
import TheSettingsDialogUser from './TheSettingsDialogUser';
import TheSettingsDialogMaterial from './TheSettingsDialogMaterial';
import TheSettingsDialogApiKey from './TheSettingsDialogApiKey';
import TheSettingsDialogThirdParty from './TheSettingsDialogThirdParty';

export default {
  name: 'TheSettingsDialog',
  components: {
    TheSettingsDialogBrand,
    TheSettingsDialogUser,
    TheSettingsDialogMaterial,
    TheSettingsDialogApiKey,
    TheSettingsDialogThirdParty
  },
  mixins: [DialogFormView],
  props: { initialTab: { type: String, default: 'settings_dialog_brand' } },
  data() {
    return {
      activeTab: this.initialTab,
      settingsFormProps: null,
      formEventBus: new Vue()
    };
  },
  computed: {
    ...mapState('session', ['adminSettings']),
    ...mapGetters('menu', ['childTabItems']),
    activeComponent() {
      switch (this.activeTab) {
        case 'settings_dialog_user':
          return 'TheSettingsDialogUser';
        case 'settings_dialog_material':
          return 'TheSettingsDialogMaterial';
        case 'settings_dialog_api_key':
          return 'TheSettingsDialogApiKey';
        case 'settings_dialog_third_party':
          return 'TheSettingsDialogThirdParty';
        default:
          return 'TheSettingsDialogBrand';
      }
    },
    isFormDataChanged() {
      return this.settingsFormProps
        ? this.settingsFormProps.isFormDataChanged
        : false;
    }
  },
  methods: {
    setActiveTab(activeTab) {
      if (this.activeTab === activeTab) return;
      if (
        this.isFormDataChanged &&
        !confirm(this.$t('app.confirm_discard_change_navigate'))
      )
        return;

      this.activeTab = activeTab;
      this.settingsFormProps = null;
    }
  }
};
</script>
