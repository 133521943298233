<template>
  <div>
    <div class="ui-section">
      <AppCopyableTextarea :value="VALUE" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiCopyableTextarea',
  data() {
    return {
      VALUE:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam consequat efficitur arcu, ac lobortis leo rutrum non. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Phasellus ac fringilla turpis, quis elementum justo. Aliquam et enim diam. Pellentesque faucibus libero risus, a laoreet velit interdum non. Ut nec sem quam. Quisque lacus nulla, aliquam gravida magna id, scelerisque luctus massa. Maecenas semper, nibh a fringilla tempus, quam tellus egestas diam, eu aliquet purus massa sit amet velit. Pellentesque faucibus lorem risus, id mollis enim venenatis sollicitudin.'
    };
  }
};
</script>
