<template>
  <div v-focus class="AppDropdownDialog dropdown__dialog" @blur="blur">
    <ul class="AppDropdownDialog__one-depth-group">
      <li
        v-for="(item, i) in oneDepthItems"
        :key="i"
        @mousemove="activateOneDepthItem(item)"
      >
        <slot :id="item.id" name="item">
          <div v-tooltip="item.tooltip">
            <button
              type="button"
              :class="buttonClass(item)"
              @click="clickItem(item)"
            >
              {{ item.label }}
            </button>
          </div>
        </slot>
      </li>
    </ul>
    <ul class="AppDropdownDialog__two-depth-group">
      <li class="AppDropdownDialog__two-depth-group-sub-title">
        {{ oneDepthActiveItem ? oneDepthActiveItem.label : '' }}
      </li>
      <li
        v-for="(item, i) in twoDepthItems"
        :key="i"
        @mousemove="activateTwoDepthItem(item)"
      >
        <slot :id="item.id" name="item">
          <div v-tooltip="item.tooltip">
            <button
              type="button"
              :class="buttonClass(item)"
              @click="clickItem(item)"
            >
              {{ item.label }}
            </button>
          </div>
        </slot>
      </li>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'AppDropdownDialog',
  model: { event: 'change' },
  props: {
    value: { type: Number, default: null },
    dialogItems: { type: Array, required: true }
  },
  data() {
    const selectedItem = this.dialogItems.find(i => i.value === this.value);
    const oneDepthItems = this.dialogItems.filter(i => !i.parent_value);
    const twoDepthItems = _.without(this.dialogItems, ...oneDepthItems);
    const isSelectedItemOneDepth = !selectedItem.parent_value;

    let oneDepthActiveIndex, twoDepthActiveIndex;
    if (isSelectedItemOneDepth) {
      oneDepthActiveIndex = oneDepthItems.findIndex(
        i => i.value === selectedItem.value
      );
      twoDepthActiveIndex = -1;
    } else {
      twoDepthActiveIndex = twoDepthItems
        .filter(i => i.parent_value === selectedItem.parent_value)
        .findIndex(i => i.value === selectedItem.value);
      oneDepthActiveIndex = oneDepthItems.findIndex(
        i => i.value === selectedItem.parent_value
      );
    }

    return {
      oneDepthActiveIndex,
      twoDepthActiveIndex,
      selectedValue: this.value
    };
  },
  computed: {
    selectedItem() {
      return this.dialogItems.find(item => item.value === this.selectedValue);
    },
    oneDepthItems() {
      return this.dialogItems
        .filter(item => !item.parent_value)
        .map(item => ({
          selected: [
            this.selectedItem.value,
            this.selectedItem.parent_value
          ].includes(item.value),
          ...item
        }));
    },
    twoDepthItems() {
      return this.dialogItems
        .filter(item => item.parent_value === this.oneDepthActiveItem?.value)
        .map(item => ({
          selected: item.value === this.selectedItem.value,
          ...item
        }));
    },
    oneDepthActiveItem: {
      get() {
        return this.oneDepthItems[this.oneDepthActiveIndex];
      },
      set(item) {
        if (item.parent_value) return;
        this.oneDepthActiveIndex = this.oneDepthItems.indexOf(item);
        this.twoDepthActiveIndex = -1;
      }
    },
    twoDepthActiveItem: {
      get() {
        return this.twoDepthItems[this.twoDepthActiveIndex];
      },
      set(item) {
        if (!item.parent_value) return;
        this.twoDepthActiveIndex = this.twoDepthItems.indexOf(item);
      }
    }
  },
  methods: {
    buttonClass(item) {
      const isItemActive = item.parent_value
        ? this.twoDepthActiveItem === item
        : this.oneDepthActiveItem === item;
      return [
        'AppDropdownDialog__dialog-link',
        { 'AppDropdownDialog__dialog-link--active': isItemActive },
        { 'AppDropdownDialog__dialog-link--selected': item.selected },
        item.class
      ];
    },
    clickItem(item) {
      this.selectedValue = item.value;
      this.$emit('change', item.value);
      this.close();
    },
    close() {
      this.$emit('close');
    },
    blur(e) {
      // Force focus to root element as long as the focus is within its decendants
      if (this.$el.contains(e.relatedTarget))
        setTimeout(() => this.$el.focus());
    },
    activateOneDepthItem(item) {
      this.oneDepthActiveItem = item;
    },
    activateTwoDepthItem(item) {
      this.twoDepthActiveItem = item;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_clearfix.scss';

.AppDropdownDialog {
  min-width: 420px;
  max-height: 210px;
  display: flex;

  &:focus {
    outline: none;
  }
}

.AppDropdownDialog__one-depth-group {
  flex: 1 0 140px;
  overflow-y: auto;
  padding: 9px 7px;
}

.AppDropdownDialog__two-depth-group {
  flex: 0 0 280px;
  overflow-y: auto;
  padding: 9px 7px;
  border-left: 1px solid $color-layout-section;
}

$dialog-item-padding: 6px 8px;

.AppDropdownDialog__two-depth-group-sub-title {
  @include text-caption;
  width: 100%;
  margin-bottom: 4px;
  padding: $dialog-item-padding;
  line-height: 19px;
}

.AppDropdownDialog__dialog-link {
  display: block;
  width: 100%;
  cursor: pointer;
  padding: $dialog-item-padding;
  position: relative;
  text-align: left;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &--active {
    background-color: $color-nav-selected;
    border-radius: 3px;
  }

  &--selected {
    &:before {
      content: '✓';
      font-family: 'LucidaGrande';
      margin-right: 4px;
    }
  }
}

.AppDropdownDialog__highlight {
  color: $color-blue;
}

.AppDropdownDialog__dialog-icon {
  position: absolute;
  top: 9px;
  right: 8px;
}
</style>
