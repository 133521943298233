<template>
  <AppModalDefault width="middle" :title="$t('title')" @close="close">
    <AppAjaxContent slot="body" :is-loading="isLoading">
      <AppInfoBox class="TheDetailCriterionDialog__information">{{
        $t('information')
      }}</AppInfoBox>
      <AppDataList :data="dataRows"/>

      <div class="TargetPaymentDetailCriterionDialog__table-title">
        {{ $t('table') }}
      </div>
      <TargetBillingPlanInfoTable :billing-plans="data.billing_plans"
    /></AppAjaxContent>
  </AppModalDefault>
</template>

<script>
import { mapGetters } from 'vuex';

import DialogView from '@/mixins/DialogView';
import api from '@/lib/api';

export default {
  name: 'TargetPaymentDetailCriterionDialog',
  mixins: [DialogView],
  data: () => ({ data: {}, isLoading: true }),
  computed: {
    ...mapGetters('session', ['activeTargetBillingPlanId']),
    dataRows() {
      if (this.isLoading) return [];

      const {
        ignore_dau,
        billing_plans,
        next_dau_calculation_date,
        dau_start_date,
        dau_end_date,
        dau
      } = this.data;
      const activeBillingPlan = billing_plans.find(
        plan => plan.id === this.activeTargetBillingPlanId
      );
      const dauInterval = this.$t('dau_interval', [
        activeBillingPlan.minimum_dau,
        activeBillingPlan.maximum_dau
      ]);
      return [
        {
          label: this.$t('dau_period.label'),
          type: 'date_period',
          value: [dau_start_date, dau_end_date]
        },
        {
          label: this.$t('dau_average.label'),
          value: dau,
          type: 'number'
        },
        {
          label: this.$t('using_plan.label'),
          value: ignore_dau
            ? this.$t('using_plan.ignore_dau', [dauInterval])
            : dauInterval
        },
        {
          label: this.$t('next_dau_calculation_date.label'),
          value: next_dau_calculation_date,
          type: 'date'
        }
      ];
    }
  },
  created() {
    api
      .get('/target/payment_criterion')
      .then(({ data }) => (this.data = data))
      .finally(() => (this.isLoading = false));
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheDetailCriterionDialog__information {
  white-space: pre-line;
  margin-bottom: 16px;
}

.TargetPaymentDetailCriterionDialog__table-title {
  @include text-sub-title;
  margin: 16px 0;
}
</style>

<i18n locale="ko">
{
  "title": "타겟 이용료 산정 기준",
  "information": "타겟 이용료는 최근 3개월 쇼핑몰 일일 사용자 수 (DAU) 기준으로 산정되며\n노출 후 결제가 완료되기까지 한달에 한번 DAU 재계산을 통해 요금표를 업데이트 합니다.",
  "dau_interval": "DAU {0} 이상 {1} 미만",
  "dau_period": {
    "label": "측정기간"
  },
  "dau_average": {
    "label": "평균 DAU"
  },
  "using_plan": {
    "label": "적용 월 이용료 구간",
    "ignore_dau": "{0} (운영자에 의한 이용료 구간 고정)"
  },
  "next_dau_calculation_date": {
    "label": "다음 이용료 재계산 및 업데이트 일(00시)"
  },
  "table": "이용료 기준표"
}
</i18n>
