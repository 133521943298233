import { constructEnum } from '@/lib/enum';
import TargetSubReportType from './TargetSubReportType';
import TargetCampaignMediaType from './TargetCampaignMediaType';

const TargetSubReport = constructEnum('TargetSubReport', {
  PC_MOBILE: 10,
  DAYS: 20,
  MEDIA: 30
});

TargetSubReport.subReportTypes = (subReport, campaignMediaType) => {
  switch (subReport) {
    case TargetSubReport.PC_MOBILE:
      return [TargetSubReportType.PC, TargetSubReportType.MOBILE];
    case TargetSubReport.DAYS:
      switch (campaignMediaType) {
        case TargetCampaignMediaType.DIRECT_MESSAGE:
        case TargetCampaignMediaType.POPUP:
          return [
            TargetSubReportType.D1,
            TargetSubReportType.D3,
            TargetSubReportType.D7
          ];
        default:
          return [];
      }
    case TargetSubReport.MEDIA:
      switch (campaignMediaType) {
        case TargetCampaignMediaType.DIRECT_MESSAGE:
          return [TargetSubReportType.SMS, TargetSubReportType.EMAIL];
        default:
          return [];
      }
    default:
      return [];
  }
};

export default TargetSubReport;
