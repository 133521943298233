<template>
  <AppModalDefault
    class="ImwebApiKeyFormDialog"
    :title="$t('title')"
    @close="close"
  >
    <template #body>
      <AppAjaxContent :is-loading="isLoading">
        <AppContainer class="ImwebApiKeyFormDialog__summary">
          <div class="ImwebApiKeyFormDialog__summary-title">
            {{ $t('summary.title') }}
          </div>
          <div>{{ $t('summary.desc') }}</div>
          <AppButton type="external_link" :to="imwebAdminUrl">
            {{ $t('summary.button_label') }}
            <AppExternalLinkIcon />
          </AppButton>
        </AppContainer>
        <AppForm
          class="ImwebApiKeyFormDialog__form"
          v-bind="formProps"
          v-on="formEvents"
        />
      </AppAjaxContent>
    </template>
    <template #foot>
      <div class="ImwebApiKeyFormDialog__button-box">
        <AppButtonSubmit
          v-bind="formProps"
          @click="formProps.eventBus.$emit('submit')"
        />
        <AppButton :label="$t('app.close')" @click="close" />
      </div>
    </template>
  </AppModalDefault>
</template>

<script>
import api from '@/lib/api';
import DialogView from '@/mixins/DialogView';
import FormView from '@/mixins/FormView';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ImwebApiKeyFormDialog',
  mixins: [DialogView, FormView],
  data() {
    return {
      isLoading: false,
      isImwebApiKeyValid: true
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'api_key',
              label: this.$t('api_key.label'),
              type: 'text',
              placeholder: this.$t('api_key.placeholder'),
              validate: [
                'required',
                {
                  rule: () => this.isImwebApiKeyValid,
                  errorMessage: this.$t('api_key_error')
                }
              ]
            },
            {
              id: 'secret_key',
              label: this.$t('secret_key.label'),
              type: 'text',
              placeholder: this.$t('secret_key.placeholder'),
              validate: [
                'required',
                {
                  rule: () => this.isImwebApiKeyValid,
                  errorMessage: this.$t('api_key_error')
                }
              ]
            }
          ]
        }
      ];
    },
    imwebAdminUrl() {
      return `${this.currentBrand.url}/admin/config/rest_v2`;
    }
  },
  watch: {
    'formObject.api_key'() {
      this.isImwebApiKeyValid = true;
    },
    'formObject.secret_key'() {
      this.isImwebApiKeyValid = true;
    }
  },
  mounted() {
    this.isLoading = true;
    api
      .get('/imweb/api_keys')
      .then(({ data }) => {
        this.orgFormObject = {
          api_key: data.api_key,
          secret_key: data.secret_key
        };
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions('toast', ['createToast']),
    ...mapActions('session', ['fetchSession']),
    submit(formData) {
      this.isLoading = true;
      api
        .patch('/imweb/api_keys', formData, { silent: true })
        .then(() => {
          this.fetchSession();
          this.createToast(this.$t('app.saved'));
          this.close(true);
        })
        .catch(error => {
          if (
            error.response.data.errors[0] ===
            this.$t('ImwebApiResponseCode.INVALID_API_KEY_OR_SECRET')
          ) {
            this.isImwebApiKeyValid = false;
            this.validateField('api_key');
            this.validateField('secret_key');
          } else error.errorHandler();
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.ImwebApiKeyFormDialog__summary {
  @include text-content;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  white-space: pre-line;
}

.ImwebApiKeyFormDialog__summary-title {
  @include text-label;
}

.ImwebApiKeyFormDialog__form {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "title": "아임웹 Api Key입력",
  "api_key": {
    "label": "아임웹 Api Key",
    "placeholder": "아임웹에서 부가서비스 신청 시 발급받은 Rest Api Key를 입력해주세요."
  },
  "secret_key": {
    "label": "아임웹 Secret Key",
    "placeholder": "아임웹에서 부가서비스 신청 시 발급받은 Secret Key를 입력해주세요."
  },
  "summary": {
    "title": "안내",
    "desc": "등록된 아임웹 Api Key와 Secret Key가 유효하지 않습니다.\n아임웹 관리자 페이지에서 부가서비스 사용을 위한 Rest Api Key 및 Secret Key를 확인 후 재입력 해 주세요.",
    "button_label": "아임웹 관리자 페이지 바로가기"
  },
  "api_key_error": "Api Key 또는 Secret Key가 유효하지 않습니다."
}
</i18n>
