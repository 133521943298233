<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{
      ...formProps,
      objectId: OBJECT_ID,
      submitLabel: $t('form.submit')
    }"
    v-on="formEvents"
  >
    <template #head>
      <AppContainer>
        <AppDataList :data="BOX_DATA">
          <template #value="{ id }">
            <div v-if="id === 'telecom_cert'">
              <div>{{ $t('form.telecom_cert.label') }}</div>
              <div class="mt4">
                <AppButton
                  :label="$t('box.telecom_cert.button')"
                  @click="openDialog('ThePhoneChangeInfoDialog')"
                />
              </div>
            </div>
            <div v-else-if="id === 'extra_docs'">
              <div>{{ $t('box.extra_docs.description') }}</div>
              <div class="mt4">
                <ul>
                  <li
                    v-for="(list, i) in $t('box.extra_docs.list')"
                    :key="i"
                    class="app-list__item"
                  >
                    {{ list }}
                  </li>
                </ul>
              </div>
            </div>
            <div v-else-if="id === 'bylaws'">
              <ul>
                <li
                  v-for="(list, i) in $t('box.bylaws.list')"
                  :key="i"
                  class="app-list__item"
                >
                  {{ list }}
                </li>
              </ul>
            </div>
          </template>
        </AppDataList>
      </AppContainer>
    </template>
    <template #group="group">
      <template v-if="group.id === 'extra_docs'">
        <div v-for="n in 3" :key="n" class="AppForm__group-field">
          <AppFileInput
            :id="n === 1 ? group.inputId : null"
            :name="`${OBJECT_ID}[extra_docs][]`"
            :placeholder="$t('form.docs_placeholder')"
            :accept="DOCS_ACCEPT"
          />
        </div>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ThePhoneChangeRequestDialog',
  mixins: [DialogFormView],
  data() {
    return {
      OBJECT_ID: 'brand_phone_change_request',
      DOCS_ACCEPT: 'image/jpg,image/png,image/gif,application/pdf',
      BOX_DATA: [
        {
          label: this.$t('box.info.label'),
          value: this.$t('box.info.description'),
          type: 'pre'
        },
        { id: 'telecom_cert', label: this.$t('box.telecom_cert.label') },
        { id: 'extra_docs', label: this.$t('box.extra_docs.label') },
        { id: 'bylaws', label: this.$t('box.bylaws.label') }
      ]
    };
  },
  computed: {
    formSections() {
      return [
        {
          id: 'basic_section',
          groups: [
            {
              id: 'phone',
              type: 'text',
              label: this.$t('form.phone.label'),
              placeholder: this.$t('form.phone.placeholder'),
              validate: ['required', 'korean_phone_format']
            },
            {
              id: 'telecom_cert',
              type: 'file',
              label: this.$t('form.telecom_cert.label'),
              placeholder: this.$t('form.docs_placeholder'),
              hint: this.$t('form.telecom_cert.hint'),
              accept: this.DOCS_ACCEPT,
              validate: ['required']
            },
            {
              id: 'extra_docs',
              label: this.$t('form.extra_docs.label'),
              groupDescription: this.$t('form.extra_docs.group_description'),
              hint: this.$t('form.extra_docs.hint')
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/brand_phone_change_requests', formData, {
          successMessage: this.$t('requested')
        })
        .then(({ data }) => {
          this.close(true);
          this.eventBus.$emit('requested', data.brand_phone_change_request);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "쇼핑몰 대표 전화번호 변경 요청",
  "box": {
    "info": {
      "label": "안내",
      "description": "요청 내용은 크리마 관리자가 확인 후 등록해드립니다.\n번호 등록된 이후부터 시스템 내 문자 발송 시스템을 이용할 수 있습니다."
    },
    "telecom_cert": {
      "label": "필수 첨부 자료",
      "button": "발급 방법 안내"
    },
    "extra_docs": {
      "label": "추가 첨부 자료",
      "description": "통신서비스 가입자명이 쇼핑몰의 사업자명 혹은 대표자 명의가 아닌 경우 추가 첨부 자료가 필요합니다.",
      "list": [
        "가족 명의 번호를 사용하는 경우: 가족관계증명서",
        "CS 대행으로 명의가 다른 경우: CS 대행 계약서",
        "그 외 상황으로 명의가 다른 경우: 명의자로부터 번호 사용에 대해 위임받은 위임장"
      ]
    },
    "bylaws": {
      "label": "발신번호 등록 형식",
      "list": [
        "메시지 수신자가 실제 발신(통화)이 가능한 번호를 입력해주세요.",
        "하이픈(-)을 제외하고 숫자만 입력해주세요.",
        "유선 번호 : 02******** (지역번호 포함)",
        "휴대폰 번호 : 010********",
        "전국 대표번호 : 15******, 16******, 18****** (지역번호 미포함)",
        "0N0 계열 번호 : 080******* (지역번호 미포함)"
      ]
    }
  },
  "form": {
    "phone": {
      "label": "새로운 쇼핑몰 대표 전화번호",
      "placeholder": "전화번호를 입력해주세요."
    },
    "telecom_cert": {
      "label": "통신서비스 이용증명원(가입증명서)",
      "hint": "가입자명과 번호가 가림 처리되지 않은 자료를 첨부해주세요.\n이미지 파일은 jpg, jpeg, png, gif 확장자만 사용 가능합니다."
    },
    "extra_docs": {
      "label": "추가 첨부 자료",
      "group_description": "파일은 최대 3개까지 첨부할 수 있습니다.",
      "hint": "이미지 파일은 jpg, jpeg, png, gif 확장자만 사용 가능합니다."
    },
    "docs_placeholder": "이미지 혹은 PDF 파일을 등록해주세요.",
    "submit": "변경 요청"
  },
  "requested": "쇼핑몰 대표 전화번호 변경을 요청했습니다."
}
</i18n>
