<template>
  <AppModalDefault
    :title="$t('title')"
    width="middle"
    hide-head-close-button
    @close="close"
  >
    <template #body>
      <AppText :text="$t('body.guide')" />
      <AppText :text="$t('body.location_section_title')" />
      <AppImage
        class="TheEasyJoinErrorCafe24Dialog__guide-image"
        src="https://assets.cre.ma/m/admin/v2/easy-join-cafe24-guide-1-v1.png"
        :resolutions="[2, 3]"
      />
      <AppText :text="$t('body.required_info_section_title')" />
      <AppImage
        class="TheEasyJoinErrorCafe24Dialog__guide-image"
        src="https://assets.cre.ma/m/admin/v2/easy-join-cafe24-guide-2-v1.png"
        :resolutions="[2, 3]"
      />
    </template>
    <template #foot>
      <AppButton
        button-style="blue"
        :label="$t('app.confirmed')"
        @click="close"
      />
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';

export default {
  name: 'TheEasyJoinErrorCafe24Dialog',
  mixins: [DialogView]
};
</script>

<style lang="scss" scoped>
.TheEasyJoinErrorCafe24Dialog__guide-image {
  margin: 10px 0 24px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>

<i18n locale="ko">
{
  "title": "쇼핑몰 필수정보 가이드",
  "body": {
    "guide": "크리마에서는 카페24의 관리자페이지에서 정보를 자동으로 불러오므로 모든 정보가 입력되어있어야 정상적으로 가입이 가능합니다.\n아래 위치에서 쇼핑몰 URL을 포함한 필수정보를 모두 입력해주세요.\n\n",
    "location_section_title": "&lt;위치 안내&gt;\n카페24 관리자 페이지: 쇼핑몰 설정 > 기본 설정 > 내 쇼핑몰 정보",
    "required_info_section_title": "&lt;필수입력 안내&gt;"
  }
}
</i18n>
