<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppTipBox text="Tip Box Basic Usage Text" />
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">With Html</label>
      </div>
      <AppTipBox text="Tip Box <b>with Html</b> Text" />
    </div>
  </div>
</template>

<script>
export default { name: 'UiTipBox' };
</script>
