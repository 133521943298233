<template>
  <AppButton
    :disabled="disabled"
    :label="label"
    @click="openSelectProductDialog"
  />
</template>

<script>
import { mapActions } from 'vuex';
import ProductSelectable from '@/mixins/ProductSelectable';

export default {
  name: 'AppProductSelectButton',
  mixins: [ProductSelectable],
  props: {
    title: {
      type: String,
      default() {
        return this.$t('select_product');
      }
    },
    subTitle: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default() {
        return this.$t('select_product');
      }
    },
    defaultFilters: {
      type: Array,
      default() {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, Array],
      default() {
        return this.multiple ? [] : null;
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    eventBus: {
      type: Object,
      default: null
    }
  },
  data() {
    return { products: [] };
  },
  created() {
    if (this.eventBus)
      this.eventBus.$on('open-dialog', this.openSelectProductDialog);

    if (this.value)
      this.products = this.multiple
        ? this.value.map(id => ({ id }))
        : [{ id: this.value }];
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openSelectProductDialog() {
      this.openDialog([
        'AppSelectSearchableDialog',
        {
          title: this.title,
          subTitle: this.subTitle,
          searchTypes: this.productSearchTypes,
          items: this.products,
          columns: this.productColumns,
          selectButtonLabel: this.label,
          multiple: this.multiple
        }
      ]).then(eventBus => {
        eventBus.$on('search', this.searchProducts);
        eventBus.$on('select', products => {
          this.products = products;
          this.$emit('select', this.multiple ? products : products[0]);
        });
      });
    }
  }
};
</script>

<i18n locale="ko">
{
  "select_product": "상품선택"
}
</i18n>
