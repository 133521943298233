<template>
  <TargetReportsDispatchResultDialogTable
    table-id="dispatch-result-items-biz-message-table"
    v-bind="{
      isLoading,
      cohortItems,
      columns,
      itemStatusOptions,
      dispatchMethod
    }"
    :item-status="resourceParams.item_status"
    @change-item-status="changeItemStatus"
    @paginate="paginate"
  />
</template>

<script>
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import TargetDispatchMethod from '@/enums/TargetDispatchMethod';
import TargetCohortItemStatus from '@/enums/TargetCohortItemStatus';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import TargetReportsDispatchResultDialogTable from './TargetReportsDispatchResultDialogTable';

export default {
  name: 'TargetReportsDispatchResultDialogTableBizMessage',
  components: { TargetReportsDispatchResultDialogTable },
  mixins: [ResourceViewNoRoute],
  props: { cohort: { type: Object, required: true } },
  data: () => ({ isLoading: true, cohortItems: nullResources }),
  computed: {
    dispatchMethod: () => TargetDispatchMethod.BIZ_MESSAGE,
    columns() {
      return [
        { id: 'id' },
        { id: 'date' },
        { id: 'user' },
        { id: 'message_id', label: this.$t('table.message_id') },
        { id: 'message' },
        { id: 'status' }
      ];
    },
    itemStatusOptions() {
      return TargetCohortItemStatus.values
        .filter(
          v =>
            ![
              TargetCohortItemStatus.SENDING,
              TargetCohortItemStatus.DUPLICATE_MSG_ID,
              TargetCohortItemStatus.LMS_NOT_ALLOWED
            ].includes(v)
        )
        .map(value => ({
          value,
          label: TargetCohortItemStatus.translateForDispatchMethod(
            value,
            TargetDispatchMethod.BIZ_MESSAGE
          )
        }));
    }
  },
  methods: {
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/target/cohorts/${this.cohort.id}/items/biz_message`, { params })
        .then(({ data }) => (this.cohortItems = data.cohort_items))
        .finally(() => (this.isLoading = false));
    },
    changeItemStatus(status) {
      this.resourceParams.item_status = status;
      this.searchResource();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dispatch-result-items-biz-message-table__id {
    flex-basis: 90px;
  }

  .dispatch-result-items-biz-message-table__date {
    flex-basis: 200px;
  }

  .dispatch-result-items-biz-message-table__user {
    flex-basis: 170px;
  }

  .dispatch-result-items-biz-message-table__message-id {
    flex-basis: 120px;
  }

  .dispatch-result-items-biz-message-table__message {
    flex-basis: 204px;
  }

  .dispatch-result-items-biz-message-table__status {
    flex-basis: 150px;
  }
}
</style>

<i18n locale="ko">
{
  "table": {
    "message_id": "메시지 ID"
  }
}
</i18n>
