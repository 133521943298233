<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    :form-props="{
      ...formProps,
      detectFormDataChange: false,
      objectId: 'insight_transaction'
    }"
    v-on="formEvents"
  >
    <input
      type="hidden"
      name="insight_transaction[description]"
      :value="description"
    />
  </AppModalForm>
</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/lib/api';
import InsightTransactionType from '@/enums/InsightTransactionType';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ThePaymentHistoryAddInsightTransactionDialog',
  mixins: [DialogFormView],
  data() {
    return {
      orgFormObject: { transaction_type: InsightTransactionType.PAYMENT }
    };
  },
  computed: {
    description() {
      return (
        this.formObject.description ||
        this.transactionTypeLabel(this.formObject.transaction_type)
      );
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'transaction_type',
              label: this.$t('form.transaction_type.label'),
              type: 'select',
              options: [
                InsightTransactionType.PAYMENT,
                InsightTransactionType.RETURN,
                InsightTransactionType.ETC
              ].map(value => ({
                label: this.transactionTypeLabel(value),
                value
              }))
            },
            {
              id: 'description',
              name: null,
              label: this.$t('form.description.label'),
              type: 'text',
              placeholder: this.transactionTypeLabel(
                this.formObject.transaction_type
              )
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapMutations('session', ['SET_INSIGHT_STATUS']),
    transactionTypeLabel(transactionType) {
      return this.$t(
        `form.transaction_type.${InsightTransactionType.key(transactionType)}`
      );
    },
    submit(formData) {
      this.isSubmitting = true;
      api
        .post('/payment_history/insight_transactions', formData, {
          successMessage: this.$t('app.saved')
        })
        .then(({ data }) => {
          if (data.insight_status) this.SET_INSIGHT_STATUS(data.insight_status);
          this.eventBus.$emit('update');
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "이용내역 추가하기",
  "sub_title": "인사이트 이용내역",
  "form": {
    "transaction_type": {
      "label": "항목 선택",
      "PAYMENT": "서비스 결제내역 추가",
      "RETURN": "서비스 결제 취소 내역 추가",
      "ETC": "기타 이용내역 추가"
    },
    "description": {
      "label": "메시지 입력"
    }
  }
}
</i18n>
