<template>
  <AppAjaxContent :is-loading="isLoading" class="TheSettingsDialogApiKey">
    <AppButton
      button-style="red"
      :label="$t('button.issue')"
      :disabled="!!apiKey"
      :tooltip="apiKey ? $t('tooltip.issue') : null"
      @click="openApiKeyFormDialog"
    />
    <AppButton
      v-if="isSuperAdminAccessible"
      class="super-admin__item"
      button-style="red"
      :label="$t('button.shop_builder_issue')"
      :disabled="!!shopBuilderApiKey"
      :tooltip="
        shopBuilderApiKey
          ? $t('tooltip.shop_builder_disabled')
          : $t('tooltip.shop_builder_issue')
      "
      @click="openShopBuilderApiKeyFormDialog"
    />
    <AppResourceTable
      class="TheSettingsDialogApiKey__table"
      table-id="api-key-setting-table"
      :columns="columns"
      :rows="rows"
      :enable-total-count="false"
      :no-data="$t('no_data')"
    >
      <template #cell="{ column, row }">
        <template v-if="column === 'updated_at'">
          {{ row.updated_at }}
          <AppSvgIcon
            v-tooltip="
              $t('tooltip.updated_at', [row.updated_at, row.created_at])
            "
            name="icon-info-tooltip"
            class="TheSettingsDialogApiKey__icon"
          />
        </template>
        <div v-else-if="column === 'manage'">
          <AppButton
            :label="$t('button.checked')"
            @click="openApiKeyInfoDialog(row)"
          />
          <AppDropdownMenuButton
            :label="$t('button.manage')"
            :menu-items="[
              [
                {
                  label: $t('button.edit'),
                  clickHandler: () => openApiKeyEditDialog(row)
                },
                {
                  label: $t('button.delete'),
                  style: 'danger',
                  clickHandler: () => confirmDeleteApiKey(row)
                }
              ]
            ]"
          />
        </div>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/lib/api';
import { mapActions } from 'vuex';
import UserRole from '@/enums/UserRole';
import ReviewApiUsageType from '@/enums/ReviewApiUsageType';

export default {
  name: 'TheSettingsDialogApiKey',
  data() {
    return { isLoading: true, apiKey: null, shopBuilderApiKey: null };
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    columns() {
      return ['name', 'updated_at', 'ability', 'manage'].map(column => ({
        id: column,
        label: this.$t(`columns.${column}`)
      }));
    },
    rows() {
      return [this.apiKey, this.shopBuilderApiKey]
        .filter(
          v => v && (!this.isShopBuilderKey(v) || this.isSuperAdminAccessible)
        )
        .map(key => ({
          ...key,
          created_at: this.formatDateTime(key.created_at),
          updated_at: this.formatDateTime(key.updated_at) || '-',
          ability: ReviewApiUsageType.t(key.review_api_usage_type),
          superAdmin: this.isShopBuilderKey(key)
        }));
    }
  },
  created() {
    api
      .get('/user/api_key')
      .then(({ data }) => {
        this.apiKey = data.api_keys.find(key => !this.isShopBuilderKey(key));
        this.shopBuilderApiKey = data.api_keys.find(key =>
          this.isShopBuilderKey(key)
        );
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('dialog', ['confirm', 'openDialog']),
    confirmDeleteApiKey(row) {
      this.confirm({
        type: 'delete_confirm',
        title: this.$t('confirm.title'),
        message: this.$t('confirm.message', [row.name])
      }).then(result => {
        if (!result) return;

        this.isShopBuilderKey(row)
          ? this.deleteShopBuilderApiKey()
          : this.deleteApiKey();
      });
    },
    deleteApiKey() {
      api
        .delete('/user/api_key', { successMessage: this.$t('app.deleted') })
        .then(() => {
          this.apiKey = null;
        });
    },
    deleteShopBuilderApiKey() {
      api
        .delete('/user/api_key/destroy_shop_builder_key', {
          successMessage: this.$t('app.deleted')
        })
        .then(() => {
          this.shopBuilderApiKey = null;
        });
    },
    openApiKeyInfoDialog(row) {
      this.openDialog(['TheSettingsDialogApiKeyInfoDialog', { apiKey: row }]);
    },
    openApiKeyEditDialog(row) {
      this.isShopBuilderKey(row)
        ? this.openShopBuilderApiKeyFormDialog()
        : this.openApiKeyFormDialog();
    },
    openApiKeyFormDialog() {
      this.openDialog([
        'TheSettingsDialogApiKeyFormDialog',
        { apiKey: this.apiKey }
      ]).then(eventBus => {
        eventBus.$on('update', data => (this.apiKey = data));
      });
    },
    openShopBuilderApiKeyFormDialog() {
      this.openDialog('TheSettingsDialogShopBuilderApiKeyFormDialog').then(
        eventBus => {
          eventBus.$on('update', data => (this.shopBuilderApiKey = data));
        }
      );
    },
    isShopBuilderKey(key) {
      return key.role === UserRole.SHOP_BUILDER;
    }
  }
};
</script>

<style lang="scss" scoped>
.TheSettingsDialogApiKey__table {
  margin-top: 24px;
}

.TheSettingsDialogApiKey__icon {
  position: relative;
  top: 2px;
}

::v-deep {
  // HACK - AppDropdownMenuButton의 dropdown을 위해 최소 공간을 확보하자.
  .AppResourceTable__body {
    min-height: 200px;
  }

  .api-key-setting-table__updated-at {
    flex: 1 0 100px;
  }

  .api-key-setting-table__manage {
    flex: 1 0 100px;
  }
}
</style>

<i18n locale="ko">
{
  "button": {
    "issue": "API키 발급",
    "shop_builder_issue": "동기화용 API키 발급",
    "checked": "키 확인",
    "manage": "관리",
    "edit": "수정",
    "delete": "API키 삭제"
  },
  "no_data": "해당 관리자 계정으로 발급된 API키가 없습니다.",
  "columns": {
    "name": "이름",
    "updated_at": "최근 수정일",
    "ability": "권한",
    "manage": "관리"
  },
  "tooltip": {
    "shop_builder_issue": "[최고관리자] 호스팅 사 동기화를 위한 목적으로만 사용합니다.",
    "shop_builder_disabled": "쇼핑몰 당 1개의 API키만 발급 가능합니다.",
    "issue": "관리자 계정 당 1개의 API키만 발급 가능합니다.",
    "updated_at": "최근 수정: {0}<br />최초 생성: {1}"
  },
  "confirm": {
    "title": "크리마 API키를 삭제하시겠습니까?",
    "message": "{0}를 삭제합니다.<br />삭제 시 복구할 수 없으며 해당 키를 사용하는 모든 동작이 정지됩니다.<br />삭제 하시겠습니까?"
  }
}
</i18n>
