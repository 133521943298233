import TargetWidgetStyle from '@/enums/TargetWidgetStyle';
import TargetWidgetHorizontalScrollButtonStyle from '@/enums/TargetWidgetHorizontalScrollButtonStyle';
import TargetWidgetProductInformationDisplayStyle from '@/enums/TargetWidgetProductInformationDisplayStyle';

export default {
  computed: {
    basicGroupOptions() {
      return {
        widget_style: {
          type: 'select',
          options: TargetWidgetStyle.availableOptions(
            this.campaign.campaign_type,
            !this.isPc
          )
        },
        ...this.horizontalStyleOptions,
        ...this.listStyleOptions,
        product_information_display_style: {
          type: 'select',
          options: TargetWidgetProductInformationDisplayStyle.optionsByDeviceType(
            this.isCartPopupWidgetCampaign ? false : this.isPc
          )
        },
        products_count_per_row:
          !this.isCartPopupWidgetCampaign && this.isPc
            ? {
                type: 'number',
                digits: 3,
                validate: [
                  'required',
                  { rule: 'min_max_integer', min: 1, max: 7 }
                ]
              }
            : {
                type: 'select',
                options: [2, 3].map(v => ({ label: v, value: v }))
              },
        reviews_count_per_product: {
          type: 'number',
          digits: 3,
          validate: ['required', { rule: 'min_max_integer', min: 2, max: 5 }]
        },
        horizontal_padding_between_products: {
          type: 'number',
          digits: 3,
          validate: ['required']
        },
        vertical_padding_between_products: {
          type: 'number',
          digits: 3,
          validate: ['required']
        },
        vertical_padding_between_reviews: {
          type: 'number',
          digits: 3,
          validate: ['required']
        }
      };
    },
    horizontalStyleOptions() {
      return {
        horizontal_scroll_multiple_rows: {
          type: 'select',
          options: [1, 2].map(v => ({
            label: v,
            value: v === 1 ? false : true
          }))
        },
        horizontal_scroll_enable_autoscroll: {
          type: 'select_radio',
          options: [
            {
              label: this.$t(
                'form.basic_section.horizontal_scroll_enable_autoscroll.true'
              ),
              value: true
            },
            {
              label: this.$t(
                'form.basic_section.horizontal_scroll_enable_autoscroll.false'
              ),
              value: false
            }
          ],
          fields: this.formObject[
            this.attributeName('horizontal_scroll_enable_autoscroll')
          ]
            ? [
                {
                  id: this.attributeName('horizontal_scroll_autoscroll_delay'),
                  validate: [
                    'required',
                    { rule: 'min_max_integer', min: 1, max: 99 }
                  ]
                }
              ]
            : []
        },
        horizontal_scroll_button_style: {
          type: 'select_radio',
          options: TargetWidgetHorizontalScrollButtonStyle.options([
            TargetWidgetHorizontalScrollButtonStyle.OUTSIDE,
            TargetWidgetHorizontalScrollButtonStyle.INSIDE,
            TargetWidgetHorizontalScrollButtonStyle.BORDER
          ])
        }
      };
    },
    listStyleOptions() {
      return {
        show_horizontal_scroll: {
          type: 'select_radio',
          options: [
            {
              label: this.$t(
                'form.basic_section.show_horizontal_scroll.true.label'
              ),
              value: true
            },
            {
              label: this.$t(
                'form.basic_section.show_horizontal_scroll.false.label'
              ),
              value: false
            }
          ],
          fields: [
            {
              id: this.attributeName('products_count_per_page'),
              validate: this.formObject[
                this.attributeName('show_horizontal_scroll')
              ]
                ? ['required', { rule: 'min_max_integer', min: 1, max: 8 }]
                : null
            }
          ]
        }
      };
    }
  }
};
