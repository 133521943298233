<template>
  <div class="AppDataBox">
    <div class="AppDataBox__title">{{ title }}</div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppDataBox',
  props: { title: { type: String, required: true } }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AppDataBox {
  text-align: left;
  display: inline-block;
}

.AppDataBox__title {
  @include text-table-head;
  margin-bottom: 16px;
}
</style>
