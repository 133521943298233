var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TheSearchDialogKeywords"},[(_vm.recentlyVisitedPages.length > 0)?_c('div',{staticClass:"TheSearchDialogKeywords__section"},[_c('div',{staticClass:"TheSearchDialogKeywords__section_title"},[_vm._v(" "+_vm._s(_vm.$t('keywords_section_recent'))+" ")]),_c('ul',_vm._l((_vm.recentlyVisitedPages),function(page,index){return _c('li',{key:index,ref:"items",refInFor:true,class:[
          'search-dialog-item',
          'search-dialog-item--keyword',
          {
            'search-dialog-item--active': index === _vm.activeIndex
          }
        ]},[_c('TheSearchDialogResult',{attrs:{"search-result":page},on:{"mousemove-search-result":function($event){return _vm.activateItem(index)},"select-search-result":_vm.selectItem}})],1)}),0)]):_vm._e(),(_vm.recommendedKeywordsUsing.length > 0)?_c('div',{staticClass:"TheSearchDialogKeywords__section"},[_c('div',{staticClass:"TheSearchDialogKeywords__section_title"},[_vm._v(" "+_vm._s(_vm.$t('keywords_section_recommendation'))+" ")]),_c('ul',_vm._l((_vm.recommendedKeywordsUsing),function(keyword,index){return _c('li',{key:index,ref:"items",refInFor:true,class:[
          'search-dialog-item',
          'search-dialog-item--keyword',
          {
            'search-dialog-item--active':
              _vm.recentlyVisitedPages.length + index === _vm.activeIndex
          }
        ]},[_c('a',{staticClass:"search-dialog-item__link",on:{"mousemove":function($event){return _vm.activateItem(_vm.recentlyVisitedPages.length + index)},"click":_vm.selectItem}},[_vm._v(_vm._s(keyword))])])}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }