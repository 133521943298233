var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['AppColorPicker', { 'AppColorPicker--disabled': _vm.disabled }]},[_c('button',{ref:"button",staticClass:"AppColorPicker__button",attrs:{"type":"button","disabled":_vm.disabled},on:{"click":function($event){_vm.isColorPickerVisible = !_vm.isColorPickerVisible}}},[_c('div',{class:[
        'AppColorPicker__color-box',
        _vm.currentValue === 'transparent'
          ? ("AppColorPicker__color-box--" + _vm.currentValue)
          : null
      ],style:({ backgroundColor: _vm.currentValue })}),_c('div',{staticClass:"AppColorPicker__color-info"},[_c('div',{class:[
          'AppColorPicker__button-text',
          { 'AppColorPicker__button-text--disabled': _vm.disabled }
        ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{class:[
          'AppColorPicker__color-text',
          { 'AppColorPicker__color-text--disabled': _vm.disabled }
        ]},[_vm._v(" "+_vm._s(_vm.currentValue)+" ")])])]),(_vm.isColorPickerVisible)?_c('AppModeless',{ref:"colorPicker",on:{"close":function($event){_vm.isColorPickerVisible = false}}},[_c('Sketch',{attrs:{"disable-alpha":"","value":_vm.currentValue},on:{"input":_vm.updateValue}})],1):_vm._e(),_c('input',{attrs:{"type":"hidden","name":_vm.name},domProps:{"value":_vm.currentValue}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }