<template>
  <AppTable :columns="COLUMNS" :rows="rows" align="left">
    <template #cell="{ value }">
      <template v-if="value && value.key">
        <Component :is="value.i18n ? 'i18n' : 'div'" :path="value.i18n">
          <AppCurrencyInput
            :value="datum[value.key]"
            :digits="6"
            :default="0"
            :name="`${name}[${value.key.replace(/_cents$/, '')}]`"
            :invalid="
              !!(
                value.validate_key &&
                errors[`${datum.id}[${value.validate_key}]`]
              )
            "
            @change="change(value, $event)"
            @blur="blur(value)"
          />
        </Component>
      </template>
    </template>
  </AppTable>
</template>

<script>
export default {
  name: 'ReviewSettingsMileageAmountSimpleProductPriceTable',
  props: {
    datum: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      COLUMNS: [
        'product_price',
        'text_review_mileage',
        'photo_review_mileage'
      ].map(id => ({ id, label: this.$t(id) }))
    };
  },
  computed: {
    rows() {
      return [
        {
          product_price: {
            i18n: 'product_price_upper',
            key: 'auto_mileage_product_price_cents'
          },
          text_review_mileage: {
            key: 'auto_mileage_text_review_upper_product_price_cents'
          },
          photo_review_mileage: {
            key: 'auto_mileage_photo_review_upper_product_price_cents'
          }
        }
      ];
    }
  },
  methods: {
    change(cell, value) {
      this.$emit('change', { key: cell.key, value });
      this.validateField(cell);
    },
    blur(cell) {
      this.validateField(cell);
    },
    validateField({ validate_key }) {
      if (validate_key)
        this.$emit('validate-field', `${this.datum.id}[${validate_key}]`);
    }
  }
};
</script>

<i18n locale="ko">
{
  "product_price": "구매 당시 상품 가격 기준",
  "text_review_mileage": "텍스트 리뷰 지급액",
  "photo_review_mileage": "포토/동영상 리뷰 지급액",
  "product_price_upper": "{0} 이상일 때"
}
</i18n>

<i18n locale="en">
{
  "product_price_upper": "when above {0}"
}
</i18n>
