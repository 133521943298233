<template>
  <AppModalForm
    :title="$t('title')"
    :sub-title="campaign.name"
    :is-loading="isLoading"
    :form-props="{
      ...formProps,
      objectId: 'widget_recommendation_settings'
    }"
    v-on="formEvents"
  >
    <template #head>
      <AppInfoBox
        v-if="isReviewHighlightWidgetCampaign"
        class="TargetCampaignSettingsWidgetUndisplayableCategoryIdsDialog__infobox"
      >
        {{ $t('info_box') }}
      </AppInfoBox>
    </template>
    <template #group-sub-item="{ group, value, errors }">
      <template v-if="group.id === 'use_undisplayable_category_ids'">
        <template v-if="value">
          <AppSelectProductCategory
            v-model="formObject.undisplayable_category_ids"
            multiple
            :disabled="!formObject.use_undisplayable_category_ids"
            :invalid="!!errors.undisplayable_category_ids"
            name="campaign[widget_recommendation_settings][undisplayable_category_ids][]"
            @change="validateField('undisplayable_category_ids')"
          />
          <AppFormError :error="errors.undisplayable_category_ids" />
        </template>
      </template>
    </template>
  </AppModalForm>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'TargetCampaignSettingsWidgetUndisplayableCategoryIdsDialog',
  mixins: [DialogFormView],
  props: { campaign: { type: Object, required: true } },
  data: () => ({ isLoading: true }),
  computed: {
    ...mapGetters('targetCampaign', ['isReviewHighlightWidgetCampaign']),
    formSections() {
      return [
        {
          groups: [
            {
              id: 'use_undisplayable_category_ids',
              type: 'select_radio',
              name:
                'campaign[widget_recommendation_settings][use_undisplayable_category_ids]',
              label: this.$t('use_undisplayable_category_ids.label'),
              groupDescription: this.$t(
                'use_undisplayable_category_ids.description'
              ),
              options: [
                {
                  label: this.$t('use_undisplayable_category_ids.false'),
                  value: false
                },
                {
                  label: this.$t('use_undisplayable_category_ids.selected'),
                  value: true
                }
              ],
              fields: this.formObject.use_undisplayable_category_ids
                ? [
                    {
                      id: 'undisplayable_category_ids',
                      validate: ['required']
                    }
                  ]
                : []
            }
          ]
        }
      ];
    }
  },
  mounted() {
    api
      .get(
        `/target/campaigns/${this.campaign.id}/widget_recommendation_settings`
      )
      .then(({ data }) => {
        this.orgFormObject = data.widget_recommendation_settings;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapMutations('targetCampaign', ['SET_CAMPAIGN']),
    submit(formData) {
      this.isSubmitting = true;
      api
        .patch(
          `/target/campaigns/${this.campaign.id}/widget_recommendation_settings`,
          formData,
          { successMessage: this.$t('app.saved') }
        )
        .then(({ data }) => {
          this.SET_CAMPAIGN(data.campaign);
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.TargetCampaignSettingsWidgetUndisplayableCategoryIdsDialog__infobox {
  margin-bottom: 24px;
}
</style>

<i18n locale="ko">
{
  "title": "위젯 사용 카테고리",
  "use_undisplayable_category_ids": {
      "label": "카테고리 선택",
      "description": "위젯을 노출하고 싶지 않은 카테고리가 있다면 아래 옵션에서 설정해주세요.",
      "false": "전체 카테고리에 위젯을 노출합니다.",
      "selected": "선택한 카테고리에서는 위젯을 노출하지 않습니다."
  },
  "info_box": "카테고리 페이지에 설치하는 경우에만 적용되는 설정입니다."
}
</i18n>
