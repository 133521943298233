<template>
  <div class="AnalyticsInsightDashboardProducts">
    <div class="AnalyticsInsightDashboardProducts__title">
      {{ $t('products_analysis') }}
    </div>
    <AppGrid>
      <div
        v-for="(type, index) in ['popular', 'risky', 'potential']"
        :key="index"
        class="
          AnalyticsInsightDashboardProducts__col AppGrid__col AppGrid__col--4
        "
      >
        <AnalyticsInsightSection
          :title="$t(`menu.analytics_insight_products_${type}`)"
          :button-props="{
            label: $t('view_products_analysis'),
            link: pathWithDateRangeParams(`insight_products/${type}`)
          }"
        >
          <AppTable
            :columns="COLUMNS"
            :rows="rows(type)"
            table-id="analytics-insight-dashboard-products"
            class="AnalyticsInsightDashboardProducts__product-table"
            :no-data="$t('insight.no_data')"
          >
            <template #cell="{ row, column }">
              <template v-if="column.id === 'name'">
                <AppImage
                  :src="row.src"
                  class="AnalyticsInsightDashboardProducts__product-image"
                />
                <div
                  :ref="`Table${index}${row.rank}${column.id}`"
                  v-tooltip="{
                    message: autoTooltip(
                      true,
                      `Table${index}${row.rank}${column.id}`
                    )
                  }"
                  class="AnalyticsInsightDashboardProducts__product-name"
                >
                  {{ row.name }}
                </div>
              </template>
            </template>
          </AppTable>
        </AnalyticsInsightSection>
      </div>
    </AppGrid>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TooltipHelper from '@/mixins/TooltipHelper';

export default {
  name: 'AnalyticsInsightDashboardProducts',
  mixins: [TooltipHelper],
  props: {
    dateRange: { type: Object, required: true },
    popularProducts: { type: Array, required: true },
    riskyProducts: { type: Array, required: true },
    potentialProducts: { type: Array, required: true }
  },
  data() {
    return {
      COLUMNS: [
        { id: 'rank', label: this.$t('product_rank') },
        { id: 'name', label: this.$t('products.name'), align: 'left' }
      ]
    };
  },
  computed: {
    ...mapGetters(['pathWithDateRangeParams']),
    popularProductsRows() {
      return this.popularProducts.map((product, index) => ({
        rank: index + 1,
        name: product.name,
        src: product.image_url
      }));
    },
    riskyProductsRows() {
      return this.riskyProducts.map((product, index) => ({
        rank: index + 1,
        name: product.name,
        src: product.image_url
      }));
    },
    potentialProductsRows() {
      return this.potentialProducts.map((product, index) => ({
        rank: index + 1,
        name: product.name,
        src: product.image_url
      }));
    }
  },
  methods: {
    rows(type) {
      return this[`${type}ProductsRows`];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.AnalyticsInsightDashboardProducts {
  margin-top: 42px;
}

.AnalyticsInsightDashboardProducts__title {
  @include text-sub-title;
  margin-bottom: 4px;

  @media (max-width: 719px) {
    & {
      margin-bottom: 0;
    }
  }
}

.AnalyticsInsightDashboardProducts__col {
  margin-top: 16px;
  min-width: 328px;
  height: 378px;

  @media (max-width: 719px) {
    &.AppGrid__col {
      margin-top: 20px;
    }
  }
}

::v-deep {
  .analytics-insight-dashboard-products__rank {
    width: 44px;
  }

  .analytics-insight-dashboard-products__name {
    display: flex;
  }
}

.AnalyticsInsightDashboardProducts__product-table {
  table-layout: fixed;
}

.AnalyticsInsightDashboardProducts__product-image {
  flex: 0 0 32px;
  height: 32px;
}

.AnalyticsInsightDashboardProducts__product-name {
  margin-left: 8px;
  min-width: 170px;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<i18n locale="ko">
{
  "products_analysis": "상품 분석",
  "view_products_analysis": "상품 분석 보기",
  "product_rank": "순위"
}
</i18n>
