<template>
  <AppModalForm
    :title="$t(editing ? 'title_edit' : 'title_new')"
    :form-props="{ ...formProps, objectId: 'smart_installation' }"
    :is-loading="isLoading"
    v-on="formEvents"
    @blur-group="formGroupBlurred"
  >
    <input
      type="hidden"
      name="smart_installation[installation_type]"
      :value="installation_type"
    />
    <input
      type="hidden"
      name="smart_installation[id]"
      :value="replacement.id"
    />
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import { mapState } from 'vuex';
import WidgetPageType from '@/enums/WidgetPageType';
import SmartInstallationType from '@/enums/SmartInstallationType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import SmartInstallationFormView from '@/views/review/smart_installation/mixins/SmartInstallationFormView';

export default {
  name: 'ReviewInstallSimulationReviewsCountReplacementFormDialog',
  mixins: [SmartInstallationFormView],
  props: {
    replacement: {
      type: Object,
      default: () => ({
        reviews_count_display_format: '%{reviews_count}'
      })
    }
  },
  data() {
    return {
      installation_type: SmartInstallationType.REPLACE_REVIEWS_COUNT
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    groupOptions() {
      const options = {
        device_type: {
          label: this.$t('device_type.label'),
          type: 'select_radio',
          options: WidgetDeviceType.options(),
          validate: ['required'],
          required: true
        },
        element_selector: {
          label: this.$t('element_selector.label'),
          type: 'text',
          placeholder: this.$t('element_selector.placeholder'),
          validate: [
            'required',
            {
              rule: 'css_selector_format',
              errorMessage: this.$t('validations.selector_format')
            }
          ]
        },
        reviews_count_display_format: {
          label: this.$t('reviews_count_display_format.label'),
          type: 'keyword_textarea',
          required: true,
          keywords: this.keywords,
          singleLine: true,
          validate: [
            'required',
            {
              rule: 'html_format',
              errorMessage: this.$t('validations.html_format')
            }
          ],
          value: this.formObject.reviews_count_display_format
        }
      };

      return {
        ...options,
        ...this.baseGroupOptions
      };
    },
    groupIds() {
      return this.formObject.page_type === WidgetPageType.SPECIFIC
        ? [
            'device_type',
            'page_type',
            'page_path',
            'element_selector',
            'skin_id',
            'reviews_count_display_format'
          ]
        : this.formObject.page_type === WidgetPageType.PRODUCT_DETAIL
        ? [
            'device_type',
            'page_type',
            'skip_product_no_from_url',
            'element_selector',
            'skin_id',
            'reviews_count_display_format'
          ]
        : [
            'device_type',
            'page_type',
            'element_selector',
            'skin_id',
            'reviews_count_display_format'
          ];
    },
    keywords() {
      return [
        {
          key: 'reviews_count',
          desc: this.$t('keywords.reviews_count'),
          value: '%{reviews_count}'
        }
      ];
    },
    editing() {
      return this.formObject.id;
    },
    pageTypeOptions() {
      const widgetPageTypes = [
        WidgetPageType.PRODUCT_DETAIL,
        WidgetPageType.MAIN,
        WidgetPageType.PRODUCT_LIST,
        WidgetPageType.PRODUCT_SEARCH,
        WidgetPageType.EVENT,
        WidgetPageType.SPECIFIC
      ];
      return WidgetPageType.options(widgetPageTypes);
    }
  },
  mounted() {
    this.orgFormObject = this.replacement;
    this.isLoading = false;
  },
  methods: {
    submit(formData) {
      if (this.editing) {
        api
          .patch(
            '/install_simulation/simulations/update_installation',
            formData,
            {
              successMessage: this.$t('app.saved')
            }
          )
          .then(({ data }) => {
            this.eventBus.$emit('update', data.smart_installation);
            this.close(true);
          });
      } else {
        api
          .post('/install_simulation/simulations', formData, {
            successMessage: this.$t('app.created')
          })
          .then(({ data }) => {
            this.eventBus.$emit('save', data.smart_installation);
            this.close(true);
          });
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title_new": "상품 리뷰 수 교체 설치 추가",
  "title_edit": "상품 리뷰 수 교체 설치 설정",
  "device_type": {
    "label": "디바이스 선택"
  },
  "element_selector": {
    "label": "설치 위치 입력",
    "placeholder": "설치 위치를 입력해주세요."
  },
  "reviews_count_display_format":{
    "label": "표시 정보"
  },
  "keywords": {
    "reviews_count": "상품 리뷰 수"
  }
}
</i18n>
