<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="normal">Normal</label>
      </div>
      <AppSelectProductOptionValue
        id="normal"
        :product-option-type-id="PRODUCT_OPTION_TYPE_ID"
        :product-option-values="productOptionValuesNormal"
        @change-items="productOptionValuesNormal = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ productOptionValuesNormal.map(p => p.id)[0] }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="disabled">Disabled</label>
      </div>
      <AppSelectProductOptionValue
        id="disabled"
        :product-option-type-id="PRODUCT_OPTION_TYPE_ID"
        disabled
        :product-option-values="productOptionValuesDisabled"
        @change-items="productOptionValuesDisabled = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ productOptionValuesDisabled.map(p => p.id)[0] }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="invalid">Invalid</label>
      </div>
      <AppSelectProductOptionValue
        id="invalid"
        :product-option-type-id="PRODUCT_OPTION_TYPE_ID"
        invalid
        :product-option-values="productOptionValuesInvalid"
        @change-items="productOptionValuesInvalid = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ productOptionValuesInvalid.map(p => p.id)[0] }}
      </div>
    </div>

    <div class="ui-section">
      <div class="ui-section__title">
        <label for="multiple">Multiple</label>
      </div>
      <AppSelectProductOptionValue
        id="multiple"
        :product-option-type-id="PRODUCT_OPTION_TYPE_ID"
        multiple
        :product-option-values="productOptionValuesMultiple"
        @change-items="productOptionValuesMultiple = $event"
      />
      <div class="ui-section__description">
        Selected Value: {{ productOptionValuesMultiple.map(p => p.id) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiSelectProductOptionValue',
  data() {
    const PRODUCT_OPTION_VALUES_BASIC = [
      { id: 5, name: '블루' },
      { id: 6, name: '오렌지' }
    ];
    return {
      PRODUCT_OPTION_TYPE_ID: 1,
      productOptionValuesNormal: PRODUCT_OPTION_VALUES_BASIC.filter(
        p => p.id === 5
      ),
      productOptionValuesDisabled: PRODUCT_OPTION_VALUES_BASIC.filter(
        p => p.id === 6
      ),
      productOptionValuesInvalid: PRODUCT_OPTION_VALUES_BASIC.filter(
        p => p.id === 5
      ),
      productOptionValuesMultiple: [...PRODUCT_OPTION_VALUES_BASIC]
    };
  }
};
</script>
