<template>
  <nav class="TheUtilityMenu">
    <ul>
      <li class="TheUtilityMenu__item">
        <button
          type="button"
          class="TheUtilityMenu__item-link"
          @click="openDialog('TheSearchDialog')"
        >
          <AppSvgIcon name="nav-search" class="TheUtilityMenu__item-icon" />
          <span class="TheUtilityMenu__item-label">{{ $t('app.search') }}</span>
        </button>
      </li>
      <li class="TheUtilityMenu__item">
        <div class="dropdown">
          <button
            v-tooltip="notificationTooltip"
            type="button"
            class="TheUtilityMenu__item-link"
            @click="isNotificationPopupVisible = !isNotificationPopupVisible"
          >
            <AppSvgIcon
              name="nav-notification"
              class="TheUtilityMenu__item-icon"
            />
            <span class="TheUtilityMenu__item-label">{{
              $t('app.notification')
            }}</span>
            <div
              v-if="currentUser.unlisted_notifications_count"
              class="TheUtilityMenu__item-unread-notification-indicator"
            />
          </button>
          <TheNotificationPopup
            v-if="isNotificationPopupVisible"
            class="dropdown__dialog"
            @close="isNotificationPopupVisible = false"
          />
        </div>
      </li>
      <li class="TheUtilityMenu__item">
        <div class="dropdown">
          <button
            type="button"
            class="TheUtilityMenu__item-link TheUtilityMenu__item-link--account"
            @click="isAccountMenuPopupVisible = !isAccountMenuPopupVisible"
          >
            <div class="TheUtilityMenu__item-account-thumbnail">
              <AppImage :src="currentUser.thumbnail_url" />
            </div>
            <span class="TheUtilityMenu__item-label">{{
              isCurrentUserAdmin ? currentBrand.name : currentUser.name
            }}</span>
          </button>
          <TheAccountMenuPopup
            v-if="isAccountMenuPopupVisible"
            class="dropdown__dialog"
            @close="isAccountMenuPopupVisible = false"
          />
        </div>
      </li>
      <li class="TheUtilityMenu__item">
        <div class="dropdown">
          <button
            type="button"
            class="TheUtilityMenu__item-link"
            @click="isMoreMenuPopupVisible = !isMoreMenuPopupVisible"
          >
            <AppSvgIcon class="TheUtilityMenu__item-icon" name="nav-more" />
          </button>
          <TheMoreMenuPopup
            v-if="isMoreMenuPopupVisible"
            class="dropdown__dialog"
            @close="isMoreMenuPopupVisible = false"
          />
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import TheNotificationPopup from './TheNotificationPopup';
import TheAccountMenuPopup from './TheAccountMenuPopup';
import TheMoreMenuPopup from './TheMoreMenuPopup';

export default {
  name: 'TheUtilityMenu',
  components: {
    TheNotificationPopup,
    TheAccountMenuPopup,
    TheMoreMenuPopup
  },
  data() {
    return {
      isNotificationPopupVisible: false,
      isAccountMenuPopupVisible: false,
      isMoreMenuPopupVisible: false
    };
  },
  computed: {
    ...mapState('session', ['currentUser', 'currentBrand']),
    ...mapGetters('session', ['isCurrentUserAdmin']),
    notificationTooltip() {
      const {
        unlisted_notifications_count,
        unchecked_notifications_count
      } = this.currentUser;
      if (unlisted_notifications_count) {
        return this.$t('tooltip_unlisted_notifications', {
          count: unlisted_notifications_count
        });
      } else if (unchecked_notifications_count) {
        return this.$t('tooltip_unchecked_notifications', {
          count: unchecked_notifications_count
        });
      } else {
        return null;
      }
    }
  },
  methods: mapActions('dialog', ['openDialog'])
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/vars/_z-indexes.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_transitions.scss';
@import '@/scss/mixins/_breakpoints.scss';

.TheUtilityMenu {
  position: relative;
  top: -4px;
  z-index: $z-index-utility-menu;
}

.TheUtilityMenu__item {
  display: inline-block;

  & + & {
    margin-left: 32px;
  }
}

.TheUtilityMenu__item-icon {
  vertical-align: middle;
}

.TheUtilityMenu__item-link {
  position: relative;
  @include text-utility-navigation;
  @include transition-basic(color);

  cursor: pointer;

  &:hover {
    color: $color-button-text-dark;
  }
}

.TheUtilityMenu__item-unread-notification-indicator {
  position: absolute;
  top: 0;
  right: -9px;
  background-color: $color-red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

$account-thumbnail-size: 28px;
$account-thumbnail-border: 1px;

.TheUtilityMenu__item-account-thumbnail {
  display: inline-block;
  border: $account-thumbnail-border solid #d8d8d8;
  border-radius: 50%;
  text-align: center;
  width: $account-thumbnail-size;
  height: $account-thumbnail-size;
  line-height: $account-thumbnail-size - $account-thumbnail-border * 2;
  vertical-align: middle;
  overflow: hidden;
  font-size: 0;
  top: -3px;
  left: 0;

  img {
    width: 100%;
    vertical-align: middle;
  }

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.TheUtilityMenu__item-label {
  margin-left: 8px;
  @include media-breakpoint-each(mobile) {
    display: none;
  }
}
</style>

<i18n locale="ko">
{
  "tooltip_unlisted_notifications": "새로운 알림이 {count}개 있습니다.",
  "tooltip_unchecked_notifications": "확인하지 않은 알림이 {count}개 있습니다."
}
</i18n>
