<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppSearchbar>
      <template #default>
        <li v-if="isSuperAdminAccessible" class="super-admin__item">
          <AppButton
            :label="$t('button.add_bonus_transaction')"
            button-style="red"
            @click="clickAddBonusTransaction"
          />
        </li>
        <li v-if="isSuperAdminAccessible" class="super-admin__item">
          <AppButton
            :label="$t('button.add_smartstore_transaction')"
            @click="clickAddTransaction"
          />
        </li>
      </template>
      <template #right>
        <li>
          <AppSelect
            v-model="resourceParams.service_type"
            :options="serviceTypeOptions"
            :placeholder="$t('filters.service_type_placeholder')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppSelect
            v-model="resourceParams.transaction_type"
            :options="transactionTypeOptions"
            :placeholder="$t('filters.transaction_type_placeholder')"
            @change="searchResource"
          />
        </li>
        <li>
          <AppDateRangePicker
            v-model="resourceParams"
            @update="searchResource"
          />
        </li>
      </template>
    </AppSearchbar>
    <AppResourceTable
      table-id="review-additional-services-transactions-table"
      :resources="resources"
      :rows="rows"
      :columns="columns"
      :enable-total-count="false"
      :no-data="$t('no_data')"
      @paginate="paginate"
    >
      <template #cell="{ row, column, value }">
        <template v-if="column === 'transaction_type'">
          <AppBadge v-bind="value" />
        </template>
        <template v-else-if="column === 'service_period'">
          <div
            class="ReviewAdditionalServicesTransactionsTable__service-period-content"
          >
            <template v-if="value && value.startedAt">
              <span>{{ `${value.startedAt} ~` }}</span>
              <span>{{ value.expiresAt }}</span>
            </template>
            <template v-else>
              {{ value }}
            </template>
          </div>
        </template>
        <template v-else-if="column === 'actions'">
          <div class="super-admin__item">
            <AppButton
              v-if="isDeleteButtonsVisible(row)"
              :label="$t('app.delete')"
              button-style="red-outline"
              @click="clickDeleteTransaction(row)"
            />
          </div>
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import moment from 'moment';
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceViewNoRoute from '@/mixins/ResourceViewNoRoute';
import CremaServiceType from '@/enums/CremaServiceType';
import ServiceTransactionType from '@/enums/ServiceTransactionType';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ThePaymentHistoryDialogReviewAdditionalServices',
  mixins: [ResourceViewNoRoute],
  data() {
    return { isLoading: true, resources: nullResources };
  },
  computed: {
    ...mapGetters(['isSuperAdminAccessible']),
    columns() {
      return [
        {
          id: 'created_at',
          label: this.$t('app.date'),
          type: 'datetime',
          width: '156px'
        },
        {
          id: 'service_type',
          label: this.$t('column.service_type'),
          width: '190px'
        },
        {
          id: 'transaction_type',
          label: this.$t('column.transaction_type'),
          width: '90px'
        },
        {
          id: 'description',
          label: this.$t('app.description'),
          align: 'left'
        },
        {
          id: 'service_period',
          label: this.$t('column.service_period'),
          width: '186px'
        },
        { id: 'actions', label: this.$t('app.actions'), superAdmin: true }
      ];
    },
    rows() {
      return this.resources.items.map(item => ({
        ...item,
        service_type: CremaServiceType.t(item.service_type),
        transaction_type: {
          label: ServiceTransactionType.t(item.transaction_type),
          badgeStyle: ServiceTransactionType.badgeStyle(item.transaction_type)
        },
        description: this.description(item),
        service_period: this.servicePeriod(item)
      }));
    },
    serviceTypeOptions() {
      return CremaServiceType.options([
        CremaServiceType.SMARTSTORE_STARTER,
        CremaServiceType.SMARTSTORE_DAILY,
        CremaServiceType.REVIEW_SYNC
      ]);
    },
    transactionTypeOptions() {
      return ServiceTransactionType.options([
        ServiceTransactionType.OPERATING,
        ServiceTransactionType.PAYMENT,
        ServiceTransactionType.PAYMENT_CANCELLED,
        ServiceTransactionType.EXPIRED,
        ServiceTransactionType.BONUS
      ]);
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    fetchResource(params) {
      this.isLoading = true;
      api
        .get('/payment_history/service_transactions', { params })
        .then(({ data }) => (this.resources = data.service_transactions))
        .finally(() => (this.isLoading = false));
    },
    description(transaction) {
      if (transaction.description) return transaction.description;

      const args = { ...transaction };
      let key = `description.${ServiceTransactionType.key(
        transaction.transaction_type
      )}`;
      switch (transaction.transaction_type) {
        case ServiceTransactionType.PAYMENT:
          if (CremaServiceType.REVIEW_SYNC === transaction.service_type) {
            args.package_name = this.$t('payment.review_sync_package_name', [
              transaction.package.months,
              transaction.package.price
            ]);
          } else {
            args.package_name = this.$t(
              CremaServiceType.SMARTSTORE_STARTER === transaction.service_type
                ? 'payment.smartstore_starter_package_name'
                : 'payment.smartstore_daily_package_name',
              transaction.package
            );
          }

          break;
        case ServiceTransactionType.OPERATING:
          if (CremaServiceType.REVIEW_SYNC === transaction.service_type)
            return null;

          args.import_count = transaction.old_value - transaction.new_value;
          key += `.${CremaServiceType.key(transaction.service_type)}`;
      }

      return this.$t(key, args);
    },
    servicePeriod(transaction) {
      // TODO: add support for REVIEW_SYNC
      if (
        transaction.transaction_type === ServiceTransactionType.PAYMENT &&
        transaction.service_type === CremaServiceType.SMARTSTORE_DAILY
      ) {
        if (!transaction.new_value || !transaction.package?.months) return '-';

        const expiresAt = moment.unix(transaction.new_value);
        const startedAt = moment(expiresAt).subtract(
          transaction.package.months,
          'months'
        );
        return {
          startedAt: startedAt.startOf('day').format('YYYY.MM.DD HH:mm:ss'),
          expiresAt: expiresAt.endOf('day').format('YYYY.MM.DD HH:mm:ss')
        };
      } else if (transaction.service_type === CremaServiceType.REVIEW_SYNC) {
        if (!transaction.started_at || !transaction.expires_at) return '-';

        return {
          startedAt: moment(transaction.started_at).format(
            'YYYY.MM.DD HH:mm:ss'
          ),
          expiresAt: moment(transaction.expires_at).format(
            'YYYY.MM.DD HH:mm:ss'
          )
        };
      }

      if (
        transaction.transaction_type === ServiceTransactionType.BONUS &&
        transaction.service_type === CremaServiceType.SMARTSTORE_DAILY
      ) {
        const expiresAt = moment(transaction.expires_at);
        const startedAt = moment(transaction.started_at);
        return {
          startedAt: startedAt.startOf('day').format('YYYY.MM.DD HH:mm:ss'),
          expiresAt: expiresAt.endOf('day').format('YYYY.MM.DD HH:mm:ss')
        };
      }

      return null;
    },
    isDeleteButtonsVisible(transaction) {
      const item = this.resources.items.find(i => i.id === transaction.id);
      if (
        item &&
        item.transaction_type === ServiceTransactionType.BONUS &&
        item.service_type === CremaServiceType.SMARTSTORE_DAILY
      )
        return true;

      return false;
    },
    clickDeleteTransaction({ id }) {
      if (!confirm(this.$t('app.delete_confirm'))) return;

      api
        .delete(`/payment_history/service_transactions/${id}`, {
          successMessage: this.$t('app.deleted')
        })
        .then(this.refreshResource);
    },
    clickAddTransaction() {
      this.openDialog('ThePaymentHistoryAddSmartstoreTransactionDialog').then(
        eventBus => {
          this.orgResourceParams = this.resourceParams = this.defaultResourceParams;
          eventBus.$on('update', this.refreshResource);
        }
      );
    },
    clickAddBonusTransaction() {
      this.openDialog(
        'ThePaymentHistoryAddSmartstoreBonusTransactionDialog'
      ).then(eventBus => {
        this.orgResourceParams = this.resourceParams = this.defaultResourceParams;
        eventBus.$on('update', this.refreshResource);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.ReviewAdditionalServicesTransactionsTable__service-period-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>

<i18n locale="ko">
{
  "button": {
    "add_bonus_transaction": "충전하기",
    "add_smartstore_transaction": "이용내역 추가하기"
  },
  "filters": {
    "service_type_placeholder": "전체 서비스 종류",
    "transaction_type_placeholder": "전체 유형"
  },
  "column": {
    "service_type": "서비스 종류",
    "transaction_type": "유형",
    "expires_on": "만료일",
    "service_period": "이용기간"
  },
  "no_data": "이용내역이 없습니다.",
  "description": {
    "PAYMENT": "{package_name} ({payment_amount}원)",
    "OPERATING": {
      "SMARTSTORE_STARTER": "과거 리뷰 연동 ({import_count}건)",
      "SMARTSTORE_DAILY": "매일 리뷰 연동 ({import_count}건)"
    },
    "EXPIRED": "기간 만료"
  }
}
</i18n>
