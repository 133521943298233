const isAccessibleWhenDatabaseCreated = ({ state }) =>
  state.session.currentBrand?.database_created;

const isNotShopifyBrand = ({ state }) =>
  state.session.currentBrand.shop_builder !== 'shopify';

const isMallUserInfoFieldAccessible = ({ state }) =>
  state.session.currentBrand?.database_created &&
  state.session.currentBrand.shop_builder !== 'shopify';

export default [
  {
    id: 'brand_settings',
    menuItemId: 'settings_dialog_brand',
    sections: [
      {
        id: 'brand',
        groups: [
          { id: 'logo_image', isAccessible: isAccessibleWhenDatabaseCreated },
          { id: 'name' },
          { id: 'url' },
          { id: 'phone' },
          { id: 'sender_email', isAccessible: isAccessibleWhenDatabaseCreated },
          { id: 'shop_builder' },
          { id: 'brand_id' },
          { id: 'brand_type', superAdmin: true },
          { id: 'brand_product_type', superAdmin: true }
        ]
      },
      {
        id: 'company',
        groups: [{ id: 'company' }]
      },
      {
        id: 'misc',
        groups: [
          { id: 'force_mobile_init_js', superAdmin: true },
          { id: 'show_sub_brand', superAdmin: true },
          { id: 'use_original_product_image' },
          { id: 'disable_init_js_urls', isAccessible: isNotShopifyBrand }
        ],
        isAccessible: isAccessibleWhenDatabaseCreated
      }
    ]
  },
  {
    id: 'user_settings',
    menuItemId: 'settings_dialog_user',
    sections: [
      {
        id: 'basic',
        groups: [
          { id: 'image' },
          { id: 'email' },
          { id: 'name' },
          { id: 'phone', isAccessible: isNotShopifyBrand },
          {
            id: 'mall_username',
            isAccessible: isMallUserInfoFieldAccessible
          },
          { id: 'marketing' }
        ]
      },
      {
        id: 'brand_manager',
        groups: [{ id: 'mall_admin_username' }, { id: 'mall_admin_password' }],
        isAccessible: isMallUserInfoFieldAccessible
      },
      {
        id: 'logistics_settings',
        groups: [
          { id: 'logistics_settings.enabled' },
          { id: 'logistics_settings.solution_type' },
          { id: 'logistics_settings.domain_for_login' },
          { id: 'logistics_settings.username' },
          { id: 'logistics_settings.password' }
        ],
        isAccessible: isNotShopifyBrand
      },
      {
        id: 'security',
        groups: [
          { id: 'password_changed_at' },
          { id: 'password' },
          { id: 'session_timeout_enabled', superAdmin: true }
        ],
        isAccessible: isNotShopifyBrand
      }
    ]
  },
  {
    id: 'admin_locale',
    menuItemId: 'admin_locale_dialog',
    sections: [
      { groups: [{ id: 'admin_locale_info' }, { id: 'admin_locale' }] }
    ]
  }
];
