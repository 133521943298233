<template>
  <div>
    <ThePasswordReset v-if="isResetPasswordRequired" />
    <TheMain v-else />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ThePasswordReset from './ThePasswordReset';
import TheMain from './TheMain';

export default {
  name: 'TheLoggedIn',
  components: { ThePasswordReset, TheMain },
  computed: {
    ...mapState('session', ['currentUser']),
    isResetPasswordRequired() {
      return this.currentUser.reset_password_required;
    }
  },
  created() {
    if (process.env.VUE_APP_DISABLE_POLLING === '1') return;

    this.startPolling();
    this.startJobRequest();
  },
  beforeDestroy() {
    this.stopPolling();
    this.stopJobRequest();
  },
  methods: {
    ...mapActions('poll', ['startPolling', 'stopPolling']),
    ...mapActions('jobRequest', ['startJobRequest', 'stopJobRequest'])
  }
};
</script>
