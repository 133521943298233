import i18n from '@/lib/i18n';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('settings', ['accessibleSettings']),
    formSections() {
      const { sections } = this.accessibleSettings.find(
        s => s.id === this.SETTINGS_ID
      );
      return sections.map(section => ({
        ...section,
        label: this.sectionLabel(section),
        groups: this.formSectionGroups(section),
        advancedGroups: this.formSectionAdvancedGroups(section)
      }));
    },
    groupOptions() {
      return {};
    }
  },
  methods: {
    formSectionGroups(section) {
      return section.groups.map(group => this.formSectionGroup(group));
    },
    formSectionAdvancedGroups(section) {
      return (
        section.advancedGroups?.map(group => this.formSectionGroup(group)) || []
      );
    },
    formSectionGroup(group) {
      return { ...group, ...this.groupOptions[group.id] };
    },
    sectionLabel(section) {
      if (section.hideLabel) return;
      if (section.label) return section.label;
      if (section.id) {
        const labelKey = `settings.${this.SETTINGS_ID}.sections.${section.id}`;
        if (i18n.te(labelKey)) return i18n.t(labelKey);
      }
    }
  }
};
