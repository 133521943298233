<template>
  <AppModalForm :title="$t('title')" :form-props="formProps" v-on="formEvents">
    <template #head>
      <AppInfoBox
        class="TheInstallRequestRenewalMallAdminAccountDialog__info-box"
      >
        <div>{{ $t('info_box.content_1') }}</div>
        <AppExternalLink
          class="TheInstallRequestRenewalMallAdminAccountDialog_help-center-link"
          :label="$t('info_box.link.cafe24')"
          :to="help_center_link"
        />
        <span>{{ $t('info_box.content_2') }}</span>
        <div>{{ $t('info_box.content_3') }}</div>
      </AppInfoBox>
    </template>
  </AppModalForm>
</template>

<script>
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'TheInstallRequestRenewalMallAdminAccountDialog',
  mixins: [DialogFormView],
  computed: {
    help_center_link() {
      return 'https://help-review.cre.ma/hc/ko/articles/9991731029529--리뷰-카페24-부운영자-생성-및-권한-위임-설정';
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'mall_admin_username',
              label: this.$t('mall_admin_username.label'),
              type: 'text',
              placeholder: this.$t('mall_admin_username.placeholder'),
              validate: ['required']
            },
            {
              id: 'mall_admin_password',
              label: this.$t('mall_admin_password.label'),
              type: 'text',
              placeholder: this.$t('mall_admin_password.placeholder'),
              validate: ['required']
            }
          ]
        }
      ];
    }
  },
  methods: {
    submit(formData) {
      this.isSubmitting = true;

      api
        .patch('/install_request/renewal/mall_admin_accounts', formData, {
          silent: true
        })
        .then(() => {
          this.close(true);
        })
        .finally(() => (this.isSubmitting = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.TheInstallRequestRenewalMallAdminAccountDialog__info-box {
  margin: 2px 0 32px 0;
}

.TheInstallRequestRenewalMallAdminAccountDialog_help-center-link {
  text-decoration: underline;
}
</style>

<i18n locale="ko">
{
  "title": "부운영자 계정 정보 요청",
  "submit": "리뉴얼 위젯 설치 요청",
  "info_box": {
    "content_1": "위젯 설치 요청 진행을 위해서는 부운영자 계정 정보가 필요합니다.",
    "content_2": "을 참고하여 부운영자 계정을 입력해주세요.",
    "content_3": "부운영자 계정 정보 요청이 반복될 경우 크리마 운영팀에 문의해주세요.",
    "link": {
      "cafe24": "cafe24 부운영자 생성 및 권한 위임 설정 방법"
    }
  },
  "mall_admin_username": {
    "label": "부운영자 아이디",
    "placeholder": "부운영자 ID를 입력해주세요."
  },
  "mall_admin_password": {
    "label": "부운영자 비밀번호",
    "placeholder": "부운영자 비밀번호를 입력해주세요."
  }
}
</i18n>
