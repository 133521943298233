<template>
  <div class="TheContent">
    <div
      v-if="!showCustomTitle"
      :class="[
        'TheContent__title',
        `TheContent__title--${selectedSubMenuItemId}`,
        {
          'TheContent__title--super-admin': isSuperAdminAccessible
        }
      ]"
    >
      {{ title }}
    </div>
    <div v-if="isVisible">
      <RouterView v-if="isAuthorized" />
      <ErrorUnauthorized v-else />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ErrorUnauthorized from '@/views/error/ErrorUnauthorized';
import globalEventBus from '@/lib/globalEventBus';

export default {
  name: 'TheContent',
  components: { ErrorUnauthorized },
  data() {
    return { isVisible: true };
  },
  computed: {
    ...mapState(['title']),
    ...mapState('menu', ['showCustomTitle']),
    ...mapGetters('menu', ['selectedSubMenuItemId']),
    ...mapGetters(['isSuperAdminAccessible']),
    isAuthorized() {
      return !!this.selectedSubMenuItemId;
    }
  },
  mounted() {
    const ajaxContent = document.querySelector('.AppAjaxContent');
    if (!ajaxContent) this.$store.state.isContentReady = true;

    globalEventBus.$on('refresh-content', () => {
      this.isVisible = false;
      this.$nextTick(() => (this.isVisible = true));
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';
@import '@/scss/mixins/_breakpoints.scss';

.TheContent__title {
  @include text-title;
  padding-bottom: 28px;

  @include media-breakpoint-each(tablet, mobile) {
    position: sticky;
    left: $padding-horizontal-tablet;
    width: calc(100vw - #{$padding-horizontal-tablet * 2});
  }
}
.TheContent__title--review_settings_widgets.TheContent__title--super-admin {
  padding-bottom: 12px;
}
</style>
