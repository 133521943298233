<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label for="basic">Basic Usage</label>
      </div>
      <AppButton label="Run Job" @click="runAsyncJob" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UiAsyncJob',
  data() {
    return { jobNo: 1 };
  },
  methods: {
    ...mapActions('asyncJob', ['enqueueJob']),
    ...mapActions('toast', ['createToast']),
    runAsyncJob() {
      const title = `Job #${this.jobNo}`;
      const totalCount = 50;
      this.enqueueJob({
        title,
        totalCount,
        jobHandler: async ({ progress }) => {
          for (let i = 0; i < totalCount; ++i) {
            await new Promise(resolve =>
              setTimeout(() => {
                progress();
                resolve();
              }, 30)
            );
          }
          return `${title} is all set!`;
        },
        resultHandler: this.createToast
      });
      this.jobNo += 1;
    }
  }
};
</script>
