<template>
  <AppResourceTable
    table-id="target-dm-campaigns-situational-stats-table"
    table-style="stats"
    v-bind="{ rows, columns, resources }"
    :enable-total-count="false"
    @paginate="paginate"
  />
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import ReportsView from '@/mixins/ReportsView';
import TargetCampaignStatsTableColumns from '@/mixins/TargetCampaignStatsTableColumns';
import TargetSamplingMethod from '@/enums/TargetSamplingMethod';
import TargetSubReport from '@/enums/TargetSubReport';
import TargetSubReportType from '@/enums/TargetSubReportType';

export default {
  name: 'TargetCampaignStatsTable',
  mixins: [ReportsView, TargetCampaignStatsTableColumns],
  props: {
    campaign: { type: Object, required: true },
    samplingMethod: { type: Number, required: true }
  },
  computed: {
    subReportTypes() {
      return TargetSubReport.subReportTypes(
        this.reportsRange.sub_report,
        this.campaign.campaign_media_type
      );
    },
    totalRows() {
      const { formatDate } = this.$options.filters;

      const report = this.calcTotalReport(this.reports);

      const subTotalRows = this.subReportTypes.map(subReportType => {
        const subReports = this.reports.map(
          ({ sub_reports }) => sub_reports[subReportType]
        );
        const subReport = this.calcTotalReport(subReports);

        return {
          ...this.reportToRow(subReport, report),
          subReportType,
          date_range: TargetSubReportType.t(subReportType),
          rowStyle: 'stats-total-detail'
        };
      });

      const totalRow = {
        ...this.reportToRow(report),
        date_range: `${formatDate(
          this.reportsRange.start_date
        )} ~\n${formatDate(this.reportsRange.end_date)}`,
        rowStyle: subTotalRows.length ? 'stats-total-summary' : 'stats-total'
      };

      return [totalRow, ...subTotalRows];
    },
    rows() {
      return [...this.totalRows, ...this.reportRows];
    },
    reportRows() {
      const rows = [];
      this.reportsTable.forEach(report => {
        rows.push({
          ...this.reportToRow(report),
          date_range: this.getFormattedDate(report.date),
          rowStyle: 'stats-summary'
        });

        this.subReportTypes.forEach(subReportType => {
          const subReport = report.sub_reports
            ? report.sub_reports[subReportType]
            : {};
          rows.push({
            ...this.reportToRow(subReport, report),
            subReportType,
            date: report.date,
            date_range: TargetSubReportType.t(subReportType),
            rowStyle: 'stats-detail'
          });
        });
      });
      return rows;
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    calcTotalReport(reports) {
      const totalReport = {};
      reports.forEach(report =>
        _.forEach(report, (v, k) => {
          if (_.isNumber(v)) totalReport[k] = (totalReport[k] || 0) + v;
        })
      );
      return totalReport;
    },
    openDetailDialog(reportItemType, { date, subReportType }) {
      const dateRange = date
        ? { start_date: date, end_date: this.getLastDay(date) }
        : this.reportsRange;
      this.openDialog([
        'TargetCampaignStatsDetailDialog',
        {
          reportItemType: TargetSamplingMethod.column(
            this.samplingMethod,
            reportItemType
          ),
          subReportType,
          campaign: this.campaign,
          dateRange,
          samplingMethod: this.samplingMethod
        }
      ]);
    },
    reportToRow(report, mainReport) {
      const totalColumnId = this.columns[1].id;
      const row = { ...report };
      const isReportEmpty = !(mainReport || report)[totalColumnId];
      if (isReportEmpty)
        this.columns.forEach(column => {
          if (['number', 'number2', 'rate'].includes(column.type))
            row[column.id] = null;
        });
      else
        this.columns.forEach(column => {
          if (column.type === 'number2') {
            const { column1, column2 } = column;
            row[column.id] =
              column1 in report && column2 in report
                ? [report[column1] || 0, report[column2] || 0]
                : null;
          } else if (column.type === 'rate') {
            const n = report[column.numerator];
            const d =
              report[column.denominator] ||
              (mainReport ? mainReport[column.denominator] : 0);
            row[column.id] = n && d ? n / d : 0;
          }
        });
      return row;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppResourceTableCell {
    flex: 1 0 84px;
  }

  .target-dm-campaigns-situational-stats-table__date-range,
  .target-dm-campaigns-situational-stats-table__purchase-counts {
    flex-basis: 120px;
  }

  .target-dm-campaigns-situational-stats-table__check-counts {
    flex-basis: 144px;
  }

  .target-dm-campaigns-situational-stats-table__check-rate {
    flex-basis: 70px;
  }

  .target-dm-campaigns-situational-stats-table__respond-rate {
    flex: 0.5 0 88px;
  }

  .target-dm-campaigns-situational-stats-table__respondents-count {
    flex: 0.5 0 110px;
  }

  .target-dm-campaigns-situational-stats-table__revenue,
  .target-dm-campaigns-situational-stats-table__reviewed-sub-orders-count {
    flex-basis: 100px;
  }

  .target-dm-campaigns-situational-stats-table__targeted-reviewed-sub-orders-count {
    flex-basis: 160px;
  }
}
</style>
