<template>
  <AppBadge :label="finalLabel" :badge-style="badgeStyle" :tooltip="tooltip" />
</template>

<script>
import TargetCohortItemStatus from '@/enums/TargetCohortItemStatus';

export default {
  name: 'TargetReportsDispatchResultDialogTableStatusBadge',
  props: {
    status: { type: Number, required: true },
    dispatchMethod: { type: Number, required: true },
    label: { type: String, default: '' },
    tooltip: { type: String, default: '' }
  },
  computed: {
    TargetCohortItemStatus: () => TargetCohortItemStatus,
    finalLabel() {
      return (
        this.label ||
        TargetCohortItemStatus.translateForDispatchMethod(
          this.status,
          this.dispatchMethod
        )
      );
    },
    badgeStyle() {
      switch (this.status) {
        case TargetCohortItemStatus.NONE:
        case TargetCohortItemStatus.CREATED:
        case TargetCohortItemStatus.EMPTY_TARGET:
        case TargetCohortItemStatus.INVALID_TARGET:
          return 'grey-outline';
        case TargetCohortItemStatus.SENDING:
          return 'blue';
        case TargetCohortItemStatus.SENT:
          return 'mint-green';
        case TargetCohortItemStatus.ERROR:
        case TargetCohortItemStatus.ERROR_SENDING:
        case TargetCohortItemStatus.ERROR_NOT_SENDABLE_TIME:
          return 'red';
        case TargetCohortItemStatus.NOT_ENOUGH_CREDIT:
        case TargetCohortItemStatus.PRICE_LIMIT_EXCEEDED:
        case TargetCohortItemStatus.PRICE_LIMIT_PER_DISPATCH_EXCEEDED:
        case TargetCohortItemStatus.PRICE_LIMIT_PER_CAMPAIGN_EXCEEDED:
          return 'grey';
        default:
          return 'default';
      }
    }
  }
};
</script>
