import { constructEnum } from '@/lib/enum';

const BizMessageTemplateCommentStatus = constructEnum(
  'BizMessageTemplateCommentStatus',
  {
    INQUERY: 'INQ',
    REQUEST: 'REQ',
    APPROVED: 'APR',
    REJECTED: 'REJ',
    REPLYED: 'REP'
  }
);

BizMessageTemplateCommentStatus.badgeStyle = status => {
  switch (status) {
    case BizMessageTemplateCommentStatus.APPROVED:
    case BizMessageTemplateCommentStatus.REPLYED:
      return 'mint-green';
    case BizMessageTemplateCommentStatus.REJECTED:
      return 'red';
    default:
      return 'default';
  }
};

export default BizMessageTemplateCommentStatus;
