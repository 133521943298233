var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i18n',{attrs:{"path":_vm.currencyKey}},[_c('AppNumberInput',_vm._b({attrs:{"value":_vm.currentValue,"inline":"","float-precision":_vm.useCents ? 2 : 0},on:{"change":_vm.change}},'AppNumberInput',{
      id: _vm.id,
      name: _vm.name,
      disabled: _vm.disabled,
      placeholder: _vm.placeholder,
      digits: _vm.digits,
      invalid: _vm.invalid,
      default: this.default,
      allowNegative: _vm.allowNegative
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }