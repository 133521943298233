<template>
  <div class="AccordionButtons">
    <template v-if="isCompleted">
      <AppButton
        :label="$t('app.change')"
        button-style="grey"
        @click="$emit('modify')"
      />
      <span class="AccordionButtons__completed-progress">
        <AppButton
          v-if="currentStep < stepsCount"
          :label="$t('app.next')"
          @click="$emit('next')"
        />
        <AppButton
          v-if="1 < currentStep"
          :label="$t('app.prev')"
          @click="$emit('prev')"
        />
      </span>
    </template>
    <template v-else>
      <AppButton
        :label="confirmButtonLabel"
        button-style="blue"
        :disabled="disableNextButton"
        @click="$emit('next')"
      />
      <AppButton
        v-if="1 < currentStep"
        :label="$t('app.prev')"
        @click="$emit('prev')"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'AccordionButtons',
  props: {
    currentStep: { type: Number, default: 1 },
    stepsCount: { type: Number, default: 1 },
    isCompleted: { type: Boolean, required: true },
    disableNextButton: { type: Boolean, required: true },
    completeButtonLabel: { type: String, default: null }
  },
  computed: {
    confirmButtonLabel() {
      const isLastStep = this.currentStep === this.stepsCount;
      return isLastStep ? this.completeButtonLabel : this.$t('app.next');
    }
  }
};
</script>

<style lang="scss" scoped>
.AccordionButtons {
  margin-top: 24px;
}

.AccordionButtons__completed-progress {
  margin-left: 24px;
}
</style>
