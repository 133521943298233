<template>
  <AppAjaxContent :is-loading="isLoading" :style="contentStyle">
    <template #default>
      <TargetCampaignShowHead @delete="campaignDeleted" />
      <AppContainer class="TargetDmCampaignMassShow__effects">
        <TargetCampaignsPrices :campaign="campaign" />
      </AppContainer>
      <div class="TargetDmCampaignMassShow__tabs">
        <AppTabsScrollable
          :tabs="
            ['dispatch', 'design', 'misc'].map(id => ({ id, label: $t(id) }))
          "
        />
        <TargetCampaignSettingsSection id="dispatch" :title="$t('dispatch')">
          <TargetCampaignSettingsDispatch />
        </TargetCampaignSettingsSection>
        <TargetCampaignSettingsSection id="design" :title="$t('design')">
          <TargetDmCampaignSettingsSms />
        </TargetCampaignSettingsSection>
        <TargetCampaignSettingsSection id="misc" :title="$t('misc')">
          <TargetCampaignSettingsMisc />
        </TargetCampaignSettingsSection>
      </div>
    </template>
  </AppAjaxContent>
</template>

<script>
import TargetCampaignsPrices from './components/TargetCampaignsPrices';
import TargetCampaignShowView from './TargetCampaignShowView';
import TargetCampaignShowHead from './components/TargetCampaignShowHead';
import TargetCampaignSettingsSection from './components/TargetCampaignSettingsSection';
import TargetCampaignSettingsDispatch from './TargetCampaignSettingsDispatch';
import TargetDmCampaignSettingsSms from './TargetDmCampaignSettingsSms';
import TargetCampaignSettingsMisc from './TargetCampaignSettingsMisc';

export default {
  name: 'TargetDmCampaignMassShow',
  components: {
    TargetCampaignsPrices,
    TargetCampaignShowHead,
    TargetCampaignSettingsSection,
    TargetCampaignSettingsDispatch,
    TargetDmCampaignSettingsSms,
    TargetCampaignSettingsMisc
  },
  mixins: [TargetCampaignShowView]
};
</script>

<style lang="scss" scoped>
.TargetDmCampaignMassShow__effects {
  padding: 28px 44px;
}

.TargetDmCampaignMassShow__tabs {
  margin-top: 24px;
}
</style>

<i18n locale="ko">
{
  "dispatch": "발송 설정",
  "design": "디자인",
  "misc": "기타 설정"
}
</i18n>
