<template>
  <AppModalDefault
    :title="$t('campaign_info')"
    :sub-title="campaignName"
    width="default"
    @close="close"
  >
    <template #body>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="TargetCampaignShowInfoDetailDialog__content"
        v-html="message"
      />
      <!-- eslint-enable vue/no-v-html -->
    </template>
  </AppModalDefault>
</template>

<script>
import DialogView from '@/mixins/DialogView';
export default {
  name: 'TargetCampaignShowInfoDetailDialog',
  mixins: [DialogView],
  props: {
    message: {
      type: String,
      required: true
    },
    campaignName: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';
.TargetCampaignShowInfoDetailDialog__content {
  @include text-content;

  ::v-deep img {
    display: block;
  }
}
</style>

<i18n locale="ko">
{
  "campaign_info": "캠페인 안내"
}
</i18n>
