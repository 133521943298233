<template>
  <AppModalForm
    :title="$t('title')"
    :is-loading="isLoading"
    :form-props="{ ...formProps, submitLabel: $t('apply_options') }"
    v-on="formEvents"
  />
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewSettingsTranslationSourceDialog',
  mixins: [DialogFormView],
  props: {
    sourceBrandId: {
      type: Number,
      required: true
    },
    values: {
      type: Array,
      required: true
    }
  },
  data() {
    return { optionValues: [], isLoading: true };
  },
  computed: {
    formSections() {
      return [
        {
          groups: [
            {
              id: 'translations',
              label: null,
              type: 'multiple_select_box',
              options: this.optionValues.map(value => ({ value, label: value }))
            }
          ]
        }
      ];
    }
  },
  mounted() {
    this.isLoading = true;
    api
      .get('/review/option_types/load_translation_source', {
        params: { source_brand_id: this.sourceBrandId }
      })
      .then(({ data }) => {
        this.optionValues = data.new_option_values;
        this.orgFormObject = {
          translations: _.intersection(this.values, this.optionValues)
        };
      })
      .catch(error => {
        this.close();
        throw error;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    submit() {
      const oldValues = this.orgFormObject.translations;
      const newValues = this.formObject.translations;
      this.eventBus.$emit('submit', {
        addedValues: _.difference(newValues, oldValues),
        removedValues: _.difference(oldValues, newValues)
      });
      this.close(true);
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "국문몰에서 선택값 불러오기",
  "name": "선택값 이름",
  "apply_options": "항목 적용"
}
</i18n>
