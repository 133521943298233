<template>
  <TheAccountMenuPopupService
    v-if="reviewStatus"
    service="review"
    :content-tooltip="contentTooltip"
    :items="items"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TheAccountMenuPopupService from './TheAccountMenuPopupService';

export default {
  name: 'TheAccountMenuPopupServiceReview',
  components: { TheAccountMenuPopupService },
  computed: {
    ...mapState('session', ['reviewStatus', 'reviewSettings']),
    ...mapGetters('alert', ['reviewRemainingDates']),
    ...mapGetters('session', [
      'currencyKey',
      'isReviewTesting',
      'isReviewOnboarding',
      'isKoreanBrand'
    ]),
    contentTooltip() {
      if (this.isReviewTesting || this.isReviewOnboarding) {
        return this.$t('tooltip');
      }
      return '';
    },
    items() {
      const {
        review_default_sms_count,
        review_paid_sms_price
      } = this.reviewStatus;
      const items = [];

      items.push({
        title: this.$t('expires_on'),
        content: this.expirationContent,
        contentTooltip: this.expirationTooltip
      });

      const balanceType = this.isKoreanBrand ? 'sms_balance' : 'email_balance';

      items.push({
        title: this.$t(`${balanceType}.default`),
        content: this.$t('unit.cases', [review_default_sms_count])
      });

      if (review_paid_sms_price)
        items.push({
          title: this.$t(`${balanceType}.paid`),
          content: this.$t(this.currencyKey, [review_paid_sms_price])
        });

      return items;
    },
    expirationContent() {
      if (this.isReviewTesting) return this.$t('testing');
      if (this.isReviewOnboarding) return this.$t('preparing');

      return this.formatDate(this.reviewStatus.review_expires_at);
    },
    expirationTooltip() {
      if (this.isReviewTesting || this.isReviewOnboarding) return '';

      return this.reviewRemainingDates >= 0
        ? this.$t('days_before_expiration', [this.reviewRemainingDates])
        : this.$t('days_after_expiration', [-this.reviewRemainingDates]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "expires_on": "만료일",
  "sms_balance": {
    "default": "잔여 무료 메시지",
    "paid": "잔여 유료 메시지"
  },
  "email_balance": {
    "default": "잔여 무료 이메일",
    "paid": "잔여 유료 이메일"
  },
  "days_before_expiration": "잔여 {0}일",
  "days_after_expiration": "{0}일 전 만료",
  "tooltip": "사용 시작 전에는 결제를 진행했더라도 이용기간이 차감되지 않습니다. 서비스 이용 시작 시점부터 만료일이 계산됩니다.",
  "testing": "테스트 중",
  "preparing": "사용 준비 중"
}
</i18n>
<i18n locale="en">
{
  "expires_on": "Expires On",
  "days_before_expiration": "{0} days remaning",
  "days_after_expiration": "Expires {0} days ago",
  "testing_tooltip": "Testing\nDuring testing, the period of use is not deducted.\nThe expiration date is calculated from the start of use of the service.\nPaid service usage period: {0} months",
  "onboarding_tooltip": "During testing, the period of use is not deducted.\nThe expiration date is calculated from the start of use of the service.\nPaid service usage period: {0} months",
  "testing": "Testing"
}
</i18n>
