import api from '@/lib/api';
import KakaoStyleReviewImportStatus from '@/enums/KakaoStyleReviewImportStatus';

const state = {
  reviewImportStatus: null
};

const getters = {
  isReviewImportDisabled: ({ reviewImportStatus }) =>
    reviewImportStatus !== KakaoStyleReviewImportStatus.ENABLED
};

const mutations = {
  SET_REVIEW_IMPORT_STATUS(state, reviewImportStatus) {
    state.reviewImportStatus = reviewImportStatus;
  }
};

const actions = {
  fetchHome: ({ commit }) => {
    return api.get('/kakao_style/home').then(({ data }) => {
      commit('SET_REVIEW_IMPORT_STATUS', data.review_import_status);
    });
  }
};

export default { namespaced: true, state, getters, mutations, actions };
