var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppAjaxContent',{attrs:{"is-loading":_vm.isLoading}},[_c('AppResourceTable',{staticClass:"ReviewCategoryTable__resource-table",attrs:{"table-id":"review-category-products-table","table-name":_vm.$t('review_category_products_table_title'),"resources":_vm.products,"columns":_vm.COLUMNS,"rows":_vm.rows},on:{"paginate":_vm.paginate},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var value = ref.value;
var column = ref.column;
var row = ref.row;
return [(column === 'category_names')?[_c('ul',{staticClass:"badges"},_vm._l((value),function(name,idx){return _c('li',{key:idx},[_c('AppBadge',{attrs:{"badge-style":"grey-outline","label":name}})],1)}),0)]:(column === 'status')?[_c('ul',{staticClass:"badges"},[_c('li',[_c('AppBadge',{attrs:{"badge-style":row.display ? 'mint-green' : 'default',"label":row.display
                  ? _vm.$t('products.displayed')
                  : _vm.$t('products.undisplayed')}})],1),(row.sold_out)?_c('li',[_c('AppBadge',{attrs:{"label":_vm.$t('products.sold_out')}})],1):_vm._e(),_c('li',[_c('AppBadge',{attrs:{"tooltip":row.review_display
                  ? null
                  : _vm.ProductStatusReason.t(row.product_status_reason),"badge-style":row.review_display ? 'mint-green' : 'default',"label":row.review_display
                  ? _vm.$t('products.review_displayed')
                  : _vm.$t('products.review_undisplayed')}})],1)])]:(column === 'actions')?_c('ReviewCategoryProductManageButton',{attrs:{"product":row},on:{"change":function($event){return _vm.change($event)}}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }